import { FC } from "react";

import {
  EmailFieldRHF,
  EnhancedDropDownRHFWithLabel,
  ITest,
  LabelPosition,
  PostCodeRHF,
  RHookFormTextFieldWithLabel,
} from "@websential/cosmic";
import { useQuery } from "react-query";
import { GET_QUERY_KEY, QUERY_OPTIONS } from "common/constants";
import { ProvinceService } from "services/common/ProvinceService";
import { Box } from "@mui/material";

interface IEmployeeAddressSetupFieldsPanel extends ITest {}

export const EmployeeAddressSetupFieldsPanel: FC<IEmployeeAddressSetupFieldsPanel> =
  ({ testId }) => {
    const useGetProvinceDDL = () => {
      return useQuery(GET_QUERY_KEY.PROVINCE_DDL, () => ProvinceService.get(), {
        ...QUERY_OPTIONS,
      });
    };
    const { isLoading: isLoadingProvinceDropDownList, data: provinceList } =
      useGetProvinceDDL();
    return (
      <>
        <RHookFormTextFieldWithLabel
          label="Address"
          id="addressLine1"
          testId={`${testId}-employee-address`}
          labelPosition={LabelPosition.Left}
          required
        />
        <RHookFormTextFieldWithLabel
          label="City"
          id="city"
          testId={`${testId}-employee-city`}
          labelPosition={LabelPosition.Left}
          required
        />
        {/* <RHookFormTextFieldWithLabel
          label="Province"
          id="province"
          testId={`${testId}-employee-province`}
          labelPosition={LabelPosition.Left}
          required
        /> */}
        <EnhancedDropDownRHFWithLabel
          selectName="province"
          label="Province"
          labelPosition={LabelPosition.Left}
          required
          testId={`${testId}-province-list`}
          items={provinceList ?? []}
        />
          <PostCodeRHF
          label="Post Code"
          id="postalCode"
          testId={`${testId}-employee-post-code`}
          labelPosition={LabelPosition.Left}
          labelColumns={4}
          fieldColumns={8}
          required
        />
        <RHookFormTextFieldWithLabel
          label="Country"
          id="country"
          testId={`${testId}-employee-country`}
          labelPosition={LabelPosition.Left}
          value="Canada"
          defaultValue={"Canada"}
          readOnly
          required
        />
        <EmailFieldRHF
          label="Email"
          id="email"
          testId={`${testId}-employee-email`}
          labelPosition={LabelPosition.Left}
          labelColumns={4}
          fieldColumns={8}
        />
      </>
    );
  };
