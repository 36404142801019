import { LookupFieldSetup } from "components/LookupFields";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const LookupFieldSetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Lookup Field Setup"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Lookup Fields", navigateTo: "/lookup-fields/list" },
      { text: "Create Lookup Field", navigateTo: "/lookup-fields/setup" }
      ]}
      mainContents={<LookupFieldSetup testId="data-role-setup-page" />}
    />
  );
};
