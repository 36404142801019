import { Box, Grid } from '@mui/material'
import { EnhancedDropDownRHFWithLabel, LabelPosition, RHookFormTextFieldWithLabel, ResetButton, SubmitButton } from '@websential/cosmic'
import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';

interface IglobalSearchFields {
    testId:String;
    isSubmitting:boolean;
    onResetClicked: ()=> void;
    formMethods:any
}
export const GlobalSearchFields: React.FC<IglobalSearchFields> = 
({
    testId,
    isSubmitting,
    onResetClicked,
    formMethods
}) => {
    const [roleName, setRoleName] = useState('employee')
    const onRoleNameSelectionChangeHandler = (name:any) => {
        setRoleName(name.value)
        formMethods.setValue('employeeSearchText',"")
        formMethods.setValue('vendorSearchText','')
        formMethods.setValue('recipientSearchText','')
        formMethods.setValue('beneficiarySearchText','')
    }
  return (
    <Grid
        container
        columnSpacing={2}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
            padding:"2rem 2rem 3rem 3rem",
            '& .MuiInputBase-input': {
            fontSize: '14px', 
          },    
            '& .MuiTypography-subtitle2': {
            fontWeight: 'bold', 
          },    
        }}
    >
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <RHookFormTextFieldWithLabel
            id="accountantSearchText"
            testId={`${testId}-accountant`}
            label="Accountant"
            labelPosition={LabelPosition.Top}
            // placeholder='First Name/ Last Name/ Account Title/ Email'
            placeholder='First Name/ Last Name/ Account Title'
            />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <RHookFormTextFieldWithLabel
            id="clientSearchText"
            testId={`${testId}-cllient`}
            label="Client"
            labelPosition={LabelPosition.Top}
            // placeholder='Legal Name/ Op. Name/ Email'
            placeholder='Legal Name/ Op. Name'
            />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            {/* <RHookFormTextFieldWithLabel
            id="employeeSearchText"
            testId={`${testId}-employees`}
            label="Employes"
            labelPosition={LabelPosition.Top}
            placeholder='First Name/ Last Name/ SIN/ Email'
            /> */}
            <EnhancedDropDownRHFWithLabel
                selectName="appRoleName"
                label="App Role Name"
                // selectedItem={selectedProvince.id !== null && selectedProvince}
                labelPosition={LabelPosition.Top}
                // required
                testId={`${testId}-province-list`}
                items={[
                    {label:'Employee',value:'employee'},
                    {label:'Vendor',value:'vendor'},
                    {label:'T5 Recipient',value:'recipient'},
                    {label:'T3 Beneficiary',value:'beneficiary'}]}
                onSelectionChangeHandler={onRoleNameSelectionChangeHandler}
                selectedItem={roleName}
                />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            {roleName === 'employee' ? 
                <RHookFormTextFieldWithLabel
                id="employeeSearchText"
                testId={`${testId}-employees`}
                label="Employees"
                labelPosition={LabelPosition.Top}
                // placeholder='First Name/ Last Name/ SIN/ Email'
                placeholder='First Name/ Last Name'
                /> 
            : roleName === 'vendor' ? 
                <RHookFormTextFieldWithLabel
                id="vendorSearchText"
                testId={`${testId}-vendor`}
                label="Vendor"
                labelPosition={LabelPosition.Top}
                // placeholder='First Name/ Last Name/ SIN/ Email/ Business Number/ Business Number'
                placeholder='First Name/ Last Name'
                />
            : roleName === 'recipient' ? 
                <RHookFormTextFieldWithLabel
                    id="recipientSearchText"
                    testId={`${testId}-recipient`}
                    label="T5 Recipient"
                    labelPosition={LabelPosition.Top}
                    placeholder='First Name/ Last Name'
            /> : 
                <RHookFormTextFieldWithLabel
                    id="beneficiarySearchText"
                    testId={`${testId}-beneficiary`}
                    label="T3 Beneficiary"
                    labelPosition={LabelPosition.Top}
                    placeholder='First Name/ Last Name'
            />
            }
        </Grid>
            <Box sx={{
                "& .css-1v7ji8k-MuiButtonBase-root-MuiButton-root":{
                    backgroundColor: "#0057db",
                },
                "& .css-1v7ji8k-MuiButtonBase-root-MuiButton-root:hover":{
                    backgroundColor: "#0057db",
                },
                margin:"1.5rem 0 0 1.5rem"
            }}>
                <SubmitButton
                    label="Search"
                    testId="search-button"
                    loading={isSubmitting}
                    icon={<SearchIcon />}
                    />
                <ResetButton testId="reset-button" onClickHandler={onResetClicked} />
            </Box>
    </Grid>
  )
}