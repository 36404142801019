import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";

import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  EnhancedDropDownRHFWithLabel,
  IEnhancedDropDownItem,
  ITest,
} from "@websential/cosmic";

interface Props extends ITest {
  formMethods: UseFormReturn<IFlexFieldSetupRequest, any>;
  flexFieldsList: IEnhancedDropDownItem[];
  isLoading: boolean;
}

const FIELD_NAME = "comparison";

const OPERATOR_ITEMS = [
  {
    label: "==",
    value: 1,
  },
  {
    label: "!=",
    value: 2,
  },
  {
    label: ">",
    value: 3,
  },
  {
    label: "<",
    value: 4,
  },
  {
    label: ">=",
    value: 5,
  },
  {
    label: "<=",
    value: 6,
  },
];

export const ComparisonsPanel: FC<Props> = ({
  formMethods,
  flexFieldsList,
  isLoading,
  testId,
}) => {
  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: FIELD_NAME,
  });

  const onAddClicked = () => {
    append({ operator: "", field: 0 });
  };

  return (
    <>
      {fields.map((item, index) => {
        return (
          <Grid container columnSpacing={3} key={item.id} alignItems="center">
            <Grid item xs={5}>
              <EnhancedDropDownRHFWithLabel
                selectName={`${FIELD_NAME}.${index}.operator`}
                label="Operator"
                items={OPERATOR_ITEMS}
                testId={`${testId}-${index}-operator`}
              />
            </Grid>
            <Grid item xs={5}>
              <EnhancedDropDownRHFWithLabel
                selectName={`${FIELD_NAME}.${index}.field`}
                label="Field"
                items={flexFieldsList}
                isLoading={isLoading}
                testId={`${testId}-${index}-field`}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                data-testid={`${testId}-delete-${index}`}
                variant="text"
                color="warning"
                size="small"
                onClick={() => remove(index)}
              >
                X
              </Button>
            </Grid>
          </Grid>
        );
      })}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={onAddClicked}
            data-testid={`${testId}-add`}
          >
            + Add
          </Button>
        </Box>
      </Box>
    </>
  );
};
