import {
  ACCOUNTANT_ACTIVE_STATUS_ID,
  ACCOUNTANT_SETUP_SCREEN_SLUG,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
} from "common/constants";
import { AddressFields, FlexFieldsUtils } from "common/FlexFieldsUtils";
import {
  instanceOfIAccountant,
  instanceOfIAccountantResponse,
} from "common/instance-method";
import { SetupFormLayout } from "layouts/SetupFormLayout";
import { IAccountant } from "models/accountant";
import { IResponse, IServerError } from "models/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { AccountantService } from "services";

import {
  BackdropCircularProgress,
  // CategoryFieldsPanelLayout,
  DataType,
  DeleteConfirmationDialog,
  EnhancedSnackbar,
  FieldType,
  // FlexFieldsFormPanelLayout,
  IPanel,
  ITabLayout,
  RHookFormGenerator,
} from "@websential/cosmic";
import IField from "@websential/cosmic/build/components/RHookFormGenerator/types/IField";
import { AccountantSetupToolbar } from "./AccountantSetupToolbar";
// import { FlexFieldsFormPanelLayout } from "./FlexFieldsFormPanelLayout";
import { Grid } from "@mui/material";
import { FIELD_TYPE_COMPONENT, FlexFieldsFormPanelLayout, getFieldName, getMultiSelectionDDLDefaultValue } from "./FlexFieldsFormPanelLayout";
import { DefaultValues } from "react-hook-form";
import Values from "@websential/cosmic/build/components/RHookFormGenerator/types/Values";
import { CategoryFieldsPanelLayout } from "./CategoryFieldsPanelLayout";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch } from "react-redux";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IAccountantSetup {}
const SINGLE_RECORD_DATA_QUERY_KEY = "accountant-single-data-record";
const ASSINGED_CATEGORY_DATA_QUERY_KEY = "accountant-category-data-record";
const ASSINGED_CATEGORY_PANEL_QUERY_KEY = "accountant-category-panel-record";

const useGetData = (id: string | undefined) => {
  return useQuery(
    [SINGLE_RECORD_DATA_QUERY_KEY, id],
    () => AccountantService.getById(id),
    QUERY_OPTIONS
  );
};

// const useScreenLayout: any = (refetchScreenFields:boolean) =>
//   useQuery(
//     ["accountant-setup-layout",refetchScreenFields],
//     (data) => {
//       return AccountantService.getSetupLayout(refetchScreenFields);
//     },
//     { refetchOnWindowFocus: false }
//   );
  
  const useScreenLayout = () => {
    return useQuery(
      ["accountant-setup-layout"],
      () => AccountantService.getSetupLayout(),
      QUERY_OPTIONS
    );
  };
const useGetAssignedCategoryPanel = (
  // screenId: number | string,
  // categoryFieldId: number | string,
  // categoryFieldValue: number | string
  selectedCategoriesData:any
) => {
  return useQuery(
    [ASSINGED_CATEGORY_PANEL_QUERY_KEY, selectedCategoriesData],
    () =>
      AccountantService.getAccountantCategoriesAssingedPanels(
        selectedCategoriesData
      ),
    QUERY_OPTIONS
  );
};

const useGetAccountantCategories = (screenId: string | number) => {
  return useQuery(
    [ASSINGED_CATEGORY_DATA_QUERY_KEY, screenId],
    () => AccountantService.getAccountantCategories(screenId),
    QUERY_OPTIONS
  );
};

export const AccountantSetup: React.FC<IAccountantSetup> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [metaFieldIds, setMetaFieldIds] = useState({});
  const [renderForm, setRenderForm] = useState(false);
  const [openDialog, setDialogOpen] = useState(false);
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [accountantId, setAccountantId] = useState<number>(0);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const queryClient = useQueryClient();
  const { id } = useParams();
  console.log("params----", id);
  const [editRecordId, setEditRecordId] = useState(id);
  const [
    categoryFieldsWithOnChangeMethod,
    setCategoryFieldsWithOnChangeMethod,
  ] = useState<any>(undefined);
  let screenId = 22;
  const [selectedcategoryFieldId, setselectedcategoryFieldId] = useState("");
  const [selectedcategoryFieldValue, setselectedcategoryFieldValue] = useState("");
  const [accountantTotalPanels, setAccountantTotalPanels] =
    useState<any>(undefined);
  const [accountantTotalCategories, setAccountantTotalCategories] =
    useState<any>(undefined);

  const { isLoading: isSubmitting, mutate: submitFormFields } = useMutation(
    AccountantService.create,
    {
      onSuccess: (res: IAccountant | IServerError | IResponse | any) => {
        console.log("res123",res)
        if(res.status === 200){
            if (instanceOfIAccountantResponse(res)) {
              setEditRecordId(res.id);
              queryClient.invalidateQueries(SINGLE_RECORD_DATA_QUERY_KEY);
            setSnackbarData({
              open: true,
              message: "Data has been submitted successfully!",
              severity: "success",
            });
          }
          let path = `/accountants/list`;
          navigate(path);
          } else if(res.status === 201){
            if (instanceOfIAccountantResponse(res)) {
              setEditRecordId(res.id);
              queryClient.invalidateQueries(SINGLE_RECORD_DATA_QUERY_KEY);
            setSnackbarData({
              open: true,
              message: "Data has been submitted successfully!",
              severity: "success",
            });
          }
          let path = `/accountants/list`;
          navigate(path);
          } else {
            setSnackbarData({
              open: true,
              message: res.message ? res.message : "Something went wrong!",
              severity: "error",
            });
          }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const { isLoading, data: screenFields } = useScreenLayout();


  console.log("screenFields--", screenFields);

  const { isLoading: isLoadingById, data: selectedDataRecord } =
    useGetData(editRecordId);

    const [selectedCategoriesData, setSelectedCategoriesData] = useState<any>([]);


  const {
    isLoading: isLoadingAccountantCategoriesPanels,
    data: accountantCategoryAssingedPanels,
  } = useGetAssignedCategoryPanel(
    // screenId,
    // selectedcategoryFieldId,
    // selectedcategoryFieldValue
    selectedCategoriesData
  );

  const {
    isLoading: isLoadingAccountantCategories,
    data: accountantCategoryList,
  } = useGetAccountantCategories(screenId);

  console.log(
    "accountantCategoryAssingedPanels",
    accountantCategoryAssingedPanels
  );
  console.log(
    "accountantCategoryList",
    accountantCategoryList
  );

  useEffect(()=>{
    if(accountantCategoryList){
      setAccountantTotalCategories(accountantCategoryList)
    }
  },[accountantCategoryList])

  const onSubmit = (values: any) => {
    console.log("values : ", values);
    console.log("Metaids : ", metaFieldIds);
    let flexfields:any = {
      ...values,
      ["postalCode-" + 8 + "-fixed"]: values["postalCode-" + 8 + "-fixed"] && values["postalCode-" + 8 + "-fixed"].replace(/[a-z]/g, (match: string) => match.toUpperCase()),
      ["id-" + 27 + "-fixed"]:
      selectedDataRecord && selectedDataRecord.address.id
        ? selectedDataRecord.address.id
        : undefined,
      ["isPrimary-" + 28 + "-fixed"]:true,
      ["appAddressTypeId-" + 29 + "-fixed"]:1,
    }
    console.log("flexfields : ", flexfields);

    //add category fields in meta fields 
    const categoryFields = () => {
      if(selectedCategoriesData && selectedCategoriesData.length > 0 && accountantCategoryList && accountantCategoryList.items.length > 0){
        //get all category data from category list
        // let selectedCategoriesDataIds = selectedCategoriesData.map((item:any) => item.categoryFieldId)
        // const filteredItems = accountantCategoryList.items.filter((item:any) => 
        // selectedCategoriesDataIds.includes(item.id)
        // .map((item:any) => ({
        //   fieldId: item.id,
        //   name: "categoryField",
        //   // value:
        // })));
        const makeSelectedCategoryAsMetaField = selectedCategoriesData.map((item:any) => ({
            fieldId: item.categoryFieldId,
            name: "categoryField",
            value:item.categoryFieldValue
          }));
        console.log("selectedCategoriesData",selectedCategoriesData)
        console.log("makeSelectedCategoryAsMetaField",makeSelectedCategoryAsMetaField)
        return makeSelectedCategoryAsMetaField
      } else {
        return []
      }
    }
    console.log("cateknvlsnkdv",categoryFields())
    const categoryFieldsAsMetaFields = categoryFields()

    let payload = FlexFieldsUtils.formatFixedAndMetaFields(
      flexfields,
      metaFieldIds
    );
    payload.id = editRecordId;
    payload.fixedFields["statusId"] = ACCOUNTANT_ACTIVE_STATUS_ID;
    payload.metaFields = payload.metaFields.concat(categoryFieldsAsMetaFields && categoryFieldsAsMetaFields.length > 0 ? categoryFieldsAsMetaFields : [])

    console.log("payload=======", payload);
    console.log("=======String", JSON.stringify(payload));
    submitFormFields(payload);
  };

  useEffect(()=>{
    if(id && selectedDataRecord){
      //category fields update default values
      let metaFields = selectedDataRecord.metaFields;
      let categoryField = metaFields.filter(
        (element:any) => element.name === "categoryField"
      );
      console.log("categoryField--1",categoryField)
      if(categoryField && categoryField.length > 0 && accountantCategoryList && accountantCategoryList.items.length > 0){
        const selectedCategoriesFieldsData = categoryField.map((item:any) => ({
          screenId: screenId,
          categoryFieldId: parseInt(item.fieldId),
          categoryFieldValue: item.value
        }))
        setSelectedCategoriesData((prevData: any) => [...prevData, ...selectedCategoriesFieldsData]);
        console.log("categoryField--1 inside if ",accountantCategoryList)
        //get all category data from category list
        let selectedCategoriesDataIds = categoryField && categoryField.map((item:any) => parseInt(item.fieldId))
        console.log("selectedCategoriesDataIds",selectedCategoriesDataIds)
        const addDefaultValueInCategoryFields = accountantCategoryList.items.filter((listItem:any) => 
        categoryField.find((item:any) => parseInt(item.fieldId) === listItem.id)
        ).map((listItem: any) => {
          const matchingCategory:any = categoryField.find((item: any) => parseInt(item.fieldId) === listItem.id);
          return {
            ...listItem,
            defaultValue: matchingCategory.value,
          };
        });
        console.log("accountantCategoryList.items1",accountantCategoryList.items)
        console.log("filteredItem11",addDefaultValueInCategoryFields)
        
        const finalCategoriesListWithDefaultValue = accountantCategoryList.items.map((metaCategory:any) => {
          const matchingItem = addDefaultValueInCategoryFields.find((categoryListItem:any) => categoryListItem.id === metaCategory.id);
          return matchingItem ? matchingItem : metaCategory;
        });
        setAccountantTotalCategories({items:finalCategoriesListWithDefaultValue})
        console.log("finalres1",finalCategoriesListWithDefaultValue)
      }
    }
  },[id,selectedDataRecord,accountantCategoryList])
  useEffect(() => {
    console.log("#####=>useEffect screenFields", screenFields);
    console.log("#####=>selectedDataRecord", selectedDataRecord);
    if (id) {
      //null all defualt values 
      // screenFields?.panels.forEach((screenPanel: IPanel) => {
      //   screenPanel?.fields.forEach((field: IField) => {
      //     field.defaultValue = 
      //     field.fieldId === "countryId" ? field.defaultValue : null
      //   })
      // })
      accountantTotalPanels && accountantTotalPanels.forEach((item:any) => {
          item?.panels.forEach((screenPanel: IPanel) => {
          screenPanel?.fields.forEach((field: IField) => {
            field.defaultValue = 
            field.fieldId === "countryId" ? field.defaultValue : null
          })
        })
      })
      if (
        screenFields &&
        accountantTotalPanels &&
        selectedDataRecord &&
        instanceOfIAccountant(selectedDataRecord)
      ) {
        let metaFieldIdsObject: { [key: string]: string } = {};
        // screenFields.forEach((screenTab: ITabLayout, index: number) => {
          accountantTotalPanels && accountantTotalPanels.forEach((item:any) => {
            item.panels.forEach((screenPanel: IPanel, index: number) => {
            screenPanel.fields.forEach((field: IField) => {
              console.log("field--",field)
              let isAddressField = AddressFields.find(
                (element) => element === field.fieldId
              );
              console.log("isAddressFieldisAddressField",isAddressField)
              console.log(
                "#####=>selectedDataRecord.hasOwnProperty(field.fieldId) - ",
                field.fieldId,
                " - isAddressField - ",
                isAddressField,
                selectedDataRecord.hasOwnProperty(field.fieldId)
              );

              if (
                field.isFixed &&
                selectedDataRecord.hasOwnProperty(field.fieldId) &&
                !isAddressField
              ) {
                type objectKey = keyof typeof selectedDataRecord;
                let fieldValue = field.fieldId as objectKey;
                field.defaultValue = String(selectedDataRecord[fieldValue]);
                console.log("fieldValuefieldValue",field.defaultValue)
              } else if (field.isFixed && isAddressField) {
                let addressObject = selectedDataRecord.address;
                type addressObjectKey = keyof typeof addressObject;
                let addressFieldKey = field.fieldId as addressObjectKey;
                field.defaultValue = addressObject[addressFieldKey];
                console.log("field.defaultValue",field.defaultValue)

                if (
                  String(addressFieldKey) === "country" ||
                  String(addressFieldKey) === "countryId"
                ) {
                  console.log("******", addressObject.country);
                  field.defaultValue = String(addressObject.country.id);
                } else if (
                  String(addressFieldKey) === "province" ||
                  String(addressFieldKey) === "provinceId"
                ) {
                  console.log("******", addressObject.province);
                  field.defaultValue = String(addressObject.province.id);
                }
              } else if (!field.isFixed) {
                console.log("meta field: ", field.fieldId);
                let metaFields = selectedDataRecord.metaFields;
                let metaField = metaFields.find(
                  (element) => element.name === field.fieldId
                );
                console.log("metaField--",metaField)
                if (metaField) {
                  metaFieldIdsObject[field.fieldId] = metaField.id;
                  field.defaultValue = metaField.value;
                  console.log("metaField.value",field.defaultValue)
                }
              } else {
                console.log("field not found: ", field.fieldId);
              }
            });
console.log("screenFields123",screenFields)
console.log("screenFields123",accountantTotalPanels)
            // Show form in edit mode when all data is loaded
            screenFields && screenFields.forEach((item:any) => {
            if (index === item.panels.length - 1) {
              setRenderForm(true);
            }
          })
          });

        });

        setMetaFieldIds(metaFieldIdsObject);
        console.log("metaFieldIdsObject",metaFieldIdsObject)
      }
    } else {
      // Show form in add mode
      console.log("inside else")
      setRenderForm(true);

      //null all defualt values 
      screenFields && screenFields.forEach((item:any) => {
        item?.panels.forEach((screenPanel: IPanel) => {
        screenPanel?.fields.forEach((field: IField) => {
          field.defaultValue = 
          field.fieldId === "countryId" ? field.defaultValue : null
        })
      })
      })
    }
  }, [id, screenFields, selectedDataRecord, accountantCategoryList,accountantTotalPanels]);

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  useEffect(() => {
    if(accountantCategoryList &&
      accountantCategoryList.items &&
      accountantCategoryList.items.length === 1 &&
      accountantCategoryList.items[0].defualtCategory === true){
        console.log("yeesss")
    }else if (accountantCategoryList && accountantCategoryList.items) {
      console.log("nooooooo1")
      const categoryWithOnChange = accountantCategoryList.items.map(
        (item: any) => {
          let updatedCategories: any = [];
          // let selectedCategory: any = [];
          console.log("item--", item);
          // for (let i = 0; i < accountantCategoryList.items.length; i++) {
              if (item.fieldType === "DropDownList") {
                if(item.defualtCategory === true){
                  let fieldData = {
                    screenId:screenId,
                    categoryFieldId:item.id,
                    categoryFieldValue:item.fieldInternalValue
                  }
                  setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                  updatedCategories.push(item);
                  item.isHide=true
                  item.readOnly=true
                  item.isRequired=false
                  // item.fieldLabel=''
                } else {
                const onDropDownChanged = (value: any) => {
                  console.log("selectedCategoriesData--",selectedCategoriesData)
                  let fieldData = {
                    screenId:screenId,
                    categoryFieldId:item.id,
                    categoryFieldValue:value.value
                  }
                  // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                  setSelectedCategoriesData((prevData: any[]) => {
                    // Check if an object with the same categoryFieldId exists
                    const existingIndex = prevData.findIndex((data) => data.categoryFieldId === fieldData.categoryFieldId);
              
                    if (existingIndex !== -1) {
                      // If exists, remove it
                      const newData = prevData.filter((data, index) => index !== existingIndex);
                      // Add the new object
                      return [...newData, fieldData];
                    } else {
                      // If not exists, just add the new object
                      return [...prevData, fieldData];
                    }
                  });

                  
                  // Check if an object with the same ID already exists
                  // const existingIndex = selectedCategory.findIndex(
                  //   (data: { selectedcategoryFieldId: any; }) => data.selectedcategoryFieldId === item.id
                  // );

                  // console.log("existingIndex",existingIndex)

                  // if (existingIndex !== -1) {
                  //   setSelectedCategoriesData((prevData: any) => {
                  //     const newData = [...prevData];
                  //     console.log("newData1",newData)
                  //     newData[existingIndex] = fieldData;
                  //     console.log("newData2",newData)
                  //     selectedCategory = []
                  //     selectedCategory = newData
                  //     console.log("selectedCategoryyyyyyy",selectedCategory)
                  //     return newData;
                  //   });
                  //   console.log("inside if")
                  // } else {
                  //   console.log("inside else")
                  //   setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                  //   selectedCategory.push(fieldData);
                  // }
                };
                item.onChange = onDropDownChanged;
                updatedCategories.push(item);
                console.log("item456",item)
              }
            } else if (item.fieldType === "NormalSwitch") {
                if(item.defualtCategory === true){
                  let fieldData = {
                    screenId:screenId,
                    categoryFieldId:item.id,
                    categoryFieldValue:item.fieldInternalValue
                  }
                  setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                  updatedCategories.push(item);
                  item.isHide=true
                  item.readOnly=true
                  item.isRequired=false
                  // item.fieldLabel=''
                } else {
                  const onSwitchChanged = (value: any) => {
                    // setselectedcategoryFieldId(item.id);
                    // setselectedcategoryFieldValue(value);
                    // alert(value)
                    let fieldData = {
                      screenId:screenId,
                      categoryFieldId:item.id,
                      categoryFieldValue:value
                    }
                    // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                    setSelectedCategoriesData((prevData: any[]) => {
                      // Check if an object with the same categoryFieldId exists
                      const existingIndex = prevData.findIndex((data) => data.categoryFieldId === fieldData.categoryFieldId);
                
                      if (existingIndex !== -1) {
                        // If exists, remove it
                        const newData = prevData.filter((data, index) => index !== existingIndex);
                        // Add the new object
                        return [...newData, fieldData];
                      } else {
                        // If not exists, just add the new object
                        return [...prevData, fieldData];
                      }
                    });
                  };
                  item.onChange = onSwitchChanged;
                  updatedCategories.push(item);
                }
            } else if (item.fieldType === "MultiSelectionDropDownList") {
                if(item.defualtCategory === true){
                  let fieldData = {
                    screenId:screenId,
                    categoryFieldId:item.id,
                    categoryFieldValue:item.fieldInternalValue
                  }
                  setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                  updatedCategories.push(item);
                  item.isHide=true
                  item.readOnly=true
                  item.isRequired=false
                  // item.fieldLabel=''
                } else {
                    const onMultiSelectionDropDownValueChanged = (value: any) => {
                      let selectedItems: any = [];
                      value.map((item: any) => {
                        selectedItems.push(item.value);
                      });
                      let fieldData = {
                        screenId:screenId,
                        categoryFieldId:item.id,
                        categoryFieldValue:selectedItems
                      }
                      // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                      setSelectedCategoriesData((prevData: any[]) => {
                        // Check if an object with the same categoryFieldId exists
                        const existingIndex = prevData.findIndex((data) => data.categoryFieldId === fieldData.categoryFieldId);
                  
                        if (existingIndex !== -1) {
                          // If exists, remove it
                          const newData = prevData.filter((data, index) => index !== existingIndex);
                          // Add the new object
                          return [...newData, fieldData];
                        } else {
                          // If not exists, just add the new object
                          return [...prevData, fieldData];
                        }
                      });
                    };
                    item.onChange = onMultiSelectionDropDownValueChanged;
                    updatedCategories.push(item);
                  }
            } else if (item.fieldType === "Checkbox") {
                if(item.defualtCategory === true){
                  let fieldData = {
                    screenId:screenId,
                    categoryFieldId:item.id,
                    categoryFieldValue:item.fieldInternalValue
                  }
                  setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                  updatedCategories.push(item);
                  item.isHide=true
                  item.readOnly=true
                  item.isRequired=false
                  // item.fieldLabel=''
                } else {
                  const onCheckBoxValueChanged = (value: any) => {
                    let fieldData = {
                      screenId:screenId,
                      categoryFieldId:item.id,
                      categoryFieldValue:value
                    }
                    // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                    setSelectedCategoriesData((prevData: any[]) => {
                      // Check if an object with the same categoryFieldId exists
                      const existingIndex = prevData.findIndex((data) => data.categoryFieldId === fieldData.categoryFieldId);
                
                      if (existingIndex !== -1) {
                        // If exists, remove it
                        const newData = prevData.filter((data, index) => index !== existingIndex);
                        // Add the new object
                        return [...newData, fieldData];
                      } else {
                        // If not exists, just add the new object
                        return [...prevData, fieldData];
                      }
                    });
                  };
                  item.onChange = onCheckBoxValueChanged;
                  updatedCategories.push(item);
                }
            } else if (item.fieldType === "RadioGroup") {
                if(item.defualtCategory === true){
                  let fieldData = {
                    screenId:screenId,
                    categoryFieldId:item.id,
                    categoryFieldValue:item.fieldInternalValue
                  }
                  setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                  updatedCategories.push(item);
                  item.isHide=true
                  item.readOnly=true
                  item.isRequired=false
                  // item.fieldLabel=''
                } else {
                  const onRadioButtonValueChanged = (e: any) => {
                    let fieldData = {
                      screenId:screenId,
                      categoryFieldId:item.id,
                      categoryFieldValue:e.target.value
                    }
                    // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                    setSelectedCategoriesData((prevData: any[]) => {
                      // Check if an object with the same categoryFieldId exists
                      const existingIndex = prevData.findIndex((data) => data.categoryFieldId === fieldData.categoryFieldId);
                
                      if (existingIndex !== -1) {
                        // If exists, remove it
                        const newData = prevData.filter((data, index) => index !== existingIndex);
                        // Add the new object
                        return [...newData, fieldData];
                      } else {
                        // If not exists, just add the new object
                        return [...prevData, fieldData];
                      }
                    });
                  };
                  item.onChange = onRadioButtonValueChanged;
                  updatedCategories.push(item);
                }
            } else {
                if(item.defualtCategory === true){
                  let fieldData = {
                    screenId:screenId,
                    categoryFieldId:item.id,
                    categoryFieldValue:item.fieldInternalValue
                  }
                  setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                  updatedCategories.push(item);
                  item.isHide=true
                  item.readOnly=true
                  item.isRequired=false
                  // item.fieldLabel=''
                } else {
                  let fieldData = {
                    screenId:screenId,
                    categoryFieldId:item.id,
                    categoryFieldValue:item.fieldInternalValue
                  }
                  // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                  setSelectedCategoriesData((prevData: any[]) => {
                    // Check if an object with the same categoryFieldId exists
                    const existingIndex = prevData.findIndex((data) => data.categoryFieldId === fieldData.categoryFieldId);
              
                    if (existingIndex !== -1) {
                      // If exists, remove it
                      const newData = prevData.filter((data, index) => index !== existingIndex);
                      // Add the new object
                      return [...newData, fieldData];
                    } else {
                      // If not exists, just add the new object
                      return [...prevData, fieldData];
                    }
                  });
                  updatedCategories.push(item);
                }
            }
            console.log("//dd",selectedCategoriesData);
            console.log("updatedCategories",updatedCategories)
            return item;
          // }
        }
      );
      console.log("categoryWithOnChange", categoryWithOnChange);
      setCategoryFieldsWithOnChangeMethod(categoryWithOnChange);
    }
  }, [accountantCategoryList]);

  const { isLoading: isDeleting, mutate: deleteAccountantById } = useMutation(
    AccountantService.deleteById,
    {
      onSuccess: (res: string | IServerError | IResponse) => {
        if (typeof res === "string") {
          // remove the cache of the Accountant fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: "Record has been deleted successfully.",
            severity: "success",
          });
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
        // formMethods.reset();
        let path = `/accountants/list`;
        navigate(path);
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const deleteAccountantField = () => {
    if (singleDelete) {
      setSingleDelete(false);
      deleteAccountantById(accountantId);
      setDialogOpen(false);
    }
  };

  const onDeleteClicked = () => {
    setDialogOpen(true);
    setSingleDelete(true);
    if (selectedDataRecord) {
      setAccountantId(selectedDataRecord.id);
    }
  };

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    // formMethods.reset();
    // let path = `/accountants/setup`;
    // navigate(path);
  };

  useEffect(() => {
    let totalPanels: any = [];
    if (accountantCategoryAssingedPanels && accountantCategoryAssingedPanels.length > 0) {
      // screenFields?.panels.map((item: any) => {
      //   totalPanels.push(item);
      // });
      // accountantCategoryAssingedPanels?.panels.map((item: any) => {
      //   totalPanels.push(item);
      // });
      // // totalPanels.push(accountantCategoryAssingedPanels.panels)
      // if (totalPanels && totalPanels.length !== 0) {
      //   let accountantTolalPanel = {
      //     panels: totalPanels,
      //   };
      //   setAccountantTotalPanels(accountantTolalPanel);
      // }
      const makeAllFieldsToMetaFields = accountantCategoryAssingedPanels && accountantCategoryAssingedPanels.map((item:any) => {
        return {
            ...item,
            panels: item.panels.map((panel:any) => {
                return {
                    ...panel,
                    fields: panel.fields.map((field:any) => ({
                        ...field,
                        fieldId:field.appFieldLabel,
                        isFixed: false  //now all fields is meta field
                    }))
                };
            })
        };
    });
      console.log("accountantCategoryAssingedPanels",accountantCategoryAssingedPanels)
      console.log("makeAllFieldsToMetaFields",makeAllFieldsToMetaFields)
      screenFields.map((item: any) => {
        totalPanels.push(item);
      });
      // accountantCategoryAssingedPanels.map((item: any) => {
      //   totalPanels.push(item);
      // });

      //make all field to isFixed false to make it meta field 
      makeAllFieldsToMetaFields.map((item: any) => {
        totalPanels.push(item);
      });
      if (totalPanels && totalPanels.length !== 0) {
        // let accountantTolalPanel = {
        //   panels: totalPanels,
        // };
        setAccountantTotalPanels(totalPanels);
      }
    } else {
      // screenFields?.panels.map((item: any) => {
      //   totalPanels.push(item);
      //   if (totalPanels && totalPanels.length !== 0) {
      //     let accountantTolalPanel = {
      //       panels: totalPanels,
      //     };
      //     setAccountantTotalPanels(accountantTolalPanel);
      //   }
      // });
      screenFields && screenFields.map((item: any) => {
        totalPanels.push(item);
        if (totalPanels && totalPanels.length !== 0) {
          // let accountantTolalPanel = {
          //   panels: totalPanels,
          // };
          setAccountantTotalPanels(totalPanels);
        }
      });
      // totalPanels.push(screenFields?.panels);
      console.log("insideelse");
    }
    console.log("totalPanels", totalPanels);
  }, [screenFields, accountantCategoryAssingedPanels]);
  // console.log("totalPanels--",totalPanels)
  console.log("accountantTotalPanels--", accountantTotalPanels);
  console.log("selectedCategoriesData",selectedCategoriesData)
  console.log("accountantCategoryList11",accountantCategoryList)
  console.log("categoryFieldsWithOnChangeMethod11",categoryFieldsWithOnChangeMethod)
  console.log("accountantTotalCategories11",accountantTotalCategories)


  useEffect(() => {
    if (
      accountantCategoryList &&
      accountantCategoryList.items &&
      accountantCategoryList.items.length === 1 &&
      accountantCategoryList.items[0].defualtCategory === true
    ) {
      const fieldData = {
        screenId: screenId,
        categoryFieldId: accountantCategoryList.items[0].id,
        categoryFieldValue: accountantCategoryList.items[0].fieldInternalValue
      };

      console.log("heyyyyy");
      setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
    }
  }, [accountantCategoryList]);

  const defaultValuesOfAccountantForm = useCallback(() => {
    const fieldsDefaultValues:any = {};
    accountantTotalPanels && accountantTotalPanels.forEach((tabLayout:any) => {
      tabLayout?.panels.forEach((panel:any) => {
        panel?.fields.forEach((panelField:any) => {
          const FieldComponent:any = FIELD_TYPE_COMPONENT[panelField.fieldType];
				if (FieldComponent) {
					const { fieldId, id, isFixed, defaultValue, fieldType } =
					panelField;
					const fieldName = getFieldName(fieldId, id, isFixed);
					let fieldDefaultValue = panelField.defaultValue;
					console.log("formLayout indif",panelField)
					console.log("panelField.defaultValue",panelField.defaultValue)
					console.log(
						`Cosmic FlexFieldsFormPanelLayout => Default Values Setting, fieldName: ${fieldName}, Default Value: ${fieldDefaultValue}, Field Type: ${panelField.fieldType}`
					);
					if (fieldType === FieldType.MultiSelectionDropDownList) {
						if (defaultValue) {
							fieldDefaultValue = getMultiSelectionDDLDefaultValue(
								defaultValue as string
							);
						}
					}
					// if the field has a default value
					if (fieldDefaultValue) {
						fieldsDefaultValues[fieldName] = fieldDefaultValue;
					}
				}
        });
      });
    });
    return fieldsDefaultValues;
  }, [accountantTotalPanels, getFieldName]);

  const defaultValuesOfAccountantCategpryForm = useCallback(() => {
    const fieldsDefaultValues:any = {};
    accountantTotalCategories && accountantTotalCategories?.items.forEach((panelField:any) => {
      const FieldComponent:any = FIELD_TYPE_COMPONENT[panelField.fieldType];
      if (FieldComponent) {
        const { fieldId, id, isFixed, defaultValue, fieldType } = panelField;
        const fieldName = getFieldName(fieldId, id, isFixed);
        let fieldDefaultValue = defaultValue;
        console.log(
          `Cosmic CategoryFieldsPanelLayout => Default Values Setting, fieldName: ${fieldName}, Default Value: ${fieldDefaultValue}, Field Type: ${fieldType}`
        );
        if (fieldType === FieldType.MultiSelectionDropDownList) {
          if (defaultValue) {
            fieldDefaultValue = getMultiSelectionDDLDefaultValue(
              defaultValue as string
            );
          }
        }
        // if the field has a default value
        if (fieldDefaultValue) {
          fieldsDefaultValues[fieldName] = fieldDefaultValue;
        }
      }
    });
    return fieldsDefaultValues;
  }, [accountantTotalCategories, getFieldName]);

// const AccountantDefaultValues = useMemo<DefaultValues<Values>>(() => {
//     return () => {
//       const fieldsDefaultValues:any = {};
//       accountantTotalPanels && accountantTotalPanels.forEach((tabLayout:any) => {
//         tabLayout?.panels.forEach((panel:any) => {
//           panel?.fields.forEach((panelField:any) => {
//             const fieldName = getFieldName(panelField.fieldId, panelField.id, panelField.isFixed);
//             const fieldDefaultValue = panelField.defaultValue;
//             if (fieldDefaultValue) {
//               fieldsDefaultValues[fieldName] = fieldDefaultValue;
//             }
//           });
//         });
//       });
//       return fieldsDefaultValues;
//     };
//   }, [accountantTotalPanels, getFieldName]);

useEffect(() => {
  dispatch(
    setSelectedActivityLogActions({
      entityId1: "",
      entityId2: "",
      entityId3: "",
      screenName: ACCOUNTANT_SETUP_SCREEN_SLUG,
      companyName: '',
      activityType:
        "activity_type=create&activity_type=updated&activity_type=delete",
      activityLogVisibility: true,
    })
  );
  dispatch(setSelectedStickyNavBar({isSticky:true}));
}, []);

  return (
    <Grid sx={{ pb: "5rem", "& .MuiAppBar-root":{zIndex:2,top:"65px"}}}>
      <BackdropCircularProgress
        open={
          isSubmitting ||
          isLoadingById ||
          isDeleting ||
          isLoadingAccountantCategories ||
          isLoadingAccountantCategoriesPanels
        }
        testId="accountant Setup"
      />
      <DeleteConfirmationDialog
        open={openDialog}
        deleteActionHandler={deleteAccountantField}
        cancelActionHandler={onCloseHandler}
        testId="accountant-confirmation-dialog"
      />
      {accountantTotalCategories && accountantTotalCategories?.items && accountantTotalCategories.items.length === 1 && accountantTotalCategories.items[0].defualtCategory === true ? (
        <></>
      ) : accountantTotalCategories && accountantTotalCategories.items && accountantTotalCategories.items.length > 0 ? (
        <CategoryFieldsPanelLayout
          categoryFields={
            accountantTotalCategories?.items &&
            categoryFieldsWithOnChangeMethod !== undefined
              ? categoryFieldsWithOnChangeMethod
              : []
          }
          panelLabel={"Accountant Categories"}
          formDefaultValues={defaultValuesOfAccountantCategpryForm()}
          testId={"categoryFields-panel"}
        />
      ):(<></>)}
      <SetupFormLayout
            isLoadingData={isLoading || isLoadingById}
            renderForm={renderForm}
            isSubmittingForm={isSubmitting}
          >
      <FlexFieldsFormPanelLayout
        toolbar={
          <AccountantSetupToolbar
            onResetClicked={onResetClicked}
            onDeleteClicked={onDeleteClicked}
            isSubmitting={isSubmitting}
          />
        }
        onSubmit={onSubmit}
        formLayout={accountantTotalPanels && accountantTotalPanels}
        formDefaultValues={defaultValuesOfAccountantForm()}
        testId={"accountantCategoryAssingedPanels"}
      />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"accountant-snackbar"}
        open={snackbarData.open}
      />
      </SetupFormLayout>
    </Grid>
  );
};

// import {
//   ACCOUNTANT_ACTIVE_STATUS_ID,
//   INITIAL_SNACKBAR_DATA,
//   QUERY_OPTIONS,
// } from "common/constants";
// import {
//   instanceOfIAccountantResponse,
// } from "common/instance-method";
// import { IAccountant } from "models/accountant";
// import { IResponse, IServerError } from "models/common";
// import React, { useEffect, useState } from "react";
// import { useMutation, useQuery, useQueryClient } from "react-query";
// import { useNavigate, useParams } from "react-router-dom";
// import { AccountantService } from "services";

// import {
//   BackdropCircularProgress,
//   CategoryFieldsPanelLayout,
//   DeleteConfirmationDialog,
//   EnhancedSnackbar,
//   FlexFieldsFormPanelLayout,
//   SingleColumnLayout,
//   StandardPanel,
// } from "@websential/cosmic";
// import { FormProvider, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { AccountantSetupValidationSchema } from "./validator";
// import { AccountantSetupToolbar } from "./AccountantSetupToolbar";
// import { AccountantInfoSetupFieldsPanel } from "./Panels/AccountantInfoSetupFieldsPanel";
// import { Grid } from "@mui/material";

// export interface IAccountantSetup {}
// const SINGLE_RECORD_DATA_QUERY_KEY = "accountant-single-data-record";
// const ASSINGED_CATEGORY_DATA_QUERY_KEY = "accountant-category-data-record";
// const ASSINGED_CATEGORY_PANEL_QUERY_KEY = "accountant-category-panel-record";

// const useGetData = (id: string | undefined) => {
//   return useQuery(
//     [SINGLE_RECORD_DATA_QUERY_KEY, id],
//     () => AccountantService.getById(id),
//     QUERY_OPTIONS
//   );
// };

// const useGetAssignedCategoryPanel = (
//   screenId:number|string,
//   categoryFieldId:number|string,
//   categoryFieldValue:number|string
//   ) => {
//   return useQuery(
//     [ASSINGED_CATEGORY_PANEL_QUERY_KEY, screenId,categoryFieldId,categoryFieldValue],
//     () => AccountantService.getAccountantCategoriesAssingedPanels(screenId,categoryFieldId,categoryFieldValue),
//     QUERY_OPTIONS
//   );
// };

// const useGetAccountantCategories = (screenId:string|number) => {
//   return useQuery(
//     [ASSINGED_CATEGORY_DATA_QUERY_KEY,screenId],
//     () => AccountantService.getAccountantCategories(screenId),
//     QUERY_OPTIONS
//   );
// };

// const useScreenLayout: any = () =>
//   useQuery(
//     "accountant-setup-layout",
//     (data) => {
//       return AccountantService.getSetupLayout();
//     },
//     QUERY_OPTIONS
//   );

// const convertObjToString = (str: string) => {
//   const jsonStringPoe = str as string;
//   const modifiedString = jsonStringPoe
//     .replace(/(\w+):/g, '"$1":')
//     .replace(/'/g, '"');
//   return JSON.parse(modifiedString);
// };

// export const AccountantSetup: React.FC<IAccountantSetup> = () => {
//   const navigate = useNavigate();
//   const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
//   const [openDialog, setDialogOpen] = useState(false);
//   // for delete row
//   const [singleDelete, setSingleDelete] = useState<boolean>(false);
//   const [categoryFieldsWithOnChangeMethod, setCategoryFieldsWithOnChangeMethod] = useState<any>(undefined);
//   const [accountantId, setAccountantId] = useState<number>(0);
//   const queryClient = useQueryClient();
//   const { id } = useParams();
//   console.log("params----", id);
//   const [editRecordId, setEditRecordId] = useState(id);
//   let screenId = 22;
//   const [selectedcategoryFieldId, setselectedcategoryFieldId] = useState("");
//   const [selectedcategoryFieldValue, setselectedcategoryFieldValue] = useState("");

//   const { isLoading: isSubmitting, mutate: submitFormFields } = useMutation(
//     AccountantService.create,
//     {
//       onSuccess: (res: IAccountant | IServerError | IResponse | any) => {
//         if (instanceOfIAccountantResponse(res)) {
//           setEditRecordId(res.id);
//           queryClient.invalidateQueries(SINGLE_RECORD_DATA_QUERY_KEY);
//           setSnackbarData({
//             open: true,
//             message: "Data has been submitted successfully!",
//             severity: "success",
//           });
//         }
//         // let path = `/accountants/setup/${res.id}`;
//         let path = `/accountants/list`;
//         navigate(path);
//       },
//       onError: (err) => {
//         // TODO: customize the toast message based on the response
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong, try again later!",
//           severity: "error",
//         });
//       },
//     }
//   );

//   const formMethods = useForm<any>({
//     resolver: yupResolver(AccountantSetupValidationSchema),
//     mode: "onBlur",
//     reValidateMode: "onChange",
//   });

//   const { isLoading: isLoadingById, data: selectedDataRecord } =
//     useGetData(editRecordId);

//   const { isLoading: isLoadingAccountantCategories, data: accountantCategoryList } =
//   useGetAccountantCategories(screenId);

//   const { isLoading: isLoadingAccountantCategoriesPanels, data: accountantCategoryAssingedPanels } =
//   useGetAssignedCategoryPanel(screenId,selectedcategoryFieldId,selectedcategoryFieldValue);

//   console.log("accountantCategoryAssingedPanels",accountantCategoryAssingedPanels)

//   const onSubmit = (formData: any) => {
//     console.log("formData--", formData);
//     // let accountantRequestPayload = {
//     //   id: selectedDataRecord ? selectedDataRecord.id : undefined,
//     //   fixedFields: {
//     //     firstName: formData.firstName,
//     //     lastName: formData.lastName,
//     //     accTitle: formData.accTitle,
//     //     email: formData.email,
//     //     statusId: ACCOUNTANT_ACTIVE_STATUS_ID,
//     //     address: {
//     //       id:selectedDataRecord  ? selectedDataRecord.address[0].id : undefined,
//     //       addressLine1: formData.addressLine1,
//     //       city: formData.city,
//     //       countryId: "1",
//     //       provinceId: convertObjToString(
//     //         formData.province && formData.province
//     //       ).id.toString(),
//     //       postalCode: formData.postalCode,
//     //     },
//     //   },
//     //   metaFields: [
//     //     { fieldId: "10", name: "phoneNo", value: formData.phoneNo },
//     //     { fieldId: "11", name: "ext", value: formData.ext },
//     //     { fieldId: "12", name: "website", value: formData.website },
//     //   ],
//     // };
//     // console.log("accountantRequestPayload----------", accountantRequestPayload);
//     // submitFormFields(accountantRequestPayload);
//   };

//   useEffect(() => {
//     if (selectedDataRecord) {
//       Object.keys(selectedDataRecord).forEach((fieldName) => {
//         const fieldValue = selectedDataRecord[fieldName];
//         formMethods.setValue(fieldName, fieldValue);
//         if (fieldName === "address") {
//           Object.keys(fieldValue).forEach((accountantFieldName) => {
//             const accountantFieldValue = fieldValue[accountantFieldName];
//             formMethods.setValue(accountantFieldName, accountantFieldValue);

//             if (accountantFieldName === "country") {
//               formMethods.setValue(
//                 accountantFieldName,
//                 accountantFieldValue.name ?? undefined
//               );
//             }
//                 if (accountantFieldName === "province") {
//                   let id = accountantFieldValue.id;
//                   let name = accountantFieldValue.name;
//                   let shortCode = accountantFieldValue.shortCode;
//                   formMethods.setValue(
//                     accountantFieldName,
//                     `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
//                   );
//                 }
//           });
//         }
//         if (fieldName === "metaFields") {
//           fieldValue.forEach((obj: any) => {
//             formMethods.setValue(obj.name, obj.value);
//           });
//         }
//       });
//     }
//   }, [selectedDataRecord, formMethods.setValue, formMethods]);

//   const onSnackbarClosed = (
//     _event: React.SyntheticEvent | Event,
//     reason?: string
//   ) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setSnackbarData(INITIAL_SNACKBAR_DATA);
//   };
//   const { isLoading: isDeleting, mutate: deleteAccountantById } = useMutation(
//     AccountantService.deleteById,
//     {
//       onSuccess: (res: string | IServerError | IResponse) => {
//         if (typeof res === "string") {
//           // remove the cache of the Accountant fields since we just added a new field.
//           setSnackbarData({
//             open: true,
//             message: "Record has been deleted successfully.",
//             severity: "success",
//           });
//         } else {
//           setSnackbarData({
//             open: true,
//             message: "Something went wrong, try again later!",
//             severity: "error",
//           });
//         }
//         formMethods.reset();
//         let path = `/accountants/list`;
//         navigate(path);
//       },
//       onError: (err) => {
//         // TODO: customize the toast message based on the response
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong, try again later!",
//           severity: "error",
//         });
//       },
//     }
//   );

//   const deleteAccountantField = () => {
//     if (singleDelete) {
//       setSingleDelete(false);
//       deleteAccountantById(accountantId);
//       setDialogOpen(false);
//     }
//   };

//   const onDeleteClicked = () => {
//     setDialogOpen(true);
//     setSingleDelete(true);
//     if (selectedDataRecord) {
//       setAccountantId(selectedDataRecord.id);
//     }
//   };

//   const onCloseHandler = () => {
//     setDialogOpen(false);
//   };

//   const onResetClicked = () => {
//     setSnackbarData({
//       open: true,
//       message: "Form was reset successfully",
//       severity: "success",
//     });
//     formMethods.reset();
//     let path = `/accountants/setup`;
//     navigate(path);
//   };

//   useEffect(()=>{
//     if(accountantCategoryList){
//       const categoryWithOnChange = accountantCategoryList.items.map((item:any)=>{
//         let updatedCategories:any = []
//         console.log("item--",item)
//         for(let i = 0; i < accountantCategoryList.items.length; i++){
//           if(item.fieldType === "DropDownList"){
//             const onDropDownChanged = (value:any) => {
//               setselectedcategoryFieldId(item.id)
//               setselectedcategoryFieldValue(value.value)
//               // alert(value.value)
//             }
//             item.onChange = onDropDownChanged
//             updatedCategories.push(item);
//           } else if(item.fieldType === "NormalSwitch"){
//             const onSwitchChanged = (value:any) => {
//               setselectedcategoryFieldId(item.id)
//               setselectedcategoryFieldValue(value)
//               // alert(value)
//               console.log("value--",value)
//             }
//             item.onChange = onSwitchChanged
//             updatedCategories.push(item);
//           } else if(item.fieldType === "MultiSelectionDropDownList"){
//             const onMultiSelectionDropDownValueChanged = (value:any) => {
//               let selectedItems:any = []
//               value.map((item:any)=>{
//                 selectedItems.push(item.value)
//               })
//               setselectedcategoryFieldId(item.id)
//               setselectedcategoryFieldValue(selectedItems)
//               // alert(selectedItems)
//             }
//             item.onChange = onMultiSelectionDropDownValueChanged
//             updatedCategories.push(item);
//           } else if(item.fieldType === "Checkbox"){
//             const onCheckBoxValueChanged = (value:any) => {
//               setselectedcategoryFieldId(item.id)
//               setselectedcategoryFieldValue(value)
//               // alert(value)
//             }
//             item.onChange = onCheckBoxValueChanged
//             updatedCategories.push(item);
//           } else if(item.fieldType === "RadioGroup"){
//             const onRadioButtonValueChanged = (e:any) => {
//               setselectedcategoryFieldId(item.id)
//               setselectedcategoryFieldValue(e.target.value)
//               // alert(e.target.value)
//             }
//             item.onChange = onRadioButtonValueChanged
//             updatedCategories.push(item);
//           } else {
//             console.log("inside-- else")
//             updatedCategories.push(item);
//             setselectedcategoryFieldId(item.id)
//             setselectedcategoryFieldValue(item.fieldValue)
//           }
//           return item
//         }
//       })
//       console.log("categoryWithOnChange",categoryWithOnChange)
//       setCategoryFieldsWithOnChangeMethod(categoryWithOnChange)
//     }
//   },[accountantCategoryList])

//   return (
//     <>
//     <Grid sx={{ pb: "5rem", "& .MuiAppBar-root":{zIndex:0}}}>
//       <BackdropCircularProgress
//         open={isSubmitting || isLoadingById || isDeleting || isLoadingAccountantCategories || isLoadingAccountantCategoriesPanels}
//         testId="accountant Setup"
//       />
//       <DeleteConfirmationDialog
//         open={openDialog}
//         deleteActionHandler={deleteAccountantField}
//         cancelActionHandler={onCloseHandler}
//         testId="accountant-confirmation-dialog"
//       />
//       {accountantCategoryList && accountantCategoryList.items ? (
//           <CategoryFieldsPanelLayout categoryFields={accountantCategoryList.items && categoryFieldsWithOnChangeMethod !== undefined ?  categoryFieldsWithOnChangeMethod : []} panelLabel={"Accountant Categories"} testId={"categoryFields-panel"} />
//         ) : (<></>)}
//       <FormProvider {...formMethods}>
//         <form onSubmit={formMethods.handleSubmit(onSubmit)}>
//           <SingleColumnLayout
//             children={
//               <StandardPanel title="Accountant" testId={`accountant-info`}>
//                 <AccountantInfoSetupFieldsPanel />
//               </StandardPanel>
//             }
//             toolbar={
//               <AccountantSetupToolbar
//                 onResetClicked={onResetClicked}
//                 onDeleteClicked={onDeleteClicked}
//                 isSubmitting={isSubmitting}
//               />
//             }
//             testId={"accountant-setup-layout"}
//           />
//         </form>
//           {accountantCategoryAssingedPanels && accountantCategoryAssingedPanels.panels ? (
//           <FlexFieldsFormPanelLayout onSubmit={onSubmit} formLayout={accountantCategoryAssingedPanels} testId={"accountantCategoryAssingedPanels"} />
//         ) : (<></>)}
//       </FormProvider>
//       <EnhancedSnackbar
//         message={snackbarData.message}
//         onCloseHandler={onSnackbarClosed}
//         severity={snackbarData.severity}
//         testId={`snackbar`}
//         open={snackbarData.open}
//       />
//       </Grid>
//     </>
//   );
// };

// old Code with tab form

// import {
//   ACCOUNTANT_ACTIVE_STATUS_ID,
//   INITIAL_SNACKBAR_DATA,
//   QUERY_OPTIONS,
// } from "common/constants";
// import { AddressFields, FlexFieldsUtils } from "common/FlexFieldsUtils";
// import {
//   instanceOfIAccountant,
//   instanceOfIAccountantResponse,
// } from "common/instance-method";
// import { SetupFormLayout } from "layouts/SetupFormLayout";
// import { IAccountant } from "models/accountant";
// import { IResponse, IServerError } from "models/common";
// import React, { useEffect, useState } from "react";
// import { useMutation, useQuery, useQueryClient } from "react-query";
// import { useParams } from "react-router-dom";
// import { AccountantService } from "services";

// import {
//   EnhancedSnackbar,
//   IPanel,
//   ITabLayout,
//   RHookFormGenerator,
// } from "@websential/cosmic";
// import IField from "@websential/cosmic/build/components/RHookFormGenerator/types/IField";

// export interface IAccountantSetup {}
// const SINGLE_RECORD_DATA_QUERY_KEY = "accountant-single-data-record";

// const useGetData = (id: string | undefined) => {
//   return useQuery(
//     [SINGLE_RECORD_DATA_QUERY_KEY, id],
//     () => AccountantService.getById(id),
//     QUERY_OPTIONS
//   );
// };

// const useScreenLayout: any = () =>
//   useQuery(
//     "accountant-setup-layout",
//     (data) => {
//       return AccountantService.getSetupLayout();
//     },
//     QUERY_OPTIONS
//   );

// export const AccountantSetup: React.FC<IAccountantSetup> = () => {
//   const [metaFieldIds, setMetaFieldIds] = useState({});
//   const [renderForm, setRenderForm] = useState(false);
//   const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
//   const queryClient = useQueryClient();
//   const { id } = useParams();
//   console.log("params----", id);
//   const [editRecordId, setEditRecordId] = useState(id);

//   const { isLoading: isSubmitting, mutate: submitFormFields } = useMutation(
//     AccountantService.create,
//     {
//       onSuccess: (res: IAccountant | IServerError | IResponse) => {
//         if (instanceOfIAccountantResponse(res)) {
//           setEditRecordId(res.id);
//           queryClient.invalidateQueries(SINGLE_RECORD_DATA_QUERY_KEY);
//           setSnackbarData({
//             open: true,
//             message: "Data has been submitted successfully!",
//             severity: "success",
//           });
//         }
//       },
//       onError: (err) => {
//         // TODO: customize the toast message based on the response
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong, try again later!",
//           severity: "error",
//         });
//       },
//     }
//   );

//   const { isLoading, data: screenFields } = useScreenLayout();

//   const { isLoading: isLoadingById, data: selectedDataRecord } =
//     useGetData(editRecordId);

//   const onSubmit = (values: any) => {
//     console.log("values : ",values)
//     console.log("Metaids : ",metaFieldIds)
//     let payload = FlexFieldsUtils.formatFixedAndMetaFields(
//       values,
//       metaFieldIds
//     );
//     payload.id = editRecordId;
//     payload.fixedFields["statusId"] = ACCOUNTANT_ACTIVE_STATUS_ID;

//     console.log("=======", payload);
//     console.log("=======String", JSON.stringify(payload));
//     submitFormFields(payload);
//   };

//   useEffect(() => {
//     console.log("#####=>useEffect screenFields", screenFields);
//     console.log("#####=>selectedDataRecord", selectedDataRecord);
//     if (id) {
//       if (
//         screenFields &&
//         Array.isArray(screenFields) &&
//         selectedDataRecord &&
//         instanceOfIAccountant(selectedDataRecord)
//       ) {
//         let metaFieldIdsObject: { [key: string]: string } = {};
//         screenFields.forEach((screenTab: ITabLayout, index: number) => {
//           screenTab.panels.forEach((screenPanel: IPanel) => {
//             screenPanel.fields.forEach((field: IField) => {
//               let isAddressField = AddressFields.find(
//                 (element) => element === field.fieldId
//               );
//               console.log(
//                 "#####=>selectedDataRecord.hasOwnProperty(field.fieldId) - ",
//                 field.fieldId,
//                 " - isAddressField - ",
//                 isAddressField,
//                 selectedDataRecord.hasOwnProperty(field.fieldId)
//               );

//               if (
//                 field.isFixed &&
//                 selectedDataRecord.hasOwnProperty(field.fieldId) &&
//                 !isAddressField
//               ) {
//                 type objectKey = keyof typeof selectedDataRecord;
//                 let fieldValue = field.fieldId as objectKey;
//                 field.defaultValue = String(selectedDataRecord[fieldValue]);
//               } else if (field.isFixed && isAddressField) {
//                 let addressObject = selectedDataRecord.address;
//                 type addressObjectKey = keyof typeof addressObject;
//                 let addressFieldKey = field.fieldId as addressObjectKey;
//                 field.defaultValue = addressObject[addressFieldKey];

//                 if (
//                   String(addressFieldKey) === "country" ||
//                   String(addressFieldKey) === "countryId"
//                 ) {
//                   console.log("******", addressObject.country);
//                   field.defaultValue = String(addressObject.country.id);
//                 } else if (
//                   String(addressFieldKey) === "province" ||
//                   String(addressFieldKey) === "provinceId"
//                 ) {
//                   console.log("******", addressObject.province);
//                   field.defaultValue = String(addressObject.province.id);
//                 }
//               } else if (!field.isFixed) {
//                 console.log("meta field: ", field.fieldId);
//                 let metaFields = selectedDataRecord.metaFields;
//                 let metaField = metaFields.find(
//                   (element) => element.name === field.fieldId
//                 );
//                 if (metaField) {
//                   metaFieldIdsObject[field.fieldId] = metaField.id;
//                   field.defaultValue = metaField.value;
//                 }
//               } else {
//                 console.log("field not found: ", field.fieldId);
//               }
//             });
//           });

//           // Show form in edit mode when all data is loaded
//           if (index === screenFields.length - 1) {
//             setRenderForm(true);
//           }
//         });

//         setMetaFieldIds(metaFieldIdsObject);
//       }
//     } else {
//       // Show form in add mode
//       setRenderForm(true);
//     }
//   }, [id, screenFields, selectedDataRecord]);

//   const onSnackbarClosed = (
//     _event: React.SyntheticEvent | Event,
//     reason?: string
//   ) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setSnackbarData(INITIAL_SNACKBAR_DATA);
//   };

//   return (
//     <SetupFormLayout
//       isLoadingData={isLoading || isLoadingById}
//       renderForm={renderForm}
//       isSubmittingForm={isSubmitting}
//     >
//       <RHookFormGenerator
//         formLayout={screenFields}
//         onSubmit={onSubmit}
//         testId="accountant-setup-form"
//       />
//       <EnhancedSnackbar
//         message={snackbarData.message}
//         onCloseHandler={onSnackbarClosed}
//         severity={snackbarData.severity}
//         testId={"accountant-snackbar"}
//         open={snackbarData.open}
//       />
//     </SetupFormLayout>
//   );
// };
