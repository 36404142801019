import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useState } from "react";
import { Link, MemoryRouter } from "react-router-dom";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IAppState } from "store/reducers";

import { Box, Chip, Grid, useTheme } from "@mui/material";
import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  RouterLinkButton,
} from "@websential/cosmic";
import { format } from "date-fns";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions/invoiceReportActions";
import { PrimeDataGridInvoiceReport } from "./PrimeDataGridInvoiceReport";
import { INITIAL_PAGE_SIZE, INITIAL_SNACKBAR_DATA, INVOICE_REPORT_SCREEN_SLUG } from "common/constants";
import FileSaver from "file-saver";
import { PrintingService } from "services/PrintingService";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { ClientService } from "services";
import stripeImage from './StripeBlurple.svg';

export interface IInvoiceReport {}
const INVOICE_REPORT_LIST_QUERY_KEY = "invoice-report"

// const useInvoiceReports = (
//   clientId: string,
//   taxYear: number,
//   params: IGetListRequest
// ) =>
//   useQuery(
//     ["invoice-report", clientId, taxYear, params],
//     (data) => {
//       return ReportsService.getInvoiceReport(clientId, taxYear, params);
//     },
//     { refetchOnWindowFocus: false }
//   );

const SINGLE_RECORD_DATA_QUERY_KEY = "client-single-data-record";

const useGetCompanyData = (id: string | undefined) => 
  useQuery(
    [SINGLE_RECORD_DATA_QUERY_KEY, id],
    (data) => {
      return ClientService.getById(id);
    },
    { refetchOnWindowFocus: false }
  );

export const InvoiceReport: React.FC<IInvoiceReport> = () => {
  const dispatch = useDispatch();
  // const theme = useTheme();
  // const navigate = useNavigate();
  const { id } = useSelector((state: IAppState) => state.client);
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [resInvoiceReport, setResInvoiceReport] = useState<any>(undefined);

  const [invoiceList, setInvoiceList] = useState<any>(undefined);
  const [isLoadingInvoiceList, setIsLoadingInvoiceList] = useState<any>(false);
  const [invoiceListUpdatedItems, setInvoiceListUpdatedItems] =
    useState<any>(undefined);
  const [invoiceListUpdatedColumns, setInvoiceListUpdatedColumns] =
    useState<any>(undefined);
  const [invoicePaymentMethod, setinvoicePaymentMethod] = useState<any>(undefined);
  const [invoicePaymentFor, setInvoicePaymentFor] = useState("10")


  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "invoiceNo",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  // const { isLoading, data } = useInvoiceReports(id,taxYear,queryParams); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  // const invoiceReportList: any = data;
  // console.log("@@@@@ => invoiceReportList", invoiceReportList);

  // useEffect(() => {
  //   setIsLoadingInvoiceList(true);
  //   setTimeout(() => {
  //     setIsLoadingInvoiceList(false);
  //   }, 2000);
  // }, []);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(id);

  console.log("companyGetByIdRecord",companyGetByIdRecord)

  useEffect(()=>{
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentMethod = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'payment_method')
      console.log("paymentMethodpaymentMethod",parseInt(paymentMethod.value))
      setinvoicePaymentMethod(parseInt(paymentMethod.value))

      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor? paymentFor.value : 10)
    }
  },[companyGetByIdRecord])

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: requestParams.sortField,
      order_direction: requestParams.sortOrder
        ? requestParams.sortOrder
        : "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
    };
    // if (requestParams.sort) {
    //   const [sortBy, sortDirection] = requestParams.sort.split(",");
    //   dispatchParams.order_by = toSnake(sortBy);
    //   dispatchParams.order_direction = sortDirection;
    // }
console.log("dispatchParams",dispatchParams)
    setQueryParams(dispatchParams);
  }, []);

  const onClickHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };
  const onUnPaidClickHandler = (clickedRow: any,tag:string) => {
    const fetchData = async () => {
      try {
        setIsLoadingData(true);
        const response: any =
        await ReportsService.paypalIntegrationOfUnpaidInvoice(id,clickedRow.refNo,tag);
        console.log("responseData-----------------",response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const formatDate = (datee: any) => {
    if(datee !== '' && datee !== null){
    const originalDate = new Date(datee);

    // Extract date components
    // const year = date.getFullYear();
    // const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to the month because it's zero-based
    // const day = String(date.getDate()).padStart(2, "0");

    // // Create the formatted date string
    // const formattedDate = `${year}-${month}-${day}`;

    const formattedDate = format(originalDate, "MMM dd, yyyy");

    return formattedDate;
    } else {
      return ''
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // if(invoiceReportList){
      setIsLoadingInvoiceList(true);
      try {
        const response: any = await ReportsService.getInvoiceReport(
          id,
          taxYear,
          invoicePaymentFor,
          queryParams
        );
        console.log("responseData-----------------", response);
        setResInvoiceReport(response)
        const updatedColumnsResponse = response.headerColumns.map(
          (item: any) => ({
            ...item,
            renderCell:
              item.field === "paid" ? (params: any) => params.value : undefined,
          })
        );

        const updatedRows = response.items.map((row: any) => {
          const formattedinvoiceDate = formatDate(row.invoiceDate);
          const formattedpaymentDate = formatDate(row.paymentDate);
          console.log("item.formattedDate", formattedpaymentDate);
          if (row.paid !== false && row.invoiceNo) {
            return {
              ...row,
              paid: (
                <span style={{ marginRight: "10px", color: "green" }}>
                  Paid
                </span>
              ),
              invoiceNo: (
                <Link
                  to={"/reports/invoice-detail"}
                  className="no-underline"
                  style={{ color: "blue" }}
                  onClick={() => onClickHandler(row)}
                >
                  {row.invoiceNo}
                </Link>
              ),
              invoiceDate: formattedinvoiceDate,
              paymentDate: formattedpaymentDate,
            };
          } else {
            return {
              ...row,
              paid: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor:"pointer"
                  }}
                  // onClick={() => onUnPaidClickHandler(row)}
                >
                  <span style={{ marginRight: "10px", color: "red" }}>
                    Not Paid
                  </span>
                  {invoicePaymentMethod && invoicePaymentMethod === 30 ? 
                    <img src={stripeImage} alt="Stripe" style={{height:"38px"}} onClick={() => onUnPaidClickHandler(row,'stripe')}/>
                    : invoicePaymentMethod && invoicePaymentMethod === 10 ? 
                    <span style={{ color: "#ED6C02", fontWeight:"bold" }}>
                        Funds Transfer
                    </span>
                  : invoicePaymentMethod && invoicePaymentMethod === 20 ?
                  <img src="https://www.paypalobjects.com/en_US/i/btn/btn_xpressCheckout.gif" onClick={() => onUnPaidClickHandler(row,'paypal')}/>
                  : <></>
                  }
                  {/* <Link to={`#`} onClick={onUnPaidClickHandler}> */}
                  {/* </Link> */}
                </div>
              ),
              invoiceNo: (
                <Link
                  to={"/reports/invoice-detail"}
                  className="no-underline"
                  style={{ color: "blue" }}
                  onClick={() => onClickHandler(row)}
                >
                  {row.invoiceNo}
                </Link>
              ),
              invoiceDate: formattedinvoiceDate,
              paymentDate: formattedpaymentDate,
            };
          }
        });

        console.log("updatedColumnsResponse", updatedColumnsResponse);
        setInvoiceList(response);
        setInvoiceListUpdatedColumns(updatedColumnsResponse);
        setInvoiceListUpdatedItems(updatedRows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }finally {
        setIsLoadingInvoiceList(false);
      }
      // }
    };
    console.log("invoiceListUpdatedColumns", invoiceListUpdatedColumns);

    fetchData();
  }, [queryParams,invoicePaymentMethod]);

  // const { message, componentState } = useSelector(
  //   (state: IAppState) => state.viewState
  // );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  // const [openDialog, setDialogOpen] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  // const [statusValue, setStatusValue] = useState("");
  // const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);

  // const onRequestParamsChange = useCallback((requestParams) => {
  //   console.log("##### - request params", requestParams);
  //   let dispatchParams = {
  //     search_query: requestParams.debouncedSearchQuery,
  //     order_by: "role_name",
  //     order_direction: "asc",
  //     page: requestParams.currentPage + 1,
  //     size: requestParams.pageSize,
  //   };
  //   if (requestParams.sort) {
  //     const [sortBy, sortDirection] = requestParams.sort.split(",");
  //     dispatchParams.order_by = toSnake(sortBy);
  //     dispatchParams.order_direction = sortDirection;
  //   }

  //   setQueryParams(dispatchParams);
  // }, []);
  const savePdfAsFile = (pdfData: any, filename: any) => {
    const blob = new Blob([pdfData], { type: "application/pdf" });
    FileSaver.saveAs(blob, filename);
  };

  const onRowPrintClicked = useCallback((params) => {
    const fetchData = async () => {
      setIsLoadingData(true)
    try {
      const response: any = await PrintingService.invoicePdfPring(
        id, //clientId
        params.refNo, //refNo
      );
      const filename = "Invoice.pdf";
      savePdfAsFile(response, filename);
      setIsLoadingData(false)
    } catch (err) {
      console.log("err", err);
      alert(err);
      setIsLoadingData(false)
    }
  };
  fetchData();

    // alert(`Edit row with ID: ${params.row} has been clicked!`);
    // let path = `/invoices/setup/${params.row}`;
    // navigate(path);
    // console.log("params.row", params);
    // console.log("params.refNo", params.refNo);
    // console.log("params.row", params.invoiceNo);
    // console.log("params.row", params.invoiceNo.props.children);

    // let invoicePrintPayload = {
    //   invoiceNo: params.invoiceNo.props.children,
    //   refNo: params.refNo,
    //   invAmount: params.invAmount,
    //   netAmount: params.netAmount,
    //   invoiceDate: params.invoiceDate,
    //   paidAmount: params.paidAmount,
    //   paymentDate: params.paymentDate,
    //   tax: params.tax,
    //   paid: params.paid.props.children !== "Paid" ? false : true,
    //   discount: params.discount,
    //   handlingCharges: params.handlingCharges,
    //   id: params.id,
    // };
    // console.log("invoicePrintPayload", invoicePrintPayload);
  }, []);

  // const onCloseHandler = () => {
  //   setDialogOpen(false);
  // };

  // const callAction = () => {
  //   if (apiAction === "delete") {
  //     alert(`Delete row with ID: ${selectedIds} has been clicked!`);
  //     // TODO api call for delete
  //   } else if (apiAction === "updateStatus") {
  //     alert(`action need to trigger : ${apiAction}`);
  //     // TODO api call for update status
  //   }
  //   setDialogOpen(false);
  // };

  // const onRowDeleteClicked = useCallback((params) => {
  //   setDialogOpen(true);
  //   setApiAction("delete");
  //   setSelectedIds(params.row.id);
  //   alert(`Delete row with ID: ${params.row.id} has been clicked!`);
  // }, []);

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setStatusValue(event.target.value as string);
  //   setApiAction("updateStatus");
  //   setDialogOpen(true);
  // };

  // const selectionToolbar = useCallback((selectedItems) => {
  //   setSelectedIds(selectedItems);
  //   if (selectedItems.length) {
  //     return (
  //       <Box sx={{ marginTop: theme.spacing(1) }}>
  //         {"Bulk Action Drop Down List"}
  //         {/* <EnhancedDDLBulkAction
  //           label="Status"
  //           value={statusValue}
  //           onChangeHandler={handleChange}
  //           options={[
  //             { name: "Active", value: "1" },
  //             { name: "Inactive", value: "2" },
  //           ]}
  //           testId="status-dd"
  //         /> */}
  //       </Box>
  //     );
  //   }
  //   return (
  //     <Box sx={{ marginTop: theme.spacing(1) }}>
  //       Calendar Filter Here
  //       {/* <RouterLinkButton to='/lookup-fields/setup' text="Add Lookup Field" /> */}
  //     </Box>
  //   );
  // }, []);

  //find the index of object from headerColumns to add link on given column
  // if (accountantList?.headerColumns) {
  //   const linkObjIndex = accountantList.headerColumns.findIndex(
  //     (obj: { field: any; }) => obj.field === accountantList.keyField.field
  //   );
  //   accountantList.headerColumns[linkObjIndex] = {
  //     field: accountantList.headerColumns[linkObjIndex].field,
  //     headerName: accountantList.headerColumns[linkObjIndex].headerName,
  //     flex: 1,
  //     renderCell: (params: any) => {
  //       var urlLink = accountantList.keyField.templateUrl;
  //       var newLink = urlLink.replace("{id}", params.row.id);

  //       return (
  //         <Link to={newLink} className={classes.textLink}>
  //           {params.row.accTitle}
  //         </Link>
  //       );
  //     },
  //   };
  //   const statusObjIndex = accountantList.headerColumns.findIndex(
  //     (obj: { field: string; }) => obj.field === "status"
  //   );
  //   accountantList.headerColumns[statusObjIndex] = {
  //     field: "status",
  //     headerName: "Status",
  //     flex: 1,
  //     width: 50,
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params: any) => (
  //       <Chip label={params.value} />
  //     ),
  //   };
  // }

  // if (isLoading) {
  //   return (
  //     <CircularProgress
  //       size={24}
  //       data-testid="circular-progress"
  //       className={classes.loader}
  //     />
  //   );
  // }

  // const onKeyFieldClickHandler = (clickedRow: any) => {
  //   dispatch(setSelectedINVOICE(clickedRow));
  //   console.log("clickedRow",clickedRow)
  // };

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:INVOICE_REPORT_SCREEN_SLUG,
      companyName:clientName,
      activityType:"activity_type=print",
      activityLogVisibility:true
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[])

  return emptyList ? (
    <EmptyAccountantList />
  ) : (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
      <BackdropCircularProgress
          open={
            isLoadingData
          }
          testId='invoice-report'
        />
      <PrimeDataGridInvoiceReport
        rows={invoiceListUpdatedItems && invoiceListUpdatedItems}
        columns={
          invoiceList && invoiceListUpdatedColumns
            ? invoiceListUpdatedColumns
            : []
        }
        showIndexColumn={true}
        showChecboxColumn={false}
        showSettingColumn={true}
        onRowPrintClicked={onRowPrintClicked}
        isLoading={isLoadingClientDataById || isLoadingInvoiceList}
        onSnackbarMessageData={(value: any) => setSnackbarData(value)}
        totalRecords={resInvoiceReport && resInvoiceReport.total ? resInvoiceReport.total : 0}
        initialPageSize={INITIAL_PAGE_SIZE}
        onRequestParamsChange={onRequestParamsChange}
      />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`invoice-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
  // switch (componentState) {
  //   case ComponentState.LOADING:
  //     return (
  //       <CircularProgress
  //         size={24}
  //         data-testid="circular-progress"
  //         className={classes.loader}
  //       />
  //     );
  //   case ComponentState.ERROR:
  //     return (
  //       <div>Error loading component data</div>
  //       // <ErrorState
  //       //   testId="error-container"
  //       //   message={message}
  //       //   onClick={() => {
  //       //     clearError();
  //       //     let dispatchParams = {
  //       //       search_query: "",
  //       //       order_by: "acc_title",
  //       //       order_direction: "asc",
  //       //       page: 1,
  //       //       size: 50,
  //       //     };
  //       //     dispatch(loadAccountantListRequest(dispatchParams));
  //       //   }}
  //       // />
  //     );
  //   case ComponentState.DATA:
  //     return emptyList ? (
  //       <EmptyAccountantList />
  //     ) : (
  //       <Grid
  //         item
  //         xs={12}
  //         sm={12}
  //         md={12}
  //         lg={12}
  //         xl={12}
  //         className={classes.list}
  //       >
  //         {/* <EnhancedDialog
  //           dialogTitle="Please Confirm"
  //           key="dialog-box"
  //           testId="dialog"
  //           onCloseHandler={onCloseHandler}
  //           open={openDialog}
  //           dialogContent={
  //             <Typography variant="subtitle1">Are you sure?</Typography>
  //           }
  //           dialogAction={
  //             <Grid>
  //               <Button
  //                 autoFocus
  //                 variant="contained"
  //                 onClick={onCloseHandler}
  //                 color="default"
  //                 className={classes.button}
  //               >
  //                 Cancel
  //               </Button>
  //               <Button
  //                 autoFocus
  //                 variant="contained"
  //                 onClick={callAction}
  //                 color="primary"
  //                 className={classes.button}
  //               >
  //                 ok
  //               </Button>
  //             </Grid>
  //           }
  //         /> */}
  //         <EnhancedDataGrid
  //           columns={accountantList ? accountantList.headerColumns : []}
  //           rows={accountantList?.items}
  //           selectionEnabled={true}
  //           autoHeight={true}
  //           allowSearching={true}
  //           rowsPerPageOptions={[10, 25, 50, 100]}
  //           selectionToolbar={selectionToolbar}
  //           onRowPrintClicked={onRowPrintClicked}
  //           onRowDeleteClicked={onRowDeleteClicked}
  //           initialPageSize={accountantList?.size}
  //           disableColumnMenu={true}
  //           showRowActionsColumn={true}
  //           autoPageSize={true}
  //           showIndexColumn={true}
  //           testId="accountant-list-grid"
  //           sortingMode="server"
  //           filterMode="server"
  //           paginationMode="server"
  //           onRequestParamsChange={onRequestParamsChange}
  //           totalRowsCount={accountantList?.total}
  //           isLoading={false}
  //           rowIdGetter={(row) => row.id}
  //           searchDebouncingDelay={2000}
  //           shouldMask={false}
  //         />
  //       </Grid>
  //     );
  //   default:
  //     return null;
  // }
};

// // export const AccountantListContent: React.FC<IAccountantList> = () => {
// //   return (<div>This Accountant List Content Page</div>);
// // }
