import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { BackdropCircularProgress, EnhancedDropDown, EnhancedDropDownRHFWithLabel, EnhancedSnackbar, IEnhancedDropDownItem, IResponse, IServerError, LabelPosition, StandardPanel, TopRowTwoColumnLayout } from "@websential/cosmic";
import { INITIAL_SNACKBAR_DATA } from "common/constants";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { TaxSlipYearService } from "services";
import { IGetListRequest } from "models";
import { PDFFilesToolbar } from "./PDFFilesToolbar";
import { PDFSettingsService } from "services/PDFSettingsService";
import SlipsPanel from "./Panels/SlipsPanel";
import SummariesPanel from "./Panels/SummariesPanel";
import { yupResolver } from "@hookform/resolvers/yup";
import { PDFFilesValiadations } from "./validator";

const useYears = (params: IGetListRequest) =>
    useQuery(
      ["years-list", params],
      (data) => {
        return TaxSlipYearService.getList(params);
      },
      { refetchOnWindowFocus: false }
    );
const useGetPDFFilesByTaxYear = (taxYear: number) =>
    useQuery(
      ["get-pdf-files", taxYear],
      (data) => {
        return PDFSettingsService.getPDFFilesByTaxYear(taxYear);
      },
      { refetchOnWindowFocus: false }
    );
    
export interface IPDFFiles {}

export interface IPDFFilesPayload {
    year?: number ,
    t4Pdf?: string ,
    t4aPdf?: string,
    rl1Pdf?: string,
    t4SummaryPdf?: string,
    t4aSummaryPdf?: string,
    rl1SummaryEnglishPdf?: string,
    rl1SummaryFrenchPdf?: string,
    t5Pdf?: string,
    t5SummaryPdf?: string,
    rl3Pdf?: string,
    rl3SummaryPdf?: string,
    t3Pdf?: string,
    t3SummaryPdf?: string,
    rl16Pdf?: string,
    rl16SummaryPdf?: string,
}

export const PDFFiles: React.FC<IPDFFiles> = () => {
  const dispatch = useDispatch();

    const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
    const [selectedYear, setSelectedYear] = useState<any>(undefined);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const [t4PdfFileName, setT4PdfFileName] = useState<string>('');
    const [t4PdfSavedFile, setT4SavedFile] = useState<boolean>(false);
    const [t4PdfSavedFileUrl, setT4SavedFileUrl] = useState<any>(undefined);

    const [t4aPdfFileName, setT4APdfFileName] = useState<string>('');
    const [t4aPdfSavedFile, setT4ASavedFile] = useState<boolean>(false);
    const [t4aPdfSavedFileUrl, setT4ASavedFileUrl] = useState<any>(undefined);

    const [rl1PdfFileName, setRL1PdfFileName] = useState<string>('');
    const [rl1PdfSavedFile, setRL1SavedFile] = useState<boolean>(false);
    const [rl1PdfSavedFileUrl, setRL1SavedFileUrl] = useState<any>(undefined);

    const [t5PdfFileName, setT5PdfFileName] = useState<string>('');
    const [t5PdfSavedFile, setT5SavedFile] = useState<boolean>(false);
    const [t5PdfSavedFileUrl, setT5SavedFileUrl] = useState<any>(undefined);
    
    const [rl3PdfFileName, setRL3PdfFileName] = useState<string>('');
    const [rl3PdfSavedFile, setRL3SavedFile] = useState<boolean>(false);
    const [rl3PdfSavedFileUrl, setRL3SavedFileUrl] = useState<any>(undefined);

    const [t3PdfFileName, setT3PdfFileName] = useState<string>('');
    const [t3PdfSavedFile, setT3SavedFile] = useState<boolean>(false);
    const [t3PdfSavedFileUrl, setT3SavedFileUrl] = useState<any>(undefined);
    
    const [rl16PdfFileName, setRL16PdfFileName] = useState<string>('');
    const [rl16PdfSavedFile, setRL16SavedFile] = useState<boolean>(false);
    const [rl16PdfSavedFileUrl, setRL16SavedFileUrl] = useState<any>(undefined);

    const [t4SummaryPdfFileName, setT4SummaryPdfFileName] = useState<string>('');
    const [t4SummaryPdfSavedFile, setT4SummarySavedFile] = useState<boolean>(false);
    const [t4SummaryPdfSavedFileUrl, setT4SummarySavedFileUrl] = useState<any>(undefined);

    const [t4aSummaryPdfFileName, setT4ASummaryPdfFileName] = useState<string>('');
    const [t4aSummaryPdfSavedFile, setT4ASummarySavedFile] = useState<boolean>(false);
    const [t4aSummaryPdfSavedFileUrl, setT4ASummarySavedFileUrl] = useState<any>(undefined);

    const [rl1SummaryEnglishPdfFileName, setRL1SummaryEnglishPdfFileName] = useState<string>('');
    const [rl1SummaryEnglishPdfSavedFile, setRL1SummaryEnglishSavedFile] = useState<boolean>(false);
    const [rl1SummaryEnglishPdfSavedFileUrl, setRL1SummaryEnglishSavedFileUrl] = useState<any>(undefined);
    const [rl1SummaryFrenchPdfFileName, setRL1SummaryFrenchPdfFileName] = useState<string>('');
    const [rl1SummaryFrenchPdfSavedFile, setRL1SummaryFrenchSavedFile] = useState<boolean>(false);
    const [rl1SummaryFrenchPdfSavedFileUrl, setRL1SummaryFrenchSavedFileUrl] = useState<any>(undefined);

    const [t5SummaryPdfFileName, setT5SummaryPdfFileName] = useState<string>('');
    const [t5SummaryPdfSavedFile, setT5SummarySavedFile] = useState<boolean>(false);
    const [t5SummaryPdfSavedFileUrl, setT5SummarySavedFileUrl] = useState<any>(undefined);
    
    const [rl3SummaryPdfFileName, setRL3SummaryPdfFileName] = useState<string>('');
    const [rl3SummaryPdfSavedFile, setRL3SummarySavedFile] = useState<boolean>(false);
    const [rl3SummaryPdfSavedFileUrl, setRL3SummarySavedFileUrl] = useState<any>(undefined);

    const [t3SummaryPdfFileName, setT3SummaryPdfFileName] = useState<string>('');
    const [t3SummaryPdfSavedFile, setT3SummarySavedFile] = useState<boolean>(false);
    const [t3SummaryPdfSavedFileUrl, setT3SummarySavedFileUrl] = useState<any>(undefined);
    
    const [rl16SummaryPdfFileName, setRL16SummaryPdfFileName] = useState<string>('');
    const [rl16SummaryPdfSavedFile, setRL16SummarySavedFile] = useState<boolean>(false);
    const [rl16SummaryPdfSavedFileUrl, setRL16SummarySavedFileUrl] = useState<any>(undefined);

    const {
        isLoading: isLoadingYearsList,
        data: yearsDDLList,
      } = useYears({
        search_query: "",
        order_by: "client_name",
        order_direction: "asc",
        page: 1,
        size: 25,
      });
    const {
        isLoading: isLoadingPdfFilesData,
        data: pdfFilesData,
      } = useGetPDFFilesByTaxYear(selectedYear);


    const yearsDDLItems = useMemo(() => {
        if (yearsDDLList && Array.isArray(yearsDDLList && yearsDDLList.items)) {
          return (
            yearsDDLList &&
            yearsDDLList.items.map((year: any) => ({
              id: year.id,
              label: year.year,
              value: year.year,
            }))
          );
        }
    
        // Return empty array in case of API data fetching error
        return [];
      }, [yearsDDLList]);

      console.log("yearsDDLItems",yearsDDLItems)
      console.log("yearsDDLItems",selectedYear)

      useEffect(()=>{
        if(yearsDDLItems && yearsDDLItems[0]){
          setSelectedYear(yearsDDLItems[0].value)
        }
      },[yearsDDLItems])

const onSnackbarClosed = (
  event: React.SyntheticEvent | Event,
  reason?: string
) => {
  if (reason === "clickaway") {
    return;
  }
  setSnackbarData(INITIAL_SNACKBAR_DATA);
};
const formMethods = useForm<any>({
    resolver: yupResolver(PDFFilesValiadations),
    mode: "onBlur",
    reValidateMode: "onChange",
    // defaultValues: DEFAULT_VALUES,
  });

  const { isLoading: isSubmitting, mutate: submitFlexFields } = useMutation(
    PDFSettingsService.createPDFFiles,
    {
      onSuccess: (
        res:  IServerError | IResponse | any
      ) => {
          setSnackbarData({
            open: true,
            message: res.message ? res.message : "Something went wrong!",
            severity: res.status === false ? 'error' : "success",
          });
    },
    onError: (err: any) => {
      console.log("resonce for Delete row err", err);
      // TODO: customize the toast message based on the response
      setSnackbarData({
        open: true,
        // message: "Something went wrong, try again later!",
        message: err.message,
        severity: "error",
      });
    },
}
);


const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const onSubmit = async (formData: any) => {
    console.log("formData--",formData)

    if(
      formData.t4Pdf && formData.t4Pdf.length === 0 &&
      formData.t4aPdf && formData.t4aPdf.length === 0 &&
      formData.rl1Pdf && formData.rl1Pdf.length === 0 &&
      formData.t5Pdf && formData.t5Pdf.length === 0 &&
      formData.rl3Pdf && formData.rl3Pdf.length === 0 &&
      formData.t3Pdf && formData.t3Pdf.length === 0 &&
      formData.rl16Pdf && formData.rl16Pdf.length === 0 &&
      formData.t4SummaryPdf && formData.t4SummaryPdf.length === 0 &&
      formData.t4aSummaryPdf && formData.t4aSummaryPdf.length === 0 &&
      formData.rl1SummaryEnglishPdf && formData.rl1SummaryEnglishPdf.length === 0 &&
      formData.rl1SummaryFrenchPdf && formData.rl1SummaryFrenchPdf.length === 0 &&
      formData.t5SummaryPdf && formData.t5SummaryPdf.length === 0 &&
      formData.rl3SummaryPdf && formData.rl3SummaryPdf.length === 0 &&
      formData.t3SummaryPdf && formData.t3SummaryPdf.length === 0 &&
      formData.rl16SummaryPdf && formData.rl16SummaryPdf.length === 0 
    ){
      setSnackbarData({
        open: true,
        // message: "Something went wrong, try again later!",
        message: 'Please Choose Atleast One File!',
        severity: "warning",
      });
    } else {
    

    let payload:IPDFFilesPayload = {
        year:formData.year,
        t4Pdf: undefined ,
        t4aPdf: undefined,
        rl1Pdf: undefined,
        t4SummaryPdf: undefined,
        t4aSummaryPdf: undefined,
        rl1SummaryEnglishPdf: undefined,
        rl1SummaryFrenchPdf: undefined,
        t5Pdf: undefined,
        t5SummaryPdf: undefined,
        rl3Pdf: undefined,
        rl3SummaryPdf: undefined,
        t3Pdf: undefined,
        t3SummaryPdf: undefined,
        rl16Pdf: undefined,
        rl16SummaryPdf: undefined
      }
    if (formData.t4Pdf && formData.t4Pdf.length > 0) {
        const file = formData.t4Pdf[0];
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.t4Pdf = cleanBase64
    } else if (formData.t4Pdf && t4PdfSavedFile) {
        const file = formData.t4Pdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.t4Pdf = cleanBase64
    }
    if (formData.t4aPdf && formData.t4aPdf.length > 0) {
      const file = formData.t4aPdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.t4aPdf = cleanBase64
    } else if (formData.t4aPdf && t4aPdfSavedFile) {
        const file = formData.t4aPdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.t4aPdf = cleanBase64
    }
    if (formData.rl1Pdf && formData.rl1Pdf.length > 0) {
      const file = formData.rl1Pdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.rl1Pdf = cleanBase64
    } else if (formData.rl1Pdf && rl1PdfSavedFile) {
        const file = formData.rl1Pdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.rl1Pdf = cleanBase64
    }
    if (formData.t5Pdf && formData.t5Pdf.length > 0) {
      const file = formData.t5Pdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.t5Pdf = cleanBase64
    } else if (formData.t5Pdf && t5PdfSavedFile) {
        const file = formData.t5Pdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.t5Pdf = cleanBase64
    }
    if (formData.t3Pdf && formData.t3Pdf.length > 0) {
      const file = formData.t3Pdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.t3Pdf = cleanBase64
    } else if (formData.t3Pdf && t3PdfSavedFile) {
        const file = formData.t3Pdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.t3Pdf = cleanBase64
    }
    if (formData.rl3Pdf && formData.rl3Pdf.length > 0) {
      const file = formData.rl3Pdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.rl3Pdf = cleanBase64
    } else if (formData.rl3Pdf && rl3PdfSavedFile) {
        const file = formData.rl3Pdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.rl3Pdf = cleanBase64
    }
    if (formData.rl16Pdf && formData.rl16Pdf.length > 0) {
      const file = formData.rl16Pdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.rl16Pdf = cleanBase64
    } else if (formData.rl16Pdf && rl16PdfSavedFile) {
        const file = formData.rl16Pdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.rl16Pdf = cleanBase64
    }
    if (formData.t4SummaryPdf && formData.t4SummaryPdf.length > 0) {
      const file = formData.t4SummaryPdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.t4SummaryPdf = cleanBase64
    } else if (formData.t4SummaryPdf && t4SummaryPdfSavedFile) {
        const file = formData.t4SummaryPdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.t4SummaryPdf = cleanBase64
    }
    if (formData.t4aSummaryPdf && formData.t4aSummaryPdf.length > 0) {
      const file = formData.t4aSummaryPdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.t4aSummaryPdf = cleanBase64
    } else if (formData.t4aSummaryPdf && t4aSummaryPdfSavedFile) {
        const file = formData.t4aSummaryPdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.t4aSummaryPdf = cleanBase64
    }
    if (formData.rl1SummaryEnglishPdf && formData.rl1SummaryEnglishPdf.length > 0) {
      const file = formData.rl1SummaryEnglishPdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.rl1SummaryEnglishPdf = cleanBase64
    } else if (formData.rl1SummaryEnglishPdf && rl1SummaryEnglishPdfSavedFile) {
        const file = formData.rl1SummaryEnglishPdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.rl1SummaryEnglishPdf = cleanBase64
    }
    if (formData.rl1SummaryFrenchPdf && formData.rl1SummaryFrenchPdf.length > 0) {
      const file = formData.rl1SummaryFrenchPdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.rl1SummaryFrenchPdf = cleanBase64
    } else if (formData.rl1SummaryFrenchPdf && rl1SummaryFrenchPdfSavedFile) {
        const file = formData.rl1SummaryFrenchPdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.rl1SummaryFrenchPdf = cleanBase64
    }
    if (formData.t5SummaryPdf && formData.t5SummaryPdf.length > 0) {
      const file = formData.t5SummaryPdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.t5SummaryPdf = cleanBase64
    } else if (formData.t5SummaryPdf && t5SummaryPdfSavedFile) {
        const file = formData.t5SummaryPdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.t5SummaryPdf = cleanBase64
    }
    if (formData.t3SummaryPdf && formData.t3SummaryPdf.length > 0) {
      const file = formData.t3SummaryPdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.t3SummaryPdf = cleanBase64
    } else if (formData.t3SummaryPdf && t3SummaryPdfSavedFile) {
        const file = formData.t3SummaryPdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.t3SummaryPdf = cleanBase64
    }
    if (formData.rl3SummaryPdf && formData.rl3SummaryPdf.length > 0) {
      const file = formData.rl3SummaryPdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.rl3SummaryPdf = cleanBase64
    } else if (formData.rl3SummaryPdf && rl3SummaryPdfSavedFile) {
        const file = formData.rl3SummaryPdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.rl3SummaryPdf = cleanBase64
    }
    if (formData.rl16SummaryPdf && formData.rl16SummaryPdf.length > 0) {
      const file = formData.rl16SummaryPdf[0];
      const base64 = await convertFileToBase64(file);
      const cleanBase64 = base64.split(',')[1];
      payload.rl16SummaryPdf = cleanBase64
    } else if (formData.rl16SummaryPdf && rl16SummaryPdfSavedFile) {
        const file = formData.rl16SummaryPdf;
        const base64 = await convertFileToBase64(file);
        const cleanBase64 = base64.split(',')[1];
        payload.rl16SummaryPdf = cleanBase64
    }

    console.log("payload",payload)
    submitFlexFields(payload)

  }

  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset();
  };

  const base64ToPdfFile = (base64PDF: string, fileName: string = 'document.pdf'): File => {
    // Remove any data URL prefix if present
    const base64Data = base64PDF.includes(',') ? base64PDF.split(',')[1] : base64PDF;
  
    // Convert Base64 to binary data
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
  
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
  
    // Create a Uint8Array from the binary data
    const byteArray = new Uint8Array(byteNumbers);
  
    // Create a Blob object with the MIME type for PDF
    const blob = new Blob([byteArray], { type: 'application/pdf' });
  
    // Create and return a File object
    return new File([blob], fileName, { type: 'application/pdf' });
  };


  useEffect(()=>{
    if(pdfFilesData){
      console.log("pdfFilesData",pdfFilesData)
      if(pdfFilesData.t4Pdf && pdfFilesData.t4Pdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.t4Pdf, 't4.pdf');
        formMethods.setValue('t4Pdf',pdfFile)
        setT4PdfFileName(pdfFile.name);
        setT4SavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setT4SavedFileUrl(url)
      }
      if(pdfFilesData.t4aPdf && pdfFilesData.t4aPdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.t4aPdf, 't4a.pdf');
        formMethods.setValue('t4aPdf',pdfFile)
        setT4APdfFileName(pdfFile.name);
        setT4ASavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setT4ASavedFileUrl(url)
      }
      if(pdfFilesData.rl1Pdf && pdfFilesData.rl1Pdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.rl1Pdf, 'rl1.pdf');
        formMethods.setValue('rl1Pdf',pdfFile)
        setRL1PdfFileName(pdfFile.name);
        setRL1SavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setRL1SavedFileUrl(url)
      }
      if(pdfFilesData.t5Pdf && pdfFilesData.t5Pdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.t5Pdf, 't5.pdf');
        formMethods.setValue('t5Pdf',pdfFile)
        setT5PdfFileName(pdfFile.name);
        setT5SavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setT5SavedFileUrl(url)
      }
      if(pdfFilesData.rl3Pdf && pdfFilesData.rl3Pdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.rl3Pdf, 'rl3.pdf');
        formMethods.setValue('rl3Pdf',pdfFile)
        setRL3PdfFileName(pdfFile.name);
        setRL3SavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setRL3SavedFileUrl(url)
      }
      if(pdfFilesData.t3Pdf && pdfFilesData.t3Pdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.t3Pdf, 't3.pdf');
        formMethods.setValue('t3Pdf',pdfFile)
        setT3PdfFileName(pdfFile.name);
        setT3SavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setT3SavedFileUrl(url)
      }
      if(pdfFilesData.rl16Pdf && pdfFilesData.rl16Pdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.rl16Pdf, 'rl16.pdf');
        formMethods.setValue('rl16Pdf',pdfFile)
        setRL16PdfFileName(pdfFile.name);
        setRL16SavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setRL16SavedFileUrl(url)
      }
      if(pdfFilesData.t4SummaryPdf && pdfFilesData.t4SummaryPdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.t4SummaryPdf, 't4-summary.pdf');
        formMethods.setValue('t4SummaryPdf',pdfFile)
        setT4SummaryPdfFileName(pdfFile.name);
        setT4SummarySavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setT4SummarySavedFileUrl(url)
      }
      if(pdfFilesData.t4aSummaryPdf && pdfFilesData.t4aSummaryPdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.t4aSummaryPdf, 't4aSummary.pdf');
        formMethods.setValue('t4aSummaryPdf',pdfFile)
        setT4ASummaryPdfFileName(pdfFile.name);
        setT4ASummarySavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setT4ASummarySavedFileUrl(url)
      }
      if(pdfFilesData.rl1SummaryEnglishPdf && pdfFilesData.rl1SummaryEnglishPdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.rl1SummaryEnglishPdf, 'rl1SummaryEnglish.pdf');
        formMethods.setValue('rl1SummaryEnglishPdf',pdfFile)
        setRL1SummaryEnglishPdfFileName(pdfFile.name);
        setRL1SummaryEnglishSavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setRL1SummaryEnglishSavedFileUrl(url)
      }
      if(pdfFilesData.rl1SummaryFrenchPdf && pdfFilesData.rl1SummaryFrenchPdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.rl1SummaryFrenchPdf, 'rl1SummaryFrench.pdf');
        formMethods.setValue('rl1SummaryFrenchPdf',pdfFile)
        setRL1SummaryFrenchPdfFileName(pdfFile.name);
        setRL1SummaryFrenchSavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setRL1SummaryFrenchSavedFileUrl(url)
      }
      if(pdfFilesData.t5SummaryPdf && pdfFilesData.t5SummaryPdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.t5SummaryPdf, 't5Summary.pdf');
        formMethods.setValue('t5SummaryPdf',pdfFile)
        setT5SummaryPdfFileName(pdfFile.name);
        setT5SummarySavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setT5SummarySavedFileUrl(url)
      }
      if(pdfFilesData.rl3SummaryPdf && pdfFilesData.rl3SummaryPdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.rl3SummaryPdf, 'rl3Summary.pdf');
        formMethods.setValue('rl3SummaryPdf',pdfFile)
        setRL3SummaryPdfFileName(pdfFile.name);
        setRL3SummarySavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setRL3SummarySavedFileUrl(url)
      }
      if(pdfFilesData.t3SummaryPdf && pdfFilesData.t3SummaryPdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.t3SummaryPdf, 't3Summary.pdf');
        formMethods.setValue('t3SummaryPdf',pdfFile)
        setT3SummaryPdfFileName(pdfFile.name);
        setT3SummarySavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setT3SummarySavedFileUrl(url)
      }
      if(pdfFilesData.rl16SummaryPdf && pdfFilesData.rl16SummaryPdf !== null){
        const pdfFile = base64ToPdfFile(pdfFilesData.rl16SummaryPdf, 'rl16Summary.pdf');
        formMethods.setValue('rl16SummaryPdf',pdfFile)
        setRL16SummaryPdfFileName(pdfFile.name);
        setRL16SummarySavedFile(true); 
        const url = URL.createObjectURL(pdfFile);
        setRL16SummarySavedFileUrl(url)
      }
    }
  },[pdfFilesData])
  

  const onYearSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
      setSelectedYear(Number(item.label));
      (async () => {
        try {
          setIsLoadingData(true)
          setT4SavedFile(false);
          setT4ASavedFile(false);
          setRL1SavedFile(false);
          setT5SavedFile(false);
          setRL3SavedFile(false);
          setT3SavedFile(false);
          setRL16SavedFile(false);
          setT4SummarySavedFile(false);
          setT4ASummarySavedFile(false);
          setRL1SummaryEnglishSavedFile(false);
          setRL1SummaryFrenchSavedFile(false);
          setT5SummarySavedFile(false);
          setRL3SummarySavedFile(false);
          setT3SummarySavedFile(false);
          setRL16SummarySavedFile(false);
          const response: any = await PDFSettingsService.getPDFFilesByTaxYear(Number(item.label));
          console.log("responseData-----------------", response);
          if (response) {
              if(response.t4Pdf !== null){
                const pdfFile = base64ToPdfFile(response.t4Pdf, 't4.pdf');
                formMethods.setValue('t4Pdf',pdfFile)
                setT4PdfFileName(pdfFile.name);
                setT4SavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setT4SavedFileUrl(url)
            }
              if(response.t4aPdf !== null){
                const pdfFile = base64ToPdfFile(response.t4aPdf, 't4a.pdf');
                formMethods.setValue('t4aPdf',pdfFile)
                setT4APdfFileName(pdfFile.name);
                setT4ASavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setT4ASavedFileUrl(url)
            }
              if(response.rl1Pdf !== null){
                const pdfFile = base64ToPdfFile(response.rl1Pdf, 'rl1.pdf');
                formMethods.setValue('rl1Pdf',pdfFile)
                setRL1PdfFileName(pdfFile.name);
                setRL1SavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setRL1SavedFileUrl(url)
            }
              if(response.t5Pdf !== null){
                const pdfFile = base64ToPdfFile(response.t5Pdf, 't5.pdf');
                formMethods.setValue('t5Pdf',pdfFile)
                setT5PdfFileName(pdfFile.name);
                setT5SavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setT5SavedFileUrl(url)
            }
              if(response.rl3Pdf !== null){
                const pdfFile = base64ToPdfFile(response.rl3Pdf, 'rl3.pdf');
                formMethods.setValue('rl3Pdf',pdfFile)
                setRL3PdfFileName(pdfFile.name);
                setRL3SavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setRL3SavedFileUrl(url)
            }
              if(response.t3Pdf !== null){
                const pdfFile = base64ToPdfFile(response.t3Pdf, 't3.pdf');
                formMethods.setValue('t3Pdf',pdfFile)
                setT3PdfFileName(pdfFile.name);
                setT3SavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setT3SavedFileUrl(url)
            }
              if(response.rl16Pdf !== null){
                const pdfFile = base64ToPdfFile(response.rl16Pdf, 'rl16.pdf');
                formMethods.setValue('rl16Pdf',pdfFile)
                setRL16PdfFileName(pdfFile.name);
                setRL16SavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setRL16SavedFileUrl(url)
            }
              if(response.t4SummaryPdf !== null){
                const pdfFile = base64ToPdfFile(response.t4SummaryPdf, 't4Summary.pdf');
                formMethods.setValue('t4SummaryPdf',pdfFile)
                setT4SummaryPdfFileName(pdfFile.name);
                setT4SummarySavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setT4SummarySavedFileUrl(url)
            }
              if(response.t4aSummaryPdf !== null){
                const pdfFile = base64ToPdfFile(response.t4aSummaryPdf, 't4aSummary.pdf');
                formMethods.setValue('t4aSummaryPdf',pdfFile)
                setT4ASummaryPdfFileName(pdfFile.name);
                setT4ASummarySavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setT4ASummarySavedFileUrl(url)
            }
              if(response.rl1SummaryEnglishPdf !== null){
                const pdfFile = base64ToPdfFile(response.rl1SummaryEnglishPdf, 'rl1SummaryEnglish.pdf');
                formMethods.setValue('rl1SummaryEnglishPdf',pdfFile)
                setRL1SummaryEnglishPdfFileName(pdfFile.name);
                setRL1SummaryEnglishSavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setRL1SummaryEnglishSavedFileUrl(url)
            }
              if(response.rl1SummaryFrenchPdf !== null){
                const pdfFile = base64ToPdfFile(response.rl1SummaryFrenchPdf, 'rl1SummaryFrench.pdf');
                formMethods.setValue('rl1SummaryFrenchPdf',pdfFile)
                setRL1SummaryFrenchPdfFileName(pdfFile.name);
                setRL1SummaryFrenchSavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setRL1SummaryFrenchSavedFileUrl(url)
            }
              if(response.t5SummaryPdf !== null){
                const pdfFile = base64ToPdfFile(response.t5SummaryPdf, 't5Summary.pdf');
                formMethods.setValue('t5SummaryPdf',pdfFile)
                setT5SummaryPdfFileName(pdfFile.name);
                setT5SummarySavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setT5SummarySavedFileUrl(url)
            }
              if(response.rl3SummaryPdf !== null){
                const pdfFile = base64ToPdfFile(response.rl3SummaryPdf, 'rl3Summary.pdf');
                formMethods.setValue('rl3SummaryPdf',pdfFile)
                setRL3SummaryPdfFileName(pdfFile.name);
                setRL3SummarySavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setRL3SummarySavedFileUrl(url)
            }
              if(response.t3SummaryPdf !== null){
                const pdfFile = base64ToPdfFile(response.t3SummaryPdf, 't3Summary.pdf');
                formMethods.setValue('t3SummaryPdf',pdfFile)
                setT3SummaryPdfFileName(pdfFile.name);
                setT3SummarySavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setT3SummarySavedFileUrl(url)
            }
              if(response.rl16SummaryPdf !== null){
                const pdfFile = base64ToPdfFile(response.rl16SummaryPdf, 'rl16Summary.pdf');
                formMethods.setValue('rl16SummaryPdf',pdfFile)
                setRL16SummaryPdfFileName(pdfFile.name);
                setRL16SummarySavedFile(true);
                const url = URL.createObjectURL(pdfFile);
                setRL16SummarySavedFileUrl(url)
            }
          }
        } catch (error) {
          setIsLoadingData(false)
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false)
        }
      })();
    };

  const onT4OpenFileClicked = () => {
      if(t4PdfSavedFileUrl){
          window.open(t4PdfSavedFileUrl, '_blank');
      }
  }
  const onT4AOpenFileClicked = () => {
      if(t4aPdfSavedFileUrl){
          window.open(t4aPdfSavedFileUrl, '_blank');
      }
  }
  const onRL1OpenFileClicked = () => {
      if(rl1PdfSavedFileUrl){
          window.open(rl1PdfSavedFileUrl, '_blank');
      }
  }
  const onT5OpenFileClicked = () => {
      if(t5PdfSavedFileUrl){
          window.open(t5PdfSavedFileUrl, '_blank');
      }
  }
  const onRL3OpenFileClicked = () => {
      if(rl3PdfSavedFileUrl){
          window.open(rl3PdfSavedFileUrl, '_blank');
      }
  }
  const onT3OpenFileClicked = () => {
      if(t3PdfSavedFileUrl){
          window.open(t3PdfSavedFileUrl, '_blank');
      }
  }
  const onRL16OpenFileClicked = () => {
      if(rl16PdfSavedFileUrl){
          window.open(rl16PdfSavedFileUrl, '_blank');
      }
  }

  const onT4SummaryOpenFileClicked = () => {
      if(t4SummaryPdfSavedFileUrl){
          window.open(t4SummaryPdfSavedFileUrl, '_blank');
      }
  }
  const onT4ASummaryOpenFileClicked = () => {
      if(t4aSummaryPdfSavedFileUrl){
          window.open(t4aSummaryPdfSavedFileUrl, '_blank');
      }
  }
  const onT5SummaryOpenFileClicked = () => {
      if(t5SummaryPdfSavedFileUrl){
          window.open(t5SummaryPdfSavedFileUrl, '_blank');
      }
  }
  const onT3SummaryOpenFileClicked = () => {
      if(t3SummaryPdfSavedFileUrl){
          window.open(t3SummaryPdfSavedFileUrl, '_blank');
      }
  }
  const onRL1SummaryEnglishOpenFileClicked = () => {
      if(rl1SummaryEnglishPdfSavedFileUrl){
          window.open(rl1SummaryEnglishPdfSavedFileUrl, '_blank');
      }
  }
  const onRL1SummaryFrenchOpenFileClicked = () => {
      if(rl1SummaryFrenchPdfSavedFileUrl){
          window.open(rl1SummaryFrenchPdfSavedFileUrl, '_blank');
      }
  }
  const onRL3SummaryOpenFileClicked = () => {
      if(rl3SummaryPdfSavedFileUrl){
          window.open(rl3SummaryPdfSavedFileUrl, '_blank');
      }
  }
  const onRL16SummaryOpenFileClicked = () => {
      if(rl16SummaryPdfSavedFileUrl){
          window.open(rl16SummaryPdfSavedFileUrl, '_blank');
      }
  }

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: '',
        companyName: '',
        activityType:
          "activity_type=upload",
        activityLogVisibility: false,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);
 
  return (
    <>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} 
        sx={{ width: "100%", pb: "5rem", "& .MuiAppBar-root":{zIndex:2,top:"65px"}, paddingTop: "2rem" }}>
    <BackdropCircularProgress
        open={
          isSubmitting || 
          isLoadingYearsList ||
          isLoadingPdfFilesData ||
          isLoadingData
        }
        testId={`pdf-files-backdrop`}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <TopRowTwoColumnLayout
            toolbar={
            <PDFFilesToolbar
                onResetClicked={onResetClicked}
                isSubmitting={false}
            />
            }
            topRowContent={
              <StandardPanel
                title="Select Year"
                testId={`pdf-files-year-list`}
                leftSpacing={4}
                rightSpacing={4}
              >
                {/* <EnhancedDropDownRHFWithLabel
                  selectName="year"
                  label="Select Year"
                  labelPosition={LabelPosition.Left}
                  items={yearsDDLList ? yearsDDLItems : []}
                  testId={`pdf-files-id`}
                  required
                  selectedItem={selectedYear}
                  onSelectionChangeHandler={onYearSelectionChangeHandler}
                /> */}
                <div style={{width:"100%",display:"flex",alignItems:"center"}}>
                  <div style={{width:"33%",fontSize:"small",color:"rgba(0, 0, 0, 0.6)",paddingBottom:"6.5px"}}>
                    Select Year
                  </div>
                  <div style={{width:"60%"}}>
                    <EnhancedDropDown
                      selectName={"year"}
                      items={yearsDDLList ? yearsDDLItems : []}
                      onChangeHandler={onYearSelectionChangeHandler}
                      testId={"year-ddl"}
                      selectedItem={selectedYear}
                      />
                  </div>
                </div>
              </StandardPanel>
            }
            leftColumnContent={
                <>
                  <StandardPanel
                    title="Slips"
                    // showTitle={false}
                    testId={`pdf-files`}
                  >
                    <SlipsPanel 
                      formMethods={formMethods}
                      
                      t4PdfFileName={t4PdfFileName}
                      t4PdfSavedFile={t4PdfSavedFile}
                      onT4OpenFileClicked={onT4OpenFileClicked}
                      
                      t4aPdfFileName={t4aPdfFileName}
                      t4aPdfSavedFile={t4aPdfSavedFile}
                      onT4aOpenFileClicked={onT4AOpenFileClicked}
                      
                      rl1PdfFileName={rl1PdfFileName}
                      rl1PdfSavedFile={rl1PdfSavedFile}
                      onRL1OpenFileClicked={onRL1OpenFileClicked}
                      
                      t5PdfFileName={t5PdfFileName}
                      t5PdfSavedFile={t5PdfSavedFile}
                      onT5OpenFileClicked={onT5OpenFileClicked}
                      
                      rl3PdfFileName={rl3PdfFileName}
                      rl3PdfSavedFile={rl3PdfSavedFile}
                      onRL3OpenFileClicked={onRL3OpenFileClicked}

                      t3PdfFileName={t3PdfFileName}
                      t3PdfSavedFile={t3PdfSavedFile}
                      onT3OpenFileClicked={onT3OpenFileClicked}
                      
                      rl16PdfFileName={rl16PdfFileName}
                      rl16PdfSavedFile={rl16PdfSavedFile}
                      onRL16OpenFileClicked={onRL16OpenFileClicked}
                    />
                  </StandardPanel>
                </>
            }
            rightColumnContent={
                <>
                  <StandardPanel
                    title="Summaries"
                    testId={`pdf-files`}
                    // showTitle={false}
                  >
                    <SummariesPanel 
                    formMethods={formMethods}
                    
                    t4SummaryPdfFileName={t4SummaryPdfFileName}
                    t4SummaryPdfSavedFile={t4SummaryPdfSavedFile}
                    onT4SummaryOpenFileClicked={onT4SummaryOpenFileClicked}
                    
                    t4aSummaryPdfFileName={t4aSummaryPdfFileName}
                    t4aSummaryPdfSavedFile={t4aSummaryPdfSavedFile}
                    onT4aSummaryOpenFileClicked={onT4ASummaryOpenFileClicked}
                    
                    rl1SummaryEnglishPdfFileName={rl1SummaryEnglishPdfFileName}
                    rl1SummaryEnglishPdfSavedFile={rl1SummaryEnglishPdfSavedFile}
                    onRL1SummaryEnglishOpenFileClicked={onRL1SummaryEnglishOpenFileClicked}

                    rl1SummaryFrenchPdfFileName={rl1SummaryFrenchPdfFileName}
                    rl1SummaryFrenchPdfSavedFile={rl1SummaryFrenchPdfSavedFile}
                    onRL1SummaryFrenchOpenFileClicked={onRL1SummaryFrenchOpenFileClicked}
                    
                    t5SummaryPdfFileName={t5SummaryPdfFileName}
                    t5SummaryPdfSavedFile={t5SummaryPdfSavedFile}
                    onT5SummaryOpenFileClicked={onT5SummaryOpenFileClicked}
                    
                    rl3SummaryPdfFileName={rl3SummaryPdfFileName}
                    rl3SummaryPdfSavedFile={rl3SummaryPdfSavedFile}
                    onRL3SummaryOpenFileClicked={onRL3SummaryOpenFileClicked}

                    t3SummaryPdfFileName={t3SummaryPdfFileName}
                    t3SummaryPdfSavedFile={t3SummaryPdfSavedFile}
                    onT3SummaryOpenFileClicked={onT3SummaryOpenFileClicked}
                    
                    rl16SummaryPdfFileName={rl16SummaryPdfFileName}
                    rl16SummaryPdfSavedFile={rl16SummaryPdfSavedFile}
                    onRL16SummaryOpenFileClicked={onRL16SummaryOpenFileClicked}
                    />
                  </StandardPanel>
                  
                </>
            }
            testId="global-variables-setup-form"
          />
        </form>
      </FormProvider>
    </Grid>
     <EnhancedSnackbar
     message={snackbarData.message}
     onCloseHandler={onSnackbarClosed}
     severity={snackbarData.severity}
     testId={"category-list-tab-list-snackbar"}
     open={snackbarData.open}
   />
   </>
  );
};
