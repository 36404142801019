import { AppBar, Box, Button, Toolbar, Typography, useTheme } from "@mui/material";
import { EmailField, EmailFieldRHF, IconTextButton, PrintButton, StyledRouterLink } from '@websential/cosmic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import EmailIcon from '@mui/icons-material/Email';
import { PrintingService } from "services/PrintingService";
import FileSaver from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { useState } from "react";
import { fileeeee } from "components/T4Slip";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { clearSelectedAccountant, clearSelectedClient, clearSelectedTaxYear, setSelectedAccountant } from "store/actions";
import { sendEmail } from "./sendMail";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ReportsService } from "services/ReportsService";
interface IInvoiceDetailAppBar{
  snackbarMessage:any;
}
export const InvoiceDetailAppBar: React.FC<IInvoiceDetailAppBar> = ({snackbarMessage}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const clientId = useSelector((state: IAppState) => state.client.id);
  const refNo = useSelector((state: IAppState) => state.invoice.refNo);
  const isAdminUser = useSelector((state: IAppState) => state.user.profile.isSuperStatus);

  const [emailInput, setEmailInput] = useState('')
  const [emailSubject, setEmailSubject] = useState('hello subject of email')
  const [emailFile, setEmailFile] = useState('')

  const savePdfAsFile = (pdfData: any, filename: any) => {
    const blob = new Blob([pdfData], { type: "application/pdf" });
    FileSaver.saveAs(blob, filename);
  };
  const onPrintButtonClicked = async (): Promise<void> => {
    try {
      const response: any = await PrintingService.invoicePdfPring(
        clientId, //clientId
        refNo, //refNo
      );
      const filename = "Invoice.pdf";
      savePdfAsFile(response, filename);
    } catch (err) {
      console.log("err", err);
      alert(err);
    }
  };

  const EmailInvoiceSchema = yup.object({
    EmailField: yup.string()
    .email("Please Enter a Valid Email")
    .required("Enter Your Email")
  })

  const formMethods = useForm<any>({
    resolver: yupResolver(EmailInvoiceSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    // defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = async (formData: any) => {
    console.log("formData",formData)
    try{
      const response:any = await ReportsService.generateInvoiceMail(clientId,refNo,formData.EmailField);
      console.log("response",response)
      if(response && response.status === 400){
        snackbarMessage({
          open: true,
          message: response && response.data.message,
          severity: "error",
        })
      } else if(response && response.status === 201) {
        snackbarMessage({
          open: true,
          message: "Email send Successfully.",
          severity: "success",
        })
      }else {
        snackbarMessage({
          open: true,
          message: "Something Went Wrong.",
          severity: "error",
        })
      }
    } catch (err) {
        console.log("err", err);
        // alert(err);
      }
  }

  // const onEmailFieldChangeHandler = (value:any) => {
  //   setEmailInput(value)
  //   console.log("valuevalue",value)
  // }

  // const onEmailButtonClicked = async (): Promise<void> => {
    // try {
    //   const response: any = await PrintingService.invoicePdfPring(
    //     clientId, //clientId
    //     refNo, //refNo
    //   );
    //   const filename = "Invoice.pdf";
    //   // savePdfAsFile(response, filename);
    //   const blob = new Blob([response], { type: "application/pdf" });
    //   // let file:any = FileSaver.saveAs(blob, filename);
    //   setEmailFile(response)

    //   const subject = emailSubject;
    //   // const body = response;
    //   const body = 'heyyyyy';

    //   // Encode subject and body to ensure proper formatting in the "mailto" link
    //   const encodedSubject = encodeURIComponent(subject);
    //   const encodedBody = encodeURIComponent(body);

    //   const mailtoURL = `mailto:${emailInput}?subject=${encodedSubject}&body=${encodedBody}&attachment=${blob}`;

    //   const link = document.createElement('a');
    //   link.setAttribute('href', mailtoURL);

    //   link.click();

    // } catch (err) {
    //   console.log("err", err);
    //   alert(err);
    // }
  // }

  const onUpdateInvoiceClicked = () => {
    dispatch(clearSelectedAccountant());
    dispatch(clearSelectedClient());
    dispatch(clearSelectedTaxYear());
  }
  return (
    <AppBar
          position="sticky"
          color="default"
          elevation={4}
          // data-testId={testId}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "5px",
            marginBottom: theme.spacing(4),
            top: "65px"
          }}
        >
          <Toolbar sx={{
            display:'flex',
            justifyContent:'space-between'
          }}>
              <Box sx={{
                display:"flex"
              }}>
                {/* <Box> */}
                  <PrintButton testId="print-button" label="Print Invoice"
                    onClickHandler={onPrintButtonClicked}
                    // variant="contained"
                    />
                {/* </Box> */}
                {isAdminUser === 1 ? 
                  (
                    <Box sx={{ml:"1rem"}}>
                      <Button 
                        size="small" 
                        startIcon={<ChevronRightIcon/>} 
                        data-testid="Upadte-invoice-button" 
                        variant="contained"
                        sx={{padding:"5px 15px",color:"#fff",background:"cadetblue",
                        "&:hover":{background:"cadetblue"}}}
                        onClick={onUpdateInvoiceClicked}
                        >
                        <StyledRouterLink to={"/admin-reports/update-invoice"}>
                          <Typography sx={{ textTransform: "none",color:"#fff" }}>Update Invoice</Typography>
                        </StyledRouterLink>
                      </Button>
                    </Box>
                    ):(<></>)
                }
                <Box sx={{ml:"1rem"}}>
                  <IconTextButton
                    text={"Transaction Details"}
                    icon={<FormatListBulletedIcon/>}
                    navigateTo={"/reports/transaction-detail"}
                    testId={"download-file-button"}
                  />
                </Box>
              </Box>
              <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Box
               sx={{
                display:"flex",
                alignItems:"center"
              }}>
                <Box sx={{
                  width:'26rem'
                }}>
                  <EmailFieldRHF 
                    id={"EmailField"} 
                    testId='EmailField' 
                    placeholder="hello@example.com"
                    // onChange={(e:any) => onEmailFieldChangeHandler(e.target.value)}
                  />
                </Box>
                <Box sx={{ml:"1rem"}}>
                  {/* <IconTextButton
                    text={"Email Invoice"}
                    icon={<EmailIcon/>}
                    navigateTo={"#"}
                    testId={"download-file-button"}
                    onClickHandler={onEmailButtonClicked}
                  /> */}
                  <Button variant="contained" type="submit">Email Invoice</Button>
                </Box>
              </Box>
              </form>
              </FormProvider>
          </Toolbar>
        </AppBar>
  )
}
