import * as yup from 'yup';

export const TaxProfileSetupSchema = yup.object({
  province: yup.string().required("Province is required"),
  effectiveDate: yup
  .date()
  .typeError("Effective date is required in a valid format (Jan 01, 2022)")
  .required("Effective date is required"),
  expiryDate: yup
  .date()
  .nullable()
  .min(yup.ref('effectiveDate'), "Expiry date must be greater than or equal to effective date"),
  taxLabel: yup.string().required("Tax label is required"),
  taxPercent: yup
  .number()
  .typeError("Tax percentage is required in decimal format")
  .nullable()
  .min(2, "Please enter a number greater than or equal to 2")
  // .transform((x: any) => (isNaN(x) ? undefined : x))// as yup.NumberSchema<number>
  .required("Tax percentage is required in number format")
});
