import { AXIOSCALL } from "store/types/isLoadAxiosCallTypes";

export const setSelectedAxiosCallActions = (selectTaxYear: any) => {
  return {
    type: AXIOSCALL.AXIOSCALL_SET_SELECTED,
    payload: {...selectTaxYear}
  }
}

export const clearSelectedAxiosCallActions = () => {
  return {
    type: AXIOSCALL.AXIOSCALL_CLEAR_SELECTED
  }
}
