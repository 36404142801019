import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ArrayData as AdminRoutes } from "navigation/routes/AdminRoutes";
import { clearSelectedClient, clearSelectedAccountant, clearSelectedTaxYear, clearSelectedINVOICE, clearSelectedAccountantName, clearSelectedClientName } from "store/actions";
import { clearSelectedResourceApiMessageActions } from "store/actions/resourceApiMessageActions";
export interface Item {
  id: number;
  text: string;
  open?: boolean;
  icon?: React.ReactNode;
  navigateToURL?: string;
  menu?: Item[] | React.ReactNode;
  parentMenuId?: number;
  grandParentMenuId?: number;
}
export interface ILeftMenuItem {
  item: Item;
  activeColor?: string;
  listItemButtonClickHandler?: (item: Item) => void | undefined;
}

export const LeftMenuItem: React.FC<ILeftMenuItem> = ({
  item,
  activeColor,
  listItemButtonClickHandler = undefined,
}) => {
  const { selectedMenuItem } = useSelector((state: IAppState) => {
    return {
      selectedMenuItem: state.navigation.selectedMenuItem,
    };
  });

  const dispatch = useDispatch();

  const clearClientId = () => {
    dispatch(clearSelectedClient());
  };
  const clearClientName = () => {
    dispatch(clearSelectedClientName());
  };
  const clearAccountantId = () => {
    dispatch(clearSelectedAccountant());
  };
  const clearAccountantName = () => {
    dispatch(clearSelectedAccountantName());
  };
  const clearTaxYear = () => {
    dispatch(clearSelectedTaxYear());
  };
  const clearRefNo = () => {
    dispatch(clearSelectedINVOICE());
  };

  const onListItemButtonClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: Item
  ) => {
    console.log("item--",item)
    console.log("event--",item)
    // setSelectedIndex(item.id);
    if (listItemButtonClickHandler) {
      listItemButtonClickHandler(item);
    }
    console.log("item", item.text);
    if (item.text === "Super User") {
      clearClientId();
      clearClientName();
      clearAccountantId();
      clearAccountantName();
      clearTaxYear();
      clearRefNo();
    }
    if (item.text === "Global Search") {
      clearClientId();
      clearClientName();
      clearAccountantId();
      clearAccountantName();
      clearTaxYear();
      clearRefNo();
    }
    if (item.text === "Client List") {
      clearClientId();
      clearClientName();
      clearTaxYear();
      clearRefNo();
    }
    if (item.text === "Accountant List") {
      clearAccountantId();
      clearAccountantName();
      clearClientId();
      clearClientName();
      clearTaxYear();
      clearRefNo();
    }
    if(item.parentMenuId === 10110){ //employee setup load to update field json data
      if(item.text === 'Setup'){
        // alert("Employee Setup")
    dispatch(clearSelectedResourceApiMessageActions())
        window.location.reload()
      }
    }
    if(item.parentMenuId === 10120){ //vendor setup load to update field json data
      if(item.text === 'Setup'){
        // alert("Employee Setup")
    dispatch(clearSelectedResourceApiMessageActions())
        window.location.reload()
      }
    }
    if(item.parentMenuId === 10130){ //T5 Recipient setup load to update field json data
      if(item.text === 'Setup'){
        // alert("Employee Setup")
    dispatch(clearSelectedResourceApiMessageActions())
        window.location.reload()
      }
    }
    if(item.parentMenuId === 10140){ //T5 Recipient setup load to update field json data
      if(item.text === 'Setup'){
        // alert("Employee Setup")
    dispatch(clearSelectedResourceApiMessageActions())
        window.location.reload()
      }
    }
  };
  console.log("selectedMenuItem",selectedMenuItem)
  return (
    <>
      <ListItemButton
        selected={item.id === selectedMenuItem.id || item.parentMenuId === selectedMenuItem.parentMenuId}
        onClick={(event) => {
          onListItemButtonClick(event, item);
        }}
        sx={{ pl: 1, pr: 1, color: "#fff" }}
      >
        <ListItemIcon sx={{ padding: 0, minWidth: "32px", color: "#fff" }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          sx={{ padding: 0, margin: 0.25 }}
          primaryTypographyProps={{ fontSize: ".8125rem", fontWeight: "bold" }}
          primary={item.text}
        />
        {item.menu ? (
          item.id === selectedMenuItem.id ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        ) : (
          ""
        )}
      </ListItemButton>
      <Collapse
        in={
          item.id === selectedMenuItem.id ||
          item.id === selectedMenuItem.parentMenuId ||
          item.id === selectedMenuItem.grandParentMenuId
        }
        timeout="auto"
        unmountOnExit
      >
        {Array.isArray(item.menu) ? (
          <List sx={{ pl: 3 }}>
            {item.menu &&
              item.menu.length > 0 &&
              item.menu.map((childMenuItem: { id: number; text: string }) => (
                <ListItemButton
                  key={childMenuItem.id}
                  selected={childMenuItem.id === selectedMenuItem.id}
                  onClick={(event) => {
                    onListItemButtonClick(event, childMenuItem);
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      color: "#fff",
                      fontSize: ".8125rem",
                      fontWeight: "bold",
                      padding: 0,
                      margin: 0,
                    }}
                    primary={childMenuItem.text}
                  />
                </ListItemButton>
              ))}
          </List>
        ) : (
          item.menu
        )}
      </Collapse>
    </>
  );
};
