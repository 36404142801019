import { Item } from 'components/LeftMenu';
import { NAVIGATION } from '../types';

export interface INavigation {
  selectedMenuItem: Item
}

const initialState: INavigation = {
  selectedMenuItem: {
    id: -1,
    text: ""
  }
};

export const navigationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case NAVIGATION.SET_USER_MENU_OPEN:
      return {
        ...state,
        selectedMenuItem: action.payload
      };
    case NAVIGATION.SET_USER_MENU_CLOSE:
      return {
        ...state,
        selectedMenuItem: action.payload,
      }
    default:
      // return { ...state };
      return state;
  }
};
