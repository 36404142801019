import {
  T4SlipSummaryReducedEISetup, T4SlipSummarySetup,
} from "components/T4Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT4SlipSummarySetupPage {}

export const T4SlipSummarySetupPage: FC<IT4SlipSummarySetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T4 Summary"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t4/list" },
        { text: "T4", navigateTo: "/taxslip/t4/list" },
        { text: "T4 Summary", navigateTo: "/taxslip/t4/summary" },
      ]}
      mainContents={<T4SlipSummarySetup testId="t4-summary-setup" />}
    />
  );
};
