import { AxiosResponse } from 'axios';
import http from 'common/http-common';
import { AnyARecord } from 'dns';
import {
  IGetListRequest, IResponse, IServerError, ITaxSlipYearDDLModel, ITaxSlipYearListModel, ITaxSlipYearRequestPayload,
  ITaxSlipYearResponsePayload
} from 'models';

// TODO: replace this with API response once it's ready
export const TaxSlipYearService = {
  // create: async (requestPayload: ITaxSlipYearRequestPayload): Promise<ITaxSlipYearResponsePayload> => {
  //   let response: ITaxSlipYearResponsePayload;
  //   return new Promise(resolve => setTimeout(() => resolve(response), 1000));
  // },
  create: async (
    requestPayload: ITaxSlipYearRequestPayload
  ): Promise<ITaxSlipYearResponsePayload | IServerError | IResponse> => {
    if (requestPayload.id) {
      console.log('requestPayload ==>> update request', requestPayload);
      console.log('requestPayload ==>> update request id', requestPayload.id);
      const response: AxiosResponse<ITaxSlipYearResponsePayload> = await http.put<ITaxSlipYearResponsePayload>(
        `/v1/years/${requestPayload.id}/`, requestPayload);

      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }

    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<ITaxSlipYearResponsePayload> =
      await http.post<ITaxSlipYearResponsePayload>(
        "/v1/years/",
        requestPayload
      );

    if (response.status === 201) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  delYear: async (yearId: number): Promise<string | IServerError | IResponse> => {
    console.log('deleteById', yearId);
    const response: AxiosResponse<string> = await http.delete<string>(`/v1/years/${yearId}/`);

    if (response.status === 204) {
      return "Year has been deleted successfully";
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    else if (response.status === 200) {
      return "Year has been deleted successfully";
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  getListDDL: async (): Promise<ITaxSlipYearDDLModel> => {
    const fixedResponse: ITaxSlipYearDDLModel = {items: [
      {
        "id": "ab8ccece-16c1-44e9-ba86-2990eefb178c",
        "year": 2021,
      },
      {
        "id": "4e3823aa-c2e4-45aa-aaaf-9ba1b1486acc",
        "year": 2022,
      }
    ]}
    return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    // const response: AxiosResponse<ITaxSlipYearDDLModel> = await http.get<ITaxSlipYearDDLModel>(
    //   `/v1/data-roles/?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);

    // if (response.status === 200) {
    //   return response.data;
    // } else if (response.status === 400) {
    //   return {
    //     status: false,
    //     message: "Bad Request.",
    //     error: true,
    //   };
    // } else if (response.status === 500) {
    //   return {
    //     status: false,
    //     message: "Something went wrong.",
    //     error: true,
    //   };
    // }

    // return {
    //   status: false,
    //   message: "Something went wrong please try again later.",
    // };
  },
  getList: async (
    params: IGetListRequest
  ): Promise<
    ITaxSlipYearListModel | IServerError | IResponse | any
> => {
    // const fixedResponse: ITaxSlipYearListModel = {
    //   "page": 1,
    //   "size": 5,
    //   "total": 2,
    //   "items": [
    //     {
    //       "id": "ab8ccece-16c1-44e9-ba86-2990eefb178c",
    //       "year": 2021,
    //     },
    //     {
    //       "id": "4e3823aa-c2e4-45aa-aaaf-9ba1b1486acc",
    //       "year": 2022,
    //     }
    //   ],
    //   "keyField": {
    //     "field": "id",
    //     "templateUrl": "/years/setup/id"
    //   },
    //   "headerColumns": [
    //     {
    //       "field": "year",
    //       "headerName": "Year",
    //       "description": "TaxSlip Year",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       // "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       // "isFixed": true,
    //       "sequence": 0
    //     }
    //   ]
    // }
    // return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));
  // {
    const response: AxiosResponse<ITaxSlipYearListModel> = await http.get<ITaxSlipYearListModel>(
      `/v1/years/?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  // },
},
getById: async (id: string | undefined): Promise<ITaxSlipYearListModel | IServerError | IResponse | null> => {
  if (id) {
    const response: AxiosResponse<ITaxSlipYearListModel> = await http.get<ITaxSlipYearListModel>(
      `/v1/years/${id}/`);
      console.log("upadted id : ",id)

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  }

  return null;
},
PutTaxYear: async (
  // requestPayload: ITaxSlipYearRequestPayload
  clientId: string,
  taxYear: number | string
): Promise<any | IServerError | IResponse> => {
    // console.log("requestPayload ==>> update request", requestPayload);
    const response: AxiosResponse<any> = await http.put<any>(
      `/v1/tax-year/?client_id=${clientId}&tax_year=${taxYear}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getYear: async (
    clientId: string
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/tax-year/${clientId}`);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
},
};
