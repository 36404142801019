import React from 'react';

import { Backdrop, CircularProgress, useTheme } from '@mui/material';
import { ITest } from '@websential/cosmic';



export interface IBackdropCircularProgress extends ITest {
  /**
   * set open to true to show the circular progress with backdrop; otherwise false
   */
  open: boolean;
}

export const BackdropCircularProgress: React.FC<IBackdropCircularProgress> = ({
  open,
  testId
}) => {
  const theme = useTheme();
  return (
    <Backdrop
      sx={{ 
        // color:'rgb(68 71 72 / 50%)', 
        backgroundColor:"rgb(168 170 171 / 25%)", 
        zIndex: theme.zIndex.drawer + 1 }}
      open={open}
      data-testid={testId}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
