import {
  BackdropCircularProgress,
  EnhancedDialog,
  EnhancedSnackbar,
  EnhancedToolTip,
  ITest,
  StandardPanel,
} from "@websential/cosmic";
import { Box, Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ADJUSTMENT_SCREEN_ID, ADJUSTMENT_SCREEN_SLUG, INITIAL_SNACKBAR_DATA, QUERY_OPTIONS } from "common/constants";

import { ProgressBar } from 'primereact/progressbar';
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import { AdjustmentService } from "services/AdjustmentService";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IAppState } from "store/reducers";
import { IRemittanceRequestPayload } from "models/common/ITransferRemittance";
import { RL1RemittanceTransferEmployeeSetup } from "./RL1RemittanceTransferEmployeeSetup";
import { RemittanceTransferEmployeeSetup } from "./RemittanceTransferEmployeeSetup";
import { ResourceService } from "services/ResourceService";
import { UndoRL1RemittanceTransferEmployeeSetup } from "./undoRL1RemittanceTransferred";
import { UndoRemittanceTransferEmployeeSetup } from "./undoT4RemittanceTransfered";
import { selectedSlipType } from "components/TaxSlipFilingResource";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { T4SlipService } from "services/T4SlipService";

export interface IAdjustmentsSetup extends ITest {}

const t4AdjustmentInstructions = [
  (
    <>
      To check potential adjustments & savings, click <b>'Adjust Slips'</b> button (You can undo adjustments at any time)
    </>
  ),
  (
      <>
        System will calculate adjustments as per settings in{' '} <b><i><a href="https://appv3.taxslips.com/#/adjustments/options" style={{color:"#000"}}>Adjustment &gt; Options screen.</a></i></b> 
      </>
    ),
  "Over / under remittance amount will be calculated.",
  (
    <>
      Employees' portion of adjustment will be adjusted in their own slips, and employer's contribution will be adjusted in employee selected from <b>'Transfer Over Remittance'</b> panel in <b><i><a href="https://appv3.taxslips.com/#/adjustments/options" style={{color:"#000"}}>Adjustment &gt; Options screen.</a></i></b> 
    </>
  ),
  (
    <>
      To transfer over/under remittance, select employee (usually director or owner of account) from <b>'Transfer Over Remittance'</b> panel in <br /> <b><i><a href="https://appv3.taxslips.com/#/adjustments/options" style={{color:"#000"}}>Adjustment &gt; Options screen </a></i></b> and save data.
    </>
  ),
  "Once employee is selected, you can transfer amount to selected employee's T4 Slip, from this screen.",
  (
    <>
      To view adjustment details <b><i><a href="https://appv3.taxslips.com/#/reports/t4-reports" target="_blank" style={{color:"#000"}}>click here.</a></i></b>
    </>
  )
];

const rl1AdjustmentInstructions = [
  (
    <>
      To check potential adjustments & savings, click <b>'Adjust Slips'</b> button (You can undo adjustments at any time)
    </>
  ),
  (
    <>
      System will calculate adjustments as per settings in <b><i><a href="https://appv3.taxslips.com/#/adjustments/options" style={{color:"#000"}}>Adjustment &gt; Options screen.</a></i></b>
    </>
  ),
  "Over / under remittance amount will be calculated.",
  (
    <>
      Employees' portion of adjustment will be adjusted in their own slips, and employer's contribution will be adjusted in employee selected from <b>'Transfer Over Remittance'</b> panel in <b><i><a href="https://appv3.taxslips.com/#/adjustments/options" style={{color:"#000"}}>Adjustment &gt; Options screen.</a></i></b>
    </>
  ),
  (
    <>
      To transfer over/under remittance, select employee (usually director or owner of account) from <b>'Transfer Over Remittance'</b> panel in <br /> <b><i><a href="https://appv3.taxslips.com/#/adjustments/options" style={{color:"#000"}}>Adjustment &gt; Options screen</a></i></b> and save data.
    </>
  ),
  "Once employee is selected, you can transfer amount to selected employee's T4 Slip, from this screen.",
  (
  <>
    To view adjustment details <b><i><a href="https://appv3.taxslips.com/#/reports/rl1-reports" target="_blank" style={{color:"#000"}}>click here.</a></i></b>
  </>
  ),
];

const GET_ADJUSTMENTS_QUERY_KEY = "get-adjustments";

const useAdjustments = (clientId: string) =>
  useQuery(
    GET_ADJUSTMENTS_QUERY_KEY,
    () => AdjustmentOptionsService.getAdjustmentOptionsByClientId(clientId),
    QUERY_OPTIONS
  );

const useGetSlipIds = (
  tag: string,
  clientId: string,
  taxYear: number
) =>
  useQuery(
    ["get-slip-ids", tag, clientId, taxYear],
    (data) => {
      return T4SlipService.getSlipIds(tag, clientId, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

export const AdjustmentsSetup: FC<IAdjustmentsSetup> = ({ testId }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const interval = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);
  const id  = useSelector((state: IAppState) => state.client.id)
  const taxYear = useSelector((state: IAppState) => state.taxYear.year)
  const clientName = useSelector((state : IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector((state: IAppState) => state.isLoadAxiosCall.isLoading);


  const [responceT4, setRespoceT4] = useState<any>(null)
  const [test, setTest] = useState({})
  const [isLoadding, setIsLoadingSelectData] = useState(false)
  const[openDialog, setDialogOpen] = useState(false)
  const[openProgressBarDialog, setOpenProgressBarDialog] = useState(false)
  const[dialogMessage, setDialogMessage] = useState("")
  const[dialogMessageT4, setDialogMessageT4] = useState("")
  const[dialogMessageRL1, setDialogMessageRL1] = useState("")

  const [responceRL1, setRespoceRL1] = useState<any>(undefined)
  const [isLoadingRL1, setIsLoadingSelectRL1] = useState(false)
  const [totalPayroll, setTotalPayroll] = useState<any>(undefined)
  const [RL1AmountToBeTransferred, setRL1AmountToBeTransferred] = useState<any>(undefined)
  const [T4AmountToBeTransferred, setT4AmountToBeTransferred] = useState<any>(undefined)
  const [AdjustmentOptionsResponse, setAdjustmentOptionsResponse] = useState<any>(undefined)
  const [T4RemittanceRequestPayload, setT4RemittanceRequestPayload] = useState<any>(undefined);
  const [RL1RemittanceRequestPayload, setRL1RemittanceRequestPayload] = useState<any>({});
  const [T4UndoRemittanceRequestPayload, setT4UndoRemittanceRequestPayload] = useState<any>(undefined);
  const [RL1UndoRemittanceRequestPayload, setRL1UndoRemittanceRequestPayload] = useState<any>(undefined);
  const [T4RemainingAmountValues, setT4RemainingAmountValues] = useState<any>(undefined);
  const [RL1RemainingAmountValues, setRL1RemainingAmountValues] = useState<any>(undefined);
  const [undoTransferRemittanceButton, setUndoTransferRemittanceButton] = useState<any>(undefined);
  const [remitanceTransfer, setRemitanceTransfer] = useState(false)
  const [undoRemitanceTransfer, setUndoRemitanceTransfer] = useState(false)
  const [loadRl1RemainingAmount, setLoadRl1RemainingAmount] = useState(false)
  const [undoRemitanceTransferRL1, setUndoRemitanceTransferRL1] = useState(false)
  const [t4EmptyAdjustmentMessage, setT4EmptyAdjustmentMessage] = useState("No slips created/completed.")
  const [rl1EmptyAdjustmentMessage, setRl1EmptyAdjustmentMessage] = useState("One or more slips have not been adjusted.")
  let T4RemainingAmount:any = 0
  let RL1RemainingAmount:any = 0
  
  const [undoTransferT4Items, setUndoTransferT4Items] = useState<any>(undefined);
  const [undoTransferRL1Items, setUndoTransferRL1Items] = useState<any>(undefined);

  const [percentageValue, setPercentageValue] = useState(0);
  const [progressBarTitleTag, setProgressBarTitleTag] = useState("T4");

  // let undoTransferT4Items = [
  //   {slipId:"6571cbe8e7cb3b1663182bab",resourceFirstName: "Demo 2 (ON)",resourceRemittanceValue:3927.81},
  //   {slipId:"657826be8d971f434f141e7d",resourceFirstName: "Demo44 (ON)",resourceRemittanceValue:3927.82},
  //   {slipId:"657c5eca87f9879795254a11",resourceFirstName: "Zeeshan rl1 (QC)",resourceRemittanceValue:0}
  // ]
  // let undoTransferRL1Items = undoTransferT4Items && undoTransferT4Items.filter((item:any) => item.resourceFirstName.endsWith("(QC)"));

  console.log("undoTransferT4Items",undoTransferT4Items)
  console.log("undoTransferRL1Items",undoTransferRL1Items)
//   const { isLoading: isLoadingAdjustments, data: adjustments } =
//     useAdjustments(id);
//   const adjustmentOptionsData : any = adjustments
// console.log("adjustmentsadjustmentsadjustments",adjustments)
// console.log("adjustmentOptionsData.transferOverRemittanceToSlips",adjustmentOptionsData.transferOverRemittanceToSlips)
// useEffect(()=>{
// if(adjustments){
//   setT4AmountToBeTransferred(adjustments)
// }
// },[adjustments])
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const { isLoading:loadingSlipIds, data:t4SlipIds } = useGetSlipIds("T4", id, taxYear);


  const onClickHandlerForT4 = async (): Promise<void> => {
    try {
      setIsLoadingSelectData(true)
      const responseMergeEmployees : any = await ResourceService.getDuplicateMergeList(id, taxYear);
      if(responseMergeEmployees && responseMergeEmployees.items.length > 0){
        console.log("hlvskbvkbvksbv")
        setSnackbarData({
          open: true,
          message: (
            <>
              System has detected duplicate employee(s), you can either select them and merge into one or press Ignore.{' '}
              <a href="https://appv3.taxslips.com/#/resources/Employee/merge" style={{color:"#aa1010",
                                              textDecoration:"none",
                                              fontWeight:"bold"}}>Click here to resolve</a>
            </>
          ),
          severity: "error",
          autoHide:false
        });
      } else {
        let newRunProgressBarMethodForT4SlipsAdjust : any = true;
        const t4AdjustActualDataApi = async () => {
        try {
          setIsLoadingSelectData(true)
          // setTimeout(() => {
          //   setIsLoadingSelectData(false);
          // }, 2000);
          const response:any = await AdjustmentService.createT4Adjustment(id, taxYear);
          if(response.message === "This Client and tax year has no adjusted Slip data"){
            T4RemainingAmount = 0
            setT4RemainingAmountValues("0.00")
            setRespoceT4(undefined)
            setT4EmptyAdjustmentMessage("This Client and tax year has no T4 Slips")
            newRunProgressBarMethodForT4SlipsAdjust = false
          } else if(response.message){
            setRespoceT4(undefined)
            // setSnackbarData({
            //   open: true,
            //   message: response.message ? response.message : "Something went wrong.",
            //   severity: response.status === false ? "error" : 
            //   response.success === false ? "error" : 
            //   "success",
            // })
            newRunProgressBarMethodForT4SlipsAdjust = false
          } else {
            newRunProgressBarMethodForT4SlipsAdjust = true
          }
          setIsLoadingSelectData(false)
        } catch (err) {
          setIsLoadingSelectData(false)
          console.log("err",err)
        }
        };
        t4AdjustActualDataApi();

        if(newRunProgressBarMethodForT4SlipsAdjust){
          setIsLoadingSelectData(false)
          setProgressBarTitleTag("T4")
          setOpenProgressBarDialog(true)
          //manage progress bar
          let _val = percentageValue;
          let consecutiveSameValueCount = 0; // Counter for consecutive same values
          let lastAdjustedValue:any = null; // To track the last value
        const fetchData = async () => {
          try {
            const response: any = await AdjustmentService.getT4Adjutments(id, taxYear);
            let totalSlipsAdjusted = 
              (response[0]?.totalSlipsAdjusted || 0) + 
              (response[1]?.totalSlipsAdjusted || 0);

            _val = Math.floor((totalSlipsAdjusted / response[0].totalT4) * 100); 
            console.log("_val_val",_val)
            setPercentageValue(_val);

            // Check for consecutive same values
              if (totalSlipsAdjusted === lastAdjustedValue) {
                  consecutiveSameValueCount++;
              } else {
                  consecutiveSameValueCount = 0; // Reset if value changes
              }
              lastAdjustedValue = totalSlipsAdjusted;

              if (consecutiveSameValueCount >= 4) {
                  console.log("Same value detected for 4 iterations, reloading screen.");
                  window.location.reload();
              }
            
            console.log("_val_val",response)
            console.log("_val_val",response[0].totalSlipsAdjusted)
            console.log("response[1].totalSlipsAdjusted",response[1].totalSlipsAdjusted)
            console.log("totalSlipsAdjusted",totalSlipsAdjusted)
            console.log("_val_val",response[0].totalT4)
            console.log("_val_val",t4SlipIds.slipIds.length)
            if (totalSlipsAdjusted === response[0].totalT4) {
              clearInterval(interval.current);
              setPercentageValue(100)
              
              // let totalSuccessfullImportedItems = response.counter - response.fail
              // setSnackbarData({
              //   open: true,
              //   message: `${totalSuccessfullImportedItems} items imported and ${response.fail} items have incorrect data.`,
              //   severity: "success",
              // });
              // Set up the interval
              interval.current = setInterval(() => {
                setOpenProgressBarDialog(false)
                // redirectToPage(CSVInformationUI.tag);
                clearInterval(interval.current);
              }, 3000);

              setRespoceT4(response)
              // if(response && response.transferOverRemittanceToSlips === null){
              //   T4RemainingAmount = parseFloat(response && response.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              //   setT4RemainingAmountValues(parseFloat(response && response.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
              // }
              if(AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips === null){
                if(response !== null && response[1] && Object.keys(response[1]).length > 0){
                  T4RemainingAmount = parseFloat(response[0].overUnderRemittance + response[1].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  setT4RemainingAmountValues(parseFloat(((response && response[0].overUnderRemittance) + (response[1] && response[1].overUnderRemittance ? response[1].overUnderRemittance : 0))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
                  console.log("T4RemainingAmount12e",response[1].overUnderRemittance)
                  console.log("T4RemainingAmount12e",T4RemainingAmount)
                }else if(response !== null) {
                  console.log("T4RemainingAmount12e",T4RemainingAmount)
                  T4RemainingAmount = parseFloat(response && response[0].overUnderRemittance && response[0].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  setT4RemainingAmountValues(parseFloat(response && response[0].overUnderRemittance && response[0].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
                }
              } else if(AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips && AdjustmentOptionsResponse.transferOverRemittanceToSlips.length === 0){
                console.log("23456789",response)
                if(response !== null && response[1] && Object.keys(response[1]).length > 0){
                  T4RemainingAmount = parseFloat(((response && response[0].overUnderRemittance) + (response[1] && response[1].overUnderRemittance ? response[1].overUnderRemittance : 0))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  setT4RemainingAmountValues(parseFloat(((response && response[0].overUnderRemittance) + (response[1] && response[1].overUnderRemittance ? response[1].overUnderRemittance : 0))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
                  console.log("T4RemainingAmount12e",T4RemainingAmount)
                }else if(response !== null) {
                  T4RemainingAmount = parseFloat(response && response[0].overUnderRemittance && response[0].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  setT4RemainingAmountValues(parseFloat(response && response[0].overUnderRemittance && response[0].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
                  console.log("T4RemainingAmount12e",T4RemainingAmount)
                }
                console.log("T4RemainingAmount12e",T4RemainingAmount)
              }
              console.log("T4RemainingAmount=",T4RemainingAmount)
              console.log("T4RemainingAmountValues=",T4RemainingAmountValues)
              setIsLoadingSelectData(false)
              window.location.reload()

            }
        
        
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        
        // Set up the interval
        interval.current = setInterval(() => {
          fetchData();
        }, 5000);
        }
      }
    }
    catch (err) {
      alert(err)
    } finally {
          setIsLoadingSelectData(false)
    }
  };

  const onClickHandlerUndoForT4 = async (): Promise<void> => {
    try {
      setIsLoadingSelectData(true)
      // setTimeout(() => {
        //   setIsLoadingSelectData(false);
        // }, 2000);
        
        const response : any = await AdjustmentService.undoT4Adjustment(id, taxYear);
        if(response.status === true || response.success === true){
          setSnackbarData({
            open: true,
            message: response.message ? response.message : "Undo Transfer Ramittance Successfully.",
            severity: "success",
          })
          window.location.reload()
        }
        setRespoceT4(undefined)
        setIsLoadingSelectData(false)
      } catch (err) {
      setIsLoadingSelectData(false)
      alert(err)
    }
  };
  
  const onClickHandlerForRL1 = async (): Promise<void> => {
    try {
      setIsLoadingSelectData(true)
      const responseMergeEmployees : any = await ResourceService.getDuplicateMergeList(id, taxYear);
      if(responseMergeEmployees && responseMergeEmployees.items.length > 0){
        console.log("hlvskbvkbvksbv")
        setSnackbarData({
          open: true,
          message: (
            <>
              System has detected duplicate employee(s), you can either select them and merge into one or press Ignore.{' '}
              <a href="https://appv3.taxslips.com/#/resources/Employee/merge" style={{color:"#aa1010",
                                              textDecoration:"none",
                                              fontWeight:"bold"}}>Click here to resolve</a>
            </>
          ),
          severity: "error",
          autoHide:false
        });
      } else {
        let newRunProgressBarMethodForRL1SlipsAdjust : any = true;
        const rl1AdjustActualDataApi = async () => {
          try {
            setIsLoadingSelectRL1(true)
            // setTimeout(() => {
              //   setIsLoadingSelectRL1(false);
            // }, 2000);
            const response:any = await AdjustmentService.createRL1Adjustment(id, taxYear);
            console.log("response-----",response)
            if(response.message === "This Client and tax year has no adjusted Slip data"){
              RL1RemainingAmount = 0
              setRL1RemainingAmountValues("0.00")
              setRespoceRL1(undefined)
              setRl1EmptyAdjustmentMessage("This Client and tax year has no RL1 Slips")
              newRunProgressBarMethodForRL1SlipsAdjust = false
            } else if(response.message){
              setRespoceRL1(undefined)
              setSnackbarData({
                open: true,
                message: response.message ? response.message : "Something went wrong.",
                severity: response.status === false ? "error" : 
                response.success === false ? "error" : 
                "success",
              })
              newRunProgressBarMethodForRL1SlipsAdjust = false
            }else {
              newRunProgressBarMethodForRL1SlipsAdjust = true
            }
            setIsLoadingSelectRL1(false)
          } catch (err) {
            setIsLoadingSelectRL1(false)
            alert(err)
          }
        };
        rl1AdjustActualDataApi();

        if(newRunProgressBarMethodForRL1SlipsAdjust){
          setIsLoadingSelectData(false)
          setOpenProgressBarDialog(true)
          setProgressBarTitleTag("RL-1")
          //manage progress bar
          let _val = percentageValue;
          let consecutiveSameValueCount = 0; // Counter for consecutive same values
          let lastAdjustedValue:any = null; // To track the last value
        const fetchData = async () => {
          try {
            const response: any = await AdjustmentService.getRL1Adjutments(id, taxYear);
            let totalSlipsAdjusted = 
              (response?.totalSlipsAdjusted || 0);

            _val = Math.floor((totalSlipsAdjusted / response.totalRl1Slips) * 100); 
            console.log("_val_val",_val)
            setPercentageValue(_val);

            // Check for consecutive same values
              if (totalSlipsAdjusted === lastAdjustedValue) {
                  consecutiveSameValueCount++;
              } else {
                  consecutiveSameValueCount = 0; // Reset if value changes
              }
              lastAdjustedValue = totalSlipsAdjusted;

              if (consecutiveSameValueCount >= 4) {
                  console.log("Same value detected for 4 iterations, reloading screen.");
                  window.location.reload();
              }
            
            console.log("_val_val",response)
            console.log("_val_val",response.totalSlipsAdjusted)
            // console.log("response.totalSlipsAdjusted",response.totalSlipsAdjusted)
            console.log("totalSlipsAdjusted",totalSlipsAdjusted)
            console.log("_val_val",response.totalRl1Slips)
            // console.log("_val_val",t4SlipIds.slipIds.length)
            if (response.totalSlipsAdjusted === response.totalRl1Slips) {
              clearInterval(interval.current);
              setPercentageValue(100)
              
              // let totalSuccessfullImportedItems = response.counter - response.fail
              // setSnackbarData({
              //   open: true,
              //   message: `${totalSuccessfullImportedItems} items imported and ${response.fail} items have incorrect data.`,
              //   severity: "success",
              // });
              // Set up the interval
              interval.current = setInterval(() => {
                setOpenProgressBarDialog(false)
                // redirectToPage(CSVInformationUI.tag);
                clearInterval(interval.current);
              }, 3000);

              // if(response && response.transferOverRemittanceToSlips === null || 
              //   response && response.transferOverRemittanceToSlips && response.transferOverRemittanceToSlips.length === 0){
              //   RL1RemainingAmount = parseFloat(response && response.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              //   setRL1RemainingAmountValues(parseFloat(response && response.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
              // }
              if(AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips === null
                && AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips && AdjustmentOptionsResponse.transferOverRemittanceToSlips.length === 0){
                RL1RemainingAmount = parseFloat(response && response.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                setRL1RemainingAmountValues(parseFloat(response && response.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
              } else if(AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips !== null &&
                AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips && AdjustmentOptionsResponse.transferOverRemittanceToSlips.length !== 0){
                  const hasQC = AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips.some((item:any) => item.resourceFirstName.endsWith("(QC)"));
                  if(!hasQC){
                    RL1RemainingAmount = parseFloat(response && response.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    setRL1RemainingAmountValues(parseFloat(response && response.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
                    console.log("RL1RemainingAmount987",RL1RemainingAmount)
                  }
                  // console.log("fgh",AdjustmentOptionsResponse.transferOverRemittanceToSlips);
                  // console.log(hasQC ? 'Yessssssssss' : 'No000000000');
                  // console.log("hasQC",hasQC);
                }
               // }
              setRespoceRL1(response)
              window.location.reload()

            }
        
        
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        
        // Set up the interval
        interval.current = setInterval(() => {
          fetchData();
        }, 5000);
        }

      }
    }
    catch (err) {
      alert(err)
    } finally {
          setIsLoadingSelectData(false)
    }
};

  const onClickHandlerUndoForRL1 = async (): Promise<void> => {
    try {
      setIsLoadingSelectRL1(true)
      // setTimeout(() => {
        //   setIsLoadingSelectRL1(false);
        // }, 2000);
        const response = await AdjustmentService.undoRL1Adjustment(id, taxYear);
        if(response.status === true || response.success === true){
          setSnackbarData({
            open: true,
            message: response.message ? response.message : "Undo Transfer Ramittance Successfully.",
            severity: "success",
          })
          window.location.reload()
        }
        setRespoceRL1(undefined)
        setIsLoadingSelectRL1(false)
      } catch (err) {
      setIsLoadingSelectRL1(false)
      alert(err)
    }
  };
  useEffect(()=>{
    const fetchAdjustmentOptions = async () => {
      try {
        setIsLoadingSelectRL1(true)
        // setTimeout(() => {
          //   setIsLoadingSelectRL1(false);
      // }, 3000);
            const response: any = await AdjustmentOptionsService.getAdjustmentOptionsByClientIdAndTaxYear(id,taxYear);
            if (response) {
              setAdjustmentOptionsResponse(response);
              console.log("response-=12",response)
              console.log("AdjustmentOptionsResponse-=12",AdjustmentOptionsResponse)
              if(response && response.transferOverRemittanceToSlips !== null && response.transferOverRemittanceToSlips && response.transferOverRemittanceToSlips.length === 0){
                setT4RemainingAmountValues(undefined)
                setRL1RemainingAmountValues(undefined)
              }
              if(response && response.remittanceTransferred && response.remittanceTransferred === true){
                console.log("ksfysohdsdv")
                setUndoTransferRemittanceButton("show")
                setUndoTransferRemittanceButton("show")


                const ids_list = response.transferOverRemittanceToSlips.map((ids: any) => `slip_ids=${ids.id}`).join('&');
                const t4SlipsIds: any = ids_list.replace(/^&+|&+$/g, '');
                console.log("t4SlipsIds",t4SlipsIds)
                if(t4SlipsIds.length > 0){
                  try {
                    setIsLoadingSelectRL1(true)
                    const response: any = await AdjustmentService.getT4OverRemittanceData(id, taxYear,t4SlipsIds);
                    if(response){
                      setUndoTransferT4Items(response)
                    }
                  }catch(err){
                    console.log("err",err)
                  }
                }
                
                
                let rl1SlipsIds = response.transferOverRemittanceToSlips.filter((item:any) => item.resourceFirstName.endsWith("(QC)")).map((ids: any) => `slip_ids=${ids.id}`).join('&');
                console.log("rl1SlipsIds",rl1SlipsIds)
                if(rl1SlipsIds.length > 0){
                  try {
                    setIsLoadingSelectRL1(true)
                    const response: any = await AdjustmentService.getRL1OverRemittanceData(id, taxYear,rl1SlipsIds);
                    if(response){
                      setUndoTransferRL1Items(response)
                    }
                  }catch(err){
                    console.log("err",err)
                  }
                }

                // let rl1SlipsIds = response.transferOverRemittanceToSlips.filter((item:any) => item.resourceFirstName.endsWith("(QC)")).map((item:any) => item.id)
                // let t4SlipsIds = response.transferOverRemittanceToSlips.map((item:any) => item.id)
                try {
                  setIsLoadingSelectRL1(true)
                  const response: any = await AdjustmentService.getT4Adjutments(id, taxYear);
                  if(response){
                    const t4UndoRequestPayload: IRemittanceRequestPayload = {
                      resourceRemittance: formMethods.getValues('remittance'),
                      overUnderRemittance: ((response && response[0].overUnderRemittance) + (response[1] && response[1].overUnderRemittance ? response[1].overUnderRemittance : 0)),
                      slipType: selectedSlipType,
                    };
                    console.log("t4UndoRequestPayload",response)
                    console.log("t4UndoRequestPayload",t4UndoRequestPayload)
                    setT4UndoRemittanceRequestPayload(t4UndoRequestPayload)
                    setT4AmountToBeTransferred(t4UndoRequestPayload)
                  }
                }catch(err){
                  console.log("err",err)
                }
                try {
                  setIsLoadingSelectRL1(true)
                  const response: any = await AdjustmentService.getRL1Adjutments(id, taxYear);
                  if(response){
                    const rl1UndoRequestPayload: IRemittanceRequestPayload = {
                      resourceRemittance: formMethods.getValues('remittance11'),
                      overUnderRemittance: response && response.overUnderRemittance,
                      slipType: selectedSlipType,
                    };
                    setRL1UndoRemittanceRequestPayload(rl1UndoRequestPayload)
                    setRL1AmountToBeTransferred(rl1UndoRequestPayload)
                  }
                }catch(err){
                  console.log("err",err)
                }
              } else {
                setUndoTransferRemittanceButton(undefined)
                setUndoTransferRemittanceButton(undefined)
              }
            } else {
              console.log("654326412")
              setAdjustmentOptionsResponse(undefined);
            }
            setIsLoadingSelectRL1(false)
          } catch (error) {
            setIsLoadingSelectRL1(false)
            console.error("Error fetching data:", error);
          }
    };
    fetchAdjustmentOptions();
  
    console.log("AdjustmentOptionsResponse-=b",AdjustmentOptionsResponse)
  },[])

useEffect(() => {
  
  
  const fetchDataRL1 = async () => {
    try {
      setIsLoadingSelectRL1(true)
      // setTimeout(() => {
        //   setIsLoadingSelectRL1(false);
    // }, 3000);
          const response: any = await AdjustmentService.getRL1Adjutments(id, taxYear);
          if (Array.isArray(response)) {
            setRespoceRL1(undefined);
          } else {
            setRespoceRL1(response);
            if(AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips === null
              && AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips && AdjustmentOptionsResponse.transferOverRemittanceToSlips.length === 0){
              RL1RemainingAmount = parseFloat(response && response.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              setRL1RemainingAmountValues(parseFloat(response && response.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
            } else if(AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips !== null &&
              AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips && AdjustmentOptionsResponse.transferOverRemittanceToSlips.length !== 0){
                const hasQC = AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips.some((item:any) => item.resourceFirstName.endsWith("(QC)"));
                if(!hasQC){
                  RL1RemainingAmount = parseFloat(response && response.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  setRL1RemainingAmountValues(parseFloat(response && response.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
                  console.log("RL1RemainingAmount987",RL1RemainingAmount)
                }
                // console.log("fgh",AdjustmentOptionsResponse.transferOverRemittanceToSlips);
                // console.log(hasQC ? 'Yessssssssss' : 'No000000000');
                // console.log("hasQC",hasQC);
              }
          }
          setIsLoadingSelectRL1(false)
          console.log("RL1RemainingAmount987",RL1RemainingAmount)
        } catch (error) {
          setIsLoadingSelectRL1(false)
          console.error("Error fetching data:", error);
        }
      };
      fetchDataRL1();
      
      const fetchDataT4 = async () => {
    try {
      setIsLoadingSelectRL1(true)
      // setTimeout(() => {
        //   setIsLoadingSelectRL1(false);
        // }, 3000);
        const response: any = await AdjustmentService.getT4Adjutments(id, taxYear);
        console.log("responseop",response)
        if (response && response[0] && response[0].overUnderRemittance === undefined) {
          setRespoceT4(undefined);
      } else {
        console.log("AdjustmentOptionsResponse-=",AdjustmentOptionsResponse)
        // setRespoceT4(response);
        setRespoceT4(response)
        if(AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips === null){
          console.log("Object.keys(response[1]).length",Object.keys(response[1]).length)
          if(response !== null && response[1] && Object.keys(response[1]).length > 0){
            console.log("Object.keys(response[1]).length inside",Object.keys(response[1]).length)
            T4RemainingAmount = parseFloat(response[0].overUnderRemittance + response[1].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            setT4RemainingAmountValues(parseFloat(((response && response[0].overUnderRemittance) + (response[1] && response[1].overUnderRemittance ? response[1].overUnderRemittance : 0))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
            console.log("T4RemainingAmount12e",response[1].overUnderRemittance)
            console.log("T4RemainingAmount12e",T4RemainingAmount)
          }else if(response !== null) {
            console.log("T4RemainingAmount12e",T4RemainingAmount)
            T4RemainingAmount = parseFloat(response && response[0].overUnderRemittance && response[0].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            setT4RemainingAmountValues(parseFloat(response && response[0].overUnderRemittance && response[0].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
          }
        } else if(AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips && AdjustmentOptionsResponse.transferOverRemittanceToSlips.length === 0){
          console.log("23456789",response)
          if(response !== null && response[1] && Object.keys(response[1]).length > 0){
            T4RemainingAmount = parseFloat(((response && response[0].overUnderRemittance) + (response[1] && response[1].overUnderRemittance ? response[1].overUnderRemittance : 0))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            setT4RemainingAmountValues(parseFloat(((response && response[0].overUnderRemittance) + (response[1] && response[1].overUnderRemittance ? response[1].overUnderRemittance : 0))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
            console.log("T4RemainingAmount12e",T4RemainingAmount)
          }else if(response !== null) {
            T4RemainingAmount = parseFloat(response && response[0].overUnderRemittance && response[0].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            setT4RemainingAmountValues(parseFloat(response && response[0].overUnderRemittance && response[0].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
            console.log("T4RemainingAmount12e",T4RemainingAmount)
          }
          console.log("T4RemainingAmount12e",T4RemainingAmount)
        }
      }
      setIsLoadingSelectRL1(false)
      console.log("responset4324",responceT4)
    } catch (error) {
      setIsLoadingSelectRL1(false)
      console.error("Error fetching data:", error);
    }
  };
  fetchDataT4();
  
}, [AdjustmentOptionsResponse,RL1RemainingAmount]);
console.log("RL1RemainingAmount987out",RL1RemainingAmount)
console.log("responset4324",responceT4)

let nonQuebecSlipList = AdjustmentOptionsResponse?.transferOverRemittanceToSlips !== undefined && AdjustmentOptionsResponse?.transferOverRemittanceToSlips
let QuebecSlipList: any = [];
{nonQuebecSlipList &&
  nonQuebecSlipList.map((element: any) => {
    const checkingProvince: any = element.resourceFirstName
    delete element.selectResource;
    element.slipId = element.id;
    const splitingWords = checkingProvince.split(' ');
    const lastOneWords = splitingWords.slice(-1).join(' ');
    if (lastOneWords === "(QC)") {
      QuebecSlipList.push(element)
    }
  })
}
console.log("nonQuebecSlipList",nonQuebecSlipList)
const nonQuebecListLength = (nonQuebecSlipList && nonQuebecSlipList.length)
console.log("nonQuebecListLength",nonQuebecListLength)
const QuebecListLength =  (QuebecSlipList && QuebecSlipList.length)
// const totalOverUnderRemittanceWithReduceEI = (responceT4 && responceT4[0] && responceT4[0].overUnderRemittance) + (responceT4[1].overUnderRemittance && responceT4[1].overUnderRemittance)
// console.log("totalOverUnderRemittanceWithReduceEI",totalOverUnderRemittanceWithReduceEI)
const dividerT4OverUnderRemittance = responceT4 && responceT4[0] && responceT4[0].overUnderRemittance !== undefined ? ((((responceT4 && responceT4[0].overUnderRemittance && responceT4[0].overUnderRemittance) + (responceT4[1] && responceT4[1].overUnderRemittance ? responceT4[1].overUnderRemittance : 0)) / nonQuebecListLength)* -1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
const dividerRL1OverUnderRemittance = responceRL1 ? ((responceRL1 && responceRL1.overUnderRemittance / QuebecListLength)* -1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
const formMethods = useForm<any>({
  mode: "onBlur",
  reValidateMode: "onChange",
});

const onSubmit = (formData: any) => {
  console.log("formDataformData",formData)
  console.log("openDialogue",openDialog)
  
  const t4RemittanceArray = formData.remittance && formData.remittance.length > 0 ? formData.remittance.map((item: any) => ({
    ...item,
    resourceRemittanceValue:parseFloat(item.resourceRemittanceValue && item.resourceRemittanceValue.replace(/[$,]/g, ''))
    })) : [];
  const rl1RemittanceArray = formData.remittance11 && formData.remittance11.length > 0 ? formData.remittance11.map((item: any) => ({
    ...item,
    resourceRemittanceValue:parseFloat(item.resourceRemittanceValue && item.resourceRemittanceValue.replace(/[$,]/g, ''))
    })) : [];
  console.log("responceT4yoo",rl1RemittanceArray)
  // setDialogOpen(false)
  const t4RequestPayload: IRemittanceRequestPayload = {
    resourceRemittance: t4RemittanceArray,
    overUnderRemittance: ((responceT4 && responceT4[0].overUnderRemittance) + (responceT4[1] && responceT4[1].overUnderRemittance ? responceT4[1].overUnderRemittance : 0)),
    slipType: selectedSlipType,
  };
  const rl1RequestPayload: IRemittanceRequestPayload = {
    resourceRemittance: rl1RemittanceArray,
    overUnderRemittance: responceRL1 && responceRL1.overUnderRemittance,
    slipType: selectedSlipType,
  };

  let allRemittancesForT4AndRL1 = rl1RemittanceArray ? [...t4RemittanceArray,...rl1RemittanceArray] : [...t4RemittanceArray]
  let differenceSlipDataName = []
  let differenceT4SlipDataName = []
  let differenceRL1SlipDataName = []
  console.log("allRemittancesForT4AndRL1",allRemittancesForT4AndRL1)
  for (let i = 0; i < allRemittancesForT4AndRL1.length; i++){
    console.log("123zz",allRemittancesForT4AndRL1[i].resourceRemittanceValue)
    if(allRemittancesForT4AndRL1[i].resourceRemittanceValue >= (allRemittancesForT4AndRL1[i].employmentIncome/2)){
      differenceSlipDataName.push(allRemittancesForT4AndRL1[i].resourceFirstName)
    }
  }
  for (let i = 0; i < t4RemittanceArray.length; i++){
    console.log("123zz",t4RemittanceArray[i].resourceRemittanceValue)
    if(t4RemittanceArray[i].resourceRemittanceValue >= (t4RemittanceArray[i].employmentIncome/2)){
      differenceT4SlipDataName.push(t4RemittanceArray[i].resourceFirstName)
    }
  }
  if(rl1RemittanceArray){
    for (let i = 0; i < rl1RemittanceArray.length; i++){
      console.log("123zz",rl1RemittanceArray[i].resourceRemittanceValue)
      if(rl1RemittanceArray[i].resourceRemittanceValue >= (rl1RemittanceArray[i].employmentIncome/2)){
        differenceRL1SlipDataName.push(rl1RemittanceArray[i].resourceFirstName)
      }
    }
}
  console.log("differenceSlipDataName",differenceSlipDataName)
  console.log("differenceSlipDataName",differenceSlipDataName.length)
  if( T4RemainingAmount === 0 && RL1RemainingAmount === 0){
    if(differenceSlipDataName.length > 0 && openDialog === false){
      console.log("Amount to be transferred is greater than 50% of employment income for " + differenceSlipDataName.join(", ") + "." + "\n\nConfirm to continue.")
      setDialogMessage("Amount to be transferred is greater than 50% of employment income for");
      setDialogMessageT4(differenceT4SlipDataName.join(", "));
      setDialogMessageRL1(differenceRL1SlipDataName.join(", "));
      setDialogOpen(true)
    } else {
      setDialogOpen(false)
    console.log("t4RequestPayload?.resourceRemittance?.length",t4RequestPayload?.resourceRemittance?.length)
    if(t4RequestPayload?.resourceRemittance?.length > 0){
      const fetchData = async () => {
        try {
          setIsLoadingSelectRL1(true)
          // setTimeout(() => {
            //   setIsLoadingSelectRL1(false);
            // }, 2000);
            const response = await AdjustmentService.transfert4remittance(id, taxYear, t4RequestPayload);
            if(response){
              setT4AmountToBeTransferred(response)
              setUndoTransferRemittanceButton("show")
              window.location.reload()
            }
            setIsLoadingSelectRL1(false)
            setSnackbarData({
              open: true,
            message: response.message ? response.message : "Something went wrong.",
            severity: response.status === true ? "success" : "error",
          });
        } catch (err) {
          setIsLoadingSelectRL1(false)
          console.log("err",err)
        }
      };
      fetchData();
    } 
    console.log("rl1RequestPayload.resourceRemittance?.length",rl1RequestPayload.resourceRemittance?.length)
    if(rl1RequestPayload.resourceRemittance?.length > 0){
      const fetchRl1remittanceData = async () => {
        try {
          setIsLoadingSelectRL1(true)
          // setTimeout(() => {
            //   setIsLoadingSelectRL1(false);
          // }, 2000);
          const response = await AdjustmentService.transferrl1remittance(id, taxYear, rl1RequestPayload);
          if(response){
            setRL1AmountToBeTransferred(response)
            setUndoTransferRemittanceButton("show")
          }
          setIsLoadingSelectRL1(false)
          setSnackbarData({
            open: true,
            message: "RL1 Adjustment Successfully Transferrred.",
            severity: "success",
          });
        } catch (err) {
          setIsLoadingSelectRL1(false)
          console.log("err",err)
          // alert(err)
        }
      };
      fetchRl1remittanceData();
    } 
    if(responceT4){
      let totalCodeSum = 0;
      // t4RemittanceArray && t4RemittanceArray.map((listData:any) => {
      //   totalCodeSum += parseFloat(listData.resourceRemittanceValue);
      // })
      totalCodeSum = undoTransferT4Items && undoTransferT4Items.reduce((sum: any, item: any) => (sum) + (item.resourceRemittanceValue), 0);
      console.log("totalCodeSum",totalCodeSum)
      const t4TotalAmountTwoDecimal = Number(totalCodeSum && totalCodeSum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
      console.log("t4TotalAmountTwoDecimal",t4TotalAmountTwoDecimal)
      const remaingAmounttt = t4TotalAmountTwoDecimal + parseFloat(((responceT4 && responceT4[0].overUnderRemittance) + (responceT4[1] && responceT4[1].overUnderRemittance ? responceT4[1].overUnderRemittance : 0)))
      setT4RemainingAmountValues(remaingAmounttt)
    }
    if(responceRL1){
      let totalCodeSum11 = 0;
      // rl1RemittanceArray && rl1RemittanceArray.map((listData:any) => {
      //   totalCodeSum11 += parseFloat(listData.resourceRemittanceValue);
      // })
      totalCodeSum11 = undoTransferRL1Items && undoTransferRL1Items.reduce((sum: any, item: any) => (sum) + (item.resourceRemittanceValue), 0);
      const rl1TotalAmountTwoDecimal = Number(totalCodeSum11 && totalCodeSum11.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
      const remaingAmounttt11 = rl1TotalAmountTwoDecimal + parseFloat(responceRL1 && responceRL1.overUnderRemittance)
      setRL1RemainingAmountValues(remaingAmounttt11)
    }
  }
  } else {
    setSnackbarData({
      open: true,
      message: "Remaining Amount must be equal to 0",
      severity: "error",
    });
  }
if(responceT4){
  const t4UndoRequestPayload: IRemittanceRequestPayload = {
    // resourceRemittance: t4RemittanceArrayT4,
    resourceRemittance: t4RemittanceArray,
    overUnderRemittance: ((responceT4 && responceT4[0].overUnderRemittance) + (responceT4[1] && responceT4[1].overUnderRemittance ? responceT4[1].overUnderRemittance : 0)),
    slipType: selectedSlipType,
  };
  setT4UndoRemittanceRequestPayload(t4UndoRequestPayload)
}
if(responceRL1){
  const rl1UndoRequestPayload: IRemittanceRequestPayload = {
    // resourceRemittance: rl1RemittanceArrayRL1,
    resourceRemittance: rl1RemittanceArray,
    overUnderRemittance: responceRL1 && responceRL1.overUnderRemittance,
    slipType: selectedSlipType,
  };
  setRL1UndoRemittanceRequestPayload(rl1UndoRequestPayload)
}

// setT4RemittanceRequestPayload(t4RequestPayload)

 

  
  // setRL1RemittanceRequestPayload(rl1RequestPayload)


  // window.location.reload()
};

// const onClickHandlerTransfirRemmitance = async (): Promise<void> => {
//   if(T4RemittanceRequestPayload){
//   try {
//     setIsLoadingSelectRL1(true)
//     setTimeout(() => {
//       setIsLoadingSelectRL1(false);
//     }, 2000);
//     console.log("T4RemittanceRequestPayloadT4RemittanceRequestPayload",T4RemittanceRequestPayload)
//     const response = await AdjustmentService.transfert4remittance(id, taxYear, T4RemittanceRequestPayload && T4RemittanceRequestPayload);
//     setT4AmountToBeTransferred(response)
//   } catch (err) {
//     console.log("err",err)
//     // alert(err)
//   }
// }
// if(T4RemittanceRequestPayload){
//   try {
//     setIsLoadingSelectRL1(true)
//     setTimeout(() => {
//       setIsLoadingSelectRL1(false);
//     }, 2000);
//     const response = await AdjustmentService.transferrl1remittance(id, taxYear, RL1RemittanceRequestPayload && RL1RemittanceRequestPayload);
//     setRL1AmountToBeTransferred(response)
//   } catch (err) {
//     console.log("err",err)
//     // alert(err)
//   }
// }
// };
// const [t4UndoRemittance, setT4UndoRemittance] = useState<any>(undefined)
// const [rl1UndoRemittance, setRL1UndoRemittance] = useState<any>(undefined)
const onClickHandlerUndoTransfirRemmitance = async (): Promise<void> => {
let t4UndoRemittance : any = undefined
let rl1UndoRemittance : any = undefined
if(T4UndoRemittanceRequestPayload){
  // try {
    setIsLoadingSelectRL1(true)
    console.log("T4UndoRemittanceRequestPayload--inside",T4UndoRemittanceRequestPayload)

    let T4UndoRemittancePayload = {
      "resourceRemittance":undoTransferT4Items,
      overUnderRemittance:T4UndoRemittanceRequestPayload.overUnderRemittance,
      slipType:T4UndoRemittanceRequestPayload.slipType,
    }
    
    console.log("T4UndoRemittancePayload",T4UndoRemittancePayload)

    const response = await AdjustmentService.undotransfert4remittance(id, taxYear, undoTransferT4Items ? T4UndoRemittancePayload : T4UndoRemittanceRequestPayload);
    if(response.message === "Undo T4 Remittance successfully."){
      t4UndoRemittance = response.message
      // setUndoTransferRemittanceButton(undefined)
      setSnackbarData({
        open: true,
        message: response.message,
        severity: "success",
      });
    } else {
      setSnackbarData({
        open: true,
        message: response.message ? `T4 ${response.message}` : "Something went wrong.",
        severity: "error",
      });
    }
    setIsLoadingSelectRL1(false)
}
if(RL1UndoRemittanceRequestPayload){
  // try {
    setIsLoadingSelectRL1(true)
    let RL1UndoRemittancePayload = {
      "resourceRemittance":undoTransferRL1Items,
      overUnderRemittance:RL1UndoRemittanceRequestPayload.overUnderRemittance,
      slipType:RL1UndoRemittanceRequestPayload.slipType,
    }

    const response = await AdjustmentService.undotransferrl1remittance(id, taxYear, undoTransferRL1Items ? RL1UndoRemittancePayload : {"resourceRemittance":[],overUnderRemittance:0,slipType:400});
    if(response.message === "Undo RL1 Remittance successfully."){
      rl1UndoRemittance = response.message
      // setUndoTransferRemittanceButton(undefined)
      setSnackbarData({
        open: true,
        message: response.message,
        severity: "success",
      });
      console.log("tfedavjf",rl1UndoRemittance)
    } else {
      setSnackbarData({
        open: true,
        message: response.message ? `RL1 ${response.message}` : "Something went wrong.",
        severity: "error",
      });
    }
    console.log("tfedavjfhvb",rl1UndoRemittance)
    // setRL1AmountToBeTransferred(undefined)
    // setIsLoadingSelectRL1(false);
    // } catch (err) {
      //   console.log("err",err)
      //   alert(err)
      // }
    }
    if(t4UndoRemittance && rl1UndoRemittance){
      setUndoTransferRemittanceButton(undefined)
      // setT4AmountToBeTransferred(undefined)
      // setRL1AmountToBeTransferred(undefined)
      window.location.reload()
      setIsLoadingSelectRL1(false)
} else {
  setIsLoadingSelectRL1(false)
}
};
console.log("T4UndoRemittanceRequestPayload",T4UndoRemittanceRequestPayload)
console.log("RL1UndoRemittanceRequestPayload",RL1UndoRemittanceRequestPayload)
console.log("undoTransferRemittanceButton",undoTransferRemittanceButton)


console.log("gettt resourceRemittanceValue",formMethods.getValues('remittance.0.resourceRemittanceValue'))
console.log("remitanceTransfer ....",remitanceTransfer)
console.log("T4RemainingAmount0000",T4RemainingAmount)
if(nonQuebecSlipList && responceT4 && responceT4[0].overUnderRemittance && remitanceTransfer === true){
let totalValue:any = 0;
    
    for (let i = 0; i < nonQuebecSlipList.length; i++) {
      const key = `remittance.${i}.resourceRemittanceValue`;
      const value = formMethods.getValues(key);
    
    // Ensure value is a number (parse if necessary)
    const numericValue = value && value !== '' ? parseFloat(value.replace(/[$,]/g, '')) : 0;
    console.log('value',value)
    console.log('numericValue',numericValue)
    console.log('totalValue',totalValue)
    
    if (!isNaN(numericValue)) {
      totalValue += numericValue;
    }
    
    console.log(`${key}:t4--- ${value}`);
  }
  console.log("totalValuetotalValuet4 remit",totalValue)
  if(totalValue !== 0){
  // const totalAmountTwoDecimal = Number(totalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  const totalAmountTwoDecimal = Number(totalValue.toFixed(2))

  const remaingAmounttt = totalAmountTwoDecimal + parseFloat(((responceT4 && responceT4[0].overUnderRemittance) + (responceT4[1] && responceT4[1].overUnderRemittance ? responceT4[1].overUnderRemittance : 0)))
  T4RemainingAmount = remaingAmounttt
  console.log("totalAmountTwoDecimal",totalAmountTwoDecimal)
  console.log("T4RemainingAmount",T4RemainingAmount)
} else {
  console.log("responceT4--",responceT4)
  if(responceT4){
    T4RemainingAmount = parseFloat(((responceT4 && responceT4[0].overUnderRemittance) + (responceT4[1] && responceT4[1].overUnderRemittance ? responceT4[1].overUnderRemittance : 0)))
  }else{
    T4RemainingAmount = 0
  }
}

  // console.log("T4RemainingAmount22",responceT4 && responceT4.overUnderRemittance)
  console.log("T4RemainingAmount22",T4RemainingAmount)
}

console.log("undoRemitanceTransfer falseeee",undoRemitanceTransfer)
if(nonQuebecSlipList && undoRemitanceTransfer === true){
  let totalValue:any = 0;
      
    //   for (let i = 0; i < nonQuebecSlipList.length; i++) {
    //     const key = `remittanceT4.${i}.resourceRemittanceValue`;
    //     const value = formMethods.getValues(key);
      
    //   // Ensure value is a number (parse if necessary)
    //   const numericValue = parseFloat(value);
      
    //   if (!isNaN(numericValue)) {
    //     totalValue += numericValue;
    //   }
      
    //   console.log(`${key}: ${value}`);
    // }
    totalValue = undoTransferT4Items && undoTransferT4Items.reduce((sum: any, item: any) => (sum) + (item.resourceRemittanceValue), 0);
    console.log("totalValuetotalValueundo t4",totalValue)  
    if(totalValue && totalValue !== 0){
      // const totalAmountTwoDecimal = Number(totalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
      const totalAmountTwoDecimal = Number(totalValue.toFixed(2))
    
      const remaingAmounttt = totalAmountTwoDecimal + parseFloat(((responceT4 && responceT4[0].overUnderRemittance) + (responceT4[1] && responceT4[1].overUnderRemittance ? responceT4[1].overUnderRemittance : 0)))
      T4RemainingAmount = remaingAmounttt
      console.log("totalAmountTwoDecimal",totalAmountTwoDecimal)
    } else {
      console.log("insiiiiiiiiiiiiiiiiiide",responceT4)
      if(responceT4){
        T4RemainingAmount = parseFloat(((responceT4 && responceT4[0].overUnderRemittance) + (responceT4[1] && responceT4[1].overUnderRemittance ? responceT4[1].overUnderRemittance : 0)))
      }else{
        T4RemainingAmount = 0
      }
    }
  }


console.log("then loadRl1RemainingAmount",loadRl1RemainingAmount)
if(QuebecSlipList){
  let totalValueRL1 = 0;
    
    for (let i = 0; i < QuebecSlipList.length; i++) {
      const key = `remittance11.${i}.resourceRemittanceValue`;
      const value = formMethods.getValues(key);
      // Ensure value is a number (parse if necessary)
      // const numericValue = parseFloat(value && value);
      const numericValue = value && value !== '' ? parseFloat(value.replace(/[$,]/g, '')) : 0;
      
      if (!isNaN(numericValue)) {
        totalValueRL1 += numericValue;
      }
      
      console.log(`${key}:rl1 ${value}`);
  }
  console.log("totalValueRL1totalValueRL1",totalValueRL1)
  // const totalAmountRL1TwoDecimal = Number(totalValueRL1.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  if(totalValueRL1 && totalValueRL1 !== 0){
  const remaingAmountRL1 = totalValueRL1 + parseFloat(responceRL1 && responceRL1.overUnderRemittance)
  RL1RemainingAmount = remaingAmountRL1
  console.log("RL1RemainingAmount11",responceRL1 && responceRL1.overUnderRemittance)
  console.log("RL1RemainingAmount11 inside",RL1RemainingAmount)
  // setDataa(remaingAmountRL1)
  } else {
    console.log("RL1RemainingAmount innnn",RL1RemainingAmount)
    if(responceRL1){
      RL1RemainingAmount = parseFloat(responceRL1 && responceRL1.overUnderRemittance)
    } else {
      RL1RemainingAmount = 0
    }
  }
}

console.log("undoRemitanceTransferRL1..",undoRemitanceTransferRL1)
if(QuebecSlipList && undoRemitanceTransferRL1 === true){
  let totalValueRL1 = 0;
    
  //   for (let i = 0; i < QuebecSlipList.length; i++) {
  //     const key = `undoremittance11.${i}.resourceRemittanceValue`;
  //     const value = formMethods.getValues(key);
  //     // Ensure value is a number (parse if necessary)
  //     const numericValue = parseFloat(value && value);
      
  //     if (!isNaN(numericValue)) {
  //       totalValueRL1 += numericValue;
  //     }
      
  //     console.log(`${key}:rl1 ${value}`);
  // }
  // console.log("totalValueRL1totalValueRL1",totalValueRL1)
  // const totalAmountRL1TwoDecimal = Number(totalValueRL1.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))

  totalValueRL1 = undoTransferRL1Items && undoTransferRL1Items.reduce((sum: any, item: any) => (sum) + (item.resourceRemittanceValue), 0);
  console.log("totalValueRL1123",totalValueRL1)
  if(totalValueRL1 && totalValueRL1 !== 0){
    const remaingAmountRL1 = ((totalValueRL1) + (parseFloat(responceRL1 && responceRL1.overUnderRemittance)))
    RL1RemainingAmount = remaingAmountRL1
    console.log("RL1RemainingAmount11",responceRL1 && responceRL1.overUnderRemittance)
    console.log("RL1RemainingAmount11 inside",RL1RemainingAmount)
  } else {
    console.log("RL1RemainingAmount innnn2",RL1RemainingAmount)
    if(responceRL1){
      RL1RemainingAmount = parseFloat(responceRL1 && responceRL1.overUnderRemittance)
    } else {
      RL1RemainingAmount = 0
    }
  }
}

const onClickHandlerIgnoreTransfirRemmitance = async (): Promise<void> => {
  try {
    setIsLoadingSelectData(true)
    // setTimeout(() => {
      //   setIsLoadingSelectData(false);
      // }, 2000);
      let payload = {
        ...AdjustmentOptionsResponse,
        ignoreRemittanceTransferred:true,
        clientId:id,
        adjustmentType:'ignore_remittance_transfers',
        taxYear:taxYear
      }

      const response : any = await AdjustmentOptionsService.create(payload);
      if(response.success === false){
        setSnackbarData({
          open: true,
          message: response.message ? response.message : "Something went wrong!",
          severity: "error",
        });
      }else{
      setSnackbarData({
        open: true,
        message: "Data has been submitted successfully!",
        severity: "success",
      });
      window.location.reload()
    }
      // setRespoceT4(undefined)
      setIsLoadingSelectData(false)
    } catch (err) {
    setIsLoadingSelectData(false)
    alert(err)
  }
};

const onClickHandlerUndoIgnoreTransfirRemmitance = async (): Promise<void> => {
  try {
    setIsLoadingSelectData(true)
    // setTimeout(() => {
      //   setIsLoadingSelectData(false);
      // }, 2000);
      let payload = {
        ...AdjustmentOptionsResponse,
        ignoreRemittanceTransferred:false,
        clientId:id,
        adjustmentType:'undo_ignore_remittance_transfers',
        taxYear:taxYear
      }

      const response : any = await AdjustmentOptionsService.create(payload);
      if(response.success === false){
        setSnackbarData({
          open: true,
          message: response.message ? response.message : "Something went wrong!",
          severity: "error",
        });
      }else{
      setSnackbarData({
        open: true,
        message: "Data has been submitted successfully!",
        severity: "success",
      });
      window.location.reload()
    }
      // setRespoceT4(undefined)
      setIsLoadingSelectData(false)
    } catch (err) {
    setIsLoadingSelectData(false)
    alert(err)
  }
};

const onCloseHandler = () => {
  setDialogOpen(false);
};

const onConfirmedButtonClickedHandler = () => {
  setDialogOpen(false);
};




console.log("gettt 11 resourceRemittanceValue",formMethods.getValues('remittance11.0.resourceRemittanceValue'))
console.log("QuebecSlipList 11",QuebecSlipList)
console.log("dividerRL1OverUnderRemittance 11",dividerRL1OverUnderRemittance)
console.log("then")
console.log("then T4RemainingAmountValues",T4RemainingAmountValues)
console.log("then T4RemainingAmount",T4RemainingAmount)
console.log("then RL1RemainingAmount",RL1RemainingAmount)
console.log("T4AmountToBeTransferred--",T4AmountToBeTransferred)
console.log("responceT4--end",responceT4)
// console.log("AdjustmentOptionsResponse.transferOverRemittanceToSlips--end",AdjustmentOptionsResponse.transferOverRemittanceToSlips)

// useEffect(() => {
//   const fetchDataRL1 = async () => {
//     try {
//       setIsLoadingSelectRL1(true)
//     setTimeout(() => {
//       setIsLoadingSelectRL1(false);
//     }, 3000);
//           const response: any = await AdjustmentService.getRL1Adjutments(id, taxYear);
//           if (Array.isArray(response)) {
//             setRespoceRL1(undefined);
//           } else {
//             setRespoceRL1(response);
//           }
//         } catch (error) {
//           console.error("Error fetching data:", error);
//         }
//   };
//   fetchDataRL1();
//   }, []);

useEffect(()=>{
  dispatch(setSelectedActivityLogActions({
    entityId1:'',
    entityId2:'',
    entityId3:'',
    screenName:ADJUSTMENT_SCREEN_SLUG,
    companyName:clientName,
    activityType:"activity_type=t4_adjustments&activity_type=t4_undo_adjustments&activity_type=rl1_adjustments&activity_type=rl1_undo_adjustments&activity_type=remittance_transfers&activity_type=undo_remittance_transfers&activity_type=ignore_remittance_transfers&activity_type=undo_ignore_remittance_transfers",
    activityLogVisibility:true
  }));
  dispatch(setSelectedStickyNavBar({isSticky:false}));
},[])

  console.log("responceRL1-",responceRL1)
  console.log("RL1RemainingAmountValues-",RL1RemainingAmountValues)
  console.log("RL1RemainingAmount-",RL1RemainingAmount)

  console.log("responceRL1-",responceRL1)
  console.log("RL1RemainingAmountValues-",RL1RemainingAmountValues)

  console.log("responceRL1-",responceRL1)
  console.log("RL1RemainingAmountValues-",RL1RemainingAmountValues)
  console.log("T4AmountToBeTransferred-",T4AmountToBeTransferred)
  console.log("RL1RemainingAmountValues-",RL1RemainingAmountValues)
  console.log("dividerT4OverUnderRemittance-",dividerT4OverUnderRemittance)

return (
    <>
      <BackdropCircularProgress 
        open={
          // isLoadingAdjustments || 
          isLoadingActivityLogData ||
          isLoadingRL1 || 
          isLoadding
          } testId={testId} />
      <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <Grid
        container
        sx={{ marginTop: theme.spacing(0) }}
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StandardPanel
            title="T4 Adjustment"
            variant="outlined"
            action={
              <EnhancedToolTip
                title="T4 Adjustment instructions"
                tooltipTitle="T4 Adjustment instructions"
                tooltipContents={t4AdjustmentInstructions}
                testId="t4-adjustment-instructions"
              />
            }
            testId={`${testId}-t4-adjustment-panel`}
          >
            {responceT4 ?
             (
              <>
              <Typography variant="body2" sx={{ paddingBottom: 2, fontWeight: 'bold' }}>
                 {`Total Adjusted T4 Slips:  ${responceT4[0] && responceT4[0].totalSlipsAdjusted ?responceT4[0].totalSlipsAdjusted : 0} / ${responceT4[0] && responceT4[0].totalT4 ? responceT4[0].totalT4 : 0}`}
              </Typography>
              <Typography variant="body2" sx={{ paddingBottom: 2, fontWeight: 'bold', fontSize:"1rem" }}>
                Calculation of Over-remittance Available for Transfer
              </Typography>

              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography sx={{fontWeight:"bold"}}>T4 Slips</Typography>
                <Typography>{responceT4[0] && responceT4[0].totalSlipsAdjusted ? responceT4[0].totalSlipsAdjusted : 0}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>Total CPP Adjustment</Typography>
                <Typography>{parseFloat(responceT4[0] && responceT4[0].totalCppAdjustments ? responceT4[0].totalCppAdjustments : 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>Total EI Adjustment</Typography>
                <Typography>{parseFloat(responceT4[0] && responceT4[0].totalEiAdjustments ? responceT4[0].totalEiAdjustments : 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>(Over)/ Under-remittance</Typography>
                <Typography>{responceT4[0] && responceT4[0].overUnderRemittance < 0 ? `(${Math.abs(responceT4[0] && responceT4[0].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')})` : responceT4[0] && parseFloat(responceT4[0].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              {responceT4 && responceT4[1] && Object.keys(responceT4[1]).length > 0 ?
              (<Box sx={{marginTop:"1.3rem"}}>
                <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography sx={{fontWeight:"bold"}}>T4 Reduced E.I</Typography>
                <Typography>{responceT4[1] && responceT4[1].totalSlipsAdjusted}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>Total CPP Adjustment</Typography>
                <Typography>{parseFloat(responceT4[1] && responceT4[1].totalCppAdjustments ? responceT4[1].totalCppAdjustments : 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>Total EI Adjustment</Typography>
                <Typography>{parseFloat(responceT4[1] && responceT4[1].totalEiAdjustments ? responceT4[1].totalEiAdjustments : 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>(Over)/ Under-remittance</Typography>
                <Typography>{responceT4[1] && responceT4[1].overUnderRemittance < 0 ? `(${Math.abs(responceT4[1].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')})` : parseFloat(responceT4[1].overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              </Box>)
              :(<></>)
              }
              <Divider />
              <Typography variant="body2" sx={{ paddingBottom: 2, paddingTop: 2, fontWeight: 'bold' }}>
                  AMOUNT(S) TO BE TRANSFERRED
              </Typography>
              <Box sx={{ paddingBottom: 2, display:"flex",justifyContent:"space-between"}}>
                <Typography sx={{ fontWeight: 'bold'}}>Employee T4</Typography>
                <Typography sx={{ fontWeight: 'bold'}}>T4</Typography>
              </Box>
              {T4AmountToBeTransferred ?
              (<UndoRemittanceTransferEmployeeSetup
                formMethods={formMethods}
                // items={nonQuebecSlipList && nonQuebecSlipList}
                items={undoTransferT4Items}
                dividerOverUnderRemittance={dividerT4OverUnderRemittance}
                testId="RemittanceTransferEmployeeSetup"
                undoRemitanceTransfer={(value) => setUndoRemitanceTransfer(value)}
                />):
                <RemittanceTransferEmployeeSetup
                formMethods={formMethods}
                items={nonQuebecSlipList && nonQuebecSlipList}
                dividerOverUnderRemittance={dividerT4OverUnderRemittance && dividerT4OverUnderRemittance}
                testId="RemittanceTransferEmployeeSetup"
                remitanceTransfer={(value) => setRemitanceTransfer(value)}
                />

              }
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between", fontWeight: 'bold'}}>
                <Typography sx={{ fontWeight: 'bold'}}>Remaining Amount</Typography>
                <Typography sx={{ fontWeight: 'bold'}}>{T4RemainingAmountValues ? T4RemainingAmountValues : Number(T4RemainingAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              </>
            ) :
            <Typography variant="body2" sx={{ paddingBottom: 2 }}>
              {t4EmptyAdjustmentMessage}
            </Typography>}
            {responceT4 ?
             (
            <Button
              size="medium"
              startIcon={<ArrowForwardIosIcon />}
              data-testid={testId}
              disabled={AdjustmentOptionsResponse && AdjustmentOptionsResponse.remittanceTransferred === true ? true : 
                undoTransferRemittanceButton === "show" ? true : false}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
                textTransform: "none",
              }}
              onClick={onClickHandlerUndoForT4}
            >
              Undo All T4 Slips
            </Button>
            ) :
            <Button
              size="medium"
              startIcon={<ArrowForwardIosIcon />}
              data-testid={testId}
              disabled={loading}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
                textTransform: "none",
              }}
              onClick={onClickHandlerForT4}
            >
              Adjust All T4 Slips
            </Button>}
          </StandardPanel>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StandardPanel
            title="RL-1 Adjustment"
            variant="outlined"
            action={
              <EnhancedToolTip
                title="RL-1 Adjustment instructions"
                tooltipTitle="RL-1 Adjustment instructions"
                tooltipContents={rl1AdjustmentInstructions}
                testId="rl1-adjustment-instructions"
              />
            }
            testId={`${testId}-rl1-adjustment-panel`}
          >
            {responceRL1 ?
             (
              <>
              <Typography variant="body2" sx={{ paddingBottom: 2, fontWeight: 'bold' }}>
                 {`Total Adjusted RL-1 Slips: ${responceRL1.totalSlipsAdjusted ? responceRL1.totalSlipsAdjusted : 0} / ${responceRL1.totalRl1Slips ? responceRL1.totalRl1Slips : 0}`}
              </Typography>
              <Typography variant="body2" sx={{ paddingBottom: 2, fontWeight: 'bold'}}>
                Calculation of Over-remittance Available for Transfer
              </Typography>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>RL1 Slips</Typography>
                <Typography>{responceRL1.totalSlipsAdjusted ? responceRL1.totalSlipsAdjusted : 0}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>Total QPIP Adjustment</Typography>
                <Typography>{responceRL1.totalQpipAdjustments ? parseFloat(responceRL1.totalQpipAdjustments).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>Total QPP Adjustment</Typography>
                <Typography>{responceRL1.totalQppAdjustments ? parseFloat(responceRL1.totalQppAdjustments).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>(Over)/ Under-remittance</Typography>
                <Typography>{responceRL1.overUnderRemittance < 0 ? `(${Math.abs(responceRL1.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')})` : parseFloat(responceRL1.overUnderRemittance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              <Divider />
              <Typography variant="body2" sx={{ paddingBottom: 2, paddingTop: 2, fontWeight: 'bold' }}>
                  AMOUNT(S) TO BE TRANSFERRED
              </Typography>
              <Box sx={{ paddingBottom: 2, display:"flex",justifyContent:"space-between"}}>
                <Typography sx={{ fontWeight: 'bold'}}>Employee RL-1</Typography>
                <Typography sx={{ fontWeight: 'bold'}}>RL-1</Typography>
              </Box>
              { RL1AmountToBeTransferred ?
              (<UndoRL1RemittanceTransferEmployeeSetup
                formMethods={formMethods}
                // items={QuebecSlipList && QuebecSlipList}
                items={undoTransferRL1Items}
                dividerOverUnderRemittance={dividerRL1OverUnderRemittance && dividerRL1OverUnderRemittance}
                testId="RL1RemittanceTransferEmployeeSetup"
                undoRemitanceTransferRL1={(value) => setUndoRemitanceTransferRL1(value)}
                />):
                <RL1RemittanceTransferEmployeeSetup
                formMethods={formMethods}
                items={QuebecSlipList && QuebecSlipList}
                dividerOverUnderRemittance={dividerRL1OverUnderRemittance && dividerRL1OverUnderRemittance}
                testId="RL1RemittanceTransferEmployeeSetup"
                loadRL1RemainingAmount={(value) => setLoadRl1RemainingAmount(value)}
                RL1RemainingAmount={RL1RemainingAmount}
                />

              }

              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between", fontWeight: 'bold'}}>
                <Typography sx={{ fontWeight: 'bold'}}>Remaining Amount</Typography>
                <Typography sx={{ fontWeight: 'bold'}}>{RL1RemainingAmountValues ? RL1RemainingAmountValues : parseFloat(RL1RemainingAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              </>
            ) :
            <Typography variant="body2" sx={{ paddingBottom: 2 }}>
              {rl1EmptyAdjustmentMessage}
            </Typography>}
            {responceRL1 ?
             (
            <Button
              size="medium"
              startIcon={<ArrowForwardIosIcon />}
              data-testid={testId}
              disabled={AdjustmentOptionsResponse && AdjustmentOptionsResponse.remittanceTransferred === true ? true : 
                undoTransferRemittanceButton === "show" ? true : false}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
                textTransform: "none",
              }}
              onClick={onClickHandlerUndoForRL1}
            >
              Undo All RL1 Slips
            </Button>
            ) :
            <Button
              size="medium"
              startIcon={<ArrowForwardIosIcon />}
              data-testid={testId}
              disabled={loading}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
                textTransform: "none",
              }}
              onClick={onClickHandlerForRL1}
            >
              Adjust All RL1 Slips
            </Button>}
          </StandardPanel>
        </Grid>
        <Box sx={{ paddingTop: 3,display:"flex", paddingLeft: "330px"}}>
          {/* { responceT4 && T4AmountToBeTransferred ? */}
          { responceT4 && undoTransferRemittanceButton ?
            (
            <Button
              size="medium"
              startIcon={<ArrowForwardIosIcon />}
              data-testid={testId}
              disabled={loading}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                marginRight: '10px',
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
                textTransform: "none",
              }}
              onClick={onClickHandlerUndoTransfirRemmitance}
              // type="submit"
              
            >
              Undo Transfer Remittance
            </Button>
            ):responceT4 || responceRL1 ? 
            (
            <>
            <Button
              size="medium"
              startIcon={<ArrowForwardIosIcon />}
              data-testid={testId}
              disabled={AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips === null ||
                AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips && AdjustmentOptionsResponse.transferOverRemittanceToSlips.length === 0 ? true : false}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                marginRight: '10px',
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
                textTransform: "none",
              }}
              // onClick={onClickHandlerTransfirRemmitance}
              type="submit"
            >
              Transfer Remittance
            </Button>
            {AdjustmentOptionsResponse && AdjustmentOptionsResponse.ignoreRemittanceTransferred === false ? 
            <Button
              size="medium"
              startIcon={<ArrowForwardIosIcon />}
              data-testid={testId}
              disabled={
                // AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips === null ||
                // AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips && AdjustmentOptionsResponse.transferOverRemittanceToSlips.length === 0 ||
                AdjustmentOptionsResponse && AdjustmentOptionsResponse.remittanceTransferred === false ||
                responceT4 && responceT4[0] && responceT4[0].overUnderRemittance === undefined ||
                responceRL1 && responceRL1.length === 0 ? false : true}
              variant="contained"
              sx={{
                color: theme.palette.common.white,
                backgroundColor: '#ffbf01',
                "&:hover": {
                  backgroundColor: '#ffbf01',
                },
                textTransform: "none",
              }}
              onClick={onClickHandlerIgnoreTransfirRemmitance}
            >
              Ignore Transfer Remittance
            </Button> :
            <Button
            size="medium"
            startIcon={<ArrowForwardIosIcon />}
            data-testid={testId}
            disabled={
              // AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips === null ||
              // AdjustmentOptionsResponse && AdjustmentOptionsResponse.transferOverRemittanceToSlips && AdjustmentOptionsResponse.transferOverRemittanceToSlips.length === 0 ||
              AdjustmentOptionsResponse && AdjustmentOptionsResponse.ignoreRemittanceTransferred === true || 
              responceT4 && responceT4[0] && responceT4[0].overUnderRemittance === undefined ||
              responceRL1 && responceRL1.length === 0 ? false : true}
            variant="contained"
            sx={{
              color: theme.palette.common.white,
              backgroundColor: '#ffbf01',
              "&:hover": {
                backgroundColor: '#ffbf01',
              },
              textTransform: "none",
            }}
            onClick={onClickHandlerUndoIgnoreTransfirRemmitance}
          >
            Undo Ignore Transfer Remittance
          </Button>}
            </>):(<></>)}
          </Box>
      </Grid>
      </form>
      </FormProvider>

      {/* Dialog opens if Amount to be transferred is greater than 50% of employment income */}
        <EnhancedDialog
            dialogTitle="Transfer Remittance"
            key="dialog-box"
            testId="dialog"
            onCloseHandler={onCloseHandler}
            open={openDialog}
            dialogContent={
              <div>
                <div>{dialogMessage}</div>
                {dialogMessageT4 !== "" ?
                <><b>T4: </b><span>{dialogMessageT4}</span>
                <br/></>
                 : <></>}
                {dialogMessageRL1 !== "" ?
                  <>
                  <b>RL1: </b><span>{dialogMessageRL1}</span>
                  <br/>
                  </> 
                : <></>}
                
                <br/>
                <b>Confirm to continue.</b>
                </div>}
            dialogAction={
              <Grid>
                <Button
                  autoFocus
                  variant="contained"
                  // onClick={onConfirmedButtonClickedHandler}
                  onClick={formMethods.handleSubmit(onSubmit)}
                  type="submit"
                  // color="success"
                  // disabled={isLoadingMerging}
                >
                  Confirm
                </Button>
                <Button
                  autoFocus
                  variant="contained"
                  onClick={onCloseHandler}
                  sx={{
                    backgroundColor: "#e0e0e0",
                    "&:hover": {
                      backgroundColor: "#bdbdbd",
                    },
                    color: "#212121",
                  }}
                  style={{ marginLeft: "5px" }}
                >
                  Cancel
                </Button>
              </Grid>
            }
          />


          {/* ProgressBar Dialog */}
        <EnhancedDialog
            dialogTitle={`${progressBarTitleTag} Slips Adjustment`}
            key="dialog-box-1"
            testId="dialog"
            onCloseHandler={onCloseHandler}
            showCloseButton={false}
            open={openProgressBarDialog}
            dialogContent={
              <ProgressBar 
                value={percentageValue}
                pt={{
                    value: { style: { background: 'linear-gradient(to right, #8e2de2, #4a00e0)' } }
                }}
              ></ProgressBar>
            }
            dialogAction={
              <></>
            }
          />

      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
        autoHide={snackbarData.autoHide}
      />
    </>
  );
};
