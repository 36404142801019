import { FC } from "react";

import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Toolbar } from "@mui/material";
import { DeleteButton, DisabledButton, PrintButton, ResetButton, SplitButton, SubmitButton } from "@websential/cosmic";
import { useQuery } from "react-query";
import { RL1SlipService } from "services/RL1SlipService";
import { useSelector } from "react-redux";
import { selectedRL1SummaryType } from "components/RL1Slip/RL1SlipSummarySetup";
import { IAppState } from "store/reducers";
import { selectedRL1SlipType } from "../RL1SlipSetup";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PrintIcon from "@mui/icons-material/Print";

export interface IRL1SlipSetupToolbar {
  onResetClicked?: () => void;
  onDeleteClicked?: () => void;
  onPrintClicked: () => void;
  isSubmitting?: boolean;
  formMethods: any
}

const RL1_SUMMARY_DATA_QUERY_KEY = "get-rl1-summary-data";
const useGetRL1SummaryData = (
  clientid: string,
  slipType: string | number,
  taxYear: number) =>
  useQuery(
    [RL1_SUMMARY_DATA_QUERY_KEY, clientid, slipType, taxYear],
    (data) => {
      return RL1SlipService.getRL1Summary(clientid, slipType, taxYear);
    },
    { refetchOnWindowFocus: false }
  );



export const RL1SlipSetupToolbar: FC<IRL1SlipSetupToolbar> = ({
  onResetClicked,
  onDeleteClicked,
  onPrintClicked,
  isSubmitting,
  formMethods
}) => {
  const id = useSelector((state: IAppState)=> state.client.id)
  const taxYear = useSelector((state: IAppState)=> state.taxYear.year)
  // const [selectedType, setSelectedType] = useState(400);
  const {
    isLoading: isLoadingSummaryGetData,
    data: rl1SummaryData } =
    useGetRL1SummaryData(id ,selectedRL1SummaryType ,taxYear);
  // console.log("selectedType",selectedType)

  console.log("rl1SummaryData ============",rl1SummaryData)
  
  
  const onchangeTooltip = () => {
    //it have to optional from cosmic then remove it 
  }
  const selectedSlip = formMethods.getValues('selectedSlip')

  return (
    <Toolbar>
      <>
      <div style={{display:"contents"}}>
      <div style={{margin:"1rem 0rem"}}>
          {rl1SummaryData && rl1SummaryData.confirmationCode !== null 
          && selectedRL1SlipType === 400 ? 
          (
            <div style={{cursor:"not-allowed"}}>
              <DisabledButton 
                tooltiptitle={"Confirmation No. has been enterned in [Complaince -> rl1 -> Summary]"} 
                isDisabled={true}
                buttonLabel={"Submit"}
                icon={<PlaylistAddCheckOutlinedIcon />}
                testId='disableButton' 
                onClick={onchangeTooltip} />
            </div>
          ):(
            <SubmitButton
              label="Submit"
              testId="submit-button"
              loading={isSubmitting}
              icon={<PlaylistAddCheckOutlinedIcon />}
              
            />
            )
          }
          </div>
        <ResetButton testId="reset-button" onClickHandler={onResetClicked} />
        <div style={{margin:"1rem 0rem"}}>
          {rl1SummaryData && rl1SummaryData.confirmationCode !== null 
          && selectedRL1SlipType === 400 ? 
          (
            <div style={{cursor:"not-allowed",marginLeft:"1rem"}}>
              <DisabledButton 
                tooltiptitle={"Confirmation No. has been enterned in [Complaince -> t4 -> Summary]"} 
                isDisabled={true}
                buttonLabel={"Delete"}
                icon={<DeleteOutlineIcon />}
                testId='disableButton' 
                onClick={onchangeTooltip} />
            </div>
          ): selectedSlip && selectedSlip === 'create-new' ?
          (
            <div style={{cursor:"not-allowed",marginLeft:"1rem"}}>
              <DisabledButton 
                tooltiptitle={""} 
                isDisabled={true}
                buttonLabel={"Delete"}
                icon={<DeleteOutlineIcon />}
                testId='disableButton' 
                onClick={onchangeTooltip} />
            </div>
          ):
          (
            <DeleteButton
            testId="delete-button"
            onClickHandler={onDeleteClicked}
            />
            )
          } 
          </div>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div>
          {selectedSlip && selectedSlip === 'create-new' ?
          (
            <div style={{cursor:"not-allowed",marginLeft:"1rem"}}>
              <DisabledButton 
                tooltiptitle={""} 
                isDisabled={true}
                buttonLabel={"Print"}
                icon={<PrintIcon />}
                testId='disableButton' 
                onClick={onchangeTooltip} />
            </div>
          ):(
        <PrintButton
            onClickHandler={onPrintClicked}
            testId="rl1-slip-print-button" label={"RL-1"}
          />
          )}
        </div>
      </>
    </Toolbar>
  );
};
