import { FC, useEffect, useState } from "react";

import {
  BusinessNumber,
  BusinessNumberRHF,
  EnhancedDropDownRHFWithLabel,
  EnhancedIOSSwitchRHFWithLabel,
  EnhancedRadioGroupRHFWithLabel,
  ITest,
  LabelPosition,
  RHookFormMaskedTextFieldWithLabel,
  RHookFormTextFieldWithLabel,
  SinNumberRHF,
} from "@websential/cosmic";

import { FieldWrapper } from "../../../FieldWrapper/FieldWrapper";
import { Box } from "@mui/material";

interface IVendorInfoSetupFieldsPanel extends ITest {
  requiredFliedsAccordingToPayeeType:boolean;
}

export const VendorInfoSetupFieldsPanel: FC<IVendorInfoSetupFieldsPanel> = ({
  testId,
  requiredFliedsAccordingToPayeeType
}) => {
  const [selectedValue, setSelectedValue] = useState(true);
  const [isRequired, setIsRequired] = useState(true);

  const onChange = (event: any) => {
    const value = event.value //business = false, personal = true
    console.log("eventttt",value)
    setSelectedValue(value);
    if(value === true){
      setIsRequired(value)
    }else{
      setIsRequired(value)
    }
  };
  
  // console.log("requiredFliedsAccordingToPayeeType11",requiredFliedsAccordingToPayeeType)
  useEffect(()=>{
    console.log("requiredFliedsAccordingToPayeeType",requiredFliedsAccordingToPayeeType)
    if(requiredFliedsAccordingToPayeeType === true){
      setIsRequired(requiredFliedsAccordingToPayeeType)
    }else{
      setIsRequired(requiredFliedsAccordingToPayeeType)
    }
  },[requiredFliedsAccordingToPayeeType])
  return (
    <>
      <FieldWrapper>
        <EnhancedIOSSwitchRHFWithLabel
          name="reflectInVendor"
          checked={false}
          testId="reflectInVendor"
          label="Reflect in Vendor"
          labelPosition={LabelPosition.Left}
        />
      </FieldWrapper>
      <FieldWrapper>
        {/* <EnhancedRadioGroupRHFWithLabel
          name="payeeType"
          selectedValue={true}
          // selectedValue={selectedValue}
          // onChangeHandler={onChange}
          key="payeeType"
          testId="payeeType"
          disabled={false}
          options={[
            {
              label: "Personal",
              id: "personal-option",
              value: true,
            },
            {
              label: "Business",
              id: "business-option",
              value: false,
            },
          ]}
          label="Payee Type"
          labelPosition={LabelPosition.Left}
          labelColumns={4}
          fieldColumns={8}
        /> */}
        <EnhancedDropDownRHFWithLabel
        label="Payee Type"
        selectName="payeeType"
        items={[
          {
            label: "Personal",
            id: "personal-option",
            value: true,
          },
          {
            label: "Business",
            id: "business-option",
            value: false,
          },
        ]}
        selectedItem={selectedValue}
        testId={`${testId}-payee-type`}
        labelPosition={LabelPosition.Left}
        onSelectionChangeHandler={onChange}
        required
      />
      </FieldWrapper>
      <RHookFormTextFieldWithLabel
        label="First Name"
        id="name1"
        testId={`${testId}-first-name`}
        labelPosition={LabelPosition.Left}
        required={isRequired}
      />
      {/* <RHookFormTextFieldWithLabel
        label="Initials"
        id="initials"
        testId={`${testId}-initials`}
        labelPosition={LabelPosition.Left}
      /> */}
      <RHookFormMaskedTextFieldWithLabel
            label="Initials"
            id="initials"
            testId={`${testId}-initials`}
            labelPosition={LabelPosition.Left}
            inputMaskPattern={"a"}
        />
      <RHookFormTextFieldWithLabel
        label="Last Name"
        id="name2"
        testId={`${testId}-last-name`}
        labelPosition={LabelPosition.Left}
        required={isRequired}
      />
      {/* <RHookFormTextFieldWithLabel
        label="Social Insurance"
        id="uniqueIdentifier"
        testId={`${testId}-social-insurance`}
        labelPosition={LabelPosition.Left}
        required={isRequired}
      /> */}
          <SinNumberRHF
            id="uniqueIdentifier"
            label="Social Insurance"
            required={isRequired}
            labelPosition={LabelPosition.Left}
            labelColumns={4}
            fieldColumns={8}
            testId="uniqueIdentifier"
          />
          <RHookFormMaskedTextFieldWithLabel
              id='businessNumber'
              label='Business #'
              labelPosition={LabelPosition.Left}
              testId='BusinessNumber'
              required={!isRequired}
              labelColumns={4}
              fieldColumns={8}
              placeholder={"000000000RT0000"}
              inputMaskPattern={"{000000000Ra0000}"}
              // defaultValue={"123123123RT0000"}
          />
      <RHookFormTextFieldWithLabel
        label="Business Name"
        id="businessName"
        testId={`${testId}-business-name`}
        labelPosition={LabelPosition.Left}
        required={!isRequired}
      />
    </>
  );
};
