import { FlexFieldScreenFieldset } from "components/FlexFieldScreenFieldset";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IFlexFieldScreenFieldsetPage {}

export const FlexFieldScreenFieldsetPage: FC<IFlexFieldScreenFieldsetPage> =
  () => {
    return (
      <AuthenticatedLayout
        pageTitle="Flex Field Screen Setup"
        breadcrumbCurrentPageName=""
        breadcrumbLinks={[]}
        mainContents={
          <FlexFieldScreenFieldset testId="flex-field-screen-setup" />
        }
      />
    );
  };
