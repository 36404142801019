import { RL16SlipSummarySetup } from "components/RL16Slip/RL16SlipSummarySetup";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL16SlipSummarySetupPage {}

export const RL16SlipSummarySetupPage: FC<IRL16SlipSummarySetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="RL-16 Summary"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl16/list" },
        { text: "RL-16", navigateTo: "/taxslip/rl16/list" },
        { text: "RL-16 Summary", navigateTo: "/taxslip/rl16/summary" },
      ]}
      mainContents={<RL16SlipSummarySetup testId="RL16-summary-setup" />}
    />
  );
};
