import { AxiosError, AxiosResponse } from 'axios';
import http from 'common/http-common';
import { IDropDownListResponse } from 'models';
import { IGetListRequest, IResponse, IServerError } from 'models/common';
import { IT4ASlipListModel } from 'models/t4aSlip';
import { IT4ASlipRequestPayload } from 'models/t4aSlip/IT4ASlipRequestPayload';
let getClientId = "";
let getTaxYear:any = null;
// TODO: replace this with API response once it's ready
export const T4ASlipService = {
  getList: async (clientId: string, taxYear: number, params: IGetListRequest): Promise<IT4ASlipListModel[] | IServerError | IResponse> => {
    const response: AxiosResponse<IT4ASlipListModel[]> = await http.get<IT4ASlipListModel[]>(
      `/v1/t4a-slips/?client_id=${clientId}&tax_year=${taxYear}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);

    if (response.status === 200) {
      getClientId = clientId;
      getTaxYear = taxYear;
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  delT4ASlip: async (requestPayload: any): Promise<string | IServerError | IResponse> => {
    const response: AxiosResponse<string> = await http.delete<string>(
      `/v1/t4a-slips/${requestPayload.t4ASlipId}/?client_id=${requestPayload.clientId}&slip_type=400&tax_year=${requestPayload.taxYear}`);

    if (response.status === 200) {
      return "Taxslip T4A Slip List has been deleted successfully";
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  delBulkT4ASlips: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    console.log("requestPayload", typeof requestPayload);
    const response = await http.delete<string>(
      `/v1/t4a-slips/bulk-delete/${requestPayload.clientId}/?tax_year=${requestPayload.taxYear}`,
      { data: requestPayload.ids}
    );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    console.log("REsponse ?????????????????????????????????????", response);
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getT4ASlipById: async (id: string | undefined,clientId:string,taxYear:number): Promise<any | IServerError | IResponse | null> => {
    if (id) {
      const response: AxiosResponse<any> = await http.get<any>(
        `/v1/t4a-slips/${id}/?client_id=${clientId}&tax_year=${taxYear}`);
  
      console.log("id : ",id)
      console.log("Client id : ",clientId)
      console.log("TaxYear : ",taxYear)
      console.log("response of selected row in t4a slip : ",response.data)
    
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }

    return null;
  },
  getById: async (id: string | undefined, clientId: string, taxYear: number): Promise<any | IServerError | IResponse | null> => {
    if (!id) return null;
    console.log("id from getbyId Service : ",id)
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/t4a-slips/${id}/?client_id=${clientId}&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getResourceSlipDropDownList: async (resourceId: string, clientId: string, taxYear: number): Promise<IDropDownListResponse[] | IServerError | IResponse> => {
    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<IDropDownListResponse[]>(
      `/v1/t4a-slips/vendor-ddl-slips/${resourceId}?client_id=${clientId}&tax_year=${taxYear}`);
console.log("resourceId",resourceId)
console.log("clientId",clientId)
console.log("resourceId",taxYear)
console.log("resource-ddl-slips res",response)
    if (response.status === 200) {
      console.log("responce resource-ddl-slips",response.data)
      return response.data;
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  create: async (
    requestPayload: IT4ASlipRequestPayload
  ): Promise<IT4ASlipRequestPayload | IServerError | IResponse | any> => {
    if (requestPayload.id) {
      console.log('requestPayload ==>> update request', requestPayload);
      const response: AxiosResponse<IT4ASlipRequestPayload> = await http.put<IT4ASlipRequestPayload>(
        `/v1/t4a-slips/${requestPayload.id}/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`, requestPayload);

      if (response.status === 200) {
        console.log("responce data of update id",response.data)
        // return response.data;
        return {status:response.status, data:response.data};
      } else if (response.status === 400) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }

    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<IT4ASlipRequestPayload> =
      await http.post<IT4ASlipRequestPayload>(
        `/v1/t4a-slips/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`,
        requestPayload
      );

    if (response.status === 201) {
      // return response.data;
      return {status:response.status, data:response.data};
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  t4aSlipRetrieveData: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    console.log("requestPayload",requestPayload);
    const response = await http.post<string>(
      `/v1/t4a-slips-csv/import-csv/?client_id=${requestPayload.id}&tax_year=${requestPayload.taxYear}`,
      requestPayload.formData
    );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    // console.log("loginDetail", loginDetail);
    console.log("REsponse ?????????????????????????????????????", response);

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getT4ASummary: async (
    clientId: string,
    slipType: string | number,
    taxYear: number): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/t4a-summary/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`);

    if (response.status === 200) {
      console.log("T4 Summary response.data '===========",response.data)
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  t4aSummaryclearConfirmationCode: async (
    clientId: string,
    slipType: number | undefined,
    taxYear: number
  ): Promise<string | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<string> | any= await http.put<string>(
      `/v1/t4a-summary/clear-confirmation-code/${clientId}/?slip_type=${slipType}&tax_year=${taxYear}`
    );

    if (response.status === 200) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    } else if (response.status === 400) {
      return response.data
    } else if (response.status === 404) {
      return response.data
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
  createSummary: async (
    requestPayload: any)
    : Promise<any | IServerError | IResponse> => {
    
    console.log("requestPayload t4 setup : ",requestPayload)

    console.log('requestPayload ==>> add request', requestPayload);
    try {
    const response: AxiosResponse<any> = await http.put<any>(
      `/v1/t4a-summary/?client_id=${requestPayload.id}&tax_year=${requestPayload.taxYear}`, requestPayload);
console.log("response3245345",response)
if (response.status === 200) {
  return response;
    }
    else if (response.status === 201) {
      return response;
    }
    else if (response.status === 404) {
      return response;
    }
    else if (response.status === 400) {
      return response;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    } else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }

    // return {
    //   status: false,
    //   message: "Something went wrong please try again later.",
    // };
  },
  getResourceInformationById: async (
    resourceId: string | undefined,
    clientId:string,
    roleName:string
    ): Promise<any | IServerError | IResponse | null> => {
    if (resourceId) {
      const response: AxiosResponse<any> = await http.get<any>(
        `/v1/resources/${roleName}/${resourceId}/?client_id=${clientId}`);
  
      console.log("response of getResourceInformationById : ",response.data)
    
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }

    return null;
  },
  getVendorDDL: async (
    clientId: string, dataRole: string,taxYear:number
  ): Promise<any | IServerError | IResponse | null> => {

    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<
      IDropDownListResponse[]
    >(`/v1/resources/ddl-list/${dataRole}/by-year/?client_id=${clientId}&tax_year=${taxYear}`);

    console.log("respooooooooooooooooonse",response)

    if (response.status === 200) {
      return response.data;
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getVendorSlipDropDownList: async (
    resourceId: string,
    clientId: string,
    taxYear: number
  ): Promise<IDropDownListResponse[] | IServerError | IResponse> => {
    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<
      IDropDownListResponse[]
    >(
      `/v1/t4a-slips/vendor-ddl-slips/${resourceId}?client_id=${clientId}&tax_year=${taxYear}`
    );
    console.log("resourceId", resourceId);
    console.log("clientId", clientId);
    console.log("resourceId", taxYear);
    if (response.status === 200) {
      console.log("responce resource-ddl-slips", response.data);
      return response.data;
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getT4AAuditFiling: async (
    clientId: string,
    taxYear:number
  ): Promise<
  any | IServerError | IResponse
  > => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/audit/audit-filling?client_id=${clientId}&audit_filling_tag=T4A&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
};
