import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { JournalCodeSetup } from "components/Reports/GeneralClient";

export interface IJournalCodeAdminPage {}

export const JournalCodeAdminPage: FC<IJournalCodeAdminPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Journal Codes Setup"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<JournalCodeSetup />}
    />
  );
};
