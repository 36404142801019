import { FC, useEffect, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ITest } from '@websential/cosmic';
import { Card,
  CardContent, } from '@mui/material';
import "./EnhancedAppBarStyle.css"

import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'store/reducers';

import { Link } from "react-router-dom";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { setSelectedActivityLogActions } from 'store/actions/activityLogActions';
import { ActivitylogServices } from 'services/ActivitylogServices';
import { setSelectedAxiosCallActions } from 'store/actions/isLoadAxiosCallActions';

export interface IEnhancedAppBarFooter extends ITest {
  /**
   * Title of the Appbar
   */
  footerMessage?: string;
  /**
   * Use to set the width of appBar
   */
  drawerWidth: string;

  testId: string;
}


interface StyledAppBarFooterProps {
  width: string;
  "data-testid": string;
}

const StyledAppBarFooter = styled((props:StyledAppBarFooterProps) => {
  console.log('$$$$$-CustomizedSlider-styled-props', props)
  return <AppBar {...props} />
})(
  ({ theme, width }: any) => `
  position:fixed;
  top: auto;
  bottom: 0;
  background-color: #ffffff;
  padding:0;
  color: ${grey[700]};
  width: ${width};
  // width: ${`calc(100% - 240px)`};
  marginLeft: 240px;
  :hover {
    color: ${theme.palette.primary.main, 0.2};
  }
`,
);

export const EnhancedAppBarFooter: FC<IEnhancedAppBarFooter> = ({
  footerMessage,
  testId,
  drawerWidth,
}: IEnhancedAppBarFooter) => {
  const dispatch = useDispatch()
  const isAdminUser = useSelector((state: IAppState) => state.user.profile.isSuperStatus);
  const accountantId = useSelector((state: IAppState) => state.accountant.id);
  const activityLog = useSelector((state: IAppState) => state.activityLog);

  const [activityLogData, setActivityLogData] = useState("No activity log found");
  const [buttonState, setButtonState] = useState(false);
  const [activityLogDataArray, setActivityLogDataArray] = useState<any>([]);

  const ActivityLogDataArray:any = []
  const formatDate = (date:string) => {
    let dateObj = new Date(date);
    let formattedDate = dateObj.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: undefined // This will remove the timezone name
    });
    let timeZoneOffset = dateObj.getTimezoneOffset();
    let sign = timeZoneOffset < 0 ? '+' : '-';
    timeZoneOffset = Math.abs(timeZoneOffset);
    let hours = String(Math.floor(timeZoneOffset / 60)).padStart(2, '0');
    let minutes = String(timeZoneOffset % 60).padStart(2, '0');
    let timeZone = `(GMT${sign}${hours}:${minutes})`;

    let updatedDateFormat = `${formattedDate} ${timeZone}`
    console.log("updatedDateFormat",updatedDateFormat);
    return updatedDateFormat
  }
  const activityLogDataOrganize = (activityTypeName:string,activityTypeValue:any) => {
    if(activityTypeName === "delete"){
      // ActivityLogDataArray.push(`last deleted on ${activityTypeValue.created_on}`)
      // ActivityLogDataArray.push(`last deleted by ${activityTypeValue.user}`)
      // console.log("inside ActivityLogDataArray delete",ActivityLogDataArray)
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Deleted on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Deleted by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "emailed"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Emailed on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Emailed by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "print"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Printed on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Printed by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "print_xml"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last XML Printed on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last XML Printed by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "import_xml"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last XML Imported on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last XML Imported by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "import_csv"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last CSV Imported on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last CSV Imported by:</b>{' '}{activityTypeValue.user}</>),]);
    }  else if(activityTypeName === "create"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Created on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Created by:</b>{' '}{activityTypeValue.user}</>),]);
    }  else if(activityTypeName === "updated"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Updated on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Updated by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "ignore_insurable_earnings"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Insurable Earnings Ignored on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Insurable Earnings Ignored by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "undo_ignore_insurable_earnings"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Undo Ignore Insurable Earnings on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Undo Ignore Insurable Earnings by:</b>{' '}{activityTypeValue.user}</>),]);
    }else if(activityTypeName === "ignore_pensionable_earnings"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Pensionable Earnings Ignored on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Pensionable Earnings Ignored by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "undo_ignore_pensionable_earnings"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Undo Ignore Pensionable Earnings on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Undo Ignore Pensionable Earnings by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "undo_ignore_pensionable_earnings"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Filing Resource Created on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Filing Resource Created by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "move_employees"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Employees Move on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Employees Move by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "move_vendors"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Vendors Move on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Vendors Move by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "move_t5_recipients"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last T5 Recipients Move on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last T5 Recipients Move by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "move_t3_beneficiary"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last T3 Beneficiary Move on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last T3 Beneficiary Move by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "t4_adjustments"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last T4 Adjustments on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last T4 Adjustments by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "t4_undo_adjustments"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last T4 Undo Adjustments on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last T4 Undo Adjustments by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "rl1_adjustments"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last RL-1 Adjustments on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last RL-1 Adjustments by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "rl1_undo_adjustments"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last RL-1 Undo Adjustments on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last RL-1 Undo Adjustments by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "remittance_transfers"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Remittance Transfers on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Remittance Transfers by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "undo_remittance_transfers"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Undo Remittance Transfers on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Undo Remittance Transfers by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "ignore_remittance_transfers"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Ignore Remittance Transfers on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Ignore Remittance Transfers by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "undo_ignore_remittance_transfers"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Undo Ignore Remittance Transfers on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Undo Ignore Remittance Transfers by:</b>{' '}{activityTypeValue.user}</>),]);
    }  else if(activityTypeName === "api_keys_generated"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last API Keys Generated on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last API Keys Generated by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "paid"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Paid on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Paid by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "generated"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Generated on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Generated by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "recalculated"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Recalculated on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Recalculated by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "delete_t4_confirmation_no"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Delete T4 Confirmation No on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Delete T4 Confirmation No by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "print_pdf"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Print PDF on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Print PDF by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "import_xml"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Import XML on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Import XML by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "import_csv"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Import CSV on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Import CSV by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "delete_t5_confirmation_no"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Delete T5 Confirmation No on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Delete T5 Confirmation No by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "print_xml"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Print XML on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Print XML by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "delete_t4a_confirmation_no"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Delete T4A Confirmation No on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Delete T4A Confirmation No by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "delete_rl1_confirmation_no"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Delete Rl1 Confirmation No on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Delete Rl1 Confirmation No by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "recalculated"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Recalculated on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Recalculated by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "recalculated"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Recalculated on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Recalculated by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "recalculated"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Recalculated on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Recalculated by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "update_parent"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Accountant Updated on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Accountant Updated by:</b>{' '}{activityTypeValue.user}</>),]);
    } else if(activityTypeName === "assign_user"){
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Assign User on:</b>{' '}{formatDate(activityTypeValue.created_on)}</>),]);
      setActivityLogDataArray((prevData: any) => [...prevData, (<><b>Last Assign User by:</b>{' '}{activityTypeValue.user}</>),]);
    }
    console.log("11111",ActivityLogDataArray)
  }
  console.log("121212",activityLogDataArray)
  const onToggleButtonClickHandler = (e:any) => {
    // Toggle the state between true and false
    setButtonState(!buttonState);
    console.log("buttonState",buttonState)
    // alert(buttonState)
    console.log("jdcjasbkc d",e)
    // alert(activityLog.screenName)
    setActivityLogData(activityLog.screenName)
    if(buttonState === false){
      (async() => {
        try{
          dispatch(setSelectedAxiosCallActions({
            isLoading:true
          }));
          // dispatch(setSelectedActivityLogActions({
          //   isLoadingGetActivityLogData:true,
          //   entityId1:activityLog.entityId1,
          //   entityId2:activityLog.entityId2,
          //   entityId3:activityLog.entityId3,
          //   screenId:activityLog.screenName,
          //   variableValue:activityLog.companyName,
          //   activityType:activityLog.activityType,
          // }));
          let requestPayload = {
            entityId1:activityLog.entityId1,
            entityId2:activityLog.entityId2,
            entityId3:activityLog.entityId3,
            screenId:activityLog.screenName,
            variableValue:activityLog.companyName,
            activityType:activityLog.activityType,
          }
          const response = await ActivitylogServices.getActivityLogData(requestPayload)
        //   const response:any = {
        //     "last_updated_activities": {
        //         "delete": {
        //             "id": 4,
        //             "activity_type": "delete",
        //             "entity_id_1": "string",
        //             "entity_id_2": "string",
        //             "entity_id_3": "string",
        //             "created_on": "2024-03-27T11:17:40.256000",
        //             "ip_address": "192.168.1.113",
        //             "comments": "string",
        //             "application": 20,
        //             "browser": 2,
        //             "os": 2,
        //             "screen": 58,
        //             "user": "TaxSlips Admin",
        //             "variable_value": "Amna Sheikh"
        //         },
        //         "emailed": {
        //             "id": 5,
        //             "activity_type": "emailed",
        //             "entity_id_1": "string",
        //             "entity_id_2": "string",
        //             "entity_id_3": "string",
        //             "created_on": "2024-03-27T11:17:40.256000",
        //             "ip_address": "192.168.1.113",
        //             "comments": "string",
        //             "application": 20,
        //             "browser": 2,
        //             "os": 2,
        //             "screen": 58,
        //             "user": "TaxSlips Admin",
        //             "variable_value": "Amna Sheikh"
        //         },
        //         "print": {
        //             "id": 6,
        //             "activity_type": "print",
        //             "entity_id_1": "string",
        //             "entity_id_2": "string",
        //             "entity_id_3": "string",
        //             "created_on": "2024-03-27T11:17:40.256000",
        //             "ip_address": "192.168.1.113",
        //             "comments": "string",
        //             "application": 20,
        //             "browser": 2,
        //             "os": 2,
        //             "screen": 58,
        //             "user": "TaxSlips Admin",
        //             "variable_value": "Amna Sheikh"
        //         }
        //     }
        // }

          console.log("response11",response)
          if (Object.keys(response && response.last_updated_activities).length > 0) {
            setActivityLogDataArray([])
            Object.keys(response.last_updated_activities).forEach((fieldName) => {
              const fieldValue = response.last_updated_activities[fieldName];
              activityLogDataOrganize(fieldName,fieldValue)
            })
          } else {
            setActivityLogDataArray([])
          }

        } catch {
          dispatch(setSelectedAxiosCallActions({
            isLoading:false
          }));
          // dispatch(setSelectedActivityLogActions({
          //   isLoadingGetActivityLogData:false,
          //   entityId1:activityLog.entityId1,
          //   entityId2:activityLog.entityId2,
          //   entityId3:activityLog.entityId3,
          //   screenId:activityLog.screenName,
          //   variableValue:activityLog.companyName,
          //   activityType:activityLog.activityType}));
        } finally {
          dispatch(setSelectedAxiosCallActions({
            isLoading:false
          }));
        //   dispatch(setSelectedActivityLogActions({
        //     isLoadingGetActivityLogData:false,
        //     entityId1:activityLog.entityId1,
        //     entityId2:activityLog.entityId2,
        //     entityId3:activityLog.entityId3,
        //     screenId:activityLog.screenName,
        //     variableValue:activityLog.companyName,
        //     activityType:activityLog.activityType}));
        }
      })();
    } else {
      setActivityLogDataArray([])
    }
  }
  // const handleOutsideClick = (event:any) => {
  //   // Check if the click is outside the card
  //   const cardContainer = document.querySelector('.card-container');

  //   if (buttonState && cardContainer && !cardContainer.contains(event.target)) {
  //     setButtonState(false);
  //   }
  // };

  // // Add event listener for outside clicks
  // useEffect(() => {
  //   window.addEventListener('click', handleOutsideClick);

  //   return () => {
  //     window.removeEventListener('click', handleOutsideClick);
  //   };
  // }, [buttonState]);
  
  console.log("buttonState--",buttonState)
  console.log("ActivityLogDataArray--",ActivityLogDataArray)

  return (
    <StyledAppBarFooter data-testid={testId} width={drawerWidth}>
      <Toolbar sx={{padding:"0 15px !important",minHeight:"24px !important"}}>
      {/* <IconButton aria-label="toggle" sx={{borderRadius:"5px"}} onClick={onToggleButtonClickHandler}>
            <DehazeIcon sx={{margin:0}}/>
      </IconButton> */}
        {/* <StyledIcon data-testid={testId + "-icon"}>
          {titleIcon}
        </StyledIcon> */}
        {activityLog && activityLog.activityLogVisibility ? 
        <div>
        <div className='card-container'>
        {buttonState &&
          <Card sx={{
            top:"auto",
            left:"70px !important",
            bottom:"85% !important",
            minWidth:"395px",
            position:"absolute",
            border:"1px solid #0057db"

          }}>
            <Typography component="div" sx={{
              color:"#fff",
              fontSize:"13px",
              display:"flex",
              justifyContent:"space-between",
              background:"#0057db",
              padding:"6px",
            }}><b>Activity Log</b>
            {accountantId !== "" &&

            <Link
            to="/reports/activity-log-report"
            // target="_blank"
            style={{ textDecoration: "none",color:"#fff" }}
            >
                View Details
              </Link>
            }
            </Typography>
            <CardContent sx={{
              fontSize:"13px",
            }}>
              {/* {activityLogData} */}
              <ul style={{ margin: 0, padding: 1, listStyleType: "none" }}>
                {activityLogDataArray && activityLogDataArray.length > 0 ? 
                  activityLogDataArray.map((item:any) => {
                    return (
                      <li style={{ margin: 0, paddingBottom: 2 }}>
                        <Typography variant="body2">{item}</Typography>
                      </li>
                    );
                  }) : "No activity log found"}
              </ul>
              </CardContent>
          </Card>
        }</div>
        <Typography component="span" sx={{ 
          // flexGrow: 1, 
          fontSize:"11px",
          marginLeft:"50px",
          color:"#066BB3", 
          cursor:"pointer",
          display:"flex",
          alignItems:"center",
          userSelect:"none"
          }}
          onClick={onToggleButtonClickHandler}
          >
            Actvity Log&nbsp;<span><ArrowDropUpIcon sx={{fontSize:"15px"}}/></span>
            {/* <br /> */}
          </Typography>
          {/* <br />
          <span>
            card
          </span> */}
          {/* <Box sx={{flexGrow:1}}></Box> */}
          </div> : <></>}
        <Typography
          // noWrap
          sx={{
            // marginLeft: theme.spacing(2),
            display: "flex",
            fontSize:"11px",
            fontFamily:"Raleway,sans-serif",
            marginRight:"10%",
            right:0,
            position:"fixed"
            // flexGrow: 2
          }}
          data-testid={testId + "-title"}
        >
          {footerMessage}
        </Typography>
        {/* <Card>
            <CardHeader>Card  Header</CardHeader>
            <CardContent>Card   Content</CardContent>
          </Card> */}

        {/* <div style={{ flexGrow: 20 }} /> */}
        {/* <BasicDemoMenuBar /> */}
        {/* {toolbarMenuContent} */}

          {/* {mask} */}
        {/* <div style={{ flexGrow: 1 }} /> */}
        {/* <div data-testid={testId + "-toolbar"}>{toolbar}</div> */}
      </Toolbar>
    </StyledAppBarFooter>
  );
};
