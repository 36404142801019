import { AxiosResponse } from 'axios';
import http from 'common/http-common';
import { IGetListRequest, IResponse, IServerError } from 'models/common';
import { IRL1AdjustmentReportModel } from 'models/rl1AdjustmentReport/IRL1AdjustmentReportModel';

export const RL1AdjustmentReportService = {
      getRL1AdjustmentReportNew: async (
        clientId:string,
        taxYear: number,
        params: IGetListRequest
      ): Promise<
      IRL1AdjustmentReportModel | IServerError | IResponse
      > => {

      //   const fixedResponse: any =  {
      //     "page": 1,
      //     "size": 50,
      //     "total": 1,
      //     "items": [
      //         {
      //             "employee": "First RL1",
      //             "adjusted": true,
      //             "employmentIncome": 60000.0,
      //             "qppEntered": 3500.0,
      //             "qppAdjusted": 3474.75,
      //             "qppDifference": -25.25,
      //             "ppipEntered": 240.0,
      //             "ppipAdjusted": 296.4,
      //             "ppipDifference": 56.4,
      //             "rl1TaxEntered": 3000.0,
      //             "rl1TaxCalculated": 2968.85,
      //             "rl1TaxAdjusted": 2968.85,
      //             "rl1TaxDifference": -31.15,
      //             "totalDifference": 0.0
      //         }
      //     ],
      //     "sumData": [
      //         {
      //             "id": "",
      //             "employee": "Employee\nContribution",
      //             "qppDifference": -25.25,
      //             "ppipDifference": 56.4,
      //             "rl1TaxDifference": -31.15,
      //             "totalDifference": -0.0,
      //             "employmentIncome": 60000.0,
      //             "qppEntered": 3500.0,
      //             "qppAdjusted": 3474.75,
      //             "ppipEntered": 240.0,
      //             "ppipAdjusted": 296.4,
      //             "rl1TaxEntered": 3000.0,
      //             "rl1TaxCalculated": 2968.85,
      //             "rl1TaxAdjusted": 2968.85
      //         },
      //         {
      //             "id": "",
      //             "employee": "Employer\nContribution",
      //             "qppDifference": -25.25,
      //             "ppipDifference": 79.01,
      //             "rl1TaxDifference": 0.0,
      //             "totalDifference": 53.76
      //         },
      //         {
      //             "id": "",
      //             "employee": "Total",
      //             "qppDifference": -50.5,
      //             "ppipDifference": 135.41,
      //             "rl1TaxDifference": -31.15,
      //             "totalDifference": 53.76
      //         }
      //     ],
      //     "keyField": {
      //         "field": "name",
      //         "templateUrl": "/rl1-slips/{id}"
      //     },
      //     "headerColumns": [
      //         {
      //             "field": "employee",
      //             "headerName": "Employee",
      //             "description": "Employee Name",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 0,
      //             "frozenColumn": true,
      //             "footer": null,
      //             "minWidth": 100
      //         },
      //         {
      //             "field": "adjusted",
      //             "headerName": "Adjusted",
      //             "description": "Adjusted",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 1,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 90
      //         },
      //         {
      //             "field": "employmentIncome",
      //             "headerName": "Employment\nIncome",
      //             "description": "Employment Income",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 2,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 100
      //         },
      //         {
      //             "field": "qppEntered",
      //             "headerName": "QPP\nEntered",
      //             "description": "QPP Entered",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 3,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 100
      //         },
      //         {
      //             "field": "qppAdjusted",
      //             "headerName": "QPP\nAdjusted",
      //             "description": "QPP Adjusted",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 4,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 100
      //         },
      //         {
      //             "field": "qppDifference",
      //             "headerName": "QPP\nDifference",
      //             "description": "QPP Difference",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 5,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 100
      //         },
      //         {
      //             "field": "ppipEntered",
      //             "headerName": "PPIP\nEntered",
      //             "description": "PPIP Entered",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 6,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 80
      //         },
      //         {
      //             "field": "ppipAdjusted",
      //             "headerName": "PPIP\nAdjusted",
      //             "description": "PPIP Adjusted",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 7,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 80
      //         },
      //         {
      //             "field": "ppipDifference",
      //             "headerName": "PPIP\ndifference",
      //             "description": "PPIP difference",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 8,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 90
      //         },
      //         {
      //             "field": "rl1TaxEntered",
      //             "headerName": "RL1 Tax\nEntered",
      //             "description": "RL1 Tax Entered",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 9,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 100
      //         },
      //         {
      //             "field": "rl1TaxCalculated",
      //             "headerName": "RL1 Tax\nCalculated",
      //             "description": "RL1 Tax Calculated",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 10,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 100
      //         },
      //         {
      //             "field": "rl1TaxAdjusted",
      //             "headerName": "RL1 Tax\nAdjusted",
      //             "description": "RL1 Tax Adjusted",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 11,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 100
      //         },
      //         {
      //             "field": "rl1TaxDifference",
      //             "headerName": "RL1 Tax\nDifference",
      //             "description": "RL1 Tax Difference",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 12,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 100
      //         },
      //         {
      //             "field": "totalDifference",
      //             "headerName": "Total\nDifference",
      //             "description": "Total Difference",
      //             "flex": 1,
      //             "hide": false,
      //             "hideable": false,
      //             "sortable": true,
      //             "sortingOrder": "asc",
      //             "resizable": false,
      //             "type": "string",
      //             "align": "left",
      //             "headerAlign": "left",
      //             "hideSortIcons": false,
      //             "disableColumnMenu": true,
      //             "disableReorder": true,
      //             "disableExport": true,
      //             "maskable": false,
      //             "isFixed": true,
      //             "sequence": 13,
      //             "frozenColumn": false,
      //             "footer": null,
      //             "minWidth": 100
      //         }
      //     ]
      // }
      //   return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

        const response: AxiosResponse<any> = await http.get<any>(
          // `/reports/t4-reports?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);
          `/v1/rl1-adjustment-report/?client_id=${clientId}&tax_year=${taxYear}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`);

        if (response.status === 200) {
          console.log("response.data t4 report",response.data)
          return response.data;
        } else if (response.status === 400) {
          return {
            status: false,
            message: "Bad Request.",
            error: true,
          };
        } else if (response.status === 500) {
          return {
            status: false,
            message: "Something went wrong.",
            error: true,
          };
        }

        return {
          status: false,
          message: "Something went wrong please try again later.",
    };

  }}
