
import { useNavigate } from "react-router-dom";
export const SlipsRoutesComponent = () => {
const navigate = useNavigate();
const ArrayData = [
  {
    label: "T4",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/taxslip/t4/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/taxslip/t4/setup")
        },
      },
      {
        label: "Summary",
        command: () => {
          navigate("/taxslip/t4/summary")
        },
      },
      {
        label: "Summary Reduced EI",
        command: () => {
          navigate("/taxslip/t4/summary-reduced-ei")
        },
      },
    ],  
  },
  {
    label: "T4A",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/taxslip/t4a/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/taxslip/t4a/setup")
        },
      },
      {
        label: "Summary",
        command: () => {
          navigate("/taxslip/t4a/summary")
        },
      },
    ],  },
  {
    label: "T5",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/taxslip/t5/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/taxslip/t5/setup")
        },
      },
      {
        label: "Summary",
        command: () => {
          navigate("/taxslip/t5/summary")
        },
      },
    ],  },
  {
    label: "T3",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/taxslip/t3/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/taxslip/t3/setup")
        },
      },
      {
        label: "Summary",
        command: () => {
          navigate("/taxslip/t3/summary")
        },
      },
      {
        label: "Settings",
        command: () => {
          navigate("/taxslip/t3/settings")
        },
      },
    ],  },
  {
    label: "RL-1",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/taxslip/rl1/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/taxslip/rl1/setup")
        },
      },
      {
        label: "Summary",
        command: () => {
          navigate("/taxslip/rl1/summary")
        },
      },
      {
        label: "Settings",
        command: () => {
          navigate("/taxslip/rl1/settings")
        },
      },
    ],  },
    {
      label: "RL-3",
      items: [
        {
          label: "List",
          command: () => {
            navigate("/taxslip/rl3/list")
          },
        },
        {
          label: "Setup",
          command: () => {
            navigate("/taxslip/rl3/setup")
          },
        },
        {
          label: "Summary",
          command: () => {
            navigate("/taxslip/rl3/summary")
          },
        },
      ],  },
    {
      label: "RL-16",
      items: [
        {
          label: "List",
          command: () => {
            navigate("/taxslip/rl16/list")
          },
        },
        {
          label: "Setup",
          command: () => {
            navigate("/taxslip/rl16/setup")
          },
        },
        {
          label: "Summary",
          command: () => {
            navigate("/taxslip/rl16/summary")
          },
        },
      {
        label: "Settings",
        command: () => {
          navigate("/taxslip/rl16/settings")
        },
      },
      ],  },
];

return {
  SlipsRoutes:ArrayData
}
}