import { LeftMenu } from "components/LeftMenu";
import UploadFileIcon from '@mui/icons-material/UploadFile';
const pdfLeftMenu = [
  {
    id: 1011,
    text: "PDF Files",
    open: false,
    // icon: <UploadFileIcon />,
    navigateToURL: "/pdf-settings/pdf-files",
    parentMenuId: 1000,
  },
  {
    id: 1012,
    text: "PDF Axis",
    open: false,
    // icon: <LocalOfferOutlinedIcon />,
    navigateToURL: "/pdf-settings/pdf-axis",
    parentMenuId: 1000,
  },
];

export const PdfMenu = () => {
  return <LeftMenu menuItems={pdfLeftMenu} />;
};
