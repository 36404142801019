import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ResourceService } from "services/ResourceService";
import { IAppState } from "store/reducers";

import { Box, Grid, Typography, useTheme } from "@mui/material";
import {
  BackdropCircularProgress,
  EnhancedDropDown,
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  LabelPosition,
} from "@websential/cosmic";

import {
  GET_QUERY_KEY,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  MOVE_PEOPLE_SCREEN_SLUG,
  MOVE_EMPLOYEES_SCREEN_SLUG,
  MOVE_VENDORS_SCREEN_SLUG,
  MOVE_T5_RECIPIENTS_SCREEN_SLUG,
  QUERY_OPTIONS,
  ROWS_PER_PAGE_PER_OPTIONS,
  MOVE_T3_BENEFICIARY_SCREEN_SLUG,
} from "common/constants";
import { PrimeDataGrid } from "components/PrimeDataGrid";
import { MoveEmployeeFirstPanel } from "./MoveEmployeeFirstPanel";
import { TaxSlipYearService } from "services";
import { Button } from "primereact/button";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { ProvinceService } from "services/common/ProvinceService";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IMoveResource {}
const useMoveResourceFirstPanel = (
  clientId: string,
  taxYear: number,
  resourceName: string,
  params: IGetListRequest
) =>
  useQuery(
    [`${resourceName.toLowerCase()}-move-first-panel`,taxYear, params],
    (data) => {
      return ResourceService.getMoveResourceList(clientId,taxYear, resourceName, params);
    },
    { refetchOnWindowFocus: false }
  );
const useMoveResourceSecondPanel = (
  clientId: string,
  taxYear: number,
  resourceName: string,
  params: IGetListRequest
) =>
  useQuery(
    [`${resourceName.toLowerCase()}-move-second-panel`, params],
    (data) => {
      return ResourceService.getMoveResourceList(clientId,taxYear, resourceName, params);
    },
    { refetchOnWindowFocus: false }
  );

const useYears = (params: IGetListRequest) =>
  useQuery(
    ["years-list", params],
    (data) => {
      return TaxSlipYearService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );
export const MoveResource: React.FC<IMoveResource> = () => {
  const { resourceName } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const clientName = useSelector((state : IAppState) => state.client.clientName);
  const [dataRoleName, setDataRoleName] = useState<string>("");
  const { id } = useSelector((state: IAppState) => state.client);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [moveResourceFirstPanelYear, setMoveResourceFirstPanelYear] = useState(
    taxYear - 1
  );
  const [selectedResource, setSelectedResource] = useState(
    resourceName
  );
  const [moveResourceFirstPanelItems, setMoveResourceFirstPanelItems] =
    useState([]);
  const [moveResourceSecondPanelItems, setMoveResourceSecondPanelItems] =
    useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [
    moveResourceFirstPanelUpdatedItems,
    setMoveResourceFirstPanelUpdatedItems,
  ] = useState<any>(undefined);
  const [
    moveResourceSecondPanelUpdatedItems,
    setMoveResourceSecondPanelUpdatedItems,
  ] = useState<any>(undefined);

  //years DDl
  const {
    isLoading: isLoadingYearsList,
    data: yearsDDLList,
    // refetch: refetchTabsDDL,
  } = useYears({
    search_query: "",
    order_by: "client_name",
    order_direction: "asc",
    page: 1,
    size: 25,
  });

  const yearsDDLItems = useMemo(() => {
    if (yearsDDLList && Array.isArray(yearsDDLList && yearsDDLList.items)) {
      return (
        yearsDDLList &&
        yearsDDLList.items.map((year: any) => ({
          id: year.id,
          label: year.year,
          value: year.year,
        }))
      );
    }

    // Return empty array in case of API data fetching error
    return [];
  }, [yearsDDLList]);

  // for delete row Purpose
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  let MOVE_RESOURCE_FIRST_PANEL_QUERY_KEY = `${dataRoleName.toLowerCase()}-move-first-panel`;
  let MOVE_RESOURCE_SECOND_PANEL_QUERY_KEY = `${dataRoleName.toLowerCase()}-move-second-panel`;

  useEffect(() => {
    if (resourceName && resourceName.length > 0) {
      console.log("resourceName>>>>>", resourceName);
      setDataRoleName(resourceName);
    }
  }, [dataRoleName, resourceName]);

  const theme = useTheme();

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "name1",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  //first
  const {
    isLoading: isLoadingMoveResourceFirstPanel,
    data,
    refetch: refetchMoveResourceFirstPanel,
  } = useMoveResourceFirstPanel(id,moveResourceFirstPanelYear, String(resourceName), queryParams);
  const moveResourceFirstPanel: any = data;
  console.log("@@@@@ => moveResourceFirstPanel", moveResourceFirstPanel);

  //second
  const {
    isLoading: isLoadingMoveResourceSecondPanel,
    data: moveResourceSecondPanelData,
    refetch: refetchMoveResourceSecondPanel,
  } = useMoveResourceSecondPanel(id,taxYear, String(resourceName), queryParams);
  const moveResourceSecondPanel: any = moveResourceSecondPanelData;
  console.log("@@@@@ => moveResourceSecondPanel", moveResourceSecondPanel);

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const moveResourceFirstPanelSelectionToolbar = useCallback(
    (selectedItems) => {
      console.log("selectedItems //////////// ", selectedItems);
      setMoveResourceFirstPanelItems(selectedItems);
      return null;
    },
    [dataRoleName, theme]
  );
  const moveResourceSecondPanelSelectionToolbar = useCallback(
    (selectedItems) => {
      console.log("selectedItems //////////// ", selectedItems);
      setMoveResourceSecondPanelItems(selectedItems);
      return null;
    },
    [dataRoleName, theme]
  );

  // useEffect(() => {
  //   if (moveResourceFirstPanel) {
  //     const updatedItemsResponse = moveResourceFirstPanel.items.map(
  //       (item: any) => ({
  //         ...item,
  //         fullName: item.name1 + item.name2,
  //       })
  //     );
  //     setMoveResourceFirstPanelUpdatedItems(updatedItemsResponse);
  //   }
  // }, [moveResourceFirstPanel]);

  // useEffect(() => {
  //   if (moveResourceSecondPanel) {
  //     const updatedItemsResponse = moveResourceSecondPanel?.items.map(
  //       (item: any) => ({
  //         ...item,
  //         fullName: item.name1 + item.name2,
  //       })
  //     );
  //     setMoveResourceSecondPanelUpdatedItems(updatedItemsResponse);
  //   }
  // }, [moveResourceSecondPanel]);

  const moveResourceFirstPanelUpdatedColumns = [
    {
      field: "label",
      headerName: `Select / Un-select All ${resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName === "T3_Beneficiary" ? "T3 Beneficiary" : resourceName}(s)`,
      description: `Select / Un-select All ${resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName === "T3_Beneficiary" ? "T3 Beneficiary" : resourceName}(s)`,
      flex: 1,
      hide: false,
      hideable: false,
      sortable: true,
      sortingOrder: "asc",
      resizable: false,
      type: "string",
      align: "left",
      headerAlign: "left",
      hideSortIcons: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      maskable: false,
      isFixed: true,
      sequence: 0,
      frozenColumn: false,
      footer: null,
    },
  ];

  const onYearSelectionChangeHandler = (item: any): void => {
    let selectedValue = item.value;
    setMoveResourceFirstPanelYear(selectedValue);
    // refetchMoveResourceFirstPanel()
    // queryClient.invalidateQueries(MOVE_RESOURCE_FIRST_PANEL_QUERY_KEY);
  };
  console.log("moveResourceFirstPanelYear",moveResourceFirstPanelYear)

  const onMoveResourceToPanelTwoClicked = () => {
    (async () => {
      try {
        setIsLoadingData(true);
        let selectedUpdatedItems =
          moveResourceFirstPanelItems &&
          moveResourceFirstPanelItems.map((item: any) => ({
            resourceId: item.resourceId,
            taxYear: taxYear,
            resourceRoleName: resourceName,
            clientId: id,
            label:item.label,
            poeShortCode:item.poeShortCode,
            recipientType:item.recipientType
          }));
        let requestPayload = { resourcesList: selectedUpdatedItems };
        console.log("requestPayload", requestPayload);
        const responce:any = await ResourceService.moveResource(requestPayload);
        if(responce.status === 201){
          setSnackbarData({
            open: true,
            message: responce.data.message ? responce.data.message :  `${resourceName} has been added successfully!`,
            severity: "success",
          });
          return queryClient.invalidateQueries(MOVE_RESOURCE_SECOND_PANEL_QUERY_KEY);
        }else if(responce.status === 400){
          setSnackbarData({
            open: true,
            message: responce.data.message ? responce.data.message :  `Something went wrong!`,
            severity: "error",
          });
        } else {
          setSnackbarData({
            open: true,
            message: responce.message ? responce.message :  `Something went wrong!`,
            severity: "error",
          });
        }
      } catch (error : any) {
        setSnackbarData({
          open: true,
          message: "Somthing went wrong!",
          severity: "error",
        });
        setIsLoadingData(false);
      } finally {
        setIsLoadingData(false);
      }
    })();
  };
  const onMoveResourceToPanelOneClicked = () => {
    (async () => {
      setIsLoadingData(true);
      try {
        console.log("moveResourceSecondPanelItems",moveResourceSecondPanelItems)
        // Extracting ids into a new array
        const selectedResourceIds = moveResourceSecondPanelItems.map((item:any) => item.resourceId);
        let payload = {
          clientId:id,
          taxYear:taxYear,
          ids:selectedResourceIds,
          roleName:resourceName,
          options:"Delete By Year"
        }
        const responce:any = await ResourceService.delBulkResources(payload);
        if (responce) {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            // message: responce && responce.message ? responce.message : "Somthing went wrong!",
            message: `${resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName === "T3_Beneficiary" ? "T3 Beneficiary" : resourceName} moves back to ${moveResourceFirstPanelYear}`,
            severity: responce && responce.status === false ? "error" : "success",
          });

          return queryClient.invalidateQueries(MOVE_RESOURCE_SECOND_PANEL_QUERY_KEY);
        } else {
          setSnackbarData({
            open: true,
            message: responce && responce.message ? responce.message : "Somthing went wrong!",
            severity: responce && responce.status === false ? "error" : "success",
          });
        }
      } catch (error:any) {
        setSnackbarData({
          open: true,
          message: error ? error : "Somthing went wrong!",
          severity: "error",
        });
        setIsLoadingData(false);
      } finally {
        setIsLoadingData(false);
      }
    })();
  };

  console.log("moveResourceFirstPanelItems", moveResourceFirstPanelItems);
  console.log("moveResourceSeconcdPanelItems", moveResourceSecondPanelItems);

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:resourceName === 'Employee' ? MOVE_EMPLOYEES_SCREEN_SLUG : resourceName === 'Vendor' ? MOVE_VENDORS_SCREEN_SLUG : resourceName === 'T5_Recipient' ? MOVE_T5_RECIPIENTS_SCREEN_SLUG : resourceName === 'T3_Beneficiary' ? MOVE_T3_BENEFICIARY_SCREEN_SLUG : MOVE_PEOPLE_SCREEN_SLUG,
      companyName:clientName,
      activityType:resourceName === 'Employee' ? "activity_type=move_employees" : resourceName === 'Vendor' ? "activity_type=move_vendors" : resourceName === 'T5_Recipient' ? "activity_type=move_t5_recipients" : resourceName === 'T3_Beneficiary' ? "activity_type=move_t3_Beneficiary" : "activity_type=move_people",
      activityLogVisibility:true
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[resourceName])

  let resourceDDLList = [
    {id:1,label:"Employee",value:"Employee"},
    {id:2,label:"Vendor",value:"Vendor"},
    {id:3,label:"T5 Recipient",value:"T5_Recipient"},
    {id:3,label:"T3 Beneficiary",value:"T3_Beneficiary"},
  ]

  const onResourceSelectionChangeHandler = (item: any): void => {
    let selectedResource = item.value;
    setSelectedResource(selectedResource);
    let path = `/resources/${selectedResource}/move-people`;
    navigate(path);
    // refetchMoveResourceFirstPanel()
    // queryClient.invalidateQueries(MOVE_RESOURCE_FIRST_PANEL_QUERY_KEY);
  };
  return (
    <>
      <Grid
        container
        columnSpacing={2}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ width: "100%", mt: "2rem" }}
      >
        <BackdropCircularProgress
          open={
            isLoadingData ||
            isLoadingYearsList ||
            isLoadingMoveResourceFirstPanel ||
            isLoadingMoveResourceSecondPanel
          }
          testId="move resource"
        />
        <Grid
        item
        // columnSpacing={2}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ width: "100%", mb: "2rem" }}
      >
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "rgba(0, 0, 0, 0.6)", padding: "7px" }}>
                Select Resource
              </Typography>
              <Box
                sx={{
                  width: "60%",
                  padding: "3px 10px 0 0",
                  "& .css-26l3qy-menu": { zIndex: 2 },
                }}
              >
                <EnhancedDropDown
                  selectName={"resourceDDL"}
                  items={resourceDDLList}
                  onChangeHandler={onResourceSelectionChangeHandler}
                  testId={"resource-ddl"}
                  selectedItem={selectedResource}
                />
              </Box>
            </div>
        </Grid>
      </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <Box sx={{ border: "1px solid #0057db" }}>
            <div
              style={{
                background: "#0057db",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "#fff", padding: "7px" }}>
                {resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName === "T3_Beneficiary" ? "T3 Beneficiary" : resourceName} of
              </Typography>
              <Box
                sx={{
                  width: "15%",
                  padding: "3px 3px 0 0",
                  "& .css-26l3qy-menu": { zIndex: 2 },
                }}
              >
                <EnhancedDropDown
                  selectName={"invoiceStatus"}
                  items={yearsDDLItems}
                  onChangeHandler={onYearSelectionChangeHandler}
                  testId={"year-ddl"}
                  selectedItem={moveResourceFirstPanelYear}
                />
              </Box>
            </div>
            <MoveEmployeeFirstPanel
              columns={
                moveResourceFirstPanel && moveResourceFirstPanelUpdatedColumns
              }
              rows={
                moveResourceFirstPanel &&
                moveResourceFirstPanel.length !== 0 &&
                moveResourceFirstPanel
              }
              selectionToolbar={moveResourceFirstPanelSelectionToolbar}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              icon="pi pi-angle-double-right"
              onClick={onMoveResourceToPanelTwoClicked}
              size="small"
              severity="info"
              raised
              disabled={
                moveResourceFirstPanelItems &&
                moveResourceFirstPanelItems.length > 0
                  ? false
                  : true
              }
            />
            <br />
            <Button
              icon="pi pi-angle-double-left"
              onClick={onMoveResourceToPanelOneClicked}
              size="small"
              severity="info"
              raised
              disabled={
                moveResourceSecondPanelItems &&
                moveResourceSecondPanelItems.length > 0
                  ? false
                  : true
              }
            />
          </Box>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <Box sx={{ border: "1px solid #0057db" }}>
            <div
              style={{
                background: "#0057db",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "#fff", padding: "7px" }}>
                {resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName === "T3_Beneficiary" ? "T3 Beneficiary" : resourceName} of {taxYear}
              </Typography>
              {/* <Box sx={{ width: "15%", padding:"3px 3px 0 0", "& .css-26l3qy-menu":{zIndex:2} }}>
                <EnhancedDropDown
                  selectName={"invoiceStatus"}
                  items={yearsDDLItems}
                  onChangeHandler={onYearSelectionChangeHandler}
                  testId={"year-ddl"}
                  selectedItem={moveResourceFirstPanelYear}
                />
              </Box> */}
            </div>
            <MoveEmployeeFirstPanel
              columns={
                moveResourceSecondPanel && moveResourceFirstPanelUpdatedColumns
              }
              rows={
                moveResourceSecondPanel &&
                moveResourceSecondPanel.length !== 0 &&
                moveResourceSecondPanel
              }
              selectionToolbar={moveResourceSecondPanelSelectionToolbar}
            />
          </Box>
        </Grid>
      </Grid>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"resource-list-snackbar"}
        open={snackbarData.open}
      />
    </>
  );
};
