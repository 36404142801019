import { IEmployeeListPayload } from "models/employee";
import { IVendorListPayload } from "models/vendor";
import { CLIENT } from "../types";

export interface IAPIKeys {
  auth_key: string,
  value_secret: string,
}

export interface IClient {
  id: string;
  apiKeys?: IAPIKeys;
  employeeList?: IEmployeeListPayload,
  vendorList?: IVendorListPayload
  clientName?:string
} 

const initialState: IClient = {
  id: ""
};

export const clientReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLIENT.API_KEYS_REQUEST_LOAD:
      return {
        ...state,
      };
    case CLIENT.API_KEYS_REQUEST_SUCCESS:
      return {
        ...state,
        apiKeys: {
          auth_key: action.payload.auth_key,
          value_secret: action.payload.value_secret,
        },
      };
    case CLIENT.API_KEYS_REQUEST_GENERATE:
      return {
        ...state,
      };
    case CLIENT.EMPLOYEE_LIST_REQUEST_LOAD:
      return {
        ...state,
        employeeList: {},
      };
    case CLIENT.EMPLOYEE_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        employeeList: action.payload,
      };
    case CLIENT.VENDOR_LIST_REQUEST_LOAD:
      return {
        ...state,
        vendorList: {},
      };
    case CLIENT.VENDOR_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        vendorList: action.payload,
      };
    case CLIENT.CLIENT_SET_SELECTED:
      return {
        ...state,
        id: action.payload.id,
      }
    case CLIENT.CLIENT_CLEAR_SELECTED:
      return {
        ...state,
        id: "",
      }
    case CLIENT.CLIENT_NAME_SET_SELECTED:
      return {
        ...state,
        clientName: action.payload.clientName,
      }
    case CLIENT.CLIENT_NAME_CLEAR_SELECTED:
      return {
        ...state,
        clientName: "",
      }
    default:
      return state;
  }
};
