import { LeftMenu } from "components/LeftMenu";

const data = [
  {
    id: 10110,
    text: "Employee",
    open: false,
    menu: [
      {
        menuId: 10111,
        text: "List",
        navigateToURL: "/resources/Employee/list",
        parentMenuId: 10110,
        grandParentMenuId: 10100,
      },
      {
        menuId: 10112,
        text: "Setup",
        navigateToURL: "/resources/Employee/setup",
        parentMenuId: 10110,
        grandParentMenuId: 10100,
      },
      {
        menuId: 10114,
        text: "Merge Employees",
        navigateToURL: "/resources/Employee/merge",
        parentMenuId: 10110,
        grandParentMenuId: 10100,
      },
      {
        menuId: 10115,
        text: "Undo Merge Employees",
        navigateToURL: "/resources/Employee/undo-merge",
        parentMenuId: 10110,
        grandParentMenuId: 10100,
      },
    ],
    parentMenuId: 10100,
  },
  {
    id: 10120,
    text: "Vendor",
    open: false,
    menu: [
      {
        menuId: 10121,
        text: "List",
        navigateToURL: "/resources/Vendor/list",
        parentMenuId: 10120,
        grandParentMenuId: 10100,
      },
      {
        menuId: 10122,
        text: "Setup",
        navigateToURL: "/resources/Vendor/setup",
        parentMenuId: 10120,
        grandParentMenuId: 10100,
      },
      //   {
      //     menuId: 10123,
      //     text: "Merge Vendors",
      //     navigateToURL: "/resources/Vendor/merge",
      //     parentMenuId: 10120,
      //     grandParentMenuId: 10100,
      //   },
      //   {
      //     menuId: 10124,
      //     text: "Undo Merge Vendors",
      //     navigateToURL: "/resources/Vendor/undo-merge",
      //     parentMenuId: 10120,
      //     grandParentMenuId: 10100,
      //   },
    ],
    parentMenuId: 10100,
  },
  {
    id: 10130,
    text: "T5 Recipient",
    open: false,
    menu: [
      {
        menuId: 10131,
        text: "List",
        navigateToURL: "/resources/T5_Recipient/list",
        parentMenuId: 10130,
        grandParentMenuId: 10100,
      },
      {
        menuId: 10132,
        text: "Setup",
        navigateToURL: "/resources/T5_Recipient/setup",
        parentMenuId: 10130,
        grandParentMenuId: 10100,
      },
    ],
    parentMenuId: 10100,
  },
  {
    id: 10140,
    text: "T3 Beneficiary",
    open: false,
    menu: [
      {
        menuId: 10141,
        text: "List",
        navigateToURL: "/resources/T3_Beneficiary/list",
        parentMenuId: 10140,
        grandParentMenuId: 10100,
      },
      {
        menuId: 10142,
        text: "Setup",
        navigateToURL: "/resources/T3_Beneficiary/setup",
        parentMenuId: 10140,
        grandParentMenuId: 10100,
      },
    ],
    parentMenuId: 10100,
  },
  {
    id: 10150,
    text: "Filing Resource",
    open: false,
    menu: [
      {
        menuId: 10151,
        text: "List",
        navigateToURL: "/filing-resources/list",
        parentMenuId: 10150,
        grandParentMenuId: 10100,
      },
      {
        menuId: 10152,
        text: "Setup",
        navigateToURL: "/filing-resources/setup",
        parentMenuId: 10150,
        grandParentMenuId: 10100,
      },
    ],
    parentMenuId: 10100,
  },
  {
    id: 10160,
    text: "Move People",
    open: false,
    navigateToURL: "/resources/Employee/move-people",
    parentMenuId: 10100,
  },
];

export const PeopleMenu = () => {
  return <LeftMenu menuItems={data} />;
};
