import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { T3Report } from "components/Reports/T3Report";

export interface IT3ReportPage {}

export const T3ReportPage: FC<IT3ReportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T3 Report"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<T3Report />}
    />
  );
};
