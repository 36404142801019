import React, { FC, useEffect, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import { grey } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ITest } from '@websential/cosmic';
import { IconButton,
  Box, } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import "./EnhancedAppBarStyle.css"

import { useDispatch, useSelector } from 'react-redux';
import { setDrawerCurrentState } from 'store/actions/drawerActions';
import { IAppState } from 'store/reducers';

import { useNavigate } from "react-router-dom";
import { Menubar } from 'primereact/menubar';
import { BasicDemoMenuBar } from './BasicMenuBar';



// import { ITest, MaskUnmask } from "@websential/cosmic";

export interface IEnhancedAppBar extends ITest {
  /**
   * Icon to be displayed before the title
   */
  titleIcon?: React.ReactNode;
  /**
   * Title of the Appbar
   */
  title?: string;
  /**
   * An items toolbar to be added at right of Appbar; it may contain the user profile or notifications components
   */
  mask?: React.ReactNode;

  toolbar?: React.ReactNode;
  /**
   * Use to set the width of appBar
   */
  drawerWidth: string;
  /**
   * Content to be display in toolbar (should be a react component)
   */
  toolbarMenuContent: React.ReactNode;

  testId: string;
}

const StyledIcon = styled('div')(({ theme }) => ({
  margin: theme.spacing(2),
  display: "flex",
  color: grey[500],
}));

interface StyledAppBarProps {
  width: string;
  "data-testid": string;
}

const StyledAppBar = styled((props:StyledAppBarProps) => {
  console.log('$$$$$-CustomizedSlider-styled-props', props)
  return <AppBar {...props} />
})(
  ({ theme, width }: any) => `
  background-color: #ffffff;
  padding:0;
  color: ${grey[700]};
  width: ${width};
  // width: ${`calc(100% - 240px)`};
  marginLeft: 240px;
  z-index:3;
  box-shadow:0px 2px 4px -1px rgba(0,0,0,0),0px 4px 5px 0px rgba(0,0,0,0.05),0px 1px 10px 0px rgba(0,0,0,0);
  :hover {
    color: ${theme.palette.primary.main, 0.2};
  }
`,
);

export const EnhancedAppBar: FC<IEnhancedAppBar> = ({
  titleIcon,
  title,
  mask,
  toolbar,
  testId,
  drawerWidth,
  toolbarMenuContent
}: IEnhancedAppBar) => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const drawerAction = useSelector((state:IAppState) => state.drawerAction.open)
  const [buttonState, setButtonState] = useState(drawerAction);
  const onToggleButtonClickHandler = (e:any) => {
    // Toggle the state between true and false
    setButtonState((prevButtonState:any) => !prevButtonState);
    console.log("buttonState",buttonState)
    // alert(buttonState)
    console.log("jdcjasbkc d",e)
  }
  
  useEffect(()=>{
    dispatch(setDrawerCurrentState({open:buttonState}));
    console.log("buttonState out",buttonState)
  },[buttonState])


  const items = [
    // {
    //     label: 'Home',
    //     items: [
    //         {
    //             label: 'Dashboard',
    //             // url: "/#/clients/dashboard",
    //             // command: () => {
    //             //   history.push('/installation');
    //             // }
    //             // Use Link instead of router.push
    //             // content: <Link to="/clients/dashboard">Dashboard</Link>,
    //             // command:onHomeClicked,
    //             command: () => {
    //               // router.push('/installation');
    //               alert("fffff")
    //               // navigate("/clients/dashboard")
    //           },
    //         },
    //         {
    //             label: 'Client List',
    //             // url: '/#/accountants/clients'
    //         },
    //         {
    //             label: 'Super User',
    //             // url: '/#/accountants'
    //         },
    //         {
    //             label: 'Global Search',
    //             // url: '/#/global-search'
    //         },
    //     ]
    // },
    // {
    //   label: "Report",

    //   items: [
    //     {
    //       label: "Employees",
    //       command: () => {
    //         // navigate("/reports/employees");
    //       },
    //     },
    //     {
    //       label: "Vendors",
    //       command: () => {
    //         // navigate("/reports/vendors");
    //       },
    //     },
    //   ]
    //   },
      {
        label: 'Home',
        icon: 'pi pi-home'
    },
    {
        label: 'Features',
        icon: 'pi pi-star'
    },
    {
        label: 'Projects',
        icon: 'pi pi-search',
        items: [
            {
                label: 'Components',
                icon: 'pi pi-bolt'
            },
            {
                label: 'Blocks',
                icon: 'pi pi-server'
            },
            {
                label: 'UI Kit',
                icon: 'pi pi-pencil'
            },
            {
                label: 'Templates',
                icon: 'pi pi-palette',
                items: [
                    {
                        label: 'Apollo',
                        icon: 'pi pi-palette'
                    },
                    {
                        label: 'Ultima',
                        icon: 'pi pi-palette'
                    }
                ]
            }
        ]
    },
    {
        label: 'Contact',
        icon: 'pi pi-envelope'
    }
    
];

  return (
    <StyledAppBar data-testid={testId} width={drawerWidth}>
      <Toolbar sx={{padding:"0 15px !important"}}>
      <IconButton aria-label="toggle" sx={{borderRadius:"5px"}} onClick={onToggleButtonClickHandler}>
            <DehazeIcon sx={{margin:0}}/>
      </IconButton>
        <StyledIcon data-testid={testId + "-icon"}>
          {titleIcon}
        </StyledIcon>
        <Typography
          variant="h5"
          noWrap
          sx={{
            // marginLeft: theme.spacing(2),
            display: "flex",
          }}
          data-testid={testId + "-title"}
        >
          {title}
        </Typography>

        <div style={{ flexGrow: 20 }} />
        {/* <BasicDemoMenuBar /> */}
        {toolbarMenuContent}

          {mask}
        <div style={{ flexGrow: 1 }} />
        <div data-testid={testId + "-toolbar"}>{toolbar}</div>
      </Toolbar>
    </StyledAppBar>
  );
};
