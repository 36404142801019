import {
  GET_QUERY_KEY,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  RL16_SETTINGS_SCREEN_SLUG,
} from "common/constants";
import { instanceOfIFlexFieldSetupRequest } from "common/instance-method";
import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { IGetListRequest, IResponse, IServerError } from "models/common";
import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FlexFieldService } from "services/FlexFieldService";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BackdropCircularProgress,
  EnhancedDropDownRHFWithLabel,
  EnhancedIOSSwitchRHFWithLabel,
  EnhancedSnackbar,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  PhoneNumberRHF,
  PostCodeRHF,
  RHookFormMaskedTextFieldWithLabel,
  RHookFormTextFieldWithLabel,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  TwoColumnLayout,
} from "@websential/cosmic";

import { ClientService, TaxSlipYearService } from "services";
import { T4SlipService } from "services/T4SlipService";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { RL16SlipSettingValidation } from "./RL16SettingValidation";
import { RL16SlipService } from "services/RL16SlipService";
import { Grid } from "@mui/material";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { ProvinceService } from "services/common/ProvinceService";
import { RHookFormMaskedTextFieldWithLabelWithOnChange } from "./RHookFormMaskedTextFieldWithLabelWithOnChange/RHookFormMaskedTextFieldWithLabelWithOnChange";
import { RL16SlipSettingValidationSchema } from "./validator";

export interface IRL16SlipSettingsSetup extends ITest {}

const DEFAULT_VALUES = {
  // comparison: [
  //   {
  //     operator: undefined,
  //     field: undefined,
  //   },
  // ],
  // fieldType: undefined,
  // fieldLabel: "",
  // fieldId: "",
  // defaultValue: "",
  // placeholder: "",
  // fieldFormat: "",
  // fieldValue: "",
  // dataType: undefined,
  // fieldColumn: undefined,
  // parentRelation: undefined,
  // fieldInternalValue: "",
  // notes: "",
  // minValue: 0,
  // maxValue: 0,
  // isCategory: false,
  // isFixed: false,
  // isHide: false,
  // isRequired: false,
  // parentValue: "",
  // parent: undefined,
  // id: undefined,
};

// const FLEX_FIELD_DATA_QUERY_KEY = "get-flex-field-data";
// const LIST_FIELDS_QUERY_KEY = "get-flex-fields-list";

// const useGetFlexFieldData = (flexFieldId: number) =>
//   useQuery(
//     [FLEX_FIELD_DATA_QUERY_KEY, flexFieldId],
//     () => FlexFieldService.getFlexFieldById(flexFieldId),
//     { ...QUERY_OPTIONS, enabled: !!flexFieldId }
//   );
// const useGetFlexFieldsList = () =>
//   useQuery(
//     LIST_FIELDS_QUERY_KEY,
//     () => FlexFieldService.getFlexFieldDropDownList("none"),
//     QUERY_OPTIONS
//   );

//Updted code starts from here
// Taxslip Year List
const useYears = (params: IGetListRequest) =>
  useQuery(
    ["years-list", params],
    (data) => {
      return TaxSlipYearService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

const RL16_SETTING_DATA_QUERY_KEY = "get-RL16-setting-data";
const useGetRL16SettingData = (clientid: string, taxYear: number) =>
  useQuery(
    [RL16_SETTING_DATA_QUERY_KEY, clientid, taxYear],
    (data) => {
      return RL16SlipService.getRL16SettingData(clientid, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

  const useGetProfileData = (id: string | undefined) => 
    useQuery(
      ["client-single-data-record", id],
      (data) => { return ClientService.getById(id);
      },
      { refetchOnWindowFocus: false }
    );

  const useGetTransmitterDDL = (clientId: string, taxYear: number) =>
    useQuery(
      ["transmitters"],
      () => {
        return T4SlipService.getAllActiveTransmittersByClientIdAndTaxYear(
          clientId,
          taxYear
        );
      },
      { refetchOnWindowFocus: false }
    );

export const RL16SlipSettingsSetup: FC<IRL16SlipSettingsSetup> = ({ testId }) => {
  const dispatch = useDispatch();
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );

  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [filer, setfiler] = useState(false);
  const [filerFieldProperties, setfilerFieldProperties] = useState(false);
  // const queryClient = useQueryClient();

  const useGetProvinceDDL = () => {
    return useQuery(GET_QUERY_KEY.PROVINCE_DDL, () => ProvinceService.get(), {
      ...QUERY_OPTIONS,
    });
  };
  const { isLoading: isLoadingProvinceDropDownList, data: provinceList } =
    useGetProvinceDDL();

  //new code
  const id = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [selectedYear, setSelectedYear] = useState(taxYear);

  const { isLoading: isSubmitting, mutate: submitRL16SettingData } = useMutation(
    RL16SlipService.rl16SettingCreate,
    {
      onSuccess: (res: any | IServerError | IResponse) => {
        // if (instanceOfIFlexFieldSetupRequest(res)) {
        // // remove the cache of the flex fields since we just added a new field.
        // queryClient.invalidateQueries(LIST_FIELDS_QUERY_KEY);
        // // [corner case] remove the cache of the field that has just been edited in-case the user re-selects it again
        // queryClient.invalidateQueries([FLEX_FIELD_DATA_QUERY_KEY, res.id]);
        // // set the currently added/edited flex field as the selected field
        // formMethods.setValue("id", res.id);
        if (res.message) {
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "error",
          });
        } else {
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );
  // const { isLoading: isLoadingFlexFieldsList, data: flexFieldsList } =
  //   useGetFlexFieldsList();
  // const validationSchema = useMemo(
  //   () =>
  //     getValidationSchema(
  //     //   flexFieldsList && Array.isArray(flexFieldsList)
  //     //     ? flexFieldsList.map((flexField: any) => ({
  //     //         id: flexField.id,
  //     //         value: flexField.fieldId,
  //     //       }))
  //     //     : []
  //     ),
  //   [flexFieldsList]
  // );
  const formMethods = useForm<any>({
    resolver: yupResolver(RL16SlipSettingValidationSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  // const [selectedFlexFieldId, selectedParent] = useWatch({
  //   control: formMethods.control,
  //   name: ["id", "parent"],
  // });
  // const { isLoading: isLoadingSelectedFlexField, data: selectedFlexFieldData } =
  //   useGetFlexFieldData(selectedFlexFieldId as number);

  /**
   * Handle when the form is submitted.
   *
   * @param {IFlexFieldSetupRequest} formData
   */
  const onSubmit = (formData: any) => {
    const parseJSONLikeString = (str: string) => {
      const modifiedString = str && str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
      return JSON.parse(modifiedString);
    };
    const requestPayload = {
      id: id,
      taxYear: formData.taxYear,
      trustAccountNumber: formData.trustAccountNumber,
      nameOfTrust: formData.nameOfTrust,
      transmitter: formData.transmitter,
      submissionMethod: parseInt(formData.submissionMethod),
      sourceOfRL16SlipsUsed: formData.sourceOfRL16SlipsUsed,
      identificationNumber: formData.identificationNumber,
      trustIdentificationNumber: formData.trustIdentificationNumber,
      country: {
        id: 1,
        name: "Canada",
        shortCode: "CAN",
      },
      neq: formData.neq,
      filerName: formData.filerName,
      filerIdentificationNumber: formData.filer === true ? formData.filerIdentificationNumber : undefined,
      filerFirstName: formData.filer === false ? formData.filerFirstName : undefined,
      filerLastName: formData.filer === false ? formData.filerLastName : undefined,
      filer: formData.filer,
      filerAddress: {
        address: formData.address,
        city: formData.city,
        province: formData.filerProvince && parseJSONLikeString(formData.filerProvince),
        // postalCode: formData.postalCode,
        postalCode:
        formData.postalCode ? 
        formData.postalCode.replace(/[a-z]/g, (match: string) =>
          match.toUpperCase()
        ) : undefined,
        country: {
          id: 1,
          name: "Canada",
          shortCode: "CAN",
          },
          usedCompanyAddress: formData.usedCompanyAddress,
      },
      copyFromPreviousYear: formData.copyFromPreviousYear,
      sequentialNumberStart: formData.sequentialNumberStart,
      sequentialNumberEnd: formData.sequentialNumberEnd,
    };
    submitRL16SettingData(requestPayload);
    console.log("formData", formData);
    console.log("requestPayload", requestPayload);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset();
  };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // const yearListItems = useMemo(() => {
  //   return [
  //     {
  //       label: "2020",
  //       value: "2020",
  //     },
  //     {
  //       label: "2021",
  //       value: "2021",
  //     },
  //   ];
  //   // if (!flexFieldsList) {
  //   //   return [];
  //   // }
  //   // return flexFieldsList.map((flexField) => ({
  //   //   label: flexField.fieldLabel,
  //   //   value: flexField.id,
  //   // }));
  // }, []);

  // set the values of the selected flex field coming from BE.
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (selectedFlexFieldData) {
  //     Object.keys(selectedFlexFieldData).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = selectedFlexFieldData[fieldName];
  //       // @ts-ignore
  //       formMethods.setValue(fieldName, fieldValue);
  //     });
  //   }
  // }, [selectedFlexFieldData, formMethods.setValue, formMethods]);

  // if we have selected the parent and a flex field and they both are the same, we should un-set the parent because a field cannot be the parent of itself
  // useEffect(() => {
  //   if (
  //     selectedFlexFieldId &&
  //     selectedParent &&
  //     selectedParent === selectedFlexFieldId
  //   ) {
  //     formMethods.setValue("parent", undefined);
  //   }
  // }, [formMethods, selectedFlexFieldId, selectedParent]);

  //new code
  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "role_name",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useYears(queryParams); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const YearsList: any = data;
  console.log("YearsList", YearsList);
  const years = YearsList?.items.map((x: any) => {
    const { year, ...rest } = x; // Extract year property and the rest of the properties
    return { ...rest, value: year, label: year }; // Rename year to value
  });
  console.log("years", years);


  const { isLoading: isLoadingSummaryGetData, data: RL16SettingData, refetch: refetchRL16SettingData } =
    useGetRL16SettingData(id, selectedYear);

  const { isLoading: isLoadingProfileData, data: clientProfileDataRecord } =
    useGetProfileData(id);

  const { isLoading: isLoadingTransmitterDDL, data: transmitterDDL } =
    useGetTransmitterDDL(id, taxYear);
  console.log("transmitterDDL", transmitterDDL);

  useEffect(()=>{
    if(clientProfileDataRecord && clientProfileDataRecord.clientName && RL16SettingData && !RL16SettingData.id){
      formMethods.setValue('nameOfTrust',clientProfileDataRecord.clientName)
    }
  },[clientProfileDataRecord])

  console.log("RL16SettingData ============", RL16SettingData);

  useEffect(() => {
    if (RL16SettingData && RL16SettingData.id && filerFieldProperties === false) {
      Object.keys(RL16SettingData).forEach((fieldName) => {
        const fieldValue = RL16SettingData[fieldName];
        formMethods.setValue(fieldName, fieldValue ?? undefined);
        if(fieldName === 'filerAddress'){
          Object.keys(fieldValue).forEach((addressFieldName) => {
            const addressFieldValue = fieldValue[addressFieldName]
            formMethods.setValue(addressFieldName, addressFieldValue ?? undefined);
            if (addressFieldName === "province") {
              let id = addressFieldValue.id;
              let name = addressFieldValue.name;
              let shortCode = addressFieldValue.shortCode;
              formMethods.setValue(
                'filerProvince',
                // '{id:9, name: "Ontario",shortCode:"ON"}'
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
              );
            }
            if (addressFieldName === "country") {
              formMethods.setValue('country',addressFieldValue.name);
            }
          })
        }
        if(fieldName === "filer"){
          setfiler(fieldValue)
          formMethods.setValue(fieldName, fieldValue ?? undefined);
        }
        if(fieldName === "filerName"){
          if(filer){
            formMethods.setValue(fieldName, fieldValue);
          }
        }
        if(fieldName === "filerFirstName"){
          if(!filer){
            formMethods.setValue(fieldName, fieldValue);
          } else {
            formMethods.setValue(fieldName, "");
          }
        }
        if(fieldName === "filerLastName"){
          if(!filer){
            formMethods.setValue(fieldName, fieldValue);
          } else {
            formMethods.setValue(fieldName, "");
          }
        }
      });
    } else if (RL16SettingData && RL16SettingData.message) {
      setSnackbarData({
        open: true,
        message: RL16SettingData.message.message ? RL16SettingData.message.message : RL16SettingData.message.detail ? RL16SettingData.message.detail : RL16SettingData.message ? RL16SettingData.message : 'Something went wrong!',
        severity: "error",
      });
      // formMethods.reset();
      formMethods.setValue('taxYear',selectedYear)
      formMethods.setValue('filer',filer)
      if(clientProfileDataRecord){
        formMethods.setValue('nameOfTrust',clientProfileDataRecord.clientName)
      }
    } else if(filerFieldProperties === false) {
      // formMethods.reset();
      formMethods.setValue('taxYear',selectedYear)
      formMethods.setValue('filer',filer)
        if(clientProfileDataRecord){
          formMethods.setValue('nameOfTrust',clientProfileDataRecord.clientName)
        }
    }
  }, [RL16SettingData, formMethods.setValue, formMethods,filer]);
  console.log("lll-", formMethods.getValues("identification"));
  // console.log("lll-",formMethods.getValues('identification').length)

  const onYearSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
      setSelectedYear(Number(item.value));
      setfilerFieldProperties(false)
      refetchRL16SettingData()
    }

  const onUsedCompanyAddressChangeHandler = (value: boolean) => {
      if(value){
        (async () => {
          try {
            setIsLoadingData(true)
            const response: any = await ClientService.getById(id);
            console.log("responseData-----------------", response);
            if (response) {
              if (response && response.id) {
                Object.keys(response).forEach((fieldName) => {
                  const fieldValue = response[fieldName];
                  if(fieldName === "address"){
                    Object.keys(fieldValue).forEach((addressFieldName) => {
                      const addressFieldValue = fieldValue[addressFieldName]
                      Object.keys(addressFieldValue).forEach((addressFieldName1) => {
                        const addressFieldValue1 = addressFieldValue[addressFieldName1]
                        if(addressFieldName1 === 'addressLine1'){
                          formMethods.setValue('address', addressFieldValue1 ?? undefined);
                        }
                        if(addressFieldName1 === 'city'){
                          formMethods.setValue(addressFieldName1, addressFieldValue1 ?? undefined);
                        }
                        if(addressFieldName1 === 'postalCode'){
                          formMethods.setValue(addressFieldName1, addressFieldValue1 ?? undefined);
                        } 
                        if (addressFieldName1 === "province") {
                          let id = addressFieldValue1.id;
                          let name = addressFieldValue1.name;
                          let shortCode = addressFieldValue1.shortCode;
                          formMethods.setValue(
                            'filerProvince',
                            `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                          );
                        }
                      })
                    })
                  }
                });
              } 
            }
          } catch (error) {
            setIsLoadingData(false)
            console.error("Error fetching data:", error);
          } finally {
            setIsLoadingData(false)
            }
            })();
      } else {
        formMethods.setValue('city','')
        formMethods.setValue('filerProvince',undefined)
        formMethods.setValue('postalCode','')
        formMethods.setValue('address','')
      }
  }

  const onTrustIdentificationNumberChangeHandler = (value:any) => {
    let inputValue = value.target.value
    formMethods.setValue('identificationNumber',inputValue.substring(0, 10))
  }

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: RL16_SETTINGS_SCREEN_SLUG,
        companyName: clientName,
        activityType: "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));

    const identificationNum = formMethods.getValues('trustIdentificationNumber')
    console.log('identificationNum',identificationNum)
    if(identificationNum && identificationNum.length > 0){
      formMethods.setValue('identificationNumber',identificationNum.substring(0, 10))
    }
  }, [formMethods]);

  const onCopyFromPrevoisYearChangedHandler = (value: boolean) => {
    if(value){
      (async () => {
        try {
          setIsLoadingData(true)
          const response: any = await RL16SlipService.getRL16SettingData(id, selectedYear-1);
          console.log("responseData-----------------", response);
          if (response) {
            if (response && response.id) {
              Object.keys(response).forEach((fieldName) => {
                const fieldValue = response[fieldName];
                if(fieldName === 'sequentialNumberStart'){
                  formMethods.setValue(fieldName, fieldValue ?? undefined);
                }
                if(fieldName === 'sequentialNumberEnd'){
                  formMethods.setValue(fieldName, fieldValue ?? undefined);
                }
              });
            } else if (response && response.message) {
              setSnackbarData({
                open: true,
                message: response.message.message ? response.message.message : response.message ? response.message : 'Something went wrong!',
                severity: "error",
              });
              formMethods.setValue('sequentialNumberStart',undefined)
              formMethods.setValue('sequentialNumberEnd',undefined)
              }
          }
        } catch (error) {
          setIsLoadingData(false)
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false)
          }
          })();
    } else {
      formMethods.setValue('sequentialNumberStart',undefined)
      formMethods.setValue('sequentialNumberEnd',undefined)
    }
}

const onFilerSelectionChangeHandler = (value:any) => {
  setfiler(value)
  setfilerFieldProperties(true)
}

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={
          isLoading ||
          // isLoadingSelectedFlexField ||
          isSubmitting ||
          isLoadingProfileData ||
          isLoadingSummaryGetData ||
          isLoadingActivityLogData ||
          isLoadingTransmitterDDL ||
          isLoadingData
        }
        testId={testId}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <TwoColumnLayout
            toolbar={
              <StandardSubmitFormButtonsToolbar
                onResetClicked={onResetClicked}
                isSubmitting={isSubmitting}
                testId={testId}
              />
            }
            leftColumnContent={
              <>
                <StandardPanel title="Year" testId={`${testId}-year-panel`}>
                  <EnhancedDropDownRHFWithLabel
                    label="Select Year"
                    selectName="taxYear"
                    items={years ?? []}
                    testId={`${testId}-selected-year`}
                    labelPosition={LabelPosition.Left}
                    selectedItem={selectedYear}
                    onSelectionChangeHandler={onYearSelectionChangeHandler}
                  />
                </StandardPanel>
                <StandardPanel
                  title="Slip Data"
                  testId={`${testId}-slip-data`}
                  topPadding={2}
                >
                  <EnhancedDropDownRHFWithLabel
                    label="Transmitter"
                    selectName="transmitter"
                    items={transmitterDDL && !transmitterDDL.message ? transmitterDDL : []}
                    // selectedItem="2020"
                    testId={`${testId}-transmitter`}
                    labelPosition={LabelPosition.Left}
                    required
                  />
                  <EnhancedDropDownRHFWithLabel
                    label="Submission Method"
                    selectName="submissionMethod"
                    items={[
                      { label: "XML", value: 1 },
                      { label: "Paper", value: 2 },
                    ]}
                    selectedItem={1}
                    testId={`${testId}-submission-method`}
                    labelPosition={LabelPosition.Left}
                    required
                  />
                  <EnhancedDropDownRHFWithLabel
                    label="Source of RL-16 Slips Used"
                    selectName="sourceOfRL16SlipsUsed"
                    items={[
                      {
                        label:
                          "if the RL slips were obtained from Revenu Québec",
                        value: "if the RL1 slips were obtained from quebec",
                      },
                      {
                        label:
                          "if the RL slips are facsimiles obtained from a third party",
                        value:
                          "if the RL1 slips are facsimiles obtained from a third party",
                      },
                      {
                        label:
                          "if the RL slips are facsimiles produced by the transmitter",
                        value:
                          "if the RL1 slips are facsimiles produced by the transmitter",
                      },
                      {
                        label:
                          "if the source of the RL slips is any combination of the above",
                        value:
                          "if the source of the RL1 slips is any combination of the above",
                      },
                    ]}
                    // selectedItem="1"
                    testId={`${testId}-source-of-rl-slips-used`}
                    labelPosition={LabelPosition.Left}
                    required
                  />
                </StandardPanel>
                <StandardPanel
                  title="Trust Identification"
                  testId={`${testId}-identification-data`}
                  topPadding={2}
                >
                  {/* <RHookFormTextFieldWithLabel
                    label="Trust Identification #"
                    id="trustIdentificationNumber"
                    testId={`${testId}-identificationNumber`}
                    labelPosition={LabelPosition.Left}
                    onChangeHandler={onTrustIdentificationNumberChangeHandler}
                  /> */}
                  {/* <RHookFormMaskedTextFieldWithLabel
                      id='trustIdentificationNumber'
                      label='Identification #'
                      labelPosition={LabelPosition.Left}
                      testId='trustIdentificationNumber'
                      labelColumns={4}
                      fieldColumns={8}
                      placeholder={"000000000RS0000"}
                      inputMaskPattern={"{000000000Ra0000}"}
                      
                      // ons={onTrustIdentificationNumberChangeHandler}
                  /> */}
                  <RHookFormMaskedTextFieldWithLabelWithOnChange
                      id='trustIdentificationNumber'
                      label='Identification #'
                      labelPosition={LabelPosition.Left}
                      testId='trustIdentificationNumber'
                      labelColumns={4}
                      fieldColumns={8}
                      placeholder={"0000000000RS0000"}
                      inputMaskPattern={"{0000000000Ra0000}"}
                      onChangeHandler={onTrustIdentificationNumberChangeHandler}
                  />
                  <RHookFormTextFieldWithLabel
                    label="Identification # (First 10 Characters)"
                    id="identificationNumber"
                    testId={`${testId}-identificationNumber`}
                    labelPosition={LabelPosition.Left}
                    readOnly
                  />
                  <RHookFormMaskedTextFieldWithLabel
                        label="NEQ."
                        id="neq"
                        testId={`${testId}-neq`}
                        labelPosition={LabelPosition.Left}
                        placeholder={"0000000000"}
                        // required
                        inputMaskPattern={"0000000000"}
                        labelColumns={4}
                        fieldColumns={8}
                      />
                  <RHookFormMaskedTextFieldWithLabel
                        label="Trust Account #"
                        id="trustAccountNumber"
                        testId={`${testId}-trustAccountNumber`}
                        labelPosition={LabelPosition.Left}
                        placeholder={"T00000000"}
                        // required
                        inputMaskPattern={"T00000000"}
                        labelColumns={4}
                        fieldColumns={8}
                      />
                  <RHookFormTextFieldWithLabel
                    label="Name of Trust"
                    id="nameOfTrust"
                    testId={`${testId}-nameOfTrust`}
                    labelPosition={LabelPosition.Left}
                  />
                </StandardPanel>
              </>
            }
            rightColumnContent={
              <>
              <StandardPanel
                  title="Sequential Numbers"
                  testId={`${testId}-sequential-numbers`}
                >
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="copyFromPreviousYear"
                      checked={false}
                      testId="copyFromPreviousYear"
                      label="Copy from Previous Year"
                      labelPosition={LabelPosition.Left}
                      onSelectionChangeHandler={onCopyFromPrevoisYearChangedHandler}
                    />
                  </FieldWrapper>
                  <RHookFormMaskedTextFieldWithLabel
                    label="Start #"
                    id="sequentialNumberStart"
                    testId={`${testId}-start-number`}
                    labelPosition={LabelPosition.Left}
                    placeholder={"00000000"}
                    required
                    inputMaskPattern={"00000000"}
                    labelColumns={4}
                    fieldColumns={8}
                  />
                  <RHookFormMaskedTextFieldWithLabel
                    label="End #"
                    id="sequentialNumberEnd"
                    testId={`${testId}-end-number`}
                    labelPosition={LabelPosition.Left}
                    placeholder={"00000000"}
                    required
                    inputMaskPattern={"00000000"}
                    labelColumns={4}
                    fieldColumns={8}
                  />
                </StandardPanel>
                <StandardPanel
                  title="Filer Identification"
                  testId={`${testId}-sequential-numbers`}
                  topPadding={2}
                >
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="filer"
                      checked={false}
                      testId="filer"
                      label="Filer is a Trustee"
                      labelPosition={LabelPosition.Left}
                      onSelectionChangeHandler={onFilerSelectionChangeHandler}
                    />
                  </FieldWrapper>
                  {filer ?  
                  <>
                    <RHookFormMaskedTextFieldWithLabel
                        id='filerIdentificationNumber'
                        label='Filer Identification #'
                        labelPosition={LabelPosition.Left}
                        testId='filerIdentificationNumber'
                        labelColumns={4}
                        fieldColumns={8}
                        placeholder={"0000000000RS0000"}
                        inputMaskPattern={"{0000000000Ra0000}"}
                    />
                    <RHookFormTextFieldWithLabel
                      label="Name"
                      id="filerName"
                      testId={`${testId}-filerName`}
                      labelPosition={LabelPosition.Left}
                    />
                  </> : 
                  <>
                  {/* <RHookFormTextFieldWithLabel
                    label="Filer Identification Number"
                    id="filerIdentificationNumber"
                    testId={`${testId}-filerIdentificationNumber`}
                    labelPosition={LabelPosition.Left}
                  /> */}
                  <RHookFormTextFieldWithLabel
                    label="First Name"
                    id="filerFirstName"
                    testId={`${testId}-filerFirstName`}
                    labelPosition={LabelPosition.Left}
                  />
                  <RHookFormTextFieldWithLabel
                    label="Last Name"
                    id="filerLastName"
                    testId={`${testId}-filerLastName`}
                    labelPosition={LabelPosition.Left}
                  />
                  </>}
                </StandardPanel>
                <StandardPanel
                  title="Address"
                  testId={`${testId}-sequential-numbers`}
                  topPadding={2}
                >
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="usedCompanyAddress"
                      checked={false}
                      testId="usedCompanyAddress"
                      label="Use Company Address"
                      labelPosition={LabelPosition.Left}
                      onSelectionChangeHandler={onUsedCompanyAddressChangeHandler}
                    />
                  </FieldWrapper>
                  <RHookFormTextFieldWithLabel
                    label="Address"
                    id="address"
                    testId={`${testId}-trustee-address`}
                    labelPosition={LabelPosition.Left}
                  />
                  <RHookFormTextFieldWithLabel
                    label="City"
                    id="city"
                    testId={`${testId}-trustee-city`}
                    labelPosition={LabelPosition.Left}
                  />
                  <EnhancedDropDownRHFWithLabel
                    selectName="filerProvince"
                    label="Province"
                    // selectedItem={selectedProvince.id !== null && selectedProvince}
                    labelPosition={LabelPosition.Left}
                    testId={`${testId}-province-list`}
                    items={provinceList ?? []}
                    // onSelectionChangeHandler={onProvinceSelectionChangeHandler}
                  />
                    <PostCodeRHF
                    label="Postal Code"
                    id="postalCode"
                    testId={`${testId}-trustee-post-code`}
                    labelPosition={LabelPosition.Left}
                    labelColumns={4}
                    fieldColumns={8}
                  />
                  <RHookFormTextFieldWithLabel
                    label="Country"
                    id="country"
                    testId={`${testId}-trustee-country`}
                    labelPosition={LabelPosition.Left}
                    value="Canada"
                    defaultValue={"Canada"}
                    readOnly
                  />
                </StandardPanel>
              </>
            }
            testId="RL16-settings-setup-form"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
