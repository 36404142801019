import { Item } from 'components/LeftMenu';

import { NAVIGATION } from '../types';

// export const setUserMenu = (menuItems: Item[]) => {
//   return {
//     type: NAVIGATION.SET_USER_MENU,
//     payload: [...menuItems]
//   };
// };

export const setUserMenuOpen = (
  selectedMenuItem: Item
) => {
  return {
    type: NAVIGATION.SET_USER_MENU_OPEN,
    payload: { ...selectedMenuItem },
  };
};

export const setUserMenuClose = (selectedMenuItem: Item) => {
  return {
    type: NAVIGATION.SET_USER_MENU_CLOSE,
    payload: { ...selectedMenuItem },
  };
};
