import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

export const ArrayData = [
  {
    id: 5000,
    text: "Home",
    open: true,
    icon: <HomeOutlinedIcon />,
    menu: [
      {
        menuId: 5001,
        text: "Client List",
        navigateToURL: "/accountants/clients",
        parentMenuId: 5000
      },
      {
        menuId: 5002,
        text: "Move Clients",
        navigateToURL: "/accountants/move-clients",
        parentMenuId: 5000
      },
      {
        menuId: 5003,
        text: "Adjustment Report",
        navigateToURL: "/accountants/client-adjustment-report",
        parentMenuId: 5000
      },
      {
        menuId: 5004,
        text: "Super User",
        navigateToURL: "/accountants",
        parentMenuId: 5000
      },
      {
        menuId: 5005,
        text: "Global Search",
        navigateToURL: "/global-search",
        parentMenuId: 5000
      },
    ],
  },
  {
    id: 5100,
    text: "Client",
    open: false,
    icon: <ContactMailOutlinedIcon />,
    menu: [
      {
        menuId: 5101,
        text: "List",
        navigateToURL: "/clients",
        parentMenuId: 5100
      },
      {
        menuId: 5102,
        text: "Setup",
        navigateToURL: "/clients/setup",
        parentMenuId: 5100
      },
    ],
  },
  {
    id: 100000,
    text: "Logout",
    icon: <LogoutOutlinedIcon />,
    navigateToURL: "/logout",
  },
];
