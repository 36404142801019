import { AccountFormLayout } from "components/AccountFormLayout";
import { IObject } from "models";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import {
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  IResponse,
  IServerError,
  LabelPosition,
  RHookFormTextFieldWithLabel,
  Spacer,
  StandardPanel,
  StyledRouterLink,
  SubmitButton,
  SubmitButtonVariant,
} from "@websential/cosmic";

import {
  LabelCaption,
  PlaceholderText,
  ValidationMessages,
} from "../../common/AppMessages";
import { AuthService } from "services";
import { IRegister, IRegisterResponse } from "./IRegister";
import { useState } from "react";
import { INITIAL_SNACKBAR_DATA } from "common/constants";
import { useMutation } from "react-query";
import { Alert, AlertTitle, Divider } from "@mui/material";
import { Button } from "primereact/button";
import TermsAndConditions from "./TermsAndConditions";

export interface ICreateAnAccountForm {
  firstName: string;
  lastName: string;
  email: string;
  timezone?: IObject;
}

const timeZoneItems: any = [
  {
    value: "(GMT-12:00) International Date Line West",
    label: "(GMT-12:00) International Date Line West",
  },
  {
    value: "(GMT-11:00) Midway Island, Samoa",
    label: "(GMT-11:00) Midway Island, Samoa",
  },
  { value: "(GMT-10:00) Hawaii", label: "(GMT-10:00) Hawaii" },
  { value: "(GMT-09:00) Alaska", label: "(GMT-09:00) Alaska" },
  {
    value: "(GMT-08:00) Pacific Time (US & Canada)",
    label: "(GMT-08:00) Pacific Time (US & Canada)",
  },
  {
    value: "(GMT-08:00) Tijuana, Baja California",
    label: "(GMT-08:00) Tijuana, Baja California",
  },
  { value: "(GMT-07:00) Arizona", label: "(GMT-07:00) Arizona " },
  {
    value: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
  },
  {
    value: "(GMT-07:00) Mountain Time (US & Canada)",
    label: "(GMT-07:00) Mountain Time (US & Canada)",
  },
  {
    value: "(GMT-06:00) Central America",
    label: "(GMT-06:00) Central America ",
  },
  {
    value: "(GMT-06:00) Central Time (US & Canada)",
    label: "(GMT-06:00) Central Time (US & Canada)",
  },
  {
    value: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
  },
  { value: "(GMT-06:00) Saskatchewan", label: "(GMT-06:00) Saskatchewan " },
  {
    value: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
    label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
  },
  {
    value: "(GMT-05:00) Eastern Time (US & Canada)",
    label: "(GMT-05:00) Eastern Time (US & Canada)",
  },
  { value: "(GMT-05:00) Indiana (East)", label: "(GMT-05:00) Indiana (East)" },
  {
    value: "(GMT-04:00) Atlantic Time (Canada)",
    label: "(GMT-04:00) Atlantic Time (Canada)",
  },
  {
    value: "(GMT-04:00) Caracas, La Paz",
    label: "(GMT-04:00) Caracas, La Paz",
  },
  { value: "(GMT-04:00) Manaus", label: "(GMT-04:00) Manaus " },
  { value: "(GMT-04:00) Santiago", label: "(GMT-04:00) Santiago " },
  { value: "(GMT-03:30) Newfoundland", label: "(GMT-03:30) Newfoundland " },
  { value: "(GMT-03:00) Brasilia", label: "(GMT-03:00) Brasilia " },
  {
    value: "(GMT-03:00) Buenos Aires, Georgetown",
    label: "(GMT-03:00) Buenos Aires, Georgetown ",
  },
  { value: "(GMT-03:00) Greenland", label: "(GMT-03:00) Greenland " },
  { value: "(GMT-03:00) Montevideo", label: "(GMT-03:00) Montevideo " },
  { value: "(GMT-02:00) Mid-Atlantic", label: "(GMT-02:00) Mid-Atlantic " },
  { value: "(GMT-01:00) Cape Verde Is.", label: "(GMT-01:00) Cape Verde Is. " },
  { value: "(GMT-01:00) Azores", label: "(GMT-01:00) Azores " },
  {
    value: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
    label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik ",
  },
  {
    value:
      "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
    label:
      "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London ",
  },
  {
    value: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna ",
  },
  {
    value: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague ",
  },
  {
    value: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris ",
  },
  {
    value: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb ",
  },
  {
    value: "(GMT+01:00) West Central Africa",
    label: "(GMT+01:00) West Central Africa ",
  },
  { value: "(GMT+02:00) Amman", label: "(GMT+02:00) Amman " },
  {
    value: "(GMT+02:00) Athens, Bucharest, Istanbul",
    label: "(GMT+02:00) Athens, Bucharest, Istanbul ",
  },
  { value: "(GMT+02:00) Beirut", label: "(GMT+02:00) Beirut " },
  { value: "(GMT+02:00) Cairo", label: "(GMT+02:00) Cairo " },
  {
    value: "(GMT+02:00) Harare, Pretoria",
    label: "(GMT+02:00) Harare, Pretoria ",
  },
  {
    value: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius ",
  },
  { value: "(GMT+02:00) Jerusalem", label: "(GMT+02:00) Jerusalem " },
  { value: "(GMT+02:00) Minsk", label: "(GMT+02:00) Minsk " },
  { value: "(GMT+02:00) Windhoek", label: "(GMT+02:00) Windhoek " },
  {
    value: "(GMT+03:00) Kuwait, Riyadh, Baghdad",
    label: "(GMT+03:00) Kuwait, Riyadh, Baghdad ",
  },
  {
    value: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd ",
  },
  { value: "(GMT+03:00) Nairobi", label: "(GMT+03:00) Nairobi " },
  { value: "(GMT+03:00) Tbilisi", label: "(GMT+03:00) Tbilisi " },
  { value: "(GMT+03:30) Tehran", label: "(GMT+03:30) Tehran " },
  {
    value: "(GMT+04:00) Abu Dhabi, Muscat",
    label: "(GMT+04:00) Abu Dhabi, Muscat ",
  },
  { value: "(GMT+04:00) Baku", label: "(GMT+04:00) Baku " },
  { value: "(GMT+04:00) Yerevan", label: "(GMT+04:00) Yerevan " },
  { value: "(GMT+04:30) Kabul", label: "(GMT+04:30) Kabul " },
  { value: "(GMT+05:00) Yekaterinburg", label: "(GMT+05:00) Yekaterinburg " },
  {
    value: "(GMT+05:00) Islamabad, Karachi, Tashkent",
    label: "(GMT+05:00) Islamabad, Karachi, Tashkent ",
  },
  {
    value: "(GMT+05:30) Sri Jayawardenapura",
    label: "(GMT+05:30) Sri Jayawardenapura ",
  },
  {
    value: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi ",
  },
  { value: "(GMT+05:45) Kathmandu", label: "(GMT+05:45) Kathmandu " },
  {
    value: "(GMT+06:00) Almaty, Novosibirsk",
    label: "(GMT+06:00) Almaty, Novosibirsk ",
  },
  { value: "(GMT+06:00) Astana, Dhaka", label: "(GMT+06:00) Astana, Dhaka " },
  {
    value: "(GMT+06:30) Yangon (Rangoon)",
    label: "(GMT+06:30) Yangon (Rangoon) ",
  },
  {
    value: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
    label: "(GMT+07:00) Bangkok, Hanoi, Jakarta ",
  },
  { value: "(GMT+07:00) Krasnoyarsk", label: "(GMT+07:00) Krasnoyarsk " },
  {
    value: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi ",
  },
  {
    value: "(GMT+08:00) Kuala Lumpur, Singapore",
    label: "(GMT+08:00) Kuala Lumpur, Singapore ",
  },
  {
    value: "(GMT+08:00) Irkutsk, Ulaan Bataar",
    label: "(GMT+08:00) Irkutsk, Ulaan Bataar ",
  },
  { value: "(GMT+08:00) Perth", label: "(GMT+08:00) Perth " },
  { value: "(GMT+08:00) Taipei", label: "(GMT+08:00) Taipei " },
  {
    value: "(GMT+09:00) Osaka, Sapporo, Tokyo",
    label: "(GMT+09:00) Osaka, Sapporo, Tokyo ",
  },
  { value: "(GMT+09:00) Seoul", label: "(GMT+09:00) Seoul " },
  { value: "(GMT+09:00) Yakutsk", label: "(GMT+09:00) Yakutsk " },
  { value: "(GMT+09:30) Adelaide", label: "(GMT+09:30) Adelaide " },
  { value: "(GMT+09:30) Darwin", label: "(GMT+09:30) Darwin " },
  { value: "(GMT+10:00) Brisbane", label: "(GMT+10:00) Brisbane " },
  {
    value: "(GMT+10:00) Canberra, Melbourne, Sydney",
    label: "(GMT+10:00) Canberra, Melbourne, Sydney ",
  },
  { value: "(GMT+10:00) Hobart", label: "(GMT+10:00) Hobart " },
  {
    value: "(GMT+10:00) Guam, Port Moresby",
    label: "(GMT+10:00) Guam, Port Moresby ",
  },
  { value: "(GMT+10:00) Vladivostok", label: "(GMT+10:00) Vladivostok " },
  {
    value: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
    label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia ",
  },
  {
    value: "(GMT+12:00) Auckland, Wellington",
    label: "(GMT+12:00) Auckland, Wellington ",
  },
  {
    value: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
    label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is. ",
  },
  { value: "(GMT+13:00) Nuku'alofa", label: "(GMT+13:00) Nuku'alofa " },
];

const createAnAccountFormSchema = yup.object({
  firstName: yup.string().max(50).required(ValidationMessages.firstName),
  lastName: yup.string().max(50).required(ValidationMessages.lastName),
  email: yup
    .string()
    .email(ValidationMessages.invalidEmailFormat)
    .max(50)
    .required(ValidationMessages.email),
  timezone: yup.string().required(),
  terms: yup.boolean().test({
    name: "terms",
    message: "Please accept the terms!",
    test: (value) => value === true,
  }),
});

const AlreadyHaveAnAccountLinkComponent = () => {
  return (
    <Grid
      item
      md={12}
      lg={12}
      xl={12}
      style={{ position: "relative", top: "1.3rem" }}
    >
      <Typography variant="subtitle2">
        Already have an account?&nbsp;
        <StyledRouterLink to="/login">Sign In</StyledRouterLink>
      </Typography>
    </Grid>
  );
};

const AccountRegisteredSuccessfully = () => {
  return (
    <StandardPanel title={`Welcome to TaxSlips`} testId={`welcome-panel`}>
      <Alert severity="success" sx={{ marginBottom: "5px" }}>
        Congratulations! Your registration is now complete.
      </Alert>
      <Alert severity="warning" sx={{ marginBottom: "10px" }}>
        Your account activation link has been sent to your email address.
      </Alert>
      <Divider />
      <Typography
        variant="subtitle2"
        sx={{ paddingTop: "10px", textAlign: "right" }}
      >
        Already have an account?&nbsp;
        <StyledRouterLink to="/login">Sign In</StyledRouterLink>
      </Typography>
    </StandardPanel>
  );
};
interface IAccountProps {
  successPanel: (value: string) => void;
}

const CreateAnAccountFormComponent: React.FC<IAccountProps> = ({
  successPanel,
}) => {
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [visibleTermsAndConditions, setVisibleTermsAndConditions] =
    useState(false);
  const methods = useForm<any>({
    resolver: yupResolver(createAnAccountFormSchema),
  });

  const { isLoading: isSubmitting, mutate: registerUser } = useMutation(
    AuthService.register,
    {
      onSuccess: (
        res:
          | IRegisterResponse
          | IServerError
          | any
          | IResponse
          | ICreateAnAccountForm
      ) => {
        if (res.message === "User register successfully.") {
          successPanel(
            "Your registration is now complete. Your account activation link has been sent to your email address."
          );
        } else {
          console.log("res", res);
          setSnackbarData({
            open: true,
            message: res?.message ? res.message : "Somethingwent wrong!",
            severity: res.status === false ? "error" : "success",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };
  const onSelectionChange = async (e: any) => {
    alert(e);
  };
  const [checked, setChecked] = useState(false);

  const onSubmit = (data: ICreateAnAccountForm) => registerUser(data);
  console.log(methods.formState.isDirty); // make sure formState is read before render to enable the Proxy

  const footerContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => {
          setVisibleTermsAndConditions(false);
        }}
        size="small"
      />
    </div>
  );
  const [value, setValue] = useState(false)
  const  onSelectionChangeHandler = (event:any) => {
    setValue(event.target.value)
    methods.setValue('terms',event.target.value)
  }
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container md={12} lg={12} xl={12}>
            <Grid item md={12} lg={12} xl={12}>
              <RHookFormTextFieldWithLabel
                label={LabelCaption.firstName}
                id="firstName"
                testId="firstName"
                placeholder={PlaceholderText.firstName}
                required={true}
              />
            </Grid>
            <Spacer y={2} x={2} />
            <Grid item md={12} lg={12} xl={12}>
              <RHookFormTextFieldWithLabel
                label={LabelCaption.lastName}
                id="lastName"
                testId="lastName"
                placeholder={PlaceholderText.lastName}
                required={true}
              />
            </Grid>
            <Spacer y={2} x={2} />
            <Grid item md={12} lg={12} xl={12}>
              <RHookFormTextFieldWithLabel
                label={LabelCaption.email}
                id="email"
                testId="email"
                placeholder={PlaceholderText.email}
                required={true}
              />
            </Grid>
            <Spacer y={2} x={2} />
            <Grid item md={12} lg={12} xl={12}>
              {/* <RHookFormTextFieldWithLabel
              label={LabelCaption.timezone}
              id="timezone"
              testId="timezone"
              placeholder={PlaceholderText.timezone}
              required={true}
            /> */}
              <EnhancedDropDownRHFWithLabel
                selectName="timezone"
                label={LabelCaption.timezone}
                // selectedItem={selectedProvince.id !== null && selectedProvince}
                labelPosition={LabelPosition.Top}
                required
                testId={`time-zone-items-list`}
                items={timeZoneItems}
                selectedItem={"(GMT-05:00) Eastern Time (US & Canada)"}
                // onSelectionChangeHandler={onProvinceSelectionChangeHandler}
              />
            </Grid>
          </Grid>
          <Spacer y={2} x={2} />
          <div className="p-field">
            <Typography
              sx={{
                paddingBottom: "8px",
                cursor: "pointer",
                "&.MuiTypography-root:hover": { color: "#066BB3" },
                color: "#018ef3",
                fontSize: "0.875rem",
              }}
              variant="body1"
              onClick={() => setVisibleTermsAndConditions(true)}
            >
              Terms & Conditions{" "}
              <span style={{ color: "#d50000", fontSize: "1rem" }}>*</span>
            </Typography>
            <InputSwitch
              id="terms"
              checked={value}
              {...methods.register("terms")}
              className={methods.formState.errors.terms ? "p-invalid" : ""}
              onChange={onSelectionChangeHandler}
            />
            {methods.formState.errors.terms && (
              <p
                className="p-error"
                style={{ fontSize: "0.75rem", marginTop: "3px" }}
              >
                {methods.formState.errors.terms.message}
              </p>
            )}
          </div>
          <Spacer y={2} x={2} />
          <SubmitButton
            label="Create an Account"
            variant={SubmitButtonVariant.Primary}
            loading={isSubmitting}
            testId="create-an-account-button"
          />

          {/* Terms and Conditions Dialog BOX */}
          <Dialog
            header="Terms & Conditions"
            footer={footerContent}
            visible={visibleTermsAndConditions}
            style={{ width: "50vw" }}
            onHide={() => setVisibleTermsAndConditions(false)}
          >
            <TermsAndConditions />
          </Dialog>
          <Spacer y={2} x={2} />
          <AlreadyHaveAnAccountLinkComponent />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"register-snackbar"}
        open={snackbarData.open}
      />
    </>
  );
};

export const CreateAnAccountForm = () => {
  const [successResponse, setSuccessResponse] = useState<any>(undefined);
  return (
    <>
      {successResponse ? (
        <Grid container md={12} lg={12} xl={12}>
          <Grid item md={1} lg={1} xl={1}></Grid>
          <Grid item md={10} lg={10} xl={10}>
            <AccountRegisteredSuccessfully />
          </Grid>
          <Grid item md={1} lg={1} xl={1}></Grid>
        </Grid>
      ) : (
        <AccountFormLayout
          title="Create an Account"
          subtitle="Enter your details to create your account"
          form={
            <CreateAnAccountFormComponent
              successPanel={(value: any) => setSuccessResponse(value)}
            />
          }
        />
      )}
    </>
  );
};
