import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { FC } from "react";

import { Grid } from "@mui/material";
import { ITest, RHookFormTextFieldWithLabel } from "@websential/cosmic";

interface IHSFPanel extends ITest {}

export const HSFPanel: FC<IHSFPanel> = ({
  testId,
}) => {
  return (
    <Grid
      container
      columnSpacing="6"
      rowSpacing="2"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="HSF (Minimum)"
          id="qhsfMin"
          testId={`${testId}-hsf-min`}
        />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="HSF (Maximum)"
          id="qhsfMax"
          testId={`${testId}-hsf-maximum`}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="HSF Value (1)"
            id="qhsfValue1"
            testId={`${testId}-hsf-value-1`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="HSF Value (2)"
            id="qhsfValue2"
            testId={`${testId}-gsf-priamry-value-2`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="HSF Rate Min (%)"
            id="qhsfRate1"
            testId={`${testId}-hsf-rate-1`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="HSF Rate Max (%)"
            id="qhsfRate2"
            testId={`${testId}-hsf-rate-2`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="HSF Normalize"
            id="qhsfNormalize"
            testId={`${testId}-hsf-normalize`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="Total Payroll Value"
            id="totalPayrollValue"
            testId={`${testId}-total-payroll`}
          />
        </FieldWrapper>
      </Grid>
    </Grid>
  );
};
