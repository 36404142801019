import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { FilingResourceList } from "components/FilingResource";

export interface IFilingResourceListPage {}

export const FilingResourceListPage: FC<IFilingResourceListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Filing Resources List"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: 'Filing Resources', navigateTo: '/filing-resources/list' },
      ]}
      mainContents={<FilingResourceList />}
    />
  );
};
