import { EnhancedCheckboxRHF, ITest } from "@websential/cosmic";
import { FC } from 'react';

interface Props extends ITest { }

export const ValidationsPanel: FC<Props> = ({ testId }) => {
    return (
        <>
            <EnhancedCheckboxRHF
                checkboxLabel="Is Fixed"
                name="isFixed"
                testId={`${testId}-is-fixed`}
                checked={false}
            />
            <EnhancedCheckboxRHF
                checkboxLabel="Is Category"
                name="isCategory"
                testId={`${testId}-is-category`}
                checked={false}
            />
            <EnhancedCheckboxRHF
                checkboxLabel="Is Required"
                name="isRequired"
                testId={`${testId}-is-required`}
                checked={false}
            />
            <EnhancedCheckboxRHF
                checkboxLabel="Is Hidden"
                name="isHide"
                testId={`${testId}-is-hide`}
                checked={false}
            />
            <EnhancedCheckboxRHF
                checkboxLabel="Is Readonly"
                name="readOnly"
                testId={`${testId}-is-readonly`}
                checked={false}
            />
            <EnhancedCheckboxRHF
                checkboxLabel="Display as Masked"
                name="displayMasked"
                testId={`${testId}-display-masked`}
                checked={false}
            />
            <EnhancedCheckboxRHF
                checkboxLabel="Uppercase Input Field"
                name="uppercase"
                testId={`${testId}-uppercase`}
                checked={false}
            />
        </>
    )
};
