import React, { useState, useRef, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import SearchIcon from '@mui/icons-material/Search';
import "./vendorReportDataTableStyle.css"
import { RequestParams } from '@websential/cosmic';

export interface IDataTable {
    rows: any;
    columns:any;
    onRequestParamsChange?: (params: RequestParams) => void;
    initialPageSize?: number | undefined;
    initialPage?: number;
    totalRecords?: number;
    isLoading?: boolean;
}

const PrimeDataGrid: React.FC<IDataTable> = ({
    rows,
    columns,
    onRequestParamsChange,
    initialPageSize = 10,
    initialPage = 0,
    totalRecords,
    isLoading
}) => {
    const isInitialRequest = useRef(true);
    const [itemSearch, setItemSearch] = useState('');
    const [requestParams, setRequestParams] = useState<any>({
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        sortField: null,
        sortOrder: null,
        searchQuery:"",
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

const onSearchButtonClicked = () => {
    setRequestParams((prevParams:any) => ({
        ...prevParams,
        searchQuery: itemSearch,
        }));
}
const handleClearSearch = () => {
    setItemSearch('')
    setRequestParams((prevParams:any) => ({
        ...prevParams,
        searchQuery: "",
        }));
        }
// Search type and billing profile Button
    const renderHeader = () => {
        return (
            // <div className='flex justify-content-between p-4 pl-0'  style={{backgroundColor:"#ebecf0",margin:"-17px"}}>
            <span className="p-input-icon-left w-5" style={{display:"flex"}}>
                <i className="pi pi-search" />
                <InputText value={itemSearch} onChange={(e) => setItemSearch(e.target.value)} placeholder="Search by Vendor Name" className='w-full' style={{borderRadius:"5px 0px 0px 5px"}}/>
                {itemSearch && (
                    <Button
                    icon="pi pi-times"
                    onClick={handleClearSearch}
                    className="p-button-warning"
                    style={{borderRadius:0}}
                    />
                )}
                <Button
                    onClick={onSearchButtonClicked}
                    // variant="contained"
                    style={{
                    padding:"8px 10px 8px 10px",
                    marginRight:"-14px!important",
                    borderRadius:"0px 5px 5px 0px"}}
                >
                    <SearchIcon />
                </Button>
            </span>
            // </div>
        )
    }
    const headerSearch = renderHeader();

const updatedHeaderColumns = columns && columns.map((item:any) => ({
    ...item,
    headerName:item.headerName === "payee_type" ? "Payee Type" :
    item.headerName === "initials" ? "Initials" :
    item.headerName,
    frozenColumn : item.field === "name1" ? true : item.frozenColumn,
}));


const indexColumn = {
    body: (rowData:any, row:any) => {
        return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
    },
    header: '#',
};

const onPage = (event:any) => {
    setRequestParams(event);
};
// This useEffect is triggered when the debouncedSearchQuery, pageSize or currentPage values change
useEffect(() => {
    // if the results fetcher was passed, we can use it to fetch the results.
    if (onRequestParamsChange) {
      // skip the first initial call
      if (isInitialRequest.current === true) {
        console.log(
          "##### => useEffect - requestParams - no first call",
          requestParams
        );
        isInitialRequest.current = false;
      } else {
        console.log(
          "##### => useEffect - requestParams - second call",
          requestParams
        );
        onRequestParamsChange(requestParams);
      }
    }
  }, [onRequestParamsChange, requestParams]);
    console.log("props headerColumns => ",columns)
    console.log("props items EmployeeReport => ",rows)
    console.log("updatedHeaderColumns",updatedHeaderColumns)
  return (
    <div>
       <DataTable
            value={rows && rows} 
            responsiveLayout="scroll" 
            lazy 
            // filterDisplay="row"
            dataKey="id" 
            paginator
            first={requestParams.first} 
            rows={requestParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            // paginator 
            // rows={25} 
            rowsPerPageOptions={[25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={`Showing {first} to {last} of ${totalRecords} Items`}
            // selection={selectedItems} onSelectionChange={(e:any) => setSelectedItems(e.value)}
            editMode="row" 
            showGridlines
            resizableColumns columnResizeMode="expand"
            scrollable
            reorderableColumns
            filterDisplay="menu"
            loading={isLoading}
            globalFilterFields={['headerName']}
            header={headerSearch}
            emptyMessage="No Record found."
            className='vendorReportDataTableStyle'
            >
                <Column 
                    {...indexColumn} 
                    frozen
                    className='indexColumn'
                    />
                {columns && updatedHeaderColumns && updatedHeaderColumns.map((x:any,index:any) => {                    
                    return (
                    <Column 
                        key={index} 
                        field={x.field} 
                        header={x.headerName} 
                        headerTooltip={x.description}
                        headerTooltipOptions={{ position: 'top' }}
                        // flex (This property is not use Yet)
                        // hidden={val === x.hide ? clickHideBtn(x, x.field, false) : x.field === field ? val: x.hide } 
                        sortable={x.sortable} 
                        resizeable={x.resizable}
                        dataType={x.type}
                        align={x.align}
                        alignHeader={x.headerAlign}
                        // hideSortIcons (This property is not use Yet)
                        // filterElement={HideButton(x.hideable, x, x.field)}
                        // showFilterMenuOptions
                        // filter
                        // showFilterMenu={x.disableColumnMenu}
                        // filterPlaceholder="Search by name"
                        reorderable={x.disableReorder}
                        exportable={x.disableExport}
                        frozen={x.frozenColumn}
                        columnKey={x.field}
                        style={{
                            // minWidth:"250px",
                                fontSize:"0.875rem",
                                // padding:"0px 16px",
                               }}
                        // className="p-column-header-content"
                        footer={x.footer}
                        />
                )})}
            </DataTable>
    </div>
  )
}

export default PrimeDataGrid

//resizeablbe must be false if frozen is true and viseversa