import { FC } from "react";

import Avatar from "@mui/material/Avatar";
import { EnhancedMenu, IEnhancedMenuItem, IHeading, ISeparator, ITest } from "@websential/cosmic";

import { profileMenuItemsArraySuperUser } from "./ProfileMenuItems";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";

const maxChartactersToDisplay = 15;

export interface IProfileMenuProps extends ITest {
  /**
   * Name of the user to be displayed; if length  > 15 then it will be truncated
   */
  userProfileName: string;
  /**
   * Profile picture URL to be displayed; if not provided then default user picture will be displayed
   */
  userProfilePictureURL?: string;
}

export const ProfileMenu: FC<IProfileMenuProps> = ({
  userProfileName,
  userProfilePictureURL,
  testId,
}: IProfileMenuProps) => {
  const clientId = useSelector((state:IAppState) => state.client.id)
  const truncate = (str: string, n: number) => {
    return str && str.length > n ? str.substring(0, n) + "..." : str;
  };

  const profileMenuItemsArray: (IEnhancedMenuItem | ISeparator | IHeading)[] = [
    {
      label: "Profile",
      navigateTo: `/clients/setup/${clientId}`,
    },
    {
      label: "Default Settings",
      navigateTo: '/me/default-settings',
    },
    {
      label: "API",
      navigateTo: '/me/api',
    },
    {
      label: "Users",
      navigateTo: '/invite/user',
    },
    {
      label: "Change Password",
      navigateTo: '/me/change-password',
    },
    {
      label: "Logout",
      navigateTo: '/logout',
    },
  ];

  return (
    <EnhancedMenu
      menuTitleText={truncate(userProfileName, maxChartactersToDisplay)}
      menuTitleBackgroundColor="secondary"
      variant="text"
      testId={testId}
      startIcon={
        <Avatar alt={userProfileName} src={userProfilePictureURL ?? ""} />
      }
      showEndIcon={true}
      menuItems={clientId !== "" ? profileMenuItemsArray : profileMenuItemsArraySuperUser}
    />
  );
};
