import {
  INITIAL_SNACKBAR_DATA,
} from "common/constants";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { ProgressBar } from 'primereact/progressbar';

import { Grid } from "@mui/material";
import { BackdropCircularProgress, EnhancedDialog, EnhancedSnackbar, IResponse, IServerError } from "@websential/cosmic";

import { T4SlipService } from "services/T4SlipService";
import PrimeDataGrid from "./PrimeDataGrid";
import { useNavigate } from "react-router-dom";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { DefaultSettingsService } from "services/DefaultSettingsService";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
const DEFAULT_SETTINGS_DATA_QUERY_KEY = "default-settings-data-record";

const useGetDefaultSettingsData = (
  clientId: string,
) => 
  useQuery(
    [DEFAULT_SETTINGS_DATA_QUERY_KEY, clientId],
    (data) => {
      return DefaultSettingsService.getDefaultSettings(clientId);
    },
    { refetchOnWindowFocus: false }
  );

export interface ICSVInformationUI {}
export const CSVInformationUI: React.FC<ICSVInformationUI> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const interval = useRef<any>(null);
  const clientId = useSelector((state: IAppState) => state.client.id);
  const CSVInformationUI = useSelector((state: IAppState) => state.CSVInformationUI);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const[openDialog, setDialogOpen] = useState(false)
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [CSVInformationUIsLists, setCSVInformationUIsLists] = useState<any>(undefined);
  const [onDataSubmit, setOnDataSubmit] = useState<any>(undefined);
  const [percentageValue, setPercentageValue] = useState(0);
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const { isLoading: isLoadingDefaultSettingsData, data: defaultSettingsData } = useGetDefaultSettingsData(clientId);

  useEffect(()=>{
    setIsLoadingList(true)
    if(CSVInformationUI){
      setCSVInformationUIsLists(CSVInformationUI.data)
    }
    setIsLoadingList(false)
  },[CSVInformationUI])

  const redirectToPage = (tag:string) => {
    if(tag === 'T4'){
      let path = "/taxslip/t4/list"
      navigate(path)
    } else if(tag === 'T4A'){
      let path = "/taxslip/t4a/list"
      navigate(path)
    } else if(tag === 'RL1'){
      let path = "/taxslip/rl1/list"
      navigate(path)
    } else if(tag === 'Employee'){
      let path = "/resources/Employee/list"
      navigate(path)
    } else if(tag === 'T5'){
      let path = "/taxslip/t5/list"
      navigate(path)
    } else if(tag === 'RL3'){
      let path = "/taxslip/rl3/list"
      navigate(path)
    } else if(tag === 'T3'){
      let path = "/taxslip/t3/list"
      navigate(path)
    } else if(tag === 'RL16'){
      let path = "/taxslip/rl16/list"
      navigate(path)
    }
  }

  const { isLoading: isSubmittingT4Data, mutate: submitT4Data } = useMutation(
    T4SlipService.submitTemporaryData,
    {
      onSuccess: (res: any | IServerError | IResponse) => {
        console.log("responceeee submitTemporaryData",res)
        if(res.message === `${res.totalFetched} Records found and fetching started`){
          (async () => {
            try {
              setSnackbarData({
                open: true,
                message: res.message,
                severity: "success",
              });
              setDialogOpen(true)
              setPercentageValue(10)
              const response: any = await T4SlipService.submitT4Data(onDataSubmit,res.id);
              console.log("responseData-----------------", response);
              //manage progress bar
              if(response.status === 201){
                setPercentageValue(100)
                setTimeout(()=>{
                  setDialogOpen(false)
                  redirectToPage(CSVInformationUI.tag);
                },1000)
              } else {
                setDialogOpen(false)
                setSnackbarData({
                  open: true,
                  message: response.message ? response.message : "Something went wrong.",
                  severity: "error",
                });
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          })();
        }else {
          setSnackbarData({
            open: true,
            message: res.message ? res.message : "Something went wrong.",
            severity: "error",
          });
        }
      },
      onError: (err:any) => {
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
      },
    },
  );
  const { isLoading: isSubmittingTemporaryData, mutate: submitTemporaryData } = useMutation(
    T4SlipService.submitTemporaryData,
    {
      onSuccess: (res: any | IServerError | IResponse) => {
        console.log("responceeee submitTemporaryData",res)
        if(res.message === `${res.totalFetched} Records found and fetching started`){
          setDialogOpen(true)
          const SubmitActualDataApi = async () => {
            try {
              const response: any = await T4SlipService.submitT4Data(onDataSubmit,res.id);
              console.log("responseData-----------------", response);
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          SubmitActualDataApi();
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "success",
          });

          //manage progress bar
          let _val = percentageValue;
          const fetchData = async () => {
            try {
              const response: any = await T4SlipService.temporaryItemsCount(res.id);
              console.log("12345789", response);
              console.log("12345789ff", response.fail);
              console.log("12345789tt", res.totalFetched);
              _val = Math.floor((response.counter / res.totalFetched) * 100); 
              console.log("_val_val",_val)
              setPercentageValue(_val);
          
              if (response.counter >= res.totalFetched) {
                clearInterval(interval.current);
                setPercentageValue(100)
                
                let totalSuccessfullImportedItems = response.counter - response.fail
                setSnackbarData({
                  open: true,
                  message: `${totalSuccessfullImportedItems} items imported and ${response.fail} items have incorrect data.`,
                  severity: "success",
                });
                // Set up the interval
                interval.current = setInterval(() => {
                  setDialogOpen(false)
                  redirectToPage(CSVInformationUI.tag);
                  clearInterval(interval.current);
                }, 3000);
              }
          
          
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          
          // Set up the interval
          interval.current = setInterval(() => {
            fetchData();
          }, 10000);
        } else {
          setSnackbarData({
            open: true,
            message: `${res.message ? res.message : 'importing Failed.'}`,
            severity: "error",
          });
        }
      },
      onError: (err:any) => {
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
      },
    },
  );

  useEffect(()=>{
    // console.log("length of items",onDataSubmit.items.length)
    console.log("onDataSubmit",onDataSubmit)
    if(onDataSubmit){
      if(onDataSubmit && onDataSubmit.items && onDataSubmit.items.length > 50){
        submitTemporaryData(onDataSubmit)
      } else {
        submitT4Data(onDataSubmit)
      }
    }
  },[onDataSubmit])

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:'',
      companyName:'',
      activityType:"",
      activityLogVisibility:false
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[])

  console.log("onDataSubmitonDataSubmit",onDataSubmit)

  console.log("t4InformationList CSVInformationUI Response Data",CSVInformationUI.data)

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ width: "100%", overflow: "hidden" }}
    >
      <BackdropCircularProgress 
        open={
          isSubmittingTemporaryData ||
          isSubmittingT4Data ||
          isLoadingDefaultSettingsData ||
          isLoadingList
          } testId="CSVInformationUI" />
      <PrimeDataGrid
        rows={CSVInformationUIsLists && CSVInformationUIsLists?.items}
        columns={CSVInformationUIsLists ? CSVInformationUIsLists?.headerColumns : []}
        showIndexColumn={true}
        onSnackbarMessageData={(value: any) => setSnackbarData(value)}
        onDataSubmit={(value: any) => setOnDataSubmit(value)}
        isLoading={false}
        defaultSettingsData={defaultSettingsData  && !defaultSettingsData.message && defaultSettingsData}
      />

      {/* Edit Dialog */}
        <EnhancedDialog
            dialogTitle={`${CSVInformationUI.tag} Csv Items Importing`}
            key="dialog-box"
            testId="dialog"
            onCloseHandler={onCloseHandler}
            // showCloseButton={false}
            open={openDialog}
            dialogContent={
              <ProgressBar 
                value={percentageValue}
                pt={{
                    value: { style: { background: 'linear-gradient(to right, #8e2de2, #4a00e0)' } }
                }}
              ></ProgressBar>
            }
            dialogAction={
              <></>
            }
          />


       <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`CSVInformationUI-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
