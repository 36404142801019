import { useMemo } from "react";

export const T4OtherInformationService = {
  getT4OI: () => {
    return [
      {
        label: "N/A",
        value: "",
      },
      {
        value: "30",
        label: "30 - Board and lodging",
        amount: 1
        },
        {
        value: "31",
        label: "31 - Special work site",
        amount: 2
        },
        {
        value: "32",
        label: "32 - Travel in a prescribed zone",
        amount: 3
        },
        {
        value: "33",
        label: "33 - Medical travel assistance",
        amount: 4
        },
        {
        value: "34",
        label: "34 - Personal use of employer's automobile or motor vehicle",
        amount: 5
        },
        {
        value: "35",
        label: "35 - Total Reasonable Per-Kilometre Allowance amount",
        amount: 6
        },
        {
        value: "36",
        label: "36 - Interest-free and low-interest loans",
        amount: 6
        },
        {
        value: "37",
        label: "37 - Employee home-relocation loan deduction",
        amount: 7
        },
        {
        value: "38",
        label: "38 - Security options benefits",
        amount: 9
        },
        {
        value: "39",
        label: "39 - Security options deduction - 110(1)(d)",
        amount: 11
        },
        {
        value: "40",
        label: "40 - Other taxable allowances and benefits",
        amount: 12
        },
        {
        value: "41",
        label: "41 - Security options deduction - 110(1)(d.1)",
        amount: 13
        },
        {
        value: "42",
        label: "42 - Employment commissions",
        amount: 14
        },
        {
        value: "43",
        label: "43 - Canadian Forces personnel and police deduction",
        amount: 15
        },
        {
        value: "53",
        label: "53 - Deferred security option benefits",
        amount: 16
        },
        {
        value: "57",
        label: "57 - Employment income - March 15 to May 9",
        amount: 16.5
        },
        {
        value: "58",
        label: "58 - Employment income - May 10 to July 4",
        amount: 17
        },
        {
        value: "59",
        label: "59 - Employment income - July 5 to August 29",
        amount: 18
        },
        {
        value: "60",
        label: "60 - Employment income - August 30 to September 26",
        amount: 19
        },
        {
        value: "66",
        label: "66 - Eligible retiring allowances",
        amount: 20
        },
        {
        value: "67",
        label: "67 - Non-eligible retiring allowances",
        amount: 21
        },
        {
        value: "69",
        label: "69 - Indian (exempt income) - Non-eligible retiring allowances",
        amount: 22
        },
        {
        value: "71",
        label: "71 - Indian (exempt income) - Employment",
        amount: 23
        },
        {
        value: "72",
        label: "72 - Section 122.3 income - Employment outside Canada",
        amount: 24
        },
        {
        value: "73",
        label: "73 - Number of days outside Canada",
        amount: 25
        },
        {
        value: "74",
        label: "74 - Past service contributions for 1989 or earlier years while a contributor",
        amount: 26
        },
        {
        value: "75",
        label: "75 - Past service contributions for 1989 or earlier years while not a contributor",
        amount: 27
        },
        {
        value: "77",
        label: "77 - Workers' compensation benefits repaid to the employer",
        amount: 28
        },
        {
        value: "78",
        label: "78 - Fishers - Gross income",
        amount: 29
        },
        {
        value: "79",
        label: "79 - Fishers - Net partnership amount",
        amount: 30
        },
        {
        value: "80",
        label: "80 - Fishers - Shareperson amount",
        amount: 31
        },
        {
        value: "81",
        label: "81 - Placement or employment agency workers - Gross income",
        amount: 32
        },
        {
        value: "82",
        label: "82 - Taxi drivers and drivers of other passenger-carrying vehicles - Gross income",
        amount: 33
        },
        {
        value: "83",
        label: "83 - Barbers or hairdressers - Gross income",
        amount: 34
        },
        {
        value: "84",
        label: "84 - Public transit pass",
        amount: 35
        },
        {
        value: "85",
        label: "85 - Employee-paid premiums for private health services plans",
        amount: 36
        },
        {
        value: "86",
        label: "86 - Security options election",
        amount: 37
        },
        {
        value: "87",
        label: "87 - Emergency services volunteer exempt amount",
        amount: 38
        },
        {
        value: "88",
        label: "88 - Indian (exempt income) - self-employment",
        amount: 39
        },
        {
        value: "97",
        label: "97 - Stock option benefit amount before February 28, 2000",
        amount: 8
        },
        {
        value: "98",
        label: "98 - Stock option and share deduction 110(1) (d) amount before February 28, 2000",
        amount: 10
        },
        {
        value: "99",
        label: "99 - Stock option and share deduction 110(1) (d.1) amount before February 28, 2000",
        amount: 12
        },
    ];
  },
  getT3OI: () => {
    return [
      {
        label: "N/A",
        value: "",
      },
      {
        value: "22",
        label: "22 - Lump-sum pension income",
        amount: 1
        },
        {
        value: "24",
        label: "24 - Foreign business income",
        amount: 2
        },
        {
        value: "25",
        label: "25 - Foreign non-business income",
        amount: 3
        },
        {
        value: "31",
        label: "31 - Qualifying pension income",
        amount: 4
        },
        {
        value: "33",
        label: "33 - Foreign business income tax paid",
        amount: 5
        },
        {
        value: "34",
        label: "34 - Foreign non-business income tax paid",
        amount: 6
        },
        {
        value: "35",
        label: "35 - Eligible death benefits",
        amount: 6
        },
        {
        value: "37",
        label: "37 - Insurance segregated fund net capital losses",
        amount: 7
        },
        {
        value: "38",
        label: "38 - Part XIII.2 tax credit",
        amount: 9
        },
        {
        value: "40",
        label: "40 - Investment cost or expenditures",
        amount: 11
        },
        {
        value: "41",
        label: "41 - Investment tax credit",
        amount: 12
        },
        {
        value: "42",
        label: "42 - Amount resulting in cost base adjustment",
        amount: 13
        },
        {
        value: "45",
        label: "45 - Other credits",
        amount: 14
        },
        {
        value: "46",
        label: "46 - Pension income qualifying for an eligible annuity for a minor",
        amount: 15
        },
        {
        value: "47",
        label: "47 - Retiring allowance qualifying for transfer to an RPP or RRSP",
        amount: 16
        },
        {
        value: "48",
        label: "48 - Eligible amount of charitable donations",
        amount: 17
        }
    ];
  },
  getT4OIForDefaultSettings: () => {
    return [
      {
        label: "N/A",
        value: "",
      },
      {
        value: `{code: "30", label: "30 - Board and lodging", amount: 0}`,
        label: "30 - Board and lodging",
        amount: 1
        },
        {
        value: `{code: "31", label: "31 - Special work site", amount: 0}`,
        label: "31 - Special work site",
        amount: 2
        },
        {
        value: `{code: "32", label: "32 - Travel in a prescribed zone", amount: 0}`,
        label: "32 - Travel in a prescribed zone",
        amount: 3
        },
        {
        value: `{code: "33", label: "33 - Medical travel assistance", amount: 0}`,
        label: "33 - Medical travel assistance",
        amount: 4
        },
        {
        value: `{code: "34", label: "34 - Personal use of employer's automobile or motor vehicle", amount: 0}`,
        label: "34 - Personal use of employer's automobile or motor vehicle",
        amount: 5
        },
        {
        value: `{code: "35", label: "35 - Total Reasonable Per-Kilometre Allowance amount", amount: 0}`,
        label: "35 - Total Reasonable Per-Kilometre Allowance amount",
        amount: 6
        },
        {
        value: `{code: "36", label: "36 - Interest-free and low-interest loans", amount: 0}`,
        label: "36 - Interest-free and low-interest loans",
        amount: 6
        },
        {
        value: `{code: "37", label: "37 - Employee home-relocation loan deduction", amount: 0}`,
        label: "37 - Employee home-relocation loan deduction",
        amount: 7
        },
        {
        value: `{code: "38", label: "38 - Security options benefits", amount: 0}`,
        label: "38 - Security options benefits",
        amount: 9
        },
        {
        value: `{code: "39", label: "39 - Security options deduction - 110(1)(d)", amount: 0}`,
        label: "39 - Security options deduction - 110(1)(d)",
        amount: 11
        },
        {
        value: `{code: "40", label: "40 - Other taxable allowances and benefits", amount: 0}`,
        label: "40 - Other taxable allowances and benefits",
        amount: 12
        },
        {
        value: `{code: "41", label: "41 - Security options deduction - 110(1)(d.1)", amount: 0}`,
        label: "41 - Security options deduction - 110(1)(d.1)",
        amount: 13
        },
        {
        value: `{code: "42", label: "42 - Employment commissions", amount: 0}`,
        label: "42 - Employment commissions",
        amount: 14
        },
        {
        value: `{code: "43", label: "43 - Canadian Forces personnel and police deduction", amount: 0}`,
        label: "43 - Canadian Forces personnel and police deduction",
        amount: 15
        },
        {
        value: `{code: "53", label: "53 - Deferred security option benefits", amount: 0}`,
        label: "53 - Deferred security option benefits",
        amount: 16
        },
        {
        value: `{code: "57", label: "57 - Employment income - March 15 to May 9", amount: 0}`,
        label: "57 - Employment income - March 15 to May 9",
        amount: 16.5
        },
        {
        value: `{code: "57", label: "58 - Employment income - May 10 to July 4", amount: 0}`,
        label: "58 - Employment income - May 10 to July 4",
        amount: 17
        },
        {
        value: `{code: "59", label: "59 - Employment income - July 5 to August 29", amount: 0}`,
        label: "59 - Employment income - July 5 to August 29",
        amount: 18
        },
        {
        value: `{code: "60", label: "60 - Employment income - August 30 to September 26", amount: 0}`,
        label: "60 - Employment income - August 30 to September 26",
        amount: 19
        },
        {
        value: `{code: "66", label: "66 - Eligible retiring allowances", amount: 0}`,
        label: "66 - Eligible retiring allowances",
        amount: 20
        },
        {
        value: `{code: "67", label: "67 - Non-eligible retiring allowances", amount: 0}`,
        label: "67 - Non-eligible retiring allowances",
        amount: 21
        },
        {
        value: `{code: "69", label: "69 - Indian (exempt income) - Non-eligible retiring allowances", amount: 0}`,
        label: "69 - Indian (exempt income) - Non-eligible retiring allowances",
        amount: 22
        },
        {
        value: `{code: "71", label: "71 - Indian (exempt income) - Employment", amount: 0}`,
        label: "71 - Indian (exempt income) - Employment",
        amount: 23
        },
        {
        value: `{code: "72", label: "72 - Section 122.3 income - Employment outside Canada", amount: 0}`,
        label: "72 - Section 122.3 income - Employment outside Canada",
        amount: 24
        },
        {
        value: `{code: "73", label: "73 - Number of days outside Canada", amount: 0}`,
        label: "73 - Number of days outside Canada",
        amount: 25
        },
        {
        value: `{code: "74", label: "74 - Past service contributions for 1989 or earlier years while a contributor", amount: 0}`,
        label: "74 - Past service contributions for 1989 or earlier years while a contributor",
        amount: 26
        },
        {
        value: `{code: "75", label: "75 - Past service contributions for 1989 or earlier years while not a contributor", amount: 0}`,
        label: "75 - Past service contributions for 1989 or earlier years while not a contributor",
        amount: 27
        },
        {
        value: `{code: "77", label: "77 - Workers' compensation benefits repaid to the employer", amount: 0}`,
        label: "77 - Workers' compensation benefits repaid to the employer",
        amount: 28
        },
        {
        value: `{code: "78", label: "78 - Fishers - Gross income", amount: 0}`,
        label: "78 - Fishers - Gross income",
        amount: 29
        },
        {
        value: `{code: "79", label: "79 - Fishers - Net partnership amount", amount: 0}`,
        label: "79 - Fishers - Net partnership amount",
        amount: 30
        },
        {
        value: `{code: "80", label: "80 - Fishers - Shareperson amountg", amount: 0}`,
        label: "80 - Fishers - Shareperson amount",
        amount: 31
        },
        {
        value: `{code: "81", label: "81 - Placement or employment agency workers - Gross income", amount: 0}`,
        label: "81 - Placement or employment agency workers - Gross income",
        amount: 32
        },
        {
        value: `{code: "82", label: "82 - Taxi drivers and drivers of other passenger-carrying vehicles - Gross income", amount: 0}`,
        label: "82 - Taxi drivers and drivers of other passenger-carrying vehicles - Gross income",
        amount: 33
        },
        {
        value: `{code: "83", label: "83 - Barbers or hairdressers - Gross income", amount: 0}`,
        label: "83 - Barbers or hairdressers - Gross income",
        amount: 34
        },
        {
        value: `{code: "84", label: "84 - Public transit pass", amount: 0}`,
        label: "84 - Public transit pass",
        amount: 35
        },
        {
        value: `{code: "85", label: "85 - Employee-paid premiums for private health services plans", amount: 0}`,
        label: "85 - Employee-paid premiums for private health services plans",
        amount: 36
        },
        {
        value: `{code: "86", label: "86 - Security options electio", amount: 0}`,
        label: "86 - Security options election",
        amount: 37
        },
        {
        value: `{code: "87", label: "87 - Emergency services volunteer exempt amount", amount: 0}`,
        label: "87 - Emergency services volunteer exempt amount",
        amount: 38
        },
        {
        value: `{code: "88", label: "88 - Indian (exempt income) - self-employment", amount: 0}`,
        label: "88 - Indian (exempt income) - self-employment",
        amount: 39
        },
        {
        value: `{code: "97", label: "97 - Stock option benefit amount before February 28, 2000", amount: 0}`,
        label: "97 - Stock option benefit amount before February 28, 2000",
        amount: 8
        },
        {
        value: `{code: "98", label: "98 - Stock option and share deduction 110(1) (d) amount before February 28, 2000", amount: 0}`,
        label: "98 - Stock option and share deduction 110(1) (d) amount before February 28, 2000",
        amount: 10
        },
        {
        value: `{code: "99", label: "99 - Stock option and share deduction 110(1) (d.1) amount before February 28, 2000", amount: 0}`,
        label: "99 - Stock option and share deduction 110(1) (d.1) amount before February 28, 2000",
        amount: 12
        },
    ];
  }
}

export const T4AOtherInformationService = {

  getT4AOI: () => {
    return [
      {
        label: "N/A",
        value: "",
      },
      {
        value: "014",
        label: "014 - Recipient's number",
        amount: 1
        },
        {
        value: "026",
        label: "026 - Eligible retiring allowances (for 2009 and prior years only)",
        amount: 2
        },
        {
        value: "027",
        label: "027 - Non-eligible retiring allowances (for 2009 and prior years only)",
        amount: 3
        },
        {
        value: "028",
        label: "028 - Other income",
        amount: 4
        },
        {
        value: "030",
        label: "030 - Patronage allocations",
        amount: 5
        },
        {
        value: "032",
        label: "032 - Registered pension plan contributions (past service)",
        amount: 6
        },
        {
        value: "034",
        label: "034 - Pension adjustment",
        amount: 7
        },
        {
        value: "036",
        label: "036 - Plan registration number",
        amount: 8
        },
        {
        value: "037",
        label: "037 - Advanced life deferred annuity purchase",
        amount: 9
        },
        {
        value: "040",
        label: "040 - RESP accumulated income payments",
        amount: 10
        },
        {
        value: "042",
        label: "042 - RESP educational assistance payments",
        amount: 11
        },
        {
        value: "046",
        label: "046 - Charitable donations",
        amount: 12
        },
        {
        value: "102",
        label: "102 - Lump-sum payments - non-resident services transferred under paragraph 60(j) (included in box 018)",
        amount: 13
        },
        {
        value: "104",
        label: "104 - Research grants",
        amount: 14
        },
        {
        value: "105",
        label: "105 - Scholarships, bursaries, fellowships, artists' project grants, and prizes",
        amount: 15
        },
        {
        value: "106",
        label: "106 - Death benefits",
        amount: 16
        },
        {
        value: "107",
        label: "107 - Payments from a wage-loss replacement plan",
        amount: 17
        },
        {
        value: "108",
        label: "108 - Lump-sum payments from a registered pension plan (RPP) that you cannot transfer (included in box 018)",
        amount: 18
        },
        {
        value: "109",
        label: "109 - Periodic payments from an unregistered plan",
        amount: 19
        },
        {
        value: "110",
        label: "110 - Lump-sum payments accrued to December 31, 1971 (included in box 018)",
        amount: 20
        },
        {
        value: "111",
        label: "111 - Income averaging annuity contracts (IAAC) (included in box 024)",
        amount: 21
        },
        {
        value: "115",
        label: "115 - Deferred profit sharing plan (DPSP) annuity or instalment payments (included in box 024)",
        amount: 22
        },
        {
        value: "116",
        label: "116 - Medical travel assistance",
        amount: 23
        },
        {
        value: "117",
        label: "117 - Loan benefits",
        amount: 24
        },
        {
        value: "118",
        label: "118 - Medical premium benefits",
        amount: 25
        },
        {
        value: "119",
        label: "119 - Premiums paid to a group term life insurance plan",
        amount: 26
        },
        {
        value: "122",
        label: "122 - RESP accumulated income payments paid to other (included in box 040)",
        amount: 27
        },
        {
        value: "123",
        label: "123 - Payments from a revoked DPSP",
        amount: 28
        },
        {
        value: "124",
        label: "124 - Board and lodging at special work sites",
        amount: 29
        },
        {
        value: "125",
        label: "125 - Disability benefits paid out of a superannuation or pension plan",
        amount: 30
        },
        {
        value: "126",
        label: "126 - Pre-1990 RPP past service contributions while a contributor (included in box 032)",
        amount: 31
        },
        {
        value: "127",
        label: "127 - Veterans' benefits",
        amount: 32
        },
        {
        value: "129",
        label: "129 - Tax deferred cooperative share",
        amount: 33
        },
        {
        value: "130",
        label: "130 - Apprenticeship incentive grant or Apprenticeship completion grant",
        amount: 34
        },
        {
        value: "131",
        label: "131 - Registered disability savings plan (RDSP)",
        amount: 35
        },
        {
        value: "132",
        label: "132 - Wage Earner Protection Program (WEPP)",
        amount: 36
        },
        {
        value: "133",
        label: "133 - Variable pension benefits",
        amount: 37
        },
        {
        value: "134",
        label: "134 - Tax-Free Savings Account (TFSA) taxable amount",
        amount: 38
        },
        {
        value: "135",
        label: "135 - Recipient-paid premiums for private health services plans",
        amount: 39
        },
        {
        value: "136",
        label: "136 - Parents of Murdered or Missing Children/Parents of Young Victims of Crimes(PYVC)",
        amount: 40
        },
        {
        value: "142",
        label: "142 - Indian (exempt income) - Eligible retiring allowances (for 2009 and prior years only)",
        amount: 41
        },
        {
        value: "143",
        label: "143 - Indian (exempt income) - Non-eligible retiring allowances (for 2009 and prior years only)",
        amount: 42
        },
        {
        value: "144",
        label: "144 - Indian (exempt income) - Other income",
        amount: 43
        },
        {
        value: "146",
        label: "146 - Indian (exempt income) - Pension or superannuation",
        amount: 44
        },
        {
        value: "148",
        label: "148 - Indian (exempt income) - Lump-sum payments",
        amount: 45
        },
        {
        value: "150",
        label: "150 - Labour Adjustment Benefits Act and Appropriation Acts",
        amount: 46
        },
        {
        value: "152",
        label: "152 - SUBP qualified under the Income Tax Act",
        amount: 47
        },
        {
        value: "154",
        label: "154 - Cash award or prize from payer",
        amount: 48
        },
        {
        value: "156",
        label: "156 - Bankruptcy settlement",
        amount: 19
        },
        {
        value: "158",
        label: "158 - Lump-sum payments that you cannot transfer, that are not reported elsewhere (included in box 018)",
        amount: 50
        },
        {
        value: "162",
        label: "162 - Code 162 &amp;ndash; Pre 1990 RPP past service contributions while not a contributor",
        amount: 51
       },       {
        value: "180",
        label: "180 - Lump-sum payments from a deferred profit sharing plan (DPSP) that you cannot transfer (included in box 018)",
        amount: 52
        },
        {
        value: "190",
        label: "190 - Lump-sum payments from an unregistered plan (included in box 018)",
        amount: 53
        },
        {
        value: "194",
        label: "194 - Pooled Registered Pension Plans (PRPP) annuity payments from taxable income",
        amount: 54
        },
        {
        value: "195",
        label: "195 - Indian (exempt income) &amp;ndash; PRPP payments",
        amount: 55
        },
        {
        value: "200",
        label: "200 - Provincial/Territorial  COVID-19 financial assistance payments",
        amount: 56
        }
    ];
  },
  getT4AOIForDefaultSettings: () => {
    return [
      {
        label: "N/A",
        value: "",
      },
      {
        value: `{code: "014", label: "014 - Recipient's number", amount: 0}`,
        label: "014 - Recipient's number",
        amount: 1
        },
        {
        value: `{code: "026", label: "026 - Eligible retiring allowances (for 2009 and prior years only)", amount: 0}`,
        label: "026 - Eligible retiring allowances (for 2009 and prior years only)",
        amount: 2
        },
        {
        value: `{code: "027", label: "027 - Non-eligible retiring allowances (for 2009 and prior years only)", amount: 0}`,
        label: "027 - Non-eligible retiring allowances (for 2009 and prior years only)",
        amount: 3
        },
        {
        value: `{code: "028", label: "028 - Other income", amount: 0}`,
        label: "028 - Other income",
        amount: 4
        },
        {
        value: `{code: "030", label: "030 - Patronage allocations", amount: 0}`,
        label: "030 - Patronage allocations",
        amount: 5
        },
        {
        value: `{code: "032", label: "032 - Registered pension plan contributions (past service)", amount: 0}`,
        label: "032 - Registered pension plan contributions (past service)",
        amount: 6
        },
        {
        value: `{code: "034", label: "034 - Pension adjustment", amount: 0}`,
        label: "034 - Pension adjustment",
        amount: 7
        },
        {
        value: `{code: "036", label: "036 - Plan registration number", amount: 0}`,
        label: "036 - Plan registration number",
        amount: 8
        },
        {
        value: `{code: "037", label: "037 - Advanced life deferred annuity purchase", amount: 0}`,
        label: "037 - Advanced life deferred annuity purchase",
        amount: 9
        },
        {
        value: `{code: "040", label: "040 - RESP accumulated income payments", amount: 0}`,
        label: "040 - RESP accumulated income payments",
        amount: 10
        },
        {
        value: `{code: "042", label: "042 - RESP educational assistance payments", amount: 0}`,
        label: "042 - RESP educational assistance payments",
        amount: 11
        },
        {
        value: `{code: "046", label: "046 - Charitable donations", amount: 0}`,
        label: "046 - Charitable donations",
        amount: 12
        },
        {
        value: `{code: "102", label: "102 - Lump-sum payments - non-resident services transferred under paragraph 60(j) (included in box 018)", amount: 0}`,
        label: "102 - Lump-sum payments - non-resident services transferred under paragraph 60(j) (included in box 018)",
        amount: 13
        },
        {
        value: `{code: "104", label: "104 - Research grants", amount: 0}`,
        label: "104 - Research grants",
        amount: 14
        },
        {
        value: `{code: "105", label: "105 - Scholarships, bursaries, fellowships, artists' project grants, and prizes", amount: 0}`,
        label: "105 - Scholarships, bursaries, fellowships, artists' project grants, and prizes",
        amount: 15
        },
        {
        value: `{code: "106", label: "106 - Death benefits", amount: 0}`,
        label: "106 - Death benefits",
        amount: 16
        },
        {
        value: `{code: "107", label: "107 - Payments from a wage-loss replacement plan", amount: 0}`,
        label: "107 - Payments from a wage-loss replacement plan",
        amount: 17
        },
        {
        value: `{code: "108", label: "108 - Lump-sum payments from a registered pension plan (RPP) that you cannot transfer (included in box 018)", amount: 0}`,
        label: "108 - Lump-sum payments from a registered pension plan (RPP) that you cannot transfer (included in box 018)",
        amount: 18
        },
        {
        value: `{code: "109", label: "109 - Periodic payments from an unregistered plan", amount: 0}`,
        label: "109 - Periodic payments from an unregistered plan",
        amount: 19
        },
        {
        value: `{code: "110", label: "110 - Lump-sum payments accrued to December 31, 1971 (included in box 018)", amount: 0}`,
        label: "110 - Lump-sum payments accrued to December 31, 1971 (included in box 018)",
        amount: 20
        },
        {
        value: `{code: "111", label: "111 - Income averaging annuity contracts (IAAC) (included in box 024)", amount: 0}`,
        label: "111 - Income averaging annuity contracts (IAAC) (included in box 024)",
        amount: 21
        },
        {
        value: `{code: "115", label: "115 - Deferred profit sharing plan (DPSP) annuity or instalment payments (included in box 024)", amount: 0}`,
        label: "115 - Deferred profit sharing plan (DPSP) annuity or instalment payments (included in box 024)",
        amount: 22
        },
        {
        value: `{code: "116", label: "116 - Medical travel assistance", amount: 0}`,
        label: "116 - Medical travel assistance",
        amount: 23
        },
        {
        value: `{code: "117", label: "117 - Loan benefits", amount: 0}`,
        label: "117 - Loan benefits",
        amount: 24
        },
        {
        value: `{code: "118", label: "118 - Medical premium benefits", amount: 0}`,
        label: "118 - Medical premium benefits",
        amount: 25
        },
        {
        value: `{code: "119", label: "119 - Premiums paid to a group term life insurance plan", amount: 0}`,
        label: "119 - Premiums paid to a group term life insurance plan",
        amount: 26
        },
        {
        value: `{code: "122", label: "122 - RESP accumulated income payments paid to other (included in box 040)", amount: 0}`,
        label: "122 - RESP accumulated income payments paid to other (included in box 040)",
        amount: 27
        },
        {
        value: `{code: "123", label: "123 - Payments from a revoked DPSP", amount: 0}`,
        label: "123 - Payments from a revoked DPSP",
        amount: 28
        },
        {
        value: `{code: "124", label: "124 - Board and lodging at special work sites", amount: 0}`,
        label: "124 - Board and lodging at special work sites",
        amount: 29
        },
        {
        value: `{code: "125", label: "125 - Disability benefits paid out of a superannuation or pension plan", amount: 0}`,
        label: "125 - Disability benefits paid out of a superannuation or pension plan",
        amount: 30
        },
        {
        value: `{code: "126", label: "126 - Pre-1990 RPP past service contributions while a contributor (included in box 032)", amount: 0}`,
        label: "126 - Pre-1990 RPP past service contributions while a contributor (included in box 032)",
        amount: 31
        },
        {
        value: `{code: "127", label: "127 - Veterans' benefits", amount: 0}`,
        label: "127 - Veterans' benefits",
        amount: 32
        },
        {
        value: `{code: "129", label: "129 - Tax deferred cooperative share", amount: 0}`,
        label: "129 - Tax deferred cooperative share",
        amount: 33
        },
        {
        value: `{code: "130", label: "130 - Apprenticeship incentive grant or Apprenticeship completion grant", amount: 0}`,
        label: "130 - Apprenticeship incentive grant or Apprenticeship completion grant",
        amount: 34
        },
        {
        value: `{code: "131", label: "131 - Registered disability savings plan (RDSP)", amount: 0}`,
        label: "131 - Registered disability savings plan (RDSP)",
        amount: 35
        },
        {
        value: `{code: "132", label: "132 - Wage Earner Protection Program (WEPP)", amount: 0}`,
        label: "132 - Wage Earner Protection Program (WEPP)",
        amount: 36
        },
        {
        value: `{code: "133", label: "133 - Variable pension benefits", amount: 0}`,
        label: "133 - Variable pension benefits",
        amount: 37
        },
        {
        value: `{code: "134", label: "134 - Tax-Free Savings Account (TFSA) taxable amount", amount: 0}`,
        label: "134 - Tax-Free Savings Account (TFSA) taxable amount",
        amount: 38
        },
        {
        value: `{code: "135", label: "135 - Recipient-paid premiums for private health services plans", amount: 0}`,
        label: "135 - Recipient-paid premiums for private health services plans",
        amount: 39
        },
        {
        value: `{code: "136", label: "136 - Parents of Murdered or Missing Children/Parents of Young Victims of Crimes(PYVC)", amount: 0}`,
        label: "136 - Parents of Murdered or Missing Children/Parents of Young Victims of Crimes(PYVC)",
        amount: 40
        },
        {
        value: `{code: "142", label: "142 - Indian (exempt income) - Eligible retiring allowances (for 2009 and prior years only)", amount: 0}`,
        label: "142 - Indian (exempt income) - Eligible retiring allowances (for 2009 and prior years only)",
        amount: 41
        },
        {
        value: `{code: "143", label: "143 - Indian (exempt income) - Non-eligible retiring allowances (for 2009 and prior years only)", amount: 0}`,
        label: "143 - Indian (exempt income) - Non-eligible retiring allowances (for 2009 and prior years only)",
        amount: 42
        },
        {
        value: `{code: "144", label: "144 - Indian (exempt income) - Other income", amount: 0}`,
        label: "144 - Indian (exempt income) - Other income",
        amount: 43
        },
        {
        value: `{code: "146", label: "146 - Indian (exempt income) - Pension or superannuation", amount: 0}`,
        label: "146 - Indian (exempt income) - Pension or superannuation",
        amount: 44
        },
        {
        value: `{code: "148", label: "148 - Indian (exempt income) - Lump-sum payments", amount: 0}`,
        label: "148 - Indian (exempt income) - Lump-sum payments",
        amount: 45
        },
        {
        value: `{code: "150", label: "150 - Labour Adjustment Benefits Act and Appropriation Acts", amount: 0}`,
        label: "150 - Labour Adjustment Benefits Act and Appropriation Acts",
        amount: 46
        },
        {
        value: `{code: "152", label: "152 - SUBP qualified under the Income Tax Act", amount: 0}`,
        label: "152 - SUBP qualified under the Income Tax Act",
        amount: 47
        },
        {
        value: `{code: "154", label: "154 - Cash award or prize from payer", amount: 0}`,
        label: "154 - Cash award or prize from payer",
        amount: 48
        },
        {
        value: `{code: "156", label: "156 - Bankruptcy settlement", amount: 0}`,
        label: "156 - Bankruptcy settlement",
        amount: 19
        },
        {
        value: `{code: "158", label: "158 - Lump-sum payments that you cannot transfer, that are not reported elsewhere (included in box 018)", amount: 0}`,
        label: "158 - Lump-sum payments that you cannot transfer, that are not reported elsewhere (included in box 018)",
        amount: 50
        },
        {
        value: `{code: "162", label: "162 - Code 162 &amp;ndash; Pre 1990 RPP past service contributions while not a contributor", amount: 0}`,
        label: "162 - Code 162 &amp;ndash; Pre 1990 RPP past service contributions while not a contributor",
        amount: 51
       },       {
        value: `{code: "180", label: "180 - Lump-sum payments from a deferred profit sharing plan (DPSP) that you cannot transfer (included in box 018)", amount: 0}`,
        label: "180 - Lump-sum payments from a deferred profit sharing plan (DPSP) that you cannot transfer (included in box 018)",
        amount: 52
        },
        {
        value: `{code: "190", label: "190 - Lump-sum payments from an unregistered plan (included in box 018)", amount: 0}`,
        label: "190 - Lump-sum payments from an unregistered plan (included in box 018)",
        amount: 53
        },
        {
        value: `{code: "194", label: "194 - Pooled Registered Pension Plans (PRPP) annuity payments from taxable income", amount: 0}`,
        label: "194 - Pooled Registered Pension Plans (PRPP) annuity payments from taxable income",
        amount: 54
        },
        {
        value: `{code: "195", label: "195 - Indian (exempt income) &amp;ndash; PRPP payments", amount: 0}`,
        label: "195 - Indian (exempt income) &amp;ndash; PRPP payments",
        amount: 55
        },
        {
        value: `{code: "200", label: "200 - Provincial/Territorial  COVID-19 financial assistance payments", amount: 0}`,
        label: "200 - Provincial/Territorial  COVID-19 financial assistance payments",
        amount: 56
        }
    ];
  }
}
export const RL1OtherInformationService = {
  getRL1OI: () => {
    return [
      {
        label: "N/A",
        value: "",
      },
      {
        value: "A-1",
        label: "A-1 - Employee benefit plan",
        amount: 1
        },
        {
        value: "A-2",
        label: "A-2 - Employee trust;",
        amount: 2
        },
        {
        value: "A-3",
        label: "A-3 - Repayment of salary or wages;",
        amount: 3
        },
        {
        value: "A-4",
        label: "A-4 - Chainsaw expenses;",
        amount: 4
        },
        {
        value: "A-5",
        label: "A-5 - Brushcutter expenses;",
        amount: 5
        },
        {
        value: "A-6",
        label: "A-6 - Remuneration received by a Québec sailor;",
        amount: 6
        },
        {
        value: "A-7",
        label: "A-7 - Canadian Forces personnel and police deduction;",
        amount: 7
        },
        {
        value: "A-9",
        label: "A-9 - Deduction for foreign specialists;",
        amount: 8
        },
        {
        value: "A-10",
        label: "A-10 - Deduction for foreign researchers;",
        amount: 9
        },
        {
        value: "A-11",
        label: "A-11 - Deduction for foreign researchers on a post-doctoral internship;",
        amount: 10
        },
        {
        value: "A-12",
        label: "A-12 - Deduction for foreign experts",
        amount: 11
        },
        {
        value: "A-13",
        label: "A-13 - Deduction for foreign professors;",
        amount: 12
        },
        {
        value: "A-14",
        label: "A-14 - Exemption rate;",
        amount: 13
        },
        {
        value: "B-1",
        label: "B-1 - CPP contribution;",
        amount: 14
        },
        {
        value: "D-1",
        label: "D-1 - Retirement compensation arrangement;",
        amount: 15
        },
        {
        value: "D-2",
        label: "D-2 - Contribution for service before 1990 - Contributor",
        amount: 16
        },
        {
        value: "D-3",
        label: "D-3 - Contribution for service before 1990 - Non-contributor;",
        amount: 17
        },
        {
        value: "G-1",
        label: "G-1 - Taxable benefit in kind;",
        amount: 18
        },
        {
        value: "G-2",
        label: "G-2 - Pensionable earnings under the CPP;",
        amount: 19
        },
        {
        value: "K-1",
        label: "K-1 - Trips for medical care;",
        amount: 20
        },
        {
        value: "L-2",
        label: "L-2 - Volunteer - compensation not included in boxes A and L",
        amount: 21
        },
        {
        value: "L-3",
        label: "L-3 - Tax-exempt allowance for expenses incurred in the course of duties;",
        amount: 22
        },
        {
        value: "L-4",
        label: "L-4 - Benefit resulting from a debt contracted for the acquisition of investments;",
        amount: 23
        },
        {
        value: "L-7",
        label: "L-7 - Benefit related to a security option at the time of death;",
        amount: 24
        },
        {
        value: "L-8",
        label: "L-8 - Election respecting security options;",
        amount: 25
        },
        {
        value: "L-9",
        label: "L-9 - Security option deduction under section 725.2 of the Taxation Act;",
        amount: 26
        },
        {
        value: "L-10",
        label: "L-10 - Security option deduction under section 725.3 of the Taxation Ac;",
        amount: 27
        },
        {
        value: "O-2",
        label: "O-2 - Deduction for patronage dividends;",
        amount: 28
        },
        {
        value: "O-3",
        label: "O-3 - Redemption of preferred shares;",
        amount: 29
        },
        {
        value: "O-4",
        label: "O-4 - Repayment of wage loss replacement benefits;",
        amount: 30
        },
        {
        value: "O-5",
        label: "O-5 - Incentive Program to Retain Essential Workers benefits (IPREW) [reserved for Revenu Québec]",
        amount: 31
        },
        {
        value: "O-6",
        label: "O-6 - Canada emergency benefits (CERB or CESB) [reserved for the Canada Revenue Agency];",
        amount: 32
        },
        {
        value: "O-7",
        label: "O-7 - Canada recovery benefits (CRB, CRSB or CRCB) [reserved for the Canada Revenue Agency];",
        amount: 33
        },
        {
        value: "O-8",
        label: "O-8 - Repayment of IPREW benefits received in 2020 or in 2021 [reserved for Revenu Québec];",
        amount: 34
        },
        {
        value: "O-9",
        label: "O-9 - Repayment of benefits received in 2020 or in 2021 (CERB,CESB, CRB, CRSB, CRCB or CWLB) [reserved for the Canada Revenue Agency];",
        amount: 35
        },
        {
        value: "O-10",
        label: "O-10 - Canada Worker Lockdown Benefit (reserved for the Canada Revenue Agency)",
        amount: 36
        },
        {
        value: "R-1",
        label: "R-1 - Employment income;",
        amount: 37
        },
        {
        value: "V-1",
        label: "V-1 - Tax-exempt benefit for board and lodging;",
        amount: 38
        },
        {
        value: "200",
        label: "200 - Currency used;",
        amount: 39
        },
        {
        value: "201",
        label: "201 - Allowance for childcare expenses;",
        amount: 40
        },
        {
        value: "211",
        label: "211 - Benefit related to previous employment;",
        amount: 41
        },
        {
        value: "235",
        label: "235 - Premium paid to a private health services plan;",
        amount: 42
        },
        {
        value: "RZ-CA",
        label: "RZ-CA - Wage earner protection program (WEPP) payments;",
        amount: 43
        },
        {
        value: "RZ-CB",
        label: "RZ-CB - Tax-free savings account (TFSA)",
        amount: 44
        },
        {
        value: "RZ-CC",
        label: "RZ-CC - Payments to the beneficiary of a registered disability savings plan (RDSP);",
        amount: 45
        },
        {
        value: "RZ-CD",
        label: "RZ-CD - Benefits paid to the parents of a crime victim;",
        amount: 46
        },
        {
        value: "RZ-RA",
        label: "RZ-RA - Payments received under a supplementary unemployment benefit plan",
        amount: 47
        },
        {
        value: "RZ-RB",
        label: "RZ-RB - Scholarships, bursaries, fellowships and prizes;",
        amount: 48
        },
        {
        value: "RZ-RC",
        label: "RZ-RC - Research grants;",
        amount: 49
        },
        {
        value: "RZ-RD",
        label: "RZ-RD - Fees for services rendered;",
        amount: 50
        },
        {
        value: "RZ-RG",
        label: "RZ-RG - Labour adjustment benefits;",
        amount: 51
        },
        {
        value: "RZ-RH",
        label: "RZ-RH - Income assistance payments for older workers;",
        amount: 52
        },
        {
        value: "RZ-RI",
        label: "RZ-RI - Benefits paid under a program administered under the Department of Fisheries and Oceans Act (federal statute);",
        amount: 53
        },
        {
        value: "RZ-RJ",
        label: "RZ-RJ - Retiring allowance (including an amount paid in respect of the loss of employment);",
        amount: 54
        },
        {
        value: "RZ-RK",
        label: "RZ-RK - Death benefit;",
        amount: 55
        },
        {
        value: "RZ-RL",
        label: "RZ-RL - Patronage dividends;",
        amount: 56
        },
        {
        value: "RZ-RM",
        label: "RZ-RM - Commissions paid to a self-employed person;",
        amount: 57
        },
        {
        value: "RZ-RN",
        label: "RZ-RN - Benefits paid under a wage loss replacement plan;",
        amount: 58
        },
        {
        value: "RZ-RO",
        label: "RZ-RO - Benefits received by a shareholder;",
        amount: 59
        },
        {
        value: "RZ-RP",
        label: "RZ-RP - Benefits received by a partner",
        amount: 60
        },
        {
        value: "RZ-RQ",
        label: "RZ-RQ - Amounts allocated under a retirement compensation arrangement;",
        amount: 61
        },
        {
        value: "RZ-RR",
        label: "RZ-RR - Payments for services rendered in Québec by a person not resident in Canada;",
        amount: 62
        },
        {
        value: "RZ-RS",
        label: "RZ-RS - Financial assistance;",
        amount: 63
        },
        {
        value: "RZ-RT",
        label: "RZ-RT - Other indemnities paid by the employer as a result of an industrial accident;",
        amount: 64
        },
        {
        value: "RZ-RU",
        label: "RZ-RU - Educational assistance payments from a registered education savings plan (RESP)",
        amount: 65
        },
        {
        value: "RZ-RV",
        label: "RZ-RV - Accumulated income payments from a registered education savings plan (RESP);",
        amount: 66
        },
        {
        value: "RZ-RX",
        label: "RZ-RX - Subsidy to the apprentices;",
        amount: 67
        }
    ];
  }
}

export const RL3OtherInformationService = {
  getRL3OI: () => {
    return [
      {
        label: "N/A",
        value: "",
      },
      {
        value: "E-1",
        label: "E-1 - Actual amount of eligible dividends (Box E)",
        amount: 1
        },
        {
        value: "E-2",
        label: "E-2 - Actual amount of ordinary dividends (Box E)",
        amount: 2
        },
        {
        value: "H-2",
        label: "H-2 - Copyright income, deduction under section 726.26 of te Taxation act",
        amount: 3
        },
        {
        value: "K-1",
        label: "K-1 - Linked notes - Interest from foreign source",
        amount: 4
        },
        {
        value: "200",
        label: "200 - Currency used",
        amount: 5
        },
        {
        value: "201",
        label: "201 - Social insurance number of one of the joint account holders",
        amount: 6
        },
        {
        value: "202",
        label: "202 - Social insurance number of one of the joint account holders",
        amount: 7
        },
        {
        value: "203",
        label: "203 - Social insurance number of one of the joint account holders",
        amount: 8
        },
        {
        value: "204",
        label: "204 - Social insurance number of one of the joint account holders",
        amount: 9
        },
        {
        value: "205",
        label: "205 - Unclaimed dividend account",
        amount: 10
        },
        {
        value: "206",
        label: "206 - Unclaimed interest account",
        amount: 11
        },
        {
        value: "207",
        label: "207 - Unclaimed dividend account - income tax withheld",
        amount: 12
        },
        {
        value: "208",
        label: "208 - Unclaimed dividend account - income tax withheld",
        amount: 13
        },
    ];
  }
}
export const RL16OtherInformationService = {
  getRL16OI: () => {
    return [
      {
        label: "N/A",
        value: "",
      },
      {
        value: "A-1",
        label: "A-1 - Foreign capital gain included in box A",
        amount: 1
        },
        {
        value: "A-2",
        label: "A-2 - Split income: Capital gain deemed to be an ordinary dividend",
        amount: 2
        },
        {
        value: "A-3",
        label: "A-3 - Split income: Capital gain deemed to be a foreign dividend",
        amount: 3
        },
        {
        value: "A-4",
        label: "A-4 - Split income: Other capital gain",
        amount: 4
        },
        {
        value: "B-1",
        label: "B-1 - Single foreign pension paymen",
        amount: 5
        },
        {
        value: "D-1",
        label: "D-1 - Amount of the foreign pension payment for witch an amount of foreign income tax mist be entered in box L.",
        amount: 6
        },
        {
        value: "E-1",
        label: "E-1 - Split income (lines 295 and 443 of the return)",
        amount: 7
        },
        {
        value: "F-1",
        label: "F-1 - Split income (lines 295 and 443 of the return)",
        amount: 8
        },
        {
        value: "G-1",
        label: "G-1 - Split income (lines 295 and 443 of the return)",
        amount: 9
        },
        {
        value: "G-3",
        label: "G-3 - Split income: Canadian xorporation (lines 295 and 443)",
        amount: 10
        },
        {
        value: "G-5",
        label: "G-5 - Retiring allowance (line 154)",
        amount: 11
        },
        {
        value: "G-6",
        label: "G-6 - Death benefit (line 154)",
        amount: 12
        },
        {
        value: "G-7",
        label: "G-7 - Death benefit: Amount of the exemption",
        amount: 13
        },
        {
        value: "G-8",
        label: "G-8 - Single payment from a registered pension plan (RPP) made to a recipient other than the surviving spouse (line 154)",
        amount: 14
        },
        {
        value: "G-9",
        label: "G-9 - income averaging annuity for artists (line 154)",
        amount: 15
        },
        {
        value: "G-10",
        label: "G-10 - income tax deducted at source on the income-averaging annuity for artists",
        amount: 16
        },
        {
        value: "H-1",
        label: "H-1 - Qaulified farm or fishing property",
        amount: 17
        },
        {
        value: "H-2",
        label: "H-2 - Qualified small business corporation shares",
        amount: 18
        },
        {
        value: "H-3",
        label: "H-3 - Split income: Capital gain deemed to be an ordinary dividend (line 167)",
        amount: 19
        },
        {
        value: "I-1",
        label: "I-1 - Split income (line 295 and 433)",
        amount: 20
        },
        {
        value: "J-1",
        label: "J-1 - Split income (line 295 and 433 of a return)",
        amount: 21
        },
        {
        value: "K-1",
        label: "K-1 - Split income: Foreign income tax on business income",
        amount: 22
        },
        {
        value: "L-1",
        label: "L-1 - Split income: Foreign income tax non-business income",
        amount: 20
        }
    ];
  }
}