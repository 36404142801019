export const CLIENT = {
  CLIENT_SET_SELECTED: "CLIENT_SET_SELECTED",
  CLIENT_CLEAR_SELECTED: "CLIENT_CLEAR_SELECTED",
  CLIENT_NAME_SET_SELECTED: "CLIENT_NAME_SET_SELECTED",
  CLIENT_NAME_CLEAR_SELECTED: "CLIENT_NAME_CLEAR_SELECTED",

  API_KEYS_REQUEST_LOAD: "CLIENT_API_KEYS_REQUEST_LOAD",
  API_KEYS_REQUEST_GENERATE: "CLIENT_API_KEYS_REQUEST_GENERATE",
  API_KEYS_REQUEST_GENERATE_SUCCESS: "CLIENT_API_KEYS_REQUEST_GENERATE_SUCCESS",
  API_KEYS_REQUEST_SUCCESS: "CLIENT_API_KEYS_REQUEST_SUCCESS",
  API_KEYS_REQUEST_FAILED: "CLIENT_API_KEYS_REQUEST_FAILED",

  EMPLOYEE_LIST_REQUEST_LOAD: "CLIENT_EMPLOYEE_LIST_REQUEST_LOAD",
  EMPLOYEE_LIST_REQUEST_SUCCESS: "CLIENT_EMPLOYEE_LIST_REQUEST_SUCCESS",
  EMPLOYEE_LIST_REQUEST_FAILED: "CLIENT_EMPLOYEE_LIST_REQUEST_FAILED",

  VENDOR_LIST_REQUEST_LOAD: "CLIENT_VENDOR_LIST_REQUEST_LOAD",
  VENDOR_LIST_REQUEST_SUCCESS: "CLIENT_VENDOR_LIST_REQUEST_SUCCESS",
  VENDOR_LIST_REQUEST_FAILED: "CLIENT_VENDOR_LIST_REQUEST_FAILED",
};
