import RL16SlipImportXmlLayout from "components/RL16Slip/RL16SlipImportXMLLayout/RL16SlipImportXmlLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL16SlipImportXmlPage {}

export const RL16SlipImportXmlPage: FC<IRL16SlipImportXmlPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import XML RL-16 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl16/list" },
        { text: "RL-16", navigateTo: "/taxslip/rl16/list" },
        {
          text: "Import XML RL-16 Slips",
          navigateTo: "/taxslip/rl16/import-xml",
        },
      ]}
      mainContents={<RL16SlipImportXmlLayout />}
    />
  );
};
