import { RL3SlipSummarySetup } from "components/RL3Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL3SlipSummarySetupPage {}

export const RL3SlipSummarySetupPage: FC<IRL3SlipSummarySetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="RL-3 Summary"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl3/list" },
        { text: "RL-3", navigateTo: "/taxslip/rl3/list" },
        { text: "RL-3 Summary", navigateTo: "/taxslip/rl3/summary" },
      ]}
      mainContents={<RL3SlipSummarySetup testId="RL3-summary-setup" />}
    />
  );
};
