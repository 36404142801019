import { AxiosError, AxiosResponse } from "axios";
import http from "common/http-common";
import { IGetListRequest, IResponse, IServerError } from "models/common";
import { IInvoiceReportListModel } from "models/reports";
import { IEmployeeReportListModel } from "models/reports/employeeReport";
import { IRL1ReportListModel } from "models/reports/rl1Report";
import { IT4ReportListModel } from "models/reports/t4Report";
import { IT4AReportListModel } from "models/reports/t4aReport/t4aReport";

// TODO: replace this with API response once it's ready
export const ReportsService = {
  getInvoiceAdminReport: async (
    params: IGetListRequest
  ): Promise<IInvoiceReportListModel | IServerError | IResponse> => {
    const fixedResponse: any = {
      page: 1,
      size: 50,
      total: 2,
      items: [
        {
          client: "61a8267d-3770-475d-b4e7-f8307ca4ec95",
          invoiceNo: "INV00001",
          refNo: "UPI00001",
          year: 2019,
          invAmount: 63,
          taxes: 12,
          handlingCharges: 2.79,
          discount: 4,
          netAmount: 73.79,
          invoiceDate: "2023-09-21",
          paidAmount: 73.79,
          paymentDate: "2023-09-21",
          refund: null,
          status: true,
          code: "pay",
          clientName: "payment_method",
        },
        {
          client: "5fd55a4f-f262-4a4b-acd1-84acb310a305",
          invoiceNo: "Not Assigned",
          refNo: "UPI00002",
          year: 2020,
          invAmount: 50,
          taxes: 10,
          handlingCharges: 2.79,
          discount: 4,
          netAmount: 65.79,
          invoiceDate: "2023-09-15",
          paidAmount: 60.79,
          paymentDate: "2023-09-21",
          refund: null,
          status: false,
          code: "abc",
          clientName: "abc name",
        },
      ],
      keyField: {
        field: "employee",
        templateUrl: "/taxslip/t4/setup/id",
      },
      headerColumns: [
        {
          field: "clientName",
          headerName: "Client",
          description: "Client",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 0,
          frozenColumn: false,
          footer: null,
        },
        {
          field: "invoiceNo",
          headerName: "invoice No.",
          description: "invoice No",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 2,
          frozenColumn: false,
          footer: null,
        },
        {
          field: "refNo",
          headerName: "Ref. No.",
          description: "Ref No",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 3,
          frozenColumn: false,
          footer: null,
        },
        {
          field: "year",
          headerName: "Year",
          description: "Year",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 4,
          frozenColumn: false,
          footer: null,
        },
        {
          field: "invAmount",
          headerName: "Inv. Amount",
          description: "Inv Amount",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 5,
          frozenColumn: false,
          footer: 113,
        },
        {
          field: "taxes",
          headerName: "Taxes",
          description: "Taxes",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 6,
          frozenColumn: false,
          footer: 22,
        },
        {
          field: "handlingCharges",
          headerName: "Handling Charges",
          description: "Handling Charges",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 7,
          frozenColumn: false,
          footer: 5,
        },
        {
          field: "discount",
          headerName: "Discount",
          description: "Discount",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 8,
          frozenColumn: false,
          footer: 8,
        },
        {
          field: "netAmount",
          headerName: "Net Amount",
          description: "Net Amount",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 9,
          frozenColumn: false,
          footer: 139,
        },
        {
          field: "invoiceDate",
          headerName: "Invoice Date",
          description: "Invoice Date",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 10,
          frozenColumn: false,
          footer: null,
        },
        {
          field: "paidAmount",
          headerName: "Paid Amount",
          description: "Paid Amount",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 11,
          frozenColumn: false,
          footer: 134,
        },
        {
          field: "paymentDate",
          headerName: "Payment Date",
          description: "Payment Date",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 12,
          frozenColumn: false,
          footer: null,
        },
        {
          field: "refund",
          headerName: "Refund",
          description: "Refund",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 13,
          frozenColumn: false,
          footer: 0,
        },
        {
          field: "status",
          headerName: "Status",
          description: "Status",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 14,
          frozenColumn: false,
          footer: null,
        },
      ],
    };
    return new Promise((resolve) =>
      setTimeout(() => resolve(fixedResponse), 1000)
    );

    // const response: AxiosResponse<IDataRoleListModel> = await http.get<IDataRoleListModel>(
    //   `/v1/data-roles/?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);

    // if (response.status === 200) {
    //   return response.data;
    // } else if (response.status === 400) {
    //   return {
    //     status: false,
    //     message: "Bad Request.",
    //     error: true,
    //   };
    // } else if (response.status === 500) {
    //   return {
    //     status: false,
    //     message: "Something went wrong.",
    //     error: true,
    //   };
    // }

    // return {
    //   status: false,
    //   message: "Something went wrong please try again later.",
    // };
  },
  getInvoiceAdminReport1: async (
    startDate: string,
    endDate: string,
    dateAppliesTo: number,
    paymentStatus: number,
    params:any
  ): Promise<IInvoiceReportListModel | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/billing-calculation/super-user?start_date=${startDate}&end_date=${endDate}&payment_status=${paymentStatus}&date_applies_to=${dateAppliesTo}&select_invoice=20&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getActivityLogReport: async (
    startDate: string,
    endDate: string,
    screen: string,
    user: string,
    activityType: string,
    clientName:any,
    params:any
  ): Promise<IInvoiceReportListModel | IServerError | IResponse> => {
    console.log("startDate--",startDate)
    console.log("endDate",endDate)
    console.log("screen",screen)
    console.log("user",user)
    console.log("activityType",activityType)
    console.log("params",params)
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/activity-log/activty-log/report-screen/?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&start_date=${startDate} 00:00:00&end_date=${endDate} 00:00:00${screen !== 'all' ? `&slug=${screen}` : ''}&variable_value=${clientName}&activity_type=${activityType !== 'all' ? activityType : ''}`    
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
    // const fixedResponse: any = {
    //   page: 1,
    //   size: 50,
    //   total: 2,
    //   items: [
    //     {
    //       activityType: "T4Slip Emailed",
    //       data: "DEEPAK KHOSLA 2023 (Completed)",
    //       updatedBy: "Mirza Idrees",
    //       updatedOn: "Feb 28, 2024",
    //     },
    //     {
    //       activityType: "T4Slip Emailed",
    //       data: "ZEESHAN MALIK 2023 (Completed)",
    //       updatedBy: "Mirza Idrees",
    //       updatedOn: "Mar 03, 2024",
    //     },
    //   ],
    //   keyField: {
    //     field: "employee",
    //     templateUrl: "/taxslip/t4/setup/id",
    //   },
    //   headerColumns: [
    //     {
    //       //only this column have sortable true
    //       field: "activityType",
    //       headerName: "Activity Type",
    //       description: "Activity Type",
    //       flex: 1,
    //       hide: false,
    //       hideable: false,
    //       sortable: true,
    //       sortingOrder: "asc",
    //       resizable: false,
    //       type: "string",
    //       align: "left",
    //       headerAlign: "left",
    //       hideSortIcons: false,
    //       disableColumnMenu: true,
    //       disableReorder: true,
    //       disableExport: true,
    //       maskable: false,
    //       isFixed: true,
    //       sequence: 0,
    //       frozenColumn: false,
    //       footer: null,
    //     },
    //     {
    //       field: "data",
    //       headerName: "Data",
    //       description: "Data",
    //       flex: 1,
    //       hide: false,
    //       hideable: false,
    //       sortable: false,
    //       sortingOrder: "asc",
    //       resizable: false,
    //       type: "string",
    //       align: "left",
    //       headerAlign: "left",
    //       hideSortIcons: false,
    //       disableColumnMenu: true,
    //       disableReorder: true,
    //       disableExport: true,
    //       maskable: false,
    //       isFixed: true,
    //       sequence: 2,
    //       frozenColumn: false,
    //       footer: null,
    //     },
    //     {
    //       field: "updatedBy",
    //       headerName: "Updated By",
    //       description: "Updated By",
    //       flex: 1,
    //       hide: false,
    //       hideable: false,
    //       sortable: false,
    //       sortingOrder: "asc",
    //       resizable: false,
    //       type: "string",
    //       align: "left",
    //       headerAlign: "left",
    //       hideSortIcons: false,
    //       disableColumnMenu: true,
    //       disableReorder: true,
    //       disableExport: true,
    //       maskable: false,
    //       isFixed: true,
    //       sequence: 3,
    //       frozenColumn: false,
    //       footer: null,
    //     },
    //     {
    //       field: "updatedOn",
    //       headerName: "Updated On",
    //       description: "Updated On",
    //       flex: 1,
    //       hide: false,
    //       hideable: false,
    //       sortable: false,
    //       sortingOrder: "asc",
    //       resizable: false,
    //       type: "string",
    //       align: "left",
    //       headerAlign: "left",
    //       hideSortIcons: false,
    //       disableColumnMenu: true,
    //       disableReorder: true,
    //       disableExport: true,
    //       maskable: false,
    //       isFixed: true,
    //       sequence: 4,
    //       frozenColumn: false,
    //       footer: null,
    //     },
    //   ],
    // };
    // return new Promise((resolve) =>
    //   setTimeout(() => resolve(fixedResponse), 1000)
    // );
  },
  delAdminInvoices: async (
    payload: any
  ): Promise<string | IServerError | IResponse> => {
    console.log("delAdminInvoices", payload);
    // return new Promise(resolve => setTimeout(() => resolve("Flex Field has been deleted successfully"), 1000));
    const response: AxiosResponse<string> | any = await http.delete<string>(
      `/v1/billing-calculation/bulk-delete/`,
      { data: payload }
    );

    if (response.status === 200) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getJournalExport: async (
    params: IGetListRequest
  ): Promise<IInvoiceReportListModel | IServerError | IResponse> => {
    const fixedResponse: any = {
      page: 1,
      size: 50,
      total: 2,
      items: [
        {
          client: "f17badd1-ea46-415c-8bb8-59363b8e3f4c",
          invoiceNo: "INV00001",
          refNo: "UPI00001",
          year: 2019,
          invAmount: 63,
          taxes: 12,
          handlingCharges: 2.79,
          discount: 4,
          netAmount: 73.79,
          invoiceDate: "2023-09-21",
          paidAmount: 73.79,
          paymentDate: "2023-09-21",
          refund: null,
          status: true,
          code: "pay",
          clientName: "payment_method",
        },
        {
          client: "15e3106f-e942-4c91-a63f-5b67affc6c78",
          invoiceNo: "not assigned",
          refNo: "UPI00002",
          year: 2020,
          invAmount: 50,
          taxes: 10,
          handlingCharges: 2.79,
          discount: 4,
          netAmount: 65.79,
          invoiceDate: "2023-09-15",
          paidAmount: 60.79,
          paymentDate: "2023-09-21",
          refund: null,
          status: false,
          code: "abc",
          clientName: "abc name",
        },
      ],
      keyField: {
        field: "employee",
        templateUrl: "/taxslip/t4/setup/id",
      },
      headerColumns: [
        {
          field: "clientName",
          headerName: "Client",
          description: "Client",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 0,
          frozenColumn: false,
          footer: null,
        },
        {
          field: "invoiceNo",
          headerName: "invoice No.",
          description: "invoice No",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 2,
          frozenColumn: false,
          footer: null,
        },
        {
          field: "refNo",
          headerName: "Ref. No.",
          description: "Ref No",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 3,
          frozenColumn: false,
          footer: null,
        },
        {
          field: "year",
          headerName: "Year",
          description: "Year",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 4,
          frozenColumn: false,
          footer: null,
        },
        {
          field: "invAmount",
          headerName: "Inv. Amount",
          description: "Inv Amount",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 5,
          frozenColumn: false,
          footer: 113,
        },
        {
          field: "taxes",
          headerName: "Taxes",
          description: "Taxes",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 6,
          frozenColumn: false,
          footer: 22,
        },
        {
          field: "handingCharges",
          headerName: "Handing Charges",
          description: "Handing Charges",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 7,
          frozenColumn: false,
          footer: 5,
        },
        {
          field: "discount",
          headerName: "Discount",
          description: "Discount",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 8,
          frozenColumn: false,
          footer: 8,
        },
        {
          field: "netAmount",
          headerName: "Net Amount",
          description: "Net Amount",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 9,
          frozenColumn: false,
          footer: 139,
        },
        {
          field: "invoiceDate",
          headerName: "Invoice Date",
          description: "Invoice Date",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 10,
          frozenColumn: false,
          footer: null,
        },
        {
          field: "paidAmount",
          headerName: "Paid Amount",
          description: "Paid Amount",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 11,
          frozenColumn: false,
          footer: 134,
        },
        {
          field: "paymentDate",
          headerName: "Payment Date",
          description: "Payment Date",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 12,
          frozenColumn: false,
          footer: null,
        },
        {
          field: "refund",
          headerName: "Refund",
          description: "Refund",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 13,
          frozenColumn: false,
          footer: 0,
        },
        {
          field: "status",
          headerName: "Status",
          description: "Status",
          flex: 1,
          hide: false,
          hideable: false,
          sortable: true,
          sortingOrder: "asc",
          resizable: false,
          type: "string",
          align: "left",
          headerAlign: "left",
          hideSortIcons: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          maskable: false,
          isFixed: true,
          sequence: 14,
          frozenColumn: false,
          footer: null,
        },
      ],
    };
    return new Promise((resolve) =>
      setTimeout(() => resolve(fixedResponse), 1000)
    );

    // const response: AxiosResponse<IDataRoleListModel> = await http.get<IDataRoleListModel>(
    //   `/v1/data-roles/?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);

    // if (response.status === 200) {
    //   return response.data;
    // } else if (response.status === 400) {
    //   return {
    //     status: false,
    //     message: "Bad Request.",
    //     error: true,
    //   };
    // } else if (response.status === 500) {
    //   return {
    //     status: false,
    //     message: "Something went wrong.",
    //     error: true,
    //   };
    // }

    // return {
    //   status: false,
    //   message: "Something went wrong please try again later.",
    // };
  },
  getJournalExport1: async (
    startDate: string,
    endDate: string,
    dateAppliesTo: number,
    paymentStatus: number,
    selectInvoice: number,
    params:any
  ): Promise<IInvoiceReportListModel | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/billing-calculation/super-user?start_date=${startDate}&end_date=${endDate}&payment_status=${paymentStatus}&date_applies_to=${dateAppliesTo}&select_invoice=${selectInvoice}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`
    );

    if (response.status === 200) {
      console.log("response.data11", response.data);
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  createInvoice: async (
    clientId: string,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.post<any>(
      `/v1/billing-calculation/?client_id=${clientId}&tax_year=${taxYear}`
    );

    if (response.status === 201) {
      return response.data;
    }else if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  getPreviousYearInvoice: async (
    clientId: string,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/billing-calculation/get_previous_year_invoice/?client_id=${clientId}&tax_year=${taxYear}`
    );

    if (response.status === 201) {
      return response.data;
    }else if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  getInvoiceReport: async (
    clientId: string,
    taxYear: number,
    invoicePaymentFor:string,
    params: IGetListRequest
  ): Promise<any | IServerError | IResponse> => {
    // const fixedResponse: any = {
    //   "page": 1,
    //   "size": 50,
    //   "total": 1,
    //   "items": [
    //     {
    //       "invoiceNo": "INV00001",
    //       "refNo": "UPI00001",
    //       "invAmount": 101,
    //       "netAmount": 18.94,
    //       "invoiceDate": "2023-09-06T15:58:52.729524",
    //       "paidAmount": 18.941,
    //       "paymentDate": "2023-09-01T15:58:52.729524",
    //       "tax": 121,
    //       "paid": true,
    //       "discount": 4,
    //       "handlingCharges": 0.94,
    //       "id": "64f85b6cc64c50447558fd4a"
    //     },
    //     {
    //       "invoiceNo": "INV00002",
    //       "refNo": "UPI00002",
    //       "invAmount": 10,
    //       "netAmount": 18.94,
    //       "invoiceDate": "2023-09-06T15:58:52.729524",
    //       "paidAmount": 18.94,
    //       "paymentDate": "2023-09-06T15:58:52.729524",
    //       "tax": 12,
    //       "paid": false,
    //       "discount": 4,
    //       "handlingCharges": 0.94,
    //       "id": "64f85b6cc64c50447558fd4a"
    //     },
    //   ],
    //   "keyField": {
    //     "field": "invoiceNo",
    //     "templateUrl": "/reports/invoice-detail"
    //   },
    //   "headerColumns": [
    //     {
    //       "field": "invoiceNo",
    //       "headerName": "Invoice No",
    //       "description": "Invoice No",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 0,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "invAmount",
    //       "headerName": "Inv amount",
    //       "description": "Inv amount",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 1,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "tax",
    //       "headerName": "Taxes",
    //       "description": "Taxes",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "handlingCharges",
    //       "headerName": "Handling Charges",
    //       "description": "handling Charges",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 3,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     // {
    //     //   "field": "discount",
    //     //   "headerName": "Discount",
    //     //   "description": "Discount",
    //     //   "flex": 1,
    //     //   "hide": false,
    //     //   "hideable": false,
    //     //   "sortable": true,
    //     //   "sortingOrder": "asc",
    //     //   "resizable": false,
    //     //   "type": "text",
    //     //   "align": "left",
    //     //   "headerAlign": "left",
    //     //   "hideSortIcons": false,
    //     //   "disableColumnMenu": true,
    //     //   "disableReorder": true,
    //     //   "disableExport": true,
    //     //   "maskable": false,
    //     //   "isFixed": true,
    //     //   "sequence": 4,
    //     //   "frozenColumn": false,
    //     //   "footer": null,
    //     //   "minWidth": null,
    //     //   "alignFrozen": null
    //     // },
    //     {
    //       "field": "netAmount",
    //       "headerName": "Net Amount",
    //       "description": "Net Amount",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 5,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "invoiceDate",
    //       "headerName": "Invoice Date",
    //       "description": "Invoice Date",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 6,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "paidAmount",
    //       "headerName": "Paid Amount",
    //       "description": "Paid Amount",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 7,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "paymentDate",
    //       "headerName": "payment Date",
    //       "description": "payment Date",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 8,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "paid",
    //       "headerName": "Status",
    //       "description": "Status",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 9,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       // "alignFrozen": "right"
    //     }
    //   ]
    // }
    // return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/billing-calculation/invoice-list/${clientId}/?invoice_tag=${invoicePaymentFor}&tax_year=${taxYear}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getUpdateInvoice: async (
    refNo: string
  ): Promise<any | IServerError | IResponse> => {
    if (refNo !== "") {
      const response: AxiosResponse<any> = await http.get<any>(
        `/v1/billing-calculation/${refNo}/ref-no/`
      );

      if (response.status === 200) {
        return response;
      } else if (response.status === 400) {
        return response.data;
      } else if (response.status === 404) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }

      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }
  },
  getUpdateInvoiceDDL: async (): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/billing-calculation/`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getInvoiceTransactionDetail: async (
    clientId: string,
    taxYear: number,
    refNo: string,
    params?: IGetListRequest
  ): Promise<any | IServerError | IResponse> => {
    // const fixedResponse: any = {
    //   "page": 1,
    //   "size": 50,
    //   "total": 6,
    //   "items": [
    //     {
    //       "transactionType": "T4 Slip",
    //       "employee": "polol string",
    //       "description": "T4 Slip Charged",
    //       "province": "Quebec",
    //       "transactionDate": "2023-09-18",
    //       "amount": 10,
    //       "accumulatedTotal": 10,
    //       "id": "6437b85fad05df8329a1a644"
    //     },
    //     {
    //       "transactionType": "T4 Slip",
    //       "employee": "polol string",
    //       "description": "T4 Slip Charged",
    //       "province": "Alberta",
    //       "transactionDate": "2023-09-18",
    //       "amount": 10,
    //       "accumulatedTotal": 20,
    //       "id": "64f81e47182994a43f9f8e4e"
    //     },
    //     {
    //       "transactionType": "RL-1 Slip",
    //       "employee": "string string",
    //       "description": "RL-1 Slip Charged",
    //       "province": "Quebec",
    //       "transactionDate": "2023-09-18",
    //       "amount": 10,
    //       "accumulatedTotal": 30,
    //       "id": "65044429cfc213a0a9eff569"
    //     },
    //     {
    //       "transactionType": "RL-1 Slip",
    //       "employee": "string string",
    //       "description": "RL-1 Slip Charged",
    //       "province": "Quebec",
    //       "transactionDate": "2023-09-18",
    //       "amount": 4,
    //       "accumulatedTotal": 34,
    //       "id": "65044429cfc213a0a9eff569"
    //     },
    //     {
    //       "transactionType": "RL-1 Slip",
    //       "employee": "string string",
    //       "description": "RL-1 Slip Charged",
    //       "province": "Quebec",
    //       "transactionDate": "2023-09-18",
    //       "amount": 4,
    //       "accumulatedTotal": 38,
    //       "id": "650444d8cfc213a0a9eff56a"
    //     },
    //     {
    //       "transactionType": "T4 Summary",
    //       "employee": "N/A",
    //       "description": "T4 Summary Charged",
    //       "province": "N/A",
    //       "transactionDate": "2023-09-18",
    //       "amount": 25,
    //       "accumulatedTotal": 63,
    //       "id": "64ef2f48f087af1b4be126de"
    //     }
    //   ],
    //   "sumData": [
    //     {
    //       "transactionType": "",
    //       "employee": "",
    //       "description": "",
    //       "province": "",
    //       "transactionDate": "",
    //       "amount": "Total Amount",
    //       "accumulatedTotal": 63,
    //       "id": ""
    //     },
    //     {
    //       "transactionType": "",
    //       "employee": "",
    //       "description": "",
    //       "province": "",
    //       "transactionDate": "",
    //       "amount": "Tax Amount(GST: 5.00%)",
    //       "accumulatedTotal": 12,
    //       "id": ""
    //     },
    //     {
    //       "transactionType": "",
    //       "employee": "",
    //       "description": "",
    //       "province": "",
    //       "transactionDate": "",
    //       "amount": "Discount Amount",
    //       "accumulatedTotal": 4,
    //       "id": ""
    //     },
    //     {
    //       "transactionType": "",
    //       "employee": "",
    //       "description": "",
    //       "province": "",
    //       "transactionDate": "",
    //       "amount": "Handling Charges",
    //       "accumulatedTotal": 2.79,
    //       "id": ""
    //     },
    //     {
    //       "transactionType": "",
    //       "employee": "",
    //       "description": "",
    //       "province": "",
    //       "transactionDate": "",
    //       "amount": "Net Amount",
    //       "accumulatedTotal": 73.79,
    //       "id": ""
    //     }
    //   ],
    //   "keyField": {
    //     "field": "emloyee",
    //     "templateUrl": "/"
    //   },
    //   "headerColumns": [
    //     {
    //       "field": "transactionType",
    //       "headerName": "Transaction Type",
    //       "description": "Transaction Type",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 0,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "employee",
    //       "headerName": "Employee",
    //       "description": "Employee",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 1,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "province",
    //       "headerName": "Province",
    //       "description": "Province",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "description",
    //       "headerName": "Description",
    //       "description": "Description",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 3,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "transactionDate",
    //       "headerName": "Transaction Date",
    //       "description": "Transaction Date",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 4,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "amount",
    //       "headerName": "Amount",
    //       "description": "Amount",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 5,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     },
    //     {
    //       "field": "accumulatedTotal",
    //       "headerName": "Accumulated Total",
    //       "description": "Accumulated Total",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 6,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": null,
    //       "alignFrozen": null
    //     }
    //   ]
    // }
    // return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/billing-calculation/transaction-details/${clientId}/?ref_no=${refNo}&tax_year=${taxYear}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getInvoiceDetail: async (
    clientId: string,
    refNo: string
  ): Promise<IInvoiceReportListModel | IServerError | IResponse> => {
    //   const fixedResponse: any = {
    //     'createdByEmail': 'umair@taxslips.ca',
    //     'createdByExternalId': 23,
    //     'createdAt': '2023-06-08T15:02:54.911217',
    //     'client': {'externalId': '46f7b880-624c-4635-a845-24a20c4a75c8'},
    //     'taxYear': 2022,
    //     'id': '6481a74e67d820a15dc2c536',
    //     'detail': [
    //         {
    //           'product': 'T4 slips',
    //           'quantity': 3,
    //           'unitPrice': 4.0,
    //           'amount': 12.0
    //         },
    //         {
    //           'product': 'RL1 slips',
    //           'quantity': 0,
    //           'unitPrice': 0.0,
    //           'amount': 0.0
    //         },
    //         {
    //           'product': 'T4a slips',
    //           'quantity': 0,
    //           'unitPrice': 0.0,
    //           'amount': 0.0
    //         }
    //     ],
    //     'totalAmount': 12.0,
    //     'taxAmount': 3.0,
    //     'invoiceNo': 'INV00009',
    //     'refNo': 'UPI00009',
    //     'discount': 0.0,
    //     'handlingCharges': 0.0,
    //     'netAmount': 15.0
    // }
    //   return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/billing-calculation/ref-no/${refNo}/?client_id=${clientId}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  paypalIntegrationOfUnpaidInvoice: async (
    clientId: string,
    refNo: string,
    tag: string
  ): Promise<IInvoiceReportListModel | IServerError | IResponse> => {
    if(tag === 'paypal'){
      const response: AxiosResponse<any> = await http.post<any>(
        `/v1/paypal-integration/?client_id=${clientId}&ref_no=${refNo}`
      );
  
      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        return {
          status: false,
          message: "Bad Request.",
          error: true,
        };
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    } else {
      const response: AxiosResponse<any> = await http.post<any>(
        `/v1/paypal-integration/stripe/${clientId}/?ref_no=${refNo}`
      );
  
      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        return {
          status: false,
          message: "Bad Request.",
          error: true,
        };
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getEmployeeReport: async (
    clientId: string,
    taxYear: number,
    params: IGetListRequest
  ): Promise<IEmployeeReportListModel | IServerError | IResponse> => {
    //       const fixedResponse: any = {
    //     "page": 1,
    //     "size": 50,
    //     "total": 2,
    //     "items": [
    //         {
    //             "id": "52058dd1-da26-4cfb-b07a-cada80c1bda3",
    //             "name1": "test Rl1",
    //             "name2": "Ali",
    //             "uniqueIdentifier": "123456789",
    //             "email": "zeeshan123@gmail.com",
    //             "createdDate": "2023-07-03",
    //             "businessName": null,
    //             "businessNumber": null,
    //             "status": "Active",
    //             "address": {
    //                 "id": "7262d99a-793a-47ec-848e-1c0396d36f6f",
    //                 "isPrimary": true,
    //                 "appAddressTypeId": 1,
    //                 "addressLine1": "testing",
    //                 "addressLine2": null,
    //                 "city": "dfdf",
    //                 "postalCode": "A5d5d5",
    //                 "province": {
    //                     "id": 5,
    //                     "name": "NewfoundLand & Labrador",
    //                     "shortCode": "NL"
    //                 },
    //                 "country": {
    //                     "id": 1,
    //                     "name": "Canada",
    //                     "shortCode": "CAN"
    //                 }
    //             },
    //             "metaFields": {
    //                 "name_0": "INITIALS",
    //                 "value_0": "None",
    //                 "name_1": "DATE_OF_BIRTH",
    //                 "value_1": "Z",
    //                 "name_2": "PROVINCE_OF_EMPLOYMENT",
    //                 "value_2": "id=11 name='Quebec' short_code='QC'"
    //             },
    //             "bankAccounts": []
    //         },
    //         {
    //             "id": "3d365ce1-40af-4ec2-bfc6-178a9e929de5",
    //             "name1": "Zeeshan",
    //             "name2": "Ali",
    //             "uniqueIdentifier": "123456789",
    //             "email": "waqas123@gmail.com",
    //             "createdDate": "2023-07-03",
    //             "businessName": null,
    //             "businessNumber": null,
    //             "status": "Active",
    //             "address": {
    //                 "id": "96295d01-3ea1-494f-84cc-4031d1ce6979",
    //                 "isPrimary": true,
    //                 "appAddressTypeId": 1,
    //                 "addressLine1": "testing",
    //                 "addressLine2": null,
    //                 "city": "dfdf",
    //                 "postalCode": "A5d5d5",
    //                 "province": {
    //                     "id": 8,
    //                     "name": "Nunavut",
    //                     "shortCode": "NU"
    //                 },
    //                 "country": {
    //                     "id": 1,
    //                     "name": "Canada",
    //                     "shortCode": "CAN"
    //                 }
    //             },
    //             "metaFields": {
    //                 "name_0": "PPIP_EXEMPT",
    //                 "value_0": "False",
    //                 "name_1": "PROVINCE_OF_EMPLOYMENT",
    //                 "value_1": "id=7 name='Northwest Territories' short_code='NT'",
    //                 "name_2": "REDUCED_EI",
    //                 "value_2": "False",
    //                 "name_3": "EI_EXEMPT",
    //                 "value_3": "True",
    //                 "name_4": "CPP_QPP_EXEMPT",
    //                 "value_4": "True",
    //                 "name_5": "EMPLOYMENT_CODE",
    //                 "value_5": "EmploymentCode.TAXI_DRIVERS_DRIVERS_OF_OTHER_PASSENGER_CARRYING_VEHICALS",
    //                 "name_6": "PAY_PERIOD",
    //                 "value_6": "PayPeriods.BI_WEEKLY_26"
    //             },
    //             "bankAccounts": []
    //         }
    //     ],
    //     "keyField": {
    //         "field": "name",
    //         "templateUrl": "/employees-report/{id}"
    //     },
    //     "headerColumns": [
    //         {
    //             "field": "name1",
    //             "headerName": "Name",
    //             "description": "Name",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 0
    //         },
    //         {
    //             "field": "status",
    //             "headerName": "Status",
    //             "description": "status",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 1
    //         },
    //         {
    //             "field": "uniqueIdentifier",
    //             "headerName": "Unique Identifier",
    //             "description": "unique Identifier",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 2
    //         },
    //         {
    //             "field": "email",
    //             "headerName": "Email",
    //             "description": "Email",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 3
    //         },
    //         {
    //             "field": "address.addressLine1",
    //             "headerName": "Address",
    //             "description": "Address",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 4
    //         },
    //         {
    //             "field": "address.city",
    //             "headerName": "City",
    //             "description": "City",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 5
    //         },
    //         {
    //             "field": "address.postalCode",
    //             "headerName": "Postal Code",
    //             "description": "postal Code",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 6
    //         },
    //         {
    //             "field": "address.province.name",
    //             "headerName": "Province",
    //             "description": "province",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 7
    //         },
    //         {
    //             "field": "metaFields.value_0",
    //             "headerName": "metaFields.name_0",
    //             "description": "INITIALS",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 8
    //         },
    //         {
    //             "field": "metaFields.value_1",
    //             "headerName": "DATE_OF_BIRTH",
    //             "description": "DATE_OF_BIRTH",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 9
    //         },
    //         {
    //             "field": "metaFields.value_2",
    //             "headerName": "PROVINCE_OF_EMPLOYMENT",
    //             "description": "PROVINCE_OF_EMPLOYMENT",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 10
    //         },
    //         // {
    //         //     "field": "metaFields.value_0",
    //         //     "headerName": "PPIP_EXEMPT",
    //         //     "description": "PPIP_EXEMPT",
    //         //     "flex": 1,
    //         //     "hide": false,
    //         //     "hideable": false,
    //         //     "sortable": true,
    //         //     "sortingOrder": "asc",
    //         //     "resizable": false,
    //         //     "type": "string",
    //         //     "align": "left",
    //         //     "headerAlign": "left",
    //         //     "hideSortIcons": false,
    //         //     "disableColumnMenu": true,
    //         //     "disableReorder": true,
    //         //     "disableExport": true,
    //         //     "maskable": false,
    //         //     "isFixed": true,
    //         //     "sequence": 11
    //         // },
    //         // {
    //         //     "field": "metaFields.value_1",
    //         //     "headerName": "PROVINCE_OF_EMPLOYMENT",
    //         //     "description": "PROVINCE_OF_EMPLOYMENT",
    //         //     "flex": 1,
    //         //     "hide": false,
    //         //     "hideable": false,
    //         //     "sortable": true,
    //         //     "sortingOrder": "asc",
    //         //     "resizable": false,
    //         //     "type": "string",
    //         //     "align": "left",
    //         //     "headerAlign": "left",
    //         //     "hideSortIcons": false,
    //         //     "disableColumnMenu": true,
    //         //     "disableReorder": true,
    //         //     "disableExport": true,
    //         //     "maskable": false,
    //         //     "isFixed": true,
    //         //     "sequence": 12
    //         // },
    //         // {
    //         //     "field": "metaFields.value_2",
    //         //     "headerName": "REDUCED_EI",
    //         //     "description": "REDUCED_EI",
    //         //     "flex": 1,
    //         //     "hide": false,
    //         //     "hideable": false,
    //         //     "sortable": true,
    //         //     "sortingOrder": "asc",
    //         //     "resizable": false,
    //         //     "type": "string",
    //         //     "align": "left",
    //         //     "headerAlign": "left",
    //         //     "hideSortIcons": false,
    //         //     "disableColumnMenu": true,
    //         //     "disableReorder": true,
    //         //     "disableExport": true,
    //         //     "maskable": false,
    //         //     "isFixed": true,
    //         //     "sequence": 13
    //         // },
    //         {
    //             "field": "metaFields.value_3",
    //             "headerName": "EI_EXEMPT",
    //             "description": "EI_EXEMPT",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 14
    //         },
    //         {
    //             "field": "metaFields.value_4",
    //             "headerName": "CPP_QPP_EXEMPT",
    //             "description": "CPP_QPP_EXEMPT",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 15
    //         },
    //         {
    //             "field": "metaFields.value_5",
    //             "headerName": "EMPLOYMENT_CODE",
    //             "description": "EMPLOYMENT_CODE",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 16
    //         },
    //         {
    //             "field": "metaFields.value_6",
    //             "headerName": "PAY_PERIOD",
    //             "description": "PAY_PERIOD",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 17
    //         }
    //     ]
    // }
    //       return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));
    // console.log("id from res",clientId)
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/vendors-employee-report/employee/?client_id=${clientId}&tax_year=${taxYear}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`
    );
    // `/v1/vendors-employee-report/vendors/?client_id=${clientId}
    // &order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}
    // `);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getVendorReport: async (
    clientId: string,
    taxYear: number,
    params: IGetListRequest
  ): Promise<IEmployeeReportListModel | IServerError | IResponse> => {
    //       const fixedResponse: any = {
    //     "page": 1,
    //     "size": 50,
    //     "total": 1,
    //     "items": [
    //         {
    //             "id": "e767c8ea-41e2-42d2-a991-6e3d0f1f3068",
    //             "name1": "test t4a",
    //             "name2": "Ali",
    //             "uniqueIdentifier": "123456789",
    //             "email": "zeeshan123@gmail.com",
    //             "createdDate": "2023-07-03",
    //             "businessName": null,
    //             "businessNumber": null,
    //             "status": "Active",
    //             "address": {
    //                 "id": "718ab770-70cc-4813-9276-790096a80278",
    //                 "isPrimary": true,
    //                 "appAddressTypeId": 1,
    //                 "addressLine1": "testing",
    //                 "addressLine2": null,
    //                 "city": "dfdf",
    //                 "postalCode": "A5d5d5",
    //                 "province": {
    //                     "id": 5,
    //                     "name": "NewfoundLand & Labrador",
    //                     "shortCode": "NL"
    //                 },
    //                 "country": {
    //                     "id": 1,
    //                     "name": "Canada",
    //                     "shortCode": "CAN"
    //                 }
    //             },
    //             "metaFields": {
    //                 "name_0": "INITIALS",
    //                 "value_0": "Z"
    //             },
    //             "bankAccounts": []
    //         }
    //     ],
    //     "keyField": {
    //         "field": "name",
    //         "templateUrl": "/employees-report/{id}"
    //     },
    //     "headerColumns": [
    //         {
    //             "field": "name1",
    //             "headerName": "Name",
    //             "description": "Name",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 0
    //         },
    //         {
    //             "field": "status",
    //             "headerName": "Status",
    //             "description": "status",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 1
    //         },
    //         {
    //             "field": "uniqueIdentifier",
    //             "headerName": "Unique Identifier",
    //             "description": "unique Identifier",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 2
    //         },
    //         {
    //             "field": "email",
    //             "headerName": "Email",
    //             "description": "Email",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 3
    //         },
    //         {
    //             "field": "address.addressLine1",
    //             "headerName": "Address",
    //             "description": "Address",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 4
    //         },
    //         {
    //             "field": "address.city",
    //             "headerName": "City",
    //             "description": "City",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 5
    //         },
    //         {
    //             "field": "address.postalCode",
    //             "headerName": "Postal Code",
    //             "description": "postal Code",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 6
    //         },
    //         {
    //             "field": "address.province.name",
    //             "headerName": "Province",
    //             "description": "province",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 7
    //         },
    //         {
    //             "field": "metaFields.value_0",
    //             "headerName": "INITIALS",
    //             "description": "INITIALS",
    //             "flex": 1,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             "isFixed": true,
    //             "sequence": 8
    //         }
    //     ]
    // }
    //       return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/vendors-employee-report/vendors/?client_id=${clientId}&tax_year=${taxYear}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`
    );
    // `/v1/vendors-employee-report/vendors/?client_id=${clientId}?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getT4Report: async (
    clientId: string,
    taxYear: number,
    params: IGetListRequest
  ): Promise<IT4ReportListModel | IServerError | IResponse> => {
    //       const fixedResponse: any = {
    //         "page": 1,
    //         "size": 25,
    //         "total": 20,
    //         "items": [
    //           {
    //             "id": "ab8ccece-16c1-44e9-ba86-2990eefb178c",
    //             "employeeCode": "1",
    //             "fullName": {
    //               "name" : "Babar Javaid"
    //             },
    //             "initials": "Mr.",
    //             "status": "Active",
    //             "sin": "2021",
    //             "dateOfBirth": "01-01-2000",
    //             "address": "Address of Employee",
    //             "city": "Lahore",
    //             "postCode": "A9A9A9",
    //             "province": "British Columbia",
    //             "email": "babar@websential.ca",
    //             "cppQppExempt": true,
    //             "eippExempt": true,
    //             "ppipExempt": true,
    //             "phone1": "123456789",
    //             "ext1": "123",
    //             "phone2": "123456789",
    //             "ext2": "123",
    //             "provinceOfEmployment": "Quebec",
    //             "payPeriod": "26 - Bi-Weekly",
    //             "reducedEi": false
    //           }
    //         ],
    //         "keyField": {
    //           "field": "id",
    //           "templateUrl": "/employess-report/setup/id"
    //         },
    //         "headerColumns": [
    //           {
    //             "field": "employeeCode",
    //             "headerName": "Emp Code",
    //             "description": "Employee Code",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 0
    //           },
    //           {
    //             "field": "fullName.name",
    //             "headerName": "Employee",
    //             "description": "Employee Name",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 1
    //           },
    //           {
    //             "field": "initials",
    //             "headerName": "Initials",
    //             "description": "Initials",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 2
    //           },
    //           {
    //             "field": "status",
    //             "headerName": "Status",
    //             "description": "Status",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 3
    //           },
    //           {
    //             "field": "sin",
    //             "headerName": "SIN.",
    //             "description": "Social Insurance Number",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 4
    //           },
    //           {
    //             "field": "dateOfBirth",
    //             "headerName": "Date of Birth",
    //             "description": "Date of Birth",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 5
    //           },
    //           {
    //             "field": "address",
    //             "headerName": "Address",
    //             "description": "Address",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 6
    //           },
    //           {
    //             "field": "city",
    //             "headerName": "City",
    //             "description": "City",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "postCode",
    //             "headerName": "Post Code",
    //             "description": "Post Code",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "province",
    //             "headerName": "Province",
    //             "description": "Province",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "email",
    //             "headerName": "Email",
    //             "description": "Email",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": true,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "cppQppExempt",
    //             "headerName": "CPP/QPP Exempt",
    //             "description": "CPP/QPP Exempt",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "boolean",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "eippExempt",
    //             "headerName": "EIPP Exempt",
    //             "description": "EIPP Exempt",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "boolean",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "ppipExempt",
    //             "headerName": "PPIP Exempt",
    //             "description": "PPIP Exempt",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "boolean",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "phone1",
    //             "headerName": "Phone1",
    //             "description": "Phone1",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "ext1",
    //             "headerName": "Ext",
    //             "description": "Extension",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "phone2",
    //             "headerName": "Phone",
    //             "description": "Phone",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "ext2",
    //             "headerName": "Ext",
    //             "description": "Extension",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "provinceOfEmployment",
    //             "headerName": "POE",
    //             "description": "Province of Employment",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "payPeriod",
    //             "headerName": "Pay Period",
    //             "description": "Pay Period",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "string",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           },
    //           {
    //             "field": "reducedEi",
    //             "headerName": "Reduced EI",
    //             "description": "Reduced EI",
    //             "minWidth": 150,
    //             "hide": false,
    //             "hideable": false,
    //             "sortable": true,
    //             // "sortingOrder": "asc",
    //             "resizable": false,
    //             "type": "boolean",
    //             "align": "left",
    //             "headerAlign": "left",
    //             "hideSortIcons": false,
    //             "disableColumnMenu": true,
    //             "disableReorder": true,
    //             "disableExport": true,
    //             "maskable": false,
    //             // "isFixed": true,
    //             "sequence": 7
    //           }

    //         ]
    //       }
    //       return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/vendors-employee-report/vendors/?client_id=${clientId}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getT4ReportNew: async (
    clientId: string,
    taxYear: number,
    params: IGetListRequest
  ): Promise<IT4ReportListModel | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<IT4ReportListModel> =
      await http.get<IT4ReportListModel>(
        // `/reports/t4-reports?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);
        `/v1/t4-reports/?client_id=${clientId}&tax_year=${taxYear}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`
      );

    if (response.status === 200) {
      console.log("response.data t4 report", response.data);
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
  getT4AReportNew: async (
    clientId: string,
    taxYear: number,
    params: IGetListRequest
  ): Promise<IT4AReportListModel | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<IT4ReportListModel> =
      await http.get<IT4ReportListModel>(
        // `/reports/t4-reports?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);
        `/v1/t4a-reports/?client_id=${clientId}&tax_year=${taxYear}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`
      );

    if (response.status === 200) {
      console.log("response.data t4 report", response.data);
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
  getRL1ReportNew: async (
    clientId: string,
    taxYear: number,
    params: IGetListRequest
  ): Promise<IRL1ReportListModel | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<IRL1ReportListModel> =
      await http.get<IRL1ReportListModel>(
        // `/reports/t4-reports?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);
        `/v1/rl1-reports/?client_id=${clientId}&tax_year=${taxYear}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`
      );

    if (response.status === 200) {
      console.log("response.data t4 report", response.data);
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
  getT5ReportNew: async (
    clientId: string,
    taxYear: number,
    params: IGetListRequest
  ): Promise<IT4ReportListModel | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<IT4ReportListModel> =
      await http.get<IT4ReportListModel>(
        // `/reports/t4-reports?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);
        `/v1/t5-reports/?client_id=${clientId}&tax_year=${taxYear}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`
      );

    if (response.status === 200) {
      console.log("response.data t5 report", response.data);
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
  getT3ReportNew: async (
    clientId: string,
    taxYear: number,
    params: IGetListRequest
  ): Promise<IT4ReportListModel | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<IT4ReportListModel> =
      await http.get<IT4ReportListModel>(
        // `/reports/t4-reports?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);
        `/v1/t3-reports/?client_id=${clientId}&tax_year=${taxYear}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`
      );

    if (response.status === 200) {
      console.log("response.data t3 report", response.data);
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
  getRL3ReportNew: async (
    clientId: string,
    taxYear: number,
    params: IGetListRequest
  ): Promise<any | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<any> =
      await http.get<any>(
        // `/reports/t4-reports?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);
        `/v1/rl3-reports/?client_id=${clientId}&tax_year=${taxYear}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`
      );

    if (response.status === 200) {
      console.log("response.data t4 report", response.data);
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
  getRL16ReportNew: async (
    clientId: string,
    taxYear: number,
    params: IGetListRequest
  ): Promise<any | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<any> =
      await http.get<any>(
        // `/reports/t4-reports?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);
        `/v1/rl16-reports/?client_id=${clientId}&tax_year=${taxYear}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`
      );

    if (response.status === 200) {
      console.log("response.data t4 report", response.data);
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
  getGenerralClient: async (
    params: IGetListRequest
  ): Promise<IRL1ReportListModel | IServerError | IResponse> => {
    // const fixedResponse: any = {
    //   page: 1,
    //   size: 5,
    //   total: 5,
    //   items: [
    //     {
    //       clientId: "46f82067-3fe4-4d7d-ab30-a94cd14e3cd6",
    //       clientName: "payment method",
    //       code: null,
    //       id: "64f6e1e32eaf39f36a4f31c8",
    //     },
    //     {
    //       clientId: "2c22539c-3588-4505-80cd-219873a962e2",
    //       clientName: "Zeeshan Test Client 00145",
    //       code: "Zee",
    //       id: "64f6e37f2eaf39f36a4f31ca",
    //     },
    //     {
    //       clientId: "27ae00b8-a44f-44ed-896e-9697c17ba842",
    //       clientName: "Client 02",
    //       code: "Cli",
    //       id: "64f5a67cad991882c56681d8",
    //     },
    //     {
    //       clientId: "96e2791e-c622-4153-baa2-dad976e5f6da",
    //       clientName: "xml imp",
    //       code: "xml",
    //       id: "64f5a62ead991882c56681b6",
    //     },
    //     {
    //       clientId: "05a83dc3-8912-4a47-a733-652fed1d56fc",
    //       clientName: "client 001",
    //       code: "cli",
    //       id: "64f5d750134d3d21a1bb9de3",
    //     },
    //   ],
    //   keyField: {
    //     field: "id",
    //     templateUrl: "/id",
    //   },
    //   headerColumns: [
    //     // {
    //     //     "field": "clientId",
    //     //     "headerName": "Id",
    //     //     "description": "client Id",
    //     //     "flex": 1,
    //     //     "hide": false,
    //     //     "hideable": false,
    //     //     "sortable": true,
    //     //     "sortingOrder": "asc",
    //     //     "resizable": false,
    //     //     "type": "string",
    //     //     "align": "left",
    //     //     "headerAlign": "left",
    //     //     "hideSortIcons": false,
    //     //     "disableColumnMenu": true,
    //     //     "disableReorder": true,
    //     //     "disableExport": true,
    //     //     "maskable": false,
    //     //     "isFixed": true,
    //     //     "sequence": 0,
    //     //     "frozenColumn": false,
    //     //     "footer": null
    //     // },
    //     {
    //       field: "clientName",
    //       headerName: "Client Name",
    //       description: "Client Name",
    //       flex: 1,
    //       hide: false,
    //       hideable: false,
    //       sortable: true,
    //       sortingOrder: "asc",
    //       resizable: false,
    //       type: "text",
    //       align: "left",
    //       headerAlign: "left",
    //       hideSortIcons: false,
    //       disableColumnMenu: true,
    //       disableReorder: true,
    //       disableExport: true,
    //       maskable: false,
    //       isFixed: true,
    //       sequence: 1,
    //       frozenColumn: false,
    //       footer: null,
    //     },
    //     {
    //       field: "code",
    //       headerName: "Code",
    //       description: "Code",
    //       flex: 1,
    //       hide: false,
    //       hideable: false,
    //       sortable: true,
    //       sortingOrder: "asc",
    //       resizable: false,
    //       type: "text",
    //       align: "left",
    //       headerAlign: "left",
    //       hideSortIcons: false,
    //       disableColumnMenu: true,
    //       disableReorder: true,
    //       disableExport: true,
    //       maskable: false,
    //       isFixed: true,
    //       sequence: 2,
    //       frozenColumn: false,
    //       footer: null,
    //       minWidth: 200,
    //     },
    //   ],
    // };
    // return new Promise((resolve) =>
    //   setTimeout(() => resolve(fixedResponse), 1000)
    // );

    const response: AxiosResponse<IRL1ReportListModel> = await http.get<IRL1ReportListModel>(
      `/v1/general-clients/?search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`);

    if (response.status === 200) {
      console.log("general-clients",response.data)
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  updateInvoice: async (
    requestPayload: any
  ): Promise<any | IServerError | IResponse> => {
    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<any> = await http.put<any>(
      `/v1/billing-calculation/${requestPayload.refNo}/`,
      requestPayload
    );

    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 400) {
      return response.data;
    }
    if (response.status === 201) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  updateJournalCode: async (
    requestPayload: any
  ): Promise<any | IServerError | IResponse> => {
    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<any> = await http.put<any>(
      `/v1/general-clients/`,
      requestPayload
    );

    if (response.status === 200) {
      return response.data;
    }
    if (response.status === 201) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  generateInvoiceMail: async (
    clientId: string,
    refNo: string,
    email:string
  ): Promise<any | IServerError | IResponse> => {

    const response: AxiosResponse<any> = await http.post<any>(
      `/v1/email-generate/invoice/?client_id=${clientId}&ref_no=${refNo}&email=${email}`
    );

    if (response.status === 201) {
      return response;
    } else if (response.status === 400) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getAdminTransactionsReport: async (
    params: any
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/tansaction-reports/?payment_status=${params.invoiceselected}&accountant_id=${params.accountantselected}&tax_year=${params.yearselected}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`);
    if (response.status === 200) {
      console.log("transaction-report0reponse",response.data)
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getClientAdjustmentReport: async (
    params: any
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/dashboard-api/client_adjustments/?accountant_id=${params.accountantId}&tax_year=${params.yearselected}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`);
    if (response.status === 200) {
      console.log("transaction-report0reponse",response.data)
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getUserDDL: async (): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/user-ddl`);
      console.log("responseresponse",response)
    if (response.status === 200) {
      return response.data;
    }else if (response.status === 201) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
};
