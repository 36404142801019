import { FieldLayout, ITest, LabelPosition, LayoutWithLabel } from '@websential/cosmic';
import React, { FC } from 'react';
import { IRHookFormMaskedTextFieldWithOnChange, RHookFormMaskedTextFieldWithOnChange } from './RHookFormMaskedTextFieldWithOnChange';


/**
 * Text fields allow users to enter text into a UI. They typically appear in forms and dialogs.
 * The EnhancedTextInputWR wrapper component is a complete form control using Material UI TextField including
 * a label, input, and help text. Websential is using the outlined variants.
 */
export interface IRHookFormMaskedTextFieldWithLabelWithOnChange extends IRHookFormMaskedTextFieldWithOnChange, LayoutWithLabel, ITest { }

export const RHookFormMaskedTextFieldWithLabelWithOnChange: FC<IRHookFormMaskedTextFieldWithLabelWithOnChange> = ({
  id,
  label,
  required,
  labelPosition = LabelPosition.Top,
  labelColumns = 4,
  fieldColumns = 8,
  // inputAdornment = false,
  // inputAdornmentText = "",
  inputMaskPattern = "",
  field,
  testId,
  placeholder = "",
  onChangeHandler,
  defaultValue,
  uppercase = false,
  readOnly = false,
}: IRHookFormMaskedTextFieldWithLabelWithOnChange) => {

  // The destructuring of field prop preventing some TypeScript error with the MaskedInput field.
  // DO NOT REMOVE IT
  return (
    <FieldLayout
      label={label}
      isRequired={required}
      testId={testId}
      labelPosition={labelPosition}
      labelColumns={labelColumns}
      fieldColumns={fieldColumns}
    >
      <RHookFormMaskedTextFieldWithOnChange
        id={id}
        onChangeHandler={onChangeHandler}
        defaultValue={defaultValue}
        placeholder={placeholder}
        inputMaskPattern={inputMaskPattern}
        field={field}
        testId={id}
        uppercase={uppercase}
        readOnly={readOnly}
      />
    </FieldLayout>
  );
};
