import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import { FC } from "react";

export interface IDifferencePanel {}

export const DifferencePanel: FC<IDifferencePanel> = () => {
  return (
    <>
      <TaxSlipSummaryDataInput
        id="difference"
        label="Difference"
        boxNumber=""
      />
    </>
  );
};
