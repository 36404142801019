import { LabelPosition, RHookFormMaskedTextFieldWithLabel } from "@websential/cosmic";
import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import { FC } from "react";

export interface ICompanyDetailPanel {}

export const CompanyDetailPanel: FC<ICompanyDetailPanel> = () => {
  return (
    <>
    {/* <RHookFormMaskedTextFieldWithLabel
        label="Registration Numbers For RPP Or DPSP"
        id="registrationNumbersForRppOrDscp07"
        testId={`registration-Numbers-For-Rpp-Or-Dscp071`}
        labelPosition={LabelPosition.Left}
        placeholder={"0000000"}
        required
        inputMaskPattern={"0000000"}
        labelColumns={7}
        fieldColumns={4}
      /> */}
      <TaxSlipSummaryDataInput
        id="registrationNumbersForRppOrDscp071"
        label="Registration Numbers For RPP Or DPSP"
        boxNumber="071"
        readOnly={false}
      />
      <TaxSlipSummaryDataInput
        id="registrationNumbersForRppOrDscp072"
        label="Registration Numbers For RPP Or DPSP"
        boxNumber="072"
        readOnly={false}
      />
      <TaxSlipSummaryDataInput
        id="registrationNumbersForRppOrDscp073"
        label="Registration Numbers For RPP Or DPSP"
        boxNumber="073"
        readOnly={false}
      />
    </>
  );
};
