import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { InvoiceTransactionDetail } from "components/InvoiceTransactionDetail";

export interface IInvoiceTransactionDetailPage {}

export const InvoiceTransactionDetailPage: FC<IInvoiceTransactionDetailPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Invoice Transactions Detail"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<InvoiceTransactionDetail />}
    />
  );
};
