import * as yup from 'yup';
import { AnyObject, Maybe } from "yup/lib/types";

/**
 * Validate whether a value is a valid regex or not.
 * An example of an invalid regex: "[[[".
 *
 * @param {yup.AnySchema} this
 * @param {string} errorMessage
 * @returns {yup.AnySchema}
 */
function regex(this: yup.AnySchema, errorMessage?: string): yup.AnySchema {
  return this.test("isValidRegex", function (this: yup.TestContext, value: string) {
    const { path, createError } = this;
    // we skip validating is there is no value
    if (!value) {
      return true
    }

    let isValidRegex = true;
    try {
      new RegExp(value);
    } catch (e) {
      isValidRegex = false;
    }

    if (!isValidRegex) {
      return createError({ path, message: errorMessage ?? 'Invalid regex value' });
    }

    return true;
  });
}
yup.addMethod(yup.string, "regex", regex);

function uniqueFieldId(this: yup.AnySchema, currentValues: { id: number, value: string }[], errorMessage?: string): yup.AnySchema {
  return this.test("uniqueFieldId", function (this: yup.TestContext, value: string) {
    const { path, createError, parent, } = this;
    const { id } = parent;
    // we skip validating is there is no value
    if (!value || !currentValues.length) {
      return true;
    }
    let uniqueValues = currentValues.map(flexField => flexField.value);
    // if we are editing
    if (id) {
      // we need to filter out the original field name if we are editing it in-case the user left it as it is.
      uniqueValues = currentValues.filter(flexField => flexField.id !== id).map(flexField => flexField.value);
    }
    if (uniqueValues.includes(value)) {
      return createError({ path, message: errorMessage ?? `${value} already exists!` });
    }

    return true;
  });
}

yup.addMethod(yup.string, "uniqueFieldId", uniqueFieldId);

/**
 * When the value is undefined, we convert it into 0.
 *
 * @param {yup.NumberSchema} this
 * @returns {yup.NumberSchema}
 */
function zeroIfEmpty(this: yup.NumberSchema): yup.NumberSchema {
  return this.transform(value => (isNaN(value) ? 0 : value));
}
yup.addMethod<yup.NumberSchema>(yup.number, "zeroIfEmpty", zeroIfEmpty);


declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    regex(errorMessage?: string): StringSchema<TType, TContext>;
    uniqueFieldId(currentValues: { id: number, value: string }[], errorMessage?: string): StringSchema<TType, TContext>;
  }

  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    zeroIfEmpty(): NumberSchema<TType, TContext>;
  }
}

export default yup;
