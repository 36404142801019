import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import { FC } from "react";
import { RL1SlipSummaryDataInput } from "../../../RL1Slip/RL1SlipSummaryDataInput";
export interface IRL16SlipDetailPanel {
  formMethods: any;
  globalVariablesData: any;
}

export const RL16SlipDetailPanel: FC<IRL16SlipDetailPanel> = ({
  formMethods,
  globalVariablesData,
}) => {
  const handleInputChange = (e: any) => {
    const newValue = e.target.value;
    console.log("newValue", newValue);
  };
  const onReleveChangeHandler = (e: any) => {
    const value = e.target.value;
    const totalOfBoxes = parseFloat(formMethods.getValues("totalOfBoxes").replace(/[$,]/g, ''));
    console.log("totalOfBoxes", totalOfBoxes);
    if (value && totalOfBoxes) {
      // let totalValue = parseInt(value) + parseInt(totalOfBoxes);
      let totalValue = parseInt(value) + totalOfBoxes;
      formMethods.setValue("totalInvestmentIncome", totalValue);
    }
  };
  const onTotalAmountChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value && globalVariablesData) {
      let quebecIncomeTaxCalculatedValue =
        (value * globalVariablesData?.data?.rl16?.quebecIncomeTaxWithholding) /
        100;
      formMethods.setValue("quebecIncomeTax", quebecIncomeTaxCalculatedValue);
    }
  };
  return (
    <>
      <TaxSlipSummaryDataInput
        id="totalNumberOfRL16SlipsFiled"
        label="Total number of RL-16 slips filed"
        boxNumber="31"
        textfield={true}
      />
      <RL1SlipSummaryDataInput
        id="rl16FiledOnPaper"
        label="RL-16 Filed on Paper"
        readOnly={false}
        required={true}
        textfield={true}
      />
      <RL1SlipSummaryDataInput
        id="rl16FiledOnline"
        label="RL-16 Filed Online"
        readOnly={false}
        required={true}
        textfield={true}
      />
      <TaxSlipSummaryDataInput
        id="capitalGains"
        label="Capital Gains"
        boxNumber="A"
      />
      <TaxSlipSummaryDataInput
        id="singlePensionPayment"
        label="Single pension payment"
        boxNumber="B"
      />
      <TaxSlipSummaryDataInput
        id="actualAmountOfEligibleDividends"
        label="Actual amount of eligible dividends"
        boxNumber="C1"
      />
      <TaxSlipSummaryDataInput
        id="actualAmountOfOrdinaryDividends"
        label="Actual amount of ordinary dividends"
        boxNumber="C2"
      />
      <TaxSlipSummaryDataInput
        id="pensionPaymentGivingEntitlement"
        label="Pension payment giving entitlement"
        boxNumber="D"
      />
      <TaxSlipSummaryDataInput
        id="foreignBusinessIncome"
        label="Foriegn business income"
        boxNumber="E"
      />
      <TaxSlipSummaryDataInput
        id="foreignNonBusinessIncome"
        label="Foriegn non business income"
        boxNumber="F"
      />
      <TaxSlipSummaryDataInput
        id="otherIncome"
        label="Other income"
        boxNumber="G"
      />
      <TaxSlipSummaryDataInput
        id="capitalGainsGivingEntitlement"
        label="Capital gains giving entitlement"
        boxNumber="H"
      />
      <TaxSlipSummaryDataInput
        id="taxableAmountOfEligibleAndOrdinaryDividends"
        label="Taxable amount of eligible and ordinary dividends"
        boxNumber="I"
      />
      <TaxSlipSummaryDataInput
        id="dividendTaxCredit"
        label="Dividend tax credit."
        boxNumber="J"
      />
      <TaxSlipSummaryDataInput
        id="foreignIncomeTaxOnNonBusinessIncome"
        label="Foreign income tax on non-business income."
        boxNumber="L"
      />
      <TaxSlipSummaryDataInput
        id="costBaseAdjustmentOfCapitalInterest"
        label="Cost base adjustment of capital interest."
        boxNumber="M"
      />
      </>
  );
};
