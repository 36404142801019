import { ComponentState } from "common/enums";
import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AccountantService, AuthService, TaxProfileService } from "services";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { setSelectedClient, setSelectedClientName, setUserMenuOpen } from "store/actions";
import { clearError } from "store/actions/viewStateActions";
import { IAppState } from "store/reducers";

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { BackdropCircularProgress, DeleteConfirmationDialog, EmptyList, EnhancedDropDownRHFWithLabel, EnhancedSnackbar, IResponse, IServerError, LabelPosition, PhoneNumberRHF, RouterLinkButton, SubmitButton, SubmitButtonVariant } from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import {
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  MOVE_CLIENTS_SCREEN_SLUG,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
} from "common/constants";
import { FormProvider, useForm } from "react-hook-form";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IMoveClients {}
const CLIENT_LIST_QUERY_KEY = "clients-list";


const useClients = (accountantId: string,isAdminUser:number, params: IGetListRequest) =>
  useQuery(
    ["clients-list", accountantId,isAdminUser, params],
    (data) => {
      console.log("@@@@@@=>useClients", data);
      return AccountantService.getClientsByAccountantId(accountantId,isAdminUser, params);
    },
    { refetchOnWindowFocus: false }
  );

  const useGetAccountantsDropDownList = () =>
    useQuery(
      "get-accountant-dll",
      () => TaxProfileService.getAccountantsDropDownList(),
      { refetchOnWindowFocus: false }
    );
    
export const MoveClients: React.FC<IMoveClients> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAdminUser = useSelector((state: IAppState) => state.user.profile.isSuperStatus);
  const clientIdStore = useSelector((state: IAppState) => state.client.id);


     // for delete row Purpose
     const queryClient = useQueryClient();
     const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "client_name",
    order_direction: "asc",
    page: 1,
    size: 25,
  });

  const {
    isLoading: isLoadingAccountantDropDownList,
    data: accountantList,
    // refetch: refetchTabsDDL,
  } = useGetAccountantsDropDownList();

  const { id } = useSelector((state: IAppState) => state.accountant);
  const { isLoading, data } = useClients(String(id),isAdminUser, queryParams); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const MoveClients: any | null = data;
  console.log("dataData",data)
  console.log("MoveClientsData",MoveClients)
  useEffect(()=>{
    if(MoveClients && MoveClients.items && MoveClients.items.length === 1 && zeroClient === true){
      // dispatch(setSelectedClient(MoveClients.items[0]));
      if(clientIdStore !== ""){
        let path = '/clients/dashboard'
        navigate(path)
      }
    }else{
      console.log("length is larger List")
    }
      if(MoveClients && MoveClients.message && MoveClients.message.detail === "Invalid token or expired token."){
        let path = '/logout'
        navigate(path)
      }
  },[MoveClients])

  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);
  const [searchText, setSearchText] = useState({});
  const [zeroClient, setzeroClient] = useState(true);
  
  // for delete row
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [clientId, setClientId] = useState<number>(0);

  // Bulk Delete
const [bulkDelete, setBulkDelete] = useState<boolean>(false);
const [bulkClientId, setBulkClientId] = useState<any>({});
const [bulkClientDelete, setBulkClientDelete] = useState<boolean>(false);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.debouncedSearchQuery,
      order_by: "client_name",
      order_direction: "asc",
      page: requestParams.currentPage + 1,
      size: requestParams.pageSize,
    };
    if (requestParams.sort) {
      const [sortBy, sortDirection] = requestParams.sort.split(",");
      dispatchParams.order_by = toSnake(sortBy);
      dispatchParams.order_direction = sortDirection;
    }
    console.log("dispatchParams",dispatchParams)
    // setQueryParams(dispatchParams);
    if(dispatchParams.search_query === ''){
      setQueryParams(dispatchParams);
    }
    setSearchText(dispatchParams)
  }, []);

  const onSearchButtonClicked=()=>{
    setQueryParams(searchText)
    setzeroClient(false)
  }

  const onRowEditClicked = useCallback((params) => {
    // alert(`Edit row with ID: ${params.row.id} has been clicked!`);
    let path = `/clients/setup/${params.row.id}`;
    navigate(path);
  }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const callAction = () => {
    if (apiAction === "delete") {
      alert(`Delete row with ID: ${selectedIds} has been clicked!`);
      // TODO api call for delete
    } else if (apiAction === "updateStatus") {
      alert(`action need to trigger : ${apiAction}`);
      // TODO api call for update status
    }
    setDialogOpen(false);
  };

 // For Delete Row
 const deleteClientField = () => {
  if (singleDelete) {
    setSingleDelete(false);
    deleteClientById(clientId);
    setDialogOpen(false);
  }
};

const onRowDeleteClicked = useCallback((params) => {
  setDialogOpen(true);
  setSingleDelete(true);
  setApiAction("delete");
  setClientId(params.row.id);
  console.log("ClientId in Client List OnDeleteRowClicked function",params.row.id)
  // alert(`Delete row with ID: ${params.row.id} has been clicked!`);
}, []);

const onSnackbarClosed = (
  event: React.SyntheticEvent | Event,
  reason?: string
) => {
  if (reason === "clickaway") {
    return;
  }
  setSnackbarData(INITIAL_SNACKBAR_DATA);
};

const { isLoading: isDeleting, mutate: deleteClientById } = useMutation(
  AccountantService.delClient,
  {
    onSuccess: (res: string | IServerError | IResponse | any) => {
      console.log("res--",res)
      if (res) {
        // remove the cache of the Year fields since we just added a new field.
        setSnackbarData({
          open: true,
          message: res.message ? res.message : "Something went wrong.",
          severity: res.status === false ? "error" : "success",
        });

        return queryClient.invalidateQueries(
          CLIENT_LIST_QUERY_KEY
        );
      } else {
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      }
    },
    onError: (err) => {
      // TODO: customize the toast message based on the response
      setSnackbarData({
        open: true,
        message: "Something went wrong, try again later!",
        severity: "error",
      });
    },
  }
);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusValue(event.target.value as string);
    setApiAction("updateStatus");
    setDialogOpen(true);
  };

  const onKeyFieldClickHandler = (clickedRow: any) => {
    dispatch(setSelectedClient(clickedRow));
    dispatch(setSelectedClientName(clickedRow));
  };

  //bulk delete

  const { isLoading: isBulkDeleting, mutate: deleteBulkClients } =
    useMutation(AccountantService.delBulkClients, {
      onSuccess: (res: any | string | IServerError | IResponse) => {
        if (typeof res === "object") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res.message ? res.message : "Something went wrong.",
            severity: res.status === false ? "error" : "success",
          });

          setBulkClientDelete(false)
          return queryClient.invalidateQueries(CLIENT_LIST_QUERY_KEY);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
        setBulkClientDelete(false)
      },
      onError: (err:any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
        setBulkClientDelete(false)
      },
    });

  // For Bulk Delete
  const deleteBulkClientField = () => {
    if (bulkDelete) {
      setBulkDelete(false);
      deleteBulkClients(bulkClientId);
      setDialogOpen(false);
    }
  };

  const onRowMultipleDeleteClicked = (params: any) => {
    console.log("Params in onRowMultipleDeleteClicked ====== ", params);
    let bulkDeletePayload = {
      clientIds:{"clientIds":params}
    }
    setDialogOpen(true);
    setBulkDelete(true);
    setApiAction("delete");
    setBulkClientId(bulkDeletePayload);
    setBulkClientDelete(true);
  };

  const accountantDDLItems = useMemo(() => {
    if (accountantList && Array.isArray(accountantList)) {
      return accountantList.map((accountant: any) => ({
        label: accountant.label,
        value: accountant.value,
      }));
    }
    
    // Return empty array in case of API data fetching error
    return [];
  }, [accountantList]);
  console.log("accountantDDLItems",accountantDDLItems)

  const formMethods: any = useForm<any>({
    // resolver: yupResolver(FilingResourceSetupSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    // defaultValues: DEFAULT_VALUES,
  });

  const { isLoading: isSubmitting, mutate: submitMoveClients } = useMutation(
    AccountantService.moveClientsByAccountantId,
    {
      onSuccess: (res: IServerError | IResponse | any) => {
        if (res) {
          setSnackbarData({
            open: true,
            message: res.message,
            severity: res.status === true ? "success" : "error",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  
  const selectionToolbar = useCallback((selectedItems) => {
    setSelectedIds(selectedItems);
    const onSubmit = (formData: any) => {
      console.log("formData  =  = = = = = = = = == ", formData);
      console.log("selectedItems--",selectedItems)
      if(selectedItems.length === 0){
        setSnackbarData({
          open: true,
          message: "Please select clients to move",
          severity: "warning",
        });
      } else if(formData.changeAcc === undefined){
        setSnackbarData({
          open: true,
          message: "Please select Accountant to move clients",
          severity: "warning",
        });
      } else {
        let payload = {
          clientIds:selectedItems,
          parentAccountantId:String(id),
          accountantId:formData.changeAcc
        }
        console.log("payload90",payload)
        submitMoveClients(payload);
      }
    }
  
    return (
      <Box sx={{ marginTop: theme.spacing(1), width:"50%"  }}>
        <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Box sx={{display:"flex"}}>
        <EnhancedDropDownRHFWithLabel
            selectName="changeAcc"
            label="Change Accountant"
            labelPosition={LabelPosition.Left}
            items={accountantDDLItems}
                    isLoading={isLoadingAccountantDropDownList}
            testId={`change-accountant-ddl`}
          />
          <Box sx={{width:"35%",margin:"9px 0 0 10px"}}>

          <SubmitButton
              label="Update Accountant"
              testId="submit-button"
              loading={false}
              // icon={<PlaylistAddCheckOutlinedIcon />}
              variant={SubmitButtonVariant.Primary}
              />
              </Box>
            </Box>
          </form></FormProvider>
      </Box>
    );
  }, [accountantDDLItems]);

    //defualt drawer options set in home 
    useEffect(() => {
      const homeDrawerOptionsOpen = {id:5000,text:"Home"}
      dispatch(setUserMenuOpen(homeDrawerOptionsOpen));
    }, []);

  console.log("queryParams--",queryParams)
  console.log("MoveClients--",MoveClients)

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: MOVE_CLIENTS_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=update_parent",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  const image =
  "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";
  
  return MoveClients && MoveClients.items && MoveClients.items.length === 0 && zeroClient === true ? (
    <EmptyList 
    showImage={true}
    imagePath= {image}
    heading={`There's nothing! Add Client to proceed`} 
    content={`You can add client by directly clicking on add client button.`} 
    testId={"client-empty-list"} 
    showAddButton= {true}
    addButtonLabel={"Add Client"}
    navigateToAdd={"/clients/setup"}
    showImportButton={false}
    showImportXmlButton={false}
    />
  ) : (
    <>
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <BackdropCircularProgress
          open={
            isSubmitting 
          }
          testId="T4 Slip List"
        />
      <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={bulkClientDelete === true ? deleteBulkClientField : deleteClientField}
          cancelActionHandler={onCloseHandler}
          testId="tax-profile-tab-confirmation-dialog"
        />
      <EnhancedDataGrid
        keyField={MoveClients ? MoveClients.keyField : undefined}
        onKeyFieldClickHandler={onKeyFieldClickHandler}
        columns={MoveClients && MoveClients.headerColumns ? MoveClients.headerColumns : []}
        rows={MoveClients?.items}
        selectionEnabled={true}
        autoHeight={true}
        allowSearching={true}
        onSearchButtonClicked={onSearchButtonClicked}
        searchFieldPlaceholder={"Search by Client Name..."}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        selectionToolbar={selectionToolbar}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        // initialPage={0}
        initialPageSize={INITIAL_PAGE_SIZE}
        disableColumnMenu={true}
        showRowActionsColumn={false}
        autoPageSize={false} //
        showIndexColumn={true}
        testId="client-list-grid"
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onRequestParamsChange={onRequestParamsChange}
        onSearchCrossClicked={(value) => setzeroClient(value)}
        totalRowsCount={MoveClients?.total}
        isLoading={isLoading || isDeleting || isBulkDeleting}
        rowIdGetter={(row) => row.id}
        searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
        shouldMask={false}
      />
    </Grid>
    <EnhancedSnackbar
          message={snackbarData.message}
          onCloseHandler={onSnackbarClosed}
          severity={snackbarData.severity}
          testId={`snackbar`}
          open={snackbarData.open}
        />
  </>
  );
};
