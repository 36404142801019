import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC, useEffect, useState } from "react";
import {
  Control,
  Controller,
  useFieldArray,
  UseFormReturn,
} from "react-hook-form";

import ClearIcon from "@mui/icons-material/Clear";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  EnhancedDropDown,
  IEnhancedDropDownItem,
  ITest,
  RHookFormTextField,
} from "@websential/cosmic";
import { IT4ASlipRequestPayload } from "models/t4aSlip/IT4ASlipRequestPayload";

export interface IOtherInformationSetupFieldsPanel extends ITest {
  formMethods: UseFormReturn<any,IT4ASlipRequestPayload>;
  otherInformationList: any;
  isLoading: boolean;
  selectedOtherInformation:boolean;
  oiInEdit:boolean;
}

const FIELD_NAME = "comparison";

interface EnhancedDDLFieldProps extends ITest {
  name: any;
  control: Control<IFlexFieldSetupRequest, any>;
  items: IEnhancedDropDownItem[];
  flexFieldsList: IEnhancedDropDownItem[];
  isLoading?: boolean;
  label: string;
  selectedOtherInformation:boolean;
  formMethods:any;
  onValueChange: (value: any) => void;
  oiInEdit:boolean;
  selectedOiInEdit: boolean;
}
let selectedItems:any = []
export let selectedoi:any = []
export let FilteredSelectedOtherInfoT4A:any = []

const EnhancedDropDownRHF: FC<EnhancedDDLFieldProps> = ({
  name,
  control,
  items,
  isLoading = false,
  label,
  testId,
  selectedOtherInformation,
  formMethods,
  flexFieldsList,
  onValueChange,
  selectedOiInEdit
}) => {
  const fieldTestId = `${testId}-name`;
  console.log("meeeeeeeeeeeessage",selectedOtherInformation)
  // alert(selectedOtherInformation)
  if(selectedOtherInformation !== false){
    selectedItems = []
    onValueChange(flexFieldsList)
  }
  const getValue = formMethods.getValues('comparison')
  console.log("getValueeeeeeeeeeeee",getValue)
  const defualtOI = [{amount:0, code: '', label:''}]
  if(getValue[0].code === defualtOI[0].code){
    console.log("yesyesyesyesyes")
    selectedItems = []
    onValueChange(flexFieldsList)
  } else {
    const codes = getValue.map((item:any) => item.code);
    console.log("codescodescodes",codes)
    selectedItems =  codes
  }
  return (
    <Controller
      render={({ field, fieldState: { error } }) => {
        const { ref, value, onChange, ...rest } = field;
        console.log("selectedItemsselectedItems",selectedItems)
        console.log("selectedoiselectedoi",selectedoi)
        //
        const getAvailableOI = formMethods.getValues('comparison')
        console.log("getValueeeeeeeeeeeee",getAvailableOI)
        console.log("selectedOiInEdit",selectedOiInEdit)
        if(selectedOiInEdit && getAvailableOI[0].code !== defualtOI[0].code){
          console.log("insideddedded",selectedOiInEdit)
          selectedoi = getAvailableOI
        } else if(selectedOiInEdit && getAvailableOI[0].code === defualtOI[0].code){
          console.log("insideddedded2",selectedOiInEdit)
          selectedoi = []
        } else if(getAvailableOI[0].code === defualtOI[0].code){
          console.log("insideddedded3",selectedOiInEdit)
          selectedoi = []
        } 

        const transformedValue = selectedoi.map((item:any) => {
          return { code: item.value || item.code, label: item.label, amount: item.amount };
        });
        
        console.log("transformedValue",transformedValue);
        // const filteredSelectedoi = transformedValue.filter((item:any) => {
        //   const valueItem = getAvailableOI.find((value:any) => value.code === item.code);
        //   return valueItem;
        // })
        const latestValues = transformedValue.reduce((acc: any, item: any) => {
          acc[item.code] = item.label; // Overwrite with the latest value for each code
          return acc;
        }, {});
        const filteredSelectedoi = getAvailableOI
          .filter((item: any) => item.code !== "null" && item.code !== "") // Ignore items where code is "null"
          .map((item: any) => ({
            code: item.code,
            label: latestValues[item.code] || item.label
        }));
        FilteredSelectedOtherInfoT4A=filteredSelectedoi
        
        console.log("filteredSelectedoi",filteredSelectedoi);
        return (
          <EnhancedDropDown
            {...rest}
            items={items}
            selectedItem={value}
            showEmpty={false}
            testId={fieldTestId}
            selectName={name}
            isDisabled={false}
            isLoading={isLoading}
            onChangeHandler={(val: { value: IEnhancedDropDownItem }) =>
            {
              onChange(val.value)
              let value = val.value
              selectedItems.push(value)
              selectedoi.push(val)
            }
            }
            {...(error && { helperText: error.message })}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
};

export const OtherInformationSetupFieldsPanel: FC<IOtherInformationSetupFieldsPanel> =
  ({
    formMethods,
    otherInformationList: flexFieldsList,
    isLoading,
    testId,
    selectedOtherInformation,
    oiInEdit
  }) => {
    const { fields, append, remove } = useFieldArray({
      control: formMethods.control,
      name: FIELD_NAME,
    });

    // useEffect(() => {
    //   append([
    //     // { operator: "", field: 0 },
    //     // { operator: "", field: 0 },
    //     // { operator: "", field: 0 },
    //     // { operator: "", field: 0 },
    //     { amount: 0, code: '', label:'Other Information'},
    //   ]);
    // }, []);
    console.log("otherInformationList",flexFieldsList)
    console.log("formMethods.control",formMethods)    
    
    const [additionalSelects, setAdditionalSelects] = useState<IEnhancedDropDownItem[]>(flexFieldsList);
    const[selectedOiInEdit, setSelectedOiInEdit] = useState(false)

    useEffect(()=>{
      setSelectedOiInEdit(oiInEdit)
    },[oiInEdit])

    console.log("selectedItems",selectedItems)
    const onAddClicked = () => {
      append({ amount: 0, code: 'null', label:'Empty Field'});

      console.log("selectedItems",selectedItems)
      if(flexFieldsList){
      if(selectedItems !== ''){
        // Create a new array of items excluding the currently selected one
        const filteredItems = flexFieldsList.filter((item:any) => !selectedItems.includes(item.value));
    
        // Add the new array to the state to create a new select
        setAdditionalSelects(filteredItems);
        console.log("additionalSelects",additionalSelects)
      }
    }
    if(selectedOiInEdit){
      setSelectedOiInEdit(false)
    }
    };

    return (
      <>
        {fields && fields.map((item:any, index) => {
          return (
            <Grid container columnSpacing={3} key={item.id} alignItems="center">
              <Grid item xs={7} sx={{ pt: 1 }}>
                <EnhancedDropDownRHF
                  name={`${FIELD_NAME}.${index}.code`}
                  label="Field"
                  items={additionalSelects}
                  isLoading={isLoading}
                  control={formMethods.control}
                  testId={`${testId}-${index}-code`}
                  selectedOtherInformation={selectedOtherInformation}
                  formMethods={formMethods}
                  oiInEdit={oiInEdit}
                  selectedOiInEdit={selectedOiInEdit}
                  onValueChange={(newValue:any) => setAdditionalSelects(newValue)}
                  flexFieldsList={flexFieldsList}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <RHookFormTextField
                  id={`${FIELD_NAME}.${index}.amount`}
                  testId={`${testId}-1`}
                />
              </Grid>
              <Grid item xs={1}>
                {fields.length !== 1 && index > fields.length-2 && (
                  <Button
                    data-testid={`${testId}-delete-${index}`}
                    color="warning"
                    onClick={() => remove(index)}
                  >
                    <ClearIcon fontSize="large" />
                  </Button>
                )}
              </Grid>
            </Grid>
          );
        })}
        <Box sx={{ display: "flex", justifyContent: "left" }}>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={onAddClicked}
              data-testid={`${testId}-add`}
              sx={{ fontWeight: "bold" }}
            >
              <PlusOneIcon />
              &nbsp;&nbsp;Add More
            </Button>
          </Box>
        </Box>
      </>
    );
  };
