import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IRL16SLipAxisPage1 {}
const RL16SLipAxisPage1:React.FC<IRL16SLipAxisPage1> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            
        </Grid>
    </Grid>
  )
}

export default RL16SLipAxisPage1