import { FC, useMemo } from "react";
import {
  EnhancedCheckboxRHF,
  EnhancedDropDownRHFWithLabel,
  ITest,
  LabelPosition,
  RHookFormTextFieldWithLabel,
} from "@websential/cosmic";
import { Grid, InputLabel, Typography } from "@mui/material";
import { GET_QUERY_KEY, QUERY_OPTIONS } from "common/constants";
import { useQuery } from "react-query";
import { ProvinceService } from "services/common/ProvinceService";
import { useGetLookupFieldData } from "hooks/useLookupFields";
import { ClientService } from "services";
import { IAppState } from "store/reducers";
import { useSelector } from "react-redux";

const useGetClientProfileData = (id: string | undefined) => {
  return useQuery(
    ['client-single-data-record', id],
    (data) => {
      return ClientService.getById(id);
    },
    { refetchOnWindowFocus: false }
  );
};

interface IEmployeeOrSlipsDefaultSettingsPanel extends ITest {}
export const EmployeeOrSlipsDefaultSettingsPanel: FC<IEmployeeOrSlipsDefaultSettingsPanel> =
  ({ testId }) => {
    const clientId = useSelector((state: IAppState) => state.client.id);
const { isLoading: isLoadingClientProfileDataById, data: clientProfileData } =
  useGetClientProfileData(clientId);

    const useGetProvinceDDL = () => {
      return useQuery(GET_QUERY_KEY.PROVINCE_DDL, () => ProvinceService.get(), {
        ...QUERY_OPTIONS,
      });
    };
    const { isLoading: isLoadingProvinceDropDownList, data: provinceList } =
      useGetProvinceDDL();

      const updatedProvinceList = useMemo(() => {
        if (provinceList && Array.isArray(provinceList)) {
          let province: any[] = provinceList.map(
            (item: any) => ({
              id: item.id,
              label: item.label,
              value: item.value,
            })
          );
    
          province.unshift({
            label: "--",
            value: "",
          });
          return province;
        }
    
        return [
          {
            label: "--",
            value: "",
          },
        ];
      }, [provinceList]);

    const getItemsByIndices = (indices:any) => {
      return indices.map((index:any) => provinceList && provinceList.find((item:any) => item.id === index.toString()));
    };
    
    const poeDdlAccordingToClientOperatingProvinceList = clientProfileData && provinceList && clientProfileData.operatingProvinces ? getItemsByIndices(clientProfileData.operatingProvinces) : []
    const { isLoading: isLoadingPayPeriods, data: payPeriodsList } =
      useGetLookupFieldData("payPeriods");
      
      const updatedPOEDdlAccordingToClientOperatingProvinceList = useMemo(() => {
        if (poeDdlAccordingToClientOperatingProvinceList && Array.isArray(poeDdlAccordingToClientOperatingProvinceList)) {
          let provinceOfEmployement: any[] = poeDdlAccordingToClientOperatingProvinceList.map(
            (item: any) => ({
              id: item.id,
              label: item.label,
              value: item.value,
            })
          );
    
          provinceOfEmployement.unshift({
            label: "--",
            value: "",
          });
          return provinceOfEmployement;
        }
    
        return [
          {
            label: "--",
            value: "",
          },
        ];
      }, [poeDdlAccordingToClientOperatingProvinceList]);
    return (
      <>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          columnSpacing={4}
        >
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <EnhancedDropDownRHFWithLabel
              selectName="employeeDobIsRequired"
              label="Employee Date of Birth is Required"
              testId={`${testId}-employee-dob`}
              items={[
                {
                  value: false,
                  label: "No",
                },
                {
                  value: true,
                  label: "Yes",
                },
              ]}
              selectedItem={false}
              labelPosition={LabelPosition.Left}
            />
            {/* <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                sx={{ textAlign: "left", paddingTop: 2 }}
              >
                <InputLabel>
                  <Typography variant="subtitle2">Slip Type</Typography>
                </InputLabel>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                sx={{ textAlign: "left", paddingTop: 0.6 }}
              >
                <EnhancedCheckboxRHF
                  name="t4SlipType"
                  checked={false}
                  testId="t4-Slip-Type"
                  checkboxLabel="T4"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                xl={2}
                sx={{ textAlign: "left", paddingTop: 0.6 }}
              >
                <EnhancedCheckboxRHF
                  name="t4aSlipType"
                  checked={false}
                  testId="t4a-Slip-Type"
                  checkboxLabel="T4A"
                />
              </Grid>
            </Grid> */}
            <EnhancedDropDownRHFWithLabel
              selectName="provinceOfEmployment"
              label="Province of Employment"
              labelPosition={LabelPosition.Left}
              testId={`province-of-employement`}
              // items={poeDdlAccordingToClientOperatingProvinceList ?? []}
              items={updatedPOEDdlAccordingToClientOperatingProvinceList ?? []}
            />
            <RHookFormTextFieldWithLabel
              label="City"
              id="city"
              testId={`${testId}-employee-city`}
              labelPosition={LabelPosition.Left}
            />
            <EnhancedDropDownRHFWithLabel
              selectName="province"
              label="Province"
              labelPosition={LabelPosition.Left}
              testId={`${testId}-province-list`}
              // items={provinceList ?? []}
              items={updatedProvinceList ?? []}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            columnSpacing={2}
            sx={{ textAlign: "left", paddingTop: 2 }}
          >
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                sx={{ textAlign: "left", paddingTop: 2 }}
              >
                <InputLabel>
                  <Typography variant="subtitle2">
                    Applicable Exemptions
                  </Typography>
                </InputLabel>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                sx={{ textAlign: "left", paddingTop: 0.6 }}
              >
                <EnhancedCheckboxRHF
                  name="cppQppExempt"
                  checked={false}
                  testId="cpp-qpp"
                  checkboxLabel="CPP/QPP"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                xl={2}
                sx={{ textAlign: "left", paddingTop: 0.6 }}
              >
                <EnhancedCheckboxRHF
                  name="eiExempt"
                  checked={false}
                  testId="ei"
                  checkboxLabel="EI"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                sx={{ textAlign: "left", paddingTop: 0.6 }}
              >
                <EnhancedCheckboxRHF
                  name="ppipExempt"
                  checked={false}
                  testId="ppip"
                  checkboxLabel="PPIP"
                />
              </Grid>
            </Grid>
            <EnhancedDropDownRHFWithLabel
              label="Employment Code"
              selectName="employmentCode"
              items={[
                {
                  label: "--",
                  value: '',
                },
                {
                  label: "11 - Placement or employment agency workers",
                  value: 11,
                },
                {
                  label:
                    "12 - Taxi drivers or drivers of other passenger-carrying vehicles",
                  value: 12,
                },
                { label: "13 - Barbers or hairdressers", value: 13 },
                {
                  label:
                    "14 - Withdrawal from a Prescribed Salary Deferral Arrangement Plan",
                  value: 14,
                },
                {
                  label: "15 - Seasonal Agricultural Workers Program",
                  value: 15,
                },
                {
                  label: "16 - deteached Employee Social Security Agreement",
                  value: 16,
                },
                { label: "17 - Fisher self Employed", value: 17 },
              ]}
              testId="employment-code"
              labelPosition={LabelPosition.Left}
            />
            <EnhancedDropDownRHFWithLabel
              label="Pay Periods"
              selectName="payPeriod"
              items={payPeriodsList ?? []}
              selectedItem={26}
              testId="pay-periods"
              labelPosition={LabelPosition.Left}
            />
            <EnhancedDropDownRHFWithLabel
              label="Reduced EI"
              selectName="reducedEi"
              items={[
                { label: "Yes", value: 1 },
                { label: "No", value: 0 },
              ]}
              selectedItem={0}
              testId="reduced-ei"
              labelPosition={LabelPosition.Left}
            />
          </Grid>
        </Grid>
      </>
    );
  };
