export const ValidationMessages = {
    invalidEmailFormat: 'Please add a valid email',
    email: 'Please enter your email',
    password: 'Password is required',
    firstName: 'First name is required',
    lastName: 'Last name is required',
    timezone: 'Timezone is required',
}

export const PlaceholderText = {
    email: 'Enter your email',
    password: 'Enter your password',
    firstName: 'Enter your first name',
    lastName: 'Enter your last name',
    timezone: 'Select your timezone'
}

export const LabelCaption = {
    email: 'Email',
    password: 'Password',
    firstName: 'First Name',
    lastName: 'Last Name',
    timezone: 'Timezone'
}
