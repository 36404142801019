import { FlexFieldTabList } from "components/FlexFieldTab";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IFlexFieldTabListPage {}

export const FlexFieldTabListPage: FC<IFlexFieldTabListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Flex Field Tab List"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Flex Field Tab', navigateTo: '/flex-field-tabs/list'}]}
      mainContents={<FlexFieldTabList />}
    />
  );
};
