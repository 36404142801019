import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { T4AReport } from "components/Reports/T4AReport";

export interface IT4AReportPage {}

export const T4AReportPage: FC<IT4AReportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T4A Reports"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<T4AReport />}
    />
  );
};
