
import { IDropDownListResponse, IGetListRequest } from "models";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";

import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import FileSaver from "file-saver";
import { useDispatch } from "react-redux";

import {
  BackdropCircularProgress,
  DeleteConfirmationDialog,
  EnhancedSnackbar,
  IResponse,
  IServerError,
} from "@websential/cosmic";

import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions/invoiceReportActions";
import { INITIAL_PAGE_SIZE, INITIAL_SNACKBAR_DATA, ROWS_PER_PAGE_PER_OPTIONS } from "common/constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { setSelectedAccountant, setSelectedClient } from "store/actions";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AccountantService } from "services";
import { ActivityLogReportHeader } from "./ActivityLogReportHeader";
import { PrimeDataGridActivityLogReport } from "./PrimeDataGridActivityLogReport";
import { PrimeDataGrid } from "components/PrimeDataGrid";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IActivityLogReport {}
const ACTIVITY_LOG_REPORT_LIST_QUERY_KEY = "activity-log-report-list";
const useActivityLogReport = (
  startDate:string,
  endDate:string,
  screen:string,
  user:string,
  activityType:string,
  clientName:any,
  queryParams:any
  ) =>
  useQuery(
    ["activity-log-report-list",startDate,endDate,screen,user,activityType,clientName,queryParams],
    (data) => {
      return ReportsService.getActivityLogReport(startDate,endDate,screen,user,activityType,clientName,queryParams);
    },
    { refetchOnWindowFocus: false }
  );
const useGetUserDDL = () =>
  useQuery(
    ["user-ddl"],
    (data) => {
      return ReportsService.getUserDDL();
    },
    { refetchOnWindowFocus: false }
  );
export const ActivityLogReport: React.FC<IActivityLogReport> = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { id } = useSelector((state: IAppState) => state.client);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [activityLogReportResponseData, setActivityLogReportResponseData] = useState<any>(undefined);

  // const [invoiceList, setInvoiceList] = useState<any>(undefined);
  const [isLoadingDesiredActivityLogReportList, setIsLoadingDesiredActivityLogReportList] = useState(false);
  const [isLoadingActivityLogReportList, setIsLoadingActivityLogReportList] = useState<any>(false);
  const [ActivityLogReportListUpdatedItems, setActivityLogReportListUpdatedItems] =
    useState<any>(undefined);
    const [ActivityLogReportListUpdatedColumns, setActivityLogReportListUpdatedColumns] =
    useState<any>(undefined);
    const [openDialog, setDialogOpen] = useState(false);
    const [multiDelete, setMultiDelete] = useState<boolean>(false);
    const [delRowsPayload, setDelRowsPayload] = useState<any>(undefined);
    const [queryParams, setQueryParams] = useState<IGetListRequest>({
      search_query: "",
      order_by: "name1",
      order_direction: "asc",
      page: 1,
      size: INITIAL_PAGE_SIZE,
    });

    const onRequestParamsChange = useCallback((requestParams) => {
      console.log("##### - request params", requestParams);
      let dispatchParams = {
        search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
        order_by: "name1",
        order_direction: requestParams.sortOrder ? requestParams.sortOrder : "asc",
        page: requestParams.page + 1,
        size: requestParams.rows,
      };
      console.log("dispatchParams",dispatchParams)
      
      setQueryParams(dispatchParams);
    }, []);

    const formMethods = useForm<any>({
      // resolver: yupResolver(YearSchema),
      mode: "onBlur",
      reValidateMode: "onChange",
      // defaultValues: DEFAULT_VALUES,
    });

    const formatDate = (datee: any) => {
      console.log("dateeeee",datee)
      const date = new Date(datee);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); 
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
  
      return formattedDate;
    };

  // const currentDateRange = formMethods.getValues('dateRange')
  // console.log("dateRangedateRange",currentDateRange)

  const startDate = formMethods.getValues('startDate')
  const endDate = formMethods.getValues('endDate')
  const twoWeeksBeforeDate = new Date();
  twoWeeksBeforeDate.setDate(twoWeeksBeforeDate.getDate() - 14);

  let Today = new Date()
  // const formattedStartDate = formatDate(startDate);
  // const formattedEndDate = formatDate(endDate);
  // const dateAppliesTo = formMethods.getValues('dateAppliesTo')
  // const paymentStatus = formMethods.getValues('status')
  // const { isLoading, data } = useActivityLogReport(formattedStartDate,formattedEndDate,dateAppliesTo,paymentStatus,queryParams); 
  // const { isLoading, data } = useActivityLogReport("2024-02-28","2024-03-03","all","all","all",clientName,queryParams); 
  const { isLoading, data } = useActivityLogReport(formatDate(twoWeeksBeforeDate.toDateString()),formatDate(Today),"all","all","all",clientName,queryParams); 
  const activityLogReportList: any = data;
  console.log("activityLogReportListactivityLogReportList",activityLogReportList)
  useEffect(()=>{
    if(activityLogReportList){
      setActivityLogReportResponseData(activityLogReportList)

      //initial 
      setActivityLogReportListUpdatedColumns(activityLogReportList?.headerColumns)
      setActivityLogReportListUpdatedItems(activityLogReportList?.items)
    }
    },[activityLogReportList])
    
    const { isLoading:isLoadingUserDDL, data:userDDL } = useGetUserDDL(); 

    console.log('userDDL--',userDDL)

  // const onRefNoClickHandler = (clickedRow: any) => {
  //   const refNo = clickedRow.refNo
  //   dispatch(setSelectedINVOICE({refNo:refNo}));
  //   const clientId = clickedRow.client
  //   dispatch(setSelectedClient({id:clientId}));
  //   (async () => {
  //     try {
  //       const response: any =
  //       await AccountantService.getAccountantIdByClient(clientId);
  //       console.log("responseData-----------------",response);
  //       dispatch(setSelectedAccountant({id:response.accountantId}));
  //   } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //   })();
  // };
  // const onClientClickHandler = (clickedRow: any) => {
  //   // const refNo = clickedRow.refNo
  //   // dispatch(setSelectedINVOICE({refNo:refNo}));
  //   const clientId = clickedRow.client
  //   dispatch(setSelectedClient({id:clientId}));
  //   (async () => {
  //     try {
  //       const response: any =
  //       await AccountantService.getAccountantIdByClient(clientId);
  //       console.log("responseData-----------------",response);
  //       dispatch(setSelectedAccountant({id:response.accountantId}));
  //   } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //   })();
  // };



  // useEffect(() => {
  //     setIsLoadingActivityLogReportList(true);
  //     if(activityLogReportList){
  //       const updatedColumnsResponse = activityLogReportList.headerColumns.map(
  //         (item: any) => ({
  //           ...item,
  //           renderCell:
  //             item.field === "status" ? (params: any) => params.value : undefined,
  //         })
  //       );

  //       const updatedRows = activityLogReportList.items.map((row: any) => {

  //         if (row.status !== false && row.refNo && row.clientName) {
  //           return {
  //             ...row,
  //             status: (
  //               <span style={{ color: "green" }}>
  //                 Paid
  //               </span>
  //             ),
  //             refNo: (
  //               <Link
  //                 to={"/reports/invoice-detail"}
  //                 className="no-underline"
  //                 style={{ color: "blue" }}
  //                 onClick={() => onRefNoClickHandler(row)}
  //               >
  //                 {row.refNo}
  //               </Link>
  //             ),
  //             clientName: (
  //               <Link
  //                 to={"/clients/dashboard"}
  //                 className="no-underline"
  //                 style={{ color: "blue" }}
  //                 onClick={() => onClientClickHandler(row)}
  //               >
  //                 {row.clientName}
  //               </Link>
  //             ),
  //           };
  //         } else {
  //           return {
  //             ...row,
  //             status: (
  //                 <span style={{ color: "red" }}>
  //                   Not Paid
  //                 </span>
  //             ),
  //             refNo: (
  //               <Link
  //                 to={"/reports/invoice-detail"}
  //                 className="no-underline"
  //                 style={{ color: "blue" }}
  //                 onClick={() => onRefNoClickHandler(row)}
  //               >
  //                 {row.refNo}
  //               </Link>
  //             ),
  //             clientName: (
  //               <Link
  //                 to={"/clients/dashboard"}
  //                 className="no-underline"
  //                 style={{ color: "blue" }}
  //                 onClick={() => onClientClickHandler(row)}
  //               >
  //                 {row.clientName}
  //               </Link>
  //             )
  //           };
  //         }
  //       });

  //       console.log("updatedColumnsResponse", updatedColumnsResponse);
  //       setActivityLogReportListUpdatedColumns(updatedColumnsResponse);
  //       setActivityLogReportListUpdatedItems(updatedRows);
  //     setIsLoadingActivityLogReportList(false);
  //   console.log("ActivityLogReportListUpdatedColumns", ActivityLogReportListUpdatedColumns);
  //     }
  // }, [activityLogReportList]);

  // const savePdfAsFile = (pdfData: any, filename: any) => {
  //   const blob = new Blob([pdfData], { type: "application/pdf" });
  //   FileSaver.saveAs(blob, filename);
  //   console.log("pdfData",pdfData)
  //   console.log("blob",blob)
  //   // fileeeee = pdfData
  // };

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

 const activityLogReportListDataAPICall = (startDate:string,endDate:string,screen:string,user:string,activityType:string,clientName:any,params:IGetListRequest) => {
  const fetchData = async () => {
    setIsLoadingActivityLogReportList(true);
    setIsLoadingDesiredActivityLogReportList(true);
    try {
      const response: any = await ReportsService.getActivityLogReport(startDate,endDate,screen,user,activityType,clientName,params)
      console.log("responseData-----------------", response);
      setActivityLogReportResponseData(response)

      setActivityLogReportListUpdatedColumns(response?.headerColumns)
      setActivityLogReportListUpdatedItems(response?.items)
      // const updatedColumnsResponse = response.headerColumns.map(
      //   (item: any) => ({
      //     ...item,
      //     renderCell:
      //       item.field === "status" ? (params: any) => params.value : undefined,
      //   })
      // );

      // const updatedRows = response.items.map((row: any) => {

      //   if (row.status !== false && row.refNo && row.clientName) {
      //     return {
      //       ...row,
      //       status: (
      //         <span style={{ color: "green" }}>
      //           Paid
      //         </span>
      //       ),
      //       refNo: (
      //         <Link
      //           to={"/reports/invoice-detail"}
      //           className="no-underline"
      //           style={{ color: "blue" }}
      //           onClick={() => onRefNoClickHandler(row)}
      //         >
      //           {row.refNo}
      //         </Link>
      //       ),
      //       clientName: (
      //         <Link
      //           to={"/clients/dashboard"}
      //           className="no-underline"
      //           style={{ color: "blue" }}
      //           onClick={() => onClientClickHandler(row)}
      //         >
      //           {row.clientName}
      //         </Link>
      //       ),
      //     };
      //   } else {
      //     return {
      //       ...row,
      //       status: (
      //           <span style={{ color: "red" }}>
      //             Not Paid
      //           </span>
      //       ),
      //       refNo: (
      //         <Link
      //           to={"/reports/invoice-detail"}
      //           className="no-underline"
      //           style={{ color: "blue" }}
      //           onClick={() => onRefNoClickHandler(row)}
      //         >
      //           {row.refNo}
      //         </Link>
      //       ),
      //       clientName: (
      //         <Link
      //           to={"/clients/dashboard"}
      //           className="no-underline"
      //           style={{ color: "blue" }}
      //           onClick={() => onClientClickHandler(row)}
      //         >
      //           {row.clientName}
      //         </Link>
      //       )
      //     };
      //   }
      // });

      // console.log("updatedColumnsResponse", updatedColumnsResponse);
      // setInvoiceList(response);
      // setActivityLogReportListUpdatedColumns(updatedColumnsResponse);
      // setActivityLogReportListUpdatedItems(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoadingActivityLogReportList(false);
      setIsLoadingDesiredActivityLogReportList(false);
    }
  };
  console.log("ActivityLogReportListUpdatedColumns", ActivityLogReportListUpdatedColumns);
  fetchData();
 };
 const onSubmit = (formData: any) => {
    console.log("formData",formData)
    const formattedStartDate = formData.startDate !== null ? formatDate(formData.startDate) : "2024-01-01";
    const formattedEndDate = formData.endDate !== null ? formatDate(formData.endDate) : "2024-03-31";
    // console.log("formattedStartDate",formattedStartDate)
    // console.log("formattedEndDate",formattedEndDate)

    activityLogReportListDataAPICall(formattedStartDate,formattedEndDate,formData.screen === "all" ? null : formData.screen,formData.user === "all" ? null : formData.user,formData.activityType === "all" ? null : formData.activityType,clientName,queryParams)
    
  };

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  // const { isLoading: isDeleting, mutate: deleteInvoiceById } = useMutation(
  //   ReportsService.delAdminInvoices,
  //   {
  //     onSuccess: (res: object | IServerError | IResponse | any) => {
  //       console.log("res",res)
  //       if(res && res.status === 200){
  //         console.log("res inside",queryParams)
  //         setSnackbarData({
  //           open: true,
  //           message: res.data.message,
  //           severity: "success",
  //         });
  //         activityLogReportListDataAPICall(formattedStartDate,formattedEndDate,dateAppliesTo,paymentStatus,queryParams)
  //         console.log("res inside",queryParams)
  //       }else if (typeof res === "object") {
  //         // remove the cache of the Accountant fields since we just added a new field.
  //         setSnackbarData({
  //           open: true,
  //           message: res.message,
  //           severity: "success",
  //         });

  //         return queryClient.invalidateQueries(
  //           INVOICE_LIST_QUERY_KEY
  //         );
  //       } else {
  //         setSnackbarData({
  //           open: true,
  //           message: "Something went wrong, try again later!",
  //           severity: "error",
  //         });
  //       }
  //     },
  //     onError: (err:any) => {
  //       // TODO: customize the toast message based on the response
  //       setSnackbarData({
  //         open: true,
  //         message: err.message,
  //         severity: "error",
  //       });
  //     },
  //   }
  // );

  // const deleteInvoiceField = () => {
  //   if (multiDelete) {
  //     setMultiDelete(false);
  //     deleteInvoiceById(delRowsPayload && delRowsPayload);
  //     setDialogOpen(false);
  //   }
  // };

  // const onRowDeleteClicked = useCallback((params) => {
  //   setDialogOpen(true);
  //   setMultiDelete(true);
  //   console.log("paramsparamsparams",params)
  //   let refNoList:any = []
  //   params.map((item:any)=>{
  //     refNoList.push(item.refNo.props.children)
  //   })
  //   console.log("paramsparamsparams",refNoList)
  //   let payload = {
  //     "refNo": refNoList
  //   }
  //   setDelRowsPayload(payload)
  //   // alert("clicked")
  // }, []);

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:'',
      companyName:'',
      activityType:"",
      activityLogVisibility:false
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[])
  
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%", "& .p-datepicker":{zIndex:"2001"}}}>
      <BackdropCircularProgress
          open={
            // isDeleting || 
            isLoading || 
            isLoadingUserDDL
          }
          testId="T4 Slip List"
      />
      {/* <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={deleteInvoiceField}
          cancelActionHandler={onCloseHandler}
          testId="invoice-field-tab-confirmation-dialog"
        /> */}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <ActivityLogReportHeader isSubmitting={isLoadingDesiredActivityLogReportList} formMethods={formMethods} userDDL={userDDL}/>
        </form>
      </FormProvider>
      {/* <PrimeDataGridActivityLogReport
        rows={ActivityLogReportListUpdatedItems && ActivityLogReportListUpdatedItems}
        columns={
          activityLogReportResponseData && ActivityLogReportListUpdatedColumns
            ? ActivityLogReportListUpdatedColumns
            : []
        }
        showIndexColumn={true}
        showChecboxColumn={true}
        showSettingColumn={false}
        // onRowDeleteClicked={onRowDeleteClicked}
        isLoading={isLoadingActivityLogReportList}
        // onSnackbarMessageData={(value: any) => setSnackbarData(value)}
        totalRecords={activityLogReportResponseData && activityLogReportResponseData.total}
        initialPageSize={INITIAL_PAGE_SIZE}
        onRequestParamsChange={onRequestParamsChange}
      /> */}
      <PrimeDataGrid 
        rows={ActivityLogReportListUpdatedItems && ActivityLogReportListUpdatedItems}
        columns={
          activityLogReportResponseData && ActivityLogReportListUpdatedColumns
            ? ActivityLogReportListUpdatedColumns
            : []
        }
        showIndexColumn={true} 
        showChecboxColumn={false} 
        showSettingColumn={false}
        isLoading={isLoadingActivityLogReportList}
        totalRecords={activityLogReportResponseData && activityLogReportResponseData.total ? activityLogReportResponseData.total : 0}
        initialPageSize={INITIAL_PAGE_SIZE}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        searchFieldPlaceholder={"Search by Activity Type..."}
        onRequestParamsChange={onRequestParamsChange}
        disabledHeaderTooltip={true}
       />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`invoice-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
