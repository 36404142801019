import { IAccountantListPayload } from "models/accountant";
import { IClientListPayload } from "models/client";
import { call, put } from "redux-saga/effects";
import { AccountantService } from "services";
import {
  loadAccountantListRequestFailed,
  loadAccountantListRequestSucceeded,
  loadClientListRequestFailed,
  loadClientListRequestSucceeded,
} from "store/actions";

export function* getAccountants(action: any) {
  try {
    // const accountantResponse: IAccountantListPayload = yield call(
    //   AccountantService.getAccountants,
    //   action.payload
    // );

    // if (accountantResponse) {
    //   yield put(loadAccountantListRequestSucceeded(accountantResponse));
    // }
  } catch (err) {
    yield put(loadAccountantListRequestFailed());
  }
}

// export function* getClients(action: any) {
//   try {
//     const clientResponse: IClientListPayload = yield call(
//       AccountantService.getClients,
//       action.payload
//     );

//     if (clientResponse) {
//       yield put(loadClientListRequestSucceeded(clientResponse));
//     }
//   } catch (err) {
//     yield put(loadClientListRequestFailed());
//   }
// }
