import { IResponse, IServerError } from "@websential/cosmic";
import { AxiosError, AxiosResponse } from "axios";
import http from "common/http-common";
import { IAddDefaultSettingsRequest, IDefaultSettingsRequest, IDefaultSettingsResponse } from "models/DefaultSettings";

// TODO: replace this with API response once it's ready
export const DefaultSettingsService = {
  getDefaultSettings: async (
    clientId: string,
  ): Promise<any | IServerError | IResponse | null> => {
    try {
      const response: AxiosResponse<any> = await http.get<any>(
        `/v1/default-setting/?client_id=${clientId}`
      );
      console.log("response of selected row in t4 slip : ", response.data);
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error("An error occurred:", axiosError);
      return { status: false, message: axiosError.response ? axiosError.response.data : "Unknown error" };
    }
  },    
    add: async (requestPayload: IDefaultSettingsRequest): Promise<IDefaultSettingsResponse> => {
        let response: IDefaultSettingsResponse;
        return new Promise(resolve => setTimeout(() => resolve(response), 1000));
    },
    createDefaultSettings: async (
        requestPayload: IAddDefaultSettingsRequest
      ): Promise<IAddDefaultSettingsRequest | IServerError | IResponse> => {
        console.log("requestPayload", requestPayload);
        const response = await http.post<IAddDefaultSettingsRequest>(
          `/v1/default-setting/`,
          requestPayload
        );
        if (response.status === 200) {
          return {
            status: false,
            message: "Settings have been saved!",
            error: true,
          };
        } else if (response.status === 201) {
          return {
            status: false,
            message: "Settings have been saved!",
            error: true,
          };
        } else if (response.status === 500) {
          return {
            status: false,
            message: "Something went wrong.",
            error: true,
          };
        }    
        return {
          status: false,
          message: "Something went wrong please try again later.",
        };
      },
};
