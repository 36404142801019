import { AdjustmentsSetup } from "components/Adjustments";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const AdjustmentsSetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Adjustments"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<AdjustmentsSetup testId="adjustments-setup" />}
    />
  );
};
