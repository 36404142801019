import { STICKY_NAV_BAR } from "store/types/stickyNavBarTypes";

export const setSelectedStickyNavBar = (selectIsSticky: any) => {
  return {
    type: STICKY_NAV_BAR.STICKY_NAV_BAR_SET_SELECTED,
    payload: {...selectIsSticky}
  }
}

export const clearSelectedStickyNavBar = () => {
  return {
    type: STICKY_NAV_BAR.STICKY_NAV_BAR_CLEAR_SELECTED
  }
}
