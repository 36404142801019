import { AxiosResponse } from "axios";
import http from "common/http-common";
import { IGetListRequest, IResponse, IServerError } from "models/common";
import { IT4AdjustmentReportModel } from "models/t4AdjustmentReport/IT4AdjustmentReportModel";

export const T4AdjustmentReportService = {
  getT4AdjustmentReportNew: async (
    clientId: string,
    taxYear: number,
    params: IGetListRequest
  ): Promise<IT4AdjustmentReportModel | IServerError | IResponse> => {
  //   const fixedResponse: any = {
  //     "page": 1,
  //     "size": 50,
  //     "total": 1,
  //     "items": [
  //         {
  //             "id": "656efc00c300c9adbe24a4c5",
  //             "employee": "T4 T4",
  //             "poe": "NB",
  //             "adjusted": false,
  //             "gross": 60000.0,
  //             "pensionableEarnings": 60000.0,
  //             "cppEntered": 3500.0,
  //             "cppAdjusted": 3500.0,
  //             "cppDifference": 0.0,
  //             "insurableEarnings": 60000.0,
  //             "eiEntered": 3100.0,
  //             "eiAdjusted": 3100.0,
  //             "eiDifference": 0.0,
  //             "t4TaxEntered": 0.0,
  //             "t4TaxCalculated": 6600.0,
  //             "t4TaxAdjusted": 6600.0,
  //             "t4TaxDifference": 0.0,
  //             "totalDifference": 0.0
  //         }
  //     ],
  //     "sumData": [
  //         {
  //             "id": "",
  //             "employee": "Employee\nContribution",
  //             "cppDifference": 0.0,
  //             "eiDifference": 0.0,
  //             "t4TaxDifference": 0.0,
  //             "totalDifference": 0.0,
  //             "gross": 60000.0,
  //             "pensionableEarnings": 60000.0,
  //             "cppEntered": 3500.0,
  //             "cppAdjusted": 3500.0,
  //             "insurableEarnings": 60000.0,
  //             "eiEntered": 3100.0,
  //             "eiAdjusted": 3100.0,
  //             "t4TaxEntered": 0.0,
  //             "t4TaxCalculated": 6600.0,
  //             "t4TaxAdjusted": 6600.0
  //         },
  //         {
  //             "id": "",
  //             "employee": "Employer\nContribution",
  //             "cppDifference": 0.0,
  //             "eiDifference": 0.0,
  //             "t4TaxDifference": 0.0,
  //             "totalDifference": 0.0
  //         },
  //         {
  //             "id": "",
  //             "employee": "Reduced Ei\nEmployer\nContribution",
  //             "cppDifference": 0.0,
  //             "eiDifference": 0.0,
  //             "t4TaxDifference": 0.0,
  //             "totalDifference": 0.0
  //         },
  //         {
  //             "id": "",
  //             "employee": "Total",
  //             "cppDifference": 0.0,
  //             "eiDifference": 0.0,
  //             "t4TaxDifference": 0.0,
  //             "totalDifference": 0.0
  //         }
  //     ],
  //     "keyField": {
  //         "field": "name",
  //         "templateUrl": "/t4-slips/{id}"
  //     },
  //     "headerColumns": [
  //         {
  //             "field": "employee",
  //             "headerName": "Employee",
  //             "description": "Employee",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 0,
  //             "frozenColumn": true,
  //             "footer": null,
  //             "minWidth": 100,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "poe",
  //             "headerName": "POE",
  //             "description": "province of employment",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 1,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 60,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "adjusted",
  //             "headerName": "Adjusted",
  //             "description": "Adjusted",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 2,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 90,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "gross",
  //             "headerName": "Gross",
  //             "description": "Gross",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 3,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 70,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "pensionableEarnings",
  //             "headerName": "Pensionable\nEarnings",
  //             "description": "pensionable_earning",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 4,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 100,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "cppEntered",
  //             "headerName": "CPP\nEntered",
  //             "description": "CPP Entered",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 5,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 100,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "cppAdjusted",
  //             "headerName": "CPP\nAdjusted",
  //             "description": "CPP Adjusted",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 6,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 100,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "cppDifference",
  //             "headerName": "CPP\nDifference",
  //             "description": "CPP Difference",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 7,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 100,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "insurableEarnings",
  //             "headerName": "Insurable\nEarning",
  //             "description": "Insurable Earning",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 8,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 100,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "eiEntered",
  //             "headerName": "EI\nEntered",
  //             "description": "EI Entered",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 9,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 80,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "eiAdjusted",
  //             "headerName": "EI\nAdjusted",
  //             "description": "EI Adjusted",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 10,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 80,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "eiDifference",
  //             "headerName": "EI\nDifference",
  //             "description": "EI difference",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 11,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 90,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "t4TaxEntered",
  //             "headerName": "T4 Tax\nEntered",
  //             "description": "T4 Tax Entered",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 12,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 80,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "t4TaxCalculated",
  //             "headerName": "T4 Tax\nCalculated",
  //             "description": "T4 Tax Calculated",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 13,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 90,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "t4TaxAdjusted",
  //             "headerName": "T4 Tax\nAdjusted",
  //             "description": "T4 Tax Adjusted",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 14,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 90,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "t4TaxDifference",
  //             "headerName": "T4 Tax\nDifference",
  //             "description": "T4 Tax Difference",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 15,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 90,
  //             "alignFrozen": null
  //         },
  //         {
  //             "field": "totalDifference",
  //             "headerName": "Total\nDifference",
  //             "description": "Total Difference",
  //             "flex": 1,
  //             "hide": false,
  //             "hideable": false,
  //             "sortable": true,
  //             "sortingOrder": "asc",
  //             "resizable": false,
  //             "type": "text",
  //             "align": "left",
  //             "headerAlign": "left",
  //             "hideSortIcons": false,
  //             "disableColumnMenu": true,
  //             "disableReorder": true,
  //             "disableExport": true,
  //             "maskable": false,
  //             "isFixed": true,
  //             "sequence": 16,
  //             "frozenColumn": false,
  //             "footer": null,
  //             "minWidth": 100,
  //             "alignFrozen": null
  //         }
  //     ]
  // };
  //   return new Promise((resolve) =>
  //     setTimeout(() => resolve(fixedResponse), 1000)
  //   );

        const response: AxiosResponse<any> = await http.get<any>(
          // `/reports/t4-reports?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);
          `/v1/t4-adjustment-report/?client_id=${clientId}&tax_year=${taxYear}&slip_type=400&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`);

        if (response.status === 200) {
          console.log("response.data t4 report",response.data)
          return response.data;
        } else if (response.status === 400) {
          return {
            status: false,
            message: "Bad Request.",
            error: true,
          };
        } else if (response.status === 500) {
          return {
            status: false,
            message: "Something went wrong.",
            error: true,
          };
        }

        return {
          status: false,
          message: "Something went wrong please try again later.",
    };
  },
};
