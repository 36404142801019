import { IResponse, IServerError } from "@websential/cosmic";
import http from "common/http-common";
import { IAPIKeysRequest, IAddAPIKeysRequest } from "models/ApiKeys/IAPIKeysRequest";
import { IAPIKeysResponse } from "models/ApiKeys/IAPIKeysResponse";

// TODO: replace this with API response once it's ready
export const APIKeysService = {    
    add: async (requestPayload: IAPIKeysRequest): Promise<IAPIKeysResponse> => {
        let response: IAPIKeysResponse;
        return new Promise(resolve => setTimeout(() => resolve(response), 1000));
    },
    addApiKeys: async (
        requestPayload: IAddAPIKeysRequest
      ): Promise<IAddAPIKeysRequest | IServerError | IResponse> => {
        console.log("requestPayload", requestPayload);
        const response = await http.post<IAddAPIKeysRequest>(
          `/v1/clients/api-keys/`,
          requestPayload
        );
        if (response.status === 200) {
          return response.data;
        } else if (response.status === 500) {
          return {
            status: false,
            message: "Something went wrong.",
            error: true,
          };
        }    
        return {
          status: false,
          message: "Something went wrong please try again later.",
        };
      },
};
