import { AlertColor } from "@mui/material";
import { RESOURCEAPIMESSAGE } from "store/types/resourceApiMessageTypes";

export interface IResourceApiMessage {
  open:boolean;
  message:string;
  severity:AlertColor;
}

const initialState: IResourceApiMessage = {
  open:false,
  message:"",
  severity:"error"
};

export const resourceApiMessageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESOURCEAPIMESSAGE.RESOURCEAPIMESSAGE_SET_SELECTED:
      return {
        ...state,
        open: action.payload.open,
        message: action.payload.message,
        severity: action.payload.severity,
      }
    case RESOURCEAPIMESSAGE.RESOURCEAPIMESSAGE_CLEAR_SELECTED:
      return {
        ...state,
        open: initialState.open,
        message: initialState.message,
        severity: initialState.severity,
      }
    default:
      return state;
  }
};
