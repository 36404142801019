import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { T4Report } from "components/Reports/T4Report";

export interface IT4ReportPage {}

export const T4ReportPage: FC<IT4ReportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T4 Report"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<T4Report />}
    />
  );
};
