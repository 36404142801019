import { FieldLayout, ISinNumber, RHookFormTextField, SinNumber } from '@websential/cosmic';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// export interface ISinNumberRHF
//   extends Pick<ISinNumber, Exclude<keyof ISinNumber, "error" | "helperText">> { }

export const SinNumberRHF: FC<any> = ({
  id,
  label,
  required = false,
  defaultValue,
  labelPosition,
  testId,
  labelColumns,
  fieldColumns,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  console.log("defaultValueyoooooooooooo", defaultValue);

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue || ''} // Initialize with defaultValue or empty string
      render={({ field: { onChange, value, onBlur } }) => {
        const shouldMask =
          (defaultValue && defaultValue.charAt(3) === '*') ||
          (value && value.charAt(3) === '*');

        if (shouldMask) {
          return (
            <FieldLayout
              label={label}
              isRequired={required}
              testId={testId}
              labelPosition={labelPosition}
              labelColumns={labelColumns}
              fieldColumns={fieldColumns}
            >
              <RHookFormTextField
                id={id}
                fieldValue={"Unmask to view / edit"}
                defaultValue={"Unmask to view / edit"}
                placeholder={"Unmask to view / edit"}
                testId={id}
                uppercase={false}
                readOnly={true}
              />
            </FieldLayout>
          );
        }

        return (
          <SinNumber
            id={id}
            label={label}
            onChange={onChange}
            value={value} // Use the value from Controller
            onBlur={onBlur}
            required={required}
            labelPosition={labelPosition}
            error={errors[id]}
            helperText={errors[id]?.message}
            testId={testId}
            labelColumns={labelColumns}
            fieldColumns={fieldColumns}
          />
        );
      }}
    />
  );
};