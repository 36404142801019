import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { FC } from "react";

import { Grid } from "@mui/material";
import { ITest, RHookFormTextFieldWithLabel } from "@websential/cosmic";

interface IEISettingsQubecPanel extends ITest {}

export const EISettingsQubecPanel: FC<IEISettingsQubecPanel> = ({ testId }) => {
  return (
    <Grid
      container
      columnSpacing="6"
      rowSpacing="2"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Total EI Earnings (Maximum)"
          id="eiSettingsQubecTotalEiEarningsMaximum"
          testId={`${testId}-total-ei-earnings-maximum`}
        />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Basic EI Exemption (Maximum)"
          id="eiSettingsQubecBasicEiExemptionMaximum"
          testId={`${testId}-basic-ei-exemption-maximum`}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="Required Contribution (%)"
            id="eiSettingsQubecRequiredContributionPercentage"
            testId={`${testId}-required-contribution-percentage`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="Maximum Contribution"
            id="eiSettingsQubecMaximumContribution"
            testId={`${testId}-maximum-contribution`}
          />
        </FieldWrapper>
      </Grid>
    </Grid>
  );
};
