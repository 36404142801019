import { INITIAL_SNACKBAR_DATA, QUERY_OPTIONS, YEAR_SCREEN_SLUG } from "common/constants";
import { IResponse, IServerError, ITaxSlipYearRequestPayload } from "models";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TaxSlipYearService } from "services";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  ITest,
  LabelPosition,
  RHookFormTextFieldWithLabel,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
} from "@websential/cosmic";

import { YearSchema } from "./validator";
import { useNavigate, useParams } from "react-router-dom";
import { instanceOfIYearsResponce } from "common/instance-method";
import { Grid } from "@mui/material";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch } from "react-redux";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface ITaxSlipYearSetup extends ITest {}

// for edit
const YEARS_DATA_QUERY_KEY = "get-years-data";

const useGetData = (id: string | undefined) => {
  return useQuery(YEARS_DATA_QUERY_KEY, () => TaxSlipYearService.getById(id), {
    refetchOnWindowFocus: false,
  });
};

const DEFAULT_VALUES = {
  id: "",
  year: new Date().getFullYear(),
};

export const TaxSlipYearSetup: FC<ITaxSlipYearSetup> = ({ testId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  // for edit
  const queryClient = useQueryClient();
  const { id } = useParams();
  console.log("id ===================", id);

  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    TaxSlipYearService.create,
    {
      onSuccess: (
        res: ITaxSlipYearRequestPayload | IServerError | IResponse
      ) => {
        if (instanceOfIYearsResponce(res)) {
          queryClient.invalidateQueries([YEARS_DATA_QUERY_KEY, res.id]);
          setSnackbarData({
            open: true,
            message: "Taxslip year has been added successfully",
            severity: "success",
          });
        }
        let path = "/years/list";
        navigate(path);
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "An error occurred, please try again",
          severity: "error",
        });
      },
    }
  );

  // for edit
  const { isLoading: isLoadingData, data: selectedYearData } = useGetData(id);

  const formMethods = useForm<ITaxSlipYearRequestPayload>({
    resolver: yupResolver(YearSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = (formData: ITaxSlipYearRequestPayload) => {
    console.log("formData:Year", formData);
    let payload = {
      id: id ? id : undefined,
      year: formData.year,
    };
    submitFormData(payload);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset(DEFAULT_VALUES);
  };

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // for edit
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (selectedYearData) {
      Object.keys(selectedYearData).forEach((fieldName) => {
        console.log("fieldName useEffect Year : ", fieldName);
        // @ts-ignore
        const fieldValue = selectedYearData[fieldName];
        console.log("fieldValue useEffect Year : ", fieldValue);
        // @ts-ignore
        formMethods.setValue(fieldName, fieldValue ?? undefined);
      });
    } else {
      formMethods.setValue("year", DEFAULT_VALUES.year);
    }
  }, [selectedYearData, formMethods.setValue, formMethods]);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: YEAR_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={isSubmitting || isLoadingData}
        testId={`${testId}-backdrop`}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            children={
              <StandardPanel
                title="TaxSlip Year"
                testId={`${testId}-taxslip-year`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <RHookFormTextFieldWithLabel
                  id="year"
                  testId={`${testId}-year`}
                  label="Year"
                  labelPosition={LabelPosition.Left}
                  required
                />
              </StandardPanel>
            }
            toolbar={
              <StandardSubmitFormButtonsToolbar
                onResetClicked={onResetClicked}
                isSubmitting={false}
                testId="standard-form-submit-toolbar"
              />
            }
            testId="form-layout"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
