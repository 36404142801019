import {
  GET_QUERY_KEY,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  T4A_SETUP_SCREEN_ID,
  T4A_SETUP_SCREEN_SLUG,
  VENDOR_RESOURCE_LITERAL,
} from "common/constants";
import {
  instanceOfIFlexFieldSetupRequest,
  instanceOfIT4ASlipResponce,
} from "common/instance-method";
import { useGetResourceDDLData } from "hooks/useResources";
import { IResponse, IServerError } from "models/common";
import { IDropDownListResponse } from "models/common/IDropDownListResponse";
import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { FlexFieldService } from "services/FlexFieldService";
import { IAppState } from "store/reducers";
import FileSaver from "file-saver";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import {
  BackdropCircularProgress,
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  StandardPanel,
  TwoColumnLayout,
  DeleteConfirmationDialog,
} from "@websential/cosmic";
import {
  EnhancedDialog,
  EnhancedLinkButton,
  IconTextButton,
  RouterLinkButton,
  SplitButton,
} from "@websential/cosmic";
import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions";
import Card from "@mui/material/Card";
import { Divider } from "primereact/divider";
import { Avatar } from "primereact/avatar";
import "./T4ASlipSetupStyle.css";
import NativeSelect from "@mui/material/NativeSelect";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import {
  FilingDataSetupFieldsPanel,
  FilteredSelectedOtherInfoT4A,
  T4ASlipDataPanel,
  VendorAddressSetupFieldsPanel,
  VendorInfoSetupFieldsPanel,
} from "./Panels";
import { T4ASlipSetupToolbar } from "./T4ASlipSetupToolbar";
import { T4ASlipSetupValidationSchema } from "./validator";
import { T4ASlipService } from "services/T4ASlipService";
import { ICountryReferenceModel } from "models/country/ICountryReferenceModel";
import { useNavigate, useParams } from "react-router-dom";
import { IT4ASlipRequestPayload } from "models/t4aSlip/IT4ASlipRequestPayload";
import { T4SlipService } from "services/T4SlipService";
import { PrintingService } from "services/PrintingService";
import { T4AOtherInformationService } from "components/OtherInformation/otherInformations";
import { InvoiceDialogPanel } from "components/T4Slip/T4SlipList/InvoiceDialogPanel";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { DefaultSettingsService } from "services/DefaultSettingsService";
import { ClientService } from "services";
export let selectedT4ASlipType: any = 400;
export interface IT4ASlipSetup extends ITest {}

// const useGetFlexFieldData = (flexFieldId: number) =>
//   useQuery(
//     [FLEX_FIELD_DATA_QUERY_KEY, flexFieldId],
//     () => FlexFieldService.getFlexFieldById(flexFieldId),
//     { ...QUERY_OPTIONS, enabled: !!flexFieldId }
//   );
// const useGetFlexFieldsList = () =>
//   useQuery(
//     LIST_FIELDS_QUERY_KEY,
//     () => FlexFieldService.getFlexFieldDropDownList("none"),
//     QUERY_OPTIONS
//   );

const SINGLE_RECORD_DATA_QUERY_KEY = "t4a-slips-single-data-record";
const SINGLE_Employee_DATA_QUERY_KEY = "employee-data";
const DEFAULT_SETTINGS_DATA_QUERY_KEY = "default-settings-data-record";

const useGetData = (
  id: string | undefined,
  clientId: string,
  taxYear: number
) => {
  return useQuery(
    [SINGLE_RECORD_DATA_QUERY_KEY, id],
    () => T4ASlipService.getT4ASlipById(id, clientId, taxYear),
    QUERY_OPTIONS
  );
};

const useGetSlipRecord = (
  slipId: string,
  clientId: string,
  taxYear: number
) => {
  return useQuery(
    [GET_QUERY_KEY.EMPLOYEE_RESOURCE_SLIP_DETAIL, slipId],
    () => T4ASlipService.getById(slipId, clientId, taxYear),
    { ...QUERY_OPTIONS, enabled: false }
  );
};

const useGetResourceSlips = (
  resourceId: string,
  clientId: string,
  taxYear: number
) => {
  return useQuery(
    [GET_QUERY_KEY.EMPLOYEE_RESOURCE_SLIPS_DDL, resourceId],
    () =>
      T4ASlipService.getResourceSlipDropDownList(resourceId, clientId, taxYear),
    { ...QUERY_OPTIONS, enabled: false }
  );
};

const useGetResourceInformationById = (
  resourceId: string,
  clientId: string,
  roleName: string
) => {
  return useQuery(
    [SINGLE_Employee_DATA_QUERY_KEY, resourceId, clientId, roleName],
    () =>
      T4ASlipService.getResourceInformationById(resourceId, clientId, roleName),
    QUERY_OPTIONS
  );
};

const useGetDefaultSettingsData = (
  clientId: string,
) => 
  useQuery(
    [DEFAULT_SETTINGS_DATA_QUERY_KEY, clientId],
    (data) => {
      return DefaultSettingsService.getDefaultSettings(clientId);
    },
    { refetchOnWindowFocus: false }
  );

  const useGetSlipIds = (
    tag: string,
    clientId: string,
    taxYear: number
  ) =>
    useQuery(
      ["get-slip-ids", tag, clientId, taxYear],
      (data) => {
        return T4SlipService.getSlipIds(tag, clientId, taxYear);
      },
      { refetchOnWindowFocus: false }
    );

const useGetCompanyData = (id: string | undefined) => 
  useQuery(
    ["client-get-by-id", id],
    (data) => {
      return ClientService.getById(id);
    },
    { refetchOnWindowFocus: false }
  );

const parseJSONLikeString = (str: string) => {
  // Use regular expressions to add double quotes around keys and string values
  const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');

  // Convert the modified JSON string to an object using JSON.parse()
  return JSON.parse(modifiedString);
};

export const T4ASlipSetup: FC<IT4ASlipSetup> = ({ testId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientId = useSelector((state: IAppState) => state.client.id);
  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);
  // const queryClient = useQueryClient();
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );
  const [invoicePaymentFor, setInvoicePaymentFor] = useState(10)

  const [selectedResourceId, setSelectedResourceId] = useState("");
  const [selectedResource, setSelectedResource] = useState("create-new");
  const [selectedResourceSlip, setSelectedResourceSlip] =
    useState("create-new");

    const [onResourceSelectionAPIsCalls, setOnResourceSelectionAPIsCalls] =
    useState(false);

  const [selectedResourceSlipList, setSelectedResourceSlipList] = useState<
    IDropDownListResponse[]
  >([{ label: "Create New", value: "create-new" }]);
  const [openInvoiceDialog, setInvoiceDialogOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [isLoadingDataa, setIsLoadingData] = useState(false);
  const [
    requiredFliedsAccordingToPayeeType,
    setRequiredFliedsAccordingToPayeeType,
  ] = useState(true);
  const [resourceListWithoutCreateNew, setResourceListWithoutCreateNew] =
    useState<any>(undefined);

  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [itemSearch, setItemSearch] = useState("");
  const [sidePanelsVisibility, setSidePanelsVisibility] = useState(true);

  const [selectedResourceList, setSelectedResourceList] = useState<
    IDropDownListResponse[]
  >([{ label: "Create New", value: "create-new" }]);

  const [isLoadingAdjustedData, setIsLoadingAdjustedData] = useState(false);

  // const [newCreatedSlipId, setNewCreatedSlipId] = useState('');
  // const [newCreatedResourceId, setNewCreatedResourceId] = useState('');

  const [editedResourceId, setEditedResourceId] = useState("");
  const [selectedOtherInformation, setSelectedOtherInformation] =
    useState(false);
  // const [editedRecordSlipCreateNew, setEditedRecordSlipCreateNew] = useState(false);

  // for delete row
  const [openDialog, setDialogOpen] = useState(false);
  const [apiAction, setApiAction] = useState("");
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [t4ASlipId, setT4ASlipId] = useState<any>("");
  const [oiInEdit, setOiInEdit] = useState(false);

  //one state which store both new cretaed and edited slip data
  const [T4ASlipRecord, setT4ASlipRecord] = useState<any>(undefined);
  // when  I setValue of otherinformation inside async calls method then facing issue while click on add more so we have to use state method to show otherinformation 
  const [slipOtherInformation, setSlipOtherInformation] =
  useState<any>([]);

  // const queryClient = useQueryClient();
  console.log("selectedResourceSlip", selectedResourceSlip);

  const [CountryResponse] = useState<ICountryReferenceModel>({
    id: 1,
    name: "Canada",
    shortCode: "CAN",
  });

  // for edit
  const queryClient = useQueryClient();
  const { id } = useParams();
  console.log("id from setup file which clicked : ", id);
  let notStartedSlipId = "";
  let completedSlipId = "";
  if (id !== undefined && onResourceSelectionAPIsCalls === false) {
    if (id?.length > 24) {
      notStartedSlipId = id;
    }
    if (id?.length === 24) {
      completedSlipId = id;
    }
  }

  const DEFAULT_VALUES = {
    clientId: clientId,
    taxYear: taxYear,
    //   comparison: [
    //     {
    //       operator: undefined,
    //       field: undefined,
    //     },
    //   ],
    //   fieldType: undefined,
    //   fieldLabel: "",
    //   fieldId: "",
    //   defaultValue: "",
    //   placeholder: "",
    //   fieldFormat: "",
    //   fieldValue: "",
    //   dataType: undefined,
    //   fieldColumn: undefined,
    //   parentRelation: undefined,
    //   fieldInternalValue: "",
    //   notes: "",
    //   minValue: 0,
    //   maxValue: 0,
    //   isCategory: false,
    //   isFixed: false,
    //   isHide: false,
    //   isRequired: false,
    //   parentValue: "",
    //   parent: undefined,
    //   id: undefined,
  };

  // Function to sort the data array by the label field
// sort side cards items 
const sortData = (data: IDropDownListResponse[]): IDropDownListResponse[] => {
  return data.sort((a, b) => a.label.localeCompare(b.label));
};

  const { isLoading: isSubmitting, mutate: submitFlexFields } = useMutation(
    T4ASlipService.create,
    {
      onSuccess: (
        res: IT4ASlipRequestPayload | IServerError | IResponse | any
      ) => {
        if (res.data && instanceOfIT4ASlipResponce(res.data)) {
          // remove the cache of the flex fields since we just added a new field.
          // queryClient.invalidateQueries(LIST_FIELDS_QUERY_KEY);
          // // [corner case] remove the cache of the field that has just been edited in-case the user re-selects it again
          // queryClient.invalidateQueries([FLEX_FIELD_DATA_QUERY_KEY, res.id]);
          // set the currently added/edited flex field as the selected field
          queryClient.invalidateQueries([SINGLE_RECORD_DATA_QUERY_KEY, res.id]);
          formMethods.setValue("id", res.data.id);
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
          refectchT4ASlipIds()

          if(res.status === 201 && selectedResourceSlip === "create-new"){
            (async () => {
              try {
                console.log("ïnside-=",selectedResourceSlip)
                const response: any =
                  await T4ASlipService.getResourceSlipDropDownList(
                    res.data?.resource?.externalId,
                    clientId,
                    taxYear
                  );
                  if (response && Array.isArray(response)) {
                    let resourceSlipList: IDropDownListResponse[] =
                      response.map((resourceSlip: IDropDownListResponse) => ({
                        label: resourceSlip.label,
                        value: resourceSlip.value,
                      }));

                    resourceSlipList.unshift({
                      label: "Create New",
                      value: "create-new",
                    });

                    console.log("resourceSlipListDDL", resourceSlipList);
                    setSelectedResourceSlipList(resourceSlipList);
                    formMethods.setValue(
                      "selectedSlip",
                      res.data.id
                    );
                    // createNewActionFromSlipDDL();
                    // formMethods.setValue("comparison", [
                    //   { amount: 0, code: "", label: "" },
                    // ]);
                    let path = `/taxslip/t4a/setup/${res.data.id}`;
                    navigate(path);
                  }
                } catch (error) {
                  console.error("Error fetching data:", error);
                }
              })();
            setOnResourceSelectionAPIsCalls(true)
            setSelectedResourceSlip(res.data.id)
          }
        } else {
          setSnackbarData({
            open: true,
            // message: "Something went wrong, try again later!",
            message: res.message ? res.message : res.data.message,
            severity: "error",
          });
        }
        //Load Vendor DDL
        const fetchData = async () => {
          try {
            const response: any = await T4ASlipService.getVendorDDL(
              clientId,
              VENDOR_RESOURCE_LITERAL,
              taxYear
            );
            console.log("responseData-----------------", response);

            if (response && Array.isArray(response)) {
              let resources: IDropDownListResponse[] = response.map(
                (resourceItem: IDropDownListResponse) => ({
                  // id: resourceItem.id,
                  label: resourceItem.label,
                  value: resourceItem.value,
                })
              );

              resources.unshift({
                label: "Create New",
                value: "create-new",
              });

              setSelectedResourceList(resources);
              console.log("resourcesresourcesresources", resources);
            }
            setResourceListWithoutCreateNew(sortData(response));
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };

        fetchData();

        //load slip Data
        if (res.data && res.data?.resource) {
          setT4ASlipRecord(res.data);
          setSelectedResource(res.data?.resource?.externalId);
          // Object.keys(res).forEach((fieldName) => {
          //   console.log("fieldName useEffect T4 Slip : ", fieldName);
          //   // @ts-ignore
          //   const fieldValue = res[fieldName];
          //   console.log("fieldValue useEffect T4 Slip : ", fieldValue);
          //   // @ts-ignore

          //   if (typeof fieldValue !== "object") {
          //     // @ts-ignore
          //     formMethods.setValue(fieldName, fieldValue);
          //   } else if (fieldValue !== null || undefined) {
          //     console.log("object");
          //     Object.keys(fieldValue).forEach((objFieldName: any) => {
          //       console.log("objFieldName useEffect T4 Slip : ", objFieldName);
          //       // @ts-ignore
          //       const objFieldValue = fieldValue[objFieldName];
          //       console.log("objFieldValue useEffect T4 Slip : ", objFieldValue);

          //       if (typeof objFieldValue !== "object") {
          //         // @ts-ignore
          //         formMethods.setValue(objFieldName, objFieldValue);
          //       }

          //     if (fieldName === "resource") {
          //       if (objFieldName === "externalId") {
          //         (async () => {
          //           try {
          //             const responseAssociationId = await T4SlipService.getRoleAssociationId(
          //               objFieldValue
          //             );

          //             console.log("responseData getRoleAssociationId : ", responseAssociationId.associationId);

          //             formMethods.setValue("selectedVendor", responseAssociationId.associationId);

          //         (async () => {
          //           try {
          //             const responseGetResId =
          //               await T4SlipService.getResourceActualId(responseAssociationId.associationId);
          //             console.log(
          //               "responseData////////",
          //               responseGetResId.resourceId
          //             );

          //             (async () => {
          //               try {
          //                 const response: any =
          //                   await T4ASlipService.getVendorSlipDropDownList(
          //                     responseGetResId.resourceId,
          //                     clientId,
          //                     taxYear
          //                   );
          //                 // console.log(
          //                 //   "responseData-----------------sx",
          //                 //   response[0].value
          //                 // );
          //                 // formMethods.setValue("selectedSlip", response[0].value);

          //                 if (response && Array.isArray(response)) {
          //                   let resourceSlipList: IDropDownListResponse[] =
          //                     response.map(
          //                       (resourceSlip: IDropDownListResponse) => ({
          //                         label: resourceSlip.label,
          //                         value: resourceSlip.value,
          //                       })
          //                     );

          //                   resourceSlipList.unshift({
          //                     label: "Create New",
          //                     value: "create-new",
          //                   });

          //                   console.log("resourceSlipListDDL", resourceSlipList);
          //                   setSelectedResourceSlipList(resourceSlipList);
          //                 }
          //               } catch (error) {
          //                 console.error("Error fetching data:", error);
          //               }
          //             })();
          //             // You can work with the data here or trigger other functions that depend on the fetched data
          //           } catch (error) {
          //             console.error("Error fetching data:", error);
          //           }
          //         })();

          //           } catch (error) {
          //             console.error("Error fetching data:", error);
          //           }
          //         })();
          //         setNewCreatedResourceId(objFieldValue)
          //       }
          //       }
          //       if (objFieldName === "province") {
          //         let id = objFieldValue.id;
          //         let name = objFieldValue.name;
          //         let shortCode = objFieldValue.shortCode;
          //         console.log("namename", name);

          //         formMethods.setValue(
          //           objFieldName,
          //           `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
          //         );
          //       }
          //       if (objFieldName === "provinceOfEmployment") {
          //         let id = objFieldValue.id;
          //         let name = objFieldValue.name;
          //         let shortCode = objFieldValue.shortCode;
          //         console.log("namename", name);

          //         formMethods.setValue(
          //           objFieldName,
          //           `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
          //         );
          //       }
          //       if (objFieldName === "country") {
          //         formMethods.setValue(
          //           objFieldName,
          //           objFieldValue.name ?? undefined
          //         );
          //       }

          //     });
          //   }
          //   if(fieldName === 'id'){
          //     setNewCreatedSlipId(fieldValue)
          //     formMethods.setValue("selectedSlip",fieldValue);
          //   }
          // });
        }
        // //set slip type to 400 because when we come back to slip without reload it will automatically be original
        selectedT4ASlipType = formMethods.getValues("slipType");

        // //otherinformation will update sselected items when its submitted
        setSelectedOtherInformation(true);
      },
      onError: (err: any) => {
        console.log("err", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          // message: "Something went wrong, try again later!",
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  //when cretae-new seleted from slip ddl
  const createNewActionFromSlipDDL = () => {
    // setNewCreatedSlipId('')
    //   if(id !== undefined){
    //     // window.location.href = "/taxslip/t4/setup";
    //     // completedSlipId = ''
    //     //when cretae new slected then id would be null so instead of PUT after this POST calls to cretae new slip to selected edited slip from t4slip list
    //     setEditedRecordSlipCreateNew(false)
    //   }
    //otherinformation will update selected items
    setSelectedOtherInformation(false);

    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 1000);

    formMethods.setValue("pensionOrSuperannuation", "0.00");
    formMethods.setValue("lumpSumPayments", "0.00");
    formMethods.setValue("lumpSumPaymentsOther", "0.00");
    formMethods.setValue("selfEmployedCommissions", "0.00");
    formMethods.setValue("incomeTaxDeducted", "0.00");
    formMethods.setValue("annuities", "0.00");
    formMethods.setValue("annuitiesOther", "0.00");
    formMethods.setValue("fees", "0.00");
    formMethods.setValue("slipStatus", 200);
    formMethods.setValue("provinceOfEmployment", undefined);
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    setSlipOtherInformation([])
  };

  // for edit

  //for that slips which are completed
  const { isLoading: isLoadingData, data: selectedT4ASlipsData } = useGetData(
    completedSlipId,
    clientId,
    taxYear
  );

  const { isLoading: isLoadingDefaultSettingsData, data: defaultSettingsData } = useGetDefaultSettingsData(clientId,);


  const { isLoading:loadingSlipIds, data:t4aSlipIds, refetch:refectchT4ASlipIds } = useGetSlipIds("T4A", clientId, taxYear);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  useEffect(()=>{
    console.log("companyGetByIdRecord11",companyGetByIdRecord)
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor ? parseInt(paymentFor.value) : 10)
    }
  },[companyGetByIdRecord])

  useEffect(() => {
    //get by id slip
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 2000);
    const fetchData = async () => {
      try {
        const response: any = await T4ASlipService.getT4ASlipById(
          completedSlipId,
          clientId,
          taxYear
        );
        console.log("responseData-----------------", response);
        setT4ASlipRecord(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedT4ASlipsData) {
      setT4ASlipRecord(selectedT4ASlipsData);
    }
  }, [selectedT4ASlipsData]);
  console.log("selectedT4ASlipsData=======", selectedT4ASlipsData);

  //for that slips which are not started yet
  const {
    isLoading: isLoadingNotStartedSlipId,
    data: selectedNotStartedResourceData,
    // refetch: refetchResourceSlips,
  } = useGetResourceInformationById(notStartedSlipId, clientId, "Vendor");
  console.log("resourceInfo", selectedNotStartedResourceData);

  // const useGetT4AOtherInformationList = () => {
  //   return useQuery(
  //     "get-t4a-itherinformation-list",
  //     (data) => {
  //       return T4AOtherInformationService.getT4AOI();
  //     },
  //     { refetchOnWindowFocus: false }
  //   );
  // };
  // const {
  //   isLoading: isLoadingT4AOtherInformationList,
  //   data: T4AOtherInformationList,
  // } = useGetT4AOtherInformationList();

  const [T4AOtherInformationList, setT4AOtherInformationList] =
    useState<any>(undefined);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingAdjustedData(true);
        const response: any = await T4AOtherInformationService.getT4AOI();
        setT4AOtherInformationList(response);
        } catch (error) {
          console.error("Error fetching data:", error);
        }finally {
          setIsLoadingAdjustedData(false);
        }
    };
    fetchData();
  }, []);

  // const { isLoading: isLoadingDropDownList, data: resourceList } =
  //   useGetResourceDDLData(clientId, VENDOR_RESOURCE_LITERAL);
  // console.log("resourceList", resourceList);

  // const {
  //   isLoading: isLoadingResourceSlips,
  //   data: resourceSlips,
  //   isFetching: reFetchingResourceSlipsData,
  //   refetch: refetchResourceSlips,
  // } = useGetResourceSlips(selectedResource, clientId, taxYear);
  // console.log("selectedResource resourceSlips", resourceSlips);

  // const {
  //   isLoading: isLoadingResourceInfo,
  //   data: resourceInfo,
  //   // refetch: refetchResourceSlips,
  // } = useGetResourceInformationById(selectedResourceId, clientId, "Vendor");
  // console.log("resourceInfo",resourceInfo)

  // const {
  //   isLoading: isLoadingSlipData,
  //   data: slipRecord,
  //   isFetching: reFetchingSlipData,
  //   refetch: refetchSlipRecord,
  // } = useGetSlipRecord(selectedResourceSlip, clientId, taxYear);
  // console.log("slipRecord", slipRecord);

  // const resourceListItems = useMemo(() => {
  //   if (resourceList && Array.isArray(resourceList)) {
  //     let resources: IDropDownListResponse[] = resourceList.map(
  //       (flexFieldPanel: IDropDownListResponse) => ({
  //         label: flexFieldPanel.label,
  //         value: flexFieldPanel.value,
  //       })
  //     );

  //     resources.unshift({
  //       label: "Create New",
  //       value: "create-new",
  //     });
  //     setSelectedResourceList(resourceList)
  //     return resources;
  //   }

  //   return [
  //     {
  //       label: "Create New",
  //       value: "create-new",
  //     },
  //   ];
  // }, [resourceList]);

  // const resourceSlipListItems = useMemo(() => {
  //   if (resourceSlips && Array.isArray(resourceSlips)) {
  //     let resourceSlipList: IDropDownListResponse[] = resourceSlips.map(
  //       (resourceSlip: IDropDownListResponse) => ({
  //         label: resourceSlip.label,
  //         value: resourceSlip.value,
  //       })
  //     );

  //     resourceSlipList.unshift({
  //       label: "Create New",
  //       value: "create-new",
  //     });
  //     setSelectedResourceSlipList(resourceSlipList);
  //     return resourceSlipList;
  //   }

  //   return [
  //     {
  //       label: "Create New",
  //       value: "create-new",
  //     },
  //   ];
  // }, [resourceSlips]);
  // console.log("resourceSlipListItems", resourceSlipListItems);

  const formMethods = useForm<any>({
    resolver: yupResolver(T4ASlipSetupValidationSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  // const [selectedFlexFieldId, selectedParent] = useWatch({
  //   control: formMethods.control,
  //   name: ["id", "parent"],
  // });
  // const { isLoading: isLoadingSelectedFlexField, data: selectedFlexFieldData } =
  //   useGetFlexFieldData(selectedFlexFieldId as number);

  /**
   * Handle when the form is submitted.
   *
   * @param {IT4ASlipRequestPayload} formData
   */
  const onSubmit = (formData: any) => {
    //province is in string format and by using this we convert that in object
    const jsonString = formData.province as string;
    // Custom parsing function to convert the JSON-like string to an object
    const parseJSONLikeString = (str: string) => {
      // Use regular expressions to add double quotes around keys and string values
      const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
      // Convert the modified JSON string to an object using JSON.parse()
      return JSON.parse(modifiedString);
    };
    // Convert the JSON-like string to an object
    const provinceObject = parseJSONLikeString(jsonString);
    console.log("provinceObject", provinceObject);

    //other Information
    const getValue = formMethods.getValues("comparison");
    const finalOtherInformatioItemsT4A = FilteredSelectedOtherInfoT4A.map(
      (item: any) => {
        const valueItem = getValue.find(
          (value: any) => value.code === item.code
        );
        const getLabelIfMissingInItem: any =
          T4AOtherInformationList &&
          T4AOtherInformationList.find(
            (value: any) => value.value === item.code
          );
        return {
          ...item,
          amount: valueItem.amount,
          label: getLabelIfMissingInItem.label,
        };
      }
    );

    console.log("finalOtherInformatioItemsT4A", finalOtherInformatioItemsT4A);
    console.log("selectedselectedT4ASlipsData12", selectedT4ASlipsData);
    console.log("selectedT4ASlipsData12", T4ASlipRecord);

    const requestPayload: any = {
      clientId: clientId,
      taxYear: taxYear,
      id:
        selectedT4ASlipsData && selectedT4ASlipsData.resource
          ? // && editedRecordSlipCreateNew === false
            selectedT4ASlipsData.id
            : T4ASlipRecord && T4ASlipRecord.id ? T4ASlipRecord.id
          :
            // : newCreatedSlipId !== '' && editedRecordSlipCreateNew === false ? newCreatedSlipId
            undefined,
      reflectInVendor: formData.reflectInVendor,
      slipType: formData.slipType,
      slipStatus: formData.slipStatus,
      otherInformation:
        formData.comparison[0].code !== "" ? finalOtherInformatioItemsT4A : [],
      resource: {
        // externalId:
        //   selectedResourceSlip !== "create-new"
        //     ? selectedResourceSlip
        //     : undefined,
        externalId:
          selectedResource !== "create-new"
            ? selectedResource
            : selectedT4ASlipsData && selectedT4ASlipsData.resource
            ? selectedT4ASlipsData.resource.externalId
            : // : newCreatedResourceId !== '' ? newCreatedResourceId
            selectedNotStartedResourceData && selectedNotStartedResourceData.id
            ? selectedNotStartedResourceData.id
            : undefined,
        // externalId:selectedResourceId !== "" ? selectedResourceId : undefined, //resource id of => //Ahmad 2 Ali
        firstName:
          formData.name1 && formData.name1.length > 0
            ? formData.name1
            : undefined,
        initials: formData.initials,
        lastName:
          formData.name2 && formData.name2.length > 0
            ? formData.name2
            : undefined,
        socialInsuranceNumber:
          formData.uniqueIdentifier && formData.uniqueIdentifier !== ""
            ? formData.uniqueIdentifier.replace(/-/g, "")
            : undefined,
        address: formData.addressLine1,
        city: formData.city,
        country: CountryResponse,
        postCode:
          formData.postalCode &&
          formData.postalCode.replace(/[a-z]/g, (match: string) =>
            match.toUpperCase()
          ),
        province: provinceObject, //when edit then it show n select so this technique is use bcz we cant assign fieldvalue according to field name by using direct object so first we change that oobject to string then we chnage that string to obj and send to payload
        email:
          formData.email !== "" && formData.email !== "ze*****3@gmail.com"
            ? formData.email
            : undefined,
        businessName:
          formData.businessName && formData.businessName.length > 0
            ? formData.businessName
            : undefined,
        // businessNumber: formData.businessNumber && formData.businessNumber.length > 0  ? formData.businessNumber.replace(/(\d{9})/, '$1R') : undefined,
        businessNumber:
          formData.businessNumber && formData.businessNumber.length > 0
            ? formData.businessNumber
            : undefined,
        payeeType: formData.payeeType,
      },
      slipData: {
        pensionOrSuperannuation: formData.pensionOrSuperannuation && formData.pensionOrSuperannuation !== null ? 
        parseFloat(formData.pensionOrSuperannuation.replace(/[$,]/g, '')) : 0,
        lumpSumPayments: formData.lumpSumPayments && formData.lumpSumPayments !==null ? 
        parseFloat(formData.lumpSumPayments.replace(/[$,]/g, '')) : 0,
        lumpSumPaymentsOther: formData.lumpSumPaymentsOther && formData.lumpSumPaymentsOther !==null ?
        parseFloat(formData.lumpSumPaymentsOther.replace(/[$,]/g, '')) : 0,
        selfEmployedCommissions: formData.selfEmployedCommissions && formData.selfEmployedCommissions !==null ?
        parseFloat(formData.selfEmployedCommissions.replace(/[$,]/g, '')) : 0,
        incomeTaxDeducted: formData.incomeTaxDeducted && formData.incomeTaxDeducted !==null ?
        parseFloat(formData.incomeTaxDeducted.replace(/[$,]/g, '')) : 0,
        annuities: formData.annuities && formData.annuities !== null ?
        parseFloat(formData.annuities.replace(/[$,]/g, '')) : 0,
        annuitiesOther: formData.annuitiesOther && formData.annuitiesOther !== null ?
        parseFloat(formData.annuitiesOther.replace(/[$,]/g, '')) : 0,
        fees: formData.fees && formData.fees ? 
        parseFloat(formData.fees.replace(/[$,]/g, '')) : 0,
        employerOfferedDentalBenefits:
          formData.employerOfferedDentalBenefits && formData.employerOfferedDentalBenefits !== ""
            ? formData.employerOfferedDentalBenefits
            : null,
      },
    };
    console.log("===-requestPayload t4slip ", requestPayload);
    console.log("formData--", formData);

    // submitFlexFields(formData);
    submitFlexFields(requestPayload);
  };

  const defaultSettingsCalls = () => {
    if(!id && !T4ASlipRecord && defaultSettingsData && defaultSettingsData.t4aDefaultSetting){
      console.log("t4aDefaultSetting",defaultSettingsData.t4aDefaultSetting.use)
      if (defaultSettingsData.t4aDefaultSetting.otherInformation) {
        if (defaultSettingsData.t4aDefaultSetting.otherInformation && defaultSettingsData.t4aDefaultSetting.otherInformation !== null && defaultSettingsData.t4aDefaultSetting.otherInformation.length !== 0) {
          setOiInEdit(true);
          formMethods.setValue("comparison", defaultSettingsData.t4aDefaultSetting.otherInformation);
          } 
          else {
            //   // setOiInEdit(true);
            formMethods.setValue("comparison", [
              { amount: 0, code: "", label: "" },
              ]);
        }
        }
        // if(defaultSettingsData.t4aDefaultSetting.use){
          console.log("defaultSettingsData.t4aDefaultSetting.use",defaultSettingsData.t4aDefaultSetting.use)
          formMethods.setValue('payeeType',defaultSettingsData.t4aDefaultSetting.use)
          if (defaultSettingsData.t4aDefaultSetting.use === true) {
            setRequiredFliedsAccordingToPayeeType(defaultSettingsData.t4aDefaultSetting.use);
          } else {
            setRequiredFliedsAccordingToPayeeType(defaultSettingsData.t4aDefaultSetting.use);
          }  
        // }
        if (defaultSettingsData.fillingDataAttribute) {
          if (defaultSettingsData.fillingDataAttribute.province) {
            let id = defaultSettingsData.fillingDataAttribute.province.id;
              let name = defaultSettingsData.fillingDataAttribute.province.name;
              let shortCode = defaultSettingsData.fillingDataAttribute.province.shortCode;
              formMethods.setValue(
                'province',
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
              );
          }
          if (defaultSettingsData.fillingDataAttribute.city) {
            formMethods.setValue('city',defaultSettingsData.fillingDataAttribute.city)
          }
        }
    }
  }

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset();
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    setSlipOtherInformation([])
    setSelectedResourceSlipList([{ label: "Create New", value: "create-new" }]);
    setSelectedResourceSlip("create-new");
    setSelectedOtherInformation(false);
    setSelectedResource("create-new");
    setSelectedCard(null);
    let path = `/taxslip/t4a/setup`;
    navigate(path);
    setT4ASlipRecord(undefined);
    setRequiredFliedsAccordingToPayeeType(true);
    defaultSettingsCalls()
  };

  // For Delete Row

  const { isLoading: isDeleting, mutate: deleteT4ASlipById } = useMutation(
    T4ASlipService.delT4ASlip,
    {
      onSuccess: (res: any | IServerError | IResponse) => {
        console.log("resonce for Delete row", res);
        if (typeof res === "string") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: "Record has been deleted successfully.",
            severity: "success",
          });
          setT4ASlipRecord(undefined);
          refectchT4ASlipIds()
        } else {
          setSnackbarData({
            open: true,
            // message: "Something went wrong, try again later!",
            message: res.message,
            severity: "error",
          });
        }

        //load Employee Data
        if (res) {
          const selectedVendor = formMethods.getValues("selectedVendor");
          console.log("selectedVendor", selectedVendor);
          if (selectedVendor !== "create-new") {
            // (async () => {
            //   try {
            //     setIsLoadingAdjustedData(true);
            //     setTimeout(() => {
            //       setIsLoadingAdjustedData(false);
            //     }, 1000);
            //     const response = await T4SlipService.getResourceActualId(
            //       selectedVendor
            //     );

                // setSelectedResourceId(String(response.resourceId));
                // const resourceId = response.resourceId;
                let path = `/taxslip/t4a/setup/${selectedVendor}`;
                navigate(path);
                const fetchData = async () => {
                  try {
                    const response: any =
                      await T4ASlipService.getResourceSlipDropDownList(
                        selectedVendor,
                        clientId,
                        taxYear
                      );
                    console.log("responseData-----------------", response);
                    if (response && Array.isArray(response)) {
                      let resourceSlipList: IDropDownListResponse[] =
                        response.map((resourceSlip: IDropDownListResponse) => ({
                          label: resourceSlip.label,
                          value: resourceSlip.value,
                        }));

                      resourceSlipList.unshift({
                        label: "Create New",
                        value: "create-new",
                      });

                      console.log("resourceSlipListDDL", resourceSlipList);
                      setSelectedResourceSlipList(resourceSlipList);
                      formMethods.setValue(
                        "selectedSlip",
                        resourceSlipList[0].value
                      );
                      createNewActionFromSlipDDL();
                      formMethods.setValue("comparison", [
                        { amount: 0, code: "", label: "" },
                      ]);
                    }
                  } catch (error) {
                    console.error("Error fetching data:", error);
                  }
                };

                fetchData();
            //   } catch (err) {
            //     console.log("err", err);
            //     // alert(err);
            //   }
            // })();
          }
        }

        //set slip type to 400 because when we come back to slip without reload it will automatically be original
        selectedT4ASlipType = formMethods.getValues("slipType");

        //otherinformation will update sselected items when its submitted
        setSelectedOtherInformation(true);
      },
      onError: (err: any) => {
        console.log("resonce for Delete row", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          // message: "Something went wrong, try again later!",
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  const deleteT4ASlipField = () => {
    if (singleDelete) {
      let payload = {
        t4ASlipId,
        clientId,
        taxYear,
      };
      setSingleDelete(false);
      deleteT4ASlipById(payload);
      setDialogOpen(false);
    }
  };

  const onDeleteClicked = () => {
    setDialogOpen(true);
    setSingleDelete(true);
    setApiAction("delete");
    if (
      selectedT4ASlipsData &&
      selectedT4ASlipsData.resource
      // && editedRecordSlipCreateNew === false
    ) {
      setT4ASlipId(selectedT4ASlipsData.id);
    }
    // else if (slipRecord && slipRecord.id){
    //   setT4ASlipId(slipRecord.id)
    // }
    // else if(newCreatedSlipId !== '' && editedRecordSlipCreateNew === false){
    //   setT4ASlipId(newCreatedSlipId)
    // }
    // setT4ASlipId(params.row.id);
    console.log("T4ASlipId in T4Slip List OnDeleteRowClicked function");

    // setSnackbarData({
    //   open: true,
    //   message: "Deleted successfully",
    //   severity: "success",
    // });
    // formMethods.reset();
  };

  const savePdfAsFile = (pdfData: any, filename: any) => {
    const blob = new Blob([pdfData], { type: "pdf;charset=utf-8" });
    FileSaver.saveAs(blob, filename);
  };
  const onPrintClicked = async (printType: any): Promise<void> => {
    setIsLoadingData(true);
    const maskedStatus: any = sessionStorage.getItem("maskEnabled");
    if (maskedStatus === "true") {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(t4aSlipIds && t4aSlipIds.generalAuditStatus !== null && t4aSlipIds.generalAuditStatus === true &&
              t4aSlipIds && t4aSlipIds.auditStatus !== null &&  t4aSlipIds.auditStatus === true){
              setIsLoadingData(true);
                try {
                  const jsonString = formMethods.getValues("province")
                    ? formMethods.getValues("province")
                    : ('{id:9, name: "Ontario",shortCode:"ON"}' as string);
                  const ProvinceOfEmploymentData =
                    parseJSONLikeString(jsonString);
                  // const response: any = await PrintingService.createSingleT4APdfPrint(clientId, editedResourceId, formMethods.getValues("slipType"), printType, ProvinceOfEmploymentData.shortCode, taxYear);
                  let pdfIds = `t4a_slip_ids=${id}`;
                  const response: any =
                    await PrintingService.createBulkT4APdfPrint(
                      clientId,
                      printType,
                      pdfIds,
                      taxYear
                    );
                  if (response.status === 400) {
                    setSnackbarData({
                      open: true,
                      message: response.data.message
                        ? response.data.message
                        : "Something went wrong.",
                      // message: "Please Unmask sensitive data for PDF printing.",
                      severity: "error",
                    });
                  } else {
                    const payeeType = formMethods.getValues("payeeType");
                    const name1 = formMethods.getValues("name1");
                    const name2 = formMethods.getValues("name2");
                    const businessName = formMethods.getValues("businessName");
                    if (payeeType === true) {
                      const filename = `T4Aslip-${name1} ${name2}-${printType}.pdf`;
                      savePdfAsFile(response, filename);
                    } else {
                      const filename = `T4Aslip-${businessName}-${printType}.pdf`;
                      savePdfAsFile(response, filename);
                    }
                  }
                  setIsLoadingData(false);
                } catch (err) {
                  console.log("err", err);
                  alert(err);
                }
              // }
              setIsLoadingData(false);
              } else {
                setIsLoadingData(false);
                setSnackbarData({
                open: true,
                message: (
                  <>
                    Please load Audit in the menu, press Run T4A Audit and clear all audit items to generate documents.{" "}
                    <a
                      href="https://appv3.taxslips.com/#/audit/filing"
                      style={{
                        color: "#aa1010",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to view
                    </a>
                  </>
                ),
                severity: "error",
                autoHide: false,
              });
            }

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (response.message === "Slips not found for invoice.") {
          // setIsLoadingData(true);
          // try {
          //   const filingAuditResponse: any =
          //     await T4ASlipService.getT4AAuditFiling(clientId, taxYear);
          //   console.log("filingAuditResponse", filingAuditResponse);
          //   if (
          //     filingAuditResponse &&
          //     filingAuditResponse.general_filling &&
          //     filingAuditResponse.general_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.general_filling",
          //       filingAuditResponse.general_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else if (
          //     filingAuditResponse &&
          //     filingAuditResponse.t4a_filling &&
          //     filingAuditResponse.t4a_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.t4a_filling",
          //       filingAuditResponse.t4a_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else {
          //     setIsLoadingData(true);
          if(t4aSlipIds && t4aSlipIds.generalAuditStatus !== null && t4aSlipIds.generalAuditStatus === true &&
            t4aSlipIds && t4aSlipIds.auditStatus !== null &&  t4aSlipIds.auditStatus === true){
            setIsLoadingData(true);
              try {
                const jsonString = formMethods.getValues("province")
                  ? formMethods.getValues("province")
                  : ('{id:9, name: "Ontario",shortCode:"ON"}' as string);
                const ProvinceOfEmploymentData =
                  parseJSONLikeString(jsonString);
                // const response: any = await PrintingService.createSingleT4APdfPrint(clientId, editedResourceId, formMethods.getValues("slipType"), printType, ProvinceOfEmploymentData.shortCode, taxYear);
                let pdfIds = `t4a_slip_ids=${id}`;
                const response: any =
                  await PrintingService.createBulkT4APdfPrint(
                    clientId,
                    printType,
                    pdfIds,
                    taxYear
                  );
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    // message: "Please Unmask sensitive data for PDF printing.",
                    severity: "error",
                  });
                } else {
                  const payeeType = formMethods.getValues("payeeType");
                  const name1 = formMethods.getValues("name1");
                  const name2 = formMethods.getValues("name2");
                  const businessName = formMethods.getValues("businessName");
                  if (payeeType === true) {
                    const filename = `T4Aslip-${name1} ${name2}-${printType}.pdf`;
                    savePdfAsFile(response, filename);
                  } else {
                    const filename = `T4Aslip-${businessName}-${printType}.pdf`;
                    savePdfAsFile(response, filename);
                  }
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
                alert(err);
              }
            // }
            setIsLoadingData(false);
            } else {
              setIsLoadingData(false);
              setSnackbarData({
              open: true,
              message: (
                <>
                  Please load Audit in the menu, press Run T4A Audit and clear all audit items to generate documents.{" "}
                  <a
                    href="https://appv3.taxslips.com/#/audit/filing"
                    style={{
                      color: "#aa1010",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Click here to view
                  </a>
                </>
              ),
              severity: "error",
              autoHide: false,
            });
          }
          // } catch (err: any) {
          //   setIsLoadingData(false);
          //   alert(err);
          // }
        } else if (response && response.id !== null) {
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    }
    }
  };

  const onUnPaidClickHandler = (clickedRow: any, tag:string) => {
    setIsLoadingData(true);
    // setTimeout(() => {
    //   setIsLoadingData(false);
    // }, 2500);
    const fetchData = async () => {
      try {
        const response: any =
          await ReportsService.paypalIntegrationOfUnpaidInvoice(
            clientId,
            clickedRow.refNo,
            tag
          );
        setIsLoadingData(false);
        console.log("responseData111-----------------", response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const onClickInvoiceNoHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };
  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // const t4aslipListItems = useMemo(() => {
  //   return [
  //     {
  //       label: "Create New",
  //       value: "Create New",
  //     },
  //   ];
  // }, []);

  // const otherInformationList = useMemo(() => {
  //   return [
  //     {
  //       label: "N/A",
  //       value: 0,
  //     },
  //     {
  //       label: "014 - Recipient's number",
  //       value: 14,
  //     },
  //     {
  //       label:
  //         "026 - Eligible retiring allowances (for 2009 and prior years only)",
  //       value: 26,
  //     },
  //     {
  //       label:
  //         "027 - Non-eligible retiring allowances (for 2009 and prior years only)",
  //       value: 27,
  //     },
  //   ];
  //   // if (!flexFieldsList) {
  //   //   return [];
  //   // }
  //   // return flexFieldsList.map((flexField) => ({
  //   //   label: flexField.fieldLabel,
  //   //   value: flexField.id,
  //   // }));
  // }, []);

  // set the values of the selected flex field coming from BE.
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (selectedFlexFieldData) {
  //     Object.keys(selectedFlexFieldData).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = selectedFlexFieldData[fieldName];
  //       // @ts-ignore
  //       formMethods.setValue(fieldName, fieldValue);
  //     });
  //   }
  // }, [selectedFlexFieldData, formMethods.setValue, formMethods]);

  // if we have selected the parent and a flex field and they both are the same, we should un-set the parent because a field cannot be the parent of itself
  // useEffect(() => {
  //   if (
  //     selectedFlexFieldId &&
  //     selectedParent &&
  //     selectedParent === selectedFlexFieldId
  //   ) {
  //     formMethods.setValue("parent", undefined);
  //   }
  // }, [formMethods, selectedFlexFieldId, selectedParent]);
  // set the values of the selected flex field coming from BE.
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (slipRecord) {
  //     Object.keys(slipRecord).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = slipRecord[fieldName];
  //       if (fieldName === "resource") {
  //         Object.keys(fieldValue).forEach((resourceFieldName) => {
  //           const resourceFieldValue = fieldValue[resourceFieldName];
  //           // @ts-ignore
  //           formMethods.setValue(resourceFieldName, resourceFieldValue);
  //         });
  //       } else if (fieldName === "slipData") {
  //         Object.keys(fieldValue).forEach((slipDataFieldName) => {
  //           const slipDataFieldValue = fieldValue[slipDataFieldName];
  //           // @ts-ignore
  //           formMethods.setValue(slipDataFieldName, slipDataFieldValue);
  //           console.log("slipDataFieldName : ",slipDataFieldName,"slipDataFieldValue : ",slipDataFieldValue)
  //         });
  //       } else {
  //         // @ts-ignore
  //         formMethods.setValue(fieldName, fieldValue);
  //       }
  //     });
  //   }
  // }, [slipRecord, formMethods.setValue, formMethods]);

  // set the values of the selected Slip from DDL coming from BE.
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (slipRecord) {
  //     Object.keys(slipRecord).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = slipRecord[fieldName];
  //       console.log(
  //         "fieldNameslipRecord",
  //         fieldName,
  //         " && ",
  //         "fieldValueslipRecord ",
  //         fieldValue
  //       );
  //       if (typeof fieldValue !== "object") {
  //         // @ts-ignore
  //         formMethods.setValue(fieldName, fieldValue);
  //       }
  //       if (fieldName === "resource") {
  //         Object.keys(fieldValue).forEach((resourceFieldName) => {
  //           const resourceFieldValue = fieldValue[resourceFieldName];
  //           // @ts-ignore
  //           formMethods.setValue(resourceFieldName, resourceFieldValue);
  //           console.log(
  //             "resourceFieldName : ",
  //             resourceFieldName,
  //             "resourceFieldValue : ",
  //             resourceFieldValue
  //           );
  //           if (resourceFieldName === "country") {
  //             formMethods.setValue(
  //               resourceFieldName,
  //               resourceFieldValue.name ?? undefined
  //             );
  //           }
  //           if (resourceFieldName === "province") {
  //               let id = resourceFieldValue.id;
  //               let name = resourceFieldValue.name;
  //               let shortCode = resourceFieldValue.shortCode;
  //               console.log("namename", name);

  //               formMethods.setValue(
  //                 resourceFieldName,
  //                 // '{id:9, name: "Ontario",shortCode:"ON"}'
  //                 `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
  //               );
  //             }
  //         });
  //       }
  //       if (fieldName === "slipData") {
  //         Object.keys(fieldValue).forEach((slipDataFieldName) => {
  //           const slipDataFieldValue = fieldValue[slipDataFieldName];
  //           // @ts-ignore
  //           formMethods.setValue(slipDataFieldName, slipDataFieldValue);
  //           console.log(
  //             "slipDataFieldName : ",
  //             slipDataFieldName,
  //             "slipDataFieldValue : ",
  //             slipDataFieldValue
  //           );
  //         });
  //       }

  //       //set slip type according to selected slip for submit button action
  //       if(fieldName === 'slipType'){
  //         selectedT4ASlipType = fieldValue
  //         setIsLoadingAdjustedData(true);
  //         setTimeout(() => {
  //           setIsLoadingAdjustedData(false);
  //         }, 1000);
  //       }
  //       if(fieldName === 'otherInformation'){
  //         if(fieldValue.length !== 0){
  //           formMethods.setValue('comparison',fieldValue)
  //         } else{
  //           formMethods.setValue('comparison',[{ amount: 0, code: '', label:''}])
  //         }
  //       }
  //     });
  //   }
  // }, [slipRecord, formMethods.setValue, formMethods]);

  // //resource info by using selected resource id
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (resourceInfo) {
  //     // formMethods.reset();
  //     console.log("resourceInforesourceInfo", resourceInfo);
  //     Object.keys(resourceInfo).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = resourceInfo[fieldName];
  //       console.log(
  //         "fieldNamefieldName",
  //         fieldName,
  //         " && ",
  //         "fieldValuefieldValue ",
  //         fieldValue
  //       );
  //       formMethods.setValue(fieldName, fieldValue);

  //       if (fieldName === "address") {
  //         Object.keys(fieldValue).forEach((resourceFieldName) => {
  //           const resourceFieldValue = fieldValue[resourceFieldName];
  //           // @ts-ignore
  //           console.log(
  //             "resourceFieldName : ",
  //             resourceFieldName,
  //             "resourceFieldValue : ",
  //             resourceFieldValue
  //           );
  //           // formMethods.setValue(resourceFieldName, resourceFieldValue);
  //           Object.keys(resourceFieldValue).forEach((resourceFieldNameNew) => {
  //             const resourceFieldValueNew =
  //               resourceFieldValue[resourceFieldNameNew];
  //             // @ts-ignore
  //             console.log(
  //               "resourceFieldNameNew : ",
  //               resourceFieldNameNew,
  //               "resourceFieldValue : ",
  //               resourceFieldValueNew
  //             );
  //             formMethods.setValue(resourceFieldNameNew, resourceFieldValueNew);

  //             if (resourceFieldNameNew === "country") {
  //               formMethods.setValue(
  //                 resourceFieldNameNew,
  //                 resourceFieldValueNew.name ?? undefined
  //               );
  //             }
  //             if (resourceFieldNameNew === "province") {
  //               let id = resourceFieldValueNew.id;
  //               let name = resourceFieldValueNew.name;
  //               let shortCode = resourceFieldValueNew.shortCode;

  //               console.log("namenamenmae", name);

  //               formMethods.setValue(
  //                 resourceFieldNameNew,
  //                 `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
  //                 // '{id:1, name: "Alberta",shortCode:"AB"}'
  //               );
  //             }
  //           });
  //         });
  //       } else {
  //         // @ts-ignore
  //         formMethods.setValue(fieldName, fieldValue);
  //       }
  //       if(fieldName === 'metaFields'){
  //         fieldValue.forEach((obj:any) => {
  //           formMethods.setValue(obj.name,obj.value)
  //           // if(obj.name === 'initial' || obj.name === 'INITIALS'){
  //           //   formMethods.setValue('initials',obj.value)
  //           // }
  //           if(obj.name === 'payee_type'){
  //             formMethods.setValue('payeeType',obj.value)
  //           }
  //           console.log(`"objobj" : ${obj.name} ====== objValue : ${obj.value}`)
  //         })
  //       }
  //     });
  //     formMethods.setValue("selectedSlip", "create-new");
  //     formMethods.setValue("pensionOrSuperannuation", "0.00");
  //     formMethods.setValue("lumpSumPayments", "0.00");
  //     formMethods.setValue("lumpSumPaymentsOther", "0.00");
  //     formMethods.setValue("selfEmployedCommissions", "0.00");
  //     formMethods.setValue("incomeTaxDeducted", "0.00");
  //     formMethods.setValue("annuities", "0.00");
  //     formMethods.setValue("annuitiesOther", "0.00");
  //     formMethods.setValue("fees", "0.00");
  //     formMethods.setValue("slipStatus", 200);
  //     formMethods.setValue('comparison',null);
  //   }
  // }, [resourceInfo, formMethods.setValue, formMethods]);

  // const onDateOfBirthChangeHandler = (selectedDate: any) => {
  //   console.log("onDateOfBirthChangeHandler");
  // };

  const onResourceSelectionChangeHandler = async (
    selectedItem: IEnhancedDropDownItem | any,
    index?: any
  ): Promise<void> => {
    // setSelectedResource(String(selectedItem.value));
    console.log("selectedItem.value", selectedItem.value);
    //this is for card selection
    setSelectedCard(index);
    setSelectedResourceSlip("create-new");

    if (!index) {
      //this is for card selection
      setSelectedCard(selectedItem.value);
      setSelectedResourceSlip("create-new");
    }
    if (selectedItem.value === "create-new") {
      setSnackbarData({
        open: true,
        message: "Please load a payee for T4A Slip",
        severity: "error",
      });
      setSidePanelsVisibility((prevButtonState: any) => !prevButtonState);
      formMethods.reset();
      // setNewCreatedResourceId('')
      // setNewCreatedSlipId('')
      setSelectedResourceSlipList([
        { label: "Create New", value: "create-new" },
      ]);
      setSelectedResourceSlip("create-new");
      formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
      setSlipOtherInformation([])
      //otherinformation will update selected items
      setSelectedOtherInformation(false);
      // if(id !== undefined){
      // window.location.href = "/taxslip/t4a/setup";
      // }
      let path = `/taxslip/t4a/setup`;
      navigate(path);
      setT4ASlipRecord(undefined);
      setIsLoadingAdjustedData(true);
      setTimeout(() => {
        setIsLoadingAdjustedData(false);
      }, 1000);
      setSelectedResource("create-new");
    } else {
      setOnResourceSelectionAPIsCalls(true);
      // try {
      //   const responseGetResourceActualId =
      //     await T4SlipService.getResourceActualId(selectedItem.value);

        // console.log("responseData",responseGetResourceActualId.resourceId)
        // setSelectedResource(String(responseGetResourceActualId.resourceId));
        // // setSelectedResourceId(String(responseGetResourceActualId.resourceId));
        // if (responseGetResourceActualId.resourceId !== "create new"){
        //   setEditedResourceId(String(responseGetResourceActualId.resourceId));
        // }
        // let path = `/taxslip/t4a/setup/${responseGetResourceActualId.resourceId}`;
        // navigate(path);
        (async () => {
          try {
            setIsLoadingAdjustedData(true);
            let resourceDataById =
              await T4ASlipService.getResourceInformationById(
                selectedItem.value,
                clientId,
                "Vendor"
              );
            console.log("resourceDataById", resourceDataById);
            if (resourceDataById) {
              Object.keys(resourceDataById).forEach((fieldName) => {
                // @ts-ignore
                const fieldValue = resourceDataById[fieldName];
                console.log(
                  "fieldNamefieldName",
                  fieldName,
                  " && ",
                  "fieldValuefieldValue ",
                  fieldValue
                );
                formMethods.setValue(
                  fieldName,
                  fieldValue !== null && fieldValue !== "" ? fieldValue : ""
                );

                if (fieldName === "email") {
                  if (fieldValue !== null) {
                    formMethods.setValue(fieldName, fieldValue);
                  } else {
                    const maskedStatus: any =
                      sessionStorage.getItem("maskEnabled");
                    if (maskedStatus === "true") {
                      formMethods.setValue(fieldName, "ze*****3@gmail.com");
                    } else {
                      formMethods.setValue(fieldName, "");
                    }
                  }
                }
                if (fieldName === "address") {
                  Object.keys(fieldValue).forEach((resourceFieldName) => {
                    const resourceFieldValue = fieldValue[resourceFieldName];
                    // @ts-ignore
                    console.log(
                      "resourceFieldName : ",
                      resourceFieldName,
                      "resourceFieldValue : ",
                      resourceFieldValue
                    );
                    Object.keys(resourceFieldValue).forEach(
                      (resourceFieldNameNew) => {
                        const resourceFieldValueNew =
                          resourceFieldValue[resourceFieldNameNew];
                        // @ts-ignore
                        console.log(
                          "resourceFieldNameNew : ",
                          resourceFieldNameNew,
                          "resourceFieldValue : ",
                          resourceFieldValueNew
                        );
                        formMethods.setValue(
                          resourceFieldNameNew,
                          resourceFieldValueNew
                        );

                        if (resourceFieldNameNew === "country") {
                          formMethods.setValue(
                            resourceFieldNameNew,
                            resourceFieldValueNew.name ?? undefined
                          );
                        }
                        if (resourceFieldNameNew === "province") {
                          let id = resourceFieldValueNew.id;
                          let name = resourceFieldValueNew.name;
                          let shortCode = resourceFieldValueNew.shortCode;

                          console.log("namenamenmae", name);

                          formMethods.setValue(
                            resourceFieldNameNew,
                            `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                            // '{id:1, name: "Alberta",shortCode:"AB"}'
                          );
                        }
                      }
                    );
                  });
                }
                if (fieldName === "uniqueIdentifier") {
                  const formattedNumber =
                    fieldValue !== null
                      ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
                      : undefined;
                  formMethods.setValue("uniqueIdentifier", formattedNumber);
                }

                if (fieldName === "name1") {
                  formMethods.setValue(
                    "name1",
                    fieldValue !== null ? fieldValue : undefined
                  );
                }
                // }
                if (fieldName === "metaFields") {
                  fieldValue.forEach((obj: any) => {
                    formMethods.setValue(obj.name, obj.value);
                    // if(obj.name === 'initial' || obj.name === 'INITIALS'){
                    //   formMethods.setValue('initials',obj.value)
                    // }
                    if (obj.name === "payeeType") {
                      if (obj.value === true) {
                        formMethods.setValue("payeeType", true);
                        setRequiredFliedsAccordingToPayeeType(true);
                      } else {
                        formMethods.setValue("payeeType", false);
                        setRequiredFliedsAccordingToPayeeType(false);
                      }
                    }
                    console.log(
                      `"objobj" : ${obj.name} ====== objValue : ${obj.value}`
                    );
                  });
                }
                if (fieldName === "id") {
                  // (async () => {
                  //   try {
                  //     const responseAssociationId =
                  //       await T4SlipService.getRoleAssociationId(fieldValue);

                  //     console.log(
                  //       "responseData getRoleAssociationId : ",
                  //       responseAssociationId.associationId
                  //     );

                      formMethods.setValue(
                        "selectedVendor",
                        fieldValue
                      );

                      // (async () => {
                      //   try {
                      //     const responseGetResId =
                      //       await T4SlipService.getResourceActualId(
                      //         responseAssociationId.associationId
                      //       );
                      //     console.log(
                      //       "responseData////////",
                      //       responseGetResId.resourceId
                      //     );

                          (async () => {
                            try {
                              const response: any =
                                await T4ASlipService.getResourceSlipDropDownList(
                                  fieldValue,
                                  clientId,
                                  taxYear
                                );
                              console.log(
                                "responseData----------------- inside selectedNotStartedResourceData",
                                response
                              );
                              // formMethods.setValue("selectedSlip", response[0].value);

                              if (response && Array.isArray(response)) {
                                let resourceSlipList: IDropDownListResponse[] =
                                  response.map(
                                    (resourceSlip: IDropDownListResponse) => ({
                                      label: resourceSlip.label,
                                      value: resourceSlip.value,
                                    })
                                  );

                                resourceSlipList.unshift({
                                  label: "Create New",
                                  value: "create-new",
                                });

                                console.log(
                                  "resourceSlipListDDL",
                                  resourceSlipList
                                );
                                setSelectedResourceSlipList(resourceSlipList);
                                if (
                                  resourceSlipList &&
                                  resourceSlipList.length > 1
                                ) {
                                  (async () => {
                                    try {
                                      console.log(
                                        "slipssss",
                                        resourceSlipList[1].value
                                      );
                                      setIsLoadingAdjustedData(true);
                                      let slipDataById =
                                        await T4ASlipService.getT4ASlipById(
                                          String(resourceSlipList[1].value),
                                          clientId,
                                          taxYear
                                        );
                                        setT4ASlipRecord(slipDataById)
                                      setSelectedResourceSlip(
                                        String(resourceSlipList[1].value)
                                      );
                                      formMethods.setValue(
                                        "selectedSlip",
                                        String(resourceSlipList[1].value)
                                      );
                                      console.log("slipDataById", slipDataById);
                                      if (slipDataById) {
                                        Object.keys(slipDataById).forEach(
                                          (fieldName) => {
                                            console.log(
                                              "fieldName useEffect T4 Slip : slipDataById",
                                              fieldName
                                            );
                                            // @ts-ignore
                                            const fieldValue =
                                              slipDataById[fieldName];
                                            console.log(
                                              "fieldValue useEffect T4 Slip : ",
                                              fieldValue
                                            );
                                            // @ts-ignore

                                            if (
                                              typeof fieldValue !== "object"
                                            ) {
                                              // @ts-ignore
                                              formMethods.setValue(
                                                fieldName,
                                                fieldValue ?? undefined
                                              );
                                            } else if (
                                              fieldValue !== null ||
                                              undefined
                                            ) {
                                              console.log("object");
                                              Object.keys(fieldValue).forEach(
                                                (objFieldName: any) => {
                                                  console.log(
                                                    "objFieldName useEffect T4 Slip : ",
                                                    objFieldName
                                                  );
                                                  // @ts-ignore
                                                  const objFieldValue =
                                                    fieldValue[objFieldName];
                                                  console.log(
                                                    "objFieldValue useEffect T4 Slip : ",
                                                    objFieldValue
                                                  );

                                                  if (
                                                    typeof objFieldValue !==
                                                    "object"
                                                  ) {
                                                    // @ts-ignore
                                                    formMethods.setValue(
                                                      objFieldName,
                                                      objFieldValue
                                                    );
                                                  }
                                                  if (
                                                    fieldName === "resource"
                                                  ) {
                                                    if (
                                                      objFieldName ===
                                                      "externalId"
                                                    ) {
                                                      // console.log("objFieldValue : externalid ", objFieldValue);
                                                      // let roleAssociationId = "d0a41913-11f3-4f00-a824-9977909bdd04";
                                                      // (async () => {
                                                      //   try {
                                                      //     const responseAssociationId =
                                                      //       await T4SlipService.getRoleAssociationId(
                                                      //         objFieldValue
                                                      //       );

                                                      //     console.log(
                                                      //       "responseData getRoleAssociationId : ",
                                                      //       responseAssociationId.associationId
                                                      //     );

                                                          formMethods.setValue(
                                                            "selectedVendor",
                                                            objFieldValue
                                                          );

                                                          // (async () => {
                                                          //   try {
                                                          //     const responseGetResId =
                                                          //       await T4SlipService.getResourceActualId(
                                                          //         responseAssociationId.associationId
                                                          //       );
                                                          //     console.log(
                                                          //       "responseData////////",
                                                          //       responseGetResId.resourceId
                                                          //     );

                                                              (async () => {
                                                                try {
                                                                  const response: any =
                                                                    await T4ASlipService.getResourceSlipDropDownList(
                                                                      objFieldValue,
                                                                      clientId,
                                                                      taxYear
                                                                    );
                                                                  if (
                                                                    response &&
                                                                    Array.isArray(
                                                                      response
                                                                    )
                                                                  ) {
                                                                    let resourceSlipList: IDropDownListResponse[] =
                                                                      response.map(
                                                                        (
                                                                          resourceSlip: IDropDownListResponse
                                                                        ) => ({
                                                                          label:
                                                                            resourceSlip.label,
                                                                          value:
                                                                            resourceSlip.value,
                                                                        })
                                                                      );

                                                                    resourceSlipList.unshift(
                                                                      {
                                                                        label:
                                                                          "Create New",
                                                                        value:
                                                                          "create-new",
                                                                      }
                                                                    );

                                                                    console.log(
                                                                      "resourceSlipListDDL inside slipDataById",
                                                                      resourceSlipList
                                                                    );
                                                                    setSelectedResourceSlipList(
                                                                      resourceSlipList
                                                                    );
                                                                  }
                                                                } catch (error) {
                                                                  console.error(
                                                                    "Error fetching data:",
                                                                    error
                                                                  );
                                                                }
                                                              })();
                                                              // You can work with the data here or trigger other functions that depend on the fetched data
                                                      //       } catch (error) {
                                                      //         console.error(
                                                      //           "Error fetching data:",
                                                      //           error
                                                      //         );
                                                      //       }
                                                      //     })();
                                                      //   } catch (error) {
                                                      //     console.error(
                                                      //       "Error fetching data:",
                                                      //       error
                                                      //     );
                                                      //   }
                                                      // })();
                                                      setSelectedResource(
                                                        objFieldValue
                                                      );
                                                    }
                                                    setEditedResourceId(
                                                      fieldValue.externalId
                                                    );
                                                    if (
                                                      objFieldName === "email"
                                                    ) {
                                                      if (
                                                        objFieldValue !== null
                                                      ) {
                                                        formMethods.setValue(
                                                          objFieldName,
                                                          objFieldValue
                                                        );
                                                      } else {
                                                        const maskedStatus: any =
                                                          sessionStorage.getItem(
                                                            "maskEnabled"
                                                          );
                                                        console.log(
                                                          "elesese",
                                                          maskedStatus
                                                        );
                                                        if (
                                                          maskedStatus ===
                                                          "true"
                                                        ) {
                                                          formMethods.setValue(
                                                            objFieldName,
                                                            "ze*****3@gmail.com"
                                                          );
                                                        } else {
                                                          formMethods.setValue(
                                                            objFieldName,
                                                            ""
                                                          );
                                                        }
                                                      }
                                                    }
                                                  }
                                                  if (
                                                    objFieldName === "province"
                                                  ) {
                                                    let id = objFieldValue.id;
                                                    let name =
                                                      objFieldValue.name;
                                                    let shortCode =
                                                      objFieldValue.shortCode;
                                                    console.log(
                                                      "namename",
                                                      name
                                                    );

                                                    formMethods.setValue(
                                                      objFieldName,
                                                      // '{id:9, name: "Ontario",shortCode:"ON"}'
                                                      `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                                                    );
                                                  }
                                                  if (
                                                    objFieldName === "country"
                                                  ) {
                                                    formMethods.setValue(
                                                      objFieldName,
                                                      objFieldValue.name ??
                                                        undefined
                                                    );
                                                  }
                                                  if (
                                                    objFieldName ===
                                                    "uniqueIdentifier"
                                                  ) {
                                                    const formattedNumber =
                                                      objFieldValue !== null
                                                        ? objFieldValue.replace(
                                                            /(\d{3})(\d{3})(\d{3})/,
                                                            "$1-$2-$3"
                                                          )
                                                        : undefined;
                                                    formMethods.setValue(
                                                      "uniqueIdentifier",
                                                      formattedNumber
                                                    );
                                                  }
                                                  if (
                                                    objFieldName === "payeeType"
                                                  ) {
                                                    formMethods.setValue(
                                                      "payeeType",
                                                      objFieldValue
                                                    );
                                                    if (
                                                      objFieldValue === true
                                                    ) {
                                                      setRequiredFliedsAccordingToPayeeType(
                                                        objFieldValue
                                                      );
                                                    } else {
                                                      setRequiredFliedsAccordingToPayeeType(
                                                        objFieldValue
                                                      );
                                                    }
                                                  }
                                                  if (
                                                    objFieldName !== "email" &&
                                                    objFieldValue === null
                                                  ) {
                                                    formMethods.setValue(
                                                      objFieldName,
                                                      ""
                                                    );
                                                  }
                                                }
                                              );
                                            }
                                            //set slip type according to edited row data for submit button action
                                            if (fieldName === "slipType") {
                                              selectedT4ASlipType = fieldValue;
                                              setIsLoadingAdjustedData(true);
                                              setTimeout(() => {
                                                setIsLoadingAdjustedData(false);
                                              }, 1000);
                                            }
                                            if (
                                              fieldName === "otherInformation"
                                            ) {
                                              setSlipOtherInformation(fieldValue)
                                              // formMethods.setValue(
                                              //     "comparison",
                                              //     [
                                              //       {
                                              //         amount: 0,
                                              //         code: "",
                                              //         label: "",
                                              //       },
                                              //     ]
                                              //   );
                                              // if (
                                              //   fieldValue &&
                                              //   fieldValue !== null &&
                                              //   fieldValue.length !== 0
                                              // ) {
                                              //   setOiInEdit(true);
                                              //   formMethods.setValue(
                                              //     "comparison",
                                              //     fieldValue
                                              //   );
                                              // } else {
                                              //   setOiInEdit(true);
                                              //   formMethods.setValue(
                                              //     "comparison",
                                              //     [
                                              //       {
                                              //         amount: 0,
                                              //         code: "",
                                              //         label: "",
                                              //       },
                                              //     ]
                                              //   );
                                              // }
                                            }
                                            if (fieldName === "id") {
                                              formMethods.setValue(
                                                "selectedSlip",
                                                fieldValue
                                              );
                                              let path = `/taxslip/t4a/setup/${fieldValue}`;
                                              navigate(path);
                                            }
                                            if (fieldName === "slipData") {
                                              Object.keys(fieldValue).forEach(
                                                (slipDataFieldName: any) => {
                                                  const slipDataFieldValue =
                                                    fieldValue[
                                                      slipDataFieldName
                                                    ];
                                                  console.log(
                                                    "slipDataFieldValue123",
                                                    slipDataFieldValue
                                                  );
                                                  formMethods.setValue(
                                                    slipDataFieldName,
                                                    slipDataFieldValue &&
                                                      slipDataFieldValue !==
                                                        null
                                                      ? parseFloat(
                                                          slipDataFieldValue
                                                        ).toFixed(2)
                                                      : "0.00"
                                                  );
                                                  if (
                                                    slipDataFieldName ===
                                                    "employerOfferedDentalBenefits"
                                                  ) {
                                                    formMethods.setValue(
                                                      slipDataFieldName,
                                                      slipDataFieldValue
                                                    );
                                                  }
                                                }
                                              );
                                            }
                                          }
                                        );
                                      }
                                      selectedT4ASlipType =
                                        formMethods.getValues("slipType");
                                      setSelectedOtherInformation(false);
                                    } catch (error) {
                                      setIsLoadingAdjustedData(false);
                                      console.error(
                                        "Error fetching data:",
                                        error
                                      );
                                    } finally {
                                      setSelectedResourceSlip(
                                        String(resourceSlipList[1].value)
                                      );
                                      formMethods.setValue(
                                        "selectedSlip",
                                        String(resourceSlipList[1].value)
                                      );
                                      let path = `/taxslip/t4a/setup/${resourceSlipList[1].value}`;
                                      navigate(path);
                                      setTimeout(() => {
                                        setIsLoadingAdjustedData(false);
                                      }, 3000);
                                    }
                                  })();
                                } else {
                                  setT4ASlipRecord(undefined)
                                  setSelectedResourceSlip("create-new");
                                  formMethods.setValue(
                                    "selectedSlip",
                                    "create-new"
                                  );
                                  formMethods.setValue(
                                    "pensionOrSuperannuation",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "lumpSumPayments",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "lumpSumPaymentsOther",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "selfEmployedCommissions",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "incomeTaxDeducted",
                                    "0.00"
                                  );
                                  formMethods.setValue("annuities", "0.00");
                                  formMethods.setValue(
                                    "annuitiesOther",
                                    "0.00"
                                  );
                                  formMethods.setValue("fees", "0.00");
                                  formMethods.setValue("slipStatus", 200);
                                  formMethods.setValue("comparison", []);
                                  setSlipOtherInformation([{ amount: 0, code: 'null', label:'Empty Field'}])

                                  setSelectedResource(
                                    String(
                                      selectedItem.value
                                    )
                                  );
                                  setEditedResourceId(
                                    String(
                                      selectedItem.value
                                    )
                                  );
                                  let path = `/taxslip/t4a/setup/${selectedItem.value}`;
                                  navigate(path);
                                  // setIsLoadingAdjustedData(false)
                                  setTimeout(() => {
                                    setIsLoadingAdjustedData(false);
                                  }, 3000);
                                }
                              }
                            } catch (error) {
                              console.error("Error fetching data:", error);
                            }
                          })();
                          // You can work with the data here or trigger other functions that depend on the fetched data
                  //       } catch (error) {
                  //         console.error("Error fetching data:", error);
                  //       }
                  //     })();
                  //   } catch (error) {
                  //     console.error("Error fetching data:", error);
                  //   }
                  // })();
                  setSelectedResource(fieldValue);
                }
              });
            }
          } catch (error) {
            setIsLoadingAdjustedData(false);
            console.error("Error fetching data:", error);
          }
          // finally {
          //   setSelectedResource(String(response.resourceId));
          //   setEditedResourceId(String(response.resourceId));
          //   let path = `/taxslip/t4/setup/${response.resourceId}`;
          //   navigate(path);
          //   // setIsLoadingAdjustedData(false)
          //   setTimeout(() => {
          //     setIsLoadingAdjustedData(false);
          //   }, 3000);
          // }
        })();
      // } catch (err) {
      //   console.log("err", err);
      //   // alert(err)
      // }
    }
    console.log("comparison inside", formMethods.getValues("comparison"));

    //otherinformation will update selected items
    setSelectedOtherInformation(false);
  };
  // };

  const onResourceSlipSelectionChangeHandler = (
    selectedItem: IEnhancedDropDownItem | any
  ) => {
    setOnResourceSelectionAPIsCalls(true);
    if (selectedItem.value === "create-new") {
      createNewActionFromSlipDDL();
      setSelectedResourceSlip("create-new");
      if (selectedResource !== "create-new") {
        setOnResourceSelectionAPIsCalls(true);
        let path = `/taxslip/t4a/setup/${selectedResource}`;
        navigate(path);
        window.location.reload()
      }
      setT4ASlipRecord(undefined);
    }
    if (selectedItem.value !== "create-new") {
      // setSelectedResourceSlip(String(selectedItem.value));
      // let path = `/taxslip/t4a/setup/${selectedItem.value}`;
      // navigate(path);
      // setIsLoadingAdjustedData(true);
      // setTimeout(() => {
      //   setIsLoadingAdjustedData(false);
      // }, 1000);
      (async () => {
        try {
          setIsLoadingAdjustedData(true);
          let slipDataById = await T4ASlipService.getT4ASlipById(
            String(selectedItem.value),
            clientId,
            taxYear
          );
          setT4ASlipRecord(slipDataById)
          console.log("slipDataById", slipDataById);
          if (slipDataById) {
            Object.keys(slipDataById).forEach((fieldName) => {
              console.log(
                "fieldName useEffect T4 Slip : slipDataById",
                fieldName
              );
              // @ts-ignore
              const fieldValue = slipDataById[fieldName];
              console.log("fieldValue useEffect T4 Slip : ", fieldValue);
              // @ts-ignore

              if (typeof fieldValue !== "object") {
                // @ts-ignore
                formMethods.setValue(fieldName, fieldValue ?? undefined);
              } else if (fieldValue !== null || undefined) {
                console.log("object");
                Object.keys(fieldValue).forEach((objFieldName: any) => {
                  console.log(
                    "objFieldName useEffect T4 Slip : ",
                    objFieldName
                  );
                  // @ts-ignore
                  const objFieldValue = fieldValue[objFieldName];
                  console.log(
                    "objFieldValue useEffect T4 Slip : ",
                    objFieldValue
                  );

                  if (typeof objFieldValue !== "object") {
                    // @ts-ignore
                    formMethods.setValue(objFieldName, objFieldValue);
                  }
                  if (fieldName === "resource") {
                    if (objFieldName === "externalId") {
                      // (async () => {
                      //   try {
                      //     const responseAssociationId =
                      //       await T4SlipService.getRoleAssociationId(
                      //         objFieldValue
                      //       );

                      //     console.log(
                      //       "responseData getRoleAssociationId : ",
                      //       responseAssociationId.associationId
                      //     );

                          formMethods.setValue(
                            "selectedVendor",
                            objFieldValue
                          );

                          // (async () => {
                          //   try {
                          //     const responseGetResId =
                          //       await T4SlipService.getResourceActualId(
                          //         responseAssociationId.associationId
                          //       );
                          //     console.log(
                          //       "responseData////////",
                          //       responseGetResId.resourceId
                          //     );

                              (async () => {
                                try {
                                  const response: any =
                                    await T4ASlipService.getResourceSlipDropDownList(
                                      objFieldValue,
                                      clientId,
                                      taxYear
                                    );
                                  if (response && Array.isArray(response)) {
                                    let resourceSlipList: IDropDownListResponse[] =
                                      response.map(
                                        (
                                          resourceSlip: IDropDownListResponse
                                        ) => ({
                                          label: resourceSlip.label,
                                          value: resourceSlip.value,
                                        })
                                      );

                                    resourceSlipList.unshift({
                                      label: "Create New",
                                      value: "create-new",
                                    });

                                    console.log(
                                      "resourceSlipListDDL inside T4ASlipRecord",
                                      resourceSlipList
                                    );
                                    setSelectedResourceSlipList(
                                      resourceSlipList
                                    );
                                  }
                                } catch (error) {
                                  console.error("Error fetching data:", error);
                                }
                              })();
                              // You can work with the data here or trigger other functions that depend on the fetched data
                      //       } catch (error) {
                      //         console.error("Error fetching data:", error);
                      //       }
                      //     })();
                      //   } catch (error) {
                      //     console.error("Error fetching data:", error);
                      //   }
                      // })();
                      setSelectedResource(objFieldValue);
                    }
                    setEditedResourceId(fieldValue.externalId);
                    if (objFieldName === "email") {
                      if (objFieldValue !== null) {
                        formMethods.setValue(objFieldName, objFieldValue);
                      } else {
                        const maskedStatus: any =
                          sessionStorage.getItem("maskEnabled");
                        console.log("elesese", maskedStatus);
                        if (maskedStatus === "true") {
                          formMethods.setValue(
                            objFieldName,
                            "ze*****3@gmail.com"
                          );
                        } else {
                          formMethods.setValue(objFieldName, "");
                        }
                      }
                    }
                  }
                  if (objFieldName === "province") {
                    let id = objFieldValue.id;
                    let name = objFieldValue.name;
                    let shortCode = objFieldValue.shortCode;
                    console.log("namename", name);

                    formMethods.setValue(
                      objFieldName,
                      // '{id:9, name: "Ontario",shortCode:"ON"}'
                      `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                    );
                  }
                  if (objFieldName === "country") {
                    formMethods.setValue(
                      objFieldName,
                      objFieldValue.name ?? undefined
                    );
                  }
                  if (objFieldName === "uniqueIdentifier") {
                    const formattedNumber =
                      objFieldValue !== null
                        ? objFieldValue.replace(
                            /(\d{3})(\d{3})(\d{3})/,
                            "$1-$2-$3"
                          )
                        : undefined;
                    formMethods.setValue("uniqueIdentifier", formattedNumber);
                  }
                  if (objFieldName === "payeeType") {
                    formMethods.setValue("payeeType", objFieldValue);
                    if (objFieldValue === true) {
                      setRequiredFliedsAccordingToPayeeType(objFieldValue);
                    } else {
                      setRequiredFliedsAccordingToPayeeType(objFieldValue);
                    }
                  }
                  if (objFieldName !== "email" && objFieldValue === null) {
                    formMethods.setValue(objFieldName, "");
                  }
                });
              }
              //set slip type according to edited row data for submit button action
              if (fieldName === "slipType") {
                selectedT4ASlipType = fieldValue;
                setIsLoadingAdjustedData(true);
                setTimeout(() => {
                  setIsLoadingAdjustedData(false);
                }, 1000);
              }
              if (fieldName === "otherInformation") {
                // setSlipOtherInformation(fieldValue)
                  setSlipOtherInformation([])
                  if (fieldValue && fieldValue !== null && fieldValue.length !== 0) {
                    setOiInEdit(true);
                    formMethods.setValue("comparison", fieldValue);
                  } else {
                    setOiInEdit(true);
                    formMethods.setValue("comparison", [
                      { amount: 0, code: "", label: "" },
                    ]);
                  }
              }
              if (fieldName === "id") {
                // setNewCreatedSlipId(fieldValue)
                formMethods.setValue("selectedSlip", fieldValue);
                let path = `/taxslip/t4a/setup/${fieldValue}`;
                navigate(path);
              }
              if (fieldName === "slipData") {
                Object.keys(fieldValue).forEach((slipDataFieldName: any) => {
                  const slipDataFieldValue = fieldValue[slipDataFieldName];
                  console.log("slipDataFieldValue123", slipDataFieldValue);
                  formMethods.setValue(
                    slipDataFieldName,
                    slipDataFieldValue && slipDataFieldValue !== null
                      ? parseFloat(slipDataFieldValue).toFixed(2)
                      : "0.00"
                  );
                  if (slipDataFieldName === "employerOfferedDentalBenefits") {
                    formMethods.setValue(slipDataFieldName, slipDataFieldValue);
                  }
                });
              }
            });
          }
          selectedT4ASlipType = formMethods.getValues("slipType");
          setSelectedOtherInformation(false);
        } catch (error) {
          setIsLoadingAdjustedData(false);
          console.error("Error fetching data:", error);
        } finally {
          setSelectedResourceSlip(String(selectedItem.value));
          let path = `/taxslip/t4a/setup/${selectedItem.value}`;
          navigate(path);
          setTimeout(() => {
            setIsLoadingAdjustedData(false);
          }, 2500);
        }
      })();
    }
    // }
    // setSelectedResourceSlip(String(selectedItem.value));
    //otherinformation will update selected items
    setSelectedOtherInformation(true);
  };

  // useEffect(() => {
  //   if (selectedResource !== "create-new") {
  //     refetchResourceSlips();
  //   } else {
  //   }
  // }, [refetchResourceSlips, selectedResource]);

  // useEffect(() => {
  //   if (selectedResourceSlip !== "create-new") {
  //     refetchSlipRecord();
  //   } else {
  //   }
  // }, [refetchSlipRecord, selectedResourceSlip]);

  // // for edit
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (selectedT4ASlipsData) {
  //     Object.keys(selectedT4ASlipsData).forEach((fieldName) => {
  //       console.log("fieldName useEffect T4 Slip : ", fieldName);
  //       // @ts-ignore
  //       const fieldValue = selectedT4ASlipsData[fieldName];
  //       console.log("fieldValue useEffect T4 Slip : ", fieldValue);
  //       // @ts-ignore

  //       if (typeof fieldValue !== "object") {
  //         // @ts-ignore
  //         formMethods.setValue(fieldName, fieldValue ?? undefined);
  //       } else if (fieldValue !== null || undefined) {
  //         console.log("object");
  //         Object.keys(fieldValue).forEach((objFieldName: any) => {
  //           console.log("objFieldName useEffect T4 Slip : ", objFieldName);
  //           // @ts-ignore
  //           const objFieldValue = fieldValue[objFieldName];
  //           console.log("objFieldValue useEffect T4 Slip : ", objFieldValue);
  //           if (typeof objFieldValue !== "object") {
  //             // @ts-ignore
  //             formMethods.setValue(objFieldName, objFieldValue ?? undefined);
  //           } else if (objFieldValue !== null || undefined) {
  //             console.log("id name SC = ", objFieldValue.name);
  //             formMethods.setValue(objFieldName, objFieldValue ?? undefined);
  //             // Object.keys(objFieldValue).forEach((objFieldName_1:any) => {
  //             //   console.log("objFieldName_1 useEffect T4 Slip : ",objFieldName_1)
  //             //   // @ts-ignore
  //             //   const objFieldValue_1 = objFieldValue[objFieldName_1];
  //             //   console.log("objFieldValue_1 useEffect T4 Slip : ",objFieldValue_1)
  //             //   if(objFieldValue_1 !== null || undefined || ""){
  //             //     // formMethods.setValue(objFieldName, objFieldValue_1 ?? undefined);
  //             //     formMethods.setValue(objFieldName, objFieldValue_1 ?? undefined);
  //             //   }
  //             // })
  //             if (objFieldValue.name === "Canada") {
  //               formMethods.setValue(
  //                 objFieldName,
  //                 objFieldValue.name ?? undefined
  //               );
  //             }
  //           }
  //         });
  //       }
  //     });
  //   }
  // }, [selectedT4ASlipsData, formMethods.setValue, formMethods]);

  // for edit

  //if slip is completed this will effected
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (T4ASlipRecord && onResourceSelectionAPIsCalls === false) {
      Object.keys(T4ASlipRecord).forEach((fieldName) => {
        console.log("fieldName useEffect T4 Slip : T4ASlipRecord", fieldName);
        // @ts-ignore
        const fieldValue = T4ASlipRecord[fieldName];
        console.log("fieldValue useEffect T4 Slip : ", fieldValue);
        // @ts-ignore

        if (typeof fieldValue !== "object") {
          // @ts-ignore
          formMethods.setValue(fieldName, fieldValue ?? undefined);
        } else if (fieldValue !== null || undefined) {
          console.log("object");
          Object.keys(fieldValue).forEach((objFieldName: any) => {
            console.log("objFieldName useEffect T4 Slip : ", objFieldName);
            // @ts-ignore
            const objFieldValue = fieldValue[objFieldName];
            console.log("objFieldValue useEffect T4 Slip : ", objFieldValue);

            if (typeof objFieldValue !== "object") {
              // @ts-ignore
              formMethods.setValue(objFieldName, objFieldValue);
            }
            if (fieldName === "resource") {
              if (objFieldName === "externalId") {
                // console.log("objFieldValue : externalid ", objFieldValue);
                // let roleAssociationId = "d0a41913-11f3-4f00-a824-9977909bdd04";
                // (async () => {
                //   try {
                //     const responseAssociationId =
                //       await T4SlipService.getRoleAssociationId(objFieldValue);

                //     console.log(
                //       "responseData getRoleAssociationId : ",
                //       responseAssociationId.associationId
                //     );
                    // Find the index of the object with the specified key
                    //  const indexForSelectedCard = resourceListWithoutCreateNew && resourceListWithoutCreateNew.findIndex((item:any) => item.value === responseAssociationId.associationId);
                    //  console.log("indexForSelectedCard",indexForSelectedCard)
                    //  setSelectedCard(indexForSelectedCard)
                    //for auto selected card
                    setSelectedCard(
                      String(objFieldValue)
                    );
                    formMethods.setValue(
                      "selectedVendor",
                      objFieldValue
                    );

                    // (async () => {
                    //   try {
                    //     const responseGetResId =
                    //       await T4SlipService.getResourceActualId(
                    //         responseAssociationId.associationId
                    //       );
                    //     console.log(
                    //       "responseData////////",
                    //       responseGetResId.resourceId
                    //     );

                        (async () => {
                          try {
                            const response: any =
                              await T4ASlipService.getResourceSlipDropDownList(
                                objFieldValue,
                                clientId,
                                taxYear
                              );
                            // console.log(
                            //   "responseData-----------------",
                            //   response[0].value
                            // );
                            // formMethods.setValue("selectedSlip", response[0].value);

                            if (response && Array.isArray(response)) {
                              let resourceSlipList: IDropDownListResponse[] =
                                response.map(
                                  (resourceSlip: IDropDownListResponse) => ({
                                    label: resourceSlip.label,
                                    value: resourceSlip.value,
                                  })
                                );

                              resourceSlipList.unshift({
                                label: "Create New",
                                value: "create-new",
                              });

                              console.log(
                                "resourceSlipListDDL inside T4ASlipRecord",
                                resourceSlipList
                              );
                              setSelectedResourceSlipList(resourceSlipList);
                              //for Cards scenario
                              if (
                                resourceSlipList &&
                                resourceSlipList.length > 1
                              ) {
                                setSelectedResourceSlip(
                                  String(resourceSlipList[1].value)
                                );
                              } else {
                                setSelectedResourceSlip("create-new");
                              }
                            }
                          } catch (error) {
                            console.error("Error fetching data:", error);
                          }
                        })();
                        // You can work with the data here or trigger other functions that depend on the fetched data
                //       } catch (error) {
                //         console.error("Error fetching data:", error);
                //       }
                //     })();
                //   } catch (error) {
                //     console.error("Error fetching data:", error);
                //   }
                // })();
                setSelectedResource(objFieldValue);
              }
              setEditedResourceId(fieldValue.externalId);
              if (objFieldName === "email") {
                if (objFieldValue !== null) {
                  formMethods.setValue(objFieldName, objFieldValue);
                } else {
                  const maskedStatus: any =
                    sessionStorage.getItem("maskEnabled");
                  console.log("elesese", maskedStatus);
                  if (maskedStatus === "true") {
                    console.log("elesese if", maskedStatus);
                    formMethods.setValue("email", "ze*****3@gmail.com");
                  } else {
                    formMethods.setValue(objFieldName, "");
                  }
                }
              }
            }
            if (objFieldName === "province") {
              let id = objFieldValue.id;
              let name = objFieldValue.name;
              let shortCode = objFieldValue.shortCode;
              console.log("namename", name);

              formMethods.setValue(
                objFieldName,
                // '{id:9, name: "Ontario",shortCode:"ON"}'
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
              );
            }
            if (objFieldName === "country") {
              formMethods.setValue(
                objFieldName,
                objFieldValue.name ?? undefined
              );
            }
            if (objFieldName === "uniqueIdentifier") {
              const formattedNumber =
                objFieldValue !== null
                  ? objFieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
                  : undefined;
              formMethods.setValue("uniqueIdentifier", formattedNumber);
            }
            if (objFieldName === "payeeType") {
              formMethods.setValue("payeeType", objFieldValue);
              if (objFieldValue === true) {
                setRequiredFliedsAccordingToPayeeType(objFieldValue);
              } else {
                setRequiredFliedsAccordingToPayeeType(objFieldValue);
              }
            }
            if (objFieldName !== "email" && objFieldValue === null) {
              formMethods.setValue(objFieldName, "");
            }
          });
        }
        //set slip type according to edited row data for submit button action
        if (fieldName === "slipType") {
          selectedT4ASlipType = fieldValue;
          setIsLoadingAdjustedData(true);
          setTimeout(() => {
            setIsLoadingAdjustedData(false);
          }, 1000);
        }
        if (fieldName === "otherInformation") {
          setSlipOtherInformation([])
          if (fieldValue && fieldValue !== null && fieldValue.length !== 0) {
            setOiInEdit(true);
            formMethods.setValue("comparison", fieldValue);
          } else {
            setOiInEdit(true);
            formMethods.setValue("comparison", [
              { amount: 0, code: "", label: "" },
            ]);
          }
        }
        if (fieldName === "id") {
          // setNewCreatedSlipId(fieldValue)
          formMethods.setValue("selectedSlip", fieldValue);
          let path = `/taxslip/t4a/setup/${fieldValue}`;
          navigate(path);
        }
        if (fieldName === "slipData") {
          Object.keys(fieldValue).forEach((slipDataFieldName: any) => {
            const slipDataFieldValue = fieldValue[slipDataFieldName];
            console.log("slipDataFieldValue123", slipDataFieldValue);
            formMethods.setValue(
              slipDataFieldName,
              slipDataFieldValue && slipDataFieldValue !== null
                ? parseFloat(slipDataFieldValue).toFixed(2)
                : "0.00"
            );
            if (slipDataFieldName === "employerOfferedDentalBenefits") {
              formMethods.setValue(slipDataFieldName, slipDataFieldValue);
            }
          });
        }
      });
    }
    //set slip type to 400 because when we come back to slip without reload it will automatically be original
    selectedT4ASlipType = formMethods.getValues("slipType");

    //otherinformation will update sselected items when its submitted
    // setSelectedOtherInformation(true)
    setSelectedOtherInformation(false);

    if(!id && !T4ASlipRecord && defaultSettingsData && defaultSettingsData.t4aDefaultSetting){
      console.log("t4aDefaultSetting",defaultSettingsData.t4aDefaultSetting)
      defaultSettingsCalls()
    } else if(!id && !T4ASlipRecord && defaultSettingsData && defaultSettingsData.message) {
      console.log("t4DefaultSetting else",defaultSettingsData)
      formMethods.setValue("comparison", [
        { amount: 0, code: "", label: "" },
        ]);
    }
  }, [T4ASlipRecord, formMethods.setValue, formMethods, id, defaultSettingsData, onResourceSelectionAPIsCalls]);

  //this is for not started slip
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (selectedNotStartedResourceData && onResourceSelectionAPIsCalls === false) {
      Object.keys(selectedNotStartedResourceData).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = selectedNotStartedResourceData[fieldName];
        console.log(
          "fieldNamefieldName",
          fieldName,
          " && ",
          "fieldValuefieldValue ",
          fieldValue
        );
        formMethods.setValue(
          fieldName,
          fieldValue !== null && fieldValue !== "" ? fieldValue : ""
        );

        if (fieldName === "address") {
          Object.keys(fieldValue).forEach((resourceFieldName) => {
            const resourceFieldValue = fieldValue[resourceFieldName];
            // @ts-ignore
            console.log(
              "resourceFieldName : ",
              resourceFieldName,
              "resourceFieldValue : ",
              resourceFieldValue
            );
            Object.keys(resourceFieldValue).forEach((resourceFieldNameNew) => {
              const resourceFieldValueNew =
                resourceFieldValue[resourceFieldNameNew];
              // @ts-ignore
              console.log(
                "resourceFieldNameNew : ",
                resourceFieldNameNew,
                "resourceFieldValue : ",
                resourceFieldValueNew
              );
              formMethods.setValue(resourceFieldNameNew, resourceFieldValueNew);

              if (resourceFieldNameNew === "country") {
                formMethods.setValue(
                  resourceFieldNameNew,
                  resourceFieldValueNew.name ?? undefined
                );
              }
              if (resourceFieldNameNew === "province") {
                let id = resourceFieldValueNew.id;
                let name = resourceFieldValueNew.name;
                let shortCode = resourceFieldValueNew.shortCode;

                console.log("namenamenmae", name);

                formMethods.setValue(
                  resourceFieldNameNew,
                  `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                  // '{id:1, name: "Alberta",shortCode:"AB"}'
                );
              }
            });
          });
        }
        if (fieldName === "email") {
          if (fieldValue !== null) {
            formMethods.setValue(fieldName, fieldValue);
          } else {
            const maskedStatus: any = sessionStorage.getItem("maskEnabled");
            if (maskedStatus === "true") {
              formMethods.setValue(fieldName, "ze*****3@gmail.com");
            } else {
              formMethods.setValue(fieldName, "");
            }
          }
        }
        if (fieldName === "id") {
          // (async () => {
          //   try {
          //     const responseAssociationId =
          //       await T4SlipService.getRoleAssociationId(fieldValue);

          //     console.log(
          //       "responseData getRoleAssociationId : ",
          //       responseAssociationId.associationId
          //     );
              //for auto selected card
              setSelectedCard(String(fieldValue));
              formMethods.setValue(
                "selectedVendor",
                fieldValue
              );

              // (async () => {
              //   try {
              //     const responseGetResId =
              //       await T4SlipService.getResourceActualId(
              //         responseAssociationId.associationId
              //       );
              //     console.log(
              //       "responseData////////",
              //       responseGetResId.resourceId
              //     );

                  (async () => {
                    try {
                      const response: any =
                        await T4ASlipService.getResourceSlipDropDownList(
                          fieldValue,
                          clientId,
                          taxYear
                        );
                      console.log(
                        "responseData----------------- inside selectedNotStartedResourceData",
                        response
                      );
                      // formMethods.setValue("selectedSlip", response[0].value);

                      if (response && Array.isArray(response)) {
                        let resourceSlipList: IDropDownListResponse[] =
                          response.map(
                            (resourceSlip: IDropDownListResponse) => ({
                              label: resourceSlip.label,
                              value: resourceSlip.value,
                            })
                          );

                        resourceSlipList.unshift({
                          label: "Create New",
                          value: "create-new",
                        });

                        console.log("resourceSlipListDDL", resourceSlipList);
                        setSelectedResourceSlipList(resourceSlipList);
                      }
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  })();
                  // You can work with the data here or trigger other functions that depend on the fetched data
          //       } catch (error) {
          //         console.error("Error fetching data:", error);
          //       }
          //     })();
          //   } catch (error) {
          //     console.error("Error fetching data:", error);
          //   }
          // })();
          setSelectedResource(fieldValue);
        }
        if (fieldName === "uniqueIdentifier") {
          const formattedNumber =
            fieldValue !== null
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
              : undefined;
          formMethods.setValue("uniqueIdentifier", formattedNumber);
        }

        if (fieldName === "name1") {
          formMethods.setValue(
            "name1",
            fieldValue !== null ? fieldValue : undefined
          );
        }
        // }
        if (fieldName === "metaFields") {
          fieldValue.forEach((obj: any) => {
            formMethods.setValue(obj.name, obj.value);
            // if(obj.name === 'initial' || obj.name === 'INITIALS'){
            //   formMethods.setValue('initials',obj.value)
            // }
            if (obj.name === "payeeType") {
              if (obj.value === true) {
                formMethods.setValue("payeeType", true);
                setRequiredFliedsAccordingToPayeeType(true);
              } else {
                formMethods.setValue("payeeType", false);
                setRequiredFliedsAccordingToPayeeType(false);
              }
            }
            console.log(
              `"objobj" : ${obj.name} ====== objValue : ${obj.value}`
            );
          });
        }
      });
      formMethods.setValue("selectedSlip", "create-new");
      formMethods.setValue("pensionOrSuperannuation", "0.00");
      formMethods.setValue("lumpSumPayments", "0.00");
      formMethods.setValue("lumpSumPaymentsOther", "0.00");
      formMethods.setValue("selfEmployedCommissions", "0.00");
      formMethods.setValue("incomeTaxDeducted", "0.00");
      formMethods.setValue("annuities", "0.00");
      formMethods.setValue("annuitiesOther", "0.00");
      formMethods.setValue("fees", "0.00");
      formMethods.setValue("slipStatus", 200);
      formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
      setSlipOtherInformation([])
    }
  }, [selectedNotStartedResourceData, formMethods.setValue, formMethods, onResourceSelectionAPIsCalls]);

  // //resource info by using selected resource id
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (resourceInfo) {
  //     console.log("resourceInforesourceInfo",resourceInfo)
  //     Object.keys(resourceInfo).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = resourceInfo[fieldName];
  //       console.log("fieldNamefieldName",fieldName," && ","fieldValuefieldValue ",fieldValue)
  //       formMethods.setValue(fieldName, fieldValue);

  //       if (fieldName === "address") {
  //         Object.keys(fieldValue).forEach((resourceFieldName) => {
  //           const resourceFieldValue = fieldValue[resourceFieldName];
  //           // @ts-ignore
  //           console.log("resourceFieldName : ",resourceFieldName,"resourceFieldValue : ",resourceFieldValue)
  //           // formMethods.setValue(resourceFieldName, resourceFieldValue);
  //           Object.keys(resourceFieldValue).forEach((resourceFieldNameNew) => {
  //             const resourceFieldValueNew = resourceFieldValue[resourceFieldNameNew];
  //             // @ts-ignore
  //             console.log("resourceFieldNameNew : ",resourceFieldNameNew,"resourceFieldValue : ",resourceFieldValueNew)
  //             formMethods.setValue(resourceFieldNameNew, resourceFieldValueNew);

  //             if (resourceFieldValueNew === "Canada") {
  //               formMethods.setValue(
  //                 resourceFieldNameNew,
  //                 resourceFieldValueNew.name ?? undefined
  //               );
  //             }
  //             if (resourceFieldValueNew === "province") {
  //               formMethods.setValue(
  //                 resourceFieldNameNew,
  //                 resourceFieldValueNew.shortCode ?? undefined
  //                 // {id:resourceFieldValueNew.id, name: resourceFieldValueNew.name,shortCode:resourceFieldValueNew.shortCode}
  //               );
  //             }

  //             // if (typeof resourceFieldValueNew !== "object") {
  //             //   // @ts-ignore
  //             //   formMethods.setValue(resourceFieldNameNew, resourceFieldValueNew ?? undefined);
  //             // } else if(resourceFieldValueNew !== null || undefined) {
  //             //   // @ts-ignore
  //             //   formMethods.setValue(
  //             //     resourceFieldNameNew,
  //             //     {id:5, name: "NewfoundLand & Labrador",shortCode:"NL"});
  //             //   if (resourceFieldValueNew.name === "Canada") {
  //             //     formMethods.setValue(
  //             //       resourceFieldNameNew,
  //             //       resourceFieldValueNew.name ?? undefined
  //             //     );
  //             //   }
  //             // }

  //             // if (resourceFieldNameNew === "province") {
  //             //   Object.keys(resourceFieldValueNew).forEach((addressFieldName) => {
  //             //     const addressFieldValue = resourceFieldValueNew[addressFieldName];
  //             //     // @ts-ignore
  //             //     formMethods.setValue(resourceFieldNameNew, addressFieldValue.shortCode ?? undefined);
  //             //     console.log("addressFieldName : ",addressFieldName,"addressFieldValue : ",addressFieldValue)

  //             //   });
  //             // }

  //           });
  //         });
  //       } else {
  //         // @ts-ignore
  //         formMethods.setValue(fieldName, fieldValue);
  //       }
  //     });
  //   }
  // }, [resourceInfo, formMethods.setValue, formMethods]);

  useEffect(() => {
    //Load EMployee DDL
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 2000);
    const fetchData = async () => {
      try {
        const response: any = await T4ASlipService.getVendorDDL(
          clientId,
          VENDOR_RESOURCE_LITERAL,
          taxYear
        );
        console.log("responseData-----------------", response);

        if (response && Array.isArray(response)) {
          let resources: IDropDownListResponse[] = response.map(
            (resourceItem: IDropDownListResponse) => ({
              // id: resourceItem.id,
              label: resourceItem.label,
              value: resourceItem.value,
            })
          );

          resources.unshift({
            label: "Create New",
            value: "create-new",
          });

          setSelectedResourceList(resources);
        }
        setResourceListWithoutCreateNew(sortData(response));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

      // when  I formMethods.setValue of otherinformation inside async calls method then facing issue while click on add more so we have to use state method to show otherinformation 
      useEffect(()=>{
        console.log("slipOtherInformation outside",slipOtherInformation)
        if (
          slipOtherInformation &&
          slipOtherInformation !== null &&
          slipOtherInformation.length !== 0
        ) {
        console.log("slipOtherInformation",slipOtherInformation)
          setOiInEdit(true);
          formMethods.setValue(
            "comparison",
            slipOtherInformation
          );
        } else if (!id) {
          console.log(
            "slipOtherInformation1234ee else",
            slipOtherInformation
          );
          setOiInEdit(true);
          formMethods.setValue(
            "comparison",
            [
              {
                amount: 0,
                code: "",
                label: "",
              },
            ]
          );
        }
      },[slipOtherInformation])

  const onSlipTypeSelectionChangeHandler = (e: any) => {
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 1000);
    const selectedType = e.value;
    selectedT4ASlipType = selectedType;
    // alert(selectedType);
  };

  const onCloseHandler = () => {
    setDialogOpen(false);
    setInvoiceDialogOpen(false);
  };

  console.log("selectedT4SlipsData.id", selectedT4ASlipsData?.id);
  // console.log("slipRecord.id",slipRecord?.id)
  // console.log("newCreatedSlipId",newCreatedSlipId)
  // console.log("editedRecordSlipCreateNew",editedRecordSlipCreateNew)
  console.log("selectedResource", selectedResource);
  console.log(
    "selectedT4SlipsData.resource.externalId",
    selectedT4ASlipsData?.resource?.externalId
  );
  // console.log("newCreatedResourceId",newCreatedResourceId)
  console.log(
    "selectedNotStartedResourceData.id",
    selectedNotStartedResourceData?.id
  );
  console.log("comparison", formMethods.getValues("comparison"));
  console.log(
    "selectedVendor outside",
    formMethods.getValues("selectedVendor")
  );
  console.log("businessName", formMethods.getValues("businessName"));
  console.log("businessNumber", formMethods.getValues("businessNumber"));

  const getCardStyles = (index: any) => {
    return {
      borderLeft:
        selectedCard === index ? "6px solid #0057db" : "2px solid transparent",
      cursor: "pointer",
    };
  };
  const getSelectedResourceSlipInCard = (index: any) => {
    let selectedItem =
      selectedCard === index ? selectedResourceSlip : "create-new";
    return selectedItem;
  };
  const getSelectedResourceSlipsListInCard = (index: any) => {
    let selectedList =
      selectedCard === index
        ? selectedResourceSlipList
        : [{ label: "Create New", value: "create-new" }];
    return selectedList;
  };

  const filteredResourceList =
    resourceListWithoutCreateNew &&
    resourceListWithoutCreateNew.filter((item: any) =>
      item.label.toLowerCase().includes(itemSearch.toLowerCase())
    );
  const onDividerButtonClickHandler = () => {
    setSidePanelsVisibility((prevButtonState: any) => !prevButtonState);
  };

  const onCreateNewButtonClicked = () => {
    // setSnackbarData({
    //   open: true,
    //   message: "Please load a payee for T4A Slip",
    //   severity: "error",
    // });
    formMethods.reset();
    // setNewCreatedResourceId('')
    // setNewCreatedSlipId('')
    setSelectedResourceSlipList([{ label: "Create New", value: "create-new" }]);
    setSelectedResourceSlip("create-new");
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    setSlipOtherInformation([])
    //otherinformation will update selected items
    setSelectedOtherInformation(false);
    // if(id !== undefined){
    // window.location.href = "/taxslip/t4a/setup";
    // }
    let path = `/taxslip/t4a/setup`;
    navigate(path);
    setT4ASlipRecord(undefined);
    setRequiredFliedsAccordingToPayeeType(true);
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 1000);
    setSelectedResource("create-new");
    //otherinformation will update selected items
    setSelectedOtherInformation(false);
    setSelectedCard(null);
    defaultSettingsCalls()
    
    window.location.reload()
  };

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: id ? id : "",
        entityId2: "",
        entityId3: "",
        screenName: T4A_SETUP_SCREEN_SLUG,
        companyName: clientName,
        activityType: "activity_type=create&activity_type=updated&activity_type=print&activity_type=delete",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          pb: "5rem",
          "& .MuiAppBar-root": { zIndex: 2, top: "65px" },
          "& .p-datepicker": { zIndex: 2 },
          paddingTop: "2rem",
        }}
      >
        <BackdropCircularProgress
          open={
            // isLoadingDropDownList ||
            isSubmitting ||
            // isLoadingResourceSlips ||
            // isLoadingSlipData ||
            isLoadingData ||
            // reFetchingResourceSlipsData ||
            // reFetchingSlipData ||
            isLoadingAdjustedData ||
            // isLoadingT4AOtherInformationList ||
            isDeleting ||
            isLoadingNotStartedSlipId ||
            isLoadingDataa ||
            isLoadingActivityLogData || 
            isLoadingDefaultSettingsData
          }
          testId={testId}
        />
        <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={deleteT4ASlipField}
          cancelActionHandler={onCloseHandler}
          testId="T4-confirmation-dialog"
        />
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          {sidePanelsVisibility && (
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Box sx={{ margin: "0" }}>
                <InputText
                  value={itemSearch}
                  onChange={(e) => setItemSearch(e.target.value)}
                  placeholder={"Search by name..."}
                  className="w-full search-field"
                />
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    sx={{ right: 0, margin: "10px 0px", fontSize: "12px" }}
                    onClick={onCreateNewButtonClicked}
                  >
                    + Create New
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  // display: "flex",
                  height: "76rem",
                  // flexDirection: "column",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#ccc",
                    borderRadius: "3px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#aaa",
                  },
                }}
              >
                {resourceListWithoutCreateNew &&
                  filteredResourceList.map((item: any, index: any) => {
                    return (
                      <Card
                        sx={{
                          padding: 0,
                          borderRadius: "8px",
                          marginBottom: "1rem",
                        }}
                        key={index}
                        onClick={() =>
                          onResourceSelectionChangeHandler(item, item.value)
                        }
                        style={getCardStyles(item.value)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0.8rem",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: ".875rem" }}
                          >
                            {item.label}
                          </Typography>
                          <Avatar
                            shape="circle"
                            style={{
                              background: "#0057db",
                              color: "#fff",
                              borderRadius: "50%",
                              fontWeight: "bold",
                              fontSize: ".775rem",
                              padding: "2px",
                            }}
                          >
                            {item.label
                              .replace(/\s+/g, " ")
                              .split(" ")
                              .filter((word: string) => word.trim() !== "")
                              .map((word: string) => word[0].toUpperCase())
                              .slice(0, 2)
                              .join("")}
                          </Avatar>
                        </Box>
                        {selectedCard === item.value ? (
                          <>
                            <Divider className="m-0" />
                            <Box>
                              <Box sx={{ padding: "0.6rem" }}>
                                <Dropdown
                                  value={getSelectedResourceSlipInCard(
                                    item.value
                                  )}
                                  onChange={
                                    onResourceSlipSelectionChangeHandler
                                  }
                                  options={getSelectedResourceSlipsListInCard(
                                    item.value
                                  )}
                                  onClick={(e: any) => e.stopPropagation()}
                                  className="slip-dropdown"
                                />
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <></>
                        )}
                      </Card>
                    );
                  })}
              </Box>
            </Grid>
          )}
          <Divider
            layout="vertical"
            align="center"
            className="verticle-divider"
            style={{ zIndex: 0 }}
          >
            <Button
              variant="outlined"
              sx={{
                minWidth: "fit-content",
                padding: "20px 0",
                borderRadius: "7px",
              }}
              onClick={onDividerButtonClickHandler}
            >
              {sidePanelsVisibility ? (
                <i
                  className="pi pi-angle-double-left"
                  style={{ color: "#0057db" }}
                ></i>
              ) : (
                <i
                  className="pi pi-angle-double-right"
                  style={{ color: "#0057db" }}
                ></i>
              )}
            </Button>
          </Divider>
          <Grid
            item
            xs={sidePanelsVisibility ? 8 : 10}
            sm={sidePanelsVisibility ? 8 : 10.8}
            md={sidePanelsVisibility ? 9.4 : 11.2}
            lg={sidePanelsVisibility ? 9.5 : 11.6}
            xl={sidePanelsVisibility ? 9.7 : 11.7}
            sx={{ "& .MuiGrid-container": { marginTop: 0 } }}
          >
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <TwoColumnLayout
                  toolbar={
                    <T4ASlipSetupToolbar
                      onResetClicked={onResetClicked}
                      onDeleteClicked={onDeleteClicked}
                      onPrintClicked={onPrintClicked}
                      isSubmitting={isSubmitting}
                      formMethods={formMethods}
                    />
                  }
                  leftColumnContent={
                    <>
                      {!sidePanelsVisibility && (
                        <StandardPanel
                          title="Load Slips"
                          testId={`${testId}-load-slips-panel`}
                        >
                          <Grid
                            container
                            columnSpacing={2}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <EnhancedDropDownRHFWithLabel
                                label="Load Vendor"
                                selectName="selectedVendor"
                                // items={resourceListItems}
                                // selectedItem="Create New"
                                items={selectedResourceList}
                                selectedItem={selectedResource}
                                onSelectionChangeHandler={
                                  onResourceSelectionChangeHandler
                                }
                                testId={`${testId}-selected-vendor`}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <EnhancedDropDownRHFWithLabel
                                label="Load T4A Slip"
                                selectName="selectedSlip"
                                // items={t4aslipListItems}
                                // selectedItem="Create New"
                                // items={resourceSlipListItems}
                                // selectedItem={selectedResourceSlip}
                                items={selectedResourceSlipList}
                                selectedItem={selectedResourceSlip}
                                onSelectionChangeHandler={
                                  onResourceSlipSelectionChangeHandler
                                }
                                testId={`${testId}-selected-slip`}
                                // isLoading={isLoadingFlexFieldsList}
                              />
                            </Grid>
                          </Grid>
                        </StandardPanel>
                      )}
                      <StandardPanel
                        title="Slip Data"
                        testId={`${testId}-slip-data`}
                        topPadding={!sidePanelsVisibility ? 2 : 0}
                      >
                        {T4AOtherInformationList && (
                          <T4ASlipDataPanel
                            formMethods={formMethods}
                            isLoading={false}
                            otherInformationList={
                              T4AOtherInformationList !== undefined
                                ? T4AOtherInformationList
                                : []
                            }
                            testId={testId}
                            selectedOtherInformation={selectedOtherInformation}
                            oiInEdit={oiInEdit}
                          />
                        )}
                      </StandardPanel>
                    </>
                  }
                  rightColumnContent={
                    <>
                      <StandardPanel
                        title="Vendor Info"
                        testId={`${testId}-Vendor-info`}
                      >
                        <VendorInfoSetupFieldsPanel
                          testId={testId}
                          requiredFliedsAccordingToPayeeType={
                            requiredFliedsAccordingToPayeeType
                          }
                        />
                      </StandardPanel>
                      <StandardPanel
                        title="Address"
                        testId={`${testId}-Vendor-address-panel`}
                        topPadding={2}
                      >
                        <VendorAddressSetupFieldsPanel testId={testId} />
                      </StandardPanel>
                      <StandardPanel
                        title="Filing Data"
                        testId={`${testId}-filing-data-panel`}
                        topPadding={2}
                      >
                        <EnhancedDropDownRHFWithLabel
                          label="Slip Type"
                          selectName="slipType"
                          items={[
                            { label: "Original", value: 400 },
                            { label: "Amended", value: 200 },
                            { label: "Cancelled", value: 300 },
                            { label: "Additional", value: 100 },
                          ]}
                          selectedItem={400}
                          testId="slip-type"
                          labelPosition={LabelPosition.Left}
                          required
                          onSelectionChangeHandler={
                            onSlipTypeSelectionChangeHandler
                          }
                        />
                        <FilingDataSetupFieldsPanel testId={testId} taxYear={taxYear} />
                      </StandardPanel>
                    </>
                  }
                  testId="t4-slip-setup-form"
                />
              </form>
            </FormProvider>
          </Grid>
        </Grid>
        {/* invoice dialogu open if any transaction is unPaid */}
        <EnhancedDialog
          dialogTitle="Invoice(s) Due"
          key="dialog-box"
          testId="dialog"
          onCloseHandler={onCloseHandler}
          open={openInvoiceDialog}
          dialogContent={
            <InvoiceDialogPanel
              invoiceData={invoiceData}
              onUnPaidClickHandler={onUnPaidClickHandler}
              onClickInvoiceNoHandler={onClickInvoiceNoHandler}
            />
          }
          dialogAction={
            <Grid>
              <Button autoFocus variant="contained" onClick={onCloseHandler}>
                Cancel
              </Button>
            </Grid>
          }
        />
        <EnhancedSnackbar
          message={snackbarData.message}
          onCloseHandler={onSnackbarClosed}
          severity={snackbarData.severity}
          testId={`${testId}-snackbar`}
          open={snackbarData.open}
          autoHide={snackbarData.autoHide}
        />
      </Grid>
    </>
  );
};
