import { FC, useEffect, useState } from "react";
import {
  EnhancedDropDownRHFWithLabel,
  FieldLayout,
  ITest,
  LabelPosition,
} from "@websential/cosmic";
import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { T4AOtherInformationService } from "components/OtherInformation/otherInformations";
import { Grid } from "@mui/material";

interface IT4ADefaultSettingsPanel extends ITest {}
export const T4ADefaultSettingsPanel: FC<IT4ADefaultSettingsPanel> = ({
  testId,
}) => {
  const [T4AOtherInformationList, setT4AOtherInformationList] =
    useState<any>(undefined);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await T4AOtherInformationService.getT4AOIForDefaultSettings();
        setT4AOtherInformationList(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <Grid sx={{pb:'3.6rem'}}>
      <FieldWrapper>
        <FieldLayout
          label="Other Informations"
          testId={testId}
          labelPosition={LabelPosition.Left}
          labelColumns={4}
          fieldColumns={8}
        >
          <>
            <EnhancedDropDownRHFWithLabel
              selectName="t4aOtherInformation1"
              label=""
              testId={`${testId}-other-information-list`}
              items={T4AOtherInformationList ?? []}
            />
            <EnhancedDropDownRHFWithLabel
              selectName="t4aOtherInformation2"
              label=""
              testId={`${testId}-other-information-list`}
              items={T4AOtherInformationList ?? []}
            />
            <EnhancedDropDownRHFWithLabel
              selectName="t4aOtherInformation3"
              label=""
              testId={`${testId}-other-information-list`}
              items={T4AOtherInformationList ?? []}
            />
            <EnhancedDropDownRHFWithLabel
              selectName="t4aOtherInformation4"
              label=""
              testId={`${testId}-other-information-list`}
              items={T4AOtherInformationList ?? []}
            />
            <EnhancedDropDownRHFWithLabel
              selectName="t4aOtherInformation5"
              label=""
              testId={`${testId}-other-information-list`}
              items={T4AOtherInformationList ?? []}
            />
            <EnhancedDropDownRHFWithLabel
              selectName="t4aOtherInformation6"
              label=""
              testId={`${testId}-other-information-list`}
              items={T4AOtherInformationList ?? []}
            />
          </>
        </FieldLayout>
      </FieldWrapper>
      <EnhancedDropDownRHFWithLabel
        label="Use"
        selectName="use"
        items={[
          {
            label: "Personal",
            id: "personal-option",
            value: true,
          },
          {
            label: "Business",
            id: "business-option",
            value: false,
          },
        ]}
        selectedItem={true}
        testId={`${testId}-payee-type`}
        labelPosition={LabelPosition.Left}
        
      />
    </Grid>
  );
};
