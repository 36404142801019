import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import "./undoMergeReaourceTableStyle.css";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Card } from "primereact/card";
import { ResourceService } from "services/ResourceService";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { INITIAL_PAGE_SIZE, ROWS_PER_PAGE_PER_OPTIONS } from "common/constants";

export interface IDataTable {
  rows: any;
  columns: any;
  showIndexColumn: boolean;
  showChecboxColumn: boolean;
  showSettingColumn: boolean;
  onRowEditClicked: (params: GridRenderCellParams) => void;
  onRowDeleteClicked: (params: GridRenderCellParams) => void;
  isLoading?: boolean;
  // onKeepRecordRowSelected: (value: any) => void;
  onIsLoadingMerging: (value: boolean) => void;
  loadUnmergeSlipsList: (value: boolean) => void;
  onSnackbarMessageData: (value: any) => void;
}

const PrimeDataGridUndoMergeResource: React.FC<IDataTable> = ({
  rows,
  columns,
  showIndexColumn,
  showChecboxColumn,
  showSettingColumn,
  onRowEditClicked,
  // onRowDeleteClicked,
  isLoading,
  // onKeepRecordRowSelected,
  onIsLoadingMerging,
  loadUnmergeSlipsList,
  onSnackbarMessageData,
}) => {
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const [val, setValue] = useState(false);
  const [field, setField] = useState("");

  // const [selectedRows, setSelectedRows] = useState<any>([]); //multiple

  const setting = () => {
    return (
      <div style={{ marginLeft: "0.35rem" }}>
        <i className="pi pi-cog text-xl"></i>
      </div>
    );
  };
  const delEdit = (params: any) => {
    return (
      <Button
        icon="pi pi-user-edit"
        // onClick={() => onRowEditClicked(selectedItems.length > 0 ? selectedIds : params.id)}
        onClick={() =>
          onRowEditClicked(selectedItems.length > 0 ? selectedItems : params)
        }
        className="p-button-rounded p-button-text w-2rem h-2rem"
      />
    );
  };

  const maskingCol = (val: any, hName: any) => {
    if (val == true) {
      return "*****";
    } else {
      return hName;
    }
  };

  const HideButton = (option: any, value: any, field: any) => {
    if (option == true) {
      return (
        <span className="field-checkbox mt-3 mb-0">
          <Button
            label="Hide Column"
            className="p-button-secondary uppercase"
            onClick={() => clickHideBtn(value, field, true)}
          />
        </span>
      );
    } else {
      return (option = null);
    }
  };

  const clickHideBtn = (value: any, field: any, is_load: any) => {
    if (is_load) {
      if (value.field === field) {
        setValue(value);
        setField(field);
      }
    } else {
      return false;
    }
  };

  console.log("props headerColumns => ", columns);
  console.log("props items RL1AdjustmentReport => ", rows);
  console.log("selectedItemsselectedItems", selectedItems);

  // const rowExpansionTemplate = (data: any) => {
  //   let isSelected = false;

  //   selectedRows.some((row: any) => {
  //     isSelected = row.id === data.id;
  //     return isSelected;
  //   });
  //   return (
  //     <input
  //       type="radio"
  //       checked={isSelected}
  //       onChange={() => handleSingleSelectionc(data)}
  //       style={{
  //         height: "16px",
  //         width: "16px",
  //         display: "flex",
  //         margin: "0 2.3rem",
  //       }}
  //     />
  //   );
  // };

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Employee Info"
          colSpan={3}
          style={{
            position: "sticky",
            left: "0px",
            zIndex: 1,
            backgroundColor: "#e3fcef",
          }}
        />
        <Column
          header=""
          colSpan={6}
          style={{ cursor: "auto", backgroundColor: "#e3fcef" }}
        />
        <Column
          header="T4 Slips"
          colSpan={4}
          style={{ cursor: "auto", backgroundColor: "#fffae6" }}
        />
        <Column
          header="RL-1 Slips"
          colSpan={4}
          style={{ cursor: "auto", backgroundColor: "#deebff" }}
        />
        {/* <Column
          header=""
          colSpan={1}
          style={{
            cursor: "auto",
            position: "sticky",
            right: "0px",
            zIndex: 1,
          }}
        /> */}
      </Row>
      <Row>
        <Column
          selectionMode="multiple"
          hidden={showChecboxColumn}
          frozen
          resizeable={false}
          style={{ maxWidth: "65px" }}
          field="selection"
          columnKey="selection"
          headerStyle={{ backgroundColor: "#e3fcef" }}
        ></Column>

        {/* <Column
          headerStyle={{ backgroundColor: "#e3fcef" }}
          header="Keep Record"
          frozen
          body={rowExpansionTemplate}
        /> */}
        {columns !== undefined &&
          columns.map((x: any, index: any) => (
            <Column
              field={x.field}
              header={x.headerName && maskingCol(x.maskable, x.headerName)}
              headerTooltip={x.description}
              headerTooltipOptions={{ position: "top" }}
              // flex (This property is not use Yet)
              hidden={
                val === x.hide
                  ? clickHideBtn(x, x.field, false)
                  : x.field === field
                  ? val
                  : x.hide
              }
              sortable={x.sortable}
              resizeable={x.resizable}
              dataType={x.type}
              align={x.align}
              alignHeader={x.headerAlign}
              // hideSortIcons (This property is not use Yet)
              // filterElement={HideButton(x.hideable, x, x.field)}
              // showFilterMenuOptions
              // filter  
              // showFilterMenu={x.disableColumnMenu}
              // filterPlaceholder="Search by name"
              reorderable={x.disableReorder}
              exportable={x.disableExport}
              frozen={x.frozenColumn}
              columnKey={x.field}
              style={{
                minWidth: x.minWidth,
                fontSize: "0.875rem",
              }}
            />
          ))}

        <Column
          field="id"
          header="No."
          hidden={showIndexColumn}
          headerStyle={{ fontWeight: "bold" }}
          style={{ minWidth: 60 }}
          resizeable={false}
          reorderable={false}
          columnKey="id"
          frozen
        ></Column>
        <Column
          body={delEdit}
          header={setting}
          style={{ maxWidth: "65px" }}
          hidden={showSettingColumn}
          resizeable={false}
          reorderable={false}
          frozen
          alignFrozen="right"
        ></Column>
      </Row>
    </ColumnGroup>
  );

  const onMergeEmployeesClicked = async (): Promise<void> => {
    console.log("selectedItems",selectedItems)
    // console.log("selectedRows",selectedRows)
    //seperate Data according to same SIN numbers
    if(selectedItems.length > 0){
      const maskedStatus:any = sessionStorage.getItem("maskEnabled")
      if(maskedStatus === "true"){
        onSnackbarMessageData({
            open: true,
            message: "Please unmask your data.",
            severity: "error",
          });
        } else {
    const multiSelectedMergingIds = selectedItems.reduce(
      (groups: any, item: any) => {
        const groupName = item.finalSlipId;
        groups[groupName] = [...(groups[groupName] || []), item.id];
        return groups;
      },
      {}
    );

    const MultiSelectedMergingIdsData = Object.values(multiSelectedMergingIds);
    console.log("MultiSelectedMergingIdsData", MultiSelectedMergingIdsData);

    let selectedFinalSlipRowsIds = selectedItems.map((item: any) => item.finalSlipId);
    console.log("selectedFinalSlipRowsIds", selectedFinalSlipRowsIds);
    // Use a Set to store unique IDs
    let uniqueIds = new Set<string>();
    selectedFinalSlipRowsIds.forEach((id:any) => {
      uniqueIds.add(id);
    });
    // Convert the set back to an array
    let FinalSlipIds = Array.from(uniqueIds);
    console.log("uniqueIdArray", FinalSlipIds);


    for (let i = 0; i < FinalSlipIds.length; i++) {
        const currentFinalSlipId: any = FinalSlipIds[i];
        let filteredMultiselectedItems = selectedItems.filter((item:any) => item.finalSlipId === FinalSlipIds[i]);
        let filteredAllItemsWithSameFinalSlipId = finalSlipIdFlattenedRows.filter((item:any) => item.finalSlipId === FinalSlipIds[i]);

        if(filteredAllItemsWithSameFinalSlipId.length === filteredMultiselectedItems.length){
          let currentItemData = selectedItems.filter((item:any) => item.finalSlipId === FinalSlipIds[i]);
          console.log(`all items with final slip id ${FinalSlipIds[i]} is selected    = `,currentItemData)
          let mergingidsList = currentItemData.map((id:any) => id.id)
          console.log("mergingidsList",mergingidsList)
          const ids_list = mergingidsList.map((ids: any) => `merging_ids=${ids}`).join("&");
          const mergingIds: any = ids_list.replace(/^&+|&+$/g, "");
          console.log("mergingIds", mergingIds);
          console.log("currentFinalSlipId",currentFinalSlipId)

          // onSnackbarMessageData({
          //   open: true,
          //   message: `Slip with SIN: ${currentItemData[0].resource.socialInsuranceNumber} Unmerged`,
          //   severity: "success",
          // });

              try {
                onIsLoadingMerging(true);
                const response = await ResourceService.unMergeSelectedResource(
                  clientId,
                  taxYear,
                  mergingIds,
                  // matchingValue,
                  // finalSlipResourceData
                );

                console.log("responseData", response);
                onSnackbarMessageData({
                  open: true,
                  message: `Slip with SIN: ${currentItemData[0].resource.socialInsuranceNumber} Unmerged`,
                  severity: "success",
                });
              } catch (err) {
                console.log("errerrerr", err);
                onSnackbarMessageData({
                  open: true,
                  message: "Somthing went wrong!",
                  severity: "error",
                });
              } finally {
                onIsLoadingMerging(false);
                loadUnmergeSlipsList(true)
              }
              // onIsLoadingMerging(false);
            // }
        } else {
          let currentItemData = selectedItems.filter((item:any) => item.finalSlipId === FinalSlipIds[i]);
          onSnackbarMessageData({
            open: true,
            message: `Please select all Slips with SIN: ${currentItemData[0].resource.socialInsuranceNumber} to Unmerged`,
            severity: "error",
          });
          console.log(`all items with final slip id ${FinalSlipIds[i]} is not selected`,currentItemData)
        }
        
        
        // console.log("filteredMultiselectedItems",filteredMultiselectedItems)
        // console.log("filteredAllItemsWithSameFinalSlipId",filteredAllItemsWithSameFinalSlipId)
        console.log("///one iteration complete here")
        // onIsLoadingMerging(true);
        // setTimeout(() => {
        //   onIsLoadingMerging(false);
        // }, 1000);
    }
  }
} else {
  onSnackbarMessageData({
    open: true,
    message: "Please select atleast one record!",
    severity: "error",
});
}
    // loadUnmergeSlipsList(true)
  };

  const footer = (
    <div className="flex flex-wrap justify-content-center gap-2">
      <Button
        label="Undo Merge"
        icon="pi pi-check"
        onClick={onMergeEmployeesClicked}
      />
      {/* <Button
        label="Ignore"
        icon="pi pi-times"
        className="p-button-outlined p-button-secondary"
      /> */}
    </div>
  );

  //to seperate rows group according to SIN
  const groupedData =
    rows &&
    rows.reduce((groups: any, item: any) => {
      const groupName = item.resource.socialInsuranceNumber;
      groups[groupName] = [...(groups[groupName] || []), item];
      return groups;
    }, {});
  // Flatten groupedData into an array of arrays
  const groupedRows = Object.values(groupedData);

  // Flatten the rows
  const flattenedRows = groupedRows.flat();

  const rowsWithTwoDecimalNumbers = flattenedRows && flattenedRows.map((item:any) => {
    return {
    ...item,
    t4SlipData: {
        ...item.slipData,
        employmentIncome: parseFloat(item.t4SlipData.employmentIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        cppContributions: parseFloat(item.t4SlipData.cppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // secondCppContributions: item.t4SlipData.secondCppContributions !== null && parseFloat(item.t4SlipData.secondCppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        qppContributions: parseFloat(item.t4SlipData.qppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // secondQppContributions: item.t4SlipData.secondQppContributions !== null && parseFloat(item.t4SlipData.secondQppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        eiPremiums: parseFloat(item.t4SlipData.eiPremiums).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // rppContributions: parseFloat(item.t4SlipData.rppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        incomeTaxDeducted: parseFloat(item.t4SlipData.incomeTaxDeducted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // eiInsurableEarnings: parseFloat(item.t4SlipData.eiInsurableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // cppQppPensionableEarnings: parseFloat(item.t4SlipData.cppQppPensionableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // unionDues: parseFloat(item.t4SlipData.unionDues).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // charitableDonations: parseFloat(item.t4SlipData.charitableDonations).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // registrationNumber: item.t4SlipData.registrationNumber,
        // pensionAdjustment: parseFloat(item.t4SlipData.pensionAdjustment).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // ppipPremiums: parseFloat(item.t4SlipData.ppipPremiums).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // ppipInsurableEarnings: parseFloat(item.t4SlipData.ppipInsurableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // employerOfferedDentalBenefits: parseFloat(item.t4SlipData.employerOfferedDentalBenefits).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      },
}});

  //to seperate rows group according to finalSlipId
  const finalSlipIdGroupedData =
    rows &&
    rows.reduce((groups: any, item: any) => {
      const groupName = item.finalSlipId;
      groups[groupName] = [...(groups[groupName] || []), item];
      return groups;
    }, {});
  // Flatten groupedData into an array of arrays
  const finalSlipIdGroupedRows = Object.values(finalSlipIdGroupedData);

  // Flatten the rows
  const finalSlipIdFlattenedRows = finalSlipIdGroupedRows.flat();

  //for Color code apply
  const uniqueNames = Array.from(new Set(flattenedRows.map((row:any) => row.resource.socialInsuranceNumber))); // Get unique names
  console.log("uniqueNames",uniqueNames)

  // const headerTemplate = (groupedRows: {
  //   resource: {
  //     socialInsuranceNumber:
  //       | string
  //       | number
  //   };
  // }) => {
  //   return (
  //     <>
  //       <span
  //         style={{
  //           position: "sticky",
  //           left: "0px",
  //           zIndex: 1,
  //         }}
  //       >
  //         SIN : {rows && groupedRows.resource.socialInsuranceNumber}
  //       </span>
  //     </>
  //   );
  // };

  // const handleSingleSelectionc = (rowData: any) => {
  //   console.log("rowData", rowData);
  //   if (rows) {
  //     setSelectedRows((prevSelected: any) => {
  //       const selectedGroupName = rowData.resource.socialInsuranceNumber;

  //       // Remove previously selected rows from the same group
  //       const newSelected = prevSelected.filter(
  //         (row: any) => row.resource.socialInsuranceNumber !== selectedGroupName
  //       );
  //       // Add the current row to the selected list
  //       return [...newSelected, rowData];
  //     });
  //   }
  // };

  console.log("groupedRows", groupedRows);
  console.log("finalSlipIdGroupedRows", finalSlipIdGroupedRows);
  console.log("finalSlipIdFlattenedRows", finalSlipIdFlattenedRows);
  console.log("rows", rows);
  // console.log("selectedRows", selectedRows);
  // onKeepRecordRowSelected(selectedRows);
  return (
    <Card
      title="Undo Merge Employee"
      subTitle="Following employees has been merged."
      footer={footer}
      className="mt-5"
    >
      <DataTable
        value={rows && flattenedRows && rowsWithTwoDecimalNumbers}
        responsiveLayout="scroll"
        paginator
        rows={INITIAL_PAGE_SIZE}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
        selection={selectedItems}
        onSelectionChange={(e: any) => setSelectedItems(e.value)}
        editMode="row"
        showGridlines
        resizableColumns
        columnResizeMode="expand"
        scrollable
        // scrollHeight="450px"
        reorderableColumns
        filterDisplay="menu"
        loading={isLoading}
        globalFilterFields={["headerName"]}
        emptyMessage="No Record found."
        // className='m-2'
        className="mergeResourcesDataTable"
        headerColumnGroup={headerGroup}
        style={{ margin: "0.5rem" }}
        rowClassName={(rowData:any) => `row-${uniqueNames.indexOf(rowData.resource.socialInsuranceNumber) % 2 === 0 ? 'red' : 'yellow'}`}

        // rowGroupMode="subheader" groupRowsBy='resource.socialInsuranceNumber' sortMode='single' sortField='resource.socialInsuranceNumber'
        //             sortOrder={1}
        //             rowGroupHeaderTemplate={headerTemplate}
      >
        <Column
          selectionMode="multiple"
          hidden={showChecboxColumn}
          frozen
          resizeable={false}
          style={{ maxWidth: "65px" }}
          field="selection"
          columnKey="selection"
          headerStyle={{ backgroundColor: "#e3fcef" }}
        ></Column>
        
        {/* <Column
          headerStyle={{ backgroundColor: "#e3fcef" }}
          header="Keep Record"
          frozen
          body={rowExpansionTemplate}
        /> */}

        {columns !== undefined &&
          columns.map((x: any, index: any) => (
            <Column
              key={index}
              field={x.field}
              header={x.headerName}
              headerTooltip={x.description}
              headerTooltipOptions={{ position: "top" }}
              // flex (This property is not use Yet)
              hidden={
                val === x.hide
                  ? clickHideBtn(x, x.field, false)
                  : x.field === field
                  ? val
                  : x.hide
              }
              sortable={x.sortable}
              resizeable={x.resizable}
              dataType={x.type}
              align={x.align}
              alignHeader={x.headerAlign}
              // hideSortIcons (This property is not use Yet)
              // filterElement={HideButton(x.hideable, x, x.field)}
              // showFilterMenuOptions
              // filter
              // showFilterMenu={x.disableColumnMenu}
              // filterPlaceholder="Search by name"
              reorderable={x.disableReorder}
              exportable={x.disableExport}
              frozen={x.frozenColumn}
              columnKey={x.field}
              style={{
                minWidth: x.minWidth,
                fontSize: "0.875rem",
              }}
              footer={x.footer}
              body={rows.employmentIncome}
            />
          ))}

        <Column
          field="id"
          header="No."
          hidden={showIndexColumn}
          headerStyle={{ fontWeight: "bold" }}
          style={{ maxWidth: 60, padding: "16px 25px" }}
          resizeable={false}
          reorderable={false}
          frozen={true}
          columnKey="id"
        ></Column>
        <Column
          body={delEdit}
          header={setting}
          style={{ maxWidth: "65px" }}
          hidden={showSettingColumn}
          resizeable={false}
          reorderable={false}
          frozen
          alignFrozen="right"
        ></Column>
      </DataTable>
    </Card>
  );
};

export default PrimeDataGridUndoMergeResource;

//resizeablbe must be false if frozen is true and viseversa
