import { RL16SlipSetup } from "components/RL16Slip/RL16SlipSetup";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL16SlipSetupPage {}

export const RL16SlipSetupPage: FC<IRL16SlipSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Create RL-16 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl16/list" },
        { text: "RL-16", navigateTo: "/taxslip/rl16/list" },
        { text: "Create RL-16 Slip", navigateTo: "/taxslip/rl16/setup" },
      ]}
      mainContents={<RL16SlipSetup testId="rl16-slip-setup" />}
    />
  );
};
