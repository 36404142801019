import {
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
  TAX_PROFILE_LIST_SCREEN_SLUG,
} from "common/constants";
import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useState } from "react";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TaxProfileService } from "services/TaxProfileService";
import { IAppState } from "store/reducers";

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Grid, Button, useTheme } from "@mui/material";
import { DeleteConfirmationDialog, EmptyList, EnhancedSnackbar, IResponse, IServerError, RouterLinkButton } from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface ITaxProfileList {}
const TAX_PROFILE_LIST_QUERY_KEY = "tax-profile-list";

const useTaxProfiles = (params: IGetListRequest) =>
  useQuery(
    ["tax-profile-list", params],
    (data) => {
      return TaxProfileService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

export const TaxProfileList: React.FC<ITaxProfileList> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

   // for delete row Purpose
   const queryClient = useQueryClient();
   const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "role_name",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useTaxProfiles(queryParams); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const dataRolesList: any = data;
  console.log("@@@@@ => accountantList", dataRolesList);

  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);
    // for delete row
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [taxProfileId, setTaxProfileId] = useState<number>(0);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.debouncedSearchQuery,
      order_by: "role_name",
      order_direction: "asc",
      page: requestParams.currentPage + 1,
      size: requestParams.pageSize,
    };
    if (requestParams.sort) {
      const [sortBy, sortDirection] = requestParams.sort.split(",");
      dispatchParams.order_by = toSnake(sortBy);
      dispatchParams.order_direction = sortDirection;
    }

    setQueryParams(dispatchParams);
  }, []);

  const onRowEditClicked = useCallback((params) => {
    // alert(`Edit row with ID: ${params.row.id} has been clicked!`);
    let path = `/tax-profile/setup/${params.row.id}`;
    navigate(path);
  }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const callAction = () => {
    if (apiAction === "delete") {
      alert(`Delete row with ID: ${selectedIds} has been clicked!`);
      // TODO api call for delete
    } else if (apiAction === "updateStatus") {
      alert(`action need to trigger : ${apiAction}`);
      // TODO api call for update status
    }
    setDialogOpen(false);
  };

  // For Delete Row
  const deleteTaxProfileField = () => {
    if (singleDelete) {
      setSingleDelete(false);
      deleteTaxProfileById(taxProfileId);
      setDialogOpen(false);
    }
  };

  const onRowDeleteClicked = useCallback((params) => {
    setDialogOpen(true);
    setSingleDelete(true);
    setApiAction("delete");
    setTaxProfileId(params.row.id);
    console.log("TaxProfileId in TaxProfile List OnDeleteRowClicked function",params.row.id)
    // alert(`Delete row with ID: ${params.row.id} has been clicked!`);
  }, []);

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const { isLoading: isDeleting, mutate: deleteTaxProfileById } = useMutation(
    TaxProfileService.delTaxProfile,
    {
      onSuccess: (res: string | IServerError | IResponse) => {
        if (typeof res === "string") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: "Record has been deleted successfully.",
            severity: "success",
          });

          return queryClient.invalidateQueries(
            TAX_PROFILE_LIST_QUERY_KEY
          );
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusValue(event.target.value as string);
    setApiAction("updateStatus");
    setDialogOpen(true);
  };

  const selectionToolbar = useCallback((selectedItems) => {
    setSelectedIds(selectedItems);
    if (selectedItems.length) {
      return (
        <Box sx={{ marginTop: theme.spacing(1) }}>
          <Button variant="outlined" startIcon={<DeleteIcon />} color="error">
            Delete
          </Button>
        </Box>
      );
    }
    return (
      <Box sx={{ marginTop: theme.spacing(1) }}>
        <RouterLinkButton to="/tax-profile/setup" text="Add Tax Profile" />
      </Box>
    );
  }, []);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: TAX_PROFILE_LIST_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=delete",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  const image =
  "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";
  
  return dataRolesList && dataRolesList.items.length === 0 ? (
    <EmptyList 
    showImage={true}
    imagePath= {image}
    heading={`There's nothing! Add Tax Profile to proceed`} 
    content={`You can add tax profile by directly clicking on add tax profile button.`} 
    testId={"tax profile-empty-list"} 
    showAddButton= {true}
    addButtonLabel={"Add Tax Profile"}
    navigateToAdd={"/tax-profile/setup"}
    showImportButton={false}
    showImportXmlButton={false}
    />
  ) : (
    <>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
      <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={deleteTaxProfileField}
          cancelActionHandler={onCloseHandler}
          testId="tax-profile-tab-confirmation-dialog"
        />
      <EnhancedDataGrid
        columns={dataRolesList ? dataRolesList.headerColumns : []}
        rows={dataRolesList?.items}
        selectionEnabled={true}
        autoHeight={true}
        allowSearching={true}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        selectionToolbar={selectionToolbar}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        // initialPage={0}
        initialPageSize={INITIAL_PAGE_SIZE}
        disableColumnMenu={true}
        showRowActionsColumn={true}
        autoPageSize={false} //
        showIndexColumn={true}
        testId="tax-profile-list-grid"
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onRequestParamsChange={onRequestParamsChange}
        totalRowsCount={dataRolesList?.total}
        isLoading={isLoading || isDeleting}
        rowIdGetter={(row) => row.id}
        searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
        shouldMask={false}
      />
    </Grid>
    <EnhancedSnackbar
    message={snackbarData.message}
    onCloseHandler={onSnackbarClosed}
    severity={snackbarData.severity}
    testId={"tax-profile-tab-list-snackbar"}
    open={snackbarData.open}
  />
  </>
  );
};
