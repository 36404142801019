import React, { useCallback, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { ResourceService } from "services/ResourceService";
import { IAppState } from "store/reducers";


import {
  INITIAL_SNACKBAR_DATA,
} from "common/constants";
import { BackdropCircularProgress, EnhancedSnackbar } from "@websential/cosmic";
import PrimeDataGridUndoMergeResource from "./PrimeDataGridUndoMergeResource";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IUndoMergeResource {}
const useUndoMergeResources = (
  clientId: string,
  taxYear: number,
//   params: IGetListRequest
) =>
  useQuery(
    [`undo-merge-resource-list`],
    () => {
      return ResourceService.getUndoMergeList(clientId, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

export const UndoMergeResource: React.FC<IUndoMergeResource> = () => {
  const dispatch = useDispatch();
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);

  const[isLoadingMerging, setIsLoadingMerging] = useState(false)
  const[loadUnmergeSlipsList, setLoadUnmergeSlipsList] = useState(false)
  const[UnmergeSlipsList, setUnmergeSlipsList] = useState<any>(undefined)

  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  const { isLoading, data } = useUndoMergeResources(
    clientId,
    taxYear
  ); 
  const UndoMergeResourceList: any = data;
  console.log("@@@@@ => UndoMergeResourceList", UndoMergeResourceList);

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
  useEffect(()=>{
    if(isLoading){
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    } else {
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    }
  },[isLoading])

useEffect(()=>{
  if(UndoMergeResourceList){
    setUnmergeSlipsList(UndoMergeResourceList)
  }
},[UndoMergeResourceList])


  const onRowEditClicked = useCallback((params) => {
    console.log("paprams onRowEditClicked",params)
    // setSelectedRecord(params)
  }, []);

  const onRowDeleteClicked = useCallback(() => {

  }, []);

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // useEffect(() => {
    if(loadUnmergeSlipsList === true){
      setIsLoadingMerging(true);
      setTimeout(() => {
        setIsLoadingMerging(false);
      }, 2000);
    const fetchData = async () => {
      try {
        const response: any = await ResourceService.getUndoMergeList(clientId, taxYear);
        console.log("responseData-----------------", response);
        setUnmergeSlipsList(response)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    setLoadUnmergeSlipsList(false)
  }
    console.log("loadUnmergeSlipsList",loadUnmergeSlipsList)
  // }, []);
  console.log("loadUnmergeSlipsList",loadUnmergeSlipsList)

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:'',
      companyName:'',
      activityType:"activity_type=",
      activityLogVisibility:false
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[])

  return(
    <>
    <BackdropCircularProgress
          open={
            isLoadingMerging
          }
          testId="UndoMergeResource"
        />
    <PrimeDataGridUndoMergeResource
        rows={UnmergeSlipsList !== undefined && UnmergeSlipsList?.items}
        columns={UnmergeSlipsList ? UnmergeSlipsList.headerColumns : []}
        showIndexColumn={true}
        showChecboxColumn={false}
        showSettingColumn={true}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        isLoading={isLoading || isAxiosCallLoading}
        // onKeepRecordRowSelected={(value:any) => setKeepRecordRow(value)}
        onIsLoadingMerging={(value:any) => setIsLoadingMerging(value)}
        loadUnmergeSlipsList={(value:any) => setLoadUnmergeSlipsList(value)}
        onSnackbarMessageData={(value:any) => setSnackbarData(value)}
      />
      <EnhancedSnackbar
          message={snackbarData.message}
          onCloseHandler={onSnackbarClosed}
          severity={snackbarData.severity}
          testId={`UndoMergeResource-snackbar`}
          open={snackbarData.open}
        />
    </>
  );
};
