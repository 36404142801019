import { GET_QUERY_KEY, QUERY_OPTIONS } from 'common/constants';
import { useQuery } from 'react-query';
import { LookupFieldService } from 'services/LookupFieldService';

export const useGetLookupFieldData = (
  lookupFieldId: string
) => {
  return useQuery(
    [GET_QUERY_KEY.LOOKUP_FIELD_DATA_BY_ID, lookupFieldId],
    () =>
      LookupFieldService.getByIdd(lookupFieldId),
    QUERY_OPTIONS
  );
};
