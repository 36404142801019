import { T3SlipSetup } from "components/T3Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT3SlipSetupPage {}

export const T3SlipSetupPage: FC<IT3SlipSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Create T3 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t3/list" },
        { text: "T3", navigateTo: "/taxslip/t3/list" },
        { text: "Create T3 Slip", navigateTo: "/taxslip/t3/setup" },
      ]}
      mainContents={<T3SlipSetup testId="t4-slip-setup" />}
    />
  );
};
