import { FC } from "react";

import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Toolbar } from "@mui/material";
import {
  ResetButton,
  SubmitButton,
} from "@websential/cosmic";

export interface IPDFAxisToolbar {
  onResetClicked: () => void;
  isSubmitting: boolean;
}
export const PDFAxisToolbar: FC<IPDFAxisToolbar> = ({
  onResetClicked,
  isSubmitting = false,
}) => {
  return (
    <Toolbar >
      <>
        <div style={{display:"contents"}}>
          <div style={{margin:"1rem 0rem"}}>
          
            <SubmitButton
              label="Submit"
              testId="submit-button"
              loading={isSubmitting}
              icon={<PlaylistAddCheckOutlinedIcon />}
              
            />
          </div>
          <ResetButton testId="reset-button" onClickHandler={onResetClicked} />
        </div>
        <div style={{ flexGrow: 1 }} />
        <div>
          <></>
        </div>
      </>
    </Toolbar>
  );
};
