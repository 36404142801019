 import {
  EMPLOYEE_RESOURCE_LITERAL,
  GET_QUERY_KEY,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  RL1_SETUP_SCREEN_ID,
  RL1_SETUP_SCREEN_SLUG,
} from "common/constants";
import {
  instanceOfIFlexFieldSetupRequest,
  instanceOfIRL1SlipResponce,
} from "common/instance-method";
import { useGetResourceDDLData } from "hooks/useResources";
import { IDropDownListResponse } from "models";
import { IResponse, IServerError } from "models/common";
import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { FlexFieldService } from "services/FlexFieldService";
import { IAppState } from "store/reducers";
import FileSaver from "file-saver";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import {
  BackdropCircularProgress,
  EnhancedDropDownRHFWithLabel,
  EnhancedIOSSwitchRHFWithLabel,
  EnhancedSnackbar,
  FieldLayout,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  StandardPanel,
  TwoColumnLayout,
  DeleteConfirmationDialog,
} from "@websential/cosmic";
import {
  EnhancedDialog,
  EnhancedLinkButton,
  IconTextButton,
  RouterLinkButton,
  SplitButton,
} from "@websential/cosmic";

import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions";

import { EmployeeAddressSetupFieldsPanel } from "./Panels/EmployeeAddressSetupFieldsPanel";
import { EmployeeInfoSetupFieldsPanel } from "./Panels/EmployeeInfoSetupFieldsPanel";
import { FilingDataSetupFieldsPanel } from "./Panels/FilingDataSetupFieldsPanel";
import { T4SlipDataPanel } from "./Panels/T4SlipDataPanel";
import { RL1SlipSetupToolbar } from "./RL1SlipSetupToolbar";
import { RL1SlipSetupValidationSchema } from "./validator";
import { RL1SlipService } from "services/RL1SlipService";
import { IRL1SlipRequestPayload } from "models/rl1Slip/IRL1SlipRequestPayload";
import { ICountryReferenceModel } from "models/country/ICountryReferenceModel";
import { useNavigate, useParams } from "react-router-dom";
import { IProvinceOfEmployment } from "models/common/IProvinceOfEmployment";
import { T4SlipService } from "services/T4SlipService";
import { PrintingService } from "services/PrintingService";
import { format } from "date-fns";
import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { Calendar } from "primereact/calendar";
import { RL1OtherInformationService } from "components/OtherInformation/otherInformations";
import { FilteredSelectedOtherInfoRL1 } from "./Panels";
import { InvoiceDialogPanel } from "components/T4Slip/T4SlipList/InvoiceDialogPanel";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import { TaxSlipSettingsService } from "services/TaxSlipSettingsService";
import Card from "@mui/material/Card";
import { Divider } from "primereact/divider";
import { Avatar } from "primereact/avatar";
import "./RL1SlipSetupStyle.css";
import NativeSelect from "@mui/material/NativeSelect";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { ClientService } from "services";

export let selectedRL1SlipType: any = 400;
export interface IRL1SlipSetup extends ITest {}
const SINGLE_RECORD_DATA_QUERY_KEY = "rl1-slips-single-data-record";

const SINGLE_Employee_DATA_QUERY_KEY = "employee-data";
const useGetData = (
  id: string | undefined,
  clientId: string,
  taxYear: number
) => {
  return useQuery(
    [SINGLE_RECORD_DATA_QUERY_KEY, id],
    () => RL1SlipService.getRL1SlipById(id, clientId, taxYear),
    QUERY_OPTIONS
  );
};

const useGetSlipRecord = (
  slipId: string,
  clientId: string,
  taxYear: number
) => {
  return useQuery(
    [GET_QUERY_KEY.EMPLOYEE_RESOURCE_SLIP_DETAIL, slipId],
    () => RL1SlipService.getById(slipId, clientId, taxYear),
    { ...QUERY_OPTIONS, enabled: false }
  );
};

const useGetResourceSlips = (
  resourceId: string,
  clientId: string,
  taxYear: number
) => {
  return useQuery(
    [GET_QUERY_KEY.EMPLOYEE_RESOURCE_SLIPS_DDL, resourceId],
    () =>
      RL1SlipService.getResourceSlipDropDownList(resourceId, clientId, taxYear),
    { ...QUERY_OPTIONS, enabled: false }
  );
};

const useGetResourceInformationById = (
  resourceId: string,
  clientId: string,
  roleName: string
) => {
  return useQuery(
    [SINGLE_Employee_DATA_QUERY_KEY, resourceId, clientId, roleName],
    () =>
      RL1SlipService.getResourceInformationById(resourceId, clientId, roleName),
    QUERY_OPTIONS
  );
};

const useGetGlobalVariables = (taxYear: number) =>
  useQuery(
    [GET_QUERY_KEY.GET_GLOBAL_VARIABLES_BY_YEAR, taxYear],
    (data) => {
      return TaxSlipSettingsService.getGlobalVariables(taxYear);
    },
    { refetchOnWindowFocus: false }
  );

  const useGetSlipIds = (
    tag: string,
    clientId: string,
    taxYear: number
  ) =>
    useQuery(
      ["get-slip-ids", tag, clientId, taxYear],
      (data) => {
        return T4SlipService.getSlipIds(tag, clientId, taxYear);
      },
      { refetchOnWindowFocus: false }
    );

    const useGetCompanyData = (id: string | undefined) => 
      useQuery(
        ["client-get-by-id", id],
        (data) => {
          return ClientService.getById(id);
        },
        { refetchOnWindowFocus: false }
      );

export const RL1SlipSetup: FC<IRL1SlipSetup> = ({ testId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);
  const clientId = useSelector((state: IAppState) => state.client.id);
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );
  const [invoicePaymentFor, setInvoicePaymentFor] = useState(10)

  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [adjustment, setAdjustment] = useState(false);
  const [isLoadingAdjustedData, setIsLoadingAdjustedData] = useState(false);
  const [isLoadingSlipListDDL, setIsLoadingSlipListDDL] = useState(false);
  const [selectedResourceId, setSelectedResourceId] = useState("");
  const [selectedResource, setSelectedResource] = useState("create-new");
  const [selectedResourceSlip, setSelectedResourceSlip] =
    useState("create-new");

    const [onResourceSelectionAPIsCalls, setOnResourceSelectionAPIsCalls] =
    useState(false);
  const [selectedResourceSlipList, setSelectedResourceSlipList] = useState<
    IDropDownListResponse[]
  >([{ label: "Create New", value: "create-new" }]);
  const [openInvoiceDialog, setInvoiceDialogOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [isLoadingDataa, setIsLoadingData] = useState(false);
  const [resourceListWithoutCreateNew, setResourceListWithoutCreateNew] =
    useState<any>(undefined);

  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [itemSearch, setItemSearch] = useState("");
  const [sidePanelsVisibility, setSidePanelsVisibility] = useState(true);

  const [selectedResourceList, setSelectedResourceList] = useState<
    IDropDownListResponse[]
  >([{ label: "Create New", value: "create-new" }]);
  const [EditedResourceId, setEditedResourceId] = useState("");
  // const [newCreatedSlipId, setNewCreatedSlipId] = useState('');
  // const [newCreatedResourceId, setNewCreatedResourceId] = useState('');
  const [selectedOtherInformation, setSelectedOtherInformation] =
    useState(false);
  // const [editedRecordSlipCreateNew, setEditedRecordSlipCreateNew] = useState(false);

  // for delete row
  const [openDialog, setDialogOpen] = useState(false);
  const [apiAction, setApiAction] = useState("");
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [rL1SlipId, setRL1SlipId] = useState<any>("");
  const [oiInEdit, setOiInEdit] = useState(false);

  //one state which store both new cretaed and edited slip data
  const [RL1SlipRecord, setRL1SlipRecord] = useState<any>(undefined);
  // when  I setValue of otherinformation inside async calls method then facing issue while click on add more so we have to use state method to show otherinformation 
  const [slipOtherInformation, setSlipOtherInformation] =
    useState<any>([]);

  const DEFAULT_VALUES = {
    clientId: clientId,
    taxYear: taxYear,
    //   comparison: [
    //     {
    //       operator: undefined,
    //       field: undefined,
    //     },
    //   ],
    //   fieldType: undefined,
    //   fieldLabel: "",
    //   fieldId: "",
    //   defaultValue: "",
    //   placeholder: "",
    //   fieldFormat: "",
    //   fieldValue: "",
    //   dataType: undefined,
    //   fieldColumn: undefined,
    //   parentRelation: undefined,
    //   fieldInternalValue: "",
    //   notes: "",
    //   minValue: 0,
    //   maxValue: 0,
    //   isCategory: false,
    //   isFixed: false,
    //   isHide: false,
    //   isRequired: false,
    //   parentValue: "",
    //   parent: undefined,
    //   id: undefined,
  };

  const [CountryResponse] = useState<ICountryReferenceModel>({
    id: 1,
    name: "Canada",
    shortCode: "CAN",
  });
  const [provinceOfEmploymentRes] = useState<IProvinceOfEmployment>({
    id: 11,
    name: "Quebec",
    shortCode: "QC",
  });

  const [dateOfBirth, setDateOfBirth] = useState<any>("");

  console.log("dateOfBirthdateOfBirth", dateOfBirth);

  // for edit
  const queryClient = useQueryClient();
  const { id } = useParams();
  console.log("id from setup file which clicked : ", id);
  let notStartedSlipId = "";
  let completedSlipId = "";
  if (id !== undefined && onResourceSelectionAPIsCalls === false) {
    if (id?.length > 24) {
      notStartedSlipId = id;
    }
    if (id?.length === 24) {
      completedSlipId = id;
    }
  }

  // Function to sort the data array by the label field
// sort side cards items 
const sortData = (data: IDropDownListResponse[]): IDropDownListResponse[] => {
  return data.sort((a, b) => a.label.localeCompare(b.label));
};

  const { isLoading: isSubmitting, mutate: submitFlexFields } = useMutation(
    RL1SlipService.create,
    {
      onSuccess: (
        res: IRL1SlipRequestPayload | IServerError | IResponse | any
      ) => {
        console.log("res create", res);
        //   if(res.status === 200){
        //     console.log("res create inside 200",res)
        //     let responce = res.data
        //     if (instanceOfIRL1SlipResponce(responce)) {
        //       console.log("res create inside 200 if",res)
        //     queryClient.invalidateQueries([
        //       SINGLE_RECORD_DATA_QUERY_KEY,
        //       responce.id,
        //     ]);
        //     formMethods.setValue("id", responce.id);
        //     setSnackbarData({
        //       open: true,
        //       message: "Data has been submitted successfully!",
        //       severity: "success",
        //     });
        //   }
        // } else if(res.status === 201){
        //     let responce = res.data
        //     console.log("res create inside 201",res)
        //     if (instanceOfIRL1SlipResponce(responce)) {
        //       console.log("res create inside 201 if",res)
        //       queryClient.invalidateQueries([
        //         SINGLE_RECORD_DATA_QUERY_KEY,
        //         responce.id,
        //       ]);
        //       formMethods.setValue("id", responce.id);
        //     setSnackbarData({
        //       open: true,
        //       message: "Data has been submitted successfully!",
        //       severity: "success",
        //     });
        //   }
        // } else {
        //     setSnackbarData({
        //       open: true,
        //       // message: "Something went wrong, try again later!",
        //       message: res.message,
        //       severity: "error",
        //     });
        //   }
        if (res.data && res.data.id) {
          if (res.data && instanceOfIRL1SlipResponce(res.data)) {
            // remove the cache of the flex fields since we just added a new field.
            // queryClient.invalidateQueries(LIST_FIELDS_QUERY_KEY);
            // // [corner case] remove the cache of the field that has just been edited in-case the user re-selects it again
            // queryClient.invalidateQueries([FLEX_FIELD_DATA_QUERY_KEY, res.data.id]);
            // set the currently added/edited flex field as the selected field
            queryClient.invalidateQueries([
              SINGLE_RECORD_DATA_QUERY_KEY,
              res.data.id,
            ]);
            console.log("res.data", res.data);
            console.log("res.data.id", res.data.id);

            formMethods.setValue("id", res.data.id);
            setSnackbarData({
              open: true,
              message: "Data has been submitted successfully!",
              severity: "success",
            });
            refectchRL1SlipIds()
            if(res.status === 201 && selectedResourceSlip === "create-new"){
              (async () => {
                try {
                  console.log("ïnside-=",selectedResourceSlip)
                  const response: any =
                    await RL1SlipService.getResourceSlipDropDownList(
                      res.data?.resource?.externalId,
                      clientId,
                      taxYear
                    );
                    if (response && Array.isArray(response)) {
                      let resourceSlipList: IDropDownListResponse[] =
                        response.map((resourceSlip: IDropDownListResponse) => ({
                          label: resourceSlip.label,
                          value: resourceSlip.value,
                        }));
  
                      resourceSlipList.unshift({
                        label: "Create New",
                        value: "create-new",
                      });
  
                      console.log("resourceSlipListDDL", resourceSlipList);
                      setSelectedResourceSlipList(resourceSlipList);
                      formMethods.setValue(
                        "selectedSlip",
                        res.data.id
                      );
                      // createNewActionFromSlipDDL();
                      // formMethods.setValue("comparison", [
                      //   { amount: 0, code: "", label: "" },
                      // ]);
                      let path = `/taxslip/rl1/setup/${res.data.id}`;
                      navigate(path);
                    }
                  } catch (error) {
                    console.error("Error fetching data:", error);
                  }
                })();
              setOnResourceSelectionAPIsCalls(true)
              setSelectedResourceSlip(res.data.id)
            }
          }
        } else {
          setSnackbarData({
            open: true,
            message: res.message ? res.message : res.data.message,
            severity: "error",
          });
        }
        //Load EMployee DDL
        const fetchData = async () => {
          try {
            const response: any = await RL1SlipService.getEmployeeDDL(
              clientId,
              EMPLOYEE_RESOURCE_LITERAL,
              taxYear
            );
            console.log("responseData-----------------", response);

            if (response && Array.isArray(response)) {
              let resources: IDropDownListResponse[] = response.map(
                (resourceItem: IDropDownListResponse) => ({
                  // id: resourceItem.id,
                  label: resourceItem.label,
                  value: resourceItem.value,
                })
              );

              resources.unshift({
                label: "Create New",
                value: "create-new",
              });

              setSelectedResourceList(resources);
            }
            setResourceListWithoutCreateNew(sortData(response));
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };

        fetchData();

        //load slip Data
        if (res.data && res.data?.resource) {
          setRL1SlipRecord(res.data);
          setSelectedResource(res.data?.resource?.externalId);
          // Object.keys(res).forEach((fieldName) => {
          //   console.log("fieldName useEffect T4 Slip : ", fieldName);
          //   // @ts-ignore
          //   const fieldValue = res[fieldName];
          //   console.log("fieldValue useEffect T4 Slip : ", fieldValue);
          //   // @ts-ignore

          //   if (typeof fieldValue !== "object") {
          //     // @ts-ignore
          //     formMethods.setValue(fieldName, fieldValue);
          //   } else if (fieldValue !== null || undefined) {
          //     console.log("object");
          //     Object.keys(fieldValue).forEach((objFieldName: any) => {
          //       console.log("objFieldName useEffect T4 Slip : ", objFieldName);
          //       // @ts-ignore
          //       const objFieldValue = fieldValue[objFieldName];
          //       console.log("objFieldValue useEffect T4 Slip : ", objFieldValue);

          //       formMethods.setValue(objFieldName, objFieldValue);
          //       if (typeof objFieldValue !== "object") {
          //         // @ts-ignore
          //         formMethods.setValue(objFieldName, objFieldValue);
          //       }

          //       if(objFieldName === 'dateOfBirth'){
          //         if(objFieldValue !== null || undefined){
          //         if(objFieldValue.charAt(2) === "*"){
          //           formMethods.setValue('dateOfBirth', objFieldValue);
          //           setDateOfBirth(objFieldValue)
          //         }else{
          //           const defaultDate = new Date(objFieldValue);
          //           formMethods.setValue('dateOfBirth', defaultDate);
          //           setDateOfBirth(formatDate(defaultDate as Date))
          //         }
          //         setDateOfBirthPlaceHolder(objFieldValue)
          //       }
          //     }
          //     if (fieldName === "resource") {
          //       if (objFieldName === "externalId") {
          //         (async () => {
          //           try {
          //             const responseAssociationId = await T4SlipService.getRoleAssociationId(
          //               objFieldValue
          //             );

          //             console.log("responseData getRoleAssociationId : ", responseAssociationId.associationId);

          //             formMethods.setValue("selectedEmployee", responseAssociationId.associationId);

          //         (async () => {
          //           try {
          //             const responseGetResId =
          //               await T4SlipService.getResourceActualId(responseAssociationId.associationId);
          //             console.log(
          //               "responseData////////",
          //               responseGetResId.resourceId
          //             );

          //             (async () => {
          //               try {
          //                 const response: any =
          //                   await RL1SlipService.getResourceSlipDropDownList(
          //                     responseGetResId.resourceId,
          //                     clientId,
          //                     taxYear
          //                   );
          //                 // console.log(
          //                 //   "responseData-----------------",
          //                 //   response[0].value
          //                 // );
          //                 // formMethods.setValue("selectedSlip", response[0].value);

          //                 if (response && Array.isArray(response)) {
          //                   let resourceSlipList: IDropDownListResponse[] =
          //                     response.map(
          //                       (resourceSlip: IDropDownListResponse) => ({
          //                         label: resourceSlip.label,
          //                         value: resourceSlip.value,
          //                       })
          //                     );

          //                   resourceSlipList.unshift({
          //                     label: "Create New",
          //                     value: "create-new",
          //                   });

          //                   console.log("resourceSlipListDDL", resourceSlipList);
          //                   setSelectedResourceSlipList(resourceSlipList);
          //                 }
          //               } catch (error) {
          //                 console.error("Error fetching data:", error);
          //               }
          //             })();
          //             // You can work with the data here or trigger other functions that depend on the fetched data
          //           } catch (error) {
          //             console.error("Error fetching data:", error);
          //           }
          //         })();

          //           } catch (error) {
          //             console.error("Error fetching data:", error);
          //           }
          //         })();
          //         setNewCreatedResourceId(objFieldValue)
          //       }
          //       }
          //       if (objFieldName === "province") {
          //         let id = objFieldValue.id;
          //         let name = objFieldValue.name;
          //         let shortCode = objFieldValue.shortCode;
          //         console.log("namename", name);

          //         formMethods.setValue(
          //           objFieldName,
          //           `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
          //         );
          //       }
          //       // if (objFieldName === "provinceOfEmployment") {
          //       //   let id = objFieldValue.id;
          //       //   let name = objFieldValue.name;
          //       //   let shortCode = objFieldValue.shortCode;
          //       //   console.log("namename", name);

          //       //   formMethods.setValue(
          //       //     objFieldName,
          //       //     `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
          //       //   );
          //       // }
          //       if (objFieldName === "country") {
          //         formMethods.setValue(
          //           objFieldName,
          //           objFieldValue.name ?? undefined
          //         );
          //       }

          //     });
          //   }
          //   if(fieldName === 'id'){
          //     setNewCreatedSlipId(fieldValue)
          //     formMethods.setValue("selectedSlip",fieldValue);
          //   }
          // });
        }

        // //set slip type to 400 because when we come back to slip without reload it will automatically be original
        selectedRL1SlipType = formMethods.getValues("slipType");

        // //otherinformation will update sselected items when its submitted
        setSelectedOtherInformation(true);
      },
      onError: (err) => {
        console.log("err,err", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  //when cretae-new seleted from slip ddl
  const createNewActionFromSlipDDL = () => {
    // setNewCreatedSlipId('')
    // if(id !== undefined){
    //   // window.location.href = "/taxslip/t4/setup";
    //   // completedSlipId = ''
    //   //when cretae new slected then id would be null so instead of PUT after this POST calls to cretae new slip to selected edited slip from t4slip list
    //   setEditedRecordSlipCreateNew(false)
    // }
    //otherinformation will update selected items
    setSelectedOtherInformation(false);

    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 1000);

    formMethods.setValue("selectedSlip", "create-new");
    formMethods.setValue("applyAdjustments", false);
    formMethods.setValue("boxARevenusDEmploi", "0.00");
    formMethods.setValue("boxBOtisationAuRRQ", "0.00");
    formMethods.setValue("boxCCotaIAssuranceEmploi", "0.00");
    formMethods.setValue("boxDCotisationAUnRPA", "0.00");
    formMethods.setValue("boxEImpotDuQuebecRetenu", "0.00");
    formMethods.setValue("boxFCotisationSyndicale", "0.00");
    formMethods.setValue("boxGSalaireAdmissibleAuRRQ", "0.00");
    formMethods.setValue("boxHCotisationAuRQAP", "0.00");
    formMethods.setValue("boxISalaireAdmissibleAuRQAP", "0.00");
    formMethods.setValue("boxJRegimePriveDassMaladie", "0.00");
    formMethods.setValue("boxKVoyages", "0.00");
    formMethods.setValue("boxLAutresAvantages", "0.00");
    formMethods.setValue("boxMCommissions", "0.00");
    formMethods.setValue("boxNDonsDeBienfaisance", "0.00");
    formMethods.setValue("boxOAutresRevenus", null);
    formMethods.setValue("boxPRegimeDassInterentreprises", "0.00");
    formMethods.setValue("boxQSalairesDifferes", "0.00");
    formMethods.setValue("boxRRevenuSitueDansUneReserve", "0.00");
    formMethods.setValue("boxSPourboiresRecus", "0.00");
    formMethods.setValue("boxTPourboiresAttribues", "0.00");
    formMethods.setValue("boxURetraiteProgressive", "0.00");
    formMethods.setValue("boxVNourritureEtLogement", "0.00");
    formMethods.setValue("boxWVehiculeAMoteur", "0.00");
    formMethods.setValue("code", null);
    formMethods.setValue("boxAAdjRevenusDEmploi", "0.00");
    formMethods.setValue("boxBAdjOtisationAuRRQ", "0.00");
    formMethods.setValue("boxCAdjCotaIAssuranceEmploi", "0.00");
    formMethods.setValue("boxEAdjImpotDuQuebecRetenu", "0.00");
    formMethods.setValue("boxHAdjCotisationAuRQAP", "0.00");
    formMethods.setValue("boxIAdjSalaireAdmissibleAuRQAP", "0.00");
    formMethods.setValue("slipStatus", 200);
    formMethods.setValue("provinceOfEmployment", undefined);
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    setSlipOtherInformation([])
  };

  const { isLoading: isLoadingGlobalVariables, data: editDataRecord } =
    useGetGlobalVariables(taxYear);
  const globalVariablesData: any = editDataRecord;

  const { isLoading:loadingSlipIds, data:rl1SlipIds, refetch:refectchRL1SlipIds } = useGetSlipIds("RL1", clientId, taxYear);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  useEffect(()=>{
    console.log("companyGetByIdRecord11",companyGetByIdRecord)
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor ? parseInt(paymentFor.value) : 10)
    }
  },[companyGetByIdRecord])

  // for edit
  //for that slips which are completed
  const { isLoading: isLoadingData, data: selectedRL1SlipsData } = useGetData(
    completedSlipId,
    clientId,
    taxYear
  );

  useEffect(() => {
    //get by id slip
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 2000);
    const fetchData = async () => {
      try {
        const response: any = await RL1SlipService.getRL1SlipById(
          completedSlipId,
          clientId,
          taxYear
        );
        console.log("responseData-----------------", response);
        setRL1SlipRecord(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedRL1SlipsData) {
      setRL1SlipRecord(selectedRL1SlipsData);
    }
  }, [selectedRL1SlipsData]);
  console.log("selectedRL1SlipsData=======", selectedRL1SlipsData);

  //for that slips which are not started yet
  const {
    isLoading: isLoadingNotStartedSlipId,
    data: selectedNotStartedResourceData,
    // refetch: refetchResourceSlips,
  } = useGetResourceInformationById(notStartedSlipId, clientId, "Employee");
  console.log("resourceInfo", selectedNotStartedResourceData);

const [RL1OtherInformationList, setRL1OtherInformationList] =
  useState<any>(undefined);
useEffect(() => {
  const fetchData = async () => {
    try {
      setIsLoadingAdjustedData(true);
      const response: any = await RL1OtherInformationService.getRL1OI();
      setRL1OtherInformationList(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }finally {
        setIsLoadingAdjustedData(false);
      }
  };
  fetchData();
}, []);

  // const { isLoading: isLoadingDropDownList, data: resourceList } =
  //   useGetResourceDDLData(clientId, EMPLOYEE_RESOURCE_LITERAL);
  //   console.log("resourceList",resourceList)

  // const {
  //   isLoading: isLoadingResourceSlips,
  //   data: resourceSlips,
  //   isFetching: reFetchingResourceSlipsData,
  //   refetch: refetchResourceSlips,
  // } = useGetResourceSlips(selectedResource, clientId, taxYear);
  // console.log("selectedResource",selectedResource)

  // const {
  //   isLoading: isLoadingResourceInfo,
  //   data: resourceInfo,
  //   // refetch: refetchResourceSlips,
  // } = useGetResourceInformationById(selectedResourceId, clientId, "Employee");
  // console.log("resourceInfo",resourceInfo)

  // const {
  //   isLoading: isLoadingSlipData,
  //   data: slipRecord,
  //   isFetching: reFetchingSlipData,
  //   refetch: refetchSlipRecord,
  // } = useGetSlipRecord(selectedResourceSlip, clientId, taxYear);
  // console.log("slipRecord",slipRecord)

  // const resourceListItems = useMemo(() => {
  //   if (resourceList && Array.isArray(resourceList)) {
  //     let resources: IDropDownListResponse[] = resourceList.map(
  //       (resourceItem: IDropDownListResponse) => ({
  //         label: resourceItem.label,
  //         value: resourceItem.value,
  //       })
  //     );

  //     resources.unshift({
  //       label: "Create New",
  //       value: "create-new",
  //     });
  //     setSelectedResourceList(resourceList)
  //     return resources;
  //   }

  //   return [
  //     {
  //       label: "Create New",
  //       value: "create-new",
  //     },
  //   ];
  // }, [resourceList]);
  // console.log("resourceListItems",resourceListItems)

  // const resourceSlipListItems = useMemo(() => {
  //   if (resourceSlips && Array.isArray(resourceSlips)) {
  //     let resourceSlipList: IDropDownListResponse[] = resourceSlips.map(
  //       (resourceSlip: IDropDownListResponse) => ({
  //         label: resourceSlip.label,
  //         value: resourceSlip.value,
  //       })
  //     );
  //     setSelectedResourceSlipList(resourceSlipList);
  //     resourceSlipList.unshift({
  //       label: "Create New",
  //       value: "create-new",
  //     });

  //     return resourceSlipList;
  //   }

  //   return [
  //     {
  //       label: "Create New",
  //       value: "create-new",
  //     },
  //   ];
  // }, [resourceSlips]);
  // console.log("resourceSlipListItems",resourceSlipListItems)

  const formMethods = useForm<any>({
    resolver: yupResolver(RL1SlipSetupValidationSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  console.log("formMethods", formMethods);

  // const [selectedFlexFieldId, selectedParent] = useWatch({
  //   control: formMethods.control,
  //   name: ["id", "parent"],
  // });

  // const { isLoading: isLoadingSelectedFlexField, data: selectedFlexFieldData } =
  //   useGetFlexFieldData(selectedFlexFieldId as number);

  /**
   * Handle when the form is submitted.
   *
   * @param {IRL1SlipRequestPayload} formData
   */
  const onSubmit = (formData: any) => {
    console.log("formData", formData);

    //province is in string format and by using this we convert that in object
    const jsonString = formData.province as string;
    // Custom parsing function to convert the JSON-like string to an object
    const parseJSONLikeString = (str: string) => {
      // Use regular expressions to add double quotes around keys and string values
      const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
      // Convert the modified JSON string to an object using JSON.parse()
      return JSON.parse(modifiedString);
    };
    // Convert the JSON-like string to an object
    const provinceObject = parseJSONLikeString(jsonString);
    console.log("provinceObject", provinceObject);

    //other Information
    const getValue = formMethods.getValues("comparison");
    const finalOtherInformatioItemsRL1 = FilteredSelectedOtherInfoRL1.map(
      (item: any) => {
        const valueItem = getValue.find(
          (value: any) => value.code === item.code
        );
        const getLabelIfMissingInItem: any =
          RL1OtherInformationList &&
          RL1OtherInformationList.find(
            (value: any) => value.value === item.code
          );
        return {
          ...item,
          amount: valueItem.amount,
          label: getLabelIfMissingInItem.label,
        };
      }
    );

    console.log("finalOtherInformatioItemsRL1", finalOtherInformatioItemsRL1);

    const requestPayload: IRL1SlipRequestPayload = {
      clientId: clientId,
      taxYear: taxYear,
      id:
        selectedRL1SlipsData && selectedRL1SlipsData.resource
          ? // && editedRecordSlipCreateNew === false
            selectedRL1SlipsData.id
            : RL1SlipRecord && RL1SlipRecord.id ? RL1SlipRecord.id
          : 
            // : newCreatedSlipId !== '' && editedRecordSlipCreateNew === false ? newCreatedSlipId
            undefined,
      applyAdjustments: formData.applyAdjustments !== "false" ? true : false,
      saveAdjutments: formData.applyAdjustments !== "true" ? false : true, //if apply adj true then true ow false
      reflectMasterData: formData.reflectMasterData,
      slipType: formData.slipType,
      slipStatus: formData.slipStatus,
      pensionablePeriod: formData.pensionablePeriod,
      otherInformation:
        formData.comparison[0].code !== "" ? finalOtherInformatioItemsRL1 : [],
      resource: {
        externalId:
          selectedResource !== "create-new"
            ? selectedResource
            : selectedRL1SlipsData && selectedRL1SlipsData.resource
            ? selectedRL1SlipsData.resource.externalId
            : // : newCreatedResourceId !== '' ? newCreatedResourceId
            selectedNotStartedResourceData && selectedNotStartedResourceData.id
            ? selectedNotStartedResourceData.id
            : undefined,
        // externalId:"6daf1279-a74f-458a-a973-5f8eda0c72f4", //resource id of => //testRL ed3 test
        firstName: formData.name1,
        initials: formData.initials,
        lastName: formData.name2,
        dateOfBirth:
          dateOfBirth.charAt(2) === "*"
            ? undefined
            : dateOfBirth !== "" || null
            ? dateOfBirth
            : undefined,
        socialInsuranceNumber: formData.uniqueIdentifier.replace(/-/g, ""),
        address: formData.addressLine1,
        city: formData.city,
        province: provinceObject,
        country: CountryResponse,
        postCode:
          formData.postalCode &&
          formData.postalCode.replace(/[a-z]/g, (match: string) =>
            match.toUpperCase()
          ),
        provinceOfEmployment: provinceOfEmploymentRes,
        email:
          formData.email !== "" && formData.email !== "ze*****3@gmail.com"
            ? formData.email
            : undefined,
        payPeriod: formData.payPeriod,
      },
      overRemittance: 0,
      slipData: {
        boxARevenusDEmploi: formData.boxARevenusDEmploi && formData.boxARevenusDEmploi !== null ? parseFloat(formData.boxARevenusDEmploi.replace(/[$,]/g, '')) : 0,
        boxBOtisationAuRRQ: formData.boxBOtisationAuRRQ && formData.boxBOtisationAuRRQ !== null ? parseFloat(formData.boxBOtisationAuRRQ.replace(/[$,]/g, '')) : 0,
        boxBBOtisationAuRRQ: taxYear > 2023 && formData.boxBBOtisationAuRRQ && formData.boxBBOtisationAuRRQ !== null ? parseFloat(formData.boxBBOtisationAuRRQ.replace(/[$,]/g, '')) : 0,
        boxCCotaIAssuranceEmploi: formData.boxCCotaIAssuranceEmploi && formData.boxCCotaIAssuranceEmploi !== null ? parseFloat(formData.boxCCotaIAssuranceEmploi.replace(/[$,]/g, '')) : 0,
        boxDCotisationAUnRPA: formData.boxDCotisationAUnRPA && formData.boxDCotisationAUnRPA !== null ? parseFloat(formData.boxDCotisationAUnRPA.replace(/[$,]/g, '')) : 0,
        boxEImpotDuQuebecRetenu: formData.boxEImpotDuQuebecRetenu && formData.boxEImpotDuQuebecRetenu !== null ? parseFloat(formData.boxEImpotDuQuebecRetenu.replace(/[$,]/g, '')) : 0,
        boxFCotisationSyndicale: formData.boxFCotisationSyndicale && formData.boxFCotisationSyndicale !== null ? parseFloat(formData.boxFCotisationSyndicale.replace(/[$,]/g, '')) : 0,
        boxGSalaireAdmissibleAuRRQ: formData.boxGSalaireAdmissibleAuRRQ && formData.boxGSalaireAdmissibleAuRRQ !== null ? parseFloat(
          formData.boxGSalaireAdmissibleAuRRQ.replace(/[$,]/g, '')
        ) : 0,
        boxHCotisationAuRQAP: formData.boxHCotisationAuRQAP && formData.boxHCotisationAuRQAP !== null ? parseFloat(formData.boxHCotisationAuRQAP.replace(/[$,]/g, '')) : 0,
        boxISalaireAdmissibleAuRQAP: formData.boxISalaireAdmissibleAuRQAP && formData.boxISalaireAdmissibleAuRQAP !== null ? parseFloat(
          formData.boxISalaireAdmissibleAuRQAP.replace(/[$,]/g, '')
        ) : 0,
        boxJRegimePriveDassMaladie: formData.boxJRegimePriveDassMaladie && formData.boxJRegimePriveDassMaladie !== null ? parseFloat(
          formData.boxJRegimePriveDassMaladie.replace(/[$,]/g, '')
        ) : 0,
        boxKVoyages: formData.boxKVoyages && formData.boxKVoyages !== null ? parseFloat(formData.boxKVoyages.replace(/[$,]/g, '')) : 0,
        boxLAutresAvantages: formData.boxLAutresAvantages && formData.boxLAutresAvantages !== null ? parseFloat(formData.boxLAutresAvantages.replace(/[$,]/g, '')) : 0,
        boxMCommissions: formData.boxMCommissions && formData.boxMCommissions !== null ? parseFloat(formData.boxMCommissions.replace(/[$,]/g, '')) : 0,
        boxNDonsDeBienfaisance: formData.boxNDonsDeBienfaisance && formData.boxNDonsDeBienfaisance !== null ? parseFloat(formData.boxNDonsDeBienfaisance.replace(/[$,]/g, '')) : 0,
        boxOAutresRevenus: formData.boxOAutresRevenus && formData.boxOAutresRevenus !== '' && formData.boxOAutresRevenus !== '0.00' ? parseFloat(formData.boxOAutresRevenus.replace(/[$,]/g, '')) : null,
        boxPRegimeDassInterentreprises: formData.boxPRegimeDassInterentreprises && formData.boxPRegimeDassInterentreprises !== null ? parseFloat(
          formData.boxPRegimeDassInterentreprises.replace(/[$,]/g, '')
        ) : 0,
        boxQSalairesDifferes: formData.boxQSalairesDifferes && formData.boxQSalairesDifferes !== null ? parseFloat(formData.boxQSalairesDifferes.replace(/[$,]/g, '')) : 0,
        boxRRevenuSitueDansUneReserve: formData.boxRRevenuSitueDansUneReserve && formData.boxRRevenuSitueDansUneReserve !== null ? parseFloat(
          formData.boxRRevenuSitueDansUneReserve.replace(/[$,]/g, '')
        ) : 0,
        boxSPourboiresRecus: formData.boxSPourboiresRecus && formData.boxSPourboiresRecus !== null ? parseFloat(formData.boxSPourboiresRecus.replace(/[$,]/g, '')) : 0,
        boxTPourboiresAttribues: formData.boxTPourboiresAttribues && formData.boxTPourboiresAttribues !== null ? parseFloat(formData.boxTPourboiresAttribues.replace(/[$,]/g, '')) : 0,
        boxURetraiteProgressive: formData.boxURetraiteProgressive && formData.boxURetraiteProgressive !== null && formData.boxURetraiteProgressive !== '' && taxYear < 2024 ? parseFloat(formData.boxURetraiteProgressive.replace(/[$,]/g, '')) : 0,
        boxVNourritureEtLogement: formData.boxVNourritureEtLogement && formData.boxVNourritureEtLogement !== null ? parseFloat(formData.boxVNourritureEtLogement.replace(/[$,]/g, '')) : 0,
        boxWVehiculeAMoteur: formData.boxWVehiculeAMoteur && formData.boxWVehiculeAMoteur !== null ? parseFloat(formData.boxWVehiculeAMoteur.replace(/[$,]/g, '')) : 0,
        code: formData.code !== undefined ? formData.code : null,
      },
      adjustedSlipData: {
        boxAAdjRevenusDEmploi: formData.boxARevenusDEmploiAdjusted && formData.boxARevenusDEmploiAdjusted !== null ? 
        parseFloat(formData.boxARevenusDEmploiAdjusted.replace(/[$,]/g, '')) : 0,
        boxBAdjOtisationAuRRQ: formData.boxBOtisationAuRRQAdjusted && formData.boxBOtisationAuRRQAdjusted !== null ? 
        parseFloat(formData.boxBOtisationAuRRQAdjusted.replace(/[$,]/g, '')) : 0,
        boxBBAdjOtisationAuRRQ:formData.boxBBOtisationAuRRQAdjusted && formData.boxBBOtisationAuRRQAdjusted !== null && taxYear > 2023 ? 
        parseFloat(formData.boxBBOtisationAuRRQAdjusted.replace(/[$,]/g, '')) : 0,
        boxCAdjCotaIAssuranceEmploi: formData.boxCCotaIAssuranceEmploiAdjusted && formData.boxCCotaIAssuranceEmploiAdjusted !== null ? parseFloat(
          formData.boxCCotaIAssuranceEmploiAdjusted.replace(/[$,]/g, '')
        ) : 0,
        boxEAdjImpotDuQuebecRetenu: formData.boxEImpotDuQuebecRetenuAdjusted && formData.boxEImpotDuQuebecRetenuAdjusted !== null ? parseFloat(
          formData.boxEImpotDuQuebecRetenuAdjusted.replace(/[$,]/g, '')
        ) : 0,
        boxHAdjCotisationAuRQAP: formData.boxHCotisationAuRQAPAdjusted && formData.boxHCotisationAuRQAPAdjusted !== null ? parseFloat(
          formData.boxHCotisationAuRQAPAdjusted.replace(/[$,]/g, '')
        ) : 0,
        boxIAdjSalaireAdmissibleAuRQAP: formData.boxISalaireAdmissibleAuRQAPAdjusted && formData.boxISalaireAdmissibleAuRQAPAdjusted !== null ? parseFloat(
          formData.boxISalaireAdmissibleAuRQAPAdjusted.replace(/[$,]/g, '')
        ) : 0,
      },
    };
    console.log("===-requestPayload rl1slip ", requestPayload);

    submitFlexFields(requestPayload);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset();
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    setSlipOtherInformation([])
    let path = `/taxslip/rl1/setup`;
    navigate(path);
    setRL1SlipRecord(undefined);
  };

  // For Delete Row

  const { isLoading: isDeleting, mutate: deleteRL1SlipById } = useMutation(
    RL1SlipService.delRL1Slip,
    {
      onSuccess: (res: any | IServerError | IResponse) => {
        console.log("resonce for Delete row", res);
        if (typeof res === "string") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: "Record has been deleted successfully.",
            severity: "success",
          });
          setRL1SlipRecord(undefined);
          refectchRL1SlipIds()
          // return (
          //   // window.location.reload()
          //   );
          // window.location.href = "/taxslip/t4/list";
        } else {
          setSnackbarData({
            open: true,
            // message: "Something went wrong, try again later!",
            message: res.message,
            severity: "error",
          });
        }

        //load Employee Data
        if (res) {
          const selectedEmployee = formMethods.getValues("selectedEmployee");
          console.log("selectedEmployee", selectedEmployee);
          if (selectedEmployee !== "create-new") {
            // (async () => {
            //   try {
            //     setIsLoadingAdjustedData(true);
            //     setTimeout(() => {
            //       setIsLoadingAdjustedData(false);
            //     }, 1000);
            //     const response = await T4SlipService.getResourceActualId(
            //       selectedEmployee
            //     );

                // setSelectedResourceId(String(response.resourceId));
                // const resourceId = response.resourceId;
                let path = `/taxslip/rl1/setup/${selectedEmployee}`;
                navigate(path);
                const fetchData = async () => {
                  try {
                    // setIsLoadingAdjustedData(true);
                    // setTimeout(() => {
                    //   setIsLoadingAdjustedData(false);
                    // }, 1000);
                    const response: any =
                      await RL1SlipService.getResourceSlipDropDownList(
                        selectedEmployee,
                        clientId,
                        taxYear
                      );
                    console.log("responseData-----------------", response);
                    // formMethods.setValue("selectedSlip", response);
                    // if(response.length !== 0){
                    // }
                    console.log("selectedSlip", response.length);

                    if (response && Array.isArray(response)) {
                      let resourceSlipList: IDropDownListResponse[] =
                        response.map((resourceSlip: IDropDownListResponse) => ({
                          label: resourceSlip.label,
                          value: resourceSlip.value,
                        }));

                      resourceSlipList.unshift({
                        label: "Create New",
                        value: "create-new",
                      });

                      console.log("resourceSlipListDDL", resourceSlipList);
                      setSelectedResourceSlipList(resourceSlipList);
                      formMethods.setValue(
                        "selectedSlip",
                        resourceSlipList[0].value
                      );
                      createNewActionFromSlipDDL();
                      formMethods.setValue("comparison", [
                        { amount: 0, code: "", label: "" },
                      ]);
                    }
                  } catch (error) {
                    console.error("Error fetching data:", error);
                  }
                };

                fetchData();
            //   } catch (err) {
            //     console.log("err", err);
            //     // alert(err);
            //   }
            // })();
          }
        }

        //set slip type to 400 because when we come back to slip without reload it will automatically be original
        selectedRL1SlipType = formMethods.getValues("slipType");

        //otherinformation will update sselected items when its submitted
        setSelectedOtherInformation(true);
      },
      onError: (err: any) => {
        console.log("resonce for Delete row", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          // message: "Something went wrong, try again later!",
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  const deleteRL1SlipField = () => {
    if (singleDelete) {
      let payload = {
        rL1SlipId,
        clientId,
        taxYear,
      };
      setSingleDelete(false);
      deleteRL1SlipById(payload);
      setDialogOpen(false);
    }
  };

  const onDeleteClicked = () => {
    setDialogOpen(true);
    setSingleDelete(true);
    setApiAction("delete");
    if (
      selectedRL1SlipsData &&
      selectedRL1SlipsData.resource
      // && editedRecordSlipCreateNew === false
    ) {
      setRL1SlipId(selectedRL1SlipsData.id);
    }
    // else if (slipRecord && slipRecord.id){
    //   setRL1SlipId(slipRecord.id)
    // }
    // else if(newCreatedSlipId !== '' && editedRecordSlipCreateNew === false){
    //   setRL1SlipId(newCreatedSlipId)
    // }
    // setRL1SlipId(params.row.id);
    console.log("RL1SlipId in T4Slip List OnDeleteRowClicked function");

    // setSnackbarData({
    //   open: true,
    //   message: "Deleted successfully",
    //   severity: "success",
    // });
    // formMethods.reset();
  };

  const savePdfAsFile = (pdfData: any, filename: any) => {
    // const blob = new Blob([xmlData], { type: 'pdf;charset=utf-8' });
    const blob = new Blob([pdfData], { type: "pdf;charset=utf-8" });
    FileSaver.saveAs(blob, filename);
  };
  const onPrintClicked = async (): Promise<void> => {
    const maskedStatus: any = sessionStorage.getItem("maskEnabled");
    if (maskedStatus === "true") {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(rl1SlipIds && rl1SlipIds.generalAuditStatus !== null && rl1SlipIds.generalAuditStatus === true &&
              rl1SlipIds && rl1SlipIds.auditStatus !== null &&  rl1SlipIds.auditStatus === true){
              setIsLoadingData(true);
                try {
                  // const response: any = await PrintingService.createSingleRL1PdfPrint(clientId, EditedResourceId, formMethods.getValues("slipType"), taxYear);
                  let pdfIds = `rl1_slip_ids=${id}`;
                  const response: any =
                    await PrintingService.createBulkRL1PdfPrint(
                      clientId,
                      pdfIds,
                      taxYear
                    );
                  if (response.status === 400) {
                    setSnackbarData({
                      open: true,
                      message: response.data.message
                        ? response.data.message
                        : "Something went wrong.",
                      // message: "Please Unmask sensitive data for PDF printing.",
                      severity: "error",
                    });
                  } else {
                    const filename = `RL1slip-${clientName}.pdf`;
                    savePdfAsFile(response, filename);
                  }
                  setIsLoadingData(false);
                } catch (err) {
                  console.log("err", err);
                  // alert(err)
                }
              // }
              setIsLoadingData(false);
            } else {
              setIsLoadingData(false);
              setSnackbarData({
              open: true,
              message: (
                <>
                  Please load Audit in the menu, press Run RL-1 Audit and clear all audit items to generate documents.{" "}
                  <a
                    href="https://appv3.taxslips.com/#/audit/filing"
                    style={{
                      color: "#aa1010",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Click here to view
                  </a>
                </>
              ),
              severity: "error",
              autoHide: false,
            });
          } 

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
      setIsLoadingData(true);
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (response.message === "Slips not found for invoice.") {
          // setIsLoadingData(true);
          // try {
          //   const filingAuditResponse: any =
          //     await RL1SlipService.getRL1AuditFiling(clientId, taxYear);
          //   console.log("filingAuditResponse", filingAuditResponse);
          //   if (
          //     filingAuditResponse &&
          //     filingAuditResponse.general_filling &&
          //     filingAuditResponse.general_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.general_filling",
          //       filingAuditResponse.general_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else if (
          //     filingAuditResponse &&
          //     filingAuditResponse.rl1_filling &&
          //     filingAuditResponse.rl1_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.rl1_filling",
          //       filingAuditResponse.rl1_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else {
          //     setIsLoadingData(true);
          if(rl1SlipIds && rl1SlipIds.generalAuditStatus !== null && rl1SlipIds.generalAuditStatus === true &&
            rl1SlipIds && rl1SlipIds.auditStatus !== null &&  rl1SlipIds.auditStatus === true){
            setIsLoadingData(true);
              try {
                // const response: any = await PrintingService.createSingleRL1PdfPrint(clientId, EditedResourceId, formMethods.getValues("slipType"), taxYear);
                let pdfIds = `rl1_slip_ids=${id}`;
                const response: any =
                  await PrintingService.createBulkRL1PdfPrint(
                    clientId,
                    pdfIds,
                    taxYear
                  );
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    // message: "Please Unmask sensitive data for PDF printing.",
                    severity: "error",
                  });
                } else {
                  const filename = `RL1slip-${clientName}.pdf`;
                  savePdfAsFile(response, filename);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
                // alert(err)
              }
            // }
            setIsLoadingData(false);
          } else {
            setIsLoadingData(false);
            setSnackbarData({
            open: true,
            message: (
              <>
                Please load Audit in the menu, press Run RL-1 Audit and clear all audit items to generate documents.{" "}
                <a
                  href="https://appv3.taxslips.com/#/audit/filing"
                  style={{
                    color: "#aa1010",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Click here to view
                </a>
              </>
            ),
            severity: "error",
            autoHide: false,
          });
        } 
          // } catch (err: any) {
          //   setIsLoadingData(false);
          //   alert(err);
          // }
        } else if (response && response.id !== null) {
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    }
    }
  };

  const onUnPaidClickHandler = (clickedRow: any, tag:string) => {
    setIsLoadingData(true);
    // setTimeout(() => {
    //   setIsLoadingData(false);
    // }, 2500);
    const fetchData = async () => {
      try {
        const response: any =
          await ReportsService.paypalIntegrationOfUnpaidInvoice(
            clientId,
            clickedRow.refNo,
            tag
          );
        setIsLoadingData(false);
        console.log("responseData111-----------------", response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const onClickInvoiceNoHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };
  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // const rl1slipListItems = useMemo(() => {
  //   return [
  //     {
  //       label: "Create New",
  //       value: "Create New",
  //     },
  //   ];
  // }, []);

  // const otherInformationList = useMemo(() => {
  //   return [
  //     {
  //       label: "N/A",
  //       value: "0",
  //     },
  //     {
  //       label: "A-1: Employee benefit plan",
  //       value: "A-1",
  //     },
  //     {
  //       label: "A-2: Employee trust",
  //       value: "A-3",
  //     },
  //     {
  //       label: "A-3: Repayment of salary or wages",
  //       value: "A-4",
  //     },
  //   ];
  //   // if (!flexFieldsList) {
  //   //   return [];
  //   // }
  //   // return flexFieldsList.map((flexField) => ({
  //   //   label: flexField.fieldLabel,
  //   //   value: flexField.id,
  //   // }));
  // }, []);

  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (slipRecord) {
  //     Object.keys(slipRecord).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = slipRecord[fieldName];
  //       if (typeof fieldValue !== "object") {
  //         // @ts-ignore
  //         formMethods.setValue(fieldName, fieldValue);
  //       }
  //       if (fieldName === "resource") {
  //         Object.keys(fieldValue).forEach((resourceFieldName) => {
  //           const resourceFieldValue = fieldValue[resourceFieldName];
  //           // @ts-ignore
  //           formMethods.setValue(resourceFieldName, resourceFieldValue);
  //           console.log(
  //             "resourceFieldName : ",
  //             resourceFieldName,
  //             "resourceFieldValue : ",
  //             resourceFieldValue
  //           );
  //           if(resourceFieldName === 'dateOfBirth'){
  //             if(resourceFieldValue !== null || undefined){
  //             if(resourceFieldValue.charAt(2) === "*"){
  //               // alert("yes")
  //               formMethods.setValue('dateOfBirth', resourceFieldValue);
  //               setDateOfBirth(resourceFieldValue)
  //             }else{
  //               // alert("No")
  //               const defaultDate = new Date(resourceFieldValue);
  //               formMethods.setValue('dateOfBirth', defaultDate);
  //               setDateOfBirth(formatDate(defaultDate as Date))
  //             }
  //             setDateOfBirthPlaceHolder(resourceFieldValue)
  //           }
  //         }
  //           if (resourceFieldName === "country") {
  //             formMethods.setValue(
  //               resourceFieldName,
  //               resourceFieldValue.name ?? undefined
  //             );
  //           }
  //           if (resourceFieldName === "province") {
  //               let id = resourceFieldValue.id;
  //               let name = resourceFieldValue.name;
  //               let shortCode = resourceFieldValue.shortCode;
  //               console.log("namename", name);

  //               formMethods.setValue(
  //                 resourceFieldName,
  //                 // '{id:9, name: "Ontario",shortCode:"ON"}'
  //                 `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
  //               );
  //             }
  //             if (resourceFieldName === "provinceOfEmployment") {
  //               let id = resourceFieldValue.id;
  //               let name = resourceFieldValue.name;
  //               let shortCode = resourceFieldValue.shortCode;
  //               console.log("namename", name);

  //               formMethods.setValue(
  //                 resourceFieldName,
  //                 // '{id:9, name: "Ontario",shortCode:"ON"}'
  //                 `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
  //               );
  //           }

  //         });
  //       }
  //       if (fieldName === "slipData") {
  //         Object.keys(fieldValue).forEach((slipDataFieldName) => {
  //           const slipDataFieldValue = fieldValue[slipDataFieldName];
  //           // @ts-ignore
  //           formMethods.setValue(slipDataFieldName, slipDataFieldValue);
  //           console.log(
  //             "slipDataFieldName : ",
  //             slipDataFieldName,
  //             "slipDataFieldValue : ",
  //             slipDataFieldValue
  //           );
  //         });
  //       }
  //       if (fieldName === "adjustedSlipData") {
  //         Object.keys(fieldValue).forEach((adjustedSlipDataFieldName) => {
  //           const adjustedSlipDataFieldValue =
  //             fieldValue[adjustedSlipDataFieldName];
  //           // @ts-ignore
  //           formMethods.setValue(
  //             adjustedSlipDataFieldName,
  //             adjustedSlipDataFieldValue
  //           );
  //           console.log(
  //             "adjustedSlipDataFieldName : ",
  //             adjustedSlipDataFieldName,
  //             "adjustedSlipDataFieldValue : ",
  //             adjustedSlipDataFieldValue
  //           );
  //         });
  //       }

  //       //set slip type according to selected slip for submit button action
  //       if(fieldName === 'slipType'){
  //         selectedRL1SlipType = fieldValue
  //         setIsLoadingAdjustedData(true);
  //         setTimeout(() => {
  //           setIsLoadingAdjustedData(false);
  //         }, 1000);
  //       }
  //       if(fieldName === 'otherInformation'){
  //         if(fieldValue.length !== 0){
  //           formMethods.setValue('comparison',fieldValue)
  //         } else{
  //           formMethods.setValue('comparison',[{ amount: 0, code: '', label:''}])
  //         }
  //       }
  //     });
  //   }
  // }, [slipRecord, formMethods.setValue, formMethods]);

  // set the values of the selected flex field coming from BE.
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (selectedFlexFieldData) {
  //     Object.keys(selectedFlexFieldData).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = selectedFlexFieldData[fieldName];
  //       // @ts-ignore
  //       formMethods.setValue(fieldName, fieldValue);
  //     });
  //   }
  // }, [selectedFlexFieldData, formMethods.setValue, formMethods]);

  // if we have selected the parent and a flex field and they both are the same, we should un-set the parent because a field cannot be the parent of itself
  // useEffect(() => {
  //   if (
  //     selectedFlexFieldId &&
  //     selectedParent &&
  //     selectedParent === selectedFlexFieldId
  //   ) {
  //     formMethods.setValue("parent", undefined);
  //   }
  // }, [formMethods, selectedFlexFieldId, selectedParent]);

  // format date to 2023-07-26
  const formatDate = (date: Date): string => {
    return format(date, "yyyy-MM-dd"); // Format the date as "yyyy-MM-dd"
  };

  const onDateOfBirthChangeHandler = (selectedDate: any) => {
    console.log("onDateOfBirthChangeHandler", selectedDate);
    if (selectedDate.value) {
      console.log(
        "format date to 2023-07-26",
        formatDate(selectedDate.value as Date)
      );
      // alert(formatDate(selectedDate.value as Date));
      setDateOfBirth(formatDate(selectedDate.value as Date));
    } else {
      setDateOfBirth("");
    }
  };

  // const onResourceSelectionChangeHandler = (
  //   selectedItem: IEnhancedDropDownItem
  // ) => {
  //   setSelectedResource(String(selectedItem.value));
  // };
  const onResourceSelectionChangeHandler = async (
    selectedItem: IEnhancedDropDownItem | any,
    index?: any
  ): Promise<void> => {
    // setSelectedResource(String(selectedItem.value));
    console.log("selectedItem.value", selectedItem.value);
    //this is for card selection
    setSelectedCard(index);
    setSelectedResourceSlip("create-new");

    if (!index) {
      //this is for card selection
      setSelectedCard(selectedItem.value);
      setSelectedResourceSlip("create-new");
    }
    if (selectedItem.value === "create-new") {
      setSnackbarData({
        open: true,
        message: "Please load an employee for RL-1 Slip",
        severity: "error",
      });
      setSidePanelsVisibility((prevButtonState: any) => !prevButtonState);
      // setNewCreatedResourceId('')
      formMethods.reset();
      setDateOfBirthPlaceHolder("");
      // setNewCreatedSlipId('')
      setDateOfBirth("");
      setSelectedResourceSlipList([
        { label: "Create New", value: "create-new" },
      ]);
      setSelectedResourceSlip("create-new");
      formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
      setSlipOtherInformation([])
      //otherinformation will update selected items
      setSelectedOtherInformation(false);
      // if(id !== undefined){
      //   window.location.href = "/taxslip/rl1/setup";
      // }
      let path = `/taxslip/rl1/setup`;
      navigate(path);
      setRL1SlipRecord(undefined);
      setIsLoadingAdjustedData(true);
      setTimeout(() => {
        setIsLoadingAdjustedData(false);
      }, 1000);
      setSelectedResource("create-new");
    } else {
      setOnResourceSelectionAPIsCalls(true);
      // try {
      //   const responseGetResourceActualId =
      //     await T4SlipService.getResourceActualId(selectedItem.value);

        // console.log("responseData",response.resourceId)
        // setSelectedResource(String(response.resourceId));
        // // setSelectedResourceId(String(response.resourceId));
        // if (response.resourceId !== "create new"){
        //   setEditedResourceId(String(response.resourceId));
        // }
        // let path = `/taxslip/rl1/setup/${response.resourceId}`;
        // navigate(path);
        (async () => {
          try {
            setIsLoadingAdjustedData(true);
            let resourceDataById =
              await RL1SlipService.getResourceInformationById(
                selectedItem.value,
                clientId,
                "Employee"
              );
            console.log("resourceDataById", resourceDataById);
            if (resourceDataById) {
              Object.keys(resourceDataById).forEach((fieldName) => {
                // @ts-ignore
                const fieldValue = resourceDataById[fieldName];
                console.log(
                  "fieldNamefieldName",
                  fieldName,
                  " && ",
                  "fieldValuefieldValue ",
                  fieldValue
                );
                formMethods.setValue(
                  fieldName,
                  fieldValue !== null && fieldValue !== "" ? fieldValue : ""
                );

                if (fieldName === "email") {
                  if (fieldValue !== null) {
                    formMethods.setValue(fieldName, fieldValue);
                  } else {
                    const maskedStatus: any =
                      sessionStorage.getItem("maskEnabled");
                    if (maskedStatus === "true") {
                      formMethods.setValue(fieldName, "ze*****3@gmail.com");
                    } else {
                      formMethods.setValue(fieldName, "");
                    }
                  }
                }
                if (fieldName === "address") {
                  Object.keys(fieldValue).forEach((resourceFieldName) => {
                    const resourceFieldValue = fieldValue[resourceFieldName];
                    // @ts-ignore
                    console.log(
                      "resourceFieldName : ",
                      resourceFieldName,
                      "resourceFieldValue : ",
                      resourceFieldValue
                    );
                    Object.keys(resourceFieldValue).forEach(
                      (resourceFieldNameNew) => {
                        const resourceFieldValueNew =
                          resourceFieldValue[resourceFieldNameNew];
                        // @ts-ignore
                        console.log(
                          "resourceFieldNameNew : ",
                          resourceFieldNameNew,
                          "resourceFieldValue : ",
                          resourceFieldValueNew
                        );
                        formMethods.setValue(
                          resourceFieldNameNew,
                          resourceFieldValueNew
                        );

                        if (resourceFieldNameNew === "country") {
                          formMethods.setValue(
                            resourceFieldNameNew,
                            resourceFieldValueNew.name ?? undefined
                          );
                        }
                        if (resourceFieldNameNew === "province") {
                          let id = resourceFieldValueNew.id;
                          let name = resourceFieldValueNew.name;
                          let shortCode = resourceFieldValueNew.shortCode;

                          console.log("namenamenmae", name);

                          formMethods.setValue(
                            resourceFieldNameNew,
                            `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                            // '{id:1, name: "Alberta",shortCode:"AB"}'
                          );
                        }
                      }
                    );
                  });
                }
                if (fieldName === "uniqueIdentifier") {
                  const formattedNumber = fieldValue.replace(
                    /(\d{3})(\d{3})(\d{3})/,
                    "$1-$2-$3"
                  );
                  formMethods.setValue("uniqueIdentifier", formattedNumber);
                }
                // }
                if (fieldName === "metaFields") {
                  fieldValue.forEach((obj: any) => {
                    formMethods.setValue(obj.name, obj.value);
                    // if(obj.name === 'initial' || obj.name === 'INITIALS'){
                    //   formMethods.setValue('initials',obj.value)
                    // }
                    if (obj.name === "pay_period") {
                      formMethods.setValue("payPeriod", obj.value);
                      formMethods.setValue("pensionablePeriod", obj.value);
                    }
                    if (obj.name === "date_of_birth") {
                      if (obj.value !== "") {
                        formMethods.setValue("dateOfBirth", obj.value);
                        if (obj.value.charAt(2) === "*") {
                          formMethods.setValue("dateOfBirth", obj.value);
                          setDateOfBirth(obj.value);
                        } else {
                          const defaultDate = new Date(obj.value);
                          formMethods.setValue("dateOfBirth", defaultDate);
                          setDateOfBirth(formatDate(defaultDate as Date));
                        }
                        setDateOfBirthPlaceHolder(obj.value);
                      } else {
                        const maskedStatus: any =
                          sessionStorage.getItem("maskEnabled");
                        if (maskedStatus === "true") {
                          setDateOfBirthPlaceHolder("0***"); //this would disable dob while value is null in upadte
                        }
                      }
                    }
                    console.log(
                      `"objobj" : ${obj.name} ====== objValue : ${obj.value}`
                    );
                  });
                }
                if (fieldName === "id") {
                  // (async () => {
                  //   try {
                  //     const responseAssociationId =
                  //       await T4SlipService.getRoleAssociationId(fieldValue);

                  //     console.log(
                  //       "responseData getRoleAssociationId : ",
                  //       responseAssociationId.associationId
                  //     );

                      formMethods.setValue(
                        "selectedEmployee",
                        fieldValue
                      );

                      // (async () => {
                      //   try {
                      //     const responseGetResId =
                      //       await T4SlipService.getResourceActualId(
                      //         responseAssociationId.associationId
                      //       );
                      //     console.log(
                      //       "responseData////////",
                      //       responseGetResId.resourceId
                      //     );

                          (async () => {
                            try {
                              const response: any =
                                await RL1SlipService.getResourceSlipDropDownList(
                                  fieldValue,
                                  clientId,
                                  taxYear
                                );
                                // console.log(
                                  //   "responseData-----------------",
                              //   response[0].value
                              // );
                              // formMethods.setValue("selectedSlip", response[0].value);

                              if (response && Array.isArray(response)) {
                                let resourceSlipList: IDropDownListResponse[] =
                                  response.map(
                                    (resourceSlip: IDropDownListResponse) => ({
                                      label: resourceSlip.label,
                                      value: resourceSlip.value,
                                    })
                                  );

                                resourceSlipList.unshift({
                                  label: "Create New",
                                  value: "create-new",
                                });

                                console.log(
                                  "resourceSlipListDDL",
                                  resourceSlipList
                                );
                                setSelectedResourceSlipList(resourceSlipList);
                                if (
                                  resourceSlipList &&
                                  resourceSlipList.length > 1
                                ) {
                                  (async () => {
                                    try {
                                      console.log(
                                        "slipssss",
                                        resourceSlipList[1].value
                                      );
                                      setIsLoadingAdjustedData(true);
                                      let slipDataById =
                                      await RL1SlipService.getRL1SlipById(
                                        String(resourceSlipList[1].value),
                                        clientId,
                                        taxYear
                                      );
                                      setRL1SlipRecord(slipDataById)
                                      setSelectedResourceSlip(
                                        String(resourceSlipList[1].value)
                                      );
                                      formMethods.setValue(
                                        "selectedSlip",
                                        String(resourceSlipList[1].value)
                                      );
                                      console.log("slipDataById", slipDataById);
                                      if (slipDataById) {
                                        Object.keys(slipDataById).forEach(
                                          (fieldName) => {
                                            console.log(
                                              "fieldName useEffect RL1 Slip : slipDataById",
                                              fieldName
                                            );
                                            // @ts-ignore
                                            const fieldValue =
                                              slipDataById[fieldName];
                                            console.log(
                                              "fieldValue useEffect RL1 Slip : ",
                                              fieldValue
                                            );
                                            // @ts-ignore

                                            if (
                                              typeof fieldValue !== "object"
                                            ) {
                                              // @ts-ignore
                                              formMethods.setValue(
                                                fieldName,
                                                fieldValue ?? undefined
                                              );
                                            } else if (
                                              fieldValue !== null ||
                                              undefined
                                            ) {
                                              console.log("object");
                                              Object.keys(fieldValue).forEach(
                                                (objFieldName: any) => {
                                                  console.log(
                                                    "objFieldName useEffect RL1 Slip : ",
                                                    objFieldName
                                                  );
                                                  // @ts-ignore
                                                  const objFieldValue =
                                                    fieldValue[objFieldName];
                                                  console.log(
                                                    "objFieldValue useEffect RL1 Slip : ",
                                                    objFieldValue
                                                  );
                                                  if (
                                                    typeof objFieldValue !==
                                                    "object"
                                                  ) {
                                                    // @ts-ignore
                                                    formMethods.setValue(
                                                      objFieldName,
                                                      objFieldValue ?? undefined
                                                    );
                                                  }
                                                  if (
                                                    objFieldName ===
                                                    "dateOfBirth"
                                                  ) {
                                                    if (
                                                      objFieldValue !== null ||
                                                      undefined
                                                    ) {
                                                      if (
                                                        objFieldValue.charAt(
                                                          2
                                                        ) === "*"
                                                      ) {
                                                        // alert("yes")
                                                        formMethods.setValue(
                                                          "dateOfBirth",
                                                          objFieldValue
                                                        );
                                                        setDateOfBirth(
                                                          objFieldValue
                                                        );
                                                      } else {
                                                        // alert("No")
                                                        const defaultDate =
                                                          new Date(
                                                            objFieldValue
                                                          );
                                                        formMethods.setValue(
                                                          "dateOfBirth",
                                                          defaultDate
                                                        );
                                                        setDateOfBirth(
                                                          formatDate(
                                                            defaultDate as Date
                                                          )
                                                        );
                                                      }
                                                      setDateOfBirthPlaceHolder(
                                                        objFieldValue
                                                      );
                                                    } else if (
                                                      objFieldValue === null
                                                    ) {
                                                      const maskedStatus: any =
                                                        sessionStorage.getItem(
                                                          "maskEnabled"
                                                        );
                                                      if (
                                                        maskedStatus === "true"
                                                      ) {
                                                        setDateOfBirthPlaceHolder(
                                                          "0***"
                                                        ); //this would disable dob while value is null in upadte
                                                      }
                                                    }
                                                  }
                                                  if (
                                                    fieldName === "resource"
                                                  ) {
                                                    if (
                                                      objFieldName ===
                                                      "externalId"
                                                    ) {
                                                      // (async () => {
                                                      //   try {
                                                      //     const responseAssociationId =
                                                      //       await T4SlipService.getRoleAssociationId(
                                                      //         objFieldValue
                                                      //       );

                                                      //     console.log(
                                                      //       "responseData getRoleAssociationId : ",
                                                      //       responseAssociationId.associationId
                                                      //     );

                                                          formMethods.setValue(
                                                            "selectedEmployee",
                                                            objFieldValue
                                                          );

                                                          // (async () => {
                                                          //   try {
                                                          //     const responseGetResId =
                                                          //       await T4SlipService.getResourceActualId(
                                                          //         responseAssociationId.associationId
                                                          //       );
                                                          //     console.log(
                                                          //       "responseData////////",
                                                          //       responseGetResId.resourceId
                                                          //     );

                                                              (async () => {
                                                                try {
                                                                  const response: any =
                                                                    await RL1SlipService.getResourceSlipDropDownList(
                                                                      objFieldValue,
                                                                      clientId,
                                                                      taxYear
                                                                    );

                                                                  if (
                                                                    response &&
                                                                    Array.isArray(
                                                                      response
                                                                    )
                                                                  ) {
                                                                    let resourceSlipList: IDropDownListResponse[] =
                                                                      response.map(
                                                                        (
                                                                          resourceSlip: IDropDownListResponse
                                                                        ) => ({
                                                                          label:
                                                                            resourceSlip.label,
                                                                          value:
                                                                            resourceSlip.value,
                                                                        })
                                                                      );

                                                                    resourceSlipList.unshift(
                                                                      {
                                                                        label:
                                                                          "Create New",
                                                                        value:
                                                                          "create-new",
                                                                      }
                                                                    );

                                                                    console.log(
                                                                      "resourceSlipListDDL",
                                                                      resourceSlipList
                                                                    );
                                                                    setSelectedResourceSlipList(
                                                                      resourceSlipList
                                                                    );
                                                                  }
                                                                } catch (error) {
                                                                  console.error(
                                                                    "Error fetching data:",
                                                                    error
                                                                  );
                                                                }
                                                              })();
                                                              // You can work with the data here or trigger other functions that depend on the fetched data
                                                      //       } catch (error) {
                                                      //         console.error(
                                                      //           "Error fetching data:",
                                                      //           error
                                                      //         );
                                                      //       }
                                                      //     })();
                                                      //   } catch (error) {
                                                      //     console.error(
                                                      //       "Error fetching data:",
                                                      //       error
                                                      //     );
                                                      //   }
                                                      // })();
                                                      setSelectedResource(
                                                        objFieldValue
                                                      );
                                                    }
                                                    setEditedResourceId(
                                                      fieldValue.externalId
                                                    );
                                                    if (
                                                      objFieldName === "email"
                                                    ) {
                                                      if (
                                                        objFieldValue !== null
                                                      ) {
                                                        formMethods.setValue(
                                                          objFieldName,
                                                          objFieldValue
                                                        );
                                                      } else {
                                                        const maskedStatus: any =
                                                          sessionStorage.getItem(
                                                            "maskEnabled"
                                                          );
                                                        console.log(
                                                          "elesese",
                                                          maskedStatus
                                                        );
                                                        if (
                                                          maskedStatus ===
                                                          "true"
                                                        ) {
                                                          formMethods.setValue(
                                                            objFieldName,
                                                            "ze*****3@gmail.com"
                                                          );
                                                        } else {
                                                          formMethods.setValue(
                                                            objFieldName,
                                                            ""
                                                          );
                                                        }
                                                      }
                                                    }
                                                  }
                                                  if (
                                                    objFieldName === "province"
                                                  ) {
                                                    let id = objFieldValue.id;
                                                    let name =
                                                      objFieldValue.name;
                                                    let shortCode =
                                                      objFieldValue.shortCode;
                                                    console.log(
                                                      "namename",
                                                      name
                                                    );

                                                    formMethods.setValue(
                                                      objFieldName,
                                                      // '{id:9, name: "Ontario",shortCode:"ON"}'
                                                      `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                                                    );
                                                  }
                                                  if (
                                                    objFieldName === "country"
                                                  ) {
                                                    formMethods.setValue(
                                                      objFieldName,
                                                      objFieldValue.name ??
                                                        undefined
                                                    );
                                                  }
                                                  if (
                                                    objFieldName ===
                                                    "uniqueIdentifier"
                                                  ) {
                                                    const formattedNumber =
                                                      objFieldValue.replace(
                                                        /(\d{3})(\d{3})(\d{3})/,
                                                        "$1-$2-$3"
                                                      );
                                                    formMethods.setValue(
                                                      "uniqueIdentifier",
                                                      formattedNumber
                                                    );
                                                  }
                                                  if (
                                                    objFieldName !== "email" &&
                                                    objFieldValue === null
                                                  ) {
                                                    formMethods.setValue(
                                                      objFieldName,
                                                      ""
                                                    );
                                                  }
                                                }
                                              );
                                            }
                                            //set slip type according to edited row data for submit button action
                                            if (fieldName === "slipType") {
                                              selectedRL1SlipType = fieldValue;
                                            }
                                            if (
                                              fieldName === "otherInformation"
                                            ) {
                                              setSlipOtherInformation(fieldValue)
                                              // if (
                                              //   fieldValue &&
                                              //   fieldValue !== null &&
                                              //   fieldValue.length !== 0
                                              // ) {
                                              //   setOiInEdit(true);
                                              //   formMethods.setValue(
                                              //     "comparison",
                                              //     fieldValue
                                              //   );
                                              // } else {
                                              //   setOiInEdit(true);
                                              //   formMethods.setValue(
                                              //     "comparison",
                                              //     [
                                              //       {
                                              //         amount: 0,
                                              //         code: "",
                                              //         label: "",
                                              //       },
                                              //     ]
                                              //   );
                                              // }
                                            }
                                            if (fieldName === "id") {
                                              // setNewCreatedSlipId(fieldValue)
                                              formMethods.setValue(
                                                "selectedSlip",
                                                fieldValue
                                              );
                                              let path = `/taxslip/rl1/setup/${fieldValue}`;
                                              navigate(path);
                                            }
                                            if (fieldName === "slipData") {
                                              Object.keys(fieldValue).forEach(
                                                (slipDataFieldName: any) => {
                                                  const slipDataFieldValue =
                                                    fieldValue[
                                                      slipDataFieldName
                                                    ];
                                                  formMethods.setValue(
                                                    slipDataFieldName,
                                                    slipDataFieldValue &&
                                                      slipDataFieldValue !==
                                                        null
                                                      ? parseFloat(
                                                          slipDataFieldValue
                                                        ).toFixed(2)
                                                      : "0.00"
                                                  );
                                                  if (
                                                    slipDataFieldName ===
                                                    "boxOAutresRevenus"
                                                  ) {
                                                    formMethods.setValue(
                                                      slipDataFieldName,
                                                      slipDataFieldValue !==
                                                        null
                                                        ? parseFloat(
                                                            slipDataFieldValue
                                                          ).toFixed(2)
                                                        : null
                                                    );
                                                  }
                                                  if (
                                                    slipDataFieldName === "code"
                                                  ) {
                                                    formMethods.setValue(
                                                      slipDataFieldName,
                                                      slipDataFieldValue !==
                                                        null
                                                        ? slipDataFieldValue
                                                        : null
                                                    );
                                                  }
                                                }
                                              );
                                            }
                                            if (
                                              fieldName === "adjustedSlipData"
                                            ) {
                                              Object.keys(fieldValue).forEach(
                                                (adjSlipDataFieldName: any) => {
                                                  const adjSlipDataFieldValue =
                                                    fieldValue[
                                                      adjSlipDataFieldName
                                                    ];
                                                  formMethods.setValue(
                                                    adjSlipDataFieldName,
                                                    adjSlipDataFieldValue &&
                                                      adjSlipDataFieldValue !==
                                                        null
                                                      ? parseFloat(
                                                          adjSlipDataFieldValue
                                                        ).toFixed(2)
                                                      : "0.00"
                                                  );
                                                }
                                              );
                                            }
                                          }
                                        );
                                      }
                                      selectedRL1SlipType =
                                        formMethods.getValues("slipType");
                                      setSelectedOtherInformation(false);
                                    } catch (error) {
                                      setIsLoadingAdjustedData(false);
                                      console.error(
                                        "Error fetching data:",
                                        error
                                      );
                                    } finally {
                                      setSelectedResourceSlip(
                                        String(resourceSlipList[1].value)
                                      );
                                      formMethods.setValue(
                                        "selectedSlip",
                                        String(resourceSlipList[1].value)
                                      );
                                      let path = `/taxslip/rl1/setup/${resourceSlipList[1].value}`;
                                      navigate(path);
                                      setTimeout(() => {
                                        setIsLoadingAdjustedData(false);
                                      }, 3000);
                                    }
                                  })();
                                } else {
                                  setRL1SlipRecord(undefined)
                                  setSelectedResourceSlip("create-new");
                                  formMethods.setValue(
                                    "selectedSlip",
                                    "create-new"
                                  );
                                  formMethods.setValue(
                                    "applyAdjustments",
                                    false
                                  );
                                  formMethods.setValue(
                                    "boxARevenusDEmploi",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxBOtisationAuRRQ",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxCCotaIAssuranceEmploi",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxDCotisationAUnRPA",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxEImpotDuQuebecRetenu",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxFCotisationSyndicale",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxGSalaireAdmissibleAuRRQ",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxHCotisationAuRQAP",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxISalaireAdmissibleAuRQAP",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxJRegimePriveDassMaladie",
                                    "0.00"
                                  );
                                  formMethods.setValue("boxKVoyages", "0.00");
                                  formMethods.setValue(
                                    "boxLAutresAvantages",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxMCommissions",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxNDonsDeBienfaisance",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxOAutresRevenus",
                                    null
                                  );
                                  formMethods.setValue(
                                    "boxPRegimeDassInterentreprises",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxQSalairesDifferes",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxRRevenuSitueDansUneReserve",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxSPourboiresRecus",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxTPourboiresAttribues",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxURetraiteProgressive",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxVNourritureEtLogement",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxWVehiculeAMoteur",
                                    "0.00"
                                  );
                                  formMethods.setValue("code", null);
                                  formMethods.setValue(
                                    "boxAAdjRevenusDEmploi",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxBAdjOtisationAuRRQ",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxCAdjCotaIAssuranceEmploi",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxEAdjImpotDuQuebecRetenu",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxHAdjCotisationAuRQAP",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "boxIAdjSalaireAdmissibleAuRQAP",
                                    "0.00"
                                  );
                                  formMethods.setValue("slipStatus", 200);
                                  formMethods.setValue("comparison", []);
                                  setSlipOtherInformation([{ amount: 0, code: 'null', label:'Empty Field'}])

                                  setSelectedResource(
                                    String(
                                      selectedItem.value
                                    )
                                  );
                                  setEditedResourceId(
                                    String(
                                      selectedItem.value
                                    )
                                  );
                                  let path = `/taxslip/rl1/setup/${selectedItem.value}`;
                                  navigate(path);
                                  setTimeout(() => {
                                    setIsLoadingAdjustedData(false);
                                  }, 3000);
                                }
                              }
                            } catch (error) {
                              console.error("Error fetching data:", error);
                            }
                          })();
                          // You can work with the data here or trigger other functions that depend on the fetched data
                  //       } catch (error) {
                  //         console.error("Error fetching data:", error);
                  //       }
                  //     })();
                  //   } catch (error) {
                  //     console.error("Error fetching data:", error);
                  //   }
                  // })();
                  setSelectedResource(fieldValue);
                }
              });
            }
          } catch (error) {
            setIsLoadingAdjustedData(false);
            console.error("Error fetching data:", error);
          }
          // finally {
          //   setSelectedResource(String(responseGetResourceActualId.resourceId));
          //   setEditedResourceId(String(responseGetResourceActualId.resourceId));
          //   let path = `/taxslip/rl1/setup/${responseGetResourceActualId.resourceId}`;
          //   navigate(path);
          //   setTimeout(() => {
          //     setIsLoadingAdjustedData(false);
          //   }, 2500);
          // }
        })();
      // } catch (err) {
      //   console.log("err", err);
      //   // alert(err)
      // }
    }
    //otherinformation will update selected items
    setSelectedOtherInformation(false);
  };

  const onResourceSlipSelectionChangeHandler = (
    selectedItem: IEnhancedDropDownItem | any
  ) => {
    setOnResourceSelectionAPIsCalls(true);
    setSelectedResourceSlip(String(selectedItem.value));
    if (selectedItem.value === "create-new") {
      createNewActionFromSlipDDL();
      setSelectedResourceSlip("create-new");
      if (selectedResource !== "create-new") {
        setOnResourceSelectionAPIsCalls(true);
        let path = `/taxslip/rl1/setup/${selectedResource}`;
        navigate(path);
        window.location.reload()
      }
      setRL1SlipRecord(undefined);
    }
    if (selectedItem.value !== "create-new") {
      // setSelectedResourceSlip(String(selectedItem.value));
      // let path = `/taxslip/rl1/setup/${selectedItem.value}`;
      // navigate(path);
      // setIsLoadingAdjustedData(true);
      // setTimeout(() => {
      //   setIsLoadingAdjustedData(false);
      // }, 1000);
      (async () => {
        try {
          setIsLoadingAdjustedData(true);
          let slipDataById = await RL1SlipService.getRL1SlipById(
            String(selectedItem.value),
            clientId,
            taxYear
          );
          setRL1SlipRecord(slipDataById)
          console.log("slipDataById", slipDataById);
          if (slipDataById) {
            Object.keys(slipDataById).forEach((fieldName) => {
              console.log(
                "fieldName useEffect RL1 Slip : slipDataById",
                fieldName
              );
              // @ts-ignore
              const fieldValue = slipDataById[fieldName];
              console.log("fieldValue useEffect RL1 Slip : ", fieldValue);
              // @ts-ignore

              if (typeof fieldValue !== "object") {
                // @ts-ignore
                formMethods.setValue(fieldName, fieldValue ?? undefined);
              } else if (fieldValue !== null || undefined) {
                console.log("object");
                Object.keys(fieldValue).forEach((objFieldName: any) => {
                  console.log(
                    "objFieldName useEffect RL1 Slip : ",
                    objFieldName
                  );
                  // @ts-ignore
                  const objFieldValue = fieldValue[objFieldName];
                  console.log(
                    "objFieldValue useEffect RL1 Slip : ",
                    objFieldValue
                  );
                  if (typeof objFieldValue !== "object") {
                    // @ts-ignore
                    formMethods.setValue(
                      objFieldName,
                      objFieldValue ?? undefined
                    );
                  }
                  if (objFieldName === "dateOfBirth") {
                    if (objFieldValue !== null || undefined) {
                      if (objFieldValue.charAt(2) === "*") {
                        // alert("yes")
                        formMethods.setValue("dateOfBirth", objFieldValue);
                        setDateOfBirth(objFieldValue);
                      } else {
                        // alert("No")
                        const defaultDate = new Date(objFieldValue);
                        formMethods.setValue("dateOfBirth", defaultDate);
                        setDateOfBirth(formatDate(defaultDate as Date));
                      }
                      setDateOfBirthPlaceHolder(objFieldValue);
                    } else if (objFieldValue === null) {
                      const maskedStatus: any =
                        sessionStorage.getItem("maskEnabled");
                      if (maskedStatus === "true") {
                        setDateOfBirthPlaceHolder("0***"); //this would disable dob while value is null in upadte
                      }
                    }
                  }
                  if (fieldName === "resource") {
                    if (objFieldName === "externalId") {
                      // (async () => {
                      //   try {
                      //     const responseAssociationId =
                      //       await T4SlipService.getRoleAssociationId(
                      //         objFieldValue
                      //       );

                      //     console.log(
                      //       "responseData getRoleAssociationId : ",
                      //       responseAssociationId.associationId
                          // );

                          formMethods.setValue(
                            "selectedEmployee",
                            objFieldValue
                          );

                          // (async () => {
                          //   try {
                          //     const responseGetResId =
                          //       await T4SlipService.getResourceActualId(
                          //         responseAssociationId.associationId
                          //       );
                          //     console.log(
                          //       "responseData////////",
                          //       responseGetResId.resourceId
                          //     );

                              (async () => {
                                try {
                                  const response: any =
                                    await RL1SlipService.getResourceSlipDropDownList(
                                      objFieldValue,
                                      clientId,
                                      taxYear
                                    );

                                  if (response && Array.isArray(response)) {
                                    let resourceSlipList: IDropDownListResponse[] =
                                      response.map(
                                        (
                                          resourceSlip: IDropDownListResponse
                                        ) => ({
                                          label: resourceSlip.label,
                                          value: resourceSlip.value,
                                        })
                                      );

                                    resourceSlipList.unshift({
                                      label: "Create New",
                                      value: "create-new",
                                    });

                                    console.log(
                                      "resourceSlipListDDL",
                                      resourceSlipList
                                    );
                                    setSelectedResourceSlipList(
                                      resourceSlipList
                                    );
                                  }
                                } catch (error) {
                                  console.error("Error fetching data:", error);
                                }
                              })();
                              // You can work with the data here or trigger other functions that depend on the fetched data
                      //       } catch (error) {
                      //         console.error("Error fetching data:", error);
                      //       }
                      //     })();
                      //   } catch (error) {
                      //     console.error("Error fetching data:", error);
                      //   }
                      // })();
                      setSelectedResource(objFieldValue);
                    }
                    setEditedResourceId(fieldValue.externalId);
                    if (objFieldName === "email") {
                      if (objFieldValue !== null) {
                        formMethods.setValue(objFieldName, objFieldValue);
                      } else {
                        const maskedStatus: any =
                          sessionStorage.getItem("maskEnabled");
                        console.log("elesese", maskedStatus);
                        if (maskedStatus === "true") {
                          formMethods.setValue(
                            objFieldName,
                            "ze*****3@gmail.com"
                          );
                        } else {
                          formMethods.setValue(objFieldName, "");
                        }
                      }
                    }
                  }
                  if (objFieldName === "province") {
                    let id = objFieldValue.id;
                    let name = objFieldValue.name;
                    let shortCode = objFieldValue.shortCode;
                    console.log("namename", name);

                    formMethods.setValue(
                      objFieldName,
                      // '{id:9, name: "Ontario",shortCode:"ON"}'
                      `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                    );
                  }
                  if (objFieldName === "country") {
                    formMethods.setValue(
                      objFieldName,
                      objFieldValue.name ?? undefined
                    );
                  }
                  if (objFieldName === "uniqueIdentifier") {
                    const formattedNumber = objFieldValue.replace(
                      /(\d{3})(\d{3})(\d{3})/,
                      "$1-$2-$3"
                    );
                    formMethods.setValue("uniqueIdentifier", formattedNumber);
                  }
                  if (objFieldName !== "email" && objFieldValue === null) {
                    formMethods.setValue(objFieldName, "");
                  }
                });
              }
              //set slip type according to edited row data for submit button action
              if (fieldName === "slipType") {
                selectedRL1SlipType = fieldValue;
                setIsLoadingAdjustedData(true);
                setTimeout(() => {
                  setIsLoadingAdjustedData(false);
                }, 1000);
              }
              if (fieldName === "otherInformation") {
                // setSlipOtherInformation(fieldValue)
                setSlipOtherInformation([])
                if (fieldValue && fieldValue !== null && fieldValue.length !== 0) {
                  setOiInEdit(true);
                  formMethods.setValue("comparison", fieldValue);
                } else {
                  setOiInEdit(true);
                  formMethods.setValue("comparison", [
                    { amount: 0, code: "", label: "" },
                  ]);
                }
              }
              if (fieldName === "id") {
                // setNewCreatedSlipId(fieldValue)
                formMethods.setValue("selectedSlip", fieldValue);
                let path = `/taxslip/rl1/setup/${fieldValue}`;
                navigate(path);
              }
              if (fieldName === "slipData") {
                Object.keys(fieldValue).forEach((slipDataFieldName: any) => {
                  const slipDataFieldValue = fieldValue[slipDataFieldName];
                  formMethods.setValue(
                    slipDataFieldName,
                    slipDataFieldValue && slipDataFieldValue !== null
                      ? parseFloat(slipDataFieldValue).toFixed(2)
                      : "0.00"
                  );
                  if (slipDataFieldName === "boxOAutresRevenus") {
                    formMethods.setValue(
                      slipDataFieldName,
                      slipDataFieldValue !== null
                        ? parseFloat(slipDataFieldValue).toFixed(2)
                        : null
                    );
                  }
                  if (slipDataFieldName === "code") {
                    formMethods.setValue(
                      slipDataFieldName,
                      slipDataFieldValue !== null ? slipDataFieldValue : null
                    );
                  }
                });
              }
              if (fieldName === "adjustedSlipData") {
                Object.keys(fieldValue).forEach((adjSlipDataFieldName: any) => {
                  const adjSlipDataFieldValue =
                    fieldValue[adjSlipDataFieldName];
                  formMethods.setValue(
                    adjSlipDataFieldName,
                    adjSlipDataFieldValue && adjSlipDataFieldValue !== null
                      ? parseFloat(adjSlipDataFieldValue).toFixed(2)
                      : "0.00"
                  );
                });
              }
            });
          }
          selectedRL1SlipType = formMethods.getValues("slipType");
          setSelectedOtherInformation(false);
        } catch (error) {
          setIsLoadingAdjustedData(false);
          console.error("Error fetching data:", error);
        } finally {
          setSelectedResourceSlip(String(selectedItem.value));
          let path = `/taxslip/rl1/setup/${selectedItem.value}`;
          navigate(path);
          setTimeout(() => {
            setIsLoadingAdjustedData(false);
          }, 2500);
        }
      })();
    }
    // }

    //otherinformation will update selected items
    setSelectedOtherInformation(true);
  };

  // useEffect(() => {
  //   if (selectedResource !== "create-new") {
  //     refetchResourceSlips();
  //   } else {
  //   }
  // }, [refetchResourceSlips, selectedResource]);

  // useEffect(() => {
  //   if (selectedResourceSlip !== "create-new") {
  //     refetchSlipRecord();
  //   } else {
  //   }
  // }, [refetchSlipRecord, selectedResourceSlip]);

  // for edit

  //if slip is completed this will effected
  //if dob is masked then placeholder change
  const [dateOfBirthPlaceHolder, setDateOfBirthPlaceHolder] = useState("");
  console.log("dateOfBirthPlaceHolder", dateOfBirthPlaceHolder);
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (RL1SlipRecord && onResourceSelectionAPIsCalls === false) {
      Object.keys(RL1SlipRecord).forEach((fieldName) => {
        console.log("fieldName useEffect RL1 Slip : RL1SlipRecord", fieldName);
        // @ts-ignore
        const fieldValue = RL1SlipRecord[fieldName];
        console.log("fieldValue useEffect RL1 Slip : ", fieldValue);
        // @ts-ignore

        if (typeof fieldValue !== "object") {
          // @ts-ignore
          formMethods.setValue(fieldName, fieldValue ?? undefined);
        } else if (fieldValue !== null || undefined) {
          console.log("object");
          Object.keys(fieldValue).forEach((objFieldName: any) => {
            console.log("objFieldName useEffect RL1 Slip : ", objFieldName);
            // @ts-ignore
            const objFieldValue = fieldValue[objFieldName];
            console.log("objFieldValue useEffect RL1 Slip : ", objFieldValue);
            if (typeof objFieldValue !== "object") {
              // @ts-ignore
              formMethods.setValue(objFieldName, objFieldValue ?? undefined);
            }
            // else if (objFieldValue !== null || undefined){
            //   console.log("id name SC = ",objFieldValue.name)
            //   formMethods.setValue(objFieldName, objFieldValue ?? undefined);
            //   // Object.keys(objFieldValue).forEach((objFieldName_1:any) => {
            //   //   console.log("objFieldName_1 useEffect RL1 Slip : ",objFieldName_1)
            //   //   // @ts-ignore
            //   //   const objFieldValue_1 = objFieldValue[objFieldName_1];
            //   //   console.log("objFieldValue_1 useEffect RL1 Slip : ",objFieldValue_1)
            //   //   if(objFieldValue_1 !== null || undefined || ""){
            //   //     // formMethods.setValue(objFieldName, objFieldValue_1 ?? undefined);
            //   //     formMethods.setValue(objFieldName, objFieldValue_1 ?? undefined);
            //   //   }
            //   // })
            //   if(objFieldValue.name === 'Canada'){
            //   formMethods.setValue(objFieldName, objFieldValue.name ?? undefined);
            //   }
            // }
            if (objFieldName === "dateOfBirth") {
              if (objFieldValue !== null || undefined) {
                if (objFieldValue.charAt(2) === "*") {
                  // alert("yes")
                  formMethods.setValue("dateOfBirth", objFieldValue);
                  setDateOfBirth(objFieldValue);
                } else {
                  // alert("No")
                  const defaultDate = new Date(objFieldValue);
                  formMethods.setValue("dateOfBirth", defaultDate);
                  setDateOfBirth(formatDate(defaultDate as Date));
                }
                setDateOfBirthPlaceHolder(objFieldValue);
              } else if (objFieldValue === null) {
                const maskedStatus: any = sessionStorage.getItem("maskEnabled");
                if (maskedStatus === "true") {
                  setDateOfBirthPlaceHolder("0***"); //this would disable dob while value is null in upadte
                }
              }
            }
            if (fieldName === "resource") {
              if (objFieldName === "externalId") {
                // console.log("objFieldValue : externalid ", objFieldValue);
                // let roleAssociationId = "d0a41913-11f3-4f00-a824-9977909bdd04";
                // (async () => {
                //   try {
                //     const responseAssociationId =
                //       await T4SlipService.getRoleAssociationId(objFieldValue);

                //     console.log(
                //       "responseData getRoleAssociationId : ",
                //       responseAssociationId.associationId
                //     );
                    // Find the index of the object with the specified key
                    //  const indexForSelectedCard = resourceListWithoutCreateNew && resourceListWithoutCreateNew.findIndex((item:any) => item.value === responseAssociationId.associationId);
                    //  console.log("indexForSelectedCard",indexForSelectedCard)
                    //  setSelectedCard(indexForSelectedCard)
                    //for auto selected card
                    setSelectedCard(
                      String(objFieldValue)
                    );
                    formMethods.setValue(
                      "selectedEmployee",
                      objFieldValue
                    );

                    // (async () => {
                    //   try {
                    //     const responseGetResId =
                    //       await T4SlipService.getResourceActualId(
                    //         responseAssociationId.associationId
                    //       );
                    //     console.log(
                    //       "responseData////////",
                    //       responseGetResId.resourceId
                    //     );

                        (async () => {
                          try {
                            const response: any =
                              await RL1SlipService.getResourceSlipDropDownList(
                                objFieldValue,
                                clientId,
                                taxYear
                              );
                            // console.log(
                            //   "responseData-----------------",
                            //   response[0].value
                            // );
                            // formMethods.setValue("selectedSlip", response[0].value);

                            if (response && Array.isArray(response)) {
                              let resourceSlipList: IDropDownListResponse[] =
                                response.map(
                                  (resourceSlip: IDropDownListResponse) => ({
                                    label: resourceSlip.label,
                                    value: resourceSlip.value,
                                  })
                                );

                              resourceSlipList.unshift({
                                label: "Create New",
                                value: "create-new",
                              });

                              console.log(
                                "resourceSlipListDDL",
                                resourceSlipList
                              );
                              setSelectedResourceSlipList(resourceSlipList);
                              //for Cards scenario
                              if (
                                resourceSlipList &&
                                resourceSlipList.length > 1
                              ) {
                                setSelectedResourceSlip(
                                  String(resourceSlipList[1].value)
                                );
                              } else {
                                setSelectedResourceSlip("create-new");
                              }
                            }
                          } catch (error) {
                            console.error("Error fetching data:", error);
                          }
                        })();
                        // You can work with the data here or trigger other functions that depend on the fetched data
                //       } catch (error) {
                //         console.error("Error fetching data:", error);
                //       }
                //     })();
                //   } catch (error) {
                //     console.error("Error fetching data:", error);
                //   }
                // })();
                setSelectedResource(objFieldValue);
              }
              setEditedResourceId(fieldValue.externalId);
              if (objFieldName === "email") {
                if (objFieldValue !== null) {
                  formMethods.setValue(objFieldName, objFieldValue);
                } else {
                  const maskedStatus: any =
                    sessionStorage.getItem("maskEnabled");
                  console.log("elesese", maskedStatus);
                  if (maskedStatus === "true") {
                    formMethods.setValue(objFieldName, "ze*****3@gmail.com");
                  } else {
                    formMethods.setValue(objFieldName, "");
                  }
                }
              }
            }
            if (objFieldName === "province") {
              let id = objFieldValue.id;
              let name = objFieldValue.name;
              let shortCode = objFieldValue.shortCode;
              console.log("namename", name);

              formMethods.setValue(
                objFieldName,
                // '{id:9, name: "Ontario",shortCode:"ON"}'
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
              );
            }
            if (objFieldName === "country") {
              formMethods.setValue(
                objFieldName,
                objFieldValue.name ?? undefined
              );
            }
            if (objFieldName === "uniqueIdentifier") {
              const formattedNumber = objFieldValue.replace(
                /(\d{3})(\d{3})(\d{3})/,
                "$1-$2-$3"
              );
              formMethods.setValue("uniqueIdentifier", formattedNumber);
            }
            if (objFieldName !== "email" && objFieldValue === null) {
              formMethods.setValue(objFieldName, "");
            }
          });
        }
        //set slip type according to edited row data for submit button action
        if (fieldName === "slipType") {
          selectedRL1SlipType = fieldValue;
          setIsLoadingAdjustedData(true);
          setTimeout(() => {
            setIsLoadingAdjustedData(false);
          }, 1000);
        }
        if (fieldName === "otherInformation") {
          setSlipOtherInformation([])
          if (fieldValue && fieldValue !== null && fieldValue.length !== 0) {
            setOiInEdit(true);
            formMethods.setValue("comparison", fieldValue);
          } else {
            setOiInEdit(true);
            formMethods.setValue("comparison", [
              { amount: 0, code: "", label: "" },
            ]);
          }
        }
        if (fieldName === "id") {
          // setNewCreatedSlipId(fieldValue)
          formMethods.setValue("selectedSlip", fieldValue);
          let path = `/taxslip/rl1/setup/${fieldValue}`;
          navigate(path);
        }
        if (fieldName === "slipData") {
          Object.keys(fieldValue).forEach((slipDataFieldName: any) => {
            const slipDataFieldValue = fieldValue[slipDataFieldName];
            formMethods.setValue(
              slipDataFieldName,
              slipDataFieldValue && slipDataFieldValue !== null
                ? parseFloat(slipDataFieldValue).toFixed(2)
                : "0.00"
            );
            if (slipDataFieldName === "boxGSalaireAdmissibleAuRRQ") {
              formMethods.setValue(
                slipDataFieldName,
                slipDataFieldValue !== null
                  ? parseFloat(slipDataFieldValue).toFixed(2)
                  : null
              );
            }
            if (slipDataFieldName === "boxOAutresRevenus") {
              formMethods.setValue(
                slipDataFieldName,
                slipDataFieldValue !== null
                  ? parseFloat(slipDataFieldValue).toFixed(2)
                  : null
              );
            }
            if (slipDataFieldName === "code") {
              formMethods.setValue(
                slipDataFieldName,
                slipDataFieldValue !== null ? slipDataFieldValue : null
              );
            }
          });
        }
        if (fieldName === "adjustedSlipData") {
          Object.keys(fieldValue).forEach((adjSlipDataFieldName: any) => {
            const adjSlipDataFieldValue = fieldValue[adjSlipDataFieldName];
            formMethods.setValue(
              adjSlipDataFieldName,
              adjSlipDataFieldValue && adjSlipDataFieldValue !== null
                ? parseFloat(adjSlipDataFieldValue).toFixed(2)
                : "0.00"
            );
          });
        }
      });
    }
    //set slip type to 400 because when we come back to slip without reload it will automatically be original
    selectedRL1SlipType = formMethods.getValues("slipType");

    //otherinformation will update sselected items when its submitted
    // setSelectedOtherInformation(true)
    setSelectedOtherInformation(false);
  }, [RL1SlipRecord, formMethods.setValue, formMethods, onResourceSelectionAPIsCalls]);

  //this is for not started slip
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (selectedNotStartedResourceData && onResourceSelectionAPIsCalls === false) {
      Object.keys(selectedNotStartedResourceData).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = selectedNotStartedResourceData[fieldName];
        console.log(
          "fieldNamefieldName",
          fieldName,
          " && ",
          "fieldValuefieldValue ",
          fieldValue
        );
        formMethods.setValue(
          fieldName,
          fieldValue !== null && fieldValue !== "" ? fieldValue : ""
        );

        if (fieldName === "email") {
          if (fieldValue !== null) {
            formMethods.setValue(fieldName, fieldValue);
          } else {
            const maskedStatus: any = sessionStorage.getItem("maskEnabled");
            if (maskedStatus === "true") {
              formMethods.setValue(fieldName, "ze*****3@gmail.com");
            } else {
              formMethods.setValue(fieldName, "");
            }
          }
        }
        if (fieldName === "address") {
          Object.keys(fieldValue).forEach((resourceFieldName) => {
            const resourceFieldValue = fieldValue[resourceFieldName];
            // @ts-ignore
            console.log(
              "resourceFieldName : ",
              resourceFieldName,
              "resourceFieldValue : ",
              resourceFieldValue
            );
            Object.keys(resourceFieldValue).forEach((resourceFieldNameNew) => {
              const resourceFieldValueNew =
                resourceFieldValue[resourceFieldNameNew];
              // @ts-ignore
              console.log(
                "resourceFieldNameNew : ",
                resourceFieldNameNew,
                "resourceFieldValue : ",
                resourceFieldValueNew
              );
              formMethods.setValue(resourceFieldNameNew, resourceFieldValueNew);

              if (resourceFieldNameNew === "country") {
                formMethods.setValue(
                  resourceFieldNameNew,
                  resourceFieldValueNew.name ?? undefined
                );
              }
              if (resourceFieldNameNew === "province") {
                let id = resourceFieldValueNew.id;
                let name = resourceFieldValueNew.name;
                let shortCode = resourceFieldValueNew.shortCode;

                console.log("namenamenmae", name);

                formMethods.setValue(
                  resourceFieldNameNew,
                  `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                  // '{id:1, name: "Alberta",shortCode:"AB"}'
                );
              }
            });
          });
        }
        if (fieldName === "id") {
          // (async () => {
          //   try {
          //     const responseAssociationId =
          //       await T4SlipService.getRoleAssociationId(fieldValue);

          //     console.log(
          //       "responseData getRoleAssociationId : ",
          //       responseAssociationId.associationId
          //     );
              //for auto selected card
              setSelectedCard(String(fieldValue));
              formMethods.setValue(
                "selectedEmployee",
                fieldValue
              );

              // (async () => {
              //   try {
              //     const responseGetResId =
              //       await T4SlipService.getResourceActualId(
              //         responseAssociationId.associationId
              //       );
              //     console.log(
              //       "responseData////////",
              //       responseGetResId.resourceId
              //     );

                  (async () => {
                    try {
                      const response: any =
                        await RL1SlipService.getResourceSlipDropDownList(
                          fieldValue,
                          clientId,
                          taxYear
                        );
                      // console.log(
                      //   "responseData-----------------",
                      //   response[0].value
                      // );
                      // formMethods.setValue("selectedSlip", response[0].value);

                      if (response && Array.isArray(response)) {
                        let resourceSlipList: IDropDownListResponse[] =
                          response.map(
                            (resourceSlip: IDropDownListResponse) => ({
                              label: resourceSlip.label,
                              value: resourceSlip.value,
                            })
                          );

                        resourceSlipList.unshift({
                          label: "Create New",
                          value: "create-new",
                        });

                        console.log("resourceSlipListDDL", resourceSlipList);
                        setSelectedResourceSlipList(resourceSlipList);
                      }
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  })();
                  // You can work with the data here or trigger other functions that depend on the fetched data
          //       } catch (error) {
          //         console.error("Error fetching data:", error);
          //       }
          //     })();
          //   } catch (error) {
          //     console.error("Error fetching data:", error);
          //   }
          // })();
          setSelectedResource(fieldValue);
        }
        if (fieldName === "uniqueIdentifier") {
          const formattedNumber = fieldValue.replace(
            /(\d{3})(\d{3})(\d{3})/,
            "$1-$2-$3"
          );
          formMethods.setValue("uniqueIdentifier", formattedNumber);
        }
        // }
        if (fieldName === "metaFields") {
          fieldValue.forEach((obj: any) => {
            formMethods.setValue(obj.name, obj.value);
            // if(obj.name === 'initial' || obj.name === 'INITIALS'){
            //   formMethods.setValue('initials',obj.value)
            // }
            if (obj.name === "pay_period") {
              // formMethods.setValue("payPeriod", 26);
              formMethods.setValue("payPeriod", obj.value);
              formMethods.setValue("pensionablePeriod", obj.value);
            }
            if (obj.name === "date_of_birth") {
              if (obj.value !== "") {
                formMethods.setValue("dateOfBirth", obj.value);
                if (obj.value.charAt(2) === "*") {
                  formMethods.setValue("dateOfBirth", obj.value);
                  setDateOfBirth(obj.value);
                } else {
                  const defaultDate = new Date(obj.value);
                  formMethods.setValue("dateOfBirth", defaultDate);
                  setDateOfBirth(formatDate(defaultDate as Date));
                }
                setDateOfBirthPlaceHolder(obj.value);
              } else {
                const maskedStatus: any = sessionStorage.getItem("maskEnabled");
                if (maskedStatus === "true") {
                  setDateOfBirthPlaceHolder("0***"); //this would disable dob while value is null in upadte
                }
              }
            }
            console.log(
              `"objobj" : ${obj.name} ====== objValue : ${obj.value}`
            );
          });
        }
      });
      formMethods.setValue("selectedSlip", "create-new");
      formMethods.setValue("applyAdjustments", false);
      formMethods.setValue("boxARevenusDEmploi", "0.00");
      formMethods.setValue("boxBOtisationAuRRQ", "0.00");
      formMethods.setValue("boxCCotaIAssuranceEmploi", "0.00");
      formMethods.setValue("boxDCotisationAUnRPA", "0.00");
      formMethods.setValue("boxEImpotDuQuebecRetenu", "0.00");
      formMethods.setValue("boxFCotisationSyndicale", "0.00");
      formMethods.setValue("boxGSalaireAdmissibleAuRRQ", "0.00");
      formMethods.setValue("boxHCotisationAuRQAP", "0.00");
      formMethods.setValue("boxISalaireAdmissibleAuRQAP", "0.00");
      formMethods.setValue("boxJRegimePriveDassMaladie", "0.00");
      formMethods.setValue("boxKVoyages", "0.00");
      formMethods.setValue("boxLAutresAvantages", "0.00");
      formMethods.setValue("boxMCommissions", "0.00");
      formMethods.setValue("boxNDonsDeBienfaisance", "0.00");
      formMethods.setValue("boxOAutresRevenus", null);
      formMethods.setValue("boxPRegimeDassInterentreprises", "0.00");
      formMethods.setValue("boxQSalairesDifferes", "0.00");
      formMethods.setValue("boxRRevenuSitueDansUneReserve", "0.00");
      formMethods.setValue("boxSPourboiresRecus", "0.00");
      formMethods.setValue("boxTPourboiresAttribues", "0.00");
      formMethods.setValue("boxURetraiteProgressive", "0.00");
      formMethods.setValue("boxVNourritureEtLogement", "0.00");
      formMethods.setValue("boxWVehiculeAMoteur", "0.00");
      formMethods.setValue("code", null);
      formMethods.setValue("boxAAdjRevenusDEmploi", "0.00");
      formMethods.setValue("boxBAdjOtisationAuRRQ", "0.00");
      formMethods.setValue("boxCAdjCotaIAssuranceEmploi", "0.00");
      formMethods.setValue("boxEAdjImpotDuQuebecRetenu", "0.00");
      formMethods.setValue("boxHAdjCotisationAuRQAP", "0.00");
      formMethods.setValue("boxIAdjSalaireAdmissibleAuRQAP", "0.00");
      formMethods.setValue("slipStatus", 200);
      formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
      setSlipOtherInformation([])
    }
  }, [selectedNotStartedResourceData, formMethods.setValue, formMethods, onResourceSelectionAPIsCalls]);

  // apply Adjustment
  console.log("applyAdjustmentapplyAdjustment", adjustment);
  const [adjustedDataResponse, setAdjustedDataResponse] = useState(undefined);
  const onSelectionChange = async (e: any) => {
    // alert(e);
    setAdjustment(e);

    // const jsonString = formMethods.getValues("provinceOfEmployment")
    //   ? formMethods.getValues("provinceOfEmployment")
    //   : ('{id:9, name: "Ontario",shortCode:"ON"}' as string);

    // // Custom parsing function to convert the JSON-like string to an object
    // const parseJSONLikeString = (str: string) => {
    //   // Use regular expressions to add double quotes around keys and string values
    //   const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');

    //   // Convert the modified JSON string to an object using JSON.parse()
    //   return JSON.parse(modifiedString);
    // };

    // // Convert the JSON-like string to an object
    // const myObject = parseJSONLikeString(jsonString);

    // console.log("myObject", myObject);

    const payload = {
      clientId: clientId,
      taxYear: taxYear,
      applyAdjustments: true,
      saveAdjutments: false,
      pensionablePeriod: parseInt(formMethods.getValues("pensionablePeriod")),
      slipType: formMethods.getValues("slipType"),
      slipId:
        selectedRL1SlipsData && selectedRL1SlipsData.resource
          ? selectedRL1SlipsData.id
          : RL1SlipRecord && RL1SlipRecord.id ? RL1SlipRecord.id
          : undefined,
      resource: {
        externalId:
          selectedRL1SlipsData && selectedRL1SlipsData.resource
            ? selectedRL1SlipsData.resource.externalId
            : selectedNotStartedResourceData &&
              selectedNotStartedResourceData.id
            ? selectedNotStartedResourceData.id
            : undefined,
            dateOfBirth:
        dateOfBirth.charAt(2) === "*"
            ? undefined
            : dateOfBirth !== "" || null
            ? dateOfBirth
            : undefined,
        provinceOfEmployment: provinceOfEmploymentRes,
        payPeriod: formMethods.getValues("payPeriod"),
      },
      slipData: {
        boxARevenusDEmploi: formMethods.getValues("boxARevenusDEmploi") && formMethods.getValues("boxARevenusDEmploi") !== null ? parseFloat(
          formMethods.getValues("boxARevenusDEmploi").replace(/[$,]/g, '')
        ) : 0,
        boxBOtisationAuRRQ: formMethods.getValues("boxBOtisationAuRRQ") && formMethods.getValues("boxBOtisationAuRRQ") !== null ? parseFloat(
          formMethods.getValues("boxBOtisationAuRRQ").replace(/[$,]/g, '')
        ) : 0,
        boxBBOtisationAuRRQ: taxYear > 2023 && formMethods.getValues("boxBBOtisationAuRRQ") && formMethods.getValues("boxBBOtisationAuRRQ") !== null ? parseFloat(
          formMethods.getValues("boxBBOtisationAuRRQ").replace(/[$,]/g, '')
        ) : undefined,
        boxCCotaIAssuranceEmploi: formMethods.getValues("boxCCotaIAssuranceEmploi") && formMethods.getValues("boxCCotaIAssuranceEmploi") !== null ? parseFloat(
          formMethods.getValues("boxCCotaIAssuranceEmploi").replace(/[$,]/g, '')
        ) : 0,
        boxDCotisationAUnRPA: formMethods.getValues("boxDCotisationAUnRPA") && formMethods.getValues("boxDCotisationAUnRPA") !== null ? parseFloat(
          formMethods.getValues("boxDCotisationAUnRPA").replace(/[$,]/g, '')
        ) : 0,
        boxEImpotDuQuebecRetenu: formMethods.getValues("boxEImpotDuQuebecRetenu") && formMethods.getValues("boxEImpotDuQuebecRetenu") !== null ? parseFloat(
          formMethods.getValues("boxEImpotDuQuebecRetenu").replace(/[$,]/g, '')
        ) : 0,
        boxFCotisationSyndicale: formMethods.getValues("boxFCotisationSyndicale") && formMethods.getValues("boxFCotisationSyndicale") !== null ? parseFloat(
          formMethods.getValues("boxFCotisationSyndicale").replace(/[$,]/g, '')
        ) : 0,
        boxGSalaireAdmissibleAuRRQ: formMethods.getValues("boxGSalaireAdmissibleAuRRQ") && formMethods.getValues("boxGSalaireAdmissibleAuRRQ") !== null ? parseFloat(
          formMethods.getValues("boxGSalaireAdmissibleAuRRQ").replace(/[$,]/g, '')
        ) : 0,
        boxHCotisationAuRQAP: formMethods.getValues("boxHCotisationAuRQAP") && formMethods.getValues("boxHCotisationAuRQAP") !== null ? parseFloat(
          formMethods.getValues("boxHCotisationAuRQAP").replace(/[$,]/g, '')
        ) : 0,
        boxISalaireAdmissibleAuRQAP: formMethods.getValues("boxISalaireAdmissibleAuRQAP") && formMethods.getValues("boxISalaireAdmissibleAuRQAP") !== null ? parseFloat(
          formMethods.getValues("boxISalaireAdmissibleAuRQAP").replace(/[$,]/g, '')
        ) : 0,
      },
      adjustedSlipData: {
        boxAAdjRevenusDEmploi: parseInt("0.00"),
        boxBAdjOtisationAuRRQ: parseInt("0.00"),
        boxCAdjCotaIAssuranceEmploi: parseInt("0.00"),
        boxEAdjImpotDuQuebecRetenu: parseInt("0.00"),
        boxHAdjCotisationAuRQAP: parseInt("0.00"),
        boxIAdjSalaireAdmissibleAuRQAP: parseInt("0.00"),
      },
    };

    if (e === true) {
      try {
        setIsLoadingAdjustedData(true);
        const response = await RL1SlipService.getAdjustedData(payload);
        // response["adjustedSlipData"]["boxARevenusDEmploiAdjusted"] =
        //   response["adjustedSlipData"]["boxAAdjRevenusDEmploi"];
        // response["adjustedSlipData"]["boxBOtisationAuRRQAdjusted"] =
        //   response["adjustedSlipData"]["boxBAdjOtisationAuRRQ"];
        // response["adjustedSlipData"]["boxCCotaIAssuranceEmploiAdjusted"] =
        //   response["adjustedSlipData"]["boxCAdjCotaIAssuranceEmploi"];
        // response["adjustedSlipData"]["boxEImpotDuQuebecRetenuAdjusted"] =
        //   response["adjustedSlipData"]["boxEAdjImpotDuQuebecRetenu"];
        // response["adjustedSlipData"]["boxHCotisationAuRQAPAdjusted"] =
        //   response["adjustedSlipData"]["boxHAdjCotisationAuRQAP"];
        // response["adjustedSlipData"]["boxISalaireAdmissibleAuRQAPAdjusted"] =
        //   response["adjustedSlipData"]["boxIAdjSalaireAdmissibleAuRQAP"];

        // delete response["adjustedSlipData"]["boxAAdjRevenusDEmploi"];
        // delete response["adjustedSlipData"]["boxBAdjOtisationAuRRQ"];
        // delete response["adjustedSlipData"]["boxCAdjCotaIAssuranceEmploi"];
        // delete response["adjustedSlipData"]["boxEAdjImpotDuQuebecRetenu"];
        // delete response["adjustedSlipData"]["boxHAdjCotisationAuRQAP"];
        console.log("responseData", response);
        setAdjustedDataResponse(response);
        setIsLoadingAdjustedData(false)
      } catch (err) {
        setIsLoadingAdjustedData(false)
        console.log("err", err);
        // alert(err)
      } finally {
        setIsLoadingAdjustedData(false)
      }
    }
    if (e === false) {
      formMethods.setValue("boxARevenusDEmploiAdjusted", "0.00");
      formMethods.setValue("boxBOtisationAuRRQAdjusted", "0.00");
      formMethods.setValue("boxBBOtisationAuRRQAdjusted", "0.00");
      formMethods.setValue("boxCCotaIAssuranceEmploiAdjusted", "0.00");
      formMethods.setValue("boxEImpotDuQuebecRetenuAdjusted", "0.00");
      formMethods.setValue("boxHCotisationAuRQAPAdjusted", "0.00");
      formMethods.setValue("boxISalaireAdmissibleAuRQAPAdjusted", "0.00");
    }
  };

  useEffect(() => {
    // if the selected flex field data has loaded.
    if (adjustedDataResponse) {
      // setIsLoadingAdjustedData(true);
      //   setTimeout(() => {
      //     setIsLoadingAdjustedData(false);
      //   }, 1000);
      Object.keys(adjustedDataResponse).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = adjustedDataResponse[fieldName];
        if (fieldName === "adjustedSlipData") {
          Object.keys(fieldValue).forEach((adjustedSlipDataFieldName) => {
            const adjustedSlipDataFieldValue =
              fieldValue[adjustedSlipDataFieldName];
            // @ts-ignore
            formMethods.setValue(
              adjustedSlipDataFieldName,
              parseFloat(adjustedSlipDataFieldValue).toFixed(2)
            );
            console.log(
              "adjustedSlipDataFieldName : ",
              adjustedSlipDataFieldName,
              "adjustedSlipDataFieldValue : ",
              adjustedSlipDataFieldValue
            );
          });
        }
      });
    }
  }, [adjustedDataResponse, formMethods.setValue, formMethods]);

  //resource info by using selected resource id
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (resourceInfo) {
  //     console.log("resourceInforesourceInfo",resourceInfo)
  //     Object.keys(resourceInfo).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = resourceInfo[fieldName];
  //       console.log("fieldNamefieldName",fieldName," && ","fieldValuefieldValue ",fieldValue)
  //       formMethods.setValue(fieldName, fieldValue);

  //       if (fieldName === "address") {
  //         Object.keys(fieldValue).forEach((resourceFieldName) => {
  //           const resourceFieldValue = fieldValue[resourceFieldName];
  //           // @ts-ignore
  //           console.log("resourceFieldName : ",resourceFieldName,"resourceFieldValue : ",resourceFieldValue)
  //           // formMethods.setValue(resourceFieldName, resourceFieldValue);
  //           Object.keys(resourceFieldValue).forEach((resourceFieldNameNew) => {
  //             const resourceFieldValueNew = resourceFieldValue[resourceFieldNameNew];
  //             // @ts-ignore
  //             console.log("resourceFieldNameNew : ",resourceFieldNameNew,"resourceFieldValue : ",resourceFieldValueNew)
  //             formMethods.setValue(resourceFieldNameNew, resourceFieldValueNew);

  //             // if (resourceFieldNameNew === "province") {
  //             //   Object.keys(resourceFieldValueNew).forEach((addressFieldName) => {
  //             //     const addressFieldValue = resourceFieldValueNew[addressFieldName];
  //             //     // @ts-ignore
  //             //     formMethods.setValue(resourceFieldNameNew, addressFieldValue.shortCode ?? undefined);
  //             //     console.log("addressFieldName : ",addressFieldName,"addressFieldValue : ",addressFieldValue)

  //             //   });
  //             // }

  //             if (resourceFieldNameNew === "country") {
  //               formMethods.setValue(
  //                 resourceFieldNameNew,
  //                 resourceFieldValueNew.name ?? undefined
  //               );
  //             }
  //             if (resourceFieldNameNew === "province") {
  //               let id = resourceFieldValueNew.id;
  //               let name = resourceFieldValueNew.name;
  //               let shortCode = resourceFieldValueNew.shortCode;

  //               console.log("namenamenmae", name);

  //               formMethods.setValue(
  //                 resourceFieldNameNew,
  //                 `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
  //                 // '{id:1, name: "Alberta",shortCode:"AB"}'
  //               );
  //             }

  //           });
  //         });
  //       } else {
  //         // @ts-ignore
  //         formMethods.setValue(fieldName, fieldValue);
  //       }
  //       if(fieldName === 'metaFields'){
  //         fieldValue.forEach((obj:any) => {
  //           formMethods.setValue(obj.name,obj.value)
  //           // if(obj.name === 'initial' || obj.name === 'INITIALS'){
  //           //   formMethods.setValue('initials',obj.value)
  //           // }
  //           if(obj.name === 'pay_period'){
  //             // formMethods.setValue('payPeriod',obj.value)
  //             formMethods.setValue('payPeriod',26)
  //           }
  //           if(obj.name === 'date_of_birth'){
  //             if(obj.value !== ""){
  //             formMethods.setValue('dateOfBirth',obj.value)
  //             if(obj.value.charAt(2) === "*"){
  //               formMethods.setValue('dateOfBirth', obj.value);
  //             setDateOfBirth(obj.value)
  //             }else{
  //               const defaultDate = new Date(obj.value);
  //               formMethods.setValue('dateOfBirth', defaultDate);
  //               setDateOfBirth(formatDate(defaultDate as Date))
  //             }
  //             setDateOfBirthPlaceHolder(obj.value)
  //           }
  //           }
  //           console.log(`"objobj" : ${obj.name} ====== objValue : ${obj.value}`)
  //         })
  //       }
  //     });
  //     formMethods.setValue("selectedSlip", "create-new");
  //     formMethods.setValue("applyAdjustments", false);
  //     formMethods.setValue("boxARevenusDEmploi", "0.00");
  //     formMethods.setValue("boxBOtisationAuRRQ", "0.00");
  //     formMethods.setValue("boxCCotaIAssuranceEmploi", "0.00");
  //     formMethods.setValue("boxDCotisationAUnRPA", "0.00");
  //     formMethods.setValue("boxEImpotDuQuebecRetenu", "0.00");
  //     formMethods.setValue("boxFCotisationSyndicale", "0.00");
  //     formMethods.setValue("boxGSalaireAdmissibleAuRRQ", "0.00");
  //     formMethods.setValue("boxHCotisationAuRQAP", "0.00");
  //     formMethods.setValue("boxISalaireAdmissibleAuRQAP", "0.00");
  //     formMethods.setValue("boxJRegimePriveDassMaladie", "0.00");
  //     formMethods.setValue("boxKVoyages", "0.00");
  //     formMethods.setValue("boxLAutresAvantages", "0.00");
  //     formMethods.setValue("boxMCommissions", "0.00");
  //     formMethods.setValue("boxNDonsDeBienfaisance", "0.00");
  //     formMethods.setValue("boxOAutresRevenus", null);
  //     formMethods.setValue("boxPRegimeDassInterentreprises", "0.00");
  //     formMethods.setValue("boxQSalairesDifferes", "0.00");
  //     formMethods.setValue("boxRRevenuSitueDansUneReserve", "0.00");
  //     formMethods.setValue("boxSPourboiresRecus", "0.00");
  //     formMethods.setValue("boxTPourboiresAttribues", "0.00");
  //     formMethods.setValue("boxURetraiteProgressive", "0.00");
  //     formMethods.setValue("boxVNourritureEtLogement", "0.00");
  //     formMethods.setValue("boxWVehiculeAMoteur", "0.00");
  //     formMethods.setValue("code", null);
  //     formMethods.setValue("boxAAdjRevenusDEmploi", "0.00");
  //     formMethods.setValue("boxBAdjOtisationAuRRQ", "0.00");
  //     formMethods.setValue("boxCAdjCotaIAssuranceEmploi", "0.00");
  //     formMethods.setValue("boxEAdjImpotDuQuebecRetenu", "0.00");
  //     formMethods.setValue("boxHAdjCotisationAuRQAP", "0.00");
  //     formMethods.setValue("boxIAdjSalaireAdmissibleAuRQAP", "0.00");
  //     formMethods.setValue("slipStatus", 200);
  //     formMethods.setValue('comparison',[{ amount: 0, code: '', label:''}]);
  //   }
  // }, [resourceInfo, formMethods.setValue, formMethods]);

  useEffect(() => {
    //Load EMployee DDL
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 2000);
    const fetchData = async () => {
      try {
        const response: any = await RL1SlipService.getEmployeeDDL(
          clientId,
          EMPLOYEE_RESOURCE_LITERAL,
          taxYear
        );
        console.log("responseData-----------------", response);

        if (response && Array.isArray(response)) {
          let resources: IDropDownListResponse[] = response.map(
            (resourceItem: IDropDownListResponse) => ({
              // id: resourceItem.id,
              label: resourceItem.label,
              value: resourceItem.value,
            })
          );

          resources.unshift({
            label: "Create New",
            value: "create-new",
          });

          setSelectedResourceList(resources);
        }
        setResourceListWithoutCreateNew(sortData(response));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

      // when  I formMethods.setValue of otherinformation inside async calls method then facing issue while click on add more so we have to use state method to show otherinformation 
      useEffect(()=>{
        console.log("slipOtherInformation outside",slipOtherInformation)
        if (
          slipOtherInformation &&
          slipOtherInformation !== null &&
          slipOtherInformation.length !== 0
        ) {
        console.log("slipOtherInformation",slipOtherInformation)
          setOiInEdit(true);
          formMethods.setValue(
            "comparison",
            slipOtherInformation
          );
        } else if (!id) {
          console.log(
            "slipOtherInformation1234ee else",
            slipOtherInformation
          );
          setOiInEdit(true);
          formMethods.setValue(
            "comparison",
            [
              {
                amount: 0,
                code: "",
                label: "",
              },
            ]
          );
        }
      },[slipOtherInformation])

  const onSlipTypeSelectionChangeHandler = (e: any) => {
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 1000);
    const selectedType = e.value;
    selectedRL1SlipType = selectedType;
    // alert(selectedType);
  };

  const onCloseHandler = () => {
    setDialogOpen(false);
    setInvoiceDialogOpen(false);
  };

  console.log("selectedT4SlipsData.id", selectedRL1SlipsData?.id);
  // console.log("slipRecord.id",slipRecord?.id)
  // console.log("newCreatedSlipId",newCreatedSlipId)
  // console.log("editedRecordSlipCreateNew",editedRecordSlipCreateNew)
  console.log("selectedResource", selectedResource);
  console.log(
    "selectedT4SlipsData.resource.externalId",
    selectedRL1SlipsData?.resource?.externalId
  );
  // console.log("newCreatedResourceId",newCreatedResourceId)
  console.log(
    "selectedNotStartedResourceData.id",
    selectedNotStartedResourceData?.id
  );
  // console.log("selectedResource",selectedResource)

  const getCardStyles = (index: any) => {
    return {
      borderLeft:
        selectedCard === index ? "6px solid #0057db" : "2px solid transparent",
      cursor: "pointer",
    };
  };
  const getSelectedResourceSlipInCard = (index: any) => {
    let selectedItem =
      selectedCard === index ? selectedResourceSlip : "create-new";
    return selectedItem;
  };
  const getSelectedResourceSlipsListInCard = (index: any) => {
    let selectedList =
      selectedCard === index
        ? selectedResourceSlipList
        : [{ label: "Create New", value: "create-new" }];
    return selectedList;
  };

  const filteredResourceList =
    resourceListWithoutCreateNew &&
    resourceListWithoutCreateNew.filter((item: any) =>
      item.label.toLowerCase().includes(itemSearch.toLowerCase())
    );
  const onDividerButtonClickHandler = () => {
    setSidePanelsVisibility((prevButtonState: any) => !prevButtonState);
  };

  const onCreateNewButtonClicked = () => {
    // setSnackbarData({
    //   open: true,
    //   message: "Please load an employee for RL-1 Slip",
    //   severity: "error",
    // });
    // setNewCreatedResourceId('')
    formMethods.reset();
    setDateOfBirthPlaceHolder("");
    // setNewCreatedSlipId('')
    setDateOfBirth("");
    setSelectedResourceSlipList([{ label: "Create New", value: "create-new" }]);
    setSelectedResourceSlip("create-new");
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    setSlipOtherInformation([])
    //otherinformation will update selected items
    setSelectedOtherInformation(false);
    // if(id !== undefined){
    //   window.location.href = "/taxslip/rl1/setup";
    // }
    let path = `/taxslip/rl1/setup`;
    navigate(path);
    setRL1SlipRecord(undefined);
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 1000);
    setSelectedResource("create-new");
    //otherinformation will update selected items
    setSelectedOtherInformation(false);
    setSelectedCard(null);
    setAdjustedDataResponse(undefined)

    
    window.location.reload()
  };

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: id ? id : "",
        entityId2: "",
        entityId3: "",
        screenName: RL1_SETUP_SCREEN_SLUG,
        companyName: clientName,
        activityType: "activity_type=create&activity_type=updated&activity_type=print&activity_type=delete",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          pb: "5rem",
          "& .MuiAppBar-root": { zIndex: 2, top: "65px" },
          "& .p-datepicker": { zIndex: 2 },
          paddingTop: "2rem",
        }}
      >
        <BackdropCircularProgress
          open={
            // isLoadingDropDownList ||
            isSubmitting ||
            // isLoadingResourceSlips ||
            // isLoadingSlipData ||
            isLoadingData ||
            // reFetchingResourceSlipsData ||
            // reFetchingSlipData ||
            // isLoadingResourceInfo ||
            isLoadingAdjustedData ||
            // isLoadingProvinceDropDownList ||
            isLoadingSlipListDDL ||
            isLoadingNotStartedSlipId ||
            // isLoadingRL1OtherInformationList ||
            isDeleting ||
            isLoadingNotStartedSlipId ||
            isLoadingDataa ||
            isLoadingGlobalVariables ||
            isLoadingActivityLogData
          }
          testId={testId}
        />
        <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={deleteRL1SlipField}
          cancelActionHandler={onCloseHandler}
          testId="T4-confirmation-dialog"
        />
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          {sidePanelsVisibility && (
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Box sx={{ margin: "0" }}>
                <InputText
                  value={itemSearch}
                  onChange={(e) => setItemSearch(e.target.value)}
                  placeholder={"Search by name..."}
                  className="w-full search-field"
                />
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    sx={{ right: 0, margin: "10px 0px", fontSize: "12px" }}
                    onClick={onCreateNewButtonClicked}
                  >
                    + Create New
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  // display: "flex",
                  height: "95rem",
                  // flexDirection: "column",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#ccc",
                    borderRadius: "3px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#aaa",
                  },
                }}
              >
                {resourceListWithoutCreateNew &&
                  filteredResourceList.map((item: any, index: any) => {
                    return (
                      <Card
                        sx={{
                          padding: 0,
                          borderRadius: "8px",
                          marginBottom: "1rem",
                        }}
                        key={index}
                        onClick={() =>
                          onResourceSelectionChangeHandler(item, item.value)
                        }
                        style={getCardStyles(item.value)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0.8rem",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: ".875rem" }}
                          >
                            {item.label}
                          </Typography>
                          <Avatar
                            shape="circle"
                            style={{
                              background: "#0057db",
                              color: "#fff",
                              borderRadius: "50%",
                              fontWeight: "bold",
                              fontSize: ".775rem",
                              padding: "2px",
                            }}
                          >
                            {item.label
                              .replace(/\s+/g, " ")
                              .split(" ")
                              .filter((word: string) => word.trim() !== "")
                              .map((word: string) => word[0].toUpperCase())
                              .slice(0, 2)
                              .join("")}
                          </Avatar>
                        </Box>
                        {selectedCard === item.value ? (
                          <>
                            <Divider className="m-0" />
                            <Box>
                              <Box sx={{ padding: "0.6rem" }}>
                                <Dropdown
                                  value={getSelectedResourceSlipInCard(
                                    item.value
                                  )}
                                  onChange={
                                    onResourceSlipSelectionChangeHandler
                                  }
                                  options={getSelectedResourceSlipsListInCard(
                                    item.value
                                  )}
                                  onClick={(e: any) => e.stopPropagation()}
                                  className="slip-dropdown"
                                />
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <></>
                        )}
                      </Card>
                    );
                  })}
              </Box>
            </Grid>
          )}
          <Divider
            layout="vertical"
            align="center"
            className="verticle-divider"
            style={{ zIndex: 0 }}
          >
            <Button
              variant="outlined"
              sx={{
                minWidth: "fit-content",
                padding: "20px 0",
                borderRadius: "7px",
              }}
              onClick={onDividerButtonClickHandler}
            >
              {sidePanelsVisibility ? (
                <i
                  className="pi pi-angle-double-left"
                  style={{ color: "#0057db" }}
                ></i>
              ) : (
                <i
                  className="pi pi-angle-double-right"
                  style={{ color: "#0057db" }}
                ></i>
              )}
            </Button>
          </Divider>
          <Grid
            item
            xs={sidePanelsVisibility ? 8 : 10}
            sm={sidePanelsVisibility ? 8 : 10.8}
            md={sidePanelsVisibility ? 9.4 : 11.2}
            lg={sidePanelsVisibility ? 9.5 : 11.6}
            xl={sidePanelsVisibility ? 9.7 : 11.7}
            sx={{ "& .MuiGrid-container": { marginTop: 0 } }}
          >
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <TwoColumnLayout
                  toolbar={
                    <RL1SlipSetupToolbar
                      onResetClicked={onResetClicked}
                      onDeleteClicked={onDeleteClicked}
                      onPrintClicked={onPrintClicked}
                      isSubmitting={isSubmitting}
                      formMethods={formMethods}
                    />
                  }
                  leftColumnContent={
                    <>
                      {!sidePanelsVisibility && (
                        <StandardPanel
                          title="Load Slips"
                          testId={`${testId}-load-slips-panel`}
                        >
                          <Grid
                            container
                            columnSpacing={2}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <EnhancedDropDownRHFWithLabel
                                label="Load Employee"
                                selectName="selectedEmployee"
                                items={selectedResourceList}
                                selectedItem={selectedResource}
                                // selectedItem="Create New"
                                onSelectionChangeHandler={
                                  onResourceSelectionChangeHandler
                                }
                                testId={`${testId}-selected-employee`}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <EnhancedDropDownRHFWithLabel
                                label="Load RL-1 Slip"
                                selectName="selectedSlip"
                                // items={rl1slipListItems}
                                items={selectedResourceSlipList}
                                // selectedItem="Create New"
                                selectedItem={selectedResourceSlip}
                                testId={`${testId}-selected-slip`}
                                onSelectionChangeHandler={
                                  onResourceSlipSelectionChangeHandler
                                }
                                // isLoading={isLoadingFlexFieldsList}
                              />
                            </Grid>
                          </Grid>
                        </StandardPanel>
                      )}
                      <StandardPanel
                        title="Slip Data"
                        testId={`${testId}-slip-data`}
                        topPadding={!sidePanelsVisibility ? 2 : 0}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <EnhancedIOSSwitchRHFWithLabel
                            name="applyAdjustments"
                            checked={false}
                            testId="applyAdjustments"
                            label="Apply Adjustments"
                            labelPosition={LabelPosition.Left}
                            labelColumns={6}
                            fieldColumns={6}
                            onSelectionChangeHandler={onSelectionChange}
                          />
                        </Grid>
                        {RL1OtherInformationList && (
                          <T4SlipDataPanel
                            formMethods={formMethods}
                            isLoading={false}
                            otherInformationList={
                              RL1OtherInformationList !== undefined
                                ? RL1OtherInformationList
                                : []
                            }
                            testId={testId}
                            oiInEdit={oiInEdit}
                            selectedOtherInformation={selectedOtherInformation}
                            globalVariablesData={
                              globalVariablesData && globalVariablesData
                            }
                          />
                        )}
                      </StandardPanel>
                    </>
                  }
                  rightColumnContent={
                    <>
                      <StandardPanel
                        title="Employee Info"
                        testId={`${testId}-employee-info`}
                      >
                        <EmployeeInfoSetupFieldsPanel
                          onDateOfBirthChangeHandler={
                            onDateOfBirthChangeHandler
                          }
                          testId={testId}
                        />
                        <FieldWrapper>
                          <FieldLayout
                            label="Date of Birth"
                            // isRequired={isRequired}
                            testId={testId}
                            labelPosition={LabelPosition.Left}
                            labelColumns={4}
                            fieldColumns={8}
                          >
                            <Calendar
                              id="dateOfBirth"
                              {...formMethods.register("dateOfBirth")} // Register the input with react-hook-form using methods.register
                              value={formMethods.watch("dateOfBirth") || null} // Set the value using watch to reflect changes
                              showIcon
                              // dateFormat="dd/mm/yy" // Customize the date format as needed
                              placeholder={
                                dateOfBirthPlaceHolder !== "" &&
                                dateOfBirthPlaceHolder.charAt(2) === "*"
                                  ? "Unmask to view / edit"
                                  : "dd/mm/yyyy"
                              }
                              disabled={
                                dateOfBirthPlaceHolder !== "" &&
                                dateOfBirthPlaceHolder.charAt(2) === "*"
                              } //if unmask then it is enabled
                              className="w-full"
                              style={{ height: "2.6rem" }}
                              onChange={onDateOfBirthChangeHandler}
                              dateFormat="M dd, yy"
                            />
                          </FieldLayout>
                        </FieldWrapper>
                      </StandardPanel>
                      <StandardPanel
                        title="Address"
                        testId={`${testId}-employee-address-panel`}
                        topPadding={2}
                      >
                        <EmployeeAddressSetupFieldsPanel testId={testId} />
                      </StandardPanel>
                      <StandardPanel
                        title="Filing Data"
                        testId={`${testId}-filing-data-panel`}
                        topPadding={2}
                      >
                        <EnhancedDropDownRHFWithLabel
                          label="Slip Type"
                          selectName="slipType"
                          items={[
                            { label: "Original", value: 400 },
                            { label: "Amended", value: 200 },
                            { label: "Cancelled", value: 300 },
                            { label: "Additional", value: 100 },
                          ]}
                          selectedItem={400}
                          testId="slip-type"
                          labelPosition={LabelPosition.Left}
                          required
                          onSelectionChangeHandler={
                            onSlipTypeSelectionChangeHandler
                          }
                        />

                        <FilingDataSetupFieldsPanel
                          testId={testId}
                          formMethods={formMethods}
                        />
                      </StandardPanel>
                    </>
                  }
                  testId="t4-slip-setup-form"
                />
              </form>
            </FormProvider>
          </Grid>
        </Grid>
        {/* invoice dialogu open if any transaction is unPaid */}
        <EnhancedDialog
          dialogTitle="Invoice(s) Due"
          key="dialog-box"
          testId="dialog"
          onCloseHandler={onCloseHandler}
          open={openInvoiceDialog}
          dialogContent={
            <InvoiceDialogPanel
              invoiceData={invoiceData}
              onUnPaidClickHandler={onUnPaidClickHandler}
              onClickInvoiceNoHandler={onClickInvoiceNoHandler}
            />
          }
          dialogAction={
            <Grid>
              <Button autoFocus variant="contained" onClick={onCloseHandler}>
                Cancel
              </Button>
            </Grid>
          }
        />
        <EnhancedSnackbar
          message={snackbarData.message}
          onCloseHandler={onSnackbarClosed}
          severity={snackbarData.severity}
          testId={`${testId}-snackbar`}
          open={snackbarData.open}
          autoHide={snackbarData.autoHide}
        />
      </Grid>
    </>
  );
};
