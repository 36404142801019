import { Grid } from "@mui/material";
import {
  LabelPosition,
  RHookFormTextField,
  RHookFormTextFieldWithLabel,
} from "@websential/cosmic";
import React from "react";

export interface IBillingSlabsInput {
  id: string;
  label: string;
  defaultValue?: string;
  readOnly?: boolean;
}

export const BillingSlabsInput: React.FC<IBillingSlabsInput> = ({
  id,
  label,
  defaultValue = "",
  readOnly = false,
}) => {
  return (
    <Grid container columnSpacing={1} xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <RHookFormTextFieldWithLabel
          label={label}
          id={`${id}1`}
          testId={`${id}1`}
          labelPosition={LabelPosition.Left}
          labelColumns={6}
          fieldColumns={6}
          defaultValue={defaultValue}
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
        <RHookFormTextField id={`${id}2`} testId={`${id}2`} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
        <RHookFormTextField id={`${id}3`} testId={`${id}3`} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
        <RHookFormTextField id={`${id}4`} testId={`${id}4`} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
        <RHookFormTextField id={`${id}5`} testId={`${id}5`} />
      </Grid>
    </Grid>
  );
};
