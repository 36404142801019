import {
  INITIAL_PAGE_SIZE,
} from "common/constants";
import { toSnake } from "common/methods";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";

import { Grid } from "@mui/material";

import PrimeDataGrid from "./PrimeDataGrid";
import { T4AdjustmentReportService } from "services/T4AdjustmentReportService";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IT4AdjustmentReport {}
const GET_T4_ADJUSTMENT_REPORT_QUERY_KEY = "t4-adjustment-report-list";
const useT4AdjustmentReport = (
  clientId: string,
  taxYear: number,
  params: IGetListRequest
) =>
    useQuery(
      [GET_T4_ADJUSTMENT_REPORT_QUERY_KEY, clientId, taxYear, params],
      (data) => {
        return T4AdjustmentReportService.getT4AdjustmentReportNew(clientId, taxYear, params);
      },
      { refetchOnWindowFocus: false }
    );

export const T4AdjustmentReport: React.FC<IT4AdjustmentReport> = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state: IAppState) => state.client);
  const taxYear  = useSelector((state: IAppState) => state.taxYear.year);
  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "resource.firstName",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: requestParams.sortField  && requestParams.sortField === "employee" ? "resource.firstName" : requestParams.sortField ? requestParams.sortField : "resource.firstName",
      order_direction: requestParams.sortOrder
        ? requestParams.sortOrder
        : "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
    };
    console.log("dispatchParams",dispatchParams)
    
    setQueryParams(dispatchParams);
  }, []);
  
  const { isLoading, data } = useT4AdjustmentReport(id, taxYear, queryParams);
  const T4AdjustmentReportList: any = data;
  console.log("@@@@@ => T4AdjustmentReportList responce", T4AdjustmentReportList);

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
  useEffect(()=>{
    if(isLoading){
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    } else {
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    }
  },[isLoading])

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: '',
        companyName: '',
        activityType:
          "",
        activityLogVisibility: false,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ width: "100%", overflow: "hidden" }}
    >
      <PrimeDataGrid
        rows={T4AdjustmentReportList && T4AdjustmentReportList?.items}
        last_rows={T4AdjustmentReportList && T4AdjustmentReportList?.sumData}
        columns={T4AdjustmentReportList ? T4AdjustmentReportList.headerColumns : []}
        isLoading={isLoading || isAxiosCallLoading}
        showIndexColumn={true}
        showChecboxColumn={true}
        showSettingColumn={true}
        totalRecords={T4AdjustmentReportList && T4AdjustmentReportList.total}
        initialPageSize={INITIAL_PAGE_SIZE}
        onRequestParamsChange={onRequestParamsChange}
      />
    </Grid>
  );
};
