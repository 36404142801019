import { AxiosResponse } from 'axios';
import http from 'common/http-common';
import { IGetListRequest, IResponse, IServerError } from 'models/common';
import { IDropDownListResponse } from 'models/common/IDropDownListResponse';
import { IFieldsetModel, IFieldsetResponseModel } from 'models/fieldset';
import {
  IFlexFieldPanelAssignmentRequestPayload, IFlexFieldPanelAssignmentResponsePayload,
  IFlexFieldPanelListModel,
  IScreenPanelsAssignmentResponsePayload
} from 'models/flexFieldPanel';
import { IFlexFieldPanelRequestPayload } from 'models/flexFieldPanel/IFlexFieldPanelRequestPayload';
import {
  IFlexFieldPanelResponsePayload
} from 'models/flexFieldPanel/IFlexFieldPanelResponsePayload';

// TODO: replace this with API response once it's ready
export const FieldsetService = {
  create: async (requestPayload: IFieldsetModel): Promise<IFieldsetResponseModel[] | IServerError | IResponse> => {
    // if (requestPayload.id) {
    //   console.log('requestPayload ==>> update request', requestPayload);
    //   const response: AxiosResponse<IFlexFieldPanelResponsePayload> = await http.put<IFlexFieldPanelResponsePayload>(
    //     `/v1/flex-field-panel-labels/${requestPayload.id}/`, requestPayload);

    //   if (response.status === 200) {
    //     return response.data;
    //   }
    //   else if (response.status === 500) {
    //     return {
    //       status: false,
    //       message: "Something went wrong.",
    //       error: true,
    //     };
    //   }
    // }
    console.log('requestPayload ==>> add request', requestPayload);

    let payload: any = []
    for(let index = 0; index < requestPayload.panels.length; index++) {
      payload.push({
        sequence: index + 1,
        fieldValue: requestPayload.categoryFieldValue,
        field: parseInt(requestPayload.categoryFieldId),
        screen: parseInt(requestPayload.screenId),
        tab: parseInt(requestPayload.tabId),
        panelLabelId: requestPayload.panels[index],
        defaultCategoryField: requestPayload.defaultCategoryField
      });
    }
    console.log('requestPayload ==>> add request', payload);
    const response: AxiosResponse<IFieldsetResponseModel[]> = await http.post<IFieldsetResponseModel[]>(
      '/v1/flex-field-fieldsets/', payload);

    if (response.status === 201) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "400 Bad Request.",
        error: true,
      };
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getList: async (
    params: IGetListRequest
  ): Promise<
    IFlexFieldPanelListModel[] | IServerError | IResponse
  > => {
    const response: AxiosResponse<IFlexFieldPanelListModel[]> = await http.get<IFlexFieldPanelListModel[]>(
      '/v1/flex-field-panel-labels/');

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getById: async (id: string | undefined): Promise<IFlexFieldPanelResponsePayload | IServerError | IResponse | null> => {
    if (id) return null
    const response: AxiosResponse<IFlexFieldPanelResponsePayload> = await http.get<IFlexFieldPanelResponsePayload>(
      `/v1/flex-field-panel-labels/${id}/`);

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  deleteById: async (flexFieldPanelId: number): Promise<string | IServerError | IResponse> => {
    console.log('deleteById', flexFieldPanelId);
    // return new Promise(resolve => setTimeout(() => resolve("Flex Field has been deleted successfully"), 1000));
    const response: AxiosResponse<string> = await http.delete<string>(`/v1/flex-field-panel-labels/${flexFieldPanelId}/`);

    if (response.status === 204) {
      return "Flex Field Panel has been deleted successfully";
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getDropDownList: async (): Promise<IDropDownListResponse[] | IServerError | IResponse> => {
    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<IDropDownListResponse[]>(
      '/v1/flex-field-panel-labels/ddl-list');

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getFlexFieldsAssignedToPanel: async (id: string | undefined): Promise<IFlexFieldPanelAssignmentResponsePayload | IServerError | IResponse | null> => {
    if (!id) return null;
    const response: AxiosResponse<IFlexFieldPanelAssignmentResponsePayload> = await http.get<IFlexFieldPanelAssignmentResponsePayload>(
      `/v1/flex-field-panel-labels/${id}/flex-fields`);

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  assignFlexFieldsToPanel: async (requestPayload: IFlexFieldPanelAssignmentRequestPayload): Promise<IFlexFieldPanelAssignmentResponsePayload | IServerError | IResponse | null> => {
    console.log('======', requestPayload);
    if (!requestPayload.panelId) return null;
    const response: AxiosResponse<IFlexFieldPanelAssignmentResponsePayload> = await http.post<IFlexFieldPanelAssignmentResponsePayload>(
      `/v1/flex-field-panel-labels/${requestPayload.panelId}/flex-fields`, requestPayload.assignedFields);

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getScreensDropDownList: async (): Promise<IDropDownListResponse[] | IServerError | IResponse> => {
    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<IDropDownListResponse[]>(
      '/v1/flex-field-fieldsets/screen-ddl');

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getTabsDropDownList: async (): Promise<IDropDownListResponse[] | IServerError | IResponse> => {
    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<IDropDownListResponse[]>(
      '/v1/flex-field-tabs/tabs-ddl');

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getPanelsAssignedToScreen: async (screenId: number, tabId: number, categoryFieldId: number, categoryFieldValue: string): Promise<IScreenPanelsAssignmentResponsePayload | IServerError | IResponse | null> => {
    const response: AxiosResponse<IScreenPanelsAssignmentResponsePayload> = await http.get<IScreenPanelsAssignmentResponsePayload>(
      `/v1/flex-field-fieldsets/assigned-panels?screen_id=${screenId}&tab_id=${tabId}&category_field_id=${categoryFieldId}&category_field_value=${categoryFieldValue}`);

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
};
