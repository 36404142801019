import * as _ from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

import List from "@mui/material/List";

import { Item, LeftMenuItem } from "./LeftMenuItem";
import { useDispatch, useSelector } from "react-redux";
import { setUserMenuClose, setUserMenuOpen } from "store/actions";
import { IAppState } from "store/reducers";

export interface IEnhancedLeftMenu {
  menuItems: Item[];
}

export const LeftMenu: React.FC<IEnhancedLeftMenu> = ({ menuItems }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedMenuItem } = useSelector((state: IAppState) => {
    return {
      selectedMenuItem: state.navigation.selectedMenuItem,
    };
  });
  // const [menu, setMenu] = useState(menuItems);
  
  const clearUserMenuOpen = (ItemData:Item) => {
    dispatch(setUserMenuClose(ItemData));
  };

  const listItemButtonClickHandler = (menuItem: Item) => {
    console.log("menuItem--",menuItem)
    dispatch(setUserMenuOpen(menuItem));
    // const menuCopy = _.cloneDeep(menuItems);
    // for (let index = 0; index < menuCopy.length; index++) {
    //   if (menuCopy[index].id === menuItem.id) {
    //     menuCopy[index].open = !menuCopy[index].open;
    //   } else if (menuCopy[index].id === menuItem.parentMenuId) {
    //     menuCopy[index].open = true;
    //   } else {
    //     menuCopy[index].open = false;
    //   }
    // }

    // TODO: dispatch action or call parent function to set the active menu
    // setMenu(menuCopy);
    if(selectedMenuItem.text === menuItem.text){
      // alert(`same ${selectedMenuItem.text}`)
      let closeMenuByParentID = {
        id:-1,
        text:"",
        parentMenuId:selectedMenuItem.parentMenuId ? selectedMenuItem.parentMenuId : undefined
      }
      console.log("closeMenuByParentID",closeMenuByParentID)
      clearUserMenuOpen(closeMenuByParentID)
    }
    if (menuItem.navigateToURL) {
      navigate(menuItem.navigateToURL);
    }
  };

  return (
    <List sx={{ width: "100%", bgcolor: "transparent" }}>
      {menuItems.map((item: any, index: number) => (
        <LeftMenuItem
          key={index}
          item={item}
          listItemButtonClickHandler={listItemButtonClickHandler}
        />
      ))}
    </List>
  );
};
