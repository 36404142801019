import { GridSize } from '@mui/material';
import { DataType, FieldType, ITabLayout } from '@websential/cosmic';
import { IFlexFieldsPanelLayout } from '@websential/cosmic/build/components/RHookFormGenerator/types/IFlexFieldsPanelLayout';


export const ACCOUNTANT_SETUP_FORM = 
[
	{
	  name: "Accountant",
	  description: "Accountant Information",
	  sequence: 1,
	  categoryFields: [],
	  panels: [
		{
        label: "Create Account",
        categoryFieldId: "",
        categoryFieldValue: "",
        sequence: 1,
        gridColumns: 10 as GridSize,
        fields: [
          {
            id: 1,
            fieldLabel: "First Name",
            fieldId: "firstName",
            fieldType: FieldType.TextField,
            placeholder: "First Name",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 1,
          },
          {
            id: 2,
            fieldLabel: "Last Name",
            fieldId: "lastName",
            fieldType: FieldType.TextField,
            placeholder: "Last Name",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 2,
          },
          {
            id: 3,
            fieldLabel: "Account Title",
            fieldId: "accTitle",
            fieldType: FieldType.TextField,
            placeholder: "Account Title",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 3,
          },
          {
            id: 4,
            fieldLabel: "Email",
            fieldId: "email",
            fieldType: FieldType.Email,
            placeholder: "Email",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 4,
          },
          {
            id: 5,
            fieldLabel: "Country",
            fieldId: "countryId",
            fieldType: FieldType.DropDownList,
            placeholder: "",
            fieldValue: "Canada",
            fieldInternalValue: "1",
            dataType: DataType.Object,
            fieldFormat: "",
            defaultValue: "1",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: true,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 5,
          },
          {
            id: 6,
            fieldLabel: "Province",
            fieldId: "provinceId",
            fieldType: FieldType.DropDownList,
            placeholder: "",
            fieldValue: "Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon",
            fieldInternalValue: "1,2,3,4,5,6,7,8,9,10,11,12,13",
            dataType: DataType.Object,
            fieldFormat: "",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 6,
          },
          {
            id: 7,
            fieldLabel: "City",
            fieldId: "city",
            fieldType: FieldType.TextField,
            placeholder: "City",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 7,
          },
          {
            id: 8,
            fieldLabel: "Post Code",
            fieldId: "postalCode",
            fieldType: FieldType.PostCode,
            placeholder: "A9A9A9",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: true,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 8,
          },
          {
            id: 9,
            fieldLabel: "Address",
            fieldId: "addressLine1",
            fieldType: FieldType.TextField,
            placeholder: "Address",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 9,
          },
          {
            id: 10,
            fieldLabel: "Website",
            fieldId: "website",
            fieldType: FieldType.TextField,
            placeholder: "Website",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 10,
          },
          {
            id: 11,
            fieldLabel: "Phone",
            fieldId: "phoneNo",
            fieldType: FieldType.MaskedTextField,
            placeholder: "(999) 999-9999",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "(000) 000-0000",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 5.4 as GridSize,
            fieldColumn: 6.6 as GridSize,
            fullFieldColumns: 9 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 11,
          },
          {
            id: 12,
            fieldLabel: "Ext",
            fieldId: "ext",
            fieldType: FieldType.MaskedTextField,
						placeholder: "000",
						fieldValue: "",
						fieldInternalValue: "",
						dataType: DataType.String,
						fieldFormat: "{000}",
						defaultValue: "",
						labelPosition: "left",
						labelColumn: 4 as GridSize,
						fieldColumn: 8 as GridSize,
						fullFieldColumns: 3 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 12,
          }
        ],
      }
	  ],
	},
  ] as ITabLayout[];


export const ACCOUNTANT_SETUP_FORM_WITH_NULL_DEFAULT_VALUES = 
[
	{
	  name: "Accountant",
	  description: "Accountant Information",
	  sequence: 1,
	  categoryFields: [],
	  panels: [
		{
        label: "Create Account",
        categoryFieldId: "",
        categoryFieldValue: "",
        sequence: 1,
        gridColumns: 10 as GridSize,
        fields: [
          {
            id: 1,
            fieldLabel: "First Name",
            fieldId: "firstName",
            fieldType: FieldType.TextField,
            placeholder: "First Name",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 1,
          },
          {
            id: 2,
            fieldLabel: "Last Name",
            fieldId: "lastName",
            fieldType: FieldType.TextField,
            placeholder: "Last Name",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 2,
          },
          {
            id: 3,
            fieldLabel: "Account Title",
            fieldId: "accTitle",
            fieldType: FieldType.TextField,
            placeholder: "Account Title",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 3,
          },
          {
            id: 4,
            fieldLabel: "Email",
            fieldId: "email",
            fieldType: FieldType.Email,
            placeholder: "Email",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 4,
          },
          {
            id: 5,
            fieldLabel: "Country",
            fieldId: "countryId",
            fieldType: FieldType.DropDownList,
            placeholder: "",
            fieldValue: "Canada",
            fieldInternalValue: "1",
            dataType: DataType.Object,
            fieldFormat: "",
            defaultValue: "1",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: true,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 5,
          },
          {
            id: 6,
            fieldLabel: "Province",
            fieldId: "provinceId",
            fieldType: FieldType.DropDownList,
            placeholder: "",
            fieldValue: "Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon",
            fieldInternalValue: "1,2,3,4,5,6,7,8,9,10,11,12,13",
            dataType: DataType.Object,
            fieldFormat: "",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 6,
          },
          {
            id: 7,
            fieldLabel: "City",
            fieldId: "city",
            fieldType: FieldType.TextField,
            placeholder: "City",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 7,
          },
          {
            id: 8,
            fieldLabel: "Post Code",
            fieldId: "postalCode",
            fieldType: FieldType.PostCode,
            placeholder: "A9A9A9",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: true,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 8,
          },
          {
            id: 9,
            fieldLabel: "Address",
            fieldId: "addressLine1",
            fieldType: FieldType.TextField,
            placeholder: "Address",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 9,
          },
          {
            id: 10,
            fieldLabel: "Website",
            fieldId: "website",
            fieldType: FieldType.TextField,
            placeholder: "Website",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 10,
          },
          {
            id: 11,
            fieldLabel: "Phone",
            fieldId: "phoneNo",
            fieldType: FieldType.MaskedTextField,
            placeholder: "(999) 999-9999",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "(000) 000-0000",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 5.4 as GridSize,
            fieldColumn: 6.6 as GridSize,
            fullFieldColumns: 9 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 11,
          },
          {
            id: 12,
            fieldLabel: "Ext",
            fieldId: "ext",
            fieldType: FieldType.MaskedTextField,
						placeholder: "000",
						fieldValue: "",
						fieldInternalValue: "",
						dataType: DataType.String,
						fieldFormat: "{000}",
						defaultValue: "",
						labelPosition: "left",
						labelColumn: 4 as GridSize,
						fieldColumn: 8 as GridSize,
						fullFieldColumns: 3 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            comparison: [],
            sequence: 12,
          }
        ],
      }
	  ],
	},
  ] as ITabLayout[];






//old according to tab layout
// export const ACCOUNTANT_SETUP_FORM = [
//   {
//     name: "Accountant",
//     description: "Accountant Information",
//     sequence: 1,
//     categoryFields: [],
//     panels: [
//       {
//         label: "Create Account",
//         categoryFieldId: "",
//         categoryFieldValue: "",
//         sequence: 1,
//         gridColumns: 10 as GridSize,
//         fields: [
//           {
//             id: 1,
//             fieldLabel: "First Name",
//             fieldId: "firstName",
//             fieldType: FieldType.TextField,
//             placeholder: "First Name",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             comparison: [],
//             sequence: 1,
//           },
//           {
//             id: 2,
//             fieldLabel: "Last Name",
//             fieldId: "lastName",
//             fieldType: FieldType.TextField,
//             placeholder: "Last Name",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             comparison: [],
//             sequence: 2,
//           },
//           {
//             id: 3,
//             fieldLabel: "Account Title",
//             fieldId: "accTitle",
//             fieldType: FieldType.TextField,
//             placeholder: "Account Title",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             comparison: [],
//             sequence: 3,
//           },
//           {
//             id: 4,
//             fieldLabel: "Email",
//             fieldId: "email",
//             fieldType: FieldType.Email,
//             placeholder: "Email",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             comparison: [],
//             sequence: 4,
//           },
//           {
//             id: 5,
//             fieldLabel: "Country",
//             fieldId: "countryId",
//             fieldType: FieldType.DropDownList,
//             placeholder: "",
//             fieldValue: "Canada",
//             fieldInternalValue: "1",
//             dataType: DataType.Object,
//             fieldFormat: "",
//             defaultValue: "1",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: true,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             comparison: [],
//             sequence: 5,
//           },
//           {
//             id: 6,
//             fieldLabel: "Province",
//             fieldId: "provinceId",
//             fieldType: FieldType.DropDownList,
//             placeholder: "",
//             fieldValue: "Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon",
//             fieldInternalValue: "1,2,3,4,5,6,7,8,9,10,11,12,13",
//             dataType: DataType.Object,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             comparison: [],
//             sequence: 6,
//           },
//           {
//             id: 7,
//             fieldLabel: "City",
//             fieldId: "city",
//             fieldType: FieldType.TextField,
//             placeholder: "City",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             comparison: [],
//             sequence: 7,
//           },
//           {
//             id: 8,
//             fieldLabel: "Post Code",
//             fieldId: "postalCode",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "A9A9A9",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "{a0a0a0}",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: true,

//             fieldClass: "",
//             validationFormula: "",
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             comparison: [],
//             sequence: 8,
//           },
//           {
//             id: 9,
//             fieldLabel: "Address",
//             fieldId: "addressLine1",
//             fieldType: FieldType.TextField,
//             placeholder: "Address",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             comparison: [],
//             sequence: 9,
//           },
//           {
//             id: 10,
//             fieldLabel: "Phone",
//             fieldId: "phoneNo",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "(999) 999-9999",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "(000) 000-0000",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             comparison: [],
//             sequence: 10,
//           },
//           {
//             id: 11,
//             fieldLabel: "Ext",
//             fieldId: "ext",
//             fieldType: FieldType.TextField,
//             placeholder: "536",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             comparison: [],
//             sequence: 11,
//           },
//           {
//             id: 12,
//             fieldLabel: "Website",
//             fieldId: "website",
//             fieldType: FieldType.TextField,
//             placeholder: "Website",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             comparison: [],
//             sequence: 12,
//           }
//         ],
//       }
//     ],
//   },
// ] as ITabLayout[];
