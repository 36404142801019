
import { IGetListRequest } from "models";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";

import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import FileSaver from "file-saver";
import { useDispatch } from "react-redux";

import {
  BackdropCircularProgress,
  DeleteConfirmationDialog,
  EnhancedSnackbar,
  IResponse,
  IServerError,
} from "@websential/cosmic";

import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions/invoiceReportActions";
import { ADMIN_INVOICE_REPORT_SCREEN_SLUG, INITIAL_PAGE_SIZE, INITIAL_SNACKBAR_DATA } from "common/constants";
import { useCallback, useEffect, useState } from "react";
import { PrimeDataGridInvoiceAdminReport } from "./PrimeDataGridInvoiceAdminReport";
import { setSelectedAccountant, setSelectedClient } from "store/actions";
import { FormProvider, useForm } from "react-hook-form";
import { InvoiceAdminReportHeader } from "./InvoiceAdminReportHeader";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AccountantService } from "services";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IInvoiceReport {}
const INVOICE_LIST_QUERY_KEY = "admin-invoice-list";
const useInvoices = (
  startDate:string,
  endDate:string,
  dateAppliesTo:number,
  paymentStatus:number,
  queryParams:any
  ) =>
  useQuery(
    ["admin-invoice-list",startDate,endDate,dateAppliesTo,paymentStatus,queryParams],
    (data) => {
      return ReportsService.getInvoiceAdminReport1(startDate,endDate,dateAppliesTo,paymentStatus,queryParams);
    },
    { refetchOnWindowFocus: false }
  );
export const InvoiceAdminReport: React.FC<IInvoiceReport> = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { id } = useSelector((state: IAppState) => state.client);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [invoicesResponseData, setInvoicesResponseData] = useState<any>(undefined);

  // const [invoiceList, setInvoiceList] = useState<any>(undefined);
  const [isLoadingDesiredInvoiceList, setIsLoadingDesiredInvoiceList] = useState(false);
  const [isLoadingInvoiceList, setIsLoadingInvoiceList] = useState<any>(false);
  const [invoiceListUpdatedItems, setInvoiceListUpdatedItems] =
    useState<any>(undefined);
    const [invoiceListUpdatedColumns, setInvoiceListUpdatedColumns] =
    useState<any>(undefined);
    const [openDialog, setDialogOpen] = useState(false);
    const [multiDelete, setMultiDelete] = useState<boolean>(false);
    const [delRowsPayload, setDelRowsPayload] = useState<any>(undefined);
    const [queryParams, setQueryParams] = useState<IGetListRequest>({
      search_query: "",
      order_by: "client_name",
      order_direction: "asc",
      page: 1,
      size: INITIAL_PAGE_SIZE,
    });

    const onRequestParamsChange = useCallback((requestParams) => {
      console.log("##### - request params", requestParams);
      let dispatchParams = {
        search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
        order_by: "client_name",
        order_direction: "asc",
        page: requestParams.page + 1,
        size: requestParams.rows,
      };
      console.log("dispatchParams",dispatchParams)
      
      setQueryParams(dispatchParams);
    }, []);

    const formMethods = useForm<any>({
      // resolver: yupResolver(YearSchema),
      mode: "onBlur",
      reValidateMode: "onChange",
      // defaultValues: DEFAULT_VALUES,
    });

    const formatDate = (datee: any) => {
      console.log("dateeeee",datee)
      if(datee !== '' && datee !== null){
      const date = new Date(datee);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); 
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
  
      return formattedDate;
    } else {
      return ''
    }
    };

  // const currentDateRange = formMethods.getValues('dateRange')
  // console.log("dateRangedateRange",currentDateRange)

  const startDate = formMethods.getValues('startDate')
  const endDate = formMethods.getValues('endDate')
  const currentYear = new Date().getFullYear();
  const initialStartDatee = new Date(currentYear, 0, 1);
  const initialEndDatee = new Date(currentYear, 2, 31);
  const [startDateState, setStartDateState] = useState<any>(initialStartDatee)
  const [endDateState, setEndDateState] = useState<any>(initialEndDatee)
  useEffect(() => {
    if(startDate && endDate){
      setStartDateState(startDate)
      setEndDateState(endDate)
    }
  },[startDate,endDate])

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  const dateAppliesTo = formMethods.getValues('dateAppliesTo')
  const paymentStatus = formMethods.getValues('status')
  // const { isLoading, data } = useInvoices(formattedStartDate,formattedEndDate,dateAppliesTo,paymentStatus,queryParams); 
  const { isLoading, data } = useInvoices(formatDate(startDateState),formatDate(endDateState),10,1,queryParams); 
  const invoicesList: any = data;
  console.log("invoicesListinvoicesList",invoicesList)
  useEffect(()=>{
    if(invoicesList){
      setInvoicesResponseData(invoicesList)
    }
  },[invoicesList])


  const onRefNoClickHandler = (clickedRow: any) => {
    const refNo = clickedRow.refNo
    dispatch(setSelectedINVOICE({refNo:refNo}));
    const clientId = clickedRow.client
    dispatch(setSelectedClient({id:clientId}));
    (async () => {
      try {
        const response: any =
        await AccountantService.getAccountantIdByClient(clientId);
        console.log("responseData-----------------",response);
        dispatch(setSelectedAccountant({id:response.accountantId}));
    } catch (error) {
          console.error("Error fetching data:", error);
        }
    })();
  };
  const onClientClickHandler = (clickedRow: any) => {
    // const refNo = clickedRow.refNo
    // dispatch(setSelectedINVOICE({refNo:refNo}));
    const clientId = clickedRow.client
    dispatch(setSelectedClient({id:clientId}));
    (async () => {
      try {
        const response: any =
        await AccountantService.getAccountantIdByClient(clientId);
        console.log("responseData-----------------",response);
        dispatch(setSelectedAccountant({id:response.accountantId}));
    } catch (error) {
          console.error("Error fetching data:", error);
        }
    })();
  };



  useEffect(() => {
      setIsLoadingInvoiceList(true);
      if(invoicesList){
        const updatedColumnsResponse = invoicesList.headerColumns.map(
          (item: any) => ({
            ...item,
            renderCell:
              item.field === "status" ? (params: any) => params.value : undefined,
          })
        );

        const updatedRows = invoicesList.items.map((row: any) => {

          if (row.status !== false && row.refNo && row.clientName) {
            return {
              ...row,
              status: (
                <span style={{ color: "green" }}>
                  Paid
                </span>
              ),
              refNo: (
                <Link
                  to={"/reports/invoice-detail"}
                  className="no-underline"
                  style={{ color: "blue" }}
                  onClick={() => onRefNoClickHandler(row)}
                >
                  {row.refNo}
                </Link>
              ),
              clientName: (
                <Link
                  to={"/clients/dashboard"}
                  className="no-underline"
                  style={{ color: "blue" }}
                  onClick={() => onClientClickHandler(row)}
                >
                  {row.clientName}
                </Link>
              ),
            };
          } else {
            return {
              ...row,
              status: (
                  <span style={{ color: "red" }}>
                    Not Paid
                  </span>
              ),
              refNo: (
                <Link
                  to={"/reports/invoice-detail"}
                  className="no-underline"
                  style={{ color: "blue" }}
                  onClick={() => onRefNoClickHandler(row)}
                >
                  {row.refNo}
                </Link>
              ),
              clientName: (
                <Link
                  to={"/clients/dashboard"}
                  className="no-underline"
                  style={{ color: "blue" }}
                  onClick={() => onClientClickHandler(row)}
                >
                  {row.clientName}
                </Link>
              )
            };
          }
        });

        console.log("updatedColumnsResponse", updatedColumnsResponse);
        setInvoiceListUpdatedColumns(updatedColumnsResponse);
        setInvoiceListUpdatedItems(updatedRows);
      setIsLoadingInvoiceList(false);
    console.log("invoiceListUpdatedColumns", invoiceListUpdatedColumns);
      }
  }, [invoicesList]);

  const savePdfAsFile = (pdfData: any, filename: any) => {
    const blob = new Blob([pdfData], { type: "application/pdf" });
    FileSaver.saveAs(blob, filename);
    console.log("pdfData",pdfData)
    console.log("blob",blob)
    // fileeeee = pdfData
  };

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

 const invoicesListDataAPICall = (startDate:string,endDate:string,dateAppliesTo:number,status:number,params:IGetListRequest) => {
  const fetchData = async () => {
    setIsLoadingInvoiceList(true);
    setIsLoadingDesiredInvoiceList(true);
    try {
      const response: any = await ReportsService.getInvoiceAdminReport1(startDate,endDate,dateAppliesTo,status,params)
      console.log("responseData-----------------", response);
      setInvoicesResponseData(response)
      const updatedColumnsResponse = response.headerColumns.map(
        (item: any) => ({
          ...item,
          renderCell:
            item.field === "status" ? (params: any) => params.value : undefined,
        })
      );

      const updatedRows = response.items.map((row: any) => {

        if (row.status !== false && row.refNo && row.clientName) {
          return {
            ...row,
            status: (
              <span style={{ color: "green" }}>
                Paid
              </span>
            ),
            refNo: (
              <Link
                to={"/reports/invoice-detail"}
                className="no-underline"
                style={{ color: "blue" }}
                onClick={() => onRefNoClickHandler(row)}
              >
                {row.refNo}
              </Link>
            ),
            clientName: (
              <Link
                to={"/clients/dashboard"}
                className="no-underline"
                style={{ color: "blue" }}
                onClick={() => onClientClickHandler(row)}
              >
                {row.clientName}
              </Link>
            ),
          };
        } else {
          return {
            ...row,
            status: (
                <span style={{ color: "red" }}>
                  Not Paid
                </span>
            ),
            refNo: (
              <Link
                to={"/reports/invoice-detail"}
                className="no-underline"
                style={{ color: "blue" }}
                onClick={() => onRefNoClickHandler(row)}
              >
                {row.refNo}
              </Link>
            ),
            clientName: (
              <Link
                to={"/clients/dashboard"}
                className="no-underline"
                style={{ color: "blue" }}
                onClick={() => onClientClickHandler(row)}
              >
                {row.clientName}
              </Link>
            )
          };
        }
      });

      console.log("updatedColumnsResponse", updatedColumnsResponse);
      // setInvoiceList(response);
      setInvoiceListUpdatedColumns(updatedColumnsResponse);
      setInvoiceListUpdatedItems(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoadingInvoiceList(false);
    setIsLoadingDesiredInvoiceList(false);
  };
  console.log("invoiceListUpdatedColumns", invoiceListUpdatedColumns);
  fetchData();
 };
 const onSubmit = (formData: any) => {
    console.log("formData",formData)
    // const formattedStartDate = formatDate(formData.dateRange[0]);
    // const formattedEndDate = formatDate(formData.dateRange[1]);
    const formattedStartDate = formData.startDate !== null ? formatDate(formData.startDate) : "2024-01-01";
    const formattedEndDate = formData.endDate !== null ? formatDate(formData.endDate) : "2024-03-31";
    console.log("formattedStartDate",formattedStartDate)
    console.log("formattedEndDate",formattedEndDate)

    invoicesListDataAPICall(formattedStartDate,formattedEndDate,formData.dateAppliesTo,formData.status,queryParams)
    //  const fetchData = async () => {
    //   setIsLoadingInvoiceList(true);
    //   setIsLoadingDesiredInvoiceList(true);
    //   try {
    //     const response: any = await ReportsService.getInvoiceAdminReport1(formattedStartDate,formattedEndDate,formData.dateAppliesTo,formData.status,queryParams)
    //     console.log("responseData-----------------", response);
    //     setInvoicesResponseData(response)
    //     const updatedColumnsResponse = response.headerColumns.map(
    //       (item: any) => ({
    //         ...item,
    //         renderCell:
    //           item.field === "status" ? (params: any) => params.value : undefined,
    //       })
    //     );

    //     const updatedRows = response.items.map((row: any) => {

    //       if (row.status !== false && row.refNo && row.clientName) {
    //         return {
    //           ...row,
    //           status: (
    //             <span style={{ color: "green" }}>
    //               Paid
    //             </span>
    //           ),
    //           refNo: (
    //             <Link
    //               to={"/reports/invoice-detail"}
    //               className="no-underline"
    //               style={{ color: "blue" }}
    //               onClick={() => onRefNoClickHandler(row)}
    //             >
    //               {row.refNo}
    //             </Link>
    //           ),
    //           clientName: (
    //             <Link
    //               to={"/clients/dashboard"}
    //               className="no-underline"
    //               style={{ color: "blue" }}
    //               onClick={() => onClientClickHandler(row)}
    //             >
    //               {row.clientName}
    //             </Link>
    //           ),
    //         };
    //       } else {
    //         return {
    //           ...row,
    //           status: (
    //               <span style={{ color: "red" }}>
    //                 Not Paid
    //               </span>
    //           ),
    //           refNo: (
    //             <Link
    //               to={"/reports/invoice-detail"}
    //               className="no-underline"
    //               style={{ color: "blue" }}
    //               onClick={() => onRefNoClickHandler(row)}
    //             >
    //               {row.refNo}
    //             </Link>
    //           ),
    //           clientName: (
    //             <Link
    //               to={"/clients/dashboard"}
    //               className="no-underline"
    //               style={{ color: "blue" }}
    //               onClick={() => onClientClickHandler(row)}
    //             >
    //               {row.clientName}
    //             </Link>
    //           )
    //         };
    //       }
    //     });

    //     console.log("updatedColumnsResponse", updatedColumnsResponse);
    //     // setInvoiceList(response);
    //     setInvoiceListUpdatedColumns(updatedColumnsResponse);
    //     setInvoiceListUpdatedItems(updatedRows);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    //   setIsLoadingInvoiceList(false);
    //   setIsLoadingDesiredInvoiceList(false);
    // };
    // console.log("invoiceListUpdatedColumns", invoiceListUpdatedColumns);
    // fetchData();
  };

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const { isLoading: isDeleting, mutate: deleteInvoiceById } = useMutation(
    ReportsService.delAdminInvoices,
    {
      onSuccess: (res: object | IServerError | IResponse | any) => {
        console.log("res",res)
        if(res && res.status === 200){
          console.log("res inside",queryParams)
          setSnackbarData({
            open: true,
            message: res.data.message,
            severity: "success",
          });
          invoicesListDataAPICall(formattedStartDate,formattedEndDate,dateAppliesTo,paymentStatus,queryParams)
          console.log("res inside",queryParams)
        }else if (typeof res === "object") {
          // remove the cache of the Accountant fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "success",
          });

          return queryClient.invalidateQueries(
            INVOICE_LIST_QUERY_KEY
          );
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
      },
      onError: (err:any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  const deleteInvoiceField = () => {
    if (multiDelete) {
      setMultiDelete(false);
      deleteInvoiceById(delRowsPayload && delRowsPayload);
      setDialogOpen(false);
    }
  };

  const onRowDeleteClicked = useCallback((params) => {
    setDialogOpen(true);
    setMultiDelete(true);
    console.log("paramsparamsparams",params)
    let refNoList:any = []
    params.map((item:any)=>{
      refNoList.push(item.refNo.props.children)
    })
    console.log("paramsparamsparams",refNoList)
    let payload = {
      "refNo": refNoList
    }
    setDelRowsPayload(payload)
    // alert("clicked")
  }, []);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: ADMIN_INVOICE_REPORT_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=delete",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%", "& .p-datepicker":{zIndex:"2001"}}}>
      <BackdropCircularProgress
          open={
            isDeleting || isLoading
          }
          testId="T4 Slip List"
      />
      <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={deleteInvoiceField}
          cancelActionHandler={onCloseHandler}
          testId="invoice-field-tab-confirmation-dialog"
        />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <InvoiceAdminReportHeader isSubmitting={isLoadingDesiredInvoiceList} formMethods={formMethods} />
        </form>
      </FormProvider>
      <PrimeDataGridInvoiceAdminReport
        rows={invoiceListUpdatedItems && invoiceListUpdatedItems}
        columns={
          invoicesResponseData && invoiceListUpdatedColumns
            ? invoiceListUpdatedColumns
            : []
        }
        showIndexColumn={true}
        showChecboxColumn={true}
        showSettingColumn={false}
        onRowDeleteClicked={onRowDeleteClicked}
        isLoading={isLoadingInvoiceList}
        onSnackbarMessageData={(value: any) => setSnackbarData(value)}
        totalRecords={invoicesResponseData && invoicesResponseData.total}
        initialPageSize={INITIAL_PAGE_SIZE}
        onRequestParamsChange={onRequestParamsChange}
      />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`invoice-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
