import React, { useEffect, useState } from "react";

import { Checkbox, Grid, InputLabel, Typography } from "@mui/material";
import { RHookFormTextField } from "./RHookFormTextField";
import { selectedPOE } from "components/T4Slip/T4SlipSetup/Panels";
import { checkboxDefaultSelect } from "components/T4Slip";
import { RHookFormNumericFormatTextField } from "./RHookFormNumericFormatTextField";
import { EnhancedDropDownRHFWithLabel, LabelPosition } from "@websential/cosmic";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
// import { RHookFormTextField } from "@websential/cosmic";

export interface ITaxSlipDataInput {
  id: string;
  label: string;
  boxNumber: string;
  showAdjustedInput?: boolean;
  onChange?: any;
  value?: string | number | any;
  checkBox?: boolean;
  tag?: string;
  readOnly?: boolean;
  defaultValue?: string;
  globalVariablesData?: any;
  formMethods?: any;
  ddlField?: boolean;
  requiredDDL?: any;
  ddlFieldItems?: any;
  textfield?: any;
}

export const TaxSlipDataInput: React.FC<ITaxSlipDataInput> = ({
  id,
  label,
  boxNumber,
  showAdjustedInput = true,
  onChange,
  value,
  checkBox,
  tag,
  readOnly,
  defaultValue,
  globalVariablesData,
  formMethods,
  ddlField,
  requiredDDL,
  ddlFieldItems,
  textfield
}) => {
  // console.log("idididididididid",id)
  const [readOnlyy, setReadOnly] = useState(readOnly);
  const [checkbox, setCheckbox] = useState(false);
  console.log("checkboxDefaultSelect",checkboxDefaultSelect)
  console.log("checkbox",checkbox)
  const taxYear = useSelector((state : IAppState) => state.taxYear.year)

  const onSelectionChangeHandler = (value: boolean, tag?:string) => {
    // alert(value)
    // alert(tag)
    // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeee",e)
    const cppQppExempt = formMethods.getValues('cppQppExempt')
    const eiExempt = formMethods.getValues('eiExempt')
    setCheckbox(value)
    if(tag === "ei"){
      if (value === true) {
        setReadOnly(false);
      }
    if (value === false) {
      const employmentIncome = formMethods && formMethods.getValues('employmentIncome')
    //   console.log("employmentIncome",employmentIncome)
      if (selectedPOE !== "" || "Quebec") {
    //     if(cppQppExempt !== true){
    //     formMethods.setValue('cppQppPensionableEarnings',Math.min(Number(employmentIncome), globalVariablesData && globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum).toString())
    //   } else {
    //     formMethods.setValue('cppQppPensionableEarnings',"0.00")
    //   }
      if(eiExempt !== true){
        formMethods.setValue('eiInsurableEarnings',parseFloat(Math.min(parseFloat(employmentIncome.replace(/[$,]/g, '')), globalVariablesData && globalVariablesData?.data?.t4?.eiSettingsTotalEiEarningsMaximum).toString()).toFixed(2))
      } else {
        formMethods.setValue('eiInsurableEarnings',"0.00")
      }
      }
      if (selectedPOE === "Quebec") {
    //     if(cppQppExempt !== true){
    //     formMethods.setValue('cppQppPensionableEarnings',Math.min(Number(employmentIncome), globalVariablesData && globalVariablesData?.data?.rl1?.qppSettingsTotalQppPensionableEarningsMaximum).toString())
    //   } else {
    //     formMethods.setValue('cppQppPensionableEarnings',"0.00")
    //   }
      if(eiExempt !== true){
        formMethods.setValue('eiInsurableEarnings',parseFloat(Math.min(parseFloat(employmentIncome.replace(/[$,]/g, '')), globalVariablesData && globalVariablesData?.data?.rl1?.eiSettingsQubecTotalEiEarningsMaximum).toString()).toFixed(2))
      }else {
        formMethods.setValue('eiInsurableEarnings',"0.00")
      }
      }
      setReadOnly(true);
    }
    } else if(tag === "cpp"){
      if (value === true) {
        setReadOnly(false);
      }
      if (value === false) {
        const employmentIncome = formMethods && formMethods.getValues('employmentIncome')
        if (selectedPOE !== "" || "Quebec") {
          if(cppQppExempt !== true){
            console.log("employmentIncome",parseFloat(employmentIncome.replace(/[$,]/g, '')))
            console.log("employmentIncome",globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum)
            console.log('Math.min(Number(employmentIncome), globalVariablesData && globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum).toString()',parseFloat(Math.min(parseFloat(employmentIncome.replace(/[$,]/g, '')), globalVariablesData && globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum).toString()).toFixed(2))
            if(taxYear > 2023){
              formMethods.setValue('cppQppPensionableEarnings',parseFloat(Math.min(parseFloat(employmentIncome.replace(/[$,]/g, '')), globalVariablesData && globalVariablesData?.data?.t4?.cppSettingsTotalSecondCppPensionableEarningsMaximum).toString()).toFixed(2))
            } else {
              formMethods.setValue('cppQppPensionableEarnings',parseFloat(Math.min(parseFloat(employmentIncome.replace(/[$,]/g, '')), globalVariablesData && globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum).toString()).toFixed(2))
            }
        } else {
          console.log("Math.min(Number(employmentIncome), globalVariablesData && globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum).toString() else")
          formMethods.setValue('cppQppPensionableEarnings',"0.00")
        }
      //   if(eiExempt !== true){
      //     formMethods.setValue('eiInsurableEarnings',Math.min(Number(employmentIncome), globalVariablesData && globalVariablesData?.data?.t4?.eiSettingsTotalEiEarningsMaximum).toString())
      //   } else {
      //     formMethods.setValue('eiInsurableEarnings',"0.00")
      //   }
        }
        if (selectedPOE === "Quebec") {
          if(cppQppExempt !== true){
          formMethods.setValue('cppQppPensionableEarnings',parseFloat(Math.min(parseFloat(employmentIncome.replace(/[$,]/g, '')), globalVariablesData && globalVariablesData?.data?.rl1?.qppSettingsTotalQppPensionableEarningsMaximum).toString()).toFixed(2))
        } else {
          formMethods.setValue('cppQppPensionableEarnings',"0.00")
        }
      //   if(eiExempt !== true){
      //     formMethods.setValue('eiInsurableEarnings',Math.min(Number(employmentIncome), globalVariablesData && globalVariablesData?.data?.rl1?.eiSettingsQubecTotalEiEarningsMaximum).toString())
      //   }else {
      //     formMethods.setValue('eiInsurableEarnings',"0.00")
      //   }
        }
        setReadOnly(true);
      }
    }
  };
  useEffect(()=>{
    if(checkboxDefaultSelect === true){
      setCheckbox(checkboxDefaultSelect)
    }
  },[checkboxDefaultSelect])
  return (
    <Grid container columnSpacing={2} xs={12} sm={12} md={12} lg={12} xl={12}>
      {ddlField ? 
      <><Grid item xs={12} sm={12} md={12} lg={12} xl={12} 
      sx={{
        // paddingLeft:"16px",
        "& .MuiGrid-grid-xs-7":{paddingLeft:"48px"}
      }}
      >
      <EnhancedDropDownRHFWithLabel
        selectName={id}
        label={label}
        labelPosition={LabelPosition.Left}
        testId={`consolidated-indicator-list`}
        items={ddlFieldItems ? ddlFieldItems : []}
        labelColumns={5}
        fieldColumns={7}
        required={requiredDDL}
      />
    </Grid></>:
      <><Grid
        item
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
        sx={{ textAlign: "left", paddingTop: 2 }}
      >
        <InputLabel sx={{ display: "flex" }}>
          <Typography variant="subtitle2">{label}</Typography>
          {checkBox === true ? (
            <Checkbox
              sx={{ p: 0, ml: "5px" }}
              onChange={(e:any) => onSelectionChangeHandler(e.target.checked,tag)}
              checked={checkbox}
            />
          ) : undefined}
        </InputLabel>
      </Grid>

      <Grid
        item
        xs={1}
        sm={1}
        md={1}
        lg={1}
        xl={1}
        sx={{ textAlign: "right", paddingTop: 2 }}
      >
        <InputLabel>
          <Typography variant="subtitle2">{boxNumber}</Typography>
        </InputLabel>
      </Grid>

      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        {/* <RHookFormTextField id={`${id}_actual`} testId={`${id}-1`} /> */}
        {textfield ? 
        <RHookFormTextField
          id={id}
          testId={`${id}`}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          readOnly={readOnly !== false ? readOnlyy : readOnly}
          textAlign={'end'}
        /> : 
        <RHookFormNumericFormatTextField
          id={id}
          testId={`${id}`}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          readOnly={readOnly !== false ? readOnlyy : readOnly}
          textAlign={'end'}
        />
        }
      </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        {showAdjustedInput && (
          <RHookFormNumericFormatTextField
            // id={`${id}_adjusted`}
            id={`${id}Adjusted`}
            testId={`${id}Adjusted`}
            readOnly
            defaultValue="0.00"
            textAlign={'end'}
          />
        )}
      </Grid></>}
    </Grid>
  );
};
