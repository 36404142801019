import { T4SlipSetup } from "components/T4Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT4SlipSetupPage {}

export const T4SlipSetupPage: FC<IT4SlipSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Create T4 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t4/list" },
        { text: "T4", navigateTo: "/taxslip/t4/list" },
        { text: "Create T4 Slip", navigateTo: "/taxslip/t4/setup" },
      ]}
      mainContents={<T4SlipSetup testId="t4-slip-setup" />}
    />
  );
};
