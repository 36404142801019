import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { DefaultValues, FieldValue, FormProvider, Mode, useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, useTheme } from "@mui/material";
import Grid, { GridSize } from "@mui/material/Grid";
import { FieldType,
	RHookFormTextFieldWithLabel,
	BusinessNumberRHF,
	EmailFieldRHF,
	URLFieldRHF,
	EnhancedCheckboxRHF,
	EnhancedTextAreaFieldRHF,
	RHookFormPasswordFieldWithLabel,
	RHookFormNumberFieldWithLabel,
	// SinNumberRHF,
	EnhancedAndroidSwitchRHF,
	EnhancedIOSSwitchRHF,
	EnhancedBasicSwitchRHF,
	EnhancedMultiDropDownRHFWithLabel,
	EnhancedDropDownRHFWithLabel,
	RHookFormMaskedTextFieldWithLabel,
	EnhancedRadioGroupRHFWithLabel,
RHookFormDatePicker, 
ITest,
TwoColumnLayout,
StandardPanel,
FieldLayout,
LabelPosition,
SingleColumnLayout,
DataType,
ReducedElAccountNumberRHF,
ReducedElAccountNumber,
// PostCodeRHF,
ITabLayout} from "@websential/cosmic";
import { IFlexFieldsPanelLayout } from "@websential/cosmic/build/components/RHookFormGenerator/types/IFlexFieldsPanelLayout";
import SubmittedData from "@websential/cosmic/build/components/RHookFormGenerator/types/SubmittedData";
import Values from "@websential/cosmic/build/components/RHookFormGenerator/types/Values";
import { getFieldValidationSchema } from "./validator";
import { PostCodeRHF } from "components/Resource/ResourceSetup/CommonFieldsUseInForm/PostCodeRHF";
import { SinNumberRHF } from "components/Resource/ResourceSetup/CommonFieldsUseInForm/SinNumberRHF";
export enum OnChangeActionType {
    Filter = 'filter',
    Hide = 'hide',
    ReadOnly = 'read_only',
}
interface Props extends ITest {
	fieldType: string;
  }
export const ComponentNotFound: React.FC<Props> = ({ fieldType, testId }) => {
	return (
	  <span data-testid={`${testId}-${fieldType}-not-implemented`}>
		{fieldType} is not implemented!
	  </span>
	);
  };
  

interface IProps extends ITest {
	fieldIndex: number;
	fieldColumn: GridSize;
	sequence: number;
	filterBy: string;
	readOnly: boolean;
	children: React.ReactNode;
  }
  
  export const FormFieldGrid: React.FC<IProps> = ({
	fieldIndex,
	fieldColumn,
	sequence,
	children,
	testId,
  }) => {
	return (
	  <Grid
		key={fieldIndex}
		item
		xl={fieldColumn}
		lg={fieldColumn}
		xs={fieldColumn}
		sm={fieldColumn}
		md={fieldColumn}
		data-testid={`${testId}-panel-${sequence}-field-grid-${fieldIndex}`}
	  >
		{children}
	  </Grid>
	);
  };
  
//   export default memo(FormFieldGrid, (prevProps:any, nextProps:any) => {
// 	return (
// 	  prevProps.filterBy === nextProps.filterBy &&
// 	  prevProps.readOnly === nextProps.readOnly
// 	);
//   });
  
export const FIELD_TYPE_COMPONENT:any = {
	[FieldType.TextField]: RHookFormTextFieldWithLabel,
	[FieldType.BusinessNumber]: BusinessNumberRHF,
	[FieldType.Email]: EmailFieldRHF,
	[FieldType.URL]: URLFieldRHF,
	[FieldType.Checkbox]: EnhancedCheckboxRHF,
	[FieldType.TextArea]: EnhancedTextAreaFieldRHF,
	[FieldType.PasswordTextField]: RHookFormPasswordFieldWithLabel,
	[FieldType.NumberTextField]: RHookFormNumberFieldWithLabel,
	[FieldType.SINNumber]: SinNumberRHF,
	[FieldType.ReducedElAccountNumber]: ReducedElAccountNumberRHF,
	[FieldType.PostCode]: PostCodeRHF,
	// [FieldType.PayPeriodDropDownList]: PayPeriodDDLRHF,
	// [FieldType.StatusDropDownList]: StatusDDLRHF,
	// [FieldType.CountryDropDownList]: CountryDDLRHF,
	// [FieldType.ProvinceDropDownList]: ProvinceDDLRHF,
	// [FieldType.EmploymentCodeDropDownList]: EmploymentCodeDDLRHF,
	// [FieldType.EmploymentTypeDropDownList]: EmploymentTypeDDLRHF,
	// [FieldType.EmployeeRangeDropDownList]: EmployeeRangeDDLRHF,
	// [FieldType.YesNoDropDownList]: YesNoDDLRHF,
	[FieldType.AndroidSwitch]: EnhancedAndroidSwitchRHF,
	[FieldType.IOSSwitch]: EnhancedIOSSwitchRHF,
	[FieldType.NormalSwitch]: EnhancedBasicSwitchRHF,
	[FieldType.MultiSelectionDropDownList]: EnhancedMultiDropDownRHFWithLabel,
	[FieldType.DropDownList]: EnhancedDropDownRHFWithLabel,
	[FieldType.MaskedTextField]: RHookFormMaskedTextFieldWithLabel,
	[FieldType.RadioGroup]: EnhancedRadioGroupRHFWithLabel,
	[FieldType.Date]: RHookFormDatePicker,
};
export const getFieldName = (fieldId: string, id: number, isFixed: boolean): string => `${fieldId}-${id}${isFixed ? '-fixed' : ''}`;
export const getMultiSelectionDDLDefaultValue = (defaultValue: string): string[] => defaultValue.split(',');
export const getFieldItems = (fieldValue: any, fieldInternalValue: any): { value: string; label: string; }[] => {
	console.log("fieldInternalValue",fieldInternalValue)
    let fieldInternalValueArray = typeof(fieldInternalValue) === 'string' ? (fieldInternalValue as string).split(',') : typeof(fieldInternalValue) === 'object' ? fieldInternalValue : null;

    let data = (fieldValue as string).split(',').map((item, index) => (
        { value: typeof(fieldInternalValue) === 'string' ? fieldInternalValueArray[index].trim() :
                typeof(fieldInternalValue) === 'object' ? fieldInternalValueArray[index] : 
                fieldInternalValueArray[index].trim(), 
          label: item.trim()
        }))

    // return (fieldValue as string).split(',').map((item, index) => ({ value: fieldInternalValueArray[index].trim(), label: item.trim() }))
    return data
};
export interface IFlexFieldsFormPanelLayoutProps extends ITest {
	/**
	 * The schema of the form fetched from BE. This should be memoized and its reference doesn't change.
	 */
	formLayout: ITabLayout[];
	/**
	 * A callback for when the form is submitted.
	 */
	onSubmit: (data: SubmittedData) => void;
	/**
	 * The form validation's mode.
	 */
	validationMode?: Mode;
	/**
	 * The form re-validation's mode.
	 */
	revalidateMode?: Exclude<Mode, "onTouched" | "all">;
	/**
	 * Whether we should show a divider between 2 panels or not.
	 */
	showDivider?: boolean;

	toolbar?:any;

	validationSchema?:any;

	formDefaultValues?:any;
}

export const FlexFieldsFormPanelLayout: React.FC<IFlexFieldsFormPanelLayoutProps> =
	({
		formLayout,
		testId,
		onSubmit,
		validationMode = "onBlur",
		revalidateMode = "onChange",
		toolbar,
		validationSchema,
		formDefaultValues
	}) => {
console.log("Cosmic FlexFieldsFormPanelLayout => Form Layout", formLayout);
const theme = useTheme();
const hiddenSubmitRef = useRef<HTMLInputElement>(null);
const [totalPanels, setTotalPanels] = useState<any>(undefined);
const [evenFormLayout, setEvenFormLayout] = useState<any>(undefined);
const [oddFormLayout, setOddFormLayout] = useState<any>(undefined);
const fieldValuesConversionData = useRef<
	any
>({});
const [currentDestFieldsOnChangeRules, setCurrentDestFieldsOnChangeRules] =
	useState<any>({});

	const fieldsOnChangeValueRules = useMemo<
	Record<string,any>
>(() => {
	const map:any = {};
	formLayout && formLayout.forEach((tabLayout) => {
		tabLayout?.panels.forEach((panel:any) => {
			panel?.fields.forEach((panelField:any) => {
				const { fieldId, id, isFixed, onChange } = panelField;
				const fieldName = getFieldName(fieldId, id, isFixed);
				// if the current field has onChange rules that would affect other fields.
				if (onChange) {
					map[fieldName] = onChange;
				}
			});
		});
	});
	return map;
}, [formLayout]);

console.log("formLayout ind1",formLayout)

// const calculateDefaultValues = useMemo<DefaultValues<Values>>(() => {
//     return () => {
//       const fieldsDefaultValues:any = {};
//       formLayout && formLayout.forEach((tabLayout) => {
//         tabLayout?.panels.forEach((panel) => {
//           panel?.fields.forEach((panelField) => {
//             const fieldName = getFieldName(panelField.fieldId, panelField.id, panelField.isFixed);
//             const fieldDefaultValue = panelField.defaultValue;
//             if (fieldDefaultValue) {
//               fieldsDefaultValues[fieldName] = fieldDefaultValue;
//             }
//           });
//         });
//       });
//       return fieldsDefaultValues;
//     };
//   }, [formLayout, getFieldName]);

//   const defaultValues = calculateDefaultValues();

// const defaultValues = useMemo(() => {
//     const fieldsDefaultValues:any = {};
//     formLayout && formLayout.forEach((tabLayout) => {
//       tabLayout?.panels.forEach((panel) => {
//         panel?.fields.forEach((panelField) => {
//           const fieldName = getFieldName(panelField.fieldId, panelField.id, panelField.isFixed);
//           const fieldDefaultValue = panelField.defaultValue;
//           if (fieldDefaultValue != null) { // Check if fieldDefaultValue is not null or undefined
//             fieldsDefaultValues[fieldName] = fieldDefaultValue;
//           }
//         });
//       });
//     });
//     return fieldsDefaultValues;
//   }, [formLayout, getFieldName]);


// const [defaultValues, setDefaultValues] = useState<any>({});

// const calculateDefaultValues = useCallback(() => {
//     const fieldsDefaultValues:any = {};
//     formLayout && formLayout.forEach((tabLayout) => {
//       tabLayout?.panels.forEach((panel) => {
//         panel?.fields.forEach((panelField) => {
//           const fieldName = getFieldName(panelField.fieldId, panelField.id, panelField.isFixed);
//           const fieldDefaultValue = panelField.defaultValue;
//           if (fieldDefaultValue) {
//             fieldsDefaultValues[fieldName] = fieldDefaultValue;
//           }
//         });
//       });
//     });
//     setDefaultValues(fieldsDefaultValues);
//   }, [formLayout, getFieldName]);

//   useEffect(() => {
//     calculateDefaultValues(); // Calculate default values when dependencies change
//   }, [calculateDefaultValues]);

  
const flexFieldsDefaultValues = useMemo<DefaultValues<Values>>(() => {
	const fieldsDefaultValues:any = {};
	console.log("formLayout ind",formLayout)
	formLayout && formLayout.forEach((tabLayout) => {
		tabLayout?.panels.forEach((panel:any) => {
			panel?.fields.forEach((panelField:any) => {
				const FieldComponent:any = FIELD_TYPE_COMPONENT[panelField.fieldType];
				if (FieldComponent) {
					const { fieldId, id, isFixed, defaultValue, fieldType } =
					panelField;
					const fieldName = getFieldName(fieldId, id, isFixed);
					let fieldDefaultValue = defaultValue;
					console.log("formLayout indif",panelField)
					console.log("panelField.defaultValue",panelField.defaultValue)
					console.log(
						`Cosmic FlexFieldsFormPanelLayout => Default Values Setting, fieldName: ${fieldName}, Default Value: ${fieldDefaultValue}, Field Type: ${fieldType}`
					);
					if (fieldType === FieldType.MultiSelectionDropDownList) {
						if (defaultValue) {
							fieldDefaultValue = getMultiSelectionDDLDefaultValue(
								defaultValue as string
							);
						}
					}
					// if the field has a default value
					if (fieldDefaultValue) {
						fieldsDefaultValues[fieldName] = fieldDefaultValue;
					}
				}
			});
		});
	});
	return fieldsDefaultValues;
}, [formLayout, getFieldName]);

const defaultValues = formDefaultValues ? formDefaultValues : flexFieldsDefaultValues
console.log("defaultValuesw",defaultValues)



const schema = useMemo(() => {
	const schemaAdditions:any = {};
	formLayout && formLayout.forEach((tabLayout) => {
		// 	console.log("tabLayouttabLayout", tabLayout);
		tabLayout?.panels.forEach((panel:any) => {
			console.log("panel", panel);
			panel?.fields.forEach((panelField:any) => {
				console.log("panelField", panelField);
				const FieldComponent:any = FIELD_TYPE_COMPONENT[panelField.fieldType];
				const { fieldId, id, isFixed } = panelField;
				const fieldName:any = getFieldName(fieldId, id, isFixed);
				console.log("fieldName", fieldName);
				// if we are meant to hide this field, we shouldn't add its schema
				if (
					currentDestFieldsOnChangeRules[fieldName] &&
					currentDestFieldsOnChangeRules[fieldName].type ===
						OnChangeActionType.Hide
				) {
					return;
				}
				if (FieldComponent) {
					const fieldValidationSchema = validationSchema ? validationSchema(panelField) : getFieldValidationSchema(panelField);
					// const fieldValidationSchema = validationSchema && validationSchema(panelField);
					console.log("fieldValidationSchema", fieldValidationSchema);
					// if the field has a validation scheme
					if (fieldValidationSchema) {
						schemaAdditions[fieldName] = fieldValidationSchema;
						// console.log("schemaAdditions[fieldName]",schemaAdditions[fieldName])
					}
				}
			});
		});
	});
	console.log("schemaAdditions", schemaAdditions);
	return schemaAdditions;
}, [formLayout, currentDestFieldsOnChangeRules]);

console.log(
	"Cosmic FlexFieldsFormPanelLayout => defaultValues",
	defaultValues
);
const formMethods = useForm({
	mode: validationMode,
	reValidateMode: revalidateMode,
	resolver: yupResolver(yup.object().shape(schema)),
	defaultValues,
});
// apply initialValues rules if any
useEffect(() => {
	// if some fields have defaultValue set
	if (Object.keys(defaultValues).length) {
		const newCurrentOnChangeRules:any = {};
		Object.keys(defaultValues).forEach((initialValueFieldName) => {
			const fieldValue:any = defaultValues[initialValueFieldName];
			const fieldOnChangeItems:any =
				fieldsOnChangeValueRules[initialValueFieldName];
			// if the initial value affects other fields
			if (fieldOnChangeItems && fieldOnChangeItems[fieldValue]) {
				fieldOnChangeItems[fieldValue].forEach((onChangeItem:any) => {
					const targetFieldName:any = Object.keys(onChangeItem)[0];
					const targetFieldActionType = onChangeItem[targetFieldName];
					// /**
					//  * we need to reset the target field's value in-case we have
					//  * a cached selected/pre-selected value that no longer can apply
					//  * e.g.
					//  * 1. a selected value for a field that is about to be hidden
					//  * 2. a selected value of an un-filtered dropdown that once filtered
					//  *    will not be an option e.g. the user selects NY as a province then
					//  *    the country selected is Canada.
					//  */
					formMethods.setValue(targetFieldName, undefined);
					newCurrentOnChangeRules[targetFieldName] = {
						sourceField: initialValueFieldName,
						destinationField: targetFieldName,
						sourceFieldValue: fieldValue as string,
						type: targetFieldActionType,
					};
				});
			}
		});
		setCurrentDestFieldsOnChangeRules(newCurrentOnChangeRules);
	}
}, [defaultValues, fieldsOnChangeValueRules, formMethods,formLayout]);


useEffect(() => {
	const subscription = formMethods.watch(
		(fieldValues, { name: fieldName }) => {
			const fieldOnChangeItems =
				fieldsOnChangeValueRules[fieldName as string];
			// if this is meant to be a watched field since it expects at least one of its values to change the behavior of at least 1 other field
			if (fieldOnChangeItems) {
				const fieldValue:any = fieldValues[fieldName as string];
				// check if the newly selected value is mapped in onChange map of the current field
				if (fieldOnChangeItems[fieldValue]) {
					const newCurrentOnChangeRules:any = {};
					fieldOnChangeItems[fieldValue].forEach((onChangeItem:any) => {
						const targetFieldName:any = Object.keys(onChangeItem)[0];
						const targetFieldActionType = onChangeItem[targetFieldName];
						/**
						 * we need to reset the target field's value in-case we have
						 * a cached selected/pre-selected value that no longer can apply
						 * e.g.
						 * 1. a selected value for a field that is about to be hidden
						 * 2. a selected value of an un-filtered dropdown that once filtered
						 *    will not be an option e.g. the user selects NY as a province then
						 *    the country selected is Canada.
						 */
						formMethods.setValue(targetFieldName, undefined);
						newCurrentOnChangeRules[targetFieldName] = {
							sourceField: fieldName,
							destinationField: targetFieldName,
							sourceFieldValue: fieldValue as string,
							type: targetFieldActionType,
						};
					});
					setCurrentDestFieldsOnChangeRules((prevCurrentOnChangeRules:any) => {
						// 1. get any onChange rule that belongs to the current field
						const currentFieldOnChangeRulesMap = Object.values(
							prevCurrentOnChangeRules
						).filter(
							(onChangeRule:any) => onChangeRule.sourceField === fieldName
						);
						// 2. delete them from the list of rules to make sure only new rules will be applied
						currentFieldOnChangeRulesMap.forEach(
							(currentFieldOnChangeRule:any) => {
								delete prevCurrentOnChangeRules[
									currentFieldOnChangeRule.destinationField
								];
							}
						);
						return {
							...prevCurrentOnChangeRules,
							...newCurrentOnChangeRules,
						};
					});
				} else {
					// remove any rules of the current field because the user selected a normal value that doesn't have a rule
					setCurrentDestFieldsOnChangeRules((prevCurrentOnChangeRules:any) => {
						// 1. get any onChange rule that belongs to the current field
						const currentFieldOnChangeRulesMap = Object.values(
							prevCurrentOnChangeRules
						).filter(
							(onChangeRule:any) => onChangeRule.sourceField === fieldName
						);
						// 2. delete them from the list of rules to make sure only new rules will be applied
						currentFieldOnChangeRulesMap.forEach(
							(currentFieldOnChangeRule:any) => {
								delete prevCurrentOnChangeRules[
									currentFieldOnChangeRule.destinationField
								];
							}
						);
						return { ...prevCurrentOnChangeRules };
					});
				}
			}
		}
	);
	return () => subscription.unsubscribe();
}, [fieldsOnChangeValueRules, formMethods]);

const onSubmitClicked = useCallback(() => {
	hiddenSubmitRef.current?.click();
}, []);

// const sortedFormLayout = useMemo(
// 	() => sortFormLayout(formLayout),
// 	[formLayout]
// );
useEffect(() => {
	// formMethods.setValue("firstName-1-fixed", "23450-");
	// console.log("1234788", formMethods.getValues());
	formLayout && formLayout.forEach((tabLayout) => {
		console.log("tabLayout",tabLayout)
	const allTabsPanels = formLayout.reduce(
		(acc: any, layout: any) => acc.concat(layout.panels),
		[]
		);
		setTotalPanels(allTabsPanels)
		console.log("allTabsPanels",allTabsPanels)
	if (formLayout && allTabsPanels &&  allTabsPanels.length > 1) {
		const even = allTabsPanels && allTabsPanels.filter((item:any, index:any) => index % 2 === 0);
		const odd = allTabsPanels && allTabsPanels.filter((item:any, index:any) => index % 2 !== 0);
		
		setEvenFormLayout(even);
		setOddFormLayout(odd);
	}
})
if(formLayout === undefined){
	setEvenFormLayout(undefined);
	setOddFormLayout(undefined);
}
}, [formLayout]);
console.log("oddFormLayout", oddFormLayout);
console.log("evenFormLayout", evenFormLayout);


return (
	<FormProvider {...formMethods}>
		<form
			data-testid={`${testId}-form`}
			onSubmit={formMethods.handleSubmit((data: SubmittedData) =>
				onSubmit(
					data
					// convertSubmittedData(data, fieldValuesConversionData.current)
				)
			)}
		>
			{formLayout && totalPanels &&  totalPanels.length > 1 ? (
				<>
					<TwoColumnLayout
						leftColumnContent={
							evenFormLayout &&
							evenFormLayout.map((panel:any, panelIndex:any) => {
								// const { panels } = tabLayout;
								
								console.log("panelllll",panel)
								const { gridColumns, label, fields, sequence } =
											panel;
								function stringToDate(arg0: string, fieldFormat: any) {
									throw new Error("Function not implemented.");
								}

								function dateIsAfterToday(maxDate: never) {
									throw new Error("Function not implemented.");
								}

								return(
									// panels.map((panel, panelIndex) => {
											// return (
												<StandardPanel title={label} testId={`${label}-panel`} topPadding={2}>
													<Grid
														container
														sx={{ display: "grid", placeItems: "center" }}
													>
													<Grid
														key={sequence}
														item
														xl={gridColumns}
														lg={gridColumns}
														xs={gridColumns}
														sm={gridColumns}
														md={gridColumns}
														data-testid={`${testId}-panel-${panelIndex}-grid`}
														sx={{ marginTop: theme.spacing(2), minWidth:fields && fields.length === 1 ? "70%" : undefined }}
													>
														<Grid
															container
															direction="row"
															justifyContent="flex-start"
															alignItems="flex-start"
															spacing={2}
															sx={{"& .MuiGrid-item":{paddingTop:0}}}
														>
															{fields.map((panelField:any, fieldIndex:any) => {
																const {
																	fieldId,
																	id,
																	isFixed,
																	fieldLabel,
																	placeholder,
																	isRequired,
																	fieldValue,
																	fieldInternalValue,
																	fieldType,
																	uppercase,
																	maxValue,
																	minValue,
																	fieldFormat,
																	labelPosition,
																	labelColumn,
																	fieldColumn,
																	fullFieldColumns,
																	readOnly,
																	onChange
																} = panelField;
																const FieldComponent =
																	FIELD_TYPE_COMPONENT[fieldType];
																// if the field component has not been implemented yet
																let fieldComponent: JSX.Element;
																if (!FieldComponent) {
																	return (
																		<FieldLayout
																			label={fieldLabel}
																			isRequired={isRequired}
																			testId={testId}
																			labelPosition={
																				labelPosition as LabelPosition
																			}
																			labelColumns={labelColumn}
																			fieldColumns={fieldColumn}
																		>
																			<ComponentNotFound
																				key={fieldIndex}
																				fieldType={fieldType}
																				testId={testId}
																			/>
																		</FieldLayout>
																	);
																}
																const fieldTestId = `${testId}-${fieldIndex}-${fieldId}`;
																console.log("onChangeonChange",onChange)
																const fieldName = getFieldName(
																	fieldId,
																	id,
																	isFixed
																);
																let defaultValue =
																	defaultValues[fieldName];
																	console.log("defaultValue123",defaultValue)
																let onChangeFields = { filterBy: "" };
																let setReadOnly = false;
																if (
																	currentDestFieldsOnChangeRules[
																		fieldName
																	]
																) {
																	const actionType =
																		currentDestFieldsOnChangeRules[
																			fieldName
																		].type;
																	// we should not show the field at all
																	if (
																		actionType === OnChangeActionType.Hide
																	) {
																		return <></>;
																	} else if (
																		actionType ===
																		OnChangeActionType.Filter
																	) {
																		onChangeFields = {
																			...onChangeFields,
																			filterBy:
																				currentDestFieldsOnChangeRules[
																					fieldName
																				].sourceFieldValue,
																		};
																	} else if (
																		actionType ===
																		OnChangeActionType.ReadOnly
																	) {
																		setReadOnly = true;
																	}
																}
																// common props between all the components representing form fields
																const componentsCommonProps = {
																	id: fieldId,
																	label: fieldLabel,
																	required: !!isRequired,
																	labelPosition: labelPosition,
																	labelColumns: labelColumn,
																	fieldColumns: fieldColumn,
																	onChange:onChange,
																	defaultValue:defaultValue
																};
																if (fieldType === FieldType.Checkbox) {
																	fieldComponent = (
																		<FieldComponent
																			{...componentsCommonProps}
																			name={fieldName}
																			checked={!!defaultValue}
																			testId={fieldTestId}
																			readOnly={setReadOnly}
																			checkboxLabel={fieldLabel}
																			onSelectionChangeHandler={onChange}
																		/>
																	);
																} else if (
																	fieldType === FieldType.TextArea
																) {
																	fieldComponent = (
																		<FieldComponent
																			{...componentsCommonProps}
																			id={fieldName}
																			placeholder={placeholder}
																			uppercase={!!uppercase}
																			{...(fieldValue && {
																				value: fieldValue,
																			})}
																			{...(maxValue && {
																				maxRows: maxValue,
																			})} // use maxValue as an indication of the max rows for now
																			testId={fieldTestId}
																		/>
																	);
																} else if (
																	fieldType === FieldType.MaskedTextField
																) {
																	fieldComponent = (
																		<FieldComponent
																			{...componentsCommonProps}
																			id={fieldName}
																			placeholder={placeholder}
																			{...(defaultValue && {
																				defaultValue: defaultValue,
																			})}
																			{...(fieldFormat && {
																				inputMaskPattern: fieldFormat,
																			})}
																			testId={fieldTestId}
																			readOnly={setReadOnly}
																			// TODO: add regex validation pattern when it's integrated and ready.
																		/>
																	);
																} else if (
																	fieldType ===
																	FieldType.MultiSelectionDropDownList
																) {
																	fieldComponent = (
																		<FieldComponent
																			{...onChangeFields}
																			label={fieldLabel}
																			required={!!isRequired}
																			selectName={fieldName}
																			placeholder={placeholder}
																			{...(fieldValue && {
																				items: getFieldItems(
																					fieldValue,
																					fieldInternalValue
																				),
																			})}
																			{...(defaultValue && {
																				selectedItems: defaultValue,
																			})}
																			testId={fieldTestId}
													
																			labelPosition={labelPosition}
																		/>
																	);
																} else if (
																	fieldType === FieldType.DropDownList
																) {
																	fieldComponent = (
																		<FieldComponent
																			{...onChangeFields}
																			label={fieldLabel}
																			required={!!isRequired}
																			selectName={fieldName}
																			// selectedItem={}
																			placeholder={placeholder}
																			showEmpty={false} // only show empty when it's not required
																			{...(fieldValue && {
																				items: getFieldItems(
																					fieldValue,
																					fieldInternalValue
																				),
																			})}
																			{...(defaultValue && {
																				selectedItem: defaultValue,
																			})}
																			isDisabled={readOnly || setReadOnly}
																			isSearchable={false}
																			testId={fieldTestId}
																			labelPosition={labelPosition}
																			fieldColumns={fieldColumn}
																			labelColumns={labelColumn}
																			onSelectionChangeHandler={onChange}
																		/>
																	);
																} else if (fieldType === FieldType.Date) {
																	const minDate:any = minValue
																		? stringToDate(
																				String(minValue),
																				fieldFormat
																			)
																		: null;
																	const maxDate:any = maxValue
																		? stringToDate(
																				String(maxValue),
																				fieldFormat
																			)
																		: null;
																	let shouldDisableFutureDates = true;
																	if (maxDate) {
																		// shouldDisableFutureDates =
																		// 	!dateIsAfterToday(maxDate);
																	}
																	// indicate that when the current field is submitted, we need to convert it from date back to string
																	fieldValuesConversionData.current = {
																		...fieldValuesConversionData.current,
																		[fieldName]: {
																			from: DataType.Date,
																			to: DataType.String,
																			// format: "MMM DD, YYYY",
																		},
																	};
																	fieldComponent = (
																		<FieldComponent
																			{...componentsCommonProps}
																			name={fieldName}
																			// format={fieldFormat}
																			disableFuture={false}
																			{...(defaultValue && {
																				initialDate: defaultValue,
																				// stringToDate(
																				//   defaultValue,
																				//   fieldFormat
																				// ),
																			})}
																			{...(minDate && { minDate })}
																			{...(maxDate && { maxDate })}
																			testId={fieldTestId}
																			onChange={onChange}
																		/>
																	);
																} else if (
																	fieldType === FieldType.RadioGroup
																) {
																	fieldComponent = (
																		<FieldComponent
																			{...componentsCommonProps}
																			name={fieldName}
																			{...(defaultValue && {
																				selectedValue: defaultValue,
																			})}
																			{...(fieldValue && {
																				options: getFieldItems(
																					fieldValue,
																					fieldInternalValue
																				),
																			})}
																			testId={fieldTestId}
																			readOnly={setReadOnly}
																			onChangeHandler={onChange}
																		/>
																	);
																} else if (
																	/.*DropDownList/.test(fieldType)
																) {
																	// if it's a DropdownList component e.g. CountryDropDownList or ProvinceDropDownList or PayPeriodDropDownList
																	fieldComponent = (
																		<FieldComponent
																			{...onChangeFields}
																			label={fieldLabel}
																			selectName={fieldName}
																			isRequired={!!isRequired}
																			placeholder={placeholder}
																			showEmpty={!isRequired} // only show empty when it's not required
																			{...(defaultValue && {
																				selectedItem: defaultValue,
																			})}
																			testId={fieldTestId}
																			onSelectionChangeHandler={onChange}
																			onChange={onChange}
																		/>
																	);
																} else if (/.*Switch/.test(fieldType)) {
																	// if it's a Switch component e.g. EnhancedAndroidSwitch or EnhancedIOSSwitch or EnhancedNormalSwitch
																	fieldComponent = (
																		<FieldComponent
																			label={fieldLabel}
																			name={fieldName}
																			placeholder={placeholder}
																			{...(defaultValue && {
																				checked: defaultValue,
																			})}
																			testId={fieldTestId}
																			readOnly={setReadOnly}
																			onSelectionChangeHandler={onChange}
																		/>
																	);
																} else {
																	fieldComponent = (
																		<FieldComponent
																			{...onChangeFields}
																			{...componentsCommonProps}
																			id={fieldName}
																			placeholder={placeholder}
																			{...(fieldValue && {
																				value: fieldValue,
																			})}
																			testId={fieldTestId}
																		/>
																	);
																}
																return (
																	<FormFieldGrid
																		key={fieldIndex}
																		fieldIndex={fieldIndex}
																		fieldColumn={fullFieldColumns ? fullFieldColumns : 12}
																		testId={testId}
																		sequence={sequence}
																		filterBy={onChangeFields.filterBy}
																		readOnly={setReadOnly}
																	>
																		{fieldComponent}
																	</FormFieldGrid>
																);
															})}
														</Grid>
													</Grid>
												</Grid>
											</StandardPanel>
										// );
									// })
								);
							})
						}
						rightColumnContent={
							oddFormLayout &&
							oddFormLayout.map((panel:any, panelIndex:any) => {
								// const { panels } = tabLayout;
								console.log("panelllll",panel)
								const { gridColumns, label, fields, sequence } =
											panel;
								function stringToDate(arg0: string, fieldFormat: any) {
									throw new Error("Function not implemented.");
								}

								function dateIsAfterToday(maxDate: never) {
									throw new Error("Function not implemented.");
								}

								return(
								// panels.map((panel, panelIndex) => {
								// 			return (
												<StandardPanel title={label} testId={`${label}-panel`} topPadding={2}>
													<Grid
														container
														sx={{ display: "grid", placeItems: "center" }}
													>
													<Grid
														key={sequence}
														item
														xl={gridColumns}
														lg={gridColumns}
														xs={gridColumns}
														sm={gridColumns}
														md={gridColumns}
														data-testid={`${testId}-panel-${panelIndex}-grid`}
														sx={{ marginTop: theme.spacing(7), minWidth:fields && fields.length === 1 ? "70%" : undefined }}
													>
														<Grid
															container
															direction="row"
															justifyContent="flex-start"
															alignItems="flex-start"
															spacing={2}
															sx={{"& .MuiGrid-item":{paddingTop:0}}}
														>
															{fields.map((panelField:any, fieldIndex:any) => {
																const {
																	fieldId,
																	id,
																	isFixed,
																	fieldLabel,
																	placeholder,
																	isRequired,
																	fieldValue,
																	fieldInternalValue,
																	fieldType,
																	uppercase,
																	maxValue,
																	minValue,
																	fieldFormat,
																	labelPosition,
																	labelColumn,
																	fieldColumn,
																	fullFieldColumns,
																	readOnly,
																	onChange
																} = panelField;
																const FieldComponent =
																	FIELD_TYPE_COMPONENT[fieldType];
																// if the field component has not been implemented yet
																let fieldComponent: JSX.Element;
																if (!FieldComponent) {
																	return (
																		<FieldLayout
																			label={fieldLabel}
																			isRequired={isRequired}
																			testId={testId}
																			labelPosition={
																				labelPosition as LabelPosition
																			}
																			labelColumns={labelColumn}
																			fieldColumns={fieldColumn}
																		>
																			<ComponentNotFound
																				key={fieldIndex}
																				fieldType={fieldType}
																				testId={testId}
																			/>
																		</FieldLayout>
																	);
																}
																const fieldTestId = `${testId}-${fieldIndex}-${fieldId}`;
																console.log("onChangeonChange",onChange)
																const fieldName = getFieldName(
																	fieldId,
																	id,
																	isFixed
																);
																let defaultValue =
																	defaultValues[fieldName];
																let onChangeFields = { filterBy: "" };
																let setReadOnly = false;
																if (
																	currentDestFieldsOnChangeRules[
																		fieldName
																	]
																) {
																	const actionType =
																		currentDestFieldsOnChangeRules[
																			fieldName
																		].type;
																	// we should not show the field at all
																	if (
																		actionType === OnChangeActionType.Hide
																	) {
																		return <></>;
																	} else if (
																		actionType ===
																		OnChangeActionType.Filter
																	) {
																		onChangeFields = {
																			...onChangeFields,
																			filterBy:
																				currentDestFieldsOnChangeRules[
																					fieldName
																				].sourceFieldValue,
																		};
																	} else if (
																		actionType ===
																		OnChangeActionType.ReadOnly
																	) {
																		setReadOnly = true;
																	}
																}
																// common props between all the components representing form fields
																const componentsCommonProps = {
																	id: fieldId,
																	label: fieldLabel,
																	required: !!isRequired,
																	labelPosition: labelPosition,
																	labelColumns: labelColumn,
																	fieldColumns: fieldColumn,
																	onChange:onChange,
																	defaultValue:defaultValue
																};
																if (fieldType === FieldType.Checkbox) {
																	fieldComponent = (
																		<FieldComponent
																			{...componentsCommonProps}
																			name={fieldName}
																			checked={!!defaultValue}
																			testId={fieldTestId}
																			readOnly={setReadOnly}
										
																			checkboxLabel={fieldLabel}																			
																			onSelectionChangeHandler={onChange}
																		/>
																	);
																} else if (
																	fieldType === FieldType.TextArea
																) {
																	fieldComponent = (
																		<FieldComponent
																			{...componentsCommonProps}
																			id={fieldName}
																			placeholder={placeholder}
																			uppercase={!!uppercase}
																			{...(fieldValue && {
																				value: fieldValue,
																			})}
																			{...(maxValue && {
																				maxRows: maxValue,
																			})} // use maxValue as an indication of the max rows for now
																			testId={fieldTestId}
																		/>
																	);
																} else if (
																	fieldType === FieldType.MaskedTextField
																) {
																	fieldComponent = (
																		<FieldComponent
																			{...componentsCommonProps}
																			id={fieldName}
																			placeholder={placeholder}
																			{...(defaultValue && {
																				defaultValue: defaultValue,
																			})}
																			{...(fieldFormat && {
																				inputMaskPattern: fieldFormat,
																			})}
																			testId={fieldTestId}
																			readOnly={setReadOnly}
																			// TODO: add regex validation pattern when it's integrated and ready.
																		/>
																	);
																} else if (
																	fieldType ===
																	FieldType.MultiSelectionDropDownList
																) {
																	fieldComponent = (
																		<FieldComponent
																			{...onChangeFields}
																			label={fieldLabel}
																			required={!!isRequired}
																			selectName={fieldName}
																			placeholder={placeholder}
																			{...(fieldValue && {
																				items: getFieldItems(
																					fieldValue,
																					fieldInternalValue
																				),
																			})}
																			{...(defaultValue && {
																				selectedItems: defaultValue,
																			})}
																			testId={fieldTestId}
																			labelPosition={labelPosition}																			
																			onSelectionChangeHandler={onChange}
																		/>
																	);
																} else if (
																	fieldType === FieldType.DropDownList
																) {
																	fieldComponent = (
																		<FieldComponent
																			{...onChangeFields}
																			label={fieldLabel}
																			required={!!isRequired}
																			selectName={fieldName}
																			// selectedItem={}
																			placeholder={placeholder}
																			showEmpty={false} // only show empty when it's not required
																			{...(fieldValue && {
																				items: getFieldItems(
																					fieldValue,
																					fieldInternalValue
																				),
																			})}
																			{...(defaultValue && {
																				selectedItem: defaultValue,
																			})}
																			isDisabled={readOnly || setReadOnly}
																			isSearchable={false}
																			testId={fieldTestId}
																			labelPosition={labelPosition}
																			fieldColumns={fieldColumn}
																			labelColumns={labelColumn}
																			onSelectionChangeHandler={onChange}
																		/>
																	);
																} else if (fieldType === FieldType.Date) {
																	const minDate:any = minValue
																		? stringToDate(
																				String(minValue),
																				fieldFormat
																			)
																		: null;
																	const maxDate:any = maxValue
																		? stringToDate(
																				String(maxValue),
																				fieldFormat
																			)
																		: null;
																	let shouldDisableFutureDates = true;
																	if (maxDate) {
																		// shouldDisableFutureDates =
																		// 	!dateIsAfterToday(maxDate);
																	}
																	// indicate that when the current field is submitted, we need to convert it from date back to string
																	fieldValuesConversionData.current = {
																		...fieldValuesConversionData.current,
																		[fieldName]: {
																			from: DataType.Date,
																			to: DataType.String,
																			// format: "MMM DD, YYYY",
																		},
																	};
																	fieldComponent = (
																		<FieldComponent
																			{...componentsCommonProps}
																			name={fieldName}
																			// format={fieldFormat}
																			disableFuture={false}
																			{...(defaultValue && {
																				initialDate: defaultValue,
																				// stringToDate(
																				//   defaultValue,
																				//   fieldFormat
																				// ),
																			})}
																			{...(minDate && { minDate })}
																			{...(maxDate && { maxDate })}
																			testId={fieldTestId}
																		/>
																	);
																} else if (
																	fieldType === FieldType.RadioGroup
																) {
																	fieldComponent = (
																		<FieldComponent
																			{...componentsCommonProps}
																			name={fieldName}
																			{...(defaultValue && {
																				selectedValue: defaultValue,
																			})}
																			{...(fieldValue && {
																				options: getFieldItems(
																					fieldValue,
																					fieldInternalValue
																				),
																			})}
																			testId={fieldTestId}
																			readOnly={setReadOnly}
																		/>
																	);
																} else if (
																	/.*DropDownList/.test(fieldType)
																) {
																	// if it's a DropdownList component e.g. CountryDropDownList or ProvinceDropDownList or PayPeriodDropDownList
																	fieldComponent = (
																		<FieldComponent
																			{...onChangeFields}
																			label={fieldLabel}
																			selectName={fieldName}
																			isRequired={!!isRequired}
																			placeholder={placeholder}
																			showEmpty={!isRequired} // only show empty when it's not required
																			{...(defaultValue && {
																				selectedItem: defaultValue,
																			})}
																			testId={fieldTestId}
																		/>
																	);
																} else if (/.*Switch/.test(fieldType)) {
																	// if it's a Switch component e.g. EnhancedAndroidSwitch or EnhancedIOSSwitch or EnhancedNormalSwitch
																	fieldComponent = (
																		<FieldComponent
																			label={fieldLabel}
																			name={fieldName}
																			placeholder={placeholder}
																			{...(defaultValue && {
																				checked: defaultValue,
																			})}
																			testId={fieldTestId}
																			readOnly={setReadOnly}
																		/>
																	);
																} else {
																	fieldComponent = (
																		<FieldComponent
																			{...onChangeFields}
																			{...componentsCommonProps}
																			id={fieldName}
																			placeholder={placeholder}
																			{...(fieldValue && {
																				value: fieldValue,
																			})}
																			testId={fieldTestId}
																		/>
																	);
																}
																return (
																	<FormFieldGrid
																		key={fieldIndex}
																		fieldIndex={fieldIndex}
																		fieldColumn={fullFieldColumns ? fullFieldColumns : 12}
																		testId={testId}
																		sequence={sequence}
																		filterBy={onChangeFields.filterBy}
																		readOnly={setReadOnly}
																	>
																		{fieldComponent}
																	</FormFieldGrid>
																);
															})}
														</Grid>
													</Grid>
												</Grid>
											</StandardPanel>
									// 	);
									// })
								);
							})
						}
						toolbar={toolbar}
						testId="client-setup-form"
					/>
				</>
			) : (
				<>
					{
					// formLayout.map((tabLayout) => {
						// const { panels } = formLayout?.panels;
						// return (
						// 	<>
								<SingleColumnLayout
								children={formLayout && totalPanels &&  totalPanels.map((panel:any, panelIndex:any) => {
									const { gridColumns, label, fields, sequence } =
													panel;
										function stringToDate(arg0: string, fieldFormat: string) {
											throw new Error("Function not implemented.");
										}

										function dateIsAfterToday(maxDate: any) {
											throw new Error("Function not implemented.");
										}

												return (
													<StandardPanel title={label} testId={`${label}-panel`} topPadding={2}>
														<Grid
															container
															sx={{ display: "grid", placeItems: "center" }}
														>
														<Grid
															key={sequence}
															item
															xl={gridColumns}
															lg={gridColumns}
															xs={gridColumns}
															sm={gridColumns}
															md={gridColumns}
															data-testid={`${testId}-panel-${panelIndex}-grid`}
															sx={{ marginTop: theme.spacing(7), minWidth:fields && fields.length === 1 ? "70%" : undefined }}
														>
															<Grid
																container
																direction="row"
																justifyContent="flex-start"
																alignItems="flex-start"
																spacing={2}
																sx={{"& .MuiGrid-item":{paddingTop:0}}}
															>
																{fields.map((panelField:any, fieldIndex:any) => {
																	const {
																		fieldId,
																		id,
																		isFixed,
																		fieldLabel,
																		placeholder,
																		isRequired,
																		fieldValue,
																		fieldInternalValue,
																		fieldType,
																		uppercase,
																		maxValue,
																		minValue,
																		fieldFormat,
																		labelPosition,
																		labelColumn,
																		fieldColumn,
																		fullFieldColumns,
																		readOnly,
																		onChange
																	} = panelField;
																	const FieldComponent =
																		FIELD_TYPE_COMPONENT[fieldType];
																	// if the field component has not been implemented yet
																	let fieldComponent: JSX.Element;
																	if (!FieldComponent) {
																		return (
																			<FieldLayout
																				label={fieldLabel}
																				isRequired={isRequired}
																				testId={testId}
																				labelPosition={
																					labelPosition as LabelPosition
																				}
																				labelColumns={labelColumn}
																				fieldColumns={fieldColumn}
																			>
																				<ComponentNotFound
																					key={fieldIndex}
																					fieldType={fieldType}
																					testId={testId}
																				/>
																			</FieldLayout>
																		);
																	}
																	const fieldTestId = `${testId}-${fieldIndex}-${fieldId}`;
																	console.log("onChangeonChange",onChange)
																	const fieldName = getFieldName(
																		fieldId,
																		id,
																		isFixed
																	);
																	let defaultValue =
																		defaultValues[fieldName];
																	let onChangeFields = { filterBy: "" };
																	let setReadOnly = false;
																	if (
																		currentDestFieldsOnChangeRules[
																			fieldName
																		]
																	) {
																		const actionType =
																			currentDestFieldsOnChangeRules[
																				fieldName
																			].type;
																		// we should not show the field at all
																		if (
																			actionType === OnChangeActionType.Hide
																		) {
																			return <></>;
																		} else if (
																			actionType ===
																			OnChangeActionType.Filter
																		) {
																			onChangeFields = {
																				...onChangeFields,
																				filterBy:
																					currentDestFieldsOnChangeRules[
																						fieldName
																					].sourceFieldValue,
																			};
																		} else if (
																			actionType ===
																			OnChangeActionType.ReadOnly
																		) {
																			setReadOnly = true;
																		}
																	}
																	// common props between all the components representing form fields
																	const componentsCommonProps = {
																		id: fieldId,
																		label: fieldLabel,
																		required: !!isRequired,
																		labelPosition: labelPosition,
																		labelColumns: labelColumn,
																		fieldColumns: fieldColumn,
																		onChange:onChange,
																		defaultValue:defaultValue
																	};
																	if (fieldType === FieldType.Checkbox) {
																		fieldComponent = (
																			<FieldComponent
																				{...componentsCommonProps}
																				name={fieldName}
																				checked={!!defaultValue}
																				testId={fieldTestId}
																				readOnly={setReadOnly}
																				checkboxLabel={fieldLabel}
																			/>
																		);
																	} else if (
																		fieldType === FieldType.TextArea
																	) {
																		fieldComponent = (
																			<FieldComponent
																				{...componentsCommonProps}
																				id={fieldName}
																				placeholder={placeholder}
																				uppercase={!!uppercase}
																				{...(fieldValue && {
																					value: fieldValue,
																				})}
																				{...(maxValue && {
																					maxRows: maxValue,
																				})} // use maxValue as an indication of the max rows for now
																				testId={fieldTestId}
																			/>
																		);
																	} else if (
																		fieldType === FieldType.MaskedTextField
																	) {
																		fieldComponent = (
																			<FieldComponent
																				{...componentsCommonProps}
																				id={fieldName}
																				placeholder={placeholder}
																				{...(defaultValue && {
																					defaultValue: defaultValue,
																				})}
																				{...(fieldFormat && {
																					inputMaskPattern: fieldFormat,
																				})}
																				testId={fieldTestId}
																				readOnly={setReadOnly}
																				// TODO: add regex validation pattern when it's integrated and ready.
																			/>
																		);
																	} else if (
																		fieldType ===
																		FieldType.MultiSelectionDropDownList
																	) {
																		fieldComponent = (
																			<FieldComponent
																				{...onChangeFields}
																				label={fieldLabel}
																				required={!!isRequired}
																				selectName={fieldName}
																				placeholder={placeholder}
																				{...(fieldValue && {
																					items: getFieldItems(
																						fieldValue,
																						fieldInternalValue
																					),
																				})}
																				{...(defaultValue && {
																					selectedItems: defaultValue,
																				})}
																				testId={fieldTestId}
																				labelPosition={labelPosition}
																			/>
																		);
																	} else if (
																		fieldType === FieldType.DropDownList
																	) {
																		fieldComponent = (
																			<FieldComponent
																				{...onChangeFields}
																				label={fieldLabel}
																				required={!!isRequired}
																				selectName={fieldName}
																				// selectedItem={}
																				placeholder={placeholder}
																				showEmpty={false} // only show empty when it's not required
																				{...(fieldValue && {
																					items: getFieldItems(
																						fieldValue,
																						fieldInternalValue
																					),
																				})}
																				{...(defaultValue && {
																					selectedItem: defaultValue,
																				})}
																				isDisabled={readOnly || setReadOnly}
																				isSearchable={false}
																				testId={fieldTestId}
																				labelPosition={labelPosition}
																				fieldColumns={fieldColumn}
																				labelColumns={labelColumn}
																				onSelectionChangeHandler={onChange}
																			/>
																		);
																	} else if (fieldType === FieldType.Date) {
																		const minDate:any = minValue
																			? stringToDate(
																					String(minValue),
																					fieldFormat
																				)
																			: null;
																		const maxDate:any = maxValue
																			? stringToDate(
																					String(maxValue),
																					fieldFormat
																				)
																			: null;
																		let shouldDisableFutureDates = true;
																		if (maxDate) {
																			// shouldDisableFutureDates =
																			// 	!dateIsAfterToday(maxDate);
																		}
																		// indicate that when the current field is submitted, we need to convert it from date back to string
																		fieldValuesConversionData.current = {
																			...fieldValuesConversionData.current,
																			[fieldName]: {
																				from: DataType.Date,
																				to: DataType.String,
																				// format: "MMM DD, YYYY",
																			},
																		};
																		fieldComponent = (
																			<FieldComponent
																				{...componentsCommonProps}
																				name={fieldName}
																				// format={fieldFormat}
																				disableFuture={false}
																				{...(defaultValue && {
																					initialDate: defaultValue,
																					// stringToDate(
																					//   defaultValue,
																					//   fieldFormat
																					// ),
																				})}
																				{...(minDate && { minDate })}
																				{...(maxDate && { maxDate })}
																				testId={fieldTestId}
																			/>
																		);
																	} else if (
																		fieldType === FieldType.RadioGroup
																	) {
																		fieldComponent = (
																			<FieldComponent
																				{...componentsCommonProps}
																				name={fieldName}
																				{...(defaultValue && {
																					selectedValue: defaultValue,
																				})}
																				{...(fieldValue && {
																					options: getFieldItems(
																						fieldValue,
																						fieldInternalValue
																					),
																				})}
																				testId={fieldTestId}
																				readOnly={setReadOnly}
																			/>
																		);
																	} else if (
																		/.*DropDownList/.test(fieldType)
																	) {
																		// if it's a DropdownList component e.g. CountryDropDownList or ProvinceDropDownList or PayPeriodDropDownList
																		fieldComponent = (
																			<FieldComponent
																				{...onChangeFields}
																				label={fieldLabel}
																				selectName={fieldName}
																				isRequired={!!isRequired}
																				placeholder={placeholder}
																				showEmpty={!isRequired} // only show empty when it's not required
																				{...(defaultValue && {
																					selectedItem: defaultValue,
																				})}
																				testId={fieldTestId}
																			/>
																		);
																	} else if (/.*Switch/.test(fieldType)) {
																		// if it's a Switch component e.g. EnhancedAndroidSwitch or EnhancedIOSSwitch or EnhancedNormalSwitch
																		fieldComponent = (
																			<FieldComponent
																				label={fieldLabel}
																				name={fieldName}
																				placeholder={placeholder}
																				{...(defaultValue && {
																					checked: defaultValue,
																				})}
																				testId={fieldTestId}
																				readOnly={setReadOnly}
																			/>
																		);
																	} else {
																		fieldComponent = (
																			<FieldComponent
																				{...onChangeFields}
																				{...componentsCommonProps}
																				id={fieldName}
																				placeholder={placeholder}
																				{...(fieldValue && {
																					value: fieldValue,
																				})}
																				testId={fieldTestId}
																			/>
																		);
																	}
																	return (
																		<FormFieldGrid
																			key={fieldIndex}
																			fieldIndex={fieldIndex}
																			fieldColumn={fullFieldColumns ? fullFieldColumns : 12}
																			testId={testId}
																			sequence={sequence}
																			filterBy={onChangeFields.filterBy}
																			readOnly={setReadOnly}
																		>
																			{fieldComponent}
																		</FormFieldGrid>
																	);
																})}
															</Grid>
														</Grid>
													</Grid>
												</StandardPanel>
											);
										})}
									toolbar={toolbar}
									testId={`${name}-layout`}
								/>
							// </>
						// );
					// })
					}
				</>
			)}
			<input
				type="submit"
				style={{ display: "none" }}
				ref={hiddenSubmitRef}
			/>
		</form>
	</FormProvider>
);
};
