import React, { useState, useRef, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { BackdropCircularProgress, RequestParams } from '@websential/cosmic';
import { GridRenderCellParams } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import "./t5ReportDataTableStyle.css"

export interface IDataTable {
    rows: any;
    columns:any;
    showIndexColumn:boolean;
    showChecboxColumn: boolean,
    showSettingColumn: boolean,
    onRequestParamsChange?: (params: RequestParams) => void;
    initialPageSize?: number | undefined;
    initialPage?: number;
    totalRecords?: number;
    isLoading?: boolean;
}

export const PrimeDataGrid: React.FC<IDataTable> = ({
    rows,
    columns,
    showIndexColumn,
    showChecboxColumn,
    showSettingColumn,
    onRequestParamsChange,
    initialPageSize = 25,
    initialPage = 0,
    totalRecords,
    isLoading
}) => {
    const isInitialRequest = useRef(true);
    const [selectedItems, setSelectedItems] = useState(null);
    const [itemSearch, setItemSearch] = useState('');
    const [requestParams, setRequestParams] = useState<any>({
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        sortField: null,
        sortOrder: null,
        searchQuery:"",
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

    const [val, setValue] = useState(false)
    const [field, setField] = useState("")
    const setting = () => {
        return(
            <div className='ml-4 pl-1'>
                <i className='pi pi-cog text-xl'></i>
            </div>
        )
    }
    const delEdit = (params:any) => {
        return(
            <div className='flex'>
                {/* <Button icon="pi pi-user-edit" onClick={() => onRowEditClicked(params)} className="p-button-rounded p-button-text w-2rem h-2rem"/>
                <Button icon="pi pi-trash" onClick={() => onRowDeleteClicked(params)} className="p-button-rounded p-button-text w-2rem h-2rem ml-2"/> */}
            </div>
        )
    }
     // Toast msg for Edit Button
     const toast:any = useRef(null);
     const showEdit = () => {
         toast.current.show({severity:'success', summary: 'Success Message', detail:'Edit Button Clicked', life: 8000});
     }
     // Toast msg for Delete Button
     const toastDel:any = useRef(null);
     const showDelete = () => {
         toastDel.current.show({severity:'error', summary: 'Delete Message', detail:'Delete Button Clicked', life: 8000});
     }
   
    // console.log(props.headerColumns)
   
     const maskingCol = (val:any,hName:any) => {
        if(val == true){
            return "*****"
        }else{
            return hName
        }
     }

const onSearchButtonClicked = () => {
    setRequestParams((prevParams:any) => ({
        ...prevParams,
        searchQuery: itemSearch,
      }));
}
const handleClearSearch = () => {
    setItemSearch('')
    setRequestParams((prevParams:any) => ({
        ...prevParams,
        searchQuery: "",
      }));
    }
// Search type and billing profile Button
    const renderHeader = () => {
        return (
            // <div className='flex justify-content-between p-4 pl-0'  style={{backgroundColor:"#ebecf0",margin:"-17px"}}>
            <span className="p-input-icon-left w-5" style={{display:"flex"}}>
                <i className="pi pi-search" />
                <InputText value={itemSearch} onChange={(e) => setItemSearch(e.target.value)} placeholder="Search by Employee Name" className='w-full' style={{borderRadius:"5px 0px 0px 5px"}}/>
                {itemSearch && (
                    <Button
                    icon="pi pi-times"
                    onClick={handleClearSearch}
                    className="p-button-warning"
                    style={{borderRadius:0}}
                    />
                )}
                <Button
                    onClick={onSearchButtonClicked}
                    // variant="contained"
                    style={{
                    padding:"8px 10px 8px 10px",
                    marginRight:"-14px!important",
                    borderRadius:"0px 5px 5px 0px"}}
                >
                    <SearchIcon />
                </Button>
            </span>
            // </div>
        )
    }
    const headerSearch = renderHeader();
    function search(_row:any){
        return rowsWithTwoDecimalNumbers && rowsWithTwoDecimalNumbers.filter((rows:any)=> rows?.resource?.name.toLowerCase().indexOf(itemSearch.toLowerCase())>-1 )
        // rows.effectiveStartDate.toLowerCase().indexOf(globalFilterValue1)>-1)

        // another way where we dont need to write all properties which you want to search (we can search with all props) 
        // if(rows && rows !== false && _row !== false){
        //     const columns = _row[0] && Object.keys(_row[0])
        //     console.log("columns",columns)
        //     return rows.filter((rows:any)=> columns.some((columns:any)=> rows[columns]?.toString().toLowerCase().indexOf(itemSearch.toLowerCase())>-1))  
        // }
    }

    const HideButton = (option: any, value: any , field: any) => {
        if(option == true){
                return(
                <span className='field-checkbox mt-3 mb-0'>
                    <Button label='Hide Column'  className='p-button-secondary uppercase' onClick={()=>clickHideBtn(value, field, true)}/>
                </span>
                )       
        }else{
            return option = null
        }
    }

    const clickHideBtn = (value: any, field: any, is_load: any) => {
        // console.log("xyz", value.field);
        // console.log("is_load", is_load);
        // console.log("x", value);
        // console.log("value.field === field", value.field === field);
        
        if(is_load){
            if(value.field === field){
                setValue(value)
                setField(field)
                // return true
            }
            //  else {
            //     setValue(value)
            //     setField("")
            // }
        } else {
            return false
        }
    }
    // const footer = "hello footer here";

    const rowsWithTwoDecimalNumbers = rows && rows.map((item:any) => {
        return {
        ...item,
        slipData: {
            ...item.slipData,
            actualAmountOfEligibleDivinends: parseFloat(item.slipData.actualAmountOfEligibleDivinends).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            interestFromCanadianSources: parseFloat(item.slipData.interestFromCanadianSources).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            capitalGainDividends: parseFloat(item.slipData.capitalGainDividends).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            actualAmountOfDivinendsOtherThanEligible: parseFloat(item.slipData.actualAmountOfDivinendsOtherThanEligible).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            taxableAmountOfEligibleDividends: parseFloat(item.slipData.taxableAmountOfEligibleDividends).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            dividendTaxCreditForEligibleDividends: parseFloat(item.slipData.dividendTaxCreditForEligibleDividends).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            taxableAmountOfDivinendsOtherThanEligible: parseFloat(item.slipData.taxableAmountOfDivinendsOtherThanEligible).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            dividendTaxCreditForDivdendsOtherThanEligible: parseFloat(item.slipData.dividendTaxCreditForDivdendsOtherThanEligible).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          },
        resource:{
            ...item.resource,
        },
        // otherInformation: {
        //     // ...item.otherInformation,
        //     [0 + "_label_0"]: item.otherInformation["0_label_0"],
        //     [0 + "_amount_0"]: item.otherInformation["0_amount_0"] && parseFloat(item.otherInformation["0_amount_0"]).toFixed(2),
        //     [1 + "_label_1"]: item.otherInformation["1_label_1"],
        //     [1 + "_amount_1"]: item.otherInformation["1_amount_1"] && parseFloat(item.otherInformation["1_amount_1"]).toFixed(2),
        //     [2 + "_label_2"]: item.otherInformation["2_label_2"],
        //     [2 + "_amount_2"]: item.otherInformation["2_amount_2"] && parseFloat(item.otherInformation["2_amount_2"]).toFixed(2),
        //     [3 + "_label_3"]: item.otherInformation["3_label_3"],
        //     [3 + "_amount_3"]: item.otherInformation["3_amount_3"] && parseFloat(item.otherInformation["3_amount_3"]).toFixed(2),
        // }
    }});
    // const maxObjects = 39;
    // const updateColumnswith4OI = columns && columns.slice(0, maxObjects)
    const columnsWithTwoDecimalNumbers = columns && columns.map((item:any) => {
        return {
        ...item,
        // footer: item.footer !== null && typeof(item.footer) !== "string" ? parseFloat(item.footer).toFixed(2) : item.footer
        footer: item.footer !== null && item.footer !== "" && item.footer !== "Total" ? parseFloat(item.footer).toFixed(2) : item.footer
      }});

const indexColumn = {
    body: (rowData:any, row:any) => {
        return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
    },
    header: '#',
};

const onPage = (event:any) => {
    setRequestParams(event);
};
// This useEffect is triggered when the debouncedSearchQuery, pageSize or currentPage values change
useEffect(() => {
    // if the results fetcher was passed, we can use it to fetch the results.
    if (onRequestParamsChange) {
      // skip the first initial call
      if (isInitialRequest.current === true) {
        console.log(
          "##### => useEffect - requestParams - no first call",
          requestParams
        );
        isInitialRequest.current = false;
      } else {
        console.log(
          "##### => useEffect - requestParams - second call",
          requestParams
        );
        onRequestParamsChange(requestParams);
      }
    }
  }, [onRequestParamsChange, requestParams]);

      console.log("rowsWithTwoDecimalNumbers",rowsWithTwoDecimalNumbers)
      console.log("columnsWithTwoDecimalNumbers",columnsWithTwoDecimalNumbers)
      console.log("props headerColumns => ",columns)
      console.log("props items T5Report => ",rows)
  return (
    <div>
       <DataTable 
            value={rowsWithTwoDecimalNumbers && rowsWithTwoDecimalNumbers} 
            responsiveLayout="scroll" 
            lazy 
            // filterDisplay="row"
            dataKey="id" 
            paginator
            first={requestParams.first} 
            rows={requestParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}

            // paginator 
            // rows={25} 
            rowsPerPageOptions={[25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={`Showing {first} to {last} of ${totalRecords} Items`}
            // selection={selectedItems} onSelectionChange={(e:any) => setSelectedItems(e.value)}
            editMode="row" 
            showGridlines
            resizableColumns columnResizeMode="expand"
            scrollable
            reorderableColumns
            filterDisplay="menu"
            loading={isLoading}
            globalFilterFields={['headerName']}
            emptyMessage="No Record found."
            header={headerSearch}
            className="t5ReportDataTableStyle"
            // footer={footer}
            >
                <Column selectionMode="multiple" hidden={showChecboxColumn} frozen resizeable={false}
                style={{maxWidth:"65px"}} field="selection" columnKey='selection'></Column>
                {rows && <Column 
                    {...indexColumn} 
                    frozen
                    hidden={!showIndexColumn}
                    className='indexColumn'
                    />}
                {columns &&  columnsWithTwoDecimalNumbers.map((x:any,index:any) => {
                    console.log("clickHideBtn(x, x.field, false) inhhhher", clickHideBtn(x, x.field, false));
                    
                    return (
                    <Column 
                        key={index} 
                        field={x.field} 
                        header={x.headerName} 
                        headerTooltip={x.description}
                        headerTooltipOptions={{ position: 'top' }}
                        // flex (This property is not use Yet)
                        // hidden={val === x.hide ? clickHideBtn(x, x.field, false) : x.field === field ? val: x.hide } 
                        sortable={x.sortable} 
                        resizeable={x.resizable}
                        dataType={x.type}
                        align={x.align}
                        alignHeader={x.headerAlign}
                        // hideSortIcons (This property is not use Yet)
                        // filterElement={HideButton(x.hideable, x, x.field)}
                        // showFilterMenuOptions
                        // filter
                        // showFilterMenu={x.disableColumnMenu}
                        // filterPlaceholder="Search by name"
                        reorderable={x.disableReorder}
                        exportable={x.disableExport}
                        frozen={x.frozenColumn}
                        columnKey={x.field}
                        style={{
                            minWidth:x.minWidth,
                                fontSize:"0.875rem",
                                // padding:"0px 16px",
                               }}
                        // className="p-column-header-content"
                        footer={x.footer && x.footer !== '' && x.footer !== 'Total' && x.footer !== null ? parseFloat(x.footer).toFixed(2) : x.footer}
                        />
                )})}
                    
                <Column body={delEdit} header={setting} style={{width:"110px",maxWidth:"110px"}} hidden={showSettingColumn}
                resizeable={false} reorderable={false} frozen alignFrozen='right'></Column>
            </DataTable>
    </div>
  )
}

//resizeablbe must be false if frozen is true and viseversa