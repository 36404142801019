import { FC, useEffect, useState } from "react";
import {
  EnhancedDropDownRHF,
  EnhancedDropDownRHFWithLabel,
  FieldLayout,
  ITest,
  LabelPosition,
  RHookFormNumberFieldWithLabel,
} from "@websential/cosmic";
import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { T4OtherInformationService } from "components/OtherInformation/otherInformations";
import { Grid, InputLabel, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { RHookFormNumericFormatTextField } from "components/TaxSlipDataInput/RHookFormNumericFormatTextField";

interface IT4DefaultSettingsPanel extends ITest {}
export const T4DefaultSettingsPanel: FC<IT4DefaultSettingsPanel> = ({
  testId,
}) => {
  const [T4OtherInformationList, setT4OtherInformationList] =
    useState<any>(undefined);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await T4OtherInformationService.getT4OIForDefaultSettings();
        setT4OtherInformationList(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      {/* <RHookFormNumberFieldWithLabel
        label="RPP Or DPSP Registration #"
        id="registrationNumber"
        testId={`${testId}-registration-Number`}
        // placeholder=""
        labelPosition={LabelPosition.Left}
      /> */}
      <FieldWrapper>
        <FieldLayout
          label="RPP Or DPSP Registration #"
          // isRequired={isRequired}
          testId={testId}
          labelPosition={LabelPosition.Left}
          labelColumns={4}
          fieldColumns={8}
        >
      <RHookFormNumericFormatTextField
        id='rppDpspRegistration'
        testId={`${testId}-registration-Number`}
          textAlign={'end'}
        /></FieldLayout>
        </FieldWrapper>
      <FieldWrapper>
        <FieldLayout
          label="Other Informations"
          // isRequired={isRequired}
          testId={testId}
          labelPosition={LabelPosition.Left}
          labelColumns={4}
          fieldColumns={8}
        >
          <>
            <EnhancedDropDownRHFWithLabel
              selectName="t4OtherInformation1"
              label=""
              testId={`${testId}-other-information-list`}
              items={T4OtherInformationList ?? []}
            />
            <EnhancedDropDownRHFWithLabel
              selectName="t4OtherInformation2"
              label=""
              testId={`${testId}-other-information-list`}
              items={T4OtherInformationList ?? []}
            />
            <EnhancedDropDownRHFWithLabel
              selectName="t4OtherInformation3"
              label=""
              testId={`${testId}-other-information-list`}
              items={T4OtherInformationList ?? []}
            />
            <EnhancedDropDownRHFWithLabel
              selectName="t4OtherInformation4"
              label=""
              testId={`${testId}-other-information-list`}
              items={T4OtherInformationList ?? []}
            />
            <EnhancedDropDownRHFWithLabel
              selectName="t4OtherInformation5"
              label=""
              testId={`${testId}-other-information-list`}
              items={T4OtherInformationList ?? []}
            />
            <EnhancedDropDownRHFWithLabel
              selectName="t4OtherInformation6"
              label=""
              testId={`${testId}-other-information-list`}
              items={T4OtherInformationList ?? []}
            />
          </>
        </FieldLayout>
      </FieldWrapper>

      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          sx={{ textAlign: "left", paddingTop: 2 }}
        >
          <InputLabel sx={{ display: "flex" }}>
            <Typography variant="subtitle2">
              {"Print T4/T4A Back Page"}
            </Typography>
            <Tooltip
              title={"Print T4slip Back Page for Employees"}
              placement={"top"}
            >
              <InfoIcon sx={{ fontSize: "1.1rem", margin: "1.5px" }} />
            </Tooltip>
          </InputLabel>
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
          <EnhancedDropDownRHF
            selectName="printT4T4aBackPage"
            testId={`${testId}-other-information-list`}
            items={[
              {
                value: false,
                label: "No",
              },
              {
                value: true,
                label: "Yes",
              },
            ]}
            selectedItem={true}
          />
        </Grid>
      </Grid>
    </>
  );
};
