import { ResourceList } from "components/Resource";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";
import { useParams } from "react-router-dom";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export interface IResourceListPage {}

export const ResourceListPage: FC<IResourceListPage> = () => {
  const { resourceName } = useParams();
  console.log("resourceName>>>>> from page", resourceName);
  return (
    <AuthenticatedLayout
      pageTitle={`${
        resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName === "T3_Beneficiary" ? "T3 Beneficiary" : resourceName
      } List`}
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        {
          text: `${
            resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName === "T3_Beneficiary" ? "T3 Beneficiary" : resourceName
          }`,
          navigateTo: `/resources/${resourceName}`,
        },
      ]}
      mainContents={<ResourceList />}
    />
  );
};
