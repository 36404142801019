import T4SlipImportLayout from "components/T4Slip/T4SlipImportLayout/T4SlipImportLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";
import { useParams } from "react-router-dom";
import ResourceImportLayout from "../ResourceImportLayout/ResourceImportLayout";

export interface IResourceImportPage {}
export const ResourceImportPage: FC<IResourceImportPage> = () => {
  const { resourceName } = useParams();
  console.log("resourceName>>>>> from page", resourceName);
  return (
    <AuthenticatedLayout
      pageTitle={`Import ${resourceName}`}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: `${resourceName}`, navigateTo: `/resources/${resourceName}` },
        { text: "Import T4 Slips", navigateTo: `/resources/${resourceName}/import` },
      ]}
      mainContents={<ResourceImportLayout />}
    />
  );
};
