import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import { FC } from "react";

export interface IT4SlipDetailPanel {}

export const T4SlipDetailPanel: FC<IT4SlipDetailPanel> = () => {
  return (
    <>
      <TaxSlipSummaryDataInput
        id="totalNumberOfT4SlipsFiled"
        label="Total number of T4 slips filed"
        boxNumber="88"
        textfield={true}
      />
      <TaxSlipSummaryDataInput
        id="employmentIncome"
        label="Employment Income"
        boxNumber="14"
      />
      <TaxSlipSummaryDataInput
        id="registerPensionPlanRppContribution"
        label="Register Pension Plan (RPP) Contribution"
        boxNumber="20"
      />
      <TaxSlipSummaryDataInput
        id="pensionAdjustment"
        label="Pension Adjustment"
        boxNumber="52"
      />
    </>
  );
};
