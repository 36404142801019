import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import { FC } from "react";

export interface IT3SlipDetailPanel {
  formMethods: any;
}

export const T3SlipDetailPanel: FC<IT3SlipDetailPanel> = ({formMethods}) => {
  const handleInputChange = (e: any) => {
    const newValue = e.target.value;
    console.log("newValue", newValue);
  };
  return (
    <>
      <TaxSlipSummaryDataInput
        id="totalNumberOfT3SlipsFiled"
        label="Total number of T3 slips filed"
        boxNumber="31"
        textfield={true}
      />
      <TaxSlipSummaryDataInput
        id="actualAmountOfEligibleDividends"
        label="Actual amount of eligible dividends"
        boxNumber="49"
      />
      <TaxSlipSummaryDataInput
        id="taxableAmountOfEligibleDividends"
        label="Taxable amount of eligible dividends"
        boxNumber="50"
      />
      <TaxSlipSummaryDataInput
        id="dividendTaxCreditForEligibleDividends"
        label="Dividend tax credit for eligible dividends"
        boxNumber="51"
      />
      <TaxSlipSummaryDataInput
        id="capitalGains"
        label="Capital gains"
        boxNumber="21"
      />
      <TaxSlipSummaryDataInput
        id="capitalGainsEligibleForDeduction"
        label="Capital gains eligible for deduction"
        boxNumber="30"
      />
      <TaxSlipSummaryDataInput
        id="actualAmountOfDividendsOtherThanEligibleDividends"
        label="Actual amount of dividends other than eligible dividends"
        boxNumber="23"
      />
      <TaxSlipSummaryDataInput
        id="taxableAmountOfDivinendsOtherThanEligibleDividends"
        label="Taxable amount of divinends other than eligible dividends"
        boxNumber="32"
      />
      <TaxSlipSummaryDataInput
        id="dividendTaxCreditForDividendsOtherThanEligibleDividends"
        label="Dividend tax credit for dividends other than eligible dividends"
        boxNumber="39"
      />
      <TaxSlipSummaryDataInput
        id="otherIncome"
        label="Other income"
        boxNumber="26"
      />
      <TaxSlipSummaryDataInput
        id="lumpSumPensionIncome"
        label="Lump-sum pension income"
        boxNumber="22"
      />
      <TaxSlipSummaryDataInput id="foreignBusinessIncome" 
        label="Foreign business income" 
        boxNumber="24" />
      <TaxSlipSummaryDataInput
        id="foreignNonBusinessIncome"
        label="Foreign non-business income"
        boxNumber="25"
      />
      <TaxSlipSummaryDataInput
        id="qualifyingPensionIncome"
        label="Qualifying pension income"
        boxNumber="31"
      />
      <TaxSlipSummaryDataInput
        id="foreignBusinessIncomeTaxPaid"
        label="Foreign business income tax paid"
        boxNumber="33"
      />
      <TaxSlipSummaryDataInput
        id="foreignNonBusinessIncomeTaxPaid"
        label="Foreign non-business income tax paid"
        boxNumber="34"
      />
      <TaxSlipSummaryDataInput
        id="eligibleDeathBenefits"
        label="Eligible death benefits"
        boxNumber="35"
      />
      <TaxSlipSummaryDataInput
        id="insuranceSegregatedFundNetCapitalLosses"
        label="Insurance segregated fund net capital losses"
        boxNumber="37"
      />
      <TaxSlipSummaryDataInput
        id="partXii2TaxCredit"
        label="Part XII.2 tax credit"
        boxNumber="38"
      />
      <TaxSlipSummaryDataInput
        id="investmentCostsOrExpendituresForInvestmentTaxCredit"
        label="Investment costs or expenditures for investment tax credit"
        boxNumber="40"
      />
      <TaxSlipSummaryDataInput
        id="investmentTexCredit"
        label="Investment tax credit"
        boxNumber="41"
      />
      <TaxSlipSummaryDataInput
        id="amountResultingInCostBaseAdjustment"
        label="Amount resulting in cost base adjustment"
        boxNumber="42"
      />
      <TaxSlipSummaryDataInput
        id="otherCredits"
        label="Other credits"
        boxNumber="45"
      />
      <TaxSlipSummaryDataInput
        id="pensionIncomeQualifyingForAnEligibleAnnuityForAMinor"
        label="Pension income qualifying for an eligible annuity for a minor"
        boxNumber="46"
      />
      <TaxSlipSummaryDataInput
        id="retiningAllowanceQualifyingForTransferToAnRppOrRrsp"
        label="Retining allowance qualifying for transfer to an RPP or RRSP"
        boxNumber="47"
      />
      <TaxSlipSummaryDataInput
        id="eligibleAmountOfCharitableDonation"
        label="Eligible amount of charitable donation"
        boxNumber="48"
      />
      <TaxSlipSummaryDataInput
        id="capitalGainsEligibleForDeduction"
        label="Capital gains eligible for deduction - pre Mar 19, 2007"
        boxNumber="29"
      />
    </>
  );
};
