import React, { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

// import { AccountantListContent } from "components";
// import { AuthenticatedLayout } from "layouts";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { ClientDashboard } from "components/Client/ClientDashboard/ClientDashboard";
// import { ClientDashboard } from "components/Client/ClientDashboard";

export interface IClientDashboardPage {
  // employeeList: any
}

export const ClientDashboardPage: FC<IClientDashboardPage> = (
  props: IClientDashboardPage
) => {
  return (
    <AuthenticatedLayout
      pageTitle="Client Dashboard"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<ClientDashboard/>}
    />
  );
};
