import { FC } from "react";

import {
  EnhancedDropDownRHFWithLabel,
  ITest,
  LabelPosition,
  RHookFormTextFieldWithLabel,
} from "@websential/cosmic";
import { useGetLookupFieldData } from "hooks/useLookupFields";

interface IFilingDataSetupFieldsPanel extends ITest {
  formMethods:any
}

export const FilingDataSetupFieldsPanel: FC<IFilingDataSetupFieldsPanel> = ({
  testId,
  formMethods,
}) => {
  const { isLoading: isLoadingPayPeriods, data: payPeriodsList } =
    useGetLookupFieldData("payPeriods");

    const onPayPeriodChangeHandler = (value : any) => {
      formMethods.setValue('pensionablePeriod',value.value)
    }
  return (
    <>
      <EnhancedDropDownRHFWithLabel
        label="Pay Periods"
        selectName="payPeriod"
        items={payPeriodsList ?? []}
        selectedItem={26}
        testId="pay-periods"
        labelPosition={LabelPosition.Left}
        onSelectionChangeHandler={onPayPeriodChangeHandler}
        required
      />
      <RHookFormTextFieldWithLabel
        label="Pensionable Periods"
        id="pensionablePeriod"
        testId="pensionable-periods"
        labelPosition={LabelPosition.Left}
        // value="26"
        defaultValue={26}
        required
      />
      {/* <EnhancedDropDownRHFWithLabel
        label="Slip Type"
        selectName="slipType"
        items={[
          { label: "Original", value: 400 },
          { label: "Amended", value: 200 },
          { label: "Cancelled", value: 300 },
          { label: "Additional", value: 100 },
        ]}
        selectedItem={400}
        testId="slip-type"
        labelPosition={LabelPosition.Left}
        required
      /> */}
      <EnhancedDropDownRHFWithLabel
        label="Status"
        selectName="slipStatus"
        items={[
          { label: "Draft", value: 200 },
          { label: "Completed", value: 100 },
        ]}
        selectedItem={200}
        testId="status"
        labelPosition={LabelPosition.Left}
        required
      />
    </>
  );
};
