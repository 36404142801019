import { AxiosError, AxiosResponse } from "axios";
import { ICreateNewPasswordForm, IForgotPasswordForm } from "components/ForgotPassword";
import { IRegister, IRegisterResponse } from "components/CreateAnAccount/IRegister";
import { IResponse, IServerError, ISignInRequest, ISignInResponse } from "models";

import { ICreateAnAccountForm } from "components/CreateAnAccount";
import { IUserAuthentication } from "components/UserAuthentication";
import { LocalStorageService } from "services";
import http from "../common/http-common";

export const AuthService = {
  signIn: async (
    requestPayload: ISignInRequest
  ): Promise<ISignInResponse> => {
    let loginDetail: ISignInRequest = {
      ...requestPayload
    };

    const response = await http.post<ISignInResponse>("/login", loginDetail);
    if (response.status === 200) {
      LocalStorageService.setUser(response.data);
      return response.data;
    }

    return response.data;
  },
  signOut: async () => {
    // localStorage.clear();
    try {
      const response:any = await http.get<any>(
      "/logout"
      );
    if (response.status === 200) return response.data;
    else if (response.status === 401) return response.data;
      } catch (error) {
        const axiosError = error as AxiosError;
        console.error("An error occurred:", axiosError);
        return {
          status: false,
          message: axiosError.response
            ? axiosError.response.data
            : "Unknown error",
        };
      }
  },
  maskData: async () => {
    const responseMaskData:any = await http.put<any>(
      "/v1/masking-unmasking/"
      );
    if (responseMaskData.status === 200) return responseMaskData.data;
  },
  register: async (
    requestPayload: ICreateAnAccountForm
  ): Promise<ICreateAnAccountForm | IServerError | IResponse> => {
    const response: AxiosResponse<ICreateAnAccountForm> = await http.post<ICreateAnAccountForm>(
      "/register",
      requestPayload
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  authenticationData: async (key:string | undefined): Promise<any | IServerError | IResponse> => {
  if(key){  
    try {
    const response: AxiosResponse<any> = await http.get<any>(
      `/user_detail/${key}`
    );
    console.log("responsedh",response)

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 404) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  verifyEmail: async (key:string | undefined): Promise<any | IServerError | IResponse> => {
    if(key){
    let payload = {
      email:key
    }
    const response: AxiosResponse<ICreateAnAccountForm> = await http.post<ICreateAnAccountForm>(
      "/verify-email",
      payload
    );

    if (response.status === 200) {
      return response;
    } else if (response.status === 400) {
      return response;
    } else if (response.status === 404) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  authentication: async (
    requestPayload: IUserAuthentication | any
  ): Promise<IUserAuthentication | IServerError | IResponse> => {
    console.log("requestPayload0-",requestPayload)
    const response: AxiosResponse<IUserAuthentication> = await http.post<IUserAuthentication>(
      "/forgot-password",
      requestPayload
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  forgetPassword: async (
    requestPayload: IForgotPasswordForm | ICreateNewPasswordForm | any
  ): Promise<IForgotPasswordForm | IServerError | IResponse | ICreateNewPasswordForm> => {
    console.log("requestPayload",requestPayload)
    if(requestPayload.key){
      let payload = {
        // email: requestPayload.email,
        email: requestPayload.key,
        newPassword: requestPayload.newPassword,
        confirmNewPassword: requestPayload.confirmNewPassword
      }
      console.log("payload when key",payload)
      const response: AxiosResponse<IForgotPasswordForm> = await http.post<IForgotPasswordForm>(
        "/forgot-password",
        payload
      );

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 400) {
        return response.data;
      }else if (response.status === 404) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    const response: AxiosResponse<IForgotPasswordForm> = await http.post<IForgotPasswordForm>(
      `/send-email?email=${requestPayload.email}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
};