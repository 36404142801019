import React, { FC } from "react";

import {
  Box,
  Drawer as MuiDrawer,
  styled,
  Theme,
  Toolbar,
  useTheme,
} from "@mui/material";
import { ITest } from "@websential/cosmic";

import { drawerWidth } from "../../constants";

// const StyledDrawer = styled(MuiDrawer, {
//   name: "StyledPaper",
//   slot: "Wrapper"
// })((props) => ({
//   color: "#6B8068",
//   backgroundColor: props.theme.palette.primary.main,
//   width: drawerWidth,
//   overflow: "hidden",
//   display: 'flex',
//   flexDirection: "column",
//   flexShrink: 0,
// }));

export interface IEnhancedDrawer extends ITest {
  /**
   * Header to be display in drawer (should be a react component)
   */
  drawerHeader: React.ReactNode;
  /**
   * Content to be display in drawer (should be a react component)
   */
  drawerContent?: React.ReactNode;
  /**
   * Footer to be display in drawer (should be a react component)
   */
  drawerFooter?: React.ReactNode;
}

export const EnhancedDrawer: FC<IEnhancedDrawer> = ({
  drawerHeader,
  drawerContent,
  drawerFooter,
  testId,
}: IEnhancedDrawer) => {
  const theme = useTheme();
  return (
    <Box sx={{
      display:"flex",
      flexDirection: "column",
    }}>
    <MuiDrawer
      variant="permanent"
      anchor="left"
      data-testid={testId}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: theme.palette.primary.main,
        },
          // flexDirection: "column",
        //   overflow: "hidden",
       
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "start",
          alignItems: "center",
          paddingTop: theme.spacing(2),
          paddingLeft: theme.spacing(1),
        }}
      >
        {drawerHeader}
      </Toolbar>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          marginTop: theme.spacing(1),
          overflow: "auto",
          '&::-webkit-scrollbar': {
              width: '0.4em',
          },
          '&::-webkit-scrollbar-thumb': {
              background: "#ccc",
              borderRadius: "3px",
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: "#aaa"
          },
          '& .MuiCollapse-root .css-272ig2-MuiList-root':{
            paddingLeft:"48px !important"
          },
          '& .MuiCollapse-root .css-12hxwxr':{
            paddingLeft:"48px !important"
          }
        }}
        data-testid={testId + "-content"}
      >
        {drawerContent}
      </Box>
      <div
        style={{
          display: "flex",
          height: "10vh",
          justifyContent: "center",
          alignItems: "center",
          color:"#fff",
          fontWeight:"bold"
        }}
        data-testid={testId + "-footer"}
      >
        {drawerFooter}
      </div>
    </MuiDrawer></Box>
  );
};
