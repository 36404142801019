import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { FC } from "react";

import { Grid } from "@mui/material";
import { ITest, RHookFormTextFieldWithLabel } from "@websential/cosmic";

interface ICPPSettingsPanel extends ITest {
  selectedYear:number | string;
}

export const CPPSettingsPanel: FC<ICPPSettingsPanel> = ({ testId, selectedYear }) => {
  return (
    <Grid
      container
      columnSpacing="6"
      rowSpacing="2"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Total CPP Pensionable Earnings (Maximum)"
          id="cppSettingsTotalCppPensionableEarningsMaximum"
          testId={`${testId}-total-cpp-pensionable-earnings-maximum`}
        />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Basic CPP Exemption (Maximum)"
          id="cppSettingsBasicCppExemptionMaximum"
          testId={`${testId}-basic-cpp-exemption-maximum`}
        />
      </Grid>
      {selectedYear && selectedYear > 2023 ?
      <>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Total Second CPP Pensionable Earnings (Maximum)"
          id="cppSettingsTotalSecondCppPensionableEarningsMaximum"
          testId={`${testId}-total-second-cpp-pensionable-earnings-maximum`}
        />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Second CPP Contribution Rate (%)"
          id="cppSettingsRequiredSecondContributionPercentage"
          testId={`${testId}-basic-second-cpp-exemption-maximum`}
        />
      </Grid></>
      :<></>}
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="Required Contribution (%)"
            id="cppSettingsRequiredContributionPercentage"
            testId={`${testId}-required-contribution-percentage`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}></Grid>
    </Grid>
  );
};
