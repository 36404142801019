import { FC } from "react";

import { Grid } from "@mui/material";
import { ITest, RHookFormTextFieldWithLabel } from "@websential/cosmic";

interface IT3VariablesPanel extends ITest {}

export const T3VariablesPanel: FC<IT3VariablesPanel> = ({ testId }) => {
  return (
    <Grid
      container
      columnSpacing="6"
      rowSpacing="2"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Eligible Taxable Amount (%)"
          id="eligibleDividendTaxCreditPercentageOfTaxableAmountT3"
          testId={`${testId}-cnt-rate-percentage`}
        />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Eligible Gross Up Factor (%)"
          id="eligibleGrossUpFactorT3"
          testId={`${testId}-wsdrf-rate-percentage`}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Non Eligible Taxable Amount (%)"
          id="nonEligibleDividendTaxCreditPercentageOfTaxableAmountT3"
          testId={`${testId}-wsdrf-rate-percentage`}
        />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Non Eligible Gross Up Factor (%)"
          id="nonEligibleGrossUpFactorT3"
          testId={`${testId}-wsdrf-rate-percentage`}
        />
      </Grid>
    </Grid>
  );
};
