import { CSV_INFORMATION_UI } from "store/types/CSVInformationUITypes";

export interface ICSVInformationUI {
  tag: string,
  data: any
}

const initialState: ICSVInformationUI = {
  tag:"",
  data:""
};

export const CSVInformationUIReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CSV_INFORMATION_UI.CSV_INFORMATION_UI_SET_SELECTED:
      return {
        ...state,
        tag: action.payload.tag,
        data: action.payload.data,
      }
    case CSV_INFORMATION_UI.CSV_INFORMATION_UI_CLEAR_SELECTED:
      return {
        ...state,
        tag: initialState.tag,
        data: initialState.data,
      }
    default:
      return state;
  }
};
