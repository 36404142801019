import { ACTIVITY_LOG } from "store/types/activityLogTypes";

export const setSelectedActivityLogActions = (selectTaxYear: any) => {
  return {
    type: ACTIVITY_LOG.ACTIVITY_LOG_SET_SELECTED,
    payload: {...selectTaxYear}
  }
}

export const clearSelectedActivityLogActions = () => {
  return {
    type: ACTIVITY_LOG.ACTIVITY_LOG_CLEAR_SELECTED
  }
}
