import { Item } from "components/LeftMenu";

import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SnippetFolderOutlinedIcon from "@mui/icons-material/SnippetFolderOutlined";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import TextFieldsOutlinedIcon from "@mui/icons-material/TextFieldsOutlined";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { BillingMenu } from "./BillingRoutes";
import { FlexFieldMenu } from "./FlexFieldRoutes";
import { TaxSlipMenu } from "./TaxSlipRoutes";
import { PdfMenu } from "./PdfRoutes";
import UploadFileIcon from '@mui/icons-material/UploadFile';

export const ArrayData: Item[] = [
  {
    id: 100,
    text: "Home",
    open: true,
    icon: <HomeOutlinedIcon />,
    menu: [
      {
        id: 101,
        text: "Super User",
        navigateToURL: "/accountants",
        parentMenuId: 100,
      },
      {
        id: 102,
        text: "Global Search",
        navigateToURL: "/global-search",
        parentMenuId: 100,
      },
    ],
  },
  {
    id: 200,
    text: "Accountant",
    open: false,
    icon: <ContactMailOutlinedIcon />,
    menu: [
      {
        id: 201,
        text: "List",
        navigateToURL: "/accountants",
        parentMenuId: 200,
      },
      {
        id: 202,
        text: "Setup",
        navigateToURL: "/accountants/setup",
        parentMenuId: 200,
      },
    ],
  },
  {
    id: 300,
    text: "Year",
    open: false,
    icon: <LocalOfferOutlinedIcon />,
    navigateToURL: "/years",
  },
  {
    id: 400,
    text: "Data Roles",
    open: false,
    icon: <SnippetFolderOutlinedIcon />,
    navigateToURL: "/data-roles",
  },
  {
    id: 1000,
    text: "Lookup Fields",
    open: false,
    icon: <SpellcheckIcon />,
    navigateToURL: "/lookup-fields",
  },
  {
    id: 500,
    text: "Global Variables",
    open: false,
    icon: <SettingsOutlinedIcon />,
    navigateToURL: "/global-variables",
  },
  {
    id: 600,
    text: "Flex Fields",
    open: false,
    icon: <TextFieldsOutlinedIcon />,
    menu: <FlexFieldMenu />,
  },
  {
    id: 900,
    text: "TaxSlips",
    open: false,
    icon: <DoneAllIcon />,
    menu: <TaxSlipMenu />,
  },
  {
    id: 1000,
    text: "PDF Settings",
    open: false,
    icon: <PictureAsPdfIcon />,
    menu: <PdfMenu />,
  },
  // {
  //   id: 1000,
  //   text: "PDF Settings",
  //   open: false,
  //   icon: <PictureAsPdfIcon />,
  //   menu: [
  //     {
  //       id: 1011,
  //       text: "PDF Files",
  //       open: false,
  //       icon: <UploadFileIcon />,
  //       navigateToURL: "/pdf-files",
  //       parentMenuId: 1000,
  //     },
  //     {
  //       id: 1012,
  //       text: "PDF Axis",
  //       open: false,
  //       icon: <LocalOfferOutlinedIcon />,
  //       navigateToURL: "/pdf-axis",
  //       parentMenuId: 1000,
  //     },
  //   ],
  // },
  {
    id: 700,
    text: "Billing",
    open: false,
    icon: <AttachMoneyOutlinedIcon />,
    menu: <BillingMenu />,
  },
  {
    id: 800,
    text: "Report",
    open: false,
    icon: <ListAltOutlinedIcon />,
    menu: [
      {
        id: 801,
        text: "Invoice Report",
        parentMenuId: 800,
        navigateToURL: "/admin-reports/invoices",
      },
      {
        id: 802,
        text: "Update Invoice",
        parentMenuId: 800,
        navigateToURL: "/admin-reports/update-invoice",
      },
      {
        id: 803,
        text: "Transactions Report",
        parentMenuId: 800,
        navigateToURL: "/admin-reports/transactions-report",
      },
      {
        id: 804,
        text: "Journal Code Setup",
        parentMenuId: 800,
        navigateToURL: "/admin-reports/journal-code",
      },
      {
        id: 805,
        text: "Journal Export",
        parentMenuId: 800,
        navigateToURL: "/admin-reports/journal-export",
      },
    ],
  },
  {
    id: 100000,
    text: "Logout",
    icon: <LogoutOutlinedIcon />,
    navigateToURL: "/logout",
  },
];
