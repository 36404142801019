import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IT4ASLipAxisPage2 {}
const T4ASLipAxisPage2:React.FC<IT4ASLipAxisPage2> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'slipTypeP2X'} 
                testId={'slipTypeP2X'} 
                label={'Slip Type'} 
                labelPosition={LabelPosition.Left}
                defaultValue={420}
            />
            <RHookFormTextFieldWithLabel 
                id={'taxYearP2X'} 
                testId={'taxYearP2X'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={308}
            />
            <RHookFormTextFieldWithLabel 
                id={'incomeTaxDeductedP2X'} 
                testId={'incomeTaxDeductedP2X'} 
                label={'Income Tax Deducted'} 
                labelPosition={LabelPosition.Left}
                defaultValue={545.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'selfEmployedCommissionsP2X'} 
                testId={'selfEmployedCommissionsP2X'} 
                label={'Self Employed Commissions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={546.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'feesP2X'} 
                testId={'feesP2X'} 
                label={'Fees'} 
                labelPosition={LabelPosition.Left}
                defaultValue={546.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'pensionOrSuperannuationP2X'} 
                testId={'pensionOrSuperannuationP2X'} 
                label={'Pension Or Superannuation'} 
                labelPosition={LabelPosition.Left}
                defaultValue={523}
            />
            <RHookFormTextFieldWithLabel 
                id={'lumpSumPaymentsP2X'} 
                testId={'lumpSumPaymentsP2X'} 
                label={'LumpSum Payments'} 
                labelPosition={LabelPosition.Left}
                defaultValue={523}
            />
            <RHookFormTextFieldWithLabel 
                id={'annuitiesP2X'} 
                testId={'annuitiesP2X'} 
                label={'Annuities'} 
                labelPosition={LabelPosition.Left}
                defaultValue={523}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P2X'} 
                testId={'otherInfoAmount1P2X'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={149}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P2X'} 
                testId={'otherInfoCode1P2X'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={66}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P2X'} 
                testId={'otherInfoAmount2P2X'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={149}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P2X'} 
                testId={'otherInfoCode2P2X'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={66}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P2X'} 
                testId={'otherInfoAmount3P2X'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={281.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P2X'} 
                testId={'otherInfoCode3P2X'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={197.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P2X'} 
                testId={'otherInfoAmount4P2X'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={281.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P2X'} 
                testId={'otherInfoCode4P2X'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={197.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount5P2X'} 
                testId={'otherInfoAmount5P2X'} 
                label={'Other Info Amount 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={427}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode5P2X'} 
                testId={'otherInfoCode5P2X'} 
                label={'Other Info Code 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={340.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount6P2X'} 
                testId={'otherInfoAmount6P2X'} 
                label={'Other Info Amount 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={427}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode6P2X'} 
                testId={'otherInfoCode6P2X'} 
                label={'Other Info Code 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={340.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount7P2X'} 
                testId={'otherInfoAmount7P2X'} 
                label={'Other Info Amount 7'} 
                labelPosition={LabelPosition.Left}
                defaultValue={427}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode7P2X'} 
                testId={'otherInfoCode7P2X'} 
                label={'Other Info Code 7'} 
                labelPosition={LabelPosition.Left}
                defaultValue={340.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount8P2X'} 
                testId={'otherInfoAmount8P2X'} 
                label={'Other Info Amount 8'} 
                labelPosition={LabelPosition.Left}
                defaultValue={427}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode8P2X'} 
                testId={'otherInfoCode8P2X'} 
                label={'Other Info Code 8'} 
                labelPosition={LabelPosition.Left}
                defaultValue={340.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount9P2X'} 
                testId={'otherInfoAmount9P2X'} 
                label={'Other Info Amount 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={555.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode9P2X'} 
                testId={'otherInfoCode9P2X'} 
                label={'Other Info Code 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={472}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount10P2X'} 
                testId={'otherInfoAmount10P2X'} 
                label={'Other Info Amount 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={555.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode10P2X'} 
                testId={'otherInfoCode10P2X'} 
                label={'Other Info Code 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={472}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount11P2X'} 
                testId={'otherInfoAmount11P2X'} 
                label={'Other Info Amount 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={555.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode11P2X'} 
                testId={'otherInfoCode11P2X'} 
                label={'Other Info Code 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={472}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount12P2X'} 
                testId={'otherInfoAmount12P2X'} 
                label={'Other Info Amount 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={555.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode12P2X'} 
                testId={'otherInfoCode12P2X'} 
                label={'Other Info Code 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={472}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceCityAddressP2X'} 
                testId={'resourceCityAddressP2X'} 
                label={'Resource City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={60}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceAddressP2X'} 
                testId={'resourceAddressP2X'} 
                label={'Resource Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={60}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersCityAddressP2X'} 
                testId={'employersCityAddressP2X'} 
                label={'Employers City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={60}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersAddressP2X'} 
                testId={'employersAddressP2X'} 
                label={'Employers Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={60}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersNameP2X'} 
                testId={'employersNameP2X'} 
                label={'Employers Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={60}
            />
            <RHookFormTextFieldWithLabel 
                id={'initialsP2X'} 
                testId={'initialsP2X'} 
                label={'Initials'} 
                labelPosition={LabelPosition.Left}
                defaultValue={305}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceLastNameP2X'} 
                testId={'resourceLastNameP2X'} 
                label={'Resource Last Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={216}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceFirstNameP2X'} 
                testId={'resourceFirstNameP2X'} 
                label={'Resource First Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={72}
            />
            <RHookFormTextFieldWithLabel 
                id={'accountNoP2X'} 
                testId={'accountNoP2X'} 
                label={'Account No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={210}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsuranceNumberP2X'} 
                testId={'socialInsuranceNumberP2X'} 
                label={'Social Insurance Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={65}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAccountNoP2X'} 
                testId={'employerAccountNoP2X'} 
                label={'Employer Account No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={65}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'slipTypeP2Y'} 
                testId={'slipTypeP2Y'} 
                label={'Slip Type'} 
                labelPosition={LabelPosition.Left}
                defaultValue={588}
            />
            <RHookFormTextFieldWithLabel 
                id={'taxYearP2Y'} 
                testId={'taxYearP2Y'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={569}
            />
            <RHookFormTextFieldWithLabel 
                id={'incomeTaxDeductedP2Y'} 
                testId={'incomeTaxDeductedP2Y'} 
                label={'Income Tax Deducted'} 
                labelPosition={LabelPosition.Left}
                defaultValue={503}
            />
            <RHookFormTextFieldWithLabel 
                id={'selfEmployedCommissionsP2Y'} 
                testId={'selfEmployedCommissionsP2Y'} 
                label={'Self Employed Commissions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={462}
            />
            <RHookFormTextFieldWithLabel 
                id={'feesP2Y'} 
                testId={'feesP2Y'} 
                label={'Fees'} 
                labelPosition={LabelPosition.Left}
                defaultValue={414}
            />
            <RHookFormTextFieldWithLabel 
                id={'pensionOrSuperannuationP2Y'} 
                testId={'pensionOrSuperannuationP2Y'} 
                label={'Pension Or Superannuation'} 
                labelPosition={LabelPosition.Left}
                defaultValue={503}
            />
            <RHookFormTextFieldWithLabel 
                id={'lumpSumPaymentsP2Y'} 
                testId={'lumpSumPaymentsP2Y'} 
                label={'LumpSum Payments'} 
                labelPosition={LabelPosition.Left}
                defaultValue={462}
            />
            <RHookFormTextFieldWithLabel 
                id={'annuitiesP2Y'} 
                testId={'annuitiesP2Y'} 
                label={'Annuities'} 
                labelPosition={LabelPosition.Left}
                defaultValue={414}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P2Y'} 
                testId={'otherInfoAmount1P2Y'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={279}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P2Y'} 
                testId={'otherInfoCode1P2Y'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={279}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P2Y'} 
                testId={'otherInfoAmount2P2Y'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={248}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P2Y'} 
                testId={'otherInfoCode2P2Y'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={248}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P2Y'} 
                testId={'otherInfoAmount3P2Y'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={279}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P2Y'} 
                testId={'otherInfoCode3P2Y'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={279}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P2Y'} 
                testId={'otherInfoAmount4P2Y'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={248}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P2Y'} 
                testId={'otherInfoCode4P2Y'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={248}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount5P2Y'} 
                testId={'otherInfoAmount5P2Y'} 
                label={'Other Info Amount 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={349}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode5P2Y'} 
                testId={'otherInfoCode5P2Y'} 
                label={'Other Info Code 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={349}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount6P2Y'} 
                testId={'otherInfoAmount6P2Y'} 
                label={'Other Info Amount 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={314}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode6P2Y'} 
                testId={'otherInfoCode6P2Y'} 
                label={'Other Info Code 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={314}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount7P2Y'} 
                testId={'otherInfoAmount7P2Y'} 
                label={'Other Info Amount 7'} 
                labelPosition={LabelPosition.Left}
                defaultValue={279}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode7P2Y'} 
                testId={'otherInfoCode7P2Y'} 
                label={'Other Info Code 7'} 
                labelPosition={LabelPosition.Left}
                defaultValue={279}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount8P2Y'} 
                testId={'otherInfoAmount8P2Y'} 
                label={'Other Info Amount 8'} 
                labelPosition={LabelPosition.Left}
                defaultValue={248}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode8P2Y'} 
                testId={'otherInfoCode8P2Y'} 
                label={'Other Info Code 8'} 
                labelPosition={LabelPosition.Left}
                defaultValue={248}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount9P2Y'} 
                testId={'otherInfoAmount9P2Y'} 
                label={'Other Info Amount 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={349}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode9P2Y'} 
                testId={'otherInfoCode9P2Y'} 
                label={'Other Info Code 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={349}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount10P2Y'} 
                testId={'otherInfoAmount10P2Y'} 
                label={'Other Info Amount 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={314}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode10P2Y'} 
                testId={'otherInfoCode10P2Y'} 
                label={'Other Info Code 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={314}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount11P2Y'} 
                testId={'otherInfoAmount11P2Y'} 
                label={'Other Info Amount 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={249}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode11P2Y'} 
                testId={'otherInfoCode11P2Y'} 
                label={'Other Info Code 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={249}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount12P2Y'} 
                testId={'otherInfoAmount12P2Y'} 
                label={'Other Info Amount 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={248}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode12P2Y'} 
                testId={'otherInfoCode12P2Y'} 
                label={'Other Info Code 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={248}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceCityAddressP2Y'} 
                testId={'resourceCityAddressP2Y'} 
                label={'Resource City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={360}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceAddressP2Y'} 
                testId={'resourceAddressP2Y'} 
                label={'Resource Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={375}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersCityAddressP2Y'} 
                testId={'employersCityAddressP2Y'} 
                label={'Employers City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={549}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersAddressP2Y'} 
                testId={'employersAddressP2Y'} 
                label={'Employers Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={561}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersNameP2Y'} 
                testId={'employersNameP2Y'} 
                label={'Employers Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={575}
            />
            <RHookFormTextFieldWithLabel 
                id={'initialsP2Y'} 
                testId={'initialsP2Y'} 
                label={'Initials'} 
                labelPosition={LabelPosition.Left}
                defaultValue={411}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceLastNameP2Y'} 
                testId={'resourceLastNameP2Y'} 
                label={'Resource Last Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={411}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceFirstNameP2Y'} 
                testId={'resourceFirstNameP2Y'} 
                label={'Resource First Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={411}
            />
            <RHookFormTextFieldWithLabel 
                id={'accountNoP2Y'} 
                testId={'accountNoP2Y'} 
                label={'Account No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={469}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsuranceNumberP2Y'} 
                testId={'socialInsuranceNumberP2Y'} 
                label={'Social Insurance Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={469}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAccountNoP2Y'} 
                testId={'employerAccountNoP2Y'} 
                label={'Employer Account No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={509}
            />
        </Grid>
    </Grid>
  )
}

export default T4ASLipAxisPage2