import { all, takeEvery } from "redux-saga/effects";
import { ACCOUNTANT, CLIENT, LOGIN } from "store/types";

import { getAccountants } from "./accountantSagas";
// import { getClients } from "./accountantSagas";
import { generateAPIKeys, getEmployees, getVendors } from "./clientSagas";
import { login } from "./loginSagas";

export default function* watch() {
  yield all([takeEvery(LOGIN.REQUEST, login)]);
  yield all([
    takeEvery(ACCOUNTANT.ACCOUNTANT_LIST_REQUEST_LOAD, getAccountants),
  ]);
  // yield all([takeEvery(ACCOUNTANT.CLIENT_LIST_REQUEST_LOAD, getClients)]);
  yield all([takeEvery(CLIENT.API_KEYS_REQUEST_LOAD, generateAPIKeys)]);
  yield all([takeEvery(CLIENT.API_KEYS_REQUEST_GENERATE, generateAPIKeys)]);
  yield all([takeEvery(CLIENT.EMPLOYEE_LIST_REQUEST_LOAD, getEmployees)]);
  yield all([takeEvery(CLIENT.VENDOR_LIST_REQUEST_LOAD, getVendors)]);
  // yield all([takeEvery(types.CATEGORYLIST_REQUEST, categoryListingSaga)]);
  // yield all([takeEvery(types.CATEGORYDETAILS_REQUEST, categoryDetailsSaga)]);
  // yield all([takeEvery(types.ORDERDETAILS_REQUEST, orderDetailsSaga)]);
  // yield all([takeEvery(types.PROFILE_REQUEST, profileSaga)]);
  // yield all([takeEvery(types.GOOGLE_SIGN_UP_REQUEST, signUpSaga)]);
  // yield all([takeEvery(types.LEDGER_REQUEST, ledgerSaga)]);
  // yield all([takeEvery(types.NOTIFICATIONS_REQUEST, notificationsSaga)]);
  // yield all([takeEvery(PRODUCTS.SEARCH_REQUEST, searchProducts)]);
  // yield all([takeEvery(PRODUCTS.PRODUCT_DETAIL_LOAD_REQUEST, getProduct)]);
}
