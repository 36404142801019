import React, { useState, useRef, useEffect, useCallback } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import "./T4Reportstyles.css"
import "./T4ReportstylesAbove2023.css"
import { RequestParams } from '@websential/cosmic';
import SearchIcon from '@mui/icons-material/Search';
import { IAppState } from 'store/reducers';
import { useSelector } from 'react-redux';

export interface IDataTable {
    rows: any;
    columns:any;
    last_rows:any;
    showIndexColumn:boolean;
    showChecboxColumn: boolean,
    showSettingColumn: boolean,
    isLoading?: boolean;
    onRequestParamsChange?: (params: RequestParams) => void;
    initialPageSize?: number | undefined;
    initialPage?: number;
    totalRecords?: number;
}

const PrimeDataGrid: React.FC<IDataTable> = ({
    rows,
    columns,
    last_rows,
    showIndexColumn,
    showChecboxColumn,
    showSettingColumn,
    onRequestParamsChange,
    initialPageSize = 10,
    initialPage = 0,
    totalRecords,
    isLoading
}) => {
    const isInitialRequest = useRef(true);
    const [loading, setLoading] = useState(false);
    const taxYear  = useSelector((state: IAppState) => state.taxYear.year);
    const [itemSearch, setItemSearch] = useState('');
    const [requestParams, setRequestParams] = useState<any>({
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        sortField: null,
        sortOrder: "asc",
        searchQuery:"",
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

    console.log("requestParams--",requestParams)

    
    const onSearchButtonClicked = () => {
      setLoading(true)
      setRequestParams((prevParams:any) => ({
        ...prevParams,
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        searchQuery: itemSearch,
      }));
      setTimeout(() => { 
        setLoading(false);
      }, 1000);
    }
    const handleClearSearch = () => {
      setLoading(true)
      setItemSearch('')
      setRequestParams((prevParams:any) => ({
        ...prevParams,
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        searchQuery: "",
      }));
      setTimeout(() => { 
        setLoading(false);
      }, 1000);
    }
// Search type and billing profile Button
    const renderHeader = () => {
        return (
            // <div className='flex justify-content-between p-4 pl-0'  style={{backgroundColor:"#ebecf0",margin:"-17px"}}>
                <span className="p-input-icon-left w-5" style={{display:"flex"}}>
                    <i className="pi pi-search" />
                    <InputText value={itemSearch} onChange={(e) => setItemSearch(e.target.value)} placeholder="Search by Employee Name" className='w-full' style={{borderRadius:"5px 0px 0px 5px"}}/>
                    {itemSearch && (
                        <Button
                        icon="pi pi-times"
                        onClick={handleClearSearch}
                        className="p-button-warning"
                        style={{borderRadius:0}}
                        />
                    )}
                    <Button
                        onClick={onSearchButtonClicked}
                        // variant="contained"
                        style={{
                          padding:"8px 10px 8px 10px",
                          marginRight:"-14px!important",
                          borderRadius:"0px 5px 5px 0px"}}
                      >
                        <SearchIcon />
                      </Button>
                </span>
            // </div>
            )
    }

    const indexColumn = {
        body: (rowData:any, row:any) => {
          return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
        },
        header: '#',
      };

    const headerSearch = renderHeader();
    
      const headerGroup = (
        <ColumnGroup>
          <Row>
            <Column header="" colSpan={1} style={{cursor:"auto",position: "sticky",left: 0,zIndex:1,backgroundColor:"#e3fcef"}}/>
            {/* <Column header="Employee Info" colSpan={2}  frozen={true} style={{paddingLeft:"4rem"}}/> */}
            <Column header="Employee Info" colSpan={1} className='align-header-group-column-text' style={{cursor:"auto",position: "sticky",left: "28px",zIndex:1,backgroundColor:"#e3fcef"}}/>
            <Column header="Slip Info" colSpan={3} className='align-header-group-column-text' style={{cursor:"auto",backgroundColor:"#e3fcef"}}/>
            <Column header="Canadian Pension Plan" colSpan={taxYear > 2023 ? 7 : 4} className='align-header-group-column-text' style={{cursor:"auto",backgroundColor:"#fffae6"}}/>
            <Column header="Employment Insurance" colSpan={4} className='align-header-group-column-text' style={{cursor:"auto",backgroundColor:"#ffebe6"}}/>
            <Column header="Income Tax" colSpan={4} className='align-header-group-column-text' style={{cursor:"auto",backgroundColor:"#deebff"}}/>
            <Column header="" colSpan={1} className='align-header-group-column-text' style={{cursor:"auto"}}/>
          </Row>
          <Row>
            <Column selectionMode="multiple" hidden={showChecboxColumn} frozen resizeable={false}
                style={{maxWidth:"65px"}} field="selection" columnKey='selection'></Column>
            <Column 
                {...indexColumn} 
                frozen
                hidden={!showIndexColumn}
                className='indexColumn'
                style={{backgroundColor:"#e3fcef"}}
                />
            {columns !== undefined  &&  columns.map((x:any,index:any) => (
                    <Column
                        field={x.field}
                        header={x.headerName}
                        headerTooltip={x.description}
                        headerTooltipOptions={{ position: 'top' }}
                        sortable={x.sortable}
                        resizeable={x.resizable}
                        dataType={x.type}
                        align={x.align}
                        alignHeader={x.headerAlign}
                        reorderable={x.disableReorder}
                        exportable={x.disableExport}
                        frozen={x.frozenColumn}
                        columnKey={x.field}
                        style={{
                                // whiteSpace: 'pre-line'
                               }}
                        />
                ))}
          </Row>
        </ColumnGroup>

      );


const updatedItemsResponse = rows && rows?.map((item:any) => ({
    ...item,
    adjusted: item.adjusted ? "Yes" : "No",
    gross: item.gross !== undefined ? parseFloat(item.gross).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    pensionableEarnings: item.pensionableEarnings !== undefined ? parseFloat(item.pensionableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    cppEntered: item.cppEntered !== undefined ? parseFloat(item.cppEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    secondCppEntered: item.secondCppEntered !== undefined && item.secondCppEntered !== null ? parseFloat(item.secondCppEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    cppAdjusted: item.cppAdjusted !== undefined ? parseFloat(item.cppAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    secondCppAdjusted: item.secondCppAdjusted !== undefined && item.secondCppAdjusted !== null ? parseFloat(item.secondCppAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    cppDifference: item.cppDifference !== undefined ? parseFloat(item.cppDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    secondCppDifference: item.secondCppDifference !== undefined && item.secondCppDifference !== null ? parseFloat(item.secondCppDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    insurableEarnings: item.insurableEarnings !== undefined ? parseFloat(item.insurableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    eiEntered: item.eiEntered !== undefined ? parseFloat(item.eiEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    eiAdjusted: item.eiAdjusted !== undefined ? parseFloat(item.eiAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    eiDifference: item.eiDifference !== undefined ? parseFloat(item.eiDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    t4TaxEntered: item.t4TaxEntered !== undefined ? parseFloat(item.t4TaxEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    t4TaxCalculated: item.t4TaxCalculated !== undefined ? parseFloat(item.t4TaxCalculated).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    t4TaxAdjusted: item.t4TaxAdjusted !== undefined ? parseFloat(item.t4TaxAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    t4TaxDifference: item.t4TaxDifference !== undefined ? parseFloat(item.t4TaxDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    totalDifference: item.totalDifference !== undefined ? parseFloat(item.totalDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
}));

const updatedLastRowsResponse = last_rows && last_rows?.map((item:any) => ({
    ...item,
    gross: item.gross !== undefined ? parseFloat(item.gross).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    pensionableEarnings: item.pensionableEarnings !== undefined ? parseFloat(item.pensionableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    cppEntered: item.cppEntered !== undefined ? parseFloat(item.cppEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    secondCppEntered: item.secondCppEntered !== undefined && item.secondCppEntered !== null ? parseFloat(item.secondCppEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    cppAdjusted: item.cppAdjusted !== undefined ? parseFloat(item.cppAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    secondCppAdjusted: item.secondCppAdjusted !== undefined && item.secondCppAdjusted !== null ? parseFloat(item.secondCppAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    cppDifference: item.cppDifference !== undefined ? parseFloat(item.cppDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    secondCppDifference: item.secondCppDifference !== undefined && item.secondCppDifference !== null ? parseFloat(item.secondCppDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    insurableEarnings: item.insurableEarnings !== undefined ? parseFloat(item.insurableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    eiEntered: item.eiEntered !== undefined ? parseFloat(item.eiEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    eiAdjusted: item.eiAdjusted !== undefined ? parseFloat(item.eiAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    eiDifference: item.eiDifference !== undefined ? parseFloat(item.eiDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    t4TaxEntered: item.t4TaxEntered !== undefined ? parseFloat(item.t4TaxEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    t4TaxCalculated: item.t4TaxCalculated !== undefined ? parseFloat(item.t4TaxCalculated).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    t4TaxAdjusted: item.t4TaxAdjusted !== undefined ? parseFloat(item.t4TaxAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    t4TaxDifference: item.t4TaxDifference !== undefined ? parseFloat(item.t4TaxDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    totalDifference: item.totalDifference !== undefined ? parseFloat(item.totalDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
}));

const onPage = (event:any) => {
  // setRequestParams(event);
  setLoading(true)
  //imitate delay of a backend call
  setTimeout(() => {
  setRequestParams({
    ...event,
    searchQuery: itemSearch,
  });
  setLoading(false);
}, 1000);
};
const [sortField, setSortField] = useState(null);
const [sortOrder, setSortOrder] = useState(1);
const onSort = useCallback((event: any) => {
  setLoading(true);

  let newSortOrder : any;
  if (event.sortField === sortField) {
      // Toggle sortOrder between 1 and -1
      newSortOrder = sortOrder === 1 ? -1 : 1;
      setSortOrder(newSortOrder);
  } else {
      // Set the new sortField and reset sortOrder to 1
      newSortOrder = 1;
      setSortField(event.sortField);
      setSortOrder(newSortOrder);
  }

  console.log("sortOrder 33 out", newSortOrder); // Use the newSortOrder value directly
  setRequestParams((prevParams: any) => ({
      ...prevParams,
      sortOrder: newSortOrder !== 1 ? "asc" : "desc",
      sortField: event.sortField,
  }));

  // Imitate delay of a backend call
  setTimeout(() => {
      setLoading(false);
  }, 1000);
}, [sortOrder, sortField]);
// const onSort = (event:any) => {
//     setRequestParams(event);
// };

// const onFilter = (event:any) => {
//     event['first'] = 0;
//     setRequestParams(event);
// };

// This useEffect is triggered when the debouncedSearchQuery, pageSize or currentPage values change
useEffect(() => {
    // if the results fetcher was passed, we can use it to fetch the results.
    if (onRequestParamsChange) {
      // skip the first initial call
      if (isInitialRequest.current === true) {
        console.log(
          "##### => useEffect - requestParams - no first call",
          requestParams
        );
        isInitialRequest.current = false;
      } else {
        console.log(
          "##### => useEffect - requestParams - second call",
          requestParams
        );
        onRequestParamsChange(requestParams);
      }
    }
  }, [onRequestParamsChange, requestParams]);

  

  console.log("totalRecords--",totalRecords)
  console.log("rows--",rows)
  console.log("updatedItemsResponse--",updatedItemsResponse)
  return (
    <div>
       <DataTable
            value={updatedItemsResponse && updatedItemsResponse}
            lazy 
            filterDisplay="row"
            dataKey="id" 
            paginator
            first={requestParams.first} 
            rows={requestParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            onSort={onSort}
            // onSort={onSort}
            // onFilter={onFilter}
            // id="aaa"
            // responsiveLayout="scroll"
            rowsPerPageOptions={[25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={`Showing {first} to {last} of ${totalRecords} Items`}
            // // selection={selectedItems} onSelectionChange={(e:any) => setSelectedItems(e.value)}
            // editMode="row"
            showGridlines
            resizableColumns 
            columnResizeMode="expand"
            scrollable
            scrollHeight={"70vh"} 
            // reorderableColumns
            loading={isLoading ? isLoading : loading}
            header={headerSearch}
            emptyMessage="No Record found."
            className={taxYear > 2023 ? 'myDataTableAbove2023' : "myDataTable"}
            headerColumnGroup={headerGroup}
            frozenValue={last_rows && updatedLastRowsResponse}
            style={{margin:"0.5rem"}}
            >
                <Column selectionMode="multiple" hidden={showChecboxColumn} frozen resizeable={false}
                style={{maxWidth:"65px"}} field="selection" columnKey='selection'></Column>
                {rows && <Column 
                    {...indexColumn} 
                    frozen
                    hidden={!showIndexColumn}
                    className='indexColumn'
                    />}
                

                {columns !== undefined  &&  columns.map((x:any,index:any) => (
                    <Column
                        key={index}
                        field={x.field}
                        header={x.headerName}
                        headerTooltip={x.description}
                        headerTooltipOptions={{ position: 'top' }}
                        // flex (This property is not use Yet)
                        // hidden={val === x.hide ? clickHideBtn(x, x.field, false) : x.field === field ? val: x.hide }
                        sortable={x.sortable}
                        // resizeable={x.resizable}
                        dataType={x.type}
                        align={x.align}
                        alignHeader={x.headerAlign}
                        // hideSortIcons (This property is not use Yet)
                        // filterElement={HideButton(x.hideable, x, x.field)}
                        // showFilterMenuOptions
                        // filter
                        // showFilterMenu={x.disableColumnMenu}
                        // filterPlaceholder="Search by name"
                        // reorderable={x.disableReorder}
                        // exportable={x.disableExport}
                        frozen={x.frozenColumn}
                        // columnKey={x.field}
                        style={{
                            // minWidth:x.minWidth,
                                // fontSize:"0.875rem",
                                // whiteSpace: 'pre-line'
                               }}
                        footer={x.footer}
                        // body={rows.employmentIncome}
                        />
                    // </>
                ))}

                <Column style={{width:"110px",maxWidth:"110px"}} hidden={showSettingColumn}
                resizeable={false} reorderable={false} frozen alignFrozen='right'></Column>
            </DataTable>
    </div>
  )
}

export default PrimeDataGrid

//resizeablbe must be false if frozen is true and viseversa
