// import { ArrayData as AdminAccountantRoutes } from "./routes/AdminAccountantRoutes";
// import { ArrayData as ClientListRoutes } from "./routes/ClientListRoutes";
// import { ArrayData as ClientListRoutesWithAcc } from "./routes/ClientListRoutesWithAcc";
// import { ArrayData as AccountantListRoutes } from "./routes/AccountantListRoutes";
// import { ArrayData as ClientRoutes } from "./routes/ClientRoutes";
// import { ArrayData as AdminClientRoutes } from "./routes/AdminClientRoutes";
// import { ArrayData as AdminRoutes } from "./routes/AdminRoutes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import "./RoleBasedTopMenuBar.css"
const useAccountants = (params: IGetListRequest) =>
  useQuery(
    ["accountants-list", params],
    (data) => {
      console.log("@@@@@@=>useAccountants", data);
      return AccountantService.getAccountants(params);
    },
    { refetchOnWindowFocus: false }
  );
import { IGetListRequest } from "models";
import { useQuery } from "react-query";
import { AccountantService } from "services";
import { setSelectedHomePage } from "store/actions";
import { Menubar } from "primereact/menubar";
import { AdminRoutesComponent } from "./routes/AdminRoutes";
import { AdminAccountantRoutesComponent } from "./routes/AdminAccountantRoutes";
import { ClientListRoutesComponent } from "./routes/ClientListRoutes";
import { ClientListRoutesWithAccComponent } from "./routes/ClientListRoutesWithAcc";
import { AccountantListRoutesComponent } from "./routes/AccountantListRoutes";
import { ClientRoutesComponent } from "./routes/ClientRoutes";
import { AdminClientRoutesComponent } from "./routes/AdminClientRoutes";
export const RoleBasedTopMenuBar = () => {
  const dispatch = useDispatch();
  const { AdminAccountantRoutes } = AdminAccountantRoutesComponent()
  const { ClientListRoutes } = ClientListRoutesComponent()
  const { ClientListRoutesWithAcc } = ClientListRoutesWithAccComponent()
  const { AccountantListRoutes } = AccountantListRoutesComponent()
  const { ClientRoutes } = ClientRoutesComponent()
  const { AdminClientRoutes } = AdminClientRoutesComponent()
  const { AdminRoutes } = AdminRoutesComponent()
  console.log("AdminRoutes123",AdminRoutes)
  let { isAdminUser, selectedAccountantId, selectedAccountantName, selectedClientId, selectedClientName, selectedYear, invoiceRefNo, CSVInformationUI, HomePagePath, selectedMenuItem,drawerAction } = useSelector(
    (state: IAppState) => {
      return {
        isAdminUser: state.user.profile.isSuperStatus,
        selectedAccountantId: state.accountant.id,
        selectedAccountantName: state.accountant.AccountantName,
        selectedClientId: state.client.id,
        selectedClientName: state.client.clientName,
        selectedYear: state.taxYear.year,
        invoiceRefNo: state.invoice.refNo,
        CSVInformationUI: state.CSVInformationUI,
        HomePagePath: state.HomePagePath.path,
        selectedMenuItem: state.navigation.selectedMenuItem,
        drawerAction:state.drawerAction.open
      };
    }
  );
  const { isLoading, data } = useAccountants({
    search_query: "",
    order_by: "acc_title",
    order_direction: "asc",
    page: 1,
    size: 25,
  });
  const accountantList: any = data;
  const [menu, setMenu] = useState<any>(AdminRoutes);
  useEffect(() => {
    if (isAdminUser !== 0) {
      if (selectedClientId && selectedClientId.length > 0) {
        dispatch(setSelectedHomePage({path:"/clients/dashboard"}));
        console.log("setting client routes with id : ",selectedClientId);
        setMenu(AdminClientRoutes);
      }
      else if (selectedAccountantId && selectedAccountantId.length > 0) {
        dispatch(setSelectedHomePage({path:"/accountants/clients"}));
        console.log("setting accountant routes");
        setMenu(AdminAccountantRoutes);
      }
      else {
        dispatch(setSelectedHomePage({path:"/global-search"}));
        console.log("setting admin routes");
        setMenu(AdminRoutes);
      }
    }
    else {
      if (selectedClientId && selectedClientId.length > 0) {
        dispatch(setSelectedHomePage({path:"/clients/dashboard"}));
        setMenu(ClientRoutes)
      }
      else if (selectedAccountantId && selectedAccountantId.length > 0) {
        dispatch(setSelectedHomePage({path:"/accountants/clients"}));
        console.log("inside ifffff12 sy phly ",accountantList)
        if(accountantList && accountantList.items && accountantList.items.length !== 0){
          console.log("inside ifffff12")
          setMenu(ClientListRoutesWithAcc);
        } else {
          setMenu(ClientListRoutes);
        }
      }
      else {
        dispatch(setSelectedHomePage({path:"/accountants"}));
        setMenu(AccountantListRoutes);
        }
    }
  }, [accountantList,selectedClientId,selectedAccountantId]);

  return <Menubar model={menu} className='menu-bar'/>;
};
