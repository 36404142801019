import { checkSINValidity } from 'components/T4Slip/T4SlipSetup/validator';
import * as yup from 'yup';
export const RL16SlipSetupValidationSchema = yup.object({
  recipientType: yup.number().nullable().required("Recipient Type is required"),
  initials: yup.string().nullable()
    .when('recipientType', {
      is: (value:number) => value === 10 || value === 20 || value === 60 || value === 70,
      then: yup.string().max(1, "maximum one character"),
      otherwise: yup.string().nullable()
    }),
  name1: yup.string().nullable()
    .when('recipientType', {
      is: (value:number) => value === 10 || value === 20 || value === 60 || value === 70,
      then: yup.string().required('First Name is required').max(12, "First Name has at most 12 characters"),
      otherwise: yup.string().nullable()
    }),
  name2: yup.string().nullable()
    .when('recipientType', {
      is: (value:number) => value === 10 || value === 20 || value === 60 || value === 70,
      then: yup.string().nullable().required('Last Name is required').max(20, "Last Name has at most 20 characters"),
      otherwise: yup.string().nullable()
    }),
  secondRecipientName: yup.string().nullable()
    .when('recipientType', {
      is: (value:number) => value === 20 || value === 60 || value === 70,
      then: yup.string().nullable().required('Second Recipient Name is required'),
      otherwise: yup.string().nullable()
    }),
  firstLineOfRecipientName: yup.string().nullable()
    .when('recipientType', {
      is: (value:number) => value === 30 || value === 40 || value === 50,
      then: yup.string().nullable().required('First Line Of Recipient Name is required').max(12, "First Line Of Recipient Name has at most 12 characters"),
      otherwise: yup.string().nullable()
    }),
    secondLineOfRecipientName: yup.string().nullable()
    .when('recipientType', {
      is: (value:number) => value === 30 || value === 40 || value === 50,
      then: yup.string().nullable().required('Second Line Of Recipient Name is required'),
      otherwise: yup.string().nullable()
    }),

    
    addressLine1: yup
    .string()
    .required("Address is required")
    .max(50, "Address has at most 50 characters"),

  city: yup
    .string()
    .required("City is required")
    .max(28, "City has at most 28 characters"),

  province: yup.string().nullable().required("Province is required"),
  consolidatedIndicator: yup.string().nullable().required("Consolidated Indicator is required"),
  // provinceOfEmployment: yup.string().nullable().required("Province of employment is required"),

  postalCode: yup
    .string()
    .required("PostCode is required"),

  country: yup.mixed().test(
    'is-string-or-object',
    'Input must be a string or an object.',
    (value) => typeof value === 'string' || typeof value === 'object'
  ).required("Country is required"),

  slipType: yup.number().required("slipType is required"),

  slipStatus: yup.number().required("slip Status is required"),


  transit: yup
  .string().nullable()
  .max(8, "Transit has at most 8 characters"),
  optionalTextToPrintOnTheSlip: yup
  .string().nullable()
  .max(11, "Optional Text has at most 11 characters"),
  beneficiaryNumber: yup
  .string().nullable()
  .max(20, "Beneficiary Number has at most 20 characters"),
recipientAccount: yup
  .string().nullable()
  .max(12, "Recipient Account has at most 12 characters"),

  uniqueIdentifier: yup.string().nullable()
    .when('recipientType', {
      is: (value:number) => value === 10 || value === 20 || value === 60 || value === 70,
      then:yup.string().nullable().test(
      'is-masked-or-not',
      'Invalid value',
      function (value:any) {
        let sin = value && value.replace(/-/g, '')
        const output = checkSINValidity(sin);
        console.log("output",output)
        let maxLength = 11;
  
        if (value && value.charAt(2) === '*') {
          maxLength = 7;
          if (value && value.length == maxLength) {
            return true;
          } else {
            return this.createError({
              message: 'Invalid Social Insuarance Number',
              path: this.path,
            });
          }
        } else {
          if (value && value.length == maxLength && output % 10 === 0) {
            return true;
          } else {
            return this.createError({
              message: 'Invalid Social Insuarance Number',
              // message: 'Social Insuarance Number has at least 9 characters',
              path: this.path,
            });
          }
        }
      }
    ).required('Social Insuarance is required'),
    otherwise: yup.string().nullable()
    }),

  businessNumber: yup.string().nullable()
    .when('recipientType', {
      is: (value:number) => value === 30 || value === 50,
      then:yup.string().test(
          'is-valid-or-not',
          'Invalid value',
          function (value:any) {
            let maxLength = 15;
            console.log("value456",value)
            const firstNineDigits: string = value && value.substring(0, 9);
            console.log("valueeeee-- bn",firstNineDigits)
            const output = checkSINValidity(firstNineDigits);
            console.log("output bn",output)
            
              if (value && value.length == maxLength && output % 10 === 0) {
                return true;
              } else {
                return this.createError({
                  message: 'Invalid Business Number',
                  path: this.path,
                });
              }
          }
        ).nullable().required('business Number is required'),
    otherwise: yup.string().nullable()
    }),

  trustAccountNumber: yup.string().nullable()
    .when('recipientType', {
      is: 40,
      then:yup.string().required('Trust Account Number is required'),
    otherwise: yup.string().nullable()
    }),

    email: yup
    .string().nullable()
    .email("Please enter valid Email"),
});
