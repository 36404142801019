import { FC, useState } from "react";

import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Toolbar } from "@mui/material";
import {
  DeleteButton,
  DisabledButton,
  ResetButton,
  SplitButton,
  SubmitButton,
} from "@websential/cosmic";
import { useQuery } from "react-query";
import { T3SlipService } from "services/T3SlipService";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { selectedT3SlipType } from "../T3SlipSetup";
import { selectedT3SummaryType } from "components/T3Slip/T3SlipSummarySetup";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PrintIcon from "@mui/icons-material/Print";

export interface IT3SlipSetupToolbar {
  onResetClicked: () => void;
  onDeleteClicked: () => void;
  onPrintClicked: (printType: string) => void;
  isSubmitting: boolean;
  formMethods: any;
}

const T3_SUMMARY_DATA_QUERY_KEY = "get-t3-summary-data";
const useGetT3SummaryData = (
  clientid: string,
  slipType: string | number,
  taxYear: number
) =>
  useQuery(
    [T3_SUMMARY_DATA_QUERY_KEY, clientid, slipType, taxYear],
    (data) => {
      return T3SlipService.getT3Summary(clientid, slipType, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

export const T3SlipSetupToolbar: FC<IT3SlipSetupToolbar> = ({
  onResetClicked,
  onDeleteClicked,
  onPrintClicked,
  isSubmitting = false,
  formMethods,
}) => {
  const id = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  // const [selectedType, setSelectedType] = useState(400);
  const { isLoading: isLoadingSummaryGetData, data: t3SummaryData } =
    useGetT3SummaryData(id, selectedT3SummaryType, taxYear);
  // console.log("selectedType",selectedType)

  console.log("t3SummaryData ============", t3SummaryData);

  const onchangeTooltip = () => {
    //it have to optional from cosmic then remove it
  };
  const selectedSlip = formMethods.getValues("selectedSlip");

  return (
    <Toolbar>
      <>
        <div style={{ display: "contents" }}>
          <div style={{ margin: "1rem 0rem" }}>
            {t3SummaryData &&
            t3SummaryData.confirmationCode !== null &&
            selectedT3SlipType === 400 ? (
              <div style={{ cursor: "not-allowed" }}>
                <DisabledButton
                  tooltiptitle={
                    "Confirmation No. has been enterned in [Complaince -> t3 -> Summary]"
                  }
                  isDisabled={true}
                  buttonLabel={"Submit"}
                  icon={<PlaylistAddCheckOutlinedIcon />}
                  testId="disableButton"
                  onClick={onchangeTooltip}
                />
              </div>
            ) : (
              <SubmitButton
                label="Submit"
                testId="submit-button"
                loading={isSubmitting}
                icon={<PlaylistAddCheckOutlinedIcon />}
              />
            )}
          </div>
          <ResetButton testId="reset-button" onClickHandler={onResetClicked} />
          <div style={{ margin: "1rem 0rem" }}>
            {t3SummaryData &&
            t3SummaryData.confirmationCode !== null &&
            selectedT3SlipType === 400 ? (
              <div style={{ cursor: "not-allowed", marginLeft: "1rem" }}>
                <DisabledButton
                  tooltiptitle={
                    "Confirmation No. has been enterned in [Complaince -> t4 -> Summary]"
                  }
                  isDisabled={true}
                  buttonLabel={"Delete"}
                  icon={<DeleteOutlineIcon />}
                  testId="disableButton"
                  onClick={onchangeTooltip}
                />
              </div>
            ) : selectedSlip && selectedSlip === "create-new" ? (
              <div style={{ cursor: "not-allowed", marginLeft: "1rem" }}>
                <DisabledButton
                  tooltiptitle={""}
                  isDisabled={true}
                  buttonLabel={"Delete"}
                  icon={<DeleteOutlineIcon />}
                  testId="disableButton"
                  onClick={onchangeTooltip}
                />
              </div>
            ) : (
              <DeleteButton
                testId="delete-button"
                onClickHandler={onDeleteClicked}
              />
            )}
          </div>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div>
          {selectedSlip && selectedSlip === "create-new" ? (
            <div style={{ cursor: "not-allowed", marginLeft: "1rem" }}>
              <DisabledButton
                tooltiptitle={""}
                isDisabled={true}
                buttonLabel={"Print"}
                icon={<PrintIcon />}
                testId="disableButton"
                onClick={onchangeTooltip}
              />
            </div>
          ) : (
            <SplitButton
              options={["Payer", "Recipient", "CRA"]}
              onClickHandler={onPrintClicked}
              testId="t3-print-button"
              icon={<PrintIcon />}
              listItemsLabel={`Print T3 Slip  `}
            />
          )}
        </div>
      </>
    </Toolbar>
  );
};
