import React, { useState, useRef, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import "./PierReview.css"
import "./PierReviewAbove2023.css"
import { useSelector } from 'react-redux';
import { IAppState } from 'store/reducers';

export interface IDataTable {
    rows: any;
    columns:any;
    isLoading?: boolean;
}

const PrimeDataGrid: React.FC<IDataTable> = ({
    rows,
    columns,
    isLoading
}) => {
    const [selectedItems, setSelectedItems] = useState(null);
    // this states used in the process of filteration of dataTable
    const [loading, setLoading] = useState(false);
    const [itemSearsch, setItemSearsch] = useState('');
    const taxYear  = useSelector((state: IAppState) => state.taxYear.year);

// Search type and billing profile Button
    const renderHeader = () => {
        return (
            <div className='flex justify-content-between p-4 pl-0'  style={{backgroundColor:"#ebecf0",margin:"-17px"}}>
                <span className="p-input-icon-left w-5">
                    <i className="pi pi-search" />
                    <InputText value={itemSearsch} onChange={(e) => setItemSearsch(e.target.value)} placeholder="Search by Employee Name" className='w-full'/>
                </span>
            </div>
        )
    }
    const headerSearch = renderHeader();
    function search(_row:any){
        return rowsWithTwoDecimalNumbers && rowsWithTwoDecimalNumbers.filter((rows:any)=> rows?.resource?.firstName.toLowerCase().indexOf(itemSearsch.toLowerCase())>-1 )
    }


      console.log("props headerColumns => ",columns)
      console.log("items RL1AdjustmentReportttt => ",rows)
    //   console.log("items RL1AdjustmentReport last rows => ",last_rows)

      const headerGroup = (
        <ColumnGroup>
          <Row>
            <Column header="Employee Info" colSpan={2} style={{cursor:"auto",backgroundColor:"#e3fcef"}}/>
            <Column header="CPP" colSpan={taxYear > 2023 ? 6 : 3} style={{cursor:"auto",backgroundColor:"#e3fcef"}}/>
            <Column header="QPP" colSpan={taxYear > 2023 ? 6 : 3} style={{cursor:"auto",backgroundColor:"#fffae6"}}/>
            <Column header="Employment Insurance" colSpan={3} style={{cursor:"auto",backgroundColor:"#ffebe6"}}/>
            <Column header="PPIP" colSpan={3} style={{cursor:"auto",backgroundColor:"#deebff"}}/>
            <Column header="Income Tax" colSpan={6} style={{cursor:"auto",backgroundColor:"#bec4cc"}}/>
          </Row>
          <Row>
            {columns  &&  columns.map((x:any,index:any) => (
                    <Column
                        field={x.field}
                        header={x.headerName}
                        headerTooltip={x.description}
                        headerTooltipOptions={{ position: 'top' }}
                        sortable={x.sortable}
                        resizeable={x.resizable}
                        dataType={x.type}
                        align={x.align}
                        alignHeader={x.headerAlign}
                        reorderable={x.disableReorder}
                        exportable={x.disableExport}
                        frozen={x.frozenColumn}
                        columnKey={x.field}
                        />
                ))}
          </Row>
        </ColumnGroup>

      );

      const rowsWithTwoDecimalNumbers = rows && rows?.map((item:any) => ({
        ...item,
        slipData:{
            ...item.slipData,
            cppContributions:item.slipData.cppContributions !== null ? parseFloat(item.slipData.cppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            qppContributions:item.slipData.qppContributions !== null ? parseFloat(item.slipData.qppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            eiPremiums:item.slipData.eiPremiums !== null ? parseFloat(item.slipData.eiPremiums).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            ppipPremiums:item.slipData.ppipPremiums !== null ? parseFloat(item.slipData.ppipPremiums).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            incomeTaxDeducted:item.slipData.incomeTaxDeducted !== null ? parseFloat(item.slipData.incomeTaxDeducted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            rl1Tax:item.slipData.rl1Tax !== null ? parseFloat(item.slipData.rl1Tax).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
        },
        adjustedSlipData:{
            ...item.adjustedSlipData,
            cppContributions:item.adjustedSlipData.cppContributions !== null ? parseFloat(item.adjustedSlipData.cppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            qppContributions:item.adjustedSlipData.qppContributions !== null ? parseFloat(item.adjustedSlipData.qppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            eiPremiums:item.adjustedSlipData.eiPremiums !== null ? parseFloat(item.adjustedSlipData.eiPremiums).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            ppipPremiums:item.adjustedSlipData.ppipPremiums !== null ? parseFloat(item.adjustedSlipData.ppipPremiums).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            incomeTaxDeducted:item.adjustedSlipData.incomeTaxDeducted !== null ? parseFloat(item.adjustedSlipData.incomeTaxDeducted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            rl1Tax:item.adjustedSlipData.rl1Tax !== null ? parseFloat(item.adjustedSlipData.rl1Tax).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
        },
        slipDataDifferance:{
            ...item.slipDataDifferance,
            cppContributions:item.slipDataDifferance.cppContributions !== null ? parseFloat(item.slipDataDifferance.cppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            qppContributions:item.slipDataDifferance.qppContributions !== null ? parseFloat(item.slipDataDifferance.qppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            eiPremiums:item.slipDataDifferance.eiPremiums !== null ? parseFloat(item.slipDataDifferance.eiPremiums).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            ppipPremiums:item.slipDataDifferance.ppipPremiums !== null ? parseFloat(item.slipDataDifferance.ppipPremiums).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            incomeTaxDeducted:item.slipDataDifferance.incomeTaxDeducted !== null ? parseFloat(item.slipDataDifferance.incomeTaxDeducted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            rl1Tax:item.slipDataDifferance.rl1Tax !== null ? parseFloat(item.slipDataDifferance.rl1Tax).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
        },
    }));

    console.log("rowsWithTwoDecimalNumbers",rowsWithTwoDecimalNumbers)

  return (
    <div>
       <DataTable
            value={search(rowsWithTwoDecimalNumbers && rowsWithTwoDecimalNumbers)}
            responsiveLayout="scroll"
            paginator
            rows={25}
            rowsPerPageOptions={[25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
            editMode="row"
            showGridlines
            resizableColumns columnResizeMode="expand"
            scrollable
            reorderableColumns
            loading={isLoading}
            header={headerSearch}
            emptyMessage="No Record found."
            className={taxYear > 2023 ? "pierReviewDataTableAbove2023" : "pierReviewDataTable"}
            headerColumnGroup={headerGroup}
            style={{margin:"0.5rem"}}
            >

            {columns && columns.map((x:any,index:any) => (
                    <Column
                        key={index}
                        field={x.field}
                        header={x.headerName}
                        headerTooltip={x.description}
                        headerTooltipOptions={{ position: 'top' }}
                        sortable={x.sortable}
                        dataType={x.type}
                        align={x.align}
                        alignHeader={x.headerAlign}
                        frozen={x.frozenColumn}
                        // footer={x.footer}
                        />
                ))}
            </DataTable>
    </div>
  )
}

export default PrimeDataGrid