import { T4ASlipSummarySetup } from "components/T4ASlip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT4ASlipSummarySetupPage {}

export const T4ASlipSummarySetupPage: FC<IT4ASlipSummarySetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T4A Summary"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t4a/list" },
        { text: "T4A", navigateTo: "/taxslip/t4a/list" },
        { text: "T4A Summary", navigateTo: "/taxslip/t4a/summary" },
      ]}
      mainContents={<T4ASlipSummarySetup testId="t4a-summary-setup" />}
    />
  );
};
