import * as yup from "yup";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  IResponse,
  IServerError,
  LabelPosition,
  PhoneNumberRHF,
  RHookFormMaskedTextFieldWithLabel,
  RHookFormPasswordFieldWithLabel,
  RHookFormTextFieldWithLabel,
  ResetButton,
  Spacer,
  StandardPanel,
  StyledRouterLink,
  SubmitButton,
  SubmitButtonVariant,
} from "@websential/cosmic";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { AuthService } from "services";
import Grid from "@mui/material/Grid";
import { INITIAL_SNACKBAR_DATA } from "common/constants";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

export interface IUserAuthentication {
  choosePassword: string;
  confirmPassword: string;
  // contact: string;
  // countryCode?: string;
  verify_key?: string;
  email?: string;
}

const useGetUserDetail = (key: string | undefined) =>
  useQuery(
    "authentication-data",
    (data) => {
      return AuthService.authenticationData(key)
;
    },
    { refetchOnWindowFocus: false }
  );

const useGetVerfyEmailKey = (key: string | undefined) =>
  useQuery(
    "verify-email",
    (data) => {
      return AuthService.verifyEmail(key)
;
    },
    { refetchOnWindowFocus: false }
  );

const userAuthenticationFormSchema = yup.object({
  choosePassword: yup
    .string()
    .required("Please Enter New Password")
    .min(8, "Your password must be at least 8 characters long")
    .max(15, "Your password Should be maximum 15 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d).+$/,
      "Password must contain at least 1 letter and 1 number"
    ),
  confirmPassword: yup
    .string()
    .required("Please Confirm new password")
    .min(8, "Your password must be at least 8 characters long")
    .max(15, "Your password Should be maximum 15 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d).+$/,
      "Password must contain at least 1 letter and 1 number"
    )
    .oneOf([yup.ref("choosePassword"), null], "Passwords don't match."),
  // contact: yup
  //   .string()
  //   .required("Phone is required!")
  //   .min(14, "Should be maximum 15 characters"),
});
const AlreadyHaveAnAccountLinkComponent = () => {
  return (
    <Grid
      item
      md={12}
      lg={12}
      xl={12}
      style={{ position: "relative", top: "1.3rem" }}
    >
      <Typography variant="subtitle2">
        Already have an account?&nbsp;
        <StyledRouterLink to="/login">Sign In</StyledRouterLink>
      </Typography>
    </Grid>
  );
};

const DoNotHaveAnAccountLinkComponent = () => {
  return (
    <Grid
      item
      md={12}
      lg={12}
      xl={12}
      style={{ position: "relative", top: "1.3rem" }}
    >
      <Typography variant="subtitle2">
        Do not have an account yet?&nbsp;
        <StyledRouterLink to="/create-an-account">
          Create an Account
        </StyledRouterLink>
      </Typography>
    </Grid>
  );
};

const InvalidEmail = () => {
  return (
    <StandardPanel title={`Welcome to TaxSlips`} testId={`welcome-panel`}>
      <Alert severity="error" sx={{ marginBottom: "10px" }}>
        Invalid Email
      </Alert>
      <Box sx={{ textAlign: "right" }}>
        <AlreadyHaveAnAccountLinkComponent />
        <DoNotHaveAnAccountLinkComponent />
        <Spacer y={1.5} x={1.5} />
      </Box>
    </StandardPanel>
  );
};
const AccountCreatedSuccessfully = () => {
  return (
    <StandardPanel title={`Welcome to TaxSlips`} testId={`welcome-panel`}>
      <Alert severity="success" sx={{ marginBottom: "1rem" }}>
        <AlertTitle>Account Created Successfully</AlertTitle>
        <br />
        Thank You for sending information, your account is active now.
      </Alert>
      <Divider />
      <Box sx={{ textAlign: "center", marginTop: "1rem" }}>
        <StyledRouterLink to="/login">
          <Button variant="contained">Login</Button>
        </StyledRouterLink>
      </Box>
    </StandardPanel>
  );
};

const UserAuthenticationFormComponent = () => {
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [successResponse, setSuccessResponse] = useState<any>(undefined);
  const [verifyEmailResponse, setverifyEmailResponse] = useState<any>(undefined);
  const methods = useForm<any>({
    resolver: yupResolver(userAuthenticationFormSchema),
  });

  const { key } = useParams();
  console.log("keydsfg", key);

  //get data
  const { isLoading: isLoadingData, data: authenticationData } =
    useGetUserDetail(key)
;
  console.log("authenticationData", authenticationData);
  let emailKey = authenticationData && authenticationData.email
  // const { isLoading: isLoadingVerifyEmail, data: verifyEmail } =
  //   useGetVerfyEmailKey(emailKey);
  // console.log("verifyEmail", verifyEmail);

  const { isLoading: isSubmitting, mutate: userAuthentication } = useMutation(
    AuthService.authentication,
    {
      onSuccess: (res: IServerError | any | IResponse) => {
        if (res.message === "Password has been updated.") {
          setSuccessResponse(
            "Your registration is now complete. Your account activation link has been sent to your email address."
          );
        } else {
          console.log("res", res);
          setSnackbarData({
            open: true,
            message: res?.message ? res.message : "Somethingwent wrong!",
            severity: res.status === false ? "error" : "success",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };
  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    methods.reset();
  };

  const onSubmit = (data: IUserAuthentication) => {
    let payload = {
      newPassword: data.choosePassword,
      confirmNewPassword: data.confirmPassword,
      email: key && key,
      // contact: data.contact,
      // countryCode: data.countryCode,
      // verify_key: key,
    };
    userAuthentication(payload);
    console.log("data90", payload);
  };
  console.log(methods.formState.isDirty); // make sure formState is read before render to enable the Proxy

  useEffect(()=>{
      (async () => {
      try {
            const response: any = await AuthService.verifyEmail(emailKey);
            console.log("responseData-----------------", response);
            if(response.data){
              setverifyEmailResponse(response)
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        })();
  },[authenticationData,emailKey])
  return (
    <>
      {successResponse ? (
        <AccountCreatedSuccessfully />
      ) : (
        <>
          <BackdropCircularProgress
            open={isLoadingData || isSubmitting}
            testId={"user-authentication"}
          />
          {verifyEmailResponse && verifyEmailResponse.status === 200 ? (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container md={12} lg={12} xl={12}>
                  <Grid item md={12} lg={12} xl={12}>
                    <StandardPanel
                      title={`Welcome to TaxSlips ${
                        authenticationData && authenticationData.name
                      }`}
                      testId={`welcome-panel`}
                    >
                      <Alert severity="info">
                        You are just a few steps away from creating an account
                        in Tax Slips.
                        <br />
                        Please provide following information
                      </Alert>
                    </StandardPanel>
                  </Grid>
                  <Grid item md={12} lg={12} xl={12}>
                    <StandardPanel
                      title={`User Authentication`}
                      testId={`user-authentication`}
                      topPadding={3}
                    >
                      <RHookFormPasswordFieldWithLabel
                        id="choosePassword"
                        testId={`user-authentication-new-password`}
                        label="Choose password"
                        placeholder="Choose password"
                        labelPosition={LabelPosition.Left}
                        required
                      />
                      <RHookFormPasswordFieldWithLabel
                        id="confirmPassword"
                        testId={`user-authentication-confirm-new-password`}
                        label="Confirm password"
                        placeholder="Confirm password"
                        labelPosition={LabelPosition.Left}
                        required
                      />
                      {/* for save contact no and countrycode we have to use api from RBSC 
                  API to update phone 
                  /update_phone_and_send_code 
                  required_list = list(["email", "phone", "country_code", "access_token"]) */}
                      {/* <Grid
                  container
                  columnSpacing={0}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <PhoneNumberRHF
                      label="Contact No."
                      id="contact"
                      testId={`contact-number`}
                      labelPosition={LabelPosition.Left}
                      labelColumns={6}
                      fieldColumns={6}
                      required
                    />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <RHookFormMaskedTextFieldWithLabel
                      label="Country Code"
                      id="countryCode"
                      testId={`countryCode`}
                      labelPosition={LabelPosition.Left}
                      labelColumns={6}
                      fieldColumns={6}
                      placeholder="+1"
                      inputMaskPattern="+00"
                    />
                  </Grid>
                </Grid> */}
                      <Spacer y={2} x={2} />
                      <Grid
                        container
                        columnSpacing={0}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Grid item md={4} lg={4} xl={4}></Grid>
                        <Grid item md={8} lg={8} xl={8}>
                          <SubmitButton
                            label="Submit"
                            variant={SubmitButtonVariant.Primary}
                            loading={isSubmitting}
                            testId="create-an-account-button"
                            icon={<PlaylistAddCheckOutlinedIcon />}
                          />
                          <ResetButton
                            testId="reset-button"
                            onClickHandler={onResetClicked}
                          />
                        </Grid>
                      </Grid>
                    </StandardPanel>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          ) : verifyEmailResponse ? (
            <StandardPanel
              title={`Welcome to TaxSlips`}
              testId={`welcome-panel`}
            >
              <Alert severity="error" sx={{ marginBottom: "1rem" }}>
                <AlertTitle>Error</AlertTitle>
                <br />
                {verifyEmailResponse && verifyEmailResponse.message
                  ? verifyEmailResponse.message
                  : "E-mail Does Not Exist!"}
              </Alert>
              <Divider />
              <Box sx={{ textAlign: "right" }}>
                <AlreadyHaveAnAccountLinkComponent />
                <DoNotHaveAnAccountLinkComponent />
                <Spacer y={1.5} x={1.5} />
              </Box>
            </StandardPanel>
          ) : authenticationData && authenticationData.message &&
          authenticationData.message.message === "Invalid email key." ? (<InvalidEmail/>) : (<><h1>Loading...</h1></>)}
        </>
      )}
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"register-snackbar"}
        open={snackbarData.open}
      />
    </>
  );
};

export const UserAuthentication = () => {
  return (
    <>
      <Grid container md={12} lg={12} xl={12}>
        <Grid item md={1} lg={1} xl={1}></Grid>
        <Grid item md={10} lg={10} xl={10}>
          <UserAuthenticationFormComponent />
        </Grid>
        <Grid item md={1} lg={1} xl={1}></Grid>
      </Grid>
    </>
  );
};