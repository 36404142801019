import {
  ADJUSTMENT_SCREEN_SLUG,
  AJDUSTMENT_OPTIONS_SCREEN_SLUG,
  EARNINGS_AUDIT_SCREEN_SLUG,
  EMPLOYEE_LIST_SCREEN_SLUG,
  EMPLOYEE_SETUP_SCREEN_SLUG,
  FILING_RESOURCE_LIST_SCREEN_SLUG,
  FILING_RESOURCE_SETUP_SCREEN_SLUG,
  RL1_LIST_SCREEN_SLUG,
  RL1_SETTINGS_SCREEN_SLUG,
  RL1_SETUP_SCREEN_SLUG,
  RL1_SUMMARY_SCREEN_SLUG,
  T4A_LIST_SCREEN_SLUG,
  T4A_SETUP_SCREEN_SLUG,
  T4A_SUMMARY_SCREEN_SLUG,
  T4_LIST_SCREEN_SLUG,
  T4_SETUP_SCREEN_SLUG,
  T4_SUMMARY_SCREEN_SLUG,
  T5_LIST_SCREEN_SLUG,
  T5_RECIPIENT_LIST_SCREEN_SLUG,
  T5_RECIPIENT_SETUP_SCREEN_SLUG,
  T5_SETUP_SCREEN_SLUG,
  T5_SUMMARY_SCREEN_SLUG,
  VENDOR_LIST_SCREEN_SLUG,
  VENDOR_SETUP_SCREEN_SLUG,
} from "common/constants";

const T4Options = [
  { value: T4_LIST_SCREEN_SLUG, label: "List" },
  { value: T4_SETUP_SCREEN_SLUG, label: "Setup" },
  { value: T4_SUMMARY_SCREEN_SLUG, label: "Summary" },
  { value: "t4_import_xml", label: "Import XML" },
  // { value: "t4ImportXMLSimplePay", label: "Import XML SimplePay" },
  { value: "t4_import_csv", label: "Import CSV" },
];
const T5Options = [
  { value: T5_LIST_SCREEN_SLUG, label: "List" },
  { value: T5_SETUP_SCREEN_SLUG, label: "Setup" },
  { value: T5_SUMMARY_SCREEN_SLUG, label: "Summary" },
  { value: "t5_import_xml", label: "Import XML" },
  // { value: "t5ImportXMLSimplePay", label: "Import XML SimplePay" },
  { value: "t5_import_csv", label: "Import CSV" },
];
const T4AOptions = [
  { value: T4A_LIST_SCREEN_SLUG, label: "List" },
  { value: T4A_SETUP_SCREEN_SLUG, label: "Setup" },
  { value: T4A_SUMMARY_SCREEN_SLUG, label: "Summary" },
  { value: "t4a_import_csv", label: "Import CSV" },
];
const RL1Options = [
  { value: RL1_LIST_SCREEN_SLUG, label: "List" },
  { value: RL1_SETUP_SCREEN_SLUG, label: "Setup" },
  { value: RL1_SETTINGS_SCREEN_SLUG, label: "Setting" },
  { value: RL1_SUMMARY_SCREEN_SLUG, label: "Summary" },
  { value: "rl1_import_csv", label: "Import CSV" },
  { value: "rl1_import_xml", label: "Import XML" },
  // { value: "rl1ImportXMLSimplePay", label: "Import XML SimplePay" },
];
const UserOptions = [
  // { value: "userDefaultSetting", label: "Default Setting" },
  { value: "user_api_keys", label: "Api Keys" },
  { value: "user_invoice_report", label: "Invoice Report" },
  { value: "user_client_profile", label: "Client Profile" },
];
const EmployeeOptions = [
  { value: EMPLOYEE_LIST_SCREEN_SLUG, label: "List" },
  { value: EMPLOYEE_SETUP_SCREEN_SLUG, label: "Setup" },
  { value: "employee_import_csv", label: "Import CSV" },
  { value: "employee_move_year", label: "Move Year" },
];

export const groupedOptions = [
  { value: "all", label: "All" },
  {
    label: "Audit",
    options: [{ value: EARNINGS_AUDIT_SCREEN_SLUG, label: "Earnings Audit" }],
  },
  {
    label: "Filing Resource",
    options: [
      { value: FILING_RESOURCE_LIST_SCREEN_SLUG, label: "List" },
      { value: FILING_RESOURCE_SETUP_SCREEN_SLUG, label: "Setup" },
    ],
  },
  {
    label: "T4",
    options: T4Options,
  },
  // {
  //   label: "Contact",
  //   options: [
  //     { value: "contactList", label: "List" },
  //     { value: "contactSetup", label: "Setup" },
  //   ],
  // },
  {
    label: "Employee",
    options: EmployeeOptions,
  },
  {
    label: "T4A",
    options: T4AOptions,
  },
  {
    label: "T5",
    options: T5Options,
  },
  {
    label: "Vendor",
    options: [
      { value: VENDOR_LIST_SCREEN_SLUG, label: "List" },
      { value: VENDOR_SETUP_SCREEN_SLUG, label: "Setup" },
      { value: "vendor_move_year", label: "Move Year" },
    ],
  },
  {
    label: "T5_Recipient",
    options: [
      { value: T5_RECIPIENT_LIST_SCREEN_SLUG, label: "List" },
      { value: T5_RECIPIENT_SETUP_SCREEN_SLUG, label: "Setup" },
      { value: "t5_recipient_move_year", label: "Move Year" },
    ],
  },
  {
    label: "User",
    options: UserOptions,
  },
  {
    label: "Adjustment",
    options: [
      { value: AJDUSTMENT_OPTIONS_SCREEN_SLUG, label: "Options" },
      { value: ADJUSTMENT_SCREEN_SLUG, label: "Adjustments" },
    ],
  },
  {
    label: "RL-1",
    options: RL1Options,
  },
  // {
  //   label: "Issuer",
  //   options: [
  //     { value: "issuerList", label: "List" },
  //     { value: "issuerSetup", label: "Setup" },
  //   ],
  // },
];

//audit
export const AuditActivityOptions = [
  { value: "ignore_insurable_earnings", label: "Ignored Insurable Earnings" },
  {
    value: "undo_ignore_insurable_earnings",
    label: "Undo Ignored Insurable Earnings",
  },
  {
    value: "ignore_pensionable_earnings",
    label: "Ignored Pensionable Earnings",
  },
  {
    value: "undo_ignore_pensionable_earnings",
    label: "Undo Ignored Pensionable Earnings",
  },
];

//filing Resource
export const FilingResourceListActivityOptions = [
  { value: "delete", label: "Filing Resource Deleted" },
];
export const FilingResourceSetupActivityOptions = [
  { value: "create", label: "Filing Resource Create" },
  { value: "updated", label: "Filing Resource Update" },
];

//t4
export const T4ListActivityOptions = [
  { value: "delete", label: "Delete T4 Slip" },
  { value: "emailed", label: "T4 Slip Emailed" },
  { value: "print", label: "T4 Slip Print" },
];
export const T4SetupActivityOptions = [
  { value: "create", label: "T4 Slip Create" },
  { value: "updated", label: "T4 Slip Update" },
];
export const T4SummaryActivityOptions = [
  { value: "updated", label: "T4 Summary Update" },
  { value: "delete_t4_confirmation_no", label: "Delete T4 Confirmation No" },
  { value: "print_xml", label: "T4 Summary Print XML" },
  { value: "print_pdf", label: "T4 Summary Print PDF" },
];
export const T4ImportXMLActivityOptions = [
  { value: "import_xml", label: "T4 Import XML" },
];
// export const T4SimplePayActivityOptions = [
//   { value: "t4ImportXMLSimplePay", label: "T4 Import XML SimplePay" },
// ];
export const T4ImportCSVActivityOptions = [
  { value: "import_csv", label: "T4 Import CSV" },
];

//t5
export const T5ListActivityOptions = [
  { value: "delete", label: "Delete T5 Slip" },
  { value: "emailed", label: "T5 Slip Emailed" },
  { value: "print", label: "T5 Slip Print" },
];
export const T5SetupActivityOptions = [
  { value: "create", label: "T5 Slip Create" },
  { value: "updated", label: "T5 Slip Update" },
];
export const T5SummaryActivityOptions = [
  { value: "updated", label: "T5 Summary Update" },
  { value: "delete_t5_confirmation_no", label: "Delete T5 Confirmation No" },
  { value: "print_xml", label: "T5 Summary Print XML" },
  { value: "print_pdf", label: "T5 Summary Print PDF" },
];
export const T5ImportXMLActivityOptions = [
  { value: "import_xml", label: "T5 Import XML" },
];
// export const T5SimplePayActivityOptions = [
//   { value: "t5ImportXMLSimplePay", label: "T5 Import XML SimplePay" },
// ];
export const T5ImportCSVActivityOptions = [
  { value: "import_csv", label: "T5 Import CSV" },
];

//contact person
// export const ContactListActivityOptions = [
//   { value: "contactDelete", label: "Contact Delete" },
// ];
// export const ContactSetupActivityOptions = [
//   { value: "contactCreate", label: "Contact Create" },
//   { value: "contactUpdate", label: "Contact Update" },
// ];

//employee
export const EmployeeListActivityOptions = [
  { value: "delete", label: "Delete Employee" },
  // { value: "upadteEmployeeStatus", label: "Update Employee Status" },
];
export const EmployeeSetupActivityOptions = [
  { value: "create", label: "Employee Create" },
  { value: "updated", label: "Employee Update" },
];
export const EmployeeImportCSVActivityOptions = [
  { value: "import_csv", label: "Employee Import CSV" },
];
export const MoveEmployeeActivityOptions = [
  { value: "move_employees", label: "Employees Move Year" },
];

//T4a
export const T4AListActivityOptions = [
  { value: "delete", label: "Delete T4A Slip" },
  { value: "emailed", label: "T4A Slip Emailed" },
  { value: "print", label: "T4A Slip Print" },
];
export const T4ASetupActivityOptions = [
  { value: "create", label: "T4A Slip Create" },
  { value: "updated", label: "T4A Slip Update" },
];
export const T4ASummaryActivityOptions = [
  { value: "updated", label: "T4A Summary Update" },
  { value: "delete_t4a_confirmation_no", label: "Delete T4A Confirmation No" },
  { value: "print_pdf", label: "T4A Summary Print PDF" },
];
export const T4AImportCSVActivityOptions = [
  { value: "import_csv", label: "T4A Import CSV" },
];

//vendor
export const VendorListActivityOptions = [
  { value: "delete", label: "Vendor Delete" },
];
export const VendorSetupActivityOptions = [
  { value: "create", label: "Vendor Create" },
  { value: "updated", label: "Vendor Update" },
];
export const MoveVendorActivityOptions = [
  { value: "move_vendors", label: "Vendors Move Year" },
];

//T5Recipient
export const T5RecipientListActivityOptions = [
  { value: "delete", label: "T5 Recipient Delete" },
];
export const T5RecipientSetupActivityOptions = [
  { value: "create", label: "T5 Recipient Create" },
  { value: "updated", label: "T5 Recipient Update" },
];
export const MoveT5RecipientActivityOptions = [
  { value: "move_t5_recipients", label: "T5 Recipients Move Year" },
];
export const MoveT3BeneficiaryActivityOptions = [
  { value: "move_t3_beneficiary", label: "T3 Beneficiary Move Year" },
];

//user
// export const UserDefaultSettingActivityOptions = [
//   { value: "defaultSettingUpdate", label: "Default Setting Update" },
// ];
export const UserApiKeysActivityOptions = [
  { value: "api_keys_generated", label: "Api Keys Generated" },
];
export const UserInvoiceReportActivityOptions = [
  { value: "paid", label: "Invoice Paid" },
  { value: "emailed", label: "Invoice Email" },
  { value: "generated", label: "Invoice Generated" },
  { value: "updated", label: "Invoice Updated" },
  { value: "recalculated", label: "Invoice Recalculated" },
  { value: "print", label: "Invoice Print PDF" },
];
export const UserCreateProfileActivityOptions = [
  { value: "updated", label: "Client Profile Update" },
];

//adjustment
export const AdjustmentOptionsActivityOptions = [
  { value: "updated", label: "Adjustment Options Update" },
];
export const AdjustmentsActivityOptions = [
  { value: "t4_adjustments", label: "T4 Adjustments" },
  { value: "t4_undo_adjustments", label: "T4 Undo Adjustments" },
  { value: "rl1_adjustments", label: "RL-1 Adjustments" },
  { value: "rl1_undo_adjustments", label: "RL-1 Undo Adjustments" },
  { value: "remittance_transfers", label: "Remittance Transfers" },
  { value: "undo_remittance_transfers", label: "Undo Remittance Transfers" },
  {
    value: "ignore_remittance_transfers",
    label: "Ignore Remittance Transfers",
  },
  {
    value: "undo_ignore_remittance_transfers",
    label: "Undo Ignore Remittance Transfers",
  },
];

//rl1
export const RL1ListActivityOptions = [
  { value: "delete", label: "Delete RL-1 Slip" },
  { value: "emailed", label: "RL-1 Slip Emailed" },
  { value: "print_pdf", label: "RL-1 Slip PDF Print" },
  { value: "print_rl1", label: "RL-1 Slip XML Print" },
];
export const RL1SetupActivityOptions = [
  { value: "create", label: "RL-1 Slip Create" },
  { value: "updated", label: "RL-1 Slip Update" },
];
export const RL1SettingActivityOptions = [
  { value: "updated", label: "RL-1 Setting Update" },
];
export const RL1SummaryActivityOptions = [
  { value: "updated", label: "RL-1 Summary Update" },
  { value: "delete_rl1_confirmation_no", label: "Delete RL1 Confirmation No" },
  { value: "print_pdf", label: "RL-1 Summary Print PDF" },
];
export const RL1ImportXMLActivityOptions = [
  { value: "import_xml", label: "RL-1 Import XML" },
];
// export const RL1SimplePayActivityOptions = [
//   { value: "rl1ImportXMLSimplePay", label: "RL-1 Import XML SimplePay" },
// ];
export const RL1ImportCSVActivityOptions = [
  { value: "import_csv", label: "RL-1 Import CSV" },
];

//issuer
// export const IssuertListActivityOptions = [
//   { value: "issuertDelete", label: "Issuert Delete" },
// ];
// export const IssuertSetupActivityOptions = [
//   { value: "issuertCreate", label: "Issuert Create" },
//   { value: "issuertUpdate", label: "Issuert Update" },
// ];

export const allActivityTypes = [
  { value: "all", label: "All" },
  { value: "ignoreInsurableEarnings", label: "Ignored Insurable Earnings" },
  {
    value: "undoIgnoreInsurableEarnings",
    label: "Undo Ignored Insurable Earnings",
  },
  { value: "ignorePensionableEarnings", label: "Ignored Pensionable Earnings" },
  {
    value: "undoIgnorePensionableEarnings",
    label: "Undo Ignored Pensionable Earnings",
  },
  { value: "filingResourceDeleted", label: "Filing Resource Deleted" },
  { value: "filingResourceCreate", label: "Filing Resource Create" },
  { value: "filingResourceUpdate", label: "Filing Resource Update" },
  { value: "deleteT4Slip", label: "Delete T4 Slip" },
  { value: "t4SlipEmailed", label: "T4 Slip Emailed" },
  { value: "t4SlipPrint", label: "T4 Slip Print" },
  { value: "t4SlipCreate", label: "T4 Slip Create" },
  { value: "t4SlipUpdate", label: "T4 Slip Update" },
  { value: "t4SummaryUpdate", label: "T4 Summary Update" },
  { value: "deleteT4ConfirmationNo", label: "Delete T4 Confirmation No" },
  { value: "t4SummaryPrintXML", label: "T4 Summary Print XML" },
  { value: "t4SummaryPrintPDF", label: "T4 Summary Print PDF" },
  { value: "t4ImportXML", label: "T4 Import XML" },
  { value: "t4ImportCSV", label: "T4 Import CSV" },
  { value: "deleteT5Slip", label: "Delete T5 Slip" },
  { value: "t5SlipEmailed", label: "T5 Slip Emailed" },
  { value: "t5SlipPrint", label: "T5 Slip Print" },
  { value: "t5SlipCreate", label: "T5 Slip Create" },
  { value: "t5SlipUpdate", label: "T5 Slip Update" },
  { value: "t5SummaryUpdate", label: "T5 Summary Update" },
  { value: "deleteT5ConfirmationNo", label: "Delete T5 Confirmation No" },
  { value: "t5SummaryPrintXML", label: "T5 Summary Print XML" },
  { value: "t5SummaryPrintPDF", label: "T5 Summary Print PDF" },
  { value: "t5ImportXML", label: "T5 Import XML" },
  { value: "t5ImportCSV", label: "T5 Import CSV" },
  { value: "deleteEmployee", label: "Delete Employee" },
  { value: "employeeCreate", label: "Employee Create" },
  { value: "employeeUpdate", label: "Employee Update" },
  { value: "employeeImportCSV", label: "Employee Import CSV" },
  { value: "moveEmployees", label: "Move Employees" },
  { value: "deleteT4ASlip", label: "Delete T4A Slip" },
  { value: "t4ASlipEmailed", label: "T4A Slip Emailed" },
  { value: "t4ASlipPrint", label: "T4A Slip Print" },
  { value: "t4ASlipCreate", label: "T4A Slip Create" },
  { value: "t4ASlipUpdate", label: "T4A Slip Update" },
  { value: "t4ASummaryUpdate", label: "T4A Summary Update" },
  { value: "deleteT4aConfirmationNo", label: "Delete T4A Confirmation No" },
  { value: "t4ASummaryPrintPDF", label: "T4A Summary Print PDF" },
  { value: "t4AImportCSV", label: "T4A Import CSV" },
  { value: "vendorDelete", label: "Vendor Delete" },
  { value: "vendorCreate", label: "Vendor Create" },
  { value: "vendorUpdate", label: "Vendor Update" },
  { value: "T5RecipientDelete", label: "T5 Recipient Delete" },
  { value: "T5RecipientCreate", label: "T5 Recipient Create" },
  { value: "T5RecipientUpdate", label: "T5 Recipient Update" },
  { value: "apiKeysGenerated", label: "Api Keys Generated" },
  { value: "invoicePaid", label: "Invoice Paid" },
  { value: "invoiceEmail", label: "Invoice Email" },
  { value: "invoiceGenerated", label: "Invoice Generated" },
  { value: "invoiceUpdated", label: "Invoice Updated" },
  { value: "invoiceRecalculated", label: "Invoice Recalculated" },
  { value: "invoicePrintPDF", label: "Invoice Print PDF" },
  { value: "clientProfileUpdate", label: "Client Profile Update" },
  { value: "adjustmentOptionsUpdate", label: "Adjustment Options Update" },
  { value: "t4Adjustments", label: "T4 Adjustments" },
  { value: "t4UndoAdjustments", label: "T4 Undo Adjustments" },
  { value: "rl1Adjustments", label: "RL-1 Adjustments" },
  { value: "rl1UndoAdjustments", label: "RL-1 Undo Adjustments" },
  { value: "remittanceTransfers", label: "Remittance Transfers" },
  { value: "undoRemittanceTransfers", label: "Undo Remittance Transfers" },
  { value: "ignoreRemittanceTransfers", label: "Ignore Remittance Transfers" },
  {
    value: "undoIgnoreRemittanceTransfers",
    label: "Undo Ignore Remittance Transfers",
  },
  { value: "deleteRL1Slip", label: "Delete RL-1 Slip" },
  { value: "rl1SlipEmailed", label: "RL-1 Slip Emailed" },
  { value: "rl1SlipPDFPrint", label: "RL-1 Slip PDF Print" },
  { value: "rl1SlipXMLPrint", label: "RL-1 Slip XML Print" },
  { value: "rl1SlipCreate", label: "RL-1 Slip Create" },
  { value: "rl1SlipUpdate", label: "RL-1 Slip Update" },
  { value: "rl1SettingUpdate", label: "RL-1 Setting Update" },
  { value: "rl1SummaryUpdate", label: "RL-1 Summary Update" },
  { value: "deleteRL1ConfirmationNo", label: "Delete RL1 Confirmation No" },
  { value: "rl1SummaryPrintPDF", label: "RL-1 Summary Print PDF" },
  { value: "rl1ImportXML", label: "RL-1 Import XML" },
  { value: "rl1ImportCSV", label: "RL-1 Import CSV" },
];
