import { Grid } from "@mui/material";
import {
  EnhancedDropDownRHFWithLabel,
  FieldLayout,
  IEnhancedDropDownItem,
  LabelPosition,
  RHookFormDatePicker,
  SubmitButton,
  SubmitButtonVariant,
} from "@websential/cosmic";
import { Calendar } from "primereact/calendar";
import React, { useEffect, useMemo, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./ActivityLogDataTableStyle.css";
import Select from "react-select";
import { useController } from "react-hook-form";
import { AuditActivityOptions, 
  T4ImportCSVActivityOptions, 
  T4ListActivityOptions,
   T4ImportXMLActivityOptions, 
  T4SetupActivityOptions,
  //  T4SimplePayActivityOptions, 
  T4SummaryActivityOptions,
  T5ImportCSVActivityOptions, 
  T5ListActivityOptions,
   T5ImportXMLActivityOptions, 
  T5SetupActivityOptions,
  //  T5SimplePayActivityOptions, 
  T5SummaryActivityOptions, 
  FilingResourceListActivityOptions, 
  FilingResourceSetupActivityOptions, 
  // ContactListActivityOptions,
  // ContactSetupActivityOptions,
  EmployeeListActivityOptions,
  EmployeeSetupActivityOptions,
  EmployeeImportCSVActivityOptions,
  MoveEmployeeActivityOptions,
  T4AListActivityOptions,
  T4ASetupActivityOptions,
  T4ASummaryActivityOptions,
  // RL1SimplePayActivityOptions,
  T4AImportCSVActivityOptions,
  // IssuertSetupActivityOptions,
  VendorListActivityOptions,
  VendorSetupActivityOptions,
  T5RecipientListActivityOptions,
  T5RecipientSetupActivityOptions,
  RL1ImportXMLActivityOptions,
  // IssuertListActivityOptions,
  RL1ImportCSVActivityOptions,
  // UserDefaultSettingActivityOptions,
  UserApiKeysActivityOptions,
  UserInvoiceReportActivityOptions,
  UserCreateProfileActivityOptions,
  AdjustmentOptionsActivityOptions,
  AdjustmentsActivityOptions,
  RL1ListActivityOptions,
  RL1SetupActivityOptions,
  RL1SettingActivityOptions,
  RL1SummaryActivityOptions,
  groupedOptions, 
  allActivityTypes,
  MoveVendorActivityOptions,
  MoveT5RecipientActivityOptions,
  MoveT3BeneficiaryActivityOptions} from "./ActivityLogHeaderDDLsOptions";
import { IDropDownListResponse } from "models";

export interface IActivityLogReportHeadero {
  isSubmitting: boolean;
  formMethods: any;
  userDDL: any;
}
export const ActivityLogReportHeader: React.FC<IActivityLogReportHeadero> = ({
  isSubmitting,
  formMethods,
  userDDL,
}) => {
  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({
    name: "screen",
    defaultValue: "all",
  });
  const [activityTypeDDL, setActivityTypeDDL] = useState(allActivityTypes)
  const onScreenSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
    if(item.value === 'earnings_audit'){
      setActivityTypeDDL(AuditActivityOptions)
      formMethods.setValue("activityType","ignore_insurable_earnings")
    } else if(item.value === 'filing_resource_list'){
      setActivityTypeDDL(FilingResourceListActivityOptions)
      formMethods.setValue("activityType","delete")
    } else if(item.value === 'filing_resource_setup'){
      setActivityTypeDDL(FilingResourceSetupActivityOptions)
      formMethods.setValue("activityType","create")
    } else if(item.value === 't4_list'){
      setActivityTypeDDL(T4ListActivityOptions)
      formMethods.setValue("activityType","delete")
    } else if(item.value === 't4_setup'){
      setActivityTypeDDL(T4SetupActivityOptions)
      formMethods.setValue("activityType","create")
    } else if(item.value === 't4_summary'){
      setActivityTypeDDL(T4SummaryActivityOptions)
      formMethods.setValue("activityType","updated")
    } else if(item.value === 't4_import_xml'){
      setActivityTypeDDL(T4ImportXMLActivityOptions)
      formMethods.setValue("activityType","import_xml")
    } 
    // else if(item.value === 't4ImportXMLSimplePay'){
    //   setActivityTypeDDL(T4SimplePayActivityOptions)
    //   formMethods.setValue("activityType","t4ImportXMLSimplePay")
    // } 
    else if(item.value === 't4_import_csv'){
      setActivityTypeDDL(T4ImportCSVActivityOptions)
      formMethods.setValue("activityType","import_csv")
    } else if(item.value === 't5_list'){
      setActivityTypeDDL(T5ListActivityOptions)
      formMethods.setValue("activityType","delete")
    } else if(item.value === 't5_setup'){
      setActivityTypeDDL(T5SetupActivityOptions)
      formMethods.setValue("activityType","create")
    } else if(item.value === 't5_summary'){
      setActivityTypeDDL(T5SummaryActivityOptions)
      formMethods.setValue("activityType","updated")
    } else if(item.value === 't5_import_xml'){
      setActivityTypeDDL(T5ImportXMLActivityOptions)
      formMethods.setValue("activityType","import_xml")
    } 
    // else if(item.value === 't5ImportXMLSimplePay'){
    //   setActivityTypeDDL(T5SimplePayActivityOptions)
    //   formMethods.setValue("activityType","t5ImportXMLSimplePay")
    // } 
    else if(item.value === 't5_import_csv'){
      setActivityTypeDDL(T5ImportCSVActivityOptions)
      formMethods.setValue("activityType","import_csv")
    }
    //  else if(item.value === 'contactList'){
    //   setActivityTypeDDL(ContactListActivityOptions)
    //   formMethods.setValue("activityType","contactDelete")
    // } else if(item.value === 'contactSetup'){
    //   setActivityTypeDDL(ContactSetupActivityOptions)
    //   formMethods.setValue("activityType","contactCreate")
    // }
     else if(item.value === 'employee_list'){
      setActivityTypeDDL(EmployeeListActivityOptions)
      formMethods.setValue("activityType","delete")
    } else if(item.value === 'employee_setup'){
      setActivityTypeDDL(EmployeeSetupActivityOptions)
      formMethods.setValue("activityType","create")
    } else if(item.value === 'employee_import_csv'){
      setActivityTypeDDL(EmployeeImportCSVActivityOptions)
      formMethods.setValue("activityType","import_csv")
    } else if(item.value === 'employee_move_year'){
      setActivityTypeDDL(MoveEmployeeActivityOptions)
      formMethods.setValue("activityType","move_employees")
    } else if(item.value === 'vendor_move_year'){
      setActivityTypeDDL(MoveVendorActivityOptions)
      formMethods.setValue("activityType","move_vendors")
    } else if(item.value === 't5_recipient_move_year'){
      setActivityTypeDDL(MoveT5RecipientActivityOptions)
      formMethods.setValue("activityType","move_t5_recipients")
    } else if(item.value === 't3_beneficiary_move_year'){
      setActivityTypeDDL(MoveT3BeneficiaryActivityOptions)
      formMethods.setValue("activityType","move_t3_beneficiary")
    } else if(item.value === 't4a_list'){
      setActivityTypeDDL(T4AListActivityOptions)
      formMethods.setValue("activityType","delete")
    } else if(item.value === 't4a_setup'){
      setActivityTypeDDL(T4ASetupActivityOptions)
      formMethods.setValue("activityType","create")
    } else if(item.value === 't4a_summary'){
      setActivityTypeDDL(T4ASummaryActivityOptions)
      formMethods.setValue("activityType","updated")
    } else if(item.value === 't4a_import_csv'){
      setActivityTypeDDL(T4AImportCSVActivityOptions)
      formMethods.setValue("activityType","import_csv")
    } else if(item.value === 'vendor_list'){
      setActivityTypeDDL(VendorListActivityOptions)
      formMethods.setValue("activityType","delete")
    } else if(item.value === 'vendor_setup'){
      setActivityTypeDDL(VendorSetupActivityOptions)
      formMethods.setValue("activityType","create")
    } else if(item.value === 't5_recipient_list'){
      setActivityTypeDDL(T5RecipientListActivityOptions)
      formMethods.setValue("activityType","delete")
    } else if(item.value === 't5_recipient_setup'){
      setActivityTypeDDL(T5RecipientSetupActivityOptions)
      formMethods.setValue("activityType","create")
    } 
    // else if(item.value === 'userDefaultSetting'){
    //   setActivityTypeDDL(UserDefaultSettingActivityOptions)
    //   formMethods.setValue("activityType","defaultSettingUpdate")
    // } 
    else if(item.value === 'user_api_keys'){
      setActivityTypeDDL(UserApiKeysActivityOptions)
      formMethods.setValue("activityType","api_keys_generated")
    } else if(item.value === 'user_invoice_report'){
      setActivityTypeDDL(UserInvoiceReportActivityOptions)
      formMethods.setValue("activityType","paid")
    } else if(item.value === 'user_client_profile'){
      setActivityTypeDDL(UserCreateProfileActivityOptions)
      formMethods.setValue("activityType","updated")
    } else if(item.value === 'adjustment_options'){
      setActivityTypeDDL(AdjustmentOptionsActivityOptions)
      formMethods.setValue("activityType","updated")
    } else if(item.value === 'adjustment'){
      setActivityTypeDDL(AdjustmentsActivityOptions)
      formMethods.setValue("activityType","t4_adjustments")
    } else if(item.value === 'rl1_list'){
      setActivityTypeDDL(RL1ListActivityOptions)
      formMethods.setValue("activityType","delete")
    } else if(item.value === 'rl1_setup'){
      setActivityTypeDDL(RL1SetupActivityOptions)
      formMethods.setValue("activityType","create")
    } else if(item.value === 'rl1_settings'){
      setActivityTypeDDL(RL1SettingActivityOptions)
      formMethods.setValue("activityType","updated")
    } else if(item.value === 'rl1_summary'){
      setActivityTypeDDL(RL1SummaryActivityOptions)
      formMethods.setValue("activityType","updated")
    } else if(item.value === 'rl1_import_csv'){
      setActivityTypeDDL(RL1ImportCSVActivityOptions)
      formMethods.setValue("activityType","import_csv")
    } else if(item.value === 'rl1_import_xml'){
      setActivityTypeDDL(RL1ImportXMLActivityOptions)
      formMethods.setValue("activityType","import_xml")
    } 
    // else if(item.value === 'rl1ImportXMLSimplePay'){
    //   setActivityTypeDDL(RL1SimplePayActivityOptions)
    //   formMethods.setValue("activityType","rl1ImportXMLSimplePay")
    // } 
    // else if(item.value === 'issuerList'){
    //   setActivityTypeDDL(IssuertListActivityOptions)
    //   formMethods.setValue("activityType","issuertDelete")
    // } else if(item.value === 'issuerSetup'){
    //   setActivityTypeDDL(IssuertSetupActivityOptions)
    //   formMethods.setValue("activityType","issuertCreate")
    // }
     else {
      setActivityTypeDDL(allActivityTypes)
      formMethods.setValue("activityType","all")
    } 
    console.log("itemmmm",item)
  };
  const onDDLChange = (choosedItem: IEnhancedDropDownItem) => {
    if (choosedItem) {
      onChange(choosedItem.value);
      if (onScreenSelectionChangeHandler) {
        onScreenSelectionChangeHandler(choosedItem);
      }
    } else {
      onChange(null);
      if (onScreenSelectionChangeHandler) {
        onScreenSelectionChangeHandler({label:"All",value:"all"});
      }
    }
  };

  useEffect(() => {
    const twoWeeksBeforeDate = new Date();
    twoWeeksBeforeDate.setDate(twoWeeksBeforeDate.getDate() - 14);
    formMethods.setValue(
      "startDate",
      // new Date("Wed Feb 28 2024 00:00:00 GMT+0500")
      twoWeeksBeforeDate.toDateString()
    );
    formMethods.setValue(
      "endDate",
      // new Date("Sun Mar 03 2024 00:00:00 GMT+0500")
      new Date()
    );
  }, []);

  const formatGroupLabel = (data: any) => {
    const isFirstChild = data.options && data.options.length > 0 && data.options[0].value === "earnings_audit";
    console.log("data.options[0].value",data.options[0].value)
    console.log("isFirstChild",isFirstChild)
    const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0,
    margin: isFirstChild ? "-4px 0 0 0" : "-17px 0 -4px 0",
    color: "#000",
  };

  // useEffect(()=>{
  //   let selectedScreen = formMethods.getValues('screen')
  //   console.log("selectedScreen",selectedScreen)
  // },[])
return (
    <div style={groupStyles}>
      <span style={{textTransform:"capitalize"}}>
        <b>{data.label}</b>
      </span>

      {/* <span style={{backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center'}}>{data.options.length}</span> */}
    </div>
)
      };

  // console.log("value0-", value);

  const customStyles = {
    option: (provided:any, state:any) => ({
      ...provided,
      fontSize:"13px",
      margin:0,
      padding:"2px 2px 2px 2rem",
    }),
    control: (provided:any) => ({
      ...provided,
      marginTop: "8px",
    }),
    singleValue: (provided:any) => ({
      ...provided,
      fontSize: "13px",
    }),
  };
  console.log("activityTypeDDL[0].value",activityTypeDDL[0].value)

  const userDDLItems = useMemo(() => {
    if (userDDL && Array.isArray(userDDL)) {
      let users: any[] | any = userDDL.map(
        (userItem: any) => ({
          id: userItem.id,
          label: userItem.name,
          value: userItem.name,
        })
      );

      users.unshift({
        label: "All",
        value: 'all',
      });
      return users;
    }

    return [
      {
        label: "All",
        value: 'all',
      },
    ];
  }, [userDDL]);

  console.log("userDDL",userDDL)
  console.log("userDDLItems",userDDLItems)

  return (
    <>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          margin: "0.5rem",
          border: "2px solid #dee2e6",
          width: "auto",
          padding: "2rem 5rem 1rem 5rem",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
          <FieldLayout
            label="Screens"
            testId=""
            labelPosition={LabelPosition.Left}
            labelColumns={4}
            fieldColumns={8}
          >
            <Select
              id="screen"
              {...formMethods.register("screen")}
              onChange={(val: any) => onDDLChange(val)}
              defaultValue={groupedOptions[0]}
              error={!!errors["screen"]}
              options={groupedOptions}
              formatGroupLabel={formatGroupLabel}
              styles={customStyles}
            />
          </FieldLayout>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={8}
          lg={8}
          xl={8}
          sx={{ 
            maxWidth: "60%!important"
           }}
        >
          <EnhancedDropDownRHFWithLabel
            selectName="activityType"
            label="Activity Type"
            labelPosition={LabelPosition.Left}
            items={activityTypeDDL}
            fieldColumns={9}
            labelColumns={3}
            selectedItem={activityTypeDDL[0].value}
            testId={"activityType"}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <EnhancedDropDownRHFWithLabel
            selectName="user"
            label="Users"
            labelPosition={LabelPosition.Left}
            items={userDDLItems && userDDLItems}
            fieldColumns={8}
            labelColumns={4}
            selectedItem={userDDLItems[0].value}
            testId={"users"}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          sx={{ display: "flex", paddingTop: "4px", maxWidth: "60%!important" }}
        >
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            sx={{ paddingTop: "0.75rem" }}
          >
            <span
              style={{
                fontSize: "0.875rem",
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontWeight: 500,
                letterSpacing: "0.00714em",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Select Date Range
            </span>
          </Grid>
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "45%" }}>
                <RHookFormDatePicker
                  name="startDate"
                  testId="invoice-admin-report-start-date"
                  label=""
                  labelPosition={LabelPosition.Top}
                />
              </div>
              <span
                style={{
                  fontSize: "0.875rem",
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: 500,
                  letterSpacing: "0.00714em",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                to
              </span>
              <div style={{ width: "45%" }}>
                <RHookFormDatePicker
                  name="endDate"
                  testId="invoice-admin-report-end-date"
                  label=""
                  labelPosition={LabelPosition.Top}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ marginTop: "1.5rem" }}
        >
          <SubmitButton
            label="Apply Filter"
            testId="submit-button"
            loading={isSubmitting}
            variant={SubmitButtonVariant.Primary}
            // icon={<KeyboardArrowRightIcon />}
          />
        </Grid>
      </Grid>
    </>
  );
};
