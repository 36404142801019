import { AxiosResponse } from "axios";
import http from "common/http-common";
import { IResponse, IServerError } from "../models";

export const PrintingService = {
  createT4Xml: async (
    clientId: string,
    slipType: number,
    reducedEi: boolean,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      console.log("t4xmlResponsedata", clientId);
      const response: AxiosResponse | any = await http.post(
        `/v1/xml-printing/t4/?client_id=${clientId}&slip_type=${slipType}&reduced_ei=${reducedEi}&tax_year=${taxYear}`
      );

      if (response.status === 201) {
        console.log("t4xmlResponsedata", response.data);
        return response.data;
      } else if (response.status === 400) {
        return response;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  createT4AXml: async (
    clientId: string,
    slipType: number,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const response: AxiosResponse = await http.post(
        `/v1/xml-printing/t4a/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        return {
          status: false,
          message: "Bad Request.",
          error: true,
        };
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  createT5Xml: async (
    clientId: string,
    slipType: number,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const response: AxiosResponse = await http.post(
        `/v1/xml-printing/t5/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        return {
          status: false,
          message: "Bad Request.",
          error: true,
        };
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createT3Xml: async (
    clientId: string,
    slipType: number,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const response: AxiosResponse = await http.post(
        `/v1/xml-printing/t3/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        return {
          status: false,
          message: "Bad Request.",
          error: true,
        };
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  
  createRL1Xml: async (
    clientId: string,
    slipType: number,
    taxYear: Number,
    pdfIds:any
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        slip_type: slipType,
        tax_year: taxYear,
      };
      const response: AxiosResponse | any = await http.post(
        `/v1/xml-printing/rl1/?client_id=${clientId}&slip_type=${slipType}&${pdfIds}&tax_year=${taxYear}`
      );
console.log("responswefe",response)
      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        return {
          status: false,
          message: response.data.message ? response.data.message : "Bad Request.",
          error: true,
        };
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  createRL3Xml: async (
    clientId: string,
    slipType: number,
    taxYear: Number,
    pdfIds:any
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const response: AxiosResponse = await http.post(
        `/v1/xml-printing/rl3/?client_id=${clientId}&slip_type=${slipType}&${pdfIds}&tax_year=${taxYear}`
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        return {
          status: false,
          message: "Bad Request.",
          error: true,
        };
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createRL16Xml: async (
    clientId: string,
    slipType: number,
    taxYear: Number,
    pdfIds:any
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const response: AxiosResponse = await http.post(
        `/v1/xml-printing/rl16/?client_id=${clientId}&slip_type=${slipType}&${pdfIds}&tax_year=${taxYear}`
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        return {
          status: false,
          message: "Bad Request.",
          error: true,
        };
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },


  createSingleT4PdfPrint: async (
    clientId: string,
    resourceId: string,
    slipType: number,
    printType: string,
    provinceShortCode: string,
    taxYear: number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        resource_id: resourceId,
        slip_type: slipType,
        print_type: printType,
        province_short_code: provinceShortCode,
        tax_year: taxYear,
      };
      const response: AxiosResponse | any = await http.post(
        `/v1/slips-pdf-printing/t4/?client_id=${clientId}&resource_id=${resourceId}&slip_type=${slipType}&print_type=${printType}&province_short_code=${provinceShortCode}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );
      if (response.status === 201) {
        return response.data;
      }else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  createSingleT4APdfPrint: async (
    clientId: string,
    resourceId: string,
    slipType: number,
    printType: string,
    provinceShortCode: string,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        resource_id: resourceId,
        slip_type: slipType,
        print_type: printType,
        province_short_code: provinceShortCode,
        tax_year: taxYear,
      };
      const response: AxiosResponse | any = await http.post(
        `/v1/slips-pdf-printing/t4a/?client_id=${clientId}&resource_id=${resourceId}&slip_type=${slipType}&print_type=${printType}&province_short_code=${provinceShortCode}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      }else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status !== 201) {
        alert(response.status);
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createSingleT5PdfPrint: async (
    clientId: string,
    resourceId: string,
    slipType: number,
    printType: string,
    provinceShortCode: string,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        resource_id: resourceId,
        slip_type: slipType,
        print_type: printType,
        province_short_code: provinceShortCode,
        tax_year: taxYear,
      };
      const response: AxiosResponse | any = await http.post(
        `/v1/slips-pdf-printing/t5/?client_id=${clientId}&resource_id=${resourceId}&slip_type=${slipType}&print_type=${printType === 'Recipient' ? '20' : printType === 'Payer' ? '10' : printType === 'CRA' ? '30' : ''}&province_short_code=${provinceShortCode}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      }else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status !== 201) {
        alert(response.status);
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  createSingleRL1PdfPrint: async (
    clientId: string,
    resourceId: string,
    slipType: number,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        resource_id: resourceId,
        slip_type: slipType,
        tax_year: taxYear,
      };
      const response: AxiosResponse | any = await http.post(
        `/v1/slips-pdf-printing/rl1/?client_id=${clientId}&resource_id=${resourceId}&slip_type=${slipType}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      }else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  createBulkT4PdfPrint: async (
    clientId: string,
    printType: string,
    t4SlipIds: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        print_type: printType,
        t4SlipIds,
        tax_year: taxYear,
      };
      const url = `/v1/slips-pdf-printing/bulk-t4/?client_id=${clientId}&print_type=${printType}&${t4SlipIds}&tax_year=${taxYear}`;
      const response: any = await http.post(url, requestData, {
        responseType: "arraybuffer",
      });
      // const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createBulkT4PdfEmail: async (
    clientId: string,
    emailId: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const url = `/v1/email-generate/t4/?client_id=${clientId}&email_id=${emailId}&tax_year=${taxYear}`;
      const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response;
      } else if (response.status === 400) {
        return response;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createBulkT4APdfPrint: async (
    clientId: string,
    printType: string,
    t4aSlipIds: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        print_type: printType,
        t4aSlipIds,
        tax_year: taxYear,
      };
      const response: AxiosResponse | any = await http.post(
        `/v1/slips-pdf-printing/bulk-t4a/?client_id=${clientId}&print_type=${printType}&${t4aSlipIds}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      }else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  createBulkT4APdfEmail: async (
    clientId: string,
    emailId: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const url = `/v1/email-generate/t4a/?client_id=${clientId}&email_id=${emailId}&tax_year=${taxYear}`;
      const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response;
      } else if (response.status === 400) {
        return response;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createBulkT5PdfPrint: async (
    clientId: string,
    printType: string,
    t5SlipIds: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        print_type: printType,
        t5SlipIds,
        tax_year: taxYear,
      };
      const url = `/v1/slips-pdf-printing/bulk-t5/?client_id=${clientId}&print_type=${printType}&${t5SlipIds}&tax_year=${taxYear}`;
      const response: any = await http.post(url, requestData, {
        responseType: "arraybuffer",
      });
      // const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createBulkT3PdfPrint: async (
    clientId: string,
    printType: string,
    t3SlipIds: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        print_type: printType,
        t3SlipIds,
        tax_year: taxYear,
      };
      const url = `/v1/slips-pdf-printing/bulk-t3/?client_id=${clientId}&print_type=${printType}&${t3SlipIds}&tax_year=${taxYear}`;
      const response: any = await http.post(url, requestData, {
        responseType: "arraybuffer",
      });
      // const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createBulkRL3PdfPrint: async (
    clientId: string,
    // printType: string,
    rl3SlipIds: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        // print_type: printType,
        rl3SlipIds,
        tax_year: taxYear,
      };
      const response: any = await http.post(
        `/v1/slips-pdf-printing/bulk-rl3/?client_id=${clientId}&${rl3SlipIds}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );
      // const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createBulkRL16PdfPrint: async (
    clientId: string,
    // printType: string,
    rl16SlipIds: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        // print_type: printType,
        rl16SlipIds,
        tax_year: taxYear,
      };
      const response: any = await http.post(
        `/v1/slips-pdf-printing/bulk-rl16/?client_id=${clientId}&${rl16SlipIds}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );
      // const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createBulkRL1PdfEmail: async (
    clientId: string,
    emailId: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const url = `/v1/email-generate/rl1/?client_id=${clientId}&email_id=${emailId}&tax_year=${taxYear}`;
      const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response;
      } else if (response.status === 400) {
        return response;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  createBulkRL1PdfPrint: async (
    clientId: string,
    rl1SlipIds: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        rl1SlipIds,
        tax_year: taxYear,
      };
      const response: any = await http.post(
        `/v1/slips-pdf-printing/bulk-rl1/?client_id=${clientId}&${rl1SlipIds}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  createT4SummaryPdfPrint: async (
    clientId: string,
    slipType: number,
    reduceEi: boolean,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        slip_type: slipType,
        reduce_ei: reduceEi,
        tax_year: taxYear,
      };
      const response: AxiosResponse | any = await http.post(
        `/v1/summary-pdf-printing/t4/?client_id=${clientId}&slip_type=${slipType}&reduce_ei=${reduceEi}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  createT4ASummaryPdfPrint: async (
    clientId: string,
    slipType: number,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        slip_type: slipType,
        tax_year: taxYear,
      };
      const response: AxiosResponse | any= await http.post(
        `/v1/summary-pdf-printing/t4a/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  createRL1SummaryPdfPrint: async (
    clientId: string,
    slipType: number,
    taxYear: Number,
    tag:string
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        slip_type: slipType,
        tax_year: taxYear,
      };
      // const response: AxiosResponse | any = await http.post(
      //   `/v1/summary-pdf-printing/rl1/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`,
      //   requestData,
      //   {
      //     responseType: "arraybuffer",
      //   }
      // );
      const response: AxiosResponse | any = await http.post(
        `/v1/summary-pdf-printing/${tag}/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  deleteT4SummaryConfirmationCode: async (
    clientId: string,
    slipType: number,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        slip_type: slipType,
        tax_year: taxYear,
      };
      const response: AxiosResponse = await http.post(
        `/v1/t4-summary/clear-confirmation-code/${clientId}/?slip_type=${slipType}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  deleteT4ReduceEiSummaryConfirmationCode: async (
    clientId: string,
    slipType: number,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        slip_type: slipType,
        tax_year: taxYear,
      };
      const response: AxiosResponse = await http.post(
        `/v1/t4-summary-reduced-ei/clear-confirmation-code/${clientId}/?slip_type=${slipType}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  deleteT4ASummaryConfirmationCode: async (
    clientId: string,
    slipType: number,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        slip_type: slipType,
        tax_year: taxYear,
      };
      const response: AxiosResponse = await http.post(
        `/v1/t4a-summary/clear-confirmation-code/${clientId}/?slip_type=${slipType}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  deleteRL1SummaryConfirmationCode: async (
    clientId: string,
    slipType: number,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        slip_type: slipType,
        tax_year: taxYear,
      };
      const response: AxiosResponse = await http.post(
        `/v1/rl1-summary/clear-confirmation-code/${clientId}/?slip_type=${slipType}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  invoicePdfPring: async (
    clientId: string,
    refNo: string
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        ref_no: refNo,
      };
      const response: AxiosResponse = await http.post(
        `/v1/invoice-pdf-printing/?client_id=${clientId}&ref_no=${refNo}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createAllSlipsPdfPrint: async (
    clientId: string,
    printType: string,
    tag: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        print_type: printType,
        tag: tag,
        tax_year: taxYear,
      };
      const url = `/v1/select-all-slip-for-printing/${clientId}?slip_tag=${tag}&tax_year=${taxYear}&print_type=${printType}`;
      const response: any = await http.get(url, {
        responseType: "arraybuffer",
      });
      // const response: any = await http.get(url, requestData, {
      //   responseType: "arraybuffer",
      // });
      // const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createBulkT5PdfEmail: async (
    clientId: string,
    emailId: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const url = `/v1/email-generate/t5/?client_id=${clientId}&email_id=${emailId}&tax_year=${taxYear}`;
      const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response;
      } else if (response.status === 400) {
        return response;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createBulkT3PdfEmail: async (
    clientId: string,
    emailId: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const url = `/v1/email-generate/t3/?client_id=${clientId}&email_id=${emailId}&tax_year=${taxYear}`;
      const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response;
      } else if (response.status === 400) {
        return response;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createBulkRL3PdfEmail: async (
    clientId: string,
    emailId: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const url = `/v1/email-generate/rl3/?client_id=${clientId}&email_id=${emailId}&tax_year=${taxYear}`;
      const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response;
      } else if (response.status === 400) {
        return response;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createBulkRL16PdfEmail: async (
    clientId: string,
    emailId: string,
    taxYear: Number
  ): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const url = `/v1/email-generate/rl16/?client_id=${clientId}&email_id=${emailId}&tax_year=${taxYear}`;
      const response = await http.post(url)
      console.log("response12", response);
      if (response.status === 201) {
        return response;
      } else if (response.status === 400) {
        return response;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createT5SummaryPdfPrint: async (
    clientId: string,
    slipType: number,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        slip_type: slipType,
        tax_year: taxYear,
      };
      const response: AxiosResponse | any= await http.post(
        `/v1/summary-pdf-printing/t5/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createT3SummaryPdfPrint: async (
    clientId: string,
    slipType: number,
    taxYear: Number
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        slip_type: slipType,
        tax_year: taxYear,
      };
      const response: AxiosResponse | any= await http.post(
        `/v1/summary-pdf-printing/t3/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createRL3SummaryPdfPrint: async (
    clientId: string,
    slipType: number,
    taxYear: Number,
    tag:string
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        slip_type: slipType,
        tax_year: taxYear,
      };
      const response: AxiosResponse | any= await http.post(
        `/v1/summary-pdf-printing/${tag}/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createRL16SummaryPdfPrint: async (
    clientId: string,
    slipType: number,
    taxYear: Number,
    tag:string
  ): Promise<IServerError | IResponse> => {
    if (clientId) {
      const requestData = {
        client_id: clientId,
        slip_type: slipType,
        tax_year: taxYear,
      };
      const response: AxiosResponse | any= await http.post(
        `/v1/summary-pdf-printing/${tag}/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`,
        requestData,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.status === 201) {
        return response.data;
      } else if (response.status === 400) {
        const text = new TextDecoder().decode(response.data);
        const responseObject = JSON.parse(text);
        let response400 = {
          ...response,
          data:responseObject
        }
        return response400;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
};
