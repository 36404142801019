import { useNavigate } from "react-router-dom";

export const BillingMenuComponent = () => {
const navigate = useNavigate();
const ArrayData = [
  {
    label: "Profile",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/billing-profile/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/billing-profile/setup")
        },
      }
    ],  },
  {
    label: "Tax",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/tax-profile/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/tax-profile/setup")
        },
      }
    ],  },
  {
    label: "Profile Associate",
    command: () => {
          navigate("/billing-profile-association")
        },
  }
];
return {
  BillingMenu:ArrayData
}
}

