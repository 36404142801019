import { AxiosResponse } from 'axios';
import http from 'common/http-common';
import { IDropDownListResponse } from 'models';
import { IDataModelResponsePayload, IGetListRequest, IResponse, IServerError } from 'models/common';
import { IBillingProfileScreenPanelsAssignmentResponsePayload, ITaxProfileListModel } from 'models/taxProfile';
import { ITaxProfileSetupRequest } from 'models/taxProfileSetup';

const API_BASE_RESOURCE_URL = '/v1/tax-profiles/';

export const TaxProfileService = {
  getList: async (
    params: IGetListRequest
  ): Promise<
    ITaxProfileListModel[] | IServerError | IResponse
  > => {
    const response: AxiosResponse<ITaxProfileListModel[]> = await http.get<ITaxProfileListModel[]>(API_BASE_RESOURCE_URL);

    if (response.status === 200) return response.data;

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  delTaxProfile: async (taxProfileId: number): Promise<string | IServerError | IResponse> => {
    console.log('deleteById', taxProfileId);
    const response: AxiosResponse<string> = await http.delete<string>(`/v1/tax-profiles/${taxProfileId}/`);

    if (response.status === 200) {
      return "Tax Profile has been deleted successfully";
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  create: async (requestPayload: ITaxProfileSetupRequest): Promise<IDataModelResponsePayload<ITaxProfileSetupRequest>> => {
    console.log('===-', requestPayload);
    if (requestPayload.id) {
      const response: AxiosResponse<ITaxProfileSetupRequest> = await http.put<ITaxProfileSetupRequest>(
        `${API_BASE_RESOURCE_URL}${requestPayload.id}/`, requestPayload);

      if (response.status === 200) return { success: true, message: "Record has been updated successfully", data: response.data }

      return { success: false, message: "Something went wrong please try again later." };
    }

    const response: AxiosResponse<ITaxProfileSetupRequest> = await http.post<ITaxProfileSetupRequest>(
      API_BASE_RESOURCE_URL, requestPayload);

    if (response.status === 201) return { success: true, message: "Record has been added successfully", data: response.data }

    return { success: false, data: response.data }
  },
  // getById: async (id: string | undefined): Promise<IDataModelResponsePayload<ITaxProfileSetupRequest> | null> => {
  //   console.log('===-', id);
  //   if (!id) return null
  //   const response: AxiosResponse<ITaxProfileSetupRequest> = await http.get<ITaxProfileSetupRequest>(
  //     `${API_BASE_RESOURCE_URL}${id}/`);

  //   if (response.status === 200) return { success: true, data: response.data }

  //   return { success: false, data: response.data }
  // },
  getById: async (id: string | undefined): Promise<ITaxProfileSetupRequest | IServerError | IResponse | null> => {
    console.log('===-', id);
  if (id) {
    const response: AxiosResponse<ITaxProfileSetupRequest> = await http.get<ITaxProfileSetupRequest>(
      `/v1/tax-profiles/${id}/`);
      console.log("id in getbyis in service: ",id)

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  }

  return null;
},
getProfilesDropDownList: async (): Promise<IDropDownListResponse[] | IServerError | IResponse> => {
  const response: AxiosResponse<IDropDownListResponse[]> = await http.get<IDropDownListResponse[]>(
    '/v1/billing-profiles/ddl-list');

  if (response.status === 200) {
    return response.data;
  }
  else if (response.status === 500) {
    return {
      status: false,
      message: "Something went wrong.",
      error: true,
    };
  }

  return {
    status: false,
    message: "Something went wrong please try again later.",
  };
},
getAccountantsDropDownList: async (): Promise<IDropDownListResponse[] | IServerError | IResponse | any> => {
  const response: AxiosResponse<IDropDownListResponse[]> = await http.get<IDropDownListResponse[]>(
    '/v1/accountants/ddl-acc-title/accountants/');

  if (response.status === 200) {
    return response.data;
  }
  else if (response.status === 500) {
    return {
      status: false,
      message: "Something went wrong.",
      error: true,
    };
  }

  return {
    status: false,
    message: "Something went wrong please try again later.",
  };
  // return[
  //     { label: "Accountant 1", value: "1" },
  //     { label: "Accountant 2", value: "2" },
  //   ]
},
getPanelsAssignedToScreen: async (accountantId: number | string, billingProfileId: number | string): Promise<IBillingProfileScreenPanelsAssignmentResponsePayload | IServerError | IResponse | null> => {
  const response: AxiosResponse<IBillingProfileScreenPanelsAssignmentResponsePayload> = await http.get<IBillingProfileScreenPanelsAssignmentResponsePayload>(
    `/v1/billing-profilesbilling-profile-association-clients/${billingProfileId}?accountant_id=${accountantId}`);

  if (response.status === 200) {
    return response.data;
  }
  else if (response.status === 500) {
    return {
      status: false,
      message: "Something went wrong.",
      error: true,
    };
  }

  return {
    status: false,
    message: "Something went wrong please try again later.",
  };
  // return{
  //   "accountantId": "5e26cc28-b9eb-41a7-a191-1d29d7589bb3",
  //   "billingId": "6478a4173e528aabe2f47a59",
  //   "assignedClients": [
  //     {
  //       "id": "1",
  //       "text": "by client id",
  //       "value": "a2cc0757-16ee-4e6a-90d2-f1af8dfc276b"
  //     },
  //     {
  //       "id": "2",
  //       "text": "new_client1",
  //       "value": "c6ae3c19-a8c7-4983-b23e-7457f2897638"
  //     }
  //   ],
  //   "unassignedClients": [
  //     {
  //       "id": "2fd1feae-2019-435f-b737-20752b7027d1",
  //       "text": "new_client23",
  //       "value": "2fd1feae-2019-435f-b737-20752b7027d1"
  //     },
  //     {
  //       "id": "47a36417-ab9b-4957-96dd-3ac9d1d1af7f",
  //       "text": "powerplay",
  //       "value": "47a36417-ab9b-4957-96dd-3ac9d1d1af7f"
  //     },
  //     {
  //       "id": "53dcfec2-4232-4799-9c99-9b14d81ae7be",
  //       "text": "QC_CLient",
  //       "value": "53dcfec2-4232-4799-9c99-9b14d81ae7be"
  //     },
  //     {
  //       "id": "93432a67-112a-412b-8461-4cdb4576937d",
  //       "text": "slips_check",
  //       "value": "93432a67-112a-412b-8461-4cdb4576937d"
  //     },
  //     {
  //       "id": "84bfae60-d8b1-414f-b563-5e3da4f9933a",
  //       "text": "slips_chk",
  //       "value": "84bfae60-d8b1-414f-b563-5e3da4f9933a"
  //     },
  //     {
  //       "id": "1ea326c2-c9b1-4204-a4d6-cf9eddcc4639",
  //       "text": "string11",
  //       "value": "1ea326c2-c9b1-4204-a4d6-cf9eddcc4639"
  //     },
  //     {
  //       "id": "1ad3d2ab-b466-4921-8e86-b59176e7576c",
  //       "text": "string111",
  //       "value": "1ad3d2ab-b466-4921-8e86-b59176e7576c"
  //     },
  //     {
  //       "id": "a39f6186-b9bf-48b9-a6c7-34d15baa7129",
  //       "text": "Toyota_local",
  //       "value": "a39f6186-b9bf-48b9-a6c7-34d15baa7129"
  //     }
  //   ]
  // }
},
createProfileAssociation: async (requestPayload: any): Promise<any | IServerError | IResponse> => {
  console.log('requestPayload ==>> add request', requestPayload);
  const response: AxiosResponse<any> = await http.put<any>(
    `/v1/billing-profiles/${requestPayload.profileId}/assign-clients/?client_id=${requestPayload.clientId}&association_flag=${requestPayload.flag}`, {"clients": requestPayload.clients}
    );

  if (response.status === 201) {
    return response.data;
  } else if (response.status === 200) {
    let responseWithFlag ={
      client:response.data,
      status:requestPayload.flag
    }
    return responseWithFlag;
    // return response.data;
  } else if (response.status === 400) {
    return {
      status: false,
      message: "400 Bad Request.",
      error: true,
    };
  }
  else if (response.status === 500) {
    return {
      status: false,
      message: "Something went wrong.",
      error: true,
    };
  }

  return {
    status: false,
    message: "Something went wrong please try again later.",
  };
},
};
