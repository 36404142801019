import { FC } from "react";

import { Grid } from "@mui/material";
import { ITest, LabelPosition, RHookFormMaskedTextFieldWithLabel, RHookFormTextFieldWithLabel } from "@websential/cosmic";

interface IRL16VariablesPanel extends ITest {}

export const RL16VariablesPanel: FC<IRL16VariablesPanel> = ({ testId }) => {
  return (
    <Grid
      container
      columnSpacing="6"
      rowSpacing="2"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Eligible Dividends (%)"
          id="eligibleDividendsRL16"
          testId={`${testId}-eligible-Dividends`}
        />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Ordinary Dividends (%)"
          id="ordinaryDividendsRL16"
          testId={`${testId}-ordinary-Dividends`}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Quebec Income Tax (%)"
          id="quebecIncomeTaxWithholdingRL16"
          testId={`${testId}-quebec-Income-Tax-With-holding`}
        />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
      <RHookFormMaskedTextFieldWithLabel
            label="Certification No"
            id="certificationNoRl16"
            testId={`${testId}-certificationNoRl16`}
            labelPosition={LabelPosition.Top}
            placeholder={"RQ-00-00-000"}
            inputMaskPattern={"aa-00-00-000"}
          />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormMaskedTextFieldWithLabel
            label="Authentication No"
            id="authenticationNoRl16"
            testId={`${testId}-authenticationNoRl16`}
            labelPosition={LabelPosition.Top}
            placeholder={"FS 0000000"}
            inputMaskPattern={"FS 0000000"}
          />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
      <RHookFormTextFieldWithLabel
            label="Barcode Initial"
            id="barcodeInitialRl16"
            testId={`${testId}-barcodeInitialRl16`}
            labelPosition={LabelPosition.Top}
            // placeholder={"RQ-00-00-000"}
            // inputMaskPattern={"aa-00-00-000"}
          />
      </Grid>
    </Grid>
  );
};
