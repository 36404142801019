import { checkIdentificationNumberValidity } from 'components/RL1Slip/RL1SlipSettingsSetup/RL1SettingValidation';
import { checkSINValidity } from 'components/T4Slip/T4SlipSetup/validator';
import * as yup from 'yup';
export const RL16SlipSettingValidationSchema = yup.object({
  transmitter: yup.string().required("Transmitter is required"),
  submissionMethod: yup.string().required("Submission Method is required!"),
  sourceOfRL16SlipsUsed: yup
  .string()
  .required("Source of RL-16 Slips Used is required!"),
  trustIdentificationNumber:yup.string().test(
    'is-valid-or-not',
    'Invalid value',
    function (value:any) {
      // if(value && value.length > 0){
      // let maxLength = 16;
      // console.log("value456",value)
      // const firstNineDigits: string = value && value.substring(0, 9);
      // console.log("valueeeee-- bn",firstNineDigits)
      // const output = checkSINValidity(firstNineDigits);
      // console.log("output bn",output)
      
      //   if (value && value.length == maxLength && output % 10 === 0) {
      //     return true;
      //   } else {
      //     return this.createError({
      //       message: 'Invalid Identification Number',
      //       path: this.path,
      //     });
      //   }
      // } else {
      //   return true
      // }

      let maxLength = 16;
      console.log("value456",value)
      const firstNineDigits: string = value && value.substring(0, 9);
      const tenthDigit = parseInt(value && value.charAt(9), 10);
      console.log("valueeeee-- bn",firstNineDigits)
      console.log("valueeeee-- bn",tenthDigit)
      const remainder = checkIdentificationNumberValidity(firstNineDigits);
      console.log("output bn",remainder)

        if (!value || value && value.length !== maxLength) {
          return this.createError({
            message: 'Invalid Identification Number',
            path: this.path,
          });
        }
      
        if (
          (remainder === 0 && tenthDigit === 1) ||
          (remainder === 1 && tenthDigit === 0) ||
          (remainder !== 0 && remainder !== 1 && 11 - remainder === tenthDigit)
        ) {
          return true;
        } else {
          return this.createError({
            message: 'Invalid Identification Number',
            path: this.path,
          });
        }
    }
  ).nullable(),
  filerIdentificationNumber: yup
    .string().nullable()
    .when('filer', {
      is: true,
      then: yup.string().test(
        'is-valid-or-not',
        'Invalid value',
        function (value:any) {
          let maxLength = 16;
          console.log("value456",value)
          const firstNineDigits: string = value && value.substring(0, 9);
          const tenthDigit = parseInt(value && value.charAt(9), 10);
          console.log("valueeeee-- bn",firstNineDigits)
          console.log("valueeeee-- bn",tenthDigit)
          const remainder = checkIdentificationNumberValidity(firstNineDigits && firstNineDigits);
          console.log("output bn",remainder)
    
            if (!value || value && value.length !== maxLength) {
              return this.createError({
                message: 'Invalid Identification Number',
                path: this.path,
              });
            }
          
            if (
              (remainder === 0 && tenthDigit === 1) ||
              (remainder === 1 && tenthDigit === 0) ||
              (remainder !== 0 && remainder !== 1 && 11 - remainder === tenthDigit)
            ) {
              return true;
            } else {
              return this.createError({
                message: 'Invalid Identification Number',
                path: this.path,
              });
            }
        }
      ).nullable().required('Filer Identification is required'),
      otherwise: yup.string().nullable()
    }),
  // filerIdentificationNumber:yup.string().test(
  //   'is-valid-or-not',
  //   'Invalid value',
  //   function (value:any) {
  //     // if(value && value.length > 0){
  //     // let maxLength = 16;
  //     // console.log("value456",value)
  //     // const firstNineDigits: string = value && value.substring(0, 9);
  //     // console.log("valueeeee-- bn",firstNineDigits)
  //     // const output = checkSINValidity(firstNineDigits);
  //     // console.log("output bn",output)
      
  //     //   if (value && value.length == maxLength && output % 10 === 0) {
  //     //     return true;
  //     //   } else {
  //     //     return this.createError({
  //     //       message: 'Invalid Filer Identification Number',
  //     //       path: this.path,
  //     //     });
  //     //   }
  //     // } else {
  //     //   return true
  //     // }
  //     let maxLength = 16;
  //     console.log("value456",value)
  //     const firstNineDigits: string = value && value.substring(0, 9);
  //     const tenthDigit = parseInt(value && value.charAt(9), 10);
  //     console.log("valueeeee-- bn",firstNineDigits)
  //     console.log("valueeeee-- bn",tenthDigit)
  //     const remainder = checkIdentificationNumberValidity(firstNineDigits && firstNineDigits);
  //     console.log("output bn",remainder)

  //       if (!value || value && value.length !== maxLength) {
  //         return this.createError({
  //           message: 'Invalid Identification Number',
  //           path: this.path,
  //         });
  //       }
      
  //       if (
  //         (remainder === 0 && tenthDigit === 1) ||
  //         (remainder === 1 && tenthDigit === 0) ||
  //         (remainder !== 0 && remainder !== 1 && 11 - remainder === tenthDigit)
  //       ) {
  //         return true;
  //       } else {
  //         return this.createError({
  //           message: 'Invalid Identification Number',
  //           path: this.path,
  //         });
  //       }
  //   }
  // ).nullable(),
  sequentialNumberStart: yup
    .string()
    .required("Start Number is required")
    .max(8, "Start Number has at most 8 Digits")
    .min(8, "Start Number cannot exceed 8 Digits"),
  // sequentialNumberEnd: yup.string().required("End Number is required").max(8, "End Number has at most 8 Digits").min(8, "End Number has at least 8 Digits"),
  sequentialNumberEnd: yup
    .string()
    .test(
      "greaterThanStartNumber",
      "End Number must be greater than Start Number",
      function (value) {
        const startValue = this.parent.sequentialNumberStart; // Access the value of fieldOne
        if (
          startValue &&
          value &&
          parseFloat(value) <= parseFloat(startValue)
        ) {
          return false; // Validation fails if end number is not greater than start number
        }
        return true; // Validation passes
      }
    )
    .test(
      "maxEndNumberLength",
      "Field Two must be between 8 and 8 characters",
      function (value) {
        if (value && (value.length < 8 || value.length > 8)) {
          return false;
        }
        return true;
      }
    )
    .required("End Number is required"),
});