import { ClientSetup } from "components/Client/ClientSetup";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const ClientSetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Client Setup"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Clients", navigateTo: "/clients/list" },
      { text: "Create Client", navigateTo: "/clients/setup" }
      ]}
      mainContents={<ClientSetup />}
    />
  );
};
