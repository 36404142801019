import { FC } from "react";

import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Toolbar } from "@mui/material";
import {
  DisabledButton,
  ITest,
  ResetButton,
  SubmitButton,
} from "@websential/cosmic";

export interface IAdjustmentOptionsSetupToolbar extends ITest {
  onResetClicked: () => void;
  isSubmitting: boolean;
  adjustmentOptionsData: any
}

export const AdjustmentOptionsSetupToolbar: FC<IAdjustmentOptionsSetupToolbar> = ({
  onResetClicked,
  isSubmitting = false,
  adjustmentOptionsData
}) => {
  const onchangeTooltip = () => {
    //it have to optional from cosmic then remove it 
  }
  return (
    <Toolbar >
      <>
        <div style={{display:"contents"}}>
          <div style={{margin:"1rem 0rem"}}>
          {
          adjustmentOptionsData 
          && adjustmentOptionsData.remittanceTransferred === true ? 
          (
            <div style={{cursor:"not-allowed"}}>
              <DisabledButton 
                tooltiptitle={"Remittance has been Transferred!"} 
                isDisabled={true}
                buttonLabel={"Submit"}
                icon={<PlaylistAddCheckOutlinedIcon />}
                testId='disableButton' 
                onClick={onchangeTooltip} />
            </div>
          ):(
            <SubmitButton
              label="Submit"
              testId="submit-button"
              loading={isSubmitting}
              icon={<PlaylistAddCheckOutlinedIcon />}
              
            />
            )
          } 
          </div>
          <ResetButton testId="reset-button" onClickHandler={onResetClicked} />
        </div>
      </>
    </Toolbar>
  );
};
