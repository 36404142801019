import T5SlipImportXmlLayout from "components/T5Slip/T5SlipImportXMLLayout/T5SlipImportXmlLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT5SlipImportXmlPage {}

export const T5SlipImportXmlPage: FC<IT5SlipImportXmlPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import XML T5 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t5/list" },
        { text: "T5", navigateTo: "/taxslip/t5/list" },
        { text: "Import XML T5 Slips", navigateTo: "/taxslip/t5/import-xml" },
      ]}
      mainContents={<T5SlipImportXmlLayout />}
    />
  );
};
