import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useState } from "react";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LookupFieldService } from "services";
import { IAppState } from "store/reducers";

import { Box, Grid, useTheme } from "@mui/material";
import {
  DeleteConfirmationDialog,
  EmptyList,
  EnhancedSnackbar,
  IResponse,
  IServerError,
  RouterLinkButton,
} from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import { INITIAL_PAGE_SIZE, INITIAL_SNACKBAR_DATA, LOOKUP_FIELDS_SCREEN_SLUG, ROWS_PER_PAGE_PER_OPTIONS, SEARCH_DEBOUNCE_DELAY_IN_SECONDS } from "common/constants";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface ILookupFieldList {}
const LOOKUP_FIELDS_LIST_QUERY_KEY = "lookup-field-list";

const useLookupFields = (params: IGetListRequest) =>
  useQuery(
    ["lookup-field-list", params],
    (data) => {
      return LookupFieldService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

export const LookupFieldList: React.FC<ILookupFieldList> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // for delete row Purpose
  const queryClient = useQueryClient();
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "name",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useLookupFields(queryParams); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const dataRolesList: any = data;
  console.log("@@@@@ => accountantList", dataRolesList);

  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);

  // for delete row
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [lookupFieldId, setLookupFieldId] = useState<number>(0);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.debouncedSearchQuery,
      order_by: "name",
      order_direction: "asc",
      page: requestParams.currentPage + 1,
      size: requestParams.pageSize,
    };
    if (requestParams.sort) {
      const [sortBy, sortDirection] = requestParams.sort.split(",");
      dispatchParams.order_by = toSnake(sortBy);
      dispatchParams.order_direction = sortDirection;
    }

    setQueryParams(dispatchParams);
  }, []);

  const onRowEditClicked = useCallback((params) => {
    let path = `/lookup-fields/setup/${params.row.id}`;
    navigate(path);
  }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const callAction = () => {
    if (apiAction === "delete") {
      alert(`Delete row with ID: ${selectedIds} has been clicked!`);
      // TODO api call for delete
    } else if (apiAction === "updateStatus") {
      alert(`action need to trigger : ${apiAction}`);
      // TODO api call for update status
    }
    setDialogOpen(false);
  };

  // For Delete Row
  const deleteLookupFieldField = () => {
    if (singleDelete) {
      setSingleDelete(false);
      deleteLookupFieldById(lookupFieldId);
      setDialogOpen(false);
    }
  };

  const onRowDeleteClicked = useCallback((params) => {
    setDialogOpen(true);
    setSingleDelete(true);
    setApiAction("delete");
    setLookupFieldId(params.row.id);
    console.log(
      "LookupFieldId in LookupField List OnDeleteRowClicked function",
      params.row.id
    );
    // alert(`Delete row with ID: ${params.row.id} has been clicked!`);
  }, []);

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const { isLoading: isDeleting, mutate: deleteLookupFieldById } = useMutation(
    LookupFieldService.delLookupField,
    {
      onSuccess: (res: string | IServerError | IResponse) => {
        if (typeof res === "string") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: "Record has been deleted successfully.",
            severity: "success",
          });

          return queryClient.invalidateQueries(LOOKUP_FIELDS_LIST_QUERY_KEY);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusValue(event.target.value as string);
    setApiAction("updateStatus");
    setDialogOpen(true);
  };

  const selectionToolbar = useCallback((selectedItems) => {
    setSelectedIds(selectedItems);
    if (selectedItems.length) {
      return (
        <Box sx={{ marginTop: theme.spacing(1) }}>
          {"Bulk Action Drop Down List"}
          {/* <EnhancedDDLBulkAction
            label="Status"
            value={statusValue}
            onChangeHandler={handleChange}
            options={[
              { name: "Active", value: "1" },
              { name: "Inactive", value: "2" },
            ]}
            testId="status-dd"
          /> */}
        </Box>
      );
    }
    return (
      <Box sx={{ marginTop: theme.spacing(1) }}>
        <RouterLinkButton to="/lookup-fields/setup" text="Add Lookup Field" />
      </Box>
    );
  }, []);

  //find the index of object from headerColumns to add link on given column
  // if (accountantList?.headerColumns) {
  //   const linkObjIndex = accountantList.headerColumns.findIndex(
  //     (obj: { field: any; }) => obj.field === accountantList.keyField.field
  //   );
  //   accountantList.headerColumns[linkObjIndex] = {
  //     field: accountantList.headerColumns[linkObjIndex].field,
  //     headerName: accountantList.headerColumns[linkObjIndex].headerName,
  //     flex: 1,
  //     renderCell: (params: any) => {
  //       var urlLink = accountantList.keyField.templateUrl;
  //       var newLink = urlLink.replace("{id}", params.row.id);

  //       return (
  //         <Link to={newLink} className={classes.textLink}>
  //           {params.row.accTitle}
  //         </Link>
  //       );
  //     },
  //   };
  //   const statusObjIndex = accountantList.headerColumns.findIndex(
  //     (obj: { field: string; }) => obj.field === "status"
  //   );
  //   accountantList.headerColumns[statusObjIndex] = {
  //     field: "status",
  //     headerName: "Status",
  //     flex: 1,
  //     width: 50,
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params: any) => (
  //       <Chip label={params.value} />
  //     ),
  //   };
  // }

  // if (isLoading) {
  //   return (
  //     <CircularProgress
  //       size={24}
  //       data-testid="circular-progress"
  //       className={classes.loader}
  //     />
  //   );
  // }

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: LOOKUP_FIELDS_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=delete",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  const image =
  "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";
  
  return dataRolesList && dataRolesList.items.length === 0 ? (
    <EmptyList 
    showImage={true}
    imagePath= {image}
    heading={`There's nothing! Add Lookup Field to proceed`} 
    content={`You can add lookup field by directly clicking on add lookup field button.`} 
    testId={"lookup-field-empty-list"} 
    showAddButton= {true}
    addButtonLabel={"Add Lookup Field"}
    navigateToAdd={"/lookup-fields/setup"}
    showImportButton={false}
    showImportXmlButton={false}
    />
  ) : (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
        {/* <EnhancedDialog
        dialogTitle="Please Confirm"
        key="dialog-box"
        testId="dialog"
        onCloseHandler={onCloseHandler}
        open={openDialog}
        dialogContent={
          <Typography variant="subtitle1">Are you sure?</Typography>
        }
        dialogAction={
          <Grid>
            <Button
              autoFocus
              variant="contained"
              onClick={onCloseHandler}
              color="default"
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={callAction}
              color="primary"
              className={classes.button}
            >
              ok
            </Button>
          </Grid>
        }
      /> */}
        <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={deleteLookupFieldField}
          cancelActionHandler={onCloseHandler}
          testId="lookup-field-tab-confirmation-dialog"
        />
        <EnhancedDataGrid
          columns={dataRolesList ? dataRolesList.headerColumns : []}
          rows={dataRolesList?.items}
          selectionEnabled={true}
          autoHeight={true}
          allowSearching={true}
          rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
          selectionToolbar={selectionToolbar}
          onRowEditClicked={onRowEditClicked}
          onRowDeleteClicked={onRowDeleteClicked}
          // initialPage={0}
          initialPageSize={INITIAL_PAGE_SIZE}
          disableColumnMenu={true}
          showRowActionsColumn={true}
          autoPageSize={false} //
          showIndexColumn={true}
          testId="lookup-field-list-grid"
          sortingMode="server"
          filterMode="server"
          paginationMode="server"
          onRequestParamsChange={onRequestParamsChange}
          totalRowsCount={dataRolesList?.total}
          isLoading={isLoading || isDeleting}
          rowIdGetter={(row) => row.id}
          searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
          shouldMask={false}
        />
      </Grid>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"lookup-field-tab-list-snackbar"}
        open={snackbarData.open}
      />
    </>
  );
  // switch (componentState) {
  //   case ComponentState.LOADING:
  //     return (
  //       <CircularProgress
  //         size={24}
  //         data-testid="circular-progress"
  //         className={classes.loader}
  //       />
  //     );
  //   case ComponentState.ERROR:
  //     return (
  //       <div>Error loading component data</div>
  //       // <ErrorState
  //       //   testId="error-container"
  //       //   message={message}
  //       //   onClick={() => {
  //       //     clearError();
  //       //     let dispatchParams = {
  //       //       search_query: "",
  //       //       order_by: "acc_title",
  //       //       order_direction: "asc",
  //       //       page: 1,
  //       //       size: 50,
  //       //     };
  //       //     dispatch(loadAccountantListRequest(dispatchParams));
  //       //   }}
  //       // />
  //     );
  //   case ComponentState.DATA:
  //     return emptyList ? (
  //       <EmptyAccountantList />
  //     ) : (
  //       <Grid
  //         item
  //         xs={12}
  //         sm={12}
  //         md={12}
  //         lg={12}
  //         xl={12}
  //         className={classes.list}
  //       >
  //         {/* <EnhancedDialog
  //           dialogTitle="Please Confirm"
  //           key="dialog-box"
  //           testId="dialog"
  //           onCloseHandler={onCloseHandler}
  //           open={openDialog}
  //           dialogContent={
  //             <Typography variant="subtitle1">Are you sure?</Typography>
  //           }
  //           dialogAction={
  //             <Grid>
  //               <Button
  //                 autoFocus
  //                 variant="contained"
  //                 onClick={onCloseHandler}
  //                 color="default"
  //                 className={classes.button}
  //               >
  //                 Cancel
  //               </Button>
  //               <Button
  //                 autoFocus
  //                 variant="contained"
  //                 onClick={callAction}
  //                 color="primary"
  //                 className={classes.button}
  //               >
  //                 ok
  //               </Button>
  //             </Grid>
  //           }
  //         /> */}
  //         <EnhancedDataGrid
  //           columns={accountantList ? accountantList.headerColumns : []}
  //           rows={accountantList?.items}
  //           selectionEnabled={true}
  //           autoHeight={true}
  //           allowSearching={true}
  //           rowsPerPageOptions={[10, 25, 50, 100]}
  //           selectionToolbar={selectionToolbar}
  //           onRowEditClicked={onRowEditClicked}
  //           onRowDeleteClicked={onRowDeleteClicked}
  //           initialPageSize={accountantList?.size}
  //           disableColumnMenu={true}
  //           showRowActionsColumn={true}
  //           autoPageSize={true}
  //           showIndexColumn={true}
  //           testId="accountant-list-grid"
  //           sortingMode="server"
  //           filterMode="server"
  //           paginationMode="server"
  //           onRequestParamsChange={onRequestParamsChange}
  //           totalRowsCount={accountantList?.total}
  //           isLoading={false}
  //           rowIdGetter={(row) => row.id}
  //           searchDebouncingDelay={2000}
  //           shouldMask={false}
  //         />
  //       </Grid>
  //     );
  //   default:
  //     return null;
  // }
};

// // export const AccountantListContent: React.FC<IAccountantList> = () => {
// //   return (<div>This Accountant List Content Page</div>);
// // }
