import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import "./inviteUsers.css"
import { BackdropCircularProgress, EnhancedSnackbar, IResponse, IServerError, LabelPosition, RHookFormTextFieldWithLabel, ResetButton, SubmitButton, SubmitButtonVariant } from '@websential/cosmic';
import { InviteUsersDataTable } from './InviteUsersDataTable';
import { INITIAL_SNACKBAR_DATA, INVITE_USER_SCREEN_SLUG } from 'common/constants';
import { Box, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { InviteUsersSchema } from './validator';
import { yupResolver } from '@hookform/resolvers/yup';
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { useMutation, useQuery } from 'react-query';
import { InviteUserService } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'store/reducers';
import { setSelectedActivityLogActions } from 'store/actions/activityLogActions';


export interface IInviteUsers {}

const useInviteUsers = (
  
) =>
  useQuery(
    ["invite-users"],
    (data) => {
      return InviteUserService.get();
    },
    { refetchOnWindowFocus: false }
  );
export const InviteUsers: React.FC<IInviteUsers> = () => {
  const dispatch = useDispatch();
  const clientName = useSelector((state : IAppState) => state.client.clientName);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [onDataSubmit, setOnDataSubmit] = useState<any>(undefined);

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const { isLoading, data } = useInviteUsers(); 
  const inviteUsersDataList: any = data;
  console.log("@@@@@ => inviteUsersDataList", inviteUsersDataList);

  const { isLoading: isSubmittingInviteUserData, mutate: submitInviteUserData } = useMutation(
    InviteUserService.add,
    {
      onSuccess: (res: any | IServerError | IResponse) => {
        console.log("responceeee ",res)
          setSnackbarData({
            open: true,
            message: res.messege ? res.messege : "Something went wrong.",
            severity: res.status === false ? "error" : "success",
          });
      },
      onError: (err:any) => {
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
      },
    },
  );

  let actualData = {
    "user_roles": [
      { value: "Admin", label: "Admin (Full Control)"}
    ],
    "user_roles_list": [
      {
        "id": 12,
        "role_name": "Admin",
        "user_type": 1,
        "owner_role": false,
        "application_partner": {
          "id": 13,
          "name": "Master Data",
          "url": "",
          "partner_secret": "c91526384994dfd3609b4fc06f388420519c7dbcc0836bac387a8933cf129a02",
          "partner_key": "bfc0f95db87d67b3135db339f29e4017d16cddba868fd25d7f4c0a2b460c973c",
          "application": 20,
          "auto_activate_partner_users": false
        }
      }
    ],
    "application_partner": {
      "13": "Master Data"
    },
    "time_profiles": [
      { value: "Admin", label: "Admin Profile 24/7"},
      { value: "Client", label: "Client Profile 24/7"},
    ],
    "variable_values": [
      {
        "client_id": 57,
        "client_name": "Haroon Test",
        "children": [
          {
            "client_id": 133,
            "client_name": "Adjustment Test 2",
          },
          {
            "client_id": 163,
            "client_name": "Company In 2019"
          },
          {
            "client_id": 62,
            "client_name": "Test & T4 Slips test"
          },
          {
            "client_id": 63,
            "client_name": "Test ABC"
          },
          {
            "client_id": 64,
            "client_name": "Test Adjustments"
          },
          {
            "client_id": 185,
            "client_name": "Test Confirmation #"
          },
          {
            "client_id": 191,
            "client_name": "Test Duplicate"
          },
          {
            "client_id": 186,
            "client_name": "Test exemption and merge"
          },
          {
            "client_id": 164,
            "client_name": "Test Loca Slips"
          },
          {
            "client_id": 165,
            "client_name": "Test Lock Slips"
          },
          {
            "client_id": 58,
            "client_name": "Test new Taxslips"
          },
          {
            "client_id": 127,
            "client_name": "Test TaxSlips For Verification",
            // "client_name": "Haroon Test"
          },
          {
            "client_id": 130,
            "client_name": "Test TaxSlips Scenarios",
            // "client_name": "Haroon Test"
          },
          {
            "client_id": 131,
            "client_name": "Test XML",
            // "client_name": "Haroon Test"
          },
          {
            "client_id": 140,
            "client_name": "Test222"
          }
        ]
      },
      {
        "client_id": 222,
        "client_name": "Accountant Load Test 9"
      },
      {
        "client_id": 222,
        "client_name": "fuuuuuuuuuuuuc",
        "children": [
          {
            "client_id": 133,
            "client_name": "Adjustment Test 2"
          },
          {
            "client_id": 163,
            "client_name": "Company In 2019",
            "children": []
          },
        ]
    }
    ],
    headerColumns: [
      {
          "field": "client_name",
          "headerName": "Client(s)",
          "description": "Client(s)",
          "flex": 1,
          "hide": false,
          "hideable": false,
          "sortable": true,
          "sortingOrder": "asc",
          "resizable": false,
          "type": "string",
          "align": "left",
          "headerAlign": "left",
          "hideSortIcons": false,
          "disableColumnMenu": true,
          "disableReorder": true,
          "disableExport": true,
          "maskable": false,
          "isFixed": true,
          "sequence": 0,
          "frozenColumn": false,
          "footer": null
      },
      {
          "field": "roles",
          "headerName": "Role(s)",
          "description": "Role(s)",
          "flex": 1,
          "hide": false,
          "hideable": false,
          "sortable": true,
          "sortingOrder": "asc",
          "resizable": false,
          "type": "string",
          "align": "left",
          "headerAlign": "left",
          "hideSortIcons": false,
          "disableColumnMenu": true,
          "disableReorder": true,
          "disableExport": true,
          "maskable": false,
          "isFixed": true,
          "sequence": 1,
          "frozenColumn": false,
          "footer": null
      },
      {
          "field": "timeProfiles",
          "headerName": "Time Profile(s)",
          "description": "Time Profile(s)",
          "flex": 1,
          "hide": false,
          "hideable": false,
          "sortable": true,
          "sortingOrder": "asc",
          "resizable": false,
          "type": "string",
          "align": "left",
          "headerAlign": "left",
          "hideSortIcons": false,
          "disableColumnMenu": true,
          "disableReorder": true,
          "disableExport": true,
          "maskable": false,
          "isFixed": true,
          "sequence": 2,
          "frozenColumn": false,
          "footer": null
      }
    ],
    clientHeaderColumns: [
      {
          "field": "client_name",
          "headerName": "Client(s) client_name",
          "description": "Client(s)",
          "flex": 1,
          "hide": false,
          "hideable": false,
          "sortable": true,
          "sortingOrder": "asc",
          "resizable": false,
          "type": "string",
          "align": "left",
          "headerAlign": "left",
          "hideSortIcons": false,
          "disableColumnMenu": true,
          "disableReorder": true,
          "disableExport": true,
          "maskable": false,
          "isFixed": true,
          "sequence": 0,
          "frozenColumn": false,
          "footer": null
      },
      {
          "field": "client_roles",
          "headerName": "Role(s) client_roles",
          "description": "Role(s)",
          "flex": 1,
          "hide": false,
          "hideable": false,
          "sortable": true,
          "sortingOrder": "asc",
          "resizable": false,
          "type": "string",
          "align": "left",
          "headerAlign": "left",
          "hideSortIcons": false,
          "disableColumnMenu": true,
          "disableReorder": true,
          "disableExport": true,
          "maskable": false,
          "isFixed": true,
          "sequence": 1,
          "frozenColumn": false,
          "footer": null
      },
      {
          "field": "client_time_profiles",
          "headerName": "Time Profile(s) client_time_profiles",
          "description": "Time Profile(s)",
          "flex": 1,
          "hide": false,
          "hideable": false,
          "sortable": true,
          "sortingOrder": "asc",
          "resizable": false,
          "type": "string",
          "align": "left",
          "headerAlign": "left",
          "hideSortIcons": false,
          "disableColumnMenu": true,
          "disableReorder": true,
          "disableExport": true,
          "maskable": false,
          "isFixed": true,
          "sequence": 2,
          "frozenColumn": false,
          "footer": null
      }
    ],
    "first_name": "haroon",
    "last_name": "hassan tech",
    "email": "haroon@technologyelement.com",
    "phone": "(231) 456-7988",
    "country_code": "+1",
    "status": true
  }

  // const formMethods: any = useForm<any>({
  //   resolver: yupResolver(InviteUsersSchema),
  //   mode: "onBlur",
  //   reValidateMode: "onChange",
  //   // defaultValues: DEFAULT_VALUES,
  // });

//   const onSubmit = (formData: any) => {
//     console.log("formData",formData)
//     // console.log("tableData",tableData)
//     // console.log("subtableData",subtableData)
//     // console.log("selectedItems",selectedItems)
//     // console.log("selectedSubItems",selectedSubItems)
//     let payload ={

//     }
//     onDataSubmit(payload)
// }
// const onResetClicked = () => {
//   formMethods.reset();
// };

let datatatat = {
  "userRoles": [
    {
      "label": "Admin",
      "value": "12"
    },
    {
      "label": "Test Role",
      "value": "14"
    }
  ],
  "timeProfiles": [
    {
      "label": "Admin Profile",
      "value": "12"
    },
    {
      "label": "test t",
      "value": "11"
    }
  ],
  "variableValues": [
    {
      "client_id": 57,
      "client_name": "Haroon Test",
      "children": [
        {
          "client_id": 133,
          "client_name": "Adjustment Test 2"
        },
        {
          "client_id": 163,
          "client_name": "Company In 2019"
        },
        {
          "client_id": 62,
          "client_name": "Test & T4 Slips test"
        },
        {
          "client_id": 63,
          "client_name": "Test ABC"
        },
        {
          "client_id": 64,
          "client_name": "Test Adjustments"
        },
        {
          "client_id": 185,
          "client_name": "Test Confirmation #"
        },
        {
          "client_id": 191,
          "client_name": "Test Duplicate"
        },
        {
          "client_id": 186,
          "client_name": "Test exemption and merge"
        },
        {
          "client_id": 164,
          "client_name": "Test Loca Slips"
        },
        {
          "client_id": 165,
          "client_name": "Test Lock Slips"
        },
        {
          "client_id": 58,
          "client_name": "Test new Taxslips"
        },
        {
          "client_id": 127,
          "client_name": "Test TaxSlips For Verification"
        },
        {
          "client_id": 130,
          "client_name": "Test TaxSlips Scenarios"
        },
        {
          "client_id": 131,
          "client_name": "Test XML"
        },
        {
          "client_id": 140,
          "client_name": "Test222"
        }
      ]
    },
    {
      "client_id": 115,
      "client_name": "Simplepay",
      "children": [
        {
          "client_id": 334,
          "client_name": "10Muhammad Qasim"
        },
        {
          "client_id": 335,
          "client_name": "11Muhammad Qasim"
        },
        {
          "client_id": 323,
          "client_name": "121111Muhammad Qasim"
        },
        {
          "client_id": 324,
          "client_name": "121Muhammad Qasim"
        },
        {
          "client_id": 336,
          "client_name": "12Muhammad Qasim"
        },
        {
          "client_id": 337,
          "client_name": "13Muhammad Qasim"
        },
        {
          "client_id": 338,
          "client_name": "14Muhammad Qasim"
        },
        {
          "client_id": 325,
          "client_name": "1Muhammad Qasim"
        },
        {
          "client_id": 326,
          "client_name": "2Muhammad Qasim"
        },
        {
          "client_id": 327,
          "client_name": "3Muhammad Qasim"
        },
        {
          "client_id": 328,
          "client_name": "4Muhammad Qasim"
        },
        {
          "client_id": 329,
          "client_name": "5Muhammad Qasim"
        },
        {
          "client_id": 330,
          "client_name": "6Muhammad Qasim"
        },
        {
          "client_id": 331,
          "client_name": "7Muhammad Qasim"
        },
        {
          "client_id": 332,
          "client_name": "8Muhammad Qasim"
        },
        {
          "client_id": 333,
          "client_name": "9Muhammad Qasim"
        },
        {
          "client_id": 189,
          "client_name": "Duplicate EMP"
        },
        {
          "client_id": 282,
          "client_name": "HeloMRs"
        },
        {
          "client_id": 151,
          "client_name": "New taxslips Export testing"
        },
        {
          "client_id": 292,
          "client_name": "poiujkl"
        },
        {
          "client_id": 270,
          "client_name": "psl_POahore"
        },
        {
          "client_id": 138,
          "client_name": "SimplePay Demo"
        },
        {
          "client_id": 204,
          "client_name": "Tauqeer SP Client for WS"
        },
        {
          "client_id": 149,
          "client_name": "Test & New TaxSlips"
        },
        {
          "client_id": 159,
          "client_name": "Test deductio D and 20"
        },
        {
          "client_id": 142,
          "client_name": "Test T4 For 2020"
        },
        {
          "client_id": 290,
          "client_name": "Update test_2"
        },
        {
          "client_id": 203,
          "client_name": "Zahid SP Client for WS"
        }
      ]
    },
    {
      "client_id": 205,
      "client_name": "Load Test Accountant",
      "children": [
        {
          "client_id": 206,
          "client_name": "Load test client 1"
        },
        {
          "client_id": 207,
          "client_name": "Load test client 2"
        },
        {
          "client_id": 208,
          "client_name": "Load test client 3"
        },
        {
          "client_id": 209,
          "client_name": "Load test client 4"
        },
        {
          "client_id": 210,
          "client_name": "Load test client 5"
        },
        {
          "client_id": 218,
          "client_name": "Load Test Client Test"
        },
        {
          "client_id": 224,
          "client_name": "Load Test Client Test 2"
        }
      ]
    },
    {
      "client_id": 222,
      "client_name": "Accountant Load Test 9"
    },
    {
      "client_id": 223,
      "client_name": "Accountant Load Test 10"
    },
    {
      "client_id": 243,
      "client_name": "Test Acc 10"
    },
    {
      "client_id": 244,
      "client_name": "Haroon HH6"
    },
    {
      "client_id": 271,
      "client_name": "Websential Accountant",
      "children": [
        {
          "client_id": 272,
          "client_name": "Haroon's client"
        },
        {
          "client_id": 279,
          "client_name": "Zahid Clients"
        }
      ]
    },
    {
      "client_id": 607,
      "client_name": "mudassar",
      "children": [
        {
          "client_id": 608,
          "client_name": "1123"
        },
        {
          "client_id": 612,
          "client_name": "adjustment report"
        },
        {
          "client_id": 645,
          "client_name": "Qasim 100"
        },
        {
          "client_id": 647,
          "client_name": "Qasim 1000"
        },
        {
          "client_id": 646,
          "client_name": "Qasim 500"
        }
      ]
    },
    {
      "client_id": 652,
      "client_name": "ACW",
      "children": [
        {
          "client_id": 691,
          "client_name": "123"
        },
        {
          "client_id": 690,
          "client_name": "22321"
        },
        {
          "client_id": 658,
          "client_name": "Client_test"
        },
        {
          "client_id": 671,
          "client_name": "test_invoice"
        },
        {
          "client_id": 657,
          "client_name": "test_OP"
        },
        {
          "client_id": 653,
          "client_name": "XML"
        }
      ]
    },
    {
      "client_id": 702,
      "client_name": "QQQ",
      "children": [
        {
          "client_id": 703,
          "client_name": "adjustment test"
        },
        {
          "client_id": 747,
          "client_name": "rl1 setting"
        },
        {
          "client_id": 745,
          "client_name": "setting test"
        }
      ]
    },
    {
      "client_id": 749,
      "client_name": "WWE",
      "children": [
        {
          "client_id": 829,
          "client_name": "12343"
        },
        {
          "client_id": 816,
          "client_name": "adjustment test"
        },
        {
          "client_id": 797,
          "client_name": "after live"
        },
        {
          "client_id": 837,
          "client_name": "import xml"
        },
        {
          "client_id": 822,
          "client_name": "invoice 23213"
        },
        {
          "client_id": 821,
          "client_name": "Qasim 100"
        },
        {
          "client_id": 817,
          "client_name": "rl1_adjustmet"
        },
        {
          "client_id": 833,
          "client_name": "server test"
        },
        {
          "client_id": 842,
          "client_name": "T222"
        },
        {
          "client_id": 818,
          "client_name": "Test New taxslips"
        },
        {
          "client_id": 819,
          "client_name": "Testt New taxslips"
        }
      ]
    },
    {
      "client_id": 167,
      "client_name": "Test Client Reduced EI"
    }
  ]
}

useEffect(()=>{
  // console.log("length of items",onDataSubmit.items.length)
  console.log("onDataSubmit",onDataSubmit)
  if(onDataSubmit){
      submitInviteUserData(onDataSubmit)
  }
},[onDataSubmit])

useEffect(()=>{
  dispatch(setSelectedActivityLogActions({
    entityId1:'',
    entityId2:'',
    entityId3:'',
    screenName:INVITE_USER_SCREEN_SLUG,
    companyName:clientName,
    activityType:"activity_type=assign_user",
    activityLogVisibility:true
  }));
},[])
  
    return (
      <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ width: "100%", overflow: "hidden" }}
    >
      <BackdropCircularProgress 
        open={
          isSubmittingInviteUserData
          } testId="Invite-users" />
          {/* <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Box sx={{width:"auto",marginBottom:"50px",border:"2px solid #dee2e6",
                  padding:"2rem 10rem 1rem 10rem",
                  justifyContent:"space-between"}}>

                <RHookFormTextFieldWithLabel
                  label="First Name"
                  id="name1"
                  testId={`first-name`}
                  labelPosition={LabelPosition.Left}
                  required
                  labelColumns={4}
                  fieldColumns={8}
                />
                <RHookFormTextFieldWithLabel
                  label="Last Name"
                  id="name2"
                  testId={`last-name`}
                  labelPosition={LabelPosition.Left}
                  required
                  labelColumns={4}
                  fieldColumns={8}
                />
                <RHookFormTextFieldWithLabel
                  label="Email"
                  id="email"
                  testId={`email`}
                  labelPosition={LabelPosition.Left}
                  required
                  labelColumns={4}
                  fieldColumns={8}
                />
                <div style={{ marginTop:"2rem 0" }}>
                  <SubmitButton
                    label="Invite User"
                    testId="submit-button"
                    loading={false}
                    icon={<PlaylistAddCheckOutlinedIcon />}
                    variant={SubmitButtonVariant.Primary}
                    />
                  <ResetButton testId="reset-button" onClickHandler={onResetClicked} />
                </div>
                </Box>
            </form>
            </FormProvider> */}
      <InviteUsersDataTable
        rows={inviteUsersDataList && inviteUsersDataList?.variableValues}
        columns={actualData ? actualData?.headerColumns : []}
        clientHeaderColumns={actualData ? actualData?.clientHeaderColumns : []}
        roles={inviteUsersDataList && inviteUsersDataList?.userRoles}
        timeProfiles={inviteUsersDataList && inviteUsersDataList?.timeProfiles}
        onDataSubmit={(value: any) => setOnDataSubmit(value)}
        isLoading={isLoading}
      />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`CSVInformationUI-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
    );
}