import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, useTheme } from "@mui/material";
import { BackdropCircularProgress, EnhancedDropDown, EnhancedDropDownRHFWithLabel, EnhancedSnackbar, IResponse, IServerError, LabelPosition, SingleColumnLayout, StandardPanel, TopRowTwoColumnLayout } from "@websential/cosmic";
import { CATEGORY_LIST_SCREEN_SLUG, INITIAL_SNACKBAR_DATA } from "common/constants";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TaxSlipYearService } from "services";
import { IGetListRequest } from "models";
import { PDFAxisToolbar } from "./PDFAxisToolbar";
import { PDFSettingsService } from "services/PDFSettingsService";
import T4SLipAxisPage1 from "./Pages/T4SlipAxisPages/T4SLipAxisPage1";
import T4SLipAxisPage2 from "./Pages/T4SlipAxisPages/T4SLipAxisPage2";
import T4ASLipAxisPage1 from "./Pages/T4ASlipAxisPages/T4ASLipAxisPage1";
import T4ASLipAxisPage2 from "./Pages/T4ASlipAxisPages/T4ASLipAxisPage2";
import RL1SLipAxisPage1 from "./Pages/RL1SlipAxisPages/RL1SLipAxisPage1";
import RL1SLipAxisPage2 from "./Pages/RL1SlipAxisPages/RL1SLipAxisPage2";
import RL1SummaryEnglishAxisPages1 from "./Pages/RL1SummaryEnglishAxisPages/RL1SummaryEnglishAxisPages1";
import RL1SummaryEnglishAxisPages2 from "./Pages/RL1SummaryEnglishAxisPages/RL1SummaryEnglishAxisPages2";
import RL1SummaryEnglishAxisPages3 from "./Pages/RL1SummaryEnglishAxisPages/RL1SummaryEnglishAxisPages3";
import RL1SummaryFrenchAxisPages1 from "./Pages/RL1SummaryFrenchAxisPages/RL1SummaryFrenchAxisPages1";
import RL1SummaryFrenchAxisPages3 from "./Pages/RL1SummaryFrenchAxisPages/RL1SummaryFrenchAxisPages3";
import RL1SummaryFrenchAxisPages2 from "./Pages/RL1SummaryFrenchAxisPages/RL1SummaryFrenchAxisPages2";
import InvoiceAxisPages1 from "./Pages/InvoiceAxisPages/InvoiceAxisPage1";
import T4SummaryAxisPages1 from "./Pages/T4SummaryAxisPages/T4SummaryAxisPage1";
import T4ASummaryAxisPages1 from "./Pages/T4ASummaryAxisPages/T4ASummaryAxisPage1";
import T5SLipAxisPage1 from "./Pages/T5SlipAxisPages/T5SLipAxisPage1";
import T5SLipAxisPage3 from "./Pages/T5SlipAxisPages/T5SLipAxisPage3";
import T5SLipAxisPage2 from "./Pages/T5SlipAxisPages/T5SLipAxisPage2";
import T5SummaryAxisPages1 from "./Pages/T5SummaryAxisPages/T5SummaryAxisPage1";
import RL3SLipAxisPage1 from "./Pages/RL3SlipAxisPages/RL3SLipAxisPage1";
import RL3SLipAxisPage2 from "./Pages/RL3SlipAxisPages/RL3SLipAxisPage2";
import { yupResolver } from "@hookform/resolvers/yup";
import { PDFAxisValiadations } from "./validator";
import T3SLipAxisPage1 from "./Pages/T3SlipAxisPages/T3SLipAxisPage1";
import RL16SLipAxisPage1 from "./Pages/RL16SlipAxisPages/RL16SLipAxisPage1";
import T3SLipAxisPage2 from "./Pages/T3SlipAxisPages/T3SLipAxisPage2";
import RL16SLipAxisPage2 from "./Pages/RL16SlipAxisPages/RL16SLipAxisPage2";

const useYears = (params: IGetListRequest) =>
    useQuery(
      ["years-list", params],
      (data) => {
        return TaxSlipYearService.getList(params);
      },
      { refetchOnWindowFocus: false }
    );

const useGetPDFAxisData = (
  slipName:string,
  taxYear: number
) =>
      useQuery(
        ['pdf-axis-data-record',slipName, taxYear],
        (data) => {
          return PDFSettingsService.getPDFAxisData(slipName,taxYear);
        },
        { refetchOnWindowFocus: false }
      );
    
export interface IPDFAxis {}

const tagList = [
    { label:"T4", value:"t4" },
    { label:"T4A", value:"t4a" },
    { label:"RL-1", value:"rl1" },
    { label:"T5", value:"t5" },
    { label:"RL-3", value:"rl3" },
    { label:"T3", value:"t3" },
    { label:"RL-16", value:"rl16" },
    { label:"T4 Summary", value:"t4Summary" },
    { label:"T4A Summary", value:"t4aSummary" },
    { label:"RL-1 Summary English", value:"rl1SummaryEnglish" },
    { label:"RL-1 Summary French", value:"rl1SummaryFrench" },
    { label:"T5 Summary", value:"t5Summary" },
    // { label:"RL-3 Summary", value:"rl3Summary" },
    { label:"Invoice", value:"invoice" },
]

export const PDFAxis: React.FC<IPDFAxis> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

    const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTag, setSelectedTag] = useState('t4');
    const [selectedYear, setSelectedYear] = useState<any>(undefined);

    const formMethods = useForm<any>({
      resolver: yupResolver(PDFAxisValiadations),
      mode: "onBlur",
      reValidateMode: "onChange",
      // defaultValues: DEFAULT_VALUES,
    });
    const { isLoading: isLoadingPDFAxis, data: PDFAxisDataRecord } =
    useGetPDFAxisData(selectedTag,selectedYear);
    const PDFAxisData: any = PDFAxisDataRecord;

    const onTagSelectionChangeHandler =(item:any) => {
      formMethods.reset();
      formMethods.setValue('tag',item.value)
      formMethods.setValue('year',selectedYear)
        setIsLoading(true)
        setTimeout(()=>{
            setIsLoading(false)
        },1000)
        setSelectedTag(item.value)
        queryClient.invalidateQueries(['pdf-axis-data-record', item.value,selectedYear]);
    }
  

  useEffect(()=>{
    if(PDFAxisData && PDFAxisData.message !== "Pdf Axis not found with this year"){
      Object.keys(PDFAxisData).forEach((fieldName:string)=>{
        const fieldValue = PDFAxisData[fieldName];
        formMethods.setValue(fieldName, fieldValue ?? undefined)
      })
    } else {
      console.log('Pdf Axis not found with this year')
    }
    console.log("PDFAxisData",PDFAxisData)
  },[PDFAxisData])

    const {
        isLoading: isLoadingYearsList,
        data: yearsDDLList,
        // refetch: refetchTabsDDL,
      } = useYears({
        search_query: "",
        order_by: "client_name",
        order_direction: "asc",
        page: 1,
        size: 25,
      });
    const yearsDDLItems = useMemo(() => {
        if (yearsDDLList && Array.isArray(yearsDDLList && yearsDDLList.items)) {
          return (
            yearsDDLList &&
            yearsDDLList.items.map((year: any) => ({
              id: year.id,
              label: year.year,
              value: year.year,
            }))
          );
        }
    
        // Return empty array in case of API data fetching error
        return [];
      }, [yearsDDLList]);

      useEffect(()=>{
        if(yearsDDLItems && yearsDDLItems[0]){
          setSelectedYear(yearsDDLItems[0].value)
        }
      },[yearsDDLItems])

const onSnackbarClosed = (
  event: React.SyntheticEvent | Event,
  reason?: string
) => {
  if (reason === "clickaway") {
    return;
  }
  setSnackbarData(INITIAL_SNACKBAR_DATA);
};


  const { isLoading: isSubmitting, mutate: submitFlexFields } = useMutation(
    PDFSettingsService.createPDFAxis,
    {
      onSuccess: (
        res:  IServerError | IResponse | any
      ) => {

          formMethods.setValue("id", res.id);
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
    },
    onError: (err: any) => {
      console.log("resonce for Delete row err", err);
      // TODO: customize the toast message based on the response
      setSnackbarData({
        open: true,
        // message: "Something went wrong, try again later!",
        message: err.message,
        severity: "error",
      });
    },
}
);

const onYearChangeHandler = (value:any) => {
  console.log("ssddfv",value)
  setSelectedYear(value.value)
}


  const onSubmit = (formData: any) => {
    console.log("formData--",formData)
    if(selectedTag === 't4'){
      let payload : any = {
        slipTypeP1X: formData.slipTypeP1X && formData.slipTypeP1X !== '' ? parseFloat(formData.slipTypeP1X) :  412,
        slipTypeP1Y: formData.slipTypeP1Y && formData.slipTypeP1Y !== '' ? parseFloat(formData.slipTypeP1Y) :  761,
        taxYearP1X: formData.taxYearP1X && formData.taxYearP1X !== '' ? parseFloat(formData.taxYearP1X) :  308,
        taxYearP1Y: formData.taxYearP1Y && formData.taxYearP1Y !== '' ? parseFloat(formData.taxYearP1Y) :  743,
        pipInsurableEarningsP1X: formData.pipInsurableEarningsP1X && formData.pipInsurableEarningsP1X !== '' ? parseFloat(formData.pipInsurableEarningsP1X) :  553.5,
        pipInsurableEarningsP1Y: formData.pipInsurableEarningsP1Y && formData.pipInsurableEarningsP1Y !== '' ? parseFloat(formData.pipInsurableEarningsP1Y) :  488,
        rppOrDpspRegistrationNumberP1X: formData.rppOrDpspRegistrationNumberP1X && formData.rppOrDpspRegistrationNumberP1X !== '' ? parseFloat(formData.rppOrDpspRegistrationNumberP1X) :  553.5,
        rppOrDpspRegistrationNumberP1Y: formData.rppOrDpspRegistrationNumberP1Y && formData.rppOrDpspRegistrationNumberP1Y !== '' ? parseFloat(formData.rppOrDpspRegistrationNumberP1Y) :  524,
        charitableDonationsP1X: formData.charitableDonationsP1X && formData.charitableDonationsP1X !== '' ? parseFloat(formData.charitableDonationsP1X) :  553.5,
        charitableDonationsP1Y: formData.charitableDonationsP1Y && formData.charitableDonationsP1Y !== '' ? parseFloat(formData.charitableDonationsP1Y) :  560,
        unionDuesP1X: formData.unionDuesP1X && formData.unionDuesP1X !== '' ? parseFloat(formData.unionDuesP1X) :  553.5,
        unionDuesP1Y: formData.unionDuesP1Y && formData.unionDuesP1Y !== '' ? parseFloat(formData.unionDuesP1Y) :  596,
        cppQppPensionableEarningsP1X: formData.cppQppPensionableEarningsP1X && formData.cppQppPensionableEarningsP1X !== '' ? parseFloat(formData.cppQppPensionableEarningsP1X) :  553.5,
        cppQppPensionableEarningsP1Y: formData.cppQppPensionableEarningsP1Y && formData.cppQppPensionableEarningsP1Y !== '' ? parseFloat(formData.cppQppPensionableEarningsP1Y) :  632,
        eiInsurableEarningsP1X: formData.eiInsurableEarningsP1X && formData.eiInsurableEarningsP1X !== '' ? parseFloat(formData.eiInsurableEarningsP1X) :  553.5,
        eiInsurableEarningsP1Y: formData.eiInsurableEarningsP1Y && formData.eiInsurableEarningsP1Y !== '' ? parseFloat(formData.eiInsurableEarningsP1Y) :  668,
        incomeTaxDeductedP1X: formData.incomeTaxDeductedP1X && formData.incomeTaxDeductedP1X !== '' ? parseFloat(formData.incomeTaxDeductedP1X) :  553.5,
        incomeTaxDeductedP1Y: formData.incomeTaxDeductedP1Y && formData.incomeTaxDeductedP1Y !== '' ? parseFloat(formData.incomeTaxDeductedP1Y) :  704,
        ppipPremiumsP1X: formData.ppipPremiumsP1X && formData.ppipPremiumsP1X !== '' ? parseFloat(formData.ppipPremiumsP1X) :  421,
        ppipPremiumsP1Y: formData.ppipPremiumsP1Y && formData.ppipPremiumsP1Y !== '' ? parseFloat(formData.ppipPremiumsP1Y) :  488,
        pensionAdjustmentFacteurP1X: formData.pensionAdjustmentFacteurP1X && formData.pensionAdjustmentFacteurP1X !== '' ? parseFloat(formData.pensionAdjustmentFacteurP1X) :  421,
        pensionAdjustmentFacteuP1Y: formData.pensionAdjustmentFacteuP1Y && formData.pensionAdjustmentFacteuP1Y !== '' ? parseFloat(formData.pensionAdjustmentFacteuP1Y) :  524,
        rppContributionsP1X: formData.rppContributionsP1X && formData.rppContributionsP1X !== '' ? parseFloat(formData.rppContributionsP1X) :  421,
        rppContributionsP1Y: formData.rppContributionsP1Y && formData.rppContributionsP1Y !== '' ? parseFloat(formData.rppContributionsP1Y) :  560,
        eiPremiumsP1X: formData.eiPremiumsP1X && formData.eiPremiumsP1X !== '' ? parseFloat(formData.eiPremiumsP1X) :  421,
        eiPremiumsP1Y: formData.eiPremiumsP1Y && formData.eiPremiumsP1Y !== '' ? parseFloat(formData.eiPremiumsP1Y) :  596,
        qppContributionsP1X: formData.qppContributionsP1X && formData.qppContributionsP1X !== '' ? parseFloat(formData.qppContributionsP1X) :  421,
        qppContributionsP1Y: formData.qppContributionsP1Y && formData.qppContributionsP1Y !== '' ? parseFloat(formData.qppContributionsP1Y) :  632,
        secondQppContributionsP1X: formData.secondQppContributionsP1X && formData.secondQppContributionsP1X !== '' ? parseFloat(formData.secondQppContributionsP1X) :  421,
        secondQppContributionsP1Y: formData.secondQppContributionsP1Y && formData.secondQppContributionsP1Y !== '' ? parseFloat(formData.secondQppContributionsP1Y) :  632,
        cppContributionsP1X: formData.cppContributionsP1X && formData.cppContributionsP1X !== '' ? parseFloat(formData.cppContributionsP1X) :  421,
        cppContributionsP1Y: formData.cppContributionsP1Y && formData.cppContributionsP1Y !== '' ? parseFloat(formData.cppContributionsP1Y) :  668,
        secondCppContributionsP1X: formData.secondCppContributionsP1X && formData.secondCppContributionsP1X !== '' ? parseFloat(formData.secondCppContributionsP1X) :  421,
        secondCppContributionsP1Y: formData.secondCppContributionsP1Y && formData.secondCppContributionsP1Y !== '' ? parseFloat(formData.secondCppContributionsP1Y) :  668,
        employmentIncomeP1X: formData.employmentIncomeP1X && formData.employmentIncomeP1X !== '' ? parseFloat(formData.employmentIncomeP1X) :  421,
        employmentIncomeP1Y: formData.employmentIncomeP1Y && formData.employmentIncomeP1Y !== '' ? parseFloat(formData.employmentIncomeP1Y) :  704,
        otherInfoAmount1P1X: formData.otherInfoAmount1P1X && formData.otherInfoAmount1P1X !== '' ? parseFloat(formData.otherInfoAmount1P1X) :  226.5,
        otherInfoCode1P1X: formData.otherInfoCode1P1X && formData.otherInfoCode1P1X !== '' ? parseFloat(formData.otherInfoCode1P1X) :  111,
        otherInfoAmount2P1X: formData.otherInfoAmount2P1X && formData.otherInfoAmount2P1X !== '' ? parseFloat(formData.otherInfoAmount2P1X) :  390,
        otherInfoCode2P1X: formData.otherInfoCode2P1X && formData.otherInfoCode2P1X !== '' ? parseFloat(formData.otherInfoCode2P1X) :  274.5,
        otherInfoAmount3P1X: formData.otherInfoAmount3P1X && formData.otherInfoAmount3P1X !== '' ? parseFloat(formData.otherInfoAmount3P1X) :  553.5,
        otherInfoCode3P1X: formData.otherInfoCode3P1X && formData.otherInfoCode3P1X !== '' ? parseFloat(formData.otherInfoCode3P1X) :  438,
        otherInfoAmount4P1X: formData.otherInfoAmount4P1X && formData.otherInfoAmount4P1X !== '' ? parseFloat(formData.otherInfoAmount4P1X) :  226.5,
        otherInfoCode4P1X: formData.otherInfoCode4P1X && formData.otherInfoCode4P1X !== '' ? parseFloat(formData.otherInfoCode4P1X) :  111,
        otherInfoAmount5P1X: formData.otherInfoAmount5P1X && formData.otherInfoAmount5P1X !== '' ? parseFloat(formData.otherInfoAmount5P1X) :  390,
        otherInfoCode5P1X: formData.otherInfoCode5P1X && formData.otherInfoCode5P1X !== '' ? parseFloat(formData.otherInfoCode5P1X) :  274.5,
        otherInfoAmount6P1X: formData.otherInfoAmount6P1X && formData.otherInfoAmount6P1X !== '' ? parseFloat(formData.otherInfoAmount6P1X) :  553.5,
        otherInfoCode6P1X: formData.otherInfoCode6P1X && formData.otherInfoCode6P1X !== '' ? parseFloat(formData.otherInfoCode6P1X) :  438,
        otherInfoAmount1P1Y: formData.otherInfoAmount1P1Y && formData.otherInfoAmount1P1Y !== '' ? parseFloat(formData.otherInfoAmount1P1Y) :  452,
        otherInfoCode1P1Y: formData.otherInfoCode1P1Y && formData.otherInfoCode1P1Y !== '' ? parseFloat(formData.otherInfoCode1P1Y) :  452,
        otherInfoAmount2P1Y: formData.otherInfoAmount2P1Y && formData.otherInfoAmount2P1Y !== '' ? parseFloat(formData.otherInfoAmount2P1Y) :  452,
        otherInfoCode2P1Y: formData.otherInfoCode2P1Y && formData.otherInfoCode2P1Y !== '' ? parseFloat(formData.otherInfoCode2P1Y) :  452,
        otherInfoAmount3P1Y: formData.otherInfoAmount3P1Y && formData.otherInfoAmount3P1Y !== '' ? parseFloat(formData.otherInfoAmount3P1Y) :  452,
        otherInfoCode3P1Y: formData.otherInfoCode3P1Y && formData.otherInfoCode3P1Y !== '' ? parseFloat(formData.otherInfoCode3P1Y) :  452,
        otherInfoAmount4P1Y: formData.otherInfoAmount4P1Y && formData.otherInfoAmount4P1Y !== '' ? parseFloat(formData.otherInfoAmount4P1Y) :  416,
        otherInfoCode4P1Y: formData.otherInfoCode4P1Y && formData.otherInfoCode4P1Y !== '' ? parseFloat(formData.otherInfoCode4P1Y) :  416,
        otherInfoAmount5P1Y: formData.otherInfoAmount5P1Y && formData.otherInfoAmount5P1Y !== '' ? parseFloat(formData.otherInfoAmount5P1Y) :  416,
        otherInfoCode5P1Y: formData.otherInfoCode5P1Y && formData.otherInfoCode5P1Y !== '' ? parseFloat(formData.otherInfoCode5P1Y) :  416,
        otherInfoAmount6P1Y: formData.otherInfoAmount6P1Y && formData.otherInfoAmount6P1Y !== '' ? parseFloat(formData.otherInfoAmount6P1Y) :  416,
        otherInfoCode6P1Y: formData.otherInfoCode6P1Y && formData.otherInfoCode6P1Y !== '' ? parseFloat(formData.otherInfoCode6P1Y) :  416,
        resourceCityAddressP1X: formData.resourceCityAddressP1X && formData.resourceCityAddressP1X !== '' ? parseFloat(formData.resourceCityAddressP1X) :  50,
        resourceCityAddressP1Y: formData.resourceCityAddressP1Y && formData.resourceCityAddressP1Y !== '' ? parseFloat(formData.resourceCityAddressP1Y) :  520,
        resourceAddressP1X: formData.resourceAddressP1X && formData.resourceAddressP1X !== '' ? parseFloat(formData.resourceAddressP1X) :  50,
        resourceAddressP1Y: formData.resourceAddressP1Y && formData.resourceAddressP1Y !== '' ? parseFloat(formData.resourceAddressP1Y) :  540,
        employersCityAddressP1X: formData.employersCityAddressP1X && formData.employersCityAddressP1X !== '' ? parseFloat(formData.employersCityAddressP1X) :  50,
        employersCityAddressP1Y: formData.employersCityAddressP1Y && formData.employersCityAddressP1Y !== '' ? parseFloat(formData.employersCityAddressP1Y) :  720,
        employersAddressP1X: formData.employersAddressP1X && formData.employersAddressP1X !== '' ? parseFloat(formData.employersAddressP1X) :  50,
        employersAddressP1Y: formData.employersAddressP1Y && formData.employersAddressP1Y !== '' ? parseFloat(formData.employersAddressP1Y) :  735,
        employersNameP1X: formData.employersNameP1X && formData.employersNameP1X !== '' ? parseFloat(formData.employersNameP1X) :  50,
        employersNameP1Y: formData.employersNameP1Y && formData.employersNameP1Y !== '' ? parseFloat(formData.employersNameP1Y) :  750,
        initialsP1X: formData.initialsP1X && formData.initialsP1X !== '' ? parseFloat(formData.initialsP1X) :  300,
        initialsP1Y: formData.initialsP1Y && formData.initialsP1Y !== '' ? parseFloat(formData.initialsP1Y) :  581,
        resourceLastNameP1X: formData.resourceLastNameP1X && formData.resourceLastNameP1X !== '' ? parseFloat(formData.resourceLastNameP1X) :  200,
        resourceLastNameP1Y: formData.resourceLastNameP1Y && formData.resourceLastNameP1Y !== '' ? parseFloat(formData.resourceLastNameP1Y) :  581,
        resourceFirstNameP1X: formData.resourceFirstNameP1X && formData.resourceFirstNameP1X !== '' ? parseFloat(formData.resourceFirstNameP1X) :  72,
        resourceFirstNameP1Y: formData.resourceFirstNameP1Y && formData.resourceFirstNameP1Y !== '' ? parseFloat(formData.resourceFirstNameP1Y) :  581,
        employeeAccountNoP1X: formData.employeeAccountNoP1X && formData.employeeAccountNoP1X !== '' ? parseFloat(formData.employeeAccountNoP1X) :  90,
        employeeAccountNoP1Y: formData.employeeAccountNoP1Y && formData.employeeAccountNoP1Y !== '' ? parseFloat(formData.employeeAccountNoP1Y) :  678,
        socialInsuranceNumberP1X: formData.socialInsuranceNumberP1X && formData.socialInsuranceNumberP1X !== '' ? parseFloat(formData.socialInsuranceNumberP1X) :  65,
        socialInsuranceNumberP1Y: formData.socialInsuranceNumberP1Y && formData.socialInsuranceNumberP1Y !== '' ? parseFloat(formData.socialInsuranceNumberP1Y) :  640,
        cppExemptP1X: formData.cppExemptP1X && formData.cppExemptP1X !== '' ? parseFloat(formData.cppExemptP1X) :  255,
        cppExemptP1Y: formData.cppExemptP1Y && formData.cppExemptP1Y !== '' ? parseFloat(formData.cppExemptP1Y) :  640,
        eiExemptP1X: formData.eiExemptP1X && formData.eiExemptP1X !== '' ? parseFloat(formData.eiExemptP1X) :  233,
        eiExemptP1Y: formData.eiExemptP1Y && formData.eiExemptP1Y !== '' ? parseFloat(formData.eiExemptP1Y) :  640,
        ppipExemptP1X: formData.ppipExemptP1X && formData.ppipExemptP1X !== '' ? parseFloat(formData.ppipExemptP1X) :  211,
        ppipExemptP1Y: formData.ppipExemptP1Y && formData.ppipExemptP1Y !== '' ? parseFloat(formData.ppipExemptP1Y) :  640,
        provinceOfEmployementShortCodeP1X: formData.provinceOfEmployementShortCodeP1X && formData.provinceOfEmployementShortCodeP1X !== '' ? parseFloat(formData.provinceOfEmployementShortCodeP1X) :  295,
        provinceOfEmployementShortCodeP1Y: formData.provinceOfEmployementShortCodeP1Y && formData.provinceOfEmployementShortCodeP1Y !== '' ? parseFloat(formData.provinceOfEmployementShortCodeP1Y) :  668,
        employerOfferedDentalBenefitsP1X: formData.employerOfferedDentalBenefitsP1X && formData.employerOfferedDentalBenefitsP1X !== '' ? parseFloat(formData.employerOfferedDentalBenefitsP1X) :  295,
        employerOfferedDentalBenefitsP1Y: formData.employerOfferedDentalBenefitsP1Y && formData.employerOfferedDentalBenefitsP1Y !== '' ? parseFloat(formData.employerOfferedDentalBenefitsP1Y) :  693,
        employmentCodeP1X: formData.employmentCodeP1X && formData.employmentCodeP1X !== '' ? parseFloat(formData.employmentCodeP1X) :  296,
        employmentCodeP1Y: formData.employmentCodeP1Y && formData.employmentCodeP1Y !== '' ? parseFloat(formData.employmentCodeP1Y) :  632,
        slipTypeP2X: formData.slipTypeP2X && formData.slipTypeP2X !== '' ? parseFloat(formData.slipTypeP2X) :  412,
        slipTypeP2Y: formData.slipTypeP2Y && formData.slipTypeP2Y !== '' ? parseFloat(formData.slipTypeP2Y) :  375,
        taxYearP2X: formData.taxYearP2X && formData.taxYearP2X !== '' ? parseFloat(formData.taxYearP2X) :  308,
        taxYearP2Y: formData.taxYearP2Y && formData.taxYearP2Y !== '' ? parseFloat(formData.taxYearP2Y) :  355,
        pipInsurableEarningsP2X: formData.pipInsurableEarningsP2X && formData.pipInsurableEarningsP2X !== '' ? parseFloat(formData.pipInsurableEarningsP2X) :  553.5,
        pipInsurableEarningsP2Y: formData.pipInsurableEarningsP2Y && formData.pipInsurableEarningsP2Y !== '' ? parseFloat(formData.pipInsurableEarningsP2Y) :  101,
        rppOrDpspRegistrationNumberP2X: formData.rppOrDpspRegistrationNumberP2X && formData.rppOrDpspRegistrationNumberP2X !== '' ? parseFloat(formData.rppOrDpspRegistrationNumberP2X) :  553.5,
        rppOrDpspRegistrationNumberP2Y: formData.rppOrDpspRegistrationNumberP2Y && formData.rppOrDpspRegistrationNumberP2Y !== '' ? parseFloat(formData.rppOrDpspRegistrationNumberP2Y) :  137,
        charitableDonationsP2X: formData.charitableDonationsP2X && formData.charitableDonationsP2X !== '' ? parseFloat(formData.charitableDonationsP2X) :  553.5,
        charitableDonationsP2Y: formData.charitableDonationsP2Y && formData.charitableDonationsP2Y !== '' ? parseFloat(formData.charitableDonationsP2Y) :  173,
        unionDuesP2X: formData.unionDuesP2X && formData.unionDuesP2X !== '' ? parseFloat(formData.unionDuesP2X) :  553.5,
        unionDuesP2Y: formData.unionDuesP2Y && formData.unionDuesP2Y !== '' ? parseFloat(formData.unionDuesP2Y) :  209,
        cppQppPensionableEarningsP2X: formData.cppQppPensionableEarningsP2X && formData.cppQppPensionableEarningsP2X !== '' ? parseFloat(formData.cppQppPensionableEarningsP2X) :  553.5,
        cppQppPensionableEarningsP2Y: formData.cppQppPensionableEarningsP2Y && formData.cppQppPensionableEarningsP2Y !== '' ? parseFloat(formData.cppQppPensionableEarningsP2Y) :  245,
        eiInsurableEarningsP2X: formData.eiInsurableEarningsP2X && formData.eiInsurableEarningsP2X !== '' ? parseFloat(formData.eiInsurableEarningsP2X) :  553.5,
        eiInsurableEarningsP2Y: formData.eiInsurableEarningsP2Y && formData.eiInsurableEarningsP2Y !== '' ? parseFloat(formData.eiInsurableEarningsP2Y) :  281,
        incomeTaxDeductedP2X: formData.incomeTaxDeductedP2X && formData.incomeTaxDeductedP2X !== '' ? parseFloat(formData.incomeTaxDeductedP2X) :  553.5,
        incomeTaxDeductedP2Y: formData.incomeTaxDeductedP2Y && formData.incomeTaxDeductedP2Y !== '' ? parseFloat(formData.incomeTaxDeductedP2Y) :  317,
        ppipPremiumsP2X: formData.ppipPremiumsP2X && formData.ppipPremiumsP2X !== '' ? parseFloat(formData.ppipPremiumsP2X) :  421,
        ppipPremiumsP2Y: formData.ppipPremiumsP2Y && formData.ppipPremiumsP2Y !== '' ? parseFloat(formData.ppipPremiumsP2Y) :  101,
        pensionAdjustmentFacteuP2X: formData.pensionAdjustmentFacteuP2X && formData.pensionAdjustmentFacteuP2X !== '' ? parseFloat(formData.pensionAdjustmentFacteuP2X) :  421,
        pensionAdjustmentFacteuP2Y: formData.pensionAdjustmentFacteuP2Y && formData.pensionAdjustmentFacteuP2Y !== '' ? parseFloat(formData.pensionAdjustmentFacteuP2Y) :  137,
        rppContributionsP2X: formData.rppContributionsP2X && formData.rppContributionsP2X !== '' ? parseFloat(formData.rppContributionsP2X) :  421,
        rppContributionsP2Y: formData.rppContributionsP2Y && formData.rppContributionsP2Y !== '' ? parseFloat(formData.rppContributionsP2Y) :  173,
        eiPremiumsP2X: formData.eiPremiumsP2X && formData.eiPremiumsP2X !== '' ? parseFloat(formData.eiPremiumsP2X) :  421,
        eiPremiumsP2Y: formData.eiPremiumsP2Y && formData.eiPremiumsP2Y !== '' ? parseFloat(formData.eiPremiumsP2Y) :  209,
        qppContributionsP2X: formData.qppContributionsP2X && formData.qppContributionsP2X !== '' ? parseFloat(formData.qppContributionsP2X) :  421,
        qppContributionsP2Y: formData.qppContributionsP2Y && formData.qppContributionsP2Y !== '' ? parseFloat(formData.qppContributionsP2Y) :  245,
        secondQppContributionsP2X: formData.secondQppContributionsP2X && formData.secondQppContributionsP2X !== '' ? parseFloat(formData.secondQppContributionsP2X) :  421,
        secondQppContributionsP2Y: formData.secondQppContributionsP2Y && formData.secondQppContributionsP2Y !== '' ? parseFloat(formData.secondQppContributionsP2Y) :  245,
        cppContributionsP2X: formData.cppContributionsP2X && formData.cppContributionsP2X !== '' ? parseFloat(formData.cppContributionsP2X) :  421,
        cppContributionsP2Y: formData.cppContributionsP2Y && formData.cppContributionsP2Y !== '' ? parseFloat(formData.cppContributionsP2Y) :  281,
        secondCppContributionsP2X: formData.secondCppContributionsP2X && formData.secondCppContributionsP2X !== '' ? parseFloat(formData.secondCppContributionsP2X) :  421,
        secondCppContributionsP2Y: formData.secondCppContributionsP2Y && formData.secondCppContributionsP2Y !== '' ? parseFloat(formData.secondCppContributionsP2Y) :  281,
        employmentIncomeP2X: formData.employmentIncomeP2X && formData.employmentIncomeP2X !== '' ? parseFloat(formData.employmentIncomeP2X) :  421,
        employmentIncomeP2Y: formData.employmentIncomeP2Y && formData.employmentIncomeP2Y !== '' ? parseFloat(formData.employmentIncomeP2Y) :  317,
        otherInfoAmount1P2X: formData.otherInfoAmount1P2X && formData.otherInfoAmount1P2X !== '' ? parseFloat(formData.otherInfoAmount1P2X) :  226.5,
        otherInfoCode1P2X: formData.otherInfoCode1P2X && formData.otherInfoCode1P2X !== '' ? parseFloat(formData.otherInfoCode1P2X) :  111,
        otherInfoAmount2P2X: formData.otherInfoAmount2P2X && formData.otherInfoAmount2P2X !== '' ? parseFloat(formData.otherInfoAmount2P2X) :  390,
        otherInfoCode2P2X: formData.otherInfoCode2P2X && formData.otherInfoCode2P2X !== '' ? parseFloat(formData.otherInfoCode2P2X) :  274.5,
        otherInfoAmount3P2X: formData.otherInfoAmount3P2X && formData.otherInfoAmount3P2X !== '' ? parseFloat(formData.otherInfoAmount3P2X) :  553.5,
        otherInfoCode3P2X: formData.otherInfoCode3P2X && formData.otherInfoCode3P2X !== '' ? parseFloat(formData.otherInfoCode3P2X) :  438,
        otherInfoAmount4P2X: formData.otherInfoAmount4P2X && formData.otherInfoAmount4P2X !== '' ? parseFloat(formData.otherInfoAmount4P2X) :  226.5,
        otherInfoCode4P2X: formData.otherInfoCode4P2X && formData.otherInfoCode4P2X !== '' ? parseFloat(formData.otherInfoCode4P2X) :  111,
        otherInfoAmount5P2X: formData.otherInfoAmount5P2X && formData.otherInfoAmount5P2X !== '' ? parseFloat(formData.otherInfoAmount5P2X) :  390,
        otherInfoCode5P2X: formData.otherInfoCode5P2X && formData.otherInfoCode5P2X !== '' ? parseFloat(formData.otherInfoCode5P2X) :  274.5,
        otherInfoAmount6P2X: formData.otherInfoAmount6P2X && formData.otherInfoAmount6P2X !== '' ? parseFloat(formData.otherInfoAmount6P2X) :  553.5,
        otherInfoCode6P2X: formData.otherInfoCode6P2X && formData.otherInfoCode6P2X !== '' ? parseFloat(formData.otherInfoCode6P2X) :  438,
        otherInfoAmount1P2Y: formData.otherInfoAmount1P2Y && formData.otherInfoAmount1P2Y !== '' ? parseFloat(formData.otherInfoAmount1P2Y) :  65,
        otherInfoCode1P2Y: formData.otherInfoCode1P2Y && formData.otherInfoCode1P2Y !== '' ? parseFloat(formData.otherInfoCode1P2Y) :  65,
        otherInfoAmount2P2Y: formData.otherInfoAmount2P2Y && formData.otherInfoAmount2P2Y !== '' ? parseFloat(formData.otherInfoAmount2P2Y) :  65,
        otherInfoCode2P2Y: formData.otherInfoCode2P2Y && formData.otherInfoCode2P2Y !== '' ? parseFloat(formData.otherInfoCode2P2Y) :  65,
        otherInfoAmount3P2Y: formData.otherInfoAmount3P2Y && formData.otherInfoAmount3P2Y !== '' ? parseFloat(formData.otherInfoAmount3P2Y) :  65,
        otherInfoCode3P2Y: formData.otherInfoCode3P2Y && formData.otherInfoCode3P2Y !== '' ? parseFloat(formData.otherInfoCode3P2Y) :  65,
        otherInfoAmount4P2Y: formData.otherInfoAmount4P2Y && formData.otherInfoAmount4P2Y !== '' ? parseFloat(formData.otherInfoAmount4P2Y) :  29,
        otherInfoCode4P2Y: formData.otherInfoCode4P2Y && formData.otherInfoCode4P2Y !== '' ? parseFloat(formData.otherInfoCode4P2Y) :  29,
        otherInfoAmount5P2Y: formData.otherInfoAmount5P2Y && formData.otherInfoAmount5P2Y !== '' ? parseFloat(formData.otherInfoAmount5P2Y) :  29,
        otherInfoCode5P2Y: formData.otherInfoCode5P2Y && formData.otherInfoCode5P2Y !== '' ? parseFloat(formData.otherInfoCode5P2Y) :  29,
        otherInfoAmount6P2Y: formData.otherInfoAmount6P2Y && formData.otherInfoAmount6P2Y !== '' ? parseFloat(formData.otherInfoAmount6P2Y) :  29,
        otherInfoCode6P2Y: formData.otherInfoCode6P2Y && formData.otherInfoCode6P2Y !== '' ? parseFloat(formData.otherInfoCode6P2Y) :  29,
        resourceCityAddressP2X: formData.resourceCityAddressP2X && formData.resourceCityAddressP2X !== '' ? parseFloat(formData.resourceCityAddressP2X) :  50,
        resourceCityAddressP2Y: formData.resourceCityAddressP2Y && formData.resourceCityAddressP2Y !== '' ? parseFloat(formData.resourceCityAddressP2Y) :  133,
        resourceAddressP2X: formData.resourceAddressP2X && formData.resourceAddressP2X !== '' ? parseFloat(formData.resourceAddressP2X) :  50,
        resourceAddressP2Y: formData.resourceAddressP2Y && formData.resourceAddressP2Y !== '' ? parseFloat(formData.resourceAddressP2Y) :  153,
        employersCityAddressP2X: formData.employersCityAddressP2X && formData.employersCityAddressP2X !== '' ? parseFloat(formData.employersCityAddressP2X) :  50,
        employersCityAddressP2Y: formData.employersCityAddressP2Y && formData.employersCityAddressP2Y !== '' ? parseFloat(formData.employersCityAddressP2Y) :  333,
        employersAddressP2X: formData.employersAddressP2X && formData.employersAddressP2X !== '' ? parseFloat(formData.employersAddressP2X) :  50,
        employersAddressP2Y: formData.employersAddressP2Y && formData.employersAddressP2Y !== '' ? parseFloat(formData.employersAddressP2Y) :  348,
        employersNameP2X: formData.employersNameP2X && formData.employersNameP2X !== '' ? parseFloat(formData.employersNameP2X) :  50,
        employersNameP2Y: formData.employersNameP2Y && formData.employersNameP2Y !== '' ? parseFloat(formData.employersNameP2Y) :  363,
        initialsP2X: formData.initialsP2X && formData.initialsP2X !== '' ? parseFloat(formData.initialsP2X) :  300,
        initialsP2Y: formData.initialsP2Y && formData.initialsP2Y !== '' ? parseFloat(formData.initialsP2Y) :  194,
        resourceLastNameP2X: formData.resourceLastNameP2X && formData.resourceLastNameP2X !== '' ? parseFloat(formData.resourceLastNameP2X) :  200,
        resourceLastNameP2Y: formData.resourceLastNameP2Y && formData.resourceLastNameP2Y !== '' ? parseFloat(formData.resourceLastNameP2Y) :  194,
        resourceFirstNameP2X: formData.resourceFirstNameP2X && formData.resourceFirstNameP2X !== '' ? parseFloat(formData.resourceFirstNameP2X) :  72,
        resourceFirstNameP2Y: formData.resourceFirstNameP2Y && formData.resourceFirstNameP2Y !== '' ? parseFloat(formData.resourceFirstNameP2Y) :  194,
        employeeAccountNoP2X: formData.employeeAccountNoP2X && formData.employeeAccountNoP2X !== '' ? parseFloat(formData.employeeAccountNoP2X) :  90,
        employeeAccountNoP2Y: formData.employeeAccountNoP2Y && formData.employeeAccountNoP2Y !== '' ? parseFloat(formData.employeeAccountNoP2Y) :  291,
        socialInsuranceNumberP2X: formData.socialInsuranceNumberP2X && formData.socialInsuranceNumberP2X !== '' ? parseFloat(formData.socialInsuranceNumberP2X) :  65,
        socialInsuranceNumberP2Y: formData.socialInsuranceNumberP2Y && formData.socialInsuranceNumberP2Y !== '' ? parseFloat(formData.socialInsuranceNumberP2Y) :  253,
        cppExemptP2X: formData.cppExemptP2X && formData.cppExemptP2X !== '' ? parseFloat(formData.cppExemptP2X) :  255,
        cppExemptP2Y: formData.cppExemptP2Y && formData.cppExemptP2Y !== '' ? parseFloat(formData.cppExemptP2Y) :  253,
        eiExemptP2X: formData.eiExemptP2X && formData.eiExemptP2X !== '' ? parseFloat(formData.eiExemptP2X) :  233,
        eiExemptP2Y: formData.eiExemptP2Y && formData.eiExemptP2Y !== '' ? parseFloat(formData.eiExemptP2Y) :  253,
        ppipExemptP2X: formData.ppipExemptP2X && formData.ppipExemptP2X !== '' ? parseFloat(formData.ppipExemptP2X) :  211,
        ppipExemptP2Y: formData.ppipExemptP2Y && formData.ppipExemptP2Y !== '' ? parseFloat(formData.ppipExemptP2Y) :  253,
        provinceOfEmployementShortCodeP2X: formData.provinceOfEmployementShortCodeP2X && formData.provinceOfEmployementShortCodeP2X !== '' ? parseFloat(formData.provinceOfEmployementShortCodeP2X) :  295,
        provinceOfEmployementShortCodeP2Y: formData.provinceOfEmployementShortCodeP2Y && formData.provinceOfEmployementShortCodeP2Y !== '' ? parseFloat(formData.provinceOfEmployementShortCodeP2Y) :  281,
        employerOfferedDentalBenefitsP2X: formData.employerOfferedDentalBenefitsP2X && formData.employerOfferedDentalBenefitsP2X !== '' ? parseFloat(formData.employerOfferedDentalBenefitsP2X) :  295,
        employerOfferedDentalBenefitsP2Y: formData.employerOfferedDentalBenefitsP2Y && formData.employerOfferedDentalBenefitsP2Y !== '' ? parseFloat(formData.employerOfferedDentalBenefitsP2Y) :  306,
        employmentCodeP2X: formData.employmentCodeP2X && formData.employmentCodeP2X !== '' ? parseFloat(formData.employmentCodeP2X) :  296,
        employmentCodeP2Y: formData.employmentCodeP2Y && formData.employmentCodeP2Y !== '' ? parseFloat(formData.employmentCodeP2Y) :  245
      } 
      let finalPayloadData = {
        payload:payload,
        taxYear:selectedYear,
        tag:selectedTag
      }
      submitFlexFields(finalPayloadData)
    } else if(selectedTag === 't4a'){
        let payload:any = {
          slipTypeP1X: formData.slipTypeP1X && formData.slipTypeP1X !== '' ? parseFloat(formData.slipTypeP1X) : 420,
          slipTypeP1Y: formData.slipTypeP1Y && formData.slipTypeP1Y !== '' ? parseFloat(formData.slipTypeP1Y) : 971,
          taxYearP1X: formData.taxYearP1X && formData.taxYearP1X !== '' ? parseFloat(formData.taxYearP1X) : 308,
          taxYearP1Y: formData.taxYearP1Y && formData.taxYearP1Y !== '' ? parseFloat(formData.taxYearP1Y) : 954,
          incomeTaxDeductedP1X: formData.incomeTaxDeductedP1X && formData.incomeTaxDeductedP1X !== '' ? parseFloat(formData.incomeTaxDeductedP1X) : 545.5,
          incomeTaxDeductedP1Y: formData.incomeTaxDeductedP1Y && formData.incomeTaxDeductedP1Y !== '' ? parseFloat(formData.incomeTaxDeductedP1Y) : 887,
          selfEmployedCommissionsP1X: formData.selfEmployedCommissionsP1X && formData.selfEmployedCommissionsP1X !== '' ? parseFloat(formData.selfEmployedCommissionsP1X) : 546.5,
          selfEmployedCommissionsP1Y: formData.selfEmployedCommissionsP1Y && formData.selfEmployedCommissionsP1Y !== '' ? parseFloat(formData.selfEmployedCommissionsP1Y) : 846,
          feesP1X: formData.feesP1X && formData.feesP1X !== '' ? parseFloat(formData.feesP1X) : 546.5,
          feesP1Y: formData.feesP1Y && formData.feesP1Y !== '' ? parseFloat(formData.feesP1Y) : 798,
          pensionOrSuperannuationP1X: formData.pensionOrSuperannuationP1X && formData.pensionOrSuperannuationP1X !== '' ? parseFloat(formData.pensionOrSuperannuationP1X) : 423,
          pensionOrSuperannuationP1Y: formData.pensionOrSuperannuationP1Y && formData.pensionOrSuperannuationP1Y !== '' ? parseFloat(formData.pensionOrSuperannuationP1Y) : 887,
          lumpSumPaymentsP1X: formData.lumpSumPaymentsP1X && formData.lumpSumPaymentsP1X !== '' ? parseFloat(formData.lumpSumPaymentsP1X) : 423,
          lumpSumPaymentsP1Y: formData.lumpSumPaymentsP1Y && formData.lumpSumPaymentsP1Y !== '' ? parseFloat(formData.lumpSumPaymentsP1Y) : 846,
          annuitiesP1X: formData.annuitiesP1X && formData.annuitiesP1X !== '' ? parseFloat(formData.annuitiesP1X) : 423,
          annuitiesP1Y: formData.annuitiesP1Y && formData.annuitiesP1Y !== '' ? parseFloat(formData.annuitiesP1Y) : 798,
          otherInfoAmount1P1X: formData.otherInfoAmount1P1X && formData.otherInfoAmount1P1X !== '' ? parseFloat(formData.otherInfoAmount1P1X) : 149,
          otherInfoCode1P1X: formData.otherInfoCode1P1X && formData.otherInfoCode1P1X !== '' ? parseFloat(formData.otherInfoCode1P1X) : 66,
          otherInfoAmount2P1X: formData.otherInfoAmount2P1X && formData.otherInfoAmount2P1X !== '' ? parseFloat(formData.otherInfoAmount2P1X) : 149,
          otherInfoCode2P1X: formData.otherInfoCode2P1X && formData.otherInfoCode2P1X !== '' ? parseFloat(formData.otherInfoCode2P1X) : 66,
          otherInfoAmount3P1X: formData.otherInfoAmount3P1X && formData.otherInfoAmount3P1X !== '' ? parseFloat(formData.otherInfoAmount3P1X) : 281.5,
          otherInfoCode3P1X: formData.otherInfoCode3P1X && formData.otherInfoCode3P1X !== '' ? parseFloat(formData.otherInfoCode3P1X) : 197.5,
          otherInfoAmount4P1X: formData.otherInfoAmount4P1X && formData.otherInfoAmount4P1X !== '' ? parseFloat(formData.otherInfoAmount4P1X) : 281.5,
          otherInfoCode4P1X: formData.otherInfoCode4P1X && formData.otherInfoCode4P1X !== '' ? parseFloat(formData.otherInfoCode4P1X) : 197.5,
          otherInfoAmount5P1X: formData.otherInfoAmount5P1X && formData.otherInfoAmount5P1X !== '' ? parseFloat(formData.otherInfoAmount5P1X) : 427,
          otherInfoCode5P1X: formData.otherInfoCode5P1X && formData.otherInfoCode5P1X !== '' ? parseFloat(formData.otherInfoCode5P1X) : 340.5,
          otherInfoAmount6P1X: formData.otherInfoAmount6P1X && formData.otherInfoAmount6P1X !== '' ? parseFloat(formData.otherInfoAmount6P1X) : 427,
          otherInfoCode6P1X: formData.otherInfoCode6P1X && formData.otherInfoCode6P1X !== '' ? parseFloat(formData.otherInfoCode6P1X) : 340.5,
          otherInfoAmount7P1X: formData.otherInfoAmount7P1X && formData.otherInfoAmount7P1X !== '' ? parseFloat(formData.otherInfoAmount7P1X) : 427,
          otherInfoCode7P1X: formData.otherInfoCode7P1X && formData.otherInfoCode7P1X !== '' ? parseFloat(formData.otherInfoCode7P1X) : 340.5,
          otherInfoAmount8P1X: formData.otherInfoAmount8P1X && formData.otherInfoAmount8P1X !== '' ? parseFloat(formData.otherInfoAmount8P1X) : 427,
          otherInfoCode8P1X: formData.otherInfoCode8P1X && formData.otherInfoCode8P1X !== '' ? parseFloat(formData.otherInfoCode8P1X) : 340.5,
          otherInfoAmount9P1X: formData.otherInfoAmount9P1X && formData.otherInfoAmount9P1X !== '' ? parseFloat(formData.otherInfoAmount9P1X) : 555.5,
          otherInfoCode9P1X: formData.otherInfoCode9P1X && formData.otherInfoCode9P1X !== '' ? parseFloat(formData.otherInfoCode9P1X) : 472,
          otherInfoAmount10P1X: formData.otherInfoAmount10P1X && formData.otherInfoAmount10P1X !== '' ? parseFloat(formData.otherInfoAmount10P1X) : 555.5,
          otherInfoCode10P1X: formData.otherInfoCode10P1X && formData.otherInfoCode10P1X !== '' ? parseFloat(formData.otherInfoCode10P1X) : 472,
          otherInfoAmount11P1X: formData.otherInfoAmount11P1X && formData.otherInfoAmount11P1X !== '' ? parseFloat(formData.otherInfoAmount11P1X) : 555.5,
          otherInfoCode11P1X: formData.otherInfoCode11P1X && formData.otherInfoCode11P1X !== '' ? parseFloat(formData.otherInfoCode11P1X) : 472,
          otherInfoAmount12P1X: formData.otherInfoAmount12P1X && formData.otherInfoAmount12P1X !== '' ? parseFloat(formData.otherInfoAmount12P1X) : 555.5,
          otherInfoCode12P1X: formData.otherInfoCode12P1X && formData.otherInfoCode12P1X !== '' ? parseFloat(formData.otherInfoCode12P1X) : 472,
          otherInfoAmount1P1Y: formData.otherInfoAmount1P1Y && formData.otherInfoAmount1P1Y !== '' ? parseFloat(formData.otherInfoAmount1P1Y) : 662,
          otherInfoCode1P1Y: formData.otherInfoCode1P1Y && formData.otherInfoCode1P1Y !== '' ? parseFloat(formData.otherInfoCode1P1Y) : 662,
          otherInfoAmount2P1Y: formData.otherInfoAmount2P1Y && formData.otherInfoAmount2P1Y !== '' ? parseFloat(formData.otherInfoAmount2P1Y) : 631,
          otherInfoCode2P1Y: formData.otherInfoCode2P1Y && formData.otherInfoCode2P1Y !== '' ? parseFloat(formData.otherInfoCode2P1Y) : 631,
          otherInfoAmount3P1Y: formData.otherInfoAmount3P1Y && formData.otherInfoAmount3P1Y !== '' ? parseFloat(formData.otherInfoAmount3P1Y) : 662,
          otherInfoCode3P1Y: formData.otherInfoCode3P1Y && formData.otherInfoCode3P1Y !== '' ? parseFloat(formData.otherInfoCode3P1Y) : 662,
          otherInfoAmount4P1Y: formData.otherInfoAmount4P1Y && formData.otherInfoAmount4P1Y !== '' ? parseFloat(formData.otherInfoAmount4P1Y) : 631,
          otherInfoCode4P1Y: formData.otherInfoCode4P1Y && formData.otherInfoCode4P1Y !== '' ? parseFloat(formData.otherInfoCode4P1Y) : 631,
          otherInfoAmount5P1Y: formData.otherInfoAmount5P1Y && formData.otherInfoAmount5P1Y !== '' ? parseFloat(formData.otherInfoAmount5P1Y) : 732,
          otherInfoCode5P1Y: formData.otherInfoCode5P1Y && formData.otherInfoCode5P1Y !== '' ? parseFloat(formData.otherInfoCode5P1Y) : 732,
          otherInfoAmount6P1Y: formData.otherInfoAmount6P1Y && formData.otherInfoAmount6P1Y !== '' ? parseFloat(formData.otherInfoAmount6P1Y) : 697,
          otherInfoCode6P1Y: formData.otherInfoCode6P1Y && formData.otherInfoCode6P1Y !== '' ? parseFloat(formData.otherInfoCode6P1Y) : 697,
          otherInfoAmount7P1Y: formData.otherInfoAmount7P1Y && formData.otherInfoAmount7P1Y !== '' ? parseFloat(formData.otherInfoAmount7P1Y) : 662,
          otherInfoCode7P1Y: formData.otherInfoCode7P1Y && formData.otherInfoCode7P1Y !== '' ? parseFloat(formData.otherInfoCode7P1Y) : 662,
          otherInfoAmount8P1Y: formData.otherInfoAmount8P1Y && formData.otherInfoAmount8P1Y !== '' ? parseFloat(formData.otherInfoAmount8P1Y) : 631,
          otherInfoCode8P1Y: formData.otherInfoCode8P1Y && formData.otherInfoCode8P1Y !== '' ? parseFloat(formData.otherInfoCode8P1Y) : 631,
          otherInfoAmount9P1Y: formData.otherInfoAmount9P1Y && formData.otherInfoAmount9P1Y !== '' ? parseFloat(formData.otherInfoAmount9P1Y) : 732,
          otherInfoCode9P1Y: formData.otherInfoCode9P1Y && formData.otherInfoCode9P1Y !== '' ? parseFloat(formData.otherInfoCode9P1Y) : 732,
          otherInfoAmount10P1Y: formData.otherInfoAmount10P1Y && formData.otherInfoAmount10P1Y !== '' ? parseFloat(formData.otherInfoAmount10P1Y) : 697,
          otherInfoCode10P1Y: formData.otherInfoCode10P1Y && formData.otherInfoCode10P1Y !== '' ? parseFloat(formData.otherInfoCode10P1Y) : 697,
          otherInfoAmount11P1Y: formData.otherInfoAmount11P1Y && formData.otherInfoAmount11P1Y !== '' ? parseFloat(formData.otherInfoAmount11P1Y) : 662,
          otherInfoCode11P1Y: formData.otherInfoCode11P1Y && formData.otherInfoCode11P1Y !== '' ? parseFloat(formData.otherInfoCode11P1Y) : 662,
          otherInfoAmount12P1Y: formData.otherInfoAmount12P1Y && formData.otherInfoAmount12P1Y !== '' ? parseFloat(formData.otherInfoAmount12P1Y) : 631,
          otherInfoCode12P1Y: formData.otherInfoCode12P1Y && formData.otherInfoCode12P1Y !== '' ? parseFloat(formData.otherInfoCode12P1Y) : 631,
          resourceCityAddressP1X: formData.resourceCityAddressP1X && formData.resourceCityAddressP1X !== '' ? parseFloat(formData.resourceCityAddressP1X) : 60,
          resourceCityAddressP1Y: formData.resourceCityAddressP1Y && formData.resourceCityAddressP1Y !== '' ? parseFloat(formData.resourceCityAddressP1Y) : 734,
          resourceAddressP1X: formData.resourceAddressP1X && formData.resourceAddressP1X !== '' ? parseFloat(formData.resourceAddressP1X) : 60,
          resourceAddressP1Y: formData.resourceAddressP1Y && formData.resourceAddressP1Y !== '' ? parseFloat(formData.resourceAddressP1Y) : 754,
          employersCityAddressP1X: formData.employersCityAddressP1X && formData.employersCityAddressP1X !== '' ? parseFloat(formData.employersCityAddressP1X) : 60,
          employersCityAddressP1Y: formData.employersCityAddressP1Y && formData.employersCityAddressP1Y !== '' ? parseFloat(formData.employersCityAddressP1Y) : 928,
          employersAddressP1X: formData.employersAddressP1X && formData.employersAddressP1X !== '' ? parseFloat(formData.employersAddressP1X) : 60,
          employersAddressP1Y: formData.employersAddressP1Y && formData.employersAddressP1Y !== '' ? parseFloat(formData.employersAddressP1Y) : 942,
          employersNameP1X: formData.employersNameP1X && formData.employersNameP1X !== '' ? parseFloat(formData.employersNameP1X) : 60,
          employersNameP1Y: formData.employersNameP1Y && formData.employersNameP1Y !== '' ? parseFloat(formData.employersNameP1Y) : 958,
          initialsP1X: formData.initialsP1X && formData.initialsP1X !== '' ? parseFloat(formData.initialsP1X) : 305,
          initialsP1Y: formData.initialsP1Y && formData.initialsP1Y !== '' ? parseFloat(formData.initialsP1Y) : 794,
          resourceLastNameP1X: formData.resourceLastNameP1X && formData.resourceLastNameP1X !== '' ? parseFloat(formData.resourceLastNameP1X) : 216,
          resourceLastNameP1Y: formData.resourceLastNameP1Y && formData.resourceLastNameP1Y !== '' ? parseFloat(formData.resourceLastNameP1Y) : 794,
          resourceFirstNameP1X: formData.resourceFirstNameP1X && formData.resourceFirstNameP1X !== '' ? parseFloat(formData.resourceFirstNameP1X) : 72,
          resourceFirstNameP1Y: formData.resourceFirstNameP1Y && formData.resourceFirstNameP1Y !== '' ? parseFloat(formData.resourceFirstNameP1Y) : 794,
          accountNoP1X: formData.accountNoP1X && formData.accountNoP1X !== '' ? parseFloat(formData.accountNoP1X) : 210,
          accountNoP1Y: formData.accountNoP1Y && formData.accountNoP1Y !== '' ? parseFloat(formData.accountNoP1Y) : 854,
          socialInsuranceNumberP1X: formData.socialInsuranceNumberP1X && formData.socialInsuranceNumberP1X !== '' ? parseFloat(formData.socialInsuranceNumberP1X) : 65,
          socialInsuranceNumberP1Y: formData.socialInsuranceNumberP1Y && formData.socialInsuranceNumberP1Y !== '' ? parseFloat(formData.socialInsuranceNumberP1Y) : 854,
          employerAccountNoP1X: formData.employerAccountNoP1X && formData.employerAccountNoP1X !== '' ? parseFloat(formData.employerAccountNoP1X) : 65,
          employerAccountNoP1Y: formData.employerAccountNoP1Y && formData.employerAccountNoP1Y !== '' ? parseFloat(formData.employerAccountNoP1Y) : 892,
          slipTypeP2X: formData.slipTypeP2X && formData.slipTypeP2X !== '' ? parseFloat(formData.slipTypeP2X) : 420,
          slipTypeP2Y: formData.slipTypeP2Y && formData.slipTypeP2Y !== '' ? parseFloat(formData.slipTypeP2Y) : 588,
          taxYearP2X: formData.taxYearP2X && formData.taxYearP2X !== '' ? parseFloat(formData.taxYearP2X) : 308,
          taxYearP2Y: formData.taxYearP2Y && formData.taxYearP2Y !== '' ? parseFloat(formData.taxYearP2Y) : 569,
          incomeTaxDeductedP2X: formData.incomeTaxDeductedP2X && formData.incomeTaxDeductedP2X !== '' ? parseFloat(formData.incomeTaxDeductedP2X) : 545.5,
          incomeTaxDeductedP2Y: formData.incomeTaxDeductedP2Y && formData.incomeTaxDeductedP2Y !== '' ? parseFloat(formData.incomeTaxDeductedP2Y) : 503,
          selfEmployedCommissionsP2X: formData.selfEmployedCommissionsP2X && formData.selfEmployedCommissionsP2X !== '' ? parseFloat(formData.selfEmployedCommissionsP2X) : 546.5,
          selfEmployedCommissionsP2Y: formData.selfEmployedCommissionsP2Y && formData.selfEmployedCommissionsP2Y !== '' ? parseFloat(formData.selfEmployedCommissionsP2Y) : 462,
          feesP2X: formData.feesP2X && formData.feesP2X !== '' ? parseFloat(formData.feesP2X) : 546.5,
          feesP2Y: formData.feesP2Y && formData.feesP2Y !== '' ? parseFloat(formData.feesP2Y) : 414,
          pensionOrSuperannuationP2X: formData.pensionOrSuperannuationP2X && formData.pensionOrSuperannuationP2X !== '' ? parseFloat(formData.pensionOrSuperannuationP2X) : 423,
          pensionOrSuperannuationP2Y: formData.pensionOrSuperannuationP2Y && formData.pensionOrSuperannuationP2Y !== '' ? parseFloat(formData.pensionOrSuperannuationP2Y) : 503,
          lumpSumPaymentsP2X: formData.lumpSumPaymentsP2X && formData.lumpSumPaymentsP2X !== '' ? parseFloat(formData.lumpSumPaymentsP2X) : 423,
          lumpSumPaymentsP2Y: formData.lumpSumPaymentsP2Y && formData.lumpSumPaymentsP2Y !== '' ? parseFloat(formData.lumpSumPaymentsP2Y) : 462,
          annuitiesP2X: formData.annuitiesP2X && formData.annuitiesP2X !== '' ? parseFloat(formData.annuitiesP2X) : 423,
          annuitiesP2Y: formData.annuitiesP2Y && formData.annuitiesP2Y !== '' ? parseFloat(formData.annuitiesP2Y) : 414,
          otherInfoAmount1P2X: formData.otherInfoAmount1P2X && formData.otherInfoAmount1P2X !== '' ? parseFloat(formData.otherInfoAmount1P2X) : 149,
          otherInfoCode1P2X: formData.otherInfoCode1P2X && formData.otherInfoCode1P2X !== '' ? parseFloat(formData.otherInfoCode1P2X) : 66,
          otherInfoAmount2P2X: formData.otherInfoAmount2P2X && formData.otherInfoAmount2P2X !== '' ? parseFloat(formData.otherInfoAmount2P2X) : 149,
          otherInfoCode2P2X: formData.otherInfoCode2P2X && formData.otherInfoCode2P2X !== '' ? parseFloat(formData.otherInfoCode2P2X) : 66,
          otherInfoAmount3P2X: formData.otherInfoAmount3P2X && formData.otherInfoAmount3P2X !== '' ? parseFloat(formData.otherInfoAmount3P2X) : 281.5,
          otherInfoCode3P2X: formData.otherInfoCode3P2X && formData.otherInfoCode3P2X !== '' ? parseFloat(formData.otherInfoCode3P2X) : 197.5,
          otherInfoAmount4P2X: formData.otherInfoAmount4P2X && formData.otherInfoAmount4P2X !== '' ? parseFloat(formData.otherInfoAmount4P2X) : 281.5,
          otherInfoCode4P2X: formData.otherInfoCode4P2X && formData.otherInfoCode4P2X !== '' ? parseFloat(formData.otherInfoCode4P2X) : 197.5,
          otherInfoAmount5P2X: formData.otherInfoAmount5P2X && formData.otherInfoAmount5P2X !== '' ? parseFloat(formData.otherInfoAmount5P2X) : 427,
          otherInfoCode5P2X: formData.otherInfoCode5P2X && formData.otherInfoCode5P2X !== '' ? parseFloat(formData.otherInfoCode5P2X) : 340.5,
          otherInfoAmount6P2X: formData.otherInfoAmount6P2X && formData.otherInfoAmount6P2X !== '' ? parseFloat(formData.otherInfoAmount6P2X) : 427,
          otherInfoCode6P2X: formData.otherInfoCode6P2X && formData.otherInfoCode6P2X !== '' ? parseFloat(formData.otherInfoCode6P2X) : 340.5,
          otherInfoAmount7P2X: formData.otherInfoAmount7P2X && formData.otherInfoAmount7P2X !== '' ? parseFloat(formData.otherInfoAmount7P2X) : 427,
          otherInfoCode7P2X: formData.otherInfoCode7P2X && formData.otherInfoCode7P2X !== '' ? parseFloat(formData.otherInfoCode7P2X) : 340.5,
          otherInfoAmount8P2X: formData.otherInfoAmount8P2X && formData.otherInfoAmount8P2X !== '' ? parseFloat(formData.otherInfoAmount8P2X) : 427,
          otherInfoCode8P2X: formData.otherInfoCode8P2X && formData.otherInfoCode8P2X !== '' ? parseFloat(formData.otherInfoCode8P2X) : 340.5,
          otherInfoAmount9P2X: formData.otherInfoAmount9P2X && formData.otherInfoAmount9P2X !== '' ? parseFloat(formData.otherInfoAmount9P2X) : 555.5,
          otherInfoCode9P2X: formData.otherInfoCode9P2X && formData.otherInfoCode9P2X !== '' ? parseFloat(formData.otherInfoCode9P2X) : 472,
          otherInfoAmount10P2X: formData.otherInfoAmount10P2X && formData.otherInfoAmount10P2X !== '' ? parseFloat(formData.otherInfoAmount10P2X) : 555.5,
          otherInfoCode10P2X: formData.otherInfoCode10P2X && formData.otherInfoCode10P2X !== '' ? parseFloat(formData.otherInfoCode10P2X) : 472,
          otherInfoAmount11P2X: formData.otherInfoAmount11P2X && formData.otherInfoAmount11P2X !== '' ? parseFloat(formData.otherInfoAmount11P2X) : 555.5,
          otherInfoCode11P2X: formData.otherInfoCode11P2X && formData.otherInfoCode11P2X !== '' ? parseFloat(formData.otherInfoCode11P2X) : 472,
          otherInfoAmount12P2X: formData.otherInfoAmount12P2X && formData.otherInfoAmount12P2X !== '' ? parseFloat(formData.otherInfoAmount12P2X) : 555.5,
          otherInfoCode12P2X: formData.otherInfoCode12P2X && formData.otherInfoCode12P2X !== '' ? parseFloat(formData.otherInfoCode12P2X) : 472,
          otherInfoAmount1P2Y: formData.otherInfoAmount1P2Y && formData.otherInfoAmount1P2Y !== '' ? parseFloat(formData.otherInfoAmount1P2Y) : 279,
          otherInfoCode1P2Y: formData.otherInfoCode1P2Y && formData.otherInfoCode1P2Y !== '' ? parseFloat(formData.otherInfoCode1P2Y) : 279,
          otherInfoAmount2P2Y: formData.otherInfoAmount2P2Y && formData.otherInfoAmount2P2Y !== '' ? parseFloat(formData.otherInfoAmount2P2Y) : 248,
          otherInfoCode2P2Y: formData.otherInfoCode2P2Y && formData.otherInfoCode2P2Y !== '' ? parseFloat(formData.otherInfoCode2P2Y) : 248,
          otherInfoAmount3P2Y: formData.otherInfoAmount3P2Y && formData.otherInfoAmount3P2Y !== '' ? parseFloat(formData.otherInfoAmount3P2Y) : 279,
          otherInfoCode3P2Y: formData.otherInfoCode3P2Y && formData.otherInfoCode3P2Y !== '' ? parseFloat(formData.otherInfoCode3P2Y) : 279,
          otherInfoAmount4P2Y: formData.otherInfoAmount4P2Y && formData.otherInfoAmount4P2Y !== '' ? parseFloat(formData.otherInfoAmount4P2Y) : 248,
          otherInfoCode4P2Y: formData.otherInfoCode4P2Y && formData.otherInfoCode4P2Y !== '' ? parseFloat(formData.otherInfoCode4P2Y) : 248,
          otherInfoAmount5P2Y: formData.otherInfoAmount5P2Y && formData.otherInfoAmount5P2Y !== '' ? parseFloat(formData.otherInfoAmount5P2Y) : 349,
          otherInfoCode5P2Y: formData.otherInfoCode5P2Y && formData.otherInfoCode5P2Y !== '' ? parseFloat(formData.otherInfoCode5P2Y) : 349,
          otherInfoAmount6P2Y: formData.otherInfoAmount6P2Y && formData.otherInfoAmount6P2Y !== '' ? parseFloat(formData.otherInfoAmount6P2Y) : 314,
          otherInfoCode6P2Y: formData.otherInfoCode6P2Y && formData.otherInfoCode6P2Y !== '' ? parseFloat(formData.otherInfoCode6P2Y) : 314,
          otherInfoAmount7P2Y: formData.otherInfoAmount7P2Y && formData.otherInfoAmount7P2Y !== '' ? parseFloat(formData.otherInfoAmount7P2Y) : 279,
          otherInfoCode7P2Y: formData.otherInfoCode7P2Y && formData.otherInfoCode7P2Y !== '' ? parseFloat(formData.otherInfoCode7P2Y) : 279,
          otherInfoAmount8P2Y: formData.otherInfoAmount8P2Y && formData.otherInfoAmount8P2Y !== '' ? parseFloat(formData.otherInfoAmount8P2Y) : 248,
          otherInfoCode8P2Y: formData.otherInfoCode8P2Y && formData.otherInfoCode8P2Y !== '' ? parseFloat(formData.otherInfoCode8P2Y) : 248,
          otherInfoAmount9P2Y: formData.otherInfoAmount9P2Y && formData.otherInfoAmount9P2Y !== '' ? parseFloat(formData.otherInfoAmount9P2Y) : 349,
          otherInfoCode9P2Y: formData.otherInfoCode9P2Y && formData.otherInfoCode9P2Y !== '' ? parseFloat(formData.otherInfoCode9P2Y) : 349,
          otherInfoAmount10P2Y: formData.otherInfoAmount10P2Y && formData.otherInfoAmount10P2Y !== '' ? parseFloat(formData.otherInfoAmount10P2Y) : 314,
          otherInfoCode10P2Y: formData.otherInfoCode10P2Y && formData.otherInfoCode10P2Y !== '' ? parseFloat(formData.otherInfoCode10P2Y) : 314,
          otherInfoAmount11P2Y: formData.otherInfoAmount11P2Y && formData.otherInfoAmount11P2Y !== '' ? parseFloat(formData.otherInfoAmount11P2Y) : 279,
          otherInfoCode11P2Y: formData.otherInfoCode11P2Y && formData.otherInfoCode11P2Y !== '' ? parseFloat(formData.otherInfoCode11P2Y) : 279,
          otherInfoAmount12P2Y: formData.otherInfoAmount12P2Y && formData.otherInfoAmount12P2Y !== '' ? parseFloat(formData.otherInfoAmount12P2Y) : 248,
          otherInfoCode12P2Y: formData.otherInfoCode12P2Y && formData.otherInfoCode12P2Y !== '' ? parseFloat(formData.otherInfoCode12P2Y) : 248,
          resourceCityAddressP2X: formData.resourceCityAddressP2X && formData.resourceCityAddressP2X !== '' ? parseFloat(formData.resourceCityAddressP2X) : 60,
          resourceCityAddressP2Y: formData.resourceCityAddressP2Y && formData.resourceCityAddressP2Y !== '' ? parseFloat(formData.resourceCityAddressP2Y) : 360,
          resourceAddressP2X: formData.resourceAddressP2X && formData.resourceAddressP2X !== '' ? parseFloat(formData.resourceAddressP2X) : 60,
          resourceAddressP2Y: formData.resourceAddressP2Y && formData.resourceAddressP2Y !== '' ? parseFloat(formData.resourceAddressP2Y) : 375,
          employersCityAddressP2X: formData.employersCityAddressP2X && formData.employersCityAddressP2X !== '' ? parseFloat(formData.employersCityAddressP2X) : 60,
          employersCityAddressP2Y: formData.employersCityAddressP2Y && formData.employersCityAddressP2Y !== '' ? parseFloat(formData.employersCityAddressP2Y) : 549,
          employersAddressP2X: formData.employersAddressP2X && formData.employersAddressP2X !== '' ? parseFloat(formData.employersAddressP2X) : 60,
          employersAddressP2Y: formData.employersAddressP2Y && formData.employersAddressP2Y !== '' ? parseFloat(formData.employersAddressP2Y) : 561,
          employersNameP2X: formData.employersNameP2X && formData.employersNameP2X !== '' ? parseFloat(formData.employersNameP2X) : 60,
          employersNameP2Y: formData.employersNameP2Y && formData.employersNameP2Y !== '' ? parseFloat(formData.employersNameP2Y) : 575,
          initialsP2X: formData.initialsP2X && formData.initialsP2X !== '' ? parseFloat(formData.initialsP2X) : 305,
          initialsP2Y: formData.initialsP2Y && formData.initialsP2Y !== '' ? parseFloat(formData.initialsP2Y) : 411,
          resourceLastNameP2X: formData.resourceLastNameP2X && formData.resourceLastNameP2X !== '' ? parseFloat(formData.resourceLastNameP2X) : 216,
          resourceLastNameP2Y: formData.resourceLastNameP2Y && formData.resourceLastNameP2Y !== '' ? parseFloat(formData.resourceLastNameP2Y) : 411,
          resourceFirstNameP2X: formData.resourceFirstNameP2X && formData.resourceFirstNameP2X !== '' ? parseFloat(formData.resourceFirstNameP2X) : 72,
          resourceFirstNameP2Y: formData.resourceFirstNameP2Y && formData.resourceFirstNameP2Y !== '' ? parseFloat(formData.resourceFirstNameP2Y) : 411,
          accountNoP2X: formData.accountNoP2X && formData.accountNoP2X !== '' ? parseFloat(formData.accountNoP2X) : 210,
          accountNoP2Y: formData.accountNoP2Y && formData.accountNoP2Y !== '' ? parseFloat(formData.accountNoP2Y) : 469,
          socialInsuranceNumberP2X: formData.socialInsuranceNumberP2X && formData.socialInsuranceNumberP2X !== '' ? parseFloat(formData.socialInsuranceNumberP2X) : 65,
          socialInsuranceNumberP2Y: formData.socialInsuranceNumberP2Y && formData.socialInsuranceNumberP2Y !== '' ? parseFloat(formData.socialInsuranceNumberP2Y) : 469,
          employerAccountNoP2X: formData.employerAccountNoP2X && formData.employerAccountNoP2X !== '' ? parseFloat(formData.employerAccountNoP2X) : 65,
          employerAccountNoP2Y: formData.employerAccountNoP2Y && formData.employerAccountNoP2Y !== '' ? parseFloat(formData.employerAccountNoP2Y) : 509
        }
        let finalPayloadData = {
          payload:payload,
          taxYear:selectedYear,
          tag:selectedTag
        }
        submitFlexFields(finalPayloadData)
    } else if(selectedTag === 'rl1'){
      let payload:any = {
        taxYearP1X: formData.taxYearP1X && formData.taxYearP1X !== '' ? parseFloat(formData.taxYearP1X) : 328,
        taxYearP1Y: formData.taxYearP1Y && formData.taxYearP1Y !== '' ? parseFloat(formData.taxYearP1Y) : 954,
        boxAP1X: formData.boxAP1X && formData.boxAP1X !== '' ? parseFloat(formData.boxAP1X) : 92.5,
        boxAP1Y: formData.boxAP1Y && formData.boxAP1Y !== '' ? parseFloat(formData.boxAP1Y) : 707,
        boxBP1X: formData.boxBP1X && formData.boxBP1X !== '' ? parseFloat(formData.boxBP1X) : 189,
        boxBBP1X: formData.boxBBP1X && formData.boxBBP1X !== '' && selectedYear > 2023 ? parseFloat(formData.boxBBP1X) : 189,
        boxBP1Y: formData.boxBP1Y && formData.boxBP1Y !== '' ? parseFloat(formData.boxBP1Y) : 707,
        boxBBP1Y: formData.boxBBP1Y && formData.boxBBP1Y !== '' && selectedYear > 2023 ? parseFloat(formData.boxBBP1Y) : 707,
        boxCP1X: formData.boxCP1X && formData.boxCP1X !== '' ? parseFloat(formData.boxCP1X) : 284,
        boxCP1Y: formData.boxCP1Y && formData.boxCP1Y !== '' ? parseFloat(formData.boxCP1Y) : 707,
        boxDP1X: formData.boxDP1X && formData.boxDP1X !== '' ? parseFloat(formData.boxDP1X) : 379,
        boxDP1Y: formData.boxDP1Y && formData.boxDP1Y !== '' ? parseFloat(formData.boxDP1Y) : 707,
        boxEP1X: formData.boxEP1X && formData.boxEP1X !== '' ? parseFloat(formData.boxEP1X) : 474,
        boxEP1Y: formData.boxEP1Y && formData.boxEP1Y !== '' ? parseFloat(formData.boxEP1Y) : 707,
        boxFP1X: formData.boxFP1X && formData.boxFP1X !== '' ? parseFloat(formData.boxFP1X) : 567,
        boxFP1Y: formData.boxFP1Y && formData.boxFP1Y !== '' ? parseFloat(formData.boxFP1Y) : 707,
        boxGP1X: formData.boxGP1X && formData.boxGP1X !== '' ? parseFloat(formData.boxGP1X) : 92.5,
        boxGP1Y: formData.boxGP1Y && formData.boxGP1Y !== '' ? parseFloat(formData.boxGP1Y) : 683,
        boxHP1X: formData.boxHP1X && formData.boxHP1X !== '' ? parseFloat(formData.boxHP1X) : 189,
        boxHP1Y: formData.boxHP1Y && formData.boxHP1Y !== '' ? parseFloat(formData.boxHP1Y) : 683,
        boxIP1X: formData.boxIP1X && formData.boxIP1X !== '' ? parseFloat(formData.boxIP1X) : 284,
        boxIP1Y: formData.boxIP1Y && formData.boxIP1Y !== '' ? parseFloat(formData.boxIP1Y) : 683,
        boxJP1X: formData.boxJP1X && formData.boxJP1X !== '' ? parseFloat(formData.boxJP1X) : 379,
        boxJP1Y: formData.boxJP1Y && formData.boxJP1Y !== '' ? parseFloat(formData.boxJP1Y) : 683,
        boxKP1X: formData.boxKP1X && formData.boxKP1X !== '' ? parseFloat(formData.boxKP1X) : 474,
        boxKP1Y: formData.boxKP1Y && formData.boxKP1Y !== '' ? parseFloat(formData.boxKP1Y) : 683,
        boxLP1X: formData.boxLP1X && formData.boxLP1X !== '' ? parseFloat(formData.boxLP1X) : 567,
        boxLP1Y: formData.boxLP1Y && formData.boxLP1Y !== '' ? parseFloat(formData.boxLP1Y) : 683,
        boxMP1X: formData.boxMP1X && formData.boxMP1X !== '' ? parseFloat(formData.boxMP1X) : 94.5,
        boxMP1Y: formData.boxMP1Y && formData.boxMP1Y !== '' ? parseFloat(formData.boxMP1Y) : 659,
        boxNP1X: formData.boxNP1X && formData.boxNP1X !== '' ? parseFloat(formData.boxNP1X) : 189,
        boxNP1Y: formData.boxNP1Y && formData.boxNP1Y !== '' ? parseFloat(formData.boxNP1Y) : 659,
        boxOP1X: formData.boxOP1X && formData.boxOP1X !== '' ? parseFloat(formData.boxOP1X) : 284,
        boxOP1Y: formData.boxOP1Y && formData.boxOP1Y !== '' ? parseFloat(formData.boxOP1Y) : 659,
        boxPP1X: formData.boxPP1X && formData.boxPP1X !== '' ? parseFloat(formData.boxPP1X) : 379,
        boxPP1Y: formData.boxPP1Y && formData.boxPP1Y !== '' ? parseFloat(formData.boxPP1Y) : 659,
        boxQP1X: formData.boxQP1X && formData.boxQP1X !== '' ? parseFloat(formData.boxQP1X) : 474,
        boxQP1Y: formData.boxQP1Y && formData.boxQP1Y !== '' ? parseFloat(formData.boxQP1Y) : 659,
        boxRP1X: formData.boxRP1X && formData.boxRP1X !== '' ? parseFloat(formData.boxRP1X) : 567,
        boxRP1Y: formData.boxRP1Y && formData.boxRP1Y !== '' ? parseFloat(formData.boxRP1Y) : 659,
        boxSP1X: formData.boxSP1X && formData.boxSP1X !== '' ? parseFloat(formData.boxSP1X) : 94.5,
        boxSP1Y: formData.boxSP1Y && formData.boxSP1Y !== '' ? parseFloat(formData.boxSP1Y) : 635,
        boxTP1X: formData.boxTP1X && formData.boxTP1X !== '' ? parseFloat(formData.boxTP1X) : 189,
        boxTP1Y: formData.boxTP1Y && formData.boxTP1Y !== '' ? parseFloat(formData.boxTP1Y) : 635,
        boxUP1X: formData.boxUP1X && formData.boxUP1X !== '' ? parseFloat(formData.boxUP1X) : 284,
        boxUP1Y: formData.boxUP1Y && formData.boxUP1Y !== '' ? parseFloat(formData.boxUP1Y) : 635,
        boxVP1X: formData.boxVP1X && formData.boxVP1X !== '' ? parseFloat(formData.boxVP1X) : 379,
        boxVP1Y: formData.boxVP1Y && formData.boxVP1Y !== '' ? parseFloat(formData.boxVP1Y) : 635,
        boxWP1X: formData.boxWP1X && formData.boxWP1X !== '' ? parseFloat(formData.boxWP1X) : 474,
        boxWP1Y: formData.boxWP1Y && formData.boxWP1Y !== '' ? parseFloat(formData.boxWP1Y) : 635,
        codeOP1X: formData.codeOP1X && formData.codeOP1X !== '' ? parseFloat(formData.codeOP1X) : 563,
        codeOP1Y: formData.codeOP1Y && formData.codeOP1Y !== '' ? parseFloat(formData.codeOP1Y) : 635,
        otherInfoAmount1P1X: formData.otherInfoAmount1P1X && formData.otherInfoAmount1P1X !== '' ? parseFloat(formData.otherInfoAmount1P1X) : 145,
        otherInfoCode1P1X: formData.otherInfoCode1P1X && formData.otherInfoCode1P1X !== '' ? parseFloat(formData.otherInfoCode1P1X) : 74,
        otherInfoAmount2P1X: formData.otherInfoAmount2P1X && formData.otherInfoAmount2P1X !== '' ? parseFloat(formData.otherInfoAmount2P1X) : 270.5,
        otherInfoCode2P1X: formData.otherInfoCode2P1X && formData.otherInfoCode2P1X !== '' ? parseFloat(formData.otherInfoCode2P1X) : 204,
        otherInfoAmount3P1X: formData.otherInfoAmount3P1X && formData.otherInfoAmount3P1X !== '' ? parseFloat(formData.otherInfoAmount3P1X) : 390.5,
        otherInfoCode3P1X: formData.otherInfoCode3P1X && formData.otherInfoCode3P1X !== '' ? parseFloat(formData.otherInfoCode3P1X) : 335,
        otherInfoAmount4P1X: formData.otherInfoAmount4P1X && formData.otherInfoAmount4P1X !== '' ? parseFloat(formData.otherInfoAmount4P1X) : 542.5,
        otherInfoCode4P1X: formData.otherInfoCode4P1X && formData.otherInfoCode4P1X !== '' ? parseFloat(formData.otherInfoCode4P1X) : 470,
        otherInfoAmount1P1Y: formData.otherInfoAmount1P1Y && formData.otherInfoAmount1P1Y !== '' ? parseFloat(formData.otherInfoAmount1P1Y) : 618,
        otherInfoCode1P1Y: formData.otherInfoCode1P1Y && formData.otherInfoCode1P1Y !== '' ? parseFloat(formData.otherInfoCode1P1Y) : 618,
        otherInfoAmount2P1Y: formData.otherInfoAmount2P1Y && formData.otherInfoAmount2P1Y !== '' ? parseFloat(formData.otherInfoAmount2P1Y) : 618,
        otherInfoCode2P1Y: formData.otherInfoCode2P1Y && formData.otherInfoCode2P1Y !== '' ? parseFloat(formData.otherInfoCode2P1Y) : 618,
        otherInfoAmount3P1Y: formData.otherInfoAmount3P1Y && formData.otherInfoAmount3P1Y !== '' ? parseFloat(formData.otherInfoAmount3P1Y) : 618,
        otherInfoCode3P1Y: formData.otherInfoCode3P1Y && formData.otherInfoCode3P1Y !== '' ? parseFloat(formData.otherInfoCode3P1Y) : 618,
        otherInfoAmount4P1Y: formData.otherInfoAmount4P1Y && formData.otherInfoAmount4P1Y !== '' ? parseFloat(formData.otherInfoAmount4P1Y) : 618,
        otherInfoCode4P1Y: formData.otherInfoCode4P1Y && formData.otherInfoCode4P1Y !== '' ? parseFloat(formData.otherInfoCode4P1Y) : 618,
        codeDuRelevéP1X: formData.codeDuRelevéP1X && formData.codeDuRelevéP1X !== '' ? parseFloat(formData.codeDuRelevéP1X) : 372,
        codeDuRelevéP1Y: formData.codeDuRelevéP1Y && formData.codeDuRelevéP1Y !== '' ? parseFloat(formData.codeDuRelevéP1Y) : 737,
        noDuDernierRelevéTransmisP1X: formData.noDuDernierRelevéTransmisP1X && formData.noDuDernierRelevéTransmisP1X !== '' ? parseFloat(formData.noDuDernierRelevéTransmisP1X) : 430,
        noDuDernierRelevéTransmisP1Y: formData.noDuDernierRelevéTransmisP1Y && formData.noDuDernierRelevéTransmisP1Y !== '' ? parseFloat(formData.noDuDernierRelevéTransmisP1Y) : 737,
        rl1202210AboveBoxP1X: formData.rl1202210AboveBoxP1X && formData.rl1202210AboveBoxP1X !== '' ? parseFloat(formData.rl1202210AboveBoxP1X) : 510,
        rl1202210AboveBoxP1Y: formData.rl1202210AboveBoxP1Y && formData.rl1202210AboveBoxP1Y !== '' ? parseFloat(formData.rl1202210AboveBoxP1Y) : 749.5,
        rl1202210DownBoxP1X: formData.rl1202210DownBoxP1X && formData.rl1202210DownBoxP1X !== '' ? parseFloat(formData.rl1202210DownBoxP1X) : 510,
        rl1202210DownBoxP1Y: formData.rl1202210DownBoxP1Y && formData.rl1202210DownBoxP1Y !== '' ? parseFloat(formData.rl1202210DownBoxP1Y) : 737,
        resouceFirstNameP1X: formData.resouceFirstNameP1X && formData.resouceFirstNameP1X !== '' ? parseFloat(formData.resouceFirstNameP1X) : 40,
        resouceFirstNameP1Y: formData.resouceFirstNameP1Y && formData.resouceFirstNameP1Y !== '' ? parseFloat(formData.resouceFirstNameP1Y) : 552,
        resouceLastNameP1X: formData.resouceLastNameP1X && formData.resouceLastNameP1X !== '' ? parseFloat(formData.resouceLastNameP1X) : 40,
        resouceLastNameP1Y: formData.resouceLastNameP1Y && formData.resouceLastNameP1Y !== '' ? parseFloat(formData.resouceLastNameP1Y) : 527,
        resouceAddressLine1P1X: formData.resouceAddressLine1P1X && formData.resouceAddressLine1P1X !== '' ? parseFloat(formData.resouceAddressLine1P1X) : 40,
        resouceAddressLine1P1Y: formData.resouceAddressLine1P1Y && formData.resouceAddressLine1P1Y !== '' ? parseFloat(formData.resouceAddressLine1P1Y) : 503,
        resouceAddressLine2P1X: formData.resouceAddressLine2P1X && formData.resouceAddressLine2P1X !== '' ? parseFloat(formData.resouceAddressLine2P1X) : 40,
        resouceAddressLine2P1Y: formData.resouceAddressLine2P1Y && formData.resouceAddressLine2P1Y !== '' ? parseFloat(formData.resouceAddressLine2P1Y) : 480,
        resouceCityP1X: formData.resouceCityP1X && formData.resouceCityP1X !== '' ? parseFloat(formData.resouceCityP1X) : 40,
        resouceCityP1Y: formData.resouceCityP1Y && formData.resouceCityP1Y !== '' ? parseFloat(formData.resouceCityP1Y) : 457,
        socialInsurance1To3P1X: formData.socialInsurance1To3P1X && formData.socialInsurance1To3P1X !== '' ? parseFloat(formData.socialInsurance1To3P1X) : 340,
        socialInsurance1To3P1Y: formData.socialInsurance1To3P1Y && formData.socialInsurance1To3P1Y !== '' ? parseFloat(formData.socialInsurance1To3P1Y) : 552,
        socialInsurance4To6P1X: formData.socialInsurance4To6P1X && formData.socialInsurance4To6P1X !== '' ? parseFloat(formData.socialInsurance4To6P1X) : 380,
        socialInsurance4To6P1Y: formData.socialInsurance4To6P1Y && formData.socialInsurance4To6P1Y !== '' ? parseFloat(formData.socialInsurance4To6P1Y) : 552,
        socialInsurance7To9P1X: formData.socialInsurance7To9P1X && formData.socialInsurance7To9P1X !== '' ? parseFloat(formData.socialInsurance7To9P1X) : 420,
        socialInsurance7To9P1Y: formData.socialInsurance7To9P1Y && formData.socialInsurance7To9P1Y !== '' ? parseFloat(formData.socialInsurance7To9P1Y) : 552,
        referenceNoP1X: formData.referenceNoP1X && formData.referenceNoP1X !== '' ? parseFloat(formData.referenceNoP1X) : 480,
        referenceNoP1Y: formData.referenceNoP1Y && formData.referenceNoP1Y !== '' ? parseFloat(formData.referenceNoP1Y) : 552,
        clientNameLine1P1X: formData.clientNameLine1P1X && formData.clientNameLine1P1X !== '' ? parseFloat(formData.clientNameLine1P1X) : 335,
        clientNameLine1P1Y: formData.clientNameLine1P1Y && formData.clientNameLine1P1Y !== '' ? parseFloat(formData.clientNameLine1P1Y) : 527,
        clientNameLine2P1X: formData.clientNameLine2P1X && formData.clientNameLine2P1X !== '' ? parseFloat(formData.clientNameLine2P1X) : 335,
        clientNameLine2P1Y: formData.clientNameLine2P1Y && formData.clientNameLine2P1Y !== '' ? parseFloat(formData.clientNameLine2P1Y) : 503,
        clientAddressLine1P1X: formData.clientAddressLine1P1X && formData.clientAddressLine1P1X !== '' ? parseFloat(formData.clientAddressLine1P1X) : 335,
        clientAddressLine1P1Y: formData.clientAddressLine1P1Y && formData.clientAddressLine1P1Y !== '' ? parseFloat(formData.clientAddressLine1P1Y) : 480,
        clientAddressLine2P1X: formData.clientAddressLine2P1X && formData.clientAddressLine2P1X !== '' ? parseFloat(formData.clientAddressLine2P1X) : 335,
        clientAddressLine2P1Y: formData.clientAddressLine2P1Y && formData.clientAddressLine2P1Y !== '' ? parseFloat(formData.clientAddressLine2P1Y) : 457,
        clientCityP1X: formData.clientCityP1X && formData.clientCityP1X !== '' ? parseFloat(formData.clientCityP1X) : 335,
        clientCityP1Y: formData.clientCityP1Y && formData.clientCityP1Y !== '' ? parseFloat(formData.clientCityP1Y) : 430,
        resourceProvinceCodeP1X: formData.resourceProvinceCodeP1X && formData.resourceProvinceCodeP1X !== '' ? parseFloat(formData.resourceProvinceCodeP1X) : 40,
        resourceProvinceCodeP1Y: formData.resourceProvinceCodeP1Y && formData.resourceProvinceCodeP1Y !== '' ? parseFloat(formData.resourceProvinceCodeP1Y) : 430.5,
        resourcePostalCodeFirstThreeP1X: formData.resourcePostalCodeFirstThreeP1X && formData.resourcePostalCodeFirstThreeP1X !== '' ? parseFloat(formData.resourcePostalCodeFirstThreeP1X) : 80,
        resourcePostalCodeFirstThreeP1Y: formData.resourcePostalCodeFirstThreeP1Y && formData.resourcePostalCodeFirstThreeP1Y !== '' ? parseFloat(formData.resourcePostalCodeFirstThreeP1Y) : 430.5,
        resourcePostalCodeLastThreeP1X: formData.resourcePostalCodeLastThreeP1X && formData.resourcePostalCodeLastThreeP1X !== '' ? parseFloat(formData.resourcePostalCodeLastThreeP1X) : 116,
        resourcePostalCodeLastThreeP1Y: formData.resourcePostalCodeLastThreeP1Y && formData.resourcePostalCodeLastThreeP1Y !== '' ? parseFloat(formData.resourcePostalCodeLastThreeP1Y) : 430.5,
        clientProvinceCodeP1X: formData.clientProvinceCodeP1X && formData.clientProvinceCodeP1X !== '' ? parseFloat(formData.clientProvinceCodeP1X) : 335,
        clientProvinceCodeP1Y: formData.clientProvinceCodeP1Y && formData.clientProvinceCodeP1Y !== '' ? parseFloat(formData.clientProvinceCodeP1Y) : 406.5,
        clientPostalCodeFirstThreeP1X: formData.clientPostalCodeFirstThreeP1X && formData.clientPostalCodeFirstThreeP1X !== '' ? parseFloat(formData.clientPostalCodeFirstThreeP1X) : 375,
        clientPostalCodeFirstThreeP1Y: formData.clientPostalCodeFirstThreeP1Y && formData.clientPostalCodeFirstThreeP1Y !== '' ? parseFloat(formData.clientPostalCodeFirstThreeP1Y) : 406.5,
        clientPostalCodeLastThreeP1X: formData.clientPostalCodeLastThreeP1X && formData.clientPostalCodeLastThreeP1X !== '' ? parseFloat(formData.clientPostalCodeLastThreeP1X) : 415,
        clientPostalCodeLastThreeP1Y: formData.clientPostalCodeLastThreeP1Y && formData.clientPostalCodeLastThreeP1Y !== '' ? parseFloat(formData.clientPostalCodeLastThreeP1Y) : 406.5,
        taxYearP2X: formData.taxYearP2X && formData.taxYearP2X !== '' ? parseFloat(formData.taxYearP2X) : 328,
        taxYearP2Y: formData.taxYearP2Y && formData.taxYearP2Y !== '' ? parseFloat(formData.taxYearP2Y) : 954,
        boxAP2X: formData.boxAP2X && formData.boxAP2X !== '' ? parseFloat(formData.boxAP2X) : 92.5,
        boxAP2Y: formData.boxAP2Y && formData.boxAP2Y !== '' ? parseFloat(formData.boxAP2Y) : 707,
        boxBP2X: formData.boxBP2X && formData.boxBP2X !== '' ? parseFloat(formData.boxBP2X) : 189,
        boxBBP2X: formData.boxBBP2X && formData.boxBBP2X !== ''  && selectedYear > 2023 ? parseFloat(formData.boxBBP2X) : 189,
        boxBP2Y: formData.boxBP2Y && formData.boxBP2Y !== '' ? parseFloat(formData.boxBP2Y) : 707,
        boxBBP2Y: formData.boxBBP2Y && formData.boxBBP2Y !== '' && selectedYear > 2023 ? parseFloat(formData.boxBBP2Y) : 707,
        boxCP2X: formData.boxCP2X && formData.boxCP2X !== '' ? parseFloat(formData.boxCP2X) : 284,
        boxCP2Y: formData.boxCP2Y && formData.boxCP2Y !== '' ? parseFloat(formData.boxCP2Y) : 707,
        boxDP2X: formData.boxDP2X && formData.boxDP2X !== '' ? parseFloat(formData.boxDP2X) : 379,
        boxDP2Y: formData.boxDP2Y && formData.boxDP2Y !== '' ? parseFloat(formData.boxDP2Y) : 707,
        boxEP2X: formData.boxEP2X && formData.boxEP2X !== '' ? parseFloat(formData.boxEP2X) : 474,
        boxEP2Y: formData.boxEP2Y && formData.boxEP2Y !== '' ? parseFloat(formData.boxEP2Y) : 707,
        boxFP2X: formData.boxFP2X && formData.boxFP2X !== '' ? parseFloat(formData.boxFP2X) : 567,
        boxFP2Y: formData.boxFP2Y && formData.boxFP2Y !== '' ? parseFloat(formData.boxFP2Y) : 707,
        boxGP2X: formData.boxGP2X && formData.boxGP2X !== '' ? parseFloat(formData.boxGP2X) : 92.5,
        boxGP2Y: formData.boxGP2Y && formData.boxGP2Y !== '' ? parseFloat(formData.boxGP2Y) : 683,
        boxHP2X: formData.boxHP2X && formData.boxHP2X !== '' ? parseFloat(formData.boxHP2X) : 189,
        boxHP2Y: formData.boxHP2Y && formData.boxHP2Y !== '' ? parseFloat(formData.boxHP2Y) : 683,
        boxIP2X: formData.boxIP2X && formData.boxIP2X !== '' ? parseFloat(formData.boxIP2X) : 284,
        boxIP2Y: formData.boxIP2Y && formData.boxIP2Y !== '' ? parseFloat(formData.boxIP2Y) : 683,
        boxJP2X: formData.boxJP2X && formData.boxJP2X !== '' ? parseFloat(formData.boxJP2X) : 379,
        boxJP2Y: formData.boxJP2Y && formData.boxJP2Y !== '' ? parseFloat(formData.boxJP2Y) : 683,
        boxKP2X: formData.boxKP2X && formData.boxKP2X !== '' ? parseFloat(formData.boxKP2X) : 474,
        boxKP2Y: formData.boxKP2Y && formData.boxKP2Y !== '' ? parseFloat(formData.boxKP2Y) : 683,
        boxLP2X: formData.boxLP2X && formData.boxLP2X !== '' ? parseFloat(formData.boxLP2X) : 567,
        boxLP2Y: formData.boxLP2Y && formData.boxLP2Y !== '' ? parseFloat(formData.boxLP2Y) : 683,
        boxMP2X: formData.boxMP2X && formData.boxMP2X !== '' ? parseFloat(formData.boxMP2X) : 92.5,
        boxMP2Y: formData.boxMP2Y && formData.boxMP2Y !== '' ? parseFloat(formData.boxMP2Y) : 659,
        boxNP2X: formData.boxNP2X && formData.boxNP2X !== '' ? parseFloat(formData.boxNP2X) : 189,
        boxNP2Y: formData.boxNP2Y && formData.boxNP2Y !== '' ? parseFloat(formData.boxNP2Y) : 659,
        boxOP2X: formData.boxOP2X && formData.boxOP2X !== '' ? parseFloat(formData.boxOP2X) : 284,
        boxOP2Y: formData.boxOP2Y && formData.boxOP2Y !== '' ? parseFloat(formData.boxOP2Y) : 659,
        boxPP2X: formData.boxPP2X && formData.boxPP2X !== '' ? parseFloat(formData.boxPP2X) : 379,
        boxPP2Y: formData.boxPP2Y && formData.boxPP2Y !== '' ? parseFloat(formData.boxPP2Y) : 659,
        boxQP2X: formData.boxQP2X && formData.boxQP2X !== '' ? parseFloat(formData.boxQP2X) : 474,
        boxQP2Y: formData.boxQP2Y && formData.boxQP2Y !== '' ? parseFloat(formData.boxQP2Y) : 659,
        boxRP2X: formData.boxRP2X && formData.boxRP2X !== '' ? parseFloat(formData.boxRP2X) : 567,
        boxRP2Y: formData.boxRP2Y && formData.boxRP2Y !== '' ? parseFloat(formData.boxRP2Y) : 659,
        boxSP2X: formData.boxSP2X && formData.boxSP2X !== '' ? parseFloat(formData.boxSP2X) : 92.5,
        boxSP2Y: formData.boxSP2Y && formData.boxSP2Y !== '' ? parseFloat(formData.boxSP2Y) : 635,
        boxTP2X: formData.boxTP2X && formData.boxTP2X !== '' ? parseFloat(formData.boxTP2X) : 189,
        boxTP2Y: formData.boxTP2Y && formData.boxTP2Y !== '' ? parseFloat(formData.boxTP2Y) : 635,
        boxUP2X: formData.boxUP2X && formData.boxUP2X !== '' ? parseFloat(formData.boxUP2X) : 284,
        boxUP2Y: formData.boxUP2Y && formData.boxUP2Y !== '' ? parseFloat(formData.boxUP2Y) : 635,
        boxVP2X: formData.boxVP2X && formData.boxVP2X !== '' ? parseFloat(formData.boxVP2X) : 379,
        boxVP2Y: formData.boxVP2Y && formData.boxVP2Y !== '' ? parseFloat(formData.boxVP2Y) : 635,
        boxWP2X: formData.boxWP2X && formData.boxWP2X !== '' ? parseFloat(formData.boxWP2X) : 474,
        boxWP2Y: formData.boxWP2Y && formData.boxWP2Y !== '' ? parseFloat(formData.boxWP2Y) : 635,
        codeOP2X: formData.codeOP2X && formData.codeOP2X !== '' ? parseFloat(formData.codeOP2X) : 567,
        codeOP2Y: formData.codeOP2Y && formData.codeOP2Y !== '' ? parseFloat(formData.codeOP2Y) : 635,
        otherInfoAmount1P2X: formData.otherInfoAmount1P2X && formData.otherInfoAmount1P2X !== '' ? parseFloat(formData.otherInfoAmount1P2X) : 145,
        otherInfoCode1P2X: formData.otherInfoCode1P2X && formData.otherInfoCode1P2X !== '' ? parseFloat(formData.otherInfoCode1P2X) : 74,
        otherInfoAmount2P2X: formData.otherInfoAmount2P2X && formData.otherInfoAmount2P2X !== '' ? parseFloat(formData.otherInfoAmount2P2X) : 270.5,
        otherInfoCode2P2X: formData.otherInfoCode2P2X && formData.otherInfoCode2P2X !== '' ? parseFloat(formData.otherInfoCode2P2X) : 204,
        otherInfoAmount3P2X: formData.otherInfoAmount3P2X && formData.otherInfoAmount3P2X !== '' ? parseFloat(formData.otherInfoAmount3P2X) : 390.5,
        otherInfoCode3P2X: formData.otherInfoCode3P2X && formData.otherInfoCode3P2X !== '' ? parseFloat(formData.otherInfoCode3P2X) : 335,
        otherInfoAmount4P2X: formData.otherInfoAmount4P2X && formData.otherInfoAmount4P2X !== '' ? parseFloat(formData.otherInfoAmount4P2X) : 542.5,
        otherInfoCode4P2X: formData.otherInfoCode4P2X && formData.otherInfoCode4P2X !== '' ? parseFloat(formData.otherInfoCode4P2X) : 470,
        otherInfoAmount1P2Y: formData.otherInfoAmount1P2Y && formData.otherInfoAmount1P2Y !== '' ? parseFloat(formData.otherInfoAmount1P2Y) : 618,
        otherInfoCode1P2Y: formData.otherInfoCode1P2Y && formData.otherInfoCode1P2Y !== '' ? parseFloat(formData.otherInfoCode1P2Y) : 618,
        otherInfoAmount2P2Y: formData.otherInfoAmount2P2Y && formData.otherInfoAmount2P2Y !== '' ? parseFloat(formData.otherInfoAmount2P2Y) : 618,
        otherInfoCode2P2Y: formData.otherInfoCode2P2Y && formData.otherInfoCode2P2Y !== '' ? parseFloat(formData.otherInfoCode2P2Y) : 618,
        otherInfoAmount3P2Y: formData.otherInfoAmount3P2Y && formData.otherInfoAmount3P2Y !== '' ? parseFloat(formData.otherInfoAmount3P2Y) : 618,
        otherInfoCode3P2Y: formData.otherInfoCode3P2Y && formData.otherInfoCode3P2Y !== '' ? parseFloat(formData.otherInfoCode3P2Y) : 618,
        otherInfoAmount4P2Y: formData.otherInfoAmount4P2Y && formData.otherInfoAmount4P2Y !== '' ? parseFloat(formData.otherInfoAmount4P2Y) : 618,
        otherInfoCode4P2Y: formData.otherInfoCode4P2Y && formData.otherInfoCode4P2Y !== '' ? parseFloat(formData.otherInfoCode4P2Y) : 618,
        codeDuRelevéP2X: formData.codeDuRelevéP2X && formData.codeDuRelevéP2X !== '' ? parseFloat(formData.codeDuRelevéP2X) : 372,
        codeDuRelevéP2Y: formData.codeDuRelevéP2Y && formData.codeDuRelevéP2Y !== '' ? parseFloat(formData.codeDuRelevéP2Y) : 737,
        noDuDernierRelevéTransmisP2X: formData.noDuDernierRelevéTransmisP2X && formData.noDuDernierRelevéTransmisP2X !== '' ? parseFloat(formData.noDuDernierRelevéTransmisP2X) : 430,
        noDuDernierRelevéTransmisP2Y: formData.noDuDernierRelevéTransmisP2Y && formData.noDuDernierRelevéTransmisP2Y !== '' ? parseFloat(formData.noDuDernierRelevéTransmisP2Y) : 737,
        rl1202210AboveBoxP2X: formData.rl1202210AboveBoxP2X && formData.rl1202210AboveBoxP2X !== '' ? parseFloat(formData.rl1202210AboveBoxP2X) : 510,
        rl1202210AboveBoxP2Y: formData.rl1202210AboveBoxP2Y && formData.rl1202210AboveBoxP2Y !== '' ? parseFloat(formData.rl1202210AboveBoxP2Y) : 749.5,
        rl1202210DownBoxP2X: formData.rl1202210DownBoxP2X && formData.rl1202210DownBoxP2X !== '' ? parseFloat(formData.rl1202210DownBoxP2X) : 510,
        rl1202210DownBoxP2Y: formData.rl1202210DownBoxP2Y && formData.rl1202210DownBoxP2Y !== '' ? parseFloat(formData.rl1202210DownBoxP2Y) : 737,
        resourceFirstLastNameP2X: formData.resourceFirstLastNameP2X && formData.resourceFirstLastNameP2X !== '' ? parseFloat(formData.resourceFirstLastNameP2X) : 40,
        resourceFirstLastNameP2Y: formData.resourceFirstLastNameP2Y && formData.resourceFirstLastNameP2Y !== '' ? parseFloat(formData.resourceFirstLastNameP2Y) : 115,
        resourceAddressP2X: formData.resourceAddressP2X && formData.resourceAddressP2X !== '' ? parseFloat(formData.resourceAddressP2X) : 40,
        resourceAddressP2Y: formData.resourceAddressP2Y && formData.resourceAddressP2Y !== '' ? parseFloat(formData.resourceAddressP2Y) : 102,
        resourceCityP2X: formData.resourceCityP2X && formData.resourceCityP2X !== '' ? parseFloat(formData.resourceCityP2X) : 40,
        resourceCityP2Y: formData.resourceCityP2Y && formData.resourceCityP2Y !== '' ? parseFloat(formData.resourceCityP2Y) : 90,
        clientSocialInsurance1To3P2X: formData.clientSocialInsurance1To3P2X && formData.clientSocialInsurance1To3P2X !== '' ? parseFloat(formData.clientSocialInsurance1To3P2X) : 340,
        clientSocialInsurance1To3P2Y: formData.clientSocialInsurance1To3P2Y && formData.clientSocialInsurance1To3P2Y !== '' ? parseFloat(formData.clientSocialInsurance1To3P2Y) : 170,
        clientSocialInsurance4To6P2X: formData.clientSocialInsurance4To6P2X && formData.clientSocialInsurance4To6P2X !== '' ? parseFloat(formData.clientSocialInsurance4To6P2X) : 380,
        clientSocialInsurance4To6P2Y: formData.clientSocialInsurance4To6P2Y && formData.clientSocialInsurance4To6P2Y !== '' ? parseFloat(formData.clientSocialInsurance4To6P2Y) : 170,
        clientSocialInsurance7To9P2X: formData.clientSocialInsurance7To9P2X && formData.clientSocialInsurance7To9P2X !== '' ? parseFloat(formData.clientSocialInsurance7To9P2X) : 420,
        clientSocialInsurance7To9P2Y: formData.clientSocialInsurance7To9P2Y && formData.clientSocialInsurance7To9P2Y !== '' ? parseFloat(formData.clientSocialInsurance7To9P2Y) : 170,
        clientReferenceNo1To3P2X: formData.clientReferenceNo1To3P2X && formData.clientReferenceNo1To3P2X !== '' ? parseFloat(formData.clientReferenceNo1To3P2X) : 485,
        clientReferenceNo1To3P2Y: formData.clientReferenceNo1To3P2Y && formData.clientReferenceNo1To3P2Y !== '' ? parseFloat(formData.clientReferenceNo1To3P2Y) : 170,
        clientNameP2X: formData.clientNameP2X && formData.clientNameP2X !== '' ? parseFloat(formData.clientNameP2X) : 370,
        clientNameP2Y: formData.clientNameP2Y && formData.clientNameP2Y !== '' ? parseFloat(formData.clientNameP2Y) : 130,
        clientAddressP2X: formData.clientAddressP2X && formData.clientAddressP2X !== '' ? parseFloat(formData.clientAddressP2X) : 370,
        clientAddressP2Y: formData.clientAddressP2Y && formData.clientAddressP2Y !== '' ? parseFloat(formData.clientAddressP2Y) : 115,
        clientCityP2X: formData.clientCityP2X && formData.clientCityP2X !== '' ? parseFloat(formData.clientCityP2X) : 370,
        clientCityP2Y: formData.clientCityP2Y && formData.clientCityP2Y !== '' ? parseFloat(formData.clientCityP2Y) : 100
      }
      let finalPayloadData = {
        payload:payload,
        taxYear:selectedYear,
        tag:selectedTag
      }
      submitFlexFields(finalPayloadData)
    } else if(selectedTag === 'rl1SummaryEnglish'){
      let payload:any = {
        employerNameP1X: formData.employerNameP1X && formData.employerNameP1X !== '' ? parseFloat(formData.employerNameP1X) : 30,
        employerNameP1Y: formData.employerNameP1Y && formData.employerNameP1Y !== '' ? parseFloat(formData.employerNameP1Y) : 680,
        mailingAddressP1X: formData.mailingAddressP1X && formData.mailingAddressP1X !== '' ? parseFloat(formData.mailingAddressP1X) : 30,
        mailingAddressP1Y: formData.mailingAddressP1Y && formData.mailingAddressP1Y !== '' ? parseFloat(formData.mailingAddressP1Y) : 635,
        postalCodeP1X: formData.postalCodeP1X && formData.postalCodeP1X !== '' ? parseFloat(formData.postalCodeP1X) : 305,
        postalCodeP1Y: formData.postalCodeP1Y && formData.postalCodeP1Y !== '' ? parseFloat(formData.postalCodeP1Y) : 635,
        identificationNumberP1X: formData.identificationNumberP1X && formData.identificationNumberP1X !== '' ? parseFloat(formData.identificationNumberP1X) : 495,
        identificationNumberP1Y: formData.identificationNumberP1Y && formData.identificationNumberP1Y !== '' ? parseFloat(formData.identificationNumberP1Y) : 648,
        fileRsP1X: formData.fileRsP1X && formData.fileRsP1X !== '' ? parseFloat(formData.fileRsP1X) : 460,
        fileRsP1Y: formData.fileRsP1Y && formData.fileRsP1Y !== '' ? parseFloat(formData.fileRsP1Y) : 635,
        yearP1X: formData.yearP1X && formData.yearP1X !== '' ? parseFloat(formData.yearP1X) : 410,
        yearP1Y: formData.yearP1Y && formData.yearP1Y !== '' ? parseFloat(formData.yearP1Y) : 600,
        onPaperP1X: formData.onPaperP1X && formData.onPaperP1X !== '' ? parseFloat(formData.onPaperP1X) : 210,
        onPaperP1Y: formData.onPaperP1Y && formData.onPaperP1Y !== '' ? parseFloat(formData.onPaperP1Y) : 565,
        onlineP1X: formData.onlineP1X && formData.onlineP1X !== '' ? parseFloat(formData.onlineP1X) : 330,
        onlineP1Y: formData.onlineP1Y && formData.onlineP1Y !== '' ? parseFloat(formData.onlineP1Y) : 565,
        transmitterNumberP1X: formData.transmitterNumberP1X && formData.transmitterNumberP1X !== '' ? parseFloat(formData.transmitterNumberP1X) : 465,
        transmitterNumberP1Y: formData.transmitterNumberP1Y && formData.transmitterNumberP1Y !== '' ? parseFloat(formData.transmitterNumberP1Y) : 565,
        employeeContributionP1X: formData.employeeContributionP1X && formData.employeeContributionP1X !== '' ? parseFloat(formData.employeeContributionP1X) : 412,
        employeeContributionP1Y: formData.employeeContributionP1Y && formData.employeeContributionP1Y !== '' ? parseFloat(formData.employeeContributionP1Y) : 292,
        employerContributionP1X: formData.employerContributionP1X && formData.employerContributionP1X !== '' ? parseFloat(formData.employerContributionP1X) : 412,
        employerContributionP1Y: formData.employerContributionP1Y && formData.employerContributionP1Y !== '' ? parseFloat(formData.employerContributionP1Y) : 278,
        contributionSumP1X: formData.contributionSumP1X && formData.contributionSumP1X !== '' ? parseFloat(formData.contributionSumP1X) : 558,
        contributionSumP1Y: formData.contributionSumP1Y && formData.contributionSumP1Y !== '' ? parseFloat(formData.contributionSumP1Y) : 278,
        employeePremiumsP1X: formData.employeePremiumsP1X && formData.employeePremiumsP1X !== '' ? parseFloat(formData.employeePremiumsP1X) : 412,
        employeePremiumsP1Y: formData.employeePremiumsP1Y && formData.employeePremiumsP1Y !== '' ? parseFloat(formData.employeePremiumsP1Y) : 242,
        employerPremiumsP1X: formData.employerPremiumsP1X && formData.employerPremiumsP1X !== '' ? parseFloat(formData.employerPremiumsP1X) : 412,
        employerPremiumsP1Y: formData.employerPremiumsP1Y && formData.employerPremiumsP1Y !== '' ? parseFloat(formData.employerPremiumsP1Y) : 230,
        premiumsSumP1X: formData.premiumsSumP1X && formData.premiumsSumP1X !== '' ? parseFloat(formData.premiumsSumP1X) : 558,
        premiumsSumP1Y: formData.premiumsSumP1Y && formData.premiumsSumP1Y !== '' ? parseFloat(formData.premiumsSumP1Y) : 230,
        employeeIncomeTaxP1X: formData.employeeIncomeTaxP1X && formData.employeeIncomeTaxP1X !== '' ? parseFloat(formData.employeeIncomeTaxP1X) : 412,
        employeeIncomeTaxP1Y: formData.employeeIncomeTaxP1Y && formData.employeeIncomeTaxP1Y !== '' ? parseFloat(formData.employeeIncomeTaxP1Y) : 196,
        employerIncomeTaxP1X: formData.employerIncomeTaxP1X && formData.employerIncomeTaxP1X !== '' ? parseFloat(formData.employerIncomeTaxP1X) : 412,
        employerIncomeTaxP1Y: formData.employerIncomeTaxP1Y && formData.employerIncomeTaxP1Y !== '' ? parseFloat(formData.employerIncomeTaxP1Y) : 183,
        incomeTaxSumP1X: formData.incomeTaxSumP1X && formData.incomeTaxSumP1X !== '' ? parseFloat(formData.incomeTaxSumP1X) : 558,
        incomeTaxSumP1Y: formData.incomeTaxSumP1Y && formData.incomeTaxSumP1Y !== '' ? parseFloat(formData.incomeTaxSumP1Y) : 183,
        line39And12SumP1X: formData.line39And12SumP1X && formData.line39And12SumP1X !== '' ? parseFloat(formData.line39And12SumP1X) : 558,
        line39And12SumP1Y: formData.line39And12SumP1Y && formData.line39And12SumP1Y !== '' ? parseFloat(formData.line39And12SumP1Y) : 162,
        line26And36SumP1X: formData.line26And36SumP1X && formData.line26And36SumP1X !== '' ? parseFloat(formData.line26And36SumP1X) : 558,
        line26And36SumP1Y: formData.line26And36SumP1Y && formData.line26And36SumP1Y !== '' ? parseFloat(formData.line26And36SumP1Y) : 137,
        box27P1X: formData.box27P1X && formData.box27P1X !== '' ? parseFloat(formData.box27P1X) : 558,
        box27P1Y: formData.box27P1Y && formData.box27P1Y !== '' ? parseFloat(formData.box27P1Y) : 100,
        box28P2X: formData.box28P2X && formData.box28P2X !== '' ? parseFloat(formData.box28P2X) : 450,
        box28P2Y: formData.box28P2Y && formData.box28P2Y !== '' ? parseFloat(formData.box28P2Y) : 737,
        box29P2X: formData.box29P2X && formData.box29P2X !== '' ? parseFloat(formData.box29P2X) : 345,
        box29P2Y: formData.box29P2Y && formData.box29P2Y !== '' ? parseFloat(formData.box29P2Y) : 715,
        box30P2X: formData.box30P2X && formData.box30P2X !== '' ? parseFloat(formData.box30P2X) : 317,
        box30P2Y: formData.box30P2Y && formData.box30P2Y !== '' ? parseFloat(formData.box30P2Y) : 688,
        box31P2X: formData.box31P2X && formData.box31P2X !== '' ? parseFloat(formData.box31P2X) : 171,
        box31P2Y: formData.box31P2Y && formData.box31P2Y !== '' ? parseFloat(formData.box31P2Y) : 652,
        box32P2X: formData.box32P2X && formData.box32P2X !== '' ? parseFloat(formData.box32P2X) : 317,
        box32P2Y: formData.box32P2Y && formData.box32P2Y !== '' ? parseFloat(formData.box32P2Y) : 652,
        box34P2X: formData.box34P2X && formData.box34P2X !== '' ? parseFloat(formData.box34P2X) : 317,
        box34P2Y: formData.box34P2Y && formData.box34P2Y !== '' ? parseFloat(formData.box34P2Y) : 638,
        box36P2X: formData.box36P2X && formData.box36P2X !== '' ? parseFloat(formData.box36P2X) : 228,
        box36P2Y: formData.box36P2Y && formData.box36P2Y !== '' ? parseFloat(formData.box36P2Y) : 627,
        box37P2X: formData.box37P2X && formData.box37P2X !== '' ? parseFloat(formData.box37P2X) : 558,
        box37P2Y: formData.box37P2Y && formData.box37P2Y !== '' ? parseFloat(formData.box37P2Y) : 609,
        box38P2X: formData.box38P2X && formData.box38P2X !== '' ? parseFloat(formData.box38P2X) : 558,
        box38P2Y: formData.box38P2Y && formData.box38P2Y !== '' ? parseFloat(formData.box38P2Y) : 581,
        box39P2X: formData.box39P2X && formData.box39P2X !== '' ? parseFloat(formData.box39P2X) : 558,
        box39P2Y: formData.box39P2Y && formData.box39P2Y !== '' ? parseFloat(formData.box39P2Y) : 560,
        box40P2X: formData.box40P2X && formData.box40P2X !== '' ? parseFloat(formData.box40P2X) : 470,
        box40P2Y: formData.box40P2Y && formData.box40P2Y !== '' ? parseFloat(formData.box40P2Y) : 515,
        box41NotEnteredCodeP2X: formData.box41NotEnteredCodeP2X && formData.box41NotEnteredCodeP2X !== '' ? parseFloat(formData.box41NotEnteredCodeP2X) : 285,
        box41NotEnteredCodeP2Y: formData.box41NotEnteredCodeP2Y && formData.box41NotEnteredCodeP2Y !== '' ? parseFloat(formData.box41NotEnteredCodeP2Y) : 478,
        box41EnteredCodeP2X: formData.box41EnteredCodeP2X && formData.box41EnteredCodeP2X !== '' ? parseFloat(formData.box41EnteredCodeP2X) : 410,
        box41EnteredCodeP2Y: formData.box41EnteredCodeP2Y && formData.box41EnteredCodeP2Y !== '' ? parseFloat(formData.box41EnteredCodeP2Y) : 478,
        contributionRelated1P2X: formData.contributionRelated1P2X && formData.contributionRelated1P2X !== '' ? parseFloat(formData.contributionRelated1P2X) : 410,
        contributionRelated1P2Y: formData.contributionRelated1P2Y && formData.contributionRelated1P2Y !== '' ? parseFloat(formData.contributionRelated1P2Y) : 433,
        contributionRelated2P2X: formData.contributionRelated2P2X && formData.contributionRelated2P2X !== '' ? parseFloat(formData.contributionRelated2P2X) : 285,
        contributionRelated2P2Y: formData.contributionRelated2P2Y && formData.contributionRelated2P2Y !== '' ? parseFloat(formData.contributionRelated2P2Y) : 433,
        box43P2X: formData.box43P2X && formData.box43P2X !== '' ? parseFloat(formData.box43P2X) : 558,
        box43P2Y: formData.box43P2Y && formData.box43P2Y !== '' ? parseFloat(formData.box43P2Y) : 433,
        box50P2X: formData.box50P2X && formData.box50P2X !== '' ? parseFloat(formData.box50P2X) : 228,
        box50P2Y: formData.box50P2Y && formData.box50P2Y !== '' ? parseFloat(formData.box50P2Y) : 344,
        box51P2X: formData.box51P2X && formData.box51P2X !== '' ? parseFloat(formData.box51P2X) : 410,
        box51P2Y: formData.box51P2Y && formData.box51P2Y !== '' ? parseFloat(formData.box51P2Y) : 344,
        box52P2X: formData.box52P2X && formData.box52P2X !== '' ? parseFloat(formData.box52P2X) : 410,
        box52P2Y: formData.box52P2Y && formData.box52P2Y !== '' ? parseFloat(formData.box52P2Y) : 322,
        wsdrfP2X: formData.wsdrfP2X && formData.wsdrfP2X !== '' ? parseFloat(formData.wsdrfP2X) : 410,
        wsdrfP2Y: formData.wsdrfP2Y && formData.wsdrfP2Y !== '' ? parseFloat(formData.wsdrfP2Y) : 296,
        box53P2X: formData.box53P2X && formData.box53P2X !== '' ? parseFloat(formData.box53P2X) : 558,
        box53P2Y: formData.box53P2Y && formData.box53P2Y !== '' ? parseFloat(formData.box53P2Y) : 296,
        box70P2X: formData.box70P2X && formData.box70P2X !== '' ? parseFloat(formData.box70P2X) : 558,
        box70P2Y: formData.box70P2Y && formData.box70P2Y !== '' ? parseFloat(formData.box70P2Y) : 270,
        box71P2X: formData.box71P2X && formData.box71P2X !== '' ? parseFloat(formData.box71P2X) : 228,
        box71P2Y: formData.box71P2Y && formData.box71P2Y !== '' ? parseFloat(formData.box71P2Y) : 252,
        box72P2X: formData.box72P2X && formData.box72P2X !== '' ? parseFloat(formData.box72P2X) : 480,
        box72P2Y: formData.box72P2Y && formData.box72P2Y !== '' ? parseFloat(formData.box72P2Y) : 252,
        box75P2X: formData.box75P2X && formData.box75P2X !== '' ? parseFloat(formData.box75P2X) : 554,
        box75P2Y: formData.box75P2Y && formData.box75P2Y !== '' ? parseFloat(formData.box75P2Y) : 135,
        box76P2X: formData.box76P2X && formData.box76P2X !== '' ? parseFloat(formData.box76P2X) : 554,
        box76P2Y: formData.box76P2Y && formData.box76P2Y !== '' ? parseFloat(formData.box76P2Y) : 122,
        box77P2X: formData.box77P2X && formData.box77P2X !== '' ? parseFloat(formData.box77P2X) : 554,
        box77P2Y: formData.box77P2Y && formData.box77P2Y !== '' ? parseFloat(formData.box77P2Y) : 110,
        box78P2X: formData.box78P2X && formData.box78P2X !== '' ? parseFloat(formData.box78P2X) : 554,
        box78P2Y: formData.box78P2Y && formData.box78P2Y !== '' ? parseFloat(formData.box78P2Y) : 97,
        box79P2X: formData.box79P2X && formData.box79P2X !== '' ? parseFloat(formData.box79P2X) : 554,
        box79P2Y: formData.box79P2Y && formData.box79P2Y !== '' ? parseFloat(formData.box79P2Y) : 75,
        nameOfEmployerP3X: formData.nameOfEmployerP3X && formData.nameOfEmployerP3X !== '' ? parseFloat(formData.nameOfEmployerP3X) : 30,
        nameOfEmployerP3Y: formData.nameOfEmployerP3Y && formData.nameOfEmployerP3Y !== '' ? parseFloat(formData.nameOfEmployerP3Y) : 155,
        mailingAdressP3X: formData.mailingAdressP3X && formData.mailingAdressP3X !== '' ? parseFloat(formData.mailingAdressP3X) : 30,
        mailingAdressP3Y: formData.mailingAdressP3Y && formData.mailingAdressP3Y !== '' ? parseFloat(formData.mailingAdressP3Y) : 110,
        box90P3X: formData.box90P3X && formData.box90P3X !== '' ? parseFloat(formData.box90P3X) : 350,
        box90P3Y: formData.box90P3Y && formData.box90P3Y !== '' ? parseFloat(formData.box90P3Y) : 135,
        box91P3X: formData.box91P3X && formData.box91P3X !== '' ? parseFloat(formData.box91P3X) : 452,
        box91P3Y: formData.box91P3Y && formData.box91P3Y !== '' ? parseFloat(formData.box91P3Y) : 135,
        box92P3X: formData.box92P3X && formData.box92P3X !== '' ? parseFloat(formData.box92P3X) : 554,
        box92P3Y: formData.box92P3Y && formData.box92P3Y !== '' ? parseFloat(formData.box92P3Y) : 135,
        box93P3X: formData.box93P3X && formData.box93P3X !== '' ? parseFloat(formData.box93P3X) : 350,
        box93P3Y: formData.box93P3Y && formData.box93P3Y !== '' ? parseFloat(formData.box93P3Y) : 85,
        box94P3X: formData.box94P3X && formData.box94P3X !== '' ? parseFloat(formData.box94P3X) : 452,
        box94P3Y: formData.box94P3Y && formData.box94P3Y !== '' ? parseFloat(formData.box94P3Y) : 85,
        box95P3X: formData.box95P3X && formData.box95P3X !== '' ? parseFloat(formData.box95P3X) : 554,
        box95P3Y: formData.box95P3Y && formData.box95P3Y !== '' ? parseFloat(formData.box95P3Y) : 85
      }
      let finalPayloadData = {
        payload:payload,
        taxYear:selectedYear,
        tag:selectedTag
      }
      submitFlexFields(finalPayloadData)
    } else if(selectedTag === 'rl1SummaryFrench'){
      let payload:any = {
        employerNameP1X:formData.employerNameP1X && formData.employerNameP1X !== '' ? parseFloat(formData.employerNameP1X) : 30,
        employerNameP1Y:formData.employerNameP1Y && formData.employerNameP1Y !== '' ? parseFloat(formData.employerNameP1Y) : 680,
        mailingAddressP1X:formData.mailingAddressP1X && formData.mailingAddressP1X !== '' ? parseFloat(formData.mailingAddressP1X) : 30,
        mailingAddressP1Y:formData.mailingAddressP1Y && formData.mailingAddressP1Y !== '' ? parseFloat(formData.mailingAddressP1Y) : 635,
        postalCodeP1X:formData.postalCodeP1X && formData.postalCodeP1X !== '' ? parseFloat(formData.postalCodeP1X) : 305,
        postalCodeP1Y:formData.postalCodeP1Y && formData.postalCodeP1Y !== '' ? parseFloat(formData.postalCodeP1Y) : 635,
        identificationNumberP1X:formData.identificationNumberP1X && formData.identificationNumberP1X !== '' ? parseFloat(formData.identificationNumberP1X) : 495,
        identificationNumberP1Y:formData.identificationNumberP1Y && formData.identificationNumberP1Y !== '' ? parseFloat(formData.identificationNumberP1Y) : 648,
        fileRsP1X:formData.fileRsP1X && formData.fileRsP1X !== '' ? parseFloat(formData.fileRsP1X) : 460,
        fileRsP1Y:formData.fileRsP1Y && formData.fileRsP1Y !== '' ? parseFloat(formData.fileRsP1Y) : 635,
        yearP1X:formData.yearP1X && formData.yearP1X !== '' ? parseFloat(formData.yearP1X) : 410,
        yearP1Y:formData.yearP1Y && formData.yearP1Y !== '' ? parseFloat(formData.yearP1Y) : 600,
        onPaperP1X:formData.onPaperP1X && formData.onPaperP1X !== '' ? parseFloat(formData.onPaperP1X) : 210,
        onPaperP1Y:formData.onPaperP1Y && formData.onPaperP1Y !== '' ? parseFloat(formData.onPaperP1Y) : 565,
        onlineP1X:formData.onlineP1X && formData.onlineP1X !== '' ? parseFloat(formData.onlineP1X) : 330,
        onlineP1Y:formData.onlineP1Y && formData.onlineP1Y !== '' ? parseFloat(formData.onlineP1Y) : 565,
        transmitterNumberP1X:formData.transmitterNumberP1X && formData.transmitterNumberP1X !== '' ? parseFloat(formData.transmitterNumberP1X) : 465,
        transmitterNumberP1Y:formData.transmitterNumberP1Y && formData.transmitterNumberP1Y !== '' ? parseFloat(formData.transmitterNumberP1Y) : 565,
        employeeContributionP1X:formData.employeeContributionP1X && formData.employeeContributionP1X !== '' ? parseFloat(formData.employeeContributionP1X) : 412,
        employeeContributionP1Y:formData.employeeContributionP1Y && formData.employeeContributionP1Y !== '' ? parseFloat(formData.employeeContributionP1Y) : 292,
        employerContributionP1X:formData.employerContributionP1X && formData.employerContributionP1X !== '' ? parseFloat(formData.employerContributionP1X) : 412,
        employerContributionP1Y:formData.employerContributionP1Y && formData.employerContributionP1Y !== '' ? parseFloat(formData.employerContributionP1Y) : 278,
        contributionSumP1X:formData.contributionSumP1X && formData.contributionSumP1X !== '' ? parseFloat(formData.contributionSumP1X) : 558,
        contributionSumP1Y:formData.contributionSumP1Y && formData.contributionSumP1Y !== '' ? parseFloat(formData.contributionSumP1Y) : 278,
        employeePremiumsP1X:formData.employeePremiumsP1X && formData.employeePremiumsP1X !== '' ? parseFloat(formData.employeePremiumsP1X) : 412,
        employeePremiumsP1Y:formData.employeePremiumsP1Y && formData.employeePremiumsP1Y !== '' ? parseFloat(formData.employeePremiumsP1Y) : 242,
        employerPremiumsP1X:formData.employerPremiumsP1X && formData.employerPremiumsP1X !== '' ? parseFloat(formData.employerPremiumsP1X) : 412,
        employerPremiumsP1Y:formData.employerPremiumsP1Y && formData.employerPremiumsP1Y !== '' ? parseFloat(formData.employerPremiumsP1Y) : 230,
        premiumsSumP1X:formData.premiumsSumP1X && formData.premiumsSumP1X !== '' ? parseFloat(formData.premiumsSumP1X) : 558,
        premiumsSumP1Y:formData.premiumsSumP1Y && formData.premiumsSumP1Y !== '' ? parseFloat(formData.premiumsSumP1Y) : 230,
        employeeIncomeTaxP1X:formData.employeeIncomeTaxP1X && formData.employeeIncomeTaxP1X !== '' ? parseFloat(formData.employeeIncomeTaxP1X) : 412,
        employeeIncomeTaxP1Y:formData.employeeIncomeTaxP1Y && formData.employeeIncomeTaxP1Y !== '' ? parseFloat(formData.employeeIncomeTaxP1Y) : 196,
        employerIncomeTaxP1X:formData.employerIncomeTaxP1X && formData.employerIncomeTaxP1X !== '' ? parseFloat(formData.employerIncomeTaxP1X) : 412,
        employerIncomeTaxP1Y:formData.employerIncomeTaxP1Y && formData.employerIncomeTaxP1Y !== '' ? parseFloat(formData.employerIncomeTaxP1Y) : 183,
        incomeTaxSumP1X:formData.incomeTaxSumP1X && formData.incomeTaxSumP1X !== '' ? parseFloat(formData.incomeTaxSumP1X) : 558,
        incomeTaxSumP1Y:formData.incomeTaxSumP1Y && formData.incomeTaxSumP1Y !== '' ? parseFloat(formData.incomeTaxSumP1Y) : 183,
        line39And12SumP1X:formData.line39And12SumP1X && formData.line39And12SumP1X !== '' ? parseFloat(formData.line39And12SumP1X) : 558,
        line39And12SumP1Y:formData.line39And12SumP1Y && formData.line39And12SumP1Y !== '' ? parseFloat(formData.line39And12SumP1Y) : 162,
        line26And36SumP1X:formData.line26And36SumP1X && formData.line26And36SumP1X !== '' ? parseFloat(formData.line26And36SumP1X) : 558,
        line26And36SumP1Y:formData.line26And36SumP1Y && formData.line26And36SumP1Y !== '' ? parseFloat(formData.line26And36SumP1Y) : 137,
        box27P1X:formData.box27P1X && formData.box27P1X !== '' ? parseFloat(formData.box27P1X) : 558,
        box27P1Y:formData.box27P1Y && formData.box27P1Y !== '' ? parseFloat(formData.box27P1Y) : 100,
        box28P2X:formData.box28P2X && formData.box28P2X !== '' ? parseFloat(formData.box28P2X) : 450,
        box28P2Y:formData.box28P2Y && formData.box28P2Y !== '' ? parseFloat(formData.box28P2Y) : 737,
        box29P2X:formData.box29P2X && formData.box29P2X !== '' ? parseFloat(formData.box29P2X) : 345,
        box29P2Y:formData.box29P2Y && formData.box29P2Y !== '' ? parseFloat(formData.box29P2Y) : 715,
        box30P2X:formData.box30P2X && formData.box30P2X !== '' ? parseFloat(formData.box30P2X) : 317,
        box30P2Y:formData.box30P2Y && formData.box30P2Y !== '' ? parseFloat(formData.box30P2Y) : 688,
        box31P2X:formData.box31P2X && formData.box31P2X !== '' ? parseFloat(formData.box31P2X) : 171,
        box31P2Y:formData.box31P2Y && formData.box31P2Y !== '' ? parseFloat(formData.box31P2Y) : 652,
        box32P2X:formData.box32P2X && formData.box32P2X !== '' ? parseFloat(formData.box32P2X) : 317,
        box32P2Y:formData.box32P2Y && formData.box32P2Y !== '' ? parseFloat(formData.box32P2Y) : 652,
        box34P2X:formData.box34P2X && formData.box34P2X !== '' ? parseFloat(formData.box34P2X) : 317,
        box34P2Y:formData.box34P2Y && formData.box34P2Y !== '' ? parseFloat(formData.box34P2Y) : 638,
        box36P2X:formData.box36P2X && formData.box36P2X !== '' ? parseFloat(formData.box36P2X) : 228,
        box36P2Y:formData.box36P2Y && formData.box36P2Y !== '' ? parseFloat(formData.box36P2Y) : 627,
        box37P2X:formData.box37P2X && formData.box37P2X !== '' ? parseFloat(formData.box37P2X) : 558,
        box37P2Y:formData.box37P2Y && formData.box37P2Y !== '' ? parseFloat(formData.box37P2Y) : 609,
        box38P2X:formData.box38P2X && formData.box38P2X !== '' ? parseFloat(formData.box38P2X) : 558,
        box38P2Y:formData.box38P2Y && formData.box38P2Y !== '' ? parseFloat(formData.box38P2Y) : 581,
        box39P2X:formData.box39P2X && formData.box39P2X !== '' ? parseFloat(formData.box39P2X) : 558,
        box39P2Y:formData.box39P2Y && formData.box39P2Y !== '' ? parseFloat(formData.box39P2Y) : 560,
        box40P2X:formData.box40P2X && formData.box40P2X !== '' ? parseFloat(formData.box40P2X) : 470,
        box40P2Y:formData.box40P2Y && formData.box40P2Y !== '' ? parseFloat(formData.box40P2Y) : 515,
        box41NotEnteredCodeP2X:formData.box41NotEnteredCodeP2X && formData.box41NotEnteredCodeP2X !== '' ? parseFloat(formData.box41NotEnteredCodeP2X) : 285,
        box41NotEnteredCodeP2Y:formData.box41NotEnteredCodeP2Y && formData.box41NotEnteredCodeP2Y !== '' ? parseFloat(formData.box41NotEnteredCodeP2Y) : 478,
        box41EnteredCodeP2X:formData.box41EnteredCodeP2X && formData.box41EnteredCodeP2X !== '' ? parseFloat(formData.box41EnteredCodeP2X) : 410,
        box41EnteredCodeP2Y:formData.box41EnteredCodeP2Y && formData.box41EnteredCodeP2Y !== '' ? parseFloat(formData.box41EnteredCodeP2Y) : 478,
        contributionRelated1P2X:formData.contributionRelated1P2X && formData.contributionRelated1P2X !== '' ? parseFloat(formData.contributionRelated1P2X) : 410,
        contributionRelated1P2Y:formData.contributionRelated1P2Y && formData.contributionRelated1P2Y !== '' ? parseFloat(formData.contributionRelated1P2Y) : 433,
        contributionRelated2P2X:formData.contributionRelated2P2X && formData.contributionRelated2P2X !== '' ? parseFloat(formData.contributionRelated2P2X) : 285,
        contributionRelated2P2Y:formData.contributionRelated2P2Y && formData.contributionRelated2P2Y !== '' ? parseFloat(formData.contributionRelated2P2Y) : 433,
        box43P2X:formData.box43P2X && formData.box43P2X !== '' ? parseFloat(formData.box43P2X) : 558,
        box43P2Y:formData.box43P2Y && formData.box43P2Y !== '' ? parseFloat(formData.box43P2Y) : 433,
        box50P2X:formData.box50P2X && formData.box50P2X !== '' ? parseFloat(formData.box50P2X) : 228,
        box50P2Y:formData.box50P2Y && formData.box50P2Y !== '' ? parseFloat(formData.box50P2Y) : 344,
        box51P2X:formData.box51P2X && formData.box51P2X !== '' ? parseFloat(formData.box51P2X) : 410,
        box51P2Y:formData.box51P2Y && formData.box51P2Y !== '' ? parseFloat(formData.box51P2Y) : 344,
        box52P2X:formData.box52P2X && formData.box52P2X !== '' ? parseFloat(formData.box52P2X) : 410,
        box52P2Y:formData.box52P2Y && formData.box52P2Y !== '' ? parseFloat(formData.box52P2Y) : 322,
        wsdrfP2X:formData.wsdrfP2X && formData.wsdrfP2X !== '' ? parseFloat(formData.wsdrfP2X) : 410,
        wsdrfP2Y:formData.wsdrfP2Y && formData.wsdrfP2Y !== '' ? parseFloat(formData.wsdrfP2Y) : 296,
        box53P2X:formData.box53P2X && formData.box53P2X !== '' ? parseFloat(formData.box53P2X) : 558,
        box53P2Y:formData.box53P2Y && formData.box53P2Y !== '' ? parseFloat(formData.box53P2Y) : 296,
        box70P2X:formData.box70P2X && formData.box70P2X !== '' ? parseFloat(formData.box70P2X) : 558,
        box70P2Y:formData.box70P2Y && formData.box70P2Y !== '' ? parseFloat(formData.box70P2Y) : 270,
        box71P2X:formData.box71P2X && formData.box71P2X !== '' ? parseFloat(formData.box71P2X) : 228,
        box71P2Y:formData.box71P2Y && formData.box71P2Y !== '' ? parseFloat(formData.box71P2Y) : 252,
        box72P2X:formData.box72P2X && formData.box72P2X !== '' ? parseFloat(formData.box72P2X) : 480,
        box72P2Y:formData.box72P2Y && formData.box72P2Y !== '' ? parseFloat(formData.box72P2Y) : 252,
        box75P2X:formData.box75P2X && formData.box75P2X !== '' ? parseFloat(formData.box75P2X) : 554,
        box75P2Y:formData.box75P2Y && formData.box75P2Y !== '' ? parseFloat(formData.box75P2Y) : 135,
        box76P2X:formData.box76P2X && formData.box76P2X !== '' ? parseFloat(formData.box76P2X) : 554,
        box76P2Y:formData.box76P2Y && formData.box76P2Y !== '' ? parseFloat(formData.box76P2Y) : 122,
        box77P2X:formData.box77P2X && formData.box77P2X !== '' ? parseFloat(formData.box77P2X) : 554,
        box77P2Y:formData.box77P2Y && formData.box77P2Y !== '' ? parseFloat(formData.box77P2Y) : 110,
        box78P2X:formData.box78P2X && formData.box78P2X !== '' ? parseFloat(formData.box78P2X) : 554,
        box78P2Y:formData.box78P2Y && formData.box78P2Y !== '' ? parseFloat(formData.box78P2Y) : 97,
        box79P2X:formData.box79P2X && formData.box79P2X !== '' ? parseFloat(formData.box79P2X) : 554,
        box79P2Y:formData.box79P2Y && formData.box79P2Y !== '' ? parseFloat(formData.box79P2Y) : 75,
        nameOfEmployerP3X:formData.nameOfEmployerP3X && formData.nameOfEmployerP3X !== '' ? parseFloat(formData.nameOfEmployerP3X) : 30,
        nameOfEmployerP3Y:formData.nameOfEmployerP3Y && formData.nameOfEmployerP3Y !== '' ? parseFloat(formData.nameOfEmployerP3Y) : 155,
        mailingAdressP3X:formData.mailingAdressP3X && formData.mailingAdressP3X !== '' ? parseFloat(formData.mailingAdressP3X) : 30,
        mailingAdressP3Y:formData.mailingAdressP3Y && formData.mailingAdressP3Y !== '' ? parseFloat(formData.mailingAdressP3Y) : 110,
        box90P3X:formData.box90P3X && formData.box90P3X !== '' ? parseFloat(formData.box90P3X) : 350,
        box90P3Y:formData.box90P3Y && formData.box90P3Y !== '' ? parseFloat(formData.box90P3Y) : 135,
        box91P3X:formData.box91P3X && formData.box91P3X !== '' ? parseFloat(formData.box91P3X) : 452,
        box91P3Y:formData.box91P3Y && formData.box91P3Y !== '' ? parseFloat(formData.box91P3Y) : 135,
        box92P3X:formData.box92P3X && formData.box92P3X !== '' ? parseFloat(formData.box92P3X) : 554,
        box92P3Y:formData.box92P3Y && formData.box92P3Y !== '' ? parseFloat(formData.box92P3Y) : 135,
        box93P3X:formData.box93P3X && formData.box93P3X !== '' ? parseFloat(formData.box93P3X) : 350,
        box93P3Y:formData.box93P3Y && formData.box93P3Y !== '' ? parseFloat(formData.box93P3Y) : 85,
        box94P3X:formData.box94P3X && formData.box94P3X !== '' ? parseFloat(formData.box94P3X) : 452,
        box94P3Y:formData.box94P3Y && formData.box94P3Y !== '' ? parseFloat(formData.box94P3Y) : 85,
        box95P3X:formData.box95P3X && formData.box95P3X !== '' ? parseFloat(formData.box95P3X) : 554,
        box95P3Y:formData.box95P3Y && formData.box95P3Y !== '' ? parseFloat(formData.box95P3Y) : 85
      }
      let finalPayloadData = {
        payload:payload,
        taxYear:selectedYear,
        tag:selectedTag
      }
      submitFlexFields(finalPayloadData)
    } else if(selectedTag === 'invoice'){
      let payload:any = {
        dateP1X: formData.dateP1X && formData.dateP1X !== '' ? parseFloat(formData.dateP1X) : 58,
        dateP1Y: formData.dateP1Y && formData.dateP1Y !== '' ? parseFloat(formData.dateP1Y) : 686,
        employerNameP1X: formData.employerNameP1X && formData.employerNameP1X !== '' ? parseFloat(formData.employerNameP1X) : 30,
        employerNameP1Y: formData.employerNameP1Y && formData.employerNameP1Y !== '' ? parseFloat(formData.employerNameP1Y) : 658,
        addressP1X: formData.addressP1X && formData.addressP1X !== '' ? parseFloat(formData.addressP1X) : 30,
        addressP1Y: formData.addressP1Y && formData.addressP1Y !== '' ? parseFloat(formData.addressP1Y) : 635,
        cityP1X: formData.cityP1X && formData.cityP1X !== '' ? parseFloat(formData.cityP1X) : 30,
        cityP1Y: formData.cityP1Y && formData.cityP1Y !== '' ? parseFloat(formData.cityP1Y) : 615,
        postCodeP1X: formData.postCodeP1X && formData.postCodeP1X !== '' ? parseFloat(formData.postCodeP1X) : 30,
        postCodeP1Y: formData.postCodeP1Y && formData.postCodeP1Y !== '' ? parseFloat(formData.postCodeP1Y) : 595,
        phoneNoP1X: formData.phoneNoP1X && formData.phoneNoP1X !== '' ? parseFloat(formData.phoneNoP1X) : 30,
        phoneNoP1Y: formData.phoneNoP1Y && formData.phoneNoP1Y !== '' ? parseFloat(formData.phoneNoP1Y) : 575,
        emailP1X: formData.emailP1X && formData.emailP1X !== '' ? parseFloat(formData.emailP1X) : 30,
        emailP1Y: formData.emailP1Y && formData.emailP1Y !== '' ? parseFloat(formData.emailP1Y) : 555,
        indexNoP1X: formData.indexNoP1X && formData.indexNoP1X !== '' ? parseFloat(formData.indexNoP1X) : 35,
        productP1X: formData.productP1X && formData.productP1X !== '' ? parseFloat(formData.productP1X) : 70,
        quentityP1X: formData.quentityP1X && formData.quentityP1X !== '' ? parseFloat(formData.quentityP1X) : 260,
        unitPriceP1X: formData.unitPriceP1X && formData.unitPriceP1X !== '' ? parseFloat(formData.unitPriceP1X) : 390,
        amountP1X: formData.amountP1X && formData.amountP1X !== '' ? parseFloat(formData.amountP1X) : 505,
        finalLineValuesP1Y: formData.finalLineValuesP1Y && formData.finalLineValuesP1Y !== '' ? parseFloat(formData.finalLineValuesP1Y) : 455,
        lineStartP1X: formData.lineStartP1X && formData.lineStartP1X !== '' ? parseFloat(formData.lineStartP1X) : 480,
        lineEndP1X: formData.lineEndP1X && formData.lineEndP1X !== '' ? parseFloat(formData.lineEndP1X) : 550,
        lineP1Y: formData.lineP1Y && formData.lineP1Y !== '' ? parseFloat(formData.lineP1Y) : 400,
        netAmountNameP1X: formData.netAmountNameP1X && formData.netAmountNameP1X !== '' ? parseFloat(formData.netAmountNameP1X) : 390,
        netAmountP1X: formData.netAmountP1X && formData.netAmountP1X !== '' ? parseFloat(formData.netAmountP1X) : 505,
        handlingChargesNameP1X: formData.handlingChargesNameP1X && formData.handlingChargesNameP1X !== '' ? parseFloat(formData.handlingChargesNameP1X) : 350,
        handlingChargesP1X: formData.handlingChargesP1X && formData.handlingChargesP1X !== '' ? parseFloat(formData.handlingChargesP1X) : 505,
        taxAmountNameP1X: formData.taxAmountNameP1X && formData.taxAmountNameP1X !== '' ? parseFloat(formData.taxAmountNameP1X) : 300,
        taxAmountValueP1X: formData.taxAmountValueP1X && formData.taxAmountValueP1X !== '' ? parseFloat(formData.taxAmountValueP1X) : 505,
        totalAmountNameP1X: formData.totalAmountNameP1X && formData.totalAmountNameP1X !== '' ? parseFloat(formData.totalAmountNameP1X) : 390,
        totalAmountValueP1X: formData.totalAmountValueP1X && formData.totalAmountValueP1X !== '' ? parseFloat(formData.totalAmountValueP1X) : 505,
        differanceLineP1Y: formData.differanceLineP1Y && formData.differanceLineP1Y !== '' ? parseFloat(formData.differanceLineP1Y) : 32
      }
      let finalPayloadData = {
        payload:payload,
        taxYear:selectedYear,
        tag:selectedTag
      }
      submitFlexFields(finalPayloadData)
    } else if(selectedTag === 't4Summary'){
      let payload:any = {
        taxYearX:formData.taxYearX && formData.taxYearX !== '' ? parseFloat(formData.taxYearX) : 200,
        employerAccountX:formData.employerAccountX && formData.employerAccountX !== '' ? parseFloat(formData.employerAccountX) : 295,
        employerNameX:formData.employerNameX && formData.employerNameX !== '' ? parseFloat(formData.employerNameX) : 280,
        employerAddressX:formData.employerAddressX && formData.employerAddressX !== '' ? parseFloat(formData.employerAddressX) : 280,
        box88X:formData.box88X && formData.box88X !== '' ? parseFloat(formData.box88X) : 75,
        box14X:formData.box14X && formData.box14X !== '' ? parseFloat(formData.box14X) : 185,
        box20X:formData.box20X && formData.box20X !== '' ? parseFloat(formData.box20X) : 185,
        box52X:formData.box52X && formData.box52X !== '' ? parseFloat(formData.box52X) : 185,
        box16X:formData.box16X && formData.box16X !== '' ? parseFloat(formData.box16X) : 388,
        box16aX:formData.box16aX && formData.box16aX !== '' ? parseFloat(formData.box16aX) : 388,
        box27X:formData.box27X && formData.box27X !== '' ? parseFloat(formData.box27X) : 388,
        box27aX:formData.box27aX && formData.box27aX !== '' ? parseFloat(formData.box27aX) : 388,
        box18X:formData.box18X && formData.box18X !== '' ? parseFloat(formData.box18X) : 388,
        box19X:formData.box19X && formData.box19X !== '' ? parseFloat(formData.box19X) : 388,
        box22X:formData.box22X && formData.box22X !== '' ? parseFloat(formData.box22X) : 388,
        box80X:formData.box80X && formData.box80X !== '' ? parseFloat(formData.box80X) : 388,
        box82X:formData.box82X && formData.box82X !== '' ? parseFloat(formData.box82X) : 388,
        box84X:formData.box84X && formData.box84X !== '' ? parseFloat(formData.box84X) : 157,
        box86X:formData.box86X && formData.box86X !== '' ? parseFloat(formData.box86X) : 316,
        box74X:formData.box74X && formData.box74X !== '' ? parseFloat(formData.box74X) : 275,
        box75X:formData.box75X && formData.box75X !== '' ? parseFloat(formData.box75X) : 435,
        box76X:formData.box76X && formData.box76X !== '' ? parseFloat(formData.box76X) : 80,
        box78X:formData.box78X && formData.box78X !== '' ? parseFloat(formData.box78X) : 410,
        box78AreaCodeX:formData.box78AreaCodeX && formData.box78AreaCodeX !== '' ? parseFloat(formData.box78AreaCodeX) : 340,
        extentionX:formData.extentionX && formData.extentionX !== '' ? parseFloat(formData.extentionX) : 520,
        taxYearY:formData.taxYearY && formData.taxYearY !== '' ? parseFloat(formData.taxYearY) : 738,
        employerAccountY:formData.employerAccountY && formData.employerAccountY !== '' ? parseFloat(formData.employerAccountY) : 695,
        employerNameY:formData.employerNameY && formData.employerNameY !== '' ? parseFloat(formData.employerNameY) : 660,
        employerAddressY:formData.employerAddressY && formData.employerAddressY !== '' ? parseFloat(formData.employerAddressY) : 640,
        box88Y:formData.box88Y && formData.box88Y !== '' ? parseFloat(formData.box88Y) : 570,
        box14Y:formData.box14Y && formData.box14Y !== '' ? parseFloat(formData.box14Y) : 535,
        box20Y:formData.box20Y && formData.box20Y !== '' ? parseFloat(formData.box20Y) : 500,
        box52Y:formData.box52Y && formData.box52Y !== '' ? parseFloat(formData.box52Y) : 465,
        box16Y:formData.box16Y && formData.box16Y !== '' ? parseFloat(formData.box16Y) : 570,
        box16aY:formData.box16aY && formData.box16aY !== '' ? parseFloat(formData.box16aY) : 535,
        box27Y:formData.box27Y && formData.box27Y !== '' ? parseFloat(formData.box27Y) : 500,
        box27aY:formData.box27aY && formData.box27aY !== '' ? parseFloat(formData.box27aY) : 470,
        box18Y:formData.box18Y && formData.box18Y !== '' ? parseFloat(formData.box18Y) : 440,
        box19Y:formData.box19Y && formData.box19Y !== '' ? parseFloat(formData.box19Y) : 410,
        box22Y:formData.box22Y && formData.box22Y !== '' ? parseFloat(formData.box22Y) : 380,
        box80Y:formData.box80Y && formData.box80Y !== '' ? parseFloat(formData.box80Y) : 348,
        box82Y:formData.box82Y && formData.box82Y !== '' ? parseFloat(formData.box82Y) : 320,
        box84Y:formData.box84Y && formData.box84Y !== '' ? parseFloat(formData.box84Y) : 198,
        box86Y:formData.box86Y && formData.box86Y !== '' ? parseFloat(formData.box86Y) : 198,
        box74Y:formData.box74Y && formData.box74Y !== '' ? parseFloat(formData.box74Y) : 162,
        box75Y:formData.box75Y && formData.box75Y !== '' ? parseFloat(formData.box75Y) : 162,
        box76Y:formData.box76Y && formData.box76Y !== '' ? parseFloat(formData.box76Y) : 127,
        box78Y:formData.box78Y && formData.box78Y !== '' ? parseFloat(formData.box78Y) : 127,
        box78AreaCodeY:formData.box78AreaCodeY && formData.box78AreaCodeY !== '' ? parseFloat(formData.box78AreaCodeY) : 127,
        extentionY:formData.extentionY && formData.extentionY !== '' ? parseFloat(formData.extentionY) : 127
      }
      let finalPayloadData = {
        payload:payload,
        taxYear:selectedYear,
        tag:selectedTag
      }
      submitFlexFields(finalPayloadData)
    } else if(selectedTag === 't4aSummary'){
      let payload:any = {
        taxYearX:formData.taxYearX && formData.taxYearX !== '' ? parseFloat(formData.taxYearX) : 200,
        employerAccountX:formData.employerAccountX && formData.employerAccountX !== '' ? parseFloat(formData.employerAccountX) : 265,
        employerNameX:formData.employerNameX && formData.employerNameX !== '' ? parseFloat(formData.employerNameX) : 265,
        employerAddressX:formData.employerAddressX && formData.employerAddressX !== '' ? parseFloat(formData.employerAddressX) : 265,
        box88X:formData.box88X && formData.box88X !== '' ? parseFloat(formData.box88X) : 455,
        box16X:formData.box16X && formData.box16X !== '' ? parseFloat(formData.box16X) : 515,
        box18X:formData.box18X && formData.box18X !== '' ? parseFloat(formData.box18X) : 515,
        box20X:formData.box20X && formData.box20X !== '' ? parseFloat(formData.box20X) : 515,
        box24X:formData.box24X && formData.box24X !== '' ? parseFloat(formData.box24X) : 515,
        box28X:formData.box28X && formData.box28X !== '' ? parseFloat(formData.box28X) : 515,
        box30X:formData.box30X && formData.box30X !== '' ? parseFloat(formData.box30X) : 515,
        box32X:formData.box32X && formData.box32X !== '' ? parseFloat(formData.box32X) : 515,
        box34X:formData.box34X && formData.box34X !== '' ? parseFloat(formData.box34X) : 515,
        box40X:formData.box40X && formData.box40X !== '' ? parseFloat(formData.box40X) : 515,
        box42X:formData.box42X && formData.box42X !== '' ? parseFloat(formData.box42X) : 515,
        box48X:formData.box48X && formData.box48X !== '' ? parseFloat(formData.box48X) : 515,
        box101X:formData.box101X && formData.box101X !== '' ? parseFloat(formData.box101X) : 515,
        box22X:formData.box22X && formData.box22X !== '' ? parseFloat(formData.box22X) : 534,
        box82X:formData.box82X && formData.box82X !== '' ? parseFloat(formData.box82X) : 534,
        box84X:formData.box84X && formData.box84X !== '' ? parseFloat(formData.box84X) : 186,
        box86X:formData.box86X && formData.box86X !== '' ? parseFloat(formData.box86X) : 352,
        box71X:formData.box71X && formData.box71X !== '' ? parseFloat(formData.box71X) : 200,
        box72X:formData.box72X && formData.box72X !== '' ? parseFloat(formData.box72X) : 322,
        box73X:formData.box73X && formData.box73X !== '' ? parseFloat(formData.box73X) : 450,
        box74X:formData.box74X && formData.box74X !== '' ? parseFloat(formData.box74X) : 260,
        box75X:formData.box75X && formData.box75X !== '' ? parseFloat(formData.box75X) : 420,
        box76X:formData.box76X && formData.box76X !== '' ? parseFloat(formData.box76X) : 80,
        box78X:formData.box78X && formData.box78X !== '' ? parseFloat(formData.box78X) : 330,
        telephoneX:formData.telephoneX && formData.telephoneX !== '' ? parseFloat(formData.telephoneX) : 390,
        extensionPosteX:formData.extensionPosteX && formData.extensionPosteX !== '' ? parseFloat(formData.extensionPosteX) : 500,
        taxYearY:formData.taxYearY && formData.taxYearY !== '' ? parseFloat(formData.taxYearY) : 728,
        employerAccountY:formData.employerAccountY && formData.employerAccountY !== '' ? parseFloat(formData.employerAccountY) : 685,
        employerNameY:formData.employerNameY && formData.employerNameY !== '' ? parseFloat(formData.employerNameY) : 650,
        employerAddressY:formData.employerAddressY && formData.employerAddressY !== '' ? parseFloat(formData.employerAddressY) : 630,
        box88Y:formData.box88Y && formData.box88Y !== '' ? parseFloat(formData.box88Y) : 537,
        box16Y:formData.box16Y && formData.box16Y !== '' ? parseFloat(formData.box16Y) : 522,
        box18Y:formData.box18Y && formData.box18Y !== '' ? parseFloat(formData.box18Y) : 509,
        box20Y:formData.box20Y && formData.box20Y !== '' ? parseFloat(formData.box20Y) : 496,
        box24Y:formData.box24Y && formData.box24Y !== '' ? parseFloat(formData.box24Y) : 483,
        box28Y:formData.box28Y && formData.box28Y !== '' ? parseFloat(formData.box28Y) : 470,
        box30Y:formData.box30Y && formData.box30Y !== '' ? parseFloat(formData.box30Y) : 457,
        box32Y:formData.box32Y && formData.box32Y !== '' ? parseFloat(formData.box32Y) : 444,
        box34Y:formData.box34Y && formData.box34Y !== '' ? parseFloat(formData.box34Y) : 431,
        box40Y:formData.box40Y && formData.box40Y !== '' ? parseFloat(formData.box40Y) : 418,
        box42Y:formData.box42Y && formData.box42Y !== '' ? parseFloat(formData.box42Y) : 405,
        box48Y:formData.box48Y && formData.box48Y !== '' ? parseFloat(formData.box48Y) : 392,
        box101Y:formData.box101Y && formData.box101Y !== '' ? parseFloat(formData.box101Y) : 379,
        box22Y:formData.box22Y && formData.box22Y !== '' ? parseFloat(formData.box22Y) : 359,
        box82Y:formData.box82Y && formData.box82Y !== '' ? parseFloat(formData.box82Y) : 340,
        box84Y:formData.box84Y && formData.box84Y !== '' ? parseFloat(formData.box84Y) : 274,
        box86Y:formData.box86Y && formData.box86Y !== '' ? parseFloat(formData.box86Y) : 274,
        box71Y:formData.box71Y && formData.box71Y !== '' ? parseFloat(formData.box71Y) : 250.5,
        box72Y:formData.box72Y && formData.box72Y !== '' ? parseFloat(formData.box72Y) : 250.5,
        box73Y:formData.box73Y && formData.box73Y !== '' ? parseFloat(formData.box73Y) : 250.5,
        box74Y:formData.box74Y && formData.box74Y !== '' ? parseFloat(formData.box74Y) : 225.5,
        box75Y:formData.box75Y && formData.box75Y !== '' ? parseFloat(formData.box75Y) : 225.5,
        box76Y:formData.box76Y && formData.box76Y !== '' ? parseFloat(formData.box76Y) : 191,
        box78Y:formData.box78Y && formData.box78Y !== '' ? parseFloat(formData.box78Y) : 191,
        telephoneY:formData.telephoneY && formData.telephoneY !== '' ? parseFloat(formData.telephoneY) : 191,
        extensionPosteY:formData.extensionPosteY && formData.extensionPosteY !== '' ? parseFloat(formData.extensionPosteY) : 191
      }
      let finalPayloadData = {
        payload:payload,
        taxYear:selectedYear,
        tag:selectedTag
      }
      submitFlexFields(finalPayloadData)
    } else if(selectedTag === 't5'){
      let payload:any = {
       taxYearP1X: formData.taxYearP1X && formData.taxYearP1X !== '' ? parseFloat(formData.taxYearP1X) : 390,
       taxYearP1Y: formData.taxYearP1Y && formData.taxYearP1Y !== '' ? parseFloat(formData.taxYearP1Y) : 973,
       box24P1X: formData.box24P1X && formData.box24P1X !== '' ? parseFloat(formData.box24P1X) : 80,
       box24P1Y: formData.box24P1Y && formData.box24P1Y !== '' ? parseFloat(formData.box24P1Y) : 940,
       box25P1X: formData.box25P1X && formData.box25P1X !== '' ? parseFloat(formData.box25P1X) : 185,
       box25P1Y: formData.box25P1Y && formData.box25P1Y !== '' ? parseFloat(formData.box25P1Y) : 940,
       box26P1X: formData.box26P1X && formData.box26P1X !== '' ? parseFloat(formData.box26P1X) : 290,
       box26P1Y: formData.box26P1Y && formData.box26P1Y !== '' ? parseFloat(formData.box26P1Y) : 940,
       box13P1X: formData.box13P1X && formData.box13P1X !== '' ? parseFloat(formData.box13P1X) : 395,
       box13P1Y: formData.box13P1Y && formData.box13P1Y !== '' ? parseFloat(formData.box13P1Y) : 940,
       box18P1X: formData.box18P1X && formData.box18P1X !== '' ? parseFloat(formData.box18P1X) : 500,
       box18P1Y: formData.box18P1Y && formData.box18P1Y !== '' ? parseFloat(formData.box18P1Y) : 940,
       box10P1X: formData.box10P1X && formData.box10P1X !== '' ? parseFloat(formData.box10P1X) : 80,
       box10P1Y: formData.box10P1Y && formData.box10P1Y !== '' ? parseFloat(formData.box10P1Y) : 903,
       box11P1X: formData.box11P1X && formData.box11P1X !== '' ? parseFloat(formData.box11P1X) : 185,
       box11P1Y: formData.box11P1Y && formData.box11P1Y !== '' ? parseFloat(formData.box11P1Y) : 903,
       box12P1X: formData.box12P1X && formData.box12P1X !== '' ? parseFloat(formData.box12P1X) : 290,
       box12P1Y: formData.box12P1Y && formData.box12P1Y !== '' ? parseFloat(formData.box12P1Y) : 903,
       box21P1X: formData.box21P1X && formData.box21P1X !== '' ? parseFloat(formData.box21P1X) : 385,
       box21P1Y: formData.box21P1Y && formData.box21P1Y !== '' ? parseFloat(formData.box21P1Y) : 903,
       box22P1X: formData.box22P1X && formData.box22P1X !== '' ? parseFloat(formData.box22P1X) : 440,
       box22P1Y: formData.box22P1Y && formData.box22P1Y !== '' ? parseFloat(formData.box22P1Y) : 903,
       box22P1BsX: formData.box22P1BsX && formData.box22P1BsX !== '' ? parseFloat(formData.box22P1BsX) : 425,
       box22P1BsY: formData.box22P1BsY && formData.box22P1BsY !== '' ? parseFloat(formData.box22P1BsY) : 903,
       box23P1X: formData.box23P1X && formData.box23P1X !== '' ? parseFloat(formData.box23P1X) : 535,
       box23P1Y: formData.box23P1Y && formData.box23P1Y !== '' ? parseFloat(formData.box23P1Y) : 903,
       box27P1X: formData.box27P1X && formData.box27P1X !== '' ? parseFloat(formData.box27P1X) : 190,
       box27P1Y: formData.box27P1Y && formData.box27P1Y !== '' ? parseFloat(formData.box27P1Y) : 751,
       box28P1X: formData.box28P1X && formData.box28P1X !== '' ? parseFloat(formData.box28P1X) : 245,
       box28P1Y: formData.box28P1Y && formData.box28P1Y !== '' ? parseFloat(formData.box28P1Y) : 751,
       box29P1X: formData.box29P1X && formData.box29P1X !== '' ? parseFloat(formData.box29P1X) : 345,
       box29P1Y: formData.box29P1Y && formData.box29P1Y !== '' ? parseFloat(formData.box29P1Y) : 751,
       case1P1X: formData.case1P1X && formData.case1P1X !== '' ? parseFloat(formData.case1P1X) : 155,
       case1P1Y: formData.case1P1Y && formData.case1P1Y !== '' ? parseFloat(formData.case1P1Y) : 867,
       amountCase1P1X: formData.amountCase1P1X && formData.amountCase1P1X !== '' ? parseFloat(formData.amountCase1P1X) : 205,
       amountCase1P1Y: formData.amountCase1P1Y && formData.amountCase1P1Y !== '' ? parseFloat(formData.amountCase1P1Y) : 867,
       case2P1X: formData.case2P1X && formData.case2P1X !== '' ? parseFloat(formData.case2P1X) : 300,
       case2P1Y: formData.case2P1Y && formData.case2P1Y !== '' ? parseFloat(formData.case2P1Y) : 867,
       amountCase2P1X: formData.amountCase2P1X && formData.amountCase2P1X !== '' ? parseFloat(formData.amountCase2P1X) : 350,
       amountCase2P1Y: formData.amountCase2P1Y && formData.amountCase2P1Y !== '' ? parseFloat(formData.amountCase2P1Y) : 867,
       case3P1X: formData.case3P1X && formData.case3P1X !== '' ? parseFloat(formData.case3P1X) : 450,
       case3P1Y: formData.case3P1Y && formData.case3P1Y !== '' ? parseFloat(formData.case3P1Y) : 867,
       amountCase3P1X: formData.amountCase3P1X && formData.amountCase3P1X !== '' ? parseFloat(formData.amountCase3P1X) : 500,
       amountCase3P1Y: formData.amountCase3P1Y && formData.amountCase3P1Y !== '' ? parseFloat(formData.amountCase3P1Y) : 867,
       recipientNameP1X: formData.recipientNameP1X && formData.recipientNameP1X !== '' ? parseFloat(formData.recipientNameP1X) : 58,
       recipientNameP1Y: formData.recipientNameP1Y && formData.recipientNameP1Y !== '' ? parseFloat(formData.recipientNameP1Y) : 820,
       recipientLine1NameP1X: formData.recipientLine1NameP1X && formData.recipientLine1NameP1X !== '' ? parseFloat(formData.recipientLine1NameP1X) : 58,
       recipientLine1NameP1Y: formData.recipientLine1NameP1Y && formData.recipientLine1NameP1Y !== '' ? parseFloat(formData.recipientLine1NameP1Y) : 805,
       payeeNameP1X: formData.payeeNameP1X && formData.payeeNameP1X !== '' ? parseFloat(formData.payeeNameP1X) : 330,
       payeeNameP1Y: formData.payeeNameP1Y && formData.payeeNameP1Y !== '' ? parseFloat(formData.payeeNameP1Y) : 820,
       payeeAddressP1X: formData.payeeAddressP1X && formData.payeeAddressP1X !== '' ? parseFloat(formData.payeeAddressP1X) : 330,
       payeeAddressP1Y: formData.payeeAddressP1Y && formData.payeeAddressP1Y !== '' ? parseFloat(formData.payeeAddressP1Y) : 805,
       payeeCityP1X: formData.payeeCityP1X && formData.payeeCityP1X !== '' ? parseFloat(formData.payeeCityP1X) : 330,
       payeeCityP1Y: formData.payeeCityP1Y && formData.payeeCityP1Y !== '' ? parseFloat(formData.payeeCityP1Y) : 790,
       recipientInitialP1X: formData.recipientInitialP1X && formData.recipientInitialP1X !== '' ? parseFloat(formData.recipientInitialP1X) : 58,
       recipientInitialP1Y: formData.recipientInitialP1Y && formData.recipientInitialP1Y !== '' ? parseFloat(formData.recipientInitialP1Y) : 820,
       recipientAddressP1X: formData.recipientAddressP1X && formData.recipientAddressP1X !== '' ? parseFloat(formData.recipientAddressP1X) : 58,
       recipientAddressP1Y: formData.recipientAddressP1Y && formData.recipientAddressP1Y !== '' ? parseFloat(formData.recipientAddressP1Y) : 790,
       recipientCityP1X: formData.recipientCityP1X && formData.recipientCityP1X !== '' ? parseFloat(formData.recipientCityP1X) : 58,
       recipientCityP1Y: formData.recipientCityP1Y && formData.recipientCityP1Y !== '' ? parseFloat(formData.recipientCityP1Y) : 775,
       pageP1X: formData.pageP1X && formData.pageP1X !== '' ? parseFloat(formData.pageP1X) : 585,
       pageP1Y: formData.pageP1Y && formData.pageP1Y !== '' ? parseFloat(formData.pageP1Y) : 722,
       taxYearP2X: formData.taxYearP2X && formData.taxYearP2X !== '' ? parseFloat(formData.taxYearP2X) : 390,
       taxYearP2Y: formData.taxYearP2Y && formData.taxYearP2Y !== '' ? parseFloat(formData.taxYearP2Y) : 690,
       box24P2X: formData.box24P2X && formData.box24P2X !== '' ? parseFloat(formData.box24P2X) : 80,
       box24P2Y: formData.box24P2Y && formData.box24P2Y !== '' ? parseFloat(formData.box24P2Y) : 657,
       box25P2X: formData.box25P2X && formData.box25P2X !== '' ? parseFloat(formData.box25P2X) : 185,
       box25P2Y: formData.box25P2Y && formData.box25P2Y !== '' ? parseFloat(formData.box25P2Y) : 657,
       box26P2X: formData.box26P2X && formData.box26P2X !== '' ? parseFloat(formData.box26P2X) : 290,
       box26P2Y: formData.box26P2Y && formData.box26P2Y !== '' ? parseFloat(formData.box26P2Y) : 657,
       box13P2X: formData.box13P2X && formData.box13P2X !== '' ? parseFloat(formData.box13P2X) : 395,
       box13P2Y: formData.box13P2Y && formData.box13P2Y !== '' ? parseFloat(formData.box13P2Y) : 657,
       box18P2X: formData.box18P2X && formData.box18P2X !== '' ? parseFloat(formData.box18P2X) : 500,
       box18P2Y: formData.box18P2Y && formData.box18P2Y !== '' ? parseFloat(formData.box18P2Y) : 657,
       box10P2X: formData.box10P2X && formData.box10P2X !== '' ? parseFloat(formData.box10P2X) : 80,
       box10P2Y: formData.box10P2Y && formData.box10P2Y !== '' ? parseFloat(formData.box10P2Y) : 620,
       box11P2X: formData.box11P2X && formData.box11P2X !== '' ? parseFloat(formData.box11P2X) : 185,
       box11P2Y: formData.box11P2Y && formData.box11P2Y !== '' ? parseFloat(formData.box11P2Y) : 620,
       box12P2X: formData.box12P2X && formData.box12P2X !== '' ? parseFloat(formData.box12P2X) : 290,
       box12P2Y: formData.box12P2Y && formData.box12P2Y !== '' ? parseFloat(formData.box12P2Y) : 620,
       box21P2X: formData.box21P2X && formData.box21P2X !== '' ? parseFloat(formData.box21P2X) : 385,
       box21P2Y: formData.box21P2Y && formData.box21P2Y !== '' ? parseFloat(formData.box21P2Y) : 620,
       box22P2X: formData.box22P2X && formData.box22P2X !== '' ? parseFloat(formData.box22P2X) : 440,
       box22P2Y: formData.box22P2Y && formData.box22P2Y !== '' ? parseFloat(formData.box22P2Y) : 620,
       box22P2BsX: formData.box22P2BsX && formData.box22P2BsX !== '' ? parseFloat(formData.box22P2BsX) : 425,
       box22P2BsY: formData.box22P2BsY && formData.box22P2BsY !== '' ? parseFloat(formData.box22P2BsY) : 620,
       box23P2X: formData.box23P2X && formData.box23P2X !== '' ? parseFloat(formData.box23P2X) : 535,
       box23P2Y: formData.box23P2Y && formData.box23P2Y !== '' ? parseFloat(formData.box23P2Y) : 620,
       box27P2X: formData.box27P2X && formData.box27P2X !== '' ? parseFloat(formData.box27P2X) : 190,
       box27P2Y: formData.box27P2Y && formData.box27P2Y !== '' ? parseFloat(formData.box27P2Y) : 468,
       box28P2X: formData.box28P2X && formData.box28P2X !== '' ? parseFloat(formData.box28P2X) : 245,
       box28P2Y: formData.box28P2Y && formData.box28P2Y !== '' ? parseFloat(formData.box28P2Y) : 468,
       box29P2X: formData.box29P2X && formData.box29P2X !== '' ? parseFloat(formData.box29P2X) : 345,
       box29P2Y: formData.box29P2Y && formData.box29P2Y !== '' ? parseFloat(formData.box29P2Y) : 468,
       case1P2X: formData.case1P2X && formData.case1P2X !== '' ? parseFloat(formData.case1P2X) : 155,
       case1P2Y: formData.case1P2Y && formData.case1P2Y !== '' ? parseFloat(formData.case1P2Y) : 584,
       amountCase1P2X: formData.amountCase1P2X && formData.amountCase1P2X !== '' ? parseFloat(formData.amountCase1P2X) : 205,
       amountCase1P2Y: formData.amountCase1P2Y && formData.amountCase1P2Y !== '' ? parseFloat(formData.amountCase1P2Y) : 584,
       case2P2X: formData.case2P2X && formData.case2P2X !== '' ? parseFloat(formData.case2P2X) : 300,
       case2P2Y: formData.case2P2Y && formData.case2P2Y !== '' ? parseFloat(formData.case2P2Y) : 584,
       amountCase2P2X: formData.amountCase2P2X && formData.amountCase2P2X !== '' ? parseFloat(formData.amountCase2P2X) : 350,
       amountCase2P2Y: formData.amountCase2P2Y && formData.amountCase2P2Y !== '' ? parseFloat(formData.amountCase2P2Y) : 584,
       case3P2X: formData.case3P2X && formData.case3P2X !== '' ? parseFloat(formData.case3P2X) : 450,
       case3P2Y: formData.case3P2Y && formData.case3P2Y !== '' ? parseFloat(formData.case3P2Y) : 584,
       amountCase3P2X: formData.amountCase3P2X && formData.amountCase3P2X !== '' ? parseFloat(formData.amountCase3P2X) : 500,
       amountCase3P2Y: formData.amountCase3P2Y && formData.amountCase3P2Y !== '' ? parseFloat(formData.amountCase3P2Y) : 584,
       recipientNameP2X: formData.recipientNameP2X && formData.recipientNameP2X !== '' ? parseFloat(formData.recipientNameP2X) : 58,
       recipientNameP2Y: formData.recipientNameP2Y && formData.recipientNameP2Y !== '' ? parseFloat(formData.recipientNameP2Y) : 535,
       recipientLine1NameP2X: formData.recipientLine1NameP2X && formData.recipientLine1NameP2X !== '' ? parseFloat(formData.recipientLine1NameP2X) : 58,
       recipientLine1NameP2Y: formData.recipientLine1NameP2Y && formData.recipientLine1NameP2Y !== '' ? parseFloat(formData.recipientLine1NameP2Y) : 520,
       recipientAddressP2X: formData.recipientAddressP2X && formData.recipientAddressP2X !== '' ? parseFloat(formData.recipientAddressP2X) : 58,
       recipientAddressP2Y: formData.recipientAddressP2Y && formData.recipientAddressP2Y !== '' ? parseFloat(formData.recipientAddressP2Y) : 505,
       recipientCityP2X: formData.recipientCityP2X && formData.recipientCityP2X !== '' ? parseFloat(formData.recipientCityP2X) : 58,
       recipientCityP2Y: formData.recipientCityP2Y && formData.recipientCityP2Y !== '' ? parseFloat(formData.recipientCityP2Y) : 490,
       payeeNameP2X: formData.payeeNameP2X && formData.payeeNameP2X !== '' ? parseFloat(formData.payeeNameP2X) : 330,
       payeeNameP2Y: formData.payeeNameP2Y && formData.payeeNameP2Y !== '' ? parseFloat(formData.payeeNameP2Y) : 535,
       payeeAddressP2X: formData.payeeAddressP2X && formData.payeeAddressP2X !== '' ? parseFloat(formData.payeeAddressP2X) : 330,
       payeeAddressP2Y: formData.payeeAddressP2Y && formData.payeeAddressP2Y !== '' ? parseFloat(formData.payeeAddressP2Y) : 520,
       payeeCityP2X: formData.payeeCityP2X && formData.payeeCityP2X !== '' ? parseFloat(formData.payeeCityP2X) : 330,
       payeeCityP2Y: formData.payeeCityP2Y && formData.payeeCityP2Y !== '' ? parseFloat(formData.payeeCityP2Y) : 505,
       pageP2X: formData.pageP2X && formData.pageP2X !== '' ? parseFloat(formData.pageP2X) : 585,
       pageP2Y: formData.pageP2Y && formData.pageP2Y !== '' ? parseFloat(formData.pageP2Y) : 440,
       taxYearP3X: formData.taxYearP3X && formData.taxYearP3X !== '' ? parseFloat(formData.taxYearP3X) : 390,
       taxYearP3Y: formData.taxYearP3Y && formData.taxYearP3Y !== '' ? parseFloat(formData.taxYearP3Y) : 402,
       box24P3X: formData.box24P3X && formData.box24P3X !== '' ? parseFloat(formData.box24P3X) : 80,
       box24P3Y: formData.box24P3Y && formData.box24P3Y !== '' ? parseFloat(formData.box24P3Y) : 369,
       box25P3X: formData.box25P3X && formData.box25P3X !== '' ? parseFloat(formData.box25P3X) : 185,
       box25P3Y: formData.box25P3Y && formData.box25P3Y !== '' ? parseFloat(formData.box25P3Y) : 369,
       box26P3X: formData.box26P3X && formData.box26P3X !== '' ? parseFloat(formData.box26P3X) : 290,
       box26P3Y: formData.box26P3Y && formData.box26P3Y !== '' ? parseFloat(formData.box26P3Y) : 369,
       box13P3X: formData.box13P3X && formData.box13P3X !== '' ? parseFloat(formData.box13P3X) : 395,
       box13P3Y: formData.box13P3Y && formData.box13P3Y !== '' ? parseFloat(formData.box13P3Y) : 369,
       box18P3X: formData.box18P3X && formData.box18P3X !== '' ? parseFloat(formData.box18P3X) : 500,
       box18P3Y: formData.box18P3Y && formData.box18P3Y !== '' ? parseFloat(formData.box18P3Y) : 369,
       box10P3X: formData.box10P3X && formData.box10P3X !== '' ? parseFloat(formData.box10P3X) : 80,
       box10P3Y: formData.box10P3Y && formData.box10P3Y !== '' ? parseFloat(formData.box10P3Y) : 332,
       box11P3X: formData.box11P3X && formData.box11P3X !== '' ? parseFloat(formData.box11P3X) : 185,
       box11P3Y: formData.box11P3Y && formData.box11P3Y !== '' ? parseFloat(formData.box11P3Y) : 332,
       box12P3X: formData.box12P3X && formData.box12P3X !== '' ? parseFloat(formData.box12P3X) : 290,
       box12P3Y: formData.box12P3Y && formData.box12P3Y !== '' ? parseFloat(formData.box12P3Y) : 332,
       box21P3X: formData.box21P3X && formData.box21P3X !== '' ? parseFloat(formData.box21P3X) : 385,
       box21P3Y: formData.box21P3Y && formData.box21P3Y !== '' ? parseFloat(formData.box21P3Y) : 332,
       box22P3X: formData.box22P3X && formData.box22P3X !== '' ? parseFloat(formData.box22P3X) : 440,
       box22P3Y: formData.box22P3Y && formData.box22P3Y !== '' ? parseFloat(formData.box22P3Y) : 332,
       box22P3BsX: formData.box22P3BsX && formData.box22P3BsX !== '' ? parseFloat(formData.box22P3BsX) : 425,
       box22P3BsY: formData.box22P3BsY && formData.box22P3BsY !== '' ? parseFloat(formData.box22P3BsY) : 333,
       box23P3X: formData.box23P3X && formData.box23P3X !== '' ? parseFloat(formData.box23P3X) : 535,
       box23P3Y: formData.box23P3Y && formData.box23P3Y !== '' ? parseFloat(formData.box23P3Y) : 332,
       box27P3X: formData.box27P3X && formData.box27P3X !== '' ? parseFloat(formData.box27P3X) : 190,
       box27P3Y: formData.box27P3Y && formData.box27P3Y !== '' ? parseFloat(formData.box27P3Y) : 180,
       box28P3X: formData.box28P3X && formData.box28P3X !== '' ? parseFloat(formData.box28P3X) : 245,
       box28P3Y: formData.box28P3Y && formData.box28P3Y !== '' ? parseFloat(formData.box28P3Y) : 180,
       box29P3X: formData.box29P3X && formData.box29P3X !== '' ? parseFloat(formData.box29P3X) : 345,
       box29P3Y: formData.box29P3Y && formData.box29P3Y !== '' ? parseFloat(formData.box29P3Y) : 180,
       case1P3X: formData.case1P3X && formData.case1P3X !== '' ? parseFloat(formData.case1P3X) : 155,
       case1P3Y: formData.case1P3Y && formData.case1P3Y !== '' ? parseFloat(formData.case1P3Y) : 296,
       amountCase1P3X: formData.amountCase1P3X && formData.amountCase1P3X !== '' ? parseFloat(formData.amountCase1P3X) : 205,
       amountCase1P3Y: formData.amountCase1P3Y && formData.amountCase1P3Y !== '' ? parseFloat(formData.amountCase1P3Y) : 296,
       case2P3X: formData.case2P3X && formData.case2P3X !== '' ? parseFloat(formData.case2P3X) : 300,
       case2P3Y: formData.case2P3Y && formData.case2P3Y !== '' ? parseFloat(formData.case2P3Y) : 296,
       amountCase2P3X: formData.amountCase2P3X && formData.amountCase2P3X !== '' ? parseFloat(formData.amountCase2P3X) : 350,
       amountCase2P3Y: formData.amountCase2P3Y && formData.amountCase2P3Y !== '' ? parseFloat(formData.amountCase2P3Y) : 296,
       case3P3X: formData.case3P3X && formData.case3P3X !== '' ? parseFloat(formData.case3P3X) : 450,
       case3P3Y: formData.case3P3Y && formData.case3P3Y !== '' ? parseFloat(formData.case3P3Y) : 296,
       amountCase3P3X: formData.amountCase3P3X && formData.amountCase3P3X !== '' ? parseFloat(formData.amountCase3P3X) : 500,
       amountCase3P3Y: formData.amountCase3P3Y && formData.amountCase3P3Y !== '' ? parseFloat(formData.amountCase3P3Y) : 296,
       recipientNameP3X: formData.recipientNameP3X && formData.recipientNameP3X !== '' ? parseFloat(formData.recipientNameP3X) : 58,
       recipientNameP3Y: formData.recipientNameP3Y && formData.recipientNameP3Y !== '' ? parseFloat(formData.recipientNameP3Y) : 245,
       recipientLine1NameP3X: formData.recipientLine1NameP3X && formData.recipientLine1NameP3X !== '' ? parseFloat(formData.recipientLine1NameP3X) : 58,
       recipientLine1NameP3Y: formData.recipientLine1NameP3Y && formData.recipientLine1NameP3Y !== '' ? parseFloat(formData.recipientLine1NameP3Y) : 230,
       recipientAddressP3X: formData.recipientAddressP3X && formData.recipientAddressP3X !== '' ? parseFloat(formData.recipientAddressP3X) : 58,
       recipientAddressP3Y: formData.recipientAddressP3Y && formData.recipientAddressP3Y !== '' ? parseFloat(formData.recipientAddressP3Y) : 215,
       recipientCityP3X: formData.recipientCityP3X && formData.recipientCityP3X !== '' ? parseFloat(formData.recipientCityP3X) : 58,
       recipientCityP3Y: formData.recipientCityP3Y && formData.recipientCityP3Y !== '' ? parseFloat(formData.recipientCityP3Y) : 200,
       pageP3X: formData.pageP3X && formData.pageP3X !== '' ? parseFloat(formData.pageP3X) : 585,
       pageP3Y: formData.pageP3Y && formData.pageP3Y !== '' ? parseFloat(formData.pageP3Y) : 154,
       payeeNameP3X: formData.payeeNameP3X && formData.payeeNameP3X !== '' ? parseFloat(formData.payeeNameP3X) : 330,
       payeeNameP3Y: formData.payeeNameP3Y && formData.payeeNameP3Y !== '' ? parseFloat(formData.payeeNameP3Y) : 245,
       payeeAddressP3X: formData.payeeAddressP3X && formData.payeeAddressP3X !== '' ? parseFloat(formData.payeeAddressP3X) : 330,
       payeeAddressP3Y: formData.payeeAddressP3Y && formData.payeeAddressP3Y !== '' ? parseFloat(formData.payeeAddressP3Y) : 230,
       payeeCityP3X: formData.payeeCityP3X && formData.payeeCityP3X !== '' ? parseFloat(formData.payeeCityP3X) : 330,
       payeeCityP3Y: formData.payeeCityP3Y && formData.payeeCityP3Y !== '' ? parseFloat(formData.payeeCityP3Y) : 215
     }
     let finalPayloadData = {
       payload:payload,
       taxYear:selectedYear,
       tag:selectedTag
     }
     submitFlexFields(finalPayloadData)
     } else if(selectedTag === 't3'){
     let payload:any = {
      taxYearP1X: formData.taxYearP1X && formData.taxYearP1X !== '' ? parseFloat(formData.taxYearP1X) : 250,
      taxYearP1Y: formData.taxYearP1Y && formData.taxYearP1Y !== '' ? parseFloat(formData.taxYearP1Y) : 740,
      statementCodeP1X: formData.statementCodeP1X && formData.statementCodeP1X !== '' ? parseFloat(formData.statementCodeP1X) : 300,
      statementCodeP1Y: formData.statementCodeP1Y && formData.statementCodeP1Y !== '' ? parseFloat(formData.statementCodeP1Y) : 740,
      currencyCodeP1X: formData.currencyCodeP1X && formData.currencyCodeP1X !== '' ? parseFloat(formData.currencyCodeP1X) : 350,
      currencyCodeP1Y: formData.currencyCodeP1Y && formData.currencyCodeP1Y !== '' ? parseFloat(formData.currencyCodeP1Y) : 740,
      numberOfLastStatementSentP1X: formData.numberOfLastStatementSentP1X && formData.numberOfLastStatementSentP1X !== '' ? parseFloat(formData.numberOfLastStatementSentP1X) : 390,
      numberOfLastStatementSentP1Y: formData.numberOfLastStatementSentP1Y && formData.numberOfLastStatementSentP1Y !== '' ? parseFloat(formData.numberOfLastStatementSentP1Y) : 740,
      boxA1P1X: formData.boxA1P1X && formData.boxA1P1X !== '' ? parseFloat(formData.boxA1P1X) : 105,
      boxA1P1Y: formData.boxA1P1Y && formData.boxA1P1Y !== '' ? parseFloat(formData.boxA1P1Y) : 705,
      boxA2P1X: formData.boxA2P1X && formData.boxA2P1X !== '' ? parseFloat(formData.boxA2P1X) : 220,
      boxA2P1Y: formData.boxA2P1Y && formData.boxA2P1Y !== '' ? parseFloat(formData.boxA2P1Y) : 705,
      boxBP1X: formData.boxBP1X && formData.boxBP1X !== '' ? parseFloat(formData.boxBP1X) : 332,
      boxBP1Y: formData.boxBP1Y && formData.boxBP1Y !== '' ? parseFloat(formData.boxBP1Y) : 705,
      boxCP1X: formData.boxCP1X && formData.boxCP1X !== '' ? parseFloat(formData.boxCP1X) : 448,
      boxCP1Y: formData.boxCP1Y && formData.boxCP1Y !== '' ? parseFloat(formData.boxCP1Y) : 705,
      boxDP1X: formData.boxDP1X && formData.boxDP1X !== '' ? parseFloat(formData.boxDP1X) : 560,
      boxDP1Y: formData.boxDP1Y && formData.boxDP1Y !== '' ? parseFloat(formData.boxDP1Y) : 705,
      boxEP1X: formData.boxEP1X && formData.boxEP1X !== '' ? parseFloat(formData.boxEP1X) : 105,
      boxEP1Y: formData.boxEP1Y && formData.boxEP1Y !== '' ? parseFloat(formData.boxEP1Y) : 680,
      boxFP1X: formData.boxFP1X && formData.boxFP1X !== '' ? parseFloat(formData.boxFP1X) : 218,
      boxFP1Y: formData.boxFP1Y && formData.boxFP1Y !== '' ? parseFloat(formData.boxFP1Y) : 680,
      boxGP1X: formData.boxGP1X && formData.boxGP1X !== '' ? parseFloat(formData.boxGP1X) : 332,
      boxGP1Y: formData.boxGP1Y && formData.boxGP1Y !== '' ? parseFloat(formData.boxGP1Y) : 680,
      boxHP1X: formData.boxHP1X && formData.boxHP1X !== '' ? parseFloat(formData.boxHP1X) : 448,
      boxHP1Y: formData.boxHP1Y && formData.boxHP1Y !== '' ? parseFloat(formData.boxHP1Y) : 680,
      boxIP1X: formData.boxIP1X && formData.boxIP1X !== '' ? parseFloat(formData.boxIP1X) : 560,
      boxIP1Y: formData.boxIP1Y && formData.boxIP1Y !== '' ? parseFloat(formData.boxIP1Y) : 680,
      boxJP1X: formData.boxJP1X && formData.boxJP1X !== '' ? parseFloat(formData.boxJP1X) : 105,
      boxJP1Y: formData.boxJP1Y && formData.boxJP1Y !== '' ? parseFloat(formData.boxJP1Y) : 655,
      boxKP1X: formData.boxKP1X && formData.boxKP1X !== '' ? parseFloat(formData.boxKP1X) : 220,
      boxKP1Y: formData.boxKP1Y && formData.boxKP1Y !== '' ? parseFloat(formData.boxKP1Y) : 655,
      otherInfoCode1P1X: formData.otherInfoCode1P1X && formData.otherInfoCode1P1X !== '' ? parseFloat(formData.otherInfoCode1P1X) : 35,
      otherInfoCode1P1Y: formData.otherInfoCode1P1Y && formData.otherInfoCode1P1Y !== '' ? parseFloat(formData.otherInfoCode1P1Y) : 631,
      otherInfoAmount1P1X: formData.otherInfoAmount1P1X && formData.otherInfoAmount1P1X !== '' ? parseFloat(formData.otherInfoAmount1P1X) : 100,
      otherInfoAmount1P1Y: formData.otherInfoAmount1P1Y && formData.otherInfoAmount1P1Y !== '' ? parseFloat(formData.otherInfoAmount1P1Y) : 631,
      otherInfoCode2P1X: formData.otherInfoCode2P1X && formData.otherInfoCode2P1X !== '' ? parseFloat(formData.otherInfoCode2P1X) : 175,
      otherInfoCode2P1Y: formData.otherInfoCode2P1Y && formData.otherInfoCode2P1Y !== '' ? parseFloat(formData.otherInfoCode2P1Y) : 631,
      otherInfoAmount2P1X: formData.otherInfoAmount2P1X && formData.otherInfoAmount2P1X !== '' ? parseFloat(formData.otherInfoAmount2P1X) : 260,
      otherInfoAmount2P1Y: formData.otherInfoAmount2P1Y && formData.otherInfoAmount2P1Y !== '' ? parseFloat(formData.otherInfoAmount2P1Y) : 631,
      otherInfoCode3P1X: formData.otherInfoCode3P1X && formData.otherInfoCode3P1X !== '' ? parseFloat(formData.otherInfoCode3P1X) : 320,
      otherInfoCode3P1Y: formData.otherInfoCode3P1Y && formData.otherInfoCode3P1Y !== '' ? parseFloat(formData.otherInfoCode3P1Y) : 631,
      otherInfoAmount3P1X: formData.otherInfoAmount3P1X && formData.otherInfoAmount3P1X !== '' ? parseFloat(formData.otherInfoAmount3P1X) : 390,
      otherInfoAmount3P1Y: formData.otherInfoAmount3P1Y && formData.otherInfoAmount3P1Y !== '' ? parseFloat(formData.otherInfoAmount3P1Y) : 631,
      otherInfoCode4P1X: formData.otherInfoCode4P1X && formData.otherInfoCode4P1X !== '' ? parseFloat(formData.otherInfoCode4P1X) : 453,
      otherInfoCode4P1Y: formData.otherInfoCode4P1Y && formData.otherInfoCode4P1Y !== '' ? parseFloat(formData.otherInfoCode4P1Y) : 631,
      otherInfoAmount4P1X: formData.otherInfoAmount4P1X && formData.otherInfoAmount4P1X !== '' ? parseFloat(formData.otherInfoAmount4P1X) : 520,
      otherInfoAmount4P1Y: formData.otherInfoAmount4P1Y && formData.otherInfoAmount4P1Y !== '' ? parseFloat(formData.otherInfoAmount4P1Y) : 631,
      resouceFirstNameP1X: formData.resouceFirstNameP1X && formData.resouceFirstNameP1X !== '' ? parseFloat(formData.resouceFirstNameP1X) : 40,
      resouceFirstNameP1Y: formData.resouceFirstNameP1Y && formData.resouceFirstNameP1Y !== '' ? parseFloat(formData.resouceFirstNameP1Y) : 552,
      resouceLastNameP1X: formData.resouceLastNameP1X && formData.resouceLastNameP1X !== '' ? parseFloat(formData.resouceLastNameP1X) : 40,
      resouceLastNameP1Y: formData.resouceLastNameP1Y && formData.resouceLastNameP1Y !== '' ? parseFloat(formData.resouceLastNameP1Y) : 527,
      resouceAddressLine1P1X: formData.resouceAddressLine1P1X && formData.resouceAddressLine1P1X !== '' ? parseFloat(formData.resouceAddressLine1P1X) : 40,
      resouceAddressLine1P1Y: formData.resouceAddressLine1P1Y && formData.resouceAddressLine1P1Y !== '' ? parseFloat(formData.resouceAddressLine1P1Y) : 503,
      resouceAddressLine2P1X: formData.resouceAddressLine2P1X && formData.resouceAddressLine2P1X !== '' ? parseFloat(formData.resouceAddressLine2P1X) : 40,
      resouceAddressLine2P1Y: formData.resouceAddressLine2P1Y && formData.resouceAddressLine2P1Y !== '' ? parseFloat(formData.resouceAddressLine2P1Y) : 480,
      resouceCityP1X: formData.resouceCityP1X && formData.resouceCityP1X !== '' ? parseFloat(formData.resouceCityP1X) : 40,
      resouceCityP1Y: formData.resouceCityP1Y && formData.resouceCityP1Y !== '' ? parseFloat(formData.resouceCityP1Y) : 457,
      resourceProvinceCodeP1X: formData.resourceProvinceCodeP1X && formData.resourceProvinceCodeP1X !== '' ? parseFloat(formData.resourceProvinceCodeP1X) : 40,
      resourceProvinceCodeP1Y: formData.resourceProvinceCodeP1Y && formData.resourceProvinceCodeP1Y !== '' ? parseFloat(formData.resourceProvinceCodeP1Y) : 430.05,
      resourcePostalCodeFirstThreeP1X: formData.resourcePostalCodeFirstThreeP1X && formData.resourcePostalCodeFirstThreeP1X !== '' ? parseFloat(formData.resourcePostalCodeFirstThreeP1X) : 80,
      resourcePostalCodeFirstThreeP1Y: formData.resourcePostalCodeFirstThreeP1Y && formData.resourcePostalCodeFirstThreeP1Y !== '' ? parseFloat(formData.resourcePostalCodeFirstThreeP1Y) : 430.5,
      resourcePostalCodeLastThreeP1X: formData.resourcePostalCodeLastThreeP1X && formData.resourcePostalCodeLastThreeP1X !== '' ? parseFloat(formData.resourcePostalCodeLastThreeP1X) : 116,
      resourcePostalCodeLastThreeP1Y: formData.resourcePostalCodeLastThreeP1Y && formData.resourcePostalCodeLastThreeP1Y !== '' ? parseFloat(formData.resourcePostalCodeLastThreeP1Y) : 430.5,
      clientNameLine1P1X: formData.clientNameLine1P1X && formData.clientNameLine1P1X !== '' ? parseFloat(formData.clientNameLine1P1X) : 335,
      clientNameLine1P1Y: formData.clientNameLine1P1Y && formData.clientNameLine1P1Y !== '' ? parseFloat(formData.clientNameLine1P1Y) : 527,
      clientNameLine2P1X: formData.clientNameLine2P1X && formData.clientNameLine2P1X !== '' ? parseFloat(formData.clientNameLine2P1X) : 335,
      clientNameLine2P1Y: formData.clientNameLine2P1Y && formData.clientNameLine2P1Y !== '' ? parseFloat(formData.clientNameLine2P1Y) : 503,
      clientAddressLine1P1X: formData.clientAddressLine1P1X && formData.clientAddressLine1P1X !== '' ? parseFloat(formData.clientAddressLine1P1X) : 335,
      clientAddressLine1P1Y: formData.clientAddressLine1P1Y && formData.clientAddressLine1P1Y !== '' ? parseFloat(formData.clientAddressLine1P1Y) : 480,
      clientAddressLine2P1X: formData.clientAddressLine2P1X && formData.clientAddressLine2P1X !== '' ? parseFloat(formData.clientAddressLine2P1X) : 335,
      clientAddressLine2P1Y: formData.clientAddressLine2P1Y && formData.clientAddressLine2P1Y !== '' ? parseFloat(formData.clientAddressLine2P1Y) : 457,
      clientCityP1X: formData.clientCityP1X && formData.clientCityP1X !== '' ? parseFloat(formData.clientCityP1X) : 335,
      clientCityP1Y: formData.clientCityP1Y && formData.clientCityP1Y !== '' ? parseFloat(formData.clientCityP1Y) : 430,
      clientProvinceCodeP1X: formData.clientProvinceCodeP1X && formData.clientProvinceCodeP1X !== '' ? parseFloat(formData.clientProvinceCodeP1X) : 335,
      clientProvinceCodeP1Y: formData.clientProvinceCodeP1Y && formData.clientProvinceCodeP1Y !== '' ? parseFloat(formData.clientProvinceCodeP1Y) : 406.5,
      clientPostalCodeFirstThreeP1X: formData.clientPostalCodeFirstThreeP1X && formData.clientPostalCodeFirstThreeP1X !== '' ? parseFloat(formData.clientPostalCodeFirstThreeP1X) : 375,
      clientPostalCodeFirstThreeP1Y: formData.clientPostalCodeFirstThreeP1Y && formData.clientPostalCodeFirstThreeP1Y !== '' ? parseFloat(formData.clientPostalCodeFirstThreeP1Y) : 406.5,
      clientPostalCodeLastThreeP1X: formData.clientPostalCodeLastThreeP1X && formData.clientPostalCodeLastThreeP1X !== '' ? parseFloat(formData.clientPostalCodeLastThreeP1X) : 415,
      clientPostalCodeLastThreeP1Y: formData.clientPostalCodeLastThreeP1Y && formData.clientPostalCodeLastThreeP1Y !== '' ? parseFloat(formData.clientPostalCodeLastThreeP1Y) : 406.5,
      clientSocialInsurance1To3P2X: formData.clientSocialInsurance1To3P2X && formData.clientSocialInsurance1To3P2X !== '' ? parseFloat(formData.clientSocialInsurance1To3P2X) : 360,
      clientSocialInsurance1To3P2Y: formData.clientSocialInsurance1To3P2Y && formData.clientSocialInsurance1To3P2Y !== '' ? parseFloat(formData.clientSocialInsurance1To3P2Y) : 170,
      clientSocialInsurance4To6P2X: formData.clientSocialInsurance4To6P2X && formData.clientSocialInsurance4To6P2X !== '' ? parseFloat(formData.clientSocialInsurance4To6P2X) : 396,
      clientSocialInsurance4To6P2Y: formData.clientSocialInsurance4To6P2Y && formData.clientSocialInsurance4To6P2Y !== '' ? parseFloat(formData.clientSocialInsurance4To6P2Y) : 170,
      clientSocialInsurance7To9P2X: formData.clientSocialInsurance7To9P2X && formData.clientSocialInsurance7To9P2X !== '' ? parseFloat(formData.clientSocialInsurance7To9P2X) : 436,
      clientSocialInsurance7To9P2Y: formData.clientSocialInsurance7To9P2Y && formData.clientSocialInsurance7To9P2Y !== '' ? parseFloat(formData.clientSocialInsurance7To9P2Y) : 170,
      lastNameOrCompanyNameOfTheSecondHolderX: formData.lastNameOrCompanyNameOfTheSecondHolderX && formData.lastNameOrCompanyNameOfTheSecondHolderX !== '' ? parseFloat(formData.lastNameOrCompanyNameOfTheSecondHolderX) : 40,
      lastNameOrCompanyNameOfTheSecondHolderY: formData.lastNameOrCompanyNameOfTheSecondHolderY && formData.lastNameOrCompanyNameOfTheSecondHolderY !== '' ? parseFloat(formData.lastNameOrCompanyNameOfTheSecondHolderY) : 406,
      firstNameOrCompanyNameOfTheSecondHolderX: formData.firstNameOrCompanyNameOfTheSecondHolderX && formData.firstNameOrCompanyNameOfTheSecondHolderX !== '' ? parseFloat(formData.firstNameOrCompanyNameOfTheSecondHolderX) : 40,
      firstNameOrCompanyNameOfTheSecondHolderY: formData.firstNameOrCompanyNameOfTheSecondHolderY && formData.firstNameOrCompanyNameOfTheSecondHolderY !== '' ? parseFloat(formData.firstNameOrCompanyNameOfTheSecondHolderY) : 385,
      codeDeLaDeviseSlipTypeP1X: formData.codeDeLaDeviseSlipTypeP1X && formData.codeDeLaDeviseSlipTypeP1X !== '' ? parseFloat(formData.codeDeLaDeviseSlipTypeP1X) : 310,
      codeDeLaDeviseSlipTypeP1Y: formData.codeDeLaDeviseSlipTypeP1Y && formData.codeDeLaDeviseSlipTypeP1Y !== '' ? parseFloat(formData.codeDeLaDeviseSlipTypeP1Y) : 740,
      fsP1X: formData.fsP1X && formData.fsP1X !== '' ? parseFloat(formData.fsP1X) : 524,
      fsP1Y: formData.fsP1Y && formData.fsP1Y !== '' ? parseFloat(formData.fsP1Y) : 63,
      rl3202210AboveBoxP1X: formData.rl3202210AboveBoxP1X && formData.rl3202210AboveBoxP1X !== '' ? parseFloat(formData.rl3202210AboveBoxP1X) : 510,
      rl3202210AboveBoxP1Y: formData.rl3202210AboveBoxP1Y && formData.rl3202210AboveBoxP1Y !== '' ? parseFloat(formData.rl3202210AboveBoxP1Y) : 749.5,
      rl3202210DownBoxP1X: formData.rl3202210DownBoxP1X && formData.rl3202210DownBoxP1X !== '' ? parseFloat(formData.rl3202210DownBoxP1X) : 510,
      rl3202210DownBoxP1Y: formData.rl3202210DownBoxP1Y && formData.rl3202210DownBoxP1Y !== '' ? parseFloat(formData.rl3202210DownBoxP1Y) : 737,
      taxYearP2X: formData.taxYearP2X && formData.taxYearP2X !== '' ? parseFloat(formData.taxYearP2X) : 250,
      taxYearP2Y: formData.taxYearP2Y && formData.taxYearP2Y !== '' ? parseFloat(formData.taxYearP2Y) : 740,
      statementCodeP2X: formData.statementCodeP2X && formData.statementCodeP2X !== '' ? parseFloat(formData.statementCodeP2X) : 300,
      statementCodeP2Y: formData.statementCodeP2Y && formData.statementCodeP2Y !== '' ? parseFloat(formData.statementCodeP2Y) : 740,
      currencyCodeP2X: formData.currencyCodeP2X && formData.currencyCodeP2X !== '' ? parseFloat(formData.currencyCodeP2X) : 380,
      currencyCodeP2Y: formData.currencyCodeP2Y && formData.currencyCodeP2Y !== '' ? parseFloat(formData.currencyCodeP2Y) : 740,
      fsP2X: formData.fsP2X && formData.fsP2X !== '' ? parseFloat(formData.fsP2X) : 524,
      fsP2Y: formData.fsP2Y && formData.fsP2Y !== '' ? parseFloat(formData.fsP2Y) : 63,
      numberOfLastStatementSentP2X: formData.numberOfLastStatementSentP2X && formData.numberOfLastStatementSentP2X !== '' ? parseFloat(formData.numberOfLastStatementSentP2X) : 390,
      numberOfLastStatementSentP2Y: formData.numberOfLastStatementSentP2Y && formData.numberOfLastStatementSentP2Y !== '' ? parseFloat(formData.numberOfLastStatementSentP2Y) : 740,
      rl3202210AboveBoxP2X: formData.rl3202210AboveBoxP2X && formData.rl3202210AboveBoxP2X !== '' ? parseFloat(formData.rl3202210AboveBoxP2X) : 510,
      rl3202210AboveBoxP2Y: formData.rl3202210AboveBoxP2Y && formData.rl3202210AboveBoxP2Y !== '' ? parseFloat(formData.rl3202210AboveBoxP2Y) : 749.5,
      rl3202210DownBoxP2X: formData.rl3202210DownBoxP2X && formData.rl3202210DownBoxP2X !== '' ? parseFloat(formData.rl3202210DownBoxP2X) : 510,
      rl3202210DownBoxP2Y: formData.rl3202210DownBoxP2Y && formData.rl3202210DownBoxP2Y !== '' ? parseFloat(formData.rl3202210DownBoxP2Y) : 737,
      boxA1P2X: formData.boxA1P2X && formData.boxA1P2X !== '' ? parseFloat(formData.boxA1P2X) : 105,
      boxA1P2Y: formData.boxA1P2Y && formData.boxA1P2Y !== '' ? parseFloat(formData.boxA1P2Y) : 705,
      boxA2P2X: formData.boxA2P2X && formData.boxA2P2X !== '' ? parseFloat(formData.boxA2P2X) : 220,
      boxA2P2Y: formData.boxA2P2Y && formData.boxA2P2Y !== '' ? parseFloat(formData.boxA2P2Y) : 705,
      boxBP2X: formData.boxBP2X && formData.boxBP2X !== '' ? parseFloat(formData.boxBP2X) : 332,
      boxBP2Y: formData.boxBP2Y && formData.boxBP2Y !== '' ? parseFloat(formData.boxBP2Y) : 705,
      boxCP2X: formData.boxCP2X && formData.boxCP2X !== '' ? parseFloat(formData.boxCP2X) : 448,
      boxCP2Y: formData.boxCP2Y && formData.boxCP2Y !== '' ? parseFloat(formData.boxCP2Y) : 705,
      boxDP2X: formData.boxDP2X && formData.boxDP2X !== '' ? parseFloat(formData.boxDP2X) : 560,
      boxDP2Y: formData.boxDP2Y && formData.boxDP2Y !== '' ? parseFloat(formData.boxDP2Y) : 705,
      boxEP2X: formData.boxEP2X && formData.boxEP2X !== '' ? parseFloat(formData.boxEP2X) : 105,
      boxEP2Y: formData.boxEP2Y && formData.boxEP2Y !== '' ? parseFloat(formData.boxEP2Y) : 680,
      boxFP2X: formData.boxFP2X && formData.boxFP2X !== '' ? parseFloat(formData.boxFP2X) : 220,
      boxFP2Y: formData.boxFP2Y && formData.boxFP2Y !== '' ? parseFloat(formData.boxFP2Y) : 680,
      boxGP2X: formData.boxGP2X && formData.boxGP2X !== '' ? parseFloat(formData.boxGP2X) : 332,
      boxGP2Y: formData.boxGP2Y && formData.boxGP2Y !== '' ? parseFloat(formData.boxGP2Y) : 680,
      boxHP2X: formData.boxHP2X && formData.boxHP2X !== '' ? parseFloat(formData.boxHP2X) : 448,
      boxHP2Y: formData.boxHP2Y && formData.boxHP2Y !== '' ? parseFloat(formData.boxHP2Y) : 680,
      boxIP2X: formData.boxIP2X && formData.boxIP2X !== '' ? parseFloat(formData.boxIP2X) : 560,
      boxIP2Y: formData.boxIP2Y && formData.boxIP2Y !== '' ? parseFloat(formData.boxIP2Y) : 680,
      boxJP2X: formData.boxJP2X && formData.boxJP2X !== '' ? parseFloat(formData.boxJP2X) : 105,
      boxJP2Y: formData.boxJP2Y && formData.boxJP2Y !== '' ? parseFloat(formData.boxJP2Y) : 655,
      boxKP2X: formData.boxKP2X && formData.boxKP2X !== '' ? parseFloat(formData.boxKP2X) : 220,
      boxKP2Y: formData.boxKP2Y && formData.boxKP2Y !== '' ? parseFloat(formData.boxKP2Y) : 655,
      otherInfoCode1P2X: formData.otherInfoCode1P2X && formData.otherInfoCode1P2X !== '' ? parseFloat(formData.otherInfoCode1P2X) : 35,
      otherInfoCode1P2Y: formData.otherInfoCode1P2Y && formData.otherInfoCode1P2Y !== '' ? parseFloat(formData.otherInfoCode1P2Y) : 631,
      otherInfoAmount1P2X: formData.otherInfoAmount1P2X && formData.otherInfoAmount1P2X !== '' ? parseFloat(formData.otherInfoAmount1P2X) : 100,
      otherInfoAmount1P2Y: formData.otherInfoAmount1P2Y && formData.otherInfoAmount1P2Y !== '' ? parseFloat(formData.otherInfoAmount1P2Y) : 631,
      otherInfoCode2P2X: formData.otherInfoCode2P2X && formData.otherInfoCode2P2X !== '' ? parseFloat(formData.otherInfoCode2P2X) : 175,
      otherInfoCode2P2Y: formData.otherInfoCode2P2Y && formData.otherInfoCode2P2Y !== '' ? parseFloat(formData.otherInfoCode2P2Y) : 631,
      otherInfoAmount2P2X: formData.otherInfoAmount2P2X && formData.otherInfoAmount2P2X !== '' ? parseFloat(formData.otherInfoAmount2P2X) : 260,
      otherInfoAmount2P2Y: formData.otherInfoAmount2P2Y && formData.otherInfoAmount2P2Y !== '' ? parseFloat(formData.otherInfoAmount2P2Y) : 631,
      otherInfoCode3P2X: formData.otherInfoCode3P2X && formData.otherInfoCode3P2X !== '' ? parseFloat(formData.otherInfoCode3P2X) : 320,
      otherInfoCode3P2Y: formData.otherInfoCode3P2Y && formData.otherInfoCode3P2Y !== '' ? parseFloat(formData.otherInfoCode3P2Y) : 631,
      otherInfoAmount3P2X: formData.otherInfoAmount3P2X && formData.otherInfoAmount3P2X !== '' ? parseFloat(formData.otherInfoAmount3P2X) : 390,
      otherInfoAmount3P2Y: formData.otherInfoAmount3P2Y && formData.otherInfoAmount3P2Y !== '' ? parseFloat(formData.otherInfoAmount3P2Y) : 631,
      otherInfoCode4P2X: formData.otherInfoCode4P2X && formData.otherInfoCode4P2X !== '' ? parseFloat(formData.otherInfoCode4P2X) : 453,
      otherInfoCode4P2Y: formData.otherInfoCode4P2Y && formData.otherInfoCode4P2Y !== '' ? parseFloat(formData.otherInfoCode4P2Y) : 631,
      otherInfoAmount4P2X: formData.otherInfoAmount4P2X && formData.otherInfoAmount4P2X !== '' ? parseFloat(formData.otherInfoAmount4P2X) : 520,
      otherInfoAmount4P2Y: formData.otherInfoAmount4P2Y && formData.otherInfoAmount4P2Y !== '' ? parseFloat(formData.otherInfoAmount4P2Y) : 631,
      branchNumberX: formData.branchNumberX && formData.branchNumberX !== '' ? parseFloat(formData.branchNumberX) : 440,
      branchNumberY: formData.branchNumberY && formData.branchNumberY !== '' ? parseFloat(formData.branchNumberY) : 510,
      typeX: formData.typeX && formData.typeX !== '' ? parseFloat(formData.typeX) : 562,
      typeY: formData.typeY && formData.typeY !== '' ? parseFloat(formData.typeY) : 738,
      codeDeLaDeviseSlipTypeP2X: formData.codeDeLaDeviseSlipTypeP2X && formData.codeDeLaDeviseSlipTypeP2X !== '' ? parseFloat(formData.codeDeLaDeviseSlipTypeP2X) : 310,
      codeDeLaDeviseSlipTypeP2Y: formData.codeDeLaDeviseSlipTypeP2Y && formData.codeDeLaDeviseSlipTypeP2Y !== '' ? parseFloat(formData.codeDeLaDeviseSlipTypeP2Y) : 740,
      clientAddressP2X: formData.clientAddressP2X && formData.clientAddressP2X !== '' ? parseFloat(formData.clientAddressP2X) : 370,
      clientAddressP2Y: formData.clientAddressP2Y && formData.clientAddressP2Y !== '' ? parseFloat(formData.clientAddressP2Y) : 115,
      clientCityP2X: formData.clientCityP2X && formData.clientCityP2X !== '' ? parseFloat(formData.clientCityP2X) : 370,
      clientCityP2Y: formData.clientCityP2Y && formData.clientCityP2Y !== '' ? parseFloat(formData.clientCityP2Y) : 100,
      clientNameP2X: formData.clientNameP2X && formData.clientNameP2X !== '' ? parseFloat(formData.clientNameP2X) : 370,
      clientNameP2Y: formData.clientNameP2Y && formData.clientNameP2Y !== '' ? parseFloat(formData.clientNameP2Y) : 130,
      clientReferenceNo1To3P2X: formData.clientReferenceNo1To3P2X && formData.clientReferenceNo1To3P2X !== '' ? parseFloat(formData.clientReferenceNo1To3P2X) : 360,
      clientReferenceNo1To3P2Y: formData.clientReferenceNo1To3P2Y && formData.clientReferenceNo1To3P2Y !== '' ? parseFloat(formData.clientReferenceNo1To3P2Y) : 170,
      clientSocialInsurance1To3P1X: formData.clientSocialInsurance1To3P1X && formData.clientSocialInsurance1To3P1X !== '' ? parseFloat(formData.clientSocialInsurance1To3P1X) : 335,
      clientSocialInsurance1To3P1Y: formData.clientSocialInsurance1To3P1Y && formData.clientSocialInsurance1To3P1Y !== '' ? parseFloat(formData.clientSocialInsurance1To3P1Y) : 550,
      clientSocialInsurance4To6P1X: formData.clientSocialInsurance4To6P1X && formData.clientSocialInsurance4To6P1X !== '' ? parseFloat(formData.clientSocialInsurance4To6P1X) : 370,
      clientSocialInsurance4To6P1Y: formData.clientSocialInsurance4To6P1Y && formData.clientSocialInsurance4To6P1Y !== '' ? parseFloat(formData.clientSocialInsurance4To6P1Y) : 550,
      clientSocialInsurance7To9P1X: formData.clientSocialInsurance7To9P1X && formData.clientSocialInsurance7To9P1X !== '' ? parseFloat(formData.clientSocialInsurance7To9P1X) : 420,
      clientSocialInsurance7To9P1Y: formData.clientSocialInsurance7To9P1Y && formData.clientSocialInsurance7To9P1Y !== '' ? parseFloat(formData.clientSocialInsurance7To9P1Y) : 550,
      resourceAddressP2X: formData.resourceAddressP2X && formData.resourceAddressP2X !== '' ? parseFloat(formData.resourceAddressP2X) : 40,
      resourceAddressP2Y: formData.resourceAddressP2Y && formData.resourceAddressP2Y !== '' ? parseFloat(formData.resourceAddressP2Y) : 102,
      resourceCityP2X: formData.resourceCityP2X && formData.resourceCityP2X !== '' ? parseFloat(formData.resourceCityP2X) : 40,
      resourceCityP2Y: formData.resourceCityP2Y && formData.resourceCityP2Y !== '' ? parseFloat(formData.resourceCityP2Y) : 90,
      resourceFirstLastNameP2X: formData.resourceFirstLastNameP2X && formData.resourceFirstLastNameP2X !== '' ? parseFloat(formData.resourceFirstLastNameP2X) : 40,
      resourceFirstLastNameP2Y: formData.resourceFirstLastNameP2Y && formData.resourceFirstLastNameP2Y !== '' ? parseFloat(formData.resourceFirstLastNameP2Y) : 115,
      recipientTypeP1X: formData.recipientTypeP1X && formData.recipientTypeP1X !== '' ? parseFloat(formData.recipientTypeP1X) : 430,
      recipientTypeP1Y: formData.recipientTypeP1Y && formData.recipientTypeP1Y !== '' ? parseFloat(formData.recipientTypeP1Y) : 657,
      slipOtherNumberP1X: formData.slipOtherNumberP1X && formData.slipOtherNumberP1X !== '' ? parseFloat(formData.slipOtherNumberP1X) : 480,
      slipOtherNumberP1Y: formData.slipOtherNumberP1Y && formData.slipOtherNumberP1Y !== '' ? parseFloat(formData.slipOtherNumberP1Y) : 552,
      slipOtherNumberP2X: formData.slipOtherNumberP2X && formData.slipOtherNumberP2X !== '' ? parseFloat(formData.slipOtherNumberP2X) : 480,
      slipOtherNumberP2Y: formData.slipOtherNumberP2Y && formData.slipOtherNumberP2Y !== '' ? parseFloat(formData.slipOtherNumberP2Y) : 172,
      bankTransitNumberP1X: formData.bankTransitNumberP1X && formData.bankTransitNumberP1X !== '' ? parseFloat(formData.bankTransitNumberP1X) : 500,
      bankTransitNumberP1Y: formData.bankTransitNumberP1Y && formData.bankTransitNumberP1Y !== '' ? parseFloat(formData.bankTransitNumberP1Y) : 657,
      bankTransitNumberP2X: formData.bankTransitNumberP2X && formData.bankTransitNumberP2X !== '' ? parseFloat(formData.bankTransitNumberP2X) : 490,
      bankTransitNumberP2Y: formData.bankTransitNumberP2Y && formData.bankTransitNumberP2Y !== '' ? parseFloat(formData.bankTransitNumberP2Y) : 657
    }
    let finalPayloadData = {
      payload:payload,
      taxYear:selectedYear,
      tag:selectedTag
    }
    submitFlexFields(finalPayloadData)
    } else if(selectedTag === 't5Summary'){
      let payload:any = {
        taxYearX:formData.taxYearX && formData.taxYearX !=='' ? parseFloat(formData.taxYearX) : 282,
        taxYearY:formData.taxYearY && formData.taxYearY !=='' ? parseFloat(formData.taxYearY) : 677,
        employerAccountX:formData.employerAccountX && formData.employerAccountX !=='' ? parseFloat(formData.employerAccountX) : 159,
        employerAccountY:formData.employerAccountY && formData.employerAccountY !=='' ? parseFloat(formData.employerAccountY) : 654,
        employerNameX:formData.employerNameX && formData.employerNameX !=='' ? parseFloat(formData.employerNameX) : 40,
        employerNameY:formData.employerNameY && formData.employerNameY !=='' ? parseFloat(formData.employerNameY) : 581,
        employerAddressX:formData.employerAddressX && formData.employerAddressX !=='' ? parseFloat(formData.employerAddressX) : 40,
        employerAddressY:formData.employerAddressY && formData.employerAddressY !=='' ? parseFloat(formData.employerAddressY) : 558,
        employerCityX:formData.employerCityX && formData.employerCityX !=='' ? parseFloat(formData.employerCityX) : 40,
        employerCityY:formData.employerCityY && formData.employerCityY !=='' ? parseFloat(formData.employerCityY) : 517,
        employerPostalX:formData.employerPostalX && formData.employerPostalX !=='' ? parseFloat(formData.employerPostalX) : 325,
        employerPostalY:formData.employerPostalY && formData.employerPostalY !=='' ? parseFloat(formData.employerPostalY) : 517,
        employerProvinceX:formData.employerProvinceX && formData.employerProvinceX !=='' ? parseFloat(formData.employerProvinceX) : 236,
        employerProvinceY:formData.employerProvinceY && formData.employerProvinceY !=='' ? parseFloat(formData.employerProvinceY) : 517,
        employerLanguageX:formData.employerLanguageX && formData.employerLanguageX !=='' ? parseFloat(formData.employerLanguageX) : 345,
        employerLanguageY:formData.employerLanguageY && formData.employerLanguageY !=='' ? parseFloat(formData.employerLanguageY) : 490,
        box10X:formData.box10X && formData.box10X !=='' ? parseFloat(formData.box10X) : 320,
        box10Y:formData.box10Y && formData.box10Y !=='' ? parseFloat(formData.box10Y) : 421,
        box11X:formData.box11X && formData.box11X !=='' ? parseFloat(formData.box11X) : 320,
        box11Y:formData.box11Y && formData.box11Y !=='' ? parseFloat(formData.box11Y) : 398,
        box12X:formData.box12X && formData.box12X !=='' ? parseFloat(formData.box12X) : 320,
        box12Y:formData.box12Y && formData.box12Y !=='' ? parseFloat(formData.box12Y) : 374,
        box13X:formData.box13X && formData.box13X !=='' ? parseFloat(formData.box13X) : 320,
        box13Y:formData.box13Y && formData.box13Y !=='' ? parseFloat(formData.box13Y) : 350,
        box14X:formData.box14X && formData.box14X !=='' ? parseFloat(formData.box14X) : 320,
        box14Y:formData.box14Y && formData.box14Y !=='' ? parseFloat(formData.box14Y) : 337,
        box15X:formData.box15X && formData.box15X !=='' ? parseFloat(formData.box15X) : 320,
        box15Y:formData.box15Y && formData.box15Y !=='' ? parseFloat(formData.box15Y) : 325,
        box16X:formData.box16X && formData.box16X !=='' ? parseFloat(formData.box16X) : 320,
        box16Y:formData.box16Y && formData.box16Y !=='' ? parseFloat(formData.box16Y) : 313,
        box17X:formData.box17X && formData.box17X !=='' ? parseFloat(formData.box17X) : 320,
        box17Y:formData.box17Y && formData.box17Y !=='' ? parseFloat(formData.box17Y) : 301,
        box18X:formData.box18X && formData.box18X !=='' ? parseFloat(formData.box18X) : 320,
        box18Y:formData.box18Y && formData.box18Y !=='' ? parseFloat(formData.box18Y) : 289,
        box19X:formData.box19X && formData.box19X !=='' ? parseFloat(formData.box19X) : 320,
        box19Y:formData.box19Y && formData.box19Y !=='' ? parseFloat(formData.box19Y) : 276,
        box24X:formData.box24X && formData.box24X !=='' ? parseFloat(formData.box24X) : 320,
        box24Y:formData.box24Y && formData.box24Y !=='' ? parseFloat(formData.box24Y) : 259,
        box25X:formData.box25X && formData.box25X !=='' ? parseFloat(formData.box25X) : 320,
        box25Y:formData.box25Y && formData.box25Y !=='' ? parseFloat(formData.box25Y) : 242,
        box26X:formData.box26X && formData.box26X !=='' ? parseFloat(formData.box26X) : 320,
        box26Y:formData.box26Y && formData.box26Y !=='' ? parseFloat(formData.box26Y) : 224,
        box32X:formData.box32X && formData.box32X !=='' ? parseFloat(formData.box32X) : 270,
        box32Y:formData.box32Y && formData.box32Y !=='' ? parseFloat(formData.box32Y) : 198,
        box33X:formData.box33X && formData.box33X !=='' ? parseFloat(formData.box33X) : 270,
        box33Y:formData.box33Y && formData.box33Y !=='' ? parseFloat(formData.box33Y) : 220,
        box31X:formData.box31X && formData.box31X !=='' ? parseFloat(formData.box31X) : 460,
        box31Y:formData.box31Y && formData.box31Y !=='' ? parseFloat(formData.box31Y) : 160,
        box41X:formData.box41X && formData.box41X !=='' ? parseFloat(formData.box41X) : 55,
        box41Y:formData.box41Y && formData.box41Y !=='' ? parseFloat(formData.box41Y) : 113,
        box42X:formData.box42X && formData.box42X !=='' ? parseFloat(formData.box42X) : 295,
        box42Y:formData.box42Y && formData.box42Y !=='' ? parseFloat(formData.box42Y) : 113
      }
      let finalPayloadData = {
        payload:payload,
        taxYear:selectedYear,
        tag:selectedTag
      }
      submitFlexFields(finalPayloadData)
    } else if(selectedTag === 'rl3'){
      let payload:any = {
        taxYearP1X:formData.taxYearP1X && formData.taxYearP1X !== '' ? parseFloat(formData.taxYearP1X) : 250,
        taxYearP1Y:formData.taxYearP1Y && formData.taxYearP1Y !== '' ? parseFloat(formData.taxYearP1Y) : 740,
        statementCodeP1X:formData.statementCodeP1X && formData.statementCodeP1X !== '' ? parseFloat(formData.statementCodeP1X) : 300,
        statementCodeP1Y:formData.statementCodeP1Y && formData.statementCodeP1Y !== '' ? parseFloat(formData.statementCodeP1Y) : 740,
        currencyCodeP1X:formData.currencyCodeP1X && formData.currencyCodeP1X !== '' ? parseFloat(formData.currencyCodeP1X) : 350,
        currencyCodeP1Y:formData.currencyCodeP1Y && formData.currencyCodeP1Y !== '' ? parseFloat(formData.currencyCodeP1Y) : 740,
        numberOfLastStatementSentP1X:formData.numberOfLastStatementSentP1X && formData.numberOfLastStatementSentP1X !== '' ? parseFloat(formData.numberOfLastStatementSentP1X) : 390,
        numberOfLastStatementSentP1Y:formData.numberOfLastStatementSentP1Y && formData.numberOfLastStatementSentP1Y !== '' ? parseFloat(formData.numberOfLastStatementSentP1Y) : 740,
        boxA1P1X:formData.boxA1P1X && formData.boxA1P1X !== '' ? parseFloat(formData.boxA1P1X) : 40,
        boxA1P1Y:formData.boxA1P1Y && formData.boxA1P1Y !== '' ? parseFloat(formData.boxA1P1Y) : 705,
        boxA2P1X:formData.boxA2P1X && formData.boxA2P1X !== '' ? parseFloat(formData.boxA2P1X) : 160,
        boxA2P1Y:formData.boxA2P1Y && formData.boxA2P1Y !== '' ? parseFloat(formData.boxA2P1Y) : 705,
        boxBP1X:formData.boxBP1X && formData.boxBP1X !== '' ? parseFloat(formData.boxBP1X) : 280,
        boxBP1Y:formData.boxBP1Y && formData.boxBP1Y !== '' ? parseFloat(formData.boxBP1Y) : 705,
        boxCP1X:formData.boxCP1X && formData.boxCP1X !== '' ? parseFloat(formData.boxCP1X) : 400,
        boxCP1Y:formData.boxCP1Y && formData.boxCP1Y !== '' ? parseFloat(formData.boxCP1Y) : 705,
        boxDP1X:formData.boxDP1X && formData.boxDP1X !== '' ? parseFloat(formData.boxDP1X) : 520,
        boxDP1Y:formData.boxDP1Y && formData.boxDP1Y !== '' ? parseFloat(formData.boxDP1Y) : 705,
        boxEP1X:formData.boxEP1X && formData.boxEP1X !== '' ? parseFloat(formData.boxEP1X) : 40,
        boxEP1Y:formData.boxEP1Y && formData.boxEP1Y !== '' ? parseFloat(formData.boxEP1Y) : 680,
        boxFP1X:formData.boxFP1X && formData.boxFP1X !== '' ? parseFloat(formData.boxFP1X) : 160,
        boxFP1Y:formData.boxFP1Y && formData.boxFP1Y !== '' ? parseFloat(formData.boxFP1Y) : 680,
        boxGP1X:formData.boxGP1X && formData.boxGP1X !== '' ? parseFloat(formData.boxGP1X) : 280,
        boxGP1Y:formData.boxGP1Y && formData.boxGP1Y !== '' ? parseFloat(formData.boxGP1Y) : 680,
        boxHP1X:formData.boxHP1X && formData.boxHP1X !== '' ? parseFloat(formData.boxHP1X) : 400,
        boxHP1Y:formData.boxHP1Y && formData.boxHP1Y !== '' ? parseFloat(formData.boxHP1Y) : 680,
        boxIP1X:formData.boxIP1X && formData.boxIP1X !== '' ? parseFloat(formData.boxIP1X) : 520,
        boxIP1Y:formData.boxIP1Y && formData.boxIP1Y !== '' ? parseFloat(formData.boxIP1Y) : 680,
        boxJP1X:formData.boxJP1X && formData.boxJP1X !== '' ? parseFloat(formData.boxJP1X) : 40,
        boxJP1Y:formData.boxJP1Y && formData.boxJP1Y !== '' ? parseFloat(formData.boxJP1Y) : 655,
        boxKP1X:formData.boxKP1X && formData.boxKP1X !== '' ? parseFloat(formData.boxKP1X) : 160,
        boxKP1Y:formData.boxKP1Y && formData.boxKP1Y !== '' ? parseFloat(formData.boxKP1Y) : 655,
        otherInfoCode1P1X:formData.otherInfoCode1P1X && formData.otherInfoCode1P1X !== '' ? parseFloat(formData.otherInfoCode1P1X) : 35,
        otherInfoCode1P1Y:formData.otherInfoCode1P1Y && formData.otherInfoCode1P1Y !== '' ? parseFloat(formData.otherInfoCode1P1Y) : 631,
        otherInfoAmount1P1X:formData.otherInfoAmount1P1X && formData.otherInfoAmount1P1X !== '' ? parseFloat(formData.otherInfoAmount1P1X) : 100,
        otherInfoAmount1P1Y:formData.otherInfoAmount1P1Y && formData.otherInfoAmount1P1Y !== '' ? parseFloat(formData.otherInfoAmount1P1Y) : 631,
        otherInfoCode2P1X:formData.otherInfoCode2P1X && formData.otherInfoCode2P1X !== '' ? parseFloat(formData.otherInfoCode2P1X) : 175,
        otherInfoCode2P1Y:formData.otherInfoCode2P1Y && formData.otherInfoCode2P1Y !== '' ? parseFloat(formData.otherInfoCode2P1Y) : 631,
        otherInfoAmount2P1X:formData.otherInfoAmount2P1X && formData.otherInfoAmount2P1X !== '' ? parseFloat(formData.otherInfoAmount2P1X) : 260,
        otherInfoAmount2P1Y:formData.otherInfoAmount2P1Y && formData.otherInfoAmount2P1Y !== '' ? parseFloat(formData.otherInfoAmount2P1Y) : 631,
        otherInfoCode3P1X:formData.otherInfoCode3P1X && formData.otherInfoCode3P1X !== '' ? parseFloat(formData.otherInfoCode3P1X) : 320,
        otherInfoCode3P1Y:formData.otherInfoCode3P1Y && formData.otherInfoCode3P1Y !== '' ? parseFloat(formData.otherInfoCode3P1Y) : 631,
        otherInfoAmount3P1X:formData.otherInfoAmount3P1X && formData.otherInfoAmount3P1X !== '' ? parseFloat(formData.otherInfoAmount3P1X) : 390,
        otherInfoAmount3P1Y:formData.otherInfoAmount3P1Y && formData.otherInfoAmount3P1Y !== '' ? parseFloat(formData.otherInfoAmount3P1Y) : 631,
        otherInfoCode4P1X:formData.otherInfoCode4P1X && formData.otherInfoCode4P1X !== '' ? parseFloat(formData.otherInfoCode4P1X) : 453,
        otherInfoCode4P1Y:formData.otherInfoCode4P1Y && formData.otherInfoCode4P1Y !== '' ? parseFloat(formData.otherInfoCode4P1Y) : 631,
        otherInfoAmount4P1X:formData.otherInfoAmount4P1X && formData.otherInfoAmount4P1X !== '' ? parseFloat(formData.otherInfoAmount4P1X) : 520,
        otherInfoAmount4P1Y:formData.otherInfoAmount4P1Y && formData.otherInfoAmount4P1Y !== '' ? parseFloat(formData.otherInfoAmount4P1Y) : 631,
        resouceFirstNameP1X:formData.resouceFirstNameP1X && formData.resouceFirstNameP1X !== '' ? parseFloat(formData.resouceFirstNameP1X) : 40,
        resouceFirstNameP1Y:formData.resouceFirstNameP1Y && formData.resouceFirstNameP1Y !== '' ? parseFloat(formData.resouceFirstNameP1Y) : 552,
        resouceLastNameP1X:formData.resouceLastNameP1X && formData.resouceLastNameP1X !== '' ? parseFloat(formData.resouceLastNameP1X) : 40,
        resouceLastNameP1Y:formData.resouceLastNameP1Y && formData.resouceLastNameP1Y !== '' ? parseFloat(formData.resouceLastNameP1Y) : 527,
        resouceAddressLine1P1X:formData.resouceAddressLine1P1X && formData.resouceAddressLine1P1X !== '' ? parseFloat(formData.resouceAddressLine1P1X) : 40,
        resouceAddressLine1P1Y:formData.resouceAddressLine1P1Y && formData.resouceAddressLine1P1Y !== '' ? parseFloat(formData.resouceAddressLine1P1Y) : 503,
        resouceAddressLine2P1X:formData.resouceAddressLine2P1X && formData.resouceAddressLine2P1X !== '' ? parseFloat(formData.resouceAddressLine2P1X) : 40,
        resouceAddressLine2P1Y:formData.resouceAddressLine2P1Y && formData.resouceAddressLine2P1Y !== '' ? parseFloat(formData.resouceAddressLine2P1Y) : 480,
        resouceCityP1X:formData.resouceCityP1X && formData.resouceCityP1X !== '' ? parseFloat(formData.resouceCityP1X) : 40,
        resouceCityP1Y:formData.resouceCityP1Y && formData.resouceCityP1Y !== '' ? parseFloat(formData.resouceCityP1Y) : 457,
        resourceProvinceCodeP1X:formData.resourceProvinceCodeP1X && formData.resourceProvinceCodeP1X !== '' ? parseFloat(formData.resourceProvinceCodeP1X) : 40,
        resourceProvinceCodeP1Y:formData.resourceProvinceCodeP1Y && formData.resourceProvinceCodeP1Y !== '' ? parseFloat(formData.resourceProvinceCodeP1Y) : 430.05,
        resourcePostalCodeFirstThreeP1X:formData.resourcePostalCodeFirstThreeP1X && formData.resourcePostalCodeFirstThreeP1X !== '' ? parseFloat(formData.resourcePostalCodeFirstThreeP1X) : 80,
        resourcePostalCodeFirstThreeP1Y:formData.resourcePostalCodeFirstThreeP1Y && formData.resourcePostalCodeFirstThreeP1Y !== '' ? parseFloat(formData.resourcePostalCodeFirstThreeP1Y) : 430.5,
        resourcePostalCodeLastThreeP1X:formData.resourcePostalCodeLastThreeP1X && formData.resourcePostalCodeLastThreeP1X !== '' ? parseFloat(formData.resourcePostalCodeLastThreeP1X) : 116,
        resourcePostalCodeLastThreeP1Y:formData.resourcePostalCodeLastThreeP1Y && formData.resourcePostalCodeLastThreeP1Y !== '' ? parseFloat(formData.resourcePostalCodeLastThreeP1Y) : 430.5,
        clientNameLine1P1X:formData.clientNameLine1P1X && formData.clientNameLine1P1X !== '' ? parseFloat(formData.clientNameLine1P1X) : 335,
        clientNameLine1P1Y:formData.clientNameLine1P1Y && formData.clientNameLine1P1Y !== '' ? parseFloat(formData.clientNameLine1P1Y) : 527,
        clientNameLine2P1X:formData.clientNameLine2P1X && formData.clientNameLine2P1X !== '' ? parseFloat(formData.clientNameLine2P1X) : 335,
        clientNameLine2P1Y:formData.clientNameLine2P1Y && formData.clientNameLine2P1Y !== '' ? parseFloat(formData.clientNameLine2P1Y) : 503,
        clientAddressLine1P1X:formData.clientAddressLine1P1X && formData.clientAddressLine1P1X !== '' ? parseFloat(formData.clientAddressLine1P1X) : 335,
        clientAddressLine1P1Y:formData.clientAddressLine1P1Y && formData.clientAddressLine1P1Y !== '' ? parseFloat(formData.clientAddressLine1P1Y) : 480,
        clientAddressLine2P1X:formData.clientAddressLine2P1X && formData.clientAddressLine2P1X !== '' ? parseFloat(formData.clientAddressLine2P1X) : 335,
        clientAddressLine2P1Y:formData.clientAddressLine2P1Y && formData.clientAddressLine2P1Y !== '' ? parseFloat(formData.clientAddressLine2P1Y) : 457,
        clientCityP1X:formData.clientCityP1X && formData.clientCityP1X !== '' ? parseFloat(formData.clientCityP1X) : 335,
        clientCityP1Y:formData.clientCityP1Y && formData.clientCityP1Y !== '' ? parseFloat(formData.clientCityP1Y) : 430,
        clientProvinceCodeP1X:formData.clientProvinceCodeP1X && formData.clientProvinceCodeP1X !== '' ? parseFloat(formData.clientProvinceCodeP1X) : 335,
        clientProvinceCodeP1Y:formData.clientProvinceCodeP1Y && formData.clientProvinceCodeP1Y !== '' ? parseFloat(formData.clientProvinceCodeP1Y) : 406.5,
        clientPostalCodeFirstThreeP1X:formData.clientPostalCodeFirstThreeP1X && formData.clientPostalCodeFirstThreeP1X !== '' ? parseFloat(formData.clientPostalCodeFirstThreeP1X) : 375,
        clientPostalCodeFirstThreeP1Y:formData.clientPostalCodeFirstThreeP1Y && formData.clientPostalCodeFirstThreeP1Y !== '' ? parseFloat(formData.clientPostalCodeFirstThreeP1Y) : 406.5,
        clientPostalCodeLastThreeP1X:formData.clientPostalCodeLastThreeP1X && formData.clientPostalCodeLastThreeP1X !== '' ? parseFloat(formData.clientPostalCodeLastThreeP1X) : 415,
        clientPostalCodeLastThreeP1Y:formData.clientPostalCodeLastThreeP1Y && formData.clientPostalCodeLastThreeP1Y !== '' ? parseFloat(formData.clientPostalCodeLastThreeP1Y) : 406.5,
        clientSocialInsurance1To3P2X:formData.clientSocialInsurance1To3P2X && formData.clientSocialInsurance1To3P2X !== '' ? parseFloat(formData.clientSocialInsurance1To3P2X) : 335,
        clientSocialInsurance1To3P2Y:formData.clientSocialInsurance1To3P2Y && formData.clientSocialInsurance1To3P2Y !== '' ? parseFloat(formData.clientSocialInsurance1To3P2Y) : 550,
        clientSocialInsurance4To6P2X:formData.clientSocialInsurance4To6P2X && formData.clientSocialInsurance4To6P2X !== '' ? parseFloat(formData.clientSocialInsurance4To6P2X) : 370,
        clientSocialInsurance4To6P2Y:formData.clientSocialInsurance4To6P2Y && formData.clientSocialInsurance4To6P2Y !== '' ? parseFloat(formData.clientSocialInsurance4To6P2Y) : 550,
        clientSocialInsurance7To9P2X:formData.clientSocialInsurance7To9P2X && formData.clientSocialInsurance7To9P2X !== '' ? parseFloat(formData.clientSocialInsurance7To9P2X) : 420,
        clientSocialInsurance7To9P2Y:formData.clientSocialInsurance7To9P2Y && formData.clientSocialInsurance7To9P2Y !== '' ? parseFloat(formData.clientSocialInsurance7To9P2Y) : 550,
        lastNameOrCompanyNameOfTheSecondHolderX:formData.lastNameOrCompanyNameOfTheSecondHolderX && formData.lastNameOrCompanyNameOfTheSecondHolderX !== '' ? parseFloat(formData.lastNameOrCompanyNameOfTheSecondHolderX) : 40,
        lastNameOrCompanyNameOfTheSecondHolderY:formData.lastNameOrCompanyNameOfTheSecondHolderY && formData.lastNameOrCompanyNameOfTheSecondHolderY !== '' ? parseFloat(formData.lastNameOrCompanyNameOfTheSecondHolderY) : 406,
        firstNameOrCompanyNameOfTheSecondHolderX:formData.firstNameOrCompanyNameOfTheSecondHolderX && formData.firstNameOrCompanyNameOfTheSecondHolderX !== '' ? parseFloat(formData.firstNameOrCompanyNameOfTheSecondHolderX) : 40,
        firstNameOrCompanyNameOfTheSecondHolderY:formData.firstNameOrCompanyNameOfTheSecondHolderY && formData.firstNameOrCompanyNameOfTheSecondHolderY !== '' ? parseFloat(formData.firstNameOrCompanyNameOfTheSecondHolderY) : 385,
        codeDeLaDeviseSlipTypeP1X:formData.codeDeLaDeviseSlipTypeP1X && formData.codeDeLaDeviseSlipTypeP1X !== '' ? parseFloat(formData.codeDeLaDeviseSlipTypeP1X) : 310,
        codeDeLaDeviseSlipTypeP1Y:formData.codeDeLaDeviseSlipTypeP1Y && formData.codeDeLaDeviseSlipTypeP1Y !== '' ? parseFloat(formData.codeDeLaDeviseSlipTypeP1Y) : 740,
        fsP1X:formData.fsP1X && formData.fsP1X !== '' ? parseFloat(formData.fsP1X) : 524,
        fsP1Y:formData.fsP1Y && formData.fsP1Y !== '' ? parseFloat(formData.fsP1Y) : 63,
        rl3202210AboveBoxP1X:formData.rl3202210AboveBoxP1X && formData.rl3202210AboveBoxP1X !== '' ? parseFloat(formData.rl3202210AboveBoxP1X) : 510,
        rl3202210AboveBoxP1Y:formData.rl3202210AboveBoxP1Y && formData.rl3202210AboveBoxP1Y !== '' ? parseFloat(formData.rl3202210AboveBoxP1Y) : 749.5,
        rl3202210DownBoxP1X:formData.rl3202210DownBoxP1X && formData.rl3202210DownBoxP1X !== '' ? parseFloat(formData.rl3202210DownBoxP1X) : 510,
        rl3202210DownBoxP1Y:formData.rl3202210DownBoxP1Y && formData.rl3202210DownBoxP1Y !== '' ? parseFloat(formData.rl3202210DownBoxP1Y) : 737,
        taxYearP2X:formData.taxYearP2X && formData.taxYearP2X !== '' ? parseFloat(formData.taxYearP2X) : 250,
        taxYearP2Y:formData.taxYearP2Y && formData.taxYearP2Y !== '' ? parseFloat(formData.taxYearP2Y) : 740,
        statementCodeP2X:formData.statementCodeP2X && formData.statementCodeP2X !== '' ? parseFloat(formData.statementCodeP2X) : 300,
        statementCodeP2Y:formData.statementCodeP2Y && formData.statementCodeP2Y !== '' ? parseFloat(formData.statementCodeP2Y) : 740,
        currencyCodeP2X:formData.currencyCodeP2X && formData.currencyCodeP2X !== '' ? parseFloat(formData.currencyCodeP2X) : 380,
        currencyCodeP2Y:formData.currencyCodeP2Y && formData.currencyCodeP2Y !== '' ? parseFloat(formData.currencyCodeP2Y) : 740,
        fsP2X:formData.fsP2X && formData.fsP2X !== '' ? parseFloat(formData.fsP2X) : 524,
        fsP2Y:formData.fsP2Y && formData.fsP2Y !== '' ? parseFloat(formData.fsP2Y) : 63,
        numberOfLastStatementSentP2X:formData.numberOfLastStatementSentP2X && formData.numberOfLastStatementSentP2X !== '' ? parseFloat(formData.numberOfLastStatementSentP2X) : 390,
        numberOfLastStatementSentP2Y:formData.numberOfLastStatementSentP2Y && formData.numberOfLastStatementSentP2Y !== '' ? parseFloat(formData.numberOfLastStatementSentP2Y) : 740,
        rl3202210AboveBoxP2X:formData.rl3202210AboveBoxP2X && formData.rl3202210AboveBoxP2X !== '' ? parseFloat(formData.rl3202210AboveBoxP2X) : 510,
        rl3202210AboveBoxP2Y:formData.rl3202210AboveBoxP2Y && formData.rl3202210AboveBoxP2Y !== '' ? parseFloat(formData.rl3202210AboveBoxP2Y) : 749.5,
        rl3202210DownBoxP2X:formData.rl3202210DownBoxP2X && formData.rl3202210DownBoxP2X !== '' ? parseFloat(formData.rl3202210DownBoxP2X) : 510,
        rl3202210DownBoxP2Y:formData.rl3202210DownBoxP2Y && formData.rl3202210DownBoxP2Y !== '' ? parseFloat(formData.rl3202210DownBoxP2Y) : 737,
        boxA1P2X:formData.boxA1P2X && formData.boxA1P2X !== '' ? parseFloat(formData.boxA1P2X) : 40,
        boxA1P2Y:formData.boxA1P2Y && formData.boxA1P2Y !== '' ? parseFloat(formData.boxA1P2Y) : 705,
        boxA2P2X:formData.boxA2P2X && formData.boxA2P2X !== '' ? parseFloat(formData.boxA2P2X) : 160,
        boxA2P2Y:formData.boxA2P2Y && formData.boxA2P2Y !== '' ? parseFloat(formData.boxA2P2Y) : 705,
        boxBP2X:formData.boxBP2X && formData.boxBP2X !== '' ? parseFloat(formData.boxBP2X) : 280,
        boxBP2Y:formData.boxBP2Y && formData.boxBP2Y !== '' ? parseFloat(formData.boxBP2Y) : 705,
        boxCP2X:formData.boxCP2X && formData.boxCP2X !== '' ? parseFloat(formData.boxCP2X) : 400,
        boxCP2Y:formData.boxCP2Y && formData.boxCP2Y !== '' ? parseFloat(formData.boxCP2Y) : 705,
        boxDP2X:formData.boxDP2X && formData.boxDP2X !== '' ? parseFloat(formData.boxDP2X) : 520,
        boxDP2Y:formData.boxDP2Y && formData.boxDP2Y !== '' ? parseFloat(formData.boxDP2Y) : 705,
        boxEP2X:formData.boxEP2X && formData.boxEP2X !== '' ? parseFloat(formData.boxEP2X) : 40,
        boxEP2Y:formData.boxEP2Y && formData.boxEP2Y !== '' ? parseFloat(formData.boxEP2Y) : 680,
        boxFP2X:formData.boxFP2X && formData.boxFP2X !== '' ? parseFloat(formData.boxFP2X) : 160,
        boxFP2Y:formData.boxFP2Y && formData.boxFP2Y !== '' ? parseFloat(formData.boxFP2Y) : 680,
        boxGP2X:formData.boxGP2X && formData.boxGP2X !== '' ? parseFloat(formData.boxGP2X) : 280,
        boxGP2Y:formData.boxGP2Y && formData.boxGP2Y !== '' ? parseFloat(formData.boxGP2Y) : 680,
        boxHP2X:formData.boxHP2X && formData.boxHP2X !== '' ? parseFloat(formData.boxHP2X) : 400,
        boxHP2Y:formData.boxHP2Y && formData.boxHP2Y !== '' ? parseFloat(formData.boxHP2Y) : 680,
        boxIP2X:formData.boxIP2X && formData.boxIP2X !== '' ? parseFloat(formData.boxIP2X) : 520,
        boxIP2Y:formData.boxIP2Y && formData.boxIP2Y !== '' ? parseFloat(formData.boxIP2Y) : 680,
        boxJP2X:formData.boxJP2X && formData.boxJP2X !== '' ? parseFloat(formData.boxJP2X) : 40,
        boxJP2Y:formData.boxJP2Y && formData.boxJP2Y !== '' ? parseFloat(formData.boxJP2Y) : 655,
        boxKP2X:formData.boxKP2X && formData.boxKP2X !== '' ? parseFloat(formData.boxKP2X) : 160,
        boxKP2Y:formData.boxKP2Y && formData.boxKP2Y !== '' ? parseFloat(formData.boxKP2Y) : 655,
        otherInfoCode1P2X:formData.otherInfoCode1P2X && formData.otherInfoCode1P2X !== '' ? parseFloat(formData.otherInfoCode1P2X) : 35,
        otherInfoCode1P2Y:formData.otherInfoCode1P2Y && formData.otherInfoCode1P2Y !== '' ? parseFloat(formData.otherInfoCode1P2Y) : 631,
        otherInfoAmount1P2X:formData.otherInfoAmount1P2X && formData.otherInfoAmount1P2X !== '' ? parseFloat(formData.otherInfoAmount1P2X) : 100,
        otherInfoAmount1P2Y:formData.otherInfoAmount1P2Y && formData.otherInfoAmount1P2Y !== '' ? parseFloat(formData.otherInfoAmount1P2Y) : 631,
        otherInfoCode2P2X:formData.otherInfoCode2P2X && formData.otherInfoCode2P2X !== '' ? parseFloat(formData.otherInfoCode2P2X) : 175,
        otherInfoCode2P2Y:formData.otherInfoCode2P2Y && formData.otherInfoCode2P2Y !== '' ? parseFloat(formData.otherInfoCode2P2Y) : 631,
        otherInfoAmount2P2X:formData.otherInfoAmount2P2X && formData.otherInfoAmount2P2X !== '' ? parseFloat(formData.otherInfoAmount2P2X) : 260,
        otherInfoAmount2P2Y:formData.otherInfoAmount2P2Y && formData.otherInfoAmount2P2Y !== '' ? parseFloat(formData.otherInfoAmount2P2Y) : 631,
        otherInfoCode3P2X:formData.otherInfoCode3P2X && formData.otherInfoCode3P2X !== '' ? parseFloat(formData.otherInfoCode3P2X) : 320,
        otherInfoCode3P2Y:formData.otherInfoCode3P2Y && formData.otherInfoCode3P2Y !== '' ? parseFloat(formData.otherInfoCode3P2Y) : 631,
        otherInfoAmount3P2X:formData.otherInfoAmount3P2X && formData.otherInfoAmount3P2X !== '' ? parseFloat(formData.otherInfoAmount3P2X) : 390,
        otherInfoAmount3P2Y:formData.otherInfoAmount3P2Y && formData.otherInfoAmount3P2Y !== '' ? parseFloat(formData.otherInfoAmount3P2Y) : 631,
        otherInfoCode4P2X:formData.otherInfoCode4P2X && formData.otherInfoCode4P2X !== '' ? parseFloat(formData.otherInfoCode4P2X) : 453,
        otherInfoCode4P2Y:formData.otherInfoCode4P2Y && formData.otherInfoCode4P2Y !== '' ? parseFloat(formData.otherInfoCode4P2Y) : 631,
        otherInfoAmount4P2X:formData.otherInfoAmount4P2X && formData.otherInfoAmount4P2X !== '' ? parseFloat(formData.otherInfoAmount4P2X) : 520,
        otherInfoAmount4P2Y:formData.otherInfoAmount4P2Y && formData.otherInfoAmount4P2Y !== '' ? parseFloat(formData.otherInfoAmount4P2Y) : 631,
        branchNumberX:formData.branchNumberX && formData.branchNumberX !== '' ? parseFloat(formData.branchNumberX) : 440,
        branchNumberY:formData.branchNumberY && formData.branchNumberY !== '' ? parseFloat(formData.branchNumberY) : 510,
        typeX:formData.typeX && formData.typeX !== '' ? parseFloat(formData.typeX) : 562,
        typeY:formData.typeY && formData.typeY !== '' ? parseFloat(formData.typeY) : 738,
        codeDeLaDeviseSlipTypeP2X:formData.codeDeLaDeviseSlipTypeP2X && formData.codeDeLaDeviseSlipTypeP2X !== '' ? parseFloat(formData.codeDeLaDeviseSlipTypeP2X) : 310,
        codeDeLaDeviseSlipTypeP2Y:formData.codeDeLaDeviseSlipTypeP2Y && formData.codeDeLaDeviseSlipTypeP2Y !== '' ? parseFloat(formData.codeDeLaDeviseSlipTypeP2Y) : 740
      }
      let finalPayloadData = {
        payload:payload,
        taxYear:selectedYear,
        tag:selectedTag
      }
      submitFlexFields(finalPayloadData)
    } else if(selectedTag === 'rl16'){
      let payload:any = {
        taxYearP1X:formData.taxYearP1X && formData.taxYearP1X !== '' ? parseFloat(formData.taxYearP1X) : 250,
        taxYearP1Y: formData.taxYearP1Y && formData.taxYearP1Y !== '' ? parseFloat(formData.taxYearP1Y) : 740,
        statementCodeP1X: formData.statementCodeP1X && formData.statementCodeP1X !== '' ? parseFloat(formData.statementCodeP1X) : 300,
        statementCodeP1Y: formData.statementCodeP1Y && formData.statementCodeP1Y !== '' ? parseFloat(formData.statementCodeP1Y) : 740,
        numberOfLastStatementSentP1X: formData.numberOfLastStatementSentP1X && formData.numberOfLastStatementSentP1X !== '' ? parseFloat(formData.numberOfLastStatementSentP1X) : 390,
        numberOfLastStatementSentP1Y: formData.numberOfLastStatementSentP1Y && formData.numberOfLastStatementSentP1Y !== '' ? parseFloat(formData.numberOfLastStatementSentP1Y) : 740,
        boxAP1X: formData.boxAP1X && formData.boxAP1X !== '' ? parseFloat(formData.boxAP1X) : 84,
        boxAP1Y: formData.boxAP1Y && formData.boxAP1Y !== '' ? parseFloat(formData.boxAP1Y) : 700,
        boxBP1X: formData.boxBP1X && formData.boxBP1X !== '' ? parseFloat(formData.boxBP1X) : 180,
        boxBP1Y: formData.boxBP1Y && formData.boxBP1Y !== '' ? parseFloat(formData.boxBP1Y) : 700,
        boxC1P1X: formData.boxC1P1X && formData.boxC1P1X !== '' ? parseFloat(formData.boxC1P1X) : 276,
        boxC1P1Y: formData.boxC1P1Y && formData.boxC1P1Y !== '' ? parseFloat(formData.boxC1P1Y) : 700,
        boxC2P1X: formData.boxC2P1X && formData.boxC2P1X !== '' ? parseFloat(formData.boxC2P1X) : 372,
        boxC2P1Y: formData.boxC2P1Y && formData.boxC2P1Y !== '' ? parseFloat(formData.boxC2P1Y) : 700,
        boxDP1X: formData.boxDP1X && formData.boxDP1X !== '' ? parseFloat(formData.boxDP1X) : 466,
        boxDP1Y: formData.boxDP1Y && formData.boxDP1Y !== '' ? parseFloat(formData.boxDP1Y) : 700,
        boxEP1X: formData.boxEP1X && formData.boxEP1X !== '' ? parseFloat(formData.boxEP1X) : 562,
        boxEP1Y: formData.boxEP1Y && formData.boxEP1Y !== '' ? parseFloat(formData.boxEP1Y) : 700,
        boxFP1X: formData.boxFP1X && formData.boxFP1X !== '' ? parseFloat(formData.boxFP1X) : 84,
        boxFP1Y: formData.boxFP1Y && formData.boxFP1Y !== '' ? parseFloat(formData.boxFP1Y) : 670,
        boxGP1X: formData.boxGP1X && formData.boxGP1X !== '' ? parseFloat(formData.boxGP1X) : 180,
        boxGP1Y: formData.boxGP1Y && formData.boxGP1Y !== '' ? parseFloat(formData.boxGP1Y) : 670,
        boxHP1X: formData.boxHP1X && formData.boxHP1X !== '' ? parseFloat(formData.boxHP1X) : 276,
        boxHP1Y: formData.boxHP1Y && formData.boxHP1Y !== '' ? parseFloat(formData.boxHP1Y) : 670,
        boxIP1X: formData.boxIP1X && formData.boxIP1X !== '' ? parseFloat(formData.boxIP1X) : 372,
        boxIP1Y: formData.boxIP1Y && formData.boxIP1Y !== '' ? parseFloat(formData.boxIP1Y) : 670,
        boxJP1X: formData.boxJP1X && formData.boxJP1X !== '' ? parseFloat(formData.boxJP1X) : 466,
        boxJP1Y: formData.boxJP1Y && formData.boxJP1Y !== '' ? parseFloat(formData.boxJP1Y) : 670,
        boxKP1X: formData.boxKP1X && formData.boxKP1X !== '' ? parseFloat(formData.boxKP1X) : 562,
        boxKP1Y: formData.boxKP1Y && formData.boxKP1Y !== '' ? parseFloat(formData.boxKP1Y) : 670,
        boxLP1X: formData.boxLP1X && formData.boxLP1X !== '' ? parseFloat(formData.boxLP1X) : 84,
        boxLP1Y: formData.boxLP1Y && formData.boxLP1Y !== '' ? parseFloat(formData.boxLP1Y) : 640,
        boxMP1X: formData.boxMP1X && formData.boxMP1X !== '' ? parseFloat(formData.boxMP1X) : 180,
        boxMP1Y: formData.boxMP1Y && formData.boxMP1Y !== '' ? parseFloat(formData.boxMP1Y) : 640,
        boxNP1X: formData.boxNP1X && formData.boxNP1X !== '' ? parseFloat(formData.boxNP1X) : 267,
        boxNP1Y: formData.boxNP1Y && formData.boxNP1Y !== '' ? parseFloat(formData.boxNP1Y) : 640,
        recipientTypeP1X: formData.recipientTypeP1X && formData.recipientTypeP1X !== '' ? parseFloat(formData.recipientTypeP1X) : 507,
        recipientTypeP1Y: formData.recipientTypeP1Y && formData.recipientTypeP1Y !== '' ? parseFloat(formData.recipientTypeP1Y) : 640,
        jointAccountLastNamep1X: formData.jointAccountLastNamep1X && formData.jointAccountLastNamep1X !== '' ? parseFloat(formData.jointAccountLastNamep1X) : 40,
        jointAccountLastNamep1Y: formData.jointAccountLastNamep1Y && formData.jointAccountLastNamep1Y !== '' ? parseFloat(formData.jointAccountLastNamep1Y) : 407,
        jointAccountFirstNameP1X: formData.jointAccountFirstNameP1X && formData.jointAccountFirstNameP1X !== '' ? parseFloat(formData.jointAccountFirstNameP1X) : 40,
        jointAccountFirstNameP1Y: formData.jointAccountFirstNameP1Y && formData.jointAccountFirstNameP1Y !== '' ? parseFloat(formData.jointAccountFirstNameP1Y) : 383,
        otherInfoCode1P1X: formData.otherInfoCode1P1X && formData.otherInfoCode1P1X !== '' ? parseFloat(formData.otherInfoCode1P1X) : 35,
        otherInfoCode1P1Y: formData.otherInfoCode1P1Y && formData.otherInfoCode1P1Y !== '' ? parseFloat(formData.otherInfoCode1P1Y) : 610,
        otherInfoAmount1P1X: formData.otherInfoAmount1P1X && formData.otherInfoAmount1P1X !== '' ? parseFloat(formData.otherInfoAmount1P1X) : 80,
        otherInfoAmount1P1Y: formData.otherInfoAmount1P1Y && formData.otherInfoAmount1P1Y !== '' ? parseFloat(formData.otherInfoAmount1P1Y) : 610,
        otherInfoCode2P1X: formData.otherInfoCode2P1X && formData.otherInfoCode2P1X !== '' ? parseFloat(formData.otherInfoCode2P1X) : 180,
        otherInfoCode2P1Y: formData.otherInfoCode2P1Y && formData.otherInfoCode2P1Y !== '' ? parseFloat(formData.otherInfoCode2P1Y) : 610,
        otherInfoAmount2P1X: formData.otherInfoAmount2P1X && formData.otherInfoAmount2P1X !== '' ? parseFloat(formData.otherInfoAmount2P1X) : 230,
        otherInfoAmount2P1Y: formData.otherInfoAmount2P1Y && formData.otherInfoAmount2P1Y !== '' ? parseFloat(formData.otherInfoAmount2P1Y) : 610,
        otherInfoCode3P1X: formData.otherInfoCode3P1X && formData.otherInfoCode3P1X !== '' ? parseFloat(formData.otherInfoCode3P1X) : 315,
        otherInfoCode3P1Y: formData.otherInfoCode3P1Y && formData.otherInfoCode3P1Y !== '' ? parseFloat(formData.otherInfoCode3P1Y) : 610,
        otherInfoAmount3P1X: formData.otherInfoAmount3P1X && formData.otherInfoAmount3P1X !== '' ? parseFloat(formData.otherInfoAmount3P1X) : 360,
        otherInfoAmount3P1Y: formData.otherInfoAmount3P1Y && formData.otherInfoAmount3P1Y !== '' ? parseFloat(formData.otherInfoAmount3P1Y) : 610,
        otherInfoCode4P1X: formData.otherInfoCode4P1X && formData.otherInfoCode4P1X !== '' ? parseFloat(formData.otherInfoCode4P1X) : 460,
        otherInfoCode4P1Y: formData.otherInfoCode4P1Y && formData.otherInfoCode4P1Y !== '' ? parseFloat(formData.otherInfoCode4P1Y) : 610,
        otherInfoAmount4P1X: formData.otherInfoAmount4P1X && formData.otherInfoAmount4P1X !== '' ? parseFloat(formData.otherInfoAmount4P1X) : 510,
        otherInfoAmount4P1Y: formData.otherInfoAmount4P1Y && formData.otherInfoAmount4P1Y !== '' ? parseFloat(formData.otherInfoAmount4P1Y) : 610,
        empoyeeSocialInsurance1To3P2X: formData.empoyeeSocialInsurance1To3P2X && formData.empoyeeSocialInsurance1To3P2X !== '' ? parseFloat(formData.empoyeeSocialInsurance1To3P2X) : 316,
        empoyeeSocialInsurance1To3P2Y: formData.empoyeeSocialInsurance1To3P2Y && formData.empoyeeSocialInsurance1To3P2Y !== '' ? parseFloat(formData.empoyeeSocialInsurance1To3P2Y) : 640,
        empoyeeSocialInsurance4To6P2X: formData.empoyeeSocialInsurance4To6P2X && formData.empoyeeSocialInsurance4To6P2X !== '' ? parseFloat(formData.empoyeeSocialInsurance4To6P2X) : 350,
        empoyeeSocialInsurance4To6P2Y: formData.empoyeeSocialInsurance4To6P2Y && formData.empoyeeSocialInsurance4To6P2Y !== '' ? parseFloat(formData.empoyeeSocialInsurance4To6P2Y) : 640,
        empoyeeSocialInsurance7To9P2X: formData.empoyeeSocialInsurance7To9P2X && formData.empoyeeSocialInsurance7To9P2X !== '' ? parseFloat(formData.empoyeeSocialInsurance7To9P2X) : 380,
        empoyeeSocialInsurance7To9P2Y: formData.empoyeeSocialInsurance7To9P2Y && formData.empoyeeSocialInsurance7To9P2Y !== '' ? parseFloat(formData.empoyeeSocialInsurance7To9P2Y) : 640,
        resouceFirstNameP1X: formData.resouceFirstNameP1X && formData.resouceFirstNameP1X !== '' ? parseFloat(formData.resouceFirstNameP1X) : 40,
        resouceFirstNameP1Y: formData.resouceFirstNameP1Y && formData.resouceFirstNameP1Y !== '' ? parseFloat(formData.resouceFirstNameP1Y) : 552,
        resouceLastNameP1X: formData.resouceLastNameP1X && formData.resouceLastNameP1X !== '' ? parseFloat(formData.resouceLastNameP1X) : 40,
        resouceLastNameP1Y: formData.resouceLastNameP1Y && formData.resouceLastNameP1Y !== '' ? parseFloat(formData.resouceLastNameP1Y) : 527,
        resouceAddressLine1P1X: formData.resouceAddressLine1P1X && formData.resouceAddressLine1P1X !== '' ? parseFloat(formData.resouceAddressLine1P1X) : 40,
        resouceAddressLine1P1Y: formData.resouceAddressLine1P1Y && formData.resouceAddressLine1P1Y !== '' ? parseFloat(formData.resouceAddressLine1P1Y) : 503,
        resouceAddressLine2P1X: formData.resouceAddressLine2P1X && formData.resouceAddressLine2P1X !== '' ? parseFloat(formData.resouceAddressLine2P1X) : 40,
        resouceAddressLine2P1Y: formData.resouceAddressLine2P1Y && formData.resouceAddressLine2P1Y !== '' ? parseFloat(formData.resouceAddressLine2P1Y) : 480,
        resouceCityP1X: formData.resouceCityP1X && formData.resouceCityP1X !== '' ? parseFloat(formData.resouceCityP1X) : 40,
        resouceCityP1Y: formData.resouceCityP1Y && formData.resouceCityP1Y !== '' ? parseFloat(formData.resouceCityP1Y) : 457,
        resourceProvinceCodeP1X: formData.resourceProvinceCodeP1X && formData.resourceProvinceCodeP1X !== '' ? parseFloat(formData.resourceProvinceCodeP1X) : 40,
        resourceProvinceCodeP1Y: formData.resourceProvinceCodeP1Y && formData.resourceProvinceCodeP1Y !== '' ? parseFloat(formData.resourceProvinceCodeP1Y) : 430.05,
        resourcePostalCodeFirstThreeP1X: formData.resourcePostalCodeFirstThreeP1X && formData.resourcePostalCodeFirstThreeP1X !== '' ? parseFloat(formData.resourcePostalCodeFirstThreeP1X) : 80,
        resourcePostalCodeFirstThreeP1Y: formData.resourcePostalCodeFirstThreeP1Y && formData.resourcePostalCodeFirstThreeP1Y !== '' ? parseFloat(formData.resourcePostalCodeFirstThreeP1Y) : 430.5,
        resourcePostalCodeLastThreeP1X: formData.resourcePostalCodeLastThreeP1X && formData.resourcePostalCodeLastThreeP1X !== '' ? parseFloat(formData.resourcePostalCodeLastThreeP1X) : 116,
        resourcePostalCodeLastThreeP1Y: formData.resourcePostalCodeLastThreeP1Y && formData.resourcePostalCodeLastThreeP1Y !== '' ? parseFloat(formData.resourcePostalCodeLastThreeP1Y) : 430.5,
        clientNameLine1P1X: formData.clientNameLine1P1X && formData.clientNameLine1P1X !== '' ? parseFloat(formData.clientNameLine1P1X) : 335,
        clientNameLine1P1Y: formData.clientNameLine1P1Y && formData.clientNameLine1P1Y !== '' ? parseFloat(formData.clientNameLine1P1Y) : 527,
        clientNameLine2P1X: formData.clientNameLine2P1X && formData.clientNameLine2P1X !== '' ? parseFloat(formData.clientNameLine2P1X) : 335,
        clientNameLine2P1Y: formData.clientNameLine2P1Y && formData.clientNameLine2P1Y !== '' ? parseFloat(formData.clientNameLine2P1Y) : 503,
        clientAddressLine1P1X: formData.clientAddressLine1P1X && formData.clientAddressLine1P1X !== '' ? parseFloat(formData.clientAddressLine1P1X) : 335,
        clientAddressLine1P1Y: formData.clientAddressLine1P1Y && formData.clientAddressLine1P1Y !== '' ? parseFloat(formData.clientAddressLine1P1Y) : 458,
        clientAddressLine2P1X: formData.clientAddressLine2P1X && formData.clientAddressLine2P1X !== '' ? parseFloat(formData.clientAddressLine2P1X) : 335,
        clientAddressLine2P1Y: formData.clientAddressLine2P1Y && formData.clientAddressLine2P1Y !== '' ? parseFloat(formData.clientAddressLine2P1Y) : 457,
        clientCityP1X: formData.clientCityP1X && formData.clientCityP1X !== '' ? parseFloat(formData.clientCityP1X) : 335,
        clientCityP1Y: formData.clientCityP1Y && formData.clientCityP1Y !== '' ? parseFloat(formData.clientCityP1Y) : 407,
        clientProvinceCodeP1X: formData.clientProvinceCodeP1X && formData.clientProvinceCodeP1X !== '' ? parseFloat(formData.clientProvinceCodeP1X) : 335,
        clientProvinceCodeP1Y: formData.clientProvinceCodeP1Y && formData.clientProvinceCodeP1Y !== '' ? parseFloat(formData.clientProvinceCodeP1Y) : 383,
        clientPostalCodeFirstThreeP1X: formData.clientPostalCodeFirstThreeP1X && formData.clientPostalCodeFirstThreeP1X !== '' ? parseFloat(formData.clientPostalCodeFirstThreeP1X) : 375,
        clientPostalCodeFirstThreeP1Y: formData.clientPostalCodeFirstThreeP1Y && formData.clientPostalCodeFirstThreeP1Y !== '' ? parseFloat(formData.clientPostalCodeFirstThreeP1Y) : 383,
        clientPostalCodeLastThreeP1X: formData.clientPostalCodeLastThreeP1X && formData.clientPostalCodeLastThreeP1X !== '' ? parseFloat(formData.clientPostalCodeLastThreeP1X) : 415,
        clientPostalCodeLastThreeP1Y: formData.clientPostalCodeLastThreeP1Y && formData.clientPostalCodeLastThreeP1Y !== '' ? parseFloat(formData.clientPostalCodeLastThreeP1Y) : 383.5,
        clientSocialInsurance1To3P2X: formData.clientSocialInsurance1To3P2X && formData.clientSocialInsurance1To3P2X !== '' ? parseFloat(formData.clientSocialInsurance1To3P2X) : 335,
        clientSocialInsurance1To3P2Y: formData.clientSocialInsurance1To3P2Y && formData.clientSocialInsurance1To3P2Y !== '' ? parseFloat(formData.clientSocialInsurance1To3P2Y) : 550,
        clientSocialInsurance4To6P2X: formData.clientSocialInsurance4To6P2X && formData.clientSocialInsurance4To6P2X !== '' ? parseFloat(formData.clientSocialInsurance4To6P2X) : 370,
        clientSocialInsurance4To6P2Y: formData.clientSocialInsurance4To6P2Y && formData.clientSocialInsurance4To6P2Y !== '' ? parseFloat(formData.clientSocialInsurance4To6P2Y) : 550,
        clientSocialInsurance7To9P2X: formData.clientSocialInsurance7To9P2X && formData.clientSocialInsurance7To9P2X !== '' ? parseFloat(formData.clientSocialInsurance7To9P2X) : 420,
        clientSocialInsurance7To9P2Y: formData.clientSocialInsurance7To9P2Y && formData.clientSocialInsurance7To9P2Y !== '' ? parseFloat(formData.clientSocialInsurance7To9P2Y) : 550,
        trustIdentificationNumberP1X: formData.trustIdentificationNumberP1X && formData.trustIdentificationNumberP1X !== '' ? parseFloat(formData.trustIdentificationNumberP1X) : 460,
        trustIdentificationNumberP1Y: formData.trustIdentificationNumberP1Y && formData.trustIdentificationNumberP1Y !== '' ? parseFloat(formData.trustIdentificationNumberP1Y) : 550,
        lastNameOrCompanyNameOfTheSecondHolderX: formData.lastNameOrCompanyNameOfTheSecondHolderX && formData.lastNameOrCompanyNameOfTheSecondHolderX !== '' ? parseFloat(formData.lastNameOrCompanyNameOfTheSecondHolderX) : 40,
        lastNameOrCompanyNameOfTheSecondHolderY: formData.lastNameOrCompanyNameOfTheSecondHolderY && formData.lastNameOrCompanyNameOfTheSecondHolderY !== '' ? parseFloat(formData.lastNameOrCompanyNameOfTheSecondHolderY) : 406,
        firstNameOrCompanyNameOfTheSecondHolderX: formData.firstNameOrCompanyNameOfTheSecondHolderX && formData.firstNameOrCompanyNameOfTheSecondHolderX !== '' ? parseFloat(formData.firstNameOrCompanyNameOfTheSecondHolderX) : 40,
        firstNameOrCompanyNameOfTheSecondHolderY: formData.firstNameOrCompanyNameOfTheSecondHolderY && formData.firstNameOrCompanyNameOfTheSecondHolderY !== '' ? parseFloat(formData.firstNameOrCompanyNameOfTheSecondHolderY) : 385,
        codeDeLaDeviseSlipTypeP1X: formData.codeDeLaDeviseSlipTypeP1X && formData.codeDeLaDeviseSlipTypeP1X !== '' ? parseFloat(formData.codeDeLaDeviseSlipTypeP1X) : 385,
        codeDeLaDeviseSlipTypeP1Y: formData.codeDeLaDeviseSlipTypeP1Y && formData.codeDeLaDeviseSlipTypeP1Y !== '' ? parseFloat(formData.codeDeLaDeviseSlipTypeP1Y) : 740,
        consolidateNumberP1X: formData.consolidateNumberP1X && formData.consolidateNumberP1X !== '' ? parseFloat(formData.consolidateNumberP1X) : 560,
        consolidateNumberP1Y: formData.consolidateNumberP1Y && formData.consolidateNumberP1Y !== '' ? parseFloat(formData.consolidateNumberP1Y) : 640,
        fsP1X: formData.fsP1X && formData.fsP1X !== '' ? parseFloat(formData.fsP1X) : 524,
        fsP1Y: formData.fsP1Y && formData.fsP1Y !== '' ? parseFloat(formData.fsP1Y) : 63,
        rl3202210AboveBoxP1X: formData.rl3202210AboveBoxP1X && formData.rl3202210AboveBoxP1X !== '' ? parseFloat(formData.rl3202210AboveBoxP1X) : 510,
        rl3202210AboveBoxP1Y: formData.rl3202210AboveBoxP1Y && formData.rl3202210AboveBoxP1Y !== '' ? parseFloat(formData.rl3202210AboveBoxP1Y) : 749.5,
        rl3202210DownBoxP1X: formData.rl3202210DownBoxP1X && formData.rl3202210DownBoxP1X !== '' ? parseFloat(formData.rl3202210DownBoxP1X) : 510,
        rl3202210DownBoxP1Y: formData.rl3202210DownBoxP1Y && formData.rl3202210DownBoxP1Y !== '' ? parseFloat(formData.rl3202210DownBoxP1Y) : 737,
        trustNameLine1P1X: formData.trustNameLine1P1X && formData.trustNameLine1P1X !== '' ? parseFloat(formData.trustNameLine1P1X) : 335,
        trustNameLine1P1Y: formData.trustNameLine1P1Y && formData.trustNameLine1P1Y !== '' ? parseFloat(formData.trustNameLine1P1Y) : 527,
        taxYearP2X: formData.taxYearP2X && formData.taxYearP2X !== '' ? parseFloat(formData.taxYearP2X) : 324,
        taxYearP2Y: formData.taxYearP2Y && formData.taxYearP2Y !== '' ? parseFloat(formData.taxYearP2Y) : 740,
        statementCodeP2X: formData.statementCodeP2X && formData.statementCodeP2X !== '' ? parseFloat(formData.statementCodeP2X) : 300,
        statementCodeP2Y: formData.statementCodeP2Y && formData.statementCodeP2Y !== '' ? parseFloat(formData.statementCodeP2Y) : 740,
        numberOfLastStatementSentP2X: formData.numberOfLastStatementSentP2X && formData.numberOfLastStatementSentP2X !== '' ? parseFloat(formData.numberOfLastStatementSentP2X) : 390,
        numberOfLastStatementSentP2Y: formData.numberOfLastStatementSentP2Y && formData.numberOfLastStatementSentP2Y !== '' ? parseFloat(formData.numberOfLastStatementSentP2Y) : 740,
        boxAP2X: formData.boxAP2X && formData.boxAP2X !== '' ? parseFloat(formData.boxAP2X) : 45,
        boxAP2Y: formData.boxAP2Y && formData.boxAP2Y !== '' ? parseFloat(formData.boxAP2Y) : 700,
        boxBP2X: formData.boxBP2X && formData.boxBP2X !== '' ? parseFloat(formData.boxBP2X) : 160,
        boxBP2Y: formData.boxBP2Y && formData.boxBP2Y !== '' ? parseFloat(formData.boxBP2Y) : 700,
        boxC1P2X: formData.boxC1P2X && formData.boxC1P2X !== '' ? parseFloat(formData.boxC1P2X) : 250,
        boxC1P2Y: formData.boxC1P2Y && formData.boxC1P2Y !== '' ? parseFloat(formData.boxC1P2Y) : 700,
        boxC2P2X: formData.boxC2P2X && formData.boxC2P2X !== '' ? parseFloat(formData.boxC2P2X) : 350,
        boxC2P2Y: formData.boxC2P2Y && formData.boxC2P2Y !== '' ? parseFloat(formData.boxC2P2Y) : 700,
        boxDP2X: formData.boxDP2X && formData.boxDP2X !== '' ? parseFloat(formData.boxDP2X) : 450,
        boxDP2Y: formData.boxDP2Y && formData.boxDP2Y !== '' ? parseFloat(formData.boxDP2Y) : 700,
        boxEP2X: formData.boxEP2X && formData.boxEP2X !== '' ? parseFloat(formData.boxEP2X) : 530,
        boxEP2Y: formData.boxEP2Y && formData.boxEP2Y !== '' ? parseFloat(formData.boxEP2Y) : 700,
        boxFP2X: formData.boxFP2X && formData.boxFP2X !== '' ? parseFloat(formData.boxFP2X) : 45,
        boxFP2Y: formData.boxFP2Y && formData.boxFP2Y !== '' ? parseFloat(formData.boxFP2Y) : 670,
        boxGP2X: formData.boxGP2X && formData.boxGP2X !== '' ? parseFloat(formData.boxGP2X) : 160,
        boxGP2Y: formData.boxGP2Y && formData.boxGP2Y !== '' ? parseFloat(formData.boxGP2Y) : 670,
        boxHP2X: formData.boxHP2X && formData.boxHP2X !== '' ? parseFloat(formData.boxHP2X) : 250,
        boxHP2Y: formData.boxHP2Y && formData.boxHP2Y !== '' ? parseFloat(formData.boxHP2Y) : 670,
        boxIP2X: formData.boxIP2X && formData.boxIP2X !== '' ? parseFloat(formData.boxIP2X) : 320,
        boxIP2Y: formData.boxIP2Y && formData.boxIP2Y !== '' ? parseFloat(formData.boxIP2Y) : 670,
        boxJP2X: formData.boxJP2X && formData.boxJP2X !== '' ? parseFloat(formData.boxJP2X) : 450,
        boxJP2Y: formData.boxJP2Y && formData.boxJP2Y !== '' ? parseFloat(formData.boxJP2Y) : 670,
        boxKP2X: formData.boxKP2X && formData.boxKP2X !== '' ? parseFloat(formData.boxKP2X) : 530,
        boxKP2Y: formData.boxKP2Y && formData.boxKP2Y !== '' ? parseFloat(formData.boxKP2Y) : 670,
        boxLP2X: formData.boxLP2X && formData.boxLP2X !== '' ? parseFloat(formData.boxLP2X) : 45,
        boxLP2Y: formData.boxLP2Y && formData.boxLP2Y !== '' ? parseFloat(formData.boxLP2Y) : 640,
        boxMP2X: formData.boxMP2X && formData.boxMP2X !== '' ? parseFloat(formData.boxMP2X) : 160,
        boxMP2Y: formData.boxMP2Y && formData.boxMP2Y !== '' ? parseFloat(formData.boxMP2Y) : 640,
        boxNP2X: formData.boxNP2X && formData.boxNP2X !== '' ? parseFloat(formData.boxNP2X) : 250,
        boxNP2Y: formData.boxNP2Y && formData.boxNP2Y !== '' ? parseFloat(formData.boxNP2Y) : 640,
        otherInfoCode1P2X: formData.otherInfoCode1P2X && formData.otherInfoCode1P2X !== '' ? parseFloat(formData.otherInfoCode1P2X) : 35,
        otherInfoCode1P2Y: formData.otherInfoCode1P2Y && formData.otherInfoCode1P2Y !== '' ? parseFloat(formData.otherInfoCode1P2Y) : 610,
        otherInfoAmount1P2X: formData.otherInfoAmount1P2X && formData.otherInfoAmount1P2X !== '' ? parseFloat(formData.otherInfoAmount1P2X) : 80,
        otherInfoAmount1P2Y: formData.otherInfoAmount1P2Y && formData.otherInfoAmount1P2Y !== '' ? parseFloat(formData.otherInfoAmount1P2Y) : 610,
        otherInfoCode2P2X: formData.otherInfoCode2P2X && formData.otherInfoCode2P2X !== '' ? parseFloat(formData.otherInfoCode2P2X) : 180,
        otherInfoCode2P2Y: formData.otherInfoCode2P2Y && formData.otherInfoCode2P2Y !== '' ? parseFloat(formData.otherInfoCode2P2Y) : 610,
        otherInfoAmount2P2X: formData.otherInfoAmount2P2X && formData.otherInfoAmount2P2X !== '' ? parseFloat(formData.otherInfoAmount2P2X) : 230,
        otherInfoAmount2P2Y: formData.otherInfoAmount2P2Y && formData.otherInfoAmount2P2Y !== '' ? parseFloat(formData.otherInfoAmount2P2Y) : 610,
        otherInfoCode3P2X: formData.otherInfoCode3P2X && formData.otherInfoCode3P2X !== '' ? parseFloat(formData.otherInfoCode3P2X) : 315,
        otherInfoCode3P2Y: formData.otherInfoCode3P2Y && formData.otherInfoCode3P2Y !== '' ? parseFloat(formData.otherInfoCode3P2Y) : 610,
        otherInfoAmount3P2X: formData.otherInfoAmount3P2X && formData.otherInfoAmount3P2X !== '' ? parseFloat(formData.otherInfoAmount3P2X) : 360,
        otherInfoAmount3P2Y: formData.otherInfoAmount3P2Y && formData.otherInfoAmount3P2Y !== '' ? parseFloat(formData.otherInfoAmount3P2Y) : 610,
        otherInfoCode4P2X: formData.otherInfoCode4P2X && formData.otherInfoCode4P2X !== '' ? parseFloat(formData.otherInfoCode4P2X) : 460,
        otherInfoCode4P2Y: formData.otherInfoCode4P2Y && formData.otherInfoCode4P2Y !== '' ? parseFloat(formData.otherInfoCode4P2Y) : 610,
        otherInfoAmount4P2X: formData.otherInfoAmount4P2X && formData.otherInfoAmount4P2X !== '' ? parseFloat(formData.otherInfoAmount4P2X) : 510,
        otherInfoAmount4P2Y: formData.otherInfoAmount4P2Y && formData.otherInfoAmount4P2Y !== '' ? parseFloat(formData.otherInfoAmount4P2Y) : 610,
        resouceFirstNameP2X: formData.resouceFirstNameP2X && formData.resouceFirstNameP2X !== '' ? parseFloat(formData.resouceFirstNameP2X) : 40,
        resouceFirstNameP2Y: formData.resouceFirstNameP2Y && formData.resouceFirstNameP2Y !== '' ? parseFloat(formData.resouceFirstNameP2Y) : 552,
        resouceLastNameP2X: formData.resouceLastNameP2X && formData.resouceLastNameP2X !== '' ? parseFloat(formData.resouceLastNameP2X) : 40,
        resouceLastNameP2Y: formData.resouceLastNameP2Y && formData.resouceLastNameP2Y !== '' ? parseFloat(formData.resouceLastNameP2Y) : 527,
        resouceAddressLine1P2X: formData.resouceAddressLine1P2X && formData.resouceAddressLine1P2X !== '' ? parseFloat(formData.resouceAddressLine1P2X) : 40,
        resouceAddressLine1P2Y: formData.resouceAddressLine1P2Y && formData.resouceAddressLine1P2Y !== '' ? parseFloat(formData.resouceAddressLine1P2Y) : 503,
        resouceAddressLine2P2X: formData.resouceAddressLine2P2X && formData.resouceAddressLine2P2X !== '' ? parseFloat(formData.resouceAddressLine2P2X) : 40,
        resouceAddressLine2P2Y: formData.resouceAddressLine2P2Y && formData.resouceAddressLine2P2Y !== '' ? parseFloat(formData.resouceAddressLine2P2Y) : 480,
        resouceCityP2X: formData.resouceCityP2X && formData.resouceCityP2X !== '' ? parseFloat(formData.resouceCityP2X) : 40,
        resouceCityP2Y: formData.resouceCityP2Y && formData.resouceCityP2Y !== '' ? parseFloat(formData.resouceCityP2Y) : 457,
        resourceProvinceCodeP2X: formData.resourceProvinceCodeP2X && formData.resourceProvinceCodeP2X !== '' ? parseFloat(formData.resourceProvinceCodeP2X) : 40,
        resourceProvinceCodeP2Y: formData.resourceProvinceCodeP2Y && formData.resourceProvinceCodeP2Y !== '' ? parseFloat(formData.resourceProvinceCodeP2Y) : 430.05,
        resourcePostalCodeFirstThreeP2X: formData.resourcePostalCodeFirstThreeP2X && formData.resourcePostalCodeFirstThreeP2X !== '' ? parseFloat(formData.resourcePostalCodeFirstThreeP2X) : 80,
        resourcePostalCodeFirstThreeP2Y: formData.resourcePostalCodeFirstThreeP2Y && formData.resourcePostalCodeFirstThreeP2Y !== '' ? parseFloat(formData.resourcePostalCodeFirstThreeP2Y) : 430.5,
        resourcePostalCodeLastThreeP2X: formData.resourcePostalCodeLastThreeP2X && formData.resourcePostalCodeLastThreeP2X !== '' ? parseFloat(formData.resourcePostalCodeLastThreeP2X) : 116,
        resourcePostalCodeLastThreeP2Y: formData.resourcePostalCodeLastThreeP2Y && formData.resourcePostalCodeLastThreeP2Y !== '' ? parseFloat(formData.resourcePostalCodeLastThreeP2Y) : 430.5,
        clientNameLine1P2X: formData.clientNameLine1P2X && formData.clientNameLine1P2X !== '' ? parseFloat(formData.clientNameLine1P2X) : 335,
        clientNameLine1P2Y: formData.clientNameLine1P2Y && formData.clientNameLine1P2Y !== '' ? parseFloat(formData.clientNameLine1P2Y) : 527,
        clientNameLine2P2X: formData.clientNameLine2P2X && formData.clientNameLine2P2X !== '' ? parseFloat(formData.clientNameLine2P2X) : 335,
        clientNameLine2P2Y: formData.clientNameLine2P2Y && formData.clientNameLine2P2Y !== '' ? parseFloat(formData.clientNameLine2P2Y) : 503,
        clientAddressLine1P2X: formData.clientAddressLine1P2X && formData.clientAddressLine1P2X !== '' ? parseFloat(formData.clientAddressLine1P2X) : 335,
        clientAddressLine1P2Y: formData.clientAddressLine1P2Y && formData.clientAddressLine1P2Y !== '' ? parseFloat(formData.clientAddressLine1P2Y) : 480,
        clientAddressLine2P2X: formData.clientAddressLine2P2X && formData.clientAddressLine2P2X !== '' ? parseFloat(formData.clientAddressLine2P2X) : 335,
        clientAddressLine2P2Y: formData.clientAddressLine2P2Y && formData.clientAddressLine2P2Y !== '' ? parseFloat(formData.clientAddressLine2P2Y) : 457,
        clientCityP2X: formData.clientCityP2X && formData.clientCityP2X !== '' ? parseFloat(formData.clientCityP2X) : 355,
        clientCityP2Y: formData.clientCityP2Y && formData.clientCityP2Y !== '' ? parseFloat(formData.clientCityP2Y) : 90,
        clientProvinceCodeP2X: formData.clientProvinceCodeP2X && formData.clientProvinceCodeP2X !== '' ? parseFloat(formData.clientProvinceCodeP2X) : 335,
        clientProvinceCodeP2Y: formData.clientProvinceCodeP2Y && formData.clientProvinceCodeP2Y !== '' ? parseFloat(formData.clientProvinceCodeP2Y) : 406.5,
        clientPostalCodeFirstThreeP2X: formData.clientPostalCodeFirstThreeP2X && formData.clientPostalCodeFirstThreeP2X !== '' ? parseFloat(formData.clientPostalCodeFirstThreeP2X) : 375,
        clientPostalCodeFirstThreeP2Y: formData.clientPostalCodeFirstThreeP2Y && formData.clientPostalCodeFirstThreeP2Y !== '' ? parseFloat(formData.clientPostalCodeFirstThreeP2Y) : 406.5,
        clientPostalCodeLastThreeP2X: formData.clientPostalCodeLastThreeP2X && formData.clientPostalCodeLastThreeP2X !== '' ? parseFloat(formData.clientPostalCodeLastThreeP2X) : 415,
        clientPostalCodeLastThreeP2Y: formData.clientPostalCodeLastThreeP2Y && formData.clientPostalCodeLastThreeP2Y !== '' ? parseFloat(formData.clientPostalCodeLastThreeP2Y) : 406.5,
        codeDeLaDeviseSlipTypeP2X: formData.codeDeLaDeviseSlipTypeP2X && formData.codeDeLaDeviseSlipTypeP2X !== '' ? parseFloat(formData.codeDeLaDeviseSlipTypeP2X) : 385,
        codeDeLaDeviseSlipTypeP2Y: formData.codeDeLaDeviseSlipTypeP2Y && formData.codeDeLaDeviseSlipTypeP2Y !== '' ? parseFloat(formData.codeDeLaDeviseSlipTypeP2Y) : 740,
        consolidateNumberP2X: formData.consolidateNumberP2X && formData.consolidateNumberP2X !== '' ? parseFloat(formData.consolidateNumberP2X) : 560,
        consolidateNumberP2Y: formData.consolidateNumberP2Y && formData.consolidateNumberP2Y !== '' ? parseFloat(formData.consolidateNumberP2Y) : 640,
        fsP2X: formData.fsP2X && formData.fsP2X !== '' ? parseFloat(formData.fsP2X) : 524,
        fsP2Y: formData.fsP2Y && formData.fsP2Y !== '' ? parseFloat(formData.fsP2Y) : 63,
        rl3202210AboveBoxP2X: formData.rl3202210AboveBoxP2X && formData.rl3202210AboveBoxP2X !== '' ? parseFloat(formData.rl3202210AboveBoxP2X) : 510,
        rl3202210AboveBoxP2Y: formData.rl3202210AboveBoxP2Y && formData.rl3202210AboveBoxP2Y !== '' ? parseFloat(formData.rl3202210AboveBoxP2Y) : 749.5,
        rl3202210DownBoxP2X: formData.rl3202210DownBoxP2X && formData.rl3202210DownBoxP2X !== '' ? parseFloat(formData.rl3202210DownBoxP2X) : 510,
        rl3202210DownBoxP2Y: formData.rl3202210DownBoxP2Y && formData.rl3202210DownBoxP2Y !== '' ? parseFloat(formData.rl3202210DownBoxP2Y) : 737,
        trustNameLine1P2X: formData.trustNameLine1P2X && formData.trustNameLine1P2X !== '' ? parseFloat(formData.trustNameLine1P2X) : 380,
        trustNameLine1P2Y: formData.trustNameLine1P2Y && formData.trustNameLine1P2Y !== '' ? parseFloat(formData.trustNameLine1P2Y) : 150,
        taxYear: formData.taxYear && formData.taxYear !== '' ? parseFloat(formData.taxYear) : 2024,
        settingFilerName1P1X: formData.settingFilerName1P1X && formData.settingFilerName1P1X !== '' ? parseFloat(formData.settingFilerName1P1X) : 335,
        settingFilerName1P1Y: formData.settingFilerName1P1Y && formData.settingFilerName1P1Y !== '' ? parseFloat(formData.settingFilerName1P1Y) : 478,
        clientAddressP2X: formData.clientAddressP2X && formData.clientAddressP2X !== '' ? parseFloat(formData.clientAddressP2X) : 355,
        clientAddressP2Y: formData.clientAddressP2Y && formData.clientAddressP2Y !== '' ? parseFloat(formData.clientAddressP2Y) : 105,
        clientNameP2X: formData.clientNameP2X && formData.clientNameP2X !== '' ? parseFloat(formData.clientNameP2X) : 355,
        clientNameP2Y: formData.clientNameP2Y && formData.clientNameP2Y !== '' ? parseFloat(formData.clientNameP2Y) : 120,
        resourceAddressP2X: formData.resourceAddressP2X && formData.resourceAddressP2X !== '' ? parseFloat(formData.resourceAddressP2X) : 50,
        resourceAddressP2Y: formData.resourceAddressP2Y && formData.resourceAddressP2Y !== '' ? parseFloat(formData.resourceAddressP2Y) : 102,
        resourceCityP2X: formData.resourceCityP2X && formData.resourceCityP2X !== '' ? parseFloat(formData.resourceCityP2X) : 50,
        resourceCityP2Y: formData.resourceCityP2Y && formData.resourceCityP2Y !== '' ? parseFloat(formData.resourceCityP2Y) : 90,
        resourceFirstLastNameP2X: formData.resourceFirstLastNameP2X && formData.resourceFirstLastNameP2X !== '' ? parseFloat(formData.resourceFirstLastNameP2X) : 50,
        resourceFirstLastNameP2Y: formData.resourceFirstLastNameP2Y && formData.resourceFirstLastNameP2Y !== '' ? parseFloat(formData.resourceFirstLastNameP2Y) : 115,
        typeNameP1X: formData.typeNameP1X && formData.typeNameP1X !== '' ? parseFloat(formData.typeNameP1X) : 80,
        typeNameP1Y: formData.typeNameP1Y && formData.typeNameP1Y !== '' ? parseFloat(formData.typeNameP1Y) : 380,
        typeNameP2X: formData.typeNameP2X && formData.typeNameP2X !== '' ? parseFloat(formData.typeNameP2X) : 80,
        typeNameP2Y: formData.typeNameP2Y && formData.typeNameP2Y !== '' ? parseFloat(formData.typeNameP2Y) : 200
      }
      let finalPayloadData = {
        payload:payload,
        taxYear:selectedYear,
        tag:selectedTag
      }
      submitFlexFields(finalPayloadData)
    }
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset();
  };

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: CATEGORY_LIST_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=delete",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);
 
  return (
    <>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} 
        sx={{ width: "100%", pb: "5rem", "& .MuiAppBar-root":{zIndex:2,top:"65px"}, paddingTop: "2rem" }}>
    <BackdropCircularProgress
        open={
          isSubmitting || 
          isLoading ||
          isLoadingYearsList ||
          isLoadingPDFAxis
        }
        testId={`pdf-Axis-backdrop`}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <TopRowTwoColumnLayout
            toolbar={
            <PDFAxisToolbar
                onResetClicked={onResetClicked}
                isSubmitting={false}
            />
            }
            topRowContent={
              <StandardPanel
                title="Select Year"
                testId={`pdf-Axis-year-list`}
                leftSpacing={0}
                rightSpacing={0}
              >
                 <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{alignItems:"center"}}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{padding:"0 3rem 0 3rem"}}>
                
                <EnhancedDropDownRHFWithLabel
                  selectName="tag"
                  label="Select Tag"
                  labelPosition={LabelPosition.Left}
                  items={tagList}
                  testId={`pdf-Axis-tags`}
                  selectedItem={selectedTag}
                  onSelectionChangeHandler={onTagSelectionChangeHandler}
                />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{padding:"0 3rem 0 3rem"}}>
                
                {/* <EnhancedDropDownRHFWithLabel
                  selectName="year"
                  label="Select Year"
                  labelPosition={LabelPosition.Left}
                  items={yearsDDLList ? yearsDDLItems : []}
                  testId={`pdf-Axis-id`}
                  onSelectionChangeHandler={onYearChangeHandler}
                  selectedItem={selectedYear}
                  required
                /> */}
                <div style={{width:"100%",display:"flex",alignItems:"center"}}>
                  <div style={{width:"33%",fontSize:"small",color:"rgba(0, 0, 0, 0.6)",paddingBottom:"6.5px"}}>
                    Select Year
                  </div>
                  <div style={{width:"60%"}}>
                    <EnhancedDropDown
                      selectName={"year"}
                      items={yearsDDLList ? yearsDDLItems : []}
                      onChangeHandler={onYearChangeHandler}
                      testId={"year-ddl"}
                      selectedItem={selectedYear}
                      />
                  </div>
                </div>
                    </Grid>
                 </Grid>
              </StandardPanel>
            }
            leftColumnContent={
                <>
                    {
                        selectedTag === 't4' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><T4SLipAxisPage1 taxYear={selectedYear}/></StandardPanel> : 
                        selectedTag === 't4a' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><T4ASLipAxisPage1 /></StandardPanel> :
                        selectedTag === 't5' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><T5SLipAxisPage1 /></StandardPanel> :
                        selectedTag === 't3' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><T3SLipAxisPage1 /></StandardPanel> :
                        selectedTag === 'rl1' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><RL1SLipAxisPage1 taxYear={selectedYear} /></StandardPanel> :
                        selectedTag === 'rl3' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><RL3SLipAxisPage1 /></StandardPanel> :
                        selectedTag === 'rl16' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><RL16SLipAxisPage1 /></StandardPanel> :
                        selectedTag === 'rl1SummaryEnglish' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><RL1SummaryEnglishAxisPages1 /></StandardPanel> :
                        selectedTag === 'rl1SummaryFrench' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><RL1SummaryFrenchAxisPages1 /></StandardPanel> :
                        selectedTag === 'invoice' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><InvoiceAxisPages1 /></StandardPanel> :
                        selectedTag === 't4Summary' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><T4SummaryAxisPages1 taxYear={selectedYear}/></StandardPanel> :
                        selectedTag === 't5Summary' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><T5SummaryAxisPages1 /></StandardPanel> :
                        selectedTag === 't4aSummary' ? 
                        <StandardPanel
                          title="Page 1"
                          testId={`pdf-Axis`}
                        ><T4ASummaryAxisPages1 /></StandardPanel> :
                        selectedTag === 'rl3Summary' ? 
                        // <StandardPanel
                        //   title="Page 1"
                        //   testId={`pdf-Axis`}
                        // >
                        //   <RL3SummaryAxisPages1 />
                        //   </StandardPanel> 
                          <h1>RL-3 Summary Axis not create yet!</h1> :
                        <></> 
                    }
                  {
                  selectedTag === 'rl1SummaryEnglish' ? 
                  <StandardPanel
                    title="Page 3"
                    testId={`pdf-Axis`}
                    topPadding={2}
                  >
                    <RL1SummaryEnglishAxisPages3 /> :
                  </StandardPanel> : 
                  selectedTag === 'rl1SummaryFrench' ? 
                  <StandardPanel
                    title="Page 3"
                    testId={`pdf-Axis`}
                    topPadding={2}
                  >
                    <RL1SummaryFrenchAxisPages3 /> :
                  </StandardPanel> : 
                  selectedTag === 't5' ? 
                  <StandardPanel
                    title="Page 3"
                    testId={`pdf-Axis`}
                    topPadding={2}
                  >
                    <T5SLipAxisPage3 /> :
                  </StandardPanel> : 
                  <></>}
                </>
            }
            rightColumnContent={
                <>
                  
                  {
                      selectedTag === 't4' ? 
                      <StandardPanel
                        title="Page 2"
                        testId={`pdf-Axis`}
                      ><T4SLipAxisPage2 taxYear={selectedYear}/></StandardPanel> : 
                      selectedTag === 't4a' ? 
                      <StandardPanel
                        title="Page 2"
                        testId={`pdf-Axis`}
                      ><T4ASLipAxisPage2 /></StandardPanel> :
                      selectedTag === 't5' ? 
                      <StandardPanel
                        title="Page 2"
                        testId={`pdf-Axis`}
                      ><T5SLipAxisPage2 /></StandardPanel> :
                      selectedTag === 't3' ? 
                      <StandardPanel
                        title="Page 2"
                        testId={`pdf-Axis`}
                      ><T3SLipAxisPage2 /></StandardPanel> :
                      selectedTag === 'rl1' ? 
                      <StandardPanel
                        title="Page 2"
                        testId={`pdf-Axis`}
                      ><RL1SLipAxisPage2 taxYear={selectedYear} /></StandardPanel> :
                      selectedTag === 'rl3' ? 
                      <StandardPanel
                        title="Page 2"
                        testId={`pdf-Axis`}
                      ><RL3SLipAxisPage2 /></StandardPanel> :
                      selectedTag === 'rl16' ? 
                      <StandardPanel
                        title="Page 2"
                        testId={`pdf-Axis`}
                      ><RL16SLipAxisPage2 /></StandardPanel> :
                      selectedTag === 'rl1SummaryEnglish' ? 
                      <StandardPanel
                        title="Page 2"
                        testId={`pdf-Axis`}
                      ><RL1SummaryEnglishAxisPages2 /></StandardPanel> :
                      selectedTag === 'rl1SummaryFrench' ? 
                      <StandardPanel
                        title="Page 2"
                        testId={`pdf-Axis`}
                      ><RL1SummaryFrenchAxisPages2 /></StandardPanel> :
                      <></> 
                  }
                </>
            }
            testId="global-variables-setup-form"
          />
        </form>
      </FormProvider>
    </Grid>
     <EnhancedSnackbar
     message={snackbarData.message}
     onCloseHandler={onSnackbarClosed}
     severity={snackbarData.severity}
     testId={"category-list-tab-list-snackbar"}
     open={snackbarData.open}
   />
   </>
  );
};
