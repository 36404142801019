import { AuthService } from "services/AuthService";
import { call, put } from "redux-saga/effects";
import { ISignInResponse } from "models";
import { signInSuccess } from "store/actions";
import { LocalStorageService } from "services/LocalStorageService";

export function* login(action: any) {
  try {
    const loginResponse: ISignInResponse = yield call(
      AuthService.signIn,
      action.payload
    );

    if (loginResponse.status) {
      LocalStorageService.setUser(loginResponse);
      yield put(signInSuccess(loginResponse));
    }

    console.log("login response =>", loginResponse.user.firstName);
  } catch (err) {
    console.log("err =>", err);
  }
}
