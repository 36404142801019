import { AxiosResponse } from 'axios';
import http from 'common/http-common';
import { IDataRoleRequestPayload, IDataRoleResponsePayload, IGetListRequest, IResponse, IServerError } from 'models';
import { IFilingResourceListModel } from 'models/filingResource';
import { IFilingResourceRequestPayload } from 'models/filingResource/IFilingResourceRequestPayload';
import { IFilingResourceResponsePayload } from 'models/filingResource/IFilingResourceResponsePayload';
// import { FILLING_RESOURCE_SETUP_FORM } from 'screensLayoutData/fillingResource';

let clientIdNew = "";
export const FilingResourceService = {
  create: async (
    requestPayload: IFilingResourceRequestPayload
  ): Promise<IFilingResourceResponsePayload | IServerError | IResponse> => {
    if (requestPayload.id) {
      console.log('requestPayload ==>> update request id', requestPayload.id);
      const response: AxiosResponse<IFilingResourceResponsePayload> | any  = await http.put<IFilingResourceResponsePayload>(
        `/v1/filing-resources/${requestPayload.id}/`,requestPayload
        // "/v1/filing-resources/",
        // requestPayload
        );

      if (response.status === 201) {
        return response;
      }else if (response.status === 200) {
        return response;
      }else if (response.status === 400) {
        return response;
      }else if (response.status === 404) {
        return response;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }

    // console.log("requestPayload ==>> add request", requestPayload.role);
    // if(requestPayload.role){

    //   }
    const response: AxiosResponse<IFilingResourceResponsePayload> | any =
      await http.post<IFilingResourceResponsePayload>(
        "/v1/filing-resources/",
        requestPayload
      );

    if (response.status === 201) {
      return response;
    } else if (response.status === 400) {
      return response;
    }else if (response.status === 404) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getList: async (
    clientId: string,
    params: IGetListRequest
  ): Promise<
  IFilingResourceListModel | IServerError | IResponse
  > => {
    // const fixedResponse: IFilingResourceListModel = {
    //   "page": 1,
    //   "size": 5,
    //   "total": 2,
    //   "items": [
    //     {
    //       "id": "ab8ccece-16c1-44e9-ba86-2990eefb178c",
    //       "name": "Babar Javaid",
    //       "selectedRoles": "Contact Person, Issuer, Transmitter",
    //       "appliedYear": 2020
    //     },
    //     {
    //       "id": "4e3823aa-c2e4-45aa-aaaf-9ba1b1486acc",
    //       "name": "Bilal Khan",
    //       "selectedRoles": "Contact Person, Transmitter",
    //       "appliedYear": 2020
    //     }
    //   ],
    //   "keyField": {
    //     "field": "id",
    //     "templateUrl": "/filing-resources/setup/id"
    //   },
    //   "headerColumns": [
    //     {
    //       "field": "name",
    //       "headerName": "Name",
    //       "description": "Name",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       // "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       // "isFixed": true,
    //       "sequence": 0
    //     },
    //     {
    //       "field": "selectedRoles",
    //       "headerName": "Role(s)",
    //       "description": "Role(s) Assigned",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       // "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       // "displayMode": "Chip",
    //       // "chipColors": ["#0057db", "#36b37e", "#e0e0e0"],
    //       // "isFixed": true,
    //       "sequence": 1
    //     },
    //     {
    //       "field": "appliedYear",
    //       "headerName": "Applied from Year",
    //       "description": "Applied from Year",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       // "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       // "isFixed": true,
    //       "sequence": 2
    //     }
    //   ]
    // }
    // return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    console.log("params in filling resourse service ===== ",params)
    console.log("params.page ============================================== ",params.page)
    const response: AxiosResponse<IFilingResourceListModel> = await http.get<IFilingResourceListModel>(
      `/v1/filing-resources/?client_id=${clientId}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);

    if (response.status === 200) {
      console.log("response.data filling services",response.data)
      clientIdNew = clientId;
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  delFillResource: async (fillResourceId: number): Promise<string | IServerError | IResponse> => {
    console.log('deleteById', fillResourceId);
    const response: AxiosResponse<string> = await http.delete<string>(`/v1/filing-resources/${fillResourceId}/?client_id=${clientIdNew}`);

    if (response.status === 200) {
      return "Filling Profile has been deleted successfully";
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  // getSetupLayout: async (): Promise<any> => {
  //   let response: any = FILLING_RESOURCE_SETUP_FORM;
  //   return new Promise(resolve => setTimeout(() => resolve(response), 1000));
  // },

  getById: async (
    id: string | undefined,
    clientId:string
  ): Promise<IFilingResourceListModel | IServerError | IResponse | null | any> => {
    if (!id) return null;
    const response: AxiosResponse<IFilingResourceListModel> = await http.get<IFilingResourceListModel>(
      `/v1/filing-resources/${id}/?client_id=${clientId}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

};
