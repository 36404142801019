import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import { FC } from "react";

export interface IT4ASlipDetailPanel {
  formMethods: any;
}

export const T4ASlipDetailPanel: FC<IT4ASlipDetailPanel> = ({formMethods}) => {
  const handleInputChange = (e: any) => {
    const newValue = e.target.value;
    console.log("newValue", newValue);
    // if(newValue !== ''){
      const incomeTaxDeducted = formMethods.getValues("incomeTaxDeducted")
      console.log("incomeTaxDeductedValue", incomeTaxDeducted);
      const minus = incomeTaxDeducted - newValue
      formMethods.setValue("difference", Math.abs(minus).toFixed(2));
      if(minus < 0){
        // console.log("Minus value ",Math.abs(minus))
        formMethods.setValue("overpayment", Math.abs(minus).toFixed(2));
        formMethods.setValue("balanceDue", '0.00');
      } else {
        // console.log("positive value ",minus)
        formMethods.setValue("balanceDue", minus.toFixed(2));
        formMethods.setValue("overpayment", '0.00');
      }

    // }
  };
  return (
    <>
      <TaxSlipSummaryDataInput
        id="totalNumberOfT4aSlipsFiled"
        label="Total number of T4A slips filed"
        boxNumber="088"
        textfield={true}
      />
      <TaxSlipSummaryDataInput
        id="pensionOrSuperannuation"
        label="Pension Or Superannuation"
        boxNumber="016"
      />
      <TaxSlipSummaryDataInput
        id="lumpSumPayments"
        label="Lump-Sum Payments"
        boxNumber="018"
      />
      <TaxSlipSummaryDataInput
        id="selfEmployedCommissions"
        label="Self-Employed Commissions"
        boxNumber="020"
      />
      <TaxSlipSummaryDataInput
        id="annuities"
        label="Annuities"
        boxNumber="024"
      />
      <TaxSlipSummaryDataInput
        id="otherIncome"
        label="Other Income"
        boxNumber="028"
      />
      <TaxSlipSummaryDataInput
        id="patronageAllocations"
        label="Patronage Allocations"
        boxNumber="030"
      />
      <TaxSlipSummaryDataInput
        id="registeredPensionPlanRppContributionsPastService"
        label="Registered Pension Plan (RPP) Contributions"
        boxNumber="032"
      />
      <TaxSlipSummaryDataInput
        id="pensionAdjustment"
        label="Pension Adjustment"
        boxNumber="034"
      />
      <TaxSlipSummaryDataInput
        id="respAccumulatedIncomePayments"
        label="RESP Accumulated Income Payments"
        boxNumber="040"
      />
      <TaxSlipSummaryDataInput
        id="respEducationalAssistancePayments"
        label="RESP Educational Assistance Payments"
        boxNumber="042"
      />
      <TaxSlipSummaryDataInput id="fees" label="Fees" boxNumber="048" />
      <TaxSlipSummaryDataInput
        id="otherInformation"
        label="Other Information"
        boxNumber="101"
      />
      <TaxSlipSummaryDataInput
        id="incomeTaxDeducted"
        label="Income Tax Deducted"
        boxNumber="022"
      />
      <TaxSlipSummaryDataInput
        id="remittance"
        label="Remittance"
        boxNumber="082"
        readOnly={false}
        onChange={handleInputChange}
      />
    </>
  );
};
