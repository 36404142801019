import { ISignInRequest, ISignInResponse } from "models";
import { LOGIN } from "../types";

export const signIn = (signInRequest: ISignInRequest) => {
  return {
    type: LOGIN.REQUEST,
    payload: { ...signInRequest },
  };
};

export const signInSuccess = (signInResponse: ISignInResponse) => {
  return {
    type: LOGIN.SUCCESS,
    payload: { ...signInResponse },
  };
};
