import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { T4AdjustmentReport } from "components/Adjustments/T4AdjustmentReport";

export const T4AdjustmentReportPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T4 Adjustment Report"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<T4AdjustmentReport/>}
    />
  );
};
