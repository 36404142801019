import { FC } from "react";

import { Grid, Typography } from "@mui/material";

import { TaxSlipDataInput } from "../../TaxSlipDataInput";
import {
  IOtherInformationSetupFieldsPanel,
  OtherInformationSetupFieldsPanel,
} from "./OtherInformationSetupFieldsPanel";

export interface IT4ASlipDataPanel extends IOtherInformationSetupFieldsPanel {}

export const T4ASlipDataPanel: FC<IT4ASlipDataPanel> = ({
  formMethods,
  otherInformationList,
  isLoading,
  testId,
  selectedOtherInformation,
  oiInEdit,
}) => {
  return (
    <>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}></Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
        <Grid
          item
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Actual
          </Typography>
        </Grid>
      </Grid>
      <TaxSlipDataInput
        id="pensionOrSuperannuation"
        label="Pension Or Superannuation"
        boxNumber="016"
      />
      <TaxSlipDataInput
        id="lumpSumPayments"
        label="Lump-Sum Payments"
        boxNumber="018"
        alert={true}
        alertTitle = 'Sum of other info boxes: 102, 108, 110, 180, 190'
        titlePlacement = 'top'
      />
      <TaxSlipDataInput
        id="lumpSumPaymentsOther"
        label="Lump-Sum Payments (Other)"
        boxNumber="18"
        alert={true}
        alertTitle = 'Other applicable amounts'
      />
      <TaxSlipDataInput
        id="selfEmployedCommissions"
        label="Self-Employed Commissions"
        boxNumber="020"
      />
      <TaxSlipDataInput
        id="incomeTaxDeducted"
        label="Income Tax Deducted"
        boxNumber="022"
      />
      <TaxSlipDataInput 
        id="annuities" 
        label="Annuities" 
        boxNumber="024"
        alert={true}
        alertTitle = 'Sum of other information boxes: 111, 115'
        />
      <TaxSlipDataInput
        id="annuitiesOther"
        label="Annuities (Other)"
        boxNumber="026"
      />
      <TaxSlipDataInput id="fees" label="Fees" boxNumber="048" />
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ mt: 2, p: 4, backgroundColor: "#f4f5f7" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold", pb: 1 }}>
            Other Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OtherInformationSetupFieldsPanel
            formMethods={formMethods}
            isLoading={isLoading}
            otherInformationList={otherInformationList}
            testId={testId}
            selectedOtherInformation={selectedOtherInformation}
            oiInEdit={oiInEdit}
          />
        </Grid>
      </Grid>
    </>
  );
};
