import { HOME_PAGE } from "store/types";

export interface IHomePage {
  path: string
}

const initialState: IHomePage = {
  path:"/"
};

export const homePageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HOME_PAGE.HOME_PAGE_SET_SELECTED:
      console.log("staet",state)
      return {
        ...state,
        path: action.payload.path,
      }
    case HOME_PAGE.HOME_PAGE_CLEAR_SELECTED:
      return {
        ...state,
        path: initialState.path,
      }
    default:
      return state;
    }
};
