import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearSelectedAccountant, clearSelectedAccountantName, clearSelectedClient, clearSelectedClientName, clearSelectedINVOICE, clearSelectedTaxYear } from "store/actions";

export const ClientListRoutesWithAccComponent = () => {
const navigate = useNavigate();
const dispatch = useDispatch();

const clearClientId = () => {
  dispatch(clearSelectedClient());
};
const clearClientName = () => {
  dispatch(clearSelectedClientName());
};
const clearAccountantId = () => {
  dispatch(clearSelectedAccountant());
};
const clearAccountantName = () => {
  dispatch(clearSelectedAccountantName());
};
const clearTaxYear = () => {
  dispatch(clearSelectedTaxYear());
};
const clearRefNo = () => {
  dispatch(clearSelectedINVOICE());
};
const ArrayData = [
  {
    label: "Home",
    icon: <HomeOutlinedIcon />,
    items: [
      {
        label: "Client List",
        command: () => {
            navigate("/clients")
            clearClientId();
            clearClientName();
            clearTaxYear();
            clearRefNo();
        },
      },
      {
        label: "Client Setup",
        command: () => {
            navigate("/clients/setup")
        },
      },
      {
        label: "Accountant List",
        command: () => {
            navigate("/accountants")
            clearAccountantId();
            clearAccountantName();
            clearClientId();
            clearClientName();
            clearTaxYear();
            clearRefNo();
        },
      },
    ],
  },
  {
    label: "Logout",
    icon: <LogoutOutlinedIcon />,
    command: () => {
        navigate("/logout")
    },
  },
];
return {
    ClientListRoutesWithAcc:ArrayData
  }
  }