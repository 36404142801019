import {
  GET_QUERY_KEY,
  GLOBAL_VARIABLES_SCREEN_SLUG,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  MINIMUM_TAX_YEAR,
  QUERY_OPTIONS,
} from "common/constants";
import { IGlobalVariablesModel } from "models/settings";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { TaxSlipYearService } from "services";
import { TaxSlipSettingsService } from "services/TaxSlipSettingsService";

import { yupResolver } from "@hookform/resolvers/yup";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import {
  BackdropCircularProgress,
  EnhancedDropDown,
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  SubmitButton,
  SubmitButtonVariant,
  TopRowTwoColumnLayout,
} from "@websential/cosmic";

import {
  CPPSettingsPanel,
  EISettingsNonQubecPanel,
  EISettingsQubecPanel,
  QPIPAndPPIPSettingsPanel,
  QPPSettingsPanel,
  RL1SettingsPanel,
} from "./Panels";
import { GlobalVariablesValidationSchema } from "./validator";
import { IGetListRequest } from "models";
import { HSFPrimaryPanel } from "./Panels/HSFPrimaryPanel";
import { HSFPanel } from "./Panels/HSFPanel";
import { Grid } from "@mui/material";
import { T5VariablesPanel } from "./Panels/T5VariablesPanel";
import { RL3VariablesPanel } from "./Panels/RL3VariablesPanel";
import { RL16VariablesPanel } from "./Panels/RL16VariablesPanel";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch } from "react-redux";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { T3VariablesPanel } from "./Panels/T3VariablesPanel";

export interface ITaxSlipsGlobalSettings extends ITest {}

const useGetGlobalVariables = (taxYear: number) =>
  useQuery(
    [GET_QUERY_KEY.GET_GLOBAL_VARIABLES_BY_YEAR],
    (data) => {
      return TaxSlipSettingsService.getGlobalVariables(taxYear);
    },
    { refetchOnWindowFocus: false }
  );

const useGetYearList = () =>
  useQuery(
    GET_QUERY_KEY.YEAR_DDL,
    () => TaxSlipYearService.getListDDL(),
    QUERY_OPTIONS
  );

//years list from API
const useYears = (params: IGetListRequest) =>
  useQuery(
    ["years-list", params],
    (data) => {
      return TaxSlipYearService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

export const TaxSlipsGlobalSettings: FC<ITaxSlipsGlobalSettings> = ({
  testId,
}) => {
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [selectedYear, setSelectedYear] = useState<any>(undefined);
  // const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    TaxSlipSettingsService.submitGlobalVariables,
    {
      onSuccess: (res: any) => {
        console.log("resres",res)
        if (res.status === 200) {
        setSnackbarData({
          open: true,
          message: res.message,
          severity: "success",
        });
      }else {
        if (res) {
          setSnackbarData({
            open: true,
            message:
              res &&
              res.message !== undefined &&
              typeof (res.message && res.message) !== "object"
                ? res.message && res.message
                : res.message.message
                ? res.message.message
                : "Something went wrong!",
            severity:
              res.status === false
                ? "error"
                : res.success === false
                ? "error"
                : "success",
          });
        }
      }
    },
      onError: (err: any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  const { isLoading: isLoadingYearDDL, data: yearList } = useGetYearList();
  const {
    isLoading: isLoadingGlobalVariables,
    isFetching: reFetchingGlobalVariables,
    refetch: refetchGlobalVariables,
    data: editDataRecord,
  } = useGetGlobalVariables(selectedYear);
  console.log("editDataRecord", editDataRecord);

  //get years list by API
  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "role_name",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useYears(queryParams);
  const YearsGetList: any = data;
  console.log("@@@@@ => YearsGetList", YearsGetList);

  const formMethods = useForm<any>({
    resolver: yupResolver(GlobalVariablesValidationSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    // defaultValues: DEFAULT_VALUES,
  });

  // const { isLoading: isLoadingSelectedFlexField, data: selectedFlexFieldData } =
  //   useGetFlexFieldData(selectedFlexFieldId as number);

  /**
   * Handle when the form is submitted.
   *
   * @param {IGlobalVariablesModel} formData
   */
  const onSubmit = (formData: any) => {
    console.log("formDatak",formData)
    let payload: IGlobalVariablesModel = {
      t4: 
      formData.cppSettingsTotalCppPensionableEarningsMaximum !== '' && formData.cppSettingsTotalCppPensionableEarningsMaximum !== undefined ||
      formData.cppSettingsTotalSecondCppPensionableEarningsMaximum !== '' && formData.cppSettingsTotalSecondCppPensionableEarningsMaximum !== undefined ||
      formData.cppSettingsBasicCppExemptionMaximum !== '' && formData.cppSettingsBasicCppExemptionMaximum !== undefined ||
      formData.cppSettingsRequiredSecondContributionPercentage !== '' && formData.cppSettingsRequiredSecondContributionPercentage !== undefined ||
      formData.cppSettingsRequiredContributionPercentage !== '' && formData.cppSettingsRequiredContributionPercentage !== undefined ||
      formData.eiSettingsTotalEiEarningsMaximum !== '' && formData.eiSettingsTotalEiEarningsMaximum !== undefined ||
      formData.eiSettingsBasicEiExemptionMaximum !== '' && formData.eiSettingsBasicEiExemptionMaximum !== undefined ||
      formData.eiSettingsRequiredContributionPercentage !== '' && formData.eiSettingsRequiredContributionPercentage !== undefined ||
      formData.eiSettingsMaximumContribution !== '' && formData.eiSettingsMaximumContribution !== undefined ?
      {
        cppSettingsTotalCppPensionableEarningsMaximum:
          formData.cppSettingsTotalCppPensionableEarningsMaximum !== '' ? formData.cppSettingsTotalCppPensionableEarningsMaximum : undefined,
        cppSettingsTotalSecondCppPensionableEarningsMaximum: formData.cppSettingsTotalSecondCppPensionableEarningsMaximum !== ''? formData.cppSettingsTotalSecondCppPensionableEarningsMaximum: undefined,
        cppSettingsBasicCppExemptionMaximum:
        formData.cppSettingsBasicCppExemptionMaximum !== ''? formData.cppSettingsBasicCppExemptionMaximum : undefined,
        cppSettingsRequiredSecondContributionPercentage: formData.cppSettingsRequiredSecondContributionPercentage !== '' ? formData.cppSettingsRequiredSecondContributionPercentage: undefined,
        cppSettingsRequiredContributionPercentage:
          formData.cppSettingsRequiredContributionPercentage !==''?formData.cppSettingsRequiredContributionPercentage: undefined,
        eiSettingsTotalEiEarningsMaximum:
          formData.eiSettingsTotalEiEarningsMaximum !='' ? formData.eiSettingsTotalEiEarningsMaximum : undefined,
        eiSettingsBasicEiExemptionMaximum:
          formData.eiSettingsBasicEiExemptionMaximum != ''? formData.eiSettingsBasicEiExemptionMaximum: undefined,
        eiSettingsRequiredContributionPercentage:
          formData.eiSettingsRequiredContributionPercentage !==''? formData.eiSettingsRequiredContributionPercentage: undefined,
        eiSettingsMaximumContribution: formData.eiSettingsMaximumContribution !==''?formData.eiSettingsMaximumContribution: undefined,
      } : undefined,
      rl1: 
        formData.qppSettingsTotalQppPensionableEarningsMaximum !=='' && formData.qppSettingsTotalQppPensionableEarningsMaximum !==undefined ||
        formData.qppSettingsTotalSecondQppPensionableEarningsMaximum !=='' && formData.qppSettingsTotalSecondQppPensionableEarningsMaximum !==undefined ||
        formData.qppSettingsBasicQppExemptionMaximum !=='' && formData.qppSettingsBasicQppExemptionMaximum !==undefined ||
        formData.qppSettingsRequiredSecondContributionPercentage !=='' && formData.qppSettingsRequiredSecondContributionPercentage !==undefined ||
        formData.qppSettingsRequiredContributionPercentage !=='' && formData.qppSettingsRequiredContributionPercentage !==undefined ||
        formData.eiSettingsQubecTotalEiEarningsMaximum !=='' && formData.eiSettingsQubecTotalEiEarningsMaximum !==undefined ||
        formData.eiSettingsQubecBasicEiExemptionMaximum !=='' && formData.eiSettingsQubecBasicEiExemptionMaximum !==undefined ||
        formData.eiSettingsQubecRequiredContributionPercentage !=='' && formData.eiSettingsQubecRequiredContributionPercentage !==undefined ||
        formData.eiSettingsQubecMaximumContribution !=='' && formData.eiSettingsQubecMaximumContribution !==undefined ||
        formData.qpipPpipSettingsTotalInsurableEarningsMaximum !=='' && formData.qpipPpipSettingsTotalInsurableEarningsMaximum !==undefined ||
        formData.qpipPpipSettingsTotalEmployeePremiumMaximum !=='' && formData.qpipPpipSettingsTotalEmployeePremiumMaximum !==undefined ||
        formData.qpipPpipSettingsEmployeePremiumRatePercentage !=='' && formData.qpipPpipSettingsEmployeePremiumRatePercentage !==undefined ||
        formData.qpipPpipSettingsEmployerPremiumRatePercentage !=='' && formData.qpipPpipSettingsEmployerPremiumRatePercentage !==undefined ||
        formData.rl1SettingsCntRatePercentage !=='' && formData.rl1SettingsCntRatePercentage !== undefined ||
        formData.rl1SettingsSecondCntRatePercentage !=='' && formData.rl1SettingsSecondCntRatePercentage !== undefined ||
        formData.rl1SettingsWsdrfRatePercentage !=='' && formData.rl1SettingsWsdrfRatePercentage !==undefined ||
        formData.qhsfPrimaryMin !=='' && formData.qhsfPrimaryMin !==undefined ||
        formData.qhsfPrimaryMax !=='' && formData.qhsfPrimaryMax !==undefined ||
        formData.qhsfPrimaryNormalize !=='' && formData.qhsfPrimaryNormalize !==undefined ||
        formData.qhsfPrimaryValue1 !=='' && formData.qhsfPrimaryValue1 !==undefined ||
        formData.qhsfPrimaryValue2 !=='' && formData.qhsfPrimaryValue2 !==undefined ||
        formData.qhsfPrimaryRate1 !=='' && formData.qhsfPrimaryRate1 !==undefined ||
        formData.qhsfPrimaryRate2 !=='' && formData.qhsfPrimaryRate2 !==undefined ||
        formData.qhsfMin !=='' && formData.qhsfMin !==undefined ||
        formData.qhsfMax !=='' && formData.qhsfMax !==undefined ||
        formData.qhsfNormalize !=='' && formData.qhsfNormalize !==undefined ||
        formData.qhsfValue1 !=='' && formData.qhsfValue1 !==undefined ||
        formData.qhsfValue2 !=='' && formData.qhsfValue2 !==undefined ||
        formData.qhsfRate1 !=='' && formData.qhsfRate1 !==undefined ||
        formData.qhsfRate2 !=='' && formData.qhsfRate2 !==undefined ||
        formData.certificationNoRl1 !=='' && formData.certificationNoRl1 !==undefined ||
        formData.authenticationNoRl1 !=='' && formData.authenticationNoRl1 !==undefined ||
        formData.barcodeInitialRl1 !=='' && formData.barcodeInitialRl1 !==undefined ||
        formData.totalPayrollValue !=='' && formData.totalPayrollValue !==undefined ? 
        {
        qppSettingsTotalQppPensionableEarningsMaximum:
          formData.qppSettingsTotalQppPensionableEarningsMaximum !==''? formData.qppSettingsTotalQppPensionableEarningsMaximum: undefined,
        qppSettingsTotalSecondQppPensionableEarningsMaximum:
          formData.qppSettingsTotalSecondQppPensionableEarningsMaximum !== ''? formData.qppSettingsTotalSecondQppPensionableEarningsMaximum: undefined,
        qppSettingsBasicQppExemptionMaximum:
          formData.qppSettingsBasicQppExemptionMaximum !==''?formData.qppSettingsBasicQppExemptionMaximum: undefined,
        qppSettingsRequiredSecondContributionPercentage:
          formData.qppSettingsRequiredSecondContributionPercentage !==''?formData.qppSettingsRequiredSecondContributionPercentage: undefined,
        qppSettingsRequiredContributionPercentage:
          formData.qppSettingsRequiredContributionPercentage !==''?formData.qppSettingsRequiredContributionPercentage: undefined,
        eiSettingsQubecTotalEiEarningsMaximum:
          formData.eiSettingsQubecTotalEiEarningsMaximum !==''? formData.eiSettingsQubecTotalEiEarningsMaximum: undefined,
        eiSettingsQubecBasicEiExemptionMaximum:
          formData.eiSettingsQubecBasicEiExemptionMaximum!==''? formData.eiSettingsQubecBasicEiExemptionMaximum: undefined,
        eiSettingsQubecRequiredContributionPercentage:
          formData.eiSettingsQubecRequiredContributionPercentage !==''?formData.eiSettingsQubecRequiredContributionPercentage:undefined,
        eiSettingsQubecMaximumContribution:
          formData.eiSettingsQubecMaximumContribution !== ''? formData.eiSettingsQubecMaximumContribution:undefined,
        qpipPpipSettingsTotalInsurableEarningsMaximum:
          formData.qpipPpipSettingsTotalInsurableEarningsMaximum !==''?formData.qpipPpipSettingsTotalInsurableEarningsMaximum: undefined,
        qpipPpipSettingsTotalEmployeePremiumMaximum:
          formData.qpipPpipSettingsTotalEmployeePremiumMaximum !==''? formData.qpipPpipSettingsTotalEmployeePremiumMaximum: undefined,
        qpipPpipSettingsEmployeePremiumRatePercentage:
          formData.qpipPpipSettingsEmployeePremiumRatePercentage !==''? formData.qpipPpipSettingsEmployeePremiumRatePercentage: undefined,
        qpipPpipSettingsEmployerPremiumRatePercentage:
          formData.qpipPpipSettingsEmployerPremiumRatePercentage !==''?formData.qpipPpipSettingsEmployerPremiumRatePercentage : undefined,
        rl1SettingsCntRatePercentage: formData.rl1SettingsCntRatePercentage !==''?formData.rl1SettingsCntRatePercentage: undefined,
        rl1SettingsSecondCntRatePercentage: formData.rl1SettingsSecondCntRatePercentage !==''?formData.rl1SettingsSecondCntRatePercentage: undefined,
        rl1SettingsWsdrfRatePercentage: formData.rl1SettingsWsdrfRatePercentage !==''? formData.rl1SettingsWsdrfRatePercentage: undefined,

        qhsfPrimaryMin: formData.qhsfPrimaryMin !==''?formData.qhsfPrimaryMin:undefined,
        qhsfPrimaryMax: formData.qhsfPrimaryMax !==''? formData.qhsfPrimaryMax:undefined,
        qhsfPrimaryNormalize: formData.qhsfPrimaryNormalize !==''?formData.qhsfPrimaryNormalize:undefined,
        qhsfPrimaryValue1: formData.qhsfPrimaryValue1 !==''? formData.qhsfPrimaryValue1:undefined,
        qhsfPrimaryValue2: formData.qhsfPrimaryValue2 !==''?formData.qhsfPrimaryValue2:undefined,
        qhsfPrimaryRate1: formData.qhsfPrimaryRate1!==''?formData.qhsfPrimaryRate1:undefined,
        qhsfPrimaryRate2: formData.qhsfPrimaryRate2 !==''? formData.qhsfPrimaryRate2:undefined,
        qhsfMin: formData.qhsfMin !==''?formData.qhsfMin:undefined,
        qhsfMax: formData.qhsfMax !==''?formData.qhsfMax:undefined,
        qhsfNormalize: formData.qhsfNormalize !==''? formData.qhsfNormalize:undefined,
        qhsfValue1: formData.qhsfValue1 !==''?formData.qhsfValue1:undefined,
        qhsfValue2: formData.qhsfValue2!==''?formData.qhsfValue2:undefined,
        qhsfRate1: formData.qhsfRate1!==''?formData.qhsfRate1:undefined,
        qhsfRate2: formData.qhsfRate2!==''?formData.qhsfRate2:undefined,
        totalPayrollValue: formData.totalPayrollValue !==''?formData.totalPayrollValue:undefined,
        certificationNoRl1: formData.certificationNoRl1 !==''?formData.certificationNoRl1:undefined,
        barcodeInitialRl1: formData.barcodeInitialRl1 !==''?formData.barcodeInitialRl1:undefined,
        authenticationNoRl1:
        formData.authenticationNoRl1 && formData.authenticationNoRl1 !== "" && selectedYear > 2022 ? formData.authenticationNoRl1 :
        formData.authenticationNoRl1 && formData.authenticationNoRl1 !== "" 
          ? `FS ${formData.authenticationNoRl1}` : undefined,
      } : undefined,
      t5:
      formData.eligibleDividendTaxCreditPercentageOfTaxableAmount!=='' && formData.eligibleDividendTaxCreditPercentageOfTaxableAmount!== undefined ||
      formData.eligibleGrossUpFactor!==''  && formData.eligibleGrossUpFactor!== undefined || 
      formData.nonEligibleGrossUpFactor!=='' && formData.nonEligibleGrossUpFactor!== undefined ||
      formData.nonEligibleDividendTaxCreditPercentageOfTaxableAmount!=='' && formData.nonEligibleDividendTaxCreditPercentageOfTaxableAmount!==undefined ? 
      {
        eligibleDividendTaxCreditPercentageOfTaxableAmount:
          formData.eligibleDividendTaxCreditPercentageOfTaxableAmount!==''?formData.eligibleDividendTaxCreditPercentageOfTaxableAmount:undefined,
        eligibleGrossUpFactor:
          formData.eligibleGrossUpFactor!==''?formData.eligibleGrossUpFactor:undefined,
        nonEligibleDividendTaxCreditPercentageOfTaxableAmount:
          formData.nonEligibleDividendTaxCreditPercentageOfTaxableAmount!==''?formData.nonEligibleDividendTaxCreditPercentageOfTaxableAmount:undefined,
        nonEligibleGrossUpFactor:
          formData.nonEligibleGrossUpFactor !==''?formData.nonEligibleGrossUpFactor:undefined,
      } : undefined,
      t3:
      formData.eligibleDividendTaxCreditPercentageOfTaxableAmountT3!=='' && formData.eligibleDividendTaxCreditPercentageOfTaxableAmountT3!== undefined ||
      formData.eligibleGrossUpFactorT3!==''  && formData.eligibleGrossUpFactorT3!== undefined || 
      formData.nonEligibleGrossUpFactorT3!=='' && formData.nonEligibleGrossUpFactorT3!== undefined ||
      formData.nonEligibleDividendTaxCreditPercentageOfTaxableAmountT3!=='' && formData.nonEligibleDividendTaxCreditPercentageOfTaxableAmountT3!==undefined ? 
      {
        eligibleDividendTaxCreditPercentageOfTaxableAmountT3:
          formData.eligibleDividendTaxCreditPercentageOfTaxableAmountT3!==''?formData.eligibleDividendTaxCreditPercentageOfTaxableAmountT3:undefined,
        eligibleGrossUpFactorT3:
          formData.eligibleGrossUpFactorT3!==''?formData.eligibleGrossUpFactorT3:undefined,
        nonEligibleDividendTaxCreditPercentageOfTaxableAmountT3:
          formData.nonEligibleDividendTaxCreditPercentageOfTaxableAmountT3!==''?formData.nonEligibleDividendTaxCreditPercentageOfTaxableAmountT3:undefined,
        nonEligibleGrossUpFactorT3:
          formData.nonEligibleGrossUpFactorT3 !==''?formData.nonEligibleGrossUpFactorT3:undefined,
      } : undefined,
      rl3: 
      formData.eligibleDividends !=='' && formData.eligibleDividends !== undefined ||
      formData.ordinaryDividends !=='' && formData.ordinaryDividends !== undefined ||
      formData.certificationNoRl3 !=='' && formData.certificationNoRl3 !== undefined ||
      formData.barcodeInitialRl3 !=='' && formData.barcodeInitialRl3 !== undefined ||
      formData.authenticationNoRl3 !=='' && formData.authenticationNoRl3 !== undefined ||
      formData.quebecIncomeTaxWithholding !=='' && formData.quebecIncomeTaxWithholding !== undefined ?
      {
        eligibleDividends:
          formData.eligibleDividends !==''?formData.eligibleDividends:undefined,
        ordinaryDividends:
          formData.ordinaryDividends !==''?formData.ordinaryDividends:undefined,
        quebecIncomeTaxWithholding:
          formData.quebecIncomeTaxWithholding !==''?formData.quebecIncomeTaxWithholding:undefined,
          authenticationNoRl3:
          formData.authenticationNoRl3 && formData.authenticationNoRl3 !== "" && selectedYear > 2022 ? formData.authenticationNoRl3 :
          formData.authenticationNoRl3 && formData.authenticationNoRl3 !== "" 
            ? `FS ${formData.authenticationNoRl3}` : undefined,
        certificationNoRl3:
          formData.certificationNoRl3 !==''?formData.certificationNoRl3:undefined,
          barcodeInitialRl3:
          formData.barcodeInitialRl3 !==''?formData.barcodeInitialRl3:undefined,
      }:undefined,
      rl16: 
      formData.eligibleDividendsRL16 !=='' && formData.eligibleDividendsRL16 !== undefined ||
      formData.ordinaryDividendsRL16 !=='' && formData.ordinaryDividendsRL16 !== undefined ||
      formData.certificationNoRl16 !=='' && formData.certificationNoRl16 !== undefined ||
      formData.barcodeInitialRl16 !=='' && formData.barcodeInitialRl16 !== undefined ||
      formData.authenticationNoRl16 !=='' && formData.authenticationNoRl16 !== undefined ||
      formData.quebecIncomeTaxWithholdingRL16 !=='' && formData.quebecIncomeTaxWithholdingRL16 !== undefined ?
      {
        eligibleDividendsRL16:
          formData.eligibleDividendsRL16 !==''?formData.eligibleDividendsRL16:undefined,
        ordinaryDividendsRL16:
          formData.ordinaryDividendsRL16 !==''?formData.ordinaryDividendsRL16:undefined,
        quebecIncomeTaxWithholdingRL16:
          formData.quebecIncomeTaxWithholdingRL16 !==''?formData.quebecIncomeTaxWithholdingRL16:undefined,
        certificationNoRl16:
          formData.certificationNoRl16 !==''?formData.certificationNoRl16:undefined,
          barcodeInitialRl16:
          formData.barcodeInitialRl16 !==''?formData.barcodeInitialRl16:undefined,
          authenticationNoRl16:formData.authenticationNoRl16 && formData.authenticationNoRl16 !== "" && selectedYear > 2022 ? formData.authenticationNoRl16 :
          formData.authenticationNoRl16 && formData.authenticationNoRl16 !== "" 
            ? `FS ${formData.authenticationNoRl16}` : undefined,
      }:undefined,
      taxYear: selectedYear,
    };
    console.log('payloadpayload',payload)
    submitFormData(payload);
  };

  // const onResetClicked = () => {
  //   setSnackbarData({
  //     open: true,
  //     message: "Form was reset successfully",
  //     severity: "success",
  //   });
  //   formMethods.reset(DEFAULT_VALUES);
  // };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const yearItems = useMemo(() => {
    if (YearsGetList) {
      return YearsGetList.items.map((yearItem: any) => ({
        label: String(yearItem.year),
        value: Number(yearItem.year),
      }));
    }
  }, [YearsGetList]);

  useEffect(()=>{
    if(yearItems && yearItems[0]){
      setSelectedYear(yearItems[0].value)
    }
  },[yearItems])

  const onYearSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
    if(Number(item.label) !== selectedYear){
      setSelectedYear(Number(item.label));
      const currentYearSelected = formMethods.getValues("year");
      formMethods.reset();
      formMethods.setValue("year", currentYearSelected);
    }
  };
  console.log("yearrrrrrrr", formMethods.getValues("year"));

  useEffect(() => {
    if (selectedYear >= MINIMUM_TAX_YEAR) {
      refetchGlobalVariables();
    }
  }, [refetchGlobalVariables, selectedYear]);

  useEffect(() => {
    console.log("===-use Effect called");
    // if the selected flex field data has loaded.
    if (editDataRecord) {
      if (editDataRecord.success && editDataRecord.data) {
        let globalVariables = {
          ...editDataRecord.data.t4,
          ...editDataRecord.data.rl1,
          ...editDataRecord.data.t5,
          ...editDataRecord.data.t3,
          ...editDataRecord.data.rl3,
          ...editDataRecord.data.rl16,
        };
        console.log("===globalVariables", globalVariables);
        Object.keys(globalVariables).forEach((fieldName) => {
          // @ts-ignore
          const fieldValue = globalVariables[fieldName];
          // @ts-ignore
          formMethods.setValue(fieldName, fieldValue ?? undefined);
        });
      } else {
        setSnackbarData({
          open: true,
          message: editDataRecord?.data?.message,
          severity: "error",
        });
      }
    }
  }, [editDataRecord, formMethods.setValue, formMethods]);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: GLOBAL_VARIABLES_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={
          isLoading ||
          isSubmitting ||
          isLoadingYearDDL ||
          isLoadingGlobalVariables ||
          reFetchingGlobalVariables
        }
        testId={testId}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <TopRowTwoColumnLayout
            toolbar={
              <StandardSubmitFormButtonsToolbar
                toolbarContents={
                  <SubmitButton
                    label={"Save"}
                    testId="save-button"
                    variant={SubmitButtonVariant.Secondary}
                    icon={<PlaylistAddCheckOutlinedIcon />}
                  />
                }
                // onResetClicked={onResetClicked}
                isSubmitting={false}
                testId="flex-fields-panel-assignment-toolbar"
              />
            }
            topRowContent={
              <StandardPanel
                title="Select Year"
                testId={`${testId}-year-list`}
                leftSpacing={4}
                rightSpacing={4}
              >
                {/* <EnhancedDropDownRHFWithLabel
                  selectName="year"
                  label="Select Year"
                  labelPosition={LabelPosition.Left}
                  items={yearItems ? yearItems : []}
                  testId={`${testId}-id`}
                  selectedItem={selectedYear}
                  onSelectionChangeHandler={onYearSelectionChangeHandler}
                /> */}
                <div style={{width:"100%",display:"flex",alignItems:"center"}}>
                  <div style={{width:"33%",fontSize:"small",color:"rgba(0, 0, 0, 0.6)",paddingBottom:"6.5px"}}>
                    Select Year
                  </div>
                  <div style={{width:"60%"}}>
                    <EnhancedDropDown
                      selectName={"year"}
                      items={yearItems ? yearItems : []}
                      onChangeHandler={onYearSelectionChangeHandler}
                      testId={"year-ddl"}
                      selectedItem={selectedYear}
                      />
                  </div>
                </div>
              </StandardPanel>
            }
            leftColumnContent={
              selectedYear > 0 && (
                <>
                  <StandardPanel
                    title="CPP Settings (Non Quebec)"
                    testId={`${testId}-cpp-settings`}
                  >
                    <CPPSettingsPanel testId={testId} selectedYear={selectedYear}/>
                  </StandardPanel>
                  <StandardPanel
                    title="EI Settings (Non Quebec)"
                    testId={`${testId}-ei-settings`}
                    topPadding={2}
                  >
                    <EISettingsNonQubecPanel testId={testId} />
                  </StandardPanel>
                  {/*  */}
                  <StandardPanel
                    title="HSF"
                    testId={`${testId}-hsf-panel`}
                    topPadding={2}
                  >
                    <HSFPanel testId={testId} />
                  </StandardPanel>
                  <StandardPanel
                    title="QPIP/PPIP Settings"
                    testId={`${testId}-custom-fields`}
                    topPadding={2}
                  >
                    <QPIPAndPPIPSettingsPanel testId={testId} />
                  </StandardPanel>
                  <StandardPanel
                    title="T5 Variables"
                    testId={`${testId}-custom-fields`}
                    topPadding={2}
                  >
                    <T5VariablesPanel testId={testId} />
                  </StandardPanel>
                  <StandardPanel
                    title="T3 Variables"
                    testId={`${testId}-custom-fields`}
                    topPadding={2}
                  >
                    <T3VariablesPanel testId={testId} />
                  </StandardPanel>
                </>
              )
            }
            rightColumnContent={
              selectedYear > 0 && (
                <>
                  <StandardPanel
                    title="QPP Settings"
                    testId={`${testId}-custom-fields`}
                  >
                    <QPPSettingsPanel testId={testId} selectedYear={selectedYear}/>
                  </StandardPanel>
                  <StandardPanel
                    title="EI Settings (Quebec)"
                    testId={`${testId}-custom-fields`}
                    topPadding={2}
                  >
                    <EISettingsQubecPanel testId={testId} />
                  </StandardPanel>
                  <StandardPanel
                    title="HSF Primary"
                    testId={`${testId}-hsf-primary`}
                    topPadding={2}
                  >
                    <HSFPrimaryPanel testId={testId} />
                  </StandardPanel>
                  <StandardPanel
                    title="RL-1 Settings"
                    testId={`${testId}-custom-fields`}
                    topPadding={2}
                  >
                    <RL1SettingsPanel testId={testId} />
                  </StandardPanel>
                  <StandardPanel
                    title="RL-3 Variables"
                    testId={`${testId}-custom-fields`}
                    topPadding={2}
                  >
                    <RL3VariablesPanel testId={testId} />
                  </StandardPanel>
                  <StandardPanel
                    title="RL-16 Variables"
                    testId={`${testId}-custom-fields`}
                    topPadding={2}
                  >
                    <RL16VariablesPanel testId={testId} />
                  </StandardPanel>
                </>
              )
            }
            testId="global-variables-setup-form"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
