import { IResponse, IServerError } from "@websential/cosmic";
import { AxiosResponse } from "axios";
import http from "common/http-common";

export const GlobalSearchServices = {
    getDesiredData: async (requestPayload: any): Promise<any | IServerError | IResponse> => {
         
        console.log('requestPayload ==>> add request', requestPayload);
        const response: AxiosResponse<any> = await http.post<any>(
          '/v1/accountants/accountants/global-search', requestPayload);
    
        if (response.status === 200) {
            return response.data;
        }
        else if (response.status === 500) {
          return {
            status: false,
            message: "Something went wrong.",
            error: true,
          };
        }
    
        return {
          status: false,
          message: "Something went wrong please try again later.",
        };
      },
}