import { AxiosError, AxiosResponse } from 'axios';
import http from 'common/http-common';
import { IDataModelResponsePayload, IGlobalVariablesModel } from 'models';

const API_BASE_RESOURCE_URL = '/v1/taxslip-definitions';

export const TaxSlipSettingsService = {
  submitGlobalVariables: async (requestPayload: IGlobalVariablesModel): Promise<IDataModelResponsePayload<IGlobalVariablesModel>> => {
    console.log('@@@@@=>requestPayload', requestPayload);
    let taxYear = requestPayload.taxYear;
    try {
    const response: AxiosResponse<IGlobalVariablesModel> | any = await http.put<IGlobalVariablesModel>(
      `${API_BASE_RESOURCE_URL}/global-variables/?tax_year=${taxYear}`, requestPayload);
    console.log("response11",response)

    if (response.status === 200) return { success: true, message: "Record has been updated successfully", data: response }
    if (response.status === 400) return { success: false, data: response }
    if (response.status === 404) {
      return response;
    }
    else if (response.status === 500) {
      return {
        success: false, message: "Something went wrong please try again later." 
      };
    } else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      success: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }

    // return { success: false, message: "Something went wrong please try again later." };
  },
  getGlobalVariables: async (taxYear: number): Promise<IDataModelResponsePayload<IGlobalVariablesModel> | null | any> => {
    // const response: AxiosResponse<IGlobalVariablesModel> = await http.get<IGlobalVariablesModel>(
    //   `${API_BASE_RESOURCE_URL}/global-variables/?tax_year=${taxYear}`);

    // if (response.status === 200) return { success: true, data: response.data }

    // return { success: false, data: response.data }

    //new code
    if(taxYear){
      try {
        const response: AxiosResponse<IGlobalVariablesModel> = await http.get<IGlobalVariablesModel>(
          `${API_BASE_RESOURCE_URL}/global-variables/?tax_year=${taxYear}`);
        console.log("response", response.data);
    
        if (response.status === 200) {
          return { success: true, data: response.data };
        } else {
          console.log("Response status:", response.status);
          return { success: false, data: response.data };
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.error("An error occurred:", axiosError);
        return { success: false, data: axiosError.response ? axiosError.response.data : "Unknown error" };
      }
    }
    }
};
