import { CSV_INFORMATION_UI } from "store/types/CSVInformationUITypes";

export const setSelectedCSVInformationUI = (selectCSVInformationUI: any) => {
  return {
    type: CSV_INFORMATION_UI.CSV_INFORMATION_UI_SET_SELECTED,
    payload: {...selectCSVInformationUI}
  }
}

export const clearSelectedCSVInformationUI = () => {
  return {
    type: CSV_INFORMATION_UI.CSV_INFORMATION_UI_CLEAR_SELECTED
  }
}
