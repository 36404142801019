import { ITaxYear } from "store/reducers/taxYearReducers";
import { TAX_YEAR } from "../types";

export const setSelectedTaxYear = (selectTaxYear: any) => {
  return {
    type: TAX_YEAR.TAX_YEAR_SET_SELECTED,
    payload: {...selectTaxYear}
  }
}

export const clearSelectedTaxYear = () => {
  return {
    type: TAX_YEAR.TAX_YEAR_CLEAR_SELECTED
  }
}
