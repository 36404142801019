import { T3SlipSettingsSetup } from "components/T3Slip/T3SlipSettingsSetup";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT3SlipSettingsSetupPage {}

export const T3SlipSettingsSetupPage: FC<IT3SlipSettingsSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T3 Slip Settings"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t3/list" },
        { text: "T3", navigateTo: "/taxslip/t3/list" },
        { text: "T3 Slips Settings", navigateTo: "/taxslip/t3/setting" },
      ]}
      mainContents={<T3SlipSettingsSetup testId="T3-slip-settings-setup" />}
    />
  );
};
