import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IT4SLipAxisPage1 {
    taxYear:number
}
const T4SLipAxisPage1:React.FC<IT4SLipAxisPage1> = ({taxYear}) => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'slipTypeP1X'} 
                testId={'slipTypeP1X'} 
                label={'Slip Type'} 
                labelPosition={LabelPosition.Left}
                defaultValue={412}
            />
            <RHookFormTextFieldWithLabel 
                id={'taxYearP1X'} 
                testId={'taxYearP1X'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={308}
            />
            <RHookFormTextFieldWithLabel 
                id={'pipInsurableEarningsP1X'} 
                testId={'pipInsurableEarningsP1X'} 
                label={'PIP Insurable Earnings'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'rppOrDpspRegistrationNumberP1X'} 
                testId={'rppOrDpspRegistrationNumberP1X'} 
                label={'Rpp/Dpsp Registration Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'charitableDonationsP1X'} 
                testId={'charitableDonationsP1X'} 
                label={'Charitable Donations'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'unionDuesP1X'} 
                testId={'unionDuesP1X'} 
                label={'Union Dues'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'cppQppPensionableEarningsP1X'} 
                testId={'cppQppPensionableEarningsP1X'} 
                label={'cppQpp Pensionable Earnings'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'eiInsurableEarningsP1X'} 
                testId={'eiInsurableEarningsP1X'} 
                label={'Ei Insurable Earnings'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'incomeTaxDeductedP1X'} 
                testId={'incomeTaxDeductedP1X'} 
                label={'Income Tax Deducted'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'ppipPremiumsP1X'} 
                testId={'ppipPremiumsP1X'} 
                label={'Ppip Premiums'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            <RHookFormTextFieldWithLabel 
                id={'pensionAdjustmentFacteurP1X'} 
                testId={'pensionAdjustmentFacteurP1X'} 
                label={'Pension Adjustment Facteur'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            <RHookFormTextFieldWithLabel 
                id={'rppContributionsP1X'} 
                testId={'rppContributionsP1X'} 
                label={'Rpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            <RHookFormTextFieldWithLabel 
                id={'eiPremiumsP1X'} 
                testId={'eiPremiumsP1X'} 
                label={'Ei Premiums'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            <RHookFormTextFieldWithLabel 
                id={'qppContributionsP1X'} 
                testId={'qppContributionsP1X'} 
                label={'Qpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'secondQppContributionsP1X'} 
                testId={'secondQppContributionsP1X'} 
                label={'Second Qpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'cppContributionsP1X'} 
                testId={'cppContributionsP1X'} 
                label={'Cpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'secondCppContributionsP1X'} 
                testId={'secondCppContributionsP1X'} 
                label={'Second Cpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'employmentIncomeP1X'} 
                testId={'employmentIncomeP1X'} 
                label={'Employment Income'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P1X'} 
                testId={'otherInfoAmount1P1X'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={226.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P1X'} 
                testId={'otherInfoCode1P1X'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={111}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P1X'} 
                testId={'otherInfoAmount2P1X'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P1X'} 
                testId={'otherInfoCode2P1X'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={274.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P1X'} 
                testId={'otherInfoAmount3P1X'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P1X'} 
                testId={'otherInfoCode3P1X'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={438}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P1X'} 
                testId={'otherInfoAmount4P1X'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={226.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P1X'} 
                testId={'otherInfoCode4P1X'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={111}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount5P1X'} 
                testId={'otherInfoAmount5P1X'} 
                label={'Other Info Amount 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode5P1X'} 
                testId={'otherInfoCode5P1X'} 
                label={'Other Info Code 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={274.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount6P1X'} 
                testId={'otherInfoAmount6P1X'} 
                label={'Other Info Amount 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode6P1X'} 
                testId={'otherInfoCode6P1X'} 
                label={'Other Info Code 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={438}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceCityAddressP1X'} 
                testId={'resourceCityAddressP1X'} 
                label={'Resource City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={50}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceAddressP1X'} 
                testId={'resourceAddressP1X'} 
                label={'Resource Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={50}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersCityAddressP1X'} 
                testId={'employersCityAddressP1X'} 
                label={'Employers City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={50}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersAddressP1X'} 
                testId={'employersAddressP1X'} 
                label={'Employers Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={50}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersNameP1X'} 
                testId={'employersNameP1X'} 
                label={'Employers Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={50}
            />
            <RHookFormTextFieldWithLabel 
                id={'initialsP1X'} 
                testId={'initialsP1X'} 
                label={'Initials'} 
                labelPosition={LabelPosition.Left}
                defaultValue={300}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceLastNameP1X'} 
                testId={'resourceLastNameP1X'} 
                label={'Resource Last Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={200}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceFirstNameP1X'} 
                testId={'resourceFirstNameP1X'} 
                label={'Resource First Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={72}
            />
            <RHookFormTextFieldWithLabel 
                id={'employeeAccountNoP1X'} 
                testId={'employeeAccountNoP1X'} 
                label={'Employee Account No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={90}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsuranceNumberP1X'} 
                testId={'socialInsuranceNumberP1X'} 
                label={'Social Insurance Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={65}
            />
            <RHookFormTextFieldWithLabel 
                id={'cppExemptP1X'} 
                testId={'cppExemptP1X'} 
                label={'Cpp Exempt'} 
                labelPosition={LabelPosition.Left}
                defaultValue={255}
            />
            <RHookFormTextFieldWithLabel 
                id={'eiExemptP1X'} 
                testId={'eiExemptP1X'} 
                label={'Ei Exempt'} 
                labelPosition={LabelPosition.Left}
                defaultValue={233}
            />
            <RHookFormTextFieldWithLabel 
                id={'ppipExemptP1X'} 
                testId={'ppipExemptP1X'} 
                label={'Ppip Exempt'} 
                labelPosition={LabelPosition.Left}
                defaultValue={211}
            />
            <RHookFormTextFieldWithLabel 
                id={'provinceOfEmployementShortCodeP1X'} 
                testId={'provinceOfEmployementShortCodeP1X'} 
                label={'Province Of Employement ShortCode'} 
                labelPosition={LabelPosition.Left}
                defaultValue={295}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerOfferedDentalBenefitsP1X'} 
                testId={'employerOfferedDentalBenefitsP1X'} 
                label={'Employer Offered Dental Benefits'} 
                labelPosition={LabelPosition.Left}
                defaultValue={295}
            />
            <RHookFormTextFieldWithLabel 
                id={'employmentCodeP1X'} 
                testId={'employmentCodeP1X'} 
                label={'Employment Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={296}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'slipTypeP1Y'} 
                testId={'slipTypeP1Y'} 
                label={'Slip Type'} 
                labelPosition={LabelPosition.Left}
                defaultValue={761}
            />
            <RHookFormTextFieldWithLabel 
                id={'taxYearP1Y'} 
                testId={'taxYearP1Y'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={743}
            />
            <RHookFormTextFieldWithLabel 
                id={'pipInsurableEarningsP1Y'} 
                testId={'pipInsurableEarningsP1Y'} 
                label={'PIP Insurable Earnings'} 
                labelPosition={LabelPosition.Left}
                defaultValue={488}
            />
            <RHookFormTextFieldWithLabel 
                id={'rppOrDpspRegistrationNumberP1Y'} 
                testId={'rppOrDpspRegistrationNumberP1Y'} 
                label={'Rpp/Dpsp Registration Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={524}
            />
            <RHookFormTextFieldWithLabel 
                id={'charitableDonationsP1Y'} 
                testId={'charitableDonationsP1Y'} 
                label={'Charitable Donations'} 
                labelPosition={LabelPosition.Left}
                defaultValue={560}
            />
            <RHookFormTextFieldWithLabel 
                id={'unionDuesP1Y'} 
                testId={'unionDuesP1Y'} 
                label={'Union Dues'} 
                labelPosition={LabelPosition.Left}
                defaultValue={596}
            />
            <RHookFormTextFieldWithLabel 
                id={'cppQppPensionableEarningsP1Y'} 
                testId={'cppQppPensionableEarningsP1Y'} 
                label={'cppQpp Pensionable Earnings'} 
                labelPosition={LabelPosition.Left}
                defaultValue={632}
            />
            <RHookFormTextFieldWithLabel 
                id={'eiInsurableEarningsP1Y'} 
                testId={'eiInsurableEarningsP1Y'} 
                label={'Ei Insurable Earnings'} 
                labelPosition={LabelPosition.Left}
                defaultValue={668}
            />
            <RHookFormTextFieldWithLabel 
                id={'incomeTaxDeductedP1Y'} 
                testId={'incomeTaxDeductedP1Y'} 
                label={'Income Tax Deducted'} 
                labelPosition={LabelPosition.Left}
                defaultValue={704}
            />
            <RHookFormTextFieldWithLabel 
                id={'ppipPremiumsP1Y'} 
                testId={'ppipPremiumsP1Y'} 
                label={'Ppip Premiums'} 
                labelPosition={LabelPosition.Left}
                defaultValue={488}
            />
            <RHookFormTextFieldWithLabel 
                id={'pensionAdjustmentFacteurP1Y'} 
                testId={'pensionAdjustmentFacteurP1Y'} 
                label={'Pension Adjustment Facteur'} 
                labelPosition={LabelPosition.Left}
                defaultValue={524}
            />
            <RHookFormTextFieldWithLabel 
                id={'rppContributionsP1Y'} 
                testId={'rppContributionsP1Y'} 
                label={'Rpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={560}
            />
            <RHookFormTextFieldWithLabel 
                id={'eiPremiumsP1Y'} 
                testId={'eiPremiumsP1Y'} 
                label={'Ei Premiums'} 
                labelPosition={LabelPosition.Left}
                defaultValue={596}
            />
            <RHookFormTextFieldWithLabel 
                id={'qppContributionsP1Y'} 
                testId={'qppContributionsP1Y'} 
                label={'Qpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={632}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'secondQppContributionsP1Y'} 
                testId={'secondQppContributionsP1Y'} 
                label={'Second Qpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={632}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'cppContributionsP1Y'} 
                testId={'cppContributionsP1Y'} 
                label={'Cpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={668}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'secondCppContributionsP1Y'} 
                testId={'secondCppContributionsP1Y'} 
                label={'Second Cpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={668}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'employmentIncomeP1Y'} 
                testId={'employmentIncomeP1Y'} 
                label={'Employment Income'} 
                labelPosition={LabelPosition.Left}
                defaultValue={704}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P1Y'} 
                testId={'otherInfoAmount1P1Y'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={552}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P1Y'} 
                testId={'otherInfoCode1P1Y'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={552}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P1Y'} 
                testId={'otherInfoAmount2P1Y'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={552}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P1Y'} 
                testId={'otherInfoCode2P1Y'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={552}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P1Y'} 
                testId={'otherInfoAmount3P1Y'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={552}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P1Y'} 
                testId={'otherInfoCode3P1Y'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={552}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P1Y'} 
                testId={'otherInfoAmount4P1Y'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={416}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P1Y'} 
                testId={'otherInfoCode4P1Y'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={416}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount5P1Y'} 
                testId={'otherInfoAmount5P1Y'} 
                label={'Other Info Amount 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={416}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode5P1Y'} 
                testId={'otherInfoCode5P1Y'} 
                label={'Other Info Code 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={416}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount6P1Y'} 
                testId={'otherInfoAmount6P1Y'} 
                label={'Other Info Amount 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={416}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode6P1Y'} 
                testId={'otherInfoCode6P1Y'} 
                label={'Other Info Code 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={416}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceCityAddressP1Y'} 
                testId={'resourceCityAddressP1Y'} 
                label={'Resource City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={520}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceAddressP1Y'} 
                testId={'resourceAddressP1Y'} 
                label={'Resource Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={240}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersCityAddressP1Y'} 
                testId={'employersCityAddressP1Y'} 
                label={'Employers City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={720}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersAddressP1Y'} 
                testId={'employersAddressP1Y'} 
                label={'Employers Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={735}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersNameP1Y'} 
                testId={'employersNameP1Y'} 
                label={'Employers Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={750}
            />
            <RHookFormTextFieldWithLabel 
                id={'initialsP1Y'} 
                testId={'initialsP1Y'} 
                label={'Initials'} 
                labelPosition={LabelPosition.Left}
                defaultValue={581}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceLastNameP1Y'} 
                testId={'resourceLastNameP1Y'} 
                label={'Resource Last Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={581}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceFirstNameP1Y'} 
                testId={'resourceFirstNameP1Y'} 
                label={'Resource First Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={581}
            />
            <RHookFormTextFieldWithLabel 
                id={'employeeAccountNoP1Y'} 
                testId={'employeeAccountNoP1Y'} 
                label={'Employee Account No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={678}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsuranceNumberP1Y'} 
                testId={'socialInsuranceNumberP1Y'} 
                label={'Social Insurance Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={640}
            />
            <RHookFormTextFieldWithLabel 
                id={'cppExemptP1Y'} 
                testId={'cppExemptP1Y'} 
                label={'Cpp Exempt'} 
                labelPosition={LabelPosition.Left}
                defaultValue={640}
            />
            <RHookFormTextFieldWithLabel 
                id={'eiExemptP1Y'} 
                testId={'eiExemptP1Y'} 
                label={'Ei Exempt'} 
                labelPosition={LabelPosition.Left}
                defaultValue={640}
            />
            <RHookFormTextFieldWithLabel 
                id={'ppipExemptP1Y'} 
                testId={'ppipExemptP1Y'} 
                label={'Ppip Exempt'} 
                labelPosition={LabelPosition.Left}
                defaultValue={640}
            />
            <RHookFormTextFieldWithLabel 
                id={'provinceOfEmployementShortCodeP1Y'} 
                testId={'provinceOfEmployementShortCodeP1Y'} 
                label={'Province Of Employement ShortCode'} 
                labelPosition={LabelPosition.Left}
                defaultValue={668}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerOfferedDentalBenefitsP1Y'} 
                testId={'employerOfferedDentalBenefitsP1Y'} 
                label={'Employer Offered Dental Benefits'} 
                labelPosition={LabelPosition.Left}
                defaultValue={693}
            />
            <RHookFormTextFieldWithLabel 
                id={'employmentCodeP1Y'} 
                testId={'employmentCodeP1Y'} 
                label={'Employment Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={632}
            />

            {/* USE COMMA FIELD */}
            {/* <Grid container sx={{ display: "flex",alignItems:"center" }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "left", paddingTop: 2 }}>
                <InputLabel>
                    <Typography variant="subtitle2">Employment Code</Typography>
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "left", paddingTop: 2 }}>
            <RHookFormNumericFormatTextField 
                  id={'employmentCodeP1Y'}
                  testId={'employmentCodeP1Y'}
                  // label={'Employment Code'} 
                  // labelPosition={LabelPosition.Left}
                  defaultValue={632} 
                  textAlign={'end'}            />
                </Grid>
            </Grid> */}

        </Grid>
    </Grid>
  )
}

export default T4SLipAxisPage1