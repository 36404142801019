import { IAPIKeysResponse } from "models";
import { IEmployeeListPayload } from "models/employee";
import { IVendorListPayload } from "models/vendor";
import { call, put } from "redux-saga/effects";
import { ClientService } from "services";
import {
  apiKeysRequestSucceeded,
  generateAPIKeysRequestSucceeded,
  loadEmployeeListRequestFailed,
  loadEmployeeListRequestSucceeded,
  loadVendorListRequestSucceeded,
} from "store/actions";

export function* generateAPIKeys(action: any) {
  try {
    const clientAPIKeysResponse: IAPIKeysResponse = yield call(
      ClientService.generate,
      action.payload
    );

    if (clientAPIKeysResponse.status && action.payload.generate_new === false) {
      yield put(apiKeysRequestSucceeded(clientAPIKeysResponse));
    } else if (
      clientAPIKeysResponse.status &&
      action.payload.generate_new === true
    ) {
      yield put(generateAPIKeysRequestSucceeded(clientAPIKeysResponse));
    }
  } catch (err) {
    console.log("err =>", err);
  }
}

export function* getEmployees(action: any) {
  try {
    const employeeResponse: IEmployeeListPayload = yield call(
      ClientService.getEmployees,
      action.payload
    );
    if (employeeResponse) {
      yield put(loadEmployeeListRequestSucceeded(employeeResponse));
    }
  } catch (err) {
    yield put(loadEmployeeListRequestFailed());
  }
}

export function* getVendors(action: any) {
  try {
    const vendorResponse: IVendorListPayload = yield call(
      ClientService.getVendors,
      action.payload
    );

    if (vendorResponse) {
      yield put(loadVendorListRequestSucceeded(vendorResponse));
    }
  } catch (err) {
    console.log("err =>", err);
  }
}
