import { TaxSlipDataInput } from "components/TaxSlipDataInput";

import { Grid, Typography } from "@mui/material";
import {
  IOtherInformationSetupFieldsPanel,
  OtherInformationSetupFieldsPanel,
} from "./OtherInformationSetupFieldsPanel";
import { FC, useState } from "react";
import { selectedPOE } from "./FilingDataSetupFieldsPanel";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { useQuery } from "react-query";
import { GET_QUERY_KEY, QUERY_OPTIONS } from "common/constants";
import { TaxSlipSettingsService } from "services/TaxSlipSettingsService";
import { checkboxDefaultSelect } from "../T4SlipSetup";
import { Message } from 'primereact/message';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

export let applyAdjustment = false;

export interface IT4SlipDataPanel extends IOtherInformationSetupFieldsPanel {}

export const T4SlipDataPanel: FC<IT4SlipDataPanel> = ({
  formMethods,
  otherInformationList,
  isLoading,
  testId,
  selectedOtherInformation,
  oiInEdit,
  selectedProvinceOfEmployment,
  globalVariablesData
  // filteredItemsOFOtherInformation
}) => {
  const taxYear = useSelector((state : IAppState) => state.taxYear.year)


  console.log("globalVariablesData?.t4?.eiSettingsTotalEiEarningsMaximum--",globalVariablesData?.data?.t4?.eiSettingsTotalEiEarningsMaximum)
  
  const [inputValue, setInputValue] = useState<string>('');
  const [showGlobalVariablesError, setShowGlobalVariablesError] = useState(false);

  const listItems = [
    {label:"Not eligible to access any dental care insurance, or coverage of dental service of any kind",value:1},
    {label:"Payee only",value:2},
    {label:"Payee, spouse and dependent children",value:3},
    {label:"Payee and their spouse",value:4},
    {label:"Payee and their dependent children",value:5},
  ]

  const handleInputChange = (e: any) => {
    const newValue = e.target.value;
    console.log("newValue", newValue);
    console.log("selectedPOE", selectedPOE);
    if(taxYear > 2023){
      const cppQppExempt = formMethods.getValues('cppQppExempt')
      const eiExempt = formMethods.getValues('eiExempt')
      if (selectedPOE !== "" || "Quebec") {
        // let newBox24 = Math.min(Number(newValue), 60300).toString();
        // let newBox24 = Math.min(Number(newValue), 64900).toString();
        // setBox24Value(newBox24);
        // setBox26Value(newBox26);
        console.log("globalVariablesData--",globalVariablesData)
        if(globalVariablesData && globalVariablesData?.data?.t4){
          if(cppQppExempt !== true){
            formMethods.setValue('cppQppPensionableEarnings',parseFloat(Math.min(Number(newValue), globalVariablesData && globalVariablesData?.data?.t4?.cppSettingsTotalSecondCppPensionableEarningsMaximum).toString()).toFixed(2))
          } else {
            formMethods.setValue('cppQppPensionableEarnings',"0.00")
          }
          if(eiExempt !== true){
            formMethods.setValue('eiInsurableEarnings',parseFloat(Math.min(Number(newValue), globalVariablesData && globalVariablesData?.data?.t4?.eiSettingsTotalEiEarningsMaximum).toString()).toFixed(2))
          } else {
            formMethods.setValue('eiInsurableEarnings',"0.00")
          }
          setShowGlobalVariablesError(false)
        } else {
          formMethods.setValue('eiInsurableEarnings',"0.00")
          formMethods.setValue('cppQppPensionableEarnings',"0.00")
          // alert(globalVariablesData.data.message ? globalVariablesData.data.message : "T4 Slip definition does not exist for selected year, please add definition to configure global variables")
          if(globalVariablesData){
            setShowGlobalVariablesError(globalVariablesData.data.message ? globalVariablesData.data.message : "T4 Slip definition does not exist for selected year, please add definition to configure global variables")
          }
        }
      }
      if (selectedPOE === "Quebec") {
        // let newBox24 = Math.min(Number(newValue), 60300).toString();
        // let newBox26 = Math.min(Number(newValue), 64900).toString();
        // setBox24Value(newBox24);
        // setBox26Value(newBox26);
        if(globalVariablesData  && globalVariablesData?.data?.rl1){
          if(cppQppExempt !== true){
            formMethods.setValue('cppQppPensionableEarnings',parseFloat(Math.min(Number(newValue), globalVariablesData && globalVariablesData?.data?.rl1?.qppSettingsTotalSecondQppPensionableEarningsMaximum).toString()).toFixed(2))
          } else {
            formMethods.setValue('cppQppPensionableEarnings',"0.00")
          }
          if(eiExempt !== true){
            formMethods.setValue('eiInsurableEarnings',parseFloat(Math.min(Number(newValue), globalVariablesData && globalVariablesData?.data?.rl1?.eiSettingsQubecTotalEiEarningsMaximum).toString()).toFixed(2))
            // formMethods.setValue('eiInsurableEarnings',"0.00")
          }else {
            formMethods.setValue('eiInsurableEarnings',"0.00")
          }
          setShowGlobalVariablesError(false)
        } else {
          formMethods.setValue('eiInsurableEarnings',"0.00")
          formMethods.setValue('cppQppPensionableEarnings',"0.00")
          if(globalVariablesData){
            setShowGlobalVariablesError(globalVariablesData.data.message ? globalVariablesData.data.message : "T4 Slip definition does not exist for selected year, please add definition to configure global variables")
          }
        }
      }
    } else {
    const cppQppExempt = formMethods.getValues('cppQppExempt')
    const eiExempt = formMethods.getValues('eiExempt')
    if (selectedPOE !== "" || "Quebec") {
      // let newBox24 = Math.min(Number(newValue), 60300).toString();
      // let newBox24 = Math.min(Number(newValue), 64900).toString();
      // setBox24Value(newBox24);
      // setBox26Value(newBox26);
      console.log("globalVariablesData--",globalVariablesData)
      if(globalVariablesData && globalVariablesData?.data?.t4){
        if(cppQppExempt !== true){
          console.log('parseFloat(Math.min(Number(newValue), globalVariablesData && globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum).toString()).toFixed(2)',parseFloat(Math.min(Number(newValue), globalVariablesData && globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum).toString()).toFixed(2))
          formMethods.setValue('cppQppPensionableEarnings',parseFloat(Math.min(Number(newValue), globalVariablesData && globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum).toString()).toFixed(2))
        } else {
          formMethods.setValue('cppQppPensionableEarnings',"0.00")
        }
        if(eiExempt !== true){
          formMethods.setValue('eiInsurableEarnings',parseFloat(Math.min(Number(newValue), globalVariablesData && globalVariablesData?.data?.t4?.eiSettingsTotalEiEarningsMaximum).toString()).toFixed(2))
        } else {
          formMethods.setValue('eiInsurableEarnings',"0.00")
        }
        setShowGlobalVariablesError(false)
      } else {
        formMethods.setValue('eiInsurableEarnings',"0.00")
        formMethods.setValue('cppQppPensionableEarnings',"0.00")
        // alert(globalVariablesData.data.message ? globalVariablesData.data.message : "T4 Slip definition does not exist for selected year, please add definition to configure global variables")
        if(globalVariablesData){
          setShowGlobalVariablesError(globalVariablesData.data.message ? globalVariablesData.data.message : "T4 Slip definition does not exist for selected year, please add definition to configure global variables")
        }
      }
    }
    if (selectedPOE === "Quebec") {
      // let newBox24 = Math.min(Number(newValue), 60300).toString();
      // let newBox26 = Math.min(Number(newValue), 64900).toString();
      // setBox24Value(newBox24);
      // setBox26Value(newBox26);
      if(globalVariablesData  && globalVariablesData?.data?.rl1){
        if(cppQppExempt !== true){
          formMethods.setValue('cppQppPensionableEarnings',parseFloat(Math.min(Number(newValue), globalVariablesData && globalVariablesData?.data?.rl1?.qppSettingsTotalQppPensionableEarningsMaximum).toString()).toFixed(2))
        } else {
          formMethods.setValue('cppQppPensionableEarnings',"0.00")
        }
        if(eiExempt !== true){
          formMethods.setValue('eiInsurableEarnings',parseFloat(Math.min(Number(newValue), globalVariablesData && globalVariablesData?.data?.rl1?.eiSettingsQubecTotalEiEarningsMaximum).toString()).toFixed(2))
          // formMethods.setValue('eiInsurableEarnings',"0.00")
        }else {
          formMethods.setValue('eiInsurableEarnings',"0.00")
        }
        setShowGlobalVariablesError(false)
      } else {
        formMethods.setValue('eiInsurableEarnings',"0.00")
        formMethods.setValue('cppQppPensionableEarnings',"0.00")
        if(globalVariablesData){
          setShowGlobalVariablesError(globalVariablesData.data.message ? globalVariablesData.data.message : "T4 Slip definition does not exist for selected year, please add definition to configure global variables")
        }
      }
    }
  }
  };
  console.log("selectedPOE",selectedPOE)
  console.log("oiInEdit123",oiInEdit)
  console.log("selectedProvinceOfEmployment",selectedProvinceOfEmployment)
  // if(selectedProvinceOfEmployment !== 'Quebec'){
  //   formMethods.setValue('cppContributions',"0.00")
  // } else {
  //   formMethods.setValue('qppContributions',"0.00")
  // }

  // const currentPOE = formMethods.getValues('provinceOfEmployment')
  // console.log("currentPOE1234",typeof(currentPOE))


  return (
    <>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <EnhancedIOSSwitchRHFWithLabel
            name="applyAdjustments"
            checked={false}
            testId="applyAdjustments"
            label="Apply Adjustments"
            labelPosition={LabelPosition.Left}
            labelColumns={6}
            fieldColumns={6}
            onSelectionChangeHandler={onSelectionChange}
          />
        </Grid> */}
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Actual
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          sx={{ textAlign: "center" }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Adjusted
          </Typography>
        </Grid>
      </Grid>
       <TaxSlipDataInput
        id="employmentIncome"
        label="Employment Income"
        boxNumber="14"
        onChange={handleInputChange}
        defaultValue="0.00"
        // value={inputValue}
      />
      {selectedProvinceOfEmployment !== "Quebec" ?
      (
        <><TaxSlipDataInput
           id="cppContributions"
           label="CPP Contributions"
           boxNumber="16"
           defaultValue="0.00"
         />
         {taxYear > 2023 ? 
        <TaxSlipDataInput
           id="secondCppContributions"
           label="Second CPP Contributions"
           boxNumber="16A"
           defaultValue="0.00"
         />:<></>}</>
      ):(
        <></>
      )
      }
      {selectedProvinceOfEmployment === "Quebec" ?
      (
         <><TaxSlipDataInput
            id="qppContributions"
            label="QPP Contributions"
            boxNumber="17"
            defaultValue="0.00"
          />
          {taxYear > 2023 ? 
          <TaxSlipDataInput
            id="secondQppContributions"
            label="Second QPP Contributions"
            boxNumber="17A"
            defaultValue="0.00"
            />:<></>}</>
      ):(
        <></>
      )
      }
      <TaxSlipDataInput id="eiPremiums" label="EI Premiums" boxNumber="18" 
        defaultValue="0.00"/>
      <TaxSlipDataInput
        id="rppContributions"
        label="RPP Contributions"
        boxNumber="20"
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="incomeTaxDeducted"
        label="Income Tax Deducted"
        boxNumber="22"
        defaultValue="0.00"
      />     
      {showGlobalVariablesError === false ? 
      (<>
          <TaxSlipDataInput
            id="eiInsurableEarnings"
            label="EI Insurable Earnings"
            boxNumber="24"
            checkBox={true}
            tag="ei"
            readOnly={checkboxDefaultSelect === true ? false : true}
            formMethods={formMethods}
            globalVariablesData={globalVariablesData && globalVariablesData}
            // value={box24Value}
          defaultValue="0.00"
          />
      <TaxSlipDataInput
        id="cppQppPensionableEarnings"
        label="CPP Pensionable Earnings"
        boxNumber="26"
        checkBox={true}
        tag="cpp"
        readOnly={checkboxDefaultSelect === true ? false : true}
        formMethods={formMethods}
        globalVariablesData={globalVariablesData && globalVariablesData}
        showAdjustedInput={false}
        defaultValue="0.00"
        // value={box26Value}
      /></>)
      :
      (<>
      <div>
          <TaxSlipDataInput
            id="eiInsurableEarnings"
            label="EI Insurable Earnings"
            boxNumber="24"
            checkBox={true}
            readOnly={checkboxDefaultSelect === true ? false : true}
            formMethods={formMethods}
            globalVariablesData={globalVariablesData && globalVariablesData}
            // value={box24Value}
          defaultValue="0.00"
          />
          <span style={{display:"flex",justifyContent:"center",marginLeft:"19.6rem",color:"#cc8925",fontSize:"12px",background:"#fff2e2",alignItems:"center",padding:"2px",borderRadius:"5px"}}><WarningAmberIcon sx={{marginRight:"7px",padding:"0 2px 2px 2px"}}/>{showGlobalVariablesError}</span>
        </div>
        <div>
      <TaxSlipDataInput
        id="cppQppPensionableEarnings"
        label="CPP Pensionable Earnings"
        boxNumber="26"
        checkBox={true}
        readOnly={checkboxDefaultSelect === true ? false : true}
        formMethods={formMethods}
        globalVariablesData={globalVariablesData && globalVariablesData}
        showAdjustedInput={false}
        defaultValue="0.00"
        // value={box26Value}
      />
        <span style={{display:"flex",justifyContent:"center",marginLeft:"19.6rem",color:"#cc8925",fontSize:"12px",background:"#fff2e2",alignItems:"center",padding:"2px",borderRadius:"5px"}}><WarningAmberIcon sx={{marginRight:"7px",padding:"0 2px 2px 2px"}}/>{showGlobalVariablesError}</span>
    </div></>)
}
      <TaxSlipDataInput
        id="unionDues"
        label="Union Dues"
        boxNumber="44"
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="charitableDonations"
        label="Charitable Donations"
        boxNumber="46"
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="registrationNumber"
        label="Registration Number"
        boxNumber="50"
        showAdjustedInput={false}
        defaultValue=""
        textfield={true}
      />
      <TaxSlipDataInput
        id="pensionAdjustment"
        label="Pension Adjustment"
        boxNumber="52"
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="ppipPremiums"
        label="PPIP Premiums"
        boxNumber="55"
        defaultValue="0.00"
        readOnly={selectedProvinceOfEmployment !== "Quebec" ? true : false}
      />
      <TaxSlipDataInput
        id="ppipInsurableEarnings"
        label="PPIP Insurable Earnings"
        boxNumber="56"
        defaultValue="0.00"
        readOnly={selectedProvinceOfEmployment !== "Quebec" ? true : false}
      />
      {/* {taxYear >= 2023 ? 
      <Box
        sx={{
          "& .css-5sd1dn": {
            maxWidth: "18.7rem",
            marginLeft: "6.5rem",
          },
        }}
      >
        <EnhancedDropDownRHFWithLabel
          label="Employer-offered dental benefits"
          selectName="employerOfferedDentalBenefits"
          items={listItems ?? []}
          testId="EmployerOfferedDentalBenefits"
          labelPosition={LabelPosition.Left}
        />
      </Box>
      :<></>} */}
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ mt: 2, p: 4, backgroundColor: "#f4f5f7" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold", pb: 1 }}>
            Other Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OtherInformationSetupFieldsPanel
            formMethods={formMethods}
            isLoading={isLoading}
            otherInformationList={otherInformationList}
            testId={testId}
            selectedOtherInformation={selectedOtherInformation}
            oiInEdit={oiInEdit}
            selectedProvinceOfEmployment={undefined} 
            globalVariablesData={undefined}            // filteredItemsOFOtherInformation={filteredItemsOFOtherInformation}     
            />
        </Grid>
      </Grid>
    </>
  );
};
