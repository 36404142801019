import { IHeaderColumns } from "../../models/common/IHeaderColumns";
import {
  IAccountantListColumnsResponse,
  IAccountantListPayload,
} from "models/accountant";
import { IClientListPayload } from "models/client";

import { ACCOUNTANT } from "../types";

export interface Accountant {
  id: string;
  columnsListAccountant: IAccountantListColumnsResponse[];
  columnsListClient: IHeaderColumns[];
  accountantList?: IAccountantListPayload;
  clientList?: IClientListPayload;
  AccountantName?:string
}

const initialState: Accountant = {
  id: "",
  columnsListAccountant: [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
    },
    {
      field: "accTitle",
      headerName: "Account Title",
      minWidth: 200,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
    },
    {
      field: "phone_no",
      headerName: "Phone",
      type: "number",
      minWidth: 150,
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
    },
  ],
  columnsListClient: [
    {
      field: "name",
      headerName: "Client Name",
      minWidth: 150,
    },
    {
      field: "reg",
      headerName: "Reg. #",
      minWidth: 120,
    },
    {
      field: "creation_date",
      headerName: "Creation date",
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 150,
    },
    {
      field: "phone_no",
      headerName: "Phone",
      minWidth: 150,
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
    },
  ],
};

export const accountantReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ACCOUNTANT.ACCOUNTANT_LIST_REQUEST_LOAD:
      return {
        ...state,
        accountantList: {},
      };
    case ACCOUNTANT.ACCOUNTANT_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        accountantList: action.payload,
      };
    case ACCOUNTANT.CLIENT_LIST_REQUEST_LOAD:
      return {
        ...state,
        clientList: {},
      };
    case ACCOUNTANT.CLIENT_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        clientList: action.payload,
      };
    case ACCOUNTANT.ACCOUNTANT_SET_SELECTED:
      return {
        ...state,
        id: action.payload.id,
      }
    case ACCOUNTANT.ACCOUNTANT_CLEAR_SELECTED:
      return {
        ...state,
        id: "",
      }
      case ACCOUNTANT.ACCOUNTANT_NAME_SET_SELECTED:
      return {
        ...state,
        // AccountantName: action.payload.fullName,
        AccountantName: action.payload.accTitle,
      }
    case ACCOUNTANT.ACCOUNTANT_NAME_CLEAR_SELECTED:
      return {
        ...state,
        AccountantName: "",
      }
    default:
      return state;
  }
};
