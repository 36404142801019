import { FC, useState } from "react";

import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Toolbar } from "@mui/material";
import {
  DeleteButton,
  DisabledButton,
  ResetButton,
  SplitButton,
  SubmitButton,
} from "@websential/cosmic";
import { useQuery } from "react-query";
import { T5SlipService } from "services/T5SlipService";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { selectedT5SlipType } from "../T5SlipSetup";
import { selectedT5SummaryType } from "components/T5Slip/T5SlipSummarySetup";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PrintIcon from "@mui/icons-material/Print";

export interface IT5SlipSetupToolbar {
  onResetClicked: () => void;
  onDeleteClicked: () => void;
  onPrintClicked: (printType: string) => void;
  isSubmitting: boolean;
  formMethods: any;
}

const T5_SUMMARY_DATA_QUERY_KEY = "get-t5-summary-data";
const useGetT5SummaryData = (
  clientid: string,
  slipType: string | number,
  taxYear: number
) =>
  useQuery(
    [T5_SUMMARY_DATA_QUERY_KEY, clientid, slipType, taxYear],
    (data) => {
      return T5SlipService.getT5Summary(clientid, slipType, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

export const T5SlipSetupToolbar: FC<IT5SlipSetupToolbar> = ({
  onResetClicked,
  onDeleteClicked,
  onPrintClicked,
  isSubmitting = false,
  formMethods,
}) => {
  const id = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  // const [selectedType, setSelectedType] = useState(400);
  const { isLoading: isLoadingSummaryGetData, data: t5SummaryData } =
    useGetT5SummaryData(id, selectedT5SummaryType, taxYear);
  // console.log("selectedType",selectedType)

  console.log("t5SummaryData ============", t5SummaryData);

  const onchangeTooltip = () => {
    //it have to optional from cosmic then remove it
  };
  const selectedSlip = formMethods.getValues("selectedSlip");

  return (
    <Toolbar>
      <>
        <div style={{ display: "contents" }}>
          <div style={{ margin: "1rem 0rem" }}>
            {t5SummaryData &&
            t5SummaryData.confirmationCode !== null &&
            selectedT5SlipType === 400 ? (
              <div style={{ cursor: "not-allowed" }}>
                <DisabledButton
                  tooltiptitle={
                    "Confirmation No. has been enterned in [Complaince -> t5 -> Summary]"
                  }
                  isDisabled={true}
                  buttonLabel={"Submit"}
                  icon={<PlaylistAddCheckOutlinedIcon />}
                  testId="disableButton"
                  onClick={onchangeTooltip}
                />
              </div>
            ) : (
              <SubmitButton
                label="Submit"
                testId="submit-button"
                loading={isSubmitting}
                icon={<PlaylistAddCheckOutlinedIcon />}
              />
            )}
          </div>
          <ResetButton testId="reset-button" onClickHandler={onResetClicked} />
          <div style={{ margin: "1rem 0rem" }}>
            {t5SummaryData &&
            t5SummaryData.confirmationCode !== null &&
            selectedT5SlipType === 400 ? (
              <div style={{ cursor: "not-allowed", marginLeft: "1rem" }}>
                <DisabledButton
                  tooltiptitle={
                    "Confirmation No. has been enterned in [Complaince -> t4 -> Summary]"
                  }
                  isDisabled={true}
                  buttonLabel={"Delete"}
                  icon={<DeleteOutlineIcon />}
                  testId="disableButton"
                  onClick={onchangeTooltip}
                />
              </div>
            ) : selectedSlip && selectedSlip === "create-new" ? (
              <div style={{ cursor: "not-allowed", marginLeft: "1rem" }}>
                <DisabledButton
                  tooltiptitle={""}
                  isDisabled={true}
                  buttonLabel={"Delete"}
                  icon={<DeleteOutlineIcon />}
                  testId="disableButton"
                  onClick={onchangeTooltip}
                />
              </div>
            ) : (
              <DeleteButton
                testId="delete-button"
                onClickHandler={onDeleteClicked}
              />
            )}
          </div>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div>
          {selectedSlip && selectedSlip === "create-new" ? (
            <div style={{ cursor: "not-allowed", marginLeft: "1rem" }}>
              <DisabledButton
                tooltiptitle={""}
                isDisabled={true}
                buttonLabel={"Print"}
                icon={<PrintIcon />}
                testId="disableButton"
                onClick={onchangeTooltip}
              />
            </div>
          ) : (
            <SplitButton
              options={["Payer", "Recipient", "CRA"]}
              onClickHandler={onPrintClicked}
              testId="t5-print-button"
              icon={<PrintIcon />}
              listItemsLabel={`Print T5 Slip  `}
            />
          )}
        </div>
      </>
    </Toolbar>
  );
};
