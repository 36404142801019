import { LabelPosition, EnhancedTextAreaFieldRHF, ITest, RHookFormNumberFieldWithLabel, EnhancedDropDownRHFWithLabel, IEnhancedDropDownItem, RHookFormTextFieldWithLabel } from "@websential/cosmic";
import { FC, useMemo } from 'react';
import { FieldWrapper } from "../../../FieldWrapper/FieldWrapper";

interface Props extends ITest {
    flexFieldsList: IEnhancedDropDownItem[];
    isLoading: boolean;
    selectedFlexFieldId: number;
}

const PARENT_RELATION_ITEMS = ["==", "!=", ">", "<", ">=", "<="].map(itemValue => ({
    label: itemValue,
    value: itemValue
}));

const RightPanel: FC<Props> = ({ testId, isLoading, flexFieldsList, selectedFlexFieldId }) => {
    // filter out the selected flex field from the list of fields because a field cannot be the parent of itself
    const parentFlexFieldList = useMemo(() => flexFieldsList.filter(flexField => flexField.value !== selectedFlexFieldId), [flexFieldsList, selectedFlexFieldId]);
    return (
        <>
            <FieldWrapper>
                <EnhancedTextAreaFieldRHF
                    label="Notes"
                    id="notes"
                    testId={`${testId}-notes`}
                    placeholder="Notes"
                    labelPosition={LabelPosition.Left}
                />
            </FieldWrapper>
            <FieldWrapper>
                <RHookFormNumberFieldWithLabel
                    label="Min Value"
                    id="minValue"
                    testId={`${testId}-min-value`}
                    placeholder="Min Value"
                    labelPosition={LabelPosition.Left}
                />
            </FieldWrapper>
            <FieldWrapper>
                <RHookFormNumberFieldWithLabel
                    label="Max Value"
                    id="maxValue"
                    testId={`${testId}-max-value`}
                    placeholder="Max Value"
                    labelPosition={LabelPosition.Left}
                />
            </FieldWrapper>
            <FieldWrapper>
                <EnhancedDropDownRHFWithLabel
                    selectName="parent"
                    label="Parent"
                    labelPosition={LabelPosition.Left}
                    items={parentFlexFieldList}
                    testId={`${testId}-parent`}
                    isLoading={isLoading}
                />
            </FieldWrapper>
            <FieldWrapper>
                <RHookFormTextFieldWithLabel
                    label="Parent Value"
                    id="parentValue"
                    testId={`${testId}-parent-value`}
                    placeholder="Parent Value"
                    labelPosition={LabelPosition.Left}
                />
            </FieldWrapper>
            <FieldWrapper>
                <EnhancedDropDownRHFWithLabel
                    selectName="parentRelation"
                    label="Parent Relation"
                    labelPosition={LabelPosition.Left}
                    items={PARENT_RELATION_ITEMS}
                    testId={`${testId}-parent-relation`}
                    showEmpty
                />
            </FieldWrapper>
        </>
    )
}

export default RightPanel
