import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { JournalCodeSetup } from "components/Reports/GeneralClient";
import { JournalExport } from "components/Reports/JournalExpertReport";

export interface IJournalExportAdminPage {}

export const JournalExportAdminPage: FC<IJournalExportAdminPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Journal Export"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<JournalExport/>}
    />
  );
};
