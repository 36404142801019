import { LookupFieldList } from "components/LookupFields";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export interface ILookupFieldListPage {}

export const LookupFieldListPage: FC<ILookupFieldListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Lookup Fields"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Lookup Fields', navigateTo: '/lookup-fields/list'}]}
      mainContents={<LookupFieldList />}
    />
  );
};
