import { TaxSlipsGlobalSettings } from "components/GlobalSettings";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const TaxSlipsGlobalSettingsPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Global Variables"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Global Variables', navigateTo: '/global-variables'}]}
      mainContents={<TaxSlipsGlobalSettings testId="global-variables-page" />}
    />
  );
};
