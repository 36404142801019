import { FC } from "react";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Toolbar } from "@mui/material";
import {
  DeleteButton,
  ResetButton,
  SubmitButton,
} from "@websential/cosmic";

export interface IAccountantSetupToolbar {
  onResetClicked: () => void;
  onDeleteClicked: () => void;
  isSubmitting: boolean;
}

export const AccountantSetupToolbar: FC<IAccountantSetupToolbar> = ({
  onResetClicked,
  onDeleteClicked,
  isSubmitting = false,
}) => {
  return (
    <Toolbar >
      <>
        <SubmitButton
          label="Submit"
          testId="submit-button"
          loading={isSubmitting}
          icon={<PlaylistAddCheckOutlinedIcon />}
        />
        {/* <ResetButton testId="reset-button" onClickHandler={onResetClicked} /> */}
        <DeleteButton
          testId="delete-button"
          onClickHandler={onDeleteClicked}
          />
      </>
    </Toolbar>
  );
};
