import { AdjustmentOptionsSetup } from "components/AdjustmentOptions";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const AdjustmentOptionsSetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle={`Adjustment Options`}
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: 'Adjustment', navigateTo: '/adjustments/options' },
        { text: 'Adjustment Options', navigateTo: '/adjustments/options' },
      ]}
      mainContents={
        <AdjustmentOptionsSetup testId="adjustment-options-setup" />
      }
    />
  );
};
