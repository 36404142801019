import { GridSize } from "@mui/material";
import { DataType, FieldType, ITabLayout } from "@websential/cosmic";
import { IFlexFieldsPanelLayout } from "@websential/cosmic/build/components/RHookFormGenerator/types/IFlexFieldsPanelLayout";

export const CLIENT_SETUP_FORM = 
[
    {
      name: "Particular",
      description: "Client Information",
      sequence: 1,
      categoryFields: [],
      panels: [
    {
      label: "Create Client",
      categoryFieldId: "",
      categoryFieldValue: "",
      sequence: 1,
      gridColumns: 12 as GridSize,
      fields: [
        {
          id: 1,
          fieldLabel: "Registration #",
          fieldId: "clientIdentityNo",
          fieldType: FieldType.TextField,
          placeholder: "Registration no.",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 1,
        },
        {
          id: 2,
          fieldLabel: "Legal Name",
          fieldId: "clientName",
          fieldType: FieldType.TextField,
          placeholder: "Legal Name",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 2,
        },
        {
          id: 3,
          fieldLabel: "Operating Name",
          fieldId: "operatingName",
          fieldType: FieldType.TextField,
          placeholder: "Operating Name",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 3,
        },
        {
          id: 4,
          fieldLabel: "Creation Date",
          fieldId: "createdDate",
          fieldType: FieldType.Date,
          placeholder: "Current Date",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.Date,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 4,
        },
        {
          id: 5,
          fieldLabel: "Employees",
          fieldId: "employees",
          fieldType: FieldType.DropDownList,
          placeholder: "",
          fieldValue:
            "1-10,11-25,26-50,51-100,101-200,201-300,301-500,501-1000,1001-2500,2500+",
          fieldInternalValue: "1,11,26,51,101,201,301,501,1001,2501",
          dataType: DataType.Object,
          fieldFormat: "",
          defaultValue: "26",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 5,
        },
        {
          id: 6,
          fieldLabel: "Account Manager",
          fieldId: "account_manager",
          fieldType: FieldType.TextField,
          placeholder: "Account Manager",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 6,
        },
        {
          id: 7,
          fieldLabel: "Business #",
          fieldId: "businessNo",
          fieldType: FieldType.BusinessNumber,
          placeholder: "999999999RP9999",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "{000000000RP0000}",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: true,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 7,
        },
        {
          id: 8,
          fieldLabel: "Account # for T5",
          fieldId: "t5AccountNo",
          fieldType: FieldType.MaskedTextField,
          placeholder: "999999999RZ9999",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "{000000000RZ0000}",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: true,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 8,
        },
        {
          id: 9,
          fieldLabel: "Reduce El Account #",
          fieldId: "reduced_business_no",
          fieldType: FieldType.ReducedElAccountNumber,
          placeholder: "999999999RP9999",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "{000000000RP0000}",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: true,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 9,
        },
        {
          id: 10,
          fieldLabel: "Access Code",
          fieldId: "access_code",
          fieldType: FieldType.TextField,
          placeholder: "Access Code",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 10,
        },
        {
          id: 11,
          fieldLabel: "Payment Method",
          fieldId: "payment_method",
          fieldType: FieldType.DropDownList,
          placeholder: "",
          fieldValue: "PayPal,Stripe,Direct Method",
          fieldInternalValue: "20,30,10",
          dataType: DataType.Object,
          fieldFormat: "",
          defaultValue: "30",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: true,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 11,
        },
        {
          id: 12,
          fieldLabel: "Invoice payment for",
          fieldId: "invoice_payment_for",
          fieldType: FieldType.DropDownList,
          placeholder: "",
          fieldValue: "Selected + Previous year, Seleted year only",
          fieldInternalValue: "10,20",
          dataType: DataType.Object,
          fieldFormat: "",
          defaultValue: "10",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: true,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 12,
        },
      ],
    },
    {
      label: "Address Info",
      categoryFieldId: "",
      categoryFieldValue: "",
      sequence: 2,
      gridColumns: 12 as GridSize,
      fields: [
        {
          id: 10,
          fieldLabel: "Address",
          fieldId: "addressLine1",
          fieldType: FieldType.TextField,
          placeholder: "Enter Address",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 1,
        },
        {
          id: 11,
          fieldLabel: "City",
          fieldId: "city",
          fieldType: FieldType.TextField,
          placeholder: "City",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 2,
        },
        {
          id: 12,
          fieldLabel: "Province",
          fieldId: "provinceId",
          fieldType: FieldType.DropDownList,
          placeholder: "",
          fieldValue:
            "Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon",
          fieldInternalValue: "1,2,3,4,5,6,7,8,9,10,11,12,13",
          dataType: DataType.Object,
          fieldFormat: "",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 3,
        },
        {
          id: 13,
          fieldLabel: "Operating in Provinces",
          fieldId: "operatingProvinces",
          fieldType: FieldType.MultiSelectionDropDownList,
          placeholder: "",
          fieldValue:
            "Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon",
          fieldInternalValue: "1,2,3,4,5,6,7,8,9,10,11,12,13",
          dataType: DataType.Object,
          fieldFormat: "",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 4,
        },
        {
          id: 14,
          fieldLabel: "Country",
          fieldId: "countryId",
          fieldType: FieldType.DropDownList,
          placeholder: "",
          fieldValue: "Canada",
          fieldInternalValue: "1",
          dataType: DataType.Object,
          fieldFormat: "",
          defaultValue: "1",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: true,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 5,
        },
        {
          id: 15,
          fieldLabel: "Post Code",
          fieldId: "postalCode",
          fieldType: FieldType.PostCode,
          placeholder: "A9A9A9",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: true,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 6,
        },
        {
          id: 16,
          fieldLabel: "Email",
          fieldId: "email",
          fieldType: FieldType.Email,
          placeholder: "Email",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 7,
        },
        {
          id: 17,
          fieldLabel: "Phone (Home)",
          fieldId: "phoneNo",
          fieldType: FieldType.MaskedTextField,
          placeholder: "(999) 999-9999",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "{(000) 000-0000}",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 5.4 as GridSize,
          fieldColumn: 6.6 as GridSize,
          fullFieldColumns: 9 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 8,
        },
        {
          id: 18,
          fieldLabel: "Ext",
          fieldId: "ext",
          fieldType: FieldType.MaskedTextField,
          placeholder: "000",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "{000}",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          fullFieldColumns: 3 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 9,
        },
        {
          id: 19,
          fieldLabel: "Phone (Personal)",
          fieldId: "phone2",
          fieldType: FieldType.MaskedTextField,
          placeholder: "(999) 999-9999",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "(000) 000-0000",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 5.4 as GridSize,
          fieldColumn: 6.6 as GridSize,
          fullFieldColumns: 9 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 10,
        },
        {
          id: 20,
          fieldLabel: "Ext",
          fieldId: "ext2",
          fieldType: FieldType.MaskedTextField,
          placeholder: "000",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "{000}",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          fullFieldColumns: 3 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 11,
        },
        {
          id: 21,
          fieldLabel: "Fax",
          fieldId: "fax",
          fieldType: FieldType.MaskedTextField,
          placeholder: "(999) 999-9999",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "(000) 000-0000",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 12,
        },
        {
          id: 22,
          fieldLabel: "Website",
          fieldId: "website",
          fieldType: FieldType.TextField,
          placeholder: "Website",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 13,
        },
      ],
    },
    ],
  },
] as ITabLayout[];

export const CLIENT_SETUP_FORM_FOR_ADMIN_USER = 
[
    {
      name: "Particular",
      description: "Client Information",
      sequence: 1,
      categoryFields: [],
      panels: [
    {
      label: "Create Client",
      categoryFieldId: "",
      categoryFieldValue: "",
      sequence: 1,
      gridColumns: 12 as GridSize,
      fields: [
        {
          id: 1,
          fieldLabel: "Registration #",
          fieldId: "clientIdentityNo",
          fieldType: FieldType.TextField,
          placeholder: "Registration no.",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 1,
        },
        {
          id: 2,
          fieldLabel: "Legal Name",
          fieldId: "clientName",
          fieldType: FieldType.TextField,
          placeholder: "Legal Name",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 2,
        },
        {
          id: 3,
          fieldLabel: "Operating Name",
          fieldId: "operatingName",
          fieldType: FieldType.TextField,
          placeholder: "Operating Name",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 3,
        },
        {
          id: 4,
          fieldLabel: "Creation Date",
          fieldId: "createdDate",
          fieldType: FieldType.Date,
          placeholder: "Current Date",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.Date,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 4,
        },
        {
          id: 5,
          fieldLabel: "Employees",
          fieldId: "employees",
          fieldType: FieldType.DropDownList,
          placeholder: "",
          fieldValue:
            "1-10,11-25,26-50,51-100,101-200,201-300,301-500,501-1000,1001-2500,2500+",
          fieldInternalValue: "1,11,26,51,101,201,301,501,1001,2501",
          dataType: DataType.Object,
          fieldFormat: "",
          defaultValue: "26",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 5,
        },
        {
          id: 6,
          fieldLabel: "Account Manager",
          fieldId: "account_manager",
          fieldType: FieldType.TextField,
          placeholder: "Account Manager",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 6,
        },
        {
          id: 7,
          fieldLabel: "Business #",
          fieldId: "businessNo",
          fieldType: FieldType.BusinessNumber,
          placeholder: "999999999RP9999",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "{000000000RP0000}",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: true,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 7,
        },
        {
          id: 8,
          fieldLabel: "Account # for T5",
          fieldId: "t5AccountNo",
          fieldType: FieldType.MaskedTextField,
          placeholder: "999999999RZ9999",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "{000000000RZ0000}",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: true,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 8,
        },
        {
          id: 9,
          fieldLabel: "Reduce El Account #",
          fieldId: "reduced_business_no",
          fieldType: FieldType.ReducedElAccountNumber,
          placeholder: "999999999RP9999",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "{000000000RP0000}",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: true,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 9,
        },
        {
          id: 10,
          fieldLabel: "Access Code",
          fieldId: "access_code",
          fieldType: FieldType.TextField,
          placeholder: "Access Code",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 10,
        },
        {
          id: 11,
          fieldLabel: "Payment Method",
          fieldId: "payment_method",
          fieldType: FieldType.DropDownList,
          placeholder: "",
          fieldValue: "PayPal,Stripe,Direct Method",
          fieldInternalValue: "20,30,10",
          dataType: DataType.Object,
          fieldFormat: "",
          defaultValue: "30",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 11,
        },
        {
          id: 12,
          fieldLabel: "Invoice payment for",
          fieldId: "invoice_payment_for",
          fieldType: FieldType.DropDownList,
          placeholder: "",
          fieldValue: "Selected + Previous year, Seleted year only",
          fieldInternalValue: "10,20",
          dataType: DataType.Object,
          fieldFormat: "",
          defaultValue: "10",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 12,
        },
      ],
    },
    {
      label: "Address Info",
      categoryFieldId: "",
      categoryFieldValue: "",
      sequence: 2,
      gridColumns: 12 as GridSize,
      fields: [
        {
          id: 10,
          fieldLabel: "Address",
          fieldId: "addressLine1",
          fieldType: FieldType.TextField,
          placeholder: "Enter Address",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 1,
        },
        {
          id: 11,
          fieldLabel: "City",
          fieldId: "city",
          fieldType: FieldType.TextField,
          placeholder: "City",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 2,
        },
        {
          id: 12,
          fieldLabel: "Province",
          fieldId: "provinceId",
          fieldType: FieldType.DropDownList,
          placeholder: "",
          fieldValue:
            "Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon",
          fieldInternalValue: "1,2,3,4,5,6,7,8,9,10,11,12,13",
          dataType: DataType.Object,
          fieldFormat: "",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 3,
        },
        {
          id: 13,
          fieldLabel: "Operating in Provinces",
          fieldId: "operatingProvinces",
          fieldType: FieldType.MultiSelectionDropDownList,
          placeholder: "",
          fieldValue:
            "Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon",
          fieldInternalValue: "1,2,3,4,5,6,7,8,9,10,11,12,13",
          dataType: DataType.Object,
          fieldFormat: "",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 4,
        },
        {
          id: 14,
          fieldLabel: "Country",
          fieldId: "countryId",
          fieldType: FieldType.DropDownList,
          placeholder: "",
          fieldValue: "Canada",
          fieldInternalValue: "1",
          dataType: DataType.Object,
          fieldFormat: "",
          defaultValue: "1",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: true,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 5,
        },
        {
          id: 15,
          fieldLabel: "Post Code",
          fieldId: "postalCode",
          fieldType: FieldType.PostCode,
          placeholder: "A9A9A9",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: true,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 6,
        },
        {
          id: 16,
          fieldLabel: "Email",
          fieldId: "email",
          fieldType: FieldType.Email,
          placeholder: "Email",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: null,
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: true,
          isRequired: true,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 7,
        },
        {
          id: 17,
          fieldLabel: "Phone (Home)",
          fieldId: "phoneNo",
          fieldType: FieldType.MaskedTextField,
          placeholder: "(999) 999-9999",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "{(000) 000-0000}",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 5.4 as GridSize,
          fieldColumn: 6.6 as GridSize,
          fullFieldColumns: 9 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 8,
        },
        {
          id: 18,
          fieldLabel: "Ext",
          fieldId: "ext",
          fieldType: FieldType.MaskedTextField,
          placeholder: "000",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "{000}",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          fullFieldColumns: 3 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 9,
        },
        {
          id: 19,
          fieldLabel: "Phone (Personal)",
          fieldId: "phone2",
          fieldType: FieldType.MaskedTextField,
          placeholder: "(999) 999-9999",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "(000) 000-0000",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 5.4 as GridSize,
          fieldColumn: 6.6 as GridSize,
          fullFieldColumns: 9 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 10,
        },
        {
          id: 20,
          fieldLabel: "Ext",
          fieldId: "ext2",
          fieldType: FieldType.MaskedTextField,
          placeholder: "000",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "{000}",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          fullFieldColumns: 3 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 11,
        },
        {
          id: 21,
          fieldLabel: "Fax",
          fieldId: "fax",
          fieldType: FieldType.MaskedTextField,
          placeholder: "(999) 999-9999",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "(000) 000-0000",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 12,
        },
        {
          id: 22,
          fieldLabel: "Website",
          fieldId: "website",
          fieldType: FieldType.TextField,
          placeholder: "Website",
          fieldValue: "",
          fieldInternalValue: "",
          dataType: DataType.String,
          fieldFormat: "",
          defaultValue: "",
          labelPosition: "left",
          labelColumn: 4 as GridSize,
          fieldColumn: 8 as GridSize,
          notes: "",
          minValue: "",
          maxValue: "",
          parent: null,
          parentValue: "",
          parentRelation: null,
          isFixed: false,
          isRequired: false,
          isCategory: false,
          isHide: false,
          readOnly: false,
          displayMasked: false,
          uppercase: false,

          fieldClass: "",
          validationFormula: "",
          comparison: [],
          employeeAccess: 0,
          variableValue: null,
          systemField: false,
          apiPath: null,
          sequence: 13,
        },
      ],
    },
    ],
  },
] as ITabLayout[];


//old method according to rhookform
// export const CLIENT_SETUP_FORM = [
//   {
//     name: "Particular",
//     description: "Client Information",
//     sequence: 1,
//     categoryFields: [],
//     panels: [
//       {
//         label: "Create Client",
//         categoryFieldId: "",
//         categoryFieldValue: "",
//         sequence: 1,
//         gridColumns: 10 as GridSize,
//         fields: [
//           {
//             id: 1,
//             fieldLabel: "Registration no.",
//             fieldId: "clientIdentityNo",
//             fieldType: FieldType.TextField,
//             placeholder: "Registration no.",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 1,
//           },
//           {
//             id: 2,
//             fieldLabel: "Legal Name",
//             fieldId: "clientName",
//             fieldType: FieldType.TextField,
//             placeholder: "Legal Name",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 2,
//           },
//           {
//             id: 3,
//             fieldLabel: "Operating Name",
//             fieldId: "operatingName",
//             fieldType: FieldType.TextField,
//             placeholder: "Operating Name",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 3,
//           },
//           {
//             id: 4,
//             fieldLabel: "Creation Date",
//             fieldId: "creation_date",
//             fieldType: FieldType.Date,
//             placeholder: "Current Date",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 4,
//           },
//           {
//             id: 5,
//             fieldLabel: "Employees",
//             fieldId: "employees",
//             fieldType: FieldType.DropDownList,
//             placeholder: "",
//             fieldValue:
//               "1-10,11-25,26-50,51-100,101-200,201-300,301-500,501-1000,1001-2500,2500+",
//             fieldInternalValue: "1,11,26,51,101,201,301,501,1001,2501",
//             dataType: DataType.Object,
//             fieldFormat: "",
//             defaultValue: "26",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 5,
//           },
//           {
//             id: 6,
//             fieldLabel: "Account Manager",
//             fieldId: "account_manager",
//             fieldType: FieldType.TextField,
//             placeholder: "Account Manager",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 6,
//           },
//           {
//             id: 7,
//             fieldLabel: "Business no.",
//             fieldId: "businessNo",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "999999999RP9999",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "{000000000RP0000}",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: true,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 7,
//           },
//           {
//             id: 8,
//             fieldLabel: "Reduce El Account no.",
//             fieldId: "reduced_business_no",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "999999999RP9999",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "{000000000RP0000}",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: true,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 8,
//           },
//           {
//             id: 9,
//             fieldLabel: "Access Code",
//             fieldId: "access_code",
//             fieldType: FieldType.TextField,
//             placeholder: "Access Code",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 9,
//           },
//           {
//             id: 10,
//             fieldLabel: "Payment Method",
//             fieldId: "payment_method",
//             fieldType: FieldType.DropDownList,
//             placeholder: "",
//             fieldValue: "paypal,Direct method",
//             fieldInternalValue: "20,10",
//             dataType: DataType.Object,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 10,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     name: "Address",
//     description: "Enter address of client",
//     sequence: 2,
//     categoryFields: [],
//     panels: [
//       {
//         label: "Address Info",
//         categoryFieldId: "",
//         categoryFieldValue: "",
//         sequence: 2,
//         gridColumns: 10 as GridSize,
//         fields: [
//           {
//             id: 10,
//             fieldLabel: "Address",
//             fieldId: "addressLine1",
//             fieldType: FieldType.TextField,
//             placeholder: "Enter Address",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 1,
//           },
//           {
//             id: 11,
//             fieldLabel: "City",
//             fieldId: "city",
//             fieldType: FieldType.TextField,
//             placeholder: "City",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 2,
//           },
//           {
//             id: 12,
//             fieldLabel: "Province",
//             fieldId: "provinceId",
//             fieldType: FieldType.DropDownList,
//             placeholder: "",
//             fieldValue:
//               "Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon",
//             fieldInternalValue: "1,2,3,4,5,6,7,8,9,10,11,12,13",
//             dataType: DataType.Object,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 3,
//           },
//           {
//             id: 13,
//             fieldLabel: "Operating in Provinces",
//             fieldId: "operatingProvinces",
//             fieldType: FieldType.MultiSelectionDropDownList,
//             placeholder: "",
//             fieldValue:
//               "Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon",
//             fieldInternalValue: "1,2,3,4,5,6,7,8,9,10,11,12,13",
//             dataType: DataType.Object,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 4,
//           },
//           {
//             id: 14,
//             fieldLabel: "Country",
//             fieldId: "countryId",
//             fieldType: FieldType.DropDownList,
//             placeholder: "",
//             fieldValue: "Canada",
//             fieldInternalValue: "1",
//             dataType: DataType.Object,
//             fieldFormat: "",
//             defaultValue: "1",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: true,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 5,
//           },
//           {
//             id: 15,
//             fieldLabel: "Post Code",
//             fieldId: "postalCode",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "A9A9A9",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "{A0A0A0}",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: true,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 6,
//           },
//           {
//             id: 16,
//             fieldLabel: "Email",
//             fieldId: "email",
//             fieldType: FieldType.Email,
//             placeholder: "Email",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 7,
//           },
//           {
//             id: 17,
//             fieldLabel: "Phone (Home)",
//             fieldId: "phoneNo",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "(999) 999-9999",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "{(000) 000-0000}",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 8,
//           },
//           {
//             id: 18,
//             fieldLabel: "Ext",
//             fieldId: "ext",
//             fieldType: FieldType.TextField,
//             placeholder: "536",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 9,
//           },
//           {
//             id: 19,
//             fieldLabel: "Phone (Personal)",
//             fieldId: "phone2",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "(999) 999-9999",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "(000) 000-0000",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 10,
//           },
//           {
//             id: 20,
//             fieldLabel: "Ext",
//             fieldId: "ext2",
//             fieldType: FieldType.TextField,
//             placeholder: "536",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 11,
//           },
//           {
//             id: 21,
//             fieldLabel: "Fax",
//             fieldId: "fax",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "(999) 999-9999",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "(000) 000-0000",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 12,
//           },
//           {
//             id: 22,
//             fieldLabel: "Website",
//             fieldId: "website",
//             fieldType: FieldType.TextField,
//             placeholder: "Website",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 13,
//           },
//         ],
//       },
//     ],
//   },
// ] as ITabLayout[];
