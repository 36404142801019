import {
  ACCOUNTANT_LIST_SCREEN_SLUG,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
} from "common/constants";
import { ComponentState } from "common/enums";
import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import { IAccountantListPayload } from "models/accountant";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AccountantService } from "services";
import { IResponse, IServerError } from "models/common";
import SearchIcon from '@mui/icons-material/Search';
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import {
  loadAccountantListRequest,
  setSelectedAccountant,
  setSelectedAccountantName,
  setUserMenuOpen,
} from "store/actions";
import { clearError } from "store/actions/viewStateActions";
import { IAppState } from "store/reducers";

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import {
  DeleteConfirmationDialog,
  EmptyList,
  EnhancedSnackbar,
  RouterLinkButton,
} from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import { newData } from "services";
import OnRowDelete from "./OnRowDelete";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
// import { fetchAccountantRequest } from "store/actions/getListActions";

export interface IAccountantList {}
const ACCOUNTANT_LIST_QUERY_KEY = "accountants-list";

const useAccountants = (params: IGetListRequest) =>
  useQuery(
    ["accountants-list", params],
    (data) => {
      console.log("@@@@@@=>useAccountants", data);
      return AccountantService.getAccountants(params);
    },
    { refetchOnWindowFocus: false }
  );

export const AccountantList: React.FC<IAccountantList> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAdminUser = useSelector(
    (state: IAppState) => state.user.profile.isSuperStatus
  );

  // for delete row Purpose
  const queryClient = useQueryClient();
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  // Bulk Delete
const [bulkDelete, setBulkDelete] = useState<boolean>(false);
const [bulkAccountantId, setBulkAccountantId] = useState<any>({});
const [bulkAccountantDelete, setBulkAccountantDelete] = useState<boolean>(false);

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "acc_title",
    order_direction: "asc",
    page: 1,
    size: 25,
  });

  const { isLoading, data } = useAccountants(queryParams); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const accountantList: any = data;
  console.log("accountantList",accountantList)

  useEffect(()=>{
    if(isAdminUser === 0){
      if(accountantList && accountantList.items && accountantList.items.length === 1 && zeroAccountant === true){
          dispatch(setSelectedAccountant(accountantList.items[0]));
          let path = '/accountants/clients'
          navigate(path)
        }else if(accountantList && accountantList.items && accountantList.items.length === 0 && zeroAccountant === true){
          dispatch(setSelectedAccountant({id:"0"})); //default Accountant id gets from backend
          let path = '/clients'
          navigate(path)
      }
      else{
        console.log("length is larger List")
      }
    }
  },[accountantList])

  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);
  const [searchText, setSearchText] = useState({});
  const [zeroAccountant, setzeroAccountant] = useState<any>(true);

  // for delete row
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [accountantId, setAccountantId] = useState<number>(0);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.debouncedSearchQuery,
      order_by: "acc_title",
      order_direction: "asc",
      page: requestParams.currentPage + 1,
      size: requestParams.pageSize,
    };
    if (requestParams.sort) {
      const [sortBy, sortDirection] = requestParams.sort.split(",");
      dispatchParams.order_by = toSnake(sortBy);
      dispatchParams.order_direction = sortDirection;
    }
    console.log("dispatchParams",dispatchParams)
    if(dispatchParams.search_query === ''){
      setQueryParams(dispatchParams);
    }
    setSearchText(dispatchParams)
  }, []);
  
  const onSearchButtonClicked=()=>{
    setQueryParams(searchText)
    setzeroAccountant(false)
  }

  const onRowEditClicked = useCallback((params) => {
    // alert(`Edit row with ID: ${params.row.id} has been clicked!`);
    let path = `/accountants/setup/${params.row.id}`;
    navigate(path);
  }, []);

  // When Delete Button Clicked

  // const onRowDeleteClicked = useCallback((data) => {
  //   console.log("data ========================= ", data);
  //   setDialogOpen(true);
  //   setApiAction("delete");
  //   setSelectedIds(data.row.id);
  //   console.log("id///////////////////", data.row.id);
  //   alert(`Delete row with ID: ${data.row.id} has been clicked!`);
  //   return AccountantService.delAccountants(data.row.id);
  // }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const deleteAccountantField = () => {
    if (singleDelete) {
      setSingleDelete(false);
      deleteAccountantById(accountantId);
      setDialogOpen(false);
    }
  };
  const onRowDeleteClicked = useCallback((params) => {
    setDialogOpen(true);
    setSingleDelete(true);
    setAccountantId(params.row.id);
    console.log(
      "accountantId in Accountant List OnDeleteRowClicked function",
      params.row.id
    );
    // if(singleDelete === true){
    //   console.log("clicked")
    // return (
    //   <OnRowDelete name={ACCOUNTANT_LIST_QUERY_KEY} id={params.row.id}/>
    //   )
    // }
  }, []);

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const { isLoading: isDeleting, mutate: deleteAccountantById } = useMutation(
    AccountantService.deleteById,
    {
      onSuccess: (res: string | IServerError | IResponse | any) => {
        if (res) {
          // remove the cache of the Accountant fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res.message ? res.message : "Something went wrong.",
            severity: res.status === false ? "error" : "success",
          });

          return queryClient.invalidateQueries(ACCOUNTANT_LIST_QUERY_KEY);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const onKeyFieldClickHandler = (clickedRow: any) => {
    dispatch(setSelectedAccountant(clickedRow));
    dispatch(setSelectedAccountantName(clickedRow));
    console.log("clickedRow", clickedRow);
  };

  //bulk delete

  const { isLoading: isBulkDeleting, mutate: deleteBulkAccountants } =
    useMutation(AccountantService.delBulkAccountants, {
      onSuccess: (res: any | string | IServerError | IResponse) => {
        if (typeof res === "object") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res.message ? res.message : "Something went wrong.",
            severity: res.status === false ? "error" : "success",
          });

          setBulkAccountantDelete(false)
          return queryClient.invalidateQueries(ACCOUNTANT_LIST_QUERY_KEY);
        } else {
          setSnackbarData({
            open: true,
            message: res.message ? res.message : "Something went wrong, try again later!",
            severity: res.status === false ? "error" : "success",
          });
        }
        setBulkAccountantDelete(false)
      },
      onError: (err:any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
        setBulkAccountantDelete(false)
      },
    });

  // For Bulk Delete
  const deleteBulkAccountantField = () => {
    if (bulkDelete) {
      setBulkDelete(false);
      deleteBulkAccountants(bulkAccountantId);
      setDialogOpen(false);
    }
  };

  const onRowMultipleDeleteClicked = (params: any) => {
    console.log("Params in onRowMultipleDeleteClicked ====== ", params);
    let bulkDeletePayload = {
      accountantIds:{"accountantIds":params}
    }
    setDialogOpen(true);
    setBulkDelete(true);
    setApiAction("delete");
    setBulkAccountantId(bulkDeletePayload);
    setBulkAccountantDelete(true);
  };

  const selectionToolbar = useCallback((selectedItems) => {
    setSelectedIds(selectedItems);
    // console.log("selectedItems //////////// ",selectedItems)
    if (selectedItems.length) {
      return (
        <Box sx={{ marginTop: theme.spacing(1) }}>
          {/* {"Bulk Action Drop Down List"} */}
          {/* <EnhancedDDLBulkAction
            label="Status"
            value={statusValue}
            onChangeHandler={handleChange}
            options={[
              { name: "Active", value: "1" },
              { name: "Inactive", value: "2" },
            ]}
            testId="status-dd"
          /> */}
          <Button
            onClick={() => onRowMultipleDeleteClicked(selectedItems)}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </Box>
      );
    }
    return (
      <>
      {isAdminUser !== 0 ? 
      <Box sx={{ marginTop: theme.spacing(1) }}>
        <RouterLinkButton to="/accountants/setup" text="Add Accountant" />
      </Box>
      :<></>}
      </>
    );
  }, []);

  
  const getColumnMinWidth = (field:any) => {
    const maxContentWidth = Math.max(...accountantList?.items.map((row:any) => String(row[field]).length * 8)); // Assuming an average character width of 8px
    return maxContentWidth + 16; // Add some padding
  };

  const columnsWithMinWidth = useMemo(() => {
    return accountantList && accountantList.headerColumns && accountantList?.headerColumns.map((column:any) => ({
      ...column,
      minWidth: getColumnMinWidth(column.field),
    }));
  }, [accountantList?.headerColumns]);

  console.log("columnsWithMinWidth",columnsWithMinWidth)


  //find the index of object from headerColumns to add link on given column
  // if (accountantList?.headerColumns) {
  //   const linkObjIndex = accountantList.headerColumns.findIndex(
  //     (obj: { field: any; }) => obj.field === accountantList.keyField.field
  //   );
  //   accountantList.headerColumns[linkObjIndex] = {
  //     field: accountantList.headerColumns[linkObjIndex].field,
  //     headerName: accountantList.headerColumns[linkObjIndex].headerName,
  //     flex: 1,
  //     renderCell: (params: any) => {
  //       var urlLink = accountantList.keyField.templateUrl;
  //       var newLink = urlLink.replace("{id}", params.row.id);

  //       return (
  //         <Link to={newLink} className={classes.textLink}>
  //           {params.row.accTitle}
  //         </Link>
  //       );
  //     },
  //   };
  //   const statusObjIndex = accountantList.headerColumns.findIndex(
  //     (obj: { field: string; }) => obj.field === "status"
  //   );
  //   accountantList.headerColumns[statusObjIndex] = {
  //     field: "status",
  //     headerName: "Status",
  //     flex: 1,
  //     width: 50,
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params: any) => (
  //       <Chip label={params.value} />
  //     ),
  //   };
  // }

  // if (isLoading) {
  //   return (
  //     <CircularProgress
  //       size={24}
  //       data-testid="circular-progress"
  //       className={classes.loader}
  //     />
  //   );
  // }
  // const CustomFooter = () => (
  //   <GridFooterContainer>
  //     {/* Add your custom button here */}
  //     <button onClick={() => alert('Button Clicked')}>Custom Button</button>      
  //   </GridFooterContainer>
  // );

    //defualt drawer options set in home 
    useEffect(() => {
      const accountantDrawerOptionsOpen = {id:200,text:"Accountant"}
      dispatch(setUserMenuOpen(accountantDrawerOptionsOpen));
    }, []);

    useEffect(() => {
      dispatch(
        setSelectedActivityLogActions({
          entityId1: "",
          entityId2: "",
          entityId3: "",
          screenName: ACCOUNTANT_LIST_SCREEN_SLUG,
          companyName: '',
          activityType:
            "activity_type=delete",
          activityLogVisibility: true,
        })
      );
      dispatch(setSelectedStickyNavBar({isSticky:false}));
    }, []);

  const image =
    "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";

  return accountantList && accountantList.items && accountantList.items.length === 0 && isAdminUser !== 0 && zeroAccountant === true ? (
    <EmptyList
      showImage={true}
      imagePath={image}
      heading={`There's nothing! Add Accountant to proceed`}
      content={`You can add accountant by directly clicking on add accountant button.`}
      testId={"accountant-empty-list"}
      showAddButton={true}
      addButtonLabel={"Add accountant"}
      navigateToAdd={"/accountants/setup"}
      showImportButton={false}
      showImportXmlButton={false}
    />
  ) : (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        // className={classes.list}
      >
        {/* <EnhancedDialog
        dialogTitle="Please Confirm"
        key="dialog-box"
        testId="dialog"
        onCloseHandler={onCloseHandler}
        open={openDialog}
        dialogContent={
          <Typography variant="subtitle1">Are you sure?</Typography>
        }
        dialogAction={
          <Grid>
            <Button
              autoFocus
              variant="contained"
              onClick={onCloseHandler}
              color="default"
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={callAction}
              color="primary"
              className={classes.button}
            >
              ok
            </Button>
          </Grid>
        }
      /> */}
        <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={bulkAccountantDelete === true ? deleteBulkAccountantField : deleteAccountantField}
          cancelActionHandler={onCloseHandler}
          testId="accountant-field-tab-confirmation-dialog"
        />
        <EnhancedDataGrid
          keyField={accountantList ? accountantList.keyField : undefined}
          onKeyFieldClickHandler={onKeyFieldClickHandler}
          columns={accountantList && accountantList.headerColumns ? columnsWithMinWidth : []}
          rows={accountantList?.items}
          selectionEnabled={isAdminUser !== 0 ? true : false}
          autoHeight={true}
          allowSearching={true}
          onSearchButtonClicked={onSearchButtonClicked}
          searchFieldPlaceholder={"Search by Account Title..."}
          rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
          selectionToolbar={selectionToolbar}
          onRowEditClicked={onRowEditClicked}
          // onRowDeleteClicked={<OnRowDelete name={ACCOUNTANT_LIST_QUERY_KEY}/>}
          onRowDeleteClicked={onRowDeleteClicked}
          // initialPage={0}
          initialPageSize={INITIAL_PAGE_SIZE}
          disableColumnMenu={true}
          showRowActionsColumn={isAdminUser !== 0 ? true : false}
          autoPageSize={false} //
          showIndexColumn={true}
          testId="accountant-list-grid"
          sortingMode="server"
          filterMode="server"
          paginationMode="server"
          onRequestParamsChange={onRequestParamsChange}
          onSearchCrossClicked={(value) => setzeroAccountant(value)}
          totalRowsCount={accountantList?.total}
          isLoading={isLoading || isDeleting || isBulkDeleting}
          rowIdGetter={(row) => row.id}
          searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
          shouldMask={false}
        />
        {/* <OnRowDelete name={ACCOUNTANT_LIST_QUERY_KEY} id={9}/> */}
        {/* <OnRowDelete name={ACCOUNTANT_LIST_QUERY_KEY} id={accountantId}/> */}
      </Grid>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"accountant-field-tab-list-snackbar"}
        open={snackbarData.open}
      />
    </>
  );
  // switch (componentState) {
  //   case ComponentState.LOADING:
  //     return (
  //       <CircularProgress
  //         size={24}
  //         data-testid="circular-progress"
  //         className={classes.loader}
  //       />
  //     );
  //   case ComponentState.ERROR:
  //     return (
  //       <div>Error loading component data</div>
  //       // <ErrorState
  //       //   testId="error-container"
  //       //   message={message}
  //       //   onClick={() => {
  //       //     clearError();
  //       //     let dispatchParams = {
  //       //       search_query: "",
  //       //       order_by: "acc_title",
  //       //       order_direction: "asc",
  //       //       page: 1,
  //       //       size: 50,
  //       //     };
  //       //     dispatch(loadAccountantListRequest(dispatchParams));
  //       //   }}
  //       // />
  //     );
  //   case ComponentState.DATA:
  //     return emptyList ? (
  //       <EmptyAccountantList />
  //     ) : (
  //       <Grid
  //         item
  //         xs={12}
  //         sm={12}
  //         md={12}
  //         lg={12}
  //         xl={12}
  //         className={classes.list}
  //       >
  //         {/* <EnhancedDialog
  //           dialogTitle="Please Confirm"
  //           key="dialog-box"
  //           testId="dialog"
  //           onCloseHandler={onCloseHandler}
  //           open={openDialog}
  //           dialogContent={
  //             <Typography variant="subtitle1">Are you sure?</Typography>
  //           }
  //           dialogAction={
  //             <Grid>
  //               <Button
  //                 autoFocus
  //                 variant="contained"
  //                 onClick={onCloseHandler}
  //                 color="default"
  //                 className={classes.button}
  //               >
  //                 Cancel
  //               </Button>
  //               <Button
  //                 autoFocus
  //                 variant="contained"
  //                 onClick={callAction}
  //                 color="primary"
  //                 className={classes.button}
  //               >
  //                 ok
  //               </Button>
  //             </Grid>
  //           }
  //         /> */}
  //         <EnhancedDataGrid
  //           columns={accountantList ? accountantList.headerColumns : []}
  //           rows={accountantList?.items}
  //           selectionEnabled={true}
  //           autoHeight={true}
  //           allowSearching={true}
  //           rowsPerPageOptions={[10, 25, 50, 100]}
  //           selectionToolbar={selectionToolbar}
  //           onRowEditClicked={onRowEditClicked}
  //           onRowDeleteClicked={onRowDeleteClicked}
  //           initialPageSize={accountantList?.size}
  //           disableColumnMenu={true}
  //           showRowActionsColumn={true}
  //           autoPageSize={true}
  //           showIndexColumn={true}
  //           testId="accountant-list-grid"
  //           sortingMode="server"
  //           filterMode="server"
  //           paginationMode="server"
  //           onRequestParamsChange={onRequestParamsChange}
  //           totalRowsCount={accountantList?.total}
  //           isLoading={false}
  //           rowIdGetter={(row) => row.id}
  //           searchDebouncingDelay={2000}
  //           shouldMask={false}
  //         />
  //       </Grid>
  //     );
  //   default:
  //     return null;
  // }
};
