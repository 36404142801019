import * as yup from 'yup';

// export const GlobalVariablesValidationSchema = yup.object({
//   cppSettingsTotalCppPensionableEarningsMaximum: yup.number()
//     .nullable(true).moreThan(1),
//   cppSettingsBasicCppExemptionMaximum: yup.number()
//     .nullable(true).moreThan(1),
//   cppSettingsRequiredContributionPercentage: yup.number()
//     .nullable(true).moreThan(1),
//   eiSettingsTotalEiEarningsMaximum: yup.number().typeError("Total EI Earnings (Maximum) must be a number")
//     .nullable(true).moreThan(1).transform((_, val) => (val !== "" ? Number(val) : null)),
//   eiSettingsBasicEiExemptionMaximum: yup.number()
//     .nullable(true).moreThan(1),
//   eiSettingsRequiredContributionPercentage: yup.number()
//     .nullable(true).moreThan(1),
//   eiSettingsMaximumContribution: yup.number()
//     .nullable(true).moreThan(1),
//   qppSettingsTotalQppPensionableEarningsMaximum: yup.number()
//     .nullable(true).moreThan(1),
//   qppSettingsBasicQppExemptionMaximum: yup.number()
//     .nullable(true).moreThan(1),
//   qppSettingsRequiredContributionPercentage: yup.number()
//     .nullable(true).moreThan(1),
//   eiSettingsQubecTotalEiEarningsMaximum: yup.number()
//     .nullable(true).moreThan(1),
//   eiSettingsQubecBasicEiExemptionMaximum: yup.number()
//     .nullable(true).moreThan(1),
//   eiSettingsQubecRequiredContributionPercentage: yup.number()
//     .nullable(true).moreThan(1),
//   eiSettingsQubecMaximumContribution: yup.number()
//     .nullable(true).moreThan(1),
//   qpipPpipSettingsTotalInsurableEarningsMaximum: yup.number()
//     .nullable(true).moreThan(1),
//   qpipPpipSettingsTotalEmployeePremiumMaximum: yup.number()
//     .nullable(true).moreThan(1),
//   qpipPpipSettingsEmployeePremiumRatePercentage: yup.number()
//     .nullable(true).moreThan(1),
//   qpipPpipSettingsEmployerPremiumRatePercentage: yup.number()
//     .nullable(true).moreThan(1),
//   rl1SettingsCntRatePercentage: yup.number()
//     .nullable(true).moreThan(1),
//   rl1SettingsWsdrfRatePercentage: yup.number()
//     .nullable(true).moreThan(1),
// });

//value can be less than one 
export const GlobalVariablesValidationSchema = yup.object({
  cppSettingsTotalCppPensionableEarningsMaximum: yup.string()
    .nullable(),
  cppSettingsBasicCppExemptionMaximum: yup.string()
    .nullable(),
  cppSettingsRequiredContributionPercentage: yup.string()
    .nullable(),
  eiSettingsTotalEiEarningsMaximum: yup.string().typeError("Total EI Earnings (Maximum) must be a string")
    .nullable().transform((_, val) => (val !== "" ? String(val) : null)),
  eiSettingsBasicEiExemptionMaximum: yup.string()
    .nullable(),
  eiSettingsRequiredContributionPercentage: yup.string()
    .nullable(),
  eiSettingsMaximumContribution: yup.string()
    .nullable(),
  qppSettingsTotalQppPensionableEarningsMaximum: yup.string()
    .nullable(),
  qppSettingsBasicQppExemptionMaximum: yup.string()
    .nullable(),
  qppSettingsRequiredContributionPercentage: yup.string()
    .nullable(),
  eiSettingsQubecTotalEiEarningsMaximum: yup.string()
    .nullable(),
  eiSettingsQubecBasicEiExemptionMaximum: yup.string()
    .nullable(),
  eiSettingsQubecRequiredContributionPercentage: yup.string()
    .nullable(),
  eiSettingsQubecMaximumContribution: yup.string()
    .nullable(),
  qpipPpipSettingsTotalInsurableEarningsMaximum: yup.string()
    .nullable(),
  qpipPpipSettingsTotalEmployeePremiumMaximum: yup.string()
    .nullable(),
  qpipPpipSettingsEmployeePremiumRatePercentage: yup.string()
    .nullable(),
  qpipPpipSettingsEmployerPremiumRatePercentage: yup.string()
    .nullable(),
  rl1SettingsCntRatePercentage: yup.string()
    .nullable(),
  rl1SettingsWsdrfRatePercentage: yup.string()
    .nullable(),
});
