import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IT5SummaryAxisPages1 {}
const T5SummaryAxisPages1:React.FC<IT5SummaryAxisPages1> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearX'} 
                testId={'taxYearX'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={282}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAccountX'} 
                testId={'employerAccountX'} 
                label={'Employer Account'} 
                labelPosition={LabelPosition.Left}
                defaultValue={159}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerNameX'} 
                testId={'employerNameX'} 
                label={'Employer Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAddressX'} 
                testId={'employerAddressX'} 
                label={'Employer Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerCityX'} 
                testId={'employerCityX'} 
                label={'Employer City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerPostalX'} 
                testId={'employerPostalX'} 
                label={'Employer Postal'} 
                labelPosition={LabelPosition.Left}
                defaultValue={325}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerProvinceX'} 
                testId={'employerProvinceX'} 
                label={'Employer Province'} 
                labelPosition={LabelPosition.Left}
                defaultValue={236}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerLanguageX'} 
                testId={'employerLanguageX'} 
                label={'Employer Language'} 
                labelPosition={LabelPosition.Left}
                defaultValue={345}
            />
            <RHookFormTextFieldWithLabel 
                id={'box10X'} 
                testId={'box10X'} 
                label={'Box 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box11X'} 
                testId={'box11X'} 
                label={'Box 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box12X'} 
                testId={'box12X'} 
                label={'Box 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box13X'} 
                testId={'box13X'} 
                label={'Box 13'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box14X'} 
                testId={'box14X'} 
                label={'Box 14'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box15X'} 
                testId={'box15X'} 
                label={'Box 15'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box16X'} 
                testId={'box16X'} 
                label={'Box 16'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box17X'} 
                testId={'box17X'} 
                label={'Box 17'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box18X'} 
                testId={'box18X'} 
                label={'Box 18'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box19X'} 
                testId={'box19X'} 
                label={'Box 19'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box24X'} 
                testId={'box24X'} 
                label={'Box 24'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box25X'} 
                testId={'box25X'} 
                label={'Box 25'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box26X'} 
                testId={'box26X'} 
                label={'Box 26'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box32X'} 
                testId={'box32X'} 
                label={'Box 32'} 
                labelPosition={LabelPosition.Left}
                defaultValue={270}
            />
            <RHookFormTextFieldWithLabel 
                id={'box33X'} 
                testId={'box33X'} 
                label={'Box 33'} 
                labelPosition={LabelPosition.Left}
                defaultValue={270}
            />
            <RHookFormTextFieldWithLabel 
                id={'box31X'} 
                testId={'box31X'} 
                label={'Box 31'} 
                labelPosition={LabelPosition.Left}
                defaultValue={460}
            />
            <RHookFormTextFieldWithLabel 
                id={'box41X'} 
                testId={'box41X'} 
                label={'Box 41'} 
                labelPosition={LabelPosition.Left}
                defaultValue={55}
            />
            <RHookFormTextFieldWithLabel 
                id={'box42X'} 
                testId={'box42X'} 
                label={'Box 42'} 
                labelPosition={LabelPosition.Left}
                defaultValue={295}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearY'} 
                testId={'taxYearY'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={677}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAccountY'} 
                testId={'employerAccountY'} 
                label={'Employer Account'} 
                labelPosition={LabelPosition.Left}
                defaultValue={654}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerNameY'} 
                testId={'employerNameY'} 
                label={'Employer Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={581}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAddressY'} 
                testId={'employerAddressY'} 
                label={'Employer Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerCityY'} 
                testId={'employerCityY'} 
                label={'Employer City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={517}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerPostalY'} 
                testId={'employerPostalY'} 
                label={'Employer Postal'} 
                labelPosition={LabelPosition.Left}
                defaultValue={517}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerProvinceY'} 
                testId={'employerProvinceY'} 
                label={'Employer Province'} 
                labelPosition={LabelPosition.Left}
                defaultValue={517}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerLanguageY'} 
                testId={'employerLanguageY'} 
                label={'Employer Language'} 
                labelPosition={LabelPosition.Left}
                defaultValue={490}
            />
            <RHookFormTextFieldWithLabel 
                id={'box10Y'} 
                testId={'box10Y'} 
                label={'Box 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            <RHookFormTextFieldWithLabel 
                id={'box11Y'} 
                testId={'box11Y'} 
                label={'Box 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={398}
            />
            <RHookFormTextFieldWithLabel 
                id={'box12Y'} 
                testId={'box12Y'} 
                label={'Box 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={374}
            />
            <RHookFormTextFieldWithLabel 
                id={'box13Y'} 
                testId={'box13Y'} 
                label={'Box 13'} 
                labelPosition={LabelPosition.Left}
                defaultValue={350}
            />
            <RHookFormTextFieldWithLabel 
                id={'box14Y'} 
                testId={'box14Y'} 
                label={'Box 14'} 
                labelPosition={LabelPosition.Left}
                defaultValue={337}
            />
            <RHookFormTextFieldWithLabel 
                id={'box15Y'} 
                testId={'box15Y'} 
                label={'Box 15'} 
                labelPosition={LabelPosition.Left}
                defaultValue={325}
            />
            <RHookFormTextFieldWithLabel 
                id={'box16Y'} 
                testId={'box16Y'} 
                label={'Box 16'} 
                labelPosition={LabelPosition.Left}
                defaultValue={313}
            />
            <RHookFormTextFieldWithLabel 
                id={'box17Y'} 
                testId={'box17Y'} 
                label={'Box 17'} 
                labelPosition={LabelPosition.Left}
                defaultValue={301}
            />
            <RHookFormTextFieldWithLabel 
                id={'box18Y'} 
                testId={'box18Y'} 
                label={'Box 18'} 
                labelPosition={LabelPosition.Left}
                defaultValue={289}
            />
            <RHookFormTextFieldWithLabel 
                id={'box19Y'} 
                testId={'box19Y'} 
                label={'Box 19'} 
                labelPosition={LabelPosition.Left}
                defaultValue={276}
            />
            <RHookFormTextFieldWithLabel 
                id={'box24Y'} 
                testId={'box24Y'} 
                label={'Box 24'} 
                labelPosition={LabelPosition.Left}
                defaultValue={259}
            />
            <RHookFormTextFieldWithLabel 
                id={'box25Y'} 
                testId={'box25Y'} 
                label={'Box 25'} 
                labelPosition={LabelPosition.Left}
                defaultValue={242}
            />
            <RHookFormTextFieldWithLabel 
                id={'box26Y'} 
                testId={'box26Y'} 
                label={'Box 26'} 
                labelPosition={LabelPosition.Left}
                defaultValue={224}
            />
            <RHookFormTextFieldWithLabel 
                id={'box32Y'} 
                testId={'box32Y'} 
                label={'Box 32'} 
                labelPosition={LabelPosition.Left}
                defaultValue={198}
            />
            <RHookFormTextFieldWithLabel 
                id={'box33Y'} 
                testId={'box33Y'} 
                label={'Box 33'} 
                labelPosition={LabelPosition.Left}
                defaultValue={220}
            />
            <RHookFormTextFieldWithLabel 
                id={'box31Y'} 
                testId={'box31Y'} 
                label={'Box 31'} 
                labelPosition={LabelPosition.Left}
                defaultValue={160}
            />
            <RHookFormTextFieldWithLabel 
                id={'box41Y'} 
                testId={'box41Y'} 
                label={'Box 41'} 
                labelPosition={LabelPosition.Left}
                defaultValue={113}
            />
            <RHookFormTextFieldWithLabel 
                id={'box42Y'} 
                testId={'box42Y'} 
                label={'Box 42'} 
                labelPosition={LabelPosition.Left}
                defaultValue={113}
            />
        </Grid>
    </Grid>
  )
}

export default T5SummaryAxisPages1