import { LOGIN } from '../types';

const initialState = {
  profile: {},
  isAuthenticated: false,
  token: '',
}

const loginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN.REQUEST:
      return {
        ...state,
        profile: {},
        isAuthenticated: false,
      }
      case LOGIN.SUCCESS:
        return {
          ...state,
          profile: {
            ...action.payload.user
          },
          isAuthenticated: true,
          token: action.payload.token
        }
      default:
      return state;
  }
}

export default loginReducer;
