import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import React, { useState } from "react";

import { Box, Grid, InputLabel, Paper, Typography } from "@mui/material";
import {
  EnhancedDivider,
  EnhancedDropDownRHF,
  EnhancedDropDownRHFWithLabel,
  EnhancedTextAreaFieldRHF,
  FlatStandardPanel,
  LabelPosition,
  RequiredAsterisk,
  RHookFormTextField,
} from "@websential/cosmic";

export interface ITaxSlipFilingResourceSelectionPanel {}
export let selectedSlipType : number = 400
export let isLoadingselectedSlipType : boolean = false
export const TaxSlipFilingResourceSelectionPanel: React.FC<ITaxSlipFilingResourceSelectionPanel> =
  () => {
    const [selectedSlipTypeNew, setSelectedSlipTypeNew] = useState(400)
    const onChangeHandler = (e:any) => {
      console.log(e.value);
      setSelectedSlipTypeNew(e.value)
      selectedSlipType = selectedSlipTypeNew
      isLoadingselectedSlipType = true
      console.log("isLoadingselectedSlipType",isLoadingselectedSlipType)
      setTimeout(() => {
        isLoadingselectedSlipType = false
      },2000)
    }

    console.log("selectedSlipType",selectedSlipType)
    return (
      <Grid container>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
          <Paper variant="outlined">
            <FlatStandardPanel
              title="Select Resources"
              testId="company-persons-panel"
              // leftSpacing={2}
              // rightSpacing={2}
            >
              <EnhancedDropDownRHFWithLabel
                selectName="transmitter"
                label="Transmitter"
                labelPosition={LabelPosition.Left}
                items={[{ label: "Babar", value: "babar" }]}
                testId="transmitter"
                required
              />
              <EnhancedDropDownRHFWithLabel
                selectName="slipType"
                label="XML Type"
                labelPosition={LabelPosition.Left}
                items={[
                  { label: "Original", value: 400 },
                  { label: "Amended", value: 200 },
                  { label: "Cancelled", value: 300 },
                  { label: "Additional", value: 100 },
                ]}
                testId="xmlType"
                selectedItem={selectedSlipTypeNew}
                onSelectionChangeHandler={onChangeHandler}
              />
              <EnhancedTextAreaFieldRHF
                id="notes"
                testId="notes"
                label="Notes"
                maxRows={15}
                minRows={5}
                labelPosition={LabelPosition.Left}
              />
              <Box sx={{ pt: 2 }}></Box>
              <EnhancedDivider testId="transmitter-divider" />
              <Box sx={{ pb: 2 }}></Box>
              <EnhancedDropDownRHFWithLabel
                selectName="issuer"
                label="Issuer"
                labelPosition={LabelPosition.Left}
                items={[{ label: "Babar", value: "babar" }]}
                testId="transmitter"
                required
              />
              <TaxSlipSummaryDataInput
                id="ownerSin1"
                label="SIN 1 of the proprietor(s) or principal owner(s)"
                boxNumber="74"
              />
              <TaxSlipSummaryDataInput
                id="ownerSin2"
                label="SIN 2 of the proprietor(s) or principal owner(s)"
                boxNumber="75"
              />
              <Box sx={{ pt: 2 }}></Box>
              <EnhancedDivider testId="transmitter-divider" />
              <Box sx={{ pb: 2 }}></Box>
              <Grid
                container
                columnSpacing={0}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{ textAlign: "left", paddingTop: 1 }}
                >
                  <InputLabel>
                    <Typography variant="subtitle2">
                      Person to contact about this return <RequiredAsterisk />
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  sx={{ textAlign: "center", paddingTop: 1 }}
                >
                  <InputLabel>
                    <Typography variant="subtitle2">76</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <EnhancedDropDownRHF
                    selectName="contactPerson"
                    // label="Person to contact about this return"
                    // labelPosition={LabelPosition.Left}
                    items={[{ label: "Babar", value: "babar" }]}
                    testId="contactPerson"
                    // required
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{ textAlign: "left", paddingTop: 2 }}
                >
                  <InputLabel>
                    <Typography variant="subtitle2">
                      Phone number (Ext.)
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  sx={{ textAlign: "center", paddingTop: 2 }}
                >
                  <InputLabel>
                    <Typography variant="subtitle2">78</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <RHookFormTextField
                    // id="phone-number"
                    id="phone1"
                    testId="phone-number"
                    readOnly
                  />
                </Grid>
              </Grid>
            </FlatStandardPanel>
          </Paper>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
      </Grid>
    );
  };
