
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SnippetFolderOutlinedIcon from "@mui/icons-material/SnippetFolderOutlined";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import TextFieldsOutlinedIcon from "@mui/icons-material/TextFieldsOutlined";

// import { BillingMenu } from "./BillingRoutes";
// import { FlexFieldMenu } from "./FlexFieldRoutes";
// import { TaxSlipMenu } from "./TaxSlipRoutes";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearSelectedAccountant, clearSelectedAccountantName, clearSelectedClient, clearSelectedClientName, clearSelectedINVOICE, clearSelectedTaxYear } from "store/actions";
import { BillingMenuComponent } from "./BillingRoutes";
import { FlexFieldMenuComponent } from "./FlexFieldRoutes";
import { TaxSlipMenuComponent } from "./TaxSlipRoutes";

export const AdminRoutesComponent = () => {

const navigate = useNavigate();
const dispatch = useDispatch();


const { BillingMenu } = BillingMenuComponent()
const { FlexFieldMenu } = FlexFieldMenuComponent()
// const { TaxSlipMenu } = TaxSlipMenuComponent()

const clearClientId = () => {
  dispatch(clearSelectedClient());
};
const clearClientName = () => {
  dispatch(clearSelectedClientName());
};
const clearAccountantId = () => {
  dispatch(clearSelectedAccountant());
};
const clearAccountantName = () => {
  dispatch(clearSelectedAccountantName());
};
const clearTaxYear = () => {
  dispatch(clearSelectedTaxYear());
};
const clearRefNo = () => {
  dispatch(clearSelectedINVOICE());
};
const ArrayData = [
  {
    label: "Home",
    icon: <HomeOutlinedIcon />,
    items: [
      {
        label: "Super User",
        command: () => {
          navigate("/accountants")
          clearClientId();
          clearClientName();
          clearAccountantId();
          clearAccountantName();
          clearTaxYear();
          clearRefNo();
        },
        
      },
      {
        label: "Global Search",
        command: () => {
          navigate("/global-search")
          clearClientId();
          clearClientName();
          clearAccountantId();
          clearAccountantName();
          clearTaxYear();
          clearRefNo();
        },
      },
    ],
  },
  // {
  //   label: "Accountant",
  //   icon: <ContactMailOutlinedIcon />,
  //   items: [
  //     {
  //       label: "List",
  //       command: () => {
  //         navigate("/accountants")
  //         clearAccountantId();
  //         clearAccountantName();
  //         clearClientId();
  //         clearClientName();
  //         clearTaxYear();
  //         clearRefNo();
  //       },
  //     },
  //     {
  //       label: "Setup",
  //       command: () => {
  //         navigate("/accountants/setup")
  //       },
  //     },
  //   ],
  // },
  {
    label: "Billing",
    icon: <AttachMoneyOutlinedIcon />,
    items: BillingMenu,
  },
  {
    label: "Misc",
    icon: <ContactMailOutlinedIcon />,
    items: [
      {
        label: "Year",
        icon: <LocalOfferOutlinedIcon />,
        command: () => {
              navigate("/years")
            },
      },
      {
        label: "Data Roles",
        icon: <SnippetFolderOutlinedIcon />,
        command: () => {
              navigate("/data-roles")
            },
      },
      {
        label: "Lookup Fields",
        icon: <SpellcheckIcon />,
        command: () => {
              navigate("/lookup-fields")
            },
      },
      {
        label: "Categories",
        items: [
          {
            label: "List",
            command: () => {
              navigate("/taxslip-categories/list")
            },
            navigateToURL: "/taxslip-categories/list"
          },
          {
            label: "Setup",
            command: () => {
              navigate("/taxslip-categories/setup")
            },
            navigateToURL: "/taxslip-categories/setup"
          },
        ],
      },
      {
        label: "Definitions",
        items: [
          {
            label: "List",
            command: () => {
              navigate("/taxslip-definitions/list")
            },
            navigateToURL: "/taxslip-definitions/list"
          },
          {
            label: "Setup",
            command: () => {
              navigate("/taxslip-definitions/setup")
            },
            navigateToURL: "/taxslip-definitions/setup"
          },
        ],
      },
      {
        label: "PDF Settings",
        items: [
          {
            label: "PDF Files",
            command: () => {
              navigate("/pdf-settings/pdf-files")
            },
            navigateToURL: "/pdf-settings/pdf-files"
          },
          {
            label: "PDF Axis",
            command: () => {
              navigate("/pdf-settings/pdf-axis")
            },
            navigateToURL: "/pdf-settings/pdf-axis"
          },
        ],
      },
    ],
  },
  
  
  
  {
    label: "Global Variables",
    icon: <SettingsOutlinedIcon />,
    command: () => {
          navigate("/global-variables")
        },
  },
  {
    label: "Flex Fields",
    icon: <TextFieldsOutlinedIcon />,
    items: FlexFieldMenu,
  },
  // {
  //   label: "TaxSlips",
  //   icon: <DoneAllIcon />,
  //   items: TaxSlipMenu,
  // },
  
  {
    label: "Report",
    icon: <ListAltOutlinedIcon />,
    items: [
      {
        label: "Invoice Report",
        command: () => {
          navigate("/admin-reports/invoices")
        },
      },
      {
        label: "Update Invoice",
        command: () => {
          navigate("/admin-reports/update-invoice")
        },
      },
      {
        label: "Transactions Report",
        command: () => {
          navigate("/admin-reports/transactions-report")
        },
      },
      {
        label: "Journal Code Setup",
        command: () => {
          navigate("/admin-reports/journal-code")
        },
      },
      {
        label: "Journal Export",
        command: () => {
          navigate("/admin-reports/journal-export")
        },
      },
    ],
  },
  // {
  //   label: "Logout",
  //   icon: <LogoutOutlinedIcon />,
  //   command: () => {
  //         navigate("/logout")
  //       },
  // },
];
return {
  AdminRoutes:ArrayData
}
}
