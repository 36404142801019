import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import "./inviteUsers.css"
import { EnhancedDropDown,ResetButton, EnhancedMultiDropDownRHF, EnhancedMultiDropDownRHFWithLabel, LabelPosition, RHookFormTextFieldWithLabel, SubmitButtonVariant, SubmitButton } from '@websential/cosmic';
import { FormProvider, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { yupResolver } from '@hookform/resolvers/yup';
import { InviteUsersSchema } from './validator';
import { Box } from '@mui/material';
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";


export interface IInviteUsersDataTable {
    rows: any[];
    columns: any[];
    clientHeaderColumns: any[];
    roles: any;
    timeProfiles: any;
    isLoading?: boolean;
    onDataSubmit:(value:any) => void
}
export const InviteUsersDataTable: React.FC<IInviteUsersDataTable> = ({
    rows,
  columns,
  clientHeaderColumns,
  roles,
  timeProfiles,
  isLoading,
  onDataSubmit
}) => {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [tableData, setTableData] = useState<any>(rows && rows);
  const [subtableData, setSubTableData] = useState<any>(null);
  const [selectedSubItems, setSelectedSubItems] = useState<any>([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  
    const [expandedRows, setExpandedRows] = useState<any>(null);
    const toast = useRef<any>(null);

    useEffect(()=>{
      if(rows){
        setTableData(rows)
      }
    },[rows])

    const allowExpansion = (rowData:any) => {
        console.log("rowDatasf",rowData)
        return rowData && rowData.children && rowData.children.length > 0;
    };
    const formMethods: any = useForm<any>({
        resolver: yupResolver(InviteUsersSchema),
        mode: "onBlur",
        reValidateMode: "onChange",
        // defaultValues: DEFAULT_VALUES,
      });

    const onCellValueChange = (event: any, rowIndex: number, field: any) => {
        const updatedData = [...tableData];
        console.log("updatedData",updatedData)
        console.log("event.field",event.field)
        updatedData[rowIndex][event.field] = field;
        console.log("updatedData",updatedData)
        setTableData(updatedData);
      };
    const onSubCellValueChange = (event: any, rowIndex: number, field: any) => {
        console.log("subtableData",subtableData)
        const updatedData = [...subtableData];
        console.log("updatedData",updatedData)
        console.log("event.field",event.field)
        updatedData[rowIndex][event.field] = field;
        console.log("updatedData",updatedData)
        setSubTableData(updatedData);
      };

      console.log("tableData",tableData)
      console.log("subtableData",subtableData)
      console.log("selectedItems",selectedItems)
      console.log("selectedSubItems",selectedSubItems)


    const onSubmit = (formData: any) => {
      setFormSubmitted(true)
        // const hasNullSelectedItems = selectedItems && selectedItems.length > 0 ? selectedItems.some((row: any) => {
        //   console.log("row--",row)
        //     if(row.roles && row.timeProfiles){
        //       return true
        //     } else {
        //       return false
        //     }
        // }) : true

        // const hasNullSelectedSubItems = selectedSubItems && selectedSubItems.length > 0 ? selectedSubItems.some((row: any) => {
        //   console.log("row--",row)
        //   for(let i = 0; i < row.length; i++){

        //     if(row.client_roles && row.client_time_profiles){
        //         return true
        //       } else {
        //           return false
        //         }
        //       }
        // }) : true

        const hasNullSelectedItems = selectedItems.length === 0 || selectedItems.every(
          (item:any) => item.hasOwnProperty("roles") && item.hasOwnProperty("timeProfiles")
        );
        // const hasNullSelectedSubItems = selectedSubItems.length > 0 && selectedSubItems.every(
        //   (item:any) => item.hasOwnProperty("client_roles") && item.hasOwnProperty("client_time_profiles")
        // );
        const hasNullSelectedSubItems = selectedSubItems.length === 0 || selectedSubItems.every(
          (item:any) => item.hasOwnProperty("client_roles") && item.hasOwnProperty("client_time_profiles")
        );

        console.log("hasNullSelectedItems , hasNullSelectedSubItems",hasNullSelectedItems, hasNullSelectedSubItems)
  if(hasNullSelectedItems && hasNullSelectedSubItems){
    setFormSubmitted(false)
  
          console.log("formData",formData)
          console.log("tableData",tableData)
          console.log("subtableData",subtableData)
          console.log("selectedItems",selectedItems)
          console.log("selectedSubItemsin on submit",selectedSubItems)
          // let allSelectedAccountantsAndClients = []
          // Merge the two arrays
          let allSelectedAccountantsAndClients: any[] = [...selectedItems, ...selectedSubItems];

          // for (let i = 0; i < selectedItems.length && i < selectedSubItems.length; i++) {
          //   console.log("item123", selectedItems[i]);
          //   console.log("subItem", selectedSubItems[i]);

          //   // Merge properties from selectedItems[i] and selectedSubItems[i]
          //   mergedData.push({
          //     ...selectedItems[i],
          //     ...selectedSubItems[i],
          //   });
          // }
          
          // Flatten the array of arrays into a single array
          // mergedData = mergedData.flat();
          
          console.log("allSelectedAccountantsAndClients",allSelectedAccountantsAndClients)
          
          let finalDataOfAllSelectedItems = []
          for(let i = 0; i <  allSelectedAccountantsAndClients.length; i++){
            finalDataOfAllSelectedItems.push({
              // "firstName": formData.name1,
              // "lastName": formData.name2,
              // "timezone": "(GMT-08:00) Pacific Time (US & Canada)",
              "variableValue": allSelectedAccountantsAndClients[i].client_name,
              "timeProfileId": allSelectedAccountantsAndClients[i].timeProfiles  ? parseInt(allSelectedAccountantsAndClients[i].roles) : allSelectedAccountantsAndClients[i].client_time_profiles,
              "roleId": allSelectedAccountantsAndClients[i].roles  ? parseInt(allSelectedAccountantsAndClients[i].roles) : allSelectedAccountantsAndClients[i].client_roles,
              // "email": formData.email,
              // "applicationId": 20
            });
          }
          // ]
          console.log("finalDataOfAllSelectedItems",finalDataOfAllSelectedItems)
          let payload = {
            "firstName": formData.name1,
            "lastName": formData.name2,
            "timezone": "(GMT-08:00) Pacific Time (US & Canada)",
            "userDetail": finalDataOfAllSelectedItems,
            "email": formData.email,
            "applicationId": 20
          }
          console.log("payload",payload)
          onDataSubmit(payload)
        }
      }


    const renderErrorClass = (field:any, rowIndex: number,rowData:any) => {
      console.log("kghskvbksjvbksdvbkjsdvb",rowData)
      console.log("kghskvbksjvbksdvbkjsdvb",rowIndex)
      console.log("kghskvbksjvbksdvbkjsdvb",field)
      // if(rowData.client_id === 223){
      //   return 'error-input'
      // }
        let result = "input-dropdown-fields";

        selectedItems.forEach((selectedItem: any) => {
          const matchingItem = tableData.find((item: any) => item.client_id === selectedItem.client_id);
          if (matchingItem && formSubmitted) {
            console.log("matchingItem ss",matchingItem)
            // Check if roles and timeProfiles are available in newData
            if (matchingItem.roles) {
              result = "input-dropdown-fields";
            } else {
              if (field.field === "roles" && matchingItem.client_id === rowData.client_id){
                result = "error-input input-dropdown-fields";
              }
            }

            if (matchingItem.timeProfiles) {
              result = "input-dropdown-fields";
            } else {
              if (field.field === "timeProfiles" && matchingItem.client_id === rowData.client_id) {
                  result = "error-input input-dropdown-fields";
                }
            }
          } else {
            // If no matchingItem found
            result = "input-dropdown-fields";
          }
        });
console.log("result11",result)
      return result
    }
  const renderSubItemErrorClass = (field:any, rowIndex: number,rowData:any) => {
        let result = "input-dropdown-fields";

        selectedSubItems.forEach((selectedItem: any) => {
          const matchingItem = subtableData.find((item: any) => item.client_id === selectedItem.client_id);
          if (matchingItem  && formSubmitted) {
            // Check if roles and timeProfiles are available in newData
            if (matchingItem.client_roles) {
              result = "input-dropdown-fields";
            } else {
              if (field.field === "client_roles" && matchingItem.client_id === rowData.client_id){
                result = "error-input input-dropdown-fields";
              }
            }

            if (matchingItem.client_time_profiles) {
              result = "input-dropdown-fields";
            } else {
              if (field.field === "client_time_profiles" && matchingItem.client_id === rowData.client_id) {
                  result = "error-input input-dropdown-fields";
                }
            }
          } else {
            // If no matchingItem found
            result = "input-dropdown-fields";
          }
        });

console.log("result11",result)
      return result
    }

    const columnsBody = (rowData: any, row: any,x:any) => {
        console.log("rowData-=",rowData)
        console.log("x.field-=",x.field)
        if(x.field === "roles"){
            return (
        <Dropdown
          options={roles && roles}
          value={rowData.roles}
          onChange={(e:any) => onCellValueChange(x, row.rowIndex, e.target.value)}
          placeholder="--"
          className={renderErrorClass(x, row.rowIndex,rowData)}
        />
              )
          } 
        if(x.field === "timeProfiles"){
            return (
                <Dropdown
                options={timeProfiles && timeProfiles}
                value={rowData.timeProfiles}
                onChange={(e:any) => onCellValueChange(x, row.rowIndex, e.target.value)}
                placeholder="--"
                className={renderErrorClass(x, row.rowIndex,rowData)}
              />
              )
          }
        return (
            <td role="cell" style={{fontSize:"0.875rem"}}>{rowData[x.field]}</td>
        )
    }

    const columnsSubBody = (rowData: any, row: any,x:any) => {
        console.log("rowData-=Sub",rowData)
        console.log("x.field-=Sub",x.field)
        if(x.field === "client_roles"){
            return (
                <Dropdown
                options={roles && roles}
                value={rowData.client_roles}
                onChange={(e:any) => onSubCellValueChange(x, row.rowIndex, e.target.value)}
                placeholder="--"
                className={renderSubItemErrorClass(x, row.rowIndex,rowData)}
              />
              )
          } 
        if(x.field === "client_time_profiles"){
            return (
                <Dropdown
                options={timeProfiles && timeProfiles}
                value={rowData.client_time_profiles}
                onChange={(e:any) => onSubCellValueChange(x, row.rowIndex, e.target.value)}
                placeholder="--"
                className={renderSubItemErrorClass(x, row.rowIndex,rowData)}
              />
              )
          } 
        return (
            <td role="cell" style={{fontSize:"0.875rem"}}>{rowData[x.field]}</td>
        )
    }

    const[hasAcc, setHasAcc] = useState(false)
    const rowExpansionTemplate = (data:any) => {
        console.log("datqweqa",data)
        if (data && data.children) {
            setSubTableData(data.children);

            const hasAccountant = selectedItems.some((item:any) => item.client_name === data.client_name);
            console.log("hasAccountant",hasAccountant)
            if(hasAccountant){
                setHasAcc(true)
            } else {
                setHasAcc(false)
            }
        }
        return (
            <div className="p-3">
                <DataTable value={data.children}
                selection={selectedSubItems}
                onSelectionChange={(e: any) => setSelectedSubItems(e.value)}
                className={hasAcc ? "p-disabled SubItemsDataTable" : "SubItemsDataTable"}
                selectionMode={'checkbox'}
                >
                <Column
                        selectionMode="multiple"
                        frozen
                        resizeable={false}
                        style={{ width: "34px" }}
                        field="selection"
                        columnKey="selection"
                      ></Column>
                {clientHeaderColumns &&
                    clientHeaderColumns.map((x: any, index: any) => {
                    return (
                        <Column
                            key={index}
                            field={x.field}
                            header={x.headerName}
                        body={(rowData:any,row:any) => columnsSubBody(rowData,row,x)}
                        />
                    );
                    })}
                </DataTable>
            </div>
        );
    };

    
    const onResetClicked = () => {
        formMethods.reset();
      };

    const footer = () => {
        return (
          <div style={{ textAlign: "center", margin:"10px 0" }}>
            <SubmitButton
              label="Invite User"
              testId="submit-button"
              loading={false}
              icon={<PlaylistAddCheckOutlinedIcon />}
              variant={SubmitButtonVariant.Primary}
              />
            <ResetButton testId="reset-button" onClickHandler={onResetClicked} />
          </div>
        );
      };
    
    console.log("rows123",rows)
    console.log("rows123",tableData)

    return (
        <div className="card">
            <Toast ref={toast} />
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Box sx={{width:"50%",marginBottom:"50px"}}>

                <RHookFormTextFieldWithLabel
                  label="First Name"
                  id="name1"
                  testId={`first-name`}
                  labelPosition={LabelPosition.Left}
                  required
                  labelColumns={4}
                  fieldColumns={8}
                />
                <RHookFormTextFieldWithLabel
                  label="Last Name"
                  id="name2"
                  testId={`last-name`}
                  labelPosition={LabelPosition.Left}
                  required
                  labelColumns={4}
                  fieldColumns={8}
                />
                <RHookFormTextFieldWithLabel
                  label="Email"
                  id="email"
                  testId={`email`}
                  labelPosition={LabelPosition.Left}
                  required
                  labelColumns={4}
                  fieldColumns={8}
                />
                
                </Box>
            <DataTable value={rows && tableData} 
                expandedRows={expandedRows} 
                lazy
                onRowToggle={(e:any) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    dataKey="client_name" 
                    selection={selectedItems}
                    onSelectionChange={(e: any) => setSelectedItems(e.value)}
                    selectionMode={'checkbox'}
                    footer={footer}
                    loading={isLoading}
                    editMode="cell"
                    >
                      <Column
                        selectionMode="multiple"
                        frozen
                        resizeable={false}
                        style={{ width: "34px" }}
                        field="selection"
                        columnKey="selection"
                      ></Column>

                <Column expander={allowExpansion} style={{ width: '35px' }} />
                {columns &&
                    columns.map((x: any, index: any) => {
                    return (
                        <Column
                            key={index}
                            field={x.field}
                            header={x.headerName}
                        body={(rowData:any,row:any) => columnsBody(rowData,row,x)}
                        />
                    );
                    })}
            </DataTable>
            </form>
            </FormProvider>
        </div>
    );
}