import { AccountantList } from 'components/Accountant';
import { AuthenticatedLayout } from 'layouts/AuthenticatedLayout/AuthenticatedLayout';
import { FC } from 'react';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { InviteUsers } from 'components/InviteUsers';

export interface IInviteUsersPage { }

export const InviteUsersPage: FC<IInviteUsersPage> = (
  props: IInviteUsersPage
) => {
  return (
    <AuthenticatedLayout
      pageTitle="Invite Users"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Invite Users', navigateTo: '/invite/user'}]}
      mainContents={<InviteUsers />}
    />
  );
};
