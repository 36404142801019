import { InvoiceReport } from "components/Reports";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { InvoiceAdminReport } from "components/Reports/InvoiceReport/InvoiceAdminReport";
import { UpdateInvoice } from "components/Reports/Update Invoice";

export interface IUpdateInvoiceAdminPage {}

export const UpdateInvoiceAdminPage: FC<IUpdateInvoiceAdminPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Update Invoice"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<UpdateInvoice />}
    />
  );
};
