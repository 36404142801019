import { ChangePassword } from "components/ChangePassword";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { DefaultSettings } from "components/DefaultSettings";

export const DefaultSettingsPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Default Settings"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Default Settings', navigateTo: '/me/default-settings'}]}
      mainContents={<DefaultSettings testId="default-settings-setup-page" />}
    />
  );
};
