import { Box, InputAdornment, OutlinedInput } from '@mui/material'
import { EnhancedDropDownRHFWithLabel, EnhancedTextAreaFieldRHF, FieldLayout, LabelPosition, RHookFormDatePicker, RHookFormMaskedTextFieldWithLabel, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React, { useState } from 'react'
import { InputNumber } from 'primereact/inputnumber';
import "./UpdateInvoiceDataTableStyle.css";
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
export interface IUpdateInvoiceDetailsByRefNo {
  formMethods:any;
  paymentDate:any;
  paymentDatePlaceHolder:any
  onPaymentDateChangeHandler:(item:any)=>void;
  refundDatePlaceHolder:any
  onRefundDateChangeHandler:(item:any)=>void;
  companyName:any,
  paidAmountValue:(value:any) => void;
  refundAmountValue:(value:any) => void;
  onPaymentStatusChangeHandler:(value:any) => void;

}
export const UpdateInvoiceDetailsByRefNo: React.FC<IUpdateInvoiceDetailsByRefNo> = ({
  formMethods,
  paymentDate,
  paymentDatePlaceHolder,
  onPaymentDateChangeHandler,
  refundDatePlaceHolder,
  onRefundDateChangeHandler,
  companyName,
  paidAmountValue,
  refundAmountValue,
  onPaymentStatusChangeHandler
}) => {
    return (
    <>
    <FieldLayout
        label="Client"
        // isRequired={required}
        testId=""
        labelPosition={LabelPosition.Left}
        labelColumns={4}
        fieldColumns={8}
      >
        <Box sx={{
          background:"whitesmoke",
          // background:"lightgrey",
          borderRadius: "5px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          padding:"8px"
        }}
        >{companyName}</Box>
      </FieldLayout>
    {/* <RHookFormMaskedTextFieldWithLabel
        label="Invoice No."
        id="invoiceNo"
        testId="invoice-number"
        labelPosition={LabelPosition.Left}
        inputMaskPattern={"INV00000"}
        placeholder='INV00000'
        readOnly={true}
      /> */}
    <RHookFormTextFieldWithLabel
        label="Invoice No."
        id="invoiceNo"
        testId="invoice-number"
        labelPosition={LabelPosition.Left}
        placeholder='INV00000'
        readOnly={true}
      />
    <EnhancedDropDownRHFWithLabel
        label="Payment Status"
        selectName="status"
        items={[
            { label: "Paid", value: true },
            { label: "Not Paid", value: false },
        ]}
        // selectedItem={26}
        testId="payment-status"
        labelPosition={LabelPosition.Left}
        onSelectionChangeHandler={onPaymentStatusChangeHandler}
        // required
      />
      {/* <RHookFormTextFieldWithLabel
        id="paidAmount"
        testId={`paid-amount`}
        label="Paid Amount"
        // required
        labelPosition={LabelPosition.Left}
        defaultValue={"0.00"}
    />
    <FieldLayout
      label="Paid Amount"
      // isRequired={required}
      testId=""
      labelPosition={LabelPosition.Left}
      labelColumns={4}
      fieldColumns={8}
    >
    <OutlinedInput
        id="outlined-adornment-paidAmount"
        endAdornment={<InputAdornment position="end">$</InputAdornment>}
        sx={{p:"8.5px 14px","& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":{p:0}}}
        className='UpdateInvoiceDataTableStyle'
        />
        </FieldLayout> */}
    <FieldLayout
            label="Paid Amount"
            // isRequired={required}
            testId=""
            labelPosition={LabelPosition.Left}
            labelColumns={4}
            fieldColumns={8}
          >
        <InputNumber 
            id='paidAmount'
            {...formMethods.register('paidAmount')} // Register the input with react-hook-form using methods.register
            inputId="currency-us" 
            value={formMethods.watch('paidAmount') || 0} 
            onValueChange={(e:any) => paidAmountValue(e.target.value)} 
            mode="currency" 
            currency="USD" 
            locale="en-US" 
            className='UpdateInvoiceDataTableStyle'
            />
        </FieldLayout> 
    {/* <RHookFormDatePicker
        name="paymentDate"
        labelPosition={LabelPosition.Left}
        testId="payment-date"
        // required
        label="Payment Date"
    /> */}
    <FieldLayout
      label="Payment Date"
      // isRequired={isRequired}
      testId=""
      labelPosition={LabelPosition.Left}
      labelColumns={4}
      fieldColumns={8}
      >
      <Calendar
        id="paymentDate"
        {...formMethods.register('paymentDate')} // Register the input with react-hook-form using methods.register
        value={formMethods.watch('paymentDate') || null} // Set the value using watch to reflect changes
        showIcon
        // dateFormat="dd/mm/yy" // Customize the date format as needed
        placeholder={paymentDatePlaceHolder && paymentDatePlaceHolder.charAt(2) === '*' ? 'Unmask to view / edit' : 'dd/mm/yyyy'}
        disabled={paymentDatePlaceHolder && paymentDatePlaceHolder.charAt(2) === '*'}  //if unmask then it is enabled
        className="w-full"
        style={{ height: '2.6rem', margin:"8px 0px 4px 0px"}}
        onChange={onPaymentDateChangeHandler}
        dateFormat="M dd, yy"
      />
    </FieldLayout>
    {/* <RHookFormTextFieldWithLabel
        id="refundAmount"
        testId={`refund-amount`}
        label="Refund Amount"
        // required
        labelPosition={LabelPosition.Left}
    /> */}
    <FieldLayout
        label="Refund Amount"
        // isRequired={required}
        testId=""
        labelPosition={LabelPosition.Left}
        labelColumns={4}
        fieldColumns={8}
      >
    <InputNumber 
        id='refundAmount'
        {...formMethods.register('refundAmount')} // Register the input with react-hook-form using methods.register
        inputId="currency-us" 
        value={formMethods.watch('refundAmount') || 0} 
        onValueChange={(e:any) => refundAmountValue(e.target.value)} 
        mode="currency" 
        currency="USD" 
        locale="en-US" 
        className='UpdateInvoiceDataTableStyle'
        // defaultValue={"10"}
        />
    </FieldLayout> 
    {/* <RHookFormDatePicker
        name="refundDate"
        labelPosition={LabelPosition.Left}
        testId="refund-date"
        // required
        label="Refund Date"
    /> */}
    <FieldLayout
      label="Refund Date"
      // isRequired={isRequired}
      testId=""
      labelPosition={LabelPosition.Left}
      labelColumns={4}
      fieldColumns={8}
      >
      <Calendar
        id="refundDate"
        {...formMethods.register('refundDate')} // Register the input with react-hook-form using methods.register
        value={formMethods.watch('refundDate') || null} // Set the value using watch to reflect changes
        showIcon
        // dateFormat="dd/mm/yy" // Customize the date format as needed
        placeholder={refundDatePlaceHolder && refundDatePlaceHolder.charAt(2) === '*' ? 'Unmask to view / edit' : 'dd/mm/yyyy'}
        disabled={refundDatePlaceHolder && refundDatePlaceHolder.charAt(2) === '*'}  //if unmask then it is enabled
        className="w-full"
        style={{ height: '2.6rem', margin:"8px 0px 8px 0px"}}
        onChange={onRefundDateChangeHandler}
        dateFormat="M dd, yy"
      />
    </FieldLayout>
    <EnhancedTextAreaFieldRHF
        label="Notes"
        id="notes"
        testId={`notes`}
        placeholder="Notes"
        labelPosition={LabelPosition.Left}
    />
    </>
  )
}

