import { TaxSlipCategoryList } from "components/TaxSlipCategory";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { PDFFiles } from "components/PDF Settings/PDF Files";

export interface IPDFFilesPage {}

export const PDFFilesPage: FC<IPDFFilesPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="PDF Files"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'PDF Files', navigateTo: '/pdf-settings/pdf-files'}]}
      mainContents={<PDFFiles />}
    />
  );
};
