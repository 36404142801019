import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "./CSVInformationUI.css";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { InputNumber } from "primereact/inputnumber";
import {
  ConsolidateIndicatorDDL,
  RL16OtherInformationList,
  RL1OtherInformationList,
  RL3OtherInformationList,
  T3OtherInformationList,
  T4AOtherInformationList,
  T4OtherInformationList,
  employeeCodeList,
  employeeTypeList,
  employerOfferedDentalBenifits,
  payPeriodsList,
  provinceShortCodeList,
  recipientTypeOptionsDDL,
  recipientTypeRL3OptionsDDL,
  rl1CaseOList,
  slipTypeList,
} from "./otherInfoDdlLists";
import { checkSINValidity } from "../T4SlipSetup/validator";
import { RHookFormMaskedTextField } from "@websential/cosmic";
import { IMaskMixin } from "react-imask";
import { TextField } from "@mui/material";
import { renderWithClient } from "../../../../tests/utils";
import { useQuery } from "react-query";
import { RL3SlipService } from "services/RL3SlipService";
export interface ICsvInfoItems {
  employeeCode: number;
  socialInsurance: string;
  firstName: string;
  lastName: string;
  initials: string;
  dateOfBirth: string;
  status: string;
  type: string;
  businessNumber: string;
  businessName: string;
  address: string;
  city: string;
  postCode: string;
  province: string;
  email: string;
  phone1: string;
  ext1: number;
  phone2: string;
  ext2: number;
  provinceOfEmployment: string;
  cppQppExempt: string;
  eiExempt: string;
  ppipExempt: string;
  employmentCode: number;
  payPeriod: number;
  reducedEi: string;
  employmentIncome: string | number;
  cppPremium: number | string;
  qppPremiums: number | string;
  eiPremiums: number | string;
  rppPremiums: number | string;
  incomeTaxDeducted: number | string;
  eiInsurableEarnings: number | string;
  cppQppPensionableEarnings: number | string;
  unionDues: number | string;
  charitableDonations: number | string;
  registrationNumber: number | string;
  pensionAdjustment: number | string;
  ppipPremiums: number | string;
  ppipInsurableEarnings: number | string;
  t4OtherInformation1: string | null;
  otherInfo1Amount: number | string;
  t4OtherInformation2: string | null;
  otherInfo2Amount: number | string;
  t4OtherInformation3: string | null;
  otherInfo3Amount: number | string;
  t4OtherInformation4: string | null;
  otherInfo4Amount: number | string;
  t4OtherInformation5: string | null;
  otherInfo5Amount: number | string;
  t4OtherInformation6: string | null;
  otherInfo6Amount: number | string;
  slipType: string;
  pensionablePeriods: number;
  slipStatus: string;

  // rl1
  aRevenusDEmploi: number | string;
  bCotisationAuRrq: number | string;
  cCotÀLAssuranceEmploi: number | string;
  dCotisationÀUnRpa: number | string;
  eImpôtDuQuébecRetenu: number | string;
  fCotisationSyndicale: number | string;
  gSalaireAdmissibleAuRrq: number | string;
  hCotisationAuRqap: number | string;
  iSalaireAdmissibleAuRqap: number | string;
  jRégimePrivéDAssMaladie: number | string;
  kVoyagesRégionÉloignée: number | string;
  lAutresAvantages: number | string;
  mCommissions: number | string;
  nDonsDeBienfaisance: number | string;
  oAutresRevenus: number | string;
  pRégimedX92assInterentreprises: number | string;
  qSalairesDifférés: number | string;
  rRevenuSituéDansUneRéserve: number | string;
  sPourboiresReçus: number | string;
  tPourboiresAttribués: number | string;
  uRetraiteProgressive: number | string;
  vNourritureEtLogement: number | string;
  wVéhiculeÀMoteur: number | string;
  codeCaseO: string;
  otherInfo1: string;
  otherInfo2: string;
  otherInfo3: string;
  otherInfo4: string;
}
export interface IDataTable {
  rows: ICsvInfoItems[];
  columns: any;
  showIndexColumn: boolean;
  isLoading?: boolean;
  onSnackbarMessageData: (value: any) => void;
  onDataSubmit: (value: any) => void;
  defaultSettingsData?: any;
}

const useGetForeingCurrencyDDL = () =>
  useQuery(
    "",
    (data) => {
      return RL3SlipService.getForeingCurrencyDDL();
    },
    { refetchOnWindowFocus: false }
  );

const PrimeDataGrid: React.FC<IDataTable> = ({
  rows,
  columns,
  showIndexColumn,
  isLoading,
  onSnackbarMessageData,
  onDataSubmit,
  defaultSettingsData,
}) => {
  const CSVInformationUI = useSelector(
    (state: IAppState) => state.CSVInformationUI
  );
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);

  const {
    isLoading: isLoadingForeignCurrencyDDL,
    data: foreignCurrencyDDList,
  } = useGetForeingCurrencyDDL();

  const foreignCurrencyDDListItems = useMemo(() => {
    if (foreignCurrencyDDList && Array.isArray(foreignCurrencyDDList)) {
      console.log("foreignCurrencyDDList1", foreignCurrencyDDList);
      let foreignCurrency: any[] = foreignCurrencyDDList.map(
        (resourceItem: any) => ({
          label: resourceItem.label,
          value: resourceItem.value,
          name: resourceItem.name,
        })
      );
      foreignCurrency.unshift({
        label: "--",
        value: null,
      });
      console.log("foreignCurrency", foreignCurrency);
      return foreignCurrency;
    }
  }, [foreignCurrencyDDList]);

  // Custom column for row indices
  const indexColumn = {
    body: (rowData: any, row: any) => {
      return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
    },
    header: "#",
  };

  const MaskedInput = IMaskMixin(TextField);

  const [tableData, setTableData] = useState<any>(rows && rows);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const rowsWithTwoDecimalNumbers =
    rows &&
    rows.map((item: any) => ({
      ...item,
      employmentIncome: parseFloat(item.employmentIncome).toFixed(2),
      qppPremiums: parseFloat(item.qppPremiums).toFixed(2),
      cppPremium: parseFloat(item.cppPremium).toFixed(2),
      eiPremiums: parseFloat(item.eiPremiums).toFixed(2),
      rppPremiums: parseFloat(item.rppPremiums).toFixed(2),
      secondCppContributions: parseFloat(item.secondCppContributions).toFixed(
        2
      ),
      secondQppContributions: parseFloat(item.secondQppContributions).toFixed(
        2
      ),
      incomeTaxDeducted: parseFloat(item.incomeTaxDeducted).toFixed(2),
      eiInsurableEarnings: parseFloat(item.eiInsurableEarnings).toFixed(2),
      cppQppPensionableEarnings: parseFloat(
        item.cppQppPensionableEarnings
      ).toFixed(2),
      unionDues: parseFloat(item.unionDues).toFixed(2),
      charitableDonations: parseFloat(item.charitableDonations).toFixed(2),
      registrationNumber: parseFloat(item.registrationNumber).toFixed(2),
      pensionAdjustment: parseFloat(item.pensionAdjustment).toFixed(2),
      ppipPremiums: parseFloat(item.ppipPremiums).toFixed(2),
      ppipInsurableEarnings: parseFloat(item.ppipInsurableEarnings).toFixed(2),
      otherInfo1Amount: parseFloat(item.otherInfo1Amount).toFixed(2),
      otherInfo2Amount: parseFloat(item.otherInfo2Amount).toFixed(2),
      otherInfo3Amount: parseFloat(item.otherInfo3Amount).toFixed(2),
      otherInfo4Amount: parseFloat(item.otherInfo4Amount).toFixed(2),
      otherInfo5Amount: parseFloat(item.otherInfo5Amount).toFixed(2),
      otherInfo6Amount: parseFloat(item.otherInfo6Amount).toFixed(2),

      //rl1
      aRevenusDEmploi: parseFloat(item.aRevenusDEmploi).toFixed(2),
      bCotisationAuRrq: parseFloat(item.bCotisationAuRrq).toFixed(2),
      bbCotisationrrqsuppl: parseFloat(item.bbCotisationrrqsuppl).toFixed(2),
      cCotÀLAssuranceEmploi: parseFloat(item.cCotÀLAssuranceEmploi).toFixed(2),
      dCotisationÀUnRpa: parseFloat(item.dCotisationÀUnRpa).toFixed(2),
      eImpôtDuQuébecRetenu: parseFloat(item.eImpôtDuQuébecRetenu).toFixed(2),
      fCotisationSyndicale: parseFloat(item.fCotisationSyndicale).toFixed(2),
      gSalaireAdmissibleAuRrq: parseFloat(item.gSalaireAdmissibleAuRrq).toFixed(
        2
      ),
      hCotisationAuRqap: parseFloat(item.hCotisationAuRqap).toFixed(2),
      iSalaireAdmissibleAuRqap: parseFloat(
        item.iSalaireAdmissibleAuRqap
      ).toFixed(2),
      jRégimePrivéDAssMaladie: parseFloat(item.jRégimePrivéDAssMaladie).toFixed(
        2
      ),
      kVoyagesRégionÉloignée: parseFloat(item.kVoyagesRégionÉloignée).toFixed(
        2
      ),
      lAutresAvantages: parseFloat(item.lAutresAvantages).toFixed(2),
      mCommissions: parseFloat(item.mCommissions).toFixed(2),
      nDonsDeBienfaisance: parseFloat(item.nDonsDeBienfaisance).toFixed(2),
      oAutresRevenus: parseFloat(item.oAutresRevenus).toFixed(2),
      pRégimedX92assInterentreprises: parseFloat(
        item.pRégimedX92assInterentreprises
      ).toFixed(2),
      qSalairesDifférés: parseFloat(item.qSalairesDifférés).toFixed(2),
      rRevenuSituéDansUneRéserve: parseFloat(
        item.rRevenuSituéDansUneRéserve
      ).toFixed(2),
      sPourboiresReçus: parseFloat(item.sPourboiresReçus).toFixed(2),
      tPourboiresAttribués: parseFloat(item.tPourboiresAttribués).toFixed(2),
      uRetraiteProgressive: parseFloat(item.uRetraiteProgressive).toFixed(2),
      vNourritureEtLogement: parseFloat(item.vNourritureEtLogement).toFixed(2),
      wVéhiculeÀMoteur: parseFloat(item.wVéhiculeÀMoteur).toFixed(2),

      // t4a
      pensionOrSuperannuation: parseFloat(item.pensionOrSuperannuation).toFixed(
        2
      ),
      lumpSumPayments: parseFloat(item.lumpSumPayments).toFixed(2),
      lumpSumPaymentsOtherAmounts: parseFloat(
        item.lumpSumPaymentsOtherAmounts
      ).toFixed(2),
      selfEmployedCommissions: parseFloat(item.selfEmployedCommissions).toFixed(
        2
      ),
      annuities: parseFloat(item.annuities).toFixed(2),
      annuitiesOther: parseFloat(item.annuitiesOther).toFixed(2),
      fees: parseFloat(item.fees).toFixed(2),

      //t5
      actualAmountOfEligibleDivinends: parseFloat(
        item.actualAmountOfEligibleDivinends
      ).toFixed(2),
      interestFromCanadianSources: parseFloat(
        item.interestFromCanadianSources
      ).toFixed(2),
      capitalGainDividends: parseFloat(item.capitalGainDividends).toFixed(2),
      actualAmountOfDivinendsOtherThanEligible: parseFloat(
        item.actualAmountOfDivinendsOtherThanEligible
      ).toFixed(2),
      otherIncomeFromCanadianSources: parseFloat(
        item.otherIncomeFromCanadianSources
      ).toFixed(2),
      foreignIncome: parseFloat(item.foreignIncome).toFixed(2),
      foreignTaxPaid: parseFloat(item.foreignTaxPaid).toFixed(2),
      royaltiesFromCanadianSources: parseFloat(
        item.royaltiesFromCanadianSources
      ).toFixed(2),
      accuredIncomeOrAnnuities: parseFloat(
        item.accuredIncomeOrAnnuities
      ).toFixed(2),
      amountEligibleForResourceAllowanceDeduction: parseFloat(
        item.amountEligibleForResourceAllowanceDeduction
      ).toFixed(2),

      //rl3
      actualAmountOfOrdinaryDivinends: parseFloat(
        item.actualAmountOfOrdinaryDivinends
      ).toFixed(2),
      grossForeignInvestmentIncome: parseFloat(
        item.grossForeignInvestmentIncome
      ).toFixed(2),
      foreignIncomeTax: parseFloat(item.foreignIncomeTax).toFixed(2),
      interestFromLinkedNotes: parseFloat(item.interestFromLinkedNotes).toFixed(
        2
      ),

      //t3
      capitalGains: parseFloat(item.capitalGains).toFixed(2),
      capitalGainsEligible: parseFloat(item.capitalGainsEligible).toFixed(2),
      actualAmountOtherThanEligible: parseFloat(
        item.actualAmountOtherThanEligible
      ).toFixed(2),
      otherIncome: parseFloat(item.otherIncome).toFixed(2),

      //rl16
      singlePensionPayments: parseFloat(item.singlePensionPayments).toFixed(2),
      actualAmountOfEligibleDividends: parseFloat(
        item.actualAmountOfEligibleDividends
      ).toFixed(2),
      pensionPaymentGivingEntitlement: parseFloat(
        item.pensionPaymentGivingEntitlement
      ).toFixed(2),
      foreignBusinessIncome: parseFloat(item.foreignBusinessIncome).toFixed(2),
      foreignNonBusinessIncome: parseFloat(
        item.foreignNonBusinessIncome
      ).toFixed(2),
      foreignIncomeTaxOnBusinessIncome: parseFloat(
        item.foreignIncomeTaxOnBusinessIncome
      ).toFixed(2),
      foreignIncomeTaxOnNonBusinessIncome: parseFloat(
        item.foreignIncomeTaxOnNonBusinessIncome
      ).toFixed(2),
      costBaseAdjustmentOfCapitalInterest: parseFloat(
        item.costBaseAdjustmentOfCapitalInterest
      ).toFixed(2),
      donationFromAReligiousOrganization: parseFloat(
        item.donationFromAReligiousOrganization
      ).toFixed(2),
    }));

  useEffect(() => {
    if (rows) {
      // setTableData(rows)
      setTableData(rowsWithTwoDecimalNumbers);
    }
  }, [rows]);

  const onCellValueChange = (event: any, rowIndex: number, field: any) => {
    const updatedData = [...tableData];
    updatedData[rowIndex][event.field] = field;
    setTableData(updatedData);
  };

  const addDashesInSIN = (sin: any) => {
    if (sin !== null) {
      const numericValue = sin.replace(/\D/g, "");
      const maxLength = Math.min(numericValue.length, 9);
      console.log("numericValue", numericValue);
      let formattedValue = "";
      for (let i = 0; i < maxLength; i++) {
        if (i === 3 || i === 6) {
          formattedValue += "-";
        }
        formattedValue += numericValue.charAt(i);
      }
      console.log("formattedValuefor", formattedValue);
      return formattedValue;
    }
  };

  const handleFormSubmit = () => {
    setFormSubmitted(true);
    let invalidSin: any = false;
    let invalidBN: any = false;
    console.log("tableData", tableData);
    const hasNullOrEmpty = tableData.some((row: any) => {
      console.log("row--", row);
      for (const key of Object.keys(row)) {
        if (
          // key === 'firstName' ||
          // key === 'lastName' ||
          key === "status" ||
          key === "address" ||
          key === "city" ||
          key === "postCode" ||
          key === "province" ||
          key === "provinceOfEmployment" ||
          key === "payPeriod" ||
          key === "slipType" ||
          key === "slipStatus" ||
          key === "vendorStatus"
          // key === 'payeeType' ||
          // key === 'socialInsurance' ||
          // key === 'businessName'
        ) {
          console.log("key--", key);
          console.log("row[key]", row[key]);
          if (row[key] === null || row[key] === "") {
            return true;
          }
          // if(key === 'firstName'){
          //   if (row[key] === null || row[key] && row[key].length > 12) {
          //           return true;
          //   }
          // }
          // if(key === 'lastName'){
          //   if (row[key] === null || row[key] && row[key].length > 20) {
          //           return true;
          //   }
          // }
          if (key === "address") {
            if (row[key] === null || (row[key] && row[key].length > 50)) {
              return true;
            }
          }
          if (key === "city") {
            if (row[key] === null || (row[key] && row[key].length > 28)) {
              return true;
            }
          }
        } else if (
          key === "firstName" ||
          key === "lastName" ||
          key === "payeeType" ||
          key === "socialInsurance" ||
          key === "businessName" ||
          key === "businessNumber" ||
          key === "firstLineOfRecipient" ||
          key === "secondLineOfRecipient" ||
          key === "trustAccountNumber"
        ) {
          if (CSVInformationUI.tag === "T4A") {
            console.log("key 33", key);
            console.log("row[key]", row[key]);
            if (row.payeeType === "Business") {
              console.log("2345890- busines");
              if (
                (row.firstName === null || row.firstName === "") &&
                (row.lastName === null || row.lastName === "") &&
                (row.socialInsurance === null || row.socialInsurance === "") &&
                row.businessNumber !== null &&
                row.businessNumber !== "" &&
                row.businessName !== null &&
                row.businessName !== ""
              ) {
                console.log("2345890- busines false");
                return false;
              } else if (
                (row.firstName && row.firstName.length > 0) ||
                (row.lastName !== null && row.lastName.length > 0) ||
                (row.socialInsurance && row.socialInsurance.length > 0)
              ) {
                return false;
              } else {
                console.log("2345890- busines true");
                return true;
              }
            } else if (row.payeeType === "Personal") {
              console.log("2345890- personal");

              if (row.socialInsurance === null || row.socialInsurance === "") {
                console.log("2345890- per SIN");
                if (
                  row.socialInsurance === null ||
                  row.socialInsurance === ""
                ) {
                  return true;
                }
              } else if (
                row.socialInsurance &&
                row.socialInsurance.length > 0
              ) {
                const sinWithDash = addDashesInSIN(row.socialInsurance);
                console.log("sinWithDash", sinWithDash);
                if (
                  row.socialInsurance === null ||
                  row.socialInsurance === "" ||
                  (sinWithDash && sinWithDash.length <= 11)
                ) {
                  if (sinWithDash && sinWithDash.length == 11) {
                    // let sin = row[key] && row[key].replace(/-/g, '')
                    let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                    const output = checkSINValidity(sin);
                    console.log("sin", sin);
                    console.log("output", output);
                    let maxLength = 9;
                    if (sin && sin.length == maxLength && output % 10 === 0) {
                      invalidSin = false;
                    } else {
                      invalidSin = true;
                      return true;
                    }
                  } else {
                    return true;
                  }
                }
              }

              if (
                ((row.firstName !== null && row.firstName !== "") ||
                  (typeof row.firstName === "string" &&
                    row.firstName !== "" &&
                    row.firstName.length > 12)) &&
                ((row.lastName !== null && row.lastName !== "") ||
                  (typeof row.lastName === "string" &&
                    row.lastName !== "" &&
                    row.lastName.length > 20)) &&
                // (row.socialInsurance !== null && row.socialInsurance !== "") &&
                (row.businessNumber !== null || row.businessNumber !== "") &&
                (row.businessName !== null || row.businessName !== "")
              ) {
                console.log("2345890- personal FN");
                return false;
              } else {
                console.log("2345890- personal true");
                return true;
              }

              // if (row.firstName === null || row.firstName === '') {
              //     console.log("2345890- per FN")
              //     return true;
              //   }
              //   if (row.firstName === null || row.firstName && row.firstName.length > 12) {
              //     return true;
              //   }
              //   if (row.lastName === null || row.lastName === '') {
              //     console.log("2345890- per LN")
              //     return true;
              //   }
              //   if (row.lastName === null || row.lastName && row.lastName.length > 20) {
              //     return true;
              //   }
              //   if (row.businessName === null || row.businessName === '') {
              //     console.log("2345890- per BN")
              //     return false;
              //   }
              //   if (row.businessNumber === null || row.businessNumber === '') {
              //     console.log("2345890- per BNum")
              //     return false;
              //   }
            }
          } else if (CSVInformationUI.tag === "T5") {
            if (row.recipientType === "Individual") {
              if (
                row.firstName !== null &&
                row.firstName !== "" &&
                row.lastName !== null &&
                row.lastName !== "" &&
                row.socialInsurance !== null &&
                row.socialInsurance !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.socialInsurance && row.socialInsurance.length > 0) {
                  const sinWithDash = addDashesInSIN(row.socialInsurance);
                  console.log("sinWithDash", sinWithDash);
                  if (
                    row.socialInsurance === null ||
                    row.socialInsurance === "" ||
                    (sinWithDash && sinWithDash.length <= 11)
                  ) {
                    if (sinWithDash && sinWithDash.length == 11) {
                      // let sin = row[key] && row[key].replace(/-/g, '')
                      let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                      const output = checkSINValidity(sin);
                      console.log("sin", sin);
                      console.log("output", output);
                      let maxLength = 9;
                      if (sin && sin.length == maxLength && output % 10 === 0) {
                        invalidSin = false;
                      } else {
                        invalidSin = true;
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
                return false;
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (row.recipientType === "Joint Account") {
              if (
                row.firstName !== null &&
                row.firstName !== "" &&
                row.lastName !== null &&
                row.lastName !== "" &&
                row.socialInsurance !== null &&
                row.socialInsurance !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.socialInsurance && row.socialInsurance.length > 0) {
                  const sinWithDash = addDashesInSIN(row.socialInsurance);
                  console.log("sinWithDash", sinWithDash);
                  if (
                    row.socialInsurance === null ||
                    row.socialInsurance === "" ||
                    (sinWithDash && sinWithDash.length <= 11)
                  ) {
                    if (sinWithDash && sinWithDash.length == 11) {
                      // let sin = row[key] && row[key].replace(/-/g, '')
                      let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                      const output = checkSINValidity(sin);
                      console.log("sin", sin);
                      console.log("output", output);
                      let maxLength = 9;
                      if (sin && sin.length == maxLength && output % 10 === 0) {
                        invalidSin = false;
                      } else {
                        invalidSin = true;
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
                return false;
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (row.recipientType === "Corporation") {
              console.log("row.trustAccountNumber--", row.trustAccountNumber);
              if (
                row.firstLineOfRecipient !== null &&
                row.firstLineOfRecipient !== "" &&
                row.secondLineOfRecipient !== null &&
                row.secondLineOfRecipient !== "" &&
                row.businessNumber !== null &&
                row.businessNumber !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.businessNumber && row.businessNumber.length > 0) {
                  if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length == 15
                  ) {
                    const firstNineDigits: string =
                      row.businessNumber && row.businessNumber.substring(0, 9);
                    const output = checkSINValidity(firstNineDigits);
                    if (output % 10 === 0) {
                      invalidBN = false;
                    } else {
                      invalidBN = true;
                      return true;
                    }
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length < 15
                  ) {
                    invalidBN = true;
                    return true;
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length > 15
                  ) {
                    invalidBN = true;
                    return true;
                  }
                }
                return false;
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (
              row.recipientType === "Association Trust Club Partnership"
            ) {
              if (
                row.firstLineOfRecipient !== null &&
                row.firstLineOfRecipient !== "" &&
                row.secondLineOfRecipient !== null &&
                row.secondLineOfRecipient !== "" &&
                row.trustAccountNumber !== null &&
                row.trustAccountNumber !== ""
              ) {
                console.log("2345890- Individual if");
                if (
                  row.trustAccountNumber &&
                  row.trustAccountNumber.length > 0
                ) {
                  if (row.trustAccountNumber.length === 9) {
                    return false;
                  } else {
                    return true;
                  }
                }
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (row.recipientType === "Corporation") {
              console.log("row.trustAccountNumber--", row.trustAccountNumber);
              if (
                row.firstLineOfRecipient !== null &&
                row.firstLineOfRecipient !== "" &&
                row.secondLineOfRecipient !== null &&
                row.secondLineOfRecipient !== "" &&
                row.businessNumber !== null &&
                row.businessNumber !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.businessNumber && row.businessNumber.length > 0) {
                  if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length == 15
                  ) {
                    const firstNineDigits: string =
                      row.businessNumber && row.businessNumber.substring(0, 9);
                    const output = checkSINValidity(firstNineDigits);
                    if (output % 10 === 0) {
                      invalidBN = false;
                    } else {
                      invalidBN = true;
                      return true;
                    }
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length < 15
                  ) {
                    invalidBN = true;
                    return true;
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length > 15
                  ) {
                    invalidBN = true;
                    return true;
                  }
                }
                return false;
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            }
          } else if (CSVInformationUI.tag === "T3") {
            if (row.recipientType === "Individual") {
              if (
                row.firstName !== null &&
                row.firstName !== "" &&
                row.lastName !== null &&
                row.lastName !== "" &&
                row.socialInsurance !== null &&
                row.socialInsurance !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.socialInsurance && row.socialInsurance.length > 0) {
                  const sinWithDash = addDashesInSIN(row.socialInsurance);
                  console.log("sinWithDash", sinWithDash);
                  if (
                    row.socialInsurance === null ||
                    row.socialInsurance === "" ||
                    (sinWithDash && sinWithDash.length <= 11)
                  ) {
                    if (sinWithDash && sinWithDash.length == 11) {
                      // let sin = row[key] && row[key].replace(/-/g, '')
                      let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                      const output = checkSINValidity(sin);
                      console.log("sin", sin);
                      console.log("output", output);
                      let maxLength = 9;
                      if (sin && sin.length == maxLength && output % 10 === 0) {
                        invalidSin = false;
                      } else {
                        invalidSin = true;
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
                return false;
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (row.recipientType === "Joint Account") {
              if (
                row.firstName !== null &&
                row.firstName !== "" &&
                row.lastName !== null &&
                row.lastName !== "" &&
                row.socialInsurance !== null &&
                row.socialInsurance !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.socialInsurance && row.socialInsurance.length > 0) {
                  const sinWithDash = addDashesInSIN(row.socialInsurance);
                  console.log("sinWithDash", sinWithDash);
                  if (
                    row.socialInsurance === null ||
                    row.socialInsurance === "" ||
                    (sinWithDash && sinWithDash.length <= 11)
                  ) {
                    if (sinWithDash && sinWithDash.length == 11) {
                      // let sin = row[key] && row[key].replace(/-/g, '')
                      let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                      const output = checkSINValidity(sin);
                      console.log("sin", sin);
                      console.log("output", output);
                      let maxLength = 9;
                      if (sin && sin.length == maxLength && output % 10 === 0) {
                        invalidSin = false;
                      } else {
                        invalidSin = true;
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
                return false;
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (row.recipientType === "Corporation") {
              console.log("row.trustAccountNumber--", row.trustAccountNumber);
              if (
                row.firstLineOfRecipient !== null &&
                row.firstLineOfRecipient !== "" &&
                row.secondLineOfRecipient !== null &&
                row.secondLineOfRecipient !== "" &&
                row.businessNumber !== null &&
                row.businessNumber !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.businessNumber && row.businessNumber.length > 0) {
                  if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length == 15
                  ) {
                    const firstNineDigits: string =
                      row.businessNumber && row.businessNumber.substring(0, 9);
                    const output = checkSINValidity(firstNineDigits);
                    if (output % 10 === 0) {
                      invalidBN = false;
                    } else {
                      invalidBN = true;
                      return true;
                    }
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length < 15
                  ) {
                    invalidBN = true;
                    return true;
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length > 15
                  ) {
                    invalidBN = true;
                    return true;
                  }
                }
                return false;
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (
              row.recipientType === "Association Trust Club Partnership"
            ) {
              if (
                row.firstLineOfRecipient !== null &&
                row.firstLineOfRecipient !== "" &&
                row.secondLineOfRecipient !== null &&
                row.secondLineOfRecipient !== "" &&
                row.trustAccountNumber !== null &&
                row.trustAccountNumber !== ""
              ) {
                console.log("2345890- Individual if");
                if (
                  row.trustAccountNumber &&
                  row.trustAccountNumber.length > 0
                ) {
                  if (row.trustAccountNumber.length === 9) {
                    return false;
                  } else {
                    return true;
                  }
                }
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (row.recipientType === "Corporation") {
              console.log("row.trustAccountNumber--", row.trustAccountNumber);
              if (
                row.firstLineOfRecipient !== null &&
                row.firstLineOfRecipient !== "" &&
                row.secondLineOfRecipient !== null &&
                row.secondLineOfRecipient !== "" &&
                row.businessNumber !== null &&
                row.businessNumber !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.businessNumber && row.businessNumber.length > 0) {
                  if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length == 15
                  ) {
                    const firstNineDigits: string =
                      row.businessNumber && row.businessNumber.substring(0, 9);
                    const output = checkSINValidity(firstNineDigits);
                    if (output % 10 === 0) {
                      invalidBN = false;
                    } else {
                      invalidBN = true;
                      return true;
                    }
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length < 15
                  ) {
                    invalidBN = true;
                    return true;
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length > 15
                  ) {
                    invalidBN = true;
                    return true;
                  }
                }
                return false;
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            }
          } else if (CSVInformationUI.tag === "RL3") {
            if (row.recipientType === "Individual") {
              if (
                row.firstName !== null &&
                row.firstName !== "" &&
                row.lastName !== null &&
                row.lastName !== "" &&
                row.socialInsurance !== null &&
                row.socialInsurance !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.socialInsurance && row.socialInsurance.length > 0) {
                  const sinWithDash = addDashesInSIN(row.socialInsurance);
                  console.log("sinWithDash", sinWithDash);
                  if (
                    row.socialInsurance === null ||
                    row.socialInsurance === "" ||
                    (sinWithDash && sinWithDash.length <= 11)
                  ) {
                    if (sinWithDash && sinWithDash.length == 11) {
                      // let sin = row[key] && row[key].replace(/-/g, '')
                      let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                      const output = checkSINValidity(sin);
                      console.log("sin", sin);
                      console.log("output", output);
                      let maxLength = 9;
                      if (sin && sin.length == maxLength && output % 10 === 0) {
                        invalidSin = false;
                      } else {
                        invalidSin = true;
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
                return false;
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (row.recipientType === "Joint Account") {
              if (
                row.firstName !== null &&
                row.firstName !== "" &&
                row.lastName !== null &&
                row.lastName !== "" &&
                row.socialInsurance !== null &&
                row.socialInsurance !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.socialInsurance && row.socialInsurance.length > 0) {
                  const sinWithDash = addDashesInSIN(row.socialInsurance);
                  console.log("sinWithDash", sinWithDash);
                  if (
                    row.socialInsurance === null ||
                    row.socialInsurance === "" ||
                    (sinWithDash && sinWithDash.length <= 11)
                  ) {
                    if (sinWithDash && sinWithDash.length == 11) {
                      // let sin = row[key] && row[key].replace(/-/g, '')
                      let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                      const output = checkSINValidity(sin);
                      console.log("sin", sin);
                      console.log("output", output);
                      let maxLength = 9;
                      if (sin && sin.length == maxLength && output % 10 === 0) {
                        invalidSin = false;
                      } else {
                        invalidSin = true;
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
                return false;
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (row.recipientType === "Corporation") {
              console.log("row.trustAccountNumber--", row.trustAccountNumber);
              if (
                row.firstLineOfRecipient !== null &&
                row.firstLineOfRecipient !== "" &&
                row.secondLineOfRecipient !== null &&
                row.secondLineOfRecipient !== "" &&
                row.businessNumber !== null &&
                row.businessNumber !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.businessNumber && row.businessNumber.length > 0) {
                  if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length == 15
                  ) {
                    const firstNineDigits: string =
                      row.businessNumber && row.businessNumber.substring(0, 9);
                    const output = checkSINValidity(firstNineDigits);
                    if (output % 10 === 0) {
                      invalidBN = false;
                    } else {
                      invalidBN = true;
                      return true;
                    }
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length < 15
                  ) {
                    invalidBN = true;
                    return true;
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length > 15
                  ) {
                    invalidBN = true;
                    return true;
                  }
                }
                return false;
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (
              row.recipientType === "Association Trust Club Partnership"
            ) {
              if (
                row.firstLineOfRecipient !== null &&
                row.firstLineOfRecipient !== "" &&
                row.secondLineOfRecipient !== null &&
                row.secondLineOfRecipient !== "" &&
                row.trustAccountNumber !== null &&
                row.trustAccountNumber !== ""
              ) {
                console.log("2345890- Individual if");
                if (
                  row.trustAccountNumber &&
                  row.trustAccountNumber.length > 0
                ) {
                  if (row.trustAccountNumber.length === 9) {
                    return false;
                  } else {
                    return true;
                  }
                }
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (
              row.recipientType === "Government or International Organization"
            ) {
              console.log("row.trustAccountNumber--", row.trustAccountNumber);
              if (
                row.firstLineOfRecipient !== null &&
                row.firstLineOfRecipient !== "" &&
                row.secondLineOfRecipient !== null &&
                row.secondLineOfRecipient !== "" &&
                row.businessNumber !== null &&
                row.businessNumber !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.businessNumber && row.businessNumber.length > 0) {
                  if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length == 15
                  ) {
                    const firstNineDigits: string =
                      row.businessNumber && row.businessNumber.substring(0, 9);
                    const output = checkSINValidity(firstNineDigits);
                    if (output % 10 === 0) {
                      invalidBN = false;
                    } else {
                      invalidBN = true;
                      return true;
                    }
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length < 15
                  ) {
                    invalidBN = true;
                    return true;
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length > 15
                  ) {
                    invalidBN = true;
                    return true;
                  }
                }
                return false;
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (
              row.recipientType ===
              "Spouse or trust settlor of spouse deceased person"
            ) {
              if (
                row.firstName !== null &&
                row.firstName !== "" &&
                row.lastName !== null &&
                row.lastName !== "" &&
                row.socialInsurance !== null &&
                row.socialInsurance !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.socialInsurance && row.socialInsurance.length > 0) {
                  const sinWithDash = addDashesInSIN(row.socialInsurance);
                  console.log("sinWithDash", sinWithDash);
                  if (
                    row.socialInsurance === null ||
                    row.socialInsurance === "" ||
                    (sinWithDash && sinWithDash.length <= 11)
                  ) {
                    if (sinWithDash && sinWithDash.length == 11) {
                      // let sin = row[key] && row[key].replace(/-/g, '')
                      let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                      const output = checkSINValidity(sin);
                      console.log("sin", sin);
                      console.log("output", output);
                      let maxLength = 9;
                      if (sin && sin.length == maxLength && output % 10 === 0) {
                        invalidSin = false;
                      } else {
                        invalidSin = true;
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
                return false;
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (
              row.recipientType === "Holder of joint account other than 2"
            ) {
              if (
                row.firstName !== null &&
                row.firstName !== "" &&
                row.lastName !== null &&
                row.lastName !== "" &&
                row.socialInsurance !== null &&
                row.socialInsurance !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.socialInsurance && row.socialInsurance.length > 0) {
                  const sinWithDash = addDashesInSIN(row.socialInsurance);
                  console.log("sinWithDash", sinWithDash);
                  if (
                    row.socialInsurance === null ||
                    row.socialInsurance === "" ||
                    (sinWithDash && sinWithDash.length <= 11)
                  ) {
                    if (sinWithDash && sinWithDash.length == 11) {
                      // let sin = row[key] && row[key].replace(/-/g, '')
                      let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                      const output = checkSINValidity(sin);
                      console.log("sin", sin);
                      console.log("output", output);
                      let maxLength = 9;
                      if (sin && sin.length == maxLength && output % 10 === 0) {
                        invalidSin = false;
                      } else {
                        invalidSin = true;
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
                return false;
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            }
          } else if (CSVInformationUI.tag === "RL16") {
            if (row.recipientType === "Individual") {
              if (
                row.firstName !== null &&
                row.firstName !== "" &&
                row.lastName !== null &&
                row.lastName !== "" &&
                row.socialInsurance !== null &&
                row.socialInsurance !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.socialInsurance && row.socialInsurance.length > 0) {
                  const sinWithDash = addDashesInSIN(row.socialInsurance);
                  console.log("sinWithDash", sinWithDash);
                  if (
                    row.socialInsurance === null ||
                    row.socialInsurance === "" ||
                    (sinWithDash && sinWithDash.length <= 11)
                  ) {
                    if (sinWithDash && sinWithDash.length == 11) {
                      // let sin = row[key] && row[key].replace(/-/g, '')
                      let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                      const output = checkSINValidity(sin);
                      console.log("sin", sin);
                      console.log("output", output);
                      let maxLength = 9;
                      if (sin && sin.length == maxLength && output % 10 === 0) {
                        invalidSin = false;
                      } else {
                        invalidSin = true;
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
                return false;
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (row.recipientType === "Joint Account") {
              if (
                row.firstName !== null &&
                row.firstName !== "" &&
                row.lastName !== null &&
                row.lastName !== "" &&
                row.socialInsurance !== null &&
                row.socialInsurance !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.socialInsurance && row.socialInsurance.length > 0) {
                  const sinWithDash = addDashesInSIN(row.socialInsurance);
                  console.log("sinWithDash", sinWithDash);
                  if (
                    row.socialInsurance === null ||
                    row.socialInsurance === "" ||
                    (sinWithDash && sinWithDash.length <= 11)
                  ) {
                    if (sinWithDash && sinWithDash.length == 11) {
                      // let sin = row[key] && row[key].replace(/-/g, '')
                      let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                      const output = checkSINValidity(sin);
                      console.log("sin", sin);
                      console.log("output", output);
                      let maxLength = 9;
                      if (sin && sin.length == maxLength && output % 10 === 0) {
                        invalidSin = false;
                      } else {
                        invalidSin = true;
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
                return false;
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (row.recipientType === "Corporation") {
              console.log("row.trustAccountNumber--", row.trustAccountNumber);
              if (
                row.firstLineOfRecipient !== null &&
                row.firstLineOfRecipient !== "" &&
                row.secondLineOfRecipient !== null &&
                row.secondLineOfRecipient !== "" &&
                row.businessNumber !== null &&
                row.businessNumber !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.businessNumber && row.businessNumber.length > 0) {
                  if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length == 15
                  ) {
                    const firstNineDigits: string =
                      row.businessNumber && row.businessNumber.substring(0, 9);
                    const output = checkSINValidity(firstNineDigits);
                    if (output % 10 === 0) {
                      invalidBN = false;
                    } else {
                      invalidBN = true;
                      return true;
                    }
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length < 15
                  ) {
                    invalidBN = true;
                    return true;
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length > 15
                  ) {
                    invalidBN = true;
                    return true;
                  }
                }
                return false;
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (
              row.recipientType === "Association Trust Club Partnership"
            ) {
              if (
                row.firstLineOfRecipient !== null &&
                row.firstLineOfRecipient !== "" &&
                row.secondLineOfRecipient !== null &&
                row.secondLineOfRecipient !== "" &&
                row.trustAccountNumber !== null &&
                row.trustAccountNumber !== ""
              ) {
                console.log("2345890- Individual if");
                if (
                  row.trustAccountNumber &&
                  row.trustAccountNumber.length > 0
                ) {
                  if (row.trustAccountNumber.length === 9) {
                    return false;
                  } else {
                    return true;
                  }
                }
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (
              row.recipientType === "Government or International Organization"
            ) {
              console.log("row.trustAccountNumber--", row.trustAccountNumber);
              if (
                row.firstLineOfRecipient !== null &&
                row.firstLineOfRecipient !== "" &&
                row.secondLineOfRecipient !== null &&
                row.secondLineOfRecipient !== "" &&
                row.businessNumber !== null &&
                row.businessNumber !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.businessNumber && row.businessNumber.length > 0) {
                  if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length == 15
                  ) {
                    const firstNineDigits: string =
                      row.businessNumber && row.businessNumber.substring(0, 9);
                    const output = checkSINValidity(firstNineDigits);
                    if (output % 10 === 0) {
                      invalidBN = false;
                    } else {
                      invalidBN = true;
                      return true;
                    }
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length < 15
                  ) {
                    invalidBN = true;
                    return true;
                  } else if (
                    row.businessNumber !== null &&
                    row.businessNumber !== "" &&
                    row.businessNumber &&
                    row.businessNumber.length > 15
                  ) {
                    invalidBN = true;
                    return true;
                  }
                }
                return false;
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (
              row.recipientType ===
              "Spouse or trust settlor of spouse deceased person"
            ) {
              if (
                row.firstName !== null &&
                row.firstName !== "" &&
                row.lastName !== null &&
                row.lastName !== "" &&
                row.socialInsurance !== null &&
                row.socialInsurance !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.socialInsurance && row.socialInsurance.length > 0) {
                  const sinWithDash = addDashesInSIN(row.socialInsurance);
                  console.log("sinWithDash", sinWithDash);
                  if (
                    row.socialInsurance === null ||
                    row.socialInsurance === "" ||
                    (sinWithDash && sinWithDash.length <= 11)
                  ) {
                    if (sinWithDash && sinWithDash.length == 11) {
                      // let sin = row[key] && row[key].replace(/-/g, '')
                      let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                      const output = checkSINValidity(sin);
                      console.log("sin", sin);
                      console.log("output", output);
                      let maxLength = 9;
                      if (sin && sin.length == maxLength && output % 10 === 0) {
                        invalidSin = false;
                      } else {
                        invalidSin = true;
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
                return false;
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            } else if (
              row.recipientType === "Holder of joint account other than 2"
            ) {
              if (
                row.firstName !== null &&
                row.firstName !== "" &&
                row.lastName !== null &&
                row.lastName !== "" &&
                row.socialInsurance !== null &&
                row.socialInsurance !== ""
              ) {
                console.log("2345890- Individual if");
                if (row.socialInsurance && row.socialInsurance.length > 0) {
                  const sinWithDash = addDashesInSIN(row.socialInsurance);
                  console.log("sinWithDash", sinWithDash);
                  if (
                    row.socialInsurance === null ||
                    row.socialInsurance === "" ||
                    (sinWithDash && sinWithDash.length <= 11)
                  ) {
                    if (sinWithDash && sinWithDash.length == 11) {
                      // let sin = row[key] && row[key].replace(/-/g, '')
                      let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                      const output = checkSINValidity(sin);
                      console.log("sin", sin);
                      console.log("output", output);
                      let maxLength = 9;
                      if (sin && sin.length == maxLength && output % 10 === 0) {
                        invalidSin = false;
                      } else {
                        invalidSin = true;
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }
                return false;
              } else if (row.businessNumber && row.businessNumber.length > 0) {
                if (row.businessNumber.length == 15) {
                  const firstNineDigits: string =
                    row[key] && row[key].substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);
                  if (output % 10 === 0) {
                    invalidBN = false;
                  } else {
                    invalidBN = true;
                    return true;
                  }
                } else if (row.businessNumber.length < 15) {
                  invalidBN = true;
                  return true;
                } else if (row.businessNumber.length > 15) {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row.trustAccountNumber &&
                row.trustAccountNumber.length > 9
              ) {
                return false;
              } else {
                console.log("2345890- Individual else");
                return true;
              }
            }
          } else {
            if (key === "firstName") {
              if (row[key] === null || (row[key] && row[key].length > 12)) {
                return true;
              }
            }
            if (key === "lastName") {
              if (row[key] === null || (row[key] && row[key].length > 20)) {
                return true;
              }
            }
            if (key === "socialInsurance") {
              const sinWithDash = addDashesInSIN(row[key]);
              console.log("sinWithDash", sinWithDash);
              if (
                row[key] === null ||
                row[key] === "" ||
                (sinWithDash && sinWithDash.length <= 11)
              ) {
                if (sinWithDash && sinWithDash.length == 11) {
                  // let sin = row[key] && row[key].replace(/-/g, '')
                  let sin = sinWithDash && sinWithDash.replace(/-/g, "");
                  const output = checkSINValidity(sin);
                  console.log("sin", sin);
                  console.log("output", output);
                  let maxLength = 9;
                  if (sin && sin.length == maxLength && output % 10 === 0) {
                    invalidSin = false;
                  } else {
                    invalidSin = true;
                    return true;
                  }
                } else {
                  return true;
                }
              }
            }
            if (key === "businessNumber") {
              if (
                row[key] !== null &&
                row[key] !== "" &&
                row[key] &&
                row[key].length == 15
              ) {
                const firstNineDigits: string =
                  row[key] && row[key].substring(0, 9);
                const output = checkSINValidity(firstNineDigits);
                if (output % 10 === 0) {
                  invalidBN = false;
                } else {
                  invalidBN = true;
                  return true;
                }
              } else if (
                row[key] !== null &&
                row[key] !== "" &&
                row[key] &&
                row[key].length < 15
              ) {
                invalidBN = true;
                return true;
              }
            }
          }
        }
        //   else if (key === 'businessNumber') {
        //     if (row[key] !== null && row[key] !== '' && row[key] && row[key].length == 15) {
        //         const firstNineDigits: string = row[key] && row[key].substring(0, 9);
        //         const output = checkSINValidity(firstNineDigits);
        //         if (output % 10 === 0) {
        //             invalidBN=false
        //         } else {
        //           invalidBN=true
        //           return true;
        //         }
        //     } else if(row[key] !== null && row[key] !== '' && row[key] && row[key].length < 15){
        //       invalidBN=true
        //       return true;
        //     }
        // }
        else if (key === "initials") {
          if (row[key] && row[key].length > 1) {
            return true;
          }
        } else if (key === "dateOfBirth") {
          if (CSVInformationUI.tag === "T4") {
            if (
              defaultSettingsData &&
              defaultSettingsData.fillingDataAttribute.employeeDobIsRequired ===
                true
            ) {
              if (row[key] === null) {
                return true;
              } else if (row[key] === "") {
                return true;
              }
            }
          } else if (CSVInformationUI.tag === "Employee") {
            if (row[key] === null) {
              return true;
            } else if (row[key] === "") {
              return true;
            }
          }
        }
      }
      return false;
    });
    if (hasNullOrEmpty === true && invalidSin === true) {
      onSnackbarMessageData({
        open: true,
        message: "Invalid SIN!",
        severity: "error",
      });
    } else if (hasNullOrEmpty === true && invalidBN === true) {
      onSnackbarMessageData({
        open: true,
        message: "Invalid Business Number!",
        severity: "error",
      });
    } else if (hasNullOrEmpty === true) {
      onSnackbarMessageData({
        open: true,
        message: "Required field data missing!",
        severity: "error",
      });
    } else {
      console.log("Table Data:", tableData);
      setFormSubmitted(false);
      if (CSVInformationUI.tag === "T4") {
        const t4CsvData = tableData.map((item: any) => ({
          employeeCode: item.employeeCode,
          socialInsurance: item.socialInsurance.replace(/-/g, ""),
          firstName: item.firstName,
          lastName: item.lastName,
          initials: item.initials,
          dateOfBirth: item.dateOfBirth,
          status: item.status,
          type: item.type,
          businessNumber: item.businessNumber,
          businessName: item.businessName,
          address: item.address,
          city: item.city,
          postCode: item.postCode,
          province: item.province,
          email: item.email,
          phone1: item.phone1,
          ext1: item.ext1,
          phone2: item.phone2,
          ext2: item.ext2,
          provinceOfEmployment: item.provinceOfEmployment,
          cppQppExempt: item.cppQppExempt,
          eiExempt: item.eiExempt,
          ppipExempt: item.ppipExempt,
          employmentCode: item.employmentCode,
          payPeriod: item.payPeriod,
          reducedEi: item.reducedEi,
          employmentIncome: item.employmentIncome,
          cppPremium: item.cppPremium,
          qppPremiums: item.qppPremiums,
          secondCppContributions: item.secondCppContributions,
          secondQppContributions: item.secondQppContributions,
          eiPremiums: item.eiPremiums,
          rppPremiums: item.rppPremiums,
          incomeTaxDeducted: item.incomeTaxDeducted,
          eiInsurableEarnings: item.eiInsurableEarnings,
          cppQppPensionableEarnings: item.cppQppPensionableEarnings,
          unionDues: item.unionDues,
          charitableDonations: item.charitableDonations,
          registrationNumber: item.registrationNumber,
          pensionAdjustment: item.pensionAdjustment,
          ppipPremiums: item.ppipPremiums,
          ppipInsurableEarnings: item.ppipInsurableEarnings,
          t4OtherInformation1: item.t4OtherInformation1,
          otherInfo1Amount: item.otherInfo1Amount,
          t4OtherInformation2: item.t4OtherInformation2,
          otherInfo2Amount: item.otherInfo2Amount,
          t4OtherInformation3: item.t4OtherInformation3,
          otherInfo3Amount: item.otherInfo3Amount,
          t4OtherInformation4: item.t4OtherInformation4,
          otherInfo4Amount: item.otherInfo4Amount,
          t4OtherInformation5: item.t4OtherInformation5,
          otherInfo5Amount: item.otherInfo5Amount,
          t4OtherInformation6: item.t4OtherInformation6,
          otherInfo6Amount: item.otherInfo6Amount,
          slipType: item.slipType,
          pensionablePeriods: item.pensionablePeriods,
          slipStatus: item.slipStatus,
        }));
        console.log("t4CsvData", t4CsvData);
        let payload = {
          tag: CSVInformationUI.tag,
          clientId,
          taxYear,
          items: t4CsvData,
        };
        onDataSubmit(payload);
      } else if (CSVInformationUI.tag === "T4A") {
        const t4ACsvData = tableData.map((item: any) => ({
          socialInsurance:
            item.socialInsurance !== ""
              ? item.socialInsurance.replace(/-/g, "")
              : undefined,
          payeeType: item.payeeType,
          firstName: item.firstName !== "" ? item.firstName : undefined,
          lastName: item.lastName !== "" ? item.lastName : undefined,
          initials: item.initials,
          vendorStatus: item.vendorStatus,
          businessNumber:
            item.businessNumber !== "" ? item.businessNumber : undefined,
          businessName:
            item.businessName !== "" ? item.businessName : undefined,
          address: item.address,
          city: item.city,
          postCode: item.postCode,
          province: item.province,
          email: item.email,
          phone1: item.phone1,
          ext1: item.ext1,
          phone2: item.phone2,
          ext2: item.ext2,
          pensionOrSuperannuation: item.pensionOrSuperannuation,
          lumpSumPayments: item.lumpSumPayments,
          lumpSumPaymentsOtherAmounts: item.lumpSumPaymentsOtherAmounts,
          selfEmployedCommissions: item.selfEmployedCommissions,
          incomeTaxDeducted: item.incomeTaxDeducted,
          annuities: item.annuities,
          annuitiesOther: item.annuitiesOther,
          fees: item.fees,
          otherInformation1: item.otherInformation1,
          otherInfo1Amount: item.otherInfo1Amount,
          otherInformation2: item.otherInformation2,
          otherInfo2Amount: item.otherInfo2Amount,
          otherInformation3: item.otherInformation3,
          otherInfo3Amount: item.otherInfo3Amount,
          otherInformation4: item.otherInformation4,
          otherInfo4Amount: item.otherInfo4Amount,
          otherInformation5: item.otherInformation5,
          otherInfo5Amount: item.otherInfo5Amount,
          otherInformation6: item.otherInformation6,
          otherInfo6Amount: item.otherInfo6Amount,
          slipType: item.slipType,
          slipStatus: item.slipStatus,
        }));
        console.log("t4ACsvData", t4ACsvData);
        let payload = {
          tag: CSVInformationUI.tag,
          clientId,
          taxYear,
          items: t4ACsvData,
        };
        onDataSubmit(payload);
      } else if (CSVInformationUI.tag === "RL1") {
        const rl1CsvData = tableData.map((item: any) => ({
          employeeCode: item.employeeCode,
          socialInsurance: item.socialInsurance.replace(/-/g, ""),
          firstName: item.firstName,
          lastName: item.lastName,
          initials: item.initials,
          dateOfBirth: item.dateOfBirth,
          status: item.status,
          type: item.type,
          businessNumber: item.businessNumber,
          businessName: item.businessName,
          address: item.address,
          city: item.city,
          postCode: item.postCode,
          province: item.province,
          email: item.email,
          phone1: item.phone1,
          ext1: item.ext1,
          phone2: item.phone2,
          ext2: item.ext2,
          cppQppExempt: item.cppQppExempt,
          eiExempt: item.eiExempt,
          ppipExempt: item.ppipExempt,
          employmentCode: item.employmentCode,
          payPeriod: item.payPeriod,
          reducedEi: item.reducedEi,
          aRevenusDEmploi: item.aRevenusDEmploi,
          bCotisationAuRrq: item.bCotisationAuRrq,
          bbCotisationrrqsuppl: item.bbCotisationrrqsuppl,
          cCotÀLAssuranceEmploi: item.cCotÀLAssuranceEmploi,
          dCotisationÀUnRpa: item.dCotisationÀUnRpa,
          eImpôtDuQuébecRetenu: item.eImpôtDuQuébecRetenu,
          fCotisationSyndicale: item.fCotisationSyndicale,
          gSalaireAdmissibleAuRrq: item.gSalaireAdmissibleAuRrq,
          hCotisationAuRqap: item.hCotisationAuRqap,
          iSalaireAdmissibleAuRqap: item.iSalaireAdmissibleAuRqap,
          jRégimePrivéDAssMaladie: item.jRégimePrivéDAssMaladie,
          kVoyagesRégionÉloignée: item.kVoyagesRégionÉloignée,
          lAutresAvantages: item.lAutresAvantages,
          mCommissions: item.mCommissions,
          nDonsDeBienfaisance: item.nDonsDeBienfaisance,
          oAutresRevenus:
            item.oAutresRevenus !== "NaN" ? item.oAutresRevenus : undefined,
          pRégimedX92assInterentreprises: item.pRégimedX92assInterentreprises,
          qSalairesDifférés: item.qSalairesDifférés,
          rRevenuSituéDansUneRéserve: item.rRevenuSituéDansUneRéserve,
          sPourboiresReçus: item.sPourboiresReçus,
          tPourboiresAttribués: item.tPourboiresAttribués,
          uRetraiteProgressive: item.uRetraiteProgressive,
          vNourritureEtLogement: item.vNourritureEtLogement,
          wVéhiculeÀMoteur: item.wVéhiculeÀMoteur,
          codeCaseO: item.codeCaseO,
          otherInfo1: item.otherInfo1,
          otherInfo1Amount: item.otherInfo1Amount,
          otherInfo2: item.otherInfo2,
          otherInfo2Amount: item.otherInfo2Amount,
          otherInfo3: item.otherInfo3,
          otherInfo3Amount: item.otherInfo3Amount,
          otherInfo4: item.otherInfo4,
          otherInfo4Amount: item.otherInfo4Amount,
          otherInfo5: item.otherInfo5,
          otherInfo5Amount: item.otherInfo5Amount,
          otherInfo6: item.otherInfo6,
          otherInfo6Amount: item.otherInfo6Amount,
          slipType: item.slipType,
          pensionablePeriods: item.pensionablePeriods,
          slipStatus: item.slipStatus,
        }));
        console.log("rl1CsvData", rl1CsvData);
        let payload = {
          tag: CSVInformationUI.tag,
          clientId,
          taxYear,
          items: rl1CsvData,
        };
        onDataSubmit(payload);
      } else if (CSVInformationUI.tag === "Employee") {
        const empCsvData = tableData.map((item: any) => ({
          employeeCode: item.employeeCode,
          socialInsurance: item.socialInsurance.replace(/-/g, ""),
          firstName: item.firstName,
          lastName: item.lastName,
          initials: item.initials,
          dateOfBirth: item.dateOfBirth,
          status: item.status,
          type: item.type,
          businessNumber: item.businessNumber,
          businessName: item.businessName,
          address: item.address,
          city: item.city,
          postalCode: item.postalCode,
          province: item.province,
          email: item.email,
          phone1: item.phone1,
          ext1: item.ext1,
          phone2: item.phone2,
          ext2: item.ext2,
          provinceOfEmployment: item.provinceOfEmployment,
          cppQppExempt: item.cppQppExempt,
          eiExempt: item.eiExempt,
          ppipExempt: item.ppipExempt,
          employmentCode: item.employmentCode,
          payPeriod: item.payPeriod,
          reducedEi: item.reducedEi,
        }));
        console.log("empCsvData", empCsvData);
        let payload = {
          tag: CSVInformationUI.tag,
          clientId,
          taxYear,
          items: empCsvData,
        };
        onDataSubmit(payload);
      } else if (CSVInformationUI.tag === "T5") {
        const t5CsvData = tableData.map((item: any) => ({
          // employeeCode:item.employeeCode,
          socialInsurance:
            item.socialInsurance && item.socialInsurance !== null
              ? item.socialInsurance.replace(/-/g, "")
              : item.socialInsurance,
          trustAccountNumber: item.trustAccountNumber,
          firstName: item.firstName,
          lastName: item.lastName,
          initials: item.initials,
          dateOfBirth: item.dateOfBirth,
          status: item.status,
          type: item.type,
          businessNumber: item.businessNumber,
          // businessName:item.businessName,
          address: item.address,
          city: item.city,
          postCode: item.postCode,
          province: item.province,
          email: item.email,
          phone1: item.phone1,
          ext1: item.ext1,
          recipientType: item.recipientType,
          businessName: item.businessName,
          firstLineOfRecipient: item.firstLineOfRecipient,
          secondLineOfRecipient: item.secondLineOfRecipient,
          // phone2:item.phone2,
          // ext2:item.ext2,
          // provinceOfEmployment:item.provinceOfEmployment,
          actualAmountOfEligibleDivinends: item.actualAmountOfEligibleDivinends,
          // taxableAmountOfEligibleDividends:item.taxableAmountOfEligibleDividends,
          // dividendTaxCreditForEligibleDividends:item.dividendTaxCreditForEligibleDividends,
          interestFromCanadianSources: item.interestFromCanadianSources,
          capitalGainDividends: item.capitalGainDividends,
          actualAmountOfDivinendsOtherThanEligible:
            item.actualAmountOfDivinendsOtherThanEligible,
          // taxableAmountOfDivinendsOtherThanEligible:item.taxableAmountOfDivinendsOtherThanEligible,
          // dividendTaxCreditForDivdendsOtherThanEligible:item.dividendTaxCreditForDivdendsOtherThanEligible,
          otherIncomeFromCanadianSources: item.otherIncomeFromCanadianSources,
          foreignIncome: item.foreignIncome,
          foreignTaxPaid: item.foreignTaxPaid,
          royaltiesFromCanadianSources: item.royaltiesFromCanadianSources,
          accuredIncomeOrAnnuities: item.accuredIncomeOrAnnuities,
          amountEligibleForResourceAllowanceDeduction:
            item.amountEligibleForResourceAllowanceDeduction,
          foreignCurrency: item.foreignCurrency,
          transit: item.transit,
          recipientAccount: item.recipientAccount,
          optionalTextToPrintOnTheSlip: item.optionalTextToPrintOnTheSlip,
          slipType: item.slipType,
          slipStatus: item.slipStatus,
        }));
        console.log("t5CsvData", t5CsvData);
        let payload = {
          tag: CSVInformationUI.tag,
          clientId,
          taxYear,
          items: t5CsvData,
        };
        onDataSubmit(payload);
      } else if (CSVInformationUI.tag === "T3") {
        const t3CsvData = tableData.map((item: any) => ({
          socialInsurance:
            item.socialInsurance && item.socialInsurance !== null
              ? item.socialInsurance.replace(/-/g, "")
              : item.socialInsurance,
          firstName: item.firstName,
          lastName: item.lastName,
          initials: item.initials,
          status: item.status,
          businessName: item.businessName,
          businessNumber: item.businessNumber,
          jointFullName: item.jointFullName,
          // name3:item.name3,
          // name4:item.name4,
          firstLineOfRecipient: item.firstLineOfRecipient,
          secondLineOfRecipient: item.secondLineOfRecipient,
          trustAccountNumber: item.trustAccountNumber,
          recipientType: item.recipientType,
          address: item.address,
          city: item.city,
          postCode: item.postCode,
          province: item.province,
          email: item.email,
          phone1: item.phone1,
          ext1: item.ext1,
          actualAmountOfEligibleDividends: item.actualAmountOfEligibleDividends,
          capitalGains: item.capitalGains,
          capitalGainsEligible: item.capitalGainsEligible,
          actualAmountOtherThanEligible: item.actualAmountOtherThanEligible,
          otherIncome: item.otherIncome,
          t3OtherInformation1: item.t3OtherInformation1,
          otherInfo1Amount: item.otherInfo1Amount,
          t3OtherInformation2: item.t3OtherInformation2,
          otherInfo2Amount: item.otherInfo2Amount,
          t3OtherInformation3: item.t3OtherInformation3,
          otherInfo3Amount: item.otherInfo3Amount,
          t3OtherInformation4: item.t3OtherInformation4,
          otherInfo4Amount: item.otherInfo4Amount,
          t3OtherInformation5: item.t3OtherInformation5,
          otherInfo5Amount: item.otherInfo5Amount,
          t3OtherInformation6: item.t3OtherInformation6,
          slipType: item.slipType,
          slipStatus: item.slipStatus,
        }));
        console.log("t3CsvData", t3CsvData);
        let payload = {
          tag: CSVInformationUI.tag,
          clientId,
          taxYear,
          items: t3CsvData,
        };
        onDataSubmit(payload);
      } else if (CSVInformationUI.tag === "RL3") {
        const rl3CsvData = tableData.map((item: any) => ({
          socialInsurance:
            item.socialInsurance && item.socialInsurance !== null
              ? item.socialInsurance.replace(/-/g, "")
              : item.socialInsurance,
          firstName: item.firstName,
          lastName: item.lastName,
          initials: item.initials,
          status: item.status,
          businessName: item.businessName,
          businessNumber: item.businessNumber,
          jointFullName: item.jointFullName,
          // name3:item.name3,
          // name4:item.name4,
          firstLineOfRecipient: item.firstLineOfRecipient,
          secondLineOfRecipient: item.secondLineOfRecipient,
          trustAccountNumber: item.trustAccountNumber,
          recipientType: item.recipientType,
          address: item.address,
          city: item.city,
          postCode: item.postCode,
          province: item.province,
          email: item.email,
          phone1: item.phone1,
          ext1: item.ext1,
          actualAmountOfEligibleDivinends: item.actualAmountOfEligibleDivinends,
          actualAmountOfOrdinaryDivinends: item.actualAmountOfOrdinaryDivinends,
          interestFromCanadianSources: item.interestFromCanadianSources,
          otherIncomeFromCanadianSources: item.otherIncomeFromCanadianSources,
          grossForeignInvestmentIncome: item.grossForeignInvestmentIncome,
          foreignIncomeTax: item.foreignIncomeTax,
          royaltiesFromCanadianSources: item.royaltiesFromCanadianSources,
          capitalGainDividends: item.capitalGainDividends,
          accuredIncomeOrAnnuities: item.accuredIncomeOrAnnuities,
          interestFromLinkedNotes: item.interestFromLinkedNotes,
          foreignCurrency: item.foreignCurrency,
          bankTransit: item.bankTransit,
          otherIdentificationNumber: item.otherIdentificationNumber,
          numberAssignedByPayerOrNominee: item.numberAssignedByPayerOrNominee,
          optionalTextToPrintOnTheSlip: item.optionalTextToPrintOnTheSlip,
          otherInfo1: item.otherInfo1,
          otherInfo1Amount: item.otherInfo1Amount,
          otherInfo2: item.otherInfo2,
          otherInfo2Amount: item.otherInfo2Amount,
          otherInfo3: item.otherInfo3,
          otherInfo3Amount: item.otherInfo3Amount,
          otherInfo4: item.otherInfo4,
          otherInfo4Amount: item.otherInfo4Amount,
          otherInfo5: item.otherInfo5,
          otherInfo5Amount: item.otherInfo5Amount,
          otherInfo6: item.otherInfo6,
          otherInfo6Amount: item.otherInfo6Amount,
          slipType: item.slipType,
          slipStatus: item.slipStatus,
        }));
        console.log("rl3CsvData", rl3CsvData);
        let payload = {
          tag: CSVInformationUI.tag,
          clientId,
          taxYear,
          items: rl3CsvData,
        };
        onDataSubmit(payload);
      } else if (CSVInformationUI.tag === "RL16") {
        const rl16CsvData = tableData.map((item: any) => ({
          socialInsurance:
            item.socialInsurance && item.socialInsurance !== null
              ? item.socialInsurance.replace(/-/g, "")
              : item.socialInsurance,
          firstName: item.firstName,
          lastName: item.lastName,
          initials: item.initials,
          status: item.status,
          businessName: item.businessName,
          businessNumber: item.businessNumber,
          jointFullName: item.jointFullName,
          // name3:item.name3,
          // name4:item.name4,
          firstLineOfRecipient: item.firstLineOfRecipient,
          secondLineOfRecipient: item.secondLineOfRecipient,
          trustAccountNumber: item.trustAccountNumber,
          recipientType: item.recipientType,
          address: item.address,
          city: item.city,
          postCode: item.postCode,
          province: item.province,
          email: item.email,
          phone1: item.phone1,
          ext1: item.ext1,
          capitalGains: item.capitalGains,
          singlePensionPayments: item.singlePensionPayments,
          actualAmountOfEligibleDividends: item.actualAmountOfEligibleDividends,
          actualAmountOtherThanEligible: item.actualAmountOtherThanEligible,
          capitalGainsGivingEntitlement: item.capitalGainsGivingEntitlement,
          pensionPaymentGivingEntitlement: item.pensionPaymentGivingEntitlement,
          foreignBusinessIncome: item.foreignBusinessIncome,
          foreignNonBusinessIncome: item.foreignNonBusinessIncome,
          otherIncome: item.otherIncome,
          foreignIncomeTaxOnBusinessIncome:
            item.foreignIncomeTaxOnBusinessIncome,
          foreignIncomeTaxOnNonBusinessIncome:
            item.foreignIncomeTaxOnNonBusinessIncome,
          costBaseAdjustmentOfCapitalInterest:
            item.costBaseAdjustmentOfCapitalInterest,
          donationFromAReligiousOrganization:
            item.donationFromAReligiousOrganization,
          otherInfo1: item.otherInfo1,
          otherInfo1Amount: item.otherInfo1Amount,
          otherInfo2: item.otherInfo2,
          otherInfo2Amount: item.otherInfo2Amount,
          otherInfo3: item.otherInfo3,
          otherInfo3Amount: item.otherInfo3Amount,
          otherInfo4: item.otherInfo4,
          otherInfo4Amount: item.otherInfo4Amount,
          otherInfo5: item.otherInfo5,
          otherInfo5Amount: item.otherInfo5Amount,
          otherInfo6: item.otherInfo6,
          otherInfo6Amount: item.otherInfo6Amount,
          slipType: item.slipType,
          slipStatus: item.slipStatus,
        }));
        console.log("rl16CsvData", rl16CsvData);
        let payload = {
          tag: CSVInformationUI.tag,
          clientId,
          taxYear,
          items: rl16CsvData,
        };
        onDataSubmit(payload);
      }
    }
  };

  const footer = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <Button
          label="Submit"
          icon="pi pi-check"
          severity="success"
          type="submit"
          onClick={handleFormSubmit}
        />
      </div>
    );
  };

  // const generateCellId = (colIndex: number, rowIndex: number) => {
  //   return `cell_${colIndex}_${rowIndex}`;
  // };

  const renderErrorClass = (field: any, rowIndex: number, rowData: any) => {
    console.log("field-=", field);
    if (
      formSubmitted &&
      field.field !== "dateOfBirth" &&
      field.field !== "businessNumber" &&
      field.field !== "initials" &&
      field.field !== "firstName" &&
      field.field !== "lastName" &&
      field.field !== "socialInsurance" &&
      field.field !== "businessName" &&
      field.field !== "firstLineOfRecipient" &&
      field.field !== "secondLineOfRecipient" &&
      field.field !== "trustAccountNumber" &&
      (tableData[rowIndex][field.field] === null ||
        tableData[rowIndex][field.field] === "")
    ) {
      return "error-input";
    }
    console.log("field.field", field.field);
    if (formSubmitted && field.field === "socialInsurance") {
      const sinWithDashes = addDashesInSIN(tableData[rowIndex][field.field]);
      if (sinWithDashes && sinWithDashes.length < 11) {
        return "error-input p-inputtext p-component p-filled input-dropdown-fields";
      }
      if (sinWithDashes && sinWithDashes.length == 11) {
        let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
        const output = checkSINValidity(sin);
        let maxLength = 9;
        if (sin && sin.length == maxLength && output % 10 === 0) {
          return "p-inputtext p-component p-filled input-dropdown-fields";
        } else {
          return "error-input p-inputtext p-component p-filled input-dropdown-fields";
        }
      }
    }
    // if (field.field === 'socialInsurance'){
    //   return 'p-inputtext p-component p-filled input-dropdown-fields'
    // }
    if (field.field === "businessNumber") {
      let value = tableData[rowIndex][field.field];
      if (value !== null && value !== "" && value && value.length == 15) {
        const firstNineDigits: string = value && value.substring(0, 9);
        const output = checkSINValidity(firstNineDigits);
        if (output % 10 === 0) {
          return "p-inputtext p-component p-filled input-dropdown-fields";
        } else {
          return "error-input p-inputtext p-component p-filled input-dropdown-fields";
        }
      } else if (value !== null && value !== "" && value && value.length < 15) {
        return "error-input p-inputtext p-component p-filled input-dropdown-fields";
      } else if (value !== null && value !== "" && value && value.length > 15) {
        return "error-input p-inputtext p-component p-filled input-dropdown-fields";
      }
    }
    if (field.field === "businessNumber") {
      if (tableData[rowIndex][field.field] === null || "") {
        return "p-inputtext p-component p-filled input-dropdown-fields";
      }
    }
    if (
      field.field === "firstName" &&
      rowData.firstName === null &&
      rowData.firstName &&
      rowData.firstName.length > 12
    ) {
      return "error-input";
    }
    if (
      field.field === "lastName" &&
      rowData.lastName === null &&
      rowData.lastName &&
      rowData.lastName.length > 20
    ) {
      return "error-input";
    }
    if (
      field.field === "address" &&
      rowData.address === null &&
      rowData.address &&
      rowData.address.length > 50
    ) {
      return "error-input";
    }
    if (
      field.field === "city" &&
      rowData.city === null &&
      rowData.city &&
      rowData.city.length > 28
    ) {
      return "error-input";
    }
    if (
      field.field === "initials" &&
      rowData.initials &&
      rowData.initials.length > 1
    ) {
      console.log("rowData.initials.length", rowData.initials.length);
      return "error-input";
    }
    if (CSVInformationUI.tag === "T4") {
      if (
        defaultSettingsData &&
        defaultSettingsData.fillingDataAttribute.employeeDobIsRequired === true
      ) {
        if (field.field === "dateOfBirth" && rowData.dateOfBirth === null) {
          return "error-input";
        } else if (
          field.field === "dateOfBirth" &&
          rowData.dateOfBirth === ""
        ) {
          return "error-input";
        }
      }
    }
    if (CSVInformationUI.tag === "Employee") {
      if (
        defaultSettingsData &&
        defaultSettingsData.fillingDataAttribute.employeeDobIsRequired === true
      ) {
        if (field.field === "dateOfBirth" && rowData.dateOfBirth === null) {
          return "error-input";
        } else if (
          field.field === "dateOfBirth" &&
          rowData.dateOfBirth === ""
        ) {
          return "error-input";
        }
      }
    }
    if (CSVInformationUI.tag === "T4A") {
      //   console.log("CSVInformationUI.tag",CSVInformationUI.tag)
      if (rowData.payeeType === "Business") {
        console.log("field.field123", field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessName") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "error-input";
          }
          // if(tableData[rowIndex][field.field] && tableData[rowIndex][field.field].length > 12){
          //   return 'error-input';
          // }
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "error-input";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
      } else if (rowData.payeeType === "Personal") {
        // console.log("1423rowData.lastName",rowData.socialInsurance.length)
        if (field.field === "socialInsurance") {
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "error-input";
          }
          // return 'error-input';
        }
        if (field.field === "firstName") {
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "error-input";
          }
          if (rowData.firstName && rowData.firstName.length > 12) {
            return "error-input";
          }
        }
        if (field.field === "lastName") {
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "error-input";
          }
          if (rowData.lastName && rowData.lastName.length > 12) {
            return "error-input";
          }
        }
        if (field.field === "businessName" && rowData.businessName === null) {
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "businessNumber" &&
          rowData.businessNumber === null
        ) {
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
      }
    } else if (CSVInformationUI.tag === "T5") {
      console.log("rowData.recipientType", rowData.recipientType);
      if (rowData.recipientType === "Individual") {
        console.log("rowData.recipientType INDIVIDUAL", rowData.recipientType);
        console.log("field.field123", field);
        //   if (field.field === 'socialInsurance'){
        //     console.log("1423")
        //   return 'error-input';
        // }
        if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === null
        ) {
          return "error-input";
        } else if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === ""
        ) {
          return "error-input";
        } else if (field.field === "socialInsurance") {
          const sinWithDashes = addDashesInSIN(
            tableData[rowIndex][field.field]
          );
          if (sinWithDashes && sinWithDashes.length < 11) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (sinWithDashes && sinWithDashes.length == 11) {
            let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
            const output = checkSINValidity(sin);
            let maxLength = 9;
            if (sin && sin.length == maxLength && output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          }
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          return "error-input";
        } else if (field.field === "firstName" && rowData.firstName === "") {
          return "error-input";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          return "error-input";
        } else if (field.field === "lastName" && rowData.lastName === "") {
          return "error-input";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          console.log(
            "rowData.firstLineOfRecipient123",
            rowData.firstLineOfRecipient
          );
          console.log("1423 firstLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          console.log(
            "rowData.secondLineOfRecipient123",
            rowData.secondLineOfRecipient
          );
          console.log("1423 secondLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (rowData.recipientType === "Joint Account") {
        console.log("field.field123", field);
        console.log("rowData.recipientType JA", rowData.recipientType);
        // if (field.field === 'socialInsurance'){
        //   console.log("1423")
        // return 'error-input';
        // }
        if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === null
        ) {
          return "error-input";
        } else if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === ""
        ) {
          return "error-input";
        } else if (field.field === "socialInsurance") {
          const sinWithDashes = addDashesInSIN(
            tableData[rowIndex][field.field]
          );
          if (sinWithDashes && sinWithDashes.length < 11) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (sinWithDashes && sinWithDashes.length == 11) {
            let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
            const output = checkSINValidity(sin);
            let maxLength = 9;
            if (sin && sin.length == maxLength && output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          }
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          return "error-input";
        } else if (field.field === "firstName" && rowData.firstName === "") {
          return "error-input";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          return "error-input";
        } else if (field.field === "lastName" && rowData.lastName === "") {
          return "error-input";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          console.log(
            "rowData.firstLineOfRecipient123",
            rowData.firstLineOfRecipient
          );
          console.log("1423 firstLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          console.log(
            "rowData.secondLineOfRecipient123",
            rowData.secondLineOfRecipient
          );
          console.log("1423 secondLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (rowData.recipientType === "Corporation") {
        console.log("rowData.recipientType CORPORATION", rowData.recipientType);
        console.log("field.field123333", field.field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (field.field === "businessNumber") {
          // console.log("1423--",tableData[rowIndex][field.field].length)
          // if(tableData[rowIndex][field.field] === null){
          //   return 'error-input';
          // } else if(tableData[rowIndex][field.field] === ''){
          //   return 'error-input';
          // }
          // if(tableData[rowIndex][field.field] && tableData[rowIndex][field.field].length > 15){
          //   console.log("jbdcjksbdc")
          //   return 'error-input';
          // }
          let value = rowData.businessNumber;
          if (value !== null && value !== "" && value && value.length == 15) {
            const firstNineDigits: string = value && value.substring(0, 9);
            const output = checkSINValidity(firstNineDigits);
            if (output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length < 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length > 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (
        rowData.recipientType === "Association Trust Club Partnership"
      ) {
        console.log("rowData.recipientType TRUST", rowData.recipientType);
        console.log("field.field123", field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "error-input";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length < 9
          ) {
            return "error-input";
          }
        }
      } else if (
        rowData.recipientType === "Government or International Organization"
      ) {
        console.log("rowData.recipientType CORPORATION", rowData.recipientType);
        console.log("field.field123333", field.field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (field.field === "businessNumber") {
          // console.log("1423--",tableData[rowIndex][field.field].length)
          // if(tableData[rowIndex][field.field] === null){
          //   return 'error-input';
          // } else if(tableData[rowIndex][field.field] === ''){
          //   return 'error-input';
          // }
          // if(tableData[rowIndex][field.field] && tableData[rowIndex][field.field].length > 15){
          //   console.log("jbdcjksbdc")
          //   return 'error-input';
          // }
          let value = rowData.businessNumber;
          if (value !== null && value !== "" && value && value.length == 15) {
            const firstNineDigits: string = value && value.substring(0, 9);
            const output = checkSINValidity(firstNineDigits);
            if (output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length < 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length > 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      }
    } else if (CSVInformationUI.tag === "T3") {
      console.log("rowData.recipientType", rowData.recipientType);
      if (rowData.recipientType === "Individual") {
        console.log("rowData.recipientType INDIVIDUAL", rowData.recipientType);
        console.log("field.field123", field);
        //   if (field.field === 'socialInsurance'){
        //     console.log("1423")
        //   return 'error-input';
        // }
        if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === null
        ) {
          return "error-input";
        } else if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === ""
        ) {
          return "error-input";
        } else if (field.field === "socialInsurance") {
          const sinWithDashes = addDashesInSIN(
            tableData[rowIndex][field.field]
          );
          if (sinWithDashes && sinWithDashes.length < 11) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (sinWithDashes && sinWithDashes.length == 11) {
            let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
            const output = checkSINValidity(sin);
            let maxLength = 9;
            if (sin && sin.length == maxLength && output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          }
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          return "error-input";
        } else if (field.field === "firstName" && rowData.firstName === "") {
          return "error-input";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          return "error-input";
        } else if (field.field === "lastName" && rowData.lastName === "") {
          return "error-input";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          console.log(
            "rowData.firstLineOfRecipient123",
            rowData.firstLineOfRecipient
          );
          console.log("1423 firstLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          console.log(
            "rowData.secondLineOfRecipient123",
            rowData.secondLineOfRecipient
          );
          console.log("1423 secondLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (rowData.recipientType === "Joint Account") {
        console.log("field.field123", field);
        console.log("rowData.recipientType JA", rowData.recipientType);
        // if (field.field === 'socialInsurance'){
        //   console.log("1423")
        // return 'error-input';
        // }
        if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === null
        ) {
          return "error-input";
        } else if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === ""
        ) {
          return "error-input";
        } else if (field.field === "socialInsurance") {
          const sinWithDashes = addDashesInSIN(
            tableData[rowIndex][field.field]
          );
          if (sinWithDashes && sinWithDashes.length < 11) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (sinWithDashes && sinWithDashes.length == 11) {
            let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
            const output = checkSINValidity(sin);
            let maxLength = 9;
            if (sin && sin.length == maxLength && output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          }
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          return "error-input";
        } else if (field.field === "firstName" && rowData.firstName === "") {
          return "error-input";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          return "error-input";
        } else if (field.field === "lastName" && rowData.lastName === "") {
          return "error-input";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          console.log(
            "rowData.firstLineOfRecipient123",
            rowData.firstLineOfRecipient
          );
          console.log("1423 firstLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          console.log(
            "rowData.secondLineOfRecipient123",
            rowData.secondLineOfRecipient
          );
          console.log("1423 secondLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (rowData.recipientType === "Corporation") {
        console.log("rowData.recipientType CORPORATION", rowData.recipientType);
        console.log("field.field123333", field.field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (field.field === "businessNumber") {
          // console.log("1423--",tableData[rowIndex][field.field].length)
          // if(tableData[rowIndex][field.field] === null){
          //   return 'error-input';
          // } else if(tableData[rowIndex][field.field] === ''){
          //   return 'error-input';
          // }
          // if(tableData[rowIndex][field.field] && tableData[rowIndex][field.field].length > 15){
          //   console.log("jbdcjksbdc")
          //   return 'error-input';
          // }
          let value = rowData.businessNumber;
          if (value !== null && value !== "" && value && value.length == 15) {
            const firstNineDigits: string = value && value.substring(0, 9);
            const output = checkSINValidity(firstNineDigits);
            if (output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length < 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length > 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (
        rowData.recipientType === "Association Trust Club Partnership"
      ) {
        console.log("rowData.recipientType TRUST", rowData.recipientType);
        console.log("field.field123", field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "error-input";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length < 9
          ) {
            return "error-input";
          }
        }
      } else if (
        rowData.recipientType === "Government or International Organization"
      ) {
        console.log("rowData.recipientType CORPORATION", rowData.recipientType);
        console.log("field.field123333", field.field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (field.field === "businessNumber") {
          // console.log("1423--",tableData[rowIndex][field.field].length)
          // if(tableData[rowIndex][field.field] === null){
          //   return 'error-input';
          // } else if(tableData[rowIndex][field.field] === ''){
          //   return 'error-input';
          // }
          // if(tableData[rowIndex][field.field] && tableData[rowIndex][field.field].length > 15){
          //   console.log("jbdcjksbdc")
          //   return 'error-input';
          // }
          let value = rowData.businessNumber;
          if (value !== null && value !== "" && value && value.length == 15) {
            const firstNineDigits: string = value && value.substring(0, 9);
            const output = checkSINValidity(firstNineDigits);
            if (output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length < 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length > 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      }
    } else if (CSVInformationUI.tag === "RL3") {
      console.log("rowData.recipientType", rowData.recipientType);
      if (rowData.recipientType === "Individual") {
        console.log("rowData.recipientType INDIVIDUAL", rowData.recipientType);
        console.log("field.field123", field);
        //   if (field.field === 'socialInsurance'){
        //     console.log("1423")
        //   return 'error-input';
        // }
        if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === null
        ) {
          return "error-input";
        } else if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === ""
        ) {
          return "error-input";
        } else if (field.field === "socialInsurance") {
          const sinWithDashes = addDashesInSIN(
            tableData[rowIndex][field.field]
          );
          if (sinWithDashes && sinWithDashes.length < 11) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (sinWithDashes && sinWithDashes.length == 11) {
            let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
            const output = checkSINValidity(sin);
            let maxLength = 9;
            if (sin && sin.length == maxLength && output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          }
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          return "error-input";
        } else if (field.field === "firstName" && rowData.firstName === "") {
          return "error-input";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          return "error-input";
        } else if (field.field === "lastName" && rowData.lastName === "") {
          return "error-input";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          console.log(
            "rowData.firstLineOfRecipient123",
            rowData.firstLineOfRecipient
          );
          console.log("1423 firstLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          console.log(
            "rowData.secondLineOfRecipient123",
            rowData.secondLineOfRecipient
          );
          console.log("1423 secondLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (rowData.recipientType === "Joint Account") {
        console.log("field.field123", field);
        console.log("rowData.recipientType JA", rowData.recipientType);
        // if (field.field === 'socialInsurance'){
        //   console.log("1423")
        // return 'error-input';
        // }
        if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === null
        ) {
          return "error-input";
        } else if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === ""
        ) {
          return "error-input";
        } else if (field.field === "socialInsurance") {
          const sinWithDashes = addDashesInSIN(
            tableData[rowIndex][field.field]
          );
          if (sinWithDashes && sinWithDashes.length < 11) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (sinWithDashes && sinWithDashes.length == 11) {
            let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
            const output = checkSINValidity(sin);
            let maxLength = 9;
            if (sin && sin.length == maxLength && output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          }
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          return "error-input";
        } else if (field.field === "firstName" && rowData.firstName === "") {
          return "error-input";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          return "error-input";
        } else if (field.field === "lastName" && rowData.lastName === "") {
          return "error-input";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          console.log(
            "rowData.firstLineOfRecipient123",
            rowData.firstLineOfRecipient
          );
          console.log("1423 firstLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          console.log(
            "rowData.secondLineOfRecipient123",
            rowData.secondLineOfRecipient
          );
          console.log("1423 secondLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (rowData.recipientType === "Corporation") {
        console.log("rowData.recipientType CORPORATION", rowData.recipientType);
        console.log("field.field123333", field.field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (field.field === "businessNumber") {
          // console.log("1423--",tableData[rowIndex][field.field].length)
          // if(tableData[rowIndex][field.field] === null){
          //   return 'error-input';
          // } else if(tableData[rowIndex][field.field] === ''){
          //   return 'error-input';
          // }
          // if(tableData[rowIndex][field.field] && tableData[rowIndex][field.field].length > 15){
          //   console.log("jbdcjksbdc")
          //   return 'error-input';
          // }
          let value = rowData.businessNumber;
          if (value !== null && value !== "" && value && value.length == 15) {
            const firstNineDigits: string = value && value.substring(0, 9);
            const output = checkSINValidity(firstNineDigits);
            if (output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length < 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length > 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (
        rowData.recipientType === "Association Trust Club Partnership"
      ) {
        console.log("rowData.recipientType TRUST", rowData.recipientType);
        console.log("field.field123", field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "error-input";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length < 9
          ) {
            return "error-input";
          }
        }
      } else if (
        rowData.recipientType === "Government or International Organization"
      ) {
        console.log("rowData.recipientType CORPORATION", rowData.recipientType);
        console.log("field.field123333", field.field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (field.field === "businessNumber") {
          // console.log("1423--",tableData[rowIndex][field.field].length)
          // if(tableData[rowIndex][field.field] === null){
          //   return 'error-input';
          // } else if(tableData[rowIndex][field.field] === ''){
          //   return 'error-input';
          // }
          // if(tableData[rowIndex][field.field] && tableData[rowIndex][field.field].length > 15){
          //   console.log("jbdcjksbdc")
          //   return 'error-input';
          // }
          let value = rowData.businessNumber;
          if (value !== null && value !== "" && value && value.length == 15) {
            const firstNineDigits: string = value && value.substring(0, 9);
            const output = checkSINValidity(firstNineDigits);
            if (output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length < 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length > 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (
        rowData.recipientType ===
        "Spouse or trust settlor of spouse deceased person"
      ) {
        console.log("field.field123", field);
        console.log("rowData.recipientType JA", rowData.recipientType);
        // if (field.field === 'socialInsurance'){
        //   console.log("1423")
        // return 'error-input';
        // }
        if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === null
        ) {
          return "error-input";
        } else if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === ""
        ) {
          return "error-input";
        } else if (field.field === "socialInsurance") {
          const sinWithDashes = addDashesInSIN(
            tableData[rowIndex][field.field]
          );
          if (sinWithDashes && sinWithDashes.length < 11) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (sinWithDashes && sinWithDashes.length == 11) {
            let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
            const output = checkSINValidity(sin);
            let maxLength = 9;
            if (sin && sin.length == maxLength && output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          }
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          return "error-input";
        } else if (field.field === "firstName" && rowData.firstName === "") {
          return "error-input";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          return "error-input";
        } else if (field.field === "lastName" && rowData.lastName === "") {
          return "error-input";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          console.log(
            "rowData.firstLineOfRecipient123",
            rowData.firstLineOfRecipient
          );
          console.log("1423 firstLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          console.log(
            "rowData.secondLineOfRecipient123",
            rowData.secondLineOfRecipient
          );
          console.log("1423 secondLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (
        rowData.recipientType === "Holder of joint account other than 2"
      ) {
        console.log("field.field123", field);
        console.log("rowData.recipientType JA", rowData.recipientType);
        // if (field.field === 'socialInsurance'){
        //   console.log("1423")
        // return 'error-input';
        // }
        if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === null
        ) {
          return "error-input";
        } else if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === ""
        ) {
          return "error-input";
        } else if (field.field === "socialInsurance") {
          const sinWithDashes = addDashesInSIN(
            tableData[rowIndex][field.field]
          );
          if (sinWithDashes && sinWithDashes.length < 11) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (sinWithDashes && sinWithDashes.length == 11) {
            let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
            const output = checkSINValidity(sin);
            let maxLength = 9;
            if (sin && sin.length == maxLength && output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          }
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          return "error-input";
        } else if (field.field === "firstName" && rowData.firstName === "") {
          return "error-input";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          return "error-input";
        } else if (field.field === "lastName" && rowData.lastName === "") {
          return "error-input";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          console.log(
            "rowData.firstLineOfRecipient123",
            rowData.firstLineOfRecipient
          );
          console.log("1423 firstLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          console.log(
            "rowData.secondLineOfRecipient123",
            rowData.secondLineOfRecipient
          );
          console.log("1423 secondLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      }
    } else if (CSVInformationUI.tag === "RL16") {
      console.log("rowData.recipientType", rowData.recipientType);
      if (rowData.recipientType === "Individual") {
        console.log("rowData.recipientType INDIVIDUAL", rowData.recipientType);
        console.log("field.field123", field);
        //   if (field.field === 'socialInsurance'){
        //     console.log("1423")
        //   return 'error-input';
        // }
        if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === null
        ) {
          return "error-input";
        } else if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === ""
        ) {
          return "error-input";
        } else if (field.field === "socialInsurance") {
          const sinWithDashes = addDashesInSIN(
            tableData[rowIndex][field.field]
          );
          if (sinWithDashes && sinWithDashes.length < 11) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (sinWithDashes && sinWithDashes.length == 11) {
            let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
            const output = checkSINValidity(sin);
            let maxLength = 9;
            if (sin && sin.length == maxLength && output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          }
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          return "error-input";
        } else if (field.field === "firstName" && rowData.firstName === "") {
          return "error-input";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          return "error-input";
        } else if (field.field === "lastName" && rowData.lastName === "") {
          return "error-input";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          console.log(
            "rowData.firstLineOfRecipient123",
            rowData.firstLineOfRecipient
          );
          console.log("1423 firstLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          console.log(
            "rowData.secondLineOfRecipient123",
            rowData.secondLineOfRecipient
          );
          console.log("1423 secondLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (rowData.recipientType === "Joint Account") {
        console.log("field.field123", field);
        console.log("rowData.recipientType JA", rowData.recipientType);
        // if (field.field === 'socialInsurance'){
        //   console.log("1423")
        // return 'error-input';
        // }
        if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === null
        ) {
          return "error-input";
        } else if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === ""
        ) {
          return "error-input";
        } else if (field.field === "socialInsurance") {
          const sinWithDashes = addDashesInSIN(
            tableData[rowIndex][field.field]
          );
          if (sinWithDashes && sinWithDashes.length < 11) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (sinWithDashes && sinWithDashes.length == 11) {
            let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
            const output = checkSINValidity(sin);
            let maxLength = 9;
            if (sin && sin.length == maxLength && output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          }
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          return "error-input";
        } else if (field.field === "firstName" && rowData.firstName === "") {
          return "error-input";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          return "error-input";
        } else if (field.field === "lastName" && rowData.lastName === "") {
          return "error-input";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          console.log(
            "rowData.firstLineOfRecipient123",
            rowData.firstLineOfRecipient
          );
          console.log("1423 firstLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          console.log(
            "rowData.secondLineOfRecipient123",
            rowData.secondLineOfRecipient
          );
          console.log("1423 secondLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (rowData.recipientType === "Corporation") {
        console.log("rowData.recipientType CORPORATION", rowData.recipientType);
        console.log("field.field123333", field.field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (field.field === "businessNumber") {
          // console.log("1423--",tableData[rowIndex][field.field].length)
          // if(tableData[rowIndex][field.field] === null){
          //   return 'error-input';
          // } else if(tableData[rowIndex][field.field] === ''){
          //   return 'error-input';
          // }
          // if(tableData[rowIndex][field.field] && tableData[rowIndex][field.field].length > 15){
          //   console.log("jbdcjksbdc")
          //   return 'error-input';
          // }
          let value = rowData.businessNumber;
          if (value !== null && value !== "" && value && value.length == 15) {
            const firstNineDigits: string = value && value.substring(0, 9);
            const output = checkSINValidity(firstNineDigits);
            if (output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length < 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length > 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (
        rowData.recipientType === "Association Trust Club Partnership"
      ) {
        console.log("rowData.recipientType TRUST", rowData.recipientType);
        console.log("field.field123", field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "error-input";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length < 9
          ) {
            return "error-input";
          }
        }
      } else if (
        rowData.recipientType === "Government or International Organization"
      ) {
        console.log("rowData.recipientType CORPORATION", rowData.recipientType);
        console.log("field.field123333", field.field);
        if (field.field === "socialInsurance") {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          console.log("rowData.firstName123", rowData.firstName);
          console.log("1423 firstName");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          console.log("1423");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          return "error-input";
        } else if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === ""
        ) {
          return "error-input";
        }
        if (field.field === "businessNumber") {
          // console.log("1423--",tableData[rowIndex][field.field].length)
          // if(tableData[rowIndex][field.field] === null){
          //   return 'error-input';
          // } else if(tableData[rowIndex][field.field] === ''){
          //   return 'error-input';
          // }
          // if(tableData[rowIndex][field.field] && tableData[rowIndex][field.field].length > 15){
          //   console.log("jbdcjksbdc")
          //   return 'error-input';
          // }
          let value = rowData.businessNumber;
          if (value !== null && value !== "" && value && value.length == 15) {
            const firstNineDigits: string = value && value.substring(0, 9);
            const output = checkSINValidity(firstNineDigits);
            if (output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length < 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          } else if (
            value !== null &&
            value !== "" &&
            value &&
            value.length > 15
          ) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (
        rowData.recipientType ===
        "Spouse or trust settlor of spouse deceased person"
      ) {
        console.log("field.field123", field);
        console.log("rowData.recipientType JA", rowData.recipientType);
        // if (field.field === 'socialInsurance'){
        //   console.log("1423")
        // return 'error-input';
        // }
        if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === null
        ) {
          return "error-input";
        } else if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === ""
        ) {
          return "error-input";
        } else if (field.field === "socialInsurance") {
          const sinWithDashes = addDashesInSIN(
            tableData[rowIndex][field.field]
          );
          if (sinWithDashes && sinWithDashes.length < 11) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (sinWithDashes && sinWithDashes.length == 11) {
            let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
            const output = checkSINValidity(sin);
            let maxLength = 9;
            if (sin && sin.length == maxLength && output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          }
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          return "error-input";
        } else if (field.field === "firstName" && rowData.firstName === "") {
          return "error-input";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          return "error-input";
        } else if (field.field === "lastName" && rowData.lastName === "") {
          return "error-input";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          console.log(
            "rowData.firstLineOfRecipient123",
            rowData.firstLineOfRecipient
          );
          console.log("1423 firstLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          console.log(
            "rowData.secondLineOfRecipient123",
            rowData.secondLineOfRecipient
          );
          console.log("1423 secondLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      } else if (
        rowData.recipientType === "Holder of joint account other than 2"
      ) {
        console.log("field.field123", field);
        console.log("rowData.recipientType JA", rowData.recipientType);
        // if (field.field === 'socialInsurance'){
        //   console.log("1423")
        // return 'error-input';
        // }
        if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === null
        ) {
          return "error-input";
        } else if (
          field.field === "socialInsurance" &&
          rowData.socialInsurance === ""
        ) {
          return "error-input";
        } else if (field.field === "socialInsurance") {
          const sinWithDashes = addDashesInSIN(
            tableData[rowIndex][field.field]
          );
          if (sinWithDashes && sinWithDashes.length < 11) {
            return "error-input p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (sinWithDashes && sinWithDashes.length == 11) {
            let sin = sinWithDashes && sinWithDashes.replace(/-/g, "");
            const output = checkSINValidity(sin);
            let maxLength = 9;
            if (sin && sin.length == maxLength && output % 10 === 0) {
              return "p-inputtext p-component p-filled input-dropdown-fields";
            } else {
              return "error-input p-inputtext p-component p-filled input-dropdown-fields";
            }
          }
        }
        if (field.field === "firstName" && rowData.firstName === null) {
          return "error-input";
        } else if (field.field === "firstName" && rowData.firstName === "") {
          return "error-input";
        }
        if (field.field === "lastName" && rowData.lastName === null) {
          return "error-input";
        } else if (field.field === "lastName" && rowData.lastName === "") {
          return "error-input";
        }
        if (
          field.field === "firstLineOfRecipient" &&
          rowData.firstLineOfRecipient === null
        ) {
          console.log(
            "rowData.firstLineOfRecipient123",
            rowData.firstLineOfRecipient
          );
          console.log("1423 firstLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (
          field.field === "secondLineOfRecipient" &&
          rowData.secondLineOfRecipient === null
        ) {
          console.log(
            "rowData.secondLineOfRecipient123",
            rowData.secondLineOfRecipient
          );
          console.log("1423 secondLineOfRecipient");
          return "p-inputtext p-component p-filled input-dropdown-fields";
        }
        if (field.field === "businessNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 15
          ) {
            return "error-input";
          }
        }
        if (field.field === "trustAccountNumber") {
          console.log("1423");
          if (
            tableData[rowIndex][field.field] === null ||
            tableData[rowIndex][field.field] === ""
          ) {
            return "p-inputtext p-component p-filled input-dropdown-fields";
          }
          if (
            tableData[rowIndex][field.field] &&
            tableData[rowIndex][field.field].length > 9
          ) {
            return "error-input";
          }
        }
      }
    } else {
      if (field.field === "socialInsurance") {
        if (
          tableData[rowIndex][field.field] === null ||
          tableData[rowIndex][field.field] === ""
        ) {
          return "error-input";
        }
        // return 'error-input';
      }
      if (field.field === "firstName") {
        if (
          tableData[rowIndex][field.field] === null ||
          tableData[rowIndex][field.field] === ""
        ) {
          return "error-input";
        }
        if (rowData.firstName && rowData.firstName.length > 12) {
          return "error-input";
        }
      }
      if (field.field === "lastName") {
        if (
          tableData[rowIndex][field.field] === null ||
          tableData[rowIndex][field.field] === ""
        ) {
          return "error-input";
        }
        if (rowData.lastName && rowData.lastName.length > 12) {
          return "error-input";
        }
      }
    }

    return "input-dropdown-fields";
  };

  const columnsBody = (rowData: any, row: any, x: any) => {
    console.log("rowData-=", rowData);
    if (x.field === "socialInsurance") {
      const [formattedValue, setFormattedValue] = useState(
        addDashesInSIN(rowData.socialInsurance)
      );
      console.log("formattedValue", formattedValue);
      const handleValueChange = (event: any, rowIndex: number, field: any) => {
        setFormattedValue(addDashesInSIN(field));
        console.log("formattedValue2", formattedValue);
        const updatedData = [...tableData];
        updatedData[rowIndex][event.field] = field;
        setTableData(updatedData);
        console.log("updatedData2", updatedData);
      };
      return (
        <input
          type="text"
          className={renderErrorClass(x, row.rowIndex, rowData)}
          value={formattedValue}
          onChange={(e: any) =>
            handleValueChange(x, row.rowIndex, e.target.value)
          }
          maxLength={11} // Adjust as needed to match the desired format length
          style={{ padding: "0.85rem" }}
        />
      );
    } else if (x.field === "status") {
      return (
        <Dropdown
          options={[
            { label: "Active", value: "Active" },
            { label: "Inactive", value: "Inactive" },
          ]}
          value={rowData.status}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={renderErrorClass(x, row.rowIndex, rowData)}
        />
      );
    } else if (x.field === "vendorStatus") {
      return (
        <Dropdown
          options={[
            { label: "Active", value: "Active" },
            { label: "Inactive", value: "Inactive" },
          ]}
          value={rowData.vendorStatus}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={renderErrorClass(x, row.rowIndex, rowData)}
        />
      );
    } else if (x.field === "type") {
      return (
        <Dropdown
          options={employeeTypeList}
          value={rowData.type}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
        />
      );
    } else if (x.field === "province") {
      return (
        <Dropdown
          options={provinceShortCodeList}
          value={rowData.province}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={renderErrorClass(x, row.rowIndex, rowData)}
        />
      );
    } else if (x.field === "provinceOfEmployment") {
      return (
        <Dropdown
          options={provinceShortCodeList}
          value={rowData.provinceOfEmployment}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={renderErrorClass(x, row.rowIndex, rowData)}
        />
      );
    } else if (x.field === "cppQppExempt") {
      return (
        <Dropdown
          options={[
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
          ]}
          value={rowData.cppQppExempt}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
        />
      );
    } else if (x.field === "eiExempt") {
      return (
        <Dropdown
          options={[
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
          ]}
          value={rowData.eiExempt}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
        />
      );
    } else if (x.field === "ppipExempt") {
      return (
        <Dropdown
          options={[
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
          ]}
          value={rowData.ppipExempt}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
        />
      );
    } else if (x.field === "employmentCode") {
      return (
        <Dropdown
          options={employeeCodeList}
          value={rowData.employmentCode}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
        />
      );
    } else if (x.field === "payPeriod") {
      return (
        <Dropdown
          options={payPeriodsList}
          value={rowData.payPeriod}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={renderErrorClass(x, row.rowIndex, rowData)}
        />
      );
    } else if (x.field === "reducedEi") {
      return (
        <Dropdown
          options={[
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
          ]}
          value={rowData.reducedEi}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
        />
      );
    } else if (x.field === "slipType") {
      return (
        <Dropdown
          options={slipTypeList}
          value={rowData.slipType}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={renderErrorClass(x, row.rowIndex, rowData)}
        />
      );
    } else if (x.field === "slipStatus") {
      return (
        <Dropdown
          options={[
            { label: "Completed", value: "Complete" },
            { label: "Draft", value: "Draft" },
          ]}
          value={rowData.slipStatus}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={renderErrorClass(x, row.rowIndex, rowData)}
        />
      );
    } else if (x.field === "t4OtherInformation1") {
      //t4 OI
      return (
        <Dropdown
          options={T4OtherInformationList}
          value={rowData.t4OtherInformation1}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "t4OtherInformation2") {
      return (
        <Dropdown
          options={T4OtherInformationList}
          value={rowData.t4OtherInformation2}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "t4OtherInformation3") {
      return (
        <Dropdown
          options={T4OtherInformationList}
          value={rowData.t4OtherInformation3}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "t4OtherInformation4") {
      return (
        <Dropdown
          options={T4OtherInformationList}
          value={rowData.t4OtherInformation4}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "t4OtherInformation5") {
      return (
        <Dropdown
          options={T4OtherInformationList}
          value={rowData.t4OtherInformation5}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "t4OtherInformation6") {
      return (
        <Dropdown
          options={T4OtherInformationList}
          value={rowData.t4OtherInformation6}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "codeCaseO") {
      //rl1
      return (
        <Dropdown
          options={rl1CaseOList}
          value={rowData.codeCaseO}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "otherInfo1") {
      //rl1 OI
      return (
        <Dropdown
          options={
            CSVInformationUI.tag === "RL3"
              ? RL3OtherInformationList
              : CSVInformationUI.tag === "RL16"
              ? RL16OtherInformationList
              : RL1OtherInformationList
          }
          value={rowData.otherInfo1}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "otherInfo2") {
      return (
        <Dropdown
          options={
            CSVInformationUI.tag === "RL3"
              ? RL3OtherInformationList
              : CSVInformationUI.tag === "RL16"
              ? RL16OtherInformationList
              : RL1OtherInformationList
          }
          value={rowData.otherInfo2}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "otherInfo3") {
      return (
        <Dropdown
          options={
            CSVInformationUI.tag === "RL3"
              ? RL3OtherInformationList
              : CSVInformationUI.tag === "RL16"
              ? RL16OtherInformationList
              : RL1OtherInformationList
          }
          value={rowData.otherInfo3}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "otherInfo4") {
      return (
        <Dropdown
          options={
            CSVInformationUI.tag === "RL3"
              ? RL3OtherInformationList
              : CSVInformationUI.tag === "RL16"
              ? RL16OtherInformationList
              : RL1OtherInformationList
          }
          value={rowData.otherInfo4}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "otherInfo5") {
      return (
        <Dropdown
          options={
            CSVInformationUI.tag === "RL3"
              ? RL3OtherInformationList
              : CSVInformationUI.tag === "RL16"
              ? RL16OtherInformationList
              : RL1OtherInformationList
          }
          value={rowData.otherInfo5}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "otherInfo6") {
      return (
        <Dropdown
          options={
            CSVInformationUI.tag === "RL3"
              ? RL3OtherInformationList
              : CSVInformationUI.tag === "RL16"
              ? RL16OtherInformationList
              : RL1OtherInformationList
          }
          value={rowData.otherInfo6}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "payeeType") {
      //t4a
      return (
        <Dropdown
          options={[
            { label: "Business", value: "Business" },
            { label: "Personal", value: "Personal" },
          ]}
          value={rowData.payeeType}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={renderErrorClass(x, row.rowIndex, rowData)}
        />
      );
    } else if (x.field === "otherInformation1") {
      return (
        <Dropdown
          options={T4AOtherInformationList}
          value={rowData.otherInformation1}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "otherInformation2") {
      return (
        <Dropdown
          options={T4AOtherInformationList}
          value={rowData.otherInformation2}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "otherInformation3") {
      return (
        <Dropdown
          options={T4AOtherInformationList}
          value={rowData.otherInformation3}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "otherInformation4") {
      return (
        <Dropdown
          options={T4AOtherInformationList}
          value={rowData.otherInformation4}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "otherInformation5") {
      return (
        <Dropdown
          options={T4AOtherInformationList}
          value={rowData.otherInformation5}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "otherInformation6") {
      return (
        <Dropdown
          options={T4AOtherInformationList}
          value={rowData.otherInformation6}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "employerOfferedDentalBenefits") {
      return (
        <Dropdown
          options={employerOfferedDentalBenifits}
          value={rowData.employerOfferedDentalBenefits}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "recipientType") {
      return (
        <Dropdown
          options={
            CSVInformationUI.tag === "RL3"
              ? recipientTypeRL3OptionsDDL
              : recipientTypeOptionsDDL
          }
          value={rowData.recipientType}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "foreignCurrency") {
      return (
        <Dropdown
          options={foreignCurrencyDDList && foreignCurrencyDDListItems}
          value={rowData.foreignCurrency}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "t3OtherInformation1") {
      //t3 OI
      return (
        <Dropdown
          options={T3OtherInformationList}
          value={rowData.t3OtherInformation1}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "t3OtherInformation2") {
      return (
        <Dropdown
          options={T3OtherInformationList}
          value={rowData.t3OtherInformation2}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "t3OtherInformation3") {
      return (
        <Dropdown
          options={T3OtherInformationList}
          value={rowData.t3OtherInformation3}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "t3OtherInformation4") {
      return (
        <Dropdown
          options={T3OtherInformationList}
          value={rowData.t3OtherInformation4}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "t3OtherInformation5") {
      return (
        <Dropdown
          options={T3OtherInformationList}
          value={rowData.t3OtherInformation5}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "t3OtherInformation6") {
      return (
        <Dropdown
          options={T3OtherInformationList}
          value={rowData.t3OtherInformation6}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    } else if (x.field === "consolidateIndicator") {
      return (
        <Dropdown
          options={ConsolidateIndicatorDDL}
          value={rowData.consolidateIndicator}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          placeholder="--"
          className={"input-dropdown-fields"}
        />
      );
    }
    // else if(x.field === "businessNumber"){
    //   return (
    //     <MaskedInput
    //       value={rowData[x.field]}
    //       onChange={(e:any) => onCellValueChange(x, row.rowIndex, e.target.value)}
    //       className={renderErrorClass(x, row.rowIndex,rowData)}
    //       placeholder={"000000000RP0000"}
    //       mask={"000000000RP0000"}
    //       sx={{width:"200px"}}
    //   />
    //     )
    // }

    if (
      x.field === "firstName" ||
      x.field === "lastName" ||
      x.field === "dateOfBirth" ||
      x.field === "status" ||
      x.field === "address" ||
      x.field === "city" ||
      x.field === "postCode" ||
      x.field === "businessNumber" ||
      x.field === "businessName" ||
      x.field === "initials" ||
      x.field === "firstLineOfRecipient" ||
      x.field === "secondLineOfRecipient" ||
      x.field === "trustAccountNumber"
    ) {
      return (
        <InputText
          value={rowData[x.field]}
          onChange={(e: any) =>
            onCellValueChange(x, row.rowIndex, e.target.value)
          }
          className={renderErrorClass(x, row.rowIndex, rowData)}
        />
      );
    }

    return (
      <InputText
        //  id={generateCellId(0, row.rowIndex)}
        value={rowData[x.field]}
        onChange={(e: any) =>
          onCellValueChange(x, row.rowIndex, e.target.value)
        }
        // className={renderErrorClass(x, row.rowIndex,rowData)}
      />
    );
  };
  console.log("props headerColumns => ", columns);
  console.log("props items T4 slip information => ", rows);
  console.log("tableData =>", tableData);
  console.log("CSVInformationUI => ", CSVInformationUI);
  return (
    <DataTable
      value={rows && tableData}
      responsiveLayout="scroll"
      // paginator
      // rows={25}
      // rowsPerPageOptions={[25, 50, 100]}
      // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Slips Information"
      // selection={selectedItems}
      // onSelectionChange={(e: any) => setSelectedItems(e.value)}
      editMode="cell"
      showGridlines
      resizableColumns
      columnResizeMode="expand"
      scrollable
      reorderableColumns
      filterDisplay="menu"
      loading={isLoading}
      globalFilterFields={["code"]}
      emptyMessage={
        CSVInformationUI
          ? "Data is loading please wait for a while..."
          : "No Record found."
      }
      className="CSVInformationUI"
      footer={footer}
      style={{ margin: "0.5rem" }}
      editingRows={rows && rows.map((item: any, index: any) => index)}
      height={"400px"}
    >
      {rows && (
        <Column
          {...indexColumn}
          style={{
            width: "3rem",
            textAlign: "center",
            zIndex: 2,
            borderRight: "1px solid #dee2e6",
          }}
          frozen
          hidden={!showIndexColumn}
          className="indexColumn"
        />
      )}

      {columns &&
        columns.map((x: any, index: any) => {
          return (
            <Column
              key={index}
              field={x.field}
              header={x.headerName}
              headerTooltip={x.description}
              headerTooltipOptions={{ position: "top" }}
              sortable={false}
              resizeable={x.resizable}
              dataType={x.type}
              align={x.align}
              alignHeader={x.headerAlign}
              reorderable={x.disableReorder}
              exportable={x.disableExport}
              frozen={x.frozenColumn}
              columnKey={x.field}
              style={{
                minWidth: x.minWidth,
                fontSize: "0.875rem",
              }}
              footer={x.footer}
              body={(rowData: any, row: any) => columnsBody(rowData, row, x)}
            />
          );
        })}
    </DataTable>
  );
};

export default PrimeDataGrid;
