import { FC } from "react";

import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Toolbar } from "@mui/material";
import {
  EnhancedDropDownRHFWithLabel,
  LabelPosition,
  ResetButton,
  SubmitButton,
} from "@websential/cosmic";

export interface ITaxslipsDefinitionsToolbar {
  onResetClicked: () => void;
  isSubmitting: boolean;
  yearsDDL:any;
}
export const TaxslipsDefinitionsToolbar: FC<ITaxslipsDefinitionsToolbar> = ({
  onResetClicked,
  isSubmitting = false,
  yearsDDL
}) => {
  return (
    <Toolbar >
      <>
        <div style={{display:"contents"}}>
          <div style={{margin:"1rem 0rem"}}>
          
            <SubmitButton
              label="Submit"
              testId="submit-button"
              loading={isSubmitting}
              icon={<PlaylistAddCheckOutlinedIcon />}
              
            />
          </div>
          <ResetButton testId="reset-button" onClickHandler={onResetClicked} />
        </div>
        <div style={{ flexGrow: 1 }} />
        <div style={{minWidth:"30%"}}>
        <EnhancedDropDownRHFWithLabel
                  selectName="year"
                  label="Select Year"
                  labelPosition={LabelPosition.Left}
                  items={yearsDDL ? yearsDDL : []}
                  testId={`year-ddl`}
                  selectedItem={new Date().getFullYear()}
                  required
                />
        </div>
      </>
    </Toolbar>
  );
};
