import { IDataRoleModel, ILookupFieldSetupRequestPayload, ITaxSlipCategoryRequestPayload, ITaxSlipYearRequestPayload } from "models";
import { IAccountant, IAccountantResponse } from "models/accountant";
import { IClient, IClientResponse } from "models/client";
import { IDropDownListResponse, IFlexFieldSetupRequest, IFlexFieldSetupResponse } from "models/flexField";
import { IFlexFieldPanelAssignmentResponsePayload, IFlexFieldPanelRequestPayload, IScreenPanelsAssignmentResponsePayload } from "models/flexFieldPanel";
import { IFlexFieldTabSetupRequest } from "models/flexFieldTab";
import { IResource, IResourceResponse } from "models/resource";
import { IRL1SlipRequestPayload } from "models/rl1Slip/IRL1SlipRequestPayload";
import { IT4SlipRequestPayload } from "models/t4Slip/IT4SlipRequestPayload";
import { IT4ASlipRequestPayload } from "models/t4aSlip/IT4ASlipRequestPayload";
import { IBillingProfileScreenPanelsAssignmentResponsePayload } from "models/taxProfile";

export const instanceOfIFlexFieldSetupRequest = (
  item: any
): item is IFlexFieldSetupRequest => "id" in item;

export const instanceOfIFlexFieldTabSetupRequest = (
  item: any
): item is IFlexFieldTabSetupRequest => "appName" in item;

export const instanceOfIFlexFieldPanelLabelSetupRequest = (
  item: any
): item is IFlexFieldPanelRequestPayload => "appName" in item;

export const instanceOfIFlexFieldPanelAssignmentResponsePayload = (
  item: any
): item is IFlexFieldPanelAssignmentResponsePayload => "panelId" in item;

export const instanceOfIIDropDownListResponseArray = (
  item: any
): item is IDropDownListResponse => "label" in item;

export const instanceOfIFlexFieldSetupResponse = (
  item: any
): item is IFlexFieldSetupResponse => "fieldLabel" in item;

export const instanceOfIScreenPanelsAssignmentResponsePayload = (
  item: any
): item is IScreenPanelsAssignmentResponsePayload => "screenId" in item;

export const instanceOfIAccountantResponse = (
  item: any
): item is IAccountantResponse => "accTitle" in item;

export const instanceOfIClientResponse = (
  item: any
): item is IClientResponse => "clientIdentityNo" in item;

export const instanceOfIAccountant = (
  item: any
): item is IAccountant => "accTitle" in item;

export const instanceOfIClient = (
  item: any
): item is IClient => "clientIdentityNo" in item;

export const instanceOfIResourceResponse = (
  item: any
): item is IResourceResponse => "clientName" in item;

export const instanceOfIResource = (
  item: any
): item is IResource => "uniqueIdentifier" in item;
export const instanceOfIDataRoleResponce = (
  item: any
): item is IDataRoleModel => "roleName" in item;
export const instanceOfILookupFieldResponce = (
  item: any
): item is ILookupFieldSetupRequestPayload => "name" in item;
export const instanceOfIYearsResponce = (
  item: any
): item is ITaxSlipYearRequestPayload => "year" in item;
export const instanceOfITaxslipCategoryResponce = (
  item: any
): item is ITaxSlipCategoryRequestPayload => "name" in item;
export const instanceOfITaxslipDefinitionsResponce = (
  item: any
): item is any => "code" in item;
export const instanceOfIFillingResourceResponce = (
  item: any
): item is ITaxSlipCategoryRequestPayload => "name" in item;
export const instanceOfIT4SlipResponce = (
  item: any
): item is IT4SlipRequestPayload => "slipStatus" in item;
export const instanceOfIT4ASlipResponce = (
  item: any
): item is IT4ASlipRequestPayload => "slipStatus" in item;
export const instanceOfIT5SlipResponce = (
  item: any
): item is IT4ASlipRequestPayload => "slipStatus" in item;
export const instanceOfIT3SlipResponce = (
  item: any
): item is IT4ASlipRequestPayload => "slipStatus" in item;
export const instanceOfIRL3SlipResponce = (
  item: any
): item is IT4ASlipRequestPayload => "slipStatus" in item;
export const instanceOfIRL16SlipResponce = (
  item: any
): item is IT4ASlipRequestPayload => "slipStatus" in item;
export const instanceOfIRL1SlipResponce = (
  item: any
): item is IRL1SlipRequestPayload => "id" in item;
export const instanceOfIBillingProfileScreenPanelsAssignmentResponsePayload = (
  item: any
): item is IBillingProfileScreenPanelsAssignmentResponsePayload => "accountantId" in item;
