import { GenerateXMLButton } from "components/GenerateXMLButton";
import { FC } from "react";

import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Box, Toolbar } from "@mui/material";
import {
  PrintButton,
  RHookFormTextField,
  SubmitButton,
  DeleteButton,
} from "@websential/cosmic";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";

export interface IT4SummaryToolbar {
  onGenerateXMLClicked: () => void;
  onPrintClicked: () => void;
  isSubmitting: boolean;
  onDeleteClicked: () => void;
  SummaryResponseToPass: any;
}

export const T4SummaryToolbar: FC<IT4SummaryToolbar> = ({
  onGenerateXMLClicked,
  onPrintClicked,
  isSubmitting = false,
  onDeleteClicked,
  SummaryResponseToPass,
}) => {
  const isAdminUser = useSelector((state:IAppState) => state.user.profile.isSuperStatus)
  return (
    <Toolbar>
      <>
        <div style={{ display: "flex", width: "50%" }}>
          <div
            style={{
              alignSelf: "center",
              paddingTop: "3px",
              paddingRight: "12px",
            }}
          >
            <SubmitButton
              label="Submit"
              testId="submit-button"
              loading={isSubmitting}
              icon={<PlaylistAddCheckOutlinedIcon />}
            />
          </div>
          <RHookFormTextField
            id="confirmationCode"
            testId={"confirmation-number"}
            placeholder="Confirmation Number"
          />
          {isAdminUser === 1 ? 
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DeleteButton
              onClickHandler={onDeleteClicked}
              testId="delete-button"
            />
          </Box>
          :<></>}
        </div>
        <div style={{ flexGrow: 1 }} />
        <div>
          <GenerateXMLButton
            onClickHandler={onGenerateXMLClicked}
            testId="summary-generate-xml-button"
          />
          <PrintButton
            onClickHandler={onPrintClicked}
            testId="summary-print-button"
            label={""}
          />
        </div>
      </>
    </Toolbar>
  );
};
