import { INITIAL_SNACKBAR_DATA, QUERY_OPTIONS } from "common/constants";
import { FC, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";

import { Box, Grid, Typography, useTheme } from "@mui/material";
import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  EnhancedToolTip,
  ITest,
  StandardPanel,
} from "@websential/cosmic";
import { AdjustmentService } from "services/AdjustmentService";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import PrimeDataGrid from "./PrimeDataGrid";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IPierReviewSetup extends ITest {}
const PIER_REVIEW_LIST_QUERY_KEY = "pier-review-list"

const usePierReview = (
  clientId: string,
  taxYear: number
) =>
  useQuery(
    [PIER_REVIEW_LIST_QUERY_KEY,clientId],
    (data) => {
      return AdjustmentService.createPierReview(clientId, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

const t4AdjustmentInstructions = [
  "To check potential adjustments & savings, click 'Adjust Slips' button (You can undo adjustments at any time)",
  "System will calculate adjustments as per settings in Adjustment > Options screen.",
  "Over / under remittance amount will be calculated.",
  "Employees' portion of adjustment will be adjusted in their own slips, and employer's contribution will be adjusted in employee selected from 'Transfer Over Remittance' panel in Adjustment > Options screen.",
  "To transfer over/under remittance, select employee (usually director or owner of account) from 'Transfer Over Remittance' panel in Adjustment > Options screen and save data.",
  "Once employee is selected, you can transfer amount to selected employee's T4 Slip, from this screen.",
  "To view adjustment details click here.",
];

const rl1AdjustmentInstructions = [
  "To check potential adjustments & savings, click 'Adjust Slips' button (You can undo adjustments at any time)",
  "System will calculate adjustments as per settings in Adjustment > Options screen.",
  "Over / under remittance amount will be calculated.",
  "Employees' portion of adjustment will be adjusted in their own slips, and employer's contribution will be adjusted in employee selected from 'Transfer Over Remittance' panel in Adjustment > Options screen.",
  "To transfer over/under remittance, select employee (usually director or owner of account) from 'Transfer Over Remittance' panel in Adjustment > Options screen and save data.",
  "Once employee is selected, you can transfer amount to selected employee's T4 Slip, from this screen.",
  "To view adjustment details click here.",
];

const GET_ADJUSTMENTS_QUERY_KEY = "get-adjustments";

const useAdjustments = (clientId: string) =>
  useQuery(
    GET_ADJUSTMENTS_QUERY_KEY,
    () => AdjustmentOptionsService.getAdjustmentOptionsByClientId(clientId),
    QUERY_OPTIONS
  );

export const PierReviewSetup: React.FC<IPierReviewSetup> = ({ testId }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const clientId  = useSelector((state: IAppState) => state.client.id)
  const taxYear = useSelector((state: IAppState) => state.taxYear.year)
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [pierReview, setPierReview] = useState<any>(undefined);

  // const { isLoading, data } = usePierReview(clientId, taxYear);
  // const PierReviewData: any = data;
  // console.log("@@@@@ => PierReviewData responce", PierReviewData?.items);

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  // const queryClient = useQueryClient();
  // const isAxiosCallLoading = queryClient.isFetching([PIER_REVIEW_LIST_QUERY_KEY]) > 0;
  // console.log("isAxiosCallLoading",isAxiosCallLoading)

  const { isLoading: isLoadingAdjustments, data: adjustments } =
    useAdjustments("");

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  useEffect(() => {
    const fetchData = async () => {
      // Set loading to true when starting the API call
      setIsLoadingData(true);

      try {
        const response = await AdjustmentService.createPierReview(clientId, taxYear);
        setPierReview(response)

        // Handle the response as needed, update auditFiling
      } catch (error) {
        setSnackbarData({
          open: true,
          message: "Something went wrong!",
          severity: "error",
        });
      } finally {
        // Set loading to false when the API call is completed (success or error)
        setIsLoadingData(false);
      }
    };

    fetchData();
  }, [clientId, taxYear]); // Include necessary dependencies for your API call

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: '',
        companyName: '',
        activityType:
          "",
        activityLogVisibility: false,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  return (
    <>
      <BackdropCircularProgress open={isLoadingAdjustments || isLoadingData} testId={testId} />
      <Grid
        container
        sx={{ marginTop: theme.spacing(0) }}
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StandardPanel
            title="T4 Slip(s) (Employer Contribution)"
            variant="outlined"
            action={
              <EnhancedToolTip
                title=""
                tooltipTitle="T4 Adjustment instructions"
                tooltipContents={t4AdjustmentInstructions}
                testId="t4-adjustment-instructions"
              />
            }
            testId={`${testId}-t4-adjustment-panel`}
          >
            {pierReview && pierReview ?
             (
              <>
              <Typography variant="body2" sx={{ paddingBottom: 2, fontWeight: 'bold' }}>
                 {`Total T4 Slips: ${pierReview && pierReview["t4Adjustments"]["totalSlipsAdjusted"]} / ${pierReview && pierReview["t4Adjustments"]["totalSlipsAdjusted"]}`}
              </Typography>

              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>T4 Slips</Typography>
                <Typography>{pierReview && pierReview["t4Adjustments"]["totalSlipsAdjusted"]}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>Total CPP Adjustment</Typography>
                <Typography>{parseFloat(pierReview && pierReview["t4Adjustments"]["totalCppAdjustments"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>Total EI Adjustment</Typography>
                <Typography>{parseFloat(pierReview && pierReview["t4Adjustments"]["totalEiAdjustments"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>(Over)/ Under-remittance</Typography>
                <Typography>{pierReview && pierReview["t4Adjustments"]["overUnderRemittance"] < 0 ? `(${Math.abs(pierReview && pierReview["t4Adjustments"]["overUnderRemittance"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')})` : pierReview && parseFloat(pierReview["t4Adjustments"]["overUnderRemittance"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              </>
            ) :
            <Typography variant="body2" sx={{ paddingBottom: 2 }}>
              No slips created/completed.
            </Typography>}

          </StandardPanel>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <StandardPanel
            title="RL-1 Slip(s) (Employer Contribution)"
            variant="outlined"
            action={
              <EnhancedToolTip
                title=""
                tooltipTitle="RL-1 Adjustment instructions"
                tooltipContents={rl1AdjustmentInstructions}
                testId="rl1-adjustment-instructions"
              />
            }
            testId={`${testId}-rl1-adjustment-panel`}
          >
            {pierReview && pierReview ?
             (
              <>
              <Typography variant="body2" sx={{ paddingBottom: 2, fontWeight: 'bold' }}>
                 {`Total Adjusted RL-1 Slips: ${pierReview && pierReview["rl1Adjustments"]["totalSlipsAdjusted"]} / ${pierReview && pierReview["rl1Adjustments"]["totalSlipsAdjusted"]}`}
              </Typography>

              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>RL1</Typography>
                <Typography>{pierReview && pierReview["rl1Adjustments"]["totalSlipsAdjusted"]}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>Total QPP Adjustment</Typography>
                <Typography>{parseFloat(pierReview && pierReview["rl1Adjustments"]["totalQpipAdjustments"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>Total PPIP Adjustment</Typography>
                <Typography>{parseFloat(pierReview && pierReview["rl1Adjustments"]["totalQppAdjustments"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
                <Typography>(Over)/ Under-remittance</Typography>
                <Typography>{pierReview && pierReview["rl1Adjustments"]["overUnderRemittance"] < 0 ? `(${Math.abs(pierReview && pierReview["rl1Adjustments"]["overUnderRemittance"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')})` : pierReview && parseFloat(pierReview["rl1Adjustments"]["overUnderRemittance"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Box>
              </>
            ) :
            <Typography variant="body2" sx={{ paddingBottom: 2 }}>
              One or more slips have not been adjusted.
            </Typography>}
          </StandardPanel>
        </Grid>
      </Grid>

      <Grid>
      <PrimeDataGrid
        rows={pierReview && pierReview?.items && pierReview && pierReview?.items}
        columns={pierReview && pierReview ? pierReview && pierReview.headerColumns : []}
        isLoading={isLoadingData}
      />
      </Grid>

      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </>
  );
};
