import { API_KEYS_SCREEN_ID, API_KEYS_SCREEN_SLUG, INITIAL_SNACKBAR_DATA } from "common/constants";
import { IAPIKeysRequest, IAddAPIKeysRequest } from "models/ApiKeys";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { APIKeysService } from "services/APIKeysService";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  ITest,
  LabelPosition,
  RHookFormTextFieldWithLabel,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  SubmitButton,
  SubmitButtonVariant,
} from "@websential/cosmic";

import { APIKeysSchema } from "./validator";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IAPIKeys extends ITest {}

const DEFAULT_VALUES = {
  clientSecret: "",
  authKey: "",
  taxSlipsClientId: "",
};

export const APIKeys: FC<IAPIKeys> = ({ testId }) => {
  const clientName = useSelector((state : IAppState) => state.client.clientName);
  const dispatch = useDispatch();
  const isLoadingActivityLogData = useSelector((state: IAppState) => state.isLoadAxiosCall.isLoading);

  const clientId = useSelector((state : IAppState) => state.client.id);
  console.log("clientName",clientName)
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  const { isLoading: isSubmitting, mutate: submitAPIKeys } = useMutation(
    APIKeysService.add,
    {
      onSuccess: (res: IAPIKeysRequest) => {
        setSnackbarData({
          open: true,
          message: "API keys has been generated successfully",
          severity: "success",
        });
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );
  const formMethods = useForm<IAPIKeysRequest>({
    resolver: yupResolver(APIKeysSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset(DEFAULT_VALUES);
  };

  /**
   * Handle when the form is submitted.
   *
   * @param {IAPIKeysRequest} formData
   */
  const onSubmit = (formData: IAPIKeysRequest) => {
    console.log("formData",formData)
    submitAPIKeys(formData);
  };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  useEffect(()=>{
    const fetchData = async () => {
      try {
        let payload:IAddAPIKeysRequest = {
          clientName:clientName,
          generateNew:false
        }
        const response: any = await APIKeysService.addApiKeys(payload);
        console.log("responseData-----------------", response);
        if(response){
          formMethods.setValue('clientSecret',response.valueSecret)
          formMethods.setValue('authKey',response.authKey)
          formMethods.setValue('taxSlipsClientId',clientId)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  },[])

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:API_KEYS_SCREEN_SLUG,
      companyName:clientName,
      activityType:"activity_type=api_keys_generated",
      activityLogVisibility:true
    }));
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  },[])

  return (
    <>
      <BackdropCircularProgress open={isSubmitting || isLoadingActivityLogData} testId={testId} />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            children={
              <StandardPanel
                title="API Keys"
                testId={`${testId}-custom-fields`}
              >
                <RHookFormTextFieldWithLabel
                  label="Client Secret"
                  id="clientSecret"
                  testId={`${testId}-client-secret`}
                  readOnly
                  labelPosition={LabelPosition.Left}
                />

                <RHookFormTextFieldWithLabel
                  label="Auth Key"
                  id="authKey"
                  testId={`${testId}-auth-key`}
                  readOnly
                  labelPosition={LabelPosition.Left}
                />

                <RHookFormTextFieldWithLabel
                  label="TaxSlips Client ID"
                  id="taxSlipsClientId"
                  testId={`${testId}-tax-slips-client-id`}
                  readOnly
                  labelPosition={LabelPosition.Left}
                />
              </StandardPanel>
            }
            toolbar={
              <StandardSubmitFormButtonsToolbar
                toolbarContents={
                  <SubmitButton
                    label={"GENERATE KEY"}
                    testId={"btnSubmit"}
                    variant={SubmitButtonVariant.Primary}
                  />
                }
                onResetClicked={onResetClicked}
                isSubmitting={false}
                testId="api-keys-toolbar"
              />
            }
            testId={"api-keys-layout"}
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </>
  );
};
