import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";

// import { SlipsMenu } from "./SlipsRoutes";
// import { PeopleMenu } from "./PeopleRoutes";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearSelectedAccountant, clearSelectedAccountantName, clearSelectedClient, clearSelectedClientName, clearSelectedINVOICE, clearSelectedTaxYear } from "store/actions";
import { SlipsRoutesComponent } from "./SlipsRoutes";
import { PeopleMenuComponent } from "./PeopleRoutes";
export const ClientRoutesComponent = () => {

const { SlipsRoutes } = SlipsRoutesComponent();
const { PeopleMenu } = PeopleMenuComponent();

const navigate = useNavigate();
const dispatch = useDispatch();

const clearClientId = () => {
  dispatch(clearSelectedClient());
};
const clearClientName = () => {
  dispatch(clearSelectedClientName());
};
const clearAccountantId = () => {
  dispatch(clearSelectedAccountant());
};
const clearAccountantName = () => {
  dispatch(clearSelectedAccountantName());
};
const clearTaxYear = () => {
  dispatch(clearSelectedTaxYear());
};
const clearRefNo = () => {
  dispatch(clearSelectedINVOICE());
};
const ArrayData = [
  {
    label: "Home",
    icon: <HomeOutlinedIcon />,
    items: [
      {
        label: "Dashboard",
        command: () => {
            navigate("/clients/dashboard")
        },
      },
      {
        label: "Client List",
        command: () => {
            clearClientId();
            clearClientName();
            clearTaxYear();
            clearRefNo();
            navigate("/clients")
        },
      },
    ],
  },
  {
    label: "People",
    icon: <ContactMailOutlinedIcon />,
    items: PeopleMenu,
  },
  {
    label: "Adjustment",
    icon: <ListAltOutlinedIcon />,
    items: [
      {
        label: "Options",
        command: () => {
            navigate("/adjustments/options")
        },
      },
      {
        label: "Adjustments",
        command: () => {
            navigate("/adjustments/setup")
        },
      },
      {
        label: "T4 Report",
        command: () => {
            navigate("/adjustments/t4-report")
        },
      },
      {
        label: "RL-1 Report",
        command: () => {
            navigate("/adjustments/rl1-report")
        },
      },
    ],
  },
  {
    label: "Audit",
    icon: <AssessmentOutlinedIcon />,
    items: [
      {
        label: "Filing",
        command: () => {
            navigate("/audit/filing")
        },
      },
      {
        label: "Earnings Audit",
        command: () => {
            navigate("/audit/earnings-audit")
        },
      },
      {
        label: "PIER Review",
        command: () => {
            navigate("/audit/pier-review")
        },
      },
    ],
  },
  {
    label: "Compliance",
    icon: <DoneAllOutlinedIcon />,
    items: SlipsRoutes,
  },
  {
    label: "Report",
    icon: <TableChartOutlinedIcon />,

    items: [
      {
        label: "Employees",
        command: () => {
            navigate("/reports/employees")
        },
      },
      {
        label: "Vendors",
        command: () => {
            navigate("/reports/vendors")
        },
      },
      {
        label: "T4",
        command: () => {
            navigate("/reports/t4-reports")
        },
      },
      {
        label: "RL-1",
        command: () => {
            navigate("/reports/rl1-reports")
        },
      },
      {
        label: "T4A",
        command: () => {
            navigate("/reports/t4a-reports")
        },
      },
      {
        label: "T5",
        command: () => {
            navigate("/reports/t5-reports")
        },
      },
      {
        label: "RL-3",
        command: () => {
          navigate("/reports/rl3-reports");
        },
      },
      {
        label: "Invoices",
        command: () => {
            navigate("/reports/invoice-report")
        },
      },
      {
        label: "Activity Log",
        command: () => {
            navigate("/reports/activity-log-report")
        },
      },
    ],
  },
//   {
//     label: "Logout",
//     icon: <LogoutOutlinedIcon />,
//     command: () => {
//         navigate("/logout")
//     },
//   },
];
return {
    ClientRoutes:ArrayData
  }
  }