import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { PierReviewSetup } from "components/Audit/PierReview";

export const PierReviewSetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Pier Review"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<PierReviewSetup testId="pier-review-setup" />}
    />
  );
};
