import { T4ASlipSetup } from "components/T4ASlip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT4ASlipSetupPage {}

export const T4ASlipSetupPage: FC<IT4ASlipSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Create T4A Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t4a/list" },
        { text: "T4A", navigateTo: "/taxslip/t4a/list" },
        { text: "Create T4A Slip", navigateTo: "/taxslip/t4a/setup" },
      ]}
      mainContents={<T4ASlipSetup testId="t4-slip-setup" />}
    />
  );
};
