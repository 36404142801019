import { GridSize } from "@mui/material";
import { DataType, FieldType } from "@websential/cosmic";
import { AxiosError, AxiosResponse } from "axios";
import { IFixedAndMetaField } from "common/FlexFieldsUtils";
import { INITIAL_PAGE_SIZE } from "common/constants";
import http from "common/http-common";
import { IGetListRequest, IResponse, IServerError } from "models/common";
import { IDropDownListResponse } from "models/common/IDropDownListResponse";
import {
  IResource,
  IResourceListModel,
  IResourceRequestPayload,
} from "models/resource";
import { EMPLOYEE_SETUP_FORM } from "screensLayoutData/employee";
import { T3_Beneficiary_SETUP_FORM_WITH_CORPORATION_AND_GOVERNMENT_RECIPIENT_TYPE, T3_Beneficiary_SETUP_FORM_WITH_INDIVIDUAL_RECIPIENT_TYPE, T3_Beneficiary_SETUP_FORM_WITH_TRUST_RECIPIENT_TYPE } from "screensLayoutData/t3Beneficiary";
import { T5_RECIPIENT_SETUP_FORM_WITH_CORPORATION_AND_GOVERNMENT_RECIPIENT_TYPE, T5_RECIPIENT_SETUP_FORM_WITH_INDIVIDUAL_RECIPIENT_TYPE, T5_RECIPIENT_SETUP_FORM_WITH_TRUST_RECIPIENT_TYPE } from "screensLayoutData/t5Recipient";
import { VENDOR_SETUP_FORM_WITH_BUSINESS_PAYEE_TYPE, VENDOR_SETUP_FORM_WITH_PERSONAL_PAYEE_TYPE } from "screensLayoutData/vendor";

// const employeeListResponse: IResourceListModel = {
//   page: 1,
//   size: 5,
//   total: 2,
//   items: [
//     {
//       id: "ab8ccece-16c1-44e9-ba86-2990eefb178c",
//       name1: "Babar Javaid",
//       uniqueIdentifier: "213-456-789",
//       socialInsuranceNumber: "000-000-000",
//       email: "babar@websential.ca",
//       province: "British Columbia Alberta",
//       status: "Active",
//     },
//     {
//       id: "ab8ccece-16c1-44e9-ba86-2990eefb178d",
//       name1: "Bilal Khan",
//       uniqueIdentifier: "123-456-789",
//       socialInsuranceNumber: "000-000-000",
//       email: "bilal@websential.ca",
//       province: "Alberta",
//       status: "Active",
//     },
//   ],
//   keyField: {
//     field: "id",
//     templateUrl: "/Employees/setup/{id}",
//   },
//   headerColumns: [
//     {
//       field: "name1",
//       headerName: "Employee",
//       description: "Employee Name",
//       flex: 1,
//       hide: false,
//       hideable: false,
//       sortable: true,
//       // "sortingOrder": "asc",
//       resizable: false,
//       type: "string",
//       align: "left",
//       headerAlign: "left",
//       hideSortIcons: false,
//       disableColumnMenu: true,
//       disableReorder: true,
//       disableExport: true,
//       maskable: false,
//       // "isFixed": true,
//       sequence: 0,
//     },
//     {
//       field: "uniqueIdentifier",
//       headerName: "Employee Code",
//       description: "Employee Code",
//       flex: 1,
//       hide: false,
//       hideable: false,
//       sortable: true,
//       // "sortingOrder": "asc",
//       resizable: false,
//       type: "string",
//       align: "left",
//       headerAlign: "left",
//       hideSortIcons: false,
//       disableColumnMenu: true,
//       disableReorder: true,
//       disableExport: true,
//       maskable: false,
//       // "isFixed": true,
//       sequence: 1,
//     },
//     {
//       field: "socialInsuranceNumber",
//       headerName: "Social Insurance No.",
//       description: "Social Insurance No.",
//       flex: 1,
//       hide: false,
//       hideable: false,
//       sortable: true,
//       // "sortingOrder": "asc",
//       resizable: false,
//       type: "string",
//       align: "left",
//       headerAlign: "left",
//       hideSortIcons: false,
//       disableColumnMenu: true,
//       disableReorder: true,
//       disableExport: true,
//       maskable: false,
//       // "isFixed": true,
//       sequence: 1,
//     },
//     {
//       field: "email",
//       headerName: "Email",
//       description: "Email",
//       flex: 1,
//       hide: false,
//       hideable: false,
//       sortable: true,
//       // "sortingOrder": "asc",
//       resizable: false,
//       type: "string",
//       align: "left",
//       headerAlign: "left",
//       hideSortIcons: false,
//       disableColumnMenu: true,
//       disableReorder: true,
//       disableExport: true,
//       maskable: false,
//       // "isFixed": true,
//       sequence: 2,
//     },
//     {
//       field: "province",
//       headerName: "Province",
//       description: "Province",
//       flex: 1,
//       hide: false,
//       hideable: false,
//       sortable: true,
//       // "sortingOrder": "asc",
//       resizable: false,
//       type: "string",
//       align: "left",
//       headerAlign: "left",
//       hideSortIcons: false,
//       disableColumnMenu: true,
//       disableReorder: true,
//       disableExport: true,
//       maskable: false,
//       // "isFixed": true,
//       sequence: 3,
//     },
//     {
//       field: "status",
//       headerName: "Status",
//       description: "Status",
//       flex: 1,
//       hide: false,
//       hideable: false,
//       sortable: true,
//       // "sortingOrder": "asc",
//       resizable: false,
//       type: "string",
//       align: "left",
//       headerAlign: "left",
//       hideSortIcons: false,
//       disableColumnMenu: true,
//       disableReorder: true,
//       disableExport: true,
//       maskable: false,
//       // "isFixed": true,
//       sequence: 4,
//     },
//   ],
// };

// const vendorListResponse: IResourceListModel = {
//   page: 1,
//   size: 5,
//   total: 2,
//   items: [
//     {
//       id: "ab8ccece-16c1-44e9-ba86-2990eefb178c",
//       name1: "Babar Javaid",
//       socialInsuranceNumber: "000-000-000",
//       businessNumber: "213-456-789",
//       email: "babar@websential.ca",
//       province: "British Columbia Alberta",
//       status: "Active",
//     },
//     {
//       id: "ab8ccece-16c1-44e9-ba86-2990eefb178d",
//       name1: "Bilal Khan",
//       socialInsuranceNumber: "000-000-000",
//       businessNumber: "123-456-789",
//       email: "bilal@websential.ca",
//       province: "Alberta",
//       status: "Active",
//     },
//   ],
//   keyField: {
//     field: "id",
//     templateUrl: "/Vendors/setup/{id}",
//   },
//   headerColumns: [
//     {
//       field: "name1",
//       headerName: "Vendor",
//       description: "Vendor",
//       flex: 1,
//       hide: false,
//       hideable: false,
//       sortable: true,
//       // "sortingOrder": "asc",
//       resizable: false,
//       type: "string",
//       align: "left",
//       headerAlign: "left",
//       hideSortIcons: false,
//       disableColumnMenu: true,
//       disableReorder: true,
//       disableExport: true,
//       maskable: false,
//       // "isFixed": true,
//       sequence: 0,
//     },
//     {
//       field: "socialInsuranceNumber",
//       headerName: "Social Insurance No.",
//       description: "Social Insurance No.",
//       flex: 1,
//       hide: false,
//       hideable: false,
//       sortable: true,
//       // "sortingOrder": "asc",
//       resizable: false,
//       type: "string",
//       align: "left",
//       headerAlign: "left",
//       hideSortIcons: false,
//       disableColumnMenu: true,
//       disableReorder: true,
//       disableExport: true,
//       maskable: false,
//       // "isFixed": true,
//       sequence: 1,
//     },
//     {
//       field: "businessNumber",
//       headerName: "Business Number",
//       description: "Business Number",
//       flex: 1,
//       hide: false,
//       hideable: false,
//       sortable: true,
//       // "sortingOrder": "asc",
//       resizable: false,
//       type: "string",
//       align: "left",
//       headerAlign: "left",
//       hideSortIcons: false,
//       disableColumnMenu: true,
//       disableReorder: true,
//       disableExport: true,
//       maskable: false,
//       // "isFixed": true,
//       sequence: 1,
//     },
//     {
//       field: "email",
//       headerName: "Email",
//       description: "Email",
//       flex: 1,
//       hide: false,
//       hideable: false,
//       sortable: true,
//       // "sortingOrder": "asc",
//       resizable: false,
//       type: "string",
//       align: "left",
//       headerAlign: "left",
//       hideSortIcons: false,
//       disableColumnMenu: true,
//       disableReorder: true,
//       disableExport: true,
//       maskable: false,
//       // "isFixed": true,
//       sequence: 2,
//     },
//     {
//       field: "province",
//       headerName: "Province",
//       description: "Province",
//       flex: 1,
//       hide: false,
//       hideable: false,
//       sortable: true,
//       // "sortingOrder": "asc",
//       resizable: false,
//       type: "string",
//       align: "left",
//       headerAlign: "left",
//       hideSortIcons: false,
//       disableColumnMenu: true,
//       disableReorder: true,
//       disableExport: true,
//       maskable: false,
//       // "isFixed": true,
//       sequence: 3,
//     },
//     {
//       field: "status",
//       headerName: "Status",
//       description: "Status",
//       flex: 1,
//       hide: false,
//       hideable: false,
//       sortable: true,
//       // "sortingOrder": "asc",
//       resizable: false,
//       type: "string",
//       align: "left",
//       headerAlign: "left",
//       hideSortIcons: false,
//       disableColumnMenu: true,
//       disableReorder: true,
//       disableExport: true,
//       maskable: false,
//       // "isFixed": true,
//       sequence: 4,
//     },
//   ],
// };
let clientIdNew = "";
// TODO: replace this with API response once it's ready
export const ResourceService = {
  getList: async (
    clientId: string,
    taxYear: number,
    resourceName: string,
    params: IGetListRequest
  ): Promise<IResourceListModel[] | IServerError | IResponse> => {
    const response: AxiosResponse<IResourceListModel[]> = await http.get<
      IResourceListModel[]
    >(`/v1/resources/${resourceName}?client_id=${clientId}&tax_year=${taxYear}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);

    if (response.status === 200) {
      clientIdNew = clientId;
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getMoveResourceList: async (
    clientId: string,
    taxYear: number,
    resourceName: string,
    params: IGetListRequest
  ): Promise<IResourceListModel[] | IServerError | IResponse> => {
    const response: AxiosResponse<IResourceListModel[]> = await http.get<
      IResourceListModel[]
    >(`/v1/resources/${taxYear}/get-resource-list-by-year/${resourceName}/?client_id=${clientId}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);

    if (response.status === 200) {
      clientIdNew = clientId;
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getDuplicateMergeList: async (
    clientId: string,
    taxYear: number,
    // params: IGetListRequest
  ): Promise<IResourceListModel[] | IServerError | IResponse> => {
    // const fixedResponse: any = {
    //   "page": 1,
    //   "size": 41,
    //   "total": 41,
    //   "items": [
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc78abf8fa7cc97c9a8424",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "ad089ef0-7d74-40d1-b272-da354a824d81",
    //         "firstName": "XML21",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888888881",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 0,
    //         "qppContributions": 3500,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": 60550,
    //         "boxBOtisationAuRRQ": 430,
    //         "boxCCotaIAssuranceEmploi": 2110,
    //         "boxEImpotDuQuebecRetenu": 0
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7d8dbd8d4bc8c0761565",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "ad089ef0-7d74-40d1-b272-da354a824d81",
    //         "firstName": "XML21",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888888881",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 0,
    //         "qppContributions": 3500,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": 60550,
    //         "boxBOtisationAuRRQ": 430,
    //         "boxCCotaIAssuranceEmploi": 2110,
    //         "boxEImpotDuQuebecRetenu": 0
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7f1bd30de84d2c1f1f75",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "ad089ef0-7d74-40d1-b272-da354a824d81",
    //         "firstName": "XML21",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888888881",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 0,
    //         "qppContributions": 3500,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": 60550,
    //         "boxBOtisationAuRRQ": 430,
    //         "boxCCotaIAssuranceEmploi": 2110,
    //         "boxEImpotDuQuebecRetenu": 0
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc81bb9014d92a6af3278d",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "ad089ef0-7d74-40d1-b272-da354a824d81",
    //         "firstName": "XML21",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888888881",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 0,
    //         "qppContributions": 3500,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": 60550,
    //         "boxBOtisationAuRRQ": 430,
    //         "boxCCotaIAssuranceEmploi": 2110,
    //         "boxEImpotDuQuebecRetenu": 0
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc78acf8fa7cc97c9a8428",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "6eaf7db9-f778-4da3-925a-0da99cac92c6",
    //         "firstName": "XML23",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc78acf8fa7cc97c9a842a",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc78adf8fa7cc97c9a842c",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "f6107206-ec79-4156-b28f-050b03b7dd3d",
    //         "firstName": "XML6",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc78adf8fa7cc97c9a842e",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "d0ad3b95-31ab-4019-904c-1ca6e12f9b5e",
    //         "firstName": "XML7",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7d8ebd8d4bc8c0761569",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "6eaf7db9-f778-4da3-925a-0da99cac92c6",
    //         "firstName": "XML23",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7d8fbd8d4bc8c076156b",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7d8fbd8d4bc8c076156d",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "f6107206-ec79-4156-b28f-050b03b7dd3d",
    //         "firstName": "XML6",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7d90bd8d4bc8c076156f",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "d0ad3b95-31ab-4019-904c-1ca6e12f9b5e",
    //         "firstName": "XML7",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7f1cd30de84d2c1f1f79",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "6eaf7db9-f778-4da3-925a-0da99cac92c6",
    //         "firstName": "XML23",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7f1dd30de84d2c1f1f7b",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7f1dd30de84d2c1f1f7d",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "f6107206-ec79-4156-b28f-050b03b7dd3d",
    //         "firstName": "XML6",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7f1ed30de84d2c1f1f7f",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "d0ad3b95-31ab-4019-904c-1ca6e12f9b5e",
    //         "firstName": "XML7",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc81bc9014d92a6af32791",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "6eaf7db9-f778-4da3-925a-0da99cac92c6",
    //         "firstName": "XML23",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc81bc9014d92a6af32793",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc81bd9014d92a6af32797",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "d0ad3b95-31ab-4019-904c-1ca6e12f9b5e",
    //         "firstName": "XML7",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e48ca0e35e5e472b408571",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e48ca0e35e5e472b408573",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "d0ad3b95-31ab-4019-904c-1ca6e12f9b5e",
    //         "firstName": "XML7",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e48e5afa4b1ac5610231e7",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e48e5afa4b1ac5610231e9",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "d0ad3b95-31ab-4019-904c-1ca6e12f9b5e",
    //         "firstName": "XML7",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e48fd43f73e809a4342191",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49685cdefc0644de4d661",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb0626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49dc49f28091ca89607c8",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49e2af4b295817d905f1a",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49eedf4b295817d905f1c",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e4a029f4b295817d905f1e",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e4a0e64136f039de412429",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb1626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb2626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb3626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb4626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb5626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb6626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb7626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb8626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb9626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb2126bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       }
    //     }
    //   ],
    //   "keyField": {
    //     "field": "id",
    //     "templateUrl": "/id"
    //   },
    //   "headerColumns": [
    //     {
    //       "field": "resource.firstName",
    //       "headerName": "Employee",
    //       "description": "Employee Name",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 0,
    //       "frozenColumn": true,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "resource.socialInsuranceNumber",
    //       "headerName": "SIN",
    //       "description": "SIN of Employee",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 1,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "resource.dateOfBirth",
    //       "headerName": "D.O.B ",
    //       "description": "date of birth",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "resource.address",
    //       "headerName": "Address ",
    //       "description": "address",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "resource.city",
    //       "headerName": "City ",
    //       "description": "city",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "resource.postCode",
    //       "headerName": "postCode ",
    //       "description": "postCode",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "resource.provinceOfEmployment.shortCode",
    //       "headerName": "Province ",
    //       "description": "Province Of Employment",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "t4SlipData.employmentIncome",
    //       "headerName": "Employment Income ",
    //       "description": "Employment Income ",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 150
    //     },
    //     {
    //       "field": "t4SlipData.cpp_contributions",
    //       "headerName": "CPP/QPP ",
    //       "description": "cpp qpp contributions",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "t4SlipData.eiPremiums",
    //       "headerName": "E.I ",
    //       "description": "ei Premiums",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "t4SlipData.incomeTaxDeducted",
    //       "headerName": "Income Tax Deducted",
    //       "description": "income Tax Deducted",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 250
    //     },
    //     {
    //       "field": "rl1SlipData.boxARevenusDEmploi",
    //       "headerName": "A-Revenus d'emploi",
    //       "description": "A-Revenus d'emploi",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 250
    //     },
    //     {
    //       "field": "rl1SlipData.boxBOtisationAuRRQ",
    //       "headerName": "B-Cotisation au emploi",
    //       "description": "B-Cotisation au emploi",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 250
    //     },
    //     {
    //       "field": "rl1SlipData.boxCCotaIAssuranceEmploi",
    //       "headerName": "C-Cot.a` Iassurance emploi",
    //       "description": "C-Cot.a` Iassurance emploi",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 250
    //     },
    //     {
    //       "field": "rl1SlipData.boxEImpotDuQuebecRetenu",
    //       "headerName": "E-Imo`t du Que`bec retenue",
    //       "description": "E-Imo`t du Que`bec retenue",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 250
    //     }
    //   ]
    // }
    // return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    const response: AxiosResponse<IResourceListModel[]> = await http.get<
      IResourceListModel[]
    >(`/v1/t4-duplicate-merging/merging-duplicate-list/?client_id=${clientId}&tax_year=${taxYear}`);

    if (response.status === 200) {
      clientIdNew = clientId;
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getUndoMergeList: async (
    clientId: string,
    taxYear: number,
    // params: IGetListRequest
  ): Promise<any | IServerError | IResponse> => {
    // const fixedResponse: any = {
    //   "page": 1,
    //   "size": 41,
    //   "total": 41,
    //   "items": [
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc78abf8fa7cc97c9a8424",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "ad089ef0-7d74-40d1-b272-da354a824d81",
    //         "firstName": "XML21",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888888881",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 0,
    //         "qppContributions": 3500,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": 60550,
    //         "boxBOtisationAuRRQ": 430,
    //         "boxCCotaIAssuranceEmploi": 2110,
    //         "boxEImpotDuQuebecRetenu": 0
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e35"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7d8dbd8d4bc8c0761565",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "ad089ef0-7d74-40d1-b272-da354a824d81",
    //         "firstName": "XML21",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888888881",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 0,
    //         "qppContributions": 3500,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": 60550,
    //         "boxBOtisationAuRRQ": 430,
    //         "boxCCotaIAssuranceEmploi": 2110,
    //         "boxEImpotDuQuebecRetenu": 0
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e35"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7f1bd30de84d2c1f1f75",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "ad089ef0-7d74-40d1-b272-da354a824d81",
    //         "firstName": "XML21",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888888881",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 0,
    //         "qppContributions": 3500,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": 60550,
    //         "boxBOtisationAuRRQ": 430,
    //         "boxCCotaIAssuranceEmploi": 2110,
    //         "boxEImpotDuQuebecRetenu": 0
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e35"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc81bb9014d92a6af3278d",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "ad089ef0-7d74-40d1-b272-da354a824d81",
    //         "firstName": "XML21",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888888881",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 0,
    //         "qppContributions": 3500,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": 60550,
    //         "boxBOtisationAuRRQ": 430,
    //         "boxCCotaIAssuranceEmploi": 2110,
    //         "boxEImpotDuQuebecRetenu": 0
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e35"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc78acf8fa7cc97c9a8428",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "6eaf7db9-f778-4da3-925a-0da99cac92c6",
    //         "firstName": "XML23",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc78acf8fa7cc97c9a842a",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc78adf8fa7cc97c9a842c",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "f6107206-ec79-4156-b28f-050b03b7dd3d",
    //         "firstName": "XML6",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc78adf8fa7cc97c9a842e",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "d0ad3b95-31ab-4019-904c-1ca6e12f9b5e",
    //         "firstName": "XML7",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7d8ebd8d4bc8c0761569",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "6eaf7db9-f778-4da3-925a-0da99cac92c6",
    //         "firstName": "XML23",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7d8fbd8d4bc8c076156b",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7d8fbd8d4bc8c076156d",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "f6107206-ec79-4156-b28f-050b03b7dd3d",
    //         "firstName": "XML6",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7d90bd8d4bc8c076156f",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "d0ad3b95-31ab-4019-904c-1ca6e12f9b5e",
    //         "firstName": "XML7",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7f1cd30de84d2c1f1f79",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "6eaf7db9-f778-4da3-925a-0da99cac92c6",
    //         "firstName": "XML23",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7f1dd30de84d2c1f1f7b",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7f1dd30de84d2c1f1f7d",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "f6107206-ec79-4156-b28f-050b03b7dd3d",
    //         "firstName": "XML6",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc7f1ed30de84d2c1f1f7f",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "d0ad3b95-31ab-4019-904c-1ca6e12f9b5e",
    //         "firstName": "XML7",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc81bc9014d92a6af32791",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "6eaf7db9-f778-4da3-925a-0da99cac92c6",
    //         "firstName": "XML23",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc81bc9014d92a6af32793",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64dc81bd9014d92a6af32797",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "d0ad3b95-31ab-4019-904c-1ca6e12f9b5e",
    //         "firstName": "XML7",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e48ca0e35e5e472b408571",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e48ca0e35e5e472b408573",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "d0ad3b95-31ab-4019-904c-1ca6e12f9b5e",
    //         "firstName": "XML7",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e48e5afa4b1ac5610231e7",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e48e5afa4b1ac5610231e9",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "d0ad3b95-31ab-4019-904c-1ca6e12f9b5e",
    //         "firstName": "XML7",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e48fd43f73e809a4342191",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49685cdefc0644de4d661",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb0626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49dc49f28091ca89607c8",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49e2af4b295817d905f1a",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49eedf4b295817d905f1c",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e4a029f4b295817d905f1e",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e4a0e64136f039de412429",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb1626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb2626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb3626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb4626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb5626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb6626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb7626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb8626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb9626bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     },
    //     {
    //       "taxYear": 2023,
    //       "id": "64e49cfb2126bdc687d6fc03",
    //       "client": {
    //         "externalId": "dc7a2c43-7b0e-4b0b-98ef-546dbf5e814e"
    //       },
    //       "resource": {
    //         "externalId": "64b43ff5-18be-4d20-b6a5-41c625799ec0",
    //         "firstName": "XML24",
    //         "initials": "M",
    //         "lastName": "Employee",
    //         "dateOfBirth": null,
    //         "socialInsuranceNumber": "888999888",
    //         "address": "Street 12345",
    //         "city": "Toronto",
    //         "country": {
    //           "id": 1,
    //           "name": "Canada",
    //           "shortCode": "CAN"
    //         },
    //         "postCode": "A0A0A0",
    //         "province": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "provinceOfEmployment": {
    //           "id": 1,
    //           "name": "Ontario",
    //           "shortCode": "ON"
    //         },
    //         "email": null,
    //         "employeeCode": 1001,
    //         "cppQppExempt": false,
    //         "eiExempt": false,
    //         "ppipExempt": false,
    //         "employmentCode": 11,
    //         "payPeriod": 26,
    //         "reducedEi": false
    //       },
    //       "t4SlipData": {
    //         "employmentIncome": 60000,
    //         "cppContributions": 3500,
    //         "qppContributions": 0,
    //         "eiPremiums": 3100,
    //         "incomeTaxDeducted": 3000
    //       },
    //       "rl1SlipData": {
    //         "boxARevenusDEmploi": "N/A",
    //         "boxBOtisationAuRRQ": "N/A",
    //         "boxCCotaIAssuranceEmploi": "N/A",
    //         "boxEImpotDuQuebecRetenu": "N/A"
    //       },
    //       "finalSlipId": "64f02f8dd50fea3518d66e21"
    //     }
    //   ],
    //   "keyField": {
    //     "field": "id",
    //     "templateUrl": "/id"
    //   },
    //   "headerColumns": [
    //     {
    //       "field": "resource.firstName",
    //       "headerName": "Employee",
    //       "description": "Employee Name",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 0,
    //       "frozenColumn": true,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "resource.socialInsuranceNumber",
    //       "headerName": "SIN",
    //       "description": "SIN of Employee",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 1,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "resource.dateOfBirth",
    //       "headerName": "D.O.B ",
    //       "description": "date of birth",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "resource.address",
    //       "headerName": "Address ",
    //       "description": "address",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "resource.city",
    //       "headerName": "City ",
    //       "description": "city",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "resource.postCode",
    //       "headerName": "postCode ",
    //       "description": "postCode",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "resource.provinceOfEmployment.shortCode",
    //       "headerName": "Province ",
    //       "description": "Province Of Employment",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "t4SlipData.employmentIncome",
    //       "headerName": "Employment Income ",
    //       "description": "Employment Income ",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 150
    //     },
    //     {
    //       "field": "t4SlipData.cpp_contributions",
    //       "headerName": "CPP/QPP ",
    //       "description": "cpp qpp contributions",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "t4SlipData.eiPremiums",
    //       "headerName": "E.I ",
    //       "description": "ei Premiums",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 100
    //     },
    //     {
    //       "field": "t4SlipData.incomeTaxDeducted",
    //       "headerName": "Income Tax Deducted",
    //       "description": "income Tax Deducted",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 250
    //     },
    //     {
    //       "field": "rl1SlipData.boxARevenusDEmploi",
    //       "headerName": "A-Revenus d'emploi",
    //       "description": "A-Revenus d'emploi",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 250
    //     },
    //     {
    //       "field": "rl1SlipData.boxBOtisationAuRRQ",
    //       "headerName": "B-Cotisation au emploi",
    //       "description": "B-Cotisation au emploi",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 250
    //     },
    //     {
    //       "field": "rl1SlipData.boxCCotaIAssuranceEmploi",
    //       "headerName": "C-Cot.a` Iassurance emploi",
    //       "description": "C-Cot.a` Iassurance emploi",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 250
    //     },
    //     {
    //       "field": "rl1SlipData.boxEImpotDuQuebecRetenu",
    //       "headerName": "E-Imo`t du Que`bec retenue",
    //       "description": "E-Imo`t du Que`bec retenue",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "text",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null,
    //       "minWidth": 250
    //     }
    //   ]
    // }
    // return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    const response: AxiosResponse<any> = await http.get<any>(`/v1/t4-duplicate-merging/unmerging-duplicate-list/?client_id=${clientId}&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  mergeSelectedResource: async (
    clientId: string,
    taxYear: number,
    mergingIds:any,
    finalSlipId:string,
    finalSlipResourceData:object,
    // params: IGetListRequest
  ): Promise<any | IServerError | IResponse> => {

    try {
      const response: AxiosResponse<any> = await http.post<any>(
        `/v1/t4-duplicate-merging/duplicate-merge/?client_id=${clientId}&final_slip_id=${finalSlipId}&${mergingIds}&ignore_true_merge_false=false&tax_year=${taxYear}`,finalSlipResourceData);

        if (response.status === 201) {
          return { success: true, data: "Record has been Merged Successfully!" };
        } else {
          console.log("Response status:", response.status);
          return { success: false, data: response.data };
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.error("An error occurred:", axiosError);
        return { success: false, data: axiosError.response ? axiosError.response.data : "Unknown error" };
      }
  },
  ignoreMergedSelectedResource: async (
    clientId: string,
    taxYear: number,
    mergingIds:any,
    finalSlipId:string,
    finalSlipResourceData:object,
    // params: IGetListRequest
  ): Promise<any | IServerError | IResponse> => {

    try {
      const response: AxiosResponse<any> = await http.post<any>(
        `/v1/t4-duplicate-merging/duplicate-merge/?client_id=${clientId}&final_slip_id=${finalSlipId}&${mergingIds}&ignore_true_merge_false=true&tax_year=${taxYear}`,finalSlipResourceData);

        if (response.status === 201) {
          return { success: true, data: "Record has been Merged Successfully!" };
        } else {
          console.log("Response status:", response.status);
          return { success: false, data: response.data };
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.error("An error occurred:", axiosError);
        return { success: false, data: axiosError.response ? axiosError.response.data : "Unknown error" };
      }
  },

  unMergeSelectedResource: async (
    clientId: string,
    taxYear: number,
    mergingIds:any,
    // finalSlipId:string,
    // finalSlipResourceData:object,
    // params: IGetListRequest
  ): Promise<any | IServerError | IResponse> => {

    try {
      const response: AxiosResponse<any> = await http.get<any>(
        `/v1/t4-duplicate-merging/duplicate-unmerge/?client_id=${clientId}&${mergingIds}&tax_year=${taxYear}`);

        if (response.status === 200) {
          return { success: true, data: "Record has been Merged Successfully!" };
        } else {
          console.log("Response status:", response.status);
          return { success: false, data: response.data };
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.error("An error occurred:", axiosError);
        return { success: false, data: axiosError.response ? axiosError.response.data : "Unknown error" };
      }
  },


  delResource: async (
    payload: any
  ): Promise<string | IServerError | IResponse> => {
    // console.log("deleteById", resourceId);
    if(payload.option === "Delete By Year"){
      const response: AxiosResponse<string> = await http.delete<string>(
        `/v1/resources/by-year/${payload.resourceId}/?tax_year=${payload.taxYear}&client_id=${payload.clientId}&resource_role_name=${payload.roleName}`
      );
  
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 204) {
        return "Resource has been deleted successfully";
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    } else if(payload.option === "Permanent Delete"){
      const response: AxiosResponse<string> = await http.delete<string>(
        `/v1/resources/${payload.resourceId}/?client_id=${payload.clientId}&resource_role_name=${payload.roleName}`
      );
  
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 204) {
        return "Resource has been deleted successfully";
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    } else {
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }
  },

  delBulkResources: async (
    requestPayload: any,
    // delteOptions:string
  ): Promise<string | IServerError | IResponse> => {
    console.log("requestPayload55", requestPayload);
    // console.log("delteOptions",delteOptions);
    if(requestPayload.options === "Delete By Year"){
      let loginDetail: any = {
        resourceIds: requestPayload.ids,
        // "resourceIds": ["f11fcad6-f51b-4858-8b75-d0a4a7fa2fb7"]
      };
      console.log("loginDetail", loginDetail);
  
      // const response = await http.delete<string>(`/v1/resources/client_id=${clientIdNew}`, loginDetail);
      const response = await http.delete<string>(
        `/v1/resources/bulk_delete-by-year/${requestPayload.taxYear}/?client_id=${requestPayload.clientId}&resource_role_name=${requestPayload.roleName}`,
        { data: loginDetail }
      );
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      console.log("loginDetail", loginDetail);
      console.log("REsponse ?????????????????????????????????????", response);
  
      return {
        status: false,
        message:  "Something went wrong please try again later.",
      };
    } else if(requestPayload.options === "Permanent Delete"){
      let loginDetail: any = {
        resourceIds: requestPayload.ids,
        // "resourceIds": ["f11fcad6-f51b-4858-8b75-d0a4a7fa2fb7"]
      };
      console.log("loginDetail", loginDetail);
  
      // const response = await http.delete<string>(`/v1/resources/client_id=${clientIdNew}`, loginDetail);
      const response = await http.delete<string>(
        `/v1/resources/?client_id=${requestPayload.clientId}&resource_role_name=${requestPayload.roleName}`,
        { data: loginDetail }
      );
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      console.log("loginDetail", loginDetail);
      console.log("REsponse ?????????????????????????????????????", response);
  
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    } else {
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }

    
  },

  getSetupLayout: async (resourceName: string): Promise<any> => {
    if (resourceName.toLowerCase() === "employee") {
      return new Promise((resolve) =>
        setTimeout(() => resolve(EMPLOYEE_SETUP_FORM), 1000)
      );
    }

    if (resourceName.toLowerCase() === "vendor") {
      return new Promise((resolve) =>
        setTimeout(() => resolve(VENDOR_SETUP_FORM_WITH_PERSONAL_PAYEE_TYPE), 1000)
      );
    }

    return new Promise((resolve) => setTimeout(() => resolve([]), 1000));
  },

  getEmployeeSetupLayout: async (): Promise<any> => {
    let response: any = EMPLOYEE_SETUP_FORM;
    return new Promise(resolve => setTimeout(() => resolve(response), 1000));
  },
  getVendorSetupLayout: async (payeeType:string): Promise<any> => {
    console.log("inside--",payeeType)
    console.log("inside--typeof(payeeType)",typeof(payeeType))
    if(payeeType === "true"){
      console.log("iffffffffffffff--")
      let response: any = VENDOR_SETUP_FORM_WITH_PERSONAL_PAYEE_TYPE;
      console.log("response",response)
      return new Promise(resolve => setTimeout(() => resolve(response), 1000));
    } else if(payeeType === "false"){
      console.log("elseeeeeeeeeeee--")
      let response: any = VENDOR_SETUP_FORM_WITH_BUSINESS_PAYEE_TYPE;
      console.log("response",response)
      return new Promise(resolve => setTimeout(() => resolve(response), 1000));
    }
    // let response: any = VENDOR_SETUP_FORM;
    // return new Promise(resolve => setTimeout(() => resolve(response), 1000));
  },
  getT5RecipientSetupLayout: async (recipientType:number): Promise<any> => {
    console.log("inside--",recipientType)
    console.log("inside--typeof(recipientType)",typeof(recipientType))
    if(recipientType === 10){
      console.log("iffffffffffffff--")
      let response: any = T5_RECIPIENT_SETUP_FORM_WITH_INDIVIDUAL_RECIPIENT_TYPE;
      console.log("response1211",response)
      return new Promise(resolve => setTimeout(() => resolve(response), 1000));
    } else if(recipientType === 30 || recipientType === 50){
      console.log("elseeeeeeeeeeee--")
      let response: any = T5_RECIPIENT_SETUP_FORM_WITH_CORPORATION_AND_GOVERNMENT_RECIPIENT_TYPE;
      console.log("response1212",response)
      return new Promise(resolve => setTimeout(() => resolve(response), 1000));
    } else if(recipientType === 40){
      console.log("elseeeeeeeeeeee--")
      let response: any = T5_RECIPIENT_SETUP_FORM_WITH_TRUST_RECIPIENT_TYPE;
      console.log("response1213",response)
      return new Promise(resolve => setTimeout(() => resolve(response), 1000));
    }
    // let response: any = VENDOR_SETUP_FORM;
    // return new Promise(resolve => setTimeout(() => resolve(response), 1000));
  },
  getT3BeneficiarySetupLayout: async (recipientType:number): Promise<any> => {
    console.log("inside--",recipientType)
    console.log("inside--typeof(recipientType)",typeof(recipientType))
    if(recipientType === 10){
      console.log("iffffffffffffff--")
      let response: any = T3_Beneficiary_SETUP_FORM_WITH_INDIVIDUAL_RECIPIENT_TYPE;
      console.log("response1211",response)
      return new Promise(resolve => setTimeout(() => resolve(response), 1000));
    } else if(recipientType === 30 || recipientType === 50){
      console.log("elseeeeeeeeeeee--")
      let response: any = T3_Beneficiary_SETUP_FORM_WITH_CORPORATION_AND_GOVERNMENT_RECIPIENT_TYPE;
      console.log("response1212",response)
      return new Promise(resolve => setTimeout(() => resolve(response), 1000));
    } else if(recipientType === 40){
      console.log("elseeeeeeeeeeee--")
      let response: any = T3_Beneficiary_SETUP_FORM_WITH_TRUST_RECIPIENT_TYPE;
      console.log("response1213",response)
      return new Promise(resolve => setTimeout(() => resolve(response), 1000));
    }
    // let response: any = VENDOR_SETUP_FORM;
    // return new Promise(resolve => setTimeout(() => resolve(response), 1000));
  },
  getById: async (
    id: string | undefined,
    dataRoleName: string,
    clientId: string
  ): Promise<IResource | IServerError | IResponse | null | any> => {
    if (!id) return null;
    const response: AxiosResponse<IResource> = await http.get<IResource>(
      `/v1/resources/${dataRoleName}/${id}/?client_id=${clientId}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  create: async (
    requestPayload: IResourceRequestPayload | any
  ): Promise<IResource | IServerError | IResponse | any> => {
    if (requestPayload.id) {
      console.log("requestPayload ==>> update request", requestPayload);
      const response: AxiosResponse<IResource> = await http.put<IResource>(
        `/v1/resources/${requestPayload.dataRoleName}/${requestPayload.id}/`,
        requestPayload
      );
      if (response.status === 200) {
        return response;
      } else if (response.status === 400) {
        return response;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }

    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<IResource> = await http.post<IResource>(
      `/v1/resources/${requestPayload.dataRoleName}?tax_year=${requestPayload.taxYear}`,
      requestPayload
    );

    if (response.status === 201) {
      console.log("response.data",response.data)
      return response;
    } else if (response.status === 400) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getDropDownList: async (
    clientId: string,
    resourceName: string
  ): Promise<IDropDownListResponse[] | IServerError | IResponse> => {
    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<
      IDropDownListResponse[]
    >(`/v1/resources/ddl-list/${resourceName}/?client_id=${clientId}`);

    if (response.status === 200) {
      return response.data;
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  resourcesRetrieveData: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    // console.log("requestPayload", typeof requestPayload);
    console.log("requestPayload",requestPayload);

    // let loginDetail: any = {
    //   resourceIds: requestPayload,
    //   // "resourceIds": ["f11fcad6-f51b-4858-8b75-d0a4a7fa2fb7"]
    // };
    // console.log("loginDetail", loginDetail);
    // const headers={'Content-Type': requestPayload.file[0].type}
    // const response = await http.delete<string>(`/v1/resources/client_id=${clientIdNew}`, loginDetail);
    const response = await http.post<string>(
      `/v1/resources/import-csv/?client_id=${requestPayload.id}&resource_role_name=${requestPayload.resourceName}`,
      requestPayload.formData
    );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    // console.log("loginDetail", loginDetail);
    console.log("REsponse ?????????????????????????????????????", response);

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getResourceCategories: async (
    screenId: string | number,
    resourceName:string | undefined
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/flex-fields/screen-fields?screen_name=${screenId}`
      );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
    // const fixedResponse: any = {
    //   page: 1,
    //   size: INITIAL_PAGE_SIZE,
    //   total: 2,
    //   items: [
    //     // {
    //     //   fieldLabel: "Province",
    //     //   fieldId: "provinceId",
    //     //   fieldType: FieldType.DropDownList,
    //     //   placeholder: "",
    //     //   fieldValue: "Alberta",
    //     //   fieldInternalValue: "alberta",
    //     //   // fieldValue: "Alberta,British Columbia,Manitoba",
    //     //   // fieldInternalValue: "alberta,british columbia,manitoba",
    //     //   id: 11,
    //     //   dataType: DataType.Object,
    //     //   fieldFormat: "",
    //     //   defaultValue: "",
    //     //   labelPosition: "left",
    //     //   labelColumn: 4 as GridSize,
    //     //   fieldColumn: 8 as GridSize,
    //     //   notes: "",
    //     //   minValue: "",
    //     //   maxValue: "",
    //     //   parent: null,
    //     //   parentValue: "",
    //     //   parentRelation: null,
    //     //   isFixed: true,
    //     //   isRequired: true,
    //     //   isCategory: true,
    //     //   isHide: false,
    //     //   readOnly: false,
    //     //   displayMasked: false,
    //     //   uppercase: false,

    //     //   fieldClass: "",
    //     //   validationFormula: "",
    //     //   comparison: [],
    //     //   employeeAccess: 0,
    //     //   variableValue: null,
    //     //   systemField: false,
    //     //   apiPath: null,
    //     //   defualtCategory: false,
    //     //   sequence: 1,
    //     // },
    //     // {
    //     //   fieldLabel: "Province",
    //     //   fieldId: "provinceId",
    //     //   fieldType: FieldType.DropDownList,
    //     //   placeholder: "",
    //     //   fieldValue: "Alberta",
    //     //   fieldInternalValue: "alberta",
    //     //   // fieldValue: "Alberta,British Columbia,Manitoba",
    //     //   // fieldInternalValue: "alberta,british columbia,manitoba",
    //     //   id: 12,
    //     //   dataType: DataType.Object,
    //     //   fieldFormat: "",
    //     //   defaultValue: "",
    //     //   labelPosition: "left",
    //     //   labelColumn: 4 as GridSize,
    //     //   fieldColumn: 8 as GridSize,
    //     //   notes: "",
    //     //   minValue: "",
    //     //   maxValue: "",
    //     //   parent: null,
    //     //   parentValue: "",
    //     //   parentRelation: null,
    //     //   isFixed: true,
    //     //   isRequired: true,
    //     //   isCategory: true,
    //     //   isHide: false,
    //     //   readOnly: false,
    //     //   displayMasked: false,
    //     //   uppercase: false,

    //     //   fieldClass: "",
    //     //   validationFormula: "",
    //     //   comparison: [],
    //     //   employeeAccess: 0,
    //     //   variableValue: null,
    //     //   systemField: false,
    //     //   apiPath: null,
    //     //   defualtCategory: false,
    //     //   sequence: 1,
    //     // },
    //     // {
    //     //   id: 5,
    //     //   fieldLabel: "Country",
    //     //   fieldId: "countryId",
    //     //   fieldType: FieldType.DropDownList,
    //     //   placeholder: "",
    //     //   fieldValue: "Canada",
    //     //   fieldInternalValue: "1",
    //     //   dataType: DataType.Object,
    //     //   fieldFormat: "",
    //     //   defaultValue: "1",
    //     //   labelPosition: "left",
    //     //   labelColumn: 4 as GridSize,
    //     //   fieldColumn: 8 as GridSize,
    //     //   notes: "",
    //     //   minValue: "",
    //     //   maxValue: "",
    //     //   parent: null,
    //     //   parentValue: "",
    //     //   parentRelation: null,
    //     //   isFixed: true,
    //     //   isRequired: true,
    //     //   isCategory: true,
    //     //   isHide: false,
    //     //   readOnly: false,
    //     //   displayMasked: false,
    //     //   uppercase: false,

    //     //   fieldClass: "",
    //     //   validationFormula: "",
    //     //   comparison: [],
    //     //   employeeAccess: 0,
    //     //   variableValue: null,
    //     //   systemField: false,
    //     //   apiPath: null,
    //     //   defualtCategory: true,
    //     //   sequence: 3,
    //     // },
    //     // {
    //     //   id: 6,
    //     //   fieldLabel: "Country",
    //     //   fieldId: "countryId",
    //     //   fieldType: FieldType.DropDownList,
    //     //   placeholder: "",
    //     //   fieldValue: "Canada",
    //     //   fieldInternalValue: "1",
    //     //   dataType: DataType.Object,
    //     //   fieldFormat: "",
    //     //   defaultValue: "1",
    //     //   labelPosition: "left",
    //     //   labelColumn: 4 as GridSize,
    //     //   fieldColumn: 8 as GridSize,
    //     //   notes: "",
    //     //   minValue: "",
    //     //   maxValue: "",
    //     //   parent: null,
    //     //   parentValue: "",
    //     //   parentRelation: null,
    //     //   isFixed: true,
    //     //   isRequired: true,
    //     //   isCategory: true,
    //     //   isHide: false,
    //     //   readOnly: false,
    //     //   displayMasked: false,
    //     //   uppercase: false,

    //     //   fieldClass: "",
    //     //   validationFormula: "",
    //     //   comparison: [],
    //     //   employeeAccess: 0,
    //     //   variableValue: null,
    //     //   systemField: false,
    //     //   apiPath: null,
    //     //   defualtCategory: false,
    //     //   sequence: 3,
    //     // }
    //   ],
    //   keyField: {
    //     field: "id",
    //     templateUrl: "/data-roles/setup/id",
    //   },
    //   headerColumns: [
    //     {
    //       field: "roleName",
    //       headerName: "Data Role Name",
    //       description: "Data role name",
    //       flex: 1,
    //       hide: false,
    //       hideable: false,
    //       sortable: true,
    //       // "sortingOrder": "asc",
    //       resizable: false,
    //       type: "string",
    //       align: "left",
    //       headerAlign: "left",
    //       hideSortIcons: false,
    //       disableColumnMenu: true,
    //       disableReorder: true,
    //       disableExport: true,
    //       maskable: false,
    //       // "isFixed": true,
    //       sequence: 0,
    //     },
    //   ],
    // };
    // return new Promise((resolve) =>
    //   setTimeout(() => resolve(fixedResponse), 1000)
    // );
  },
  getResourceCategoriesAssingedPanels: async (
    requestPayload:any
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse =
      await http.post(
        `/v1/flex-field-fieldsets/panel-flex-fields`,
        requestPayload
      );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
    // console.log(`inside requestPayload = ${requestPayload},,,,, screenId ${requestPayload.screenId} , categoryId ${requestPayload.categoryId}, categoryValue ${requestPayload.categoryValue}`)
    // if (requestPayload[0].categoryId !== "" && requestPayload[0].categoryValue === "alberta") {
    //   const fixedResponse: any = {
    //     panels: [
    //       {
    //         label: "Create Client",
    //         categoryFieldId: "",
    //         categoryFieldValue: "",
    //         sequence: 1,
    //         gridColumns: 11 as GridSize,
    //         fields: [
    //           {
    //             id: 1,
    //             fieldLabel: "Registration no.",
    //             fieldId: "clientIdentityNo",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Registration no.",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 1,
    //           },
    //           {
    //             id: 2,
    //             fieldLabel: "Legal Name",
    //             fieldId: "clientName",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Legal Name",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 2,
    //           },
    //           {
    //             id: 3,
    //             fieldLabel: "Operating Name",
    //             fieldId: "operatingName",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Operating Name",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 3,
    //           },
    //           {
    //             id: 4,
    //             fieldLabel: "Creation Date",
    //             fieldId: "createdOn",
    //             fieldType: FieldType.Date,
    //             placeholder: "Current Date",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 4,
    //           },
    //           {
    //             id: 5,
    //             fieldLabel: "Employees",
    //             fieldId: "employees",
    //             fieldType: FieldType.DropDownList,
    //             placeholder: "",
    //             fieldValue:
    //               "1-10,11-25,26-50,51-100,101-200,201-300,301-500,501-1000,1001-2500,2500+",
    //             fieldInternalValue: "1,11,26,51,101,201,301,501,1001,2501",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "26",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 5,
    //           },
    //           {
    //             id: 6,
    //             fieldLabel: "Account Manager",
    //             fieldId: "accountManager",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Account Manager",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 6,
    //           },
    //           {
    //             id: 7,
    //             fieldLabel: "Business no.",
    //             fieldId: "businessNo",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "999999999RP9999",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{000000000RP0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: true,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 7,
    //           },
    //           {
    //             id: 8,
    //             fieldLabel: "Reduce El Account no.",
    //             fieldId: "reducedBusinessNo",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "000000000AA0000",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{000000000AA0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: true,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 8,
    //           },
    //           {
    //             id: 9,
    //             fieldLabel: "Access Code",
    //             fieldId: "accessCode",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Access Code",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 9,
    //           },
    //           {
    //             id: 10,
    //             fieldLabel: "Payment Method",
    //             fieldId: "payment_method",
    //             fieldType: FieldType.DropDownList,
    //             placeholder: "",
    //             fieldValue: "paypal,Direct method",
    //             fieldInternalValue: "20,10",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 10,
    //           },
    //         ],
    //       },
    //       {
    //         label: "Address Info",
    //         categoryFieldId: "",
    //         categoryFieldValue: "",
    //         sequence: 2,
    //         gridColumns: 12 as GridSize,
    //         fields: [
    //           {
    //             id: 10,
    //             fieldLabel: "Address",
    //             fieldId: "addressline1",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Enter Address",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 1,
    //           },
    //           {
    //             id: 11,
    //             fieldLabel: "City",
    //             fieldId: "city",
    //             fieldType: FieldType.TextField,
    //             placeholder: "City",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 2,
    //           },
    //           {
    //             id: 12,
    //             fieldLabel: "Province",
    //             fieldId: "provinceId",
    //             fieldType: FieldType.DropDownList,
    //             placeholder: "",
    //             fieldValue: "Alberta,British Columbia,Manitoba",
    //             fieldInternalValue: "1,2,3",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 3,
    //           },
    //           {
    //             id: 12,
    //             fieldLabel: "Operating in Provinces",
    //             fieldId: "operatingInProvinces",
    //             fieldType: FieldType.MultiSelectionDropDownList,
    //             placeholder: "",
    //             fieldValue: "Alberta,British Columbia,Manitoba",
    //             fieldInternalValue: "1,2,3",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 4,
    //           },
    //           {
    //             id: 5,
    //             fieldLabel: "Country",
    //             fieldId: "countryId",
    //             fieldType: FieldType.DropDownList,
    //             placeholder: "",
    //             fieldValue: "Canada",
    //             fieldInternalValue: "1",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "1",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: true,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 5,
    //           },
    //           {
    //             id: 8,
    //             fieldLabel: "Post Code",
    //             fieldId: "postal_code",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "A9A9A9",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{a0a0a0}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: true,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 6,
    //           },
    //           {
    //             id: 4,
    //             fieldLabel: "Email",
    //             fieldId: "email",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Email",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 7,
    //           },
    //           {
    //             id: 10,
    //             fieldLabel: "Phone (Home)",
    //             fieldId: "phone1",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "(999) 999-9999",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{(000) 000-0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 8 as GridSize,
    //             fieldColumn: 8 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 8,
    //           },
    //           {
    //             id: 11,
    //             fieldLabel: "Ext",
    //             fieldId: "ext1",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "000",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 4 as GridSize,
    //             fieldColumn: 4 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 9,
    //           },
    //           {
    //             id: 10,
    //             fieldLabel: "Phone (Personal)",
    //             fieldId: "phone2",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "(999) 999-9999",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{(000) 000-0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 8 as GridSize,
    //             fieldColumn: 8 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 10,
    //           },
    //           {
    //             id: 11,
    //             fieldLabel: "Ext",
    //             fieldId: "ext2",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "000",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 4 as GridSize,
    //             fieldColumn: 4 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 11,
    //           },
    //           {
    //             id: 10,
    //             fieldLabel: "Fax",
    //             fieldId: "fax",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "(999) 999-9999",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{(000) 000-0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 12,
    //           },
    //           {
    //             id: 12,
    //             fieldLabel: "Website",
    //             fieldId: "website",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Website",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 13,
    //           },
    //         ],
    //       },
    //     ],
    //   };
    //   return new Promise((resolve) =>
    //     setTimeout(() => resolve(fixedResponse), 1000)
    //   );
    // }
  },
  moveResource: async (
    requestPayload: any
  ): Promise<IServerError | IResponse | any> => {
    // if (requestPayload.id) {
    //   console.log("requestPayload ==>> update request", requestPayload);
    //   const response: AxiosResponse = await http.put(
    //     `/v1/resources/${requestPayload.dataRoleName}/${requestPayload.id}/`,
    //     requestPayload
    //   );
    //   if (response.status === 200) {
    //     return response;
    //   } else if (response.status === 400) {
    //     return response;
    //   } else if (response.status === 500) {
    //     return {
    //       status: false,
    //       message: "Something went wrong.",
    //       error: true,
    //     };
    //   }
    // }

    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<IResource> = await http.post<IResource>(
      `/v1/resources/move_resources/`,
      requestPayload
    );

    if (response.status === 201) {
      console.log("response.data",response.data)
      return response;
    } else if (response.status === 400) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
};
