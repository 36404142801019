import { Grid, Link, Alert } from "@mui/material";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      backgroundColor: "#FFFFFF",
      fontWeight: "bold",
      fontSize: "16px",
      margin: "0 10px 0 10px",
      cursor: "pointer",
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 12px 4px",
    },
  })
);

export interface IGuidPanelList {
  items: {
    title: string;
    color: string;
    navigateTo: string;
  }[];
}
export const GuidPanelList = (props: IGuidPanelList) => {
  const classes = useStyles();
  console.log("guidPanels", props);
  return (
    <Grid container spacing={2.5} sx={{marginTop:"1.5rem",marginBottom:"20px"}}>
      {props.items.map((x, index) => {
        return (
          <Grid item xs={12} sm={12} lg={3} xl={3}>
            <Link href={x.navigateTo} underline="none" target={'_blank'} key={index} sx={{}}>
              <Alert
                variant="filled"
                severity="info"
                sx={{
                  borderLeft: `5px solid ${x.color}`,
                  color: x.color,
                }}
                className={classes.alert}
                icon={
                  <SaveAsOutlinedIcon
                    sx={{ color: x.color, fontSize: "24px" }}
                  />
                }
              >
                {x.title}
              </Alert>
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
};
