import { AxiosError, AxiosResponse } from "axios";
import { GET_QUERY_KEY, QUERY_OPTIONS, VENDOR_RESOURCE_LITERAL } from "common/constants";
import { IGetListRequest, IResponse, IServerError } from "models/common";

import { IDropDownListResponse } from "models/common/IDropDownListResponse";
import { IT4SlipListModel } from "models/t4Slip";
import { IT4SlipRequestPayload } from "models/t4Slip/IT4SlipRequestPayload";
import { ResourceService } from "./ResourceService";
import http from "common/http-common";
import { truncate } from "fs";
import { useQuery } from "react-query";

export let getClientId = "";
let getTaxYear: any = null;
// TODO: replace this with API response once it's ready
export const T4SlipService = {
  getT4SlipById: async (
    id: string | undefined,
    clientId: string,
    taxYear: number
  ): Promise<any | IServerError | IResponse | null> => {
    if (id) {
      const response: AxiosResponse<any> = await http.get<any>(
        `/v1/t4-slips/${id}/?client_id=${clientId}&tax_year=${taxYear}`
      );

      console.log("id : ", id);
      console.log("Client id : ", clientId);
      console.log("TaxYear : ", taxYear);
      console.log("response of selected row in t4 slip : ", response.data);

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }

      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }

    return null;
  },
  getResourceSlipDropDownList: async (
    resourceId: string,
    clientId: string,
    taxYear: number
  ): Promise<IDropDownListResponse[] | IServerError | IResponse> => {
    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<
      IDropDownListResponse[]
    >(
      `/v1/t4-slips/resource-ddl-slips/${resourceId}?client_id=${clientId}&tax_year=${taxYear}`
    );
    console.log("resourceId", resourceId);
    console.log("clientId", clientId);
    console.log("resourceId", taxYear);
    if (response.status === 200) {
      console.log("responce resource-ddl-slips", response.data);
      return response.data;
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getById: async (
    id: string | undefined,
    clientId: string,
    taxYear: number
  ): Promise<any | IServerError | IResponse | null> => {
    if (!id) return null;
    console.log("id from getbyId Service : ", id);
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/t4-slips/${id}/?client_id=${clientId}&tax_year=${taxYear}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  delT4Slip: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    console.log("deleteById", requestPayload.t4SlipId);
    const response: AxiosResponse<string> = await http.delete<string>(
      `/v1/t4-slips/${requestPayload.t4SlipId}/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    } else if (response.status === 400) {
      return response.data;
    }
    // else if (response.status === 200) {
    //   return {
    //     status: true,
    //     message: "Taxslip T4 Slip List has been deleted successfully",
    //     error: false,
    //   };
    // }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  delBulkSlips: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    console.log("requestPayload", typeof requestPayload);
    const response = await http.delete<string>(
      `/v1/t4-slips/bulk-delete/${requestPayload.clientId}/?tax_year=${requestPayload.taxYear}`,
      { data: requestPayload.ids}
    );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    console.log("REsponse ?????????????????????????????????????", response);
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getList: async (
    clientId: string,
    taxYear: number,
    params: IGetListRequest
  ): Promise<IT4SlipListModel[] | IServerError | IResponse> => {
    const response: AxiosResponse<IT4SlipListModel[]> = await http.get<
      IT4SlipListModel[]
    >(
      `/v1/t4-slips/?client_id=${clientId}&tax_year=${taxYear}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`
    );

    if (response.status === 200) {
      getClientId = clientId;
      getTaxYear = taxYear;
      console.log("T4 slip list response.data '===========", response.data);
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  create: async (
    requestPayload: IT4SlipRequestPayload
  ): Promise<IT4SlipRequestPayload | IServerError | IResponse | any> => {
    if (requestPayload.id) {
      // console.log('requestPayload ==>> update request', requestPayload);
      const updatedData = {
        ...requestPayload,
        resource: {
          ...requestPayload.resource,
          externalId: requestPayload.id,
          // is: 'your_value_here', // Replace 'your_value_here' with the value you want to add for the 'is' property
        },
      };

      const response: AxiosResponse<IT4SlipRequestPayload> =
        await http.put<IT4SlipRequestPayload>(
          `/v1/t4-slips/${requestPayload.id}/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`,
          requestPayload
        );

      console.log("updatedData ==>> update request status", response.status);
      if (response.status === 200) {
        const updatedData = {
          ...requestPayload,
          slipId:requestPayload.id
        };
        if(requestPayload.applyAdjustments === true && requestPayload.saveAdjutments === true && requestPayload.resource.provinceOfEmployment.shortCode === 'QC'){
          const response: AxiosResponse<any> = await http.post<any>(
            `/v1/t4-slips/apply-adjustments/${requestPayload.clientId}/?tax_year=${requestPayload.taxYear}`,
            updatedData
          );
          console.log("responswewee",response)
        }
        console.log("responce data of update id", response.data);
        // return response.data;
        return {status:response.status, data:response.data};
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }

      
    }

    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<IT4SlipRequestPayload> =
      await http.post<IT4SlipRequestPayload>(
        `/v1/t4-slips/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`,
        requestPayload
      );

    if (response.status === 201) {
      const updatedData = {
        ...requestPayload,
        slipId:requestPayload.id ? requestPayload.id : undefined
      };
      if(requestPayload.applyAdjustments === true && requestPayload.saveAdjutments === true && requestPayload.resource.provinceOfEmployment.shortCode === 'QC'){
        const response: AxiosResponse<any> = await http.post<any>(
          `/v1/t4-slips/apply-adjustments/${requestPayload.clientId}/?tax_year=${requestPayload.taxYear}`,
          updatedData
        );
        console.log("response123",response)
      }
      // return response.data;
      return {status:response.status, data:response.data};
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    } else if (response.status === 400) {
      return response.data;
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  t4SlipRetrieveData: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    console.log("requestPayload", requestPayload);
    const response = await http.post<string>(
      `/v1/t4-slips-csv/import-csv/?client_id=${requestPayload.id}&tax_year=${requestPayload.taxYear}`,
      requestPayload.formData
    );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    // console.log("loginDetail", loginDetail);
    console.log("REsponse ?????????????????????????????????????", response);

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  submitT4Data: async (
    requestPayload: any,
    objectId:string
  ): Promise<string | IServerError | IResponse> => {
    console.log("requestPayload", requestPayload);
    let res:any = undefined
    if(requestPayload.tag === 'T4'){
      const response = await http.post<string>(`/v1/t4-slips-csv/csv/${objectId}/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`);
      res = response
    } else if(requestPayload.tag === 'T4A'){
      const response = await http.post<string>(`/v1/t4a-slips-csv/csv/${objectId}/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`);
      res = response
    } else if(requestPayload.tag === 'RL1'){
      const response = await http.post<string>(`/v1/rl1-slips-csv/csv/${objectId}/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`);
      res = response
    } else if(requestPayload.tag === 'Employee'){
      const response = await http.post<string>(`/v1/resources/csv/${objectId}/?resource_role_name=${requestPayload.tag}&client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`);
      res = response
    } else if(requestPayload.tag === 'T5'){
      const response = await http.post<string>(`/v1/t5-slips-csv/csv/${objectId}/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`);
      res = response
    } else if(requestPayload.tag === 'RL3'){
      const response = await http.post<string>(`/v1/rl3-slips-csv/csv/${objectId}/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`);
      res = response
    } else if(requestPayload.tag === 'T3'){
      const response = await http.post<string>(`/v1/t3-slips-csv/csv/${objectId}/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`);
      res = response
    } else if(requestPayload.tag === 'RL16'){
      const response = await http.post<string>(`/v1/rl16-slips-csv/csv/${objectId}/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`);
      res = response
    }
    // const response = await http.post<string>(
    //   `/v1/resources/?resource_role_name=${requestPayload.tag}&client_id=${requestPayload.clientId}`,
    //   {"items":requestPayload.items}
    // );
    if (res && res.status === 201) {
      return res;
    } else if (res && res.status === 400) {
      return res.data;
    } else if (res && res.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  temporaryItemsCount: async (
    objectId:string | undefined
  ): Promise<string | IServerError | IResponse | any> => {
    const response = await http.get<string>(
      `/v1/temporary-storage/last-ten-second/${objectId}`);
    // const response = {
    //   status:200,
    //   data:null
    // };
    if (response && response.status === 200) {
      return response.data;
      // return {
      //   "id": "655b312f44a39f74d4df5185",
      //   "counter": 10,
      //   "fail": 6
      // };
    } else if (response && response.status === 400) {
      return response.data;
    } else if (response && response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  submitTemporaryData: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse | any> => {
    console.log("requestPayload", requestPayload);
    const response = await http.post<string>(
      `/v1/temporary-storage/`,
      {"items":requestPayload.items}
    );
    // const response = {
    //   status:201,
    //   data:null
    // };
    if (response && response.status === 201) {
      return response.data;
      // return {
      //   "id": "655b355e44a39f74d4df5198",
      //   "totalFetched": 1,
      //   "message": "1 Records found and fetching started"
      // };
    } else if (response && response.status === 400) {
      return response.data;
    } else if (response && response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  t4SlipRetrieveXmlData: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    console.log("requestPayload", requestPayload);
    const response:any = await http.post<string>(
      `/v1/t4-xml/?client_id=${requestPayload.id}&tax_year=${requestPayload.taxYear}`,
      requestPayload.formData
    );
    if (response.status === 201) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    // console.log("loginDetail", loginDetail);
    console.log("REsponse ?????????????????????????????????????", response);

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getT4Summary: async (
    clientId: string,
    slipType: string | number,
    taxYear: number
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/t4-summary/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`
    );

    if (response.status === 200) {
      console.log("T4 Summary response.data '===========", response.data);
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  t4SummaryclearConfirmationCode: async (
    clientId: string,
    slipType: number | undefined,
    taxYear: number
  ): Promise<string | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<string> | any= await http.put<string>(
      `/v1/t4-summary/clear-confirmation-code/${clientId}/?slip_type=${slipType}&tax_year=${taxYear}`
    );

    if (response.status === 200) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    } else if (response.status === 400) {
      return response.data
    } else if (response.status === 404) {
      return response.data
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
  getT4SummaryReduceEI: async (
    clientId: string,
    slipType: string | number,
    taxYear: number
  ): Promise<any | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/t4-summary-reduced-ei/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`
    );

    if (response.status === 200) {
      console.log("T4 Summary Reduce EI response.data '===========", response.data);
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
  t4SummaryReduceEIclearConfirmationCode: async (
    clientId: string,
    slipType: number | undefined,
    taxYear: number
  ): Promise<string | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<string> | any= await http.put<string>(
      `/v1/t4-summary-reduced-ei/clear-confirmation-code/${clientId}/?slip_type=${slipType}&tax_year=${taxYear}`
    );

    if (response.status === 200) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    } else if (response.status === 400) {
      return response.data
    } else if (response.status === 404) {
      return response.data
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
  createSummary: async (
    requestPayload: any
  ): Promise<any | IServerError | IResponse> => {
    // let response: IFlexFieldSetupResponse;
    // // if it's edit
    // if (requestPayload.id) {
    //   // @ts-ignore
    //   response = flexFields.find((flexField) => flexField.id === requestPayload.id);
    // } else {
    //   // return the first field for now until BE is ready.
    //   response = flexFields[0];
    // }
    console.log("requestPayload t4 setup : ", requestPayload);
    // if (requestPayload.id) {
    //   console.log('requestPayload ==>> update request', requestPayload);
    //   const response: AxiosResponse<IFlexFieldSetupResponse> = await http.put<IFlexFieldSetupResponse>(
    //     `/v1/flex-fields/${requestPayload.id}/`, requestPayload);

    //   if (response.status === 200) {
    //     return response.data;
    //   }
    //   else if (response.status === 500) {
    //     return {
    //       status: false,
    //       message: "Something went wrong.",
    //       error: true,
    //     };
    //   }
    // }

    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<any> = await http.put<any>(
      `/v1/t4-summary/?client_id=${requestPayload.id}&tax_year=${requestPayload.taxYear}`,
      requestPayload
    );

    if (response.status === 200) {
      return response;
    }
    if (response.status === 201) {
      return response;
    }
    if (response.status === 404) {
      return response.data;
    }else if (response.status === 400) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createT4SummaryReducedEI: async (
    requestPayload: any
  ): Promise<any | IServerError | IResponse> => {
    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<any> = await http.put<any>(
      `/v1/t4-summary-reduced-ei/?client_id=${requestPayload.id}&tax_year=${requestPayload.taxYear}`,
      requestPayload
  );

    if (response.status === 200) {
      return response;
    }
    if (response.status === 201) {
      return response;
    }
    if (response.status === 404) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  // role Association id send and get resource actual Id
  getResourceActualId: async (
    id: any
  ): Promise<any | IServerError | IResponse | null> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/resources/?resource_id=${id}`
      
    );

    console.log("response of getResourceActualId : ", response.data);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  // Resource Actual id send and get Role Association Id
  getRoleAssociationId: async (
    id: any
  ): Promise<any | IServerError | IResponse | null> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/resources/association-id/${id}`
    );

    console.log("response of getResourceActualId : ", response.data);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getResourceInformationById: async (
    resourceId: string | undefined,
    clientId: string,
    roleName: string
  ): Promise<any | IServerError | IResponse | null> => {
    if (resourceId) {
      const response: AxiosResponse<any> = await http.get<any>(
        `/v1/resources/${roleName}/${resourceId}/?client_id=${clientId}`
      );

      console.log("response of getResourceInformationById : ", response.data);

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }

      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }

    return null;
  },
  getAdjustedData: async (
    params: any
    // clientId:string,
    // taxYear:number
  ): Promise<any | IServerError | IResponse | null> => {
    console.log("params : ", params);

    const response: AxiosResponse<any> = await http.post<any>(
      `/v1/t4-slips/apply-adjustments/${params.clientId}/?tax_year=${params.taxYear}`,
      params
    );

    console.log("response of getAdjustedData : ", response.data);

    if (response.status === 201) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  getAllActiveTransmittersByClientIdAndTaxYear: async (
    clientId: string,
    taxYear: number
  ): Promise<any | IServerError | IResponse | null> => {
    try {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/filing-resources/transmitter/${clientId}?tax_year=${taxYear}`
    );

    console.log("response of transmitter : ", response.data);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }

    // return {
    //   status: false,
    //   message: "Something went wrong please try again later.",
    // };
  },
  getAllActiveIssuersByClientIdAndTaxYear: async (
    clientId: string,
    taxYear: number
  ): Promise<any | IServerError | IResponse | null> => {
    try {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/filing-resources/issuer/${clientId}?tax_year=${taxYear}`
    );

    console.log("response of getAdjustedData : ", response.data);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }

    // return {
    //   status: false,
    //   message: "Something went wrong please try again later.",
    // };
  },
  getAllActiveContactPersonsByClientIdAndTaxYear: async (
    clientId: string,
    taxYear: number
  ): Promise<any | IServerError | IResponse | null> => {
    try {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/filing-resources/contact-person/${clientId}?tax_year=${taxYear}`
    );

    console.log("response of getAdjustedData : ", response.data);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }

    // return {
    //   status: false,
    //   message: "Something went wrong please try again later.",
    // };
  },
  //get employee list after create slip and in useeffect bcz when screen appears its needs to load
  getEmployeeDDL: async (
    clientId: string, dataRole: string,taxYear:number
  ): Promise<any | IServerError | IResponse | null> => {

    // let queryKey = GET_QUERY_KEY.EMPLOYEE_RESOURCE_DDL;
    // switch (dataRole) {
    //   case VENDOR_RESOURCE_LITERAL:
    //     queryKey = GET_QUERY_KEY.VENDOR_RESOURCE_DDL
    //     break;
    //   default:
    //     queryKey = GET_QUERY_KEY.EMPLOYEE_RESOURCE_DDL;
    //     break;
    // }

    // return useQuery(
    //   queryKey,
    //   () => ResourceService.getDropDownList(clientId, dataRole),
    //   QUERY_OPTIONS
    // );
    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<
      IDropDownListResponse[]
    >(`/v1/resources/ddl-list/${dataRole}/by-year/?client_id=${clientId}&tax_year=${taxYear}`);

    console.log("respooooooooooooooooonse",response)

    if (response.status === 200) {
      return response.data;
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getIssuerDataById: async (
    id: string,
    clientId:string
  ): Promise<any | IServerError | IResponse | null> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/filing-resources/${id}/?client_id=${clientId}`
    );

    console.log("response of getResourceActualId : ", response.data);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getContactPersonDataById: async (
    id: string,
    clientId:string
  ): Promise<any | IServerError | IResponse | null> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/filing-resources/${id}/?client_id=${clientId}`
    );

    console.log("response of getResourceActualId : ", response.data);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getCSVInformationUIData: async (
    params: IGetListRequest
  ): Promise<
  any | IServerError | IResponse
  > => {
          const fixedResponse: any = {
            "items": [
              {
                "clientId": "f17badd1-ea46-415c-8bb8-59363b8e3f4c",
                "firstName": "jun allia",
                "lastName": "Employee",
                "socialInsurance": "3456-657",
                "email": "demo@gmail.com",
                "businessName": "fake name",
                "businessNumber": "99999999AA9999",
                "address": "Street 12345",
                "city": "Toronto",
                "postalCode": "A0A0A0",
                "province": "ON",
                "status": "Active",
                "employeeCode": 2325,
                "initials": "M",
                "dateOfBirth": "1985-09-05",
                "phone1": "(56) 765-6667",
                "ext1": "566",
                "phone2": "(56) 756-7777",
                "ext2": "54",
                "provinceOfEmployment": "ON",
                "cppQppExempt": "No",
                "eiExempt": "No",
                "ppipExempt": "No",
                "employmentCode": 13,
                "payPeriod": "12",
                "reducedEi": "No",
                "employeeType": "Fulltime Employee"
              }
            ],
            "headerColumns": [
              {
                "field": "clientId",
                "headerName": "Client Id",
                "description": "Client Id",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 0,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "firstName",
                "headerName": "First Name",
                "description": "First Name",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 1,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "lastName",
                "headerName": "Last Name",
                "description": "Last Name",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 2,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "socialInsurance",
                "headerName": "Social Insurance",
                "description": "Social Insurance",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 3,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "email",
                "headerName": "Email",
                "description": "Email",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 4,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "businessName",
                "headerName": "Business Name",
                "description": "Business Name",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 5,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "businessNumber",
                "headerName": "Business Number",
                "description": "Business Number",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 6,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "address",
                "headerName": "Address",
                "description": "Address",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 7,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "city",
                "headerName": "City",
                "description": "City",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 8,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "postalCode",
                "headerName": "Postal Code",
                "description": "Postal Code",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 9,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "province",
                "headerName": "Province",
                "description": "Province",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 10,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "status",
                "headerName": "Status",
                "description": "Status",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 11,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "employeeCode",
                "headerName": "Employee Code",
                "description": "Employee Code",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 12,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "initials",
                "headerName": "Initials",
                "description": "Initials",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 13,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "dateOfBirth",
                "headerName": "Date Of Birth",
                "description": "Date Of Birth",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 14,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "phone1",
                "headerName": "Phone1",
                "description": "Phone1",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 15,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "ext1",
                "headerName": "Ext1",
                "description": "Ext1",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 16,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "phone2",
                "headerName": "Phone2",
                "description": "Phone2",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 17,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "ext2",
                "headerName": "Ext2",
                "description": "Ext2",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 18,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "provinceOfEmployment",
                "headerName": "Province Of Employment",
                "description": "Province Of Employment",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 19,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "cppQppExempt",
                "headerName": "Cpp Qpp Exempt",
                "description": "Cpp Qpp Exempt",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 20,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "eiExempt",
                "headerName": "Ei Exempt",
                "description": "Ei Exempt",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 21,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "ppipExempt",
                "headerName": "Ppip Exempt",
                "description": "Ppip Exempt",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 22,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "employmentCode",
                "headerName": "Employment Code",
                "description": "Employment Code",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 23,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "payPeriod",
                "headerName": "Pay Period",
                "description": "Pay Period",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 24,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "reducedEi",
                "headerName": "Reduced Ei",
                "description": "Reduced Ei",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 25,
                "frozenColumn": false,
                "footer": null
              },
              {
                "field": "employeeType",
                "headerName": "Employee Type",
                "description": "Employee Type",
                "flex": 1,
                "hide": false,
                "hideable": false,
                "sortable": true,
                "sortingOrder": "asc",
                "resizable": false,
                "type": "string",
                "align": "left",
                "headerAlign": "left",
                "hideSortIcons": false,
                "disableColumnMenu": true,
                "disableReorder": true,
                "disableExport": true,
                "maskable": false,
                "isFixed": true,
                "sequence": 26,
                "frozenColumn": false,
                "footer": null
              }
            ]
          }
  return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    // const response: AxiosResponse<IRL1ReportListModel> = await http.get<IRL1ReportListModel>(
    //   `/v1/general-clients/?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`);

    // if (response.status === 200) {
    //   console.log("general-clients",response.data)
    //   return response.data;
    // } else if (response.status === 400) {
    //   return {
    //     status: false,
    //     message: "Bad Request.",
    //     error: true,
    //   };
    // } else if (response.status === 500) {
    //   return {
    //     status: false,
    //     message: "Something went wrong.",
    //     error: true,
    //   };
    // }

    // return {
    //   status: false,
    //   message: "Something went wrong please try again later.",
    // };
  },
  getT4AuditFiling: async (
    clientId: string,
    taxYear:number
  ): Promise<
  any | IServerError | IResponse
  > => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/audit/audit-filling?client_id=${clientId}&audit_filling_tag=T4&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getSlipIds: async (
    tag: string,
    clientId: string,
    taxYear: number
  ): Promise<any | IServerError | IResponse | null> => {
    const response: AxiosResponse<any> = await http.post<any>(
      `v1/dashboard-api/all-slips-ids/?client_id=${clientId}&tax_year=${taxYear}&slip_tag=${tag}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
    // const fixedResponse: any = {
    //   // t4AuditStatus:true,
    //   slipIds:[
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //       '66604585444d16324afb067b',
    //     ]} 
    
    //   return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

  },
};
