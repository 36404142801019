import { useSelector } from 'react-redux';
import { IAppState } from './store/reducers';
import { DefaultTaxSlipTheme } from './themes/DefaultTaxSlipTheme';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { AppRouter } from './navigation';

// Prime REact Configeration add import files here
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
//core
import "primereact/resources/primereact.min.css";   
import "primeicons/primeicons.css";                                //icons
import "primeflex/primeflex.css";                                //flex

const App = () => {
  const isAuthenticated = useSelector((state: IAppState) => state.user.isAuthenticated);
  console.log("isAuthenticatedisAuthenticated",isAuthenticated)
  return (
    <>
    <ThemeProvider theme={DefaultTaxSlipTheme}>
      <CssBaseline />
      <AppRouter isAuthenticated={isAuthenticated} />
    </ThemeProvider>
  </>
  );
};

export default App;

// import { MaskUnmask } from '@websential/cosmic'
// function App() {
//   return (
//     <>
//       <h1>Vite + React</h1>
      
//         <p>
//           Edit <code>src/App.tsx</code> and save to test HMR
//         </p>
//       <MaskUnmask PostApiUrl={''} UnsetMaskFlagTimeoutInMinutes={0} testId={'undefined'}/>
//       <p className="read-the-docs">
//         Click on the Vite and React logos to learn more
//       </p>
//     </>
//   )
// }

// export default App

