import "./StatementOfDutiesPaidOrPayableUI.css";

import { FC, useEffect, useState } from "react";
import { Typography, useTheme } from "@mui/material";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { IAppState } from "store/reducers";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useSelector } from "react-redux";

// import { Button } from "primereact/button";

// import { Toolbar } from "primereact/toolbar";







export const getMonthRange = (year: number, month: string): string => {
	const monthIndexMap: Record<string, number> = {
	  january: 0,
	  february: 1,
	  march: 2,
	  april: 3,
	  may: 4,
	  june: 5,
	  july: 6,
	  august: 7,
	  september: 8,
	  october: 9,
	  november: 10,
	  december: 11,
	};
  
	const monthIndex = monthIndexMap[month.toLowerCase()];
  
	if (monthIndex === undefined) {
	  throw new Error(`Invalid month name: ${month}`);
	}
  
	const firstDate = new Date(year, monthIndex, 1);
	const lastDate = new Date(year, monthIndex + 1, 0);
  
	const formatDate = (date: Date) =>
	  date.toLocaleDateString("en-US", {
		month: "short",
		day: "2-digit",
	  });
  
	return `${formatDate(firstDate)} - ${formatDate(lastDate)}`;
};


export const generateTotalColumnLabel = () => {
    return (
      <div 
	  style={{ fontSize:"0.875rem" }}
	  >
		<div style={{textAlign:"left"}}>Add lines 5 through 16,<br /> 
		column by column.</div>
        <div style={{textAlign:"right",fontWeight:"bold"}}>Total of amounts <br />
			withheld and <br />
			contributions</div>
      </div>
    );
  };

  
  const generateHeaderName = (alpha:string,name:string,nameLine2?:string,nameLine3?:string) => {
	  return (
		  <span 
		  style={{ textAlign:"center" }}
		  >
        <b>{alpha}</b><br />
        <span>{name}</span><br />
		{nameLine2 && <><span>{nameLine2}</span><br/></>}
		{nameLine3 && <span>{nameLine3}</span>}
      </span>
    );
};

const TabTableheaderColumns =  [
	{
		"field": "id",
		"headerName":"",
		"description": "",
		"flex": 1,
		"hide": false,
		"hideable": false,
		"sortable": true,
		"sortingOrder": "asc",
		"resizable": false,
		"type": "string",
		"align": "left",
		"headerAlign": "center",
		"hideSortIcons": false,
		"disableColumnMenu": true,
		"disableReorder": true,
		"disableExport": true,
		"maskable": false,
		"isFixed": true,
		"sequence": 0,
		"frozenColumn": false,
		"footer": null
	},
	{
		"field": "renmittancePeriodBoxA",
		"headerName": generateHeaderName("A","Remittance Period"),
		"description": "",
		"flex": 1,
		"hide": false,
		"hideable": false,
		"sortable": true,
		"sortingOrder": "asc",
		"resizable": false,
		"type": "string",
		"align": "left",
		"headerAlign": "center",
		"hideSortIcons": false,
		"disableColumnMenu": true,
		"disableReorder": true,
		"disableExport": true,
		"maskable": false,
		"isFixed": true,
		"sequence": 0,
		"frozenColumn": false,
		"footer": null
	},
	{
		"field": "qppContributionBoxB",
		"headerName": generateHeaderName("B","QPP contributions"),
		"description": "",
		"flex": 1,
		"hide": false,
		"hideable": false,
		"sortable": true,
		"sortingOrder": "asc",
		"resizable": false,
		"type": "string",
		"align": "left",
		"headerAlign": "center",
		"hideSortIcons": false,
		"disableColumnMenu": true,
		"disableReorder": true,
		"disableExport": true,
		"maskable": false,
		"isFixed": true,
		"sequence": 1,
		"frozenColumn": false,
		"footer": null
	},
	{
		"field": "qpipPremiumsBoxC",
		"headerName": generateHeaderName("C","QPIP premiums"),
		"description": "",
		"flex": 1,
		"hide": false,
		"hideable": false,
		"sortable": true,
		"sortingOrder": "asc",
		"resizable": false,
		"type": "string",
		"align": "left",
		"headerAlign": "center",
		"hideSortIcons": false,
		"disableColumnMenu": true,
		"disableReorder": true,
		"disableExport": true,
		"maskable": false,
		"isFixed": true,
		"sequence": 2,
		"frozenColumn": false,
		"footer": null
	},
	{
		"field": "quebecIncomeTaxBoxD",
		"headerName": generateHeaderName("D","Québec income tax"),
		"description": "",
		"flex": 1,
		"hide": false,
		"hideable": false,
		"sortable": true,
		"sortingOrder": "asc",
		"resizable": false,
		"type": "string",
		"align": "left",
		"headerAlign": "center",
		"hideSortIcons": false,
		"disableColumnMenu": true,
		"disableReorder": true,
		"disableExport": true,
		"maskable": false,
		"isFixed": true,
		"sequence": 3,
		"frozenColumn": false,
		"footer": null
	},
	{
		"field": "contributionToHealthServicesFundBoxE",
		"headerName": generateHeaderName("E","Contribution","to the health","services fund"),
		"description": "",
		"flex": 1,
		"hide": false,
		"hideable": false,
		"sortable": true,
		"sortingOrder": "asc",
		"resizable": false,
		"type": "string",
		"align": "left",
		"headerAlign": "center",
		"hideSortIcons": false,
		"disableColumnMenu": true,
		"disableReorder": true,
		"disableExport": true,
		"maskable": false,
		"isFixed": true,
		"sequence": 4,
		"frozenColumn": false,
		"footer": null
	},
	{
		"field": "totalForTheRemittancePeriodBoxF",
		"headerName": generateHeaderName("F","Total for the","remittance period","(B + C + D + E)"),
		"description": "",
		"flex": 1,
		"hide": false,
		"hideable": false,
		"sortable": true,
		"sortingOrder": "asc",
		"resizable": false,
		"type": "string",
		"align": "left",
		"headerAlign": "center",
		"hideSortIcons": false,
		"disableColumnMenu": true,
		"disableReorder": true,
		"disableExport": true,
		"maskable": false,
		"isFixed": true,
		"sequence": 4,
		"frozenColumn": false,
		"footer": null
	}
  ]

interface IStatementOfDutiesPaidOrPayable {
	statementofDutiesPaidorPayableData: (data: any) => void;
	statementofDutiesPaidorPayableDataInGetById:any
}
export const StatementOfDutiesPaidOrPayable: FC<IStatementOfDutiesPaidOrPayable> = ({ statementofDutiesPaidorPayableData,statementofDutiesPaidorPayableDataInGetById }) => {
	const theme = useTheme();
	const taxYear = useSelector((state: IAppState) => state.taxYear.year);
	let initialStatementofDutiesPaidorPayableData = [
		{
			"id": "5",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"january")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "6",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"february")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "7",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"march")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "8",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"april")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "9",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"may")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "10",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"june")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "11",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"july")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "12",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"august")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "13",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"september")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "14",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"october")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "15",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"november")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "16",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"december")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "17",
			"renmittancePeriodBoxA": generateTotalColumnLabel(),
			"qppContributionBoxB": "0.00",
			"qpipPremiumsBoxC": "0.00",
			"quebecIncomeTaxBoxD": "0.00",
			"contributionToHealthServicesFundBoxE": "0.00",
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
	]
	const [statementofDutiesPaidorPayable, setStatementofDutiesPaidorPayable] = useState<any>(initialStatementofDutiesPaidorPayableData);

	useEffect(() => {
		statementofDutiesPaidorPayableData(statementofDutiesPaidorPayable);
	}, [statementofDutiesPaidorPayable]);
	
	useEffect(() => {
		if(statementofDutiesPaidorPayableDataInGetById){
			setStatementofDutiesPaidorPayable(statementofDutiesPaidorPayableDataInGetById)
		}
	}, [statementofDutiesPaidorPayableDataInGetById]);

	const onCellValueChange = (event: any, rowIndex: number, field: any) => {
		// if(event.field !== "renmittancePeriodBoxA"){
			
			//update the specific cell value
			const updatedData = [...statementofDutiesPaidorPayable];
			updatedData[rowIndex][event.field] = field;
	
			// Calculate the row total for the specified row index
			const currentRow = updatedData[rowIndex];
			const rowTotal = [
				currentRow.qppContributionBoxB || 0,
				currentRow.qpipPremiumsBoxC || 0,
				currentRow.quebecIncomeTaxBoxD || 0,
				currentRow.contributionToHealthServicesFundBoxE || 0,
			].reduce((sum, value) => sum + value, 0);
	
			// Place the total in totalForTheRemittancePeriodBoxF for the same row
			currentRow.totalForTheRemittancePeriodBoxF = parseFloat(rowTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	
			// Recalculate the summary row for the column if necessary
			// if (field) {
				let filteredData = updatedData.filter(item => item.id !== "17");
				let fieldValues = filteredData
					.map(item => item[event.field])
					.filter(value => value !== null);
				let sumOfColumn = fieldValues.reduce((total, value) => total + value, 0);
		
				// Update the object with id 17 for the corresponding field
				const summaryRowIndex = updatedData.findIndex(item => item.id === "17");
				if (summaryRowIndex !== -1) {
					updatedData[summaryRowIndex][event.field] = parseFloat(sumOfColumn).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				}
			// } else {
			// 	// Update the object with id 17 for the corresponding field if field is null
			// 	const summaryRowIndex = updatedData.findIndex(item => item.id === "17");
			// 	if (summaryRowIndex !== -1) {
			// 		updatedData[summaryRowIndex][event.field] = "0.00";
			// 	}
			// }
	
			// Calculate the sum of totalForTheRemittancePeriodBoxF column
			const totalForAllRows:any = updatedData
			.filter(item => item.id !== "17") // Exclude the summary row
			.map(item => item.totalForTheRemittancePeriodBoxF && parseFloat(item.totalForTheRemittancePeriodBoxF.replace(/[$,]/g, '')) || 0) // Ignore nulls
			.reduce((sum, value) => sum + value, 0);
	
			// Place the total in totalForTheRemittancePeriodBoxF of row id 17
			const summaryRowIndex1 = updatedData.findIndex(item => item.id === "17");
			if (summaryRowIndex1 !== -1) {
				updatedData[summaryRowIndex1].totalForTheRemittancePeriodBoxF = parseFloat(totalForAllRows).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
	
			// Update the statementofDutiesPaidorPayable with the modified data
			setStatementofDutiesPaidorPayable(updatedData);
			console.log("updatedData--",updatedData)


		// } else {
		// 	//update the specific cell value
		// 	const updatedData = [...statementofDutiesPaidorPayable];
		// 	updatedData[rowIndex][event.field] = field;
		// 	setStatementofDutiesPaidorPayable(updatedData);
		// 	console.log("updatedData--",updatedData)
		// }

		console.log("event--",event)
		console.log("rowIndex",rowIndex)
		console.log("field",field)
	};

	const renderErrorClass = (field: any, rowIndex: number, rowData: any) => {
		console.log("field.field",field.field)
		if (field.field !== "id" && field.field !== "renmittancePeriodBoxA") {
			return "numberField p-component p-filled";
		} else if (field.field === "renmittancePeriodBoxA") {
			return "p-component p-filled renmittancePeriodBoxA";
		} else if(field.field === "id") {
			return "indexColumn"
		}
	};

	const columnsBody = (rowData: any, row: any, x: any) => {
		console.log("row.rowIndex",row.rowIndex)
		if (x.field === "renmittancePeriodBoxA" && row.rowIndex !== 12) {
			return (
				<InputText 
					value={rowData[x.field]}
					// onChange={(e: any) => onCellValueChange(x, row.rowIndex, e.target.value)}
					className={renderErrorClass(x, row.rowIndex, rowData)}
					// useGrouping={false}
					disabled={true}
				/>
			);
		} else if (x.field !== "id" && x.field !== "totalForTheRemittancePeriodBoxF" && row.rowIndex !== 12) {
			return (
				<InputNumber
					value={rowData[x.field]}
					onChange={(e: any) => onCellValueChange(x, row.rowIndex, e.value)}
					className={renderErrorClass(x, row.rowIndex, rowData)}
					useGrouping={true}
					minFractionDigits={2} maxFractionDigits={2}
					// style={{textAlign:"end"}}
				/>
			);
		} else {
			return (
				<Typography className={renderErrorClass(x, row.rowIndex, rowData)}>{rowData[x.field]}</Typography>
			)
		}
	};
	console.log("statementofDutiesPaidorPayable--", statementofDutiesPaidorPayable);

	return (
		<>
			<div className="card">
				<DataTable
					value={statementofDutiesPaidorPayable}
					// selection={selectedStatementofDutiesPaidorPayable}
					// onSelectionChange={(e) => setSelectedStatementofDutiesPaidorPayable(e.value)}
					dataKey="id"
					showGridlines
					className="StatementOfDutiesPaidOrPayable"
					editMode="cell"
					resizableColumns
					columnResizeMode="expand"
					scrollable
					reorderableColumns
					filterDisplay="menu"
					// selectionMode="checkbox"
					// scrollHeight="580px"
				>
					{TabTableheaderColumns.map((x: any, index: any) => {
						return (
							<Column
								key={index}
								field={x.field}
								header={x.headerName}
								headerTooltip={x.description}
								headerTooltipOptions={{ position: "top" }}
								// sortable={false}
								// resizeable={x.resizable}
								// dataType={x.type}
								align={x.align}
								alignHeader={x.headerAlign}
								// reorderable={x.disableReorder}
								// exportable={x.disableExport}
								frozen={x.frozenColumn}
								columnKey={x.field}
								style={{
									minWidth: x.minWidth,
									fontSize: "0.875rem",
								}}
								footer={x.footer}
								body={(rowData: any, row: any) => columnsBody(rowData, row, x)}
							/>
						);
					})}
				</DataTable>
			</div>
		</>
	);
};
