import { AlertColor } from "@mui/material";

export const ROWS_PER_PAGE_PER_OPTIONS = [25, 50, 100];
export const INITIAL_PAGE_SIZE = 25;
export const SEARCH_DEBOUNCE_DELAY_IN_SECONDS = 2;
export const INITIAL_SNACKBAR_DATA = {
  open: false,
  message: "",
  severity: "success" as AlertColor,
};
export const ACCOUNTANT_ACTIVE_STATUS_ID = 5;
export const CLIENT_ACTIVE_STATUS_ID = 9;
export const RESOURCE_ACTIVE_STATUS_ID = 13;
export const MINIMUM_TAX_YEAR = 2012;
export const DATE_FORMAT_API_PAYLOAD = "YYYY-MM-DD";
export const EMPLOYEE_RESOURCE_LITERAL = "Employee";
export const VENDOR_RESOURCE_LITERAL = "Vendor";
export const T5_RECIPIENT_RESOURCE_LITERAL = "T5_Recipient";
export const T3_BENEFICIARY_RESOURCE_LITERAL = "T3_Beneficiary";

export const GET_QUERY_KEY = {
  LOOKUP_FIELD_DATA_BY_ID: "lookup-field-data-by-id",
  EMPLOYEE_RESOURCE_SLIP_DETAIL: "employee-resource-slip-detail",
  EMPLOYEE_RESOURCE_SLIPS_DDL: "employee-resource-slips-ddl",
  EMPLOYEE_RESOURCE_DDL: "employee-resource-ddl",
  VENDOR_RESOURCE_SLIP_DETAIL: "vendor-resource-slip-detail",
  VENDOR_RESOURCE_SLIPS_DDL: "vendor-resource-slips-ddl",
  VENDOR_RESOURCE_DDL: "vendor-resource-ddl",
  YEAR_DDL: "get-years-ddl",
  PROVINCE_DDL: "get-provinces-ddl",
  OPERATING_PROVINCE_DDL: "get-operating-provinces-ddl",
  COUNTRY_DDL: "get-country-ddl",
  BILLING_PROFILE_DDL: "get-billing-profile-ddl",
  GET_TAX_PROFILE_BY_ID: "tax-profile-by-id",
  GET_BILLING_PROFILE_BY_ID: "billing-profile-by-id",
  GET_GLOBAL_VARIABLES_BY_YEAR: "global-variables-by-year",
  LIST_T4_SLIPS: "t4-slips-list",
  LIST_T4A_SLIPS: "t4a-slips-list",
  LIST_T5_SLIPS: "t5-slips-list",
  LIST_T3_SLIPS: "t3-slips-list",
  LIST_RL1_SLIPS: "rl1-slips-list",
  LIST_RL3_SLIPS: "rl3-slips-list",
  LIST_RL16_SLIPS: "rl16-slips-list",
  RL1_SLIP_DATA_CODE_DDL: "get-slip-data-code-ddl",
};

export const QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
};

//screen ids
export const GLOBAL_SEARCH_SCREEN_ID = 77;
export const SUPER_USER_SCREEN_ID = 78;
export const ACCOUNTANT_LIST_SCREEN_ID = 79;
export const ACCOUNTANT_SETUP_SCREEN_ID = 80;
export const YEAR_SCREEN_ID = 81;
export const DATA_ROLE_SCREEN_ID = 82;
export const LOOKUP_FIELDS_SCREEN_ID = 83;
export const GLOBAL_VARIABLES_SCREEN_ID = 84;
export const FLEX_FIELDS_LIST_SCREEN_ID = 85;
export const FLEX_FIELDS_SETUP_SCREEN_ID = 86;
export const FLEX_FIELD_TAB_LIST_SCREEN_ID = 87;
export const FLEX_FIELD_TAB_SETUP_SCREEN_ID = 88;
export const FLEX_FIELD_PANEL_LIST_SCREEN_ID = 89;
export const FLEX_FIELD_PANEL_SETUP_SCREEN_ID = 90;
export const FLEX_FIELD_PANEL_ASSIGNMENT_SETUP_SCREEN_ID = 91;
export const FLEX_FIELD_SCREEN_SETUP_SCREEN_ID = 92;
export const CATEGORY_LIST_SCREEN_ID = 93;
export const CATEGORY_SETUP_SCREEN_ID = 94;
export const BILLING_PROFILE_LIST_SCREEN_ID = 95;
export const BILLING_PROFILE_SETUP_SCREEN_ID = 96;
export const TAX_PROFILE_LIST_SCREEN_ID = 97;
export const TAX_PROFILE_SETUP_SCREEN_ID = 98;
export const BILLING_PROFILE_ASSOCIATION_SETUP_SCREEN_ID = 99;
export const ADMIN_INVOICE_REPORT_SCREEN_ID = 100;
export const UPDATE_INVOICE_SCREEN_ID = 101;
export const TRANSACTIONS_REPORT_SCREEN_ID = 102;
export const JOURNAL_CODES_SETUP_SCREEN_ID = 103;
export const JOURNAL_EXPORT_SCREEN_ID = 104;
export const MOVE_CLIENTS_SCREEN_ID = 105;
export const CLIENT_LIST_SCREEN_ID = 106;
export const CLIENT_SETUP_SCREEN_ID = 107;
export const CLIENT_DASHBOARD_SCREEN_ID = 108;
export const EMPLOYEE_LIST_SCREEN_ID = 109;
export const EMPLOYEE_SETUP_SCREEN_ID = 110;
export const MOVE_EMPLOYEES_SCREEN_ID = 111;
export const MERGE_EMPLOYEES_SCREEN_ID = 112;
export const UNDO_MERGE_EMPLOYEES_SCREEN_ID = 113;
export const VENDOR_LIST_SCREEN_ID = 114;
export const VENDOR_SETUP_SCREEN_ID = 115;
export const T5_RECIPIENT_LIST_SCREEN_ID = 116;
export const T5_RECIPIENT_SETUP_SCREEN_ID = 117;
export const FILING_RESOURCE_LIST_SCREEN_ID = 118;
export const FILING_RESOURCE_SETUP_SCREEN_ID = 119;
export const AJDUSTMENT_OPTIONS_SCREEN_ID = 120;
export const ADJUSTMENT_SCREEN_ID = 121;
export const T4_ADJUSTMENT_REPORT_SCREEN_ID = 122;
export const RL1_ADJUSTMENT_REPORT_SCREEN_ID = 123;
export const AUDIT_FILING_SCREEN_ID = 124;
export const EARNINGS_AUDIT_SCREEN_ID = 125;
export const PIER_REVIEW_SCREEN_ID = 126;
export const T4_LIST_SCREEN_ID = 127;
export const T4_SETUP_SCREEN_ID = 128;
export const T4_SUMMARY_SCREEN_ID = 129;
export const T4_SUMMARY_RESUCED_EI_SCREEN_ID = 130;
export const T4A_LIST_SCREEN_ID = 131;
export const T4A_SETUP_SCREEN_ID = 132;
export const T4A_SUMMARY_SCREEN_ID = 133;
export const T5_LIST_SCREEN_ID = 134;
export const T5_SETUP_SCREEN_ID = 135;
export const T5_SUMMARY_SCREEN_ID = 136;
export const RL1_LIST_SCREEN_ID = 137;
export const RL1_SETUP_SCREEN_ID = 138;
export const RL1_SUMMARY_SCREEN_ID = 139;
export const RL1_SETTINGS_SCREEN_ID = 140;
export const EMPLOYEES_REPORT_SCREEN_ID = 141;
export const VENDORS_REPORT_SCREEN_ID = 142;
export const T4_REPORT_SCREEN_ID = 143;
export const RL1_REPORT_SCREEN_ID = 144;
export const T4A_REPORT_SCREEN_ID = 145;
export const INVOICE_REPORT_SCREEN_ID = 146;
export const INVOICE_DETAIL_SCREEN_ID = 147;
export const ACTIVITY_LOG_REPORT_SCREEN_ID = 148;
export const API_KEYS_SCREEN_ID = 149;

//screen slugs
export const GLOBAL_SEARCH_SCREEN_SLUG = "global_search";
export const SUPER_USER_SCREEN_SLUG = "super_user";
export const ACCOUNTANT_LIST_SCREEN_SLUG = "accountant_list";
export const ACCOUNTANT_SETUP_SCREEN_SLUG = "accountant_setup";
export const YEAR_SCREEN_SLUG = "year";
export const DATA_ROLE_SCREEN_SLUG = "data_roles";
export const LOOKUP_FIELDS_SCREEN_SLUG = "lookup_fields";
export const GLOBAL_VARIABLES_SCREEN_SLUG = "global_variables";
export const FLEX_FIELDS_LIST_SCREEN_SLUG = "flex_fields_list";
export const FLEX_FIELDS_SETUP_SCREEN_SLUG = "flex_fields_setup";
export const FLEX_FIELD_TAB_LIST_SCREEN_SLUG = "flex_field_tab_list";
export const FLEX_FIELD_TAB_SETUP_SCREEN_SLUG = "flex_field_tab_setup";
export const FLEX_FIELD_PANEL_LIST_SCREEN_SLUG = "flex_field_panel_list";
export const FLEX_FIELD_PANEL_SETUP_SCREEN_SLUG = "flex_field_panel_setup";
export const FLEX_FIELD_PANEL_ASSIGNMENT_SETUP_SCREEN_SLUG =
  "flex_field_panel_assignment_setup";
export const FLEX_FIELD_SCREEN_SETUP_SCREEN_SLUG = "flex_field_screen_setup";
export const CATEGORY_LIST_SCREEN_SLUG = "category_list";
export const CATEGORY_SETUP_SCREEN_SLUG = "category_setup";
export const DEFINITIONS_LIST_SCREEN_SLUG = "definitons_list";
export const DEFINITIONS_SETUP_SCREEN_SLUG = "definitons_setup";
export const BILLING_PROFILE_LIST_SCREEN_SLUG = "billing_profile_list";
export const BILLING_PROFILE_SETUP_SCREEN_SLUG = "billing_profile_setup";
export const TAX_PROFILE_LIST_SCREEN_SLUG = "tax_profile_list";
export const TAX_PROFILE_SETUP_SCREEN_SLUG = "tax_profile_setup";
export const BILLING_PROFILE_ASSOCIATION_SETUP_SCREEN_SLUG =
  "billing_profile_association_setup";
export const ADMIN_INVOICE_REPORT_SCREEN_SLUG = "invoice_report";
export const UPDATE_INVOICE_SCREEN_SLUG = "update_invoice";
export const TRANSACTIONS_REPORT_SCREEN_SLUG = "transactions_report";
export const JOURNAL_CODES_SETUP_SCREEN_SLUG = "journal_codes_setup";
export const JOURNAL_EXPORT_SCREEN_SLUG = "journal_export";
export const MOVE_CLIENTS_SCREEN_SLUG = "move_clients";
export const CLIENT_LIST_SCREEN_SLUG = "client_list";
export const CLIENT_SETUP_SCREEN_SLUG = "client_setup";
export const CLIENT_DASHBOARD_SCREEN_SLUG = "client_dashboard";
export const EMPLOYEE_LIST_SCREEN_SLUG = "employee_list";
export const EMPLOYEE_SETUP_SCREEN_SLUG = "employee_setup";
export const MOVE_PEOPLE_SCREEN_SLUG = "move_people";
export const MOVE_EMPLOYEES_SCREEN_SLUG = "employee_move_year";
export const MOVE_VENDORS_SCREEN_SLUG = "vendor_move_year";
export const MOVE_T5_RECIPIENTS_SCREEN_SLUG = "t5_recipient_move_year";
export const MOVE_T3_BENEFICIARY_SCREEN_SLUG = "t3_recipient_move_year";
export const MERGE_EMPLOYEES_SCREEN_SLUG = "merge_employees";
export const UNDO_MERGE_EMPLOYEES_SCREEN_SLUG = "undo_merge_employees";
export const VENDOR_LIST_SCREEN_SLUG = "vendor_list";
export const VENDOR_SETUP_SCREEN_SLUG = "vendor_setup";
export const T5_RECIPIENT_LIST_SCREEN_SLUG = "t5_recipient_list";
export const T5_RECIPIENT_SETUP_SCREEN_SLUG = "t5_recipient_setup";
export const T3_BENEFICIARY_LIST_SCREEN_SLUG = "t3_beneficiary_list";
export const T3_BENEFICIARY_SETUP_SCREEN_SLUG = "t3_beneficiary_setup";
export const FILING_RESOURCE_LIST_SCREEN_SLUG = "filing_resource_list";
export const FILING_RESOURCE_SETUP_SCREEN_SLUG = "filing_resource_setup";
export const AJDUSTMENT_OPTIONS_SCREEN_SLUG = "adjustment_options";
export const ADJUSTMENT_SCREEN_SLUG = "adjustment";
export const T4_ADJUSTMENT_REPORT_SCREEN_SLUG = "t4_adjustment_report";
export const RL1_ADJUSTMENT_REPORT_SCREEN_SLUG = "rl1_adjustment_report";
export const AUDIT_FILING_SCREEN_SLUG = "audit_filing";
export const EARNINGS_AUDIT_SCREEN_SLUG = "earnings_audit";
export const PIER_REVIEW_SCREEN_SLUG = "pier_review";
export const T4_LIST_SCREEN_SLUG = "t4_list";
export const T4_SETUP_SCREEN_SLUG = "t4_setup";
export const T4_SUMMARY_SCREEN_SLUG = "t4_summary";
export const T4_SUMMARY_RESUCED_EI_SCREEN_SLUG = "t4_summary_reduced_ei";
export const T4A_LIST_SCREEN_SLUG = "t4a_list";
export const T4A_SETUP_SCREEN_SLUG = "t4a_setup";
export const T4A_SUMMARY_SCREEN_SLUG = "t4a_summary";
export const T5_LIST_SCREEN_SLUG = "t5_list";
export const T5_SETUP_SCREEN_SLUG = "t5_setup";
export const T5_SUMMARY_SCREEN_SLUG = "t5_summary";
export const T3_LIST_SCREEN_SLUG = "t3_list";
export const T3_SETUP_SCREEN_SLUG = "t3_setup";
export const T3_SUMMARY_SCREEN_SLUG = "t3_summary";
export const T3_SETTINGS_SCREEN_SLUG = "t3_settings";
export const RL3_LIST_SCREEN_SLUG = "rl3_list";
export const RL3_SETUP_SCREEN_SLUG = "rl3_setup";
export const RL3_SUMMARY_SCREEN_SLUG = "rl3_summary";
export const RL16_LIST_SCREEN_SLUG = "rl16_list";
export const RL16_SETUP_SCREEN_SLUG = "rl16_setup";
export const RL16_SUMMARY_SCREEN_SLUG = "rl16_summary";
export const RL16_SETTINGS_SCREEN_SLUG = "rl16_settings";
export const RL1_LIST_SCREEN_SLUG = "rl1_list";
export const RL1_SETUP_SCREEN_SLUG = "rl1_setup";
export const RL1_SUMMARY_SCREEN_SLUG = "rl1_summary";
export const RL1_SETTINGS_SCREEN_SLUG = "rl1_settings";
export const EMPLOYEES_REPORT_SCREEN_SLUG = "employees_report";
export const VENDORS_REPORT_SCREEN_SLUG = "vendors_report";
export const T4_REPORT_SCREEN_SLUG = "t4_report";
export const RL1_REPORT_SCREEN_SLUG = "rl1_report";
export const T4A_REPORT_SCREEN_SLUG = "t4a_report";
export const T5_REPORT_SCREEN_SLUG = "t5_report";
export const T3_REPORT_SCREEN_SLUG = "t5_report";
export const RL3_REPORT_SCREEN_SLUG = "rl3_report";
export const RL16_REPORT_SCREEN_SLUG = "rl16_report";
export const INVOICE_REPORT_SCREEN_SLUG = "invoice_report";
export const INVOICE_DETAIL_SCREEN_SLUG = "invoice_detail";
export const ACTIVITY_LOG_REPORT_SCREEN_SLUG = "activity_log_report";
export const API_KEYS_SCREEN_SLUG = "api_keys";
export const DEFAULT_SETTINGS_SCREEN_SLUG = "default-settings";
export const CHANGE_PASSWORD_SCREEN_SLUG = "change_password";
export const INVITE_USER_SCREEN_SLUG = "invite_user";
