import RL3SlipImportXmlLayout from "components/RL3Slip/RL3SlipImportXMLLayout/RL3SlipImportXmlLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL3SlipImportXmlPage {}

export const RL3SlipImportXmlPage: FC<IRL3SlipImportXmlPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import XML RL-3 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl3/list" },
        { text: "RL-3", navigateTo: "/taxslip/rl3/list" },
        {
          text: "Import XML RL-3 Slips",
          navigateTo: "/taxslip/rl3/import-xml",
        },
      ]}
      mainContents={<RL3SlipImportXmlLayout />}
    />
  );
};
