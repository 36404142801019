import { TaxSlipDataInput } from "components/TaxSlipDataInput";

import { Box, Grid, Typography, InputLabel, TextField, } from "@mui/material";
import {
  EnhancedDropDownRHFWithLabel,
  EnhancedIOSSwitchRHFWithLabel,
  LabelPosition,
  RHookFormTextField
} from "@websential/cosmic";
import {
  IOtherInformationSetupFieldsPanel,
  OtherInformationSetupFieldsPanel,
} from "./OtherInformationSetupFieldsPanel";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { GET_QUERY_KEY, QUERY_OPTIONS } from "common/constants";
import { RL1SlipDataCodeService } from "services/common/RL1SlipDataCodeService";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";

// import { RHookFormTextField } from "components/TaxSlipDataInput/RHookFormTextField";
export interface IT4SlipDataPanel extends IOtherInformationSetupFieldsPanel {}

export const T4SlipDataPanel: FC<IT4SlipDataPanel> = ({
  formMethods,
  otherInformationList,
  isLoading,
  testId,
  oiInEdit,
  selectedOtherInformation,
  globalVariablesData
}) => {
  const taxYear = useSelector((state : IAppState) => state.taxYear.year)

  const [validateState, setValidationState] = useState(false);
  // const [boxGValue, setBoxGValue] = useState('');
  const handleInputChange = (e: any) => {
    if(taxYear > 2023){
      const newValue = e.target.value;
      formMethods.setValue('boxGSalaireAdmissibleAuRRQ',parseFloat(Math.min(Number(newValue),  globalVariablesData && globalVariablesData?.data?.rl1.qppSettingsTotalSecondQppPensionableEarningsMaximum).toString()).toFixed(2))
    } else {
    const newValue = e.target.value;
    // let newBoxG = Math.min(Number(newValue), 64900).toString();
    formMethods.setValue('boxGSalaireAdmissibleAuRRQ',parseFloat(Math.min(Number(newValue),  globalVariablesData && globalVariablesData?.data?.rl1.qppSettingsTotalQppPensionableEarningsMaximum).toString()).toFixed(2))
    // setBoxGValue(newBoxG);
    }
  };

  const useGetSlipDataCodeDDL = () => {
    return useQuery(
      GET_QUERY_KEY.RL1_SLIP_DATA_CODE_DDL,
      () => RL1SlipDataCodeService.get(),
      {
        ...QUERY_OPTIONS,
      }
    );
  };
  const { isLoading: isLoadingProvinceDropDownList, data: codeList } =
    useGetSlipDataCodeDDL();

    // const label = [
    //   {label:"A-Revenus d'emploi"},
    //   {label:"B-Cotisation au RRQ"},
    //   {label:"C- Cot. à l'assurance emploi"},
    //   {label:"D- Cotisation à un RPA"},
    //   {label:"E-Impôt du Québec retenu"},
    //   {label:"F-Cotisation syndicale"},
    //   {label:"G-Salaire admissible au RRQ"},
    //   {label:"H-Cotisation au RQAP"},
    //   {label:"I-Salaire admissible au RQAP"},
    //   {label:"J- Régime privé d'ass. maladie"},
    //   {label:"K-Voyages (région éloignée)"},
    //   {label:"L-Autres avantages"},
    //   {label:"M-Commissions"},
    //   {label:"N-Dons de bienfaisance"},
    //   {label:"O-Autres revenus"},
    //   {label:"P- Régime d’ass. interentreprises"},
    //   {label:"Q-Salaires différés"},
    //   {label:"R-Revenu « situé» dans une réserve"},
    //   {label:"S- Pourboires reçus"},
    //   {label:"T-Pourboires attribués"},
    //   {label:"U- Retraite progressive"},
    //   {label:"V-Nourriture et logement"},
    //   {label:"W-Véhicule à moteur"},
    // ]
    // const boxNumber : any = [
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    //   {number:""},
    // ]
    // const actualBox : any = [
    //   {id:"boxARevenusDEmploi", testId:"boxARevenusDEmploi"},
    //   {id:"boxBOtisationAuRRQ", testId:"boxBOtisationAuRRQ"},
    //   {id:"boxCCotaIAssuranceEmploi", testId:"boxCCotaIAssuranceEmploi"},
    //   {id:"boxDCotisationAUnRPA", testId:"boxDCotisationAUnRPA"},
    //   {id:"boxEImpotDuQuebecRetenu", testId:"boxEImpotDuQuebecRetenu"},
    //   {id:"boxFCotisationSyndicale", testId:"boxFCotisationSyndicale"},
    //   {id:"boxGSalaireAdmissibleAuRRQ", testId:"boxGSalaireAdmissibleAuRRQ"},
    //   {id:"boxHCotisationAuRQAP", testId:"boxHCotisationAuRQAP"},
    //   {id:"boxISalaireAdmissibleAuRQAP", testId:"boxISalaireAdmissibleAuRQAP"},
    //   {id:"boxJRegimePriveDassMaladie", testId:"boxJRegimePriveDassMaladie"},
    //   {id:"boxKVoyages", testId:"boxKVoyages"},
    //   {id:"boxLAutresAvantages", testId:"boxLAutresAvantages"},
    //   {id:"boxMCommissions", testId:"boxMCommissions"},
    //   {id:"boxNDonsDeBienfaisance", testId:"boxNDonsDeBienfaisance"},
    //   {id:"boxOAutresRevenus", testId:"boxOAutresRevenus"},
    //   {id:"boxPRegimeDassInterentreprises", testId:"boxPRegimeDassInterentreprises"},
    //   {id:"boxQSalairesDifferes", testId:"boxQSalairesDifferes"},
    //   {id:"boxRRevenuSitueDansUneReserve", testId:"boxRRevenuSitueDansUneReserve"},
    //   {id:"boxSPourboiresRecus", testId:"boxSPourboiresRecus"},
    //   {id:"boxTPourboiresAttribues", testId:"boxTPourboiresAttribues"},
    //   {id:"boxURetraiteProgressive", testId:"boxURetraiteProgressive"},
    //   {id:"boxVNourritureEtLogement", testId:"boxVNourritureEtLogement"},
    //   {id:"boxWVehiculeAMoteur", testId:"boxWVehiculeAMoteur"},
    // ]
    // const adjustedBox : any = [
    //   {id:"boxAAdjRevenusDEmploi", testId:"boxAAdjRevenusDEmploi",visibility:"visible", defaultValue: "0.00"},
    //   {id:"boxBAdjOtisationAuRRQ", testId:"boxBAdjOtisationAuRRQ",visibility:"visible", defaultValue: "0.00"},
    //   {id:"boxCAdjCotaIAssuranceEmploi", testId:"boxCAdjCotaIAssuranceEmploi",visibility:"visible", defaultValue: "0.00"},
    //   {id:"boxDAdjCotisationAUnRPA", testId:"boxDAdjCotisationAUnRPA",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxEAdjImpotDuQuebecRetenu", testId:"boxEAdjImpotDuQuebecRetenu",visibility:"visible", defaultValue: "0.00"},
    //   {id:"boxFAdjCotisationSyndicale", testId:"boxFAdjCotisationSyndicale",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxGAdjSalaireAdmissibleAuRRQ", testId:"boxGAdjSalaireAdmissibleAuRRQ",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxHAdjCotisationAuRQAP", testId:"boxHAdjCotisationAuRQAP",visibility:"visible", defaultValue: "0.00"},
    //   {id:"boxIAdjSalaireAdmissibleAuRQAP", testId:"boxIAdjSalaireAdmissibleAuRQAP",visibility:"visible", defaultValue: "0.00"},
    //   {id:"boxJAdjRegimePriveDassMaladie", testId:"boxJAdjRegimePriveDassMaladie",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxKAdjVoyages", testId:"boxKAdjVoyages",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxLAdjAutresAvantages", testId:"boxLAdjAutresAvantages",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxMAdjCommissions", testId:"boxMAdjCommissions",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxNAdjDonsDeBienfaisance", testId:"boxNAdjDonsDeBienfaisance",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxOAdjAutresRevenus", testId:"boxOAdjAutresRevenus",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxPAdjRegimeDassInterentreprises", testId:"boxPAdjRegimeDassInterentreprises",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxQAdjSalairesDifferes", testId:"boxQAdjSalairesDifferes",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxRAdjRevenuSitueDansUneReserve", testId:"boxRAdjRevenuSitueDansUneReserve",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxSAdjPourboiresRecus", testId:"boxSAdjPourboiresRecus",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxTAdjPourboiresAttribues", testId:"boxTAdjPourboiresAttribues",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxUAdjRetraiteProgressive", testId:"boxUAdjRetraiteProgressive",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxVAdjNourritureEtLogement", testId:"boxVAdjNourritureEtLogement",visibility:"hidden", defaultValue: "0.00"},
    //   {id:"boxWAdjVehiculeAMoteur", testId:"boxWAdjVehiculeAMoteur",visibility:"hidden", defaultValue: "0.00"},
    // ]

    const handleValidateCode = (e:any) => {
      const value = e.target.value
      if(value.length > 0){
      setValidationState(true)
    } else {
      setValidationState(false)
      }
    }

  return (
    <>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <EnhancedIOSSwitchRHFWithLabel
            name="applyAdjustments"
            checked={false}
            testId="applyAdjustments"
            label="Apply Adjustments"
            labelPosition={LabelPosition.Left}
            labelColumns={6}
            fieldColumns={6}
          />
        </Grid> */}
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Actual
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          sx={{ textAlign: "center" }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Adjusted
          </Typography>
        </Grid>
      </Grid>
      <TaxSlipDataInput
        id="boxARevenusDEmploi"
        label="A-Revenus d'emploi"
        boxNumber=""
        onChange={handleInputChange}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxBOtisationAuRRQ"
        label="B-Cotisation au RRQ"
        boxNumber=""
        defaultValue="0.00"
      />
      {taxYear > 2023 ? 
        <TaxSlipDataInput
           id="boxBBOtisationAuRRQ"
           label="B-B-Cotisation au RRQ"
           boxNumber=""
           defaultValue="0.00"
         />:<></>}
      <TaxSlipDataInput
        id="boxCCotaIAssuranceEmploi"
        label="C- Cot. à l'assurance emploi"
        boxNumber=""
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxDCotisationAUnRPA"
        label="D- Cotisation à un RPA"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxEImpotDuQuebecRetenu"
        label="E-Impôt du Québec retenu"
        boxNumber=""
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxFCotisationSyndicale"
        label="F-Cotisation syndicale"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxGSalaireAdmissibleAuRRQ"
        label="G-Salaire admissible au RRQ"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
        // value={boxGValue}
      />
      <TaxSlipDataInput
        id="boxHCotisationAuRQAP"
        label="H-Cotisation au RQAP"
        boxNumber=""
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxISalaireAdmissibleAuRQAP"
        label="I-Salaire admissible au RQAP"
        boxNumber=""
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxJRegimePriveDassMaladie"
        label="J- Régime privé d'ass. maladie"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxKVoyages"
        label="K-Voyages (région éloignée)"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxLAutresAvantages"
        label="L-Autres avantages"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxMCommissions"
        label="M-Commissions"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxNDonsDeBienfaisance"
        label="N-Dons de bienfaisance"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxOAutresRevenus"
        label="O-Autres revenus"
        boxNumber=""
        showAdjustedInput={false}
        onChange={handleValidateCode}
      />
      <TaxSlipDataInput
        id="boxPRegimeDassInterentreprises"
        label="P- Régime d’ass. interentreprises"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxQSalairesDifferes"
        label="Q-Salaires différés"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxRRevenuSitueDansUneReserve"
        label="R-Revenu « situé» dans une réserve"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxSPourboiresRecus"
        label="S- Pourboires reçus"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxTPourboiresAttribues"
        label="T-Pourboires attribués"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      {taxYear >= 2024 ? 
      <></> :
      <TaxSlipDataInput
        id="boxURetraiteProgressive"
        label="U- Retraite progressive"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />}
      <TaxSlipDataInput
        id="boxVNourritureEtLogement"
        label="V-Nourriture et logement"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="boxWVehiculeAMoteur"
        label="W-Véhicule à moteur"
        boxNumber=""
        showAdjustedInput={false}
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="code"
        label="Code (Case O)"
        boxNumber=""
        ddlField={true}
        ddlFieldItems={codeList}
        requiredDDL={validateState}
      />

{/* <Grid container columnSpacing={2} xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item   xs={4}  sm={4}  md={4} lg={4}  xl={4} sx={{ textAlign: "left", paddingTop: 2, mb:"2rem" }}>
        {label.map((x:any,i:any) => {
          return (
            <InputLabel sx={{ mb:"1.9rem" }}>
              <Typography variant="subtitle2" key={i}>{x.label}</Typography>
            </InputLabel>
          )
        })}
      </Grid>
      <Grid item xs={2}  sm={2} md={2} lg={2} xl={2} sx={{ textAlign: "right", paddingTop: 2 }}>
      {boxNumber.map((x:any,i:any) => {
          return (
            <InputLabel sx={{ mb:"1.9rem" }}>
              <Typography variant="subtitle2" key={i}>{x.number}</Typography>
            </InputLabel>
          )
        })}
      </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
      {actualBox.map((x:any,i:any) => {
          return (
            <RHookFormTextField 
              key={i} 
              id={x.id} 
              testId={x.testId} 
            />
          )
        })}
      </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
      {adjustedBox.map((x:any,i:any) => {
        console.log("visibility",x.visibility)
          return (
            <Box sx={{visibility:x.visibility}}>
              <RHookFormTextField id={x.id}  testId={x.testId} readOnly  defaultValue={x.defaultValue} />
            </Box>
          )
        })}
      </Grid>
    </Grid> */}

      {/* <Box
        sx={{
          "& .css-5sd1dn": {
            maxWidth: "9rem",
            margin: "0 6.5rem 0 6.5rem",
          },
        }}
      >
        <EnhancedDropDownRHFWithLabel
          label="Code (Case O)"
          selectName="code"
          items={codeList ?? []}
          testId="code"
          labelPosition={LabelPosition.Left}
          required={validateState}
        />
      </Box> */}

      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ mt: 2, p: 4, backgroundColor: "#f4f5f7" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold", pb: 1 }}>
            Other Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OtherInformationSetupFieldsPanel
            formMethods={formMethods}
            isLoading={isLoading}
            otherInformationList={otherInformationList}
            testId={testId}
            oiInEdit={oiInEdit}
            selectedOtherInformation={selectedOtherInformation}
            globalVariablesData={undefined}
          />
        </Grid>
      </Grid>
    </>
  );
};
