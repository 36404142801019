import { AxiosResponse } from 'axios';
import http from 'common/http-common';
import { IGetListRequest, IResponse, IServerError } from 'models/common';
import { IDropDownListResponse, IFlexFieldListModel } from 'models/flexField';
import { IFlexFieldSetupRequest } from 'models/flexField/IFlexFieldSetupRequest';
import { IFlexFieldSetupResponse } from 'models/flexField/IFlexFieldSetupResponse';

export const FlexFieldService = {
  getFlexFieldDropDownList: async (filter: string): Promise<IDropDownListResponse[] | IServerError | IResponse> => {
    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<IDropDownListResponse[]>(
      `/v1/flex-fields/ddl-list?filter=${filter}`);

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getFlexFieldById: async (fieldId: number): Promise<IFlexFieldSetupResponse | IServerError | IResponse> => {
    const response: AxiosResponse<IFlexFieldSetupResponse> = await http.get<IFlexFieldSetupResponse>(
      `/v1/flex-fields/${fieldId}/`);

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };

    //@ts-ignore
    // return new Promise(resolve => setTimeout(() => resolve(flexFields.find((flexField) => flexField.id === fieldId)), 1000));
  },
  submitFlexField: async (requestPayload: IFlexFieldSetupRequest): Promise<IFlexFieldSetupResponse | IServerError | IResponse> => {
    // let response: IFlexFieldSetupResponse;
    // // if it's edit
    // if (requestPayload.id) {
    //   // @ts-ignore
    //   response = flexFields.find((flexField) => flexField.id === requestPayload.id);
    // } else {
    //   // return the first field for now until BE is ready.
    //   response = flexFields[0];
    // }
    console.log("requestPayload t4 setup : ",requestPayload)
    if (requestPayload.id) {
      console.log('requestPayload ==>> update request', requestPayload);
      const response: AxiosResponse<IFlexFieldSetupResponse> = await http.put<IFlexFieldSetupResponse>(
        `/v1/flex-fields/${requestPayload.id}/`, requestPayload);

      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }

    console.log('requestPayload ==>> add request', requestPayload);
    const response: AxiosResponse<IFlexFieldSetupResponse> = await http.post<IFlexFieldSetupResponse>(
      '/v1/flex-fields/', requestPayload);

    if (response.status === 201) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
    // // TODO: when replacing this with the API and it's edit mode, make sure the ID is included so that we can invalidate the cache key of that ID
    // return new Promise(resolve => setTimeout(() => resolve(response), 1000));
  },
  getList: async (params: IGetListRequest): Promise<IFlexFieldListModel[] | IServerError | IResponse> => {
    const response: AxiosResponse<IFlexFieldListModel[]> = await http.get<IFlexFieldListModel[]>(
      '/v1/flex-fields/');

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  deleteFlexFieldById: async (fieldId: number): Promise<string | IServerError | IResponse> => {
    console.log('deleteFlexFieldById', fieldId);
    // return new Promise(resolve => setTimeout(() => resolve("Flex Field has been deleted successfully"), 1000));
    const response: AxiosResponse<string> = await http.delete<string>(`/v1/flex-fields/${fieldId}/`);

    if (response.status === 204) {
      return "Flex Field has been deleted successfully";
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  deleteBulkFlexFields: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    console.log("requestPayload", typeof requestPayload);
    const response = await http.delete<string>(`/v1/flex-fields/`, {
      data: {"flexFieldIds":requestPayload.flexFieldIds}
    });
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    console.log("REsponse ?????????????????????????????????????", response);
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
};
