import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { useParams } from "react-router-dom";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { ResourceSetup } from "components/Resource";

export const ResourceSetupPage = () => {
  const { resourceName } = useParams();
  return (
    <AuthenticatedLayout
      pageTitle={`${resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName === "T3_Beneficiary" ? "T3 Beneficiary" : resourceName} Setup`}
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: `${resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName === "T3_Beneficiary" ? "T3 Beneficiary" : resourceName}`, navigateTo: `/resources/${resourceName}` },
        {
          text: `Add ${resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName === "T3_Beneficiary" ? "T3 Beneficiary" : resourceName}`,
          navigateTo: `/resources/${resourceName}/setup`,
        },
      ]}
      mainContents={<ResourceSetup />}
    />
  );
};
