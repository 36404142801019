import {
  DATE_FORMAT_API_PAYLOAD,
  GET_QUERY_KEY,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  TAX_PROFILE_LIST_SCREEN_SLUG,
  TAX_PROFILE_SETUP_SCREEN_SLUG,
} from "common/constants";
import dayjs from "dayjs";
// import {
//   IDataModelResponsePayload,
//   IResponse,
//   IServerError,
// } from "models/common";
import { IProvinceReferenceModel } from "models/province/IProvinceReferenceModel";
import { ITaxProfileSetupRequest } from "models/taxProfileSetup/ITaxProfileSetupRequest";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ProvinceService } from "services/common/ProvinceService";
import { TaxProfileService } from "services/TaxProfileService";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BackdropCircularProgress,
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  RHookFormDatePicker,
  RHookFormTextFieldWithLabel,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
} from "@websential/cosmic";

import { TaxProfileSetupSchema } from "./validator";
import { Grid } from "@mui/material";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch } from "react-redux";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface ITaxProfileSetup extends ITest {}

const useGetProvinceDDL = () => {
  return useQuery(GET_QUERY_KEY.PROVINCE_DDL, () => ProvinceService.getOld(), {
    ...QUERY_OPTIONS,
  });
};

// const useGetById = (id: string | undefined) => {
//   return useQuery(
//     GET_QUERY_KEY.GET_TAX_PROFILE_BY_ID,
//     () => TaxProfileService.getById(id),
//     QUERY_OPTIONS
//   );
// };

const useGetById = (id: string | undefined) =>
  useQuery(
    [GET_QUERY_KEY.GET_TAX_PROFILE_BY_ID, id],
    (data) => {
      return TaxProfileService.getById(id);
    },
    { refetchOnWindowFocus: false }
  );

export const TaxProfileSetup: FC<ITaxProfileSetup> = ({ testId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [selectedProvince, setSelectedProvince] =
    useState<IProvinceReferenceModel>({
      id: null,
      name: "",
      shortCode: "",
    });
  const { id } = useParams();
  const [editRecordId, setEditRecordId] = useState(id);

  const formMethods = useForm<ITaxProfileSetupRequest>({
    resolver: yupResolver(TaxProfileSetupSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    TaxProfileService.create,
    {
      onSuccess: (res: any) => {
        let message = "Not a valid response message from API";
        if (res.success) {
          message = String(res.message);
          setEditRecordId(res.data.id);
        } else {
          message = String(res.data.message);
        }

        setSnackbarData({
          open: true,
          message: message,
          severity: res.success ? "success" : "error",
        });
        let path = "/tax-profile/list";
        navigate(path);
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const { isLoading: isLoadingProvinceDropDownList, data: provinceList } =
    useGetProvinceDDL();

  console.log("provinceList", provinceList);

  const { isLoading: isLoadingEditData, data: editDataRecord } =
    useGetById(editRecordId);

  const onSubmit = (formData: any) => {
    console.log("===-formData", formData);
    const requestPayload: ITaxProfileSetupRequest = {
      id: editRecordId,
      province: selectedProvince,
      effectiveDate: dayjs(formData.effectiveDate).format(
        DATE_FORMAT_API_PAYLOAD
      ),
      expiryDate: formData.expiryDate
        ? dayjs(formData.expiryDate).format(DATE_FORMAT_API_PAYLOAD)
        : formData.expiryDate,
      taxLabel: formData.taxLabel,
      taxPercent: formData.taxPercent,
    };

    console.log("===-requestPayload", requestPayload);

    submitFormData(requestPayload);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset();
    let path = `/tax-profile/setup`;
    navigate(path);
  };

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const onProvinceSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
    setSelectedProvince({
      id: item.id,
      name: item.label,
      shortCode: String(item.value),
    });
  };

  // useEffect(() => {
  //   console.log("===-use Effect called");
  //   // if the selected flex field data has loaded.
  //   if (editDataRecord && editDataRecord.success && editDataRecord.data) {
  //     console.log("===-editDataRecord", editDataRecord.data);
  //     Object.keys(editDataRecord.data).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = editDataRecord.data[fieldName];
  //       console.log(
  //         "===-fieldValue",
  //         fieldValue,
  //         "typeof fieldValue: ",
  //         typeof fieldValue
  //       );

  //       if (typeof fieldValue !== "object") {
  //         // @ts-ignore
  //         formMethods.setValue(fieldName, fieldValue ?? undefined);
  //       } else {
  //         // @ts-ignore
  //         formMethods.setValue(fieldName, fieldValue.shortCode ?? undefined);
  //       }
  //     });
  //   }
  // }, [editDataRecord, formMethods.setValue, formMethods]);
  // for edit
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (editDataRecord) {
      Object.keys(editDataRecord).forEach((fieldName) => {
        console.log("fieldName useEffect tax profile setup : ", fieldName);
        // @ts-ignore
        const fieldValue = editDataRecord[fieldName];
        console.log("fieldValue useEffect tax profile setup : ", fieldValue);
        // @ts-ignore
        // formMethods.setValue(fieldName, fieldValue ?? undefined);
        if (typeof fieldValue !== "object") {
          // @ts-ignore
          formMethods.setValue(fieldName, fieldValue ?? undefined);
        } else if (fieldValue !== null || undefined) {
          // @ts-ignore
          formMethods.setValue(fieldName, fieldValue.shortCode ?? undefined);
          setSelectedProvince(fieldValue);
        }
      });
    }
  }, [editDataRecord, formMethods.setValue, formMethods]);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: TAX_PROFILE_SETUP_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={
          isLoadingProvinceDropDownList || isSubmitting || isLoadingEditData
        }
        testId={testId}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            toolbar={
              <StandardSubmitFormButtonsToolbar
                onResetClicked={onResetClicked}
                isSubmitting={false}
                testId="tax-profile-setup-toolbar"
              />
            }
            children={
              <StandardPanel
                title="Add Tax Profile"
                testId="tax-profile-setup-panel"
                leftSpacing={2}
                rightSpacing={2}
              >
                <EnhancedDropDownRHFWithLabel
                  selectName="province"
                  label="Province"
                  selectedItem={selectedProvince.shortCode}
                  labelPosition={LabelPosition.Left}
                  required
                  testId={"tax-profile-province-list"}
                  items={provinceList ?? []}
                  onSelectionChangeHandler={onProvinceSelectionChangeHandler}
                />
                <RHookFormDatePicker
                  name="effectiveDate"
                  labelPosition={LabelPosition.Left}
                  testId="effective-date"
                  required
                  label="Effective Date"
                />
                <RHookFormDatePicker
                  name="expiryDate"
                  labelPosition={LabelPosition.Left}
                  testId="expiry-date"
                  label={"Expiry Date"}
                />
                <RHookFormTextFieldWithLabel
                  id="taxLabel"
                  testId={`${testId}-tax-label`}
                  label="Tax Label"
                  required
                  labelPosition={LabelPosition.Left}
                />
                <RHookFormTextFieldWithLabel
                  id="taxPercent"
                  testId={`${testId}-tax-percentage`}
                  label="Tax %"
                  required
                  labelPosition={LabelPosition.Left}
                />
              </StandardPanel>
            }
            testId="tax-profile-setup-grid"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
