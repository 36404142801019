import { LeftMenu } from "components/LeftMenu";

const billingRoutes = [
  {
    id: 710,
    text: "Profile",
    open: false,
    menu: [
      {
        id: 711,
        text: "List",
        parentMenuId: 710,
        grandParentMenuId: 700,
        navigateToURL: "/billing-profile/list"
      },
      {
        id: 712,
        text: "Setup",
        parentMenuId: 710,
        grandParentMenuId: 700,
        navigateToURL: "/billing-profile/setup"
      }
    ],
    parentMenuId: 700
  },
  {
    id: 720,
    text: "Tax",
    open: false,
    menu: [
      {
        id: 721,
        text: "List",
        parentMenuId: 720,
        grandParentMenuId: 700,
        navigateToURL: "/tax-profile/list"
      },
      {
        id: 722,
        text: "Setup",
        parentMenuId: 720,
        grandParentMenuId: 700,
        navigateToURL: "/tax-profile/setup"
      }
    ],
    parentMenuId: 700
  },
  {
    id: 730,
    text: "Profile Associate",
    open: false,
    parentMenuId: 700,
    navigateToURL: "/billing-profile-association"
  }
];

export const BillingMenu = () => {
  return <LeftMenu menuItems={billingRoutes} />;
};
