import { FC, useEffect, useState } from "react";

import { RL1SlipSummaryDataInput } from "../../RL1SlipSummaryDataInput";
import { UseFormReturn } from "react-hook-form";

export interface IEligibleTrainingExpendituresPanel {
  totalPayroll:number;
  formMethods:any;
  amountReducedGetById:any;
}
export const EligibleTrainingExpendituresPanel: FC<IEligibleTrainingExpendituresPanel> =
  ({totalPayroll,formMethods,amountReducedGetById}) => {
    const [sumTotal, setSumTotal] = useState("0.00")
    const [finalTotal, setFinalTotal] = useState("0.00")
    const [subtractedValue, setSubtractedValue] = useState("0.00")
    
    const handleOnChange = (e:any) => {
      const value = e.target.value
      let eligibleTrainingExpendituresForTheCurrentYear = parseFloat(formMethods.getValues("eligibleTrainingExpendituresForTheCurrentYear").replace(/[$,]/g, ''))
      console.log("eligibleTrainingExpendituresForTheCurrentYear",eligibleTrainingExpendituresForTheCurrentYear)
      if(eligibleTrainingExpendituresForTheCurrentYear !== null){
        console.log("value88",value)
        const balance = parseFloat(value) + eligibleTrainingExpendituresForTheCurrentYear
        console.log("balance",balance)
        if(!isNaN(balance)){
        let convertToString = balance.toString()
        console.log("convertToString",convertToString)
        formMethods.setValue('balanceWc',parseFloat(convertToString).toFixed(2))
        setSumTotal(parseFloat(convertToString).toFixed(2))
        setFinalTotal(parseFloat(convertToString).toFixed(2))
      }else{
        formMethods.setValue('balanceWc',eligibleTrainingExpendituresForTheCurrentYear)
      }
    } else {
      console.log("else67")
      if(value !== ''){
        formMethods.setValue('balanceWc',parseFloat(value).toFixed(2))
      } else {formMethods.setValue('balanceWc',"0.00")}
    }  
  }
  const OnEligibleTrainingChangehandler = (e:any) => {
    const actualValue = e.target.value
    let balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears = parseFloat(formMethods.getValues("balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears").replace(/[$,]/g, ''))
    if(balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears){
      const balance = balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears + parseFloat(actualValue)
      if(!isNaN(balance)){
        let convertToString = balance.toString()
        formMethods.setValue('balanceWc',parseFloat(convertToString).toFixed(2))
        setSumTotal(parseFloat(convertToString).toFixed(2))
        setFinalTotal(parseFloat(convertToString).toFixed(2))
      }else{
        formMethods.setValue('balanceWc',balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears)
      }
    } else {
      if(actualValue !== ''){
        formMethods.setValue('balanceWc',parseFloat(actualValue).toFixed(2))
      } else {formMethods.setValue('balanceWc',"0.00")}
    }
    }
    const OnAmountReducedChangehandler = (e:any) => {
      const editedValue = e.target.value
      setSubtractedValue(editedValue)
      let balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears = parseFloat(formMethods.getValues("balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears").replace(/[$,]/g, ''))
      let eligibleTrainingExpendituresForTheCurrentYear = formMethods.getValues("eligibleTrainingExpendituresForTheCurrentYear")
      const balance = balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears + parseFloat(eligibleTrainingExpendituresForTheCurrentYear.replace(/[$,]/g, ''))
      console.log("balancebalance",balance)
      console.log("balancebalance",parseFloat(eligibleTrainingExpendituresForTheCurrentYear.replace(/[$,]/g, '')))
      if(!isNaN(balance)){
        let convertToString = balance.toString()
        setSumTotal(parseFloat(convertToString).toFixed(2))
        const finalBalance = parseFloat(convertToString) - editedValue
        if(!isNaN(finalBalance)){
          const finalBalanceCTS = finalBalance.toString()
          // alert(typeof(finalBalanceCTS))
          formMethods.setValue('balanceWc',parseFloat(finalBalanceCTS).toFixed(2))
          setFinalTotal(parseFloat(finalBalanceCTS).toFixed(2))
        } else {
          formMethods.setValue('balanceWc',balance)
        }
      }else{
        formMethods.setValue('balanceWc',balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears)
      }
      
    }

    useEffect(() => {
      if (totalPayroll === 0) {
        console.log("totalPayroll",totalPayroll)
        setSubtractedValue("0.00");
        let balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears = formMethods.getValues("balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears") && parseFloat(formMethods.getValues("balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears").replace(/[$,]/g, ''))
        let eligibleTrainingExpendituresForTheCurrentYear = formMethods.getValues("eligibleTrainingExpendituresForTheCurrentYear") && parseFloat(formMethods.getValues("eligibleTrainingExpendituresForTheCurrentYear").replace(/[$,]/g, ''))
        console.log("balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears",balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears)
        console.log("eligibleTrainingExpendituresForTheCurrentYear",eligibleTrainingExpendituresForTheCurrentYear)
        if(balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears && eligibleTrainingExpendituresForTheCurrentYear){
          const finalTotal = balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears  + eligibleTrainingExpendituresForTheCurrentYear 
          console.log("finalTotal",finalTotal)
            let convertTotalToStr = finalTotal.toString()
            setFinalTotal(parseFloat(convertTotalToStr).toFixed(2))
          }
        }
    }, [totalPayroll]);



    return (
      <>
        <RL1SlipSummaryDataInput
          id="balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears"
          label="Balance of eligible training expenditures carried forward from previous years"
          readOnly={false}
          onChange={handleOnChange}
        />
        <RL1SlipSummaryDataInput
          id="eligibleTrainingExpendituresForTheCurrentYear"
          label="Eligible training expenditures for the current year"
          readOnly={false}
          onChange={OnEligibleTrainingChangehandler}
        />
        <RL1SlipSummaryDataInput
          id="amountReduced"
          label="Amount used to reduce or cancel the contribution on line 51. Carry this amount to line 52"
          alert={true}
          alertTitle = 'Field will be disabled when WSDRF Amount is 0'     
          titlePlacement = 'top'
          readOnly={totalPayroll !== 0 ? false : true}
          value={amountReducedGetById ? parseFloat(amountReducedGetById).toFixed(2) : totalPayroll !== 0 ? subtractedValue : "0.00"}
          onChange={OnAmountReducedChangehandler}
        />
        <RL1SlipSummaryDataInput id="balanceWc" label="Balance" 
        value={totalPayroll !== 0 ? finalTotal : sumTotal}
        />
      </>
    );
  };
