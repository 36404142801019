import { EARNINGS_AUDIT_SCREEN_ID, EARNINGS_AUDIT_SCREEN_SLUG, INITIAL_PAGE_SIZE, INITIAL_SNACKBAR_DATA, QUERY_OPTIONS, ROWS_PER_PAGE_PER_OPTIONS } from "common/constants";
import { FC, useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";

import { Box, Grid, useTheme } from "@mui/material";
import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  ITest,
} from "@websential/cosmic";

import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { PrimeDataGrid } from "components/PrimeDataGrid";
import { IGetListRequest } from "models";
import { Button } from "primereact/button";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import { IAdjustmentOptionsModel } from "models/adjustmentOptions";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IEarningsAuditSetup extends ITest {}

const GET_AUDIT_FILING_QUERY_KEY_FOR_EI = "get-audit-filing-for-ei";
const GET_AUDIT_FILING_QUERY_KEY_FOR_CPP = "get-audit-filing-for-cpp";

const useAuditEarningsForEI = (clientId: string,taxYear:number,params:IGetListRequest) =>
  useQuery(
    [GET_AUDIT_FILING_QUERY_KEY_FOR_EI],
    (data) => {
      return AdjustmentOptionsService.getAuditEarningsForEI(clientId,taxYear,params);
    },
    { refetchOnWindowFocus: false }
  );
const useAuditEarningsForCPP = (clientId: string,taxYear:number,params:IGetListRequest) =>
  useQuery(
    [GET_AUDIT_FILING_QUERY_KEY_FOR_CPP],
    (data) => {
      return AdjustmentOptionsService.getAuditEarningsForCPP(clientId,taxYear,params);
    },
    { refetchOnWindowFocus: false }
  );

  
const GET_ADJUSTMENTS_QUERY_KEY = "get-adjustments";

  const useAdjustments = (clientId: string) =>
  useQuery(
    GET_ADJUSTMENTS_QUERY_KEY,
    () => AdjustmentOptionsService.getAdjustmentOptionsByClientId(clientId),
    QUERY_OPTIONS
  );

const eIInsurableEarningsHeaderColumns = [
  {
    "field": "name",
    "headerName": "Employee Name",
    "description": "Employee Name",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "text",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 0,
    "frozenColumn": true,
    "footer": null,
    "minWidth": null,
    "alignFrozen": null,
  },
  {
    "field": "employmentIncome",
    "headerName": "Employment Income",
    "description": "Employment Income",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "text",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 0,
    "frozenColumn": false,
    "footer": null,
    "minWidth": null,
    "alignFrozen": null
  },
  {
    "field": "eiInsurableEarnings",
    "headerName": "EI Insurable Earnings",
    "description": "EI Insurable Earnings",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "text",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 0,
    "frozenColumn": false,
    "footer": null,
    "minWidth": null,
    "alignFrozen": null
  },
  {
    "field": "dateOfBirth",
    "headerName": "Date of Birth",
    "description": "Date of Birth",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "text",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 0,
    "frozenColumn": false,
    "footer": null,
    "minWidth": null,
    "alignFrozen": null
  },
  {
    "field": "eiExempt",
    "headerName": "EI Exempt",
    "description": "EI Exempt",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "text",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 0,
    "frozenColumn": false,
    "footer": null,
    "minWidth": null,
    "alignFrozen": null
  },
  {
    "field": "ignoredEiAuditEarning",
    "headerName": "Ignore",
    "description": "Ignore",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "text",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 0,
    "frozenColumn": false,
    "footer": null,
    "minWidth": null,
    "alignFrozen": null
  },
]

const cppPensionableEarningsHeaderColumns = [
  {
    "field": "name",
    "headerName": "Employee Name",
    "description": "Employee Name",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "text",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 0,
    "frozenColumn": true,
    "footer": null,
    "minWidth": null,
    "alignFrozen": null
  },
  {
    "field": "employmentIncome",
    "headerName": "Employment Income",
    "description": "Employment Income",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "text",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 0,
    "frozenColumn": false,
    "footer": null,
    "minWidth": null,
    "alignFrozen": null
  },
  {
    "field": "cppQppPensionableEarnings",
    "headerName": "CPP Pensionable Earnings",
    "description": "CPP Pensionable Earnings",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "text",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 0,
    "frozenColumn": false,
    "footer": null,
    "minWidth": null,
    "alignFrozen": null
  },
  {
    "field": "dateOfBirth",
    "headerName": "Date of Birth",
    "description": "Date of Birth",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "text",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 0,
    "frozenColumn": false,
    "footer": null,
    "minWidth": null,
    "alignFrozen": null
  },
  {
    "field": "cppQppExempt",
    "headerName": "CPP Exempt",
    "description": "CPP Exempt",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "text",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 0,
    "frozenColumn": false,
    "footer": null,
    "minWidth": null,
    "alignFrozen": null
  },
  {
    "field": "ignoredCppAuditEarning",
    "headerName": "Ignore",
    "description": "Ignore",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "text",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 0,
    "frozenColumn": false,
    "footer": null,
    "minWidth": null,
    "alignFrozen": null
  },
]

export const EarningsAuditSetup: FC<IEarningsAuditSetup> = ({ testId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientName = useSelector((state : IAppState) => state.client.clientName);
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const isLoadingActivityLogData = useSelector((state: IAppState) => state.isLoadAxiosCall.isLoading);
  // const [auditFilingData, setAuditFilingData] = useState<any>(undefined);
  const [updatedEIInsurableEarningsItems, setupdatedEIInsurableEarningsItems] = useState<any>(undefined);
  const [totalEIitems, setTotalEIitems] = useState<any>(undefined);
  const [totalCPPitems, setTotalCPPitems] = useState<any>(undefined);
  const [updatedCPPPensionableEarningsItems, setupdatedCPPPensionableEarningsItems] = useState<any>(undefined);
  const [selectedItemsOfEIInsurableEarnings, setSelectedItemsOfEIInsurableEarnings] = useState<any>([]);
  const [selectedItemsOfCPPPensionableEarnings, setSelectedItemsOfCPPPensionableEarnings] = useState<any>([]);
  const [queryParamsOfEIInsurableEarnings, setQueryParamsOfEIInsurableEarnings] = useState<IGetListRequest>({
    search_query: "",
    order_by: "name1",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });
  const [queryParamsOfCPPPensionableEarnings, setQueryParamsOfCPPPensionableEarnings] = useState<IGetListRequest>({
    search_query: "",
    order_by: "name1",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

    const { isLoading: isLoadingAdjustments, data: adjustments } =
    useAdjustments(clientId);
    let AdjustmentOptionsData : any = adjustments
console.log("adjustments",adjustments)
  const { isLoading: isLoadingEIInsurableEarningsAudit, data: eIInsurableEarningsAuditData, refetch: refetchEI } =
  useAuditEarningsForEI(clientId,taxYear,queryParamsOfEIInsurableEarnings);
  const { isLoading: isLoadingCPPPensionableEarningsAudit, data: CPPPensionableEarningsAuditData, refetch: refetchCPP } =
  useAuditEarningsForCPP(clientId,taxYear,queryParamsOfCPPPensionableEarnings);

  console.log("eIInsurableEarningsAuditData",eIInsurableEarningsAuditData)

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
  useEffect(()=>{
    if(isLoadingEIInsurableEarningsAudit){
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },3000)
    } else {
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },3000)
    }
    if(isLoadingCPPPensionableEarningsAudit){
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },3000)
    } else {
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },3000)
    }
  },[isLoadingEIInsurableEarningsAudit,isLoadingCPPPensionableEarningsAudit])

const onRequestParamsOfEIInsurableEarningsChange = useCallback((requestParams) => {
  let dispatchParams = {
    search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
    order_by: "name1",
    order_direction: requestParams.sortOrder ? requestParams.sortOrder : "asc",
    page: requestParams.page + 1,
    size: requestParams.rows,
  };
  setQueryParamsOfEIInsurableEarnings(dispatchParams);
}, []);

const onRequestParamsOfCPPPensionableEarningsChange = useCallback((requestParams) => {
  let dispatchParams = {
    search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
    order_by: "name1",
    order_direction: requestParams.sortOrder ? requestParams.sortOrder : "asc",
    page: requestParams.page + 1,
    size: requestParams.rows,
  };
  setQueryParamsOfCPPPensionableEarnings(dispatchParams);
}, []);

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };
  
  const onIgnoreEiInsurableButtonClickHandler = async (): Promise<void> => {
    if(selectedItemsOfEIInsurableEarnings.length > 0){
    try {
      setIsLoadingData(true)
      const ids_list = selectedItemsOfEIInsurableEarnings.map((ids: any) => `t4_slip_ids=${ids}`).join('&');
      const finalEIIds: any = ids_list.replace(/^&+|&+$/g, '');
      const  result = await AdjustmentOptionsService.ignoreEIAuditEarnings(clientId,taxYear,finalEIIds);
      if(result && result.status === 200){
        setSnackbarData({
          open: true, 
          message: result.data.message,
          severity: "success",
        });
        refetchEI()
      }else{
        setSnackbarData({
          open: true,
          message: result && result.data.message ,
          severity: result.status === false ? "error" : 
          result.success === false ? "error" : "success",
        });
      }
    } catch (err) {
      alert(err)
    } finally {
      setIsLoadingData(false)
    }
  }else {
    setSnackbarData({
      open: true,
      message: "Please select atleat one record!",
      severity: "error",
    });
  }
  }
  const onUndoIgnoreEiInsurableButtonClickHandler = async (): Promise<void> => {
    if(selectedItemsOfEIInsurableEarnings.length > 0){
      try {
      setIsLoadingData(true)
      const ids_list = selectedItemsOfEIInsurableEarnings.map((ids: any) => `t4_slip_ids=${ids}`).join('&');
      const finalEIIds: any = ids_list.replace(/^&+|&+$/g, '');
      const  result = await AdjustmentOptionsService.undoIgnoreEIAuditEarnings(clientId,taxYear,finalEIIds);
      if(result && result.status === 200){
        setSnackbarData({
          open: true, 
          message: result.data.message,
          severity: "success",
        });
        refetchEI()
      }else{
        setSnackbarData({
          open: true,
          message: result && result.data.message ,
          severity: result.status === false ? "error" : 
          result.success === false ? "error" : "success",
        });
      }
    } catch (err) {
      alert(err)
    } finally {
      setIsLoadingData(false)
    }
  }else {
    setSnackbarData({
      open: true,
      message: "Please select atleat one record!",
      severity: "error",
    });
  }
  }
  const onIgnorePensionableEarningsButtonClickHandler = async (): Promise<void> => {
    if(selectedItemsOfCPPPensionableEarnings.length > 0){
    try {
      setIsLoadingData(true)
      const ids_list = selectedItemsOfCPPPensionableEarnings.map((ids: any) => `t4_slip_ids=${ids}`).join('&');
      const finalEIIds: any = ids_list.replace(/^&+|&+$/g, '');
      const  result = await AdjustmentOptionsService.ignoreCPPAuditEarnings(clientId,taxYear,finalEIIds);
      if(result && result.status === 200){
        setSnackbarData({
          open: true, 
          message: result.data.message,
          severity: "success",
        });
        refetchCPP()
      }else{
        setSnackbarData({
          open: true,
          message: result && result.data.message ,
          severity: result.status === false ? "error" : 
          result.success === false ? "error" : "success",
        });
      }
    } catch (err) {
      alert(err)
    } finally {
      setIsLoadingData(false)
    }
  }else {
    setSnackbarData({
      open: true,
      message: "Please select atleat one record!",
      severity: "error",
    });
  }
  }
  const onUndoIgnorePensionableEarningsButtonClickHandler = async (): Promise<void> => {
  if(selectedItemsOfCPPPensionableEarnings.length > 0){
  try {
    setIsLoadingData(true)
    const ids_list = selectedItemsOfCPPPensionableEarnings.map((ids: any) => `t4_slip_ids=${ids}`).join('&');
    const finalEIIds: any = ids_list.replace(/^&+|&+$/g, '');
    const  result = await AdjustmentOptionsService.undoIgnoreCPPAuditEarnings(clientId,taxYear,finalEIIds);
    if(result && result.status === 200){
      setSnackbarData({
        open: true, 
        message: result.data.message,
        severity: "success",
      });
      refetchCPP()
    }else{
      setSnackbarData({
        open: true,
        message: result && result.data.message,
        severity: result.status === false ? "error" : 
        result.success === false ? "error" : "success",
      });
    }
  } catch (err) {
    alert(err)
  } finally {
    setIsLoadingData(false)
  }
}else {
  setSnackbarData({
    open: true,
    message: "Please select atleat one record!",
    severity: "error",
  });
}
  }
  const footerOfEIInsurableEarnings =() => {
    return (
    <div className="flex flex-wrap justify-content-center gap-2">
      <Button
        label="Ignore"
        icon="pi pi-times"
        severity="info"
        size="small"
        raised
        onClick={onIgnoreEiInsurableButtonClickHandler}
        disabled={AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? true : false}
        tooltip={AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? "Remittance has been transferred." : ""} 
        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
        />
      <Button
        label="Undo Ignore"
        icon="pi pi-undo"
        severity="secondary"
        size="small"
        // text
        raised 
        onClick={onUndoIgnoreEiInsurableButtonClickHandler}
        disabled={AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? true : false}
        tooltip={AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? "Remittance has been transferred." : ""} 
        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
      />
    </div>
  );
  }
  const footerOfCPPPensionableEarnings =() => {
    return (
    <div className="flex flex-wrap justify-content-center gap-2">
          <Button
            type="button"
            label="Ignore"
            icon="pi pi-times"
            severity="info"
            size="small"
            raised
            onClick={onIgnorePensionableEarningsButtonClickHandler}
            disabled={AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? true : false}
            tooltip={AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? "Remittance has been transferred." : ""} 
            tooltipOptions={{ showOnDisabled: true, position: 'top' }}          
            />
      <Button
        label="Undo Ignore"
        icon="pi pi-undo"
        severity="secondary"
        size="small"
        // text
        raised 
        onClick={onUndoIgnorePensionableEarningsButtonClickHandler}
        disabled={AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? true : false}
        tooltip={AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? "Remittance has been transferred." : ""} 
        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
      />
    </div>
  );
  }

  const formatDate = (datee: any) => {
    const originalDate = new Date(datee);
    const formattedDate = format(originalDate, "MMM dd, yyyy");

    return formattedDate;
  };

  const onClickHandler = (clickedRow: any) => {
    setIsLoadingData(true)
    setTimeout(()=>{
      setIsLoadingData(false)
    },2000)
    let path = `/taxslip/t4/setup/${clickedRow}`;
    navigate(path);
  };

  useEffect(()=>{
    if(eIInsurableEarningsAuditData && eIInsurableEarningsAuditData.status !== 404 && eIInsurableEarningsAuditData.status !== 401){
      const dataWithoutTotalObject = eIInsurableEarningsAuditData && eIInsurableEarningsAuditData.slice(0, -1);
      const updatedItemsResponseEI = eIInsurableEarningsAuditData && eIInsurableEarningsAuditData.map((item:any) => ({
        ...item,
        employmentIncome: item.employmentIncome && item.employmentIncome !== null ? parseFloat(item.employmentIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00',
        eiInsurableEarnings: item.eiInsurableEarnings && item.eiInsurableEarnings !== null ? parseFloat(item.eiInsurableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00',
        ignoredEiAuditEarning: item.ignoredEiAuditEarning ? <i className="pi pi-check" style={{ color: 'green' }}></i> : <i className="pi pi-times" style={{ color: 'red' }}></i>,
        dateOfBirth: (item.dateOfBirth !== "" && item.dateOfBirth !== null && item.dateOfBirth && item.dateOfBirth.charAt(3) !== "*") ? formatDate(item.dateOfBirth) : item.dateOfBirth,
        name: (
          <Box
            style={{ color: "blue",cursor:"pointer" }}
            onClick={() => onClickHandler(item.id)}
          >
            {item.name}
          </Box>
        ),
      }));
      setupdatedEIInsurableEarningsItems(updatedItemsResponseEI)
      let totalEI = eIInsurableEarningsAuditData.slice(-1)[0];
      setTotalEIitems(totalEI)
    } else {
      setupdatedEIInsurableEarningsItems([])
    }
  },[eIInsurableEarningsAuditData])
  useEffect(()=>{
    if(CPPPensionableEarningsAuditData && CPPPensionableEarningsAuditData.status !== 404 && CPPPensionableEarningsAuditData.status !== 401){
      const dataWithoutTotalObject = CPPPensionableEarningsAuditData && CPPPensionableEarningsAuditData.slice(0, -1);
      const updatedItemsResponseCPP = CPPPensionableEarningsAuditData && CPPPensionableEarningsAuditData.map((item:any) => ({
        ...item,
        employmentIncome: item.employmentIncome && item.employmentIncome !== null ? parseFloat(item.employmentIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00',
        cppQppPensionableEarnings: item.cppQppPensionableEarnings && item.cppQppPensionableEarnings !== null ? parseFloat(item.cppQppPensionableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00',
        ignoredCppAuditEarning: item.ignoredCppAuditEarning ? <i className="pi pi-check" style={{ color: 'green' }}></i> : <i className="pi pi-times" style={{ color: 'red' }}></i>,
        dateOfBirth: (item.dateOfBirth !== "" && item.dateOfBirth !== null && item.dateOfBirth && item.dateOfBirth.charAt(3) !== "*") ? formatDate(item.dateOfBirth) : item.dateOfBirth,
        name: (
          <Box
            style={{ color: "blue",cursor:"pointer" }}
            onClick={() => onClickHandler(item.id)}
          >
            {item.name}
          </Box>
        ),
      }));
      setupdatedCPPPensionableEarningsItems(updatedItemsResponseCPP)
      let totalCPP = CPPPensionableEarningsAuditData.slice(-1)[0];
      setTotalCPPitems(totalCPP)
    } else {
      setupdatedCPPPensionableEarningsItems([])
    }
  },[CPPPensionableEarningsAuditData])

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:EARNINGS_AUDIT_SCREEN_SLUG,
      companyName:clientName,
      activityType:"activity_type=ignore_insurable_earnings&activity_type=undo_ignore_insurable_earnings&activity_type=ignore_pensionable_earnings&activity_type=undo_ignore_pensionable_earnings",
      activityLogVisibility:true
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[])

  return (
    <>
      {/* <BackdropCircularProgress open={isLoadingData || isAxiosCallLoading || isLoadingCPPPensionableEarningsAudit || isLoadingEIInsurableEarningsAudit} testId={testId} /> */}
      <BackdropCircularProgress open={isLoadingActivityLogData} testId={testId} />
      <Grid
        container
        sx={{ marginTop: theme.spacing(0) }}
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <PrimeDataGrid 
            columns={eIInsurableEarningsHeaderColumns}
            rows={eIInsurableEarningsAuditData && updatedEIInsurableEarningsItems}
            showIndexColumn={true}
            showChecboxColumn={true}
            showSettingColumn={false}
            isLoading={isLoadingData || isAxiosCallLoading || isLoadingCPPPensionableEarningsAudit || isLoadingEIInsurableEarningsAudit || isLoadingAdjustments}
            totalRecords={eIInsurableEarningsAuditData && totalEIitems ? totalEIitems.total : 0}
            initialPageSize={INITIAL_PAGE_SIZE}
            rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
            onRequestParamsChange={onRequestParamsOfEIInsurableEarningsChange}
            selectionToolbar={(e: any) => setSelectedItemsOfEIInsurableEarnings(e)}
            footer={footerOfEIInsurableEarnings}
            cardHeaderTitle={"Insurable Earnings Review"}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PrimeDataGrid 
          columns={cppPensionableEarningsHeaderColumns}
          rows={CPPPensionableEarningsAuditData && updatedCPPPensionableEarningsItems}
          showIndexColumn={true}
          showChecboxColumn={true}
          showSettingColumn={false}
          isLoading={isLoadingData || isAxiosCallLoading || isLoadingCPPPensionableEarningsAudit || isLoadingEIInsurableEarningsAudit || isLoadingAdjustments}
          totalRecords={CPPPensionableEarningsAuditData && totalCPPitems ? totalCPPitems.total : 0}
          initialPageSize={INITIAL_PAGE_SIZE}
          rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
          onRequestParamsChange={onRequestParamsOfCPPPensionableEarningsChange}
          selectionToolbar={(e: any) => setSelectedItemsOfCPPPensionableEarnings(e)}
          footer={footerOfCPPPensionableEarnings}
          cardHeaderTitle={"Pensionable Earnings Review"}
          />
        </Grid> 
        
      </Grid>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </>
  );
};
