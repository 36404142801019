import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { TaxSlipDefinitionsSetup } from "components/TaxSlipCategory/TaxSlipDefinitionsSetup";

export const TaxSlipDefinitionsSetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="TaxSlip Definitions Setup"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{ text: "TaxSlip Definitions List", navigateTo: "/taxslip-definitions/list" },
      { text: "Create Taxslip Definitions", navigateTo: '/taxslip-definitions/setup' }
      ]}
      mainContents={<TaxSlipDefinitionsSetup testId="data-role-setup-page" />}
    />
  );
};
