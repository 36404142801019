import { Grid } from "@mui/material";
import {
  RHookFormTextFieldWithLabel,
  RHookFormMaskedTextFieldWithLabel,
  LabelPosition,
  ITest,
  EnhancedCheckboxRHF,
  EnhancedDropDownRHFWithLabel,
} from "@websential/cosmic";
import React, { FC, useEffect, useState } from "react";
interface IRecipentTypeFields extends ITest {
  value: number;
  vendorDDL:any[];
  formMethods:any;
  individualChangeHandler?: (value: boolean) => void;
}
export const RecipentTypeFields: FC<IRecipentTypeFields> = ({
  testId,
  value,
  vendorDDL,
  formMethods,
  individualChangeHandler
}) => {
  console.log("vendorDDL",vendorDDL)
  const [individual, setIndividual] = useState(false);
  const onIndividualChangeHandler = (e: any) => {
    formMethods.setValue('secondRecipientName',undefined)
    // formMethods.setValue('name3',"")
    // formMethods.setValue('name4',"")
    // formMethods.setValue('initials1',"")
    setIndividual(e);
  };
  useEffect(()=>{
    const individual = formMethods.getValues('individual')
    if(individual === undefined){
      formMethods.setValue('individual',false)
      console.log("individual--",individual)
    }
  },[])
  return (
    <>
      {value === 10 ? (
        <>
          <RHookFormTextFieldWithLabel
            label="First Name"
            id="name1"
            testId={`${testId}-first-name`}
            labelPosition={LabelPosition.Left}
            required
          />
          <RHookFormMaskedTextFieldWithLabel
            label="Initials"
            id="initials"
            testId={`${testId}-initials`}
            labelPosition={LabelPosition.Left}
            inputMaskPattern={"a"}
          />
          <RHookFormTextFieldWithLabel
            label="Last Name"
            id="name2"
            testId={`${testId}-last-name`}
            labelPosition={LabelPosition.Left}
            required
          />
        </>
      ) : value === 20 ? (
        <>
          <RHookFormTextFieldWithLabel
            label="First Name"
            id="name1"
            testId={`${testId}-first-name`}
            labelPosition={LabelPosition.Left}
            required
          />
          <RHookFormMaskedTextFieldWithLabel
            label="Initials"
            id="initials"
            testId={`${testId}-initials`}
            labelPosition={LabelPosition.Left}
            inputMaskPattern={"a"}
          />
          <RHookFormTextFieldWithLabel
            label="Last Name"
            id="name2"
            testId={`${testId}-last-name`}
            labelPosition={LabelPosition.Left}
            required
          />

          <Grid
            container
            columnSpacing={0}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Grid item xs={9.5} sm={9.5} md={9.5} lg={9.5} xl={9.5}>
              {/* {individual === true ? (
                <>
                  <RHookFormTextFieldWithLabel
                    label="First Name"
                    id="name3"
                    testId={`${testId}-first-name`}
                    labelPosition={LabelPosition.Left}
                    labelColumns={5.05}
                    fieldColumns={6.95}
                    required
                  />
                  <RHookFormTextFieldWithLabel
                    label="Last Name"
                    id="name4"
                    testId={`${testId}-last-name`}
                    labelPosition={LabelPosition.Left}
                    labelColumns={5.05}
                    fieldColumns={6.95}
                    required
                  />
                  <RHookFormMaskedTextFieldWithLabel
                    label="Initials"
                    id="initials1"
                    testId={`${testId}-initials`}
                    labelPosition={LabelPosition.Left}
                    labelColumns={5.05}
                    fieldColumns={6.95}
                    inputMaskPattern={"a"}
                  />
                </>
              ) : ( */}
                {/*  <RHookFormTextFieldWithLabel
                   // label="Name of second recipient"
                   label="Second recipient"
                   id="secondRecipientName"
                   testId={`${testId}-name-Of-Second-Recipient`}
                   labelPosition={LabelPosition.Left}
                   labelColumns={5.05}
                   fieldColumns={6.95}
                   required
                 /> */}
                <EnhancedDropDownRHFWithLabel 
                  testId={"vendor-ddl"} 
                  selectName={"secondRecipientName"} 
                  items={vendorDDL ?? []} 
                  label={"Second recipient"}
                  labelPosition={LabelPosition.Left}
                  labelColumns={5.05}
                  fieldColumns={6.95}
                  required
                  />
              {/*  )} */}
            </Grid>
            <Grid
              item
              xs={2.5}
              sm={2.5}
              md={2.5}
              lg={2.5}
              xl={2.5}
              sx={{ textAlign: "end", padding: "6px 0 0 6px" }}
            >
              <EnhancedCheckboxRHF
                name="individual"
                checked={false}
                testId="individual"
                checkboxLabel="individual"
                onSelectionChangeHandler={individualChangeHandler}
              />
            </Grid>
          </Grid>
        </>
      ) : value === 30 || 40 || 50 ? (
        <>
          <RHookFormTextFieldWithLabel
            // label="First line of recipient name"
            label="Recipient name line 1"
            id="firstLineOfRecipientName"
            testId={`${testId}-first-Line-Of-Rescipient-Name`}
            labelPosition={LabelPosition.Left}
            required
          />
          <RHookFormTextFieldWithLabel
            // label="Second line of recipient name"
            label="Recipient name line 2"
            id="secondLineOfRecipientName"
            testId={`${testId}-second-Line-Of-Rescipient-Name`}
            labelPosition={LabelPosition.Left}
            required
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
