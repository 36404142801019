import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearSelectedResourceApiMessageActions } from "store/actions/resourceApiMessageActions";
export const PeopleMenuComponent = () => {
const navigate = useNavigate();
const dispatch = useDispatch();
const ArrayData = [
  {
    label: "Employee",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/resources/Employee/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/resources/Employee/setup")
          window.location.reload()
          dispatch(clearSelectedResourceApiMessageActions())
        },
      },
      // {
      //   label: "Move Employees",
      //   command: () => {
      //     navigate("/resources/Employee/move-people")
      //   },
      // },
      {
        label: "Merge Employees",
        command: () => {
          navigate("/resources/Employee/merge")
        },
      },
      {
        label: "Undo Merge Employees",
        command: () => {
          navigate("/resources/Employee/undo-merge")
        },
      },
    ],  
  },
  {
    label: "Vendor",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/resources/Vendor/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/resources/Vendor/setup")
          window.location.reload()
          dispatch(clearSelectedResourceApiMessageActions())
        },
      },
    ],  
  },
  {
    label: "T5 Recipient",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/resources/T5_Recipient/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/resources/T5_Recipient/setup")
          window.location.reload()
          dispatch(clearSelectedResourceApiMessageActions())
        },
      },
    ],  
  },
  {
    label: "T3 Beneficiary",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/resources/T3_Beneficiary/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/resources/T3_Beneficiary/setup")
          window.location.reload()
          dispatch(clearSelectedResourceApiMessageActions())
        },
      },
    ],  
  },
  {
    label: "Filing Resource",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/filing-resources/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/filing-resources/setup")
        },
      }
    ],  
  },
  {
    label: "Move People",
    command: () => {
        navigate("/resources/Employee/move-people")
    }
  }
];

return {
  PeopleMenu:ArrayData
}
}
