import { DashboardLayout } from "layouts";
import { FC } from "react";

import DashboardOutlined from "@mui/icons-material/DashboardOutlined";
import { GlobalSearch } from "components/GlobalSearch";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
// import { FlexFieldsUtils } from "common/FlexFieldsUtils";

export interface IGlobalSearchPage {}

export const GlobalSearchPage: FC<IGlobalSearchPage> = () => {
  // FlexFieldsUtils.formatFixedAndMetaFields({});
  return (
    <AuthenticatedLayout
      pageTitle="Global Search"
      pageTitleIcon={<DashboardOutlined />}
      breadcrumbLinks={[{text: 'Global Search', navigateTo: '/'}]}
      breadcrumbCurrentPageName={"Global Search"}    
      mainContents={<GlobalSearch testId="Global Search " />} 
      />
  );
};
