import { CSVInformationUI } from "components/T4Slip/CSVInformationUI";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface ICSVInformationUIPage {}

export const CSVInformationUIPage: FC<ICSVInformationUIPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="CSV Information"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<CSVInformationUI />}
    />
  );
};
