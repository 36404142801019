import {
  FILING_RESOURCE_SETUP_SCREEN_ID,
  FILING_RESOURCE_SETUP_SCREEN_SLUG,
  GET_QUERY_KEY,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
} from "common/constants";
import {
  IDataRoleRequestPayload,
  IDataRoleResponsePayload,
  IGetListRequest,
  IResponse,
  IServerError,
} from "models";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DataRoleService, TaxSlipYearService } from "services";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import {
  BackdropCircularProgress,
  EmailFieldRHF,
  EnhancedDropDownRHFWithLabel,
  EnhancedMultiDropDownRHFWithLabel,
  EnhancedSnackbar,
  ExtensionNumberRHF,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  PhoneNumberRHF,
  PostCodeRHF,
  RHookFormDatePicker,
  RHookFormMaskedTextFieldWithLabel,
  RHookFormTextFieldWithLabel,
  SinNumberRHF,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
} from "@websential/cosmic";

import { FilingResourceSetupSchema } from "./validator";
import { IFilingResourceRequestPayload } from "models/filingResource/IFilingResourceRequestPayload";
import { FilingResourceService } from "services/FilingResourceService";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { IProvinceReferenceModel } from "models/province/IProvinceReferenceModel";
import { ProvinceService } from "services/common/ProvinceService";
import { ICountryReferenceModel } from "models/country/ICountryReferenceModel";
import { CountryService } from "services/common/CountryService";
import { useNavigate, useParams } from "react-router-dom";
import { instanceOfIFillingResourceResponce } from "common/instance-method";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IFilingResourceSetup extends ITest {}
const SINGLE_RECORD_DATA_QUERY_KEY = "filling-resource-single-data-record";

// const useGetData = (id: string | undefined, clientId: string) => {
//   return useQuery(
//     [SINGLE_RECORD_DATA_QUERY_KEY, id],
//     () => FilingResourceService.getById(id, clientId),
//     QUERY_OPTIONS
//   );
// };

const useGetData = (id: string | undefined, clientId: string) =>
  useQuery(
    [`single-filing-resource-data`, id, clientId],
    (data) => {
      return FilingResourceService.getById(id, clientId);
    },
    { refetchOnWindowFocus: false }
  );

const contactPersonId = 1;
const issuerId = 2;
const transmitterId = 3;
// export let issuerSelectedValidate = false;

//years list from API
const useYears = (params: IGetListRequest) =>
  useQuery(
    ["years-list", params],
    (data) => {
      return TaxSlipYearService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

export let currentTaxYear = new Date().getFullYear() 

export const FilingResourceSetup: FC<IFilingResourceSetup> = ({ testId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );
  currentTaxYear = taxYear;

  const [issuerSelected, setIssuerSelected] = useState<boolean>(false);
  const [firstNameDisabled, setFirstNameDisabled] = useState<boolean>(false);
  const [selectedProvince, setSelectedProvince] =
    useState<IProvinceReferenceModel>({
      name: "",
      shortCode: "",
    });

  const [CountryResponse] = useState<ICountryReferenceModel>({
    id: 1,
    name: "Canada",
    shortCode: "CAN",
  });

  // for edit
  const queryClient = useQueryClient();
  const { id } = useParams();
  console.log("id from setup file which clicked : ", id);

  const [transmitterSelected, setTransmitterSelected] =
    useState<boolean>(false);
  const clientId = useSelector((state: IAppState) => state.client.id);
  const DEFAULT_VALUES = {
    // clientId: clientId,
    // id: null,
    // name: "",
    // effectiveFromYear: undefined,
    // phone1: "",
    // ext: null,
    // role: ["6173ddf7-68e2-426f-b1be-9d1dd63eb49c"],
    // ownerSin1: "",
    // ownerSin2: null,
    // craTransmitter: "",
    // rqTransmitter: null,
    // rl1Type: null,
    // address: "",
    // city: "",
    // postCode: "",
    // contactName: "",
    // email: "",
    // province:{}
    id: undefined,
    name: "",
    effectiveFromYear: undefined,
    phone1: "",
    ext: "",
    role: ["6173ddf7-68e2-426f-b1be-9d1dd63eb49c"],
    ownerSin1: "",
    craTransmitter: "",
    rqTransmitter: "",
    rl1Type: "",
    address: "",
    city: "",
    postCode: "",
    contactName: "",
    email: "",
    province: undefined,
    country: undefined,
  };
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    FilingResourceService.create,
    {
      onSuccess: (
        res: IFilingResourceRequestPayload | IServerError | IResponse | any
      ) => {
        if (instanceOfIFillingResourceResponce(res)) {
          queryClient.invalidateQueries([SINGLE_RECORD_DATA_QUERY_KEY, res.id]);
        }
        if (res.status === 201 || res.status === 200) {
          setSnackbarData({
            open: true,
            message: "Filing Resource has been added successfully",
            severity: "success",
          });
          setTimeout(() => {
            let path = "/filing-resources/list";
            navigate(path);
          });
        } else {
          setSnackbarData({
            open: true,
            message:
              res && res.data.message && typeof res.data.message !== "object"
                ? res.data.message
                : res.data.message.message
                ? res.data.message.message
                : "Data has been submitted successfully!",
            severity:
              res.data.status === false
                ? "error"
                : res.data.success === false
                ? "error"
                : "success",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "An error occurred, please try again",
          severity: "error",
        });
      },
    }
  );

  // for edit
  const { isLoading: isLoadingData, data: selectedFillingResourceData } =
    useGetData(id, clientId);

  //get years list by API
  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "role_name",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useYears(queryParams); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const YearsGetList: any = data;
  console.log("@@@@@ => YearsGetList", YearsGetList);

  const yearItems = useMemo(() => {
    if (YearsGetList) {
      return YearsGetList.items.map((yearItem: any) => ({
        label: String(yearItem.year),
        value: yearItem.year,
      }));
    }
  }, [YearsGetList]);

  const formMethods: any = useForm<IFilingResourceRequestPayload>({
    resolver: yupResolver(FilingResourceSetupSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = (formData: any) => {
    console.log("formData  =  = = = = = = = = == ", formData);

    //province is in string format and by using this we convert that in object
    const jsonString =
      formData.province !== undefined && ""
        ? formData.province
        : ('{id:9, name: "Ontario",shortCode:"ON"}' as string);
    // Custom parsing function to convert the JSON-like string to an object
    const parseJSONLikeString = (str: string) => {
      // Use regular expressions to add double quotes around keys and string values
      const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
      // Convert the modified JSON string to an object using JSON.parse()
      return JSON.parse(modifiedString);
    };
    // Convert the JSON-like string to an object
    const provinceObject = parseJSONLikeString(jsonString);
    console.log("provinceObject", provinceObject);

    const maskedStatus: any = sessionStorage.getItem("maskEnabled");
    console.log("maskedStatus", maskedStatus);

    const requestPayload: IFilingResourceRequestPayload = {
      // clientId: formData.clientId,
      clientId: clientId,
      id: selectedFillingResourceData
        ? selectedFillingResourceData.id
        : undefined,
      name: formData.name,
      effectiveFromYear: parseInt(formData.effectiveFromYear),
      phone1: formData.phone1 && formData.phone1.replace(/\D/g, ""),
      ext: formData.ext !== "" ? formData.ext : undefined,
      role: formData.role,
      ownerSin1:
        selectedFillingResourceData &&
        selectedFillingResourceData.id &&
        maskedStatus === "true"
          ? "000000000"
          : formData.ownerSin1 &&  issuerSelected ? formData.ownerSin1.replace(/-/g, "") : undefined,
      ownerSin2:
        formData.ownerSin2 !== "" && issuerSelected
          ? formData.ownerSin2 && formData.ownerSin2.replace(/-/g, "")
          : undefined,
      transmitterAccountNumber: taxYear > 2023 && transmitterSelected && formData.transmitterAccountNumber ? formData.transmitterAccountNumber : undefined,
      craTransmitter: formData.craTransmitter && transmitterSelected 
      && formData.craTransmitter.length === 6
      ? `MM${formData.craTransmitter}` : formData.craTransmitter.length === 8
      ? `${formData.craTransmitter}` : undefined,
      rqTransmitter:
        formData.rqTransmitter !== "" && formData.rqTransmitter !== undefined && transmitterSelected
          && formData.rqTransmitter.length === 6
          ? `NP${formData.rqTransmitter}`
          : formData.rqTransmitter.length === 8
          ? `${formData.rqTransmitter}`
          : undefined,
      rl1Type:
        formData.rl1Type !== "" && formData.rl1Type !== undefined && transmitterSelected
          ? formData.rl1Type
          : undefined,
      address: formData.address && transmitterSelected ? formData.address : undefined,
      city: formData.city && transmitterSelected ? formData.city : undefined,
      postCode:
        formData.postCode && transmitterSelected ? 
        formData.postCode.replace(/[a-z]/g, (match: string) =>
          match.toUpperCase()
        ) : undefined,
      contactName: formData.contactName && transmitterSelected ? formData.contactName : undefined,
      email: formData.email && transmitterSelected ? formData.email : undefined,
      // province: formData.province,
      province: transmitterSelected ? provinceObject : undefined,
      country: transmitterSelected ? CountryResponse : undefined,
    };
    console.log("===-requestPayload", requestPayload);
    console.log("===-formData", formData);

    submitFormData(requestPayload);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset(DEFAULT_VALUES);
    let path = `/filing-resources/setup`;
    navigate(path);
  };

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const onSelectionChangeHandler = (selectedItems: IEnhancedDropDownItem[]) => {
    console.log("selectedItems", selectedItems);
    if (selectedItems.length === 1) {
      if (selectedItems.find((item) => item.id === 1)) {
        setIssuerSelected(false);
        setTransmitterSelected(false);
      } else if (selectedItems.find((item) => item.id === 2)) {
        setIssuerSelected(true);
        setTransmitterSelected(false);
      } else if (selectedItems.find((item) => item.id === 3)) {
        // setIssuerSelected(true);
        setIssuerSelected(false);
        setTransmitterSelected(true);
      }
    } else if (selectedItems.length === 2) {
      if (
        selectedItems.find((item) => item.id === 1) &&
        selectedItems.find((item) => item.id === 2)
      ) {
        console.log("inside 1&& 2");
        setIssuerSelected(true);
        setTransmitterSelected(false);
      } else if (
        selectedItems.find((item) => item.id === 1) &&
        selectedItems.find((item) => item.id === 3)
      ) {
        console.log("inside 1&& 3");
        // setIssuerSelected(true);
        setIssuerSelected(false);
        setTransmitterSelected(true);
      } else if (
        selectedItems.find((item) => item.id === 2) &&
        selectedItems.find((item) => item.id === 3)
      ) {
        console.log("inside 2&& 3");
        setIssuerSelected(true);
        setTransmitterSelected(true);
      }
    } else if (selectedItems.length === 3) {
      if (selectedItems.find((item) => item.id === 1 && 2 && 3)) {
        setIssuerSelected(true);
        setTransmitterSelected(true);
      }
    } else if (selectedItems.length === 0) {
      setIssuerSelected(false);
      setTransmitterSelected(false);
    }
    // setIssuerSelected(
    //   selectedItems.findIndex((obj) => obj.id === issuerId || transmitterId) !== -1
    // );
    // console.log("issuerSelected",issuerSelected)
    // setTransmitterSelected(
    //   selectedItems.findIndex((obj) => obj.id === transmitterId) !== -1
    // );
  };

  console.log("SelectedProvince", selectedProvince);
  console.log("CountryResponse", CountryResponse);
  const useGetProvinceDDL = () => {
    return useQuery(GET_QUERY_KEY.PROVINCE_DDL, () => ProvinceService.get(), {
      ...QUERY_OPTIONS,
    });
  };

  const { isLoading: isLoadingProvinceDropDownList, data: provinceList } =
    useGetProvinceDDL();

  // const onProvinceSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
  //   setSelectedProvince({
  //     name: item.label,
  //     shortCode: String(item.value),
  //   });
  // };

  // for edit
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (selectedFillingResourceData) {
      setFirstNameDisabled(true);
      Object.keys(selectedFillingResourceData).forEach((fieldName) => {
        console.log("fieldName useEffect Filing Resources : ", fieldName);
        // @ts-ignore
        const fieldValue = selectedFillingResourceData[fieldName];
        console.log("fieldValue useEffect Filing Resources : ", fieldValue);
        // @ts-ignore
        if (typeof fieldValue !== "object") {
          // @ts-ignore
          formMethods.setValue(
            fieldName,
            fieldValue && fieldValue !== null ? fieldValue : undefined
          );
        }
        if (fieldName === "role") {
          // @ts-ignore
          formMethods.setValue(fieldName, fieldValue ?? undefined);
          if (fieldValue.includes("6173ddf7-68e2-426f-b1be-9d1dd63eb49d")) {
            setIssuerSelected(true);
          }
          if (fieldValue.includes("6173ddf7-68e2-426f-b1be-9d1dd63eb49e")) {
            setTransmitterSelected(true);
          }
        }
        if (fieldName === "province") {
          let id = fieldValue.id;
          let name = fieldValue.name;
          let shortCode = fieldValue.shortCode;
          console.log("namename", name);

          formMethods.setValue(
            fieldName,
            // '{id:9, name: "Ontario",shortCode:"ON"}'
            `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
          );
        }
        if (fieldName === "country") {
          // if (objFieldValue.name === "Canada") {
          formMethods.setValue(fieldName, fieldValue.name ?? undefined);
          // }
        }
        if (fieldName === "ownerSin1") {
          const formattedNumber = fieldValue
            ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
            : undefined;
          formMethods.setValue("ownerSin1", formattedNumber);
        }
        if (fieldName === "ownerSin2") {
          const formattedNumber = fieldValue
            ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
            : undefined;
          formMethods.setValue("ownerSin2", formattedNumber);
        }
        if (fieldName === "phone1") {
          const formattedNumber = fieldValue
            ? fieldValue.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
            : undefined;
          formMethods.setValue("phone1", formattedNumber);
        }
      });
    }
  }, [selectedFillingResourceData, formMethods.setValue, formMethods]);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: id ? id : "",
        entityId2: "",
        entityId3: "",
        screenName: FILING_RESOURCE_SETUP_SCREEN_SLUG,
        companyName: clientName,
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        // open={isSubmitting}
        open={
          isLoadingProvinceDropDownList ||
          isSubmitting ||
          isLoadingData ||
          isLoadingActivityLogData
        }
        testId={`${testId}-backdrop`}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            children={
              <>
                <StandardPanel
                  title="Resource Information"
                  testId={`${testId}-resource-information`}
                >
                  <EnhancedMultiDropDownRHFWithLabel
                    selectName="role"
                    label="Role"
                    labelPosition={LabelPosition.Left}
                    items={[
                      {
                        id: 1,
                        label: "Contact Person",
                        value: "6173ddf7-68e2-426f-b1be-9d1dd63eb49c",
                      },
                      {
                        id: 2,
                        label: "Issuer",
                        value: "6173ddf7-68e2-426f-b1be-9d1dd63eb49d",
                      },
                      {
                        id: 3,
                        label: "Transmitter",
                        value: "6173ddf7-68e2-426f-b1be-9d1dd63eb49e",
                      },
                      // { label: "Contact Person",value: 1},
                      // {label: "Issuer",value: 2},
                      // {label: "Transmitter",value: 3},
                    ]}
                    selectedItems={["6173ddf7-68e2-426f-b1be-9d1dd63eb49c"]}
                    isClearable={false}
                    testId={`${testId}-selected-roles`}
                    onSelectionChangeHandler={onSelectionChangeHandler}
                    required
                  />
                  <EnhancedDropDownRHFWithLabel
                    selectName="effectiveFromYear"
                    label="Effective From"
                    labelPosition={LabelPosition.Left}
                    items={yearItems ? yearItems : []}
                    testId={`${testId}-effective-from`}
                    required
                  />
                  <RHookFormTextFieldWithLabel
                    label="Name"
                    id="name"
                    testId={`${testId}-name`}
                    labelPosition={LabelPosition.Left}
                    readOnly={firstNameDisabled}
                    required
                  />
                  <Box sx={{ pb: 2 }}></Box>
                  <Grid
                    container
                    columnSpacing={0}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                      <PhoneNumberRHF
                        label="Phone"
                        id="phone1"
                        testId={`${testId}-phone`}
                        labelPosition={LabelPosition.Left}
                        labelColumns={6}
                        fieldColumns={6}
                        required
                      />
                      {/* <RHookFormTextFieldWithLabel
                        label="Phone"
                        id="phone1"
                        placeholder="(777) 777-777"
                        testId={`${testId}-phone`}
                        labelPosition={LabelPosition.Left}
                        labelColumns={6}
                        fieldColumns={6}
                        required
                      /> */}
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                      <ExtensionNumberRHF
                        label="Ext."
                        id="ext"
                        testId={`${testId}-ext`}
                        labelPosition={LabelPosition.Left}
                        labelColumns={6}
                        fieldColumns={6}
                      />
                      {/* <RHookFormTextFieldWithLabel
                        label="Ext."
                        id="ext"
                        testId={`${testId}-ext`}
                        labelPosition={LabelPosition.Left}
                        labelColumns={6}
                        fieldColumns={6}
                      /> */}
                    </Grid>
                  </Grid>
                </StandardPanel>
                {issuerSelected && (
                  <StandardPanel
                    id="issuerPanel"
                    title="Other Details for Issuer"
                    testId={`${testId}-other-details-issuer`}
                    topPadding={2}
                  >
                    {selectedFillingResourceData &&
                    selectedFillingResourceData.ownerSin1 &&
                    selectedFillingResourceData.ownerSin1.charAt(2) === "*" ? (
                      <RHookFormTextFieldWithLabel
                        label="Owner SIN 1"
                        id="ownerSin1"
                        testId={`${testId}-owner-sin-1`}
                        labelPosition={LabelPosition.Left}
                        required
                        labelColumns={4}
                        fieldColumns={8}
                        readOnly={selectedFillingResourceData ? true : false}
                        value={"Unmask to view / edit"}
                      />
                    ) : (
                      <SinNumberRHF
                        label="Owner SIN 1"
                        id="ownerSin1"
                        testId={`${testId}-owner-sin-1`}
                        labelPosition={LabelPosition.Left}
                        required
                        labelColumns={4}
                        fieldColumns={8}
                      />
                    )}
                    <SinNumberRHF
                      label="Owner SIN 2"
                      id="ownerSin2"
                      testId={`${testId}-owner-sin-2`}
                      labelPosition={LabelPosition.Left}
                      labelColumns={4}
                      fieldColumns={8}
                    />
                    {/* <RHookFormTextFieldWithLabel
                      label="Owner SIN 1"
                      id="ownerSin1"
                      testId={`${testId}-owner-sin-1`}
                      labelPosition={LabelPosition.Left}
                      required
                    /> */}
                    {/* <RHookFormTextFieldWithLabel
                      label="Owner SIN 2"
                      id="ownerSin2"
                      testId={`${testId}-owner-sin-2`}
                      labelPosition={LabelPosition.Left}
                    /> */}
                  </StandardPanel>
                )}
                {transmitterSelected && (
                  <>
                    <StandardPanel
                      title="Other Details for Transmitter"
                      testId={`${testId}-other-details-transmitter`}
                      topPadding={2}
                    >
                      {taxYear > 2023 ? <RHookFormMaskedTextFieldWithLabel
                        id='transmitterAccountNumber'
                        label='Account Number'
                        labelPosition={LabelPosition.Left}
                        testId='accountNumber'
                        required
                        labelColumns={4}
                        fieldColumns={8}
                        placeholder={"000000000RP0000"}
                        inputMaskPattern={"{000000000Ra0000}"}
                        // defaultValue={"123123123RT0000"}
                    />:<></>}
                      {taxYear <= 2023 ? <RHookFormMaskedTextFieldWithLabel
                        label="CRA Transmitter #"
                        id="craTransmitter"
                        testId={`${testId}-cra-transmitter-number`}
                        labelPosition={LabelPosition.Left}
                        placeholder={"MM123456"}
                        required
                        inputMaskPattern={"MM000000"}
                        labelColumns={4}
                        fieldColumns={8}
                        />:<></>}
                      <RHookFormMaskedTextFieldWithLabel
                        label="RQ Transmitter #"
                        id="rqTransmitter"
                        testId={`${testId}-rq-transmitter-number`}
                        labelPosition={LabelPosition.Left}
                        placeholder={"NP123456"}
                        // required
                        inputMaskPattern={"NP000000"}
                        labelColumns={4}
                        fieldColumns={8}
                      />
                      {/* <RHookFormTextFieldWithLabel
                        label="CRA Transmitter #"
                        id="craTransmitter"
                        testId={`${testId}-cra-transmitter-number`}
                        labelPosition={LabelPosition.Left}
                        placeholder="MM123456"
                        required
                      /> */}
                      {/* <RHookFormTextFieldWithLabel
                        label="RQ Transmitter #"
                        id="rqTransmitter"
                        testId={`${testId}-rq-transmitter-number`}
                        labelPosition={LabelPosition.Left}
                        placeholder="NP123456"
                      /> */}
                      <EnhancedDropDownRHFWithLabel
                        selectName="rl1Type"
                        label="RL-1 Type"
                        labelPosition={LabelPosition.Left}
                        items={[
                          {
                            label: "If you are filing for yourself",
                            value: "if you are filing for yourself",
                          },
                          {
                            label:
                              "If you are filing on behalf of other filers",
                            value:
                              "if you are filing on behalf of other filers",
                          },
                          {
                            label:
                              "If you are filing for yourself and on behalf of other filers",
                            value:
                              "if you are filing for yourself and on behalf of other filers",
                          },
                        ]}
                        testId={`${testId}-rl1-type`}
                      />
                      <RHookFormTextFieldWithLabel
                        label="Address"
                        id="address"
                        testId={`${testId}-transmitter-address`}
                        labelPosition={LabelPosition.Left}
                        required
                      />
                      <RHookFormTextFieldWithLabel
                        label="City"
                        id="city"
                        testId={`${testId}-transmitter-city`}
                        labelPosition={LabelPosition.Left}
                        required
                      />
                      <EnhancedDropDownRHFWithLabel
                        selectName="province"
                        label="Province"
                        // selectedItem={selectedProvince.shortCode}
                        labelPosition={LabelPosition.Left}
                        required
                        testId={`${testId}-transmitter-province`}
                        items={provinceList ?? []}
                        // onSelectionChangeHandler={
                        //   onProvinceSelectionChangeHandler
                        // }
                      />
                      {/* <EnhancedDropDownRHFWithLabel
                        selectName="province"
                        label="Province"
                        labelPosition={LabelPosition.Left}
                        required
                        testId={`${testId}-province-list`}
                        items={[
                          {
                            label: "Alberta",
                            value: { id: 1, name: "Alberta", shortCode: "AB" },
                          },
                          {
                            label: "British Columbia",
                            value: {
                              id: 2,
                              name: "British Columbia",
                              shortCode: "BC",
                            },
                          },
                          {
                            label: "Manitoba",
                            value: { id: 3, name: "Manitoba", shortCode: "MB" },
                          },
                          {
                            label: "New Brunswick",
                            value: {
                              id: 4,
                              name: "New Brunswick",
                              shortCode: "NB",
                            },
                          },
                          {
                            label: "Newfoundland and Labrador",
                            value: {
                              id: 5,
                              name: "Newfoundland and Labrador",
                              shortCode: "NL",
                            },
                          },
                          {
                            label: "Northwest Territories",
                            value: {
                              id: 7,
                              name: "Northwest Territories",
                              shortCode: "NT",
                            },
                          },
                          {
                            label: "Nova Scotia",
                            value: {
                              id: 6,
                              name: "Nova Scotia",
                              shortCode: "US",
                            },
                          },
                          {
                            label: "Nunavut",
                            value: { id: 8, name: "Nunavut", shortCode: "NU" },
                          },
                          {
                            label: "Ontario",
                            value: { id: 9, name: "Ontario", shortCode: "ON" },
                          },
                          {
                            label: "Prince Edward Island",
                            value: {
                              id: 10,
                              name: "Prince Edward Island",
                              shortCode: "PE",
                            },
                          },
                          {
                            label: "Quebec",
                            value: { id: 11, name: "Quebec", shortCode: "QC" },
                          },
                          {
                            label: "Saskatchewan",
                            value: {
                              id: 12,
                              name: "Saskatchewan",
                              shortCode: "SK",
                            },
                          },
                          {
                            label: "Yukon",
                            value: { id: 13, name: "Yukon", shortCode: "YT" },
                          },
                        ]}
                      /> */}
                      {/* <EnhancedDropDownRHFWithLabel
                        selectName="province"
                        label="Province"
                        labelPosition={LabelPosition.Left}
                        items={[
                          { label:"Alberta", value:1},
                          {
                            label: "British Columbia",
                            value: 2,
                          },
                          {
                            label: "Manitoba",
                            value: 3,
                          },
                          {
                            label: "New Brunswick",
                            value: 4,
                          },
                          {
                            label: "Newfoundland and Labrador",
                            value: 5,
                          },
                          {
                            label: "Northwest Territories",
                            value: 6,
                          },
                          {
                            label: "Nova Scotia",
                            value: 7,
                          },
                          {
                            label: "Nunavut",
                            value: 8,
                          },
                          {
                            label: "Ontario",
                            value: 9,
                          },
                          {
                            label: "Prince Edward Island",
                            value: 10,
                          },
                          {
                            label: "Quebec",
                            value: 11,
                          },
                          {
                            label: "Saskatchewan",
                            value: 12,
                          },
                          {
                            label: "Yukon",
                            value: 13,
                          },
                        ]}
                        testId={`${testId}-transmitter-province`}
                        required
                      /> */}

                      <RHookFormTextFieldWithLabel
                        label="Country"
                        id="country"
                        testId={`${testId}-transmitter-country`}
                        labelPosition={LabelPosition.Left}
                        value="Canada"
                        defaultValue={"Canada"}
                        readOnly
                      />
                      <PostCodeRHF
                        label="Post Code"
                        id="postCode"
                        testId={`${testId}-transmitter-post-code`}
                        labelPosition={LabelPosition.Left}
                        required
                        labelColumns={4}
                        fieldColumns={8}
                      />
                      {/* <RHookFormTextFieldWithLabel
                        label="Post Code"
                        id="postCode"
                        testId={`${testId}-transmitter-post-code`}
                        labelPosition={LabelPosition.Left}
                        required
                      /> */}
                      <RHookFormTextFieldWithLabel
                        label="Contact Name"
                        id="contactName"
                        testId={`${testId}-transmitter-contact-name`}
                        labelPosition={LabelPosition.Left}
                        required
                      />
                      {/* <RHookFormTextFieldWithLabel
                        label="Email"
                        id="email"
                        testId={`${testId}-transmitter-email`}
                        labelPosition={LabelPosition.Left}
                        required
                        /> */}
                      <EmailFieldRHF
                        label="Email"
                        id="email"
                        testId={`${testId}-transmitter-email`}
                        labelPosition={LabelPosition.Left}
                        labelColumns={4}
                        fieldColumns={8}
                        required
                      />
                    </StandardPanel>
                  </>
                )}
              </>
            }
            toolbar={
              <StandardSubmitFormButtonsToolbar
                onResetClicked={onResetClicked}
                isSubmitting={false}
                testId="standard-form-submit-toolbar"
              />
            }
            testId="form-layout"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
