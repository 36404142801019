import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IInvoiceAxisPages1 {}
const InvoiceAxisPages1:React.FC<IInvoiceAxisPages1> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'dateP1X'} 
                testId={'dateP1X'} 
                label={'Date'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerNameP1X'} 
                testId={'employerNameP1X'} 
                label={'Employer Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={30}
            />
            <RHookFormTextFieldWithLabel 
                id={'addressP1X'} 
                testId={'addressP1X'} 
                label={'Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={30}
            />
            <RHookFormTextFieldWithLabel 
                id={'cityP1X'} 
                testId={'cityP1X'} 
                label={'City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={30}
            />
            <RHookFormTextFieldWithLabel 
                id={'postCodeP1X'} 
                testId={'postCodeP1X'} 
                label={'Post Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={30}
            />
            <RHookFormTextFieldWithLabel 
                id={'phoneNoP1X'} 
                testId={'phoneNoP1X'} 
                label={'Phone No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={30}
            />
            <RHookFormTextFieldWithLabel 
                id={'emailP1X'} 
                testId={'emailP1X'} 
                label={'Email'} 
                labelPosition={LabelPosition.Left}
                defaultValue={30}
            />
            <RHookFormTextFieldWithLabel 
                id={'indexNoP1X'} 
                testId={'indexNoP1X'} 
                label={'Index No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={35}
            />
            <RHookFormTextFieldWithLabel 
                id={'productP1X'} 
                testId={'productP1X'} 
                label={'Product'} 
                labelPosition={LabelPosition.Left}
                defaultValue={70}
            />
                <RHookFormTextFieldWithLabel 
                    id={'quentityP1X'} 
                    testId={'quentityP1X'} 
                    label={'Quentity'} 
                    labelPosition={LabelPosition.Left}
                    defaultValue={260}
                />
            <RHookFormTextFieldWithLabel 
                id={'unitPriceP1X'} 
                testId={'unitPriceP1X'} 
                label={'Unit Price'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountP1X'} 
                testId={'amountP1X'} 
                label={'Amount'} 
                labelPosition={LabelPosition.Left}
                defaultValue={505}
            />
            <RHookFormTextFieldWithLabel 
                id={'lineStartP1X'} 
                testId={'lineStartP1X'} 
                label={'Line Start'} 
                labelPosition={LabelPosition.Left}
                defaultValue={480}
            />
            <RHookFormTextFieldWithLabel 
                id={'lineEndP1X'} 
                testId={'lineEndP1X'} 
                label={'Line End'} 
                labelPosition={LabelPosition.Left}
                defaultValue={550}
            />
            <RHookFormTextFieldWithLabel 
                id={'netAmountNameP1X'} 
                testId={'netAmountNameP1X'} 
                label={'Net Amount Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'netAmountP1X'} 
                testId={'netAmountP1X'} 
                label={'Net Amount'} 
                labelPosition={LabelPosition.Left}
                defaultValue={505}
            />
            <RHookFormTextFieldWithLabel 
                id={'handlingChargesNameP1X'} 
                testId={'handlingChargesNameP1X'} 
                label={'Handling Charges Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={350}
            />
            <RHookFormTextFieldWithLabel 
                id={'handlingChargesP1X'} 
                testId={'handlingChargesP1X'} 
                label={'Handling Charges'} 
                labelPosition={LabelPosition.Left}
                defaultValue={505}
            />
            <RHookFormTextFieldWithLabel 
                id={'taxAmountNameP1X'} 
                testId={'taxAmountNameP1X'} 
                label={'Tax Amount Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={300}
            />
            <RHookFormTextFieldWithLabel 
                id={'taxAmountValueP1X'} 
                testId={'taxAmountValueP1X'} 
                label={'Tax Amount Value'} 
                labelPosition={LabelPosition.Left}
                defaultValue={505}
            />
            <RHookFormTextFieldWithLabel 
                id={'totalAmountNameP1X'} 
                testId={'totalAmountNameP1X'} 
                label={'Total Amount Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'totalAmountValueP1X'} 
                testId={'totalAmountValueP1X'} 
                label={'Total Amount Value'} 
                labelPosition={LabelPosition.Left}
                defaultValue={505}
            />
            <RHookFormTextFieldWithLabel 
                id={'invoiceStatusP1X'} 
                testId={'invoiceStatusP1X'} 
                label={'Invoice Status'} 
                labelPosition={LabelPosition.Left}
                defaultValue={455}
            />
            <RHookFormTextFieldWithLabel 
                id={'invoiceNoP1X'} 
                testId={'invoiceNoP1X'} 
                label={'Invoice No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={86}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'dateP1Y'} 
                testId={'dateP1Y'} 
                label={'Date'} 
                labelPosition={LabelPosition.Left}
                defaultValue={687}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerNameP1Y'} 
                testId={'employerNameP1Y'} 
                label={'Employer Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={645}
            />
            <RHookFormTextFieldWithLabel 
                id={'addressP1Y'} 
                testId={'addressP1Y'} 
                label={'Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={625}
            />
            <RHookFormTextFieldWithLabel 
                id={'cityP1Y'} 
                testId={'cityP1Y'} 
                label={'City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={605}
            />
            <RHookFormTextFieldWithLabel 
                id={'postCodeP1Y'} 
                testId={'postCodeP1Y'} 
                label={'Post Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={585}
            />
            <RHookFormTextFieldWithLabel 
                id={'phoneNoP1Y'} 
                testId={'phoneNoP1Y'} 
                label={'Phone No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={565}
            />
            <RHookFormTextFieldWithLabel 
                id={'emailP1Y'} 
                testId={'emailP1Y'} 
                label={'Email'} 
                labelPosition={LabelPosition.Left}
                defaultValue={545}
            />
            <RHookFormTextFieldWithLabel 
                id={'finalLineValuesP1Y'} 
                testId={'finalLineValuesP1Y'} 
                label={'Final Line Values'} 
                labelPosition={LabelPosition.Left}
                defaultValue={455}
            />
            <RHookFormTextFieldWithLabel 
                id={'lineP1Y'} 
                testId={'lineP1Y'} 
                label={'Line'} 
                labelPosition={LabelPosition.Left}
                defaultValue={400}
            />
            <RHookFormTextFieldWithLabel 
                id={'differanceLineP1Y'} 
                testId={'differanceLineP1Y'} 
                label={'Differance Line'} 
                labelPosition={LabelPosition.Left}
                defaultValue={32}
            />
            <RHookFormTextFieldWithLabel 
                id={'invoiceStatusP1Y'} 
                testId={'invoiceStatusP1Y'} 
                label={'Invoice Status'} 
                labelPosition={LabelPosition.Left}
                defaultValue={740}
            />
            <RHookFormTextFieldWithLabel 
                id={'invoiceNoP1Y'} 
                testId={'invoiceNoP1Y'} 
                label={'Invoice No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={701}
            />
        </Grid>
    </Grid>
  )
}

export default InvoiceAxisPages1