import { BILLING_PROFILE_ASSOCIATION_SETUP_SCREEN_SLUG, INITIAL_SNACKBAR_DATA, QUERY_OPTIONS } from "common/constants";
import {
  IDropDownListResponse,
  IFlexFieldPanelAssignmentRequestPayload,
  IFlexFieldPanelAssignmentResponsePayload,
} from "models";
import { IResponse, IServerError } from "models/common";
import { FC, useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { FlexFieldPanelService, TaxProfileService } from "services";

import { yupResolver } from "@hookform/resolvers/yup";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Box, useTheme } from "@mui/material";
import {
  BackdropCircularProgress,
  DNDTwoColumnList,
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  IDNDList,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  SubmitButton,
  SubmitButtonVariant,
} from "@websential/cosmic";

import { BillingProfileClientsAssignmentSchema } from "./validator";
import { instanceOfIBillingProfileScreenPanelsAssignmentResponsePayload } from "common/instance-method";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch } from "react-redux";

export interface ITaxProfileAssignment extends ITest {}
const firstPanelDraggableId = "firstList";
const secondPanelDraggableId = "secondList";
const DEFAULT_VALUES = {
  name: "",
};
const BILLING_PROFILE_ASSOCIATION_ASSIGNED_PANELS_QUERY_KEY =
  "billing-profile-association-assigned-panels";

const useGetProfilesDropDownList = () =>
    useQuery(
      "get-billing-profile-dll",
      () => TaxProfileService.getProfilesDropDownList(),
      { refetchOnWindowFocus: false }
    );

const useGetAccountantsDropDownList = () =>
    useQuery(
      "get-accountant-dll",
      () => TaxProfileService.getAccountantsDropDownList(),
      { refetchOnWindowFocus: false }
    );

    
    export const TaxProfileAssignment: FC<ITaxProfileAssignment> = ({ testId }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedAccountant, setSelectedAccountant] = useState('All');
  const [accountantListItem, setAccountantListItem] = useState<
  IDropDownListResponse[]
>([{ label: "All", value: "All" }]);
  const [selectedBillingProfile, setSelectedBillingProfile] = useState("");
  // const [firstList, setFirstList] = useState<IDNDList>({
  //   droppableId: "firstList",
  //   elements: [
  //     {
  //       id: "1",
  //       value: "tax-profile-1-value",
  //       text: "tax-profile-1-text",
  //     },
  //     {
  //       id: "2",
  //       value: "tax-profile-2-value",
  //       text: "tax-profile-2-text",
  //     },
  //     {
  //       id: "3",
  //       value: "tax-profile-3-value",
  //       text: "tax-profile-3-text",
  //     },
  //   ],
  //   testId: "firstList",
  // });
  // const [secondList, setSecondList] = useState<IDNDList>({
    //   droppableId: "secondList",
    //   elements: [
  //     {
  //       id: "4",
  //       value: "tax-profile-4-value",
  //       text: "tax-profile-4-text",
  //     },
  //     {
  //       id: "5",
  //       value: "tax-profile-5-value",
  //       text: "tax-profile-5-text",
  //     },
  //     {
  //       id: "6",
  //       value: "tax-profile-6-value",
  //       text: "tax-profile-6-text",
  //     },
  //   ],
  //   testId: "secondList",
  // });
  const [firstList, setFirstList] = useState<IDNDList>({
    droppableId: "firstList",
    elements: [],
    testId: "firstList",
  });
  const [secondList, setSecondList] = useState<IDNDList>({
    droppableId: "secondList",
    elements: [],
    testId: "secondList",
  });
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [isLoadingData, setIsLoadingData] = useState(false);
  

  // const useGetAssignedPanels = () =>
  //     useQuery(
  //       BILLING_PROFILE_ASSOCIATION_ASSIGNED_PANELS_QUERY_KEY,
  //       () => TaxProfileService.getPanelsAssignedToScreen(selectedAccountant,selectedBillingProfile),
  //       { ...QUERY_OPTIONS, enabled: true }
  //     );

// const useGetAssignedPanels = (selectedAccountant: number | string, selectedBillingProfile: number | string) =>
//   useQuery(
//     [BILLING_PROFILE_ASSOCIATION_ASSIGNED_PANELS_QUERY_KEY, selectedAccountant,selectedBillingProfile],
//     (data) => {
//       return TaxProfileService.getPanelsAssignedToScreen(selectedAccountant,selectedBillingProfile);
//     },
//     { refetchOnWindowFocus: false }
//   );

const useGetAssignedPanels = () =>
  useQuery(
    [
      BILLING_PROFILE_ASSOCIATION_ASSIGNED_PANELS_QUERY_KEY,
      selectedAccountant,selectedBillingProfile
    ],
    () =>
    TaxProfileService.getPanelsAssignedToScreen(selectedAccountant,selectedBillingProfile),
    { ...QUERY_OPTIONS, enabled: false }
  );


  const {
    isLoading: isLoadingTabDropDownList,
    data: profileList,
    // refetch: refetchTabsDDL,
  } = useGetProfilesDropDownList();

  const {
    isLoading: isLoadingAccountantDropDownList,
    data: accountantList,
    // refetch: refetchTabsDDL,
  } = useGetAccountantsDropDownList();

  const {
    isLoading: isLoadingAssignedClients,
    data: assignedPanelResponsePayload,
    refetch: refetchAssignedPanels,
  // } = useGetAssignedPanels(selectedAccountant,selectedBillingProfile);
  } = useGetAssignedPanels();

  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    TaxProfileService.createProfileAssociation,
    {
      onSuccess: (
        res:
          | IFlexFieldPanelAssignmentResponsePayload
          | IServerError
          | IResponse
          | null
          | any
      ) => {
        if(res.status === true){
          setSnackbarData({
            open: true,
            message: "Client have been assigned to the Billing Profile successfully",
            severity: "success",
          });
        } else {
        setSnackbarData({
          open: true,
          message: "Client have been UnAssigned to the Billing Profile successfully",
          severity: "warning",
        });
      }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "An error occurred, please try again",
          severity: "error",
        });
      },
    }
  );

  const formMethods = useForm<any>({
    resolver: yupResolver(BillingProfileClientsAssignmentSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    // defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = (formData: any) => {
    // formData.clients = [];
    // secondList.elements.forEach((item, index) => {
    //   formData.clients.push(item.value);
    // });
    // console.log("formData--",formData)
    // submitFormData(formData);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    // formMethods.reset(DEFAULT_VALUES);
  };

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const onDragEnd = (result: DropResult, _provided: ResponderProvided) => {
    console.log("result",result)
    console.log("_provided",_provided)
    if (
      result.destination &&
      result.source.droppableId !== result.destination.droppableId
    ) {
      if (result.source.droppableId === "firstList") {
        let sourceList = firstList;
        let destinationList = secondList;
        let movedItem = sourceList.elements.splice(result.source.index, 1)[0];
        destinationList.elements.splice(result.destination.index, 0, movedItem);
        setFirstList({
          ...sourceList,
        });
        setSecondList({
          ...destinationList,
        });
      } else {
        let sourceList = secondList;
        let destinationList = firstList;
        let movedItem = sourceList.elements.splice(result.source.index, 1)[0];
        destinationList.elements.splice(result.destination.index, 0, movedItem);
        setSecondList({
          ...sourceList,
        });
        setFirstList({
          ...destinationList,
        });
      }
    } else {
      if (!result.destination) return;
      if (result.source.droppableId === "firstList") {
        let sourceDestinationList = firstList;
        let sourceItem = sourceDestinationList.elements.splice(
          result.source.index,
          1
        )[0];
        sourceDestinationList.elements.splice(
          result.destination.index,
          0,
          sourceItem
        );
        setFirstList({
          ...sourceDestinationList,
        });
      } else {
        let sourceDestinationList = secondList;
        let sourceItem = sourceDestinationList.elements.splice(
          result.source.index,
          1
        )[0];
        sourceDestinationList.elements.splice(
          result.destination.index,
          0,
          sourceItem
        );
        setSecondList({
          ...sourceDestinationList,
        });
      }
    }
    if(result.destination.droppableId === "secondList"){
      //this call is for accosiate clients
      let Payload = {
        profileId:selectedBillingProfile,
        clientId:result.draggableId,
        flag:true
      }
      submitFormData(Payload)
    } else {
      //this call is for UnAccosiate clients
      let Payload = {
        profileId:selectedBillingProfile,
        clientId:result.draggableId,
        flag:false
      }
      submitFormData(Payload)
    }
  };

  useEffect(() => {
    if (
      assignedPanelResponsePayload &&
      instanceOfIBillingProfileScreenPanelsAssignmentResponsePayload(
        assignedPanelResponsePayload
      )
    ) {
      let unassignedClients: IDNDList = {
        droppableId: firstPanelDraggableId,
        elements: assignedPanelResponsePayload.unassignedClients.map(
          (unassignedFlexField: any) => ({
            id: String(unassignedFlexField.id),
            text: String(unassignedFlexField.text),
            value: String(unassignedFlexField.value),
          })
        ),
        testId: firstPanelDraggableId,
      };

      let assignedClients: IDNDList = {
        droppableId: secondPanelDraggableId,
        elements: assignedPanelResponsePayload.assignedClients.map(
          (assignedFlexField: any) => ({
            id: String(assignedFlexField.id),
            text: String(assignedFlexField.text),
            value: String(assignedFlexField.value),
          })
        ),
        testId: secondPanelDraggableId,
      };
      setFirstList(unassignedClients);
      setSecondList(assignedClients);
    }
  }, [assignedPanelResponsePayload]);
  

  const profileListItems = useMemo(() => {
    if (profileList && Array.isArray(profileList)) {
      return profileList;
    }

    // Return empty array in case of API data fetching error
    return [];
  }, [profileList]);

  // const accountantListItems = useMemo(() => {
  //   if (accountantList && Array.isArray(accountantList)) {
  //     return accountantList;
  //   }

  //   // Return empty array in case of API data fetching error
  //   return [];
  // }, [accountantList]);
  const accountantListItems = useMemo(() => {
    if (accountantList && Array.isArray(accountantList)) {
      let resources: IDropDownListResponse[] = accountantList.map(
        (resourceItem: IDropDownListResponse) => ({
          id: resourceItem.id,
          label: resourceItem.label,
          value: resourceItem.value,
        })
      );

      resources.unshift({
        label: "All",
        value: "All",
      });
      setAccountantListItem(accountantList)
      return resources;
    }

    return [
      {
        label: "All",
        value: "All",
      },
    ];
  }, [accountantList]);
  console.log("accountantListItems", accountantListItems);

  useEffect(() => {
    if (selectedBillingProfile && selectedBillingProfile.length > 0) {
      refetchAssignedPanels();
    }
  }, [refetchAssignedPanels, selectedBillingProfile]);


  const onAccountantSelectionChangeHandler = (item: IEnhancedDropDownItem) : void => {
    // setSelectedAccountant(String(item.value));
    // refetchAssignedPanels();
    let selectedValue = String(item.value);
    if (selectedAccountant !== selectedValue) {
      setSelectedAccountant(selectedValue);
      setSelectedBillingProfile("")
      formMethods.setValue("selectedTaxProfile", "");
    }
  };

  const onBillingProfileSelectionChangeHandler = (
    item: IEnhancedDropDownItem
  ) => {
    setSelectedBillingProfile(String(item.value));
    // refetchAssignedPanels();
  };

  console.log("accountantListItems",accountantListItems)
  console.log("selectedAccountant",selectedAccountant)
  console.log("selectedBillingProfile",selectedBillingProfile)
  console.log("assignedPanelResponsePayload",assignedPanelResponsePayload)
  console.log("firstList",firstList)
  console.log("secondList",secondList)

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: BILLING_PROFILE_ASSOCIATION_SETUP_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create",
        activityLogVisibility: true,
      })
    );
  }, []);
  
  return (
    <>
      <BackdropCircularProgress
        open={
          isSubmitting ||
          isLoadingTabDropDownList ||
          isLoadingAccountantDropDownList ||
          isLoadingAssignedClients 
        }
        testId={`${testId}-backdrop`}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            children={
              <>
                <StandardPanel
                  title="Billing Profile Association"
                  testId={`${testId}-screen-heading-panel`}
                  leftSpacing={2}
                  rightSpacing={2}
                >
                  <EnhancedDropDownRHFWithLabel
                    testId={`${testId}-name`}
                    label="Select Accountant"
                    labelPosition={LabelPosition.Left}
                    required
                    selectName="selectedAccountant"
                    // items={[
                    //   { label: "Accountant 1", value: "1" },
                    //   { label: "Accountant 2", value: "2" },
                    // ]}
                    items={accountantListItems}
                    selectedItem={"All"}
                    onSelectionChangeHandler={
                      onAccountantSelectionChangeHandler
                    }
                  />
                  <EnhancedDropDownRHFWithLabel
                    testId={`${testId}-name`}
                    label="Select Profile"
                    labelPosition={LabelPosition.Left}
                    required
                    selectName="selectedTaxProfile"
                    // items={[
                    //   { label: "Global Profile", value: "1" },
                    //   { label: "Zero Profile", value: "2" },
                    // ]}
                    items={profileListItems}
                    selectedItem={selectedBillingProfile}
                    onSelectionChangeHandler={
                      onBillingProfileSelectionChangeHandler
                    }
                  />
                </StandardPanel>
                {selectedAccountant.length > 0 &&
                  selectedBillingProfile.length > 0 && (
                    <Box sx={{ pt: theme.spacing(3) }}>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <DNDTwoColumnList
                          firstColumnListTitle="UnAssociations"
                          firstColumnList={firstList}
                          secondColumnListTitle="Associations"
                          secondColumnList={secondList}
                          testId="billing-profile-assignment"
                        />
                      </DragDropContext>
                    </Box>
                  )}
              </>
            }
            toolbar={
              // <StandardSubmitFormButtonsToolbar
              //   toolbarContents={
              //     <SubmitButton
              //       label={"Save"}
              //       testId="save-button"
              //       variant={SubmitButtonVariant.Secondary}
              //       icon={<PlaylistAddCheckOutlinedIcon />}
              //     />
              //   }
              //   onResetClicked={onResetClicked}
              //   isSubmitting={isSubmitting}
              //   testId="billing-profile-client-assignment-toolbar"
              // />
              <></>
            }
            testId="form-layout"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </>
  );
};
