import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./JournalExportDataTableStyle.css";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { RequestParams, SplitButton } from "@websential/cosmic";
import { Box } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SearchIcon from '@mui/icons-material/Search';

export interface IDataTable {
  rows: any;
  columns: any;
  showIndexColumn: boolean;
  showChecboxColumn: boolean;
  showSettingColumn: boolean;
  onRowDeleteClicked: (params: GridRenderCellParams) => void;
  isLoading?: boolean;
  onSnackbarMessageData: (value: any) => void;
  onRequestParamsChange?: (params: RequestParams) => void;
  initialPageSize?: number | undefined;
  initialPage?: number;
  totalRecords?: number;
}

export const PrimeDataGridJournalExport: React.FC<IDataTable> = ({
  rows,
  columns,
  showIndexColumn,
  showChecboxColumn,
  showSettingColumn,
  onRowDeleteClicked,
  isLoading,
  onSnackbarMessageData,
  onRequestParamsChange,
  initialPageSize = 10,
  initialPage = 0,
  totalRecords,
}) => {  
  const isInitialRequest = useRef(true);
    const [itemSearch, setItemSearch] = useState('');
    const [requestParams, setRequestParams] = useState<any>({
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        sortField: null,
        sortOrder: null,
        searchQuery:"",
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });
  // const dt = useRef<any>(null);
  const finalTableRows = rows && rows.flat().map((x:any,index:any)=>{
    return {
      acct:"SJ",
      ...x,
      key:index % 2 === 0 ? '0' : '1'
    }
  })

  const saveAsExcelFile = (buffer:any, fileName:any) => {
      import('file-saver').then((module) => {
          if (module && module.default) {
              let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
              let EXCEL_EXTENSION = '.xlsx';
              const data = new Blob([buffer], {
                  type: EXCEL_TYPE
              });

              module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
          }
      });
  };
  const generateCSVData = (data: any[]) => {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map(item => Object.values(item).join(','));
    return `${header}\n${rows.join('\n')}`;
  };
  const onExportButtonClick = (event:any) => {
    if(event === 'Excel'){
      import('xlsx').then((xlsx) => {
        if(rows){
        const worksheet = xlsx.utils.json_to_sheet(finalTableRows);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAsExcelFile(excelBuffer, 'Journal Export');
        }
    });
    } else if(event === 'CSV'){
      // dt.current.exportCSV(false);
      const csvData = generateCSVData(finalTableRows);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'journal_export.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
    const renderHeader = () => {
        return (
                <Box 
                  sx={{ 
                    marginRight:"1rem", 
                    textTransform:"capitalize", 
                    display:"inline-block", 
                    "& .MuiPopper-root":{zIndex:2,left:"-17px",width:"107px"},
                    "& .MuiMenuItem-root:first-child":{display:"none"},
                    }}>
                    <SplitButton
                    options={[
                      "Excel",
                      "CSV"
                    ]}
                    onClickHandler={onExportButtonClick}
                    testId="t4-print-button"
                    icon={<ExitToAppIcon/>}
                    listItemsLabel={`Export To`}
                  />
              </Box>
        )
    }
    const headerSearch = renderHeader();
  // Custom column for row indices
  // const indexColumn = {
  //   body: (rowData:any, row:any) => {
  //     console.log("rowData",rowData)
  //     console.log("rowIndex",row.rowIndex)
  //     return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
  //   },
  //   header: '#',
  // };
  // const rowsLength = <span style={{color:"red"}}>{rows ? rows.length : 0}</span>
  // const ACCT = {
  //   body: (rowData:any, row:any) => {
  //     return <span>SJ</span>; // +1 to start indexing from 1 instead of 0
  //   },
  //   header: (
  //     <span style={{display:"flex",justifyContent:"space-between"}}>
  //       @ACCTLEDG(A SCII):ACCT
  //       <span style={{ color: 'red' }}>{rowsLength}</span>
  //     </span>
  //   ),
  // };
  // const Key = {
  //   body: (rowData:any, row:any) => {
  //     return <span>{row.rowIndex % 2 === 0 ? '0' : '1'}</span>; // +1 to start indexing from 1 instead of 0
  //   },
  //   header: 'KEY',
  // };
  console.log("props headerColumns => ",columns)
  console.log("props items General Clients => ",rows)
  console.log("finalTableRowsfinalTableRows",finalTableRows)

  const onPage = (event:any) => {
    setRequestParams(event);
};
// This useEffect is triggered when the debouncedSearchQuery, pageSize or currentPage values change
useEffect(() => {
  // if the results fetcher was passed, we can use it to fetch the results.
  if (onRequestParamsChange) {
    // skip the first initial call
    if (isInitialRequest.current === true) {
      console.log(
        "##### => useEffect - requestParams - no first call",
        requestParams
      );
      isInitialRequest.current = false;
    } else {
      console.log(
        "##### => useEffect - requestParams - second call",
        requestParams
      );
      onRequestParamsChange(requestParams);
    }
  }
}, [onRequestParamsChange, requestParams]);

  return (
      <DataTable
        value={rows && finalTableRows && finalTableRows}
        responsiveLayout="scroll"
        lazy 
        // filterDisplay="row"
        // dataKey="id" 
        paginator
        first={requestParams.first} 
        rows={requestParams.rows}
        totalRecords={totalRecords}
        onPage={onPage}

        // paginator
        // rows={25}
        rowsPerPageOptions={[25, 50, 100]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={`Showing {first} to {last} of ${totalRecords} Items`}
        showGridlines
        resizableColumns
        columnResizeMode="expand"
        scrollable
        reorderableColumns
        loading={isLoading}
        header={headerSearch}
        emptyMessage="No Record found."
        className="JournalExportDataTable"
        style={{ margin: "1.5rem 0.5rem 0.5rem 0.5rem"}}
        // ref={dt}
      >

        {/* <Column {...ACCT} className="acct"/> */}

        {columns &&
          columns.map((x: any, index: any) => (
            <Column
              key={index}
              field={x.field}
              header={x.headerName}
              headerTooltip={x.description}
              headerTooltipOptions={{ position: "top" }}
              hidden={x.hide}
              sortable={x.sortable}
              resizeable={x.resizable}
              dataType={x.type}
              align={x.align}
              alignHeader={x.headerAlign}
              reorderable={x.disableReorder}
              exportable={x.disableExport}
              frozen={x.frozenColumn}
              alignFrozen={x.alignFrozen}
              columnKey={x.field}
              style={{
                minWidth: x.minWidth,
                fontSize: "0.875rem"
              }}
            />
          ))}

        {/* <Column {...Key} /> */}
      </DataTable>
  );
};

//resizeablbe must be false if frozen is true and viseversa
