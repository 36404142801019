export interface IFixedAndMetaField {
  id?: string
  fixedFields: {
    [key: string]: any;
  },
  metaFields: {
    [key: string]: any;
  }
}

export const AddressFields = ["isPrimary", "appAddressTypeId", "addressLine1", "addressLine2", "city", "postalCode", "provinceId", "countryId", "country", "province", "addressId", "id"];

export const FlexFieldsUtils = {
  formatFixedAndMetaFields: (fromInputData: any, metaFieldIds: { [key: string]: string }): any => {
    // This is sample data used to test the function
    // fromInputData = {
    //   accTitle_3_fixed: "asdf",
    //   addressLine1_9_fixed: "asdfasdf",
    //   city_7_fixed: "asdf",
    //   countryId_5_fixed: "1",
    //   email_4_fixed: "asdf",
    //   ext1_11: "asdf",
    //   firstName_1_fixed: "basdf",
    //   lastName_2_fixed: "asdf",
    //   phone1_10: "asdf",
    //   postal_code_8_fixed: "a9a9a9",
    //   provinceId_6_fixed: "1",
    //   website_12: "asdf"
    // };

    console.log('#####', metaFieldIds);
    let fixedAndMetaFields: IFixedAndMetaField = {
      fixedFields: {},
      metaFields: []
    };

    Object.entries(fromInputData).forEach(([key, value], index) => {
      let keyParts = key.split('-');
      if (keyParts.length === 3) {
        let isAddressField = AddressFields.find(element => element === keyParts[0]);
        if (!isAddressField) {
          fixedAndMetaFields.fixedFields[keyParts[0]] = value
        } else {
          if (!fixedAndMetaFields.fixedFields.hasOwnProperty("address")) {
            fixedAndMetaFields.fixedFields['address'] = {}
          }

          fixedAndMetaFields.fixedFields.address[keyParts[0]] = value
        }
      } else if (keyParts.length === 2) {
        let metaFieldId = metaFieldIds[keyParts[0]];
        fixedAndMetaFields.metaFields.push({
          "id": metaFieldId,
          "fieldId": keyParts[1],
          "name": keyParts[0],
          "value": value
        })
      }
    });

    return fixedAndMetaFields;
  },
  formatFixedAndMetaFieldsWithAddressArray: (fromInputData: any, metaFieldIds: { [key: string]: string }): any => {
    // This is sample data used to test the function
    // fromInputData = {
    //   accTitle_3_fixed: "asdf",
    //   addressLine1_9_fixed: "asdfasdf",
    //   city_7_fixed: "asdf",
    //   countryId_5_fixed: "1",
    //   email_4_fixed: "asdf",
    //   ext1_11: "asdf",
    //   firstName_1_fixed: "basdf",
    //   lastName_2_fixed: "asdf",
    //   phone1_10: "asdf",
    //   postal_code_8_fixed: "a9a9a9",
    //   provinceId_6_fixed: "1",
    //   website_12: "asdf"
    // };

    console.log('#####', metaFieldIds);
    console.log('#####fromInputData --------------', fromInputData);
    let fixedAndMetaFields: IFixedAndMetaField = {
      fixedFields: {},
      metaFields: []
    };

    Object.entries(fromInputData).forEach(([key, value], index) => {
      let keyParts = key.split('-');
      console.log("key=================",key)
      console.log("value=================",value)
      console.log("keyParts=================",keyParts)
      if (keyParts.length === 3) {
        let isAddressField = AddressFields.find(element => element === keyParts[0]);
        console.log("isAddressField--",isAddressField)
        if (!isAddressField) {
          fixedAndMetaFields.fixedFields[keyParts[0]] = value;
          console.log("value-----",value)
        } else {
          if (!fixedAndMetaFields.fixedFields.hasOwnProperty("address")) {
            fixedAndMetaFields.fixedFields['address'] = [{}];
            console.log("fixedFields",fixedAndMetaFields.fixedFields['address'])
          }

          fixedAndMetaFields.fixedFields.address[0][keyParts[0]] = value;
        }
      } else if (keyParts.length === 2) {
        let metaFieldId = metaFieldIds[keyParts[0]];
        fixedAndMetaFields.metaFields.push({
          "id": metaFieldId,
          "fieldId": keyParts[1],
          "name": keyParts[0],
          "value": value
        })
      }
    });

    return fixedAndMetaFields;
  }
}
