import { checkSINValidity } from "components/T4Slip/T4SlipSetup/validator";
import * as yup from "yup";
import { currentTaxYear } from "./FilingResourceSetup";
// import { issuerSelectedValidate } from "./FilingResourceSetup";
export const FilingResourceSetupSchema = yup.object().shape({
  // CONTACT PERSON
  name: yup
    .string()
    .required("Name is required!")
    .min(3, "Minimum length 3 characters required")
    .max(50, "Maximum length 50 characters"),

  effectiveFromYear: yup.string().required("Effective Form Year is required!"),

  phone1: yup
    .string()
    // .matches(
    //   /^\(\d{3}\) \d{3}-\d{3}$/,
    //   "Please enter a valid phone number like (777) 777-777."
    // )
    .required("Phone is required!")
    .min(14, "Minimum length 10 characters"),

  // ISSUER
  role: yup
    .array()
    .of(yup.string())
    .min(1, "At least one option must be selected"),
  ownerSin1: yup.string().when("role", {
    is: (val: any) =>
      val[0] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49d" ||
      val[1] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49d" ,
      // val[0] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e",
    then: 
    // yup
    //   .string()
    //   .required("Owner SIN 1 is required!")
    //   .min(9, "Minimum 9 characters required"),
    yup.string().test(
      'is-masked-or-not',
      'Invalid value',
      function (value:any) {
        console.log("valuewe",value)
        if(value){
        let sin = value && value.replace(/-/g, '')
        const output = checkSINValidity(sin);
        console.log("output",output)
        let maxLength = 11;
  
        if (value && value.charAt(2) === '*') {
          maxLength = 7;
          if (value && value.length == maxLength) {
            return true;
          } else {
            return this.createError({
              message: 'Invalid Social Insuarance Number',
              path: this.path,
            });
          }
        } else {
          if (value && value.length == maxLength && output % 10 === 0) {
            return true;
          } else {
            return this.createError({
              message: 'Invalid Social Insuarance Number',
              // message: 'Social Insuarance Number has at least 9 characters',
              path: this.path,
            });
          }
        }
      }
      return this.createError({
        message: 'Social Insuarance Number is required',
        path: this.path,
      });
      }
    ).required('Social Insuarance Number is required')
  }),

  ownerSin2: yup.string().when("role", {
    is: (val: any) =>
      val[0] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49d" ||
      val[1] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49d",
    then: 
    // yup
    //   .string()
    //   .required("Owner SIN 1 is required!")
    //   .min(9, "Minimum 9 characters required"),
    yup.string().test(
      'is-masked-or-not',
      'Invalid value',
      function (value:any) {
        console.log("valuewe12",value)
        if(value){
        let sin = value && value.replace(/-/g, '')
        const output = checkSINValidity(sin);
        console.log("output",output)
        let maxLength = 11;
  
        if (value && value.charAt(2) === '*') {
          maxLength = 7;
          if (value && value.length == maxLength) {
            return true;
          } else {
            return this.createError({
              message: 'Invalid Social Insuarance Number',
              path: this.path,
            });
          }
        } else {
          if (value && value.length == maxLength && output % 10 === 0) {
            return true;
          } else {
            return this.createError({
              message: 'Invalid Social Insuarance Number',
              // message: 'Social Insuarance Number has at least 9 characters',
              path: this.path,
            });
          }
        }
      }
      return true;
      }
    ).nullable()
  }),

  // TRANSMITTER
  craTransmitter: yup.string().when("role", {
    is: (val: any) =>
      currentTaxYear <= 2023 &&     
      (val[0] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[1] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[2] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e"),
    then: yup
      .string()
      .required("CRA Transmitter is required!")
      .min(6, "Minimum 8 characters required")
      // .max(8, "Maximum 8 characters required"),
  }),
  address: yup.string().when("role", {
    is: (val: any) =>
      val[0] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[1] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[2] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e",
    then: yup
      .string()
      .required("Address is required!")
      .min(1, "Atleast 1 character required")
      .max(60, "Maximum 60 characters required"),
  }),
  city: yup.string().when("role", {
    is: (val: any) =>
      val[0] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[1] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[2] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e",
    then: yup
      .string()
      .required("City is required!")
      .min(1, "Atleast 1 character required")
      .max(28, "Maximum 28 characters required"),
  }),
  province: yup.string().nullable().when("role", {
    is: (val: any) =>
      val[0] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[1] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[2] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e",
    then: yup.string().nullable().required("Province is required!"),
  }),
  // country: yup
  // .string()
  // .when("role", {
  //   is: (val: any) => val[0] === '6173ddf7-68e2-426f-b1be-9d1dd63eb49e' || val[1] === '6173ddf7-68e2-426f-b1be-9d1dd63eb49e' || val[2] === '6173ddf7-68e2-426f-b1be-9d1dd63eb49e',
  //   then: yup
  //     .string()
  //     .required("Country is required!"),
  // }),
  postCode: yup.string().when("role", {
    is: (val: any) =>
      val[0] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[1] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[2] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e",
    then: yup
      .string()
      .required("Post Code is required!")
      .min(6, "Atleast 6 character required")
      .max(6, "Maximum 6 characters required"),
  }),
  contactName: yup.string().when("role", {
    is: (val: any) =>
      val[0] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[1] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[2] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e",
    then: yup
      .string()
      .required("Contact Name is required!")
      .min(1, "Atleast 1 character required")
      .max(22, "Maximum 22 characters required"),
  }),
  email: yup.string().when("role", {
    is: (val: any) =>
      val[0] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[1] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" ||
      val[2] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e",
    then: yup
      .string()
      .email("Please enter a valid email.")
      .required("Email is required!"),
  }),

  transmitterAccountNumber: yup.string().nullable()
  .when("role", {
    is: (val: any) =>
      currentTaxYear > 2023 && 
      (val[0] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" || 
       val[1] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e" || 
       val[2] === "6173ddf7-68e2-426f-b1be-9d1dd63eb49e"),
    then: yup.string().test(
      'is-valid-or-not',
      'Invalid value',
      function (value:any) {
        let maxLength = 15;
        console.log("value456",value)
        console.log("value45655",currentTaxYear)
        const firstNineDigits: string = value && value.substring(0, 9);
        console.log("valueeeee-- bn",firstNineDigits)
        const output = checkSINValidity(firstNineDigits);
        console.log("output bn",output)
        
          if (value && value.length == maxLength && output % 10 === 0) {
            return true;
          } else {
            return this.createError({
              message: 'Invalid Account Number',
              path: this.path,
            });
          }
      }
    ).nullable().required('Account Number is required'),
    otherwise: yup.string().nullable()
  }),
});
// if(currentTaxYear){
//   console.log("currentTaxYear",currentTaxYear)
// }
