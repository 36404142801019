import { AxiosResponse } from 'axios';
import http from 'common/http-common';
import { IGetListRequest, IResponse, IServerError } from 'models/common';
import { ILookupFieldListModel } from 'models/lookupField';
import {
  ILookupFieldSetupRequestPayload
} from 'models/lookupField/ILookupFieldSetupRequestPayload';
import {
  ILookupFieldSetupResponsePayload
} from 'models/lookupField/ILookupFieldSetupResponsePayload';

const lookupFields: { [key: string]: any } = {
  "payPeriods": [
    {
      label: "1 - Annually",
      value: 1
    },
    {
      label: "2 - Semi Annually",
      value: 2
    },
    {
      label: "4 - Quarterly",
      value: 4
    },
    {
      label: "12 - Monthly",
      value: 12
    },
    {
      label: "24 - Semi Monthly",
      value: 24
    },
    {
      label: "26 - Bi-Weekly",
      value: 26
    },
    {
      label: "27 - Bi-Weekly",
      value: 27
    },
    {
      label: "52 - Weekly",
      value: 52
    },
    {
      label: "53 - Weekly",
      value: 53
    },
    {
      label: "22 - Pay Periods",
      value: 22
    },
  ]
}

// TODO: replace this with API response once it's ready
export const LookupFieldService = {
  // add: async (addRequestData: ILookupFieldSetupRequestPayload): Promise<ILookupFieldSetupResponsePayload> => {
  //   let response: ILookupFieldSetupResponsePayload;
  //   return new Promise(resolve => setTimeout(() => resolve(response), 1000));
  // },
  create: async (
    requestPayload: ILookupFieldSetupRequestPayload
  ): Promise<ILookupFieldSetupResponsePayload | IServerError | IResponse> => {
    if (requestPayload.id) {
      // const requestUpdatePayload = {
      //   name: requestPayload.name,
      //   updatedBy: requestPayload.createdBy,
      // };

      console.log('requestPayload ==>> update request', requestPayload);
      const response: AxiosResponse<ILookupFieldSetupResponsePayload> = await http.put<ILookupFieldSetupResponsePayload>(
        `/v1/lookup-fields/${requestPayload.id}`, requestPayload);

      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<ILookupFieldSetupResponsePayload> =
      await http.post<ILookupFieldSetupResponsePayload>(
        "/v1/lookup-fields/",
        requestPayload
      );

    if (response.status === 201) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  delLookupField: async (lookupFieldId: number): Promise<string | IServerError | IResponse> => {
    console.log('deleteById', lookupFieldId);
    const response: AxiosResponse<string> = await http.delete<string>(`/v1/lookup-fields/${lookupFieldId}/`);

    if (response.status === 204) {
      return "Lookup Field has been deleted successfully";
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    else if (response.status === 200) {
      return "Lookup Field has been deleted successfully";
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getList: async (
    params: IGetListRequest
  ): Promise<
    ILookupFieldListModel | IServerError | IResponse
  > => {
    // const fixedResponse: ILookupFieldListModel = {
    //   "page": 1,
    //   "size": 5,
    //   "total": 2,
      // "items": [
      //   {
      //     "id": "ab8ccece-16c1-44e9-ba86-2990eefb178c",
      //     "name": "Employment Type",
      //     "slug": "employment-type"
      //   },
      //   {
      //     "id": "4e3823aa-c2e4-45aa-aaaf-9ba1b1486acc",
      //     "name": "Slip Type",
      //     "slug": "slip-type"
      //   }
      // ],
    //   "keyField": {
    //     "field": "id",
    //     "templateUrl": "/lookup-fields/setup/id"
    //   },
    //   "headerColumns": [
    //     {
    //       "field": "name",
    //       "headerName": "Name",
    //       "description": "name",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       // "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       // "isFixed": true,
    //       "sequence": 0
    //     },
    //     {
    //       "field": "slug",
    //       "headerName": "Slug",
    //       "description": "Slug",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       // "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       // "isFixed": true,
    //       "sequence": 1
    //     }
    //   ]
    // }
    // return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    const response: AxiosResponse<ILookupFieldListModel> = await http.get<ILookupFieldListModel>(
      `/v1/lookup-fields/?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`);
    console.log("response lookup filds", response)
    if (response.status === 200) {
      console.log("200 in Lookup fields",response.data)
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getById: async (id: string | undefined): Promise<any | IServerError | IResponse | null> => {
    if (id) {
      const response: AxiosResponse<ILookupFieldListModel> = await http.get<ILookupFieldListModel>(
        `/v1/lookup-fields/${id}/`);
        console.log("id in getbyis in service: ",id)
  
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }

    return null;
  },

  // this api is for t4slip payperiods list 
  getByIdd: async (id: string): Promise<any> => {
    return Promise.resolve(lookupFields[id]);
  },
};
