import { INITIAL_PAGE_SIZE, INITIAL_SNACKBAR_DATA } from "common/constants";
import { IDropDownListResponse, IGetListRequest } from "models";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Grid } from "@mui/material";
import { BackdropCircularProgress, EnhancedSnackbar } from "@websential/cosmic";

import { TransactionsReportDataTable } from "./PrimeDataGrid";
import { AccountantService, TaxProfileService, TaxSlipYearService } from "services";
import { ReportsService } from "services/ReportsService";
import { setSelectedAccountant, setSelectedAccountantName, setSelectedClient, setSelectedClientName } from "store/actions";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface ITransactionsReport {}

const useTransactionsReport = (
  params: IGetListRequest
) =>
  useQuery(
    ["Transaction-report-list", params],
    (data) => {
      return ReportsService.getAdminTransactionsReport(params);
    },
    { refetchOnWindowFocus: false }
  );

const useGetAccountantsDropDownList = () =>
  useQuery(
    "get-accountant-dll",
    () => TaxProfileService.getAccountantsDropDownList(),
    { refetchOnWindowFocus: false }
  );
const useYears = (params: IGetListRequest) =>
  useQuery(
    ["years-list", params],
    (data) => {
      return TaxSlipYearService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

export const TransactionsReport: React.FC<ITransactionsReport> = () => {
  const dispatch = useDispatch();
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const [queryParams, setQueryParams] = useState<any>({
    search_query: "",
    order_by: "client_name",
    order_direction: "asc",
    page: 1,
    size: 25,
    accountantselected: "All",
    invoiceselected: 400,
    yearselected: 2024,
  });

  const { isLoading, data:transactionReport } = useTransactionsReport(queryParams);
  const transactionReportList: any = transactionReport;
  console.log("@@@@@ => ItransactionReport", transactionReportList);

  const {
    isLoading: isLoadingAccountantDropDownList,
    data: accountantDDLList,
    // refetch: refetchTabsDDL,
  } = useGetAccountantsDropDownList();

  const {
    isLoading: isLoadingYearsList,
    data: yearsDDLList,
    // refetch: refetchTabsDDL,
  } = useYears({
    search_query: "",
    order_by: "client_name",
    order_direction: "asc",
    page: 1,
    size: 25,
  });
  const yearsDDLItems = useMemo(() => {
    if (yearsDDLList && Array.isArray(yearsDDLList && yearsDDLList.items)) {
      return (
        yearsDDLList &&
        yearsDDLList.items.map((year: any) => ({
          id: year.id,
          label: year.year,
          value: year.year,
        }))
      );
    }

    // Return empty array in case of API data fetching error
    return [];
  }, [yearsDDLList]);
  console.log("accountantDDLItemsqw", yearsDDLList);
  console.log("accountantDDLItems", yearsDDLItems);

  const accountantDDLItems = useMemo(() => {
    if (accountantDDLList && Array.isArray(accountantDDLList)) {
      let resources: IDropDownListResponse[] = accountantDDLList.map(
        (resourceItem: IDropDownListResponse) => ({
          id: resourceItem.id,
          label: resourceItem.label,
          value: resourceItem.value,
        })
      );

      resources.unshift({
        label: "--All Accountants--",
        value: "All",
      });
      return resources;
    }

    return [
      {
        label: "--All Accountants--",
        value: "All",
      },
    ];
  }, [accountantDDLList]);

  

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: "client_name",
      order_direction: "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
      accountantselected: requestParams.accountantselected,
      invoiceselected: requestParams.invoiceselected,
      yearselected: requestParams.yearselected,
    };
    console.log("dispatchParams", dispatchParams);

    setQueryParams(dispatchParams);
  }, []);

  console.log("queryParams", queryParams);

  let data = {
    "page": 1,
    "size": 50,
    "total": 3,
    "items": [
      {
        "clientId": "64be1e04-7e13-4aea-8af5-1ac5325ea239",
        "clientName": "12343",
        "accountantId": "64be1e04-7e13-4aea-8af5-1ac5325ea239",
        "t4TotalSlip": 4,
        "rl1TotalSlip": 2,
        "t4aTotalSlip": 0,
        "t4TotalSummary": 0,
        "rl1TotalSummary": 1,
        "t4aTotalSummary": 0,
        "duplicationExist": "No"
      },
      {
        "clientId": "48f866b2-f3a1-4248-b6c6-1523511c8605",
        "clientName": "after live",
        "accountantId": "f3193f46-dd18-4a99-8d71-68300fba1b2f",
        "t4TotalSlip": 2,
        "rl1TotalSlip": 1,
        "t4aTotalSlip": 1,
        "t4TotalSummary": 0,
        "rl1TotalSummary": 1,
        "t4aTotalSummary": 0,
        "duplicationExist": "No"
      },
      {
        "clientId": "c8c79f95-90fa-4d32-b3ec-5bee22414909",
        "clientName": "T222",
        "accountantId": "f3193f46-dd18-4a99-8d71-68300fba1b2f",
        "t4TotalSlip": 4,
        "rl1TotalSlip": 2,
        "t4aTotalSlip": 2,
        "t4TotalSummary": 1,
        "rl1TotalSummary": 0,
        "t4aTotalSummary": 0,
        "duplicationExist": "No"
      }
    ],
    "keyField": {
      "field": "client_name",
      "templateUrl": "/taxslip/client/setup/id"
    },
    "headerColumns": [
      {
        "field": "clientName",
        "headerName": "Client Name",
        "description": "Client Name",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 0,
        "frozenColumn": false,
        "footer": "Total"
      },
      {
        "field": "t4TotalSlip",
        "headerName": "T4",
        "description": "T4",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 1,
        "frozenColumn": false,
        "footer": 10
      },
      {
        "field": "t4TotalSummary",
        "headerName": "T4 Summary",
        "description": "T4 Summary",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 2,
        "frozenColumn": false,
        "footer": 1
      },
      {
        "field": "rl1TotalSlip",
        "headerName": "RL-1",
        "description": "RL-1",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 3,
        "frozenColumn": false,
        "footer": 5
      },
      {
        "field": "rl1TotalSummary",
        "headerName": "RL-1 Summary",
        "description": "RL-1 Summary",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 4,
        "frozenColumn": false,
        "footer": 2
      },
      {
        "field": "t4aTotalSlip",
        "headerName": "T4A",
        "description": "T4A",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 5,
        "frozenColumn": false,
        "footer": 3
      },
      {
        "field": "t4aTotalSummary",
        "headerName": "T4A Summary",
        "description": "T4A Summary",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 6,
        "frozenColumn": false,
        "footer": 0
      },
      {
        "field": "duplicationExist",
        "headerName": "Duplication Exist",
        "description": "Duplication Exist",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 7,
        "frozenColumn": false,
        "footer": null
      }
    ]
  }
  const onClientClickHandler = async (clickedRow: any) => {
    console.log("clickedRow", clickedRow);
    //get accountant titile 
    // try {
    //   const response = await AccountantService.getById(clickedRow.accountantId);
    //   console.log("response11",response)
      dispatch(setSelectedAccountantName({accTitle:clickedRow.accTitle}));
    // } catch (err) {
    //   console.log("err", err);
    // }
    dispatch(setSelectedAccountant({id:clickedRow.accountantId}));
    dispatch(setSelectedClient({id:clickedRow.clientId}));
    dispatch(setSelectedClientName({clientName:clickedRow.clientName}));
  };

  const sortedItems = transactionReport && transactionReport?.items.toSorted((a:any, b:any) => a.clientName.localeCompare(b.clientName));
  const updatedRowsWithClientNavigation = transactionReport && sortedItems && sortedItems.map((row: any) => {
    return {
      ...row,
      clientName: (
        <Link
          to={"/clients/dashboard"}
          className="no-underline"
          style={{ color: "blue" }}
          onClick={() => onClientClickHandler(row)}
        >
          {row.clientName}
        </Link>
      ),
    };
  });
  // console.log("yearsList",yearsList)

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: '',
        companyName: '',
        activityType:
          "",
        activityLogVisibility: false,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ width: "100%", overflow: "hidden" }}
    >
      <BackdropCircularProgress open={false} testId="TransactionsReport" />
      <TransactionsReportDataTable
        rows={transactionReport && updatedRowsWithClientNavigation}
        columns={transactionReport ? transactionReport?.headerColumns : []}
        showIndexColumn={true}
        initialPageSize={INITIAL_PAGE_SIZE}
        totalRecords={transactionReport ? transactionReport.total : 0}
        onRequestParamsChange={onRequestParamsChange}
        accountantDDLList={accountantDDLItems}
        yearsDDL={yearsDDLItems}
        // onRowDeleteClicked={onRowDeleteClicked}
        isLoading={isLoading}
      />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`TransactionsReport-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
