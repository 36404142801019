import {
  EMPLOYEE_RESOURCE_LITERAL,
  GET_QUERY_KEY,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  T4_SETUP_SCREEN_ID,
  T4_SETUP_SCREEN_SLUG,
} from "common/constants";
import {
  instanceOfIFlexFieldSetupRequest,
  instanceOfIT4SlipResponce,
} from "common/instance-method";
import { useGetResourceDDLData } from "hooks/useResources";
import { IResponse, IServerError } from "models/common";
import { IDropDownListResponse } from "models/common/IDropDownListResponse";
import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { FlexFieldService } from "services/FlexFieldService";
import { T4SlipService } from "services/T4SlipService";
import { DefaultSettingsService } from "services/DefaultSettingsService";

// import { ResourceService } from "services/ResourceService";
import { IAppState } from "store/reducers";
import FileSaver from "file-saver";
export let fileeeee: any = undefined;
export let checkboxDefaultSelect: any = false;
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import {
  // BackdropCircularProgress,
  DeleteConfirmationDialog,
  EnhancedDropDownRHFWithLabel,
  EnhancedIOSSwitchRHFWithLabel,
  EnhancedSnackbar,
  FieldLayout,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  StandardPanel,
  TwoColumnLayout,
} from "@websential/cosmic";
import {
  EnhancedDialog,
  EnhancedLinkButton,
  IconTextButton,
  RouterLinkButton,
  SplitButton,
} from "@websential/cosmic";
import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions";
import { v4 as uuidv4 } from "uuid";

import {
  EmployeeAddressSetupFieldsPanel,
  EmployeeInfoSetupFieldsPanel,
  FilingDataSetupFieldsPanel,
  FilteredSelectedOtherInfoT4,
  T4SlipDataPanel,
  applyAdjustment,
  provinceOfEmploymentRes,
  provinceResponce,
  selectedPOE,
} from "./Panels";
import { T4SlipSetupToolbar } from "./T4SlipSetupToolbar";
import { T4SlipSetupValidationSchema } from "./validator";
import { IT4SlipRequestPayload } from "models/t4Slip/IT4SlipRequestPayload";
import { ICountryReferenceModel } from "models/country/ICountryReferenceModel";
import { useNavigate, useParams } from "react-router-dom";
import { IProvinceReferenceModel } from "models/province/IProvinceReferenceModel";
import {
  PROVINCE_OF_EMPLOYMENT,
  ProvinceService,
  getPROVINCE_OF_EMPLOYMENT,
} from "services/common/ProvinceService";
import { AnyObject } from "yup/lib/object";
import { PrintingService } from "services/PrintingService";

import { format } from "date-fns";
import { Calendar } from "primereact/calendar";
import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { T4OtherInformationService } from "components/OtherInformation/otherInformations";
import { InvoiceDialogPanel } from "../T4SlipList/InvoiceDialogPanel";
import { TaxSlipSettingsService } from "services/TaxSlipSettingsService";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
// import { Card } from "primereact/card";
import Card from "@mui/material/Card";
import { Divider } from "primereact/divider";
import { Avatar } from "primereact/avatar";
import "./T4SlipSetupStyle.css";
import NativeSelect from "@mui/material/NativeSelect";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { BackdropCircularProgress } from "components/BackDrop";
import { ClientService } from "services";
export let selectedSlipTypee: any = 400;
export interface IT4SlipSetup extends ITest {}
const SINGLE_RECORD_DATA_QUERY_KEY = "t4-slips-single-data-record";
const SINGLE_Employee_DATA_QUERY_KEY = "employee-data";
const ADJUSTMENT_DATA_QUERY_KEY = "adjusted-data";
const DEFAULT_SETTINGS_DATA_QUERY_KEY = "default-settings-data-record";

const useGetData = (
  id: string | undefined,
  clientId: string,
  taxYear: number
) => 
   useQuery(
    [SINGLE_RECORD_DATA_QUERY_KEY, id],
    (data) => {
      return T4SlipService.getT4SlipById(id, clientId, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

const useGetGlobalVariables = (taxYear: number) =>
  useQuery(
    [GET_QUERY_KEY.GET_GLOBAL_VARIABLES_BY_YEAR, taxYear],
    (data) => {
      return TaxSlipSettingsService.getGlobalVariables(taxYear);
    },
    { refetchOnWindowFocus: false }
  );

const useGetSlipRecord = (
  slipId: string,
  clientId: string,
  taxYear: number
) => {
  return useQuery(
    [GET_QUERY_KEY.EMPLOYEE_RESOURCE_SLIP_DETAIL, slipId],
    () => T4SlipService.getById(slipId, clientId, taxYear),
    { ...QUERY_OPTIONS, enabled: false }
  );
};

const useGetResourceSlips = (
  resourceId: string,
  clientId: string,
  taxYear: number
) => {
  return useQuery(
    [GET_QUERY_KEY.EMPLOYEE_RESOURCE_SLIPS_DDL, resourceId],
    () =>
      T4SlipService.getResourceSlipDropDownList(resourceId, clientId, taxYear),
    { ...QUERY_OPTIONS, enabled: false }
  );
};

const useGetResourceInformationById = (
  resourceId: string,
  clientId: string,
  roleName: string
) => {
  return useQuery(
    [SINGLE_Employee_DATA_QUERY_KEY, resourceId, clientId, roleName],
    () =>
      T4SlipService.getResourceInformationById(resourceId, clientId, roleName),
    QUERY_OPTIONS
  );
};

const useGetDefaultSettingsData = (
  clientId: string,
) => 
  useQuery(
    [DEFAULT_SETTINGS_DATA_QUERY_KEY, clientId],
    (data) => {
      return DefaultSettingsService.getDefaultSettings(clientId);
    },
    { refetchOnWindowFocus: false }
  );

// const useGetDropDownList = (clientId: string) =>
//   useQuery(
//     GET_QUERY_KEY.EMPLOYEE_RESOURCE_DDL,
//     () => ResourceService.getDropDownList(clientId, "Employee"),
//     QUERY_OPTIONS
//   );

const parseJSONLikeString = (str: string) => {
  // Use regular expressions to add double quotes around keys and string values
  const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');

  // Convert the modified JSON string to an object using JSON.parse()
  return JSON.parse(modifiedString);
};


const useGetSlipIds = (
  tag: string,
  clientId: string,
  taxYear: number
) =>
  useQuery(
    ["get-slip-ids", tag, clientId, taxYear],
    (data) => {
      return T4SlipService.getSlipIds(tag, clientId, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

  const useGetCompanyData = (id: string | undefined) => 
    useQuery(
      ["client-get-by-id", id],
      (data) => {
        return ClientService.getById(id);
      },
      { refetchOnWindowFocus: false }
    );

export const T4SlipSetup: FC<IT4SlipSetup> = ({ testId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );

  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [adjustment, setAdjustment] = useState(false);
  const [isLoadingAdjustedData, setIsLoadingAdjustedData] = useState(false);
  const [isLoadingSlipListDDL, setIsLoadingSlipListDDL] = useState(false);
  const [selectedResourceId, setSelectedResourceId] = useState("");
  const [selectedResource, setSelectedResource] = useState("create-new");
  const [showbox17IfQC, setShowbox17IfQC] = useState(false);
  const [selectedResourceSlip, setSelectedResourceSlip] =
    useState("create-new");
  const [selectedResourceSlipList, setSelectedResourceSlipList] = useState<
    IDropDownListResponse[]
  >([{ label: "Create New", value: "create-new" }]);

  const [selectedResourceList, setSelectedResourceList] = useState<
    IDropDownListResponse[]
  >([{ label: "Create New", value: "create-new" }]);
  const [resourceListWithoutCreateNew, setResourceListWithoutCreateNew] =
    useState<any>(undefined);
  const [openInvoiceDialog, setInvoiceDialogOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [isLoadingDataa, setIsLoadingData] = useState(false);

  const [onResourceSelectionAPIsCalls, setOnResourceSelectionAPIsCalls] =
    useState(false);

  const [editedResourceId, setEditedResourceId] = useState("");
  // const [newCreatedSlipId, setNewCreatedSlipId] = useState('');
  // const [newCreatedResourceId, setNewCreatedResourceId] = useState('');
  const [selectedOtherInformation, setSelectedOtherInformation] =
    useState(false);
  const [oiInEdit, setOiInEdit] = useState(false);
  // const [editedRecordSlipCreateNew, setEditedRecordSlipCreateNew] = useState(false);

  // for delete row
  const [openDialog, setDialogOpen] = useState(false);
  const [apiAction, setApiAction] = useState("");
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [t4SlipId, setT4SlipId] = useState<any>("");
  const [selectedProvinceOfEmployment, setSelectedProvinceOfEmployment] =
    useState("");
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [itemSearch, setItemSearch] = useState("");
  const [sidePanelsVisibility, setSidePanelsVisibility] = useState(true);

  //one state which store both new cretaed and edited slip data
  const [T4SlipRecord, setT4SlipRecord] = useState<any>(undefined);
  // when  I setValue of otherinformation inside async calls method then facing issue while click on add more so we have to use state method to show otherinformation 
  const [slipOtherInformation, setSlipOtherInformation] =
    useState<any>([]);

    const [invoicePaymentFor, setInvoicePaymentFor] = useState(10)
  // let T4SlipRecord = undefined

  // const queryClient = useQueryClient();
  console.log("selectedResourceSlip", selectedResourceSlip);

  // const [selectedProvince, setSelectedProvince] =
  //   useState<IProvinceReferenceModel>({
  //     id: null,
  //     name: "",
  //     shortCode: "",
  //   });

  const [CountryResponse] = useState<ICountryReferenceModel>({
    id: 1,
    name: "Canada",
    shortCode: "CAN",
  });

  const [dateOfBirth, setDateOfBirth] = useState<any>("");

  console.log("dateOfBirthdateOfBirth", dateOfBirth);

  // for edit
  const queryClient = useQueryClient();
  const { id } = useParams();
  console.log("id from setup file which clicked : ", id?.length);
  let notStartedSlipId = "";
  let completedSlipId = "";
  if (id !== undefined && onResourceSelectionAPIsCalls === false) {
    if (id?.length > 24) {
      notStartedSlipId = id;
    }
    if (id?.length === 24) {
      completedSlipId = id;
    }
  }
  console.log("notStartedSlipId", notStartedSlipId);
  console.log("completedSlipId", completedSlipId);

  const DEFAULT_VALUES = {
    clientId: clientId,
    taxYear: taxYear,
    // id: null,
    // applyAdjustments:false,
    // saveAdjutments:false,
    // reflectMasterData:false,
    // slipType:100,
    // slipStatus:100,
    // pensionablePeriod:0,
    // otherInformation:{
    //   code:'',
    //   label:'',
    //   amount:0
    // },
    // resource:{
    //   externalId: null,
    // firstName: '',
    // initials: '',
    // lastName: '',
    // dateOfBirth: '01-01-2000',
    // socialInsuranceNumber: '',
    // address: '',
    // city: '',
    // province: {
    //   id:undefined,
    //   name:'',
    //   shortCode:''
    // },
    // country: {
    //   id:undefined,
    //   name:'',
    //   shortCode:''
    // },
    // postCode:'',
    // provinceOfEmployment: {
    //   id:undefined,
    //   name:'',
    //   shortCode:''
    // },
    // email: '',
    // employeeCode: 0,
    // cppQppExempt: false,
    // eiExempt: false,
    // ppipExempt: false,
    // employmentCode: 11,
    // payPeriod: 1,
    // reducedEi: false
    // },
    // overRemittance:0,
    slipData: {
      employmentIncome: "0.00",
      cppContributions: "0.00",
      qppContributions: "0.00",
      eiPremiums: "0.00",
      rppContributions: "0.00",
      incomeTaxDeducted: "0.00",
      eiInsurableEarnings: "0.00",
      cppQppPensionableEarnings: "0.00",
      unionDues: "0.00",
      charitableDonations: "0.00",
      registrationNumber: undefined,
      pensionAdjustment: "0.00",
      ppipPremiums: "0.00",
      ppipInsurableEarnings: "0.00",
    },
    adjustedSlipData: {
      employmentIncome: "0.00",
      cppContributions: "0.00",
      qppContributions: "0.00",
      eiPremiums: "0.00",
      incomeTaxDeducted: "0.00",
      eiInsurableEarnings: "0.00",
      ppipPremiums: "0.00",
      ppipInsurableEarnings: "0.00",
    },
  };

  const formMethods = useForm<any>({
    resolver: yupResolver(T4SlipSetupValidationSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  console.log("formMethods", formMethods);
  console.log("dob", formMethods.getValues("dateOfBirth"));

// Function to sort the data array by the label field
// sort side cards items 
const sortData = (data: IDropDownListResponse[]): IDropDownListResponse[] => {
  return data.sort((a, b) => a.label.localeCompare(b.label));
};

  const { isLoading: isSubmitting, mutate: submitFlexFields } = useMutation(
    T4SlipService.create,
    {
      onSuccess: (
        res: IT4SlipRequestPayload | IServerError | IResponse | any
      ) => {
        console.log("resonce--=", res);
        console.log("resonce Data-=", res.data);
        if (res.data && instanceOfIT4SlipResponce(res.data)) {
          // remove the cache of the flex fields since we just added a new field.
          // queryClient.invalidateQueries(LIST_FIELDS_QUERY_KEY);
          // // [corner case] remove the cache of the field that has just been edited in-case the user re-selects it again
          // queryClient.invalidateQueries([FLEX_FIELD_DATA_QUERY_KEY, res.id]);
          // set the currently added/edited flex field as the selected field

          // commented bcs of ei insuarable earning value issue if i set 50k then if unmask data dost show saved 50k value
          queryClient.invalidateQueries([SINGLE_RECORD_DATA_QUERY_KEY, res.data.id]);
          
          formMethods.setValue("id", res.data.id);
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
          refectchT4SlipIds()
          
          if(res.status === 201 && selectedResourceSlip === "create-new"){
            (async () => {
              try {
                console.log("ïnside-=",selectedResourceSlip)
                const response: any =
                  await T4SlipService.getResourceSlipDropDownList(
                    res.data?.resource?.externalId,
                    clientId,
                    taxYear
                  );
                  if (response && Array.isArray(response)) {
                    let resourceSlipList: IDropDownListResponse[] =
                      response.map((resourceSlip: IDropDownListResponse) => ({
                        label: resourceSlip.label,
                        value: resourceSlip.value,
                      }));

                    resourceSlipList.unshift({
                      label: "Create New",
                      value: "create-new",
                    });

                    console.log("resourceSlipListDDL", resourceSlipList);
                    setSelectedResourceSlipList(resourceSlipList);
                    formMethods.setValue(
                      "selectedSlip",
                      res.data.id
                    );
                    // createNewActionFromSlipDDL();
                    // formMethods.setValue("comparison", [
                    //   { amount: 0, code: "", label: "" },
                    // ]);
                    let path = `/taxslip/t4/setup/${res.data.id}`;
                    navigate(path);
                  }
                } catch (error) {
                  console.error("Error fetching data:", error);
                }
              })();
            setOnResourceSelectionAPIsCalls(true)
            setSelectedResourceSlip(res.data.id)
          }
        } else {
          setSnackbarData({
            open: true,
            message: res.message ? res.message : res.data.message,
            severity: "error",
          });
        }
        //Load EMployee DDL
        const fetchData = async () => {
          try {
            const response: any = await T4SlipService.getEmployeeDDL(
              clientId,
              EMPLOYEE_RESOURCE_LITERAL,
              taxYear
            );
            console.log("responseData-----------------", response);

            if (response && Array.isArray(response)) {
              let resources: IDropDownListResponse[] = response.map(
                (resourceItem: IDropDownListResponse) => ({
                  // id: resourceItem.id,
                  label: resourceItem.label,
                  value: resourceItem.value,
                })
              );

              resources.unshift({
                label: "Create New",
                value: "create-new",
              });

              setSelectedResourceList(resources);
            }
            setResourceListWithoutCreateNew(sortData(response));
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };

        fetchData();

        console.log('setSelectedResourceSlip',selectedResourceSlip)
        //load slip Data
        if (res && res.data && res.data?.resource) {
          setT4SlipRecord(res.data);
          setSelectedResource(res.data?.resource?.externalId);
          //   Object.keys(res).forEach((fieldName) => {
          //     console.log("fieldName useEffect T4 Slip : when created ", fieldName);
          //     // @ts-ignore
          //     const fieldValue = res[fieldName];
          //     console.log("fieldValue useEffect T4 Slip : ", fieldValue);
          //     // @ts-ignore

          //     formMethods.setValue(fieldName, fieldValue);
          //     if (typeof fieldValue !== "object") {
          //       // @ts-ignore
          //       formMethods.setValue(fieldName, fieldValue);
          //     } else if (fieldValue !== null || undefined) {
          //       console.log("object");
          //       Object.keys(fieldValue).forEach((objFieldName: any) => {
          //         console.log("objFieldName useEffect T4 Slip : when created", objFieldName);
          //         // @ts-ignore
          //         const objFieldValue = fieldValue[objFieldName];
          //         console.log("objFieldValue useEffect T4 Slip : ", objFieldValue);

          //         formMethods.setValue(objFieldName, objFieldValue);
          //         formMethods.setValue(objFieldName, objFieldValue);
          //         if (typeof objFieldValue !== "object") {
          //           // @ts-ignore
          //           formMethods.setValue(objFieldName, objFieldValue);
          //         }

          //         if(objFieldName === 'dateOfBirth'){
          //           if(objFieldValue !== null || undefined){
          //           if(objFieldValue.charAt(2) === "*"){
          //             formMethods.setValue('dateOfBirth', objFieldValue);
          //             setDateOfBirth(objFieldValue)
          //           }else{
          //             const defaultDate = new Date(objFieldValue);
          //             formMethods.setValue('dateOfBirth', defaultDate);
          //             setDateOfBirth(formatDate(defaultDate as Date))
          //           }
          //           setDateOfBirthPlaceHolder(objFieldValue)
          //         }
          //       }
          //       if (fieldName === "resource") {
          //         if (objFieldName === "externalId") {
          //           (async () => {
          //             try {
          //               const responseAssociationId = await T4SlipService.getRoleAssociationId(
          //                 objFieldValue
          //               );

          //               console.log("responseData getRoleAssociationId : ", responseAssociationId.associationId);

          //               formMethods.setValue("selectedEmployee", responseAssociationId.associationId);

          //           (async () => {
          //             try {
          //               const responseGetResId =
          //                 await T4SlipService.getResourceActualId(responseAssociationId.associationId);
          //               console.log(
          //                 "responseData////////",
          //                 responseGetResId.resourceId
          //               );

          //               (async () => {
          //                 try {
          //                   const response: any =
          //                     await T4SlipService.getResourceSlipDropDownList(
          //                       responseGetResId.resourceId,
          //                       clientId,
          //                       taxYear
          //                     );
          //               //     console.log(
          //               //       "responseData-----------------",
          //               //       response[0].value
          //               //     );
          //               //     console.log(
          //               //       "responseData-----------------",
          //               //       newCreatedSlipId
          //               //     );
          //               //     console.log(
          //               //       "responseData-----------------",
          //               //       slipRecord
          //               //     );
          //               //     // formMethods.setValue("selectedSlip", response[0].value);

          //                   if (response && Array.isArray(response)) {
          //                     let resourceSlipList: IDropDownListResponse[] =
          //                       response.map(
          //                         (resourceSlip: IDropDownListResponse) => ({
          //                           label: resourceSlip.label,
          //                           value: resourceSlip.value,
          //                         })
          //                       );

          //                     resourceSlipList.unshift({
          //                       label: "Create New",
          //                       value: "create-new",
          //                     });

          //                     console.log("resourceSlipListDDL", resourceSlipList);
          //                     setSelectedResourceSlipList(resourceSlipList);
          //                   }
          //                 } catch (error) {
          //                   console.error("Error fetching data:", error);
          //                 }
          //               })();
          //               // You can work with the data here or trigger other functions that depend on the fetched data
          //             } catch (error) {
          //               console.error("Error fetching data:", error);
          //             }
          //           })();

          //             } catch (error) {
          //               console.error("Error fetching data:", error);
          //             }
          //           })();
          //           // setNewCreatedResourceId(objFieldValue)
          //           setSelectedResource(objFieldValue)
          //         }

          //         }
          //         if (objFieldName === "province") {
          //           let id = objFieldValue.id;
          //           let name = objFieldValue.name;
          //           let shortCode = objFieldValue.shortCode;
          //           console.log("namename", name);

          //           formMethods.setValue(
          //             objFieldName,
          //             `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
          //           );
          //         }
          //         if (objFieldName === "provinceOfEmployment") {
          //           let id = objFieldValue.id;
          //           let name = objFieldValue.name;
          //           let shortCode = objFieldValue.shortCode;
          //           console.log("namename", name);

          //           formMethods.setValue(
          //             objFieldName,
          //             `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
          //           );
          //         }
          //         if (objFieldName === "country") {
          //           formMethods.setValue(
          //             objFieldName,
          //             objFieldValue.name ?? undefined
          //           );
          //         }

          //       });
          //     }
          //     if(fieldName === 'id'){
          //       // setNewCreatedSlipId(fieldValue)
          //       formMethods.setValue("selectedSlip",fieldValue);
          //       let path = `/taxslip/t4/setup/${fieldValue}`;
          //       navigate(path);
          //     }
          //   });
        }

        // //set slip type to 400 because when we come back to slip without reload it will automatically be original
        selectedSlipTypee = formMethods.getValues("slipType");

        // //otherinformation will update sselected items when its submitted
        setSelectedOtherInformation(true);
      },
      onError: (err: any) => {
        console.log("resonce for Delete row err", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          // message: "Something went wrong, try again later!",
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  //when cretae-new seleted from slip ddl
  const createNewActionFromSlipDDL = () => {
    // setNewCreatedSlipId('')
    // if(id !== undefined){
    //   // window.location.href = "/taxslip/t4/setup";
    //   // completedSlipId = ''
    //   //when cretae new slected then id would be null so instead of PUT after this POST calls to cretae new slip to selected edited slip from t4slip list
    //   setEditedRecordSlipCreateNew(false)
    // }
    //otherinformation will update selected items
    setSelectedOtherInformation(false);

    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 1000);

    // formMethods.setValue("selectedSlip", "create-new");
    formMethods.setValue("applyAdjustments", false);
    formMethods.setValue("employmentIncome", "0.00");
    formMethods.setValue("cppContributions", "0.00");
    formMethods.setValue("secondCppContributions", "0.00");
    formMethods.setValue("eiPremiums", "0.00");
    formMethods.setValue("rppContributions", "0.00");
    formMethods.setValue("incomeTaxDeducted", "0.00");
    formMethods.setValue("eiInsurableEarnings", "0.00");
    formMethods.setValue("cppQppPensionableEarnings", "0.00");
    formMethods.setValue("unionDues", "0.00");
    formMethods.setValue("charitableDonations", "0.00");
    formMethods.setValue("registrationNumber", undefined);
    formMethods.setValue("pensionAdjustment", "0.00");
    formMethods.setValue("ppipPremiums", "0.00");
    formMethods.setValue("ppipInsurableEarnings", "0.00");
    formMethods.setValue("employmentIncomeAdjusted", "0.00");
    formMethods.setValue("secondCppContributionsAdjusted", "0.00");
    formMethods.setValue("eiPremiumsAdjusted", "0.00");
    formMethods.setValue("incomeTaxDeductedAdjusted", "0.00");
    formMethods.setValue("eiInsurableEarningsAdjusted", "0.00");
    formMethods.setValue("ppipPremiumsAdjusted", "0.00");
    formMethods.setValue("ppipInsurableEarningsAdjusted", "0.00");
    formMethods.setValue("slipStatus", 200);
    formMethods.setValue("provinceOfEmployment", undefined);
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    setSlipOtherInformation([])

    // setNewCreatedSlipId('')
  };

  // for edit

  //for that slips which are completed
  const { isLoading: isLoadingData, data: selectedT4SlipsData } = useGetData(
    completedSlipId,
    clientId,
    taxYear
  );
  const { isLoading: isLoadingDefaultSettingsData, data: defaultSettingsData } = useGetDefaultSettingsData(clientId);


  const { isLoading: isLoadingGlobalVariables, data: editDataRecord } =
    useGetGlobalVariables(taxYear);
  const globalVariablesData: any = editDataRecord;

  const { isLoading:loadingSlipIds, data:t4SlipIds, refetch:refectchT4SlipIds } = useGetSlipIds("T4", clientId, taxYear);


  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  useEffect(()=>{
    console.log("companyGetByIdRecord11",companyGetByIdRecord)
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor ? parseInt(paymentFor.value) : 10)
    }
  },[companyGetByIdRecord])

  // useEffect(() => {
  //   //get by id slip
  //   setIsLoadingAdjustedData(true);
  //   setTimeout(() => {
  //     setIsLoadingAdjustedData(false);
  //   }, 2000);
  //   const fetchData = async () => {
  //     try {
  //       const response: any = await T4SlipService.getT4SlipById(
  //         completedSlipId,
  //         clientId,
  //         taxYear
  //       );
  //       console.log("responseData-----------------", response);
  //       setT4SlipRecord(response);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  
  useEffect(() => {
    if (selectedT4SlipsData) {
      setT4SlipRecord(selectedT4SlipsData);
    }
  }, [selectedT4SlipsData]);
  console.log("selectedT4SlipsData=======", selectedT4SlipsData);

  //for that slips which are not started yet
  const {
    isLoading: isLoadingNotStartedSlipId,
    data: selectedNotStartedResourceData,
    // refetch: refetchResourceSlips,
  } = useGetResourceInformationById(notStartedSlipId, clientId, "Employee");
  console.log("resourceInfo", selectedNotStartedResourceData);

  // const { isLoading: isLoadingDropDownList, data: resourceList } =
  //   useGetResourceDDLData(clientId, EMPLOYEE_RESOURCE_LITERAL);
  // console.log("resourceList", resourceList);
  // const {
  //   isLoading: isLoadingResourceSlips,
  //   data: resourceSlips,
  //   refetch: refetchResourceSlips,
  // } = useGetResourceSlips(selectedResource, clientId, taxYear);
  // console.log("selectedResource resourceSlips", resourceSlips);

  // const {
  //   isLoading: isLoadingResourceInfo,
  //   data: resourceInfo,
  //   // refetch: refetchResourceSlips,
  // } = useGetResourceInformationById(selectedResourceId, clientId, "Employee");
  // console.log("resourceInfo", resourceInfo);

  // const {
  //   isLoading: isLoadingSlipData,
  //   data: slipRecord,
  //   isFetching: reFetchingSlipData,
  //   refetch: refetchSlipRecord,
  // } = useGetSlipRecord(selectedResourceSlip, clientId, taxYear);

  // console.log("slipRecord", slipRecord);
  // const resourceListItems = useMemo(() => {
  //   if (resourceList && Array.isArray(resourceList)) {
  //     let resources: IDropDownListResponse[] = resourceList.map(
  //       (resourceItem: IDropDownListResponse) => ({
  //         id: resourceItem.id,
  //         label: resourceItem.label,
  //         value: resourceItem.value,
  //       })
  //     );

  //     resources.unshift({
  //       label: "Create New",
  //       value: "create-new",
  //     });
  //     setSelectedResourceList(resourceList)
  //     return resources;
  //   }

  //   return [
  //     {
  //       label: "Create New",
  //       value: "create-new",
  //     },
  //   ];
  // }, [resourceList]);
  // console.log("resourceListItems", resourceListItems);

  // const resourceSlipListItems = useMemo(() => {
  //   if (resourceSlips && Array.isArray(resourceSlips)) {
  //     let resourceSlipList: IDropDownListResponse[] = resourceSlips.map(
  //       (resourceSlip: IDropDownListResponse) => ({
  //         label: resourceSlip.label,
  //         value: resourceSlip.value,
  //       })
  //     );

  //     resourceSlipList.unshift({
  //       label: "Create New",
  //       value: "create-new",
  //     });
  //     setSelectedResourceSlipList(resourceSlipList);
  //     return resourceSlipList;
  //   }

  //   return [
  //     {
  //       label: "Create New",
  //       value: "create-new",
  //     },
  //   ];
  // }, [resourceSlips]);
  // console.log("resourceSlipListItems", resourceSlipListItems);

  /**
   * Handle when the form is submitted.
   *
   * @param {IT4SlipRequestPayload} formData
   */

  const [T4OtherInformationList, setT4OtherInformationList] =
    useState<any>(undefined);
  useEffect(() => {
    //Load OtherInformationList
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 2000);
    const fetchData = async () => {
      try {
        const response: any = await T4OtherInformationService.getT4OI();
        setT4OtherInformationList(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [isDOBAtLeast10YearsAgo, setIsDOBAtLeast10YearsAgo] = useState(false);

  const onSubmit = (formData: any) => {
    console.log("formData == ", formData);
    // if (dateOfBirth.charAt(2) !== "*" && dateOfBirth !== null && dateOfBirth !== "") {
    //   const userDateOfBirth = new Date(dateOfBirth);
    //   const currentDate = new Date();

    //   const isAtLeast10YearsAgo = currentDate.getFullYear() - userDateOfBirth.getFullYear() >= 10;
    //   console.log("dateOfBirth",userDateOfBirth.getFullYear())
    //   console.log("currentDate",currentDate.getFullYear())
    //   console.log("isAtLeast10YearsAgo",isAtLeast10YearsAgo)
    //   setIsDOBAtLeast10YearsAgo(isAtLeast10YearsAgo)
    // }
    // console.log("isDOBAtLeast10YearsAgo",isDOBAtLeast10YearsAgo)
    // if(isDOBAtLeast10YearsAgo === false){
    //   setSnackbarData({
    //         open: true,
    //         message: "Date of birth must be at least 10 years back!",
    //         severity: "error",
    //       });

    // }
    // else {

    //province is in string format and by using this we convert that in object
    const jsonString = formData.province as string;
    // Custom parsing function to convert the JSON-like string to an object
    const parseJSONLikeString = (str: string) => {
      // Use regular expressions to add double quotes around keys and string values
      const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
      // Convert the modified JSON string to an object using JSON.parse()
      return JSON.parse(modifiedString);
    };
    // Convert the JSON-like string to an object
    const provinceObject = parseJSONLikeString(jsonString);
    console.log("provinceObject", provinceObject);

    //province is in string format and by using this we convert that in object
    const jsonStringPoe = formData.provinceOfEmployment as string;
    // Custom parsing function to convert the JSON-like string to an object
    const parseJSONLikeStringPeo = (str: string) => {
      // Use regular expressions to add double quotes around keys and string values
      const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
      // Convert the modified JSON string to an object using JSON.parse()
      return JSON.parse(modifiedString);
    };
    // Convert the JSON-like string to an object
    const poeObject = parseJSONLikeStringPeo(jsonStringPoe);
    console.log("poeObject", poeObject);

    //other Information
    const getValue = formMethods.getValues("comparison");
    console.log("T4OtherInformationList", T4OtherInformationList);
    const finalOtherInformatioItems = FilteredSelectedOtherInfoT4.map(
      (item: any) => {
        const valueItem = getValue.find(
          (value: any) => value.code === item.code
        );
        const getLabelIfMissingInItem =
          T4OtherInformationList &&
          T4OtherInformationList.find(
            (value: any) => value.value === item.code
          );
        console.log("valueItemqwe", valueItem);
        console.log("getLabelIfMissingInItem", getLabelIfMissingInItem);
        console.log("item11", item);
        return {
          ...item,
          amount: valueItem.amount,
          label: getLabelIfMissingInItem.label,
        };
      }
    );

    console.log("getValue123", getValue);
    console.log("selectedT4SlipsData12", selectedT4SlipsData);
    console.log("selectedT4SlipsData12", T4SlipRecord);

    console.log("finalOtherInformatioItems", finalOtherInformatioItems);
    const requestPayload: IT4SlipRequestPayload = {
      // clientId: formData.clientId,
      // taxYear:formData.taxYear,
      // id: formData.id,
      clientId: clientId,
      taxYear: taxYear,
      id:
        selectedT4SlipsData && selectedT4SlipsData.resource
          ? // && editedRecordSlipCreateNew === false
            selectedT4SlipsData.id
            : T4SlipRecord && T4SlipRecord.id ? T4SlipRecord.id 
            : 
            // : newCreatedSlipId !== '' && editedRecordSlipCreateNew === false ? newCreatedSlipId
            undefined,
      // applyAdjustments: formData.applyAdjustments,
      ignoredEiAuditEarning: false, //this tag is for audit earnings ignore tag
      ignoredCppAuditEarning: false, //this tag is for audit earnings ignore tag
      ignoredDuplicateSlips: false, //ignore duplicate employees
      applyAdjustments: formData.applyAdjustments !== "false" ? true : false,
      saveAdjutments: formData.applyAdjustments !== "true" ? false : true, //if apply adj true then true ow false
      reflectMasterData: formData.reflectMasterData,
      slipType: formData.slipType,
      slipStatus: formData.slipStatus,
      pensionablePeriod: formData.pensionablePeriod,
      otherInformation:
        formData.comparison[0].code !== "" ? finalOtherInformatioItems : [],
      resource: {
        externalId:
          selectedResource !== "create-new"
            ? selectedResource
            : selectedT4SlipsData && selectedT4SlipsData.resource
            ? selectedT4SlipsData.resource.externalId
            : // : newCreatedResourceId !== '' ? newCreatedResourceId
            selectedNotStartedResourceData && selectedNotStartedResourceData.id
            ? selectedNotStartedResourceData.id
            : undefined,
        // externalId:selectedResourceSlip !== "create-new" ? selectedResourceSlip : (selectedT4SlipsData && selectedT4SlipsData.resource) ? selectedT4SlipsData.resource.externalId : undefined,
        // externalId:
        //   selectedResource !== "create-new"
        //     ? selectedResource
        //     : selectedT4SlipsData !== undefined || null
        //     ? selectedT4SlipsData.resource.externalId
        //     : undefined,
        // externalId:selectedResourceId,
        // externalId:"6daf1279-a74f-458a-a973-5f8eda0c72f4", //resource id of => //testRL ed3 test
        firstName: formData.name1,
        initials: formData.initials,
        lastName: formData.name2,
        // dateOfBirth: formData.dateOfBirth,
        dateOfBirth:
          dateOfBirth.charAt(2) === "*"
            ? undefined
            : dateOfBirth !== "" || null
            ? dateOfBirth
            : undefined,
        socialInsuranceNumber: formData.uniqueIdentifier.replace(/-/g, ""),
        address: formData.addressLine1,
        city: formData.city,
        // province:provinceResponce,
        // province: formData.province,
        province: provinceObject, //when edit then it show n select so this technique is use bcz we cant assign fieldvalue according to field name by using direct object so first we change that oobject to string then we chnage that string to obj and send to payload
        country: CountryResponse,
        postCode:
          formData.postalCode &&
          formData.postalCode.replace(/[a-z]/g, (match: string) =>
            match.toUpperCase()
          ),
        // provinceOfEmployment:provinceOfEmploymentRes,
        // provinceOfEmployment: formData.provinceOfEmployment,
        provinceOfEmployment: poeObject,
        email:
          formData.email !== "" && formData.email !== "ze*****3@gmail.com"
            ? formData.email
            : undefined,
        employeeCode: parseInt(formData.employeeCode),
        cppQppExempt: formData.cppQppExempt,
        eiExempt: formData.eiExempt,
        ppipExempt: formData.ppipExempt,
        employmentCode:
          formData.employmentCode !== undefined &&
          formData.employmentCode !== ""
            ? formData.employmentCode
            : null,
        payPeriod: formData.payPeriod,
        reducedEi: formData.reducedEi,
      },
      overRemittance: 0,
      slipData: {
        employmentIncome: formData.employmentIncome && formData.employmentIncome !== null ? parseFloat(formData.employmentIncome.replace(/[$,]/g, '')) : 0,
        cppContributions:
          selectedProvinceOfEmployment !== "Quebec" && formData.cppContributions && formData.cppContributions !== null  
            ? parseFloat(formData.cppContributions.replace(/[$,]/g, ''))
            : 0,
        secondCppContributions:
          selectedProvinceOfEmployment !== "Quebec" && taxYear > 2023 && formData.secondCppContributions && formData.secondCppContributions !== null 
            ? parseFloat(formData.secondCppContributions.replace(/[$,]/g, ''))
            : 0,
        qppContributions:
          selectedProvinceOfEmployment === "Quebec" && formData.qppContributions !== null && formData.qppContributions !== undefined
            ? parseFloat(formData.qppContributions.replace(/[$,]/g, ''))
            : 0,
        secondQppContributions:
          selectedProvinceOfEmployment === "Quebec"  && taxYear > 2023 && formData.secondQppContributions !== null && formData.secondQppContributions !== undefined
            ? parseFloat(formData.secondQppContributions.replace(/[$,]/g, ''))
            : 0,
        eiPremiums: formData.eiPremiums && formData.eiPremiums !== null ? parseFloat(formData.eiPremiums.replace(/[$,]/g, '')) : 0,
        rppContributions: formData.rppContributions && formData.rppContributions !== null ? parseFloat(formData.rppContributions.replace(/[$,]/g, '')) : 0,
        incomeTaxDeducted: formData.incomeTaxDeducted && formData.incomeTaxDeducted !== null ? parseFloat(formData.incomeTaxDeducted.replace(/[$,]/g, '')) : 0,
        eiInsurableEarnings: formData.eiInsurableEarnings && formData.eiInsurableEarnings !== null ? parseFloat(formData.eiInsurableEarnings.replace(/[$,]/g, '')) : 0,
        cppQppPensionableEarnings: formData.cppQppPensionableEarnings && formData.cppQppPensionableEarnings !== null ? parseFloat(
          formData.cppQppPensionableEarnings.replace(/[$,]/g, '')
        ) : 0,
        unionDues: formData.unionDues && formData.unionDues !== null ? parseFloat(formData.unionDues.replace(/[$,]/g, '')) : 0,
        charitableDonations: formData.charitableDonations && formData.charitableDonations !== null ? parseFloat(formData.charitableDonations.replace(/[$,]/g, '')) : 0,
        registrationNumber: formData.registrationNumber && formData.registrationNumber !== null ? parseInt(formData.registrationNumber) : undefined,
        pensionAdjustment: formData.pensionAdjustment && formData.pensionAdjustment !== null ? parseFloat(formData.pensionAdjustment.replace(/[$,]/g, '')) : 0,
        ppipPremiums: formData.ppipPremiums && formData.ppipPremiums !== null ? parseFloat(formData.ppipPremiums.replace(/[$,]/g, '')) : 0,
        ppipInsurableEarnings: formData.ppipInsurableEarnings && formData.ppipInsurableEarnings !== null ? parseFloat(formData.ppipInsurableEarnings.replace(/[$,]/g, '')) : 0,
        employerOfferedDentalBenefits:
          formData.employerOfferedDentalBenefits && formData.employerOfferedDentalBenefits !== ""
            ? formData.employerOfferedDentalBenefits
            : null,
      },
      adjustedSlipData: {
        employmentIncome: formData.employmentIncomeAdjusted && parseFloat(formData.employmentIncomeAdjusted.replace(/[$,]/g, '')),
        cppContributions:
          selectedProvinceOfEmployment !== "Quebec" && formData.cppContributionsAdjusted
            ? parseFloat(formData.cppContributionsAdjusted.replace(/[$,]/g, ''))
            : 0,
        secondCppContributions:
          selectedProvinceOfEmployment !== "Quebec" && taxYear > 2023 && formData.secondCppContributionsAdjusted
            ? parseFloat(formData.secondCppContributionsAdjusted.replace(/[$,]/g, ''))
            : 0,
        qppContributions:
          selectedProvinceOfEmployment === "Quebec" && formData.qppContributionsAdjusted && formData.qppContributionsAdjusted !== null
            ? parseFloat(formData.qppContributionsAdjusted.replace(/[$,]/g, ''))
            : 0,
        secondQppContributions:
          selectedProvinceOfEmployment === "Quebec" && taxYear > 2023 && formData.secondQppContributionsAdjusted && formData.secondQppContributionsAdjusted !== null
            ? parseFloat(formData.secondQppContributionsAdjusted.replace(/[$,]/g, ''))
            : 0,
        eiPremiums: formData.eiPremiumsAdjusted && parseFloat(formData.eiPremiumsAdjusted.replace(/[$,]/g, '')),
        incomeTaxDeducted: formData.incomeTaxDeductedAdjusted && parseFloat(formData.incomeTaxDeductedAdjusted.replace(/[$,]/g, '')),
        eiInsurableEarnings: formData.eiInsurableEarningsAdjusted && parseFloat(formData.eiInsurableEarningsAdjusted.replace(/[$,]/g, '')),
        ppipPremiums: formData.ppipPremiumsAdjusted && parseFloat(formData.ppipPremiumsAdjusted.replace(/[$,]/g, '')),
        ppipInsurableEarnings: formData.ppipInsurableEarningsAdjusted && parseFloat(
          formData.ppipInsurableEarningsAdjusted.replace(/[$,]/g, '')
        ),
      },
    };
    console.log("===-requestPayload t4slip ", requestPayload);
    // console.log("===-formData.employmentIncome t4slip ", formData.employmentIncome.replace(/[$,]/g, ''));
    // console.log("===-formData.cppContributions t4slip ", formData.cppContributions.replace(/[$,]/g, ''));
    console.log("formData.employmentCode ", formData.employmentCode);
    console.log("formData.employmentCode ", selectedProvinceOfEmployment);
    console.log(
      "formData.applyAdjustments ",
      Boolean(formData.applyAdjustments)
    );

    // if (formData.comparison[0].amount !== "") {
    //   console.log("not equal");
    // } else {
    //   console.log("equal");
    // }
    // submitFlexFields(formData);
    // alert(formData.employmentCode)

    submitFlexFields(requestPayload);
    // }
  };

  const defaultSettingsCalls = () => {
    if(!id && !T4SlipRecord && defaultSettingsData && defaultSettingsData.t4DefaultSetting){
      console.log("t4DefaultSetting",defaultSettingsData.t4DefaultSetting)
      // setSlipOtherInformation([])
      if (defaultSettingsData.t4DefaultSetting.otherInformation) {
        if (defaultSettingsData.t4DefaultSetting.otherInformation && defaultSettingsData.t4DefaultSetting.otherInformation !== null && defaultSettingsData.t4DefaultSetting.otherInformation.length !== 0) {
          setOiInEdit(true);
          formMethods.setValue("comparison", defaultSettingsData.t4DefaultSetting.otherInformation);
          } 
          else {
            //   // setOiInEdit(true);
            formMethods.setValue("comparison", [
              { amount: 0, code: "", label: "" },
              ]);
        }
        }
        if (defaultSettingsData.t4DefaultSetting.rppDpspRegistration && defaultSettingsData.t4DefaultSetting.rppDpspRegistration !== '' && defaultSettingsData.t4DefaultSetting.rppDpspRegistration > 0) {
          formMethods.setValue('registrationNumber',defaultSettingsData.t4DefaultSetting.rppDpspRegistration)
          }
        if (defaultSettingsData.fillingDataAttribute) {
          if (defaultSettingsData.fillingDataAttribute.provinceOfEmployment) {
            let id = defaultSettingsData.fillingDataAttribute.provinceOfEmployment.id;
            let name = defaultSettingsData.fillingDataAttribute.provinceOfEmployment.name;
            let shortCode = defaultSettingsData.fillingDataAttribute.provinceOfEmployment.shortCode;
            formMethods.setValue(
              'provinceOfEmployment',
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
            );
            if (defaultSettingsData.fillingDataAttribute.provinceOfEmployment.name === "Quebec") {
                setShowbox17IfQC(true);
                setSelectedProvinceOfEmployment("Quebec");
            } else {
              setShowbox17IfQC(false);
              setSelectedProvinceOfEmployment(name);
            }
          }
          if (defaultSettingsData.fillingDataAttribute.province) {
            let id = defaultSettingsData.fillingDataAttribute.province.id;
              let name = defaultSettingsData.fillingDataAttribute.province.name;
              let shortCode = defaultSettingsData.fillingDataAttribute.province.shortCode;
              formMethods.setValue(
                'province',
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
              );
          }
          if (defaultSettingsData.fillingDataAttribute.city) {
            formMethods.setValue('city',defaultSettingsData.fillingDataAttribute.city)
          }
          if (defaultSettingsData.fillingDataAttribute.cppQppExempt) {
            formMethods.setValue('cppQppExempt',defaultSettingsData.fillingDataAttribute.cppQppExempt)
          }
          if (defaultSettingsData.fillingDataAttribute.eiExempt) {
            formMethods.setValue('eiExempt',defaultSettingsData.fillingDataAttribute.eiExempt)
          }
          if (defaultSettingsData.fillingDataAttribute.ppipExempt) {
            formMethods.setValue('ppipExempt',defaultSettingsData.fillingDataAttribute.ppipExempt)
          }
          if (defaultSettingsData.fillingDataAttribute.employmentCode) {
            formMethods.setValue('employmentCode',defaultSettingsData.fillingDataAttribute.employmentCode)
          }
          if (defaultSettingsData.fillingDataAttribute.payPeriod) {
            formMethods.setValue('payPeriod',defaultSettingsData.fillingDataAttribute.payPeriod)
          }
          if (defaultSettingsData.fillingDataAttribute.reducedEi) {
            formMethods.setValue('reducedEi',defaultSettingsData.fillingDataAttribute.reducedEi)
          }
        }
    }
  }

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset();
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    setSlipOtherInformation([])
    setDateOfBirth("");
    setDateOfBirthPlaceHolder("");
    setSelectedResourceSlipList([{ label: "Create New", value: "create-new" }]);
    setSelectedResourceSlip("create-new");
    setSelectedOtherInformation(false);
    setSelectedResource("create-new");
    setSelectedCard(null);
    let path = `/taxslip/t4/setup`;
    navigate(path);
    setT4SlipRecord(undefined);
    defaultSettingsCalls()
  };

  // For Delete Row

  const { isLoading: isDeleting, mutate: deleteT4SlipById } = useMutation(
    T4SlipService.delT4Slip,
    {
      onSuccess: (res: any | IServerError | IResponse) => {
        console.log("resonce for Delete row", res);
        if (typeof res === "string") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: "Record has been deleted successfully.",
            severity: "success",
          });
          setT4SlipRecord(undefined);
          defaultSettingsCalls()
          refectchT4SlipIds()

          // return (
          //   // window.location.reload()
          //   );
          // window.location.href = "/taxslip/t4/list";
        } else {
          setSnackbarData({
            open: true,
            // message: "Something went wrong, try again later!",
            message: res.message,
            severity: "error",
          });
        }

        //load Employee Data
        if (res) {
          const selectedEmployee = formMethods.getValues("selectedEmployee");
          console.log("selectedEmployee", selectedEmployee);
          if (selectedEmployee !== "create-new") {
            // (async () => {
            //   try {
            //     setIsLoadingAdjustedData(true);
            //     setTimeout(() => {
            //       setIsLoadingAdjustedData(false);
            //     }, 1000);
            //     const response = await T4SlipService.getResourceActualId(
            //       selectedEmployee
            //     );

                // const resourceId = response.resourceId;
                let path = `/taxslip/t4/setup/${selectedEmployee}`;
                navigate(path);
                const fetchData = async () => {
                  try {
                    // setIsLoadingAdjustedData(true);
                    // setTimeout(() => {
                    //   setIsLoadingAdjustedData(false);
                    // }, 1000);
                    const response: any =
                      await T4SlipService.getResourceSlipDropDownList(
                        selectedEmployee,
                        clientId,
                        taxYear
                      );
                    console.log("responseData-----------------", response);
                    // formMethods.setValue("selectedSlip", response);
                    // if(response.length !== 0){
                    // }
                    console.log("selectedSlip", response.length);

                    if (response && Array.isArray(response)) {
                      let resourceSlipList: IDropDownListResponse[] =
                        response.map((resourceSlip: IDropDownListResponse) => ({
                          label: resourceSlip.label,
                          value: resourceSlip.value,
                        }));

                      resourceSlipList.unshift({
                        label: "Create New",
                        value: "create-new",
                      });

                      console.log("resourceSlipListDDL", resourceSlipList);
                      setSelectedResourceSlipList(resourceSlipList);
                      formMethods.setValue(
                        "selectedSlip",
                        resourceSlipList[0].value
                      );
                      createNewActionFromSlipDDL();
                      formMethods.setValue("comparison", [
                        { amount: 0, code: "", label: "" },
                      ]);
                    }
                  } catch (error) {
                    console.error("Error fetching data:", error);
                  }
                };

                fetchData();
            //   } catch (err) {
            //     console.log("err", err);
            //     // alert(err);
            //   }
            // })();
          }
        }

        //set slip type to 400 because when we come back to slip without reload it will automatically be original
        selectedSlipTypee = formMethods.getValues("slipType");

        //otherinformation will update sselected items when its submitted
        setSelectedOtherInformation(true);
      },
      onError: (err: any) => {
        console.log("resonce for Delete row", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          // message: "Something went wrong, try again later!",
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  const deleteT4SlipField = () => {
    if (singleDelete) {
      let payload = {
        t4SlipId,
        clientId,
        taxYear,
      };
      setSingleDelete(false);
      deleteT4SlipById(payload);
      setDialogOpen(false);
    }
  };

  const onDeleteClicked = () => {
    setDialogOpen(true);
    setSingleDelete(true);
    setApiAction("delete");
    if (
      selectedT4SlipsData &&
      selectedT4SlipsData.resource
      // && editedRecordSlipCreateNew === false
    ) {
      setT4SlipId(selectedT4SlipsData.id);
    }
    // else if (slipRecord && slipRecord.id){
    //   setT4SlipId(slipRecord.id)
    // }
    // else if(newCreatedSlipId !== '' && editedRecordSlipCreateNew === false){
    //   setT4SlipId(newCreatedSlipId)
    // }
    // setT4SlipId(params.row.id);
    console.log("T4SlipId in T4Slip List OnDeleteRowClicked function");

    // setSnackbarData({
    //   open: true,
    //   message: "Deleted successfully",
    //   severity: "success",
    // });
    // formMethods.reset();
  };

  const savePdfAsFile = (pdfData: any, filename: any) => {
    const blob = new Blob([pdfData], { type: "application/pdf" });
    FileSaver.saveAs(blob, filename);
    console.log("pdfData", pdfData);
    console.log("blob", blob);
    fileeeee = pdfData;
  };
  const onPrintClicked = async (printType: any): Promise<void> => {
    const maskedStatus: any = sessionStorage.getItem("maskEnabled");
    if (maskedStatus === "true") {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else {
      setIsLoadingData(true);
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
          // setIsLoadingData(true);
          // try {
          //   const filingAuditResponse: any =
          //     await T4SlipService.getT4AuditFiling(clientId, taxYear);
          //   console.log("filingAuditResponse", filingAuditResponse);
          //   if (
          //     filingAuditResponse &&
          //     filingAuditResponse.general_filling &&
          //     filingAuditResponse.general_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.general_filling",
          //       filingAuditResponse.general_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else if (
          //     filingAuditResponse &&
          //     filingAuditResponse.t4_filling &&
          //     filingAuditResponse.t4_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.t4_filling",
          //       filingAuditResponse.t4_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else {
            if(t4SlipIds && t4SlipIds.generalAuditStatus !== null && t4SlipIds.generalAuditStatus === true &&
              t4SlipIds && t4SlipIds.auditStatus !== null &&  t4SlipIds.auditStatus === true){
              setIsLoadingData(true);
              try {
                const jsonString = formMethods.getValues("provinceOfEmployment")
                  ? formMethods.getValues("provinceOfEmployment")
                  : ('{id:9, name: "Ontario",shortCode:"ON"}' as string);
                const ProvinceOfEmploymentData =
                  parseJSONLikeString(jsonString);
                // const response: any = await PrintingService.createSingleT4PdfPrint(
                //   clientId,
                //   editedResourceId,
                //   formMethods.getValues("slipType"),
                //   printType,
                //   ProvinceOfEmploymentData.shortCode,
                //   taxYear
                // );
                let pdfIds = `t4_slip_ids=${id}`;
                const response: any =
                  await PrintingService.createBulkT4PdfPrint(
                    clientId,
                    printType,
                    pdfIds,
                    taxYear
                  );
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    // message: "Please Unmask sensitive data for PDF printing.",
                    severity: "error",
                  });
                } else {
                  const employeeFirstName = formMethods.getValues("name1");
                  const employeeLastName = formMethods.getValues("name2");
                  const filename = `T4slip-${employeeFirstName} ${employeeLastName}-${printType}.pdf`;
                  savePdfAsFile(response, filename);
                  console.log("response", response);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
                alert(err);
              }
            // }
            setIsLoadingData(false);
          } else {
            setIsLoadingData(false);
            setSnackbarData({
            open: true,
            message: (
              <>
                Please load Audit in the menu, press Run T4 Audit and clear all audit items (Audit Filing and PIER Review) to generate documents.{" "}
                <a
                  href="https://appv3.taxslips.com/#/audit/filing"
                  style={{
                    color: "#aa1010",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Click here to view
                </a>
              </>
            ),
            severity: "error",
            autoHide: false,
          });
        }
          // } catch (err: any) {
          //   setIsLoadingData(false);
          //   alert(err);
          // }
        } else if (
          response?.id != null &&
          previousYearInvoiceResponse?.id != null
        ) {
          console.log("one");
          setInvoiceData([previousYearInvoiceResponse, response]);
          setInvoiceDialogOpen(true);
        } else if (
          previousYearInvoiceResponse?.id != null &&
          response?.message === "Slips not found for invoice."
        ) {
          console.log("two");
          setInvoiceData([previousYearInvoiceResponse]);
          setInvoiceDialogOpen(true);
        } else if (
          response?.id != null &&
          previousYearInvoiceResponse?.message === "Slips not found for invoice."
        ) {
          console.log("three");
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        }else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    } else {
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (response.message === "Slips not found for invoice.") {
            if(t4SlipIds && t4SlipIds.generalAuditStatus !== null && t4SlipIds.generalAuditStatus === true &&
              t4SlipIds && t4SlipIds.auditStatus !== null &&  t4SlipIds.auditStatus === true){
              setIsLoadingData(true);
              try {
                const jsonString = formMethods.getValues("provinceOfEmployment")
                  ? formMethods.getValues("provinceOfEmployment")
                  : ('{id:9, name: "Ontario",shortCode:"ON"}' as string);
                const ProvinceOfEmploymentData =
                  parseJSONLikeString(jsonString);
                let pdfIds = `t4_slip_ids=${id}`;
                const response: any =
                  await PrintingService.createBulkT4PdfPrint(
                    clientId,
                    printType,
                    pdfIds,
                    taxYear
                  );
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    severity: "error",
                  });
                } else {
                  const employeeFirstName = formMethods.getValues("name1");
                  const employeeLastName = formMethods.getValues("name2");
                  const filename = `T4slip-${employeeFirstName} ${employeeLastName}-${printType}.pdf`;
                  savePdfAsFile(response, filename);
                  console.log("response", response);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
                alert(err);
              }
            setIsLoadingData(false);
          } else {
            setIsLoadingData(false);
            setSnackbarData({
            open: true,
            message: (
              <>
                Please load Audit in the menu, press Run T4 Audit and clear all audit items (Audit Filing and PIER Review) to generate documents.{" "}
                <a
                  href="https://appv3.taxslips.com/#/audit/filing"
                  style={{
                    color: "#aa1010",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Click here to view
                </a>
              </>
            ),
            severity: "error",
            autoHide: false,
          });
        }
        } else if (response && response.id !== null) {
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    }
    }
  };

  console.log("savePdfAsFile", savePdfAsFile);

  const onUnPaidClickHandler = (clickedRow: any, tag:string) => {
    setIsLoadingData(true);
    // setTimeout(() => {
    //   setIsLoadingData(false);
    // }, 2500);
    const fetchData = async () => {
      try {
        const response: any =
          await ReportsService.paypalIntegrationOfUnpaidInvoice(
            clientId,
            clickedRow.refNo,
            tag
          );
        setIsLoadingData(false);
        console.log("responseData111-----------------", response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const onClickInvoiceNoHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };
  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // const otherInformationList = useMemo(() => {
  //   return [
  //     {
  //       label: "N/A",
  //       value: "0",
  //     },
  //     {
  //       label: "30 - Board and lodging",
  //       value: "30",
  //     },
  //     {
  //       label: "31 - Special work site",
  //       value: "31",
  //     },
  //     {
  //       label: "32 - Travel in a prescribed zone",
  //       value: "32",
  //     },
  //   ];
  // }, []);

  // set the values of the selected Slip from DDL coming from BE.
  // useEffect(() => {
  // if the selected flex field data has loaded.
  // if (slipRecord) {
  //   Object.keys(slipRecord).forEach((fieldName) => {
  //     // @ts-ignore
  //     const fieldValue = slipRecord[fieldName];
  //     console.log(
  //       "fieldNameslipRecord",
  //       fieldName,
  //       " && ",
  //       "fieldValueslipRecord ",
  //       fieldValue
  //     );
  //     if (typeof fieldValue !== "object") {
  //       // @ts-ignore
  //       formMethods.setValue(fieldName, fieldValue);
  //     }
  //     if (fieldName === "resource") {
  //       Object.keys(fieldValue).forEach((resourceFieldName) => {
  //         const resourceFieldValue = fieldValue[resourceFieldName];
  //         // @ts-ignore
  //         formMethods.setValue(resourceFieldName, resourceFieldValue);
  //         console.log(
  //           "resourceFieldName : ",
  //           resourceFieldName,
  //           "resourceFieldValue : ",
  //           resourceFieldValue
  //         );
  //         if(resourceFieldName === 'dateOfBirth'){
  //           if(resourceFieldValue !== null || undefined){
  //           if(resourceFieldValue.charAt(2) === "*"){
  //             // alert("yes")
  //             formMethods.setValue('dateOfBirth', resourceFieldValue);
  //             setDateOfBirth(resourceFieldValue)
  //           }else{
  //             // alert("No")
  //             const defaultDate = new Date(resourceFieldValue);
  //             formMethods.setValue('dateOfBirth', defaultDate);
  //             setDateOfBirth(formatDate(defaultDate as Date))
  //           }
  //           setDateOfBirthPlaceHolder(resourceFieldValue)
  //         }
  //       }
  //         if (resourceFieldName === "country") {
  //           formMethods.setValue(
  //             resourceFieldName,
  //             resourceFieldValue.name ?? undefined
  //           );
  //         }
  //         if (resourceFieldName === "province") {
  //             let id = resourceFieldValue.id;
  //             let name = resourceFieldValue.name;
  //             let shortCode = resourceFieldValue.shortCode;
  //             console.log("namename", name);

  //             formMethods.setValue(
  //               resourceFieldName,
  //               // '{id:9, name: "Ontario",shortCode:"ON"}'
  //               `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
  //             );
  //           }
  //           if (resourceFieldName === "provinceOfEmployment") {
  //             let id = resourceFieldValue.id;
  //             let name = resourceFieldValue.name;
  //             let shortCode = resourceFieldValue.shortCode;
  //             console.log("namename", name);

  //             formMethods.setValue(
  //               resourceFieldName,
  //               // '{id:9, name: "Ontario",shortCode:"ON"}'
  //               `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
  //             );
  //         }

  //       });
  //     }
  //     if (fieldName === "slipData") {
  //       Object.keys(fieldValue).forEach((slipDataFieldName) => {
  //         const slipDataFieldValue = fieldValue[slipDataFieldName];
  //         // @ts-ignore
  //         formMethods.setValue(slipDataFieldName, slipDataFieldValue);
  //         console.log(
  //           "slipDataFieldName : ",
  //           slipDataFieldName,
  //           "slipDataFieldValue : ",
  //           slipDataFieldValue
  //         );
  //       });
  //     }
  //     if (fieldName === "adjustedSlipData") {
  //       Object.keys(fieldValue).forEach((adjustedSlipDataFieldName) => {
  //         const adjustedSlipDataFieldValue =
  //           fieldValue[adjustedSlipDataFieldName];
  //         // @ts-ignore
  //         formMethods.setValue(
  //           adjustedSlipDataFieldName,
  //           adjustedSlipDataFieldValue
  //         );
  //         console.log(
  //           "adjustedSlipDataFieldName : ",
  //           adjustedSlipDataFieldName,
  //           "adjustedSlipDataFieldValue : ",
  //           adjustedSlipDataFieldValue
  //         );
  //       });
  //     }

  //     //set slip type according to selected slip for submit button action
  //     if(fieldName === 'slipType'){
  //       selectedSlipTypee = fieldValue
  //       setIsLoadingAdjustedData(true);
  //       setTimeout(() => {
  //         setIsLoadingAdjustedData(false);
  //       }, 1000);
  //     }
  //     if(fieldName === 'otherInformation'){
  //       if(fieldValue.length !== 0){
  //         formMethods.setValue('comparison',fieldValue)
  //       } else{
  //         formMethods.setValue('comparison',[{ amount: 0, code: '', label:''}])
  //       }
  //     }
  //   });
  // }
  // }, [slipRecord, formMethods.setValue, formMethods]);

  //resource info by using selected resource id
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (resourceInfo) {
  //     // formMethods.reset();
  //     console.log("resourceInforesourceInfo", resourceInfo);
  //     Object.keys(resourceInfo).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = resourceInfo[fieldName];
  //       console.log(
  //         "fieldNamefieldName",
  //         fieldName,
  //         " && ",
  //         "fieldValuefieldValue ",
  //         fieldValue
  //       );
  //       formMethods.setValue(fieldName, fieldValue);

  //       if (fieldName === "address") {
  //         Object.keys(fieldValue).forEach((resourceFieldName) => {
  //           const resourceFieldValue = fieldValue[resourceFieldName];
  //           // @ts-ignore
  //           console.log(
  //             "resourceFieldName : ",
  //             resourceFieldName,
  //             "resourceFieldValue : ",
  //             resourceFieldValue
  //           );
  //           // formMethods.setValue(resourceFieldName, resourceFieldValue);
  //           Object.keys(resourceFieldValue).forEach((resourceFieldNameNew) => {
  //             const resourceFieldValueNew =
  //               resourceFieldValue[resourceFieldNameNew];
  //             // @ts-ignore
  //             console.log(
  //               "resourceFieldNameNew : ",
  //               resourceFieldNameNew,
  //               "resourceFieldValue : ",
  //               resourceFieldValueNew
  //             );
  //             formMethods.setValue(resourceFieldNameNew, resourceFieldValueNew);

  //             // if (resourceFieldNameNew === "province") {
  //             //   Object.keys(resourceFieldValueNew).forEach((addressFieldName) => {
  //             //     const addressFieldValue = resourceFieldValueNew[addressFieldName];
  //             //     // @ts-ignore
  //             //     formMethods.setValue(resourceFieldNameNew, addressFieldValue.shortCode ?? undefined);
  //             //     console.log("addressFieldName : ",addressFieldName,"addressFieldValue : ",addressFieldValue)

  //             //   });
  //             // }
  //             if (resourceFieldNameNew === "country") {
  //               formMethods.setValue(
  //                 resourceFieldNameNew,
  //                 resourceFieldValueNew.name ?? undefined
  //               );
  //             }
  //             if (resourceFieldNameNew === "province") {
  //               let id = resourceFieldValueNew.id;
  //               let name = resourceFieldValueNew.name;
  //               let shortCode = resourceFieldValueNew.shortCode;

  //               console.log("namenamenmae", name);

  //               formMethods.setValue(
  //                 resourceFieldNameNew,
  //                 `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
  //                 // '{id:1, name: "Alberta",shortCode:"AB"}'
  //               );
  //             }
  //           });
  //         });
  //       } else {
  //         // @ts-ignore
  //         formMethods.setValue(fieldName, fieldValue);
  //       }
  //       if(fieldName === 'metaFields'){
  //         fieldValue.forEach((obj:any) => {
  //           formMethods.setValue(obj.name,obj.value)
  //           if(obj.name === 'initial' || obj.name === 'INITIALS'){
  //             formMethods.setValue('initials',obj.value)
  //           }
  //           if(obj.name === 'employee_code'){
  //             formMethods.setValue('employeeCode',obj.value)
  //           }
  //           if(obj.name === 'pay_period'){
  //             formMethods.setValue('payPeriod',obj.value)
  //           }
  //           if(obj.name === 'province_of_employment'){
  //             getPROVINCE_OF_EMPLOYMENT(obj.value)
  //             console.log("PROVINCE_OF_EMPLOYMENT",PROVINCE_OF_EMPLOYMENT)
  //             if(PROVINCE_OF_EMPLOYMENT !== ''){
  //               formMethods.setValue("provinceOfEmployment",PROVINCE_OF_EMPLOYMENT);
  //             }
  //           }
  //           if(obj.name === 'reduced_ei'){
  //               formMethods.setValue('reducedEi',obj.value)
  //           }
  //           if(obj.name === 'date_of_birth'){
  //             if(obj.value !== ""){
  //             formMethods.setValue('dateOfBirth',obj.value)
  //             if(obj.value.charAt(2) === "*"){
  //               formMethods.setValue('dateOfBirth', obj.value);
  //             setDateOfBirth(obj.value)
  //             }else{
  //               const defaultDate = new Date(obj.value);
  //               formMethods.setValue('dateOfBirth', defaultDate);
  //               setDateOfBirth(formatDate(defaultDate as Date))
  //             }
  //             setDateOfBirthPlaceHolder(obj.value)
  //           }
  //           }
  //           if(obj.name === 'cpp_qpp_exempt'){
  //               formMethods.setValue('cppQppExempt',obj.value)
  //           }
  //           if(obj.name === 'ppip_exempt'){
  //               formMethods.setValue('ppipExempt',obj.value)
  //           }
  //           if(obj.name === 'ei_exempt'){
  //               formMethods.setValue('eiExempt',obj.value)
  //           }
  //           if(obj.name === 'employment_code'){
  //             formMethods.setValue('employmentCode',obj.value)
  //           }
  //           console.log(`"objobj" : ${obj.name} ====== objValue : ${obj.value}`)
  //         })
  //       }
  //     });
  //     formMethods.setValue("selectedSlip", "create-new");
  //     formMethods.setValue("applyAdjustments", false);
  //     formMethods.setValue("employmentIncome", "0.00");
  //     formMethods.setValue("cppContributions", "0.00");
  //     formMethods.setValue("eiPremiums", "0.00");
  //     formMethods.setValue("rppContributions", "0.00");
  //     formMethods.setValue("incomeTaxDeducted", "0.00");
  //     formMethods.setValue("eiInsurableEarnings", "0.00");
  //     formMethods.setValue("cppQppPensionableEarnings", "0.00");
  //     formMethods.setValue("unionDues", "0.00");
  //     formMethods.setValue("charitableDonations", "0.00");
  //     formMethods.setValue("registrationNumber", "0.00");
  //     formMethods.setValue("pensionAdjustment", "0.00");
  //     formMethods.setValue("ppipPremiums", "0.00");
  //     formMethods.setValue("ppipInsurableEarnings", "0.00");
  //     formMethods.setValue("employmentIncomeAdjusted", "0.00");
  //     formMethods.setValue("cppContributionsAdjusted", "0.00");
  //     formMethods.setValue("eiPremiumsAdjusted", "0.00");
  //     formMethods.setValue("incomeTaxDeductedAdjusted", "0.00");
  //     formMethods.setValue("eiInsurableEarningsAdjusted", "0.00");
  //     formMethods.setValue("ppipPremiumsAdjusted", "0.00");
  //     formMethods.setValue("ppipInsurableEarningsAdjusted", 0);
  //     formMethods.setValue("slipStatus", 200);
  //     formMethods.setValue('comparison',[{amount:0, code: '', label:''}]);
  //     // formMethods.setValue("reducedEi", 0);
  //     // formMethods.setValue("ppipExempt", false);
  //     // formMethods.setValue("eiExempt", false);
  //     // formMethods.setValue("cppQppExempt", false);
  //     // formMethods.setValue("provinceOfEmployment", null);
  //     // formMethods.setValue("provinceOfEmployment", null);
  //   }
  // }, [resourceInfo, formMethods.setValue, formMethods]);

  // if we have selected the parent and a flex field and they both are the same, we should un-set the parent because a field cannot be the parent of itself
  // useEffect(() => {
  //   if (slipRecord) {
  //     formMethods.setValue("parent", undefined);
  //   }
  // }, [formMethods, slipRecord]);

  // format date to 2023-07-26
  const formatDate = (date: Date): string => {
    return format(date, "yyyy-MM-dd"); // Format the date as "yyyy-MM-dd"
  };

  const onDateOfBirthChangeHandler = (selectedDate: any) => {
    console.log("onDateOfBirthChangeHandler", selectedDate);
    if (selectedDate.value) {
      console.log(
        "format date to 2023-07-26",
        formatDate(selectedDate.value as Date)
      );
      // alert(formatDate(selectedDate.value as Date));
      setDateOfBirth(formatDate(selectedDate.value as Date));
    } else {
      setDateOfBirth("");
    }
  };
  console.log("mmmm : ", formMethods.getValues("comparison"));
  const onResourceSelectionChangeHandler = async (
    selectedItem: IEnhancedDropDownItem | any,
    index?: any
  ): Promise<void> => {
    console.log("selectedItem.value", selectedItem.value);

    //this is for card selection
    setSelectedCard(index);
    setSelectedResourceSlip("create-new");

    if (!index) {
      //this is for card selection
      setSelectedCard(selectedItem.value);
      setSelectedResourceSlip("create-new");
    }
    if (selectedItem.value === "create-new") {
      setSnackbarData({
        open: true,
        message: "Please load an employee for T4 Slip",
        severity: "error",
      });
      setSidePanelsVisibility((prevButtonState: any) => !prevButtonState);
      formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
      formMethods.reset();
      formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
      setSlipOtherInformation([])
      setDateOfBirth("");
      setDateOfBirthPlaceHolder("");
      // setNewCreatedResourceId('')
      // setNewCreatedSlipId('')
      setSelectedResourceSlipList([
        { label: "Create New", value: "create-new" },
      ]);
      setSelectedResourceSlip("create-new");

      //otherinformation will update selected items
      setSelectedOtherInformation(false);
      // if(id !== undefined){
      //   window.location.href = "/taxslip/t4/setup";
      // }
      let path = `/taxslip/t4/setup`;
      navigate(path);
      setT4SlipRecord(undefined);
      setIsLoadingAdjustedData(true);
      setTimeout(() => {
        setIsLoadingAdjustedData(false);
      }, 1000);
      setSelectedResource("create-new");
    } else {
      setOnResourceSelectionAPIsCalls(true);
      // try {
      //   const responseGetResourceActualId =
      //     await T4SlipService.getResourceActualId(selectedItem.value);

      //   console.log("responseData09", responseGetResourceActualId.resourceId);
        // setSelectedResource(String(response.resourceId));
        // setSelectedResourceId(String(response.resourceId));
        // if (response.resourceId !== "create new"){
        //   setEditedResourceId(String(response.resourceId));
        // }
        // let path = `/taxslip/t4/setup/${response.resourceId}`;
        // navigate(path);
        (async () => {
          try {
            setIsLoadingAdjustedData(true);
            let resourceDataById =
              await T4SlipService.getResourceInformationById(
                selectedItem.value,
                clientId,
                "Employee"
              );
            console.log("resourceDataById", resourceDataById);
            if (resourceDataById) {
              Object.keys(resourceDataById).forEach((fieldName) => {
                // @ts-ignore
                const fieldValue = resourceDataById[fieldName];
                console.log(
                  "fieldNamefieldName",
                  fieldName,
                  " && ",
                  "fieldValuefieldValue ",
                  fieldValue
                );
                formMethods.setValue(
                  fieldName,
                  fieldValue !== null && fieldValue !== "" ? fieldValue : ""
                );

                if (fieldName === "email") {
                  if (fieldValue !== null) {
                    formMethods.setValue(fieldName, fieldValue);
                  } else {
                    const maskedStatus: any =
                      sessionStorage.getItem("maskEnabled");
                    if (maskedStatus === "true") {
                      formMethods.setValue(fieldName, "ze*****3@gmail.com");
                    } else {
                      formMethods.setValue(fieldName, "");
                    }
                  }
                }
                if (fieldName === "address") {
                  Object.keys(fieldValue).forEach((resourceFieldName) => {
                    const resourceFieldValue = fieldValue[resourceFieldName];
                    // @ts-ignore
                    console.log(
                      "resourceFieldName : ",
                      resourceFieldName,
                      "resourceFieldValue : ",
                      resourceFieldValue
                    );
                    Object.keys(resourceFieldValue).forEach(
                      (resourceFieldNameNew) => {
                        const resourceFieldValueNew =
                          resourceFieldValue[resourceFieldNameNew];
                        // @ts-ignore
                        console.log(
                          "resourceFieldNameNew : ",
                          resourceFieldNameNew,
                          "resourceFieldValue : ",
                          resourceFieldValueNew
                        );
                        formMethods.setValue(
                          resourceFieldNameNew,
                          resourceFieldValueNew
                        );

                        if (resourceFieldNameNew === "country") {
                          formMethods.setValue(
                            resourceFieldNameNew,
                            resourceFieldValueNew.name ?? undefined
                          );
                        }
                        if (resourceFieldNameNew === "province") {
                          let id = resourceFieldValueNew.id;
                          let name = resourceFieldValueNew.name;
                          let shortCode = resourceFieldValueNew.shortCode;

                          console.log("namenamenmae", name);

                          formMethods.setValue(
                            resourceFieldNameNew,
                            `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                            // '{id:1, name: "Alberta",shortCode:"AB"}'
                          );
                        }
                      }
                    );
                  });
                }
                if (fieldName === "metaFields") {
                  fieldValue.forEach((obj: any) => {
                    formMethods.setValue(obj.name, obj.value);
                    if (obj.name === "initial" || obj.name === "INITIALS") {
                      formMethods.setValue("initials", obj.value);
                    }
                    if (obj.name === "employee_code") {
                      formMethods.setValue("employeeCode", obj.value);
                    }
                    if (obj.name === "pay_period") {
                      formMethods.setValue("payPeriod", obj.value);
                      formMethods.setValue("pensionablePeriod", obj.value);
                    }
                    if (obj.name === "province_of_employment") {
                      getPROVINCE_OF_EMPLOYMENT(obj.value);
                      console.log(
                        "PROVINCE_OF_EMPLOYMENT",
                        PROVINCE_OF_EMPLOYMENT
                      );
                      if (PROVINCE_OF_EMPLOYMENT !== "") {
                        formMethods.setValue(
                          "provinceOfEmployment",
                          PROVINCE_OF_EMPLOYMENT
                        );
                      }
                      console.log("obj.value123", obj.value);
                      if (obj.value === 11) {
                        setShowbox17IfQC(true);
                        setSelectedProvinceOfEmployment("Quebec");
                      } else {
                        setShowbox17IfQC(false);
                        setSelectedProvinceOfEmployment(obj.name);
                        console.log("sjhksbvksbvbsvkbskvvk", obj.value);
                        console.log(
                          "sjhksbvksbvbsvkbskvvk",
                          selectedProvinceOfEmployment
                        );
                      }
                    }
                    if (obj.name === "reduced_ei") {
                      if (obj.value === false) {
                        formMethods.setValue("reducedEi", 0);
                      } else if (obj.value === true) {
                        formMethods.setValue("reducedEi", 1);
                      }
                    }
                    if (obj.name === "date_of_birth") {
                      if (obj.value !== "") {
                        formMethods.setValue("dateOfBirth", obj.value);
                        if (obj.value.charAt(2) === "*") {
                          formMethods.setValue("dateOfBirth", obj.value);
                          setDateOfBirth(obj.value);
                        } else {
                          const defaultDate = new Date(obj.value);
                          formMethods.setValue("dateOfBirth", defaultDate);
                          setDateOfBirth(formatDate(defaultDate as Date));
                        }
                        setDateOfBirthPlaceHolder(obj.value);
                      } else {
                        const maskedStatus: any =
                          sessionStorage.getItem("maskEnabled");
                        if (maskedStatus === "true") {
                          setDateOfBirthPlaceHolder("0***"); //this would disable dob while value is null in upadte
                        }
                      }
                    }
                    if (obj.name === "cpp_qpp_exempt") {
                      formMethods.setValue("cppQppExempt", obj.value);
                    }
                    if (obj.name === "ppip_exempt") {
                      formMethods.setValue("ppipExempt", obj.value);
                    }
                    if (obj.name === "ei_exempt") {
                      formMethods.setValue("eiExempt", obj.value);
                    }
                    if (obj.name === "employment_code") {
                      formMethods.setValue("employmentCode", obj.value);
                    }
                    console.log(
                      `"objobj" : ${obj.name} ====== objValue : ${obj.value}`
                    );
                  });
                }
                if (fieldName === "uniqueIdentifier") {
                  const formattedNumber = fieldValue.replace(
                    /(\d{3})(\d{3})(\d{3})/,
                    "$1-$2-$3"
                  );
                  formMethods.setValue("uniqueIdentifier", formattedNumber);
                }
                if (fieldName === "id") {
                  // (async () => {
                  //   try {
                  //     setIsLoadingAdjustedData(true);
                  //     const responseAssociationId =
                  //       await T4SlipService.getRoleAssociationId(fieldValue);

                  //     console.log(
                  //       "responseData getRoleAssociationId : ",
                  //       responseAssociationId.associationId
                  //     );

                      formMethods.setValue(
                        "selectedEmployee",
                        fieldValue
                      );

                      // (async () => {
                      //   try {
                      //     const responseGetResId =
                      //       await T4SlipService.getResourceActualId(
                      //         responseAssociationId.associationId
                      //       );
                      //     console.log(
                      //       "responseData////////",
                      //       responseGetResId.resourceId
                      //     );

                          (async () => {
                            try {
                              const response: any =
                                await T4SlipService.getResourceSlipDropDownList(
                                  fieldValue,
                                  clientId,
                                  taxYear
                                );
                              // console.log(
                              //   "responseData-----------------",
                              //   response[0].value
                              // );
                              // formMethods.setValue("selectedSlip", response[0].value);

                              if (response && Array.isArray(response)) {
                                let resourceSlipList: IDropDownListResponse[] =
                                  response.map(
                                    (resourceSlip: IDropDownListResponse) => ({
                                      label: resourceSlip.label,
                                      value: resourceSlip.value,
                                    })
                                  );

                                resourceSlipList.unshift({
                                  label: "Create New",
                                  value: "create-new",
                                });

                                console.log(
                                  "resourceSlipListDDL",
                                  resourceSlipList
                                );
                                setSelectedResourceSlipList(resourceSlipList);
                                if (
                                  resourceSlipList &&
                                  resourceSlipList.length > 1
                                ) {
                                  (async () => {
                                    try {
                                      console.log(
                                        "slipssss",
                                        resourceSlipList[1].value
                                      );
                                      setIsLoadingAdjustedData(true);
                                      let slipDataById =
                                        await T4SlipService.getT4SlipById(
                                          String(resourceSlipList[1].value),
                                          clientId,
                                          taxYear
                                        );
                                        setT4SlipRecord(slipDataById)
                                      setSelectedResourceSlip(
                                        String(resourceSlipList[1].value)
                                      );
                                      formMethods.setValue(
                                        "selectedSlip",
                                        String(resourceSlipList[1].value)
                                      );
                                      console.log("slipDataById", slipDataById);
                                      if (slipDataById) {
                                        setIsLoadingAdjustedData(true);
                                        Object.keys(slipDataById).forEach(
                                          (fieldName) => {
                                            console.log(
                                              "fieldName useEffect T4 Slip : slipDataById",
                                              fieldName
                                            );
                                            // @ts-ignore
                                            const fieldValue =
                                              slipDataById[fieldName];
                                            console.log(
                                              "fieldValue useEffect T4 Slip : ",
                                              fieldValue
                                            );
                                            // @ts-ignore

                                            if (
                                              typeof fieldValue !== "object"
                                            ) {
                                              // @ts-ignore
                                              formMethods.setValue(
                                                fieldName,
                                                fieldValue ?? undefined
                                              );
                                            } else if (
                                              fieldValue !== null ||
                                              undefined
                                            ) {
                                              console.log("object");
                                              Object.keys(fieldValue).forEach(
                                                (objFieldName: any) => {
                                                  console.log(
                                                    "objFieldName useEffect T4 Slip : slipDataById",
                                                    objFieldName
                                                  );
                                                  // @ts-ignore
                                                  const objFieldValue =
                                                    fieldValue[objFieldName];
                                                  console.log(
                                                    "objFieldValue useEffect T4 Slip : ",
                                                    objFieldValue
                                                  );

                                                  if (
                                                    typeof objFieldValue !==
                                                    "object"
                                                  ) {
                                                    // @ts-ignore
                                                    formMethods.setValue(
                                                      objFieldName,
                                                      objFieldValue ?? undefined
                                                    );
                                                  }

                                                  if (
                                                    objFieldName ===
                                                    "dateOfBirth"
                                                  ) {
                                                    if (
                                                      objFieldValue !== null ||
                                                      undefined
                                                    ) {
                                                      if (
                                                        objFieldValue.charAt(
                                                          2
                                                        ) === "*"
                                                      ) {
                                                        // alert("yes")
                                                        formMethods.setValue(
                                                          "dateOfBirth",
                                                          objFieldValue
                                                        );
                                                        setDateOfBirth(
                                                          objFieldValue
                                                        );
                                                      } else {
                                                        // alert("No")
                                                        const defaultDate =
                                                          new Date(
                                                            objFieldValue
                                                          );
                                                        console.log(
                                                          "defaultDate",
                                                          defaultDate
                                                        );
                                                        formMethods.setValue(
                                                          "dateOfBirth",
                                                          defaultDate
                                                        );
                                                        setDateOfBirth(
                                                          formatDate(
                                                            defaultDate as Date
                                                          )
                                                        );
                                                        console.log(
                                                          "dateOfBirth",
                                                          dateOfBirth
                                                        );
                                                      }
                                                      setDateOfBirthPlaceHolder(
                                                        objFieldValue
                                                      );
                                                    } else if (
                                                      objFieldValue === null
                                                    ) {
                                                      const maskedStatus: any =
                                                        sessionStorage.getItem(
                                                          "maskEnabled"
                                                        );
                                                      if (
                                                        maskedStatus === "true"
                                                      ) {
                                                        setDateOfBirthPlaceHolder(
                                                          "0***"
                                                        ); //this would disable dob while value is null in upadte
                                                      }
                                                    }
                                                  }

                                                  if (
                                                    fieldName === "resource"
                                                  ) {
                                                    if (
                                                      objFieldName ===
                                                      "externalId"
                                                    ) {
                                                      // (async () => {
                                                      //   try {
                                                      //     const responseAssociationId =
                                                      //       await T4SlipService.getRoleAssociationId(
                                                      //         objFieldValue
                                                      //       );

                                                      //     console.log(
                                                      //       "responseData getRoleAssociationId : ",
                                                      //       responseAssociationId.associationId
                                                      //     );

                                                          formMethods.setValue(
                                                            "selectedEmployee",
                                                            objFieldValue
                                                          );

                                                          // (async () => {
                                                          //   try {
                                                          //     const responseGetResId =
                                                          //       await T4SlipService.getResourceActualId(
                                                          //         responseAssociationId.associationId
                                                          //       );
                                                          //     console.log(
                                                          //       "responseData////////",
                                                          //       responseGetResId.resourceId
                                                          //     );

                                                              (async () => {
                                                                try {
                                                                  const response: any =
                                                                    await T4SlipService.getResourceSlipDropDownList(
                                                                      objFieldValue,
                                                                      clientId,
                                                                      taxYear
                                                                    );
                                                                  if (
                                                                    response &&
                                                                    Array.isArray(
                                                                      response
                                                                    )
                                                                  ) {
                                                                    let resourceSlipList: IDropDownListResponse[] =
                                                                      response.map(
                                                                        (
                                                                          resourceSlip: IDropDownListResponse
                                                                        ) => ({
                                                                          label:
                                                                            resourceSlip.label,
                                                                          value:
                                                                            resourceSlip.value,
                                                                        })
                                                                      );

                                                                    resourceSlipList.unshift(
                                                                      {
                                                                        label:
                                                                          "Create New",
                                                                        value:
                                                                          "create-new",
                                                                      }
                                                                    );

                                                                    console.log(
                                                                      "resourceSlipListDDL",
                                                                      resourceSlipList
                                                                    );
                                                                    setSelectedResourceSlipList(
                                                                      resourceSlipList
                                                                    );
                                                                  }
                                                                } catch (error) {
                                                                  console.error(
                                                                    "Error fetching data:",
                                                                    error
                                                                  );
                                                                }
                                                              })();
                                                              // You can work with the data here or trigger other functions that depend on the fetched data
                                                      //       } catch (error) {
                                                      //         console.error(
                                                      //           "Error fetching data:",
                                                      //           error
                                                      //         );
                                                      //       }
                                                      //     })();
                                                      //   } catch (error) {
                                                      //     console.error(
                                                      //       "Error fetching data:",
                                                      //       error
                                                      //     );
                                                      //   }
                                                      // })();
                                                      setSelectedResource(
                                                        objFieldValue
                                                      );
                                                    }
                                                    setEditedResourceId(
                                                      fieldValue.externalId
                                                    );
                                                    if (
                                                      objFieldName ===
                                                      "reducedEi"
                                                    ) {
                                                      if (
                                                        objFieldValue === false
                                                      ) {
                                                        formMethods.setValue(
                                                          "reducedEi",
                                                          0
                                                        );
                                                      } else if (
                                                        objFieldValue === true
                                                      ) {
                                                        formMethods.setValue(
                                                          "reducedEi",
                                                          1
                                                        );
                                                      }
                                                    }
                                                    if (
                                                      objFieldName === "email"
                                                    ) {
                                                      if (
                                                        objFieldValue !== null
                                                      ) {
                                                        formMethods.setValue(
                                                          objFieldName,
                                                          objFieldValue
                                                        );
                                                      } else {
                                                        const maskedStatus: any =
                                                          sessionStorage.getItem(
                                                            "maskEnabled"
                                                          );
                                                        console.log(
                                                          "elesese",
                                                          maskedStatus
                                                        );
                                                        if (
                                                          maskedStatus ===
                                                          "true"
                                                        ) {
                                                          formMethods.setValue(
                                                            objFieldName,
                                                            "ze*****3@gmail.com"
                                                          );
                                                        } else {
                                                          formMethods.setValue(
                                                            objFieldName,
                                                            ""
                                                          );
                                                        }
                                                      }
                                                    }
                                                  }
                                                  if (
                                                    objFieldName === "province"
                                                  ) {
                                                    let id = objFieldValue.id;
                                                    let name =
                                                      objFieldValue.name;
                                                    let shortCode =
                                                      objFieldValue.shortCode;
                                                    console.log(
                                                      "namename",
                                                      name
                                                    );
                                                    let selectedPOEwhenUpdate =
                                                      objFieldValue.name;

                                                    formMethods.setValue(
                                                      objFieldName,
                                                      // '{id:9, name: "Ontario",shortCode:"ON"}'
                                                      `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                                                    );
                                                  }
                                                  if (
                                                    objFieldName ===
                                                    "provinceOfEmployment"
                                                  ) {
                                                    let id = objFieldValue.id;
                                                    let name =
                                                      objFieldValue.name;
                                                    let shortCode =
                                                      objFieldValue.shortCode;
                                                    console.log(
                                                      "namename",
                                                      name
                                                    );

                                                    formMethods.setValue(
                                                      objFieldName,
                                                      // '{id:9, name: "Ontario",shortCode:"ON"}'
                                                      `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                                                    );
                                                    if (
                                                      objFieldValue.name ===
                                                      "Quebec"
                                                    ) {
                                                      setShowbox17IfQC(true);
                                                      setSelectedProvinceOfEmployment(
                                                        "Quebec"
                                                      );
                                                    } else {
                                                      setShowbox17IfQC(false);
                                                      setSelectedProvinceOfEmployment(
                                                        name
                                                      );
                                                      console.log(
                                                        "sjhksbvksbvbsvkbskvvk",
                                                        selectedProvinceOfEmployment
                                                      );
                                                    }
                                                  }
                                                  if (
                                                    objFieldName === "country"
                                                  ) {
                                                    formMethods.setValue(
                                                      objFieldName,
                                                      objFieldValue.name ??
                                                        undefined
                                                    );
                                                  }
                                                  if (
                                                    objFieldName ===
                                                    "uniqueIdentifier"
                                                  ) {
                                                    const formattedNumber =
                                                      objFieldValue.replace(
                                                        /(\d{3})(\d{3})(\d{3})/,
                                                        "$1-$2-$3"
                                                      );
                                                    formMethods.setValue(
                                                      "uniqueIdentifier",
                                                      formattedNumber
                                                    );
                                                  }
                                                  if (
                                                    objFieldName !== "email" &&
                                                    objFieldValue === null
                                                  ) {
                                                    formMethods.setValue(
                                                      objFieldName,
                                                      ""
                                                    );
                                                  }
                                                }
                                              );
                                            }

                                            //set slip type according to edited row data for submit button action
                                            if (fieldName === "slipType") {
                                              selectedSlipTypee = fieldValue;
                                            }
                                            if (
                                              fieldName === "otherInformation"
                                            ) {
                                            setSlipOtherInformation(fieldValue)
                                              // formMethods.setValue(
                                              //   "comparison",
                                              //   [
                                              //     {
                                              //       amount: 0,
                                              //       code: "",
                                              //       label: "",
                                              //     },
                                              //   ]
                                              // );
                                              // if (
                                              //   fieldValue &&
                                              //   fieldValue !== null &&
                                              //   fieldValue.length !== 0
                                              // ) {
                                              //   setOiInEdit(true);
                                              //   formMethods.setValue(
                                              //     "comparison",
                                              //     fieldValue
                                              //   );
                                              // } else {
                                              //   console.log(
                                              //     "fieldValue1234ee else",
                                              //     fieldValue
                                              //   );
                                              //   setOiInEdit(true);
                                              //   formMethods.setValue(
                                              //     "comparison",
                                              //     [
                                              //       {
                                              //         amount: 0,
                                              //         code: "",
                                              //         label: "",
                                              //       },
                                              //     ]
                                              //   );
                                              // }
                                            }
                                            // if(fieldName === 'id'){
                                            //   // setNewCreatedSlipId(fieldValue)
                                            //   formMethods.setValue("selectedSlip",fieldValue);
                                            //   let path = `/taxslip/t4/setup/${fieldValue}`;
                                            //   navigate(path);
                                            // }
                                            if (fieldName === "slipData") {
                                              Object.keys(fieldValue).forEach(
                                                (slipDataFieldName: any) => {
                                                  const slipDataFieldValue =
                                                    fieldValue[
                                                      slipDataFieldName
                                                    ];
                                                  formMethods.setValue(
                                                    slipDataFieldName,
                                                    slipDataFieldValue &&
                                                      slipDataFieldValue !==
                                                        null
                                                      ? parseFloat(
                                                          slipDataFieldValue
                                                        ).toFixed(2)
                                                      : "0.00"
                                                  );
                                                  if (
                                                    slipDataFieldName ===
                                                    "registrationNumber"
                                                  ) {
                                                    formMethods.setValue(
                                                      slipDataFieldName,
                                                      slipDataFieldValue 
                                                    );
                                                  }
                                                  if (
                                                    slipDataFieldName ===
                                                    "employerOfferedDentalBenefits"
                                                  ) {
                                                    formMethods.setValue(
                                                      slipDataFieldName,
                                                      slipDataFieldValue
                                                    );
                                                  }
                                                  if (
                                                    slipDataFieldName ===
                                                    "eiInsurableEarnings"
                                                  ) {
                                                    console.log(
                                                      "slipDataFieldValue=",
                                                      slipDataFieldValue
                                                    );
                                                    if (
                                                      selectedPOEwhenUpdate !==
                                                        "" ||
                                                      "Quebec"
                                                    ) {
                                                      console.log(
                                                        "globalVariablesData-=",
                                                        globalVariablesData
                                                      );
                                                      console.log(
                                                        "chiieckboxDefaultSelect",
                                                        globalVariablesData &&
                                                          globalVariablesData
                                                            ?.data?.t4
                                                            ?.eiSettingsTotalEiEarningsMaximum
                                                      );
                                                      let val1 =
                                                        slipDataFieldValue;
                                                      let val2 =
                                                        globalVariablesData &&
                                                        globalVariablesData
                                                          ?.data?.t4
                                                          ?.eiSettingsTotalEiEarningsMaximum;
                                                      if (val1 > val2 && val2) {
                                                        console.log(
                                                          "inside ifui"
                                                        );
                                                        checkboxDefaultSelect =
                                                          true;
                                                        console.log(
                                                          "checkboxDefaultSelect",
                                                          checkboxDefaultSelect
                                                        );
                                                        console.log(
                                                          "checkboxDefaultSelect",
                                                          slipDataFieldValue
                                                        );
                                                      } else {
                                                        checkboxDefaultSelect =
                                                          false;
                                                        console.log(
                                                          "elseeeeeeeeeeeeeeeeeeeee"
                                                        );
                                                      }
                                                    }
                                                    if (
                                                      selectedPOEwhenUpdate ===
                                                      "Quebec"
                                                    ) {
                                                      console.log(
                                                        "globalVariablesData-=",
                                                        globalVariablesData
                                                      );
                                                      console.log(
                                                        "chiieckboxDefaultSelect",
                                                        globalVariablesData &&
                                                          globalVariablesData
                                                            ?.data?.t4
                                                            ?.eiSettingsTotalEiEarningsMaximum
                                                      );
                                                      let val1 =
                                                        slipDataFieldValue;
                                                      let val2 =
                                                        globalVariablesData &&
                                                        globalVariablesData
                                                          ?.data?.rl1
                                                          ?.eiSettingsQubecTotalEiEarningsMaximum;
                                                      if (val1 > val2 && val2) {
                                                        console.log(
                                                          "inside ifui"
                                                        );
                                                        checkboxDefaultSelect =
                                                          true;
                                                        console.log(
                                                          "checkboxDefaultSelect",
                                                          checkboxDefaultSelect
                                                        );
                                                        console.log(
                                                          "checkboxDefaultSelect",
                                                          slipDataFieldValue
                                                        );
                                                      } else {
                                                        checkboxDefaultSelect =
                                                          false;
                                                        console.log(
                                                          "elseeeeeeeeeeeeeeeeeeeee"
                                                        );
                                                      }
                                                    }
                                                  }
                                                }
                                              );
                                            }
                                            if (
                                              fieldName === "adjustedSlipData"
                                            ) {
                                              Object.keys(fieldValue).forEach(
                                                (adjSlipDataFieldName: any) => {
                                                  const adjSlipDataFieldValue =
                                                    fieldValue[
                                                      adjSlipDataFieldName
                                                    ];
                                                  console.log(
                                                    "adjSlipDataFieldValue",
                                                    adjSlipDataFieldValue
                                                  );
                                                  formMethods.setValue(
                                                    adjSlipDataFieldName,
                                                    adjSlipDataFieldValue !==
                                                      null || undefined
                                                      ? parseFloat(
                                                          adjSlipDataFieldValue
                                                        ).toFixed(2)
                                                      : "0.00"
                                                  );
                                                }
                                              );
                                            }
                                          }
                                        );
                                      }
                                      //set slip type to 400 because when we come back to slip without reload it will automatically be original
                                      selectedSlipTypee =
                                        formMethods.getValues("slipType");

                                      //otherinformation will update sselected items when its submitted
                                      //  setSelectedOtherInformation(true)
                                      setSelectedOtherInformation(false);
                                    } catch (error) {
                                      setIsLoadingAdjustedData(false);
                                      console.error(
                                        "Error fetching data:",
                                        error
                                      );
                                    } finally {
                                      setSelectedResourceSlip(
                                        String(resourceSlipList[1].value)
                                      );
                                      formMethods.setValue(
                                        "selectedSlip",
                                        String(resourceSlipList[1].value)
                                      );
                                      let path = `/taxslip/t4/setup/${resourceSlipList[1].value}`;
                                      navigate(path);
                                      setTimeout(() => {
                                        setIsLoadingAdjustedData(false);
                                      }, 3000);
                                    }
                                  })();
                                } else {
                                  setT4SlipRecord(undefined)
                                  setSelectedResourceSlip("create-new");
                                  formMethods.setValue(
                                    "selectedSlip",
                                    "create-new"
                                  );
                                  formMethods.setValue(
                                    "applyAdjustments",
                                    false
                                  );
                                  formMethods.setValue(
                                    "employmentIncome",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "cppContributions",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "secondCppContributions",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "qppContributions",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "secondQppContributions",
                                    "0.00"
                                  );
                                  formMethods.setValue("eiPremiums", "0.00");
                                  formMethods.setValue(
                                    "rppContributions",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "incomeTaxDeducted",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "eiInsurableEarnings",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "cppQppPensionableEarnings",
                                    "0.00"
                                  );
                                  formMethods.setValue("unionDues", "0.00");
                                  formMethods.setValue(
                                    "charitableDonations",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "registrationNumber",
                                    undefined
                                  );
                                  formMethods.setValue(
                                    "pensionAdjustment",
                                    "0.00"
                                  );
                                  formMethods.setValue("ppipPremiums", "0.00");
                                  formMethods.setValue(
                                    "ppipInsurableEarnings",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "employmentIncomeAdjusted",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "cppContributionsAdjusted",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "secondCppContributionsAdjusted",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "secondQppContributionsAdjusted",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "eiPremiumsAdjusted",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "incomeTaxDeductedAdjusted",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "eiInsurableEarningsAdjusted",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "ppipPremiumsAdjusted",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "ppipInsurableEarningsAdjusted",
                                    0
                                  );
                                  formMethods.setValue("slipStatus", 200);
                                  formMethods.setValue("comparison", []);
                                  setSlipOtherInformation([{ amount: 0, code: 'null', label:'Empty Field'}])

                                  setSelectedResource(
                                    String(
                                      selectedItem.value
                                    )
                                  );
                                  setEditedResourceId(
                                    String(
                                      selectedItem.value
                                    )
                                  );
                                  let path = `/taxslip/t4/setup/${selectedItem.value}`;
                                  navigate(path);
                                  setIsLoadingAdjustedData(false)
                                  // setTimeout(() => {
                                  //   setIsLoadingAdjustedData(false);
                                  // }, 3000);
                                }
                              }
                            } catch (error) {
                              console.error("Error fetching data:", error);
                            }
                          })();
                          // You can work with the data here or trigger other functions that depend on the fetched data
                  //       } catch (error) {
                  //         console.error("Error fetching data:", error);
                  //       }
                  //     })();
                  //     // setIsLoadingAdjustedData(true)
                  //   } catch (error) {
                  //     console.error("Error fetching data:", error);
                  //   }
                  // })();
                  setSelectedResource(fieldValue);
                }
              });
            }
          } catch (error) {
            setIsLoadingAdjustedData(false);
            console.error("Error fetching data:", error);
          }
          // finally {
          //   setSelectedResource(String(response.resourceId));
          //   setEditedResourceId(String(response.resourceId));
          //   let path = `/taxslip/t4/setup/${response.resourceId}`;
          //   navigate(path);
          //   // setIsLoadingAdjustedData(false)
          //   setTimeout(() => {
          //     setIsLoadingAdjustedData(false);
          //   }, 3000);
          // }
        })();
      // } catch (err) {
      //   console.log("err", err);
      //   // alert(err);
      // }
    }
    //otherinformation will update selected items
    setSelectedOtherInformation(false);
  };

  const onResourceSlipSelectionChangeHandler = (
    selectedItem: IEnhancedDropDownItem | any
  ) => {
    console.log("selectedItem.value", selectedItem.value);
    setOnResourceSelectionAPIsCalls(true)
    if (selectedItem.value === "create-new") {
      createNewActionFromSlipDDL();
      setSelectedResourceSlip("create-new");
      if (selectedResource !== "create-new") {
        setOnResourceSelectionAPIsCalls(true);
        let path = `/taxslip/t4/setup/${selectedResource}`;
        navigate(path);
        window.location.reload()
      }
      setT4SlipRecord(undefined);
    }
    if (selectedItem.value !== "create-new") {
      // setSelectedResourceSlip(String(selectedItem.value));
      // let path = `/taxslip/t4/setup/${selectedItem.value}`;
      // navigate(path);
      // setIsLoadingAdjustedData(true);
      // setTimeout(() => {
      //   setIsLoadingAdjustedData(false);
      // }, 1000);
      (async () => {
        try {
          setIsLoadingAdjustedData(true);
          let slipDataById = await T4SlipService.getT4SlipById(
            String(selectedItem.value),
            clientId,
            taxYear
          );
          setT4SlipRecord(slipDataById)
          console.log("slipDataById", slipDataById);
          if (slipDataById) {
            setIsLoadingAdjustedData(true);
            Object.keys(slipDataById).forEach((fieldName) => {
              console.log(
                "fieldName useEffect T4 Slip : slipDataById",
                fieldName
              );
              // @ts-ignore
              const fieldValue = slipDataById[fieldName];
              console.log("fieldValue useEffect T4 Slip : ", fieldValue);
              // @ts-ignore

              if (typeof fieldValue !== "object") {
                // @ts-ignore
                formMethods.setValue(fieldName, fieldValue ?? undefined);
              } else if (fieldValue !== null || undefined) {
                console.log("object");
                Object.keys(fieldValue).forEach((objFieldName: any) => {
                  console.log(
                    "objFieldName useEffect T4 Slip : slipDataById",
                    objFieldName
                  );
                  // @ts-ignore
                  const objFieldValue = fieldValue[objFieldName];
                  console.log(
                    "objFieldValue useEffect T4 Slip : ",
                    objFieldValue
                  );

                  if (typeof objFieldValue !== "object") {
                    // @ts-ignore
                    formMethods.setValue(
                      objFieldName,
                      objFieldValue ?? undefined
                    );
                  }

                  if (objFieldName === "dateOfBirth") {
                    if (objFieldValue !== null || undefined) {
                      if (objFieldValue.charAt(2) === "*") {
                        // alert("yes")
                        formMethods.setValue("dateOfBirth", objFieldValue);
                        setDateOfBirth(objFieldValue);
                      } else {
                        // alert("No")
                        const defaultDate = new Date(objFieldValue);
                        console.log("defaultDate", defaultDate);
                        formMethods.setValue("dateOfBirth", defaultDate);
                        setDateOfBirth(formatDate(defaultDate as Date));
                        console.log("dateOfBirth", dateOfBirth);
                      }
                      setDateOfBirthPlaceHolder(objFieldValue);
                    } else if (objFieldValue === null) {
                      const maskedStatus: any = sessionStorage.getItem("maskEnabled");
                      if (maskedStatus === "true") {
                        setDateOfBirthPlaceHolder("0***"); //this would disable dob while value is null in upadte
                      }
                    }
                  }

                  if (fieldName === "resource") {
                    if (objFieldName === "externalId") {
                      // (async () => {
                      //   try {
                      //     const responseAssociationId =
                      //       await T4SlipService.getRoleAssociationId(
                      //         objFieldValue
                      //       );

                      //     console.log(
                      //       "responseData getRoleAssociationId : ",
                      //       responseAssociationId.associationId
                      //     );

                          formMethods.setValue(
                            "selectedEmployee",
                            objFieldValue
                          );

                          // (async () => {
                          //   try {
                          //     const responseGetResId =
                          //       await T4SlipService.getResourceActualId(
                          //         responseAssociationId.associationId
                          //       );
                          //     console.log(
                          //       "responseData////////",
                          //       responseGetResId.resourceId
                          //     );

                              (async () => {
                                try {
                                  const response: any =
                                    await T4SlipService.getResourceSlipDropDownList(
                                      objFieldValue,
                                      clientId,
                                      taxYear
                                    );
                                  if (response && Array.isArray(response)) {
                                    let resourceSlipList: IDropDownListResponse[] =
                                      response.map(
                                        (
                                          resourceSlip: IDropDownListResponse
                                        ) => ({
                                          label: resourceSlip.label,
                                          value: resourceSlip.value,
                                        })
                                      );

                                    resourceSlipList.unshift({
                                      label: "Create New",
                                      value: "create-new",
                                    });

                                    console.log(
                                      "resourceSlipListDDL",
                                      resourceSlipList
                                    );
                                    setSelectedResourceSlipList(
                                      resourceSlipList
                                    );
                                  }
                                } catch (error) {
                                  console.error("Error fetching data:", error);
                                }
                              })();
                              // You can work with the data here or trigger other functions that depend on the fetched data
                      //       } catch (error) {
                      //         console.error("Error fetching data:", error);
                      //       }
                      //     })();
                      //   } catch (error) {
                      //     console.error("Error fetching data:", error);
                      //   }
                      // })();
                      setSelectedResource(objFieldValue);
                    }
                    setEditedResourceId(fieldValue.externalId);
                    if (objFieldName === "reducedEi") {
                      if (objFieldValue === false) {
                        formMethods.setValue("reducedEi", 0);
                      } else if (objFieldValue === true) {
                        formMethods.setValue("reducedEi", 1);
                      }
                    }
                    if (objFieldName === "email") {
                      if (objFieldValue !== null) {
                        formMethods.setValue(objFieldName, objFieldValue);
                      } else {
                        const maskedStatus: any =
                          sessionStorage.getItem("maskEnabled");
                        console.log("elesese", maskedStatus);
                        if (maskedStatus === "true") {
                          console.log("elesese if", maskedStatus);
                          formMethods.setValue("email", "ze*****3@gmail.com");
                        } else {
                          formMethods.setValue(objFieldName, "");
                        }
                      }
                    }
                  }
                  if (objFieldName === "province") {
                    let id = objFieldValue.id;
                    let name = objFieldValue.name;
                    let shortCode = objFieldValue.shortCode;
                    console.log("namename", name);
                    let selectedPOEwhenUpdate = objFieldValue.name;
      
                    formMethods.setValue(
                      objFieldName,
                      // '{id:9, name: "Ontario",shortCode:"ON"}'
                      `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                    );
                  }
                  if (objFieldName === "provinceOfEmployment") {
                    let id = objFieldValue.id;
                    let name = objFieldValue.name;
                    let shortCode = objFieldValue.shortCode;
                    console.log("namename", name);
                    formMethods.setValue(
                      objFieldName,
                      // '{id:9, name: "Ontario",shortCode:"ON"}'
                      `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                    );
                    if (objFieldValue.name === "Quebec") {
                      setShowbox17IfQC(true);
                      setSelectedProvinceOfEmployment("Quebec");
                    } else {
                      setShowbox17IfQC(false);
                      setSelectedProvinceOfEmployment(name);
                      console.log(
                        "sjhksbvksbvbsvkbskvvk",
                        selectedProvinceOfEmployment
                      );
                    }
                  }
                  if (objFieldName === "country") {
                    formMethods.setValue(
                      objFieldName,
                      objFieldValue.name ?? undefined
                    );
                  }
                  if (objFieldName === "uniqueIdentifier") {
                    const formattedNumber = objFieldValue.replace(
                      /(\d{3})(\d{3})(\d{3})/,
                      "$1-$2-$3"
                    );
                    formMethods.setValue("uniqueIdentifier", formattedNumber);
                  }
                  if (objFieldName !== "email" && objFieldValue === null) {
                    formMethods.setValue(objFieldName, "");
                  }
                });
              }

              //set slip type according to edited row data for submit button action
              if (fieldName === "slipType") {
                selectedSlipTypee = fieldValue;
                setIsLoadingAdjustedData(true);
                setTimeout(() => {
                  setIsLoadingAdjustedData(false);
                }, 1000);
              }
              if (fieldName === "otherInformation") {
                setSlipOtherInformation([])
                if (fieldValue && fieldValue !== null && fieldValue.length !== 0) {
                  console.log("fieldValue1234ee", fieldValue);
                  let oiFromSimplePay = [
                    {
                      code: "32",
                      label: "Other Information",
                      amount: 400.0,
                    },
                    {
                      code: "42",
                      label: "Other Information",
                      amount: 200.0,
                    },
                  ];
                  setOiInEdit(true);
                  formMethods.setValue("comparison", fieldValue);
                } else {
                  console.log("fieldValue1234ee else", fieldValue);
                  setOiInEdit(true);
                  formMethods.setValue("comparison", [
                    { amount: 0, code: "", label: "" },
                  ]);
                }
              }
              if (fieldName === "id") {
                // setNewCreatedSlipId(fieldValue)
                formMethods.setValue("selectedSlip", fieldValue);
                let path = `/taxslip/t4/setup/${fieldValue}`;
                navigate(path);
              }
              if (fieldName === "slipData") {
                Object.keys(fieldValue).forEach((slipDataFieldName: any) => {
                  const slipDataFieldValue = fieldValue[slipDataFieldName];
                  formMethods.setValue(
                    slipDataFieldName,
                    slipDataFieldValue && slipDataFieldValue !== null
                      ? parseFloat(slipDataFieldValue).toFixed(2)
                      : "0.00"
                  );
                  if (slipDataFieldName === "registrationNumber") {
                    formMethods.setValue(slipDataFieldName, slipDataFieldValue);
                  }
                  if (slipDataFieldName === "employerOfferedDentalBenefits") {
                    formMethods.setValue(slipDataFieldName, slipDataFieldValue);
                  }
                  if (slipDataFieldName === "eiInsurableEarnings") {
                    console.log("slipDataFieldValue=", slipDataFieldValue);
                    if (selectedPOEwhenUpdate !== "" || "Quebec") {
                      console.log("globalVariablesData-=", globalVariablesData);
                      console.log(
                        "chiieckboxDefaultSelect",
                        globalVariablesData &&
                          globalVariablesData?.data?.t4
                            ?.eiSettingsTotalEiEarningsMaximum
                      );
                      let val1 = slipDataFieldValue;
                      let val2 =
                        globalVariablesData &&
                        globalVariablesData?.data?.t4
                          ?.eiSettingsTotalEiEarningsMaximum;
                      if (val1 > val2 && val2) {
                        console.log("inside ifui");
                        checkboxDefaultSelect = true;
                        console.log("checkboxDefaultSelect", checkboxDefaultSelect);
                        console.log("checkboxDefaultSelect", slipDataFieldValue);
                      } else {
                        checkboxDefaultSelect = false;
                        console.log("elseeeeeeeeeeeeeeeeeeeee");
                      }
                    }
                    if (selectedPOEwhenUpdate === "Quebec") {
                      console.log("globalVariablesData-=", globalVariablesData);
                      console.log(
                        "chiieckboxDefaultSelect",
                        globalVariablesData &&
                          globalVariablesData?.data?.t4
                            ?.eiSettingsTotalEiEarningsMaximum
                      );
                      let val1 = slipDataFieldValue;
                      let val2 =
                        globalVariablesData &&
                        globalVariablesData?.data?.rl1
                          ?.eiSettingsQubecTotalEiEarningsMaximum;
                      if (val1 > val2 && val2) {
                        console.log("inside ifui");
                        checkboxDefaultSelect = true;
                        console.log("checkboxDefaultSelect", checkboxDefaultSelect);
                        console.log("checkboxDefaultSelect", slipDataFieldValue);
                      } else {
                        checkboxDefaultSelect = false;
                        console.log("elseeeeeeeeeeeeeeeeeeeee");
                      }
                    }
                    formMethods.setValue(slipDataFieldName, parseFloat(slipDataFieldValue).toFixed(2));
                  }
                });
              }
              if (fieldName === "adjustedSlipData") {
                Object.keys(fieldValue).forEach((adjSlipDataFieldName: any) => {
                  const adjSlipDataFieldValue = fieldValue[adjSlipDataFieldName];
                  console.log("adjSlipDataFieldValue", adjSlipDataFieldValue);
                  formMethods.setValue(
                    adjSlipDataFieldName,
                    adjSlipDataFieldValue !== null || undefined
                      ? parseFloat(adjSlipDataFieldValue).toFixed(2)
                      : "0.00"
                  );
                });
              }
            });
            setIsLoadingAdjustedData(false);
          }
          //set slip type to 400 because when we come back to slip without reload it will automatically be original
          selectedSlipTypee = formMethods.getValues("slipType");

          //otherinformation will update sselected items when its submitted
          //  setSelectedOtherInformation(true)
          setSelectedOtherInformation(false);
          setIsLoadingAdjustedData(false);
        } catch (error) {
          setIsLoadingAdjustedData(false);
          console.error("Error fetching data:", error);
        } finally {
          setSelectedResourceSlip(String(selectedItem.value));
          let path = `/taxslip/t4/setup/${selectedItem.value}`;
          navigate(path);
          setTimeout(() => {
            setIsLoadingAdjustedData(false);
          }, 2500);
        }
      })();
    }
    //otherinformation will update selected items
    setSelectedOtherInformation(true);
  };

  // useEffect(() => {
  //   if (selectedResource !== "create-new") {
  //     refetchResourceSlips();
  //   } else {
  //   }
  // }, [refetchResourceSlips, selectedResource]);

  // useEffect(() => {
  //   if (selectedResourceSlip !== "create-new") {
  //     refetchSlipRecord();
  //   } else {
  //   }
  // }, [refetchSlipRecord, selectedResourceSlip]);

  // for edit

  //if slip is completed this will effected
  //if dob is masked then placeholder change
  const [dateOfBirthPlaceHolder, setDateOfBirthPlaceHolder] = useState("");
  console.log("dateOfBirthPlaceHolder", dateOfBirthPlaceHolder);
  let selectedPOEwhenUpdate = "";
  useEffect(() => {
    // if the selected flex field data has loaded.
    console.log("onResourceSelectionAPIsCalls-=",onResourceSelectionAPIsCalls)
    if (T4SlipRecord && onResourceSelectionAPIsCalls === false) {
      setIsLoadingAdjustedData(true);
      Object.keys(T4SlipRecord).forEach((fieldName) => {
        console.log("fieldName useEffect T4 Slip : T4SlipRecord", fieldName);
        // @ts-ignore
        const fieldValue = T4SlipRecord[fieldName];
        console.log("fieldValue useEffect T4 Slip : ", fieldValue);
        // @ts-ignore

        if (typeof fieldValue !== "object") {
          // @ts-ignore
          formMethods.setValue(fieldName, fieldValue ?? undefined);
        } else if (fieldValue !== null || undefined) {
          console.log("object");
          Object.keys(fieldValue).forEach((objFieldName: any) => {
            console.log(
              "objFieldName useEffect T4 Slip : T4SlipRecord",
              objFieldName
            );
            // @ts-ignore
            const objFieldValue = fieldValue[objFieldName];
            console.log("objFieldValue useEffect T4 Slip : ", objFieldValue);

            if (typeof objFieldValue !== "object") {
              // @ts-ignore
              formMethods.setValue(objFieldName, objFieldValue ?? undefined);
            }

            if (objFieldName === "dateOfBirth") {
              if (objFieldValue !== null || undefined) {
                if (objFieldValue.charAt(2) === "*") {
                  // alert("yes")
                  formMethods.setValue("dateOfBirth", objFieldValue);
                  setDateOfBirth(objFieldValue);
                } else {
                  // alert("No")
                  const defaultDate = new Date(objFieldValue);
                  console.log("defaultDate", defaultDate);
                  formMethods.setValue("dateOfBirth", defaultDate);
                  setDateOfBirth(formatDate(defaultDate as Date));
                  console.log("dateOfBirth", dateOfBirth);
                }
                setDateOfBirthPlaceHolder(objFieldValue);
              } else if (objFieldValue === null) {
                const maskedStatus: any = sessionStorage.getItem("maskEnabled");
                if (maskedStatus === "true") {
                  setDateOfBirthPlaceHolder("0***"); //this would disable dob while value is null in upadte
                }
              }
            }

            if (fieldName === "resource") {
              if (objFieldName === "externalId") {
                // console.log("objFieldValue : externalid ", objFieldValue);
                // let roleAssociationId = "d0a41913-11f3-4f00-a824-9977909bdd04";
                // (async () => {
                //   try {
                //     const responseAssociationId =
                //       await T4SlipService.getRoleAssociationId(objFieldValue);

                //     console.log(
                //       "responseData getRoleAssociationId : ",
                //       responseAssociationId.associationId
                //     );
                    // Find the index of the object with the specified key
                    // const indexForSelectedCard = resourceListWithoutCreateNew && resourceListWithoutCreateNew.findIndex((item:any) => item.value === responseAssociationId.associationId);
                    // console.log("indexForSelectedCard",indexForSelectedCard)
                    // setSelectedCard(indexForSelectedCard)
                    //for auto selected card
                    setSelectedCard(
                      String(objFieldValue)
                    );

                    formMethods.setValue(
                      "selectedEmployee",
                      objFieldValue
                    );

                    // (async () => {
                    //   try {
                    //     const responseGetResId =
                    //       await T4SlipService.getResourceActualId(
                    //         responseAssociationId.associationId
                    //       );
                    //     console.log(
                    //       "responseData////////",
                    //       responseGetResId.resourceId
                    //     );

                        (async () => {
                          try {
                            const response: any =
                              await T4SlipService.getResourceSlipDropDownList(
                                objFieldValue,
                                clientId,
                                taxYear
                              );
                            // console.log(
                            //   "responseData-----------------",
                            //   response[0].value
                            // );
                            // formMethods.setValue("selectedSlip", response[0].value);

                            if (response && Array.isArray(response)) {
                              let resourceSlipList: IDropDownListResponse[] =
                                response.map(
                                  (resourceSlip: IDropDownListResponse) => ({
                                    label: resourceSlip.label,
                                    value: resourceSlip.value,
                                  })
                                );

                              resourceSlipList.unshift({
                                label: "Create New",
                                value: "create-new",
                              });

                              console.log(
                                "resourceSlipListDDL",
                                resourceSlipList
                              );
                              setSelectedResourceSlipList(resourceSlipList);
                              //for Cards scenario
                              if (
                                resourceSlipList &&
                                resourceSlipList.length > 1
                              ) {
                                setSelectedResourceSlip(
                                  String(resourceSlipList[1].value)
                                );
                              } else {
                                setSelectedResourceSlip("create-new");
                              }
                            }
                          } catch (error) {
                            console.error("Error fetching data:", error);
                          }
                        })();
                        // You can work with the data here or trigger other functions that depend on the fetched data
                //       } catch (error) {
                //         console.error("Error fetching data:", error);
                //       }
                //     })();
                //   } catch (error) {
                //     console.error("Error fetching data:", error);
                //   }
                // })();
                setSelectedResource(objFieldValue);
              }
              setEditedResourceId(fieldValue.externalId);
              if (objFieldName === "reducedEi") {
                if (objFieldValue === false) {
                  formMethods.setValue("reducedEi", 0);
                } else if (objFieldValue === true) {
                  formMethods.setValue("reducedEi", 1);
                }
              }
              if (objFieldName === "email") {
                if (objFieldValue !== null) {
                  formMethods.setValue(objFieldName, objFieldValue);
                } else {
                  const maskedStatus: any =
                    sessionStorage.getItem("maskEnabled");
                  console.log("elesese", maskedStatus);
                  if (maskedStatus === "true") {
                    console.log("elesese if", maskedStatus);
                    formMethods.setValue("email", "ze*****3@gmail.com");
                  } else {
                    formMethods.setValue(objFieldName, "");
                  }
                }
              }
            }
            if (objFieldName === "province") {
              let id = objFieldValue.id;
              let name = objFieldValue.name;
              let shortCode = objFieldValue.shortCode;
              console.log("namename", name);
              let selectedPOEwhenUpdate = objFieldValue.name;

              formMethods.setValue(
                objFieldName,
                // '{id:9, name: "Ontario",shortCode:"ON"}'
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
              );
            }
            if (objFieldName === "provinceOfEmployment") {
              let id = objFieldValue.id;
              let name = objFieldValue.name;
              let shortCode = objFieldValue.shortCode;
              console.log("namename", name);

              formMethods.setValue(
                objFieldName,
                // '{id:9, name: "Ontario",shortCode:"ON"}'
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
              );
              if (objFieldValue.name === "Quebec") {
                setShowbox17IfQC(true);
                setSelectedProvinceOfEmployment("Quebec");
              } else {
                setShowbox17IfQC(false);
                setSelectedProvinceOfEmployment(name);
                console.log(
                  "sjhksbvksbvbsvkbskvvk",
                  selectedProvinceOfEmployment
                );
              }
            }
            if (objFieldName === "country") {
              formMethods.setValue(
                objFieldName,
                objFieldValue.name ?? undefined
              );
            }
            if (objFieldName === "uniqueIdentifier") {
              const formattedNumber = objFieldValue.replace(
                /(\d{3})(\d{3})(\d{3})/,
                "$1-$2-$3"
              );
              formMethods.setValue("uniqueIdentifier", formattedNumber);
            }
            if (objFieldName !== "email" && objFieldValue === null) {
              formMethods.setValue(objFieldName, "");
            }
          });
        }

        //set slip type according to edited row data for submit button action
        if (fieldName === "slipType") {
          selectedSlipTypee = fieldValue;
          setIsLoadingAdjustedData(true);
          setTimeout(() => {
            setIsLoadingAdjustedData(false);
          }, 1000);
        }
        if (fieldName === "otherInformation") {
          setSlipOtherInformation([])
          if (fieldValue && fieldValue !== null && fieldValue.length !== 0) {
            console.log("fieldValue1234ee", fieldValue);
            let oiFromSimplePay = [
              {
                code: "32",
                label: "Other Information",
                amount: 400.0,
              },
              {
                code: "42",
                label: "Other Information",
                amount: 200.0,
              },
            ];
            setOiInEdit(true);
            formMethods.setValue("comparison", fieldValue);
          } else {
            console.log("fieldValue1234ee else", fieldValue);
            setOiInEdit(true);
            formMethods.setValue("comparison", [
              { amount: 0, code: "", label: "" },
            ]);
          }
          //   let oiFromSimplePay = [{
          //     "code": "32",
          //     "label":"32 - Travel in a prescribed zone",
          //     "amount": 400.0
          // }]
          // let oiFromSimplePay = [
          //       {
          //         "code": "32",
          //         "label": "32 - Travel in a prescribed zone",
          //         "amount": 123.0
          //     }
          // ]
          // formMethods.setValue('comparison',oiFromSimplePay)
        }
        if (fieldName === "id") {
          // setNewCreatedSlipId(fieldValue)
          formMethods.setValue("selectedSlip", fieldValue);
          let path = `/taxslip/t4/setup/${fieldValue}`;
          navigate(path);
        }
        if (fieldName === "slipData") {
          Object.keys(fieldValue).forEach((slipDataFieldName: any) => {
            const slipDataFieldValue = fieldValue[slipDataFieldName];
            formMethods.setValue(
              slipDataFieldName,
              slipDataFieldValue && slipDataFieldValue !== null
                ? parseFloat(slipDataFieldValue).toFixed(2)
                : "0.00"
            );
            if (slipDataFieldName === "registrationNumber") {
              formMethods.setValue(slipDataFieldName, slipDataFieldValue);
            }
            if (slipDataFieldName === "employerOfferedDentalBenefits") {
              formMethods.setValue(slipDataFieldName, slipDataFieldValue);
            }
            if (slipDataFieldName === "eiInsurableEarnings") {
              console.log("slipDataFieldValue=", slipDataFieldValue);
              if (selectedPOEwhenUpdate !== "" || "Quebec") {
                console.log("globalVariablesData-=", globalVariablesData);
                console.log(
                  "chiieckboxDefaultSelect",
                  globalVariablesData &&
                    globalVariablesData?.data?.t4
                      ?.eiSettingsTotalEiEarningsMaximum
                );
                let val1 = slipDataFieldValue;
                let val2 =
                  globalVariablesData &&
                  globalVariablesData?.data?.t4
                    ?.eiSettingsTotalEiEarningsMaximum;
                if (val1 > val2 && val2) {
                  console.log("inside ifui");
                  checkboxDefaultSelect = true;
                  console.log("checkboxDefaultSelect", checkboxDefaultSelect);
                  console.log("checkboxDefaultSelect", slipDataFieldValue);
                } else {
                  checkboxDefaultSelect = false;
                  console.log("elseeeeeeeeeeeeeeeeeeeee");
                }
              }
              if (selectedPOEwhenUpdate === "Quebec") {
                console.log("globalVariablesData-=", globalVariablesData);
                console.log(
                  "chiieckboxDefaultSelect",
                  globalVariablesData &&
                    globalVariablesData?.data?.t4
                      ?.eiSettingsTotalEiEarningsMaximum
                );
                let val1 = slipDataFieldValue;
                let val2 =
                  globalVariablesData &&
                  globalVariablesData?.data?.rl1
                    ?.eiSettingsQubecTotalEiEarningsMaximum;
                if (val1 > val2 && val2) {
                  console.log("inside ifui");
                  checkboxDefaultSelect = true;
                  console.log("checkboxDefaultSelect", checkboxDefaultSelect);
                  console.log("checkboxDefaultSelect", slipDataFieldValue);
                } else {
                  checkboxDefaultSelect = false;
                  console.log("elseeeeeeeeeeeeeeeeeeeee");
                }
              }
              // if (selectedPOE !== "" || "Quebec") {
              //   if(slipDataFieldName === 'employmentIncome'){
              //   formMethods.setValue('eiInsurableEarnings',Math.min(Number(slipDataFieldValue), globalVariablesData && globalVariablesData?.data?.t4?.eiSettingsTotalEiEarningsMaximum).toString())
              //   }
              // }
              // if (selectedPOE === "Quebec") {
              //   if(slipDataFieldName === 'employmentIncome'){
              //     formMethods.setValue('eiInsurableEarnings',Math.min(Number(slipDataFieldValue), globalVariablesData && globalVariablesData?.data?.rl1?.eiSettingsQubecTotalEiEarningsMaximum).toString())
              //   }
              // }
              formMethods.setValue(slipDataFieldName, parseFloat(slipDataFieldValue).toFixed(2));
            }
            // if(slipDataFieldName === 'cppQppPensionableEarnings'){
            console.log(
              "slipDataFieldValue=cppQppPensionableEarnings",
              slipDataFieldValue
            );
            // if (selectedPOE !== "" || "Quebec") {
            //   if(slipDataFieldName === 'employmentIncome'){
            //     formMethods.setValue('cppQppPensionableEarnings',Math.min(Number(slipDataFieldValue), globalVariablesData && globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum).toString())
            //   }
            // }
            // if (selectedPOE === "Quebec") {
            //   if(slipDataFieldName === 'employmentIncome'){
            //     formMethods.setValue('cppQppPensionableEarnings',Math.min(Number(slipDataFieldValue), globalVariablesData && globalVariablesData?.data?.rl1?.qppSettingsTotalQppPensionableEarningsMaximum).toString())
            //   }
            // }
            // }
          });
        }
        if (fieldName === "adjustedSlipData") {
          Object.keys(fieldValue).forEach((adjSlipDataFieldName: any) => {
            const adjSlipDataFieldValue = fieldValue[adjSlipDataFieldName];
            console.log("adjSlipDataFieldValue", adjSlipDataFieldValue);
            formMethods.setValue(
              adjSlipDataFieldName,
              adjSlipDataFieldValue !== null || undefined
                ? parseFloat(adjSlipDataFieldValue).toFixed(2)
                : "0.00"
            );
          });
        }
      });
      setIsLoadingAdjustedData(false);
    }
    //set slip type to 400 because when we come back to slip without reload it will automatically be original
    selectedSlipTypee = formMethods.getValues("slipType");

    //otherinformation will update sselected items when its submitted
    //  setSelectedOtherInformation(true)
    setSelectedOtherInformation(false);

    if(!id && !T4SlipRecord && defaultSettingsData && defaultSettingsData.t4DefaultSetting){
      console.log("t4DefaultSetting",defaultSettingsData.t4DefaultSetting)
      defaultSettingsCalls()
      } else if(!id && !T4SlipRecord && defaultSettingsData && defaultSettingsData.message) {
        console.log("t4DefaultSetting else",defaultSettingsData)
        formMethods.setValue("comparison", [
          { amount: 0, code: "", label: "" },
          ]);
      }
  }, [T4SlipRecord, formMethods.setValue, formMethods, globalVariablesData, id, defaultSettingsData, onResourceSelectionAPIsCalls]);

  //this is for not started slip
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (
      selectedNotStartedResourceData &&
      onResourceSelectionAPIsCalls === false
    ) {
      Object.keys(selectedNotStartedResourceData).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = selectedNotStartedResourceData[fieldName];
        console.log(
          "fieldNamefieldName",
          fieldName,
          " && ",
          "fieldValuefieldValue ",
          fieldValue
        );
        formMethods.setValue(
          fieldName,
          fieldValue !== null && fieldValue !== "" ? fieldValue : ""
        );

        if (fieldName === "address") {
          Object.keys(fieldValue).forEach((resourceFieldName) => {
            const resourceFieldValue = fieldValue[resourceFieldName];
            // @ts-ignore
            console.log(
              "resourceFieldName : ",
              resourceFieldName,
              "resourceFieldValue : ",
              resourceFieldValue
            );
            Object.keys(resourceFieldValue).forEach((resourceFieldNameNew) => {
              const resourceFieldValueNew =
                resourceFieldValue[resourceFieldNameNew];
              // @ts-ignore
              console.log(
                "resourceFieldNameNew : ",
                resourceFieldNameNew,
                "resourceFieldValue : ",
                resourceFieldValueNew
              );
              formMethods.setValue(resourceFieldNameNew, resourceFieldValueNew);

              if (resourceFieldNameNew === "country") {
                formMethods.setValue(
                  resourceFieldNameNew,
                  resourceFieldValueNew.name ?? undefined
                );
              }
              if (resourceFieldNameNew === "province") {
                let id = resourceFieldValueNew.id;
                let name = resourceFieldValueNew.name;
                let shortCode = resourceFieldValueNew.shortCode;

                console.log("namenamenmae", name);

                formMethods.setValue(
                  resourceFieldNameNew,
                  `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                  // '{id:1, name: "Alberta",shortCode:"AB"}'
                );
              }
            });
          });
        }
        if (fieldName === "email") {
          if (fieldValue !== null) {
            formMethods.setValue(fieldName, fieldValue);
          } else {
            const maskedStatus: any = sessionStorage.getItem("maskEnabled");
            if (maskedStatus === "true") {
              formMethods.setValue(fieldName, "ze*****3@gmail.com");
            } else {
              formMethods.setValue(fieldName, "");
            }
          }
        }
        if (fieldName === "id") {
          // (async () => {
          //   try {
          //     const responseAssociationId =
          //       await T4SlipService.getRoleAssociationId(fieldValue);

          //     console.log(
          //       "responseData getRoleAssociationId : ",
          //       responseAssociationId.associationId
          //     );
              //for auto selected card
              setSelectedCard(String(fieldValue));
              formMethods.setValue(
                "selectedEmployee",
                fieldValue
              );

              // (async () => {
              //   try {
              //     const responseGetResId =
              //       await T4SlipService.getResourceActualId(
              //         responseAssociationId.associationId
              //       );
              //     console.log(
              //       "responseData////////",
              //       responseGetResId.resourceId
              //     );

                  (async () => {
                    try {
                      const response: any =
                        await T4SlipService.getResourceSlipDropDownList(
                          fieldValue,
                          clientId,
                          taxYear
                        );
                      // console.log(
                      //   "responseData-----------------",
                      //   response[0].value
                      // );
                      // formMethods.setValue("selectedSlip", response[0].value);

                      if (response && Array.isArray(response)) {
                        let resourceSlipList: IDropDownListResponse[] =
                          response.map(
                            (resourceSlip: IDropDownListResponse) => ({
                              label: resourceSlip.label,
                              value: resourceSlip.value,
                            })
                          );

                        resourceSlipList.unshift({
                          label: "Create New",
                          value: "create-new",
                        });

                        console.log("resourceSlipListDDL", resourceSlipList);
                        setSelectedResourceSlipList(resourceSlipList);
                      }
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  })();
                  // You can work with the data here or trigger other functions that depend on the fetched data
          //       } catch (error) {
          //         console.error("Error fetching data:", error);
          //       }
          //     })();
          //   } catch (error) {
          //     console.error("Error fetching data:", error);
          //   }
          // })();
          setSelectedResource(fieldValue);
        }
        if (fieldName === "metaFields") {
          fieldValue.forEach((obj: any) => {
            formMethods.setValue(obj.name, obj.value);
            if (obj.name === "initial" || obj.name === "INITIALS") {
              formMethods.setValue("initials", obj.value);
            }
            if (obj.name === "employee_code") {
              formMethods.setValue("employeeCode", obj.value);
            }
            if (obj.name === "pay_period") {
              formMethods.setValue("payPeriod", obj.value);
              formMethods.setValue("pensionablePeriod", obj.value);
            }
            if (obj.name === "province_of_employment") {
              getPROVINCE_OF_EMPLOYMENT(obj.value);
              console.log("PROVINCE_OF_EMPLOYMENT", PROVINCE_OF_EMPLOYMENT);
              if (PROVINCE_OF_EMPLOYMENT !== "") {
                formMethods.setValue(
                  "provinceOfEmployment",
                  PROVINCE_OF_EMPLOYMENT
                );
              }
              console.log("obj.value123", obj.value);
              if (obj.value === 11) {
                setShowbox17IfQC(true);
                setSelectedProvinceOfEmployment("Quebec");
              } else {
                setShowbox17IfQC(false);
                setSelectedProvinceOfEmployment(obj.name);
                console.log("sjhksbvksbvbsvkbskvvk", obj.value);
                console.log(
                  "sjhksbvksbvbsvkbskvvk",
                  selectedProvinceOfEmployment
                );
              }
            }
            if (obj.name === "reduced_ei") {
              if (obj.value === false) {
                formMethods.setValue("reducedEi", 0);
              } else if (obj.value === true) {
                formMethods.setValue("reducedEi", 1);
              }
            }
            if (obj.name === "date_of_birth") {
              if (obj.value !== "") {
                formMethods.setValue("dateOfBirth", obj.value);
                if (obj.value.charAt(2) === "*") {
                  formMethods.setValue("dateOfBirth", obj.value);
                  setDateOfBirth(obj.value);
                } else {
                  const defaultDate = new Date(obj.value);
                  formMethods.setValue("dateOfBirth", defaultDate);
                  setDateOfBirth(formatDate(defaultDate as Date));
                }
                setDateOfBirthPlaceHolder(obj.value);
              } else {
                const maskedStatus: any = sessionStorage.getItem("maskEnabled");
                console.log("elseeeeeee", maskedStatus);
                if (maskedStatus === "true") {
                  setDateOfBirthPlaceHolder("0***"); //this would disable dob while value is null in upadte
                  console.log("elseeeeeee12", dateOfBirthPlaceHolder);
                }
              }
            }
            if (obj.name === "cpp_qpp_exempt") {
              formMethods.setValue("cppQppExempt", obj.value);
            }
            if (obj.name === "ppip_exempt") {
              formMethods.setValue("ppipExempt", obj.value);
            }
            if (obj.name === "ei_exempt") {
              formMethods.setValue("eiExempt", obj.value);
            }
            if (obj.name === "employment_code") {
              formMethods.setValue("employmentCode", obj.value);
            }
            console.log(
              `"objobj" : ${obj.name} ====== objValue : ${obj.value}`
            );
          });
        }
        if (fieldName === "uniqueIdentifier") {
          const formattedNumber = fieldValue.replace(
            /(\d{3})(\d{3})(\d{3})/,
            "$1-$2-$3"
          );
          formMethods.setValue("uniqueIdentifier", formattedNumber);
        }
      });
      formMethods.setValue("selectedSlip", "create-new");
      formMethods.setValue("applyAdjustments", false);
      formMethods.setValue("employmentIncome", "0.00");
      formMethods.setValue("cppContributions", "0.00");
      formMethods.setValue("secondCppContributions", "0.00");
      formMethods.setValue("qppContributions", "0.00");
      formMethods.setValue("secondQppContributions", "0.00");
      formMethods.setValue("eiPremiums", "0.00");
      formMethods.setValue("rppContributions", "0.00");
      formMethods.setValue("incomeTaxDeducted", "0.00");
      formMethods.setValue("eiInsurableEarnings", "0.00");
      formMethods.setValue("cppQppPensionableEarnings", "0.00");
      formMethods.setValue("unionDues", "0.00");
      formMethods.setValue("charitableDonations", "0.00");
      formMethods.setValue("registrationNumber", undefined);
      formMethods.setValue("pensionAdjustment", "0.00");
      formMethods.setValue("ppipPremiums", "0.00");
      formMethods.setValue("ppipInsurableEarnings", "0.00");
      formMethods.setValue("employmentIncomeAdjusted", "0.00");
      formMethods.setValue("cppContributionsAdjusted", "0.00");
      formMethods.setValue("secondCppContributionsAdjusted", "0.00");
      formMethods.setValue("eiPremiumsAdjusted", "0.00");
      formMethods.setValue("incomeTaxDeductedAdjusted", "0.00");
      formMethods.setValue("eiInsurableEarningsAdjusted", "0.00");
      formMethods.setValue("ppipPremiumsAdjusted", "0.00");
      formMethods.setValue("ppipInsurableEarningsAdjusted", 0);
      formMethods.setValue("slipStatus", 200);
      formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
      setSlipOtherInformation([])
      console.log("sjhksbvksbvbsvkbskvvkend", selectedProvinceOfEmployment);
    }
  }, [selectedNotStartedResourceData, formMethods.setValue, formMethods, onResourceSelectionAPIsCalls]);

  // apply Adjustment
  // console.log("applyAdjustmentapplyAdjustment", adjustment);
  const [adjustedDataResponse, setAdjustedDataResponse] = useState(undefined);
  const onSelectionChange = async (e: any) => {
    // alert(e);
    // setAdjustment(e);

    const jsonString = formMethods.getValues("provinceOfEmployment")
      ? formMethods.getValues("provinceOfEmployment")
      : ('{id:9, name: "Ontario",shortCode:"ON"}' as string);

    // Custom parsing function to convert the JSON-like string to an object
    const parseJSONLikeString = (str: string) => {
      // Use regular expressions to add double quotes around keys and string values
      const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');

      // Convert the modified JSON string to an object using JSON.parse()
      return JSON.parse(modifiedString);
    };

    // Convert the JSON-like string to an object
    const myObject = parseJSONLikeString(jsonString);

    console.log("myObject", myObject);

    const payload = {
      clientId: clientId,
      taxYear: taxYear,
      slipId:
        selectedT4SlipsData && selectedT4SlipsData.resource
          ? selectedT4SlipsData.id
            : T4SlipRecord && T4SlipRecord.id ? T4SlipRecord.id 
            : undefined,
      applyAdjustments: true,
      saveAdjutments: false,
      pensionablePeriod: parseInt(formMethods.getValues("pensionablePeriod")),
      slipType: formMethods.getValues("slipType"),
      resource: {
        externalId:
          selectedT4SlipsData && selectedT4SlipsData.resource
            ? selectedT4SlipsData.resource.externalId
            : selectedNotStartedResourceData &&
              selectedNotStartedResourceData.id
            ? selectedNotStartedResourceData.id
            : undefined,
          dateOfBirth:
            dateOfBirth.charAt(2) === "*"
              ? undefined
              : 
              dateOfBirth !== "" || null
              ? dateOfBirth
              : undefined,
        // provinceOfEmployment:
        //   formMethods.getValues("provinceOfEmployment") !== undefined
        //     ? formMethods.getValues("provinceOfEmployment")
        //     : { id: 9, name: "Ontario", shortCode: "ON" },
        provinceOfEmployment: myObject,
        cppQppExempt: formMethods.getValues("cppQppExempt"),
        eiExempt: formMethods.getValues("eiExempt"),
        ppipExempt: formMethods.getValues("ppipExempt"),
        payPeriod: formMethods.getValues("payPeriod"),
      },
      slipData: {
        employmentIncome: Number(formMethods.getValues("employmentIncome").replace(/[$,]/g, '')),
        cppContributions: formMethods.getValues("cppContributions") && formMethods.getValues("cppContributions") !== null ? parseFloat(formMethods.getValues("cppContributions").replace(/[$,]/g, '')) : 0,
        secondCppContributions: taxYear > 2023 && formMethods.getValues("secondCppContributions") && formMethods.getValues("secondCppContributions") !== null
        ? parseFloat(formMethods.getValues("secondCppContributions").replace(/[$,]/g, '')) : 0,
        qppContributions: formMethods.getValues("qppContributions") && formMethods.getValues("qppContributions") !== null ? parseFloat(formMethods.getValues("qppContributions").replace(/[$,]/g, '')) : 0,
        secondQppContributions: taxYear > 2023 && formMethods.getValues("secondQppContributions") && formMethods.getValues("secondQppContributions") !== null
        ? parseFloat(formMethods.getValues("secondQppContributions").replace(/[$,]/g, '')) : 0,
        eiPremiums: formMethods.getValues("eiPremiums") && formMethods.getValues("eiPremiums") !== null ? parseFloat(formMethods.getValues("eiPremiums").replace(/[$,]/g, '')) : 0,
        // rppContributions:parseFloat(formMethods.getValues('rppContributions')),
        incomeTaxDeducted: formMethods.getValues("incomeTaxDeducted") && formMethods.getValues("incomeTaxDeducted") !== null ? parseFloat(
          formMethods.getValues("incomeTaxDeducted").replace(/[$,]/g, '')
        ) : 0,
        eiInsurableEarnings: formMethods.getValues("eiInsurableEarnings") && formMethods.getValues("eiInsurableEarnings") !== null ? parseFloat(
          formMethods.getValues("eiInsurableEarnings").replace(/[$,]/g, '')
        ) : 0,
        cppQppPensionableEarnings: formMethods.getValues("cppQppPensionableEarnings") && formMethods.getValues("cppQppPensionableEarnings") !== null ? parseFloat(
          formMethods.getValues("cppQppPensionableEarnings").replace(/[$,]/g, '')
        ) : 0,
        // unionDues:parseFloat(formMethods.getValues('unionDues')),
        // charitableDonations:parseFloat(formMethods.getValues('charitableDonations')),
        // registrationNumber:parseFloat(formMethods.getValues('registrationNumber')),
        // pensionAdjustment:parseFloat(formMethods.getValues('pensionAdjustment')),
        ppipPremiums: formMethods.getValues("ppipPremiums") && formMethods.getValues("ppipPremiums") !== null ? parseFloat(formMethods.getValues("ppipPremiums").replace(/[$,]/g, '')) : 0,
        ppipInsurableEarnings: formMethods.getValues("ppipInsurableEarnings") && formMethods.getValues("ppipInsurableEarnings") !== null ? parseFloat(
          formMethods.getValues("ppipInsurableEarnings").replace(/[$,]/g, '')
        ) : 0,
      },
      adjustedSlipData: {
        // employmentIncomeAdjusted: parseFloat("2.22"),
        // cppContributionsAdjusted: parseFloat("2.22"),
        // qppContributions: parseFloat("0.00"),
        // eiPremiumsAdjusted: parseFloat("2.22"),
        // incomeTaxDeductedAdjusted: parseFloat("2.22"),
        // eiInsurableEarningsAdjusted: parseFloat("2.22"),
        // ppipPremiumsAdjusted: parseFloat("2.22"),
        // ppipInsurableEarningsAdjusted: parseFloat("2.22"),
      },
    };

    if (e === true) {
      try {
        setIsLoadingAdjustedData(true);
        // setTimeout(() => {
        //   setIsLoadingAdjustedData(false);
        // }, 3000);
        const response = await T4SlipService.getAdjustedData(payload);
        // response["adjustedSlipData"]["employmentIncomeAdjusted"] =
        //   response["adjustedSlipData"]["employmentIncome"];
        // response["adjustedSlipData"]["cppContributionsAdjusted"] =
        //   response["adjustedSlipData"]["cppContributions"];
        // response["adjustedSlipData"]["qppContributionsAdjusted"] =
        //   response["adjustedSlipData"]["qppContributions"];
        // response["adjustedSlipData"]["eiPremiumsAdjusted"] =
        //   response["adjustedSlipData"]["eiPremiums"];
        // response["adjustedSlipData"]["incomeTaxDeductedAdjusted"] =
        //   response["adjustedSlipData"]["incomeTaxDeducted"];
        // response["adjustedSlipData"]["eiInsurableEarningsAdjusted"] =
        //   response["adjustedSlipData"]["eiInsurableEarnings"];
        // response["adjustedSlipData"]["ppipPremiumsAdjusted"] =
        //   response["adjustedSlipData"]["ppipPremiums"];
        // response["adjustedSlipData"]["ppipInsurableEarningsAdjusted"] =
        //   response["adjustedSlipData"]["ppipInsurableEarnings"];
        // response["adjustedSlipData"]["employmentIncomeAdjusted"] =
        //   response["adjustedSlipData"]["employmentIncome"];

        // delete response["adjustedSlipData"]["employmentIncome"];
        // delete response["adjustedSlipData"]["cppContributions"];
        // delete response["adjustedSlipData"]["qppContributions"];
        // delete response["adjustedSlipData"]["eiPremiums"];
        // delete response["adjustedSlipData"]["incomeTaxDeducted"];
        // delete response["adjustedSlipData"]["eiInsurableEarnings"];
        // delete response["adjustedSlipData"]["ppipPremiums"];
        // delete response["adjustedSlipData"]["ppipInsurableEarnings"];
        // delete response["adjustedSlipData"]["employmentIncome"];
        console.log("payload123", payload);
        console.log("responseData123", response);
        if (response) {
          Object.keys(response).forEach((fieldName) => {
            // @ts-ignore
            const fieldValue = response[fieldName];
            if (fieldName === "adjustedSlipData") {
              Object.keys(fieldValue).forEach((adjustedSlipDataFieldName) => {
                const adjustedSlipDataFieldValue =
                  fieldValue[adjustedSlipDataFieldName];
                // @ts-ignore
                formMethods.setValue(
                  adjustedSlipDataFieldName,
                  parseFloat(adjustedSlipDataFieldValue).toFixed(2)
                );
                console.log(
                  "adjustedSlipDataFieldName : ",
                  adjustedSlipDataFieldName,
                  "adjustedSlipDataFieldValue : ",
                  adjustedSlipDataFieldValue
                );
              });
            }
          });
        }
        // setAdjustedDataResponse(response);
      } catch (err) {
        console.log("err", err);
        // alert(err)
      } finally {
        setIsLoadingAdjustedData(false);
      }
    }
    if (e === false) {
      formMethods.setValue("employmentIncomeAdjusted", "0.00");
      formMethods.setValue("cppContributionsAdjusted", "0.00");
      formMethods.setValue("secondCppContributionsAdjusted", "0.00");
      formMethods.setValue("qppContributionsAdjusted", "0.00");
      formMethods.setValue("secondQppContributionsAdjusted", "0.00");
      formMethods.setValue("eiPremiumsAdjusted", "0.00");
      formMethods.setValue("incomeTaxDeductedAdjusted", "0.00");
      formMethods.setValue("eiInsurableEarningsAdjusted", "0.00");
      formMethods.setValue("ppipPremiumsAdjusted", "0.00");
      formMethods.setValue("ppipInsurableEarningsAdjusted", "0.00");
    }
  };

  // useEffect(() => {
  // if the selected flex field data has loaded.
  // if (adjustedDataResponse) {
  //   console.log("adjustedDataResponse",adjustedDataResponse)
  // setIsLoadingAdjustedData(true);
  //   setTimeout(() => {
  //     setIsLoadingAdjustedData(false);
  //   }, 1000);
  // Object.keys(adjustedDataResponse).forEach((fieldName) => {
  // @ts-ignore
  // const fieldValue = adjustedDataResponse[fieldName];
  // if (fieldName === "adjustedSlipData") {
  //   Object.keys(fieldValue).forEach((adjustedSlipDataFieldName) => {
  //     const adjustedSlipDataFieldValue =
  //       fieldValue[adjustedSlipDataFieldName];
  //     // @ts-ignore
  //     formMethods.setValue(
  //       adjustedSlipDataFieldName,
  //       parseFloat(adjustedSlipDataFieldValue).toFixed(2)
  //     );
  //     console.log(
  //       "adjustedSlipDataFieldName : ",
  //       adjustedSlipDataFieldName,
  //       "adjustedSlipDataFieldValue : ",
  //       adjustedSlipDataFieldValue
  //     );
  //   });
  // }
  // if (fieldName === "slipData") {
  //   Object.keys(fieldValue).forEach((slipDataFieldName) => {
  //     const slipDataFieldValue =
  //       fieldValue[slipDataFieldName];
  //     // @ts-ignore
  //     if(slipDataFieldName === "qppContributions"){
  //       formMethods.setValue(
  //         slipDataFieldName,
  //         slipDataFieldValue ? parseFloat(slipDataFieldValue).toFixed(2) : 0
  //       );
  //     }
  //     console.log(
  //       "slipDataFieldName : ",
  //       slipDataFieldName,
  //       "slipDataFieldValue : ",
  //       slipDataFieldValue
  //     );
  //   });
  // }
  //     });
  //   }
  // }, [adjustedDataResponse, formMethods.setValue, formMethods]);

  // const useGetT4OtherInformationList = () => {
  //   return useQuery('get-t4-itherinformation-list', () => T4OtherInformationService.getT4OI(), {
  //     ...QUERY_OPTIONS,
  //   });
  // };
  // const { isLoading: isLoadingT4OtherInformationList, data: T4OtherInformationList } =
  // useGetT4OtherInformationList();
  // const onProvinceSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
  //   setSelectedProvince({
  //     id: item.id,
  //     name: item.label,
  //     shortCode: String(item.value),
  //   });
  // };
  // const { register } = formMethods; // Get the register function from useForm



  useEffect(() => {
    //Load EMployee DDL
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 2000);
    const fetchData = async () => {
      try {
        const response: any = await T4SlipService.getEmployeeDDL(
          clientId,
          EMPLOYEE_RESOURCE_LITERAL,
          taxYear
        );
        console.log("responseData-----------------", response);

        if (response && Array.isArray(response)) {
          let resources: IDropDownListResponse[] = response.map(
            (resourceItem: IDropDownListResponse) => ({
              // id: resourceItem.id,
              label: resourceItem.label,
              value: resourceItem.value,
            })
          );

          resources.unshift({
            label: "Create New",
            value: "create-new",
          });

          setSelectedResourceList(resources);
        }
        console.log("pooooooooooooooo",sortData(response))
        setResourceListWithoutCreateNew(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // when  I formMethods.setValue of otherinformation inside async calls method then facing issue while click on add more so we have to use state method to show otherinformation 
  useEffect(()=>{
    console.log("slipOtherInformation outside",slipOtherInformation)
    if (
      slipOtherInformation &&
      slipOtherInformation !== null &&
      slipOtherInformation.length !== 0
    ) {
    console.log("slipOtherInformation",slipOtherInformation)
      setOiInEdit(true);
      formMethods.setValue(
        "comparison",
        slipOtherInformation
      );
    } else if (!id) {
      // console.log(
      //   "slipOtherInformation1234ee else",
      //   slipOtherInformation
      // );
      // setOiInEdit(true);
      // formMethods.setValue(
      //   "comparison",
      //   [
      //     {
      //       amount: 0,
      //       code: "",
      //       label: "",
      //     },
      //   ]
      // );
      defaultSettingsCalls()
    }
  },[slipOtherInformation])

  const onSlipTypeSelectionChangeHandler = (e: any) => {
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 1000);
    const selectedType = e.value;
    selectedSlipTypee = selectedType;
    // alert(selectedType);
  };

  const onCloseHandler = () => {
    setDialogOpen(false);
    setInvoiceDialogOpen(false);
  };

  console.log("selectedT4SlipsData.id", selectedT4SlipsData?.id);
  // console.log("slipRecord.id",slipRecord?.id)
  // console.log("newCreatedSlipId",newCreatedSlipId)
  // console.log("editedRecordSlipCreateNew",editedRecordSlipCreateNew)
  console.log("selectedResource", selectedResource);
  console.log(
    "selectedT4SlipsData.resource.externalId",
    selectedT4SlipsData?.resource?.externalId
  );
  // console.log("newCreatedResourceId",newCreatedResourceId)
  console.log(
    "selectedNotStartedResourceData.id",
    selectedNotStartedResourceData?.id
  );
  // console.log("selectedResource",selectedResource)
  console.log("T4SlipRecord", T4SlipRecord);
  console.log("FilteredSelectedOtherInfoT4", FilteredSelectedOtherInfoT4);
  console.log("selectedProvinceOfEmployment", selectedProvinceOfEmployment);
  console.log("selectedResourceSlip--", selectedResourceSlip);
  console.log("dateOfBirthPlaceHolder--", dateOfBirthPlaceHolder);
  console.log("selectedResourceList--", selectedResourceList.slice(1));
  console.log("resourceListWithoutCreateNew--", resourceListWithoutCreateNew);
  console.log("selectedCard--", selectedCard);

  const getCardStyles = (index: any) => {
    console.log("index--", index);
    return {
      borderLeft:
        selectedCard === index ? "6px solid #0057db" : "2px solid transparent",
      cursor: "pointer",
    };
  };
  const getSelectedResourceSlipInCard = (index: any) => {
    let selectedItem =
      selectedCard === index ? selectedResourceSlip : "create-new";
    return selectedItem;
  };
  const getSelectedResourceSlipsListInCard = (index: any) => {
    let selectedList =
      selectedCard === index
        ? selectedResourceSlipList
        : [{ label: "Create New", value: "create-new" }];
    return selectedList;
  };

  const filteredResourceList =
    resourceListWithoutCreateNew &&
    resourceListWithoutCreateNew.filter((item: any) =>
      item.label.toLowerCase().includes(itemSearch.toLowerCase())
    );

  const onCreateNewButtonClicked = () => {
    // setSnackbarData({
    //   open: true,
    //   message: "Please load an employee for T4 Slip",
    //   severity: "error",
    // });
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    formMethods.reset();
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    setSlipOtherInformation([])
    formMethods.setValue("employmentIncome", '0.00');
    setDateOfBirth("");
    setDateOfBirthPlaceHolder("");
    // setNewCreatedResourceId('')
    // setNewCreatedSlipId('')
    setSelectedResourceSlipList([{ label: "Create New", value: "create-new" }]);
    setSelectedResourceSlip("create-new");

    //otherinformation will update selected items
    setSelectedOtherInformation(false);
    // if(id !== undefined){
    //   window.location.href = "/taxslip/t4/setup";
    // }
    let path = `/taxslip/t4/setup`;
    navigate(path); 
    setT4SlipRecord(undefined);
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 1000);
    //otherinformation will update selected items
    setSelectedOtherInformation(false);
    setSelectedResource("create-new");
    setSelectedCard(null);
    defaultSettingsCalls()

    //because of issue in slipdata values not clear issue
    window.location.reload()
  };
  const onDividerButtonClickHandler = () => {
    setSidePanelsVisibility((prevButtonState: any) => !prevButtonState);
  };

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: id ? id : "",
        entityId2: "",
        entityId3: "",
        screenName: T4_SETUP_SCREEN_SLUG,
        companyName: clientName,
        activityType: "activity_type=create&activity_type=updated&activity_type=delete&activity_type=print",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          pb: "5rem",
          "& .MuiAppBar-root": { zIndex: 2, top: "65px" },
          "& .p-datepicker": { zIndex: 2 },
          paddingTop: "2rem",
        }}
      >
        <BackdropCircularProgress
          open={
            // isLoadingDropDownList ||
            // isLoadingResourceSlips ||
            // isLoadingSlipData ||
            isSubmitting ||
            isLoadingData ||
            // isLoadingResourceInfo ||
            // reFetchingSlipData ||
            isLoadingAdjustedData ||
            // isLoadingProvinceDropDownList ||
            isLoadingSlipListDDL ||
            isLoadingNotStartedSlipId ||
            isDeleting ||
            isLoadingNotStartedSlipId ||
            isLoadingDataa ||
            isLoadingGlobalVariables ||
            isLoadingActivityLogData || 
            isLoadingDefaultSettingsData
            // isLoadingT4OtherInformationList
          }
          testId={testId}
        />
        <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={deleteT4SlipField}
          cancelActionHandler={onCloseHandler}
          testId="T4-confirmation-dialog"
        />
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          {sidePanelsVisibility && (
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Box sx={{ margin: "0" }}>
                <InputText
                  value={itemSearch}
                  onChange={(e) => setItemSearch(e.target.value)}
                  placeholder={"Search by name..."}
                  className="w-full search-field"
                />
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    sx={{ right: 0, margin: "10px 0px", fontSize: "12px" }}
                    onClick={onCreateNewButtonClicked}
                  >
                    + Create New
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  // display: "flex",
                  height: "95rem",
                  // flexDirection: "column",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#ccc",
                    borderRadius: "3px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#aaa",
                  },
                }}
              >
                {resourceListWithoutCreateNew &&
                  filteredResourceList.map((item: any, index: any) => {
                    return (
                      <Card
                        sx={{
                          padding: 0,
                          borderRadius: "8px",
                          marginBottom: "1rem",
                        }}
                        key={index}
                        onClick={() =>
                          onResourceSelectionChangeHandler(item, item.value)
                        }
                        style={getCardStyles(item.value)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0.8rem",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: ".875rem" }}
                          >
                            {item.label}
                          </Typography>
                          <Avatar
                            shape="circle"
                            style={{
                              background: "#0057db",
                              color: "#fff",
                              borderRadius: "50%",
                              fontWeight: "bold",
                              fontSize: ".775rem",
                              padding: "2px",
                            }}
                          >
                            {item.label
                              .replace(/\s+/g, " ")
                              .split(" ")
                              .filter((word: string) => word.trim() !== "")
                              .map((word: string) => word[0].toUpperCase())
                              .slice(0, 2)
                              .join("")}
                          </Avatar>
                        </Box>
                        {selectedCard === item.value ? (
                          <>
                            <Divider className="m-0" />
                            <Box>
                              <Box sx={{ padding: "0.6rem" }}>
                                <Dropdown
                                  value={getSelectedResourceSlipInCard(
                                    item.value
                                  )}
                                  onChange={
                                    onResourceSlipSelectionChangeHandler
                                  }
                                  options={getSelectedResourceSlipsListInCard(
                                    item.value
                                  )}
                                  onClick={(e: any) => e.stopPropagation()}
                                  className="slip-dropdown"
                                />
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <></>
                        )}
                      </Card>
                    );
                  })}
              </Box>
            </Grid>
          )}
          <Divider
            layout="vertical"
            align="center"
            className="verticle-divider"
            style={{ zIndex: 0 }}
          >
            <Button
              variant="outlined"
              sx={{
                minWidth: "fit-content",
                padding: "20px 0",
                borderRadius: "7px",
              }}
              onClick={onDividerButtonClickHandler}
            >
              {sidePanelsVisibility ? (
                <i
                  className="pi pi-angle-double-left"
                  style={{ color: "#0057db" }}
                ></i>
              ) : (
                <i
                  className="pi pi-angle-double-right"
                  style={{ color: "#0057db" }}
                ></i>
              )}
            </Button>
          </Divider>
          <Grid
            item
            xs={sidePanelsVisibility ? 8 : 10}
            sm={sidePanelsVisibility ? 8 : 10.8}
            md={sidePanelsVisibility ? 9.4 : 11.2}
            lg={sidePanelsVisibility ? 9.5 : 11.6}
            xl={sidePanelsVisibility ? 9.7 : 11.7}
            sx={{ "& .MuiGrid-container": { marginTop: 0 } }}
          >
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <TwoColumnLayout
                  toolbar={
                    <T4SlipSetupToolbar
                      onResetClicked={onResetClicked}
                      onDeleteClicked={onDeleteClicked}
                      onPrintClicked={onPrintClicked}
                      isSubmitting={isSubmitting}
                      formMethods={formMethods}
                    />
                  }
                  leftColumnContent={
                    <>
                      {!sidePanelsVisibility && (
                        <StandardPanel
                          title="Load Slips"
                          testId={`${testId}-load-slips-panel`}
                        >
                          <Grid
                            container
                            columnSpacing={2}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <EnhancedDropDownRHFWithLabel
                                label="Load Employee"
                                selectName="selectedEmployee"
                                items={selectedResourceList}
                                selectedItem={selectedResource}
                                testId="selected-employee"
                                onSelectionChangeHandler={
                                  onResourceSelectionChangeHandler
                                }
                                // isLoading={isLoadingFlexFieldsList}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <EnhancedDropDownRHFWithLabel
                                label="Load T4 Slip"
                                selectName="selectedSlip"
                                items={selectedResourceSlipList}
                                selectedItem={selectedResourceSlip}
                                testId="selected-slip"
                                onSelectionChangeHandler={
                                  onResourceSlipSelectionChangeHandler
                                }
                                // isLoading={isLoadingFlexFieldsList}
                              />
                            </Grid>
                          </Grid>
                        </StandardPanel>
                      )}
                      <StandardPanel
                        title="Slip Data"
                        testId={`${testId}-slip-data`}
                        topPadding={!sidePanelsVisibility ? 2 : 0}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <EnhancedIOSSwitchRHFWithLabel
                            name="applyAdjustments"
                            checked={false}
                            testId="applyAdjustments"
                            label="Apply Adjustments"
                            labelPosition={LabelPosition.Left}
                            labelColumns={6}
                            fieldColumns={6}
                            onSelectionChangeHandler={onSelectionChange}
                          />
                        </Grid>
                        {T4OtherInformationList && (
                          <T4SlipDataPanel
                            formMethods={formMethods}
                            isLoading={false}
                            otherInformationList={T4OtherInformationList}
                            testId={testId}
                            selectedOtherInformation={selectedOtherInformation}
                            oiInEdit={oiInEdit}
                            selectedProvinceOfEmployment={
                              selectedProvinceOfEmployment
                            }
                            globalVariablesData={
                              globalVariablesData && globalVariablesData
                            }
                          />
                        )}
                      </StandardPanel>
                    </>
                  }
                  rightColumnContent={
                    <>
                      <StandardPanel
                        title="Employee Info"
                        testId={`${testId}-employee-info`}
                      >
                        <EmployeeInfoSetupFieldsPanel
                          onDateOfBirthChangeHandler={
                            onDateOfBirthChangeHandler
                          }
                          testId={testId}
                        />
                        <FieldWrapper>
                          <FieldLayout
                            label="Date of Birth"
                            // isRequired={isRequired}
                            testId={testId}
                            labelPosition={LabelPosition.Left}
                            labelColumns={4}
                            fieldColumns={8}
                          >
                            <Calendar
                              id="dateOfBirth"
                              {...formMethods.register("dateOfBirth")} // Register the input with react-hook-form using methods.register
                              value={formMethods.watch("dateOfBirth") || null} // Set the value using watch to reflect changes
                              showIcon
                              // dateFormat="dd/mm/yy" // Customize the date format as needed
                              placeholder={
                                dateOfBirthPlaceHolder !== "" ||
                                dateOfBirthPlaceHolder.charAt(2) === "*"
                                  ? "Unmask to view / edit"
                                  : "dd/mm/yyyy"
                              }
                              disabled={
                                dateOfBirthPlaceHolder !== "" &&
                                dateOfBirthPlaceHolder.charAt(2) === "*"
                              } //if unmask then it is enabled
                              // className="w-full"
                              style={{ height: "2.6rem" }}
                              onChange={onDateOfBirthChangeHandler}
                              dateFormat="M dd, yy"
                              required={defaultSettingsData && !defaultSettingsData.message && defaultSettingsData.fillingDataAttribute.employeeDobIsRequired}
                              // invalid={true}
                              className={`w-full ${defaultSettingsData && !defaultSettingsData.message && defaultSettingsData.fillingDataAttribute.employeeDobIsRequired && dateOfBirth === '' ? 'p-invalid' : ''}`}
                            />
                            {defaultSettingsData && !defaultSettingsData.message && defaultSettingsData.fillingDataAttribute.employeeDobIsRequired && dateOfBirth === '' ? <small className="p-error" style={{paddingLeft:"1rem"}}>Date is required.</small> : <></>}                          
                          </FieldLayout>
                        </FieldWrapper>
                      </StandardPanel>
                      <StandardPanel
                        title="Address"
                        testId={`${testId}-employee-address-panel`}
                        topPadding={2}
                      >
                        <EmployeeAddressSetupFieldsPanel testId={testId} />
                        {/* <EnhancedDropDownRHFWithLabel
                        selectName="province"
                        label="Province"
                        // selectedItem={selectedProvince.id !== null && selectedProvince}
                        labelPosition={LabelPosition.Left}
                        required
                        testId={`${testId}-province-list`}
                        items={provinceList ?? []}
                        onSelectionChangeHandler={onProvinceSelectionChangeHandler}
                      /> */}
                      </StandardPanel>
                      <StandardPanel
                        title="Filing Data"
                        testId={`${testId}-filing-data-panel`}
                        topPadding={2}
                      >
                        <EnhancedDropDownRHFWithLabel
                          label="Slip Type"
                          selectName="slipType"
                          items={[
                            { label: "Original", value: 400 },
                            { label: "Amended", value: 200 },
                            { label: "Cancelled", value: 300 },
                            { label: "Additional", value: 100 },
                          ]}
                          selectedItem={400}
                          testId="slip-type"
                          labelPosition={LabelPosition.Left}
                          required
                          onSelectionChangeHandler={
                            onSlipTypeSelectionChangeHandler
                          }
                        />
                        <FilingDataSetupFieldsPanel
                          formMethods={formMethods}
                          onPOEchange={(value) =>
                            setSelectedProvinceOfEmployment(value)
                          }
                          showbox17IfQC={showbox17IfQC}
                          globalVariablesData={
                            globalVariablesData && globalVariablesData
                          }
                        />
                      </StandardPanel>
                    </>
                  }
                  testId="t4-slip-setup-form"
                />
              </form>
            </FormProvider>
          </Grid>
        </Grid>
        {/* invoice dialogu open if any transaction is unPaid */}
        <EnhancedDialog
          dialogTitle="Invoice(s) Due"
          key="dialog-box-1"
          testId="dialog"
          onCloseHandler={onCloseHandler}
          open={openInvoiceDialog}
          dialogContent={
            <InvoiceDialogPanel
              invoiceData={invoiceData}
              onUnPaidClickHandler={onUnPaidClickHandler}
              onClickInvoiceNoHandler={onClickInvoiceNoHandler}
            />
          }
          dialogAction={
            <Grid>
              <Button autoFocus variant="contained" onClick={onCloseHandler}>
                Cancel
              </Button>
            </Grid>
          }
        />
        <EnhancedSnackbar
          message={snackbarData.message}
          onCloseHandler={onSnackbarClosed}
          severity={snackbarData.severity}
          testId={`${testId}-snackbar`}
          open={snackbarData.open}
          autoHide={snackbarData.autoHide}
        />
      </Grid>
    </>
  );
};
