import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IT4SummaryAxisPages1 {
    taxYear:number
}
const T4SummaryAxisPages1:React.FC<IT4SummaryAxisPages1> = ({taxYear}) => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearX'} 
                testId={'taxYearX'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={200}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAccountX'} 
                testId={'employerAccountX'} 
                label={'Employer Account'} 
                labelPosition={LabelPosition.Left}
                defaultValue={295}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerNameX'} 
                testId={'employerNameX'} 
                label={'Employer Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={280}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAddressX'} 
                testId={'employerAddressX'} 
                label={'Employer Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={280}
            />
            <RHookFormTextFieldWithLabel 
                id={'box88X'} 
                testId={'box88X'} 
                label={'Box 88'} 
                labelPosition={LabelPosition.Left}
                defaultValue={75}
            />
            <RHookFormTextFieldWithLabel 
                id={'box14X'} 
                testId={'box14X'} 
                label={'Box 14'} 
                labelPosition={LabelPosition.Left}
                defaultValue={185}
            />
            <RHookFormTextFieldWithLabel 
                id={'box20X'} 
                testId={'box20X'} 
                label={'Box 20'} 
                labelPosition={LabelPosition.Left}
                defaultValue={185}
            />
            <RHookFormTextFieldWithLabel 
                id={'box52X'} 
                testId={'box52X'} 
                label={'Box 52'} 
                labelPosition={LabelPosition.Left}
                defaultValue={185}
            />
            <RHookFormTextFieldWithLabel 
                id={'box16X'} 
                testId={'box16X'} 
                label={'Box 16'} 
                labelPosition={LabelPosition.Left}
                defaultValue={388}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'box16aX'} 
                testId={'box16aX'} 
                label={'Box 16A'} 
                labelPosition={LabelPosition.Left}
                defaultValue={388}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'box27X'} 
                testId={'box27X'} 
                label={'Box 27'} 
                labelPosition={LabelPosition.Left}
                defaultValue={388}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'box27aX'} 
                testId={'box27aX'} 
                label={'Box 27A'} 
                labelPosition={LabelPosition.Left}
                defaultValue={388}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'box18X'} 
                testId={'box18X'} 
                label={'Box 18'} 
                labelPosition={LabelPosition.Left}
                defaultValue={388}
            />
            <RHookFormTextFieldWithLabel 
                id={'box19X'} 
                testId={'box19X'} 
                label={'Box 19'} 
                labelPosition={LabelPosition.Left}
                defaultValue={388}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22X'} 
                testId={'box22X'} 
                label={'Box 22'} 
                labelPosition={LabelPosition.Left}
                defaultValue={388}
            />
            <RHookFormTextFieldWithLabel 
                id={'box80X'} 
                testId={'box80X'} 
                label={'Box 80'} 
                labelPosition={LabelPosition.Left}
                defaultValue={388}
            />
            <RHookFormTextFieldWithLabel 
                id={'box82X'} 
                testId={'box82X'} 
                label={'Box 82'} 
                labelPosition={LabelPosition.Left}
                defaultValue={388}
            />
            <RHookFormTextFieldWithLabel 
                id={'box84X'} 
                testId={'box84X'} 
                label={'Box 84'} 
                labelPosition={LabelPosition.Left}
                defaultValue={157}
            />
            <RHookFormTextFieldWithLabel 
                id={'box86X'} 
                testId={'box86X'} 
                label={'Box 86'} 
                labelPosition={LabelPosition.Left}
                defaultValue={316}
            />
            <RHookFormTextFieldWithLabel 
                id={'box74X'} 
                testId={'box74X'} 
                label={'Box 74'} 
                labelPosition={LabelPosition.Left}
                defaultValue={275}
            />
            <RHookFormTextFieldWithLabel 
                id={'box75X'} 
                testId={'box75X'} 
                label={'Box 75'} 
                labelPosition={LabelPosition.Left}
                defaultValue={435}
            />
            <RHookFormTextFieldWithLabel 
                id={'box76X'} 
                testId={'box76X'} 
                label={'Box 76'} 
                labelPosition={LabelPosition.Left}
                defaultValue={80}
            />
            <RHookFormTextFieldWithLabel 
                id={'box78X'} 
                testId={'box78X'} 
                label={'Box 78'} 
                labelPosition={LabelPosition.Left}
                defaultValue={410}
            />
            <RHookFormTextFieldWithLabel 
                id={'box78AreaCodeX'} 
                testId={'box78AreaCodeX'} 
                label={'Box 78 Area Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={340}
            />
            <RHookFormTextFieldWithLabel 
                id={'extentionX'} 
                testId={'extentionX'} 
                label={'Extention'} 
                labelPosition={LabelPosition.Left}
                defaultValue={520}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearY'} 
                testId={'taxYearY'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={738}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAccountY'} 
                testId={'employerAccountY'} 
                label={'Employer Account'} 
                labelPosition={LabelPosition.Left}
                defaultValue={695}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerNameY'} 
                testId={'employerNameY'} 
                label={'Employer Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={660}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAddressY'} 
                testId={'employerAddressY'} 
                label={'Employer Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={640}
            />
            <RHookFormTextFieldWithLabel 
                id={'box88Y'} 
                testId={'box88Y'} 
                label={'Box 88'} 
                labelPosition={LabelPosition.Left}
                defaultValue={570}
            />
            <RHookFormTextFieldWithLabel 
                id={'box14Y'} 
                testId={'box14Y'} 
                label={'Box 14'} 
                labelPosition={LabelPosition.Left}
                defaultValue={535}
            />
            <RHookFormTextFieldWithLabel 
                id={'box20Y'} 
                testId={'box20Y'} 
                label={'Box 20'} 
                labelPosition={LabelPosition.Left}
                defaultValue={500}
            />
            <RHookFormTextFieldWithLabel 
                id={'box52Y'} 
                testId={'box52Y'} 
                label={'Box 52'} 
                labelPosition={LabelPosition.Left}
                defaultValue={465}
            />
            <RHookFormTextFieldWithLabel 
                id={'box16Y'} 
                testId={'box16Y'} 
                label={'Box 16'} 
                labelPosition={LabelPosition.Left}
                defaultValue={570}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'box16aY'} 
                testId={'box16aY'} 
                label={'Box 16A'} 
                labelPosition={LabelPosition.Left}
                defaultValue={535}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'box27Y'} 
                testId={'box27Y'} 
                label={'Box 27'} 
                labelPosition={LabelPosition.Left}
                defaultValue={500}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'box27aY'} 
                testId={'box27aY'} 
                label={'Box 27A'} 
                labelPosition={LabelPosition.Left}
                defaultValue={470}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'box18Y'} 
                testId={'box18Y'} 
                label={'Box 18'} 
                labelPosition={LabelPosition.Left}
                defaultValue={440}
            />
            <RHookFormTextFieldWithLabel 
                id={'box19Y'} 
                testId={'box19Y'} 
                label={'Box 19'} 
                labelPosition={LabelPosition.Left}
                defaultValue={410}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22Y'} 
                testId={'box22Y'} 
                label={'Box 22'} 
                labelPosition={LabelPosition.Left}
                defaultValue={380}
            />
            <RHookFormTextFieldWithLabel 
                id={'box80Y'} 
                testId={'box80Y'} 
                label={'Box 80'} 
                labelPosition={LabelPosition.Left}
                defaultValue={348}
            />
            <RHookFormTextFieldWithLabel 
                id={'box82Y'} 
                testId={'box82Y'} 
                label={'Box 82'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'box84Y'} 
                testId={'box84Y'} 
                label={'Box 84'} 
                labelPosition={LabelPosition.Left}
                defaultValue={198}
            />
            <RHookFormTextFieldWithLabel 
                id={'box86Y'} 
                testId={'box86Y'} 
                label={'Box 86'} 
                labelPosition={LabelPosition.Left}
                defaultValue={198}
            />
            <RHookFormTextFieldWithLabel 
                id={'box74Y'} 
                testId={'box74Y'} 
                label={'Box 74'} 
                labelPosition={LabelPosition.Left}
                defaultValue={162}
            />
            <RHookFormTextFieldWithLabel 
                id={'box75Y'} 
                testId={'box75Y'} 
                label={'Box 75'} 
                labelPosition={LabelPosition.Left}
                defaultValue={162}
            />
            <RHookFormTextFieldWithLabel 
                id={'box76Y'} 
                testId={'box76Y'} 
                label={'Box 76'} 
                labelPosition={LabelPosition.Left}
                defaultValue={127}
            />
            <RHookFormTextFieldWithLabel 
                id={'box78Y'} 
                testId={'box78Y'} 
                label={'Box 78'} 
                labelPosition={LabelPosition.Left}
                defaultValue={127}
            />
            <RHookFormTextFieldWithLabel 
                id={'box78AreaCodeY'} 
                testId={'box78AreaCodeY'} 
                label={'Box 78 Area Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={127}
            />
            <RHookFormTextFieldWithLabel 
                id={'extentionY'} 
                testId={'extentionY'} 
                label={'Extention'} 
                labelPosition={LabelPosition.Left}
                defaultValue={127}
            />
        </Grid>
    </Grid>
  )
}

export default T4SummaryAxisPages1