import { TaxProfileAssignment } from "components/TaxProfileAssignment";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface ITaxProfileAssociationPage {}

export const TaxProfileAssociationPage: FC<ITaxProfileAssociationPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Billing Profile Association Setup"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<TaxProfileAssignment testId="tax-profile-setup" />}
    />
  );
};
