import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { TaxSlipCategorySetup } from "components/TaxSlipCategory";

export const TaxSlipCategorySetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="TaxSlip Category Setup"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{ text: "TaxSlip Category List", navigateTo: "/taxslip-categories/list" },
      { text: "Create Taxslip Category", navigateTo: '/taxslip-categories/setup' }
      ]}
      mainContents={<TaxSlipCategorySetup testId="data-role-setup-page" />}
    />
  );
};
