import { MergeResource } from "components/Resource/MergeResource";
import { UndoMergeResource } from "components/Resource/UndoMergeResource";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";
import { useParams } from "react-router-dom";

export interface IMergeResourcesPage {}
export const MergeResourcesPage: FC<IMergeResourcesPage> = () => {
  const { resourceName } = useParams();
  console.log("resourceName>>>>> from page", resourceName);
  return (
    <AuthenticatedLayout
      pageTitle={`Merge ${resourceName}`}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: `${resourceName}`, navigateTo: `/resources/${resourceName}` },
        { text: `Merge ${resourceName}`, navigateTo: `/resources/${resourceName}/merge` },
      ]}
      mainContents={<MergeResource />}
    />
  );
};
