import { FC } from "react";

import {
  EmailFieldRHF,
  EnhancedDropDownRHFWithLabel,
  ITest,
  LabelPosition,
  PostCodeRHF,
  RHookFormTextFieldWithLabel,
} from "@websential/cosmic";
import { useQuery } from "react-query";
import { GET_QUERY_KEY, QUERY_OPTIONS } from "common/constants";
import { ProvinceService } from "services/common/ProvinceService";
import { Box } from "@mui/material";

interface IVendorAddressSetupFieldsPanel extends ITest {}

export const VendorAddressSetupFieldsPanel: FC<IVendorAddressSetupFieldsPanel> =
  ({ testId }) => {
    const useGetProvinceDDL = () => {
      return useQuery(GET_QUERY_KEY.PROVINCE_DDL, () => ProvinceService.get(), {
        ...QUERY_OPTIONS,
      });
    };
    const { isLoading: isLoadingProvinceDropDownList, data: provinceList } =
      useGetProvinceDDL();
    return (
      <>
        <RHookFormTextFieldWithLabel
          label="Address"
          id="addressLine1"
          testId={`${testId}-vendor-address`}
          labelPosition={LabelPosition.Left}
          required
        />
        <RHookFormTextFieldWithLabel
          label="City"
          id="city"
          testId={`${testId}-vendor-city`}
          labelPosition={LabelPosition.Left}
          required
        />
        {/* <RHookFormTextFieldWithLabel
          label="Province"
          id="province"
          testId={`${testId}-vendor-province`}
          labelPosition={LabelPosition.Left}
          required
        /> */}
        <EnhancedDropDownRHFWithLabel
          selectName="province"
          label="Province"
          // selectedItem={selectedProvince.id !== null && selectedProvince}
          labelPosition={LabelPosition.Left}
          required
          testId={`${testId}-province-list`}
          items={provinceList ?? []}
          // onSelectionChangeHandler={onProvinceSelectionChangeHandler}
        />
        {/* <EnhancedDropDownRHFWithLabel
          selectName="provinceOfEmployment"
          label="Province of employment"
          labelPosition={LabelPosition.Left}
          required
          testId={`${testId}-province-list`}
          items={provinceList ?? []}
        /> */}
          <PostCodeRHF
          label="Post Code"
          id="postalCode"
          testId={`${testId}-vendor-post-code`}
          labelPosition={LabelPosition.Left}
          labelColumns={4}
          fieldColumns={8}
          required
        />
        <RHookFormTextFieldWithLabel
          label="Country"
          id="country"
          testId={`${testId}-employee-country`}
          labelPosition={LabelPosition.Left}
          value="Canada"
          defaultValue={"Canada"}
          readOnly
          required
        />
        <EmailFieldRHF
          label="Email"
          id="email"
          testId={`${testId}-vendor-email`}
          labelPosition={LabelPosition.Left}
          labelColumns={4}
          fieldColumns={8}
        />
      </>
    );
  };
