import { FC } from "react";

import {
  EnhancedDropDownRHFWithLabel,
  ITest,
  LabelPosition,
} from "@websential/cosmic";

interface IFilingDataSetupFieldsPanel extends ITest {}

export const FilingDataSetupFieldsPanel: FC<IFilingDataSetupFieldsPanel> = ({
  testId,
}) => {
  return (
    <>
      {/* <EnhancedDropDownRHFWithLabel
        label="Slip Type"
        selectName="slipType"
        items={[
          { label: "Original", value: 400 },
          { label: "Amended", value: 200 },
          { label: "Cancelled", value: 300 },
          { label: "Additional", value: 100 },
        ]}
        selectedItem={400}
        testId={`${testId}-slip-type`}
        labelPosition={LabelPosition.Left}
        required
      /> */}
      <EnhancedDropDownRHFWithLabel
        label="Status"
        selectName="slipStatus"
        items={[
          { label: "Draft", value: 200 },
          { label: "Completed", value: 100 },
        ]}
        selectedItem={200}
        testId={`${testId}-status`}
        labelPosition={LabelPosition.Left}
        required
      />
    </>
  );
};
