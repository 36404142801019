import React from "react";

import { Grid, InputLabel, Typography } from "@mui/material";
import { RHookFormTextField } from "components/TaxSlipDataInput/RHookFormTextField";
import { RHookFormNumericFormatTextField } from "components/TaxSlipDataInput/RHookFormNumericFormatTextField";
// import { RHookFormTextField } from "@websential/cosmic";
// import { RHookFormTextField } from "./RHookFormTextField";

export interface ITaxSlipSummaryDataInput {
  id: string;
  label: string;
  boxNumber: string;
  readOnly?: boolean;
  onChange?:any;
  textfield?:any;
}

export const TaxSlipSummaryDataInput: React.FC<ITaxSlipSummaryDataInput> = ({
  id,
  label,
  boxNumber,
  readOnly = true,
  onChange,
  textfield
}) => {
  return (
    <Grid container columnSpacing={0} xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid
        item
        xs={7}
        sm={7}
        md={7}
        lg={7}
        xl={7}
        sx={{ textAlign: "left", paddingTop: 2 }}
      >
        <InputLabel>
          <Typography variant="subtitle2">{label}</Typography>
        </InputLabel>
      </Grid>
      <Grid
        item
        xs={1}
        sm={1}
        md={1}
        lg={1}
        xl={1}
        sx={{ textAlign: "center", paddingTop: 2 }}
      >
        <InputLabel>
          <Typography variant="subtitle2">{boxNumber}</Typography>
        </InputLabel>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        {textfield ? 
        <RHookFormTextField
        id={id}
        testId={id}
        readOnly={readOnly}
        onChange={onChange}
        textAlign={'end'}
      />:
          <RHookFormNumericFormatTextField
            id={id}
            testId={id}
            readOnly={readOnly}
            onChange={onChange}
            textAlign={'end'}
          />
        }
      </Grid>
    </Grid>
  );
};
