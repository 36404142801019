import { FC, useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import {
  IOtherInformationSetupFieldsPanel,
  OtherInformationSetupFieldsPanel,
} from "./OtherInformationSetupFieldsPanel";
import { TaxSlipDataInput } from "../../TaxSlipDataInput";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  EnhancedDropDownRHFWithLabel,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
} from "@websential/cosmic";
interface IForeignCurrencyDDL {
  label: String;
  name: String;
  value: String;
}
export interface IRL3SlipDataPanel extends IOtherInformationSetupFieldsPanel {}

export const RL3SlipDataPanel: FC<IRL3SlipDataPanel> = ({
  formMethods,
  otherInformationList,
  isLoading,
  testId,
  selectedOtherInformation,
  oiInEdit,
  // selectedProvinceOfEmployment,
  foreignCurrencyDDL,
  globalVariablesData,
}) => {
  const [countryLabel, setCountryLabel] = useState("");
  const [showGlobalVariablesError, setShowGlobalVariablesError] =
    useState(false);

  const onForiegnCurrencyChangeHandler = (e: any) => {
    let CountryFullName = foreignCurrencyDDL.find(
      (value: any) => e.value === value.value
    );
    console.log("CountryFullName", CountryFullName);
    setCountryLabel(CountryFullName.name);
  };
  let foreignCurrencyValue = formMethods.getValues("currencyCode");
  useEffect(() => {
    console.log("foreignCurrencyValue", foreignCurrencyValue);
    if (foreignCurrencyValue) {
      let CountryFullName =
        foreignCurrencyDDL &&
        foreignCurrencyDDL.find(
          (value: any) => foreignCurrencyValue === value.value
        );
      console.log("CountryFullName", CountryFullName);
      setCountryLabel(CountryFullName && CountryFullName.name);
    }
  }, [formMethods, foreignCurrencyValue]);
  const handleBoxA1InputChangeHandler = (e: any) => {
    console.log(e.target.value);
    let value = parseInt(e.target.value);
    const boxA2 = parseFloat(formMethods.getValues("actualAmountOfOrdinaryDivinends") && typeof(formMethods.getValues("actualAmountOfOrdinaryDivinends")) === "string" ? formMethods.getValues("actualAmountOfOrdinaryDivinends").replace(/[$,]/g, '') : formMethods.getValues("actualAmountOfOrdinaryDivinends"));    // const selectedRecipientType = formMethods.getValues('recipientType')
    // if(selectedRecipientType != 30){
    if (globalVariablesData && globalVariablesData?.data?.t5) {
      if (value >= 0) {
        // let box25CalculatedValue = ((value * 0.38) + value);
        // let box26CalculatedValue = (box25CalculatedValue * 0.150198);
        let calculatedValue1 =
          value * (globalVariablesData?.data?.t5?.eligibleGrossUpFactor / 100) +
          value;
        let calculatedValue2 =
          // parseInt(boxA2) *
            boxA2 *
            (globalVariablesData?.data?.t5?.nonEligibleGrossUpFactor / 100) +
          // parseInt(boxA2);
            boxA2;
        let boxBValue = calculatedValue1 + calculatedValue2;
        formMethods.setValue("taxableAmountOfDividends", boxBValue.toFixed(2));
        let boxCValue =
          // (value * 16.146) / 100 + (parseInt(boxA2) * 3.933) / 100;
          // (value * globalVariablesData?.data?.rl3?.eligibleDividends) / 100 + (parseInt(boxA2) * globalVariablesData?.data?.rl3?.ordinaryDividends) / 100;
          (value * globalVariablesData?.data?.rl3?.eligibleDividends) / 100 + (boxA2 * globalVariablesData?.data?.rl3?.ordinaryDividends) / 100;
        formMethods.setValue("dividendTaxCredit", boxCValue.toFixed(2));
      } else {
        formMethods.setValue("taxableAmountOfDividends", "0.00");
        formMethods.setValue("dividendTaxCredit", "0.00");
      }
      setShowGlobalVariablesError(false);
    } else {
      formMethods.setValue("taxableAmountOfDividends", "0.00");
      formMethods.setValue("dividendTaxCredit", "0.00");
      if(globalVariablesData){
      setShowGlobalVariablesError(
        globalVariablesData.data.message
          ? globalVariablesData.data.message
          : "RL-3 Slip definition does not exist for selected year, please add definition to configure global variables"
      );
    }
    }
    // } else {
    //   formMethods.setValue('taxableAmountOfEligibleDividends',"0.00")
    //   formMethods.setValue('dividendTaxCreditForEligibleDividends',"0.00")
    // }
  };
  const handleBoxA2InputChangeHandler = (e: any) => {
    console.log(e.target.value);
    let value = parseInt(e.target.value);
    const boxA1 = parseFloat(formMethods.getValues("actualAmountOfEligibleDivinends") && typeof(formMethods.getValues("actualAmountOfEligibleDivinends")) === "string" ? formMethods.getValues("actualAmountOfEligibleDivinends").replace(/[$,]/g, '') : formMethods.getValues("actualAmountOfEligibleDivinends"));    // const selectedRecipientType = formMethods.getValues('recipientType')

    // if(selectedRecipientType != 30){
    if (globalVariablesData && globalVariablesData?.data?.t5) {
      if (value >= 0) {
        let calculatedValue1 =
          // parseInt(boxA1) *
          boxA1 *
            (globalVariablesData?.data?.t5?.eligibleGrossUpFactor / 100) +
          // parseInt(boxA1);
          boxA1;
        let calculatedValue2 =
          value *
            (globalVariablesData?.data?.t5?.nonEligibleGrossUpFactor / 100) +
          value;
        let boxBValue = calculatedValue1 + calculatedValue2;
        formMethods.setValue("taxableAmountOfDividends", boxBValue.toFixed(2));
        let boxCValue =
          // (parseInt(boxA1) * globalVariablesData?.data?.rl3?.eligibleDividends) / 100 + (value * globalVariablesData?.data?.rl3?.ordinaryDividends) / 100;
          (boxA1 * globalVariablesData?.data?.rl3?.eligibleDividends) / 100 + (value * globalVariablesData?.data?.rl3?.ordinaryDividends) / 100;
        formMethods.setValue("dividendTaxCredit", boxCValue.toFixed(2));
      } else {
        formMethods.setValue("taxableAmountOfDividends", "0.00");
        formMethods.setValue("dividendTaxCredit", "0.00");
      }
      setShowGlobalVariablesError(false);
    } else {
      formMethods.setValue("taxableAmountOfDividends", "0.00");
      formMethods.setValue("dividendTaxCredit", "0.00");
      if(globalVariablesData){
      setShowGlobalVariablesError(
        globalVariablesData.data.message
          ? globalVariablesData.data.message
          : "RL-3 Slip definition does not exist for selected year, please add definition to configure global variables"
      );
    }
    }
    // } else {
    //   formMethods.setValue('taxableAmountOfEligibleDividends',"0.00")
    //   formMethods.setValue('dividendTaxCreditForEligibleDividends',"0.00")
    // }
  };

  const selectedRecipientType = formMethods.getValues("recipientType");
  console.log("selectedRecipientType", selectedRecipientType);
  return (
    <>
      <Grid container columnSpacing={2} xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}></Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Actual
          </Typography>
        </Grid>
      </Grid>
      {showGlobalVariablesError === false ? (
        <>
          <TaxSlipDataInput
            id="actualAmountOfEligibleDivinends"
            label="Actual amount of eligible dividends"
            boxNumber="A1"
            defaultValue="0.00"
            onChange={handleBoxA1InputChangeHandler}
            readOnly={selectedRecipientType === 30 ? true : false}
          />
        </>
      ) : (
        <>
          <div>
            <TaxSlipDataInput
              id="actualAmountOfEligibleDivinends"
              label="Actual amount of eligible dividends"
              boxNumber="A1"
              defaultValue="0.00"
              onChange={handleBoxA1InputChangeHandler}
              readOnly={selectedRecipientType === 30 ? true : false}
            />
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "19.6rem",
                color: "#cc8925",
                fontSize: "12px",
                background: "#fff2e2",
                alignItems: "center",
                padding: "2px",
                borderRadius: "5px",
              }}
            >
              <WarningAmberIcon
                sx={{ marginRight: "7px", padding: "0 2px 2px 2px" }}
              />
              {showGlobalVariablesError}
            </span>
          </div>
        </>
      )}
      {showGlobalVariablesError === false ? (
        <>
          <TaxSlipDataInput
            id="actualAmountOfOrdinaryDivinends"
            label="Actual amount of ordinary dividends"
            boxNumber="A2"
            defaultValue="0.00"
            onChange={handleBoxA2InputChangeHandler}
            readOnly={selectedRecipientType === 30 ? true : false}
          />
        </>
      ) : (
        <>
          <div>
            <TaxSlipDataInput
              id="actualAmountOfOrdinaryDivinends"
              label="Actual amount of ordinary dividends"
              onChange={handleBoxA2InputChangeHandler}
              readOnly={selectedRecipientType === 30 ? true : false}
              boxNumber="A2"
              defaultValue="0.00"
            />
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "19.6rem",
                color: "#cc8925",
                fontSize: "12px",
                background: "#fff2e2",
                alignItems: "center",
                padding: "2px",
                borderRadius: "5px",
              }}
            >
              <WarningAmberIcon
                sx={{ marginRight: "7px", padding: "0 2px 2px 2px" }}
              />
              {showGlobalVariablesError}
            </span>
          </div>
        </>
      )}
      <TaxSlipDataInput
        id="taxableAmountOfDividends"
        label="Taxable amount of dividends"
        boxNumber="B"
        defaultValue="0.00"
        readOnly={true}
      />
      <TaxSlipDataInput
        id="dividendTaxCredit"
        label="Dividend tax credit"
        boxNumber="C"
        defaultValue="0.00"
        readOnly={true}
      />
      <TaxSlipDataInput
        id="interestFromCanadianSources"
        label="Interest from canadian sources"
        boxNumber="D"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="otherIncomeFromCanadianSources"
        label="Other income from canadian sources"
        boxNumber="E"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="grossForeignInvestmentIncome"
        label="Gross foreign investment income"
        boxNumber="F"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="foreignIncomeTax"
        label="Foreign income tax"
        boxNumber="G"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="royaltiesFromCanadianSources"
        label="Royalties from canadian sources"
        boxNumber="H"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="capitalGainDividends"
        label="Capital gain dividends"
        boxNumber="I"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="accuredIncomeOrAnnuities"
        label="Accured income: annuities"
        boxNumber="J"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="interestFromLinkedNotes"
        label="Interest from linked notes"
        boxNumber="K"
        defaultValue="0.00"
      />
      <EnhancedDropDownRHFWithLabel
        selectName="currencyCode"
        label="Currency code"
        labelPosition={LabelPosition.Left}
        testId={`${testId}-currency-list`}
        items={foreignCurrencyDDL ?? []}
        onSelectionChangeHandler={onForiegnCurrencyChangeHandler}
        labelColumns={9}
        fieldColumns={2.6}
      />
      <Typography
        sx={{
          fontSize: "small",
          paddingRight: "17px",
          textAlign: "end",
          fontWeight: "600",
        }}
      >
        {countryLabel}
      </Typography>
      <TaxSlipDataInput id="bankTransit" label="Bank transit" boxNumber=""  textfield={true} />
      <TaxSlipDataInput
        id="otherIdentificationNumber"
        label="Other identification number"
        boxNumber=""
        textfield={true}
      />
      <TaxSlipDataInput
        id="numberAssignedByPayerOrNominee"
        label="Number assigned by payer/nominee"
        boxNumber=""
        textfield={true}
      />
      <TaxSlipDataInput
        id="optionalTextToPrintOnTheSlip"
        label="Optional text to print on the slip"
        boxNumber=""
        optionalTextField={true}
      />

      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ mt: 2, p: 4, backgroundColor: "#f4f5f7" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold", pb: 1 }}>
            Other Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OtherInformationSetupFieldsPanel
            formMethods={formMethods}
            isLoading={isLoading}
            otherInformationList={otherInformationList}
            testId={testId}
            selectedOtherInformation={selectedOtherInformation}
            oiInEdit={oiInEdit}
            // selectedProvinceOfEmployment={undefined}
            globalVariablesData={undefined}
          />
        </Grid>
      </Grid>
    </>
  );
};
