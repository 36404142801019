import T5SlipImportLayout from "components/T5Slip/T5SlipImportLayout/T5SlipImportLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT5SlipImportPage {}

export const T5SlipImportPage: FC<IT5SlipImportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import T5 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t5/list" },
        { text: "T5", navigateTo: "/taxslip/t5/list" },
        { text: "Import T5 Slips", navigateTo: "/taxslip/t5/import" },
      ]}
      mainContents={<T5SlipImportLayout />}
    />
  );
};
