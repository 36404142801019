import { T4SlipList } from "components/T4Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT4SlipListPage {}

export const T4SlipListPage: FC<IT4SlipListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T4 Slip List"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t4/list" },
        { text: "T4", navigateTo: "/taxslip/t4/list" },
        { text: "T4 Slips", navigateTo: "/taxslip/t4/list" },
      ]}
      mainContents={<T4SlipList />}
    />
  );
};
