import { FC } from "react";

import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FlexFieldSetup } from "components/FlexField";

export interface IFlexFieldSetupPage {}

export const FlexFieldSetupPage: FC<IFlexFieldSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Flex Fields Setup"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{ text: "Flex Fields", navigateTo: "/flex-fields/list" },
      { text: "Create Flex Fields", navigateTo: "/flex-field/setup" }]}
      mainContents={<FlexFieldSetup testId="flex-field-setup" />}
    />
  );
};
