import { FilingResourceSetup } from "components/FilingResource";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const FilingResourceSetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Filing Resource Setup"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
      { text: "Filing Resources", navigateTo: "/filing-resources/list" },
      { text: "Create Filing Resources", navigateTo: "/filing-resources/setup" }]}
      mainContents={<FilingResourceSetup testId="filing-resource-setup-page" />}
    />
  );
};
