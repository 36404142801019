import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useRef, useState } from "react";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IAppState } from "store/reducers";
import EmailIcon from '@mui/icons-material/Email';
import PrintIcon from "@mui/icons-material/Print";
import { Print } from "@mui/icons-material";
import ArticleIcon from "@mui/icons-material/Article";
import { Box, Button, Grid, Tooltip, useTheme } from "@mui/material";
import { BackdropCircularProgress, DeleteConfirmationDialog, EmptyList, EnhancedDialog, EnhancedLinkButton, EnhancedSnackbar, IResponse, IServerError, IconTextButton, RouterLinkButton, SplitButton } from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import {
  GET_QUERY_KEY,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
  // RL16_LIST_SCREEN_ID,
  RL16_LIST_SCREEN_SLUG,
} from "common/constants";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FileSaver from "file-saver";
import { PrintingService } from "services/PrintingService";
import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions";
import { InvoiceDialogPanel } from "components/T4Slip/T4SlipList/InvoiceDialogPanel";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import { RL16SlipService } from "services/RL16SlipService";
import { PrimeDataGrid } from "components/PrimeDataGrid";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { T4SlipService } from "services/T4SlipService";
import { ClientService } from "services";
import { ProgressBar } from "primereact/progressbar";

export interface IRL16Slip {}
const TAXSLIP_RL16_LIST_QUERY_KEY = "rl16-slips-list"

const useRL16Slips = (
  clientId: string,
  taxYear: number,
  params: IGetListRequest
) =>
  useQuery(
    [GET_QUERY_KEY.LIST_RL16_SLIPS, params],
    (data) => {
      return RL16SlipService.getList(clientId, taxYear, params);
    },
    { refetchOnWindowFocus: false }
  );

  const GET_ADJUSTMENTS_QUERY_KEY = "get-adjustments";

  const useAdjustments = (clientId: string) =>
  useQuery(
    GET_ADJUSTMENTS_QUERY_KEY,
    () => AdjustmentOptionsService.getAdjustmentOptionsByClientId(clientId),
    QUERY_OPTIONS
  );

  const useGetSlipIds = (
    tag: string,
    clientId: string,
    taxYear: number
  ) =>
    useQuery(
      ["get-slip-ids", tag, clientId, taxYear],
      (data) => {
        return T4SlipService.getSlipIds(tag, clientId, taxYear);
      },
      { refetchOnWindowFocus: false }
    );

    const useGetCompanyData = (id: string | undefined) => 
      useQuery(
        ["client-get-by-id", id],
        (data) => {
          return ClientService.getById(id);
        },
        { refetchOnWindowFocus: false }
      );
    
export const RL16SlipList: React.FC<IRL16Slip> = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state: IAppState) => state.client);
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear  = useSelector((state: IAppState) => state.taxYear.year);
  const { resourceName } = useParams();
  const [dataRoleName, setDataRoleName] = useState<string>("");
  const clientName = useSelector((state : IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector((state: IAppState) => state.isLoadAxiosCall.isLoading);
  const maskedStatus:any = sessionStorage.getItem("maskEnabled")
  const [invoicePaymentFor, setInvoicePaymentFor] = useState(10)

  const interval = useRef<any>(null);
  const [percentageValue, setPercentageValue] = useState(1);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const[openInvoiceDialog, setInvoiceDialogOpen] = useState(false)
  const[invoiceData, setInvoiceData] = useState<any>([])
  const[isLoadingData, setIsLoadingData] = useState(false)
  const [selectedRowsValues, setSelectedRowsValues] = useState([]);
  const [RL16SlipListUpdatedItems, setRL16SlipListUpdatedItems] = useState<any>(undefined);


  // for delete row Purpose
  const queryClient = useQueryClient();
  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);

  useEffect(() => {
    if (resourceName && resourceName.length > 0) {
      console.log("resourceName>>>>>", resourceName);
      setDataRoleName(resourceName);
    }
  }, [dataRoleName, resourceName]);

  const theme = useTheme();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "name1",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useRL16Slips(id, taxYear, queryParams);
  const slipList: any = data;
  console.log("@@@@@ => resourceList", slipList);

  const { isLoading: isLoadingAdjustments, data: adjustments } =
    useAdjustments(clientId);
  let AdjustmentOptionsData : any = adjustments

  const { isLoading:loadingSlipIds, data:rl16SlipIds } = useGetSlipIds("RL16", clientId, taxYear);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  useEffect(()=>{
    console.log("companyGetByIdRecord11",companyGetByIdRecord)
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor ? parseInt(paymentFor.value) : 10)
    }
  },[companyGetByIdRecord])

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  const isAxiosCallLoading = queryClient.isFetching([TAXSLIP_RL16_LIST_QUERY_KEY]) > 0;

  useEffect(()=>{
    if(slipList){
      const updatedItemsResponse = slipList.items.map((item:any) => ({
        ...item,
        name: (
          <Link
            to={`/taxslip/rl16/setup/${item.id}`}
            className="no-underline"
            style={{ color: "blue" }}
            // onClick={() => onClickHandler(row)}
          >
            {item.name}
          </Link>
        ),
        }));
      setRL16SlipListUpdatedItems(updatedItemsResponse)
      console.log("RL16SlipListUpdatedItemsRL16SlipListUpdatedItems",updatedItemsResponse)
      console.log("RL16SlipListUpdatedItemsRL16SlipListUpdatedItems",RL16SlipListUpdatedItems)
  }
},[slipList])

  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);
  const [searchText, setSearchText] = useState({});
  const [zeroClient, setzeroClient] = useState(true);

  // for delete row
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [RL16SlipId, setRL16SlipId] = useState<number>(0);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: "name1",
      order_direction: requestParams.sortOrder ? requestParams.sortOrder : "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
    };
    // if (requestParams.sort) {
    //   const [sortBy, sortDirection] = requestParams.sort.split(",");
    //   dispatchParams.order_by = toSnake(sortBy);
    //   dispatchParams.order_direction = sortDirection;
    // }
console.log("dispatchParams",dispatchParams)
    setQueryParams(dispatchParams);
  }, []);

  const onSearchButtonClicked=()=>{
    setQueryParams(searchText)
    setzeroClient(false)
  }

  const onRowEditClicked = useCallback((params) => {
    let path = `/taxslip/rl16/setup/${params.id}`;
    navigate(path);
  }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
    setInvoiceDialogOpen(false);
  };

  const callAction = () => {
    if (apiAction === "delete") {
      alert(`Delete row with ID: ${selectedIds} has been clicked!`);
      // TODO api call for delete
    } else if (apiAction === "updateStatus") {
      alert(`action need to trigger : ${apiAction}`);
      // TODO api call for update status
    }
    setDialogOpen(false);
  };

  // For Delete Row
 const deleteRL16SlipField = () => {
  if (singleDelete) {
    let payload = {
      RL16SlipId,
      clientId,
      taxYear
    }
    setSingleDelete(false);
    deleteRL16SlipById(payload);
    setDialogOpen(false);
  }
};

const onRowDeleteClicked = useCallback((params) => {
  setDialogOpen(true);
  setSingleDelete(true);
  setApiAction("delete");
  setRL16SlipId(params.id);
  console.log("RL16SlipId in RL16Slip List OnDeleteRowClicked function",params.id)
  // alert(`Delete row with ID: ${params.id} has been clicked!`);
}, []);

const onSnackbarClosed = (
  event: React.SyntheticEvent | Event,
  reason?: string
) => {
  if (reason === "clickaway") {
    return;
  }
  setSnackbarData(INITIAL_SNACKBAR_DATA);
};

const { isLoading: isDeleting, mutate: deleteRL16SlipById } = useMutation(
  RL16SlipService.delRL16Slip,
  {
    onSuccess: (res: string | IServerError | IResponse | any) => {
      if (typeof res === "string") {
        // remove the cache of the Year fields since we just added a new field.
        setSnackbarData({
          open: true,
          message: res,
          severity: "success",
        });

        return queryClient.invalidateQueries(
          TAXSLIP_RL16_LIST_QUERY_KEY
          );
        } else {
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "error",
          });
        }
      },
    onError: (err:any) => {
      // TODO: customize the toast message based on the response
      setSnackbarData({
        open: true,
        message: err.message,
        severity: "error",
      });
    },
  }
);

// Bulk Delete
const [bulkDelete, setBulkDelete] = useState<boolean>(false);
const [bulkRL16SlipsId, setBulkRL16SlipsId] = useState<any>({});
const [bulkRL16SlipsDelete, setBulkRL16SlipsDelete] = useState<boolean>(false);

const { isLoading: isBulkDeleting, mutate: deleteBulkRL16SlipsById } =
    useMutation(RL16SlipService.delBulkRL16Slips, {
      onSuccess: (res: any | string | IServerError | IResponse) => {
        if (typeof res === "object") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "success",
          });

          setBulkRL16SlipsDelete(false)
          return queryClient.invalidateQueries(TAXSLIP_RL16_LIST_QUERY_KEY);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
        setBulkRL16SlipsDelete(false)
      },
      onError: (err:any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
        setBulkRL16SlipsDelete(false)
      },
    });

  // For Bulk Delete
  const deleteBulkRL16SlipsField = () => {
    if (bulkDelete) {
      setBulkDelete(false);
      deleteBulkRL16SlipsById(bulkRL16SlipsId);
      setDialogOpen(false);
    }
  };

const savePdfAsFile = (pdfData: any, filename: any) => {
  const blob = new Blob([pdfData], { type: 'pdf;charset=utf-8' });
  FileSaver.saveAs(blob, filename);
};
console.log("selectedRowsValues12",selectedRowsValues)
let newData:any = []
const onPrintClicked = async (): Promise<void> => {
  const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
  console.log("completedSlipsIds",completedSlipsIds)
  // Convert arrays to strings and compare
  if(newData.length > 0){
    const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
    if (!areArraysEqual) {
      setSnackbarData({
        open: true,
        message: "Please Selected RL16 Completed Slips Only!",
        severity: "error",
      });
    }else{
  setIsLoadingData(true);
  const maskedStatus:any = sessionStorage.getItem("maskEnabled")
  if(maskedStatus === "true"){
    setIsLoadingData(false);
    setSnackbarData({
      open: true,
      message: "Please unmask your data.",
      severity: "error",
    });
  } else {
    if(invoicePaymentFor === 10){
      try {
        const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
          clientId,
          taxYear-1
        );
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
          if(rl16SlipIds && rl16SlipIds.generalAuditStatus !== null && rl16SlipIds.generalAuditStatus === true &&
            rl16SlipIds && rl16SlipIds.auditStatus !== null &&  rl16SlipIds.auditStatus === true){
            setIsLoadingData(true);
        const ids_list: any = newData.map((ids:any) => `rl16_slip_ids=${ids}`).join('&');
        const pdfIds = ids_list.replace(/^&+|&+$/g, '')
        setIsLoadingData(true);
        try {
          const response: any = await PrintingService.createBulkRL16PdfPrint(clientId, pdfIds, taxYear);
          if(response.status === 400){
            setSnackbarData({
              open: true,
              message: response.data.message ? response.data.message : "Something went wrong.",
              // message: "Please Unmask sensitive data for PDF printing.",
              severity: "error",
            });
          }else{
            const filename = `RL-16slip-${clientName}.pdf`;
            savePdfAsFile(response, filename);
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err",err)
          alert(err)
        }
      // }
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      setSnackbarData({
      open: true,
      message: (
        <>
          Please load Audit in the menu, press Run RL-16 Audit and clear all audit items to generate documents.{" "}
          <a
            href="https://appv3.taxslips.com/#/audit/filing"
            style={{
              color: "#aa1010",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Click here to view
          </a>
        </>
      ),
      severity: "error",
      autoHide: false,
    });
  }

        } else if (
          response?.id != null &&
          previousYearInvoiceResponse?.id != null
        ) {
          console.log("one");
          setInvoiceData([previousYearInvoiceResponse, response]);
          setInvoiceDialogOpen(true);
        } else if (
          previousYearInvoiceResponse?.id != null &&
          response?.message === "Slips not found for invoice."
        ) {
          console.log("two");
          setInvoiceData([previousYearInvoiceResponse]);
          setInvoiceDialogOpen(true);
        } else if (
          response?.id != null &&
          previousYearInvoiceResponse?.message === "Slips not found for invoice."
        ) {
          console.log("three");
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        }else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    } else {
    try {
      const response:any = await ReportsService.createInvoice(clientId,taxYear);
      setIsLoadingData(false);
      console.log("responseresponse",response)
      if(response.message === "Slips not found for invoice."){
        // setIsLoadingData(true);
        //     try {
        //       const filingAuditResponse:any = await RL16SlipService.getRL16AuditFiling(clientId,taxYear);
        //       console.log("filingAuditResponse",filingAuditResponse)
        //       if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
        //         console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
        //         setSnackbarData({
        //           open: true,
        //           message: (
        //             <>
        //               Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
        //               <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
        //                                               textDecoration:"none",
        //                                               fontWeight:"bold"}}>Click here to view</a>
        //             </>
        //           ),
        //           severity: "error",
        //           autoHide:false
        //         });
        //       } else if(filingAuditResponse && filingAuditResponse.rl16_filling && filingAuditResponse.rl16_filling.length > 0){
        //         console.log("filingAuditResponse.RL16_filling",filingAuditResponse.rl16_filling)
        //         setSnackbarData({
        //           open: true,
        //           message: (
        //             <>
        //               Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
        //               <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
        //                                               textDecoration:"none",
        //                                               fontWeight:"bold"}}>Click here to view</a>
        //             </>
        //           ),
        //           severity: "error",
        //           autoHide:false
        //         });
        //       } else {
          if(rl16SlipIds && rl16SlipIds.generalAuditStatus !== null && rl16SlipIds.generalAuditStatus === true &&
            rl16SlipIds && rl16SlipIds.auditStatus !== null &&  rl16SlipIds.auditStatus === true){
            setIsLoadingData(true);
        const ids_list: any = newData.map((ids:any) => `rl16_slip_ids=${ids}`).join('&');
        const pdfIds = ids_list.replace(/^&+|&+$/g, '')
        setIsLoadingData(true);
        try {
          const response: any = await PrintingService.createBulkRL16PdfPrint(clientId, pdfIds, taxYear);
          if(response.status === 400){
            setSnackbarData({
              open: true,
              message: response.data.message ? response.data.message : "Something went wrong.",
              // message: "Please Unmask sensitive data for PDF printing.",
              severity: "error",
            });
          }else{
            const filename = `RL-16slip-${clientName}.pdf`;
            savePdfAsFile(response, filename);
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err",err)
          alert(err)
        }
      // }
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      setSnackbarData({
      open: true,
      message: (
        <>
          Please load Audit in the menu, press Run RL-16 Audit and clear all audit items to generate documents.{" "}
          <a
            href="https://appv3.taxslips.com/#/audit/filing"
            style={{
              color: "#aa1010",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Click here to view
          </a>
        </>
      ),
      severity: "error",
      autoHide: false,
    });
  }
    // } catch(err:any) {
    //   setIsLoadingData(false);
    //   alert(err)
    // }
      } else if(response && response.id !== null) {
        setInvoiceData([response])
        setInvoiceDialogOpen(true)
      }else{
        setSnackbarData({
          open: true,
          message: "Something went wrong.",
          severity: "error",
        });
      }
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log("err",err)
    } finally{
      setIsLoadingData(false);
    }
    }
  }
}
}
}

const onAllSlipsPrintClicked = async (event:any): Promise<void> => {
    setIsLoadingData(true);
    const maskedStatus:any = sessionStorage.getItem("maskEnabled")
    if(maskedStatus === "true"){
    setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(rl16SlipIds && rl16SlipIds.generalAuditStatus !== null && rl16SlipIds.generalAuditStatus === true &&
              rl16SlipIds && rl16SlipIds.auditStatus !== null &&  rl16SlipIds.auditStatus === true){
              setIsLoadingData(true);
            try {
              const response: any = await PrintingService.createAllSlipsPdfPrint(clientId, event === 'Recipient' ? "20" : event === 'Payer' ? '10' : '30', "RL16", taxYear);          console.log("response000",response)
              if(response.status === 400){
                setSnackbarData({
                  open: true,
                  message: response.data.message ? response.data.message : "Something went wrong.",
                  // message: "Please Unmask sensitive data for PDF printing.",
                  severity: "error",
                });
              }else{
                const filename = `RL-16slip-${clientName}-${event}.pdf`;
                savePdfAsFile(response, filename);
              }
              setIsLoadingData(false);
            } catch (err) {
              console.log("err",err)
            }
          // }
          setIsLoadingData(false);
        } else {
          setIsLoadingData(false);
          setSnackbarData({
          open: true,
          message: (
            <>
              Please load Audit in the menu, press Run RL-16 Audit and clear all audit items to generate documents.{" "}
              <a
                href="https://appv3.taxslips.com/#/audit/filing"
                style={{
                  color: "#aa1010",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Click here to view
              </a>
            </>
          ),
          severity: "error",
          autoHide: false,
        });
      }

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
    try {
      const response:any = await ReportsService.createInvoice(clientId,taxYear);
      setIsLoadingData(false);
      console.log("responseresponse",response)
      if(response.message === "Slips not found for invoice."){
        // setIsLoadingData(true);
        //     try {
        //       const filingAuditResponse:any = await RL16SlipService.getRL16AuditFiling(clientId,taxYear);
        //       console.log("filingAuditResponse",filingAuditResponse)
        //       if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
        //         console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
        //         setSnackbarData({
        //           open: true,
        //           message: (
        //             <>
        //               Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
        //               <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
        //                                               textDecoration:"none",
        //                                               fontWeight:"bold"}}>Click here to view</a>
        //             </>
        //           ),
        //           severity: "error",
        //           autoHide:false
        //         });
        //       } else if(filingAuditResponse && filingAuditResponse.rl16_filling && filingAuditResponse.rl16_filling.length > 0){
        //         console.log("filingAuditResponse.RL16_filling",filingAuditResponse.rl16_filling)
        //         setSnackbarData({
        //           open: true,
        //           message: (
        //             <>
        //               Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
        //               <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
        //                                               textDecoration:"none",
        //                                               fontWeight:"bold"}}>Click here to view</a>
        //             </>
        //           ),
        //           severity: "error",
        //           autoHide:false
        //         });
        //       } else {
        // setIsLoadingData(true);
        if(rl16SlipIds && rl16SlipIds.generalAuditStatus !== null && rl16SlipIds.generalAuditStatus === true &&
          rl16SlipIds && rl16SlipIds.auditStatus !== null &&  rl16SlipIds.auditStatus === true){
          setIsLoadingData(true);
        try {
          const response: any = await PrintingService.createAllSlipsPdfPrint(clientId, event === 'Recipient' ? "20" : event === 'Payer' ? '10' : '30', "RL16", taxYear);          console.log("response000",response)
          if(response.status === 400){
            setSnackbarData({
              open: true,
              message: response.data.message ? response.data.message : "Something went wrong.",
              // message: "Please Unmask sensitive data for PDF printing.",
              severity: "error",
            });
          }else{
            const filename = `RL-16slip-${clientName}-${event}.pdf`;
            savePdfAsFile(response, filename);
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err",err)
        }
      // }
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      setSnackbarData({
      open: true,
      message: (
        <>
          Please load Audit in the menu, press Run RL-16 Audit and clear all audit items to generate documents.{" "}
          <a
            href="https://appv3.taxslips.com/#/audit/filing"
            style={{
              color: "#aa1010",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Click here to view
          </a>
        </>
      ),
      severity: "error",
      autoHide: false,
    });
  }
    // } catch(err:any) {
    //   setIsLoadingData(false);
    //   alert(err)
    // }
      } else if(response && response.id !== null) {
        setInvoiceData([response])
        setInvoiceDialogOpen(true)
      }else{
        setSnackbarData({
          open: true,
          message: "Something went wrong.",
          severity: "error",
        });
      }
      setIsLoadingData(false);
    } catch (err) {
      console.log("err",err)
    }
    }
    }
};

const saveXmlAsFile = (xmlData: any, filename: any) => {
  const blob = new Blob([xmlData], { type: 'xml;charset=utf-8' });
  FileSaver.saveAs(blob, filename);
};

const onGenerateXMLClicked = async (SlipType: any): Promise<void> => {
  const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
console.log("completedSlipsIds",completedSlipsIds)
console.log("newData",newData)
// Convert arrays to strings and compare
if(newData.length > 0){
  const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
  if (!areArraysEqual) {
    setSnackbarData({
      open: true,
      message: "Please Selected RL16 Completed Slips Only!",
      severity: "error",
    });
  }else{
setIsLoadingData(true);
if(maskedStatus === "true"){
  setIsLoadingData(false);
  setSnackbarData({
    open: true,
    message: "Please unmask your data.",
    severity: "error",
  });
} else {
  if(invoicePaymentFor === 10){
    try {
      const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
        clientId,
        taxYear-1
      );
    try {
      const response: any = await ReportsService.createInvoice(
        clientId,
        taxYear
      );
      setIsLoadingData(false);
      console.log("responseresponse", response);
      if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
        if(rl16SlipIds && rl16SlipIds.generalAuditStatus !== null && rl16SlipIds.generalAuditStatus === true &&
          rl16SlipIds && rl16SlipIds.auditStatus !== null &&  rl16SlipIds.auditStatus === true){
          setIsLoadingData(true);
        try {
          let SlipTypeData: number = 100;
          if (SlipType === "Original"){
            SlipTypeData = 400;
          }
          if (SlipType === "Amended"){
            SlipTypeData = 200;
          };
          if (SlipType === "Cancelled"){
            SlipTypeData = 300;
          }
        console.log("SlipTypeData", SlipTypeData)
        const ids_list: any = newData.map((ids:any) => `rl16_slip_ids=${ids}`).join('&');
        const pdfIds = ids_list.replace(/^&+|&+$/g, '')
          const response: any = await PrintingService.createRL16Xml(clientId, SlipTypeData ,taxYear,pdfIds);
          if(response && response.message){
            setSnackbarData({
              open: true,
              message: response.message ? response.message : "Something went wrong.",
              severity: "error",
            });
          }else{
            const filename = `RL16xml-${clientName}-${SlipType}.xml`;
            saveXmlAsFile(response, filename);
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err",err)
          alert(err)
        }
      // }
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      setSnackbarData({
      open: true,
      message: (
        <>
          Please load Audit in the menu, press Run RL-16 Audit and clear all audit items to generate documents.{" "}
          <a
            href="https://appv3.taxslips.com/#/audit/filing"
            style={{
              color: "#aa1010",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Click here to view
          </a>
        </>
      ),
      severity: "error",
      autoHide: false,
    });
  }

      } else if (
        response?.id != null &&
        previousYearInvoiceResponse?.id != null
      ) {
        console.log("one");
        setInvoiceData([previousYearInvoiceResponse, response]);
        setInvoiceDialogOpen(true);
      } else if (
        previousYearInvoiceResponse?.id != null &&
        response?.message === "Slips not found for invoice."
      ) {
        console.log("two");
        setInvoiceData([previousYearInvoiceResponse]);
        setInvoiceDialogOpen(true);
      } else if (
        response?.id != null &&
        previousYearInvoiceResponse?.message === "Slips not found for invoice."
      ) {
        console.log("three");
        setInvoiceData([response]);
        setInvoiceDialogOpen(true);
      }else {
        setSnackbarData({
          open: true,
          message: "Something went wrong.",
          severity: "error",
        });
      }
      setIsLoadingData(false);
    } catch (err) {
      console.log("err", err);
      setIsLoadingData(false);
    }
    } catch (err) {
      console.log("err", err);
      setIsLoadingData(false);
    }
  } else {
  try {
    const response:any = await ReportsService.createInvoice(clientId,taxYear);
    setIsLoadingData(false);
    console.log("responseresponse",response)
    if(response.message === "Slips not found for invoice."){
      // setIsLoadingData(true);
      //     try {
      //       const filingAuditResponse:any = await RL16SlipService.getRL16AuditFiling(clientId,taxYear);
      //       console.log("filingAuditResponse",filingAuditResponse)
      //       if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
      //         console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
      //         setSnackbarData({
      //           open: true,
      //           message: (
      //             <>
      //               Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
      //               <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
      //                                               textDecoration:"none",
      //                                               fontWeight:"bold"}}>Click here to view</a>
      //             </>
      //           ),
      //           severity: "error",
      //           autoHide:false
      //         });
      //       } else if(filingAuditResponse && filingAuditResponse.rl16_filling && filingAuditResponse.rl16_filling.length > 0){
      //         console.log("filingAuditResponse.rl16_filling",filingAuditResponse.rl16_filling)
      //         setSnackbarData({
      //           open: true,
      //           message: (
      //             <>
      //               Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
      //               <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
      //                                               textDecoration:"none",
      //                                               fontWeight:"bold"}}>Click here to view</a>
      //             </>
      //           ),
      //           severity: "error",
      //           autoHide:false
      //         });
      //       } else {
      // setIsLoadingData(true);
      if(rl16SlipIds && rl16SlipIds.generalAuditStatus !== null && rl16SlipIds.generalAuditStatus === true &&
        rl16SlipIds && rl16SlipIds.auditStatus !== null &&  rl16SlipIds.auditStatus === true){
        setIsLoadingData(true);
      try {
        let SlipTypeData: number = 100;
        if (SlipType === "Original"){
          SlipTypeData = 400;
        }
        if (SlipType === "Amended"){
          SlipTypeData = 200;
        };
        if (SlipType === "Cancelled"){
          SlipTypeData = 300;
        }
      console.log("SlipTypeData", SlipTypeData)
      const ids_list: any = newData.map((ids:any) => `rl16_slip_ids=${ids}`).join('&');
      const pdfIds = ids_list.replace(/^&+|&+$/g, '')
        const response: any = await PrintingService.createRL16Xml(clientId, SlipTypeData ,taxYear,pdfIds);
        if(response && response.message){
          setSnackbarData({
            open: true,
            message: response.message ? response.message : "Something went wrong.",
            severity: "error",
          });
        }else{
          const filename = `RL16xml-${clientName}-${SlipType}.xml`;
          saveXmlAsFile(response, filename);
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err",err)
        alert(err)
      }
    // }
    setIsLoadingData(false);
  } else {
    setIsLoadingData(false);
    setSnackbarData({
    open: true,
    message: (
      <>
        Please load Audit in the menu, press Run RL-16 Audit and clear all audit items to generate documents.{" "}
        <a
          href="https://appv3.taxslips.com/#/audit/filing"
          style={{
            color: "#aa1010",
            textDecoration: "none",
            fontWeight: "bold",
          }}
        >
          Click here to view
        </a>
      </>
    ),
    severity: "error",
    autoHide: false,
  });
}
  // } catch(err:any) {
  //   setIsLoadingData(false);
  //   alert(err)
  // }
    } else if(response && response.id !== null) {
      setInvoiceData([response])
      setInvoiceDialogOpen(true)
    }else{
      setSnackbarData({
        open: true,
        message: "Something went wrong.",
        severity: "error",
      });
    }
    setIsLoadingData(false);
  } catch (err) {
    console.log("err",err)
  }
}
}
}
}
};

const onEmailButtonClicked = async (): Promise<void> => {
  const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
  console.log("completedSlipsIds",completedSlipsIds)
  // Convert arrays to strings and compare
  if(newData.length > 0){
    const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
    if (!areArraysEqual) {
      setSnackbarData({
        open: true,
        message: "Please Selected RL16 Completed Slips Only!",
        severity: "error",
      });
    }else{
  setIsLoadingData(true);
  const maskedStatus:any = sessionStorage.getItem("maskEnabled")
  if(maskedStatus === "true"){
    setIsLoadingData(false);
    setSnackbarData({
      open: true,
      message: "Please unmask your data.",
      severity: "error",
    });
  } else {
    if(invoicePaymentFor === 10){
      try {
        const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
          clientId,
          taxYear-1
        );
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
          if(rl16SlipIds && rl16SlipIds.generalAuditStatus !== null && rl16SlipIds.generalAuditStatus === true &&
            rl16SlipIds && rl16SlipIds.auditStatus !== null &&  rl16SlipIds.auditStatus === true){
              const ids_list = 
                    (slipList?.items?.length === newData.length) 
                        ? {items:rl16SlipIds.slipIds} 
                        : {items:newData};
                        console.log("slipList?.items?.length === newData.length ",slipList?.items?.length === newData.length)
                        console.log("ids_list rl16SlipIds.slipIds ",rl16SlipIds.slipIds)
                        console.log("ids_list newData",newData)
                        console.log("ids_list",ids_list)
                    
                    setIsLoadingData(true);
                    try {
                      const tempResponse: any =
                        await T4SlipService.submitTemporaryData(
                          ids_list
                        );
                    const SubmitActualDataApi = async () => {
                      try {
                        const response: any =
                        await PrintingService.createBulkRL16PdfEmail(
                          clientId,
                          tempResponse.id,
                          taxYear
                        );
                      } catch (error) {
                        console.error("Error fetching data:", error);
                      }
                    };
                    SubmitActualDataApi();
                    setSnackbarData({
                      open: true,
                      message: "Email sending started!",
                      severity: "success",
                    });
                    setOpenProgressBarDialog(true)
                    //manage progress bar
                    let _val = percentageValue;
                    let consecutiveSameValueCount = 0; // Counter for consecutive same values
                  let lastAdjustedValue:any = null; // To track the last value
                    const fetchData = async () => {
                      try {
                        const response: any = await T4SlipService.temporaryItemsCount(tempResponse.id);
                        console.log("12345789", response);
                        console.log("12345789ff", response.fail);
                        console.log("12345789tt", tempResponse.totalFetched);
                        _val = Math.floor((response.counter / tempResponse.totalFetched) * 100); 
                        console.log("_val_val",_val)
                        setPercentageValue(_val);
                    // Check for consecutive same values
                    if (response.counter === lastAdjustedValue) {
                      consecutiveSameValueCount++;
                    } else {
                        consecutiveSameValueCount = 0; // Reset if value changes
                    }
                    lastAdjustedValue = response.counter;

                    if (consecutiveSameValueCount >= 4) {
                        console.log("Same value detected for 4 iterations, reloading screen.");
                        setSnackbarData({
                          open: true,
                          message: `Something went wrong, please try again later.`,
                          severity: "error",
                        });
                        setTimeout(()=>{
                          window.location.reload();
                        },2000)
                    }
                        if (response.counter >= tempResponse.totalFetched) {
                          clearInterval(interval.current);
                          setPercentageValue(100)
                          
                          let totalSuccessfullImportedItems = response.counter - response.fail
                          setSnackbarData({
                            open: true,
                            message: `Email sent successfully to ${totalSuccessfullImportedItems} T3 Beneficiary(s) except following. ${response.name}`,
                            severity: "success",
                          });
                          // Set up the interval
                          interval.current = setInterval(() => {
                            setOpenProgressBarDialog(false)
                            // redirectToPage(CSVInformationUI.tag);
                            clearInterval(interval.current);
                          }, 3000);
                        }
                    
                    
                      } catch (error) {
                        console.error("Error fetching data:", error);
                      }
                    };
                    
                    // Set up the interval
                    interval.current = setInterval(() => {
                      fetchData();
                    }, 4000);
        //     setIsLoadingData(true);
        // const ids_list: any = newData.map((ids:any) => `slip_ids=${ids}`).join('&');
        // const pdfIds = ids_list.replace(/^&+|&+$/g, '')
        // setIsLoadingData(true);
        // try {
        //   const response: any = await PrintingService.createBulkRL16PdfEmail(clientId, pdfIds, taxYear);
        //   if(response.status === 400){
        //     setSnackbarData({
        //       open: true,
        //       message: response.data.message ? response.data.message : "Something went wrong.",
        //       // message: "Please Unmask sensitive data for PDF printing.",
        //       severity: "error",
        //     });
        //   }else if(response.status === 201){
        //     setSnackbarData({
        //       open: true,
        //       message: response.data.message ? response.data.message : "Email send Successfully.",
        //       severity: "success",
        //     });
        //   } else {
        //     setSnackbarData({
        //       open: true,
        //       message: "Something Went Wrong.",
        //       severity: "error",
        //     })
        //   }
        //   setIsLoadingData(false);
        } catch (err) {
          console.log("err",err)
          alert(err)
        }
      // }
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      setSnackbarData({
      open: true,
      message: (
        <>
         Please load Audit in the menu, press Run RL-16 Audit and clear all audit items to generate documents.{" "}
          <a
            href="https://appv3.taxslips.com/#/audit/filing"
            style={{
              color: "#aa1010",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Click here to view
          </a>
        </>
      ),
      severity: "error",
      autoHide: false,
    });
  }

        } else if (
          response?.id != null &&
          previousYearInvoiceResponse?.id != null
        ) {
          console.log("one");
          setInvoiceData([previousYearInvoiceResponse, response]);
          setInvoiceDialogOpen(true);
        } else if (
          previousYearInvoiceResponse?.id != null &&
          response?.message === "Slips not found for invoice."
        ) {
          console.log("two");
          setInvoiceData([previousYearInvoiceResponse]);
          setInvoiceDialogOpen(true);
        } else if (
          response?.id != null &&
          previousYearInvoiceResponse?.message === "Slips not found for invoice."
        ) {
          console.log("three");
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        }else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    } else {
    try {
      const response:any = await ReportsService.createInvoice(clientId,taxYear);
      setIsLoadingData(false);
      console.log("responseresponse",response)
      if(response.message === "Slips not found for invoice."){
        // setIsLoadingData(true);
        // try {
        //   const filingAuditResponse:any = await RL16SlipService.getRL16AuditFiling(clientId,taxYear);
        //   console.log("filingAuditResponse",filingAuditResponse)
        //   if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
        //     console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
        //     setSnackbarData({
        //       open: true,
        //       message: (
        //         <>
        //           Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
        //           <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
        //                                           textDecoration:"none",
        //                                           fontWeight:"bold"}}>Click here to view</a>
        //         </>
        //       ),
        //       severity: "error",
        //       autoHide:false
        //     });
        //   } else if(filingAuditResponse && filingAuditResponse.RL16_filling && filingAuditResponse.RL16_filling.length > 0){
        //     console.log("filingAuditResponse.RL16_filling",filingAuditResponse.RL16_filling)
        //     setSnackbarData({
        //       open: true,
        //       message: (
        //         <>
        //           Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
        //           <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
        //                                           textDecoration:"none",
        //                                           fontWeight:"bold"}}>Click here to view</a>
        //         </>
        //       ),
        //       severity: "error",
        //       autoHide:false
        //     });
        //   } 
          // else if(filingAuditResponse && filingAuditResponse.RL16_warnings && filingAuditResponse.RL16_warnings.length > 0){
          //   // Check if a string contains the word "email"
          //   const containsEmail = (str: string) => str.toLowerCase().includes('email is missing');
          //   const containsDOB = (str: string) => str.toLowerCase().includes('date of birth is missing');

          //   console.log("containsEmail",containsEmail)

          //   // Iterate over the array and log the string if it contains the word "email"
          //   filingAuditResponse && filingAuditResponse.RL16_warnings.forEach((str:any) => {
          //     if (containsEmail(str)) {
          //       console.log("strstrstr email",str);
          //       setSnackbarData({
          //         open: true,
          //         message: (
          //           <>
          //             {str}{' '}
          //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
          //                                             textDecoration:"none",
          //                                             fontWeight:"bold"}}>Click here to view</a>
          //           </>
          //         ),
          //         severity: "error",
          //         autoHide:false
          //       });
          //     } else if (containsDOB(str)) {
          //       console.log("strstrstr dob",str);
          //       setSnackbarData({
          //         open: true,
          //         message: (
          //           <>
          //             {str}{' '}
          //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
          //                                             textDecoration:"none",
          //                                             fontWeight:"bold"}}>Click here to view</a>
          //           </>
          //         ),
          //         severity: "error",
          //         autoHide:false
          //       });
          //     }
          //   });
            // setSnackbarData({
            //   open: true,
            //   message: (
            //     <>
            //       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
            //       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
            //                                       textDecoration:"none",
            //                                       fontWeight:"bold"}}>Click here to view</a>
            //     </>
            //   ),
            //   severity: "error",
            //   autoHide:false
            // });
          // } 
        // else {
          if(rl16SlipIds && rl16SlipIds.generalAuditStatus !== null && rl16SlipIds.generalAuditStatus === true &&
            rl16SlipIds && rl16SlipIds.auditStatus !== null &&  rl16SlipIds.auditStatus === true){
              const ids_list = 
                    (slipList?.items?.length === newData.length) 
                        ? {items:rl16SlipIds.slipIds} 
                        : {items:newData};
                        console.log("slipList?.items?.length === newData.length ",slipList?.items?.length === newData.length)
                        console.log("ids_list rl16SlipIds.slipIds ",rl16SlipIds.slipIds)
                        console.log("ids_list newData",newData)
                        console.log("ids_list",ids_list)
                    
                    setIsLoadingData(true);
                    try {
                      const tempResponse: any =
                        await T4SlipService.submitTemporaryData(
                          ids_list
                        );
                    const SubmitActualDataApi = async () => {
                      try {
                        const response: any =
                        await PrintingService.createBulkRL16PdfEmail(
                          clientId,
                          tempResponse.id,
                          taxYear
                        );
                      } catch (error) {
                        console.error("Error fetching data:", error);
                      }
                    };
                    SubmitActualDataApi();
                    setSnackbarData({
                      open: true,
                      message: "Email sending started!",
                      severity: "success",
                    });
                    setOpenProgressBarDialog(true)
                    //manage progress bar
                    let _val = percentageValue;
                    let consecutiveSameValueCount = 0; // Counter for consecutive same values
                  let lastAdjustedValue:any = null; // To track the last value
                    const fetchData = async () => {
                      try {
                        const response: any = await T4SlipService.temporaryItemsCount(tempResponse.id);
                        console.log("12345789", response);
                        console.log("12345789ff", response.fail);
                        console.log("12345789tt", tempResponse.totalFetched);
                        _val = Math.floor((response.counter / tempResponse.totalFetched) * 100); 
                        console.log("_val_val",_val)
                        setPercentageValue(_val);
                        // Check for consecutive same values
                        if (response.counter === lastAdjustedValue) {
                          consecutiveSameValueCount++;
                        } else {
                            consecutiveSameValueCount = 0; // Reset if value changes
                        }
                        lastAdjustedValue = response.counter;

                        if (consecutiveSameValueCount >= 4) {
                            console.log("Same value detected for 4 iterations, reloading screen.");
                            setSnackbarData({
                              open: true,
                              message: `Something went wrong, please try again later.`,
                              severity: "error",
                            });
                            setTimeout(()=>{
                              window.location.reload();
                            },2000)
                        }
                    
                        if (response.counter >= tempResponse.totalFetched) {
                          clearInterval(interval.current);
                          setPercentageValue(100)
                          
                          let totalSuccessfullImportedItems = response.counter - response.fail
                          setSnackbarData({
                            open: true,
                            message: `Email sent successfully to ${totalSuccessfullImportedItems} T3 Beneficiary(s) except following. ${response.name}`,
                            severity: "success",
                          });
                          // Set up the interval
                          interval.current = setInterval(() => {
                            setOpenProgressBarDialog(false)
                            // redirectToPage(CSVInformationUI.tag);
                            clearInterval(interval.current);
                          }, 3000);
                        }
                    
                    
                      } catch (error) {
                        console.error("Error fetching data:", error);
                      }
                    };
                    
                    // Set up the interval
                    interval.current = setInterval(() => {
                      fetchData();
                    }, 4000);
        //     setIsLoadingData(true);
        // const ids_list: any = newData.map((ids:any) => `slip_ids=${ids}`).join('&');
        // const pdfIds = ids_list.replace(/^&+|&+$/g, '')
        // setIsLoadingData(true);
        // try {
        //   const response: any = await PrintingService.createBulkRL16PdfEmail(clientId, pdfIds, taxYear);
        //   if(response.status === 400){
        //     setSnackbarData({
        //       open: true,
        //       message: response.data.message ? response.data.message : "Something went wrong.",
        //       // message: "Please Unmask sensitive data for PDF printing.",
        //       severity: "error",
        //     });
        //   }else if(response.status === 201){
        //     setSnackbarData({
        //       open: true,
        //       message: response.data.message ? response.data.message : "Email send Successfully.",
        //       severity: "success",
        //     });
        //   } else {
        //     setSnackbarData({
        //       open: true,
        //       message: "Something Went Wrong.",
        //       severity: "error",
        //     })
        //   }
        //   setIsLoadingData(false);
        } catch (err) {
          console.log("err",err)
          alert(err)
        }
      // }
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      setSnackbarData({
      open: true,
      message: (
        <>
         Please load Audit in the menu, press Run RL-16 Audit and clear all audit items to generate documents.{" "}
          <a
            href="https://appv3.taxslips.com/#/audit/filing"
            style={{
              color: "#aa1010",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Click here to view
          </a>
        </>
      ),
      severity: "error",
      autoHide: false,
    });
  }
    // } catch(err:any) {
    //   setIsLoadingData(false);
    //   alert(err)
    // }
      } else if(response && response.id !== null) {
        setInvoiceData([response])
        setInvoiceDialogOpen(true)
      }else{
        setSnackbarData({
          open: true,
          message: "Something went wrong.",
          severity: "error",
        });
      }
      setIsLoadingData(false);
    } catch (err) {
      console.log("err",err)
    }
    }
  }
  }
}
}

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusValue(event.target.value as string);
    setApiAction("updateStatus");
    setDialogOpen(true);
  };

  const onRowMultipleDeleteClicked = (params: any) => {
    console.log("Params in onRowMultipleDeleteClicked ====== ", params);
    let bulkDeletePayload = {
      ids:{"slipIds":params},
      clientId,
      taxYear
    }
    setDialogOpen(true);
    setBulkDelete(true);
    setApiAction("delete");
    setBulkRL16SlipsId(bulkDeletePayload);
    setBulkRL16SlipsDelete(true);
  };

  const selectionToolbar = useCallback(
    (selectedItems) => {
      setSelectedIds(selectedItems);
      newData = selectedItems
      console.log("selectedItems--",selectedItems)
      if (selectedItems.length) {
        return (
          <Box sx={{ marginTop: theme.spacing(1), display:"flex",justifyContent:"center",alignItems:"center" }}>
            {slipList && slipList?.items.length === selectedItems.length ?
            (<Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block", "& .MuiPopper-root":{zIndex:2}}}>
            <Button variant="outlined" startIcon={<Print />} onClick={onAllSlipsPrintClicked}>
              Print
            </Button>
          </Box>)
          :(<Box sx={{ marginRight:"0.5rem", textTransform:"capitalize", display:"inline-block"}}>
          <Button variant="outlined" startIcon={<Print />} onClick={onPrintClicked}>
            Print
          </Button>
          {/* <PrintButton
            onClickHandler={onPrintClicked}
            testId="RL16-Slips-print-button" label={"Print"}
            /> */}
        </Box>)}
            <Box sx={{ 
              marginRight:"0.5rem", 
              textTransform:"capitalize", 
              display:"inline-block",
              "& .MuiPopper-root":{zIndex:2}}}>
            <SplitButton
                  options={[
                    "Original",
                    "Amended",
                    "Cancelled",
                  ]}
                  onClickHandler={onGenerateXMLClicked}
                  testId="RL16-print-button"
                  icon={<ArticleIcon />}
                  listItemsLabel={`Generate XML  `}
                />
            </Box>
            
            
            <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
              <Button variant="outlined" startIcon={<EmailIcon />} onClick={onEmailButtonClicked}>
                Email
              </Button>
            </Box>
            <Button
                onClick={() => onRowMultipleDeleteClicked(selectedItems)}
                variant="contained"
                color="error"
              >
                Delete
            </Button>
          </Box>
        );
      }
      return (
        <Box sx={{ marginTop: theme.spacing(1),display:"flex" }}>
          <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
          {AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? 
              <Tooltip title="Remittance has been transferred.">
                <span>
                  <Button startIcon={<ExitToAppIcon/>} disabled>Import CSV</Button>
                </span>
              </Tooltip>
              :
            <EnhancedLinkButton text={"Import CSV"} icon={<ExitToAppIcon/>} navigateTo={"/taxslip/rl16/import"} testId={'import-rl16-slip-button'}/>
            }
          </Box>
          <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
          {AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? 
            <Tooltip title="Remittance has been transferred.">
            <span>
              <Button startIcon={<ExitToAppIcon/>} disabled>Import XML</Button>
            </span>
          </Tooltip>
          :
            <EnhancedLinkButton text={"Import XML"} icon={<ExitToAppIcon/>} navigateTo={"/taxslip/rl16/import-xml"} testId={'import-rl16-slip-button'}/>
          }
          </Box>

          {AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? 
            <Tooltip title="Remittance has been transferred.">
              <span>
                <Button variant="outlined" disabled >Add RL-16 Slip</Button>
              </span>
            </Tooltip>
            :
          <RouterLinkButton to="/taxslip/rl16/setup" text="Add RL-16 Slip" />
          }
        </Box>
      );
    },
    [theme,selectedRowsValues]
  );

  const onUnPaidClickHandler = (clickedRow: any, tag:string) => {
    setIsLoadingData(true);
    // setTimeout(() => {
    //   setIsLoadingData(false);
    // }, 2500);
    const fetchData = async () => {
      try {
        const response: any =
        await ReportsService.paypalIntegrationOfUnpaidInvoice(id,clickedRow.refNo,tag);
        setIsLoadingData(false);
        console.log("responseData111-----------------",response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const onClickInvoiceNoHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:RL16_LIST_SCREEN_SLUG,
      companyName:clientName,
      activityType:"activity_type=delete&activity_type=emailed&activity_type=print&activity_type=import_xml&activity_type=import_csv",
      activityLogVisibility:true
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[])

  const image =
  "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";
  
  return slipList && slipList.items.length === 0 && zeroClient === true ? (
    <EmptyList 
    showImage={true}
    imagePath= {image}
    heading={`There's nothing! Add RL-16 Slip to proceed`} 
    content={`You can add RL-16 slip by directly clicking on add button or by importing csv file.`} 
    testId={"RL-16slip-empty-list"} 
    showAddButton= {true}
    addButtonLabel={`Add RL-16 Slip`}
    navigateToAdd={"/taxslip/rl16/setup"}
    showImportButton={true}
    importButtonLabel="Import CSV"
    navigateToImport={"/taxslip/rl16/import"}
    // showImportXmlButton={true}
    // importXmlButtonLabel="Import XML"
    // navigateToImportXml={"/taxslip/RL16/import-xml"}
    />
  ) : (
    <>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
      <BackdropCircularProgress
          open={
            isBulkDeleting || isDeleting || isLoadingData || isLoadingAdjustments ||isLoadingActivityLogData
          }
          testId="RL16 Slip List"
        />
      <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={bulkRL16SlipsDelete === true ? deleteBulkRL16SlipsField : deleteRL16SlipField}
          cancelActionHandler={onCloseHandler}
          testId="RL16-confirmation-dialog"
        />
      {/* <EnhancedDataGrid
        columns={slipList ? slipList.headerColumns : []}
        rows={slipList && RL16SlipListUpdatedItems ? RL16SlipListUpdatedItems : []}
        selectionEnabled={true}
        autoHeight={true}
        allowSearching={true}
        onSearchButtonClicked={onSearchButtonClicked}
        searchFieldPlaceholder={"Search by Employee Name..."}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        selectionToolbar={selectionToolbar}
        selectedRowsValues={(value:any)=>setSelectedRowsValues(value)}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        // initialPage={0}
        initialPageSize={INITIAL_PAGE_SIZE}
        disableColumnMenu={true}
        showRowActionsColumn={true}
        autoPageSize={false} //
        showIndexColumn={true}
        testId="resource-list-grid"
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onRequestParamsChange={onRequestParamsChange}
        onSearchCrossClicked={(value) => setzeroClient(value)}
        totalRowsCount={slipList?.total}
        isLoading={isLoading || isAxiosCallLoading}
        rowIdGetter={(row) => row.id}
        searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
        shouldMask={false}
      /> */}
      <PrimeDataGrid 
        columns={slipList ? slipList.headerColumns : []}
        rows={slipList && RL16SlipListUpdatedItems ? RL16SlipListUpdatedItems : []}
        showIndexColumn={true}
        showChecboxColumn={true}
        showSettingColumn={true}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        isLoading={isLoading || isAxiosCallLoading}
        onSnackbarMessageData={(value: any) => setSnackbarData(value)}
        totalRecords={slipList && slipList.total ? slipList.total : 0}
        initialPageSize={INITIAL_PAGE_SIZE}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        onRequestParamsChange={onRequestParamsChange}
        selectionToolbar={selectionToolbar}
        selectedRowsValues={(value:any)=>setSelectedRowsValues(value)}
        searchFieldPlaceholder={"Search by Employee Name..."}
        changeLoginForGetEmptyItemsBySearchQuery={(value:any)=>setzeroClient(value)}
       />
      {/* invoice dialogu open if any transaction is unPaid */}
      <EnhancedDialog
            dialogTitle="Invoice(s) Due"
            key="dialog-box"
            testId="dialog"
            onCloseHandler={onCloseHandler}
            open={openInvoiceDialog}
            dialogContent={
              <InvoiceDialogPanel 
              invoiceData={invoiceData} 
              onUnPaidClickHandler={onUnPaidClickHandler}
              onClickInvoiceNoHandler={onClickInvoiceNoHandler}
              />
            }
            dialogAction={
              <Grid>
                <Button
                  autoFocus
                  variant="contained"
                  onClick={onCloseHandler}
                >
                  Cancel
                </Button>
              </Grid>
            }
          />

          {/* Email Send Dialog */}
        <EnhancedDialog
            dialogTitle={`Email sending`}
            key="dialog-box-1"
            testId="dialog"
            onCloseHandler={onCloseHandler}
            showCloseButton={false}
            open={openProgressBarDialog}
            dialogContent={
              <ProgressBar 
                value={percentageValue}
                pt={{
                    value: { style: { background: 'linear-gradient(to right, #8e2de2, #4a00e0)' } }
                }}
              ></ProgressBar>
            }
            dialogAction={
              <></>
            }
          />
    </Grid>
    <EnhancedSnackbar
    message={snackbarData.message}
    onCloseHandler={onSnackbarClosed}
    severity={snackbarData.severity}
    testId={"RL16-list-snackbar"}
    open={snackbarData.open}
    autoHide={snackbarData.autoHide}
  />
  </>
  );
};
