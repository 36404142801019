import { AxiosResponse } from "axios";
import React, { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid } from "@mui/material";
import {
  ITest,
  EnhancedDialog,
  EnhancedSnackbarAlert,
  RHookFormPasswordFieldWithLabel,
  SubmitButton,
} from "@websential/cosmic";
import http from "common/http-common";
import { useNavigate } from "react-router-dom";

// import http from "../../common/http-common";
// import { EnhancedDialog } from "../EnhancedDialog";
// import { EnhancedSnackbar } from "../EnhancedSnackbar";
// import { ITest } from "../../interfaces";
// import { RHookFormPasswordFieldWithLabel } from "../RHookFormPasswordFieldWithLabel";
// import { SubmitButton } from "../SubmitButton";
// import { EnhancedSnackbarAlert } from "../EnhancedSnackbarAlert";

export interface IMaskUnmask extends ITest {
  /**
   * If true, component will show loading state.
   */
  PostApiUrl: string;
  /**
   * for mask data when user click button with text 'Mask'.
   */
  PutApiUrl: string;
  /**
   * If true, component will show error state.
   */
  UnsetMaskFlagTimeoutInMinutes: number;

  homePathRoute:string;
}

export interface IPostPayload {
  password: string;
}

export interface IServerError {
  status: boolean;
  message: string;
  error: boolean;
}

export interface IResponse {
  status: boolean;
  message: string;
}
export interface IResponseTimeOut {
  status: boolean;
  masked_status: boolean;
  message: string;
}

export const ApiService = {
  verify: async (
    data: IPostPayload,
    url: string
  ): Promise<IPostPayload | IServerError | IResponse> => {
    const response: AxiosResponse<IPostPayload> = await http.post<IPostPayload>(
      url,
      data
    );
    if (response.status === 200) return response.data;
    else if (response.status === 400) return response.data;
    return {
      status: false,
      message: "Something went wrong please try again later.",
      error: true,
    };
  },
  verifyTimeLimit: async (
    // data: IPostPayload,
    url: string
  ): Promise<IPostPayload | IServerError | IResponse> => {
    const response: AxiosResponse<IPostPayload> = await http.put<IPostPayload>(
      url
      // data
    );
    if (response.status === 200) return response.data;
    else if (response.status === 400) return response.data;
    return {
      status: false,
      message: "Something went wrong please try again later.",
      error: true,
    };
  },
};

export const MaskUnmask: FC<IMaskUnmask> = ({
  PostApiUrl,
  PutApiUrl,
  UnsetMaskFlagTimeoutInMinutes,
  homePathRoute,
  testId,
}) => {
  const schema = yup.object().shape({
    password: yup
      .string()
      .required("Password is required.")
      .min(8, "Invalid password.")
      .max(100, "Invalid password."),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [isError, setError] = React.useState<boolean>(false);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");

  const [open, setOpen] = React.useState(false);

  const [buttonLabel, setButtonLabel] = React.useState<string>("");

  const fetchApiDataAsync = async (data: IPostPayload): Promise<void> => {
    try {
      setLoading(true);
      const response = await ApiService.verify(data, PostApiUrl);
      const responseData = response as IResponse;
      const responseError = response as IServerError;

      if (responseError.error) {
        setMessage(responseError.message);
        setError(true);
      } else {
        if (responseData.status) {
          setButtonLabel("Mask");
          window.sessionStorage.setItem("maskEnabled", "false");
          const unmaskTime = new Date();
          window.sessionStorage.setItem("unmaskTime", unmaskTime.toString());
          setMessage(
            "Success, data will remain unmasked for next " +
              UnsetMaskFlagTimeoutInMinutes +
              " minutes"
          );
          setError(false);
          setOpen(false);
          methods.reset();
          // navigate(homePathRoute);
          window.location.reload()
        } else {
          window.sessionStorage.setItem("maskEnabled", "true");
          setButtonLabel("Unmask");
          setError(true);
          setMessage(responseData.message);
        }
        setShowMessage(true);
      }
      setLoading(false);
    } catch (err) {
      setError(true);
      setMessage("Something went wrong please try again later.");
      setLoading(false);
    }
  };
  const fetchApiDataAsyncTimeOut = async (): Promise<void> => {
    console.log("mask clicked");
    try {
      setLoading(true);
      const response = await ApiService.verifyTimeLimit(PutApiUrl);
      const responseData = response as IResponseTimeOut;
      const responseError = response as IServerError;
      console.log("mask clicked in try");

      if (responseError.error) {
        setMessage(responseError.message);
        setError(true);
      } else {
        if (responseData.status) {
          setButtonLabel("UnMask");
          window.sessionStorage.setItem("maskEnabled", "true");
          // const unmaskTime = new Date();
          // window.sessionStorage.setItem("unmaskTime", unmaskTime.toString());
          setMessage(responseData.message);
          // navigate(homePathRoute);
          window.location.reload()
        }
      }
    } catch (err) {
      setError(true);
      setMessage("Something went wrong please try again later.");
      setLoading(false);
    }
  };

  var add_minutes = function (dt: any, minutes: any) {
    return new Date(dt.getTime() + minutes * 60000);
  };

  useEffect(() => {
    // Get saved maskEnabled from sessionStorage
    let maskEnabledStorage = sessionStorage.getItem("maskEnabled") || "";
    // Set the storage key in case key does not exist in session
    window.sessionStorage.setItem("maskEnabled", maskEnabledStorage);

    if (maskEnabledStorage !== "" && maskEnabledStorage === "false") {
      setButtonLabel("Mask");
    } else {
      setButtonLabel("Unmask");
    }

    const interval = setInterval(() => {
      const unmaskedTime = window.sessionStorage.getItem("unmaskTime");
      if (unmaskedTime) {
        const maskedTime = add_minutes(
          new Date(unmaskedTime),
          UnsetMaskFlagTimeoutInMinutes
        );
        if (new Date() > new Date(maskedTime)) {
          window.sessionStorage.setItem("maskEnabled", "true");
          sessionStorage.removeItem("unmaskTime");
          setButtonLabel("Unmask");
          fetchApiDataAsyncTimeOut();
          // navigate(homePathRoute);
          // window.location.reload()
        }
      }
    }, 1000 * 30); // its half 30 second interval
    return () => clearInterval(interval);
  }, []);

  const onSubmit = (data: any) => {
    setLoading(true);
    if (buttonLabel === "Unmask") {
      fetchApiDataAsync(data);
    }
  };

  const handleClose = (_event?: React.SyntheticEvent, _reason?: string) => {
    setMessage("");
    setShowMessage(false);
  };

  const onOpenHandler = () => {
    methods.reset();
    if (buttonLabel === "Unmask") {
      setOpen(true);
    } else {
      window.sessionStorage.setItem("maskEnabled", "true");
      setButtonLabel("Unmask");
      sessionStorage.removeItem("unmaskTime");
    }
    if (buttonLabel === "Mask") {
      fetchApiDataAsyncTimeOut();
    }
  };

  const onCloseHandler = () => {
    setOpen(false);
    setMessage("");
    setShowMessage(false);
    methods.reset();
  };

  return (
    <Grid data-testid={testId}>
      {showMessage && (
        <EnhancedSnackbarAlert
          showMessage={showMessage}
          isError={isError}
          message={message}
          autoHideDuration={5000}
          handleClose={handleClose}
        />
      )}
      <Button
        color="primary"
        data-testid={"mask-unmask-button"}
        onClick={onOpenHandler}
        variant="contained"
        style={{
          textTransform: "capitalize",
        }}
      >
        {buttonLabel}
      </Button>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <EnhancedDialog
            dialogTitle="Verify your password to unmask data"
            key="dialog-box"
            testId="dialog"
            onCloseHandler={onCloseHandler}
            open={open}
            dialogContent={
              <RHookFormPasswordFieldWithLabel
                id="password"
                testId="password"
                label="Password"
                placeholder="Please enter your password"
                required={true}
              />
            }
            dialogAction={
              <SubmitButton
                label="Verify"
                testId="Verify"
                loading={isLoading}
                onClickHandler={methods.handleSubmit(onSubmit)}
              />
            }
          />
        </form>
      </FormProvider>
    </Grid>
  );
};
