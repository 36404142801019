import { BillingProfileList } from "components/BillingProfile";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IBillingProfileListPage {}

export const BillingProfileListPage: FC<IBillingProfileListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Billing Profile List"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Billing Profile', navigateTo: '/billing-profile/list'}]}
      mainContents={<BillingProfileList />}
    />
  );
};
