import { T3SlipSummarySetup } from "components/T3Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT3SlipSummarySetupPage {}

export const T3SlipSummarySetupPage: FC<IT3SlipSummarySetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T3 Summary"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t3/list" },
        { text: "T3", navigateTo: "/taxslip/t3/list" },
        { text: "T3 Summary", navigateTo: "/taxslip/t3/summary" },
      ]}
      mainContents={<T3SlipSummarySetup testId="t3-summary-setup" />}
    />
  );
};
