import { ChangePassword } from "components/ChangePassword";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { APIKeys } from "components/APIKeys";

export const APIKeysPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="API Keys"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Api Keys', navigateTo: '/me/api'}]}
      mainContents={<APIKeys testId="api-keys-setup-page" />}
    />
  );
};
