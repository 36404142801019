import { AxiosResponse } from 'axios';
import http from 'common/http-common';
import { IGetListRequest, IResponse, IServerError } from 'models/common';
import {
    IFlexFieldTabListModel, IFlexFieldTabSetupRequest, IFlexFieldTabSetupResponse
} from 'models/flexFieldTab';

// TODO: replace this with API response once it's ready
export const FlexFieldTabService = {
  create: async (requestPayload: IFlexFieldTabSetupRequest): Promise<IFlexFieldTabSetupResponse | IServerError | IResponse> => {
    if (requestPayload.id) {
      console.log('requestPayload ==>> update request', requestPayload);
      const response: AxiosResponse<IFlexFieldTabSetupResponse> = await http.put<IFlexFieldTabSetupResponse>(
        `/v1/flex-field-tabs/${requestPayload.id}/`, requestPayload);

      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }

    console.log('requestPayload ==>> add request', requestPayload);
    const response: AxiosResponse<IFlexFieldTabSetupResponse> = await http.post<IFlexFieldTabSetupResponse>(
      '/v1/flex-field-tabs/', requestPayload);

    if (response.status === 201) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getList: async (
    params: IGetListRequest
  ): Promise<
    IFlexFieldTabListModel[] | IServerError | IResponse
  > => {
    const response: AxiosResponse<IFlexFieldTabListModel[]> = await http.get<IFlexFieldTabListModel[]>(
      '/v1/flex-field-tabs/');

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getById: async (id: string | undefined): Promise<IFlexFieldTabSetupResponse | IServerError | IResponse | null> => {
    if (id) {
      const response: AxiosResponse<IFlexFieldTabSetupResponse> = await http.get<IFlexFieldTabSetupResponse>(
        `/v1/flex-field-tabs/${id}/`);
  
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }

    return null;
  },
  deleteById: async (flexFieldTabId: number): Promise<string | IServerError | IResponse> => {
    console.log('deleteById', flexFieldTabId);
    // return new Promise(resolve => setTimeout(() => resolve("Flex Field has been deleted successfully"), 1000));
    const response: AxiosResponse<string> = await http.delete<string>(`/v1/flex-field-tabs/${flexFieldTabId}/`);

    if (response.status === 204) {
      return "Flex Field Tab has been deleted successfully";
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
};
