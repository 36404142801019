import { Box, Theme, createStyles } from "@mui/material";
import { AccountLayout } from "layouts";
import { FC, useEffect } from "react";
import { AuthService } from "services/AuthService";
import { Link } from 'react-router-dom';
import { Button, Typography, Container } from '@mui/material';
import {  makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    // textAlign: 'center',
    // marginTop: '100px',
    // margin:0,
    background:"#eeeeee",
    // margin: theme.spacing(2),
    textAlign:"center",
    height:"100vh",
    paddingTop:"1rem"
  },
  container: {
    textAlign: 'center',
    // paddingTop: '100px',
  },
  heading: {
    fontSize: '3rem',
    paddingBottom: theme.spacing(2),
  },
  text: {
    fontSize: '1.2rem',
    paddingBottom: theme.spacing(4),
  },
}));
const imagePath =
  "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";
export interface INoFoundPage{}
export const NoFoundPage: FC<INoFoundPage> = ({}) => {
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <img
            src={imagePath}
            alt="Not found"
          />
      <Box className={classes.container}>
        <Typography variant="h2" className={classes.heading}>
          Oops! Page Not Found
        </Typography>
        <Typography variant="body1" className={classes.text}>
          The page you are looking for doesn't exist or has been moved.
        </Typography>
        <Button component={Link} to="/" variant="contained" color="primary" className="no-underline">
          Go back to home
        </Button>
      </Box>
    </Box>
  );
};
