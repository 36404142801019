import {
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  RL1_SETTINGS_SCREEN_ID,
  RL1_SETTINGS_SCREEN_SLUG,
} from "common/constants";
import { instanceOfIFlexFieldSetupRequest } from "common/instance-method";
import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { IGetListRequest, IResponse, IServerError } from "models/common";
import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FlexFieldService } from "services/FlexFieldService";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BackdropCircularProgress,
  EnhancedDropDownRHFWithLabel,
  EnhancedIOSSwitchRHFWithLabel,
  EnhancedSnackbar,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  RHookFormMaskedTextFieldWithLabel,
  RHookFormTextFieldWithLabel,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  TwoColumnLayout,
} from "@websential/cosmic";

import { getValidationSchema } from "./validator";
import { TaxSlipYearService } from "services";
import { T4SlipService } from "services/T4SlipService";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { RL1SlipSettingValidation } from "./RL1SettingValidation";
import { RL1SlipService } from "services/RL1SlipService";
import { Grid } from "@mui/material";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IRL1SlipSettingsSetup extends ITest {}

const DEFAULT_VALUES = {
  // comparison: [
  //   {
  //     operator: undefined,
  //     field: undefined,
  //   },
  // ],
  // fieldType: undefined,
  // fieldLabel: "",
  // fieldId: "",
  // defaultValue: "",
  // placeholder: "",
  // fieldFormat: "",
  // fieldValue: "",
  // dataType: undefined,
  // fieldColumn: undefined,
  // parentRelation: undefined,
  // fieldInternalValue: "",
  // notes: "",
  // minValue: 0,
  // maxValue: 0,
  // isCategory: false,
  // isFixed: false,
  // isHide: false,
  // isRequired: false,
  // parentValue: "",
  // parent: undefined,
  // id: undefined,
};

// const FLEX_FIELD_DATA_QUERY_KEY = "get-flex-field-data";
// const LIST_FIELDS_QUERY_KEY = "get-flex-fields-list";

// const useGetFlexFieldData = (flexFieldId: number) =>
//   useQuery(
//     [FLEX_FIELD_DATA_QUERY_KEY, flexFieldId],
//     () => FlexFieldService.getFlexFieldById(flexFieldId),
//     { ...QUERY_OPTIONS, enabled: !!flexFieldId }
//   );
// const useGetFlexFieldsList = () =>
//   useQuery(
//     LIST_FIELDS_QUERY_KEY,
//     () => FlexFieldService.getFlexFieldDropDownList("none"),
//     QUERY_OPTIONS
//   );

//Updted code starts from here
// Taxslip Year List
const useYears = (params: IGetListRequest) =>
  useQuery(
    ["years-list", params],
    (data) => {
      return TaxSlipYearService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

const useGetTransmitterDDL = (clientId: string, taxYear: number) =>
  useQuery(
    ["transmitters"],
    () => {
      return T4SlipService.getAllActiveTransmittersByClientIdAndTaxYear(
        clientId,
        taxYear
      );
    },
    { refetchOnWindowFocus: false }
  );
const RL1_SETTING_DATA_QUERY_KEY = "get-rl1-setting-data";
const useGetRL1SettingData = (clientid: string, taxYear: number) =>
  useQuery(
    [RL1_SETTING_DATA_QUERY_KEY, clientid, taxYear],
    (data) => {
      return RL1SlipService.getRL1SettingData(clientid, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

export const RL1SlipSettingsSetup: FC<IRL1SlipSettingsSetup> = ({ testId }) => {
  const dispatch = useDispatch();
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );

  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [isLoadingData, setIsLoadingData] = useState(false);
  // const queryClient = useQueryClient();

  //new code
  const id = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [selectedYear, setSelectedYear] = useState(taxYear);

  const { isLoading: isSubmitting, mutate: submitRL1SettingData } = useMutation(
    RL1SlipService.rl1SettingCreate,
    {
      onSuccess: (res: any | IServerError | IResponse) => {
        // if (instanceOfIFlexFieldSetupRequest(res)) {
        // // remove the cache of the flex fields since we just added a new field.
        // queryClient.invalidateQueries(LIST_FIELDS_QUERY_KEY);
        // // [corner case] remove the cache of the field that has just been edited in-case the user re-selects it again
        // queryClient.invalidateQueries([FLEX_FIELD_DATA_QUERY_KEY, res.id]);
        // // set the currently added/edited flex field as the selected field
        // formMethods.setValue("id", res.id);
        if (res.message) {
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "error",
          });
        } else {
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );
  // const { isLoading: isLoadingFlexFieldsList, data: flexFieldsList } =
  //   useGetFlexFieldsList();
  // const validationSchema = useMemo(
  //   () =>
  //     getValidationSchema(
  //     //   flexFieldsList && Array.isArray(flexFieldsList)
  //     //     ? flexFieldsList.map((flexField: any) => ({
  //     //         id: flexField.id,
  //     //         value: flexField.fieldId,
  //     //       }))
  //     //     : []
  //     ),
  //   [flexFieldsList]
  // );
  const formMethods = useForm<any>({
    resolver: yupResolver(RL1SlipSettingValidation),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  // const [selectedFlexFieldId, selectedParent] = useWatch({
  //   control: formMethods.control,
  //   name: ["id", "parent"],
  // });
  // const { isLoading: isLoadingSelectedFlexField, data: selectedFlexFieldData } =
  //   useGetFlexFieldData(selectedFlexFieldId as number);

  /**
   * Handle when the form is submitted.
   *
   * @param {IFlexFieldSetupRequest} formData
   */
  const onSubmit = (formData: any) => {
    const requestPayload = {
      id: id,
      taxYear: formData.taxYear,
      transmitter: formData.transmitter,
      copyFromPreviousYear: formData.copyFromPreviousYear,
      submissionMethod: parseInt(formData.submissionMethod),
      sourceOfRl1SlipsUsed: formData.sourceOfRl1SlipsUsed,
      sequentialNumberStart: formData.sequentialNumberStart,
      sequentialNumberEnd: formData.sequentialNumberEnd,
      quebecEnterprise:
        formData.quebecEnterprise !== ""
          ? formData.quebecEnterprise
          : undefined,
      identification:
        formData.identification &&
        formData.identification.replace(/(\d{10})(\d{4})/, "$1RS$2"),
    };
    submitRL1SettingData(requestPayload);
    console.log("formData", formData);
    console.log("requestPayload", requestPayload);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset();
  };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // const yearListItems = useMemo(() => {
  //   return [
  //     {
  //       label: "2020",
  //       value: "2020",
  //     },
  //     {
  //       label: "2021",
  //       value: "2021",
  //     },
  //   ];
  //   // if (!flexFieldsList) {
  //   //   return [];
  //   // }
  //   // return flexFieldsList.map((flexField) => ({
  //   //   label: flexField.fieldLabel,
  //   //   value: flexField.id,
  //   // }));
  // }, []);

  // set the values of the selected flex field coming from BE.
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (selectedFlexFieldData) {
  //     Object.keys(selectedFlexFieldData).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = selectedFlexFieldData[fieldName];
  //       // @ts-ignore
  //       formMethods.setValue(fieldName, fieldValue);
  //     });
  //   }
  // }, [selectedFlexFieldData, formMethods.setValue, formMethods]);

  // if we have selected the parent and a flex field and they both are the same, we should un-set the parent because a field cannot be the parent of itself
  // useEffect(() => {
  //   if (
  //     selectedFlexFieldId &&
  //     selectedParent &&
  //     selectedParent === selectedFlexFieldId
  //   ) {
  //     formMethods.setValue("parent", undefined);
  //   }
  // }, [formMethods, selectedFlexFieldId, selectedParent]);

  //new code
  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "role_name",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useYears(queryParams); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const YearsList: any = data;
  console.log("YearsList", YearsList);
  const years = YearsList?.items.map((x: any) => {
    const { year, ...rest } = x; // Extract year property and the rest of the properties
    return { ...rest, value: year, label: year }; // Rename year to value
  });
  console.log("years", years);

  const { isLoading: isLoadingTransmitterDDL, data: transmitterDDL } =
    useGetTransmitterDDL(id, taxYear);
  console.log("transmitterDDL", transmitterDDL);

  const taxYearValue = formMethods.getValues("taxYear");
  const { isLoading: isLoadingSummaryGetData, data: rl1SettingData, refetch: refetchRL1SettingData } =
    useGetRL1SettingData(id, selectedYear);

  console.log("rl1SettingData ============", rl1SettingData);

  //shown geted Data
  useEffect(() => {
    console.log("===-use Effect called");
    // if the selected flex field data has loaded.
    if (rl1SettingData && rl1SettingData.id) {
      Object.keys(rl1SettingData).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = rl1SettingData[fieldName];

        console.log(`fieldNAme : ${fieldName} && fieldValue : ${fieldValue}`);
        // @ts-ignore
        formMethods.setValue(fieldName, fieldValue ?? undefined);
        if(fieldName === "quebecEnterprise"){
          formMethods.setValue(fieldName, fieldValue.toString());
        }
      });
    } else if (rl1SettingData && rl1SettingData.message) {
      setSnackbarData({
        open: true,
        message: rl1SettingData.message.message ? rl1SettingData.message.message : rl1SettingData.message ? rl1SettingData.message : 'Something went wrong!',
        severity: "error",
      });
      formMethods.reset();
      formMethods.setValue('taxYear',selectedYear)
    } else {
      formMethods.reset();
    }
  }, [rl1SettingData, formMethods.setValue, formMethods]);
  console.log("lll-", formMethods.getValues("identification"));
  // console.log("lll-",formMethods.getValues('identification').length)

  const onYearSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
      setSelectedYear(Number(item.value));
      refetchRL1SettingData()
    }

  const onCopyFromPrevoisYearChangedHandler = (value: boolean) => {
      if(value){
        (async () => {
          try {
            setIsLoadingData(true)
            const response: any = await RL1SlipService.getRL1SettingData(id, selectedYear-1);
            console.log("responseData-----------------", response);
            if (response) {
              if (response && response.id) {
                Object.keys(response).forEach((fieldName) => {
                  const fieldValue = response[fieldName];
                  if(fieldName === 'sequentialNumberStart'){
                    formMethods.setValue(fieldName, fieldValue ?? undefined);
                  }
                  if(fieldName === 'sequentialNumberEnd'){
                    formMethods.setValue(fieldName, fieldValue ?? undefined);
                  }
                });
              } else if (response && response.message) {
                setSnackbarData({
                  open: true,
                  message: response.message.message ? response.message.message : response.message ? response.message : 'Something went wrong!',
                  severity: "error",
                });
                formMethods.setValue('sequentialNumberStart',undefined)
                formMethods.setValue('sequentialNumberEnd',undefined)
                }
            }
          } catch (error) {
            setIsLoadingData(false)
            console.error("Error fetching data:", error);
          } finally {
            setIsLoadingData(false)
            }
            })();
      } else {
        formMethods.setValue('sequentialNumberStart',undefined)
        formMethods.setValue('sequentialNumberEnd',undefined)
      }
  }

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: RL1_SETTINGS_SCREEN_SLUG,
        companyName: clientName,
        activityType: "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={
          isLoading ||
          // isLoadingSelectedFlexField ||
          isSubmitting ||
          // isLoadingFlexFieldsList ||
          isLoadingTransmitterDDL ||
          isLoadingSummaryGetData ||
          isLoadingActivityLogData ||
          isLoadingData
        }
        testId={testId}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <TwoColumnLayout
            toolbar={
              <StandardSubmitFormButtonsToolbar
                onResetClicked={onResetClicked}
                isSubmitting={isSubmitting}
                testId={testId}
              />
            }
            leftColumnContent={
              <>
                <StandardPanel title="Year" testId={`${testId}-year-panel`}>
                  <EnhancedDropDownRHFWithLabel
                    label="Select Year"
                    selectName="taxYear"
                    items={years ?? []}
                    testId={`${testId}-selected-year`}
                    labelPosition={LabelPosition.Left}
                    selectedItem={selectedYear}
                    onSelectionChangeHandler={onYearSelectionChangeHandler}
                  />
                </StandardPanel>
                <StandardPanel
                  title="Slip Data"
                  testId={`${testId}-slip-data`}
                  topPadding={2}
                >
                  <EnhancedDropDownRHFWithLabel
                    label="Transmitter"
                    selectName="transmitter"
                    items={transmitterDDL && !transmitterDDL.message ? transmitterDDL : []}
                    // selectedItem="2020"
                    testId={`${testId}-transmitter`}
                    labelPosition={LabelPosition.Left}
                    required
                  />
                  <EnhancedDropDownRHFWithLabel
                    label="Submission Method"
                    selectName="submissionMethod"
                    items={[
                      { label: "XML", value: 1 },
                      { label: "Paper", value: 2 },
                    ]}
                    selectedItem={1}
                    testId={`${testId}-submission-method`}
                    labelPosition={LabelPosition.Left}
                    required
                  />
                  <EnhancedDropDownRHFWithLabel
                    label="Source of RL-1 Slips Used"
                    selectName="sourceOfRl1SlipsUsed"
                    items={[
                      {
                        label:
                          "if the RL slips were obtained from Revenu Québec",
                        value: "if the RL1 slips were obtained from quebec",
                      },
                      {
                        label:
                          "if the RL slips are facsimiles obtained from a third party",
                        value:
                          "if the RL1 slips are facsimiles obtained from a third party",
                      },
                      {
                        label:
                          "if the RL slips are facsimiles produced by the transmitter",
                        value:
                          "if the RL1 slips are facsimiles produced by the transmitter",
                      },
                      {
                        label:
                          "if the source of the RL slips is any combination of the above",
                        value:
                          "if the source of the RL1 slips is any combination of the above",
                      },
                    ]}
                    // selectedItem="1"
                    testId={`${testId}-source-of-rl-slips-used`}
                    labelPosition={LabelPosition.Left}
                    required
                  />
                </StandardPanel>
              </>
            }
            rightColumnContent={
              <>
                <StandardPanel
                  title="Sequential Numbers"
                  testId={`${testId}-sequential-numbers`}
                >
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="copyFromPreviousYear"
                      checked={false}
                      testId="copyFromPreviousYear"
                      label="Copy from Previous Year"
                      labelPosition={LabelPosition.Left}
                      onSelectionChangeHandler={onCopyFromPrevoisYearChangedHandler}
                    />
                  </FieldWrapper>
                  {/* <RHookFormTextFieldWithLabel
                    label="Start #"
                    id="sequentialNumberStart"
                    testId={`${testId}-start-number`}
                    labelPosition={LabelPosition.Left}
                    required
                  /> */}
                  {/* <RHookFormTextFieldWithLabel
                    label="End #"
                    id="sequentialNumberEnd"
                    testId={`${testId}-end-number`}
                    labelPosition={LabelPosition.Left}
                    required
                  /> */}
                  <RHookFormMaskedTextFieldWithLabel
                    label="Start #"
                    id="sequentialNumberStart"
                    testId={`${testId}-start-number`}
                    labelPosition={LabelPosition.Left}
                    placeholder={"00000000"}
                    required
                    inputMaskPattern={"00000000"}
                    labelColumns={4}
                    fieldColumns={8}
                  />
                  <RHookFormMaskedTextFieldWithLabel
                    label="End #"
                    id="sequentialNumberEnd"
                    testId={`${testId}-end-number`}
                    labelPosition={LabelPosition.Left}
                    placeholder={"00000000"}
                    required
                    inputMaskPattern={"00000000"}
                    labelColumns={4}
                    fieldColumns={8}
                  />
                </StandardPanel>
                <StandardPanel
                  title="Enterprise/Identification Number"
                  testId={`${testId}-enterprise-identification-number-panel`}
                  topPadding={2}
                >
                  {/* <RHookFormTextFieldWithLabel
                    label="Quebec Enterprise #"
                    id="quebecEnterprise"
                    testId={`${testId}-start-number`}
                    labelPosition={LabelPosition.Left}
                  /> */}
                  <RHookFormMaskedTextFieldWithLabel
                    label="Quebec Enterprise #"
                    id="quebecEnterprise"
                    testId={`${testId}-quebecEnterprise`}
                    labelPosition={LabelPosition.Left}
                    placeholder={"0000000000"}
                    required
                    inputMaskPattern={"0000000000"}
                    labelColumns={4}
                    fieldColumns={8}
                  />
                  <RHookFormMaskedTextFieldWithLabel
                    id="identification"
                    label="Identification #"
                    required
                    testId={`${testId}-identification-number`}
                    labelPosition={LabelPosition.Left}
                    placeholder={"0000000000RS0000"}
                    inputMaskPattern={"0000000000RS0000"}
                  />
                </StandardPanel>
              </>
            }
            testId="rl1-settings-setup-form"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
