import { AxiosError, AxiosResponse } from 'axios';
import { IGetListRequest, IResponse, IServerError } from 'models';

import { IAdjustmentOptionsModel } from 'models/adjustmentOptions';
import http from 'common/http-common';

export const AdjustmentOptionsService = {
  getAdjustmentOptionsByClientId: async (
    clientId: string
  ): Promise<
  IAdjustmentOptionsModel | IServerError | IResponse
  > => {
    const fixedResponse: IAdjustmentOptionsModel = {
      // cppQPPAdjustOverUnder: true,
      cppQPPAdjustPensionablePeriods: false,
      eiAdjustPremiumOverAnnualMaximum: true,
      eiAdjustPremiumBasedOnInsurableEarnings: true,
      eiAdjustInsurableEarningsBasedOnPremium: false,
      reducedEIRate: "",
      ppipAdjustPremiumOverAnnualMaximum: true,
      ppipAdjustPremiumBasedOnInsurableEarnings: true,
      ppipAdjustInsurableEarningsBasedOnPremium: false,
      // transferOverRemittanceToEmployees: "",
      overRemittanceIncreaseEmployeesGrossPay: false,
      cppEiPpipAdjustDifferenceGreaterThan: "1.00",
      cppEiPpipAdjustCalculatedAmount: false,
      maximumAmountByWhichTaxMayBeReduced: "999999.99",
      lockAdjustmentOptions: false,
      disableT4Rl1Adjustments: false,
      // mergeT4Rl1SlipsBeforeAdjustment: true,
      ignoreAdjustments: false,
      t4EmployeesPrint: "",
      t4EmployerPrint: "",
      t4CraPrint: "",
      emailMessage: "Your T4 Slip for the year 2020 is attached.\n\nNote:\nThe attached T4 slip can only be viewed with Acrobat Reader.",
      clientId: '',
      adjustmentType: '',
      taxYear: 0,
      cppQPPDjustOverUnder: false,
      transferOverRemittanceToSlips: '',
      mergeT4Rl1SlipsBeforeAAdjustment: false,
      remittanceTransferred: false,
      ignoreRemittanceTransferred: false
    }
    return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    // const response: AxiosResponse<any> = await http.get<any>(
    //   `/v1/audit/?client_id=${clientId}&tax_year=${taxYear}`);

    // if (response.status === 200) {
    //   return response.data;
    // } else if (response.status === 400) {
    //   return {
    //     status: false,
    //     message: "Bad Request.",
    //     error: true,
    //   };
    // } else if (response.status === 500) {
    //   return {
    //     status: false,
    //     message: "Something went wrong.",
    //     error: true,
    //   };
    // }

    // return {
    //   status: false,
    //   message: "Something went wrong please try again later.",
    // };
  },
  getAuditFiling: async (
    clientId: string,
    taxYear:number
  ): Promise<
  any | IServerError | IResponse
  > => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/audit/?client_id=${clientId}&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getGeneralAuditFiling: async (
    clientId: string,
    taxYear:number
  ): Promise<
  any | IServerError | IResponse
  > => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/audit/general-audit/?client_id=${clientId}&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createAuditFiling: async (
    requestPayload: any
  ): Promise<
  any | IServerError | IResponse
  > => {
    const response: AxiosResponse<any> = await http.post<any>(
      `/v1/audit/audit-filling?client_id=${requestPayload.clientId}&audit_filling_tag=${requestPayload.tag}&tax_year=${requestPayload.taxYear}`,requestPayload);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createAuditFilingStatusAndTime: async (
    requestPayload: any
  ): Promise<
  any | IServerError | IResponse
  > => {
    const response: AxiosResponse<any> = await http.post<any>(
      `/v1/audit/filling-audit-status?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`,requestPayload);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getAuditFilingStatusAndTime: async (
    clientId: string,
    taxYear:number
  ): Promise<
  any | IServerError | IResponse
  > => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/audit/get-audit-ststus/?client_id=${clientId}&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
    // const fixedResponse: any = {
    //   general:{
    //     auditStatus:false,
    //     lastCheck:"",
    //   },
    //   t4:{
    //     auditStatus:false,
    //     lastCheck:"",
    //   },
    //   t4a:{
    //     auditStatus:false,
    //     lastCheck:"",
    //   },
    //   rl1:{
    //     auditStatus:false,
    //     lastCheck:"",
    //   },
    //   t5:{
    //     auditStatus:false,
    //     lastCheck:"",
    //   },
    //   rl3:{
    //     auditStatus:false,
    //     lastCheck:"",
    //   },
    //   t3:{
    //     auditStatus:false,
    //     lastCheck:"",
    //   },
    //   rl16:{
    //     auditStatus:false,
    //     lastCheck:"",
    //   }
    // }
    
    // return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

  },
  getAuditEarningsForEI: async (
    clientId: string,
    taxYear:number,
    params:IGetListRequest
  ): Promise<
  any | IServerError | IResponse
  > => {
    try {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/audit-earnings/ei-ensurable-earning?client_id=${clientId}&tax_year=${taxYear}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`);
console.log("responseqw",response)
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 404) {
      return response;
    }else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    } else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    return  axiosError.response
        ? axiosError.response
        : "Unknown error"
  }
  },
  ignoreEIAuditEarnings: async (
    clientId: string,
    taxYear:number,
    slipIds:string
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.put<any>(
      `/v1/audit-earnings/ignored-audit-earning?client_id=${clientId}&earning_tag=EI&${slipIds}&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response;
    }
    if (response.status === 400) {
      return response.data;
    }
    if (response.status === 201) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  ignoreCPPAuditEarnings: async (
    clientId: string,
    taxYear:number,
    slipIds:string
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.put<any>(
      `/v1/audit-earnings/ignored-audit-earning?client_id=${clientId}&earning_tag=cpp&${slipIds}&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response;
    }
    if (response.status === 400) {
      return response.data;
    }
    if (response.status === 201) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  undoIgnoreEIAuditEarnings: async (
    clientId: string,
    taxYear:number,
    slipIds:string
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.put<any>(
      `/v1/audit-earnings/undo-ignored-audit-earning?client_id=${clientId}&earning_tag=EI&${slipIds}&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response;
    }
    if (response.status === 400) {
      return response.data;
    }
    if (response.status === 201) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  undoIgnoreCPPAuditEarnings: async (
    clientId: string,
    taxYear:number,
    slipIds:string
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.put<any>(
      `/v1/audit-earnings/undo-ignored-audit-earning?client_id=${clientId}&earning_tag=cpp&${slipIds}&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response;
    }
    if (response.status === 400) {
      return response.data;
    }
    if (response.status === 201) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getAuditEarningsForCPP: async (
    clientId: string,
    taxYear:number,
    params:IGetListRequest
  ): Promise<
  any | IServerError | IResponse
  > => {
    try {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/audit-earnings/pensionable-earnings?client_id=${clientId}&tax_year=${taxYear}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    } else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    return  axiosError.response
        ? axiosError.response
        : "Unknown error"
  }
  },
  getAdjustmentOptionsByClientIdAndTaxYear: async (
    clientId: string,
    taxYear:number
  ): Promise<
  IAdjustmentOptionsModel | IServerError | IResponse | any
  > => {
    const response: AxiosResponse<IAdjustmentOptionsModel> = await http.get<IAdjustmentOptionsModel>(
      `/v1/adjustment-options/?client_id=${clientId}&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  submit: async (requestPayload: IAdjustmentOptionsModel): Promise<IAdjustmentOptionsModel> => {
    console.log('adjustment options requestPayload', requestPayload);
    let response: IAdjustmentOptionsModel;

    // TODO: when replacing this with the API and it's edit mode, make sure the ID is included so that we can invalidate the cache key of that ID
    return new Promise(resolve => setTimeout(() => resolve(response), 1000));
  },
  create: async (
    requestPayload: any
  ): Promise<any | IServerError | IResponse> => {
    
    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<IAdjustmentOptionsModel> =
      await http.post<IAdjustmentOptionsModel>(
        `/v1/adjustment-options/?client_id=${requestPayload.clientId}&adjustment_type=${requestPayload.adjustmentType}&tax_year=${requestPayload.taxYear}`,        requestPayload
      );

    if (response.status === 201) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getTransferOverRemittance: async (
    clientId: string,
    taxYear: number
    )
    : Promise<any | IServerError | IResponse | null> => {
    // if (id) {
      const response: AxiosResponse<any> = await http.get<any>(
        `/v1/adjustment-options/t4-ddl/${clientId}?tax_year=${taxYear}`);
  
      console.log("response of selected row in t4 slip : ",response.data)
    
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    // }

    // return null;
  },
};
