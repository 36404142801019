// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HashRouter as Router, Route, Routes } from "react-router-dom";

import { PrivateRoute, ProtectedRoutes, PublicRoute } from "@websential/cosmic";

import {
  CreateAnAccountPage,
  ForgotPasswordPage,
  LoginPage,
  LogoutPage,
  ResetPasswordPage,
} from "../../pages";
import {
  AccountantsRoutes,
  AdjustmentRoutes,
  AuditRoutes,
  BillingProfileAssociationRoutes,
  BillingProfileRoutes,
  ClientRoutes,
  DataRoleRoutes,
  FilingResourceRoutes,
  FlexFieldPanelAssignmentRoutes,
  FlexFieldPanelRoutes,
  FlexFieldRoutes,
  FlexFieldScreenSetupRoutes,
  FlexFieldTabRoutes,
  GlobalSearchRoutes,
  GlobalVariablesRoutes,
  ReportsRoutes,
  LogoutRoutes,
  LookupFieldRoutes,
  ResourceRoutes,
  routes,
  TaxProfileRoutes,
  TaxSlipCategoryRoutes,
  TaxSlipRoutes,
  YearsRoutes,
  MeRoutes,
  nonSuperUserInitialRoutes,
  AdminReportsRoutes,
  ForgetPasswordRoutes,
  clientDashboardInitialRoutes,
  nonSuperUserInitialRoutesIfAccAssign,
  superUserInitialClientListRoutes,
  PDFSettingsRoutes,
  TaxSlipDefinitionsRoutes,
} from "./routes";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { NoFoundPage } from "pages/Anonymous/NoFoundPage";
import { UserAuthenticationPage } from "pages/Anonymous/UserAuthenticationPage";
import { InviteUsersPage } from "pages/Anonymous/InviteUsersPage";

export const AppRouter = ({ isAuthenticated }: any) => {
  const homePage = useSelector((state: IAppState)=> state.HomePagePath.path)
  const clientId = useSelector((state: IAppState) => state.client.id);
  const accountantId = useSelector((state: IAppState) => state.accountant.id);
  const isAdminUser = useSelector((state: IAppState) => state.user.profile.isSuperStatus);
  return (
    // <Router basename="/taxslips-web-app">
    // <Router basename="https://appv3.taxslips.com">
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} redirectTo="/">
              <LoginPage />
              {/* <ProtectedRoutes routeList={ClientRoutes} /> */}
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} redirectTo="/">
              <LoginPage />
              {/* <ProtectedRoutes routeList={ClientRoutes} /> */}
            </PublicRoute>
          }
        />
        <Route
          path="/taxslips_v5.com/"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} redirectTo="/">
              <LoginPage />
            </PublicRoute>
          }
        />
        <Route
          path="/create-an-account"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} redirectTo="/">
              <CreateAnAccountPage />
            </PublicRoute>
          }
        />
        <Route
          path="/user-authentication/:key"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} redirectTo="/">
              <UserAuthenticationPage />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password/*"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} redirectTo="/forgot-password">
              {/* <ForgotPasswordPage /> */}
              <ProtectedRoutes routeList={ForgetPasswordRoutes} />
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} redirectTo="/">
              <ResetPasswordPage />
            </PublicRoute>
          }
        />
        <Route
          index
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              {/* <ProtectedRoutes routeList={routes} /> */}
              <ProtectedRoutes 
                routeList={
                  clientId !== "" ? clientDashboardInitialRoutes : 
                  isAdminUser === 1 && accountantId !== "" ? superUserInitialClientListRoutes : 
                  isAdminUser !== 1 && accountantId !== "" ? nonSuperUserInitialRoutes : 
                  isAdminUser !== 1 ? nonSuperUserInitialRoutesIfAccAssign : 
                  routes
                } />
            </PrivateRoute>
          }
        />
        <Route
          // path={isAdminUser === 1 ? "/accountants/*" : "/"}
          path={"/accountants/*"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={AccountantsRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/global-search/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={GlobalSearchRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path="/clients/*"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={ClientRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path="/adjustments/*"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={AdjustmentRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path="/audit/*"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={AuditRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path="/resources/*"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={ResourceRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path="/filing-resources/*"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={FilingResourceRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/years/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={YearsRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/data-roles/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={DataRoleRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/lookup-fields/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={LookupFieldRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/global-variables/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={GlobalVariablesRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/flex-fields/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={FlexFieldRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/flex-field-tabs/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={FlexFieldTabRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/flex-field-panels/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={FlexFieldPanelRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/flex-field-panel-assignment/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={FlexFieldPanelAssignmentRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/flex-field-screen-setup/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={FlexFieldScreenSetupRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/taxslip-categories/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={TaxSlipCategoryRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/taxslip-definitions/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={TaxSlipDefinitionsRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/pdf-settings/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={PDFSettingsRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/billing-profile/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={BillingProfileRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/tax-profile/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={TaxProfileRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ? "/billing-profile-association/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={BillingProfileAssociationRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path={isAdminUser === 1 ?  "/admin-reports/*" : "/"}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={AdminReportsRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path="/reports/*"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={ReportsRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path="/taxslip/*"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={TaxSlipRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path="/me/*"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              <ProtectedRoutes routeList={MeRoutes} />
            </PrivateRoute>
          }
        />
        <Route
          path="/invite/user"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} redirectTo="/login">
              {/* <ProtectedRoutes routeList={MeRoutes} /> */}
              <InviteUsersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/logout"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}  redirectTo="/login" >
              {/* <ProtectedRoutes routeList={LogoutRoutes} /> */}
              <LogoutPage/>
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NoFoundPage />} />
      </Routes>
    </Router>
  );
};
