import { useGetLookupFieldData } from "hooks/useLookupFields";
import { FC, useState } from "react";

import { Grid, InputLabel, Typography } from "@mui/material";
import {
  EnhancedCheckboxRHF,
  EnhancedDropDownRHFWithLabel,
  IEnhancedDropDownItem,
  LabelPosition,
  RHookFormTextFieldWithLabel,
} from "@websential/cosmic";
import { IProvinceReferenceModel } from "models/province/IProvinceReferenceModel";
import { useQuery } from "react-query";
import { GET_QUERY_KEY, QUERY_OPTIONS } from "common/constants";
import { ProvinceService } from "services/common/ProvinceService";
import { IT4SlipRequestPayload } from "models/t4Slip/IT4SlipRequestPayload";
import { UseFormReturn } from "react-hook-form";
import { ClientService } from "services";
import { IAppState } from "store/reducers";
import { useSelector } from "react-redux";

const useGetClientProfileData = (id: string | undefined) => {
  return useQuery(
    ['client-single-data-record', id],
    (data) => {
      return ClientService.getById(id);
    },
    { refetchOnWindowFocus: false }
  );
};

interface IFilingDataSetupFieldsPanel {
  selectedPayPeriod?: number;
  selectedPensionablePeriod?: number;
  selectedSlipType?: number;
  selectedProvinceOfEmployment?: string;
  selectedEmploymentCode?: number;
  selectedReducedEi?: number;
  selectedSlipStatus?: number;
  formMethods: UseFormReturn< any,IT4SlipRequestPayload>;
  onPOEchange: (value: any) => void;
  showbox17IfQC?:boolean;
  globalVariablesData:any;
}
export let provinceOfEmploymentRes : any = undefined
export let selectedPOE:any = ""
export const FilingDataSetupFieldsPanel: FC<IFilingDataSetupFieldsPanel> = ({
  selectedPayPeriod,
  // selectedSlipType,
  // selectedProvinceOfEmployment,
  // selectedEmploymentCode,
  formMethods,
  onPOEchange,
  showbox17IfQC,
  globalVariablesData
}) => {
  const taxYear = useSelector((state : IAppState) => state.taxYear.year)
  const { isLoading: isLoadingPayPeriods, data: payPeriodsList } =
    useGetLookupFieldData("payPeriods");
  const clientId = useSelector((state: IAppState) => state.client.id);
  const { isLoading: isLoadingClientProfileDataById, data: clientProfileData } =
    useGetClientProfileData(clientId);

    const [selectedProvince, setSelectedProvince] =
    useState<IProvinceReferenceModel>({
      id:null,
      name: "",
      shortCode: "",
    });
    const useGetProvinceDDL = () => {
      return useQuery(GET_QUERY_KEY.PROVINCE_DDL, () => ProvinceService.get(), {
        ...QUERY_OPTIONS,
      });
    };
    const { isLoading: isLoadingProvinceDropDownList, data: provinceList } =
    useGetProvinceDDL();

    const getItemsByIndices = (indices:any) => {
      return indices.map((index:any) => provinceList && provinceList.find((item:any) => item.id === index.toString()));
    };
    
    const poeDdlAccordingToClientOperatingProvinceList = clientProfileData && provinceList && clientProfileData.operatingProvinces && getItemsByIndices(clientProfileData.operatingProvinces)
    // const onProvinceSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
    //   setSelectedProvince({
    //     id:item.id,
    //     name: item.label,
    //     shortCode: String(item.value),
    //   });
    // };
    provinceOfEmploymentRes = selectedProvince
    let currentCppContributions = formMethods.getValues('cppContributions')
    console.log("currentCppContributions",currentCppContributions)
    let currentQppContributions = formMethods.getValues('qppContributions')
    console.log("currentQppContributions",currentQppContributions)
    const handleSelectChange = (e:any) => {
      console.log(e.label)
      // alert(e.label)
      selectedPOE = e.label
      onPOEchange(e.label)
      if(e.label !== 'Quebec'){
        formMethods.setValue('cppContributions',currentCppContributions)
        console.log("insideinside if")

        formMethods.setValue('ppipPremiums',"0.00")
        formMethods.setValue('ppipInsurableEarnings',"0.00")
      } else {
        formMethods.setValue('qppContributions',currentQppContributions)
        console.log("insideinside else")
      }
    }
    
    if(showbox17IfQC !== true){
      formMethods.setValue('cppContributions',currentCppContributions)
      console.log("insideinside if")
    } else {
      onPOEchange("Quebec")
      formMethods.setValue('qppContributions',currentQppContributions)
      console.log("insideinside else")
    }

    console.log("showbox17IfQC",showbox17IfQC)

    const onCppQppExemptChangeHandler = (e: any) => {
      // alert(e)
      if(globalVariablesData && globalVariablesData?.data?.t4){
        if (e === true){
          formMethods.setValue('cppQppPensionableEarnings',"0.00")
      } else {
        const employmentIncome:any = parseInt(formMethods.getValues('employmentIncome').replace(/[$,]/g, ''))
        console.log("globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum",globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum)
        console.log("globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum",employmentIncome)
        if(taxYear > 2023){
          if(employmentIncome > globalVariablesData?.data?.t4?.cppSettingsTotalSecondCppPensionableEarningsMaximum){
            formMethods.setValue('cppQppPensionableEarnings',parseFloat(globalVariablesData?.data?.t4?.cppSettingsTotalSecondCppPensionableEarningsMaximum).toFixed(2))
          }
          else{
            formMethods.setValue('cppQppPensionableEarnings',parseFloat(employmentIncome).toFixed(2))
          }
        } else {
          if(employmentIncome > globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum){
            formMethods.setValue('cppQppPensionableEarnings',parseFloat(globalVariablesData?.data?.t4?.cppSettingsTotalCppPensionableEarningsMaximum).toFixed(2))
          }
          else{
            formMethods.setValue('cppQppPensionableEarnings',parseFloat(employmentIncome).toFixed(2))
          }
        }
      }
      }
    };
    const onEiExemptChangeHandler = (e: any) => {
      // alert(e)
      if(globalVariablesData && globalVariablesData?.data?.t4){
      if (e === true){
        formMethods.setValue('eiInsurableEarnings',"0.00")
      } else {
        const employmentIncome = parseInt(formMethods.getValues('employmentIncome').replace(/[$,]/g, ''))
        console.log("globalVariablesData?.data?.t4?.eiSettingsTotalEiEarningsMaximum",globalVariablesData?.data?.t4?.eiSettingsTotalEiEarningsMaximum)
        if(employmentIncome > globalVariablesData?.data?.t4?.eiSettingsTotalEiEarningsMaximum){
          formMethods.setValue('eiInsurableEarnings',globalVariablesData?.data?.t4?.eiSettingsTotalEiEarningsMaximum)
        }
        else{
          formMethods.setValue('eiInsurableEarnings',employmentIncome)
        }
      }
      }
    };
    const listItems = [
      {label:"Not eligible to access any dental care insurance, or coverage of dental service of any kind",value:1},
      {label:"Payee only",value:2},
      {label:"Payee, spouse and dependent children",value:3},
      {label:"Payee and their spouse",value:4},
      {label:"Payee and their dependent children",value:5},
    ]
    
    const onPayPeriodChangeHandler = (value : any) => {
      formMethods.setValue('pensionablePeriod',value.value)
    }
    const onPensionablePeriodChange = (value : any) => {
      // alert(value)
      // formMethods.setValue('pensionablePeriod',value.value)
    }
  return (
    <>
      <EnhancedDropDownRHFWithLabel
        label="Pay Periods"
        selectName="payPeriod"
        items={payPeriodsList ?? []}
        selectedItem={26}
        testId="pay-periods"
        labelPosition={LabelPosition.Left}
        onSelectionChangeHandler={onPayPeriodChangeHandler}
        required
      />
      <RHookFormTextFieldWithLabel
        label="Pensionable Periods"
        id="pensionablePeriod"
        testId="pensionable-periods"
        labelPosition={LabelPosition.Left}
        // value="26"
        defaultValue={26}
        // readOnly={true}
        required
        onChangeHandler={onPensionablePeriodChange}
      />
      {/* <EnhancedDropDownRHFWithLabel
        label="Slip Type"
        selectName="slipType"
        items={[
          { label: "Original", value: 400 },
          { label: "Amended", value: 200 },
          { label: "Cancelled", value: 300 },
          { label: "Additional", value: 100 },
        ]}
        selectedItem={400}
        testId="slip-type"
        labelPosition={LabelPosition.Left}
        required
      /> */}
      {/* <EnhancedDropDownRHFWithLabel
        label="Province of Employment"
        selectName="provinceOfEmployment"
        items={[
          { label: "Ontario", value: "ontario" },
          { label: "Quebec", value: "quebec" },
          { label: "Alberta", value: "alberta" },
        ]}
        testId="province-of-employment"
        labelPosition={LabelPosition.Left}
        required
      /> */}
      <EnhancedDropDownRHFWithLabel
            selectName="provinceOfEmployment"
            label="Province of Employment"
            // selectedItem={selectedProvince.shortCode}
            labelPosition={LabelPosition.Left}
            required
            testId={`province-of-employement`}
            items={poeDdlAccordingToClientOperatingProvinceList ?? []}
            onSelectionChangeHandler={handleSelectChange}
            // items={[
            //   {label: "Alberta", value: {id:1, name: "Alberta",shortCode:"AB"}, },
            //   {label: "British Columbia", value: {id:2, name: "British Columbia",shortCode:"BC"},},
            //   {label:"Manitoba", value: {id:3, name: "Manitoba",shortCode:"MB"},},
            //   {label:"New Brunswick", value: {id:4, name: "New Brunswick",shortCode:"NB"}, },
            //   {label:"Newfoundland and Labrador", value: {id:5, name: "Newfoundland and Labrador",shortCode:"NL"}, },
            //   {label:"Northwest Territories", value: {id:7, name: "Northwest Territories",shortCode:"NT"},},
            //   {label:"Nova Scotia", value: {id:6, name: "Nova Scotia",shortCode:"US"}, },
            //   {label:"Nunavut", value: {id:8, name: "Nunavut",shortCode:"NU"}, },
            //   {label:"Ontario", value: {id:9, name: "Ontario",shortCode:"ON"}, },
            //   {label:"Prince Edward Island", value: {id:10,name: "Prince Edward Island",shortCode:"PE"}, },
            //   {label:"Quebec", value: {id:11,name: "Quebec",shortCode:"QC"}, },
            //   {label:"Saskatchewan", value: {id:12,name: "Saskatchewan",shortCode:"SK"}, },
            //   {label:"Yukon", value: {id:13,name: "Yukon",shortCode:"YT"},},
            // ]}
            // onSelectionChangeHandler={
            //   onProvinceSelectionChangeHandler
            // }
          />
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          sx={{ textAlign: "left", paddingTop: 2 }}
        >
          <InputLabel>
            <Typography variant="subtitle2">Exempt</Typography>
          </InputLabel>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          sx={{ textAlign: "left", paddingTop: 0.6 }}
        >
          <EnhancedCheckboxRHF
            name="cppQppExempt"
            checked={false}
            testId="cpp-qpp"
            checkboxLabel="CPP/QPP"
            onSelectionChangeHandler={onCppQppExemptChangeHandler}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          sx={{ textAlign: "left", paddingTop: 0.6 }}
        >
          <EnhancedCheckboxRHF
            name="eiExempt"
            checked={false}
            testId="ei"
            checkboxLabel="EI"
            onSelectionChangeHandler={onEiExemptChangeHandler}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          sx={{ textAlign: "left", paddingTop: 0.6 }}
        >
          <EnhancedCheckboxRHF
            name="ppipExempt"
            checked={false}
            testId="ppip"
            checkboxLabel="PPIP"
          />
        </Grid>
      </Grid>
      {taxYear >= 2023 ? 
        <EnhancedDropDownRHFWithLabel
          label="Employer-offered dental benefits"
          selectName="employerOfferedDentalBenefits"
          items={listItems ?? []}
          showEmpty={true}
          testId="EmployerOfferedDentalBenefits"
          labelPosition={LabelPosition.Left}
        />
      :<></>}
      <EnhancedDropDownRHFWithLabel
        label="Employment Code"
        selectName="employmentCode"
        items={[
          { label: "11 - Placement or employment agency workers", value: 11 },
          {
            label:
              "12 - Taxi drivers or drivers of other passenger-carrying vehicles",
            value: 12,
          },
          { label: "13 - Barbers or hairdressers", value: 13 },
          { label: "14 - Withdrawal from a Prescribed Salary Deferral Arrangement Plan", value: 14 },
          { label: "15 - Seasonal Agricultural Workers Program", value: 15 },
          { label: "16 - deteached Employee Social Security Agreement", value: 16 },
          { label: "17 - Fisher self Employed", value: 17 },
        ]}
        testId="employment-code"
        labelPosition={LabelPosition.Left}
      />
      <EnhancedDropDownRHFWithLabel
        label="Reduced EI"
        selectName="reducedEi"
        items={[
          { label: "Yes", value: 1 },
          { label: "No", value: 0 },
        ]}
        selectedItem={0}
        testId="reduced-ei"
        labelPosition={LabelPosition.Left}
        required
      />
      <EnhancedDropDownRHFWithLabel
        label="Status"
        selectName="slipStatus"
        items={[
          { label: "Draft", value: 200 },
          { label: "Completed", value: 100 },
        ]}
        selectedItem={200}
        testId="status"
        labelPosition={LabelPosition.Left}
        required
      />
    </>
  );
};
