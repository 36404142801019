import { LeftMenu } from "components/LeftMenu";

const taxSlipsLeftMenu = [
  {
    id: 901,
    text: "Categories",
    open: false,
    menu: [
      {
        id: 911,
        text: "List",
        parentMenuId: 901,
        grandParentMenuId: 900,
        navigateToURL: "/taxslip-categories/list"
      },
      {
        id: 912,
        text: "Setup",
        parentMenuId: 901,
        grandParentMenuId: 900,
        navigateToURL: "/taxslip-categories/setup"
      },
    ],
    parentMenuId: 900,
  },
  {
    id: 920,
    text: "Definitions",
    open: false,
    menu: [
      {
        id: 921,
        text: "List",
        parentMenuId: 920,
        grandParentMenuId: 900,
        navigateToURL: "/taxslip-definitions/list"
      },
      {
        id: 922,
        text: "Setup",
        parentMenuId: 920,
        grandParentMenuId: 900,
        navigateToURL: "/taxslip-definitions/setup"
      },
    ],
    parentMenuId: 900,
  },
  // {
  //   id: 930,
  //   text: "Panels",
  //   open: false,
  //   menu: [
  //     {
  //       id: 931,
  //       text: "List",
  //       parentMenuId: 930,
  //       grandParentMenuId: 900,
  //       navigateToURL: "/flex-field-panels/list"
  //     },
  //     {
  //       id: 932,
  //       text: "Setup",
  //       parentMenuId: 930,
  //       grandParentMenuId: 900,
  //       navigateToURL: "/flex-field-panels/setup"
  //     },
  //   ],
  //   parentMenuId: 900,
  // },
  // {
  //   id: 940,
  //   text: "Assign Field to Panel",
  //   open: false,
  //   parentMenuId: 900,
  //   navigateToURL: "/flex-field-panel-assignment"
  // },
  // {
  //   id: 950,
  //   text: "Field Set",
  //   open: false,
  //   parentMenuId: 900,
  //   navigateToURL: "/flex-field-screen-setup"
  // },
];

export const TaxSlipMenu = () => {
  return <LeftMenu menuItems={taxSlipsLeftMenu} />;
};
