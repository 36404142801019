import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "./transactionDetailTableStyle.css";

export interface IDataTable {
  rows: any;
  last_rows: any;
  columns: any;
  showIndexColumn: boolean;
  showChecboxColumn: boolean;
  showSettingColumn: boolean;
  isLoading?: boolean;
}

export const TransactionDetailDataTable: React.FC<IDataTable> = ({
  rows,
  last_rows,
  columns,
  showIndexColumn,
  showChecboxColumn,
  showSettingColumn,
  // onRowPrintClicked,
  isLoading,
  // onSnackbarMessageData,
}) => {
  
  const setting = () => {
    return (
      <div style={{ marginLeft: "0.35rem" }}>
        <i className="pi pi-cog text-xl"></i>
      </div>
    );
  };
  const delEdit = (params: any) => {
    return (
      <Button
        icon="pi pi-print"
        // onClick={() =>
        //   onRowPrintClicked(selectedItems.length > 0 ? selectedItems : params)
        // }
        className="p-button-rounded p-button-text w-2rem h-2rem"
      />
    );
  };

    // this states used in the process of filteration of dataTable
    const [itemSearsch, setItemSearsch] = useState('');

    const [val, setValue] = useState(false)
    const [field, setField] = useState("")
    

// Search type and billing profile Button
    const renderHeader = () => {
        return (
             <div className='flex justify-content-between p-4'  style={{backgroundColor:"#ebecf0",margin:"-17px"}}>
                <span className="p-input-icon-left w-5">
                    <i className="pi pi-search" />
                    <InputText value={itemSearsch} onChange={(e) => setItemSearsch(e.target.value)} placeholder="Search by Employee Name" className='w-full bg-transparent'/>
                </span>
                {/* <Button label='Ganeral Client List' className='p-button-success uppercase'/> */}
            </div>
        )
    }
    const headerSearch = renderHeader();
    const search = (_row:any) => {
        return rowsWithTwoDecimalNumbers && rowsWithTwoDecimalNumbers.filter((rows:any)=> rows.employee.toLowerCase().indexOf(itemSearsch.toLowerCase())>-1 )
        
        // || rows.effectiveStartDate.toLowerCase().indexOf(globalFilterValue1)>-1)

        // another way where we dont need to write all properties which you want to search (we can search with all props)
        // if(rows && rows !== false && _row !== false){
        //     const columns = _row[0] && Object.keys(_row[0])
        //     console.log("columns",columns)
        //     return rows.filter((rows:any)=> columns.some((columns:any)=> rows[columns]?.toString().toLowerCase().indexOf(itemSearsch.toLowerCase())>-1))
        // }
    }

// Sequence of header Columns
    console.log("columns", typeof(columns))

    const HideButton = (option: any, value: any , field: any) => {
        if(option == true){
                return(
                <span className='field-checkbox mt-3 mb-0'>
                    <Button label='Hide Column'  className='p-button-secondary uppercase' onClick={()=>clickHideBtn(value, field, true)}/>
                </span>
                )
        }else{
            return option = null
        }
    }

    const clickHideBtn = (value: any, field: any, is_load: any) => {

        if(is_load){
            if(value.field === field){
                setValue(value)
                setField(field)
                // return true
            }
            //  else {
            //     setValue(value)
            //     setField("")
            // }
        } else {
            return false
        }
    }

      console.log("props headerColumns => ",columns)
      console.log("props items General Clients => ",rows)


  // Custom column for row indices
  const indexColumn = {
    body: (rowData:any, row:any) => {
      return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
    },
    header: '#',
  };
  console.log("props items transaction details last rows => ",last_rows)

  const rowsWithTwoDecimalNumbers = rows && rows.map((item:any) => ({
    ...item,
    amount: parseFloat(item.amount).toFixed(2),
    accumulatedTotal: parseFloat(item.accumulatedTotal).toFixed(2),
  }));
  const lastRowsWithTwoDecimalNumbers = last_rows && last_rows.map((item:any) => ({
    ...item,
    accumulatedTotal: parseFloat(item.accumulatedTotal).toFixed(2),
  }));

  console.log("rowsWithTwoDecimalNumbers",rowsWithTwoDecimalNumbers)
  console.log("lastRowsWithTwoDecimalNumbers",lastRowsWithTwoDecimalNumbers)

  return (
      <DataTable
        value={search(rowsWithTwoDecimalNumbers)}
        responsiveLayout="scroll"
        paginator
        rows={25}
        rowsPerPageOptions={[25, 50, 100, 2]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Transactions"
        // selection={selectedItems}
        // onSelectionChange={(e: any) => setSelectedItems(e.value)}
        // editMode="row"
        showGridlines
        resizableColumns
        columnResizeMode="expand"
        scrollable
        // scrollHeight="450px"
        reorderableColumns
        // filterDisplay="menu"
        loading={isLoading}
        // globalFilterFields={["headerName"]}
        header={headerSearch}
        emptyMessage="No Record found."
        className="transactionDetailTableStyle"
        style={{ margin: "0.5rem" }}
        frozenValue={lastRowsWithTwoDecimalNumbers}
      >
        <Column
          selectionMode="multiple"
          hidden={!showChecboxColumn}
          frozen
          resizeable={false}
          style={{ maxWidth: "65px" }}
          field="selection"
          columnKey="selection"
        ></Column>

        <Column 
          {...indexColumn} 
          frozen
          hidden={!showIndexColumn}
          />

        {columns &&
          columns.map((x: any, index: any) => (
            <Column
              key={index}
              field={x.field}
              header={x.headerName}
              headerTooltip={x.description}
              headerTooltipOptions={{ position: "top" }}
              // flex (This property is not use Yet)
              hidden={
                val === x.hide
                  ? clickHideBtn(x, x.field, false)
                  : x.field === field
                  ? val
                  : x.hide
              }
              sortable={x.sortable}
              resizeable={x.resizable}
              dataType={x.type}
              align={x.align}
              alignHeader={x.headerAlign}
              // hideSortIcons (This property is not use Yet)
              // filterElement={HideButton(x.hideable, x, x.field)}
              // showFilterMenuOptions
              // filter
              // showFilterMenu={x.disableColumnMenu}
              // filterPlaceholder="Search by name"
              reorderable={x.disableReorder}
              exportable={x.disableExport}
              frozen={x.frozenColumn}
              alignFrozen={x.alignFrozen}
              columnKey={x.field}
              style={{
                minWidth: x.minWidth,
                fontSize: "0.875rem",
              }}
              footer={x.footer}
              // body={rows.employmentIncome}
            />
          ))}

        <Column
          body={delEdit}
          header={setting}
          style={{ maxWidth: "65px" }}
          hidden={!showSettingColumn}
          resizeable={false}
          reorderable={false}
          frozen
          alignFrozen="right"
        ></Column>
      </DataTable>
  );
};

//resizeablbe must be false if frozen is true and viseversa
