import React, { useState, useRef, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "./invoiceDataTableStyle.css";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { RequestParams } from "@websential/cosmic";
import SearchIcon from '@mui/icons-material/Search';

export interface IDataTable {
  rows: any;
  columns: any;
  showIndexColumn: boolean;
  showChecboxColumn: boolean;
  showSettingColumn: boolean;
  onRowPrintClicked: (params: GridRenderCellParams) => void;
  // onRowDeleteClicked: (params: GridRenderCellParams) => void;
  isLoading?: boolean;
  // onKeepRecordRowSelected: (value: any) => void;
  // onIsLoadingMerging: (value: boolean) => void;
  // loadmergeSlipsList: (value: boolean) => void;
  onSnackbarMessageData: (value: any) => void;
  // keyField?: IKeyField;
  // onKeyFieldClickHandler?: (clickedRow: any) => void;
    onRequestParamsChange?: (params: RequestParams) => void;
    initialPageSize?: number | undefined;
    initialPage?: number;
    totalRecords?: number;
}

export const PrimeDataGridInvoiceReport: React.FC<IDataTable> = ({
  rows,
  columns,
  showIndexColumn,
  showChecboxColumn,
  showSettingColumn,
  onRowPrintClicked,
  isLoading,
  onSnackbarMessageData,
  onRequestParamsChange,
  initialPageSize = 10,
  initialPage = 0,
  totalRecords
}) => {
  const isInitialRequest = useRef(true);
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [itemSearch, setItemSearch] = useState('');
    const [requestParams, setRequestParams] = useState<any>({
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        sortField: null,
        sortOrder: "asc",
        searchQuery:"",
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

    console.log("requestParams",requestParams)
  
  const setting = () => {
    return (
      <div style={{ marginLeft: "0.35rem" }}>
        <i className="pi pi-cog text-xl"></i>
      </div>
    );
  };
  const delEdit = (params: any) => {
    return (
      <Button
        icon="pi pi-print"
        onClick={() =>
          onRowPrintClicked(selectedItems.length > 0 ? selectedItems : params)
        }
        className="p-button-rounded p-button-text w-1rem h-2rem"
        style={{minWidth:"100%"}}
      />
    );
  };

    // this states used in the process of filteration of dataTable
    const [itemSearsch, setItemSearsch] = useState('');

    const [val, setValue] = useState(false)
    const [field, setField] = useState("")

    const onSearchButtonClicked = () => {
      setLoading(true)
      setRequestParams((prevParams:any) => ({
        ...prevParams,
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        searchQuery: itemSearch,
      }));
      setTimeout(() => { 
        setLoading(false);
      }, 1000);
    }
    const handleClearSearch = () => {
      setLoading(true)
      setItemSearch('')
      setRequestParams((prevParams:any) => ({
        ...prevParams,
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        searchQuery: "",
      }));
      setTimeout(() => { 
        setLoading(false);
      }, 1000);
    }
// Search type and billing profile Button
    const renderHeader = () => {
        return (
          // <div className='flex justify-content-between p-4 pl-0'  style={{backgroundColor:"#ebecf0",margin:"-17px"}}>
          <span className="p-input-icon-left w-5" style={{display:"flex"}}>
            <i className="pi pi-search" />
            <InputText value={itemSearch} onChange={(e) => setItemSearch(e.target.value)} placeholder="Search by Invoice No" className='w-full' style={{borderRadius:"5px 0px 0px 5px"}}/>
            {itemSearch && (
                <Button
                icon="pi pi-times"
                onClick={handleClearSearch}
                className="p-button-warning"
                style={{borderRadius:0}}
                />
            )}
            <Button
                onClick={onSearchButtonClicked}
                // variant="contained"
                style={{
                padding:"8px 10px 8px 10px",
                marginRight:"-14px!important",
                borderRadius:"0px 5px 5px 0px"}}
            >
                <SearchIcon />
            </Button>
        </span>
          // </div>
        )
    }
    const headerSearch = renderHeader();
    function search(_row:any){
        return rowsWithTwoDecimalNumbers && rowsWithTwoDecimalNumbers.filter((rows:any)=> rows.invoiceNo.props.children.toLowerCase().indexOf(itemSearsch.toLowerCase())>-1 )
        
        // || rows.effectiveStartDate.toLowerCase().indexOf(globalFilterValue1)>-1)

        // another way where we dont need to write all properties which you want to search (we can search with all props)
        // if(rows && rows !== false && _row !== false){
        //     const columns = _row[0] && Object.keys(_row[0])
        //     console.log("columns",columns)
        //     return rows.filter((rows:any)=> columns.some((columns:any)=> rows[columns]?.toString().toLowerCase().indexOf(itemSearsch.toLowerCase())>-1))
        // }
    }

// Sequence of header Columns
    console.log("columns", typeof(columns))

    const HideButton = (option: any, value: any , field: any) => {
        if(option == true){
                return(
                <span className='field-checkbox mt-3 mb-0'>
                    <Button label='Hide Column'  className='p-button-secondary uppercase' onClick={()=>clickHideBtn(value, field, true)}/>
                </span>
                )
        }else{
            return option = null
        }
    }

    const clickHideBtn = (value: any, field: any, is_load: any) => {

        if(is_load){
            if(value.field === field){
                setValue(value)
                setField(field)
                // return true
            }
            //  else {
            //     setValue(value)
            //     setField("")
            // }
        } else {
            return false
        }
    }

      console.log("props headerColumns => ",columns)
      console.log("props items General Clients => ",rows)


  // Custom column for row indices
  const indexColumn = {
    body: (rowData:any, row:any) => {
      return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
    },
    header: '#',
  };

  const onPage = (event:any) => {
    // setRequestParams(event);
    setLoading(true)
    //imitate delay of a backend call
    setTimeout(() => {
    setRequestParams({
      ...event,
      searchQuery: itemSearch,
    });
    setLoading(false);
  }, 1000);
  };
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const onSort = useCallback((event: any) => {
    setLoading(true);

    let newSortOrder : any;
    if (event.sortField === sortField) {
        // Toggle sortOrder between 1 and -1
        newSortOrder = sortOrder === 1 ? -1 : 1;
        setSortOrder(newSortOrder);
    } else {
        // Set the new sortField and reset sortOrder to 1
        newSortOrder = 1;
        setSortField(event.sortField);
        setSortOrder(newSortOrder);
    }

    console.log("sortOrder 33 out", newSortOrder); // Use the newSortOrder value directly
    setRequestParams((prevParams: any) => ({
        ...prevParams,
        sortOrder: newSortOrder !== 1 ? "asc" : "desc",
        sortField: event.sortField,
    }));

    // Imitate delay of a backend call
    setTimeout(() => {
        setLoading(false);
    }, 1000);
  }, [sortOrder, sortField]);
// This useEffect is triggered when the debouncedSearchQuery, pageSize or currentPage values change
useEffect(() => {
    // if the results fetcher was passed, we can use it to fetch the results.
    if (onRequestParamsChange) {
      // skip the first initial call
      if (isInitialRequest.current === true) {
        console.log(
          "##### => useEffect - requestParams - no first call",
          requestParams
        );
        isInitialRequest.current = false;
      } else {
        console.log(
          "##### => useEffect - requestParams - second call",
          requestParams
        );
        onRequestParamsChange(requestParams);
      }
    }
  }, [onRequestParamsChange, requestParams]);

  const rowsWithTwoDecimalNumbers = rows && rows.map((item:any) => ({
    ...item,
    invAmount: parseFloat(item.invAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    tax: parseFloat(item.tax).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    handlingCharges: parseFloat(item.handlingCharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    netAmount: parseFloat(item.netAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    paidAmount: parseFloat(item.paidAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    discount: parseFloat(item.discount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  }));
console.log("rowsWithTwoDecimalNumbers",rowsWithTwoDecimalNumbers)
  return (
      <DataTable
        value={rows && rowsWithTwoDecimalNumbers && rowsWithTwoDecimalNumbers}
        responsiveLayout="scroll"
        lazy 
        // filterDisplay="row"
        dataKey="id" 
        paginator
        first={requestParams.first} 
        rows={requestParams.rows}
        totalRecords={totalRecords && totalRecords}
        onPage={onPage}
        onSort={onSort}
        loading={isLoading ? isLoading : loading}

        // paginator 
        // rows={25}
        rowsPerPageOptions={[25, 50, 100]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={`Showing {first} to {last} of ${totalRecords && totalRecords} Items`}
        editMode="row" 
        showGridlines
        resizableColumns columnResizeMode="expand"
        scrollable
        scrollHeight={"70vh"}
        reorderableColumns
        filterDisplay="menu"
        globalFilterFields={['headerName']}
        header={headerSearch}
        emptyMessage="No Record found."
        className="invoiceDataTable"
        style={{ margin: "0.5rem" }}
      >
        <Column
          selectionMode="multiple"
          hidden={!showChecboxColumn}
          frozen
          resizeable={false}
          style={{ maxWidth: "65px" }}
          field="selection"
          columnKey="selection"
        ></Column>

        <Column 
          {...indexColumn} 
          frozen
          hidden={!showIndexColumn}
          />

        {columns &&
          columns.map((x: any, index: any) => (
            <Column
            key={index}
            field={x.field}
            header={x.headerName}
            headerTooltip={x.description}
            headerTooltipOptions={{ position: "top" }}
            hidden={x.hide}
            sortable={x.sortable}
            // resizeable={x.resizable}
            dataType={x.type}
            align={x.align}
            alignHeader={x.headerAlign}
            reorderable={x.disableReorder}
            exportable={x.disableExport}
            frozen={x.frozenColumn}
            alignFrozen={x.alignFrozen}
            columnKey={x.field}
            style={{
              minWidth: x.minWidth,
              fontSize: "0.875rem"
            }}
            footer={x.footer}
              // body={rows.employmentIncome}
            />
          ))}

        <Column
          body={delEdit}
          header={setting}
          style={{ maxWidth: "65px" }}
          hidden={!showSettingColumn}
          resizeable={false}
          reorderable={false}
          frozen
          alignFrozen="right"
        ></Column>
      </DataTable>
  );
};

//resizeablbe must be false if frozen is true and viseversa
