import {
  LabelCaption,
  PlaceholderText,
  ValidationMessages,
} from "common/AppMessages";
import { INITIAL_SNACKBAR_DATA } from "common/constants";
import { AccountFormLayout } from "components/AccountFormLayout";
import { IGetListRequest, ISignInRequest, ISignInResponse } from "models";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { AccountantService, LocalStorageService } from "services";
import { AuthService } from "services/AuthService";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  EmailFieldRHF,
  EnhancedSnackbar,
  RHookFormPasswordFieldWithLabel,
  RHookFormTextFieldWithLabel,
  Spacer,
  StyledRouterLink,
  SubmitButton,
  SubmitButtonVariant,
} from "@websential/cosmic";

import { setSelectedAccountant, setSelectedClient, signInSuccess } from "../../store/actions";
import { IAppState } from "store/reducers";
import { loginFormSchema } from "./validator";

// const loginFormSchema: yup.SchemaOf<ISignInRequest> = yup.object({
  // email: yup
  //   .string()
  //   .email(ValidationMessages.invalidEmailFormat)
  //   .max(50)
  //   .required(ValidationMessages.email),
  // password: yup.string().max(50).required(),
// });

const ForgotPasswordLinkComponent = () => {
  return (
    <Grid
      item
      md={12}
      lg={12}
      xl={12}
      style={{
        position: "relative",
        top: "1.3rem",
        textAlign: "right",
        zIndex: 1,
      }}
    >
      <Typography variant="subtitle2">
        <StyledRouterLink to="/forgot-password">
          Forgot Password?
        </StyledRouterLink>
      </Typography>
    </Grid>
  );
};
const LoginFormComponent = () => {
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [authData, setAuthData] = useState({})
  const [accQueryParams, setAccQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "acc_title",
    order_direction: "asc",
    page: 1,
    size: 25,
  });
  const [clientQueryParams, setClientQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "client_name",
    order_direction: "asc",
    page: 1,
    size: 25,
  });
  // const loading = useSelector((state: any) => state.viewState.loading);
  const dispatch = useDispatch();
  const methods = useForm<ISignInRequest>({
    resolver: yupResolver(loginFormSchema),
  });

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const { isLoading: isSubmitting, mutate: submitFormFields } = useMutation(
    AuthService.signIn,
    {
      onSuccess: (res: ISignInResponse) => {
        if (res.status) {
          LocalStorageService.setUser(res);
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "success",
          });
          dispatch(signInSuccess(res));
            if(res.user.isSuperStatus === 0){
              (async () => {
              try {
                const accResponse: any = await AccountantService.getAccountants(accQueryParams)
                if(accResponse && accResponse.items.length === 1){
                    dispatch(setSelectedAccountant(accResponse.items[0]));
                        try {
                          const clientResponse: any = await AccountantService.getClientsByAccountantId(accResponse.items[0].id,res.user.isSuperStatus, clientQueryParams)
                          if(clientResponse && clientResponse.items.length === 1){
                            dispatch(setSelectedClient(clientResponse.items[0]));
                          }
                        }catch{}
                      } else if(accResponse && accResponse.items.length === 0){
                        try {
                          const clientResponse: any = await AccountantService.getClientsByAccountantId('',res.user.isSuperStatus, {})
                          if(clientResponse && clientResponse.items.length === 1){
                            dispatch(setSelectedClient(clientResponse.items[0]));
                          }
                        }catch{}
                      }
                  }catch{}
                })();
              }
              (async () => {
                try {
                  const maskData: any = await  AuthService.maskData();
                }catch{} 
              })();
                console.log("resresresres",res)
              } else {
                setSnackbarData({
                  open: true,
                  message: res.message,
                  severity: "error",
          });
        }
      },
      onError: (err: any) => {
        console.log("$$$$$", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const onSubmit = (loginRequest: ISignInRequest) => {
    submitFormFields(loginRequest);
    setAuthData(loginRequest)
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container md={12} lg={12} xl={12}>
            <Grid item md={12} lg={12} xl={12}>
              <EmailFieldRHF
                label={LabelCaption.email}
                id="email"
                testId="email"
                placeholder={PlaceholderText.email}
              />
              {/* <RHookFormTextFieldWithLabel
                label={LabelCaption.email}
                id="email"
                testId="email"
                placeholder={PlaceholderText.email}
              /> */}
            </Grid>
            <ForgotPasswordLinkComponent />
            <Grid item md={12} lg={12} xl={12}>
              <RHookFormPasswordFieldWithLabel
                label={LabelCaption.password}
                id="password"
                testId="password"
                placeholder={PlaceholderText.password}
              />
            </Grid>
          </Grid>
          <Spacer y={2} x={2} />
          <SubmitButton
            label="Sign In"
            variant={SubmitButtonVariant.Primary}
            testId="sign-in-button"
            loading={isSubmitting}
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId="login-form-snackbar"
        open={snackbarData.open}
        autoHide={true}
      />
    </>
  );
};

export const LoginForm = () => {
  return (
    <AccountFormLayout
      title="Welcome to Taxslips"
      subtitle="Do not have an account yet?"
      subtitleLinkText="Create an Account"
      subtitleLinkNavigateTo="/create-an-account"
      form={<LoginFormComponent />}
    />
  );
};
