import { STICKY_NAV_BAR } from "store/types/stickyNavBarTypes";

export interface IStickyNavBar {
  isSticky:boolean;
}

const initialState: IStickyNavBar = {
  // year: 2022
 isSticky:true
};

export const stickyNavBarReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STICKY_NAV_BAR.STICKY_NAV_BAR_SET_SELECTED:
      return {
        ...state,
        isSticky: action.payload.isSticky,
      }
    case STICKY_NAV_BAR.STICKY_NAV_BAR_CLEAR_SELECTED:
      return {
        ...state,
        isSticky: initialState.isSticky,
      }
    default:
      return state;
  }
};
