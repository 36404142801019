import { DataRoleList } from "components/DataRole";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export interface IDataRoleListPage {}

export const DataRoleListPage: FC<IDataRoleListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Data Roles"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Data Roles', navigateTo: '/data-roles/list'}]}
      mainContents={<DataRoleList />}
    />
  );
};
