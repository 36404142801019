import { ACCOUNTANT_LIST_SCREEN_SLUG, DATA_ROLE_SCREEN_SLUG, INITIAL_SNACKBAR_DATA, QUERY_OPTIONS } from "common/constants";
import { IDataRoleRequestPayload, IResponse, IServerError } from "models";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DataRoleService } from "services";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  ITest,
  LabelPosition,
  RHookFormTextFieldWithLabel,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
} from "@websential/cosmic";

import { DataRoleSchema } from "./validator";
import { useNavigate, useParams } from "react-router-dom";
import { instanceOfIDataRoleResponce } from "common/instance-method";
import { Grid } from "@mui/material";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch } from "react-redux";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IDataRoleSetup extends ITest {}

const DEFAULT_VALUES = {
  roleName: "",
  appRoleName: "",
};

const SINGLE_RECORD_DATA_QUERY_KEY = "get-data-roles-list";

const useGetData = (id: string | undefined) => {
  return useQuery(
    // [SINGLE_RECORD_DATA_QUERY_KEY, id],
    SINGLE_RECORD_DATA_QUERY_KEY,
    () => DataRoleService.getById(id),
    QUERY_OPTIONS
  );
};

export const DataRoleSetup: FC<IDataRoleSetup> = ({ testId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  // const [metaFieldIds, setMetaFieldIds] = useState({});
  // const [renderForm, setRenderForm] = useState(false);
  const queryClient = useQueryClient();
  const { id } = useParams();
  console.log("params----id of data role edited", id);
  // const [editRecordId, setEditRecordId] = useState(id);

  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    DataRoleService.create,
    {
      onSuccess: (res: IDataRoleRequestPayload | IServerError | IResponse) => {
        if (instanceOfIDataRoleResponce(res)) {
          queryClient.invalidateQueries([SINGLE_RECORD_DATA_QUERY_KEY, res.id]);
          setSnackbarData({
            open: true,
            message: "Data Role has been added successfully",
            severity: "success",
          });
        }
        let path = "/data-roles";
        navigate(path);
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "An error occurred, please try again",
          severity: "error",
        });
      },
    }
  );

  const { isLoading: isLoadingData, data: selectedDataRecord } = useGetData(id);
  console.log("data of edited DR", selectedDataRecord);

  const formMethods = useForm<IDataRoleRequestPayload>({
    resolver: yupResolver(DataRoleSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit = (formData: IDataRoleRequestPayload) => {
    submitFormData(formData);
    console.log("formData     =========== ", formData);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset(DEFAULT_VALUES);
  };

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  useEffect(() => {
    // if the selected flex field data has loaded.
    if (selectedDataRecord) {
      Object.keys(selectedDataRecord).forEach((fieldName) => {
        console.log("fieldName useEffect DR : ", fieldName);
        // @ts-ignore
        const fieldValue = selectedDataRecord[fieldName];
        console.log("fieldValue useEffect DR : ", fieldValue);
        // @ts-ignore
        formMethods.setValue(fieldName, fieldValue ?? undefined);
      });
    }
  }, [selectedDataRecord, formMethods.setValue, formMethods]);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: DATA_ROLE_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={isSubmitting || isLoadingData}
        testId={`${testId}-backdrop`}
      />
      <Grid>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <SingleColumnLayout
              children={
                <StandardPanel
                  title="Data Role"
                  testId={`${testId}-data-role`}
                  leftSpacing={2}
                  rightSpacing={2}
                >
                  <RHookFormTextFieldWithLabel
                    id="roleName"
                    testId={`${testId}-role`}
                    label="Data Role"
                    labelPosition={LabelPosition.Left}
                    required
                  />
                </StandardPanel>
              }
              toolbar={
                <StandardSubmitFormButtonsToolbar
                  onResetClicked={onResetClicked}
                  isSubmitting={false}
                  testId="standard-form-submit-toolbar"
                />
              }
              testId="form-layout"
            />
          </form>
        </FormProvider>
        <EnhancedSnackbar
          message={snackbarData.message}
          onCloseHandler={onSnackbarClosed}
          severity={snackbarData.severity}
          testId={`${testId}-snackbar`}
          open={snackbarData.open}
        />
      </Grid>
    </Grid>
  );
};
