import React from "react";

import { Grid, InputLabel, Tooltip, Typography } from "@mui/material";
import { RequiredAsterisk,
  //  RHookFormTextField 
  }
    from "@websential/cosmic";
import InfoIcon from '@mui/icons-material/Info';
import { RHookFormTextField } from "components/TaxSlipDataInput/RHookFormTextField";
import { RHookFormNumericFormatTextField } from "components/TaxSlipDataInput/RHookFormNumericFormatTextField";

export interface IRL1SlipSummaryDataInput {
  id: string;
  label: string;
  required?: boolean;
  readOnly?: any | void;
  onChange?: Function;
  value?: any;
  alert?:boolean;
  alertTitle?:string;
  titlePlacement?:any;
  defaultValue?:string;
  textfield?:any;
}

export const RL1SlipSummaryDataInput: React.FC<IRL1SlipSummaryDataInput> = ({
  id,
  label,
  required = false,
  readOnly = true,
  onChange,
  value,
  alert,
  alertTitle,
  titlePlacement,
  defaultValue,
  textfield
}) => {
  return (
    <Grid container columnSpacing={0} xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid
        item
        xs={8}
        sm={8}
        md={8}
        lg={8}
        xl={8}
        sx={{ textAlign: "left", paddingTop: 2 }}
      >
        <InputLabel sx={{display:"flex"}}>
          <Typography variant="subtitle2" sx={{ whiteSpace: "normal" }}>
            {label} {required && <RequiredAsterisk />}
          </Typography>
          {alert === true ? (
              <Tooltip title={alertTitle} placement={titlePlacement}>
                  <InfoIcon sx={{fontSize:"1.1rem",margin:"1.5px"}}/>
              </Tooltip>
            ): undefined}
        </InputLabel>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        {/* {<RHookFormTextField 
          id={id} testId={id} 
          readOnly={readOnly} 
          onChange={onChange} 
          value={value}
          defaultValue={defaultValue}
          textAlign={'end'}
        />} */}
        {textfield ? 
        <RHookFormTextField 
        id={id} testId={id} 
        readOnly={readOnly} 
        onChange={onChange} 
        value={value}
        defaultValue={defaultValue}
        textAlign={'end'}
      />:
          <RHookFormNumericFormatTextField
            id={id}
            testId={id}
            readOnly={readOnly}
            onChange={onChange}
            fieldValue={value}
            defaultValue={defaultValue}
            textAlign={'end'}
          />
        }
      </Grid>
    </Grid>
  );
};
