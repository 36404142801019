import { FC, useState } from "react";

import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Toolbar } from "@mui/material";
import {
  DeleteButton,
  DisabledButton,
  ResetButton,
  SplitButton,
  SubmitButton,
} from "@websential/cosmic";
import { useQuery } from "react-query";
import { T4ASlipService } from "services/T4ASlipService";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { selectedT4ASlipType } from "../T4ASlipSetup";
import { selectedT4ASummaryType } from "components/T4ASlip/T4ASlipSummarySetup";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PrintIcon from "@mui/icons-material/Print";

export interface IT4ASlipSetupToolbar {
  onResetClicked: () => void;
  onDeleteClicked: () => void;
  onPrintClicked: (printType:string) => void;
  isSubmitting: boolean;
  formMethods: any
}

const T4A_SUMMARY_DATA_QUERY_KEY = "get-t4a-summary-data";
const useGetT4ASummaryData = (
  clientid: string,
  slipType: string | number,
  taxYear: number) =>
  useQuery(
    [T4A_SUMMARY_DATA_QUERY_KEY, clientid, slipType, taxYear],
    (data) => {
      return T4ASlipService.getT4ASummary(clientid, slipType, taxYear);
    },
    { refetchOnWindowFocus: false }
  );


export const T4ASlipSetupToolbar: FC<IT4ASlipSetupToolbar> = ({
  onResetClicked,
  onDeleteClicked,
  onPrintClicked,
  isSubmitting = false,
  formMethods
}) => {
  const id = useSelector((state: IAppState)=> state.client.id)
  const taxYear = useSelector((state: IAppState)=> state.taxYear.year)
  // const [selectedType, setSelectedType] = useState(400);
  const {
    isLoading: isLoadingSummaryGetData,
    data: t4aSummaryData } =
  useGetT4ASummaryData(id ,selectedT4ASummaryType ,taxYear);
  // console.log("selectedType",selectedType)

  console.log("t4aSummaryData ============",t4aSummaryData)
  
  
  const onchangeTooltip = () => {
    //it have to optional from cosmic then remove it 
  }
  const selectedSlip = formMethods.getValues('selectedSlip')

  return (
    <Toolbar>
      <>
      <div style={{display:"contents"}}>
        <div style={{margin:"1rem 0rem"}}>
            {t4aSummaryData && t4aSummaryData.confirmationCode !== null 
            && selectedT4ASlipType === 400 ? 
            (
              <div style={{cursor:"not-allowed"}}>
                <DisabledButton 
                  tooltiptitle={"Confirmation No. has been enterned in [Complaince -> t4a -> Summary]"} 
                  isDisabled={true}
                  buttonLabel={"Submit"}
                  icon={<PlaylistAddCheckOutlinedIcon />}
                  testId='disableButton' 
                  onClick={onchangeTooltip} />
              </div>
            ):(
              <SubmitButton
                label="Submit"
                testId="submit-button"
                loading={isSubmitting}
                icon={<PlaylistAddCheckOutlinedIcon />}
                
              />
              )
            }
          </div>
          <ResetButton testId="reset-button" onClickHandler={onResetClicked} />
          <div style={{margin:"1rem 0rem"}}>
          {t4aSummaryData && t4aSummaryData.confirmationCode !== null 
          && selectedT4ASlipType === 400 ? 
          (
            <div style={{cursor:"not-allowed",marginLeft:"1rem"}}>
              <DisabledButton 
                tooltiptitle={"Confirmation No. has been enterned in [Complaince -> t4 -> Summary]"} 
                isDisabled={true}
                buttonLabel={"Delete"}
                icon={<DeleteOutlineIcon />}
                testId='disableButton' 
                onClick={onchangeTooltip} />
            </div>
          ): selectedSlip && selectedSlip === 'create-new' ?
          (
            <div style={{cursor:"not-allowed",marginLeft:"1rem"}}>
              <DisabledButton 
                tooltiptitle={""} 
                isDisabled={true}
                buttonLabel={"Delete"}
                icon={<DeleteOutlineIcon />}
                testId='disableButton' 
                onClick={onchangeTooltip} />
            </div>
          ):
          (
            <DeleteButton
            testId="delete-button"
            onClickHandler={onDeleteClicked}
            />
            )
          } 
          </div>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div>
          {selectedSlip && selectedSlip === 'create-new' ?
          (
            <div style={{cursor:"not-allowed",marginLeft:"1rem"}}>
              <DisabledButton 
                tooltiptitle={""} 
                isDisabled={true}
                buttonLabel={"Print"}
                icon={<PrintIcon />}
                testId='disableButton' 
                onClick={onchangeTooltip} />
            </div>
          ):(
        <SplitButton
            options={[
              "Vendor",
              "Employer",
              "CRA",
            ]}
            onClickHandler={onPrintClicked}
            testId="t4a-print-button"
            icon={<PrintIcon />}
            listItemsLabel={`Print T4A Slip  `}
          />
          )}
        </div>
      </>
    </Toolbar>
  );
};
