import { IGetListRequest } from "models";
import { IAccountantListPayload } from "models/accountant";
import { IClientListPayload, IClientRequest } from "models/client";

import { ACCOUNTANT } from "../types";

export const loadAccountantListRequest = (requestPayload: IGetListRequest) => {
  return {
    type: ACCOUNTANT.ACCOUNTANT_LIST_REQUEST_LOAD,
    payload: { ...requestPayload },
  };
};

export const loadAccountantListRequestSucceeded = (
  responsePayload: IAccountantListPayload
) => {
  return {
    type: ACCOUNTANT.ACCOUNTANT_LIST_REQUEST_SUCCESS,
    payload: { ...responsePayload },
  };
};

export const loadAccountantListRequestFailed = () => {
  return {
    type: ACCOUNTANT.ACCOUNTANT_LIST_REQUEST_FAILED,
  };
};

export const loadClientListRequest = (
  requestPayload: IClientRequest & IGetListRequest
) => {
  return {
    type: ACCOUNTANT.CLIENT_LIST_REQUEST_LOAD,
    payload: { ...requestPayload },
  };
};

export const loadClientListRequestSucceeded = (
  responsePayload: IClientListPayload
) => {
  return {
    type: ACCOUNTANT.CLIENT_LIST_REQUEST_SUCCESS,
    payload: { ...responsePayload },
  };
};

export const loadClientListRequestFailed = () => {
  return {
    type: ACCOUNTANT.CLIENT_LIST_REQUEST_FAILED,
  };
};

export const setSelectedAccountant = (selectAccountant: any) => {
  return {
    type: ACCOUNTANT.ACCOUNTANT_SET_SELECTED,
    payload: {...selectAccountant}
  }
}

export const clearSelectedAccountant = () => {
  return {
    type: ACCOUNTANT.ACCOUNTANT_CLEAR_SELECTED
  }
}
export const setSelectedAccountantName = (selectAccountant: any) => {
  return {
    type: ACCOUNTANT.ACCOUNTANT_NAME_SET_SELECTED,
    payload: {...selectAccountant}
  }
}

export const clearSelectedAccountantName = () => {
  return {
    type: ACCOUNTANT.ACCOUNTANT_NAME_CLEAR_SELECTED
  }
}