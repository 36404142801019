import { AccountLayout } from 'layouts';

import { LoginForm } from '../../components/LoginForm';

export const LoginPage = () => {
  return (
    <AccountLayout
      contents={<LoginForm />}
    />
  );
};
