import { DataType, FieldType } from '@websential/cosmic';
import IField from '@websential/cosmic/build/components/RHookFormGenerator/types/IField';
import { checkSINValidity } from 'components/T4Slip/T4SlipSetup/validator';
import * as yup from 'yup';
const getMinMaxValidationMessage = (fieldLabel: string, minOrMaxValue: number | string, dataType: DataType, isMax: boolean = true): string => {
    const suffix = `${dataType !== DataType.Number && dataType !== DataType.Array && dataType !== DataType.Object ? ' characters' : ''}`;
    return `${fieldLabel} must be at ${isMax ? 'most' : 'least'} ${minOrMaxValue}${suffix}!`
}
type YupValidationSchema = yup.StringSchema | yup.NumberSchema<number | null | undefined> | yup.DateSchema | yup.ArraySchema<any> | null;

/**
 * Get the validation schema of a field based on its data type.
 * 
 * @param {IField} field 
 * @returns {YupValidationSchema}
 */

export const clientJSONdataValidation = (field: IField): YupValidationSchema => {
    const { dataType, isRequired, maxValue, minValue, fieldLabel, fieldType } = field;
    console.log("fieldType--",fieldType)
    const isDateField = fieldType === FieldType.Date;
    let fieldSchemaChain: any = null;
    if (fieldType === FieldType.MultiSelectionDropDownList) {
        fieldSchemaChain = yup.array().of(yup.string());
    } else if (fieldType === FieldType.DropDownList) {
        fieldSchemaChain = yup.string()
    } else if (dataType === DataType.String) {
        fieldSchemaChain = yup.string()
        if (fieldType === FieldType.Email) {
            fieldSchemaChain = fieldSchemaChain.email("Please add a valid email")
        } else if (fieldType === FieldType.URL) {
            fieldSchemaChain = fieldSchemaChain.url("Please add a valid url")
        }
    } else if (dataType === DataType.Number) {
        fieldSchemaChain = yup
            .number()
            .typeError(`${fieldLabel} is required!`)
            .nullable()
            .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    } else if (dataType === DataType.Boolean) {
        yup.boolean().oneOf([true, false]);
    } else if (fieldType === FieldType.Date) {
      if(isRequired){
        fieldSchemaChain = yup.date().typeError(`${fieldLabel} is required!`).required().nullable();
      }
    } else if (fieldType === FieldType.BusinessNumber) {
        fieldSchemaChain = yup.string().test(
            'is-valid-or-not',
            'Invalid value',
            function (value:any) {
              let maxLength = 13;
              const firstNineDigits: string = value && value.substring(0, 9);
              console.log("valueeeee-- bn",firstNineDigits)
              const output = checkSINValidity(firstNineDigits);
              console.log("output bn",output)
              
                if (value && value.length == maxLength && output % 10 === 0) {
                  return true;
                } else {
                  return this.createError({
                    message: 'Invalid Business Number',
                    path: this.path,
                  });
                }
            }
          ).typeError(`${fieldLabel} is required!`).required().nullable();
    }
    console.log("fieldSchemaChain",fieldSchemaChain)
    // we need to make sure that we have initialized it first
    if (fieldSchemaChain !== null) {
        if (maxValue && !isDateField) {
            fieldSchemaChain = fieldSchemaChain.max(Number(maxValue), getMinMaxValidationMessage(fieldLabel, maxValue, dataType))
        }
        if ((minValue || minValue === 0) && !isDateField) {
            fieldSchemaChain = fieldSchemaChain.min(Number(minValue), getMinMaxValidationMessage(fieldLabel, minValue, dataType, false))
        }
        if (isRequired) {
            fieldSchemaChain = fieldSchemaChain.required(`${fieldLabel} is required!`)
        }
        if (isRequired && fieldType === FieldType.BusinessNumber) {
            fieldSchemaChain = yup.string().test(
                'is-valid-or-not',
                'Invalid value',
                function (value:any) {
                  let maxLength = 15;
                  const firstNineDigits: string = value && value.substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);                  
                    if (value && value.length == maxLength && output % 10 === 0) {
                        return true;
                    } else {
                        return this.createError({
                            message: `Invalid ${fieldLabel}`,
                            path: this.path,
                        });
                    }
                }
              ).typeError(`${fieldLabel} is required!`).required().nullable();
        }
        if (fieldType === FieldType.ReducedElAccountNumber) {
            fieldSchemaChain = yup.string().test(
                'is-valid-or-not',
                'Invalid value',
                function (value:any) {
                  console.log("value;",value)
                  if(value){
                  let maxLength = 15;
                  const firstNineDigits: string = value && value.substring(0, 9);
                  const output = checkSINValidity(firstNineDigits);                  
                    if (value && value.length == maxLength && output % 10 === 0) {
                        return true;
                      } else {
                        return this.createError({
                            message: `Invalid ${fieldLabel}`,
                            path: this.path,
                        });
                      }
                    }
                    return true;
                }
              ).nullable();
        }
        if (isRequired && fieldType === FieldType.SINNumber) {
            fieldSchemaChain = yup.string().test(
                'is-valid-or-not',
                'Invalid value',
                function (value:any) {
                    let sin = value && value.replace(/-/g, '')
                    const output = checkSINValidity(sin);
                    console.log("output",output)
                    let maxLength = 11;
              
                    if (value && value.charAt(2) === '*') {
                      maxLength = 7;
                      if (value && value.length == maxLength) {
                        return true;
                      } else {
                        return this.createError({
                          message: 'Invalid Social Insuarance Number',
                          path: this.path,
                        });
                      }
                    } else {
                      if (value && value.length == maxLength && output % 10 === 0) {
                        return true;
                      } else {
                        return this.createError({
                          message: 'Invalid Social Insuarance Number',
                          // message: 'Social Insuarance Number has at least 9 characters',
                          path: this.path,
                        });
                      }
                    }
                  }
              ).typeError(`${fieldLabel} is required!`).required().nullable();
        }
        if (isRequired && fieldLabel === "Account # for T5") {
          fieldSchemaChain = yup.string().test(
            'is-valid-or-not',
            'Invalid value',
            function (value:any) {
              let maxLength = 15;
              const firstNineDigits: string = value && value.substring(0, 9);
              const output = checkSINValidity(firstNineDigits);                  
                if (value && value.length == maxLength && output % 10 === 0) {
                    return true;
                } else {
                    return this.createError({
                        message: `Invalid ${fieldLabel}`,
                        path: this.path,
                    });
                }
            }
          ).typeError(`${fieldLabel} is required!`).required().nullable();
        }
    }
    return fieldSchemaChain;
}