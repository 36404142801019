import { AxiosResponse } from "axios";
import http from "common/http-common";
import {
  IResponse,
  IServerError,
} from "../models";
import { IRemittanceRequestPayload } from "models/common/ITransferRemittance";
export const AdjustmentService = {
  createPierReview: async (clientId: string, taxYear: number): Promise<IServerError | IResponse> => {
    if (clientId) {
      const response: AxiosResponse= await http.get(
        `/v1/pier-review/?client_id=${clientId}&tax_year=${taxYear}`);
      if (response.status === 200) {
        console.log("status response data", response.data)
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createT4Adjustment: async (clientId: string, taxYear: number): Promise<IServerError | IResponse> => {
    if (clientId) {
      const response: AxiosResponse= await http.post(
        `/v1/t4-slips/apply-bulk-adjustments/${clientId}/?tax_year=${taxYear}`);
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 400) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  undoT4Adjustment: async (clientId: string, taxYear: number): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const response: AxiosResponse= await http.put(
        `/v1/t4-slips/clear-bulk-adjustments/${clientId}/?tax_year=${taxYear}`);
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createRL1Adjustment: async (clientId: string, taxYear: number): Promise<IServerError | IResponse> => {
    if (clientId) {
      const response: AxiosResponse= await http.post(
        `/v1/rl1-slips/apply-bulk-adjustments/${clientId}/?tax_year=${taxYear}`);
        if (response.status === 201) {
        return response.data;
      }
      else if (response.status === 400) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  undoRL1Adjustment: async (clientId: string, taxYear: number): Promise<IServerError | IResponse | any> => {
    if (clientId) {
      const response: AxiosResponse= await http.put(
        `/v1/rl1-slips/clear-bulk-adjustments/${clientId}/?tax_year=${taxYear}`);
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getT4Adjutments: async (clientId: string, taxYear: number): Promise<IServerError | IResponse> => {
    if (clientId) {
      const response: AxiosResponse= await http.get(
        `/v1/pier-review/t4-adjusted-slips/${clientId}?tax_year=${taxYear}`);
      if (response.status === 200) {
        console.log("status response t4 data", response.data)
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getRL1Adjutments: async (clientId: string, taxYear: number): Promise<IServerError | IResponse> => {
    if (clientId) {
      const response: AxiosResponse= await http.get(
        `/v1/pier-review/rl1-adjusted-slips/${taxYear}?client_id=${clientId}`);
      if (response.status === 200) {
        console.log("status response rl1 data", response.data)
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

transfert4remittance: async (clientId: string, taxYear: number, requestData: IRemittanceRequestPayload): Promise<IServerError | IResponse> => {
  if (clientId) {
    console.log("hehehehehehe", requestData)
    const response: AxiosResponse= await http.post(
      `/v1/t4-slips/apply-remittance/${clientId}/?tax_year=${taxYear}`, requestData);
    if (response.status === 200) {
      return {
        status: true,
        message: "T4 Adjustment Successfully Transferrred.",
      };
    } else if (response.status === 400) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
  }
  return {
    status: false,
    message: "Something went wrong please try again later.",
  };
},

undotransfert4remittance: async (clientId: string, taxYear: number, requestData: IRemittanceRequestPayload): Promise<IServerError | IResponse> => {
  if (clientId) {
    console.log("requestData56",requestData)
    const response: AxiosResponse= await http.put(
      `/v1/t4-slips/undo-remittance/${clientId}/?tax_year=${taxYear}`, requestData);
    if (response.status === 200) {
      return {
        status: true,
        message: "Undo T4 Remittance successfully.",
        error: false,
      };
    }else if (response.status === 400) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
  }
  return {
    status: false,
    message: "Something went wrong please try again later.",
  };
},

transferrl1remittance: async (clientId: string, taxYear: number, requestData: IRemittanceRequestPayload): Promise<IServerError | IResponse> => {
  if (clientId) {
    const response: AxiosResponse= await http.post(
      `/v1/rl1-slips/apply-remittance/${clientId}/?tax_year=${taxYear}`, requestData);
    if (response.status === 200) {
      // alert("RL1-Remitaance has been transfere")
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
  }
  return {
    status: false,
    message: "Something went wrong please try again later.",
  };
},

undotransferrl1remittance: async (clientId: string, taxYear: number, requestData: IRemittanceRequestPayload): Promise<IServerError | IResponse> => {
  if (clientId) {
    const response: AxiosResponse= await http.put(
      `/v1/rl1-slips/undo-remittance/${clientId}/?tax_year=${taxYear}`, requestData);
    if (response.status === 200) {
      return {
        status: true,
        message: "Undo RL1 Remittance successfully.",
        error: false,
      };
    } else if (response.status === 400) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
  }
  return {
    status: false,
    message: "Something went wrong please try again later.",
  };
},
getT4OverRemittanceData: async (clientId: string, taxYear: number,
  t4SlipIds: string): Promise<IServerError | IResponse> => {
  if (clientId) {
    const response: AxiosResponse= await http.get(
      `/v1/adjustment-options/${clientId}/t4/?tax_year=${taxYear}&${t4SlipIds}`);
    if (response.status === 200) {
      console.log("status response t4 data", response.data)
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
  }
  return {
    status: false,
    message: "Something went wrong please try again later.",
  };
},
getRL1OverRemittanceData: async (clientId: string, taxYear: number,
  t4SlipIds: string): Promise<IServerError | IResponse> => {
  if (clientId) {
    const response: AxiosResponse= await http.get(
      `/v1/adjustment-options/${clientId}/rl1/?tax_year=${taxYear}&${t4SlipIds}`);
    if (response.status === 200) {
      console.log("status response t4 data", response.data)
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
  }
  return {
    status: false,
    message: "Something went wrong please try again later.",
  };
},
}