import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IRL1SLipAxisPage2 {
    taxYear:number
}
const RL1SLipAxisPage2:React.FC<IRL1SLipAxisPage2> = ({taxYear}) => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP2X'} 
                testId={'taxYearP2X'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={328}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxAP2X'} 
                testId={'boxAP2X'} 
                label={'Box A'} 
                labelPosition={LabelPosition.Left}
                defaultValue={92.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxBP2X'} 
                testId={'boxBP2X'} 
                label={'Box B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={189}
            />

            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'boxBBP2X'} 
                testId={'boxBBP2X'} 
                label={'Box BB'} 
                labelPosition={LabelPosition.Left}
                defaultValue={189}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'boxCP2X'} 
                testId={'boxCP2X'} 
                label={'Box C'} 
                labelPosition={LabelPosition.Left}
                defaultValue={284}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxDP2X'} 
                testId={'boxDP2X'} 
                label={'Box D'} 
                labelPosition={LabelPosition.Left}
                defaultValue={379}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxEP2X'} 
                testId={'boxEP2X'} 
                label={'Box E'} 
                labelPosition={LabelPosition.Left}
                defaultValue={474}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxFP2X'} 
                testId={'boxFP2X'} 
                label={'Box F'} 
                labelPosition={LabelPosition.Left}
                defaultValue={567}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxGP2X'} 
                testId={'boxGP2X'} 
                label={'Box G'} 
                labelPosition={LabelPosition.Left}
                defaultValue={92.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxHP2X'} 
                testId={'boxHP2X'} 
                label={'Box H'} 
                labelPosition={LabelPosition.Left}
                defaultValue={189}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxIP2X'} 
                testId={'boxIP2X'} 
                label={'Box I'} 
                labelPosition={LabelPosition.Left}
                defaultValue={284}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxJP2X'} 
                testId={'boxJP2X'} 
                label={'Box J'} 
                labelPosition={LabelPosition.Left}
                defaultValue={379}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxKP2X'} 
                testId={'boxKP2X'} 
                label={'Box K'} 
                labelPosition={LabelPosition.Left}
                defaultValue={474}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxLP2X'} 
                testId={'boxLP2X'} 
                label={'Box L'} 
                labelPosition={LabelPosition.Left}
                defaultValue={567}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxMP2X'} 
                testId={'boxMP2X'} 
                label={'Box M'} 
                labelPosition={LabelPosition.Left}
                defaultValue={92.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxNP2X'} 
                testId={'boxNP2X'} 
                label={'Box N'} 
                labelPosition={LabelPosition.Left}
                defaultValue={189}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxOP2X'} 
                testId={'boxOP2X'} 
                label={'Box O'} 
                labelPosition={LabelPosition.Left}
                defaultValue={284}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxPP2X'} 
                testId={'boxPP2X'} 
                label={'Box P'} 
                labelPosition={LabelPosition.Left}
                defaultValue={379}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxQP2X'} 
                testId={'boxQP2X'} 
                label={'Box Q'} 
                labelPosition={LabelPosition.Left}
                defaultValue={474}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxRP2X'} 
                testId={'boxRP2X'} 
                label={'Box R'} 
                labelPosition={LabelPosition.Left}
                defaultValue={567}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxSP2X'} 
                testId={'boxSP2X'} 
                label={'Box C'} 
                labelPosition={LabelPosition.Left}
                defaultValue={92.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxTP2X'} 
                testId={'boxTP2X'} 
                label={'Box T'} 
                labelPosition={LabelPosition.Left}
                defaultValue={189}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxUP2X'} 
                testId={'boxUP2X'} 
                label={'Box U'} 
                labelPosition={LabelPosition.Left}
                defaultValue={284}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxVP2X'} 
                testId={'boxVP2X'} 
                label={'Box V'} 
                labelPosition={LabelPosition.Left}
                defaultValue={379}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxWP2X'} 
                testId={'boxWP2X'} 
                label={'Box W'} 
                labelPosition={LabelPosition.Left}
                defaultValue={474}
            />
            <RHookFormTextFieldWithLabel 
                id={'codeOP2X'} 
                testId={'codeOP2X'} 
                label={'Code O'} 
                labelPosition={LabelPosition.Left}
                defaultValue={567}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P2X'} 
                testId={'otherInfoAmount1P2X'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={145}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P2X'} 
                testId={'otherInfoCode1P2X'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={74}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P2X'} 
                testId={'otherInfoAmount2P2X'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={270.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P2X'} 
                testId={'otherInfoCode2P2X'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={204}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P2X'} 
                testId={'otherInfoAmount3P2X'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P2X'} 
                testId={'otherInfoCode3P2X'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={355}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P2X'} 
                testId={'otherInfoAmount4P2X'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={542.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P2X'} 
                testId={'otherInfoCode4P2X'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={470}
            />
            <RHookFormTextFieldWithLabel 
                id={'codeDuRelevéP2X'} 
                testId={'codeDuRelevéP2X'} 
                label={'Code DuRelevé'} 
                labelPosition={LabelPosition.Left}
                defaultValue={372}
            />
            <RHookFormTextFieldWithLabel 
                id={'noDuDernierRelevéTransmisP2X'} 
                testId={'noDuDernierRelevéTransmisP2X'} 
                label={'No Du Dernier Relevé Transmis'} 
                labelPosition={LabelPosition.Left}
                defaultValue={430}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl1202210AboveBoxP2X'} 
                testId={'rl1202210AboveBoxP2X'} 
                label={'Rl1 2022 10 Above Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={510}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl1202210DownBoxP2X'} 
                testId={'rl1202210DownBoxP2X'} 
                label={'Rl1 2022 10 Down Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={510}
            />
                    <RHookFormTextFieldWithLabel 
                        id={'resourceFirstLastNameP2X'} 
                        testId={'resourceFirstLastNameP2X'} 
                        label={'Resource First Last Name'} 
                        labelPosition={LabelPosition.Left}
                        defaultValue={40}
                    />
            <RHookFormTextFieldWithLabel 
                id={'resourceAddressP2X'} 
                testId={'resourceAddressP2X'} 
                label={'Resource Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceCityP2X'} 
                testId={'resourceCityP2X'} 
                label={'Resource City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientSocialInsurance1To3P2X'} 
                testId={'clientSocialInsurance1To3P2X'} 
                label={'Client Social Insurance 1 To 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={340}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientSocialInsurance4To6P2X'} 
                testId={'clientSocialInsurance4To6P2X'} 
                label={'Client Social Insurance 4 To 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={380}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientSocialInsurance7To9P2X'} 
                testId={'clientSocialInsurance7To9P2X'} 
                label={'Client Social Insurance 7 To 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={420}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientReferenceNo1To3P2X'} 
                testId={'clientReferenceNo1To3P2X'} 
                label={'Client Reference No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={485}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientNameP2X'} 
                testId={'clientNameP2X'} 
                label={'Client Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={370}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientAddressP2X'} 
                testId={'clientAddressP2X'} 
                label={'Client Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={370}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientCityP2X'} 
                testId={'clientCityP2X'} 
                label={'Client City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={370}
            />
            
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP2Y'} 
                testId={'taxYearP2Y'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={954}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxAP2Y'} 
                testId={'boxAP2Y'} 
                label={'Box A'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxBP2Y'} 
                testId={'boxBP2Y'} 
                label={'Box B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'boxBBP2Y'} 
                testId={'boxBBP2Y'} 
                label={'Box BB'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'boxCP2Y'} 
                testId={'boxCP2Y'} 
                label={'Box C'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxDP2Y'} 
                testId={'boxDP2Y'} 
                label={'Box D'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxEP2Y'} 
                testId={'boxEP2Y'} 
                label={'Box E'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxFP2Y'} 
                testId={'boxFP2Y'} 
                label={'Box F'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxGP2Y'} 
                testId={'boxGP2Y'} 
                label={'Box G'} 
                labelPosition={LabelPosition.Left}
                defaultValue={683}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxHP2Y'} 
                testId={'boxHP2Y'} 
                label={'Box H'} 
                labelPosition={LabelPosition.Left}
                defaultValue={683}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxIP2Y'} 
                testId={'boxIP2Y'} 
                label={'Box I'} 
                labelPosition={LabelPosition.Left}
                defaultValue={683}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxJP2Y'} 
                testId={'boxJP2Y'} 
                label={'Box J'} 
                labelPosition={LabelPosition.Left}
                defaultValue={683}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxKP2Y'} 
                testId={'boxKP2Y'} 
                label={'Box K'} 
                labelPosition={LabelPosition.Left}
                defaultValue={683}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxLP2Y'} 
                testId={'boxLP2Y'} 
                label={'Box L'} 
                labelPosition={LabelPosition.Left}
                defaultValue={683}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxMP2Y'} 
                testId={'boxMP2Y'} 
                label={'Box M'} 
                labelPosition={LabelPosition.Left}
                defaultValue={659}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxNP2Y'} 
                testId={'boxNP2Y'} 
                label={'Box N'} 
                labelPosition={LabelPosition.Left}
                defaultValue={659}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxOP2Y'} 
                testId={'boxOP2Y'} 
                label={'Box O'} 
                labelPosition={LabelPosition.Left}
                defaultValue={659}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxPP2Y'} 
                testId={'boxPP2Y'} 
                label={'Box P'} 
                labelPosition={LabelPosition.Left}
                defaultValue={659}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxQP2Y'} 
                testId={'boxQP2Y'} 
                label={'Box Q'} 
                labelPosition={LabelPosition.Left}
                defaultValue={659}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxRP2Y'} 
                testId={'boxRP2Y'} 
                label={'Box R'} 
                labelPosition={LabelPosition.Left}
                defaultValue={659}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxSP2Y'} 
                testId={'boxSP2Y'} 
                label={'Box C'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxTP2Y'} 
                testId={'boxTP2Y'} 
                label={'Box T'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxUP2Y'} 
                testId={'boxUP2Y'} 
                label={'Box U'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxVP2Y'} 
                testId={'boxVP2Y'} 
                label={'Box V'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxWP2Y'} 
                testId={'boxWP2Y'} 
                label={'Box W'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'codeOP2Y'} 
                testId={'codeOP2Y'} 
                label={'Code O'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P2Y'} 
                testId={'otherInfoAmount1P2Y'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P2Y'} 
                testId={'otherInfoCode1P2Y'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P2Y'} 
                testId={'otherInfoAmount2P2Y'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P2Y'} 
                testId={'otherInfoCode2P2Y'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P2Y'} 
                testId={'otherInfoAmount3P2Y'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P2Y'} 
                testId={'otherInfoCode3P2Y'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P2Y'} 
                testId={'otherInfoAmount4P2Y'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P2Y'} 
                testId={'otherInfoCode4P2Y'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'codeDuRelevéP2Y'} 
                testId={'codeDuRelevéP2Y'} 
                label={'Code DuRelevé'} 
                labelPosition={LabelPosition.Left}
                defaultValue={737}
            />
            <RHookFormTextFieldWithLabel 
                id={'noDuDernierRelevéTransmisP2Y'} 
                testId={'noDuDernierRelevéTransmisP2Y'} 
                label={'No Du Dernier Relevé Transmis'} 
                labelPosition={LabelPosition.Left}
                defaultValue={737}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl1202210AboveBoxP2Y'} 
                testId={'rl1202210AboveBoxP2Y'} 
                label={'Rl1 2022 10 Above Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={749.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl1202210DownBoxP2Y'} 
                testId={'rl1202210DownBoxP2Y'} 
                label={'Rl1 2022 10 Down Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={737}
            />
                    <RHookFormTextFieldWithLabel 
                        id={'resourceFirstLastNameP2Y'} 
                        testId={'resourceFirstLastNameP2Y'} 
                        label={'Resource First Last Name'} 
                        labelPosition={LabelPosition.Left}
                        defaultValue={115}
                    />
            <RHookFormTextFieldWithLabel 
                id={'resourceAddressP2Y'} 
                testId={'resourceAddressP2Y'} 
                label={'Resource Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={102}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceCityP2Y'} 
                testId={'resourceCityP2Y'} 
                label={'Resource City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={90}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientSocialInsurance1To3P2Y'} 
                testId={'clientSocialInsurance1To3P2Y'} 
                label={'Client Social Insurance 1 To 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={170}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientSocialInsurance4To6P2Y'} 
                testId={'clientSocialInsurance4To6P2Y'} 
                label={'Client Social Insurance 4 To 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={170}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientSocialInsurance7To9P2Y'} 
                testId={'clientSocialInsurance7To9P2Y'} 
                label={'Client Social Insurance 7 To 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={170}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientReferenceNo1To3P2Y'} 
                testId={'clientReferenceNo1To3P2Y'} 
                label={'Client Reference No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={170}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientNameP2Y'} 
                testId={'clientNameP2Y'} 
                label={'Client Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={130}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientAddressP2Y'} 
                testId={'clientAddressP2Y'} 
                label={'Client Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={115}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientCityP2Y'} 
                testId={'clientCityP2Y'} 
                label={'Client City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={100}
            />
        </Grid>
    </Grid>
  )
}

export default RL1SLipAxisPage2