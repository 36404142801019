import { Box } from "@mui/material";
import { FC } from "react";

// import { EmptyList } from "@websential/ws-react-ui/lib/components/EmptyList";

export const EmptyAccountantList: FC = () => {
  const image =
    "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_accountant_list.png";
  return (
    <Box>No data found</Box>
    // <EmptyList
    //   imagePath={image}
    //   heading="There's nothing! Add accountants to proceed next"
    //   content='Click "Add Accountant" button to add an accountant'
    //   addButtonLabel="Add Accountant"
    //   showImage={true}
    //   showAddButton={true}
    //   showImportButton={false}
    //   navigateToAdd="/accountants/add"
    //   testId="accountant"
    // />
  );
};
