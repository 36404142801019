import { IResponse, IServerError } from "@websential/cosmic";
import { AxiosResponse } from "axios";
import http from "common/http-common";
import { IChangePasswordRequest } from "models/changePassword/IChangePasswordRequest";
import { IChangePasswordResponse } from "models/changePassword/IChangePasswordResponse";

// TODO: replace this with API response once it's ready
export const ChangePasswordService = {
    update: async (requestPayload: IChangePasswordRequest): Promise<IChangePasswordResponse  | IServerError | IResponse> => {
        let response: AxiosResponse = await http.post("/reset-password",requestPayload);
        if(response.status === 200){
            return response.data
        }  else if (response.status === 400) {
            return response.data;
          } else if (response.status === 500) {
            return {
              status: false,
              message: "Something went wrong.",
              error: true,
            };
          }
      
          return {
            status: false,
            message: "Something went wrong please try again later.",
          };
        
        // return new Promise(resolve => setTimeout(() => resolve(response), 1000));
    }
};
