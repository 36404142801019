import React from "react";

import { Grid } from "@mui/material";
import { BackdropCircularProgress } from "@websential/cosmic";

export interface ISetupFormLayout {
  isLoadingData: boolean;
  renderForm: boolean;
  isSubmittingForm: boolean;
  forMultipleColumn?: boolean;
  children: React.ReactNode;
}

export const SetupFormLayout: React.FC<ISetupFormLayout> = ({
  isLoadingData,
  renderForm,
  isSubmittingForm,
  forMultipleColumn,
  children,
}) => {
  return (
    <>
      {isLoadingData || !renderForm ? (
        <BackdropCircularProgress
          open={isLoadingData}
          testId="accountant-setup-loading"
        />
      ) : (
        <>
          <BackdropCircularProgress
            open={isSubmittingForm}
            testId="accountant-setup-submitting"
          />
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            {forMultipleColumn ? <Grid item md={0} lg={0} xl={0} /> : <Grid item md={1} lg={1} xl={1} />}
            <Grid item md={forMultipleColumn ? 12 : 10} lg={forMultipleColumn ? 12 : 10} xl={forMultipleColumn ? 12 : 10} sx={{ paddingTop: 1 }}>
              {children}
            </Grid>
            {forMultipleColumn ? <Grid item md={0} lg={0} xl={0} /> : <Grid item md={1} lg={1} xl={1} />}
          </Grid>
        </>
      )}
    </>
  );
};
