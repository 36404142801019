import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { EarningsAuditSetup } from "components/Audit/EarningsAuditSetup";

export const EarningsAuditSetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Earnings Audit"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<EarningsAuditSetup testId="earnings-audit-setup-page" />}
    />
  );
};
