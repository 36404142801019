import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IT4SLipAxisPage2 {
    taxYear:number
}
const T4SLipAxisPage2:React.FC<IT4SLipAxisPage2> = ({taxYear}) => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'slipTypeP2X'} 
                testId={'slipTypeP2X'} 
                label={'Slip Type'} 
                labelPosition={LabelPosition.Left}
                defaultValue={412}
            />
            <RHookFormTextFieldWithLabel 
                id={'taxYearP2X'} 
                testId={'taxYearP2X'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={308}
            />
            <RHookFormTextFieldWithLabel 
                id={'pipInsurableEarningsP2X'} 
                testId={'pipInsurableEarningsP2X'} 
                label={'PIP Insurable Earnings'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'rppOrDpspRegistrationNumberP2X'} 
                testId={'rppOrDpspRegistrationNumberP2X'} 
                label={'Rpp/Dpsp Registration Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'charitableDonationsP2X'} 
                testId={'charitableDonationsP2X'} 
                label={'Charitable Donations'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'unionDuesP2X'} 
                testId={'unionDuesP2X'} 
                label={'Union Dues'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'cppQppPensionableEarningsP2X'} 
                testId={'cppQppPensionableEarningsP2X'} 
                label={'cppQpp Pensionable Earnings'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'eiInsurableEarningsP2X'} 
                testId={'eiInsurableEarningsP2X'} 
                label={'Ei Insurable Earnings'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'incomeTaxDeductedP2X'} 
                testId={'incomeTaxDeductedP2X'} 
                label={'Income Tax Deducted'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'ppipPremiumsP2X'} 
                testId={'ppipPremiumsP2X'} 
                label={'Ppip Premiums'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            <RHookFormTextFieldWithLabel 
                id={'pensionAdjustmentFacteurP2X'} 
                testId={'pensionAdjustmentFacteurP2X'} 
                label={'Pension Adjustment Facteur'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            <RHookFormTextFieldWithLabel 
                id={'rppContributionsP2X'} 
                testId={'rppContributionsP2X'} 
                label={'Rpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            <RHookFormTextFieldWithLabel 
                id={'eiPremiumsP2X'} 
                testId={'eiPremiumsP2X'} 
                label={'Ei Premiums'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            <RHookFormTextFieldWithLabel 
                id={'qppContributionsP2X'} 
                testId={'qppContributionsP2X'} 
                label={'Qpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'secondQppContributionsP2X'} 
                testId={'secondQppContributionsP2X'} 
                label={'Second Qpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'cppContributionsP2X'} 
                testId={'cppContributionsP2X'} 
                label={'Cpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'secondCppContributionsP2X'} 
                testId={'secondCppContributionsP2X'} 
                label={'Second Cpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'employmentIncomeP2X'} 
                testId={'employmentIncomeP2X'} 
                label={'Employment Income'} 
                labelPosition={LabelPosition.Left}
                defaultValue={421}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P2X'} 
                testId={'otherInfoAmount1P2X'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={226.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P2X'} 
                testId={'otherInfoCode1P2X'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={111}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P2X'} 
                testId={'otherInfoAmount2P2X'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P2X'} 
                testId={'otherInfoCode2P2X'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={274.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P2X'} 
                testId={'otherInfoAmount3P2X'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P2X'} 
                testId={'otherInfoCode3P2X'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={438}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P2X'} 
                testId={'otherInfoAmount4P2X'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={226.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P2X'} 
                testId={'otherInfoCode4P2X'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={111}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount5P2X'} 
                testId={'otherInfoAmount5P2X'} 
                label={'Other Info Amount 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode5P2X'} 
                testId={'otherInfoCode5P2X'} 
                label={'Other Info Code 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={274.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount6P2X'} 
                testId={'otherInfoAmount6P2X'} 
                label={'Other Info Amount 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={553.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode6P2X'} 
                testId={'otherInfoCode6P2X'} 
                label={'Other Info Code 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={438}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceCityAddressP2X'} 
                testId={'resourceCityAddressP2X'} 
                label={'Resource City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={50}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceAddressP2X'} 
                testId={'resourceAddressP2X'} 
                label={'Resource Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={50}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersCityAddressP2X'} 
                testId={'employersCityAddressP2X'} 
                label={'Employers City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={50}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersAddressP2X'} 
                testId={'employersAddressP2X'} 
                label={'Employers Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={50}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersNameP2X'} 
                testId={'employersNameP2X'} 
                label={'Employers Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={50}
            />
            <RHookFormTextFieldWithLabel 
                id={'initialsP2X'} 
                testId={'initialsP2X'} 
                label={'Initials'} 
                labelPosition={LabelPosition.Left}
                defaultValue={300}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceLastNameP2X'} 
                testId={'resourceLastNameP2X'} 
                label={'Resource Last Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={200}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceFirstNameP2X'} 
                testId={'resourceFirstNameP2X'} 
                label={'Resource First Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={72}
            />
            <RHookFormTextFieldWithLabel 
                id={'employeeAccountNoP2X'} 
                testId={'employeeAccountNoP2X'} 
                label={'Employee Account No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={90}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsuranceNumberP2X'} 
                testId={'socialInsuranceNumberP2X'} 
                label={'Social Insurance Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={65}
            />
            <RHookFormTextFieldWithLabel 
                id={'cppExemptP2X'} 
                testId={'cppExemptP2X'} 
                label={'Cpp Exempt'} 
                labelPosition={LabelPosition.Left}
                defaultValue={255}
            />
            <RHookFormTextFieldWithLabel 
                id={'eiExemptP2X'} 
                testId={'eiExemptP2X'} 
                label={'Ei Exempt'} 
                labelPosition={LabelPosition.Left}
                defaultValue={233}
            />
            <RHookFormTextFieldWithLabel 
                id={'ppipExemptP2X'} 
                testId={'ppipExemptP2X'} 
                label={'Ppip Exempt'} 
                labelPosition={LabelPosition.Left}
                defaultValue={211}
            />
            <RHookFormTextFieldWithLabel 
                id={'provinceOfEmployementShortCodeP2X'} 
                testId={'provinceOfEmployementShortCodeP2X'} 
                label={'Province Of Employement ShortCode'} 
                labelPosition={LabelPosition.Left}
                defaultValue={295}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerOfferedDentalBenefitsP2X'} 
                testId={'employerOfferedDentalBenefitsP2X'} 
                label={'Employer Offered Dental Benefits'} 
                labelPosition={LabelPosition.Left}
                defaultValue={295}
            />
            <RHookFormTextFieldWithLabel 
                id={'employmentCodeP2X'} 
                testId={'employmentCodeP2X'} 
                label={'Employment Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={296}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'slipTypeP2Y'} 
                testId={'slipTypeP2Y'} 
                label={'Slip Type'} 
                labelPosition={LabelPosition.Left}
                defaultValue={375}
            />
            <RHookFormTextFieldWithLabel 
                id={'taxYearP2Y'} 
                testId={'taxYearP2Y'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={355}
            />
            <RHookFormTextFieldWithLabel 
                id={'pipInsurableEarningsP2Y'} 
                testId={'pipInsurableEarningsP2Y'} 
                label={'PIP Insurable Earnings'} 
                labelPosition={LabelPosition.Left}
                defaultValue={101}
            />
            <RHookFormTextFieldWithLabel 
                id={'rppOrDpspRegistrationNumberP2Y'} 
                testId={'rppOrDpspRegistrationNumberP2Y'} 
                label={'Rpp/Dpsp Registration Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={137}
            />
            <RHookFormTextFieldWithLabel 
                id={'charitableDonationsP2Y'} 
                testId={'charitableDonationsP2Y'} 
                label={'Charitable Donations'} 
                labelPosition={LabelPosition.Left}
                defaultValue={173}
            />
            <RHookFormTextFieldWithLabel 
                id={'unionDuesP2Y'} 
                testId={'unionDuesP2Y'} 
                label={'Union Dues'} 
                labelPosition={LabelPosition.Left}
                defaultValue={209}
            />
            <RHookFormTextFieldWithLabel 
                id={'cppQppPensionableEarningsP2Y'} 
                testId={'cppQppPensionableEarningsP2Y'} 
                label={'cppQpp Pensionable Earnings'} 
                labelPosition={LabelPosition.Left}
                defaultValue={245}
            />
            <RHookFormTextFieldWithLabel 
                id={'eiInsurableEarningsP2Y'} 
                testId={'eiInsurableEarningsP2Y'} 
                label={'Ei Insurable Earnings'} 
                labelPosition={LabelPosition.Left}
                defaultValue={281}
            />
            <RHookFormTextFieldWithLabel 
                id={'incomeTaxDeductedP2Y'} 
                testId={'incomeTaxDeductedP2Y'} 
                label={'Income Tax Deducted'} 
                labelPosition={LabelPosition.Left}
                defaultValue={317}
            />
            <RHookFormTextFieldWithLabel 
                id={'ppipPremiumsP2Y'} 
                testId={'ppipPremiumsP2Y'} 
                label={'Ppip Premiums'} 
                labelPosition={LabelPosition.Left}
                defaultValue={101}
            />
            <RHookFormTextFieldWithLabel 
                id={'pensionAdjustmentFacteurP2Y'} 
                testId={'pensionAdjustmentFacteurP2Y'} 
                label={'Pension Adjustment Facteur'} 
                labelPosition={LabelPosition.Left}
                defaultValue={137}
            />
            <RHookFormTextFieldWithLabel 
                id={'rppContributionsP2Y'} 
                testId={'rppContributionsP2Y'} 
                label={'Rpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={173}
            />
            <RHookFormTextFieldWithLabel 
                id={'eiPremiumsP2Y'} 
                testId={'eiPremiumsP2Y'} 
                label={'Ei Premiums'} 
                labelPosition={LabelPosition.Left}
                defaultValue={209}
            />
            <RHookFormTextFieldWithLabel 
                id={'qppContributionsP2Y'} 
                testId={'qppContributionsP2Y'} 
                label={'Qpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={245}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'secondQppContributionsP2Y'} 
                testId={'secondQppContributionsP2Y'} 
                label={'Second Qpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={245}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'cppContributionsP2Y'} 
                testId={'cppContributionsP2Y'} 
                label={'Cpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={281}
            />
            {taxYear > 2023 ? 
            <RHookFormTextFieldWithLabel 
                id={'secondCppContributionsP2Y'} 
                testId={'secondCppContributionsP2Y'} 
                label={'Second Cpp Contributions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={281}
            />:<></>}
            <RHookFormTextFieldWithLabel 
                id={'employmentIncomeP2Y'} 
                testId={'employmentIncomeP2Y'} 
                label={'Employment Income'} 
                labelPosition={LabelPosition.Left}
                defaultValue={317}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P2Y'} 
                testId={'otherInfoAmount1P2Y'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={65}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P2Y'} 
                testId={'otherInfoCode1P2Y'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={65}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P2Y'} 
                testId={'otherInfoAmount2P2Y'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={65}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P2Y'} 
                testId={'otherInfoCode2P2Y'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={65}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P2Y'} 
                testId={'otherInfoAmount3P2Y'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={65}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P2Y'} 
                testId={'otherInfoCode3P2Y'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={65}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P2Y'} 
                testId={'otherInfoAmount4P2Y'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={29}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P2Y'} 
                testId={'otherInfoCode4P2Y'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={29}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount5P2Y'} 
                testId={'otherInfoAmount5P2Y'} 
                label={'Other Info Amount 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={29}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode5P2Y'} 
                testId={'otherInfoCode5P2Y'} 
                label={'Other Info Code 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={29}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount6P2Y'} 
                testId={'otherInfoAmount6P2Y'} 
                label={'Other Info Amount 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={29}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode6P2Y'} 
                testId={'otherInfoCode6P2Y'} 
                label={'Other Info Code 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={29}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceCityAddressP2Y'} 
                testId={'resourceCityAddressP2Y'} 
                label={'Resource City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={133}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceAddressP2Y'} 
                testId={'resourceAddressP2Y'} 
                label={'Resource Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={153}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersCityAddressP2Y'} 
                testId={'employersCityAddressP2Y'} 
                label={'Employers City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={333}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersAddressP2Y'} 
                testId={'employersAddressP2Y'} 
                label={'Employers Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={348}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersNameP2Y'} 
                testId={'employersNameP2Y'} 
                label={'Employers Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={363}
            />
            <RHookFormTextFieldWithLabel 
                id={'initialsP2Y'} 
                testId={'initialsP2Y'} 
                label={'Initials'} 
                labelPosition={LabelPosition.Left}
                defaultValue={194}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceLastNameP2Y'} 
                testId={'resourceLastNameP2Y'} 
                label={'Resource Last Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={194}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceFirstNameP2Y'} 
                testId={'resourceFirstNameP2Y'} 
                label={'Resource First Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={194}
            />
            <RHookFormTextFieldWithLabel 
                id={'employeeAccountNoP2Y'} 
                testId={'employeeAccountNoP2Y'} 
                label={'Employee Account No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={291}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsuranceNumberP2Y'} 
                testId={'socialInsuranceNumberP2Y'} 
                label={'Social Insurance Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={253}
            />
            <RHookFormTextFieldWithLabel 
                id={'cppExemptP2Y'} 
                testId={'cppExemptP2Y'} 
                label={'Cpp Exempt'} 
                labelPosition={LabelPosition.Left}
                defaultValue={253}
            />
            <RHookFormTextFieldWithLabel 
                id={'eiExemptP2Y'} 
                testId={'eiExemptP2Y'} 
                label={'Ei Exempt'} 
                labelPosition={LabelPosition.Left}
                defaultValue={253}
            />
            <RHookFormTextFieldWithLabel 
                id={'ppipExemptP2Y'} 
                testId={'ppipExemptP2Y'} 
                label={'Ppip Exempt'} 
                labelPosition={LabelPosition.Left}
                defaultValue={253}
            />
            <RHookFormTextFieldWithLabel 
                id={'provinceOfEmployementShortCodeP2Y'} 
                testId={'provinceOfEmployementShortCodeP2Y'} 
                label={'Province Of Employement ShortCode'} 
                labelPosition={LabelPosition.Left}
                defaultValue={281}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerOfferedDentalBenefitsP2Y'} 
                testId={'employerOfferedDentalBenefitsP2Y'} 
                label={'Employer Offered Dental Benefits'} 
                labelPosition={LabelPosition.Left}
                defaultValue={306}
            />
            <RHookFormTextFieldWithLabel 
                id={'employmentCodeP2Y'} 
                testId={'employmentCodeP2Y'} 
                label={'Employment Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={245}
            />

            {/* USE COMMA FIELD */}
            {/* <Grid container sx={{ display: "flex",alignItems:"center" }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "left", paddingTop: 2 }}>
                <InputLabel>
                    <Typography variant="subtitle2">Employment Code</Typography>
                </InputLabel>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "left", paddingTop: 2 }}>
            <RHookFormNumericFormatTextField 
                  id={'employmentCodeP2Y'}
                  testId={'employmentCodeP2Y'}
                  // label={'Employment Code'} 
                  // labelPosition={LabelPosition.Left}
                  defaultValue={632} 
                  textAlign={'end'}            />
                </Grid>
            </Grid> */}

        </Grid>
    </Grid>
  )
}

export default T4SLipAxisPage2