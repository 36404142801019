import T4SlipImportLayout from "components/T4Slip/T4SlipImportLayout/T4SlipImportLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT4SlipImportPage {}

export const T4SlipImportPage: FC<IT4SlipImportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import T4 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t4/list" },
        { text: "T4", navigateTo: "/taxslip/t4/list" },
        { text: "Import T4 Slips", navigateTo: "/taxslip/t4/import" },
      ]}
      mainContents={<T4SlipImportLayout />}
    />
  );
};
