import { AxiosResponse } from "axios";
import http from "common/http-common";
import { IGetUserRole, IPostData, IPostResponse } from "models";

export interface IServerError {
  status: boolean;
  message: string;
  error: boolean;
}

export interface IResponse {
  status: boolean;
  message: string;
}

export interface IPostServerError {
  status: boolean;
  error_message: string;
  error: boolean;
}

export const InviteUserService = {
  get: async (): Promise<IGetUserRole | any | IServerError | IResponse> => {
    const response: AxiosResponse<IGetUserRole | any> = await http.get<IGetUserRole | any>(
      "/user-details"
    );
    if (response.status === 200) return response.data;
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  add: async (data: IPostData): Promise<IPostResponse | IPostServerError> => {
    const response: AxiosResponse<
      IPostResponse | IPostServerError
    > = await http.post<IPostData, AxiosResponse<IPostResponse>>(
      "/assign-user/",
      data
    );
    console.log("response",response)
    if (response.status === 200)
      return response.data;
    else if (response.status === 400)
      return response.data;
    else if (response.status === 400)
      return response.data;
    return {
      status: false,
      error_message: "Something went wrong please try again later.",
      error: true,
    };
  },
};
