import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IT4ASummaryAxisPages1 {}
const T4ASummaryAxisPages1:React.FC<IT4ASummaryAxisPages1> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearX'} 
                testId={'taxYearX'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={200}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAccountX'} 
                testId={'employerAccountX'} 
                label={'Employer Account'} 
                labelPosition={LabelPosition.Left}
                defaultValue={265}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerNameX'} 
                testId={'employerNameX'} 
                label={'Employer Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={265}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAddressX'} 
                testId={'employerAddressX'} 
                label={'Employer Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={265}
            />
            <RHookFormTextFieldWithLabel 
                id={'box88X'} 
                testId={'box88X'} 
                label={'Box 88'} 
                labelPosition={LabelPosition.Left}
                defaultValue={455}
            />
            <RHookFormTextFieldWithLabel 
                id={'box16X'} 
                testId={'box16X'} 
                label={'Box 16'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box18X'} 
                testId={'box18X'} 
                label={'Box 18'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box20X'} 
                testId={'box20X'} 
                label={'Box 20'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box24X'} 
                testId={'box24X'} 
                label={'Box 24'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box28X'} 
                testId={'box28X'} 
                label={'Box 28'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box30X'} 
                testId={'box30X'} 
                label={'Box 30'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box32X'} 
                testId={'box32X'} 
                label={'Box 32'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box34X'} 
                testId={'box34X'} 
                label={'Box 34'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box40X'} 
                testId={'box40X'} 
                label={'Box 40'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box42X'} 
                testId={'box42X'} 
                label={'Box 42'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box48X'} 
                testId={'box48X'} 
                label={'Box 48'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box101X'} 
                testId={'box101X'} 
                label={'Box 101'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22X'} 
                testId={'box22X'} 
                label={'Box 22'} 
                labelPosition={LabelPosition.Left}
                defaultValue={534}
            />
            <RHookFormTextFieldWithLabel 
                id={'box82X'} 
                testId={'box82X'} 
                label={'Box 82'} 
                labelPosition={LabelPosition.Left}
                defaultValue={534}
            />
            <RHookFormTextFieldWithLabel 
                id={'box84X'} 
                testId={'box84X'} 
                label={'Box 84'} 
                labelPosition={LabelPosition.Left}
                defaultValue={186}
            />
            <RHookFormTextFieldWithLabel 
                id={'box86X'} 
                testId={'box86X'} 
                label={'Box 86'} 
                labelPosition={LabelPosition.Left}
                defaultValue={322}
            />
            <RHookFormTextFieldWithLabel 
                id={'box71X'} 
                testId={'box71X'} 
                label={'Box 71'} 
                labelPosition={LabelPosition.Left}
                defaultValue={200}
            />
            <RHookFormTextFieldWithLabel 
                id={'box72X'} 
                testId={'box72X'} 
                label={'Box 72'} 
                labelPosition={LabelPosition.Left}
                defaultValue={322}
            />
            <RHookFormTextFieldWithLabel 
                id={'box73X'} 
                testId={'box73X'} 
                label={'Box 73'} 
                labelPosition={LabelPosition.Left}
                defaultValue={450}
            />
            <RHookFormTextFieldWithLabel 
                id={'box74X'} 
                testId={'box74X'} 
                label={'Box 74'} 
                labelPosition={LabelPosition.Left}
                defaultValue={260}
            />
            <RHookFormTextFieldWithLabel 
                id={'box75X'} 
                testId={'box75X'} 
                label={'Box 75'} 
                labelPosition={LabelPosition.Left}
                defaultValue={420}
            />
            <RHookFormTextFieldWithLabel 
                id={'box76X'} 
                testId={'box76X'} 
                label={'Box 76'} 
                labelPosition={LabelPosition.Left}
                defaultValue={80}
            />
            <RHookFormTextFieldWithLabel 
                id={'box78X'} 
                testId={'box78X'} 
                label={'Box 78'} 
                labelPosition={LabelPosition.Left}
                defaultValue={330}
            />
            <RHookFormTextFieldWithLabel 
                id={'telephoneX'} 
                testId={'telephoneX'} 
                label={'Telephone'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'extensionPosteX'} 
                testId={'extensionPosteX'} 
                label={'Extension Poste'} 
                labelPosition={LabelPosition.Left}
                defaultValue={500}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearY'} 
                testId={'taxYearY'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={728}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAccountY'} 
                testId={'employerAccountY'} 
                label={'Employer Account'} 
                labelPosition={LabelPosition.Left}
                defaultValue={685}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerNameY'} 
                testId={'employerNameY'} 
                label={'Employer Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={650}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAddressY'} 
                testId={'employerAddressY'} 
                label={'Employer Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={630}
            />
            <RHookFormTextFieldWithLabel 
                id={'box88Y'} 
                testId={'box88Y'} 
                label={'Box 88'} 
                labelPosition={LabelPosition.Left}
                defaultValue={537}
            />
            <RHookFormTextFieldWithLabel 
                id={'box16Y'} 
                testId={'box16Y'} 
                label={'Box 16'} 
                labelPosition={LabelPosition.Left}
                defaultValue={522}
            />
            <RHookFormTextFieldWithLabel 
                id={'box18Y'} 
                testId={'box18Y'} 
                label={'Box 18'} 
                labelPosition={LabelPosition.Left}
                defaultValue={509}
            />
            <RHookFormTextFieldWithLabel 
                id={'box20Y'} 
                testId={'box20Y'} 
                label={'Box 20'} 
                labelPosition={LabelPosition.Left}
                defaultValue={496}
            />
            <RHookFormTextFieldWithLabel 
                id={'box24Y'} 
                testId={'box24Y'} 
                label={'Box 24'} 
                labelPosition={LabelPosition.Left}
                defaultValue={483}
            />
            <RHookFormTextFieldWithLabel 
                id={'box28Y'} 
                testId={'box28Y'} 
                label={'Box 28'} 
                labelPosition={LabelPosition.Left}
                defaultValue={470}
            />
            <RHookFormTextFieldWithLabel 
                id={'box30Y'} 
                testId={'box30Y'} 
                label={'Box 30'} 
                labelPosition={LabelPosition.Left}
                defaultValue={457}
            />
            <RHookFormTextFieldWithLabel 
                id={'box32Y'} 
                testId={'box32Y'} 
                label={'Box 32'} 
                labelPosition={LabelPosition.Left}
                defaultValue={444}
            />
            <RHookFormTextFieldWithLabel 
                id={'box34Y'} 
                testId={'box34Y'} 
                label={'Box 34'} 
                labelPosition={LabelPosition.Left}
                defaultValue={431}
            />
            <RHookFormTextFieldWithLabel 
                id={'box40Y'} 
                testId={'box40Y'} 
                label={'Box 40'} 
                labelPosition={LabelPosition.Left}
                defaultValue={418}
            />
            <RHookFormTextFieldWithLabel 
                id={'box42Y'} 
                testId={'box42Y'} 
                label={'Box 42'} 
                labelPosition={LabelPosition.Left}
                defaultValue={405}
            />
            <RHookFormTextFieldWithLabel 
                id={'box48Y'} 
                testId={'box48Y'} 
                label={'Box 48'} 
                labelPosition={LabelPosition.Left}
                defaultValue={392}
            />
            <RHookFormTextFieldWithLabel 
                id={'box101Y'} 
                testId={'box101Y'} 
                label={'Box 101'} 
                labelPosition={LabelPosition.Left}
                defaultValue={379}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22Y'} 
                testId={'box22Y'} 
                label={'Box 22'} 
                labelPosition={LabelPosition.Left}
                defaultValue={359}
            />
            <RHookFormTextFieldWithLabel 
                id={'box82Y'} 
                testId={'box82Y'} 
                label={'Box 82'} 
                labelPosition={LabelPosition.Left}
                defaultValue={340}
            />
            <RHookFormTextFieldWithLabel 
                id={'box84Y'} 
                testId={'box84Y'} 
                label={'Box 84'} 
                labelPosition={LabelPosition.Left}
                defaultValue={274}
            />
            <RHookFormTextFieldWithLabel 
                id={'box86Y'} 
                testId={'box86Y'} 
                label={'Box 86'} 
                labelPosition={LabelPosition.Left}
                defaultValue={274}
            />
            <RHookFormTextFieldWithLabel 
                id={'box71Y'} 
                testId={'box71Y'} 
                label={'Box 71'} 
                labelPosition={LabelPosition.Left}
                defaultValue={250.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'box72Y'} 
                testId={'box72Y'} 
                label={'Box 72'} 
                labelPosition={LabelPosition.Left}
                defaultValue={250.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'box73Y'} 
                testId={'box73Y'} 
                label={'Box 73'} 
                labelPosition={LabelPosition.Left}
                defaultValue={250.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'box74Y'} 
                testId={'box74Y'} 
                label={'Box 74'} 
                labelPosition={LabelPosition.Left}
                defaultValue={225.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'box75Y'} 
                testId={'box75Y'} 
                label={'Box 75'} 
                labelPosition={LabelPosition.Left}
                defaultValue={225.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'box76Y'} 
                testId={'box76Y'} 
                label={'Box 76'} 
                labelPosition={LabelPosition.Left}
                defaultValue={191}
            />
            <RHookFormTextFieldWithLabel 
                id={'box78Y'} 
                testId={'box78Y'} 
                label={'Box 78'} 
                labelPosition={LabelPosition.Left}
                defaultValue={191}
            />
            <RHookFormTextFieldWithLabel 
                id={'telephoneY'} 
                testId={'telephoneY'} 
                label={'Telephone'} 
                labelPosition={LabelPosition.Left}
                defaultValue={191}
            />
            <RHookFormTextFieldWithLabel 
                id={'extensionPosteY'} 
                testId={'extensionPosteY'} 
                label={'Extension Poste'} 
                labelPosition={LabelPosition.Left}
                defaultValue={191}
            />
        </Grid>
    </Grid>
  )
}

export default T4ASummaryAxisPages1