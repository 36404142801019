import { DRAWER_TYPES } from "store/types/drawerActionsTypes";

export interface IDrawer {
  open:boolean
}

const initialState: IDrawer = {
  open: true
};

export const drawerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DRAWER_TYPES.DRAWER_OPEN_SET_SELECTED:
      return {
        ...state,
        open: action.payload.open,
      }
    case DRAWER_TYPES.DRAWER_CLOSE_SELECTED:
      return {
        ...state,
        open: initialState.open,
      }
    default:
      return state;
  }
};
