import { RL1SlipSummarySetup } from "components/RL1Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL1SlipSummarySetupPage {}

export const RL1SlipSummarySetupPage: FC<IRL1SlipSummarySetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="RL-1 Slip Summary"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl1/list" },
        { text: "RL-1", navigateTo: "/taxslip/rl1/list" },
        { text: "RL-1 Summary", navigateTo: "/taxslip/rl1/summary" },
      ]}
      mainContents={<RL1SlipSummarySetup testId="t4-slip-setup" />}
    />
  );
};
