import { RESOURCEAPIMESSAGE } from "store/types/resourceApiMessageTypes";

export const setSelectedResourceApiMessageActions = (selectTaxYear: any) => {
  return {
    type: RESOURCEAPIMESSAGE.RESOURCEAPIMESSAGE_SET_SELECTED,
    payload: {...selectTaxYear}
  }
}

export const clearSelectedResourceApiMessageActions = () => {
  return {
    type: RESOURCEAPIMESSAGE.RESOURCEAPIMESSAGE_CLEAR_SELECTED
  }
}
