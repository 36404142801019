import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IT5SLipAxisPage2 {}
const T5SLipAxisPage2:React.FC<IT5SLipAxisPage2> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP2X'} 
                testId={'taxYearP2X'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'box24P2X'} 
                testId={'box24P2X'} 
                label={'Box 24'} 
                labelPosition={LabelPosition.Left}
                defaultValue={80}
            />
            <RHookFormTextFieldWithLabel 
                id={'box25P2X'} 
                testId={'box25P2X'} 
                label={'Box 25'} 
                labelPosition={LabelPosition.Left}
                defaultValue={185}
            />
            <RHookFormTextFieldWithLabel 
                id={'box26P2X'} 
                testId={'box26P2X'} 
                label={'Box 26'} 
                labelPosition={LabelPosition.Left}
                defaultValue={290}
            />
            <RHookFormTextFieldWithLabel 
                id={'box13P2X'} 
                testId={'box13P2X'} 
                label={'Box 13'} 
                labelPosition={LabelPosition.Left}
                defaultValue={395}
            />
            <RHookFormTextFieldWithLabel 
                id={'box18P2X'} 
                testId={'box18P2X'} 
                label={'Box 18'} 
                labelPosition={LabelPosition.Left}
                defaultValue={500}
            />
            <RHookFormTextFieldWithLabel 
                id={'box10P2X'} 
                testId={'box10P2X'} 
                label={'Box 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={80}
            />
            <RHookFormTextFieldWithLabel 
                id={'box11P2X'} 
                testId={'box11P2X'} 
                label={'Box 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={185}
            />
            <RHookFormTextFieldWithLabel 
                id={'box12P2X'} 
                testId={'box12P2X'} 
                label={'Box 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={290}
            />
            <RHookFormTextFieldWithLabel 
                id={'box21P2X'} 
                testId={'box21P2X'} 
                label={'Box 21'} 
                labelPosition={LabelPosition.Left}
                defaultValue={385}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22P2X'} 
                testId={'box22P2X'} 
                label={'Box 22'} 
                labelPosition={LabelPosition.Left}
                defaultValue={440}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22P2BsX'} 
                testId={'box22P2BsX'} 
                label={'Box 22 B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={425}
            />
            <RHookFormTextFieldWithLabel 
                id={'box23P2X'} 
                testId={'box23P2X'} 
                label={'Box 23'} 
                labelPosition={LabelPosition.Left}
                defaultValue={535}
            />
            <RHookFormTextFieldWithLabel 
                id={'box27P2X'} 
                testId={'box27P2X'} 
                label={'Box 27'} 
                labelPosition={LabelPosition.Left}
                defaultValue={190}
            />
            <RHookFormTextFieldWithLabel 
                id={'box28P2X'} 
                testId={'box28P2X'} 
                label={'Box 28'} 
                labelPosition={LabelPosition.Left}
                defaultValue={245}
            />
            <RHookFormTextFieldWithLabel 
                id={'box29P2X'} 
                testId={'box29P2X'} 
                label={'Box 29'} 
                labelPosition={LabelPosition.Left}
                defaultValue={345}
            />
            <RHookFormTextFieldWithLabel 
                id={'case1P2X'} 
                testId={'case1P2X'} 
                label={'Case 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={155}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase1P2X'} 
                testId={'amountCase1P2X'} 
                label={'Amount Case 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={205}
            />
            <RHookFormTextFieldWithLabel 
                id={'case2P2X'} 
                testId={'case2P2X'} 
                label={'Case 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={300}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase2P2X'} 
                testId={'amountCase2P2X'} 
                label={'Amount Case 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={350}
            />
            <RHookFormTextFieldWithLabel 
                id={'case3P2X'} 
                testId={'case3P2X'} 
                label={'Case 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={450}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase3P2X'} 
                testId={'amountCase3P2X'} 
                label={'Amount Case 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={500}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientNameP2X'} 
                testId={'recipientNameP2X'} 
                label={'Recipient Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientLine1NameP2X'} 
                testId={'recipientLine1NameP2X'} 
                label={'Recipient Line 1 Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeNameP2X'} 
                testId={'payeeNameP2X'} 
                label={'Payee Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={330}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeAddressP2X'} 
                testId={'payeeAddressP2X'} 
                label={'Payee Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={330}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeCityP2X'} 
                testId={'payeeCityP2X'} 
                label={'Payee City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={330}
            />
            {/* <RHookFormTextFieldWithLabel 
                id={'recipientInitialP2X'} 
                testId={'recipientInitialP2X'} 
                label={'Recipient Initial'} 
                labelPosition={LabelPosition.Left}
                defaultValue={330}
            /> */}
            <RHookFormTextFieldWithLabel 
                id={'recipientAddressP2X'} 
                testId={'recipientAddressP2X'} 
                label={'Recipient Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientCityP2X'} 
                testId={'recipientCityP2X'} 
                label={'Recipient City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'pageP2X'} 
                testId={'pageP2X'} 
                label={'Page'} 
                labelPosition={LabelPosition.Left}
                defaultValue={585}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP2Y'} 
                testId={'taxYearP2Y'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={690}
            />
            <RHookFormTextFieldWithLabel 
                id={'box24P2Y'} 
                testId={'box24P2Y'} 
                label={'Box 24'} 
                labelPosition={LabelPosition.Left}
                defaultValue={657}
            />
            <RHookFormTextFieldWithLabel 
                id={'box25P2Y'} 
                testId={'box25P2Y'} 
                label={'Box 25'} 
                labelPosition={LabelPosition.Left}
                defaultValue={657}
            />
            <RHookFormTextFieldWithLabel 
                id={'box26P2Y'} 
                testId={'box26P2Y'} 
                label={'Box 26'} 
                labelPosition={LabelPosition.Left}
                defaultValue={657}
            />
            <RHookFormTextFieldWithLabel 
                id={'box13P2Y'} 
                testId={'box13P2Y'} 
                label={'Box 13'} 
                labelPosition={LabelPosition.Left}
                defaultValue={657}
            />
            <RHookFormTextFieldWithLabel 
                id={'box18P2Y'} 
                testId={'box18P2Y'} 
                label={'Box 18'} 
                labelPosition={LabelPosition.Left}
                defaultValue={657}
            />
            <RHookFormTextFieldWithLabel 
                id={'box10P2Y'} 
                testId={'box10P2Y'} 
                label={'Box 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={620}
            />
            <RHookFormTextFieldWithLabel 
                id={'box11P2Y'} 
                testId={'box11P2Y'} 
                label={'Box 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={620}
            />
            <RHookFormTextFieldWithLabel 
                id={'box12P2Y'} 
                testId={'box12P2Y'} 
                label={'Box 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={620}
            />
            <RHookFormTextFieldWithLabel 
                id={'box21P2Y'} 
                testId={'box21P2Y'} 
                label={'Box 21'} 
                labelPosition={LabelPosition.Left}
                defaultValue={620}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22P2Y'} 
                testId={'box22P2Y'} 
                label={'Box 22'} 
                labelPosition={LabelPosition.Left}
                defaultValue={620}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22P2BsY'} 
                testId={'box22P2BsY'} 
                label={'Box 22 B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={620}
            />
            <RHookFormTextFieldWithLabel 
                id={'box23P2Y'} 
                testId={'box23P2Y'} 
                label={'Box 23'} 
                labelPosition={LabelPosition.Left}
                defaultValue={620}
            />
            <RHookFormTextFieldWithLabel 
                id={'box27P2Y'} 
                testId={'box27P2Y'} 
                label={'Box 27'} 
                labelPosition={LabelPosition.Left}
                defaultValue={468}
            />
            <RHookFormTextFieldWithLabel 
                id={'box28P2Y'} 
                testId={'box28P2Y'} 
                label={'Box 28'} 
                labelPosition={LabelPosition.Left}
                defaultValue={468}
            />
            <RHookFormTextFieldWithLabel 
                id={'box29P2Y'} 
                testId={'box29P2Y'} 
                label={'Box 29'} 
                labelPosition={LabelPosition.Left}
                defaultValue={468}
            />
            <RHookFormTextFieldWithLabel 
                id={'case1P2Y'} 
                testId={'case1P2Y'} 
                label={'Case 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={584}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase1P2Y'} 
                testId={'amountCase1P2Y'} 
                label={'Amount Case 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={584}
            />
            <RHookFormTextFieldWithLabel 
                id={'case2P2Y'} 
                testId={'case2P2Y'} 
                label={'Case 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={584}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase2P2Y'} 
                testId={'amountCase2P2Y'} 
                label={'Amount Case 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={584}
            />
            <RHookFormTextFieldWithLabel 
                id={'case3P2Y'} 
                testId={'case3P2Y'} 
                label={'Case 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={584}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase3P2Y'} 
                testId={'amountCase3P2Y'} 
                label={'Amount Case 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={584}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientNameP2Y'} 
                testId={'recipientNameP2Y'} 
                label={'Recipient Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={535}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientLine1NameP2Y'} 
                testId={'recipientLine1NameP2Y'} 
                label={'Recipient Line 1 Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={520}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeNameP2Y'} 
                testId={'payeeNameP2Y'} 
                label={'Payee Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={535}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeAddressP2Y'} 
                testId={'payeeAddressP2Y'} 
                label={'Payee Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={520}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeCityP2Y'} 
                testId={'payeeCityP2Y'} 
                label={'Payee City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={505}
            />
            {/* <RHookFormTextFieldWithLabel 
                id={'recipientInitialP2Y'} 
                testId={'recipientInitialP2Y'} 
                label={'Recipient Initial'} 
                labelPosition={LabelPosition.Left}
                defaultValue={520}
            /> */}
            <RHookFormTextFieldWithLabel 
                id={'recipientAddressP2Y'} 
                testId={'recipientAddressP2Y'} 
                label={'Recipient Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={505}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientCityP2Y'} 
                testId={'recipientCityP2Y'} 
                label={'Recipient City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={490}
            />
            <RHookFormTextFieldWithLabel 
                id={'pageP2Y'} 
                testId={'pageP2Y'} 
                label={'Page'} 
                labelPosition={LabelPosition.Left}
                defaultValue={440}
            />
        </Grid>
    </Grid>
  )
}

export default T5SLipAxisPage2