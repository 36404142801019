import * as yup from 'yup';

export const FlexFieldTabValidationSchema = yup.object({
  name: yup
    .string()
    .required("Name is required ")
    .min(3, "Minimum length 3 characters")
    .max(50, "Maximum length 50 characters"),
  appName: yup
    .string()
    .required("App Name is required")
    .min(3, "Minimum length 3 characters")
    .max(50, "Maximum length 50 characters"),
  description: yup
    .string()
    .max(1000, "The maximum allowed characters for description is 1000")
    .transform((x) => (x === '' ? undefined : x)) as yup.StringSchema<string>,
  sequence: yup
    .number()
    .transform((x: any) => (isNaN(x) ? undefined : x))// as yup.NumberSchema<number>
    .nullable()
    .typeError("Sequence is required in number format")
    .required("Sequence is required in number format")
    .min(1, "Please enter a number between 1 and 1000")
    .max(1000, "Please enter a number between 1 and 1000")
});
