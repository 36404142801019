import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from "react-query-devtools";
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './store';

const { persister, store } = configureStore();
export const queryClient:any = new QueryClient();
const PGate = PersistGate as any
ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PGate loading={null} persistor={persister}>
          <App />
        </PGate>
      </Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from 'react'
// import App from './App'
// import ReactDOM from 'react-dom'
// ReactDOM.render(
//   <React.StrictMode>
//           <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
