import { RL16SlipSettingsSetup } from "components/RL16Slip/RL16SlipSettingsSetup";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL16SlipSettingsSetupPage {}

export const RL16SlipSettingsSetupPage: FC<IRL16SlipSettingsSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="RL16 Slip Settings"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl16/list" },
        { text: "RL16", navigateTo: "/taxslip/rl16/list" },
        { text: "RL16 Slips Settings", navigateTo: "/taxslip/rl16/setting" },
      ]}
      mainContents={<RL16SlipSettingsSetup testId="RL16-slip-settings-setup" />}
    />
  );
};
