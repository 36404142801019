import * as yup from 'yup';

export const BillingProfileClientsAssignmentSchema = yup.object({
  selectedAccountant: yup
    .string()
    .required("Accountant selection is required"),
    selectedTaxProfile: yup
    .string()
    .required("Profile selection is required")
});
