import React from "react";

import { Alert, Box, Grid, Typography } from "@mui/material";
import {
  FieldLayout,
  LabelPosition,
  RHookFormTextField,
  RHookFormTextFieldWithLabel,
} from "@websential/cosmic";
import { Link } from 'react-router-dom';

export interface ITaxSlipAccountInformation {
  accountIdentifierTitle: string;
  accountIdentifier: string;
  accountInformationMessage: string;
  accountName: string;
  accountAddressLine1: string;
  accountCity: string;
  accountProvince: string;
  accountPostalCode: string;
  logoURL: string;
  companyInformation: any;
}

export const TaxSlipAccountInformation: React.FC<ITaxSlipAccountInformation> =
  ({
    accountIdentifierTitle,
    accountIdentifier,
    accountInformationMessage,
    accountName,
    accountAddressLine1,
    accountCity,
    accountProvince,
    accountPostalCode,
    logoURL,
    companyInformation,
  }) => {
    console.log("companyInformation", companyInformation);
    return (
      <Grid
        container
        rowSpacing={2}
        columnSpacing={0}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <img src={logoURL} alt="Logo" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Alert severity="info">{accountInformationMessage}</Alert>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Alert severity="info"
          sx={{alignItems:"center", "& .MuiAlert-action":{padding:"0!important"}}}
          action={
            <Link
              to={"https://www.canada.ca/en/revenue-agency/services/e-services/filing-information-returns-electronically-t4-t5-other-types-returns-overview/filing-information-returns-electronically-t4-t5-other-types-returns-file.html"}
              // className={classes.subTitleLink}
              // data-testid={testId + "-subtitleLink"}
              target="_blank"
              style={{textDecoration:"none",color:"#0057db"}}
            >
              Submit XML File to CRA
            </Link>
          }>
            {/* <Box sx={{display:"flex",justifyContent:"space-between"}}>
            <Link
              to={"https://www.canada.ca/en/revenue-agency/services/e-services/filing-information-returns-electronically-t4-t5-other-types-returns-overview/filing-information-returns-electronically-t4-t5-other-types-returns-file.html"}
              // className={classes.subTitleLink}
              // data-testid={testId + "-subtitleLink"}
              target="_blank"
              style={{textDecoration:"none",color:"#0057db"}}
            >
              Submit XML File to CRA
            </Link> */}
            <Typography sx={{fontSize:"0.875rem"}}>
              Access Code : {companyInformation?.companyAccessCode}
              
            </Typography>
            {/* </Box> */}
          </Alert>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Account
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {/* <RHookFormTextFieldWithLabel
            label={accountIdentifierTitle}
            id="accountIdentifierTitle"
            testId="account-identifier-title"
            readOnly
            labelPosition={LabelPosition.Top}
            value={companyInformation && companyInformation.employerAccountNumber}
            defaultValue={companyInformation && companyInformation.employerAccountNumber}
          /> */}
          <FieldLayout
            label={accountIdentifierTitle}
            testId="account-identifier-title"
            labelPosition={LabelPosition.Top}
          >
            <RHookFormTextField
              id="employerAccountNumber"
              testId="account-identifier-title"
              value={
                companyInformation && companyInformation.employerAccountNumber ? companyInformation.employerAccountNumber : ""
              }
              defaultValue={
                companyInformation && companyInformation.employerAccountNumber ? companyInformation.employerAccountNumber : ""
              }
              readOnly
              // required
            />
          </FieldLayout>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {/* {accountName} */}
            {companyInformation?.companyName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2">
            {companyInformation?.address?.addressLine1}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2">{`${companyInformation && companyInformation?.address?.city ? companyInformation?.address?.city  + " , " : ""}${companyInformation && companyInformation?.address?.province?.shortCode ? companyInformation?.address?.province?.shortCode  + " , " : ""}${companyInformation && companyInformation?.address?.postalCode ? companyInformation?.address?.postalCode : ""}`}</Typography>
        </Grid>
      </Grid>
    );
  };
