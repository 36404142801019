import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IRL1SLipAxisPage1 {
    taxYear:number
}
const RL1SLipAxisPage1:React.FC<IRL1SLipAxisPage1> = ({taxYear}) => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP1X'} 
                testId={'taxYearP1X'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={328}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxAP1X'} 
                testId={'boxAP1X'} 
                label={'Box A'} 
                labelPosition={LabelPosition.Left}
                defaultValue={92.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxBP1X'} 
                testId={'boxBP1X'} 
                label={'Box B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={189}
            />
            {taxYear > 2023 ?
            <RHookFormTextFieldWithLabel 
                id={'boxBBP1X'} 
                testId={'boxBBP1X'} 
                label={'Box BB'} 
                labelPosition={LabelPosition.Left}
                defaultValue={189}
            /> : <></>}
            <RHookFormTextFieldWithLabel 
                id={'boxCP1X'} 
                testId={'boxCP1X'} 
                label={'Box C'} 
                labelPosition={LabelPosition.Left}
                defaultValue={284}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxDP1X'} 
                testId={'boxDP1X'} 
                label={'Box D'} 
                labelPosition={LabelPosition.Left}
                defaultValue={379}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxEP1X'} 
                testId={'boxEP1X'} 
                label={'Box E'} 
                labelPosition={LabelPosition.Left}
                defaultValue={474}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxFP1X'} 
                testId={'boxFP1X'} 
                label={'Box F'} 
                labelPosition={LabelPosition.Left}
                defaultValue={567}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxGP1X'} 
                testId={'boxGP1X'} 
                label={'Box G'} 
                labelPosition={LabelPosition.Left}
                defaultValue={92.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxHP1X'} 
                testId={'boxHP1X'} 
                label={'Box H'} 
                labelPosition={LabelPosition.Left}
                defaultValue={189}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxIP1X'} 
                testId={'boxIP1X'} 
                label={'Box I'} 
                labelPosition={LabelPosition.Left}
                defaultValue={284}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxJP1X'} 
                testId={'boxJP1X'} 
                label={'Box J'} 
                labelPosition={LabelPosition.Left}
                defaultValue={379}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxKP1X'} 
                testId={'boxKP1X'} 
                label={'Box K'} 
                labelPosition={LabelPosition.Left}
                defaultValue={474}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxLP1X'} 
                testId={'boxLP1X'} 
                label={'Box L'} 
                labelPosition={LabelPosition.Left}
                defaultValue={567}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxMP1X'} 
                testId={'boxMP1X'} 
                label={'Box M'} 
                labelPosition={LabelPosition.Left}
                defaultValue={94.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxNP1X'} 
                testId={'boxNP1X'} 
                label={'Box N'} 
                labelPosition={LabelPosition.Left}
                defaultValue={189}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxOP1X'} 
                testId={'boxOP1X'} 
                label={'Box O'} 
                labelPosition={LabelPosition.Left}
                defaultValue={284}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxPP1X'} 
                testId={'boxPP1X'} 
                label={'Box P'} 
                labelPosition={LabelPosition.Left}
                defaultValue={379}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxQP1X'} 
                testId={'boxQP1X'} 
                label={'Box Q'} 
                labelPosition={LabelPosition.Left}
                defaultValue={474}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxRP1X'} 
                testId={'boxRP1X'} 
                label={'Box R'} 
                labelPosition={LabelPosition.Left}
                defaultValue={567}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxSP1X'} 
                testId={'boxSP1X'} 
                label={'Box C'} 
                labelPosition={LabelPosition.Left}
                defaultValue={94.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxTP1X'} 
                testId={'boxTP1X'} 
                label={'Box T'} 
                labelPosition={LabelPosition.Left}
                defaultValue={189}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxUP1X'} 
                testId={'boxUP1X'} 
                label={'Box U'} 
                labelPosition={LabelPosition.Left}
                defaultValue={284}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxVP1X'} 
                testId={'boxVP1X'} 
                label={'Box V'} 
                labelPosition={LabelPosition.Left}
                defaultValue={379}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxWP1X'} 
                testId={'boxWP1X'} 
                label={'Box W'} 
                labelPosition={LabelPosition.Left}
                defaultValue={474}
            />
            <RHookFormTextFieldWithLabel 
                id={'codeOP1X'} 
                testId={'codeOP1X'} 
                label={'Code O'} 
                labelPosition={LabelPosition.Left}
                defaultValue={563}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P1X'} 
                testId={'otherInfoAmount1P1X'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={145}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P1X'} 
                testId={'otherInfoCode1P1X'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={74}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P1X'} 
                testId={'otherInfoAmount2P1X'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={270.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P1X'} 
                testId={'otherInfoCode2P1X'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={204}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P1X'} 
                testId={'otherInfoAmount3P1X'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P1X'} 
                testId={'otherInfoCode3P1X'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P1X'} 
                testId={'otherInfoAmount4P1X'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={542.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P1X'} 
                testId={'otherInfoCode4P1X'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={470}
            />
            <RHookFormTextFieldWithLabel 
                id={'codeDuRelevéP1X'} 
                testId={'codeDuRelevéP1X'} 
                label={'Code DuRelevé'} 
                labelPosition={LabelPosition.Left}
                defaultValue={372}
            />
            <RHookFormTextFieldWithLabel 
                id={'noDuDernierRelevéTransmisP1X'} 
                testId={'noDuDernierRelevéTransmisP1X'} 
                label={'No Du Dernier Relevé Transmis'} 
                labelPosition={LabelPosition.Left}
                defaultValue={430}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl1202210AboveBoxP1X'} 
                testId={'rl1202210AboveBoxP1X'} 
                label={'Rl1 2022 10 Above Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={510}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl1202210DownBoxP1X'} 
                testId={'rl1202210DownBoxP1X'} 
                label={'Rl1 2022 10 Down Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={510}
            />
                    <RHookFormTextFieldWithLabel 
                        id={'resourceFirstNameP1X'} 
                        testId={'resourceFirstNameP1X'} 
                        label={'Resource First Name'} 
                        labelPosition={LabelPosition.Left}
                        defaultValue={40}
                    />
                <RHookFormTextFieldWithLabel 
                    id={'resourceLastNameP1X'} 
                    testId={'resourceLastNameP1X'} 
                    label={'Resource Last Name'} 
                    labelPosition={LabelPosition.Left}
                    defaultValue={40}
                />
            <RHookFormTextFieldWithLabel 
                id={'resouceAddressLine1P1X'} 
                testId={'resouceAddressLine1P1X'} 
                label={'Resouce Address Line 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceAddressLine2P1X'} 
                testId={'resouceAddressLine2P1X'} 
                label={'Resouce Address Line 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceCityP1X'} 
                testId={'resouceCityP1X'} 
                label={'Resouce City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsurance1To3P1X'} 
                testId={'socialInsurance1To3P1X'} 
                label={'Social Insurance 1 To 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={340}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsurance4To6P1X'} 
                testId={'socialInsurance4To6P1X'} 
                label={'Social Insurance 4 To 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={380}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsurance7To9P1X'} 
                testId={'socialInsurance7To9P1X'} 
                label={'Social Insurance 7 To 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={420}
            />
            <RHookFormTextFieldWithLabel 
                id={'referenceNoP1X'} 
                testId={'referenceNoP1X'} 
                label={'Reference No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={480}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientNameLine1P1X'} 
                testId={'clientNameLine1P1X'} 
                label={'Client Name Line 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientNameLine2P1X'} 
                testId={'clientNameLine2P1X'} 
                label={'Client Name Line 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientAddressLine1P1X'} 
                testId={'clientAddressLine1P1X'} 
                label={'Client Address Line 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientAddressLine2P1X'} 
                testId={'clientAddressLine2P1X'} 
                label={'Client Address Line 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientCityP1X'} 
                testId={'clientCityP1X'} 
                label={'Client City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceProvinceCodeP1X'} 
                testId={'resourceProvinceCodeP1X'} 
                label={'Resource Province Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourcePostalCodeFirstThreeP1X'} 
                testId={'resourcePostalCodeFirstThreeP1X'} 
                label={'Resource Postal Code First Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={80}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourcePostalCodeLastThreeP1X'} 
                testId={'resourcePostalCodeLastThreeP1X'} 
                label={'Resource Postal Code Last Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={116}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientProvinceCodeP1X'} 
                testId={'clientProvinceCodeP1X'} 
                label={'Client Province Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientPostalCodeFirstThreeP1X'} 
                testId={'clientPostalCodeFirstThreeP1X'} 
                label={'Client Postal Code First Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={375}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientPostalCodeLastThreeP1X'} 
                testId={'clientPostalCodeLastThreeP1X'} 
                label={'Client Postal Code Last Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={415}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP1Y'} 
                testId={'taxYearP1Y'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={954}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxAP1Y'} 
                testId={'boxAP1Y'} 
                label={'Box A'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxBP1Y'} 
                testId={'boxBP1Y'} 
                label={'Box B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />
            {taxYear > 2023 ?
            <RHookFormTextFieldWithLabel 
                id={'boxBBP1Y'} 
                testId={'boxBBP1Y'} 
                label={'Box BB'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            /> : <></>}
            <RHookFormTextFieldWithLabel 
                id={'boxCP1Y'} 
                testId={'boxCP1Y'} 
                label={'Box C'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxDP1Y'} 
                testId={'boxDP1Y'} 
                label={'Box D'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxEP1Y'} 
                testId={'boxEP1Y'} 
                label={'Box E'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxFP1Y'} 
                testId={'boxFP1Y'} 
                label={'Box F'} 
                labelPosition={LabelPosition.Left}
                defaultValue={707}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxGP1Y'} 
                testId={'boxGP1Y'} 
                label={'Box G'} 
                labelPosition={LabelPosition.Left}
                defaultValue={683}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxHP1Y'} 
                testId={'boxHP1Y'} 
                label={'Box H'} 
                labelPosition={LabelPosition.Left}
                defaultValue={683}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxIP1Y'} 
                testId={'boxIP1Y'} 
                label={'Box I'} 
                labelPosition={LabelPosition.Left}
                defaultValue={683}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxJP1Y'} 
                testId={'boxJP1Y'} 
                label={'Box J'} 
                labelPosition={LabelPosition.Left}
                defaultValue={683}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxKP1Y'} 
                testId={'boxKP1Y'} 
                label={'Box K'} 
                labelPosition={LabelPosition.Left}
                defaultValue={683}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxLP1Y'} 
                testId={'boxLP1Y'} 
                label={'Box L'} 
                labelPosition={LabelPosition.Left}
                defaultValue={683}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxMP1Y'} 
                testId={'boxMP1Y'} 
                label={'Box M'} 
                labelPosition={LabelPosition.Left}
                defaultValue={659}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxNP1Y'} 
                testId={'boxNP1Y'} 
                label={'Box N'} 
                labelPosition={LabelPosition.Left}
                defaultValue={659}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxOP1Y'} 
                testId={'boxOP1Y'} 
                label={'Box O'} 
                labelPosition={LabelPosition.Left}
                defaultValue={659}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxPP1Y'} 
                testId={'boxPP1Y'} 
                label={'Box P'} 
                labelPosition={LabelPosition.Left}
                defaultValue={659}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxQP1Y'} 
                testId={'boxQP1Y'} 
                label={'Box Q'} 
                labelPosition={LabelPosition.Left}
                defaultValue={659}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxRP1Y'} 
                testId={'boxRP1Y'} 
                label={'Box R'} 
                labelPosition={LabelPosition.Left}
                defaultValue={659}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxSP1X'} 
                testId={'boxSP1X'} 
                label={'Box C'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxTP1Y'} 
                testId={'boxTP1Y'} 
                label={'Box T'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxUP1Y'} 
                testId={'boxUP1Y'} 
                label={'Box U'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxVP1Y'} 
                testId={'boxVP1Y'} 
                label={'Box V'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxWP1Y'} 
                testId={'boxWP1Y'} 
                label={'Box W'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'codeOP1Y'} 
                testId={'codeOP1Y'} 
                label={'Code O'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P1Y'} 
                testId={'otherInfoAmount1P1Y'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P1Y'} 
                testId={'otherInfoCode1P1Y'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P1Y'} 
                testId={'otherInfoAmount2P1Y'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P1Y'} 
                testId={'otherInfoCode2P1Y'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P1Y'} 
                testId={'otherInfoAmount3P1Y'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P1Y'} 
                testId={'otherInfoCode3P1Y'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P1Y'} 
                testId={'otherInfoAmount4P1Y'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P1Y'} 
                testId={'otherInfoCode4P1Y'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={618}
            />
            <RHookFormTextFieldWithLabel 
                id={'codeDuRelevéP1Y'} 
                testId={'codeDuRelevéP1Y'} 
                label={'Code DuRelevé'} 
                labelPosition={LabelPosition.Left}
                defaultValue={737}
            />
            <RHookFormTextFieldWithLabel 
                id={'noDuDernierRelevéTransmisP1Y'} 
                testId={'noDuDernierRelevéTransmisP1Y'} 
                label={'No Du Dernier Relevé Transmis'} 
                labelPosition={LabelPosition.Left}
                defaultValue={737}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl1202210AboveBoxP1Y'} 
                testId={'rl1202210AboveBoxP1Y'} 
                label={'Rl1 2022 10 Above Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={749.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl1202210DownBoxP1Y'} 
                testId={'rl1202210DownBoxP1Y'} 
                label={'Rl1 2022 10 Down Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={737}
            />
                    <RHookFormTextFieldWithLabel 
                        id={'resourceFirstNameP1Y'} 
                        testId={'resourceFirstNameP1Y'} 
                        label={'Resource First Name'} 
                        labelPosition={LabelPosition.Left}
                        defaultValue={552}
                    />
                <RHookFormTextFieldWithLabel 
                    id={'resourceLastNameP1Y'} 
                    testId={'resourceLastNameP1Y'} 
                    label={'Resource Last Name'} 
                    labelPosition={LabelPosition.Left}
                    defaultValue={527}
                />
            <RHookFormTextFieldWithLabel 
                id={'resouceAddressLine1P1Y'} 
                testId={'resouceAddressLine1P1Y'} 
                label={'Resouce Address Line 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={503}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceAddressLine2P1Y'} 
                testId={'resouceAddressLine2P1Y'} 
                label={'Resouce Address Line 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={480}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceCityP1Y'} 
                testId={'resouceCityP1Y'} 
                label={'Resouce City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={457}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsurance1To3P1Y'} 
                testId={'socialInsurance1To3P1Y'} 
                label={'Social Insurance 1 To 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={552}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsurance4To6P1Y'} 
                testId={'socialInsurance4To6P1Y'} 
                label={'Social Insurance 4 To 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={552}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsurance7To9P1Y'} 
                testId={'socialInsurance7To9P1Y'} 
                label={'Social Insurance 7 To 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={552}
            />
            <RHookFormTextFieldWithLabel 
                id={'referenceNoP1Y'} 
                testId={'referenceNoP1Y'} 
                label={'Reference No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={552}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientNameLine1P1Y'} 
                testId={'clientNameLine1P1Y'} 
                label={'Client Name Line 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={527}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientNameLine2P1Y'} 
                testId={'clientNameLine2P1Y'} 
                label={'Client Name Line 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={303}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientAddressLine1P1Y'} 
                testId={'clientAddressLine1P1Y'} 
                label={'Client Address Line 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={480}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientAddressLine2P1Y'} 
                testId={'clientAddressLine2P1Y'} 
                label={'Client Address Line 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={527}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientCityP1Y'} 
                testId={'clientCityP1Y'} 
                label={'Client City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={430}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceProvinceCodeP1Y'} 
                testId={'resourceProvinceCodeP1Y'} 
                label={'Resource Province Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={430.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourcePostalCodeFirstThreeP1Y'} 
                testId={'resourcePostalCodeFirstThreeP1Y'} 
                label={'Resource Postal Code First Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={430.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourcePostalCodeLastThreeP1Y'} 
                testId={'resourcePostalCodeLastThreeP1Y'} 
                label={'Resource Postal Code Last Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={430.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientProvinceCodeP1Y'} 
                testId={'clientProvinceCodeP1Y'} 
                label={'Client Province Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={406.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientPostalCodeFirstThreeP1Y'} 
                testId={'clientPostalCodeFirstThreeP1Y'} 
                label={'Client Postal Code First Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={406.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientPostalCodeLastThreeP1Y'} 
                testId={'clientPostalCodeLastThreeP1Y'} 
                label={'Client Postal Code Last Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={406.5}
            />
        </Grid>
    </Grid>
  )
}

export default RL1SLipAxisPage1