import React from "react";

import { Alert, Grid, Typography } from "@mui/material";
import { LabelPosition, RHookFormTextFieldWithLabel } from "@websential/cosmic";

export interface IAccountInformation {
  // accountIdentifierTitle: string;
  // accountIdentifier: string;
  // accountInformationMessage: string;
  // accountName: string;
  // accountAddressLine1: string;
  // accountCity: string;
  // accountProvince: string;
  // accountPostalCode: string;
  logoURL: string;
}

export const AccountInformation: React.FC<IAccountInformation> = ({
  // accountIdentifierTitle,
  // accountIdentifier,
  // accountInformationMessage,
  // accountName,
  // accountAddressLine1,
  // accountCity,
  // accountProvince,
  // accountPostalCode,
  logoURL,
}) => {
  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={0}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <img src={logoURL} alt="Logo" />
      </Grid>
      {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Alert severity="info">{accountInformationMessage}</Alert>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Account
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <RHookFormTextFieldWithLabel
            label={accountIdentifierTitle}
            id="accountIdentifierTitle"
            testId="account-identifier-title"
            readOnly
            labelPosition={LabelPosition.Top}
            value={accountIdentifier}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {accountName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2">{accountAddressLine1}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2">{`${accountCity}, ${accountProvince}, ${accountPostalCode}`}</Typography>
        </Grid> */}
    </Grid>
  );
};
