import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { JournalCodeSetup } from "components/Reports/GeneralClient";
import { TransactionsReport } from "components/Reports/TransactionsReport";

export interface ITransactionsReportAdminPage {}

export const TransactionsReportAdminPage: FC<ITransactionsReportAdminPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Transactions Report"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<TransactionsReport />}
    />
  );
};
