import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import ForwardIcon from "@mui/icons-material/Forward";
// import ImportSlipsInstructions from "./ImportSlipsInstructions";
// import DownloadFileButton from "./DownloadFileButton";
import { createStyles, makeStyles } from "@mui/styles";

// import SampleFile from "./Sample_Employee _T4Slips.xlxs"

import {
  BackdropCircularProgress,
  DownloadFileButton,
  EnhancedSnackbar,
  IResponse,
  IServerError,
  ImportSlipsInstructions,
} from "@websential/cosmic";
import { INITIAL_SNACKBAR_DATA, RL1_LIST_SCREEN_SLUG } from "common/constants";
import { useMutation } from "react-query";
import { IAppState } from "store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { RL1SlipService } from "services/RL1SlipService";
import { useNavigate } from "react-router-dom";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";

const instructionList = [
  { icon: ForwardIcon, text: "Download sample excel file." },
  { icon: ForwardIcon, text: "Fill employees data as per headings defined." },
  { icon: ForwardIcon, text: "Delete sample record." },
  { icon: ForwardIcon, text: "Save As CSV (Comma delimited) (*.csv)." },
  {
    icon: ForwardIcon,
    text: "Upload the file for import and Press Retrive Data Button.",
  },
  { icon: ForwardIcon, text: "Maximum allowed file size is one MB." },
];
const instructionTitle = "Instructions";
const instructionSubTitle = "File Instructions";

// styles
const useStyles = makeStyles(() =>
  createStyles({
    titleStyle: {
      fontSize: ".9rem",
      color: "#000",
      padding: "16px 16px 16px 26px",
      fontWeight: "bold",
    },
    line: {
      borderColor: "rgba(0,0,0,.12)",
      border: "0",
      borderTop: "1px solid rgba(0,0,0,.1)",
      margin: 0,
    },
    subTitle: {
      color: "rgba(0,0,0,.6)",
      fontSize: ".875rem",
      p: "12px",
    },
  })
);

const RL1SlipImportXmlLayout = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState('');
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const id  = useSelector((state: IAppState) => state.client.id)
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const taxYear  = useSelector((state: IAppState) => state.taxYear.year)


  const handleFileChange = (event:any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = (event:any) => {
    event?.preventDefault();
    const formData = new FormData();
    formData.append('file',selectedFile)

    let payload = {
      id,
      taxYear,
      formData,
    }
    rl1SlipRetrieveXmlDataByFile(payload)
  }

  const { isLoading: isSubmitting, mutate: rl1SlipRetrieveXmlDataByFile } = useMutation(
    RL1SlipService.rl1SlipRetrieveXmlData,
    {
      onSuccess: (res: any | IServerError | IResponse) => {
        console.log("res",res)
        if(res.status === 201){
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "success",
          });
          setTimeout(() => {
            let path = "/taxslip/rl1/list"
            navigate(path)
          });
        }
      },
      onError: (err:any) => {
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
      },
    }
  );

    const onSnackbarClosed = (
      event: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackbarData(INITIAL_SNACKBAR_DATA);
    };
  
    useEffect(()=>{
      dispatch(setSelectedActivityLogActions({
        entityId1:'',
        entityId2:'',
        entityId3:'',
        screenName:RL1_LIST_SCREEN_SLUG,
        companyName:clientName,
        activityType:"activity_type=import_xml",
        activityLogVisibility:true
      }));
    },[])

  return (
    <>
    <BackdropCircularProgress
        open={
          isSubmitting
        } 
        testId="import-resource-backdrop"      
      />
      <form onSubmit={handleSubmit}>
      <Box sx={{ width: "100%", marginTop: "2rem" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <Card>
              <Box className={classes.titleStyle}>Import RL-1 Slips</Box>
              <hr className={classes.line} />
              <Box sx={{ padding: "16px", height: "318px" }}>
                <Grid container>
                  <Grid item xs={12} sm={12} lg={5} xl={5} sx={{ p: "12px" }}>
                    <Typography className={classes.subTitle}>
                      Import RL-1 Slips
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={7} xl={7} sx={{ p: "12px" }}>
                    <Box sx={{ p: "12px" }}>
                      <input
                        accept=".xml"
                        type="file"
                        onChange={handleFileChange}
                        style={{ color: "rgba(0,0,0,.6)" }}
                      />
                      <Typography
                        variant="body2"
                        sx={{ pt: "8px", color: "rgba(0,0,0,.6)" }}
                      >
                        Please upload .xml file.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={5}
                    xl={5}
                    sx={{ p: "12px", mt: "20px" }}
                  ></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={7}
                    xl={7}
                    sx={{ p: "12px", mt: "20px" }}
                  >
                    <Button variant="contained" type="submit">Retrieve data</Button>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <ImportSlipsInstructions
              instructions={instructionList}
              title={instructionTitle}
              subTitle={instructionSubTitle}
              testId="rl1-slip-import-instructions" 
            />
          </Grid>
        </Grid>
      </Box>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"resource-list-snackbar"}
        open={snackbarData.open}
      />
  </form>
    </>
  );
};

export default RL1SlipImportXmlLayout;