import { EmployeeReport } from "components/Reports";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export interface IEmployeeReportPage {}

export const EmployeeReportPage: FC<IEmployeeReportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Employess"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<EmployeeReport />}
    />
  );
};
