import { AuditFilingSetup } from "components/Audit";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const AuditFilingSetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Audit Filing"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<AuditFilingSetup testId="audit-filing-setup" />}
    />
  );
};
