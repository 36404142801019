import * as yup from 'yup';

export const ChangePasswordSchema = yup.object({
  oldPassword: yup
    .string()
    .required("Old password is required")
    .min(8, "Should be minimum 8 characters")
    .max(15, "Should be maximum 15 characters"),
  newPassword: yup
    .string()
    .required("New password is required")
    .min(8, "Should be minimum 8 characters")
    .max(15, "Should be maximum 15 characters"),
    confirmNewPassword: yup
    .string()
    .required("Confirm new password is required")
    .min(8, "Should be minimum 8 characters")
    .max(15, "Should be maximum 15 characters")
    .oneOf([yup.ref('newPassword'), null], "Passwords don't match.")
});
