import { ArrayData as AdminAccountantRoutes } from "navigation/routes/AdminAccountantRoutes";
import { ArrayData as ClientListRoutes } from "navigation/routes/ClientListRoutes";
import { ArrayData as ClientListRoutesWithAcc } from "navigation/routes/ClientListRoutesWithAcc";
import { ArrayData as AccountantListRoutes } from "navigation/routes/AccountantListRoutes";
import { ArrayData as ClientRoutes } from "navigation/routes/ClientRoutes";
import { ArrayData as AdminClientRoutes, ArrayData } from "navigation/routes/AdminClientRoutes";
import { ArrayData as AdminRoutes } from "navigation/routes/AdminRoutes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
const useAccountants = (params: IGetListRequest) =>
  useQuery(
    ["accountants-list", params],
    (data) => {
      console.log("@@@@@@=>useAccountants", data);
      return AccountantService.getAccountants(params);
    },
    { refetchOnWindowFocus: false }
  );
import { LeftMenu } from "../LeftMenu";
import { IGetListRequest } from "models";
import { useQuery } from "react-query";
import { AccountantService } from "services";
import { homePageReducer } from "store/reducers/homePageReducers";
import { setSelectedAccountantName, setSelectedHomePage } from "store/actions";
import { useNavigate } from "react-router-dom";
export const RoleBasedLeftMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { isAdminUser, selectedAccountantId, selectedAccountantName, selectedClientId, selectedClientName, selectedYear, invoiceRefNo, CSVInformationUI, HomePagePath, selectedMenuItem,drawerAction,activityLog,isLoadAxiosCall,stickyNavBar } = useSelector(
    (state: IAppState) => {
      return {
        isAdminUser: state.user.profile.isSuperStatus,
        selectedAccountantId: state.accountant.id,
        selectedAccountantName: state.accountant.AccountantName,
        selectedClientId: state.client.id,
        selectedClientName: state.client.clientName,
        selectedYear: state.taxYear.year,
        invoiceRefNo: state.invoice.refNo,
        CSVInformationUI: state.CSVInformationUI,
        HomePagePath: state.HomePagePath.path,
        selectedMenuItem: state.navigation.selectedMenuItem,
        drawerAction:state.drawerAction.open,
        activityLog:state.activityLog,
        isLoadAxiosCall:state.isLoadAxiosCall.isLoading,
        stickyNavBar: state.stickyNavBar.isSticky,
      };
    }
  );
  const { isLoading, data } = useAccountants({
    search_query: "",
    order_by: "acc_title",
    order_direction: "asc",
    page: 1,
    size: 25,
  });
  const accountantList: any = data;
  const [menu, setMenu] = useState(AdminRoutes);
  console.log(
    `@@@@@@=> isAdminUser: ${isAdminUser}, 
      selectedAccountantId: ${selectedAccountantId}, 
      selectedAccountantName: ${selectedAccountantName}, 
      selectedClientId: ${selectedClientId},  
      selectedClientName: ${selectedClientName},  
      selectedYear: ${selectedYear}, 
      invoiceRefNo: ${invoiceRefNo}, 
      CSVInformationUITag: ${CSVInformationUI.tag}, 
      CSVInformationUIData: ${CSVInformationUI.data}
      HomePagePath: ${HomePagePath}
      selectedMenuItem: ${selectedMenuItem.id}
      drawerAction: ${drawerAction}
      activityLog: ${activityLog.screenName}
      isLoadAxiosCall: ${isLoadAxiosCall}
      stickyNavBar: ${stickyNavBar}
      `
    );
  useEffect(() => {
    if (isAdminUser !== 0) {
      if (selectedClientId && selectedClientId.length > 0) {
        dispatch(setSelectedHomePage({path:"/clients/dashboard"}));
        console.log("setting client routes with id : ",selectedClientId);
        setMenu(AdminClientRoutes);
      }
      else if (selectedAccountantId && selectedAccountantId.length > 0) {
        dispatch(setSelectedHomePage({path:"/accountants/clients"}));
        console.log("setting accountant routes");
        setMenu(AdminAccountantRoutes);
      }
      else {
        dispatch(setSelectedHomePage({path:"/global-search"}));
        console.log("setting admin routes");
        setMenu(AdminRoutes);
      }
    }
    else {
      // alert("Only Admin can Login So, Please provide Admin Username or Password!")
      // if (selectedClientId && selectedClientId.length > 0) {
      //   setMenu(ClientRoutes)
      // } else {
      //   setMenu(ClientListRoutes);
      // }
      // if (selectedAccountantId && selectedAccountantId.length > 0) {
      //   setMenu(ClientListRoutes);
      // }
      // else {
        //     setMenu(AccountantListRoutes);
        //   }
      if (selectedClientId && selectedClientId.length > 0) {
        dispatch(setSelectedHomePage({path:"/clients/dashboard"}));
        setMenu(ClientRoutes)
      }
      else if (selectedAccountantId && selectedAccountantId.length > 0) {
        dispatch(setSelectedHomePage({path:"/accountants/clients"}));
        console.log("inside ifffff12 sy phly ",accountantList)
        // if(accountantList && accountantList.items && accountantList.items.length !== 0){
        //   console.log("inside ifffff12")
        //   setMenu(ClientListRoutesWithAcc);
        // } else {
        //   setMenu(ClientListRoutes);
        // }
        if(accountantList && accountantList.items && accountantList.items.length > 1){
          console.log("inside ifffff12")
          setMenu(ClientListRoutesWithAcc);
        } else if(accountantList && accountantList.items && accountantList.items.length === 1){
          setMenu(ClientListRoutes);
          dispatch(setSelectedAccountantName(accountantList.items[0]));
        } else {
          setMenu(ClientListRoutes);
        }
      }
      else {
        dispatch(setSelectedHomePage({path:"/accountants"}));
        setMenu(AccountantListRoutes);
        }
    }

    if(accountantList && accountantList.message && accountantList.message.detail === "Invalid token or expired token."){
        let path = '/logout'
        navigate(path)
    }
  }, [accountantList,selectedClientId,selectedAccountantId]);

  return <LeftMenu menuItems={menu} />;
};
