import { ComponentState } from "common/enums";

import { ACCOUNTANT, CLIENT, LOGIN, VIEW_STATE } from "../types";

const initialState = {
  componentState: ComponentState.NONE,
  loading: false,
  error: false,
  success: false,
  message: "",
};

export const viewStateReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN.REQUEST:
    case CLIENT.API_KEYS_REQUEST_LOAD:
    case CLIENT.API_KEYS_REQUEST_GENERATE:
      return {
        ...state,
        loading: true,
        componentState: ComponentState.LOADING,
      };
    case LOGIN.SUCCESS:
      return {
        ...state,
        loading: false,
        componentState: ComponentState.DATA,
        success: action.payload.status,
        error: !action.payload.status,
      };
    case ACCOUNTANT.CLIENT_LIST_REQUEST_LOAD:
      return {
        ...state,
        loading: true,
        componentState: ComponentState.LOADING,
      };
    case ACCOUNTANT.CLIENT_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        componentState: ComponentState.DATA,
      };
    case ACCOUNTANT.CLIENT_LIST_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        message: "Something went wrong please try again later.",
        componentState: ComponentState.ERROR,
      };
    case ACCOUNTANT.ACCOUNTANT_LIST_REQUEST_LOAD:
      return {
        ...state,
        loading: true,
        componentState: ComponentState.LOADING,
      };
    case ACCOUNTANT.ACCOUNTANT_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        componentState: ComponentState.DATA,
      };
    case ACCOUNTANT.ACCOUNTANT_LIST_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        message: "Something went wrong please try again later.",
        componentState: ComponentState.ERROR,
      };
    case CLIENT.API_KEYS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        componentState: ComponentState.DATA,
      };
    case CLIENT.API_KEYS_REQUEST_GENERATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.status,
        message: "API keys generated successfully",
        componentState: ComponentState.DATA,
      };
    case CLIENT.API_KEYS_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        message: "API keys generation failed",
        componentState: ComponentState.ERROR,
      };
    case CLIENT.EMPLOYEE_LIST_REQUEST_LOAD:
      return {
        ...state,
        loading: true,
        componentState: ComponentState.LOADING,
      };
    case CLIENT.EMPLOYEE_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        componentState: ComponentState.DATA,
      };
    case CLIENT.EMPLOYEE_LIST_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        message: "Something went wrong please try again later.",
        componentState: ComponentState.ERROR,
      };
    case CLIENT.VENDOR_LIST_REQUEST_LOAD:
      return {
        ...state,
        loading: true,
        componentState: ComponentState.LOADING,
      };
    case CLIENT.VENDOR_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        componentState: ComponentState.DATA,
      };
    case CLIENT.VENDOR_LIST_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        message: "Something went wrong please try again later.",
        componentState: ComponentState.ERROR,
      };
    case VIEW_STATE.CLEAR_SUCCESS_STATE:
      return {
        ...state,
        success: false,
        message: "",
      };
    case VIEW_STATE.CLEAR_ERROR_STATE:
      return {
        ...state,
        error: false,
        message: "",
      };
    default:
      return state;
  }
};
