import T4SlipImportXmlLayout from "components/T4Slip/T4SlipImportXMLLayout/T4SlipImportXmlLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT4SlipImportXmlPage {}

export const T4SlipImportXmlPage: FC<IT4SlipImportXmlPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import XML T4 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t4/list" },
        { text: "T4", navigateTo: "/taxslip/t4/list" },
        { text: "Import XML T4 Slips", navigateTo: "/taxslip/t4/import-xml" },
      ]}
      mainContents={<T4SlipImportXmlLayout />}
    />
  );
};
