import { T3SlipList } from "components/T3Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT3SlipListPagePage {}

export const T3SlipListPage: FC<IT3SlipListPagePage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T3 Slip List"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t3/list" },
        { text: "T3", navigateTo: "/taxslip/t3/list" },
        { text: "T3 Slips", navigateTo: "/taxslip/t3/list" },
      ]}
      mainContents={<T3SlipList />}
    />
  );
};
