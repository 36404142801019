import React from "react";
import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Paper from "@mui/material/Paper";
import { createStyles, makeStyles } from "@mui/styles";
import { IDashboardPanels } from "./IDashboardPanels";
import { useDispatch, useSelector } from "react-redux";
import { setUserMenuOpen } from "store/actions";

const useStyles = makeStyles(() =>
  createStyles({
    panelCss: {
      display: "flex",
      flexDirection: "column",
      margin: "0 10px 20px 10px",
      alignItems: "center",
      padding: "20px",
      textAlign: "center",
      cursor: "pointer",
      height: "100%",
      borderRadius: "6px",
    },
    deadlineTitle: {
      marginBottom: "15px",
      fontSize: "16px",
      width: "100%",
      color: "#f44336",
      
      textAlign: "left",
      // textAlign: "center",
    },
    deadlineData: {
      fontSize: "1.75rem",
      color: "#f44336",
      fontWeight: 500,
      
      // marginTop: "60px",
      marginTop: "20px",
      textAlign:'center',
    },
    link: {
      textDecoration: 'none'
    },
  })
);

let employeeDrawerOptionsOpen = {
  id: 10110,
  text: "Employee",
  open: false,
  menu: [
    {
      menuId: 10111,
      text: "List",
      navigateToURL: "/resources/Employee/list",
      parentMenuId: 10110,
      grandParentMenuId: 10100,
    },
    {
      menuId: 10112,
      text: "Setup",
      navigateToURL: "/resources/Employee/setup",
      parentMenuId: 10110,
      grandParentMenuId: 10100,
    },
    {
      menuId: 10113,
      text: "Merge Employees",
      navigateToURL: "/resources/Employee/merge",
      parentMenuId: 10110,
      grandParentMenuId: 10100,
    },
    {
      menuId: 10114,
      text: "Undo Merge Employees",
      navigateToURL: "/resources/Employee/undo-merge",
      parentMenuId: 10110,
      grandParentMenuId: 10100,
    },
  ],
  parentMenuId: 10100
}
const vendorDrawerOptionsOpen ={
  id: 10120,
  text: "Vendor",
  open: false,
  menu: [
    {
      menuId: 10121,
      text: "List",
      navigateToURL: "/resources/Vendor/list",
      parentMenuId: 10120,
      grandParentMenuId: 10100,
    },
    {
      menuId: 10122,
      text: "Setup",
      navigateToURL: "/resources/Vendor/setup",
      parentMenuId: 10120,
      grandParentMenuId: 10100,
    },
  ],
  parentMenuId: 10100
}
const t5RecipientsDrawerOptionsOpen ={
    id: 10130,
    text: "T5 Recipient",
    open: false,
    menu: [
      {
        menuId: 10131,
        text: "List",
        navigateToURL: "/resources/T5_Recipient/list",
        parentMenuId: 10130,
        grandParentMenuId: 10100,
      },
      {
        menuId: 10132,
        text: "Setup",
        navigateToURL: "/resources/T5_Recipient/setup",
        parentMenuId: 10130,
        grandParentMenuId: 10100,
      },
    ],
    parentMenuId: 10100
}
const filingResourceDrawerOptionsOpen = {
  id: 10130,
  text: "Filing Resource",
  open: false,
  menu: [
    {
      menuId: 10131,
      text: "List",
      navigateToURL: "/filing-resources/list",
      parentMenuId: 10130,
      grandParentMenuId: 10100,
    },
    {
      menuId: 10132,
      text: "Setup",
      navigateToURL: "/filing-resources/setup",
      parentMenuId: 10130,
      grandParentMenuId: 10100,
    }
  ],
  parentMenuId: 10100
}
const t4SlipAndSummaryDrawerOptionsOpen = {
  id: 10410,
  text: "T4",
  open: false,
  menu: [
    {
      menuId: 10411,
      text: "List",
      navigateToURL: "/taxslip/t4/list",
      parentMenuId: 10410,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10412,
      text: "Setup",
      navigateToURL: "/taxslip/t4/setup",
      parentMenuId: 10410,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10413,
      text: "Summary",
      navigateToURL: "/taxslip/t4/summary",
      parentMenuId: 10410,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10414,
      text: "Summary Reduced EI",
      navigateToURL: "/taxslip/t4/summary-reduced-ei",
      parentMenuId: 10410,
      grandParentMenuId: 10400,
    }
  ],
  parentMenuId: 10400
}
const t4aSlipAndSummaryDrawerOptionsOpen = {
  id: 10420,
  text: "T4A",
  open: false,
  menu: [
    {
      menuId: 10421,
      text: "List",
      navigateToURL: "/taxslip/t4a/list",
      parentMenuId: 10420,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10422,
      text: "Setup",
      navigateToURL: "/taxslip/t4a/setup",
      parentMenuId: 10420,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10423,
      text: "Summary",
      navigateToURL: "/taxslip/t4a/summary",
      parentMenuId: 10420,
      grandParentMenuId: 10400,
    },
  ],
  parentMenuId: 10400
}
const rl1SlipAndSummaryDrawerOptionsOpen = {
  id: 10450,
  text: "RL-1",
  open: false,
  menu: [
    {
      menuId: 10441,
      text: "List",
      navigateToURL: "/taxslip/rl1/list",
      parentMenuId: 10450,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10442,
      text: "Setup",
      navigateToURL: "/taxslip/rl1/setup",
      parentMenuId: 10450,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10443,
      text: "Summary",
      navigateToURL: "/taxslip/rl1/summary",
      parentMenuId: 10450,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10444,
      text: "Settings",
      navigateToURL: "/taxslip/rl1/settings",
      parentMenuId: 10450,
      grandParentMenuId: 10400,
    },
  ],
  parentMenuId: 10400
}

const t5SlipAndSummaryDrawerOptionsOpen = {
  id: 10430,
  text: "T5",
  open: false,
  menu: [
    {
      menuId: 10421,
      text: "List",
      navigateToURL: "/taxslip/t5/list",
      parentMenuId: 10430,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10422,
      text: "Setup",
      navigateToURL: "/taxslip/t5/setup",
      parentMenuId: 10430,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10423,
      text: "Summary",
      navigateToURL: "/taxslip/t5/summary",
      parentMenuId: 10430,
      grandParentMenuId: 10400,
    },
  ],
  parentMenuId: 10400
}
const t3SlipAndSummaryDrawerOptionsOpen = {
  id: 10440,
  text: "T3",
  open: false,
  menu: [
    {
      menuId: 10431,
      text: "List",
      navigateToURL: "/taxslip/t3/list",
      parentMenuId: 10440,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10432,
      text: "Setup",
      navigateToURL: "/taxslip/t3/setup",
      parentMenuId: 10440,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10433,
      text: "Summary",
      navigateToURL: "/taxslip/t3/summary",
      parentMenuId: 10440,
      grandParentMenuId: 10400,
    },
  ],
  parentMenuId: 10400
}
const rl3SlipAndSummaryDrawerOptionsOpen = {
  id: 10460,
  text: "RL-3",
  open: false,
  menu: [
    {
      menuId: 10451,
      text: "List",
      navigateToURL: "/taxslip/rl3/list",
      parentMenuId: 10460,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10452,
      text: "Setup",
      navigateToURL: "/taxslip/rl3/setup",
      parentMenuId: 10460,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10453,
      text: "Summary",
      navigateToURL: "/taxslip/rl3/summary",
      parentMenuId: 10460,
      grandParentMenuId: 10400,
    },
  ],
  parentMenuId: 10400
}
const rl16SlipAndSummaryDrawerOptionsOpen = {
  id: 10470,
  text: "RL-16",
  open: false,
  menu: [
    {
      menuId: 10461,
      text: "List",
      navigateToURL: "/taxslip/rl16/list",
      parentMenuId: 10470,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10462,
      text: "Setup",
      navigateToURL: "/taxslip/rl16/setup",
      parentMenuId: 10470,
      grandParentMenuId: 10400,
    },
    {
      menuId: 10463,
      text: "Summary",
      navigateToURL: "/taxslip/rl16/summary",
      parentMenuId: 10470,
      grandParentMenuId: 10400,
    },
  ],
  parentMenuId: 10400
}
export const DashboardPanels = (props: IDashboardPanels) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const onItemClickHandler = (item:any) => {
    if(item.title === "Employees"){
      dispatch(setUserMenuOpen(employeeDrawerOptionsOpen));
    } else if(item.title === "Vendors") {
      dispatch(setUserMenuOpen(vendorDrawerOptionsOpen));
    } else if(item.title === "T5 Recipients") {
      dispatch(setUserMenuOpen(t5RecipientsDrawerOptionsOpen));
    } else if(item.title === "Filling Resource") {
      dispatch(setUserMenuOpen(filingResourceDrawerOptionsOpen));
    }
  }
  const onSummaryClickHandler = (item:any) => {
    if(item.title === "T4 Summary"){
      dispatch(setUserMenuOpen(t4SlipAndSummaryDrawerOptionsOpen));
    } else if(item.title === "T4A Summary") {
      dispatch(setUserMenuOpen(t4aSlipAndSummaryDrawerOptionsOpen));
    } else if(item.title === "RL-1 Summary") {
      dispatch(setUserMenuOpen(rl1SlipAndSummaryDrawerOptionsOpen));
    } else if(item.title === "T5 Summary") {
      dispatch(setUserMenuOpen(t5SlipAndSummaryDrawerOptionsOpen));
    } else if(item.title === "T3 Summary") {
      dispatch(setUserMenuOpen(t3SlipAndSummaryDrawerOptionsOpen));
    } else if(item.title === "RL-3 Summary") {
      dispatch(setUserMenuOpen(rl3SlipAndSummaryDrawerOptionsOpen));
    } else if(item.title === "RL-16 Summary") {
      dispatch(setUserMenuOpen(rl16SlipAndSummaryDrawerOptionsOpen));
    }
  }
  const onSlipClickHandler = (item:any) => {
    console.log("item.title",item.title)
    if(item.title === "T4"){
      dispatch(setUserMenuOpen(t4SlipAndSummaryDrawerOptionsOpen));
    } else if(item.title === "T4A") {
      dispatch(setUserMenuOpen(t4aSlipAndSummaryDrawerOptionsOpen));
    } else if(item.title === "RL-1") {
      dispatch(setUserMenuOpen(rl1SlipAndSummaryDrawerOptionsOpen));
    } else if(item.title === "T5") {
      dispatch(setUserMenuOpen(t5SlipAndSummaryDrawerOptionsOpen));
    } else if(item.title === "T3") {
      dispatch(setUserMenuOpen(t3SlipAndSummaryDrawerOptionsOpen));
    } else if(item.title === "RL-3") {
      dispatch(setUserMenuOpen(rl3SlipAndSummaryDrawerOptionsOpen));
    } else if(item.title === "RL-16") {
      dispatch(setUserMenuOpen(rl16SlipAndSummaryDrawerOptionsOpen));
    }
  }
  console.log("panelas", props);
  return (
    <Grid container spacing={2.5}>
      {props.panel?.map((item, index) => {
        return (
          <Grid item xs={12} sm={12} lg={3} xl={3} sx={{ pl: 0 }}>
            {item.jointPanels ? 
            (<Paper elevation={8} className={classes.panelCss} sx={{height:"auto"}}>
              {item.jointPanels.map((subItem:any,subItemIndex:any) => {
                return(
                  <Link to={subItem.navigateTo ? subItem.navigateTo : '#'}
                    className="no-underline"
                    onClick={() => onItemClickHandler(subItem)}
                    >
                        <Box key={subItemIndex}>
                        {subItem.icon}
                        <Typography
                          variant="h5"
                          sx={{
                            m: "15px 0",
                            fontSize: "16px",
                            color: "rgb(0, 82, 204)",
                          }}
                        >
                          {subItem?.title}
                        </Typography>
                        </Box>
                        {subItem.divider ? <Divider sx={{margin:"3.8rem 0 2.5rem 0"}}/> : undefined}
                    </Link>
                )
              })}
              </Paper>) : 
              (<Link to={item.navigateTo ? item.navigateTo : '#'} 
              className="no-underline"
              onClick={() => onItemClickHandler(item)}
              >
                <Paper elevation={8} className={classes.panelCss} key={index}>
                  {item.icon}
                  <Typography
                    variant="h5"
                    sx={{
                      m: "15px 0",
                      fontSize: "16px",
                      color: "rgb(0, 82, 204)",
                    }}
                  >
                    {item?.title}
                  </Typography>
                  <Typography
                    sx={{ color: "#303030!important", fontSize: "1.5em" }}
                  >
                    {item?.count}
                  </Typography>
                </Paper>
              </Link>
              )}
          </Grid>
        );
      })}
      <Grid item xs={12} sm={12} lg={3} xl={3}>
        <Paper elevation={8} className={classes.panelCss} sx={{cursor:"auto !important"}}>
          <Typography
            variant="h5"
            sx={{ mb: "15px", fontSize: "16px", color: "rgb(0, 82, 204)" }}
          >
            Slips
          </Typography>
          {props.slip.map((x, index) => {
            return (
              <>
                <Link
                  to={x?.navigateTo}
                  // underline="none"
                  className="no-underline"
                  style={{ width: "100%" }}
                  key={index}
                  onClick={() => onSlipClickHandler(x)}
                >
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      p: 0,
                    }}
                  >
                    <ListItem divider disablePadding sx={{color:"rgb(0, 82, 204)"}}>
                      <ListItemButton>
                        <ListItemIcon>
                          {x.icon && (
                            <img
                              src={x.icon}
                              style={{
                                color: "rgb(0, 82, 204)",
                                fontSize: "40px",
                              }}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={x.title}
                          sx={{
                            ml: "10px",
                            "& .MuiListItemText-primary": {
                              fontWeight: "bold",
                            },
                          }}
                        />
                        <Chip
                          label={x.slipCount}
                          sx={{ borderRadius: "5px" }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Link>
              </>
            );
          })}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} lg={3} xl={3}>
        <Paper elevation={8} className={classes.panelCss} sx={{cursor:"auto !important"}}>
          <Typography
            variant="h5"
            sx={{ mb: "15px", fontSize: "16px", color: "rgb(0, 82, 204)" }}
          >
            Summary
          </Typography>
          {props.summaryList.map((x, index) => {
            return (
              <Link
                to={x?.navigateTo}
                // underline="none"
                // className="no-underline"
                style={{ width: "100%" }}
                className={classes.link}
                key={index}
                onClick={() => onSummaryClickHandler(x)}
              >
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    p: 0,
                  }}
                >
                  <ListItem divider disablePadding sx={{color:"rgb(0, 82, 204)"}}>
                    <ListItemButton>
                      <ListItemIcon>
                        {x.icon && (
                          <img
                            src={x.icon}
                            style={{
                              color: "rgb(0, 82, 204)",
                              fontSize: "40px",
                            }}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={x.title}
                        sx={{
                          ml: "10px",
                          "& .MuiListItemText-primary": { fontWeight: "bold" },
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Link>
            );
          })}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} lg={3} xl={3}>
        <Paper elevation={8} className={classes.panelCss} sx={{cursor:"auto !important"}}>
          <Typography
            variant="h5"
            sx={{ mb: "15px", fontSize: "16px", color: "rgb(0, 82, 204)" }}
          >
            Slips
          </Typography>
          {props.slipSecondList.map((x, index) => {
            return (
              <>
                <Link
                  to={x?.navigateTo}
                  // underline="none"
                  className="no-underline"
                  style={{ width: "100%" }}
                  key={index}
                  onClick={() => onSlipClickHandler(x)}
                >
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      p: 0,
                    }}
                  >
                    <ListItem divider disablePadding sx={{color:"rgb(0, 82, 204)"}}>
                      <ListItemButton>
                        <ListItemIcon>
                          {x.icon && (
                            <img
                              src={x.icon}
                              style={{
                                color: "rgb(0, 82, 204)",
                                fontSize: "40px",
                              }}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={x.title}
                          sx={{
                            ml: "10px",
                            "& .MuiListItemText-primary": {
                              fontWeight: "bold",
                            },
                          }}
                        />
                        <Chip
                          label={x.slipCount}
                          sx={{ borderRadius: "5px" }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Link>
              </>
            );
          })}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} lg={3} xl={3}>
        <Paper elevation={8} className={classes.panelCss} sx={{cursor:"auto !important"}}>
          <Typography
            variant="h5"
            sx={{ mb: "15px", fontSize: "16px", color: "rgb(0, 82, 204)" }}
          >
            Summary
          </Typography>
          {props.summarySecondList.map((x, index) => {
            return (
              <Link
                to={x?.navigateTo}
                // underline="none"
                // className="no-underline"
                style={{ width: "100%" }}
                className={classes.link}
                key={index}
                onClick={() => onSummaryClickHandler(x)}
              >
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    p: 0,
                  }}
                >
                  <ListItem divider disablePadding sx={{color:"rgb(0, 82, 204)"}}>
                    <ListItemButton>
                      <ListItemIcon>
                        {x.icon && (
                          <img
                            src={x.icon}
                            style={{
                              color: "rgb(0, 82, 204)",
                              fontSize: "40px",
                            }}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={x.title}
                        sx={{
                          ml: "10px",
                          "& .MuiListItemText-primary": { fontWeight: "bold" },
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Link>
            );
          })}
        </Paper>
      </Grid>
      {props.panelSecondlist?.map((item, index) => {
        return (
          <Grid item xs={12} sm={12} lg={3} xl={3} sx={{ pl: 0 }}>
            {item.jointPanels ? 
            (<Paper elevation={8} className={classes.panelCss} sx={{height:"auto"}}>
              {item.jointPanels.map((subItem:any,subItemIndex:any) => {
                return(
                  <Link to={subItem.navigateTo ? subItem.navigateTo : '#'}
                    className="no-underline"
                    onClick={() => onItemClickHandler(subItem)}
                    >
                        <Box key={subItemIndex}>
                        {subItem.icon}
                        <Typography
                          variant="h5"
                          sx={{
                            m: "15px 0",
                            fontSize: "16px",
                            color: "rgb(0, 82, 204)",
                          }}
                        >
                          {subItem?.title}
                        </Typography>
                        </Box>
                        {subItem.divider ? <Divider sx={{margin:"3.8rem 0 2.5rem 0"}}/> : undefined}
                    </Link>
                )
              })}
              </Paper>) : 
              (<Link to={item.navigateTo ? item.navigateTo : '#'} 
              className="no-underline"
              onClick={() => onItemClickHandler(item)}
              >
                <Paper elevation={8} className={classes.panelCss} key={index}>
                  {item.icon}
                  <Typography
                    variant="h5"
                    sx={{
                      m: "15px 0",
                      fontSize: "16px",
                      color: "rgb(0, 82, 204)",
                    }}
                  >
                    {item?.title}
                  </Typography>
                  <Typography
                    sx={{ color: "#303030!important", fontSize: "1.5em" }}
                  >
                    {item?.count}
                  </Typography>
                </Paper>
              </Link>
              )}
          </Grid>
        );
      })}
      <Grid item xs={12} sm={12} lg={3} xl={3}>
        <Paper elevation={8} className={classes.panelCss} sx={{cursor:"auto !important"}}>
          <Typography variant="h5" className={classes.deadlineTitle}>
            {props.deadline.title}
          </Typography>
          <Typography variant="h3" className={classes.deadlineData}>
            {props.deadline.date}
          </Typography>
        </Paper>

        {/* this is used when filing resource and filingdeadline use in same panel and seperate with seperator line */}
        {/* <Paper elevation={8} className={classes.panelCss} sx={{height:"auto"}}>
              {props.deadline.map((item:any,itemIndex:any) => {
                return(
                  <>
                  {item.navigateTo ? 
                  <><Link to={item.navigateTo ? item.navigateTo : '#'}
                    className="no-underline"
                    onClick={() => onItemClickHandler(item)}
                    >
                        <Box key={itemIndex}>
                        {item.icon}
                        <Typography
                          variant="h5"
                          sx={{
                            m: "15px 3rem",
                            fontSize: "16px",
                            color: "rgb(0, 82, 204)",
                          }}
                        >
                          {item?.title}
                        </Typography>
                        </Box>
                        {item.divider ? <Divider sx={{margin:"3.8rem 0 2.5rem 0"}}/> : undefined}
                    </Link></>
                    :<>
                    <Typography variant="h5" className={classes.deadlineTitle}>
                      {item.title}
                    </Typography>
                    <Typography variant="h3" className={classes.deadlineData}>
                      {item.date}
                    </Typography>
                    </>}
                    </>
                )
              })}
            </Paper> */}
      </Grid>
    </Grid>
  );
};
