import { checkSINValidity } from 'components/T4Slip/T4SlipSetup/validator';
import * as yup from 'yup';

export const RL1SlipSetupValidationSchema = yup.object({
  applyAdjustments: yup.string().required("Apply Adjustments is required"),
  addressLine1: yup
  .string()
  .required("Address is required")
  .max(50, "Address has at most 50 characters"),
  uniqueIdentifier: yup.string().test(
    'is-masked-or-not',
    'Invalid value',
    function (value:any) {
      let sin = value && value.replace(/-/g, '')
      const output = checkSINValidity(sin);
      console.log("output",output)
      let maxLength = 11;

      if (value && value.charAt(2) === '*') {
        maxLength = 7;
        if (value && value.length == maxLength) {
          return true;
        } else {
          return this.createError({
            message: 'Invalid Social Insuarance Number',
            path: this.path,
          });
        }
      } else {
        if (value && value.length == maxLength && output % 10 === 0) {
          return true;
        } else {
          return this.createError({
            message: 'Invalid Social Insuarance Number',
            path: this.path,
          });
        }
      }
    }
  ).required('Social Insuarance is required'),
name1: yup
  .string()
  .required("First Name is required")
  .max(12, "First Name has at most 12 characters"),
name2: yup
  .string()
  .required("Last Name is required")
  .max(20, "Last Name has at most 20 characters"),
initials: yup.string().max(1, "maximum one character"),
city: yup
  .string()
  .required("City is required")
  .max(28, "City has at most 28 characters"),
  province: yup.string().nullable().required("Province is required"),
postalCode: yup
  .string()
  .required("Employee PostCode is required")
  .max(10, "Post Code has at most 10 characters"),
country: yup.string().required("Employee Country is required"),
payPeriod: yup.number().required("payPeriods is required"),
pensionablePeriod: yup.number()
.transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
.required("pensionable Periods is required").nullable()
.test(
  "is-less-than-or-equal-to-payPeriod",
  "pensionable Periods must be less than or equal to Pay Periods",
  function (value:any) {
    const { payPeriod } = this.parent;
    return value <= payPeriod;
  }
),
slipType: yup.number().required("slipType is required"),
// code:yup.string().when("boxOAutresRevenus",{
//   is: (name: string) => name && name.length > 0,
//   then: yup.string().required('Code is required'),
//   // otherwise: yup.string(),
// }),
// boxOAutresRevenus:yup.string().when("code",{
//   is: (name: string) => {console.log("name23",name)
//   name && name.trim().length > 0},
//   then: yup.string().required('Box O is required because of code selection'),
//   // otherwise: yup.string(),
// }),

// boxOAutresRevenus: yup.string().when('code', {
//   is: (value: string) => value !== undefined,
//   then: yup.string().required('boxOAutresRevenus is required'),
// }),
// code: yup.string().when('boxOAutresRevenus', {
//   is: (value: string) => value && value.length > 0,
//   then: yup.string().required('Code is required'),
// }),

boxOAutresRevenus: yup.string().nullable().test(
  'requiredWithCode',
  'boxOAutresRevenus is required',
  function (value) {
    const selectValue = this.resolve(yup.ref('code'));
    return selectValue !== null && undefined ? !!value : true;
  }
),
code: yup.string().nullable().test(
  'requiredWithBoxO',
  'Code is required',
  function (value) {
    const textFieldValue = this.resolve(yup.ref('boxOAutresRevenus'));
    return textFieldValue !== '' && null ? !!value : true;
  }
),

email: yup
    .string().nullable()
    .email("Please enter valid Email"),
});
