import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IT5SLipAxisPage3 {}
const T5SLipAxisPage3:React.FC<IT5SLipAxisPage3> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP3X'} 
                testId={'taxYearP3X'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'box24P3X'} 
                testId={'box24P3X'} 
                label={'Box 24'} 
                labelPosition={LabelPosition.Left}
                defaultValue={80}
            />
            <RHookFormTextFieldWithLabel 
                id={'box25P3X'} 
                testId={'box25P3X'} 
                label={'Box 25'} 
                labelPosition={LabelPosition.Left}
                defaultValue={185}
            />
            <RHookFormTextFieldWithLabel 
                id={'box26P3X'} 
                testId={'box26P3X'} 
                label={'Box 26'} 
                labelPosition={LabelPosition.Left}
                defaultValue={290}
            />
            <RHookFormTextFieldWithLabel 
                id={'box13P3X'} 
                testId={'box13P3X'} 
                label={'Box 13'} 
                labelPosition={LabelPosition.Left}
                defaultValue={395}
            />
            <RHookFormTextFieldWithLabel 
                id={'box18P3X'} 
                testId={'box18P3X'} 
                label={'Box 18'} 
                labelPosition={LabelPosition.Left}
                defaultValue={500}
            />
            <RHookFormTextFieldWithLabel 
                id={'box10P3X'} 
                testId={'box10P3X'} 
                label={'Box 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={80}
            />
            <RHookFormTextFieldWithLabel 
                id={'box11P3X'} 
                testId={'box11P3X'} 
                label={'Box 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={185}
            />
            <RHookFormTextFieldWithLabel 
                id={'box12P3X'} 
                testId={'box12P3X'} 
                label={'Box 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={290}
            />
            <RHookFormTextFieldWithLabel 
                id={'box21P3X'} 
                testId={'box21P3X'} 
                label={'Box 21'} 
                labelPosition={LabelPosition.Left}
                defaultValue={385}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22P3X'} 
                testId={'box22P3X'} 
                label={'Box 22'} 
                labelPosition={LabelPosition.Left}
                defaultValue={440}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22P3BsX'} 
                testId={'box22P3BsX'} 
                label={'Box 22 B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={445}
            />
            <RHookFormTextFieldWithLabel 
                id={'box23P3X'} 
                testId={'box23P3X'} 
                label={'Box 23'} 
                labelPosition={LabelPosition.Left}
                defaultValue={535}
            />
            <RHookFormTextFieldWithLabel 
                id={'box27P3X'} 
                testId={'box27P3X'} 
                label={'Box 27'} 
                labelPosition={LabelPosition.Left}
                defaultValue={190}
            />
            <RHookFormTextFieldWithLabel 
                id={'box28P3X'} 
                testId={'box28P3X'} 
                label={'Box 28'} 
                labelPosition={LabelPosition.Left}
                defaultValue={245}
            />
            <RHookFormTextFieldWithLabel 
                id={'box29P3X'} 
                testId={'box29P3X'} 
                label={'Box 29'} 
                labelPosition={LabelPosition.Left}
                defaultValue={345}
            />
            <RHookFormTextFieldWithLabel 
                id={'case1P3X'} 
                testId={'case1P3X'} 
                label={'Case 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={155}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase1P3X'} 
                testId={'amountCase1P3X'} 
                label={'Amount Case 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={205}
            />
            <RHookFormTextFieldWithLabel 
                id={'case2P3X'} 
                testId={'case2P3X'} 
                label={'Case 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={300}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase2P3X'} 
                testId={'amountCase2P3X'} 
                label={'Amount Case 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={350}
            />
            <RHookFormTextFieldWithLabel 
                id={'case3P3X'} 
                testId={'case3P3X'} 
                label={'Case 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={450}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase3P3X'} 
                testId={'amountCase3P3X'} 
                label={'Amount Case 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={500}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientNameP3X'} 
                testId={'recipientNameP3X'} 
                label={'Recipient Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientLine1NameP3X'} 
                testId={'recipientLine1NameP3X'} 
                label={'Recipient Line 1 Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeNameP3X'} 
                testId={'payeeNameP3X'} 
                label={'Payee Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={330}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeAddressP3X'} 
                testId={'payeeAddressP3X'} 
                label={'Payee Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={330}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeCityP3X'} 
                testId={'payeeCityP3X'} 
                label={'Payee City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={330}
            />
            {/* <RHookFormTextFieldWithLabel 
                id={'recipientInitialP3X'} 
                testId={'recipientInitialP3X'} 
                label={'Recipient Initial'} 
                labelPosition={LabelPosition.Left}
                defaultValue={30}
            /> */}
            <RHookFormTextFieldWithLabel 
                id={'recipientAddressP3X'} 
                testId={'recipientAddressP3X'} 
                label={'Recipient Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientCityP3X'} 
                testId={'recipientCityP3X'} 
                label={'Recipient City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'pageP3X'} 
                testId={'pageP3X'} 
                label={'Page'} 
                labelPosition={LabelPosition.Left}
                defaultValue={585}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP3Y'} 
                testId={'taxYearP3Y'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={402}
            />
            <RHookFormTextFieldWithLabel 
                id={'box24P3Y'} 
                testId={'box24P3Y'} 
                label={'Box 24'} 
                labelPosition={LabelPosition.Left}
                defaultValue={369}
            />
            <RHookFormTextFieldWithLabel 
                id={'box25P3Y'} 
                testId={'box25P3Y'} 
                label={'Box 25'} 
                labelPosition={LabelPosition.Left}
                defaultValue={369}
            />
            <RHookFormTextFieldWithLabel 
                id={'box26P3Y'} 
                testId={'box26P3Y'} 
                label={'Box 26'} 
                labelPosition={LabelPosition.Left}
                defaultValue={369}
            />
            <RHookFormTextFieldWithLabel 
                id={'box13P3Y'} 
                testId={'box13P3Y'} 
                label={'Box 13'} 
                labelPosition={LabelPosition.Left}
                defaultValue={369}
            />
            <RHookFormTextFieldWithLabel 
                id={'box18P3Y'} 
                testId={'box18P3Y'} 
                label={'Box 18'} 
                labelPosition={LabelPosition.Left}
                defaultValue={369}
            />
            <RHookFormTextFieldWithLabel 
                id={'box10P3Y'} 
                testId={'box10P3Y'} 
                label={'Box 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={332}
            />
            <RHookFormTextFieldWithLabel 
                id={'box11P3Y'} 
                testId={'box11P3Y'} 
                label={'Box 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={332}
            />
            <RHookFormTextFieldWithLabel 
                id={'box12P3Y'} 
                testId={'box12P3Y'} 
                label={'Box 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={332}
            />
            <RHookFormTextFieldWithLabel 
                id={'box21P3Y'} 
                testId={'box21P3Y'} 
                label={'Box 21'} 
                labelPosition={LabelPosition.Left}
                defaultValue={332}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22P3Y'} 
                testId={'box22P3Y'} 
                label={'Box 22'} 
                labelPosition={LabelPosition.Left}
                defaultValue={332}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22P3BsY'} 
                testId={'box22P3BsY'} 
                label={'Box 22 B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={332}
            />
            <RHookFormTextFieldWithLabel 
                id={'box23P3Y'} 
                testId={'box23P3Y'} 
                label={'Box 23'} 
                labelPosition={LabelPosition.Left}
                defaultValue={332}
            />
            <RHookFormTextFieldWithLabel 
                id={'box27P3Y'} 
                testId={'box27P3Y'} 
                label={'Box 27'} 
                labelPosition={LabelPosition.Left}
                defaultValue={180}
            />
            <RHookFormTextFieldWithLabel 
                id={'box28P3Y'} 
                testId={'box28P3Y'} 
                label={'Box 28'} 
                labelPosition={LabelPosition.Left}
                defaultValue={180}
            />
            <RHookFormTextFieldWithLabel 
                id={'box29P3Y'} 
                testId={'box29P3Y'} 
                label={'Box 29'} 
                labelPosition={LabelPosition.Left}
                defaultValue={180}
            />
            <RHookFormTextFieldWithLabel 
                id={'case1P3Y'} 
                testId={'case1P3Y'} 
                label={'Case 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={296}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase1P3Y'} 
                testId={'amountCase1P3Y'} 
                label={'Amount Case 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={296}
            />
            <RHookFormTextFieldWithLabel 
                id={'case2P3Y'} 
                testId={'case2P3Y'} 
                label={'Case 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={296}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase2P3Y'} 
                testId={'amountCase2P3Y'} 
                label={'Amount Case 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={296}
            />
            <RHookFormTextFieldWithLabel 
                id={'case3P3Y'} 
                testId={'case3P3Y'} 
                label={'Case 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={296}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase3P3Y'} 
                testId={'amountCase3P3Y'} 
                label={'Amount Case 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={296}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientNameP3Y'} 
                testId={'recipientNameP3Y'} 
                label={'Recipient Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={245}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientLine1NameP3Y'} 
                testId={'recipientLine1NameP3Y'} 
                label={'Recipient Line 1 Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={230}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeNameP3Y'} 
                testId={'payeeNameP3Y'} 
                label={'Payee Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={245}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeAddressP3Y'} 
                testId={'payeeAddressP3Y'} 
                label={'Payee Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={230}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeCityP3Y'} 
                testId={'payeeCityP3Y'} 
                label={'Payee City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={215}
            />
            {/* <RHookFormTextFieldWithLabel 
                id={'recipientInitialP3Y'} 
                testId={'recipientInitialP3Y'} 
                label={'Recipient Initial'} 
                labelPosition={LabelPosition.Left}
                defaultValue={30}
            /> */}
            <RHookFormTextFieldWithLabel 
                id={'recipientAddressP3Y'} 
                testId={'recipientAddressP3Y'} 
                label={'Recipient Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={215}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientCityP3Y'} 
                testId={'recipientCityP3Y'} 
                label={'Recipient City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={200}
            />
            <RHookFormTextFieldWithLabel 
                id={'pageP3Y'} 
                testId={'pageP3Y'} 
                label={'Page'} 
                labelPosition={LabelPosition.Left}
                defaultValue={154}
            />
        </Grid>
    </Grid>
  )
}

export default T5SLipAxisPage3