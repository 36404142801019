import { FLEX_FIELD_PANEL_ASSIGNMENT_SETUP_SCREEN_SLUG, INITIAL_SNACKBAR_DATA, QUERY_OPTIONS } from "common/constants";
import { instanceOfIFlexFieldPanelAssignmentResponsePayload } from "common/instance-method";
import {
  IDropDownListResponse,
  IFlexFieldPanelAssignmentRequestPayload,
  IFlexFieldPanelAssignmentResponsePayload,
} from "models";
import { IResponse, IServerError } from "models/common";
import { FC, useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { FlexFieldPanelService } from "services";

import { yupResolver } from "@hookform/resolvers/yup";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Box, Grid, useTheme } from "@mui/material";
import {
  BackdropCircularProgress,
  DNDTwoColumnList,
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  IDNDList,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  SubmitButton,
  SubmitButtonVariant,
} from "@websential/cosmic";

import { FlexFieldPanelAssignmentSchema } from "./validator";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch } from "react-redux";

export interface IFlexFieldPanelAssignment extends ITest {}
const firstPanelDraggableId = "firstList";
const secondPanelDraggableId = "secondList";

const FLEX_FIELD_PANEL_LABEL_DDL_QUERY_KEY = "get-flex-field-panel-label-dll";
const FLEX_FIELD_PANEL_LABEL_FIELDS_QUERY_KEY =
  "get-flex-field-panel-label-fields";

const useGetFlexFieldPanelFields = (flexFieldPanelId: number) => {
  return useQuery(
    [FLEX_FIELD_PANEL_LABEL_FIELDS_QUERY_KEY, flexFieldPanelId],
    () =>
      FlexFieldPanelService.getFlexFieldsAssignedToPanel(
        String(flexFieldPanelId)
      ),
    { ...QUERY_OPTIONS, enabled: !!flexFieldPanelId }
  );
};

const useGetDropDownList = () =>
  useQuery(
    FLEX_FIELD_PANEL_LABEL_DDL_QUERY_KEY,
    () => FlexFieldPanelService.getDropDownList(),
    QUERY_OPTIONS
  );

export const FlexFieldPanelAssignment: FC<IFlexFieldPanelAssignment> = ({
  testId,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [firstList, setFirstList] = useState<IDNDList>({
    droppableId: firstPanelDraggableId,
    elements: [],
    testId: firstPanelDraggableId,
  });
  const [secondList, setSecondList] = useState<IDNDList>({
    droppableId: secondPanelDraggableId,
    elements: [],
    testId: secondPanelDraggableId,
  });
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [selectedPanel, setSelectedPanel] = useState(0);
  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    FlexFieldPanelService.assignFlexFieldsToPanel,
    {
      onSuccess: (
        res:
          | IFlexFieldPanelAssignmentResponsePayload
          | IServerError
          | IResponse
          | null
      ) => {
        setSnackbarData({
          open: true,
          message: "Flex field(s) have been assigned to the panel successfully",
          severity: "success",
        });
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "An error occurred, please try again",
          severity: "error",
        });
      },
    }
  );
  const { isLoading: isLoadingDropDownList, data: flexFieldPanelLabelList } =
    useGetDropDownList();
  const {
    isLoading: isLoadingAssignedFields,
    data: flexFieldsAssignedToPanel,
  } = useGetFlexFieldPanelFields(selectedPanel);

  const formMethods = useForm<IFlexFieldPanelAssignmentRequestPayload>({
    resolver: yupResolver(FlexFieldPanelAssignmentSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const onSubmit = (formData: IFlexFieldPanelAssignmentRequestPayload) => {
    console.log("======>secondList", secondList);
    let requestPayload: IFlexFieldPanelAssignmentRequestPayload = {
      panelId: selectedPanel,
      assignedFields: [],
    };
    console.log("requestPayload", requestPayload);
    for (let index = 0; index < secondList.elements.length; index++) {
      requestPayload.assignedFields.push({
        sequence: index + 1,
        field: Number(secondList.elements[index].id),
      });
    }
    console.log("requestPayload", requestPayload);
    submitFormData(requestPayload);
  };

  // const onResetClicked = () => {
  //   setSnackbarData({
  //     open: true,
  //     message: "Form was reset successfully",
  //     severity: "success",
  //   });
  //   formMethods.reset(DEFAULT_VALUES);
  // };

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const onDragEnd = (result: DropResult, _provided: ResponderProvided) => {
    if (
      result.destination &&
      result.source.droppableId !== result.destination.droppableId
    ) {
      if (result.source.droppableId === firstPanelDraggableId) {
        let sourceList = firstList;
        let destinationList = secondList;
        let movedItem = sourceList.elements.splice(result.source.index, 1)[0];
        destinationList.elements.splice(result.destination.index, 0, movedItem);
        setFirstList({
          ...sourceList,
        });
        setSecondList({
          ...destinationList,
        });
      } else {
        let sourceList = secondList;
        let destinationList = firstList;
        let movedItem = sourceList.elements.splice(result.source.index, 1)[0];
        destinationList.elements.splice(result.destination.index, 0, movedItem);
        setSecondList({
          ...sourceList,
        });
        setFirstList({
          ...destinationList,
        });
      }
    } else {
      if (!result.destination) return;
      if (result.source.droppableId === firstPanelDraggableId) {
        let sourceDestinationList = firstList;
        let sourceItem = sourceDestinationList.elements.splice(
          result.source.index,
          1
        )[0];
        sourceDestinationList.elements.splice(
          result.destination.index,
          0,
          sourceItem
        );
        setFirstList({
          ...sourceDestinationList,
        });
      } else {
        let sourceDestinationList = secondList;
        let sourceItem = sourceDestinationList.elements.splice(
          result.source.index,
          1
        )[0];
        sourceDestinationList.elements.splice(
          result.destination.index,
          0,
          sourceItem
        );
        setSecondList({
          ...sourceDestinationList,
        });
      }
    }
    // if(result.destination.droppableId === "secondList"){
    // //this call is for accosiate clients
    // let requestPayload: IFlexFieldPanelAssignmentRequestPayload = {
    //   panelId: selectedPanel,
    //   assignedFields: [],
    // };
    // console.log("requestPayload",requestPayload)
    // for (let index = 0; index < secondList.elements.length; index++) {
    //   requestPayload.assignedFields.push({
    //     sequence: index + 1,
    //     field: Number(secondList.elements[index].id),
    //   });
    // }
    // console.log("requestPayload",requestPayload)
    // //   submitFormData(Payload)
    // } else {
    // //this call is for UnAccosiate clients
    // let requestPayload: IFlexFieldPanelAssignmentRequestPayload = {
    //   panelId: selectedPanel,
    //   assignedFields: [],
    // };
    // console.log("requestPayload",requestPayload)
    // for (let index = 0; index < firstList.elements.length; index++) {
    //   requestPayload.assignedFields.push({
    //     sequence: index + 1,
    //     field: Number(firstList.elements[index].id),
    //   });
    // }
    // console.log("requestPayload",requestPayload)
    // //   submitFormData(Payload)
    // }
  };

  const panelItems = useMemo(() => {
    if (flexFieldPanelLabelList && Array.isArray(flexFieldPanelLabelList)) {
      return flexFieldPanelLabelList.map(
        (flexFieldPanel: IDropDownListResponse) => ({
          label: flexFieldPanel.label,
          value: flexFieldPanel.value,
        })
      );
    }

    // Return empty array in case of API data fetching error
    return [];
  }, [flexFieldPanelLabelList]);

  useEffect(() => {
    if (
      flexFieldsAssignedToPanel &&
      instanceOfIFlexFieldPanelAssignmentResponsePayload(
        flexFieldsAssignedToPanel
      )
    ) {
      let unassignedFlexFields: IDNDList = {
        droppableId: firstPanelDraggableId,
        elements: flexFieldsAssignedToPanel.unassignedFlexFields.map(
          (unassignedFlexField: any) => ({
            id: String(unassignedFlexField.id),
            text: String(unassignedFlexField.text),
            value: String(unassignedFlexField.value),
          })
        ),
        testId: firstPanelDraggableId,
      };

      let assignedFlexFields: IDNDList = {
        droppableId: secondPanelDraggableId,
        elements: flexFieldsAssignedToPanel.assignedFlexFields.map(
          (assignedFlexField: any) => ({
            id: String(assignedFlexField.id),
            text: String(assignedFlexField.text),
            value: String(assignedFlexField.value),
          })
        ),
        testId: secondPanelDraggableId,
      };
      setFirstList(unassignedFlexFields);
      setSecondList(assignedFlexFields);
    }
  }, [flexFieldsAssignedToPanel]);

  const onPanelSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
    setSelectedPanel(Number(item.value));
  };

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: FLEX_FIELD_PANEL_ASSIGNMENT_SETUP_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={isSubmitting || isLoadingDropDownList || isLoadingAssignedFields}
        testId={`${testId}-backdrop`}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            children={
              <>
                <StandardPanel
                  title="Assign Flex Field(s) to Panel"
                  testId={`${testId}-screen-heading-panel`}
                  leftSpacing={2}
                  rightSpacing={2}
                >
                  <EnhancedDropDownRHFWithLabel
                    testId={`${testId}-name`}
                    label="Select Panel"
                    labelPosition={LabelPosition.Left}
                    required
                    selectName="selectedPanel"
                    items={panelItems}
                    onSelectionChangeHandler={onPanelSelectionChangeHandler}
                  />
                </StandardPanel>
                {selectedPanel > 0 && (
                  <Box sx={{ pt: theme.spacing(3) }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <DNDTwoColumnList
                        firstColumnListTitle="UnAssociations"
                        firstColumnList={firstList}
                        secondColumnListTitle="Associations"
                        secondColumnList={secondList}
                        testId="flex-fields-assignment"
                      />
                    </DragDropContext>
                  </Box>
                )}
              </>
            }
            toolbar={
              <StandardSubmitFormButtonsToolbar
                toolbarContents={
                  <SubmitButton
                    label={"Save"}
                    testId="save-button"
                    variant={SubmitButtonVariant.Secondary}
                    icon={<PlaylistAddCheckOutlinedIcon />}
                  />
                }
                // onResetClicked={onResetClicked}
                isSubmitting={false}
                testId="flex-fields-panel-assignment-toolbar"
              />
            }
            testId="form-layout"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
