import {
  LabelPosition,
  RHookFormTextFieldWithLabel
} from "@websential/cosmic";
import { IRemittanceRequestPayload } from "models/common/ITransferRemittance";

import { FC, useEffect } from "react";
import {
  Control,
  Controller,
  UseFormReturn,
  useFieldArray,
} from "react-hook-form";

import Grid from "@mui/material/Grid";
import {
  ITest,
} from "@websential/cosmic";
import { Box, TextField, Typography } from "@mui/material";

export interface IRemittanceTransferEmployeeSetup extends ITest {
  formMethods: UseFormReturn< any,IRemittanceRequestPayload>;
  items: any;
  dividerOverUnderRemittance: any;
  undoRemitanceTransferRL1: (value: boolean) => void;
}

const FIELD_NAME = "undoremittance11";

interface EnhancedDDLFieldProps extends ITest {
  name: any;
  control: Control<any>;
  label: any;
  formMethods:any;
  defualtValue:any;
  items: any;
}

const CustomFormTextFieldWithLabel: FC<EnhancedDDLFieldProps> = ({
  name,
  control,
  label,
  testId,
  defualtValue,
  formMethods,
  items
}) => {
  const handleInputChange = (e: any) => {
    for (let i = 0; i < items.length; i++) {
      const key = `undoremittance11.${i}.resourceRemittanceValue`;
      if (key === e.target.name) {
        console.log("e.target.name", e.target.name);
        formMethods.setValue(e.target.name, e.target.value);
      }
    }
  };
  return (
    <Controller
    render={({ field, fieldState: { error } }) => {
      const { ref, ...rest } = field;
      return (
        <Grid container sx={{ margin: "8px 0 4px 0" }}>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              sx={{ fontSize: "0.875rem", color: "rgba(0, 0, 0, 0.6)" }}
            >
              {label}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              {...rest}
              id={name}
              {...formMethods.register(name)}
              value={formMethods.watch(name)}
              defaultValue={defualtValue}
              {...(error && { helperText: error.message })}
              onChange={handleInputChange}
              sx={{
                "& .MuiInputBase-input": { padding: "8.5px 14px" },
                width: "100%",
                border: 0,
              }}
            />
          </Grid>
        </Grid>
      );
    }}
    name={name}
    control={control}
  />
  );
};
export const UndoRL1RemittanceTransferEmployeeSetup: FC<IRemittanceTransferEmployeeSetup> =
  ({
    formMethods,
    items,
    dividerOverUnderRemittance,
    testId,
    undoRemitanceTransferRL1
  }) => {
//     const { fields, append, remove } = useFieldArray({
//       control: formMethods.control,
//       name: FIELD_NAME,
//     });

//     useEffect(() => {
//       if(items && items !== null){
//         append(items)
//         for (let i = 0; i < items && items.length; i++) {
//           console.log(`undoremittance11.${i}.resourceRemittanceValue`);
//           formMethods.setValue(
//             `undoremittance11.${i}.resourceRemittanceValue`,
//             dividerOverUnderRemittance
//           );
//         }
//       }
//     }, []);
// console.log("gettt 11 inside",formMethods.getValues('undoremittance11.0.resourceRemittanceValue'))
 undoRemitanceTransferRL1(true)
    return (
      <>
        {/* {fields && fields.map((item:any, index) => {
          return (
            <Grid container xs={12} key={item.slipId} alignItems="center">

                <CustomFormTextFieldWithLabel
                  name={`${FIELD_NAME}.${index}.resourceRemittanceValue`}
                  label={item && item.resourceFirstName}
                  control={formMethods.control}
                  testId={`${testId}-${index}-code`}
                  formMethods={formMethods}
                  defualtValue={dividerOverUnderRemittance && dividerOverUnderRemittance}
                  items={items}
                />
              </Grid>
          );
        })} */}

        {items && items.map((item:any,index:any) => {
          return(
            <Box sx={{ paddingBottom: 2,display:"flex",justifyContent:"space-between"}}>
              <Typography>{item.resourceFirstName}</Typography>
              <Typography>{item.resourceRemittanceValue}</Typography>
            </Box>
          )
        })}

      </>
    );
  };
