import { T5SlipSummarySetup } from "components/T5Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT5SlipSummarySetupPage {}

export const T5SlipSummarySetupPage: FC<IT5SlipSummarySetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T5 Summary"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t5/list" },
        { text: "T5", navigateTo: "/taxslip/t5/list" },
        { text: "T5 Summary", navigateTo: "/taxslip/t5/summary" },
      ]}
      mainContents={<T5SlipSummarySetup testId="t5-summary-setup" />}
    />
  );
};
