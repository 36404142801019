import {
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  JOURNAL_CODES_SETUP_SCREEN_SLUG,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
} from "common/constants";
import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useState } from "react";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IAppState } from "store/reducers";

import { Box, Grid, useTheme } from "@mui/material";
import { BackdropCircularProgress, EnhancedSnackbar, IResponse, IServerError, RouterLinkButton } from "@websential/cosmic";

import { ReportsService } from "services/ReportsService";
import PrimeDataGrid from "./PrimeDataGrid";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IJournalCode {}

const useJournalCode = (
  params: IGetListRequest
) =>
  useQuery(
    ["IJournalCode-list", params],
    (data) => {
      return ReportsService.getGenerralClient(params);
    },
    { refetchOnWindowFocus: false }
  );


export const JournalCodeSetup: React.FC<IJournalCode> = () => {
  const dispatch = useDispatch();
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [journalCodesLists, setJournalCodesLists] = useState<any>(undefined);
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };
  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "role_name",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: "name1",
      order_direction: "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
    };
    // if (requestParams.sort) {
    //   const [sortBy, sortDirection] = requestParams.sort.split(",");
    //   dispatchParams.order_by = toSnake(sortBy);
    //   dispatchParams.order_direction = sortDirection;
    // }

    setQueryParams(dispatchParams);
  }, []);

  
  const { isLoading, data } = useJournalCode(queryParams);
  const JournalCodeList: any = data;
  console.log("@@@@@ => IJournalCode", JournalCodeList);
  
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
  useEffect(()=>{
    if(isLoading){
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    } else {
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    }
  },[isLoading])

  useEffect(()=>{
    if(JournalCodeList){
      setJournalCodesLists(JournalCodeList)
    }
  },[JournalCodeList])

  const { isLoading: isSubmitting, mutate: submitJournalCodes } = useMutation(
    ReportsService.updateJournalCode,
    {
      onSuccess: (res: IServerError | IResponse | any) => {
        console.log("resonce for submitJournalCodes", res);
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
          const JournalCodeList = async () => {
            try {
              setIsLoadingList(true);
              setTimeout(()=>{
                setIsLoadingList(false);
              },1000)
              const response = await ReportsService.getGenerralClient(queryParams);
              setJournalCodesLists(response)
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          JournalCodeList();
      },
      onError: (err: any) => {
        console.log("resonce for submitJournalCodes err", err);
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
      },
    }
  )

  const onRowEditClicked = useCallback((params) => {
    console.log("params",params)
  }, []);

  const onSubmitButtonClicked = useCallback((params) => {
    let JournalCodesEditPayload = {
      "updatedModel": params
    }
    if(params.length){
      submitJournalCodes(JournalCodesEditPayload)
      console.log("JournalCodesEditPayload",JournalCodesEditPayload)
    } else {
      setSnackbarData({
        open: true,
        message: "Please Select at least one item to update code",
        severity: "error",
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: JOURNAL_CODES_SETUP_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ width: "100%", overflow: "hidden" }}
    >
      <BackdropCircularProgress 
        open={
          isSubmitting || isLoadingList
          } testId="JournalCodeSetup" />
      <PrimeDataGrid
        rows={journalCodesLists && journalCodesLists?.items}
        columns={journalCodesLists ? journalCodesLists?.headerColumns : []}
        showIndexColumn={true}
        showChecboxColumn={true}
        showSettingColumn={false}
        onRowEditClicked={onRowEditClicked}
        onSubmitButtonClicked={onSubmitButtonClicked}
        totalRecords={journalCodesLists && journalCodesLists.total ? journalCodesLists.total : 0}
        initialPageSize={INITIAL_PAGE_SIZE}
        onRequestParamsChange={onRequestParamsChange}
        // onRowDeleteClicked={onRowDeleteClicked}
        isLoading={isLoading || isAxiosCallLoading}
      />
       <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`JournalCodeSetup-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
