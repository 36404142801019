import { AxiosResponse } from "axios";
import http from "common/http-common";
import { IDropDownListResponse } from "models";
import {
  IBillingProfileListModel,
  IBillingProfileModel,
} from "models/billingProfile";
import {
  IDataModelResponsePayload,
  IGetListRequest,
  IResponse,
  IServerError,
} from "models/common";

const API_BASE_RESOURCE_URL = "/v1/billing-profiles/";

export const BillingProfileService = {
  getList: async (
    params: IGetListRequest
  ): Promise<IBillingProfileListModel | IServerError | IResponse> => {
    // const fixedResponse: any = {
    //   "page": 1,
    //   "size": 50,
    //   "total": 1,
    //   "items": [
    //     {
    //       "profileLabel": "invoice",
    //       "effectiveDate": "2019-06-01",
    //       "endDate": "2023-06-01",
    //       "isGlobalProfile": true,
    //       "baseCharges": 1,
    //       "discountPercentage": 2,
    //       "chargesType": "flat slab charges",
    //       "discountAmount": 2,
    //       "slip": {
    //         "t4": {
    //           "flatSlipCharges": null,
    //           "flatSummaryCharges": 25,
    //           "slabCharges": [
    //             {
    //               "slab": 1,
    //               "charges": 10
    //             },
    //             {
    //               "slab": 2,
    //               "charges": 7
    //             },
    //             {
    //               "slab": 12,
    //               "charges": 5
    //             }
    //           ]
    //         },
    //         "t4a": {
    //           "flatSlipCharges": null,
    //           "flatSummaryCharges": 25,
    //           "slabCharges": [
    //             {
    //               "slab": 1,
    //               "charges": 10
    //             },
    //             {
    //               "slab": 4,
    //               "charges": 7
    //             },
    //             {
    //               "slab": 8,
    //               "charges": 5
    //             }
    //           ]
    //         },
    //         "rl1": {
    //           "flatSlipCharges": null,
    //           "flatSummaryCharges": 25,
    //           "slabCharges": [
    //             {
    //               "slab": 1,
    //               "charges": 10
    //             },
    //             {
    //               "slab": 2,
    //               "charges": 4
    //             },
    //             {
    //               "slab": 5,
    //               "charges": 5
    //             }
    //           ]
    //         }
    //       },
    //       "clients": [
    //         "d3c5e4d2-1260-427f-a89b-4055a35f9571",
    //         "c6ae3c19-a8c7-4983-b23e-7457f2897632"
    //       ],
    //       "id": "6478a4173e528aabe2f47a59"
    //     }
    //   ],
    //   "keyField": {
    //     "field": "id",
    //     "templateUrl": "/id"
    //   },
    //   "headerColumns": [
    //     {
    //       "field": "profileLabel",
    //       "headerName": "Profile Label",
    //       "description": "Profile Label of the Billing Profile",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 0,
    //       "frozenColumn": false,
    //       "footer": null
    //     },
    //     {
    //       "field": "effectiveDate",
    //       "headerName": "Effective Date",
    //       "description": "Effective Date of the Billing Profile",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 1,
    //       "frozenColumn": false,
    //       "footer": null
    //     },
    //     {
    //       "field": "endDate",
    //       "headerName": "End Date ",
    //       "description": "End Date of the Billing Profile",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 2,
    //       "frozenColumn": false,
    //       "footer": null
    //     },
    //     {
    //       "field": "isGlobalProfile",
    //       "headerName": "Global Profile",
    //       "description": "Global Profile of the Billing Profile",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 3,
    //       "frozenColumn": false,
    //       "footer": null
    //     },
    //     {
    //       "field": "baseCharges",
    //       "headerName": "Base Charges",
    //       "description": "Base Charges of the Billing Profile",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 4,
    //       "frozenColumn": false,
    //       "footer": null
    //     },
    //     {
    //       "field": "discountPercentage",
    //       "headerName": "Discount %",
    //       "description": "Discount Percentage of the Billing Profile",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 5,
    //       "frozenColumn": false,
    //       "footer": null
    //     },
    //     {
    //       "field": "chargesType",
    //       "headerName": "Charges Type",
    //       "description": "Charges Type of the Billing Profile",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 6,
    //       "frozenColumn": false,
    //       "footer": null
    //     },
    //     {
    //       "field": "discountAmount",
    //       "headerName": "Discount $",
    //       "description": "Discount Amount of the Billing Profile",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       "isFixed": true,
    //       "sequence": 7,
    //       "frozenColumn": false,
    //       "footer": null
    //     }
    //   ]
    // }

    // return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    const response: AxiosResponse<IBillingProfileListModel> =
      await http.get<IBillingProfileListModel>(
        `/v1/billing-profiles/?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`
      );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  delBillingProfile: async (
    billingProfileId: number
  ): Promise<string | IServerError | IResponse> => {
    console.log("deleteById", billingProfileId);
    const response: AxiosResponse<string> = await http.delete<string>(
      `/v1/billing-profiles/${billingProfileId}/`
    );

    if (response.status === 200) {
      return "Billing Profile has been deleted successfully";
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  create: async (
    requestPayload: IBillingProfileModel
  ): Promise<IDataModelResponsePayload<IBillingProfileModel>> => {
    console.log("===-", requestPayload);
    if (requestPayload.id) {
      const response: AxiosResponse<IBillingProfileModel> =
        await http.put<IBillingProfileModel>(
          `${API_BASE_RESOURCE_URL}${requestPayload.id}/`,
          requestPayload
        );

      if (response.status === 200)
        return {
          success: true,
          message: "Record has been updated successfully",
          data: response.data,
        };
      if (response.status === 400)
        return { success: false, data: response.data };

      return {
        success: false,
        message: "Something went wrong please try again later.",
      };
    }

    const response: AxiosResponse<IBillingProfileModel> =
      await http.post<IBillingProfileModel>(
        API_BASE_RESOURCE_URL,
        requestPayload
      );

    if (response.status === 201)
      return {
        success: true,
        message: "Record has been added successfully",
        data: response.data,
      };

    return { success: false, data: response.data };
  },
  // getById: async (
  //   id: string | undefined
  // ): Promise<IDataModelResponsePayload<IBillingProfileModel> | null> => {
  //   console.log("===-", id);
  //   if (!id) return null;
  //   const response: AxiosResponse<IBillingProfileModel> =
  //     await http.get<IBillingProfileModel>(`${API_BASE_RESOURCE_URL}${id}/`);

  //   if (response.status === 200) return { success: true, data: response.data };

  //   return { success: false, data: response.data };
  // },
  getDropDownList: async (): Promise<
    IDropDownListResponse[] | IServerError | IResponse
  > => {
    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<
      IDropDownListResponse[]
    >("/v1/billing-profiles/ddl-list");

    if (response.status === 200) {
      return response.data;
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getById: async (id: string | undefined): Promise<any | IServerError | IResponse | null> => {
    console.log('id from get by id service : ', id);
  if (id) {
    const response: AxiosResponse<IBillingProfileModel> = await http.get<IBillingProfileModel>(
      `/v1/billing-profiles/${id}/`);
      console.log("id in getbyis in service: ",id)

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  }
  return null;
},
};
