import {
  FLEX_FIELDS_LIST_SCREEN_SLUG,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
} from "common/constants";
import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { IResponse, IServerError } from "models/common";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FlexFieldService } from "services";
import { IAppState } from "store/reducers";

import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import {
  DeleteConfirmationDialog,
  EmptyList,
  EnhancedSnackbar,
  RouterLinkButton,
} from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IFlexFieldList {}

const LIST_FIELDS_QUERY_KEY = "flex-field-list";

const useFlexFields = (params: IGetListRequest) =>
  useQuery(
    [LIST_FIELDS_QUERY_KEY, params],
    (data) => {
      return FlexFieldService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

export const FlexFieldList: React.FC<IFlexFieldList> = () => {
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "role_name",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useFlexFields(queryParams); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const flexFieldsList: any = data;
  console.log("@@@@@ => flex fields list", flexFieldsList);

  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  const [openDialog, setDialogOpen] = useState(false);
  const [flexFieldId, setFlexFieldId] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [emptyList, setEmptyList] = useState(false);

// Bulk Delete
const [bulkDelete, setBulkDelete] = useState<boolean>(false);
const [bulkFlexFieldId, setBulkFlexFieldId] = useState<any>({});
const [bulkFlexFieldDelete, setBulkFlexFieldDelete] = useState<boolean>(false);


  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.debouncedSearchQuery,
      order_by: "role_name",
      order_direction: "asc",
      page: requestParams.currentPage + 1,
      size: requestParams.pageSize,
    };
    if (requestParams.sort) {
      const [sortBy, sortDirection] = requestParams.sort.split(",");
      dispatchParams.order_by = toSnake(sortBy);
      dispatchParams.order_direction = sortDirection;
    }

    setQueryParams(dispatchParams);
  }, []);

  const onRowEditClicked = (params: any) => {
    let path = `/flex-fields/setup/${params.row.id}`;
    navigate(path);
  };

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const deleteFlexField = () => {
    if (singleDelete) {
      setSingleDelete(false);
      deleteFlexFieldById(flexFieldId);
      setDialogOpen(false);
    }
  };

  const onRowDeleteClicked = useCallback((params) => {
    setDialogOpen(true);
    setSingleDelete(true);
    setFlexFieldId(params.row.id);
  }, []);

  //bulk delete

  const { isLoading: isBulkDeleting, mutate: deleteBulkFlexFields } =
    useMutation(FlexFieldService.deleteBulkFlexFields, {
      onSuccess: (res: any | string | IServerError | IResponse) => {
        if (typeof res === "object") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res.message ? res.message : "Something went wrong.",
            severity: res.status === false ? "error" : "success",
          });

          setBulkFlexFieldDelete(false)
          return queryClient.invalidateQueries(LIST_FIELDS_QUERY_KEY);
        } else {
          setSnackbarData({
            open: true,
            message: res.message ? res.message : "Something went wrong, try again later!",
            severity: res.status === false ? "error" : "success",
          });
        }
        setBulkFlexFieldDelete(false)
      },
      onError: (err:any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
        setBulkFlexFieldDelete(false)
      },
    });

  // For Bulk Delete
  const deleteBulkFlexField = () => {
    if (bulkDelete) {
      setBulkDelete(false);
      deleteBulkFlexFields(bulkFlexFieldId);
      setDialogOpen(false);
    }
  };

  const onRowMultipleDeleteClicked = (params: any) => {
    console.log("Params in onRowMultipleDeleteClicked ====== ", params);
    // let bulkDeletePayload = {
    //   FlexFieldIds:{"FlexFieldIds":params}
    // }
    setDialogOpen(true);
    setBulkDelete(true);
    // setApiAction("delete");
    setBulkFlexFieldId(params);
    setBulkFlexFieldDelete(true);
  };

  const selectionToolbar = useCallback((selectedItems) => {
    if (selectedItems.length) {
      return (
        <Box sx={{ marginTop: theme.spacing(1) }}>
          <Button
            onClick={() => onRowMultipleDeleteClicked(selectedItems)}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </Box>
      );
    }
    return (
      <Box sx={{ marginTop: theme.spacing(1), width: "100%", display: "flex" }}>
        <div style={{ flexGrow: 1 }} />
        <div>
          <RouterLinkButton to="/flex-fields/setup" text="Add Flex Field" />
        </div>
      </Box>
    );
  }, []);

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const { isLoading: isDeleting, mutate: deleteFlexFieldById } = useMutation(
    FlexFieldService.deleteFlexFieldById,
    {
      onSuccess: (res: string | IServerError | IResponse) => {
        if (typeof res === "string") {
          // remove the cache of the flex fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: "Record has been deleted successfully.",
            severity: "success",
          });

          return queryClient.invalidateQueries(LIST_FIELDS_QUERY_KEY);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  // if (isLoading) {
  //   return (
  //     <CircularProgress
  //       size={24}
  //       data-testid="circular-progress"
  //       className={classes.loader}
  //     />
  //   );
  // }

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: FLEX_FIELDS_LIST_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=delete",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  const image =
  "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";
  
  return flexFieldsList && flexFieldsList.items.length === 0 ? (
    <EmptyList 
    showImage={true}
    imagePath= {image}
    heading={`There's nothing! Add Flex Field to proceed`} 
    content={`You can add flex field by directly clicking on add flex field button.`} 
    testId={"Flex-Field-empty-list"} 
    showAddButton= {true}
    addButtonLabel={"Add Flex Field"}
    navigateToAdd={"/flex-fields/setup"}
    showImportButton={false}
    showImportXmlButton={false}
    />
  ) : (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
        <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={bulkFlexFieldDelete === true ? deleteBulkFlexField : deleteFlexField}
          // deleteActionHandler={deleteFlexField}
          cancelActionHandler={onCloseHandler}
          testId="flex-field-confirmation-dialog"
        />
        <EnhancedDataGrid
          columns={flexFieldsList ? flexFieldsList.headerColumns : []}
          rows={flexFieldsList?.items}
          selectionEnabled={true}
          autoHeight={true}
          allowSearching={false}
          selectionToolbar={selectionToolbar}
          onRowEditClicked={onRowEditClicked}
          onRowDeleteClicked={onRowDeleteClicked}
          disableColumnMenu={true}
          showRowActionsColumn={true}
          autoPageSize={false}
          showIndexColumn={true}
          testId="flex-field-list-data-grid"
          sortingMode="server"
          filterMode="server"
          paginationMode="server"
          onRequestParamsChange={onRequestParamsChange}
          totalRowsCount={flexFieldsList?.total}
          isLoading={isLoading || isDeleting}
          rowIdGetter={(row) => row.id}
          initialPageSize={INITIAL_PAGE_SIZE}
          rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
          searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
          shouldMask={false}
        />
      </Grid>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"flex-field-list-snackbar"}
        open={snackbarData.open}
      />
    </>
  );
};
