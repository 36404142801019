import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import { FC } from "react";
import { RL1SlipSummaryDataInput } from "../../../RL1Slip/RL1SlipSummaryDataInput";
export interface IRL3SlipDetailPanel {
  formMethods: any;
  globalVariablesData: any;
}

export const RL3SlipDetailPanel: FC<IRL3SlipDetailPanel> = ({
  formMethods,
  globalVariablesData,
}) => {
  const handleInputChange = (e: any) => {
    const newValue = e.target.value;
    console.log("newValue", newValue);
  };
  const onReleveChangeHandler = (e: any) => {
    const value = e.target.value;
    const totalOfBoxes = parseFloat(formMethods.getValues("totalOfBoxes").replace(/[$,]/g, ''));
    console.log("totalOfBoxes", totalOfBoxes);
    if (value && totalOfBoxes) {
      // let totalValue = parseInt(value) + parseInt(totalOfBoxes);
      let totalValue = parseInt(value) + totalOfBoxes;
      formMethods.setValue("totalInvestmentIncome", totalValue);
    }
  };
  const onTotalAmountChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value && globalVariablesData) {
      let quebecIncomeTaxCalculatedValue =
        (value * globalVariablesData?.data?.rl3?.quebecIncomeTaxWithholding) /
        100;
      formMethods.setValue("quebecIncomeTax", quebecIncomeTaxCalculatedValue);
    }
  };
  return (
    <>
      <TaxSlipSummaryDataInput
        id="totalNumberOfRL3SlipsFiled"
        label="Total number of RL-3 slips filed"
        boxNumber="31"
        textfield={true}
      />
      <RL1SlipSummaryDataInput
        id="rl3FiledOnPaper"
        label="RL-3 Filed on Paper"
        readOnly={false}
        required={true}
        textfield={true}
      />
      <RL1SlipSummaryDataInput
        id="rl3FiledOnline"
        label="RL-3 Filed Online"
        readOnly={false}
        required={true}
        textfield={true}
      />
      <TaxSlipSummaryDataInput
        id="actualAmountOfEligibleDividends"
        label="Actual amount of eligible dividends"
        boxNumber="A1"
      />
      <TaxSlipSummaryDataInput
        id="actualAmountOfOrdinaryDividends"
        label="Actual amount of ordinary dividends"
        boxNumber="A2"
      />
      <TaxSlipSummaryDataInput
        id="taxableAmountOfDividends"
        label="Taxable amount of dividends"
        boxNumber="B"
      />
      <TaxSlipSummaryDataInput
        id="dividendTaxCredit"
        label="Dividend tax credit"
        boxNumber="C"
      />
      <TaxSlipSummaryDataInput
        id="canadaOrQuebecSavingsBondsInterest"
        label="Canada or quebec savings bonds interest"
        boxNumber=""
      />
      <TaxSlipSummaryDataInput
        id="otherInterestFromCanadianSources"
        label="Other interest from canadian source"
        boxNumber=""
      />
      <TaxSlipSummaryDataInput
        id="totalInterestFromCanadianSources"
        label="Total interest from canadian source"
        boxNumber="D"
      />
      <TaxSlipSummaryDataInput
        id="otherIncomeFromCanadianSources"
        label="Other income from canadian source"
        boxNumber="E"
      />
      <TaxSlipSummaryDataInput
        id="grossForeignIncome"
        label="Gross foreign income"
        boxNumber="F"
      />
      <TaxSlipSummaryDataInput
        id="foreignIncomeTax"
        label="Foreign income tax"
        boxNumber="G"
      />
      <TaxSlipSummaryDataInput
        id="royaltiesFromCanadianSources"
        label="Royalities from canadian source"
        boxNumber="H"
      />
      <TaxSlipSummaryDataInput
        id="capitalGainsDividends"
        label="Capital gains dividends"
        boxNumber="I"
      />
      <TaxSlipSummaryDataInput
        id="accruedIncomeAnnuities"
        label="Accrued income: Annuities"
        boxNumber="J"
      />
      <TaxSlipSummaryDataInput
        id="interestFromLinkedNotes"
        label="Interest from linked notes"
        boxNumber="K"
      />
      <TaxSlipSummaryDataInput
        id="totalOfBoxes"
        label="Total of boxes A1, A2, D, E, H, I and J"
        boxNumber="1"
      />
      <TaxSlipSummaryDataInput
        id="amountForWhichReleveNotField"
        label="Amount for which relevé 3's not filed"
        boxNumber="2"
        readOnly={false}
        onChange={onReleveChangeHandler}
      />
      <TaxSlipSummaryDataInput
        id="totalInvestmentIncome"
        label="Total investment income (lines 1+2)"
        boxNumber="3"
      />
      <TaxSlipSummaryDataInput
        id="totalAmountOfIncomeOfUnknownUsers"
        label="Total amount of income of unknown users"
        boxNumber=""
        readOnly={false}
        onChange={onTotalAmountChangeHandler}
      />
      <TaxSlipSummaryDataInput
        id="quebecIncomeTax"
        label="Quebec income tax with holding (15%)"
        boxNumber=""
      />
    </>
  );
};
