import {
  INITIAL_PAGE_SIZE,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
} from "common/constants";
import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useState } from "react";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IAppState } from "store/reducers";

import { Box, Grid, useTheme } from "@mui/material";
import { RouterLinkButton } from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import { ReportsService } from "services/ReportsService";
import PrimeDataGrid from "./PrimeDataGrid";
import { format } from "date-fns";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IEmployeeReport {}
const EMPLOYEE_REPORT_LIST_QUERY_KEY = "employee-report-list"

const useEmployeeReport = (
  clientId: string,
  taxYear: number,
  params: IGetListRequest) =>
  useQuery(
    // ["employee-report-list", params],
    // (data) => {
    //   return ReportsService.getEmployeeReport(clientId,params);
    // },
    // { refetchOnWindowFocus: false }
    [EMPLOYEE_REPORT_LIST_QUERY_KEY, params],
    (data) => {
      return ReportsService.getEmployeeReport(clientId,taxYear,params);
    },
    { refetchOnWindowFocus: false }
  );

export const EmployeeReport: React.FC<IEmployeeReport> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useSelector((state: IAppState) => state.client);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  console.log("id ksdbksjdbv",id)
  const [employeeReportUpdatedItems, setEmployeeReportUpdatedItems] =
    useState<any>(undefined);

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "name1",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useEmployeeReport(id,taxYear,queryParams);
  const employeeReportList: any = data;
  console.log("@@@@@ => employee-report", employeeReportList);

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
  useEffect(()=>{
    if(isLoading){
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    } else {
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    }
  },[isLoading])

  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: "name1",
      order_direction: "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
    };
    // if (requestParams.sort) {
    //   const [sortBy, sortDirection] = requestParams.sort.split(",");
    //   dispatchParams.order_by = toSnake(sortBy);
    //   dispatchParams.order_direction = sortDirection;
    // }

    setQueryParams(dispatchParams);
  }, []);

  const onRowEditClicked = useCallback((params) => {
    alert(`Edit row with ID: ${params.row.id} has been clicked!`);
    let path = `/employees/setup/${params.row.id}`;
    navigate(path);
  }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const callAction = () => {
    if (apiAction === "delete") {
      alert(`Delete row with ID: ${selectedIds} has been clicked!`);
      // TODO api call for delete
    } else if (apiAction === "updateStatus") {
      alert(`action need to trigger : ${apiAction}`);
      // TODO api call for update status
    }
    setDialogOpen(false);
  };

  const onRowDeleteClicked = useCallback((params) => {
    setDialogOpen(true);
    setApiAction("delete");
    setSelectedIds(params.row.id);
    alert(`Delete row with ID: ${params.row.id} has been clicked!`);
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusValue(event.target.value as string);
    setApiAction("updateStatus");
    setDialogOpen(true);
  };

  const selectionToolbar = useCallback((selectedItems) => {
    setSelectedIds(selectedItems);
    if (selectedItems.length) {
      return (
        <Box sx={{ marginTop: theme.spacing(1) }}>
          {"Bulk Action Drop Down List"}
          {/* <EnhancedDDLBulkAction
            label="Status"
            value={statusValue}
            onChangeHandler={handleChange}
            options={[
              { name: "Active", value: "1" },
              { name: "Inactive", value: "2" },
            ]}
            testId="status-dd"
          /> */}
        </Box>
      );
    }
    return (
      <Box sx={{ marginTop: theme.spacing(1) }}>
        <RouterLinkButton
          to="/billing-profile/setup"
          text="Add Billing Profile"
        />
      </Box>
    );
  }, []);

  // if (isLoading) {
  //   return (
  //     <CircularProgress
  //       size={24}
  //       data-testid="circular-progress"
  //       className={classes.loader}
  //     />
  //   );
  // }
  // const onKeyFieldClickHandler = (clickedRow: any) => {
  //   dispatch(setSelectedAccountant(clickedRow));
  // };

  const formatDate = (datee: any) => {
    const originalDate = new Date(datee);
    const formattedDate = format(originalDate, "MMM dd, yyyy");

    return formattedDate;
  };

  useEffect(()=>{
    if(employeeReportList){
      const updatedItemsResponse = employeeReportList.items.map((item:any) => ({
        ...item,
        dateOfBirth: (item.dateOfBirth !== "" && item.dateOfBirth !== null && item.dateOfBirth && item.dateOfBirth.charAt(3) !== "*") ? formatDate(item.dateOfBirth) : item.dateOfBirth,
      }));
      console.log("updatedItemsResponse",updatedItemsResponse)
      setEmployeeReportUpdatedItems(updatedItemsResponse)
  }
},[employeeReportList])

useEffect(()=>{
  dispatch(setSelectedActivityLogActions({
    entityId1:'',
    entityId2:'',
    entityId3:'',
    screenName:'',
    companyName:'',
    activityType:"",
    activityLogVisibility:false
  }));
  dispatch(setSelectedStickyNavBar({isSticky:false}));
},[])

  return emptyList ? (
    <EmptyAccountantList />
  ) : (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
      {/* <EnhancedDialog
        dialogTitle="Please Confirm"
        key="dialog-box"
        testId="dialog"
        onCloseHandler={onCloseHandler}
        open={openDialog}
        dialogContent={
          <Typography variant="subtitle1">Are you sure?</Typography>
        }
        dialogAction={
          <Grid>
            <Button
              autoFocus
              variant="contained"
              onClick={onCloseHandler}
              color="default"
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={callAction}
              color="primary"
              className={classes.button}
            >
              ok
            </Button>
          </Grid>
        }
      /> */}
      {/* <EnhancedDataGrid
        columns={employeeReportList ? employeeReportList.headerColumns : []}
        rows={employeeReportList?.items}
        selectionEnabled={false}
        autoHeight={false}
        allowSearching={true}
        selectionToolbar={selectionToolbar}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        // initialPage={0}
        disableColumnMenu={true}
        showRowActionsColumn={false}
        autoPageSize={false} //
        showIndexColumn={true}
        testId="data-role-list-grid"
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onRequestParamsChange={onRequestParamsChange}
        totalRowsCount={employeeReportList?.total}
        isLoading={isLoading}
        rowIdGetter={(row) => row.id}
        initialPageSize={INITIAL_PAGE_SIZE}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
        shouldMask={false}
      /> */}
      <PrimeDataGrid
        rows={employeeReportList && employeeReportUpdatedItems && employeeReportUpdatedItems}
        columns={employeeReportList ? employeeReportList.headerColumns : []}
        isLoading={isLoading || isAxiosCallLoading}
        totalRecords={employeeReportList && employeeReportList.total ? employeeReportList.total : 0}
        initialPageSize={INITIAL_PAGE_SIZE}
        onRequestParamsChange={onRequestParamsChange}
        // showIndexColumn={true}
        // showChecboxColumn={true}
        // showSettingColumn={true}
        // onRowEditClicked={onRowEditClicked}
        // onRowDeleteClicked={onRowDeleteClicked}
        // keyField={employeeReportList ? employeeReportList.keyField : undefined}
        // onKeyFieldClickHandler={onKeyFieldClickHandler}
      />
    </Grid>
  );
};
