import { IGetListRequest, IResponse, IServerError } from "models/common";
import { ITaxSlipCategoryListModel } from "models/taxSlipCategory";
import {
  ITaxSlipCategoryRequestPayload,
  ITaxSlipCategoryResponsePayload,
} from "models";
import { AxiosResponse } from "axios";
import http from "common/http-common";

// TODO: replace this with API response once it's ready
export const TaxSlipCategoryService = {
  create: async (
    requestPayload: ITaxSlipCategoryRequestPayload
  ): Promise<ITaxSlipCategoryResponsePayload | IServerError | IResponse> => {
    if (requestPayload.id) {
      console.log('requestPayload ==>> update request', requestPayload);
      const response: AxiosResponse<ITaxSlipCategoryResponsePayload> = await http.put<ITaxSlipCategoryResponsePayload>(
        `/v1/taxslip-categories/${requestPayload.id}`, requestPayload);

      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }

    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<ITaxSlipCategoryResponsePayload> =
      await http.post<ITaxSlipCategoryResponsePayload>(
        "/v1/taxslip-categories/",
        requestPayload
      );

    if (response.status === 201) {
      console.log("response 300 create",response.data)
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  delCategoryList: async (
    categoryListId: number
  ): Promise<string | IServerError | IResponse> => {
    console.log("deleteById", categoryListId);
    const response: AxiosResponse<string> = await http.delete<string>(
      `/v1/taxslip-categories/${categoryListId}/`
    );

    if (response.status === 204) {
      return "Category List has been deleted successfully";
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getList: async (
    params: IGetListRequest
  ): Promise<ITaxSlipCategoryListModel | IServerError | IResponse> => {
    //  const fixedResponse: ITaxSlipCategoryListModel = {
    //    "page": 1,
    //    "size": 5,
    //    "total": 2,
    //    "items": [
    //      {
    //        "id": "ab8ccece-16c1-44e9-ba86-2990eefb178c",
    //        "name": "Client Profile",
    //        "slug": "client-profile"
    //      },
    //      {
    //        "id": "4e3823aa-c2e4-45aa-aaaf-9ba1b1486acc",
    //        "name": "Sales Tax - CRA",
    //        "slug": "sales-tax-cra"
    //      },
    //      {
    //        "id": "4e3823aa-c2e4-45aa-aaaf-9ba1b1486acd",
    //        "name": "T4/RL-1",
    //        "slug": "t4-rl-1"
    //      }
    //    ],
    //    "keyField": {
    //      "field": "id",
    //      "templateUrl": "/taxslip-categories/setup/id"
    //    },
    //    "headerColumns": [
    //      {
    //        "field": "name",
    //        "headerName": "Name",
    //        "description": "name",
    //        "flex": 1,
    //        "hide": false,
    //        "hideable": false,
    //        "sortable": true,
    //        // "sortingOrder": "asc",
    //        "resizable": false,
    //        "type": "string",
    //        "align": "left",
    //        "headerAlign": "left",
    //        "hideSortIcons": false,
    //        "disableColumnMenu": true,
    //        "disableReorder": true,
    //        "disableExport": true,
    //        "maskable": false,
    //        // "isFixed": true,
    //        "sequence": 0
    //      },
    //      {
    //        "field": "slug",
    //        "headerName": "Slug",
    //        "description": "Slug",
    //        "flex": 1,
    //        "hide": false,
    //        "hideable": false,
    //        "sortable": true,
    //        // "sortingOrder": "asc",
    //        "resizable": false,
    //        "type": "string",
    //        "align": "left",
    //        "headerAlign": "left",
    //        "hideSortIcons": false,
    //        "disableColumnMenu": true,
    //        "disableReorder": true,
    //        "disableExport": true,
    //        "maskable": false,
    //        // "isFixed": true,
    //        "sequence": 1
    //      }
    //    ]
    //  }
    //  return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));

    const response: AxiosResponse<ITaxSlipCategoryListModel> =
      await http.get<ITaxSlipCategoryListModel>(
        `/v1/taxslip-categories/?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`
      );
    if (response.status === 200) {
      console.log("response '''''''", response.data);
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getById: async (id: string | undefined): Promise<ITaxSlipCategoryListModel | IServerError | IResponse | null> => {
    if (id) {
      const response: AxiosResponse<ITaxSlipCategoryListModel> = await http.get<ITaxSlipCategoryListModel>(
        `/v1/taxslip-categories/${id}/`);
  
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }

    return null;
  },
};
