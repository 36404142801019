import { IGetListRequest } from "models";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  BackdropCircularProgress,
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  IResponse,
  IServerError,
  LabelPosition,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
} from "@websential/cosmic";
import { FormProvider, useForm } from "react-hook-form";
import {
  INITIAL_SNACKBAR_DATA,
  UPDATE_INVOICE_SCREEN_SLUG,
} from "common/constants";
import { UpdateInvoiceDetailsByRefNo } from "./UpdateInvoiceDetailsByRefNo";
import UpdateInvoideDataTable from "./UpdateInvoideDataTable";
import { Grid } from "@mui/material";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { clearSelectedINVOICE, setSelectedINVOICE } from "store/actions";
import { ReportsService } from "services/ReportsService";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Calendar } from "primereact/calendar";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
export interface IUpdateInvoice {}

const useUpdateInvoices = (refNo: string) =>
  useQuery(
    ["update-invoice", refNo],
    (data) => {
      return ReportsService.getUpdateInvoice(refNo);
    },
    { refetchOnWindowFocus: false }
  );

const useUpdateInvoicesDDL = () =>
  useQuery(
    ["update-invoice-ddl"],
    (data) => {
      return ReportsService.getUpdateInvoiceDDL();
    },
    { refetchOnWindowFocus: false }
  );

export const UpdateInvoice: React.FC<IUpdateInvoice> = () => {
  const dispatch = useDispatch();
  const refNo = useSelector((state: IAppState) => state.invoice.refNo);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [paymentDate, setPaymentDate] = useState<any>(undefined);
  const [paymentDatePlaceHolder, setPaymentDatePlaceHolder] =
    useState<any>(undefined);
  const [refundDate, setRefundDate] = useState<any>(undefined);
  const [responseData, setResponseData] = useState<any>(undefined);
  const [refundDatePlaceHolder, setRefundDatePlaceHolder] =
    useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [paidAmountValue, setPaidAmountValue] = useState(0);
  const [refundAmountValue, setRefundAmountValue] = useState(0);

  const { isLoading: isLoadingInvoiceDetailDDL, data: invoiceRefNoDDL } =
    useUpdateInvoicesDDL();

  const { isLoading: isLoadingInvoiceDetail, data: invoiceDetailByRefNo } =
    useUpdateInvoices(refNo);

  useEffect(() => {
    if (invoiceDetailByRefNo && invoiceDetailByRefNo.status === 200) {
      setResponseData(invoiceDetailByRefNo.data);
    }
  }, [invoiceDetailByRefNo]);

  let DEFAULT_VALUES = {};
  const T4SlipSetupValidationSchema = yup.object({});

  const formMethods = useForm<any>({
    resolver: yupResolver(T4SlipSetupValidationSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });

  const { isLoading: isSubmitting, mutate: submitInvoice } = useMutation(
    ReportsService.updateInvoice,
    {
      onSuccess: (res: IServerError | IResponse | any) => {
        console.log("resonce [[[[[[res", res);
        if (res.message) {
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "error",
          });
        } else {
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
        }
      },
      onError: (err: any) => {
        console.log("resonce [[[[]]]]err", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          // message: "Something went wrong, try again later!",
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  const onSubmit = (formData: any) => {
    // console.log("formData",formData)
    const requestPayload = {
      refNo: refNo,
      invoiceNo: formData.invoiceNo && formData.invoiceNo,
      paymentStatus: formData.status,
      paidAmount: paidAmountValue,
      paymentDate:
        paymentDate && paymentDate.charAt(2) === "*"
          ? undefined
          : paymentDate !== "" || null
          ? paymentDate
          : undefined,
      refundAmount: refundAmountValue,
      refundDate:
        refundDate && refundDate.charAt(2) === "*"
          ? undefined
          : refundDate !== "" || null
          ? refundDate
          : undefined,
      notes: formData.notes !== "" ? formData.notes : undefined,
    };
    console.log("requestPayload", requestPayload);
    submitInvoice(requestPayload);
  };

  const onResetClicked = () => {
    dispatch(clearSelectedINVOICE());
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset();
    window.location.reload();
  };

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const refNoDDL = [
    {
      label: "UPI00001",
      value: "UPI00001",
    },
    {
      label: "UPI00002",
      value: "UPI00002",
    },
  ];

  // format date to 2023-07-26
  const formatDate = (date: Date): string => {
    console.log("dateee", date);
    return format(date, "yyyy-MM-dd"); // Format the date as "yyyy-MM-dd"
  };

  const onPaymentDateChangeHandler = (selectedDate: any) => {
    setPaymentDate(formatDate(selectedDate.value as Date));
  };

  const onRefundDateChangeHandler = (selectedDate: any) => {
    setRefundDate(formatDate(selectedDate.value as Date));
  };

  const onRefNoChangeHandler = (ref: any) => {
    const refNumber = { refNo: ref.value };
    dispatch(setSelectedINVOICE(refNumber));
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (invoiceDetailByRefNo && invoiceDetailByRefNo.status === 200) {
      formMethods.setValue(
        "invoiceNo",
        invoiceDetailByRefNo && invoiceDetailByRefNo.data.invoiceNo
      );
      formMethods.setValue(
        "refNo",
        invoiceDetailByRefNo && invoiceDetailByRefNo.data.refNo
      );
      formMethods.setValue(
        "status",
        invoiceDetailByRefNo && invoiceDetailByRefNo.data.paymentStatus
      );
      formMethods.setValue(
        "paidAmount",
        invoiceDetailByRefNo && invoiceDetailByRefNo.data.paidAmount
      );
      formMethods.setValue(
        "refundAmount",
        invoiceDetailByRefNo && invoiceDetailByRefNo.data.refundAmount
      );
      formMethods.setValue(
        "notes",
        invoiceDetailByRefNo && invoiceDetailByRefNo.data.notes
      );
      setRefundAmountValue(
        invoiceDetailByRefNo && invoiceDetailByRefNo.data.refundAmount
      );
      setPaidAmountValue(
        invoiceDetailByRefNo && invoiceDetailByRefNo.data.paidAmount
      );
      //paymentDate
      if (
        (invoiceDetailByRefNo &&
          invoiceDetailByRefNo.data.paymentDate !== null) ||
        undefined
      ) {
        if (
          invoiceDetailByRefNo &&
          invoiceDetailByRefNo.data.paymentDate.charAt(2) === "*"
        ) {
          formMethods.setValue(
            "paymentDate",
            invoiceDetailByRefNo && invoiceDetailByRefNo.data.paymentDate
          );
          setPaymentDate(
            invoiceDetailByRefNo && invoiceDetailByRefNo.data.paymentDate
          );
        } else {
          const defaultDate = new Date(
            invoiceDetailByRefNo && invoiceDetailByRefNo.data.paymentDate
          );
          setPaymentDate(formatDate(defaultDate as Date));
          formMethods.setValue("paymentDate", defaultDate);
        }
        setPaymentDatePlaceHolder(
          invoiceDetailByRefNo && invoiceDetailByRefNo.data.paymentDate
        );
      } else {
        formMethods.setValue("paymentDate", null);
      }
      //refundDate
      if (
        (invoiceDetailByRefNo &&
          invoiceDetailByRefNo.data.refundDate !== null) ||
        undefined
      ) {
        if (
          invoiceDetailByRefNo &&
          invoiceDetailByRefNo.data.refundDate.charAt(2) === "*"
        ) {
          formMethods.setValue(
            "refundDate",
            invoiceDetailByRefNo && invoiceDetailByRefNo.data.refundDate
          );
          setRefundDate(
            invoiceDetailByRefNo && invoiceDetailByRefNo.data.refundDate
          );
        } else {
          const defaultDate = new Date(
            invoiceDetailByRefNo && invoiceDetailByRefNo.data.refundDate
          );
          setRefundDate(formatDate(defaultDate as Date));
          formMethods.setValue("refundDate", defaultDate);
        }
        setRefundDatePlaceHolder(
          invoiceDetailByRefNo && invoiceDetailByRefNo.data.refundDate
        );
      } else {
        formMethods.setValue("refundDate", null);
      }
    } else {
      if (invoiceDetailByRefNo) {
        setSnackbarData({
          open: true,
          message:
            invoiceDetailByRefNo &&
            invoiceDetailByRefNo.message !== undefined &&
            typeof (
              invoiceDetailByRefNo.message && invoiceDetailByRefNo.message
            ) !== "object"
              ? invoiceDetailByRefNo.message && invoiceDetailByRefNo.message
              : invoiceDetailByRefNo.message.message
              ? invoiceDetailByRefNo.message.message
              : "Something went wrong!",
          severity:
            invoiceDetailByRefNo.status === false
              ? "error"
              : invoiceDetailByRefNo.success === false
              ? "error"
              : "success",
        });
      }
    }
  }, [invoiceDetailByRefNo, formMethods.setValue, formMethods]);

  const onPaymentStatusChangeHandler = (event: any) => {
    console.log("evenettt", event.value);
    if (event.value === true) {
      formMethods.setValue("paymentDate", new Date());
      setPaymentDate(formatDate(new Date() as Date));
    } else {
      formMethods.setValue("paymentDate", undefined);
      setPaymentDate(undefined);
    }
  };

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: UPDATE_INVOICE_SCREEN_SLUG,
        companyName: "",
        activityType: "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({ isSticky: true }));
  }, []);

  console.log("refNo in update invoice", refNo);
  return (
    <Grid
      sx={{
        paddingBottom: "5rem",
        "& .MuiAppBar-root": { zIndex: 2, top: "65px" },
      }}
    >
      <BackdropCircularProgress
        open={
          isLoadingInvoiceDetail ||
          isLoading ||
          isSubmitting ||
          isLoadingInvoiceDetailDDL
        }
        testId={`UpdateInvoice-backdrop`}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            children={
              <>
                <StandardPanel
                  title="Update Invoice"
                  testId={`UpdateInvoice`}
                  leftSpacing={2}
                  rightSpacing={2}
                >
                  <EnhancedDropDownRHFWithLabel
                    selectName="refNo"
                    label="Load Invoice Ref. No."
                    labelPosition={LabelPosition.Left}
                    items={invoiceRefNoDDL ? invoiceRefNoDDL : []}
                    // selectedItem={selectedBillingProfile.id}
                    testId={"UpdateInvoice-refNo-list"}
                    // isLoading={isLoadingBillingProfileList}
                    onSelectionChangeHandler={onRefNoChangeHandler}
                  />
                </StandardPanel>
                <StandardPanel
                  title="Update Invoice Details"
                  testId={`UpdateInvoice Details`}
                  leftSpacing={2}
                  rightSpacing={2}
                  topPadding={2}
                >
                  <UpdateInvoiceDetailsByRefNo
                    formMethods={formMethods}
                    paymentDate={paymentDate && paymentDate}
                    paymentDatePlaceHolder={
                      paymentDatePlaceHolder && paymentDatePlaceHolder
                    }
                    onPaymentDateChangeHandler={onPaymentDateChangeHandler}
                    refundDatePlaceHolder={
                      refundDatePlaceHolder && refundDatePlaceHolder
                    }
                    onRefundDateChangeHandler={onRefundDateChangeHandler}
                    companyName={responseData && responseData.companyName}
                    paidAmountValue={(value: any) => setPaidAmountValue(value)}
                    refundAmountValue={(value: any) =>
                      setRefundAmountValue(value)
                    }
                    onPaymentStatusChangeHandler={onPaymentStatusChangeHandler}
                  />
                </StandardPanel>
              </>
            }
            toolbar={
              <StandardSubmitFormButtonsToolbar
                onResetClicked={onResetClicked}
                isSubmitting={false}
                testId="standard-form-submit-toolbar"
              />
            }
            testId="form-layout"
          />
        </form>
      </FormProvider>
      {/* <UpdateInvoideDataTable
        rows={[]}
        columns={[]}
        showIndexColumn={false}
        showChecboxColumn={false}
        showSettingColumn={false}
        // isLoading={isLoading}
      /> */}
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`UpdateInvoice-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
