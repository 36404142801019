import { Box, Grid, Paper } from '@mui/material'
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { T4SlipService } from 'services/T4SlipService';
import { setSelectedAccountant, setSelectedAccountantName, setSelectedClient, setSelectedClientName } from 'store/actions';

interface IglobalSearchPanels {
    responseData:any
}

// styles
const useStyles = makeStyles(() =>
  createStyles({
    titleStyle: {
      fontSize: ".9rem",
      color: "#000",
      padding: "16px 16px 16px 26px",
      fontWeight: "bold",
    },
    line: {
      borderColor: "rgba(0,0,0,.12)",
      border: "0",
      borderTop: "1px solid rgba(0,0,0,.1)",
      margin: 0,
    },
    scrollBarOverFlow:{
        padding:"10px",
        overflow: "auto",
        fontSize:"small",
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-thumb': {
            background: "#a0a0a0",
            borderRadius: "3px",
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: "#ccc"
        }
    },
    paper:{
        height:"23rem",
        display: "flex",
        flexDirection: "column"       
    }
  })
);

export const GlobalSearchPanels: React.FC<IglobalSearchPanels> = 
({responseData}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    console.log("responseData",responseData)

    const [accountantList, setAccountantList] = useState<any>(undefined);
    const [clientList, setClinetList] = useState<any>(undefined);
    const [employeeList, setEmployeeList] = useState<any>(undefined);
    const [vendorList, setVendorList] = useState<any>(undefined);
    const [recipientList, setRecipientsList] = useState<any>(undefined);
    const [beneficiaryList, setBeneficiaryList] = useState<any>(undefined);

    useEffect(()=>{
        if(responseData){
            if(responseData.accountants.length !== 0){
                setAccountantList(responseData.accountants)
            } else {
                setAccountantList(undefined)
            }
            if(responseData.clients.length !== 0){
                setClinetList(responseData.clients)
            }else {
                setClinetList(undefined)
            }
            //resources
            const employees:any = []
            const vendors:any = []
            const recipients:any = []
            const beneficiaries:any = []
            responseData.resources.forEach((resource:any)=>{
                if(resource.roleName === 'Employee'){
                    employees.push(resource)
                } else if (resource.roleName === 'Vendor'){
                    vendors.push(resource);
                } else if (resource.roleName === 'T5_Recipient'){
                    recipients.push(resource);
                } else if (resource.roleName === 'T3_Beneficiary'){
                    beneficiaries.push(resource);
                }
            })
            setEmployeeList(employees);
            setVendorList(vendors);
            setRecipientsList(recipients);
            setBeneficiaryList(beneficiaries);
        }else {
            console.log("empty")
        }
    },[responseData])

    console.log("accountantListaccountantList",accountantList)
    console.log("clientListclientList",clientList)
    console.log("employeeList",employeeList)
    console.log("vendorList",vendorList)

    //when click on accountant id updated of accountant in redux store state.Accountant
    const onAccountantClickHandler = (item: any) => {
        dispatch(setSelectedAccountant({id:item.id}));
        dispatch(setSelectedAccountantName({accTitle:item.accTitle}));
        console.log("selectedItem",item)
    };

    //when click on Client id updated of Client in redux store state.Client
    const onClientClickHandler = (item:any) => {
        dispatch(setSelectedAccountant({id:item.accountantId}));
        // dispatch(setSelectedAccountantName({accTitle:item.accTitle}));
        dispatch(setSelectedClient({id:item.id}));
        dispatch(setSelectedClientName({clientName:item.clientName}));
        console.log("itemitemitemitem",item)
      };

    //when click on Employee id first change table id(Association id) to resource Actual id then navigate
    const onEmployeeClickHandler = async ( item:any): Promise<void> => {
        console.log("onEmployeeClickHandler",item)

        // Check if the resource ID is available in the list of accountants
        const matchingAccountant = accountantList.find((acc:any) => acc.id === item.accountantId);
        // Check if the resource ID is available in the list of Clients
        const matchingClient = clientList.find((client:any) => client.id === item.clientId);
        console.log("matchingAccountant",matchingAccountant.accTitle)
        console.log("matchingClient",matchingClient.clientName)
        
        dispatch(setSelectedAccountant({id:item.accountantId}));
        dispatch(setSelectedAccountantName({accTitle:matchingAccountant.accTitle}));
        dispatch(setSelectedClient({id:item.clientId}));
        dispatch(setSelectedClientName({clientName:matchingClient.clientName}));
        
    //get employee actual id
        try {
            const response = await T4SlipService.getResourceActualId(item.id);
            let path = `/resources/Employee/setup/${response.resourceId}`;
            navigate(path);
          } catch (err) {
            console.log("err", err);
          }
      };

    //when click on Vendor  first change table id(Association id) to resource Actual id then navigate
    const onVendorClickHandler = async (item:any): Promise<void> => {
        console.log("onVendorClickHandler",item)
        // Check if the resource ID is available in the list of accountants
        const matchingAccountant = accountantList.find((acc:any) => acc.id === item.accountantId);
        // Check if the resource ID is available in the list of Clients
        const matchingClient = clientList.find((client:any) => client.id === item.clientId);

        dispatch(setSelectedAccountant({id:item.accountantId}));
        dispatch(setSelectedAccountantName({accTitle:matchingAccountant.accTitle}));
        dispatch(setSelectedClient({id:item.clientId}));
        dispatch(setSelectedClientName({clientName:matchingClient.clientName}));

    //get Vendor actual id
        try {
            const response = await T4SlipService.getResourceActualId(item.id);
            let path = `/resources/Vendor/setup/${response.resourceId}`;
            navigate(path);
          } catch (err) {
            console.log("err", err);
          }
      };

    //when click on Recipient  first change table id(Association id) to resource Actual id then navigate
    const onRecipientClickHandler = async (item:any): Promise<void> => {
        console.log("onRecipientClickHandler",item)
        // Check if the resource ID is available in the list of accountants
        const matchingAccountant = accountantList.find((acc:any) => acc.id === item.accountantId);
        // Check if the resource ID is available in the list of Clients
        const matchingClient = clientList.find((client:any) => client.id === item.clientId);

        dispatch(setSelectedAccountant({id:item.accountantId}));
        dispatch(setSelectedAccountantName({accTitle:matchingAccountant.accTitle}));
        dispatch(setSelectedClient({id:item.clientId}));
        dispatch(setSelectedClientName({clientName:matchingClient.clientName}));

    //get Recipient actual id
        try {
            const response = await T4SlipService.getResourceActualId(item.id);
            let path = `/resources/T5_Recipient/setup/${response.resourceId}`;
            navigate(path);
          } catch (err) {
            console.log("err", err);
          }
      };
    //when click on Beneficiary  first change table id(Association id) to resource Actual id then navigate
    const onBeneficiaryClickHandler = async (item:any): Promise<void> => {
        console.log("onBeneficiaryClickHandler",item)
        // Check if the resource ID is available in the list of accountants
        const matchingAccountant = accountantList.find((acc:any) => acc.id === item.accountantId);
        // Check if the resource ID is available in the list of Clients
        const matchingClient = clientList.find((client:any) => client.id === item.clientId);

        dispatch(setSelectedAccountant({id:item.accountantId}));
        dispatch(setSelectedAccountantName({accTitle:matchingAccountant.accTitle}));
        dispatch(setSelectedClient({id:item.clientId}));
        dispatch(setSelectedClientName({clientName:matchingClient.clientName}));

    //get Beneficiary actual id
        try {
            const response = await T4SlipService.getResourceActualId(item.id);
            let path = `/resources/T3_Beneficiary/setup/${response.resourceId}`;
            navigate(path);
          } catch (err) {
            console.log("err", err);
          }
      };

  return (
    <Grid
        container
        columnSpacing={3}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{marginLeft:"-11px"}}
    >   
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2} sx={{paddingTop:"2rem"}}>
            <Paper elevation={3} className={classes.paper}> 
                 <Box className={classes.titleStyle}>Accountants</Box>
                <hr className={classes.line}/>
                <Box className={classes.scrollBarOverFlow}>
                    {responseData && accountantList !== undefined ? accountantList.map((item:any)=>{
                        return (
                            <Box sx={{paddingBottom:"5px"}}>
                                <Link 
                                    onClick={() => onAccountantClickHandler(item)}
                                    to={`/accountants/clients`} 
                                    className="no-underline" 
                                    style={{color:"#0052cc"}}
                                    >{item.accTitle}</Link>
                            </Box>
                        )
                    }):(
                        <Box sx={{color:"red",fontSize:"small"}}>No record found</Box>
                    )}
                </Box> 
            </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2} sx={{paddingTop:"2rem"}}>
            <Paper elevation={3} className={classes.paper}>
                <Box className={classes.titleStyle}>Clients</Box>
                <hr className={classes.line}/>
                <Box className={classes.scrollBarOverFlow}>
                    {responseData && clientList !== undefined ? clientList.map((item:any)=>{
                        return (
                            <Box sx={{paddingBottom:"5px"}}>
                                <Link 
                                    onClick={() => onClientClickHandler(item)}
                                    to={`/clients/dashboard`} 
                                    className="no-underline" 
                                    style={{color:"#0052cc"}}
                                    >{item.clientName}</Link>
                            </Box>
                        )
                    }):(
                        <Box sx={{color:"red",fontSize:"small"}}>No record found</Box>
                    )}
                </Box> 
            </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2} sx={{paddingTop:"2rem"}}>
            <Paper elevation={3} className={classes.paper}>
                <Box className={classes.titleStyle}>Employees</Box>
                <hr className={classes.line}/>
                <Box className={classes.scrollBarOverFlow}>
                    {responseData && employeeList !== undefined && employeeList.length !== 0 ? employeeList.map((item:any)=>{
                        return (
                            <Box sx={{paddingBottom:"5px"}}>
                                <Link 
                                    onClick={() => onEmployeeClickHandler(item)}
                                    to=""
                                    className="no-underline" 
                                    style={{color:"#0052cc"}}
                                    >{`${item.name1} ${item.name2}`}</Link>
                            </Box>
                        )
                    }):(
                        <Box sx={{color:"red",fontSize:"small"}}>No record found</Box>
                    )}
                </Box> 
            </Paper>
        </Grid>
        {/* <Grid item xs={1.71} sm={1.71} md={1.71} lg={1.71} xl={1.71} sx={{paddingTop:"2rem"}}>
            <Paper elevation={3} className={classes.paper}>
                <Box className={classes.titleStyle}>T4 Slips</Box>
                <hr className={classes.line}/>
                <Box className={classes.scrollBarOverFlow}>
                    <Box sx={{color:"red",fontSize:"small"}}>No record found</Box>
                </Box>
            </Paper>
        </Grid>
        <Grid item xs={1.71} sm={1.71} md={1.71} lg={1.71} xl={1.71} sx={{paddingTop:"2rem"}}>
            <Paper elevation={3} className={classes.paper}>
                <Box className={classes.titleStyle}>RL1 Slips</Box>
                <hr className={classes.line}/>
                <Box className={classes.scrollBarOverFlow}>
                    <Box sx={{color:"red",fontSize:"small"}}>No record found</Box>
                </Box>
            </Paper>
        </Grid> */}
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2} sx={{paddingTop:"2rem"}}>
            <Paper elevation={3} className={classes.paper}>
                <Box className={classes.titleStyle}>Vendors</Box>
                <hr className={classes.line}/>
                <Box className={classes.scrollBarOverFlow}>
                    {responseData && vendorList !== undefined && vendorList.length !== 0 ? vendorList.map((item:any)=>{
                        return (
                            <Box sx={{paddingBottom:"5px"}}>
                                <Link 
                                    onClick={() => onVendorClickHandler(item)}
                                    to=''
                                    className="no-underline" 
                                    style={{color:"#0052cc"}}
                                    >{`${item.name1} ${item.name2}`}</Link>
                            </Box>
                        )
                    }):(
                        <Box sx={{color:"red",fontSize:"small"}}>No record found</Box>
                    )}
                </Box> 
            </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2} sx={{paddingTop:"2rem"}}>
            <Paper elevation={3} className={classes.paper}>
                <Box className={classes.titleStyle}>T5 Recipients</Box>
                <hr className={classes.line}/>
                <Box className={classes.scrollBarOverFlow}>
                    {responseData && recipientList !== undefined && recipientList.length !== 0 ? recipientList.map((item:any)=>{
                        return (
                            <Box sx={{paddingBottom:"5px"}}>
                                <Link 
                                    onClick={() => onRecipientClickHandler(item)}
                                    to=''
                                    className="no-underline" 
                                    style={{color:"#0052cc"}}
                                    >{`${item.name1} ${item.name2}`}</Link>
                            </Box>
                        )
                    }):(
                        <Box sx={{color:"red",fontSize:"small"}}>No record found</Box>
                    )}
                </Box> 
            </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2} sx={{paddingTop:"2rem"}}>
            <Paper elevation={3} className={classes.paper}>
                <Box className={classes.titleStyle}>T3 Beneficiary</Box>
                <hr className={classes.line}/>
                <Box className={classes.scrollBarOverFlow}>
                    {responseData && beneficiaryList !== undefined && beneficiaryList.length !== 0 ? beneficiaryList.map((item:any)=>{
                        return (
                            <Box sx={{paddingBottom:"5px"}}>
                                <Link 
                                    onClick={() => onBeneficiaryClickHandler(item)}
                                    to=''
                                    className="no-underline" 
                                    style={{color:"#0052cc"}}
                                    >{`${item.name1} ${item.name2}`}</Link>
                            </Box>
                        )
                    }):(
                        <Box sx={{color:"red",fontSize:"small"}}>No record found</Box>
                    )}
                </Box> 
            </Paper>
        </Grid>
        {/* <Grid item xs={1.71} sm={1.71} md={1.71} lg={1.71} xl={1.71} sx={{paddingTop:"2rem"}}>
            <Paper elevation={3} className={classes.paper}>
                <Box className={classes.titleStyle}>T4A Slips</Box>
                <hr className={classes.line}/>
                <Box className={classes.scrollBarOverFlow}>
                    <Box sx={{color:"red",fontSize:"small"}}>No record found</Box>
                </Box>
            </Paper>
        </Grid> */}
    </Grid>
  )
}