import { ClientList } from "components/Client";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { MoveClients } from "components/Client/MoveClients";

export interface IMoveClientListPage {}

export const MoveClientListPage: FC<IMoveClientListPage> = (
  props: IMoveClientListPage
) => {
  return (
    <AuthenticatedLayout
      pageTitle="Move Clients"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<MoveClients />}
    />
  );
};
