import {
  GET_QUERY_KEY,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  T3_SETTINGS_SCREEN_SLUG,
} from "common/constants";
import { instanceOfIFlexFieldSetupRequest } from "common/instance-method";
import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { IGetListRequest, IResponse, IServerError } from "models/common";
import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FlexFieldService } from "services/FlexFieldService";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BackdropCircularProgress,
  EnhancedDropDownRHFWithLabel,
  EnhancedIOSSwitchRHFWithLabel,
  EnhancedSnackbar,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  PhoneNumberRHF,
  PostCodeRHF,
  RHookFormMaskedTextFieldWithLabel,
  RHookFormTextFieldWithLabel,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  TwoColumnLayout,
} from "@websential/cosmic";

import { ClientService, TaxSlipYearService } from "services";
import { T4SlipService } from "services/T4SlipService";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { T3SlipSettingValidation } from "./T3SettingValidation";
import { T3SlipService } from "services/T3SlipService";
import { Grid } from "@mui/material";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { ProvinceService } from "services/common/ProvinceService";

export interface IT3SlipSettingsSetup extends ITest {}

const DEFAULT_VALUES = {
  // comparison: [
  //   {
  //     operator: undefined,
  //     field: undefined,
  //   },
  // ],
  // fieldType: undefined,
  // fieldLabel: "",
  // fieldId: "",
  // defaultValue: "",
  // placeholder: "",
  // fieldFormat: "",
  // fieldValue: "",
  // dataType: undefined,
  // fieldColumn: undefined,
  // parentRelation: undefined,
  // fieldInternalValue: "",
  // notes: "",
  // minValue: 0,
  // maxValue: 0,
  // isCategory: false,
  // isFixed: false,
  // isHide: false,
  // isRequired: false,
  // parentValue: "",
  // parent: undefined,
  // id: undefined,
};

// const FLEX_FIELD_DATA_QUERY_KEY = "get-flex-field-data";
// const LIST_FIELDS_QUERY_KEY = "get-flex-fields-list";

// const useGetFlexFieldData = (flexFieldId: number) =>
//   useQuery(
//     [FLEX_FIELD_DATA_QUERY_KEY, flexFieldId],
//     () => FlexFieldService.getFlexFieldById(flexFieldId),
//     { ...QUERY_OPTIONS, enabled: !!flexFieldId }
//   );
// const useGetFlexFieldsList = () =>
//   useQuery(
//     LIST_FIELDS_QUERY_KEY,
//     () => FlexFieldService.getFlexFieldDropDownList("none"),
//     QUERY_OPTIONS
//   );

//Updted code starts from here
// Taxslip Year List
const useYears = (params: IGetListRequest) =>
  useQuery(
    ["years-list", params],
    (data) => {
      return TaxSlipYearService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

const T3_SETTING_DATA_QUERY_KEY = "get-T3-setting-data";
const useGetT3SettingData = (clientid: string, taxYear: number) =>
  useQuery(
    [T3_SETTING_DATA_QUERY_KEY, clientid, taxYear],
    (data) => {
      return T3SlipService.getT3SettingData(clientid, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

  const useGetProfileData = (id: string | undefined) => 
    useQuery(
      ["client-single-data-record", id],
      (data) => { return ClientService.getById(id);
      },
      { refetchOnWindowFocus: false }
    );

  const useGetTransmitterDDL = (clientId: string, taxYear: number) =>
    useQuery(
      ["transmitters"],
      () => {
        return T4SlipService.getAllActiveTransmittersByClientIdAndTaxYear(
          clientId,
          taxYear
        );
      },
      { refetchOnWindowFocus: false }
    );

export const T3SlipSettingsSetup: FC<IT3SlipSettingsSetup> = ({ testId }) => {
  const dispatch = useDispatch();
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );

  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [trusteeIsIndividual, setTrusteeIsIndividual] = useState(false);
  const [usedCompanyAddress, setUsedCompanyAddress] = useState(false);
  // const queryClient = useQueryClient();

  const useGetProvinceDDL = () => {
    return useQuery(GET_QUERY_KEY.PROVINCE_DDL, () => ProvinceService.get(), {
      ...QUERY_OPTIONS,
    });
  };
  const { isLoading: isLoadingProvinceDropDownList, data: provinceList } =
    useGetProvinceDDL();

  //new code
  const id = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [selectedYear, setSelectedYear] = useState(taxYear);

  const { isLoading: isSubmitting, mutate: submitT3SettingData } = useMutation(
    T3SlipService.t3SettingCreate,
    {
      onSuccess: (res: any | IServerError | IResponse) => {
        // if (instanceOfIFlexFieldSetupRequest(res)) {
        // // remove the cache of the flex fields since we just added a new field.
        // queryClient.invalidateQueries(LIST_FIELDS_QUERY_KEY);
        // // [corner case] remove the cache of the field that has just been edited in-case the user re-selects it again
        // queryClient.invalidateQueries([FLEX_FIELD_DATA_QUERY_KEY, res.id]);
        // // set the currently added/edited flex field as the selected field
        // formMethods.setValue("id", res.id);
        if (res.message) {
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "error",
          });
        } else {
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );
  // const { isLoading: isLoadingFlexFieldsList, data: flexFieldsList } =
  //   useGetFlexFieldsList();
  // const validationSchema = useMemo(
  //   () =>
  //     getValidationSchema(
  //     //   flexFieldsList && Array.isArray(flexFieldsList)
  //     //     ? flexFieldsList.map((flexField: any) => ({
  //     //         id: flexField.id,
  //     //         value: flexField.fieldId,
  //     //       }))
  //     //     : []
  //     ),
  //   [flexFieldsList]
  // );
  const formMethods = useForm<any>({
    resolver: yupResolver(T3SlipSettingValidation),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  // const [selectedFlexFieldId, selectedParent] = useWatch({
  //   control: formMethods.control,
  //   name: ["id", "parent"],
  // });
  // const { isLoading: isLoadingSelectedFlexField, data: selectedFlexFieldData } =
  //   useGetFlexFieldData(selectedFlexFieldId as number);

  /**
   * Handle when the form is submitted.
   *
   * @param {IFlexFieldSetupRequest} formData
   */
  const onSubmit = (formData: any) => {
    const parseJSONLikeString = (str: string) => {
      const modifiedString = str && str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
      return JSON.parse(modifiedString);
    };
    const requestPayload = {
      id: id,
      taxYear: formData.taxYear,
      trustAccountNumber: formData.trustAccountNumber,
      nameOfTrust: formData.nameOfTrust,
      transmitter: formData.transmitter,
      trusteeFirstName: formData.trusteeFirstName,
      trusteeLastName: formData.trusteeLastName,
      country: {
        id: 1,
        name: "Canada",
        shortCode: "CAN",
      },
      t3Province: formData.t3Province && parseJSONLikeString(formData.t3Province),
      trusteeIsIndividual: formData.trusteeIsIndividual,
      trusteeAddress: {
        address: formData.address,
        city: formData.city,
        province: formData.trusteeProvince && parseJSONLikeString(formData.trusteeProvince),
        postalCode: formData.postalCode,
        country: {
          id: 1,
          name: "Canada",
          shortCode: "CAN",
          },
          usedCompanyAddress: formData.usedCompanyAddress,
          nameOfNonIndividualTrustee: formData.nameOfNonIndividualTrustee,
          telephone: formData.telephone,
      },
    };
    submitT3SettingData(requestPayload);
    console.log("formData", formData);
    console.log("requestPayload", requestPayload);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset();
  };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // const yearListItems = useMemo(() => {
  //   return [
  //     {
  //       label: "2020",
  //       value: "2020",
  //     },
  //     {
  //       label: "2021",
  //       value: "2021",
  //     },
  //   ];
  //   // if (!flexFieldsList) {
  //   //   return [];
  //   // }
  //   // return flexFieldsList.map((flexField) => ({
  //   //   label: flexField.fieldLabel,
  //   //   value: flexField.id,
  //   // }));
  // }, []);

  // set the values of the selected flex field coming from BE.
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (selectedFlexFieldData) {
  //     Object.keys(selectedFlexFieldData).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = selectedFlexFieldData[fieldName];
  //       // @ts-ignore
  //       formMethods.setValue(fieldName, fieldValue);
  //     });
  //   }
  // }, [selectedFlexFieldData, formMethods.setValue, formMethods]);

  // if we have selected the parent and a flex field and they both are the same, we should un-set the parent because a field cannot be the parent of itself
  // useEffect(() => {
  //   if (
  //     selectedFlexFieldId &&
  //     selectedParent &&
  //     selectedParent === selectedFlexFieldId
  //   ) {
  //     formMethods.setValue("parent", undefined);
  //   }
  // }, [formMethods, selectedFlexFieldId, selectedParent]);

  //new code
  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "role_name",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useYears(queryParams); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const YearsList: any = data;
  console.log("YearsList", YearsList);
  const years = YearsList?.items.map((x: any) => {
    const { year, ...rest } = x; // Extract year property and the rest of the properties
    return { ...rest, value: year, label: year }; // Rename year to value
  });
  console.log("years", years);


  const { isLoading: isLoadingSummaryGetData, data: T3SettingData, refetch: refetchT3SettingData } =
    useGetT3SettingData(id, selectedYear);

  const { isLoading: isLoadingProfileData, data: clientProfileDataRecord } =
    useGetProfileData(id);

    const { isLoading: isLoadingTransmitterDDL, data: transmitterDDL } =
    useGetTransmitterDDL(id, taxYear);
  console.log("transmitterDDL", transmitterDDL);

  useEffect(()=>{
    if(clientProfileDataRecord && clientProfileDataRecord.clientName && T3SettingData && !T3SettingData.id){
      formMethods.setValue('nameOfTrust',clientProfileDataRecord.clientName)
    }
  },[clientProfileDataRecord])

  console.log("T3SettingData ============", T3SettingData);

  useEffect(() => {
    if (T3SettingData && T3SettingData.id) {
      Object.keys(T3SettingData).forEach((fieldName) => {
        const fieldValue = T3SettingData[fieldName];
        formMethods.setValue(fieldName, fieldValue ?? undefined);
        if (fieldName === "t3Province") {
          let id = fieldValue.id;
          let name = fieldValue.name;
          let shortCode = fieldValue.shortCode;
          formMethods.setValue(
            fieldName,
            `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
          );
        }
        if(fieldName === 'trusteeAddress'){
          Object.keys(fieldValue).forEach((addressFieldName) => {
            const addressFieldValue = fieldValue[addressFieldName]
            formMethods.setValue(addressFieldName, addressFieldValue ?? undefined);
            if (addressFieldName === "province") {
              let id = addressFieldValue.id;
              let name = addressFieldValue.name;
              let shortCode = addressFieldValue.shortCode;
              formMethods.setValue(
                'trusteeProvince',
                // '{id:9, name: "Ontario",shortCode:"ON"}'
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
              );
            }
          })
        }
      });
    } else if (T3SettingData && T3SettingData.message) {
      setSnackbarData({
        open: true,
        message: T3SettingData.message.message ? T3SettingData.message.message : T3SettingData.message.detail ? T3SettingData.message.detail : T3SettingData.message ? T3SettingData.message : 'Something went wrong!',
        severity: "error",
      });
      formMethods.reset();
      formMethods.setValue('taxYear',selectedYear)
      if(clientProfileDataRecord){
        formMethods.setValue('nameOfTrust',clientProfileDataRecord.clientName)
      }
      } else {
        formMethods.reset();
        formMethods.setValue('taxYear',selectedYear)
      if(clientProfileDataRecord){
        formMethods.setValue('nameOfTrust',clientProfileDataRecord.clientName)
      }
    }
  }, [T3SettingData, formMethods.setValue, formMethods]);
  console.log("lll-", formMethods.getValues("identification"));
  // console.log("lll-",formMethods.getValues('identification').length)

  const onYearSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
      setSelectedYear(Number(item.value));
      refetchT3SettingData()
    }

  const onUsedCompanyAddressChangeHandler = (value: boolean) => {
      if(value){
        (async () => {
          try {
            setIsLoadingData(true)
            const response: any = await ClientService.getById(id);
            console.log("responseData-----------------", response);
            if (response) {
              if (response && response.id) {
                Object.keys(response).forEach((fieldName) => {
                  const fieldValue = response[fieldName];
                  console.log("fieldValuefieldValue",fieldValue)
                  if(fieldName === "address"){
                    console.log("fielddd",fieldValue)
                    Object.keys(fieldValue).forEach((addressFieldName) => {
                      const addressFieldValue = fieldValue[addressFieldName]
                      console.log("skjdvnksdjvb",addressFieldValue)
                      Object.keys(addressFieldValue).forEach((addressFieldName1) => {
                        const addressFieldValue1 = addressFieldValue[addressFieldName1]
                        console.log("skjdvnksdjvb1",addressFieldValue1)
                        console.log("skjdvnksdjvb1",addressFieldName1)
                        if(addressFieldName1 === 'addressLine1'){
                          formMethods.setValue('address', addressFieldValue1 ?? undefined);
                        }
                        if(addressFieldName1 === 'city'){
                          formMethods.setValue(addressFieldName1, addressFieldValue1 ?? undefined);
                        }
                        if(addressFieldName1 === 'postalCode'){
                          formMethods.setValue(addressFieldName1, addressFieldValue1 ?? undefined);
                        } 
                        if (addressFieldName1 === "province") {
                          let id = addressFieldValue1.id;
                          let name = addressFieldValue1.name;
                          let shortCode = addressFieldValue1.shortCode;
                          console.log("namename", name);
                          let selectedPOEwhenUpdate = addressFieldValue1.name;
            
                          formMethods.setValue(
                            'trusteeProvince',
                            // '{id:9, name: "Ontario",shortCode:"ON"}'
                            `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                          );
                        }
                      })
                    })
                  }
                });
              } 
            }
          } catch (error) {
            setIsLoadingData(false)
            console.error("Error fetching data:", error);
          } finally {
            setIsLoadingData(false)
            }
            })();
      } else {
        formMethods.setValue('address','')
        formMethods.setValue('city','')
        formMethods.setValue('trusteeProvince',undefined)
        formMethods.setValue('postalCode','')
      }
  }

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: T3_SETTINGS_SCREEN_SLUG,
        companyName: clientName,
        activityType: "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={
          isLoading ||
          // isLoadingSelectedFlexField ||
          isSubmitting ||
          isLoadingProfileData ||
          isLoadingSummaryGetData ||
          isLoadingActivityLogData ||
          isLoadingTransmitterDDL ||
          isLoadingData
        }
        testId={testId}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <TwoColumnLayout
            toolbar={
              <StandardSubmitFormButtonsToolbar
                onResetClicked={onResetClicked}
                isSubmitting={isSubmitting}
                testId={testId}
              />
            }
            leftColumnContent={
              <>
                <StandardPanel title="Year" testId={`${testId}-year-panel`}>
                  <EnhancedDropDownRHFWithLabel
                    label="Select Year"
                    selectName="taxYear"
                    items={years ?? []}
                    testId={`${testId}-selected-year`}
                    labelPosition={LabelPosition.Left}
                    selectedItem={selectedYear}
                    onSelectionChangeHandler={onYearSelectionChangeHandler}
                  />
                </StandardPanel>
                <StandardPanel
                  title="Transmitter and Identification"
                  testId={`${testId}-identification-data`}
                  topPadding={2}
                >
                  <EnhancedDropDownRHFWithLabel
                    label="Transmitter"
                    selectName="transmitter"
                    items={transmitterDDL && !transmitterDDL.message ? transmitterDDL : []}
                    // selectedItem="2020"
                    testId={`${testId}-transmitter`}
                    labelPosition={LabelPosition.Left}
                    required
                  />
                  <RHookFormMaskedTextFieldWithLabel
                        label="Trust Account #"
                        id="trustAccountNumber"
                        testId={`${testId}-trustAccountNumber`}
                        labelPosition={LabelPosition.Left}
                        placeholder={"00000000"}
                        // required
                        inputMaskPattern={"00000000"}
                        labelColumns={4}
                        fieldColumns={8}
                      />
                  <RHookFormTextFieldWithLabel
                    label="Name of Trust"
                    id="nameOfTrust"
                    testId={`${testId}-nameOfTrust`}
                    labelPosition={LabelPosition.Left}
                  />
                  <RHookFormTextFieldWithLabel
                    label="Trustee First Name"
                    id="trusteeFirstName"
                    testId={`${testId}-trusteeFirstName`}
                    labelPosition={LabelPosition.Left}
                  />
                  <RHookFormTextFieldWithLabel
                    label="Trustee Last Name"
                    id="trusteeLastName"
                    testId={`${testId}-trusteeLastName`}
                    labelPosition={LabelPosition.Left}
                  />
                  <RHookFormTextFieldWithLabel
                    label="Country"
                    id="country"
                    testId={`${testId}-trustee-country`}
                    labelPosition={LabelPosition.Left}
                    value="Canada"
                    defaultValue={"Canada"}
                    readOnly
                  />
                  <EnhancedDropDownRHFWithLabel
                    selectName="t3Province"
                    label="Province"
                    // selectedItem={selectedProvince.id !== null && selectedProvince}
                    labelPosition={LabelPosition.Left}
                    testId={`${testId}-province-list`}
                    items={provinceList ?? []}
                    // onSelectionChangeHandler={onProvinceSelectionChangeHandler}
                  />
                </StandardPanel>
              </>
            }
            rightColumnContent={
              <>
                <StandardPanel
                  title="Address"
                  testId={`${testId}-sequential-numbers`}
                >
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="trusteeIsIndividual"
                      checked={false}
                      testId="trusteeIsIndividual"
                      label="Trustee is Individual"
                      labelPosition={LabelPosition.Left}
                      onSelectionChangeHandler={(value:boolean) => setTrusteeIsIndividual(value)}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="usedCompanyAddress"
                      checked={false}
                      testId="usedCompanyAddress"
                      label="Use Company Address"
                      labelPosition={LabelPosition.Left}
                      onSelectionChangeHandler={onUsedCompanyAddressChangeHandler}
                    />
                  </FieldWrapper>
                  {trusteeIsIndividual && 
                  <RHookFormTextFieldWithLabel
                    label="Name of Non Individual Trustee"
                    id="nameOfNonIndividualTrustee"
                    testId={`${testId}-nameOfNonIndividualTrustee`}
                    labelPosition={LabelPosition.Left}
                  />}
                  <RHookFormTextFieldWithLabel
                    label="Address"
                    id="address"
                    testId={`${testId}-trustee-address`}
                    labelPosition={LabelPosition.Left}
                  />
                  <RHookFormTextFieldWithLabel
                    label="City"
                    id="city"
                    testId={`${testId}-trustee-city`}
                    labelPosition={LabelPosition.Left}
                  />
                  <EnhancedDropDownRHFWithLabel
                    selectName="trusteeProvince"
                    label="Province"
                    // selectedItem={selectedProvince.id !== null && selectedProvince}
                    labelPosition={LabelPosition.Left}
                    testId={`${testId}-province-list`}
                    items={provinceList ?? []}
                    // onSelectionChangeHandler={onProvinceSelectionChangeHandler}
                  />
                    <PostCodeRHF
                    label="Postal Code"
                    id="postalCode"
                    testId={`${testId}-trustee-post-code`}
                    labelPosition={LabelPosition.Left}
                    labelColumns={4}
                    fieldColumns={8}
                  />
                  <RHookFormTextFieldWithLabel
                    label="Country"
                    id="country"
                    testId={`${testId}-trustee-country`}
                    labelPosition={LabelPosition.Left}
                    value="Canada"
                    defaultValue={"Canada"}
                    readOnly
                  />
                  <PhoneNumberRHF
                    label="Phone"
                    id="telephone"
                    testId={`${testId}-phone`}
                    labelPosition={LabelPosition.Left}
                    labelColumns={4}
                    fieldColumns={8}
                  />
                </StandardPanel>
              </>
            }
            testId="T3-settings-setup-form"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
