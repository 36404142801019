import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  FlatStandardPanel,
  ITest,
  SingleColumnLayout,
  Spacer,
  StandardPanel,
} from "@websential/cosmic";
import { Button, Divider, Grid, Paper } from "@mui/material";
import {
  CNTFinancingContributionPanel,
  EligibleTrainingExpendituresPanel,
  HealthServiceFundPanel,
  RL1SlipDetailPanel,
  WSDRFContributionPanel,
} from "./Panels";
import {
  EnhancedDialog,
  EnhancedLinkButton,
  IconTextButton,
  RouterLinkButton,
  SplitButton,
} from "@websential/cosmic";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
  GET_QUERY_KEY,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  RL1_SUMMARY_SCREEN_ID,
  RL1_SUMMARY_SCREEN_SLUG,
} from "common/constants";
import { IResponse, IServerError } from "models/common";
import { StatementOfDutiesPaidOrPayable, generateTotalColumnLabel, getMonthRange } from "./Panels/StatementOfDutiesPaidOrPayable";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { AccountInformation } from "./Panels/AccountInformation";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import FileSaver from "file-saver";
import { FlexFieldService } from "services/FlexFieldService";
import { IAppState } from "store/reducers";
import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { InvoiceDialogPanel } from "components/T4Slip/T4SlipList/InvoiceDialogPanel";
import { PrintingService } from "services/PrintingService";
import { RL1SlipService } from "services/RL1SlipService";
import { RL1SummarySetupSchema } from "./summaryValidate";
import { RL1SummaryToolbar } from "../RL1SummaryToolbar";
import { ReportsService } from "services/ReportsService";
import { TaxSlipSettingsService } from "services/TaxSlipSettingsService";
import { checkSummaryType } from "components/T4Slip";
import { getValidationSchema } from "./validator";
import { instanceOfIFlexFieldSetupRequest } from "common/instance-method";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedINVOICE } from "store/actions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { yupResolver } from "@hookform/resolvers/yup";
import { T4SlipService } from "services/T4SlipService";
import { ClientService } from "services";

export let selectedRL1SummaryType: any = 400;
export interface IRL1SlipSummarySetup extends ITest {}

const DEFAULT_VALUES = {
  // comparison: [
  //   {
  //     operator: undefined,
  //     field: undefined,
  //   },
  // ],
  // fieldType: undefined,
  // fieldLabel: "",
  // fieldId: "",
  // defaultValue: "",
  // placeholder: "",
  // fieldFormat: "",
  // fieldValue: "",
  // dataType: undefined,
  // fieldColumn: undefined,
  // parentRelation: undefined,
  // fieldInternalValue: "",
  // notes: "",
  // minValue: 0,
  // maxValue: 0,
  // isCategory: false,
  // isFixed: false,
  // isHide: false,
  // isRequired: false,
  // parentValue: "",
  // parent: undefined,
  // id: undefined,
};

const RL1_SUMMARY_DATA_QUERY_KEY = "get-rl1-summary-data";
// const LIST_FIELDS_QUERY_KEY = "get-rl1-summary-list";

// const useGetFlexFieldData = (flexFieldId: number) =>
//   useQuery(
//     [FLEX_FIELD_DATA_QUERY_KEY, flexFieldId],
//     () => FlexFieldService.getFlexFieldById(flexFieldId),
//     { ...QUERY_OPTIONS, enabled: !!flexFieldId }
//   );
// const useGetFlexFieldsList = () =>
//   useQuery(
//     LIST_FIELDS_QUERY_KEY,
//     () => FlexFieldService.getFlexFieldDropDownList("none"),
//     QUERY_OPTIONS
//   );

const useGetRL1SummaryData = (
  clientid: string,
  slipType: string | number,
  taxYear: number
) =>
  useQuery(
    [RL1_SUMMARY_DATA_QUERY_KEY, clientid, slipType, taxYear],
    (data) => {
      return RL1SlipService.getRL1Summary(clientid, slipType, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

//get global taxslip-definitions/global-variables
const useGetGlobalVariables = (taxYear: number) =>
  useQuery(
    [GET_QUERY_KEY.GET_GLOBAL_VARIABLES_BY_YEAR, taxYear],
    (data) => {
      return TaxSlipSettingsService.getGlobalVariables(taxYear);
    },
    { refetchOnWindowFocus: false }
  );

  const useGetSlipIds = (
    tag: string,
    clientId: string,
    taxYear: number
  ) =>
    useQuery(
      ["get-slip-ids", tag, clientId, taxYear],
      (data) => {
        return T4SlipService.getSlipIds(tag, clientId, taxYear);
      },
      { refetchOnWindowFocus: false }
    );

    const useGetCompanyData = (id: string | undefined) => 
      useQuery(
        ["client-get-by-id", id],
        (data) => {
          return ClientService.getById(id);
        },
        { refetchOnWindowFocus: false }
      );

export const RL1SlipSummarySetup: FC<IRL1SlipSummarySetup> = ({ testId }) => {
  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const id = useSelector((state: IAppState) => state.client.id);
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );
  const [invoicePaymentFor, setInvoicePaymentFor] = useState(10)

  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const clientId = useSelector((state: IAppState) => state.client.id);

  const [selectedType, setSelectedType] = useState(400);
  const [openInvoiceDialog, setInvoiceDialogOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [cntAmountWhileUpdate, setCntAmountWhileUpdate] = useState("0.00");
  let initialStatementofDutiesPaidorPayableData = [
		{
			"id": "5",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"january")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "6",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"february")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "7",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"march")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "8",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"april")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "9",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"may")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "10",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"june")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "11",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"july")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "12",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"august")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "13",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"september")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "14",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"october")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "15",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"november")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "16",
			"renmittancePeriodBoxA": `${getMonthRange(taxYear,"december")}`,
			"qppContributionBoxB": 0.00,
			"qpipPremiumsBoxC": 0.00,
			"quebecIncomeTaxBoxD": 0.00,
			"contributionToHealthServicesFundBoxE": 0.00,
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
		{
			"id": "17",
			"renmittancePeriodBoxA": generateTotalColumnLabel(),
			"qppContributionBoxB": "0.00",
			"qpipPremiumsBoxC": "0.00",
			"quebecIncomeTaxBoxD": "0.00",
			"contributionToHealthServicesFundBoxE": "0.00",
			"totalForTheRemittancePeriodBoxF": "0.00"
		},
	]
  const [statementofDutiesPaidorPayableData, setStatementofDutiesPaidorPayableData] = useState<any>(initialStatementofDutiesPaidorPayableData);
  const [statementofDutiesPaidorPayableDataInGetById, setStatementofDutiesPaidorPayableDataInGetById] = useState<any>(initialStatementofDutiesPaidorPayableData);

  console.log("statementofDutiesPaidorPayableData-=",statementofDutiesPaidorPayableData)
  console.log("statementofDutiesPaidorPayableDataInGetById-=",statementofDutiesPaidorPayableDataInGetById)

  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    RL1SlipService.createSummary,
    {
      onSuccess: (
        res: IFlexFieldSetupRequest | IServerError | IResponse | any
      ) => {
        // if (instanceOfIFlexFieldSetupRequest(res)) {
        //   // remove the cache of the flex fields since we just added a new field.
        //   queryClient.invalidateQueries(LIST_FIELDS_QUERY_KEY);
        //   // [corner case] remove the cache of the field that has just been edited in-case the user re-selects it again
        //   queryClient.invalidateQueries([FLEX_FIELD_DATA_QUERY_KEY, res.id]);
        //   // set the currently added/edited flex field as the selected field
        //   formMethods.setValue("id", res.id);
        if (res.status === 201 || res.status === 200) {
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
        } else {
          setSnackbarData({
            open: true,
            message:
              res && res.message
                ? res.message
                : res.data.message
                ? res.data.message
                : "Something went wrong!",
            severity: "error",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );
  // const { isLoading: isLoadingFlexFieldsList, data: flexFieldsList } =
  //   useGetFlexFieldsList();
  // const validationSchema = useMemo(
  //   () =>
  //     getValidationSchema(
  //       flexFieldsList && Array.isArray(flexFieldsList)
  //         ? flexFieldsList.map((flexField: any) => ({
  //             id: flexField.id,
  //             value: flexField.fieldId,
  //           }))
  //         : []
  //     ),
  //   [flexFieldsList]
  // );
  // const formMethods = useForm<IFlexFieldSetupRequest>({
  //   resolver: yupResolver(validationSchema),
  //   mode: "onBlur",
  //   reValidateMode: "onChange",
  //   defaultValues: DEFAULT_VALUES,
  // });
  // const [selectedFlexFieldId, selectedParent] = useWatch({
  //   control: formMethods.control,
  //   name: ["id", "parent"],
  // });
  // const { isLoading: isLoadingSelectedFlexField, data: selectedFlexFieldData } =
  //   useGetFlexFieldData(selectedFlexFieldId as number);

  const formMethods = useForm<any>({
    resolver: yupResolver(RL1SummarySetupSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  const {
    isLoading: isLoadingSummaryGetData,
    isFetching: reFetchingSlipType,
    refetch: refetchSlipType,
    data: rl1SummaryData,
  } = useGetRL1SummaryData(id, selectedType, taxYear);
  console.log("selectedType", selectedType);

  console.log("rl1SummaryData ============", rl1SummaryData);

  const {
    isLoading: isLoadingGlobalVariables,
    isFetching: reFetchingGlobalVariables,
    refetch: refetchGlobalVariables,
    data: globalVariablesData,
  } = useGetGlobalVariables(taxYear);

  console.log(
    "globalVariablesData ============",
    globalVariablesData?.data?.rl1
  );

  const { isLoading:loadingSlipIds, data:rl1SlipIds } = useGetSlipIds("RL1", clientId, taxYear);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  useEffect(()=>{
    console.log("companyGetByIdRecord11",companyGetByIdRecord)
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor ? parseInt(paymentFor.value) : 10)
    }
  },[companyGetByIdRecord])

  /**
   * Handle when the form is submitted.
   *
   * @param {IFlexFieldSetupRequest} formData
   */
  const onSubmit = (formData: any) => {
    let Payload: any = {
      id: id,
      taxYear: taxYear,
      slipType: 400,
      confirmationCode:
        formData.confirmationNumber !== ""
          ? formData.confirmationNumber
          : undefined,

      rl1FiledOnPaper: formData.rl1FiledOnPaper,
      rl1FiledOnline: formData.rl1FiledOnline,
      qppContributionsQpipPremiumsIncomeTaxRemittedAmount:
      formData.qppContributionsQpipPremiumsIncomeTaxRemittedAmount === ""
      ? undefined
      : formData.qppContributionsQpipPremiumsIncomeTaxRemittedAmount === "0.00"
      ? undefined
      : parseFloat(formData.qppContributionsQpipPremiumsIncomeTaxRemittedAmount.replace(/[$,]/g, '')),
      totalPayrollForThePurposeOfDeterminingContributionRate:
      formData.totalPayrollForThePurposeOfDeterminingContributionRate === ""
      ? undefined
      : formData.totalPayrollForThePurposeOfDeterminingContributionRate === "0.00"
      ? undefined
      : parseFloat(formData.totalPayrollForThePurposeOfDeterminingContributionRate.replace(/[$,]/g, '')),
      salaryAndWagesSubjectToTheContribution:
      formData.salaryAndWagesSubjectToTheContribution === ""
      ? undefined
      : formData.salaryAndWagesSubjectToTheContribution === "0.00"
      ? undefined
      : parseFloat(formData.salaryAndWagesSubjectToTheContribution.replace(/[$,]/g, '')),
      exemptSalaryAndWages: formData.exemptSalaryAndWages === ""
      ? undefined
      : formData.exemptSalaryAndWages === "0.00"
      ? undefined
      : parseFloat(formData.exemptSalaryAndWages.replace(/[$,]/g, '')),
      contributionToTheHealthServicesFundRemittedInTheYear:
      formData.contributionToTheHealthServicesFundRemittedInTheYear === ""
      ? undefined
      : formData.contributionToTheHealthServicesFundRemittedInTheYear === "0.00"
      ? undefined
      : parseFloat(formData.contributionToTheHealthServicesFundRemittedInTheYear.replace(/[$,]/g, '')),
      remunerationSubjectToContribution:
      formData.remunerationSubjectToContribution === ""
      ? undefined
      : formData.remunerationSubjectToContribution === "0.00"
      ? undefined
      : parseFloat(formData.remunerationSubjectToContribution.replace(/[$,]/g, '')),
      employerCode:
      formData.employerCode === ""
      ? undefined
      : formData.employerCode,
      totalPayroll: formData.totalPayroll === ""
      ? undefined
      : formData.totalPayroll === "0.00"
      ? undefined
      : parseFloat(formData.totalPayroll.replace(/[$,]/g, '')),
      balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears:
      formData.balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears === ""
      ? undefined
      : formData.balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears === "0.00"
      ? undefined
      : parseFloat(formData.balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears.replace(/[$,]/g, '')),
      eligibleTrainingExpendituresForTheCurrentYear:
        formData.eligibleTrainingExpendituresForTheCurrentYear === ""
          ? undefined
          : formData.eligibleTrainingExpendituresForTheCurrentYear === "0.00"
          ? undefined
          : parseFloat(formData.eligibleTrainingExpendituresForTheCurrentYear.replace(/[$,]/g, '')),
          
      amountUsedToReduceOrCancelTheContributionOnLine51CarryThisAmountToLine52:
          formData.amountReduced === ""
            ? undefined
            : formData.amountReduced === "0.00"
            ? undefined
            : parseFloat(formData.amountReduced.replace(/[$,]/g, '')),
        // formData.amountReduced,
      primaryAndManufacturingSectors: false,


      //Statement of duties paid or payable 
      //if year > 2023
      //payload data
      periodNo5: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      //  statementofDutiesPaidorPayableData[0]
      {
        contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[0].contributionToHealthServicesFundBoxE !== null ? statementofDutiesPaidorPayableData[0].contributionToHealthServicesFundBoxE : 0.00,
        id : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[0].id,
        qpipPremiumsBoxC : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[0].qpipPremiumsBoxC !== null ? statementofDutiesPaidorPayableData[0].qpipPremiumsBoxC : 0.00,
        qppContributionBoxB : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[0].qppContributionBoxB !== null ? statementofDutiesPaidorPayableData[0].qppContributionBoxB : 0.00,
        quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[0].quebecIncomeTaxBoxD !== null ? statementofDutiesPaidorPayableData[0].quebecIncomeTaxBoxD : 0.00,
        renmittancePeriodBoxA : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[0].renmittancePeriodBoxA !== null ? statementofDutiesPaidorPayableData[0].renmittancePeriodBoxA : 0.00,
        totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[0].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
      }
       : undefined,
      periodNo6: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      // statementofDutiesPaidorPayableData[1]
      {
        contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[1].contributionToHealthServicesFundBoxE !== null ? statementofDutiesPaidorPayableData[1].contributionToHealthServicesFundBoxE : 0.00,
        id : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[1].id,
        qpipPremiumsBoxC : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[1].qpipPremiumsBoxC !== null ? statementofDutiesPaidorPayableData[1].qpipPremiumsBoxC : 0.00,
        qppContributionBoxB : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[1].qppContributionBoxB !== null ? statementofDutiesPaidorPayableData[1].qppContributionBoxB : 0.00,
        quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[1].quebecIncomeTaxBoxD !== null ? statementofDutiesPaidorPayableData[1].quebecIncomeTaxBoxD : 0.00,
        renmittancePeriodBoxA : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[1].renmittancePeriodBoxA !== null ? statementofDutiesPaidorPayableData[1].renmittancePeriodBoxA : 0.00,
        totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[1].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
      }
       : undefined,
      periodNo7: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      // statementofDutiesPaidorPayableData[2]
      {
        contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[2].contributionToHealthServicesFundBoxE !== null ? statementofDutiesPaidorPayableData[2].contributionToHealthServicesFundBoxE : 0.00,
        id : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[2].id,
        qpipPremiumsBoxC : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[2].qpipPremiumsBoxC !== null ? statementofDutiesPaidorPayableData[2].qpipPremiumsBoxC : 0.00,
        qppContributionBoxB : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[2].qppContributionBoxB !== null ? statementofDutiesPaidorPayableData[2].qppContributionBoxB : 0.00,
        quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[2].quebecIncomeTaxBoxD !== null ? statementofDutiesPaidorPayableData[2].quebecIncomeTaxBoxD : 0.00,
        renmittancePeriodBoxA : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[2].renmittancePeriodBoxA !== null ? statementofDutiesPaidorPayableData[2].renmittancePeriodBoxA : 0.00,
        totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[2].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
      } 
      : undefined,
      periodNo8: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      // statementofDutiesPaidorPayableData[3]
      {
        contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[3].contributionToHealthServicesFundBoxE !== null ? statementofDutiesPaidorPayableData[3].contributionToHealthServicesFundBoxE : 0.00,
        id : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[3].id,
        qpipPremiumsBoxC : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[3].qpipPremiumsBoxC !== null ? statementofDutiesPaidorPayableData[3].qpipPremiumsBoxC : 0.00,
        qppContributionBoxB : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[3].qppContributionBoxB !== null ? statementofDutiesPaidorPayableData[3].qppContributionBoxB : 0.00,
        quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[3].quebecIncomeTaxBoxD !== null ? statementofDutiesPaidorPayableData[3].quebecIncomeTaxBoxD : 0.00,
        renmittancePeriodBoxA : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[3].renmittancePeriodBoxA !== null ? statementofDutiesPaidorPayableData[3].renmittancePeriodBoxA : 0.00,
        totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[3].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
      }
       : undefined,
      periodNo9: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      // statementofDutiesPaidorPayableData[4]
      {
        contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[4].contributionToHealthServicesFundBoxE !== null ? statementofDutiesPaidorPayableData[4].contributionToHealthServicesFundBoxE : 0.00,
        id : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[4].id,
        qpipPremiumsBoxC : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[4].qpipPremiumsBoxC !== null ? statementofDutiesPaidorPayableData[4].qpipPremiumsBoxC : 0.00,
        qppContributionBoxB : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[4].qppContributionBoxB !== null ? statementofDutiesPaidorPayableData[4].qppContributionBoxB : 0.00,
        quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[4].quebecIncomeTaxBoxD !== null ? statementofDutiesPaidorPayableData[4].quebecIncomeTaxBoxD : 0.00,
        renmittancePeriodBoxA : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[4].renmittancePeriodBoxA !== null ? statementofDutiesPaidorPayableData[4].renmittancePeriodBoxA : 0.00,
        totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[4].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
      }
       : undefined,
      periodNo10: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      // statementofDutiesPaidorPayableData[5]
      {
        contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[5].contributionToHealthServicesFundBoxE !== null ? statementofDutiesPaidorPayableData[5].contributionToHealthServicesFundBoxE : 0.00,
        id : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[5].id,
        qpipPremiumsBoxC : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[5].qpipPremiumsBoxC !== null ? statementofDutiesPaidorPayableData[5].qpipPremiumsBoxC : 0.00,
        qppContributionBoxB : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[5].qppContributionBoxB !== null ? statementofDutiesPaidorPayableData[5].qppContributionBoxB : 0.00,
        quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[5].quebecIncomeTaxBoxD !== null ? statementofDutiesPaidorPayableData[5].quebecIncomeTaxBoxD : 0.00,
        renmittancePeriodBoxA : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[5].renmittancePeriodBoxA !== null ? statementofDutiesPaidorPayableData[5].renmittancePeriodBoxA : 0.00,
        totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[5].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
      }
       : undefined,
      periodNo11: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      // statementofDutiesPaidorPayableData[6]
      {
        contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[6].contributionToHealthServicesFundBoxE !== null ? statementofDutiesPaidorPayableData[6].contributionToHealthServicesFundBoxE : 0.00,
        id : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[6].id,
        qpipPremiumsBoxC : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[6].qpipPremiumsBoxC !== null ? statementofDutiesPaidorPayableData[6].qpipPremiumsBoxC : 0.00,
        qppContributionBoxB : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[6].qppContributionBoxB !== null ? statementofDutiesPaidorPayableData[6].qppContributionBoxB : 0.00,
        quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[6].quebecIncomeTaxBoxD !== null ? statementofDutiesPaidorPayableData[6].quebecIncomeTaxBoxD : 0.00,
        renmittancePeriodBoxA : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[6].renmittancePeriodBoxA !== null ? statementofDutiesPaidorPayableData[6].renmittancePeriodBoxA : 0.00,
        totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[6].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
      }
       : undefined,
      periodNo12: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      // statementofDutiesPaidorPayableData[7]
      {
        contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[7].contributionToHealthServicesFundBoxE !== null ? statementofDutiesPaidorPayableData[7].contributionToHealthServicesFundBoxE : 0.00,
        id : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[7].id,
        qpipPremiumsBoxC : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[7].qpipPremiumsBoxC !== null ? statementofDutiesPaidorPayableData[7].qpipPremiumsBoxC : 0.00,
        qppContributionBoxB : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[7].qppContributionBoxB !== null ? statementofDutiesPaidorPayableData[7].qppContributionBoxB : 0.00,
        quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[7].quebecIncomeTaxBoxD !== null ? statementofDutiesPaidorPayableData[7].quebecIncomeTaxBoxD : 0.00,
        renmittancePeriodBoxA : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[7].renmittancePeriodBoxA !== null ? statementofDutiesPaidorPayableData[7].renmittancePeriodBoxA : 0.00,
        totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[7].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
      }
       : undefined,
      periodNo13: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      // statementofDutiesPaidorPayableData[8]
      {
        contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[8].contributionToHealthServicesFundBoxE !== null ? statementofDutiesPaidorPayableData[8].contributionToHealthServicesFundBoxE : 0.00,
        id : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[8].id,
        qpipPremiumsBoxC : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[8].qpipPremiumsBoxC !== null ? statementofDutiesPaidorPayableData[8].qpipPremiumsBoxC : 0.00,
        qppContributionBoxB : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[8].qppContributionBoxB !== null ? statementofDutiesPaidorPayableData[8].qppContributionBoxB : 0.00,
        quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[8].quebecIncomeTaxBoxD !== null ? statementofDutiesPaidorPayableData[8].quebecIncomeTaxBoxD : 0.00,
        renmittancePeriodBoxA : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[8].renmittancePeriodBoxA !== null ? statementofDutiesPaidorPayableData[8].renmittancePeriodBoxA : 0.00,
        totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[8].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
      }
       : undefined,
      periodNo14: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      // statementofDutiesPaidorPayableData[9]
      {
        contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[9].contributionToHealthServicesFundBoxE !== null ? statementofDutiesPaidorPayableData[9].contributionToHealthServicesFundBoxE : 0.00,
        id : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[9].id,
        qpipPremiumsBoxC : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[9].qpipPremiumsBoxC !== null ? statementofDutiesPaidorPayableData[9].qpipPremiumsBoxC : 0.00,
        qppContributionBoxB : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[9].qppContributionBoxB !== null ? statementofDutiesPaidorPayableData[9].qppContributionBoxB : 0.00,
        quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[9].quebecIncomeTaxBoxD !== null ? statementofDutiesPaidorPayableData[9].quebecIncomeTaxBoxD : 0.00,
        renmittancePeriodBoxA : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[9].renmittancePeriodBoxA !== null ? statementofDutiesPaidorPayableData[9].renmittancePeriodBoxA : 0.00,
        totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[9].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
      }
       : undefined,
      periodNo15: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      // statementofDutiesPaidorPayableData[10]
      {
        contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[10].contributionToHealthServicesFundBoxE !== null ? statementofDutiesPaidorPayableData[10].contributionToHealthServicesFundBoxE : 0.00,
        id : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[10].id,
        qpipPremiumsBoxC : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[10].qpipPremiumsBoxC !== null ? statementofDutiesPaidorPayableData[10].qpipPremiumsBoxC : 0.00,
        qppContributionBoxB : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[10].qppContributionBoxB !== null ? statementofDutiesPaidorPayableData[10].qppContributionBoxB : 0.00,
        quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[10].quebecIncomeTaxBoxD !== null ? statementofDutiesPaidorPayableData[10].quebecIncomeTaxBoxD : 0.00,
        renmittancePeriodBoxA : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[10].renmittancePeriodBoxA !== null ? statementofDutiesPaidorPayableData[10].renmittancePeriodBoxA : 0.00,
        totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[10].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
      }
       : undefined,
      periodNo16: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      // statementofDutiesPaidorPayableData[11]
      {
        contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[11].contributionToHealthServicesFundBoxE !== null ? statementofDutiesPaidorPayableData[11].contributionToHealthServicesFundBoxE : 0.00,
        id : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[11].id,
        qpipPremiumsBoxC : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[11].qpipPremiumsBoxC !== null ? statementofDutiesPaidorPayableData[11].qpipPremiumsBoxC : 0.00,
        qppContributionBoxB : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[11].qppContributionBoxB !== null ? statementofDutiesPaidorPayableData[11].qppContributionBoxB : 0.00,
        quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[11].quebecIncomeTaxBoxD !== null ? statementofDutiesPaidorPayableData[11].quebecIncomeTaxBoxD : 0.00,
        renmittancePeriodBoxA : statementofDutiesPaidorPayableData && statementofDutiesPaidorPayableData[11].renmittancePeriodBoxA !== null ? statementofDutiesPaidorPayableData[11].renmittancePeriodBoxA : 0.00,
        totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[11].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
      }
       : undefined,
      periodNo17: taxYear > 2023 && statementofDutiesPaidorPayableData ? 
      // statementofDutiesPaidorPayableData[12] 
            {
              contributionToHealthServicesFundBoxE : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[12].contributionToHealthServicesFundBoxE.replace(/[$,]/g, '')),
              id : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[12].id.replace(/[$,]/g, '')),
              qpipPremiumsBoxC : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[12].qpipPremiumsBoxC.replace(/[$,]/g, '')),
              qppContributionBoxB : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[12].qppContributionBoxB.replace(/[$,]/g, '')),
              quebecIncomeTaxBoxD : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[12].quebecIncomeTaxBoxD.replace(/[$,]/g, '')),
              renmittancePeriodBoxA : "Total of amounts withheld and contributions",
              totalForTheRemittancePeriodBoxF : statementofDutiesPaidorPayableData && parseFloat(statementofDutiesPaidorPayableData[12].totalForTheRemittancePeriodBoxF.replace(/[$,]/g, ''))
            }
      : undefined
    };
    // submitFlexFields(formData);
    submitFormData(Payload);
    console.log("formData ========== ", formData);
  };

  const onCloseHandler = () => {
    setInvoiceDialogOpen(false);
  };

  const savePdfAsFile = (pdfData: any, filename: any) => {
    const blob = new Blob([pdfData], { type: "pdf;charset=utf-8" });
    FileSaver.saveAs(blob, filename);
  };
  const onPrintClicked = async (event: any): Promise<void> => {
    setIsLoadingData(true);
    const maskedStatus: any = sessionStorage.getItem("maskEnabled");
    if (maskedStatus === "true") {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(rl1SlipIds && rl1SlipIds.generalAuditStatus !== null && rl1SlipIds.generalAuditStatus === true &&
              rl1SlipIds && rl1SlipIds.auditStatus !== null &&  rl1SlipIds.auditStatus === true){
              setIsLoadingData(true);
                try {
                  const response: any =
                    await PrintingService.createRL1SummaryPdfPrint(
                      clientId,
                      400,
                      taxYear,
                      event === "Print PDF (English)"
                        ? "rl1-english"
                        : "rl1-french"
                    );
                  if (response.status === 400) {
                    setSnackbarData({
                      open: true,
                      message: response.data.message
                        ? response.data.message
                        : "Something went wrong.",
                      // message: "Please Unmask sensitive data for PDF printing.",
                      severity: "error",
                    });
                  } else {
                    const filename = `RL1Summary-${clientName}-${
                      event === "Print PDF (English)" ? "English" : "French"
                    }.pdf`;
                    savePdfAsFile(response, filename);
                  }
                  setIsLoadingData(false);
                } catch (err) {
                  console.log("err", err);
                  alert(err);
                }
              // }
              setIsLoadingData(false);
            } else {
              setIsLoadingData(false);
              setSnackbarData({
              open: true,
              message: (
                <>
                  Please load Audit in the menu, press Run RL-1 Audit and clear all audit items to generate documents.{" "}
                  <a
                    href="https://appv3.taxslips.com/#/audit/filing"
                    style={{
                      color: "#aa1010",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Click here to view
                  </a>
                </>
              ),
              severity: "error",
              autoHide: false,
            });
          }

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (response.message === "Slips not found for invoice.") {
          // setIsLoadingData(true);
          // try {
          //   const filingAuditResponse: any =
          //     await RL1SlipService.getRL1AuditFiling(clientId, taxYear);
          //   console.log("filingAuditResponse", filingAuditResponse);
          //   if (
          //     filingAuditResponse &&
          //     filingAuditResponse.general_filling &&
          //     filingAuditResponse.general_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.general_filling",
          //       filingAuditResponse.general_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else if (
          //     filingAuditResponse &&
          //     filingAuditResponse.rl1_filling &&
          //     filingAuditResponse.rl1_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.rl1_filling",
          //       filingAuditResponse.rl1_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else {
          //     setIsLoadingData(true);
          if(rl1SlipIds && rl1SlipIds.generalAuditStatus !== null && rl1SlipIds.generalAuditStatus === true &&
            rl1SlipIds && rl1SlipIds.auditStatus !== null &&  rl1SlipIds.auditStatus === true){
            setIsLoadingData(true);
              try {
                const response: any =
                  await PrintingService.createRL1SummaryPdfPrint(
                    clientId,
                    400,
                    taxYear,
                    event === "Print PDF (English)"
                      ? "rl1-english"
                      : "rl1-french"
                  );
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    // message: "Please Unmask sensitive data for PDF printing.",
                    severity: "error",
                  });
                } else {
                  const filename = `RL1Summary-${clientName}-${
                    event === "Print PDF (English)" ? "English" : "French"
                  }.pdf`;
                  savePdfAsFile(response, filename);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
                alert(err);
              }
            // }
            setIsLoadingData(false);
          } else {
            setIsLoadingData(false);
            setSnackbarData({
            open: true,
            message: (
              <>
                Please load Audit in the menu, press Run RL-1 Audit and clear all audit items to generate documents.{" "}
                <a
                  href="https://appv3.taxslips.com/#/audit/filing"
                  style={{
                    color: "#aa1010",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Click here to view
                </a>
              </>
            ),
            severity: "error",
            autoHide: false,
          });
        }
          // } catch (err: any) {
          //   setIsLoadingData(false);
          //   alert(err);
          // }
        } else if (response && response.id !== null) {
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    }
    }
  };

  const onDeleteClicked = async (): Promise<void> => {
    //delete confirmation code
    try {
      setIsLoadingData(true);
      const response: any =
        await RL1SlipService.rl1SummaryclearConfirmationCode(
          clientId,
          checkSummaryType(rl1SummaryData && rl1SummaryData.slipType),
          taxYear
        );
      console.log("response64", response);
      if (response.status === 200) {
        setSnackbarData({
          open: true,
          message: response.data.message,
          severity: "success",
        });
        refetchSlipType()
      } else {
        setSnackbarData({
          open: true,
          message:
            response &&
            response.message !== undefined &&
            typeof (response.message && response.message) !== "object"
              ? response.message && response.message
              : response.message.message
              ? response.message.message
              : "Something went wrong!",
          severity:
            response.status === false
              ? "error"
              : response.success === false
              ? "error"
              : "success",
        });
      }
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log("err", err);
    }
  };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // set the values of the selected flex field coming from BE.
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (selectedFlexFieldData) {
  //     Object.keys(selectedFlexFieldData).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = selectedFlexFieldData[fieldName];
  //       // @ts-ignore
  //       formMethods.setValue(fieldName, fieldValue);
  //     });
  //   }
  // }, [selectedFlexFieldData, formMethods.setValue, formMethods]);

  // // if we have selected the parent and a flex field and they both are the same, we should un-set the parent because a field cannot be the parent of itself
  // useEffect(() => {
  //   if (
  //     selectedFlexFieldId &&
  //     selectedParent &&
  //     selectedParent === selectedFlexFieldId
  //   ) {
  //     formMethods.setValue("parent", undefined);
  //   }
  // }, [formMethods, selectedFlexFieldId, selectedParent]);

  useEffect(() => {
    console.log("===-use Effect called");
    // if the selected flex field data has loaded.
    if (rl1SummaryData) {
      Object.keys(rl1SummaryData).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = rl1SummaryData[fieldName];
        // @ts-ignore
        formMethods.setValue(fieldName, fieldValue ?? undefined);

        // set all values to decimal
        if (
          fieldName === "qppContributionsQpipPremiumsIncomeTaxRemittedAmount"
        ) {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (
          fieldName === "totalPayrollForThePurposeOfDeterminingContributionRate"
        ) {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "salaryAndWagesSubjectToTheContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "exemptSalaryAndWages") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (
          fieldName === "contributionToTheHealthServicesFundRemittedInTheYear"
        ) {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "remunerationSubjectToContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "totalPayroll") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (
          fieldName ===
          "balanceOfEligibleTrainingExpendituresCarriedForwardFromPreviousYears"
        ) {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "eligibleTrainingExpendituresForTheCurrentYear") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (
          fieldName ===
          "amountUsedToReduceOrCancelTheContributionOnLine51CarryThisAmountToLine52"
        ) {
          fieldValue !== null || undefined
            ? formMethods.setValue("amountReduced", parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "primaryAndManufacturingSectors") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "qppEmployeeContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "secondQppEmployeeContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "qppEmployerContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "secondQppEmployerContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "qpipEmployeePremiums") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "qpipEmployerPremiums") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "quebecIncomeTax") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "balance") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "contributionRateIn") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (
          fieldName === "contributionToTheHealthServicesFundBeforeTheReduction"
        ) {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "balanceHsf") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "cntRate") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "cntAmount") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
            setCntAmountWhileUpdate(fieldValue !== null || undefined
              ? parseFloat(fieldValue).toFixed(2)
              : "0.00")
        }
        if (fieldName === "wsdfrRate") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "wsdfrAmount") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");

            if(fieldValue !== "" && fieldValue !==  0){
              setTotalPayroll(fieldValue)
            }
        }
        if (fieldName === "balanceWc") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        // if (fieldName === "periodNo5") {
        //   setStatementofDutiesPaidorPayableData((prevData:any) =>
        //       prevData.map((item:any, index:any) =>
        //         index === 1 ? fieldValue : item
        //       )
        //     )
        // }
        if (fieldName === "periodNo5" && fieldValue !== null && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                // {
                index === 0 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  qppContributionBoxB:fieldValue.qppContributionBoxB,
                  qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
                //   console.log("index55",index)
                //   console.log("index55",item)
                // }
              )
              // prevData.map((item:any, i:any) => (i === 0 ? fieldValue  : item))
            )
        }
        if (fieldName === "periodNo6" && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                index === 1 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  qppContributionBoxB:fieldValue.qppContributionBoxB,
                  qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
              )
            )
        }
        if (fieldName === "periodNo7" && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                index === 2 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  qppContributionBoxB:fieldValue.qppContributionBoxB,
                  qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
              )
            )
        }
        if (fieldName === "periodNo8" && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                index === 3 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  qppContributionBoxB:fieldValue.qppContributionBoxB,
                  qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
              )
            )
        }
        if (fieldName === "periodNo9" && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                index === 4 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  qppContributionBoxB:fieldValue.qppContributionBoxB,
                  qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
              )
            )
        }
        if (fieldName === "periodNo10" && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                index === 5 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  qppContributionBoxB:fieldValue.qppContributionBoxB,
                  qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
              )
            )
        }
        if (fieldName === "periodNo11" && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                index === 6 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  qppContributionBoxB:fieldValue.qppContributionBoxB,
                  qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
              )
            )
        }
        if (fieldName === "periodNo12" && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                index === 7 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  qppContributionBoxB:fieldValue.qppContributionBoxB,
                  qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
              )
            )
        }
        if (fieldName === "periodNo13" && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                index === 8 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  qppContributionBoxB:fieldValue.qppContributionBoxB,
                  qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
              )
            )
        }
        if (fieldName === "periodNo14" && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                index === 9 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  qppContributionBoxB:fieldValue.qppContributionBoxB,
                  qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
              )
            )
        }
        if (fieldName === "periodNo15" && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                index === 10 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  qppContributionBoxB:fieldValue.qppContributionBoxB,
                  qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
              )
            )
        }
        if (fieldName === "periodNo16" && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                index === 11 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  qppContributionBoxB:fieldValue.qppContributionBoxB,
                  qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
              )
            )
        }
        if (fieldName === "periodNo17" && fieldValue !== null) {
          console.log("fieldNamefieldName",fieldName)
          console.log("fieldNamefieldName",fieldValue)
          console.log("statementofDutiesPaidorPayableData-=1133",statementofDutiesPaidorPayableData)
          setStatementofDutiesPaidorPayableDataInGetById((prevData:any) =>
              prevData.map((item:any, index:any) => 
                index === 12 ? {
                  id:item.id,
                  renmittancePeriodBoxA:item.renmittancePeriodBoxA,
                  // qppContributionBoxB:fieldValue.qppContributionBoxB,
                  // qpipPremiumsBoxC:fieldValue.qpipPremiumsBoxC,
                  // quebecIncomeTaxBoxD:fieldValue.quebecIncomeTaxBoxD,
                  // contributionToHealthServicesFundBoxE:fieldValue.contributionToHealthServicesFundBoxE,
                  qppContributionBoxB:parseFloat(fieldValue.qppContributionBoxB).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  qpipPremiumsBoxC:parseFloat(fieldValue.qpipPremiumsBoxC).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  quebecIncomeTaxBoxD:parseFloat(fieldValue.quebecIncomeTaxBoxD).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  contributionToHealthServicesFundBoxE:parseFloat(fieldValue.contributionToHealthServicesFundBoxE).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  totalForTheRemittancePeriodBoxF:parseFloat(fieldValue.totalForTheRemittancePeriodBoxF).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } : item
              )
            )
        }
        console.log("statementofDutiesPaidorPayableData-=11",statementofDutiesPaidorPayableData)
      });
    }
  }, [rl1SummaryData, formMethods.setValue, formMethods]);

  console.log("wsdfrRate22", formMethods.getValues("wsdfrRate"));

  const onUnPaidClickHandler = (clickedRow: any, tag:string) => {
    setIsLoadingData(true);
    // setTimeout(() => {
    //   setIsLoadingData(false);
    // }, 2500);
    const fetchData = async () => {
      try {
        const response: any =
          await ReportsService.paypalIntegrationOfUnpaidInvoice(
            clientId,
            clickedRow.refNo,
            tag
          );
        setIsLoadingData(false);
        console.log("responseData111-----------------", response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const onClickInvoiceNoHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const [totalPayroll, setTotalPayroll] = useState(0);
  console.log("enabledFieldValue", totalPayroll);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: RL1_SUMMARY_SCREEN_SLUG,
        companyName: clientName,
        activityType:
          "activity_type=updated&activity_type=delete&activity_type=print",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={
          // isLoadingSelectedFlexField ||
          isSubmitting ||
          isLoadingSummaryGetData ||
          reFetchingSlipType ||
          isLoadingGlobalVariables ||
          isLoadingData ||
          isLoadingActivityLogData
          // isLoadingFlexFieldsList
        }
        testId={testId}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            toolbar={
              <RL1SummaryToolbar
                isSubmitting={isSubmitting}
                onPrintClicked={onPrintClicked}
                onDeleteClicked={onDeleteClicked}
              />
            }
            testId="t4-slip-summary-setup-form"
          >
            <Paper>
              <FlatStandardPanel
                title="Account Information"
                testId={`${testId}-account-information-panel`}
                showTitle={false}
                topPadding={3}
                leftSpacing={2}
                rightSpacing={2}
              >
                <AccountInformation
                  logoURL={`${process.env.PUBLIC_URL}/images/rq_logo.svg`}
                  // accountIdentifierTitle="Payer's account number"
                  // accountIdentifier="121212120RP0001"
                  // accountInformationMessage="You have to file your T4A information return on or before the last day of February."
                  // accountName="HELLOFRESH"
                  // accountAddressLine1="House # 34, Street twenty three Link Road Cavalary Ground"
                  // accountCity=" Toronto"
                  // accountProvince="ON"
                  // accountPostalCode="W3R4T5"
                />
              </FlatStandardPanel>
              <FlatStandardPanel
                title="RL-1 Summary"
                testId={`${testId}-t4-slip-detial-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <RL1SlipDetailPanel
                  formMethods={formMethods}
                  summaryResponse={rl1SummaryData}
                  taxYear={taxYear}
                />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="Health Services Fund"
                testId={`${testId}-difference-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <HealthServiceFundPanel
                  formMethods={formMethods}
                  globalSetingsRes={
                    globalVariablesData && globalVariablesData?.data?.rl1
                  }
                />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="Contribution to the Financing of the CNT"
                testId={`${testId}-total-balance-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <CNTFinancingContributionPanel
                  cntRate={
                    globalVariablesData &&
                    globalVariablesData?.data?.rl1?.rl1SettingsCntRatePercentage
                  }
                  secondCntRate={
                    globalVariablesData &&
                    globalVariablesData?.data?.rl1?.rl1SettingsSecondCntRatePercentage
                  }
                  formMethods={formMethods}
                  cntAmountWhileUpdate={cntAmountWhileUpdate}
                  taxYear={taxYear}
                  employerCode={rl1SummaryData && rl1SummaryData?.employerCode}
                />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="Contribution to the WSDRF"
                testId={`${testId}-statutory-contributions-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <WSDRFContributionPanel
                  formMethods={formMethods}
                  wsdrfRate={
                    globalVariablesData &&
                    globalVariablesData?.data?.rl1
                      ?.rl1SettingsWsdrfRatePercentage
                  }
                  onValueChange={(newValue) => setTotalPayroll(newValue)}
                />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="Work Chart – Eligible Training Expenditures"
                testId={`${testId}-statutory-contributions-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <EligibleTrainingExpendituresPanel
                  totalPayroll={totalPayroll}
                  amountReducedGetById={rl1SummaryData && rl1SummaryData?.amountUsedToReduceOrCancelTheContributionOnLine51CarryThisAmountToLine52}
                  formMethods={formMethods}
                />
              </FlatStandardPanel>
              <Spacer y={4} x={4} />
            </Paper>
          </SingleColumnLayout>
          {taxYear > 2023 ? <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
              <StandardPanel
                        title="Statement of duties paid or payable"
                        testId={`${testId}-slip-data`}
                        topPadding={2}
                      >
                        <StatementOfDutiesPaidOrPayable 
                        statementofDutiesPaidorPayableData={(data:any) => setStatementofDutiesPaidorPayableData(data)}
                        statementofDutiesPaidorPayableDataInGetById={rl1SummaryData && taxYear > 2023 ? statementofDutiesPaidorPayableDataInGetById : undefined}
                        />
                </StandardPanel>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
          </Grid>:<></>}
        </form>
      </FormProvider>
      {/* invoice dialogu open if any transaction is unPaid */}
      <EnhancedDialog
        dialogTitle="Invoice(s) Due"
        key="dialog-box"
        testId="dialog"
        onCloseHandler={onCloseHandler}
        open={openInvoiceDialog}
        dialogContent={
          <InvoiceDialogPanel
            invoiceData={invoiceData}
            onUnPaidClickHandler={onUnPaidClickHandler}
            onClickInvoiceNoHandler={onClickInvoiceNoHandler}
          />
        }
        dialogAction={
          <Grid>
            <Button autoFocus variant="contained" onClick={onCloseHandler}>
              Cancel
            </Button>
          </Grid>
        }
      />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
        autoHide={snackbarData.autoHide}
      />
    </Grid>
  );
};
