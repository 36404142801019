import { INITIAL_PAGE_SIZE, INITIAL_SNACKBAR_DATA } from "common/constants";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";
import { BackdropCircularProgress, EnhancedSnackbar } from "@websential/cosmic";

import { ClientAdjustmentReportDataTable } from "./PrimeDataGrid";
import { TaxSlipYearService } from "services";
import { ReportsService } from "services/ReportsService";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { IAppState } from "store/reducers";

export interface IClientAdjustmentReport {}

const useClientAdjustmentReport = (
  params: IGetListRequest
) =>
  useQuery(
    ["client-adjustment-report", params],
    (data) => {
      return ReportsService.getClientAdjustmentReport(params);
    },
    { refetchOnWindowFocus: false }
  );
  
const useYears = (params: IGetListRequest) =>
  useQuery(
    ["years-list", params],
    (data) => {
      return TaxSlipYearService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

export const ClientAdjustmentReport: React.FC<IClientAdjustmentReport> = () => {
  const dispatch = useDispatch();
  const accountantId = useSelector((state:IAppState) => state.accountant.id)
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const [queryParams, setQueryParams] = useState<any>({
    search_query: "",
    order_by: "client_name",
    order_direction: "asc",
    page: 1,
    size: 25,
    accountantId: accountantId,
    // invoiceselected: 400,
    yearselected: 2024,
  });

  const { isLoading, data:clientAdjustmentReport } = useClientAdjustmentReport(queryParams);
  const clientAdjustmentReportList: any = clientAdjustmentReport;
  console.log("@@@@@ => IclientAdjustmentReport", clientAdjustmentReportList);

  // const {
  //   isLoading: isLoadingAccountantDropDownList,
  //   data: accountantDDLList,
  //   // refetch: refetchTabsDDL,
  // } = useGetAccountantsDropDownList();

  const {
    isLoading: isLoadingYearsList,
    data: yearsDDLList,
  } = useYears({
    search_query: "",
    order_by: "client_name",
    order_direction: "asc",
    page: 1,
    size: 25,
  });
  const yearsDDLItems = useMemo(() => {
    if (yearsDDLList && Array.isArray(yearsDDLList && yearsDDLList.items)) {
      return (
        yearsDDLList &&
        yearsDDLList.items.map((year: any) => ({
          id: year.id,
          label: year.year,
          value: year.year,
        }))
      );
    }

    // Return empty array in case of API data fetching error
    return [];
  }, [yearsDDLList]);
  console.log("accountantDDLItemsqw", yearsDDLList);
  console.log("accountantDDLItems", yearsDDLItems);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: "client_name",
      order_direction: "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
      accountantId: accountantId,
      yearselected: requestParams.yearselected,
    };
    console.log("dispatchParams", dispatchParams);

    setQueryParams(dispatchParams);
  }, []);

  console.log("queryParams", queryParams);

  let data = {
    "page": 1,
    "size": 3,
    "total": 3,
    "items": [
      {
        "clientName": "Zeeshan Malik",
        "totalEmployees": 4,
        "t4Ajustmentamount": 2,
        "rl1Ajustmentamount": "N/A"
      },
      {
        "clientName": "Bilal Khan",
        "totalEmployees": "N/A",
        "t4Ajustmentamount": 7,
        "rl1Ajustmentamount": 1
      },
      {
       "clientName": "Alexandra Daddario",
        "totalEmployees": 6,
        "t4Ajustmentamount": 9,
        "rl1Ajustmentamount": 1
      }
    ],
    "headerColumns": [
      {
        "field": "clientName",
        "headerName": "Client Name",
        "description": "Client Name",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 0,
        "frozenColumn": false,
        "footer": null
      },
      {
        "field": "totalEmployees",
        "headerName": "Total Employees",
        "description": "Total Employees",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 1,
        "frozenColumn": false,
        "footer": null
      },
      {
        "field": "t4AdjustmentAmount",
        "headerName": "T4 Adjustment Amount",
        "description": "T4 Adjustment Amount",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 2,
        "frozenColumn": false,
        "footer": null
      },
      {
        "field": "rl1AdjustmentAmount",
        "headerName": "RL-1 Adjustment Amount",
        "description": "RL-1 Adjustment Amount",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 3,
        "frozenColumn": false,
        "footer": null
      }
    ]
  }

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: '',
        companyName: '',
        activityType:
          "",
        activityLogVisibility: false,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ width: "100%", overflow: "hidden" }}
    >
      <BackdropCircularProgress open={false} testId="ClientAdjustmentReport" />
      <ClientAdjustmentReportDataTable
        rows={clientAdjustmentReport && clientAdjustmentReport?.items}
        // rows={data.items}
        // columns={clientAdjustmentReport ? clientAdjustmentReport?.headerColumns : []}
        columns={data.headerColumns}
        showIndexColumn={true}
        initialPageSize={INITIAL_PAGE_SIZE}
        totalRecords={clientAdjustmentReport ? clientAdjustmentReport?.total : 0}
        // totalRecords={data.total}
        onRequestParamsChange={onRequestParamsChange}
        yearsDDL={yearsDDLItems}
        isLoading={isLoading}
      />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`ClientAdjustmentReport-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
