import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  ITest,
  StandardPanel,
} from "@websential/cosmic";
import { Box, Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { INITIAL_SNACKBAR_DATA, QUERY_OPTIONS } from "common/constants";
import { useDispatch, useSelector } from "react-redux";

import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import { AuditAlert } from "../AuditAlert";
import { IAppState } from "store/reducers";
import { T4SlipService } from "services/T4SlipService";
import { queryClient } from "index";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { useQuery } from "react-query";

export interface IAuditFilingSetup extends ITest {}

const GET_AUDIT_FILING_QUERY_KEY = "get-audit-filing";

// const useAuditFiling = (clientId: string,taxYear:number) =>
//   useQuery(
//     [GET_AUDIT_FILING_QUERY_KEY],
//     (data) => {
//       return AdjustmentOptionsService.getAuditFiling(clientId,taxYear);
//     },
//     { refetchOnWindowFocus: false }
//   );


const useGeneralAuditFiling = (clientId: string,taxYear:number) =>
  useQuery(
    ["get-general-audit"],
    (data) => {
      return AdjustmentOptionsService.getGeneralAuditFiling(clientId,taxYear);
    },
    { refetchOnWindowFocus: false }
  );

const useAuditFilingStatusAndTime = (clientId: string,taxYear:number) =>
  useQuery(
    [GET_AUDIT_FILING_QUERY_KEY],
    (data) => {
      return AdjustmentOptionsService.getAuditFilingStatusAndTime(clientId,taxYear);
    },
    { refetchOnWindowFocus: false }
  );


const formatDateWithTime = (date:any) => {
    return date.toLocaleString('en-US', {
        month: 'short', // Short month format (e.g., "Jan")
        day: '2-digit', // Two-digit day format
        year: 'numeric', // Four-digit year
        hour: '2-digit', // Two-digit hour format
        minute: '2-digit', // Two-digit minute format
        second: '2-digit', // Two-digit second format
        hour12: false // 24-hour clock format
    });
};

export const AuditFilingSetup: FC<IAuditFilingSetup> = ({ testId }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [auditFilingStatusAndTimeData, setAuditFilingStatusAndTimeData] = useState<any>(undefined);
  // const [generalAuditFilingData, setGeneralAuditFilingData] = useState<any>(undefined);
  // const [t4AuditFilingData, setT4AuditFilingData] = useState<any>(undefined);
  // const [t4aAuditFilingData, setT4aAuditFilingData] = useState<any>(undefined);
  // const [rl1AuditFilingData, setRL1AuditFilingData] = useState<any>(undefined);
  // const [t5AuditFilingData, setT5AuditFilingData] = useState<any>(undefined);
  // const [rl3AuditFilingData, setRL3AuditFilingData] = useState<any>(undefined);
  // const [t3AuditFilingData, setT3AuditFilingData] = useState<any>(undefined);
  // const [rl16AuditFilingData, setRL16AuditFilingData] = useState<any>(undefined);

  const [generalAuditFilingData, setGeneralAuditFilingData] = useState<any>({
    general_filling: [],
    general_warnings: [],
  });

  const [t4AuditFilingData, setT4AuditFilingData] = useState<any>({
    t4_filling: [],
    t4_warnings: [],
  });

  const [t4aAuditFilingData, setT4aAuditFilingData] = useState<any>({
    t4a_filling: [],
    t4a_warnings: [],
  });

  const [rl1AuditFilingData, setRL1AuditFilingData] = useState<any>({
    rl1_filling: [],
    rl1_warnings: [],
  });

  const [t5AuditFilingData, setT5AuditFilingData] = useState<any>({
    t5_filling: [],
    t5_warnings: [],
  });

  const [rl3AuditFilingData, setRL3AuditFilingData] = useState<any>({
    rl3_filling: [],
    rl3_warnings: [],
  });

  const [t3AuditFilingData, setT3AuditFilingData] = useState<any>({
    t3_filling: [],
    t3_warnings: [],
  });

  const [rl16AuditFilingData, setRL16AuditFilingData] = useState<any>({
    rl16_filling: [],
    rl16_warnings: [],
  });


  // status
  const [generalAuditFilingStatus, setGeneralAuditFilingStatus] = useState<any>(false);
  const [t4AuditFilingStatus, setT4AuditFilingStatus] = useState<any>(false);
  const [t4aAuditFilingStatus, setT4aAuditFilingStatus] = useState<any>(false);
  const [rl1AuditFilingStatus, setRL1AuditFilingStatus] = useState<any>(false);
  const [t5AuditFilingStatus, setT5AuditFilingStatus] = useState<any>(false);
  const [rl3AuditFilingStatus, setRL3AuditFilingStatus] = useState<any>(false);
  const [t3AuditFilingStatus, setT3AuditFilingStatus] = useState<any>(false);
  const [rl16AuditFilingStatus, setRL16AuditFilingStatus] = useState<any>(false);

  // time
  const [generalAuditFilingTime, setGeneralAuditFilingTime] = useState<any>(undefined);
  const [t4AuditFilingTime, setT4AuditFilingTime] = useState<any>(undefined);
  const [t4aAuditFilingTime, setT4aAuditFilingTime] = useState<any>(undefined);
  const [rl1AuditFilingTime, setRL1AuditFilingTime] = useState<any>(undefined);
  const [t5AuditFilingTime, setT5AuditFilingTime] = useState<any>(undefined);
  const [rl3AuditFilingTime, setRL3AuditFilingTime] = useState<any>(undefined);
  const [t3AuditFilingTime, setT3AuditFilingTime] = useState<any>(undefined);
  const [rl16AuditFilingTime, setRL16AuditFilingTime] = useState<any>(undefined);


//   const { isLoading: isLoadingAuditFiling, data: auditFiling } =
//     useAuditFiling(clientId,taxYear);


  const { isLoading: isLoadingGeneralAuditFiling, data: generalAuditFiling } =
  useGeneralAuditFiling(clientId,taxYear);


  const { isLoading: isLoadingAuditFilingStatusAndTime, data: auditFilingStatusAndTime } =
    useAuditFilingStatusAndTime(clientId,taxYear);

//     console.log("auditFiling--",auditFiling)
//     console.log("isLoadingAuditFiling--",isLoadingAuditFiling)

// console.log("auditFilingData==",auditFilingData)
  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // set the values of the selected flex field coming from BE.
  // useEffect(() => {}, []);

  // const alertsWithWarnings = [
  //   "Email is missing",
  //   "Social Insurance No. is missing",
  // ];
  // const emptyAlerts: string[] = [];

  useEffect(() => {
  //   const fetchData = async () => {
  //     // Set loading to true when starting the API call
  //     setIsLoadingData(true);

  //     try {
  //       const response = await AdjustmentOptionsService.getAuditFiling(clientId,taxYear);
  //       setGeneralAuditFilingData(response)

  //       // Handle the response as needed, update auditFiling
  //     } catch (error) {
  //       setSnackbarData({
  //         open: true,
  //         message: "Something went wrong!",
  //         severity: "error",
  //       });
  //     } finally {
  //       // Set loading to false when the API call is completed (success or error)
  //       setIsLoadingData(false);
  //     }
  //   };

  //   fetchData();

  // get genral audit filing
    // const fetchData = async () => {
      // Set loading to true when starting the API call
      // try {
      // setIsLoadingData(true);
        // const response = await AdjustmentOptionsService.getGeneralAuditFiling(clientId,taxYear);
        // setGeneralAuditFilingData(response)
        // // setT4AuditFilingData(response)
        // setT4AuditFilingData((prevData:any) => ({
        //     ...prevData,
        //     t4_filling: [...prevData.t4_filling, ...response.t4_filling],
        //     t4_warnings: [...prevData.t4_warnings, ...response.t4_warnings],
        // }));

        // Handle the response as needed, update auditFiling
    //   } catch (error) {
    //     setSnackbarData({
    //       open: true,
    //       message: "Something went wrong!",
    //       severity: "error",
    //     });
    //   } finally {
    //     // Set loading to false when the API call is completed (success or error)
    //     setIsLoadingData(false);
    //   }
    // };

    // fetchData();


    if(generalAuditFiling){
      setGeneralAuditFilingData(generalAuditFiling)
      // setT4AuditFilingData(generalAuditFiling)
      // setT4AuditFilingData((prevData:any) => ({
      //     ...prevData,
      //     t4_filling: [...prevData.t4_filling, ...generalAuditFiling.t4_filling],
      //     t4_warnings: [...prevData.t4_warnings, ...generalAuditFiling.t4_warnings],
      // }));

      setT4AuditFilingData((prevData: any) => {
        const newT4Filling = [
            ...prevData.t4_filling,
            ...generalAuditFiling.t4_filling
        ].filter((value, index, self) => self.indexOf(value) === index);
    
        const newT4Warnings = [
            ...prevData.t4_warnings,
            ...generalAuditFiling.t4_warnings
        ].filter((value, index, self) => self.indexOf(value) === index);
    
        return {
            ...prevData,
            t4_filling: newT4Filling,
            t4_warnings: newT4Warnings
        };
      });
    }

  // define all initial status here 
  if(auditFilingStatusAndTime){
    setIsLoadingData(true);
    setGeneralAuditFilingStatus(auditFilingStatusAndTime && auditFilingStatusAndTime.general !== null && auditFilingStatusAndTime.general.auditStatus ? auditFilingStatusAndTime.general.auditStatus : undefined)
    setGeneralAuditFilingTime(auditFilingStatusAndTime && auditFilingStatusAndTime.general !== null && auditFilingStatusAndTime.general.lastCheck ? auditFilingStatusAndTime.general.lastCheck : undefined)

    setT4AuditFilingStatus(auditFilingStatusAndTime && auditFilingStatusAndTime.t4 !== null && auditFilingStatusAndTime.t4.auditStatus ? auditFilingStatusAndTime.t4.auditStatus : undefined)
    setT4AuditFilingTime(auditFilingStatusAndTime && auditFilingStatusAndTime.t4 !== null && auditFilingStatusAndTime.t4.lastCheck ? auditFilingStatusAndTime.t4.lastCheck : undefined)

    setT4aAuditFilingStatus(auditFilingStatusAndTime && auditFilingStatusAndTime.t4a !== null && auditFilingStatusAndTime.t4a.auditStatus ? auditFilingStatusAndTime.t4a.auditStatus : undefined)
    setT4aAuditFilingTime(auditFilingStatusAndTime && auditFilingStatusAndTime.t4a !== null && auditFilingStatusAndTime.t4a.lastCheck ? auditFilingStatusAndTime.t4a.lastCheck : undefined)

    setRL1AuditFilingStatus(auditFilingStatusAndTime && auditFilingStatusAndTime.rl1 !== null && auditFilingStatusAndTime.rl1.auditStatus ? auditFilingStatusAndTime.rl1.auditStatus : undefined)
    setRL1AuditFilingTime(auditFilingStatusAndTime && auditFilingStatusAndTime.rl1 !== null && auditFilingStatusAndTime.rl1.lastCheck ? auditFilingStatusAndTime.rl1.lastCheck : undefined)

    setT5AuditFilingStatus(auditFilingStatusAndTime && auditFilingStatusAndTime.t5 !== null && auditFilingStatusAndTime.t5.auditStatus ? auditFilingStatusAndTime.t5.auditStatus : undefined)
    setT5AuditFilingTime(auditFilingStatusAndTime && auditFilingStatusAndTime.t5 !== null && auditFilingStatusAndTime.t5.lastCheck ? auditFilingStatusAndTime.t5.lastCheck : undefined)

    setRL3AuditFilingStatus(auditFilingStatusAndTime && auditFilingStatusAndTime.rl3 !== null && auditFilingStatusAndTime.rl3.auditStatus ? auditFilingStatusAndTime.rl3.auditStatus : undefined)
    setRL3AuditFilingTime(auditFilingStatusAndTime && auditFilingStatusAndTime.rl3 !== null && auditFilingStatusAndTime.rl3.lastCheck ? auditFilingStatusAndTime.rl3.lastCheck : undefined)

    setT3AuditFilingStatus(auditFilingStatusAndTime && auditFilingStatusAndTime.t3 !== null && auditFilingStatusAndTime.t3.auditStatus ? auditFilingStatusAndTime.t3.auditStatus : undefined)
    setT3AuditFilingTime(auditFilingStatusAndTime && auditFilingStatusAndTime.t3 !== null && auditFilingStatusAndTime.t3.lastCheck ? auditFilingStatusAndTime.t3.lastCheck : undefined)

    setRL16AuditFilingStatus(auditFilingStatusAndTime && auditFilingStatusAndTime.rl16 !== null && auditFilingStatusAndTime.rl16.auditStatus ? auditFilingStatusAndTime.rl16.auditStatus : undefined)
    setRL16AuditFilingTime(auditFilingStatusAndTime && auditFilingStatusAndTime.rl16 !== null && auditFilingStatusAndTime.rl16.lastCheck ? auditFilingStatusAndTime.rl16.lastCheck : undefined)
    setIsLoadingData(false);
  }
  }, [clientId, taxYear, auditFilingStatusAndTime,generalAuditFiling]); // Include necessary dependencies for your API call

  // const onGeneralAuditFilingClickedHandler = () => {
  //   alert("clicked General Filing")
  // }
  
  const onT4AuditFilingClickedHandler = () => {
    // alert("clicked T4 Filing")
    setIsLoadingData(true);
    const fetchData = async () => {
      try {
        const response = await T4SlipService.getSlipIds("T4",clientId,taxYear);
        // setAuditFilingStatusAndTimeData(response)
        if(response && response.slipIds){
          if(response.slipIds && response.slipIds.length > 100){
            const maxItemsPerIteration = 100;
            const totalIterations = Math.ceil(response.slipIds.length / maxItemsPerIteration);
            let localT4AuditFilingData = { ...t4AuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            // Calculate total iterations required
            setIsLoadingData(true);
            for (let i = 0; i < totalIterations; i++) {
              const start = i * maxItemsPerIteration;
              const end = Math.min(start + maxItemsPerIteration, response.slipIds.length);
              const chunk = response.slipIds.slice(start, end);
              // const ids_list = chunk
              //     .map((ids: any) => `t4_slip_ids=${ids}`)
              //     .join("&");
                // const pdfIds: any = ids_list.replace(/^&+|&+$/g, "");
                try {
                  let payload = {
                    clientId:clientId,
                    taxYear:taxYear,
                    tag:"T4",
                    slipIds:chunk
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFiling(payload)
                    console.log("response000", response);
                    if (response && response.t4_filling && response.t4_warnings) {
                      // setGeneralAuditFilingData((prevData:any) => ({
                      //     ...prevData,
                      //     general_filling: [...prevData.general_filling, ...response.general_filling],
                      //     general_warnings: [...prevData.general_warnings, ...response.general_warnings],
                      // }));
                      //   setT4AuditFilingData((prevData:any) => ({
                      //     ...prevData,
                      //     t4_filling: [...prevData.t4_filling, ...response.t4_filling],
                      //     t4_warnings: [...prevData.t4_warnings, ...response.t4_warnings],
                      // }));

                        //   setT4AuditFilingData((prevData: any) => {
                        //     const newT4Filling = [
                        //         ...prevData.t4_filling,
                        //         ...response.t4_filling
                        //     ].filter((value, index, self) => self.indexOf(value) === index);
                        
                        //     const newT4Warnings = [
                        //         ...prevData.t4_warnings,
                        //         ...response.t4_warnings
                        //     ].filter((value, index, self) => self.indexOf(value) === index);
                        
                        //     return {
                        //         ...prevData,
                        //         t4_filling: newT4Filling,
                        //         t4_warnings: newT4Warnings
                        //     };
                        // });

                        localT4AuditFilingData = {
                          t4_filling: [
                            ...localT4AuditFilingData.t4_filling,
                            ...response.t4_filling,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                          t4_warnings: [
                            ...localT4AuditFilingData.t4_warnings,
                            ...response.t4_warnings,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                        };
                    
                      console.log("t4AuditFilingData--",t4AuditFilingData)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
                  console.log(`Iteration ${i + 1}: Count = ${chunk.length}`);
                  console.log(chunk);
                }

                setT4AuditFilingData(localT4AuditFilingData); // Final state update after loop ends

                if(localGeneralAuditFilingData.general_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                if(localT4AuditFilingData.t4_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"t4",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setT4AuditFilingStatus(response.auditStatus)
                        setT4AuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"t4",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setT4AuditFilingStatus(response.auditStatus)
                        setT4AuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                setIsLoadingData(false);
            } else {
            setIsLoadingData(true);
            let localT4AuditFilingData = { ...t4AuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            try {
              let payload = {
                clientId:clientId,
                taxYear:taxYear,
                tag:"T4",
                slipIds:response.slipIds
              }
              const t4FilingResponse: any =
                await AdjustmentOptionsService.createAuditFiling(payload)
                console.log("t4FilingResponse000", t4FilingResponse);
                if (t4FilingResponse && t4FilingResponse.t4_filling && t4FilingResponse.t4_warnings) {
                  // setGeneralAuditFilingData((prevData:any) => ({
                  //     ...prevData,
                  //     general_filling: [...prevData.general_filling, ...t4FilingResponse.general_filling],
                  //     general_warnings: [...prevData.general_warnings, ...t4FilingResponse.general_warnings],
                  // }));
                  //   setT4AuditFilingData((prevData:any) => ({
                  //     ...prevData,
                  //     t4_filling: [...prevData.t4_filling, ...t4FilingResponse.t4_filling],
                  //     t4_warnings: [...prevData.t4_warnings, ...t4FilingResponse.t4_warnings],
                  // }));

                  //   setT4AuditFilingData((prevData: any) => {
                  //     const newT4Filling = [
                  //         ...prevData.t4_filling,
                  //         ...t4FilingResponse.t4_filling
                  //     ].filter((value, index, self) => self.indexOf(value) === index);
                  
                  //     const newT4Warnings = [
                  //         ...prevData.t4_warnings,
                  //         ...t4FilingResponse.t4_warnings
                  //     ].filter((value, index, self) => self.indexOf(value) === index);
                  
                  //     return {
                  //         ...prevData,
                  //         t4_filling: newT4Filling,
                  //         t4_warnings: newT4Warnings
                  //     };
                  // });

                  localT4AuditFilingData = {
                    t4_filling: [
                      ...localT4AuditFilingData.t4_filling,
                      ...t4FilingResponse.t4_filling,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                    t4_warnings: [
                      ...localT4AuditFilingData.t4_warnings,
                      ...t4FilingResponse.t4_warnings,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                  };

                }
              } catch (err) {
                setIsLoadingData(false);
                console.log("err", err);
              }

              setT4AuditFilingData(localT4AuditFilingData); // Final state update after loop ends
              if(localGeneralAuditFilingData.general_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              if(localT4AuditFilingData.t4_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"t4",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setT4AuditFilingStatus(response.auditStatus)
                      setT4AuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"t4",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setT4AuditFilingStatus(response.auditStatus)
                      setT4AuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              setIsLoadingData(false);
          }
        }
      } catch (error) {
        setSnackbarData({
          open: true,
          message: "Something went wrong!",
          severity: "error",
        });
      } finally {
        setIsLoadingData(false);
      }
    };
    fetchData();
  }
  const onT4AAuditFilingClickedHandler = () => {
    // alert("clicked T4A Filing")
    setIsLoadingData(true);
    const fetchData = async () => {
      try {
        const response = await T4SlipService.getSlipIds("T4A",clientId,taxYear);
        // setAuditFilingStatusAndTimeData(response)
        if(response && response.slipIds){
          if(response.slipIds && response.slipIds.length > 100){
            const maxItemsPerIteration = 100;
            const totalIterations = Math.ceil(response.slipIds.length / maxItemsPerIteration); // Calculate total iterations required
            let localT4AAuditFilingData = { ...t4aAuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            setIsLoadingData(true);
            for (let i = 0; i < totalIterations; i++) {
              const start = i * maxItemsPerIteration;
              const end = Math.min(start + maxItemsPerIteration, response.slipIds.length);
              const chunk = response.slipIds.slice(start, end);
                try {
                  let payload = {
                    clientId:clientId,
                    taxYear:taxYear,
                    tag:"T4A",
                    slipIds:chunk
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFiling(payload)
                    console.log("response000", response);
                    if (response && response.t4a_filling && response.t4a_warnings) {
                          // setT4aAuditFilingData((prevData: any) => {
                          //   const newT4AFilling = [
                          //       ...prevData.t4a_filling,
                          //       ...response.t4a_filling
                          //   ].filter((value, index, self) => self.indexOf(value) === index);
                        
                          //   const newT4AWarnings = [
                          //       ...prevData.t4a_warnings,
                          //       ...response.t4a_warnings
                          //   ].filter((value, index, self) => self.indexOf(value) === index);
                        
                            // return {
                            //     ...prevData,
                            //     t4a_filling: newT4AFilling,
                            //     t4a_warnings: newT4AWarnings
                            // };
                        // });

                        localT4AAuditFilingData = {
                          t4a_filling: [
                            ...localT4AAuditFilingData.t4a_filling,
                            ...response.t4a_filling,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                          t4a_warnings: [
                            ...localT4AAuditFilingData.t4a_warnings,
                            ...response.t4a_warnings,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                        };
                        
                    
                      console.log("t4aAuditFilingData--",t4aAuditFilingData)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
                  console.log(`Iteration ${i + 1}: Count = ${chunk.length}`);
                  console.log(chunk);
                }
                setT4aAuditFilingData(localT4AAuditFilingData); // Final state update after loop ends
                if(localGeneralAuditFilingData.general_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                if(localT4AAuditFilingData.t4a_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"t4a",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setT4aAuditFilingStatus(response.auditStatus)
                        setT4aAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"t4a",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setT4aAuditFilingStatus(response.auditStatus)
                        setT4aAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                setIsLoadingData(false);
            } else {
            setIsLoadingData(true);
            let localT4AAuditFilingData = { ...t4aAuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            try {
              let payload = {
                clientId:clientId,
                taxYear:taxYear,
                tag:"T4A",
                slipIds:response.slipIds
              }
              const t4AFilingResponse: any =
                await AdjustmentOptionsService.createAuditFiling(payload)
                console.log("t4aFilingResponse000", t4AFilingResponse);
                if (t4AFilingResponse) {
                  //   setT4aAuditFilingData((prevData: any) => {
                  //     const newT4AFilling = [
                  //         ...prevData.t4a_filling,
                  //         ...t4AFilingResponse.t4a_filling
                  //     ].filter((value, index, self) => self.indexOf(value) === index);
                  
                  //     const newT4AWarnings = [
                  //         ...prevData.t4a_warnings,
                  //         ...t4AFilingResponse.t4a_warnings
                  //     ].filter((value, index, self) => self.indexOf(value) === index);
                  
                  //     return {
                  //         ...prevData,
                  //         t4a_filling: newT4AFilling,
                  //         t4a_warnings: newT4AWarnings
                  //     };
                  // });

                  localT4AAuditFilingData = {
                    t4a_filling: [
                      ...localT4AAuditFilingData.t4a_filling,
                      ...t4AFilingResponse.t4a_filling,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                    t4a_warnings: [
                      ...localT4AAuditFilingData.t4a_warnings,
                      ...t4AFilingResponse.t4a_warnings,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                  };

                  }
                
              } catch (err) {
                setIsLoadingData(false);
                console.log("err", err);
              }

              setT4aAuditFilingData(localT4AAuditFilingData); // Final state update after loop ends
              if(localGeneralAuditFilingData.general_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              if(localT4AAuditFilingData.t4a_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"t4a",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setT4aAuditFilingStatus(response.auditStatus)
                      setT4aAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"t4a",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setT4aAuditFilingStatus(response.auditStatus)
                      setT4aAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              setIsLoadingData(false);
          }
        }
      } catch (error) {
        setSnackbarData({
          open: true,
          message: "Something went wrong!",
          severity: "error",
        });
      } finally {
        setIsLoadingData(false);
      }
    };
    fetchData();
  }
  const onRL1AuditFilingClickedHandler = () => {
    // alert("clicked RL1 Filing")
    setIsLoadingData(true);
    const fetchData = async () => {
      try {
        const response = await T4SlipService.getSlipIds("RL1",clientId,taxYear);
        // setAuditFilingStatusAndTimeData(response)
        if(response && response.slipIds){
          if(response.slipIds && response.slipIds.length > 100){
            const maxItemsPerIteration = 100;
            const totalIterations = Math.ceil(response.slipIds.length / maxItemsPerIteration); // Calculate total iterations required

            let localRL1AuditFilingData = { ...rl1AuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            setIsLoadingData(true);
            for (let i = 0; i < totalIterations; i++) {
              const start = i * maxItemsPerIteration;
              const end = Math.min(start + maxItemsPerIteration, response.slipIds.length);
              const chunk = response.slipIds.slice(start, end);
                try {
                  let payload = {
                    clientId:clientId,
                    taxYear:taxYear,
                    tag:"RL1",
                    slipIds:chunk
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFiling(payload)
                    console.log("response000", response);
                    if (response && response.rl1_filling && response.rl1_warnings) {
                        //   setRL1AuditFilingData((prevData: any) => {
                        //     const newRL1Filling = [
                        //         ...prevData.rl1_filling,
                        //         ...response.rl1_filling
                        //     ].filter((value, index, self) => self.indexOf(value) === index);
                        
                        //     const newRL1Warnings = [
                        //         ...prevData.rl1_warnings,
                        //         ...response.rl1_warnings
                        //     ].filter((value, index, self) => self.indexOf(value) === index);
                        
                        //     return {
                        //         ...prevData,
                        //         rl1_filling: newRL1Filling,
                        //         rl1_warnings: newRL1Warnings
                        //     };
                        // });

                        localRL1AuditFilingData = {
                          rl1_filling: [
                            ...localRL1AuditFilingData.rl1_filling,
                            ...response.rl1_filling,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                          rl1_warnings: [
                            ...localRL1AuditFilingData.rl1_warnings,
                            ...response.rl1_warnings,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                        };
                        
                    
                      console.log("rl1AuditFilingData--",rl1AuditFilingData)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
                  console.log(`Iteration ${i + 1}: Count = ${chunk.length}`);
                  console.log(chunk);
                }
                setRL1AuditFilingData(localRL1AuditFilingData); // Final state update after loop ends
                if(localGeneralAuditFilingData.general_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                if(localRL1AuditFilingData.rl1_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"rl1",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setRL1AuditFilingStatus(response.auditStatus)
                        setRL1AuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"rl1",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setRL1AuditFilingStatus(response.auditStatus)
                        setRL1AuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                setIsLoadingData(false);
            } else {
            setIsLoadingData(true);
            let localRL1AuditFilingData = { ...rl1AuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            try {
              let payload = {
                clientId:clientId,
                taxYear:taxYear,
                tag:"RL1",
                slipIds:response.slipIds
              }
              const RL1FilingResponse: any =
                await AdjustmentOptionsService.createAuditFiling(payload)
                console.log("RL1FilingResponse000", RL1FilingResponse);
                if (RL1FilingResponse) {
                  //   setRL1AuditFilingData((prevData: any) => {
                  //     const newRL1Filling = [
                  //         ...prevData.rl1_filling,
                  //         ...RL1FilingResponse.rl1_filling
                  //     ].filter((value, index, self) => self.indexOf(value) === index);
                  
                  //     const newRL1Warnings = [
                  //         ...prevData.rl1_warnings,
                  //         ...RL1FilingResponse.rl1_warnings
                  //     ].filter((value, index, self) => self.indexOf(value) === index);
                  
                  //     return {
                  //         ...prevData,
                  //         rl1_filling: newRL1Filling,
                  //         rl1_warnings: newRL1Warnings
                  //     };
                  // });
                  
                  localRL1AuditFilingData = {
                    rl1_filling: [
                      ...localRL1AuditFilingData.rl1_filling,
                      ...RL1FilingResponse.rl1_filling,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                    rl1_warnings: [
                      ...localRL1AuditFilingData.rl1_warnings,
                      ...RL1FilingResponse.rl1_warnings,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                  };
                  
                }
              } catch (err) {
                setIsLoadingData(false);
                console.log("err", err);
              }
              setRL1AuditFilingData(localRL1AuditFilingData); // Final state update after loop ends
              if(localGeneralAuditFilingData.general_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              if(localRL1AuditFilingData.rl1_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"rl1",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setRL1AuditFilingStatus(response.auditStatus)
                      setRL1AuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"rl1",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setRL1AuditFilingStatus(response.auditStatus)
                      setRL1AuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              setIsLoadingData(false);
          }
        }
      } catch (error) {
        setSnackbarData({
          open: true,
          message: "Something went wrong!",
          severity: "error",
        });
      } finally {
        setIsLoadingData(false);
      }
    };
    fetchData();
  }
  const onT5AuditFilingClickedHandler = () => {
    // alert("clicked T5 Filing")
    setIsLoadingData(true);
    const fetchData = async () => {
      try {
        const response = await T4SlipService.getSlipIds("T5",clientId,taxYear);
        // setAuditFilingStatusAndTimeData(response)
        if(response && response.slipIds){
          if(response.slipIds && response.slipIds.length > 100){
            const maxItemsPerIteration = 100;
            const totalIterations = Math.ceil(response.slipIds.length / maxItemsPerIteration);
            let localT5AuditFilingData = { ...t5AuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            // Calculate total iterations required
            setIsLoadingData(true);
            for (let i = 0; i < totalIterations; i++) {
              const start = i * maxItemsPerIteration;
              const end = Math.min(start + maxItemsPerIteration, response.slipIds.length);
              const chunk = response.slipIds.slice(start, end);
                try {
                  let payload = {
                    clientId:clientId,
                    taxYear:taxYear,
                    tag:"T5",
                    slipIds:chunk
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFiling(payload)
                    console.log("response000", response);
                    if (response && response.t5_filling && response.t5_warnings) {

                        localT5AuditFilingData = {
                          t5_filling: [
                            ...localT5AuditFilingData.t5_filling,
                            ...response.t5_filling,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                          t5_warnings: [
                            ...localT5AuditFilingData.t5_warnings,
                            ...response.t5_warnings,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                        };
                    
                      console.log("t5AuditFilingData--",t5AuditFilingData)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
                  console.log(`Iteration ${i + 1}: Count = ${chunk.length}`);
                  console.log(chunk);
                }

                setT5AuditFilingData(localT5AuditFilingData); // Final state update after loop ends

                if(localGeneralAuditFilingData.general_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                if(localT5AuditFilingData.t5_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"t5",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setT5AuditFilingStatus(response.auditStatus)
                        setT5AuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"t5",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setT5AuditFilingStatus(response.auditStatus)
                        setT5AuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                setIsLoadingData(false);
            } else {
            setIsLoadingData(true);
            let localT5AuditFilingData = { ...t5AuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            try {
              let payload = {
                clientId:clientId,
                taxYear:taxYear,
                tag:"T5",
                slipIds:response.slipIds
              }
              const t5FilingResponse: any =
                await AdjustmentOptionsService.createAuditFiling(payload)
                console.log("t5FilingResponse000", t5FilingResponse);
                if (t5FilingResponse && t5FilingResponse.t5_filling && t5FilingResponse.t5_warnings) {
                  localT5AuditFilingData = {
                    t5_filling: [
                      ...localT5AuditFilingData.t5_filling,
                      ...t5FilingResponse.t5_filling,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                    t5_warnings: [
                      ...localT5AuditFilingData.t5_warnings,
                      ...t5FilingResponse.t5_warnings,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                  };

                }
              } catch (err) {
                setIsLoadingData(false);
                console.log("err", err);
              }

              setT5AuditFilingData(localT5AuditFilingData); // Final state update after loop ends
              if(localGeneralAuditFilingData.general_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              if(localT5AuditFilingData.t5_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"t5",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setT5AuditFilingStatus(response.auditStatus)
                      setT5AuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"t5",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setT5AuditFilingStatus(response.auditStatus)
                      setT5AuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              setIsLoadingData(false);
          }
        }
      } catch (error) {
        setSnackbarData({
          open: true,
          message: "Something went wrong!",
          severity: "error",
        });
      } finally {
        setIsLoadingData(false);
      }
    };
    fetchData();
  }
  const onRL3AuditFilingClickedHandler = () => {
    // alert("clicked RL3 Filing")
    setIsLoadingData(true);
    const fetchData = async () => {
      try {
        const response = await T4SlipService.getSlipIds("RL3",clientId,taxYear);
        // setAuditFilingStatusAndTimeData(response)
        if(response && response.slipIds){
          if(response.slipIds && response.slipIds.length > 100){
            const maxItemsPerIteration = 100;
            const totalIterations = Math.ceil(response.slipIds.length / maxItemsPerIteration); // Calculate total iterations required

            let localRL3AuditFilingData = { ...rl3AuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            setIsLoadingData(true);
            for (let i = 0; i < totalIterations; i++) {
              const start = i * maxItemsPerIteration;
              const end = Math.min(start + maxItemsPerIteration, response.slipIds.length);
              const chunk = response.slipIds.slice(start, end);
                try {
                  let payload = {
                    clientId:clientId,
                    taxYear:taxYear,
                    tag:"RL3",
                    slipIds:chunk
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFiling(payload)
                    console.log("response000", response);
                    if (response && response.rl3_filling && response.rl3_warnings) {
                        //   setRL3AuditFilingData((prevData: any) => {
                        //     const newRL3Filling = [
                        //         ...prevData.rl3_filling,
                        //         ...response.rl3_filling
                        //     ].filter((value, index, self) => self.indexOf(value) === index);
                        
                        //     const newRL3Warnings = [
                        //         ...prevData.rl3_warnings,
                        //         ...response.rl3_warnings
                        //     ].filter((value, index, self) => self.indexOf(value) === index);
                        
                        //     return {
                        //         ...prevData,
                        //         rl3_filling: newRL3Filling,
                        //         rl3_warnings: newRL3Warnings
                        //     };
                        // });

                        localRL3AuditFilingData = {
                          rl3_filling: [
                            ...localRL3AuditFilingData.rl3_filling,
                            ...response.rl3_filling,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                          rl3_warnings: [
                            ...localRL3AuditFilingData.rl3_warnings,
                            ...response.rl3_warnings,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                        };
                        
                    
                      console.log("rl3AuditFilingData--",rl3AuditFilingData)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
                  console.log(`Iteration ${i + 1}: Count = ${chunk.length}`);
                  console.log(chunk);
                }
                setRL3AuditFilingData(localRL3AuditFilingData); // Final state update after loop ends
                if(localGeneralAuditFilingData.general_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                if(localRL3AuditFilingData.rl3_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"rl3",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setRL3AuditFilingStatus(response.auditStatus)
                        setRL3AuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"rl3",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setRL3AuditFilingStatus(response.auditStatus)
                        setRL3AuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                setIsLoadingData(false);
            } else {
            setIsLoadingData(true);
            let localRL3AuditFilingData = { ...rl3AuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            try {
              let payload = {
                clientId:clientId,
                taxYear:taxYear,
                tag:"RL3",
                slipIds:response.slipIds
              }
              const RL3FilingResponse: any =
                await AdjustmentOptionsService.createAuditFiling(payload)
                console.log("RL3FilingResponse000", RL3FilingResponse);
                if (RL3FilingResponse) {
                  //   setRL3AuditFilingData((prevData: any) => {
                  //     const newRL3Filling = [
                  //         ...prevData.rl3_filling,
                  //         ...RL3FilingResponse.rl3_filling
                  //     ].filter((value, index, self) => self.indexOf(value) === index);
                  
                  //     const newRL3Warnings = [
                  //         ...prevData.rl3_warnings,
                  //         ...RL3FilingResponse.rl3_warnings
                  //     ].filter((value, index, self) => self.indexOf(value) === index);
                  
                  //     return {
                  //         ...prevData,
                  //         rl3_filling: newRL3Filling,
                  //         rl3_warnings: newRL3Warnings
                  //     };
                  // });
                  
                  localRL3AuditFilingData = {
                    rl3_filling: [
                      ...localRL3AuditFilingData.rl3_filling,
                      ...RL3FilingResponse.rl3_filling,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                    rl3_warnings: [
                      ...localRL3AuditFilingData.rl3_warnings,
                      ...RL3FilingResponse.rl3_warnings,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                  };
                  
                }
              } catch (err) {
                setIsLoadingData(false);
                console.log("err", err);
              }
              setRL3AuditFilingData(localRL3AuditFilingData); // Final state update after loop ends
              if(localGeneralAuditFilingData.general_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              if(localRL3AuditFilingData.rl3_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"rl3",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setRL3AuditFilingStatus(response.auditStatus)
                      setRL3AuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"rl3",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setRL3AuditFilingStatus(response.auditStatus)
                      setRL3AuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              setIsLoadingData(false);
          }
        }
      } catch (error) {
        setSnackbarData({
          open: true,
          message: "Something went wrong!",
          severity: "error",
        });
      } finally {
        setIsLoadingData(false);
      }
    };
    fetchData();
  }
  const onT3AuditFilingClickedHandler = () => {
    // alert("clicked T3 Filing")
    setIsLoadingData(true);
    const fetchData = async () => {
      try {
        const response = await T4SlipService.getSlipIds("T3",clientId,taxYear);
        // setAuditFilingStatusAndTimeData(response)
        if(response && response.slipIds){
          if(response.slipIds && response.slipIds.length > 100){
            const maxItemsPerIteration = 100;
            const totalIterations = Math.ceil(response.slipIds.length / maxItemsPerIteration);
            let localT3AuditFilingData = { ...t3AuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            // Calculate total iterations required
            setIsLoadingData(true);
            for (let i = 0; i < totalIterations; i++) {
              const start = i * maxItemsPerIteration;
              const end = Math.min(start + maxItemsPerIteration, response.slipIds.length);
              const chunk = response.slipIds.slice(start, end);
                try {
                  let payload = {
                    clientId:clientId,
                    taxYear:taxYear,
                    tag:"T3",
                    slipIds:chunk
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFiling(payload)
                    console.log("response000", response);
                    if (response && response.t3_filling && response.t3_warnings) {

                        localT3AuditFilingData = {
                          t3_filling: [
                            ...localT3AuditFilingData.t3_filling,
                            ...response.t3_filling,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                          t3_warnings: [
                            ...localT3AuditFilingData.t3_warnings,
                            ...response.t3_warnings,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                        };
                    
                      console.log("t3AuditFilingData--",t3AuditFilingData)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
                  console.log(`Iteration ${i + 1}: Count = ${chunk.length}`);
                  console.log(chunk);
                }

                setT3AuditFilingData(localT3AuditFilingData); // Final state update after loop ends

                if(localGeneralAuditFilingData.general_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                if(localT3AuditFilingData.t3_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"t3",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setT3AuditFilingStatus(response.auditStatus)
                        setT3AuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"t3",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setT3AuditFilingStatus(response.auditStatus)
                        setT3AuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                setIsLoadingData(false);
            } else {
            setIsLoadingData(true);
            let localT3AuditFilingData = { ...t3AuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            try {
              let payload = {
                clientId:clientId,
                taxYear:taxYear,
                tag:"T3",
                slipIds:response.slipIds
              }
              const t3FilingResponse: any =
                await AdjustmentOptionsService.createAuditFiling(payload)
                console.log("t3FilingResponse000", t3FilingResponse);
                if (t3FilingResponse && t3FilingResponse.t3_filling && t3FilingResponse.t3_warnings) {
                  localT3AuditFilingData = {
                    t3_filling: [
                      ...localT3AuditFilingData.t3_filling,
                      ...t3FilingResponse.t3_filling,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                    t3_warnings: [
                      ...localT3AuditFilingData.t3_warnings,
                      ...t3FilingResponse.t3_warnings,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                  };

                }
              } catch (err) {
                setIsLoadingData(false);
                console.log("err", err);
              }

              setT3AuditFilingData(localT3AuditFilingData); // Final state update after loop ends
              if(localGeneralAuditFilingData.general_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              if(localT3AuditFilingData.t3_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"t3",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setT3AuditFilingStatus(response.auditStatus)
                      setT3AuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"t3",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setT3AuditFilingStatus(response.auditStatus)
                      setT3AuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              setIsLoadingData(false);
          }
        }
      } catch (error) {
        setSnackbarData({
          open: true,
          message: "Something went wrong!",
          severity: "error",
        });
      } finally {
        setIsLoadingData(false);
      }
    };
    fetchData();
  }
  const onRL16AuditFilingClickedHandler = () => {
    // alert("clicked RL16 Filing")
    setIsLoadingData(true);
    const fetchData = async () => {
      try {
        const response = await T4SlipService.getSlipIds("RL16",clientId,taxYear);
        // setAuditFilingStatusAndTimeData(response)
        if(response && response.slipIds){
          if(response.slipIds && response.slipIds.length > 100){
            const maxItemsPerIteration = 100;
            const totalIterations = Math.ceil(response.slipIds.length / maxItemsPerIteration); // Calculate total iterations required

            let localRL16AuditFilingData = { ...rl16AuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            setIsLoadingData(true);
            for (let i = 0; i < totalIterations; i++) {
              const start = i * maxItemsPerIteration;
              const end = Math.min(start + maxItemsPerIteration, response.slipIds.length);
              const chunk = response.slipIds.slice(start, end);
                try {
                  let payload = {
                    clientId:clientId,
                    taxYear:taxYear,
                    tag:"RL16",
                    slipIds:chunk
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFiling(payload)
                    console.log("response000", response);
                    if (response && response.rl16_filling && response.rl16_warnings) {
                        //   setRL16AuditFilingData((prevData: any) => {
                        //     const newRL16Filling = [
                        //         ...prevData.rl16_filling,
                        //         ...response.rl16_filling
                        //     ].filter((value, index, self) => self.indexOf(value) === index);
                        
                        //     const newRL16Warnings = [
                        //         ...prevData.rl16_warnings,
                        //         ...response.rl16_warnings
                        //     ].filter((value, index, self) => self.indexOf(value) === index);
                        
                        //     return {
                        //         ...prevData,
                        //         rl16_filling: newRL16Filling,
                        //         rl16_warnings: newRL16Warnings
                        //     };
                        // });

                        localRL16AuditFilingData = {
                          rl16_filling: [
                            ...localRL16AuditFilingData.rl16_filling,
                            ...response.rl16_filling,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                          rl16_warnings: [
                            ...localRL16AuditFilingData.rl16_warnings,
                            ...response.rl16_warnings,
                          ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                        };
                        
                    
                      console.log("rl16AuditFilingData--",rl16AuditFilingData)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
                  console.log(`Iteration ${i + 1}: Count = ${chunk.length}`);
                  console.log(chunk);
                }
                setRL16AuditFilingData(localRL16AuditFilingData); // Final state update after loop ends
                if(localGeneralAuditFilingData.general_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"general",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setGeneralAuditFilingStatus(response.auditStatus)
                        setGeneralAuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                if(localRL16AuditFilingData.rl16_filling.length === 0){
                  try {
                    let payload = {
                      auditTag:"rl16",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:true,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setRL16AuditFilingStatus(response.auditStatus)
                        setRL16AuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                } else {
                  try {
                    let payload = {
                      auditTag:"rl16",
                      clientId:clientId,
                      taxYear:taxYear,
                      auditStatus:false,
                      lastCheck:formatDateWithTime(new Date())
                    }
                    const response: any =
                      await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                      console.log("response000", response);
                      if (response) {
                        setRL16AuditFilingStatus(response.auditStatus)
                        setRL16AuditFilingTime(response.lastCheck)
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                }
                setIsLoadingData(false);
            } else {
            setIsLoadingData(true);
            let localRL16AuditFilingData = { ...rl16AuditFilingData }; // Local copy to track updates
            let localGeneralAuditFilingData = { ...generalAuditFilingData }; // Local copy to track updates
            try {
              let payload = {
                clientId:clientId,
                taxYear:taxYear,
                tag:"RL16",
                slipIds:response.slipIds
              }
              const RL16FilingResponse: any =
                await AdjustmentOptionsService.createAuditFiling(payload)
                console.log("RL16FilingResponse000", RL16FilingResponse);
                if (RL16FilingResponse) {
                  //   setRL16AuditFilingData((prevData: any) => {
                  //     const newRL16Filling = [
                  //         ...prevData.rl16_filling,
                  //         ...RL16FilingResponse.rl16_filling
                  //     ].filter((value, index, self) => self.indexOf(value) === index);
                  
                  //     const newRL16Warnings = [
                  //         ...prevData.rl16_warnings,
                  //         ...RL16FilingResponse.rl16_warnings
                  //     ].filter((value, index, self) => self.indexOf(value) === index);
                  
                  //     return {
                  //         ...prevData,
                  //         rl16_filling: newRL16Filling,
                  //         rl16_warnings: newRL16Warnings
                  //     };
                  // });
                  
                  localRL16AuditFilingData = {
                    rl16_filling: [
                      ...localRL16AuditFilingData.rl16_filling,
                      ...RL16FilingResponse.rl16_filling,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                    rl16_warnings: [
                      ...localRL16AuditFilingData.rl16_warnings,
                      ...RL16FilingResponse.rl16_warnings,
                    ].filter((value, index, self) => self.indexOf(value) === index), // Ensure uniqueness if needed
                  };
                  
                }
              } catch (err) {
                setIsLoadingData(false);
                console.log("err", err);
              }
              setRL16AuditFilingData(localRL16AuditFilingData); // Final state update after loop ends
              if(localGeneralAuditFilingData.general_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"general",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setGeneralAuditFilingStatus(response.auditStatus)
                      setGeneralAuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              if(localRL16AuditFilingData.rl16_filling.length === 0){
                try {
                  let payload = {
                    auditTag:"rl16",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:true,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setRL16AuditFilingStatus(response.auditStatus)
                      setRL16AuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              } else {
                try {
                  let payload = {
                    auditTag:"rl16",
                    clientId:clientId,
                    taxYear:taxYear,
                    auditStatus:false,
                    lastCheck:formatDateWithTime(new Date())
                  }
                  const response: any =
                    await AdjustmentOptionsService.createAuditFilingStatusAndTime(payload)
                    console.log("response000", response);
                    if (response) {
                      setRL16AuditFilingStatus(response.auditStatus)
                      setRL16AuditFilingTime(response.lastCheck)
                    }
                  } catch (err) {
                    setIsLoadingData(false);
                    console.log("err", err);
                  }
              }
              setIsLoadingData(false);
          }
        }
      } catch (error) {
        setSnackbarData({
          open: true,
          message: "Something went wrong!",
          severity: "error",
        });
      } finally {
        setIsLoadingData(false);
      }
    };
    fetchData();
  }

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: '',
        companyName: '',
        activityType:
          "",
        activityLogVisibility: false,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  return (
    <>
      <BackdropCircularProgress open={isLoadingData || isLoadingAuditFilingStatusAndTime || isLoadingGeneralAuditFiling} testId={testId} />
      <Grid
        container
        sx={{ marginTop: theme.spacing(0) }}
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xs={0} sm={1} md={1} lg={1} xl={1} />
        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
          <StandardPanel
            title="General"
            variant="outlined"
            testId={`general-panel`}
          >
            {generalAuditFilingData && generalAuditFilingData?.general_filling && generalAuditFilingData?.general_filling.length > 0 ? <><AuditAlert title="FILLING" alerts={generalAuditFilingData !== undefined && generalAuditFilingData.general_filling} />
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} /></> : <></>}
            {generalAuditFilingData  && generalAuditFilingData?.general_warnings && generalAuditFilingData?.general_warnings.length > 0 ? <><AuditAlert title="WARNING" alerts={generalAuditFilingData !== undefined && generalAuditFilingData.general_warnings} />
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} /></> : <></>}
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <Box>
                {generalAuditFilingTime && <Typography sx={{fontSize:"0.85rem"}}>Last Checked : {generalAuditFilingTime}</Typography>}
                <Typography sx={{alignItems:"center",display:"flex",fontSize:"0.85rem"}}>
                  Status : {generalAuditFilingStatus ? 
                    <> <span style={{color:"green"}}>&nbsp;Verified</span> 
                      <i className="pi pi-check" style={{ color: 'green',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> 
                    : <><span style={{color:"red"}}>&nbsp;Not Verified</span> 
                      <i className="pi pi-times" style={{ color: 'red',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> }
                </Typography>
              </Box>
              {/* <Box>
                <Button
                  size="small"
                  data-testid={testId}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    textTransform: "none",
                  }}
                  onClick={onGeneralAuditFilingClickedHandler}
                >
                  Run General Audit
                </Button>
              </Box> */}
            </Box>
          </StandardPanel>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
          <StandardPanel
            title="T4"
            variant="outlined"
            testId={`${testId}-t4-panel`}
          >
            {t4AuditFilingData !== undefined && t4AuditFilingData.t4_filling && t4AuditFilingData.t4_filling.length > 0 ? <>
            <AuditAlert title="FILLING" alerts={t4AuditFilingData !== undefined && t4AuditFilingData.t4_filling} />
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} /></> : <></>}
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <Box>
                {t4AuditFilingTime && <Typography sx={{fontSize:"0.85rem"}}>Last Checked : {t4AuditFilingTime}</Typography>}
                <Typography sx={{alignItems:"center",display:"flex",fontSize:"0.85rem"}}>
                  Status : {t4AuditFilingStatus ? 
                    <> <span style={{color:"green"}}>&nbsp;Verified</span> 
                      <i className="pi pi-check" style={{ color: 'green',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> 
                    : <><span style={{color:"red"}}>&nbsp;Not Verified</span> 
                      <i className="pi pi-times" style={{ color: 'red',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> }
                </Typography>
              </Box>
              <Box>
                <Button
                  size="small"
                  data-testid={testId}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    textTransform: "none",
                  }}
                  onClick={onT4AuditFilingClickedHandler}
                >
                  Run T4 Audit
                </Button>
              </Box>
            </Box>
            
            {t4AuditFilingData !== undefined && t4AuditFilingData.t4_warnings && t4AuditFilingData.t4_warnings.length > 0 ? <>
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
            <AuditAlert title="WARNING" alerts={t4AuditFilingData !== undefined && t4AuditFilingData.t4_warnings} />
            {/*  <Divider sx={{ marginTop: 2, marginBottom: 2 }} /> */}
            </> : <></>}
            
          </StandardPanel>
        </Grid>
        <Grid item xs={0} sm={1} md={1} lg={2} xl={1} />
        <Grid item xs={0} sm={1} md={1} lg={1} xl={1} />
        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
          <StandardPanel
            title="T4A"
            variant="outlined"
            testId={`${testId}-t4a-panel`}
          >
            {t4aAuditFilingData !== undefined && t4aAuditFilingData.t4a_filling && t4aAuditFilingData.t4a_filling.length > 0 ? <>
            <AuditAlert title="FILLING" alerts={t4aAuditFilingData !== undefined && t4aAuditFilingData.t4a_filling} />
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} /></> : <></>}
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <Box>
                {t4aAuditFilingTime && <Typography sx={{fontSize:"0.85rem"}}>Last Checked : {t4aAuditFilingTime}</Typography>}
                <Typography sx={{alignItems:"center",display:"flex",fontSize:"0.85rem"}}>
                  Status : {t4aAuditFilingStatus ? 
                    <> <span style={{color:"green"}}>&nbsp;Verified</span> 
                      <i className="pi pi-check" style={{ color: 'green',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> 
                    : <><span style={{color:"red"}}>&nbsp;Not Verified</span> 
                      <i className="pi pi-times" style={{ color: 'red',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> }
                </Typography>
              </Box>
              <Box>
                <Button
                  size="small"
                  data-testid={testId}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    textTransform: "none",
                  }}
                  onClick={onT4AAuditFilingClickedHandler}
                >
                  Run T4A Audit
                </Button>
              </Box>
            </Box>
            {t4aAuditFilingData !== undefined && t4aAuditFilingData.t4a_warnings && t4aAuditFilingData.t4a_warnings.length > 0 ? <>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <AuditAlert title="WARNING" alerts={t4aAuditFilingData !== undefined && t4aAuditFilingData.t4a_warnings} />
            </> : <></>}
          </StandardPanel>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
          <StandardPanel
            title="RL-1"
            variant="outlined"
            testId={`${testId}-rl1-panel`}
          >
            {rl1AuditFilingData !== undefined && rl1AuditFilingData.rl1_filling && rl1AuditFilingData.rl1_filling.length > 0 ? <>
            <AuditAlert title="FILLING" alerts={rl1AuditFilingData !== undefined && rl1AuditFilingData.rl1_filling} />
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} /></> : <></>}
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <Box>
                {rl1AuditFilingTime && <Typography sx={{fontSize:"0.85rem"}}>Last Checked : {rl1AuditFilingTime}</Typography>}
                <Typography sx={{alignItems:"center",display:"flex",fontSize:"0.85rem"}}>
                  Status : {rl1AuditFilingStatus ? 
                    <> <span style={{color:"green"}}>&nbsp;Verified</span> 
                      <i className="pi pi-check" style={{ color: 'green',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> 
                    : <><span style={{color:"red"}}>&nbsp;Not Verified</span> 
                      <i className="pi pi-times" style={{ color: 'red',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> }
                </Typography>
              </Box>
              <Box>
                <Button
                  size="small"
                  data-testid={testId}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    textTransform: "none",
                  }}
                  onClick={onRL1AuditFilingClickedHandler}
                >
                  Run RL-1 Audit
                </Button>
              </Box>
            </Box>
            {rl1AuditFilingData !== undefined && rl1AuditFilingData.rl1_warnings && rl1AuditFilingData.rl1_warnings.length > 0 ? <>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <AuditAlert title="WARNING" alerts={rl1AuditFilingData !== undefined && rl1AuditFilingData.rl1_warnings} />
            </> : <></>}
          </StandardPanel>
        </Grid>
        <Grid item xs={0} sm={1} md={1} lg={1} xl={1} />
        <Grid item xs={0} sm={1} md={1} lg={1} xl={1} />
        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
          <StandardPanel
            title="T5"
            variant="outlined"
            testId={`${testId}-t5-panel`}
          >
            {t5AuditFilingData !== undefined && t5AuditFilingData.t5_filling && t5AuditFilingData.t5_filling.length > 0 ? <>
            <AuditAlert title="FILLING" alerts={t5AuditFilingData !== undefined && t5AuditFilingData.t5_filling} />
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} /></> : <></>}
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <Box>
                {t5AuditFilingTime && <Typography sx={{fontSize:"0.85rem"}}>Last Checked : {t5AuditFilingTime}</Typography>}
                <Typography sx={{alignItems:"center",display:"flex",fontSize:"0.85rem"}}>
                  Status : {t5AuditFilingStatus ? 
                    <> <span style={{color:"green"}}>&nbsp;Verified</span> 
                      <i className="pi pi-check" style={{ color: 'green',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> 
                    : <><span style={{color:"red"}}>&nbsp;Not Verified</span> 
                      <i className="pi pi-times" style={{ color: 'red',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> }
                </Typography>
              </Box>
              <Box>
                <Button
                  size="small"
                  data-testid={testId}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    textTransform: "none",
                  }}
                  onClick={onT5AuditFilingClickedHandler}
                >
                  Run T5 Audit
                </Button>
              </Box>
            </Box>
            {t5AuditFilingData !== undefined && t5AuditFilingData.t5_warnings && t5AuditFilingData.t5_warnings.length > 0 ? <>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <AuditAlert title="WARNING" alerts={t5AuditFilingData !== undefined && t5AuditFilingData.t5_warnings} />
            </> : <></>}
          </StandardPanel>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
          <StandardPanel
            title="RL-3"
            variant="outlined"
            testId={`${testId}-rl3-panel`}
          >
            {rl3AuditFilingData !== undefined && rl3AuditFilingData.rl3_filling && rl3AuditFilingData.rl3_filling.length > 0 ? <>
            <AuditAlert title="FILLING" alerts={rl3AuditFilingData !== undefined && rl3AuditFilingData.rl3_filling} />
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} /></> : <></>}
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <Box>
                {rl3AuditFilingTime && <Typography sx={{fontSize:"0.85rem"}}>Last Checked : {rl3AuditFilingTime}</Typography>}
                <Typography sx={{alignItems:"center",display:"flex",fontSize:"0.85rem"}}>
                  Status : {rl3AuditFilingStatus ? 
                    <> <span style={{color:"green"}}>&nbsp;Verified</span> 
                      <i className="pi pi-check" style={{ color: 'green',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> 
                    : <><span style={{color:"red"}}>&nbsp;Not Verified</span> 
                      <i className="pi pi-times" style={{ color: 'red',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> }
                </Typography>
              </Box>
              <Box>
                <Button
                  size="small"
                  data-testid={testId}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    textTransform: "none",
                  }}
                  onClick={onRL3AuditFilingClickedHandler}
                >
                  Run RL-3 Audit
                </Button>
              </Box>
            </Box>
            {rl3AuditFilingData !== undefined && rl3AuditFilingData.rl3_warnings && rl3AuditFilingData.rl3_warnings.length > 0 ? <>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <AuditAlert title="WARNING" alerts={rl3AuditFilingData !== undefined && rl3AuditFilingData.rl3_warnings} />
            </> : <></>}
          </StandardPanel>
        </Grid>
        <Grid item xs={0} sm={1} md={1} lg={1} xl={1} />
        <Grid item xs={0} sm={1} md={1} lg={1} xl={1} />
        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
          <StandardPanel
            title="T3"
            variant="outlined"
            testId={`${testId}-t3-panel`}
          >
            {t3AuditFilingData !== undefined && t3AuditFilingData.t3_filling && t3AuditFilingData.t3_filling.length > 0 ? <>
            <AuditAlert title="FILLING" alerts={t3AuditFilingData !== undefined && t3AuditFilingData.t3_filling} />
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} /></> : <></>}
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <Box>
                {t3AuditFilingTime && <Typography sx={{fontSize:"0.85rem"}}>Last Checked : {t3AuditFilingTime}</Typography>}
                <Typography sx={{alignItems:"center",display:"flex",fontSize:"0.85rem"}}>
                  Status : {t3AuditFilingStatus ? 
                    <> <span style={{color:"green"}}>&nbsp;Verified</span> 
                      <i className="pi pi-check" style={{ color: 'green',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> 
                    : <><span style={{color:"red"}}>&nbsp;Not Verified</span> 
                      <i className="pi pi-times" style={{ color: 'red',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> }
                </Typography>
              </Box>
              <Box>
                <Button
                  size="small"
                  data-testid={testId}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    textTransform: "none",
                  }}
                  onClick={onT3AuditFilingClickedHandler}
                >
                  Run T3 Audit
                </Button>
              </Box>
            </Box>
            {t3AuditFilingData !== undefined && t3AuditFilingData.t3_warnings && t3AuditFilingData.t3_warnings.length > 0 ? <>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <AuditAlert title="WARNING" alerts={t3AuditFilingData !== undefined && t3AuditFilingData.t3_warnings} />
            </> : <></>}
          </StandardPanel>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
          <StandardPanel
            title="RL-16"
            variant="outlined"
            testId={`${testId}-rl16-panel`}
          >
            {rl16AuditFilingData !== undefined && rl16AuditFilingData.rl16_filling && rl16AuditFilingData.rl16_filling.length > 0 ? <>
            <AuditAlert title="FILLING" alerts={rl16AuditFilingData !== undefined && rl16AuditFilingData.rl16_filling} />
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} /></> : <></>}
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <Box>
                {rl16AuditFilingTime && <Typography sx={{fontSize:"0.85rem"}}>Last Checked : {rl16AuditFilingTime}</Typography>}
                <Typography sx={{alignItems:"center",display:"flex",fontSize:"0.85rem"}}>
                  Status : {rl16AuditFilingStatus ? 
                    <> <span style={{color:"green"}}>&nbsp;Verified</span> 
                      <i className="pi pi-check" style={{ color: 'green',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> 
                    : <><span style={{color:"red"}}>&nbsp;Not Verified</span> 
                      <i className="pi pi-times" style={{ color: 'red',marginLeft:"4px",fontSize:"0.85rem",fontWeight:"bold" }}></i></> }
                </Typography>
              </Box>
              <Box>
                <Button
                  size="small"
                  data-testid={testId}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    textTransform: "none",
                  }}
                  onClick={onRL16AuditFilingClickedHandler}
                >
                  Run RL-16 Audit
                </Button>
              </Box>
            </Box>
            {rl16AuditFilingData !== undefined && rl16AuditFilingData.rl16_warnings && rl16AuditFilingData.rl16_warnings.length > 0 ? <>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <AuditAlert title="WARNING" alerts={rl16AuditFilingData !== undefined && rl16AuditFilingData.rl16_warnings} />
            </> : <></>}
          </StandardPanel>
        </Grid>
      </Grid>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </>
  );
};
