import { useNavigate } from "react-router-dom";
export const FlexFieldMenuComponent = () => {
const navigate = useNavigate();
const ArrayData = [
  {
    label: "Fields",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/flex-fields/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/flex-fields/setup")
        },
      },
    ],
  },
  {
    label: "Tabs",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/flex-field-tabs/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/flex-field-tabs/setup")
        },
      },
    ],
  },
  {
    label: "Panels",
    items: [
      {
        label: "List",
        command: () => {
          navigate("/flex-field-panels/list")
        },
      },
      {
        label: "Setup",
        command: () => {
          navigate("/flex-field-panels/setup")
        },
      },
    ],
  },
  {
    label: "Assign Field to Panel",
    command: () => {
          navigate("/flex-field-panel-assignment")
        },
  },
  {
    label: "Field Set",
    command: () => {
          navigate("/flex-field-screen-setup")
        },
  },
];
return {
  FlexFieldMenu:ArrayData
}
}
