import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { JournalCodeSetup } from "components/Reports/GeneralClient";
import { TransactionsReport } from "components/Reports/TransactionsReport";
import { ClientAdjustmentReport } from "components/Reports/ClientAdjustmentReport";

export interface IClientAdjustmentReportPage {}

export const ClientAdjustmentReportPage: FC<IClientAdjustmentReportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Client Adjustment Report"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<ClientAdjustmentReport />}
    />
  );
};
