import { ClientList } from "components/Client";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export interface IClientListPage {}

export const ClientListPage: FC<IClientListPage> = (
  props: IClientListPage
) => {
  return (
    <AuthenticatedLayout
      pageTitle="Client List"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<ClientList />}
    />
  );
};
