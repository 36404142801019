import { IPostCode, PostCode } from "@websential/cosmic";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";


export interface IPostCodeRHF
  extends Pick<IPostCode, Exclude<keyof IPostCode, "error" | "helperText">> {}

export const PostCodeRHF: FC<any> = ({
  id,
  required,
  defaultValue,
  labelPosition,
  testId,
  labelColumns,
  fieldColumns,
}: any) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  console.log("inside PostCodeRHF")
  console.log("defaultValuedefaultValue",defaultValue)
  // console.log("defaultValuedefaultValue",value)
  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue || ''} // Initialize with defaultValue or empty string
      render={({ field: { onChange, value, onBlur } }) => (
        <PostCode
          id={id}
          label="Post Code"
          required={required}
          onChange={onChange}
          value={value} // Use the value from Controller
          onBlur={onBlur}
          labelPosition={labelPosition}
          error={errors[id]}
          helperText={errors[id]?.message}
          testId={testId}
          labelColumns={labelColumns}
          fieldColumns={fieldColumns}
        />
      )}
    />
  );
};
