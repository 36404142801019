import T4ASlipImportLayout from "components/T4ASlip/T4ASlipImportLayout/T4ASlipImportLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT4ASlipImportPage {}

export const T4ASlipImportPage: FC<IT4ASlipImportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import T4A Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t4a/list" },
        { text: "T4A", navigateTo: "/taxslip/t4a/list" },
        { text: "Import T4A Slips", navigateTo: "/taxslip/t4a/import" },
      ]}
      mainContents={<T4ASlipImportLayout />}
    />
  );
};
