import { TaxProfileList } from "components/TaxProfile";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface ITaxProfileListPage {}

export const TaxProfileListPage: FC<ITaxProfileListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Tax Profile List"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Tax Profile', navigateTo: '/tax-profile/list'}]}
      mainContents={<TaxProfileList />}
    />
  );
};
