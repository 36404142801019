import React from "react";

import ArticleIcon from "@mui/icons-material/Article";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ITest } from "@websential/cosmic";

export interface IGenerateXMLButton extends ITest {
  /**
   * The label of the reset button, default label is Reset.
   */
  label?: string;
  /**
   * if true, margin of 0 will be applied on the left otherwise margin 2 is applied
   */
  disableLeftMargin?: boolean;
  /**
   * Callback function fired when button is pressed.
   */
  onClickHandler?: () => void;
}

/**
 * Reset button control is only useable in form,
 * It allow user to reset the form.
 */
export const GenerateXMLButton: React.FC<IGenerateXMLButton> = ({
  label = "Generate XML",
  onClickHandler,
  disableLeftMargin = false,
  testId,
}) => {
  return (
    <Button
      size="medium"
      startIcon={<ArticleIcon />}
      data-testid={testId}
      variant="outlined"
      // color="primary"
      onClick={onClickHandler}
      sx={{
        marginLeft: disableLeftMargin ? 0 : 2,
        borderColor: "#dfe1e6",
        backgroundColor: "#dfe1e6",
        "&:hover": {
          backgroundColor: "#e2e6ea",
        },
        color: "#212529",
      }}
    >
      {label.length > 0 ? (
        <Typography sx={{ textTransform: "none" }}>{label}</Typography>
      ) : null}
    </Button>
  );
};
