import axios, { AxiosResponse } from "axios";
import { LocalStorageService } from "../services/LocalStorageService";

// export const baseURL = "http://192.168.1.4:8502/";
// export const baseURL = "http://192.168.1.4:8502";
// export const baseURL = "http://192.168.1.4:8802";
// export const baseURL = "http://192.168.1.4:8402";
// export const baseURL = "http://localhost:8200";
export const baseURL = "https://swv3.taxslips.com/"; //for live build
// "homepage": "https://appv3.taxslips.com/",      //this will paste on package.json file 

const http = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Headers":
      "Context-Type, X-Auth-Token, Origin, Authorization",
  },
});

const requestHandler = (request: any) => {
  request.headers[
    "Authorization"
  ] = `Bearer ${LocalStorageService.getUserToken()}`;
  return request;
};

const responseSuccessHandler = (response: AxiosResponse<never>) => {
  return response;
};

const responseErrorHandler = (error: { response: { status: number } }) => {
  if (
    error.response &&
    error.response.status === 401 &&
    window.location.href.includes("/login")
  ) {
    return Promise.resolve(error.response);
  } else if (
    error.response &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    // AuthService.signOut();
    // window.location.href = "/";
    //window.location.reload();
  } else if (error.response && error.response.status === 400) {
    return error.response;
  }

  return Promise.reject(error);
};

http.interceptors.request.use(
  (request: any) => requestHandler(request),
  (error: any) => console.error("error =>", error)
);

http.interceptors.response.use(
  (response: any) => responseSuccessHandler(response),
  (error: any) => responseErrorHandler(error)
);

export default http;
