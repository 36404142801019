import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { FC } from "react";

import { Grid } from "@mui/material";
import { ITest, RHookFormTextFieldWithLabel } from "@websential/cosmic";

interface IQPIPAndPPIPSettingsPanel extends ITest {}

export const QPIPAndPPIPSettingsPanel: FC<IQPIPAndPPIPSettingsPanel> = ({
  testId,
}) => {
  return (
    <Grid
      container
      columnSpacing="6"
      rowSpacing="2"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Total Insurable Earnings (Maximum)"
          id="qpipPpipSettingsTotalInsurableEarningsMaximum"
          testId={`${testId}-total-insurable-earnings-maximum`}
        />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Total Employee Premium (Maximum)"
          id="qpipPpipSettingsTotalEmployeePremiumMaximum"
          testId={`${testId}-total-employee-premium-maximum`}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="Employee Premium Rate (%)"
            id="qpipPpipSettingsEmployeePremiumRatePercentage"
            testId={`${testId}-employee-premium-rate-percentage`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="Employer Premium Rate (%)"
            id="qpipPpipSettingsEmployerPremiumRatePercentage"
            testId={`${testId}-employer-premium-rate-percentage`}
          />
        </FieldWrapper>
      </Grid>
    </Grid>
  );
};
