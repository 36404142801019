import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
      color: "#000000de",
    },
    subTitle: {
      fontWeight: "bold",
      color: "#00000099",
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
      paddingBottom: theme.spacing(4),
    },
    subTitleLink: {
      textDecoration: "none",
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  })
);

export interface IAccountLayoutFormProps {
  /**
   * Title of account form layout
   */
  title: string;
  /**
   * Subtitle of account form layout
   */
  subtitle: string;
  /**
   * Include link in subtitle of account form layout
   */
  subtitleLinkText?: string;
  /**
   * The user will be redirected on which page after click on the link
   */
  subtitleLinkNavigateTo?: string;
  /**
   * Form to be displayed
   */
  form: React.ReactNode;
}

export const AccountFormLayout: FC<IAccountLayoutFormProps> = ({
  title,
  subtitle,
  subtitleLinkText,
  subtitleLinkNavigateTo,
  form,
}) => {
  const classes = useStyles();
  const testId = "form-layout";
  return (
    <Grid container data-testid={testId}>
      <Grid item md={2} lg={4} xl={4}></Grid>
  <Grid item md={8} lg={4} xl={4}>
        <Grid item md={12} lg={12} xl={12} data-testid={testId + "-title"}>
          <Typography variant="h4" className={classes.title} style={{fontWeight: 'bold'}}>
            {title}
          </Typography>
        </Grid>
        <Grid item md={12} lg={12} xl={12} data-testid={testId + "-subtitle"}>
          <Typography variant="subtitle2" className={classes.subTitle} style={{fontWeight: 'bold'}}>
            {subtitle}&nbsp;
            <Link
              to={subtitleLinkNavigateTo ?? ""}
              className={classes.subTitleLink}
              data-testid={testId + "-subtitleLink"}
            >
              {subtitleLinkText}
            </Link>
          </Typography>
        </Grid>
        <Grid item md={12} lg={12} xl={12} data-testid={testId + "-form"}>
          {form}
        </Grid>
      </Grid>
      <Grid item md={2} lg={4} xl={4}></Grid>
    </Grid>
  );
};
