import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { DefaultValues, FieldValue, FormProvider, Mode, useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, useTheme } from "@mui/material";
import Grid, { GridSize } from "@mui/material/Grid";
import { FieldType,
	RHookFormTextFieldWithLabel,
	BusinessNumberRHF,
	EmailFieldRHF,
	URLFieldRHF,
	EnhancedCheckboxRHF,
	EnhancedTextAreaFieldRHF,
	RHookFormPasswordFieldWithLabel,
	RHookFormNumberFieldWithLabel,
	SinNumberRHF,
	EnhancedAndroidSwitchRHF,
	EnhancedIOSSwitchRHF,
	EnhancedBasicSwitchRHF,
	EnhancedMultiDropDownRHFWithLabel,
	EnhancedDropDownRHFWithLabel,
	RHookFormMaskedTextFieldWithLabel,
	EnhancedRadioGroupRHFWithLabel,
RHookFormDatePicker, 
ITest,
TwoColumnLayout,
StandardPanel,
FieldLayout,
LabelPosition,
SingleColumnLayout,
DataType,
ReducedElAccountNumberRHF,
ReducedElAccountNumber,
PostCodeRHF,
ITabLayout} from "@websential/cosmic";
import { getFieldValidationSchema } from "./validator";
import IField from "@websential/cosmic/build/components/RHookFormGenerator/types/IField";
import Values from "@websential/cosmic/build/components/RHookFormGenerator/types/Values";
export enum OnChangeActionType {
    Filter = 'filter',
    Hide = 'hide',
    ReadOnly = 'read_only',
}
interface Props extends ITest {
	fieldType: string;
  }
export const ComponentNotFound: React.FC<Props> = ({ fieldType, testId }) => {
	return (
	  <span data-testid={`${testId}-${fieldType}-not-implemented`}>
		{fieldType} is not implemented!
	  </span>
	);
  };

  interface IProps extends ITest {
	fieldIndex: number;
	fieldColumn: GridSize;
	sequence: number;
	filterBy: string;
	readOnly: boolean;
	children: React.ReactNode;
  }
  
  export const FormFieldGrid: React.FC<IProps> = ({
	fieldIndex,
	fieldColumn,
	sequence,
	children,
	testId,
  }) => {
	return (
	  <Grid
		key={fieldIndex}
		item
		xl={fieldColumn}
		lg={fieldColumn}
		xs={fieldColumn}
		sm={fieldColumn}
		md={fieldColumn}
		data-testid={`${testId}-panel-${sequence}-field-grid-${fieldIndex}`}
	  >
		{children}
	  </Grid>
	);
  };

const FIELD_TYPE_COMPONENT:any = {
	[FieldType.TextField]: RHookFormTextFieldWithLabel,
	[FieldType.BusinessNumber]: BusinessNumberRHF,
	[FieldType.Email]: EmailFieldRHF,
	[FieldType.URL]: URLFieldRHF,
	[FieldType.Checkbox]: EnhancedCheckboxRHF,
	[FieldType.TextArea]: EnhancedTextAreaFieldRHF,
	[FieldType.PasswordTextField]: RHookFormPasswordFieldWithLabel,
	[FieldType.NumberTextField]: RHookFormNumberFieldWithLabel,
	[FieldType.SINNumber]: SinNumberRHF,
	// [FieldType.PayPeriodDropDownList]: PayPeriodDDLRHF,
	// [FieldType.StatusDropDownList]: StatusDDLRHF,
	// [FieldType.CountryDropDownList]: CountryDDLRHF,
	// [FieldType.ProvinceDropDownList]: ProvinceDDLRHF,
	// [FieldType.EmploymentCodeDropDownList]: EmploymentCodeDDLRHF,
	// [FieldType.EmploymentTypeDropDownList]: EmploymentTypeDDLRHF,
	// [FieldType.EmployeeRangeDropDownList]: EmployeeRangeDDLRHF,
	// [FieldType.YesNoDropDownList]: YesNoDDLRHF,
	[FieldType.AndroidSwitch]: EnhancedAndroidSwitchRHF,
	[FieldType.IOSSwitch]: EnhancedIOSSwitchRHF,
	[FieldType.NormalSwitch]: EnhancedBasicSwitchRHF,
	[FieldType.MultiSelectionDropDownList]: EnhancedMultiDropDownRHFWithLabel,
	[FieldType.DropDownList]: EnhancedDropDownRHFWithLabel,
	[FieldType.MaskedTextField]: RHookFormMaskedTextFieldWithLabel,
	[FieldType.RadioGroup]: EnhancedRadioGroupRHFWithLabel,
	[FieldType.Date]: RHookFormDatePicker,
};

export const getFieldName = (fieldId: string, id: number, isFixed: boolean): string => `${fieldId}-${id}${isFixed ? '-fixed' : ''}`;
export const getMultiSelectionDDLDefaultValue = (defaultValue: string): string[] => defaultValue.split(',');
export const getFieldItems = (fieldValue: any, fieldInternalValue: any): { value: string; label: string; }[] => {
	console.log("fieldInternalValue",fieldInternalValue)
    let fieldInternalValueArray = typeof(fieldInternalValue) === 'string' ? (fieldInternalValue as string).split(',') : typeof(fieldInternalValue) === 'object' ? fieldInternalValue : null;

    let data = (fieldValue as string).split(',').map((item, index) => (
        { value: typeof(fieldInternalValue) === 'string' ? fieldInternalValueArray[index].trim() :
                typeof(fieldInternalValue) === 'object' ? fieldInternalValueArray[index] : 
                fieldInternalValueArray[index].trim(), 
          label: item.trim()
        }))

    // return (fieldValue as string).split(',').map((item, index) => ({ value: fieldInternalValueArray[index].trim(), label: item.trim() }))
    return data
};

export interface ICategoryFieldsPanelLayoutProps extends ITest {

	categoryFields: IField[];

	panelLabel: string;

	formDefaultValues?:any;
}

export const CategoryFieldsPanelLayout: React.FC<ICategoryFieldsPanelLayoutProps> =
	({ testId, categoryFields, panelLabel, formDefaultValues }) => {
const theme = useTheme();
const fieldValuesConversionData = useRef<any>({});
const [currentDestFieldsOnChangeRules, setCurrentDestFieldsOnChangeRules] =
	useState<any>({});

const fieldsOnChangeValueRules = useMemo<any>(() => {
	const map:any = {};
	categoryFields.forEach((panelField) => {
		const { fieldId, id, isFixed, onChange } = panelField;
		const fieldName = getFieldName(fieldId, id, isFixed);
		// if the current field has onChange rules that would affect other fields.
		if (onChange) {
			map[fieldName] = onChange;
		}
	});
	return map;
}, [categoryFields]);

const categoryDefaultValues = useMemo<DefaultValues<Values>>(() => {
	const fieldsDefaultValues:any = {};
	categoryFields.forEach((panelField) => {
		const FieldComponent:any = FIELD_TYPE_COMPONENT[panelField.fieldType];
		if (FieldComponent) {
			const { fieldId, id, isFixed, defaultValue, fieldType } = panelField;
			const fieldName = getFieldName(fieldId, id, isFixed);
			let fieldDefaultValue = defaultValue;
			console.log(
				`Cosmic CategoryFieldsPanelLayout => Default Values Setting, fieldName: ${fieldName}, Default Value: ${fieldDefaultValue}, Field Type: ${fieldType}`
			);
			if (fieldType === FieldType.MultiSelectionDropDownList) {
				if (defaultValue) {
					fieldDefaultValue = getMultiSelectionDDLDefaultValue(
						defaultValue as string
					);
				}
			}
			// if the field has a default value
			if (fieldDefaultValue) {
				fieldsDefaultValues[fieldName] = fieldDefaultValue;
			}
		}
	});
	return fieldsDefaultValues;
}, [categoryFields]);
const defaultValues = formDefaultValues ? formDefaultValues : categoryDefaultValues

const formMethods = useForm({
	// mode: validationMode,
	// reValidateMode: revalidateMode,
	// resolver: yupResolver(yup.object().shape(schema)),
	defaultValues,
});
// apply initialValues rules if any
useEffect(() => {
	// if some fields have defaultValue set
	if (Object.keys(defaultValues).length) {
		const newCurrentOnChangeRules:any = {};
		Object.keys(defaultValues).forEach((initialValueFieldName) => {
			const fieldValue = defaultValues[initialValueFieldName];
			const fieldOnChangeItems =
				fieldsOnChangeValueRules[initialValueFieldName];
			// if the initial value affects other fields
			if (fieldOnChangeItems && fieldOnChangeItems[fieldValue]) {
				fieldOnChangeItems[fieldValue].forEach((onChangeItem:any) => {
					const targetFieldName = Object.keys(onChangeItem)[0];
					const targetFieldActionType = onChangeItem[targetFieldName];
					// /**
					//  * we need to reset the target field's value in-case we have
					//  * a cached selected/pre-selected value that no longer can apply
					//  * e.g.
					//  * 1. a selected value for a field that is about to be hidden
					//  * 2. a selected value of an un-filtered dropdown that once filtered
					//  *    will not be an option e.g. the user selects NY as a province then
					//  *    the country selected is Canada.
					//  */
					formMethods.setValue(targetFieldName, undefined);
					newCurrentOnChangeRules[targetFieldName] = {
						sourceField: initialValueFieldName,
						destinationField: targetFieldName,
						sourceFieldValue: fieldValue as string,
						type: targetFieldActionType,
					};
				});
			}
		});
		setCurrentDestFieldsOnChangeRules(newCurrentOnChangeRules);
	}
}, [defaultValues, fieldsOnChangeValueRules, formMethods]);

useEffect(() => {
	const subscription = formMethods.watch(
		(fieldValues, { name: fieldName }) => {
			const fieldOnChangeItems =
				fieldsOnChangeValueRules[fieldName as string];
			// if this is meant to be a watched field since it expects at least one of its values to change the behavior of at least 1 other field
			if (fieldOnChangeItems) {
				const fieldValue = fieldValues[fieldName as string];
				// check if the newly selected value is mapped in onChange map of the current field
				if (fieldOnChangeItems[fieldValue]) {
					const newCurrentOnChangeRules:any = {};
					fieldOnChangeItems[fieldValue].forEach((onChangeItem:any) => {
						const targetFieldName = Object.keys(onChangeItem)[0];
						const targetFieldActionType = onChangeItem[targetFieldName];
						/**
						 * we need to reset the target field's value in-case we have
						 * a cached selected/pre-selected value that no longer can apply
						 * e.g.
						 * 1. a selected value for a field that is about to be hidden
						 * 2. a selected value of an un-filtered dropdown that once filtered
						 *    will not be an option e.g. the user selects NY as a province then
						 *    the country selected is Canada.
						 */
						formMethods.setValue(targetFieldName, undefined);
						newCurrentOnChangeRules[targetFieldName] = {
							sourceField: fieldName,
							destinationField: targetFieldName,
							sourceFieldValue: fieldValue as string,
							type: targetFieldActionType,
						};
					});
					setCurrentDestFieldsOnChangeRules((prevCurrentOnChangeRules:any) => {
						// 1. get any onChange rule that belongs to the current field
						const currentFieldOnChangeRulesMap = Object.values(
							prevCurrentOnChangeRules
						).filter(
							(onChangeRule:any) => onChangeRule.sourceField === fieldName
						);
						// 2. delete them from the list of rules to make sure only new rules will be applied
						currentFieldOnChangeRulesMap.forEach(
							(currentFieldOnChangeRule:any) => {
								delete prevCurrentOnChangeRules[
									currentFieldOnChangeRule.destinationField
								];
							}
						);
						return {
							...prevCurrentOnChangeRules,
							...newCurrentOnChangeRules,
						};
					});
				} else {
					// remove any rules of the current field because the user selected a normal value that doesn't have a rule
					setCurrentDestFieldsOnChangeRules((prevCurrentOnChangeRules:any) => {
						// 1. get any onChange rule that belongs to the current field
						const currentFieldOnChangeRulesMap = Object.values(
							prevCurrentOnChangeRules
						).filter(
							(onChangeRule:any) => onChangeRule.sourceField === fieldName
						);
						// 2. delete them from the list of rules to make sure only new rules will be applied
						currentFieldOnChangeRulesMap.forEach(
							(currentFieldOnChangeRule:any) => {
								delete prevCurrentOnChangeRules[
									currentFieldOnChangeRule.destinationField
								];
							}
						);
						return { ...prevCurrentOnChangeRules };
					});
				}
			}
		}
	);
	return () => subscription.unsubscribe();
}, [fieldsOnChangeValueRules, formMethods]);
function stringToDate(arg0: string, fieldFormat: string) {
	throw new Error("Function not implemented.");
}

function dateIsAfterToday(maxDate: never) {
	throw new Error("Function not implemented.");
}

return (
		<FormProvider {...formMethods}>
			<StandardPanel
				title={panelLabel}
				testId={`${panelLabel}-panel`}
				topPadding={2}
			>
				<Grid container sx={{ display: "grid", placeItems: "center" }}>
					<Grid
						key={1}
						item
						xl={12}
						lg={12}
						xs={12}
						sm={12}
						md={12}
						data-testid={`${testId}-panel-${panelLabel}-grid`}
						sx={{ marginTop: theme.spacing(7), minWidth:categoryFields && categoryFields.length === 1 ? "50%" : undefined }}
					>
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={2}
							sx={{ "& .MuiGrid-item": { paddingTop: 0 } }}
						>
							{categoryFields.map((panelField, fieldIndex) => {
								const {
									fieldId,
									id,
									isFixed,
									fieldLabel,
									placeholder,
									isRequired,
									fieldValue,
									fieldInternalValue,
									fieldType,
									uppercase,
									maxValue,
									minValue,
									fieldFormat,
									labelPosition,
									labelColumn,
									fieldColumn,
									fullFieldColumns,
									readOnly,
									onChange,
								} = panelField;
								const FieldComponent = FIELD_TYPE_COMPONENT[fieldType];
								// if the field component has not been implemented yet
								let fieldComponent: JSX.Element;
								if (!FieldComponent) {
									return (
										<FieldLayout
											label={fieldLabel}
											isRequired={isRequired}
											testId={testId}
											labelPosition={labelPosition as LabelPosition}
											labelColumns={labelColumn}
											fieldColumns={fieldColumn}
										>
											<ComponentNotFound
												key={fieldIndex}
												fieldType={fieldType}
												testId={testId}
											/>
										</FieldLayout>
									);
								}
								const fieldTestId = `${testId}-${fieldIndex}-${fieldId}`;
								const fieldName = getFieldName(fieldId, id, isFixed);
								let defaultValue = defaultValues[fieldName];
								let onChangeFields = { filterBy: "" };
								let setReadOnly = false;
								if (currentDestFieldsOnChangeRules[fieldName]) {
									const actionType =
										currentDestFieldsOnChangeRules[fieldName].type;
									// we should not show the field at all
									if (actionType === OnChangeActionType.Hide) {
										return <></>;
									} else if (actionType === OnChangeActionType.Filter) {
										onChangeFields = {
											...onChangeFields,
											filterBy:
												currentDestFieldsOnChangeRules[fieldName]
													.sourceFieldValue,
										};
									} else if (actionType === OnChangeActionType.ReadOnly) {
										setReadOnly = true;
									}
								}
								// common props between all the components representing form fields
								const componentsCommonProps = {
									id: fieldId,
									label: fieldLabel,
									required: !!isRequired,
									labelPosition: labelPosition,
									labelColumns: labelColumn,
									fieldColumns: fieldColumn,
									onChange: onChange,
								};
								if (fieldType === FieldType.Checkbox) {
									fieldComponent = (
										<FieldComponent
											{...componentsCommonProps}
											name={fieldName}
											checked={!!defaultValue}
											testId={fieldTestId}
											readOnly={setReadOnly}
											checkboxLabel={fieldLabel}
											onSelectionChangeHandler={onChange}
										/>
									);
								} else if (fieldType === FieldType.TextArea) {
									fieldComponent = (
										<FieldComponent
											{...componentsCommonProps}
											id={fieldName}
											placeholder={placeholder}
											uppercase={!!uppercase}
											{...(fieldValue && {
												value: fieldValue,
											})}
											{...(maxValue && {
												maxRows: maxValue,
											})} // use maxValue as an indication of the max rows for now
											testId={fieldTestId}
										/>
									);
								} else if (fieldType === FieldType.MaskedTextField) {
									fieldComponent = (
										<FieldComponent
											{...componentsCommonProps}
											id={fieldName}
											placeholder={placeholder}
											{...(defaultValue && {
												defaultValue: defaultValue,
											})}
											{...(fieldFormat && {
												inputMaskPattern: fieldFormat,
											})}
											testId={fieldTestId}
											readOnly={setReadOnly}
											// TODO: add regex validation pattern when it's integrated and ready.
										/>
									);
								} else if (
									fieldType === FieldType.MultiSelectionDropDownList
								) {
									fieldComponent = (
										<FieldComponent
											{...onChangeFields}
											label={fieldLabel}
											required={!!isRequired}
											selectName={fieldName}
											placeholder={placeholder}
											{...(fieldValue && {
												items: getFieldItems(fieldValue, fieldInternalValue),
											})}
											{...(defaultValue && {
												selectedItems: defaultValue,
											})}
											testId={fieldTestId}
											labelPosition={labelPosition}
											onSelectionChangeHandler={onChange}
										/>
									);
								} else if (fieldType === FieldType.DropDownList) {
									fieldComponent = (
										<FieldComponent
											{...onChangeFields}
											label={fieldLabel}
											required={!!isRequired}
											selectName={fieldName}
											// selectedItem={}
											placeholder={placeholder}
											showEmpty={false} // only show empty when it's not required
											{...(fieldValue && {
												items: getFieldItems(fieldValue, fieldInternalValue),
											})}
											{...(defaultValue && {
												selectedItem: defaultValue,
											})}
											isDisabled={readOnly || setReadOnly}
											isSearchable={false}
											testId={fieldTestId}
											labelPosition={labelPosition}
											fieldColumns={fieldColumn}
											labelColumns={labelColumn}
											onSelectionChangeHandler={onChange}
										/>
									);
								} else if (fieldType === FieldType.Date) {
									const minDate:any = minValue
										? stringToDate(String(minValue), fieldFormat)
										: null;
									const maxDate:any = maxValue
										? stringToDate(String(maxValue), fieldFormat)
										: null;
									let shouldDisableFutureDates = true;
									// if (maxDate) {
									// 	shouldDisableFutureDates = !dateIsAfterToday(maxDate);
									// }
									// indicate that when the current field is submitted, we need to convert it from date back to string
									fieldValuesConversionData.current = {
										...fieldValuesConversionData.current,
										[fieldName]: {
											from: DataType.Date,
											to: DataType.String,
											// format: "MMM DD, YYYY",
										},
									};
									fieldComponent = (
										<FieldComponent
											{...componentsCommonProps}
											name={fieldName}
											// format={fieldFormat}
											disableFuture={false}
											{...(defaultValue && {
												initialDate: defaultValue,
												// stringToDate(
												//   defaultValue,
												//   fieldFormat
												// ),
											})}
											{...(minDate && { minDate })}
											{...(maxDate && { maxDate })}
											testId={fieldTestId}
											onChange={onChange}
										/>
									);
								} else if (fieldType === FieldType.RadioGroup) {
									fieldComponent = (
										<FieldComponent
											{...componentsCommonProps}
											name={fieldName}
											{...(defaultValue && {
												selectedValue: defaultValue,
											})}
											{...(fieldValue && {
												options: getFieldItems(
													fieldValue,
													fieldInternalValue
												),
											})}
											testId={fieldTestId}
											readOnly={setReadOnly}
											onChangeHandler={onChange}
										/>
									);
								} else if (/.*DropDownList/.test(fieldType)) {
									// if it's a DropdownList component e.g. CountryDropDownList or ProvinceDropDownList or PayPeriodDropDownList
									fieldComponent = (
										<FieldComponent
											{...onChangeFields}
											label={fieldLabel}
											selectName={fieldName}
											isRequired={!!isRequired}
											placeholder={placeholder}
											showEmpty={!isRequired} // only show empty when it's not required
											{...(defaultValue && {
												selectedItem: defaultValue,
											})}
											testId={fieldTestId}
											onSelectionChangeHandler={onChange}
											onChange={onChange}
										/>
									);
								} else if (/.*Switch/.test(fieldType)) {
									// if it's a Switch component e.g. EnhancedAndroidSwitch or EnhancedIOSSwitch or EnhancedNormalSwitch
									fieldComponent = (
										<FieldComponent
											label={fieldLabel}
											name={fieldName}
											placeholder={placeholder}
											{...(defaultValue && {
												checked: defaultValue,
											})}
											testId={fieldTestId}
											readOnly={setReadOnly}
											onSelectionChangeHandler={onChange}
										/>
									);
								} else {
									fieldComponent = (
										<FieldComponent
											{...onChangeFields}
											{...componentsCommonProps}
											id={fieldName}
											placeholder={placeholder}
											{...(fieldValue && {
												value: fieldValue,
											})}
											testId={fieldTestId}
										/>
									);
								}
								return (
									<FormFieldGrid
										key={fieldIndex}
										fieldIndex={fieldIndex}
										fieldColumn={fullFieldColumns ? fullFieldColumns : 12}
										testId={testId}
										sequence={1}
										filterBy={onChangeFields.filterBy}
										readOnly={setReadOnly}
									>
										{fieldComponent}
									</FormFieldGrid>
								);
							})}
						</Grid>
					</Grid>
				</Grid>
			</StandardPanel>
		</FormProvider>
	);
};
