import {
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  T4_SUMMARY_RESUCED_EI_SCREEN_SLUG,
} from "common/constants";
import { instanceOfIFlexFieldSetupRequest } from "common/instance-method";
import { TaxSlipAccountInformation } from "components/TaxSlipAccountInformation";
import {
  TaxSlipFilingResourceSelectionPanel,
  isLoadingselectedSlipType,
  selectedSlipType,
} from "components/TaxSlipFilingResource";
import { IResponse, IServerError } from "models/common";
import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FlexFieldService } from "services/FlexFieldService";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import FileSaver from "file-saver";
import {
  BackdropCircularProgress,
  EnhancedDivider,
  EnhancedDropDownRHF,
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  EnhancedTextAreaFieldRHF,
  FlatStandardPanel,
  ITest,
  LabelPosition,
  RHookFormTextField,
  RequiredAsterisk,
  SingleColumnLayout,
  Spacer,
} from "@websential/cosmic";

import { T4SummaryToolbar } from "../T4SummaryToolbar";
import {
  DifferencePanel,
  EmployerDetailPanel,
  T4SlipDetailPanel,
  TotalBalancePanel,
} from "./Panels";
import { getValidationSchema } from "./validator";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { PrintingService } from "services/PrintingService";
import { T4SlipService } from "services/T4SlipService";
import { T4SummarySetupSchema } from "../T4SlipSummarySetup/summaryValidate";
import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import {
  EnhancedDialog,
  EnhancedLinkButton,
  IconTextButton,
  RouterLinkButton,
  SplitButton,
} from "@websential/cosmic";
import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions";
import { InvoiceDialogPanel } from "../T4SlipList/InvoiceDialogPanel";
import { checkSummaryType } from "../T4SlipSummarySetup";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { ClientService } from "services";
export interface IT4SlipSummaryReducedEISetup extends ITest {}
export let selectedT4SummaryReduceEIType: any = 400;

const T4_SUMMARY_DATA_QUERY_KEY = "get-t4-summary-data";
const useGetT4SummaryReduceEIData = (
  clientid: string,
  slipType: string | number,
  taxYear: number
) =>
  useQuery(
    [T4_SUMMARY_DATA_QUERY_KEY, clientid, slipType, taxYear],
    (data) => {
      return T4SlipService.getT4SummaryReduceEI(clientid, slipType, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

const useGetTransmitterDDL = (clientId: string, taxYear: number) =>
  useQuery(
    ["transmitters"],
    () => {
      return T4SlipService.getAllActiveTransmittersByClientIdAndTaxYear(
        clientId,
        taxYear
      );
    },
    { refetchOnWindowFocus: false }
  );

const useGetIssuerDDL = (clientId: string, taxYear: number) =>
  useQuery(
    ["Issuers"],
    () => {
      return T4SlipService.getAllActiveIssuersByClientIdAndTaxYear(
        clientId,
        taxYear
      );
    },
    { refetchOnWindowFocus: false }
  );
const useGetContactPersonDDL = (clientId: string, taxYear: number) =>
  useQuery(
    ["contact-person"],
    () => {
      return T4SlipService.getAllActiveContactPersonsByClientIdAndTaxYear(
        clientId,
        taxYear
      );
    },
    { refetchOnWindowFocus: false }
  );

  const useGetSlipIds = (
    tag: string,
    clientId: string,
    taxYear: number
  ) =>
    useQuery(
      ["get-slip-ids", tag, clientId, taxYear],
      (data) => {
        return T4SlipService.getSlipIds(tag, clientId, taxYear);
      },
      { refetchOnWindowFocus: false }
    );

const useGetCompanyData = (id: string | undefined) => 
  useQuery(
    ["client-get-by-id", id],
    (data) => {
      return ClientService.getById(id);
    },
    { refetchOnWindowFocus: false }
  );

export const T4SlipSummaryReducedEISetup: FC<IT4SlipSummaryReducedEISetup> = ({
  testId,
}) => {
  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const clientId = useSelector((state: IAppState) => state.client.id);
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [SummaryResponseToPass, setSummaryResponseToPass] =
    useState<any>(undefined);
  const [openInvoiceDialog, setInvoiceDialogOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [invoicePaymentFor, setInvoicePaymentFor] = useState(10)

  const [selectedType, setSelectedType] = useState(400);
  const [selectedIssuer, setSelectedIssuer] = useState("");
  const [selectedContactPerson, setSelectedContactPerson] = useState("");
  const [isLoadingIssuerSelect, setIsLoadingIssuerSelect] = useState(false);

  const maskedStatus: any = sessionStorage.getItem("maskEnabled");

  const formMethods = useForm<any>({
    resolver: yupResolver(T4SummarySetupSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    // defaultValues: DEFAULT_VALUES,
  });

  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    T4SlipService.createT4SummaryReducedEI,
    {
      onSuccess: (res: any | IServerError | IResponse | any) => {
        if (res.status === 201 || res.status === 200) {
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
        } else {
          setSnackbarData({
            open: true,
            message:
              res && res.message
                ? res.message
                : res.data.message
                ? res.data.message
                : "Something went wrong!",
            severity: "error",
          });
        }
      },
      onError: (err: any) => {
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const {
    isLoading: isLoadingSummaryGetData,
    isFetching: reFetchingSlipType,
    refetch: refetchSlipType,
    data: t4SummaryReduceEIData,
  } = useGetT4SummaryReduceEIData(clientId, selectedType, taxYear);
  console.log("selectedType", selectedType);

  console.log("t4SummaryReduceEIData ============", t4SummaryReduceEIData);

  /**
   * Handle when the form is submitted.
   *
   * @param {IFlexFieldSetupRequest} formData
   */

  const onSubmit = (formData: any) => {
    let Payload = {
      id: clientId,
      taxYear: taxYear,
      minusRemittance:
        formData.minusRemittance === "0.00" || ""
          ? undefined
          : parseFloat(formData.minusRemittance.replace(/[$,]/g, "")),
      slipType: selectedType,
      confirmationCode:
        formData.confirmationCode !== ""
          ? formData.confirmationCode
          : undefined,
      notes: formData.notes !== "" ? formData.notes : undefined,
      transmitter: formData.valueOfTransmitter,
      issuer: formData.valueOfIssuer,
      contactPerson: formData.valueOfContactPerson,
    };
    submitFormData(Payload);
    console.log("formData ========== ", formData);
  };

  const onCloseHandler = () => {
    setInvoiceDialogOpen(false);
  };

  const saveXmlAsFile = (xmlData: any, filename: any) => {
    const blob = new Blob([xmlData], { type: "xml;charset=utf-8" });
    FileSaver.saveAs(blob, filename);
  };
  const onGenerateXMLClicked = async (): Promise<void> => {
    setIsLoadingData(true);
    if (maskedStatus === "true") {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else if (
      t4SummaryReduceEIData &&
      t4SummaryReduceEIData.transmitter === null &&
      t4SummaryReduceEIData.issuer === null &&
      t4SummaryReduceEIData.contactPerson === null
    ) {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message:
          "Please select and submit transmitter, issuer and contact person.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(t4SlipIds && t4SlipIds.generalAuditStatus !== null && t4SlipIds.generalAuditStatus === true &&
              t4SlipIds && t4SlipIds.auditStatus !== null &&  t4SlipIds.auditStatus === true){
              setIsLoadingData(true);
              try {
                const response: any = await PrintingService.createT4Xml(
                  clientId,
                  400,
                  false,
                  taxYear
                );
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    severity: "error",
                  });
                } else {
                  const filename = `T4Summaryxml-${clientName}.xml`;
                  saveXmlAsFile(response, filename);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
                alert(err);
              }
            // }
            setIsLoadingData(false);
            } else {
              setIsLoadingData(false);
              setSnackbarData({
              open: true,
              message: (
                <>
                  Please load Audit in the menu, press Run T4 Audit and clear all audit items (Audit Filing and PIER Review) to generate documents.{" "}
                  <a
                    href="https://appv3.taxslips.com/#/audit/filing"
                    style={{
                      color: "#aa1010",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Click here to view
                  </a>
                </>
              ),
              severity: "error",
              autoHide: false,
            });
          }

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
    try {
      const response: any = await ReportsService.createInvoice(
        clientId,
        taxYear
      );
      setIsLoadingData(false);
      console.log("responseresponse", response);
      if (response.message === "Slips not found for invoice.") {
        // setIsLoadingData(true);
        // try {
        //   const filingAuditResponse: any = await T4SlipService.getT4AuditFiling(
        //     clientId,
        //     taxYear
        //   );
        //   console.log("filingAuditResponse", filingAuditResponse);
        //   if (
        //     filingAuditResponse &&
        //     filingAuditResponse.general_filling &&
        //     filingAuditResponse.general_filling.length > 0
        //   ) {
        //     console.log(
        //       "filingAuditResponse.general_filling",
        //       filingAuditResponse.general_filling
        //     );
        //     setSnackbarData({
        //       open: true,
        //       message: (
        //         <>
        //           Please load Audit in the menu and clear all audit items (Audit
        //           Filing and PIER Review) to generate documents.{" "}
        //           <a
        //             href="https://appv3.taxslips.com/#/audit/filing"
        //             style={{
        //               color: "#aa1010",
        //               textDecoration: "none",
        //               fontWeight: "bold",
        //             }}
        //           >
        //             Click here to view
        //           </a>
        //         </>
        //       ),
        //       severity: "error",
        //       autoHide: false,
        //     });
        //   } else if (
        //     filingAuditResponse &&
        //     filingAuditResponse.t4_filling &&
        //     filingAuditResponse.t4_filling.length > 0
        //   ) {
        //     console.log(
        //       "filingAuditResponse.t4_filling",
        //       filingAuditResponse.t4_filling
        //     );
        //     setSnackbarData({
        //       open: true,
        //       message: (
        //         <>
        //           Please load Audit in the menu and clear all audit items (Audit
        //           Filing and PIER Review) to generate documents.{" "}
        //           <a
        //             href="https://appv3.taxslips.com/#/audit/filing"
        //             style={{
        //               color: "#aa1010",
        //               textDecoration: "none",
        //               fontWeight: "bold",
        //             }}
        //           >
        //             Click here to view
        //           </a>
        //         </>
        //       ),
        //       severity: "error",
        //       autoHide: false,
        //     });
        //   } else {
          if(t4SlipIds && t4SlipIds.generalAuditStatus !== null && t4SlipIds.generalAuditStatus === true &&
            t4SlipIds && t4SlipIds.auditStatus !== null &&  t4SlipIds.auditStatus === true){
            setIsLoadingData(true);
            try {
              const response: any = await PrintingService.createT4Xml(
                clientId,
                400,
                false,
                taxYear
              );
              if (response.status === 400) {
                setSnackbarData({
                  open: true,
                  message: response.data.message
                    ? response.data.message
                    : "Something went wrong.",
                  severity: "error",
                });
              } else {
                const filename = `T4Summaryxml-${clientName}.xml`;
                saveXmlAsFile(response, filename);
              }
              setIsLoadingData(false);
            } catch (err) {
              console.log("err", err);
              alert(err);
            }
          // }
          setIsLoadingData(false);
          } else {
            setIsLoadingData(false);
            setSnackbarData({
            open: true,
            message: (
              <>
                Please load Audit in the menu, press Run T4 Audit and clear all audit items (Audit Filing and PIER Review) to generate documents.{" "}
                <a
                  href="https://appv3.taxslips.com/#/audit/filing"
                  style={{
                    color: "#aa1010",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Click here to view
                </a>
              </>
            ),
            severity: "error",
            autoHide: false,
          });
        }
        // } catch (err: any) {
        //   setIsLoadingData(false);
        //   alert(err);
        // }
      } else if (response && response.id !== null) {
        setInvoiceData([response]);
        setInvoiceDialogOpen(true);
      } else {
        setSnackbarData({
          open: true,
          message: "Something went wrong.",
          severity: "error",
        });
      }
      setIsLoadingData(false);
    } catch (err) {
      console.log("err", err);
      setIsLoadingData(false);
    }
  }
  }
};
  const savePdfAsFile = (pdfData: any, filename: any) => {
    const blob = new Blob([pdfData], { type: "application/pdf" });
    FileSaver.saveAs(blob, filename);
  };
  const onPrintClicked = async (): Promise<void> => {
    setIsLoadingData(true);
    const maskedStatus: any = sessionStorage.getItem("maskEnabled");
    if (maskedStatus === "true") {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else if (
      t4SummaryReduceEIData &&
      t4SummaryReduceEIData.issuer === null &&
      t4SummaryReduceEIData.contactPerson === null
    ) {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please select and submit issuer and contact person.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(t4SlipIds && t4SlipIds.generalAuditStatus !== null && t4SlipIds.generalAuditStatus === true &&
              t4SlipIds && t4SlipIds.auditStatus !== null &&  t4SlipIds.auditStatus === true){
              setIsLoadingData(true);
                try {
                  const response: any =
                    await PrintingService.createT4SummaryPdfPrint(
                      clientId,
                      400,
                      true,
                      taxYear
                    );
                  if (response.status === 400) {
                    setSnackbarData({
                      open: true,
                      message: response.data.message
                        ? response.data.message
                        : "Something went wrong.",
                      severity: "error",
                    });
                  } else {
                    const filename = `T4Summary-${clientName}.pdf`;
                    savePdfAsFile(response, filename);
                  }
                  setIsLoadingData(false);
                } catch (err) {
                  console.log("err", err);
                  alert(err);
                }
              // }
              setIsLoadingData(false);
            } else {
              setIsLoadingData(false);
              setSnackbarData({
              open: true,
              message: (
                <>
                  Please load Audit in the menu, press Run T4 Audit and clear all audit items (Audit Filing and PIER Review) to generate documents.{" "}
                  <a
                    href="https://appv3.taxslips.com/#/audit/filing"
                    style={{
                      color: "#aa1010",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Click here to view
                  </a>
                </>
              ),
              severity: "error",
              autoHide: false,
            });
          }

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (response.message === "Slips not found for invoice.") {
          setIsLoadingData(true);
          // try {
          //   const filingAuditResponse: any =
          //     await T4SlipService.getT4AuditFiling(clientId, taxYear);
          //   console.log("filingAuditResponse", filingAuditResponse);
          //   if (
          //     filingAuditResponse &&
          //     filingAuditResponse.general_filling &&
          //     filingAuditResponse.general_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.general_filling",
          //       filingAuditResponse.general_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else if (
          //     filingAuditResponse &&
          //     filingAuditResponse.t4_filling &&
          //     filingAuditResponse.t4_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.t4_filling",
          //       filingAuditResponse.t4_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else {
          //     setIsLoadingData(true);
          if(t4SlipIds && t4SlipIds.generalAuditStatus !== null && t4SlipIds.generalAuditStatus === true &&
            t4SlipIds && t4SlipIds.auditStatus !== null &&  t4SlipIds.auditStatus === true){
            setIsLoadingData(true);
              try {
                const response: any =
                  await PrintingService.createT4SummaryPdfPrint(
                    clientId,
                    400,
                    true,
                    taxYear
                  );
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    severity: "error",
                  });
                } else {
                  const filename = `T4Summary-${clientName}.pdf`;
                  savePdfAsFile(response, filename);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
                alert(err);
              }
            // }
            setIsLoadingData(false);
          } else {
            setIsLoadingData(false);
            setSnackbarData({
            open: true,
            message: (
              <>
                Please load Audit in the menu, press Run T4 Audit and clear all audit items (Audit Filing and PIER Review) to generate documents.{" "}
                <a
                  href="https://appv3.taxslips.com/#/audit/filing"
                  style={{
                    color: "#aa1010",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Click here to view
                </a>
              </>
            ),
            severity: "error",
            autoHide: false,
          });
        }
          // } catch (err: any) {
          //   setIsLoadingData(false);
          //   alert(err);
          // }
        } else if (response && response.id !== null) {
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    }
    }
  };

  const onDeleteClicked = async (): Promise<void> => {
    //delete confirmation code
    try {
      setIsLoadingData(true);
      const response: any =
        await T4SlipService.t4SummaryReduceEIclearConfirmationCode(
          clientId,
          checkSummaryType(
            t4SummaryReduceEIData && t4SummaryReduceEIData.slipType
          ),
          taxYear
        );
      console.log("response64", response);
      if (response.status === 200) {
        setSnackbarData({
          open: true,
          message: response.data.message,
          severity: "success",
        });
        refetchSlipType();
      } else {
        setSnackbarData({
          open: true,
          message:
            response &&
            response.message !== undefined &&
            typeof (response.message && response.message) !== "object"
              ? response.message && response.message
              : response.message.message
              ? response.message.message
              : "Something went wrong!",
          severity:
            response.status === false
              ? "error"
              : response.success === false
              ? "error"
              : "success",
        });
      }
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log("err", err);
    }
  };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  useEffect(() => {
    console.log("===-use Effect called");
    // if the selected flex field data has loaded.
    if (
      t4SummaryReduceEIData &&
      t4SummaryReduceEIData.message
      //&& t4SummaryReduceEIData.message.status_code === 404
    ) {
      console.log("inside t4SummaryReduceEIData ============");
      formMethods.reset();
      formMethods.setValue("totalNumberOfT4SlipsFiled", 0);
      formMethods.setValue("employmentIncome", "0.00");
      formMethods.setValue("registerPensionPlanRppContribution", "0.00");
      formMethods.setValue("pensionAdjustment", "0.00");
      formMethods.setValue("employeesCppContribution", "0.00");
      formMethods.setValue("employeesSecondCppContribution", "0.00");
      formMethods.setValue("employersCppContribution", "0.00");
      formMethods.setValue("employersSecondCppContribution", "0.00");
      formMethods.setValue("employeesEiContribution", "0.00");
      formMethods.setValue("employersEiContribution", "0.00");
      formMethods.setValue("incomeTaxDeducted", "0.00");
      formMethods.setValue("totalDeductionReported", "0.00");
      formMethods.setValue("difference", "0.00");
      formMethods.setValue("overpayment", "0.00");
      formMethods.setValue("balanceDue", "0.00");
      formMethods.setValue("minusRemittance", "0.00");
      setSnackbarData({
        open: true,
        message:
          t4SummaryReduceEIData && t4SummaryReduceEIData.message
            ? t4SummaryReduceEIData.message.message
            : "Something went wrong!",
        severity: "error",
        autoHide: true,
      });
    } else if (t4SummaryReduceEIData) {
      Object.keys(t4SummaryReduceEIData).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = t4SummaryReduceEIData[fieldName];
        // @ts-ignore
        formMethods.setValue(fieldName, fieldValue ?? undefined);
        console.log(
          `fieldName : ${fieldName} ,,,,, field Value : ${fieldValue}`
        );

        // set all values to decimal
        if (fieldName === "registerPensionPlanRppContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "employmentIncome") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "pensionAdjustment") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "employeesCppContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "employeesSecondCppContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "employersCppContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "employersSecondCppContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "employeesEiContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "employersEiContribution") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "incomeTaxDeducted") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "totalDeductionReported") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "difference") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "overpayment") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "balanceDue") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "minusRemittance") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "transmitter") {
          formMethods.setValue("valueOfTransmitter", fieldValue);
        }
        if (fieldName === "issuer") {
          formMethods.setValue("valueOfIssuer", fieldValue);
        }
        if (fieldName === "contactPerson") {
          formMethods.setValue("valueOfContactPerson", fieldValue);
        }
        if (fieldName === "ownerSin1") {
          formMethods.setValue(
            fieldName,
            fieldValue
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
              : undefined
          );
        }
        if (fieldName === "ownerSin2") {
          formMethods.setValue(
            fieldName,
            fieldValue
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
              : undefined
          );
        }
        if (fieldName === "phone1") {
          formMethods.setValue(
            fieldName,
            fieldValue
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
              : undefined
          );
        }
      });
    }
  }, [t4SummaryReduceEIData, formMethods.setValue, formMethods]);

  useEffect(() => {
    if (selectedSlipType) {
      refetchSlipType();
    }
  }, [refetchSlipType, selectedSlipType]);

  const onSlipTypeSelectionChangeHandler = (e: any) => {
    setSelectedType(e.value);

    const selectedType = e.value;
    selectedT4SummaryReduceEIType = selectedType;
  };

  const { isLoading: isLoadingTransmitterDDL, data: transmitterDDL } =
    useGetTransmitterDDL(clientId, taxYear);
  console.log("transmitterDDL", transmitterDDL);

  const { isLoading: isLoadingIssuerDDL, data: issuerDDL } = useGetIssuerDDL(
    clientId,
    taxYear
  );
  console.log("transmitterDDL", issuerDDL);

  const { isLoading: isLoadingContactPersonDDL, data: contactPersonDDL } =
    useGetContactPersonDDL(clientId, taxYear);
  console.log("transmitterDDL", contactPersonDDL);

  const { isLoading:loadingSlipIds, data:t4SlipIds } = useGetSlipIds("T4", clientId, taxYear);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  useEffect(()=>{
    console.log("companyGetByIdRecord11",companyGetByIdRecord)
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor ? parseInt(paymentFor.value) : 10)
    }
  },[companyGetByIdRecord])

  //when isssuer selection chanhe sin1 abd sin 2 would be updated by using get by id of filing ewsource
  const onIssuerSelectionChangeHandler = async (
    selectedItem: any
  ): Promise<void> => {
    console.log("selectedItem.value", selectedItem.value);

    try {
      setIsLoadingIssuerSelect(true);
      setTimeout(() => {
        setIsLoadingIssuerSelect(false);
      }, 1000);
      const response = await T4SlipService.getIssuerDataById(
        selectedItem.value,
        clientId
      );

      console.log("responseData", response);
      setSelectedIssuer(response);
    } catch (err) {
      console.log("err", err);
      // alert(err);
    }
  };
  //update value of sin1 and sin 2
  useEffect(() => {
    if (selectedIssuer) {
      Object.keys(selectedIssuer).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = selectedIssuer[fieldName];
        // @ts-ignore
        if (fieldName === "ownerSin1") {
          formMethods.setValue(
            fieldName,
            fieldValue
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
              : undefined
          );
        }
        if (fieldName === "ownerSin2") {
          formMethods.setValue(
            fieldName,
            fieldValue
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
              : undefined
          );
        }
      });
    }
  }, [selectedIssuer, formMethods.setValue, formMethods]);

  //when Contact Person selection chanhe sin1 abd sin 2 would be updated by using get by id of filing ewsource
  const onContactPersonSelectionChangeHandler = async (
    selectedItem: any
  ): Promise<void> => {
    console.log("selectedItem.value", selectedItem.value);

    try {
      setIsLoadingIssuerSelect(true);
      setTimeout(() => {
        setIsLoadingIssuerSelect(false);
      }, 1000);
      const response = await T4SlipService.getContactPersonDataById(
        selectedItem.value,
        clientId
      );

      console.log("responseData Contact Person", response);
      setSelectedContactPerson(response);
    } catch (err) {
      console.log("err", err);
      // alert(err);
    }
  };
  //update value of sin1 and sin 2
  useEffect(() => {
    if (selectedContactPerson) {
      Object.keys(selectedContactPerson).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = selectedContactPerson[fieldName];
        // @ts-ignore
        if (fieldName === "phone1") {
          formMethods.setValue(
            fieldName,
            fieldValue
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
              : undefined
          );
        }
      });
    }
  }, [selectedContactPerson, formMethods.setValue, formMethods]);

  // setSummaryResponseToPass(12121212)

  const onUnPaidClickHandler = (clickedRow: any, tag:string) => {
    setIsLoadingData(true);
    // setTimeout(() => {
    //   setIsLoadingData(false);
    // }, 2500);
    const fetchData = async () => {
      try {
        const response: any =
          await ReportsService.paypalIntegrationOfUnpaidInvoice(
            clientId,
            clickedRow.refNo,
            tag
          );
        setIsLoadingData(false);
        console.log("responseData111-----------------", response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const onClickInvoiceNoHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: T4_SUMMARY_RESUCED_EI_SCREEN_SLUG,
        companyName: clientName,
        activityType:
          "activity_type=updated&activity_type=delete&activity_type=print&activity_type=print_xml",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({ isSticky: true }));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={
          isSubmitting ||
          isLoadingSummaryGetData ||
          reFetchingSlipType ||
          isLoadingselectedSlipType ||
          isLoadingTransmitterDDL ||
          isLoadingIssuerDDL ||
          isLoadingContactPersonDDL ||
          isLoadingIssuerSelect ||
          isLoadingData
        }
        testId={testId}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            toolbar={
              <T4SummaryToolbar
                isSubmitting={isSubmitting}
                onGenerateXMLClicked={onGenerateXMLClicked}
                onPrintClicked={onPrintClicked}
                onDeleteClicked={onDeleteClicked}
                SummaryResponseToPass={SummaryResponseToPass}
              />
            }
            testId="t4-slip-summary-setup-form"
          >
            <Paper>
              <FlatStandardPanel
                title="Account Information"
                testId={`${testId}-account-information-panel`}
                showTitle={false}
                topPadding={3}
                leftSpacing={2}
                rightSpacing={2}
              >
                <TaxSlipAccountInformation
                  logoURL={`${process.env.PUBLIC_URL}/images/cra_logo.png`}
                  accountIdentifierTitle="Employer's account number"
                  accountIdentifier="121212120RP0001"
                  accountInformationMessage="You have to file your T4 information return on or before the last day of February."
                  accountName="HELLOFRESH"
                  accountAddressLine1="House # 34, Street twenty three Link Road Cavalary Ground"
                  accountCity=" Toronto"
                  accountProvince="ON"
                  accountPostalCode="W3R4T5"
                  companyInformation={
                    t4SummaryReduceEIData &&
                    t4SummaryReduceEIData.message &&
                    t4SummaryReduceEIData.message.status_code !== 404
                      ? t4SummaryReduceEIData
                      : undefined
                  }
                />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="T4 Slips Detail"
                testId={`${testId}-t4-slip-detial-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <T4SlipDetailPanel />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="Statutory Contributions"
                testId={`${testId}-statutory-contributions-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <EmployerDetailPanel formMethods={formMethods} />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="Difference"
                testId={`${testId}-difference-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <DifferencePanel />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="Total Balance"
                testId={`${testId}-total-balance-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <TotalBalancePanel />
              </FlatStandardPanel>
              {/* <TaxSlipFilingResourceSelectionPanel /> */}

              <Grid container>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                  <Paper variant="outlined">
                    <FlatStandardPanel
                      title="Select Resources"
                      testId="company-persons-panel"
                      // leftSpacing={2}
                      // rightSpacing={2}
                    >
                      <EnhancedDropDownRHFWithLabel
                        selectName="valueOfTransmitter"
                        label="Transmitter"
                        labelPosition={LabelPosition.Left}
                        // items={[{ label: "Babar", value: "64abebd533ed876a2a4a5b0a" }]}
                        items={transmitterDDL && transmitterDDL.message ? [] : transmitterDDL ? transmitterDDL : []}
                        testId="transmitter"
                        required
                      />
                      <EnhancedDropDownRHFWithLabel
                        selectName="slipType"
                        label="XML Type"
                        labelPosition={LabelPosition.Left}
                        items={[
                          { label: "Original", value: 400 },
                          { label: "Amended", value: 200 },
                          { label: "Cancelled", value: 300 },
                          { label: "Additional", value: 100 },
                        ]}
                        testId="xmlType"
                        selectedItem={selectedType}
                        onSelectionChangeHandler={
                          onSlipTypeSelectionChangeHandler
                        }
                      />
                      <EnhancedTextAreaFieldRHF
                        id="notes"
                        testId="notes"
                        label="Notes"
                        maxRows={15}
                        minRows={5}
                        labelPosition={LabelPosition.Left}
                      />
                      <Box sx={{ pt: 2 }}></Box>
                      <EnhancedDivider testId="transmitter-divider" />
                      <Box sx={{ pb: 2 }}></Box>
                      <EnhancedDropDownRHFWithLabel
                        selectName="valueOfIssuer"
                        label="Issuer"
                        labelPosition={LabelPosition.Left}
                        // items={[{ label: "Babar", value: "64abebd533ed876a2a4a5b0a" }]}
                        items={issuerDDL && issuerDDL.message ? [] : issuerDDL ? issuerDDL : []}
                        // selectedItem='646df8e0670529a84abf83df'
                        testId="transmitter"
                        required
                        onSelectionChangeHandler={
                          onIssuerSelectionChangeHandler
                        }
                      />
                      <TaxSlipSummaryDataInput
                        id="ownerSin1"
                        label="SIN 1 of the proprietor(s) or principal owner(s)"
                        boxNumber="74"
                        textfield={true}
                      />
                      <TaxSlipSummaryDataInput
                        id="ownerSin2"
                        label="SIN 2 of the proprietor(s) or principal owner(s)"
                        boxNumber="75"
                        textfield={true}
                      />
                      <Box sx={{ pt: 2 }}></Box>
                      <EnhancedDivider testId="transmitter-divider" />
                      <Box sx={{ pb: 2 }}></Box>
                      <Grid
                        container
                        columnSpacing={0}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          sx={{ textAlign: "left", paddingTop: 1 }}
                        >
                          <InputLabel>
                            <Typography variant="subtitle2">
                              Person to contact about this return{" "}
                              <RequiredAsterisk />
                            </Typography>
                          </InputLabel>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          sx={{ textAlign: "center", paddingTop: 1 }}
                        >
                          <InputLabel>
                            <Typography variant="subtitle2">76</Typography>
                          </InputLabel>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <EnhancedDropDownRHF
                            selectName="valueOfContactPerson"
                            // label="Person to contact about this return"
                            // labelPosition={LabelPosition.Left}
                            // items={[{ label: "Babar", value: "64abebd533ed876a2a4a5b0a" }]}
                            items={contactPersonDDL && contactPersonDDL.message ? [] : contactPersonDDL ? contactPersonDDL : []}
                            testId="contactPerson"
                            // required
                            onSelectionChangeHandler={
                              onContactPersonSelectionChangeHandler
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          sx={{ textAlign: "left", paddingTop: 2 }}
                        >
                          <InputLabel>
                            <Typography variant="subtitle2">
                              Phone number (Ext.)
                            </Typography>
                          </InputLabel>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          sx={{ textAlign: "center", paddingTop: 2 }}
                        >
                          <InputLabel>
                            <Typography variant="subtitle2">78</Typography>
                          </InputLabel>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <RHookFormTextField
                            // id="phone-number"
                            id="phone1"
                            testId="phone-number"
                            readOnly
                          />
                        </Grid>
                      </Grid>
                    </FlatStandardPanel>
                  </Paper>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
              </Grid>
              <Spacer y={4} x={4} />
            </Paper>
          </SingleColumnLayout>
        </form>
      </FormProvider>
      {/* invoice dialogu open if any transaction is unPaid */}
      <EnhancedDialog
        dialogTitle="Invoice(s) Due"
        key="dialog-box"
        testId="dialog"
        onCloseHandler={onCloseHandler}
        open={openInvoiceDialog}
        dialogContent={
          <InvoiceDialogPanel
            invoiceData={invoiceData}
            onUnPaidClickHandler={onUnPaidClickHandler}
            onClickInvoiceNoHandler={onClickInvoiceNoHandler}
          />
        }
        dialogAction={
          <Grid>
            <Button autoFocus variant="contained" onClick={onCloseHandler}>
              Cancel
            </Button>
          </Grid>
        }
      />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
        autoHide={snackbarData.autoHide}
      />
    </Grid>
  );
};
