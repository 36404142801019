import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { UndoMergeResource } from "components/Resource/UndoMergeResource";

export interface IUndoMergeResourcesPage {}
export const UndoMergeResourcesPage: FC<IUndoMergeResourcesPage> = () => {
  const { resourceName } = useParams();
  console.log("resourceName>>>>> from page", resourceName);
  return (
    <AuthenticatedLayout
      pageTitle={`Undo Merge ${resourceName}`}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: `${resourceName}`, navigateTo: `/resources/${resourceName}` },
        {
          text: `Undo Merge ${resourceName}`,
          navigateTo: `/resources/${resourceName}/undo-merge`,
        },
      ]}
      mainContents={<UndoMergeResource />}
    />
  );
};
