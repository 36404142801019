import { checkSINValidity } from 'components/T4Slip/T4SlipSetup/validator';
import * as yup from 'yup';
export const T4ASlipSetupValidationSchema = yup.object({
  name1: yup
    .string().nullable()
    .when('payeeType', {
      is: true,
      then: yup.string().required('First Name is required').max(12, "First Name has at most 12 characters"),
      otherwise: yup.string().nullable()
    }),

  initials: yup.string().max(1, "maximum one character"),

  name2: yup
    .string().nullable()
    .when('payeeType', {
      is: true,
      then: yup.string().required('Last Name is required').max(20, "Last Name has at most 20 characters"),
      otherwise: yup.string().nullable()
    }),

  uniqueIdentifier: yup
    .string().nullable()
    .when('payeeType', {
      is: true,
      then: yup.string().test(
        'is-masked-or-not',
        'Invalid value',
        function (value:any) {
          let sin = value && value.replace(/-/g, '')
          const output = checkSINValidity(sin);
          console.log("output",output)
          let maxLength = 11;
    
          if (value && value.charAt(2) === '*') {
            maxLength = 7;
            if (value && value.length == maxLength) {
              return true;
            } else {
              return this.createError({
                message: 'Invalid Social Insuarance Number',
                path: this.path,
              });
            }
          } else {
            if (value && value.length == maxLength && output % 10 === 0) {
              return true;
            } else {
              return this.createError({
                message: 'Invalid Social Insuarance Number',
                // message: 'Social Insuarance Number has at least 9 characters',
                path: this.path,
              });
            }
          }
        }
      ).required('Social Insuarance is required'),
      otherwise: yup.string().nullable()
    }),

  businessName: yup.string().nullable()
  .when('payeeType', {
    is: false,
    then: yup.string().nullable().required('business Name is required'),
    otherwise: yup.string().nullable()
  }),

  // businessNumber: yup.string().nullable()
  // .when('payeeType', {
  //   is: false,
  //   then: yup.string().test('isRequired', 'Business Number is required', value => {
  //     return value !== undefined && value !== null && value.trim() !== '';
  //   }),
  //   otherwise: yup.string().nullable()
  // }),
  businessNumber: yup.string().nullable()
  .when('payeeType', {
    is: false,
    then: yup.string().test(
      'is-valid-or-not',
      'Invalid value',
      function (value:any) {
        let maxLength = 15;
        console.log("value456",value)
        const firstNineDigits: string = value && value.substring(0, 9);
        console.log("valueeeee-- bn",firstNineDigits)
        const output = checkSINValidity(firstNineDigits);
        console.log("output bn",output)
        
          if (value && value.length == maxLength && output % 10 === 0) {
            return true;
          } else {
            return this.createError({
              message: 'Invalid Business Number',
              path: this.path,
            });
          }
      }
    ).nullable().required('business Number is required'),
    otherwise: yup.string().nullable()
  }),

  addressLine1: yup
    .string()
    .required("Address is required")
    .max(50, "Address has at most 50 characters"),

  city: yup
    .string()
    .required("City is required")
    .max(28, "City has at most 28 characters"),

  province: yup.string().nullable().required("Province is required"),

  postalCode: yup
    .string()
    .required("PostCode is required")
    .max(10, "Post Code has at most 10 characters"),

  country: yup.mixed().test(
    'is-string-or-object',
    'Input must be a string or an object.',
    (value) => typeof value === 'string' || typeof value === 'object'
  ).required("Country is required"),

  slipType: yup.number().required("slipType is required"),

  slipStatus: yup.number().required("slip Status is required"),

  email: yup
    .string().nullable()
    .email("Please enter valid Email"),
});
