export const T4OtherInformationList = [
    {label: "N/A",value: null},
    {label: "30 - Board and lodging",value: 30},
    {label: "31 - Special work site",value: 31},
    {label: "32 - Travel in a prescribed zone",value: 32},
    {label: "33 - Medical travel assistance",value: 33},
    {label: "34 - Personal use of employer's automobile or motor vehicle",value: 34},
    {label: "35 - Total Reasonable Per-Kilometre Allowance amount",value: 35},
    {label: "36 - Interest-free and low-interest loans",value: 36},
    {label: "37 - Employee home-relocation loan deduction",value: 37},
    {label: "38 - Security options benefits",value: 38},
    {label: "39 - Security options deduction - 110(1)(d)",value: 39},
    {label: "40 - Other taxable allowances and benefits",value: 40},
    {label: "41 - Security options deduction - 110(1)(d.1)",value: 41},
    {label: "42 - Employment commissions",value: 42},
    {label: "43 - Canadian Forces personnel and police deduction",value: 43},
    {label: "53 - Deferred security option benefits",value: 53},
    {label: "57 - Employment income - March 15 to May 9",value: 57},
    {label: "58 - Employment income - May 10 to July 4",value: 58},
    {label: "59 - Employment income - July 5 to August 29",value: 59},
    {label: "60 - Employment income - August 30 to September 26",value: 60},
    {label: "66 - Eligible retiring allowances",value: 66},
    {label: "67 - Non-eligible retiring allowances",value: 67},
    {label: "69 - Indian (exempt income) - Non-eligible retiring allowances",value: 69},
    // {label: "70",value: 70}, //not avail in t4 oi in setup
    {label: "71 - Indian (exempt income) - Employment",value: 71},
    {label: "72 - Section 122.3 income - Employment outside Canada",value: 72},
    {label: "73 - Number of days outside Canada",value: 73},
    {label: "74 - Past service contributions for 1989 or earlier years while a contributor",value: 74},
    {label: "75 - Past service contributions for 1989 or earlier years while not a contributor",value: 75},
    {label: "77 - Workers' compensation benefits repaid to the employer",value: 77},
    {label: "78 - Fishers - Gross income",value: 78},
    {label: "79 - Fishers - Net partnership amount",value: 43},
    {label: "80 - Fishers - Shareperson amount",value: 80},
    {label: "81 - Placement or employment agency workers - Gross income",value: 81},
    {label: "82 - Taxi drivers and drivers of other passenger-carrying vehicles - Gross income",value: 82},
    {label: "83 - Barbers or hairdressers - Gross income",value: 83},
    {label: "84 - Public transit pass",value: 84},
    {label: "85 - Employee-paid premiums for private health services plans",value: 85},
    {label: "86 - Security options election",value: 86},
    {label: "87 - Emergency services volunteer exempt amount",value: 87},
    {label: "88 - Indian (exempt income) - self-employment",value: 88},
    {label: "97 - Stock option benefit amount before February 28, 2000",value: 97},
    {label: "98 - Stock option and share deduction 110(1) (d) amount before February 28, 2000",value: 98},
    {label: "99 - Stock option and share deduction 110(1) (d.1) amount before February 28, 2000",value: 99}
    ]

export const T4AOtherInformationList = [
    {label: "N/A", value: null},
    {value: 14,  label: "14 - Recipient's number"},
    {value: 26,  label: "26 - Eligible retiring allowances (for 2009 and prior years only)"},
    {value: 27,  label: "27 - Non-eligible retiring allowances (for 2009 and prior years only)"},
    {value: 28,  label: "28 - Other income"},
    {value: 30,  label: "30 - Patronage allocations"},
    {value: 32,  label: "32 - Registered pension plan contributions (past service)"},
    {value: 34,  label: "34 - Pension adjustment"},
    {value: 36,  label: "36 - Plan registration number"},
    {value: 37,  label: "37 - Advanced life deferred annuity purchase"},
    {value: 40,  label: "40 - RESP accumulated income payments"},
    {value: 42,  label: "42 - RESP educational assistance payments"},
    {value: 46,  label: "46 - Charitable donations"},
    {value: 102, label: "102 - Lump-sum payments - non-resident services transferred under paragraph 60(j) (included in box 018)"},
    {value: 104, label: "104 - Research grants"},
    {value: 105, label: "105 - Scholarships, bursaries, fellowships, artists' project grants, and prizes"},
    {value: 106, label: "106 - Death benefits"},
    {value: 107, label: "107 - Payments from a wage-loss replacement plan"},
    {value: 108, label: "108 - Lump-sum payments from a registered pension plan (RPP) that you cannot transfer (included in box 018)"},
    {value: 109, label: "109 - Periodic payments from an unregistered plan"},
    {value: 110, label: "110 - Lump-sum payments accrued to December 31, 1971 (included in box 018)"},
    {value: 111, label: "111 - Income averaging annuity contracts (IAAC) (included in box 024)"},
    {value: 115, label: "115 - Deferred profit sharing plan (DPSP) annuity or instalment payments (included in box 024)"},
    {value: 116, label: "116 - Medical travel assistance"},
    {value: 117, label: "117 - Loan benefits"},
    {value: 118, label: "118 - Medical premium benefits"},
    {value: 119, label: "119 - Premiums paid to a group term life insurance plan"},
    {value: 122, label: "122 - RESP accumulated income payments paid to other (included in box 040)"},
    {value: 123, label: "123 - Payments from a revoked DPSP"},
    {value: 124, label: "124 - Board and lodging at special work sites"},
    {value: 125, label: "125 - Disability benefits paid out of a superannuation or pension plan"},
    {value: 126, label: "126 - Pre-1990 RPP past service contributions while a contributor (included in box 032)"},
    {value: 127, label: "127 - Veterans' benefits"},
    {value: 129, label: "129 - Tax deferred cooperative share"},
    {value: 130, label: "130 - Apprenticeship incentive grant or Apprenticeship completion grant"},
    {value: 131, label: "131 - Registered disability savings plan (RDSP)"},
    {value: 132, label: "132 - Wage Earner Protection Program (WEPP)"},
    {value: 133, label: "133 - Variable pension benefits"},
    {value: 134, label: "134 - Tax-Free Savings Account (TFSA) taxable amount"},
    {value: 135, label: "135 - Recipient-paid premiums for private health services plans"},
    {value: 136, label: "136 - Parents of Murdered or Missing Children/Parents of Young Victims of Crimes(PYVC)"},
    {value: 142, label: "142 - Indian (exempt income) - Eligible retiring allowances (for 2009 and prior years only)"},
    {value: 143, label: "143 - Indian (exempt income) - Non-eligible retiring allowances (for 2009 and prior years only)"},
    {value: 144, label: "144 - Indian (exempt income) - Other income"},
    {value: 146, label: "146 - Indian (exempt income) - Pension or superannuation"},
    {value: 148, label: "148 - Indian (exempt income) - Lump-sum payments"},
    {value: 150, label: "150 - Labour Adjustment Benefits Act and Appropriation Acts"},
    {value: 152, label: "152 - SUBP qualified under the Income Tax Act"},
    {value: 154, label: "154 - Cash award or prize from payer"},
    {value: 156, label: "156 - Bankruptcy settlement"},
    {value: 158, label: "158 - Lump-sum payments that you cannot transfer, that are not reported elsewhere (included in box 018)"},
    {value: 162, label: "162 - Code 162 &amp;ndash; Pre 1990 RPP past service contributions while not a contributor"},       
    {value: 180,label: "180 - Lump-sum payments from a deferred profit sharing plan (DPSP) that you cannot transfer  ed in box 018)"},
    {value: 190, label: "190 - Lump-sum payments from an unregistered plan (included in box 018)"},
    {value: 194, label: "194 - Pooled Registered Pension Plans (PRPP) annuity payments from taxable income"},
    {value: 195, label: "195 - Indian (exempt income) &amp;ndash; PRPP payments"},
    {value: 200, label: "200 - Provincial/Territorial  COVID-19 financial assistance payments"}
  ]



export const RL1OtherInformationList = [
  {value: null, label: "N/A" },
  {value: "A1", label: "A1 - Employee benefit plan" },
  {value: "A2", label: "A2 - Employee trust;" },
  {value: "A3", label: "A3 - Repayment of salary or wages;" },
  {value: "A4", label: "A4 - Chainsaw expenses;" },
  {value: "A5", label: "A5 - Brushcutter expenses;" },
  {value: "A6", label: "A6 - Remuneration received by a Québec sailor;" },
  {value: "A7", label: "A7 - Canadian Forces personnel and police deduction;"},
  {value: "A9", label: "A9 - Deduction for foreign specialists;" },
  {value: "A10",label: "A10 - Deduction for foreign researchers;" },
  {value: "A11",label: "A11 - Deduction for foreign researchers on a post-doctoral internship;"},
  {value: "A12",label: "A12 - Deduction for foreign experts"},
  {value: "A13",label: "A13 - Deduction for foreign professors;"},
  {value: "A14",label: "A14 - Exemption rate;"},
  {value: "B1", label: "B1 - CPP contribution;"},
  {value: "D1", label: "D1 - Retirement compensation arrangement;"},
  {value: "D2", label: "D2 - Contribution for service before 1990 - Contributor"},
  {value: "D3", label: "D3 - Contribution for service before 1990 - Non-contributor;"},
  {value: "G1", label: "G1 - Taxable benefit in kind;"},
  {value: "G2", label: "G2 - Pensionable earnings under the CPP;"},
  {value: "K1", label: "K1 - Trips for medical care;"},
  {value: "L2", label: "L2 - Volunteer - compensation not included in boxes A and L"},
  {value: "L3", label: "L3 - Tax-exempt allowance for expenses incurred in the course of duties;"},
  {value: "L4", label: "L4 - Benefit resulting from a debt contracted for the acquisition of investments;"},
  {value: "L7", label: "L7 - Benefit related to a security option at the time of death;"},
  {value: "L8", label: "L8 - Election respecting security options;"},
  {value: "L9", label: "L9 - Security option deduction under section 725.2 of the Taxation Act;"},
  {value: "L10",label: "L10 - Security option deduction under section 725.3 of the Taxation Ac;"},
  {value: "O2", label: "O2 - Deduction for patronage dividends;"},
  {value: "O3", label: "O3 - Redemption of preferred shares;"},
  {value: "O4", label: "O4 - Repayment of wage loss replacement benefits;"},
  {value: "O5", label: "O5 - Incentive Program to Retain Essential Workers benefits (IPREW) [reserved for Revenu Québec]"},
  {value: "O6", label: "O6 - Canada emergency benefits (CERB or CESB) [reserved for the Canada Revenue Agency];"},
  {value: "O7", label: "O7 - Canada recovery benefits (CRB, CRSB or CRCB) [reserved for the Canada Revenue Agency];"},
  {value: "O8", label: "O8 - Repayment of IPREW benefits received in 2020 or in 2021 [reserved for Revenu Québec];"},
  {value: "O9", label: "O9 - Repayment of benefits received in 2020 or in 2021 (CERB,CESB, CRB, CRSB, CRCB or CWLB) [reserved for the Canada Revenue Agency];"},
  {value: "O10",label: "O10 - Canada Worker Lockdown Benefit (reserved for the Canada Revenue Agency)"},
  {value: "R1", label: "R1 - Employment income;"},
  {value: "V1", label: "V1 - Tax-exempt benefit for board and lodging;"},
  {value: "200", label: "200 - Currency used;"},
  {value: "201", label: "201 - Allowance for childcare expenses;"},
  {value: "211", label: "211 - Benefit related to previous employment;"},
  {value: "235", label: "235 - Premium paid to a private health services plan;"},
  {value: "RZCA", label: "RZCA - Wage earner protection program (WEPP) payments;"},
  {value: "RZCB", label: "RZCB - Tax-free savings account (TFSA)"},
  {value: "RZCC", label: "RZCC - Payments to the beneficiary of a registered disability savings plan (RDSP);"},
  {value: "RZCD", label: "RZCD - Benefits paid to the parents of a crime victim;"},
  {value: "RZRA", label: "RZRA - Payments received under a supplementary unemployment benefit plan"},
  {value: "RZRB", label: "RZRB - Scholarships, bursaries, fellowships and prizes;"},
  {value: "RZRC", label: "RZRC - Research grants;"},
  {value: "RZRD", label: "RZRD - Fees for services rendered;"},
  {value: "RZRG", label: "RZRG - Labour adjustment benefits;"},
  {value: "RZRH", label: "RZRH - Income assistance payments for older workers;"},
  {value: "RZRI", label: "RZRI - Benefits paid under a program administered under the Department of Fisheries and Oceans Act (federal statute);"},
  {value: "RZRJ", label: "RZRJ - Retiring allowance (including an amount paid in respect of the loss of employment);"},
  {value: "RZRK", label: "RZRK - Death benefit;"},
  {value: "RZRL", label: "RZRL - Patronage dividends;"},
  {value: "RZRM", label: "RZRM - Commissions paid to a self-employed person;"},
  {value: "RZRN", label: "RZRN - Benefits paid under a wage loss replacement plan;"},
  {value: "RZRO", label: "RZRO - Benefits received by a shareholder;"},
  {value: "RZRP", label: "RZRP - Benefits received by a partner"},
  {value: "RZRQ", label: "RZRQ - Amounts allocated under a retirement compensation arrangement;"},
  {value: "RZRR", label: "RZRR - Payments for services rendered in Québec by a person not resident in Canada;"},
  {value: "RZRS", label: "RZRS - Financial assistance;"},
  {value: "RZRT", label: "RZRT - Other indemnities paid by the employer as a result of an industrial accident;"},
  {value: "RZRU", label: "RZRU - Educational assistance payments from a registered education savings plan (RESP)"},
  {value: "RZRV", label: "RZRV - Accumulated income payments from a registered education savings plan (RESP);"},
  {value: "RZRX", label: "RZRX - Subsidy to the apprentices;"},
];


export const RL3OtherInformationList = [
  {value: null, label: "N/A" },
  {value: "E-1",label: "E-1 - Actual amount of eligible dividends (Box E)"},
  {value: "E-2",label: "E-2 - Actual amount of ordinary dividends (Box E)"},
  {value: "H-2",label: "H-2 - Copyright income, deduction under section 726.26 of te Taxation act"},
  {value: "K-1",label: "K-1 - Linked notes - Interest from foreign source"},
  {value: "200",label: "200 - Currency used"},
  {value: "201",label: "201 - Social insurance number of one of the joint account holders"},
  {value: "202",label: "202 - Social insurance number of one of the joint account holders"},
  {value: "203",label: "203 - Social insurance number of one of the joint account holders"},
  {value: "204",label: "204 - Social insurance number of one of the joint account holders"},
  {value: "205",label: "205 - Unclaimed dividend account"},
  {value: "206",label: "206 - Unclaimed interest account"},
  {value: "207",label: "207 - Unclaimed dividend account - income tax withheld"},
  {value: "208",label: "208 - Unclaimed dividend account - income tax withheld"},
];


export const T3OtherInformationList = [
  {value: null, label: "N/A" },
  {value: "22",label: "22 - Lump-sum pension income"},
  {value: "24",label: "24 - Foreign business income"},
  {value: "25",label: "25 - Foreign non-business income"},
  {value: "31",label: "31 - Qualifying pension income"},
  {value: "33",label: "33 - Foreign business income tax paid"},
  {value: "34",label: "34 - Foreign non-business income tax paid"},
  {value: "35",label: "35 - Eligible death benefits"},
  {value: "37",label: "37 - Insurance segregated fund net capital losses"},
  {value: "38",label: "38 - Part XIII.2 tax credit"},
  {value: "40",label: "40 - Investment cost or expenditures"},
  {value: "41",label: "41 - Investment tax credit"},
  {value: "42",label: "42 - Amount resulting in cost base adjustment"},
  {value: "45",label: "45 - Other credits"},
  {value: "46",label: "46 - Pension income qualifying for an eligible annuity for a minor"},
  {value: "47",label: "47 - Retiring allowance qualifying for transfer to an RPP or RRSP"},
  {value: "48",label: "48 - Eligible amount of charitable donations"}
];

export const RL16OtherInformationList = [
  {value: null, label: "N/A" },
  {value: "A-1",label: "A-1 - Foreign capital gain included in box A"},
  {value: "A-2",label: "A-2 - Split income: Capital gain deemed to be an ordinary dividend"},
  {value: "A-3",label: "A-3 - Split income: Capital gain deemed to be a foreign dividend"},
  {value: "A-4",label: "A-4 - Split income: Other capital gain"},
  {value: "B-1",label: "B-1 - Single foreign pension paymen"},
  {value: "D-1",label: "D-1 - Amount of the foreign pension payment for witch an amount of foreign income tax mist be entered in box L."},
  {value: "E-1",label: "E-1 - Split income (lines 295 and 443 of the return)"},
  {value: "F-1",label: "F-1 - Split income (lines 295 and 443 of the return)"},
  {value: "G-1",label: "G-1 - Split income (lines 295 and 443 of the return)"},
  {value: "G-3",label: "G-3 - Split income: Canadian xorporation (lines 295 and 443)"},
  {value: "G-5",label: "G-5 - Retiring allowance (line 154)"},
  {value: "G-6",label: "G-6 - Death benefit (line 154)"},
  {value: "G-7",label: "G-7 - Death benefit: Amount of the exemption"},
  {value: "G-8",label: "G-8 - Single payment from a registered pension plan (RPP) made to a recipient other than the surviving spouse (line 154)"},
  {value: "G-9",label: "G-9 - income averaging annuity for artists (line 154)"},
  {value: "G-10",label: "G-10 - income tax deducted at source on the income-averaging annuity for artists"},
  {value: "H-1",label: "H-1 - Qaulified farm or fishing property"},
  {value: "H-2",label: "H-2 - Qualified small business corporation shares"},
  {value: "H-3",label: "H-3 - Split income: Capital gain deemed to be an ordinary dividend (line 167)"},
  {value: "I-1",label: "I-1 - Split income (line 295 and 433)"},
  {value: "J-1",label: "J-1 - Split income (line 295 and 433 of a return)"},
  {value: "K-1",label: "K-1 - Split income: Foreign income tax on business income"},
  {value: "L-1",label: "L-1 - Split income: Foreign income tax non-business income"}
];

export const payPeriodsList = [
    {label: "1 - Annually",value: 1},
    {label: "2 - Semi Annually",value: 2},
    {label: "4 - Quarterly",value: 4},
    {label: "12 - Monthly",value: 12},
    {label: "24 - Semi Monthly",value: 24},
    {label: "26 - Bi-Weekly",value: 26},
    {label: "27 - Bi-Weekly",value: 27},
    {label: "52 - Weekly",value: 52},
    {label: "53 - Weekly",value: 53},
    {label: "22 - Pay Periods",value: 22},
]
export const employeeTypeList = [
  {label:"Parttime Employee",value:'Parttime Employee'},
  {label:"Fulltime Employee",value:'Fulltime Employee'},
  {label:"Contractor",value:'Contractor'},
  {label:"Business",value:'Business'}
]
export const employeeCodeList = [
  {label: "--",value: null},
  {label:"11",value:11},
  {label:"12",value:12},
  {label:"13",value:13},
  {label:"14",value:14},
  {label:"15",value:15},
  {label:"16",value:16},
  {label:"17",value:17}
]
export const slipTypeList = [
  { label: "Original", value: "Original" },
  { label: "Amended", value: "Amended" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Additional", value: "Additional" },
]
export const provinceShortCodeList = [
    {label:"Alberta",value:'AB'},
    {label:"British Columbia",value:'BC'},
    {label:"Manitoba",value:'MB'},
    {label:"New Brunswick",value:'NB'},
    {label:"Newfoundland and Labrador",value:'NL'},
    {label:"Northwest Territories",value:'NT'},
    {label:"Nova Scotia",value:'NS'},
    {label:"Nunavut",value:'NU'},
    {label:"Ontario",value:'ON'},
    {label:"Prince Edward Island",value:'PE'},
    {label:"Quebec",value:'QC'},
    {label:"Saskatchewan",value:'SK'},
    {label:"Yukon",value:'YT'},
]

export const rl1CaseOList = [
    {label: "--",value: null},
    { label: "CA: Wage earner protection program (WEPP) payments", value: "CA" },
    { label: "CB: Tax-free savings account (TFSA)", value: "CB",},
    { label: "CC: Payments to the beneficiary of a registered disability savings plan (RDSP)", value: "CC",},
    { label: "CD: Benefits paid to parents of murdered or missing children", value: "CD",},
    { label: "RA: Payments received under a supplementary unemployment benefit plan", value: "RA",},
    { label: "RB: Scholarships, bursaries, fellowships and prizes", value: "RB",},
    { label: "RC: Research grants", value: "RC",},
    { label: "RD: Fees for services rendered", value: "RD",},
    { label: "RG: Labour adjustment benefits", value: "RG",},
    { label: "RH: Labour adjustment benefits for older workers and income assistance payments", value: "RH",},
    { label: "RI: Benefits paid under a program established pursuant to an agreement under section 5 of the Department of Fisheries and Oceans Act (Statutes of Canada)", value: "RI",},
    { label: "RJ: Retiring allowance (including an amount paid in respect of the loss of employment)", value: "RJ",},
    { label: "RK: Death benefit", value: "RK",},
    { label: "RL: Patronage dividends", value: "RL",},
    { label: "RM: Commissions paid to a self-employed person", value: "RM",},
    { label: "RN: Benefits paid under a wage loss replacement plan", value: "RN",},
    { label: "RO: Benefits received by a shareholder", value: "RO",},
    { label: "RP: Benefits received by a partner", value: "RP",},
    { label: "RQ: Amounts allocated under a retirement compensation arrangement", value: "RQ",},
    { label: "RR: Payments for services rendered in Québec by a person not resident in Canada", value: "RR",},
    { label: "RS: Financial assistance", value: "RS",},
    { label: "RT: Other indemnities paid by the employer as a result of an industrial accident", value: "RT",},
    { label: "RU: Educational assistance payments from a registered education savings plan (RESP)", value: "RU",},
    { label: "RV: Accumulated income payments from a registered education savings plan (RESP)", value: "RV",},
    { label: "RX: Incentive subsidy to the apprentices", value: "RX",},
    { label: "RZ: Several codes associated with the amount in box O", value: "Z",},
]
export const employerOfferedDentalBenifits = [
  {label: "--",value: null},
  {label:"Not eligible to access any dental care insurance, or coverage of dental service of any kind",value:1},
  {label:"Payee only",value:2},
  {label:"Payee, spouse and dependent children",value:3},
  {label:"Payee and their spouse",value:4},
  {label:"Payee and their dependent children",value:5},
]
export const recipientTypeOptionsDDL = [
  {label: "--",value: null},
  {label:"Individual",value:'Individual'},
  {label:"Joint Account",value:'Joint Account'},
  {label:"Corporation",value:'Corporation'},
  {label:"Association Trust Club Partnership",value:'Association Trust Club Partnership'},
  {label:"Government or International Organization",value:'Government or International Organization'},
]
export const recipientTypeRL3OptionsDDL = [
  {label: "--",value: null},
  {label:"Individual",value:'Individual'},
  {label:"Joint Account",value:'Joint Account'},
  {label:"Corporation",value:'Corporation'},
  {label:"Association Trust Club Partnership",value:'Association Trust Club Partnership'},
  {label:"Government or International Organization",value:'Government or International Organization'},
  {label: "Spouse or trust settlor of spouse deceased person", value: "Spouse or trust settlor of spouse deceased person"},
  {label: "Holder of joint account other than 2", value: "Holder of joint account other than 2"},
]

export const ConsolidateIndicatorDDL = [{label:"C | for a consolidated RL-16 slip",value:"C"},{label:"U | for a single RL-16 slip",value:"U"}]