import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import { FC } from "react";

export interface ITotalBalancePanel {}

export const TotalBalancePanel: FC<ITotalBalancePanel> = () => {
  return (
    <>
      <TaxSlipSummaryDataInput
        id="overpayment"
        label="Overpayment"
        boxNumber="84"
      />
      <TaxSlipSummaryDataInput
        id="balanceDue"
        label="Balance Due"
        boxNumber="86"
      />
    </>
  );
};
