import { InvoiceReport } from "components/Reports";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { ActivityLogReport } from "components/Reports/ActivityLogReport";

export interface IActivityLogReportPage {}

export const ActivityLogReportPage: FC<IActivityLogReportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Client Activity Log Report"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<ActivityLogReport />}
    />
  );
};
