import { TaxSlipYearList } from "components/TaxSlipYear";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export interface ITaxSlipYearListPage {}

export const TaxSlipYearListPage: FC<ITaxSlipYearListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Years"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Years', navigateTo: '/years/list'}]}
      mainContents={<TaxSlipYearList />}
    />
  );
};
