import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { ResourceService } from "services/ResourceService";
import { IAppState } from "store/reducers";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";

import { Button, CircularProgress, Grid, useTheme } from "@mui/material";

import {
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
} from "common/constants";
import PrimeDataGridMergeResource from "./PrimeDataGridMergeResource";
import { FormProvider, useForm } from "react-hook-form";
import { BackdropCircularProgress, EnhancedDialog, EnhancedDropDownRHFWithLabel, EnhancedSnackbar, IResponse, IServerError, LabelPosition, RHookFormPasswordFieldWithLabel, SubmitButton } from "@websential/cosmic";
import { UpdateDialogPanel } from "./UpdateDialogPanel";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IMergeResource {}
const useMergeResources = (
  clientId: string,
  taxYear: number,
//   params: IGetListRequest
) =>
  useQuery(
    [`merge-resource-list`],
    () => {
      return ResourceService.getDuplicateMergeList(clientId, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

export const MergeResource: React.FC<IMergeResource> = () => {
  const dispatch = useDispatch();
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);

  const[openDialog, setDialogOpen] = useState(false)
  const[selectedRecord, setSelectedRecord] = useState<any>([])
  const[keepRecordRow, setKeepRecordRow] = useState<any>([])
  const[isLoadingMerging, setIsLoadingMerging] = useState(false)
  const[loadmergeSlipsList, setLoadmergeSlipsList] = useState(false)
  const[mergeSlipsList, setMergeSlipsList] = useState<any>(undefined)

  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  const { isLoading, data } = useMergeResources(
    clientId,
    taxYear
  ); 
  const MergeResourceList: any = data;
  console.log("@@@@@ => MergeResourceList", MergeResourceList);
  console.log("mergeSlipsList",mergeSlipsList);

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
  useEffect(()=>{
    if(isLoading){
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    } else {
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    }
  },[isLoading])

useEffect(()=>{
  if(MergeResourceList){
    setMergeSlipsList(MergeResourceList)
  }
},[MergeResourceList])

  const onRowEditClicked = useCallback((params) => {
    console.log("paprams onRowEditClicked",params)
    setSelectedRecord(params)
    setDialogOpen(true)
  }, []);

  const onRowDeleteClicked = useCallback(() => {

  }, []);

  const formMethods = useForm<any>({
    // resolver: yupResolver(T4SlipSetupValidationSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: [],
  });

  const onSubmit = (formData: any) => {
  //this case is for when two radio selected but multi select is for one slip
  let finalKeepInRecordData:any = undefined
  let MultiSelectedRowsMergingIds:any = undefined
  let SingleSelectedRowFinalSlipId:any = undefined
  let SingleSelectedRowResourceData:any = undefined
  if(keepRecordRow && keepRecordRow.length > 1){
    for (const value of keepRecordRow) {
      if (selectedRecord.includes(value)) {
        console.log('Yes',value);
        finalKeepInRecordData = value
      } 
    }
  } else {
    finalKeepInRecordData = keepRecordRow[0]
  }
  const finalMultiSelectedSlipIds = selectedRecord.map((item:any) => item.id)

  console.log("formData",formData)
  console.log("keepRecordRowkeepRecordRow",keepRecordRow)
  console.log("finalKeepInRecordDatafinalKeepInRecordData",finalKeepInRecordData)
  console.log("finalMultiSelectedSlipIdsfinalMultiSelectedSlipIds",finalMultiSelectedSlipIds)

  if(finalKeepInRecordData){
    const updatedIds = finalMultiSelectedSlipIds.filter((item:any) => item !== finalKeepInRecordData.id);
    const ids_list = updatedIds.map((ids: any) => `merging_ids=${ids}`).join('&');
    const mergingIds: any = ids_list.replace(/^&+|&+$/g, '');
    MultiSelectedRowsMergingIds = mergingIds
    SingleSelectedRowFinalSlipId = finalKeepInRecordData.id
    SingleSelectedRowResourceData = finalKeepInRecordData.resource
    console.log("mergingIds",mergingIds)
  } else {
    const updatedIds = finalMultiSelectedSlipIds.filter((item:any) => item !== keepRecordRow[0].id);
    const ids_list = updatedIds.map((ids: any) => `merging_ids=${ids}`).join('&');
    const mergingIds: any = ids_list.replace(/^&+|&+$/g, '');
    MultiSelectedRowsMergingIds = mergingIds
    SingleSelectedRowFinalSlipId = keepRecordRow[0].id
    SingleSelectedRowResourceData = keepRecordRow[0].resource
    console.log("mergingIds",mergingIds)
  }


  //province is in string format and by using this we convert that in object
  const jsonString = formData.province as string;
  // Custom parsing function to convert the JSON-like string to an object
  const parseJSONLikeString = (str: string) => {
    // Use regular expressions to add double quotes around keys and string values
    const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
    // Convert the modified JSON string to an object using JSON.parse()
    return JSON.parse(modifiedString);
  };
  // Convert the JSON-like string to an object
  const provinceObject = parseJSONLikeString(jsonString);

  let resourcePayloadUpdated:any = {
    externalId:SingleSelectedRowResourceData.externalId,
    firstName:formData.firstName,
    lastName: formData.lastName,
    dateOfBirth: formData.dateOfBirth,
    socialInsuranceNumber: formData.socialInsuranceNumber,
    address: formData.address,
    city: formData.city,
    country:SingleSelectedRowResourceData.country,
    postCode:formData.postCode,
    province:provinceObject,
    provinceOfEmployment:SingleSelectedRowResourceData.provinceOfEmployment,
    email:formData.email,
    employeeCode:SingleSelectedRowResourceData.employeeCode,
    cppQppExempt:SingleSelectedRowResourceData.cppQppExempt,
    eiExempt:SingleSelectedRowResourceData.eiExempt,
    ppipExempt:SingleSelectedRowResourceData.ppipExempt,
    employmentCode:SingleSelectedRowResourceData.employmentCode,
    payPeriod:SingleSelectedRowResourceData.payPeriod,
    reducedEi:SingleSelectedRowResourceData.reducedEi
  }
  //post merge slip call
  const fetchData = async () => {
    if(finalKeepInRecordData && MultiSelectedRowsMergingIds && SingleSelectedRowFinalSlipId && SingleSelectedRowResourceData){
      try {
        setIsLoadingMerging(true)
        const response = await ResourceService.mergeSelectedResource(
            clientId,
            taxYear,
            MultiSelectedRowsMergingIds,
            SingleSelectedRowFinalSlipId,
            resourcePayloadUpdated
        );

        console.log("responseData", response);
        if(response && response.data === "Record has been Merged Successfully!"){
          setSnackbarData({
              open: true,
              message: response.data,
              severity: "success",
            })
        }else{
          setSnackbarData({
              open: true,
              message: response.message ? response.message : response.data.message ? response.data.message : "Something went wrong!",
              severity: "error",
            })
        }
      } catch (err) {
        console.log("errerrerr", err);
        setSnackbarData({
            open: true,
            message: "Somthing went wrong!",
            severity: "error",
          })
      }
    }
    setIsLoadingMerging(false)
    setLoadmergeSlipsList(true)
    setDialogOpen(false);
  };

  fetchData();
  // const payload:any = {

  // }
  // // submitMergingData(formData)
  // // formMethods.reset()
  }

  const onCloseHandler = () => {
    setKeepRecordRow([])
    setSelectedRecord([])
    formMethods.reset()
    // formMethods.setValue('firstName','')
    setDialogOpen(false);
  };

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // useEffect(() => {
    if(loadmergeSlipsList === true){
      setIsLoadingMerging(true);
      setTimeout(() => {
        setIsLoadingMerging(false);
      }, 2000);
    const fetchData = async () => {
      try {
        const response: any = await ResourceService.getDuplicateMergeList(clientId, taxYear);
        console.log("responseData-----------------", response);
        setMergeSlipsList(response)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    setLoadmergeSlipsList(false)
  }
    console.log("loadmergeSlipsList",loadmergeSlipsList)
  // }, []);
  console.log("loadmergeSlipsList",loadmergeSlipsList)
  console.log("snackbarData",snackbarData)
  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:'',
      companyName:'',
      activityType:"activity_type=",
      activityLogVisibility:false
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[])

  return(
    <>
    <BackdropCircularProgress
          open={
            isLoadingMerging
          }
          testId="MergeResource"
        />
    <PrimeDataGridMergeResource
        rows={mergeSlipsList !== undefined && mergeSlipsList?.items}
        columns={mergeSlipsList ? mergeSlipsList.headerColumns : []}
        showIndexColumn={true}
        showChecboxColumn={false}
        showSettingColumn={false}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        isLoading={isLoading || isAxiosCallLoading}
        onKeepRecordRowSelected={(value:any) => setKeepRecordRow(value)}
        onIsLoadingMerging={(value:any) => setIsLoadingMerging(value)}
        onSnackbarMessageData={(value:any) => setSnackbarData(value)}
        loadmergeSlipsList={(value:any) => setLoadmergeSlipsList(value)}
      />

      {/* Edit Dialog */}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <EnhancedDialog
            dialogTitle="Custom Merge Employee"
            key="dialog-box"
            testId="dialog"
            onCloseHandler={onCloseHandler}
            open={openDialog}
            dialogContent={
              <UpdateDialogPanel 
              selectedRecordData={selectedRecord} 
              keepRecordRowData={keepRecordRow}
              formMethods={formMethods}
              />
            }
            dialogAction={
              <Grid>
                <Button
                  autoFocus
                  variant="contained"
                  onClick={formMethods.handleSubmit(onSubmit)}
                  color="success"
                  type="submit"
                  disabled={isLoadingMerging}
                >
                  {isLoadingMerging && <CircularProgress size={24} sx={{marginRight:"12px"}}/>}
                  Merge Employee (s)
                </Button>
                <Button
                  autoFocus
                  variant="contained"
                  onClick={onCloseHandler}
                  sx={{
                    backgroundColor: "#e0e0e0",
                    "&:hover": {
                      backgroundColor: "#bdbdbd",
                    },
                    color: "#212121",
                  }}
                  style={{ marginLeft: "5px" }}
                >
                  Cancel
                </Button>
              </Grid>
            }
          />
        </form>
      </FormProvider>

      <EnhancedSnackbar
          message={snackbarData.message}
          onCloseHandler={onSnackbarClosed}
          severity={snackbarData.severity}
          testId={`MergeResource-snackbar`}
          open={snackbarData.open}
        />
    </>
  );
};
