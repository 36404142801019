import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import {
  IAdjustmentOptionsModel,
  IDDLItem,
  ITransferOverRemittance,
} from "models/adjustmentOptions";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import {
  AJDUSTMENT_OPTIONS_SCREEN_ID,
  AJDUSTMENT_OPTIONS_SCREEN_SLUG,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
} from "common/constants";
import {
  BackdropCircularProgress,
  EnhancedDropDownRHFWithLabel,
  EnhancedIOSSwitchRHFWithLabel,
  EnhancedMultiDropDownRHFWithLabel,
  EnhancedSnackbar,
  EnhancedTextAreaFieldRHF,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  RHookFormTextFieldWithLabel,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  TwoColumnLayout,
} from "@websential/cosmic";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { ProvinceService } from "services/common/ProvinceService";
import { Grid } from "@mui/material";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { AdjustmentOptionsSetupToolbar } from "./AdjustmentOptionsSetupToolbar";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IAdjustmentOptionsSetup extends ITest {}

const switchControlLabelColumns = 8;
const switchControlFieldColumns = 4;
const emailMessage =
  "Your T4 Slip for the year 2020 is attached.\n\nNote:\nThe attached T4 slip can only be viewed with Acrobat Reader.";

const DEFAULT_VALUES = {
  cppQPPDdjustOverUnder: false,
  cppQPPAdjustPensionablePeriods: true,
  eiAdjustPremiumOverAnnualMaximum: true,
  eiAdjustPremiumBasedOnInsurableEarnings: true,
  eiAdjustInsurableEarningsBasedOnPremium: false,
  reducedEIRate: "",
  ppipAdjustPremiumOverAnnualMaximum: true,
  ppipAdjustPremiumBasedOnInsurableEarnings: true,
  ppipAdjustInsurableEarningsBasedOnPremium: false,
  transferOverRemittanceToSlips: "",
  overRemittanceIncreaseEmployeesGrossPay: false,
  cppEiPpipAdjustDifferenceGreaterThan: "1.00",
  cppEiPpipAdjustCalculatedAmount: false,
  maximumAmountByWhichTaxMayBeReduced: "999999.99",
  lockAdjustmentOptions: false,
  disableT4Rl1Adjustments: false,
  mergeT4Rl1SlipsBeforeAAdjustment: true,
  ignoreAdjustments: false,
  t4EmployeesPrint: "",
  t4EmployerPrint: "",
  t4CraPrint: "",
  emailMessage: emailMessage,
};

const GET_ADJUSTMENT_OPTIONS_QUERY_KEY = "get-adjustment-options";

// const useAdjustmentOptions = (clientId: string,taxYear:number) =>
//   useQuery(
//     GET_ADJUSTMENT_OPTIONS_QUERY_KEY,
//     () => AdjustmentOptionsService.getAdjustmentOptionsByClientIdAndTaxYear(clientId,taxYear),
//     QUERY_OPTIONS
//   );

const useAdjustmentOptions = (clientId: string, taxYear: number) =>
  useQuery(
    [GET_ADJUSTMENT_OPTIONS_QUERY_KEY, clientId, taxYear],
    (data) => {
      return AdjustmentOptionsService.getAdjustmentOptionsByClientIdAndTaxYear(
        clientId,
        taxYear
      );
    },
    { refetchOnWindowFocus: false }
  );

export const AdjustmentOptionsSetup: FC<IAdjustmentOptionsSetup> = ({
  testId,
}) => {
  const dispatch = useDispatch();
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );

  const [selectedTransferOverRemittance, setSelectedTransferOverRemittance] =
    useState<any>({
      resourceFirstName: "",
      id: "",
      selectResource: true,
    });

  const emailMessage = `Your T4 Slip for the year ${taxYear} is attached.\n\nNote:\nThe attached T4 slip can only be viewed with Acrobat Reader.`;

  // const useGetTransferOverRemittanceDDL = () => {
  //   return useQuery(["transferOverRemittance"], () => ProvinceService.getTransferOverRemittance(), {
  //     ...QUERY_OPTIONS,
  //   });
  // };
  const useGetTransferOverRemittanceDDL = (
    clientId: string,
    taxYear: number
  ) => {
    return useQuery(
      ["transferOverRemittance"],
      () =>
        AdjustmentOptionsService.getTransferOverRemittance(clientId, taxYear),
      QUERY_OPTIONS
    );
  };

  const {
    isLoading: isLoadingTransferOverRemittance,
    data: transferOverRemittanceDDL,
  } = useGetTransferOverRemittanceDDL(clientId, taxYear);

  console.log("transferOverRemittanceDDL", transferOverRemittanceDDL);
  // const [modifiedArray, setModifiedArray] = useState<IDDLItem[]>(() => {
  //   // Initialize the modified array with the initial state of the original array
  //   return originalArray.map(item => ({ id: item.label, name: item.value }));
  // });

  const onTransferOverRemittanceSelectionChangeHandler = (
    selectedItems: IEnhancedDropDownItem[]
  ) => {
    // setSelectedTransferOverRemittance({
    //   resourceFirstName: selectedItems.label,
    //   id: String(selectedItems.value),
    //   selectResource: true,
    // });
    const newData = selectedItems.map((item) => ({
      resourceFirstName: item.label,
      id: String(item.value),
      selectResource: true,
      employmentIncome: item?.employmentIncome,
    }));
    console.log("selectedItems", selectedItems);
    console.log("newData", newData);
    setSelectedTransferOverRemittance(newData);
  };
  const [lockAdjustmentOptions, setLockAdjustmentOptions] =
    useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);

  const { isLoading: isSubmitting, mutate: submitForm } = useMutation(
    AdjustmentOptionsService.create,
    {
      onSuccess: (res: IAdjustmentOptionsModel | any) => {
        // // remove the cache of the flex fields since we just added a new field.
        // queryClient.invalidateQueries(GET_ADJUSTMENT_OPTIONS_QUERY_KEY);
        // // [corner case] remove the cache of the field that has just been edited in-case the user re-selects it again
        // queryClient.invalidateQueries([FLEX_FIELD_DATA_QUERY_KEY, res.id]);
        // // set the currently added/edited flex field as the selected field
        // formMethods.setValue("id", res.id);
        if (res.success === false) {
          setSnackbarData({
            open: true,
            message: res.message ? res.message : "Something went wrong!",
            severity: "error",
          });
        } else {
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );
  const { isLoading: isLoadingFlexFieldsList, data: adjustmentOptions } =
    useAdjustmentOptions(clientId, taxYear);

  console.log("adjustmentOptions", adjustmentOptions);

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  useEffect(() => {
    if (isLoadingFlexFieldsList) {
      setIsAxiosCallLoading(true);
      setTimeout(() => {
        setIsAxiosCallLoading(false);
      }, 1000);
    } else {
      setIsAxiosCallLoading(true);
      setTimeout(() => {
        setIsAxiosCallLoading(false);
      }, 1000);
    }
  }, [isLoadingFlexFieldsList]);

  const formMethods = useForm<IAdjustmentOptionsModel>({
    // resolver: yupResolver(validationSchema),
    // mode: "onBlur",
    // reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });

  /**
   * Handle when the form is submitted.
   *
   * @param {IFlexFieldSetupRequest} formData
   */
  const onSubmit = (formData: IAdjustmentOptionsModel) => {
    // submitForm(formData);
    // console.log("form Data",formData)

    const requestPayload: IAdjustmentOptionsModel = {
      clientId: clientId,
      taxYear: taxYear,
      adjustmentType:'adjustment_options',
      cppQPPDjustOverUnder: formData.cppQPPDjustOverUnder,
      cppQPPAdjustPensionablePeriods: formData.cppQPPAdjustPensionablePeriods,
      eiAdjustPremiumOverAnnualMaximum:
        formData.eiAdjustPremiumOverAnnualMaximum,
      eiAdjustPremiumBasedOnInsurableEarnings:
        formData.eiAdjustPremiumBasedOnInsurableEarnings,
      eiAdjustInsurableEarningsBasedOnPremium:
        formData.eiAdjustInsurableEarningsBasedOnPremium,

      reducedEIRate: formData.reducedEIRate,
      ppipAdjustPremiumOverAnnualMaximum:
        formData.ppipAdjustPremiumOverAnnualMaximum,
      ppipAdjustPremiumBasedOnInsurableEarnings:
        formData.ppipAdjustPremiumBasedOnInsurableEarnings,
      ppipAdjustInsurableEarningsBasedOnPremium:
        formData.ppipAdjustInsurableEarningsBasedOnPremium,
      transferOverRemittanceToSlips:
        selectedTransferOverRemittance.id !== ""
          ? selectedTransferOverRemittance
          : null,
      overRemittanceIncreaseEmployeesGrossPay:
        formData.overRemittanceIncreaseEmployeesGrossPay,
      cppEiPpipAdjustDifferenceGreaterThan:
        formData.cppEiPpipAdjustDifferenceGreaterThan,
      cppEiPpipAdjustCalculatedAmount: formData.cppEiPpipAdjustCalculatedAmount,
      maximumAmountByWhichTaxMayBeReduced:
        formData.maximumAmountByWhichTaxMayBeReduced,
      lockAdjustmentOptions: formData.lockAdjustmentOptions,
      disableT4Rl1Adjustments: formData.disableT4Rl1Adjustments,

      mergeT4Rl1SlipsBeforeAAdjustment:
        formData.mergeT4Rl1SlipsBeforeAAdjustment,
      ignoreAdjustments: formData.ignoreAdjustments,
      t4EmployeesPrint: formData.t4EmployeesPrint,
      t4EmployerPrint: formData.t4EmployerPrint,
      t4CraPrint: formData.t4CraPrint,
      emailMessage: formData.emailMessage,
      remittanceTransferred: false,
      ignoreRemittanceTransferred:
        adjustmentOptions &&
        adjustmentOptions.ignoreRemittanceTransferred &&
        adjustmentOptions.ignoreRemittanceTransferred,
    };
    console.log("requestPayload", requestPayload);
    submitForm(requestPayload);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset(DEFAULT_VALUES);
  };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const cppQPPAdjustOverUnderChangeHandler = (checkedStatus: boolean) => {
    formMethods.setValue("cppQPPDjustOverUnder", checkedStatus);
    formMethods.setValue("cppQPPAdjustPensionablePeriods", !checkedStatus);
  };

  const cppQPPAdjustPensionablePeriodsChangeHandler = (
    checkedStatus: boolean
  ) => {
    formMethods.setValue("cppQPPDjustOverUnder", !checkedStatus);
    formMethods.setValue("cppQPPAdjustPensionablePeriods", checkedStatus);
  };

  const eiAdjustPremiumBasedOnInsurableEarningsChangeHandler = (
    checkedStatus: boolean
  ) => {
    formMethods.setValue(
      "eiAdjustPremiumBasedOnInsurableEarnings",
      checkedStatus
    );
    formMethods.setValue(
      "eiAdjustInsurableEarningsBasedOnPremium",
      !checkedStatus
    );
  };

  const eiAdjustInsurableEarningsBasedOnPremiumChangeHandler = (
    checkedStatus: boolean
  ) => {
    formMethods.setValue(
      "eiAdjustPremiumBasedOnInsurableEarnings",
      !checkedStatus
    );
    formMethods.setValue(
      "eiAdjustInsurableEarningsBasedOnPremium",
      checkedStatus
    );
  };

  const ppipAdjustPremiumBasedOnInsurableEarningsChangeHandler = (
    checkedStatus: boolean
  ) => {
    formMethods.setValue(
      "ppipAdjustPremiumBasedOnInsurableEarnings",
      checkedStatus
    );
    formMethods.setValue(
      "ppipAdjustInsurableEarningsBasedOnPremium",
      !checkedStatus
    );
  };

  const ppipAdjustInsurableEarningsBasedOnPremiumChangeHandler = (
    checkedStatus: boolean
  ) => {
    formMethods.setValue(
      "ppipAdjustPremiumBasedOnInsurableEarnings",
      !checkedStatus
    );
    formMethods.setValue(
      "ppipAdjustInsurableEarningsBasedOnPremium",
      checkedStatus
    );
  };

  const lockAdjustmentOptionsChangeHandler = (checkedStatus: boolean) => {
    setLockAdjustmentOptions(checkedStatus);
    // formMethods.setValue("ppipAdjustPremiumBasedOnInsurableEarnings", !checkedStatus);
    // formMethods.setValue("ppipAdjustInsurableEarningsBasedOnPremium", checkedStatus);
  };

  // set the values of the selected flex field coming from BE.
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (adjustmentOptions) {
      Object.keys(adjustmentOptions).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = adjustmentOptions[fieldName];
        console.log(
          "fieldNameAdjustment",
          fieldName,
          " && ",
          "fieldValueAdjustment ",
          fieldValue
        );
        // if (fieldName === "transferOverRemittanceToSlips") {
        //   // let id = fieldValue.id;
        //   // let name = fieldValue.name;
        //   // let shortCode = fieldValue.shortCode;
        //   // console.log("namename", name);

        //   // formMethods.setValue(
        //   //   fieldName,
        //   //   // '{id:9, name: "Ontario",shortCode:"ON"}'
        //   //   `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
        //   // );
        //   if(fieldValue !== null){
        //   const values = fieldValue.map((item:any) => item.id);
        //   console.log("values--",values)
        //   console.log("values--",values[0])
        // formMethods.setValue(fieldName, values);
        //   }
        //   // Object.keys(fieldValue).forEach((fieldNameOverRemittance) => {
        //   //   // @ts-ignore
        //   //   const fieldValueOverRemittance = fieldValue[fieldNameOverRemittance];
        //   //   console.log(
        //   //     "fieldNameAdjustment",
        //   //     fieldNameOverRemittance,
        //   //     " && ",
        //   //     "fieldValueAdjustment ",
        //   //     fieldValueOverRemittance
        //   //   );
        //   //   // formMethods.setValue(fieldName, fieldValue);
        //   // });
        // }
        // @ts-ignore
        formMethods.setValue(fieldName, fieldValue);
        if (fieldName === "transferOverRemittanceToSlips") {
          if (fieldValue !== null) {
            const values = fieldValue.map((item: any) => item.id);
            console.log("values--", values);
            console.log("values--", values[0]);
            formMethods.setValue(fieldName, values);

            const newData = fieldValue.map((item: any) => ({
              resourceFirstName: item.resourceFirstName,
              id: String(item.id),
              selectResource: item.selectResource,
            }));
            console.log("newData123", newData);
            setSelectedTransferOverRemittance(newData);
          }
        }
        // formMethods.setValue("transferOverRemittanceToSlips", "64be7723ea267d2e1682fddd");
      });
    }
  }, [adjustmentOptions, formMethods.setValue, formMethods]);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: AJDUSTMENT_OPTIONS_SCREEN_SLUG,
        companyName: clientName,
        activityType: "activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={
          isSubmitting ||
          isLoadingFlexFieldsList ||
          isLoadingTransferOverRemittance ||
          isLoadingActivityLogData ||
          isAxiosCallLoading
        }
        testId={testId}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <TwoColumnLayout
            toolbar={
              // <StandardSubmitFormButtonsToolbar
              <AdjustmentOptionsSetupToolbar
                onResetClicked={onResetClicked}
                isSubmitting={isSubmitting}
                testId={testId}
                adjustmentOptionsData={adjustmentOptions}
              />
            }
            leftColumnContent={
              <>
                <StandardPanel
                  title="CPP/QPP"
                  testId={`${testId}-cpp-qpp-panel`}
                >
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="cppQPPDjustOverUnder"
                      checked={false}
                      testId="cppQPPAdjustOverUnder"
                      label="Adjust if over maximum/under required minimum"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      onSelectionChangeHandler={
                        cppQPPAdjustOverUnderChangeHandler
                      }
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="cppQPPAdjustPensionablePeriods"
                      checked={true}
                      testId="cppQPPAdjustPensionablePeriods"
                      label="Adjust based on number of pensionable periods"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      onSelectionChangeHandler={
                        cppQPPAdjustPensionablePeriodsChangeHandler
                      }
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                </StandardPanel>
                <StandardPanel
                  title="EI"
                  testId={`${testId}-ei-panel`}
                  topPadding={2}
                >
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="eiAdjustPremiumOverAnnualMaximum"
                      checked={true}
                      testId="eiAdjustPremiumOverAnnualMaximum"
                      label="Adjust EI premiums if over annual maximum"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="eiAdjustPremiumBasedOnInsurableEarnings"
                      checked={true}
                      testId="eiAdjustPremiumBasedOnInsurableEarnings"
                      label="Adjust EI premiums based on insurable earnings"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      onSelectionChangeHandler={
                        eiAdjustPremiumBasedOnInsurableEarningsChangeHandler
                      }
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="eiAdjustInsurableEarningsBasedOnPremium"
                      checked={false}
                      testId="eiAdjustInsurableEarningsBasedOnPremium"
                      label="Adjust insurable earnings based on EI premiums"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      onSelectionChangeHandler={
                        eiAdjustInsurableEarningsBasedOnPremiumChangeHandler
                      }
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                  <RHookFormTextFieldWithLabel
                    label="Reduced EI Rate"
                    id="reducedEIRate"
                    testId="reduced-ei-rate"
                    labelPosition={LabelPosition.Left}
                    labelColumns={8}
                    fieldColumns={4}
                    readOnly={lockAdjustmentOptions}
                    defaultValue="0.01"
                  />
                </StandardPanel>
                <StandardPanel
                  title="PPIP"
                  testId={`${testId}-ppip-panel`}
                  topPadding={2}
                >
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="ppipAdjustPremiumOverAnnualMaximum"
                      checked={true}
                      testId="ppipAdjustPremiumOverAnnualMaximum"
                      label="Adjust PPIP premiums if over annual maximum"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="ppipAdjustPremiumBasedOnInsurableEarnings"
                      checked={true}
                      testId="ppipAdjustPremiumBasedOnInsurableEarnings"
                      label="Adjust PPIP premiums based on insurable earnings"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      onSelectionChangeHandler={
                        ppipAdjustPremiumBasedOnInsurableEarningsChangeHandler
                      }
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="ppipAdjustInsurableEarningsBasedOnPremium"
                      checked={false}
                      testId="ppipAdjustInsurableEarningsBasedOnPremium"
                      label="Adjust insurable earnings based on PPIP premiums"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      onSelectionChangeHandler={
                        ppipAdjustInsurableEarningsBasedOnPremiumChangeHandler
                      }
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                </StandardPanel>
                <StandardPanel
                  title="Transfer Over-Remittance"
                  testId={`${testId}-transfer-over-remittance-panel`}
                  topPadding={2}
                >
                  <EnhancedMultiDropDownRHFWithLabel
                    selectName="transferOverRemittanceToSlips"
                    label="Transfer over-remittance to employee(s)"
                    labelPosition={LabelPosition.Left}
                    labelColumns={4}
                    fieldColumns={8}
                    items={transferOverRemittanceDDL ?? []}
                    testId="transferOverRemittanceToSlips"
                    onSelectionChangeHandler={
                      onTransferOverRemittanceSelectionChangeHandler
                    }
                    isDisabled={lockAdjustmentOptions}
                  />
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="overRemittanceIncreaseEmployeesGrossPay"
                      checked={false}
                      testId="overRemittanceIncreaseEmployeesGrossPay"
                      label="Increase gross pay of above employee by amount of over-remittance"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                </StandardPanel>
              </>
            }
            rightColumnContent={
              <>
                <StandardPanel
                  title="Other Adjustment Options"
                  testId={`${testId}-other-adjustment-options-panel`}
                >
                  <RHookFormTextFieldWithLabel
                    label="Adjust CPP/EI/PPIP difference greater than"
                    id="cppEiPpipAdjustDifferenceGreaterThan"
                    value="1.00"
                    testId="cppEiPpipAdjustDifferenceGreaterThan"
                    labelPosition={LabelPosition.Left}
                    labelColumns={8}
                    fieldColumns={4}
                    readOnly={lockAdjustmentOptions}
                  />
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="cppEiPpipAdjustCalculatedAmount"
                      checked={false}
                      testId="cppEiPpipAdjustCalculatedAmount"
                      label="Adjust CPP/EI/PPIP to calculated amounts even if there is insufficient tax to offset the adjustment"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                  <RHookFormTextFieldWithLabel
                    label="Maximum amount by which tax may be reduced"
                    id="maximumAmountByWhichTaxMayBeReduced"
                    value="999999.99"
                    testId="maximumAmountByWhichTaxMayBeReduced"
                    labelPosition={LabelPosition.Left}
                    labelColumns={8}
                    fieldColumns={4}
                    readOnly={lockAdjustmentOptions}
                  />
                </StandardPanel>
                <StandardPanel
                  title="Lock/Disable"
                  testId={`${testId}-lock-disable-panel`}
                  topPadding={2}
                >
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="lockAdjustmentOptions"
                      checked={false}
                      testId="lockAdjustmentOptions"
                      label="Lock adjustment options"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      onSelectionChangeHandler={
                        lockAdjustmentOptionsChangeHandler
                      }
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="disableT4Rl1Adjustments"
                      checked={false}
                      testId="disableT4Rl1Adjustments"
                      label="Disable T4/RL-1 adjustments"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="mergeT4Rl1SlipsBeforeAAdjustment"
                      checked={true}
                      testId="mergeT4Rl1SlipsBeforeAAdjustment"
                      label="Merge T4/RL-1 slips before adjustment"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <EnhancedIOSSwitchRHFWithLabel
                      name="ignoreAdjustments"
                      checked={false}
                      testId="ignoreAdjustments"
                      label="Ignore Adjustments"
                      labelPosition={LabelPosition.Left}
                      labelColumns={switchControlLabelColumns}
                      fieldColumns={switchControlFieldColumns}
                      disabled={lockAdjustmentOptions}
                    />
                  </FieldWrapper>
                </StandardPanel>
                <StandardPanel
                  title="T4 Print Type"
                  testId={`${testId}-t4-print-type-panel`}
                  topPadding={2}
                >
                  <EnhancedDropDownRHFWithLabel
                    selectName="t4EmployeesPrint"
                    items={[
                      { label: "Original", value: 400 },
                      { label: "Amended", value: 200 },
                      { label: "Cancelled", value: 300 },
                      { label: "Additional", value: 100 },
                    ]}
                    testId="t4EmployeesPrint"
                    label="T4 Employees Print"
                    labelPosition={LabelPosition.Left}
                    labelColumns={8}
                    fieldColumns={4}
                    isDisabled={lockAdjustmentOptions}
                  />
                  <EnhancedDropDownRHFWithLabel
                    selectName="t4EmployerPrint"
                    items={[
                      { label: "Original", value: 400 },
                      { label: "Amended", value: 200 },
                      { label: "Cancelled", value: 300 },
                      { label: "Additional", value: 100 },
                    ]}
                    testId="t4EmployerPrint"
                    label="T4 Employer Print"
                    labelPosition={LabelPosition.Left}
                    labelColumns={8}
                    fieldColumns={4}
                    isDisabled={lockAdjustmentOptions}
                  />
                  <EnhancedDropDownRHFWithLabel
                    selectName="t4CraPrint"
                    items={[
                      { label: "Original", value: 400 },
                      { label: "Amended", value: 200 },
                      { label: "Cancelled", value: 300 },
                      { label: "Additional", value: 100 },
                    ]}
                    testId="t4CraPrint"
                    label="T4 CRA Print"
                    labelPosition={LabelPosition.Left}
                    labelColumns={8}
                    fieldColumns={4}
                    isDisabled={lockAdjustmentOptions}
                  />
                </StandardPanel>
                <StandardPanel
                  title="E-mail Message"
                  testId={`${testId}-email-message-panel`}
                  topPadding={2}
                >
                  <EnhancedTextAreaFieldRHF
                    id="emailMessage"
                    testId="emailMessage"
                    label="E-mail Message"
                    defaultValue={emailMessage}
                    disabled={lockAdjustmentOptions}
                  />
                </StandardPanel>
              </>
            }
            testId="adjustment-options-setup-form"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
