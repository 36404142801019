import {
  INITIAL_PAGE_SIZE,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
} from "common/constants";
import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useState } from "react";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IAppState } from "store/reducers";

import { Box, Grid, useTheme } from "@mui/material";
import { RouterLinkButton } from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import { ReportsService } from "services/ReportsService";
// import PrimeDataGrid from "./PrimeDataGrid";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { PrimeDataGrid } from "components/PrimeDataGrid";

export interface IRL1Report {}
const RL1_REPORT_LIST_QUERY_KEY = "rl1-report-list"

const useRL1Report = (
  clientId: string,
  taxYear: number,
  params: IGetListRequest
) =>
  useQuery(
    ["rl1-report-list", params],
    (data) => {
      return ReportsService.getRL1ReportNew(clientId, taxYear, params);
    },
    { refetchOnWindowFocus: false }
  );


export const RL1Report: React.FC<IRL1Report> = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state: IAppState) => state.client);
  const taxYear  = useSelector((state: IAppState) => state.taxYear.year);
  const theme = useTheme();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "resource.firstName",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useRL1Report(id, taxYear, queryParams);
  const RL1ReportList: any = data;
  console.log("RL-1-report ===============", RL1ReportList);

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
  useEffect(()=>{
    if(isLoading){
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    } else {
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    }
  },[isLoading])


  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  
  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: requestParams.sortField  && requestParams.sortField === "resource.name" ? "resource.firstName" : requestParams.sortField ? requestParams.sortField : "resource.firstName",
      order_direction: requestParams.sortOrder
        ? requestParams.sortOrder
        : "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
    };
    // if (requestParams.sort) {
    //   const [sortBy, sortDirection] = requestParams.sort.split(",");
    //   dispatchParams.order_by = toSnake(sortBy);
    //   dispatchParams.order_direction = sortDirection;
    // }
console.log("dispatchParams",dispatchParams)
    setQueryParams(dispatchParams);
  }, []);

  const onRowEditClicked = useCallback((params) => {
    alert(`Edit row with ID: ${params.row.id} has been clicked!`);
    // let path = `/T4s/setup/${params.row.id}`;
    // navigate(path);
  }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const callAction = () => {
    if (apiAction === "delete") {
      alert(`Delete row with ID: ${selectedIds} has been clicked!`);
      // TODO api call for delete
    } else if (apiAction === "updateStatus") {
      alert(`action need to trigger : ${apiAction}`);
      // TODO api call for update status
    }
    setDialogOpen(false);
  };

  const onRowDeleteClicked = useCallback((params) => {
    setDialogOpen(true);
    setApiAction("delete");
    setSelectedIds(params.row.id);
    alert(`Delete row with ID: ${params.row.id} has been clicked!`);
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusValue(event.target.value as string);
    setApiAction("updateStatus");
    setDialogOpen(true);
  };

  const selectionToolbar = useCallback((selectedItems) => {
    setSelectedIds(selectedItems);
    if (selectedItems.length) {
      return (
        <Box sx={{ marginTop: theme.spacing(1) }}>
          {"Bulk Action Drop Down List"}
          {/* <EnhancedDDLBulkAction
            label="Status"
            value={statusValue}
            onChangeHandler={handleChange}
            options={[
              { name: "Active", value: "1" },
              { name: "Inactive", value: "2" },
            ]}
            testId="status-dd"
          /> */}
        </Box>
      );
    }
    // return (
    //   <Box sx={{ marginTop: theme.spacing(1) }}>
    //     <RouterLinkButton
    //       to="/billing-profile/setup"
    //       text="RL-1 report"
    //     />
    //   </Box>
    // );
  }, []);

  // if (isLoading) {
  //   return (
  //     <CircularProgress
  //       size={24}
  //       data-testid="circular-progress"
  //       className={classes.loader}
  //     />
  //   );
  // }

  const rowsWithTwoDecimalNumbers = RL1ReportList && RL1ReportList?.items?.rl1Report && RL1ReportList?.items?.rl1Report.map((item:any) => {
    // let { otherInformation } = item;
    // const limitedObject = Object.fromEntries(
    //     Object.entries(otherInformation).slice(0, 10)
    // );
    // item.otherInformation = limitedObject
    return {
    ...item,
    slipData: {
        ...item.slipData,
        boxARevenusDEmploi: item.slipData.boxARevenusDEmploi !== null ? parseFloat(item.slipData.boxARevenusDEmploi).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxBOtisationAuRRQ: item.slipData.boxBOtisationAuRRQ !== null ? parseFloat(item.slipData.boxBOtisationAuRRQ).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxBBOtisationAuRRQ: item.slipData.boxBBOtisationAuRRQ !== null ? parseFloat(item.slipData.boxBBOtisationAuRRQ).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxCCotaIAssuranceEmploi: item.slipData.boxCCotaIAssuranceEmploi !== null ? parseFloat(item.slipData.boxCCotaIAssuranceEmploi).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxDCotisationAUnRPA: item.slipData.boxDCotisationAUnRPA !== null ? parseFloat(item.slipData.boxDCotisationAUnRPA).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxEImpotDuQuebecRetenu: item.slipData.boxEImpotDuQuebecRetenu !== null ? parseFloat(item.slipData.boxEImpotDuQuebecRetenu).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxFCotisationSyndicale: item.slipData.boxFCotisationSyndicale !== null ? parseFloat(item.slipData.boxFCotisationSyndicale).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxGSalaireAdmissibleAuRRQ: item.slipData.boxGSalaireAdmissibleAuRRQ !== null ? parseFloat(item.slipData.boxGSalaireAdmissibleAuRRQ).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxHCotisationAuRQAP: item.slipData.boxHCotisationAuRQAP !== null ? parseFloat(item.slipData.boxHCotisationAuRQAP).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxISalaireAdmissibleAuRQAP: item.slipData.boxISalaireAdmissibleAuRQAP !== null ? parseFloat(item.slipData.boxISalaireAdmissibleAuRQAP).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxJRegimePriveDassMaladie: item.slipData.boxJRegimePriveDassMaladie !== null ? parseFloat(item.slipData.boxJRegimePriveDassMaladie).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxKVoyages: item.slipData.boxKVoyages !== null ? parseFloat(item.slipData.boxKVoyages).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxLAutresAvantages: item.slipData.boxLAutresAvantages !== null ? parseFloat(item.slipData.boxLAutresAvantages).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxMCommissions: item.slipData.boxMCommissions !== null ? parseFloat(item.slipData.boxMCommissions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxNDonsDeBienfaisance: item.slipData.boxNDonsDeBienfaisance !== null ? parseFloat(item.slipData.boxNDonsDeBienfaisance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxOAutresRevenus: item.slipData.boxOAutresRevenus !== null ? parseFloat(item.slipData.boxOAutresRevenus).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
        boxPRegimeDassInterentreprises: item.slipData.boxPRegimeDassInterentreprises !== null ? parseFloat(item.slipData.boxPRegimeDassInterentreprises).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxQSalairesDifferes: item.slipData.boxQSalairesDifferes !== null ? parseFloat(item.slipData.boxQSalairesDifferes).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxRRevenuSitueDansUneReserve: item.slipData.boxRRevenuSitueDansUneReserve !== null ? parseFloat(item.slipData.boxRRevenuSitueDansUneReserve).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxSPourboiresRecus: item.slipData.boxSPourboiresRecus !== null ? parseFloat(item.slipData.boxSPourboiresRecus).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxTPourboiresAttribues: item.slipData.boxTPourboiresAttribues !== null ? parseFloat(item.slipData.boxTPourboiresAttribues).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxURetraiteProgressive: item.slipData.boxURetraiteProgressive !== null ? parseFloat(item.slipData.boxURetraiteProgressive).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxVNourritureEtLogement: item.slipData.boxVNourritureEtLogement !== null ? parseFloat(item.slipData.boxVNourritureEtLogement).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxWVehiculeAMoteur: item.slipData.boxWVehiculeAMoteur !== null ? parseFloat(item.slipData.boxWVehiculeAMoteur).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
      },
      adjustedSlipData:{
        ...item.adjustedSlipData,
        boxAAdjRevenusDEmploi: item.adjustedSlipData.boxAAdjRevenusDEmploi !== null ? parseFloat(item.adjustedSlipData.boxAAdjRevenusDEmploi).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxBAdjOtisationAuRRQ: item.adjustedSlipData.boxBAdjOtisationAuRRQ !== null ? parseFloat(item.adjustedSlipData.boxBAdjOtisationAuRRQ).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxBBAdjOtisationAuRRQ: item.adjustedSlipData.boxBBAdjOtisationAuRRQ !== null ? parseFloat(item.adjustedSlipData.boxBBAdjOtisationAuRRQ).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxCAdjCotaIAssuranceEmploi: item.adjustedSlipData.boxCAdjCotaIAssuranceEmploi !== null ? parseFloat(item.adjustedSlipData.boxCAdjCotaIAssuranceEmploi).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxEAdjImpotDuQuebecRetenu: item.adjustedSlipData.boxEAdjImpotDuQuebecRetenu !== null ? parseFloat(item.adjustedSlipData.boxEAdjImpotDuQuebecRetenu).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxHAdjCotisationAuRQAP: item.adjustedSlipData.boxHAdjCotisationAuRQAP !== null ? parseFloat(item.adjustedSlipData.boxHAdjCotisationAuRQAP).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
        boxIAdjSalaireAdmissibleAuRQAP: item.adjustedSlipData.boxIAdjSalaireAdmissibleAuRQAP !== null ? parseFloat(item.adjustedSlipData.boxIAdjSalaireAdmissibleAuRQAP).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00",
    },
    otherInformation: {
        // ...item.otherInformation,
        [0 + "_label_0"]: item.otherInformation["0_label_0"],
        [0 + "_amount_0"]: item.otherInformation["0_amount_0"] && parseFloat(item.otherInformation["0_amount_0"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        [1 + "_label_1"]: item.otherInformation["1_label_1"],
        [1 + "_amount_1"]: item.otherInformation["1_amount_1"] && parseFloat(item.otherInformation["1_amount_1"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        [2 + "_label_2"]: item.otherInformation["2_label_2"],
        [2 + "_amount_2"]: item.otherInformation["2_amount_2"] && parseFloat(item.otherInformation["2_amount_2"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        [3 + "_label_3"]: item.otherInformation["3_label_3"],
        [3 + "_amount_3"]: item.otherInformation["3_amount_3"] && parseFloat(item.otherInformation["3_amount_3"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    }
}
  });
// const maxObjects = 45;
// const updateColumnswith4OI = columns && columns.slice(0, maxObjects)
const columnsWithTwoDecimalNumbers = RL1ReportList && RL1ReportList.headerColumns && RL1ReportList.headerColumns.map((item:any) => {
  return {
    ...item,
    // footer: item.footer !== null && typeof(item.footer) !== "string" ? parseFloat(item.footer).toFixed(2) : item.footer
    footer: item.footer !== null && item.footer !== "" && item.footer !== "Total" ? parseFloat(item.footer).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : item.footer
  }});

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:'',
      companyName:'',
      activityType:"",
      activityLogVisibility:false
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[])
  
  return emptyList ? (
    <EmptyAccountantList />
  ) : (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ width: "100%", overflow: "hidden" }}
    >
      {/* <EnhancedDialog
        dialogTitle="Please Confirm"
        key="dialog-box"
        testId="dialog"
        onCloseHandler={onCloseHandler}
        open={openDialog}
        dialogContent={
          <Typography variant="subtitle1">Are you sure?</Typography>
        }
        dialogAction={
          <Grid>
            <Button
              autoFocus
              variant="contained"
              onClick={onCloseHandler}
              color="default"
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={callAction}
              color="primary"
              className={classes.button}
            >
              ok
            </Button>
          </Grid>
        }
      /> */}
      {/* <EnhancedDataGrid
        columns={T4ReportList ? T4ReportList.headerColumns : []}
        rows={T4ReportList?.items?.t4Report}
        // total={T4ReportList?.items[0]?.total}
        // selectionEnabled={false}
        // autoHeight={false}
        allowSearching={true}
        selectionToolbar={selectionToolbar}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        // initialPage={0}
        disableColumnMenu={true}
        showRowActionsColumn={false}
        autoPageSize={false} 
        showIndexColumn={true}
        testId="data-role-list-grid"
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onRequestParamsChange={onRequestParamsChange}
        totalRowsCount={T4ReportList?.total}
        isLoading={isLoading}
        rowIdGetter={(row) => row.id}
        initialPageSize={INITIAL_PAGE_SIZE}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
        shouldMask={false}
      /> */}
      <PrimeDataGrid
        rows={RL1ReportList && rowsWithTwoDecimalNumbers ? rowsWithTwoDecimalNumbers : []}
        columns={RL1ReportList && columnsWithTwoDecimalNumbers ? columnsWithTwoDecimalNumbers : []}
        showIndexColumn={true}
        showChecboxColumn={false}
        showSettingColumn={false}
        totalRecords={RL1ReportList && RL1ReportList.total ? RL1ReportList.total : 0}
        initialPageSize={INITIAL_PAGE_SIZE}
        onRequestParamsChange={onRequestParamsChange}
        isLoading={isLoading || isAxiosCallLoading}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        searchFieldPlaceholder={"Search by Employee Name..."}
        tableScrollHeight={true}
      />
    </Grid>
  );
};
