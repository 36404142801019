import { FlexFieldTabSetup } from "components/FlexFieldTab";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IFlexFieldTabSetupPage {}

export const FlexFieldTabSetupPage: FC<IFlexFieldTabSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Flex Field Tab Setup"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Flex Field Tabs", navigateTo: "/flex-field-tabs/list" },
      { text: "Create Flex Field Tab", navigateTo: "/flex-field-tabs/setup" }
      ]}
      mainContents={<FlexFieldTabSetup testId="flex-field-tab-setup" />}
    />
  );
};
