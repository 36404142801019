import * as yup from 'yup';

export const APIKeysSchema = yup.object({
  clientSecret: yup
    .string()
    .max(50, "max length error message"),
  authKey: yup
    .string()
    .max(50, "max length error message"),
  taxSlipsClientId: yup
    .string()
    .max(50, "max length error message"),
});
