import { T4ASlipList } from "components/T4ASlip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT4ASlipListPage {}

export const T4ASlipListPage: FC<IT4ASlipListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T4A Slip List"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t4a/list" },
        { text: "T4A", navigateTo: "/taxslip/t4a/list" },
        { text: "T4A Slips", navigateTo: "/taxslip/t4a/list" },
      ]}
      mainContents={<T4ASlipList />}
    />
  );
};
