import RL1SlipImportLayout from "components/RL1Slip/RL1SlipImportLayout/RL1SlipImportLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL1SlipImportPage {}

export const RL1SlipImportPage: FC<IRL1SlipImportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import RL-1 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl1/list" },
        { text: "RL-1", navigateTo: "/taxslip/rl1/list" },
        { text: "Import RL-1 Slips", navigateTo: "/taxslip/rl1/import" },
      ]}
      mainContents={<RL1SlipImportLayout />}
    />
  );
};
