import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { VendorReport } from "components/Reports/VendorReport/VendorReport";

export interface IVendorReportPage {}

export const VendorReportPage: FC<IVendorReportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Vendors"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<VendorReport />}
    />
  );
};
