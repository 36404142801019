import * as yup from 'yup';

const numberValidationSchema = (message: string) => (
  yup.string().matches(/^([0]([.][0-9]+)?|[1-9]([0-9]+)?([.][0-9]+)?)$/, {
    message: message,
    excludeEmptyString: true
  }).transform((x) => (x === '' ? null : x)).nullable()
)

export const BillingProfileSetupSchema = yup.object().shape({
  profileLabel: yup.string().required("Profile label is required"),
  effectiveDate: yup
    .date()
    .typeError("Effective date is required in a valid format (Jan 01, 2022)")
    .required("Effective date is required"),
  endDate: yup
    .date()
    .nullable()
    .min(yup.ref('effectiveDate'), "Expiry date must be greater than or equal to effective date"),
  isGlobalProfile: yup.boolean(),
  baseCharges: numberValidationSchema('Base charges are required in decimal format'),
  discountPercentage: numberValidationSchema('Discount percentage is required in decimal format'),
  discountAmount: numberValidationSchema('Discount amount is required in decimal format'),
  chargesType: yup.string(),
  t4flatSlipCharges: numberValidationSchema('T4 slip charges are required in decimal format'),
  t4flatSummaryCharges: numberValidationSchema('T4 summary charges are required in decimal format'),
  t4aflatSlipCharges: numberValidationSchema('T4A slip charges are required in decimal format'),
  t4aflatSummaryCharges: numberValidationSchema('T4A summary charges are required in decimal format'),
  rl1flatSlipCharges: numberValidationSchema('RL-1 slip charges are required in decimal format'),
  rl1flatSummaryCharges: numberValidationSchema('RL-1 summary charges are required in decimal format'),
  t4SlipSlab1: numberValidationSchema('T4 slip slab is required in number format'),
  t4SlipSlabCharges1: numberValidationSchema('T4 slip slab charges are required in decimal format'),
  t4SlipSlab2: numberValidationSchema('T4 slip slab is required in number format'),
  t4SlipSlabCharges2: numberValidationSchema('T4 slip slab charges are required in decimal format'),
  t4SlipSlab3: numberValidationSchema('T4 slip slab is required in number format'),
  t4SlipSlabCharges3: numberValidationSchema('T4 slip slab charges are required in decimal format'),
  t4SlipSlab4: numberValidationSchema('T4 slip slab is required in number format'),
  t4SlipSlabCharges4: numberValidationSchema('T4 slip slab charges are required in decimal format'),
  t4SlipSlab5: numberValidationSchema('T4 slip slab is required in number format'),
  t4SlipSlabCharges5: numberValidationSchema('T4 slip slab charges are required in decimal format'),
  t4aSlipSlab1: numberValidationSchema('T4A slip slab is required in number format'),
  t4aSlipSlabCharges1: numberValidationSchema('T4A slip slab charges are required in decimal format'),
  t4aSlipSlab2: numberValidationSchema('T4A slip slab is required in number format'),
  t4aSlipSlabCharges2: numberValidationSchema('T4A slip slab charges are required in decimal format'),
  t4aSlipSlab3: numberValidationSchema('T4A slip slab is required in number format'),
  t4aSlipSlabCharges3: numberValidationSchema('T4A slip slab charges are required in decimal format'),
  t4aSlipSlab4: numberValidationSchema('T4A slip slab is required in number format'),
  t4aSlipSlabCharges4: numberValidationSchema('T4A slip slab charges are required in decimal format'),
  t4aSlipSlab5: numberValidationSchema('T4A slip slab is required in number format'),
  t4aSlipSlabCharges5: numberValidationSchema('T4A slip slab charges are required in decimal format'),
  rl1SlipSlab1: numberValidationSchema('RL-1 slip slab is required in number format'),
  rl1SlipSlabCharges1: numberValidationSchema('RL-1 slip slab charges are required in decimal format'),
  rl1SlipSlab2: numberValidationSchema('RL-1 slip slab is required in number format'),
  rl1SlipSlabCharges2: numberValidationSchema('RL-1 slip slab charges are required in decimal format'),
  rl1SlipSlab3: numberValidationSchema('RL-1 slip slab is required in number format'),
  rl1SlipSlabCharges3: numberValidationSchema('RL-1 slip slab charges are required in decimal format'),
  rl1SlipSlab4: numberValidationSchema('RL-1 slip slab is required in number format'),
  rl1SlipSlabCharges4: numberValidationSchema('RL-1 slip slab charges are required in decimal format'),
  rl1SlipSlab5: numberValidationSchema('RL-1 slip slab is required in number format'),
  rl1SlipSlabCharges5: numberValidationSchema('RL-1 slip slab charges are required in decimal format'),
});
