import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";

import { SlipsMenu } from "./SlipsRoutes";
import { PeopleMenu } from "./PeopleRoutes";

export const ArrayData : any = [
  {
    id: 10000,
    text: "Home",
    open: true,
    icon: <HomeOutlinedIcon />,
    menu: [
      {
        menuId: 10001,
        text: "Dashboard",
        navigateToURL: "/clients/dashboard",
        parentMenuId: 10000
      },
      {
        menuId: 10002,
        text: "Client List",
        navigateToURL: "/clients",
        parentMenuId: 10000
      },
      {
        menuId: 10003,
        text: "Super User",
        navigateToURL: "/accountants",
        parentMenuId: 10000
      },
      {
        menuId: 10004,
        text: "Global Search",
        navigateToURL: "/global-search",
        parentMenuId: 10000
      },
    ],
  },
  {
    id: 10100,
    text: "People",
    open: false,
    icon: <ContactMailOutlinedIcon />,
    // menu: [
    //   {
    //     menuId: 10101,
    //     text: "Employee",
    //     navigateToURL: "/resources/Employee/list",
    //     parentMenuId: 10100
    //   },
    //   {
    //     menuId: 10102,
    //     text: "Vendors",
    //     navigateToURL: "/resources/Vendor/list",
    //     parentMenuId: 10100
    //   },
    //   {
    //     menuId: 10103,
    //     text: "Filing Resources",
    //     navigateToURL: "/filing-resources/list",
    //     parentMenuId: 10100
    //   },
    // ],
    menu: <PeopleMenu />,
  },
  {
    id: 10200,
    text: "Adjustment",
    open: false,
    icon: <ListAltOutlinedIcon />,
    menu: [
      {
        menuId: 1,
        text: "Options",
        navigateToURL: "/adjustments/options",
        parentMenuId: 10200
      },
      {
        menuId: 2,
        text: "Adjustments",
        navigateToURL: "/adjustments/setup",
        parentMenuId: 10200
      },
      {
        menuId: 3,
        text: "T4 Report",
        navigateToURL: "/adjustments/t4-report",
        parentMenuId: 10200
      },
      {
        menuId: 4,
        text: "RL-1 Report",
        navigateToURL: "/adjustments/rl1-report",
        parentMenuId: 10200
      },
    ],
  },
  {
    id: 10300,
    text: "Audit",
    open: false,
    icon: <AssessmentOutlinedIcon />,
    menu: [
      {
        menuId: 1,
        text: "Filing",
        navigateToURL: "/audit/filing",
        parentMenuId: 10300
      },
      {
        menuId: 2,
        text: "Earnings Audit",
        navigateToURL: "/audit/earnings-audit",
        parentMenuId: 10300
      },
      {
        menuId: 3,
        text: "PIER Review",
        navigateToURL: "/audit/pier-review",
        parentMenuId: 10300
      },
    ],
  },
  {
    id: 10400,
    text: "Compliance",
    open: false,
    icon: <DoneAllOutlinedIcon />,
    menu: <SlipsMenu />,
  },
  {
    id: 6,
    text: "Report",
    open: false,
    icon: <TableChartOutlinedIcon />,

    menu: [
      {
        menuId: 1,
        text: "Employees",
        navigateToURL: "/reports/employees",
        parentMenuId: 6
      },
      {
        menuId: 2,
        text: "Vendors",
        navigateToURL: "/reports/vendors",
        parentMenuId: 6
      },
      {
        menuId: 3,
        text: "T4",
        navigateToURL: "/reports/t4-reports",
        parentMenuId: 6
      },
      {
        menuId: 4,
        text: "RL-1",
        navigateToURL: "/reports/rl1-reports",
        parentMenuId: 6
      },
      {
        menuId: 5,
        text: "T4A",
        navigateToURL: "/reports/t4a-reports",
        parentMenuId: 6
      },
      {
        menuId: 6,
        text: "T5",
        navigateToURL: "/reports/t5-reports",
        parentMenuId: 6
      },
      {
        menuId: 7,
        text: "T3",
        navigateToURL: "/reports/t3-reports",
        parentMenuId: 6
      },
      {
        menuId: 8,
        text: "RL-3",
        navigateToURL: "/reports/rl3-reports",
        parentMenuId: 6
      },
      {
        menuId: 9,
        text: "RL-16",
        navigateToURL: "/reports/rl16-reports",
        parentMenuId: 6
      },
      {
        menuId: 10,
        text: "Invoices",
        navigateToURL: "/reports/invoice-report",
        parentMenuId: 6
        // navigateToURL: "/reports/invoice-detail",
      },
      {
        menuId: 11,
        text: "Actvity Log",
        navigateToURL: "/reports/activity-log-report",
        parentMenuId: 6
        // navigateToURL: "/reports/invoice-detail",
      },
      // {
      //   menuId: 7,
      //   text: "General Clients",
      //   navigateToURL: "/reports/general-clients",
      // },
    ],
  },
  {
    id: 100000,
    text: "Logout",
    icon: <LogoutOutlinedIcon />,
    navigateToURL: "/logout",
  },
];
