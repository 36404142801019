
import { DRAWER_TYPES } from "store/types/drawerActionsTypes";

export const setDrawerCurrentState = (selectDrawerState: any) => {
  return {
    type: DRAWER_TYPES.DRAWER_OPEN_SET_SELECTED,
    payload: {...selectDrawerState}
  }
}

export const clearDrawerClose = () => {
  return {
    type: DRAWER_TYPES.DRAWER_CLOSE_SELECTED
  }
}
