// import { toSnake } from "common/methods";
// import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
// import { IGetListRequest } from "models";
// import React, { useCallback, useEffect, useState } from "react";
// // import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// // import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// // import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// // import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
// import { useMutation, useQuery, useQueryClient } from "react-query";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import { IAppState } from "store/reducers";
// import FileSaver from "file-saver";
// import EmailIcon from '@mui/icons-material/Email';
// import ArticleIcon from "@mui/icons-material/Article";
// import { Box, Button, Grid, useTheme } from "@mui/material";
// import { BackdropCircularProgress, DeleteConfirmationDialog, EmptyList, EnhancedDialog, EnhancedLinkButton, EnhancedSnackbar, IResponse, IServerError, IconTextButton, PrintButton, RouterLinkButton, SplitButton } from "@websential/cosmic";

// import { EnhancedDataGrid } from "../../EnhancedDataGrid";
// import {
//   GET_QUERY_KEY,
//   INITIAL_PAGE_SIZE,
//   INITIAL_SNACKBAR_DATA,
//   ROWS_PER_PAGE_PER_OPTIONS,
//   SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
// } from "common/constants";
// import { RL1SlipService } from "services/RL1SlipService";
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// import { PrintingService } from "services/PrintingService";
// import { ReportsService } from "services/ReportsService";
// import { setSelectedINVOICE } from "store/actions";
// import { InvoiceDialogPanel } from "components/T4Slip/T4SlipList/InvoiceDialogPanel";
// import { Print } from "@mui/icons-material";
// import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";

// export interface IRL1Slip {}
// const TAXSLIP_RL1_LIST_QUERY_KEY = "rl1-slips-list"

// const useRL1Slips = (
//   clientId: string,
//   taxYear: number,
//   params: IGetListRequest
// ) =>
//   useQuery(
//     [GET_QUERY_KEY.LIST_RL1_SLIPS, params],
//     (data) => {
//       return RL1SlipService.getList(clientId, taxYear, params);
//     },
//     { refetchOnWindowFocus: false }
//   );

// export const RL1SlipList: React.FC<IRL1Slip> = () => {
//   const dispatch = useDispatch();
//   const { id } = useSelector((state: IAppState) => state.client);
//   const taxYear  = useSelector((state: IAppState) => state.taxYear.year);
//   const clientId = useSelector((state: IAppState) => state.client.id);
//   const { resourceName } = useParams();
//   const [dataRoleName, setDataRoleName] = useState<string>("");
//   const maskedStatus:any = sessionStorage.getItem("maskEnabled")

//   const[openInvoiceDialog, setInvoiceDialogOpen] = useState(false)
//   const[invoiceData, setInvoiceData] = useState<any>([])
//   const[isLoadingData, setIsLoadingData] = useState(false)
//   const [selectedRowsValues, setSelectedRowsValues] = useState([]);


//   // for delete row Purpose
//   const queryClient = useQueryClient();
//   const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);

//   useEffect(() => {
//     if (resourceName && resourceName.length > 0) {
//       console.log("resourceName>>>>>", resourceName);
//       setDataRoleName(resourceName);
//     }
//   }, [dataRoleName, resourceName]);

//   const theme = useTheme();
//   const navigate = useNavigate();

//   const [queryParams, setQueryParams] = useState<IGetListRequest>({
//     search_query: "",
//     order_by: "name1",
//     order_direction: "asc",
//     page: 1,
//     size: INITIAL_PAGE_SIZE,
//   });

//   const { isLoading, data } = useRL1Slips(id, taxYear, queryParams);
//   const slipList: any = data;
//   console.log("@@@@@ => resourceList", slipList);

//   //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
//   // const isAxiosCallLoading = queryClient.isFetching([TAXSLIP_RL1_LIST_QUERY_KEY]) > 0;
//   const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
//   useEffect(()=>{
//     if(isLoading){
//       setIsAxiosCallLoading(true)
//       setTimeout(()=>{
//         setIsAxiosCallLoading(false)
//       },1000)
//     } else {
//       setIsAxiosCallLoading(true)
//       setTimeout(()=>{
//         setIsAxiosCallLoading(false)
//       },1000)
//     }
//   },[isLoading])

//   const [openDialog, setDialogOpen] = useState(false);
//   const [selectedIds, setSelectedIds] = useState([]);
//   const [statusValue, setStatusValue] = useState("");
//   const [apiAction, setApiAction] = useState("");
//   const [emptyList, setEmptyList] = useState(false);
//   const [searchText, setSearchText] = useState({});
//   const [zeroClient, setzeroClient] = useState(true);

//   // for delete row
//   const [singleDelete, setSingleDelete] = useState<boolean>(false);
//   const [rL1SlipId, setRL1SlipId] = useState<number>(0);

//   const onRequestParamsChange = useCallback((requestParams) => {
//     console.log("##### - request params", requestParams);
//     let dispatchParams = {
//       search_query: requestParams.debouncedSearchQuery,
//       order_by: "name1",
//       order_direction: "asc",
//       page: requestParams.currentPage + 1,
//       size: requestParams.pageSize,
//     };
//     if (requestParams.sort) {
//       const [sortBy, sortDirection] = requestParams.sort.split(",");
//       dispatchParams.order_by = toSnake(sortBy);
//       dispatchParams.order_direction = sortDirection;
//     }

//     // setQueryParams(dispatchParams);
//     if(dispatchParams.search_query === ''){
//       setQueryParams(dispatchParams);
//     }
//     setSearchText(dispatchParams)
//   }, []);

//   const onSearchButtonClicked=()=>{
//     setQueryParams(searchText)
//     setzeroClient(false)
//   }

//   const onRowEditClicked = useCallback((params) => {
//     let path = `/taxslip/rl1/setup/${params.id}`;
//     navigate(path);
//   }, []);

//   const onCloseHandler = () => {
//     setDialogOpen(false);
//     setInvoiceDialogOpen(false);
//   };

//   const callAction = () => {
//     if (apiAction === "delete") {
//       alert(`Delete row with ID: ${selectedIds} has been clicked!`);
//       // TODO api call for delete
//     } else if (apiAction === "updateStatus") {
//       alert(`action need to trigger : ${apiAction}`);
//       // TODO api call for update status
//     }
//     setDialogOpen(false);
//   };

//    // For Delete Row
//  const deleteRL1SlipField = () => {
//   if (singleDelete) {
//     let payload = {
//       rL1SlipId,
//       clientId,
//       taxYear
//     }
//     setSingleDelete(false);
//     deleteRL1SlipById(payload);
//     setDialogOpen(false);
//   }
// };

// const onRowDeleteClicked = useCallback((params) => {
//   setDialogOpen(true);
//   setSingleDelete(true);
//   setApiAction("delete");
//   setRL1SlipId(params.id);
//   console.log("RL1SlipId in RL1Slip List OnDeleteRowClicked function",params.id)
//   // alert(`Delete row with ID: ${params.id} has been clicked!`);
// }, []);

// const onSnackbarClosed = (
//   event: React.SyntheticEvent | Event,
//   reason?: string
// ) => {
//   if (reason === "clickaway") {
//     return;
//   }
//   setSnackbarData(INITIAL_SNACKBAR_DATA);
// };

// const { isLoading: isDeleting, mutate: deleteRL1SlipById } = useMutation(
//   RL1SlipService.delRL1Slip,
//   {
//     onSuccess: (res: string | IServerError | IResponse) => {
//       console.log("resonce for Delete row",res)
//       if (typeof res === "string") {
//         // remove the cache of the Year fields since we just added a new field.
//         setSnackbarData({
//           open: true,
//           message: res,
//           severity: "success",
//         });

//         return queryClient.invalidateQueries(
//           TAXSLIP_RL1_LIST_QUERY_KEY
//           );
//         } else {
//           setSnackbarData({
//             open: true,
//             message: res.message,
//             severity: "error",
//           });
//         }
//       },
//       onError: (err:any) => {
//         console.log("resonce for Delete row",err)
//         // TODO: customize the toast message based on the response
//         if (err.message === "Request failed with status code 404"){
//           setSnackbarData({
//             open: true,
//             message: "Record not Found",
//             severity: "error",
//           });
//         }
//         else{
//         setSnackbarData({
//           open: true,
//           // message: "Something went wrong, try again later!",
//           message: err.message,
//           severity: "error",
//         });
//       }
//       },
//   }
// );

// // Bulk Delete
// const [bulkDelete, setBulkDelete] = useState<boolean>(false);
// const [bulkRL1SlipsId, setBulkRL1SlipsId] = useState<any>({});
// const [bulkRL1SlipsDelete, setBulkRL1SlipsDelete] = useState<boolean>(false);

// const { isLoading: isBulkDeleting, mutate: deleteBulkRL1SlipsById } =
//     useMutation(RL1SlipService.delBulkRL1Slips, {
//       onSuccess: (res: any | string | IServerError | IResponse) => {
//         if (typeof res === "object") {
//           // remove the cache of the Year fields since we just added a new field.
//           setSnackbarData({
//             open: true,
//             message: res.message,
//             severity: "success",
//           });

//           setBulkRL1SlipsDelete(false)
//           return queryClient.invalidateQueries(TAXSLIP_RL1_LIST_QUERY_KEY);
//         } else {
//           setSnackbarData({
//             open: true,
//             message: "Something went wrong, try again later!",
//             severity: "error",
//           });
//         }
//         setBulkRL1SlipsDelete(false)
//       },
//       onError: (err:any) => {
//         // TODO: customize the toast message based on the response
//         setSnackbarData({
//           open: true,
//           message: err.message,
//           severity: "error",
//         });
//         setBulkRL1SlipsDelete(false)
//       },
//     });

//   // For Bulk Delete
//   const deleteBulkRL1SlipsField = () => {
//     if (bulkDelete) {
//       setBulkDelete(false);
//       deleteBulkRL1SlipsById(bulkRL1SlipsId);
//       setDialogOpen(false);
//     }
//   };


// const saveXmlAsFile = (xmlData: any, filename: any) => {
//   const blob = new Blob([xmlData], { type: 'xml;charset=utf-8' });
//   FileSaver.saveAs(blob, filename);
// };

// const onGenerateXMLClicked = async (SlipType: any): Promise<void> => {
//     const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
//   console.log("completedSlipsIds",completedSlipsIds)
//   console.log("newData",newData)
//   // Convert arrays to strings and compare
//   if(newData.length > 0){
//     const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
//     if (!areArraysEqual) {
//       setSnackbarData({
//         open: true,
//         message: "Please Selected RL1 Completed Slips Only!",
//         severity: "error",
//       });
//     }else{
//   setIsLoadingData(true);
//   if(maskedStatus === "true"){
//     setIsLoadingData(false);
//     setSnackbarData({
//       open: true,
//       message: "Please unmask your data.",
//       severity: "error",
//     });
//   } else {
//     try {
//       const response:any = await ReportsService.createInvoice(clientId,taxYear);
//       setIsLoadingData(false);
//       console.log("responseresponse",response)
//       if(response.message === "Slips not found for invoice."){
//         setIsLoadingData(true);
//             try {
//               const filingAuditResponse:any = await RL1SlipService.getRL1AuditFiling(clientId,taxYear);
//               console.log("filingAuditResponse",filingAuditResponse)
//               if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
//                 console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else if(filingAuditResponse && filingAuditResponse.rl1_filling && filingAuditResponse.rl1_filling.length > 0){
//                 console.log("filingAuditResponse.rl1_filling",filingAuditResponse.rl1_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else {
//         setIsLoadingData(true);
//         try {
//           let SlipTypeData: number = 100;
//           if (SlipType === "Original"){
//             SlipTypeData = 400;
//           }
//           if (SlipType === "Amended"){
//             SlipTypeData = 200;
//           };
//           if (SlipType === "Cancelled"){
//             SlipTypeData = 300;
//           }
//         console.log("SlipTypeData", SlipTypeData)
//         const ids_list: any = newData.map((ids:any) => `rl1_slip_ids=${ids}`).join('&');
//         const pdfIds = ids_list.replace(/^&+|&+$/g, '')
//           const response: any = await PrintingService.createRL1Xml(clientId, SlipTypeData ,taxYear,pdfIds);
//           if(response && response.message){
//             setSnackbarData({
//               open: true,
//               message: response.message ? response.message : "Something went wrong.",
//               severity: "error",
//             });
//           }else{
//             const filename = 'RL1xml.xml';
//             saveXmlAsFile(response, filename);
//           }
//           setIsLoadingData(false);
//         } catch (err) {
//           console.log("err",err)
//           alert(err)
//         }
//       }
//       setIsLoadingData(false);
//     } catch(err:any) {
//       setIsLoadingData(false);
//       alert(err)
//     }
//       } else if(response && response.id !== null) {
//         setInvoiceData(response)
//         setInvoiceDialogOpen(true)
//       }else{
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong.",
//           severity: "error",
//         });
//       }
//       setIsLoadingData(false);
//     } catch (err) {
//       console.log("err",err)
//     }
//   }
//   }
// }
// };

// const onEmailButtonClicked = async (): Promise<void> => {
//   const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
//   console.log("completedSlipsIds",completedSlipsIds)
//   // Convert arrays to strings and compare
//   if(newData.length > 0){
//     const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
//     if (!areArraysEqual) {
//       setSnackbarData({
//         open: true,
//         message: "Please Selected RL1 Completed Slips Only!",
//         severity: "error",
//       });
//     }else{
//   setIsLoadingData(true);
//   if(maskedStatus === "true"){
//     setIsLoadingData(false);
//     setSnackbarData({
//       open: true,
//       message: "Please unmask your data.",
//       severity: "error",
//     });
//   } else {
//     try {
//       const response:any = await ReportsService.createInvoice(clientId,taxYear);
//       setIsLoadingData(false);
//       console.log("responseresponse",response)
//       if(response.message === "Slips not found for invoice."){
//         setIsLoadingData(true);
//         try {
//           const filingAuditResponse:any = await RL1SlipService.getRL1AuditFiling(clientId,taxYear);
//           console.log("filingAuditResponse",filingAuditResponse)
//           if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
//             console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
//             setSnackbarData({
//               open: true,
//               message: (
//                 <>
//                   Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
//                   <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                   textDecoration:"none",
//                                                   fontWeight:"bold"}}>Click here to view</a>
//                 </>
//               ),
//               severity: "error",
//               autoHide:false
//             });
//           } else if(filingAuditResponse && filingAuditResponse.rl1_filling && filingAuditResponse.rl1_filling.length > 0){
//             console.log("filingAuditResponse.rl1_filling",filingAuditResponse.rl1_filling)
//             setSnackbarData({
//               open: true,
//               message: (
//                 <>
//                   Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
//                   <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                   textDecoration:"none",
//                                                   fontWeight:"bold"}}>Click here to view</a>
//                 </>
//               ),
//               severity: "error",
//               autoHide:false
//             });
//           } 
//           // else if(filingAuditResponse && filingAuditResponse.rl1_warnings && filingAuditResponse.rl1_warnings.length > 0){
//           //   // Check if a string contains the word "email"
//           //   const containsEmail = (str: string) => str.toLowerCase().includes('email is missing');
//           //   const containsDOB = (str: string) => str.toLowerCase().includes('date of birth is missing');

//           //   console.log("containsEmail",containsEmail)

//           //   // Iterate over the array and log the string if it contains the word "email"
//           //   filingAuditResponse && filingAuditResponse.rl1_warnings.forEach((str:any) => {
//           //     if (containsEmail(str)) {
//           //       console.log("strstrstr email",str);
//           //       setSnackbarData({
//           //         open: true,
//           //         message: (
//           //           <>
//           //             {str}{' '}
//           //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//           //                                             textDecoration:"none",
//           //                                             fontWeight:"bold"}}>Click here to view</a>
//           //           </>
//           //         ),
//           //         severity: "error",
//           //         autoHide:false
//           //       });
//           //     } else if (containsDOB(str)) {
//           //       console.log("strstrstr dob",str);
//           //       setSnackbarData({
//           //         open: true,
//           //         message: (
//           //           <>
//           //             {str}{' '}
//           //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//           //                                             textDecoration:"none",
//           //                                             fontWeight:"bold"}}>Click here to view</a>
//           //           </>
//           //         ),
//           //         severity: "error",
//           //         autoHide:false
//           //       });
//           //     }
//           //   });
//             // setSnackbarData({
//             //   open: true,
//             //   message: (
//             //     <>
//             //       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
//             //       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//             //                                       textDecoration:"none",
//             //                                       fontWeight:"bold"}}>Click here to view</a>
//             //     </>
//             //   ),
//             //   severity: "error",
//             //   autoHide:false
//             // });
//           // } 
//           else {
//         const ids_list: any = newData.map((ids:any) => `slip_ids=${ids}`).join('&');
//         const pdfIds = ids_list.replace(/^&+|&+$/g, '')
//         setIsLoadingData(true);
//         try {
//           const response: any = await PrintingService.createBulkRL1PdfEmail(clientId, pdfIds, taxYear);
//           if(response.status === 400){
//             setSnackbarData({
//               open: true,
//               message: response.data.message ? response.data.message : "Something went wrong.",
//               // message: "Please Unmask sensitive data for PDF printing.",
//               severity: "error",
//             });
//           }else if(response.status === 201){
//             setSnackbarData({
//               open: true,
//               message: response.data.message ? response.data.message : "Email send Successfully.",
//               severity: "success",
//             });
//           } else {
//             setSnackbarData({
//               open: true,
//               message: "Something Went Wrong.",
//               severity: "error",
//             })
//           }
//           setIsLoadingData(false);
//         } catch (err) {
//           console.log("err",err)
//           alert(err)
//         }
//       }
//       setIsLoadingData(false);
//     } catch(err:any) {
//       setIsLoadingData(false);
//       alert(err)
//     }
//       } else if(response && response.id !== null) {
//         setInvoiceData(response)
//         setInvoiceDialogOpen(true)
//       }else{
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong.",
//           severity: "error",
//         });
//       }
//       setIsLoadingData(false);
//     } catch (err) {
//       console.log("err",err)
//     }
//     }
//   }
// }
// }

// const savePdfAsFile = (pdfData: any, filename: any) => {
//   const blob = new Blob([pdfData], { type: 'pdf;charset=utf-8' });
//   FileSaver.saveAs(blob, filename);
// };
// let newData:any = []
// const onPrintClicked = async (): Promise<void> => {
//   const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
//   console.log("completedSlipsIds",completedSlipsIds)
//   // Convert arrays to strings and compare
//   if(newData.length > 0){
//     const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
//     if (!areArraysEqual) {
//       setSnackbarData({
//         open: true,
//         message: "Please Selected RL1 Completed Slips Only!",
//         severity: "error",
//       });
//     }else{
//       if(maskedStatus === "true"){
//     setIsLoadingData(false);
//         setSnackbarData({
//           open: true,
//           message: "Please unmask your data.",
//           severity: "error",
//         });
//       } else {
//   setIsLoadingData(true);
//     try {
//       const response:any = await ReportsService.createInvoice(clientId,taxYear);
//       setIsLoadingData(false);
//       console.log("responseresponse",response)
//       if(response.message === "Slips not found for invoice."){
//         setIsLoadingData(true);
//             try {
//               const filingAuditResponse:any = await RL1SlipService.getRL1AuditFiling(clientId,taxYear);
//               console.log("filingAuditResponse",filingAuditResponse)
//               if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
//                 console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else if(filingAuditResponse && filingAuditResponse.rl1_filling && filingAuditResponse.rl1_filling.length > 0){
//                 console.log("filingAuditResponse.rl1_filling",filingAuditResponse.rl1_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else {
//         let ids_list:any = newData.map((ids:any) => `rl1_slip_ids=${ids}`).join('&');
//         const pdfIds = ids_list.replace(/^&+|&+$/g, '')
//         setIsLoadingData(true);
//         try {
//         const response: any = await PrintingService.createBulkRL1PdfPrint(clientId, pdfIds , taxYear);
//         if(response.status === 400){
//           setSnackbarData({
//             open: true,
//             message: response.data.message ? response.data.message : "Something went wrong.",
//             // message: "Please Unmask sensitive data for PDF printing.",
//             severity: "error",
//           });
//         }else{
//           const filename = 'RL1slip.pdf';
//           savePdfAsFile(response, filename);
//         }
//         setIsLoadingData(false);
//         } catch (err) {
//           console.log("err",err)
//           alert(err)
//         }
//       }
//       setIsLoadingData(false);
//     } catch(err:any) {
//       setIsLoadingData(false);
//       alert(err)
//     }
//       } else if(response && response.id !== null) {
//         setInvoiceData(response)
//         setInvoiceDialogOpen(true)
//       }else{
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong.",
//           severity: "error",
//         });
//       }
//     } catch (err) {
//       console.log("err",err)
//       setIsLoadingData(false);
//     }
//     }
//   }
// }
// };

// const onAllSlipsPrintClicked = async (): Promise<void> => {
//     setIsLoadingData(true);
//     if(maskedStatus === "true"){
//     setIsLoadingData(false);
//       setSnackbarData({
//         open: true,
//         message: "Please unmask your data.",
//         severity: "error",
//       });
//     } else {
//     try {
//       const response:any = await ReportsService.createInvoice(clientId,taxYear);
//       setIsLoadingData(false);
//       console.log("responseresponse",response)
//       if(response.message === "Slips not found for invoice."){
//         setIsLoadingData(true);
//             try {
//               const filingAuditResponse:any = await RL1SlipService.getRL1AuditFiling(clientId,taxYear);
//               console.log("filingAuditResponse",filingAuditResponse)
//               if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
//                 console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else if(filingAuditResponse && filingAuditResponse.rl1_filling && filingAuditResponse.rl1_filling.length > 0){
//                 console.log("filingAuditResponse.rl1_filling",filingAuditResponse.rl1_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else {
//         setIsLoadingData(true);
//         try {
//           const response: any = await PrintingService.createAllSlipsPdfPrint(clientId, "event", "RL1", taxYear);
//           console.log("response000",response)
//           if(response.status === 400){
//             setSnackbarData({
//               open: true,
//               message: response.data.message ? response.data.message : "Something went wrong.",
//               // message: "Please Unmask sensitive data for PDF printing.",
//               severity: "error",
//             });
//           }else{
//             const filename = 'RL1slip.pdf';
//             savePdfAsFile(response, filename);
//           }
//           setIsLoadingData(false);
//         } catch (err) {
//           console.log("err",err)
//         }
//       }
//       setIsLoadingData(false);
//     } catch(err:any) {
//       setIsLoadingData(false);
//       alert(err)
//     }
//       } else if(response && response.id !== null) {
//         setInvoiceData(response)
//         setInvoiceDialogOpen(true)
//       }else{
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong.",
//           severity: "error",
//         });
//       }
//       setIsLoadingData(false);
//     } catch (err) {
//       console.log("err",err)
//     }
//     }
// };


//   const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
//     setStatusValue(event.target.value as string);
//     setApiAction("updateStatus");
//     setDialogOpen(true);
//   };

//   const onRowMultipleDeleteClicked = (params: any) => {
//     console.log("Params in onRowMultipleDeleteClicked ====== ", params);
//     let bulkDeletePayload = {
//       ids:{"slipIds":params},
//       clientId,
//       taxYear
//     }
//     setDialogOpen(true);
//     setBulkDelete(true);
//     setApiAction("delete");
//     setBulkRL1SlipsId(bulkDeletePayload);
//     setBulkRL1SlipsDelete(true);
//   };

//   const selectionToolbar = useCallback(
//     (selectedItems) => {
//       setSelectedIds(selectedItems);
//       newData = selectedItems
//       console.log("selectedItems--",selectedItems)
//       if (selectedItems.length) {
//         return (
//           <Box sx={{ marginTop: theme.spacing(1), display:"flex",justifyContent:"center",alignItems:"center" }}>
//             <Box sx={{ 
//               marginRight:"0.5rem", 
//               textTransform:"capitalize", 
//               display:"inline-block",
//               "& .MuiPopper-root":{zIndex:2}}}>
//             <SplitButton
//                   options={[
//                     "Original",
//                     "Amended",
//                     "Cancelled",
//                   ]}
//                   onClickHandler={onGenerateXMLClicked}
//                   testId="RL1-print-button"
//                   icon={<ArticleIcon />}
//                   listItemsLabel={`Generate XML  `}
//                 />
//             </Box>
//             <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block", "& .MuiPopper-root":{zIndex:2}}}>
//               <Button variant="outlined" startIcon={<Print />} onClick={onAllSlipsPrintClicked}>
//                 Print All
//               </Button>
//             </Box>
//             <Box sx={{ marginRight:"0.5rem", textTransform:"capitalize", display:"inline-block"}}>
//               <Button variant="outlined" startIcon={<Print />} onClick={onPrintClicked}>
//                 Print
//               </Button>
//               {/* <PrintButton
//                 onClickHandler={onPrintClicked}
//                 testId="RL1-Slips-print-button" label={"Print"}
//                 /> */}
//             </Box>
//             <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
//               <Button variant="outlined" startIcon={<EmailIcon />} onClick={onEmailButtonClicked}>
//                 Email
//               </Button>
//             </Box>
//             <Button
//                 onClick={() => onRowMultipleDeleteClicked(selectedItems)}
//                 variant="contained"
//                 color="error"
//               >
//                 Delete
//             </Button>
//           </Box>
//         );
//       }
//       return (
//         <Box sx={{ marginTop: theme.spacing(1),display:"flex" }}>
//           <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
//             <EnhancedLinkButton text={"Import CSV"} icon={<ExitToAppIcon/>} navigateTo={"/taxslip/rl1/import"} testId={'import-rl1-slip-button'}/>
//           </Box>
//           <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
//             <EnhancedLinkButton text={"Import XML"} icon={<ExitToAppIcon/>} navigateTo={"/taxslip/rl1/import-xml"} testId={'import-rl1-slip-button'}/>
//           </Box>
//           <RouterLinkButton to="/taxslip/rl1/setup" text="Add RL-1 Slip" />
//         </Box>
//       );
//     },
//     [theme,selectedRowsValues]
//   );

//   const onUnPaidClickHandler = (clickedRow: any) => {
//     setIsLoadingData(true);
//     // setTimeout(() => {
//     //   setIsLoadingData(false);
//     // }, 2500);
//     const fetchData = async () => {
//       try {
//         const response: any =
//         await ReportsService.paypalIntegrationOfUnpaidInvoice(id,clickedRow.refNo);
//         setIsLoadingData(false);
//         console.log("responseData111-----------------",response);
//         window.open(response, '_blank');

//     } catch (error) {
//           console.error("Error fetching data:", error);
//           setIsLoadingData(false);
//         }
//     }
//     fetchData();
//     console.log("clickedRowclickedRowclickedRow", clickedRow);
//   };

//   const onClickInvoiceNoHandler = (clickedRow: any) => {
//     dispatch(setSelectedINVOICE(clickedRow));
//     console.log("clickedRowclickedRowclickedRow", clickedRow);
//   };


//   const image =
//   "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";
  
//   return slipList && slipList.items && slipList.items.length === 0 && zeroClient === true || slipList && slipList.message && slipList.message.message === 'No Quebec employees found' ? (
//     <EmptyList 
//     showImage={true}
//     imagePath= {image}
//     heading={`There's nothing! Add RL1 Slip to proceed`} 
//     content={`You can add rl1 slip by directly clicking on add button or by importing csv/xml file.`} 
//     testId={"rl1slip-empty-list"} 
//     showAddButton= {true}
//     addButtonLabel={`Add RL1 Slip`}
//     navigateToAdd={"/taxslip/rl1/setup"}
//     showImportButton={true}
//     importButtonLabel="Import CSV"
//     navigateToImport={"/taxslip/rl1/import"}
//     showImportXmlButton={true}
//     importXmlButtonLabel="Import XML"
//     navigateToImportXml={"/taxslip/rl1/import-xml"}
//     />
//   ) : (
//     <>
//     <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
//       <BackdropCircularProgress
//           open={
//             isBulkDeleting || isDeleting || isLoadingData
//           }
//           testId="RL1 Slip List"
//         />
//       <DeleteConfirmationDialog
//           open={openDialog}
//           deleteActionHandler={bulkRL1SlipsDelete === true ? deleteBulkRL1SlipsField : deleteRL1SlipField}
//           cancelActionHandler={onCloseHandler}
//           testId="RL!-confirmation-dialog"
//         />
//       <EnhancedDataGrid
//         columns={slipList && slipList.headerColumns ? slipList.headerColumns : []}
//         rows={slipList?.items}
//         selectionEnabled={true}
//         autoHeight={true}
//         allowSearching={true}
//         onSearchButtonClicked={onSearchButtonClicked}
//         searchFieldPlaceholder={"Search by Employee Name..."}
//         rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
//         selectionToolbar={selectionToolbar}
//         selectedRowsValues={(value:any)=>setSelectedRowsValues(value)}
//         onRowEditClicked={onRowEditClicked}
//         onRowDeleteClicked={onRowDeleteClicked}
//         // initialPage={0}
//         initialPageSize={INITIAL_PAGE_SIZE}
//         disableColumnMenu={true}
//         showRowActionsColumn={true}
//         autoPageSize={false} //
//         showIndexColumn={true}
//         testId="resource-list-grid"
//         sortingMode="server"
//         filterMode="server"
//         paginationMode="server"
//         onRequestParamsChange={onRequestParamsChange}
//         onSearchCrossClicked={(value) => setzeroClient(value)}
//         totalRowsCount={slipList?.total}
//         isLoading={isLoading || isAxiosCallLoading}
//         rowIdGetter={(row) => row.id}
//         searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
//         shouldMask={false}
//       />
//       {/* invoice dialogu open if any transaction is unPaid */}
//       <EnhancedDialog
//             dialogTitle="Invoice(s) Due"
//             key="dialog-box"
//             testId="dialog"
//             onCloseHandler={onCloseHandler}
//             open={openInvoiceDialog}
//             dialogContent={
//               <InvoiceDialogPanel 
//               invoiceData={invoiceData} 
//               onUnPaidClickHandler={onUnPaidClickHandler}
//               onClickInvoiceNoHandler={onClickInvoiceNoHandler}
//               />
//             }
//             dialogAction={
//               <Grid>
//                 <Button
//                   autoFocus
//                   variant="contained"
//                   onClick={onCloseHandler}
//                 >
//                   Cancel
//                 </Button>
//               </Grid>
//             }
//           />
//     </Grid>
//     <EnhancedSnackbar
//     message={snackbarData.message}
//     onCloseHandler={onSnackbarClosed}
//     severity={snackbarData.severity}
//     testId={"RL!-list-snackbar"}
//     open={snackbarData.open}
//     autoHide={snackbarData.autoHide}
//   />
//   </>
//   );
// };



// use prime data grid 
import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useRef, useState } from "react";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IAppState } from "store/reducers";
import FileSaver from "file-saver";
import EmailIcon from '@mui/icons-material/Email';
import ArticleIcon from "@mui/icons-material/Article";
import { Box, Button, Grid, Tooltip, useTheme } from "@mui/material";
import { BackdropCircularProgress, DeleteConfirmationDialog, EmptyList, EnhancedDialog, EnhancedLinkButton, EnhancedSnackbar, IResponse, IServerError, IconTextButton, PrintButton, RouterLinkButton, SplitButton } from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import {
  GET_QUERY_KEY,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  RL1_LIST_SCREEN_ID,
  RL1_LIST_SCREEN_SLUG,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
} from "common/constants";
import { RL1SlipService } from "services/RL1SlipService";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { PrintingService } from "services/PrintingService";
import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions";
import { InvoiceDialogPanel } from "components/T4Slip/T4SlipList/InvoiceDialogPanel";
import { Print } from "@mui/icons-material";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import { PrimeDataGrid } from "../../PrimeDataGrid";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { T4SlipService } from "services/T4SlipService";
import { ClientService } from "services";
import { ProgressBar } from "primereact/progressbar";
export interface IRL1Slip {}
const TAXSLIP_RL1_LIST_QUERY_KEY = "rl1-slips-list"

const useRL1Slips = (
  clientId: string,
  taxYear: number,
  params: IGetListRequest
) =>
  useQuery(
    [GET_QUERY_KEY.LIST_RL1_SLIPS, params],
    (data) => {
      return RL1SlipService.getList(clientId, taxYear, params);
    },
    { refetchOnWindowFocus: false }
  );

  const GET_ADJUSTMENTS_QUERY_KEY = "get-adjustments";

  const useAdjustments = (clientId: string) =>
  useQuery(
    GET_ADJUSTMENTS_QUERY_KEY,
    () => AdjustmentOptionsService.getAdjustmentOptionsByClientId(clientId),
    QUERY_OPTIONS
  );

  const useGetSlipIds = (
    tag: string,
    clientId: string,
    taxYear: number
  ) =>
    useQuery(
      ["get-slip-ids", tag, clientId, taxYear],
      (data) => {
        return T4SlipService.getSlipIds(tag, clientId, taxYear);
      },
      { refetchOnWindowFocus: false }
    );

    const useGetCompanyData = (id: string | undefined) => 
      useQuery(
        ["client-get-by-id", id],
        (data) => {
          return ClientService.getById(id);
        },
        { refetchOnWindowFocus: false }
      );

export const RL1SlipList: React.FC<IRL1Slip> = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state: IAppState) => state.client);
  const taxYear  = useSelector((state: IAppState) => state.taxYear.year);
  const clientId = useSelector((state: IAppState) => state.client.id);
  const clientName = useSelector((state : IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector((state: IAppState) => state.isLoadAxiosCall.isLoading);
  const [invoicePaymentFor, setInvoicePaymentFor] = useState(10)
  const { resourceName } = useParams();
  const [dataRoleName, setDataRoleName] = useState<string>("");
  const maskedStatus:any = sessionStorage.getItem("maskEnabled")

  const interval = useRef<any>(null);
  const [percentageValue, setPercentageValue] = useState(0);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const[openInvoiceDialog, setInvoiceDialogOpen] = useState(false)
  const[invoiceData, setInvoiceData] = useState<any>([])
  const[isLoadingData, setIsLoadingData] = useState(false)
  const [selectedRowsValues, setSelectedRowsValues] = useState([]);
  const [rl1SlipListUpdatedItems, setRl1SlipListUpdatedItems] = useState<any>(undefined);

  // for delete row Purpose
  const queryClient = useQueryClient();
  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);

  useEffect(() => {
    if (resourceName && resourceName.length > 0) {
      console.log("resourceName>>>>>", resourceName);
      setDataRoleName(resourceName);
    }
  }, [dataRoleName, resourceName]);

  const theme = useTheme();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "name1",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useRL1Slips(id, taxYear, queryParams);
  const slipList: any = data;
  console.log("@@@@@ => resourceList", slipList);

  const { isLoading: isLoadingAdjustments, data: adjustments } =
    useAdjustments(clientId);
  let AdjustmentOptionsData : any = adjustments


  const { isLoading:loadingSlipIds, data:rl1SlipIds } = useGetSlipIds("RL1", clientId, taxYear);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  useEffect(()=>{
    console.log("companyGetByIdRecord11",companyGetByIdRecord)
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor ? parseInt(paymentFor.value) : 10)
    }
  },[companyGetByIdRecord])

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  // const isAxiosCallLoading = queryClient.isFetching([TAXSLIP_RL1_LIST_QUERY_KEY]) > 0;
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
  useEffect(()=>{
    if(isLoading){
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    } else {
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    }
  },[isLoading])

  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);
  const [searchText, setSearchText] = useState({});
  const [zeroClient, setzeroClient] = useState(true);

  // for delete row
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [rL1SlipId, setRL1SlipId] = useState<number>(0);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: "name1",
      order_direction: requestParams.sortOrder ? requestParams.sortOrder : "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
    };
    // if (requestParams.sort) {
    //   const [sortBy, sortDirection] = requestParams.sort.split(",");
    //   dispatchParams.order_by = toSnake(sortBy);
    //   dispatchParams.order_direction = sortDirection;
    // }
console.log("dispatchParams",dispatchParams)
    setQueryParams(dispatchParams);
  }, []);

  const onSearchButtonClicked=()=>{
    setQueryParams(searchText)
    setzeroClient(false)
  }

  const onRowEditClicked = useCallback((params) => {
    let path = `/taxslip/rl1/setup/${params.id}`;
    navigate(path);
  }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
    setInvoiceDialogOpen(false);
  };

  const callAction = () => {
    if (apiAction === "delete") {
      alert(`Delete row with ID: ${selectedIds} has been clicked!`);
      // TODO api call for delete
    } else if (apiAction === "updateStatus") {
      alert(`action need to trigger : ${apiAction}`);
      // TODO api call for update status
    }
    setDialogOpen(false);
  };

   // For Delete Row
 const deleteRL1SlipField = () => {
  if (singleDelete) {
    let payload = {
      rL1SlipId,
      clientId,
      taxYear
    }
    setSingleDelete(false);
    deleteRL1SlipById(payload);
    setDialogOpen(false);
  }
};

const onRowDeleteClicked = useCallback((params) => {
  setDialogOpen(true);
  setSingleDelete(true);
  setApiAction("delete");
  setRL1SlipId(params.id);
  console.log("RL1SlipId in RL1Slip List OnDeleteRowClicked function",params.id)
  // alert(`Delete row with ID: ${params.id} has been clicked!`);
}, []);

const onSnackbarClosed = (
  event: React.SyntheticEvent | Event,
  reason?: string
) => {
  if (reason === "clickaway") {
    return;
  }
  setSnackbarData(INITIAL_SNACKBAR_DATA);
};

const { isLoading: isDeleting, mutate: deleteRL1SlipById } = useMutation(
  RL1SlipService.delRL1Slip,
  {
    onSuccess: (res: string | IServerError | IResponse) => {
      console.log("resonce for Delete row",res)
      if (typeof res === "string") {
        // remove the cache of the Year fields since we just added a new field.
        setSnackbarData({
          open: true,
          message: res,
          severity: "success",
        });

        return queryClient.invalidateQueries(
          TAXSLIP_RL1_LIST_QUERY_KEY
          );
        } else {
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "error",
          });
        }
      },
      onError: (err:any) => {
        console.log("resonce for Delete row",err)
        // TODO: customize the toast message based on the response
        if (err.message === "Request failed with status code 404"){
          setSnackbarData({
            open: true,
            message: "Record not Found",
            severity: "error",
          });
        }
        else{
        setSnackbarData({
          open: true,
          // message: "Something went wrong, try again later!",
          message: err.message,
          severity: "error",
        });
      }
      },
  }
);

// Bulk Delete
const [bulkDelete, setBulkDelete] = useState<boolean>(false);
const [bulkRL1SlipsId, setBulkRL1SlipsId] = useState<any>({});
const [bulkRL1SlipsDelete, setBulkRL1SlipsDelete] = useState<boolean>(false);

const { isLoading: isBulkDeleting, mutate: deleteBulkRL1SlipsById } =
    useMutation(RL1SlipService.delBulkRL1Slips, {
      onSuccess: (res: any | string | IServerError | IResponse) => {
        if (typeof res === "object") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "success",
          });

          setBulkRL1SlipsDelete(false)
          return queryClient.invalidateQueries(TAXSLIP_RL1_LIST_QUERY_KEY);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
        setBulkRL1SlipsDelete(false)
      },
      onError: (err:any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
        setBulkRL1SlipsDelete(false)
      },
    });

  // For Bulk Delete
  const deleteBulkRL1SlipsField = () => {
    if (bulkDelete) {
      setBulkDelete(false);
      deleteBulkRL1SlipsById(bulkRL1SlipsId);
      setDialogOpen(false);
    }
  };


const saveXmlAsFile = (xmlData: any, filename: any) => {
  const blob = new Blob([xmlData], { type: 'xml;charset=utf-8' });
  FileSaver.saveAs(blob, filename);
};

const onGenerateXMLClicked = async (SlipType: any): Promise<void> => {
    const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
  console.log("completedSlipsIds",completedSlipsIds)
  console.log("newData",newData)
  // Convert arrays to strings and compare
  if(newData.length > 0){
    const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
    if (!areArraysEqual) {
      setSnackbarData({
        open: true,
        message: "Please Selected RL1 Completed Slips Only!",
        severity: "error",
      });
    }else{
  setIsLoadingData(true);
  if(maskedStatus === "true"){
    setIsLoadingData(false);
    setSnackbarData({
      open: true,
      message: "Please unmask your data.",
      severity: "error",
    });
  } else {
    if(invoicePaymentFor === 10){
      try {
        const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
          clientId,
          taxYear-1
        );
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
          if(rl1SlipIds && rl1SlipIds.generalAuditStatus !== null && rl1SlipIds.generalAuditStatus === true &&
            rl1SlipIds && rl1SlipIds.auditStatus !== null &&  rl1SlipIds.auditStatus === true){
            setIsLoadingData(true);
          try {
            let SlipTypeData: number = 100;
            if (SlipType === "Original"){
              SlipTypeData = 400;
            }
            if (SlipType === "Amended"){
              SlipTypeData = 200;
            };
            if (SlipType === "Cancelled"){
              SlipTypeData = 300;
            }
          console.log("SlipTypeData", SlipTypeData)
          const ids_list: any = newData.map((ids:any) => `rl1_slip_ids=${ids}`).join('&');
          const pdfIds = ids_list.replace(/^&+|&+$/g, '')
            const response: any = await PrintingService.createRL1Xml(clientId, SlipTypeData ,taxYear,pdfIds);
            if(response && response.message){
              setSnackbarData({
                open: true,
                message: response.message ? response.message : "Something went wrong.",
                severity: "error",
              });
            }else{
              const filename = `RL1xml-${SlipType}.xml`;
              saveXmlAsFile(response, filename);
            }
            setIsLoadingData(false);
          } catch (err) {
            console.log("err",err)
            alert(err)
          }
        // }
        setIsLoadingData(false);
      } else {
        setIsLoadingData(false);
        setSnackbarData({
        open: true,
        message: (
          <>
            Please load Audit in the menu, press Run RL-1 Audit and clear all audit items to generate documents.{" "}
            <a
              href="https://appv3.taxslips.com/#/audit/filing"
              style={{
                color: "#aa1010",
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              Click here to view
            </a>
          </>
        ),
        severity: "error",
        autoHide: false,
      });
    }

        } else if (
          response?.id != null &&
          previousYearInvoiceResponse?.id != null
        ) {
          console.log("one");
          setInvoiceData([previousYearInvoiceResponse, response]);
          setInvoiceDialogOpen(true);
        } else if (
          previousYearInvoiceResponse?.id != null &&
          response?.message === "Slips not found for invoice."
        ) {
          console.log("two");
          setInvoiceData([previousYearInvoiceResponse]);
          setInvoiceDialogOpen(true);
        } else if (
          response?.id != null &&
          previousYearInvoiceResponse?.message === "Slips not found for invoice."
        ) {
          console.log("three");
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        }else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    } else {
    try {
      const response:any = await ReportsService.createInvoice(clientId,taxYear);
      setIsLoadingData(false);
      console.log("responseresponse",response)
      if(response.message === "Slips not found for invoice."){
        // setIsLoadingData(true);
        //     try {
        //       const filingAuditResponse:any = await RL1SlipService.getRL1AuditFiling(clientId,taxYear);
        //       console.log("filingAuditResponse",filingAuditResponse)
        //       if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
        //         console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
        //         setSnackbarData({
        //           open: true,
        //           message: (
        //             <>
        //               Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
        //               <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
        //                                               textDecoration:"none",
        //                                               fontWeight:"bold"}}>Click here to view</a>
        //             </>
        //           ),
        //           severity: "error",
        //           autoHide:false
        //         });
        //       } else if(filingAuditResponse && filingAuditResponse.rl1_filling && filingAuditResponse.rl1_filling.length > 0){
        //         console.log("filingAuditResponse.rl1_filling",filingAuditResponse.rl1_filling)
        //         setSnackbarData({
        //           open: true,
        //           message: (
        //             <>
        //               Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
        //               <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
        //                                               textDecoration:"none",
        //                                               fontWeight:"bold"}}>Click here to view</a>
        //             </>
        //           ),
        //           severity: "error",
        //           autoHide:false
        //         });
        //       } else {
        // setIsLoadingData(true);
        if(rl1SlipIds && rl1SlipIds.generalAuditStatus !== null && rl1SlipIds.generalAuditStatus === true &&
          rl1SlipIds && rl1SlipIds.auditStatus !== null &&  rl1SlipIds.auditStatus === true){
          setIsLoadingData(true);
        try {
          let SlipTypeData: number = 100;
          if (SlipType === "Original"){
            SlipTypeData = 400;
          }
          if (SlipType === "Amended"){
            SlipTypeData = 200;
          };
          if (SlipType === "Cancelled"){
            SlipTypeData = 300;
          }
        console.log("SlipTypeData", SlipTypeData)
        const ids_list: any = newData.map((ids:any) => `rl1_slip_ids=${ids}`).join('&');
        const pdfIds = ids_list.replace(/^&+|&+$/g, '')
          const response: any = await PrintingService.createRL1Xml(clientId, SlipTypeData ,taxYear,pdfIds);
          if(response && response.message){
            setSnackbarData({
              open: true,
              message: response.message ? response.message : "Something went wrong.",
              severity: "error",
            });
          }else{
            const filename = `RL1xml-${SlipType}.xml`;
            saveXmlAsFile(response, filename);
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err",err)
          alert(err)
        }
      // }
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      setSnackbarData({
      open: true,
      message: (
        <>
          Please load Audit in the menu, press Run RL-1 Audit and clear all audit items to generate documents.{" "}
          <a
            href="https://appv3.taxslips.com/#/audit/filing"
            style={{
              color: "#aa1010",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Click here to view
          </a>
        </>
      ),
      severity: "error",
      autoHide: false,
    });
  }
    // } catch(err:any) {
    //   setIsLoadingData(false);
    //   alert(err)
    // }
      } else if(response && response.id !== null) {
        setInvoiceData([response])
        setInvoiceDialogOpen(true)
      }else{
        setSnackbarData({
          open: true,
          message: "Something went wrong.",
          severity: "error",
        });
      }
      setIsLoadingData(false);
    } catch (err) {
      console.log("err",err)
    }
  }
  }
  }
}
};

const onEmailButtonClicked = async (): Promise<void> => {
  const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
  console.log("completedSlipsIds",completedSlipsIds)
  // Convert arrays to strings and compare
  if(newData.length > 0){
    const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
    if (!areArraysEqual) {
      setSnackbarData({
        open: true,
        message: "Please Selected RL1 Completed Slips Only!",
        severity: "error",
      });
    }else{
  setIsLoadingData(true);
  if(maskedStatus === "true"){
    setIsLoadingData(false);
    setSnackbarData({
      open: true,
      message: "Please unmask your data.",
      severity: "error",
    });
  } else {
    if(invoicePaymentFor === 10){
      try {
        const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
          clientId,
          taxYear-1
        );
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
          if(rl1SlipIds && rl1SlipIds.generalAuditStatus !== null && rl1SlipIds.generalAuditStatus === true &&
            rl1SlipIds && rl1SlipIds.auditStatus !== null &&  rl1SlipIds.auditStatus === true){
            setIsLoadingData(true);

            const ids_list = 
                  (slipList?.items?.length === newData.length) 
                      ? {items:rl1SlipIds.slipIds} 
                      : {items:newData};
                      console.log("slipList?.items?.length === newData.length ",slipList?.items?.length === newData.length)
                      console.log("ids_list rl1SlipIds.slipIds ",rl1SlipIds.slipIds)
                      console.log("ids_list newData",newData)
                      console.log("ids_list",ids_list)
                  
                  setIsLoadingData(true);
                  try {
                    const tempResponse: any =
                      await T4SlipService.submitTemporaryData(
                        ids_list
                      );
                  const SubmitActualDataApi = async () => {
                    setPercentageValue(0)
                    try {
                      const response: any =
                      await PrintingService.createBulkRL1PdfEmail(
                        clientId,
                        tempResponse.id,
                        taxYear
                      );
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  };
                  SubmitActualDataApi();
                  setSnackbarData({
                    open: true,
                    message: "Email sending started!",
                    severity: "success",
                  });
                  setOpenProgressBarDialog(true)
                  //manage progress bar
                  let _val = percentageValue;
                  let consecutiveSameValueCount = 0; // Counter for consecutive same values
                  let lastAdjustedValue:any = null; // To track the last value
                  const fetchData = async () => {
                    try {
                      const response: any = await T4SlipService.temporaryItemsCount(tempResponse.id);
                      console.log("12345789", response);
                      console.log("12345789ff", response.fail);
                      console.log("12345789tt", tempResponse.totalFetched);
                      _val = Math.floor((response.counter / tempResponse.totalFetched) * 100); 
                      console.log("_val_val",_val)
                      setPercentageValue(_val);
                      // Check for consecutive same values
                      if (response.counter === lastAdjustedValue) {
                        consecutiveSameValueCount++;
                      } else {
                          consecutiveSameValueCount = 0; // Reset if value changes
                      }
                      lastAdjustedValue = response.counter;

                      if (consecutiveSameValueCount >= 4) {
                          console.log("Same value detected for 4 iterations, reloading screen.");
                          setSnackbarData({
                            open: true,
                            message: `Something went wrong, please try again later.`,
                            severity: "error",
                          });
                          setTimeout(()=>{
                            window.location.reload();
                          },2000)
                      }
                  
                      if (response.counter >= tempResponse.totalFetched) {
                        clearInterval(interval.current);
                        setPercentageValue(100)
                        
                        let totalSuccessfullImportedItems = response.counter - response.fail
                        setSnackbarData({
                          open: true,
                          message: `Email sent successfully to ${totalSuccessfullImportedItems} employee(s) except following. ${response.name}`,
                          severity: "success",
                        });
                        // Set up the interval
                        interval.current = setInterval(() => {
                          setOpenProgressBarDialog(false)
                          // redirectToPage(CSVInformationUI.tag);
                          clearInterval(interval.current);
                        }, 3000);
                      }
                  
                  
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  };
                  
                  // Set up the interval
                  interval.current = setInterval(() => {
                    fetchData();
                  }, 4000);
                  // const ids_list: any = newData.map((ids:any) => `slip_ids=${ids}`).join('&');
                  // const pdfIds = ids_list.replace(/^&+|&+$/g, '')
                  // setIsLoadingData(true);
                  // try {
                  //   const response: any = await PrintingService.createBulkRL1PdfEmail(clientId, pdfIds, taxYear);
                  //   if(response.status === 400){
                  //     setSnackbarData({
                  //       open: true,
                  //       message: response.data.message ? response.data.message : "Something went wrong.",
                  //       // message: "Please Unmask sensitive data for PDF printing.",
                  //       severity: "error",
                  //     });
                  //   }else if(response.status === 201){
                  //     setSnackbarData({
                  //       open: true,
                  //       message: response.data.message ? response.data.message : "Email send Successfully.",
                  //       severity: "success",
                  //     });
                  //   } else {
                  //     setSnackbarData({
                  //       open: true,
                  //       message: "Something Went Wrong.",
                  //       severity: "error",
                  //     })
                  //   }
                  //   setIsLoadingData(false);
                  } catch (err) {
                    console.log("err",err)
                    alert(err)
                  }
                // }
                setIsLoadingData(false);
              } else {
                setIsLoadingData(false);
                setSnackbarData({
                open: true,
                message: (
                  <>
                    Please load Audit in the menu, press Run RL-1 Audit and clear all audit items to generate documents.{" "}
                    <a
                      href="https://appv3.taxslips.com/#/audit/filing"
                      style={{
                        color: "#aa1010",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to view
                    </a>
                  </>
                ),
                severity: "error",
                autoHide: false,
              });
            }

        } else if (
          response?.id != null &&
          previousYearInvoiceResponse?.id != null
        ) {
          console.log("one");
          setInvoiceData([previousYearInvoiceResponse, response]);
          setInvoiceDialogOpen(true);
        } else if (
          previousYearInvoiceResponse?.id != null &&
          response?.message === "Slips not found for invoice."
        ) {
          console.log("two");
          setInvoiceData([previousYearInvoiceResponse]);
          setInvoiceDialogOpen(true);
        } else if (
          response?.id != null &&
          previousYearInvoiceResponse?.message === "Slips not found for invoice."
        ) {
          console.log("three");
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        }else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    } else {
    try {
      const response:any = await ReportsService.createInvoice(clientId,taxYear);
      setIsLoadingData(false);
      console.log("responseresponse",response)
      if(response.message === "Slips not found for invoice."){
        // setIsLoadingData(true);
        // try {
        //   const filingAuditResponse:any = await RL1SlipService.getRL1AuditFiling(clientId,taxYear);
        //   console.log("filingAuditResponse",filingAuditResponse)
        //   if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
        //     console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
        //     setSnackbarData({
        //       open: true,
        //       message: (
        //         <>
        //           Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
        //           <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
        //                                           textDecoration:"none",
        //                                           fontWeight:"bold"}}>Click here to view</a>
        //         </>
        //       ),
        //       severity: "error",
        //       autoHide:false
        //     });
        //   } else if(filingAuditResponse && filingAuditResponse.rl1_filling && filingAuditResponse.rl1_filling.length > 0){
        //     console.log("filingAuditResponse.rl1_filling",filingAuditResponse.rl1_filling)
        //     setSnackbarData({
        //       open: true,
        //       message: (
        //         <>
        //           Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
        //           <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
        //                                           textDecoration:"none",
        //                                           fontWeight:"bold"}}>Click here to view</a>
        //         </>
        //       ),
        //       severity: "error",
        //       autoHide:false
        //     });
        //   } 
          // else if(filingAuditResponse && filingAuditResponse.rl1_warnings && filingAuditResponse.rl1_warnings.length > 0){
          //   // Check if a string contains the word "email"
          //   const containsEmail = (str: string) => str.toLowerCase().includes('email is missing');
          //   const containsDOB = (str: string) => str.toLowerCase().includes('date of birth is missing');

          //   console.log("containsEmail",containsEmail)

          //   // Iterate over the array and log the string if it contains the word "email"
          //   filingAuditResponse && filingAuditResponse.rl1_warnings.forEach((str:any) => {
          //     if (containsEmail(str)) {
          //       console.log("strstrstr email",str);
          //       setSnackbarData({
          //         open: true,
          //         message: (
          //           <>
          //             {str}{' '}
          //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
          //                                             textDecoration:"none",
          //                                             fontWeight:"bold"}}>Click here to view</a>
          //           </>
          //         ),
          //         severity: "error",
          //         autoHide:false
          //       });
          //     } else if (containsDOB(str)) {
          //       console.log("strstrstr dob",str);
          //       setSnackbarData({
          //         open: true,
          //         message: (
          //           <>
          //             {str}{' '}
          //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
          //                                             textDecoration:"none",
          //                                             fontWeight:"bold"}}>Click here to view</a>
          //           </>
          //         ),
          //         severity: "error",
          //         autoHide:false
          //       });
          //     }
          //   });
            // setSnackbarData({
            //   open: true,
            //   message: (
            //     <>
            //       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
            //       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
            //                                       textDecoration:"none",
            //                                       fontWeight:"bold"}}>Click here to view</a>
            //     </>
            //   ),
            //   severity: "error",
            //   autoHide:false
            // });
          // } 
          // else {
            if(rl1SlipIds && rl1SlipIds.generalAuditStatus !== null && rl1SlipIds.generalAuditStatus === true &&
              rl1SlipIds && rl1SlipIds.auditStatus !== null &&  rl1SlipIds.auditStatus === true){
              setIsLoadingData(true);

              const ids_list = 
                    (slipList?.items?.length === newData.length) 
                        ? {items:rl1SlipIds.slipIds} 
                        : {items:newData};
                        console.log("slipList?.items?.length === newData.length ",slipList?.items?.length === newData.length)
                        console.log("ids_list rl1SlipIds.slipIds ",rl1SlipIds.slipIds)
                        console.log("ids_list newData",newData)
                        console.log("ids_list",ids_list)
                    
                    setIsLoadingData(true);
                    try {
                      const tempResponse: any =
                        await T4SlipService.submitTemporaryData(
                          ids_list
                        );
                    const SubmitActualDataApi = async () => {
                      try {
                        const response: any =
                        await PrintingService.createBulkRL1PdfEmail(
                          clientId,
                          tempResponse.id,
                          taxYear
                        );
                      } catch (error) {
                        console.error("Error fetching data:", error);
                      }
                    };
                    SubmitActualDataApi();
                    setSnackbarData({
                      open: true,
                      message: "Email sending started!",
                      severity: "success",
                    });
                    setOpenProgressBarDialog(true)
                    //manage progress bar
                    let _val = percentageValue;
                    let consecutiveSameValueCount = 0; // Counter for consecutive same values
                  let lastAdjustedValue:any = null; // To track the last value
                    const fetchData = async () => {
                      try {
                        const response: any = await T4SlipService.temporaryItemsCount(tempResponse.id);
                        console.log("12345789", response);
                        console.log("12345789ff", response.fail);
                        console.log("12345789tt", tempResponse.totalFetched);
                        _val = Math.floor((response.counter / tempResponse.totalFetched) * 100); 
                        console.log("_val_val",_val)
                        setPercentageValue(_val);
                        // Check for consecutive same values
                        if (response.counter === lastAdjustedValue) {
                          consecutiveSameValueCount++;
                        } else {
                            consecutiveSameValueCount = 0; // Reset if value changes
                        }
                        lastAdjustedValue = response.counter;

                        if (consecutiveSameValueCount >= 4) {
                            console.log("Same value detected for 4 iterations, reloading screen.");
                            setSnackbarData({
                              open: true,
                              message: `Something went wrong, please try again later.`,
                              severity: "error",
                            });
                            setTimeout(()=>{
                              window.location.reload();
                            },2000)
                        }
                    
                        if (response.counter >= tempResponse.totalFetched) {
                          clearInterval(interval.current);
                          setPercentageValue(100)
                          
                          let totalSuccessfullImportedItems = response.counter - response.fail
                          setSnackbarData({
                            open: true,
                            message: `Email sent successfully to ${totalSuccessfullImportedItems} employee(s) except following. ${response.name}`,
                            severity: "success",
                          });
                          // Set up the interval
                          interval.current = setInterval(() => {
                            setOpenProgressBarDialog(false)
                            // redirectToPage(CSVInformationUI.tag);
                            clearInterval(interval.current);
                          }, 3000);
                        }
                    
                    
                      } catch (error) {
                        console.error("Error fetching data:", error);
                      }
                    };
                    
                    // Set up the interval
                    interval.current = setInterval(() => {
                      fetchData();
                    }, 4000);
        // const ids_list: any = newData.map((ids:any) => `slip_ids=${ids}`).join('&');
        // const pdfIds = ids_list.replace(/^&+|&+$/g, '')
        // setIsLoadingData(true);
        // try {
        //   const response: any = await PrintingService.createBulkRL1PdfEmail(clientId, pdfIds, taxYear);
        //   if(response.status === 400){
        //     setSnackbarData({
        //       open: true,
        //       message: response.data.message ? response.data.message : "Something went wrong.",
        //       // message: "Please Unmask sensitive data for PDF printing.",
        //       severity: "error",
        //     });
        //   }else if(response.status === 201){
        //     setSnackbarData({
        //       open: true,
        //       message: response.data.message ? response.data.message : "Email send Successfully.",
        //       severity: "success",
        //     });
        //   } else {
        //     setSnackbarData({
        //       open: true,
        //       message: "Something Went Wrong.",
        //       severity: "error",
        //     })
        //   }
        //   setIsLoadingData(false);
        } catch (err) {
          console.log("err",err)
          alert(err)
        }
      // }
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      setSnackbarData({
      open: true,
      message: (
        <>
          Please load Audit in the menu, press Run RL-1 Audit and clear all audit items to generate documents.{" "}
          <a
            href="https://appv3.taxslips.com/#/audit/filing"
            style={{
              color: "#aa1010",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Click here to view
          </a>
        </>
      ),
      severity: "error",
      autoHide: false,
    });
  }
    // } catch(err:any) {
    //   setIsLoadingData(false);
    //   alert(err)
    // }
      } else if(response && response.id !== null) {
        setInvoiceData([response])
        setInvoiceDialogOpen(true)
      }else{
        setSnackbarData({
          open: true,
          message: "Something went wrong.",
          severity: "error",
        });
      }
      setIsLoadingData(false);
    } catch (err) {
      console.log("err",err)
    }
    }
  }
}
}
}

const savePdfAsFile = (pdfData: any, filename: any) => {
  const blob = new Blob([pdfData], { type: 'pdf;charset=utf-8' });
  FileSaver.saveAs(blob, filename);
};
let newData:any = []
const onPrintClicked = async (): Promise<void> => {
  const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
  console.log("completedSlipsIds",completedSlipsIds)
  // Convert arrays to strings and compare
  if(newData.length > 0){
    const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
    if (!areArraysEqual) {
      setSnackbarData({
        open: true,
        message: "Please Selected RL1 Completed Slips Only!",
        severity: "error",
      });
    }else{
      if(maskedStatus === "true"){
    setIsLoadingData(false);
        setSnackbarData({
          open: true,
          message: "Please unmask your data.",
          severity: "error",
        });
      } else {
        if(invoicePaymentFor === 10){
          try {
            const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
              clientId,
              taxYear-1
            );
          try {
            const response: any = await ReportsService.createInvoice(
              clientId,
              taxYear
            );
            setIsLoadingData(false);
            console.log("responseresponse", response);
            if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
              if(rl1SlipIds && rl1SlipIds.generalAuditStatus !== null && rl1SlipIds.generalAuditStatus === true &&
                rl1SlipIds && rl1SlipIds.auditStatus !== null &&  rl1SlipIds.auditStatus === true){
                setIsLoadingData(true);
            let ids_list:any = newData.map((ids:any) => `rl1_slip_ids=${ids}`).join('&');
            const pdfIds = ids_list.replace(/^&+|&+$/g, '')
            setIsLoadingData(true);
            try {
            const response: any = await PrintingService.createBulkRL1PdfPrint(clientId, pdfIds , taxYear);
            if(response.status === 400){
              setSnackbarData({
                open: true,
                message: response.data.message ? response.data.message : "Something went wrong.",
                // message: "Please Unmask sensitive data for PDF printing.",
                severity: "error",
              });
            }else{
              const filename = `RL1slip-${clientName}.pdf`;
              savePdfAsFile(response, filename);
            }
            setIsLoadingData(false);
            } catch (err) {
              console.log("err",err)
              alert(err)
            }
          // }
          setIsLoadingData(false);
        } else {
          setIsLoadingData(false);
          setSnackbarData({
          open: true,
          message: (
            <>
              Please load Audit in the menu, press Run RL-1 Audit and clear all audit items to generate documents.{" "}
              <a
                href="https://appv3.taxslips.com/#/audit/filing"
                style={{
                  color: "#aa1010",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Click here to view
              </a>
            </>
          ),
          severity: "error",
          autoHide: false,
        });
      }
  
            } else if (
              response?.id != null &&
              previousYearInvoiceResponse?.id != null
            ) {
              console.log("one");
              setInvoiceData([previousYearInvoiceResponse, response]);
              setInvoiceDialogOpen(true);
            } else if (
              previousYearInvoiceResponse?.id != null &&
              response?.message === "Slips not found for invoice."
            ) {
              console.log("two");
              setInvoiceData([previousYearInvoiceResponse]);
              setInvoiceDialogOpen(true);
            } else if (
              response?.id != null &&
              previousYearInvoiceResponse?.message === "Slips not found for invoice."
            ) {
              console.log("three");
              setInvoiceData([response]);
              setInvoiceDialogOpen(true);
            }else {
              setSnackbarData({
                open: true,
                message: "Something went wrong.",
                severity: "error",
              });
            }
            setIsLoadingData(false);
          } catch (err) {
            console.log("err", err);
            setIsLoadingData(false);
          }
          } catch (err) {
            console.log("err", err);
            setIsLoadingData(false);
          }
        } else {
  setIsLoadingData(true);
    try {
      const response:any = await ReportsService.createInvoice(clientId,taxYear);
      setIsLoadingData(false);
      console.log("responseresponse",response)
      if(response.message === "Slips not found for invoice."){
        // setIsLoadingData(true);
        //     try {
        //       const filingAuditResponse:any = await RL1SlipService.getRL1AuditFiling(clientId,taxYear);
        //       console.log("filingAuditResponse",filingAuditResponse)
        //       if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
        //         console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
        //         setSnackbarData({
        //           open: true,
        //           message: (
        //             <>
        //               Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
        //               <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
        //                                               textDecoration:"none",
        //                                               fontWeight:"bold"}}>Click here to view</a>
        //             </>
        //           ),
        //           severity: "error",
        //           autoHide:false
        //         });
        //       } else if(filingAuditResponse && filingAuditResponse.rl1_filling && filingAuditResponse.rl1_filling.length > 0){
        //         console.log("filingAuditResponse.rl1_filling",filingAuditResponse.rl1_filling)
        //         setSnackbarData({
        //           open: true,
        //           message: (
        //             <>
        //               Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
        //               <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
        //                                               textDecoration:"none",
        //                                               fontWeight:"bold"}}>Click here to view</a>
        //             </>
        //           ),
        //           severity: "error",
        //           autoHide:false
        //         });
        //       } else {
          if(rl1SlipIds && rl1SlipIds.generalAuditStatus !== null && rl1SlipIds.generalAuditStatus === true &&
            rl1SlipIds && rl1SlipIds.auditStatus !== null &&  rl1SlipIds.auditStatus === true){
            setIsLoadingData(true);
        let ids_list:any = newData.map((ids:any) => `rl1_slip_ids=${ids}`).join('&');
        const pdfIds = ids_list.replace(/^&+|&+$/g, '')
        setIsLoadingData(true);
        try {
        const response: any = await PrintingService.createBulkRL1PdfPrint(clientId, pdfIds , taxYear);
        if(response.status === 400){
          setSnackbarData({
            open: true,
            message: response.data.message ? response.data.message : "Something went wrong.",
            // message: "Please Unmask sensitive data for PDF printing.",
            severity: "error",
          });
        }else{
          const filename = `RL1slip-${clientName}.pdf`;
          savePdfAsFile(response, filename);
        }
        setIsLoadingData(false);
        } catch (err) {
          console.log("err",err)
          alert(err)
        }
      // }
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      setSnackbarData({
      open: true,
      message: (
        <>
          Please load Audit in the menu, press Run RL-1 Audit and clear all audit items to generate documents.{" "}
          <a
            href="https://appv3.taxslips.com/#/audit/filing"
            style={{
              color: "#aa1010",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Click here to view
          </a>
        </>
      ),
      severity: "error",
      autoHide: false,
    });
  }
    // } catch(err:any) {
    //   setIsLoadingData(false);
    //   alert(err)
    // }
      } else if(response && response.id !== null) {
        setInvoiceData([response])
        setInvoiceDialogOpen(true)
      }else{
        setSnackbarData({
          open: true,
          message: "Something went wrong.",
          severity: "error",
        });
      }
    } catch (err) {
      console.log("err",err)
      setIsLoadingData(false);
    }
    }
  }
  }
}
};

const onAllSlipsPrintClicked = async (): Promise<void> => {
    setIsLoadingData(true);
    if(maskedStatus === "true"){
    setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(rl1SlipIds && rl1SlipIds.generalAuditStatus !== null && rl1SlipIds.generalAuditStatus === true &&
              rl1SlipIds && rl1SlipIds.auditStatus !== null &&  rl1SlipIds.auditStatus === true){
              setIsLoadingData(true);
            try {
              const response: any = await PrintingService.createAllSlipsPdfPrint(clientId, "event", "RL1", taxYear);
              console.log("response000",response)
              if(response.status === 400){
                setSnackbarData({
                  open: true,
                  message: response.data.message ? response.data.message : "Something went wrong.",
                  // message: "Please Unmask sensitive data for PDF printing.",
                  severity: "error",
                });
              }else{
                const filename = `RL1slip-${clientName}.pdf`;
                savePdfAsFile(response, filename);
              }
              setIsLoadingData(false);
            } catch (err) {
              console.log("err",err)
            }
          // }
          setIsLoadingData(false);
        } else {
          setIsLoadingData(false);
          setSnackbarData({
          open: true,
          message: (
            <>
              Please load Audit in the menu, press Run RL-1 Audit and clear all audit items to generate documents.{" "}
              <a
                href="https://appv3.taxslips.com/#/audit/filing"
                style={{
                  color: "#aa1010",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Click here to view
              </a>
            </>
          ),
          severity: "error",
          autoHide: false,
        });
      }

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
    try {
      const response:any = await ReportsService.createInvoice(clientId,taxYear);
      setIsLoadingData(false);
      console.log("responseresponse",response)
      if(response.message === "Slips not found for invoice."){
        // setIsLoadingData(true);
            // try {
            //   const filingAuditResponse:any = await RL1SlipService.getRL1AuditFiling(clientId,taxYear);
            //   console.log("filingAuditResponse",filingAuditResponse)
            //   if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
            //     console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
            //     setSnackbarData({
            //       open: true,
            //       message: (
            //         <>
            //           Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
            //           <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
            //                                           textDecoration:"none",
            //                                           fontWeight:"bold"}}>Click here to view</a>
            //         </>
            //       ),
            //       severity: "error",
            //       autoHide:false
            //     });
            //   } else if(filingAuditResponse && filingAuditResponse.rl1_filling && filingAuditResponse.rl1_filling.length > 0){
            //     console.log("filingAuditResponse.rl1_filling",filingAuditResponse.rl1_filling)
            //     setSnackbarData({
            //       open: true,
            //       message: (
            //         <>
            //           Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
            //           <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
            //                                           textDecoration:"none",
            //                                           fontWeight:"bold"}}>Click here to view</a>
            //         </>
            //       ),
            //       severity: "error",
            //       autoHide:false
            //     });
            //   } else {
        setIsLoadingData(true);
        if(rl1SlipIds && rl1SlipIds.generalAuditStatus !== null && rl1SlipIds.generalAuditStatus === true &&
          rl1SlipIds && rl1SlipIds.auditStatus !== null &&  rl1SlipIds.auditStatus === true){
          setIsLoadingData(true);
        try {
          const response: any = await PrintingService.createAllSlipsPdfPrint(clientId, "event", "RL1", taxYear);
          console.log("response000",response)
          if(response.status === 400){
            setSnackbarData({
              open: true,
              message: response.data.message ? response.data.message : "Something went wrong.",
              // message: "Please Unmask sensitive data for PDF printing.",
              severity: "error",
            });
          }else{
            const filename = `RL1slip-${clientName}.pdf`;
            savePdfAsFile(response, filename);
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err",err)
        }
      // }
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      setSnackbarData({
      open: true,
      message: (
        <>
          Please load Audit in the menu, press Run RL-1 Audit and clear all audit items to generate documents.{" "}
          <a
            href="https://appv3.taxslips.com/#/audit/filing"
            style={{
              color: "#aa1010",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Click here to view
          </a>
        </>
      ),
      severity: "error",
      autoHide: false,
    });
  }
    // } catch(err:any) {
    //   setIsLoadingData(false);
    //   alert(err)
    // }
      } else if(response && response.id !== null) {
        setInvoiceData([response])
        setInvoiceDialogOpen(true)
      }else{
        setSnackbarData({
          open: true,
          message: "Something went wrong.",
          severity: "error",
        });
      }
      setIsLoadingData(false);
    } catch (err) {
      console.log("err",err)
    }
    }
    }
};


  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusValue(event.target.value as string);
    setApiAction("updateStatus");
    setDialogOpen(true);
  };

  const onRowMultipleDeleteClicked = (params: any) => {
    console.log("Params in onRowMultipleDeleteClicked ====== ", params);
    let bulkDeletePayload = {
      ids:{"slipIds":params},
      clientId,
      taxYear
    }
    setDialogOpen(true);
    setBulkDelete(true);
    setApiAction("delete");
    setBulkRL1SlipsId(bulkDeletePayload);
    setBulkRL1SlipsDelete(true);
  };

  const selectionToolbar = useCallback(
    (selectedItems) => {
      setSelectedIds(selectedItems);
      newData = selectedItems
      console.log("selectedItems--",selectedItems)
      if (selectedItems.length) {
        return (
          <Box sx={{ marginTop: theme.spacing(1), display:"flex",justifyContent:"center",alignItems:"center" }}>
            {slipList && slipList?.items.length === selectedItems.length ?
            (<Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block", "& .MuiPopper-root":{zIndex:2}}}>
            <Button variant="outlined" startIcon={<Print />} onClick={onAllSlipsPrintClicked}>
              Print
            </Button>
          </Box>)
          :(<Box sx={{ marginRight:"0.5rem", textTransform:"capitalize", display:"inline-block"}}>
          <Button variant="outlined" startIcon={<Print />} onClick={onPrintClicked}>
            Print
          </Button>
          {/* <PrintButton
            onClickHandler={onPrintClicked}
            testId="RL1-Slips-print-button" label={"Print"}
            /> */}
        </Box>)}
            <Box sx={{ 
              marginRight:"0.5rem", 
              textTransform:"capitalize", 
              display:"inline-block",
              "& .MuiPopper-root":{zIndex:2}}}>
            <SplitButton
                  options={[
                    "Original",
                    "Amended",
                    "Cancelled",
                  ]}
                  onClickHandler={onGenerateXMLClicked}
                  testId="RL1-print-button"
                  icon={<ArticleIcon />}
                  listItemsLabel={`Generate XML  `}
                />
            </Box>
            
            
            <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
              <Button variant="outlined" startIcon={<EmailIcon />} onClick={onEmailButtonClicked}>
                Email
              </Button>
            </Box>
            <Button
                onClick={() => onRowMultipleDeleteClicked(selectedItems)}
                variant="contained"
                color="error"
              >
                Delete
            </Button>
          </Box>
        );
      }
      return (
        <Box sx={{ marginTop: theme.spacing(1),display:"flex" }}>
          <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
          {AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? 
              <Tooltip title="Remittance has been transferred.">
                <span>
                  <Button startIcon={<ExitToAppIcon/>} disabled>Import CSV</Button>
                </span>
              </Tooltip>
              :
            <EnhancedLinkButton text={"Import CSV"} icon={<ExitToAppIcon/>} navigateTo={"/taxslip/rl1/import"} testId={'import-rl1-slip-button'}/>
            }
          </Box>
          <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
          {AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? 
            <Tooltip title="Remittance has been transferred.">
            <span>
              <Button startIcon={<ExitToAppIcon/>} disabled>Import XML</Button>
            </span>
          </Tooltip>
          :
            <EnhancedLinkButton text={"Import XML"} icon={<ExitToAppIcon/>} navigateTo={"/taxslip/rl1/import-xml"} testId={'import-rl1-slip-button'}/>
          }
          </Box>

          {AdjustmentOptionsData && AdjustmentOptionsData.remittanceTransferred ? 
            <Tooltip title="Remittance has been transferred.">
              <span>
                <Button variant="outlined" disabled >Add RL-1 Slip</Button>
              </span>
            </Tooltip>
            :
          <RouterLinkButton to="/taxslip/rl1/setup" text="Add RL-1 Slip" />
          }
        </Box>
      );
    },
    [theme,selectedRowsValues]
  );

  const onUnPaidClickHandler = (clickedRow: any, tag:string) => {
    setIsLoadingData(true);
    // setTimeout(() => {
    //   setIsLoadingData(false);
    // }, 2500);
    const fetchData = async () => {
      try {
        const response: any =
        await ReportsService.paypalIntegrationOfUnpaidInvoice(id,clickedRow.refNo,tag);
        setIsLoadingData(false);
        console.log("responseData111-----------------",response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const onClickInvoiceNoHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:RL1_LIST_SCREEN_SLUG,
      companyName:clientName,
      activityType:"activity_type=delete&activity_type=emailed&activity_type=print&activity_type=print_xml&activity_type=import_xml&activity_type=import_csv",
      activityLogVisibility:true
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[])

  useEffect(()=>{
    if(slipList){
      const updatedItemsResponse = slipList.items.map((item:any) => ({
        ...item,
        name: (
          <Link
            to={`/taxslip/rl1/setup/${item.id}`}
            className="no-underline"
            style={{ color: "blue" }}
            // onClick={() => onClickHandler(row)}
          >
            {item.name}
          </Link>
        ),
      }));
      setRl1SlipListUpdatedItems(updatedItemsResponse)
      console.log("Rl1SlipListUpdatedItemsRl1SlipListUpdatedItems",updatedItemsResponse)
  }
},[slipList])


  const image =
  "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";
  
  return slipList && slipList.items && slipList.items.length === 0 && zeroClient === true || slipList && slipList.message && slipList.message.message === 'No Quebec employees found' ? (
    <EmptyList 
    showImage={true}
    imagePath= {image}
    heading={`There's nothing! Add RL1 Slip to proceed`} 
    content={`You can add rl1 slip by directly clicking on add button or by importing csv/xml file.`} 
    testId={"rl1slip-empty-list"} 
    showAddButton= {true}
    addButtonLabel={`Add RL1 Slip`}
    navigateToAdd={"/taxslip/rl1/setup"}
    showImportButton={true}
    importButtonLabel="Import CSV"
    navigateToImport={"/taxslip/rl1/import"}
    showImportXmlButton={true}
    importXmlButtonLabel="Import XML"
    navigateToImportXml={"/taxslip/rl1/import-xml"}
    />
  ) : (
    <>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
      <BackdropCircularProgress
          open={
            isBulkDeleting || isDeleting || isLoadingData || isLoadingAdjustments || isLoadingActivityLogData
          }
          testId="RL1 Slip List"
        />
      <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={bulkRL1SlipsDelete === true ? deleteBulkRL1SlipsField : deleteRL1SlipField}
          cancelActionHandler={onCloseHandler}
          testId="RL!-confirmation-dialog"
        />
      {/* <EnhancedDataGrid
        columns={slipList && slipList.headerColumns ? slipList.headerColumns : []}
        rows={slipList?.items}
        selectionEnabled={true}
        autoHeight={true}
        allowSearching={true}
        onSearchButtonClicked={onSearchButtonClicked}
        searchFieldPlaceholder={"Search by Employee Name..."}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        selectionToolbar={selectionToolbar}
        selectedRowsValues={(value:any)=>setSelectedRowsValues(value)}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        // initialPage={0}
        initialPageSize={INITIAL_PAGE_SIZE}
        disableColumnMenu={true}
        showRowActionsColumn={true}
        autoPageSize={false} //
        showIndexColumn={true}
        testId="resource-list-grid"
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onRequestParamsChange={onRequestParamsChange}
        onSearchCrossClicked={(value) => setzeroClient(value)}
        totalRowsCount={slipList?.total}
        isLoading={isLoading || isAxiosCallLoading}
        rowIdGetter={(row) => row.id}
        searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
        shouldMask={false}
      /> */}
      <PrimeDataGrid 
      columns={slipList && slipList.headerColumns ? slipList.headerColumns : []}
      rows={slipList && slipList.items && rl1SlipListUpdatedItems && rl1SlipListUpdatedItems}
      showIndexColumn={true}
      showChecboxColumn={true}
      showSettingColumn={true}
      onRowEditClicked={onRowEditClicked}
      onRowDeleteClicked={onRowDeleteClicked}
      isLoading={isLoading || isAxiosCallLoading}
      onSnackbarMessageData={(value: any) => setSnackbarData(value)}
      totalRecords={slipList && slipList.total ? slipList.total : 0}
      initialPageSize={INITIAL_PAGE_SIZE}
      rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
      onRequestParamsChange={onRequestParamsChange}
      selectionToolbar={selectionToolbar}
      selectedRowsValues={(value:any)=>setSelectedRowsValues(value)}
      searchFieldPlaceholder={"Search by Employee Name..."}
      changeLoginForGetEmptyItemsBySearchQuery={(value:any)=>setzeroClient(value)}
      
       />
      {/* invoice dialogu open if any transaction is unPaid */}
      <EnhancedDialog
            dialogTitle="Invoice(s) Due"
            key="dialog-box"
            testId="dialog"
            onCloseHandler={onCloseHandler}
            open={openInvoiceDialog}
            dialogContent={
              <InvoiceDialogPanel 
              invoiceData={invoiceData} 
              onUnPaidClickHandler={onUnPaidClickHandler}
              onClickInvoiceNoHandler={onClickInvoiceNoHandler}
              />
            }
            dialogAction={
              <Grid>
                <Button
                  autoFocus
                  variant="contained"
                  onClick={onCloseHandler}
                >
                  Cancel
                </Button>
              </Grid>
            }
          />

          {/* Email Send Dialog */}
        <EnhancedDialog
            dialogTitle={`Email sending`}
            key="dialog-box-1"
            testId="dialog"
            onCloseHandler={onCloseHandler}
            showCloseButton={false}
            open={openProgressBarDialog}
            dialogContent={
              <ProgressBar 
                value={percentageValue}
                pt={{
                    value: { style: { background: 'linear-gradient(to right, #8e2de2, #4a00e0)' } }
                }}
              ></ProgressBar>
            }
            dialogAction={
              <></>
            }
          />
    </Grid>
    <EnhancedSnackbar
    message={snackbarData.message}
    onCloseHandler={onSnackbarClosed}
    severity={snackbarData.severity}
    testId={"RL!-list-snackbar"}
    open={snackbarData.open}
    autoHide={snackbarData.autoHide}
  />
  </>
  );
};
