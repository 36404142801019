import { T4SlipSummaryReducedEISetup } from "components/T4Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT4SlipSummaryReducedEISetupPage {}

export const T4SlipSummaryReducedEISetupPage: FC<IT4SlipSummaryReducedEISetupPage> =
  () => {
    return (
      <AuthenticatedLayout
        pageTitle="T4 Summary Reduced EI"
        breadcrumbCurrentPageName=""
        breadcrumbLinks={[
          { text: "Compliance", navigateTo: "/taxslip/t4/list" },
          { text: "T4", navigateTo: "/taxslip/t4/list" },
          {
            text: "T4 Summary Reduced EI",
            navigateTo: "/taxslip/t4/summary-reduced-ei",
          },
        ]}
        mainContents={
          <T4SlipSummaryReducedEISetup testId="t4-summary-reduced-ei-setup" />
        }
      />
    );
  };
