import { T5SlipSetup } from "components/T5Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT5SlipSetupPage {}

export const T5SlipSetupPage: FC<IT5SlipSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Create T5 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t5/list" },
        { text: "T5", navigateTo: "/taxslip/t5/list" },
        { text: "Create T5 Slip", navigateTo: "/taxslip/t5/setup" },
      ]}
      mainContents={<T5SlipSetup testId="t4-slip-setup" />}
    />
  );
};
