import { Box, Card, Grid, Paper } from '@mui/material';
import { BackdropCircularProgress, EnhancedDivider, EnhancedSnackbar, IResponse, IServerError, ITest, IconTextButton, LabelPosition, RHookFormTextFieldWithLabel, ResetButton, SingleColumnLayout, StandardPanel, SubmitButton } from '@websential/cosmic'
import { GLOBAL_SEARCH_SCREEN_SLUG, INITIAL_PAGE_SIZE, INITIAL_SNACKBAR_DATA } from 'common/constants';
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query';
import { GlobalSearchServices } from 'services/GlobalSearchServices';
import { GlobalSearchFields } from './GlobalSearchFields';
import { GlobalSearchPanels } from './GlobalSearchPanels';
import { IGetListRequest } from 'models';
import { DataRoleService } from 'services';
import { useNavigate } from 'react-router-dom';
import { setUserMenuOpen } from 'store/actions';
import { useDispatch } from 'react-redux';
import { setSelectedActivityLogActions } from 'store/actions/activityLogActions';
import { setSelectedStickyNavBar } from 'store/actions/stickyNavBarActions';

export interface IGlobalSearch extends ITest{}

const DATA_ROLE_LIST_QUERY_KEY = "data-roles-list";
const useDataRoles = (params: IGetListRequest) =>
  useQuery(
    [DATA_ROLE_LIST_QUERY_KEY, params],
    (data) => {
      return DataRoleService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

let employeeRoleId:any = undefined
let vendorRoleId:any = undefined
let recipientRoleId:any = undefined
let beneficiaryRoleId:any = undefined
export const GlobalSearch: React.FC<IGlobalSearch> = ({ testId }) => {
    const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [responseData, setResponseData] = useState(undefined);
    const formMethods = useForm<any>({
    mode: "onBlur",
    reValidateMode: "onChange",
    });

    const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    GlobalSearchServices.getDesiredData,
    {
        onSuccess: (res: any | IServerError | IResponse) => {
        console.log("res123",res)
        if(res && res.accountants && res.accountants.length === 0 &&
            res.clients && res.clients.length === 0 &&
            res.resources && res.resources.length === 0){
            setSnackbarData({
                open: true,
                message: "No record found",
                severity: "error",
            });
        } else {
            setSnackbarData({
                open: true,
                message: "Successfully Get Data",
                severity: "success",
            });
        }
        setResponseData(res)
        },
        onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
            open: true,
            message: "An error occurred, please try again",
            severity: "error",
        });
        },
    }
    );

    const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "role_name",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
    });

    //data- roles list to get role ID named Employee, Vendor
    const { isLoading, data } = useDataRoles(queryParams); 
    const dataRolesList: any = data;
    console.log("@@@@@ => accountantList", dataRolesList);

    if(dataRolesList && dataRolesList.items){
    //vendor ID get
        const vendorItem = dataRolesList.items.find((item:any) => item.roleName === "Vendor");
        if (vendorItem) {
            const vendorId = vendorItem.id;
            vendorRoleId = vendorId
            }
    //Employee ID get
        const employeeItem = dataRolesList.items.find((item:any) => item.roleName === "Employee");
        if (employeeItem) {
            const employeeId = employeeItem.id;
            employeeRoleId = employeeId
            }
            // T5 Recipient
        const recipientItem = dataRolesList.items.find((item:any) => item.roleName === "T5_Recipient");
        if (recipientItem) {
            const recipientId = recipientItem.id;
            recipientRoleId = recipientId
            }
    // T3 Beneficiary
        const beneficiaryItem = dataRolesList.items.find((item:any) => item.roleName === "T3_Beneficiary");
        if (beneficiaryItem) {
            const beneficiaryId = beneficiaryItem.id;
            beneficiaryRoleId = beneficiaryId
        }
    } else if(dataRolesList && dataRolesList.message.detail === "Invalid token or expired token."){
        let path = '/logout'
        navigate(path)
    }
    console.log("employeeRoleId",employeeRoleId)
    console.log("vendorRoleId",vendorRoleId)

    const onSubmit = (formData: any) => {
    let payload = {
        accountantSearchText:formData.accountantSearchText,
        clientSearchText:formData.clientSearchText,
        roleId: 
        // formData.employeeSearchText && formData.vendorSearchText !== "" ? [employeeRoleId,vendorRoleId]:
            formData.employeeSearchText && formData.employeeSearchText !== "" && employeeRoleId ? [employeeRoleId] : 
            formData.vendorSearchText && formData.vendorSearchText !== "" && vendorRoleId ? [vendorRoleId] :
            formData.recipientSearchText && formData.recipientSearchText !== "" && recipientRoleId ? [recipientRoleId] :
            formData.beneficiarySearchText && formData.beneficiarySearchText !== "" && beneficiaryRoleId ? [beneficiaryRoleId] :
            [],
        resourceSearchText:
            formData.employeeSearchText && formData.employeeSearchText !== "" ? formData.employeeSearchText : 
            formData.vendorSearchText && formData.vendorSearchText !== "" ? formData.vendorSearchText : 
            formData.recipientSearchText && formData.recipientSearchText !== "" ? formData.recipientSearchText : 
            formData.beneficiarySearchText && formData.beneficiarySearchText !== "" ? formData.beneficiarySearchText : 
            '',
    }
    submitFormData(payload);
    console.log("formDataformData",formData)
    };

    const onResetClicked = () => {
    setSnackbarData({
        open: true,
        message: "Reset successfully",
        severity: "success",
    });
    formMethods.reset();
    setResponseData(undefined)
    };
    const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
    ) => {
    if (reason === "clickaway") {
        return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
    };

//defualt drawer options set in home 
  useEffect(() => {
    const homeDrawerOptionsOpen = {id:100,text:"Home"}
    dispatch(setUserMenuOpen(homeDrawerOptionsOpen));
  }, []);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: GLOBAL_SEARCH_SCREEN_SLUG,
        companyName: '',
        activityType:
          "",
        activityLogVisibility: false,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  return (
    <>
        <BackdropCircularProgress
            open={
                isSubmitting || 
                isLoading
            }
            testId={`${testId}-backdrop`}
        />
        <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Card data-testid="global-search" sx={{marginTop:"2rem",padding:"10px 0 30px 0","& .MuiDivider-root":{margin:"0 15px"}}}>
                <GlobalSearchFields 
                        testId={testId} 
                        onResetClicked={onResetClicked} 
                        isSubmitting={isSubmitting}
                        formMethods={formMethods}
                />
                    <EnhancedDivider
                        absolute={false}
                        flexItem={false}
                        light={false}
                        orientation="horizontal"
                        variant="fullWidth"
                        testId="divider"
                    />

                {/* panels */}
                <GlobalSearchPanels responseData={responseData && responseData}/>
            </Card>
        </form>
        </FormProvider>
        <EnhancedSnackbar
            message={snackbarData.message}
            onCloseHandler={onSnackbarClosed}
            severity={snackbarData.severity}
            testId={"data-role-field-tab-list-snackbar"}
            open={snackbarData.open}
        />
    </>
  )
}