import { FlexFieldPanelAssignment } from "components/FlexFieldPanelAssignment";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IFlexFieldPanelAssignmentPage {}

export const FlexFieldPanelAssignmentPage: FC<IFlexFieldPanelAssignmentPage> =
  () => {
    return (
      <AuthenticatedLayout
        pageTitle="Flex Field Panel Assignment Setup"
        breadcrumbCurrentPageName=""
        breadcrumbLinks={[]}
        mainContents={
          <FlexFieldPanelAssignment testId="flex-field-panel-assignment-setup" />
        }
      />
    );
  };
