import { Box, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ExposureIcon from "@mui/icons-material/Exposure";
import { GuidPanelList } from "./GuidPanelList";
import { DashboardPanels } from "./DashboardPanels";

import { IGetListRequest } from "models";
import { ResourceService } from "services/ResourceService";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { useEffect, useState } from "react";
import { GET_QUERY_KEY, INITIAL_PAGE_SIZE } from "common/constants";
import { FilingResourceService } from "services/FilingResourceService";
import { T4SlipService } from "services/T4SlipService";
import { T4ASlipService } from "services/T4ASlipService";
import { RL1SlipService } from "services/RL1SlipService";
import { BackdropCircularProgress } from "@websential/cosmic";
import { TaxSlipYearService } from "services";
import { setSelectedTaxYear, setUserMenuOpen } from "store/actions";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import { DashboardServices } from "services/common/DashboardServices";
import { useNavigate } from "react-router-dom";
import { clearSelectedResourceApiMessageActions } from "store/actions/resourceApiMessageActions";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";


const useTaxYearByClient = (
  clientId:string
  ) =>
useQuery(
  ["year"],
  (data) => {
    return TaxSlipYearService.getYear(clientId);
  },
  { refetchOnWindowFocus: false }
);

// DASHBOARD API
const useGetAllCounts = (
  clientId: string,
  taxYear:number
) =>
  useQuery(
    [`dashboard-counts`, clientId, taxYear],
    (data) => {
      return DashboardServices.getAllCounts(clientId, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

// employee
// let totalEmployees = 0;
// const EmpReasource = "Employee";
// const useResources = (
//   clientId: string,
//   EmpReasource: string,
//   params: IGetListRequest
// ) =>
//   useQuery(
//     [`${EmpReasource.toLowerCase()}-resource-list`, params],
//     (data) => {
//       return ResourceService.getList(clientId, EmpReasource, params);
//     },
//     { refetchOnWindowFocus: false }
//   );

// // vendor
// let totalVendor = 0;
// const vendorReasource = "Vendor";
// const useResourcesVendor = (
//   clientId: string,
//   vendorReasource: string,
//   params: IGetListRequest
// ) =>
//   useQuery(
//     [`${vendorReasource.toLowerCase()}-resource-list`, params],
//     (data) => {
//       return ResourceService.getList(clientId, vendorReasource, params);
//     },
//     { refetchOnWindowFocus: false }
//   );

// // Filling Resource
// let totalFR = 0;
// const useFilingResources = (clientId: string, params: IGetListRequest) =>
//   useQuery(
//     ["filing-resource-list", params],
//     (data) => {
//       return FilingResourceService.getList(clientId, params);
//     },
//     { refetchOnWindowFocus: false }
//   );

//   // T4 slip
//   let totalT4Slips = 0
//   const useT4Slips = (
//     clientId: string,
//     taxYear: number,
//     params: IGetListRequest
//   ) =>
//     useQuery(
//       [GET_QUERY_KEY.LIST_T4_SLIPS, clientId, taxYear, params],
//       (data) => {
//         return T4SlipService.getList(clientId, taxYear, params);
//       },
//       { refetchOnWindowFocus: false }
//     );
    
//   // RL-1 slips
//   let totalRl1Slips = 0
//   const useRl1Slips = (
//     clientId: string,
//     taxYear: number,
//     params: IGetListRequest
//   ) =>
//     useQuery(
//       [GET_QUERY_KEY.LIST_RL1_SLIPS, clientId, taxYear, params],
//       (data) => {
//         return RL1SlipService.getList(clientId, taxYear, params);
//       },
//       { refetchOnWindowFocus: false }
//     );
    
//   // T4A Slips
//   let totalT4aSlips = 0
//   const useT4ASlips = (
//     clientId: string,
//     taxYear: number,
//     params: IGetListRequest
//   ) =>
//     useQuery(
//       [GET_QUERY_KEY.LIST_T4A_SLIPS, clientId, taxYear, params],
//       (data) => {
//         return T4ASlipService.getList(clientId, taxYear, params);
//       },
//       { refetchOnWindowFocus: false }
//     );


  // Taxslip Year List
  let totalYears: any = []
  const useYears = (params: IGetListRequest) =>
  useQuery(
    ["years-list", params],
    (data) => {
      return TaxSlipYearService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

//adjustment options get call by client id and taxyear
const GET_ADJUSTMENT_OPTIONS_QUERY_KEY = "get-adjustment-options";
const useAdjustmentOptions = (
  clientId: string,taxYear:number
) =>
  useQuery(
    [GET_ADJUSTMENT_OPTIONS_QUERY_KEY, clientId, taxYear],
    (data) => {
      return AdjustmentOptionsService.getAdjustmentOptionsByClientIdAndTaxYear(clientId,taxYear);
    },
    { refetchOnWindowFocus: false }
  );

export const ClientDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useSelector((state: IAppState) => state.client);
  const taxYear = useSelector((state : IAppState) => state.taxYear.year);
  // const [year, setYear] = useState(new Date().getFullYear().toString());

  const [year, setYear] = useState(taxYear);


  // all counts recieved here 
  const { isLoading: isLoadingDashboardCounters, data: dashboardCounters } = useGetAllCounts(id, taxYear);
  const counts: any = dashboardCounters;

  useEffect(()=>{
      if(counts && counts.message && counts.message.detail === "Invalid token or expired token."){
        let path = '/logout'
        navigate(path)
      }
  },[counts])


  // resources
//   // employee
  const [queryParams ] = useState<IGetListRequest>({
    search_query: "",
    order_by: "name",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });
//   const employeeData = useResources(id, String(EmpReasource), queryParams);
//   const empData: any = employeeData.data;
//   console.log("@@@@@ => empData from dashboard", empData);
//   if (empData !== undefined) {
//     totalEmployees = empData.total;
//     console.log("@@@@@ => Total Vendor", totalEmployees);
//   }

//   // vendor
//   const dataVendor = useResourcesVendor(
//     id,
//     String(vendorReasource),
//     queryParams
//   );
//   const vendorData: any = dataVendor.data;
//   console.log("@@@@@ => vendorData from dashboard", vendorData);
//   if (vendorData !== undefined) {
//     totalVendor = vendorData.total;
//     console.log("@@@@@ => Total Vendor", totalVendor);
//   }

//   // Filling Resource
//   const [queryParamsFR] = useState<IGetListRequest>({
//     search_query: "",
//     order_by: "role_name",
//     order_direction: "asc",
//     page: 1,
//     size: 25,
//   });
//   const dataFR = useFilingResources(id, queryParamsFR);
//   const listRecords: any = dataFR.data;
//   console.log("@@@@@ => Filling Resource client Dashboard", listRecords);
//   if (listRecords !== undefined) {
//     totalFR = listRecords.total;
//     console.log("@@@@@ => Total Filling Resources", totalFR);
//   }

// // T4 slip
//   const T4SlipData  = useT4Slips(id, year, queryParams);
//   const t4SlipList: any = T4SlipData.data;
//   console.log("@@@@@ => T4 slip list", t4SlipList);
//   if (t4SlipList !== undefined) {
//     // totalT4Slips = t4SlipList.total;
//     const filteredOnlySlipsIds = t4SlipList && t4SlipList.items.filter((item:any) => item.id.length === 24);
//     console.log("filteredOnlySlipsIds",filteredOnlySlipsIds)
//     totalT4Slips = filteredOnlySlipsIds && filteredOnlySlipsIds.length;
//     console.log("@@@@@ => Total T4 Slips", totalT4Slips);
//   }

//   // T4A slips
//   const t4aSlipData = useT4ASlips(id, year, queryParams);
//   const t4aSlipList: any = t4aSlipData.data;
//   console.log("@@@@@ => T4A slip list", t4aSlipList);
//   if (t4aSlipList !== undefined) {
//     // totalT4aSlips = t4aSlipList.total;
//     const filteredOnlySlipsIds = t4aSlipList && t4aSlipList.items.filter((item:any) => item.id.length === 24);
//     console.log("filteredOnlySlipsIds",filteredOnlySlipsIds)
//     totalT4aSlips = filteredOnlySlipsIds && filteredOnlySlipsIds.length;
//     console.log("@@@@@ => Total T4a slips", totalT4aSlips);
//   }

//   // RL-1 slips
//   const rl1SlipData  = useRl1Slips(id, year, queryParams);
//   const rl1SlipList: any = rl1SlipData.data;
//   console.log("@@@@@ => RL-1 slip list", rl1SlipList);
//   if (rl1SlipList !== undefined) {
//     if(rl1SlipList && rl1SlipList.total){
//       // totalRl1Slips = rl1SlipList.total;
//       const filteredOnlySlipsIds = rl1SlipList && rl1SlipList.items.filter((item:any) => item.id.length === 24);
//       totalRl1Slips = filteredOnlySlipsIds && filteredOnlySlipsIds.length;
//       console.log("filteredOnlySlipsIds",filteredOnlySlipsIds)
//       console.log("@@@@@ => Total RL-1 slips", totalRl1Slips);
//     } else if (rl1SlipList && rl1SlipList.message && rl1SlipList.message.message === 'No Quebec employees found'){
//       totalRl1Slips = 0;
//     }
//   }

  // Taxslip Year list
  const yearListData = useYears(queryParams); 
  const yearsList: any = yearListData.data;
  console.log("@@@@@ => year List", yearsList);
  if (yearsList !== undefined) {
    totalYears = yearsList.items;
    console.log("@@@@@ => Total Years", totalYears);
  }

  // Adjustment OPtions
  const adjustmentOptions = useAdjustmentOptions(id,year); 
  const adjustmentOptionsData: any = adjustmentOptions.data;
  console.log("adjustmentOptions Data", adjustmentOptionsData);


  const [isYearLoading, setIsYearLoading] = useState(false)
  let loading = false
     // Taxslip Currernt Year by Client
     const { isLoading: isLoadingYear, data: yearGet, refetch: refetchCurrentYear, } = useTaxYearByClient(id); 
     const currentYear: any = yearGet;
     console.log("@@@@@ => currentYear", currentYear);
     let yearSelected = ''
     if(currentYear !== undefined){
       console.log("@@@@@ => currentYear", currentYear.taxYear);
       dispatch(setSelectedTaxYear({year:currentYear.taxYear}));
       yearSelected = currentYear.taxYear
      //  setYear(currentYear.taxYear)
     }

     const simplePanels = [
      {
        icon: (
          <PersonOutlineIcon
            sx={{ color: "rgb(0, 82, 204)", fontSize: "40px" }}
          />
        ),
        title: "Employees",
        navigateTo: "/resources/Employee/list",
        count: counts && counts.totalEmployee,
      },
      {
        icon: (
          <PersonOutlineIcon
            sx={{ color: "rgb(0, 82, 204)", fontSize: "40px" }}
          />
        ),
        title: "Vendors",
        navigateTo: "/resources/Vendor/list",
        count: counts && counts.totalVendor,
      },
      {
        icon: (
          <PersonOutlineIcon
            sx={{ color: "rgb(0, 82, 204)", fontSize: "40px" }}
          />
        ),
        title: "T5 Recipients",
        navigateTo: "/resources/T5_Recipient/list",
        count: counts && counts.totalT5Recipient,
      },
      {
        icon: (
          <PersonOutlineIcon
            sx={{ color: "rgb(0, 82, 204)", fontSize: "40px" }}
          />
        ),
        title: "T3 Beneficiary",
        navigateTo: "/resources/T3_Benificiary/list",
        count: counts && counts.totalT3Beneficiary,
      },
      // {
      //   icon: (
      //     <PeopleOutlineIcon
      //       sx={{ color: "rgb(0, 82, 204)", fontSize: "40px" }}
      //     />
      //   ),
      //   title: "Filling Resource",
      //   navigateTo: "/filing-resources/list",
      //   count: counts && counts.fillingResourceTotal,
      // },

      //show two panels in one panel
      // {
      //   jointPanels:[
      //     {
      //       icon: (
      //         <ExposureIcon sx={{ color: "rgb(0, 82, 204)", fontSize: "40px",marginTop:"-2.5px" }} />
      //         ),
      //         title: "Potential Adjustment & Correction",
      //         navigateTo: "/audit/pier-review",
      //         divider:true
      //     },
      //     {
      //       icon: (
      //         <PeopleOutlineIcon
      //           sx={{ color: "rgb(0, 82, 204)", fontSize: "40px" }}
      //         />
      //       ),
      //       title: "Users",
      //       navigateTo: "/invite/user",
      //     },
      //   ]
      // }
    ];

     const simplePanelsSecondList = [
      {
      icon: (
        <ExposureIcon sx={{ color: "rgb(0, 82, 204)", fontSize: "40px",marginTop:"-2.5px" }} />
        ),
        title: "Potential Adjustment & Correction",
        navigateTo: "/audit/pier-review",
      },
      {
        icon: (
          <PeopleOutlineIcon
            sx={{ color: "rgb(0, 82, 204)", fontSize: "40px" }}
          />
        ),
        title: "Users",
        navigateTo: "/invite/user",
      },
      {
        icon: (
          <PeopleOutlineIcon
            sx={{ color: "rgb(0, 82, 204)", fontSize: "40px" }}
          />
        ),
        title: "Filling Resource",
        navigateTo: "/filing-resources/list",
        count: counts && counts.fillingResourceTotal,
      },
    ];

  const summariesList = [
    {
      icon: "https://app.taxslips.com/_nuxt/img/cra_flag.3b01a4d.png",
      title: "T4 Summary",
      navigateTo: "/taxslip/t4/summary",
    },
    {
      icon: "https://app.taxslips.com/_nuxt/img/cra_flag.3b01a4d.png",
      title: "T4A Summary",
      navigateTo: "/taxslip/t4a/summary",
    },
    {
      icon: "https://app.taxslips.com/_nuxt/img/rq_flag.a7be73f.png",
      title: "RL-1 Summary",
      navigateTo: "/taxslip/rl1/summary",
    },
  ];
  const secondSummariesList = [
    {
      icon: "https://app.taxslips.com/_nuxt/img/cra_flag.3b01a4d.png",
      title: "T5 Summary",
      navigateTo: "/taxslip/t5/summary",
    },
    {
      icon: "https://app.taxslips.com/_nuxt/img/cra_flag.3b01a4d.png",
      title: "T3 Summary",
      navigateTo: "/taxslip/t3/summary",
    },
    {
      icon: "https://app.taxslips.com/_nuxt/img/rq_flag.a7be73f.png",
      title: "RL-3 Summary",
      navigateTo: "/taxslip/rl3/summary",
    },
    {
      icon: "https://app.taxslips.com/_nuxt/img/rq_flag.a7be73f.png",
      title: "RL-16 Summary",
      navigateTo: "/taxslip/rl16/summary",
    },
  ];
  const slipsList = [
    {
      icon: "https://app.taxslips.com/_nuxt/img/cra_flag.3b01a4d.png",
      title: "T4",
      navigateTo: "/taxslip/t4/list",
      slipCount: counts && counts.t4TotalSlip,
    },
    {
      icon: "https://app.taxslips.com/_nuxt/img/cra_flag.3b01a4d.png",
      title: "T4A",
      navigateTo: "/taxslip/t4a/list",
      slipCount: counts && counts.t4aTotalSlip,
    },
    {
      icon: "https://app.taxslips.com/_nuxt/img/rq_flag.a7be73f.png",
      title: "RL-1",
      navigateTo: "/taxslip/rl1/list",
      slipCount: counts && counts.rl1TotalSlip,
    },
  ];
  const secondSlipsList = [
    {
      icon: "https://app.taxslips.com/_nuxt/img/cra_flag.3b01a4d.png",
      title: "T5",
      navigateTo: "/taxslip/t5/list",
      slipCount: counts && counts.t5TotalSlip,
    },
    {
      icon: "https://app.taxslips.com/_nuxt/img/cra_flag.3b01a4d.png",
      title: "T3",
      navigateTo: "/taxslip/t3/list",
      slipCount: counts && counts.t3TotalSlip,
    },
    {
      icon: "https://app.taxslips.com/_nuxt/img/rq_flag.a7be73f.png",
      title: "RL-3",
      navigateTo: "/taxslip/rl3/list",
      slipCount: counts && counts.rl3TotalSlip,
    },
    {
      icon: "https://app.taxslips.com/_nuxt/img/rq_flag.a7be73f.png",
      title: "RL-16",
      navigateTo: "/taxslip/rl16/list",
      slipCount: counts && counts.rl16TotalSlip,
    },
  ];
  const guidPanels = {
    items: [
      {
        title: "Tax Slips Guide",
        color: "#2196f3",
        // navigateTo: "https://v3.taxslips.com/guides/#/general-guide",
        navigateTo: "https://taxslips.com/guides/#/general-guide",
      },
      {
        title: "T4 Guide",
        color: "#ffc107",
        // navigateTo: "https://v3.taxslips.com/guides/#/t4-guide",
        navigateTo: "https://taxslips.com/guides/#/t4-guide",
      },
      {
        title: "T4A Guide",
        color: "#f44336",
        // navigateTo: "https://v3.taxslips.com/guides/#/t4a-guide",
        navigateTo: "https://taxslips.com/guides/#/t4a-guide",
      },
      {
        title: "RL-1 Guide",
        color: "#4caf50",
        // navigateTo: "https://v3.taxslips.com/guides/#/rl1-guide",
        navigateTo: "https://taxslips.com/guides/#/rl1-guide",
      },
      {
        title: "T5 Guide",
        color: "#2196f3",
        navigateTo: "https://taxslips.com/guides/#/t5-guide",
      },
      {
        title: "RL-3 Guide",
        color: "#ffc107",
        navigateTo: "https://taxslips.com/guides/#/rl3-guide",
      },
      {
        title: "T3 Guide",
        color: "#f44336",
        navigateTo: "https://taxslips.com/guides/#/t3-guide",
      },
      {
        title: "RL-16 Guide",
        color: "#4caf50",
        navigateTo: "https://taxslips.com/guides/#/rl16-guide",
      },
    ],
  };
  const [nextFebruaryDate, setNextFebruaryDate] = useState('');
  const calculateNextFebruaryDate = () => {
    const nextYear:any = yearSelected + 1;
    const isLeapYear = (nextYear % 4 === 0 && nextYear % 100 !== 0) || nextYear % 400 === 0;
    const febLast = isLeapYear ? 29 : 28;

    setNextFebruaryDate(`${febLast} Feb ${nextYear}`);
  };

  {/* this is used when filing resource and filingdeadline use in same panel and seperate with seperator line */}
//   const deadLine = [
//     {
//       icon: (
//         <PeopleOutlineIcon
//           sx={{ color: "rgb(0, 82, 204)", fontSize: "40px" }}
//         />
//       ),
//       title: "Filling Resource",
//       navigateTo: "/filing-resources/list",
//       count: counts && counts.fillingResourceTotal,
//       divider:true
//     },
//     {
//       title: "Filing Deadline",
//       date: nextFebruaryDate,
//     }
// ];
  const deadLine = {
      title: "Filing Deadline",
      date: nextFebruaryDate,
    }
  
  // const useGetYearList = () =>
  // useQuery(
  //   GET_QUERY_KEY.YEAR_DDL,
  //   () => TaxSlipYearService.getListDDL(),
  //   QUERY_OPTIONS
  // );
  // const { isLoading: isLoadingYearDDL, data: yearList } = useYears(queryParamsFR);
  // const [selectedYear, setSelectedYear] = useState(2022);
  
  // const onYearSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
  //   setSelectedYear(Number(item.label));
  // };

  // let isLoading = false
  const [isLoadingSelect, setIsLoadingSelect] = useState(false)

  const handleChange = async (event: SelectChangeEvent): Promise<void> => {
    console.log("event.target.value",event.target.value)
    setIsYearLoading(true)    
    try {
      setYear(Number(event.target.value));
      yearSelected = event.target.value
      // setIsLoadingSelect(true)
      // setTimeout(() => {
      //   setIsLoadingSelect(false);
      // }, 1000);
      const response = await TaxSlipYearService.PutTaxYear(id,Number(event.target.value));
      dispatch(setSelectedTaxYear({year:response.taxYear}));
      refetchCurrentYear()
      console.log("responseData PutTaxYear",response.taxYear)
      // window.location.reload()
      // window.location.href = "/clients/dashboard";
    } catch (err) {
      console.log("err",err)
      alert(err)
    }
    setIsYearLoading(false)
  };
  // console.log("isLoading",isLoading)


  // const handleChange = (event: SelectChangeEvent) => {
  //   console.log("event.target.value",event.target.value)
  //   setYear(Number(event.target.value));
  //   dispatch(setSelectedTaxYear({year:Number(event.target.value)}));
  //   // isLoading=true
  //   setIsLoadingSelect(true)
  //   setTimeout(() => {
  //     setIsLoadingSelect(false);
  //   }, 1000);
  // };
  // console.log("isLoading",isLoading)

  // const yearItems = useMemo(() => {
  //   if (yearsList !== undefined && yearsList) {
  //     return yearsList.items.map((yearItem:any) => ({
  //       label: String(yearItem.year),
  //       value: yearItem.id,
  //     }));
  //   }
  // }, [yearsList]);

  // console.log("selected Year",year)
  // console.log("yearItemsyearItems",yearItems)

  // const schema = yup.object().shape({
  //   year: yup
  //     .string()
  //     .typeError("Please select your feeling.")
  //     .required("Please select your feeling."),
  // });
  // const methods = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    setIsYearLoading(true)
    setTimeout(() => {
      setIsYearLoading(false);
    }, 1500);
    calculateNextFebruaryDate();
    if(yearSelected !== ""){
      setYear(Number(yearSelected))
    }
  },[yearSelected])

  //defualt drawer options set in home 
  useEffect(() => {
    const homeDrawerOptionsOpen = {id:10000,text:"Home"}
    dispatch(setUserMenuOpen(homeDrawerOptionsOpen));
    dispatch(clearSelectedResourceApiMessageActions())
    // window.location.href = "/clients/dashboard";
    // window.location.href = "/";
  }, []);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: '',
        companyName: '',
        activityType:
          "",
        activityLogVisibility: false,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  console.log("yearSelected--",yearSelected)

  return (
    <>
    <BackdropCircularProgress
        open={
          // isLoading ||
          // isLoadingYearDDL
          isYearLoading ||
          isLoadingSelect  
          // || loading
          || isLoadingYear ||
          isLoadingDashboardCounters
        } 
        testId="client-dashboard-backdrop"      
      />
    <Grid
      xs={12}
      lg={12}
      xl={12}
      sm={12}
      sx={{margin: "0 2.5rem 0 3rem" }}
    >
      <Grid
      xs={12}
      lg={12}
      xl={12}
      sm={12}
      sx={{p: "0 10px 1rem 0", }}
      >
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent:"flex-end"}}>
        <InputLabel
          id="demo-simple-select-label"
          sx={{marginRight: '20px'}} // Adjust the positioning of the label
        >
          Filling Year
        </InputLabel>
          <Select
            test-Id="year-simple-select"
            value={yearSelected}
            onChange={handleChange}
            sx={{width:"200px",height:"2.5rem"}}
          >
             {yearsList !== undefined && yearsList.items.map((option:any) => (
              <MenuItem key={option.year} value={option.year}>
                {option.year}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Grid>
      {/* <EnhancedDropDownWithLabel
        selectName="selectedItemDDL"
        items={yearsList.items}
        onChangeHandler={handleChange}
        selectedItem={year}
        testId="enhancedDropDownSelectedList"
        label='Fruits'
        labelPosition={LabelPosition.Left}
      /> */}
      {/* <FormProvider {...methods}>
                <EnhancedDropDownRHFWithLabel
                  selectName="year"
                  label="Select Year"
                  labelPosition={LabelPosition.Left}
                  items={yearItems ? yearItems : []}
                  testId=""
                  onSelectionChangeHandler={onYearSelectionChangeHandler}
                />
        </FormProvider> */}
      <DashboardPanels
        panel={simplePanels}
        panelSecondlist={simplePanelsSecondList}
        summaryList={summariesList}
        summarySecondList={secondSummariesList}
        slip={slipsList}
        slipSecondList={secondSlipsList}
        deadline={deadLine}
      />
      <GuidPanelList {...guidPanels} />
    </Grid>
    </>
  );
};
