import React, { FC } from "react";
import { useFormContext } from "react-hook-form";

import TextField from "@mui/material/TextField";
import { ITest } from "@websential/cosmic";


/**
 * Text fields allow users to enter text into a UI. They typically appear in forms and dialogs.
 * The EnhancedTextInputWR wrapper component is a complete form control using Material UI TextField including
 * a label, input, and help text. Websential is using the outlined variants.
 */
export interface IRHookFormTextField extends ITest {
  /**
   * The id of the input element.
   */
  id: string;
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string;
  /**
   * If true, text input in the field will be shown in upper case
   * but the value will be in same case as typed by the user
   * you need to convert the text to upper case in case server required it in upper case
   */
  uppercase?: boolean;
  /**
   * Set the value of field; it is normally used when edit operation of the entity is required
   */
  value?: string | number;
  defaultValue?: string | number;
  onChange?: any;
  /**
   * If true, will not allow user to edit value
   */
  readOnly?: boolean;

  textAlign: any;
}

export const RHookFormTextField: FC<IRHookFormTextField> = ({
  id,
  testId,
  placeholder = "",
  uppercase = false,
  readOnly = false,
  textAlign,
  value,
  defaultValue,
  onChange,
}: IRHookFormTextField) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth={true}
      margin="dense"
      id={id}
      value={value}
      defaultValue={defaultValue}
      {...register(id)}
      onChange={onChange}
      placeholder={placeholder}
      error={!!errors[id]}
      helperText={errors[id]?.message}
      inputProps={{
        "data-testid": testId + "-field",
        style: {
          textTransform: uppercase ? "uppercase" : "none",
          backgroundColor: readOnly ? "#f2f2f2" : "white",
          textAlign: textAlign,
        },
        readOnly: readOnly,
      }}
    />
  );
};
