import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import "./rl16ReportDataTableStyle.css"
import { RequestParams } from '@websential/cosmic';
import { Button } from 'primereact/button';
import SearchIcon from '@mui/icons-material/Search';

export interface IDataTable {
    rows: any;
    columns:any;
    onRequestParamsChange?: (params: RequestParams) => void;
    initialPageSize?: number | undefined;
    initialPage?: number;
    totalRecords?: number;
    isLoading?: boolean;
}

const PrimeDataGrid: React.FC<IDataTable> = ({
    rows,
    columns,
    onRequestParamsChange,
    initialPageSize = 10,
    initialPage = 0,
    totalRecords,
    isLoading
}) => {
    const isInitialRequest = useRef(true);
    const [itemSearsch, setItemSearsch] = useState('');
    const [itemSearch, setItemSearch] = useState('');
    const [requestParams, setRequestParams] = useState<any>({
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        sortField: null,
        sortOrder: null,
        searchQuery:"",
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });
    
const onSearchButtonClicked = () => {
    setRequestParams((prevParams:any) => ({
        ...prevParams,
        searchQuery: itemSearch,
        }));
}
const handleClearSearch = () => {
    setItemSearch('')
    setRequestParams((prevParams:any) => ({
        ...prevParams,
        searchQuery: "",
        }));
    }
// Search type and billing profile Button
    const renderHeader = () => {
        return (
            // <div className='flex justify-content-between p-4 pl-0'  style={{backgroundColor:"#ebecf0",margin:"-17px"}}>
            <span className="p-input-icon-left w-5" style={{display:"flex"}}>
                <i className="pi pi-search" />
                <InputText value={itemSearch} onChange={(e) => setItemSearch(e.target.value)} placeholder="Search by Employee Name" className='w-full' style={{borderRadius:"5px 0px 0px 5px"}}/>
                {itemSearch && (
                    <Button
                    icon="pi pi-times"
                    onClick={handleClearSearch}
                    className="p-button-warning"
                    style={{borderRadius:0}}
                    />
                )}
                <Button
                    onClick={onSearchButtonClicked}
                    // variant="contained"
                    style={{
                    padding:"8px 10px 8px 10px",
                    marginRight:"-14px!important",
                    borderRadius:"0px 5px 5px 0px"}}
                >
                    <SearchIcon />
                </Button>
            </span>
            // </div>
        )
    }
    const headerSearch = renderHeader();
    const rowsWithTwoDecimalNumbers = rows && rows.map((item:any) => {
        // let { otherInformation } = item;
        // const limitedObject = Object.fromEntries(
        //     Object.entries(otherInformation).slice(0, 10)
        // );
        // item.otherInformation = limitedObject
        return {
        ...item,
        slipData: {
            ...item.slipData,
            capitalGains: item.slipData.capitalGains !== null ? parseFloat(item.slipData.capitalGains).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            singlePensionPayment: item.slipData.singlePensionPayment !== null ? parseFloat(item.slipData.singlePensionPayment).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            actualAmountOfEligibleDivinends: item.slipData.actualAmountOfEligibleDivinends !== null ? parseFloat(item.slipData.actualAmountOfEligibleDivinends).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            actualAmountOfDivinendsOtherThanEligible: item.slipData.actualAmountOfDivinendsOtherThanEligible !== null ? parseFloat(item.slipData.actualAmountOfDivinendsOtherThanEligible).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            pensionPaymentGivingEntitlement: item.slipData.pensionPaymentGivingEntitlement !== null ? parseFloat(item.slipData.pensionPaymentGivingEntitlement).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            foreignBusinessIncome: item.slipData.foreignBusinessIncome !== null ? parseFloat(item.slipData.foreignBusinessIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            foreignNonBusinessIncome: item.slipData.foreignNonBusinessIncome !== null ? parseFloat(item.slipData.foreignNonBusinessIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            otherIncome: item.slipData.otherIncome !== null ? parseFloat(item.slipData.otherIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            capitalGainGivingEntitlement: item.slipData.capitalGainGivingEntitlement !== null ? parseFloat(item.slipData.capitalGainGivingEntitlement).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            foreignIncomeTaxOnBusinessIncome: item.slipData.foreignIncomeTaxOnBusinessIncome !== null ? parseFloat(item.slipData.foreignIncomeTaxOnBusinessIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            foreignIncomeTaxOnNonBusinessIncome: item.slipData.foreignIncomeTaxOnNonBusinessIncome !== null ? parseFloat(item.slipData.foreignIncomeTaxOnNonBusinessIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            costBasedAdjustmentOfCapitalInterest: item.slipData.costBasedAdjustmentOfCapitalInterest !== null ? parseFloat(item.slipData.costBasedAdjustmentOfCapitalInterest).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            donationFromAReligiousOrganization: item.slipData.donationFromAReligiousOrganization !== null ? parseFloat(item.slipData.donationFromAReligiousOrganization).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            taxableAmountOfEligibleAndOrdinaryDividends: item.slipData.taxableAmountOfEligibleAndOrdinaryDividends !== null ? parseFloat(item.slipData.taxableAmountOfEligibleAndOrdinaryDividends).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
            dividendTaxCredit: item.slipData.dividendTaxCredit !== null ? parseFloat(item.slipData.dividendTaxCredit).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0.00",
          },
        // otherInformation: {
        //     // ...item.otherInformation,
        //     [0 + "_label_0"]: item.otherInformation["0_label_0"],
        //     [0 + "_amount_0"]: item.otherInformation["0_amount_0"] && parseFloat(item.otherInformation["0_amount_0"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        //     [1 + "_label_1"]: item.otherInformation["1_label_1"],
        //     [1 + "_amount_1"]: item.otherInformation["1_amount_1"] && parseFloat(item.otherInformation["1_amount_1"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        //     [2 + "_label_2"]: item.otherInformation["2_label_2"],
        //     [2 + "_amount_2"]: item.otherInformation["2_amount_2"] && parseFloat(item.otherInformation["2_amount_2"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        //     [3 + "_label_3"]: item.otherInformation["3_label_3"],
        //     [3 + "_amount_3"]: item.otherInformation["3_amount_3"] && parseFloat(item.otherInformation["3_amount_3"]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // }
    }
      });
    // const maxObjects = 45;
    // const updateColumnswith4OI = columns && columns.slice(0, maxObjects)
    const columnsWithTwoDecimalNumbers = columns && columns.map((item:any) => {
        return {
        ...item,
        // footer: item.footer !== null && typeof(item.footer) !== "string" ? parseFloat(item.footer).toFixed(2) : item.footer
        footer: item.footer !== null && item.footer !== "" && item.footer !== "Total" ? parseFloat(item.footer).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : item.footer
    }});

    const indexColumn = {
        body: (rowData:any, row:any) => {
            return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
        },
        header: '#',
    };
    
    const onPage = (event:any) => {
        setRequestParams(event);
    };
    // This useEffect is triggered when the debouncedSearchQuery, pageSize or currentPage values change
    useEffect(() => {
        // if the results fetcher was passed, we can use it to fetch the results.
        if (onRequestParamsChange) {
          // skip the first initial call
          if (isInitialRequest.current === true) {
            console.log(
              "##### => useEffect - requestParams - no first call",
              requestParams
            );
            isInitialRequest.current = false;
          } else {
            console.log(
              "##### => useEffect - requestParams - second call",
              requestParams
            );
            onRequestParamsChange(requestParams);
          }
        }
      }, [onRequestParamsChange, requestParams]);

      console.log("props headerColumns => ",columns)
      console.log("props items RL16Report => ",rows)
      console.log("rowsWithTwoDecimalNumbers",rowsWithTwoDecimalNumbers)

  return (
    <div>
       <DataTable 
            value={rows && rowsWithTwoDecimalNumbers} 
            responsiveLayout="scroll" 
            lazy 
            // filterDisplay="row"
            dataKey="id" 
            paginator
            first={requestParams.first} 
            rows={requestParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}

            // paginator 
            // rows={25}
            rowsPerPageOptions={[25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={`Showing {first} to {last} of ${totalRecords} Items`}
            editMode="row" 
            showGridlines
            resizableColumns columnResizeMode="expand"
            scrollable
            reorderableColumns
            filterDisplay="menu"
            loading={isLoading}
            globalFilterFields={['headerName']}
            header={headerSearch}
            emptyMessage="No Record found."
            className="rl16ReportDataTableStyle"
            // footer={footer}
            >
                {rows && <Column 
                    {...indexColumn} 
                    frozen
                    className='indexColumn'
                    />}
                {columns && columnsWithTwoDecimalNumbers.map((x:any,index:any) => {
                  return (
                    <Column 
                        key={index} 
                        field={x.field} 
                        header={x.headerName} 
                        headerTooltip={x.description}
                        headerTooltipOptions={{ position: 'top' }}
                        sortable={x.sortable} 
                        resizeable={x.resizable}
                        dataType={x.type}
                        align={x.align}
                        alignHeader={x.headerAlign}
                        reorderable={x.disableReorder}
                        exportable={x.disableExport}
                        frozen={x.frozenColumn}
                        columnKey={x.field}
                        style={{
                            minWidth:x.minWidth,
                                fontSize:"0.875rem",
                               }}
                        footer={x.footer}
                        />
                )})}
            </DataTable>
    </div>
  )
}

export default PrimeDataGrid

