import { IEnhancedMenuItem, IHeading, ISeparator } from '@websential/cosmic';

export const profileMenuItemsArraySuperUser: (IEnhancedMenuItem | ISeparator | IHeading)[] = [
  {
    label: "Change Password",
    navigateTo: '/me/change-password',
  },
  {
    label: "Users",
    navigateTo: '/invite/user',
  },
  {
    label: "Logout",
    navigateTo: '/logout',
  },
];
// export const profileMenuItemsArray: (IEnhancedMenuItem | ISeparator | IHeading)[] = [
//   {
//     label: "Profile",
//     navigateTo: '/clients/setup/:id',
//   },
//   {
//     label: "API",
//     navigateTo: '/me/api',
//   },
//   {
//     label: "Users",
//     navigateTo: '/invite/user',
//   },
//   {
//     label: "Change Password",
//     navigateTo: '/me/change-password',
//   },
//   {
//     label: "Logout",
//     navigateTo: '/logout',
//   },
// ];
