import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";
import { useParams } from "react-router-dom";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { MoveResource } from "components/Resource/MoveResource";

export interface IMoveResourcePage {}

export const MoveResourcePage: FC<IMoveResourcePage> = () => {
  const { resourceName } = useParams();
  console.log("resourceName>>>>> from page", resourceName);
  return (
    <AuthenticatedLayout
      pageTitle={`Move ${resourceName !== 'T5_Recipient' ? resourceName : 'T5 Recipient'}s`}
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        {
          text: `Move ${resourceName !== 'T5_Recipient' ? resourceName : 'T5 Recipient'}s`,
          navigateTo: `/resources/${resourceName}/move-people`,
        },
      ]}
      mainContents={<MoveResource />}
    />
  );
};
