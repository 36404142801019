import { TaxSlipYearSetup } from "components/TaxSlipYear";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const TaxSlipYearSetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="TaxSlip Year Setup"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Taxslip Years", navigateTo: "/years/list" },
      { text: "Create Year", navigateTo: "/years/setup" }
      ]}
      mainContents={<TaxSlipYearSetup testId="taxslip-year-setup-page" />}
    />
  );
};
