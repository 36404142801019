import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { InvoiceDetail } from "components/InvoiceDetail";

export interface IInvoiceDetailPage {}

export const InvoiceDetailPage: FC<IInvoiceDetailPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Invoice Detail"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<InvoiceDetail />}
    />
  );
};
