import { AxiosError, AxiosResponse } from "axios";
import { IFixedAndMetaField } from "common/FlexFieldsUtils";
import http from "common/http-common";
import { IAccountantListPayload, IAccountantResponse } from "models/accountant";
import { IClientListPayload, IClientRequest } from "models/client";
import {
  ACCOUNTANT_SETUP_FORM,
  ACCOUNTANT_SETUP_FORM_WITH_NULL_DEFAULT_VALUES,
} from "screensLayoutData/accountant";

import {
  IAccountant,
  IGetListRequest,
  IResponse,
  IServerError,
} from "../models";
import { GridSize } from "@mui/material";
import { DataType, FieldType } from "@websential/cosmic";
import { INITIAL_PAGE_SIZE } from "common/constants";
export let newData: IAccountantListPayload;
export const AccountantService = {
  getAccountants: async (
    data: IGetListRequest
  ): Promise<
    IAccountantListPayload | IServerError | IResponse | IAccountantResponse[]
  > => {
    try {
    const response: AxiosResponse<IAccountantListPayload> =
      await http.get<IAccountantListPayload>(
        "/v1/accountants/?order_by=" +
          data.order_by +
          "&order_direction=" +
          data.order_direction +
          "&page=" +
          data.page +
          "&size=" +
          data.size +
          "&search_query=" +
          data.search_query
      );

    if (response.status === 200) {
      // for (var i = 0; i < response.data.items.length; i++) {
      //   const record = response.data.items[i];
      //   response.data.items[i].name = record.firstName + " " + record.lastName;
      //   response.data.items[i].city = record.address?.city;
      //   record.metaFields?.map(
      //     (field: { name: string | number; value: any }) => {
      //       response.data.items[i][field.name] = field.value;
      //     }
      //   );
      // }

      // console.log("@@@@@ => ", response.data);
      // newData = response.data;
      // console.log("newData??????????????", newData);

      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return { status: false, message: axiosError.response ? axiosError.response.data : "Unknown error" };
  }
  },

  deleteById: async (
    accountantId: number
  ): Promise<string | IServerError | IResponse> => {
    console.log("deleteById", accountantId);
    // return new Promise(resolve => setTimeout(() => resolve("Flex Field has been deleted successfully"), 1000));
    const response: AxiosResponse<string> = await http.delete<string>(
      `/v1/accountants/${accountantId}/`
    );

    if (response.status === 204) {
      return {
        status: true,
        message: "Accountant has been deleted successfully",
      };
    } else if (response.status === 404) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  delAccountants: async (
    accountantId: string
    // data: IGetListRequest
  ): Promise<
    IAccountantListPayload | IServerError | IResponse | IAccountantResponse[]
  > => {
    const response: AxiosResponse<IAccountantListPayload> =
      await http.delete<IAccountantListPayload>(
        "/v1/accountants/" + accountantId + "/"
      );
    const responseNewList: AxiosResponse<IAccountantListPayload> =
      await http.get<IAccountantListPayload>(
        "/v1/accountants/"
        // +
        //   data.order_by +
        //   "&order_direction=" +
        //   data.order_direction +
        //   "&page=" +
        //   data.page +
        //   "&size=" +
        //   data.size +
        //   "&search_query=" +
        //   data.search_query
      );

    if (response.status === 204) {
      for (var i = 0; i < responseNewList.data.items.length; i++) {
        const record = responseNewList.data.items[i];
        responseNewList.data.items[i].name =
          record.firstName + " " + record.lastName;
        responseNewList.data.items[i].city = record.address?.city;
        record.metaFields?.map(
          (field: { name: string | number; value: any }) => {
            responseNewList.data.items[i][field.name] = field.value;
          }
        );
      }

      console.log("@@@@@ responseNewList.data => ", responseNewList.data);
      //       newData = response.data
      // console.log("newData??????????????",newData)

      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  getClientsByAccountantId: async (
    accountantId: string,
    isAdminUser: number,
    params: IGetListRequest
  ): Promise<IClientListPayload | IServerError | IResponse> => {
    if (accountantId !== "" && accountantId !== "0") {
      try {
        const response: AxiosResponse<IClientListPayload> =
          await http.get<IClientListPayload>(
            "/v1/accountants/" +
              accountantId +
              "/clients?order_by=" +
              params.order_by +
              "&order_direction=" +
              params.order_direction +
              "&page=" +
              params.page +
              "&size=" +
              params.size +
              "&search_query=" +
              params.search_query
          );
        console.log("response..", response);
        if (response.status === 200) {
          return response.data;
        } else if (response.status === 400) {
          return {
            status: false,
            message: "Bad Request.",
            error: true,
          };
        } else if (response.status === 500) {
          return {
            status: false,
            message: "Something went wrong.",
            error: true,
          };
        } else {
          console.log("Response status:", response.status);
          return response.data;
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.error("An error occurred:", axiosError);
        return {
          status: false,
          message: axiosError.response
            ? axiosError.response.data
            : "Unknown error",
        };
      }
    } else if ((isAdminUser === 0 && accountantId === "") || "0") {
      try {
        const response: AxiosResponse<IClientListPayload> =
          await http.get<IClientListPayload>("/v1/clients/");

        console.log("response..client", response);
        if (response.status === 200) {
          return response.data;
        } else if (response.status === 400) {
          return {
            status: false,
            message: "Bad Request.",
            error: true,
          };
        } else if (response.status === 500) {
          return {
            status: false,
            message: "Something went wrong.",
            error: true,
          };
        } else {
          console.log("Response status:", response.status);
          return response.data;
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.error("An error occurred:", axiosError);
        return {
          status: false,
          message: axiosError.response
            ? axiosError.response.data
            : "Unknown error",
        };
      }
    } else {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
  },

  getAccountantIdByClient: async (
    clientId: string
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/clients/accountant-id/${clientId}/`
    );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  delClient: async (
    clientId: number
  ): Promise<string | IServerError | IResponse> => {
    console.log("deleteById", clientId);
    const response: AxiosResponse<string> = await http.delete<string>(
      `/v1/clients/${clientId}/`
    );

    if (response.status === 204) {
      return { status: true, message: "Client has been deleted successfully" };
    } else if (response.status === 404) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  delBulkClients: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    console.log("requestPayload", typeof requestPayload);
    const response = await http.delete<string>(`/v1/clients/`, {
      data: requestPayload.clientIds,
    });
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    console.log("REsponse ?????????????????????????????????????", response);
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  delBulkAccountants: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    // console.log("requestPayload", typeof requestPayload);
    // const response = await http.delete<string>(
    //   `/v1/accountant/`,
    //   { data: requestPayload.accountantIds}
    // );
    // if (response.status === 200) {
    //   return response.data;
    // } else if (response.status === 500) {
    //   return {
    //     status: false,
    //     message: "Something went wrong.",
    //     error: true,
    //   };
    // }
    // console.log("REsponse ?????????????????????????????????????", response);
    // return {
    //   status: false,
    //   message: "Something went wrong please try again later.",
    // };

    return {
      status: false,
      message: "Accountant Bulk del API not created yet.",
    };
  },

  getAccountantList: async (params: IGetListRequest) => {
    console.log("#####", params);
    const response: AxiosResponse<IAccountantListPayload> =
      await http.get<IAccountantListPayload>("/v1/accountants/");
    if (response.status === 200) {
      for (var i = 0; i < response.data.total; i++) {
        const record = response.data.items[i];
        response.data.items[i].name = record.firstName + " " + record.lastName;
        response.data.items[i].city = record.address?.city;
        record.metaFields?.map(
          (field: { name: string | number; value: any }) => {
            response.data.items[i][field.name] = field.value;
          }
        );
      }
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getSetupLayout: async (): Promise<any> => {
    let response: any = ACCOUNTANT_SETUP_FORM_WITH_NULL_DEFAULT_VALUES;
    return new Promise((resolve) => setTimeout(() => resolve(response), 1000));
  },
  create: async (
    requestPayload: IFixedAndMetaField
  ): Promise<IAccountant | IServerError | IResponse | any> => {
    if (requestPayload.id) {
      console.log("requestPayload ==>> update request", requestPayload);
      const response: AxiosResponse<any> = await http.put<IAccountant>(
        `/v1/accountants/${requestPayload.id}/`,
        requestPayload
      );

      if (response.status === 200) {
        return response;
      }else if (response.status === 400) {
        return response.data;
      }else if (response.status === 404) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }

    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<any> = await http.post<IAccountant>(
      "/v1/accountants/",
      requestPayload
    );

    if (response.status === 201) {
      return response;
    }else if (response.status === 400) {
      return response.data;
    }else if (response.status === 404) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getById: async (
    id: string | undefined
  ): Promise<IAccountant | IServerError | IResponse | null | any> => {
    if (!id) return null;
    const response: AxiosResponse<IAccountant> = await http.get<IAccountant>(
      `/v1/accountants/${id}/`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getAccountantCategories: async (
    screenId: string | number
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse =
      await http.get(
        // '/v1/flex-fields/?search_query=category&order_by=name&order_direction=asc&page=1&size=50'
        `/v1/flex-fields/screen-fields?screen_name=${screenId}`
      );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };

    // const response: AxiosResponse<any> = await http.get<any>(
    //   `/v1`
    // );
    // if (response.status === 200) {
    //   return response.data;
    // } else if (response.status === 500) {
    //   return {
    //     status: false,
    //     message: "Something went wrong.",
    //     error: true,
    //   };
    // }
    // return {
    //   status: false,
    //   message: "Something went wrong please try again later.",
    // };
    // const fixedResponse: any = {
    //   page: 1,
    //   size: INITIAL_PAGE_SIZE,
    //   total: 2,
    //   items: [
    //     // {
    //     //   appFieldLabel: "country1",
    //     //   comparison: [],
    //     //   dataType: "string",
    //     //   defaultValue: "Canada",
    //     //   displayMasked: false,
    //     //   fieldColumn: 8,
    //     //   fieldFormat: null,
    //     //   fieldInternalValue: "canada",
    //     //   fieldLabel: "Country1",
    //     //   fieldType: "DropDownList",
    //     //   fieldValue: "Canada,Usa",
    //     //   id: 120,
    //     //   isCategory: true,
    //     //   isFixed: false,
    //     //   isHide: false,
    //     //   isRequired: false,
    //     //   labelColumn: 4,
    //     //   labelPosition: "left",
    //     //   maxValue: "0",
    //     //   minValue: "0",
    //     //   notes: null,
    //     //   parent: null,
    //     //   parentValue: null,
    //     //   parentRelation: null,
    //     //   placeholder: "Select Country",
    //     //   readOnly: false,
    //     //   uppercase: false,
    //     //   defualtCategory: false,
    //     //   // "defualtCategory":true
    //     // },
    //     {
    //       fieldLabel: "Province",
    //       fieldId: "provinceId",
    //       fieldType: FieldType.DropDownList,
    //       placeholder: "",
    //       fieldValue: "Alberta,Ontario",
    //       fieldInternalValue: "alberta,ontario",
    //       // fieldValue: "Alberta,British Columbia,Manitoba",
    //       // fieldInternalValue: "alberta,british columbia,manitoba",
    //       id: 11,
    //       dataType: DataType.Object,
    //       fieldFormat: "",
    //       defaultValue: "",
    //       labelPosition: "left",
    //       labelColumn: 4 as GridSize,
    //       fieldColumn: 8 as GridSize,
    //       notes: "",
    //       minValue: "",
    //       maxValue: "",
    //       parent: null,
    //       parentValue: "",
    //       parentRelation: null,
    //       isFixed: false,
    //       isRequired: false,
    //       isCategory: true,
    //       isHide: false,
    //       readOnly: false,
    //       displayMasked: false,
    //       uppercase: false,
    //       fieldClass: "",
    //       validationFormula: "",
    //       comparison: [],
    //       employeeAccess: 0,
    //       variableValue: null,
    //       systemField: false,
    //       apiPath: null,
    //       defualtCategory: false,
    //       sequence: 1,
    //     },
    //     // {
    //     //   fieldLabel: "Province of employment",
    //     //   fieldId: "provinceId",
    //     //   fieldType: FieldType.DropDownList,
    //     //   placeholder: "",
    //     //   fieldValue: "Alberta",
    //     //   fieldInternalValue: "alberta",
    //     //   // fieldValue: "Alberta,British Columbia,Manitoba",
    //     //   // fieldInternalValue: "alberta,british columbia,manitoba",
    //     //   id: 12,
    //     //   dataType: DataType.Object,
    //     //   fieldFormat: "",
    //     //   defaultValue: "alberta",
    //     //   labelPosition: "left",
    //     //   labelColumn: 4 as GridSize,
    //     //   fieldColumn: 8 as GridSize,
    //     //   notes: "",
    //     //   minValue: "",
    //     //   maxValue: "",
    //     //   parent: null,
    //     //   parentValue: "",
    //     //   parentRelation: null,
    //     //   isFixed: true,
    //     //   isRequired: true,
    //     //   isCategory: true,
    //     //   isHide: false,
    //     //   readOnly: false,
    //     //   displayMasked: false,
    //     //   uppercase: false,
    //     //   fieldClass: "",
    //     //   validationFormula: "",
    //     //   comparison: [],
    //     //   employeeAccess: 0,
    //     //   variableValue: null,
    //     //   systemField: false,
    //     //   apiPath: null,
    //     //   defualtCategory: true,
    //     //   sequence: 1,
    //     // },
    //     {
    //             "appFieldLabel": "mobile",
    //             "comparison": [],
    //             "dataType": "string",
    //             "defaultValue": "",
    //             "displayMasked": false,
    //             "fieldColumn": 8,
    //             "fieldFormat": null,
    //             "fieldInternalValue": "1,2,3",
    //             "fieldLabel": "Mobile",
    //             "fieldType": "DropDownList",
    //             "fieldValue": "apple,samsung,vivo",
    //             "id": 125,
    //             "isCategory": true,
    //             "isFixed": false,
    //             "isHide": false,
    //             "isRequired": false,
    //             "labelColumn": 4,
    //             "labelPosition": "left",
    //             "maxValue": "0",
    //             "minValue": "0",
    //             "notes": null,
    //             "parent": null,
    //             "parentValue": null,
    //             "parentRelation": null,
    //             "placeholder": null,
    //             "readOnly": false,
    //             "uppercase": false,
    //             defualtCategory: false,
    //           },
    //     // {
    //     //   id: 5,
    //     //   fieldLabel: "Country",
    //     //   fieldId: "countryId",
    //     //   fieldType: FieldType.DropDownList,
    //     //   placeholder: "",
    //     //   fieldValue: "Canada",
    //     //   fieldInternalValue: "1",
    //     //   dataType: DataType.Object,
    //     //   fieldFormat: "",
    //     //   defaultValue: "1",
    //     //   labelPosition: "left",
    //     //   labelColumn: 4 as GridSize,
    //     //   fieldColumn: 8 as GridSize,
    //     //   notes: "",
    //     //   minValue: "",
    //     //   maxValue: "",
    //     //   parent: null,
    //     //   parentValue: "",
    //     //   parentRelation: null,
    //     //   isFixed: true,
    //     //   isRequired: true,
    //     //   isCategory: true,
    //     //   isHide: false,
    //     //   readOnly: false,
    //     //   displayMasked: false,
    //     //   uppercase: false,
    //     //   fieldClass: "",
    //     //   validationFormula: "",
    //     //   comparison: [],
    //     //   employeeAccess: 0,
    //     //   variableValue: null,
    //     //   systemField: false,
    //     //   apiPath: null,
    //     //   defualtCategory: true,
    //     //   sequence: 3,
    //     // },
    //     // {
    //     //   id: 6,
    //     //   fieldLabel: "Country",
    //     //   fieldId: "countryId",
    //     //   fieldType: FieldType.DropDownList,
    //     //   placeholder: "",
    //     //   fieldValue: "Canada",
    //     //   fieldInternalValue: "1",
    //     //   dataType: DataType.Object,
    //     //   fieldFormat: "",
    //     //   defaultValue: "1",
    //     //   labelPosition: "left",
    //     //   labelColumn: 4 as GridSize,
    //     //   fieldColumn: 8 as GridSize,
    //     //   notes: "",
    //     //   minValue: "",
    //     //   maxValue: "",
    //     //   parent: null,
    //     //   parentValue: "",
    //     //   parentRelation: null,
    //     //   isFixed: true,
    //     //   isRequired: true,
    //     //   isCategory: true,
    //     //   isHide: false,
    //     //   readOnly: false,
    //     //   displayMasked: false,
    //     //   uppercase: false,
    //     //   fieldClass: "",
    //     //   validationFormula: "",
    //     //   comparison: [],
    //     //   employeeAccess: 0,
    //     //   variableValue: null,
    //     //   systemField: false,
    //     //   apiPath: null,
    //     //   defualtCategory: false,
    //     //   sequence: 3,
    //     // }
    //   ],
    //   keyField: {
    //     field: "id",
    //     templateUrl: "/data-roles/setup/id",
    //   },
    //   headerColumns: [
    //     {
    //       field: "roleName",
    //       headerName: "Data Role Name",
    //       description: "Data role name",
    //       flex: 1,
    //       hide: false,
    //       hideable: false,
    //       sortable: true,
    //       // "sortingOrder": "asc",
    //       resizable: false,
    //       type: "string",
    //       align: "left",
    //       headerAlign: "left",
    //       hideSortIcons: false,
    //       disableColumnMenu: true,
    //       disableReorder: true,
    //       disableExport: true,
    //       maskable: false,
    //       // "isFixed": true,
    //       sequence: 0,
    //     },
    //   ],
    // };
    // return new Promise((resolve) =>
    //   setTimeout(() => resolve(fixedResponse), 1000)
    // );
  },
  getAccountantCategoriesAssingedPanels: async (
    requestPayload: any
  ): Promise<any | IServerError | IResponse> => {
    console.log("requestPayload",requestPayload)
    const response: AxiosResponse =
      await http.post(
        `/v1/flex-field-fieldsets/panel-flex-fields`,
        requestPayload
      );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  // if(requestPayload[0].categoryValue === "alberta"){
  // const fixedResponse:any = [
  //   {
  //     "name": "Basic Info",
  //     "description": "Add basic information",
  //     "sequence": 1,
  //     "categoryFields": [],
  //     "panels": [
  //       {
  //         "label": "Umair",
  //         "categoryFieldId": "128",
  //         "categoryFieldValue": "Name Umair",
  //         "sequence": 1,
  //         "gridColumns": 12,
  //         "fields": [
  //           {
  //             "appFieldLabel": "NameUmair",
  //             "comparison": [],
  //             "dataType": "string",
  //             "defaultValue": null,
  //             "displayMasked": true,
  //             "fieldColumn": 6,
  //             "fieldFormat": "Test",
  //             "fieldInternalValue": null,
  //             "fieldLabel": "Name Umair",
  //             "fieldType": "TextField",
  //             "fieldValue": "1",
  //             "id": 128,
  //             "isCategory": true,
  //             "isFixed": true,
  //             "isHide": true,
  //             "isRequired": true,
  //             "labelColumn": 4,
  //             "labelPosition": "left",
  //             "maxValue": "50",
  //             "minValue": "2",
  //             "notes": "Testing for QA",
  //             "parent": null,
  //             "parentValue": null,
  //             "parentRelation": null,
  //             "placeholder": "Enter Name",
  //             "readOnly": true,
  //             "uppercase": true
  //           }
  //         ]
  //       }
  //     ]
  //   }
  // ]
    // console.log("requestPayload12",requestPayload)
    // // const response: AxiosResponse<any> = await http.get<any>(
    // //   `/v1`
    // // );
    // // if (response.status === 200) {
    // //   return response.data;
    // // } else if (response.status === 500) {
    // //   return {
    // //     status: false,
    // //     message: "Something went wrong.",
    // //     error: true,
    // //   };
    // // }
    // // return {
    // //   status: false,
    // //   message: "Something went wrong please try again later.",
    // console.log(
    //   `inside requestPayload = ${requestPayload},,,,, screenId ${requestPayload.screenId} , categoryId ${requestPayload.categoryId}, categoryValue ${requestPayload.categoryValue}`
    // );
    // // };
    // if (
    //   requestPayload[0].categoryId !== "" &&
    //   requestPayload[0].categoryValue === "alberta" //alberta
    // ) {
    //   const fixedResponse: any = {
    //     panels: [
    //       {
    //         label: "Create Client",
    //         categoryFieldId: "",
    //         categoryFieldValue: "",
    //         sequence: 1,
    //         gridColumns: 11 as GridSize,
    //         fields: [
    //           {
    //             "appFieldLabel": "mobile",
    //             "comparison": [],
    //             "dataType": "string",
    //             "defaultValue": "1",
    //             "displayMasked": false,
    //             "fieldColumn": 8,
    //             "fieldFormat": null,
    //             "fieldInternalValue": "1,2,3",
    //             "fieldLabel": "Mobile",
    //             "fieldType": "DropDownList",
    //             "fieldValue": "apple,samsung,vivo",
    //             "id": 125,
    //             "isCategory": true,
    //             "isFixed": false,
    //             "isHide": false,
    //             "isRequired": false,
    //             "labelColumn": 4,
    //             "labelPosition": "left",
    //             "maxValue": "0",
    //             "minValue": "0",
    //             "notes": null,
    //             "parent": null,
    //             "parentValue": null,
    //             "parentRelation": null,
    //             "placeholder": null,
    //             "readOnly": false,
    //             "uppercase": false
    //           },
    //           {
    //             id: 2,
    //             fieldLabel: "Legal Name",
    //             fieldId: "clientName",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Legal Name",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 2,
    //           },
    //           {
    //             id: 3,
    //             fieldLabel: "Operating Name",
    //             fieldId: "operatingName",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Operating Name",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 3,
    //           },
    //           {
    //             id: 4,
    //             fieldLabel: "Creation Date",
    //             fieldId: "createdOn",
    //             fieldType: FieldType.Date,
    //             placeholder: "Current Date",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 4,
    //           },
    //           {
    //             id: 5,
    //             fieldLabel: "Employees",
    //             fieldId: "employees",
    //             fieldType: FieldType.DropDownList,
    //             placeholder: "",
    //             fieldValue:
    //               "1-10,11-25,26-50,51-100,101-200,201-300,301-500,501-1000,1001-2500,2500+",
    //             fieldInternalValue: "1,11,26,51,101,201,301,501,1001,2501",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "26",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 5,
    //           },
    //           {
    //             id: 6,
    //             fieldLabel: "Account Manager",
    //             fieldId: "accountManager",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Account Manager",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 6,
    //           },
    //           {
    //             id: 7,
    //             fieldLabel: "Business no.",
    //             fieldId: "businessNo",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "999999999RP9999",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{000000000RP0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: true,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 7,
    //           },
    //           {
    //             id: 8,
    //             fieldLabel: "Reduce El Account no.",
    //             fieldId: "reducedBusinessNo",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "000000000AA0000",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{000000000AA0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: true,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 8,
    //           },
    //           {
    //             id: 9,
    //             fieldLabel: "Access Code",
    //             fieldId: "accessCode",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Access Code",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 9,
    //           },
    //           {
    //             id: 10,
    //             fieldLabel: "Payment Method",
    //             fieldId: "payment_method",
    //             fieldType: FieldType.DropDownList,
    //             placeholder: "",
    //             fieldValue: "paypal,Direct method",
    //             fieldInternalValue: "20,10",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 10,
    //           },
    //         ],
    //       },
    //       {
    //         label: "Address Info",
    //         categoryFieldId: "",
    //         categoryFieldValue: "",
    //         sequence: 2,
    //         gridColumns: 12 as GridSize,
    //         fields: [
    //           {
    //             id: 10,
    //             fieldLabel: "Address",
    //             fieldId: "addressline1",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Enter Address",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 1,
    //           },
    //           {
    //             id: 11,
    //             fieldLabel: "City",
    //             fieldId: "city",
    //             fieldType: FieldType.TextField,
    //             placeholder: "City",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 2,
    //           },
    //           {
    //             id: 12,
    //             fieldLabel: "Province",
    //             fieldId: "provinceId",
    //             fieldType: FieldType.DropDownList,
    //             placeholder: "",
    //             fieldValue: "Alberta,British Columbia,Manitoba",
    //             fieldInternalValue: "1,2,3",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 3,
    //           },
    //           {
    //             id: 12,
    //             fieldLabel: "Operating in Provinces",
    //             fieldId: "operatingInProvinces",
    //             fieldType: FieldType.MultiSelectionDropDownList,
    //             placeholder: "",
    //             fieldValue: "Alberta,British Columbia,Manitoba",
    //             fieldInternalValue: "1,2,3",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 4,
    //           },
    //           {
    //             id: 5,
    //             fieldLabel: "Country",
    //             fieldId: "countryId",
    //             fieldType: FieldType.DropDownList,
    //             placeholder: "",
    //             fieldValue: "Canada",
    //             fieldInternalValue: "1",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "1",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: true,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 5,
    //           },
    //           {
    //             id: 8,
    //             fieldLabel: "Post Code",
    //             fieldId: "postal_code",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "A9A9A9",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{a0a0a0}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: true,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 6,
    //           },
    //           {
    //             id: 4,
    //             fieldLabel: "Email",
    //             fieldId: "email",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Email",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 7,
    //           },
    //           {
    //             id: 10,
    //             fieldLabel: "Phone (Home)",
    //             fieldId: "phone1",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "(999) 999-9999",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{(000) 000-0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 8 as GridSize,
    //             fieldColumn: 8 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 8,
    //           },
    //           {
    //             id: 11,
    //             fieldLabel: "Ext",
    //             fieldId: "ext1",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "000",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 4 as GridSize,
    //             fieldColumn: 4 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 9,
    //           },
    //           {
    //             id: 10,
    //             fieldLabel: "Phone (Personal)",
    //             fieldId: "phone2",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "(999) 999-9999",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{(000) 000-0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 8 as GridSize,
    //             fieldColumn: 8 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 10,
    //           },
    //           {
    //             id: 11,
    //             fieldLabel: "Ext",
    //             fieldId: "ext2",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "000",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 4 as GridSize,
    //             fieldColumn: 4 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 11,
    //           },
    //           {
    //             id: 10,
    //             fieldLabel: "Fax",
    //             fieldId: "fax",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "(999) 999-9999",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{(000) 000-0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 12,
    //           },
    //           {
    //             id: 12,
    //             fieldLabel: "Website",
    //             fieldId: "website",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Website",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 13,
    //           },
    //         ],
    //       },
    //     ],
    //   };
    //   return new Promise((resolve) =>
    //     setTimeout(() => resolve(fixedResponse), 1000)
    //   );
    // } else if(requestPayload[0].categoryValue === "ontario"){
    //   const fixedResponse:any = 
    //   [
    //     {
    //       "name": "Basic Info",
    //       "description": "Add basic information",
    //       "sequence": 1,
    //       "categoryFields": [],
    //       "panels": [
    //         {
    //           "label": "22 roza1",
    //           "categoryFieldId": "355",
    //           "categoryFieldValue": "NameUmair",
    //           "sequence": 1,
    //           "gridColumns": 12,
    //           "fields": [
    //             // {
    //             //   "appFieldLabel": "NameUmair",
    //             //   "comparison": [],
    //             //   "dataType": "string",
    //             //   "defaultValue": null,
    //             //   "displayMasked": false,
    //             //   "fieldColumn": 6,
    //             //   "fieldFormat": "Test",
    //             //   "fieldInternalValue": null,
    //             //   "fieldLabel": "Name Umair",
    //             //   "fieldType": "TextField",
    //             //   "fieldValue": "1",
    //             //   "id": 354,
    //             //   "isCategory": false,
    //             //   "isFixed": true,
    //             //   "isHide": false,
    //             //   "isRequired": false,
    //             //   "labelColumn": 4,
    //             //   "labelPosition": "left",
    //             //   "maxValue": "50",
    //             //   "minValue": "2",
    //             //   "notes": "Testing for QA",
    //             //   "parent": null,
    //             //   "parentValue": null,
    //             //   "parentRelation": null,
    //             //   "placeholder": null,
    //             //   "readOnly": false,
    //             //   "uppercase": false,
    //             //   "defaultCategory": null
    //             // },
    //             {
    //               id: 354,
    //               fieldLabel: "Name Umair",
    //               "appFieldLabel": "NameUmair",
    //               fieldId: "NameUmair",
    //               fieldType: FieldType.TextField,
    //               placeholder: "Name Umair",
    //               fieldValue: "",
    //               fieldInternalValue: "",
    //               dataType: DataType.String,
    //               fieldFormat: "TextField",
    //               defaultValue: null,
    //               labelPosition: "left",
    //               labelColumn: 4 as GridSize,
    //               fieldColumn: 8 as GridSize,
    //               notes: "",
    //               minValue: "",
    //               maxValue: "",
    //               parent: null,
    //               parentValue: "",
    //               parentRelation: null,
    //               isFixed: false,
    //               isRequired: false,
    //               isCategory: false,
    //               isHide: false,
    //               readOnly: false,
    //               displayMasked: false,
    //               uppercase: false,
      
    //               fieldClass: "",
    //               validationFormula: "",
    //               employeeAccess: 0,
    //               variableValue: null,
    //               systemField: false,
    //               comparison: [],
    //               sequence: 1,
    //             },
    //             {
    //               "appFieldLabel": "setup",
    //               "comparison": [],
    //               "dataType": "string",
    //               "defaultValue": "1",
    //               "displayMasked": false,
    //               "fieldColumn": 8,
    //               "fieldFormat": null,
    //               "fieldInternalValue": "1,2,3",
    //               "fieldLabel": "Setup",
    //               "fieldType": "DropDownList",
    //               "fieldValue": "1,2,3",
    //               "id": 355,
    //               "isCategory": true,
    //               "isFixed": true,
    //               "isHide": false,
    //               "isRequired": false,
    //               "labelColumn": 4,
    //               "labelPosition": "left",
    //               "maxValue": "",
    //               "minValue": "",
    //               "notes": null,
    //               // "parent": null,
    //               // "parentValue": null,
    //               // "parentRelation": null,
    //               "placeholder": null,
    //               "readOnly": false,
    //               "uppercase": false,
    //               "defaultCategory": null
    //             }
    //           ]
    //         }
    //       ]
    //     },
    //     // {
    //     //   "name": "Address",
    //     //   "description": "Enter address of client",
    //     //   "sequence": 2,
    //     //   "categoryFields": [],
    //     //   "panels": [
    //     //     {
    //     //       "label": "new_mouse",
    //     //       "categoryFieldId": "355",
    //     //       "categoryFieldValue": "NameUmair",
    //     //       "sequence": 2,
    //     //       "gridColumns": 12,
    //     //       "fields": [
    //     //         {
    //     //           "appFieldLabel": "setup",
    //     //           "comparison": [],
    //     //           "dataType": "string",
    //     //           "defaultValue": "1",
    //     //           "displayMasked": false,
    //     //           "fieldColumn": 8,
    //     //           "fieldFormat": null,
    //     //           "fieldInternalValue": "1,2,3",
    //     //           "fieldLabel": "Yify",
    //     //           "fieldType": "DropDownList",
    //     //           "fieldValue": "1,2,3",
    //     //           "id": 356,
    //     //           "isCategory": true,
    //     //           "isFixed": false,
    //     //           "isHide": false,
    //     //           "isRequired": false,
    //     //           "labelColumn": 4,
    //     //           "labelPosition": "left",
    //     //           "maxValue": "",
    //     //           "minValue": "",
    //     //           "notes": null,
    //     //           "parent": null,
    //     //           "parentValue": null,
    //     //           "parentRelation": null,
    //     //           "placeholder": null,
    //     //           "readOnly": false,
    //     //           "uppercase": false,
    //     //           "defaultCategory": null
    //     //         }
    //     //       ]
    //     //     }
    //     //   ]
    //     // },
    //     // {
    //     //   "name": "Address",
    //     //   "description": "Enter address of client",
    //     //   "sequence": 2,
    //     //   "categoryFields": [],
    //     //   "panels": [
    //     //     {
    //     //       "label": "new_mouse",
    //     //       "categoryFieldId": "355",
    //     //       "categoryFieldValue": "NameUmair",
    //     //       "sequence": 3,
    //     //       "gridColumns": 12,
    //     //       "fields": [
    //     //         {
    //     //           "appFieldLabel": "setup",
    //     //           "comparison": [],
    //     //           "dataType": "string",
    //     //           "defaultValue": "1",
    //     //           "displayMasked": false,
    //     //           "fieldColumn": 8,
    //     //           "fieldFormat": null,
    //     //           "fieldInternalValue": "1,2,3",
    //     //           "fieldLabel": "Yify",
    //     //           "fieldType": "DropDownList",
    //     //           "fieldValue": "1,2,3",
    //     //           "id": 355,
    //     //           "isCategory": true,
    //     //           "isFixed": false,
    //     //           "isHide": false,
    //     //           "isRequired": false,
    //     //           "labelColumn": 4,
    //     //           "labelPosition": "left",
    //     //           "maxValue": "",
    //     //           "minValue": "",
    //     //           "notes": null,
    //     //           "parent": null,
    //     //           "parentValue": null,
    //     //           "parentRelation": null,
    //     //           "placeholder": null,
    //     //           "readOnly": false,
    //     //           "uppercase": false,
    //     //           "defaultCategory": null
    //     //         }
    //     //       ]
    //     //     }
    //     //   ]
    //     // }
    //   ]
    //   return new Promise((resolve) =>
    //     setTimeout(() => resolve(fixedResponse), 1000)
    //   );
    // }
  },
  moveClientsByAccountantId: async (
    requestPayload: any
  ): Promise<IServerError | IResponse | any> => {
    try {
      const response: AxiosResponse<IAccountant> = await http.post<IAccountant>(
        "/v1/clients/update-parent/",
        requestPayload
      );

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      } else {
        console.log("Response status:", response.status);
        return response.data;
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error("An error occurred:", axiosError);
      return {
        status: false,
        message: axiosError.response
          ? axiosError.response.data
          : "Unknown error",
      };
    }
  },
};
