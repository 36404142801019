import { IKeyField } from "models/common/IKeyField";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Delete, EditOutlined, Settings } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  TextField,
  styled,
} from "@mui/material";
import commonColors from "@mui/material/colors/common";
import LinearProgress from "@mui/material/LinearProgress";
import {} from "@mui/material";
// import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumns,
  GridCsvExportOptions,
  GridFeatureMode,
  GridFilterModel,
  GridOverlay,
  GridRenderCellParams,
  GridRowIdGetter,
  GridRowModel,
  GridRowParams,
  GridSelectionModel,
  GridSortDirection,
  GridSortModel,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { StyledRouterLink } from "@websential/cosmic";

import useDebounce from "../../hooks/useDebounce";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

// import { ITest } from '../../interfaces/';

// interface StyleProps {
//   stickyHeader: boolean;
// }

const LinearLoadingOverlay = () => {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};
const stickyHeader = false;
const StyledDataGridContainer = styled("div")(({ theme }) => ({
  // backgroundColor: '#ff0000',
  // height: "inherit",
  "& .MuiDataGrid-columnHeader": {
    height: 56,
    // height: "inherit",
    zIndex: () => (stickyHeader ? theme.zIndex.snackbar : "initial"),
    position: () => (stickyHeader ? "sticky" : "absolute"),
    background: commonColors.white,
    // backgroundColor: '#ff0000',
  },
  "& .MuiDataGrid-columnHeaderCheckbox": {
    zIndex: () => (stickyHeader ? theme.zIndex.snackbar : "initial"),
    position: () => (stickyHeader ? "sticky" : "absolute"),
    background: commonColors.white,
    // backgroundColor: '#ff0000',
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
  "& .MuiDataGrid-windowContainer": {
    background: "#fff",
  },
  "& .MuiCircularProgress-indeterminate": {
    zIndex: theme.zIndex.snackbar,
  },
  "& .MuiDataGrid-overlay": {
    backgroundColor: "rgb(127 108 108 / 38%)",
  },
  "& .MuiDataGrid-columnHeaderWrapper": {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  "& .MuiDataGrid-cell": {
    backgroundColor: commonColors.white,
    //   borderRight: "1px solid #dee2e6"
  },
  // "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
  //   backgroundColor: commonColors.white,
  //   borderRight: "1px solid #dee2e6"
  // },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
}));

// const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => {
//   return {
//     root: {
//       height: "inherit",
//       "& .MuiDataGrid-columnsContainer": {
//         zIndex: ({ stickyHeader }) =>
//           stickyHeader ? theme.zIndex.snackbar : "initial",
//         position: ({ stickyHeader }) => (stickyHeader ? "sticky" : "absolute"),
//         background: commonColors.white,
//       },
//       "& .MuiDataGrid-columnHeaderTitle": {
//         fontWeight: "bold",
//       },
//       "& .MuiDataGrid-windowContainer": {
//         background: "#fff",
//       },
//       "& .MuiCircularProgress-indeterminate": {
//         zIndex: theme.zIndex.snackbar,
//       },
//       "& .MuiDataGrid-overlay": {
//         backgroundColor: "rgb(127 108 108 / 38%)",
//       },
//       "& .MuiDataGrid-columnHeaderWrapper": {
//         borderBottom: "1px solid rgba(224, 224, 224, 1)",
//       },
//     },
//     loadingRoot: {
//       pointerEvents: "none",
//     },
//     customToolbarContainer: {
//       display: "flex",
//       justifyContent: "space-between",
//       padding: "6px 0",
//     },
//     searchInput: {
//       width: "40%",
//       display: "flex",
//       alignItems: "center",
//     },
//   };
// });

// interface Response {
//   data: GridRowData[]
// }
export interface RequestParams {
  pageSize: number;
  debouncedSearchQuery: string;
  currentPage: number;
  sort?: string;
  filter?: string;
}

export interface EnhancedGridColDef extends GridColDef {
  maskable?: boolean;
  displayMode?: "Normal" | "Chip";
  chipColors?: [];
}

export type EnhancedGridColumns = EnhancedGridColDef[];

export interface IEnhancedDataGrid {
  testId: string;
  /**
   * Set rows to set the link able.
   */
  keyField?: IKeyField;
  /**
   * Set rows to set the link able.
   */
  onKeyFieldClickHandler?: (clickedRow: any) => void;
  /**
   * Set rows to set the data of the grid.
   */
  rows?: GridRowModel[];
  /**
   * Set columns to set the columns of the grid.
   */
  columns: EnhancedGridColumns;
  /**
   * Set selectionEnabled flag to false to not make the rows selectable.
   */
  selectionEnabled?: boolean | undefined;
  /**
   * Set autoHeight flag to true to make the grid height dynamic and follow the number of rows in the grid.
   */
  autoHeight?: boolean | undefined;
  /**
   * Set pageSize to set the number of rows in one page.
   */
  initialPageSize?: number | undefined;
  /**
   * Set page to set the current page's number when making the component controlled.
   */
  initialPage?: number;
  /**
   * Set rowHeight to set the height of the rows in pixels.
   */
  rowHeight?: number | undefined;
  /**
   * Set hideFooter to hide the footer component.
   */
  hideFooter?: boolean | undefined;
  /**
   * Set loading to true to show a loading overlay.
   */
  isLoading?: boolean | undefined;
  /**
   * Set disableColumnMenu to true to disable showing the columns' menus.
   */
  disableColumnMenu?: boolean | undefined;
  /**
   * Set showDefaultToolbar to true to enable showing the default toolbar.
   */
  showDefaultToolbar?: boolean | undefined;
  /**
   * Set disableSelectionOnClick to true to disable the selection on click on a row or cell.
   */
  disableSelectionOnClick?: boolean | undefined;
  /**
   * Set disableColumnFilter to true to disable column filters.
   * @default false
   */
  disableColumnFilter?: boolean;
  /**
   * A custom footer component e.g. {@code ()=><p>Custom footer</p>}.
   */
  customFooter?: React.JSXElementConstructor<any>;
  /**
   * A custom toolbar component e.g. {@code ()=><p>Custom toolbar</p>}.
   */
  customToolbar?: React.JSXElementConstructor<any>;
  /**
   * A custom header component e.g. {@code ()=><p>Custom header</p>}.
   */
  customHeader?: React.JSXElementConstructor<any>;
  /**
   * A function that allows the grid to retrieve the row id (only used when we have the unique identifier of each row named differently than 'id').
   */
  rowIdGetter?: GridRowIdGetter;
  /**
   * A function that determines if a row can be selected.
   * @param {GridRowParams} params With all properties from [[GridRowParams]].
   * @param {MuiCallbackDetails} details Additional details for this callback.
   */
  isRowSelectable?: (params: GridRowParams, details?: any) => boolean;
  /**
   * A function that determines if a cell can be edited.
   * @param {GridCellParams} params With all properties from [[GridCellParams]].
   * @param {MuiCallbackDetails} details Additional details for this callback.
   */
  isCellEditable?: (params: GridCellParams, details?: any) => boolean;
  /**
   * Sorting can be processed on the server or client-side.
   * Set it to 'client' if you would like to handle sorting on the client-side.
   * Set it to 'server' if you would like to handle sorting on the server-side.
   */
  sortingMode?: GridFeatureMode;
  /**
   * Filtering can be processed on the server or client-side.
   * Set it to 'server' if you would like to handle filtering on the server-side.
   */
  filterMode?: GridFeatureMode;
  /**
   * Pagination can be processed on the server or client-side.
   * Set it to 'client' if you would like to handle the pagination on the client-side.
   * Set it to 'server' if you would like to handle the pagination on the server-side.
   */
  paginationMode?: GridFeatureMode;
  /**
   * Select the pageSize dynamically using the component UI.
   */
  rowsPerPageOptions?: number[];
  /**
   * If `true`, the pageSize is calculated according to the container size and the max number of rows to avoid rendering a vertical scroll bar.
   */
  autoPageSize?: boolean;
  /**
   * If `true`, the exporting toolbar will show.
   */
  allowExporting?: boolean;
  /**
   * If `true`, the row count in the footer is hidden.
   */
  hideFooterRowCount?: boolean;
  /**
   * If `true`, the selected row count in the footer is hidden.
   */
  hideFooterSelectedRowCount?: boolean;
  /**
   * If `true`, the header will be sticky.
   */
  stickyHeader?: boolean;
  /**
   * If `true`, we will render a search input.
   */
  allowSearching?: boolean;
  /**
   * A render prop (@see https://reactjs.org/docs/render-props.html) that will be
   * called when selectionEnabled = true and that will give the parent component
   * the power to render any component based on the value of the selectedItems.
   * NOTE: this render prop should be memoized using useCallback.
   */
  selectionToolbar?: (selectedItems: GridSelectionModel) => React.ReactNode;
  selectedRowsValues?:  any;
  /**
   * The csv export options that can be used to over-ride the default exporting options.
   */
  exportOptions?: GridCsvExportOptions;
  /**
   * The number milliseconds we should debounce the user's search query before sending it to BE.
   */
  searchDebouncingDelay?: number;
  /**
   * A callback when one of the request params changes e.g. the pageSize or the searchQuery.
   * NOTE: this render prop should be memoized.
   * when doing serverSide data fetching, paginationMode should be set to 'server' along with sortingMode, filterMode if any of them is enabled.
   */
  onRequestParamsChange?: (params: RequestParams) => void;
  /**
   * If `true`, masking will apply to maskable columns.
   */
  shouldMask?: boolean;
  /**
   * If `true`, will append an Index column to the beginning of the data grid.
   */
  showIndexColumn?: boolean;
  /**
   * A function that will mask a text value.
   * NOTE: this value should be memoized using useCallback.
   */
  valueMasker?: (text: string) => string;
  /**
   * If `true`, will append a column to the end of the data grid that supports actions for each row.
   * NOTE: onRowEditClicked and onRowDeleteClicked have to be passed if it's set to true.
   */
  showRowActionsColumn?: boolean;
  /**
   * If `true`, we will show a linear progress loading indicator instead of a circular one.
   */
  showLinearLoadingOverlay?: boolean;
  /**
   * A function that will be invoked when the edit row icon is clicked.
   * NOTE: this value should be memoized using useCallback.
   */
  onRowEditClicked?: (params: GridRenderCellParams) => void;
  /**
   * A function that will be invoked when the delete row icon is clicked.
   */
  onRowDeleteClicked?: (params: GridRenderCellParams) => void;
  /**
   * Set the total number of rows, if it is different than the length of the value `rows` prop.
   * This should be used with server side mode to indicate how many rows there are to be used
   * for pagination.
   */
  totalRowsCount?: number;
  /**
   * If true an error message will show. This should only be set to true when something goes wrong
   * with the BE API.
   */
  hasError?: boolean;
  /**
   * The order of the sorting sequence.
   * @default ['asc', 'desc', null]
   */
  sortingOrder?: GridSortDirection[];

  onSearchButtonClicked?:React.MouseEventHandler<HTMLButtonElement>;

  searchFieldPlaceholder?:string;
  
  onSearchCrossClicked?:(params: any) => void;
}

/**
 * A default masker that will mask a text.
 * e.g. will convert "test@gmail.com" to "t*************".
 *
 * @param {string} value
 * @returns {string}
 */
const defaultValueMasker = (value: string): string => {
  if (value && value.length > 0) {
    return value.replace(
      /^(.)(.*)(.*)$/,
      (_, a, b, c) => a + b.replace(/./g, "*") + c
    );
  }

  return value;
};

/**
 * This is a function that manually calculates the order of a row based on the current page + pageSize.
 *
 * @param {number} rowsArrayIndex the index of the row in the array that is currently being shown. e.g. even if we are in page number 5, the index of the first row on that page will be 0 since it's returned from BE.
 * @param {number} currentPage
 * @param {number} pageSize
 * @returns {number}
 */
const getRowIndex = (
  rowsArrayIndex: number,
  currentPage: number,
  pageSize: number
): number => currentPage * pageSize + (rowsArrayIndex + 1);

/**
 * this will append a field called index after calculating its value.
 * @param {GridRowModel[]} rows
 * @param {number} currentPage
 * @param {number} pageSize
 * @returns {GridRowModel[]}
 */
const getIndexedRows = (
  rows: GridRowModel[],
  currentPage: number,
  pageSize: number
): GridRowModel[] => {
  const rowsLength = rows.length;
  const shouldCalculateIndex = rowsLength <= pageSize; // when it's not client side where the rows can exceed the number of rows per page.
  return rows.map((row, index) => ({
    ...row,
    index: shouldCalculateIndex
      ? getRowIndex(index, currentPage, pageSize)
      : index + 1,
  }));
};

const INDEX_COLUMN = {
  field: "index",
  headerName: "No.",
  maxWidth: 60,
  renderCell: (params) => params.row.index,
  sortable: false,
  filterable: false,
  flex: 0,
} as GridColDef;

const getEditDeleteColumn = (
  onRowEditClicked?: (params: GridRenderCellParams) => void,
  onRowDeleteClicked?: (params: GridRenderCellParams) => void
) =>
  ({
    field: "actions",
    renderHeader: () => <Settings data-testid="settings-icon" />,
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridRenderCellParams) => {
      if (onRowEditClicked && onRowDeleteClicked) {
        const style = { minWidth: "unset", margin: "0px 2px" };
        return (
          <div>
            <Button
              style={style}
              size="small"
              onClick={() => onRowEditClicked(params)}
            >
              <EditOutlined data-testid="edit-icon" fontSize="small" />
            </Button>
            <Button
              style={style}
              size="small"
              onClick={() => onRowDeleteClicked(params)}
            >
              <Delete data-testid="delete-icon" fontSize="small" />
            </Button>
          </div>
        );
      }
      return <></>;
    },
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  } as GridColDef);

/**
 * A function that will append the index column if we are meant to show it to the existing columns,
 * otherwise it will just return the original columns.
 *
 * @param {GridColDef[]} columns
 * @param {boolean} showIndexColumn
 * @param {boolean} showRowActionsColumn
 * @returns {GridColDef[]}
 */
const getEnhancedColumns = (
  isLoading: boolean,
  columns: GridColDef[],
  showIndexColumn: boolean,
  showRowActionsColumn: boolean,
  onRowEditClicked?: (params: GridRenderCellParams) => void,
  onRowDeleteClicked?: (params: GridRenderCellParams) => void
): GridColDef[] => {
  if (isLoading) {
    return columns;
  }
  // if we are not meant to show the index column and the row actions column, just return the original columns
  if (!showRowActionsColumn && !showIndexColumn) {
    return columns;
  }
  // if we need to show both
  if (showRowActionsColumn && showIndexColumn) {
    return [
      ...[INDEX_COLUMN],
      ...columns,
      ...[getEditDeleteColumn(onRowEditClicked, onRowDeleteClicked)],
    ];
  }
  // if we need to either show the index column or the row actions column
  return showIndexColumn
    ? [...[INDEX_COLUMN], ...columns]
    : [
        ...columns,
        ...[getEditDeleteColumn(onRowEditClicked, onRowDeleteClicked)],
      ];
};

export const EnhancedDataGrid: React.FC<IEnhancedDataGrid> = ({
  keyField,
  onKeyFieldClickHandler,
  rows = [],
  columns,
  selectionEnabled = true,
  autoHeight = true,
  hideFooter = false,
  isLoading = false,
  rowHeight = 52,
  disableColumnMenu = false,
  showDefaultToolbar = false,
  hideFooterRowCount = false,
  hideFooterSelectedRowCount = false,
  disableSelectionOnClick = true,
  disableColumnFilter = true,
  autoPageSize = false,
  allowSearching = false,
  onSearchButtonClicked,
  searchFieldPlaceholder,
  initialPageSize = 10,
  initialPage = 0,
  hasError = false,
  customFooter,
  customHeader,
  customToolbar,
  rowIdGetter,
  isRowSelectable,
  isCellEditable,
  selectionToolbar,
  selectedRowsValues,
  valueMasker = defaultValueMasker,
  shouldMask = false,
  sortingMode = "server",
  filterMode = "server",
  paginationMode = "server",
  rowsPerPageOptions,
  allowExporting = false,
  stickyHeader = false,
  totalRowsCount,
  exportOptions,
  testId,
  searchDebouncingDelay = 50,
  showIndexColumn = false,
  showLinearLoadingOverlay = false,
  showRowActionsColumn = false,
  onRequestParamsChange,
  onSearchCrossClicked,
  onRowEditClicked,
  onRowDeleteClicked,
  sortingOrder,
}) => {
  // const classes = useStyles({ stickyHeader });
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<GridSelectionModel>([]);
  const debouncedSearchQuery = useDebounce<string>(
    searchQuery,
    searchDebouncingDelay
  );
  const isInitialRequest = useRef(true);
  const [requestParams, setRequestParams] = useState<RequestParams>({
    pageSize: initialPageSize,
    debouncedSearchQuery: "",
    currentPage: initialPage,
  });

  const onSelectionModelChange = useCallback((items: any) => {
    // console.log("itemmmmmm3",items)
    // console.log("itemmmmmm3",items.length)
    setSelectedItems(items);
    if(selectedRowsValues){
      if (items.length > 0) {
        const selectedRowData = rows.filter((row) => items.includes(row.id));
        console.log('Selected Row Data: in', selectedRowData);
        selectedRowsValues(selectedRowData)
      } else {
        selectedRowsValues(items)
        // console.log('No row selected');
      }
    }
  }, [rows,selectedRowsValues]);

  console.log("selectedItems---",selectedItems)

  const enhancedRows = useMemo(
    () =>
      showIndexColumn ? getIndexedRows(rows, currentPage, pageSize) : rows,
    [currentPage, pageSize, rows, showIndexColumn]
  );
  const enhancedColumns = useMemo(() => {
    // return the original columns if we shouldn't mask or there are no columns to be masked.
    // if (!shouldMask) {
    //   return getEnhancedColumns(
    //     columns,
    //     showIndexColumn,
    //     showRowActionsColumn,
    //     onRowEditClicked,
    //     onRowDeleteClicked
    //   );
    // }

    const newColumns = [...columns];
    newColumns.forEach((column, index) => {
      const newColumn = { ...column };

      // if (column.displayMode === "Chip") {
      //   newColumn.renderCell = (params) => {
      //     console.log(params.row)
      //     return (<Chip label="Chip Filled" />)
      //   }
      //   newColumns[index] = newColumn;
      // }
      // if the current column is maskable
      if (column.maskable) {
        // if no custom renderCell function has been added to that column, we add our own renderer that masks the column value.
        if (!newColumn.renderCell) {
          newColumn.renderCell = (params) =>
            valueMasker(params.row[column.field]); // render a masked value instead of the actual value
          newColumn.disableExport = true; // don't allow exporting it if it's masked
          newColumns[index] = newColumn;
        }
      }

      if (keyField && newColumn.field === keyField.field) {
        newColumn.renderCell = (params) => {
          return (
            <StyledRouterLink
              to={keyField.templateUrl.replace("{id}", params.row.id)}
              onClick={(event: any) =>
                onKeyFieldClickHandler && onKeyFieldClickHandler(params.row)
              }
            >
              {params.row[keyField.field]}
            </StyledRouterLink>
          );
        };

        newColumns[index] = newColumn;
      }
    });

    return getEnhancedColumns(
      isLoading,
      newColumns,
      showIndexColumn,
      showRowActionsColumn,
      onRowEditClicked,
      onRowDeleteClicked
    );
  }, [
    keyField,
    columns,
    // shouldMask,
    valueMasker,
    showIndexColumn,
    showRowActionsColumn,
    onRowEditClicked,
    onRowDeleteClicked,
  ]) as GridColumns;

  let toolbar;
  if (showDefaultToolbar || customToolbar) {
    toolbar = customToolbar || GridToolbar;
  } else if (allowExporting) {
    toolbar = () => (
      <GridToolbarContainer>
        <GridToolbarExport
          {...(exportOptions && { csvOptions: exportOptions })}
        />
      </GridToolbarContainer>
    );
  }

  // listen to any changes in debouncedSearchQuery
  useEffect(() => {
    if (isInitialRequest.current !== true) {
      console.log(
        "##### => useEffect - debouncedSearchQuery",
        debouncedSearchQuery
      );
      setRequestParams((prevRequestParams) => ({
        ...prevRequestParams,
        debouncedSearchQuery,
      }));
    }
  }, [debouncedSearchQuery]);

  // This useEffect is triggered when the debouncedSearchQuery, pageSize or currentPage values change
  useEffect(() => {
    // if the results fetcher was passed, we can use it to fetch the results.
    if (onRequestParamsChange) {
      // skip the first initial call
      if (isInitialRequest.current === true) {
        console.log(
          "##### => useEffect - requestParams - no first call",
          requestParams
        );
        isInitialRequest.current = false;
      } else {
        console.log(
          "##### => useEffect - requestParams - second call",
          requestParams
        );
        onRequestParamsChange(requestParams);
      }
      if(onSearchCrossClicked){
        onSearchCrossClicked(true)
      }
    }
  }, [onRequestParamsChange, requestParams]);

  const handleOnSearchInputChange = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const [pageChange, setPageChange] = useState(false)
  const onPageChange = (page: number) => {
    if(page > 0){
    console.log("##### => onPageChange", page);
    setCurrentPage(page);
    setRequestParams((prevRequestParams) => ({
      ...prevRequestParams,
      currentPage: page,
    }));
    setPageChange(true)
  }else if(page === 0 && currentPage === 1){
    console.log("##### => onPageChange", page);
    setCurrentPage(page);
    setRequestParams((prevRequestParams) => ({
      ...prevRequestParams,
      currentPage: page,
    }));
  }
  console.log("##### => currentPage out", currentPage);
  console.log("##### => onPageChange out", page);
  }

  const onPageSizeChange = useCallback(
    (pageSizeParam: number) => {
      console.log("##### => onPageSizeChange", pageSizeParam, totalRowsCount);
      console.log(
        "@@@@@ => ",
        totalRowsCount && totalRowsCount >= pageSizeParam
      );
      console.log(
        "@@@@@ => ",
        totalRowsCount &&
          pageSize < totalRowsCount &&
          pageSizeParam >= totalRowsCount
      );
      // we should trigger the callback only when the totalRowsCount is larger than the current pageSize
      // BUG: Need to fix bug when pageSize is greater than totalrecords but previously selected pageSize is less than total records
      if (
        totalRowsCount &&
        (totalRowsCount >= pageSizeParam ||
          (pageSize < totalRowsCount && pageSizeParam >= totalRowsCount))
      ) {
        // if (totalRowsCount && totalRowsCount >= pageSizeParam) {
        console.log("##### => onPageSizeChange -> calling setRequestParams");
        setRequestParams((prevRequestParams) => ({
          ...prevRequestParams,
          pageSize: pageSizeParam,
        }));
      }

      setPageSize(pageSizeParam);
    },
    [totalRowsCount]
  );

  const onSortModelChange = useCallback((model: GridSortModel) => {
    // if we just clicked un-sort
    if (!model.length) {
      // reset the data
      setRequestParams((prevRequestParams) => {
        const newRequestParams = { ...prevRequestParams };
        delete newRequestParams.sort;
        return newRequestParams;
      });
    } else {
      const [sortingColumn] = model;
      const { sort, field } = sortingColumn;
      // e.g. "sort=id,desc"
      setRequestParams((prevRequestParams) => ({
        ...prevRequestParams,
        sort: `${field},${sort}`,
      }));
    }
  }, []);
  const onFilterModelChange = useCallback((model: GridFilterModel) => {
    const { items } = model;
    // if we just cleared the filter
    if (!items.length) {
      // reset the data
      setRequestParams((prevRequestParams) => {
        const newRequestParams = { ...prevRequestParams };
        delete newRequestParams.filter;
        return newRequestParams;
      });
    } else {
      const [filteringItem] = items;
      const { value, operatorValue, columnField } = filteringItem;
      if (operatorValue === "isEmpty" || operatorValue === "isNotEmpty") {
        // e.g. id,
        setRequestParams((prevRequestParams) => ({
          ...prevRequestParams,
          filter: `${columnField},${operatorValue}`,
        }));
      } else {
        // if we just input the filter's value
        if (typeof value !== "undefined") {
          // if we just cleared the value
          if (value === "") {
            // reset the data
            setRequestParams((prevRequestParams) => {
              const newRequestParams = { ...prevRequestParams };
              delete newRequestParams.filter;
              return newRequestParams;
            });
          } else {
            // e.g. "id,equals,test" or "id,isEmpty"
            setRequestParams((prevRequestParams) => ({
              ...prevRequestParams,
              filter: `${columnField},${operatorValue},${value}`,
            }));
          }
        }
      }
    }
  }, []);

  const CustomNoRowsOverlay = () => {
    return <div style={{
      textAlign:"center",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      fontSize:"1.1rem",
      background:"#BDBDBD",
      height:"104px",
    }}>No record found</div>;
  };

  return (
    <StyledDataGridContainer
      sx={{ pointerEvents: isLoading ? "none" : "auto" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "6px 0",
        }}
      >
        {allowSearching && (
          <div
            style={{
              width: "35%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              margin="dense"
              id="search-data-grid"
              value={searchQuery}
              type="text"
              onChange={handleOnSearchInputChange}
              placeholder={searchFieldPlaceholder ? searchFieldPlaceholder : "Search..."}
              data-testid={`${testId}-search-field`}
              {...(searchQuery && {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        data-testid={`${testId}-search-clear-button`}
                        style={{ minWidth: "unset", margin: "0px 2px" }}
                        size="small"
                        onClick={() => setSearchQuery("")}
                        variant="contained"
                        color="warning"
                        sx={{
                          padding:"8px 10px",
                          marginRight:"auto!important",
                          borderRadius:"5px 0px 0px 5px"
                        }}
                      >
                        <ClearIcon/>
                      </Button>
                      <Button
                        onClick={onSearchButtonClicked}
                        style={{ minWidth: "unset" }}
                        size="small"
                        variant="contained"
                        sx={{
                          padding:"8px 10px",
                          marginRight:"-14px!important",
                          borderRadius:"0px 5px 5px 0px"}}
                      >
                        <SearchIcon />
                      </Button>
                    </InputAdornment>
                  ),
                },
              })}
            />
            {/* <div>
              <Button variant="outlined" startIcon={<SearchIcon />} onClick={onSearchButtonClicked}>
                Search
              </Button>
            </div> */}
          </div>
        )}
        {selectionToolbar && selectionToolbar(selectedItems)}
      </div>
      <div style={autoHeight ? {} : { width: "100%", height: "75vh" }}>
        <DataGrid
          showColumnRightBorder={true}
          showCellRightBorder={true}
          data-testid={testId}
          rows={enhancedRows}
          columns={enhancedColumns}
          pageSize={pageSize}
          autoPageSize={autoPageSize}
          checkboxSelection={isLoading ? false : selectionEnabled}
          autoHeight={autoHeight}
          rowHeight={rowHeight}
          hideFooter={hideFooter}
          // hideFooterRowCount={hideFooterRowCount}
          hideFooterSelectedRowCount={hideFooterSelectedRowCount}
          loading={isLoading}
          disableColumnMenu={disableColumnMenu}
          disableSelectionOnClick={disableSelectionOnClick}
          sortingMode={sortingMode}
          filterMode={filterMode}
          paginationMode={paginationMode}
          disableColumnFilter={disableColumnFilter}
          page={currentPage}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange}
          sx={{}}
          components={{
            ...(customHeader && { Header: customHeader }),
            ...(showLinearLoadingOverlay && {
              LoadingOverlay: LinearLoadingOverlay,
            }),
            ...(customFooter && { Footer: customFooter }),
            ...(toolbar && { Toolbar: toolbar }),
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          {...(rowIdGetter && { getRowId: rowIdGetter })}
          {...(isRowSelectable && { isRowSelectable })}
          {...(sortingOrder && { sortingOrder })}
          {...(isCellEditable && { isCellEditable })}
          {...(selectionEnabled &&
            selectionToolbar && { onSelectionModelChange })}
          {...(rowsPerPageOptions && { rowsPerPageOptions })}
          {...(totalRowsCount && { rowCount: totalRowsCount })}
          {...(hasError && { error: true })}
        />
      </div>
    </StyledDataGridContainer>
  );
};
