import { AccountantSetup } from "components/Accountant";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const AccountantSetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Accountant Setup"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Accountants', navigateTo: '/accountants/list'}, {text: 'Add Accountant', navigateTo: '/accountants/setup'}]}
      mainContents={<AccountantSetup />}
    />
  );
};
