import { FLEX_FIELD_SCREEN_SETUP_SCREEN_SLUG, INITIAL_SNACKBAR_DATA, QUERY_OPTIONS } from "common/constants";
import {
  instanceOfIFlexFieldSetupResponse,
  instanceOfIScreenPanelsAssignmentResponsePayload,
} from "common/instance-method";
import { IResponse, IServerError } from "models/common";
import { IFieldsetModel, IFieldsetResponseModel } from "models/fieldset";
import { FC, useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { FieldsetService, FlexFieldService } from "services";

import { yupResolver } from "@hookform/resolvers/yup";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Box, Grid, useTheme } from "@mui/material";
import {
  BackdropCircularProgress,
  DNDTwoColumnList,
  EnhancedDropDownRHFWithLabel,
  EnhancedIOSSwitchRHFWithLabel,
  EnhancedSnackbar,
  IDNDList,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  SubmitButton,
  SubmitButtonVariant,
} from "@websential/cosmic";

import { FieldsetSchema } from "./validator";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch } from "react-redux";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IFlexFieldScreenFieldset extends ITest {}
const firstPanelDraggableId = "firstList";
const secondPanelDraggableId = "secondList";

const FLEX_FIELD_FIELDSET_SCREENS_DDL_QUERY_KEY =
  "get-flex-field-fieldset-screen-dll";
const FLEX_FIELD_FIELDSET_TABS_DDL_QUERY_KEY =
  "get-flex-field-fieldset-tabs-dll";
const FLEX_FIELD_FIELDSET_CATEGORY_FIELDS_DDL_QUERY_KEY =
  "get-flex-field-fieldset-category-fields-dll";
const FLEX_FIELD_DATA_QUERY_KEY = "get-flex-field-data";
const FLEX_FIELD_FIELDSET_ASSIGNED_PANELS_QUERY_KEY =
  "get-flex-field-fieldset-assigned-panels";

// const DEFAULT_VALUES = {
//   screenId: "",
//   tabId: "",
//   categoryFieldId: "",
// };

export const FlexFieldScreenFieldset: FC<IFlexFieldScreenFieldset> = ({
  testId,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedScreenId, setSelectedScreenId] = useState(0);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [selectedCategoryFieldId, setSelectedCategoryFieldId] = useState(0);
  const [selectedCategoryFieldValue, setSelectedCategoryFieldValue] =
    useState("");
  const [firstList, setFirstList] = useState<IDNDList>({
    droppableId: "firstList",
    elements: [],
    testId: "firstList",
  });
  const [secondList, setSecondList] = useState<IDNDList>({
    droppableId: "secondList",
    elements: [],
    testId: "secondList",
  });
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    FieldsetService.create,
    {
      onSuccess: (
        res: IFieldsetResponseModel[] | IServerError | IResponse | null | any
      ) => {
        console.log("resresres", res);
        if (res.message) {
          setSnackbarData({
            open: true,
            // message: "Flex field(s) have been assigned to the panel successfully",
            message: res?.message,
            severity: res?.status === false ? "error" : "success",
          });
        } else {
          setSnackbarData({
            open: true,
            message:
              "Flex field(s) have been assigned to the panel successfully",
            severity: "success",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "An error occurred, please try again",
          severity: "error",
        });
      },
    }
  );

  const formMethods = useForm<IFieldsetModel>({
    resolver: yupResolver(FieldsetSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    // defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = (formData: IFieldsetModel) => {
    console.log("@@@@@@=>formData", formData);
    formData.panels = [];
    secondList.elements.forEach((item, index) => {
      formData.panels.push(Number(item.value));
    });

    submitFormData(formData);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    // formMethods.reset(DEFAULT_VALUES);
  };

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const onDragEnd = (result: DropResult, _provided: ResponderProvided) => {
    if (
      result.destination &&
      result.source.droppableId !== result.destination.droppableId
    ) {
      if (result.source.droppableId === "firstList") {
        let sourceList = firstList;
        let destinationList = secondList;
        let movedItem = sourceList.elements.splice(result.source.index, 1)[0];
        destinationList.elements.splice(result.destination.index, 0, movedItem);
        setFirstList({
          ...sourceList,
        });
        setSecondList({
          ...destinationList,
        });
      } else {
        let sourceList = secondList;
        let destinationList = firstList;
        let movedItem = sourceList.elements.splice(result.source.index, 1)[0];
        destinationList.elements.splice(result.destination.index, 0, movedItem);
        setSecondList({
          ...sourceList,
        });
        setFirstList({
          ...destinationList,
        });
      }
    } else {
      if (!result.destination) return;
      if (result.source.droppableId === "firstList") {
        let sourceDestinationList = firstList;
        let sourceItem = sourceDestinationList.elements.splice(
          result.source.index,
          1
        )[0];
        sourceDestinationList.elements.splice(
          result.destination.index,
          0,
          sourceItem
        );
        setFirstList({
          ...sourceDestinationList,
        });
      } else {
        let sourceDestinationList = secondList;
        let sourceItem = sourceDestinationList.elements.splice(
          result.source.index,
          1
        )[0];
        sourceDestinationList.elements.splice(
          result.destination.index,
          0,
          sourceItem
        );
        setSecondList({
          ...sourceDestinationList,
        });
      }
    }
  };

  const useGetScreensDropDownList = () =>
    useQuery(
      FLEX_FIELD_FIELDSET_SCREENS_DDL_QUERY_KEY,
      () => FieldsetService.getScreensDropDownList(),
      QUERY_OPTIONS
    );

  const useGetTabsDropDownList = () =>
    useQuery(
      FLEX_FIELD_FIELDSET_TABS_DDL_QUERY_KEY,
      () => FieldsetService.getTabsDropDownList(),
      { ...QUERY_OPTIONS, enabled: true }
    );

  const useGetCategoryFieldsDropDownList = () =>
    useQuery(
      [
        FLEX_FIELD_FIELDSET_CATEGORY_FIELDS_DDL_QUERY_KEY,
        selectedCategoryFieldId,
      ],
      () => FlexFieldService.getFlexFieldDropDownList("category"),
      { ...QUERY_OPTIONS, enabled: true }
    );

  const useGetFlexFieldData = (flexFieldId: number) => {
    return useQuery(
      [FLEX_FIELD_DATA_QUERY_KEY, flexFieldId],
      () => FlexFieldService.getFlexFieldById(flexFieldId),
      { ...QUERY_OPTIONS, enabled: false }
    );
  };

  const useGetAssignedPanels = () =>
    useQuery(
      [
        FLEX_FIELD_FIELDSET_ASSIGNED_PANELS_QUERY_KEY,
        selectedCategoryFieldValue,
      ],
      () =>
        FieldsetService.getPanelsAssignedToScreen(
          selectedScreenId,
          selectedTabId,
          selectedCategoryFieldId,
          selectedCategoryFieldValue
        ),
      { ...QUERY_OPTIONS, enabled: false }
    );

  const { isLoading: isLoadingScreenDropDownList, data: screenList } =
    useGetScreensDropDownList();

  const {
    isLoading: isLoadingTabDropDownList,
    data: tabList,
    // refetch: refetchTabsDDL,
  } = useGetTabsDropDownList();

  const {
    isLoading: isLoadingCategoryFieldsDropDownList,
    data: categoryFieldList,
    // refetch: refetchCategoryFieldsDDL,
  } = useGetCategoryFieldsDropDownList();

  const {
    isLoading: isLoadingCategoryFieldValuesDropDownList,
    data: categoryFieldValue,
    refetch: refetchCategoryValuesDDL,
  } = useGetFlexFieldData(selectedCategoryFieldId);

  const {
    isLoading: isLoadingAssignedPanels,
    data: assignedPanelResponsePayload,
    refetch: refetchAssignedPanels,
  } = useGetAssignedPanels();

  console.log("assignedPanelResponsePayload", assignedPanelResponsePayload);

  useEffect(() => {
    console.log("$$$$$$-useEffect-selectedTabId", selectedTabId);
  }, [selectedTabId]);

  useEffect(() => {
    if (selectedCategoryFieldId > 0) {
      refetchCategoryValuesDDL();
    }
  }, [refetchCategoryValuesDDL, selectedCategoryFieldId]);

  useEffect(() => {
    if (selectedCategoryFieldValue && selectedCategoryFieldValue.length > 0) {
      refetchAssignedPanels();
    }
  }, [refetchAssignedPanels, selectedCategoryFieldValue]);

  useEffect(() => {
    if (
      assignedPanelResponsePayload &&
      instanceOfIScreenPanelsAssignmentResponsePayload(
        assignedPanelResponsePayload
      )
    ) {
      let unassignedPanels: IDNDList = {
        droppableId: firstPanelDraggableId,
        elements: assignedPanelResponsePayload.unassignedPanels.map(
          (unassignedFlexField: any) => ({
            id: String(unassignedFlexField.id),
            text: String(unassignedFlexField.text),
            value: String(unassignedFlexField.value),
          })
        ),
        testId: firstPanelDraggableId,
      };

      let assignedPanels: IDNDList = {
        droppableId: secondPanelDraggableId,
        elements: assignedPanelResponsePayload.assignedPanels.map(
          (assignedFlexField: any) => ({
            id: String(assignedFlexField.id),
            text: String(assignedFlexField.text),
            value: String(assignedFlexField.value),
          })
        ),
        testId: secondPanelDraggableId,
      };
      setFirstList(unassignedPanels);
      setSecondList(assignedPanels);
    }
  }, [assignedPanelResponsePayload]);

  const tabListItems = useMemo(() => {
    if (tabList && Array.isArray(tabList)) {
      return tabList;
    }

    // Return empty array in case of API data fetching error
    return [];
  }, [tabList]);

  const categoryFieldValueList = useMemo(() => {
    if (
      categoryFieldValue &&
      instanceOfIFlexFieldSetupResponse(categoryFieldValue)
    ) {
      if (
        categoryFieldValue.fieldValue &&
        categoryFieldValue.fieldValue.length > 0
      ) {
        let categoryFieldValues = categoryFieldValue.fieldValue.split(",");
        let categoryFieldInternalValues =
          categoryFieldValue.fieldInternalValue.split(",");
        return categoryFieldValues.map((fieldValue: string, index: number) => ({
          label: fieldValue,
          value: categoryFieldInternalValues[index],
        }));
      }
    }

    return [];
  }, [categoryFieldValue]);

  const onScreenSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
    let selectedValue = Number(item.value);
    if (selectedScreenId !== selectedValue) {
      setSelectedScreenId(selectedValue);
      setSelectedTabId(0);
      setSelectedCategoryFieldId(0);
      setSelectedCategoryFieldValue("");
      formMethods.setValue("tabId", "");
      formMethods.setValue("categoryFieldId", "");
      formMethods.setValue("categoryFieldValue", "");
    }
  };

  const onTabSelectionChangeHandler = (item: IEnhancedDropDownItem) => {
    let selectedValue = Number(item.value);
    if (selectedTabId !== selectedValue) {
      setSelectedTabId(selectedValue);
      setSelectedCategoryFieldId(0);
      setSelectedCategoryFieldValue("");
      formMethods.setValue("categoryFieldId", "");
      formMethods.setValue("categoryFieldValue", "");
    }
  };

  const onCategoryFieldSelectionChangeHandler = (
    item: IEnhancedDropDownItem
  ) => {
    let selectedValue = Number(item.value);
    if (selectedCategoryFieldId !== selectedValue) {
      setSelectedCategoryFieldId(selectedValue);
      setSelectedCategoryFieldValue("");
      formMethods.setValue("categoryFieldValue", "");
    }
  };

  const onCategoryValueSelectionChangeHandler = (
    item: IEnhancedDropDownItem
  ) => {
    setSelectedCategoryFieldValue(String(item.value));
  };

  const FixScreenList = [
    {
      label: "Accountant Setup",
      value: 22,
    },
    {
      label: "Client Setup",
      value: 21,
    },
    {
      label: "Resource Setup",
      value: 19,
    },
  ];

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: FLEX_FIELD_SCREEN_SETUP_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);


  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={
          isSubmitting ||
          isLoadingScreenDropDownList ||
          isLoadingTabDropDownList ||
          isLoadingCategoryFieldsDropDownList ||
          isLoadingCategoryFieldValuesDropDownList ||
          isLoadingAssignedPanels
        }
        testId={`${testId}-backdrop`}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            children={
              <>
                <StandardPanel
                  title="Screen Fieldset"
                  testId={`${testId}-screen-heading-panel`}
                  leftSpacing={2}
                  rightSpacing={2}
                >
                  <EnhancedDropDownRHFWithLabel
                    testId={`${testId}-name`}
                    label="Select Screen"
                    labelPosition={LabelPosition.Left}
                    required
                    selectName="screenId"
                    // items={
                    //   screenList && Array.isArray(screenList) ? screenList : []
                    // }
                    items={FixScreenList}
                    onSelectionChangeHandler={onScreenSelectionChangeHandler}
                  />
                  {/* {selectedScreenId > 0 && ( */}
                  <EnhancedDropDownRHFWithLabel
                    testId={`${testId}-name`}
                    label="Select Tab"
                    labelPosition={LabelPosition.Left}
                    selectName="tabId"
                    items={tabListItems}
                    selectedItem={selectedTabId}
                    onSelectionChangeHandler={onTabSelectionChangeHandler}
                    required
                  />
                  {/* )} */}
                  {/* {selectedScreenId > 0 && selectedTabId > 0 && ( */}
                  <EnhancedDropDownRHFWithLabel
                    testId={`${testId}-name`}
                    label="Select Category"
                    labelPosition={LabelPosition.Left}
                    required
                    selectName="categoryFieldId"
                    items={
                      categoryFieldList && Array.isArray(categoryFieldList)
                        ? categoryFieldList
                        : []
                    }
                    onSelectionChangeHandler={
                      onCategoryFieldSelectionChangeHandler
                    }
                  />
                  {/* )} */}
                  {selectedScreenId > 0 &&
                    selectedTabId > 0 &&
                    selectedCategoryFieldId > 0 && (
                      <>
                        <EnhancedDropDownRHFWithLabel
                          testId={`${testId}-name`}
                          label="Select Category Value"
                          labelPosition={LabelPosition.Left}
                          required
                          selectName="categoryFieldValue"
                          items={categoryFieldValueList}
                          onSelectionChangeHandler={
                            onCategoryValueSelectionChangeHandler
                          }
                        />
                        <EnhancedIOSSwitchRHFWithLabel
                          name="defaultCategoryField"
                          checked={false}
                          testId="defaultCategoryField"
                          label="Default Category"
                          labelPosition={LabelPosition.Left}
                        />
                      </>
                    )}
                </StandardPanel>
                {selectedScreenId > 0 &&
                  selectedTabId > 0 &&
                  selectedCategoryFieldId > 0 &&
                  selectedCategoryFieldValue &&
                  selectedCategoryFieldValue.length > 0 && (
                    <Box sx={{ pt: theme.spacing(3) }}>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <DNDTwoColumnList
                          firstColumnListTitle="UnAssociations"
                          firstColumnList={firstList}
                          secondColumnListTitle="Associations"
                          secondColumnList={secondList}
                          testId="flex-fields-assignment"
                        />
                      </DragDropContext>
                    </Box>
                  )}
              </>
            }
            toolbar={
              <StandardSubmitFormButtonsToolbar
                toolbarContents={
                  <SubmitButton
                    label={"Save"}
                    testId="save-button"
                    variant={SubmitButtonVariant.Secondary}
                    icon={<PlaylistAddCheckOutlinedIcon />}
                  />
                }
                onResetClicked={onResetClicked}
                isSubmitting={false}
                testId="flex-fields-panel-assignment-toolbar"
              />
            }
            testId="form-layout"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
