import { DataRoleSetup } from "components/DataRole";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const DataRoleSetupPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Data Role Setup"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Data Roles", navigateTo: "/data-roles" },
      { text: "Create Data Role", navigateTo: "/data-roles/setup" }
      ]}
      mainContents={<DataRoleSetup testId="data-role-setup-page" />}
    />
  );
};
