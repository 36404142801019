import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import { FC } from "react";

export interface IT5SlipDetailPanel {
  formMethods: any;
}

export const T5SlipDetailPanel: FC<IT5SlipDetailPanel> = ({formMethods}) => {
  const handleInputChange = (e: any) => {
    const newValue = e.target.value;
    console.log("newValue", newValue);
  };
  return (
    <>
      <TaxSlipSummaryDataInput
        id="totalNumberOfT5SlipsFiled"
        label="Total number of T5 slips filed"
        boxNumber="31"
        textfield={true}
      />
      <TaxSlipSummaryDataInput
        id="actualAmountOfDividendsOtherThanEligible"
        label="Actual amount of dividends other than eligible"
        boxNumber="10"
      />
      <TaxSlipSummaryDataInput
        id="taxableAmountOfDivinendsOtherThanEligible"
        label="Taxable amount of dividends other than eligible"
        boxNumber="11"
      />
      <TaxSlipSummaryDataInput
        id="federalDividendTaxCreditOtherThanEligible"
        label="Federal dividend tax credit other than eligible"
        boxNumber="12"
      />
      <TaxSlipSummaryDataInput
        id="interestFromCanadianSources"
        label="Interest from canadian source"
        boxNumber="13"
      />
      <TaxSlipSummaryDataInput
        id="foreignIncome"
        label="Foreign income"
        boxNumber="15"
      />
      <TaxSlipSummaryDataInput
        id="foreignTaxPaid"
        label="Foreign tax paid"
        boxNumber="16"
      />
      <TaxSlipSummaryDataInput
        id="royaltiesFromCanadianSources"
        label="Royalities from canadian source"
        boxNumber="17"
      />
      <TaxSlipSummaryDataInput
        id="capitalGainsDividends"
        label="Capital gains dividends"
        boxNumber="18"
      />
      <TaxSlipSummaryDataInput
        id="accruedIncomeAnnuities"
        label="Accrued income: Annuities"
        boxNumber="19"
      />
      <TaxSlipSummaryDataInput
        id="amountEligibleForResourceAllowanceDeduction"
        label="Amount eligible for resource allowance deduction"
        boxNumber="20"
      />
      <TaxSlipSummaryDataInput id="actualAmountOfEligibleDividends" 
        label="Actual amount of eligible dividends" 
        boxNumber="24" />
      <TaxSlipSummaryDataInput
        id="taxableAmountOfEligibleDividends"
        label="Taxable amount of eligible dividends"
        boxNumber="25"
      />
      <TaxSlipSummaryDataInput
        id="dividendTaxCreditForEligibleDividends"
        label="Dividend tax credit other than eligible"
        boxNumber="26"
      />
      <TaxSlipSummaryDataInput
        id="unclaimedAmounts"
        label="Unclaimed amounts"
        boxNumber="32"
        readOnly={false}
        onChange={handleInputChange}
      />
      <TaxSlipSummaryDataInput
        id="taxDeductedFromUnclaimedAmounts"
        label="Tax deducted from unclaimed amount"
        boxNumber="33"
        readOnly={false}
        onChange={handleInputChange}
      />
    </>
  );
};
