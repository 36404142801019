import { FC } from "react";

import {
  EnhancedDropDownRHFWithLabel,
  ITest,
  LabelPosition,
} from "@websential/cosmic";

interface IFilingDataSetupFieldsPanel extends ITest {taxYear:number}
const listItems = [
  {label:"Not eligible to access any dental care insurance, or coverage of dental service of any kind",value:1},
  {label:"Payee only",value:2},
  {label:"Payee, spouse and dependent children",value:3},
  {label:"Payee and their spouse",value:4},
  {label:"Payee and their dependent children",value:5},
]
export const FilingDataSetupFieldsPanel: FC<IFilingDataSetupFieldsPanel> = ({
  testId,taxYear
}) => {
  return (
    <>
      {/* <EnhancedDropDownRHFWithLabel
        label="Slip Type"
        selectName="slipType"
        items={[
          { label: "Original", value: 400 },
          { label: "Amended", value: 200 },
          { label: "Cancelled", value: 300 },
          { label: "Additional", value: 100 },
        ]}
        selectedItem={400}
        testId={`${testId}-slip-type`}
        labelPosition={LabelPosition.Left}
        required
      /> */}
      {taxYear >= 2023 ? 
        <EnhancedDropDownRHFWithLabel
          label="Employer-offered dental benefits"
          selectName="employerOfferedDentalBenefits"
          items={listItems ?? []}
          showEmpty={true}
          testId="EmployerOfferedDentalBenefits"
          labelPosition={LabelPosition.Left}
        />
      :<></>}
      <EnhancedDropDownRHFWithLabel
        label="Status"
        selectName="slipStatus"
        items={[
          { label: "Draft", value: 200 },
          { label: "Completed", value: 100 },
        ]}
        selectedItem={200}
        testId={`${testId}-status`}
        labelPosition={LabelPosition.Left}
        required
      />
    </>
  );
};
