import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { GridRenderCellParams } from "@mui/x-data-grid";
import "./generalClientsDataTableStyle.css";
import { RequestParams } from "@websential/cosmic";
import SearchIcon from '@mui/icons-material/Search';

export interface IDataTable {
  rows: any;
  columns: any;
  showIndexColumn: boolean;
  showChecboxColumn: boolean;
  showSettingColumn: boolean;
  onRowEditClicked: (params: GridRenderCellParams) => void;
  onSubmitButtonClicked: (params: GridRenderCellParams) => void;
  // onRowDeleteClicked:(params: GridRenderCellParams) => void;
  isLoading?: boolean;
  onRequestParamsChange?: (params: RequestParams) => void;
initialPageSize?: number | undefined;
initialPage?: number;
totalRecords?: number;
}

const PrimeDataGrid: React.FC<IDataTable> = ({
  rows,
  columns,
  showIndexColumn,
  showChecboxColumn,
  showSettingColumn,
  onRowEditClicked,
  // onRowDeleteClicked,
  isLoading,
  onSubmitButtonClicked,
  onRequestParamsChange,
  initialPageSize = 10,
  initialPage = 0,
  totalRecords,
}) => {
  const isInitialRequest = useRef(true);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [itemSearch, setItemSearch] = useState('');
    const [requestParams, setRequestParams] = useState<any>({
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        sortField: null,
        sortOrder: null,
        searchQuery:"",
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

  const [val, setValue] = useState(false);
  const [field, setField] = useState("");
  const setting = () => {
    return (
      <div style={{ marginLeft: "0.35rem" }}>
        <i className="pi pi-cog text-xl"></i>
      </div>
    );
  };
  const delEdit = (params: any) => {
    return (
      <Button
        icon="pi pi-user-edit"
        onClick={() =>
          onRowEditClicked(selectedItems.length > 0 ? selectedItems : params)
        }
        className="p-button-rounded p-button-text w-2rem h-2rem"
      />
    );
  };

  const onSearchButtonClicked = () => {
    setRequestParams((prevParams:any) => ({
        ...prevParams,
        searchQuery: itemSearch,
      }));
}
const handleClearSearch = () => {
    setItemSearch('')
    setRequestParams((prevParams:any) => ({
        ...prevParams,
        searchQuery: "",
      }));
    }
// Search type and billing profile Button
    const renderHeader = () => {
        return (
            // <div className='flex justify-content-between p-4 pl-0'  style={{backgroundColor:"#ebecf0",margin:"-17px"}}>
            <span className="p-input-icon-left w-5" style={{display:"flex"}}>
                <i className="pi pi-search" />
                <InputText value={itemSearch} onChange={(e) => setItemSearch(e.target.value)} placeholder="Search by Employee Name" className='w-full' style={{borderRadius:"5px 0px 0px 5px"}}/>
                {itemSearch && (
                    <Button
                    icon="pi pi-times"
                    onClick={handleClearSearch}
                    className="p-button-warning"
                    style={{borderRadius:0}}
                    />
                )}
                <Button
                    onClick={onSearchButtonClicked}
                    // variant="contained"
                    style={{
                    padding:"8px 10px 8px 10px",
                    marginRight:"-14px!important",
                    borderRadius:"0px 5px 5px 0px"}}
                >
                    <SearchIcon />
                </Button>
            </span>
            // </div>
        )
    }
    const headerSearch = renderHeader();

  const clickHideBtn = (value: any, field: any, is_load: any) => {
    if (is_load) {
      if (value.field === field) {
        setValue(value);
        setField(field);
      }
    } else {
      return false;
    }
  };
  const footer = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <Button
          label="Submit"
          icon="pi pi-check"
          severity="success"
          onClick={() => onSubmitButtonClicked(selectedItems)}
        />
      </div>
    );
  };

  // Custom column for row indices
  const indexColumn = {
    body: (rowData: any, row: any) => {
      return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
    },
    header: "#",
  };

  const onCellEditComplete = (e: any) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    if (newValue && newValue.trim().length > 0) rowData[field] = newValue;
    else event.preventDefault();
  };

  const cellEditor = (options: any) => {
    if (options.field === "code") return codeEditor(options);
    else return textEditor(options);
  };
  const codeEditor = (options: any) => {
    return (
      <InputText
        style={{
          padding: "3px 0px 5px 8px",
          width: "100%",
          fontSize: "0.875rem",
        }}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };
  const textEditor = (options: any) => {
    return <span style={{ fontSize: "0.875rem" }}>{options.value}</span>;
  };

  const onPage = (event:any) => {
    setRequestParams(event);
};
// This useEffect is triggered when the debouncedSearchQuery, pageSize or currentPage values change
useEffect(() => {
  // if the results fetcher was passed, we can use it to fetch the results.
  if (onRequestParamsChange) {
    // skip the first initial call
    if (isInitialRequest.current === true) {
      console.log(
        "##### => useEffect - requestParams - no first call",
        requestParams
      );
      isInitialRequest.current = false;
    } else {
      console.log(
        "##### => useEffect - requestParams - second call",
        requestParams
      );
      onRequestParamsChange(requestParams);
    }
  }
}, [onRequestParamsChange, requestParams]);

  console.log("props headerColumns => ", columns);
  console.log("props items General Clients => ", rows);
  console.log("selectedItems => journal code ", selectedItems);
  return (
    <DataTable
      value={rows && rows}
      responsiveLayout="scroll"
      lazy
      dataKey="id" 
      paginator
      first={requestParams.first} 
      rows={requestParams.rows}
      totalRecords={totalRecords}
      onPage={onPage}

      rowsPerPageOptions={[25, 50, 100]}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate={`Showing {first} to {last} of ${totalRecords} Items`}
      selection={selectedItems}
      onSelectionChange={(e: any) => setSelectedItems(e.value)}
      selectionMode={'checkbox'}
      editMode="cell"
      showGridlines
      resizableColumns
      columnResizeMode="expand"
      scrollable
      reorderableColumns
      filterDisplay="menu"
      loading={isLoading}
      globalFilterFields={["code"]}
      emptyMessage="No Record found."
      className="generalClientsDataTableStyle"
      header={headerSearch}
      footer={footer}
      style={{ margin: "0.5rem" }}
    >
      <Column
        selectionMode="multiple"
        hidden={!showChecboxColumn}
        frozen
        resizeable={false}
        style={{ width: "34px" }}
        field="selection"
        columnKey="selection"
      ></Column>
      {rows && <Column
        {...indexColumn}
        style={{ width: "3rem", textAlign: "center" }}
        frozen
        hidden={!showIndexColumn}
        className="indexColumn"
      />}

      {columns &&
        columns.map((x: any, index: any) => {
          console.log(
            "clickHideBtn(x, x.field, false) inhhhher",
            clickHideBtn(x, x.field, false)
          );

          return (
            <Column
              key={index}
              field={x.field}
              header={x.headerName}
              headerTooltip={x.description}
              headerTooltipOptions={{ position: "top" }}
              // hidden={
              //   val === x.hide
              //     ? clickHideBtn(x, x.field, false)
              //     : x.field === field
              //     ? val
              //     : x.hide
              // }
              sortable={x.sortable}
              resizeable={x.resizable}
              dataType={x.type}
              align={x.align}
              alignHeader={x.headerAlign}
              reorderable={x.disableReorder}
              exportable={x.disableExport}
              frozen={x.frozenColumn}
              columnKey={x.field}
              style={{
                minWidth: x.minWidth,
                fontSize: "0.875rem",
              }}
              footer={x.footer}
              editor={(options) => cellEditor(options)}
              onCellEditComplete={onCellEditComplete}
            />
          );
        })}

      <Column
        body={delEdit}
        header={setting}
        style={{ width: "32px" }}
        hidden={!showSettingColumn}
        resizeable={false}
        reorderable={false}
        frozen
        alignFrozen="right"
      ></Column>
    </DataTable>
  );
};

export default PrimeDataGrid;

//resizeablbe must be false if frozen is true and viseversa
