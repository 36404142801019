import { DEFINITIONS_SETUP_SCREEN_SLUG, INITIAL_PAGE_SIZE, INITIAL_SNACKBAR_DATA, QUERY_OPTIONS } from "common/constants";
import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import {
  IGetListRequest,
  IResponse,
  IServerError,
} from "models";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BackdropCircularProgress,
  EnhancedDropDownRHFWithLabel,
  EnhancedIOSSwitchRHFWithLabel,
  EnhancedSnackbar,
  ITest,
  LabelPosition,
  RHookFormTextFieldWithLabel,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
} from "@websential/cosmic";

import { TaxSlipDefinitionsSchema } from "./validator";
import { useNavigate, useParams } from "react-router-dom";
import { instanceOfITaxslipDefinitionsResponce } from "common/instance-method";
import { Grid } from "@mui/material";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch } from "react-redux";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { TaxSlipDefinitionsService } from "services/TaxSlipDefinitionsService";
import { TaxslipsDefinitionsToolbar } from "./TaslipsDefinitionsToolbar";
import { TaxSlipCategoryService, TaxSlipYearService } from "services";

export interface ITaxSlipDefinitionsSetup extends ITest {}

// for edit
const TAXSLIP_Definitions_LIST_DATA_QUERY_KEY = "get-taxslip-Definitions-data";

const useGetData = (id: string | undefined) => 
  useQuery(
    TAXSLIP_Definitions_LIST_DATA_QUERY_KEY,
    (data) => {
      return TaxSlipDefinitionsService.getById(id)
    },
    {refetchOnWindowFocus: false}
  );

const useTaxSlipCategories = (params: IGetListRequest) =>
  useQuery(
    ["taxslip-category-list", params],
    (data) => {
      return TaxSlipCategoryService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

const useYears = (params: IGetListRequest) =>
  useQuery(
    ["years-list", params],
    (data) => {
      return TaxSlipYearService.getList(params);
    },
    { refetchOnWindowFocus: false }
  );

const DEFAULT_VALUES = {
  // name: "",
};

export const TaxSlipDefinitionsSetup: FC<ITaxSlipDefinitionsSetup> = ({ testId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  // for edit
  const queryClient = useQueryClient();
  const { id } = useParams();
  console.log("id from setup file which clicked : ", id);

  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    TaxSlipDefinitionsService.create,
    {
      onSuccess: (
        res: any | IServerError | IResponse
      ) => {
        if (instanceOfITaxslipDefinitionsResponce(res)) {
          queryClient.invalidateQueries([
            TAXSLIP_Definitions_LIST_DATA_QUERY_KEY,
            res.id,
          ]);
          setSnackbarData({
            open: true,
            message: "Taxslip Definitions has been added successfully",
            severity: "success",
          });
        }
        let path = "/taxslip-definitions/list";
        navigate(path);
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "An error occurred, please try again",
          severity: "error",
        });
      },
    }
  );

  // for edit
  const { isLoading: isLoadingData, data: selectedTaxslipDefinitionsData } =
    useGetData(id);

    const { isLoading: isLoadingCategoryData, data  } = useTaxSlipCategories({
      search_query: "",
      order_by: "name",
      order_direction: "asc",
      page: 1,
      size: INITIAL_PAGE_SIZE,
    }); 
    const selectedTaxslipCategoryData: any = data;

    const categoryIdDDL = useMemo(() => {
      if (selectedTaxslipCategoryData && Array.isArray(selectedTaxslipCategoryData && selectedTaxslipCategoryData?.items)) {
        return (
          selectedTaxslipCategoryData &&
          selectedTaxslipCategoryData?.items.map((item: any) => ({
            id: item.id,
            label: item.name,
            value: item.id,
          }))
        );
      }
  
      // Return empty array in case of API data fetching error
      return [];
    }, [selectedTaxslipCategoryData]);


    const {
      isLoading: isLoadingYearsList,
      data: yearsDDLList,
      // refetch: refetchTabsDDL,
    } = useYears({
      search_query: "",
      order_by: "client_name",
      order_direction: "asc",
      page: 1,
      size: 25,
    });
  const yearsDDLItems = useMemo(() => {
      if (yearsDDLList && Array.isArray(yearsDDLList && yearsDDLList.items)) {
        return (
          yearsDDLList &&
          yearsDDLList.items.map((year: any) => ({
            id: year.id,
            label: year.year,
            value: year.year,
          }))
        );
      }
  
      // Return empty array in case of API data fetching error
      return [];
    }, [yearsDDLList]);

  const formMethods = useForm<any>({
    resolver: yupResolver(TaxSlipDefinitionsSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = (formData: any) => {
    console.log("formDataa",formData)
    let payload = {
      id:id && id,
      code:formData.code,
      title:formData.title,
      description:formData.description !== "" ? formData.description : undefined,
      categoryId:formData.categoryId,
      taxYear: formData.year,
      supportedLanguages:[{code:formData.supportedLanguages,id:1}],
      dataRole:{name:"Employee",id:1},
      supportedCountry:{id: 1,name: "Canada",shortCode: "CAN"},
      legislationOrAuthority:formData.legislationOrAuthority !== "" ? formData.legislationOrAuthority : undefined
    }
    submitFormData(payload);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset(DEFAULT_VALUES);
  };

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // for edit
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (selectedTaxslipDefinitionsData) {
      Object.keys(selectedTaxslipDefinitionsData).forEach((fieldName) => {
        console.log("fieldName useEffect TC : ", fieldName);
        // @ts-ignore
        const fieldValue = selectedTaxslipDefinitionsData[fieldName];
        console.log("fieldValue useEffect TC : ", fieldValue);
        // @ts-ignore
        formMethods.setValue(fieldName, fieldValue ?? undefined);
        if(fieldName === 'supportedLanguages'){
          formMethods.setValue(fieldName, fieldValue[0].code ?? undefined);
        }
        if(fieldName === 'taxYear'){
          formMethods.setValue('year', fieldValue ?? undefined);
        }
      });
    }
  }, [selectedTaxslipDefinitionsData, formMethods.setValue, formMethods]);


  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: DEFINITIONS_SETUP_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={isSubmitting || isLoadingData || isLoadingYearsList || isLoadingCategoryData}
        testId={`${testId}-backdrop`}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            children={
              <StandardPanel
                title="TaxSlip Definitions"
                testId={`${testId}-taxslip-Definitions`}
              >
                <RHookFormTextFieldWithLabel
                  id="code"
                  testId={`${testId}-code`}
                  label="Code"
                  labelPosition={LabelPosition.Left}
                  required
                />
                <RHookFormTextFieldWithLabel
                  id="title"
                  testId={`${testId}-title`}
                  label="Title"
                  labelPosition={LabelPosition.Left}
                  required
                />
                <RHookFormTextFieldWithLabel
                  id="description"
                  testId={`${testId}-description`}
                  label="Description"
                  labelPosition={LabelPosition.Left}
                />
                <RHookFormTextFieldWithLabel
                  id="legislationOrAuthority"
                  testId={`${testId}-legislationOrAuthority`}
                  label="Legislation or Authority"
                  labelPosition={LabelPosition.Left}
                />
                    <EnhancedDropDownRHFWithLabel
                      selectName="categoryId"
                      label="Category"
                      labelPosition={LabelPosition.Left}
                      required
                      testId={`${testId}-category`}
                      items={categoryIdDDL ? categoryIdDDL : []}
                    />
                <RHookFormTextFieldWithLabel
                    label="Country"
                    id="country"
                    testId={`${testId}-employee-country`}
                    labelPosition={LabelPosition.Left}
                    value="Canada"
                    defaultValue={"Canada"}
                    readOnly
                    required
                  />
                <EnhancedDropDownRHFWithLabel
                  selectName="supportedLanguages"
                  label="Supported Languages"
                  labelPosition={LabelPosition.Left}
                  required
                  testId={`${testId}-languages`}
                  items={[{label:"English",value:"english"},{label:"French",value:"french"}]}
                  selectedItem={'english'}
                />
              </StandardPanel>
            }
            toolbar={
              // <StandardSubmitFormButtonsToolbar
              //   onResetClicked={onResetClicked}
              //   isSubmitting={false}
              //   testId="standard-form-submit-toolbar"
              // />
              <TaxslipsDefinitionsToolbar
                onResetClicked={onResetClicked}
                isSubmitting={false}
                yearsDDL={yearsDDLItems}
              />
            }
            testId="form-layout"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
