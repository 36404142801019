import { FC, useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";

import { TaxSlipDataInput } from "../../TaxSlipDataInput";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  EnhancedDropDownRHFWithLabel,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
} from "@websential/cosmic";
import { OtherInformationSetupFieldsPanel } from "./OtherInformationSetupFieldsPanel";
interface IForeignCurrencyDDL {
  label:String;
  name:String;
  value:String;
}
export interface IT3SlipDataPanel extends ITest {
  formMethods: any;
  otherInformationList: any;
  isLoading: boolean;
  selectedOtherInformation:boolean;
  oiInEdit:boolean;
  selectedProvinceOfEmployment:any;
  globalVariablesData:any;
}

export const T3SlipDataPanel: FC<IT3SlipDataPanel> = ({
  formMethods,
  otherInformationList,
  isLoading,
  testId,
  selectedOtherInformation,
  oiInEdit,
  selectedProvinceOfEmployment,
  globalVariablesData
}) => {
  const [countryLabel, setCountryLabel] = useState("")
  const [showGlobalVariablesError, setShowGlobalVariablesError] = useState(false);

  // const onForiegnCurrencyChangeHandler = (e: any) => {
  //     let CountryFullName = foreignCurrencyDDL.find((value:any) => e.value === value.value)
  //     console.log("CountryFullName",CountryFullName)
  //     setCountryLabel(CountryFullName.name)
  //   }
  //   let foreignCurrencyValue = formMethods.getValues('foreignCurrency')
  //   useEffect(()=>{
  //     console.log("foreignCurrencyValue",foreignCurrencyValue)
  //     if(foreignCurrencyValue){
  //     let CountryFullName = foreignCurrencyDDL && foreignCurrencyDDL.find((value:any) => foreignCurrencyValue === value.value)
  //     console.log("CountryFullName",CountryFullName)
  //     setCountryLabel(CountryFullName && CountryFullName.name)
  //     }
  //   },[formMethods,foreignCurrencyValue])
  const handleBox49InputChangeHandler = (e: any) => {
    console.log(e.target.value)
    let value = parseInt(e.target.value)
    const selectedRecipientType = formMethods.getValues('recipientType')
    if(selectedRecipientType != 30){
      if (globalVariablesData && globalVariablesData?.data?.t3) {
    if(value > 0){
      // let box25CalculatedValue = ((value * 0.38) + value);
      // let box26CalculatedValue = (box25CalculatedValue * 0.150198);
        let box25CalculatedValue = (value * (globalVariablesData?.data?.t3?.eligibleGrossUpFactorT3/100)) + value;
        let box26CalculatedValue = box25CalculatedValue * (globalVariablesData?.data?.t3?.eligibleDividendTaxCreditPercentageOfTaxableAmountT3/100);
        formMethods.setValue('taxableAmountOfEligibleDividends',box25CalculatedValue.toFixed(2))
        formMethods.setValue('dividendTaxCreditForEligibleDividends',box26CalculatedValue.toFixed(2))
      } else {
        formMethods.setValue('taxableAmountOfEligibleDividends',"0.00")
        formMethods.setValue('dividendTaxCreditForEligibleDividends',"0.00")
      }
      setShowGlobalVariablesError(false)
    } else {
      formMethods.setValue('taxableAmountOfEligibleDividends',"0.00")
      formMethods.setValue('dividendTaxCreditForEligibleDividends',"0.00")
      if(globalVariablesData){
        setShowGlobalVariablesError(globalVariablesData.data.message ? globalVariablesData.data.message : "T3 Slip definition does not exist for selected year, please add definition to configure global variables")
      }
    }
  } else {
    formMethods.setValue('taxableAmountOfEligibleDividends',"0.00")
    formMethods.setValue('dividendTaxCreditForEligibleDividends',"0.00")
  }
  }
  const handleBox23InputChangeHandler = (e: any) => {
    console.log(e.target.value)
    let value = parseInt(e.target.value)
    const selectedRecipientType = formMethods.getValues('recipientType')
    if(selectedRecipientType != 30){
      if (globalVariablesData && globalVariablesData?.data?.t3) {
      if(value > 0){
        // let box11CalculatedValue = ((value * 0.15) + value);
        // let box12CalculatedValue = ((box11CalculatedValue * 9.0301)/100);
          let box11CalculatedValue = (value * (globalVariablesData?.data?.t3?.nonEligibleGrossUpFactorT3/100)) + value;
          let box12CalculatedValue = (box11CalculatedValue * (globalVariablesData?.data?.t3?.nonEligibleDividendTaxCreditPercentageOfTaxableAmountT3/100)) / 100;
          formMethods.setValue('taxableAmountOtherThanEligible',box11CalculatedValue.toFixed(2))
          formMethods.setValue('dividendTaxCreditOtherThanEligible',box12CalculatedValue.toFixed(2))
        } else {
          formMethods.setValue('taxableAmountOtherThanEligible',"0.00")
          formMethods.setValue('dividendTaxCreditOtherThanEligible',"0.00")
        }
        setShowGlobalVariablesError(false)
      } else {
        formMethods.setValue('taxableAmountOtherThanEligible',"0.00")
        formMethods.setValue('dividendTaxCreditOtherThanEligible',"0.00")
        if(globalVariablesData){
          setShowGlobalVariablesError(globalVariablesData.data.message ? globalVariablesData.data.message : "T3 Slip definition does not exist for selected year, please add definition to configure global variables")
        }
      }
    } else {
      formMethods.setValue('taxableAmountOfEligibleDividends',"0.00")
      formMethods.setValue('dividendTaxCreditForEligibleDividends',"0.00")
    }
  }
  return (
    <>
      <Grid container columnSpacing={2} xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}></Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Actual
          </Typography>
        </Grid>
      </Grid>
      {showGlobalVariablesError === false ? 
      (<>
      <TaxSlipDataInput
        id="actualAmountOfEligibleDivinends"
        label="Actual amount of eligible dividends"
        boxNumber="49"
        defaultValue="0.00"
        onChange={handleBox49InputChangeHandler}
      />
      </>)
      :
      (<>
      <div>
      <TaxSlipDataInput
        id="actualAmountOfEligibleDivinends"
        label="Actual amount of eligible dividends"
        boxNumber="49"
        defaultValue="0.00"
        onChange={handleBox49InputChangeHandler}
      />
        <span style={{display:"flex",justifyContent:"center",marginLeft:"19.6rem",color:"#cc8925",fontSize:"12px",background:"#fff2e2",alignItems:"center",padding:"2px",borderRadius:"5px"}}><WarningAmberIcon sx={{marginRight:"7px",padding:"0 2px 2px 2px"}}/>{showGlobalVariablesError}</span>
      </div></>)
}
      <TaxSlipDataInput
        id="taxableAmountOfEligibleDividends"
        label="Taxable amount of eligible dividends"
        boxNumber="50"
        defaultValue="0.00"
        readOnly={true}
      />
      <TaxSlipDataInput
        id="dividendTaxCreditForEligibleDividends"
        label="Dividend tax credit for eligible dividends"
        boxNumber="51"
        defaultValue="0.00"
        readOnly={true}
      />
      <TaxSlipDataInput
        id="capitalGains"
        label="Capital gains"
        boxNumber="21"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="capitalGainsEligible"
        label="Capital gains - eligible"
        boxNumber="30"
        defaultValue="0.00"
      />
      {showGlobalVariablesError === false ? 
      (<>
      <TaxSlipDataInput
        id="actualAmountOtherThanEligible"
        label="Actual amount - other than eligible"
        boxNumber="23"
        defaultValue="0.00"
        onChange={handleBox23InputChangeHandler}
      />
      </>)
      :
      (<>
      <div>
      <TaxSlipDataInput
        id="actualAmountOtherThanEligible"
        label="Actual amount - other than eligible"
        boxNumber="23"
        defaultValue="0.00"
        onChange={handleBox23InputChangeHandler}
      />
        <span style={{display:"flex",justifyContent:"center",marginLeft:"19.6rem",color:"#cc8925",fontSize:"12px",background:"#fff2e2",alignItems:"center",padding:"2px",borderRadius:"5px"}}><WarningAmberIcon sx={{marginRight:"7px",padding:"0 2px 2px 2px"}}/>{showGlobalVariablesError}</span>
      </div></>)
}
      <TaxSlipDataInput
        id="taxableAmountOtherThanEligible"
        label="Taxable amount - other than eligible"
        boxNumber="32"
        defaultValue="0.00"
        readOnly={true}
      />
      <TaxSlipDataInput
        id="dividendTaxCreditOtherThanEligible"
        label="Dividend tax credit - other than eligible"
        boxNumber="39"
        defaultValue="0.00"
        readOnly={true}
      />
      <TaxSlipDataInput
        id="otherIncome"
        label=" Other income"
        boxNumber="26"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
            id="optionalTextToPrintOnTheSlip"
            label="Optional text to print on the slip"
            boxNumber=""
            optionalTextField={true}
            alert={true}
            titlePlacement={'top'}
            alertTitle="To print text at the top of this slip (e.g., 'Additional' or 'Suplicate'), enter it here. If this slip is amended or cancelled do not indicate this here. The requirements 
            of amended or cancelled slips are taken care of by setting the status of the slip."
          />
      <TaxSlipDataInput
            id="footNotes"
            label="Foot notes"
            boxNumber=""
            notesField={true}
          />

      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ mt: 2, p: 4, backgroundColor: "#f4f5f7" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold", pb: 1 }}>
            Other Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OtherInformationSetupFieldsPanel
            formMethods={formMethods}
            isLoading={isLoading}
            otherInformationList={otherInformationList}
            testId={testId}
            selectedOtherInformation={selectedOtherInformation}
            oiInEdit={oiInEdit}
            selectedProvinceOfEmployment={undefined} 
            globalVariablesData={undefined}            // filteredItemsOFOtherInformation={filteredItemsOFOtherInformation}     
            />
        </Grid>
      </Grid>
    </>
  );
};
