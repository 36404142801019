import { BillingSlabsInput } from "components/BillingSlabLayout";
import { FC } from "react";

import {
  EnhancedRadioGroupRHFWithLabel,
  ITest,
  LabelPosition,
} from "@websential/cosmic";

interface IBillingProfileSlabsFieldsPanel extends ITest {}

export const BillingProfileSlabsFieldsPanel: FC<IBillingProfileSlabsFieldsPanel> =
  ({ testId }) => {
    return (
      <>
        <EnhancedRadioGroupRHFWithLabel
          name="chargesType"
          selectedValue="flat slab charges"
          key="chargesType"
          testId={testId}
          disabled={false}
          options={[
            {
              label: "Slab Charges",
              id: "slab-charges",
              value: "slab charges",
            },
            {
              label: "Flat Slab Charges",
              id: "flat-slab-charges",
              value: "flat slab charges",
            },
          ]}
          label="Apply Charges"
          labelPosition={LabelPosition.Left}
          // labelColumns={2}
          // fieldColumns={10}
        />
        <BillingSlabsInput id="t4SlipSlab" label="T4 Slips Slabs" defaultValue="1" readOnly={true} />
        <BillingSlabsInput id="t4SlipSlabCharges" label="T4 Charges" defaultValue="2.50" readOnly={false} />
        <BillingSlabsInput id="rl1SlipSlab" label="RL-1 Slips Slabs" defaultValue="1" readOnly={true} />
        <BillingSlabsInput id="rl1SlipSlabCharges" label="RL-1 Charges" defaultValue="2.50" readOnly={false} />
        <BillingSlabsInput id="t4aSlipSlab" label="T4A Slips Slabs" defaultValue={"1"} readOnly={true} />
        <BillingSlabsInput id="t4aSlipSlabCharges" label="T4A Charges" defaultValue="2.50" readOnly={false} />
        <BillingSlabsInput id="t5SlipSlab" label="T5 Slips Slabs" defaultValue="1" readOnly={true} />
        <BillingSlabsInput id="t5SlipSlabCharges" label="T5 Charges" defaultValue="2.50" readOnly={false} />
        <BillingSlabsInput id="rl3SlipSlab" label="RL-3 Slips Slabs" defaultValue="1" readOnly={true} />
        <BillingSlabsInput id="rl3SlipSlabCharges" label="RL-3 Charges" defaultValue="2.50" readOnly={false} />
        <BillingSlabsInput id="t3SlipSlab" label="T3 Slips Slabs" defaultValue="1" readOnly={true} />
        <BillingSlabsInput id="t3SlipSlabCharges" label="T3 Charges" defaultValue="2.50" readOnly={false} />
        <BillingSlabsInput id="rl16SlipSlab" label="RL-16 Slips Slabs" defaultValue="1" readOnly={true} />
        <BillingSlabsInput id="rl16SlipSlabCharges" label="RL-16 Charges" defaultValue="2.50" readOnly={false} />
      </>
    );
  };
