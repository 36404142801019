import { EnhancedDrawer } from "components/EnhancedDrawer";
import { Header } from "components/EnhancedDrawer/EnhancedDrawerPropData";
import { ProfileMenu } from "components/ProfileMenu";
import { RoleBasedLeftMenu } from "components/RoleBasedLeftMenu";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import "./LayoutStyleDrawerTransition.css"

import { Box, Toolbar } from "@mui/material";
import {
  // EnhancedAppBar,
  EnhancedBreadcrumb,
  IBreadcrumbItem,
} from "@websential/cosmic";

import { drawerWidth } from "../../constants";
import { IAppState } from "store/reducers";
import { EnhancedAppBar } from "./EnhancedAppBar";
import { MaskUnmask } from "./EnhancedAppBar/MaskUnmask";
import { baseURL } from "common/http-common";
import { RoleBasedTopMenuBar } from "components/RoleBasedTopMenuBar";
import { EnhancedAppBarFooter } from "./EnhancedAppBar/EnhancedAppBarFooter";

// import { BottomNavigation, LeftNavigation } from 'navigation';
// import { ProfileMenu } from 'components/ProfileMenu';
// import { useSelector } from 'react-redux';

export interface IAuthenticatedLayout {
  /**
   * Content to be displayed within the main content area
   */
  mainContents: React.ReactNode;
  /**
   * Drawer menu contents between header and footer
   */
  // menuContents: React.ReactNode,
  /**
   * Contents to be displayed in the drawer footer
   */
  drawerFooter?: React.ReactNode;
  /**
   * Page title icon to be displayed on the AppBar
   */
  pageTitleIcon?: React.ReactNode;
  /**
   * Page title to be displayed on the AppBar
   */
  pageTitle: string;
  /**
   * A react component containing links to the parent pages of current page
   * It should use the react-router link for navigation
   */
  breadcrumbLinks: IBreadcrumbItem[];
  /**
   * Currently opened page to be shown in the breadcrumb, it will not be clickable
   */
  breadcrumbCurrentPageName: string;
}
export const AuthenticatedLayout: React.FC<IAuthenticatedLayout> = ({
  drawerFooter,
  pageTitleIcon,
  pageTitle,
  breadcrumbLinks,
  breadcrumbCurrentPageName,
  mainContents,
}: IAuthenticatedLayout) => {
  const profileName = useSelector(
    (state: any) =>
      `${state.user.profile.firstName} ${state.user.profile.lastName}`
  );

  const adminEmail = useSelector(
    (state: IAppState) => state.user.profile.email
  );
  const AccountantId = useSelector((state : IAppState) => state.accountant.id);
  const AccountantName = useSelector((state : IAppState) => state.accountant.AccountantName);
  const clientName = useSelector((state : IAppState) => state.client.clientName);
  const clientId = useSelector((state : IAppState) => state.client.id);
  const homePathRoute = useSelector((state:IAppState) => state.HomePagePath.path)
  const drawerAction = useSelector((state:IAppState) => state.drawerAction.open)
  const stickyNavBar = useSelector((state : IAppState) => state.stickyNavBar.isSticky);
  console.log("adminEmail", adminEmail);
  console.log("drawerAction12", drawerAction);

  return (
    <Box sx={{ display: "flex" }}>
      <EnhancedAppBar
        // drawerWidth={String(drawerWidth)}
        drawerWidth={drawerAction ? "calc(100% - 240px)" : "100%"}
        title={pageTitle}
        toolbarMenuContent={<RoleBasedTopMenuBar />}
        toolbar={
          <ProfileMenu userProfileName={profileName} testId={"profile-menu"} />
        }
        titleIcon={pageTitleIcon}
        mask={
          <MaskUnmask
            // PostApiUrl={'http://192.168.1.4:8502/v1/masking-unmasking/'}
            PostApiUrl={`${baseURL}v1/masking-unmasking/`}
            UnsetMaskFlagTimeoutInMinutes={60}
            testId={"mask-unmask"}
            PutApiUrl={`${baseURL}v1/masking-unmasking/`}
            homePathRoute={homePathRoute}
          />
        }
        testId="appBar"
      />
      {/* {drawerAction ? 
      <> */}
      <Box className={` ${drawerAction ? 'transition' : 'hidden'}`}>
      <EnhancedDrawer
      drawerHeader={<Header />}
      drawerContent={<RoleBasedLeftMenu />}
      drawerFooter={
        // adminEmail === "admin@taxslips.ca" ? "TaxSlips" : "TaxSlips"
        clientName && clientId ? clientName : 
        AccountantName && AccountantId ? AccountantName : 
        "TaxSlips"
      }
      testId="drawer"
      /> 
      </Box>
      {/* </>
      : 
      <></>  
    } */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#ebecf0",
          minHeight: "100vh",
          p: 1,
          pl: 3,
          pr: 3,
          pb:"45px", //height of footer
          // overflow: "hidden", //it effects sticky nav bar, it make non sticky nav
         // overflowX:"clip" //it show scroll on bottum full screen in merge resource and pier review etc
          // overflowX:"auto" //it effects sticky nav bar, it make non sticky nav 
          
        // so we are using here store property isSticky that set true on all setups which have sticky nav
        // and isSticky set false in reports like t4adjustmentreport which dont have nav bar means submit bar
        
        overflowX:stickyNavBar ? 'clip' : 'auto'
        }}
      >
        <Toolbar />
        <Toolbar
          sx={{
            paddingTop: { sm: 1, md: 1, lg: 1, xl: 1 },
            paddingLeft: { sm: 0, md: 0, lg: 0, xl: 0 },
            minHeight: { sm: "48px", md: "48px", lg: "48px", xl: "48px" },
          }}
        >
          <EnhancedBreadcrumb
            paths={breadcrumbLinks}
            testId="authenticated-layout-breadcrumb" 
            homePath={homePathRoute}         
            />
        </Toolbar>
        <Box>
          {mainContents}
          {/* <Outlet /> */}
        </Box>
      </Box>
      <EnhancedAppBarFooter
        drawerWidth={drawerAction ? "calc(100% - 240px)" : "100%"}
        footerMessage="© 2015-24 Hatchbox Inc. All Rights Reserved." testId={"EnhancedAppBarFooter"}      />
    </Box>
  );
};
