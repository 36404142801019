import { FLEX_FIELDS_SETUP_SCREEN_SLUG, INITIAL_SNACKBAR_DATA, QUERY_OPTIONS } from "common/constants";
import { instanceOfIFlexFieldSetupRequest } from "common/instance-method";
import { IResponse, IServerError } from "models/common";
import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FlexFieldService } from "services/FlexFieldService";

import { yupResolver } from "@hookform/resolvers/yup";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  ITest,
  LabelPosition,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  TwoColumnLayout,
} from "@websential/cosmic";

import { ComparisonsPanel, CustomFieldsPanel } from "./Panels";
import RightPanel from "./Panels/RightPanel";
import { ValidationsPanel } from "./Panels/ValidationsPanel";
import { getValidationSchema } from "./validator";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IFlexFieldSetup extends ITest {}

const DEFAULT_VALUES = {
  appFieldLabel: "",
  comparison: [
    {
      operator: undefined,
      field: undefined,
    },
  ],
  dataType: "string",
  defaultValue: "",
  displayMasked: false,
  fieldColumn: 8,
  fieldFormat: "",
  fieldInternalValue: "",
  fieldLabel: "",
  fieldType: "TextField",
  fieldValue: "",
  id: undefined,
  isCategory: false,
  isFixed: false,
  isHide: false,
  isRequired: false,
  labelColumn: 4,
  labelPosition: "left",
  maxValue: 0,
  minValue: 0,
  notes: "",
  parentRelation: undefined,
  parentValue: "",
  parent: undefined,
  placeholder: "",
  readOnly: false,
  uppercase: false,
};

const FLEX_FIELD_DATA_QUERY_KEY = "get-flex-field-data";
const LIST_FIELDS_QUERY_KEY = "get-flex-fields-list";

const useGetFlexFieldData = (flexFieldId: number) => {
  console.log("$$$$$", flexFieldId);
  return useQuery(
    [FLEX_FIELD_DATA_QUERY_KEY, flexFieldId],
    () => FlexFieldService.getFlexFieldById(flexFieldId),
    { ...QUERY_OPTIONS, enabled: !!flexFieldId }
  );
};
const useGetFlexFieldList = () =>
  useQuery(
    LIST_FIELDS_QUERY_KEY,
    () => FlexFieldService.getFlexFieldDropDownList("none"),
    QUERY_OPTIONS
  );

export const FlexFieldSetup: FC<IFlexFieldSetup> = ({ testId }) => {
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { isLoading: isSubmitting, mutate: submitFlexField } = useMutation(
    FlexFieldService.submitFlexField,
    {
      onSuccess: (res: IFlexFieldSetupRequest | IServerError | IResponse) => {
        if (instanceOfIFlexFieldSetupRequest(res)) {
          // remove the cache of the flex fields since we just added a new field.
          queryClient.invalidateQueries(LIST_FIELDS_QUERY_KEY);
          // [corner case] remove the cache of the field that has just been edited in-case the user re-selects it again
          queryClient.invalidateQueries([FLEX_FIELD_DATA_QUERY_KEY, res.id]);
          // set the currently added/edited flex field as the selected field
          formMethods.setValue("id", res.id);
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );
  const { isLoading: isLoadingFlexFieldList, data: flexFieldList } =
    useGetFlexFieldList();
  const validationSchema = useMemo(
    () =>
      getValidationSchema(
        flexFieldList && Array.isArray(flexFieldList)
          ? flexFieldList.map((flexField: any) => ({
              id: flexField.id,
              value: flexField.appFieldLabel,
            }))
          : []
      ),
    [flexFieldList]
  );
  const formMethods = useForm<IFlexFieldSetupRequest>({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  const [selectedFlexFieldId, selectedParent] = useWatch({
    control: formMethods.control,
    name: ["id", "parent"],
  });
  const { isLoading: isLoadingSelectedFlexField, data: selectedFlexFieldData } =
    useGetFlexFieldData(selectedFlexFieldId as number);

  /**
   * Handle when the form is submitted.
   *
   * @param {IFlexFieldSetupRequest} formData
   */
  const onSubmit = (formData: IFlexFieldSetupRequest) => {
    submitFlexField(formData);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset(DEFAULT_VALUES);
  };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const flexFieldItems = useMemo(() => {
    if (flexFieldList && Array.isArray(flexFieldList)) {
      return flexFieldList.map((flexField: any) => ({
        label: flexField.label,
        value: flexField.value,
      }));
    }

    // Return empty array in case of API data fetching error
    return [];
  }, [flexFieldList]);

  // set the values of the selected flex field coming from BE.
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (selectedFlexFieldData) {
      Object.keys(selectedFlexFieldData).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = selectedFlexFieldData[fieldName];
        // @ts-ignore
        formMethods.setValue(fieldName, fieldValue ?? undefined);
      });
    }
  }, [selectedFlexFieldData, formMethods.setValue, formMethods]);

  // if we have selected the parent and a flex field and they both are the same, we should un-set the parent because a field cannot be the parent of itself
  useEffect(() => {
    if (
      selectedFlexFieldId &&
      selectedParent &&
      selectedParent === selectedFlexFieldId
    ) {
      formMethods.setValue("parent", undefined);
    }
  }, [formMethods, selectedFlexFieldId, selectedParent]);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: FLEX_FIELDS_SETUP_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          isLoadingSelectedFlexField || isSubmitting || isLoadingFlexFieldList
        }
        data-testid={`${testId}-backdrop`}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <TwoColumnLayout
            toolbar={
              <StandardSubmitFormButtonsToolbar
                onResetClicked={onResetClicked}
                isSubmitting={isSubmitting}
                testId={testId}
              />
            }
            leftColumnContent={
              <>
                <StandardPanel
                  title="Flex Fields"
                  testId="flex-field-setup-flex-field-list-panel"
                >
                  <EnhancedDropDownRHFWithLabel
                    selectName="id"
                    label="Load Fields"
                    labelPosition={LabelPosition.Left}
                    items={flexFieldItems}
                    testId="flex-field-setup-flex-field-list"
                    isLoading={isLoadingFlexFieldList}
                    // onSelectionChangeHandler={}
                  />
                  {/* <FieldsPanel
                    isLoading={isLoadingFlexFieldList}
                    flexFieldsList={flexFieldItems}
                    testId={testId}
                  /> */}
                </StandardPanel>
                <div style={{ paddingBottom: "8rem" }}>
                  <StandardPanel
                    title="Custom Fields"
                    testId={`${testId}-custom-fields`}
                    topPadding={2}
                  >
                    <CustomFieldsPanel testId={testId} />
                  </StandardPanel>
                </div>
              </>
            }
            rightColumnContent={
              <>
                <StandardPanel
                  title="Custom Fields"
                  testId={`${testId}-custom-fields`}
                  showTitle={false}
                >
                  <RightPanel
                    testId={testId}
                    isLoading={isLoadingFlexFieldList}
                    flexFieldsList={flexFieldItems}
                    selectedFlexFieldId={selectedFlexFieldId as number}
                  />
                </StandardPanel>
                <StandardPanel
                  title="Validations"
                  testId={`${testId}-validations`}
                  topPadding={2}
                >
                  <ValidationsPanel testId={testId} />
                </StandardPanel>
                <StandardPanel
                  title="Comparisons"
                  testId={`${testId}-comparisons`}
                  topPadding={2}
                >
                  <ComparisonsPanel
                    formMethods={formMethods}
                    isLoading={isLoadingFlexFieldList}
                    flexFieldsList={flexFieldItems}
                    testId={testId}
                  />
                </StandardPanel>
              </>
            }
            testId="flex-field-setup-form"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
