import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { RL1AdjustmentReport } from "components/Adjustments/RL1AdjustmentReport";

export const Rl1AdjustmentReportPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="RL1 Adjustment Report"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<RL1AdjustmentReport/>}
    />
  );
};
