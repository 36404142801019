import { CATEGORY_SETUP_SCREEN_SLUG, INITIAL_SNACKBAR_DATA, QUERY_OPTIONS } from "common/constants";
import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import {
  IResponse,
  IServerError,
  ITaxSlipCategoryRequestPayload,
} from "models";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TaxSlipCategoryService } from "services";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BackdropCircularProgress,
  EnhancedIOSSwitchRHFWithLabel,
  EnhancedSnackbar,
  ITest,
  LabelPosition,
  RHookFormTextFieldWithLabel,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
} from "@websential/cosmic";

import { TaxSlipCategorySchema } from "./validator";
import { useNavigate, useParams } from "react-router-dom";
import { instanceOfITaxslipCategoryResponce } from "common/instance-method";
import { Grid } from "@mui/material";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch } from "react-redux";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface ITaxSlipCategorySetup extends ITest {}

// for edit
const TAXSLIP_CATEGORY_LIST_DATA_QUERY_KEY = "get-taxslip-category-data";

const useGetData = (id: string | undefined) => {
  return useQuery(
    TAXSLIP_CATEGORY_LIST_DATA_QUERY_KEY,
    () => TaxSlipCategoryService.getById(id),
    QUERY_OPTIONS
  );
};

const DEFAULT_VALUES = {
  name: "",
};

export const TaxSlipCategorySetup: FC<ITaxSlipCategorySetup> = ({ testId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  // for edit
  const queryClient = useQueryClient();
  const { id } = useParams();
  console.log("id from setup file which clicked : ", id);

  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    TaxSlipCategoryService.create,
    {
      onSuccess: (
        res: ITaxSlipCategoryRequestPayload | IServerError | IResponse
      ) => {
        if (instanceOfITaxslipCategoryResponce(res)) {
          queryClient.invalidateQueries([
            TAXSLIP_CATEGORY_LIST_DATA_QUERY_KEY,
            res.id,
          ]);
          setSnackbarData({
            open: true,
            message: "Taxslip category has been added successfully",
            severity: "success",
          });
        }
        let path = "/taxslip-categories/list";
        navigate(path);
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "An error occurred, please try again",
          severity: "error",
        });
      },
    }
  );

  // for edit
  const { isLoading: isLoadingData, data: selectedTaxslipCategoryData } =
    useGetData(id);

  const formMethods = useForm<ITaxSlipCategoryRequestPayload>({
    resolver: yupResolver(TaxSlipCategorySchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = (formData: ITaxSlipCategoryRequestPayload) => {
    submitFormData(formData);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset(DEFAULT_VALUES);
  };

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // for edit
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (selectedTaxslipCategoryData) {
      Object.keys(selectedTaxslipCategoryData).forEach((fieldName) => {
        console.log("fieldName useEffect TC : ", fieldName);
        // @ts-ignore
        const fieldValue = selectedTaxslipCategoryData[fieldName];
        console.log("fieldValue useEffect TC : ", fieldValue);
        // @ts-ignore
        formMethods.setValue(fieldName, fieldValue ?? undefined);
      });
    }
  }, [selectedTaxslipCategoryData, formMethods.setValue, formMethods]);


  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: CATEGORY_SETUP_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={isSubmitting || isLoadingData}
        testId={`${testId}-backdrop`}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            children={
              <StandardPanel
                title="TaxSlip Category"
                testId={`${testId}-taxslip-category`}
              >
                <RHookFormTextFieldWithLabel
                  id="name"
                  testId={`${testId}-name`}
                  label="Name"
                  labelPosition={LabelPosition.Left}
                  required
                />
                <FieldWrapper>
                  <EnhancedIOSSwitchRHFWithLabel
                    name="active"
                    checked={true}
                    testId="switch"
                    label="Active"
                    labelPosition={LabelPosition.Left}
                  />
                </FieldWrapper>
              </StandardPanel>
            }
            toolbar={
              <StandardSubmitFormButtonsToolbar
                onResetClicked={onResetClicked}
                isSubmitting={false}
                testId="standard-form-submit-toolbar"
              />
            }
            testId="form-layout"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
