import { AxiosResponse } from "axios";
import { IResponse, IServerError } from "models";

import http from "../common/http-common";
export interface IActivityLogRequest { 
  entityId1:string;
  entityId2:string;
  entityId3:string;
  screenId: string;
  variableValue:string;
  activityType:string;
}
export const ActivitylogServices = {
  getActivityLogData: async (
    requestPayload: IActivityLogRequest
  ): Promise<
    IServerError | IResponse | any
  > => {
    console.log('requestPayload00',requestPayload)
    const response: AxiosResponse=
      await http.get(
        // `/v1/activity-log/activty-log-for-screen/?entity_id_1=${requestPayload.entityId}&list_screen=false&screen_id=${requestPayload.screenId}&variable_value=${requestPayload.variableValue}&${requestPayload.activityType}`
        `/v1/activity-log/activty-log-for-screen/?entity_id_1=${requestPayload.entityId1}&entity_id_2=${requestPayload.entityId2}&entity_id_3=${requestPayload.entityId3}&slug=${requestPayload.screenId}&variable_value=${requestPayload.variableValue}&${requestPayload.activityType}`
      );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
};