import { LeftMenu } from "components/LeftMenu";

const flexFieldsLeftMenu = [
  {
    id: 601,
    text: "Fields",
    open: false,
    menu: [
      {
        id: 611,
        text: "List",
        parentMenuId: 601,
        grandParentMenuId: 600,
        navigateToURL: "/flex-fields/list"
      },
      {
        id: 612,
        text: "Setup",
        parentMenuId: 601,
        grandParentMenuId: 600,
        navigateToURL: "/flex-fields/setup"
      },
    ],
    parentMenuId: 600,
  },
  {
    id: 620,
    text: "Tabs",
    open: false,
    menu: [
      {
        id: 621,
        text: "List",
        parentMenuId: 620,
        grandParentMenuId: 600,
        navigateToURL: "/flex-field-tabs/list"
      },
      {
        id: 622,
        text: "Setup",
        parentMenuId: 620,
        grandParentMenuId: 600,
        navigateToURL: "/flex-field-tabs/setup"
      },
    ],
    parentMenuId: 600,
  },
  {
    id: 630,
    text: "Panels",
    open: false,
    menu: [
      {
        id: 631,
        text: "List",
        parentMenuId: 630,
        grandParentMenuId: 600,
        navigateToURL: "/flex-field-panels/list"
      },
      {
        id: 632,
        text: "Setup",
        parentMenuId: 630,
        grandParentMenuId: 600,
        navigateToURL: "/flex-field-panels/setup"
      },
    ],
    parentMenuId: 600,
  },
  {
    id: 640,
    text: "Assign Field to Panel",
    open: false,
    parentMenuId: 600,
    navigateToURL: "/flex-field-panel-assignment"
  },
  {
    id: 650,
    text: "Field Set",
    open: false,
    parentMenuId: 600,
    navigateToURL: "/flex-field-screen-setup"
  },
];

export const FlexFieldMenu = () => {
  return <LeftMenu menuItems={flexFieldsLeftMenu} />;
};
