import { HOME_PAGE } from "store/types";

export const setSelectedHomePage = (selectHomePage: any) => {
  return {
    type: HOME_PAGE.HOME_PAGE_SET_SELECTED,
    payload: {...selectHomePage}
  }
}

export const clearSelectedHomePage = () => {
  return {
    type: HOME_PAGE.HOME_PAGE_CLEAR_SELECTED
  }
}
