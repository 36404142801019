import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IT5SLipAxisPage1 {}
const T5SLipAxisPage1:React.FC<IT5SLipAxisPage1> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP1X'} 
                testId={'taxYearP1X'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'box24P1X'} 
                testId={'box24P1X'} 
                label={'Box 24'} 
                labelPosition={LabelPosition.Left}
                defaultValue={80}
            />
            <RHookFormTextFieldWithLabel 
                id={'box25P1X'} 
                testId={'box25P1X'} 
                label={'Box 25'} 
                labelPosition={LabelPosition.Left}
                defaultValue={185}
            />
            <RHookFormTextFieldWithLabel 
                id={'box26P1X'} 
                testId={'box26P1X'} 
                label={'Box 26'} 
                labelPosition={LabelPosition.Left}
                defaultValue={290}
            />
            <RHookFormTextFieldWithLabel 
                id={'box13P1X'} 
                testId={'box13P1X'} 
                label={'Box 13'} 
                labelPosition={LabelPosition.Left}
                defaultValue={395}
            />
            <RHookFormTextFieldWithLabel 
                id={'box18P1X'} 
                testId={'box18P1X'} 
                label={'Box 18'} 
                labelPosition={LabelPosition.Left}
                defaultValue={500}
            />
            <RHookFormTextFieldWithLabel 
                id={'box10P1X'} 
                testId={'box10P1X'} 
                label={'Box 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={80}
            />
            <RHookFormTextFieldWithLabel 
                id={'box11P1X'} 
                testId={'box11P1X'} 
                label={'Box 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={185}
            />
            <RHookFormTextFieldWithLabel 
                id={'box12P1X'} 
                testId={'box12P1X'} 
                label={'Box 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={290}
            />
            <RHookFormTextFieldWithLabel 
                id={'box21P1X'} 
                testId={'box21P1X'} 
                label={'Box 21'} 
                labelPosition={LabelPosition.Left}
                defaultValue={385}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22P1X'} 
                testId={'box22P1X'} 
                label={'Box 22'} 
                labelPosition={LabelPosition.Left}
                defaultValue={440}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22P1BsX'} 
                testId={'box22P1BsX'} 
                label={'Box 22 B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={445}
            />
            <RHookFormTextFieldWithLabel 
                id={'box23P1X'} 
                testId={'box23P1X'} 
                label={'Box 23'} 
                labelPosition={LabelPosition.Left}
                defaultValue={535}
            />
            <RHookFormTextFieldWithLabel 
                id={'box27P1X'} 
                testId={'box27P1X'} 
                label={'Box 27'} 
                labelPosition={LabelPosition.Left}
                defaultValue={190}
            />
            <RHookFormTextFieldWithLabel 
                id={'box28P1X'} 
                testId={'box28P1X'} 
                label={'Box 28'} 
                labelPosition={LabelPosition.Left}
                defaultValue={245}
            />
            <RHookFormTextFieldWithLabel 
                id={'box29P1X'} 
                testId={'box29P1X'} 
                label={'Box 29'} 
                labelPosition={LabelPosition.Left}
                defaultValue={345}
            />
            <RHookFormTextFieldWithLabel 
                id={'case1P1X'} 
                testId={'case1P1X'} 
                label={'Case 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={155}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase1P1X'} 
                testId={'amountCase1P1X'} 
                label={'Amount Case 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={205}
            />
            <RHookFormTextFieldWithLabel 
                id={'case2P1X'} 
                testId={'case2P1X'} 
                label={'Case 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={300}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase2P1X'} 
                testId={'amountCase2P1X'} 
                label={'Amount Case 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={350}
            />
            <RHookFormTextFieldWithLabel 
                id={'case3P1X'} 
                testId={'case3P1X'} 
                label={'Case 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={450}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase3P1X'} 
                testId={'amountCase3P1X'} 
                label={'Amount Case 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={500}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientNameP1X'} 
                testId={'recipientNameP1X'} 
                label={'Recipient Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientLine1NameP1X'} 
                testId={'recipientLine1NameP1X'} 
                label={'Recipient Line 1 Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeNameP1X'} 
                testId={'payeeNameP1X'} 
                label={'Payee Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={330}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeAddressP1X'} 
                testId={'payeeAddressP1X'} 
                label={'Payee Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={330}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeCityP1X'} 
                testId={'payeeCityP1X'} 
                label={'Payee City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={330}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientInitialP1X'} 
                testId={'recipientInitialP1X'} 
                label={'Recipient Initial'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientAddressP1X'} 
                testId={'recipientAddressP1X'} 
                label={'Recipient Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientCityP1X'} 
                testId={'recipientCityP1X'} 
                label={'Recipient City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={58}
            />
            <RHookFormTextFieldWithLabel 
                id={'pageP1X'} 
                testId={'pageP1X'} 
                label={'Page'} 
                labelPosition={LabelPosition.Left}
                defaultValue={585}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP1Y'} 
                testId={'taxYearP1Y'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={973}
            />
            <RHookFormTextFieldWithLabel 
                id={'box24P1Y'} 
                testId={'box24P1Y'} 
                label={'Box 24'} 
                labelPosition={LabelPosition.Left}
                defaultValue={940}
            />
            <RHookFormTextFieldWithLabel 
                id={'box25P1Y'} 
                testId={'box25P1Y'} 
                label={'Box 25'} 
                labelPosition={LabelPosition.Left}
                defaultValue={940}
            />
            <RHookFormTextFieldWithLabel 
                id={'box26P1Y'} 
                testId={'box26P1Y'} 
                label={'Box 26'} 
                labelPosition={LabelPosition.Left}
                defaultValue={940}
            />
            <RHookFormTextFieldWithLabel 
                id={'box13P1Y'} 
                testId={'box13P1Y'} 
                label={'Box 13'} 
                labelPosition={LabelPosition.Left}
                defaultValue={940}
            />
            <RHookFormTextFieldWithLabel 
                id={'box18P1Y'} 
                testId={'box18P1Y'} 
                label={'Box 18'} 
                labelPosition={LabelPosition.Left}
                defaultValue={940}
            />
            <RHookFormTextFieldWithLabel 
                id={'box10P1Y'} 
                testId={'box10P1Y'} 
                label={'Box 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={903}
            />
            <RHookFormTextFieldWithLabel 
                id={'box11P1Y'} 
                testId={'box11P1Y'} 
                label={'Box 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={903}
            />
            <RHookFormTextFieldWithLabel 
                id={'box12P1Y'} 
                testId={'box12P1Y'} 
                label={'Box 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={903}
            />
            <RHookFormTextFieldWithLabel 
                id={'box21P1Y'} 
                testId={'box21P1Y'} 
                label={'Box 21'} 
                labelPosition={LabelPosition.Left}
                defaultValue={903}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22P1Y'} 
                testId={'box22P1Y'} 
                label={'Box 22'} 
                labelPosition={LabelPosition.Left}
                defaultValue={903}
            />
            <RHookFormTextFieldWithLabel 
                id={'box22P1BsY'} 
                testId={'box22P1BsY'} 
                label={'Box 22 B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={903}
            />
            <RHookFormTextFieldWithLabel 
                id={'box23P1Y'} 
                testId={'box23P1Y'} 
                label={'Box 23'} 
                labelPosition={LabelPosition.Left}
                defaultValue={903}
            />
            <RHookFormTextFieldWithLabel 
                id={'box27P1Y'} 
                testId={'box27P1Y'} 
                label={'Box 27'} 
                labelPosition={LabelPosition.Left}
                defaultValue={751}
            />
            <RHookFormTextFieldWithLabel 
                id={'box28P1Y'} 
                testId={'box28P1Y'} 
                label={'Box 28'} 
                labelPosition={LabelPosition.Left}
                defaultValue={751}
            />
            <RHookFormTextFieldWithLabel 
                id={'box29P1Y'} 
                testId={'box29P1Y'} 
                label={'Box 29'} 
                labelPosition={LabelPosition.Left}
                defaultValue={751}
            />
            <RHookFormTextFieldWithLabel 
                id={'case1P1Y'} 
                testId={'case1P1Y'} 
                label={'Case 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={867}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase1P1Y'} 
                testId={'amountCase1P1Y'} 
                label={'Amount Case 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={867}
            />
            <RHookFormTextFieldWithLabel 
                id={'case2P1Y'} 
                testId={'case2P1Y'} 
                label={'Case 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={867}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase2P1Y'} 
                testId={'amountCase2P1Y'} 
                label={'Amount Case 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={867}
            />
            <RHookFormTextFieldWithLabel 
                id={'case3P1Y'} 
                testId={'case3P1Y'} 
                label={'Case 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={867}
            />
            <RHookFormTextFieldWithLabel 
                id={'amountCase3P1Y'} 
                testId={'amountCase3P1Y'} 
                label={'Amount Case 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={867}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientNameP1Y'} 
                testId={'recipientNameP1Y'} 
                label={'Recipient Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={820}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientLine1NameP1Y'} 
                testId={'recipientLine1NameP1Y'} 
                label={'Recipient Line 1 Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={805}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeNameP1Y'} 
                testId={'payeeNameP1Y'} 
                label={'Payee Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={820}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeAddressP1Y'} 
                testId={'payeeAddressP1Y'} 
                label={'Payee Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={805}
            />
            <RHookFormTextFieldWithLabel 
                id={'payeeCityP1Y'} 
                testId={'payeeCityP1Y'} 
                label={'Payee City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={790}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientInitialP1Y'} 
                testId={'recipientInitialP1Y'} 
                label={'Recipient Initial'} 
                labelPosition={LabelPosition.Left}
                defaultValue={820}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientAddressP1Y'} 
                testId={'recipientAddressP1Y'} 
                label={'Recipient Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={790}
            />
            <RHookFormTextFieldWithLabel 
                id={'recipientCityP1Y'} 
                testId={'recipientCityP1Y'} 
                label={'Recipient City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={775}
            />
            <RHookFormTextFieldWithLabel 
                id={'pageP1Y'} 
                testId={'pageP1Y'} 
                label={'Page'} 
                labelPosition={LabelPosition.Left}
                defaultValue={722}
            />
        </Grid>
    </Grid>
  )
}

export default T5SLipAxisPage1