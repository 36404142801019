import React, { useState, useRef, useEffect, useCallback } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import "./RL1Reportstyles.css"
import "./RL1ReportstylesAbove2023.css"
import { GridRenderCellParams } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { RequestParams } from '@websential/cosmic';
import { useSelector } from 'react-redux';
import { IAppState } from 'store/reducers';

export interface IDataTable {
    rows: any;
    columns:any;
    last_rows:any;
    showIndexColumn:boolean;
    showChecboxColumn: boolean,
    showSettingColumn: boolean,
    onRowEditClicked:(params: GridRenderCellParams) => void;
    onRowDeleteClicked:(params: GridRenderCellParams) => void;
    isLoading?: boolean;
    onRequestParamsChange?: (params: RequestParams) => void;
    initialPageSize?: number | undefined;
    initialPage?: number;
    totalRecords?: number;
}

const PrimeDataGrid: React.FC<IDataTable> = ({
    rows,
    columns,
    last_rows,
    showIndexColumn,
    showChecboxColumn,
    showSettingColumn,
    onRowEditClicked,
    onRowDeleteClicked,
    onRequestParamsChange,
    initialPageSize = 10,
    initialPage = 0,
    totalRecords,
    isLoading
}) => {
    const taxYear  = useSelector((state: IAppState) => state.taxYear.year);
    const isInitialRequest = useRef(true);
    const [loading, setLoading] = useState(false);
    const [itemSearch, setItemSearch] = useState('');
    const [requestParams, setRequestParams] = useState<any>({
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        sortField: null,
        sortOrder: "asc",
        searchQuery:"",
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

    const [val, setValue] = useState(false)
    const [field, setField] = useState("")
    const setting = () => {
        return(
            <div className='ml-4 pl-1'>
                <i className='pi pi-cog text-xl'></i>
            </div>
        )
    }
    const delEdit = (params:any) => {
        return(
            <div className='flex'>
                <Button icon="pi pi-user-edit" onClick={() => onRowEditClicked(params)} className="p-button-rounded p-button-text w-2rem h-2rem"/>
                <Button icon="pi pi-trash" onClick={() => onRowDeleteClicked(params)} className="p-button-rounded p-button-text w-2rem h-2rem ml-2"/>
            </div>
        )
    }

     const maskingCol = (val:any,hName:any) => {
        if(val == true){
            return "*****"
        }else{
            return hName
        }
     }
     const onSearchButtonClicked = () => {
        setLoading(true)
        setRequestParams((prevParams:any) => ({
          ...prevParams,
          first: initialPage,
          rows: initialPageSize,
          page: initialPage,
          searchQuery: itemSearch,
        }));
        setTimeout(() => { 
          setLoading(false);
        }, 1000);
      }
      const handleClearSearch = () => {
        setLoading(true)
        setItemSearch('')
        setRequestParams((prevParams:any) => ({
          ...prevParams,
          first: initialPage,
          rows: initialPageSize,
          page: initialPage,
          searchQuery: "",
        }));
        setTimeout(() => { 
          setLoading(false);
        }, 1000);
      }
// Search type and billing profile Button
    const renderHeader = () => {
        return (
            // <div className='flex justify-content-between p-4 pl-0'  style={{backgroundColor:"#ebecf0",margin:"-17px"}}>
            <span className="p-input-icon-left w-5" style={{display:"flex"}}>
                <i className="pi pi-search" />
                <InputText value={itemSearch} onChange={(e) => setItemSearch(e.target.value)} placeholder="Search by Employee Name" className='w-full' style={{borderRadius:"5px 0px 0px 5px"}}/>
                {itemSearch && (
                    <Button
                    icon="pi pi-times"
                    onClick={handleClearSearch}
                    className="p-button-warning"
                    style={{borderRadius:0}}
                    />
                )}
                <Button
                    onClick={onSearchButtonClicked}
                    // variant="contained"
                    style={{
                    padding:"8px 10px 8px 10px",
                    marginRight:"-14px!important",
                    borderRadius:"0px 5px 5px 0px"}}
                >
                    <SearchIcon />
                </Button>
            </span>
            // </div>
            )
    }
    const headerSearch = renderHeader();
    const indexColumn = {
        body: (rowData:any, row:any) => {
          return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
        },
        header: '#',
      };

      console.log("props headerColumns => ",columns)
      console.log("props items RL1AdjustmentReport => ",rows)
      console.log("props items RL1AdjustmentReport last rows => ",last_rows)

      const headerGroup = (
        <ColumnGroup>
          <Row className="row1">
            <Column header="" colSpan={1} style={{cursor:"auto",position: "sticky",left: 0,zIndex:1,backgroundColor:"#e3fcef"}}/>
            {/* <Column header="Employee Info" colSpan={2}  frozen={true} style={{paddingLeft:"4rem"}}/> */}
            <Column header="Employee Info" colSpan={1} className='align-header-group-column-text' style={{position: "sticky",left: "0px",zIndex:1,backgroundColor:"#e3fcef"}}/>
            <Column header="Slip Info" colSpan={2} className='align-header-group-column-text' style={{cursor:"auto",backgroundColor:"#e3fcef"}}/>
            <Column header="Canadian Pension Plan" colSpan={taxYear > 2023 ? 6 : 3} className='align-header-group-column-text' style={{cursor:"auto",backgroundColor:"#fffae6"}}/>
            <Column header="Employment Insurance" colSpan={3} className='align-header-group-column-text' style={{cursor:"auto",backgroundColor:"#ffebe6"}}/>
            <Column header="Income Tax" colSpan={4} className='align-header-group-column-text' style={{cursor:"auto",backgroundColor:"#deebff"}}/>
            <Column header="" colSpan={1} className='align-header-group-column-text' style={{cursor:"auto"}}/>
          </Row>
          <Row className="row2">
            <Column selectionMode="multiple" hidden={showChecboxColumn} frozen resizeable={false}
                style={{maxWidth:"65px"}} field="selection" columnKey='selection'></Column>
                <Column 
                    {...indexColumn} 
                    frozen
                    hidden={!showIndexColumn}
                    className='indexColumn'
                    />
            {columns !== undefined  &&  columns.map((x:any,index:any) => (
                    <Column
                        field={x.field}
                        header={x.headerName && maskingCol(x.maskable,x.headerName)}
                        headerTooltip={x.description}
                        headerTooltipOptions={{ position: 'top' }}
                        // flex (This property is not use Yet)
                        // hidden={val === x.hide ? clickHideBtn(x, x.field, false) : x.field === field ? val: x.hide }
                        sortable={x.sortable}
                        resizeable={x.resizable}
                        dataType={x.type}
                        align={x.align}
                        alignHeader={x.headerAlign}
                        // hideSortIcons (This property is not use Yet)
                        // filterElement={HideButton(x.hideable, x, x.field)}
                        // showFilterMenuOptions
                        // filter
                        // showFilterMenu={x.disableColumnMenu}
                        // filterPlaceholder="Search by name"
                        reorderable={x.disableReorder}
                        exportable={x.disableExport}
                        frozen={x.frozenColumn}
                        columnKey={x.field}
                        style={{
                            // minWidth:x.minWidth,
                                // fontSize:"0.875rem",
                                whiteSpace: 'pre-line'
                               }}
                        />
                ))}
          </Row>
        </ColumnGroup>

      );
const updatedItemsResponse = rows && rows?.map((item:any) => ({
    ...item,
    adjusted: item.adjusted ? "Yes" : "No",
    employmentIncome: item.employmentIncome !== undefined ? parseFloat(item.employmentIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    qppEntered: item.qppEntered !== undefined ? parseFloat(item.qppEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    secondQppEntered: item.secondQppEntered !== undefined && item.secondQppEntered !== null ? parseFloat(item.secondQppEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    qppAdjusted: item.qppAdjusted !== undefined ? parseFloat(item.qppAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    secondQppAdjusted: item.secondQppAdjusted !== undefined && item.secondQppAdjusted !== null ? parseFloat(item.secondQppAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    qppDifference: item.qppDifference !== undefined ? parseFloat(item.qppDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    secondQppDifference: item.secondQppDifference !== undefined && item.secondQppDifference !== null ? parseFloat(item.secondQppDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    ppipEntered: item.ppipEntered !== undefined ? parseFloat(item.ppipEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    ppipAdjusted: item.ppipAdjusted !== undefined ? parseFloat(item.ppipAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    ppipDifference: item.ppipDifference !== undefined ? parseFloat(item.ppipDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    rl1TaxEntered: item.rl1TaxEntered !== undefined ? parseFloat(item.rl1TaxEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    rl1TaxCalculated: item.rl1TaxCalculated !== undefined ? parseFloat(item.rl1TaxCalculated).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    rl1TaxAdjusted: item.rl1TaxAdjusted !== undefined ? parseFloat(item.rl1TaxAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    rl1TaxDifference: item.rl1TaxDifference !== undefined ? parseFloat(item.rl1TaxDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    totalDifference: item.totalDifference !== undefined ? parseFloat(item.totalDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
}));
    
const updatedLastRowsResponse = last_rows && last_rows?.map((item:any) => ({
    ...item,
    employmentIncome: item.employmentIncome !== undefined ? parseFloat(item.employmentIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    qppEntered: item.qppEntered !== undefined ? parseFloat(item.qppEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    secondQppEntered: item.secondQppEntered !== undefined && item.secondQppEntered !== null ? parseFloat(item.secondQppEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    qppAdjusted: item.qppAdjusted !== undefined ? parseFloat(item.qppAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    secondQppAdjusted: item.secondQppAdjusted !== undefined && item.secondQppAdjusted !== null ? parseFloat(item.secondQppAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    qppDifference: item.qppDifference !== undefined ? parseFloat(item.qppDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    secondQppDifference: item.secondQppDifference !== undefined && item.secondQppDifference !== null ? parseFloat(item.secondQppDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    ppipEntered: item.ppipEntered !== undefined ? parseFloat(item.ppipEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    ppipAdjusted: item.ppipAdjusted !== undefined ? parseFloat(item.ppipAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    ppipDifference: item.ppipDifference !== undefined ? parseFloat(item.ppipDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    rl1TaxEntered: item.rl1TaxEntered !== undefined ? parseFloat(item.rl1TaxEntered).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    rl1TaxCalculated: item.rl1TaxCalculated !== undefined ? parseFloat(item.rl1TaxCalculated).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    rl1TaxAdjusted: item.rl1TaxAdjusted !== undefined ? parseFloat(item.rl1TaxAdjusted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    rl1TaxDifference: item.rl1TaxDifference !== undefined ? parseFloat(item.rl1TaxDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
    totalDifference: item.totalDifference !== undefined ? parseFloat(item.totalDifference).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "",
}));

const onPage = (event:any) => {
    // setRequestParams(event);
    setLoading(true)
    //imitate delay of a backend call
    setTimeout(() => {
    setRequestParams({
      ...event,
      searchQuery: itemSearch,
    });
    setLoading(false);
  }, 1000);
  };
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const onSort = useCallback((event: any) => {
    setLoading(true);

    let newSortOrder : any;
    if (event.sortField === sortField) {
        // Toggle sortOrder between 1 and -1
        newSortOrder = sortOrder === 1 ? -1 : 1;
        setSortOrder(newSortOrder);
    } else {
        // Set the new sortField and reset sortOrder to 1
        newSortOrder = 1;
        setSortField(event.sortField);
        setSortOrder(newSortOrder);
    }

    console.log("sortOrder 33 out", newSortOrder); // Use the newSortOrder value directly
    setRequestParams((prevParams: any) => ({
        ...prevParams,
        sortOrder: newSortOrder !== 1 ? "asc" : "desc",
        sortField: event.sortField,
    }));

    // Imitate delay of a backend call
    setTimeout(() => {
        setLoading(false);
    }, 1000);
}, [sortOrder, sortField]);
useEffect(() => {
    // if the results fetcher was passed, we can use it to fetch the results.
    if (onRequestParamsChange) {
      // skip the first initial call
      if (isInitialRequest.current === true) {
        console.log(
          "##### => useEffect - requestParams - no first call",
          requestParams
        );
        isInitialRequest.current = false;
      } else {
        console.log(
          "##### => useEffect - requestParams - second call",
          requestParams
        );
        onRequestParamsChange(requestParams);
      }
    }
  }, [onRequestParamsChange, requestParams]);
          console.log("updatedItemsResponse",updatedItemsResponse)
          console.log("updatedLastRowsResponse",updatedLastRowsResponse)
  return (
    <div>
       <DataTable
            value={updatedItemsResponse && updatedItemsResponse}
            // id="aaa"
            // responsiveLayout="scroll"
            lazy 
            filterDisplay="row"
            dataKey="id" 
            paginator
            first={requestParams.first} 
            rows={requestParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            onSort={onSort}
            // paginator
            // rows={25}
            rowsPerPageOptions={[25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={`Showing {first} to {last} of ${totalRecords} Items`}
            // selection={selectedItems} onSelectionChange={(e:any) => setSelectedItems(e.value)}
            editMode="row"
            showGridlines
            resizableColumns columnResizeMode="expand"
            scrollable
            reorderableColumns
            scrollHeight={"70vh"} 
            // reorderableColumns
            loading={isLoading ? isLoading : loading}
            // globalFilterFields={['headerName']}
            header={headerSearch}
            emptyMessage="No Record found."
            // className='m-2'
            className={taxYear > 2023 ? "myDataTableRL1Above2023" : "myDataTableRL1"}
            headerColumnGroup={headerGroup}
            frozenValue={last_rows && updatedLastRowsResponse}
            style={{margin:"0.5rem"}}
            >
                <Column selectionMode="multiple" hidden={showChecboxColumn} frozen resizeable={false}
                style={{maxWidth:"65px"}} field="selection" columnKey='selection'></Column>
                <Column 
                    {...indexColumn} 
                    frozen
                    hidden={!showIndexColumn}
                    className='indexColumn'
                    />
                {columns && columns.map((x:any,index:any) => (
                    <Column
                        key={index}
                        field={x.field}
                        header={x.headerName}
                        headerTooltip={x.description}
                        headerTooltipOptions={{ position: 'top' }}
                        // flex (This property is not use Yet)
                        // hidden={val === x.hide ? clickHideBtn(x, x.field, false) : x.field === field ? val: x.hide }
                        sortable={x.sortable}
                        // resizeable={x.resizable}
                        dataType={x.type}
                        align={x.align}
                        alignHeader={x.headerAlign}
                        // hideSortIcons (This property is not use Yet)
                        // filterElement={HideButton(x.hideable, x, x.field)}
                        // showFilterMenuOptions
                        // filter
                        // showFilterMenu={x.disableColumnMenu}
                        // filterPlaceholder="Search by name"
                        reorderable={x.disableReorder}
                        exportable={x.disableExport}
                        frozen={x.frozenColumn}
                        columnKey={x.field}
                        style={{
                            // minWidth:x.minWidth,
                                // fontSize:"0.875rem",
                                whiteSpace: 'pre-line'
                               }}
                        footer={x.footer}
                        body={rows.employmentIncome}
                        />
                    // </>
                ))}

                <Column body={delEdit} header={setting} style={{width:"110px",maxWidth:"110px"}} hidden={showSettingColumn}
                resizeable={false} reorderable={false} frozen alignFrozen='right'></Column>
            </DataTable>
    </div>
  )
}

export default PrimeDataGrid

//resizeablbe must be false if frozen is true and viseversa
