import { FC, useState } from "react";

import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Button, Toolbar, Tooltip } from "@mui/material";
import {
  DeleteButton,
  DisabledButton,
  EnhancedToolTip,
  ResetButton,
  SplitButton,
  SubmitButton,
} from "@websential/cosmic";
import { useQuery } from "react-query";
import { T4SlipService } from "services/T4SlipService";
import { IAppState } from "store/reducers";
import { useSelector } from "react-redux";
import { selectedSlipTypee } from "../T4SlipSetup";
import { selectedT4SummaryType } from "components/T4Slip/T4SlipSummarySetup";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PrintIcon from "@mui/icons-material/Print";
import { selectedT4SummaryReduceEIType } from "components/T4Slip/T4SlipSummaryReducedEISetup";

export interface IT4SlipSetupToolbar {
  onResetClicked: () => void;
  onDeleteClicked: () => void;
  onPrintClicked: (printType:string) => void;
  isSubmitting: boolean;
  formMethods: any
}

const T4_SUMMARY_DATA_QUERY_KEY = "get-t4-summary-data";
const useGetT4SummaryData = (
  clientid: string,
  slipType: string | number,
  taxYear: number) =>
  useQuery(
    [T4_SUMMARY_DATA_QUERY_KEY, clientid, slipType, taxYear],
    (data) => {
      return T4SlipService.getT4Summary(clientid, slipType, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

  const useGetT4SummaryReduceEIData = (
    clientid: string,
    slipType: string | number,
    taxYear: number) =>
    useQuery(
      [T4_SUMMARY_DATA_QUERY_KEY, clientid, slipType, taxYear],
      (data) => {
        return T4SlipService.getT4SummaryReduceEI(clientid, slipType, taxYear);
      },
      { refetchOnWindowFocus: false }
    );
export const T4SlipSetupToolbar: FC<IT4SlipSetupToolbar> = ({
  onResetClicked,
  onDeleteClicked,
  onPrintClicked,
  isSubmitting = false,
  formMethods
}) => {
  const id = useSelector((state: IAppState)=> state.client.id)
  const taxYear = useSelector((state: IAppState)=> state.taxYear.year)
  // const [selectedType, setSelectedType] = useState(400);
  const {
    isLoading: isLoadingSummaryGetData,
    data: t4SummaryData } =
  useGetT4SummaryData(id ,selectedT4SummaryType ,taxYear);
  const {
    isLoading: isLoadingSummaryReduceEIGetData,
    data: t4SummaryReduceEIData } =
    useGetT4SummaryReduceEIData(id ,selectedT4SummaryReduceEIType ,taxYear);
  // console.log("selectedType",selectedType)

  console.log("t4SummaryReduceEIData ============",t4SummaryReduceEIData)
  
  
  const onchangeTooltip = () => {
    //it have to optional from cosmic then remove it 
  }
  const selectedSlip = formMethods.getValues('selectedSlip')
  return (
    <Toolbar >
      <>
        <div style={{display:"contents"}}>
          <div style={{margin:"1rem 0rem"}}>
          {
          t4SummaryData && t4SummaryData.confirmationCode !== null 
          && t4SummaryReduceEIData && t4SummaryReduceEIData.confirmationCode !== null 
          && selectedSlipTypee === 400 ? 
          (
            <div style={{cursor:"not-allowed"}}>
              <DisabledButton 
                tooltiptitle={"Confirmation No. has been enterned in [Complaince -> t4 -> Summary]"} 
                isDisabled={true}
                buttonLabel={"Submit"}
                icon={<PlaylistAddCheckOutlinedIcon />}
                testId='disableButton' 
                onClick={onchangeTooltip} />
            </div>
          ):(
            <SubmitButton
              label="Submit"
              testId="submit-button"
              loading={isSubmitting}
              icon={<PlaylistAddCheckOutlinedIcon />}
              
            />
            )
          } 
          </div>
          <ResetButton testId="reset-button" onClickHandler={onResetClicked} />
          <div style={{margin:"1rem 0rem"}}>
          {t4SummaryData && t4SummaryData.confirmationCode !== null 
          && t4SummaryReduceEIData && t4SummaryReduceEIData.confirmationCode !== null 
          && selectedSlipTypee === 400 ? 
          (
            <div style={{cursor:"not-allowed",marginLeft:"1rem"}}>
              <DisabledButton 
                tooltiptitle={"Confirmation No. has been enterned in [Complaince -> t4 -> Summary]"} 
                isDisabled={true}
                buttonLabel={"Delete"}
                icon={<DeleteOutlineIcon />}
                testId='disableButton' 
                onClick={onchangeTooltip} />
            </div>
          ): selectedSlip && selectedSlip === 'create-new' ?
          (
            <div style={{cursor:"not-allowed",marginLeft:"1rem"}}>
              <DisabledButton 
                tooltiptitle={""} 
                isDisabled={true}
                buttonLabel={"Delete"}
                icon={<DeleteOutlineIcon />}
                testId='disableButton' 
                onClick={onchangeTooltip} />
            </div>
          ):
          (
            <DeleteButton
            testId="delete-button"
            onClickHandler={onDeleteClicked}
            />
            )
          } 
          </div>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div>
          {selectedSlip && selectedSlip === 'create-new' ?
          (
            <div style={{cursor:"not-allowed",marginLeft:"1rem"}}>
              <DisabledButton 
                tooltiptitle={""} 
                isDisabled={true}
                buttonLabel={"Print"}
                icon={<PrintIcon />}
                testId='disableButton' 
                onClick={onchangeTooltip} />
            </div>
          ):(
            <SplitButton
            options={[
              "Employee",
              "Employer",
              "CRA",
            ]}
            onClickHandler={onPrintClicked}
            testId="t4-print-button"
            icon={<PrintIcon />}
            listItemsLabel={`Print T4 \n Slip`}
          />
          )}
        </div>
      </>
    </Toolbar>
  );
};
