import { FC, useState } from "react";

import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Toolbar } from "@mui/material";
import {
  DeleteButton,
  DisabledButton,
  ResetButton,
  SplitButton,
  SubmitButton,
  PrintButton
} from "@websential/cosmic";
import { useQuery } from "react-query";
import { RL16SlipService } from "services/RL16SlipService";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { selectedRL16SlipType } from "../RL16SlipSetup";
import { selectedRL16SummaryType } from "components/RL16Slip/RL16SlipSummarySetup";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PrintIcon from "@mui/icons-material/Print";

export interface IRL16SlipSetupToolbar {
  onResetClicked: () => void;
  onDeleteClicked: () => void;
  onPrintClicked: () => void;
  isSubmitting: boolean;
  formMethods: any;
}

const RL16_SUMMARY_DATA_QUERY_KEY = "get-rl16-summary-data";
const useGetRL16SummaryData = (
  clientid: string,
  slipType: string | number,
  taxYear: number
) =>
  useQuery(
    [RL16_SUMMARY_DATA_QUERY_KEY, clientid, slipType, taxYear],
    (data) => {
      return RL16SlipService.getRL16Summary(clientid, slipType, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

export const RL16SlipSetupToolbar: FC<IRL16SlipSetupToolbar> = ({
  onResetClicked,
  onDeleteClicked,
  onPrintClicked,
  isSubmitting = false,
  formMethods,
}) => {
  const id = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  // const [selectedType, setSelectedType] = useState(400);
  const { isLoading: isLoadingSummaryGetData, data: RL16SummaryData } =
    useGetRL16SummaryData(id, selectedRL16SummaryType, taxYear);
  // console.log("selectedType",selectedType)

  console.log("RL16SummaryData ============", RL16SummaryData);

  const onchangeTooltip = () => {
    //it have to optional from cosmic then remove it
  };
  const selectedSlip = formMethods.getValues("selectedSlip");

  return (
    <Toolbar>
      <>
        <div style={{ display: "contents" }}>
          <div style={{ margin: "1rem 0rem" }}>
            {RL16SummaryData &&
            RL16SummaryData.confirmationCode !== null &&
            selectedRL16SlipType === 400 ? (
              <div style={{ cursor: "not-allowed" }}>
                <DisabledButton
                  tooltiptitle={
                    "Confirmation No. has been enterned in [Complaince -> RL-16 -> Summary]"
                  }
                  isDisabled={true}
                  buttonLabel={"Submit"}
                  icon={<PlaylistAddCheckOutlinedIcon />}
                  testId="disableButton"
                  onClick={onchangeTooltip}
                />
              </div>
            ) : (
              <SubmitButton
                label="Submit"
                testId="submit-button"
                loading={isSubmitting}
                icon={<PlaylistAddCheckOutlinedIcon />}
              />
            )}
          </div>
          <ResetButton testId="reset-button" onClickHandler={onResetClicked} />
          <div style={{ margin: "1rem 0rem" }}>
            {RL16SummaryData &&
            RL16SummaryData.confirmationCode !== null &&
            selectedRL16SlipType === 400 ? (
              <div style={{ cursor: "not-allowed", marginLeft: "1rem" }}>
                <DisabledButton
                  tooltiptitle={
                    "Confirmation No. has been enterned in [Complaince -> t4 -> Summary]"
                  }
                  isDisabled={true}
                  buttonLabel={"Delete"}
                  icon={<DeleteOutlineIcon />}
                  testId="disableButton"
                  onClick={onchangeTooltip}
                />
              </div>
            ) : selectedSlip && selectedSlip === "create-new" ? (
              <div style={{ cursor: "not-allowed", marginLeft: "1rem" }}>
                <DisabledButton
                  tooltiptitle={""}
                  isDisabled={true}
                  buttonLabel={"Delete"}
                  icon={<DeleteOutlineIcon />}
                  testId="disableButton"
                  onClick={onchangeTooltip}
                />
              </div>
            ) : (
              <DeleteButton
                testId="delete-button"
                onClickHandler={onDeleteClicked}
              />
            )}
          </div>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div>
          {selectedSlip && selectedSlip === "create-new" ? (
            <div style={{ cursor: "not-allowed", marginLeft: "1rem" }}>
              <DisabledButton
                tooltiptitle={""}
                isDisabled={true}
                buttonLabel={"Print"}
                icon={<PrintIcon />}
                testId="disableButton"
                onClick={onchangeTooltip}
              />
            </div>
          ) : (
            <PrintButton
            onClickHandler={onPrintClicked}
            testId="rl16-slip-print-button" label={"RL-16"}
          />
          )}
        </div>
      </>
    </Toolbar>
  );
};
