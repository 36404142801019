import { LabelPosition, RHookFormTextFieldWithLabel, EnhancedTextAreaFieldRHF, EnhancedDropDownRHFWithLabel, DataType, FieldType, ITest } from "@websential/cosmic";
import { FC } from 'react';
import { FieldWrapper } from "../../../FieldWrapper/FieldWrapper";

const FIELD_COLUMN_ITEMS = Array(12).fill(1).map((x, y) => {
    const column = x + y
    return {
        label: column,
        value: column
    }
});

const FIELD_TYPE_ITEMS = Object.keys(FieldType).map(fieldTypeKey => {
    // @ts-ignore
    const fieldType = FieldType[fieldTypeKey];
    return {
        label: fieldType,
        value: fieldType
    }
});
const DATA_TYPE_ITEMS = Object.keys(DataType).map(dataTypeKey => {
    // @ts-ignore
    const dataType = DataType[dataTypeKey];
    return {
        label: dataType,
        value: dataType
    }
});

interface Props extends ITest {

}

export const CustomFieldsPanel: FC<Props> = ({ testId }) => {
    return (
        <>
            <FieldWrapper>
                <RHookFormTextFieldWithLabel
                    label="Field Label"
                    id="fieldLabel"
                    testId={`${testId}-field-label`}
                    placeholder="Field Label"
                    labelPosition={LabelPosition.Left}
                    required
                />
            </FieldWrapper>
            <FieldWrapper>
                <RHookFormTextFieldWithLabel
                    label="App Label"
                    id="appFieldLabel"
                    testId={`${testId}-app-field-label`}
                    placeholder="App Label"
                    labelPosition={LabelPosition.Left}
                    required
                />
            </FieldWrapper>
            <FieldWrapper>
                <EnhancedDropDownRHFWithLabel
                    selectName="fieldType"
                    label="Field UI Type"
                    labelPosition={LabelPosition.Left}
                    items={FIELD_TYPE_ITEMS}
                    // required
                    testId={`${testId}-field-type`}
                />
            </FieldWrapper>
            <FieldWrapper>
                <RHookFormTextFieldWithLabel
                    label="Placeholder"
                    id="placeholder"
                    testId={`${testId}-placeholder`}
                    placeholder="Placeholder"
                    labelPosition={LabelPosition.Left}
                />
            </FieldWrapper>
            <FieldWrapper>
                <EnhancedTextAreaFieldRHF
                    label="Field Value"
                    id="fieldValue"
                    testId={`${testId}-field-value`}
                    placeholder="Field Value"
                    labelPosition={LabelPosition.Left}
                />
            </FieldWrapper>
            <FieldWrapper>
                <EnhancedTextAreaFieldRHF
                    label="Field Internal Value"
                    id="fieldInternalValue"
                    testId={`${testId}-field-internal-value`}
                    placeholder="Field Internal Value"
                    labelPosition={LabelPosition.Left}
                />
            </FieldWrapper>
            <FieldWrapper>
                <EnhancedDropDownRHFWithLabel
                    selectName="dataType"
                    label="Data Type"
                    labelPosition={LabelPosition.Left}
                    items={DATA_TYPE_ITEMS}
                    // required
                    testId={`${testId}-data-type`}
                />
            </FieldWrapper>
            <FieldWrapper>
                <RHookFormTextFieldWithLabel
                    label="Field Format"
                    id="fieldFormat"
                    testId={`${testId}-field-format`}
                    placeholder="Field Format"
                    labelPosition={LabelPosition.Left}
                />
            </FieldWrapper>
            <FieldWrapper>
                <RHookFormTextFieldWithLabel
                    label="Default Value"
                    id="defaultValue"
                    testId={`${testId}-default-value`}
                    placeholder="Default Value"
                    labelPosition={LabelPosition.Left}
                />
            </FieldWrapper>
            <FieldWrapper>
                <EnhancedDropDownRHFWithLabel
                    selectName="labelPosition"
                    label="Label Position"
                    labelPosition={LabelPosition.Left}
                    showEmpty={false}
                    items={[{label: 'Top', value: 'top'}, {label: 'Left', value: 'left'}]}
                    selectedItem="left"
                    testId={`${testId}-label-position`}
                />
            </FieldWrapper>
            <FieldWrapper>
                <EnhancedDropDownRHFWithLabel
                    selectName="labelColumn"
                    label="Label Columns"
                    labelPosition={LabelPosition.Left}
                    showEmpty={false}
                    items={FIELD_COLUMN_ITEMS}
                    selectedItem={4}
                    testId={`${testId}-label-columns`}
                />
            </FieldWrapper>
            <FieldWrapper>
                <EnhancedDropDownRHFWithLabel
                    selectName="fieldColumn"
                    label="Field Columns"
                    labelPosition={LabelPosition.Left}
                    showEmpty={false}
                    items={FIELD_COLUMN_ITEMS}
                    selectedItem={8}
                    testId={`${testId}-field-columns`}
                />
            </FieldWrapper>
        </>
    )
};
