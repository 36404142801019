import { AXIOSCALL } from "store/types/isLoadAxiosCallTypes";

export interface IIsLoadAxiosCall {
  isLoading:boolean
}

const initialState: IIsLoadAxiosCall = {
  isLoading:false
};

export const isLoadAxiosCallReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AXIOSCALL.AXIOSCALL_SET_SELECTED:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }
    case AXIOSCALL.AXIOSCALL_CLEAR_SELECTED:
      return {
        ...state,
        isLoading: initialState.isLoading,
      }
    default:
      return state;
  }
};
