import { ACTIVITY_LOG } from "store/types/activityLogTypes";

export interface IActivityLog {
  screenName: string;
  entityId1:string;
  entityId2:string;
  entityId3:string;
  companyName:string;
  activityType:string;
  isLoadingGetActivityLogData:boolean;
  activityLogVisibility:boolean;
}

const initialState: IActivityLog = {
  entityId1:'',
  entityId2:'',
  entityId3:'',
  screenName: '',
  companyName:'',
  activityType:'',
  isLoadingGetActivityLogData:false,
  activityLogVisibility:false,
};

export const activityLogReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTIVITY_LOG.ACTIVITY_LOG_SET_SELECTED:
      return {
        ...state,
        entityId1: action.payload.entityId1,
        entityId2: action.payload.entityId2,
        entityId3: action.payload.entityId3,
        screenName: action.payload.screenName,
        companyName: action.payload.companyName,
        activityType: action.payload.activityType,
        isLoadingGetActivityLogData: action.payload.isLoadingGetActivityLogData,
        activityLogVisibility: action.payload.activityLogVisibility,
      }
    case ACTIVITY_LOG.ACTIVITY_LOG_CLEAR_SELECTED:
      return {
        ...state,
        entityId1: initialState.entityId1,
        entityId2: initialState.entityId2,
        entityId3: initialState.entityId3,
        screenName: initialState.screenName,
        companyName: initialState.companyName,
        activityType: initialState.activityType,
        isLoadingGetActivityLogData: initialState.isLoadingGetActivityLogData,
        activityLogVisibility: initialState.activityLogVisibility,
      }
    default:
      return state;
  }
};
