import { TaxProfileSetup } from "components/TaxProfile";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface ITaxProfileSetupPage {}

export const TaxProfileSetupPage: FC<ITaxProfileSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Tax Profile Setup"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{ text: "Tax Profile List", navigateTo: "/tax-profile/list" },
      { text: "Create Tax Profile", navigateTo: "/tax-profile/setup" }]}
      mainContents={<TaxProfileSetup testId="tax-profile-setup" />}
    />
  );
};
