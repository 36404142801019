import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { TaxSlipDefinitionsList } from "components/TaxSlipCategory/TaxSlipDefinitionsList";

export interface ITaxSlipDefinitionsListPage {}

export const TaxSlipDefinitionsListPage: FC<ITaxSlipDefinitionsListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="TaxSlip Definitions"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'TaxSlip Definitions', navigateTo: '/taxslip-definitions/list'}]}
      mainContents={<TaxSlipDefinitionsList />}
    />
  );
};
