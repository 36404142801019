
import { IGetListRequest } from "models";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";

import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import FileSaver from "file-saver";
import { useDispatch } from "react-redux";

import {
  EnhancedSnackbar,
} from "@websential/cosmic";

import { ReportsService } from "services/ReportsService";
import { INITIAL_PAGE_SIZE, INITIAL_SNACKBAR_DATA } from "common/constants";
import { useCallback, useEffect, useState } from "react";
import { setSelectedClient, setSelectedINVOICE } from "store/actions";
import { FormProvider, useForm } from "react-hook-form";
import { JournalExportHeader } from "./JournalExportHeader";
import { PrimeDataGridJournalExport } from "./PrimeDataGridJournalExport";
import { useQuery } from "react-query";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IJournalExport {}
const useJournals = (
  startDate:string,
  endDate:string,
  dateAppliesTo:number,
  paymentStatus:number,
  selectInvoice:number,
  queryParams:any
  ) =>
  useQuery(
    ["admin-journal-list"],
    (data) => {
      return ReportsService.getJournalExport1(startDate,endDate,dateAppliesTo,paymentStatus,selectInvoice,queryParams);
    },
    { refetchOnWindowFocus: false }
  );
export const JournalExport: React.FC<IJournalExport> = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state: IAppState) => state.client);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [journalsListResponseData, setJournalsListResponseData] = useState<any>(undefined);

  // const [JournalExport, setJournalExport] = useState<any>(undefined);
  const [isLoadingDesiredJournalExport, setIsLoadingDesiredJournalExport] = useState(false);
  const [isLoadingJournalExport, setIsLoadingJournalExport] = useState<any>(false);
  const [totalItems, settotalItems] = useState<any>(undefined);
  const [JournalExportUpdatedItems, setJournalExportUpdatedItems] =
    useState<any>(undefined);
  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "client_name",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const formMethods = useForm<any>({
    // resolver: yupResolver(YearSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    // defaultValues: DEFAULT_VALUES,
  });

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: "client_name",
      order_direction: "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
    };
    console.log("dispatchParams",dispatchParams)
    
    setQueryParams(dispatchParams);
  }, []);

  const formatDate = (datee: any) => {
    const date = new Date(datee);

    // Extract date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to the month because it's zero-based
    const day = String(date.getDate()).padStart(2, "0");

    // Create the formatted date string
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  
  // const currentDateRange = formMethods.getValues('dateRange')
  //   console.log("dateRangedateRange",currentDateRange)
  //   const formattedStartDate = formatDate(currentDateRange && currentDateRange[0]);
  //   const formattedEndDate = formatDate(currentDateRange && currentDateRange[1]);
  //   const dateAppliesTo = formMethods.getValues('dateAppliesTo')
  //   const paymentStatus = formMethods.getValues('status')
  //   const selectInvoice = formMethods.getValues('selectInvoice')
  //   const { isLoading, data } = useJournals(formattedStartDate,formattedEndDate,10,paymentStatus,selectInvoice,queryParams); 
  // // const { isLoading, data } = useInvoices("2024-01-01","2024-03-31",10,1,queryParams); 
  // const journalsList: any = data;
  // console.log("journalsListjournalsList",journalsList)
  
  // const { isLoading, data } = useJournals("2022-01-01","2024-09-30",1,10); 
  const { isLoading, data } = useJournals("2024-01-01","2024-12-31",10,1,20,queryParams); 
  const journalsList: any = data;
  console.log("journalsListjournalsList",journalsList)

  useEffect(()=>{
    if(journalsList){
      setJournalsListResponseData(journalsList)
    }
  },[journalsList])

  const generateHeaderName = () => {
    return (
      <span style={{ display: 'flex', justifyContent: 'space-between' }}>
        @ACCTLEDG(A SCII):ACCT
        <span style={{ color: 'red' }}>{totalItems}</span>
      </span>
    );
  };
  const headerColumnsJournalExport = [
    {
      "field": "acct",
      "headerName": generateHeaderName(),
      "description": "@ACCTLEDG(A SCII):ACCT",
      "flex": 1,
      "hide": false,
      "hideable": false,
      "sortable": false,
      "sortingOrder": "asc",
      "resizable": false,
      "type": "string",
      "align": "left",
      "headerAlign": "left",
      "hideSortIcons": false,
      "disableColumnMenu": true,
      "disableReorder": true,
      "disableExport": true,
      "maskable": false,
      "isFixed": true,
      "sequence": 1
    },
    {
      "field": "date",
      "headerName": "Date(-1)",
      "description": "Date(-1)",
      "flex": 1,
      "hide": false,
      "hideable": false,
      "sortable": true,
      "sortingOrder": "asc",
      "resizable": false,
      "type": "string",
      "align": "left",
      "headerAlign": "left",
      "hideSortIcons": false,
      "disableColumnMenu": true,
      "disableReorder": true,
      "disableExport": true,
      "maskable": false,
      "isFixed": true,
      "sequence": 2
    },
    {
      "field": "refNo",
      "headerName": "REF",
      "description": "Refrence No",
      "flex": 1,
      "hide": false,
      "hideable": false,
      "sortable": true,
      "sortingOrder": "asc",
      "resizable": false,
      "type": "string",
      "align": "left",
      "headerAlign": "left",
      "hideSortIcons": false,
      "disableColumnMenu": true,
      "disableReorder": true,
      "disableExport": true,
      "maskable": false,
      "isFixed": true,
      "sequence": 3
    },
    {
      "field": "desc",
      "headerName": "DESC",
      "description": "Description",
      "flex": 1,
      "hide": false,
      "hideable": false,
      "sortable": true,
      "sortingOrder": "asc",
      "resizable": false,
      "type": "string",
      "align": "left",
      "headerAlign": "left",
      "hideSortIcons": false,
      "disableColumnMenu": true,
      "disableReorder": true,
      "disableExport": true,
      "maskable": false,
      "isFixed": true,
      "sequence": 4
    },
    {
      "field": "amount",
      "headerName": "AMT",
      "description": "Amount",
      "flex": 1,
      "hide": false,
      "hideable": false,
      "sortable": true,
      "sortingOrder": "asc",
      "resizable": false,
      "type": "string",
      "align": "left",
      "headerAlign": "left",
      "hideSortIcons": false,
      "disableColumnMenu": true,
      "disableReorder": true,
      "disableExport": true,
      "maskable": false,
      "isFixed": true,
      "sequence": 5
      },
      {
        "field": "code",
        "headerName": "XACCT",
        "description": "XACCT",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": true,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 6,
      },
      {
        "field": "key",
        "headerName": "KEY",
        "description": "KEY",
        "flex": 1,
        "hide": false,
        "hideable": false,
        "sortable": false,
        "sortingOrder": "asc",
        "resizable": false,
        "type": "string",
        "align": "left",
        "headerAlign": "left",
        "hideSortIcons": false,
        "disableColumnMenu": true,
        "disableReorder": true,
        "disableExport": true,
        "maskable": false,
        "isFixed": true,
        "sequence": 7,
      }
  ]
  
  

  const onClickHandler = (clickedRow: any) => {
    const refNo = clickedRow.refNo
    dispatch(setSelectedINVOICE({refNo:refNo}));
    const clientId = clickedRow.clientId
    dispatch(setSelectedClient({id:clientId}));
  };

  

  const formatedDate = (inputDate: string): string => {
    const [year, month, day] = inputDate.split('-');
    return `${day}${month}${year}`;
  };

  useEffect(() => {
      setIsLoadingJournalExport(true);
      if(journalsList){
        const updatedRows = journalsList.items.map((row: any) => {
          console.log("rowrowrowrowrowrow",row)
          settotalItems(journalsList.items.length)
          const rows:any = [];
          // for (let i = 0; i < journalsList.items.length; i++) {
          //   console.log("iiiii",i)
            for (let j = 0; j < 5; j++) {
              const roww:any = {
                // ...row,
                date: formatedDate(row.invoiceDate),
                refNo: row.refNo,
                desc: null,
                amount: null,
                code: null,
              };
              if (j === 0) {
                roww.desc = row.clientName;
                roww.amount = row.netAmount
                roww.code = `A${row.code}`
              } else if (j === 1) {
                roww.desc = `GST-${row.clientName}`;
                roww.amount = row.taxes
                roww.code = "GST"
              } else if (j === 2) {
                roww.desc =  `HC-${row.clientName}`;
                roww.amount = row.handlingCharges
                roww.code = "HC"
              } else if (j === 3) {
                roww.desc = row.clientName;
                roww.amount = row.invAmount
                roww.code = `S${row.code}`
              } else if(j === 4) {
                roww.refNo = null;
                roww.desc = null;
                roww.amount = null;
                roww.code = null;
              }
              rows.push(roww);
            }
          // }
          return rows;
        });
        setJournalExportUpdatedItems(updatedRows);
        console.log("üpadted rows",updatedRows.flat())
      setIsLoadingJournalExport(false);
    };
  }, [journalsList]);

  const onRowDeleteClicked = useCallback((params) => {
    alert("clicked")
  }, []);

 

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

 const onSubmit = (formData: any) => {
    console.log("formData",formData)
    const formattedStartDate = formData.startDate !== null ? formatDate(formData.startDate) : "2024-01-01";
    const formattedEndDate = formData.endDate !== null ? formatDate(formData.endDate) : "2024-03-31";
    const dateAppliesTo = 10; //invoice
    console.log("formattedStartDate",formattedStartDate)
    console.log("formattedEndDate",formattedEndDate)

    const fetchData = async () => {
      setIsLoadingJournalExport(true);
      setIsLoadingDesiredJournalExport(true);
      try {
        const response: any = await ReportsService.getJournalExport1(formattedStartDate,formattedEndDate,dateAppliesTo,formData.status,formData.selectInvoice,queryParams)
        console.log("responseData-----------------", response);
        setJournalsListResponseData(response)
        const updatedRows = response.items.map((row: any) => {
          const rows:any = [];
          for (let i = 0; i < response.items.length; i++) {
            for (let j = 0; j < 5; j++) {
              const roww:any = {
                // ...row,
                date: formatedDate(row.invoiceDate),
                refNo: row.refNo,
                desc: null,
                amount: null,
                code: null,
              };
              if (j === 0) {
                roww.desc = row.clientName;
                roww.amount = row.netAmount
                roww.code = `A${row.code}`
              } else if (j === 1) {
                roww.desc = `GST-${row.clientName}`;
                roww.amount = row.taxes
                roww.code = "GST"
              } else if (j === 2) {
                roww.desc =  `HC-${row.clientName}`;
                roww.amount = row.handlingCharges
                roww.code = "HC"
              } else if (j === 3) {
                roww.desc = row.clientName;
                roww.amount = row.invAmount
                roww.code = `S${row.code}`
              } else if(j === 4) {
                roww.refNo = null;
                roww.desc = null;
                roww.amount = null;
                roww.code = null;
              }
              rows.push(roww);
            }
          }
          return rows;
        });
        setJournalExportUpdatedItems(updatedRows);
        console.log("üpadted rows",updatedRows.flat())
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoadingJournalExport(false);
      setIsLoadingDesiredJournalExport(false);
    };
    fetchData();

  };

  const saveAsExcelFile = (buffer:any, fileName:any) => {
    import('file-saver').then((module) => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        }
    });
};

useEffect(() => {
  dispatch(
    setSelectedActivityLogActions({
      entityId1: "",
      entityId2: "",
      entityId3: "",
      screenName: '',
      companyName: '',
      activityType:
        "",
      activityLogVisibility: false,
    })
  );
  dispatch(setSelectedStickyNavBar({isSticky:false}));
}, []);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <JournalExportHeader isSubmitting={isLoadingDesiredJournalExport} formMethods={formMethods} />
        </form>
      </FormProvider>
      <PrimeDataGridJournalExport
        rows={JournalExportUpdatedItems && JournalExportUpdatedItems}
        columns={
          journalsListResponseData && headerColumnsJournalExport
            ? headerColumnsJournalExport
            : []
        }
        showIndexColumn={false}
        showChecboxColumn={false}
        showSettingColumn={false}
        onRowDeleteClicked={onRowDeleteClicked}
        isLoading={isLoadingJournalExport}
        onSnackbarMessageData={(value: any) => setSnackbarData(value)}
        totalRecords={journalsListResponseData && journalsListResponseData.total}
        initialPageSize={INITIAL_PAGE_SIZE}
        onRequestParamsChange={onRequestParamsChange}
      />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`invoice-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
