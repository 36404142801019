// import { toSnake } from "common/methods";
// import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
// import { IGetListRequest } from "models";
// import React, { useCallback, useEffect, useState } from "react";
// // import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// // import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// // import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// // import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
// import { useMutation, useQuery, useQueryClient } from "react-query";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import { IAppState } from "store/reducers";
// import EmailIcon from '@mui/icons-material/Email';
// import PrintIcon from "@mui/icons-material/Print";

// import { Box, Button, Grid, useTheme } from "@mui/material";
// import { BackdropCircularProgress, DeleteConfirmationDialog, EmptyList, EnhancedDialog, EnhancedLinkButton, EnhancedSnackbar, IResponse, IServerError, IconTextButton, RouterLinkButton, SplitButton } from "@websential/cosmic";

// import { EnhancedDataGrid } from "../../EnhancedDataGrid";
// import {
//   GET_QUERY_KEY,
//   INITIAL_PAGE_SIZE,
//   INITIAL_SNACKBAR_DATA,
//   QUERY_OPTIONS,
//   ROWS_PER_PAGE_PER_OPTIONS,
//   SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
// } from "common/constants";
// import { T4ASlipService } from "services/T4ASlipService";
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// import FileSaver from "file-saver";
// import { PrintingService } from "services/PrintingService";
// import { ReportsService } from "services/ReportsService";
// import { setSelectedINVOICE } from "store/actions";
// import { InvoiceDialogPanel } from "components/T4Slip/T4SlipList/InvoiceDialogPanel";
// import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";

// export interface IT4ASlip {}
// const TAXSLIP_T4A_LIST_QUERY_KEY = "t4a-slips-list"

// const useT4ASlips = (
//   clientId: string,
//   taxYear: number,
//   params: IGetListRequest
// ) =>
//   useQuery(
//     [GET_QUERY_KEY.LIST_T4A_SLIPS, params],
//     (data) => {
//       return T4ASlipService.getList(clientId, taxYear, params);
//     },
//     { refetchOnWindowFocus: false }
//   );

// export const T4ASlipList: React.FC<IT4ASlip> = () => {
//   const dispatch = useDispatch();
//   const { id } = useSelector((state: IAppState) => state.client);
//   const clientId = useSelector((state: IAppState) => state.client.id);
//   const taxYear  = useSelector((state: IAppState) => state.taxYear.year);
//   const { resourceName } = useParams();
//   const [dataRoleName, setDataRoleName] = useState<string>("");

//   const[openInvoiceDialog, setInvoiceDialogOpen] = useState(false)
//   const[invoiceData, setInvoiceData] = useState<any>([])
//   const[isLoadingData, setIsLoadingData] = useState(false)
//   const [selectedRowsValues, setSelectedRowsValues] = useState([]);
//   const [t4aSlipListUpdatedItems, setT4aSlipListUpdatedItems] = useState<any>(undefined);

//   // for delete row Purpose
//   const queryClient = useQueryClient();
//   const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);

//   useEffect(() => {
//     if (resourceName && resourceName.length > 0) {
//       console.log("resourceName>>>>>", resourceName);
//       setDataRoleName(resourceName);
//     }
//   }, [dataRoleName, resourceName]);

//   const theme = useTheme();
//   const navigate = useNavigate();

//   const [queryParams, setQueryParams] = useState<IGetListRequest>({
//     search_query: "",
//     order_by: "name1",
//     order_direction: "asc",
//     page: 1,
//     size: INITIAL_PAGE_SIZE,
//   });

//   const { isLoading, data } = useT4ASlips(id, taxYear, queryParams);
//   const slipList: any = data;
//   console.log("@@@@@ => resourceList", slipList);

//   //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
//   const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
//   useEffect(()=>{
//     if(isLoading){
//       setIsAxiosCallLoading(true)
//       setTimeout(()=>{
//         setIsAxiosCallLoading(false)
//       },1000)
//     } else {
//       setIsAxiosCallLoading(true)
//       setTimeout(()=>{
//         setIsAxiosCallLoading(false)
//       },1000)
//     }
//   },[isLoading])

//   useEffect(()=>{
//     if(slipList){
//       const updatedItemsResponse = slipList.items.map((item:any) => ({
//         ...item,
//         payeeType: item.payeeType === true ? "Personal" : item.payeeType === false ? "Business" : null,
//       }));
//       setT4aSlipListUpdatedItems(updatedItemsResponse)
//       console.log("t4aSlipListUpdatedItemst4aSlipListUpdatedItems",updatedItemsResponse)
//       console.log("t4aSlipListUpdatedItemst4aSlipListUpdatedItems",t4aSlipListUpdatedItems)
//   }
// },[slipList])

//   const { message, componentState } = useSelector(
//     (state: IAppState) => state.viewState
//   );
//   // console.log("masked = >", masked);
//   // const { accountantList } = useSelector(
//   //   (state: IAppState) => state.accountant
//   // );

//   const [openDialog, setDialogOpen] = useState(false);
//   const [selectedIds, setSelectedIds] = useState([]);
//   const [statusValue, setStatusValue] = useState("");
//   const [apiAction, setApiAction] = useState("");
//   const [emptyList, setEmptyList] = useState(false);
//   const [searchText, setSearchText] = useState({});
//   const [zeroClient, setzeroClient] = useState(true);

//   // for delete row
//   const [singleDelete, setSingleDelete] = useState<boolean>(false);
//   const [t4ASlipId, setT4ASlipId] = useState<number>(0);

//   const onRequestParamsChange = useCallback((requestParams) => {
//     console.log("##### - request params", requestParams);
//     let dispatchParams = {
//       search_query: requestParams.debouncedSearchQuery,
//       order_by: "name1",
//       order_direction: "asc",
//       page: requestParams.currentPage + 1,
//       size: requestParams.pageSize,
//     };
//     if (requestParams.sort) {
//       const [sortBy, sortDirection] = requestParams.sort.split(",");
//       dispatchParams.order_by = toSnake(sortBy);
//       dispatchParams.order_direction = sortDirection;
//     }

//     // setQueryParams(dispatchParams);
//     if(dispatchParams.search_query === ''){
//       setQueryParams(dispatchParams);
//     }
//     setSearchText(dispatchParams)
//   }, []);

//   const onSearchButtonClicked=()=>{
//     setQueryParams(searchText)
//     setzeroClient(false)
//   }

//   const onRowEditClicked = useCallback((params) => {
//     let path = `/taxslip/t4a/setup/${params.row.id}`;
//     navigate(path);
//   }, []);

//   const onCloseHandler = () => {
//     setDialogOpen(false);
//     setInvoiceDialogOpen(false);
//   };

//   const callAction = () => {
//     if (apiAction === "delete") {
//       alert(`Delete row with ID: ${selectedIds} has been clicked!`);
//       // TODO api call for delete
//     } else if (apiAction === "updateStatus") {
//       alert(`action need to trigger : ${apiAction}`);
//       // TODO api call for update status
//     }
//     setDialogOpen(false);
//   };

//   // For Delete Row
//  const deleteT4ASlipField = () => {
//   if (singleDelete) {
//     let payload = {
//       t4ASlipId,
//       clientId,
//       taxYear
//     }
//     setSingleDelete(false);
//     deleteT4ASlipById(payload);
//     setDialogOpen(false);
//   }
// };

// const onRowDeleteClicked = useCallback((params) => {
//   setDialogOpen(true);
//   setSingleDelete(true);
//   setApiAction("delete");
//   setT4ASlipId(params.row.id);
//   console.log("T4ASlipId in T4ASlip List OnDeleteRowClicked function",params.row.id)
//   // alert(`Delete row with ID: ${params.row.id} has been clicked!`);
// }, []);

// const onSnackbarClosed = (
//   event: React.SyntheticEvent | Event,
//   reason?: string
// ) => {
//   if (reason === "clickaway") {
//     return;
//   }
//   setSnackbarData(INITIAL_SNACKBAR_DATA);
// };

// const { isLoading: isDeleting, mutate: deleteT4ASlipById } = useMutation(
//   T4ASlipService.delT4ASlip,
//   {
//     onSuccess: (res: string | IServerError | IResponse | any) => {
//       if (typeof res === "string") {
//         // remove the cache of the Year fields since we just added a new field.
//         setSnackbarData({
//           open: true,
//           message: res,
//           severity: "success",
//         });

//         return queryClient.invalidateQueries(
//           TAXSLIP_T4A_LIST_QUERY_KEY
//           );
//         } else {
//           setSnackbarData({
//             open: true,
//             message: res.message,
//             severity: "error",
//           });
//         }
//       },
//     onError: (err:any) => {
//       // TODO: customize the toast message based on the response
//       setSnackbarData({
//         open: true,
//         message: err.message,
//         severity: "error",
//       });
//     },
//   }
// );

// // Bulk Delete
// const [bulkDelete, setBulkDelete] = useState<boolean>(false);
// const [bulkT4ASlipsId, setBulkT4ASlipsId] = useState<any>({});
// const [bulkT4ASlipsDelete, setBulkT4ASlipsDelete] = useState<boolean>(false);

// const { isLoading: isBulkDeleting, mutate: deleteBulkT4ASlipsById } =
//     useMutation(T4ASlipService.delBulkT4ASlips, {
//       onSuccess: (res: any | string | IServerError | IResponse) => {
//         if (typeof res === "object") {
//           // remove the cache of the Year fields since we just added a new field.
//           setSnackbarData({
//             open: true,
//             message: res.message,
//             severity: "success",
//           });

//           setBulkT4ASlipsDelete(false)
//           return queryClient.invalidateQueries(TAXSLIP_T4A_LIST_QUERY_KEY);
//         } else {
//           setSnackbarData({
//             open: true,
//             message: "Something went wrong, try again later!",
//             severity: "error",
//           });
//         }
//         setBulkT4ASlipsDelete(false)
//       },
//       onError: (err:any) => {
//         // TODO: customize the toast message based on the response
//         setSnackbarData({
//           open: true,
//           message: err.message,
//           severity: "error",
//         });
//         setBulkT4ASlipsDelete(false)
//       },
//     });

//   // For Bulk Delete
//   const deleteBulkT4ASlipsField = () => {
//     if (bulkDelete) {
//       setBulkDelete(false);
//       deleteBulkT4ASlipsById(bulkT4ASlipsId);
//       setDialogOpen(false);
//     }
//   };

// const savePdfAsFile = (pdfData: any, filename: any) => {
//   const blob = new Blob([pdfData], { type: 'pdf;charset=utf-8' });
//   FileSaver.saveAs(blob, filename);
// };
// console.log("selectedRowsValues12",selectedRowsValues)
// let newData:any = []
// const onPrintClicked = async (printType: any): Promise<void> => {
//   const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
//   console.log("completedSlipsIds",completedSlipsIds)
//   // Convert arrays to strings and compare
//   if(newData.length > 0){
//     const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
//     if (!areArraysEqual) {
//       setSnackbarData({
//         open: true,
//         message: "Please Selected T4A Completed Slips Only!",
//         severity: "error",
//       });
//     }else{
//   setIsLoadingData(true);
//   const maskedStatus:any = sessionStorage.getItem("maskEnabled")
//   if(maskedStatus === "true"){
//     setIsLoadingData(false);
//     setSnackbarData({
//       open: true,
//       message: "Please unmask your data.",
//       severity: "error",
//     });
//   } else {
//     try {
//       const response:any = await ReportsService.createInvoice(clientId,taxYear);
//       setIsLoadingData(false);
//       console.log("responseresponse",response)
//       if(response.message === "Slips not found for invoice."){
//         setIsLoadingData(true);
//             try {
//               const filingAuditResponse:any = await T4ASlipService.getT4AAuditFiling(clientId,taxYear);
//               console.log("filingAuditResponse",filingAuditResponse)
//               if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
//                 console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else if(filingAuditResponse && filingAuditResponse.t4a_filling && filingAuditResponse.t4a_filling.length > 0){
//                 console.log("filingAuditResponse.t4a_filling",filingAuditResponse.t4a_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else {
//         const ids_list: any = newData.map((ids:any) => `t4a_slip_ids=${ids}`).join('&');
//         const pdfIds = ids_list.replace(/^&+|&+$/g, '')
//         setIsLoadingData(true);
//         try {
//           const response: any = await PrintingService.createBulkT4APdfPrint(clientId, printType, pdfIds, taxYear);
//           if(response.status === 400){
//             setSnackbarData({
//               open: true,
//               message: response.data.message ? response.data.message : "Something went wrong.",
//               // message: "Please Unmask sensitive data for PDF printing.",
//               severity: "error",
//             });
//           }else{
//             const filename = 'T4Aslip.pdf';
//             savePdfAsFile(response, filename);
//           }
//           setIsLoadingData(false);
//         } catch (err) {
//           console.log("err",err)
//           alert(err)
//         }
//       }
//       setIsLoadingData(false);
//     } catch(err:any) {
//       setIsLoadingData(false);
//       alert(err)
//     }
//       } else if(response && response.id !== null) {
//         setInvoiceData(response)
//         setInvoiceDialogOpen(true)
//       }else{
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong.",
//           severity: "error",
//         });
//       }
//       setIsLoadingData(false);
//     } catch (err) {
//       console.log("err",err)
//     }
//     }
//   }
// }
// }

// const onAllSlipsPrintClicked = async (event:any): Promise<void> => {
//     setIsLoadingData(true);
//     const maskedStatus:any = sessionStorage.getItem("maskEnabled")
//     if(maskedStatus === "true"){
//     setIsLoadingData(false);
//       setSnackbarData({
//         open: true,
//         message: "Please unmask your data.",
//         severity: "error",
//       });
//     } else {
//     try {
//       const response:any = await ReportsService.createInvoice(clientId,taxYear);
//       setIsLoadingData(false);
//       console.log("responseresponse",response)
//       if(response.message === "Slips not found for invoice."){
//         setIsLoadingData(true);
//             try {
//               const filingAuditResponse:any = await T4ASlipService.getT4AAuditFiling(clientId,taxYear);
//               console.log("filingAuditResponse",filingAuditResponse)
//               if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
//                 console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else if(filingAuditResponse && filingAuditResponse.t4a_filling && filingAuditResponse.t4a_filling.length > 0){
//                 console.log("filingAuditResponse.t4a_filling",filingAuditResponse.t4a_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else {
//         setIsLoadingData(true);
//         try {
//           const response: any = await PrintingService.createAllSlipsPdfPrint(clientId, event, "T4A", taxYear);
//           console.log("response000",response)
//           if(response.status === 400){
//             setSnackbarData({
//               open: true,
//               message: response.data.message ? response.data.message : "Something went wrong.",
//               // message: "Please Unmask sensitive data for PDF printing.",
//               severity: "error",
//             });
//           }else{
//             const filename = 'T4Aslip.pdf';
//             savePdfAsFile(response, filename);
//           }
//           setIsLoadingData(false);
//         } catch (err) {
//           console.log("err",err)
//         }
//       }
//       setIsLoadingData(false);
//     } catch(err:any) {
//       setIsLoadingData(false);
//       alert(err)
//     }
//       } else if(response && response.id !== null) {
//         setInvoiceData(response)
//         setInvoiceDialogOpen(true)
//       }else{
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong.",
//           severity: "error",
//         });
//       }
//       setIsLoadingData(false);
//     } catch (err) {
//       console.log("err",err)
//     }
//     }
// };

// const onEmailButtonClicked = async (): Promise<void> => {
//   const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
//   console.log("completedSlipsIds",completedSlipsIds)
//   // Convert arrays to strings and compare
//   if(newData.length > 0){
//     const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
//     if (!areArraysEqual) {
//       setSnackbarData({
//         open: true,
//         message: "Please Selected T4A Completed Slips Only!",
//         severity: "error",
//       });
//     }else{
//   setIsLoadingData(true);
//   const maskedStatus:any = sessionStorage.getItem("maskEnabled")
//   if(maskedStatus === "true"){
//     setIsLoadingData(false);
//     setSnackbarData({
//       open: true,
//       message: "Please unmask your data.",
//       severity: "error",
//     });
//   } else {
//     try {
//       const response:any = await ReportsService.createInvoice(clientId,taxYear);
//       setIsLoadingData(false);
//       console.log("responseresponse",response)
//       if(response.message === "Slips not found for invoice."){
//         setIsLoadingData(true);
//         try {
//           const filingAuditResponse:any = await T4ASlipService.getT4AAuditFiling(clientId,taxYear);
//           console.log("filingAuditResponse",filingAuditResponse)
//           if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
//             console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
//             setSnackbarData({
//               open: true,
//               message: (
//                 <>
//                   Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
//                   <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                   textDecoration:"none",
//                                                   fontWeight:"bold"}}>Click here to view</a>
//                 </>
//               ),
//               severity: "error",
//               autoHide:false
//             });
//           } else if(filingAuditResponse && filingAuditResponse.t4a_filling && filingAuditResponse.t4a_filling.length > 0){
//             console.log("filingAuditResponse.t4a_filling",filingAuditResponse.t4a_filling)
//             setSnackbarData({
//               open: true,
//               message: (
//                 <>
//                   Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
//                   <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                   textDecoration:"none",
//                                                   fontWeight:"bold"}}>Click here to view</a>
//                 </>
//               ),
//               severity: "error",
//               autoHide:false
//             });
//           }
//           // else if(filingAuditResponse && filingAuditResponse.t4a_warnings && filingAuditResponse.t4a_warnings.length > 0){
//           //   // Check if a string contains the word "email"
//           //   const containsEmail = (str: string) => str.toLowerCase().includes('email is missing');
//           //   const containsDOB = (str: string) => str.toLowerCase().includes('date of birth is missing');

//           //   console.log("containsEmail",containsEmail)

//           //   // Iterate over the array and log the string if it contains the word "email"
//           //   filingAuditResponse && filingAuditResponse.t4a_warnings.forEach((str:any) => {
//           //     if (containsEmail(str)) {
//           //       console.log("strstrstr email",str);
//           //       setSnackbarData({
//           //         open: true,
//           //         message: (
//           //           <>
//           //             {str}{' '}
//           //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//           //                                             textDecoration:"none",
//           //                                             fontWeight:"bold"}}>Click here to view</a>
//           //           </>
//           //         ),
//           //         severity: "error",
//           //         autoHide:false
//           //       });
//           //     } else if (containsDOB(str)) {
//           //       console.log("strstrstr dob",str);
//           //       setSnackbarData({
//           //         open: true,
//           //         message: (
//           //           <>
//           //             {str}{' '}
//           //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//           //                                             textDecoration:"none",
//           //                                             fontWeight:"bold"}}>Click here to view</a>
//           //           </>
//           //         ),
//           //         severity: "error",
//           //         autoHide:false
//           //       });
//           //     }
//           //   });
//             // setSnackbarData({
//             //   open: true,
//             //   message: (
//             //     <>
//             //       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
//             //       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//             //                                       textDecoration:"none",
//             //                                       fontWeight:"bold"}}>Click here to view</a>
//             //     </>
//             //   ),
//             //   severity: "error",
//             //   autoHide:false
//             // });
//           // }
//         else {
//         const ids_list: any = newData.map((ids:any) => `slip_ids=${ids}`).join('&');
//         const pdfIds = ids_list.replace(/^&+|&+$/g, '')
//         setIsLoadingData(true);
//         try {
//           const response: any = await PrintingService.createBulkT4APdfEmail(clientId, pdfIds, taxYear);
//           if(response.status === 400){
//             setSnackbarData({
//               open: true,
//               message: response.data.message ? response.data.message : "Something went wrong.",
//               // message: "Please Unmask sensitive data for PDF printing.",
//               severity: "error",
//             });
//           }else if(response.status === 201){
//             setSnackbarData({
//               open: true,
//               message: response.data.message ? response.data.message : "Email send Successfully.",
//               severity: "success",
//             });
//           } else {
//             setSnackbarData({
//               open: true,
//               message: "Something Went Wrong.",
//               severity: "error",
//             })
//           }
//           setIsLoadingData(false);
//         } catch (err) {
//           console.log("err",err)
//           alert(err)
//         }
//       }
//       setIsLoadingData(false);
//     } catch(err:any) {
//       setIsLoadingData(false);
//       alert(err)
//     }
//       } else if(response && response.id !== null) {
//         setInvoiceData(response)
//         setInvoiceDialogOpen(true)
//       }else{
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong.",
//           severity: "error",
//         });
//       }
//       setIsLoadingData(false);
//     } catch (err) {
//       console.log("err",err)
//     }
//     }
//   }
// }
// }

//   const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
//     setStatusValue(event.target.value as string);
//     setApiAction("updateStatus");
//     setDialogOpen(true);
//   };

//   const onRowMultipleDeleteClicked = (params: any) => {
//     console.log("Params in onRowMultipleDeleteClicked ====== ", params);
//     let bulkDeletePayload = {
//       ids:{"slipIds":params},
//       clientId,
//       taxYear
//     }
//     setDialogOpen(true);
//     setBulkDelete(true);
//     setApiAction("delete");
//     setBulkT4ASlipsId(bulkDeletePayload);
//     setBulkT4ASlipsDelete(true);
//   };

//   const selectionToolbar = useCallback(
//     (selectedItems) => {
//       setSelectedIds(selectedItems);
//       newData = selectedItems
//       if (selectedItems.length) {
//         return (
//           <Box sx={{ marginTop: theme.spacing(1),display:"flex",justifyContent:"center",alignItems:"center" }}>
//             <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block", "& .MuiPopper-root":{zIndex:2}}}>
//               <SplitButton
//                   options={[
//                     "Vendor",
//                     "Employer",
//                     "CRA",
//                   ]}
//                   onClickHandler={onAllSlipsPrintClicked}
//                   testId="t4-print-button"
//                   icon={<PrintIcon />}
//                   listItemsLabel={`Print All T4A \nSlips`}
//                 />
//             </Box>
//             <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block", "& .MuiPopper-root":{zIndex:2}}}>
//               <SplitButton
//                 options={[
//                   "Vendor",
//                   "Employer",
//                   "CRA",
//                 ]}
//                 onClickHandler={onPrintClicked}
//                 testId="t4-print-button"
//                 icon={<PrintIcon />}
//                 listItemsLabel={`Print T4A \nSelected Slips`}
//               />
//               </Box>
//               <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
//                 <Button variant="outlined" startIcon={<EmailIcon />} onClick={onEmailButtonClicked}>
//                   Email
//                 </Button>
//               </Box>
//               <Button
//                 onClick={() => onRowMultipleDeleteClicked(selectedItems)}
//                 // sx={{marginBottom:"1rem"}}
//                 variant="contained"
//                 color="error"
//               >
//                 Delete
//             </Button>
//           </Box>
//         );
//       }
//       return (
//         <Box sx={{ marginTop: theme.spacing(1),display:"flex" }}>
//           <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
//             <EnhancedLinkButton text={"Import CSV"} icon={<ExitToAppIcon/>} navigateTo={"/taxslip/t4a/import"} testId={'import-t4a-slip-button'}/>
//           </Box>
//           <RouterLinkButton to="/taxslip/t4a/setup" text="Add T4A Slip" />
//         </Box>
//       );
//     },
//     [theme,selectedRowsValues]
//   );

//   const onUnPaidClickHandler = (clickedRow: any) => {
//     setIsLoadingData(true);
//     // setTimeout(() => {
//     //   setIsLoadingData(false);
//     // }, 2500);
//     const fetchData = async () => {
//       try {
//         const response: any =
//         await ReportsService.paypalIntegrationOfUnpaidInvoice(id,clickedRow.refNo);
//         setIsLoadingData(false);
//         console.log("responseData111-----------------",response);
//         window.open(response, '_blank');

//     } catch (error) {
//           console.error("Error fetching data:", error);
//           setIsLoadingData(false);
//         }
//     }
//     fetchData();
//     console.log("clickedRowclickedRowclickedRow", clickedRow);
//   };

//   const onClickInvoiceNoHandler = (clickedRow: any) => {
//     dispatch(setSelectedINVOICE(clickedRow));
//     console.log("clickedRowclickedRowclickedRow", clickedRow);
//   };

//   const image =
//   "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";

//   return slipList && slipList.items.length === 0 && zeroClient === true ? (
//     <EmptyList
//     showImage={true}
//     imagePath= {image}
//     heading={`There's nothing! Add T4A Slip to proceed`}
//     content={`You can add t4a slip by directly clicking on add button or by importing csv file.`}
//     testId={"t4aslip-empty-list"}
//     showAddButton= {true}
//     addButtonLabel={`Add T4A Slip`}
//     navigateToAdd={"/taxslip/t4a/setup"}
//     showImportButton={true}
//     importButtonLabel="Import CSV"
//     navigateToImport={"/taxslip/t4a/import"}
//     // showImportXmlButton={true}
//     // importXmlButtonLabel="Import XML"
//     // navigateToImportXml={"/taxslip/t4a/import-xml"}
//     />
//   ) : (
//     <>
//     <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
//       <BackdropCircularProgress
//           open={
//             isBulkDeleting || isDeleting || isLoadingData
//           }
//           testId="T4A Slip List"
//         />
//       <DeleteConfirmationDialog
//           open={openDialog}
//           deleteActionHandler={bulkT4ASlipsDelete === true ? deleteBulkT4ASlipsField : deleteT4ASlipField}
//           cancelActionHandler={onCloseHandler}
//           testId="T4A-confirmation-dialog"
//         />
//       <EnhancedDataGrid
//         columns={slipList ? slipList.headerColumns : []}
//         rows={slipList && t4aSlipListUpdatedItems ? t4aSlipListUpdatedItems : []}
//         selectionEnabled={true}
//         autoHeight={true}
//         allowSearching={true}
//         onSearchButtonClicked={onSearchButtonClicked}
//         searchFieldPlaceholder={"Search by Employee Name..."}
//         rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
//         selectionToolbar={selectionToolbar}
//         selectedRowsValues={(value:any)=>setSelectedRowsValues(value)}
//         onRowEditClicked={onRowEditClicked}
//         onRowDeleteClicked={onRowDeleteClicked}
//         // initialPage={0}
//         initialPageSize={INITIAL_PAGE_SIZE}
//         disableColumnMenu={true}
//         showRowActionsColumn={true}
//         autoPageSize={false} //
//         showIndexColumn={true}
//         testId="resource-list-grid"
//         sortingMode="server"
//         filterMode="server"
//         paginationMode="server"
//         onRequestParamsChange={onRequestParamsChange}
//         onSearchCrossClicked={(value) => setzeroClient(value)}
//         totalRowsCount={slipList?.total}
//         isLoading={isLoading || isAxiosCallLoading}
//         rowIdGetter={(row) => row.id}
//         searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
//         shouldMask={false}
//       />
//       {/* invoice dialogu open if any transaction is unPaid */}
//       <EnhancedDialog
//             dialogTitle="Invoice(s) Due"
//             key="dialog-box"
//             testId="dialog"
//             onCloseHandler={onCloseHandler}
//             open={openInvoiceDialog}
//             dialogContent={
//               <InvoiceDialogPanel
//               invoiceData={invoiceData}
//               onUnPaidClickHandler={onUnPaidClickHandler}
//               onClickInvoiceNoHandler={onClickInvoiceNoHandler}
//               />
//             }
//             dialogAction={
//               <Grid>
//                 <Button
//                   autoFocus
//                   variant="contained"
//                   onClick={onCloseHandler}
//                 >
//                   Cancel
//                 </Button>
//               </Grid>
//             }
//           />
//     </Grid>
//     <EnhancedSnackbar
//     message={snackbarData.message}
//     onCloseHandler={onSnackbarClosed}
//     severity={snackbarData.severity}
//     testId={"T4A-list-snackbar"}
//     open={snackbarData.open}
//     autoHide={snackbarData.autoHide}
//   />
//   </>
//   );
// };

// use prime data grid
import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useRef, useState } from "react";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IAppState } from "store/reducers";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";

import { Box, Button, Grid, Tooltip, useTheme } from "@mui/material";
import {
  BackdropCircularProgress,
  DeleteConfirmationDialog,
  EmptyList,
  EnhancedDialog,
  EnhancedLinkButton,
  EnhancedSnackbar,
  IResponse,
  IServerError,
  IconTextButton,
  RouterLinkButton,
  SplitButton,
} from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import {
  GET_QUERY_KEY,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
  T4A_LIST_SCREEN_ID,
  T4A_LIST_SCREEN_SLUG,
} from "common/constants";
import { T4ASlipService } from "services/T4ASlipService";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FileSaver from "file-saver";
import { PrintingService } from "services/PrintingService";
import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions";
import { InvoiceDialogPanel } from "components/T4Slip/T4SlipList/InvoiceDialogPanel";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import { PrimeDataGrid } from "../../PrimeDataGrid";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { T4SlipService } from "services/T4SlipService";
import { ClientService } from "services";
import { ProgressBar } from "primereact/progressbar";

export interface IT4ASlip {}
const TAXSLIP_T4A_LIST_QUERY_KEY = "t4a-slips-list";

const useT4ASlips = (
  clientId: string,
  taxYear: number,
  params: IGetListRequest
) =>
  useQuery(
    [GET_QUERY_KEY.LIST_T4A_SLIPS, params],
    (data) => {
      return T4ASlipService.getList(clientId, taxYear, params);
    },
    { refetchOnWindowFocus: false }
  );

const GET_ADJUSTMENTS_QUERY_KEY = "get-adjustments";

const useAdjustments = (clientId: string) =>
  useQuery(
    GET_ADJUSTMENTS_QUERY_KEY,
    () => AdjustmentOptionsService.getAdjustmentOptionsByClientId(clientId),
    QUERY_OPTIONS
  );

  const useGetSlipIds = (
    tag: string,
    clientId: string,
    taxYear: number
  ) =>
    useQuery(
      ["get-slip-ids", tag, clientId, taxYear],
      (data) => {
        return T4SlipService.getSlipIds(tag, clientId, taxYear);
      },
      { refetchOnWindowFocus: false }
    );

    const useGetCompanyData = (id: string | undefined) => 
      useQuery(
        ["client-get-by-id", id],
        (data) => {
          return ClientService.getById(id);
        },
        { refetchOnWindowFocus: false }
      );

export const T4ASlipList: React.FC<IT4ASlip> = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state: IAppState) => state.client);
  const clientId = useSelector((state: IAppState) => state.client.id);
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const { resourceName } = useParams();
  const [dataRoleName, setDataRoleName] = useState<string>("");
  const [invoicePaymentFor, setInvoicePaymentFor] = useState(10)

  const [openInvoiceDialog, setInvoiceDialogOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [selectedRowsValues, setSelectedRowsValues] = useState([]);
  const [t4aSlipListUpdatedItems, setT4aSlipListUpdatedItems] =
    useState<any>(undefined);

  const interval = useRef<any>(null);
  const [percentageValue, setPercentageValue] = useState(0);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  // for delete row Purpose
  const queryClient = useQueryClient();
  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);

  useEffect(() => {
    if (resourceName && resourceName.length > 0) {
      console.log("resourceName>>>>>", resourceName);
      setDataRoleName(resourceName);
    }
  }, [dataRoleName, resourceName]);

  const theme = useTheme();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "name1",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useT4ASlips(id, taxYear, queryParams);
  const slipList: any = data;
  console.log("@@@@@ => resourceList", slipList);

  const { isLoading: isLoadingAdjustments, data: adjustments } =
    useAdjustments(clientId);
  let AdjustmentOptionsData: any = adjustments;

  const { isLoading:loadingSlipIds, data:t4aSlipIds } = useGetSlipIds("T4A", clientId, taxYear);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  useEffect(()=>{
    console.log("companyGetByIdRecord11",companyGetByIdRecord)
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor ? parseInt(paymentFor.value) : 10)
    }
  },[companyGetByIdRecord])

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
  useEffect(() => {
    if (isLoading) {
      setIsAxiosCallLoading(true);
      setTimeout(() => {
        setIsAxiosCallLoading(false);
      }, 1000);
    } else {
      setIsAxiosCallLoading(true);
      setTimeout(() => {
        setIsAxiosCallLoading(false);
      }, 1000);
    }
  }, [isLoading]);

  useEffect(() => {
    if (slipList) {
      const updatedItemsResponse = slipList.items.map((item: any) => ({
        ...item,
        payeeType:
          item.payeeType === true
            ? "Personal"
            : item.payeeType === false
            ? "Business"
            : null,
        name: (
          <Link
            to={`/taxslip/t4a/setup/${item.id}`}
            className="no-underline"
            style={{ color: "blue" }}
            // onClick={() => onClickHandler(row)}
          >
            {item.name}
          </Link>
        ),
      }));
      setT4aSlipListUpdatedItems(updatedItemsResponse);
      console.log(
        "t4aSlipListUpdatedItemst4aSlipListUpdatedItems",
        updatedItemsResponse
      );
      console.log(
        "t4aSlipListUpdatedItemst4aSlipListUpdatedItems",
        t4aSlipListUpdatedItems
      );
    }
  }, [slipList]);

  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);
  const [searchText, setSearchText] = useState({});
  const [zeroClient, setzeroClient] = useState(true);

  // for delete row
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [t4ASlipId, setT4ASlipId] = useState<number>(0);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: "name1",
      order_direction: requestParams.sortOrder
        ? requestParams.sortOrder
        : "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
    };
    // if (requestParams.sort) {
    //   const [sortBy, sortDirection] = requestParams.sort.split(",");
    //   dispatchParams.order_by = toSnake(sortBy);
    //   dispatchParams.order_direction = sortDirection;
    // }
    console.log("dispatchParams", dispatchParams);
    setQueryParams(dispatchParams);
  }, []);

  const onSearchButtonClicked = () => {
    setQueryParams(searchText);
    setzeroClient(false);
  };

  const onRowEditClicked = useCallback((params) => {
    let path = `/taxslip/t4a/setup/${params.id}`;
    navigate(path);
  }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
    setInvoiceDialogOpen(false);
  };

  const callAction = () => {
    if (apiAction === "delete") {
      alert(`Delete row with ID: ${selectedIds} has been clicked!`);
      // TODO api call for delete
    } else if (apiAction === "updateStatus") {
      alert(`action need to trigger : ${apiAction}`);
      // TODO api call for update status
    }
    setDialogOpen(false);
  };

  // For Delete Row
  const deleteT4ASlipField = () => {
    if (singleDelete) {
      let payload = {
        t4ASlipId,
        clientId,
        taxYear,
      };
      setSingleDelete(false);
      deleteT4ASlipById(payload);
      setDialogOpen(false);
    }
  };

  const onRowDeleteClicked = useCallback((params) => {
    setDialogOpen(true);
    setSingleDelete(true);
    setApiAction("delete");
    setT4ASlipId(params.id);
    console.log(
      "T4ASlipId in T4ASlip List OnDeleteRowClicked function",
      params.id
    );
    // alert(`Delete row with ID: ${params.row.id} has been clicked!`);
  }, []);

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const { isLoading: isDeleting, mutate: deleteT4ASlipById } = useMutation(
    T4ASlipService.delT4ASlip,
    {
      onSuccess: (res: string | IServerError | IResponse | any) => {
        if (typeof res === "string") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res,
            severity: "success",
          });

          return queryClient.invalidateQueries(GET_QUERY_KEY.LIST_T4A_SLIPS);
        } else {
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "error",
          });
        }
      },
      onError: (err: any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  // Bulk Delete
  const [bulkDelete, setBulkDelete] = useState<boolean>(false);
  const [bulkT4ASlipsId, setBulkT4ASlipsId] = useState<any>({});
  const [bulkT4ASlipsDelete, setBulkT4ASlipsDelete] = useState<boolean>(false);

  const { isLoading: isBulkDeleting, mutate: deleteBulkT4ASlipsById } =
    useMutation(T4ASlipService.delBulkT4ASlips, {
      onSuccess: (res: any | string | IServerError | IResponse) => {
        if (typeof res === "object") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "success",
          });

          setBulkT4ASlipsDelete(false);
          return queryClient.invalidateQueries(TAXSLIP_T4A_LIST_QUERY_KEY);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
        setBulkT4ASlipsDelete(false);
      },
      onError: (err: any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
        setBulkT4ASlipsDelete(false);
      },
    });

  // For Bulk Delete
  const deleteBulkT4ASlipsField = () => {
    if (bulkDelete) {
      setBulkDelete(false);
      deleteBulkT4ASlipsById(bulkT4ASlipsId);
      setDialogOpen(false);
    }
  };

  const savePdfAsFile = (pdfData: any, filename: any) => {
    const blob = new Blob([pdfData], { type: "pdf;charset=utf-8" });
    FileSaver.saveAs(blob, filename);
  };
  console.log("selectedRowsValues12", selectedRowsValues);
  let newData: any = [];
  const onPrintClicked = async (printType: any): Promise<void> => {
    const completedSlipsIds = selectedRowsValues
      .filter((item: any) => item.slipStatus === "Completed")
      .map((item: any) => item.id);
    console.log("completedSlipsIds", completedSlipsIds);
    // Convert arrays to strings and compare
    if (newData.length > 0) {
      const areArraysEqual =
        JSON.stringify(newData.sort()) ===
        JSON.stringify(completedSlipsIds.sort());
      if (!areArraysEqual) {
        setSnackbarData({
          open: true,
          message: "Please Selected T4A Completed Slips Only!",
          severity: "error",
        });
      } else {
        setIsLoadingData(true);
        const maskedStatus: any = sessionStorage.getItem("maskEnabled");
        if (maskedStatus === "true") {
          setIsLoadingData(false);
          setSnackbarData({
            open: true,
            message: "Please unmask your data.",
            severity: "error",
          });
        } else {
          if(invoicePaymentFor === 10){
            try {
              const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
                clientId,
                taxYear-1
              );
            try {
              const response: any = await ReportsService.createInvoice(
                clientId,
                taxYear
              );
              setIsLoadingData(false);
              console.log("responseresponse", response);
              if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
                if(t4aSlipIds && t4aSlipIds.generalAuditStatus !== null && t4aSlipIds.generalAuditStatus === true &&
                  t4aSlipIds && t4aSlipIds.auditStatus !== null &&  t4aSlipIds.auditStatus === true){
                  setIsLoadingData(true);
                  const ids_list: any = newData
                    .map((ids: any) => `t4a_slip_ids=${ids}`)
                    .join("&");
                  const pdfIds = ids_list.replace(/^&+|&+$/g, "");
                  setIsLoadingData(true);
                  try {
                    const response: any =
                      await PrintingService.createBulkT4APdfPrint(
                        clientId,
                        printType,
                        pdfIds,
                        taxYear
                      );
                    if (response.status === 400) {
                      setSnackbarData({
                        open: true,
                        message: response.data.message
                          ? response.data.message
                          : "Something went wrong.",
                        // message: "Please Unmask sensitive data for PDF printing.",
                        severity: "error",
                      });
                    } else {
                      const filename = `T4Aslip-${clientName}-${printType}.pdf`;
                      savePdfAsFile(response, filename);
                    }
                    setIsLoadingData(false);
                  } catch (err) {
                    console.log("err", err);
                    alert(err);
                  }
                // }
                setIsLoadingData(false);
                } else {
                  setIsLoadingData(false);
                  setSnackbarData({
                  open: true,
                  message: (
                    <>
                      Please load Audit in the menu, press Run T4A Audit and clear all audit items to generate documents.{" "}
                      <a
                        href="https://appv3.taxslips.com/#/audit/filing"
                        style={{
                          color: "#aa1010",
                          textDecoration: "none",
                          fontWeight: "bold",
                        }}
                      >
                        Click here to view
                      </a>
                    </>
                  ),
                  severity: "error",
                  autoHide: false,
                });
              }
    
              } else if (
                response?.id != null &&
                previousYearInvoiceResponse?.id != null
              ) {
                console.log("one");
                setInvoiceData([previousYearInvoiceResponse, response]);
                setInvoiceDialogOpen(true);
              } else if (
                previousYearInvoiceResponse?.id != null &&
                response?.message === "Slips not found for invoice."
              ) {
                console.log("two");
                setInvoiceData([previousYearInvoiceResponse]);
                setInvoiceDialogOpen(true);
              } else if (
                response?.id != null &&
                previousYearInvoiceResponse?.message === "Slips not found for invoice."
              ) {
                console.log("three");
                setInvoiceData([response]);
                setInvoiceDialogOpen(true);
              }else {
                setSnackbarData({
                  open: true,
                  message: "Something went wrong.",
                  severity: "error",
                });
              }
              setIsLoadingData(false);
            } catch (err) {
              console.log("err", err);
              setIsLoadingData(false);
            }
            } catch (err) {
              console.log("err", err);
              setIsLoadingData(false);
            }
          } else {
          try {
            const response: any = await ReportsService.createInvoice(
              clientId,
              taxYear
            );
            setIsLoadingData(false);
            console.log("responseresponse", response);
            if (response.message === "Slips not found for invoice.") {
              // setIsLoadingData(true);
              // try {
              //   const filingAuditResponse: any =
              //     await T4ASlipService.getT4AAuditFiling(clientId, taxYear);
              //   console.log("filingAuditResponse", filingAuditResponse);
              //   if (
              //     filingAuditResponse &&
              //     filingAuditResponse.general_filling &&
              //     filingAuditResponse.general_filling.length > 0
              //   ) {
              //     console.log(
              //       "filingAuditResponse.general_filling",
              //       filingAuditResponse.general_filling
              //     );
              //     setSnackbarData({
              //       open: true,
              //       message: (
              //         <>
              //           Please load Audit in the menu and clear all audit items
              //           (Audit Filing and PIER Review) to generate documents.{" "}
              //           <a
              //             href="https://appv3.taxslips.com/#/audit/filing"
              //             style={{
              //               color: "#aa1010",
              //               textDecoration: "none",
              //               fontWeight: "bold",
              //             }}
              //           >
              //             Click here to view
              //           </a>
              //         </>
              //       ),
              //       severity: "error",
              //       autoHide: false,
              //     });
              //   } else if (
              //     filingAuditResponse &&
              //     filingAuditResponse.t4a_filling &&
              //     filingAuditResponse.t4a_filling.length > 0
              //   ) {
              //     console.log(
              //       "filingAuditResponse.t4a_filling",
              //       filingAuditResponse.t4a_filling
              //     );
              //     setSnackbarData({
              //       open: true,
              //       message: (
              //         <>
              //           Please load Audit in the menu and clear all audit items
              //           (Audit Filing and PIER Review) to generate documents.{" "}
              //           <a
              //             href="https://appv3.taxslips.com/#/audit/filing"
              //             style={{
              //               color: "#aa1010",
              //               textDecoration: "none",
              //               fontWeight: "bold",
              //             }}
              //           >
              //             Click here to view
              //           </a>
              //         </>
              //       ),
              //       severity: "error",
              //       autoHide: false,
              //     });
              //   } else {
                if(t4aSlipIds && t4aSlipIds.generalAuditStatus !== null && t4aSlipIds.generalAuditStatus === true &&
                  t4aSlipIds && t4aSlipIds.auditStatus !== null &&  t4aSlipIds.auditStatus === true){
                  setIsLoadingData(true);
                  const ids_list: any = newData
                    .map((ids: any) => `t4a_slip_ids=${ids}`)
                    .join("&");
                  const pdfIds = ids_list.replace(/^&+|&+$/g, "");
                  setIsLoadingData(true);
                  try {
                    const response: any =
                      await PrintingService.createBulkT4APdfPrint(
                        clientId,
                        printType,
                        pdfIds,
                        taxYear
                      );
                    if (response.status === 400) {
                      setSnackbarData({
                        open: true,
                        message: response.data.message
                          ? response.data.message
                          : "Something went wrong.",
                        // message: "Please Unmask sensitive data for PDF printing.",
                        severity: "error",
                      });
                    } else {
                      const filename = `T4Aslip-${clientName}-${printType}.pdf`;
                      savePdfAsFile(response, filename);
                    }
                    setIsLoadingData(false);
                  } catch (err) {
                    console.log("err", err);
                    alert(err);
                  }
                // }
                setIsLoadingData(false);
                } else {
                  setIsLoadingData(false);
                  setSnackbarData({
                  open: true,
                  message: (
                    <>
                      Please load Audit in the menu, press Run T4A Audit and clear all audit items to generate documents.{" "}
                      <a
                        href="https://appv3.taxslips.com/#/audit/filing"
                        style={{
                          color: "#aa1010",
                          textDecoration: "none",
                          fontWeight: "bold",
                        }}
                      >
                        Click here to view
                      </a>
                    </>
                  ),
                  severity: "error",
                  autoHide: false,
                });
              }
              // } catch (err: any) {
              //   setIsLoadingData(false);
              //   alert(err);
              // }
            } else if (response && response.id !== null) {
              setInvoiceData([response]);
              setInvoiceDialogOpen(true);
            } else {
              setSnackbarData({
                open: true,
                message: "Something went wrong.",
                severity: "error",
              });
            }
            setIsLoadingData(false);
          } catch (err) {
            console.log("err", err);
          }
        }
      }
      }
    }
  };

  const onAllSlipsPrintClicked = async (event: any): Promise<void> => {
    setIsLoadingData(true);
    const maskedStatus: any = sessionStorage.getItem("maskEnabled");
    if (maskedStatus === "true") {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(t4aSlipIds && t4aSlipIds.generalAuditStatus !== null && t4aSlipIds.generalAuditStatus === true &&
              t4aSlipIds && t4aSlipIds.auditStatus !== null &&  t4aSlipIds.auditStatus === true){
              setIsLoadingData(true);
                try {
                  const response: any =
                    await PrintingService.createAllSlipsPdfPrint(
                      clientId,
                      event,
                      "T4A",
                      taxYear
                    );
                  console.log("response000", response);
                  if (response.status === 400) {
                    setSnackbarData({
                      open: true,
                      message: response.data.message
                        ? response.data.message
                        : "Something went wrong.",
                      // message: "Please Unmask sensitive data for PDF printing.",
                      severity: "error",
                    });
                  } else {
                    const filename = `T4Aslip-${clientName}-${event}.pdf`;
                    savePdfAsFile(response, filename);
                  }
                  setIsLoadingData(false);
                } catch (err) {
                  console.log("err", err);
                }
              // }
              setIsLoadingData(false);
              } else {
                setIsLoadingData(false);
                setSnackbarData({
                open: true,
                message: (
                  <>
                    Please load Audit in the menu, press Run T4A Audit and clear all audit items to generate documents.{" "}
                    <a
                      href="https://appv3.taxslips.com/#/audit/filing"
                      style={{
                        color: "#aa1010",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to view
                    </a>
                  </>
                ),
                severity: "error",
                autoHide: false,
              });
            }

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (response.message === "Slips not found for invoice.") {
          // setIsLoadingData(true);
          // try {
          //   const filingAuditResponse: any =
          //     await T4ASlipService.getT4AAuditFiling(clientId, taxYear);
          //   console.log("filingAuditResponse", filingAuditResponse);
          //   if (
          //     filingAuditResponse &&
          //     filingAuditResponse.general_filling &&
          //     filingAuditResponse.general_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.general_filling",
          //       filingAuditResponse.general_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else if (
          //     filingAuditResponse &&
          //     filingAuditResponse.t4a_filling &&
          //     filingAuditResponse.t4a_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.t4a_filling",
          //       filingAuditResponse.t4a_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else {
          //     setIsLoadingData(true);
          if(t4aSlipIds && t4aSlipIds.generalAuditStatus !== null && t4aSlipIds.generalAuditStatus === true &&
            t4aSlipIds && t4aSlipIds.auditStatus !== null &&  t4aSlipIds.auditStatus === true){
            setIsLoadingData(true);
              try {
                const response: any =
                  await PrintingService.createAllSlipsPdfPrint(
                    clientId,
                    event,
                    "T4A",
                    taxYear
                  );
                console.log("response000", response);
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    // message: "Please Unmask sensitive data for PDF printing.",
                    severity: "error",
                  });
                } else {
                  const filename = `T4Aslip-${clientName}-${event}.pdf`;
                  savePdfAsFile(response, filename);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
              }
            // }
            setIsLoadingData(false);
            } else {
              setIsLoadingData(false);
              setSnackbarData({
              open: true,
              message: (
                <>
                  Please load Audit in the menu, press Run T4A Audit and clear all audit items to generate documents.{" "}
                  <a
                    href="https://appv3.taxslips.com/#/audit/filing"
                    style={{
                      color: "#aa1010",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Click here to view
                  </a>
                </>
              ),
              severity: "error",
              autoHide: false,
            });
          }
          // } catch (err: any) {
          //   setIsLoadingData(false);
          //   alert(err);
          // }
        } else if (response && response.id !== null) {
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
      }
    }
    }
  };

  const onEmailButtonClicked = async (): Promise<void> => {
    const completedSlipsIds = selectedRowsValues
      .filter((item: any) => item.slipStatus === "Completed")
      .map((item: any) => item.id);
    console.log("completedSlipsIds", completedSlipsIds);
    // Convert arrays to strings and compare
    if (newData.length > 0) {
      const areArraysEqual =
        JSON.stringify(newData.sort()) ===
        JSON.stringify(completedSlipsIds.sort());
      if (!areArraysEqual) {
        setSnackbarData({
          open: true,
          message: "Please Selected T4A Completed Slips Only!",
          severity: "error",
        });
      } else {
        setIsLoadingData(true);
        const maskedStatus: any = sessionStorage.getItem("maskEnabled");
        if (maskedStatus === "true") {
          setIsLoadingData(false);
          setSnackbarData({
            open: true,
            message: "Please unmask your data.",
            severity: "error",
          });
        } else {
          if(invoicePaymentFor === 10){
            try {
              const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
                clientId,
                taxYear-1
              );
            try {
              const response: any = await ReportsService.createInvoice(
                clientId,
                taxYear
              );
              setIsLoadingData(false);
              console.log("responseresponse", response);
              if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
                if(t4aSlipIds && t4aSlipIds.generalAuditStatus !== null && t4aSlipIds.generalAuditStatus === true &&
                  t4aSlipIds && t4aSlipIds.auditStatus !== null &&  t4aSlipIds.auditStatus === true){
                  // const ids_list = slipList?.items.length === newData.length ? 
                  // t4aSlipIds.slipIds
                  //   .map((ids: any) => `slip_ids=${ids}`)
                  //   .join("&") :
                  //   newData
                  //   .map((ids: any) => `slip_ids=${ids}`)
                  //   .join("&");
                  // const pdfIds: any = ids_list.replace(/^&+|&+$/g, "");
                  const ids_list = 
                  (slipList?.items?.length === newData.length) 
                      ? {items:t4aSlipIds.slipIds} 
                      : {items:newData};
                      console.log("slipList?.items?.length === newData.length ",slipList?.items?.length === newData.length)
                      console.log("ids_list t4aSlipIds.slipIds ",t4aSlipIds.slipIds)
                      console.log("ids_list newData",newData)
                      console.log("ids_list",ids_list)
                  
                  setIsLoadingData(true);
                  try {
                    const tempResponse: any =
                      await T4SlipService.submitTemporaryData(
                        ids_list
                      );
                  const SubmitActualDataApi = async () => {
                    try {
                      const response: any =
                      await PrintingService.createBulkT4APdfEmail(
                        clientId,
                        tempResponse.id,
                        taxYear
                      );
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  };
                  SubmitActualDataApi();
                  setSnackbarData({
                    open: true,
                    message: "Email sending started!",
                    severity: "success",
                  });
                  setOpenProgressBarDialog(true)
                  //manage progress bar
                  let _val = percentageValue;
                  let consecutiveSameValueCount = 0; // Counter for consecutive same values
                  let lastAdjustedValue:any = null; // To track the last value
                  const fetchData = async () => {
                    try {
                      const response: any = await T4SlipService.temporaryItemsCount(tempResponse.id);
                      console.log("12345789", response);
                      console.log("12345789ff", response.fail);
                      console.log("12345789tt", tempResponse.totalFetched);
                      _val = Math.floor((response.counter / tempResponse.totalFetched) * 100); 
                      console.log("_val_val",_val)
                      setPercentageValue(_val);
                      // Check for consecutive same values
                      if (response.counter === lastAdjustedValue) {
                        consecutiveSameValueCount++;
                      } else {
                          consecutiveSameValueCount = 0; // Reset if value changes
                      }
                      lastAdjustedValue = response.counter;

                      if (consecutiveSameValueCount >= 4) {
                          console.log("Same value detected for 4 iterations, reloading screen.");
                          setSnackbarData({
                            open: true,
                            message: `Something went wrong, please try again later.`,
                            severity: "error",
                          });
                          setTimeout(()=>{
                            window.location.reload();
                          },2000)
                      }
                  
                      if (response.counter >= tempResponse.totalFetched) {
                        clearInterval(interval.current);
                        setPercentageValue(100)
                        
                        let totalSuccessfullImportedItems = response.counter - response.fail
                        setSnackbarData({
                          open: true,
                          message: `Email sent successfully to ${totalSuccessfullImportedItems} vendor(s) except following. ${response.name}`,
                          severity: "success",
                        });
                        // Set up the interval
                        interval.current = setInterval(() => {
                          setOpenProgressBarDialog(false)
                          // redirectToPage(CSVInformationUI.tag);
                          clearInterval(interval.current);
                        }, 3000);
                      }
                  
                  
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  };
                  
                  // Set up the interval
                  interval.current = setInterval(() => {
                    fetchData();
                  }, 4000);
          
                  // try {
                  //   const response: any =
                  //     await PrintingService.createBulkT4APdfEmail(
                  //       clientId,
                  //       pdfIds,
                  //       taxYear
                  //     );
                  //   if (response.status === 400) {
                  //     setSnackbarData({
                  //       open: true,
                  //       message: response.data.message
                  //         ? response.data.message
                  //         : "Something went wrong.",
                  //       // message: "Please Unmask sensitive data for PDF printing.",
                  //       severity: "error",
                  //     });
                  //   } else if (response.status === 201) {
                  //     setSnackbarData({
                  //       open: true,
                  //       message: response.data.message
                  //         ? response.data.message
                  //         : "Email send Successfully.",
                  //       severity: "success",
                  //     });
                  //   } else {
                  //     setSnackbarData({
                  //       open: true,
                  //       message: "Something Went Wrong.",
                  //       severity: "error",
                  //     });
                  //   }
                  //   setIsLoadingData(false);
                  // } catch (err) {
                  //   console.log("err", err);
                  //   alert(err);
                  // }
                  } catch (err) {
                    console.log("err", err);
                  }
                // }
                setIsLoadingData(false);
              // } catch (err: any) {
              //   setIsLoadingData(false);
              //   alert(err);
              // }
            } else {
                setIsLoadingData(false);
                setSnackbarData({
                open: true,
                message: (
                  <>
                    Please load Audit in the menu, press Run T4A Audit and clear all audit items to generate documents.{" "}
                    <a
                      href="https://appv3.taxslips.com/#/audit/filing"
                      style={{
                        color: "#aa1010",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to view
                    </a>
                  </>
                ),
                severity: "error",
                autoHide: false,
              });
            }
    
              } else if (
                response?.id != null &&
                previousYearInvoiceResponse?.id != null
              ) {
                console.log("one");
                setInvoiceData([previousYearInvoiceResponse, response]);
                setInvoiceDialogOpen(true);
              } else if (
                previousYearInvoiceResponse?.id != null &&
                response?.message === "Slips not found for invoice."
              ) {
                console.log("two");
                setInvoiceData([previousYearInvoiceResponse]);
                setInvoiceDialogOpen(true);
              } else if (
                response?.id != null &&
                previousYearInvoiceResponse?.message === "Slips not found for invoice."
              ) {
                console.log("three");
                setInvoiceData([response]);
                setInvoiceDialogOpen(true);
              }else {
                setSnackbarData({
                  open: true,
                  message: "Something went wrong.",
                  severity: "error",
                });
              }
              setIsLoadingData(false);
            } catch (err) {
              console.log("err", err);
              setIsLoadingData(false);
            }
            } catch (err) {
              console.log("err", err);
              setIsLoadingData(false);
            }
          } else {
          try {
            const response: any = await ReportsService.createInvoice(
              clientId,
              taxYear
            );
            setIsLoadingData(false);
            console.log("responseresponse", response);
            if (response.message === "Slips not found for invoice.") {
              // setIsLoadingData(true);
              // try {
              //   const filingAuditResponse: any =
              //     await T4ASlipService.getT4AAuditFiling(clientId, taxYear);
              //   console.log("filingAuditResponse", filingAuditResponse);
              //   if (
              //     filingAuditResponse &&
              //     filingAuditResponse.general_filling &&
              //     filingAuditResponse.general_filling.length > 0
              //   ) {
              //     console.log(
              //       "filingAuditResponse.general_filling",
              //       filingAuditResponse.general_filling
              //     );
              //     setSnackbarData({
              //       open: true,
              //       message: (
              //         <>
              //           Please load Audit in the menu and clear all audit items
              //           (Audit Filing and PIER Review) to send Email.{" "}
              //           <a
              //             href="https://appv3.taxslips.com/#/audit/filing"
              //             style={{
              //               color: "#aa1010",
              //               textDecoration: "none",
              //               fontWeight: "bold",
              //             }}
              //           >
              //             Click here to view
              //           </a>
              //         </>
              //       ),
              //       severity: "error",
              //       autoHide: false,
              //     });
              //   } else if (
              //     filingAuditResponse &&
              //     filingAuditResponse.t4a_filling &&
              //     filingAuditResponse.t4a_filling.length > 0
              //   ) {
              //     console.log(
              //       "filingAuditResponse.t4a_filling",
              //       filingAuditResponse.t4a_filling
              //     );
              //     setSnackbarData({
              //       open: true,
              //       message: (
              //         <>
              //           Please load Audit in the menu and clear all audit items
              //           (Audit Filing and PIER Review) to send Email.{" "}
              //           <a
              //             href="https://appv3.taxslips.com/#/audit/filing"
              //             style={{
              //               color: "#aa1010",
              //               textDecoration: "none",
              //               fontWeight: "bold",
              //             }}
              //           >
              //             Click here to view
              //           </a>
              //         </>
              //       ),
              //       severity: "error",
              //       autoHide: false,
              //     });
              //   }
                // else if(filingAuditResponse && filingAuditResponse.t4a_warnings && filingAuditResponse.t4a_warnings.length > 0){
                //   // Check if a string contains the word "email"
                //   const containsEmail = (str: string) => str.toLowerCase().includes('email is missing');
                //   const containsDOB = (str: string) => str.toLowerCase().includes('date of birth is missing');

                //   console.log("containsEmail",containsEmail)

                //   // Iterate over the array and log the string if it contains the word "email"
                //   filingAuditResponse && filingAuditResponse.t4a_warnings.forEach((str:any) => {
                //     if (containsEmail(str)) {
                //       console.log("strstrstr email",str);
                //       setSnackbarData({
                //         open: true,
                //         message: (
                //           <>
                //             {str}{' '}
                //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
                //                                             textDecoration:"none",
                //                                             fontWeight:"bold"}}>Click here to view</a>
                //           </>
                //         ),
                //         severity: "error",
                //         autoHide:false
                //       });
                //     } else if (containsDOB(str)) {
                //       console.log("strstrstr dob",str);
                //       setSnackbarData({
                //         open: true,
                //         message: (
                //           <>
                //             {str}{' '}
                //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
                //                                             textDecoration:"none",
                //                                             fontWeight:"bold"}}>Click here to view</a>
                //           </>
                //         ),
                //         severity: "error",
                //         autoHide:false
                //       });
                //     }
                //   });
                // setSnackbarData({
                //   open: true,
                //   message: (
                //     <>
                //       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
                //       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
                //                                       textDecoration:"none",
                //                                       fontWeight:"bold"}}>Click here to view</a>
                //     </>
                //   ),
                //   severity: "error",
                //   autoHide:false
                // });
                // }
                // else {
                  if(t4aSlipIds && t4aSlipIds.generalAuditStatus !== null && t4aSlipIds.generalAuditStatus === true &&
                    t4aSlipIds && t4aSlipIds.auditStatus !== null &&  t4aSlipIds.auditStatus === true){
                    // const ids_list = slipList?.items.length === newData.length ? 
                    // t4aSlipIds.slipIds
                    //   .map((ids: any) => `slip_ids=${ids}`)
                    //   .join("&") :
                    //   newData
                    //   .map((ids: any) => `slip_ids=${ids}`)
                    //   .join("&");
                    // const pdfIds: any = ids_list.replace(/^&+|&+$/g, "");
                    const ids_list = 
                    (slipList?.items?.length === newData.length) 
                        ? {items:t4aSlipIds.slipIds} 
                        : {items:newData};
                        console.log("slipList?.items?.length === newData.length ",slipList?.items?.length === newData.length)
                        console.log("ids_list t4aSlipIds.slipIds ",t4aSlipIds.slipIds)
                        console.log("ids_list newData",newData)
                        console.log("ids_list",ids_list)
                    
                    setIsLoadingData(true);
                    try {
                      const tempResponse: any =
                        await T4SlipService.submitTemporaryData(
                          ids_list
                        );
                    const SubmitActualDataApi = async () => {
                      setPercentageValue(0)
                      try {
                        const response: any =
                        await PrintingService.createBulkT4APdfEmail(
                          clientId,
                          tempResponse.id,
                          taxYear
                        );
                      } catch (error) {
                        console.error("Error fetching data:", error);
                      }
                    };
                    SubmitActualDataApi();
                    setSnackbarData({
                      open: true,
                      message: "Email sending started!",
                      severity: "success",
                    });
                    setOpenProgressBarDialog(true)
                    //manage progress bar
                    let _val = percentageValue;
                    let consecutiveSameValueCount = 0; // Counter for consecutive same values
                    let lastAdjustedValue:any = null; // To track the last value
                    const fetchData = async () => {
                      try {
                        const response: any = await T4SlipService.temporaryItemsCount(tempResponse.id);
                        console.log("12345789", response);
                        console.log("12345789ff", response.fail);
                        console.log("12345789tt", tempResponse.totalFetched);
                        _val = Math.floor((response.counter / tempResponse.totalFetched) * 100); 
                        console.log("_val_val",_val)
                        setPercentageValue(_val);
                        // Check for consecutive same values
                        if (response.counter === lastAdjustedValue) {
                          consecutiveSameValueCount++;
                        } else {
                            consecutiveSameValueCount = 0; // Reset if value changes
                        }
                        lastAdjustedValue = response.counter;

                        if (consecutiveSameValueCount >= 4) {
                            console.log("Same value detected for 4 iterations, reloading screen.");
                            setSnackbarData({
                              open: true,
                              message: `Something went wrong, please try again later.`,
                              severity: "error",
                            });
                            setTimeout(()=>{
                              window.location.reload();
                            },2000)
                        }
                    
                        if (response.counter >= tempResponse.totalFetched) {
                          clearInterval(interval.current);
                          setPercentageValue(100)
                          
                          let totalSuccessfullImportedItems = response.counter - response.fail
                          setSnackbarData({
                            open: true,
                            message: `Email sent successfully to ${totalSuccessfullImportedItems} vendor(s) except following. ${response.name}`,
                            severity: "success",
                          });
                          // Set up the interval
                          interval.current = setInterval(() => {
                            setOpenProgressBarDialog(false)
                            // redirectToPage(CSVInformationUI.tag);
                            clearInterval(interval.current);
                          }, 3000);
                        }
                    
                    
                      } catch (error) {
                        console.error("Error fetching data:", error);
                      }
                    };
                    
                    // Set up the interval
                    interval.current = setInterval(() => {
                      fetchData();
                    }, 4000);
            
                    // try {
                    //   const response: any =
                    //     await PrintingService.createBulkT4APdfEmail(
                    //       clientId,
                    //       pdfIds,
                    //       taxYear
                    //     );
                    //   if (response.status === 400) {
                    //     setSnackbarData({
                    //       open: true,
                    //       message: response.data.message
                    //         ? response.data.message
                    //         : "Something went wrong.",
                    //       // message: "Please Unmask sensitive data for PDF printing.",
                    //       severity: "error",
                    //     });
                    //   } else if (response.status === 201) {
                    //     setSnackbarData({
                    //       open: true,
                    //       message: response.data.message
                    //         ? response.data.message
                    //         : "Email send Successfully.",
                    //       severity: "success",
                    //     });
                    //   } else {
                    //     setSnackbarData({
                    //       open: true,
                    //       message: "Something Went Wrong.",
                    //       severity: "error",
                    //     });
                    //   }
                    //   setIsLoadingData(false);
                    // } catch (err) {
                    //   console.log("err", err);
                    //   alert(err);
                    // }
                    } catch (err) {
                      console.log("err", err);
                    }
                  // }
                  setIsLoadingData(false);
                // } catch (err: any) {
                //   setIsLoadingData(false);
                //   alert(err);
                // }
              } else {
                  setIsLoadingData(false);
                  setSnackbarData({
                  open: true,
                  message: (
                    <>
                      Please load Audit in the menu, press Run T4A Audit and clear all audit items to generate documents.{" "}
                      <a
                        href="https://appv3.taxslips.com/#/audit/filing"
                        style={{
                          color: "#aa1010",
                          textDecoration: "none",
                          fontWeight: "bold",
                        }}
                      >
                        Click here to view
                      </a>
                    </>
                  ),
                  severity: "error",
                  autoHide: false,
                });
              }
              // } catch (err: any) {
              //   setIsLoadingData(false);
              //   alert(err);
              // }
            } else if (response && response.id !== null) {
              setInvoiceData([response]);
              setInvoiceDialogOpen(true);
            } else {
              setSnackbarData({
                open: true,
                message: "Something went wrong.",
                severity: "error",
              });
            }
            setIsLoadingData(false);
          } catch (err) {
            console.log("err", err);
          }
        }
      }
    }
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusValue(event.target.value as string);
    setApiAction("updateStatus");
    setDialogOpen(true);
  };

  const onRowMultipleDeleteClicked = (params: any) => {
    console.log("Params in onRowMultipleDeleteClicked ====== ", params);
    let bulkDeletePayload = {
      ids: { slipIds: params },
      clientId,
      taxYear,
    };
    setDialogOpen(true);
    setBulkDelete(true);
    setApiAction("delete");
    setBulkT4ASlipsId(bulkDeletePayload);
    setBulkT4ASlipsDelete(true);
  };

  const selectionToolbar = useCallback(
    (selectedItems) => {
      setSelectedIds(selectedItems);
      newData = selectedItems;
      if (selectedItems.length) {
        return (
          <Box
            sx={{
              marginTop: theme.spacing(1),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {slipList && slipList?.items.length === selectedItems.length ? (
              <Box
                sx={{
                  marginRight: "1rem",
                  textTransform: "capitalize",
                  display: "inline-block",
                  "& .MuiPopper-root": { zIndex: 2 },
                }}
              >
                <SplitButton
                  options={["Vendor", "Employer", "CRA"]}
                  onClickHandler={onAllSlipsPrintClicked}
                  testId="t4-print-button"
                  icon={<PrintIcon />}
                  listItemsLabel={`Print T4A Slips `}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  marginRight: "1rem",
                  textTransform: "capitalize",
                  display: "inline-block",
                  "& .MuiPopper-root": { zIndex: 2 },
                }}
              >
                <SplitButton
                  options={["Vendor", "Employer", "CRA"]}
                  onClickHandler={onPrintClicked}
                  testId="t4-print-button"
                  icon={<PrintIcon />}
                  listItemsLabel={`Print T4A Slips `}
                />
              </Box>
            )}

            <Box
              sx={{
                marginRight: "1rem",
                textTransform: "capitalize",
                display: "inline-block",
              }}
            >
              <Button
                variant="outlined"
                startIcon={<EmailIcon />}
                onClick={onEmailButtonClicked}
              >
                Email
              </Button>
            </Box>
            <Button
              onClick={() => onRowMultipleDeleteClicked(selectedItems)}
              // sx={{marginBottom:"1rem"}}
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          </Box>
        );
      }
      return (
        <Box sx={{ marginTop: theme.spacing(1), display: "flex" }}>
          <Box
            sx={{
              marginRight: "1rem",
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            {AdjustmentOptionsData &&
            AdjustmentOptionsData.remittanceTransferred ? (
              <Tooltip title="Remittance has been transferred.">
                <span>
                  <Button startIcon={<ExitToAppIcon />} disabled>
                    Import CSV
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <EnhancedLinkButton
                text={"Import CSV"}
                icon={<ExitToAppIcon />}
                navigateTo={"/taxslip/t4a/import"}
                testId={"import-t4a-slip-button"}
              />
            )}
          </Box>
          {AdjustmentOptionsData &&
          AdjustmentOptionsData.remittanceTransferred ? (
            <Tooltip title="Remittance has been transferred.">
              <span>
                <Button variant="outlined" disabled>
                  Add T4A Slip
                </Button>
              </span>
            </Tooltip>
          ) : (
            <RouterLinkButton to="/taxslip/t4a/setup" text="Add T4A Slip" />
          )}
        </Box>
      );
    },
    [theme, selectedRowsValues]
  );

  const onUnPaidClickHandler = (clickedRow: any, tag: string) => {
    setIsLoadingData(true);
    // setTimeout(() => {
    //   setIsLoadingData(false);
    // }, 2500);
    const fetchData = async () => {
      try {
        const response: any =
          await ReportsService.paypalIntegrationOfUnpaidInvoice(
            id,
            clickedRow.refNo,
            tag
          );
        setIsLoadingData(false);
        console.log("responseData111-----------------", response);
        if (tag === "paypal") {
          window.open(response, "_blank");
        } else {
          window.open(response.message, "_blank");
        }
      } catch (error) {
        setIsLoadingData(false);
        console.error("Error fetching data:", error);
      } finally {
        setIsLoadingData(false);
      }
    };
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const onClickInvoiceNoHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: T4A_LIST_SCREEN_SLUG,
        companyName: clientName,
        activityType:
          "activity_type=delete&activity_type=emailed&activity_type=print&activity_type=import_csv",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({ isSticky: false }));
  }, []);

  const image =
    "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";

  return slipList && slipList.items.length === 0 && zeroClient === true ? (
    <EmptyList
      showImage={true}
      imagePath={image}
      heading={`There's nothing! Add T4A Slip to proceed`}
      content={`You can add t4a slip by directly clicking on add button or by importing csv file.`}
      testId={"t4aslip-empty-list"}
      showAddButton={true}
      addButtonLabel={`Add T4A Slip`}
      navigateToAdd={"/taxslip/t4a/setup"}
      showImportButton={true}
      importButtonLabel="Import CSV"
      navigateToImport={"/taxslip/t4a/import"}
      // showImportXmlButton={true}
      // importXmlButtonLabel="Import XML"
      // navigateToImportXml={"/taxslip/t4a/import-xml"}
    />
  ) : (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
        <BackdropCircularProgress
          open={
            isBulkDeleting ||
            isDeleting ||
            isLoadingData ||
            isLoadingAdjustments
          }
          testId="T4A Slip List"
        />
        <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={
            bulkT4ASlipsDelete === true
              ? deleteBulkT4ASlipsField
              : deleteT4ASlipField
          }
          cancelActionHandler={onCloseHandler}
          testId="T4A-confirmation-dialog"
        />
        {/* <EnhancedDataGrid
        columns={slipList ? slipList.headerColumns : []}
        rows={slipList && t4aSlipListUpdatedItems ? t4aSlipListUpdatedItems : []}
        selectionEnabled={true}
        autoHeight={true}
        allowSearching={true}
        onSearchButtonClicked={onSearchButtonClicked}
        searchFieldPlaceholder={"Search by Employee Name..."}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        selectionToolbar={selectionToolbar}
        selectedRowsValues={(value:any)=>setSelectedRowsValues(value)}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        // initialPage={0}
        initialPageSize={INITIAL_PAGE_SIZE}
        disableColumnMenu={true}
        showRowActionsColumn={true}
        autoPageSize={false} //
        showIndexColumn={true}
        testId="resource-list-grid"
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onRequestParamsChange={onRequestParamsChange}
        onSearchCrossClicked={(value) => setzeroClient(value)}
        totalRowsCount={slipList?.total}
        isLoading={isLoading || isAxiosCallLoading}
        rowIdGetter={(row) => row.id}
        searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
        shouldMask={false}
      /> */}

        <PrimeDataGrid
          columns={slipList ? slipList.headerColumns : []}
          rows={
            slipList && t4aSlipListUpdatedItems ? t4aSlipListUpdatedItems : []
          }
          showIndexColumn={true}
          showChecboxColumn={true}
          showSettingColumn={true}
          onRowEditClicked={onRowEditClicked}
          onRowDeleteClicked={onRowDeleteClicked}
          isLoading={isLoading || isAxiosCallLoading}
          onSnackbarMessageData={(value: any) => setSnackbarData(value)}
          totalRecords={slipList && slipList.total ? slipList.total : 0}
          initialPageSize={INITIAL_PAGE_SIZE}
          rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
          onRequestParamsChange={onRequestParamsChange}
          selectionToolbar={selectionToolbar}
          selectedRowsValues={(value: any) => setSelectedRowsValues(value)}
          searchFieldPlaceholder={"Search by Employee Name..."}
          changeLoginForGetEmptyItemsBySearchQuery={(value: any) =>
            setzeroClient(value)
          }
        />
        {/* invoice dialogu open if any transaction is unPaid */}
        <EnhancedDialog
          dialogTitle="Invoice(s) Due"
          key="dialog-box"
          testId="dialog"
          onCloseHandler={onCloseHandler}
          open={openInvoiceDialog}
          dialogContent={
            <InvoiceDialogPanel
              invoiceData={invoiceData}
              onUnPaidClickHandler={onUnPaidClickHandler}
              onClickInvoiceNoHandler={onClickInvoiceNoHandler}
            />
          }
          dialogAction={
            <Grid>
              <Button autoFocus variant="contained" onClick={onCloseHandler}>
                Cancel
              </Button>
            </Grid>
          }
        />

        {/* Email Send Dialog */}
        <EnhancedDialog
            dialogTitle={`Email sending`}
            key="dialog-box-1"
            testId="dialog"
            onCloseHandler={onCloseHandler}
            showCloseButton={false}
            open={openProgressBarDialog}
            dialogContent={
              <ProgressBar 
                value={percentageValue}
                pt={{
                    value: { style: { background: 'linear-gradient(to right, #8e2de2, #4a00e0)' } }
                }}
              ></ProgressBar>
            }
            dialogAction={
              <></>
            }
          />
      </Grid>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"T4A-list-snackbar"}
        open={snackbarData.open}
        autoHide={snackbarData.autoHide}
      />
    </>
  );
};
