import RL3SlipImportLayout from "components/RL3Slip/RL3SlipImportLayout/RL3SlipImportLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL3SlipImportPage {}

export const RL3SlipImportPage: FC<IRL3SlipImportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import RL-3 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl3/list" },
        { text: "RL-3", navigateTo: "/taxslip/rl3/list" },
        { text: "Import RL-3 Slips", navigateTo: "/taxslip/rl3/import" },
      ]}
      mainContents={<RL3SlipImportLayout />}
    />
  );
};
