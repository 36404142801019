import T3SlipImportXmlLayout from "components/T3Slip/T3SlipImportXMLLayout/T3SlipImportXmlLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT3SlipImportXmlPage {}

export const T3SlipImportXmlPage: FC<IT3SlipImportXmlPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import XML T3 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t3/list" },
        { text: "T3", navigateTo: "/taxslip/t3/list" },
        { text: "Import XML T3 Slips", navigateTo: "/taxslip/t3/import-xml" },
      ]}
      mainContents={<T3SlipImportXmlLayout />}
    />
  );
};
