import { AdjustmentOptionsSetupPage } from "pages/AdjustmentOptions";
import { AdjustmentsSetupPage } from "pages/Adjustments";
import { AuditFilingSetupPage } from "pages/AuditFiling";
import {
  BillingProfileListPage,
  BillingProfileSetupPage,
  TaxProfileAssociationPage,
  TaxProfileListPage,
  TaxProfileSetupPage,
} from "pages/BillingProfile";
import {
  ClientDashboardPage,
  ClientListPage,
  ClientSetupPage,
} from "pages/Client";
import { DataRoleListPage, DataRoleSetupPage } from "pages/DataRole";
import {
  FilingResourceListPage,
  FilingResourceSetupPage,
} from "pages/FilingResource";
import {
  FlexFieldListPage,
  FlexFieldPanelAssignmentPage,
  FlexFieldPanelListPage,
  FlexFieldPanelSetupPage,
  FlexFieldScreenFieldsetPage,
  FlexFieldSetupPage,
  FlexFieldTabListPage,
  FlexFieldTabSetupPage,
} from "pages/FlexField";
import { GlobalSearchPage } from "pages/GlobalSearch";
import { LookupFieldListPage, LookupFieldSetupPage } from "pages/LookupField";
import { EmployeeReportPage, InvoiceReportPage } from "pages/Reports";
import { ResourceListPage, ResourceSetupPage } from "pages/Resource";
import {
  RL1SlipListPage,
  RL1SlipSetupPage,
  RL1SlipSummarySetupPage,
} from "pages/RL1Slip";
import { RL1SlipSettingsSetupPage } from "pages/RL1Slip/RL1SlipSettingsSetupPage";
import { TaxSlipsGlobalSettingsPage } from "pages/Settings";
import {
  T4ASlipListPage,
  T4ASlipSetupPage,
  T4ASlipSummarySetupPage,
} from "pages/T4ASlip";
import {
  T4SlipListPage,
  T4SlipSetupPage,
  T4SlipSummaryReducedEISetupPage,
  T4SlipSummarySetupPage,
  T4SlipImportPage,
} from "pages/T4Slip";
import {
  TaxSlipCategoryListPage,
  TaxSlipCategorySetupPage,
} from "pages/TaxSlipCategory";
import { TaxSlipYearListPage, TaxSlipYearSetupPage } from "pages/TaxSlipYear";

import { IProtectedRouteItem } from "@websential/cosmic";

// import { lazy } from 'react';
import {
  AccountantListPage,
  AccountantSetupPage,
  DashboardPage,
  ForgotPasswordPage,
  LogoutPage,
} from "../../pages";
import { ChangePasswordPage } from "pages/Me";
import { T4ASlipImportPage } from "pages/T4ASlip/T4ASlipImportPage";
import { RL1SlipImportPage } from "pages/RL1Slip/RL1SlipImportPage";
import { T4ReportPage } from "pages/Reports/T4ReportPage/T4ReportPage";
import { T4AReportPage } from "pages/Reports/T4AReportPage";
import { Rl1AdjustmentReportPage } from "pages/Adjustments/RL1AdjustmentReport";
import { T4AdjustmentReportPage } from "pages/Adjustments/T4AdjustmentReport";
import { RL1ReportPage } from "pages/Reports/RL1ReportPage";
import { InvoiceReportAdminPage } from "pages/Reports/InvoiceReportPage/InvoiceReportAdminPage";
import { VendorReportPage } from "pages/Reports/VendorReportPage/VendorReportPage";
import { InvoiceDetailPage } from "pages/InvoiceDetail/InvoiceDetailPage";
import { ResourceImportPage } from "components/Resource/ResourceImportPage";
import { T4SlipImportXmlPage } from "pages/T4Slip/T4SlipImportXMLPage";
import { RL1SlipImportXmlPage } from "pages/RL1Slip/RL1SlipImportXmlPage";
import { PierReviewSetupPage } from "pages/AuditFiling/PierReview";
import { UndoMergeResourcesPage } from "pages/Resource/UndoMergeResourcesPage";
import { MergeResourcesPage } from "pages/Resource/MergeResourcesPage";
import { JournalCodeAdminPage } from "pages/Reports/GeneralClient";
import { UpdateInvoiceAdminPage } from "pages/Reports/UpdateInvoiceAdminPage";
import { InvoiceTransactionDetailPage } from "pages/InvoiceTransactionDetail";
import { JournalExportAdminPage } from "pages/Reports/JournalExpertsAdminPage";
import { CSVInformationUIPage } from "pages/T4Slip/T4SlipInformationPage";
import { APIKeysPage } from "pages/Me/APIKeysPage";
import { MoveClientListPage } from "pages/Client/MoveClientListPage";
import { TransactionsReportAdminPage } from "pages/Reports/TransactionsReportAdminPage";
import {
  T5SlipListPage,
  T5SlipSetupPage,
  T5SlipSummarySetupPage,
} from "pages/T5Slip";
import { T5SlipImportPage } from "pages/T5Slip/T5SlipImportPage";
import { EarningsAuditSetupPage } from "pages/AuditFiling/EarningsAuditSetupPage";
import { ActivityLogReportPage } from "pages/Reports/ActivityLogReportPage";
import { MoveResourcePage } from "pages/Resource/MoveResourcePage";
import { T5ReportPage } from "pages/Reports/T5ReportPage";
import { RL3SlipListPage, RL3SlipSetupPage, RL3SlipSummarySetupPage } from "pages/RL3Slip";
import { RL3SlipImportPage } from "pages/RL3Slip/RL3SlipImportPage";
import { T5SlipImportXmlPage } from "pages/T5Slip/T5SlipImportXMLPage";
import { RL3ReportPage } from "pages/Reports/RL3ReportPage";
import { DefaultSettingsPage } from "pages/Me/DefaultSettingsPage";
import { ClientAdjustmentReportPage } from "pages/Reports/ClientAdjustmentReportPage";
import { PDFFilesPage } from "pages/PDF Settings/PDFFilesPage";
import { PDFAxisPage } from "pages/PDF Settings/PDFFilesPage/PDFAxisPage";
import { TaxSlipDefinitionsListPage } from "pages/TaxSlipCategory/TaxSlipDefinitionsListPage";
import { TaxSlipDefinitionsSetupPage } from "pages/TaxSlipCategory/TaxSlipDefinitionsSetup";
import { T3SlipImportXmlPage } from "pages/T3Slip/T3SlipImportXMLPage";
import { T3SlipImportPage } from "pages/T3Slip/T3SlipImportPage";
import { T3SlipListPage, T3SlipSetupPage, T3SlipSummarySetupPage } from "pages/T3Slip";
import { T3ReportPage } from "pages/Reports/T3ReportPage";
import { RL16SlipListPage, RL16SlipSetupPage, RL16SlipSummarySetupPage } from "pages/RL16Slip";
import { RL16SlipImportPage } from "pages/RL16Slip/RL16SlipImportPage";
import { RL16ReportPage } from "pages/Reports/RL16ReportPage";
import { T3SlipSettingsSetupPage } from "pages/T3Slip/T3SlipSettingsSetupPage";
import { RL16SlipSettingsSetupPage } from "pages/RL16Slip/RL16SlipSettingsSetupPage";
import { RL16SlipImportXmlPage } from "pages/RL16Slip/RL16SlipImportXMLPage";
import { RL3SlipImportXmlPage } from "pages/RL3Slip/RL3SlipImportXMLPage";
export const routes: IProtectedRouteItem[] = [
  {
    path: "/",
    // component:ClientDashboardPage,
    component: GlobalSearchPage,
    exact: true,
  },
];
export const nonSuperUserInitialRoutesIfAccAssign: IProtectedRouteItem[] = [
  {
    path: "/",
    // component:DashboardPage,
    component: AccountantListPage,
    exact: true,
  },
];
export const superUserInitialClientListRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    // component:DashboardPage,
    component: ClientListPage,
    exact: true,
  },
];
export const nonSuperUserInitialRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    // component:DashboardPage,
    component: ClientListPage,
    exact: true,
  },
];
export const clientDashboardInitialRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    // component:DashboardPage,
    component: ClientDashboardPage,
    exact: true,
  },
];

export const GlobalSearchRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: GlobalSearchPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];
export const ForgetPasswordRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: ForgotPasswordPage,
    exact: true,
  },
  {
    path: "/:key",
    component: ForgotPasswordPage,
    exact: true,
  },
];

export const LookupFieldRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: LookupFieldListPage,
    exact: true,
  },
  {
    path: "list",
    component: LookupFieldListPage,
    exact: true,
  },
  {
    path: "setup",
    component: LookupFieldSetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: LookupFieldSetupPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const DataRoleRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: DataRoleListPage,
    exact: true,
  },
  {
    path: "list",
    component: DataRoleListPage,
    exact: true,
  },
  {
    path: "setup",
    component: DataRoleSetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: DataRoleSetupPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const AccountantsRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: AccountantListPage,
    exact: true,
  },
  {
    path: "list",
    component: AccountantListPage,
    exact: true,
  },
  {
    path: "setup",
    component: AccountantSetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: AccountantSetupPage,
    exact: true,
  },
  {
    path: "clients",
    component: ClientListPage,
    exact: true,
  },
  {
    path: "move-clients",
    component: MoveClientListPage,
    exact: true,
  },
  {
    path: "/client-adjustment-report",
    component: ClientAdjustmentReportPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const ClientRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: ClientListPage,
    exact: true,
  },
  {
    path: "list",
    component: ClientListPage,
    exact: true,
  },
  {
    path: "setup",
    component: ClientSetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: ClientSetupPage,
    exact: true,
  },
  {
    path: "dashboard",
    component: ClientDashboardPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const ResourceRoutes: IProtectedRouteItem[] = [
  {
    path: ":resourceName",
    component: ResourceListPage,
    exact: true,
  },
  {
    path: ":resourceName/list",
    component: ResourceListPage,
    exact: true,
  },
  {
    path: ":resourceName/setup",
    component: ResourceSetupPage,
    exact: true,
  },
  {
    path: ":resourceName/setup/:id",
    component: ResourceSetupPage,
    exact: true,
  },
  {
    path: ":resourceName/move-people",
    component: MoveResourcePage,
    exact: true,
  },
  {
    path: ":resourceName/import",
    component: ResourceImportPage,
    exact: true,
  },
  {
    path: ":resourceName/merge",
    component: MergeResourcesPage,
    exact: true,
  },
  {
    path: ":resourceName/undo-merge",
    component: UndoMergeResourcesPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const FilingResourceRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: FilingResourceListPage,
    exact: true,
  },
  {
    path: "list",
    component: FilingResourceListPage,
    exact: true,
  },
  {
    path: "setup",
    component: FilingResourceSetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: FilingResourceSetupPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const YearsRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: TaxSlipYearListPage,
    exact: true,
  },
  {
    path: "list",
    component: TaxSlipYearListPage,
    exact: true,
  },
  {
    path: "setup",
    component: TaxSlipYearSetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: TaxSlipYearSetupPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const GlobalVariablesRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: TaxSlipsGlobalSettingsPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const FlexFieldRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: FlexFieldListPage,
    exact: true,
  },
  {
    path: "list",
    component: FlexFieldListPage,
    exact: true,
  },
  {
    path: "setup",
    component: FlexFieldSetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: FlexFieldSetupPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const FlexFieldTabRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: FlexFieldTabListPage,
    exact: true,
  },
  {
    path: "list",
    component: FlexFieldTabListPage,
    exact: true,
  },
  {
    path: "setup",
    component: FlexFieldTabSetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: FlexFieldTabSetupPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const FlexFieldPanelRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: FlexFieldPanelListPage,
    exact: true,
  },
  {
    path: "list",
    component: FlexFieldPanelListPage,
    exact: true,
  },
  {
    path: "setup",
    component: FlexFieldPanelSetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: FlexFieldPanelSetupPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const FlexFieldPanelAssignmentRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: FlexFieldPanelAssignmentPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const FlexFieldScreenSetupRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: FlexFieldScreenFieldsetPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const TaxSlipCategoryRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: TaxSlipCategoryListPage,
    exact: true,
  },
  {
    path: "list",
    component: TaxSlipCategoryListPage,
    exact: true,
  },
  {
    path: "setup",
    component: TaxSlipCategorySetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: TaxSlipCategorySetupPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];
export const TaxSlipDefinitionsRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: TaxSlipDefinitionsListPage,
    exact: true,
  },
  {
    path: "list",
    component: TaxSlipDefinitionsListPage,
    exact: true,
  },
  {
    path: "setup",
    component: TaxSlipDefinitionsSetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: TaxSlipDefinitionsSetupPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const PDFSettingsRoutes: IProtectedRouteItem[] = [
  {
    path: "pdf-files",
    component: PDFFilesPage,
    exact: true,
  },
  {
    path: "pdf-axis",
    component: PDFAxisPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const BillingProfileRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: BillingProfileListPage,
    exact: true,
  },
  {
    path: "list",
    component: BillingProfileListPage,
    exact: true,
  },
  {
    path: "setup",
    component: BillingProfileSetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: BillingProfileSetupPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const TaxProfileRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: TaxProfileListPage,
    exact: true,
  },
  {
    path: "list",
    component: TaxProfileListPage,
    exact: true,
  },
  {
    path: "setup",
    component: TaxProfileSetupPage,
    exact: true,
  },
  {
    path: "setup/:id",
    component: TaxProfileSetupPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const BillingProfileAssociationRoutes: IProtectedRouteItem[] = [
  {
    path: "/",
    component: TaxProfileAssociationPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const AdminReportsRoutes: IProtectedRouteItem[] = [
  {
    path: "/invoices",
    component: InvoiceReportAdminPage,
    exact: true,
  },
  {
    path: "/update-invoice",
    component: UpdateInvoiceAdminPage,
    exact: true,
  },
  {
    path: "/transactions-report",
    component: TransactionsReportAdminPage,
    exact: true,
  },
  {
    path: "/journal-code",
    component: JournalCodeAdminPage,
    exact: true,
  },
  {
    path: "/journal-export",
    component: JournalExportAdminPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];
export const ReportsRoutes: IProtectedRouteItem[] = [
  {
    path: "/employees",
    component: EmployeeReportPage,
    exact: true,
  },
  {
    path: "/vendors",
    component: VendorReportPage,
    exact: true,
  },
  {
    path: "/t4-reports",
    component: T4ReportPage,
    exact: true,
  },
  {
    path: "/rl1-reports",
    component: RL1ReportPage,
    exact: true,
  },
  {
    path: "/t4a-reports",
    component: T4AReportPage,
    exact: true,
  },
  {
    path: "/t5-reports",
    component: T5ReportPage,
    exact: true,
  },
  {
    path: "/t3-reports",
    component: T3ReportPage,
    exact: true,
  },
  {
    path: "/rl3-reports",
    component: RL3ReportPage,
    exact: true,
  },
  {
    path: "/rl16-reports",
    component: RL16ReportPage,
    exact: true,
  },
  {
    path: "/invoice-report",
    component: InvoiceReportPage,
    exact: true,
  },
  {
    path: "/invoice-detail",
    component: InvoiceDetailPage,
    exact: true,
  },
  {
    path: "/transaction-detail",
    component: InvoiceTransactionDetailPage,
    exact: true,
  },
  {
    path: "/activity-log-report",
    component: ActivityLogReportPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const TaxSlipRoutes: IProtectedRouteItem[] = [
  {
    path: "t4/list",
    component: T4SlipListPage,
    exact: true,
  },
  {
    path: "t4/setup",
    component: T4SlipSetupPage,
    exact: true,
  },
  {
    path: "t4/setup/:id",
    component: T4SlipSetupPage,
    exact: true,
  },
  {
    path: "t4/summary",
    component: T4SlipSummarySetupPage,
    exact: true,
  },
  {
    path: "t4/summary-reduced-ei",
    component: T4SlipSummaryReducedEISetupPage,
    exact: true,
  },
  {
    path: "t4/import",
    component: T4SlipImportPage,
    exact: true,
  },
  {
    path: "t4/import-xml",
    component: T4SlipImportXmlPage,
    exact: true,
  },
  {
    path: "csv-information",
    component: CSVInformationUIPage,
    exact: true,
  },
  // {
  //   path: "t4/pd27-e-setup",
  //   component: T4SlipSummaryReducedEISetupPage,
  //   exact: true,
  // },
  {
    path: "t4a/list",
    component: T4ASlipListPage,
    exact: true,
  },
  {
    path: "t4a/setup",
    component: T4ASlipSetupPage,
    exact: true,
  },
  {
    path: "t4a/setup/:id",
    component: T4ASlipSetupPage,
    exact: true,
  },
  {
    path: "t4a/summary",
    component: T4ASlipSummarySetupPage,
    exact: true,
  },
  {
    path: "t4a/import",
    component: T4ASlipImportPage,
    exact: true,
  },
  {
    path: "t5/list",
    component: T5SlipListPage,
    exact: true,
  },
  {
    path: "t5/setup",
    component: T5SlipSetupPage,
    exact: true,
  },
  {
    path: "t5/setup/:id",
    component: T5SlipSetupPage,
    exact: true,
  },
  {
    path: "t5/summary",
    component: T5SlipSummarySetupPage,
    exact: true,
  },
  {
    path: "t5/import",
    component: T5SlipImportPage,
    exact: true,
  },
  {
    path: "t5/import-xml",
    component: T5SlipImportXmlPage,
    exact: true,
  },
  {
    path: "t3/list",
    component: T3SlipListPage,
    exact: true,
  },
  {
    path: "t3/setup",
    component: T3SlipSetupPage,
    exact: true,
  },
  {
    path: "t3/setup/:id",
    component: T3SlipSetupPage,
    exact: true,
  },
  {
    path: "t3/summary",
    component: T3SlipSummarySetupPage,
    exact: true,
  },
  {
    path: "t3/settings",
    component: T3SlipSettingsSetupPage,
    exact: true,
  },
  {
    path: "t3/import",
    component: T3SlipImportPage,
    exact: true,
  },
  {
    path: "t3/import-xml",
    component: T3SlipImportXmlPage,
    exact: true,
  },
  {
    path: "rl1/list",
    component: RL1SlipListPage,
    exact: true,
  },
  {
    path: "rl1/setup",
    component: RL1SlipSetupPage,
    exact: true,
  },
  {
    path: "rl1/setup/:id",
    component: RL1SlipSetupPage,
    exact: true,
  },
  {
    path: "rl1/summary",
    component: RL1SlipSummarySetupPage,
    exact: true,
  },
  {
    path: "rl1/settings",
    component: RL1SlipSettingsSetupPage,
    exact: true,
  },
  {
    path: "rl1/import",
    component: RL1SlipImportPage,
    exact: true,
  },
  {
    path: "rl1/import-xml",
    component: RL1SlipImportXmlPage,
    exact: true,
  },
  {
    path: "rl3/list",
    component: RL3SlipListPage,
    exact: true,
  },
  {
    path: "rl3/setup",
    component: RL3SlipSetupPage,
    exact: true,
  },
  {
    path: "rl3/setup/:id",
    component: RL3SlipSetupPage,
    exact: true,
  },
  {
    path: "rl3/summary",
    component: RL3SlipSummarySetupPage,
    exact: true,
  },
  {
    path: "rl3/import",
    component: RL3SlipImportPage,
    exact: true,
  },
  {
    path: "rl3/import-xml",
    component: RL3SlipImportXmlPage,
    exact: true,
  },
  {
    path: "rl16/list",
    component: RL16SlipListPage,
    exact: true,
  },
  {
    path: "rl16/setup",
    component: RL16SlipSetupPage,
    exact: true,
  },
  {
    path: "rl16/setup/:id",
    component: RL16SlipSetupPage,
    exact: true,
  },
  {
    path: "rl16/summary",
    component: RL16SlipSummarySetupPage,
    exact: true,
  },
  {
    path: "rl16/settings",
    component: RL16SlipSettingsSetupPage,
    exact: true,
  },
  {
    path: "rl16/import",
    component: RL16SlipImportPage,
    exact: true,
  },
  {
    path: "rl16/import-xml",
    component: RL16SlipImportXmlPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const MeRoutes: IProtectedRouteItem[] = [
  {
    path: "/change-password",
    component: ChangePasswordPage,
    exact: true,
  },
  {
    path: "/api",
    component: APIKeysPage,
    exact: true,
  },
  {
    path: "/default-settings",
    component: DefaultSettingsPage,
    exact: true,
  },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const LogoutRoutes: IProtectedRouteItem[] = [
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const AdjustmentRoutes: IProtectedRouteItem[] = [
  {
    path: "options",
    component: AdjustmentOptionsSetupPage,
    exact: true,
  },
  {
    path: "/setup",
    component: AdjustmentsSetupPage,
    exact: true,
  },
  {
    path: "/rl1-report",
    component: Rl1AdjustmentReportPage,
    exact: true,
  },
  {
    path: "/t4-report",
    component: T4AdjustmentReportPage,
    exact: true,
  },
  // {
  //   path: ":resourceName/setup",
  //   component: ResourceSetupPage,
  //   exact: true,
  // },
  // {
  //   path: ":resourceName/setup/:id",
  //   component: ResourceSetupPage,
  //   exact: true,
  // },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];

export const AuditRoutes: IProtectedRouteItem[] = [
  {
    path: "filing",
    component: AuditFilingSetupPage,
    exact: true,
  },
  {
    path: "earnings-audit",
    component: EarningsAuditSetupPage,
    exact: true,
  },
  {
    path: "pier-review",
    component: PierReviewSetupPage,
    exact: true,
  },
  // {
  //   path: ":resourceName/setup",
  //   component: ResourceSetupPage,
  //   exact: true,
  // },
  // {
  //   path: ":resourceName/setup/:id",
  //   component: ResourceSetupPage,
  //   exact: true,
  // },
  {
    path: "*",
    component: LogoutPage,
    exact: true,
  },
];
