import { TaxSlipCategoryList } from "components/TaxSlipCategory";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { PDFAxis } from "components/PDF Settings/PDF Axis";

export interface IPDFAxisPage {}

export const PDFAxisPage: FC<IPDFAxisPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="PDF Axis"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'PDF Axis', navigateTo: '/pdf-settings/pdf-axis'}]}
      mainContents={<PDFAxis />}
    />
  );
};
