import {
  GET_QUERY_KEY,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  VENDOR_RESOURCE_LITERAL,
  T5_RECIPIENT_RESOURCE_LITERAL,
  // RL3_SETUP_SCREEN_ID,
  RL3_SETUP_SCREEN_SLUG,
} from "common/constants";
import { instanceOfIRL3SlipResponce } from "common/instance-method";
import { IResponse, IServerError } from "models/common";
import { IDropDownListResponse } from "models/common/IDropDownListResponse";
import { FC, useEffect, useState, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import FileSaver from "file-saver";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import {
  BackdropCircularProgress,
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  StandardPanel,
  TwoColumnLayout,
  DeleteConfirmationDialog,
} from "@websential/cosmic";
import { EnhancedDialog } from "@websential/cosmic";
import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions";
import { format } from "date-fns";
// import { Card } from "primereact/card";
import Card from "@mui/material/Card";
import { Divider } from "primereact/divider";
import { Avatar } from "primereact/avatar";
import "./RL3SlipSetupStyle.css";
import NativeSelect from "@mui/material/NativeSelect";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import {
  FilingDataSetupFieldsPanel,
  RL3SlipDataPanel,
  VendorAddressSetupFieldsPanel,
  VendorInfoSetupFieldsPanel,
} from "./Panels";
import { RL3SlipSetupValidationSchema } from "./validator";
import { RL3SlipService } from "services/RL3SlipService";
import { ICountryReferenceModel } from "models/country/ICountryReferenceModel";
import { useNavigate, useParams } from "react-router-dom";
import { T4SlipService } from "services/T4SlipService";
import { InvoiceDialogPanel } from "components/T4Slip/T4SlipList/InvoiceDialogPanel";
import { RL3SlipSetupToolbar } from "./RL3SlipSetupToolbar";
import { IRL3SlipRequestPayload } from "models/rl3Slip/IRL3SlipRequestPayload";
import { PrintingService } from "services/PrintingService";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { TaxSlipSettingsService } from "services/TaxSlipSettingsService";
import { RL3OtherInformationService } from "components/OtherInformation/otherInformations";
import { FilteredSelectedOtherInfoRL3 } from "./Panels/OtherInformationSetupFieldsPanel";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { ClientService } from "services";
export let selectedRL3SlipType: any = 400;
export interface IRL3SlipSetup extends ITest {}

const SINGLE_RECORD_DATA_QUERY_KEY = "rl3-slips-single-data-record";
const SINGLE_Employee_DATA_QUERY_KEY = "employee-data";

const useGetData = (
  id: string | undefined,
  clientId: string,
  taxYear: number
) => {
  return useQuery(
    [SINGLE_RECORD_DATA_QUERY_KEY, id],
    () => RL3SlipService.getRL3SlipById(id, clientId, taxYear),
    QUERY_OPTIONS
  );
};

const useGetForeingCurrencyDDL = () =>
  useQuery(
    "",
    (data) => {
      return RL3SlipService.getForeingCurrencyDDL();
    },
    { refetchOnWindowFocus: false }
  );

const useGetResourceInformationById = (
  resourceId: string,
  clientId: string,
  roleName: string
) => {
  return useQuery(
    [SINGLE_Employee_DATA_QUERY_KEY, resourceId, clientId, roleName],
    () =>
      RL3SlipService.getResourceInformationById(resourceId, clientId, roleName),
    QUERY_OPTIONS
  );
};

const parseJSONLikeString = (str: string) => {
  // Use regular expressions to add double quotes around keys and string values
  const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');

  // Convert the modified JSON string to an object using JSON.parse()
  return JSON.parse(modifiedString);
};
const useGetGlobalVariables = (taxYear: number) =>
  useQuery(
    [GET_QUERY_KEY.GET_GLOBAL_VARIABLES_BY_YEAR, taxYear],
    (data) => {
      return TaxSlipSettingsService.getGlobalVariables(taxYear);
    },
    { refetchOnWindowFocus: false }
  );

  const useGetSlipIds = (
    tag: string,
    clientId: string,
    taxYear: number
  ) =>
    useQuery(
      ["get-slip-ids", tag, clientId, taxYear],
      (data) => {
        return T4SlipService.getSlipIds(tag, clientId, taxYear);
      },
      { refetchOnWindowFocus: false }
    );

    const useGetCompanyData = (id: string | undefined) => 
      useQuery(
        ["client-get-by-id", id],
        (data) => {
          return ClientService.getById(id);
        },
        { refetchOnWindowFocus: false }
      );

export const RL3SlipSetup: FC<IRL3SlipSetup> = ({ testId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientId = useSelector((state: IAppState) => state.client.id);
  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [selectedResource, setSelectedResource] = useState("create-new");
  const [selectedResourceSlip, setSelectedResourceSlip] =
    useState("create-new");

    const [invoicePaymentFor, setInvoicePaymentFor] = useState(10)

    const [onResourceSelectionAPIsCalls, setOnResourceSelectionAPIsCalls] =
    useState(false);

  const [selectedResourceSlipList, setSelectedResourceSlipList] = useState<
    IDropDownListResponse[]
  >([{ label: "Create New", value: "create-new" }]);
  const [openInvoiceDialog, setInvoiceDialogOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [isLoadingDataa, setIsLoadingData] = useState(false);

  const [selectedResourceList, setSelectedResourceList] = useState<
    IDropDownListResponse[]
  >([{ label: "Create New", value: "create-new" }]);

  const [isLoadingAdjustedData, setIsLoadingAdjustedData] = useState(false);
  const [editedResourceId, setEditedResourceId] = useState("");
  // for delete row
  const [openDialog, setDialogOpen] = useState(false);
  const [apiAction, setApiAction] = useState("");
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [RL3SlipId, setRL3SlipId] = useState<any>("");
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );

  const [resourceListWithoutCreateNew, setResourceListWithoutCreateNew] =
    useState<any>(undefined);
  const [secondRecipientDDL, setSecondRecipientDDL] = useState<any>(undefined);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [itemSearch, setItemSearch] = useState("");
  const [sidePanelsVisibility, setSidePanelsVisibility] = useState(true);
  const [
    selectedSecondRecipientResourceActualIdValue,
    setSelectedSecondRecipientResourceActualIdValue,
  ] = useState<any>(undefined);
  const [recipientTypeValue, setRecipientTypeValue] = useState<any>(undefined);

  //one state which store both new cretaed and edited slip data
  const [RL3SlipRecord, setRL3SlipRecord] = useState<any>(undefined);
  // when  I setValue of otherinformation inside async calls method then facing issue while click on add more so we have to use state method to show otherinformation 
  const [slipOtherInformation, setSlipOtherInformation] =
    useState<any>([]);

  const [selectedOtherInformation, setSelectedOtherInformation] =
    useState(false);
  const [oiInEdit, setOiInEdit] = useState(false);

  // const queryClient = useQueryClient();
  console.log("selectedResourceSlip", selectedResourceSlip);

  const [CountryResponse] = useState<ICountryReferenceModel>({
    id: 1,
    name: "Canada",
    shortCode: "CAN",
  });

  // for edit
  const queryClient = useQueryClient();
  const { id } = useParams();
  console.log("id from setup file which clicked : ", id);
  let notStartedSlipId = "";
  let completedSlipId = "";
  if (id !== undefined && onResourceSelectionAPIsCalls === false) {
    if (id?.length > 24) {
      notStartedSlipId = id;
    }
    if (id?.length === 24) {
      completedSlipId = id;
    }
  }

  const DEFAULT_VALUES = {
    clientId: clientId,
    taxYear: taxYear,
  };

  // Function to sort the data array by the label field
// sort side cards items 
const sortData = (data: IDropDownListResponse[]): IDropDownListResponse[] => {
  return data.sort((a, b) => a.label.localeCompare(b.label));
};

  const { isLoading: isSubmitting, mutate: submitFlexFields } = useMutation(
    RL3SlipService.create,
    {
      onSuccess: (
        res: IRL3SlipRequestPayload | IServerError | IResponse | any
      ) => {
        if (res.data && instanceOfIRL3SlipResponce(res.data)) {
          queryClient.invalidateQueries([SINGLE_RECORD_DATA_QUERY_KEY, res.data.id]);
          formMethods.setValue("id", res.data.id);
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
          refectchRL3SlipIds()
          if(res.status === 201 && selectedResourceSlip === "create-new"){
            (async () => {
              try {
                console.log("ïnside-=",selectedResourceSlip)
                const response: any =
                  await RL3SlipService.getResourceSlipDropDownList(
                    res.data?.resource?.externalId,
                    clientId,
                    taxYear
                  );
                  if (response && Array.isArray(response)) {
                    let resourceSlipList: IDropDownListResponse[] =
                      response.map((resourceSlip: IDropDownListResponse) => ({
                        label: resourceSlip.label,
                        value: resourceSlip.value,
                      }));

                    resourceSlipList.unshift({
                      label: "Create New",
                      value: "create-new",
                    });

                    console.log("resourceSlipListDDL", resourceSlipList);
                    setSelectedResourceSlipList(resourceSlipList);
                    formMethods.setValue(
                      "selectedSlip",
                      res.data.id
                    );
                    // createNewActionFromSlipDDL();
                    // formMethods.setValue("comparison", [
                    //   { amount: 0, code: "", label: "" },
                    // ]);
                    let path = `/taxslip/rl3/setup/${res.data.id}`;
                    navigate(path);
                  }
                } catch (error) {
                  console.error("Error fetching data:", error);
                }
              })();
            setOnResourceSelectionAPIsCalls(true)
            setSelectedResourceSlip(res.data.id)
          }
        } else {
          setSnackbarData({
            open: true,
            // message: "Something went wrong, try again later!",
            message: res.message ? res.message : res.data.message,
            severity: "error",
          });
        }
        //Load RL3 Recipient DDL
        const fetchData = async () => {
          const recipientType = formMethods.getValues("recipientType");
          try {
            const response: any =
              await RL3SlipService.getRL3RecipientDDLByRecipientType(
                clientId,
                T5_RECIPIENT_RESOURCE_LITERAL,
                taxYear,
                res.recipientType ? res.recipientType : recipientType
              );
            console.log("responseData-----------------", response);

            if (response && Array.isArray(response)) {
              let resources: IDropDownListResponse[] = response.map(
                (resourceItem: IDropDownListResponse) => ({
                  // id: resourceItem.id,
                  label: resourceItem.label,
                  value: resourceItem.value,
                })
              );

              resources.unshift({
                label: "Create New",
                value: "create-new",
              });

              setSelectedResourceList(resources);
              console.log("resourcesresourcesresources", resources);
            }
            setResourceListWithoutCreateNew(sortData(response));
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };

        fetchData();

        //load slip Data
        if (res.data && res.data?.resource) {
          setRL3SlipRecord(res.data);
          setSelectedResource(res.data?.resource?.externalId);
        }
        // //set slip type to 400 because when we come back to slip without reload it will automatically be original
        selectedRL3SlipType = formMethods.getValues("slipType");

        // //otherinformation will update sselected items when its submitted
        setSelectedOtherInformation(true);
      },
      onError: (err: any) => {
        console.log("err", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          // message: "Something went wrong, try again later!",
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  //when cretae-new seleted from slip ddl
  const createNewActionFromSlipDDL = () => {
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 1000);
    // //otherinformation will update sselected items when its submitted
    setSelectedOtherInformation(true);
    formMethods.setValue("actualAmountOfEligibleDivinends", "0.00");
    formMethods.setValue("taxableAmountOfEligibleDividends", "0.00");
    formMethods.setValue("dividendTaxCreditForEligibleDividends", "0.00");
    formMethods.setValue("interestFromCanadianSources", "0.00");
    formMethods.setValue("capitalGainDividends", "0.00");
    formMethods.setValue("actualAmountOfDivinendsOtherThanEligible", "0.00");
    formMethods.setValue("taxableAmountOfDivinendsOtherThanEligible", "0.00");
    formMethods.setValue(
      "dividendTaxCreditForDivdendsOtherThanEligible",
      "0.00"
    );
    formMethods.setValue("otherIncomeFromCanadianSources", "0.00");
    formMethods.setValue("foreignIncome", "0.00");
    formMethods.setValue("foreignTaxPaid", "0.00");
    formMethods.setValue("royaltiesFromCanadianSources", "0.00");
    formMethods.setValue("accuredIncomeOrAnnuities", "0.00");
    formMethods.setValue("amountEligibleForResourceAllowanceDeduction", "0.00");
    formMethods.setValue("foreignCurrency", undefined);
    formMethods.setValue("transit", "");
    formMethods.setValue("recipientAccount", "");
    formMethods.setValue("optionalTextToPrintOnTheSlip", "");
    formMethods.setValue("slipStatus", 200);
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    setSlipOtherInformation([])
  };

  const {
    isLoading: isLoadingForeignCurrencyDDL,
    data: foreignCurrencyDDList,
  } = useGetForeingCurrencyDDL();

  const foreignCurrencyDDListItems = useMemo(() => {
    if (foreignCurrencyDDList && Array.isArray(foreignCurrencyDDList)) {
      console.log("foreignCurrencyDDList1", foreignCurrencyDDList);
      let foreignCurrency: any[] = foreignCurrencyDDList.map(
        (resourceItem: any) => ({
          label: resourceItem.label,
          value: resourceItem.value,
          name: resourceItem.name,
        })
      );
      foreignCurrency.unshift({
        label: "--",
        value: null,
      });
      console.log("foreignCurrency", foreignCurrency);
      return foreignCurrency;
    }
  }, [foreignCurrencyDDList]);

  // for edit

  //for that slips which are completed
  const { isLoading: isLoadingData, data: selectedRL3SlipsData } = useGetData(
    completedSlipId,
    clientId,
    taxYear
  );

  useEffect(() => {
    //get by id slip
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 2000);
    const fetchData = async () => {
      try {
        const response: any = await RL3SlipService.getRL3SlipById(
          completedSlipId,
          clientId,
          taxYear
        );
        console.log("responseData-----------------", response);
        setRL3SlipRecord(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedRL3SlipsData) {
      setRL3SlipRecord(selectedRL3SlipsData);
    }
  }, [selectedRL3SlipsData]);
  console.log("selectedRL3SlipsData=======", selectedRL3SlipsData);

  //for that slips which are not started yet
  const {
    isLoading: isLoadingNotStartedSlipId,
    data: selectedNotStartedResourceData,
    // refetch: refetchResourceSlips,
  } = useGetResourceInformationById(
    notStartedSlipId,
    clientId,
    T5_RECIPIENT_RESOURCE_LITERAL
  );
  console.log("resourceInfo", selectedNotStartedResourceData);

  const formMethods = useForm<any>({
    resolver: yupResolver(RL3SlipSetupValidationSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  /**
   * Handle when the form is submitted.
   *
   * @param {IRL3SlipRequestPayload} formData
   */
  const onSubmit = (formData: any) => {
    //province is in string format and by using this we convert that in object
    const jsonString = formData.province as string;
    // Custom parsing function to convert the JSON-like string to an object
    const parseJSONLikeString = (str: string) => {
      // Use regular expressions to add double quotes around keys and string values
      const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
      // Convert the modified JSON string to an object using JSON.parse()
      return JSON.parse(modifiedString);
    };
    // Convert the JSON-like string to an object
    const provinceObject = parseJSONLikeString(jsonString);
    console.log("provinceObject", provinceObject);

    //province is in string format and by using this we convert that in object
    // const jsonStringPoe = formData.provinceOfEmployment as string;
    // Custom parsing function to convert the JSON-like string to an object
    // const parseJSONLikeStringPeo = (str: string) => {
    //   // Use regular expressions to add double quotes around keys and string values
    //   const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
    //   // Convert the modified JSON string to an object using JSON.parse()
    //   return JSON.parse(modifiedString);
    // };
    // Convert the JSON-like string to an object
    // const poeObject = parseJSONLikeStringPeo(jsonStringPoe);
    // console.log("poeObject", poeObject);

    //other Information
    const getValue = formMethods.getValues("comparison");
    console.log("RL3OtherInformationList", RL3OtherInformationList);
    const finalOtherInformatioItems = FilteredSelectedOtherInfoRL3.map(
      (item: any) => {
        const valueItem = getValue.find(
          (value: any) => value.code === item.code
        );
        const getLabelIfMissingInItem =
          RL3OtherInformationList &&
          RL3OtherInformationList.find(
            (value: any) => value.value === item.code
          );
        console.log("valueItemqwe", valueItem);
        console.log("getLabelIfMissingInItem", getLabelIfMissingInItem);
        console.log("item11", item);
        return {
          ...item,
          amount: valueItem.amount,
          label: getLabelIfMissingInItem.label,
        };
      }
    );

    if (
      formData.recipientType === 20 ||
      formData.recipientType === 60 ||
      formData.recipientType === 70
    ) {
      // (async () => {
      //   try {
      //       const selectedSecondRecipientResourceActualId =
      //         await T4SlipService.getResourceActualId(formData.secondRecipientName && formData.secondRecipientName);
      //       setSelectedSecondRecipientResourceActualIdValue(
      //         selectedSecondRecipientResourceActualId
      //       );
      const selectedSecondRacipientObject =
        secondRecipientDDL &&
        secondRecipientDDL.find(
          (item: any) => item.value === formData.secondRecipientName
        );
      console.log(
        "selectedSecondRacipientObject",
        selectedSecondRacipientObject
      );
      console.log("secondRecipientDDL", secondRecipientDDL);

      const requestPayload: any = {
        clientId: clientId,
        taxYear: taxYear,
        id:
          selectedRL3SlipsData && selectedRL3SlipsData.resource
            ? selectedRL3SlipsData.id
            : RL3SlipRecord && RL3SlipRecord.id ? RL3SlipRecord.id 
            : undefined,
        recipientType: formData.recipientType,
        reflectMasterData: formData.reflectMasterData,
        slipType: formData.slipType,
        secondRecipientName:
          formData.recipientType === 20
            ? selectedSecondRacipientObject &&
              selectedSecondRacipientObject.label
            : undefined,
        slipStatus: formData.slipStatus,
        otherInformation:
          formData.comparison[0].code !== "" ? finalOtherInformatioItems : [],
        resource: {
          externalId:
            selectedResource !== "create-new"
              ? selectedResource
              : selectedRL3SlipsData && selectedRL3SlipsData.resource
              ? selectedRL3SlipsData.resource.externalId
              : selectedNotStartedResourceData &&
                selectedNotStartedResourceData.id
              ? selectedNotStartedResourceData.id
              : undefined,
          firstName:
            formData.name1 && formData.name1.length > 0
              ? formData.name1
              : undefined,
          initials: formData.initials,
          lastName:
            formData.name2 && formData.name2.length > 0
              ? formData.name2
              : undefined,
          // dateOfBirth:formData.dateOfBirth,
          socialInsuranceNumber:
            formData.uniqueIdentifier && formData.uniqueIdentifier !== ""
              ? formData.uniqueIdentifier.replace(/-/g, "")
              : undefined,
          address: formData.addressLine1,
          city: formData.city,
          country: CountryResponse,
          postCode:
            formData.postalCode &&
            formData.postalCode.replace(/[a-z]/g, (match: string) =>
              match.toUpperCase()
            ),
          province: provinceObject, //when edit then it show n select so this technique is use bcz we cant assign fieldvalue according to field name by using direct object so first we change that oobject to string then we chnage that string to obj and send to payload
          email: formData.email !== "" && formData.email !== "ze*****3@gmail.com"
            ? formData.email
            : undefined,
        },
        individual: formData.individual,
        slipData: {
          actualAmountOfEligibleDivinends: formData.actualAmountOfEligibleDivinends && formData.actualAmountOfEligibleDivinends !== null ?
          parseFloat(
            formData.actualAmountOfEligibleDivinends.replace(/[$,]/g, '')
          ) : 0,
          actualAmountOfOrdinaryDivinends: formData.actualAmountOfOrdinaryDivinends && formData.actualAmountOfOrdinaryDivinends !== null ? 
          parseFloat(
            formData.actualAmountOfOrdinaryDivinends.replace(/[$,]/g, '')
          ) : 0,
          interestFromCanadianSources: formData.interestFromCanadianSources && formData.interestFromCanadianSources !== null ?
          parseFloat(
            formData.interestFromCanadianSources.replace(/[$,]/g, '')
          ) : 0,
          otherIncomeFromCanadianSources: formData.otherIncomeFromCanadianSources && formData.otherIncomeFromCanadianSources !== null ? 
          parseFloat(
            formData.otherIncomeFromCanadianSources.replace(/[$,]/g, '')
          ) : 0,
          grossForeignInvestmentIncome: formData.grossForeignInvestmentIncome && formData.grossForeignInvestmentIncome !== null ? 
          parseFloat(
            formData.grossForeignInvestmentIncome.replace(/[$,]/g, '')
          ) : 0,
          foreignIncomeTax: formData.foreignIncomeTax && formData.foreignIncomeTax !== null ? 
          parseFloat(formData.foreignIncomeTax.replace(/[$,]/g, '')) : 0,
          royaltiesFromCanadianSources: formData.royaltiesFromCanadianSources && formData.royaltiesFromCanadianSources !== null ? 
          parseFloat(
            formData.royaltiesFromCanadianSources.replace(/[$,]/g, '')
          ) : 0,
          capitalGainDividends: formData.capitalGainDividends && formData.capitalGainDividends !== null ? 
          parseFloat(formData.capitalGainDividends.replace(/[$,]/g, '')) : 0,
          accuredIncomeOrAnnuities: formData.accuredIncomeOrAnnuities && formData.accuredIncomeOrAnnuities !== null ? 
          parseFloat(
            formData.accuredIncomeOrAnnuities.replace(/[$,]/g, '')
          ) : 0,
          interestFromLinkedNotes: formData.interestFromLinkedNotes && formData.interestFromLinkedNotes !== null ? 
          parseFloat(formData.interestFromLinkedNotes.replace(/[$,]/g, '')) : 0,
          currencyCode: formData.currencyCode && formData.currencyCode,
          bankTransit: formData.bankTransit && formData.bankTransit !== '' ? formData.bankTransit : undefined,
          otherIdentificationNumber: formData.otherIdentificationNumber && formData.otherIdentificationNumber !== '' ? formData.otherIdentificationNumber : undefined,
          numberAssignedByPayerOrNominee: formData.numberAssignedByPayerOrNominee && formData.numberAssignedByPayerOrNominee !== '' ? formData.numberAssignedByPayerOrNominee : undefined,
          optionalTextToPrintOnTheSlip: formData.optionalTextToPrintOnTheSlip && formData.optionalTextToPrintOnTheSlip !== '' ? formData.optionalTextToPrintOnTheSlip : undefined,
        },
        secondExternalId:
          formData.secondRecipientName && formData.secondRecipientName,
      };
      console.log("===-requestPayload t4slip ", requestPayload);
      console.log("formData--", formData);

      // submitFlexFields(formData);
      submitFlexFields(requestPayload);
      //   } catch (error: any) {
      //     alert(error)
      //   }
      // })();
    } else {
      //   (async () => {
      //   try {
      //     console.log(
      //       "formData.secondRecipientName",
      //       formData.secondRecipientName
      //     );
      //     let secondRecipient =
      //       formData.secondRecipientName && formData.secondRecipientName;
      //     if (secondRecipient) {
      //       const selectedSecondRecipientResourceActualId =
      //         await T4SlipService.getResourceActualId(secondRecipient);
      //       setSelectedSecondRecipientResourceActualIdValue(
      //         selectedSecondRecipientResourceActualId
      //       );
      //     }
      //   } catch (error: any) {}
      // })();
      const selectedSecondRacipientObject =
        secondRecipientDDL &&
        secondRecipientDDL.find(
          (item: any) => item.value === formData.secondRecipientName
        );
      console.log(
        "selectedSecondRacipientObject",
        selectedSecondRacipientObject
      );
      console.log("secondRecipientDDL", secondRecipientDDL);

      const requestPayload: any = {
        clientId: clientId,
        taxYear: taxYear,
        id:
          selectedRL3SlipsData && selectedRL3SlipsData.resource
            ? selectedRL3SlipsData.id
            : undefined,
        recipientType: formData.recipientType,
        reflectMasterData: formData.reflectMasterData,
        slipType: formData.slipType,
        // secondRecipientName:
        //   formData.recipientType === 20
        //     ? selectedSecondRacipientObject && selectedSecondRacipientObject.label
        //     : undefined,
        slipStatus: formData.slipStatus,
        otherInformation:
          formData.comparison[0].code !== "" ? finalOtherInformatioItems : [],
        resource: {
          externalId:
            selectedResource !== "create-new"
              ? selectedResource
              : selectedRL3SlipsData && selectedRL3SlipsData.resource
              ? selectedRL3SlipsData.resource.externalId
              : selectedNotStartedResourceData &&
                selectedNotStartedResourceData.id
              ? selectedNotStartedResourceData.id
              : undefined,
          firstName:
            formData.recipientType === 10 &&
            formData.name1 &&
            formData.name1.length > 0
              ? formData.name1
              : formData.recipientType === 20 &&
                formData.name1 &&
                formData.name1.length > 0
              ? formData.name1
              : undefined,
          initials:
            formData.recipientType === 10
              ? formData.initials
              : formData.recipientType === 20
              ? formData.initials
              : "",
          lastName:
            formData.recipientType === 10 &&
            formData.name2 &&
            formData.name2.length > 0
              ? formData.name2
              : formData.recipientType === 20 &&
                formData.name2 &&
                formData.name2.length > 0
              ? formData.name2
              : undefined,
          // dateOfBirth:formData.dateOfBirth,
          socialInsuranceNumber:
            formData.recipientType === 10 &&
            formData.uniqueIdentifier &&
            formData.uniqueIdentifier !== ""
              ? formData.uniqueIdentifier.replace(/-/g, "")
              : formData.recipientType === 20 &&
                formData.uniqueIdentifier &&
                formData.uniqueIdentifier !== ""
              ? formData.uniqueIdentifier.replace(/-/g, "")
              : undefined,
          address: formData.addressLine1,
          city: formData.city,
          country: CountryResponse,
          postCode:
            formData.postalCode &&
            formData.postalCode.replace(/[a-z]/g, (match: string) =>
              match.toUpperCase()
            ),
          province: provinceObject, //when edit then it show n select so this technique is use bcz we cant assign fieldvalue according to field name by using direct object so first we change that oobject to string then we chnage that string to obj and send to payload
          // provinceOfEmployment: poeObject,
          email: formData.email !== "" && formData.email !== "ze*****3@gmail.com"
            ? formData.email
            : undefined,
          businessName:
            formData.firstLineOfRecipientName +
            formData.secondLineOfRecipientName, //First line of recipient name * Second line of recipient name *
          businessNumber:
            formData.recipientType === 30 && formData.businessNumber !== ""
              ? formData.businessNumber
              : formData.recipientType === 50 && formData.businessNumber !== ""
              ? formData.businessNumber
              : undefined,
        },
        individual: formData.individual,
        trustAccountNumber:formData.recipientType === 40 ? 
        formData.trustAccountNumber && formData.trustAccountNumber.charAt(0) === 'T' ? formData.trustAccountNumber : `T${formData.trustAccountNumber}` : undefined,
        firstLineOfRecipientName:
          formData.recipientType === 30 &&
          formData.firstLineOfRecipientName !== ""
            ? formData.firstLineOfRecipientName
            : formData.recipientType === 40 &&
              formData.firstLineOfRecipientName !== ""
            ? formData.firstLineOfRecipientName
            : formData.recipientType === 50 &&
              formData.firstLineOfRecipientName !== ""
            ? formData.firstLineOfRecipientName
            : undefined,
        secondLineOfRecipientName:
          formData.recipientType === 30 &&
          formData.secondLineOfRecipientName !== ""
            ? formData.secondLineOfRecipientName
            : formData.recipientType === 40 &&
              formData.secondLineOfRecipientName !== ""
            ? formData.secondLineOfRecipientName
            : formData.recipientType === 50 &&
              formData.secondLineOfRecipientName !== ""
            ? formData.secondLineOfRecipientName
            : undefined,
        // name3: (formData.recipientType === 20 && formData.individual === false) ? selectedSecondRacipientObject && selectedSecondRacipientObject.label.substring(0, selectedSecondRacipientObject.label.indexOf(' ')) : formData.name3 !== "" ? formData.name3 : undefined,
        // name4: (formData.recipientType === 20 && formData.individual === false) ? selectedSecondRacipientObject && selectedSecondRacipientObject.label.substring(selectedSecondRacipientObject.label.indexOf(' ') + 1) : formData.name4 !== "" ?formData.name4 : undefined,
        // initials1: formData.initials1,
        slipData: {
          actualAmountOfEligibleDivinends: formData.actualAmountOfEligibleDivinends && formData.actualAmountOfEligibleDivinends !== null ?
          parseFloat(
            formData.actualAmountOfEligibleDivinends.replace(/[$,]/g, '')
          ) : 0,
          actualAmountOfOrdinaryDivinends: formData.actualAmountOfOrdinaryDivinends && formData.actualAmountOfOrdinaryDivinends !== null ? 
          parseFloat(
            formData.actualAmountOfOrdinaryDivinends.replace(/[$,]/g, '')
          ) : 0,
          interestFromCanadianSources: formData.interestFromCanadianSources && formData.interestFromCanadianSources !== null ?
          parseFloat(
            formData.interestFromCanadianSources.replace(/[$,]/g, '')
          ) : 0,
          otherIncomeFromCanadianSources: formData.otherIncomeFromCanadianSources && formData.otherIncomeFromCanadianSources !== null ? 
          parseFloat(
            formData.otherIncomeFromCanadianSources.replace(/[$,]/g, '')
          ) : 0,
          grossForeignInvestmentIncome: formData.grossForeignInvestmentIncome && formData.grossForeignInvestmentIncome !== null ? 
          parseFloat(
            formData.grossForeignInvestmentIncome.replace(/[$,]/g, '')
          ) : 0,
          foreignIncomeTax: formData.foreignIncomeTax && formData.foreignIncomeTax !== null ? 
          parseFloat(formData.foreignIncomeTax.replace(/[$,]/g, '')) : 0,
          royaltiesFromCanadianSources: formData.royaltiesFromCanadianSources && formData.royaltiesFromCanadianSources !== null ? 
          parseFloat(
            formData.royaltiesFromCanadianSources.replace(/[$,]/g, '')
          ) : 0,
          capitalGainDividends: formData.capitalGainDividends && formData.capitalGainDividends !== null ? 
          parseFloat(formData.capitalGainDividends.replace(/[$,]/g, '')) : 0,
          accuredIncomeOrAnnuities: formData.accuredIncomeOrAnnuities && formData.accuredIncomeOrAnnuities !== null ? 
          parseFloat(
            formData.accuredIncomeOrAnnuities.replace(/[$,]/g, '')
          ) : 0,
          interestFromLinkedNotes: formData.interestFromLinkedNotes && formData.interestFromLinkedNotes !== null ? 
          parseFloat(formData.interestFromLinkedNotes.replace(/[$,]/g, '')) : 0,
          currencyCode: formData.currencyCode && formData.currencyCode,
          bankTransit: formData.bankTransit && formData.bankTransit !== '' ? formData.bankTransit : undefined,
          otherIdentificationNumber: formData.otherIdentificationNumber && formData.otherIdentificationNumber !== '' ? formData.otherIdentificationNumber : undefined,
          numberAssignedByPayerOrNominee: formData.numberAssignedByPayerOrNominee && formData.numberAssignedByPayerOrNominee !== '' ? formData.numberAssignedByPayerOrNominee : undefined,
          optionalTextToPrintOnTheSlip: formData.optionalTextToPrintOnTheSlip && formData.optionalTextToPrintOnTheSlip !== '' ? formData.optionalTextToPrintOnTheSlip : undefined,
        },
        // secondExternalId:
        //   formData.recipientType === 20 &&
        //   selectedSecondRecipientResourceActualIdValue && selectedSecondRecipientResourceActualIdValue?.resourceId
        //     ? selectedSecondRecipientResourceActualIdValue.resourceId
        //     : undefined,
      };
      console.log("===-requestPayload t4slip ", requestPayload);
      console.log("formData--", formData);

      // submitFlexFields(formData);
      submitFlexFields(requestPayload);
    }
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset();
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    setSlipOtherInformation([])
    setSelectedResourceSlipList([{ label: "Create New", value: "create-new" }]);
    setSelectedResourceSlip("create-new");
    setSelectedOtherInformation(false);
    setSelectedResource("create-new");
    setSelectedCard(null);
    let path = `/taxslip/rl3/setup`;
    navigate(path);
    setRL3SlipRecord(undefined);
  };

  // For Delete Row

  const { isLoading: isDeleting, mutate: deleteRL3SlipById } = useMutation(
    RL3SlipService.delRL3Slip,
    {
      onSuccess: (res: any | IServerError | IResponse) => {
        console.log("resonce for Delete row", res);
        if (typeof res === "string") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: "Record has been deleted successfully.",
            severity: "success",
          });
          setRL3SlipRecord(undefined);
          refectchRL3SlipIds()
        } else {
          setSnackbarData({
            open: true,
            // message: "Something went wrong, try again later!",
            message: res.message,
            severity: "error",
          });
        }

        //load Employee Data
        if (res) {
          const selectedVendor = formMethods.getValues("selectedVendor");
          console.log("selectedVendor", selectedVendor);
          if (selectedVendor !== "create-new") {
            // (async () => {
            //   try {
                setIsLoadingAdjustedData(true);
                setTimeout(() => {
                  setIsLoadingAdjustedData(false);
                }, 1000);
                // const response = await T4SlipService.getResourceActualId(
                //   selectedVendor
                // );

                // setSelectedResourceId(String(response.resourceId));
                // const resourceId = response.resourceId;
                let path = `/taxslip/rl3/setup/${selectedVendor}`;
                navigate(path);
                const fetchData = async () => {
                  try {
                    const response: any =
                      await RL3SlipService.getResourceSlipDropDownList(
                        selectedVendor,
                        clientId,
                        taxYear
                      );
                    console.log("responseData-----------------", response);
                    if (response && Array.isArray(response)) {
                      let resourceSlipList: IDropDownListResponse[] =
                        response.map((resourceSlip: IDropDownListResponse) => ({
                          label: resourceSlip.label,
                          value: resourceSlip.value,
                        }));

                      resourceSlipList.unshift({
                        label: "Create New",
                        value: "create-new",
                      });

                      console.log("resourceSlipListDDL", resourceSlipList);
                      setSelectedResourceSlipList(resourceSlipList);
                      formMethods.setValue(
                        "selectedSlip",
                        resourceSlipList[0].value
                      );
                      createNewActionFromSlipDDL();
                    }
                  } catch (error) {
                    console.error("Error fetching data:", error);
                  }
                };

                fetchData();
            //   } catch (err) {
            //     console.log("err", err);
            //     // alert(err);
            //   }
            // })();
          }
        }

        //set slip type to 400 because when we come back to slip without reload it will automatically be original
        selectedRL3SlipType = formMethods.getValues("slipType");

        // //otherinformation will update sselected items when its submitted
        setSelectedOtherInformation(true);
      },
      onError: (err: any) => {
        console.log("resonce for Delete row", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          // message: "Something went wrong, try again later!",
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  const deleteRL3SlipField = () => {
    if (singleDelete) {
      let payload = {
        RL3SlipId,
        clientId,
        taxYear,
      };
      setSingleDelete(false);
      deleteRL3SlipById(payload);
      setDialogOpen(false);
    }
  };

  const onDeleteClicked = () => {
    setDialogOpen(true);
    setSingleDelete(true);
    setApiAction("delete");
    if (
      selectedRL3SlipsData &&
      selectedRL3SlipsData.resource
      // && editedRecordSlipCreateNew === false
    ) {
      setRL3SlipId(selectedRL3SlipsData.id);
    }
    // else if (slipRecord && slipRecord.id){
    //   setRL3SlipId(slipRecord.id)
    // }
    // else if(newCreatedSlipId !== '' && editedRecordSlipCreateNew === false){
    //   setRL3SlipId(newCreatedSlipId)
    // }
    // setRL3SlipId(params.row.id);
    console.log("RL3SlipId in T4Slip List OnDeleteRowClicked function");

    // setSnackbarData({
    //   open: true,
    //   message: "Deleted successfully",
    //   severity: "success",
    // });
    // formMethods.reset();
  };

  const savePdfAsFile = (pdfData: any, filename: any) => {
    const blob = new Blob([pdfData], { type: "pdf;charset=utf-8" });
    FileSaver.saveAs(blob, filename);
  };
  const onPrintClicked = async (): Promise<void> => {
    const maskedStatus: any = sessionStorage.getItem("maskEnabled");
    if (maskedStatus === "true") {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(rl3SlipIds && rl3SlipIds.generalAuditStatus !== null && rl3SlipIds.generalAuditStatus === true &&
              rl3SlipIds && rl3SlipIds.auditStatus !== null &&  rl3SlipIds.auditStatus === true){
              setIsLoadingData(true);
                try {
                  // const response: any = await PrintingService.createSingleRL3PdfPrint(clientId, EditedResourceId, formMethods.getValues("slipType"), taxYear);
                  let pdfIds = `rl3_slip_ids=${id}`;
                  const response: any =
                    await PrintingService.createBulkRL3PdfPrint(
                      clientId,
                      pdfIds,
                      taxYear
                    );
                  if (response.status === 400) {
                    setSnackbarData({
                      open: true,
                      message: response.data.message
                        ? response.data.message
                        : "Something went wrong.",
                      // message: "Please Unmask sensitive data for PDF printing.",
                      severity: "error",
                    });
                  } else {
                    const filename = `RL3slip-${clientName}.pdf`;
                    savePdfAsFile(response, filename);
                  }
                  setIsLoadingData(false);
                } catch (err) {
                  console.log("err", err);
                  // alert(err)
                }
              // }
              setIsLoadingData(false);
            } else {
              setIsLoadingData(false);
              setSnackbarData({
              open: true,
              message: (
                <>
                  Please load Audit in the menu, press Run RL-3 Audit and clear all audit items to generate documents.{" "}
                  <a
                    href="https://appv3.taxslips.com/#/audit/filing"
                    style={{
                      color: "#aa1010",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Click here to view
                  </a>
                </>
              ),
              severity: "error",
              autoHide: false,
            });
          }

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
      setIsLoadingData(true);
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (response.message === "Slips not found for invoice.") {
          // setIsLoadingData(true);
          // try {
          //   const filingAuditResponse: any =
          //     await RL3SlipService.getRL3AuditFiling(clientId, taxYear);
          //   console.log("filingAuditResponse", filingAuditResponse);
          //   if (
          //     filingAuditResponse &&
          //     filingAuditResponse.general_filling &&
          //     filingAuditResponse.general_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.general_filling",
          //       filingAuditResponse.general_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else if (
          //     filingAuditResponse &&
          //     filingAuditResponse.rl3_filling &&
          //     filingAuditResponse.rl3_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.rl3_filling",
          //       filingAuditResponse.rl3_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else {
          //     setIsLoadingData(true);
          if(rl3SlipIds && rl3SlipIds.generalAuditStatus !== null && rl3SlipIds.generalAuditStatus === true &&
            rl3SlipIds && rl3SlipIds.auditStatus !== null &&  rl3SlipIds.auditStatus === true){
            setIsLoadingData(true);
              try {
                // const response: any = await PrintingService.createSingleRL3PdfPrint(clientId, EditedResourceId, formMethods.getValues("slipType"), taxYear);
                let pdfIds = `rl3_slip_ids=${id}`;
                const response: any =
                  await PrintingService.createBulkRL3PdfPrint(
                    clientId,
                    pdfIds,
                    taxYear
                  );
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    // message: "Please Unmask sensitive data for PDF printing.",
                    severity: "error",
                  });
                } else {
                  const filename = `RL3slip-${clientName}.pdf`;
                  savePdfAsFile(response, filename);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
                // alert(err)
              }
            // }
            setIsLoadingData(false);
          } else {
            setIsLoadingData(false);
            setSnackbarData({
            open: true,
            message: (
              <>
                Please load Audit in the menu, press Run RL-3 Audit and clear all audit items to generate documents.{" "}
                <a
                  href="https://appv3.taxslips.com/#/audit/filing"
                  style={{
                    color: "#aa1010",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Click here to view
                </a>
              </>
            ),
            severity: "error",
            autoHide: false,
          });
        }
          // } catch (err: any) {
          //   setIsLoadingData(false);
          //   alert(err);
          // }
        } else if (response && response.id !== null) {
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    }
    }
  };

  const onUnPaidClickHandler = (clickedRow: any, tag:string) => {
    setIsLoadingData(true);
    // setTimeout(() => {
    //   setIsLoadingData(false);
    // }, 2500);
    const fetchData = async () => {
      try {
        const response: any =
          await ReportsService.paypalIntegrationOfUnpaidInvoice(
            clientId,
            clickedRow.refNo,
            tag
          );
        setIsLoadingData(false);
        console.log("responseData111-----------------", response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const onClickInvoiceNoHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };
  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const onResourceSelectionChangeHandler = async (
    selectedItem: IEnhancedDropDownItem | any,
    index?: any
  ): Promise<void> => {
    // setSelectedResource(String(selectedItem.value));
    console.log("selectedItem.value", selectedItem.value);
    //this is for card selection
    setSelectedCard(index);
    setSelectedResourceSlip("create-new");
    if (!index) {
      //this is for card selection
      setSelectedCard(selectedItem.value);
      setSelectedResourceSlip("create-new");
    }
    if (selectedItem.value === "create-new") {
      setSnackbarData({
        open: true,
        message: "Please load a payee for RL3 Slip",
        severity: "error",
      });
      setSidePanelsVisibility((prevButtonState: any) => !prevButtonState);
      formMethods.reset();
      formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
      setSlipOtherInformation([])
      //otherinformation will update selected items
      setSelectedOtherInformation(false);
      setSelectedResourceSlipList([
        { label: "Create New", value: "create-new" },
      ]);
      setSelectedResourceSlip("create-new");
      // //otherinformation will update sselected items when its submitted
      setSelectedOtherInformation(true);
      let path = `/taxslip/rl3/setup`;
      navigate(path);
      setRL3SlipRecord(undefined);
      setIsLoadingAdjustedData(true);
      setTimeout(() => {
        setIsLoadingAdjustedData(false);
      }, 1000);
      setSelectedResource("create-new");
    } else {
      setOnResourceSelectionAPIsCalls(true);
      // try {
      //   const responseGetResourceActualId =
      //     await T4SlipService.getResourceActualId(selectedItem.value);

        // console.log("responseGetResourceActualIdData",responseGetResourceActualId.resourceId)
        // setSelectedResource(String(responseGetResourceActualId.resourceId));
        // // setSelectedResourceId(String(responseGetResourceActualId.resourceId));
        // if (responseGetResourceActualId.resourceId !== "create new"){
        //   setEditedResourceId(String(responseGetResourceActualId.resourceId));
        // }
        // let path = `/taxslip/rl3/setup/${responseGetResourceActualId.resourceId}`;
        // navigate(path);
        (async () => {
          try {
            setIsLoadingAdjustedData(true);
            let resourceDataById =
              await RL3SlipService.getResourceInformationById(
                selectedItem.value,
                clientId,
                T5_RECIPIENT_RESOURCE_LITERAL
              );
            console.log("resourceDataById", resourceDataById);
            if (resourceDataById) {
              Object.keys(resourceDataById).forEach((fieldName) => {
                // @ts-ignore
                const fieldValue = resourceDataById[fieldName];
                console.log(
                  "fieldNamefieldName",
                  fieldName,
                  " && ",
                  "fieldValuefieldValue ",
                  fieldValue
                );
                formMethods.setValue(fieldName, fieldValue);
                if (fieldName === "email") {
                  if (fieldValue !== null) {
                    formMethods.setValue(fieldName, fieldValue);
                  } else {
                    const maskedStatus: any =
                      sessionStorage.getItem("maskEnabled");
                    if (maskedStatus === "true") {
                      formMethods.setValue(fieldName, "ze*****3@gmail.com");
                    } else {
                      formMethods.setValue(fieldName, "");
                    }
                  }
                }
                if (fieldName === "address") {
                  Object.keys(fieldValue).forEach((resourceFieldName) => {
                    const resourceFieldValue = fieldValue[resourceFieldName];
                    // @ts-ignore
                    console.log(
                      "resourceFieldName : ",
                      resourceFieldName,
                      "resourceFieldValue : ",
                      resourceFieldValue
                    );
                    Object.keys(resourceFieldValue).forEach(
                      (resourceFieldNameNew) => {
                        const resourceFieldValueNew =
                          resourceFieldValue[resourceFieldNameNew];
                        // @ts-ignore
                        console.log(
                          "resourceFieldNameNew : ",
                          resourceFieldNameNew,
                          "resourceFieldValue : ",
                          resourceFieldValueNew
                        );
                        formMethods.setValue(
                          resourceFieldNameNew,
                          resourceFieldValueNew
                        );

                        if (resourceFieldNameNew === "country") {
                          formMethods.setValue(
                            resourceFieldNameNew,
                            resourceFieldValueNew.name ?? undefined
                          );
                        }
                        if (resourceFieldNameNew === "province") {
                          let id = resourceFieldValueNew.id;
                          let name = resourceFieldValueNew.name;
                          let shortCode = resourceFieldValueNew.shortCode;

                          console.log("namenamenmae", name);

                          formMethods.setValue(
                            resourceFieldNameNew,
                            `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                            // '{id:1, name: "Alberta",shortCode:"AB"}'
                          );
                        }
                      }
                    );
                  });
                }
                if (fieldName === "id") {
                  // (async () => {
                  //   try {
                  //     const responseAssociationId =
                  //       await T4SlipService.getRoleAssociationId(fieldValue);

                  //     console.log(
                  //       "responseData getRoleAssociationId : ",
                  //       responseAssociationId.associationId
                  //     );

                      formMethods.setValue(
                        "selectedVendor",
                        // responseAssociationId.associationId
                        fieldValue
                      );

                      // (async () => {
                      //   try {
                      //     const responseGetResId =
                      //       await T4SlipService.getResourceActualId(
                      //         responseAssociationId.associationId
                      //       );
                      //     console.log(
                      //       "responseData////////",
                      //       responseGetResId.resourceId
                      //     );

                          (async () => {
                            try {
                              const response: any =
                                await RL3SlipService.getResourceSlipDropDownList(
                                  // responseGetResId.resourceId,
                                  fieldValue,
                                  clientId,
                                  taxYear
                                );
                              console.log(
                                "responseData----------------- inside selectedNotStartedResourceData",
                                response
                              );
                              // formMethods.setValue("selectedSlip", response[0].value);

                              if (response && Array.isArray(response)) {
                                let resourceSlipList: IDropDownListResponse[] =
                                  response.map(
                                    (resourceSlip: IDropDownListResponse) => ({
                                      label: resourceSlip.label,
                                      value: resourceSlip.value,
                                    })
                                  );

                                resourceSlipList.unshift({
                                  label: "Create New",
                                  value: "create-new",
                                });

                                console.log(
                                  "resourceSlipListDDL",
                                  resourceSlipList
                                );
                                setSelectedResourceSlipList(resourceSlipList);
                                if (
                                  resourceSlipList &&
                                  resourceSlipList.length > 1
                                ) {
                                  (async () => {
                                    try {
                                      console.log(
                                        "slipssss",
                                        resourceSlipList[1].value
                                      );
                                      setIsLoadingAdjustedData(true);
                                      let slipDataById =
                                        await RL3SlipService.getRL3SlipById(
                                          String(resourceSlipList[1].value),
                                          clientId,
                                          taxYear
                                        );
                                        setRL3SlipRecord(slipDataById)
                                      setSelectedResourceSlip(
                                        String(resourceSlipList[1].value)
                                      );
                                      formMethods.setValue(
                                        "selectedSlip",
                                        String(resourceSlipList[1].value)
                                      );
                                      console.log("slipDataById", slipDataById);
                                      if (slipDataById) {
                                        Object.keys(slipDataById).forEach(
                                          (fieldName) => {
                                            console.log(
                                              "fieldName useEffect T4 Slip : slipDataById",
                                              fieldName
                                            );
                                            // @ts-ignore
                                            const fieldValue =
                                              slipDataById[fieldName];
                                            console.log(
                                              "fieldValue useEffect T4 Slip : ",
                                              fieldValue
                                            );
                                            // @ts-ignore

                                            if (
                                              typeof fieldValue !== "object"
                                            ) {
                                              // @ts-ignore
                                              formMethods.setValue(
                                                fieldName,
                                                fieldValue ?? undefined
                                              );
                                            } else if (
                                              fieldValue !== null ||
                                              undefined
                                            ) {
                                              console.log("object");
                                              Object.keys(fieldValue).forEach(
                                                (objFieldName: any) => {
                                                  console.log(
                                                    "objFieldName useEffect T4 Slip : ",
                                                    objFieldName
                                                  );
                                                  // @ts-ignore
                                                  const objFieldValue =
                                                    fieldValue[objFieldName];
                                                  console.log(
                                                    "objFieldValue useEffect T4 Slip : ",
                                                    objFieldValue
                                                  );

                                                  if (
                                                    typeof objFieldValue !==
                                                    "object"
                                                  ) {
                                                    // @ts-ignore
                                                    formMethods.setValue(
                                                      objFieldName,
                                                      objFieldValue
                                                    );
                                                  }
                                                  if (
                                                    fieldName === "resource"
                                                  ) {
                                                    if (
                                                      objFieldName ===
                                                      "externalId"
                                                    ) {
                                                      // console.log("objFieldValue : externalid ", objFieldValue);
                                                      // let roleAssociationId = "d0a41913-11f3-4f00-a824-9977909bdd04";
                                                      // (async () => {
                                                      //   try {
                                                      //     const responseAssociationId =
                                                      //       await T4SlipService.getRoleAssociationId(
                                                      //         objFieldValue
                                                      //       );

                                                      //     console.log(
                                                      //       "responseData getRoleAssociationId : ",
                                                      //       responseAssociationId.associationId
                                                      //     );
                                                          //for auto selected card
                                                          setSelectedCard(
                                                            String(
                                                              objFieldValue
                                                            )
                                                          );
                                                          formMethods.setValue(
                                                            "selectedVendor",
                                                            objFieldValue
                                                          );

                                                          // (async () => {
                                                          //   try {
                                                          //     const responseGetResId =
                                                          //       await T4SlipService.getResourceActualId(
                                                          //         responseAssociationId.associationId
                                                          //       );
                                                          //     console.log(
                                                          //       "responseData////////",
                                                          //       responseGetResId.resourceId
                                                          //     );

                                                              (async () => {
                                                                try {
                                                                  const response: any =
                                                                    await RL3SlipService.getResourceSlipDropDownList(
                                                                      objFieldValue,
                                                                      clientId,
                                                                      taxYear
                                                                    );
                                                                  // console.log(
                                                                  //   "responseData-----------------",
                                                                  //   response[0].value
                                                                  // );
                                                                  // formMethods.setValue("selectedSlip", response[0].value);

                                                                  if (
                                                                    response &&
                                                                    Array.isArray(
                                                                      response
                                                                    )
                                                                  ) {
                                                                    let resourceSlipList: IDropDownListResponse[] =
                                                                      response.map(
                                                                        (
                                                                          resourceSlip: IDropDownListResponse
                                                                        ) => ({
                                                                          label:
                                                                            resourceSlip.label,
                                                                          value:
                                                                            resourceSlip.value,
                                                                        })
                                                                      );

                                                                    resourceSlipList.unshift(
                                                                      {
                                                                        label:
                                                                          "Create New",
                                                                        value:
                                                                          "create-new",
                                                                      }
                                                                    );

                                                                    console.log(
                                                                      "resourceSlipListDDL inside slipDataById",
                                                                      resourceSlipList
                                                                    );
                                                                    setSelectedResourceSlipList(
                                                                      resourceSlipList
                                                                    );
                                                                    //for Cards scenario
                                                                    if (
                                                                      resourceSlipList &&
                                                                      resourceSlipList.length >
                                                                        1
                                                                    ) {
                                                                      setSelectedResourceSlip(
                                                                        String(
                                                                          resourceSlipList[1]
                                                                            .value
                                                                        )
                                                                      );
                                                                    } else {
                                                                      setSelectedResourceSlip(
                                                                        "create-new"
                                                                      );
                                                                    }
                                                                  }
                                                                } catch (error) {
                                                                  console.error(
                                                                    "Error fetching data:",
                                                                    error
                                                                  );
                                                                }
                                                              })();
                                                              // You can work with the data here or trigger other functions that depend on the fetched data
                                                      //       } catch (error) {
                                                      //         console.error(
                                                      //           "Error fetching data:",
                                                      //           error
                                                      //         );
                                                      //       }
                                                      //     })();
                                                      //   } catch (error) {
                                                      //     console.error(
                                                      //       "Error fetching data:",
                                                      //       error
                                                      //     );
                                                      //   }
                                                      // })();
                                                      setSelectedResource(
                                                        objFieldValue
                                                      );
                                                    }
                                                    setEditedResourceId(
                                                      fieldValue.externalId
                                                    );
                                                    if (
                                                      objFieldName === "email"
                                                    ) {
                                                      if (
                                                        objFieldValue !== null
                                                      ) {
                                                        formMethods.setValue(
                                                          objFieldName,
                                                          objFieldValue
                                                        );
                                                      } else {
                                                        const maskedStatus: any =
                                                          sessionStorage.getItem(
                                                            "maskEnabled"
                                                          );
                                                        console.log(
                                                          "elesese",
                                                          maskedStatus
                                                        );
                                                        if (
                                                          maskedStatus ===
                                                          "true"
                                                        ) {
                                                          formMethods.setValue(
                                                            objFieldName,
                                                            "ze*****3@gmail.com"
                                                          );
                                                        } else {
                                                          formMethods.setValue(
                                                            objFieldName,
                                                            ""
                                                          );
                                                        }
                                                      }
                                                    }
                                                  }
                                                  if (
                                                    objFieldName === "province"
                                                  ) {
                                                    let id = objFieldValue.id;
                                                    let name =
                                                      objFieldValue.name;
                                                    let shortCode =
                                                      objFieldValue.shortCode;
                                                    console.log(
                                                      "namename",
                                                      name
                                                    );

                                                    formMethods.setValue(
                                                      objFieldName,
                                                      // '{id:9, name: "Ontario",shortCode:"ON"}'
                                                      `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                                                    );
                                                  }
                                                  if (
                                                    objFieldName === "country"
                                                  ) {
                                                    formMethods.setValue(
                                                      objFieldName,
                                                      objFieldValue.name ??
                                                        undefined
                                                    );
                                                  }
                                                  if (
                                                    objFieldName ===
                                                    "uniqueIdentifier"
                                                  ) {
                                                    const formattedNumber =
                                                      objFieldValue !== null
                                                        ? objFieldValue.replace(
                                                            /(\d{3})(\d{3})(\d{3})/,
                                                            "$1-$2-$3"
                                                          )
                                                        : undefined;
                                                    formMethods.setValue(
                                                      "uniqueIdentifier",
                                                      formattedNumber
                                                    );
                                                  }
                                                  if (
                                                    objFieldName ===
                                                    "recipientType"
                                                  ) {
                                                    formMethods.setValue(
                                                      "recipientType",
                                                      objFieldValue
                                                    );
                                                  }
                                                }
                                              );
                                            }
                                            //set slip type according to edited row data for submit button action
                                            if (
                                              fieldName === "otherInformation"
                                            ) {
                                              setSlipOtherInformation(fieldValue)
                                              // if (
                                              //   fieldValue &&
                                              //   fieldValue !== null &&
                                              //   fieldValue.length !== 0
                                              // ) {
                                              //   setOiInEdit(true);
                                              //   formMethods.setValue(
                                              //     "comparison",
                                              //     fieldValue
                                              //   );
                                              // } else {
                                              //   console.log(
                                              //     "fieldValue1234ee else",
                                              //     fieldValue
                                              //   );
                                              //   setOiInEdit(true);
                                              //   formMethods.setValue(
                                              //     "comparison",
                                              //     [
                                              //       {
                                              //         amount: 0,
                                              //         code: "",
                                              //         label: "",
                                              //       },
                                              //     ]
                                              //   );
                                              // }
                                            }
                                            if (fieldName === "id") {
                                              // setNewCreatedSlipId(fieldValue)
                                              formMethods.setValue(
                                                "selectedSlip",
                                                fieldValue
                                              );
                                              let path = `/taxslip/rl3/setup/${fieldValue}`;
                                              navigate(path);
                                            }
                                            if (fieldName === "slipData") {
                                              Object.keys(fieldValue).forEach((slipDataFieldName: any) => {
                                                const slipDataFieldValue = fieldValue[slipDataFieldName];
                                                console.log("slipDataFieldValue123", slipDataFieldValue);
                                                if (
                                                  slipDataFieldName !==
                                                    "currencyCode" &&
                                                  slipDataFieldName !==
                                                    "bankTransit" &&
                                                  slipDataFieldName !==
                                                    "otherIdentificationNumber" &&
                                                  slipDataFieldName !==
                                                    "numberAssignedByPayerOrNominee" &&
                                                  slipDataFieldName !==
                                                    "optionalTextToPrintOnTheSlip"
                                                ) {
                                                formMethods.setValue(
                                                  slipDataFieldName,
                                                  slipDataFieldValue && slipDataFieldValue !== null
                                                    ? parseFloat(slipDataFieldValue).toFixed(2)
                                                    : "0.00"
                                                );
                                              } else {
                                                formMethods.setValue(
                                                  slipDataFieldName,
                                                  slipDataFieldValue
                                                );
                                              }
                                              });
                                            }
                                            if (
                                              fieldName === "secondExternalId"
                                            ) {
                                              if (fieldValue !== null) {
                                                // (async () => {
                                                //   try {
                                                //     const responseAssociationId =
                                                //       await T4SlipService.getRoleAssociationId(
                                                //         fieldValue
                                                //       );
                                                    formMethods.setValue(
                                                      "secondRecipientName",
                                                      fieldValue
                                                    );
                                                //   } catch {}
                                                // })();
                                              }
                                            }
                                          }
                                        );
                                      }
                                      //set slip type to 400 because when we come back to slip without reload it will automatically be original
                                      selectedRL3SlipType =
                                        formMethods.getValues("slipType");
                                      // //otherinformation will update sselected items when its submitted
                                      setSelectedOtherInformation(true);
                                    } catch (error) {
                                      setIsLoadingAdjustedData(false);
                                      console.error(
                                        "Error fetching data:",
                                        error
                                      );
                                    } finally {
                                      setSelectedResourceSlip(
                                        String(resourceSlipList[1].value)
                                      );
                                      formMethods.setValue(
                                        "selectedSlip",
                                        String(resourceSlipList[1].value)
                                      );
                                      setSelectedResource(
                                        String(
                                          selectedItem.value
                                        )
                                      );
                                      let path = `/taxslip/rl3/setup/${resourceSlipList[1].value}`;
                                      navigate(path);
                                      setTimeout(() => {
                                        setIsLoadingAdjustedData(false);
                                      }, 3000);
                                    }
                                  })();
                                } else {
                                  setRL3SlipRecord(undefined)
                                  setSelectedResourceSlip("create-new");
                                  formMethods.setValue(
                                    "selectedSlip",
                                    "create-new"
                                  );
                                  formMethods.setValue(
                                    "actualAmountOfEligibleDivinends",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "taxableAmountOfDividends",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "dividendTaxCredit",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "interestFromCanadianSources",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "otherIncomeFromCanadianSources",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "grossForeignInvestmentIncome",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "foreignIncomeTax",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "royaltiesFromCanadianSources",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "capitalGainDividends",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "accuredIncomeOrAnnuities",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "interestFromLinkedNotes",
                                    "0.00"
                                  );
                                  formMethods.setValue(
                                    "currencyCode",
                                    undefined
                                  );
                                  formMethods.setValue("bankTransit", "");
                                  formMethods.setValue(
                                    "otherIdentificationNumber",
                                    ""
                                  );
                                  formMethods.setValue(
                                    "numberAssignedByPayerOrNominee",
                                    ""
                                  );
                                  formMethods.setValue(
                                    "optionalTextToPrintOnTheSlip",
                                    ""
                                  );
                                  formMethods.setValue("slipStatus", 200);
                                  formMethods.setValue("comparison", []);
                                  setSlipOtherInformation([{ amount: 0, code: 'null', label:'Empty Field'}])

                                  setSelectedResource(
                                    String(
                                      selectedItem.value
                                    )
                                  );
                                  setEditedResourceId(
                                    String(
                                      selectedItem.value
                                    )
                                  );
                                  let path = `/taxslip/rl3/setup/${selectedItem.value}`;
                                  navigate(path);
                                  // setIsLoadingAdjustedData(false)
                                  setTimeout(() => {
                                    setIsLoadingAdjustedData(false);
                                  }, 3000);
                                }
                              }
                            } catch (error) {
                              console.error("Error fetching data:", error);
                            }
                          })();
                          // You can work with the data here or trigger other functions that depend on the fetched data
                  //       } catch (error) {
                  //         console.error("Error fetching data:", error);
                  //       }
                  //     })();
                  //   } catch (error) {
                  //     console.error("Error fetching data:", error);
                  //   }
                  // })();
                  setSelectedResource(fieldValue);
                }
                if (fieldName === "uniqueIdentifier") {
                  const formattedNumber =
                    fieldValue !== null
                      ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
                      : undefined;
                  formMethods.setValue("uniqueIdentifier", formattedNumber);
                }

                if (fieldName === "firstName") {
                  formMethods.setValue(
                    "firstName",
                    fieldValue !== null ? fieldValue : undefined
                  );
                }
                // }
                if (fieldName === "metaFields") {
                  fieldValue.forEach((obj: any) => {
                    formMethods.setValue(obj.name, obj.value);
                    if (obj.name === "initial") {
                      formMethods.setValue("initials", obj.value);
                    }
                    if (obj.name === "recipientType") {
                      formMethods.setValue(obj.name, parseInt(obj.value));
                    }
                    console.log(
                      `"objobj" : ${obj.name} ====== objValue : ${obj.value}`
                    );
                  });
                }
              });
            }
          } catch (error) {
            setIsLoadingAdjustedData(false);
            console.error("Error fetching data:", error);
          }
          // finally {
          //   setSelectedResource(String(response.resourceId));
          //   setEditedResourceId(String(response.resourceId));
          //   let path = `/taxslip/t4/setup/${response.resourceId}`;
          //   navigate(path);
          //   // setIsLoadingAdjustedData(false)
          //   setTimeout(() => {
          //     setIsLoadingAdjustedData(false);
          //   }, 3000);
          // }
        })();
      // } catch (err) {
      //   console.log("err", err);
      // }
    }
    //otherinformation will update selected items
    setSelectedOtherInformation(false);
  };

  const onResourceSlipSelectionChangeHandler = (
    selectedItem: IEnhancedDropDownItem | any
  ) => {
    setOnResourceSelectionAPIsCalls(true);
    if (selectedItem.value === "create-new") {
      createNewActionFromSlipDDL();
      setSelectedResourceSlip("create-new");
      if (selectedResource !== "create-new") {
        setOnResourceSelectionAPIsCalls(true);
        let path = `/taxslip/rl3/setup/${selectedResource}`;
        navigate(path);
        window.location.reload()
      }
      setRL3SlipRecord(undefined);
    }
    if (selectedItem.value !== "create-new") {
      // setSelectedResourceSlip(String(selectedItem.value));
      // let path = `/taxslip/rl3/setup/${selectedItem.value}`;
      // navigate(path);
      // setIsLoadingAdjustedData(true);
      // setTimeout(() => {
      //   setIsLoadingAdjustedData(false);
      // }, 1000);
      (async () => {
        try {
          setIsLoadingAdjustedData(true);
          let slipDataById = await RL3SlipService.getRL3SlipById(
            String(selectedItem.value),
            clientId,
            taxYear
          );
          setRL3SlipRecord(slipDataById)
          console.log("slipDataById", slipDataById);
          if (slipDataById) {
            Object.keys(slipDataById).forEach((fieldName) => {
              console.log(
                "fieldName useEffect T4 Slip : slipDataById",
                fieldName
              );
              // @ts-ignore
              const fieldValue = slipDataById[fieldName];
              console.log("fieldValue useEffect T4 Slip : ", fieldValue);
              // @ts-ignore

              if (typeof fieldValue !== "object") {
                // @ts-ignore
                formMethods.setValue(fieldName, fieldValue ?? undefined);
              } else if (fieldValue !== null || undefined) {
                console.log("object");
                Object.keys(fieldValue).forEach((objFieldName: any) => {
                  console.log(
                    "objFieldName useEffect T4 Slip : ",
                    objFieldName
                  );
                  // @ts-ignore
                  const objFieldValue = fieldValue[objFieldName];
                  console.log(
                    "objFieldValue useEffect T4 Slip : ",
                    objFieldValue
                  );

                  if (typeof objFieldValue !== "object") {
                    // @ts-ignore
                    formMethods.setValue(objFieldName, objFieldValue);
                  }
                  if (fieldName === "resource") {
                    if (objFieldName === "externalId") {
                      // console.log("objFieldValue : externalid ", objFieldValue);
                      // let roleAssociationId = "d0a41913-11f3-4f00-a824-9977909bdd04";
                      // (async () => {
                      //   try {
                      //     const responseAssociationId =
                      //       await T4SlipService.getRoleAssociationId(
                      //         objFieldValue
                      //       );

                      //     console.log(
                      //       "responseData getRoleAssociationId : ",
                      //       responseAssociationId.associationId
                      //     );

                          formMethods.setValue(
                            "selectedVendor",
                            objFieldValue
                          );

                          // (async () => {
                          //   try {
                          //     const responseGetResId =
                          //       await T4SlipService.getResourceActualId(
                          //         responseAssociationId.associationId
                          //       );
                          //     console.log(
                          //       "responseData////////",
                          //       responseGetResId.resourceId
                          //     );

                              (async () => {
                                try {
                                  const response: any =
                                    await RL3SlipService.getResourceSlipDropDownList(
                                      objFieldValue,
                                      clientId,
                                      taxYear
                                    );
                                  // console.log(
                                  //   "responseData-----------------",
                                  //   response[0].value
                                  // );
                                  // formMethods.setValue("selectedSlip", response[0].value);

                                  if (response && Array.isArray(response)) {
                                    let resourceSlipList: IDropDownListResponse[] =
                                      response.map(
                                        (
                                          resourceSlip: IDropDownListResponse
                                        ) => ({
                                          label: resourceSlip.label,
                                          value: resourceSlip.value,
                                        })
                                      );

                                    resourceSlipList.unshift({
                                      label: "Create New",
                                      value: "create-new",
                                    });

                                    console.log(
                                      "resourceSlipListDDL inside slipDataById",
                                      resourceSlipList
                                    );
                                    setSelectedResourceSlipList(
                                      resourceSlipList
                                    );
                                    //for Cards scenario
                                    if (
                                      resourceSlipList &&
                                      resourceSlipList.length > 1
                                    ) {
                                      setSelectedResourceSlip(
                                        String(resourceSlipList[1].value)
                                      );
                                    } else {
                                      setSelectedResourceSlip("create-new");
                                    }
                                  }
                                } catch (error) {
                                  console.error("Error fetching data:", error);
                                }
                              })();
                              // You can work with the data here or trigger other functions that depend on the fetched data
                      //       } catch (error) {
                      //         console.error("Error fetching data:", error);
                      //       }
                      //     })();
                      //   } catch (error) {
                      //     console.error("Error fetching data:", error);
                      //   }
                      // })();
                      setSelectedResource(objFieldValue);
                    }
                    setEditedResourceId(fieldValue.externalId);
                    if (objFieldName === "email") {
                      if (objFieldValue !== null) {
                        formMethods.setValue(objFieldName, objFieldValue);
                      } else {
                        const maskedStatus: any =
                          sessionStorage.getItem("maskEnabled");
                        console.log("elesese", maskedStatus);
                        if (maskedStatus === "true") {
                          formMethods.setValue(
                            objFieldName,
                            "ze*****3@gmail.com"
                          );
                        } else {
                          formMethods.setValue(objFieldName, "");
                        }
                      }
                    }
                  }
                  if (objFieldName === "province") {
                    let id = objFieldValue.id;
                    let name = objFieldValue.name;
                    let shortCode = objFieldValue.shortCode;
                    console.log("namename", name);

                    formMethods.setValue(
                      objFieldName,
                      // '{id:9, name: "Ontario",shortCode:"ON"}'
                      `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                    );
                  }
                  if (objFieldName === "country") {
                    formMethods.setValue(
                      objFieldName,
                      objFieldValue.name ?? undefined
                    );
                  }
                  if (objFieldName === "uniqueIdentifier") {
                    const formattedNumber =
                      objFieldValue !== null
                        ? objFieldValue.replace(
                            /(\d{3})(\d{3})(\d{3})/,
                            "$1-$2-$3"
                          )
                        : undefined;
                    formMethods.setValue("uniqueIdentifier", formattedNumber);
                  }
                });
              }
              //set slip type according to edited row data for submit button action
              if (fieldName === "slipType") {
                selectedRL3SlipType = fieldValue;
                setIsLoadingAdjustedData(true);
                setTimeout(() => {
                  setIsLoadingAdjustedData(false);
                }, 1000);
              }
              if (fieldName === "otherInformation") {
                // setSlipOtherInformation(fieldValue)
                setSlipOtherInformation([])
                if (fieldValue && fieldValue !== null && fieldValue.length !== 0) {
                  console.log("fieldValue1234ee", fieldValue);
                  let oiFromSimplePay = [
                    {
                      code: "32",
                      label: "Other Information",
                      amount: 400.0,
                    },
                    {
                      code: "42",
                      label: "Other Information",
                      amount: 200.0,
                    },
                  ];
                  setOiInEdit(true);
                  formMethods.setValue("comparison", fieldValue);
                } else {
                  console.log("fieldValue1234ee else", fieldValue);
                  setOiInEdit(true);
                  formMethods.setValue("comparison", [
                    { amount: 0, code: "", label: "" },
                  ]);
                }
              }
              if (fieldName === "id") {
                // setNewCreatedSlipId(fieldValue)
                formMethods.setValue("selectedSlip", fieldValue);
                let path = `/taxslip/rl3/setup/${fieldValue}`;
                navigate(path);
              }
              if (fieldName === "slipData") {
                Object.keys(fieldValue).forEach((slipDataFieldName: any) => {
                  const slipDataFieldValue = fieldValue[slipDataFieldName];
                  console.log("slipDataFieldValue123", slipDataFieldValue);
                  if (
                    slipDataFieldName !==
                      "currencyCode" &&
                    slipDataFieldName !==
                      "bankTransit" &&
                    slipDataFieldName !==
                      "otherIdentificationNumber" &&
                    slipDataFieldName !==
                      "numberAssignedByPayerOrNominee" &&
                    slipDataFieldName !==
                      "optionalTextToPrintOnTheSlip"
                  ) {
                  formMethods.setValue(
                    slipDataFieldName,
                    slipDataFieldValue && slipDataFieldValue !== null
                      ? parseFloat(slipDataFieldValue).toFixed(2)
                      : "0.00"
                  );
                } else {
                  formMethods.setValue(
                    slipDataFieldName,
                    slipDataFieldValue
                  );
                }
                });
              }
              if (fieldName === "recipientType") {
                formMethods.setValue("recipientType", fieldValue);
                if(fieldValue === 20 || fieldValue === 60 || fieldValue === 70){
                  const individual = RL3SlipRecord.individual
                  if(individual === false){
                    (async () => {
                      try {
                        setIsLoadingAdjustedData(true);
                        const response: any =
                          await RL3SlipService.getRL3RecipientDDLByRecipientType(
                            clientId,
                            T5_RECIPIENT_RESOURCE_LITERAL,
                            taxYear,
                            30
                          );
                        console.log(
                          "responseData-----------------secondRecipientDDL",
                          response
                        );
                        setSecondRecipientDDL(response);
                      } catch (error) {
                        console.error("Error fetching data:", error);
                      } finally {
                        setIsLoadingAdjustedData(false);
                      }
                    })();
                  } else {
                    (async () => {
                      try {
                        setIsLoadingAdjustedData(true);
                        const response: any =
                          await RL3SlipService.getRL3RecipientDDLByRecipientType(
                            clientId,
                            T5_RECIPIENT_RESOURCE_LITERAL,
                            taxYear,
                            10
                          );
                        console.log(
                          "responseData-----------------secondRecipientDDL",
                          response
                        );
                        setSecondRecipientDDL(response);
                      } catch (error) {
                        console.error("Error fetching data:", error);
                      } finally {
                        setIsLoadingAdjustedData(false);
                      }
                    })();
                  }
                }
              }
              if (fieldName === "secondExternalId") {
                if (fieldValue !== null) {
                  // (async () => {
                  //   try {
                  //     const responseAssociationId =
                  //       await T4SlipService.getRoleAssociationId(fieldValue);
                      formMethods.setValue(
                        "secondRecipientName",
                       fieldValue
                      );
                  //   } catch {}
                  // })();
                }
              }
            });
          }
          selectedRL3SlipType = formMethods.getValues("slipType");
          setSelectedOtherInformation(false);
        } catch (error) {
          setIsLoadingAdjustedData(false);
          console.error("Error fetching data:", error);
        } finally {
          setSelectedResourceSlip(String(selectedItem.value));
          let path = `/taxslip/rl3/setup/${selectedItem.value}`;
          navigate(path);
          setIsLoadingAdjustedData(true);
          setTimeout(() => {
            setIsLoadingAdjustedData(false);
          }, 2500);
        }
      })();
    }
  };

  const { isLoading: isLoadingGlobalVariables, data: editDataRecord } =
    useGetGlobalVariables(taxYear);
  const globalVariablesData: any = editDataRecord;

  const { isLoading:loadingSlipIds, data:rl3SlipIds, refetch:refectchRL3SlipIds } = useGetSlipIds("RL3", clientId, taxYear);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  useEffect(()=>{
    console.log("companyGetByIdRecord11",companyGetByIdRecord)
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor ? parseInt(paymentFor.value) : 10)
    }
  },[companyGetByIdRecord])

  // for edit

  //if slip is completed this will effected
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (RL3SlipRecord && onResourceSelectionAPIsCalls === false) {
      Object.keys(RL3SlipRecord).forEach((fieldName) => {
        console.log("fieldName useEffect T4 Slip : RL3SlipRecord", fieldName);
        // @ts-ignore
        const fieldValue = RL3SlipRecord[fieldName];
        console.log("fieldValue useEffect T4 Slip : ", fieldValue);
        // @ts-ignore

        if (typeof fieldValue !== "object") {
          // @ts-ignore
          formMethods.setValue(fieldName, fieldValue ?? undefined);
        } else if (fieldValue !== null || undefined) {
          console.log("object");
          Object.keys(fieldValue).forEach((objFieldName: any) => {
            console.log("objFieldName useEffect T4 Slip : ", objFieldName);
            // @ts-ignore
            const objFieldValue = fieldValue[objFieldName];
            console.log("objFieldValue useEffect T4 Slip : ", objFieldValue);

            if (typeof objFieldValue !== "object") {
              // @ts-ignore
              formMethods.setValue(objFieldName, objFieldValue);
            }
            if (fieldName === "resource") {
              if (objFieldName === "externalId") {
                // console.log("objFieldValue : externalid ", objFieldValue);
                // let roleAssociationId = "d0a41913-11f3-4f00-a824-9977909bdd04";
                // (async () => {
                //   try {
                //     const responseAssociationId =
                //       await T4SlipService.getRoleAssociationId(objFieldValue);

                //     console.log(
                //       "responseData getRoleAssociationId : ",
                //       responseAssociationId.associationId
                //     );
                    //for auto selected card
                    setSelectedCard(
                      String(objFieldValue)
                    );
                    formMethods.setValue(
                      "selectedVendor",
                      objFieldValue
                    );

                    // (async () => {
                    //   try {
                    //     const responseGetResId =
                    //       await T4SlipService.getResourceActualId(
                    //         responseAssociationId.associationId
                    //       );
                    //     console.log(
                    //       "responseData////////",
                    //       responseGetResId.resourceId
                    //     );

                        (async () => {
                          try {
                            const response: any =
                              await RL3SlipService.getResourceSlipDropDownList(
                                objFieldValue,
                                clientId,
                                taxYear
                              );
                            console.log(
                              "responseData-----------------selectedResourceSlipList",
                              response
                            );
                            // formMethods.setValue("selectedSlip", response[0].value);

                            if (response && Array.isArray(response)) {
                              let resourceSlipList: IDropDownListResponse[] =
                                response.map(
                                  (resourceSlip: IDropDownListResponse) => ({
                                    label: resourceSlip.label,
                                    value: resourceSlip.value,
                                  })
                                );

                              resourceSlipList.unshift({
                                label: "Create New",
                                value: "create-new",
                              });

                              console.log(
                                "resourceSlipListDDL inside RL3SlipRecord",
                                resourceSlipList
                              );
                              setSelectedResourceSlipList(resourceSlipList);
                              //for Cards scenario
                              if (
                                resourceSlipList &&
                                resourceSlipList.length > 1
                              ) {
                                setSelectedResourceSlip(
                                  String(resourceSlipList[1].value)
                                );
                              } else {
                                setSelectedResourceSlip("create-new");
                              }
                            }
                          } catch (error) {
                            console.error("Error fetching data:", error);
                          }
                        })();
                        // You can work with the data here or trigger other functions that depend on the fetched data
                //       } catch (error) {
                //         console.error("Error fetching data:", error);
                //       }
                //     })();
                //   } catch (error) {
                //     console.error("Error fetching data:", error);
                //   }
                // })();
                setSelectedResource(objFieldValue);
              }
              setEditedResourceId(fieldValue.externalId);
              if (objFieldName === "email") {
                if (objFieldValue !== null) {
                  formMethods.setValue(objFieldName, objFieldValue);
                } else {
                  const maskedStatus: any =
                    sessionStorage.getItem("maskEnabled");
                  console.log("elesese", maskedStatus);
                  if (maskedStatus === "true") {
                    formMethods.setValue(objFieldName, "ze*****3@gmail.com");
                  } else {
                    formMethods.setValue(objFieldName, "");
                  }
                }
              }
            }
            if (objFieldName === "province") {
              let id = objFieldValue.id;
              let name = objFieldValue.name;
              let shortCode = objFieldValue.shortCode;
              console.log("namename", name);

              formMethods.setValue(
                objFieldName,
                // '{id:9, name: "Ontario",shortCode:"ON"}'
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
              );
            }
            if (objFieldName === "provinceOfEmployment") {
              let id = objFieldValue.id;
              let name = objFieldValue.name;
              let shortCode = objFieldValue.shortCode;
              console.log("namename", name);

              formMethods.setValue(
                objFieldName,
                // '{id:9, name: "Ontario",shortCode:"ON"}'
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
              );
            }
            if (objFieldName === "country") {
              formMethods.setValue(
                objFieldName,
                objFieldValue.name ?? undefined
              );
            }
            if (objFieldName === "uniqueIdentifier") {
              const formattedNumber =
                objFieldValue !== null
                  ? objFieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
                  : undefined;
              formMethods.setValue("uniqueIdentifier", formattedNumber);
            }
          });
        }
        //set slip type according to edited row data for submit button action
        if (fieldName === "slipType") {
          selectedRL3SlipType = fieldValue;
          setIsLoadingAdjustedData(true);
          setTimeout(() => {
            setIsLoadingAdjustedData(false);
          }, 1000);
        }
        if (fieldName === "otherInformation") {
          setSlipOtherInformation([])
          if (fieldValue && fieldValue !== null && fieldValue.length !== 0) {
            console.log("fieldValue1234ee", fieldValue);
            let oiFromSimplePay = [
              {
                code: "32",
                label: "Other Information",
                amount: 400.0,
              },
              {
                code: "42",
                label: "Other Information",
                amount: 200.0,
              },
            ];
            setOiInEdit(true);
            formMethods.setValue("comparison", fieldValue);
          } else {
            console.log("fieldValue1234ee else", fieldValue);
            setOiInEdit(true);
            formMethods.setValue("comparison", [
              { amount: 0, code: "", label: "" },
            ]);
          }
        }
        if (fieldName === "id") {
          // setNewCreatedSlipId(fieldValue)
          formMethods.setValue("selectedSlip", fieldValue);
          let path = `/taxslip/rl3/setup/${fieldValue}`;
          navigate(path);
        }
        if (fieldName === "slipData") {
          Object.keys(fieldValue).forEach((slipDataFieldName: any) => {
            const slipDataFieldValue = fieldValue[slipDataFieldName];
            console.log("slipDataFieldValue123", slipDataFieldValue);
            if (
              slipDataFieldName !==
                "currencyCode" &&
              slipDataFieldName !==
                "bankTransit" &&
              slipDataFieldName !==
                "otherIdentificationNumber" &&
              slipDataFieldName !==
                "numberAssignedByPayerOrNominee" &&
              slipDataFieldName !==
                "optionalTextToPrintOnTheSlip"
            ) {
            formMethods.setValue(
              slipDataFieldName,
              slipDataFieldValue && slipDataFieldValue !== null
                ? parseFloat(slipDataFieldValue).toFixed(2)
                : "0.00"
            );
          } else {
            formMethods.setValue(
              slipDataFieldName,
              slipDataFieldValue
            );
          }
          });
        }
        if (fieldName === "recipientType") {
          formMethods.setValue("recipientType", fieldValue);
          if(fieldValue === 20 || fieldValue === 60 || fieldValue === 70){
            const individual = RL3SlipRecord.individual
            if(individual === false){
              (async () => {
                try {
                  setIsLoadingAdjustedData(true);
                  const response: any =
                    await RL3SlipService.getRL3RecipientDDLByRecipientType(
                      clientId,
                      T5_RECIPIENT_RESOURCE_LITERAL,
                      taxYear,
                      30
                    );
                  console.log(
                    "responseData-----------------secondRecipientDDL",
                    response
                  );
                  setSecondRecipientDDL(response);
                } catch (error) {
                  console.error("Error fetching data:", error);
                } finally {
                  setIsLoadingAdjustedData(false);
                }
              })();
            } else {
              (async () => {
                try {
                  setIsLoadingAdjustedData(true);
                  const response: any =
                    await RL3SlipService.getRL3RecipientDDLByRecipientType(
                      clientId,
                      T5_RECIPIENT_RESOURCE_LITERAL,
                      taxYear,
                      10
                    );
                  console.log(
                    "responseData-----------------secondRecipientDDL",
                    response
                  );
                  setSecondRecipientDDL(response);
                } catch (error) {
                  console.error("Error fetching data:", error);
                } finally {
                  setIsLoadingAdjustedData(false);
                }
              })();
            }
          }
        }
        if (fieldName === "secondExternalId") {
          if (fieldValue !== null) {
            // (async () => {
            //   try {
            //     const responseAssociationId =
            //       await T4SlipService.getRoleAssociationId(fieldValue);
                formMethods.setValue(
                  "secondRecipientName",
                  fieldValue
                );
            //   } catch {}
            // })();
          }
        }
      });
    }
    //set slip type to 400 because when we come back to slip without reload it will automatically be original
    selectedRL3SlipType = formMethods.getValues("slipType");
  }, [RL3SlipRecord, formMethods.setValue, formMethods, onResourceSelectionAPIsCalls]);

  //this is for not started slip
  useEffect(() => {
    // if the selected flex field data has loaded.
    if (selectedNotStartedResourceData && onResourceSelectionAPIsCalls === false) {
      Object.keys(selectedNotStartedResourceData).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = selectedNotStartedResourceData[fieldName];
        console.log(
          "fieldNamefieldName",
          fieldName,
          " && ",
          "fieldValuefieldValue ",
          fieldValue
        );
        formMethods.setValue(fieldName, fieldValue);

        if (fieldName === "email") {
          if (fieldValue !== null) {
            formMethods.setValue(fieldName, fieldValue);
          } else {
            const maskedStatus: any = sessionStorage.getItem("maskEnabled");
            if (maskedStatus === "true") {
              formMethods.setValue(fieldName, "ze*****3@gmail.com");
            } else {
              formMethods.setValue(fieldName, "");
            }
          }
        }
        if (fieldName === "address") {
          Object.keys(fieldValue).forEach((resourceFieldName) => {
            const resourceFieldValue = fieldValue[resourceFieldName];
            // @ts-ignore
            console.log(
              "resourceFieldName : ",
              resourceFieldName,
              "resourceFieldValue : ",
              resourceFieldValue
            );
            Object.keys(resourceFieldValue).forEach((resourceFieldNameNew) => {
              const resourceFieldValueNew =
                resourceFieldValue[resourceFieldNameNew];
              // @ts-ignore
              console.log(
                "resourceFieldNameNew : ",
                resourceFieldNameNew,
                "resourceFieldValue : ",
                resourceFieldValueNew
              );
              formMethods.setValue(resourceFieldNameNew, resourceFieldValueNew);

              if (resourceFieldNameNew === "country") {
                formMethods.setValue(
                  resourceFieldNameNew,
                  resourceFieldValueNew.name ?? undefined
                );
              }
              if (resourceFieldNameNew === "province") {
                let id = resourceFieldValueNew.id;
                let name = resourceFieldValueNew.name;
                let shortCode = resourceFieldValueNew.shortCode;

                console.log("namenamenmae", name);

                formMethods.setValue(
                  resourceFieldNameNew,
                  `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
                  // '{id:1, name: "Alberta",shortCode:"AB"}'
                );
              }
            });
          });
        }
        if (fieldName === "id") {
          // (async () => {
          //   try {
          //     const responseAssociationId =
          //       await T4SlipService.getRoleAssociationId(fieldValue);

          //     console.log(
          //       "responseData getRoleAssociationId : ",
          //       responseAssociationId.associationId
          //     );
              //for auto selected card
              setSelectedCard(String(fieldValue));
              formMethods.setValue(
                "selectedVendor",
                fieldValue
              );

              // (async () => {
              //   try {
              //     const responseGetResId =
              //       await T4SlipService.getResourceActualId(
              //         responseAssociationId.associationId
              //       );
              //     console.log(
              //       "responseData////////",
              //       responseGetResId.resourceId
              //     );

                  (async () => {
                    try {
                      const response: any =
                        await RL3SlipService.getResourceSlipDropDownList(
                          fieldValue,
                          clientId,
                          taxYear
                        );
                      console.log(
                        "responseData----------------- inside selectedNotStartedResourceData",
                        response
                      );
                      // formMethods.setValue("selectedSlip", response[0].value);

                      if (response && Array.isArray(response)) {
                        let resourceSlipList: IDropDownListResponse[] =
                          response.map(
                            (resourceSlip: IDropDownListResponse) => ({
                              label: resourceSlip.label,
                              value: resourceSlip.value,
                            })
                          );

                        resourceSlipList.unshift({
                          label: "Create New",
                          value: "create-new",
                        });

                        console.log("resourceSlipListDDL", resourceSlipList);
                        setSelectedResourceSlipList(resourceSlipList);
                      }
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  })();
                  // You can work with the data here or trigger other functions that depend on the fetched data
          //       } catch (error) {
          //         console.error("Error fetching data:", error);
          //       }
          //     })();
          //   } catch (error) {
          //     console.error("Error fetching data:", error);
          //   }
          // })();
          setSelectedResource(fieldValue);
        }
        if (fieldName === "uniqueIdentifier") {
          const formattedNumber =
            fieldValue !== null
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
              : undefined;
          formMethods.setValue("uniqueIdentifier", formattedNumber);
        }

        if (fieldName === "firstName") {
          formMethods.setValue(
            "firstName",
            fieldValue !== null ? fieldValue : undefined
          );
        }
        // }
        if (fieldName === "metaFields") {
          fieldValue.forEach((obj: any) => {
            formMethods.setValue(obj.name, obj.value);
            if (obj.name === "initial") {
              formMethods.setValue("initials", obj.value);
            }
            if (obj.name === "recipientType") {
              formMethods.setValue(obj.name, parseInt(obj.value));
            }
            console.log(
              `"objobj" : ${obj.name} ====== objValue : ${obj.value}`
            );
          });
        }
      });
      formMethods.setValue("selectedSlip", "create-new");
      formMethods.setValue("actualAmountOfEligibleDivinends", "0.00");
      formMethods.setValue("taxableAmountOfDividends", "0.00");
      formMethods.setValue("dividendTaxCredit", "0.00");
      formMethods.setValue("interestFromCanadianSources", "0.00");
      formMethods.setValue("otherIncomeFromCanadianSources", "0.00");
      formMethods.setValue("grossForeignInvestmentIncome", "0.00");
      formMethods.setValue("foreignIncomeTax", "0.00");
      formMethods.setValue("royaltiesFromCanadianSources", "0.00");
      formMethods.setValue("capitalGainDividends", "0.00");
      formMethods.setValue("accuredIncomeOrAnnuities", "0.00");
      formMethods.setValue("interestFromLinkedNotes", "0.00");
      formMethods.setValue("currencyCode", undefined);
      formMethods.setValue("bankTransit", "");
      formMethods.setValue("otherIdentificationNumber", "");
      formMethods.setValue("numberAssignedByPayerOrNominee", "");
      formMethods.setValue("optionalTextToPrintOnTheSlip", "");
      formMethods.setValue("slipStatus", 200);
      formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
      setSlipOtherInformation([])
    }
  }, [selectedNotStartedResourceData, formMethods.setValue, formMethods, onResourceSelectionAPIsCalls]);

  useEffect(() => {
    //Load RL3 Recipient DDL
    if (RL3SlipRecord) {
      const fetchData = async () => {
        try {
          setIsLoadingAdjustedData(true);
          const response: any =
            await RL3SlipService.getRL3RecipientDDLByRecipientType(
              clientId,
              T5_RECIPIENT_RESOURCE_LITERAL,
              taxYear,
              RL3SlipRecord.recipientType
            );
          if (response && Array.isArray(response)) {
            let resources: IDropDownListResponse[] = response.map(
              (resourceItem: IDropDownListResponse) => ({
                label: resourceItem.label,
                value: resourceItem.value,
              })
            );
            resources.unshift({
              label: "Create New",
              value: "create-new",
            });
            setSelectedResourceList(resources);
          }
          setResourceListWithoutCreateNew(sortData(response));
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoadingAdjustedData(false);
        } finally {
          setIsLoadingAdjustedData(false);
        }
      };
      fetchData();
    } else if (selectedNotStartedResourceData) {
      selectedNotStartedResourceData?.metaFields.forEach((field: any) => {
        if (field.name === "recipientType") {
          let recipientTypeValue = parseInt(field.value);
          const fetchData = async () => {
            try {
              setIsLoadingAdjustedData(true);
              const response: any =
                await RL3SlipService.getRL3RecipientDDLByRecipientType(
                  clientId,
                  T5_RECIPIENT_RESOURCE_LITERAL,
                  taxYear,
                  recipientTypeValue
                );
              if (response && Array.isArray(response)) {
                let resources: IDropDownListResponse[] = response.map(
                  (resourceItem: IDropDownListResponse) => ({
                    label: resourceItem.label,
                    value: resourceItem.value,
                  })
                );
                resources.unshift({
                  label: "Create New",
                  value: "create-new",
                });
                setSelectedResourceList(resources);
              }
              setResourceListWithoutCreateNew(sortData(response));
            } catch (error) {
              console.error("Error fetching data:", error);
              setIsLoadingAdjustedData(false);
            } finally {
              setIsLoadingAdjustedData(false);
            }
          };
          fetchData();
        }
      });
    } else {
      const recipientType = formMethods.getValues("recipientType");
      const fetchData = async () => {
        try {
          setIsLoadingAdjustedData(true);
          const response: any =
            await RL3SlipService.getRL3RecipientDDLByRecipientType(
              clientId,
              T5_RECIPIENT_RESOURCE_LITERAL,
              taxYear,
              recipientType
            );
          if (response && Array.isArray(response)) {
            let resources: IDropDownListResponse[] = response.map(
              (resourceItem: IDropDownListResponse) => ({
                label: resourceItem.label,
                value: resourceItem.value,
              })
            );
            resources.unshift({
              label: "Create New",
              value: "create-new",
            });
            setSelectedResourceList(resources);
          }
          setResourceListWithoutCreateNew(sortData(response));
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoadingAdjustedData(false);
        } finally {
          setIsLoadingAdjustedData(false);
        }
      };
      fetchData();
    }
  }, [RL3SlipRecord, selectedNotStartedResourceData, formMethods]);

      // when  I formMethods.setValue of otherinformation inside async calls method then facing issue while click on add more so we have to use state method to show otherinformation 
      useEffect(()=>{
        console.log("slipOtherInformation outside",slipOtherInformation)
        if (
          slipOtherInformation &&
          slipOtherInformation !== null &&
          slipOtherInformation.length !== 0
        ) {
        console.log("slipOtherInformation",slipOtherInformation)
          setOiInEdit(true);
          formMethods.setValue(
            "comparison",
            slipOtherInformation
          );
        } else if (!id) {
          console.log(
            "slipOtherInformation1234ee else",
            slipOtherInformation
          );
          setOiInEdit(true);
          formMethods.setValue(
            "comparison",
            [
              {
                amount: 0,
                code: "",
                label: "",
              },
            ]
          );
        }
      },[slipOtherInformation])

  const onSlipTypeSelectionChangeHandler = (e: any) => {
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 1000);
    const selectedType = e.value;
    selectedRL3SlipType = selectedType;
    // alert(selectedType);
  };

  const onCloseHandler = () => {
    setDialogOpen(false);
    setInvoiceDialogOpen(false);
  };

  console.log("selectedT4SlipsData.id", selectedRL3SlipsData?.id);
  console.log("selectedResource", selectedResource);
  console.log(
    "selectedT4SlipsData.resource.externalId",
    selectedRL3SlipsData?.resource?.externalId
  );
  console.log(
    "selectedNotStartedResourceData.id",
    selectedNotStartedResourceData?.id
  );
  console.log(
    "selectedVendor outside",
    formMethods.getValues("selectedVendor")
  );
  console.log("businessName", formMethods.getValues("businessName"));
  console.log("businessNumber", formMethods.getValues("businessNumber"));
  console.log("selectedResourceSlipList--", selectedResourceSlipList);

  const getCardStyles = (index: any) => {
    return {
      borderLeft:
        selectedCard === index ? "6px solid #0057db" : "2px solid transparent",
      cursor: "pointer",
    };
  };
  const getSelectedResourceSlipInCard = (index: any) => {
    let selectedItem =
      selectedCard === index ? selectedResourceSlip : "create-new";
    return selectedItem;
  };
  const getSelectedResourceSlipsListInCard = (index: any) => {
    let selectedList =
      selectedCard === index
        ? selectedResourceSlipList
        : [{ label: "Create New", value: "create-new" }];
    return selectedList;
  };

  const filteredResourceList =
    resourceListWithoutCreateNew &&
    resourceListWithoutCreateNew.filter((item: any) =>
      item.label.toLowerCase().includes(itemSearch.toLowerCase())
    );

  const onCreateNewButtonClicked = () => {
    setSnackbarData({
      open: true,
      message: "Please load a payee for RL3 Slip",
      severity: "error",
    });
    formMethods.reset();
    formMethods.setValue("comparison", [{ amount: 0, code: "", label: "" }]);
    setSlipOtherInformation([])
    //otherinformation will update selected items
    setSelectedOtherInformation(false);
    setSelectedResourceSlipList([{ label: "Create New", value: "create-new" }]);
    setSelectedResourceSlip("create-new");
    let path = `/taxslip/rl3/setup`;
    navigate(path);
    setRL3SlipRecord(undefined);
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 1000);
    setSelectedResource("create-new");
    setSelectedOtherInformation(false);
    setSelectedCard(null);
  };
  const onDividerButtonClickHandler = () => {
    setSidePanelsVisibility((prevButtonState: any) => !prevButtonState);
  };
  const onIndividualSelectionChangeHandler = (value: boolean) => {
    formMethods.setValue("secondRecipientName", undefined);
    if (value === true) {
      (async () => {
        try {
          setIsLoadingAdjustedData(true);
          const response: any =
            await RL3SlipService.getRL3RecipientDDLByRecipientType(
              clientId,
              T5_RECIPIENT_RESOURCE_LITERAL,
              taxYear,
              10
            );
          console.log("responseData-----------------", response);
          setSecondRecipientDDL(response);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingAdjustedData(false);
        }
      })();
    } else if (value === false) {
      (async () => {
        try {
          setIsLoadingAdjustedData(true);
          const response: any =
            await RL3SlipService.getRL3RecipientDDLByRecipientType(
              clientId,
              T5_RECIPIENT_RESOURCE_LITERAL,
              taxYear,
              30
            );
          console.log("responseData-----------------", response);
          setSecondRecipientDDL(response);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingAdjustedData(false);
        }
      })();
    }
  };
  useEffect(() => {
    const individual = formMethods.getValues("individual");
    console.log("individual--", individual);
    if (individual === false) {
      (async () => {
        try {
          setIsLoadingAdjustedData(true);
          const response: any =
            await RL3SlipService.getRL3RecipientDDLByRecipientType(
              clientId,
              T5_RECIPIENT_RESOURCE_LITERAL,
              taxYear,
              30
            );
          console.log(
            "responseData-----------------secondRecipientDDL",
            response
          );
          setSecondRecipientDDL(response);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingAdjustedData(false);
        }
      })();
      console.log("secondRecipientDDL", secondRecipientDDL);
    } else if (individual === true) {
      (async () => {
        try {
          setIsLoadingAdjustedData(true);
          const response: any =
            await RL3SlipService.getRL3RecipientDDLByRecipientType(
              clientId,
              T5_RECIPIENT_RESOURCE_LITERAL,
              taxYear,
              10
            );
          console.log("responseData-----------------", response);
          setSecondRecipientDDL(response);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingAdjustedData(false);
        }
      })();
    }
  }, [formMethods]);

  let foreignCurrencyDDL = [
    {
      label: "AFG",
      value: "AFG",
      name: "Afghanistan",
    },
    {
      label: "CAN",
      value: "CAN",
      name: "Canada",
    },
    {
      label: "PAK",
      value: "PAK",
      name: "Pakistan",
    },
  ];

  useEffect(() => {
    const recipientType = formMethods.getValues("recipientType");
    console.log("recipientType", recipientType);
    setRecipientTypeValue(recipientType);
  }, [formMethods]);
  console.log("formMethods.getValues('recipientType')", recipientTypeValue);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: id ? id : "",
        entityId2: "",
        entityId3: "",
        screenName: RL3_SETUP_SCREEN_SLUG,
        companyName: clientName,
        activityType: "activity_type=create&activity_type=updated&activity_type=print&activity_type=delete",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  const [RL3OtherInformationList, setRL3OtherInformationList] =
    useState<any>(undefined);
  useEffect(() => {
    //Load OtherInformationList
    setIsLoadingAdjustedData(true);
    setTimeout(() => {
      setIsLoadingAdjustedData(false);
    }, 2000);
    const fetchData = async () => {
      try {
        const response: any = await RL3OtherInformationService.getRL3OI();
        setRL3OtherInformationList(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          pb: "5rem",
          "& .MuiAppBar-root": { zIndex: 2, top: "65px" },
          "& .p-datepicker": { zIndex: 2 },
          paddingTop: "2rem",
        }}
      >
        <BackdropCircularProgress
          open={
            isSubmitting ||
            isLoadingData ||
            isLoadingAdjustedData ||
            isDeleting ||
            isLoadingNotStartedSlipId ||
            isLoadingDataa ||
            isLoadingForeignCurrencyDDL ||
            isLoadingActivityLogData ||
            isLoadingGlobalVariables
          }
          testId={testId}
        />
        <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={deleteRL3SlipField}
          cancelActionHandler={onCloseHandler}
          testId="T4-confirmation-dialog"
        />
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          {sidePanelsVisibility && (
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Box sx={{ margin: "0" }}>
                <InputText
                  value={itemSearch}
                  onChange={(e) => setItemSearch(e.target.value)}
                  placeholder={"Search by name..."}
                  className="w-full search-field"
                />
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    sx={{ right: 0, margin: "10px 0px", fontSize: "12px" }}
                    onClick={onCreateNewButtonClicked}
                  >
                    + Create New
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  // display: "flex",
                  height: "78rem",
                  // flexDirection: "column",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#ccc",
                    borderRadius: "3px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#aaa",
                  },
                }}
              >
                {resourceListWithoutCreateNew &&
                  filteredResourceList.map((item: any, index: any) => {
                    return (
                      <Card
                        sx={{
                          padding: 0,
                          borderRadius: "8px",
                          marginBottom: "1rem",
                        }}
                        key={index}
                        onClick={() =>
                          onResourceSelectionChangeHandler(item, item.value)
                        }
                        style={getCardStyles(item.value)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0.8rem",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: ".875rem" }}
                          >
                            {item.label}
                          </Typography>
                          <Avatar
                            shape="circle"
                            style={{
                              background: "#0057db",
                              color: "#fff",
                              borderRadius: "50%",
                              fontWeight: "bold",
                              fontSize: ".775rem",
                              padding: "2px",
                            }}
                          >
                            {item.label
                              .replace(/\s+/g, " ")
                              .split(" ")
                              .filter((word: string) => word.trim() !== "")
                              .map((word: string) => word[0].toUpperCase())
                              .slice(0, 2)
                              .join("")}
                          </Avatar>
                        </Box>
                        {selectedCard === item.value ? (
                          <>
                            <Divider className="m-0" />
                            <Box>
                              <Box sx={{ padding: "0.6rem" }}>
                                <Dropdown
                                  value={getSelectedResourceSlipInCard(
                                    item.value
                                  )}
                                  onChange={
                                    onResourceSlipSelectionChangeHandler
                                  }
                                  options={getSelectedResourceSlipsListInCard(
                                    item.value
                                  )}
                                  onClick={(e: any) => e.stopPropagation()}
                                  className="slip-dropdown"
                                />
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <></>
                        )}
                      </Card>
                    );
                  })}
              </Box>
            </Grid>
          )}
          <Divider
            layout="vertical"
            align="center"
            className="verticle-divider"
            style={{ zIndex: 0 }}
          >
            <Button
              variant="outlined"
              sx={{
                minWidth: "fit-content",
                padding: "20px 0",
                borderRadius: "7px",
              }}
              onClick={onDividerButtonClickHandler}
            >
              {sidePanelsVisibility ? (
                <i
                  className="pi pi-angle-double-left"
                  style={{ color: "#0057db" }}
                ></i>
              ) : (
                <i
                  className="pi pi-angle-double-right"
                  style={{ color: "#0057db" }}
                ></i>
              )}
            </Button>
          </Divider>
          <Grid
            item
            xs={sidePanelsVisibility ? 8 : 10}
            sm={sidePanelsVisibility ? 8 : 10.8}
            md={sidePanelsVisibility ? 9.4 : 11.2}
            lg={sidePanelsVisibility ? 9.5 : 11.6}
            xl={sidePanelsVisibility ? 9.7 : 11.7}
            sx={{ "& .MuiGrid-container": { marginTop: 0 } }}
          >
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <TwoColumnLayout
                  toolbar={
                    <RL3SlipSetupToolbar
                      onResetClicked={onResetClicked}
                      onDeleteClicked={onDeleteClicked}
                      onPrintClicked={onPrintClicked}
                      isSubmitting={isSubmitting}
                      formMethods={formMethods}
                    />
                  }
                  leftColumnContent={
                    <>
                      {!sidePanelsVisibility && (
                        <StandardPanel
                          title="Load Slips"
                          testId={`${testId}-load-slips-panel`}
                        >
                          <Grid
                            container
                            columnSpacing={2}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <EnhancedDropDownRHFWithLabel
                                label="Load RL3 Recipient"
                                selectName="selectedVendor"
                                items={selectedResourceList}
                                selectedItem={selectedResource}
                                onSelectionChangeHandler={
                                  onResourceSelectionChangeHandler
                                }
                                testId={`${testId}-selected-vendor`}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <EnhancedDropDownRHFWithLabel
                                label="Load RL3 Slip"
                                selectName="selectedSlip"
                                items={selectedResourceSlipList}
                                selectedItem={selectedResourceSlip}
                                onSelectionChangeHandler={
                                  onResourceSlipSelectionChangeHandler
                                }
                                testId={`${testId}-selected-slip`}
                              />
                            </Grid>
                          </Grid>
                        </StandardPanel>
                      )}
                      <StandardPanel
                        title="Slip Data"
                        testId={`${testId}-slip-data`}
                        topPadding={!sidePanelsVisibility ? 2 : 0}
                      >
                        {RL3OtherInformationList && (
                          <RL3SlipDataPanel
                            formMethods={formMethods}
                            testId={testId}
                            isLoading={false}
                            otherInformationList={RL3OtherInformationList}
                            selectedOtherInformation={
                              selectedOtherInformation &&
                              selectedOtherInformation
                            }
                            oiInEdit={oiInEdit}
                            // selectedProvinceOfEmployment={
                            //   selectedProvinceOfEmployment
                            // }
                            foreignCurrencyDDL={
                              foreignCurrencyDDList &&
                              foreignCurrencyDDListItems
                            }
                            globalVariablesData={
                              globalVariablesData && globalVariablesData
                            }
                          />
                        )}
                      </StandardPanel>
                    </>
                  }
                  rightColumnContent={
                    <>
                      <StandardPanel
                        title="T5 Recipient Info"
                        testId={`${testId}-Vendor-info`}
                      >
                        <VendorInfoSetupFieldsPanel
                          testId={testId}
                          formMethods={formMethods}
                          vendorDDL={secondRecipientDDL && secondRecipientDDL}
                          individualChangeHandler={
                            onIndividualSelectionChangeHandler
                          }
                          isLoading={(value: boolean) =>
                            setIsLoadingAdjustedData(value)
                          }
                          resourceDDLWithRecipientType={(value: any) =>
                            setSelectedResourceList(value)
                          }
                          resourceDDLWithoutCreateNewWithRecipientType={(
                            value: any
                          ) => setResourceListWithoutCreateNew(sortData(value))}
                          globalVariablesData={
                            globalVariablesData && globalVariablesData
                          }
                        />
                      </StandardPanel>
                      <StandardPanel
                        title="Address"
                        testId={`${testId}-Vendor-address-panel`}
                        topPadding={2}
                      >
                        <VendorAddressSetupFieldsPanel testId={testId} />
                      </StandardPanel>
                      <StandardPanel
                        title="Filing Data"
                        testId={`${testId}-filing-data-panel`}
                        topPadding={2}
                      >
                        <EnhancedDropDownRHFWithLabel
                          label="Slip Type"
                          selectName="slipType"
                          items={[
                            { label: "Original", value: 400 },
                            { label: "Amended", value: 200 },
                            { label: "Cancelled", value: 300 },
                            { label: "Additional", value: 100 },
                          ]}
                          selectedItem={400}
                          testId="slip-type"
                          labelPosition={LabelPosition.Left}
                          required
                          onSelectionChangeHandler={
                            onSlipTypeSelectionChangeHandler
                          }
                        />
                        <FilingDataSetupFieldsPanel testId={testId} />
                      </StandardPanel>
                    </>
                  }
                  testId="t4-slip-setup-form"
                />
              </form>
            </FormProvider>
          </Grid>
        </Grid>
        {/* invoice dialogu open if any transaction is unPaid */}
        <EnhancedDialog
          dialogTitle="Invoice(s) Due"
          key="dialog-box"
          testId="dialog"
          onCloseHandler={onCloseHandler}
          open={openInvoiceDialog}
          dialogContent={
            <InvoiceDialogPanel
              invoiceData={invoiceData}
              onUnPaidClickHandler={onUnPaidClickHandler}
              onClickInvoiceNoHandler={onClickInvoiceNoHandler}
            />
          }
          dialogAction={
            <Grid>
              <Button autoFocus variant="contained" onClick={onCloseHandler}>
                Cancel
              </Button>
            </Grid>
          }
        />
        <EnhancedSnackbar
          message={snackbarData.message}
          onCloseHandler={onSnackbarClosed}
          severity={snackbarData.severity}
          testId={`${testId}-snackbar`}
          open={snackbarData.open}
          autoHide={snackbarData.autoHide}
        />
      </Grid>
    </>
  );
};
