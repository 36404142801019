import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface RL1SummaryEnglishAxisPages2 {}
const RL1SummaryEnglishAxisPages2:React.FC<RL1SummaryEnglishAxisPages2> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'box28P2X'} 
                testId={'box28P2X'} 
                label={'Box 28'} 
                labelPosition={LabelPosition.Left}
                defaultValue={450}
            />
            <RHookFormTextFieldWithLabel 
                id={'box29P2X'} 
                testId={'box29P2X'} 
                label={'Box 29'} 
                labelPosition={LabelPosition.Left}
                defaultValue={345}
            />
            <RHookFormTextFieldWithLabel 
                id={'box30P2X'} 
                testId={'box30P2X'} 
                label={'Box 30'} 
                labelPosition={LabelPosition.Left}
                defaultValue={317}
            />
            <RHookFormTextFieldWithLabel 
                id={'box31P2X'} 
                testId={'box31P2X'} 
                label={'Box 31'} 
                labelPosition={LabelPosition.Left}
                defaultValue={171}
            />
            <RHookFormTextFieldWithLabel 
                id={'box32P2X'} 
                testId={'box32P2X'} 
                label={'Box 32'} 
                labelPosition={LabelPosition.Left}
                defaultValue={317}
            />
            <RHookFormTextFieldWithLabel 
                id={'box34P2X'} 
                testId={'box34P2X'} 
                label={'Box 34'} 
                labelPosition={LabelPosition.Left}
                defaultValue={317}
            />
            <RHookFormTextFieldWithLabel 
                id={'box36P2X'} 
                testId={'box36P2X'} 
                label={'Box 36'} 
                labelPosition={LabelPosition.Left}
                defaultValue={228}
            />
            <RHookFormTextFieldWithLabel 
                id={'box37P2X'} 
                testId={'box37P2X'} 
                label={'Box 37'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
            <RHookFormTextFieldWithLabel 
                id={'box38P2X'} 
                testId={'box38P2X'} 
                label={'Box 38'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
            <RHookFormTextFieldWithLabel 
                id={'box39P2X'} 
                testId={'box39P2X'} 
                label={'Box 39'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
            <RHookFormTextFieldWithLabel 
                id={'box40P2X'} 
                testId={'box40P2X'} 
                label={'Box 40'} 
                labelPosition={LabelPosition.Left}
                defaultValue={470}
            />
            <RHookFormTextFieldWithLabel 
                id={'box41NotEnteredCodeP2X'} 
                testId={'box41NotEnteredCodeP2X'} 
                label={'Box 41 Not Entered Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={285}
            />
            <RHookFormTextFieldWithLabel 
                id={'box41EnteredCodeP2X'} 
                testId={'box41EnteredCodeP2X'} 
                label={'Box 41 Entered Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={410}
            />
            <RHookFormTextFieldWithLabel 
                id={'contributionRelated1P2X'} 
                testId={'contributionRelated1P2X'} 
                label={'Contribution Related 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={410}
            />
            <RHookFormTextFieldWithLabel 
                id={'contributionRelated2P2X'} 
                testId={'contributionRelated2P2X'} 
                label={'Contribution Related 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={285}
            />
            <RHookFormTextFieldWithLabel 
                id={'box43P2X'} 
                testId={'box43P2X'} 
                label={'Box 43'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
            <RHookFormTextFieldWithLabel 
                id={'box50P2X'} 
                testId={'box50P2X'} 
                label={'Box 50'} 
                labelPosition={LabelPosition.Left}
                defaultValue={228}
            />
            <RHookFormTextFieldWithLabel 
                id={'box51P2X'} 
                testId={'box51P2X'} 
                label={'Box 51'} 
                labelPosition={LabelPosition.Left}
                defaultValue={410}
            />
            <RHookFormTextFieldWithLabel 
                id={'box52P2X'} 
                testId={'box52P2X'} 
                label={'Box 52'} 
                labelPosition={LabelPosition.Left}
                defaultValue={410}
            />
            <RHookFormTextFieldWithLabel 
                id={'wsdrfP2X'} 
                testId={'wsdrfP2X'} 
                label={'Wsdrf'} 
                labelPosition={LabelPosition.Left}
                defaultValue={410}
            />
            <RHookFormTextFieldWithLabel 
                id={'box53P2X'} 
                testId={'box53P2X'} 
                label={'Box 53'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
            <RHookFormTextFieldWithLabel 
                id={'box70P2X'} 
                testId={'box70P2X'} 
                label={'Box 70'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
            <RHookFormTextFieldWithLabel 
                id={'box71P2X'} 
                testId={'box71P2X'} 
                label={'Box 71'} 
                labelPosition={LabelPosition.Left}
                defaultValue={228}
            />
            <RHookFormTextFieldWithLabel 
                id={'box72P2X'} 
                testId={'box72P2X'} 
                label={'Box 72'} 
                labelPosition={LabelPosition.Left}
                defaultValue={480}
            />
            <RHookFormTextFieldWithLabel 
                id={'box75P2X'} 
                testId={'box75P2X'} 
                label={'Box 75'} 
                labelPosition={LabelPosition.Left}
                defaultValue={554}
            />
            <RHookFormTextFieldWithLabel 
                id={'box76P2X'} 
                testId={'box76P2X'} 
                label={'Box 76'} 
                labelPosition={LabelPosition.Left}
                defaultValue={554}
            />
            <RHookFormTextFieldWithLabel 
                id={'box77P2X'} 
                testId={'box77P2X'} 
                label={'Box 77'} 
                labelPosition={LabelPosition.Left}
                defaultValue={554}
            />
            <RHookFormTextFieldWithLabel 
                id={'box78P2X'} 
                testId={'box78P2X'} 
                label={'Box 78'} 
                labelPosition={LabelPosition.Left}
                defaultValue={554}
            />
            <RHookFormTextFieldWithLabel 
                id={'box79P2X'} 
                testId={'box79P2X'} 
                label={'Box 79'} 
                labelPosition={LabelPosition.Left}
                defaultValue={554}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'box28P2Y'} 
                testId={'box28P2Y'} 
                label={'Box 28'} 
                labelPosition={LabelPosition.Left}
                defaultValue={737}
            />
            <RHookFormTextFieldWithLabel 
                id={'box29P2Y'} 
                testId={'box29P2Y'} 
                label={'Box 29'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box30P2Y'} 
                testId={'box30P2Y'} 
                label={'Box 30'} 
                labelPosition={LabelPosition.Left}
                defaultValue={688}
            />
            <RHookFormTextFieldWithLabel 
                id={'box31P2Y'} 
                testId={'box31P2Y'} 
                label={'Box 31'} 
                labelPosition={LabelPosition.Left}
                defaultValue={652}
            />
            <RHookFormTextFieldWithLabel 
                id={'box32P2Y'} 
                testId={'box32P2Y'} 
                label={'Box 32'} 
                labelPosition={LabelPosition.Left}
                defaultValue={652}
            />
            <RHookFormTextFieldWithLabel 
                id={'box34P2Y'} 
                testId={'box34P2Y'} 
                label={'Box 34'} 
                labelPosition={LabelPosition.Left}
                defaultValue={638}
            />
            <RHookFormTextFieldWithLabel 
                id={'box36P2Y'} 
                testId={'box36P2Y'} 
                label={'Box 36'} 
                labelPosition={LabelPosition.Left}
                defaultValue={627}
            />
            <RHookFormTextFieldWithLabel 
                id={'box37P2Y'} 
                testId={'box37P2Y'} 
                label={'Box 37'} 
                labelPosition={LabelPosition.Left}
                defaultValue={609}
            />
            <RHookFormTextFieldWithLabel 
                id={'box38P2Y'} 
                testId={'box38P2Y'} 
                label={'Box 38'} 
                labelPosition={LabelPosition.Left}
                defaultValue={581}
            />
            <RHookFormTextFieldWithLabel 
                id={'box39P2Y'} 
                testId={'box39P2Y'} 
                label={'Box 39'} 
                labelPosition={LabelPosition.Left}
                defaultValue={660}
            />
            <RHookFormTextFieldWithLabel 
                id={'box40P2Y'} 
                testId={'box40P2Y'} 
                label={'Box 40'} 
                labelPosition={LabelPosition.Left}
                defaultValue={515}
            />
            <RHookFormTextFieldWithLabel 
                id={'box41NotEnteredCodeP2Y'} 
                testId={'box41NotEnteredCodeP2Y'} 
                label={'Box 41 Not Entered Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={478}
            />
            <RHookFormTextFieldWithLabel 
                id={'box41EnteredCodeP2Y'} 
                testId={'box41EnteredCodeP2Y'} 
                label={'Box 41 Entered Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={478}
            />
            <RHookFormTextFieldWithLabel 
                id={'contributionRelated1P2Y'} 
                testId={'contributionRelated1P2Y'} 
                label={'Contribution Related 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={433}
            />
            <RHookFormTextFieldWithLabel 
                id={'contributionRelated2P2Y'} 
                testId={'contributionRelated2P2Y'} 
                label={'Contribution Related 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={433}
            />
            <RHookFormTextFieldWithLabel 
                id={'box43P2Y'} 
                testId={'box43P2Y'} 
                label={'Box 43'} 
                labelPosition={LabelPosition.Left}
                defaultValue={433}
            />
            <RHookFormTextFieldWithLabel 
                id={'box50P2Y'} 
                testId={'box50P2Y'} 
                label={'Box 50'} 
                labelPosition={LabelPosition.Left}
                defaultValue={344}
            />
            <RHookFormTextFieldWithLabel 
                id={'box51P2Y'} 
                testId={'box51P2Y'} 
                label={'Box 51'} 
                labelPosition={LabelPosition.Left}
                defaultValue={344}
            />
            <RHookFormTextFieldWithLabel 
                id={'box52P2Y'} 
                testId={'box52P2Y'} 
                label={'Box 52'} 
                labelPosition={LabelPosition.Left}
                defaultValue={322}
            />
            <RHookFormTextFieldWithLabel 
                id={'wsdrfP2Y'} 
                testId={'wsdrfP2Y'} 
                label={'Wsdrf'} 
                labelPosition={LabelPosition.Left}
                defaultValue={296}
            />
            <RHookFormTextFieldWithLabel 
                id={'box53P2Y'} 
                testId={'box53P2Y'} 
                label={'Box 53'} 
                labelPosition={LabelPosition.Left}
                defaultValue={296}
            />
            <RHookFormTextFieldWithLabel 
                id={'box70P2Y'} 
                testId={'box70P2Y'} 
                label={'Box 70'} 
                labelPosition={LabelPosition.Left}
                defaultValue={270}
            />
            <RHookFormTextFieldWithLabel 
                id={'box71P2Y'} 
                testId={'box71P2Y'} 
                label={'Box 71'} 
                labelPosition={LabelPosition.Left}
                defaultValue={252}
            />
            <RHookFormTextFieldWithLabel 
                id={'box72P2Y'} 
                testId={'box72P2Y'} 
                label={'Box 72'} 
                labelPosition={LabelPosition.Left}
                defaultValue={252}
            />
            <RHookFormTextFieldWithLabel 
                id={'box75P2Y'} 
                testId={'box75P2Y'} 
                label={'Box 75'} 
                labelPosition={LabelPosition.Left}
                defaultValue={135}
            />
            <RHookFormTextFieldWithLabel 
                id={'box76P2Y'} 
                testId={'box76P2Y'} 
                label={'Box 76'} 
                labelPosition={LabelPosition.Left}
                defaultValue={112}
            />
            <RHookFormTextFieldWithLabel 
                id={'box77P2Y'} 
                testId={'box77P2Y'} 
                label={'Box 77'} 
                labelPosition={LabelPosition.Left}
                defaultValue={110}
            />
            <RHookFormTextFieldWithLabel 
                id={'box78P2Y'} 
                testId={'box78P2Y'} 
                label={'Box 78'} 
                labelPosition={LabelPosition.Left}
                defaultValue={97}
            />
            <RHookFormTextFieldWithLabel 
                id={'box79P2Y'} 
                testId={'box79P2Y'} 
                label={'Box 79'} 
                labelPosition={LabelPosition.Left}
                defaultValue={75}
            />
        </Grid>
    </Grid>
  )
}

export default RL1SummaryEnglishAxisPages2