import { Box, Grid, Typography, Tooltip } from '@mui/material'
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VisibilityIcon from '@mui/icons-material/Visibility';
interface ISummariesPanel {
    formMethods:any;

    t4SummaryPdfFileName:string;
    t4SummaryPdfSavedFile:any;
    onT4SummaryOpenFileClicked:()=>void;

    t4aSummaryPdfFileName:string;
    t4aSummaryPdfSavedFile:any;
    onT4aSummaryOpenFileClicked:()=>void;

    rl1SummaryEnglishPdfFileName:string;
    rl1SummaryEnglishPdfSavedFile:any;
    onRL1SummaryEnglishOpenFileClicked:()=>void;

    rl1SummaryFrenchPdfFileName:string;
    rl1SummaryFrenchPdfSavedFile:any;
    onRL1SummaryFrenchOpenFileClicked:()=>void;

    t5SummaryPdfFileName:string;
    t5SummaryPdfSavedFile:any;
    onT5SummaryOpenFileClicked:()=>void;
    
    rl3SummaryPdfFileName:string;
    rl3SummaryPdfSavedFile:any;
    onRL3SummaryOpenFileClicked:()=>void;

    t3SummaryPdfFileName:string;
    t3SummaryPdfSavedFile:any;
    onT3SummaryOpenFileClicked:()=>void;
    
    rl16SummaryPdfFileName:string;
    rl16SummaryPdfSavedFile:any;
    onRL16SummaryOpenFileClicked:()=>void;
}

const useStyles = makeStyles(() =>
    createStyles({
      titleStyle: {
        fontSize: ".9rem",
        color: "#000",
        padding: "16px 16px 16px 26px",
        fontWeight: "bold",
      },
      line: {
        borderColor: "rgba(0,0,0,.12)",
        border: "0",
        borderTop: "1px solid rgba(0,0,0,.1)",
        margin: 0,
      },
      subTitle: {
        color: "rgba(0,0,0,.6)",
        fontSize: ".875rem",
        // p: "12px",
      },
    })
  );

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const SummariesPanel:React.FC<ISummariesPanel> = ({
    formMethods,
    t4SummaryPdfFileName,
    t4SummaryPdfSavedFile,
    onT4SummaryOpenFileClicked,
    t4aSummaryPdfFileName,
    t4aSummaryPdfSavedFile,
    onT4aSummaryOpenFileClicked,
    rl1SummaryEnglishPdfFileName,
    rl1SummaryEnglishPdfSavedFile,
    onRL1SummaryEnglishOpenFileClicked,
    rl1SummaryFrenchPdfFileName,
    rl1SummaryFrenchPdfSavedFile,
    onRL1SummaryFrenchOpenFileClicked,
    t5SummaryPdfFileName,
    t5SummaryPdfSavedFile,
    onT5SummaryOpenFileClicked,
    rl3SummaryPdfFileName,
    rl3SummaryPdfSavedFile,
    onRL3SummaryOpenFileClicked,
    t3SummaryPdfFileName,
    t3SummaryPdfSavedFile,
    onT3SummaryOpenFileClicked,
    rl16SummaryPdfFileName,
    rl16SummaryPdfSavedFile,
    onRL16SummaryOpenFileClicked,
}) => {
    const classes = useStyles();
    const [t4SummaryFileName, setT4SummaryFileName] = useState<string>('');
    const [t4SummaryPdfSavedPdfFile, setT4SummaryPdfSavedFile] = useState(t4SummaryPdfSavedFile); 
    useEffect(()=>{
        setT4SummaryPdfSavedFile(t4SummaryPdfSavedFile)
        if(t4SummaryPdfSavedFile === false){
            setT4SummaryFileName('Choose a file')
        }
    },[t4SummaryPdfSavedFile])  
    const handleT4SummaryFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setT4SummaryFileName(file.name);
        setT4SummaryPdfSavedFile(false)
      }
    };

    const [t4aSummaryFileName, setT4ASummaryFileName] = useState<string>('');
    const [t4aSummaryPdfSavedPdfFile, setT4ASummaryPdfSavedFile] = useState(t4aSummaryPdfSavedFile); 
    useEffect(()=>{
        setT4ASummaryPdfSavedFile(t4aSummaryPdfSavedFile)
        if(t4aSummaryPdfSavedFile === false){
            setT4ASummaryFileName('Choose a file')
        }
    },[t4aSummaryPdfSavedFile])  
    const handleT4ASummaryFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setT4ASummaryFileName(file.name);
        setT4ASummaryPdfSavedFile(false)
      }
    };

    const [rl1SummaryEnglishFileName, setRL1SummaryEnglishFileName] = useState<string>('');
    const [rl1SummaryEnglishPdfSavedPdfFile, setRL1SummaryEnglishPdfSavedFile] = useState(rl1SummaryEnglishPdfSavedFile); 
    useEffect(()=>{
        setRL1SummaryEnglishPdfSavedFile(rl1SummaryEnglishPdfSavedFile)
        if(rl1SummaryEnglishPdfSavedFile === false){
            setRL1SummaryEnglishFileName('Choose a file')
        }
    },[rl1SummaryEnglishPdfSavedFile])  
    const handleRL1SummaryEnglishFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setRL1SummaryEnglishFileName(file.name);
        setRL1SummaryEnglishPdfSavedFile(false)
      }
    };

    const [rl1SummaryFrenchFileName, setRL1SummaryFrenchFileName] = useState<string>('');
    const [rl1SummaryFrenchPdfSavedPdfFile, setRL1SummaryFrenchPdfSavedFile] = useState(rl1SummaryFrenchPdfSavedFile); 
    useEffect(()=>{
        setRL1SummaryFrenchPdfSavedFile(rl1SummaryFrenchPdfSavedFile)
        if(rl1SummaryFrenchPdfSavedFile === false){
            setRL1SummaryFrenchFileName('Choose a file')
        }
    },[rl1SummaryFrenchPdfSavedFile])  
    const handleRL1SummaryFrenchFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setRL1SummaryFrenchFileName(file.name);
        setRL1SummaryFrenchPdfSavedFile(false)
      }
    };

    const [t5SummaryFileName, setT5SummaryFileName] = useState<string>('');
    const [t5SummaryPdfSavedPdfFile, setT5SummaryPdfSavedFile] = useState(t5SummaryPdfSavedFile); 
    useEffect(()=>{
        setT5SummaryPdfSavedFile(t5SummaryPdfSavedFile)
        if(t5SummaryPdfSavedFile === false){
            setT5SummaryFileName('Choose a file')
        }
    },[t5SummaryPdfSavedFile])  
    const handleT5SummaryFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setT5SummaryFileName(file.name);
        setT5SummaryPdfSavedFile(false)
      }
    };

    const [rl3SummaryFileName, setRL3SummaryFileName] = useState<string>('');
    const [rl3SummaryPdfSavedPdfFile, setRL3SummaryPdfSavedFile] = useState(rl3SummaryPdfSavedFile); 
    useEffect(()=>{
        setRL3SummaryPdfSavedFile(rl3SummaryPdfSavedFile)
        if(rl3SummaryPdfSavedFile === false){
            setRL3SummaryFileName('Choose a file')
        }
    },[rl3SummaryPdfSavedFile])  
    const handleRL3SummaryFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setRL3SummaryFileName(file.name);
        setRL3SummaryPdfSavedFile(false)
      }
    };

    const [t3SummaryFileName, setT3SummaryFileName] = useState<string>('');
    const [t3SummaryPdfSavedPdfFile, setT3SummaryPdfSavedFile] = useState(t3SummaryPdfSavedFile); 
    useEffect(()=>{
        setT3SummaryPdfSavedFile(t3SummaryPdfSavedFile)
        if(t3SummaryPdfSavedFile === false){
            setT3SummaryFileName('Choose a file')
        }
    },[t3SummaryPdfSavedFile])  
    const handleT3SummaryFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setT3SummaryFileName(file.name);
        setT3SummaryPdfSavedFile(false)
      }
    };

    const [rl16SummaryFileName, setRL16SummaryFileName] = useState<string>('');
    const [rl16SummaryPdfSavedPdfFile, setRL16SummaryPdfSavedFile] = useState(rl16SummaryPdfSavedFile); 
    useEffect(()=>{
        setRL16SummaryPdfSavedFile(rl16SummaryPdfSavedFile)
        if(rl16SummaryPdfSavedFile === false){
            setRL16SummaryFileName('Choose a file')
        }
    },[rl16SummaryPdfSavedFile])  
    const handleRL16SummaryFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setRL16SummaryFileName(file.name);
        setRL16SummaryPdfSavedFile(false)
      }
    };
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{alignItems:"center"}}>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                T4 Summary PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {t4SummaryPdfSavedPdfFile &&
            // <Button
            //     variant="contained"
            //     sx={{padding:"0px 7px 0px 10px",fontSize:"small",marginRight:"5px"}}
            //     onClick={onT4SummaryOpenFileClicked}
            // >Open Saved File</Button>
            <Tooltip title={'Open saved file.'} placement={'top'}>
              <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
              onClick={onT4SummaryOpenFileClicked}/>
            </Tooltip>
            }
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='t4SummaryPdf'
                    {...formMethods.register("t4SummaryPdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='t4SummaryPdf' type="file" accept=".pdf" {...formMethods.register("t4SummaryPdf")}
                    onChange={handleT4SummaryFileChange}/>
                </Button>
                <label
                    htmlFor="t4SummaryPdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {t4SummaryPdfSavedPdfFile && t4SummaryPdfFileName !== '' ? t4SummaryPdfFileName : t4SummaryFileName !== '' ? t4SummaryFileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                T4A Summary PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {t4aSummaryPdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onT4aSummaryOpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='t4aSummaryPdf'
                    {...formMethods.register("t4aSummaryPdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='t4aSummaryPdf' type="file" accept=".pdf" {...formMethods.register("t4aSummaryPdf")}
                    onChange={handleT4ASummaryFileChange}/>
                </Button>
                <label
                    htmlFor="t4aSummaryPdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {t4aSummaryPdfSavedPdfFile && t4aSummaryPdfFileName !== '' ? t4aSummaryPdfFileName : t4aSummaryFileName !== '' ? t4aSummaryFileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                RL-1 Summary English PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {rl1SummaryEnglishPdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onRL1SummaryEnglishOpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='rl1SummaryEnglishPdf'
                    {...formMethods.register("rl1SummaryEnglishPdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='rl1SummaryEnglishPdf' type="file" accept=".pdf" {...formMethods.register("rl1SummaryEnglishPdf")}
                    onChange={handleRL1SummaryEnglishFileChange}/>
                </Button>
                <label
                    htmlFor="rl1SummaryEnglishPdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {rl1SummaryEnglishPdfSavedPdfFile && rl1SummaryEnglishPdfFileName !== '' ? rl1SummaryEnglishPdfFileName : rl1SummaryEnglishFileName !== '' ? rl1SummaryEnglishFileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                RL-1 Summary French PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {rl1SummaryFrenchPdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onRL1SummaryFrenchOpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='rl1SummaryFrenchPdf'
                    {...formMethods.register("rl1SummaryFrenchPdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='rl1SummaryFrenchPdf' type="file" accept=".pdf" {...formMethods.register("rl1SummaryFrenchPdf")}
                    onChange={handleRL1SummaryFrenchFileChange}/>
                </Button>
                <label
                    htmlFor="rl1SummaryFrenchPdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {rl1SummaryFrenchPdfSavedPdfFile && rl1SummaryFrenchPdfFileName !== '' ? rl1SummaryFrenchPdfFileName : rl1SummaryFrenchFileName !== '' ? rl1SummaryFrenchFileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                T5 Summary PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {t5SummaryPdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onT5SummaryOpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='t5SummaryPdf'
                    {...formMethods.register("t5SummaryPdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='t5SummaryPdf' type="file" accept=".pdf" {...formMethods.register("t5SummaryPdf")}
                    onChange={handleT5SummaryFileChange}/>
                </Button>
                <label
                    htmlFor="t5SummaryPdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {t5SummaryPdfSavedPdfFile && t5SummaryPdfFileName !== '' ? t5SummaryPdfFileName : t5SummaryFileName !== '' ? t5SummaryFileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                RL-3 Summary PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {rl3SummaryPdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onRL3SummaryOpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='rl3SummaryPdf'
                    {...formMethods.register("rl3SummaryPdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='rl3SummaryPdf' type="file" accept=".pdf" {...formMethods.register("rl3SummaryPdf")}
                    onChange={handleRL3SummaryFileChange}/>
                </Button>
                <label
                    htmlFor="rl3SummaryPdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {rl3SummaryPdfSavedPdfFile && rl3SummaryPdfFileName !== '' ? rl3SummaryPdfFileName : rl3SummaryFileName !== '' ? rl3SummaryFileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                T3 Summary PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {t3SummaryPdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onT3SummaryOpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='t3SummaryPdf'
                    {...formMethods.register("t3SummaryPdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='t3SummaryPdf' type="file" accept=".pdf" {...formMethods.register("t3SummaryPdf")}
                    onChange={handleT3SummaryFileChange}/>
                </Button>
                <label
                    htmlFor="t3SummaryPdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {t3SummaryPdfSavedPdfFile && t3SummaryPdfFileName !== '' ? t3SummaryPdfFileName : t3SummaryFileName !== '' ? t3SummaryFileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                RL-16 Summary PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {rl16SummaryPdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onRL16SummaryOpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='rl16SummaryPdf'
                    {...formMethods.register("rl16SummaryPdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='rl16SummaryPdf' type="file" accept=".pdf" {...formMethods.register("rl16SummaryPdf")}
                    onChange={handleRL16SummaryFileChange}/>
                </Button>
                <label
                    htmlFor="rl16SummaryPdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {rl16SummaryPdfSavedPdfFile && rl16SummaryPdfFileName !== '' ? rl16SummaryPdfFileName : rl16SummaryFileName !== '' ? rl16SummaryFileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>

    </Grid>
  )
}

export default SummariesPanel