import { RL1SlipSettingsSetup } from "components/RL1Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL1SlipSettingsSetupPage {}

export const RL1SlipSettingsSetupPage: FC<IRL1SlipSettingsSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="RL-1 Slip Settings"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl1/list" },
        { text: "RL-1", navigateTo: "/taxslip/rl1/list" },
        { text: "RL-1 Slips Settings", navigateTo: "/taxslip/rl1/setting" },
      ]}
      mainContents={<RL1SlipSettingsSetup testId="rl1-slip-settings-setup" />}
    />
  );
};
