import { RL3SlipList } from "components/RL3Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL3SlipListPagePage {}

export const RL3SlipListPage: FC<IRL3SlipListPagePage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="RL-3 Slip List"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl3/list" },
        { text: "RL-3", navigateTo: "/taxslip/rl3/list" },
        { text: "RL-3 Slips", navigateTo: "/taxslip/rl3/list" },
      ]}
      mainContents={<RL3SlipList />}
    />
  );
};
