import { DataType, FieldType } from "@websential/cosmic";
import IField from "@websential/cosmic/build/components/RHookFormGenerator/types/IField";
import * as yup from "yup";

export const AccountantSetupValidationSchema = yup.object({
  firstName: yup
    .string()
    .required("First Name is required")
    .min(1, "Minimum length 1 characters")
    .max(50, "Maximum length 50 characters"),
    lastName: yup
    .string()
    .required("Last Name is required")
    .min(1, "Minimum length 1 characters")
    .max(50, "Maximum length 50 characters"),
    accTitle: yup
    .string()
    .required("Accountant Title is required")
    .min(1, "Minimum length 1 characters")
    .max(100, "Maximum length 100 characters"),
    addressLine1: yup
    .string()
    .required("Address is required")
    .min(1, "Minimum length 1 characters")
    .max(40, "Maximum length 40 characters"),
    city: yup
    .string()
    .required("City is required")
    .min(1, "Minimum length 1 characters")
    .max(35, "Maximum length 35 characters"),
    province: yup.string().required("Province is required"),
    postalCode: yup
      .string()
      .required("PostCode is required")
      .max(10, "Post Code has at most 10 characters"),
    email: yup
    .string().email()
    .required("Email is required"),
})

const getMinMaxValidationMessage = (fieldLabel: string, minOrMaxValue: number | string, dataType: DataType, isMax: boolean = true): string => {
  const suffix = `${dataType !== DataType.Number && dataType !== DataType.Array && dataType !== DataType.Object ? ' characters' : ''}`;
  return `${fieldLabel} must be at ${isMax ? 'most' : 'least'} ${minOrMaxValue}${suffix}!`
}
export const checkSINValidity = (inputNumber: string): number => {
  let result = 0;
  if(inputNumber){
    for (let i = 0; i < inputNumber.length; i++) {
      const digit = parseInt(inputNumber[i], 10);

      // Multiply even digits by 2 and odd digits by 1
      let j = i+1
      const multiplier = j % 2 === 0 ? 2 : 1;
      const product = digit * multiplier;

      if(product.toString().length > 1){
        const sumOfDigits = product.toString().split('').reduce((sum, num) => sum + parseInt(num, 10), 0);
        result += sumOfDigits;
      } else {
        result += product;
      }
    }
  }
  return result;
};
type YupValidationSchema = yup.StringSchema | yup.NumberSchema<number | null | undefined> | yup.DateSchema | yup.ArraySchema<any> | null;

export const getFieldValidationSchema = (field: IField): YupValidationSchema => {
  const { dataType, isRequired, maxValue, minValue, fieldLabel, fieldType } = field;
  console.log("fieldType--",fieldType)
  const isDateField = fieldType === FieldType.Date;
  let fieldSchemaChain: YupValidationSchema | any = null;
  if (fieldType === FieldType.MultiSelectionDropDownList) {
      fieldSchemaChain = yup.array().of(yup.string());
  } else if (fieldType === FieldType.DropDownList) {
      fieldSchemaChain = yup.string()
  } else if (dataType === DataType.String && !isDateField) {
      fieldSchemaChain = yup.string()
      if (fieldType === FieldType.Email) {
          fieldSchemaChain = fieldSchemaChain.email("Please add a valid email")
      } else if (fieldType === FieldType.URL) {
          fieldSchemaChain = fieldSchemaChain.url("Please add a valid url")
      }
  } else if (dataType === DataType.Number) {
      fieldSchemaChain = yup
          .number()
          .typeError(`${fieldLabel} is required!`)
          .nullable()
          .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
  } else if (dataType === DataType.Boolean) {
      yup.boolean().oneOf([true, false]);
  } else if (fieldType === FieldType.Date) {
    if(isRequired){
      fieldSchemaChain = yup.date().typeError(`${fieldLabel} is required!`).nullable().required();
    }
  } else if (fieldType === FieldType.BusinessNumber) {
      fieldSchemaChain = yup.string().test(
          'is-valid-or-not',
          'Invalid value',
          function (value:any) {
            let maxLength = 13;
            const firstNineDigits: string = value && value.substring(0, 9);
            console.log("valueeeee-- bn",firstNineDigits)
            const output = checkSINValidity(firstNineDigits);
            console.log("output bn",output)
            
              if (value && value.length == maxLength && output % 10 === 0) {
                return true;
              } else {
                return this.createError({
                  message: 'Invalid Business Number',
                  path: this.path,
                });
              }
          }
        ).typeError(`${fieldLabel} isss required!`).required().nullable();
  }
  console.log("fieldSchemaChain",fieldSchemaChain)
  // we need to make sure that we have initialized it first
  if (fieldSchemaChain !== null) {
      if (maxValue && !isDateField) {
          fieldSchemaChain = fieldSchemaChain.max(Number(maxValue), getMinMaxValidationMessage(fieldLabel, maxValue, dataType))
      }
      if ((minValue || minValue === 0) && !isDateField) {
          fieldSchemaChain = fieldSchemaChain.min(Number(minValue), getMinMaxValidationMessage(fieldLabel, minValue, dataType, false))
      }
      if (isRequired) {
          fieldSchemaChain = fieldSchemaChain.required(`${fieldLabel} is required!`)
      }
      if (isRequired && fieldType === FieldType.BusinessNumber) {
          fieldSchemaChain = yup.string().test(
              'is-valid-or-not',
              'Invalid value',
              function (value:any) {
                let maxLength = 15;
                const firstNineDigits: string = value && value.substring(0, 9);
                const output = checkSINValidity(firstNineDigits);                  
                  if (value && value.length == maxLength && output % 10 === 0) {
                      return true;
                  } else {
                      return this.createError({
                          message: `Invalid ${fieldLabel}`,
                          path: this.path,
                      });
                  }
              }
            ).typeError(`${fieldLabel} is required!`).required().nullable();
      }
      if (isRequired && fieldType === FieldType.ReducedElAccountNumber) {
          fieldSchemaChain = yup.string().test(
              'is-valid-or-not',
              'Invalid value',
              function (value:any) {
                let maxLength = 15;
                const firstNineDigits: string = value && value.substring(0, 9);
                const output = checkSINValidity(firstNineDigits);                  
                  if (value && value.length == maxLength && output % 10 === 0) {
                      return true;
                  } else {
                      return this.createError({
                          message: `Invalid ${fieldLabel}`,
                          path: this.path,
                      });
                  }
              }
            ).typeError(`${fieldLabel} is required!`).required().nullable();
      }
      if (isRequired && fieldType === FieldType.SINNumber) {
          fieldSchemaChain = yup.string().test(
              'is-valid-or-not',
              'Invalid value',
              function (value:any) {
                  let sin = value && value.replace(/-/g, '')
                  const output = checkSINValidity(sin);
                  console.log("output",output)
                  let maxLength = 11;
            
                  if (value && value.charAt(2) === '*') {
                    maxLength = 7;
                    if (value && value.length == maxLength) {
                      return true;
                    } else {
                      return this.createError({
                        message: 'Invalid Social Insuarance Number',
                        path: this.path,
                      });
                    }
                  } else {
                    if (value && value.length == maxLength && output % 10 === 0) {
                      return true;
                    } else {
                      return this.createError({
                        message: 'Invalid Social Insuarance Number',
                        // message: 'Social Insuarance Number has at least 9 characters',
                        path: this.path,
                      });
                    }
                  }
                }
            ).typeError(`${fieldLabel} is required!`).required().nullable();
      }
  }
  return fieldSchemaChain;
}