import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { FC } from "react";

import { Grid } from "@mui/material";
import { ITest, RHookFormTextFieldWithLabel } from "@websential/cosmic";

interface IHSFPrimaryPanel extends ITest {}

export const HSFPrimaryPanel: FC<IHSFPrimaryPanel> = ({
  testId,
}) => {
  return (
    <Grid
      container
      columnSpacing="6"
      rowSpacing="2"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="HSF Primary (Minimum)"
          id="qhsfPrimaryMin"
          testId={`${testId}-hsf-primary-min`}
        />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="HSF Primary (Maximum)"
          id="qhsfPrimaryMax"
          testId={`${testId}-hsf-primary-maximum`}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="HSF Primary Value (1)"
            id="qhsfPrimaryValue1"
            testId={`${testId}-hsf-primary-value-1`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="HSF Primary Value (2)"
            id="qhsfPrimaryValue2"
            testId={`${testId}-gsf-priamry-value-2`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="HSF Primary Rate Min (%)"
            id="qhsfPrimaryRate1"
            testId={`${testId}-hsf-primary-rate-1`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="HSF Primary Rate Max (%)"
            id="qhsfPrimaryRate2"
            testId={`${testId}-hsf-primary-rate-2`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="HSF Primary Normalize"
            id="qhsfPrimaryNormalize"
            testId={`${testId}-hsf-primary-normalize`}
          />
        </FieldWrapper>
      </Grid>
    </Grid>
  );
};
