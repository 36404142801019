import { FC } from "react";

import {
  EnhancedIOSSwitchRHFWithLabel,
  ITest,
  LabelPosition,
  RHookFormDatePicker,
  RHookFormTextFieldWithLabel,
} from "@websential/cosmic";

import { FieldWrapper } from "../../../FieldWrapper/FieldWrapper";

interface IBillingProfileSetupFieldsPanel extends ITest {}

export const BillingProfileSetupFieldsPanel: FC<IBillingProfileSetupFieldsPanel> =
  ({ testId }) => {
    return (
      <>
        <FieldWrapper>
          <EnhancedIOSSwitchRHFWithLabel
            name="isGlobalProfile"
            checked={false}
            testId="is-global-profile"
            label="Global Profile"
            labelPosition={LabelPosition.Left}
          />
        </FieldWrapper>
        <RHookFormTextFieldWithLabel
          label="Profile Label"
          id="profileLabel"
          testId="profile-label"
          placeholder="Profile Label"
          labelPosition={LabelPosition.Left}
          required
        />
        <FieldWrapper>
          <RHookFormDatePicker
            name="effectiveDate"
            // onChange={onEffectiveDateChangeHandler}
            labelPosition={LabelPosition.Left}
            testId="effective-date"
            label="Effective Date"
            required
          />
        </FieldWrapper>
        <RHookFormDatePicker
          name="endDate"
          labelPosition={LabelPosition.Left}
          testId="expiry-date"
          label="Expiry Date"
        />
        <RHookFormTextFieldWithLabel
          label="T4 Slip Charges"
          id="t4flatSlipCharges"
          testId={`${testId}-t4-slip-charges`}
          placeholder="T4 Slip Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="T4 Summary Charges"
          id="t4flatSummaryCharges"
          testId={`${testId}-t4-summary-charges`}
          placeholder="T4 Summary Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="T4A Slip Charges"
          id="t4aflatSlipCharges"
          testId={`${testId}-t4a-slip-charges`}
          placeholder="T4A Slip Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="T4A Summary Charges"
          id="t4aflatSummaryCharges"
          testId={`${testId}-t4a-summary-charges`}
          placeholder="T4A Summary Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="RL-1 Slip Charges"
          id="rl1flatSlipCharges"
          testId={`${testId}-rl1-slip-charges`}
          placeholder="RL-1 Slip Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="RL-1 Summary Charges"
          id="rl1flatSummaryCharges"
          testId={`${testId}-rl1-summary-charges`}
          placeholder="RL-1 Summary Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="T5 Slip Charges"
          id="t5flatSlipCharges"
          testId={`${testId}-t5-slip-charges`}
          placeholder="T5 Slip Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="T5 Summary Charges"
          id="t5flatSummaryCharges"
          testId={`${testId}-t5-summary-charges`}
          placeholder="T5 Summary Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="RL-3 Slip Charges"
          id="rl3flatSlipCharges"
          testId={`${testId}-rl3-slip-charges`}
          placeholder="RL-3 Slip Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="RL-3 Summary Charges"
          id="rl3flatSummaryCharges"
          testId={`${testId}-rl3-summary-charges`}
          placeholder="RL-3 Summary Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="T3 Slip Charges"
          id="t3flatSlipCharges"
          testId={`${testId}-t3-slip-charges`}
          placeholder="T3 Slip Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="T3 Summary Charges"
          id="t3flatSummaryCharges"
          testId={`${testId}-t3-summary-charges`}
          placeholder="T3 Summary Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="RL-16 Slip Charges"
          id="rl16flatSlipCharges"
          testId={`${testId}-rl16-slip-charges`}
          placeholder="RL-16 Slip Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="RL-16 Summary Charges"
          id="rl16flatSummaryCharges"
          testId={`${testId}-rl16-summary-charges`}
          placeholder="RL-16 Summary Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="Base Charges"
          id="baseCharges"
          testId={`${testId}-base-charges`}
          placeholder="Base Charges"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="Discount %"
          id="discountPercentage"
          testId={`${testId}-discount-percentage`}
          placeholder="Discount %"
          labelPosition={LabelPosition.Left}
        />
        <RHookFormTextFieldWithLabel
          label="Discount $"
          id="discountAmount"
          testId={`${testId}-discount-amount`}
          placeholder="Discount $"
          labelPosition={LabelPosition.Left}
        />
      </>
    );
  };
