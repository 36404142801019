// import { toSnake } from "common/methods";
// import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
// import { IGetListRequest } from "models";
// import React, { useCallback, useEffect, useState } from "react";
// import { useMutation, useQuery, useQueryClient } from "react-query";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import { IAppState } from "store/reducers";
// import FileSaver from "file-saver";
// import EmailIcon from '@mui/icons-material/Email';
// import PrintIcon from "@mui/icons-material/Print";

// import { Box, Button, Grid, IconButton, useTheme } from "@mui/material";
// import { BackdropCircularProgress, DeleteConfirmationDialog, EmptyList, EnhancedDialog, EnhancedLinkButton, EnhancedSnackbar, IResponse, IServerError, IconTextButton, RouterLinkButton, SplitButton } from "@websential/cosmic";

// import { EnhancedDataGrid } from "../../EnhancedDataGrid";
// import {
//   GET_QUERY_KEY,
//   INITIAL_PAGE_SIZE,
//   INITIAL_SNACKBAR_DATA,
//   QUERY_OPTIONS,
//   ROWS_PER_PAGE_PER_OPTIONS,
//   SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
// } from "common/constants";
// import { T4SlipService } from "services/T4SlipService";
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// import { PrintingService } from "services/PrintingService";
// import { ReportsService } from "services/ReportsService";
// import { InvoiceDialogPanel } from "./InvoiceDialogPanel";
// import { setSelectedINVOICE } from "store/actions";
// import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";

// export interface IT4Slip {}
// const TAXSLIP_T4_LIST_QUERY_KEY = "t4-slips-list"

// const useT4Slips = (
//   clientId: string,
//   taxYear: number,
//   params: IGetListRequest
// ) =>
//   useQuery(
//     [GET_QUERY_KEY.LIST_T4_SLIPS, params],
//     (data) => {
//       return T4SlipService.getList(clientId, taxYear, params);
//     },
//     { refetchOnWindowFocus: false }
//   );

// export const T4SlipList: React.FC<IT4Slip> = () => {
//   const dispatch = useDispatch();
//   const { id } = useSelector((state: IAppState) => state.client);
//   const { resourceName } = useParams();
//   const [dataRoleName, setDataRoleName] = useState<string>("");
//   const clientId = useSelector((state: IAppState) => state.client.id);
//   const taxYear  = useSelector((state: IAppState) => state.taxYear.year);
//   const [t4SlipListUpdatedItems, setT4SlipListUpdatedItems] = useState<any>(undefined);
//   const [selectedRowsValues, setSelectedRowsValues] = useState([]);

//   const[openInvoiceDialog, setInvoiceDialogOpen] = useState(false)
//   const[invoiceData, setInvoiceData] = useState<any>([])
//   const[isLoadingData, setIsLoadingData] = useState(false)

//     // for delete row Purpose
//     const queryClient : any = useQueryClient();
//     const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);

//   useEffect(() => {
//     if (resourceName && resourceName.length > 0) {
//       console.log("resourceName>>>>>", resourceName);
//       setDataRoleName(resourceName);
//     }
//   }, [dataRoleName, resourceName]);

//   const theme = useTheme();
//   const navigate = useNavigate();

//   const [queryParams, setQueryParams] = useState<IGetListRequest>({
//     search_query: "",
//     order_by: "name1",
//     order_direction: "asc",
//     page: 1,
//     size: INITIAL_PAGE_SIZE,
//   });

//   const { isLoading, data } = useT4Slips(id, taxYear, queryParams);
//   const slipList: any = data;
//   console.log("@@@@@ => SlipList", slipList);

//   //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
//   // const isAxiosCallLoading = queryClient.isFetching([TAXSLIP_T4_LIST_QUERY_KEY]) > 0;
//   const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
//   useEffect(()=>{
//     if(isLoading){
//       setIsAxiosCallLoading(true)
//       setTimeout(()=>{
//         setIsAxiosCallLoading(false)
//       },1000)
//     } else {
//       setIsAxiosCallLoading(true)
//       setTimeout(()=>{
//         setIsAxiosCallLoading(false)
//       },1000)
//     }
//   },[isLoading])
//   console.log("@@@@@ => isAxiosCallLoading", isAxiosCallLoading);

//   useEffect(()=>{
//     if(slipList){
//       const updatedItemsResponse = slipList.items.map((item:any) => ({
//         ...item,
//         cppQppExempt: item.cppQppExempt ? "Yes" : "No",
//         eiExempt: item.eiExempt ? "Yes" : "No",
//         reducedEi: item.reducedEi ? "Yes" : "No"
//       }));
//       setT4SlipListUpdatedItems(updatedItemsResponse)
//   }
//   console.log("t4SlipListUpdatedItemst4SlipListUpdatedItems",t4SlipListUpdatedItems)
// },[slipList])

//   const { message, componentState } = useSelector(
//     (state: IAppState) => state.viewState
//   );
//   // console.log("masked = >", masked);
//   // const { accountantList } = useSelector(
//   //   (state: IAppState) => state.accountant
//   // );

//   const [openDialog, setDialogOpen] = useState(false);
//   const [selectedIds, setSelectedIds] = useState([]);
//   const [statusValue, setStatusValue] = useState("");
//   const [apiAction, setApiAction] = useState("");
//   const [emptyList, setEmptyList] = useState(false);
//   const [searchText, setSearchText] = useState({});
//   const [zeroClient, setzeroClient] = useState(true);

//     // for delete row
//     const [singleDelete, setSingleDelete] = useState<boolean>(false);
//     const [t4SlipId, setT4SlipId] = useState<number>(0);

//   const onRequestParamsChange = useCallback((requestParams) => {
//     console.log("##### - request params", requestParams);
//     let dispatchParams = {
//       search_query: requestParams.debouncedSearchQuery,
//       order_by: "name1",
//       order_direction: "asc",
//       page: requestParams.currentPage + 1,
//       size: requestParams.pageSize,
//     };
//     if (requestParams.sort) {
//       const [sortBy, sortDirection] = requestParams.sort.split(",");
//       dispatchParams.order_by = toSnake(sortBy);
//       dispatchParams.order_direction = sortDirection;
//     }

//     // setQueryParams(dispatchParams);
//     if(dispatchParams.search_query === ''){
//       setQueryParams(dispatchParams);
//     }
//     setSearchText(dispatchParams)
//   }, []);

//   const onSearchButtonClicked=()=>{
//     setQueryParams(searchText)
//     setzeroClient(false)
//   }
//   console.log("zeroClient--",zeroClient)

//   const onRowEditClicked = useCallback((params) => {
//     let path = `/taxslip/t4/setup/${params.row.id}`;
//     navigate(path);
//   }, []);

//   const onCloseHandler = () => {
//     setDialogOpen(false);
//     setInvoiceDialogOpen(false);
//   };

//   const callAction = () => {
//     if (apiAction === "delete") {
//       alert(`Delete row with ID: ${selectedIds} has been clicked!`);
//       // TODO api call for delete
//     } else if (apiAction === "updateStatus") {
//       alert(`action need to trigger : ${apiAction}`);
//       // TODO api call for update status
//     }
//     setDialogOpen(false);
//   };

//   // For Delete Row
//  const deleteT4SlipField = () => {
//   if (singleDelete) {
//     let payload = {
//       t4SlipId,
//       clientId,
//       taxYear
//     }
//     setSingleDelete(false);
//     deleteT4SlipById(payload);
//     setDialogOpen(false);
//   }
// };

// const onRowDeleteClicked = useCallback((params) => {
//   setDialogOpen(true);
//   setSingleDelete(true);
//   setApiAction("delete");
//   setT4SlipId(params.row.id);
//   console.log("T4SlipId in T4Slip List OnDeleteRowClicked function",params.row.id)
//   // alert(`Delete row with ID: ${params.row.id} has been clicked!`);
// }, []);

// const onSnackbarClosed = (
//   event: React.SyntheticEvent | Event,
//   reason?: string
// ) => {
//   if (reason === "clickaway") {
//     return;
//   }
//   setSnackbarData(INITIAL_SNACKBAR_DATA);
// };

// const { isLoading: isDeleting, mutate: deleteT4SlipById } = useMutation(
//   T4SlipService.delT4Slip,
//   {
//     onSuccess: (res: any | object | IServerError | IResponse) => {
//       console.log("resonce for Delete row",res)
//       if (res.message === "T4 slip has been deleted") {
//         // remove the cache of the Year fields since we just added a new field.
//         setSnackbarData({
//           open: true,
//           message: res.message ? res.message : "T4 slip has been deleted",
//           severity: "success",
//         });

//         return queryClient.invalidateQueries(
//           TAXSLIP_T4_LIST_QUERY_KEY
//           );
//         } else {
//           setSnackbarData({
//             open: true,
//             message: res.message,
//             severity: "error",
//           });
//         }
//       },
//       onError: (err:any) => {
//         console.log("resonce for Delete row",err)
//         // TODO: customize the toast message based on the response
//         setSnackbarData({
//           open: true,
//           message: err.message,
//         severity: "error",
//       });
//     },
//   }
// );

// // Bulk Delete
// const [bulkDelete, setBulkDelete] = useState<boolean>(false);
// const [bulkT4SlipsId, setBulkT4SlipsId] = useState<any>({});
// const [bulkT4SlipsDelete, setBulkT4SlipsDelete] = useState<boolean>(false);

// const { isLoading: isBulkDeleting, mutate: deleteBulkT4SlipsById } =
//     useMutation(T4SlipService.delBulkSlips, {
//       onSuccess: (res: any | string | IServerError | IResponse) => {
//         if (typeof res === "object") {
//           // remove the cache of the Year fields since we just added a new field.
//           setSnackbarData({
//             open: true,
//             message: res.message,
//             severity: "success",
//           });

//           setBulkT4SlipsDelete(false)
//           return queryClient.invalidateQueries(
//             TAXSLIP_T4_LIST_QUERY_KEY
//             );
//         } else {
//           setSnackbarData({
//             open: true,
//             message: "Something went wrong, try again later!",
//             severity: "error",
//           });
//         }
//         setBulkT4SlipsDelete(false)
//       },
//       onError: (err:any) => {
//         // TODO: customize the toast message based on the response
//         setSnackbarData({
//           open: true,
//           message: err.message,
//           severity: "error",
//         });
//         setBulkT4SlipsDelete(false)
//       },
//     });

//   // For Bulk Delete
//   const deleteBulkT4SlipsField = () => {
//     if (bulkDelete) {
//       setBulkDelete(false);
//       deleteBulkT4SlipsById(bulkT4SlipsId);
//       setDialogOpen(false);
//     }
//   };

// const savePdfAsFile = (xmlData: any, filename: any) => {
//   const blob = new Blob([xmlData], { type: 'pdf;charset=utf-8' });
//   FileSaver.saveAs(blob, filename);
// };

//   const onRowMultipleDeleteClicked = (params: any) => {
//     console.log("Params in onRowMultipleDeleteClicked ====== ", params);
//     let bulkDeletePayload = {
//       ids:{"slipIds":params},
//       clientId,
//       taxYear
//     }
//     setDialogOpen(true);
//     setBulkDelete(true);
//     setApiAction("delete");
//     setBulkT4SlipsId(bulkDeletePayload);
//     setBulkT4SlipsDelete(true);
//   };

//   const selectionToolbar = useCallback(
//     (selectedItems) => {
//       console.log("selectedItems",selectedItems)
//       console.log("selectedRowsValues",selectedRowsValues)
//       console.log("queryParams.size",queryParams.size)
//       setSelectedIds(selectedItems);
//       newData = selectedItems
//       if (selectedItems && selectedItems.length) {
//         return (
//           <Box sx={{ marginTop: theme.spacing(1),display:"flex",justifyContent:"center",alignItems:"center" }}>
//           {/* {queryParams.size === selectedItems.length ?
//             (<Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block", "& .MuiPopper-root":{zIndex:2}}}>
//             <SplitButton
//                 options={[
//                   "Print all",
//                   "Employee",
//                   "Employer",
//                   "CRA",
//                 ]}
//                 onClickHandler={onAllSlipsPrintClicked}
//                 testId="t4-print-button"
//                 icon="Print"
//               />
//           </Box>)
//           :(<></>)} */}
//             <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block", "& .MuiPopper-root":{zIndex:2}}}>
//               <SplitButton
//                   options={[
//                     "Employee",
//                     "Employer",
//                     "CRA",
//                   ]}
//                   onClickHandler={onAllSlipsPrintClicked}
//                   testId="t4-print-button"
//                   icon={<PrintIcon />}
//                   listItemsLabel={`Print All T4 \nSlips`}
//                 />
//             </Box>
//             <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block", "& .MuiPopper-root":{zIndex:2}}}>
//               <SplitButton
//                 options={[
//                   "Employee",
//                   "Employer",
//                   "CRA",
//                 ]}
//                 onClickHandler={onPrintClicked}
//                 testId="t4-print-button"
//                 icon={<PrintIcon />}
//                 listItemsLabel={`Print T4 \nSelected Slips`}
//               />
//             </Box>
//             <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
//               <Button variant="outlined" startIcon={<EmailIcon />} onClick={onEmailButtonClicked}>
//                 Email
//               </Button>
//             </Box>
//             <Button
//                 onClick={() => onRowMultipleDeleteClicked(selectedItems)}
//                 // sx={{marginBottom:"1rem"}}
//                 variant="contained"
//                 color="error"
//               >
//                 Delete
//             </Button>
//           </Box>
//         );
//       }
//       return (
//         <Box sx={{ marginTop: theme.spacing(1), display:"flex" }}>
//           <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
//             <EnhancedLinkButton text={"Import CSV"} icon={<ExitToAppIcon/>} navigateTo={"/taxslip/t4/import"} testId={'import-t4-slip-button'}/>
//           </Box>
//           <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block"}}>
//             <EnhancedLinkButton text={"Import XML"} icon={<ExitToAppIcon/>} navigateTo={"/taxslip/t4/import-xml"} testId={'import-t4-slip-button'}/>
//           </Box>

//           <RouterLinkButton to="/taxslip/t4/setup" text="Add T4 Slip" />
//         </Box>
//       );
//     },
//     [theme,selectedRowsValues]
//   );

//   let newData:any = []
//   const onPrintClicked = async (event:any): Promise<void> => {
//     const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
//     console.log("completedSlipsIds12",completedSlipsIds)
//     // Convert arrays to strings and compare
//     if(newData.length > 0){
//       const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
//       if (!areArraysEqual) {
//         setSnackbarData({
//           open: true,
//           message: "Please Selected T4 Completed Slips Only!",
//           severity: "error",
//         });
//       }else{
//         setIsLoadingData(true);
//         const maskedStatus:any = sessionStorage.getItem("maskEnabled")
//         if(maskedStatus === "true"){
//           setIsLoadingData(false);
//           setSnackbarData({
//             open: true,
//             message: "Please unmask your data.",
//             severity: "error",
//           });
//         } else {
//         try {
//           const response:any = await ReportsService.createInvoice(clientId,taxYear);
//           setIsLoadingData(false);
//           console.log("responseresponse",response)
//           if(response.message === "Slips not found for invoice."){
//             setIsLoadingData(true);
//             try {
//               const filingAuditResponse:any = await T4SlipService.getT4AuditFiling(clientId,taxYear);
//               console.log("filingAuditResponse",filingAuditResponse)
//               if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
//                 console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else if(filingAuditResponse && filingAuditResponse.t4_filling && filingAuditResponse.t4_filling.length > 0){
//                 console.log("filingAuditResponse.t4_filling",filingAuditResponse.t4_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else {
//                 const ids_list = newData.map((ids: any) => `t4_slip_ids=${ids}`).join('&');
//                 const pdfIds: any = ids_list.replace(/^&+|&+$/g, '');
//                 setIsLoadingData(true);
//                 try {
//                   const response: any = await PrintingService.createBulkT4PdfPrint(clientId, event, pdfIds, taxYear);
//                   console.log("response000",response)
//                   if(response.status === 400){
//                     setSnackbarData({
//                       open: true,
//                       message: response.data.message ? response.data.message : "Something went wrong.",
//                       // message: "Please Unmask sensitive data for PDF printing.",
//                       severity: "error",
//                     });
//                   }else{
//                     const filename = 'T4slip.pdf';
//                     savePdfAsFile(response, filename);
//                   }
//                   setIsLoadingData(false);
//                 } catch (err) {
//                   console.log("err",err)
//                 }
//               }
//               setIsLoadingData(false);
//             } catch(err:any) {
//               setIsLoadingData(false);
//               alert(err)
//             }
//           } else if(response && response.id !== null) {
//             setInvoiceData(response)
//             setInvoiceDialogOpen(true)
//           }else{
//             setSnackbarData({
//               open: true,
//               message: "Something went wrong.",
//               severity: "error",
//             });
//           }
//           setIsLoadingData(false);
//         } catch (err) {
//           console.log("err",err)
//           setIsLoadingData(false);
//         }
//       }
//       }
//     }
//   };
//   const onAllSlipsPrintClicked = async (event:any): Promise<void> => {
//       setIsLoadingData(true);
//       const maskedStatus:any = sessionStorage.getItem("maskEnabled")
//       if(maskedStatus === "true"){
//           setIsLoadingData(false);
//         setSnackbarData({
//           open: true,
//           message: "Please unmask your data.",
//           severity: "error",
//         });
//       } else {
//       try {
//         const response:any = await ReportsService.createInvoice(clientId,taxYear);
//         setIsLoadingData(false);
//         console.log("responseresponse",response)
//         if(response.message === "Slips not found for invoice."){
//           setIsLoadingData(true);
//             try {
//               const filingAuditResponse:any = await T4SlipService.getT4AuditFiling(clientId,taxYear);
//               console.log("filingAuditResponse",filingAuditResponse)
//               if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
//                 console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else if(filingAuditResponse && filingAuditResponse.t4_filling && filingAuditResponse.t4_filling.length > 0){
//                 console.log("filingAuditResponse.t4_filling",filingAuditResponse.t4_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to generate documents.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else {
//           setIsLoadingData(true);
//           try {
//             const response: any = await PrintingService.createAllSlipsPdfPrint(clientId, event, "T4", taxYear);
//             console.log("response000",response)
//             if(response.status === 400){
//               setSnackbarData({
//                 open: true,
//                 message: response.data.message ? response.data.message : "Something went wrong.",
//                 // message: "Please Unmask sensitive data for PDF printing.",
//                 severity: "error",
//               });
//             }else{
//               const filename = 'T4slip.pdf';
//               savePdfAsFile(response, filename);
//             }
//             setIsLoadingData(false);
//           } catch (err) {
//             console.log("err",err)
//           }
//         }
//         setIsLoadingData(false);
//             } catch(err:any) {
//               setIsLoadingData(false);
//               alert(err)
//             }
//         } else if(response && response.id !== null) {
//           setInvoiceData(response)
//           setInvoiceDialogOpen(true)
//         }else{
//           setSnackbarData({
//             open: true,
//             message: "Something went wrong.",
//             severity: "error",
//           });
//         }
//         setIsLoadingData(false);
//       } catch (err) {
//         console.log("err",err)
//       }
//       }
//   };
//   const onEmailButtonClicked = async (): Promise<void> => {
//     const completedSlipsIds = selectedRowsValues.filter((item:any) => item.slipStatus === "Completed").map((item:any) => item.id);
//     console.log("completedSlipsIds12",completedSlipsIds)
//     // Convert arrays to strings and compare
//     if(newData.length > 0){
//       const areArraysEqual = JSON.stringify(newData.sort()) === JSON.stringify(completedSlipsIds.sort());
//       if (!areArraysEqual) {
//         setSnackbarData({
//           open: true,
//           message: "Please Selected T4 Completed Slips Only!",
//           severity: "error",
//         });
//       }else{
//         setIsLoadingData(true);
//         const maskedStatus:any = sessionStorage.getItem("maskEnabled")
//         if(maskedStatus === "true"){
//           setIsLoadingData(false);
//           setSnackbarData({
//             open: true,
//             message: "Please unmask your data.",
//             severity: "error",
//           });
//         } else {
//         try {
//           const response:any = await ReportsService.createInvoice(clientId,taxYear);
//           setIsLoadingData(false);
//           console.log("responseresponse",response)
//           if(response.message === "Slips not found for invoice."){
//             setIsLoadingData(true);
//             try {
//               const filingAuditResponse:any = await T4SlipService.getT4AuditFiling(clientId,taxYear);
//               console.log("filingAuditResponse",filingAuditResponse)
//               if(filingAuditResponse && filingAuditResponse.general_filling && filingAuditResponse.general_filling.length > 0){
//                 console.log("filingAuditResponse.general_filling",filingAuditResponse.general_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               } else if(filingAuditResponse && filingAuditResponse.t4_filling && filingAuditResponse.t4_filling.length > 0){
//                 console.log("filingAuditResponse.t4_filling",filingAuditResponse.t4_filling)
//                 setSnackbarData({
//                   open: true,
//                   message: (
//                     <>
//                       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
//                       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                                                       textDecoration:"none",
//                                                       fontWeight:"bold"}}>Click here to view</a>
//                     </>
//                   ),
//                   severity: "error",
//                   autoHide:false
//                 });
//               }
//               // else if(filingAuditResponse && filingAuditResponse.t4_warnings && filingAuditResponse.t4_warnings.length > 0){
//               //   // Check if a string contains the word "email"
//               //   const containsEmail = (str: string) => str.toLowerCase().includes('email is missing');
//               //   const containsDOB = (str: string) => str.toLowerCase().includes('date of birth is missing');

//               //   console.log("containsEmail",containsEmail)

//               //   // Iterate over the array and log the string if it contains the word "email"
//               //   filingAuditResponse && filingAuditResponse.t4_warnings.forEach((str:any) => {
//               //     if (containsEmail(str)) {
//               //       console.log("strstrstr email",str);
//               //       setSnackbarData({
//               //         open: true,
//               //         message: (
//               //           <>
//               //             {str}{' '}
//               //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//               //                                             textDecoration:"none",
//               //                                             fontWeight:"bold"}}>Click here to view</a>
//               //           </>
//               //         ),
//               //         severity: "error",
//               //         autoHide:false
//               //       });
//               //     } else if (containsDOB(str)) {
//               //       console.log("strstrstr dob",str);
//               //       setSnackbarData({
//               //         open: true,
//               //         message: (
//               //           <>
//               //             {str}{' '}
//               //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//               //                                             textDecoration:"none",
//               //                                             fontWeight:"bold"}}>Click here to view</a>
//               //           </>
//               //         ),
//               //         severity: "error",
//               //         autoHide:false
//               //       });
//               //     }
//               //   });
//                 // setSnackbarData({
//                 //   open: true,
//                 //   message: (
//                 //     <>
//                 //       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
//                 //       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
//                 //                                       textDecoration:"none",
//                 //                                       fontWeight:"bold"}}>Click here to view</a>
//                 //     </>
//                 //   ),
//                 //   severity: "error",
//                 //   autoHide:false
//                 // });
//               // }
//             else {
//             const ids_list = newData.map((ids: any) => `slip_ids=${ids}`).join('&');
//             const pdfIds: any = ids_list.replace(/^&+|&+$/g, '');
//             setIsLoadingData(true);
//             try {
//               const response: any = await PrintingService.createBulkT4PdfEmail(clientId, pdfIds, taxYear);
//               console.log("response000",response)
//               if(response.status === 400){
//                 setSnackbarData({
//                   open: true,
//                   message: response.data.message ? response.data.message : "Something went wrong.",
//                   // message: "Please Unmask sensitive data for PDF printing.",
//                   severity: "error",
//                 });
//               }else if(response.status === 201){
//                 setSnackbarData({
//                   open: true,
//                   message: response.data.message ? response.data.message : "Email send Successfully.",
//                   severity: "success",
//                 });
//               } else {
//                 setSnackbarData({
//                   open: true,
//                   message: "Something Went Wrong.",
//                   severity: "error",
//                 })
//               }
//               setIsLoadingData(false);
//             } catch (err) {
//               console.log("err",err)
//             }
//             }
//             setIsLoadingData(false);
//             } catch(err:any) {
//               setIsLoadingData(false);
//               alert(err)
//             }
//           } else if(response && response.id !== null) {
//             setInvoiceData(response)
//             setInvoiceDialogOpen(true)
//           }else{
//             setSnackbarData({
//               open: true,
//               message: "Something went wrong.",
//               severity: "error",
//             });
//           }
//           setIsLoadingData(false);
//         } catch (err) {
//           console.log("err",err)
//         }
//       }
//       }
//     }
//   }

//     const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
//       setStatusValue(event.target.value as string);
//       setApiAction("updateStatus");
//       setDialogOpen(true);
//     };

//     const onUnPaidClickHandler = (clickedRow: any) => {
//       setIsLoadingData(true);
//       // setTimeout(() => {
//       //   setIsLoadingData(false);
//       // }, 2500);
//       const fetchData = async () => {
//         try {
//           const response: any =
//           await ReportsService.paypalIntegrationOfUnpaidInvoice(id,clickedRow.refNo);
//           setIsLoadingData(false);
//           console.log("responseData111-----------------",response);
//           window.open(response, '_blank');

//       } catch (error) {
//             console.error("Error fetching data:", error);
//             setIsLoadingData(false);
//           }
//       }
//       fetchData();
//       console.log("clickedRowclickedRowclickedRow", clickedRow);
//     };

//     const onClickInvoiceNoHandler = (clickedRow: any) => {
//       dispatch(setSelectedINVOICE(clickedRow));
//       console.log("clickedRowclickedRowclickedRow", clickedRow);
//     };

//     useEffect(()=>{
//       // const data = ["my email is starts with zee", "here is last letters 645"];

//       // // Check if a string contains the word "email"
//       // const containsEmail = (str: string) => str.toLowerCase().includes('email');

//       // // Iterate over the array and log the string if it contains the word "email"
//       // data.forEach((str) => {
//       //   if (containsEmail(str)) {
//       //     console.log("strstrstr",str);
//       //   }
//       // });
//     },[])

//   const image =
//   "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";

//   return slipList && slipList.items.length === 0 && zeroClient === true ? (
//     <EmptyList
//     showImage={true}
//     imagePath= {image}
//     heading={`There's nothing! Add T4 Slip to proceed`}
//     content={`You can add t4 slip by directly clicking on add button or by importing csv/xml file.`}
//     testId={"t4slip-empty-list"}
//     showAddButton= {true}
//     addButtonLabel={`Add T4 Slip`}
//     navigateToAdd={"/taxslip/t4/setup"}
//     showImportButton={true}
//     importButtonLabel="Import CSV"
//     navigateToImport={"/taxslip/t4/import"}
//     showImportXmlButton={true}
//     importXmlButtonLabel="Import XML"
//     navigateToImportXml={"/taxslip/t4/import-xml"}
//     />
//   ) : (
//     <>
//     <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
//       <BackdropCircularProgress
//           open={
//             isBulkDeleting || isDeleting || isLoadingData
//           }
//           testId="T4 Slip List"
//         />
//       <DeleteConfirmationDialog
//           open={openDialog}
//           deleteActionHandler={bulkT4SlipsDelete === true ? deleteBulkT4SlipsField : deleteT4SlipField}
//           cancelActionHandler={onCloseHandler}
//           testId="T4-confirmation-dialog"
//         />
//       <EnhancedDataGrid
//         columns={slipList ? slipList.headerColumns : []}
//         rows={slipList && t4SlipListUpdatedItems ? t4SlipListUpdatedItems : []}
//         selectionEnabled={true}
//         autoHeight={true}
//         allowSearching={true}
//         onSearchButtonClicked={onSearchButtonClicked}
//         searchFieldPlaceholder={"Search by Employee Name..."}
//         rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
//         selectionToolbar={selectionToolbar}
//         selectedRowsValues={(value:any)=>setSelectedRowsValues(value)}
//         onRowEditClicked={onRowEditClicked}
//         onRowDeleteClicked={onRowDeleteClicked}
//         // initialPage={0}
//         initialPageSize={INITIAL_PAGE_SIZE}
//         disableColumnMenu={true}
//         showRowActionsColumn={true}
//         autoPageSize={false} //
//         showIndexColumn={true}
//         testId="resource-list-grid"
//         sortingMode="server"
//         filterMode="server"
//         paginationMode="server"
//         onRequestParamsChange={onRequestParamsChange}
//         onSearchCrossClicked={(value) => setzeroClient(value)}
//         totalRowsCount={slipList?.total}
//         isLoading={isLoading || isAxiosCallLoading}
//         rowIdGetter={(row) => row.id}
//         searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
//         shouldMask={false}
//       />
//       {/* invoice dialogu open if any transaction is unPaid */}
//       <EnhancedDialog
//             dialogTitle="Invoice(s) Due"
//             key="dialog-box"
//             testId="dialog"
//             onCloseHandler={onCloseHandler}
//             open={openInvoiceDialog}
//             dialogContent={
//               <InvoiceDialogPanel
//               invoiceData={invoiceData}
//               onUnPaidClickHandler={onUnPaidClickHandler}
//               onClickInvoiceNoHandler={onClickInvoiceNoHandler}
//               />
//             }
//             dialogAction={
//               <Grid>
//                 <Button
//                   autoFocus
//                   variant="contained"
//                   onClick={onCloseHandler}
//                 >
//                   Cancel
//                 </Button>
//               </Grid>
//             }
//           />
//     </Grid>
//     <EnhancedSnackbar
//     message={snackbarData.message}
//     onCloseHandler={onSnackbarClosed}
//     severity={snackbarData.severity}
//     testId={"T4-list-snackbar"}
//     open={snackbarData.open}
//     autoHide={snackbarData.autoHide}
//   />
//   </>
//   );
// };

// use prime data grid

import {
  BackdropCircularProgress,
  DeleteConfirmationDialog,
  EmptyList,
  EnhancedDialog,
  EnhancedLinkButton,
  EnhancedSnackbar,
  IResponse,
  IServerError,
  IconTextButton,
  RouterLinkButton,
  SplitButton,
} from "@websential/cosmic";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import {
  GET_QUERY_KEY,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
  T4_LIST_SCREEN_ID,
  T4_LIST_SCREEN_SLUG,
} from "common/constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import EmailIcon from "@mui/icons-material/Email";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FileSaver from "file-saver";
import { IAppState } from "store/reducers";
import { IGetListRequest } from "models";
import { InvoiceDialogPanel } from "./InvoiceDialogPanel";
import { PrimeDataGrid } from "../../PrimeDataGrid";
import PrintIcon from "@mui/icons-material/Print";
import { PrintingService } from "services/PrintingService";
import { ReportsService } from "services/ReportsService";
import { T4SlipService } from "services/T4SlipService";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedINVOICE } from "store/actions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { toSnake } from "common/methods";
import { ProgressBar } from "primereact/progressbar";
import { ClientService } from "services";

export interface IT4Slip {}
const TAXSLIP_T4_LIST_QUERY_KEY = "t4-slips-list";

const useT4Slips = (
  clientId: string,
  taxYear: number,
  params: IGetListRequest
) =>
  useQuery(
    [GET_QUERY_KEY.LIST_T4_SLIPS, params],
    (data) => {
      return T4SlipService.getList(clientId, taxYear, params);
    },
    { refetchOnWindowFocus: false }
  );

const GET_ADJUSTMENTS_QUERY_KEY = "get-adjustments";

const useAdjustments = (clientId: string) =>
  useQuery(
    GET_ADJUSTMENTS_QUERY_KEY,
    () => AdjustmentOptionsService.getAdjustmentOptionsByClientId(clientId),
    QUERY_OPTIONS
  );

const useGetSlipIds = (
  tag: string,
  clientId: string,
  taxYear: number
) =>
  useQuery(
    ["get-slip-ids", tag, clientId, taxYear],
    (data) => {
      return T4SlipService.getSlipIds(tag, clientId, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

  const useGetCompanyData = (id: string | undefined) => 
    useQuery(
      ["client-get-by-id", id],
      (data) => {
        return ClientService.getById(id);
      },
      { refetchOnWindowFocus: false }
    );

export const T4SlipList: React.FC<IT4Slip> = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state: IAppState) => state.client);
  const activityLog = useSelector((state: IAppState) => state.activityLog);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );
  const interval = useRef<any>(null);
  const [percentageValue, setPercentageValue] = useState(0);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);
  const { resourceName } = useParams();
  const [dataRoleName, setDataRoleName] = useState<string>("");
  const clientId = useSelector((state: IAppState) => state.client.id);
  const clientName = useSelector((state: IAppState) => state.client.clientName);

  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [t4SlipListUpdatedItems, setT4SlipListUpdatedItems] =
    useState<any>(undefined);
  const [selectedRowsValues, setSelectedRowsValues] = useState([]);

  const [openInvoiceDialog, setInvoiceDialogOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [invoicePaymentFor, setInvoicePaymentFor] = useState(10)


  // for delete row Purpose
  const queryClient: any = useQueryClient();
  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);

  useEffect(() => {
    if (resourceName && resourceName.length > 0) {
      console.log("resourceName>>>>>", resourceName);
      setDataRoleName(resourceName);
    }
  }, [dataRoleName, resourceName]);

  const theme = useTheme();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "name1",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useT4Slips(id, taxYear, queryParams);
  const slipList: any = data;
  console.log("@@@@@ => SlipList", slipList);


  const { isLoading:loadingSlipIds, data:t4SlipIds } = useGetSlipIds("T4", clientId, taxYear);
  // const t4SlipIds: any = data;
  console.log("@@@@@ => t4SlipIds", t4SlipIds);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  useEffect(()=>{
    console.log("companyGetByIdRecord11",companyGetByIdRecord)
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor ? parseInt(paymentFor.value) : 10)
    }
  },[companyGetByIdRecord])

  const { isLoading: isLoadingAdjustments, data: adjustments } =
    useAdjustments(clientId);
  let AdjustmentOptionsData: any = adjustments;

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  // const isAxiosCallLoading = queryClient.isFetching([TAXSLIP_T4_LIST_QUERY_KEY]) > 0;
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
  useEffect(() => {
    if (isLoading) {
      setIsAxiosCallLoading(true);
      setTimeout(() => {
        setIsAxiosCallLoading(false);
      }, 1000);
    } else {
      setIsAxiosCallLoading(true);
      setTimeout(() => {
        setIsAxiosCallLoading(false);
      }, 1000);
    }
  }, [isLoading]);
  console.log("@@@@@ => isAxiosCallLoading", isAxiosCallLoading);

  useEffect(() => {
    if (slipList) {
      const updatedItemsResponse = slipList.items.map((item: any) => ({
        ...item,
        cppQppExempt: item.cppQppExempt ? "Yes" : "No",
        eiExempt: item.eiExempt ? "Yes" : "No",
        reducedEi: item.reducedEi ? "Yes" : "No",
        name: (
          <Link
            to={`/taxslip/t4/setup/${item.id}`}
            className="no-underline"
            style={{ color: "blue" }}
            // onClick={() => onClickHandler(row)}
          >
            {item.name}
          </Link>
        ),
      }));
      setT4SlipListUpdatedItems(updatedItemsResponse);
    }
    console.log(
      "t4SlipListUpdatedItemst4SlipListUpdatedItems",
      t4SlipListUpdatedItems
    );
  }, [slipList]);

  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);
  const [searchText, setSearchText] = useState({});
  const [zeroClient, setzeroClient] = useState(true);

  // for delete row
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [t4SlipId, setT4SlipId] = useState<number>(0);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: "name1",
      order_direction: requestParams.sortOrder
        ? requestParams.sortOrder
        : "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
    };
    // if (requestParams.sort) {
    //   const [sortBy, sortDirection] = requestParams.sort.split(",");
    //   dispatchParams.order_by = toSnake(sortBy);
    //   dispatchParams.order_direction = sortDirection;
    // }
    console.log("dispatchParams", dispatchParams);
    setQueryParams(dispatchParams);
  }, []);

  const onSearchButtonClicked = () => {
    setQueryParams(searchText);
    setzeroClient(false);
  };
  console.log("zeroClient--", zeroClient);

  const onRowEditClicked = useCallback((params) => {
    console.log("params123", params);
    let path = `/taxslip/t4/setup/${params.id}`;
    navigate(path);
  }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
    setInvoiceDialogOpen(false);
    setOpenProgressBarDialog(false)
  };

  const callAction = () => {
    if (apiAction === "delete") {
      alert(`Delete row with ID: ${selectedIds} has been clicked!`);
      // TODO api call for delete
    } else if (apiAction === "updateStatus") {
      alert(`action need to trigger : ${apiAction}`);
      // TODO api call for update status
    }
    setDialogOpen(false);
  };

  // For Delete Row
  const deleteT4SlipField = () => {
    if (singleDelete) {
      let payload = {
        t4SlipId,
        clientId,
        taxYear,
      };
      setSingleDelete(false);
      deleteT4SlipById(payload);
      setDialogOpen(false);
    }
  };

  const onRowDeleteClicked = useCallback((params) => {
    setDialogOpen(true);
    setSingleDelete(true);
    setApiAction("delete");
    setT4SlipId(params.id);
    console.log(
      "T4SlipId in T4Slip List OnDeleteRowClicked function",
      params.id
    );
    // alert(`Delete row with ID: ${params.row.id} has been clicked!`);
  }, []);

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const { isLoading: isDeleting, mutate: deleteT4SlipById } = useMutation(
    T4SlipService.delT4Slip,
    {
      onSuccess: (res: any | object | IServerError | IResponse) => {
        console.log("resonce for Delete row", res);
        if (res.message === "T4 slip has been deleted") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res.message ? res.message : "T4 slip has been deleted",
            severity: "success",
          });

          return queryClient.invalidateQueries(GET_QUERY_KEY.LIST_T4_SLIPS);
        } else {
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "error",
          });
        }
      },
      onError: (err: any) => {
        console.log("resonce for Delete row", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  // Bulk Delete
  const [bulkDelete, setBulkDelete] = useState<boolean>(false);
  const [bulkT4SlipsId, setBulkT4SlipsId] = useState<any>({});
  const [bulkT4SlipsDelete, setBulkT4SlipsDelete] = useState<boolean>(false);

  const { isLoading: isBulkDeleting, mutate: deleteBulkT4SlipsById } =
    useMutation(T4SlipService.delBulkSlips, {
      onSuccess: (res: any | string | IServerError | IResponse) => {
        if (typeof res === "object") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "success",
          });

          setBulkT4SlipsDelete(false);
          return queryClient.invalidateQueries(TAXSLIP_T4_LIST_QUERY_KEY);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
        setBulkT4SlipsDelete(false);
      },
      onError: (err: any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
        setBulkT4SlipsDelete(false);
      },
    });

  // For Bulk Delete
  const deleteBulkT4SlipsField = () => {
    if (bulkDelete) {
      setBulkDelete(false);
      deleteBulkT4SlipsById(bulkT4SlipsId);
      setDialogOpen(false);
    }
  };

  const savePdfAsFile = (xmlData: any, filename: any) => {
    const blob = new Blob([xmlData], { type: "pdf;charset=utf-8" });
    FileSaver.saveAs(blob, filename);
  };

  const onRowMultipleDeleteClicked = (params: any) => {
    console.log("Params in onRowMultipleDeleteClicked ====== ", params);
    let bulkDeletePayload = {
      ids: { slipIds: params },
      clientId,
      taxYear,
    };
    setDialogOpen(true);
    setBulkDelete(true);
    setApiAction("delete");
    setBulkT4SlipsId(bulkDeletePayload);
    setBulkT4SlipsDelete(true);
  };

  const selectionToolbar = useCallback(
    (selectedItems) => {
      console.log("selectedItems", selectedItems);
      console.log("selectedRowsValues", selectedRowsValues);
      console.log("queryParams.size", queryParams.size);
      setSelectedIds(selectedItems);
      newData = selectedItems;
      if (selectedItems && selectedItems.length) {
        return (
          <Box
            sx={{
              marginTop: theme.spacing(1),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {slipList && slipList?.items.length === selectedItems.length ? (
              <Box
                sx={{
                  marginRight: "1rem",
                  textTransform: "capitalize",
                  display: "inline-block",
                  "& .MuiPopper-root": { zIndex: 2 },
                }}
              >
                <SplitButton
                  options={["Employee", "Employer", "CRA"]}
                  onClickHandler={onAllSlipsPrintClicked}
                  testId="t4-print-button"
                  icon={<PrintIcon />}
                  listItemsLabel={`Print T4 Slips `}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  marginRight: "1rem",
                  textTransform: "capitalize",
                  display: "inline-block",
                  "& .MuiPopper-root": { zIndex: 2 },
                }}
              >
                <SplitButton
                  options={["Employee", "Employer", "CRA"]}
                  onClickHandler={onPrintClicked}
                  testId="t4-print-button"
                  icon={<PrintIcon />}
                  listItemsLabel={`Print T4 Slips `}
                />
              </Box>
            )}
            {/* <Box sx={{ marginRight:"1rem", textTransform:"capitalize", display:"inline-block", "& .MuiPopper-root":{zIndex:2}}}>
              <SplitButton
                  options={[
                    "Employee",
                    "Employer",
                    "CRA",
                  ]}
                  onClickHandler={onAllSlipsPrintClicked}
                  testId="t4-print-button"
                  icon={<PrintIcon />}
                  listItemsLabel={`Print All T4 \nSlips`}
                />
            </Box> */}

            <Box
              sx={{
                marginRight: "1rem",
                textTransform: "capitalize",
                display: "inline-block",
              }}
            >
              <Button
                variant="outlined"
                startIcon={<EmailIcon />}
                onClick={onEmailButtonClicked}
              >
                Email
              </Button>
            </Box>
            <Button
              onClick={() => onRowMultipleDeleteClicked(selectedItems)}
              // sx={{marginBottom:"1rem"}}
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          </Box>
        );
      }
      return (
        <Box sx={{ marginTop: theme.spacing(1), display: "flex" }}>
          <Box
            sx={{
              marginRight: "1rem",
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            {AdjustmentOptionsData &&
            AdjustmentOptionsData.remittanceTransferred ? (
              <Tooltip title="Remittance has been transferred.">
                <span>
                  <Button startIcon={<ExitToAppIcon />} disabled>
                    Import CSV
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <EnhancedLinkButton
                text={"Import CSV"}
                icon={<ExitToAppIcon />}
                navigateTo={"/taxslip/t4/import"}
                testId={"import-t4-slip-button"}
              />
            )}
          </Box>
          <Box
            sx={{
              marginRight: "1rem",
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            {AdjustmentOptionsData &&
            AdjustmentOptionsData.remittanceTransferred ? (
              <Tooltip title="Remittance has been transferred.">
                <span>
                  <Button startIcon={<ExitToAppIcon />} disabled>
                    Import XML
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <EnhancedLinkButton
                text={"Import XML"}
                icon={<ExitToAppIcon />}
                navigateTo={"/taxslip/t4/import-xml"}
                testId={"import-t4-slip-button"}
              />
            )}
          </Box>
          {AdjustmentOptionsData &&
          AdjustmentOptionsData.remittanceTransferred ? (
            <Tooltip title="Remittance has been transferred.">
              <span>
                <Button variant="outlined" disabled>
                  Add T4 Slip
                </Button>
              </span>
            </Tooltip>
          ) : (
            <RouterLinkButton to="/taxslip/t4/setup" text="Add T4 Slip" />
          )}
        </Box>
      );
    },
    [theme, selectedRowsValues]
  );

  let newData: any = [];
  const onPrintClicked = async (event: any): Promise<void> => {
    const completedSlipsIds = selectedRowsValues
      .filter((item: any) => item.slipStatus === "Completed")
      .map((item: any) => item.id);
    console.log("completedSlipsIds12", completedSlipsIds);
    console.log("newData", newData);
    // Convert arrays to strings and compare
    if (newData.length > 0) {
      const areArraysEqual =
        JSON.stringify(newData.sort()) ===
        JSON.stringify(completedSlipsIds.sort());
      console.log("areArraysEqual", areArraysEqual);
      if (!areArraysEqual) {
        console.log("ifbskjdvbkisdb");
        setSnackbarData({
          open: true,
          message: "Please Selected T4 Completed Slips Only!",
          severity: "error",
        });
      } else {
        setIsLoadingData(true);
        const maskedStatus: any = sessionStorage.getItem("maskEnabled");
        if (maskedStatus === "true") {
          setIsLoadingData(false);
          setSnackbarData({
            open: true,
            message: "Please unmask your data.",
            severity: "error",
          });
        } else {
          if(invoicePaymentFor === 10){
            try {
              const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
                clientId,
                taxYear-1
              );
            try {
              const response: any = await ReportsService.createInvoice(
                clientId,
                taxYear
              );
              setIsLoadingData(false);
              console.log("responseresponse", response);
              if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
                if(t4SlipIds && t4SlipIds.generalAuditStatus !== null && t4SlipIds.generalAuditStatus === true &&
                  t4SlipIds && t4SlipIds.auditStatus !== null &&  t4SlipIds.auditStatus === true){
                  const ids_list = newData
                    .map((ids: any) => `t4_slip_ids=${ids}`)
                    .join("&");
                  const pdfIds: any = ids_list.replace(/^&+|&+$/g, "");
                  setIsLoadingData(true);
                  try {
                    const response: any =
                      await PrintingService.createBulkT4PdfPrint(
                        clientId,
                        event,
                        pdfIds,
                        taxYear
                      );
                    console.log("response000", response);
                    if (response.status === 400) {
                      setSnackbarData({
                        open: true,
                        message: response.data.message
                          ? response.data.message
                          : "Something went wrong.",
                        // message: "Please Unmask sensitive data for PDF printing.",
                        severity: "error",
                      });
                    } else {
                      const filename = `T4slip-${clientName}-${event}.pdf`;
                      savePdfAsFile(response, filename);
                    }
                    setIsLoadingData(false);
                  } catch (err) {
                    console.log("err", err);
                  }
                // }
                setIsLoadingData(false);
              } else {
                setIsLoadingData(false);
                setSnackbarData({
                open: true,
                message: (
                  <>
                    Please load Audit in the menu, press Run T4 Audit and clear all audit items (Audit Filing and PIER Review) to generate documents.{" "}
                    <a
                      href="https://appv3.taxslips.com/#/audit/filing"
                      style={{
                        color: "#aa1010",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to view
                    </a>
                  </>
                ),
                severity: "error",
                autoHide: false,
              });
            }

              } else if (
                response?.id != null &&
                previousYearInvoiceResponse?.id != null
              ) {
                console.log("one");
                setInvoiceData([previousYearInvoiceResponse, response]);
                setInvoiceDialogOpen(true);
              } else if (
                previousYearInvoiceResponse?.id != null &&
                response?.message === "Slips not found for invoice."
              ) {
                console.log("two");
                setInvoiceData([previousYearInvoiceResponse]);
                setInvoiceDialogOpen(true);
              } else if (
                response?.id != null &&
                previousYearInvoiceResponse?.message === "Slips not found for invoice."
              ) {
                console.log("three");
                setInvoiceData([response]);
                setInvoiceDialogOpen(true);
              }else {
                setSnackbarData({
                  open: true,
                  message: "Something went wrong.",
                  severity: "error",
                });
              }
              setIsLoadingData(false);
            } catch (err) {
              console.log("err", err);
              setIsLoadingData(false);
            }
            } catch (err) {
              console.log("err", err);
              setIsLoadingData(false);
            }
          } else {
          try {
            const response: any = await ReportsService.createInvoice(
              clientId,
              taxYear
            );
            setIsLoadingData(false);
            console.log("responseresponse", response);
            if (response.message === "Slips not found for invoice.") {
              setIsLoadingData(true);
              // try {
              //   const filingAuditResponse: any =
              //     await T4SlipService.getT4AuditFiling(clientId, taxYear);
              //   console.log("filingAuditResponse", filingAuditResponse);
              //   if (
              //     filingAuditResponse &&
              //     filingAuditResponse.general_filling &&
              //     filingAuditResponse.general_filling.length > 0
              //   ) {
              //     console.log(
              //       "filingAuditResponse.general_filling",
              //       filingAuditResponse.general_filling
              //     );
              //     setSnackbarData({
              //       open: true,
              //       message: (
              //         <>
              //           Please load Audit in the menu and clear all audit items
              //           (Audit Filing and PIER Review) to generate documents.{" "}
              //           <a
              //             href="https://appv3.taxslips.com/#/audit/filing"
              //             style={{
              //               color: "#aa1010",
              //               textDecoration: "none",
              //               fontWeight: "bold",
              //             }}
              //           >
              //             Click here to view
              //           </a>
              //         </>
              //       ),
              //       severity: "error",
              //       autoHide: false,
              //     });
              //   } else if (
              //     filingAuditResponse &&
              //     filingAuditResponse.t4_filling &&
              //     filingAuditResponse.t4_filling.length > 0
              //   ) {
              //     console.log(
              //       "filingAuditResponse.t4_filling",
              //       filingAuditResponse.t4_filling
              //     );
              //     setSnackbarData({
              //       open: true,
              //       message: (
              //         <>
              //           Please load Audit in the menu and clear all audit items
              //           (Audit Filing and PIER Review) to generate documents.{" "}
              //           <a
              //             href="https://appv3.taxslips.com/#/audit/filing"
              //             style={{
              //               color: "#aa1010",
              //               textDecoration: "none",
              //               fontWeight: "bold",
              //             }}
              //           >
              //             Click here to view
              //           </a>
              //         </>
              //       ),
              //       severity: "error",
              //       autoHide: false,
              //     });
              //   } else {
                if(t4SlipIds && t4SlipIds.generalAuditStatus !== null && t4SlipIds.generalAuditStatus === true &&
                  t4SlipIds && t4SlipIds.auditStatus !== null &&  t4SlipIds.auditStatus === true){
                  const ids_list = newData
                    .map((ids: any) => `t4_slip_ids=${ids}`)
                    .join("&");
                  const pdfIds: any = ids_list.replace(/^&+|&+$/g, "");
                  setIsLoadingData(true);
                  try {
                    const response: any =
                      await PrintingService.createBulkT4PdfPrint(
                        clientId,
                        event,
                        pdfIds,
                        taxYear
                      );
                    console.log("response000", response);
                    if (response.status === 400) {
                      setSnackbarData({
                        open: true,
                        message: response.data.message
                          ? response.data.message
                          : "Something went wrong.",
                        // message: "Please Unmask sensitive data for PDF printing.",
                        severity: "error",
                      });
                    } else {
                      const filename = `T4slip-${clientName}-${event}.pdf`;
                      savePdfAsFile(response, filename);
                    }
                    setIsLoadingData(false);
                  } catch (err) {
                    console.log("err", err);
                  }
                // }
                setIsLoadingData(false);
              } else {
                setIsLoadingData(false);
                setSnackbarData({
                open: true,
                message: (
                  <>
                    Please load Audit in the menu, press Run T4 Audit and clear all audit items (Audit Filing and PIER Review) to generate documents.{" "}
                    <a
                      href="https://appv3.taxslips.com/#/audit/filing"
                      style={{
                        color: "#aa1010",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to view
                    </a>
                  </>
                ),
                severity: "error",
                autoHide: false,
              });
            }
              // } catch (err: any) {
              //   setIsLoadingData(false);
              //   alert(err);
              // }
            } else if (response && response.id !== null) {
              setInvoiceData([response]);
              setInvoiceDialogOpen(true);
            } else {
              setSnackbarData({
                open: true,
                message: "Something went wrong.",
                severity: "error",
              });
            }
            setIsLoadingData(false);
          } catch (err) {
            console.log("err", err);
            setIsLoadingData(false);
          }
        }
      }
    }
    }
  };
  const onAllSlipsPrintClicked = async (event: any): Promise<void> => {
    setIsLoadingData(true);
    const maskedStatus: any = sessionStorage.getItem("maskEnabled");
    if (maskedStatus === "true") {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(t4SlipIds && t4SlipIds.generalAuditStatus !== null && t4SlipIds.generalAuditStatus === true &&
              t4SlipIds && t4SlipIds.auditStatus !== null &&  t4SlipIds.auditStatus === true){
              if(t4SlipIds && t4SlipIds.slipIds && t4SlipIds.slipIds.length > 100){
                const maxItemsPerIteration = 100;
                const totalIterations = Math.ceil(t4SlipIds.slipIds.length / maxItemsPerIteration); // Calculate total iterations required
                setIsLoadingData(true);
                for (let i = 0; i < totalIterations; i++) {
                  const start = i * maxItemsPerIteration;
                  const end = Math.min(start + maxItemsPerIteration, t4SlipIds.slipIds.length);
                  const chunk = t4SlipIds.slipIds.slice(start, end);
                  const ids_list = chunk
                      .map((ids: any) => `t4_slip_ids=${ids}`)
                      .join("&");
                    const pdfIds: any = ids_list.replace(/^&+|&+$/g, "");
                    try {
                      const response: any =
                        await PrintingService.createBulkT4PdfPrint(
                          clientId,
                          event,
                          pdfIds,
                          taxYear
                        );
                      console.log("response000", response);
                      if (response.status === 400) {
                        setSnackbarData({
                          open: true,
                          message: response.data.message
                            ? response.data.message
                            : "Something went wrong.",
                          // message: "Please Unmask sensitive data for PDF printing.",
                          severity: "error",
                        });
                      } else {
                        const filename = `T4slip-${clientName}-${event}-(${i + 1}).pdf`;
                        savePdfAsFile(response, filename);
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                    console.log(`Iteration ${i + 1}: Count = ${chunk.length}`);
                    console.log(chunk);
                  }
                  setIsLoadingData(false);
              } else {
              setIsLoadingData(true);
              try {
                const response: any =
                  await PrintingService.createAllSlipsPdfPrint(
                    clientId,
                    event,
                    "T4",
                    taxYear
                  );
                console.log("response000", response);
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    // message: "Please Unmask sensitive data for PDF printing.",
                    severity: "error",
                  });
                } else {
                  const filename = `T4slip-${clientName}-${event}.pdf`;
                  savePdfAsFile(response, filename);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
              }
            }
            } else {
              setIsLoadingData(false);
                setSnackbarData({
                open: true,
                message: (
                  <>
                    Please load Audit in the menu, press Run T4 Audit and clear all audit items (Audit Filing and PIER Review) to generate documents.{" "}
                    <a
                      href="https://appv3.taxslips.com/#/audit/filing"
                      style={{
                        color: "#aa1010",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to view
                    </a>
                  </>
                ),
                severity: "error",
                autoHide: false,
              });
            }

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (response.message === "Slips not found for invoice.") {
          setIsLoadingData(true);



          // try {
            // const filingAuditResponse: any =
            //   await T4SlipService.getT4AuditFiling(clientId, taxYear);
            // console.log("filingAuditResponse", filingAuditResponse);
            // if (
            //   filingAuditResponse &&
            //   filingAuditResponse.general_filling &&
            //   filingAuditResponse.general_filling.length > 0
            // ) {
            //   console.log(
            //     "filingAuditResponse.general_filling",
            //     filingAuditResponse.general_filling
            //   );
            //   setSnackbarData({
            //     open: true,
            //     message: (
            //       <>
            //         Please load Audit in the menu and clear all audit items
            //         (Audit Filing and PIER Review) to generate documents.{" "}
            //         <a
            //           href="https://appv3.taxslips.com/#/audit/filing"
            //           style={{
            //             color: "#aa1010",
            //             textDecoration: "none",
            //             fontWeight: "bold",
            //           }}
            //         >
            //           Click here to view
            //         </a>
            //       </>
            //     ),
            //     severity: "error",
            //     autoHide: false,
            //   });
            // } else if (
            //   filingAuditResponse &&
            //   filingAuditResponse.t4_filling &&
            //   filingAuditResponse.t4_filling.length > 0
            // ) {
            //   console.log(
            //     "filingAuditResponse.t4_filling",
            //     filingAuditResponse.t4_filling
            //   );
            //   setSnackbarData({
            //     open: true,
            //     message: (
            //       <>
            //         Please load Audit in the menu and clear all audit items
            //         (Audit Filing and PIER Review) to generate documents.{" "}
            //         <a
            //           href="https://appv3.taxslips.com/#/audit/filing"
            //           style={{
            //             color: "#aa1010",
            //             textDecoration: "none",
            //             fontWeight: "bold",
            //           }}
            //         >
            //           Click here to view
            //         </a>
            //       </>
            //     ),
            //     severity: "error",
            //     autoHide: false,
            //   });
            // } else {
            if(t4SlipIds && t4SlipIds.generalAuditStatus !== null && t4SlipIds.generalAuditStatus === true &&
              t4SlipIds && t4SlipIds.auditStatus !== null &&  t4SlipIds.auditStatus === true){
              if(t4SlipIds && t4SlipIds.slipIds && t4SlipIds.slipIds.length > 100){
                const maxItemsPerIteration = 100;
                const totalIterations = Math.ceil(t4SlipIds.slipIds.length / maxItemsPerIteration); // Calculate total iterations required
                setIsLoadingData(true);
                for (let i = 0; i < totalIterations; i++) {
                  const start = i * maxItemsPerIteration;
                  const end = Math.min(start + maxItemsPerIteration, t4SlipIds.slipIds.length);
                  const chunk = t4SlipIds.slipIds.slice(start, end);
                  const ids_list = chunk
                      .map((ids: any) => `t4_slip_ids=${ids}`)
                      .join("&");
                    const pdfIds: any = ids_list.replace(/^&+|&+$/g, "");
                    try {
                      const response: any =
                        await PrintingService.createBulkT4PdfPrint(
                          clientId,
                          event,
                          pdfIds,
                          taxYear
                        );
                      console.log("response000", response);
                      if (response.status === 400) {
                        setSnackbarData({
                          open: true,
                          message: response.data.message
                            ? response.data.message
                            : "Something went wrong.",
                          // message: "Please Unmask sensitive data for PDF printing.",
                          severity: "error",
                        });
                      } else {
                        const filename = `T4slip-${clientName}-${event}-(${i + 1}).pdf`;
                        savePdfAsFile(response, filename);
                      }
                    } catch (err) {
                      setIsLoadingData(false);
                      console.log("err", err);
                    }
                    console.log(`Iteration ${i + 1}: Count = ${chunk.length}`);
                    console.log(chunk);
                  }
                  setIsLoadingData(false);
              } else {
              setIsLoadingData(true);
              try {
                const response: any =
                  await PrintingService.createAllSlipsPdfPrint(
                    clientId,
                    event,
                    "T4",
                    taxYear
                  );
                console.log("response000", response);
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    // message: "Please Unmask sensitive data for PDF printing.",
                    severity: "error",
                  });
                } else {
                  const filename = `T4slip-${clientName}-${event}.pdf`;
                  savePdfAsFile(response, filename);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
              }
            }
            } else {
              setIsLoadingData(false);
                setSnackbarData({
                open: true,
                message: (
                  <>
                    Please load Audit in the menu, press Run T4 Audit and clear all audit items (Audit Filing and PIER Review) to generate documents.{" "}
                    <a
                      href="https://appv3.taxslips.com/#/audit/filing"
                      style={{
                        color: "#aa1010",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to view
                    </a>
                  </>
                ),
                severity: "error",
                autoHide: false,
              });
            }
            // }
            setIsLoadingData(false);
          // } catch (err: any) {
          //   setIsLoadingData(false);
          //   alert(err);
          // }


        } else if (response && response.id !== null) {
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
      }
    }
    }
  };
  const onEmailButtonClicked = async (): Promise<void> => {
    const completedSlipsIds = selectedRowsValues
      .filter((item: any) => item.slipStatus === "Completed")
      .map((item: any) => item.id);
    console.log("completedSlipsIds12", completedSlipsIds);
    // Convert arrays to strings and compare
    if (newData.length > 0) {
      const areArraysEqual =
        JSON.stringify(newData.sort()) ===
        JSON.stringify(completedSlipsIds.sort());
      if (!areArraysEqual) {
        setSnackbarData({
          open: true,
          message: "Please Selected T4 Completed Slips Only!",
          severity: "error",
        });
      } else {
        setIsLoadingData(true);
        const maskedStatus: any = sessionStorage.getItem("maskEnabled");
        if (maskedStatus === "true") {
          setIsLoadingData(false);
          setSnackbarData({
            open: true,
            message: "Please unmask your data.",
            severity: "error",
          });
        } else {
          if(invoicePaymentFor === 10){
            try {
              const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
                clientId,
                taxYear-1
              );
            try {
              const response: any = await ReportsService.createInvoice(
                clientId,
                taxYear
              );
              setIsLoadingData(false);
              console.log("responseresponse", response);
              if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
                if(t4SlipIds && t4SlipIds.generalAuditStatus !== null && t4SlipIds.generalAuditStatus === true &&
                  t4SlipIds && t4SlipIds.auditStatus !== null &&  t4SlipIds.auditStatus === true){
      
                    // const ids_list = slipList?.items.length === newData.length ? 
                    // t4SlipIds.slipIds
                    //   .map((ids: any) => `slip_ids=${ids}`)
                    //   .join("&") :
                    //   newData
                    //   .map((ids: any) => `slip_ids=${ids}`)
                    //   .join("&");
                    // const pdfIds: any = ids_list.replace(/^&+|&+$/g, "");
                    const ids_list = 
                    (slipList?.items?.length === newData.length) 
                        ? {items:t4SlipIds.slipIds} 
                        : {items:newData};
                        console.log("slipList?.items?.length === newData.length ",slipList?.items?.length === newData.length)
                        console.log("ids_list t4SlipIds.slipIds ",t4SlipIds.slipIds)
                        console.log("ids_list newData",newData)
                        console.log("ids_list",ids_list)
                    
                    setIsLoadingData(true);
                    try {
                      const tempResponse: any =
                        await T4SlipService.submitTemporaryData(
                          ids_list
                        );
                    const SubmitActualDataApi = async () => {
                      setPercentageValue(0)
                      try {
                        const response: any =
                        await PrintingService.createBulkT4PdfEmail(
                          clientId,
                          tempResponse.id,
                          taxYear
                        );
                      } catch (error) {
                        console.error("Error fetching data:", error);
                      }
                    };
                    SubmitActualDataApi();
                    setSnackbarData({
                      open: true,
                      message: "Email sending started!",
                      severity: "success",
                    });
                    setOpenProgressBarDialog(true)
                    //manage progress bar
                    let _val = percentageValue;
                    let consecutiveSameValueCount = 0; // Counter for consecutive same values
                    let lastAdjustedValue:any = null; // To track the last value
                    const fetchData = async () => {
                      try {
                        const response: any = await T4SlipService.temporaryItemsCount(tempResponse.id);
                        console.log("12345789", response);
                        console.log("12345789ff", response.fail);
                        console.log("12345789tt", tempResponse.totalFetched);
                        _val = Math.floor((response.counter / tempResponse.totalFetched) * 100); 
                        console.log("_val_val",_val)
                        setPercentageValue(_val);

                         // Check for consecutive same values
                        if (response.counter === lastAdjustedValue) {
                          consecutiveSameValueCount++;
                        } else {
                            consecutiveSameValueCount = 0; // Reset if value changes
                        }
                        lastAdjustedValue = response.counter;

                        if (consecutiveSameValueCount >= 4) {
                            console.log("Same value detected for 4 iterations, reloading screen.");
                            setSnackbarData({
                              open: true,
                              message: `Something went wrong, please try again later.`,
                              severity: "error",
                            });
                            setTimeout(()=>{
                              window.location.reload();
                            },2000)
                        }
                    
                        if (response.counter >= tempResponse.totalFetched) {
                          clearInterval(interval.current);
                          setPercentageValue(100)
                          
                          let totalSuccessfullImportedItems = response.counter - response.fail
                          setSnackbarData({
                            open: true,
                            message: `Email sent successfully to ${totalSuccessfullImportedItems} employee(s) except following. ${response.name}`,
                            severity: "success",
                          });
                          // Set up the interval
                          interval.current = setInterval(() => {
                            setOpenProgressBarDialog(false)
                            // redirectToPage(CSVInformationUI.tag);
                            clearInterval(interval.current);
                          }, 3000);
                        }
                    
                    
                      } catch (error) {
                        console.error("Error fetching data:", error);
                      }
                    };
                    
                    // Set up the interval
                    interval.current = setInterval(() => {
                      fetchData();
                    }, 4000);
            
                    // try {
                    //   const response: any =
                    //     await PrintingService.createBulkT4PdfEmail(
                    //       clientId,
                    //       "",
                    //       taxYear
                    //     );
                    //   console.log("response000", response);
                    //   if (response.status === 400) {
                    //     setSnackbarData({
                    //       open: true,
                    //       message: response.data.message
                    //         ? response.data.message
                    //         : "Something went wrong.",
                    //       // message: "Please Unmask sensitive data for PDF printing.",
                    //       severity: "error",
                    //     });
                    //   } else if (response.status === 201) {
                    //     setSnackbarData({
                    //       open: true,
                    //       message: response.data.message
                    //         ? response.data.message
                    //         : "Email send Successfully.",
                    //       severity: "success",
                    //     });
                    //   } else {
                    //     setSnackbarData({
                    //       open: true,
                    //       message: "Something Went Wrong.",
                    //       severity: "error",
                    //     });
                    //   }
                    //   setIsLoadingData(false);
                    // } catch (err) {
                    //   console.log("err", err);
                    // }
                    } catch (err) {
                      console.log("err", err);
                    }
                  // }
                  setIsLoadingData(false);
                // } catch (err: any) {
                //   setIsLoadingData(false);
                //   alert(err);
                // }
              } else {
                setIsLoadingData(false);
                setSnackbarData({
                open: true,
                message: (
                  <>
                    Please load Audit in the menu, press Run T4 Audit and clear all audit items (Audit Filing and PIER Review) to generate documents.{" "}
                    <a
                      href="https://appv3.taxslips.com/#/audit/filing"
                      style={{
                        color: "#aa1010",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to view
                    </a>
                  </>
                ),
                severity: "error",
                autoHide: false,
              });
              }

              } else if (
                response?.id != null &&
                previousYearInvoiceResponse?.id != null
              ) {
                console.log("one");
                setInvoiceData([previousYearInvoiceResponse, response]);
                setInvoiceDialogOpen(true);
              } else if (
                previousYearInvoiceResponse?.id != null &&
                response?.message === "Slips not found for invoice."
              ) {
                console.log("two");
                setInvoiceData([previousYearInvoiceResponse]);
                setInvoiceDialogOpen(true);
              } else if (
                response?.id != null &&
                previousYearInvoiceResponse?.message === "Slips not found for invoice."
              ) {
                console.log("three");
                setInvoiceData([response]);
                setInvoiceDialogOpen(true);
              }else {
                setSnackbarData({
                  open: true,
                  message: "Something went wrong.",
                  severity: "error",
                });
              }
              setIsLoadingData(false);
            } catch (err) {
              console.log("err", err);
              setIsLoadingData(false);
            }
            } catch (err) {
              console.log("err", err);
              setIsLoadingData(false);
            }
          } else {
          try {
            const response: any = await ReportsService.createInvoice(
              clientId,
              taxYear
            );
            setIsLoadingData(false);
            console.log("responseresponse", response);
            if (response.message === "Slips not found for invoice.") {
              setIsLoadingData(true);
              // try {
              //   const filingAuditResponse: any =
              //     await T4SlipService.getT4AuditFiling(clientId, taxYear);
              //   console.log("filingAuditResponse", filingAuditResponse);
              //   if (
              //     filingAuditResponse &&
              //     filingAuditResponse.general_filling &&
              //     filingAuditResponse.general_filling.length > 0
              //   ) {
              //     console.log(
              //       "filingAuditResponse.general_filling",
              //       filingAuditResponse.general_filling
              //     );
              //     setSnackbarData({
              //       open: true,
              //       message: (
              //         <>
              //           Please load Audit in the menu and clear all audit items
              //           (Audit Filing and PIER Review) to send Email.{" "}
              //           <a
              //             href="https://appv3.taxslips.com/#/audit/filing"
              //             style={{
              //               color: "#aa1010",
              //               textDecoration: "none",
              //               fontWeight: "bold",
              //             }}
              //           >
              //             Click here to view
              //           </a>
              //         </>
              //       ),
              //       severity: "error",
              //       autoHide: false,
              //     });
              //   } else if (
              //     filingAuditResponse &&
              //     filingAuditResponse.t4_filling &&
              //     filingAuditResponse.t4_filling.length > 0
              //   ) {
              //     console.log(
              //       "filingAuditResponse.t4_filling",
              //       filingAuditResponse.t4_filling
              //     );
              //     setSnackbarData({
              //       open: true,
              //       message: (
              //         <>
              //           Please load Audit in the menu and clear all audit items
              //           (Audit Filing and PIER Review) to send Email.{" "}
              //           <a
              //             href="https://appv3.taxslips.com/#/audit/filing"
              //             style={{
              //               color: "#aa1010",
              //               textDecoration: "none",
              //               fontWeight: "bold",
              //             }}
              //           >
              //             Click here to view
              //           </a>
              //         </>
              //       ),
              //       severity: "error",
              //       autoHide: false,
              //     });
              //   }
                // else if(filingAuditResponse && filingAuditResponse.t4_warnings && filingAuditResponse.t4_warnings.length > 0){
                //   // Check if a string contains the word "email"
                //   const containsEmail = (str: string) => str.toLowerCase().includes('email is missing');
                //   const containsDOB = (str: string) => str.toLowerCase().includes('date of birth is missing');

                //   console.log("containsEmail",containsEmail)

                //   // Iterate over the array and log the string if it contains the word "email"
                //   filingAuditResponse && filingAuditResponse.t4_warnings.forEach((str:any) => {
                //     if (containsEmail(str)) {
                //       console.log("strstrstr email",str);
                //       setSnackbarData({
                //         open: true,
                //         message: (
                //           <>
                //             {str}{' '}
                //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
                //                                             textDecoration:"none",
                //                                             fontWeight:"bold"}}>Click here to view</a>
                //           </>
                //         ),
                //         severity: "error",
                //         autoHide:false
                //       });
                //     } else if (containsDOB(str)) {
                //       console.log("strstrstr dob",str);
                //       setSnackbarData({
                //         open: true,
                //         message: (
                //           <>
                //             {str}{' '}
                //             <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
                //                                             textDecoration:"none",
                //                                             fontWeight:"bold"}}>Click here to view</a>
                //           </>
                //         ),
                //         severity: "error",
                //         autoHide:false
                //       });
                //     }
                //   });
                // setSnackbarData({
                //   open: true,
                //   message: (
                //     <>
                //       Please load Audit in the menu and clear all audit items (Audit Filing and PIER Review) to send Email.{' '}
                //       <a href="https://appv3.taxslips.com/#/audit/filing" style={{color:"#aa1010",
                //                                       textDecoration:"none",
                //                                       fontWeight:"bold"}}>Click here to view</a>
                //     </>
                //   ),
                //   severity: "error",
                //   autoHide:false
                // });
                // }
                // else {
              if(t4SlipIds && t4SlipIds.generalAuditStatus !== null && t4SlipIds.generalAuditStatus === true &&
                t4SlipIds && t4SlipIds.auditStatus !== null &&  t4SlipIds.auditStatus === true){
    
                  // const ids_list = slipList?.items.length === newData.length ? 
                  // t4SlipIds.slipIds
                  //   .map((ids: any) => `slip_ids=${ids}`)
                  //   .join("&") :
                  //   newData
                  //   .map((ids: any) => `slip_ids=${ids}`)
                  //   .join("&");
                  // const pdfIds: any = ids_list.replace(/^&+|&+$/g, "");
                  const ids_list = 
                  (slipList?.items?.length === newData.length) 
                      ? {items:t4SlipIds.slipIds} 
                      : {items:newData};
                      console.log("slipList?.items?.length === newData.length ",slipList?.items?.length === newData.length)
                      console.log("ids_list t4SlipIds.slipIds ",t4SlipIds.slipIds)
                      console.log("ids_list newData",newData)
                      console.log("ids_list",ids_list)
                  
                  setIsLoadingData(true);
                  try {
                    const tempResponse: any =
                      await T4SlipService.submitTemporaryData(
                        ids_list
                      );
                  const SubmitActualDataApi = async () => {
                    try {
                      const response: any =
                      await PrintingService.createBulkT4PdfEmail(
                        clientId,
                        tempResponse.id,
                        taxYear
                      );
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  };
                  SubmitActualDataApi();
                  setSnackbarData({
                    open: true,
                    message: "Email sending started!",
                    severity: "success",
                  });
                  setOpenProgressBarDialog(true)
                  //manage progress bar
                  let _val = percentageValue;
                  let consecutiveSameValueCount = 0; // Counter for consecutive same values
                  let lastAdjustedValue:any = null; // To track the last value
                  const fetchData = async () => {
                    try {
                      const response: any = await T4SlipService.temporaryItemsCount(tempResponse.id);
                      console.log("12345789", response);
                      console.log("12345789ff", response.fail);
                      console.log("12345789tt", tempResponse.totalFetched);
                      _val = Math.floor((response.counter / tempResponse.totalFetched) * 100); 
                      console.log("_val_val",_val)
                      setPercentageValue(_val);
                      // Check for consecutive same values
                      if (response.counter === lastAdjustedValue) {
                        consecutiveSameValueCount++;
                      } else {
                          consecutiveSameValueCount = 0; // Reset if value changes
                      }
                      lastAdjustedValue = response.counter;

                      if (consecutiveSameValueCount >= 4) {
                          console.log("Same value detected for 4 iterations, reloading screen.");
                          setSnackbarData({
                            open: true,
                            message: `Something went wrong, please try again later.`,
                            severity: "error",
                          });
                          setTimeout(()=>{
                            window.location.reload();
                          },2000)
                      }
                  
                      if (response.counter >= tempResponse.totalFetched) {
                        clearInterval(interval.current);
                        setPercentageValue(100)
                        
                        let totalSuccessfullImportedItems = response.counter - response.fail
                        setSnackbarData({
                          open: true,
                          message: `Email sent successfully to ${totalSuccessfullImportedItems} employee(s) except following. ${response.name}`,
                          severity: "success",
                        });
                        // Set up the interval
                        interval.current = setInterval(() => {
                          setOpenProgressBarDialog(false)
                          // redirectToPage(CSVInformationUI.tag);
                          clearInterval(interval.current);
                        }, 3000);
                      }
                  
                  
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  };
                  
                  // Set up the interval
                  interval.current = setInterval(() => {
                    fetchData();
                  }, 4000);
          
                  // try {
                  //   const response: any =
                  //     await PrintingService.createBulkT4PdfEmail(
                  //       clientId,
                  //       "",
                  //       taxYear
                  //     );
                  //   console.log("response000", response);
                  //   if (response.status === 400) {
                  //     setSnackbarData({
                  //       open: true,
                  //       message: response.data.message
                  //         ? response.data.message
                  //         : "Something went wrong.",
                  //       // message: "Please Unmask sensitive data for PDF printing.",
                  //       severity: "error",
                  //     });
                  //   } else if (response.status === 201) {
                  //     setSnackbarData({
                  //       open: true,
                  //       message: response.data.message
                  //         ? response.data.message
                  //         : "Email send Successfully.",
                  //       severity: "success",
                  //     });
                  //   } else {
                  //     setSnackbarData({
                  //       open: true,
                  //       message: "Something Went Wrong.",
                  //       severity: "error",
                  //     });
                  //   }
                  //   setIsLoadingData(false);
                  // } catch (err) {
                  //   console.log("err", err);
                  // }
                  } catch (err) {
                    console.log("err", err);
                  }
                // }
                setIsLoadingData(false);
              // } catch (err: any) {
              //   setIsLoadingData(false);
              //   alert(err);
              // }
            } else {
              setIsLoadingData(false);
              setSnackbarData({
              open: true,
              message: (
                <>
                  Please load Audit in the menu, press Run T4 Audit and clear all audit items (Audit Filing and PIER Review) to generate documents.{" "}
                  <a
                    href="https://appv3.taxslips.com/#/audit/filing"
                    style={{
                      color: "#aa1010",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Click here to view
                  </a>
                </>
              ),
              severity: "error",
              autoHide: false,
            });
            }
            } else if (response && response.id !== null) {
              setInvoiceData([response]);
              setInvoiceDialogOpen(true);
            } else {
              setSnackbarData({
                open: true,
                message: "Something went wrong.",
                severity: "error",
              });
            }
            setIsLoadingData(false);
          } catch (err) {
            console.log("err", err);
          }
        }
      }
    }
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusValue(event.target.value as string);
    setApiAction("updateStatus");
    setDialogOpen(true);
  };

  const onUnPaidClickHandler = (clickedRow: any, tag:string) => {
    setIsLoadingData(true);
    // setTimeout(() => {
    //   setIsLoadingData(false);
    // }, 2500);
    const fetchData = async () => {
      try {
        const response: any =
          await ReportsService.paypalIntegrationOfUnpaidInvoice(
            id,
            clickedRow.refNo,
            tag
          );
        setIsLoadingData(false);
        console.log("responseData111-----------------", response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const onClickInvoiceNoHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  useEffect(() => {
    // const data = ["my email is starts with zee", "here is last letters 645"];
    // // Check if a string contains the word "email"
    // const containsEmail = (str: string) => str.toLowerCase().includes('email');
    // // Iterate over the array and log the string if it contains the word "email"
    // data.forEach((str) => {
    //   if (containsEmail(str)) {
    //     console.log("strstrstr",str);
    //   }
    // });
  }, []);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: T4_LIST_SCREEN_SLUG,
        companyName: clientName,
        activityType:
          "activity_type=delete&activity_type=emailed&activity_type=print&activity_type=import_xml&activity_type=import_csv",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  const image =
    "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";

  return slipList && slipList.items.length === 0 && zeroClient === true ? (
    <EmptyList
      showImage={true}
      imagePath={image}
      heading={`There's nothing! Add T4 Slip to proceed`}
      content={`You can add t4 slip by directly clicking on add button or by importing csv/xml file.`}
      testId={"t4slip-empty-list"}
      showAddButton={true}
      addButtonLabel={`Add T4 Slip`}
      navigateToAdd={"/taxslip/t4/setup"}
      showImportButton={true}
      importButtonLabel="Import CSV"
      navigateToImport={"/taxslip/t4/import"}
      showImportXmlButton={true}
      importXmlButtonLabel="Import XML"
      navigateToImportXml={"/taxslip/t4/import-xml"}
    />
  ) : (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
        <BackdropCircularProgress
          open={
            isBulkDeleting ||
            isDeleting ||
            isLoadingData ||
            // isLoadingAdjustments ||
            isLoadingActivityLogData ||
            isLoadingActivityLogData ||
            loadingSlipIds
          }
          testId="T4 Slip List"
        />
        <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={
            bulkT4SlipsDelete === true
              ? deleteBulkT4SlipsField
              : deleteT4SlipField
          }
          cancelActionHandler={onCloseHandler}
          testId="T4-confirmation-dialog"
        />
        {/* <EnhancedDataGrid
        columns={slipList ? slipList.headerColumns : []}
        rows={slipList && t4SlipListUpdatedItems ? t4SlipListUpdatedItems : []}
        selectionEnabled={true}
        autoHeight={true}
        allowSearching={true}
        onSearchButtonClicked={onSearchButtonClicked}
        searchFieldPlaceholder={"Search by Employee Name..."}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        selectionToolbar={selectionToolbar}
        selectedRowsValues={(value:any)=>setSelectedRowsValues(value)}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        // initialPage={0}
        initialPageSize={INITIAL_PAGE_SIZE}
        disableColumnMenu={true}
        showRowActionsColumn={true}
        autoPageSize={false} //
        showIndexColumn={true}
        testId="resource-list-grid"
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onRequestParamsChange={onRequestParamsChange}
        onSearchCrossClicked={(value) => setzeroClient(value)}
        totalRowsCount={slipList?.total}
        isLoading={isLoading || isAxiosCallLoading}
        rowIdGetter={(row) => row.id}
        searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
        shouldMask={false}
      /> */}

        <PrimeDataGrid
          columns={slipList ? slipList.headerColumns : []}
          rows={
            slipList && t4SlipListUpdatedItems ? t4SlipListUpdatedItems : []
          }
          showIndexColumn={true}
          showChecboxColumn={true}
          showSettingColumn={true}
          onRowEditClicked={onRowEditClicked}
          onRowDeleteClicked={onRowDeleteClicked}
          isLoading={isLoading || isAxiosCallLoading}
          onSnackbarMessageData={(value: any) => setSnackbarData(value)}
          totalRecords={slipList && slipList.total ? slipList.total : 0}
          initialPageSize={INITIAL_PAGE_SIZE}
          rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
          onRequestParamsChange={onRequestParamsChange}
          selectionToolbar={selectionToolbar}
          selectedRowsValues={(value: any) => setSelectedRowsValues(value)}
          searchFieldPlaceholder={"Search by Employee Name..."}
          changeLoginForGetEmptyItemsBySearchQuery={(value: any) =>
            setzeroClient(value)
          }
        />
        {/* invoice dialogu open if any transaction is unPaid */}
        <EnhancedDialog
          dialogTitle="Invoice(s) Due"
          key="dialog-box"
          testId="dialog"
          onCloseHandler={onCloseHandler}
          open={openInvoiceDialog}
          dialogContent={
            <InvoiceDialogPanel
              invoiceData={invoiceData}
              onUnPaidClickHandler={onUnPaidClickHandler}
              onClickInvoiceNoHandler={onClickInvoiceNoHandler}
            />
          }
          dialogAction={
            <Grid>
              <Button autoFocus variant="contained" onClick={onCloseHandler}>
                Cancel
              </Button>
            </Grid>
          }
        />

        {/* Email Send Dialog */}
        <EnhancedDialog
            dialogTitle={`Email sending`}
            key="dialog-box-1"
            testId="dialog"
            onCloseHandler={onCloseHandler}
            showCloseButton={false}
            open={openProgressBarDialog}
            dialogContent={
              <ProgressBar 
                value={percentageValue}
                pt={{
                    value: { style: { background: 'linear-gradient(to right, #8e2de2, #4a00e0)' } }
                }}
              ></ProgressBar>
            }
            dialogAction={
              <></>
            }
          />
      </Grid>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"T4-list-snackbar"}
        open={snackbarData.open}
        autoHide={snackbarData.autoHide}
      />
    </>
  );
};
