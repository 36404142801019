import { AxiosResponse } from 'axios';
import { IFixedAndMetaField } from 'common/FlexFieldsUtils';
import http from 'common/http-common';
import { IClient } from 'models/client';
import { IVendorListPayload, IVendorRequest, IVendorResponse } from 'models/vendor';
import { CLIENT_SETUP_FORM, CLIENT_SETUP_FORM_FOR_ADMIN_USER } from 'screensLayoutData/client';

import { IAPIKeysRequest, IAPIKeysResponse } from '../models/ApiKeys';
import { IResponse, IServerError } from '../models/common';
import { IEmployeeListPayload, IEmployeeRequest, IEmployeeResponse } from '../models/employee';
import { GridSize } from '@mui/material';
import { DataType, FieldType } from '@websential/cosmic';
import { INITIAL_PAGE_SIZE } from 'common/constants';

export const ClientService = {
  generate: async (
    data: IAPIKeysRequest
  ): Promise<IAPIKeysRequest | IAPIKeysResponse | IServerError | IResponse> => {
    const response: AxiosResponse<IAPIKeysRequest> = await http.post<IAPIKeysRequest>(
      "/accounts/client/api/keys/",
      data
    );
    if (response.status === 200) return response.data;
    else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getEmployees: async (
    data: IEmployeeRequest
  ): Promise<
    IEmployeeResponse | IEmployeeListPayload | IServerError | IResponse
  > => {
    const response: AxiosResponse<IEmployeeListPayload> = await http.get<IEmployeeListPayload>(
      // TODO: update this url when employee api deployed
      // "accounts/master/data/setup/Employee/?client_ids=" + data.client_id
      "/v1/accountants/"
    );
    if (response.status === 200) return response.data;
    else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getVendors: async (
    data: IVendorRequest
  ): Promise<
    IVendorResponse | IVendorListPayload | IServerError | IResponse
  > => {
    const response: AxiosResponse<IVendorListPayload> = await http.get<IVendorListPayload>(
      // TODO: update this url when vendor api deployed
      // "accounts/master/data/setup/Vendor/?client_ids=" + data.client_id
      "/v1/accountants/"
    );
    if (response.status === 200) return response.data;
    else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getSetupLayout: async (isAdminUser:number): Promise<any> => {
    let response: any = isAdminUser !== 1 ? CLIENT_SETUP_FORM : CLIENT_SETUP_FORM_FOR_ADMIN_USER;
    return new Promise(resolve => setTimeout(() => resolve(response), 1000));
  },
  getById: async (id: string | undefined): Promise<IClient | IServerError | IResponse | null | any> => {
    if (!id) return null;
    const response: AxiosResponse<IClient> = await http.get<IClient>(
      `/v1/clients/${id}/`);

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  create: async (requestPayload: any): Promise<IClient | IServerError | IResponse> => {
    if (requestPayload.id) {
      console.log('requestPayload ==>> update request', requestPayload);
      const response: AxiosResponse<IClient> = await http.put<IClient>(
        `/v1/clients/${requestPayload.id}/`, requestPayload);

      if (response.status === 200) {
        return {
          status: true,
          message: "Data has been updated successfully!",
          error: false,
        };
      } else if (response.status === 400){
        return response.data
      } else if (response.status === 401){
        // let path = '/logout'
        // navigate(path)
        window.location.href = '/logout';
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }

    console.log('requestPayload ==>> add request', requestPayload);
    const response: AxiosResponse<IClient> = await http.post<IClient>(
      '/v1/clients/', requestPayload);

      console.log("responseop",response)
    if (response.status === 201) {
        return {
          status: true,
          message: "Data has been submitted successfully!",
          error: false,
        };
      } else if (response.status === 400){
        return response.data
      } else if (response.status === 401){
        window.location.href = '/logout';
      }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  deleteById: async (
    clientId: number
  ): Promise<string | IServerError | IResponse> => {
    console.log("deleteById", clientId);
    // return new Promise(resolve => setTimeout(() => resolve("Flex Field has been deleted successfully"), 1000));
    const response: AxiosResponse<string> = await http.delete<string>(
      `/v1/clients/${clientId}/`
    );

    if (response.status === 204) {
      return "Client has been deleted successfully";
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getClientCategories: async (
    screenId: string | number
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse =
      await http.get(
        `/v1/flex-fields/screen-fields?screen_name=${screenId}`
      );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
    // const fixedResponse: any = {
    //   page: 1,
    //   size: INITIAL_PAGE_SIZE,
    //   total: 2,
    //   items: [
    //     // {
    //     //   fieldLabel: "Province",
    //     //   fieldId: "provinceId",
    //     //   fieldType: FieldType.DropDownList,
    //     //   placeholder: "",
    //     //   fieldValue: "Alberta",
    //     //   fieldInternalValue: "alberta",
    //     //   // fieldValue: "Alberta,British Columbia,Manitoba",
    //     //   // fieldInternalValue: "alberta,british columbia,manitoba",
    //     //   id: 11,
    //     //   dataType: DataType.Object,
    //     //   fieldFormat: "",
    //     //   defaultValue: "",
    //     //   labelPosition: "left",
    //     //   labelColumn: 4 as GridSize,
    //     //   fieldColumn: 8 as GridSize,
    //     //   notes: "",
    //     //   minValue: "",
    //     //   maxValue: "",
    //     //   parent: null,
    //     //   parentValue: "",
    //     //   parentRelation: null,
    //     //   isFixed: true,
    //     //   isRequired: true,
    //     //   isCategory: true,
    //     //   isHide: false,
    //     //   readOnly: false,
    //     //   displayMasked: false,
    //     //   uppercase: false,

    //     //   fieldClass: "",
    //     //   validationFormula: "",
    //     //   comparison: [],
    //     //   employeeAccess: 0,
    //     //   variableValue: null,
    //     //   systemField: false,
    //     //   apiPath: null,
    //     //   defualtCategory: false,
    //     //   sequence: 1,
    //     // },
    //     // {
    //     //   fieldLabel: "Province",
    //     //   fieldId: "provinceId",
    //     //   fieldType: FieldType.DropDownList,
    //     //   placeholder: "",
    //     //   fieldValue: "Alberta",
    //     //   fieldInternalValue: "alberta",
    //     //   // fieldValue: "Alberta,British Columbia,Manitoba",
    //     //   // fieldInternalValue: "alberta,british columbia,manitoba",
    //     //   id: 12,
    //     //   dataType: DataType.Object,
    //     //   fieldFormat: "",
    //     //   defaultValue: "",
    //     //   labelPosition: "left",
    //     //   labelColumn: 4 as GridSize,
    //     //   fieldColumn: 8 as GridSize,
    //     //   notes: "",
    //     //   minValue: "",
    //     //   maxValue: "",
    //     //   parent: null,
    //     //   parentValue: "",
    //     //   parentRelation: null,
    //     //   isFixed: true,
    //     //   isRequired: true,
    //     //   isCategory: true,
    //     //   isHide: false,
    //     //   readOnly: false,
    //     //   displayMasked: false,
    //     //   uppercase: false,

    //     //   fieldClass: "",
    //     //   validationFormula: "",
    //     //   comparison: [],
    //     //   employeeAccess: 0,
    //     //   variableValue: null,
    //     //   systemField: false,
    //     //   apiPath: null,
    //     //   defualtCategory: false,
    //     //   sequence: 1,
    //     // },
    //     // {
    //     //   id: 5,
    //     //   fieldLabel: "Country",
    //     //   fieldId: "countryId",
    //     //   fieldType: FieldType.DropDownList,
    //     //   placeholder: "",
    //     //   fieldValue: "Canada",
    //     //   fieldInternalValue: "1",
    //     //   dataType: DataType.Object,
    //     //   fieldFormat: "",
    //     //   defaultValue: "1",
    //     //   labelPosition: "left",
    //     //   labelColumn: 4 as GridSize,
    //     //   fieldColumn: 8 as GridSize,
    //     //   notes: "",
    //     //   minValue: "",
    //     //   maxValue: "",
    //     //   parent: null,
    //     //   parentValue: "",
    //     //   parentRelation: null,
    //     //   isFixed: true,
    //     //   isRequired: true,
    //     //   isCategory: true,
    //     //   isHide: false,
    //     //   readOnly: false,
    //     //   displayMasked: false,
    //     //   uppercase: false,

    //     //   fieldClass: "",
    //     //   validationFormula: "",
    //     //   comparison: [],
    //     //   employeeAccess: 0,
    //     //   variableValue: null,
    //     //   systemField: false,
    //     //   apiPath: null,
    //     //   defualtCategory: true,
    //     //   sequence: 3,
    //     // },
    //     // {
    //     //   id: 6,
    //     //   fieldLabel: "Country",
    //     //   fieldId: "countryId",
    //     //   fieldType: FieldType.DropDownList,
    //     //   placeholder: "",
    //     //   fieldValue: "Canada",
    //     //   fieldInternalValue: "1",
    //     //   dataType: DataType.Object,
    //     //   fieldFormat: "",
    //     //   defaultValue: "1",
    //     //   labelPosition: "left",
    //     //   labelColumn: 4 as GridSize,
    //     //   fieldColumn: 8 as GridSize,
    //     //   notes: "",
    //     //   minValue: "",
    //     //   maxValue: "",
    //     //   parent: null,
    //     //   parentValue: "",
    //     //   parentRelation: null,
    //     //   isFixed: true,
    //     //   isRequired: true,
    //     //   isCategory: true,
    //     //   isHide: false,
    //     //   readOnly: false,
    //     //   displayMasked: false,
    //     //   uppercase: false,

    //     //   fieldClass: "",
    //     //   validationFormula: "",
    //     //   comparison: [],
    //     //   employeeAccess: 0,
    //     //   variableValue: null,
    //     //   systemField: false,
    //     //   apiPath: null,
    //     //   defualtCategory: false,
    //     //   sequence: 3,
    //     // }
    //   ],
    //   keyField: {
    //     field: "id",
    //     templateUrl: "/data-roles/setup/id",
    //   },
    //   headerColumns: [
    //     {
    //       field: "roleName",
    //       headerName: "Data Role Name",
    //       description: "Data role name",
    //       flex: 1,
    //       hide: false,
    //       hideable: false,
    //       sortable: true,
    //       // "sortingOrder": "asc",
    //       resizable: false,
    //       type: "string",
    //       align: "left",
    //       headerAlign: "left",
    //       hideSortIcons: false,
    //       disableColumnMenu: true,
    //       disableReorder: true,
    //       disableExport: true,
    //       maskable: false,
    //       // "isFixed": true,
    //       sequence: 0,
    //     },
    //   ],
    // };
    // return new Promise((resolve) =>
    //   setTimeout(() => resolve(fixedResponse), 1000)
    // );
  },
  getClientCategoriesAssingedPanels: async (
    requestPayload:any
  ): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse =
    await http.post(
      `/v1/flex-field-fieldsets/panel-flex-fields`,
      requestPayload
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
    // if (requestPayload[0].categoryId !== "" && requestPayload[0].categoryValue === "alberta") {
    //   const fixedResponse: any = {
    //     panels: [
    //       {
    //         label: "Create Client",
    //         categoryFieldId: "",
    //         categoryFieldValue: "",
    //         sequence: 1,
    //         gridColumns: 11 as GridSize,
    //         fields: [
    //           {
    //             id: 1,
    //             fieldLabel: "Registration no.",
    //             fieldId: "clientIdentityNo",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Registration no.",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 1,
    //           },
    //           {
    //             id: 2,
    //             fieldLabel: "Legal Name",
    //             fieldId: "clientName",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Legal Name",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 2,
    //           },
    //           {
    //             id: 3,
    //             fieldLabel: "Operating Name",
    //             fieldId: "operatingName",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Operating Name",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 3,
    //           },
    //           {
    //             id: 4,
    //             fieldLabel: "Creation Date",
    //             fieldId: "createdOn",
    //             fieldType: FieldType.Date,
    //             placeholder: "Current Date",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 4,
    //           },
    //           {
    //             id: 5,
    //             fieldLabel: "Employees",
    //             fieldId: "employees",
    //             fieldType: FieldType.DropDownList,
    //             placeholder: "",
    //             fieldValue:
    //               "1-10,11-25,26-50,51-100,101-200,201-300,301-500,501-1000,1001-2500,2500+",
    //             fieldInternalValue: "1,11,26,51,101,201,301,501,1001,2501",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "26",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 5,
    //           },
    //           {
    //             id: 6,
    //             fieldLabel: "Account Manager",
    //             fieldId: "accountManager",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Account Manager",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 6,
    //           },
    //           {
    //             id: 7,
    //             fieldLabel: "Business no.",
    //             fieldId: "businessNo",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "999999999RP9999",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{000000000RP0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: true,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 7,
    //           },
    //           {
    //             id: 8,
    //             fieldLabel: "Reduce El Account no.",
    //             fieldId: "reducedBusinessNo",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "000000000AA0000",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{000000000AA0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: true,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 8,
    //           },
    //           {
    //             id: 9,
    //             fieldLabel: "Access Code",
    //             fieldId: "accessCode",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Access Code",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 9,
    //           },
    //           {
    //             id: 10,
    //             fieldLabel: "Payment Method",
    //             fieldId: "payment_method",
    //             fieldType: FieldType.DropDownList,
    //             placeholder: "",
    //             fieldValue: "paypal,Direct method",
    //             fieldInternalValue: "20,10",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 10,
    //           },
    //         ],
    //       },
    //       {
    //         label: "Address Info",
    //         categoryFieldId: "",
    //         categoryFieldValue: "",
    //         sequence: 2,
    //         gridColumns: 12 as GridSize,
    //         fields: [
    //           {
    //             id: 10,
    //             fieldLabel: "Address",
    //             fieldId: "addressline1",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Enter Address",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 1,
    //           },
    //           {
    //             id: 11,
    //             fieldLabel: "City",
    //             fieldId: "city",
    //             fieldType: FieldType.TextField,
    //             placeholder: "City",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 2,
    //           },
    //           {
    //             id: 12,
    //             fieldLabel: "Province",
    //             fieldId: "provinceId",
    //             fieldType: FieldType.DropDownList,
    //             placeholder: "",
    //             fieldValue: "Alberta,British Columbia,Manitoba",
    //             fieldInternalValue: "1,2,3",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 3,
    //           },
    //           {
    //             id: 12,
    //             fieldLabel: "Operating in Provinces",
    //             fieldId: "operatingInProvinces",
    //             fieldType: FieldType.MultiSelectionDropDownList,
    //             placeholder: "",
    //             fieldValue: "Alberta,British Columbia,Manitoba",
    //             fieldInternalValue: "1,2,3",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 4,
    //           },
    //           {
    //             id: 5,
    //             fieldLabel: "Country",
    //             fieldId: "countryId",
    //             fieldType: FieldType.DropDownList,
    //             placeholder: "",
    //             fieldValue: "Canada",
    //             fieldInternalValue: "1",
    //             dataType: DataType.Object,
    //             fieldFormat: "",
    //             defaultValue: "1",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: true,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 5,
    //           },
    //           {
    //             id: 8,
    //             fieldLabel: "Post Code",
    //             fieldId: "postal_code",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "A9A9A9",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{a0a0a0}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: true,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 6,
    //           },
    //           {
    //             id: 4,
    //             fieldLabel: "Email",
    //             fieldId: "email",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Email",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: null,
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: true,
    //             isRequired: true,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 7,
    //           },
    //           {
    //             id: 10,
    //             fieldLabel: "Phone (Home)",
    //             fieldId: "phone1",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "(999) 999-9999",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{(000) 000-0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 8 as GridSize,
    //             fieldColumn: 8 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 8,
    //           },
    //           {
    //             id: 11,
    //             fieldLabel: "Ext",
    //             fieldId: "ext1",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "000",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 4 as GridSize,
    //             fieldColumn: 4 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 9,
    //           },
    //           {
    //             id: 10,
    //             fieldLabel: "Phone (Personal)",
    //             fieldId: "phone2",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "(999) 999-9999",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{(000) 000-0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 8 as GridSize,
    //             fieldColumn: 8 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 10,
    //           },
    //           {
    //             id: 11,
    //             fieldLabel: "Ext",
    //             fieldId: "ext2",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "000",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 4 as GridSize,
    //             fieldColumn: 4 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 11,
    //           },
    //           {
    //             id: 10,
    //             fieldLabel: "Fax",
    //             fieldId: "fax",
    //             fieldType: FieldType.MaskedTextField,
    //             placeholder: "(999) 999-9999",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "{(000) 000-0000}",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 12,
    //           },
    //           {
    //             id: 12,
    //             fieldLabel: "Website",
    //             fieldId: "website",
    //             fieldType: FieldType.TextField,
    //             placeholder: "Website",
    //             fieldValue: "",
    //             fieldInternalValue: "",
    //             dataType: DataType.String,
    //             fieldFormat: "",
    //             defaultValue: "",
    //             labelPosition: "left",
    //             labelColumn: 12 as GridSize,
    //             fieldColumn: 12 as GridSize,
    //             notes: "",
    //             minValue: "",
    //             maxValue: "",
    //             parent: null,
    //             parentValue: "",
    //             parentRelation: null,
    //             isFixed: false,
    //             isRequired: false,
    //             isCategory: false,
    //             isHide: false,
    //             readOnly: false,
    //             displayMasked: false,
    //             uppercase: false,

    //             fieldClass: "",
    //             validationFormula: "",
    //             comparison: [],
    //             employeeAccess: 0,
    //             variableValue: null,
    //             systemField: false,
    //             apiPath: null,
    //             sequence: 13,
    //           },
    //         ],
    //       },
    //     ],
    //   };
    //   return new Promise((resolve) =>
    //     setTimeout(() => resolve(fixedResponse), 1000)
    //   );
    // }
  },
};
