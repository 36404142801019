import { ComponentState } from "common/enums";
import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AccountantService, AuthService } from "services";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { setSelectedClient, setSelectedClientName, setUserMenuOpen } from "store/actions";
import { clearError } from "store/actions/viewStateActions";
import { IAppState } from "store/reducers";
import { format } from "date-fns";

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { BackdropCircularProgress, DeleteConfirmationDialog, EmptyList, EnhancedSnackbar, IResponse, IServerError, RouterLinkButton } from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import {
  CLIENT_LIST_SCREEN_SLUG,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
} from "common/constants";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IClientList {}
const CLIENT_LIST_QUERY_KEY = "clients-list";


const useClients = (accountantId: string,isAdminUser:number, params: IGetListRequest) =>
  useQuery(
    ["clients-list", accountantId,isAdminUser, params],
    (data) => {
      console.log("@@@@@@=>useClients", data);
      return AccountantService.getClientsByAccountantId(accountantId,isAdminUser, params);
    },
    { refetchOnWindowFocus: false }
  );

export const ClientList: React.FC<IClientList> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAdminUser = useSelector((state: IAppState) => state.user.profile.isSuperStatus);
  const clientIdStore = useSelector((state: IAppState) => state.client.id);


     // for delete row Purpose
     const queryClient = useQueryClient();
     const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "client_name",
    order_direction: "asc",
    page: 1,
    size: 25,
  });

  const { id } = useSelector((state: IAppState) => state.accountant);
  const { isLoading, data } = useClients(String(id),isAdminUser, queryParams); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const clientList: any | null = data;
  console.log("dataData",data)
  console.log("clientListData",clientList)
  useEffect(()=>{
    if(clientList && clientList.items && clientList.items.length === 1 && zeroClient === true){
      // dispatch(setSelectedClient(clientList.items[0]));
      if(clientIdStore !== ""){
        let path = '/clients/dashboard'
        navigate(path)
        // dispatch(setSelectedClient(clickedRow));
    dispatch(setSelectedClientName(clientList.items[0]));
      }
    }else{
      console.log("length is larger List")
    }
      if(clientList && clientList.message && clientList.message.detail === "Invalid token or expired token."){
        let path = '/logout'
        navigate(path)
      }
  },[clientList])

  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);
  const [searchText, setSearchText] = useState({});
  const [zeroClient, setzeroClient] = useState(true);
  const [clientListUpdatedItems, setClientListUpdatedItems] =
    useState<any>(undefined);

  
  // for delete row
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [clientId, setClientId] = useState<number>(0);

  // Bulk Delete
const [bulkDelete, setBulkDelete] = useState<boolean>(false);
const [bulkClientId, setBulkClientId] = useState<any>({});
const [bulkClientDelete, setBulkClientDelete] = useState<boolean>(false);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    let dispatchParams = {
      search_query: requestParams.debouncedSearchQuery,
      order_by: "client_name",
      order_direction: "asc",
      page: requestParams.currentPage + 1,
      size: requestParams.pageSize,
    };
    if (requestParams.sort) {
      const [sortBy, sortDirection] = requestParams.sort.split(",");
      dispatchParams.order_by = toSnake(sortBy);
      dispatchParams.order_direction = sortDirection;
    }
    console.log("dispatchParams",dispatchParams)
    // setQueryParams(dispatchParams);
    if(dispatchParams.search_query === ''){
      setQueryParams(dispatchParams);
    }
    setSearchText(dispatchParams)
  }, []);

  const onSearchButtonClicked=()=>{
    setQueryParams(searchText)
    setzeroClient(false)
  }

  const onRowEditClicked = useCallback((params) => {
    // alert(`Edit row with ID: ${params.row.id} has been clicked!`);
    let path = `/clients/setup/${params.row.id}`;
    navigate(path);
  }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const callAction = () => {
    if (apiAction === "delete") {
      alert(`Delete row with ID: ${selectedIds} has been clicked!`);
      // TODO api call for delete
    } else if (apiAction === "updateStatus") {
      alert(`action need to trigger : ${apiAction}`);
      // TODO api call for update status
    }
    setDialogOpen(false);
  };

 // For Delete Row
 const deleteClientField = () => {
  if (singleDelete) {
    setSingleDelete(false);
    deleteClientById(clientId);
    setDialogOpen(false);
  }
};

const onRowDeleteClicked = useCallback((params) => {
  setDialogOpen(true);
  setSingleDelete(true);
  setApiAction("delete");
  setClientId(params.row.id);
  console.log("ClientId in Client List OnDeleteRowClicked function",params.row.id)
  // alert(`Delete row with ID: ${params.row.id} has been clicked!`);
}, []);

const onSnackbarClosed = (
  event: React.SyntheticEvent | Event,
  reason?: string
) => {
  if (reason === "clickaway") {
    return;
  }
  setSnackbarData(INITIAL_SNACKBAR_DATA);
};

const { isLoading: isDeleting, mutate: deleteClientById } = useMutation(
  AccountantService.delClient,
  {
    onSuccess: (res: string | IServerError | IResponse | any) => {
      console.log("res--",res)
      if (res) {
        // remove the cache of the Year fields since we just added a new field.
        setSnackbarData({
          open: true,
          message: res.message ? res.message : "Something went wrong.",
          severity: res.status === false ? "error" : "success",
        });

        return queryClient.invalidateQueries(
          CLIENT_LIST_QUERY_KEY
        );
      } else {
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      }
    },
    onError: (err) => {
      // TODO: customize the toast message based on the response
      setSnackbarData({
        open: true,
        message: "Something went wrong, try again later!",
        severity: "error",
      });
    },
  }
);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusValue(event.target.value as string);
    setApiAction("updateStatus");
    setDialogOpen(true);
  };

  const onKeyFieldClickHandler = (clickedRow: any) => {
    dispatch(setSelectedClient(clickedRow));
    dispatch(setSelectedClientName(clickedRow));
  };

  //bulk delete

  const { isLoading: isBulkDeleting, mutate: deleteBulkClients } =
    useMutation(AccountantService.delBulkClients, {
      onSuccess: (res: any | string | IServerError | IResponse) => {
        if (typeof res === "object") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res.message ? res.message : "Something went wrong.",
            severity: res.status === false ? "error" : "success",
          });

          setBulkClientDelete(false)
          return queryClient.invalidateQueries(CLIENT_LIST_QUERY_KEY);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
        setBulkClientDelete(false)
      },
      onError: (err:any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
        setBulkClientDelete(false)
      },
    });

  // For Bulk Delete
  const deleteBulkClientField = () => {
    if (bulkDelete) {
      setBulkDelete(false);
      deleteBulkClients(bulkClientId);
      setDialogOpen(false);
    }
  };

  const onRowMultipleDeleteClicked = (params: any) => {
    console.log("Params in onRowMultipleDeleteClicked ====== ", params);
    let bulkDeletePayload = {
      clientIds:{"clientIds":params}
    }
    setDialogOpen(true);
    setBulkDelete(true);
    setApiAction("delete");
    setBulkClientId(bulkDeletePayload);
    setBulkClientDelete(true);
  };

  const selectionToolbar = useCallback((selectedItems) => {
    setSelectedIds(selectedItems);
    if (selectedItems.length) {
      return (
        <Box sx={{ marginTop: theme.spacing(1) }}>
          {/* {"Bulk Action Drop Down List"} */}
          {/* <EnhancedDDLBulkAction
            label="Status"
            value={statusValue}
            onChangeHandler={handleChange}
            options={[
              { name: "Active", value: "1" },
              { name: "Inactive", value: "2" },
            ]}
            testId="status-dd"
          /> */}
          <Button
            onClick={() => onRowMultipleDeleteClicked(selectedItems)}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </Box>
      );
    }
    return (
      <Box sx={{ marginTop: theme.spacing(1) }}>
        <RouterLinkButton to="/clients/setup" text="Add Client" />
      </Box>
    );
  }, []);

  const getColumnMinWidth = (field:any) => {
    const maxContentWidth = Math.max(...clientList?.items.map((row:any) => String(row[field]).length * 8)); // Assuming an average character width of 8px
    return maxContentWidth + 16; // Add some padding
  };

  const columnsWithMinWidth = useMemo(() => {
    return clientList && clientList?.headerColumns.map((column:any) => ({
      ...column,
      minWidth: getColumnMinWidth(column.field),
    }));
  }, [clientList?.headerColumns]);

  console.log("columnsWithMinWidth",columnsWithMinWidth)

  //find the index of object from headerColumns to add link on given column
  // if (accountantList?.headerColumns) {
  //   const linkObjIndex = accountantList.headerColumns.findIndex(
  //     (obj: { field: any; }) => obj.field === accountantList.keyField.field
  //   );
  //   accountantList.headerColumns[linkObjIndex] = {
  //     field: accountantList.headerColumns[linkObjIndex].field,
  //     headerName: accountantList.headerColumns[linkObjIndex].headerName,
  //     flex: 1,
  //     renderCell: (params: any) => {
  //       var urlLink = accountantList.keyField.templateUrl;
  //       var newLink = urlLink.replace("{id}", params.row.id);

  //       return (
  //         <Link to={newLink} className={classes.textLink}>
  //           {params.row.accTitle}
  //         </Link>
  //       );
  //     },
  //   };
  //   const statusObjIndex = accountantList.headerColumns.findIndex(
  //     (obj: { field: string; }) => obj.field === "status"
  //   );
  //   accountantList.headerColumns[statusObjIndex] = {
  //     field: "status",
  //     headerName: "Status",
  //     flex: 1,
  //     width: 50,
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params: any) => (
  //       <Chip label={params.value} />
  //     ),
  //   };
  // }

  // if (isLoading) {
  //   return (
  //     <CircularProgress
  //       size={24}
  //       data-testid="circular-progress"
  //       className={classes.loader}
  //     />
  //   );
  // }

  const formatDate = (datee: any) => {
    const originalDate = new Date(datee);
    const formattedDate = format(originalDate, "MMM dd, yyyy");

    return formattedDate;
  };

    //defualt drawer options set in home 
    useEffect(() => {
      const homeDrawerOptionsOpen = {id:5000,text:"Home"}
      dispatch(setUserMenuOpen(homeDrawerOptionsOpen));

      if(clientList){
        const updatedItemsResponse = clientList && clientList.items && clientList.items.map((item:any) => ({
          ...item,
          createdDate: (item.createdDate !== "" && item.createdDate !== null && item.createdDate && item.createdDate.charAt(3) !== "*") ? formatDate(item.createdDate) : item.createdDate,
        }));
        console.log("updatedItemsResponse",updatedItemsResponse)
        setClientListUpdatedItems(updatedItemsResponse)
    }
  },[clientList])

  console.log("queryParams--",queryParams)
  console.log("clientList--",clientList)

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: CLIENT_LIST_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=delete",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  }, []);

  const image =
  "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";
  
  return clientList && clientList.items && clientList.items.length === 0 && zeroClient === true ? (
    <EmptyList 
    showImage={true}
    imagePath= {image}
    heading={`There's nothing! Add Client to proceed`} 
    content={`You can add client by directly clicking on add client button.`} 
    testId={"client-empty-list"} 
    showAddButton= {true}
    addButtonLabel={"Add Client"}
    navigateToAdd={"/clients/setup"}
    showImportButton={false}
    showImportXmlButton={false}
    />
  ) : (
    <>
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      // className={classes.list}
    >
      {/* <EnhancedDialog
        dialogTitle="Please Confirm"
        key="dialog-box"
        testId="dialog"
        onCloseHandler={onCloseHandler}
        open={openDialog}
        dialogContent={
          <Typography variant="subtitle1">Are you sure?</Typography>
        }
        dialogAction={
          <Grid>
            <Button
              autoFocus
              variant="contained"
              onClick={onCloseHandler}
              color="default"
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={callAction}
              color="primary"
              className={classes.button}
            >
              ok
            </Button>
          </Grid>
        }
      /> */}
      {/* <BackdropCircularProgress
          open={
            isLoading
          }
          testId="T4 Slip List"
        /> */}
      <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={bulkClientDelete === true ? deleteBulkClientField : deleteClientField}
          cancelActionHandler={onCloseHandler}
          testId="tax-profile-tab-confirmation-dialog"
        />
      <EnhancedDataGrid
        keyField={clientList ? clientList.keyField : undefined}
        onKeyFieldClickHandler={onKeyFieldClickHandler}
        columns={clientList && clientList.headerColumns ? columnsWithMinWidth : []}
        rows={clientList && clientList.items && clientListUpdatedItems && clientListUpdatedItems}
        selectionEnabled={true}
        autoHeight={true}
        allowSearching={true}
        onSearchButtonClicked={onSearchButtonClicked}
        searchFieldPlaceholder={"Search by Client Name..."}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        selectionToolbar={selectionToolbar}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        // initialPage={0}
        initialPageSize={INITIAL_PAGE_SIZE}
        disableColumnMenu={true}
        showRowActionsColumn={true}
        autoPageSize={false} //
        showIndexColumn={true}
        testId="client-list-grid"
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onRequestParamsChange={onRequestParamsChange}
        onSearchCrossClicked={(value) => setzeroClient(value)}
        totalRowsCount={clientList?.total}
        isLoading={isLoading || isDeleting || isBulkDeleting}
        rowIdGetter={(row) => row.id}
        searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
        shouldMask={false}
      />
    </Grid>
    <EnhancedSnackbar
          message={snackbarData.message}
          onCloseHandler={onSnackbarClosed}
          severity={snackbarData.severity}
          testId={`snackbar`}
          open={snackbarData.open}
        />
  </>
  );
  // switch (componentState) {
  //   case ComponentState.LOADING:
  //     return (
  //       <CircularProgress
  //         size={24}
  //         data-testid="circular-progress"
  //         className={classes.loader}
  //       />
  //     );
  //   case ComponentState.ERROR:
  //     return (
  //       <div>Error loading component data</div>
  //       // <ErrorState
  //       //   testId="error-container"
  //       //   message={message}
  //       //   onClick={() => {
  //       //     clearError();
  //       //     let dispatchParams = {
  //       //       search_query: "",
  //       //       order_by: "acc_title",
  //       //       order_direction: "asc",
  //       //       page: 1,
  //       //       size: 50,
  //       //     };
  //       //     dispatch(loadAccountantListRequest(dispatchParams));
  //       //   }}
  //       // />
  //     );
  //   case ComponentState.DATA:
  //     return emptyList ? (
  //       <EmptyAccountantList />
  //     ) : (
  //       <Grid
  //         item
  //         xs={12}
  //         sm={12}
  //         md={12}
  //         lg={12}
  //         xl={12}
  //         className={classes.list}
  //       >
  //         {/* <EnhancedDialog
  //           dialogTitle="Please Confirm"
  //           key="dialog-box"
  //           testId="dialog"
  //           onCloseHandler={onCloseHandler}
  //           open={openDialog}
  //           dialogContent={
  //             <Typography variant="subtitle1">Are you sure?</Typography>
  //           }
  //           dialogAction={
  //             <Grid>
  //               <Button
  //                 autoFocus
  //                 variant="contained"
  //                 onClick={onCloseHandler}
  //                 color="default"
  //                 className={classes.button}
  //               >
  //                 Cancel
  //               </Button>
  //               <Button
  //                 autoFocus
  //                 variant="contained"
  //                 onClick={callAction}
  //                 color="primary"
  //                 className={classes.button}
  //               >
  //                 ok
  //               </Button>
  //             </Grid>
  //           }
  //         /> */}
  //         <EnhancedDataGrid
  //           columns={accountantList ? accountantList.headerColumns : []}
  //           rows={accountantList?.items}
  //           selectionEnabled={true}
  //           autoHeight={true}
  //           allowSearching={true}
  //           rowsPerPageOptions={[10, 25, 50, 100]}
  //           selectionToolbar={selectionToolbar}
  //           onRowEditClicked={onRowEditClicked}
  //           onRowDeleteClicked={onRowDeleteClicked}
  //           initialPageSize={accountantList?.size}
  //           disableColumnMenu={true}
  //           showRowActionsColumn={true}
  //           autoPageSize={true}
  //           showIndexColumn={true}
  //           testId="accountant-list-grid"
  //           sortingMode="server"
  //           filterMode="server"
  //           paginationMode="server"
  //           onRequestParamsChange={onRequestParamsChange}
  //           totalRowsCount={accountantList?.total}
  //           isLoading={false}
  //           rowIdGetter={(row) => row.id}
  //           searchDebouncingDelay={2000}
  //           shouldMask={false}
  //         />
  //       </Grid>
  //     );
  //   default:
  //     return null;
  // }
};
