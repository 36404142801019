import {
  BILLING_PROFILE_SETUP_SCREEN_SLUG,
  DATE_FORMAT_API_PAYLOAD,
  GET_QUERY_KEY,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
} from "common/constants";
import dayjs from "dayjs";
import { IBillingProfileModel } from "models/billingProfile";
import { IResponse, IServerError } from "models/common";
import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BillingProfileService } from "services/BillingProfileService";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BackdropCircularProgress,
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  TwoColumnLayout,
} from "@websential/cosmic";

import {
  BillingProfileSetupFieldsPanel,
  BillingProfileSlabsFieldsPanel,
} from "./Panels";
import { BillingProfileSetupSchema } from "./validator";
import { Grid } from "@mui/material";
import { IDropDownListResponse } from "models";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch } from "react-redux";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IBillingProfileSetup extends ITest {}

interface IBillingProfile {
  id: string;
  label: string;
}

// const useGetBillingProfileDDL = () => {
//   return useQuery(
//     GET_QUERY_KEY.BILLING_PROFILE_DDL,
//     () => BillingProfileService.getDropDownList(),
//   { refetchOnWindowFocus: false }
//   );
// };

const useGetBillingProfileDDL = () =>
  useQuery(
    [GET_QUERY_KEY.BILLING_PROFILE_DDL],
    (data) => {
      return BillingProfileService.getDropDownList();
    },
    { refetchOnWindowFocus: false }
  );

const useGetById = (id: string | undefined) => {
  return useQuery(
    [GET_QUERY_KEY.GET_BILLING_PROFILE_BY_ID, id],
    () => BillingProfileService.getById(id),
    { refetchOnWindowFocus: false }
  );
};

export const BillingProfileSetup: FC<IBillingProfileSetup> = ({ testId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [loadingData, setLoadingData] = useState(false);
  // const [selectedBillingProfile, setSelectedBillingProfile] =
  //   useState<IBillingProfile>({
  //     id: "",
  //     label: "",
  //   });

  const { id } = useParams();
  const [editRecordId, setEditRecordId] = useState<any>(id);
  const [selectedBillingProfile, setSelectedBillingProfile] = useState<any>('create-new');

  const formMethods = useForm<any>({
    resolver: yupResolver(BillingProfileSetupSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    BillingProfileService.create,
    {
      onSuccess: (res: any) => {
        let message = "Not a valid response message from API";
        if (res.success) {
          refetchBillingProfileList()
          console.log("===", res.data);
          message = String(res.message);
          setEditRecordId(res.data.id);
          setSelectedBillingProfile(res.data.id);
          let path = `/billing-profile/setup/${res.data.id}`;
          navigate(path);
        } else {
          message = String(res.data.message);
        }

        setSnackbarData({
          open: true,
          message: message,
          severity: res.success ? "success" : "error",
        });
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const { isLoading: isLoadingBillingProfileList, data: billingProfileList, refetch: refetchBillingProfileList } =
    useGetBillingProfileDDL();

    console.log("billingProfileList",billingProfileList)

  const billingProfileListItems = useMemo(() => {
    if (billingProfileList && Array.isArray(billingProfileList)) {
      let resources: IDropDownListResponse[] = billingProfileList.map(
        (resourceItem: IDropDownListResponse) => ({
          // id: resourceItem.id,
          label: resourceItem.label,
          value: resourceItem.value,
        })
      );

      resources.unshift({
        label: "Create New",
        value: "create-new",
      });
      return resources;
    }

    return [
      {
        label: "Create New",
        value: "create-new",
      },
    ];
  }, [billingProfileList]);
console.log("billingProfileListItems",billingProfileListItems)
  const { isLoading: isLoadingBillingProfileById, data: editDataRecord } =
    useGetById(id);
  console.log("id =================", id);
  console.log("editRecordId =================", editRecordId);

  const onSubmit = (formData: any) => {
    let t4SlabItems = [];
    if (formData.t4SlipSlab1 && formData.t4SlipSlabCharges1) {
      t4SlabItems.push({
        slab: formData.t4SlipSlab1,
        charges: formData.t4SlipSlabCharges1,
      });
    }

    if (formData.t4SlipSlab2 && formData.t4SlipSlabCharges2) {
      t4SlabItems.push({
        slab: formData.t4SlipSlab2,
        charges: formData.t4SlipSlabCharges2,
      });
    }

    if (formData.t4SlipSlab3 && formData.t4SlipSlabCharges3) {
      t4SlabItems.push({
        slab: formData.t4SlipSlab3,
        charges: formData.t4SlipSlabCharges3,
      });
    }

    if (formData.t4SlipSlab4 && formData.t4SlipSlabCharges4) {
      t4SlabItems.push({
        slab: formData.t4SlipSlab4,
        charges: formData.t4SlipSlabCharges4,
      });
    }

    if (formData.t4SlipSlab5 && formData.t4SlipSlabCharges5) {
      t4SlabItems.push({
        slab: formData.t4SlipSlab5,
        charges: formData.t4SlipSlabCharges5,
      });
    }

    let t4aSlabItems = [];
    if (formData.t4aSlipSlab1 && formData.t4aSlipSlabCharges1) {
      t4aSlabItems.push({
        slab: formData.t4aSlipSlab1,
        charges: formData.t4aSlipSlabCharges1,
      });
    }

    if (formData.t4aSlipSlab2 && formData.t4aSlipSlabCharges2) {
      t4aSlabItems.push({
        slab: formData.t4aSlipSlab2,
        charges: formData.t4aSlipSlabCharges2,
      });
    }

    if (formData.t4aSlipSlab3 && formData.t4aSlipSlabCharges3) {
      t4aSlabItems.push({
        slab: formData.t4aSlipSlab3,
        charges: formData.t4aSlipSlabCharges3,
      });
    }

    if (formData.t4aSlipSlab4 && formData.t4aSlipSlabCharges4) {
      t4aSlabItems.push({
        slab: formData.t4aSlipSlab4,
        charges: formData.t4aSlipSlabCharges4,
      });
    }

    if (formData.t4aSlipSlab5 && formData.t4aSlipSlabCharges5) {
      t4aSlabItems.push({
        slab: formData.t4aSlipSlab5,
        charges: formData.t4aSlipSlabCharges5,
      });
    }

    let rl1SlabItems = [];
    if (formData.rl1SlipSlab1 && formData.rl1SlipSlabCharges1) {
      rl1SlabItems.push({
        slab: formData.rl1SlipSlab1,
        charges: formData.rl1SlipSlabCharges1,
      });
    }

    if (formData.rl1SlipSlab2 && formData.rl1SlipSlabCharges2) {
      rl1SlabItems.push({
        slab: formData.rl1SlipSlab2,
        charges: formData.rl1SlipSlabCharges2,
      });
    }

    if (formData.rl1SlipSlab3 && formData.rl1SlipSlabCharges3) {
      rl1SlabItems.push({
        slab: formData.rl1SlipSlab3,
        charges: formData.rl1SlipSlabCharges3,
      });
    }

    if (formData.rl1SlipSlab4 && formData.rl1SlipSlabCharges4) {
      rl1SlabItems.push({
        slab: formData.rl1SlipSlab4,
        charges: formData.rl1SlipSlabCharges4,
      });
    }

    if (formData.rl1SlipSlab5 && formData.rl1SlipSlabCharges5) {
      rl1SlabItems.push({
        slab: formData.rl1SlipSlab5,
        charges: formData.rl1SlipSlabCharges5,
      });
    }

    let t5SlabItems = [];
    if (formData.t5SlipSlab1 && formData.t5SlipSlabCharges1) {
      t5SlabItems.push({
        slab: formData.t5SlipSlab1,
        charges: formData.t5SlipSlabCharges1,
      });
    }

    if (formData.t5SlipSlab2 && formData.t5SlipSlabCharges2) {
      t5SlabItems.push({
        slab: formData.t5SlipSlab2,
        charges: formData.t5SlipSlabCharges2,
      });
    }

    if (formData.t5SlipSlab3 && formData.t5SlipSlabCharges3) {
      t5SlabItems.push({
        slab: formData.t5SlipSlab3,
        charges: formData.t5SlipSlabCharges3,
      });
    }

    if (formData.t5SlipSlab5 && formData.t5SlipSlabCharges5) {
      t5SlabItems.push({
        slab: formData.t5SlipSlab4,
        charges: formData.t5SlipSlabCharges4,
      });
    }

    if (formData.t5SlipSlab5 && formData.t5SlipSlabCharges5) {
      t5SlabItems.push({
        slab: formData.t5SlipSlab5,
        charges: formData.t5SlipSlabCharges5,
      });
    }

    let t3SlabItems = [];
    if (formData.t3SlipSlab1 && formData.t3SlipSlabCharges1) {
      t3SlabItems.push({
        slab: formData.t3SlipSlab1,
        charges: formData.t3SlipSlabCharges1,
      });
    }

    if (formData.t3SlipSlab2 && formData.t3SlipSlabCharges2) {
      t3SlabItems.push({
        slab: formData.t3SlipSlab2,
        charges: formData.t3SlipSlabCharges2,
      });
    }

    if (formData.t3SlipSlab3 && formData.t3SlipSlabCharges3) {
      t3SlabItems.push({
        slab: formData.t3SlipSlab3,
        charges: formData.t3SlipSlabCharges3,
      });
    }

    if (formData.t3SlipSlab5 && formData.t3SlipSlabCharges5) {
      t3SlabItems.push({
        slab: formData.t3SlipSlab4,
        charges: formData.t3SlipSlabCharges4,
      });
    }

    if (formData.t3SlipSlab5 && formData.t3SlipSlabCharges5) {
      t3SlabItems.push({
        slab: formData.t3SlipSlab5,
        charges: formData.t3SlipSlabCharges5,
      });
    }

    let rl3SlabItems = [];
    if (formData.rl3SlipSlab1 && formData.rl3SlipSlabCharges1) {
      rl3SlabItems.push({
        slab: formData.rl3SlipSlab1,
        charges: formData.rl3SlipSlabCharges1,
      });
    }

    if (formData.rl3SlipSlab2 && formData.rl3SlipSlabCharges2) {
      rl3SlabItems.push({
        slab: formData.rl3SlipSlab2,
        charges: formData.rl3SlipSlabCharges2,
      });
    }

    if (formData.rl3SlipSlab3 && formData.rl3SlipSlabCharges3) {
      rl3SlabItems.push({
        slab: formData.rl3SlipSlab3,
        charges: formData.rl3SlipSlabCharges3,
      });
    }

    if (formData.rl3SlipSlab4 && formData.rl3SlipSlabCharges4) {
      rl3SlabItems.push({
        slab: formData.rl3SlipSlab4,
        charges: formData.rl3SlipSlabCharges4,
      });
    }

    if (formData.rl3SlipSlab5 && formData.rl3SlipSlabCharges5) {
      rl3SlabItems.push({
        slab: formData.rl3SlipSlab5,
        charges: formData.rl3SlipSlabCharges5,
      });
    }

    let rl16SlabItems = [];
    if (formData.rl16SlipSlab1 && formData.rl16SlipSlabCharges1) {
      rl16SlabItems.push({
        slab: formData.rl16SlipSlab1,
        charges: formData.rl16SlipSlabCharges1,
      });
    }

    if (formData.rl16SlipSlab2 && formData.rl16SlipSlabCharges2) {
      rl16SlabItems.push({
        slab: formData.rl16SlipSlab2,
        charges: formData.rl16SlipSlabCharges2,
      });
    }

    if (formData.rl16SlipSlab3 && formData.rl16SlipSlabCharges3) {
      rl16SlabItems.push({
        slab: formData.rl16SlipSlab3,
        charges: formData.rl16SlipSlabCharges3,
      });
    }

    if (formData.rl16SlipSlab4 && formData.rl16SlipSlabCharges4) {
      rl16SlabItems.push({
        slab: formData.rl16SlipSlab4,
        charges: formData.rl16SlipSlabCharges4,
      });
    }

    if (formData.rl16SlipSlab5 && formData.rl16SlipSlabCharges5) {
      rl16SlabItems.push({
        slab: formData.rl16SlipSlab5,
        charges: formData.rl16SlipSlabCharges5,
      });
    }

    let requestPayload: IBillingProfileModel = {
      id: editRecordId,
      profileLabel: formData.profileLabel,
      effectiveDate: dayjs(formData.effectiveDate).format(
        DATE_FORMAT_API_PAYLOAD
      ),
      endDate: formData.endDate
        ? dayjs(formData.endDate).format(DATE_FORMAT_API_PAYLOAD)
        : formData.endDate,
      isGlobalProfile: formData.isGlobalProfile,
      baseCharges: formData.baseCharges,
      discountPercentage: formData.discountPercentage,
      chargesType: formData.chargesType,
      discountAmount: formData.discountAmount,
      slip: {
        t4: {
          flatSlipCharges: formData.t4flatSlipCharges,
          flatSummaryCharges: formData.t4flatSummaryCharges,
          slabCharges: t4SlabItems.length > 0 ? t4SlabItems : null,
        },
        t4a: {
          flatSlipCharges: formData.t4flatSlipCharges,
          flatSummaryCharges: formData.t4flatSummaryCharges,
          slabCharges: t4aSlabItems.length > 0 ? t4aSlabItems : null,
        },
        rl1: {
          flatSlipCharges: formData.rl1flatSlipCharges,
          flatSummaryCharges: formData.rl1flatSummaryCharges,
          slabCharges: rl1SlabItems.length > 0 ? rl1SlabItems : null,
        },
        t5: {
          flatSlipCharges: formData.t5flatSlipCharges !== '' ? formData.t5flatSlipCharges : null,
          flatSummaryCharges: formData.t5flatSummaryCharges !== '' ? formData.t5flatSummaryCharges : null,
          slabCharges: t5SlabItems.length > 0 ? t5SlabItems : null,
        },
        rl3: {
          flatSlipCharges: formData.rl3flatSlipCharges !== '' ? formData.rl3flatSlipCharges : null,
          flatSummaryCharges: formData.rl3flatSummaryCharges !== '' ? formData.rl3flatSummaryCharges : null,
          slabCharges: rl3SlabItems.length > 0 ? rl3SlabItems : null,
        },
        t3: {
          flatSlipCharges: formData.t3flatSlipCharges !== '' ? formData.t3flatSlipCharges : null,
          flatSummaryCharges: formData.t3flatSummaryCharges !== '' ? formData.t3flatSummaryCharges : null,
          slabCharges: t3SlabItems.length > 0 ? t3SlabItems : null,
        },
        rl16: {
          flatSlipCharges: formData.rl16flatSlipCharges !== '' ? formData.rl16flatSlipCharges : null,
          flatSummaryCharges: formData.rl16flatSummaryCharges !== '' ? formData.rl16flatSummaryCharges : null,
          slabCharges: rl16SlabItems.length > 0 ? rl16SlabItems : null,
        },
      },
    };

    submitFormData(requestPayload);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset();
    let path = `/billing-profile/setup`;
    navigate(path);
  };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // useEffect(() => {
  //   const setFormFieldValue = (fieldName: string, fieldValue: any) => {
  //     // @ts-ignore
  //     formMethods.setValue(fieldName, fieldValue ?? undefined);
  //   };

  //   if (editDataRecord && editDataRecord.success && editDataRecord.data) {
  //     setFormFieldValue("profileLabel", editDataRecord.data.profileLabel);
  //   }
  // }, [editDataRecord, formMethods]);
  useEffect(() => {
    // if the selected flex field data has loaded.
    console.log("editDataRecord--", editDataRecord);
    if (editDataRecord) {
      Object.keys(editDataRecord).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = editDataRecord[fieldName];
        // @ts-ignore
        // formMethods.setValue(fieldName, fieldValue ?? undefined);
        if (typeof fieldValue !== "object") {
          // @ts-ignore
          formMethods.setValue(fieldName, fieldValue ?? undefined);
          if (fieldName === "baseCharges") {
            formMethods.setValue(
              fieldName,
              Number(fieldValue).toFixed(2) ?? undefined
            );
          }
          if (fieldName === "discountPercentage") {
            formMethods.setValue(
              fieldName,
              Number(fieldValue).toFixed(2) ?? undefined
            );
          }
          if (fieldName === "discountAmount") {
            formMethods.setValue(
              fieldName,
              Number(fieldValue).toFixed(2) ?? undefined
            );
          }
        } else if (fieldValue !== null || undefined) {
          // @ts-ignore
          formMethods.setValue(fieldName, fieldValue ?? undefined);

          Object.keys(fieldValue).forEach((objName: any) => {
            const objValue = fieldValue[objName];
            if (typeof objValue !== "object") {
              // @ts-ignore
              formMethods.setValue(objName, objValue ?? undefined);
            } else {
              Object.keys(objValue).forEach((subObjName: any) => {
                const SubObjValue = objValue[subObjName];
                if (typeof SubObjValue !== "object") {
                  // @ts-ignore
                  let fullName = objName + subObjName;
                  formMethods.setValue(
                    fullName,
                    Number(SubObjValue).toFixed(2) ?? undefined
                  );
                } else if (
                  typeof SubObjValue === "object" &&
                  SubObjValue !== null
                ) {
                  SubObjValue &&
                    SubObjValue.map((item: any, index: any) => {
                      let slabIdName = objName + "SlipSlab" + (index + 1);
                      formMethods.setValue(
                        slabIdName,
                        item.slab ?? undefined
                      );

                      let slipSlabChargesIdName =
                        objName + "SlipSlabCharges" + (index + 1);
                      formMethods.setValue(
                        slipSlabChargesIdName,
                        item.charges.toFixed(2) ?? undefined
                      );
                    });
                }
              });
            }
          });
        }
      });
    }
    if(id){
      setEditRecordId(id)
      setSelectedBillingProfile(id)
    }
  }, [id,editDataRecord, formMethods.setValue, formMethods]);

  const onProfileSelectionChangeHandler = async (
    selectedItem: IEnhancedDropDownItem
  ): Promise<void> => {
    console.log("selectedItem.value", selectedItem.value);
    if(selectedItem.value === 'create-new'){
      setEditRecordId(undefined);
      setSelectedBillingProfile(undefined);
      let path = `/billing-profile/setup/`;
      navigate(path);
    } else {
      setEditRecordId(String(selectedItem.value));
      setSelectedBillingProfile(String(selectedItem.value));
      let path = `/billing-profile/setup/${selectedItem.value}`;
      navigate(path);
    }

    setLoadingData(true);
    setTimeout(() => {
      setLoadingData(false);
    }, 1000);
  };

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: BILLING_PROFILE_SETUP_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={
          isLoadingBillingProfileList ||
          isSubmitting ||
          isLoadingBillingProfileById ||
          loadingData
        }
        testId={testId}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <TwoColumnLayout
            toolbar={
              <StandardSubmitFormButtonsToolbar
                onResetClicked={onResetClicked}
                isSubmitting={false}
                testId={testId}
              />
            }
            leftColumnContent={
              <>
                <StandardPanel
                  title="Billing Profile(s)"
                  testId={`${testId}-flex-fields`}
                >
                  <EnhancedDropDownRHFWithLabel
                    selectName="id"
                    label="Load Profile"
                    labelPosition={LabelPosition.Left}
                    // items={
                    //   billingProfileList && Array.isArray(billingProfileList)
                    //     ? billingProfileList
                    //     : []
                    // }
                    items={billingProfileListItems}
                    // selectedItem={selectedBillingProfile.id}
                    testId={"billing-profile-list"}
                    isLoading={isLoadingBillingProfileList}
                    selectedItem={selectedBillingProfile}
                    onSelectionChangeHandler={onProfileSelectionChangeHandler}
                  />
                </StandardPanel>
                <StandardPanel
                  title="Billing Profile Setup"
                  testId={`${testId}-custom-fields`}
                  topPadding={2}
                >
                  <BillingProfileSetupFieldsPanel testId={testId} />
                </StandardPanel>
              </>
            }
            rightColumnContent={
              <StandardPanel
                title="Profile Slabs"
                testId={`${testId}-custom-fields`}
              >
                <BillingProfileSlabsFieldsPanel testId={testId} />
              </StandardPanel>
            }
            testId="flex-field-setup-form"
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
