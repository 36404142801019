// import {
//   CLIENT_ACTIVE_STATUS_ID,
//   INITIAL_SNACKBAR_DATA,
//   QUERY_OPTIONS,
// } from "common/constants";
// import {
//   instanceOfIClientResponse,
// } from "common/instance-method";
// import { IClient } from "models";
// import { IResponse, IServerError } from "models/common";
// import React, { useEffect, useState } from "react";
// import { useMutation, useQuery, useQueryClient } from "react-query";
// import { useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import { AccountantService, ClientService } from "services";
// import { IAppState } from "store/reducers";

// import {
//   BackdropCircularProgress,
//   DeleteConfirmationDialog,
//   EnhancedSnackbar,
//   FieldLayout,
//   LabelPosition,
//   StandardPanel,
//   TwoColumnLayout,
// } from "@websential/cosmic";
// import { FormProvider, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { ClientSetupValidationSchema } from "./validator";
// import { ClientSetupToolbar } from "./ClientSetupToolbar";
// import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
// import { Calendar } from "primereact/calendar";
// import { format } from 'date-fns';
// import { ClientInfoSetupFieldsPanel } from "./Panles/ClientInfoSetupFieldsPanel";
// import { ClientAddressSetupFieldsPanel } from "./Panles/ClientAddressSetupFieldsPanel";

// export interface IClientSetup {}
// const SINGLE_RECORD_DATA_QUERY_KEY = "client-single-data-record";

// const useGetData = (id: string | undefined) => {
//   return useQuery(
//     [SINGLE_RECORD_DATA_QUERY_KEY, id],
//     () => ClientService.getById(id),
//     QUERY_OPTIONS
//   );
// };
//   const convertObjToString = (str: string) => {
//     const jsonStringPoe = str as string;
//     const modifiedString = jsonStringPoe.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
//     return JSON.parse(modifiedString);
//   };

// export const ClientSetup: React.FC<IClientSetup> = () => {
//   const navigate = useNavigate();
//   const accountantId = useSelector((state: IAppState) => state.accountant.id);
//   const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
//   const [openDialog, setDialogOpen] = useState(false);
//   const [creationDate, setCreationDate] = useState<any>('')
//   // for delete row
//   const [singleDelete, setSingleDelete] = useState<boolean>(false);
//   const [clientId, setClientId] = useState<number>(0);
//   const queryClient = useQueryClient();
//   const { id } = useParams();
//   console.log("params----", id);
//   const [editRecordId, setEditRecordId] = useState(id);
//   const [recordData, setRecordData] = useState<any>(undefined);

//   const { isLoading: isSubmitting, mutate: submitFormFields } = useMutation(
//     ClientService.create,
//     {
//       onSuccess: (res: IClient | IServerError | IResponse | any) => {
//         if (instanceOfIClientResponse(res)) {
//           setEditRecordId(res.id);
//           queryClient.invalidateQueries(SINGLE_RECORD_DATA_QUERY_KEY);
//           setSnackbarData({
//             open: true,
//             message: "Data has been submitted successfully!",
//             severity: "success",
//           });
//         }
//         // let path = `/clients/setup/${res.id}`;
//         let path = `/clients`;
//         navigate(path)
//       },
//       onError: (err) => {
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong, try again later!",
//           severity: "error",
//         });
//       },
//     }
//   );
//   const { isLoading: isLoadingById, data: selectedDataRecord } =
//     useGetData(editRecordId);

//     useEffect(()=>{
//       if(selectedDataRecord){
//         setRecordData(selectedDataRecord)
//       }
//     },[selectedDataRecord])

//   const formMethods = useForm<any>({
//     resolver:yupResolver(ClientSetupValidationSchema),
//     mode:"onBlur",
//     reValidateMode:"onChange"
//   })

//   const onSubmit = (formData: any) => {
//     console.log("formData",formData)
//     let clientRequestPayload = {
//       // accountantId: accountantId !== "0" && "" ? accountantId : undefined,
//       accountantId: accountantId !== "0" || "" ? accountantId : undefined,
//       id:recordData  ? recordData.id : undefined,
//       fixedFields:{
//         clientIdentityNo: formData.clientIdentityNo,
//         clientName: formData.clientName,
//         operatingName: formData.operatingName,
//         businessNo: formData.businessNo && formData.businessNo.replace(/(\d{9})(\d{4})/, '$1RP$2'),
//         email: formData.email,
//         operatingProvinces: formData.operatingProvinces,
//         statusId:CLIENT_ACTIVE_STATUS_ID,
//         address:[{
//           id:recordData  ? recordData.address[0].id : undefined,
//           addressLine1:formData.addressLine1,
//           city: formData.city,
//           countryId:"1",
//           provinceId:convertObjToString(formData.province && formData.province).id.toString(),
//           postalCode: formData.postalCode,
//         }]
//       },
//       metaFields: [
//         {fieldId:"22",name:"website",value:formData.website},
//         {fieldId:"21",name:"fax",value:formData.fax && formData.fax.replace(/\D/g, '')},
//         {fieldId:"20",name:"ext2",value:formData.ext2},
//         {fieldId:"19",name:"phone2",value:formData.phone2 && formData.phone2.replace(/\D/g, '')},
//         {fieldId:"18",name:"ext",value:formData.ext1},
//         {fieldId:"17",name:"phoneNo",value:formData.phone1 && formData.phone1.replace(/\D/g, '')},
//         {fieldId:"9",name:"access_code",value:formData.access_code},
//         {fieldId:"8",name:"reduced_business_no",value:formData.reduced_business_no && formData.reduced_business_no.replace(/(\d{9})(\d{4})/, '$1AA$2')},
//         {fieldId:"6",name:"account_manager",value:formData.account_manager},
//         {fieldId:"5",name:"employees",value:formData.employees},
//         {fieldId:"10",name:"payment_method",value:formData.payment_method},
//         {fieldId:"4",name:"creation_date",value:formData.creationDate && creationDate},
//       ]
//     }
//     console.log("clientRequestPayload----------",clientRequestPayload)
//     submitFormFields(clientRequestPayload);
//   };

//   // useEffect(() => {
//   //   // TODO: Navigate to Unauthorized page or permissions denied page
//   //   if (!accountantId) {
//   //     navigate("/");
//   //   }
//   // }, [accountantId, navigate]);

//   useEffect(() => {
//     if (recordData) {
//       Object.keys(recordData).forEach((fieldName) => {
//         const fieldValue = recordData[fieldName];
//         formMethods.setValue(fieldName, fieldValue);
//         if (fieldName === "address") {
//           Object.keys(fieldValue).forEach((clientFieldName) => {
//             const clientFieldValue = fieldValue[clientFieldName];
//             Object.keys(clientFieldValue).forEach((clientFieldNameNew) => {
//               const clientFieldValueNew =
//                 clientFieldValue[clientFieldNameNew];
//               formMethods.setValue(clientFieldNameNew, clientFieldValueNew);

//               if (clientFieldNameNew === "country") {
//                 formMethods.setValue(
//                   clientFieldNameNew,
//                   clientFieldValueNew.name ?? undefined
//                 );
//               }
//               if (clientFieldNameNew === "province") {
//                 let id = clientFieldValueNew.id;
//                 let name = clientFieldValueNew.name;
//                 let shortCode = clientFieldValueNew.shortCode;
//                 formMethods.setValue(
//                   clientFieldNameNew,
//                   `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
//                 );
//               }
//             });
//           });
//         }
//       if(fieldName === 'metaFields'){
//         fieldValue.forEach((obj:any) => {
//           formMethods.setValue(obj.name,obj.value)
//           if(obj.name === 'phoneNo'){
//             formMethods.setValue('phone1',obj.value)
//           }
//           if(obj.name === 'ext'){
//             formMethods.setValue('ext1',obj.value)
//           }
//           if(obj.name === 'creation_date'){
//             const defaultDate = new Date(obj.value);
//             formMethods.setValue('creationDate', defaultDate);
//             setCreationDate(formatDate(defaultDate as Date))
//           }
//         })
//       }
//       });
//     }
//   }, [recordData, formMethods.setValue, formMethods]);

//   const onSnackbarClosed = (
//     _event: React.SyntheticEvent | Event,
//     reason?: string
//   ) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setSnackbarData(INITIAL_SNACKBAR_DATA);
//   };

//   const formatDate = (date: Date): string => {
//     return format(date, 'yyyy-MM-dd'); // Format the date as "yyyy-MM-dd"
//   };

//   const onCreationDateChangeHandler = (selectedDate: any) => {
//     console.log("onCreationDateChangeHandler",selectedDate);
//     console.log("format date to 2023-07-26",formatDate(selectedDate.value as Date));
//     setCreationDate(formatDate(selectedDate.value as Date))
//   };
//   // For Delete Row
//    const { isLoading: isDeleting, mutate: deleteClientById } = useMutation(
//     AccountantService.delClient,
//     {
//       onSuccess: (res: string | IServerError | IResponse) => {
//         if (typeof res === "string") {
//           // remove the cache of the Year fields since we just added a new field.
//           setSnackbarData({
//             open: true,
//             message: "Record has been deleted successfully.",
//             severity: "success",
//           });
//         } else {
//           setSnackbarData({
//             open: true,
//             message: "Something went wrong, try again later!",
//             severity: "error",
//           });
//         }
//         formMethods.reset()
//         // let path = `/clients/setup`;
//         setRecordData(undefined)
//         let path = `/clients`;
//         navigate(path);
//       },
//       onError: (err) => {
//         // TODO: customize the toast message based on the response
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong, try again later!",
//           severity: "error",
//         });
//       },
//     }
//   );
//  const deleteClientField = () => {
//   if (singleDelete) {
//     setSingleDelete(false);
//     deleteClientById(clientId);
//     setDialogOpen(false);
//   }
// };

// const onDeleteClicked = () => {
//   setDialogOpen(true);
//   setSingleDelete(true);
//   if(recordData){
//     setClientId(recordData.id)
//   }
// };
//   const onCloseHandler = () => {
//     setDialogOpen(false);
//   };

//   const onResetClicked = () => {
//     setSnackbarData({
//       open: true,
//       message: "Form was reset successfully",
//       severity: "success",
//     });
//     formMethods.reset();
//     setRecordData(undefined)
//     let path = `/clients/setup`;
//     navigate(path);
//   };

//   console.log("inside client setup")
//   return (
//     <>
//       <BackdropCircularProgress
//           open={
//             isSubmitting ||
//             isLoadingById
//             || isDeleting
//           }
//           testId="client Setup"
//         />
//         <DeleteConfirmationDialog
//           open={openDialog}
//           deleteActionHandler={deleteClientField}
//           cancelActionHandler={onCloseHandler}
//           testId="resource-confirmation-dialog"
//         />
//         <FormProvider {...formMethods}>
//           <form onSubmit={formMethods.handleSubmit(onSubmit)}>
//             <TwoColumnLayout
//               toolbar={
//                 <ClientSetupToolbar
//                   onResetClicked={onResetClicked}
//                   onDeleteClicked={onDeleteClicked}
//                   isSubmitting={isSubmitting}
//                 />
//               }
//               leftColumnContent={
//                 <>
//                   <StandardPanel
//                     title="Particulars"
//                     testId={`client-info`}
//                   >
//                     <ClientInfoSetupFieldsPanel/>
//                      <FieldWrapper>
//                       <FieldLayout
//                           label="Creation Date"
//                           isRequired={true}
//                           testId=""
//                           labelPosition={LabelPosition.Left}
//                           labelColumns={4}
//                           fieldColumns={8}
//                           >
//                           <Calendar
//                             id="creationDate"
//                             {...formMethods.register('creationDate')} // Register the input with react-hook-form using methods.register
//                             value={formMethods.watch('creationDate') || null} // Set the value using watch to reflect changes
//                             showIcon
//                             placeholder={'dd/mm/yyyy'}
//                             className="w-full"
//                             style={{ height: '2.6rem',marginTop:"2px",marginBottom:"2.85rem" }}
//                             onChange={onCreationDateChangeHandler}
//                             required
//                           />
//                         </FieldLayout>
//                     </FieldWrapper>
//                   </StandardPanel>
//                 </>
//               }
//               rightColumnContent={
//                 <>
//                   <StandardPanel
//                     title="Address"
//                     testId={`-employee-address-panel`}
//                   >
//                     <ClientAddressSetupFieldsPanel testId={""} />
//                   </StandardPanel>
//                 </>
//               }
//               testId="client-setup-form"
//             />
//           </form>
//         </FormProvider>
//         <EnhancedSnackbar
//         message={snackbarData.message}
//         onCloseHandler={onSnackbarClosed}
//         severity={snackbarData.severity}
//         testId={`snackbar`}
//         open={snackbarData.open}
//       />
//     </>
//   );
// };

// old Code with tab form

import {
  CLIENT_ACTIVE_STATUS_ID,
  CLIENT_SETUP_SCREEN_ID,
  CLIENT_SETUP_SCREEN_SLUG,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
} from "common/constants";
import { AddressFields, FlexFieldsUtils } from "common/FlexFieldsUtils";
import {
  instanceOfIClient,
  instanceOfIClientResponse,
} from "common/instance-method";
import { SetupFormLayout } from "layouts/SetupFormLayout";
import { IClient } from "models";
import { IResponse, IServerError } from "models/common";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AccountantService, ClientService } from "services";
import { IAppState } from "store/reducers";

import {
  BackdropCircularProgress,
  // CategoryFieldsPanelLayout,
  DeleteConfirmationDialog,
  EnhancedSnackbar,
  FieldType,
  // FlexFieldsFormPanelLayout,
  IPanel,
  ITabLayout,
  RHookFormGenerator,
} from "@websential/cosmic";
import IField from "@websential/cosmic/build/components/RHookFormGenerator/types/IField";
import { Grid } from "@mui/material";
import { AccountantSetupToolbar } from "components/Accountant/AccountantSetup/AccountantSetupToolbar";
import { clientJSONdataValidation } from "./clientJSONdataValidation";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import {
  FIELD_TYPE_COMPONENT,
  FlexFieldsFormPanelLayout,
  getMultiSelectionDDLDefaultValue,
} from "components/Accountant/AccountantSetup/FlexFieldsFormPanelLayout";
import {
  CategoryFieldsPanelLayout,
  getFieldName,
} from "components/Accountant/AccountantSetup/CategoryFieldsPanelLayout";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IClientSetup {}
const SINGLE_RECORD_DATA_QUERY_KEY = "client-single-data-record";

const useGetData = (id: string | undefined) => 
   useQuery(
    [SINGLE_RECORD_DATA_QUERY_KEY, id],
    (data) => {
      return ClientService.getById(id);
    },
    { refetchOnWindowFocus: false }
  );

// const useScreenLayout = () => {
//   return useQuery(
//     ["accountant-setup-layout"],
//     () => AccountantService.getSetupLayout(),
//     QUERY_OPTIONS
//   );
// };

const useScreenLayout: any = (isAdminUser:number) =>  
  useQuery(
    ["client-setup-layout"],
    (data) => {
      return ClientService.getSetupLayout(isAdminUser);
    },
    { refetchOnWindowFocus: false }
  );

const ASSINGED_CATEGORY_DATA_QUERY_KEY = "client-category-data-record";
const ASSINGED_CATEGORY_PANEL_QUERY_KEY = "client-category-panel-record";

const useGetAssignedCategoryPanel = (
  // screenId: number | string,
  // categoryFieldId: number | string,
  // categoryFieldValue: number | string
  selectedCategoriesData: any
) => {
  return useQuery(
    [ASSINGED_CATEGORY_PANEL_QUERY_KEY, selectedCategoriesData],
    () =>
      ClientService.getClientCategoriesAssingedPanels(selectedCategoriesData),
    QUERY_OPTIONS
  );
};

const useGetClientCategories = (screenId: string | number) => {
  return useQuery(
    [ASSINGED_CATEGORY_DATA_QUERY_KEY, screenId],
    () => ClientService.getClientCategories(screenId),
    QUERY_OPTIONS
  );
};

export const ClientSetup: React.FC<IClientSetup> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );

  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const homePathRoute = useSelector(
    (state: IAppState) => state.HomePagePath.path
  );
  const isAdminUser = useSelector((state: IAppState) => state.user.profile.isSuperStatus);
  const accountantId = useSelector((state: IAppState) => state.accountant.id);
  const [metaFieldIds, setMetaFieldIds] = useState({});
  const [renderForm, setRenderForm] = useState(false);
  const [openDialog, setDialogOpen] = useState(false);
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [clientId, setClientId] = useState<number>(0);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [
    categoryFieldsWithOnChangeMethod,
    setCategoryFieldsWithOnChangeMethod,
  ] = useState<any>(undefined);
  const [clientTotalPanels, setClientTotalPanels] = useState<any>(undefined);
  const [clientTotalCategories, setClientTotalCategories] =
    useState<any>(undefined);
  let screenId = 21;
  const queryClient = useQueryClient();
  const { id } = useParams();
  console.log("params----", id);
  const [editRecordId, setEditRecordId] = useState(id);

  const { isLoading: isSubmitting, mutate: submitFormFields } = useMutation(
    ClientService.create,
    {
      onSuccess: (res: IClient | IServerError | IResponse | any) => {
        console.log("resgh", res);

        if (res && res.message === "Data has been submitted successfully!") {
          if (instanceOfIClientResponse(res)) {
            setEditRecordId(res.id);
            queryClient.invalidateQueries(SINGLE_RECORD_DATA_QUERY_KEY);
            }
                setSnackbarData({
                  open: true,
                  message: res.message,
                  severity: "success",
                });
          // let path = `/clients/list`;
          // navigate(path);
        } else if (res && res.message === "Data has been updated successfully!") {
            setSnackbarData({
              open: true,
              message: res.message,
              severity: "success",
            });
        } else {
          setSnackbarData({
            open: true,
            message:
              res && res.message && res.message
                ? res.message
                : "Something went wrong.",
            severity: "error",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const { isLoading, data: screenFields } = useScreenLayout(isAdminUser);
  console.log("screenFields---", screenFields);

  const { isLoading: isLoadingById, data: selectedDataRecord } =
    useGetData(editRecordId);

  const [selectedCategoriesData, setSelectedCategoriesData] = useState<any>([]);

  const {
    isLoading: isLoadingClientCategoriesPanels,
    data: clientCategoryAssingedPanels,
  } = useGetAssignedCategoryPanel(selectedCategoriesData);

  const { isLoading: isLoadingClientCategories, data: clientCategoryList } =
    useGetClientCategories(screenId);

  console.log("clientCategoryAssingedPanels", clientCategoryAssingedPanels);

  useEffect(() => {
    if (clientCategoryList) {
      setClientTotalCategories(clientCategoryList);
    }
  }, [clientCategoryList]);

  const onSubmit = (values: any) => {
    console.log("values", values);
    let flexfields: any = {
      ...values,
      ["postalCode-" + 15 + "-fixed"]:
        values["postalCode-" + 15 + "-fixed"] &&
        values["postalCode-" + 15 + "-fixed"].replace(
          /[a-z]/g,
          (match: string) => match.toUpperCase()
        ),
      ["operatingName-" + 3 + "-fixed"]:
        values["operatingName-" + 3 + "-fixed"] !== ""
          ? values["operatingName-" + 3 + "-fixed"]
          : undefined,
      ["id-" + 27 + "-fixed"]:
        selectedDataRecord && selectedDataRecord.address[0].id
          ? selectedDataRecord.address[0].id
          : undefined,
      ["isPrimary-" + 28 + "-fixed"]:true,
      ["appAddressTypeId-" + 29 + "-fixed"]:1,
    };
    console.log("flexfields--", flexfields);

    //add category fields in meta fields
    const categoryFields = () => {
      if (
        selectedCategoriesData &&
        selectedCategoriesData.length > 0 &&
        clientCategoryList &&
        clientCategoryList.items.length > 0
      ) {
        const makeSelectedCategoryAsMetaField = selectedCategoriesData.map(
          (item: any) => ({
            fieldId: item.categoryFieldId,
            name: "categoryField",
            value: item.categoryFieldValue,
          })
        );
        console.log("selectedCategoriesData", selectedCategoriesData);
        console.log(
          "makeSelectedCategoryAsMetaField",
          makeSelectedCategoryAsMetaField
        );
        return makeSelectedCategoryAsMetaField;
      } else {
        return [];
      }
    };
    console.log("cateknvlsnkdv", categoryFields());
    const categoryFieldsAsMetaFields = categoryFields();

    let payload = FlexFieldsUtils.formatFixedAndMetaFieldsWithAddressArray(
      flexfields,
      metaFieldIds
    );
    console.log("payload ====== ", payload);
    payload.id = editRecordId;
    payload.accountantId = accountantId !== "0" ? accountantId : undefined;
    payload.fixedFields["statusId"] = CLIENT_ACTIVE_STATUS_ID;
    payload.metaFields = payload.metaFields.concat(
      categoryFieldsAsMetaFields && categoryFieldsAsMetaFields.length > 0
        ? categoryFieldsAsMetaFields
        : []
    );

    console.log("=======", payload);
    console.log("=======String", JSON.stringify(payload));
    submitFormFields(payload);
  };

  useEffect(() => {
    if (id && selectedDataRecord) {
      //category fields update default values
      let metaFields = selectedDataRecord.metaFields;
      let categoryField = metaFields.filter(
        (element: any) => element.name === "categoryField"
      );
      console.log("categoryField--1", categoryField);
      if (
        categoryField &&
        categoryField.length > 0 &&
        clientCategoryList &&
        clientCategoryList.items.length > 0
      ) {
        const selectedCategoriesFieldsData = categoryField.map((item: any) => ({
          screenId: screenId,
          categoryFieldId: parseInt(item.fieldId),
          categoryFieldValue: item.value,
        }));
        setSelectedCategoriesData((prevData: any) => [
          ...prevData,
          ...selectedCategoriesFieldsData,
        ]);
        console.log("categoryField--1 inside if ", clientCategoryList);
        //get all category data from category list
        let selectedCategoriesDataIds =
          categoryField &&
          categoryField.map((item: any) => parseInt(item.fieldId));
        console.log("selectedCategoriesDataIds", selectedCategoriesDataIds);
        const addDefaultValueInCategoryFields = clientCategoryList.items
          .filter((listItem: any) =>
            categoryField.find(
              (item: any) => parseInt(item.fieldId) === listItem.id
            )
          )
          .map((listItem: any) => {
            const matchingCategory: any = categoryField.find(
              (item: any) => parseInt(item.fieldId) === listItem.id
            );
            return {
              ...listItem,
              defaultValue: matchingCategory.value,
            };
          });
        console.log("clientCategoryList.items1", clientCategoryList.items);
        console.log("filteredItem11", addDefaultValueInCategoryFields);

        const finalCategoriesListWithDefaultValue =
          clientCategoryList.items.map((metaCategory: any) => {
            const matchingItem = addDefaultValueInCategoryFields.find(
              (categoryListItem: any) => categoryListItem.id === metaCategory.id
            );
            return matchingItem ? matchingItem : metaCategory;
          });
        setClientTotalCategories({
          items: finalCategoriesListWithDefaultValue,
        });
        console.log("finalres1", finalCategoriesListWithDefaultValue);
      }
    }
  }, [id, selectedDataRecord, clientCategoryList]);

  // useEffect(() => {
  //   // TODO: Navigate to Unauthorized page or permissions denied page
  //   if (!accountantId) {
  //     navigate(homePathRoute);
  //   }
  // }, [accountantId, navigate]);

  useEffect(() => {
    console.log("#####=>useEffect", screenFields);
    console.log("#####=>selectedDataRecord", selectedDataRecord);
    if (id) {
      //null all defualt values
      clientTotalPanels &&
        clientTotalPanels.forEach((item: any) => {
          item?.panels.forEach((screenPanel: IPanel) => {
            screenPanel?.fields?.forEach((field: IField) => {
              // field.defaultValue = null;
              field.fieldId === "countryId" ? (field.defaultValue = "1") : 
              field.fieldId === "employees"
                ? (field.defaultValue = "26")
                : null;
            });
          });
        });
      if (
        clientTotalPanels &&
        // Array.isArray(screenFields) &&
        selectedDataRecord &&
        instanceOfIClient(selectedDataRecord)
      ) {
        console.log("##### => populating the edit record");
        let metaFieldIdsObject: { [key: string]: string } = {};
        // screenFields.forEach((screenTab: ITabLayout, index: number) => {
        clientTotalPanels &&
          clientTotalPanels.forEach((item: any) => {
            item.panels.forEach((screenPanel: IPanel, index: number) => {
              screenPanel.fields.forEach((field: IField) => {
                let isAddressField = AddressFields.find(
                  (element) => element === field.fieldId
                );
                console.log(
                  "#####=>selectedDataRecord.hasOwnProperty(field.fieldId) - ",
                  field.fieldId,
                  " - isAddressField - ",
                  isAddressField,
                  selectedDataRecord.hasOwnProperty(field.fieldId)
                );

                if (
                  field.isFixed &&
                  selectedDataRecord.hasOwnProperty(field.fieldId) &&
                  !isAddressField
                ) {
                  type objectKey = keyof typeof selectedDataRecord;
                  let fieldValue = field.fieldId as objectKey;
                  console.log("fieldValue", fieldValue);
                  // field.defaultValue = String(selectedDataRecord[fieldValue]);
                  field.defaultValue =
                    selectedDataRecord[fieldValue] !== null
                      ? String(selectedDataRecord[fieldValue])
                      : "";
                  console.log("defaultValue--", field.defaultValue);
                } else if (field.isFixed && isAddressField) {
                  let addressObject = selectedDataRecord.address[0];
                  type addressObjectKey = keyof typeof addressObject;
                  let addressFieldKey = field.fieldId as addressObjectKey;
                  field.defaultValue = addressObject[addressFieldKey];
                  console.log("defaultValue--inside else", field.defaultValue);

                  if (
                    String(addressFieldKey) === "country" ||
                    String(addressFieldKey) === "countryId"
                  ) {
                    console.log("******", addressObject.country);
                    field.defaultValue = String(addressObject.country.id);
                  } else if (
                    String(addressFieldKey) === "province" ||
                    String(addressFieldKey) === "provinceId"
                  ) {
                    console.log("******", addressObject.province);
                    field.defaultValue = String(addressObject.province.id);
                  }
                } else if (!field.isFixed) {
                  console.log("meta field: ", field.fieldId);
                  let metaFields = selectedDataRecord.metaFields;
                  let metaField = metaFields.find(
                    (element) => element.name === field.fieldId
                  );
                  if (metaField) {
                    metaFieldIdsObject[field.fieldId] = metaField.id;
                    field.defaultValue = metaField.value;
                  }
                } else {
                  console.log("field not found: ", field.fieldId);
                }
              });
              // Show form in edit mode when all data is loaded
              // Show form in edit mode when all data is loaded
              screenFields &&
                screenFields.forEach((item: any) => {
                  if (index === item.panels.length - 1) {
                    setRenderForm(true);
                  }
                });
            });
          });

        setMetaFieldIds(metaFieldIdsObject);
      }
    } else {
      // Show form in add mode
      setRenderForm(true);
      //null all defualt values
      screenFields &&
        screenFields.forEach((item: any) => {
          item?.panels.forEach((screenPanel: IPanel) => {
            screenPanel?.fields?.forEach((field: IField) => {
              // field.defaultValue = null;
              field.defaultValue = 
              field.fieldId === "payment_method" ? field.defaultValue :
              field.fieldId === "invoice_payment_for" ? field.defaultValue :
              field.fieldId === "countryId" ? field.defaultValue : 
              field.fieldId === "employees"
                ? field.defaultValue
                : null;
            });
          });
        });
      //null all defualt values 
        console.log("inside else22",screenFields)
    }
  }, [
    id,
    screenFields,
    selectedDataRecord,
    clientCategoryList,
    clientTotalPanels,
  ]);

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  useEffect(() => {
    if (
      clientCategoryList &&
      clientCategoryList.items &&
      clientCategoryList.items.length === 1 &&
      clientCategoryList.items[0].defualtCategory === true
    ) {
      console.log("yeesss");
    } else if (clientCategoryList && clientCategoryList.items) {
      console.log("nooooooo1");
      const categoryWithOnChange = clientCategoryList.items.map((item: any) => {
        let updatedCategories: any = [];
        // let selectedCategory: any = [];
        console.log("item--", item);
        // for (let i = 0; i < clientCategoryList.items.length; i++) {
        if (item.fieldType === "DropDownList") {
          if (item.defualtCategory === true) {
            let fieldData = {
              screenId: screenId,
              categoryFieldId: item.id,
              categoryFieldValue: item.fieldInternalValue,
            };
            setSelectedCategoriesData((prevData: any) => [
              ...prevData,
              fieldData,
            ]);
            updatedCategories.push(item);
            item.isHide = true;
            item.readOnly = true;
            item.isRequired = false;
            // item.fieldLabel=''
          } else {
            const onDropDownChanged = (value: any) => {
              console.log("selectedCategoriesData--", selectedCategoriesData);
              let fieldData = {
                screenId: screenId,
                categoryFieldId: item.id,
                categoryFieldValue: value.value,
              };
              // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
              setSelectedCategoriesData((prevData: any[]) => {
                // Check if an object with the same categoryFieldId exists
                const existingIndex = prevData.findIndex(
                  (data) => data.categoryFieldId === fieldData.categoryFieldId
                );

                if (existingIndex !== -1) {
                  // If exists, remove it
                  const newData = prevData.filter(
                    (data, index) => index !== existingIndex
                  );
                  // Add the new object
                  return [...newData, fieldData];
                } else {
                  // If not exists, just add the new object
                  return [...prevData, fieldData];
                }
              });

              // Check if an object with the same ID already exists
              // const existingIndex = selectedCategory.findIndex(
              //   (data: { selectedcategoryFieldId: any; }) => data.selectedcategoryFieldId === item.id
              // );

              // console.log("existingIndex",existingIndex)

              // if (existingIndex !== -1) {
              //   setSelectedCategoriesData((prevData: any) => {
              //     const newData = [...prevData];
              //     console.log("newData1",newData)
              //     newData[existingIndex] = fieldData;
              //     console.log("newData2",newData)
              //     selectedCategory = []
              //     selectedCategory = newData
              //     console.log("selectedCategoryyyyyyy",selectedCategory)
              //     return newData;
              //   });
              //   console.log("inside if")
              // } else {
              //   console.log("inside else")
              //   setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
              //   selectedCategory.push(fieldData);
              // }
            };
            item.onChange = onDropDownChanged;
            updatedCategories.push(item);
            console.log("item456", item);
          }
        } else if (item.fieldType === "NormalSwitch") {
          if (item.defualtCategory === true) {
            let fieldData = {
              screenId: screenId,
              categoryFieldId: item.id,
              categoryFieldValue: item.fieldInternalValue,
            };
            setSelectedCategoriesData((prevData: any) => [
              ...prevData,
              fieldData,
            ]);
            updatedCategories.push(item);
            item.isHide = true;
            item.readOnly = true;
            item.isRequired = false;
            // item.fieldLabel=''
          } else {
            const onSwitchChanged = (value: any) => {
              // setselectedcategoryFieldId(item.id);
              // setselectedcategoryFieldValue(value);
              // alert(value)
              let fieldData = {
                screenId: screenId,
                categoryFieldId: item.id,
                categoryFieldValue: value,
              };
              // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
              setSelectedCategoriesData((prevData: any[]) => {
                // Check if an object with the same categoryFieldId exists
                const existingIndex = prevData.findIndex(
                  (data) => data.categoryFieldId === fieldData.categoryFieldId
                );

                if (existingIndex !== -1) {
                  // If exists, remove it
                  const newData = prevData.filter(
                    (data, index) => index !== existingIndex
                  );
                  // Add the new object
                  return [...newData, fieldData];
                } else {
                  // If not exists, just add the new object
                  return [...prevData, fieldData];
                }
              });
            };
            item.onChange = onSwitchChanged;
            updatedCategories.push(item);
          }
        } else if (item.fieldType === "MultiSelectionDropDownList") {
          if (item.defualtCategory === true) {
            let fieldData = {
              screenId: screenId,
              categoryFieldId: item.id,
              categoryFieldValue: item.fieldInternalValue,
            };
            setSelectedCategoriesData((prevData: any) => [
              ...prevData,
              fieldData,
            ]);
            updatedCategories.push(item);
            item.isHide = true;
            item.readOnly = true;
            item.isRequired = false;
            // item.fieldLabel=''
          } else {
            const onMultiSelectionDropDownValueChanged = (value: any) => {
              let selectedItems: any = [];
              value.map((item: any) => {
                selectedItems.push(item.value);
              });
              let fieldData = {
                screenId: screenId,
                categoryFieldId: item.id,
                categoryFieldValue: selectedItems,
              };
              // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
              setSelectedCategoriesData((prevData: any[]) => {
                // Check if an object with the same categoryFieldId exists
                const existingIndex = prevData.findIndex(
                  (data) => data.categoryFieldId === fieldData.categoryFieldId
                );

                if (existingIndex !== -1) {
                  // If exists, remove it
                  const newData = prevData.filter(
                    (data, index) => index !== existingIndex
                  );
                  // Add the new object
                  return [...newData, fieldData];
                } else {
                  // If not exists, just add the new object
                  return [...prevData, fieldData];
                }
              });
            };
            item.onChange = onMultiSelectionDropDownValueChanged;
            updatedCategories.push(item);
          }
        } else if (item.fieldType === "Checkbox") {
          if (item.defualtCategory === true) {
            let fieldData = {
              screenId: screenId,
              categoryFieldId: item.id,
              categoryFieldValue: item.fieldInternalValue,
            };
            setSelectedCategoriesData((prevData: any) => [
              ...prevData,
              fieldData,
            ]);
            updatedCategories.push(item);
            item.isHide = true;
            item.readOnly = true;
            item.isRequired = false;
            // item.fieldLabel=''
          } else {
            const onCheckBoxValueChanged = (value: any) => {
              let fieldData = {
                screenId: screenId,
                categoryFieldId: item.id,
                categoryFieldValue: value,
              };
              // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
              setSelectedCategoriesData((prevData: any[]) => {
                // Check if an object with the same categoryFieldId exists
                const existingIndex = prevData.findIndex(
                  (data) => data.categoryFieldId === fieldData.categoryFieldId
                );

                if (existingIndex !== -1) {
                  // If exists, remove it
                  const newData = prevData.filter(
                    (data, index) => index !== existingIndex
                  );
                  // Add the new object
                  return [...newData, fieldData];
                } else {
                  // If not exists, just add the new object
                  return [...prevData, fieldData];
                }
              });
            };
            item.onChange = onCheckBoxValueChanged;
            updatedCategories.push(item);
          }
        } else if (item.fieldType === "RadioGroup") {
          if (item.defualtCategory === true) {
            let fieldData = {
              screenId: screenId,
              categoryFieldId: item.id,
              categoryFieldValue: item.fieldInternalValue,
            };
            setSelectedCategoriesData((prevData: any) => [
              ...prevData,
              fieldData,
            ]);
            updatedCategories.push(item);
            item.isHide = true;
            item.readOnly = true;
            item.isRequired = false;
            // item.fieldLabel=''
          } else {
            const onRadioButtonValueChanged = (e: any) => {
              let fieldData = {
                screenId: screenId,
                categoryFieldId: item.id,
                categoryFieldValue: e.target.value,
              };
              // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
              setSelectedCategoriesData((prevData: any[]) => {
                // Check if an object with the same categoryFieldId exists
                const existingIndex = prevData.findIndex(
                  (data) => data.categoryFieldId === fieldData.categoryFieldId
                );

                if (existingIndex !== -1) {
                  // If exists, remove it
                  const newData = prevData.filter(
                    (data, index) => index !== existingIndex
                  );
                  // Add the new object
                  return [...newData, fieldData];
                } else {
                  // If not exists, just add the new object
                  return [...prevData, fieldData];
                }
              });
            };
            item.onChange = onRadioButtonValueChanged;
            updatedCategories.push(item);
          }
        } else {
          if (item.defualtCategory === true) {
            let fieldData = {
              screenId: screenId,
              categoryFieldId: item.id,
              categoryFieldValue: item.fieldInternalValue,
            };
            setSelectedCategoriesData((prevData: any) => [
              ...prevData,
              fieldData,
            ]);
            updatedCategories.push(item);
            item.isHide = true;
            item.readOnly = true;
            item.isRequired = false;
            // item.fieldLabel=''
          } else {
            let fieldData = {
              screenId: screenId,
              categoryFieldId: item.id,
              categoryFieldValue: item.fieldInternalValue,
            };
            // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
            setSelectedCategoriesData((prevData: any[]) => {
              // Check if an object with the same categoryFieldId exists
              const existingIndex = prevData.findIndex(
                (data) => data.categoryFieldId === fieldData.categoryFieldId
              );

              if (existingIndex !== -1) {
                // If exists, remove it
                const newData = prevData.filter(
                  (data, index) => index !== existingIndex
                );
                // Add the new object
                return [...newData, fieldData];
              } else {
                // If not exists, just add the new object
                return [...prevData, fieldData];
              }
            });
            updatedCategories.push(item);
          }
        }
        console.log("//dd", selectedCategoriesData);
        console.log("updatedCategories", updatedCategories);
        return item;
        // }
      });
      console.log("categoryWithOnChange", categoryWithOnChange);
      setCategoryFieldsWithOnChangeMethod(categoryWithOnChange);
    }
  }, [clientCategoryList]);

  const { isLoading: isDeleting, mutate: deleteClientById } = useMutation(
    ClientService.deleteById,
    {
      onSuccess: (res: string | IServerError | IResponse) => {
        if (typeof res === "string") {
          // remove the cache of the Client fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: "Record has been deleted successfully.",
            severity: "success",
          });
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
        // formMethods.reset();
        let path = `/clients/list`;
        navigate(path);
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const deleteClientField = () => {
    if (singleDelete) {
      setSingleDelete(false);
      deleteClientById(clientId);
      setDialogOpen(false);
    }
  };

  const onDeleteClicked = () => {
    setDialogOpen(true);
    setSingleDelete(true);
    if (selectedDataRecord) {
      setClientId(selectedDataRecord.id);
    }
  };

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    // formMethods.reset();
    // let path = `/accountants/setup`;
    // navigate(path);
  };

  useEffect(() => {
    let totalPanels: any = [];
    if (
      clientCategoryAssingedPanels &&
      clientCategoryAssingedPanels.length > 0
    ) {
      // screenFields?.panels.map((item: any) => {
      //   totalPanels.push(item);
      // });
      // clientCategoryAssingedPanels?.panels.map((item: any) => {
      //   totalPanels.push(item);
      // });
      // // totalPanels.push(clientCategoryAssingedPanels.panels)
      // if (totalPanels && totalPanels.length !== 0) {
      //   let clientTolalPanel = {
      //     panels: totalPanels,
      //   };
      //   setClientTotalPanels(clientTolalPanel);
      // }
      const makeAllFieldsToMetaFields =
        clientCategoryAssingedPanels &&
        clientCategoryAssingedPanels.map((item: any) => {
          return {
            ...item,
            panels: item.panels.map((panel: any) => {
              return {
                ...panel,
                fields: panel.fields.map((field: any) => ({
                  ...field,
                  fieldId: field.appFieldLabel,
                  isFixed: false, //now all fields is meta field
                })),
              };
            }),
          };
        });
      screenFields.map((item: any) => {
        totalPanels.push(item);
      });
      //make all field to isFixed false to make it meta field
      makeAllFieldsToMetaFields.map((item: any) => {
        totalPanels.push(item);
      });
      if (totalPanels && totalPanels.length !== 0) {
        setClientTotalPanels(totalPanels);
      }
    } else {
      screenFields &&
        screenFields.map((item: any) => {
          totalPanels.push(item);
          if (totalPanels && totalPanels.length !== 0) {
            // let clientTolalPanel = {
            //   panels: totalPanels,
            // };
            setClientTotalPanels(totalPanels);
          }
        });
      // totalPanels.push(screenFields?.panels);
      console.log("insideelse");
    }
    console.log("totalPanels", totalPanels);
  }, [screenFields, clientCategoryAssingedPanels]);
  // console.log("totalPanels--",totalPanels)
  console.log("clientTotalPanels--", clientTotalPanels);
  console.log("selectedCategoriesData", selectedCategoriesData);
  console.log("clientCategoryList", clientCategoryList);

  useEffect(() => {
    if (
      clientCategoryList &&
      clientCategoryList.items &&
      clientCategoryList.items.length === 1 &&
      clientCategoryList.items[0].defualtCategory === true
    ) {
      const fieldData = {
        screenId: screenId,
        categoryFieldId: clientCategoryList.items[0].id,
        categoryFieldValue: clientCategoryList.items[0].fieldInternalValue,
      };

      console.log("heyyyyy");
      setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
    }
  }, [clientCategoryList]);

  const defaultValuesOfClientForm = useCallback(() => {
    const fieldsDefaultValues: any = {};
    clientTotalPanels &&
      clientTotalPanels.forEach((tabLayout: any) => {
        tabLayout?.panels.forEach((panel: any) => {
          panel?.fields.forEach((panelField: any) => {
            const FieldComponent: any =
              FIELD_TYPE_COMPONENT[panelField.fieldType];
            if (FieldComponent) {
              const { fieldId, id, isFixed, defaultValue, fieldType } =
                panelField;
              const fieldName = getFieldName(fieldId, id, isFixed);
              let fieldDefaultValue = panelField.defaultValue;
              console.log("formLayout indif", panelField);
              console.log("panelField.defaultValue", panelField.defaultValue);
              console.log(
                `Cosmic FlexFieldsFormPanelLayout => Default Values Setting, fieldName: ${fieldName}, Default Value: ${fieldDefaultValue}, Field Type: ${panelField.fieldType}`
              );
              if (fieldType === FieldType.MultiSelectionDropDownList) {
                if (defaultValue) {
                  fieldDefaultValue = getMultiSelectionDDLDefaultValue(
                    defaultValue as string
                  );
                }
              }
              // if the field has a default value
              if (fieldDefaultValue) {
                fieldsDefaultValues[fieldName] = fieldDefaultValue;
              }
            }
          });
        });
      });
    return fieldsDefaultValues;
  }, [clientTotalPanels, getFieldName]);

  const defaultValuesOfClientCategpryForm = useCallback(() => {
    const fieldsDefaultValues: any = {};
    clientTotalCategories &&
      clientTotalCategories?.items.forEach((panelField: any) => {
        const FieldComponent: any = FIELD_TYPE_COMPONENT[panelField.fieldType];
        if (FieldComponent) {
          const { fieldId, id, isFixed, defaultValue, fieldType } = panelField;
          const fieldName = getFieldName(fieldId, id, isFixed);
          let fieldDefaultValue = defaultValue;
          console.log(
            `Cosmic CategoryFieldsPanelLayout => Default Values Setting, fieldName: ${fieldName}, Default Value: ${fieldDefaultValue}, Field Type: ${fieldType}`
          );
          if (fieldType === FieldType.MultiSelectionDropDownList) {
            if (defaultValue) {
              fieldDefaultValue = getMultiSelectionDDLDefaultValue(
                defaultValue as string
              );
            }
          }
          // if the field has a default value
          if (fieldDefaultValue) {
            fieldsDefaultValues[fieldName] = fieldDefaultValue;
          }
        }
      });
    return fieldsDefaultValues;
  }, [clientTotalCategories, getFieldName]);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: id ? id : "",
        entityId2: "",
        entityId3: "",
        screenName: CLIENT_SETUP_SCREEN_SLUG,
        companyName: clientName,
        activityType: "activity_type=create&activity_type=updated&activity_type=delete",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid sx={{ pb: "5rem", "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}>
      <BackdropCircularProgress
        open={
          isSubmitting ||
          isLoadingById ||
          isLoadingClientCategories ||
          isLoadingClientCategoriesPanels ||
          isDeleting ||
          isLoadingActivityLogData
        }
        testId="accountant Setup"
      />
      <DeleteConfirmationDialog
        open={openDialog}
        deleteActionHandler={deleteClientField}
        cancelActionHandler={onCloseHandler}
        testId="accountant-confirmation-dialog"
      />
      {clientTotalCategories &&
      clientTotalCategories?.items &&
      clientTotalCategories.items.length === 1 &&
      clientTotalCategories.items[0].defualtCategory === true ? (
        <></>
      ) : clientTotalCategories &&
        clientTotalCategories.items &&
        clientTotalCategories.items.length > 0 ? (
        <CategoryFieldsPanelLayout
          categoryFields={
            clientTotalCategories?.items &&
            categoryFieldsWithOnChangeMethod !== undefined
              ? categoryFieldsWithOnChangeMethod
              : []
          }
          panelLabel={"Client Categories"}
          formDefaultValues={defaultValuesOfClientCategpryForm()}
          testId={"categoryFields-panel"}
        />
      ) : (
        <></>
      )}
      <SetupFormLayout
        isLoadingData={isLoading || isLoadingById}
        renderForm={renderForm}
        isSubmittingForm={isSubmitting}
        forMultipleColumn={true}
      >
        <FlexFieldsFormPanelLayout
          toolbar={
            <AccountantSetupToolbar
              onResetClicked={onResetClicked}
              onDeleteClicked={onDeleteClicked}
              isSubmitting={isSubmitting}
            />
          }
          onSubmit={onSubmit}
          formLayout={screenFields && clientTotalPanels && clientTotalPanels}
          formDefaultValues={defaultValuesOfClientForm()}
          testId={"clientCategoryAssingedPanels"}
          validationSchema={clientJSONdataValidation}
        />
        <EnhancedSnackbar
          message={snackbarData.message}
          onCloseHandler={onSnackbarClosed}
          severity={snackbarData.severity}
          testId={"accountant-snackbar"}
          open={snackbarData.open}
        />
      </SetupFormLayout>
    </Grid>

    //old method
    // <SetupFormLayout
    //   isLoadingData={isLoading || isLoadingById}
    //   renderForm={renderForm}
    //   isSubmittingForm={isSubmitting}
    // >
    //   <RHookFormGenerator
    //     formLayout={screenFields}
    //     onSubmit={onSubmit}
    //     testId="client-setup-form"
    //   />
    //   <EnhancedSnackbar
    //     message={snackbarData.message}
    //     onCloseHandler={onSnackbarClosed}
    //     severity={snackbarData.severity}
    //     testId={"client-snackbar"}
    //     open={snackbarData.open}
    //   />
    // </SetupFormLayout>
  );
};
