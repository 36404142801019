import { AccountantList } from 'components/Accountant';
import { AuthenticatedLayout } from 'layouts/AuthenticatedLayout/AuthenticatedLayout';
import { FC } from 'react';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useSelector } from 'react-redux';
import { IAppState } from 'store/reducers';

export interface IAccountantListPage { }

export const AccountantListPage: FC<IAccountantListPage> = (
  props: IAccountantListPage
) => {
  const homePathRoute = useSelector((state:IAppState) => state.HomePagePath.path)
  console.log("homePathRoute--",homePathRoute)
  return (
    <AuthenticatedLayout
      pageTitle="Accountant List"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName="/AccountantDashboard"
      breadcrumbLinks={[{text: 'Accountants', navigateTo: '/accountant/list'}]}
      mainContents={<AccountantList />}
    />
  );
};
