import { AxiosError, AxiosResponse } from 'axios';
import http from 'common/http-common';
import { IDropDownListResponse } from 'models';
import { IGetListRequest, IResponse, IServerError } from 'models/common';
import { IRL1SlipListModel } from 'models/rl1Slip';
import { IRL1SlipRequestPayload } from 'models/rl1Slip/IRL1SlipRequestPayload';
// TODO: replace this with API response once it's ready
export const RL1SlipService = {
  getList: async (clientId: string, taxYear: number, params: IGetListRequest): Promise<IRL1SlipListModel[] | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<IRL1SlipListModel[]> = await http.get<IRL1SlipListModel[]>(
      // `/v1/rl1-slips/?client_id=${clientId}&tax_year=${taxYear}&order_by=${params.order_by}`);
      `/v1/rl1-slips/?client_id=${clientId}&tax_year=${taxYear}&search_query=${params.search_query}&order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}`);
      console.log("responserr",response)
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 404) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return { status: false, message: axiosError.response ? axiosError.response.data : "Unknown error" };
  }
  },
  delRL1Slip: async (requestPayload: any): Promise<string | IServerError | IResponse> => {
    const response: AxiosResponse<string> = await http.delete<string>(
      `/v1/rl1-slips/${requestPayload.rL1SlipId}/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`);
    console.log('response RL1 del by id', response);

    if (response.status === 200) {
      return "Taxslip RL1 Slip List has been deleted successfully";
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    else if (response.status === 400) {
      return response.data;
    }
    else if (response.status === 404) {
      return response.data;
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  delBulkRL1Slips: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    console.log("requestPayload", typeof requestPayload);
    const response = await http.delete<string>(
      `/v1/rl1-slips/bulk-delete/${requestPayload.clientId}/?tax_year=${requestPayload.taxYear}`,
      { data: requestPayload.ids}
    );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }
    console.log("REsponse ?????????????????????????????????????", response);
    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  create: async (
    requestPayload: IRL1SlipRequestPayload
  ): Promise<IRL1SlipRequestPayload | IServerError | IResponse | any> => {
    if (requestPayload.id) {
      console.log('requestPayload ==>> update request', requestPayload);
      const response: AxiosResponse<any> = await http.put<IRL1SlipRequestPayload>(
        `/v1/rl1-slips/${requestPayload.id}/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`, requestPayload);

      if (response.status === 200) {
        console.log("responce data of update id",response.data)
        // return response.data;
        return {status:response.status, data:response.data};
      }else if (response.status === 400) {
        return response.data;
      }else if (response.status === 404) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }

    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<any> =
      await http.post<IRL1SlipRequestPayload>(
        `/v1/rl1-slips/?client_id=${requestPayload.clientId}&tax_year=${requestPayload.taxYear}`,
        requestPayload
      );

    if (response.status === 201) {
      // return response.data;
      return {status:response.status, data:response.data};
    }else if (response.status === 400) {
      return response.data;
    }else if (response.status === 404) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getRL1SlipById: async (id: string | undefined,clientId:string,taxYear:number): Promise<any | IServerError | IResponse | null> => {
    if (id) {
      const response: AxiosResponse<any> = await http.get<any>(
        `/v1/rl1-slips/${id}/?client_id=${clientId}&tax_year=${taxYear}`);
  
      console.log("id : ",id)
      console.log("Client id : ",clientId)
      console.log("TaxYear : ",taxYear)
      console.log("response of selected row in RL1 slip : ",response.data)
    
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }

    return null;
  },
  getById: async (id: string | undefined, clientId: string, taxYear: number): Promise<any | IServerError | IResponse | null> => {
    if (!id) return null;
    console.log("id from getbyId Service : ",id)
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/rl1-slips/${id}/?client_id=${clientId}&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getResourceSlipDropDownList: async (resourceId: string, clientId: string, taxYear: number): Promise<IDropDownListResponse[] | IServerError | IResponse> => {
    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<IDropDownListResponse[]>(
      `/v1/rl1-slips/resource-ddl-slips/${resourceId}?client_id=${clientId}&tax_year=${taxYear}`);
      console.log("resourceId",resourceId)
      console.log("clientId",clientId)
      console.log("resourceId",taxYear)
    if (response.status === 200) {
      console.log("responce resource-ddl-slips",response.data)
      return response.data;
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  rl1SlipRetrieveData: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    console.log("requestPayload",requestPayload);
    const response = await http.post<string>(
      `/v1/rl1-slips-csv/import-csv/?client_id=${requestPayload.id}&tax_year=${requestPayload.taxYear}`,
      requestPayload.formData
    );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    // console.log("loginDetail", loginDetail);
    console.log("REsponse ?????????????????????????????????????", response);

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  rl1SlipRetrieveXmlData: async (
    requestPayload: any
  ): Promise<string | IServerError | IResponse> => {
    console.log("requestPayload",requestPayload);
    const response:any = await http.post<string>(
      `/v1/rl1-xml/?client_id=${requestPayload.id}&tax_year=${requestPayload.taxYear}`,
      requestPayload.formData
    );
    if (response.status === 201) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    // console.log("loginDetail", loginDetail);
    console.log("REsponse ?????????????????????????????????????", response);

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getRL1Summary: async (
    clientId: string,
    slipType: string | number,
    taxYear: number): Promise<any | IServerError | IResponse> => {
    const response: AxiosResponse<any> = await http.get<any>(
      `/v1/rl1-summary/?client_id=${clientId}&slip_type=${slipType}&tax_year=${taxYear}`);

    if (response.status === 200) {
      console.log("T4 Summary response.data '===========",response.data)
      return response.data;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  rl1SummaryclearConfirmationCode: async (
    clientId: string,
    slipType: number | undefined,
    taxYear: number
  ): Promise<string | IServerError | IResponse> => {
    try {
    const response: AxiosResponse<string> | any= await http.put<string>(
      `/v1/rl1-summary/clear-confirmation-code/${clientId}/?slip_type=${slipType}&tax_year=${taxYear}`
    );

    if (response.status === 200) {
      return response;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    } else if (response.status === 400) {
      return response.data
    } else if (response.status === 404) {
      return response.data
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("response3245345e",error)
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
  createSummary: async (
    requestPayload: any)
    : Promise<any | IServerError | IResponse> => {

    console.log('requestPayload ==>> add request', requestPayload);
    const response: AxiosResponse<any> = await http.put<any>(
      `/v1/rl1-summary/?client_id=${requestPayload.id}&tax_year=${requestPayload.taxYear}`, requestPayload);

    if (response.status === 200) {
      return response;
    }
    else if (response.status === 201) {
      return response;
    }
    else if (response.status === 404) {
      return response.data;;
    }
    else if (response.status === 400) {
      return response.data;;
    }
    else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getResourceInformationById: async (
    resourceId: string | undefined,
    clientId:string,
    roleName:string
    ): Promise<any | IServerError | IResponse | null> => {
    if (resourceId) {
      const response: AxiosResponse<any> = await http.get<any>(
        `/v1/resources/${roleName}/${resourceId}/?client_id=${clientId}`);
  
      console.log("response of getResourceInformationById : ",response.data)
    
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }

    return null;
  },
  getAdjustedData: async (
    params: any
    // clientId:string,
    // taxYear:number
  ): Promise<any | IServerError | IResponse | null> => {
    console.log("params : ", params);

    const response: AxiosResponse<any> = await http.post<any>(
      `/v1/rl1-slips/apply-adjustments/${params.clientId}/?tax_year=${params.taxYear}`,
      params
    );

    console.log("response of getAdjustedData : ", response.data);

    if (response.status === 201) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getEmployeeDDL: async (
    clientId: string, dataRole: string, taxYear:number
  ): Promise<any | IServerError | IResponse | null> => {

    // let queryKey = GET_QUERY_KEY.EMPLOYEE_RESOURCE_DDL;
    // switch (dataRole) {
    //   case VENDOR_RESOURCE_LITERAL:
    //     queryKey = GET_QUERY_KEY.VENDOR_RESOURCE_DDL
    //     break;
    //   default:
    //     queryKey = GET_QUERY_KEY.EMPLOYEE_RESOURCE_DDL;
    //     break;
    // }

    // return useQuery(
    //   queryKey,
    //   () => ResourceService.getDropDownList(clientId, dataRole),
    //   QUERY_OPTIONS
    // );
    const response: AxiosResponse<IDropDownListResponse[]> = await http.get<
      IDropDownListResponse[]
    >(`/v1/rl1-slips/ddl-list-qc/${dataRole}/?client_id=${clientId}&tax_year=${taxYear}`);

    console.log("respooooooooooooooooonse",response)

    if (response.status === 200) {
      return response.data;
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
// RL1 Setting PUT
rl1SettingCreate: async (
  requestPayload: any)
  : Promise<any | IServerError | IResponse> => {

  console.log('requestPayload ==>> add request', requestPayload);
  const response: AxiosResponse<any> = await http.put<any>(
    `/v1/rl1-setting/?client_id=${requestPayload.id}&tax_year=${requestPayload.taxYear}`, requestPayload);

  if (response.status === 200) {
    return response.data;
  }
  if (response.status === 201) {
    return response.data;
  }
  if (response.status === 404) {
    return {
      status: false,
      message: "RL1 setting was not found.",
      error: true,
    };
  }
  if (response.status === 400) {
    return {
      status: false,
      message: "400 Bad Request",
      error: true,
    };
  }
  else if (response.status === 500) {
    return {
      status: false,
      message: "Something went wrong.",
      error: true,
    };
  }

  return {
    status: false,
    message: "Something went wrong please try again later.",
  };
},
getRL1SettingData: async (
  clientId: string,
  taxYear: number): Promise<any | IServerError | IResponse> => {
    try {
      const response: AxiosResponse<any> = await http.get<any>(
        `/v1/rl1-setting/?client_id=${clientId}&tax_year=${taxYear}`);

      if (response.status === 200) {
        console.log("T4 Setting response.data '===========",response.data)
        return response.data;
      }
      if (response.status === 404) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      } else {
        console.log("Response status:", response.status);
        return response.data;
      }
    } catch (error) {
      console.log("response3245345e",error)
      const axiosError = error as AxiosError;
      console.error("An error occurred:", axiosError);
      return {
        status: false,
        message: axiosError.response
          ? axiosError.response.data
          : "Unknown error",
      };
    }
},
getRL1AuditFiling: async (
  clientId: string,
  taxYear:number
): Promise<
any | IServerError | IResponse
> => {
  const response: AxiosResponse<any> = await http.get<any>(
    `/v1/audit/audit-filling?client_id=${clientId}&audit_filling_tag=RL1&tax_year=${taxYear}`);

  if (response.status === 200) {
    return response.data;
  } else if (response.status === 400) {
    return {
      status: false,
      message: "Bad Request.",
      error: true,
    };
  } else if (response.status === 500) {
    return {
      status: false,
      message: "Something went wrong.",
      error: true,
    };
  }

  return {
    status: false,
    message: "Something went wrong please try again later.",
  };
},
};
