import { toSnake } from "common/methods";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IAppState } from "store/reducers";
import { Link } from 'react-router-dom';

import { Box, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, useTheme } from "@mui/material";

// import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import { ReportsService } from "services/ReportsService";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { BackdropCircularProgress, EnhancedSnackbar } from "@websential/cosmic";
import { INITIAL_SNACKBAR_DATA, INVOICE_DETAIL_SCREEN_ID, INVOICE_DETAIL_SCREEN_SLUG } from "common/constants";
import { InvoiceDetailAppBar } from "./InvoiceDetailAppBar";
import { format } from "date-fns";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { ClientService } from "services";
import stripeImage from '../Reports/InvoiceReport/StripeBlurple.svg';

export interface IInvoiceDetail {}

const useInvoiceDetails = (
  clientId: string,
  refNo: string,
  ) =>
  useQuery(
    ["invoice-detail", clientId, refNo],
    (data) => {
      return ReportsService.getInvoiceDetail(clientId, refNo);
    },
    { refetchOnWindowFocus: false }
  );

const SINGLE_RECORD_DATA_QUERY_KEY = "client-single-data-record";

const useGetCompanyData = (id: string | undefined) => 
  useQuery(
    [SINGLE_RECORD_DATA_QUERY_KEY, id],
    (data) => {
      return ClientService.getById(id);
    },
    { refetchOnWindowFocus: false }
  );

export const InvoiceDetail: React.FC<IInvoiceDetail> = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const clientName = useSelector((state : IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector((state: IAppState) => state.isLoadAxiosCall.isLoading);

  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const[isLoadingData, setIsLoadingData] = useState(false)
  const [invoicePaymentMethod, setinvoicePaymentMethod] = useState<any>(undefined);

  // const navigate = useNavigate();
  const clientId = useSelector((state: IAppState) => state.client.id);
  // const taxYear  = useSelector((state: IAppState) => state.taxYear.year);
  const refNo = useSelector((state: IAppState) => state.invoice.refNo);

  console.log("refNo",refNo)

  // const [queryParams, setQueryParams] = useState<IGetListRequest>({
  //   search_query: "",
  //   order_by: "name",
  //   order_direction: "asc",
  //   page: 1,
  //   size: 5,
  // });

  const { isLoading, data } = useInvoiceDetails(clientId,refNo); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const invoiceDetailData: any = data;
  console.log("@@@@@ => invoiceDetail Get by id", invoiceDetailData);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  console.log("companyGetByIdRecord",companyGetByIdRecord)

  useEffect(()=>{
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentMethod = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'payment_method')
      console.log("paymentMethodpaymentMethod",parseInt(paymentMethod.value))
      setinvoicePaymentMethod(parseInt(paymentMethod.value))
    }
  },[companyGetByIdRecord])

  // const { message, componentState } = useSelector(
  //   (state: IAppState) => state.viewState
  // );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  // const [openDialog, setDialogOpen] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  // const [statusValue, setStatusValue] = useState("");
  // const [apiAction, setApiAction] = useState("");
  // const [emptyList, setEmptyList] = useState(false);

  // const onRequestParamsChange = useCallback((requestParams) => {
  //   console.log("##### - request params", requestParams);
  //   let dispatchParams = {
  //     search_query: requestParams.debouncedSearchQuery,
  //     order_by: "role_name",
  //     order_direction: "asc",
  //     page: requestParams.currentPage + 1,
  //     size: requestParams.pageSize,
  //   };
  //   if (requestParams.sort) {
  //     const [sortBy, sortDirection] = requestParams.sort.split(",");
  //     dispatchParams.order_by = toSnake(sortBy);
  //     dispatchParams.order_direction = sortDirection;
  //   }

  //   setQueryParams(dispatchParams);
  // }, []);

  // const onRowEditClicked = useCallback((params) => {
  //   alert(`Edit row with ID: ${params.row.id} has been clicked!`);
  //   let path = `/invoices/setup/${params.row.id}`;
  //   navigate(path);
  // }, []);

  // const onCloseHandler = () => {
  //   setDialogOpen(false);
  // };

  // const callAction = () => {
  //   if (apiAction === "delete") {
  //     alert(`Delete row with ID: ${selectedIds} has been clicked!`);
  //     // TODO api call for delete
  //   } else if (apiAction === "updateStatus") {
  //     alert(`action need to trigger : ${apiAction}`);
  //     // TODO api call for update status
  //   }
  //   setDialogOpen(false);
  // };

  // const onRowDeleteClicked = useCallback((params) => {
  //   setDialogOpen(true);
  //   setApiAction("delete");
  //   setSelectedIds(params.row.id);
  //   alert(`Delete row with ID: ${params.row.id} has been clicked!`);
  // }, []);

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setStatusValue(event.target.value as string);
  //   setApiAction("updateStatus");
  //   setDialogOpen(true);
  // };

  // const selectionToolbar = useCallback((selectedItems) => {
  //   setSelectedIds(selectedItems);
  //   if (selectedItems.length) {
  //     return (
  //       <Box sx={{ marginTop: theme.spacing(1) }}>
  //         {"Bulk Action Drop Down List"}
  //         {/* <EnhancedDDLBulkAction
  //           label="Status"
  //           value={statusValue}
  //           onChangeHandler={handleChange}
  //           options={[
  //             { name: "Active", value: "1" },
  //             { name: "Inactive", value: "2" },
  //           ]}
  //           testId="status-dd"
  //         /> */}
  //       </Box>
  //     );
  //   }
  //   return (
  //     <Box sx={{ marginTop: theme.spacing(1) }}>
  //       Calendar Filter Here
  //       {/* <RouterLinkButton to='/lookup-fields/setup' text="Add Lookup Field" /> */}
  //     </Box>
  //   );
  // }, []);

  // const onPrintButtonClicked = () => {
  //   alert("Print Botton Clicked")
  // }

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const onUnPaidClickHandler = (clickedRow: any, tag:string) => {
    setIsLoadingData(true);
    const fetchData = async () => {
      try {
        const response:any = await ReportsService.paypalIntegrationOfUnpaidInvoice(clientId,clickedRow.refNo,tag);
        setIsLoadingData(false);
        console.log("responseData111-----------------",response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
      fetchData();
      setIsLoadingData(false);
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const formatDate = (datee: any) => {
    const originalDate = new Date(datee);
    const formattedDate = format(originalDate, "MMM dd, yyyy");

    return formattedDate;
  };

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:INVOICE_DETAIL_SCREEN_SLUG,
      companyName:clientName,
      activityType:"activity_type=paid&activity_type=emailed&activity_type=generated&activity_type=updated&activity_type=recalculated&activity_type=print",
      activityLogVisibility:true
    }));
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  },[])
  
  return (
    <>
    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}> */}
        {/* <SingleColumnLayout
            children={
              <StandardPanel
                title="TaxSlip Year"
                testId=".."
                leftSpacing={2}
                rightSpacing={2}
              >
                
              </StandardPanel>
            }
            toolbar={
              <StandardSubmitFormButtonsToolbar
                // onResetClicked={onResetClicked}
                isSubmitting={false}
                testId="standard-form-submit-toolbar"
              />
            }
            testId="form-layout"
          /> */}

      {/* <EnhancedDataGrid
        columns={invoiceReportList ? invoiceReportList.headerColumns : []}
        rows={invoiceReportList?.items}
        selectionEnabled={true}
        autoHeight={true}
        allowSearching={true}
        rowsPerPageOptions={[1, 2, 3, 5]}
        selectionToolbar={selectionToolbar}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        initialPageSize={5}
        disableColumnMenu={true}
        showRowActionsColumn={true}
        autoPageSize={false} //
        showIndexColumn={true}
        testId="data-role-list-grid"
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onRequestParamsChange={onRequestParamsChange}
        totalRowsCount={invoiceReportList?.total}
        isLoading={isLoading}
        rowIdGetter={(row) => row.id}
        searchDebouncingDelay={2000}
        shouldMask={false}
      /> */}
      <BackdropCircularProgress
          open={
            isLoading ||
            isLoadingData ||
            isLoadingActivityLogData ||
            isLoadingClientDataById
          }
          testId="T4 Slip List"
        />
      <Grid item xs={12} sm={8} md={8} lg={8} xl={8} sx={{ 
        marginTop: theme.spacing(3),
        "& .MuiAppBar-root": { zIndex: 2, top: "65px" }
        }}>
        <InvoiceDetailAppBar snackbarMessage={(value:any) => setSnackbarData(value)}/>
        <Paper variant="elevation">
					<Box sx={{ 
            borderBottom: "1px solid #0000001f",
            display:'flex',
            justifyContent:'space-between',
            p:"16px 16px 16px 26px"}}>
            <Typography sx={{
              fontWeight:"bold",
              // fontSize:".9rem",
              m:"14px 0"
            }}>Invoice # {invoiceDetailData && invoiceDetailData.invoiceNo}</Typography>
              {invoiceDetailData && invoiceDetailData.paid !== undefined && invoiceDetailData.paid === false ? (
                  <Box sx={{
                    display:"flex",
                    mr:"3rem"
                  }}>
                    <Typography sx={{
                      color:"#dc3545",
                      mr:"5.7rem",
                      fontSize:"1.25rem"
                    }}>Un-Paid Invoice</Typography>
                      
                        {invoicePaymentMethod && invoicePaymentMethod === 30 ? 
                            <div onClick={() => onUnPaidClickHandler(invoiceDetailData,'stripe')} style={{cursor:"pointer"}}>
                              <img src={stripeImage} alt="Stripe" style={{height:"38px"}}/>
                              </div>
                          : invoicePaymentMethod && invoicePaymentMethod === 10 ? 
                          <span style={{ color: "#ED6C02", fontWeight:"bold" }}>
                            Funds Transfer
                          </span>
                          :
                          <div onClick={() => onUnPaidClickHandler(invoiceDetailData,'paypal')} style={{cursor:"pointer"}}>
                          <img src="https://www.paypalobjects.com/en_US/i/btn/btn_xpressCheckout.gif"/>
                        </div>
                          }
                  </Box>
                 ) : (
                  <Box sx={{
                    display:"flex",
                    mr:"3rem",
                    alignItems:"center"
                  }}>
                  <Typography sx={{
                    color:"green",
                    mr:"5.7rem",
                    fontSize:"1.25rem"
                  }}>Paid</Typography>
                  </Box>
                )
              } 
          </Box>
        <Grid container sx={{p:"16px",color:"rgba(0,0,0,.6)"}}>
          <Grid item xs={4} sm={4} lg={4} xl={4} sx={{p:"12px"}}>
              <Typography><span style={{fontWeight:"bold",color:"#6c757d"}}>Invoice No.:</span> {invoiceDetailData && invoiceDetailData.invoiceNo}</Typography>
              <Typography><span style={{fontWeight:"bold",color:"#6c757d"}}>Date.:</span> {invoiceDetailData && invoiceDetailData.createdAt && formatDate(invoiceDetailData.createdAt)}</Typography>
              <Typography variant="h4" sx={{fontWeight:"bold",color:"#6c757d",p:"28px 0 20px 0"}}>{invoiceDetailData && invoiceDetailData.companyName}</Typography>
              <address style={{lineHeight:"0.375rem",fontSize:".875rem",fontStyle:"normal",paddingBottom:"20px"}}>
                <p>{invoiceDetailData && invoiceDetailData.homeAddress.addressLine1}</p> 
                <p>{invoiceDetailData && invoiceDetailData.homeAddress.city}</p> 
                <p>{invoiceDetailData && invoiceDetailData.homeAddress.province.shortCode} {invoiceDetailData && invoiceDetailData.homeAddress.postalCode}</p>
              </address>
              <Box sx={{display:"flex",alignItems:"center"}}>
                {invoiceDetailData && invoiceDetailData.companyEmail ? (
                  <>
                    <MailOutlineIcon sx={{fontSize:"inherit"}}/>
                    <Typography>&nbsp;
                    {/* <a href={`mailto:${invoiceDetailData && invoiceDetailData.companyEmail}`} className="no-underline"> */}
                      {invoiceDetailData && invoiceDetailData.companyEmail}
                      {/* </a> */}
                    </Typography>
                  </>
                ):(
                  <></>
                )}
              </Box>
          </Grid>
          <Grid item xs={4} sm={4} lg={4} xl={4}  sx={{p:"12px"}}></Grid>
          <Grid item xs={4} sm={4} lg={4} xl={4} sx={{p:"12px"}}>
              <Typography><span style={{fontWeight:"bold",color:"#6c757d"}}>HST/GST No.: </span> 847623915 RT0001</Typography>
              <Typography variant="h4" sx={{fontWeight:"bold",color:"#6c757d",p:"3rem 0 20px 0"}}>Hatchbox Inc.</Typography>
              <address style={{lineHeight:"0.375rem",fontSize:".875rem",fontStyle:"normal",paddingBottom:"20px"}}>
                <p>1550 South Gateway Rd., Suite 229</p> 
                <p>Mississauga</p> 
                <p>ON L4W5G6</p>
              </address>
              <Box sx={{display:"flex",alignItems:"center"}}>
                <PhoneIcon sx={{fontSize:"inherit"}}/>
                <Typography>&nbsp;(905) 624-9559</Typography>
              </Box>
              <Box sx={{display:"flex",alignItems:"center"}}>
                <MailOutlineIcon sx={{fontSize:"inherit"}}/>
                <Typography>&nbsp;
                  {/* <Link to={'#'} className="no-underline" style={{color:"cornflowerblue"}}>hello@taxslip.ca</Link> */}
                  <a href="mailto:hello@taxslips.ca" className="no-underline">hello@taxslips.ca</a>
                  </Typography>
              </Box>
          </Grid>
      </Grid>
      <hr style={{borderColor:"rgba(0,0,0,.12)", margin:"0 28px",borderWidth:"thin 0 0"}}/>

      {/* Table */}
      {invoiceDetailData && ( 
        <TableContainer sx={{p:"16px 28px 28px 28px"}}>
          <Table aria-label="simple table">
            <TableHead sx={{
              "& .MuiTableCell-head":{
                fontWeight:"bold"
              }
            }}>
              <TableRow>
                  <TableCell sx={{width:"10px",textAlign:"center"}}>#</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell sx={{width:"150px",textAlign:"end"}}>Quantity</TableCell>
                  <TableCell sx={{width:"150px",
                    textAlign:"end"
                    }}>Unit Price</TableCell>
                  <TableCell sx={{width:"150px",
                    textAlign:"end",paddingRight:"1rem!important"
                    }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{
                    "& .MuiTableRow-root":{
                      "&:last-child":{
                        "& .MuiTableCell-root":{
                        borderBottom:"none"
                    }},
                    }
                  }}>
                {invoiceDetailData.detail.map((item:any,index:any) => (
                  <TableRow hover key={index} 
                  
                  >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.product}</TableCell>
                  <TableCell sx={{textAlign:"end"}}>
                    <Chip
                      label={item.quantity}
                      sx={{ borderRadius: "40%" }}
                      />&nbsp;x</TableCell>
                  <TableCell sx={{textAlign:"end"}}>$&nbsp;{item.unitPrice.toFixed(2)}</TableCell>
                  <TableCell sx={{textAlign:"end"}}>
                    <Chip
                        label={`$ ${item.amount.toFixed(2)}`}
                        sx={{ borderRadius: "5px" }}
                        color="primary"
                        />
                    </TableCell>
                </TableRow>
                ))}
              </TableBody>
              <TableFooter sx={{background:"#f9f9f9",
                "& .MuiTableCell-footer":{
                  fontSize:"large"
                }}}>
                <TableRow>
                  <TableCell colSpan={4} align="right" sx={{borderBottom:"none"}}>Total Amount</TableCell>
                  <TableCell sx={{borderBottom:"none",textAlign:"end"}}>${invoiceDetailData.totalAmount.toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  {/* <TableCell colSpan={4} align="right" sx={{borderBottom:"none"}}>Tax Amount (GST : {invoiceDetailData && ((invoiceDetailData.taxAmount * 100) / (invoiceDetailData.totalAmount)).toFixed(2)}%)</TableCell> */}
                  <TableCell colSpan={4} align="right" sx={{borderBottom:"none"}}>Tax Amount ({invoiceDetailData.taxLabel} : {(invoiceDetailData.taxPercent).toFixed(2)}%)</TableCell>
                  <TableCell sx={{borderBottom:"none",textAlign:"end"}}>${invoiceDetailData.taxAmount.toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right" sx={{borderBottom:"none"}}>Discount</TableCell>
                  <TableCell sx={{borderBottom:"none",textAlign:"end"}}>${invoiceDetailData.discount.toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right" sx={{borderBottom:"none"}}>Handling Charges	</TableCell>
                  <TableCell sx={{borderBottom:"none",textAlign:"end"}}>${invoiceDetailData.handlingCharges.toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} align="right" sx={{fontWeight:"bold",borderBottom:"none"}}>Net Amount</TableCell>
                  <TableCell sx={{fontWeight:"bold",borderBottom:"none",textAlign:"end"}}>${invoiceDetailData.netAmount.toFixed(2)}</TableCell>
                </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
			</Paper>
      </Grid>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"T4A-list-snackbar"}
        open={snackbarData.open}
      />
    </>
  );
};
