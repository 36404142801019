import { EnhancedDropDownRHFWithLabel, LabelPosition } from '@websential/cosmic'
import { IDropDownListResponse } from 'models'
import React, { useEffect } from 'react'
export interface IUpdateDialogPanel {
    selectedRecordData:any;
    keepRecordRowData:any;
    formMethods:any;
}

export const UpdateDialogPanel: React.FC<IUpdateDialogPanel> = ({
    selectedRecordData,
    keepRecordRowData,
    formMethods
}) => {
    let finalKeepInRecordData:any = undefined
    let firstName:any = [{
      label: selectedRecordData?.resource?.firstName,
      value: selectedRecordData?.resource?.firstName,
    }]
    let lastName:any = [{
      label: selectedRecordData?.resource?.lastName,
      value: selectedRecordData?.resource?.lastName,
    }]
    let socialInsuranceNumber:any = [{
      label: selectedRecordData?.resource?.socialInsuranceNumber,
      value: selectedRecordData?.resource?.socialInsuranceNumber,
    }]
    let dateOfBirth:any = [{
      label: selectedRecordData?.resource?.dateOfBirth,
      value: selectedRecordData?.resource?.dateOfBirth,
    }]
    let address:any = [{
      label: selectedRecordData?.resource?.address,
      value: selectedRecordData?.resource?.address,
    }]
    let city:any = [{
      label: selectedRecordData?.resource?.city,
      value: selectedRecordData?.resource?.city,
    }]
    let province:any = [{
      label: selectedRecordData?.resource?.province?.name,
      value: `{id:${selectedRecordData?.resource?.province?.id}, name: "${selectedRecordData?.resource?.province?.name}",shortCode:"${selectedRecordData?.resource?.province?.shortCode}"}`,
    }]
    let postCode:any = [{
      label: selectedRecordData?.resource?.postCode,
      value: selectedRecordData?.resource?.postCode,
    }]
    let email:any = [{
      label: selectedRecordData?.resource?.email,
      value: selectedRecordData?.resource?.email,
    }]

      
      if(selectedRecordData && selectedRecordData.length !== undefined){
        let firstNameData: IDropDownListResponse[] = selectedRecordData.map(
            (item: any) => ({
                label: item?.resource?.firstName,
                value: item?.resource?.firstName,
            })
        );
        firstName = firstNameData
        let lastNameData: IDropDownListResponse[] = selectedRecordData.map(
            (item: any) => ({
                label: item?.resource?.lastName,
                value: item?.resource?.lastName,
            })
        );
        lastName = lastNameData
        let socialInsuranceNumberData: IDropDownListResponse[] = selectedRecordData.map(
            (item: any) => ({
                label: item?.resource?.socialInsuranceNumber,
                value: item?.resource?.socialInsuranceNumber,
            })
        );
        socialInsuranceNumber = socialInsuranceNumberData
        let dateOfBirthData: IDropDownListResponse[] = selectedRecordData.map(
            (item: any) => ({
                label: item?.resource?.dateOfBirth,
                value: item?.resource?.dateOfBirth,
            })
        );
        dateOfBirth = dateOfBirthData
        let addressData: IDropDownListResponse[] = selectedRecordData.map(
            (item: any) => ({
                label: item?.resource?.address,
                value: item?.resource?.address,
            })
        );
        address = addressData
        let cityData: IDropDownListResponse[] = selectedRecordData.map(
            (item: any) => ({
                label: item?.resource?.city,
                value: item?.resource?.city,
            })
        );
        city = cityData
        let provinceData: IDropDownListResponse[] = selectedRecordData.map(
            (item: any) => ({
                label: item?.resource?.province?.name,
                value: `{id:${item?.resource?.province?.id}, name: "${item?.resource?.province?.name}",shortCode:"${item?.resource?.province?.shortCode}"}`,
            })
        );
        province = provinceData
        let postCodeData: IDropDownListResponse[] = selectedRecordData.map(
            (item: any) => ({
                label: item?.resource?.postCode,
                value: item?.resource?.postCode,
            })
        );
        postCode = postCodeData
        let emailData: IDropDownListResponse[] = selectedRecordData.map(
            (item: any) => ({
                label: item?.resource?.email,
                value: item?.resource?.email,
            })
        );
        email = emailData
        } 


if(keepRecordRowData.length > 1 && keepRecordRowData.length > 1){
  for (const value of keepRecordRowData) {
    if (selectedRecordData.includes(value)) {
      console.log('Yes',value);
      finalKeepInRecordData = value
    } 
  }
} else {
  finalKeepInRecordData = keepRecordRowData[0]
}

useEffect(()=>{
  if(finalKeepInRecordData){
    formMethods.setValue('firstName',finalKeepInRecordData?.resource?.firstName)
    formMethods.setValue('lastName',finalKeepInRecordData?.resource?.lastName)
    formMethods.setValue('socialInsuranceNumber',finalKeepInRecordData?.resource?.socialInsuranceNumber)
    formMethods.setValue('dateOfBirth',finalKeepInRecordData?.resource?.dateOfBirth)
    formMethods.setValue('address',finalKeepInRecordData?.resource?.address)
    formMethods.setValue('city',finalKeepInRecordData?.resource?.city)
    formMethods.setValue('province',`{id:${finalKeepInRecordData?.resource?.province?.id}, name: "${finalKeepInRecordData?.resource?.province?.name}",shortCode:"${finalKeepInRecordData?.resource?.province?.shortCode}"}`)
    formMethods.setValue('postCode',finalKeepInRecordData?.resource?.postCode)
    formMethods.setValue('email',finalKeepInRecordData?.resource?.email)


    console.log("finalKeepInRecordData?.resource?.firstName",finalKeepInRecordData?.resource?.firstName)
  } 
},[])

console.log("selectedRecordData",selectedRecordData)
console.log("selectedRecordData.length",selectedRecordData.length)
console.log("keepRecordRowData",keepRecordRowData)
console.log("keepRecordRowData.length",keepRecordRowData.length)
// console.log("dateOfBirth",dateOfBirth)
console.log("finalKeepInRecordData",finalKeepInRecordData)
console.log(`{id:${keepRecordRowData[0]?.resource?.province?.id}, name: "${keepRecordRowData[0]?.resource?.province?.name}",shortCode:"${keepRecordRowData[0]?.resource?.province?.shortCode}"}`)
  return (
    <>
       <EnhancedDropDownRHFWithLabel
        label="First Name"
        selectName="firstName"
        items={firstName}
        // selectedItem={keepRecordRowData.length > 1 && finalKeepInRecordData ? finalKeepInRecordData?.resource?.firstName : keepRecordRowData[0]?.resource?.firstName}
        testId="selected-first-name"
        // onSelectionChangeHandler={
        //     onFirstNameSelectionChangeHandler
        // }
        labelPosition={LabelPosition.Left}
        labelColumns={2}
        fieldColumns={10}
      />
        <EnhancedDropDownRHFWithLabel
        label="Last Name"
        selectName="lastName"
        items={lastName}
        // selectedItem={keepRecordRowData.length > 1 && finalKeepInRecordData ? finalKeepInRecordData?.resource?.lastName : keepRecordRowData[0]?.resource?.lastName}
        testId="selected-last-name"
        labelPosition={LabelPosition.Left}
        labelColumns={2}
        fieldColumns={10}
      />
       <EnhancedDropDownRHFWithLabel
        label="SIN"
        selectName="socialInsuranceNumber"
        items={socialInsuranceNumber}
        // selectedItem={keepRecordRowData.length > 1 && finalKeepInRecordData ? finalKeepInRecordData?.resource?.socialInsuranceNumber : keepRecordRowData[0]?.resource?.socialInsuranceNumber}
        testId="selected-sin"
        labelPosition={LabelPosition.Left}
        labelColumns={2}
        fieldColumns={10}
      />
      <EnhancedDropDownRHFWithLabel
        label="Date Of Birth"
        selectName="dateOfBirth"
        items={dateOfBirth}
        // selectedItem={keepRecordRowData.length > 1 && finalKeepInRecordData ? finalKeepInRecordData?.resource?.dateOfBirth : keepRecordRowData[0]?.resource?.dateOfBirth}
        testId="selected-dob"
        labelPosition={LabelPosition.Left}
        labelColumns={2}
        fieldColumns={10}
      />
        <EnhancedDropDownRHFWithLabel
        label="Address"
        selectName="address"
        items={address}
        // selectedItem={keepRecordRowData.length > 1 && finalKeepInRecordData ? finalKeepInRecordData?.resource?.address : keepRecordRowData[0]?.resource?.address}
        testId="selected-address"
        labelPosition={LabelPosition.Left}
        labelColumns={2}
        fieldColumns={10}
      />
     <EnhancedDropDownRHFWithLabel
        label="City"
        selectName="city"
        items={city}
        // selectedItem={keepRecordRowData.length > 1 && finalKeepInRecordData ? finalKeepInRecordData?.resource?.city : keepRecordRowData[0]?.resource?.city}
        testId="selected-city"
        labelPosition={LabelPosition.Left}
        labelColumns={2}
        fieldColumns={10}
      />
      <EnhancedDropDownRHFWithLabel
        label="Province"
        selectName="province"
        items={province}
        // selectedItem={keepRecordRowData.length > 1 && finalKeepInRecordData ? `{id:${finalKeepInRecordData?.resource?.province?.id}, name: "${finalKeepInRecordData?.resource?.province?.name}",shortCode:"${finalKeepInRecordData?.resource?.province?.shortCode}"}` : `{id:${keepRecordRowData[0]?.resource?.province?.id}, name: "${keepRecordRowData[0]?.resource?.province?.name}",shortCode:"${keepRecordRowData[0]?.resource?.province?.shortCode}"}`}
        testId="selected-province"
        labelPosition={LabelPosition.Left}
        labelColumns={2}
        fieldColumns={10}
      /> 
        <EnhancedDropDownRHFWithLabel
        label="Post Code"
        selectName="postCode"
        items={postCode}
        // selectedItem={keepRecordRowData.length > 1 && finalKeepInRecordData ? finalKeepInRecordData?.resource?.postCode : keepRecordRowData[0]?.resource?.postCode}
        testId="selected-postal-code"
        labelPosition={LabelPosition.Left}
        labelColumns={2}
        fieldColumns={10}
      />
       <EnhancedDropDownRHFWithLabel
        label="Email"
        selectName="email"
        items={email}
        // selectedItem={keepRecordRowData.length > 1 && finalKeepInRecordData ? finalKeepInRecordData?.resource?.email : keepRecordRowData[0]?.resource?.email}
        testId="selected-email"
        labelPosition={LabelPosition.Left}
        labelColumns={2}
        fieldColumns={10}
      />
    </>
  )
}
