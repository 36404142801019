import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import { FC } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";

export interface IEmployerDetailPanel {
  formMethods: any;
}

export const EmployerDetailPanel: FC<IEmployerDetailPanel> = ({formMethods}) => {
  const taxYear = useSelector((state : IAppState) => state.taxYear.year)
  const handleInputChange = (e: any) => {
    const newValue = e.target.value;
    console.log("newValue", newValue);
    // if(newValue !== ''){
      const totalDeductionReported = formMethods.getValues("totalDeductionReported")
      console.log("totalDeductionReportedValue", totalDeductionReported);
      const minus = totalDeductionReported - newValue
      formMethods.setValue("difference", Math.abs(minus).toFixed(2));
      console.log("minusminus",minus)
      if(minus < 0){
        // console.log("Minus value ",Math.abs(minus))
        formMethods.setValue("overpayment", Math.abs(minus).toFixed(2));
        formMethods.setValue("balanceDue", '0.00');
      } else {
        console.log("positive value ",minus)
        formMethods.setValue("balanceDue", minus.toFixed(2));
        formMethods.setValue("overpayment", '0.00');
      }

    // }
  };
  return (
    <>
      <TaxSlipSummaryDataInput
        id="employeesCppContribution"
        label="Employee's CPP Contribution"
        boxNumber="16"
      />
      {taxYear > 2023 ? 
      <TaxSlipSummaryDataInput
        id="employeesSecondCppContribution"
        label="Employee's Second CPP Contribution"
        boxNumber="16A"
      /> : <></>}
      <TaxSlipSummaryDataInput
        id="employersCppContribution"
        label="Employer's CPP Contribution"
        boxNumber="27"
      />
      {taxYear > 2023 ? 
      <TaxSlipSummaryDataInput
        id="employersSecondCppContribution"
        label="Employer's Second CPP Contribution"
        boxNumber="27A"
      />: <></>}
      <TaxSlipSummaryDataInput
        id="employeesEiContribution"
        label="Employee's EI Contribution"
        boxNumber="18"
      />
      <TaxSlipSummaryDataInput
        id="employersEiContribution"
        label="Employer's EI Contribution"
        boxNumber="19"
      />
      <TaxSlipSummaryDataInput
        id="incomeTaxDeducted"
        label="Income Tax Deducted"
        boxNumber="22"
      />
      <TaxSlipSummaryDataInput
        id="totalDeductionReported"
        label={taxYear > 2023 ? "Total Deduction Reported (16 + 16A + 27 + 27A + 18 + 19 + 22)" : "Total Deduction Reported (16 + 27 + 18 + 19 + 22)"}
        boxNumber="80"
      />
      <TaxSlipSummaryDataInput
        id="minusRemittance"
        label="Minus Remittance"
        boxNumber="82"
        readOnly={false}
        onChange={handleInputChange}
      />
    </>
  );
};
