import { FC, useEffect, useState } from "react";

import {
  BusinessNumberRHF,
  EnhancedDropDownRHFWithLabel,
  EnhancedIOSSwitchRHFWithLabel,
  ITest,
  LabelPosition,
  RHookFormDatePicker,
  RHookFormMaskedTextFieldWithLabel,
  SinNumberRHF,
} from "@websential/cosmic";

import { FieldWrapper } from "../../../FieldWrapper/FieldWrapper";
import { RecipentTypeFields } from "./RecipentTypeFields/RecipentTypeFields";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { T5SlipService } from "services/T5SlipService";
import { IDropDownListResponse } from "models";
import { T5_RECIPIENT_RESOURCE_LITERAL } from "common/constants";

interface IVendorInfoSetupFieldsPanel extends ITest {
  formMethods: any;
  vendorDDL: any[];
  individualChangeHandler?: (value: boolean) => void;
  isLoading: (value: boolean) => void;
  resourceDDLWithRecipientType: (value: any) => void;
  resourceDDLWithoutCreateNewWithRecipientType: (value: any) => void;
  globalVariablesData: any;
}

export const VendorInfoSetupFieldsPanel: FC<IVendorInfoSetupFieldsPanel> = ({
  testId,
  formMethods,
  vendorDDL,
  individualChangeHandler,
  isLoading,
  resourceDDLWithoutCreateNewWithRecipientType,
  resourceDDLWithRecipientType,
  globalVariablesData,
}) => {
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [selectedValue, setSelectedValue] = useState(10);

  const onChange = async (event: any) => {
    const value = event.value;
    console.log("eventttt", value);
    setSelectedValue(value);
    //null all values for change selection
    if (value === 30) {
      //   console.log("kshkjsnbvkjb")
      formMethods.setValue("actualAmountOfEligibleDivinends", "0.00");
      formMethods.setValue("actualAmountOfOrdinaryDivinends", "0.00");
      formMethods.setValue("taxableAmountOfDividends", "0.00");
      formMethods.setValue("dividendTaxCredit", "0.00");
    }
    //  else {
    //   let box24Value = parseInt(formMethods.getValues('actualAmountOfEligibleDivinends'))
    //   if(box24Value > 0){
    //     if (globalVariablesData && globalVariablesData?.data?.t5) {
    //       // let box25CalculatedValue = ((box24Value * 0.38) + box24Value);
    //       // let box26CalculatedValue = (box25CalculatedValue * 0.150198);
    //       let box25CalculatedValue = (box24Value * (globalVariablesData?.data?.t5?.eligibleGrossUpFactor/100)) + value;
    //       let box26CalculatedValue = box25CalculatedValue * (globalVariablesData?.data?.t5?.eligibleDividendTaxCreditPercentageOfTaxableAmount/100);
    //       formMethods.setValue('taxableAmountOfEligibleDividends',box25CalculatedValue.toFixed(2))
    //       formMethods.setValue('dividendTaxCreditForEligibleDividends',box26CalculatedValue.toFixed(2))
    //     }
    //   } else {
    //     formMethods.setValue('taxableAmountOfEligibleDividends',"0.00")
    //     formMethods.setValue('dividendTaxCreditForEligibleDividends',"0.00")
    //   }
    //   let box10Value = parseInt(formMethods.getValues('actualAmountOfDivinendsOtherThanEligible'))
    //   if(box10Value > 0){
    //     if (globalVariablesData && globalVariablesData?.data?.t5) {
    //       // let box11CalculatedValue = ((box10Value * 0.15) + box10Value);
    //       // let box12CalculatedValue = ((box11CalculatedValue * 9.0301)/100);
    //       let box11CalculatedValue = (box10Value * (globalVariablesData?.data?.t5?.nonEligibleGrossUpFactor/100)) + value;
    //       let box12CalculatedValue = (box11CalculatedValue * (globalVariablesData?.data?.t5?.nonEligibleDividendTaxCreditPercentageOfTaxableAmount/100)) / 100;
    //       formMethods.setValue('taxableAmountOfDivinendsOtherThanEligible',box11CalculatedValue.toFixed(2))
    //       formMethods.setValue('dividendTaxCreditForDivdendsOtherThanEligible',box12CalculatedValue.toFixed(2))
    //     }
    //   } else {
    //     formMethods.setValue('taxableAmountOfDivinendsOtherThanEligible',"0.00")
    //     formMethods.setValue('dividendTaxCreditForDivdendsOtherThanEligible',"0.00")
    //   }
    // }
    (async () => {
      try {
        isLoading(true);
        let response = await T5SlipService.getT5RecipientDDLByRecipientType(
          clientId,
          T5_RECIPIENT_RESOURCE_LITERAL,
          taxYear,
          value
        );
        console.log("responceresponce", response);
        if (response && Array.isArray(response)) {
          let resources: IDropDownListResponse[] = response.map(
            (resourceItem: IDropDownListResponse) => ({
              // id: resourceItem.id,
              label: resourceItem.label,
              value: resourceItem.value,
            })
          );

          resources.unshift({
            label: "Create New",
            value: "create-new",
          });
          resourceDDLWithRecipientType(resources);
        }
        resourceDDLWithoutCreateNewWithRecipientType(response);
      } catch {
        isLoading(false);
      } finally {
        isLoading(false);
      }
    })();
  };

  const recipientTypeValue = formMethods.getValues("recipientType");
  console.log("recipientTypeValue", recipientTypeValue);
  useEffect(() => {
    if (recipientTypeValue) {
      setSelectedValue(recipientTypeValue);
    }
  }, [recipientTypeValue]);
  return (
    <>
      <FieldWrapper>
        <EnhancedIOSSwitchRHFWithLabel
          name="reflectMasterData"
          checked={false}
          testId="reflectMasterData"
          label="Reflect in Master Data"
          labelPosition={LabelPosition.Left}
        />
      </FieldWrapper>
      <FieldWrapper>
        <EnhancedDropDownRHFWithLabel
          label="Recipient Type"
          selectName="recipientType"
          items={[
            {
              label: "Individual",
              id: "individual-option",
              value: 10,
            },
            {
              label: "Joint Account",
              id: "joint-account-option",
              value: 20,
            },
            {
              label: "Corporation",
              id: "corporation-option",
              value: 30,
            },
            {
              label: "Association, trust, club, partnership",
              id: "Association-trust-club-partnership-option",
              value: 40,
            },
            {
              label: "Government or international organization",
              id: "joint-account-option",
              value: 50,
            },
            {
              label: "Spouse or trust settlor of spouse deceased person",
              id: "spouse-or-trust-settlor-of-spouse-deceased-person",
              value: 60,
            },
            {
              label: "Holder of joint account other than 2",
              id: "holder-of-joint-account-other-than-2",
              value: 70,
            },
          ]}
          selectedItem={selectedValue}
          testId={`${testId}-recipientType`}
          labelPosition={LabelPosition.Left}
          onSelectionChangeHandler={onChange}
          required
        />
      </FieldWrapper>
      {selectedValue === 40 ? (
        <RHookFormMaskedTextFieldWithLabel
          label="Trust account number"
          id="trustAccountNumber"
          testId={`${testId}-rq-transmitter-number`}
          labelPosition={LabelPosition.Left}
          placeholder={"T00000000"}
          required
          inputMaskPattern={"T00000000"}
          labelColumns={4}
          fieldColumns={8}
        />
      ) : selectedValue === 30 ? (
        <BusinessNumberRHF
          id="businessNumber"
          label="Business #"
          labelPosition={LabelPosition.Left}
          testId="BusinessNumber"
          required
          labelColumns={4}
          fieldColumns={8}
          placeholder="000000000RP0000"
        />
      ) : selectedValue === 50 ? (
        <BusinessNumberRHF
          id="businessNumber"
          label="Business #"
          labelPosition={LabelPosition.Left}
          testId="BusinessNumber"
          required
          labelColumns={4}
          fieldColumns={8}
          placeholder="000000000RP0000"
        />
      ) : (
        <SinNumberRHF
          id="uniqueIdentifier"
          label="Social Insurance"
          required
          labelPosition={LabelPosition.Left}
          labelColumns={4}
          fieldColumns={8}
          testId="socialInsuranceNumber"
        />
      )}
      {/* <RHookFormDatePicker
        name="dateOfBirth"
        testId="dateOfBirth"
        label="Date of Birth"
        labelPosition={LabelPosition.Left}
      /> */}
      <RecipentTypeFields
        value={selectedValue}
        testId={"vendor-info"}
        vendorDDL={vendorDDL && vendorDDL}
        formMethods={formMethods}
        individualChangeHandler={individualChangeHandler}
      />
    </>
  );
};
