import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "./invoiceDataTableStyle.css";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import SearchIcon from '@mui/icons-material/Search';
import { RequestParams } from "@websential/cosmic";

export interface IDataTable {
  rows: any;
  columns: any;
  showIndexColumn: boolean;
  showChecboxColumn: boolean;
  showSettingColumn: boolean;
  onRowDeleteClicked: (params: GridRenderCellParams) => void;
  isLoading?: boolean;
  onSnackbarMessageData: (value: any) => void;
  onRequestParamsChange?: (params: RequestParams) => void;
  initialPageSize?: number | undefined;
  initialPage?: number;
  totalRecords?: number;
}

export const PrimeDataGridInvoiceAdminReport: React.FC<IDataTable> = ({
  rows,
  columns,
  showIndexColumn,
  showChecboxColumn,
  showSettingColumn,
  onRowDeleteClicked,
  isLoading,
  onSnackbarMessageData,
  onRequestParamsChange,
  initialPageSize = 10,
  initialPage = 0,
  totalRecords,
}) => {
  const isInitialRequest = useRef(true);
    const [itemSearch, setItemSearch] = useState('');
    const [requestParams, setRequestParams] = useState<any>({
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        sortField: null,
        sortOrder: null,
        searchQuery:"",
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

  const [selectedItems, setSelectedItems] = useState<any>([]);
  
  const setting = () => {
    return (
      <div style={{ marginLeft: "0.35rem" }}>
        <i className="pi pi-cog text-xl"></i>
      </div>
    );
  };
  const delEdit = (params: any) => {
    return (
      <>
      </>
    );
  };
    const [itemSearsch, setItemSearsch] = useState('');
  const onSearchButtonClicked = () => {
    setRequestParams((prevParams:any) => ({
          ...prevParams,
          searchQuery: itemSearch,
        }));
  }
  const handleClearSearch = () => {
      setItemSearch('')
      setRequestParams((prevParams:any) => ({
          ...prevParams,
          searchQuery: "",
        }));
  }
    const renderHeader = () => {
        return (
            //  <div className='flex justify-content-between pt-4 pb-2 pl-3 pr-3 align-items-center'  style={{backgroundColor:"#ebecf0",margin:"-17px"}}>
            //     <span className="p-input-icon-left w-3" style={{padding:"8.5px 14px 8.5px 0"}}>
            //         <i className="pi pi-search" />
            //         <InputText value={itemSearsch} onChange={(e) => setItemSearsch(e.target.value)} placeholder="Search By Client" className='w-full bg-transparent'/>
            //     </span>
            //     {selectedItems.length > 0 ?
            //     (
            //     <Button
            //       icon="pi pi-trash"
            //       onClick={() =>
            //         onRowDeleteClicked(selectedItems)
            //       }
            //       severity="danger"
            //     />):(<></>)}
            // </div>
            <div  style={{display:"flex",justifyContent:"space-between"}}>
            <span className="p-input-icon-left w-5" style={{display:"flex"}}>
                    <i className="pi pi-search" />
                    <InputText value={itemSearch} onChange={(e) => setItemSearch(e.target.value)} placeholder="Search by Client Name" className='w-full' style={{borderRadius:"5px 0px 0px 5px"}}/>
                    {itemSearch && (
                        <Button
                        icon="pi pi-times"
                        onClick={handleClearSearch}
                        className="p-button-warning"
                        style={{borderRadius:0}}
                        />
                    )}
                    <Button
                        onClick={onSearchButtonClicked}
                        // variant="contained"
                        style={{
                          padding:"8px 10px 8px 10px",
                          marginRight:"-14px!important",
                          borderRadius:"0px 5px 5px 0px"}}
                      >
                        <SearchIcon />
                      </Button>

                </span>
                      {selectedItems.length > 0 ?
                        (
                        <Button
                          icon="pi pi-trash"
                          onClick={() =>
                            onRowDeleteClicked(selectedItems)
                          }
                          severity="danger"
                        />):(<></>)}
            </div>
        )
    }
    const headerSearch = renderHeader();
    function search(_row:any){
        return rows && rows.filter((rows:any)=> rows.clientName.props.children.toLowerCase().indexOf(itemSearsch.toLowerCase())>-1 )
    }
  // Custom column for row indices
  const indexColumn = {
    body: (rowData:any, row:any) => {
      return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
    },
    header: '#',
  };

  const onPage = (event:any) => {
    setRequestParams(event);
};
// This useEffect is triggered when the debouncedSearchQuery, pageSize or currentPage values change
useEffect(() => {
  // if the results fetcher was passed, we can use it to fetch the results.
  if (onRequestParamsChange) {
    // skip the first initial call
    if (isInitialRequest.current === true) {
      console.log(
        "##### => useEffect - requestParams - no first call",
        requestParams
      );
      isInitialRequest.current = false;
    } else {
      console.log(
        "##### => useEffect - requestParams - second call",
        requestParams
      );
      onRequestParamsChange(requestParams);
    }
  }
}, [onRequestParamsChange, requestParams]);
  console.log("props headerColumns => ",columns)
  console.log("props items  => ",rows)
  console.log("selectedItems => ",selectedItems)
  return (
      <DataTable
        value={rows && rows}
        responsiveLayout="scroll"
        lazy 
        filterDisplay="row"
        // dataKey="id" 
        paginator
        first={requestParams.first} 
        rows={requestParams.rows}
        totalRecords={totalRecords}
        onPage={onPage}
        // paginator
        // rows={25}
        rowsPerPageOptions={[25, 50, 100]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={`Showing {first} to {last} of ${totalRecords} Items`}
        selection={selectedItems}
        onSelectionChange={(e: any) => setSelectedItems(e.value)}
        editMode="row"
        showGridlines
        resizableColumns
        columnResizeMode="expand"
        scrollable
        // scrollHeight="450px"
        reorderableColumns
        // filterDisplay="menu"
        loading={isLoading}
        // globalFilterFields={["headerName"]}
        header={headerSearch}
        emptyMessage="No Record found."
        className="invoiceAdminDataTable"
        style={{ margin: "1.5rem 0.5rem 0.5rem 0.5rem", border:"2px solid #dee2e6"}}
      >
        <Column
          selectionMode="multiple"
          hidden={!showChecboxColumn}
          frozen
          resizeable={false}
          style={{ maxWidth: "65px" }}
          field="selection"
          columnKey="selection"
        ></Column>

        <Column 
          {...indexColumn} 
          frozen
          hidden={!showIndexColumn}
          footer="Total"
          />

        {columns &&
          columns.map((x: any, index: any) => (
            <Column
              key={index}
              field={x.field}
              header={x.headerName}
              headerTooltip={x.description}
              headerTooltipOptions={{ position: "top" }}
              // flex (This property is not use Yet)
              hidden={x.hide}
              sortable={x.sortable}
              resizeable={x.resizable}
              dataType={x.type}
              align={x.align}
              alignHeader={x.headerAlign}
              // hideSortIcons (This property is not use Yet)
              // filterElement={HideButton(x.hideable, x, x.field)}
              // showFilterMenuOptions
              // filter
              // showFilterMenu={x.disableColumnMenu}
              // filterPlaceholder="Search by name"
              reorderable={x.disableReorder}
              exportable={x.disableExport}
              frozen={x.frozenColumn}
              alignFrozen={x.alignFrozen}
              columnKey={x.field}
              style={{
                minWidth: x.minWidth,
                fontSize: "0.875rem"
              }}
              footer={x.footer}
              // body={rows.employmentIncome}
            />
          ))}

        <Column
          body={delEdit}
          header={setting}
          style={{ maxWidth: "65px" }}
          hidden={!showSettingColumn}
          resizeable={false}
          reorderable={false}
          frozen
          alignFrozen="right"
        ></Column>
      </DataTable>
  );
};

//resizeablbe must be false if frozen is true and viseversa
