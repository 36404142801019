import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import { FC } from "react";

export interface ITotalBalancePanel {}

export const TotalBalancePanel: FC<ITotalBalancePanel> = () => {
  return (
    <>
      <TaxSlipSummaryDataInput
        id="overpayment"
        label="Over Payment"
        boxNumber="084"
      />
      <TaxSlipSummaryDataInput
        id="balanceDue"
        label="Balance Due"
        boxNumber="086"
      />
      <TaxSlipSummaryDataInput
        id="amountEnclosed"
        label="Amount Enclosed"
        boxNumber="088"
        readOnly={false}
      />
    </>
  );
};
