import { ChangePassword } from "components/ChangePassword";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const ChangePasswordPage = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Change Password"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Change Password', navigateTo: '/me/change-password'}]}
      mainContents={<ChangePassword testId="data-role-setup-page" />}
    />
  );
};
