import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { RL16Report } from "components/Reports/RL16Report";


export interface IRL16ReportPage {}

export const RL16ReportPage: FC<IRL16ReportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="RL-16 Report"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<RL16Report />}
    />
  );
};
