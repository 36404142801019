import { FlexFieldList } from "components/FlexField";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IFlexFieldListPage {}

export const FlexFieldListPage: FC<IFlexFieldListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Flex Fields List"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Flex Fields', navigateTo: '/flex-fields/list'}]}
      mainContents={<FlexFieldList />}
    />
  );
};
