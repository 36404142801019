import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface RL1SummaryEnglishAxisPages3 {}
const RL1SummaryEnglishAxisPages3:React.FC<RL1SummaryEnglishAxisPages3> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'nameOfEmployerP3X'} 
                testId={'nameOfEmployerP3X'} 
                label={'Name Of Employer'} 
                labelPosition={LabelPosition.Left}
                defaultValue={30}
            />
            <RHookFormTextFieldWithLabel 
                id={'mailingAdressP3X'} 
                testId={'mailingAdressP3X'} 
                label={'Mailing Adress'} 
                labelPosition={LabelPosition.Left}
                defaultValue={30}
            />
            <RHookFormTextFieldWithLabel 
                id={'box90P3X'} 
                testId={'box90P3X'} 
                label={'Box 90'} 
                labelPosition={LabelPosition.Left}
                defaultValue={350}
            />
            <RHookFormTextFieldWithLabel 
                id={'box91P3X'} 
                testId={'box91P3X'} 
                label={'Box 91'} 
                labelPosition={LabelPosition.Left}
                defaultValue={452}
            />
            <RHookFormTextFieldWithLabel 
                id={'box92P3X'} 
                testId={'box92P3X'} 
                label={'Box 92'} 
                labelPosition={LabelPosition.Left}
                defaultValue={554}
            />
            <RHookFormTextFieldWithLabel 
                id={'box93P3X'} 
                testId={'box93P3X'} 
                label={'Box 93'} 
                labelPosition={LabelPosition.Left}
                defaultValue={350}
            />
            <RHookFormTextFieldWithLabel 
                id={'box94P3X'} 
                testId={'box94P3X'} 
                label={'Box 94'} 
                labelPosition={LabelPosition.Left}
                defaultValue={452}
            />
            <RHookFormTextFieldWithLabel 
                id={'box95P3X'} 
                testId={'box95P3X'} 
                label={'Box 95'} 
                labelPosition={LabelPosition.Left}
                defaultValue={554}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'nameOfEmployerP3Y'} 
                testId={'nameOfEmployerP3Y'} 
                label={'Name Of Employer'} 
                labelPosition={LabelPosition.Left}
                defaultValue={115}
            />
            <RHookFormTextFieldWithLabel 
                id={'mailingAdressP3Y'} 
                testId={'mailingAdressP3Y'} 
                label={'Mailing Adress'} 
                labelPosition={LabelPosition.Left}
                defaultValue={110}
            />
            <RHookFormTextFieldWithLabel 
                id={'box90P3Y'} 
                testId={'box90P3Y'} 
                label={'Box 90'} 
                labelPosition={LabelPosition.Left}
                defaultValue={135}
            />
            <RHookFormTextFieldWithLabel 
                id={'box91P3Y'} 
                testId={'box91P3Y'} 
                label={'Box 91'} 
                labelPosition={LabelPosition.Left}
                defaultValue={135}
            />
            <RHookFormTextFieldWithLabel 
                id={'box92P3Y'} 
                testId={'box92P3Y'} 
                label={'Box 92'} 
                labelPosition={LabelPosition.Left}
                defaultValue={135}
            />
            <RHookFormTextFieldWithLabel 
                id={'box93P3Y'} 
                testId={'box93P3Y'} 
                label={'Box 93'} 
                labelPosition={LabelPosition.Left}
                defaultValue={85}
            />
            <RHookFormTextFieldWithLabel 
                id={'box94P3Y'} 
                testId={'box94P3Y'} 
                label={'Box 94'} 
                labelPosition={LabelPosition.Left}
                defaultValue={85}
            />
            <RHookFormTextFieldWithLabel 
                id={'box95P3Y'} 
                testId={'box95P3Y'} 
                label={'Box 95'} 
                labelPosition={LabelPosition.Left}
                defaultValue={85}
            />
        </Grid>
    </Grid>
  )
}

export default RL1SummaryEnglishAxisPages3