import { FC, useState } from "react";

import {
  EnhancedDatePicker,
  EnhancedIOSSwitchRHFWithLabel,
  FieldLayout,
  ITest,
  LabelPosition,
  RHookFormDatePicker,
  RHookFormMaskedTextFieldWithLabel,
  RHookFormTextField,
  RHookFormTextFieldWithLabel,
  SinNumber,
  SinNumberRHF,
} from "@websential/cosmic";

import { FieldWrapper } from "../../../FieldWrapper/FieldWrapper";

import { DatePicker, DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Stack, TextField } from "@mui/material";

import { Calendar } from 'primereact/calendar';
import { useController, useFormContext } from 'react-hook-form';
import { EnhancedTextField } from "@websential/cosmic/build/components/EnhancedTextField/EnhancedTextField";



interface IEmployeeInfoSetupFieldsPanel extends ITest {
  onDateOfBirthChangeHandler: (selectedDate: any) => void;
}



export const EmployeeInfoSetupFieldsPanel: FC<IEmployeeInfoSetupFieldsPanel> =
  ({ onDateOfBirthChangeHandler, testId }) => {

    // const [selectedDate, setSelectedDate] = useState<Date | null>(null)
    // const [dateOfBirth, setDateOfBirth] = useState<string>('');

    // const handleDateOfBirthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setDateOfBirth(event.target.value);
    // };

    // const {
    //   register,
    //   // formState: { errors },
    //   // control
    // } = useFormContext();

    // const {
    //   field: { onChange,  },
    //   formState: { errors },
    // } = useController({
    //   name: onChange,
    //   // defaultValue: value,
    // });
  
    return (
      <>
        <FieldWrapper>
          <EnhancedIOSSwitchRHFWithLabel
            // name="reflectInEmployee"
            name="reflectMasterData"
            checked={false}
            testId="reflectInEmployee"
            label="Reflect in Employee"
            labelPosition={LabelPosition.Left}
          />
        </FieldWrapper>
        <RHookFormTextFieldWithLabel
          label="Employee Code"
          id="employeeCode"
          testId={`${testId}-employee-code`}
          labelPosition={LabelPosition.Left}
        />
        {/* <RHookFormTextFieldWithLabel
          label="Social Insurance"
          id="uniqueIdentifier"
          testId={`${testId}-social-insurance`}
          labelPosition={LabelPosition.Left}
          required
        /> */}
          <SinNumberRHF
            id="uniqueIdentifier"
            label="Social Insurance"
            required={true}
            labelPosition={LabelPosition.Left}
            labelColumns={4}
            fieldColumns={8}
            testId="uniqueIdentifier"
          />
        <RHookFormTextFieldWithLabel
          label="First Name"
          id="name1"
          testId={`${testId}-first-name`}
          labelPosition={LabelPosition.Left}
          required
        />
        {/* <RHookFormTextFieldWithLabel
          label="Initials"
          id="initials"
          testId={`${testId}-initials`}
          labelPosition={LabelPosition.Left}
        /> */}
        <RHookFormMaskedTextFieldWithLabel
            label="Initials"
            id="initials"
            testId={`${testId}-initials`}
            labelPosition={LabelPosition.Left}
            inputMaskPattern={"a"}
        />
        <RHookFormTextFieldWithLabel
          label="Last Name"
          id="name2"
          testId={`${testId}-last-name`}
          labelPosition={LabelPosition.Left}
          required
        />
        {/* <FieldWrapper>
          <EnhancedDatePicker
            name="dateOfBirth"
            onChange={onDateOfBirthChangeHandler}
            labelPosition={LabelPosition.Left}
            testId="date-of-birth"
            label="Date of Birth"
          />
        </FieldWrapper> */}
        {/* <FieldWrapper>
          <FieldLayout
              label="Date of Birth"
              // isRequired={isRequired}
              testId={testId}
              labelPosition={LabelPosition.Left}
              labelColumns={4}
              fieldColumns={8}
              >
                <Calendar
                    id="dateOfBirth"
                    {...register('dateOfBirth')}
                    name="dateOfBirth"
                    onChange={onDateOfBirthChangeHandler} // Combine the onChange logic
                    value=''
                    showIcon
                    dateFormat="dd/mm/yy" // Customize the date format as needed
                    placeholder="dd/mm/yyyy"
                    className="w-full"
                    style={{ height: '2.6rem' }}
                    // ref={register('dateOfBirth')} // You can keep the register call for form control
                  />
          </FieldLayout>
        </FieldWrapper> */}
        {/* <TextField id="dateOfBirth" {...register('dateOfBirth')}/> */}
          {/* <TextField
            id="dateOfBirth"
            // label="Date of Birth"
            type="date"
            // value={dateOfBirth}
            {...register('dateOfBirth')}
            // onChange={onDateOfBirthChangeHandler}
            // InputLabelProps={{
            //   shrink: true,
            // }}
          />
        <RHookFormTextField
          // label="DD"
          id="dateOfBirth"
          testId={`${testId}-last-name`}
        /> */}
        {/* <RHookFormDatePicker testId={"t4-setup-date-of-birth"} label={"Date of Birth"} name={"dateOfBirth"} labelPosition={LabelPosition.Left}
         /> */}
      </>
    );
  };
