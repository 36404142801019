import { T5SlipList } from "components/T5Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT5SlipListPagePage {}

export const T5SlipListPage: FC<IT5SlipListPagePage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="T5 Slip List"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t5/list" },
        { text: "T5", navigateTo: "/taxslip/t5/list" },
        { text: "T5 Slips", navigateTo: "/taxslip/t5/list" },
      ]}
      mainContents={<T5SlipList />}
    />
  );
};
