import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { RL1Report } from "components/Reports/RL1Report";

export interface IRL1ReportPage {}

export const RL1ReportPage: FC<IRL1ReportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="RL-1 Report"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<RL1Report />}
    />
  );
};
