export const RL1SlipDataCodeService = {
    get: () => {
      return [
          { label: "CA: Wage earner protection program (WEPP) payments", value: "CA" },
          { label: "CB: Tax-free savings account (TFSA)", value: "CB",},
          { label: "CC: Payments to the beneficiary of a registered disability savings plan (RDSP)", value: "CC",},
          { label: "CD: Benefits paid to parents of murdered or missing children", value: "CD",},
          { label: "RA: Payments received under a supplementary unemployment benefit plan", value: "RA",},
          { label: "RB: Scholarships, bursaries, fellowships and prizes", value: "RB",},
          { label: "RC: Research grants", value: "RC",},
          { label: "RD: Fees for services rendered", value: "RD",},
          { label: "RG: Labour adjustment benefits", value: "RG",},
          { label: "RH: Labour adjustment benefits for older workers and income assistance payments", value: "RH",},
          { label: "RI: Benefits paid under a program established pursuant to an agreement under section 5 of the Department of Fisheries and Oceans Act (Statutes of Canada)", value: "RI",},
          { label: "RJ: Retiring allowance (including an amount paid in respect of the loss of employment)", value: "RJ",},
          { label: "RK: Death benefit", value: "RK",},
          { label: "RL: Patronage dividends", value: "RL",},
          { label: "RM: Commissions paid to a self-employed person", value: "RM",},
          { label: "RN: Benefits paid under a wage loss replacement plan", value: "RN",},
          { label: "RO: Benefits received by a shareholder", value: "RO",},
          { label: "RP: Benefits received by a partner", value: "RP",},
          { label: "RQ: Amounts allocated under a retirement compensation arrangement", value: "RQ",},
          { label: "RR: Payments for services rendered in Québec by a person not resident in Canada", value: "RR",},
          { label: "RS: Financial assistance", value: "RS",},
          { label: "RT: Other indemnities paid by the employer as a result of an industrial accident", value: "RT",},
          { label: "RU: Educational assistance payments from a registered education savings plan (RESP)", value: "RU",},
          { label: "RV: Accumulated income payments from a registered education savings plan (RESP)", value: "RV",},
          { label: "RX: Incentive subsidy to the apprentices", value: "RX",},
          { label: "RZ: Several codes associated with the amount in box O", value: "Z",},
      ];
    }
  };
  