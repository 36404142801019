import { BillingProfileSetup } from "components/BillingProfile";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IBillingProfileSetupPage {}

export const BillingProfileSetupPage: FC<IBillingProfileSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Billing Profile Setup"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{ text: "Billing Profile List", navigateTo: "/billing-profile/list" },
      { text: "Create Billing Profile", navigateTo: "/billing-profile/setup" }
      ]}
      mainContents={<BillingProfileSetup testId="billing-profile-setup" />}
    />
  );
};
