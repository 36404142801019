import { RL1SlipSetup } from "components/RL1Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL1SlipSetupPage {}

export const RL1SlipSetupPage: FC<IRL1SlipSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Create RL-1 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl1/list" },
        { text: "RL-1", navigateTo: "/taxslip/rl1/list" },
        { text: "Create RL-1 Slip", navigateTo: "/taxslip/rl1/setup" },
      ]}
      mainContents={<RL1SlipSetup testId="rl1-slip-setup" />}
    />
  );
};
