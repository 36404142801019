import { FC, useEffect, useState } from "react";

import { RL1SlipSummaryDataInput } from "../../RL1SlipSummaryDataInput";

export interface IRL1SlipDetailPanel {
  formMethods:any;
  summaryResponse:any;
  taxYear:number;
}

export const RL1SlipDetailPanel: FC<IRL1SlipDetailPanel> = ({
  formMethods,
  summaryResponse,
  taxYear
}) => {
// const [state, setState] = useState('0.00')
const [state, setState] = useState(summaryResponse && summaryResponse.balance !== null && summaryResponse.balance)
    const onChangeHandler = (e:any) => {
      const value = e.target.value
      // if(value !== ''){
        if(summaryResponse){
          console.log("summaryResponse.balance",summaryResponse.balance)
          const result = summaryResponse.balance - value
          console.log("summaryResponse.balance",result)
          let rountValue = (Math.round(result * 100) / 100).toFixed(2)
          setState(rountValue)
          formMethods.setValue('balance',rountValue)
        }
      // } 
      // else {
      //   formMethods.setValue('balance',summaryResponse.balance)
      // }
    }

    useEffect(()=>{
      if(summaryResponse){
        const result = summaryResponse.balance - summaryResponse.qppContributionsQpipPremiumsIncomeTaxRemittedAmount
        console.log("result",result)
        let rountValue = (Math.round(result * 100) / 100).toFixed(2)
        setState(rountValue)
        console.log("rountValue",rountValue)
        formMethods.setValue('balance',rountValue)
      }
    },[summaryResponse])
  return (
    <>
      <RL1SlipSummaryDataInput
        id="totalRl1SlipsToBeFiled"
        label="Total RL-1 slips to be filed"
        textfield={true}
      />
      <RL1SlipSummaryDataInput
        id="rl1FiledOnPaper"
        label="RL-1 Filed on Paper"
        readOnly={false}
        required={true}
        textfield={true}
      />
      <RL1SlipSummaryDataInput
        id="rl1FiledOnline"
        label="RL-1 Filed Online"
        readOnly={false}
        required={true}
        textfield={true}
      />
      <RL1SlipSummaryDataInput
        id="qppEmployeeContribution"
        label="QPP Employee Contribution"
      />
      {taxYear > 2023 ? 
      <RL1SlipSummaryDataInput
        id="secondQppEmployeeContribution"
        label="Second QPP Employee Contribution"
      />: <></>
      }
      <RL1SlipSummaryDataInput
        id="qppEmployerContribution"
        label="QPP Employer Contribution"
      />
      {taxYear > 2023 ? 
        <RL1SlipSummaryDataInput
        id="secondQppEmployerContribution"
        label="Second QPP Employer Contribution"
        />: <></>
      }
      <RL1SlipSummaryDataInput
        id="qpipEmployeePremiums"
        label="QPIP Employee Premiums"
      />
      <RL1SlipSummaryDataInput
        id="qpipEmployerPremiums"
        label="QPIP Employer Premiums"
      />
      <RL1SlipSummaryDataInput id="quebecIncomeTax" label="Quebec Income Tax" />
      <RL1SlipSummaryDataInput
        id="qppContributionsQpipPremiumsIncomeTaxRemittedAmount"
        label="QPP contributions, QPIP premiums, income tax Remitted amount"
        readOnly={false}
        onChange={onChangeHandler}
      />
      <RL1SlipSummaryDataInput id="balance" label="Balance" value={state}/>
    </>
  );
};
