import { INVOICE } from "store/types/invoiceReportTypes";
import { TAX_YEAR } from "../types";

export interface IInvoiceReport {
  id?: string,
  invoiceNo?: string,
  refNo: string,
  invAmount?:number,
  netAmount?:number,
  invoiceDate?:string,
  paidAmount?:number,
  paymentDate?:string,
  tax?:number,
  paid?:boolean,
  discount?:number,
  handlingCharges?:number,
}

const initialState: IInvoiceReport = {
  refNo:""
};

export const invoiceReportReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INVOICE.INVOICE_SET_SELECTED:
      return {
        ...state,
        refNo: action.payload.refNo,
      }
    case INVOICE.INVOICE_CLEAR_SELECTED:
      return {
        ...state,
        refNo: initialState.refNo,
      }
    default:
      return state;
  }
};
