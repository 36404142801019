import {
  GET_QUERY_KEY,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  // RL16_SUMMARY_SCREEN_ID,
  RL16_SUMMARY_SCREEN_SLUG,
} from "common/constants";
import { instanceOfIFlexFieldSetupRequest } from "common/instance-method";
import { TaxSlipAccountInformation } from "components/TaxSlipAccountInformation";
import { TaxSlipFilingResourceSelectionPanel } from "components/TaxSlipFilingResource";
import { IResponse, IServerError } from "models/common";
import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FlexFieldService } from "services/FlexFieldService";

import FileSaver from "file-saver";
import { yupResolver } from "@hookform/resolvers/yup";
// import { Divider, Paper } from "@mui/material";
import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  FlatStandardPanel,
  ITest,
  SingleColumnLayout,
  Spacer,
} from "@websential/cosmic";

import { RL16SummaryToolbar } from "../RL16SummaryToolbar";
import {
  CompanyDetailPanel,
  DifferencePanel,
  RL16SlipDetailPanel,
  TotalBalancePanel,
} from "./Panels";
import { getValidationSchema } from "./validator";
import { RL16SlipService } from "services/RL16SlipService";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { T4SummarySetupSchema } from "components/T4Slip/T4SlipSummarySetup/summaryValidate";
import {
  EnhancedDialog,
  EnhancedLinkButton,
  IconTextButton,
  RouterLinkButton,
  SplitButton,
} from "@websential/cosmic";
import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions";
import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import {
  Box,
  Grid,
  InputLabel,
  Paper,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import {
  EnhancedDivider,
  EnhancedDropDownRHF,
  EnhancedDropDownRHFWithLabel,
  EnhancedTextAreaFieldRHF,
  LabelPosition,
  RequiredAsterisk,
  RHookFormTextField,
} from "@websential/cosmic";
import { T4SlipService } from "services/T4SlipService";
import { PrintingService } from "services/PrintingService";
import { InvoiceDialogPanel } from "components/T4Slip/T4SlipList/InvoiceDialogPanel";
import { checkSummaryType } from "components/T4Slip";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { RL16SummarySetupSchema } from "./rl16SummaryValidation";
import { TaxSlipSettingsService } from "services/TaxSlipSettingsService";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { ClientService } from "services";
export let selectedRL16SummaryType: any = 400;
export interface IRL16SlipSummarySetup extends ITest {}

const DEFAULT_VALUES = {
  // comparison: [
  //   {
  //     operator: undefined,
  //     field: undefined,
  //   },
  // ],
  // fieldType: undefined,
  // fieldLabel: "",
  // fieldId: "",
  // defaultValue: "",
  // placeholder: "",
  // fieldFormat: "",
  // fieldValue: "",
  // dataType: undefined,
  // fieldColumn: undefined,
  // parentRelation: undefined,
  // fieldInternalValue: "",
  // notes: "",
  // minValue: 0,
  // maxValue: 0,
  // isCategory: false,
  // isFixed: false,
  // isHide: false,
  // isRequired: false,
  // parentValue: "",
  // parent: undefined,
  // id: undefined,
};

const RL16_SUMMARY_DATA_QUERY_KEY = "get-rl16-summary-data";
// const LIST_RL16_SUMMARY_QUERY_KEY = "get-RL16-summary-list";
// const RL16_SUMMARY_DATA_QUERY_KEY = "get-t4-summary-data";

const useGetRL16SummaryData = (
  clientid: string,
  slipType: string | number,
  taxYear: number
) =>
  useQuery(
    [RL16_SUMMARY_DATA_QUERY_KEY, clientid, slipType, taxYear],
    (data) => {
      return RL16SlipService.getRL16Summary(clientid, slipType, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

const useGetGlobalVariables = (taxYear: number) =>
  useQuery(
    [GET_QUERY_KEY.GET_GLOBAL_VARIABLES_BY_YEAR, taxYear],
    (data) => {
      return TaxSlipSettingsService.getGlobalVariables(taxYear);
    },
    { refetchOnWindowFocus: false }
  );

  const useGetSlipIds = (
    tag: string,
    clientId: string,
    taxYear: number
  ) =>
    useQuery(
      ["get-slip-ids", tag, clientId, taxYear],
      (data) => {
        return T4SlipService.getSlipIds(tag, clientId, taxYear);
      },
      { refetchOnWindowFocus: false }
    );

    const useGetCompanyData = (id: string | undefined) => 
      useQuery(
        ["client-get-by-id", id],
        (data) => {
          return ClientService.getById(id);
        },
        { refetchOnWindowFocus: false }
      );

export const RL16SlipSummarySetup: FC<IRL16SlipSummarySetup> = ({ testId }) => {
  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);
  const queryClient = useQueryClient();
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );
  const [invoicePaymentFor, setInvoicePaymentFor] = useState(10)

  const dispatch = useDispatch();
  const id = useSelector((state: IAppState) => state.client.id);
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);

  const [selectedType, setSelectedType] = useState(400);
  const [openInvoiceDialog, setInvoiceDialogOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const maskedStatus: any = sessionStorage.getItem("maskEnabled");

  const { isLoading: isLoadingGlobalVariables, data: editDataRecord } =
    useGetGlobalVariables(taxYear);
  const globalVariablesData: any = editDataRecord;

  const { isLoading:loadingSlipIds, data:rl16SlipIds } = useGetSlipIds("RL16", clientId, taxYear);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  useEffect(()=>{
    console.log("companyGetByIdRecord11",companyGetByIdRecord)
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor ? parseInt(paymentFor.value) : 10)
    }
  },[companyGetByIdRecord])

  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    RL16SlipService.createSummary,
    {
      onSuccess: (
        res: IFlexFieldSetupRequest | IServerError | IResponse | any
      ) => {
        console.log("res", res);
        if (res.status === 201 || res.status === 200) {
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
        } else {
          if (res) {
            setSnackbarData({
              open: true,
              message:
                res &&
                res.message !== undefined &&
                typeof (res.message && res.message) !== "object"
                  ? res.message && res.message
                  : res.message.message
                  ? res.message.message
                  : "Data has been submitted successfully!",
              severity:
                res.status === false
                  ? "error"
                  : res.success === false
                  ? "error"
                  : "success",
            });
          }
        }
      },
      onError: (err) => {
        console.log("err34", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );
  const formMethods = useForm<any>({
    resolver: yupResolver(RL16SummarySetupSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });

  const {
    isLoading: isLoadingSummaryGetData,
    isFetching: reFetchingSlipType,
    refetch: refetchSlipType,
    data: RL16SummaryData,
  } = useGetRL16SummaryData(id, selectedType, taxYear);
  console.log("selectedType", selectedType);

  console.log("RL16SummaryData ============", RL16SummaryData);

  /**
   * Handle when the form is submitted.
   *
   * @param {IFlexFieldSetupRequest} formData
   */
  const onSubmit = (formData: any) => {
    let Payload: any = {
      id: id,
      taxYear: taxYear,
      slipType: 400,
      confirmationCode:
        formData.confirmationCode !== ""
          ? formData.confirmationCode
          : undefined,
      rl16FiledOnPaper: formData.rl16FiledOnPaper,
      rl16FiledOnline: formData.rl16FiledOnline,
    };
    // submitFlexFields(formData);
    submitFormData(Payload);
    console.log("formData ========== ", formData);
  };

  const onCloseHandler = () => {
    setInvoiceDialogOpen(false);
  };

  // const saveXmlAsFile = (xmlData: any, filename: any) => {
  //   const blob = new Blob([xmlData], { type: "xml;charset=utf-8" });
  //   FileSaver.saveAs(blob, filename);
  // };
  // const onGenerateXMLClicked = async (): Promise<void> => {
  //   setIsLoadingData(true);
  //   if (maskedStatus === "true") {
  //     setIsLoadingData(false);
  //     setSnackbarData({
  //       open: true,
  //       message: "Please unmask your data.",
  //       severity: "error",
  //     });
  //   } else {
  //     try {
  //       const response: any = await ReportsService.createInvoice(
  //         clientId,
  //         taxYear
  //       );
  //       setIsLoadingData(false);
  //       console.log("responseresponse", response);
  //       if (response.message === "Slips not found for invoice.") {
  //         setIsLoadingData(true);
  //         try {
  //           const filingAuditResponse: any =
  //             await RL16SlipService.getRL16AuditFiling(clientId, taxYear);
  //           console.log("filingAuditResponse", filingAuditResponse);
  //           if (
  //             filingAuditResponse &&
  //             filingAuditResponse.general_filling &&
  //             filingAuditResponse.general_filling.length > 0
  //           ) {
  //             console.log(
  //               "filingAuditResponse.general_filling",
  //               filingAuditResponse.general_filling
  //             );
  //             setSnackbarData({
  //               open: true,
  //               message: (
  //                 <>
  //                   Please load Audit in the menu and clear all audit items
  //                   (Audit Filing and PIER Review) to generate documents.{" "}
  //                   <a
  //                     href="https://appv3.taxslips.com/#/audit/filing"
  //                     style={{
  //                       color: "#aa1010",
  //                       textDecoration: "none",
  //                       fontWeight: "bold",
  //                     }}
  //                   >
  //                     Click here to view
  //                   </a>
  //                 </>
  //               ),
  //               severity: "error",
  //               autoHide: false,
  //             });
  //           } else if (
  //             filingAuditResponse &&
  //             filingAuditResponse.rl16_filling &&
  //             filingAuditResponse.rl16_filling.length > 0
  //           ) {
  //             console.log(
  //               "filingAuditResponse.rl16_filling",
  //               filingAuditResponse.rl16_filling
  //             );
  //             setSnackbarData({
  //               open: true,
  //               message: (
  //                 <>
  //                   Please load Audit in the menu and clear all audit items
  //                   (Audit Filing and PIER Review) to generate documents.{" "}
  //                   <a
  //                     href="https://appv3.taxslips.com/#/audit/filing"
  //                     style={{
  //                       color: "#aa1010",
  //                       textDecoration: "none",
  //                       fontWeight: "bold",
  //                     }}
  //                   >
  //                     Click here to view
  //                   </a>
  //                 </>
  //               ),
  //               severity: "error",
  //               autoHide: false,
  //             });
  //           } else {
  //             setIsLoadingData(true);
  //             try {
  //               const response: any = await PrintingService.createRL16Xml(
  //                 clientId,
  //                 selectedType,
  //                 taxYear
  //               );
  //               if (response.status === 400) {
  //                 setSnackbarData({
  //                   open: true,
  //                   message: response.data.message
  //                     ? response.data.message
  //                     : "Something went wrong.",
  //                   severity: "error",
  //                 });
  //               } else {
  //                 const filename = "RL-16xml.xml";
  //                 saveXmlAsFile(response, filename);
  //               }
  //               setIsLoadingData(false);
  //             } catch (err) {
  //               console.log("err", err);
  //               alert(err);
  //             }
  //           }
  //           setIsLoadingData(false);
  //         } catch (err: any) {
  //           setIsLoadingData(false);
  //           alert(err);
  //         }
  //       } else if (response && response.id !== null) {
  //         setInvoiceData(response);
  //         setInvoiceDialogOpen(true);
  //       } else {
  //         setSnackbarData({
  //           open: true,
  //           message: "Something went wrong.",
  //           severity: "error",
  //         });
  //       }
  //       setIsLoadingData(false);
  //     } catch (err) {
  //       console.log("err", err);
  //       setIsLoadingData(false);
  //     }
  //   }
  // };
  const savePdfAsFile = (pdfData: any, filename: any) => {
    const blob = new Blob([pdfData], { type: "pdf;charset=utf-8" });
    FileSaver.saveAs(blob, filename);
  };
  const onPrintClicked = async (event: any): Promise<void> => {
    setIsLoadingData(true);
    const maskedStatus: any = sessionStorage.getItem("maskEnabled");
    if (maskedStatus === "true") {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(rl16SlipIds && rl16SlipIds.generalAuditStatus !== null && rl16SlipIds.generalAuditStatus === true &&
              rl16SlipIds && rl16SlipIds.auditStatus !== null &&  rl16SlipIds.auditStatus === true){
              setIsLoadingData(true);
                try {
                  const response: any =
                    await PrintingService.createRL16SummaryPdfPrint(
                      clientId,
                      400,
                      taxYear,
                      event === "Print PDF (English)"
                        ? "rl16-english"
                        : "rl16-french"
                    );
                  if (response.status === 400) {
                    setSnackbarData({
                      open: true,
                      message: response.data.message
                        ? response.data.message
                        : "Something went wrong.",
                      // message: "Please Unmask sensitive data for PDF printing.",
                      severity: "error",
                    });
                  } else {
                    const filename = `RL16Summary-${clientName}-${
                      event === "Print PDF (English)" ? "English" : "French"
                    }.pdf`;
                    savePdfAsFile(response, filename);
                  }
                  setIsLoadingData(false);
                } catch (err) {
                  console.log("err", err);
                  alert(err);
                }
              // }
              setIsLoadingData(false);
            } else {
              setIsLoadingData(false);
              setSnackbarData({
              open: true,
              message: (
                <>
                  Please load Audit in the menu, press Run RL-16 Audit and clear all audit items to generate documents.{" "}
                  <a
                    href="https://appv3.taxslips.com/#/audit/filing"
                    style={{
                      color: "#aa1010",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Click here to view
                  </a>
                </>
              ),
              severity: "error",
              autoHide: false,
            });
          }

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (response.message === "Slips not found for invoice.") {
          // setIsLoadingData(true);
          // try {
          //   const filingAuditResponse: any =
          //     await RL16SlipService.getRL16AuditFiling(clientId, taxYear);
          //   console.log("filingAuditResponse", filingAuditResponse);
          //   if (
          //     filingAuditResponse &&
          //     filingAuditResponse.general_filling &&
          //     filingAuditResponse.general_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.general_filling",
          //       filingAuditResponse.general_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else if (
          //     filingAuditResponse &&
          //     filingAuditResponse.rl16_filling &&
          //     filingAuditResponse.rl16_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.rl16_filling",
          //       filingAuditResponse.rl16_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else {
          //     setIsLoadingData(true);
          if(rl16SlipIds && rl16SlipIds.generalAuditStatus !== null && rl16SlipIds.generalAuditStatus === true &&
            rl16SlipIds && rl16SlipIds.auditStatus !== null &&  rl16SlipIds.auditStatus === true){
            setIsLoadingData(true);
              try {
                const response: any =
                  await PrintingService.createRL16SummaryPdfPrint(
                    clientId,
                    400,
                    taxYear,
                    event === "Print PDF (English)"
                      ? "rl16-english"
                      : "rl16-french"
                  );
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    // message: "Please Unmask sensitive data for PDF printing.",
                    severity: "error",
                  });
                } else {
                  const filename = `RL16Summary-${clientName}-${
                    event === "Print PDF (English)" ? "English" : "French"
                  }.pdf`;
                  savePdfAsFile(response, filename);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
                alert(err);
              }
            // }
            setIsLoadingData(false);
          } else {
            setIsLoadingData(false);
            setSnackbarData({
            open: true,
            message: (
              <>
                Please load Audit in the menu, press Run RL-16 Audit and clear all audit items to generate documents.{" "}
                <a
                  href="https://appv3.taxslips.com/#/audit/filing"
                  style={{
                    color: "#aa1010",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Click here to view
                </a>
              </>
            ),
            severity: "error",
            autoHide: false,
          });
        }
          // } catch (err: any) {
          //   setIsLoadingData(false);
          //   alert(err);
          // }
        } else if (response && response.id !== null) {
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    }
    }
  };

  const onDeleteClicked = async (): Promise<void> => {
    //delete confirmation code
    try {
      setIsLoadingData(true);
      const response: any =
        await RL16SlipService.RL16SummaryclearConfirmationCode(
          clientId,
          checkSummaryType(RL16SummaryData && RL16SummaryData.slipType),
          taxYear
        );
      console.log("response64", response);
      if (response.status === 200) {
        setSnackbarData({
          open: true,
          message: response.data.message,
          severity: "success",
        });
        refetchSlipType()
      } else {
        setSnackbarData({
          open: true,
          message:
            response &&
            response.message !== undefined &&
            typeof (response.message && response.message) !== "object"
              ? response.message && response.message
              : response.message.message
              ? response.message.message
              : "Something went wrong!",
          severity:
            response.status === false
              ? "error"
              : response.success === false
              ? "error"
              : "success",
        });
      }
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log("err", err);
    }
  };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  useEffect(() => {
    console.log("===-use Effect called");
    // if the selected flex field data has loaded.
    if (RL16SummaryData) {
      Object.keys(RL16SummaryData).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = RL16SummaryData[fieldName];
        // @ts-ignore
        formMethods.setValue(fieldName, fieldValue ?? undefined);
        // if (fieldValue && fieldValue !== null) {
        //   formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
        // } else {
        //   formMethods.setValue(fieldName, "0.00");
        // }
        //set all values to desimal
        // if(fieldName === "totalNumberOfRL16SlipsFiled"){ fieldValue !== null || undefined ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2)) : formMethods.setValue(fieldName,"0.00") }

        if (fieldName === "capitalGains") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "singlePensionPayment") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "actualAmountOfEligibleDividends") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "actualAmountOfOrdinaryDividends") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "pensionPaymentGivingEntitlement") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "foreignBusinessIncome") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "foreignNonBusinessIncome") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "otherIncome") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "capitalGainsGivingEntitlement") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "taxableAmountOfEligibleAndOrdinaryDividends") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "dividendTaxCredit") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "foreignIncomeTaxOnNonBusinessIncome") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "costBaseAdjustmentOfCapitalInterest") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
      });
    }
  }, [RL16SummaryData, formMethods.setValue, formMethods]);

  const onUnPaidClickHandler = (clickedRow: any, tag:string) => {
    setIsLoadingData(true);
    // setTimeout(() => {
    //   setIsLoadingData(false);
    // }, 2500);
    const fetchData = async () => {
      try {
        const response: any =
          await ReportsService.paypalIntegrationOfUnpaidInvoice(
            clientId,
            clickedRow.refNo,
            tag
          );
        setIsLoadingData(false);
        console.log("responseData111-----------------", response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const onClickInvoiceNoHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: RL16_SUMMARY_SCREEN_SLUG,
        companyName: clientName,
        activityType:
          "activity_type=updated&activity_type=delete&activity_type=print&activity_type=print_xml",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={
          // isLoadingSelectedFlexField ||
          isSubmitting ||
          isLoadingSummaryGetData ||
          reFetchingSlipType ||
          isLoadingGlobalVariables ||
          // isLoadingselectedSlipType
          isLoadingData ||
          isLoadingActivityLogData
        }
        testId={testId}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            toolbar={
              <RL16SummaryToolbar
                isSubmitting={isSubmitting}
                // onGenerateXMLClicked={onGenerateXMLClicked}
                onPrintClicked={onPrintClicked}
                onDeleteClicked={onDeleteClicked}
              />
            }
            testId="t4-slip-summary-setup-form"
          >
            <Paper>
              <FlatStandardPanel
                title="Account Information"
                testId={`${testId}-account-information-panel`}
                showTitle={false}
                topPadding={3}
                leftSpacing={2}
                rightSpacing={2}
              >
                <TaxSlipAccountInformation
                  logoURL={`${process.env.PUBLIC_URL}/images/cra_logo.png`}
                  accountIdentifierTitle="Payer's account number"
                  accountIdentifier="121212120RP0001"
                  accountInformationMessage="You have to file your RL-16 information return on or before the last day of February."
                  accountName="HELLOFRESH"
                  accountAddressLine1="House # 34, Street twenty three Link Road Cavalary Ground"
                  accountCity=" Toronto"
                  accountProvince="ON"
                  accountPostalCode="W3R4RL16"
                  companyInformation={RL16SummaryData && RL16SummaryData}
                />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="RL-16 Slips Detail"
                testId={`${testId}-t4-slip-detial-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <RL16SlipDetailPanel
                  formMethods={formMethods}
                  globalVariablesData={
                    globalVariablesData && globalVariablesData
                  }
                />
              </FlatStandardPanel>
              <Spacer y={4} x={4} />
            </Paper>
          </SingleColumnLayout>
        </form>
      </FormProvider>
      {/* invoice dialogu open if any transaction is unPaid */}
      <EnhancedDialog
        dialogTitle="Invoice(s) Due"
        key="dialog-box"
        testId="dialog"
        onCloseHandler={onCloseHandler}
        open={openInvoiceDialog}
        dialogContent={
          <InvoiceDialogPanel
            invoiceData={invoiceData}
            onUnPaidClickHandler={onUnPaidClickHandler}
            onClickInvoiceNoHandler={onClickInvoiceNoHandler}
          />
        }
        dialogAction={
          <Grid>
            <Button autoFocus variant="contained" onClick={onCloseHandler}>
              Cancel
            </Button>
          </Grid>
        }
      />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
        autoHide={snackbarData.autoHide}
      />
    </Grid>
  );
};
