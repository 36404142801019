import { GridSize } from "@mui/material";
import { DataType, FieldType, ITabLayout } from "@websential/cosmic";
import { IFlexFieldsPanelLayout } from "@websential/cosmic/build/components/RHookFormGenerator/types/IFlexFieldsPanelLayout";

export const VENDOR_SETUP_FORM_WITH_PERSONAL_PAYEE_TYPE = 
[
    {
      name: "Basic Info",
      description: "Add basic information",
      sequence: 1,
      categoryFields: [],
      panels: [
        {
          label: "Vendor Info",
          categoryFieldId: "",
          categoryFieldValue: "",
          sequence: 1,
          gridColumns: 12 as GridSize,
          fields: [
            {
              id: 1,
              fieldLabel: "Payee Type",
              fieldId: "payeeType",
              fieldType: FieldType.DropDownList,
              placeholder: "",
              fieldValue: "Personal,Business",
              fieldInternalValue: "true,false",
              dataType: DataType.Object,
              fieldFormat: "",
              defaultValue: "true",
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: false,
              isRequired: true,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 1,
            },
            {
              id: 2,
              fieldLabel: "Social Insurance no.",
              fieldId: "uniqueIdentifier",
              fieldType: FieldType.SINNumber,
              placeholder: "000-000-000",
              fieldValue: "",
              fieldInternalValue: "",
              dataType: DataType.String,
              fieldFormat: "{000-000-000}",
              defaultValue: "",
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: true,
              isRequired: true,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 2,
            },
            {
              id: 3,
              fieldLabel: "First Name",
              fieldId: "name1",
              fieldType: FieldType.TextField,
              placeholder: "First Name",
              fieldValue: "",
              fieldInternalValue: "",
              dataType: DataType.String,
              fieldFormat: "",
              defaultValue: null,
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "1",
              maxValue: "100",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: true,
              isRequired: true,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 3,
            },
            {
              id: 4,
              fieldLabel: "Last Name",
              fieldId: "name2",
              fieldType: FieldType.TextField,
              placeholder: "Last Name",
              fieldValue: "",
              fieldInternalValue: "",
              dataType: DataType.String,
              fieldFormat: "",
              defaultValue: null,
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "100",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: true,
              isRequired: true,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 4,
            },
            {
              id: 5,
              fieldLabel: "Initials",
              fieldId: "initials",
              fieldType: FieldType.MaskedTextField,
              placeholder: "Initials",
              fieldValue: "",
              fieldInternalValue: "",
              dataType: DataType.String,
              fieldFormat: "{a}",
              defaultValue: null,
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "1",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: false,
              isRequired: false,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 5,
            },
            {
              id: 6,
              fieldLabel: "Business Name",
              fieldId: "businessName",
              fieldType: FieldType.TextField,
              placeholder: "Business Name",
              fieldValue: "",
              fieldInternalValue: "",
              dataType: DataType.String,
              fieldFormat: "",
              defaultValue: null,
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "100",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: true,
              isRequired: false,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 6,
            },
            {
              id: 7,
              fieldLabel: "Business No.",
              fieldId: "businessNumber",
              fieldType: FieldType.MaskedTextField,
              placeholder: "999999999RT9999",
              fieldValue: "",
              fieldInternalValue: "",
              dataType: DataType.String,
              "fieldFormat": "{000000000Ra0000}",
              defaultValue: null,
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: true,
              isRequired: false,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 7,
            },
            {
              id: 8,
              fieldLabel: "Status",
              fieldId: "statusId",
              fieldType: FieldType.DropDownList,
              placeholder: "",
              fieldValue: "Active,In-Active",
              fieldInternalValue: [13,14],
              dataType: DataType.Object,
              fieldFormat: "",
              defaultValue: 13,
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: true,
              isRequired: true,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 8,
            },
          ],
        },
      {
        label: "Address",
        categoryFieldId: "",
        categoryFieldValue: "",
        sequence: 2,
        gridColumns: 12 as GridSize,
        fields: [
          {
            id: 10,
            fieldLabel: "Address",
            fieldId: "addressLine1",
            fieldType: FieldType.TextField,
            placeholder: "Enter Address",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "1",
            maxValue: "40",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 1,
          },
          {
            id: 11,
            fieldLabel: "City",
            fieldId: "city",
            fieldType: FieldType.TextField,
            placeholder: "City",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "1",
            maxValue: "35",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 2,
          },
          {
            id: 12,
            fieldLabel: "Post Code",
            fieldId: "postalCode",
            fieldType: FieldType.PostCode,
            placeholder: "A9A9A9",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: true,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 3,
          },
          {
            id: 13,
            fieldLabel: "Province",
            fieldId: "provinceId",
            fieldType: FieldType.DropDownList,
            placeholder: "",
            fieldValue:
              "Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon",
            fieldInternalValue: "1,2,3,4,5,7,6,8,9,10,11,12,13",
            dataType: DataType.Object,
            fieldFormat: "",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 4,
          },
          {
            id: 14,
            fieldLabel: "Country",
            fieldId: "countryId",
            fieldType: FieldType.DropDownList,
            placeholder: "",
            fieldValue: "Canada",
            fieldInternalValue: "1",
            dataType: DataType.Object,
            fieldFormat: "",
            defaultValue: "1",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: true,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 5,
          },

          {
            id: 15,
            fieldLabel: "Email",
            fieldId: "email",
            fieldType: FieldType.Email,
            placeholder: "Email",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 6,
          },
          {
            id: 16,
            fieldLabel: "Phone (Home)",
            fieldId: "phone1",
            fieldType: FieldType.MaskedTextField,
            placeholder: "(999) 999-9999",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "(000) 000-0000",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 5.4 as GridSize,
            fieldColumn: 6.6 as GridSize,
            fullFieldColumns: 9 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 7,
          },
          {
            id: 17,
            fieldLabel: "Ext",
            fieldId: "ext1",
            fieldType: FieldType.MaskedTextField,
						placeholder: "000",
						fieldValue: "",
						fieldInternalValue: "",
						dataType: DataType.String,
						fieldFormat: "{000}",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            fullFieldColumns: 3 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 8,
          },
          {
            id: 18,
            fieldLabel: "Phone (Personal)",
            fieldId: "phone2",
            fieldType: FieldType.MaskedTextField,
            placeholder: "(999) 999-9999",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "(000) 000-0000",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 5.4 as GridSize,
            fieldColumn: 6.6 as GridSize,
            fullFieldColumns: 9 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 9,
          },
          {
            id: 19,
            fieldLabel: "Ext",
            fieldId: "ext2",
            fieldType: FieldType.MaskedTextField,
						placeholder: "000",
						fieldValue: "",
						fieldInternalValue: "",
						dataType: DataType.String,
						fieldFormat: "{000}",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            fullFieldColumns: 3 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 10,
          },
        ],
      },
    ],
  },
] as ITabLayout[];

export const VENDOR_SETUP_FORM_WITH_BUSINESS_PAYEE_TYPE = 
[
    {
      name: "Basic Info",
      description: "Add basic information",
      sequence: 1,
      categoryFields: [],
      panels: [
        {
          label: "Vendor Info",
          categoryFieldId: "",
          categoryFieldValue: "",
          sequence: 1,
          gridColumns: 12 as GridSize,
          fields: [
            {
              id: 1,
              fieldLabel: "Payee Type",
              fieldId: "payeeType",
              fieldType: FieldType.DropDownList,
              placeholder: "",
              fieldValue: "Personal,Business",
              fieldInternalValue: "true,false",
              dataType: DataType.Object,
              fieldFormat: "",
              defaultValue: "false",
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: false,
              isRequired: true,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 1,
            },
            {
              id: 2,
              fieldLabel: "Social Insurance no.",
              fieldId: "uniqueIdentifier",
              fieldType: FieldType.SINNumber,
              placeholder: "000-000-000",
              fieldValue: "",
              fieldInternalValue: "",
              dataType: DataType.String,
              fieldFormat: "{000-000-000}",
              defaultValue: "",
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: true,
              isRequired: false,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 2,
            },
            {
              id: 3,
              fieldLabel: "First Name",
              fieldId: "name1",
              fieldType: FieldType.TextField,
              placeholder: "First Name",
              fieldValue: "",
              fieldInternalValue: "",
              dataType: DataType.String,
              fieldFormat: "",
              defaultValue: null,
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "100",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: true,
              isRequired: false,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 3,
            },
            {
              id: 4,
              fieldLabel: "Last Name",
              fieldId: "name2",
              fieldType: FieldType.TextField,
              placeholder: "Last Name",
              fieldValue: "",
              fieldInternalValue: "",
              dataType: DataType.String,
              fieldFormat: "",
              defaultValue: null,
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "100",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: true,
              isRequired: false,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 4,
            },
            {
              id: 5,
              fieldLabel: "Initials",
              fieldId: "initials",
              fieldType: FieldType.MaskedTextField,
              placeholder: "Initials",
              fieldValue: "",
              fieldInternalValue: "",
              dataType: DataType.String,
              fieldFormat: "{a}",
              defaultValue: null,
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "1",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: false,
              isRequired: false,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 5,
            },
            {
              id: 6,
              fieldLabel: "Business Name",
              fieldId: "businessName",
              fieldType: FieldType.TextField,
              placeholder: "Business Name",
              fieldValue: "",
              fieldInternalValue: "",
              dataType: DataType.String,
              fieldFormat: "",
              defaultValue: null,
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "1",
              maxValue: "100",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: true,
              isRequired: true,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 6,
            },
            {
              id: 7,
              fieldLabel: "Business No.",
              fieldId: "businessNumber",
              fieldType: FieldType.MaskedTextField,
              placeholder: "999999999RT9999",
              fieldValue: "",
              fieldInternalValue: "",
              dataType: DataType.String,
              fieldFormat: "{000000000Ra0000}",
              defaultValue: null,
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: true,
              isRequired: true,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 7,
            },
            {
              id: 8,
              fieldLabel: "Status",
              fieldId: "statusId",
              fieldType: FieldType.DropDownList,
              placeholder: "",
              fieldValue: "Active,In-Active",
              fieldInternalValue: [13,14],
              dataType: DataType.Object,
              fieldFormat: "",
              defaultValue: 13,
              labelPosition: "left",
              labelColumn: 4 as GridSize,
              fieldColumn: 8 as GridSize,
              notes: "",
              minValue: "",
              maxValue: "",
              parent: null,
              parentValue: "",
              parentRelation: null,
              isFixed: true,
              isRequired: true,
              isCategory: false,
              isHide: false,
              readOnly: false,
              displayMasked: false,
              uppercase: false,
  
              fieldClass: "",
              validationFormula: "",
              comparison: [],
              employeeAccess: 0,
              variableValue: null,
              systemField: false,
              apiPath: null,
              sequence: 8,
            },
          ],
        },
      {
        label: "Address",
        categoryFieldId: "",
        categoryFieldValue: "",
        sequence: 2,
        gridColumns: 12 as GridSize,
        fields: [
          {
            id: 10,
            fieldLabel: "Address",
            fieldId: "addressLine1",
            fieldType: FieldType.TextField,
            placeholder: "Enter Address",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "1",
            maxValue: "40",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 1,
          },
          {
            id: 11,
            fieldLabel: "City",
            fieldId: "city",
            fieldType: FieldType.TextField,
            placeholder: "City",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "1",
            maxValue: "35",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 2,
          },
          {
            id: 12,
            fieldLabel: "Post Code",
            fieldId: "postalCode",
            fieldType: FieldType.PostCode,
            placeholder: "A9A9A9",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: true,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 3,
          },
          {
            id: 13,
            fieldLabel: "Province",
            fieldId: "provinceId",
            fieldType: FieldType.DropDownList,
            placeholder: "",
            fieldValue:
              "Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon",
            fieldInternalValue: "1,2,3,4,5,7,6,8,9,10,11,12,13",
            dataType: DataType.Object,
            fieldFormat: "",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: true,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 4,
          },
          {
            id: 14,
            fieldLabel: "Country",
            fieldId: "countryId",
            fieldType: FieldType.DropDownList,
            placeholder: "",
            fieldValue: "Canada",
            fieldInternalValue: "1",
            dataType: DataType.Object,
            fieldFormat: "",
            defaultValue: "1",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: true,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 5,
          },

          {
            id: 15,
            fieldLabel: "Email",
            fieldId: "email",
            fieldType: FieldType.Email,
            placeholder: "Email",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "",
            defaultValue: null,
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: true,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 6,
          },
          {
            id: 16,
            fieldLabel: "Phone (Home)",
            fieldId: "phone1",
            fieldType: FieldType.MaskedTextField,
            placeholder: "(999) 999-9999",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "(000) 000-0000",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 5.4 as GridSize,
            fieldColumn: 6.6 as GridSize,
            fullFieldColumns: 9 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 7,
          },
          {
            id: 17,
            fieldLabel: "Ext",
            fieldId: "ext1",
            fieldType: FieldType.MaskedTextField,
						placeholder: "000",
						fieldValue: "",
						fieldInternalValue: "",
						dataType: DataType.String,
						fieldFormat: "{000}",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            fullFieldColumns: 3 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 8,
          },
          {
            id: 18,
            fieldLabel: "Phone (Personal)",
            fieldId: "phone2",
            fieldType: FieldType.MaskedTextField,
            placeholder: "(999) 999-9999",
            fieldValue: "",
            fieldInternalValue: "",
            dataType: DataType.String,
            fieldFormat: "(000) 000-0000",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 5.4 as GridSize,
            fieldColumn: 6.6 as GridSize,
            fullFieldColumns: 9 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 9,
          },
          {
            id: 19,
            fieldLabel: "Ext",
            fieldId: "ext2",
            fieldType: FieldType.MaskedTextField,
						placeholder: "000",
						fieldValue: "",
						fieldInternalValue: "",
						dataType: DataType.String,
						fieldFormat: "{000}",
            defaultValue: "",
            labelPosition: "left",
            labelColumn: 4 as GridSize,
            fieldColumn: 8 as GridSize,
            fullFieldColumns: 3 as GridSize,
            notes: "",
            minValue: "",
            maxValue: "",
            parent: null,
            parentValue: "",
            parentRelation: null,
            isFixed: false,
            isRequired: false,
            isCategory: false,
            isHide: false,
            readOnly: false,
            displayMasked: false,
            uppercase: false,

            fieldClass: "",
            validationFormula: "",
            comparison: [],
            employeeAccess: 0,
            variableValue: null,
            systemField: false,
            apiPath: null,
            sequence: 10,
          },
        ],
      },
      
      ],
    },
  ] as ITabLayout[];

//old method according to rhookform
// export const VENDOR_SETUP_FORM = [
//   {
//     name: "Basic Info",
//     description: "Add basic information",
//     sequence: 1,
//     categoryFields: [],
//     panels: [
//       {
//         label: "Enter basic information (Vendor)",
//         categoryFieldId: "",
//         categoryFieldValue: "",
//         sequence: 1,
//         gridColumns: 10 as GridSize,
//         fields: [
//           {
//             id: 1,
//             fieldLabel: "Social Insurance no.",
//             fieldId: "uniqueIdentifier",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "000-000-000",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "{000-000-000}",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 1,
//           },
//           {
//             id: 2,
//             fieldLabel: "First Name",
//             fieldId: "name1",
//             fieldType: FieldType.TextField,
//             placeholder: "First Name",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 2,
//           },
//           {
//             id: 3,
//             fieldLabel: "Last Name",
//             fieldId: "name2",
//             fieldType: FieldType.TextField,
//             placeholder: "Last Name",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 3,
//           },
//           {
//             id: 4,
//             fieldLabel: "Initials",
//             fieldId: "initial",
//             fieldType: FieldType.TextField,
//             placeholder: "Initials",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 4,
//           },
//           {
//             id: 5,
//             fieldLabel: "Business Name",
//             fieldId: "businessName",
//             fieldType: FieldType.TextField,
//             placeholder: "Business Name",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 5,
//           },
//           {
//             id: 6,
//             fieldLabel: "Business No.",
//             fieldId: "businessNumber",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "999999999RP9999",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "{000000000RP0000}",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 6,
//           },
//           {
//             id: 7,
//             fieldLabel: "Status",
//             fieldId: "statusId",
//             fieldType: FieldType.DropDownList,
//             placeholder: "",
//             fieldValue: "Active,In-Active",
//             fieldInternalValue: "13,14",
//             dataType: DataType.Object,
//             fieldFormat: "",
//             defaultValue: "13",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 7,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     name: "Address",
//     description: "Enter address of vendor",
//     sequence: 2,
//     categoryFields: [],
//     panels: [
//       {
//         label: "Enter Address (Vendor)",
//         categoryFieldId: "",
//         categoryFieldValue: "",
//         sequence: 2,
//         gridColumns: 10 as GridSize,
//         fields: [
//           {
//             id: 10,
//             fieldLabel: "Address",
//             fieldId: "addressLine1",
//             fieldType: FieldType.TextField,
//             placeholder: "Enter Address",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 1,
//           },
//           {
//             id: 11,
//             fieldLabel: "City",
//             fieldId: "city",
//             fieldType: FieldType.TextField,
//             placeholder: "City",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 2,
//           },
//           {
//             id: 12,
//             fieldLabel: "Post Code",
//             fieldId: "postalCode",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "A9A9A9",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "{a0a0a0}",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: true,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 3,
//           },
//           {
//             id: 13,
//             fieldLabel: "Province",
//             fieldId: "provinceId",
//             fieldType: FieldType.DropDownList,
//             placeholder: "",
//             fieldValue:
//               "Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon",
//             fieldInternalValue: "1,2,3,4,5,6,7,8,9,10,11,12,13",
//             dataType: DataType.Object,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: true,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 4,
//           },
//           {
//             id: 14,
//             fieldLabel: "Country",
//             fieldId: "countryId",
//             fieldType: FieldType.DropDownList,
//             placeholder: "",
//             fieldValue: "Canada",
//             fieldInternalValue: "1",
//             dataType: DataType.Object,
//             fieldFormat: "",
//             defaultValue: "1",
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: true,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: true,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 5,
//           },

//           {
//             id: 15,
//             fieldLabel: "Email",
//             fieldId: "email",
//             fieldType: FieldType.Email,
//             placeholder: "Email",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: null,
//             labelPosition: "top",
//             labelColumn: 12 as GridSize,
//             fieldColumn: 12 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 6,
//           },
//           {
//             id: 16,
//             fieldLabel: "Phone (Home)",
//             fieldId: "phone1",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "(999) 999-9999",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "(000) 000-0000",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 7,
//           },
//           {
//             id: 17,
//             fieldLabel: "Ext",
//             fieldId: "ext1",
//             fieldType: FieldType.TextField,
//             placeholder: "536",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 8,
//           },
//           {
//             id: 18,
//             fieldLabel: "Phone (Personal)",
//             fieldId: "phone2",
//             fieldType: FieldType.MaskedTextField,
//             placeholder: "(999) 999-9999",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "(000) 000-0000",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 9,
//           },
//           {
//             id: 19,
//             fieldLabel: "Ext",
//             fieldId: "ext2",
//             fieldType: FieldType.TextField,
//             placeholder: "536",
//             fieldValue: "",
//             fieldInternalValue: "",
//             dataType: DataType.String,
//             fieldFormat: "",
//             defaultValue: "",
//             labelPosition: "top",
//             labelColumn: 6 as GridSize,
//             fieldColumn: 6 as GridSize,
//             notes: "",
//             minValue: "",
//             maxValue: "",
//             parent: null,
//             parentValue: "",
//             parentRelation: null,
//             isFixed: false,
//             isRequired: false,
//             isCategory: false,
//             isHide: false,
//             readOnly: false,
//             displayMasked: false,
//             uppercase: false,

//             fieldClass: "",
//             validationFormula: "",
//             comparison: [],
//             employeeAccess: 0,
//             variableValue: null,
//             systemField: false,
//             apiPath: null,
//             sequence: 10,
//           },
//         ],
//       },
//     ],
//   },
// ] as ITabLayout[];
