import { FlexFieldPanelList } from "components/FlexFieldPanel";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IFlexFieldPanelListPage {}

export const FlexFieldPanelListPage: FC<IFlexFieldPanelListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Flex Field Panel List"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'Flex Field Panel', navigateTo: '/flex-field-panels/list'}]}
      mainContents={<FlexFieldPanelList />}
    />
  );
};
