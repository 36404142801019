import React, { useState, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "./MoveResourcePanelsStyle.css";
import { useParams } from "react-router-dom";

export interface IMoveEmployeeFirstPanel {
  rows: any;
  columns: any;
  selectionToolbar?: (selectedItems: any) => React.ReactNode;
}

export const MoveEmployeeFirstPanel: React.FC<IMoveEmployeeFirstPanel> = ({
  rows,
  columns,
  selectionToolbar,
}) => {
  const { resourceName } = useParams();
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [itemSearch, setItemSearch] = useState("");
  const renderHeader = () => {
    return (
      <span className="p-input-icon-left w-7" style={{ display: "flex" }}>
        <i
          className="pi pi-search"
          style={{ fontSize: "small", marginTop: "-6px!important" }}
        />
        <InputText
          value={itemSearch}
          onChange={(e) => setItemSearch(e.target.value)}
          placeholder={`Search by ${resourceName !== 'T5_Recipient' ? resourceName : 'T5 Recipient'} Name...`}
          className="w-full"
          style={{
            borderRadius: "5px",
            fontSize: "small",
            padding: "7px 7px 8px 2.5rem",
          }}
        />
        {selectionToolbar && selectionToolbar(selectedItems)}
      </span>
    );
  };
  const headerSearch = renderHeader();
  function search(_row: any) {
    return (
      rows &&
      rows.filter(
        (rows: any) =>
          rows?.label.toLowerCase().indexOf(itemSearch.toLowerCase()) > -1
      )
    );
  }

  const onSelectionModelChange = useCallback(
    (items: any) => {
      let selectedItemsIds = items.value.map((item: any) => item.id);
      // setSelectedItems(selectedItemsIds);
      // Check for deleted items and remove them from selectedItems
      const updatedSelectedItems = items.value.filter((selectedItem: any) => {
        return rows && rows.find((row: any) => row.id === selectedItem.id);
      });
      setSelectedItems(updatedSelectedItems);
    },
    [rows, selectedItems]
  );

  useEffect(() => {
    // Check for deleted items and remove them from selectedItems
    const updatedSelectedItems =
      selectedItems &&
      selectedItems.length > 0 &&
      selectedItems.filter((selectedItem: any) => {
        return rows && rows.find((row: any) => row.id === selectedItem.id);
      });
    // Only update if there are changes to selectedItems
    if (
      updatedSelectedItems &&
      updatedSelectedItems.length !== selectedItems.length
    ) {
      setSelectedItems(updatedSelectedItems);

      // Update selectedItemsIds
      const updatedSelectedItemsIds =
        updatedSelectedItems &&
        updatedSelectedItems.map((item: any) => item.id);
      //   setSelectedItemsIds(updatedSelectedItemsIds);
    }
  }, [rows, selectedItems]);
  console.log("props headerColumns => ", columns);
  console.log("props items T4Report => ", rows);
  return (
    <div>
      <DataTable
        value={search(rows && rows)}
        dataKey="id"
        onSelectionChange={onSelectionModelChange}
        selection={selectedItems}
        emptyMessage={`No ${resourceName !== 'T5_Recipient' ? resourceName : 'T5 Recipient'} found.`}
        header={headerSearch}
        className="moveReourceDataTableStyle"
      >
        <Column
          selectionMode="multiple"
          style={{ width: "36px", textAlign: "center" }}
          field="selection"
          columnKey="selection"
        ></Column>
        {columns &&
          columns.map((x: any, index: any) => {
            return <Column key={index} field={x.field} header={x.headerName} />;
          })}
      </DataTable>
    </div>
  );
};
