import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IRL3SLipAxisPage1 {}
const RL3SLipAxisPage1:React.FC<IRL3SLipAxisPage1> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP1X'} 
                testId={'taxYearP1X'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={250}
            />
            <RHookFormTextFieldWithLabel 
                id={'statementCodeP1X'} 
                testId={'statementCodeP1X'} 
                label={'Statement Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={300}
            />
            <RHookFormTextFieldWithLabel 
                id={'currencyCodeP1X'} 
                testId={'currencyCodeP1X'} 
                label={'Currency Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={350}
            />
            <RHookFormTextFieldWithLabel 
                id={'numberOfLastStatementSentP1X'} 
                testId={'numberOfLastStatementSentP1X'} 
                label={'Number Of Last Statement Sent'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxA1P1X'} 
                testId={'boxA1P1X'} 
                label={'Box A1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxA2P1X'} 
                testId={'boxA2P1X'} 
                label={'Box A2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={160}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxBP1X'} 
                testId={'boxBP1X'} 
                label={'Box B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={280}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxCP1X'} 
                testId={'boxCP1X'} 
                label={'Box C'} 
                labelPosition={LabelPosition.Left}
                defaultValue={400}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxDP1X'} 
                testId={'boxDP1X'} 
                label={'Box D'} 
                labelPosition={LabelPosition.Left}
                defaultValue={520}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxEP1X'} 
                testId={'boxEP1X'} 
                label={'Box E'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxFP1X'} 
                testId={'boxFP1X'} 
                label={'Box F'} 
                labelPosition={LabelPosition.Left}
                defaultValue={160}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxGP1X'} 
                testId={'boxGP1X'} 
                label={'Box G'} 
                labelPosition={LabelPosition.Left}
                defaultValue={280}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxHP1X'} 
                testId={'boxHP1X'} 
                label={'Box H'} 
                labelPosition={LabelPosition.Left}
                defaultValue={400}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxIP1X'} 
                testId={'boxIP1X'} 
                label={'Box I'} 
                labelPosition={LabelPosition.Left}
                defaultValue={520}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxJP1X'} 
                testId={'boxJP1X'} 
                label={'Box J'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxKP1X'} 
                testId={'boxKP1X'} 
                label={'Box K'} 
                labelPosition={LabelPosition.Left}
                defaultValue={160}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P1X'} 
                testId={'otherInfoAmount1P1X'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={100}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P1X'} 
                testId={'otherInfoCode1P1X'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={35}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P1X'} 
                testId={'otherInfoAmount2P1X'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={260}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P1X'} 
                testId={'otherInfoCode2P1X'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={175}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P1X'} 
                testId={'otherInfoAmount3P1X'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P1X'} 
                testId={'otherInfoCode3P1X'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P1X'} 
                testId={'otherInfoAmount4P1X'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={520}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P1X'} 
                testId={'otherInfoCode4P1X'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={453}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceFirstNameP1X'} 
                testId={'resouceFirstNameP1X'} 
                label={'Resouce First Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceLastNameP1X'} 
                testId={'resouceLastNameP1X'} 
                label={'Resouce Last Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceAddressLine1P1X'} 
                testId={'resouceAddressLine1P1X'} 
                label={'Resouce Address Line 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceAddressLine2P1X'} 
                testId={'resouceAddressLine2P1X'} 
                label={'Resouce Address Line 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceCityP1X'} 
                testId={'resouceCityP1X'} 
                label={'Resouce City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceProvinceCodeP1X'} 
                testId={'resourceProvinceCodeP1X'} 
                label={'Resource Province Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourcePostalCodeFirstThreeP1X'} 
                testId={'resourcePostalCodeFirstThreeP1X'} 
                label={'Resource Postal Code First Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={80}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourcePostalCodeLastThreeP1X'} 
                testId={'resourcePostalCodeLastThreeP1X'} 
                label={'Resource Postal Code Last Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={116}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientNameLine1P1X'} 
                testId={'clientNameLine1P1X'} 
                label={'Client Name Line 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientNameLine2P1X'} 
                testId={'clientNameLine2P1X'} 
                label={'Client Name Line 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientAddressLine1P1X'} 
                testId={'clientAddressLine1P1X'} 
                label={'Client Address Line 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientAddressLine2P1X'} 
                testId={'clientAddressLine2P1X'} 
                label={'Client Address Line 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientCityP1X'} 
                testId={'clientCityP1X'} 
                label={'Client City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientProvinceCodeP1X'} 
                testId={'clientProvinceCodeP1X'} 
                label={'Client Province Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={335}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientPostalCodeFirstThreeP1X'} 
                testId={'clientPostalCodeFirstThreeP1X'} 
                label={'Client Postal Code First Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={375}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientPostalCodeLastThreeP1X'} 
                testId={'clientPostalCodeLastThreeP1X'} 
                label={'Client Postal Code Last Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={415}
            />
            <RHookFormTextFieldWithLabel 
                id={'codeDeLaDeviseSlipTypeP1X'} 
                testId={'codeDeLaDeviseSlipTypeP1X'} 
                label={'Code DeLa Devise Slip Type'} 
                labelPosition={LabelPosition.Left}
                defaultValue={310}
            />
            <RHookFormTextFieldWithLabel 
                id={'fsP1X'} 
                testId={'fsP1X'} 
                label={'Fs'} 
                labelPosition={LabelPosition.Left}
                defaultValue={524}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl3202210AboveBoxP1X'} 
                testId={'rl3202210AboveBoxP1X'} 
                label={'RL-3 2022 10 Above Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={510}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl3202210DownBoxP1X'} 
                testId={'rl3202210DownBoxP1X'} 
                label={'RL-3 2022 10 Down Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={510}
            />
            <RHookFormTextFieldWithLabel 
                id={'lastNameOrCompanyNameOfTheSecondHolderX'} 
                testId={'lastNameOrCompanyNameOfTheSecondHolderX'} 
                label={'LastName Or Company Name Of The Second Holder'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'firstNameOrCompanyNameOfTheSecondHolderX'} 
                testId={'firstNameOrCompanyNameOfTheSecondHolderX'} 
                label={'FirstName Or Company Name Of The Second Holder'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP1Y'} 
                testId={'taxYearP1Y'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={740}
            />
            <RHookFormTextFieldWithLabel 
                id={'statementCodeP1Y'} 
                testId={'statementCodeP1Y'} 
                label={'Statement Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={740}
            />
            <RHookFormTextFieldWithLabel 
                id={'currencyCodeP1Y'} 
                testId={'currencyCodeP1Y'} 
                label={'Currency Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={740}
            />
            <RHookFormTextFieldWithLabel 
                id={'numberOfLastStatementSentP1Y'} 
                testId={'numberOfLastStatementSentP1Y'} 
                label={'Number Of Last Statement Sent'} 
                labelPosition={LabelPosition.Left}
                defaultValue={740}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxA1P1Y'} 
                testId={'boxA1P1Y'} 
                label={'Box A1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={705}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxA2P1Y'} 
                testId={'boxA2P1Y'} 
                label={'Box A2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={705}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxBP1Y'} 
                testId={'boxBP1Y'} 
                label={'Box B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={705}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxCP1Y'} 
                testId={'boxCP1Y'} 
                label={'Box C'} 
                labelPosition={LabelPosition.Left}
                defaultValue={705}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxDP1Y'} 
                testId={'boxDP1Y'} 
                label={'Box D'} 
                labelPosition={LabelPosition.Left}
                defaultValue={705}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxEP1Y'} 
                testId={'boxEP1Y'} 
                label={'Box E'} 
                labelPosition={LabelPosition.Left}
                defaultValue={680}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxFP1Y'} 
                testId={'boxFP1Y'} 
                label={'Box F'} 
                labelPosition={LabelPosition.Left}
                defaultValue={680}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxGP1Y'} 
                testId={'boxGP1Y'} 
                label={'Box G'} 
                labelPosition={LabelPosition.Left}
                defaultValue={680}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxHP1Y'} 
                testId={'boxHP1Y'} 
                label={'Box H'} 
                labelPosition={LabelPosition.Left}
                defaultValue={680}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxIP1Y'} 
                testId={'boxIP1Y'} 
                label={'Box I'} 
                labelPosition={LabelPosition.Left}
                defaultValue={680}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxJP1Y'} 
                testId={'boxJP1Y'} 
                label={'Box J'} 
                labelPosition={LabelPosition.Left}
                defaultValue={655}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxKP1Y'} 
                testId={'boxKP1Y'} 
                label={'Box K'} 
                labelPosition={LabelPosition.Left}
                defaultValue={655}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P1Y'} 
                testId={'otherInfoAmount1P1Y'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P1Y'} 
                testId={'otherInfoCode1P1Y'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P1Y'} 
                testId={'otherInfoAmount2P1Y'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P1Y'} 
                testId={'otherInfoCode2P1Y'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P1Y'} 
                testId={'otherInfoAmount3P1Y'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P1Y'} 
                testId={'otherInfoCode3P1Y'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P1Y'} 
                testId={'otherInfoAmount4P1Y'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P1Y'} 
                testId={'otherInfoCode4P1Y'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceFirstNameP1Y'} 
                testId={'resouceFirstNameP1Y'} 
                label={'Resouce First Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={552}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceLastNameP1Y'} 
                testId={'resouceLastNameP1Y'} 
                label={'Resouce Last Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={527}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceAddressLine1P1Y'} 
                testId={'resouceAddressLine1P1Y'} 
                label={'Resouce Address Line 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={530}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceAddressLine2P1Y'} 
                testId={'resouceAddressLine2P1Y'} 
                label={'Resouce Address Line 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={480}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceCityP1Y'} 
                testId={'resouceCityP1Y'} 
                label={'Resouce City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={457}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceProvinceCodeP1Y'} 
                testId={'resourceProvinceCodeP1Y'} 
                label={'Resource Province Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={430.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourcePostalCodeFirstThreeP1Y'} 
                testId={'resourcePostalCodeFirstThreeP1Y'} 
                label={'Resource Postal Code First Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={430.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourcePostalCodeLastThreeP1Y'} 
                testId={'resourcePostalCodeLastThreeP1Y'} 
                label={'Resource Postal Code Last Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={430.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientNameLine1P1Y'} 
                testId={'clientNameLine1P1Y'} 
                label={'Client Name Line 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={527}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientNameLine2P1Y'} 
                testId={'clientNameLine2P1Y'} 
                label={'Client Name Line 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={503}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientAddressLine1P1Y'} 
                testId={'clientAddressLine1P1Y'} 
                label={'Client Address Line 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={480}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientAddressLine2P1Y'} 
                testId={'clientAddressLine2P1Y'} 
                label={'Client Address Line 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={457}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientCityP1Y'} 
                testId={'clientCityP1Y'} 
                label={'Client City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={430}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientProvinceCodeP1Y'} 
                testId={'clientProvinceCodeP1Y'} 
                label={'Client Province Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={406.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientPostalCodeFirstThreeP1Y'} 
                testId={'clientPostalCodeFirstThreeP1Y'} 
                label={'Client Postal Code First Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={406.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientPostalCodeLastThreeP1Y'} 
                testId={'clientPostalCodeLastThreeP1Y'} 
                label={'Client Postal Code Last Three'} 
                labelPosition={LabelPosition.Left}
                defaultValue={406.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'codeDeLaDeviseSlipTypeP1Y'} 
                testId={'codeDeLaDeviseSlipTypeP1Y'} 
                label={'Code DeLa Devise Slip Type'} 
                labelPosition={LabelPosition.Left}
                defaultValue={740}
            />
            <RHookFormTextFieldWithLabel 
                id={'fsP1Y'} 
                testId={'fsP1Y'} 
                label={'Fs'} 
                labelPosition={LabelPosition.Left}
                defaultValue={63}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl3202210AboveBoxP1Y'} 
                testId={'rl3202210AboveBoxP1Y'} 
                label={'RL-3 2022 10 Above Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={749.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl3202210DownBoxP1Y'} 
                testId={'rl3202210DownBoxP1Y'} 
                label={'RL-3 2022 10 Down Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={737}
            />
            <RHookFormTextFieldWithLabel 
                id={'lastNameOrCompanyNameOfTheSecondHolderY'} 
                testId={'lastNameOrCompanyNameOfTheSecondHolderY'} 
                label={'LastName Or Company Name Of The Second Holder'} 
                labelPosition={LabelPosition.Left}
                defaultValue={406}
            />
            <RHookFormTextFieldWithLabel 
                id={'firstNameOrCompanyNameOfTheSecondHolderY'} 
                testId={'firstNameOrCompanyNameOfTheSecondHolderY'} 
                label={'FirstName Or Company Name Of The Second Holder'} 
                labelPosition={LabelPosition.Left}
                defaultValue={385}
            />
        </Grid>
    </Grid>
  )
}

export default RL3SLipAxisPage1