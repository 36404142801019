import { CHANGE_PASSWORD_SCREEN_SLUG, INITIAL_SNACKBAR_DATA } from "common/constants";
import { IChangePasswordRequest } from "models/changePassword/IChangePasswordRequest";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { ChangePasswordService } from "services/ChangePasswordService";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  IResponse,
  IServerError,
  ITest,
  LabelPosition,
  RHookFormPasswordFieldWithLabel,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  SubmitButton,
  SubmitButtonVariant,
} from "@websential/cosmic";
import { Grid } from "@mui/material";

import { ChangePasswordSchema } from "./validator";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IChangePassword extends ITest {}

const DEFAULT_VALUES = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

export const ChangePassword: FC<IChangePassword> = ({ testId }) => {
  const dispatch = useDispatch();
  const clientName = useSelector((state : IAppState) => state.client.clientName);
  const [isInitializing, setInitializing] = useState(false);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const { isLoading: isSubmitting, mutate: submitFlexFields } = useMutation(
    ChangePasswordService.update,
    {
      onSuccess: (
        res: IChangePasswordRequest | IServerError | IResponse | any
      ) => {
        setSnackbarData({
          open: true,
          message: res?.message ? res.message : "Something went wrong!",
          severity: res.status === false ? "error" : "success",
        });
      },
      onError: (err: any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err?.message ? err.message : "Somethingwent wrong!",
          severity: "error",
        });
      },
    }
  );

  const formMethods = useForm<IChangePasswordRequest>({
    resolver: yupResolver(ChangePasswordSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = (formData: IChangePasswordRequest) => {
    submitFlexFields(formData);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset(DEFAULT_VALUES);
  };

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:CHANGE_PASSWORD_SCREEN_SLUG,
      companyName:clientName,
      activityType:"activity_type=create&activity_type=updated",
      activityLogVisibility:true
    }));
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  },[])
  return (
    <Grid sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}>
      <BackdropCircularProgress open={isSubmitting} testId={testId} />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            children={
              <StandardPanel
                title="Change Password"
                testId={`${testId}-standard-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <RHookFormPasswordFieldWithLabel
                  id="oldPassword"
                  testId={`${testId}-old-password`}
                  label="Old password"
                  labelPosition={LabelPosition.Left}
                />
                <RHookFormPasswordFieldWithLabel
                  id="newPassword"
                  testId={`${testId}-new-password`}
                  label="New password"
                  labelPosition={LabelPosition.Left}
                />
                <RHookFormPasswordFieldWithLabel
                  id="confirmNewPassword"
                  testId={`${testId}-confirm-new-password`}
                  label="Confirm new password"
                  labelPosition={LabelPosition.Left}
                />
              </StandardPanel>
            }
            toolbar={
              <StandardSubmitFormButtonsToolbar
                toolbarContents={
                  <SubmitButton
                    label="UPDATE PASSWORD"
                    variant={SubmitButtonVariant.Primary}
                    testId="update-password-button"
                    // loading={isSubmitting}
                  />
                }
                onResetClicked={onResetClicked}
                isSubmitting={false}
                testId={""}
              />
            }
            testId={""}
          />
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
