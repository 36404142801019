import { FlexFieldPanelSetup } from "components/FlexFieldPanel";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IFlexFieldPanelSetupPage {}

export const FlexFieldPanelSetupPage: FC<IFlexFieldPanelSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Flex Field Panel Setup"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Flex Field Panels", navigateTo:"/flex-field-panels/list"},
      { text: "Create Flex Field Panel", navigateTo: "/flex-field-panels/setup" }
      ]}
      mainContents={<FlexFieldPanelSetup testId="flex-field-panel-setup" />}
    />
  );
};
