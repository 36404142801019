// import { EMPLOYEE_RESOURCE_LITERAL, INITIAL_SNACKBAR_DATA, QUERY_OPTIONS, VENDOR_RESOURCE_LITERAL } from "common/constants";
// import {
//   instanceOfIResource,
// } from "common/instance-method";
// import { IResponse, IServerError } from "models/common";
// import { IResource } from "models/resource";
// import React, { useEffect, useState } from "react";
// import { useMutation, useQuery, useQueryClient } from "react-query";
// import { useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import { ResourceService } from "services/ResourceService";
// import { IAppState } from "store/reducers";
// import { format } from 'date-fns';

// import {
//   BackdropCircularProgress,
//   DeleteConfirmationDialog,
//   EnhancedDropDownRHFWithLabel,
//   EnhancedSnackbar,
//   FieldLayout,
//   IEnhancedDropDownItem,
//   LabelPosition,
//   StandardPanel,
//   TwoColumnLayout,
// } from "@websential/cosmic";
// import { Grid } from "@mui/material";
// import { FormProvider, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { EmployeeSetupValidationSchema } from "./EmployeeValidator";
// import { ReourceSetupToolbar } from "./ResourceSetupToolbar";
// import { IDropDownListResponse } from "models";
// import { T4SlipService } from "services/T4SlipService";
// import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
// import { EmployeeInfoSetupFieldsPanel } from "./Panels/EmployeeInfoSetupFieldsPanel";
// import { Calendar } from "primereact/calendar";
// import { EmployeeAddressSetupFieldsPanel } from "./Panels/EmployeeAddressSetupFieldsPanel";
// import { FilingDataSetupFieldsPanel } from "./Panels/FilingDataSetupFieldsPanel";
// import { VendorInfoSetupFieldsPanel } from "./Panels/VendorInfoSetupFieldsPanel";
// import { VendorAddressSetupFieldsPanel } from "./Panels/VendorAddressSetupFieldsPanel";
// import { VendorSetupValidationSchema } from "./vendorValidator";
// import { T4ASlipService } from "services/T4ASlipService";
// import { PROVINCE_OF_EMPLOYMENT, getPROVINCE_OF_EMPLOYMENT } from "services/common/ProvinceService";
// export interface IResourceSetup {}
// const SINGLE_RECORD_DATA_QUERY_KEY = "resource-single-data-record";

// const useGetData = (
//   id: string | undefined,
//   dataRoleName: string,
//   clientId: string
// ) => {
//   return useQuery(
//     [SINGLE_RECORD_DATA_QUERY_KEY, id],
//     () => ResourceService.getById(id, dataRoleName, clientId),
//     QUERY_OPTIONS
//   );
// };

//   const convertObjToString = (str: string) => {
//     const jsonStringPoe = str as string;
//     const modifiedString = jsonStringPoe.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
//     return JSON.parse(modifiedString);
//   };
// export const ResourceSetup: React.FC<IResourceSetup> = () => {
//   const navigate = useNavigate();
//   const clientId = useSelector((state: IAppState) => state.client.id);
//   const [selectedResourceList, setSelectedResourceList] = useState<
//   IDropDownListResponse[]
//   >([{ label: "Create New", value: "create-new" }]);
//   const [selectedVendorList, setSelectedVendorList] = useState<
//   IDropDownListResponse[]
//   >([{ label: "Create New", value: "create-new" }]);
//   const [openDialog, setDialogOpen] = useState(false);
//   const [selectedResource, setSelectedResource] = useState("create-new");
//   const [selectedVendor, setSelectedVendor] = useState("create-new");
//   const [isLoadingData, setIsLoadingData] = useState(false);
//   const [dateOfBirthPlaceHolder, setDateOfBirthPlaceHolder] = useState('')
//   const [dateOfBirth, setDateOfBirth] = useState<any>('')
//   const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
//   const queryClient = useQueryClient();
//   const { id, resourceName } = useParams();
//   console.log("params----", id);

//   const employeeFormMethods = useForm<any>({
//     resolver: yupResolver(EmployeeSetupValidationSchema),
//     mode: "onBlur",
//     reValidateMode: "onChange",
//     // defaultValues: DEFAULT_VALUES,
//   });
//   const vendorFormMethods = useForm<any>({
//     resolver: yupResolver(VendorSetupValidationSchema),
//     mode: "onBlur",
//     reValidateMode: "onChange",
//     // defaultValues: DEFAULT_VALUES,
//   });

//   const { isLoading: isSubmitting, mutate: submitFormFields } = useMutation(
//     ResourceService.create,
//     {
//       onSuccess: (res: IResource | IServerError | IResponse | any) => {
//         console.log("resonce create", res);
//         if (instanceOfIResource(res)) {
//           queryClient.invalidateQueries(SINGLE_RECORD_DATA_QUERY_KEY);
//           setSnackbarData({
//             open: true,
//             message: `${resourceName} has been added successfully!`,
//             severity: "success",
//           });
//         } else {
//           setSnackbarData({
//             open: true,
//             message: res.message,
//             severity: "error",
//           });
//         }
//          //Load EMployee DDL
//          const fetchEmployeeData = async () => {
//           try {
//             const response: any = await T4SlipService.getEmployeeDDL(clientId, EMPLOYEE_RESOURCE_LITERAL);
//             console.log("responseData-----------------", response);
//             if (response && Array.isArray(response)) {
//               let resources: IDropDownListResponse[] = response.map(
//                 (resourceItem: IDropDownListResponse) => ({
//                   // id: resourceItem.id,
//                   label: resourceItem.label,
//                   value: resourceItem.value,
//                 })
//               );
//               resources.unshift({
//                 label: "Create New",
//                 value: "create-new",
//               });
//               setSelectedResourceList(resources);
//             }
//           } catch (error) {
//             console.error("Error fetching data:", error);
//           }
//         };
//         fetchEmployeeData();

//         //Load Vendor DDL;
//         const fetchVendorData = async () => {
//           setIsLoadingData(true);
//           try {
//             if(resourceName){
//               const response: any = await T4ASlipService.getVendorDDL(clientId, VENDOR_RESOURCE_LITERAL);
//             console.log("responseData-----------------", response);
//             if (response && Array.isArray(response)) {
//               let vendors: IDropDownListResponse[] = response.map(
//                 (resourceItem: IDropDownListResponse) => ({
//                   // id: resourceItem.id,
//                   label: resourceItem.label,
//                   value: resourceItem.value,
//                 })
//               );
//               vendors.unshift({
//                 label: "Create New",
//                 value: "create-new",
//               });
//               setSelectedVendorList(vendors);
//             }
//           }
//           setIsLoadingData(false);
//           } catch (error) {
//             console.error("Error fetching data:", error);
//             setIsLoadingData(false);
//           }
//         };
//         fetchVendorData();
//         //load resource Data
//         if (res) {
//           // setResourceRecord(res)
//           // const AccosiationIdGet = async () => {
//           //   try {
//           //     const response: any = await T4SlipService.getRoleAssociationId(res.id);
//           //     console.log("responseData-----------------", response);
//           //     setSelectedResource(response.associationId)
//           //     setSelectedVendor(response.associationId)
//           //   } catch (error) {
//           //     console.error("Error fetching data:", error);
//           //   }
//           // };
//           // AccosiationIdGet();
//           let path = `/resources/${resourceName}/setup/${res.id}`;
//           navigate(path)
//         }
//       },
//       onError: (err) => {
//         // TODO: customize the toast message based on the response
//         setSnackbarData({
//           open: true,
//           message: "Something went wrong, try again later!",
//           severity: "error",
//         });
//       },
//     }
//   );

//   const { isLoading: isLoadingById, data: selectedDataRecord } = useGetData(
//     id,
//     String(resourceName),
//     clientId
//   );
//   console.log("selectedDataRecordselectedDataRecord",selectedDataRecord)

//   useEffect(() => {
//     // if the selected Employee data has loaded.
//     if(resourceName === "Employee"){
//     if (selectedDataRecord) {
//       Object.keys(selectedDataRecord).forEach((fieldName) => {
//         // @ts-ignore
//         const fieldValue = selectedDataRecord[fieldName];
//         console.log(
//           "fieldNamefieldName",
//           fieldName,
//           " && ",
//           "fieldValuefieldValue ",
//           fieldValue
//         );
//         employeeFormMethods.setValue(fieldName, fieldValue);

//         if (fieldName === "address") {
//           Object.keys(fieldValue).forEach((resourceFieldName) => {
//             const resourceFieldValue = fieldValue[resourceFieldName];
//             Object.keys(resourceFieldValue).forEach((resourceFieldNameNew) => {
//               const resourceFieldValueNew =
//                 resourceFieldValue[resourceFieldNameNew];
//               employeeFormMethods.setValue(resourceFieldNameNew, resourceFieldValueNew);

//               if (resourceFieldNameNew === "country") {
//                 employeeFormMethods.setValue(
//                   resourceFieldNameNew,
//                   resourceFieldValueNew.name ?? undefined
//                 );
//               }
//               if (resourceFieldNameNew === "province") {
//                 let id = resourceFieldValueNew.id;
//                 let name = resourceFieldValueNew.name;
//                 let shortCode = resourceFieldValueNew.shortCode;

//                 console.log("namenamenmae", name);

//                 employeeFormMethods.setValue(
//                   resourceFieldNameNew,
//                   `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
//                   // '{id:1, name: "Alberta",shortCode:"AB"}'
//                 );
//               }
//             });
//           });
//         }
//         if (fieldName === "id") {
//           (async () => {
//             setIsLoadingData(true);
//             try {
//               const responseAssociationId = await T4SlipService.getRoleAssociationId(
//                 fieldValue
//               );
//               employeeFormMethods.setValue("selectedEmployee", responseAssociationId.associationId);
//               setSelectedResource(responseAssociationId.associationId)
//               setSelectedVendor("create-new")
//             } catch (error) {
//               console.error("Error fetching data:", error);
//             }
//             setIsLoadingData(false);
//           })();
//         }
//         if(fieldName === 'uniqueIdentifier'){
//           const formattedNumber = fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
//           employeeFormMethods.setValue("uniqueIdentifier",formattedNumber);
//         }
//       if(fieldName === 'metaFields'){
//         fieldValue.forEach((obj:any) => {
//           employeeFormMethods.setValue(obj.name,obj.value)
//             if(obj.name === 'initials'){
//               employeeFormMethods.setValue('initials',obj.value)
//             }
//             if(obj.name === 'employee_code'){
//               employeeFormMethods.setValue('employeeCode',obj.value)
//             }
//             if(obj.name === 'pay_period'){
//               employeeFormMethods.setValue('payPeriod',obj.value)
//             }
//             if(obj.name === 'province_of_employment'){
//               getPROVINCE_OF_EMPLOYMENT(obj.value)
//               console.log("PROVINCE_OF_EMPLOYMENT",PROVINCE_OF_EMPLOYMENT)
//               if(PROVINCE_OF_EMPLOYMENT !== ''){
//                 employeeFormMethods.setValue("provinceOfEmployment",PROVINCE_OF_EMPLOYMENT);
//               }
//               // setShowbox17IfQC(true)
//             }
//             if(obj.name === 'reduced_ei'){
//                 employeeFormMethods.setValue('reducedEi',obj.value)
//             }
//             if(obj.name === 'date_of_birth'){
//               if(obj.value !== ""){
//               employeeFormMethods.setValue('dateOfBirth',obj.value)
//               if(obj.value.charAt(2) === "*"){
//                 employeeFormMethods.setValue('dateOfBirth', obj.value);
//               setDateOfBirth(obj.value)
//               }else{
//                 const defaultDate = new Date(obj.value);
//                 employeeFormMethods.setValue('dateOfBirth', defaultDate);
//                 setDateOfBirth(formatDate(defaultDate as Date))
//               }
//               setDateOfBirthPlaceHolder(obj.value)
//             }
//             }
//             if(obj.name === 'cpp_qpp_exempt'){
//                 employeeFormMethods.setValue('cppQppExempt',obj.value)
//             }
//             if(obj.name === 'ppip_exempt'){
//                 employeeFormMethods.setValue('ppipExempt',obj.value)
//             }
//             if(obj.name === 'ei_exempt'){
//                 employeeFormMethods.setValue('eiExempt',obj.value)
//             }
//             if(obj.name === 'employment_code'){
//               employeeFormMethods.setValue('employmentCode',obj.value)
//             }
//             console.log(`"objobj" : ${obj.name} ====== objValue : ${obj.value}`)
//         })
//       }
//       });
//       }
//     } else {
//       // if the selected Vendor data has loaded.
//       if (selectedDataRecord) {
//         Object.keys(selectedDataRecord).forEach((fieldName) => {
//           // @ts-ignore
//           const fieldValue = selectedDataRecord[fieldName];
//           console.log(
//             "fieldNamefieldName",
//             fieldName,
//             " && ",
//             "fieldValuefieldValue ",
//             fieldValue
//           );
//           vendorFormMethods.setValue(fieldName, fieldValue);

//           if (fieldName === "address") {
//             Object.keys(fieldValue).forEach((resourceFieldName) => {
//               const resourceFieldValue = fieldValue[resourceFieldName];
//               Object.keys(resourceFieldValue).forEach((resourceFieldNameNew) => {
//                 const resourceFieldValueNew =
//                   resourceFieldValue[resourceFieldNameNew];
//                 vendorFormMethods.setValue(resourceFieldNameNew, resourceFieldValueNew);
//                 if (resourceFieldNameNew === "country") {
//                   vendorFormMethods.setValue(
//                     resourceFieldNameNew,
//                     resourceFieldValueNew.name ?? undefined
//                   );
//                 }
//                 if (resourceFieldNameNew === "province") {
//                   let id = resourceFieldValueNew.id;
//                   let name = resourceFieldValueNew.name;
//                   let shortCode = resourceFieldValueNew.shortCode;

//                   console.log("namenamenmae", name);

//                   vendorFormMethods.setValue(
//                     resourceFieldNameNew,
//                     `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
//                     // '{id:1, name: "Alberta",shortCode:"AB"}'
//                   );
//                 }
//               });
//             });
//           }
//           if (fieldName === "id") {
//             (async () => {
//               setIsLoadingData(true);
//               try {
//                 const responseAssociationId = await T4SlipService.getRoleAssociationId(
//                   fieldValue
//                   );
//                   console.log("responseData getRoleAssociationId : ", responseAssociationId.associationId);
//                   vendorFormMethods.setValue("selectedVendor", responseAssociationId.associationId);
//                   setSelectedVendor(responseAssociationId.associationId)
//                   setSelectedResource("create-new")

//                 } catch (error) {
//                   console.error("Error fetching data:", error);
//                 }
//               setIsLoadingData(false);
//           })();
//         }
//         if(fieldName === 'uniqueIdentifier'){
//           const formattedNumber = fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
//           vendorFormMethods.setValue("uniqueIdentifier",formattedNumber);
//         }
//           if(fieldName === 'metaFields'){
//             fieldValue.forEach((obj:any) => {
//               if(obj.name === 'payee_type'){
//                 vendorFormMethods.setValue('payeeType',obj.value)
//               } else if(obj.name === 'initial'){
//                 vendorFormMethods.setValue('initials',obj.value)
//               }
//               vendorFormMethods.setValue(obj.name,obj.value)
//             })
//           }
//         });
//         }
//     }
//   }, [selectedDataRecord, employeeFormMethods.setValue, employeeFormMethods, vendorFormMethods.setValue, vendorFormMethods]);

//   const onSubmit = (formData: any) => {
//     console.log("formData",formData)
//     console.log("businessNumber11",employeeFormMethods.getValues('businessNumber'))
//     if(resourceName === 'Employee'){
//       let employeeRequestPayload = {
//         clientId: clientId,
//         id:selectedDataRecord  ? selectedDataRecord.id : undefined,
//         dataRoleName: resourceName,
//         fixedFields:{
//           name1: formData.name1,
//           name2: formData.name2,
//           uniqueIdentifier: formData.uniqueIdentifier.replace(/-/g, ''),
//           businessName: formData.businessName,
//           businessNumber: formData.businessNumber && formData.businessNumber.replace(/(\d{9})(\d{4})/, '$1RP$2'),
//           statusId:formData.statusId,
//           address:[{
//             addressLine1:formData.addressLine1,
//             city: formData.city,
//             countryId:"1",
//             provinceId:convertObjToString(formData.province).id.toString(),
//             postalCode: formData.postalCode,
//           }]
//         },
//         metaFields: [
//           {fieldId:"26",name:"reduced_ei",value:formData.reducedEi},
//           {fieldId:"25",name:"pay_period",value:formData.payPeriod},
//           {fieldId:"24",name:"employment_code",value:formData.employmentCode},
//           {fieldId:"20",name:"province_of_employment",value:convertObjToString(formData.provinceOfEmployment).id.toString()},
//           {fieldId:"19",name:"ext2",value:formData.ext2},
//           {fieldId:"18",name:"phone2",value:formData.phone2 && formData.phone2.replace(/\D/g, '')},
//           {fieldId:"17",name:"ext1",value:formData.ext1},
//           {fieldId:"16",name:"phone1",value:formData.phone1 && formData.phone1.replace(/\D/g, '')},
//           {fieldId:"15",name:"email",value:formData.email},
//           {fieldId:"7",name:"employee_type",value:formData.employee_type},
//           {fieldId:"5",name:"employee_code",value:formData.employeeCode},
//           {fieldId:"4",name:"date_of_birth",value:formData.dateOfBirth && formData.dateOfBirth.charAt(2) === "*" ? "" : dateOfBirth !== "" || null ? dateOfBirth : ""},
//           {fieldId:"3",name:"initials",value:formData.initials},
//           {fieldId:"21",name:"cpp_qpp_exempt",value:formData.cppQppExempt},
//           {fieldId:"22",name:"ei_exempt",value:formData.eiExempt},
//           {fieldId:"23",name:"ppip_exempt",value:formData.ppipExempt},
//         ]
//       }
//       console.log("employeeRequestPayload----------",employeeRequestPayload)
//       submitFormFields(employeeRequestPayload);
//     } else {
//       let vendorRequestPayload = {
//         clientId: clientId,
//         id:selectedDataRecord  ? selectedDataRecord.id : undefined,
//         dataRoleName: resourceName,
//         fixedFields:{
//           name1: formData.name1,
//           name2: formData.name2,
//           uniqueIdentifier: formData.uniqueIdentifier.replace(/-/g, ''),
//           businessName: formData.businessName,
//           businessNumber: formData.businessNumber && formData.businessNumber.replace(/(\d{9})(\d{4})/, '$1RP$2'),
//           statusId:formData.statusId,
//           address:[{
//             addressLine1:formData.addressLine1,
//             city: formData.city,
//             countryId:"1",
//             provinceId:convertObjToString(formData.province).id.toString(),
//             postalCode: formData.postalCode,
//           }]
//         },
//         metaFields: [
//           {fieldId:"4",name:"initial",value:formData.initials},
//           {fieldId:"15",name:"email",value:formData.email},
//           {fieldId:"16",name:"phone1",value:formData.phone1 && formData.phone1.replace(/\D/g, '')},
//           {fieldId:"17",name:"ext1",value:formData.ext1},
//           {fieldId:"18",name:"phone2",value:formData.phone2 && formData.phone2.replace(/\D/g, '')},
//           {fieldId:"19",name:"ext2",value:formData.ext2},
//           ]
//       }
//       console.log("vendorRequestPayload----------",vendorRequestPayload)
//       submitFormFields(vendorRequestPayload);
//     }
//   };

//   const onSnackbarClosed = (
//     _event: React.SyntheticEvent | Event,
//     reason?: string
//   ) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setSnackbarData(INITIAL_SNACKBAR_DATA);
//   };

// const onResetClicked = () => {
//   setSnackbarData({
//     open: true,
//     message: "Form was reset successfully",
//     severity: "success",
//   });
//   employeeFormMethods.reset();
//   vendorFormMethods.reset();
//   let path = `/resources/${resourceName}/setup`;
//   navigate(path);
// };

// const onResourceSelectionChangeHandler = async (
//   selectedItem: IEnhancedDropDownItem
// ): Promise<void> => {
//   console.log("selectedItem.value", selectedItem.value);

//   if(selectedItem.value === "create-new"){
//     setIsLoadingData(true);
//     setSnackbarData({
//       open: true,
//       message: "Please load an Employee",
//       severity: "error",
//     });
//     employeeFormMethods.reset()
//     setDateOfBirth('')
//     setDateOfBirthPlaceHolder('')
//     let path = `/resources/${resourceName}/setup`;
//     navigate(path);
//     setSelectedResource("create-new");
//     setIsLoadingData(false);
//   } else {
//   try {
//     const response = await T4SlipService.getResourceActualId(
//       selectedItem.value
//     );

//     console.log("responseData", response.resourceId);
//     setSelectedResource(String(response.resourceId));
//     let path = `/resources/${resourceName}/setup/${response.resourceId}`;
//     navigate(path);
//   } catch (err) {
//     console.log("err", err);
//   }
// }
// };
// const onVendorSelectionChangeHandler = async (
//   selectedItem: IEnhancedDropDownItem
// ): Promise<void> => {
//   console.log("selectedItem.value", selectedItem.value);

//   if(selectedItem.value === "create-new"){
//     setIsLoadingData(true);
//     setSnackbarData({
//       open: true,
//       message: "Please load an Vendor",
//       severity: "error",
//     });
//     vendorFormMethods.reset()
//     let path = `/resources/${resourceName}/setup`;
//     navigate(path);
//     setSelectedResource("create-new");
//     setIsLoadingData(false);
//   } else {
//   try {
//     const response = await T4SlipService.getResourceActualId(
//       selectedItem.value
//     );
//     console.log("responseData", response.resourceId);
//     setSelectedVendor(String(response.resourceId));
//     let path = `/resources/${resourceName}/setup/${response.resourceId}`;
//     navigate(path);
//   } catch (err) {
//     console.log("err", err);
//   }
// }
// };

// const formatDate = (date: Date): string => {
//   return format(date, 'yyyy-MM-dd'); // Format the date as "yyyy-MM-dd"
// };

// const onDateOfBirthChangeHandler = (selectedDate: any) => {
//   console.log("onDateOfBirthChangeHandler",selectedDate);
//   console.log("format date to 2023-07-26",formatDate(selectedDate.value as Date));
//   // alert(formatDate(selectedDate.value as Date));
//   setDateOfBirth(formatDate(selectedDate.value as Date))
// };

// useEffect(() => {
//   //Load Resource DDL;
//   const fetchData = async () => {
//     setIsLoadingData(true);
//     try {
//       if(resourceName){
//         const response: any = await T4SlipService.getEmployeeDDL(clientId, EMPLOYEE_RESOURCE_LITERAL);
//       console.log("responseData-----------------", response);

//       if (response && Array.isArray(response)) {
//         let resources: IDropDownListResponse[] = response.map(
//           (resourceItem: IDropDownListResponse) => ({
//             // id: resourceItem.id,
//             label: resourceItem.label,
//             value: resourceItem.value,
//           })
//         );

//         resources.unshift({
//           label: "Create New",
//           value: "create-new",
//         });

//         setSelectedResourceList(resources);
//       }
//     }
//     setIsLoadingData(false);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setIsLoadingData(false);
//     }
//   };

//   fetchData();

// }, []);

// useEffect(() => {
//   //Load Vendor DDL;
//   const fetchData = async () => {
//     setIsLoadingData(true);
//     try {
//       if(resourceName){
//         const response: any = await T4ASlipService.getVendorDDL(clientId, VENDOR_RESOURCE_LITERAL);
//       console.log("responseData-----------------", response);

//       if (response && Array.isArray(response)) {
//         let vendors: IDropDownListResponse[] = response.map(
//           (resourceItem: IDropDownListResponse) => ({
//             // id: resourceItem.id,
//             label: resourceItem.label,
//             value: resourceItem.value,
//           })
//         );

//         vendors.unshift({
//           label: "Create New",
//           value: "create-new",
//         });

//         setSelectedVendorList(vendors);
//       }
//     }
//     setIsLoadingData(false);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setIsLoadingData(false);
//     }
//   };
//   fetchData();
// }, []);

// const onCloseHandler = () => {
//   setDialogOpen(false);
// };

// // for delete row
// const [singleDelete, setSingleDelete] = useState<boolean>(false);
// const [resourceId, setResourceId] = useState<number>(0);

// const { isLoading: isDeleting, mutate: deleteResourceById } = useMutation(
//     ResourceService.delResource,
//     {
//       onSuccess: (res: string | IServerError | IResponse | any) => {
//         if (typeof res === "string") {
//           // remove the cache of the Year fields since we just added a new field.
//           setSnackbarData({
//             open: true,
//             message: "Record has been deleted successfully.",
//             severity: "success",
//           });

//           // return queryClient.invalidateQueries(apiKey);
//         } else {
//           setSnackbarData({
//             open: true,
//             message: res.message,
//             severity: "error",
//           });
//         }
//         employeeFormMethods.reset()
//         vendorFormMethods.reset()
//         let path = `/resources/${resourceName}/setup`;
//         navigate(path);
//       },
//       onError: (err:any) => {
//         // TODO: customize the toast message based on the response
//         setSnackbarData({
//           open: true,
//           message: err.message,
//           severity: "error",
//         });
//       },
//     }
//   );

// // For Delete Row
// const deleteResourceField = () => {
//   if (singleDelete) {
//     setSingleDelete(false);
//     let payload = {
//       resourceId:resourceId,
//       clientId:clientId
//     }
//     deleteResourceById(payload);
//     setDialogOpen(false);
//   }
// };

// const onDeleteClicked = () => {
//   setDialogOpen(true);
//   setSingleDelete(true);
//   // setApiAction("delete");
//   // setResourceId(params.row.id);
//   if(selectedDataRecord){
//     setResourceId(selectedDataRecord.id)
//   }
//   vendorFormMethods.setValue("selectedVendor", "0731f171-da8f-446d-8041-5e7978adc3bb");
// };

// console.log("selectedVendor",selectedVendor)
// console.log("selectedResource",selectedResource)
// console.log("selectedVendorList",selectedVendorList)

//   return (
//     <>
//     {resourceName !== "Vendor" ? (
//       <>
//        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ pb: "5rem", "& .MuiAppBar-root":{zIndex:2}, "& .p-datepicker":{zIndex:2}}}>
//         <BackdropCircularProgress
//           open={
//             isSubmitting ||
//             isLoadingById ||
//             isLoadingData
//             || isDeleting
//           }
//           testId=""
//         />
//         <DeleteConfirmationDialog
//           open={openDialog}
//           deleteActionHandler={deleteResourceField}
//           cancelActionHandler={onCloseHandler}
//           testId="resource-confirmation-dialog"
//         />
//         <FormProvider {...employeeFormMethods}>
//           <form onSubmit={employeeFormMethods.handleSubmit(onSubmit)}>
//             <TwoColumnLayout
//               toolbar={
//                 <ReourceSetupToolbar
//                   onResetClicked={onResetClicked}
//                   onDeleteClicked={onDeleteClicked}
//                   isSubmitting={isSubmitting}
//                   formMethods={employeeFormMethods}
//                 />
//               }
//               leftColumnContent={
//                 <>
//                   <StandardPanel
//                     title="Load Employee"
//                     testId={`load-Employee-panel`}
//                   >
//                     <Grid
//                       container
//                       // columnSpacing={2}
//                       xs={12}
//                       sm={12}
//                       md={12}
//                       lg={12}
//                       xl={12}
//                     >
//                         <EnhancedDropDownRHFWithLabel
//                           label="Load Employee"
//                           selectName="selectedEmployee"
//                           items={selectedResourceList}
//                           selectedItem={selectedResource}
//                           testId="selected-employee"
//                           onSelectionChangeHandler={
//                             onResourceSelectionChangeHandler
//                           }
//                           // isLoading={isLoadingFlexFieldsList}
//                         />
//                     </Grid>
//                   </StandardPanel>
//                   <StandardPanel
//                     title="Employee Info"
//                     testId={`-employee-info`}
//                     topPadding={2}
//                   >
//                     <EmployeeInfoSetupFieldsPanel/>
//                      <FieldWrapper>
//                       <FieldLayout
//                           label="Date of Birth"
//                           // isRequired={isRequired}
//                           testId=""
//                           labelPosition={LabelPosition.Left}
//                           labelColumns={4}
//                           fieldColumns={8}
//                           >
//                           <Calendar
//                             id="dateOfBirth"
//                             {...employeeFormMethods.register('dateOfBirth')} // Register the input with react-hook-form using methods.register
//                             value={employeeFormMethods.watch('dateOfBirth') || null} // Set the value using watch to reflect changes
//                             showIcon
//                             placeholder={dateOfBirthPlaceHolder !== "" && dateOfBirthPlaceHolder.charAt(2) === '*' ? 'Unmask to view / edit' : 'dd/mm/yyyy'}
//                             disabled={dateOfBirthPlaceHolder !== "" && dateOfBirthPlaceHolder.charAt(2) === '*'}  //if unmask then it is enabled
//                             className="w-full"
//                             style={{ height: '2.6rem',marginTop:"4px",marginBottom:"4.3rem" }}
//                             onChange={onDateOfBirthChangeHandler}
//                           />
//                         </FieldLayout>
//                     </FieldWrapper>
//                   </StandardPanel>
//                 </>
//               }
//               rightColumnContent={
//                 <>
//                   <StandardPanel
//                     title="Address"
//                     testId={`-employee-address-panel`}
//                   >
//                     <EmployeeAddressSetupFieldsPanel testId={""} />
//                   </StandardPanel>

//             <StandardPanel
//                     title="Filing Data Attributes"
//                     testId={`-filing-data-panel`}
//                     topPadding={2}
//                   >
//                     <FilingDataSetupFieldsPanel />
//             </StandardPanel>
//                 </>
//               }
//               testId="resource-setup-form"
//             />
//           </form>
//         </FormProvider>
//         </Grid>
//         <EnhancedSnackbar
//         message={snackbarData.message}
//         onCloseHandler={onSnackbarClosed}
//         severity={snackbarData.severity}
//         testId={`snackbar`}
//         open={snackbarData.open}
//       />
//       </>
//     ):(
//       <>
//       <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ pb: "5rem", "& .MuiAppBar-root":{zIndex:2}, "& .p-datepicker":{zIndex:2}}}>
//         <BackdropCircularProgress
//           open={
//             isSubmitting ||
//             isLoadingById ||
//             isLoadingData
//             || isDeleting
//           }
//           testId=""
//         />
//         <DeleteConfirmationDialog
//           open={openDialog}
//           deleteActionHandler={deleteResourceField}
//           cancelActionHandler={onCloseHandler}
//           testId="resource-confirmation-dialog"
//         />
//         <FormProvider {...vendorFormMethods}>
//           <form onSubmit={vendorFormMethods.handleSubmit(onSubmit)}>
//             <TwoColumnLayout
//               toolbar={
//                 <ReourceSetupToolbar
//                   onResetClicked={onResetClicked}
//                   onDeleteClicked={onDeleteClicked}
//                   isSubmitting={isSubmitting}
//                   formMethods={vendorFormMethods}
//                 />
//               }
//               leftColumnContent={
//                 <>
//                   <StandardPanel
//                     title="Load Vendor"
//                     testId={`load-Vendor-panel`}
//                   >
//                     <Grid
//                       container
//                       // columnSpacing={2}
//                       xs={12}
//                       sm={12}
//                       md={12}
//                       lg={12}
//                       xl={12}
//                     >
//                         <EnhancedDropDownRHFWithLabel
//                           label="Load Vendor"
//                           selectName="selectedVendor"
//                           items={selectedVendorList}
//                           selectedItem={selectedVendor}
//                           testId="selected-Vendor"
//                           onSelectionChangeHandler={
//                             onVendorSelectionChangeHandler
//                           }
//                           // isLoading={isLoadingFlexFieldsList}
//                         />
//                     </Grid>
//                   </StandardPanel>
//                   <StandardPanel
//                     title="Vendor Info"
//                     testId={`-Vendor-info`}
//                     topPadding={2}
//                   >
//                     <VendorInfoSetupFieldsPanel testId={""}/>
//                   </StandardPanel>
//                 </>
//               }
//               rightColumnContent={
//                 <>
//                   <StandardPanel
//                     title="Address"
//                     testId={`vendor-address-panel`}
//                   >
//                     <VendorAddressSetupFieldsPanel testId={""} />
//                   </StandardPanel>
//                 </>
//               }
//               testId="resource-setup-form"
//             />
//           </form>
//         </FormProvider>
//         </Grid>
//         <EnhancedSnackbar
//           message={snackbarData.message}
//           onCloseHandler={onSnackbarClosed}
//           severity={snackbarData.severity}
//           testId={`snackbar`}
//           open={snackbarData.open}
//         />
//         </>
//     )}
//     </>
//   );
// };

// old Code with tab/stepper form

import {
  EMPLOYEE_RESOURCE_LITERAL,
  EMPLOYEE_SETUP_SCREEN_ID,
  EMPLOYEE_SETUP_SCREEN_SLUG,
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  T3_BENEFICIARY_SETUP_SCREEN_SLUG,
  T5_RECIPIENT_RESOURCE_LITERAL,
  T5_RECIPIENT_SETUP_SCREEN_ID,
  T5_RECIPIENT_SETUP_SCREEN_SLUG,
  VENDOR_RESOURCE_LITERAL,
  VENDOR_SETUP_SCREEN_ID,
  VENDOR_SETUP_SCREEN_SLUG,
} from "common/constants";
import { AddressFields, FlexFieldsUtils } from "common/FlexFieldsUtils";
import { instanceOfIResource } from "common/instance-method";
import { SetupFormLayout } from "layouts/SetupFormLayout";
import { IResponse, IServerError } from "models/common";
import { IResource } from "models/resource";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ResourceService } from "services/ResourceService";
import { IAppState } from "store/reducers";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  BackdropCircularProgress,
  // CategoryFieldsPanelLayout,
  DataType,
  DeleteConfirmationDialog,
  EnhancedDialog,
  EnhancedDropDownRHFWithLabel,
  EnhancedSnackbar,
  FieldType,
  // FlexFieldsFormPanelLayout,
  IPanel,
  ITabLayout,
  StandardPanel,
} from "@websential/cosmic";
import IField from "@websential/cosmic/build/components/RHookFormGenerator/types/IField";
import { Box, Button, Card, Grid, GridSize, Typography } from "@mui/material";
import { AccountantSetupToolbar } from "components/Accountant/AccountantSetup/AccountantSetupToolbar";
import {
  FIELD_TYPE_COMPONENT,
  FlexFieldsFormPanelLayout,
  getFieldName,
  getMultiSelectionDDLDefaultValue,
} from "components/Accountant/AccountantSetup/FlexFieldsFormPanelLayout";
import { resourceJSONdataValidation } from "./resourceJSONdataValidation";
import { ReourceSetupToolbar } from "./ResourceSetupToolbar";
import { EnhancedTextField } from "@websential/cosmic/build/components/EnhancedTextField";
import { T4SlipService } from "services/T4SlipService";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { CategoryFieldsPanelLayout } from "components/Accountant/AccountantSetup/CategoryFieldsPanelLayout";
import {
  clearSelectedResourceApiMessageActions,
  setSelectedResourceApiMessageActions,
} from "store/actions/resourceApiMessageActions";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Avatar } from "primereact/avatar";
import { Divider } from "primereact/divider";
import { DefaultSettingsService } from "services/DefaultSettingsService";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { ClientService } from "services";
export interface IResourceSetup {}
const DEFAULT_SETTINGS_DATA_QUERY_KEY = "default-settings-data-record";
const SINGLE_RECORD_DATA_QUERY_KEY = "resource-single-data-record";

const useGetData = (
  id: string | undefined,
  dataRoleName: string,
  clientId: string
) => {
  return useQuery(
    [SINGLE_RECORD_DATA_QUERY_KEY, id],
    () => ResourceService.getById(id, dataRoleName, clientId),
    { refetchOnWindowFocus: false }
  );
};

const useScreenLayout: any = (resourceName: string) =>
  useQuery(
    `${resourceName.toLowerCase()}-resource-setup-layout`,
    (data) => {
      return ResourceService.getSetupLayout(resourceName);
    },
    QUERY_OPTIONS
  );

const useEmployeeScreenLayout: any = () =>
  useQuery(
    `employee-resource-setup-layout`,
    (data) => {
      return ResourceService.getEmployeeSetupLayout();
    },
    { refetchOnWindowFocus: false }
  );

// const useVendorScreenLayout: any = (payeeType:boolean) =>
//   useQuery(
//     [`vendor-resource-setup-layout`,payeeType],
//     (data) => {
//       return ResourceService.getVendorSetupLayout(payeeType);
//     },
//     { refetchOnWindowFocus: false }
//   );
const VENDOR_FIELDS_DATA_QUERY_KEY = `vendor-resource-setup-layout`;
const useVendorScreenLayout = (payeeType: string) => {
  return useQuery(
    [VENDOR_FIELDS_DATA_QUERY_KEY, payeeType],
    () => ResourceService.getVendorSetupLayout(payeeType),
    QUERY_OPTIONS
  );
};

const T5_RECIPIENT_FIELDS_DATA_QUERY_KEY = `t5-recipient-resource-setup-layout`;
const useT5RecipientScreenLayout = (recipientType: number) => {
  return useQuery(
    [T5_RECIPIENT_FIELDS_DATA_QUERY_KEY, recipientType],
    () => ResourceService.getT5RecipientSetupLayout(recipientType),
    QUERY_OPTIONS
  );
};

const T3_Beneficiary_FIELDS_DATA_QUERY_KEY = `t3-beneficiary-resource-setup-layout`;
const useT3BeneficiaryScreenLayout = (recipientType: number) => {
  return useQuery(
    [T3_Beneficiary_FIELDS_DATA_QUERY_KEY, recipientType],
    () => ResourceService.getT3BeneficiarySetupLayout(recipientType),
    QUERY_OPTIONS
  );
};

const ASSINGED_CATEGORY_DATA_QUERY_KEY = `resource-category-data-record`;
const ASSINGED_CATEGORY_PANEL_QUERY_KEY = `resource-category-panel-record`;

const useGetAssignedCategoryPanel = (selectedCategoriesData: any) => {
  return useQuery(
    [ASSINGED_CATEGORY_PANEL_QUERY_KEY, selectedCategoriesData],
    () =>
      ResourceService.getResourceCategoriesAssingedPanels(
        selectedCategoriesData
      ),
    { refetchOnWindowFocus: false }
  );
};

const useGetResourceCategories = (
  screenId: string | number,
  resourceName: string | undefined
) => {
  return useQuery(
    [ASSINGED_CATEGORY_DATA_QUERY_KEY, screenId, resourceName],
    () => ResourceService.getResourceCategories(screenId, resourceName),
    { refetchOnWindowFocus: false }
  );
};

const useGetDefaultSettingsData = (
  clientId: string,
) => 
  useQuery(
    [DEFAULT_SETTINGS_DATA_QUERY_KEY, clientId],
    (data) => {
      return DefaultSettingsService.getDefaultSettings(clientId);
    },
    { refetchOnWindowFocus: false }
  );

  const useGetClientProfileData = (id: string | undefined) => {
    return useQuery(
      ['client-single-data-record', id],
      (data) => {
        return ClientService.getById(id);
      },
      { refetchOnWindowFocus: false }
    );
  };

export const ResourceSetup: React.FC<IResourceSetup> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const flexFieldPanelHeightRef = useRef<any>(null);
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );

  const homePathRoute = useSelector(
    (state: IAppState) => state.HomePagePath.path
  );
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const resourceApiMessage = useSelector(
    (state: IAppState) => state.resourceApiMessage
  );
  const [metaFieldIds, setMetaFieldIds] = useState({});
  const [renderForm, setRenderForm] = useState(false);
  const [screenFields, setScreenFields] = useState<any>(undefined);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [openDialog, setDialogOpen] = useState(false);
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [resourceId, setResourceId] = useState<number>(0);
  const [payeeType, setPayeeType] = useState("true");
  const [recipientType, setRecipientType] = useState(10);
  const [selectedResourceList, setSelectedResourceList] =
    useState<any>(undefined);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [showCreatedResourceMessage, setshowCreatedResourceMessage] =
    useState(true);
    const [recipientTypeChangeOnEditMode, setRecipientTypeChangeOnEditMode] =
    useState(true);
  const [
    categoryFieldsWithOnChangeMethod,
    setCategoryFieldsWithOnChangeMethod,
  ] = useState<any>(undefined);
  const [resourceTotalPanels, setResourceTotalPanels] =
    useState<any>(undefined);
  const [resourceTotalCategories, setResourceTotalCategories] =
    useState<any>(undefined);
  let screenId = 19;
  const queryClient = useQueryClient();
  const { id, resourceName } = useParams();
  console.log("params----", id);
  const [editRecordId, setEditRecordId] = useState(id);
  const [resourceListWithoutCreateNew, setResourceListWithoutCreateNew] =
    useState<any>(undefined);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [itemSearch, setItemSearch] = useState("");
  const [sidePanelsVisibility, setSidePanelsVisibility] = useState(true);
  const [flexFieldPanelHeight, setFlexFieldPanelHeight] = useState<any>(undefined);

  const { isLoading: isSubmitting, mutate: submitFormFields } = useMutation(
    ResourceService.create,
    {
      onSuccess: (res: IResource | IServerError | IResponse | any) => {
        if (res.status === 201) {
          if (res && res.data.id) {
            let path = `/resources/${resourceName}/setup/${res.data.id}`;
            navigate(path);
            setshowCreatedResourceMessage(false);
            dispatch(
              setSelectedResourceApiMessageActions({
                open: true,
                message: res.data.message
                  ? res.data.message
                  : `${resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName === "T3_Beneficiary" ? "T3 Beneficiary" : resourceName} has been added successfully!`,
                severity: "success",
              })
            );
            window.location.reload();
          }
        } else if (res.status === 200){
          if (res && res.data.id) {
            let path = `/resources/${resourceName}/setup/${res.data.id}`;
            navigate(path);
            setshowCreatedResourceMessage(false);
            dispatch(
              setSelectedResourceApiMessageActions({
                open: true,
                message: res.data.message
                  ? res.data.message
                  : `${resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName === "T3_Beneficiary" ? "T3 Beneficiary" : resourceName} has been updated successfully!`,
                severity: "success",
              })
            );
            window.location.reload();
          }
        } else {
          setSnackbarData({
            open: true,
            message: res.data.message
              ? res.data.message
              : res.message
              ? res.message
              : "Something went wrong.",
            severity: "error",
          });
        }
        console.log("response12", res);
        // let path = `/resources/${resourceName}/list`;
        // navigate(path);
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  // const { isLoading, data: screenFields } = useScreenLayout(resourceName);
  // console.log("screenFields,,",screenFields)

  const { isLoadingEmployeeScreenLayout, data: employeeScreenFields } =
    useEmployeeScreenLayout();
  console.log("employeeScreenFields,,", employeeScreenFields);

  const { isLoading: isLoadingVendorScreenLayout, data: vendorScreenFields } =
    useVendorScreenLayout(payeeType);
  console.log("vendorScreenFields,,", vendorScreenFields);

  const {
    isLoading: isLoadingT5RecipientScreenLayout,
    data: t5RecipientScreenFields,
  } = useT5RecipientScreenLayout(recipientType);
  console.log("t5RecipientScreenFields,,", t5RecipientScreenFields);

  const {
    isLoading: isLoadingT3BeneficiaryScreenLayout,
    data: t3BeneficiaryScreenFields,
  } = useT3BeneficiaryScreenLayout(recipientType);
  console.log("t3BeneficiaryScreenFields,,", t3BeneficiaryScreenFields);

  const { isLoading: isLoadingClientProfileDataById, data: clientProfileData } =
  useGetClientProfileData(clientId);

  const provincesDDL = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon"
  ];
  
  const getProvincesByIndices = (indices:any) => {
    return indices.map((index:any) => provincesDDL[index - 1]).join(",");
  };

  useEffect(() => {
    if (resourceName === "Employee" && employeeScreenFields && clientProfileData && clientProfileData.operatingProvinces) {
      const getPoeDdlAccourdingToClientOperatingProvinces = getProvincesByIndices(clientProfileData.operatingProvinces);
      const covertOperatingProvincesArraytoStr = clientProfileData.operatingProvinces.join(',');
      console.log("clientProfileData",clientProfileData.operatingProvinces)
      console.log("getPoeDdlAccourdingToClientOperatingProvinces",getPoeDdlAccourdingToClientOperatingProvinces)
      console.log("covertOperatingProvincesArraytoStr",covertOperatingProvincesArraytoStr)
      employeeScreenFields &&
      employeeScreenFields.forEach((item: any) => {
        item?.panels.forEach((screenPanel: IPanel) => {
          screenPanel?.fields?.forEach((field: IField) => {
            field.fieldValue =
              field.fieldId === "province_of_employment"
                ? getPoeDdlAccourdingToClientOperatingProvinces
                : field.fieldValue;
              field.fieldInternalValue =
                field.fieldId === "province_of_employment" 
                  ? clientProfileData.operatingProvinces : field.fieldInternalValue;
          });
        });
      });
      setResourceTotalPanels(undefined);
      setScreenFields(employeeScreenFields);
    } else {
      setResourceTotalPanels(undefined);
      setScreenFields(employeeScreenFields);
    }
    if (resourceName === "Vendor" && vendorScreenFields) {
      setResourceTotalPanels(undefined);
      setScreenFields(vendorScreenFields);
    }
    if (resourceName === "T5_Recipient" && t5RecipientScreenFields) {
      setResourceTotalPanels(undefined);
      setScreenFields(t5RecipientScreenFields);
    }
  }, [
    resourceName,
    employeeScreenFields,
    vendorScreenFields,
    t5RecipientScreenFields,
  ]);
  useEffect(() => {
    if (resourceName === "T3_Beneficiary" && t3BeneficiaryScreenFields) {
      setResourceTotalPanels(undefined);
      setScreenFields(t3BeneficiaryScreenFields);
    }
  }, [
    t3BeneficiaryScreenFields
  ]);

  const {
    isLoading: isLoadingById,
    data: selectedDataRecord,
    refetch: refetchResourceDataById,
  } = useGetData(editRecordId, String(resourceName), clientId);

  const { isLoading: isLoadingDefaultSettingsData, data: defaultSettingsData } = useGetDefaultSettingsData(clientId);

  const [selectedCategoriesData, setSelectedCategoriesData] = useState<any>([]);

  const {
    isLoading: isLoadingResourceCategoriesPanels,
    data: resourceCategoryAssingedPanels,
  } = useGetAssignedCategoryPanel(selectedCategoriesData);

  const { isLoading: isLoadingResourceCategories, data: resourceCategoryList } =
    useGetResourceCategories(screenId, resourceName);

  console.log("resourceCategoryAssingedPanels", resourceCategoryAssingedPanels);

  useEffect(() => {
    if (resourceCategoryList) {
      setResourceTotalCategories(resourceCategoryList);
    }
  }, [resourceCategoryList]);

  const defaultSettingsCalls = () => {
    if(!id && !selectedDataRecord && defaultSettingsData && defaultSettingsData.fillingDataAttribute && resourceName === "Employee" && employeeScreenFields){
      console.log("fillingDataAttribute",defaultSettingsData.fillingDataAttribute)
      resourceTotalPanels &&
        resourceTotalPanels.forEach((item: any) => {
          item?.panels.forEach((screenPanel: IPanel) => {
            screenPanel?.fields?.forEach((field: IField) => {
              field.defaultValue =
                field.fieldId === "statusId"
                  ? field.defaultValue
                  : field.fieldId === "statusId"
                  ? field.defaultValue
                  : field.fieldId === "countryId"
                  ? field.defaultValue 
                  : field.fieldId === "employee_type"
                  ? field.defaultValue
                  : field.fieldId === "province_of_employment" && defaultSettingsData.fillingDataAttribute.provinceOfEmployment && defaultSettingsData.fillingDataAttribute.provinceOfEmployment.id
                  ? defaultSettingsData.fillingDataAttribute.provinceOfEmployment.id
                  : field.fieldId === "city" && defaultSettingsData.fillingDataAttribute.city
                  ? defaultSettingsData.fillingDataAttribute.city
                  : field.fieldId === "provinceId" && defaultSettingsData.fillingDataAttribute.province && defaultSettingsData.fillingDataAttribute.province.id
                  ? String(defaultSettingsData.fillingDataAttribute.province.id)
                  : field.fieldId === "cpp_qpp_exempt" && defaultSettingsData.fillingDataAttribute.cppQppExempt
                  ? defaultSettingsData.fillingDataAttribute.cppQppExempt
                  : field.fieldId === "ei_exempt" && defaultSettingsData.fillingDataAttribute.eiExempt
                  ? defaultSettingsData.fillingDataAttribute.eiExempt
                  : field.fieldId === "ppip_exempt" && defaultSettingsData.fillingDataAttribute.ppipExempt
                  ? defaultSettingsData.fillingDataAttribute.ppipExempt
                  : field.fieldId === "employment_code" && defaultSettingsData.fillingDataAttribute.employmentCode
                  ? defaultSettingsData.fillingDataAttribute.employmentCode
                  : field.fieldId === "reduced_ei"
                  ? defaultSettingsData.fillingDataAttribute.reducedEi === 0 ? "False" : "True"
                  : field.fieldId === "pay_period"                  
                  ?  defaultSettingsData.fillingDataAttribute.payPeriod
                  : null;
                field.isRequired =
                  field.fieldId === "date_of_birth" && defaultSettingsData.fillingDataAttribute.employeeDobIsRequired
                    ? defaultSettingsData.fillingDataAttribute.employeeDobIsRequired : field.isRequired;
            });
          });
        });
    }
  }

  const onSubmit = (values: any) => {
    let employeeFlexFields: any = {
      ...values,
      ["postalCode-" + 12 + "-fixed"]:
        values["postalCode-" + 12 + "-fixed"] &&
        values["postalCode-" + 12 + "-fixed"].replace(
          /[a-z]/g,
          (match: string) => match.toUpperCase()
        ),
      ["name1-" + 3 + "-fixed"]:
        values["name1-" + 3 + "-fixed"] !== ""
          ? values["name1-" + 3 + "-fixed"]
          : undefined,
      ["email-" + 15 + "-fixed"]:
        values["email-" + 15 + "-fixed"] !== ""
          ? values["email-" + 15 + "-fixed"]
          : undefined,
      ["date_of_birth-" + 6]:
        values["date_of_birth-" + 6] !== null
          ? values["date_of_birth-" + 6]
          : "",
      ["uniqueIdentifier-" + 2 + "-fixed"]:
        (values["uniqueIdentifier-" + 2 + "-fixed"] &&
          values["uniqueIdentifier-" + 2 + "-fixed"] !== "") ||
        null
          ? values["uniqueIdentifier-" + 2 + "-fixed"].replace(/-/g, "")
          : undefined,
      ["id-" + 27 + "-fixed"]:
        selectedDataRecord && selectedDataRecord.address[0].id
          ? selectedDataRecord.address[0].id
          : undefined,
      ["isPrimary-" + 28 + "-fixed"]:true,
      ["appAddressTypeId-" + 29 + "-fixed"]:1,
    };
    let vendorFlexFields: any = {
      ...values,
      ["postalCode-" + 12 + "-fixed"]:
        values["postalCode-" + 12 + "-fixed"] &&
        values["postalCode-" + 12 + "-fixed"].replace(
          /[a-z]/g,
          (match: string) => match.toUpperCase()
        ),
      ["name1-" + 3 + "-fixed"]:
        values["name1-" + 3 + "-fixed"] !== ""
          ? values["name1-" + 3 + "-fixed"]
          : undefined,
      ["email-" + 15 + "-fixed"]:
        values["email-" + 15 + "-fixed"] !== ""
          ? values["email-" + 15 + "-fixed"]
          : undefined,
      ["uniqueIdentifier-" + 2 + "-fixed"]:
        (values["uniqueIdentifier-" + 2 + "-fixed"] &&
          values["uniqueIdentifier-" + 2 + "-fixed"] !== "") ||
        null
          ? values["uniqueIdentifier-" + 2 + "-fixed"].replace(/-/g, "")
          : undefined,
      // addressId:
      ["id-" + 27 + "-fixed"]:
        selectedDataRecord && selectedDataRecord.address[0].id
          ? selectedDataRecord.address[0].id
          : undefined,
      ["initials-" + 5]:values["initials-" + 5] ? values["initials-" + 5] : '',
      ["phone1-" + 16]:values["phone1-" + 16] ? values["phone1-" + 16] : '',
      ["isPrimary-" + 28 + "-fixed"]:true,
      ["appAddressTypeId-" + 29 + "-fixed"]:1,
    };
    let t5RecipientFlexFields: any = {
      ...values,
      ["postalCode-" + 12 + "-fixed"]:
        values["postalCode-" + 12 + "-fixed"] &&
        values["postalCode-" + 12 + "-fixed"].replace(
          /[a-z]/g,
          (match: string) => match.toUpperCase()
        ),
      ["name1-" + 3 + "-fixed"]:
        values["name1-" + 3 + "-fixed"] !== ""
          ? values["name1-" + 3 + "-fixed"]
          : undefined,
      ["email-" + 15 + "-fixed"]:
        values["email-" + 15 + "-fixed"] !== ""
          ? values["email-" + 15 + "-fixed"]
          : undefined,
      ["uniqueIdentifier-" + 2 + "-fixed"]:
        (values["uniqueIdentifier-" + 2 + "-fixed"] &&
          values["uniqueIdentifier-" + 2 + "-fixed"] !== "") ||
        null
          ? values["uniqueIdentifier-" + 2 + "-fixed"].replace(/-/g, "")
          : undefined,
      // addressId:
      ["id-" + 27 + "-fixed"]:
        selectedDataRecord && selectedDataRecord.address[0].id
          ? selectedDataRecord.address[0].id
          : undefined,
      ["isPrimary-" + 28 + "-fixed"]:true,
      ["appAddressTypeId-" + 29 + "-fixed"]:1,
      ["initial-" + 5]:values["initial-" + 5] ? values["initial-" + 5] : '',
      ["phone1-" + 16]:values["phone1-" + 16] ? values["phone1-" + 16] : '',
      ["firstLineOfRecipientName-" + 2]:values["firstLineOfRecipientName-" + 2] ? values["firstLineOfRecipientName-" + 2] : '',
      ["secondLineOfRecipientName-" + 3]:values["secondLineOfRecipientName-" + 3] ? values["secondLineOfRecipientName-" + 3] : '',
    };
    let t5RecipientFlexFieldsWithTrustAcc: any = {
      ...values,
      ["postalCode-" + 12 + "-fixed"]:
        values["postalCode-" + 12 + "-fixed"] &&
        values["postalCode-" + 12 + "-fixed"].replace(
          /[a-z]/g,
          (match: string) => match.toUpperCase()
        ),
      ["name1-" + 3 + "-fixed"]:
        values["name1-" + 3 + "-fixed"] !== ""
          ? values["name1-" + 3 + "-fixed"]
          : undefined,
      ["email-" + 15 + "-fixed"]:
        values["email-" + 15 + "-fixed"] !== ""
          ? values["email-" + 15 + "-fixed"]
          : undefined,
      ["uniqueIdentifier-" + 2 + "-fixed"]:
        (values["uniqueIdentifier-" + 2 + "-fixed"] &&
          values["uniqueIdentifier-" + 2 + "-fixed"] !== "") ||
        null
          ? values["uniqueIdentifier-" + 2 + "-fixed"].replace(/-/g, "")
          : undefined,
      // addressId:
      ["id-" + 27 + "-fixed"]:
        selectedDataRecord && selectedDataRecord.address[0].id
          ? selectedDataRecord.address[0].id
          : undefined,
      ["isPrimary-" + 28 + "-fixed"]:true,
      ["appAddressTypeId-" + 29 + "-fixed"]:1,
      ["initial-" + 5]:values["initial-" + 5] ? values["initial-" + 5] : '',
      ["phone1-" + 16]:values["phone1-" + 16] ? values["phone1-" + 16] : '',
    };
    console.log("employeeFlexFields--", employeeFlexFields);
    console.log("vendorFlexFields--", vendorFlexFields);
    console.log("t5RecipientFlexFields--", t5RecipientFlexFields);
    console.log("values--", values);

    //add category fields in meta fields
    const categoryFields = () => {
      if (
        selectedCategoriesData &&
        selectedCategoriesData.length > 0 &&
        resourceCategoryList &&
        resourceCategoryList.items.length > 0
      ) {
        const makeSelectedCategoryAsMetaField = selectedCategoriesData.map(
          (item: any) => ({
            fieldId: item.categoryFieldId,
            name: "categoryField",
            value: item.categoryFieldValue,
          })
        );
        console.log("selectedCategoriesData", selectedCategoriesData);
        console.log(
          "makeSelectedCategoryAsMetaField",
          makeSelectedCategoryAsMetaField
        );
        return makeSelectedCategoryAsMetaField;
      } else {
        return [];
      }
    };
    console.log("cateknvlsnkdv", categoryFields());
    const categoryFieldsAsMetaFields = categoryFields();

    let payload = FlexFieldsUtils.formatFixedAndMetaFieldsWithAddressArray(
      resourceName === "Vendor"
        ? vendorFlexFields
        : resourceName === "Employee"
        ? employeeFlexFields
        : values['recipientType-' + 1] === "40" ? t5RecipientFlexFieldsWithTrustAcc : t5RecipientFlexFields,
      metaFieldIds
    );
    payload.id = id;
    payload.clientId = clientId;
    payload.taxYear = taxYear;
    payload.dataRoleName = resourceName;
    payload.metaFields = payload.metaFields
      .filter((field: any) => field.name !== `load_${resourceName}`)
      .concat(
        categoryFieldsAsMetaFields && categoryFieldsAsMetaFields.length > 0
          ? categoryFieldsAsMetaFields
          : []
      );

    console.log("=======", payload);
    console.log("=======String", JSON.stringify(payload));
    submitFormFields(payload);
  };

  useEffect(() => {
    if (id && selectedDataRecord) {
      //category fields update default values
      let metaFields = selectedDataRecord.metaFields;
      let categoryField = metaFields.filter(
        (element: any) => element.name === "categoryField"
      );
      console.log("categoryField--1", categoryField);
      if (
        categoryField &&
        categoryField.length > 0 &&
        resourceCategoryList &&
        resourceCategoryList.items.length > 0
      ) {
        const selectedCategoriesFieldsData = categoryField.map((item: any) => ({
          screenId: screenId,
          categoryFieldId: parseInt(item.fieldId),
          categoryFieldValue: item.value,
        }));
        setSelectedCategoriesData((prevData: any) => [
          ...prevData,
          ...selectedCategoriesFieldsData,
        ]);
        console.log("categoryField--1 inside if ", resourceCategoryList);
        //get all category data from category list
        let selectedCategoriesDataIds =
          categoryField &&
          categoryField.map((item: any) => parseInt(item.fieldId));
        console.log("selectedCategoriesDataIds", selectedCategoriesDataIds);
        const addDefaultValueInCategoryFields = resourceCategoryList.items
          .filter((listItem: any) =>
            categoryField.find(
              (item: any) => parseInt(item.fieldId) === listItem.id
            )
          )
          .map((listItem: any) => {
            const matchingCategory: any = categoryField.find(
              (item: any) => parseInt(item.fieldId) === listItem.id
            );
            return {
              ...listItem,
              defaultValue: matchingCategory.value,
            };
          });
        console.log("resourceCategoryList.items1", resourceCategoryList.items);
        console.log("filteredItem11", addDefaultValueInCategoryFields);

        const finalCategoriesListWithDefaultValue =
          resourceCategoryList.items.map((metaCategory: any) => {
            const matchingItem = addDefaultValueInCategoryFields.find(
              (categoryListItem: any) => categoryListItem.id === metaCategory.id
            );
            return matchingItem ? matchingItem : metaCategory;
          });
        setResourceTotalCategories({
          items: finalCategoriesListWithDefaultValue,
        });
        console.log("finalres1", finalCategoriesListWithDefaultValue);
      }
    }
  }, [id, selectedDataRecord, resourceCategoryList]);

  useEffect(() => {
    // TODO: Navigate to Unauthorized page or permissions denied page
    if (!clientId) {
      navigate(homePathRoute);
    }
  }, [clientId, navigate]);

  const onResourceSelectionChangeHandler = async (
    selectedItem: any,
    index?: any
  ): Promise<void> => {
    console.log("selectedItem.value", selectedItem);
    dispatch(clearSelectedResourceApiMessageActions());

    //this is for card selection
    setSelectedCard(index);

    if (!index) {
      //this is for card selection
      setSelectedCard(selectedItem.value);
    }

    // alert(selectedItem.value)
    if (selectedItem.value === "create-new") {
      // setSnackbarData({
      //   open: true,
      //   message: "Please load an employee",
      //   severity: "error",
      // });
      let path = `/resources/${resourceName}/setup`;
      navigate(path);

      let totalPanels: any = [];
      if (resourceName === "Employee") {
        // totalPanels.unshift(selectedResourceList && selectedResourceList);
        employeeScreenFields &&
          employeeScreenFields.map((item: any) => {
            console.log("itemitem", item);
            totalPanels.push(item);
            if (
              // selectedResourceList &&
              totalPanels &&
              totalPanels.length !== 0
            ) {
              setResourceTotalPanels(totalPanels);
            }
          });
      } else if (resourceName === "Vendor") {
        // totalPanels.unshift(selectedResourceList && selectedResourceList);
        vendorScreenFields &&
          vendorScreenFields.map((item: any) => {
            totalPanels.push(item);
            if (
              // selectedResourceList &&
              totalPanels &&
              totalPanels.length !== 0
            ) {
              setResourceTotalPanels(totalPanels);
            }
          });
      } else if (resourceName === "T5_Recipient") {
        // totalPanels.unshift(selectedResourceList && selectedResourceList);
        t5RecipientScreenFields &&
          t5RecipientScreenFields.map((item: any) => {
            totalPanels.push(item);
            if (
              // selectedResourceList &&
              totalPanels &&
              totalPanels.length !== 0
            ) {
              setResourceTotalPanels(totalPanels);
            }
          });
      } else if (resourceName === "T3_Beneficiary") {
        // totalPanels.unshift(selectedResourceList && selectedResourceList);
        t3BeneficiaryScreenFields &&
          t3BeneficiaryScreenFields.map((item: any) => {
            totalPanels.push(item);
            if (
              // selectedResourceList &&
              totalPanels &&
              totalPanels.length !== 0
            ) {
              setResourceTotalPanels(totalPanels);
            }
          });
      }
      // Show form in add mode
      // setRenderForm(true);
      // //null all defualt values
      // screenFields && screenFields?.panels.forEach((screenPanel: IPanel) => {
      //   screenPanel?.fields?.forEach((field: IField) => {
      //   field.defaultValue =
      //     field.fieldId === "statusId" ? field.defaultValue :
      //     field.fieldId === "countryId" ? field.defaultValue :
      //     resourceName === "Employee" && field.fieldId === "employee_type" ? field.defaultValue :
      //     resourceName === "Employee" && field.fieldId === "pay_period" ? field.defaultValue :
      //     resourceName === "Employee" && field.fieldId === "reduced_ei" ? field.defaultValue :
      //     resourceName === "Vendor" && field.fieldId === "payeeType" ? field.defaultValue : null
      //   })
      // })
      // setIsLoadingData(true);
      // setTimeout(() => {
      //   setIsLoadingData(false);
      // }, 1000);
      window.location.reload();
      setSelectedCard(null);
    } else {
      // try {
      // setIsLoadingData(true);
      // const responseGetResourceActualId = await T4SlipService.getResourceActualId(
      //   selectedItem.value
      //   );
      let path = `/resources/${resourceName}/setup/${selectedItem.value}`;
      navigate(path);
      // setEditRecordId(responseGetResourceActualId && responseGetResourceActualId.resourceId)
      // setEditRecordId(undefined)

      // refetchResourceDataById()
      window.location.reload();

      // console.log("responseData", responseGetResourceActualId.resourceId);
      // setSelectedResource(String(response.resourceId));
      // setSelectedResourceId(String(response.resourceId));
      // if (response.resourceId !== "create new"){
      //   setEditedResourceId(String(response.resourceId));
      // }
      // window.location.reload()
      //null all defualt values
      // (async () => {
      //   try {
      //     setIsLoadingData(true)
      //     let resourceDataById = await ResourceService.getById(responseGetResourceActualId.resourceId, String(resourceName), clientId);
      //     console.log("resourceDataById",resourceDataById)
      //     if(resourceDataById){
      //         screenFields && screenFields?.panels.forEach((screenPanel: IPanel) => {
      //           screenPanel?.fields?.forEach((field: IField) => {
      //             field.defaultValue =
      //             field.fieldId === "statusId" ? field.defaultValue :
      //             field.fieldId === "countryId" ? field.defaultValue :
      //             resourceName === "Employee" && field.fieldId === "employee_type" ? field.defaultValue :
      //             resourceName === "Employee" && field.fieldId === "pay_period" ? field.defaultValue :
      //             resourceName === "Employee" && field.fieldId === "reduced_ei" ? field.defaultValue :
      //             resourceName === "Vendor" && field.fieldId === "payeeType" ? field.defaultValue : null
      //           })
      //         })
      //         if(resourceName === "Employee"){
      //           (async () => {
      //             try {
      //               const responseAssociationId = await T4SlipService.getRoleAssociationId(
      //                 responseGetResourceActualId.resourceId
      //               );
      //               loadResourcePanel && loadResourcePanel?.fields?.forEach((field: any) => field.defaultValue = responseAssociationId.associationId)
      //             } catch(err){
      //               alert(err)
      //             }
      //           })();
      //         }

      //         console.log("outside if 123 screenFields",screenFields)
      //         console.log("outside if 123 resourceDataById",resourceDataById)
      //         if (
      //           screenFields &&
      //           // Array.isArray(screenFields) &&
      //           resourceDataById &&
      //           instanceOfIResource(resourceDataById)
      //         ) {
      //           console.log("inside if 123")
      //           console.log("##### => populating the edit record");
      //           let metaFieldIdsObject: { [key: string]: string } = {};
      //           // screenFields.forEach((screenTab: ITabLayout, index: number) => {
      //             screenFields.panels.forEach((screenPanel: IPanel, index: number) => {
      //               screenPanel.fields.forEach((field: IField) => {
      //                 let isAddressField = AddressFields.find(
      //                   (element) => element === field.fieldId
      //                 );
      //                 console.log(
      //                   "#####=>resourceDataById.hasOwnProperty(field.fieldId)12 - ",
      //                   field.fieldId,
      //                   " - isAddressField - ",
      //                   isAddressField,
      //                   resourceDataById.hasOwnProperty(field.fieldId)
      //                 );

      //                 if (
      //                   field.isFixed &&
      //                   resourceDataById.hasOwnProperty(field.fieldId) &&
      //                   !isAddressField
      //                 ) {
      //                   type objectKey = keyof typeof resourceDataById;
      //                   let fieldValue = field.fieldId as objectKey;
      //                   field.defaultValue = resourceDataById[fieldValue] !== null ? String(resourceDataById[fieldValue]) : "";
      //                   if(field.fieldId === 'uniqueIdentifier'){
      //                     field.defaultValue = resourceDataById[fieldValue] !== null ? String(resourceDataById[fieldValue]).replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3") : "";
      //                   }
      //                   if(field.fieldId === 'email'){
      //                     const maskedStatus:any = sessionStorage.getItem("maskEnabled")
      //                     field.defaultValue = resourceDataById[fieldValue] !== null ? String(resourceDataById[fieldValue]) : resourceDataById[fieldValue] === null && maskedStatus === "true" ? "ze*****3@gmail.com" : resourceDataById[fieldValue];
      //                   }
      //                   console.log("field.defaultValue123",field.defaultValue)
      //                 } else if (field.isFixed && isAddressField) {
      //                   let addressObject = resourceDataById.address[0];
      //                   type addressObjectKey = keyof typeof addressObject;
      //                   let addressFieldKey = field.fieldId as addressObjectKey;
      //                   field.defaultValue = addressObject[addressFieldKey];

      //                   if (
      //                     String(addressFieldKey) === "country" ||
      //                     String(addressFieldKey) === "countryId"
      //                   ) {
      //                     console.log("******", addressObject.country);
      //                     field.defaultValue = String(addressObject.country.id);
      //                   } else if (
      //                     String(addressFieldKey) === "province" ||
      //                     String(addressFieldKey) === "provinceId"
      //                   ) {
      //                     console.log("******", addressObject.province);
      //                     field.defaultValue = String(addressObject.province.id);
      //                   }
      //                 } else if (!field.isFixed) {
      //                   console.log("meta field: ", field.fieldId);
      //                   let metaFields = resourceDataById.metaFields;
      //                   let metaField:any = metaFields.find(
      //                     (element:any) => element.name === field.fieldId
      //                   );
      //                   if (metaField) {
      //                     metaFieldIdsObject[field.fieldId] = metaField.id;
      //                     field.defaultValue = metaField.value;
      //                     if(metaField.name === "reduced_ei"){
      //                       if(metaField.value === true){
      //                         field.defaultValue = "True"
      //                       } else {
      //                         field.defaultValue = "False"
      //                       }
      //                     }
      //                     if(metaField.name === "payeeType"){
      //                       setPayeeType(metaField.value)
      //                     }
      //                     if(metaField.name === "date_of_birth"){
      //                       const maskedStatus:any = sessionStorage.getItem("maskEnabled")
      //                       console.log("metaField.value--",metaField.value)
      //                       if(maskedStatus === "true"){
      //                         field.defaultValue = metaField.value !== "" ? metaField.value : "1****";
      //                         field.dataType = DataType.String;
      //                       } else {
      //                         field.defaultValue = metaField.value;
      //                       }
      //                     }
      //                   }
      //                 } else {
      //                   console.log("field not found: ", field.fieldId);
      //                 }
      //               });
      //               // Show form in edit mode when all data is loaded
      //               if (index === screenFields.panels.length - 1) {
      //                 setRenderForm(true);
      //               }
      //             });
      //           setMetaFieldIds(metaFieldIdsObject);
      //           console.log("metaFieldIdsObject",metaFieldIdsObject)
      //         }
      //         console.log("screenFields123",screenFields)
      //     }
      //   } catch (error) {
      //     setIsLoadingData(false)
      //     console.error("Error fetching data:", error);
      //   } finally {
      //     setIsLoadingData(false)
      //     let path = `/resources/${resourceName}/setup/${responseGetResourceActualId.resourceId}`;
      //     navigate(path);
      //     setEditRecordId(responseGetResourceActualId && responseGetResourceActualId.resourceId)
      //   }
      // })();
      // } catch (err) {
      //   // setIsLoadingData(false)
      //   console.log("err", err);

      //   // alert(err);
      // }
      // finally{
      //   // setIsLoadingData(false)
      // }
    }
  };

  //remove load Employee panel bcz of cards selection
  // let loadResourcePanel = [
  //   {
  //     name: `Load ${resourceName}`,
  //     description: `Load ${resourceName}`,
  //     sequence: 1,
  //     categoryFields: [],
  //     panels: [
  //       {
  //         label: `Load ${
  //           resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName
  //         }`,
  //         categoryFieldId: "",
  //         categoryFieldValue: "",
  //         sequence: 1,
  //         gridColumns: 12 as GridSize,
  //         fields: [
  //           {
  //             id: 1,
  //             fieldLabel: `Load ${
  //               resourceName === "T5_Recipient" ? "T5 Recipient" : resourceName
  //             }`,
  //             fieldId: `load_${resourceName}`,
  //             fieldType: FieldType.DropDownList,
  //             placeholder: "",
  //             fieldValue: "Create New",
  //             fieldInternalValue: "create-new",
  //             onChange: onResourceSelectionChangeHandler,
  //             dataType: DataType.Object,
  //             fieldFormat: "",
  //             defaultValue: "create-new",
  //             labelPosition: "left",
  //             labelColumn: 4 as GridSize,
  //             fieldColumn: 8 as GridSize,
  //             fullFieldColumns: 12,
  //             notes: "",
  //             minValue: "",
  //             maxValue: "",
  //             parent: null,
  //             parentValue: "",
  //             parentRelation: null,
  //             isFixed: false,
  //             isRequired: false,
  //             isCategory: false,
  //             isHide: false,
  //             readOnly: false,
  //             displayMasked: false,
  //             uppercase: false,

  //             fieldClass: "",
  //             validationFormula: "",
  //             comparison: [],
  //             employeeAccess: 0,
  //             variableValue: null,
  //             systemField: false,
  //             apiPath: null,
  //             sequence: 7,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ] as ITabLayout[];

  useEffect(() => {
    console.log("#####=>useEffect", screenFields);
    console.log("#####=>selectedDataRecord", selectedDataRecord);
    if (id) {
      //cards
      setSelectedCard(String(id));
      //null all defualt values
      resourceTotalPanels &&
        resourceTotalPanels.forEach((item: any) => {
          item?.panels.forEach((screenPanel: IPanel) => {
            screenPanel?.fields?.forEach((field: IField) => {
              field.defaultValue =
                field.fieldId === "statusId"
                  ? field.defaultValue
                  : field.fieldId === "statusId"
                  ? field.defaultValue
                  : field.fieldId === "countryId"
                  ? field.defaultValue
                  : field.fieldId === `load_${resourceName}`
                  ? field.defaultValue
                  : resourceName === "Employee" &&
                    field.fieldId === "employee_type"
                  ? field.defaultValue
                  : resourceName === "Employee" &&
                    field.fieldId === "pay_period"
                  ? field.defaultValue
                  : resourceName === "Employee" &&
                    field.fieldId === "reduced_ei"
                  ? field.defaultValue
                  : resourceName === "T5_Recipient" &&
                    field.fieldId === "recipientType"
                  ? field.defaultValue
                  : resourceName === "T3_Beneficiary" &&
                    field.fieldId === "recipientType"
                  ? field.defaultValue
                  : resourceName === "Vendor" && field.fieldId === "payeeType"
                  ? field.defaultValue
                  : null;
            });
          });
        });
      if (resourceTotalPanels) {
        // (async () => {
        //   try {
        //     const responseAssociationId = await T4SlipService.getRoleAssociationId(
        //       id
        //     );
        //     console.log("responseAssociationId",responseAssociationId)
        // loadResourcePanel && loadResourcePanel?.fields?.forEach((field: any) => field.defaultValue = responseAssociationId.associationId)
        // resourceTotalPanels[0].panels[0].fields[0].defaultValue = id;
        console.log("loadResourcePanel DV", resourceTotalPanels);
        // loadResourcePanel && loadResourcePanel.forEach((item:any) => {
        //   item?.panels.forEach((screenPanel: IPanel) => {
        //   screenPanel?.fields?.forEach((field: any) => field.defaultValue = responseAssociationId.associationId)
        //   })
        //   })
        //   } catch(err){
        //     alert(err)
        //   }
        // })();
      }

      if (resourceName === "T5_Recipient" && selectedDataRecord && selectedDataRecord.metaFields) {
        let metaFields = selectedDataRecord.metaFields;
        let metaField: any = metaFields.find(
          (element:any) => element.name === 'recipientType'
        );
        if (metaField.name === "recipientType") {
          if(recipientTypeChangeOnEditMode === true){
            setRecipientType(parseInt(metaField.value));
          }
        }
      }else if (resourceName === "T3_Beneficiary" && selectedDataRecord && selectedDataRecord.metaFields) {
        let metaFields = selectedDataRecord.metaFields;
        let metaField: any = metaFields.find(
          (element:any) => element.name === 'recipientType'
        );
        if (metaField.name === "recipientType") {
          if(recipientTypeChangeOnEditMode === true){
            setRecipientType(parseInt(metaField.value));
          }
        }
      }

      if (
        resourceTotalPanels &&
        // Array.isArray(screenFields) &&
        selectedDataRecord &&
        instanceOfIResource(selectedDataRecord)
      ) {
        console.log("##### => populating the edit record");
        let metaFieldIdsObject: { [key: string]: string } = {};
        // screenFields.forEach((screenTab: ITabLayout, index: number) => {
        resourceTotalPanels &&
          resourceTotalPanels.forEach((item: any) => {
            item.panels.forEach((screenPanel: IPanel, index: number) => {
              screenPanel.fields.forEach((field: IField) => {
                let isAddressField = AddressFields.find(
                  (element) => element === field.fieldId
                );
                console.log(
                  "#####=>selectedDataRecord.hasOwnProperty(field.fieldId) - ",
                  field.fieldId,
                  " - isAddressField - ",
                  isAddressField,
                  selectedDataRecord.hasOwnProperty(field.fieldId)
                );

                if (
                  field.isFixed &&
                  selectedDataRecord.hasOwnProperty(field.fieldId) &&
                  !isAddressField
                ) {
                  type objectKey = keyof typeof selectedDataRecord;
                  let fieldValue = field.fieldId as objectKey;
                  field.defaultValue =
                    selectedDataRecord[fieldValue] !== null
                      ? String(selectedDataRecord[fieldValue])
                      : "";
                  if (field.fieldId === "uniqueIdentifier") {
                    field.defaultValue =
                      selectedDataRecord[fieldValue] !== null
                        ? String(selectedDataRecord[fieldValue]).replace(
                            /(\d{3})(\d{3})(\d{3})/,
                            "$1-$2-$3"
                          )
                        : "";
                  }
                  if (field.fieldId === "email") {
                    const maskedStatus: any =
                      sessionStorage.getItem("maskEnabled");
                    field.defaultValue =
                      selectedDataRecord[fieldValue] !== null
                        ? String(selectedDataRecord[fieldValue])
                        : selectedDataRecord[fieldValue] === null &&
                          maskedStatus === "true"
                        ? "ze*****3@gmail.com"
                        : selectedDataRecord[fieldValue];
                  }
                  console.log("field.defaultValue123", field.defaultValue);
                } else if (field.isFixed && isAddressField) {
                  let addressObject = selectedDataRecord.address[0];
                  type addressObjectKey = keyof typeof addressObject;
                  let addressFieldKey = field.fieldId as addressObjectKey;
                  field.defaultValue = addressObject[addressFieldKey];

                  if (
                    String(addressFieldKey) === "country" ||
                    String(addressFieldKey) === "countryId"
                  ) {
                    console.log("******", addressObject.country);
                    field.defaultValue = String(addressObject.country.id);
                  } else if (
                    String(addressFieldKey) === "province" ||
                    String(addressFieldKey) === "provinceId"
                  ) {
                    console.log("******", addressObject.province);
                    field.defaultValue = String(addressObject.province.id);
                  }
                } else if (!field.isFixed) {
                  console.log("meta field: ", field.fieldId);
                  let metaFields = selectedDataRecord.metaFields;
                  let metaField: any = metaFields.find(
                    (element) => element.name === field.fieldId
                  );
                  if (metaField) {
                    metaFieldIdsObject[field.fieldId] = metaField.id;
                    if (field.fieldId !== `load_${resourceName}`) {
                      field.defaultValue = metaField.value;
                    }
                    if (metaField.name === "reduced_ei") {
                      if (metaField.value === true) {
                        field.defaultValue = "True";
                      } else {
                        field.defaultValue = "False";
                      }
                    }
                    if (metaField.name === "payeeType" && field.fieldId === "payeeType") {
                      if(metaField.value === true){
                        setPayeeType('true');
                        field.defaultValue = 'true';
                      } else if(metaField.value === false){
                        setPayeeType('false');
                        field.defaultValue = 'false';
                      } else {
                        setPayeeType(metaField.value);
                        field.defaultValue = metaField.value;
                      }
                    }
                    if (metaField.name === "recipientType") {
                      field.defaultValue = parseInt(metaField.value);
                      // if(recipientTypeChangeOnEditMode === true){
                      //   setRecipientType(parseInt(metaField.value));
                      // }
                    }
                    if (metaField.name === "date_of_birth") {
                      const maskedStatus: any =
                        sessionStorage.getItem("maskEnabled");
                      console.log("metaField.value--", metaField.value);
                      if (maskedStatus === "true") {
                        field.defaultValue =
                          metaField.value !== "" ? metaField.value : "1****";
                        field.dataType = DataType.String;
                      } else {
                        field.defaultValue = metaField.value;
                      }
                    }
                  }
                } else {
                  console.log("field not found: ", field.fieldId);
                }
              });
              // Show form in edit mode when all data is loaded
              screenFields &&
                screenFields.forEach((item: any) => {
                  if (index === item.panels.length - 1) {
                    setRenderForm(true);
                  }
                });
            });
          });

        setMetaFieldIds(metaFieldIdsObject);
        console.log("metaFieldIdsObject", metaFieldIdsObject);
        console.log("screenFields123", screenFields);
      }
    } else {
      // Show form in add mode
      setRenderForm(true);
      //null all defualt values
      screenFields &&
        screenFields.forEach((item: any) => {
          item?.panels.forEach((screenPanel: IPanel) => {
            screenPanel?.fields.forEach((field: IField) => {
              field.defaultValue =
                field.fieldId === "statusId"
                  ? field.defaultValue
                  : field.fieldId === "countryId"
                  ? field.defaultValue
                  : field.fieldId === `load_${resourceName}`
                  ? field.defaultValue
                  : resourceName === "Employee" &&
                    field.fieldId === "employee_type"
                  ? field.defaultValue
                  : resourceName === "Employee" &&
                    field.fieldId === "pay_period"
                  ? field.defaultValue
                  : resourceName === "Employee" &&
                    field.fieldId === "reduced_ei"
                  ? field.defaultValue
                  : resourceName === "T5_Recipient" &&
                    field.fieldId === "recipientType"
                  ? field.defaultValue
                  : resourceName === "T3_Beneficiary" &&
                    field.fieldId === "recipientType"
                  ? field.defaultValue
                  : resourceName === "Vendor" && field.fieldId === "payeeType"
                  ? field.defaultValue
                  : null;
            });
          });
        });
    }

    if(!id && !selectedDataRecord && defaultSettingsData && defaultSettingsData.fillingDataAttribute && resourceName === "Employee"){
      console.log("fillingDataAttribute",defaultSettingsData.fillingDataAttribute)
      defaultSettingsCalls()
    }
  }, [
    id,
    screenFields,
    selectedDataRecord,
    resourceCategoryList,
    resourceTotalPanels,
    resourceName
  ]);

  // useEffect(()=>{
  //   if(id){
  //     loadResourcePanel && loadResourcePanel.forEach((item:any) => {
  //       item?.panels.forEach((screenPanel: IPanel) => {
  //       screenPanel?.fields?.forEach((field: any) => field.defaultValue = "4b965748-39e5-43b5-8dae-a194b13d43d3")
  //       })
  //     })
  //     console.log("loadResourcePanel123",loadResourcePanel)
  //   }
  // },[id,loadResourcePanel])
  // if (id && loadResourcePanel) {
  //   loadResourcePanel.forEach(item => {
  //     item.panels.forEach(screenPanel => {
  //       screenPanel.fields.forEach(field => {
  //         if (field.fieldId === `load_${resourceName}`) {
  //           field.defaultValue = "4b965748-39e5-43b5-8dae-a194b13d43d3";
  //         }
  //       });
  //     });
  //   });
  // }
  // console.log("loadResourcePanel DV2", loadResourcePanel);
  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const onSubmitAllTabs = (formData: any) => {
    console.log("formData == onSubmitAllTabs ", formData);
  };
  const initialValues = {
    name: "",
    age: "",
    Address: "",
  };

  useEffect(() => {
    if (
      resourceCategoryList &&
      resourceCategoryList.items &&
      resourceCategoryList.items.length === 1 &&
      resourceCategoryList.items[0].defualtCategory === true
    ) {
      console.log("yeesss");
    } else if (resourceCategoryList && resourceCategoryList.items) {
      console.log("nooooooo1");
      const categoryWithOnChange = resourceCategoryList.items.map(
        (item: any) => {
          let updatedCategories: any = [];
          // let selectedCategory: any = [];
          console.log("item--", item);
          // for (let i = 0; i < resourceCategoryList.items.length; i++) {
          if (item.fieldType === "DropDownList") {
            if (item.defualtCategory === true) {
              let fieldData = {
                screenId: screenId,
                categoryFieldId: item.id,
                categoryFieldValue: item.fieldInternalValue,
              };
              setSelectedCategoriesData((prevData: any) => [
                ...prevData,
                fieldData,
              ]);
              updatedCategories.push(item);
              item.isHide = true;
              item.readOnly = true;
              item.isRequired = false;
              // item.fieldLabel=''
            } else {
              const onDropDownChanged = (value: any) => {
                console.log("selectedCategoriesData--", selectedCategoriesData);
                let fieldData = {
                  screenId: screenId,
                  categoryFieldId: item.id,
                  categoryFieldValue: value.value,
                };
                // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                setSelectedCategoriesData((prevData: any[]) => {
                  // Check if an object with the same categoryFieldId exists
                  const existingIndex = prevData.findIndex(
                    (data) => data.categoryFieldId === fieldData.categoryFieldId
                  );

                  if (existingIndex !== -1) {
                    // If exists, remove it
                    const newData = prevData.filter(
                      (data, index) => index !== existingIndex
                    );
                    // Add the new object
                    return [...newData, fieldData];
                  } else {
                    // If not exists, just add the new object
                    return [...prevData, fieldData];
                  }
                });
              };
              item.onChange = onDropDownChanged;
              updatedCategories.push(item);
              console.log("item456", item);
            }
          } else if (item.fieldType === "NormalSwitch") {
            if (item.defualtCategory === true) {
              let fieldData = {
                screenId: screenId,
                categoryFieldId: item.id,
                categoryFieldValue: item.fieldInternalValue,
              };
              setSelectedCategoriesData((prevData: any) => [
                ...prevData,
                fieldData,
              ]);
              updatedCategories.push(item);
              item.isHide = true;
              item.readOnly = true;
              item.isRequired = false;
              // item.fieldLabel=''
            } else {
              const onSwitchChanged = (value: any) => {
                let fieldData = {
                  screenId: screenId,
                  categoryFieldId: item.id,
                  categoryFieldValue: value,
                };
                // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                setSelectedCategoriesData((prevData: any[]) => {
                  // Check if an object with the same categoryFieldId exists
                  const existingIndex = prevData.findIndex(
                    (data) => data.categoryFieldId === fieldData.categoryFieldId
                  );

                  if (existingIndex !== -1) {
                    // If exists, remove it
                    const newData = prevData.filter(
                      (data, index) => index !== existingIndex
                    );
                    // Add the new object
                    return [...newData, fieldData];
                  } else {
                    // If not exists, just add the new object
                    return [...prevData, fieldData];
                  }
                });
              };
              item.onChange = onSwitchChanged;
              updatedCategories.push(item);
            }
          } else if (item.fieldType === "MultiSelectionDropDownList") {
            if (item.defualtCategory === true) {
              let fieldData = {
                screenId: screenId,
                categoryFieldId: item.id,
                categoryFieldValue: item.fieldInternalValue,
              };
              setSelectedCategoriesData((prevData: any) => [
                ...prevData,
                fieldData,
              ]);
              updatedCategories.push(item);
              item.isHide = true;
              item.readOnly = true;
              item.isRequired = false;
              // item.fieldLabel=''
            } else {
              const onMultiSelectionDropDownValueChanged = (value: any) => {
                let selectedItems: any = [];
                value.map((item: any) => {
                  selectedItems.push(item.value);
                });
                let fieldData = {
                  screenId: screenId,
                  categoryFieldId: item.id,
                  categoryFieldValue: selectedItems,
                };
                // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                setSelectedCategoriesData((prevData: any[]) => {
                  // Check if an object with the same categoryFieldId exists
                  const existingIndex = prevData.findIndex(
                    (data) => data.categoryFieldId === fieldData.categoryFieldId
                  );

                  if (existingIndex !== -1) {
                    // If exists, remove it
                    const newData = prevData.filter(
                      (data, index) => index !== existingIndex
                    );
                    // Add the new object
                    return [...newData, fieldData];
                  } else {
                    // If not exists, just add the new object
                    return [...prevData, fieldData];
                  }
                });
              };
              item.onChange = onMultiSelectionDropDownValueChanged;
              updatedCategories.push(item);
            }
          } else if (item.fieldType === "Checkbox") {
            if (item.defualtCategory === true) {
              let fieldData = {
                screenId: screenId,
                categoryFieldId: item.id,
                categoryFieldValue: item.fieldInternalValue,
              };
              setSelectedCategoriesData((prevData: any) => [
                ...prevData,
                fieldData,
              ]);
              updatedCategories.push(item);
              item.isHide = true;
              item.readOnly = true;
              item.isRequired = false;
              // item.fieldLabel=''
            } else {
              const onCheckBoxValueChanged = (value: any) => {
                let fieldData = {
                  screenId: screenId,
                  categoryFieldId: item.id,
                  categoryFieldValue: value,
                };
                // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                setSelectedCategoriesData((prevData: any[]) => {
                  // Check if an object with the same categoryFieldId exists
                  const existingIndex = prevData.findIndex(
                    (data) => data.categoryFieldId === fieldData.categoryFieldId
                  );

                  if (existingIndex !== -1) {
                    // If exists, remove it
                    const newData = prevData.filter(
                      (data, index) => index !== existingIndex
                    );
                    // Add the new object
                    return [...newData, fieldData];
                  } else {
                    // If not exists, just add the new object
                    return [...prevData, fieldData];
                  }
                });
              };
              item.onChange = onCheckBoxValueChanged;
              updatedCategories.push(item);
            }
          } else if (item.fieldType === "RadioGroup") {
            if (item.defualtCategory === true) {
              let fieldData = {
                screenId: screenId,
                categoryFieldId: item.id,
                categoryFieldValue: item.fieldInternalValue,
              };
              setSelectedCategoriesData((prevData: any) => [
                ...prevData,
                fieldData,
              ]);
              updatedCategories.push(item);
              item.isHide = true;
              item.readOnly = true;
              item.isRequired = false;
              // item.fieldLabel=''
            } else {
              const onRadioButtonValueChanged = (e: any) => {
                let fieldData = {
                  screenId: screenId,
                  categoryFieldId: item.id,
                  categoryFieldValue: e.target.value,
                };
                // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
                setSelectedCategoriesData((prevData: any[]) => {
                  // Check if an object with the same categoryFieldId exists
                  const existingIndex = prevData.findIndex(
                    (data) => data.categoryFieldId === fieldData.categoryFieldId
                  );

                  if (existingIndex !== -1) {
                    // If exists, remove it
                    const newData = prevData.filter(
                      (data, index) => index !== existingIndex
                    );
                    // Add the new object
                    return [...newData, fieldData];
                  } else {
                    // If not exists, just add the new object
                    return [...prevData, fieldData];
                  }
                });
              };
              item.onChange = onRadioButtonValueChanged;
              updatedCategories.push(item);
            }
          } else {
            if (item.defualtCategory === true) {
              let fieldData = {
                screenId: screenId,
                categoryFieldId: item.id,
                categoryFieldValue: item.fieldInternalValue,
              };
              setSelectedCategoriesData((prevData: any) => [
                ...prevData,
                fieldData,
              ]);
              updatedCategories.push(item);
              item.isHide = true;
              item.readOnly = true;
              item.isRequired = false;
              // item.fieldLabel=''
            } else {
              let fieldData = {
                screenId: screenId,
                categoryFieldId: item.id,
                categoryFieldValue: item.fieldInternalValue,
              };
              // setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
              setSelectedCategoriesData((prevData: any[]) => {
                // Check if an object with the same categoryFieldId exists
                const existingIndex = prevData.findIndex(
                  (data) => data.categoryFieldId === fieldData.categoryFieldId
                );

                if (existingIndex !== -1) {
                  // If exists, remove it
                  const newData = prevData.filter(
                    (data, index) => index !== existingIndex
                  );
                  // Add the new object
                  return [...newData, fieldData];
                } else {
                  // If not exists, just add the new object
                  return [...prevData, fieldData];
                }
              });
              updatedCategories.push(item);
            }
          }
          console.log("//dd", selectedCategoriesData);
          console.log("updatedCategories", updatedCategories);
          return item;
          // }
        }
      );
      console.log("categoryWithOnChange", categoryWithOnChange);
      setCategoryFieldsWithOnChangeMethod(categoryWithOnChange);
    }
  }, [resourceCategoryList]);

  const { isLoading: isDeleting, mutate: deleteResourceById } = useMutation(
    ResourceService.delResource,
    {
      onSuccess: (res: string | IServerError | IResponse | any) => {
        if (res === "Resource has been deleted successfully") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: "Record has been deleted successfully.",
            severity: "success",
          });
          let path = `/resources/${resourceName}/list`;
          navigate(path);
        } else {
          setSnackbarData({
            open: true,
            message: res && res.message ? res.message : "Something went wrong!",
            severity: res && res.status === false ? "error" : "success",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const deleteResourceField = (e: string) => {
    if (singleDelete) {
      setSingleDelete(false);
      let payload = {
        resourceId: resourceId,
        roleName:resourceName,
        clientId: id,
        taxYear: taxYear,
        option: e,
      };
      deleteResourceById(payload);
      setDialogOpen(false);
    }
  };

  const onDeleteClicked = () => {
    setDialogOpen(true);
    setSingleDelete(true);
    if (selectedDataRecord) {
      setResourceId(selectedDataRecord.id);
    }
  };

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    // formMethods.reset();
    // let path = `/accountants/setup`;
    // navigate(path);
  };

  // useEffect(()=>{
  //   if(!resourceCategoryAssingedPanels && resourceName === "Employee"){
  //     (async () => {
  //       try {
  //         setIsLoadingData(true)
  //         const response: any = await T4SlipService.getEmployeeDDL(clientId, EMPLOYEE_RESOURCE_LITERAL);
  //         console.log("responseData-----------------", response);

  //         // Extract labels and values from data
  //         let labels = response && response.map((item:any) => item.label);
  //         let values = response && response.map((item:any) => item.value);

  //         // Join labels and values into a single string
  //         // Join labels and values into a single string
  //         let fieldValue = response && response.length > 0 ? `Create New,${labels.join(',')}` : "Create New";
  //         let fieldInternalValue = response && response.length > 0 ? `create-new,${values.join(',')}` : "create-new";

  //         console.log("fieldValue12",fieldValue)
  //         console.log("fieldInternalValue12",fieldInternalValue)

  //         const finalLoadResourcePanel = {
  //           ...loadResourcePanel,
  //           fields: loadResourcePanel.fields.map((field:any) => ({
  //             ...field,
  //             fieldValue: fieldValue,
  //             fieldInternalValue: fieldInternalValue,
  //           })),
  //         };

  //         console.log("finalLoadResourcePanel",finalLoadResourcePanel)
  //           setSelectedResourceList(finalLoadResourcePanel);
  //       } catch (error) {
  //         setIsLoadingData(false)
  //         console.error("Error fetching data:", error);
  //       } finally {
  //         setIsLoadingData(false)
  //       }
  //     })();
  //   }
  // },[])
  useEffect(() => {
    // if(!resourceCategoryAssingedPanels && resourceName === "Vendor"){
    // if(!resourceCategoryAssingedPanels){
    if (id) {
      // (async () => {
      //   try {
      //     const responseAssociationId = await T4SlipService.getRoleAssociationId(
      //       id
      //     );
      (async () => {
        try {
          setIsLoadingData(true);
          const response: any = await T4SlipService.getEmployeeDDL(
            clientId,
            resourceName === "Employee"
              ? EMPLOYEE_RESOURCE_LITERAL
              : resourceName === "Vendor"
              ? VENDOR_RESOURCE_LITERAL
              : resourceName === "T5_Recipient" ? T5_RECIPIENT_RESOURCE_LITERAL
              : 'T3_Beneficiary',
            taxYear
          );
          console.log("responseData-----------------", response);

          // let labels = response && response.map((item: any) => item.label);
          // let values = response && response.map((item: any) => item.value);

          // let fieldValue =
          //   response && response.length > 0
          //     ? `Create New,${labels.join(",")}`
          //     : "Create New";
          // let fieldInternalValue =
          //   response && response.length > 0
          //     ? `create-new,${values.join(",")}`
          //     : "create-new";

          // console.log("fieldValue12", fieldValue);
          // console.log("fieldInternalValue12", fieldInternalValue);

          // let defaultValue = id;
          // const finalLoadResourcePanel = loadResourcePanel.map((panel) => ({
          //   ...panel,
          //   panels: panel.panels.map((panel) => ({
          //     ...panel,
          //     fields: panel.fields.map((field) => ({
          //       ...field,
          //       defaultValue: defaultValue, // Update defaultValue
          //       fieldValue: fieldValue, // Update fieldValue
          //       fieldInternalValue: fieldInternalValue, // Update fieldInternalValue
          //     })),
          //   })),
          // }));

          // console.log("finalLoadResourcePanel12", finalLoadResourcePanel[0]);
          // setSelectedResourceList(finalLoadResourcePanel[0]);
          setResourceListWithoutCreateNew(response);
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
      })();
      //   } catch(err){
      //     alert(err)
      //   }
      // })();
    } else {
      (async () => {
        try {
          setIsLoadingData(true);
          const response: any = await T4SlipService.getEmployeeDDL(
            clientId,
            resourceName === "Employee"
              ? EMPLOYEE_RESOURCE_LITERAL
              : resourceName === "Vendor"
              ? VENDOR_RESOURCE_LITERAL
              : resourceName === "T5_Recipient" ? T5_RECIPIENT_RESOURCE_LITERAL
              : 'T3_Beneficiary',
            taxYear
          );
          console.log("responseData-----------------", response);

          // let labels = response && response.map((item: any) => item.label);
          // let values = response && response.map((item: any) => item.value);

          // let fieldValue =
          //   response && response.length > 0
          //     ? `Create New,${labels.join(",")}`
          //     : "Create New";
          // let fieldInternalValue =
          //   response && response.length > 0
          //     ? `create-new,${values.join(",")}`
          //     : "create-new";

          // console.log("fieldValue12", fieldValue);
          // console.log("fieldInternalValue12", fieldInternalValue);
          // const finalLoadResourcePanel = loadResourcePanel.map((panel) => ({
          //   ...panel,
          //   panels: panel.panels.map((panel) => ({
          //     ...panel,
          //     fields: panel.fields.map((field) => ({
          //       ...field,
          //       fieldValue: fieldValue, // Update fieldValue
          //       fieldInternalValue: fieldInternalValue, // Update fieldInternalValue
          //     })),
          //   })),
          // }));

          // console.log("finalLoadResourcePanel12", finalLoadResourcePanel[0]);
          // setSelectedResourceList(finalLoadResourcePanel[0]);
          setResourceListWithoutCreateNew(response);
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
      })();
    }
    // }
  }, [id]);

  useEffect(() => {
    let totalPanels: any = [];
    if (
      resourceCategoryAssingedPanels &&
      resourceCategoryAssingedPanels.length > 0
    ) {
      if (resourceName === "Employee") {
        // employeeScreenFields?.panels.map((item: any) => {
        //   totalPanels.push(item);
        // });
        employeeScreenFields &&
          employeeScreenFields.map((item: any) => {
            // totalPanels.unshift(selectedResourceList && selectedResourceList);
            totalPanels.push(item);
          });
      } else if (resourceName === "Vendor") {
        vendorScreenFields &&
          vendorScreenFields.map((item: any) => {
            // totalPanels.unshift(selectedResourceList && selectedResourceList);
            totalPanels.push(item);
          });
      } else if (resourceName === "T5_Recipient") {
        t5RecipientScreenFields &&
          t5RecipientScreenFields.map((item: any) => {
            // totalPanels.unshift(selectedResourceList && selectedResourceList);
            totalPanels.push(item);
          });
      } else if (resourceName === "T3_Beneficiary") {
        t3BeneficiaryScreenFields &&
        t3BeneficiaryScreenFields.map((item: any) => {
            // totalPanels.unshift(selectedResourceList && selectedResourceList);
            totalPanels.push(item);
          });
      }
      // resourceCategoryAssingedPanels?.panels.map((item: any) => {
      //   totalPanels.push(item);
      // });
      const makeAllFieldsToMetaFields =
        resourceCategoryAssingedPanels &&
        resourceCategoryAssingedPanels.map((item: any) => {
          return {
            ...item,
            panels: item.panels.map((panel: any) => {
              return {
                ...panel,
                fields: panel.fields.map((field: any) => ({
                  ...field,
                  fieldId: field.appFieldLabel,
                  isFixed: false, //now all fields is meta field
                })),
              };
            }),
          };
        });
      //make all field to isFixed false to make it meta field
      makeAllFieldsToMetaFields.map((item: any) => {
        totalPanels.push(item);
      });
      // totalPanels.push(resourceCategoryAssingedPanels.panels)
      if (totalPanels && totalPanels.length !== 0) {
        // let resourceTolalPanel = {
        //   panels: totalPanels,
        // };
        setResourceTotalPanels(totalPanels);
      }
    } else {
      if (resourceName === "Employee") {
        // totalPanels.unshift(selectedResourceList && selectedResourceList);
        // console.log("selectedResourceList12", selectedResourceList);
        // employeeScreenFields?.panels.map((item: any) => {
        //   console.log("itemitem",item)
        //   totalPanels.push(item);
        //   if (selectedResourceList && totalPanels && totalPanels.length !== 0) {
        //     let resourceTolalPanel = {
        //       panels: totalPanels,
        //     };
        //     setResourceTotalPanels(resourceTolalPanel);
        //   }
        // });
        employeeScreenFields &&
          employeeScreenFields.map((item: any) => {
            console.log("itemitem", item);
            totalPanels.push(item);
            if (
              // selectedResourceList &&
              totalPanels &&
              totalPanels.length !== 0
            ) {
              // let resourceTolalPanel = {
              //   panels: totalPanels,
              // };
              setResourceTotalPanels(totalPanels);
            }
          });
      } else if (resourceName === "Vendor") {
        // totalPanels.unshift(selectedResourceList && selectedResourceList);
        vendorScreenFields &&
          vendorScreenFields.map((item: any) => {
            totalPanels.push(item);
            if (
              // selectedResourceList &&
              totalPanels &&
              totalPanels.length !== 0
            ) {
              // let resourceTolalPanel = {
              //   panels: totalPanels,
              // };
              setResourceTotalPanels(totalPanels);
            }
          });
      } else if (resourceName === "T5_Recipient") {
        // totalPanels.unshift(selectedResourceList && selectedResourceList);
        t5RecipientScreenFields &&
          t5RecipientScreenFields.map((item: any) => {
            totalPanels.push(item);
            if (
              // selectedResourceList &&
              totalPanels &&
              totalPanels.length !== 0
            ) {
              // let resourceTolalPanel = {
              //   panels: totalPanels,
              // };
              setResourceTotalPanels(totalPanels);
            }
          });
      } else if (resourceName === "T3_Beneficiary") {
        // totalPanels.unshift(selectedResourceList && selectedResourceList);
        t3BeneficiaryScreenFields &&
        t3BeneficiaryScreenFields.map((item: any) => {
            totalPanels.push(item);
            if (
              // selectedResourceList &&
              totalPanels &&
              totalPanels.length !== 0
            ) {
              // let resourceTolalPanel = {
              //   panels: totalPanels,
              // };
              setResourceTotalPanels(totalPanels);
            }
          });
      }
      // totalPanels.push(screenFields?.panels);
      console.log("insideelse");
    }
    console.log("totalPanels", totalPanels);
  }, [
    vendorScreenFields,
    employeeScreenFields,
    t5RecipientScreenFields,
    screenFields,
    resourceCategoryAssingedPanels,
    // selectedResourceList,
  ]);
  // console.log("totalPanels--",totalPanels)
  console.log("resourceTotalPanels--", resourceTotalPanels);
  console.log("selectedCategoriesData", selectedCategoriesData);
  console.log("resourceCategoryList", resourceCategoryList);
  console.log("resourceCategoryAssingedPanels", resourceCategoryAssingedPanels);
  console.log("resourceName", resourceName);
  console.log("vendorScreenFields", vendorScreenFields);
  console.log("t5RecipientScreenFields-", t5RecipientScreenFields);
  // console.log("selectedResourceList--", selectedResourceList);
  console.log("renderForm--", renderForm);
  console.log("screenFields--", screenFields);
  console.log("resourceTotalPanels--", resourceTotalPanels);

  useEffect(() => {
    if (
      resourceCategoryList &&
      resourceCategoryList.items &&
      resourceCategoryList.items.length === 1 &&
      resourceCategoryList.items[0].defualtCategory === true
    ) {
      const fieldData = {
        screenId: screenId,
        categoryFieldId: resourceCategoryList.items[0].id,
        categoryFieldValue: resourceCategoryList.items[0].fieldInternalValue,
      };

      console.log("heyyyyy");
      setSelectedCategoriesData((prevData: any) => [...prevData, fieldData]);
    }
  }, [resourceCategoryList]);

  useEffect(() => {
    if (screenFields) {
      if (resourceName === "Vendor") {
        console.log("item::--", screenFields);
        screenFields &&
          screenFields[0]?.panels[0]?.fields?.map((item: any) => {
            if (item.fieldId === "payeeType") {
              const onPayeeTypeChanged = (value: any) => {
                setPayeeType(value.value);
                console.log("payeeType--", payeeType);
                if (value.value === payeeType) {
                  console.log("value.value", value.value);
                } else {
                  setScreenFields(undefined);
                  setResourceTotalPanels(undefined);
                  console.log("value.valueelse", value.value);
                }
              };
              item.onChange = onPayeeTypeChanged;
            }
            console.log("item::", item);
          });
        console.log("updatedScreenF", screenFields);
      } else if (resourceName === "T5_Recipient") {
        screenFields &&
          screenFields[0]?.panels[0]?.fields?.map((item: any) => {
            if (item.fieldId === "recipientType") {
              const onRecipientTypeChanged = (value: any) => {
                setRecipientTypeChangeOnEditMode(false)
                setRecipientType(value.value);
                console.log("recipientType--", recipientType);
                if (value.value === recipientType) {
                  console.log("value.value", value.value);
                } else {
                  setScreenFields(undefined);
                  setResourceTotalPanels(undefined);
                  console.log("value.valueelse", value.value);
                }
              };
              item.onChange = onRecipientTypeChanged;
            }
            console.log("item::", item);
          });
      } else if (resourceName === "T3_Beneficiary") {
        screenFields &&
          screenFields[0]?.panels[0]?.fields?.map((item: any) => {
            if (item.fieldId === "recipientType") {
              const onRecipientTypeChanged = (value: any) => {
                setRecipientTypeChangeOnEditMode(false)
                setRecipientType(value.value);
                console.log("recipientType--", recipientType);
                if (value.value === recipientType) {
                  console.log("value.value", value.value);
                } else {
                  setScreenFields(undefined);
                  setResourceTotalPanels(undefined);
                  console.log("value.valueelse", value.value);
                }
              };
              item.onChange = onRecipientTypeChanged;
            }
            console.log("item::", item);
          });
      }
    }
  }, [screenFields]);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: id ? id : "",
        entityId2: "",
        entityId3: "",
        screenName:
          resourceName === "Employee"
            ? EMPLOYEE_SETUP_SCREEN_SLUG
            : resourceName === "Vendor"
            ? VENDOR_SETUP_SCREEN_SLUG
            : resourceName === "T5_Recipient"
            ? T5_RECIPIENT_SETUP_SCREEN_SLUG
            : resourceName === "T3_Beneficiary"
            ? T3_BENEFICIARY_SETUP_SCREEN_SLUG
            : 0,
        companyName: clientName,
        activityType: "activity_type=create&activity_type=updated&activity_type=delete",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, [resourceName]);

  const defaultValuesOfResourceForm = useCallback(() => {
    const fieldsDefaultValues: any = {};
    resourceTotalPanels &&
      resourceTotalPanels.forEach((tabLayout: any) => {
        tabLayout?.panels.forEach((panel: any) => {
          panel?.fields.forEach((panelField: any) => {
            const FieldComponent: any =
              FIELD_TYPE_COMPONENT[panelField.fieldType];
            if (FieldComponent) {
              const { fieldId, id, isFixed, defaultValue, fieldType } =
                panelField;
              const fieldName = getFieldName(fieldId, id, isFixed);
              let fieldDefaultValue = panelField.defaultValue;
              console.log("formLayout indif", panelField);
              console.log("panelField.defaultValue", panelField.defaultValue);
              console.log(
                `Cosmic FlexFieldsFormPanelLayout => Default Values Setting, fieldName: ${fieldName}, Default Value: ${fieldDefaultValue}, Field Type: ${panelField.fieldType}`
              );
              if (fieldType === FieldType.MultiSelectionDropDownList) {
                if (defaultValue) {
                  fieldDefaultValue = getMultiSelectionDDLDefaultValue(
                    defaultValue as string
                  );
                }
              }
              // if the field has a default value
              if (fieldDefaultValue) {
                fieldsDefaultValues[fieldName] = fieldDefaultValue;
              } 
              // else if (fieldDefaultValue === null) {
              //   fieldsDefaultValues[fieldName] = fieldDefaultValue;
              // }
            }
          });
        });
      });
    return fieldsDefaultValues;
  }, [resourceTotalPanels, getFieldName]);

  const defaultValuesOfResourceCategpryForm = useCallback(() => {
    const fieldsDefaultValues: any = {};
    resourceTotalCategories &&
      resourceTotalCategories?.items.forEach((panelField: any) => {
        const FieldComponent: any = FIELD_TYPE_COMPONENT[panelField.fieldType];
        if (FieldComponent) {
          const { fieldId, id, isFixed, defaultValue, fieldType } = panelField;
          const fieldName = getFieldName(fieldId, id, isFixed);
          let fieldDefaultValue = defaultValue;
          console.log(
            `Cosmic CategoryFieldsPanelLayout => Default Values Setting, fieldName: ${fieldName}, Default Value: ${fieldDefaultValue}, Field Type: ${fieldType}`
          );
          if (fieldType === FieldType.MultiSelectionDropDownList) {
            if (defaultValue) {
              fieldDefaultValue = getMultiSelectionDDLDefaultValue(
                defaultValue as string
              );
            }
          }
          // if the field has a default value
          if (fieldDefaultValue) {
            fieldsDefaultValues[fieldName] = fieldDefaultValue;
          }
        }
      });
    return fieldsDefaultValues;
  }, [resourceTotalCategories, getFieldName]);

  //after creating resource this message would be shown
  useEffect(() => {
    if (
      showCreatedResourceMessage === true &&
      resourceApiMessage &&
      resourceApiMessage.open === true
    ) {
      setSnackbarData({
        open: resourceApiMessage.open,
        message: resourceApiMessage.message,
        severity: resourceApiMessage.severity,
      });
    }
  }, [resourceApiMessage]);

  //cards
  const getCardStyles = (index: any) => {
    console.log("index--", index);
    return {
      borderLeft:
        selectedCard === index ? "6px solid #0057db" : "2px solid transparent",
      cursor: "pointer",
    };
  };
  // const getSelectedResourceSlipInCard = (index: any) => {
  //   let selectedItem =
  //     selectedCard === index ? selectedResourceSlip : "create-new";
  //   return selectedItem;
  // };
  // const getSelectedResourceSlipsListInCard = (index: any) => {
  //   let selectedList =
  //     selectedCard === index
  //       ? selectedResourceSlipList
  //       : [{ label: "Create New", value: "create-new" }];
  //   return selectedList;
  // };

  const filteredResourceList =
    resourceListWithoutCreateNew &&
    resourceListWithoutCreateNew.filter((item: any) =>
      item.label.toLowerCase().includes(itemSearch.toLowerCase())
    );

  const onCreateNewButtonClicked = () => {
    dispatch(clearSelectedResourceApiMessageActions());
    let path = `/resources/${resourceName}/setup`;
    navigate(path);
    window.location.reload();
    setSelectedCard(null);
  };
  const onDividerButtonClickHandler = () => {
    setSidePanelsVisibility((prevButtonState: any) => !prevButtonState);
  };
useEffect(()=>{
  if(flexFieldPanelHeightRef.current){
    const heightInPx = flexFieldPanelHeightRef.current.getBoundingClientRect().height;
    console.log('Height in pixels:', heightInPx);
      const baseFontSizeInPx = 16; // Default base font size
      console.log('Base font size:', baseFontSizeInPx);
      const heightInRem = `${heightInPx / baseFontSizeInPx}rem`;
      console.log('Height in rem:', heightInRem);
      let height = parseInt(heightInRem) - 5
      console.log("flexFieldPanelHeightRef",`${parseFloat(heightInRem).toFixed(2)}rem`)
      console.log("heightInRem",heightInRem)
      console.log("heightInRem - 5",height)
    setFlexFieldPanelHeight(`${height}rem`)
  }
},[flexFieldPanelHeightRef])

  return (
    <Grid  
    container
    xs={12}
    sm={12}
    md={12}
    lg={12}
    xl={12}
    sx={{
      // pb: "5rem",
      "& .MuiAppBar-root": { zIndex: 2, top: "65px" },
      paddingTop: "2rem",
    }}>
      <BackdropCircularProgress
        open={
          // isSubmitting ||
          isLoadingById ||
          isDeleting ||
          isLoadingData ||
          isLoadingResourceCategories ||
          isLoadingResourceCategoriesPanels ||
          isLoadingVendorScreenLayout ||
          isLoadingEmployeeScreenLayout ||
          isLoadingT5RecipientScreenLayout ||
          isLoadingT3BeneficiaryScreenLayout ||
          isLoadingClientProfileDataById ||
          isLoadingActivityLogData || 
          isLoadingDefaultSettingsData
        }
        testId="accountant Setup"
      />
      {/* <DeleteConfirmationDialog
        open={openDialog}
        deleteActionHandler={deleteResourceField}
        cancelActionHandler={onCloseHandler}
        testId="accountant-confirmation-dialog"
      /> */}
      <Grid  
    item
    xs={12}
    sm={12}
    md={12}
    lg={12}
    xl={12}sx={{
      paddingBottom: "2rem",
    }}>
      {resourceTotalCategories &&
      resourceTotalCategories?.items &&
      resourceTotalCategories.items.length === 1 &&
      resourceTotalCategories.items[0].defualtCategory === true ? (
        <></>
      ) : resourceTotalCategories &&
        resourceTotalCategories.items &&
        resourceTotalCategories.items.length > 0 ? (
        <CategoryFieldsPanelLayout
          categoryFields={
            resourceTotalCategories?.items &&
            categoryFieldsWithOnChangeMethod !== undefined
              ? categoryFieldsWithOnChangeMethod
              : []
          }
          panelLabel={`${resourceName} Categories`}
          formDefaultValues={defaultValuesOfResourceCategpryForm()}
          testId={"categoryFields-panel"}
        />
      ) : (
        <></>
      )}</Grid>
      {sidePanelsVisibility && (
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Box sx={{ margin: "0" }}>
                <InputText
                  value={itemSearch}
                  onChange={(e) => setItemSearch(e.target.value)}
                  placeholder={"Search by name..."}
                  className="w-full search-field"
                />
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    sx={{ right: 0, margin: "10px 0px", fontSize: "12px" }}
                    onClick={onCreateNewButtonClicked}
                  >
                    + Create New
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  // display: "flex",
                  height: `${
                    resourceCategoryAssingedPanels && flexFieldPanelHeight ? flexFieldPanelHeight : 
                    resourceName === 'Employee' ? '59.2rem' : 
                    resourceName === 'Vendor' ? '37rem' : 
                    resourceName === 'T5_Recipient' ? '37rem' : 
                    resourceName === 'T3_Beneficiary' ? '37rem' : 
                    'auto'}`,
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#ccc",
                    borderRadius: "3px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#aaa",
                  },
                }}
              >
                {resourceListWithoutCreateNew &&
                  filteredResourceList.map((item: any, index: any) => {
                    return (
                      <Card
                        sx={{
                          padding: 0,
                          borderRadius: "8px",
                          marginBottom: "1rem",
                        }}
                        key={index}
                        onClick={() =>
                          onResourceSelectionChangeHandler(item, item.value)
                        }
                        style={getCardStyles(item.value)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0.8rem",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: ".875rem" }}
                          >
                            {item.label}
                          </Typography>
                          <Avatar
                            shape="circle"
                            style={{
                              background: "#0057db",
                              color: "#fff",
                              borderRadius: "50%",
                              fontWeight: "bold",
                              fontSize: ".775rem",
                              padding: "2px",
                            }}
                          >
                            {item.label
                              .replace(/\s+/g, " ")
                              .split(" ")
                              .filter((word: string) => word.trim() !== "")
                              .map((word: string) => word[0].toUpperCase())
                              .slice(0, 2)
                              .join("")}
                          </Avatar>
                        </Box>
                      </Card>
                    );
                  })}
              </Box>
            </Grid>
          )}
          <Divider
            layout="vertical"
            align="center"
            className="verticle-divider"
            style={{ zIndex: 0 }}
          >
            <Button
              variant="outlined"
              sx={{
                minWidth: "fit-content",
                padding: "20px 0",
                borderRadius: "7px",
              }}
              onClick={onDividerButtonClickHandler}
            >
              {sidePanelsVisibility ? (
                <i
                  className="pi pi-angle-double-left"
                  style={{ color: "#0057db" }}
                ></i>
              ) : (
                <i
                  className="pi pi-angle-double-right"
                  style={{ color: "#0057db" }}
                ></i>
              )}
            </Button>
          </Divider>
          <Grid
            item
            xs={sidePanelsVisibility ? 8 : 10}
            sm={sidePanelsVisibility ? 8 : 10.8}
            md={sidePanelsVisibility ? 9.4 : 11.2}
            lg={sidePanelsVisibility ? 9.5 : 11.6}
            xl={sidePanelsVisibility ? 9.7 : 11.7}
            sx={{ "& .MuiGrid-container": { marginTop: 0 },"& .MuiGrid-root": {paddingTop:0} }}
            ref={flexFieldPanelHeightRef}
          >
      <SetupFormLayout
        isLoadingData={
          isLoadingEmployeeScreenLayout ||
          isLoadingVendorScreenLayout ||
          isLoadingById
        }
        renderForm={renderForm}
        isSubmittingForm={false}
        forMultipleColumn={true}
      >
        {/* <Card
              >
        <ReourceSetupToolbar
            onResetClicked={onResetClicked}
            onDeleteClicked={onDeleteClicked}
            isSubmitting={isSubmitting}
            resourceDataById={selectedDataRecord}
          /></Card> */}
        <FlexFieldsFormPanelLayout
          toolbar={
            <ReourceSetupToolbar
              onResetClicked={onResetClicked}
              onDeleteClicked={onDeleteClicked}
              isSubmitting={isSubmitting}
              resourceDataById={selectedDataRecord}
            />
          }
          onSubmit={onSubmit}
          formLayout={
            screenFields && resourceTotalPanels && resourceTotalPanels
          }
          formDefaultValues={defaultValuesOfResourceForm()}
          testId={"clientCategoryAssingedPanels"}
          validationSchema={resourceJSONdataValidation}
        />
        {/* Single Delete Options to delte by year or permanenet */}
        <EnhancedDialog
          dialogTitle="Please Confirm"
          key="dialog-box"
          testId="dialog"
          onCloseHandler={onCloseHandler}
          open={openDialog}
          dialogContent={
            <Typography>Are you sure, you want to delete?</Typography>
          }
          dialogAction={
            <Grid>
              <Button
                autoFocus
                variant="contained"
                onClick={onCloseHandler}
                sx={{
                  background: "#fff",
                  color: "#000",
                  marginLeft: "16px",
                  "&.MuiButton-root:hover": { background: "#fff" },
                  fontSize: "small",
                }}
              >
                Cancel
              </Button>
              <Button
                autoFocus
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => deleteResourceField("Delete By Year")}
                color="error"
                sx={{ marginLeft: "16px", fontSize: "small" }}
              >
                Delete By Year
              </Button>
              <Button
                autoFocus
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => deleteResourceField("Permanent Delete")}
                color="error"
                sx={{ marginLeft: "16px", fontSize: "small" }}
              >
                Permanenet Delete
              </Button>
            </Grid>
          }
        />
        <EnhancedSnackbar
          message={snackbarData.message}
          onCloseHandler={onSnackbarClosed}
          severity={snackbarData.severity}
          testId={"accountant-snackbar"}
          open={snackbarData.open}
        />
      </SetupFormLayout>
      </Grid>
    </Grid>

    //old form method using rhook form
    // <SetupFormLayout
    //   isLoadingData={isLoading || isLoadingById}
    //   renderForm={renderForm}
    //   isSubmittingForm={isSubmitting}
    // >
    //   {screenFields && Array.isArray(screenFields) && (
    //     <RHookFormGenerator
    //       formLayout={screenFields}
    //       onSubmit={onSubmit}
    //       testId="resource-setup-form"
    //     />
    //   )}
    //   <EnhancedSnackbar
    //     message={snackbarData.message}
    //     onCloseHandler={onSnackbarClosed}
    //     severity={snackbarData.severity}
    //     testId={"resource-snackbar"}
    //     open={snackbarData.open}
    //   />
    // </SetupFormLayout>

    //stepper from to set validation for every step
    // <StepperForm onSubmit={onSubmitAllTabs} initialValues={initialValues}/>
  );
};
