import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { Grid, useTheme } from "@mui/material";
import { ReportsService } from "services/ReportsService";
import { BackdropCircularProgress } from "@websential/cosmic";
import { TransactionDetailDataTable } from "./TransactionDetailDataTable";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IInvoiceTransactionDetail {}

const useTransactionDetails = (
  clientId: string,
  taxYear: number,
  refNo: string,
  ) =>
  useQuery(
    ["Transaction-detail", clientId, taxYear, refNo],
    (data) => {
      return ReportsService.getInvoiceTransactionDetail(clientId, taxYear, refNo);

    },
    { refetchOnWindowFocus: false }
  );

export const InvoiceTransactionDetail: React.FC<IInvoiceTransactionDetail> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientId = useSelector((state: IAppState) => state.client.id);
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const taxYear  = useSelector((state: IAppState) => state.taxYear.year);
  const refNo = useSelector((state: IAppState) => state.invoice.refNo);

  console.log("refNo",refNo)


  const { isLoading : isLoadingTransactionDetailList, data } = useTransactionDetails(clientId,taxYear,refNo); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const transactionDetailData: any = data;
  console.log("@@@@@ => transactionDetailData", transactionDetailData);

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:'',
      companyName:'',
      activityType:"",
      activityLogVisibility:false
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[])

  return (
    <>
      {/* <BackdropCircularProgress
          open={
            isLoading
          }
          testId="T4 Slip List"
        /> */}
      <Grid item xs={12} sm={8} md={8} lg={8} xl={8} sx={{ marginTop: theme.spacing(3)}}>
      <TransactionDetailDataTable
          rows={transactionDetailData && transactionDetailData.items}
          last_rows={transactionDetailData && transactionDetailData.sumData}
          columns={transactionDetailData && transactionDetailData.headerColumns}
          showIndexColumn={true}
          showChecboxColumn={false}
          showSettingColumn={false}
          isLoading={isLoadingTransactionDetailList}
        />
      </Grid>
    </>
  );
};
