import { AxiosError, AxiosResponse } from "axios";
import http from "common/http-common";
import {
  IDataRoleListModel,
  IDataRoleRequestPayload,
  IDataRoleResponsePayload,
  IGetListRequest,
  IResponse,
  IServerError,
} from "models";

export const DataRoleService = {
  create: async (
    requestPayload: IDataRoleRequestPayload
  ): Promise<IDataRoleResponsePayload | IServerError | IResponse> => {
    if (requestPayload.id) {
      const appRoleRequest = {
        roleName: requestPayload.roleName,
        appRoleName: requestPayload.roleName.toLocaleLowerCase().replace(/\s/g, '-'),
      };
      console.log('requestPayload ==>> update request', requestPayload);
      const response: AxiosResponse<IDataRoleResponsePayload> = await http.put<IDataRoleResponsePayload>(
        `/v1/data-roles/${requestPayload.id}`, appRoleRequest);

      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
    }

    console.log("requestPayload ==>> add request", requestPayload);
    const appRoleRequest = {
      roleName: requestPayload.roleName,
      appRoleName: requestPayload.roleName.toLocaleLowerCase().replace(/\s/g, '-'),
    };
    const response: AxiosResponse<IDataRoleResponsePayload> =
      await http.post<IDataRoleResponsePayload>(
        "/v1/data-roles/",
        appRoleRequest
      );

    if (response.status === 201) {
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },

  delDataRole: async (
    dataRoleId: number
  ): Promise<string | IServerError | IResponse> => {
    console.log("deleteById", dataRoleId);
    const response: AxiosResponse<string> = await http.delete<string>(
      `/v1/data-roles/${dataRoleId}/`
    );

    if (response.status === 204) {
      return "Data Role has been deleted successfully";
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  getList: async (
    params: IGetListRequest
  ): Promise<IDataRoleListModel | IServerError | IResponse> => {
    // const fixedResponse: IDataRoleListModel = {
    //   "page": 1,
    //   "size": 5,
    //   "total": 2,
    //   "items": [
    //     {
    //       "id": "ab8ccece-16c1-44e9-ba86-2990eefb178c",
    //       "roleName": "Vendor",
    //     },
    //     {
    //       "id": "4e3823aa-c2e4-45aa-aaaf-9ba1b1486acc",
    //       "roleName": "Employee",
    //     }
    //   ],
    //   "keyField": {
    //     "field": "id",
    //     "templateUrl": "/data-roles/setup/id"
    //   },
    //   "headerColumns": [
    //     {
    //       "field": "roleName",
    //       "headerName": "Data Role Name",
    //       "description": "Data role name",
    //       "flex": 1,
    //       "hide": false,
    //       "hideable": false,
    //       "sortable": true,
    //       // "sortingOrder": "asc",
    //       "resizable": false,
    //       "type": "string",
    //       "align": "left",
    //       "headerAlign": "left",
    //       "hideSortIcons": false,
    //       "disableColumnMenu": true,
    //       "disableReorder": true,
    //       "disableExport": true,
    //       "maskable": false,
    //       // "isFixed": true,
    //       "sequence": 0
    //     }
    //   ]
    // }
    // return new Promise(resolve => setTimeout(() => resolve(fixedResponse), 1000));
    try {
    const response: AxiosResponse<IDataRoleListModel> =
      await http.get<IDataRoleListModel>(
        `/v1/data-roles/?order_by=${params.order_by}&order_direction=${params.order_direction}&page=${params.page}&size=${params.size}&search_query=${params.search_query}`
      );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return { status: false, message: axiosError.response ? axiosError.response.data : "Unknown error" };
  }
  },
   getById: async (id: string | undefined): Promise<IDataRoleListModel | IServerError | IResponse | null> => {
    if (id) {
      const response: AxiosResponse<IDataRoleListModel> = await http.get<IDataRoleListModel>(
        `/v1/data-roles/${id}/`);
  
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 500) {
        return {
          status: false,
          message: "Something went wrong.",
          error: true,
        };
      }
  
      return {
        status: false,
        message: "Something went wrong please try again later.",
      };
    }

    return null;
  },
  // getSetupLayout: async (): Promise<any> => {
  //   let response: any = ACCOUNTANT_SETUP_FORM;
  //   return new Promise(resolve => setTimeout(() => resolve(response), 1000));
  // },
};
