import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface RL1SummaryFrenchAxisPages1 {}
const RL1SummaryFrenchAxisPages1:React.FC<RL1SummaryFrenchAxisPages1> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'employerNameP1X'} 
                testId={'employerNameP1X'} 
                label={'Employer Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={30}
            />
            <RHookFormTextFieldWithLabel 
                id={'mailingAddressP1X'} 
                testId={'mailingAddressP1X'} 
                label={'Mailing Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={30}
            />
            <RHookFormTextFieldWithLabel 
                id={'postalCodeP1X'} 
                testId={'postalCodeP1X'} 
                label={'Postal Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={305}
            />
            <RHookFormTextFieldWithLabel 
                id={'identificationNumberP1X'} 
                testId={'identificationNumberP1X'} 
                label={'Identification Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={495}
            />
            <RHookFormTextFieldWithLabel 
                id={'fileRsP1X'} 
                testId={'fileRsP1X'} 
                label={'File Rs'} 
                labelPosition={LabelPosition.Left}
                defaultValue={460}
            />
            <RHookFormTextFieldWithLabel 
                id={'yearP1X'} 
                testId={'yearP1X'} 
                label={'Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={410}
            />
            <RHookFormTextFieldWithLabel 
                id={'onPaperP1X'} 
                testId={'onPaperP1X'} 
                label={'On Paper'} 
                labelPosition={LabelPosition.Left}
                defaultValue={210}
            />
            <RHookFormTextFieldWithLabel 
                id={'onlineP1X'} 
                testId={'onlineP1X'} 
                label={'Online'} 
                labelPosition={LabelPosition.Left}
                defaultValue={330}
            />
            <RHookFormTextFieldWithLabel 
                id={'transmitterNumberP1X'} 
                testId={'transmitterNumberP1X'} 
                label={'Transmitter Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={465}
            />
            <RHookFormTextFieldWithLabel 
                id={'employeeContributionP1X'} 
                testId={'employeeContributionP1X'} 
                label={'Employee Contribution'} 
                labelPosition={LabelPosition.Left}
                defaultValue={412}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerContributionP1X'} 
                testId={'employerContributionP1X'} 
                label={'Employer Contribution'} 
                labelPosition={LabelPosition.Left}
                defaultValue={412}
            />
            <RHookFormTextFieldWithLabel 
                id={'contributionSumP1X'} 
                testId={'contributionSumP1X'} 
                label={'Contribution Sum'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
            <RHookFormTextFieldWithLabel 
                id={'employeePremiumsP1X'} 
                testId={'employeePremiumsP1X'} 
                label={'Employee Premiums'} 
                labelPosition={LabelPosition.Left}
                defaultValue={412}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerPremiumsP1X'} 
                testId={'employerPremiumsP1X'} 
                label={'Employer Premiums'} 
                labelPosition={LabelPosition.Left}
                defaultValue={412}
            />
            <RHookFormTextFieldWithLabel 
                id={'premiumsSumP1X'} 
                testId={'premiumsSumP1X'} 
                label={'Premiums Sum'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
            <RHookFormTextFieldWithLabel 
                id={'employeeIncomeTaxP1X'} 
                testId={'employeeIncomeTaxP1X'} 
                label={'Employee Income Tax'} 
                labelPosition={LabelPosition.Left}
                defaultValue={412}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerIncomeTaxP1X'} 
                testId={'employerIncomeTaxP1X'} 
                label={'Employer Income Tax'} 
                labelPosition={LabelPosition.Left}
                defaultValue={412}
            />
            <RHookFormTextFieldWithLabel 
                id={'incomeTaxSumP1X'} 
                testId={'incomeTaxSumP1X'} 
                label={'Income Tax Sum'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
            <RHookFormTextFieldWithLabel 
                id={'line39And12SumP1X'} 
                testId={'line39And12SumP1X'} 
                label={'Line 39 And 12 Sum'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
            <RHookFormTextFieldWithLabel 
                id={'line26And36SumP1X'} 
                testId={'line26And36SumP1X'} 
                label={'Line 26 And 36 Sum'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
            <RHookFormTextFieldWithLabel 
                id={'box27P1X'} 
                testId={'box27P1X'} 
                label={'Box 27'} 
                labelPosition={LabelPosition.Left}
                defaultValue={558}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'employerNameP1Y'} 
                testId={'employerNameP1Y'} 
                label={'Employer Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={680}
            />
            <RHookFormTextFieldWithLabel 
                id={'mailingAddressP1Y'} 
                testId={'mailingAddressP1Y'} 
                label={'Mailing Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'postalCodeP1Y'} 
                testId={'postalCodeP1Y'} 
                label={'Postal Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'identificationNumberP1Y'} 
                testId={'identificationNumberP1Y'} 
                label={'Identification Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={648}
            />
            <RHookFormTextFieldWithLabel 
                id={'fileRsP1Y'} 
                testId={'fileRsP1Y'} 
                label={'File Rs'} 
                labelPosition={LabelPosition.Left}
                defaultValue={635}
            />
            <RHookFormTextFieldWithLabel 
                id={'yearP1Y'} 
                testId={'yearP1Y'} 
                label={'Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={600}
            />
            <RHookFormTextFieldWithLabel 
                id={'onPaperP1Y'} 
                testId={'onPaperP1Y'} 
                label={'On Paper'} 
                labelPosition={LabelPosition.Left}
                defaultValue={565}
            />
            <RHookFormTextFieldWithLabel 
                id={'onlineP1Y'} 
                testId={'onlineP1Y'} 
                label={'Online'} 
                labelPosition={LabelPosition.Left}
                defaultValue={565}
            />
            <RHookFormTextFieldWithLabel 
                id={'transmitterNumberP1Y'} 
                testId={'transmitterNumberP1Y'} 
                label={'Transmitter Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={565}
            />
            <RHookFormTextFieldWithLabel 
                id={'employeeContributionP1Y'} 
                testId={'employeeContributionP1Y'} 
                label={'Employee Contribution'} 
                labelPosition={LabelPosition.Left}
                defaultValue={292}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerContributionP1Y'} 
                testId={'employerContributionP1Y'} 
                label={'Employer Contribution'} 
                labelPosition={LabelPosition.Left}
                defaultValue={278}
            />
            <RHookFormTextFieldWithLabel 
                id={'contributionSumP1Y'} 
                testId={'contributionSumP1Y'} 
                label={'Contribution Sum'} 
                labelPosition={LabelPosition.Left}
                defaultValue={278}
            />
            <RHookFormTextFieldWithLabel 
                id={'employeePremiumsP1Y'} 
                testId={'employeePremiumsP1Y'} 
                label={'Employee Premiums'} 
                labelPosition={LabelPosition.Left}
                defaultValue={242}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerPremiumsP1Y'} 
                testId={'employerPremiumsP1Y'} 
                label={'Employer Premiums'} 
                labelPosition={LabelPosition.Left}
                defaultValue={230}
            />
            <RHookFormTextFieldWithLabel 
                id={'premiumsSumP1Y'} 
                testId={'premiumsSumP1Y'} 
                label={'Premiums Sum'} 
                labelPosition={LabelPosition.Left}
                defaultValue={230}
            />
            <RHookFormTextFieldWithLabel 
                id={'employeeIncomeTaxP1Y'} 
                testId={'employeeIncomeTaxP1Y'} 
                label={'Employee Income Tax'} 
                labelPosition={LabelPosition.Left}
                defaultValue={196}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerIncomeTaxP1Y'} 
                testId={'employerIncomeTaxP1Y'} 
                label={'Employer Income Tax'} 
                labelPosition={LabelPosition.Left}
                defaultValue={183}
            />
            <RHookFormTextFieldWithLabel 
                id={'incomeTaxSumP1Y'} 
                testId={'incomeTaxSumP1Y'} 
                label={'Income Tax Sum'} 
                labelPosition={LabelPosition.Left}
                defaultValue={183}
            />
            <RHookFormTextFieldWithLabel 
                id={'line39And12SumP1Y'} 
                testId={'line39And12SumP1Y'} 
                label={'Line 39 And 12 Sum'} 
                labelPosition={LabelPosition.Left}
                defaultValue={162}
            />
            <RHookFormTextFieldWithLabel 
                id={'line26And36SumP1Y'} 
                testId={'line26And36SumP1Y'} 
                label={'Line 26 And 36 Sum'} 
                labelPosition={LabelPosition.Left}
                defaultValue={137}
            />
            <RHookFormTextFieldWithLabel 
                id={'box27P1Y'} 
                testId={'box27P1Y'} 
                label={'Box 27'} 
                labelPosition={LabelPosition.Left}
                defaultValue={100}
            />
        </Grid>
    </Grid>
  )
}

export default RL1SummaryFrenchAxisPages1