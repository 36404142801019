import { RL16SlipList } from "components/RL16Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL16SlipListPagePage {}

export const RL16SlipListPage: FC<IRL16SlipListPagePage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="RL-16 Slip List"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl16/list" },
        { text: "RL-16", navigateTo: "/taxslip/rl16/list" },
        { text: "RL-16 Slips", navigateTo: "/taxslip/rl16/list" },
      ]}
      mainContents={<RL16SlipList />}
    />
  );
};
