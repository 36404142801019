import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IT3SLipAxisPage2 {}
const T3SLipAxisPage2:React.FC<IT3SLipAxisPage2> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP2X'} 
                testId={'taxYearP2X'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={250}
            />
            <RHookFormTextFieldWithLabel 
                id={'statementCodeP2X'} 
                testId={'statementCodeP2X'} 
                label={'Statement Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={300}
            />
            <RHookFormTextFieldWithLabel 
                id={'currencyCodeP2X'} 
                testId={'currencyCodeP2X'} 
                label={'Currency Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={380}
            />
            <RHookFormTextFieldWithLabel 
                id={'numberOfLastStatementSentP2X'} 
                testId={'numberOfLastStatementSentP2X'} 
                label={'Number of last statement sent'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxA1P2X'} 
                testId={'boxA1P2X'} 
                label={'Box A1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={105}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxA2P2X'} 
                testId={'boxA2P2X'} 
                label={'Box A2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={220}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxBP2X'} 
                testId={'boxBP2X'} 
                label={'Box B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={332}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxCP2X'} 
                testId={'boxCP2X'} 
                label={'Box C'} 
                labelPosition={LabelPosition.Left}
                defaultValue={448}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxDP2X'} 
                testId={'boxDP2X'} 
                label={'Box D'} 
                labelPosition={LabelPosition.Left}
                defaultValue={560}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxEP2X'} 
                testId={'boxEP2X'} 
                label={'Box E'} 
                labelPosition={LabelPosition.Left}
                defaultValue={105}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxFP2X'} 
                testId={'boxFP2X'} 
                label={'Box F'} 
                labelPosition={LabelPosition.Left}
                defaultValue={220}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxGP2X'} 
                testId={'boxGP2X'} 
                label={'Box G'} 
                labelPosition={LabelPosition.Left}
                defaultValue={332}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxHP2X'} 
                testId={'boxHP2X'} 
                label={'Box H'} 
                labelPosition={LabelPosition.Left}
                defaultValue={448}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxIP2X'} 
                testId={'boxIP2X'} 
                label={'Box I'} 
                labelPosition={LabelPosition.Left}
                defaultValue={560}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxJP2X'} 
                testId={'boxJP2X'} 
                label={'Box J'} 
                labelPosition={LabelPosition.Left}
                defaultValue={105}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxKP2X'} 
                testId={'boxKP2X'} 
                label={'Box K'} 
                labelPosition={LabelPosition.Left}
                defaultValue={220}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P2X'} 
                testId={'otherInfoCode1P2X'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={35}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P2X'} 
                testId={'otherInfoAmount1P2X'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={100}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P2X'} 
                testId={'otherInfoCode2P2X'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={175}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P2X'} 
                testId={'otherInfoAmount2P2X'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={260}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P2X'} 
                testId={'otherInfoCode3P2X'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={320}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P2X'} 
                testId={'otherInfoAmount3P2X'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={390}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P2X'} 
                testId={'otherInfoCode4P2X'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={453}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P2X'} 
                testId={'otherInfoAmount4P2X'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={520}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceFirstLastNameP2X'} 
                testId={'resourceFirstLastNameP2X'} 
                label={'Resouce First Last Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceAddressP2X'} 
                testId={'resourceAddressP2X'} 
                label={'Resource Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceCityP2X'} 
                testId={'resourceCityP2X'} 
                label={'Resource City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientNameP2X'} 
                testId={'clientNameP2X'} 
                label={'Client Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={370}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientAddressP2X'} 
                testId={'clientAddressP2X'} 
                label={'Client Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={370}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientCityP2X'} 
                testId={'clientCityP2X'} 
                label={'Client City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={370}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientReferenceNo1To3P2X'} 
                testId={'clientReferenceNo1To3P2X'} 
                label={'Client Reference No 1 To 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={360}
            />
            <RHookFormTextFieldWithLabel 
                id={'codeDeLaDeviseSlipTypeP2X'} 
                testId={'codeDeLaDeviseSlipTypeP2X'} 
                label={'Code DeLa Devise Slip Type'} 
                labelPosition={LabelPosition.Left}
                defaultValue={310}
            />
            <RHookFormTextFieldWithLabel 
                id={'fsP2X'} 
                testId={'fsP2X'} 
                label={'FS'} 
                labelPosition={LabelPosition.Left}
                defaultValue={524}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl3202210AboveBoxP2X'} 
                testId={'rl3202210AboveBoxP2X'} 
                label={'RL-3 2022 10 Above Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={510}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl3202210DownBoxP2X'} 
                testId={'rl3202210DownBoxP2X'} 
                label={'RL-3 2022 10 Down Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={510}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientSocialInsurance1To3P2X'} 
                testId={'clientSocialInsurance1To3P2X'} 
                label={'Client Social Insurance 1 To 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={360}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientSocialInsurance4To6P2X'} 
                testId={'clientSocialInsurance4To6P2X'} 
                label={'Client Social Insurance 4 To 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={396}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientSocialInsurance7To9P2X'} 
                testId={'clientSocialInsurance7To9P2X'} 
                label={'Client Social Insurance 7 To 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={436}
            />
            <RHookFormTextFieldWithLabel 
                id={'slipOtherNumberP2X'} 
                testId={'slipOtherNumberP2X'} 
                label={'Slip Other Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={480}
            />
            <RHookFormTextFieldWithLabel 
                id={'bankTransitNumberP2X'} 
                testId={'bankTransitNumberP2X'} 
                label={'Bank Transit Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={490}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'taxYearP2Y'} 
                testId={'taxYearP2Y'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={740}
            />
            <RHookFormTextFieldWithLabel 
                id={'statementCodeP2Y'} 
                testId={'statementCodeP2Y'} 
                label={'Statement Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={740}
            />
            <RHookFormTextFieldWithLabel 
                id={'currencyCodeP2Y'} 
                testId={'currencyCodeP2Y'} 
                label={'Currency Code'} 
                labelPosition={LabelPosition.Left}
                defaultValue={740}
            />
            <RHookFormTextFieldWithLabel 
                id={'numberOfLastStatementSentP2Y'} 
                testId={'numberOfLastStatementSentP2Y'} 
                label={'Number of last statement sent'} 
                labelPosition={LabelPosition.Left}
                defaultValue={740}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxA1P2Y'} 
                testId={'boxA1P2Y'} 
                label={'Box A1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={705}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxA2P2Y'} 
                testId={'boxA2P2Y'} 
                label={'Box A2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={705}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxBP2Y'} 
                testId={'boxBP2Y'} 
                label={'Box B'} 
                labelPosition={LabelPosition.Left}
                defaultValue={705}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxCP2Y'} 
                testId={'boxCP2Y'} 
                label={'Box C'} 
                labelPosition={LabelPosition.Left}
                defaultValue={705}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxDP2Y'} 
                testId={'boxDP2Y'} 
                label={'Box D'} 
                labelPosition={LabelPosition.Left}
                defaultValue={705}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxEP2Y'} 
                testId={'boxEP2Y'} 
                label={'Box E'} 
                labelPosition={LabelPosition.Left}
                defaultValue={680}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxFP2Y'} 
                testId={'boxFP2Y'} 
                label={'Box F'} 
                labelPosition={LabelPosition.Left}
                defaultValue={680}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxGP2Y'} 
                testId={'boxGP2Y'} 
                label={'Box G'} 
                labelPosition={LabelPosition.Left}
                defaultValue={680}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxHP2Y'} 
                testId={'boxHP2Y'} 
                label={'Box H'} 
                labelPosition={LabelPosition.Left}
                defaultValue={680}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxIP2Y'} 
                testId={'boxIP2Y'} 
                label={'Box I'} 
                labelPosition={LabelPosition.Left}
                defaultValue={680}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxJP2Y'} 
                testId={'boxJP2Y'} 
                label={'Box J'} 
                labelPosition={LabelPosition.Left}
                defaultValue={655}
            />
            <RHookFormTextFieldWithLabel 
                id={'boxKP2Y'} 
                testId={'boxKP2Y'} 
                label={'Box K'} 
                labelPosition={LabelPosition.Left}
                defaultValue={655}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P2Y'} 
                testId={'otherInfoCode1P2Y'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P2Y'} 
                testId={'otherInfoAmount1P2Y'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P2Y'} 
                testId={'otherInfoCode2P2Y'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P2Y'} 
                testId={'otherInfoAmount2P2Y'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P2Y'} 
                testId={'otherInfoCode3P2Y'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P2Y'} 
                testId={'otherInfoAmount3P2Y'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P2Y'} 
                testId={'otherInfoCode4P2Y'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P2Y'} 
                testId={'otherInfoAmount4P2Y'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceFirstLastNameP2Y'} 
                testId={'resourceFirstLastNameP2Y'} 
                label={'Resouce First Last Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={115}
            />
            <RHookFormTextFieldWithLabel 
                id={'resouceAddressP2Y'} 
                testId={'resouceAddressP2Y'} 
                label={'Resouce Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={102}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceCityP2Y'} 
                testId={'resourceCityP2Y'} 
                label={'Resource City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={40}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientNameP2Y'} 
                testId={'clientNameP2Y'} 
                label={'Client Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={130}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientAddressP2Y'} 
                testId={'clientAddressP2Y'} 
                label={'Client Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={115}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientCityP2Y'} 
                testId={'clientCityP2Y'} 
                label={'Client City'} 
                labelPosition={LabelPosition.Left}
                defaultValue={100}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientReferenceNo1To3P2Y'} 
                testId={'clientReferenceNo1To3P2Y'} 
                label={'Client Reference No 1 To 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={170}
            />
            <RHookFormTextFieldWithLabel 
                id={'codeDeLaDeviseSlipTypeP2Y'} 
                testId={'codeDeLaDeviseSlipTypeP2Y'} 
                label={'Code DeLa Devise Slip Type'} 
                labelPosition={LabelPosition.Left}
                defaultValue={740}
            />
            <RHookFormTextFieldWithLabel 
                id={'fsP2Y'} 
                testId={'fsP2Y'} 
                label={'FS'} 
                labelPosition={LabelPosition.Left}
                defaultValue={63}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl3202210AboveBoxP2Y'} 
                testId={'rl3202210AboveBoxP2Y'} 
                label={'RL-3 2022 10 Above Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={749.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'rl3202210DownBoxP2Y'} 
                testId={'rl3202210DownBoxP2Y'} 
                label={'RL-3 2022 10 Down Box'} 
                labelPosition={LabelPosition.Left}
                defaultValue={737}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientSocialInsurance1To3P2Y'} 
                testId={'clientSocialInsurance1To3P2Y'} 
                label={'Client Social Insurance 1 To 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={170}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientSocialInsurance4To6P2Y'} 
                testId={'clientSocialInsurance4To6P2Y'} 
                label={'Client Social Insurance 4 To 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={170}
            />
            <RHookFormTextFieldWithLabel 
                id={'clientSocialInsurance7To9P2Y'} 
                testId={'clientSocialInsurance7To9P2Y'} 
                label={'Client Social Insurance 7 To 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={170}
            />
            <RHookFormTextFieldWithLabel 
                id={'slipOtherNumberP2Y'} 
                testId={'slipOtherNumberP2Y'} 
                label={'Slip Other Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={172}
            />
            <RHookFormTextFieldWithLabel 
                id={'bankTransitNumberP2Y'} 
                testId={'bankTransitNumberP2Y'} 
                label={'Bank Transit Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={657}
            />
        </Grid>
    </Grid>
  )
}

export default T3SLipAxisPage2