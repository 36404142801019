import {
  FILING_RESOURCE_LIST_SCREEN_ID,
  FILING_RESOURCE_LIST_SCREEN_SLUG,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
} from "common/constants";
import { ComponentState } from "common/enums";
import { toSnake } from "common/methods";
import { EmptyAccountantList } from "components/Accountant/EmptyAccountantList";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useState } from "react";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FilingResourceService } from "services/FilingResourceService";
import { clearError } from "store/actions/viewStateActions";
import { IAppState } from "store/reducers";

import { Box, Grid, useTheme } from "@mui/material";
import { BackdropCircularProgress, DeleteConfirmationDialog, EmptyList, EnhancedSnackbar, IResponse, IServerError, RouterLinkButton } from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import { PrimeDataGrid } from "components/PrimeDataGrid";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IFilingResourceList {}
const FILLING_RESOURCE_LIST_QUERY_KEY = "filing-resource-list"

const useFilingResources = (
  clientId: string,
  params: IGetListRequest) =>
  useQuery(
    ["filing-resource-list", params],
    (data) => {
      return FilingResourceService.getList(clientId, params);
    },
    { refetchOnWindowFocus: false }
  );

export const FilingResourceList: React.FC<IFilingResourceList> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientName = useSelector((state : IAppState) => state.client.clientName);
  const { id } = useSelector((state: IAppState) => state.client);
  const isLoadingActivityLogData = useSelector((state: IAppState) => state.isLoadAxiosCall.isLoading);

  // for delete row Purpose
  const queryClient = useQueryClient();
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [filingResourceUpdatedListItems, setFilingResourceUpdatedListItems] = useState<any>(undefined);

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "role_name",
    order_direction: "asc",
    page: 1,
    size: 25,
  });

  const { isLoading, data } = useFilingResources(id,queryParams); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const listRecords: any = data;
  console.log("@@@@@ => accountantList", listRecords);

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
  useEffect(()=>{
    if(isLoading){
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    } else {
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    }
  },[isLoading])
  
  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);
  const [zeroFilingResource, setzeroFilingResource] = useState(true);

  // for delete row
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [fillResourceId, setFillResourceId] = useState<number>(0);

  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    // let dispatchParams = {
    //   search_query: requestParams.debouncedSearchQuery,
    //   order_by: "role_name",
    //   order_direction: "asc",
    //   page: requestParams.currentPage + 1,
    //   size: requestParams.pageSize,
    // };
    // if (requestParams.sort) {
    //   const [sortBy, sortDirection] = requestParams.sort.split(",");
    //   dispatchParams.order_by = toSnake(sortBy);
    //   dispatchParams.order_direction = sortDirection;
    // }

    // according to prime data table from prime react
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: "name1",
      order_direction: requestParams.sortOrder ? requestParams.sortOrder : "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
    };

    setQueryParams(dispatchParams);
  }, []);

  const onRowEditClicked = useCallback((params) => {
    // alert(`Edit row with ID: ${params.id} has been clicked!`);
    let path = `/filing-resources/setup/${params.id}`;
    navigate(path);
  }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
  };

  const callAction = () => {
    if (apiAction === "delete") {
      alert(`Delete row with ID: ${selectedIds} has been clicked!`);
      // TODO api call for delete
    } else if (apiAction === "updateStatus") {
      alert(`action need to trigger : ${apiAction}`);
      // TODO api call for update status
    }
    setDialogOpen(false);
  };

  // For Delete Row
 const deleteFillResourceField = () => {
  if (singleDelete) {
    setSingleDelete(false);
    deleteFillResourceById(fillResourceId);
    setDialogOpen(false);
  }
};

const onRowDeleteClicked = useCallback((params) => {
  setDialogOpen(true);
  setSingleDelete(true);
  setApiAction("delete");
  setFillResourceId(params.id);
  console.log("FillResourceId in FillResource List OnDeleteRowClicked function",params.id)
  // alert(`Delete row with ID: ${params.id} has been clicked!`);
}, []);

const onSnackbarClosed = (
  event: React.SyntheticEvent | Event,
  reason?: string
) => {
  if (reason === "clickaway") {
    return;
  }
  setSnackbarData(INITIAL_SNACKBAR_DATA);
};

const { isLoading: isDeleting, mutate: deleteFillResourceById } = useMutation(
  FilingResourceService.delFillResource,
  {
    onSuccess: (res: string | IServerError | IResponse) => {
      if (typeof res === "string") {
        // remove the cache of the Year fields since we just added a new field.
        setSnackbarData({
          open: true,
          message: "Record has been deleted successfully.",
          severity: "success",
        });
        
        return queryClient.invalidateQueries(
          FILLING_RESOURCE_LIST_QUERY_KEY
          );
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });
        }
      },
    onError: (err) => {
      // TODO: customize the toast message based on the response
      setSnackbarData({
        open: true,
        message: "Something went wrong, try again later!",
        severity: "error",
      });
    },
  }
);


  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusValue(event.target.value as string);
    setApiAction("updateStatus");
    setDialogOpen(true);
  };

  const selectionToolbar = useCallback((selectedItems) => {
    setSelectedIds(selectedItems);
    if (selectedItems.length) {
      return (
        <Box sx={{ marginTop: theme.spacing(1) }}>
          {"Bulk Action Drop Down List"}
          {/* <EnhancedDDLBulkAction
            label="Status"
            value={statusValue}
            onChangeHandler={handleChange}
            options={[
              { name: "Active", value: "1" },
              { name: "Inactive", value: "2" },
            ]}
            testId="status-dd"
          /> */}
        </Box>
      );
    }
    return (
      <Box sx={{ marginTop: theme.spacing(1) }}>
        <RouterLinkButton
          to="/filing-resources/setup"
          text="Add Filing Resource"
        />
      </Box>
    );
  }, []);

  //find the index of object from headerColumns to add link on given column
  // if (accountantList?.headerColumns) {
  //   const linkObjIndex = accountantList.headerColumns.findIndex(
  //     (obj: { field: any; }) => obj.field === accountantList.keyField.field
  //   );
  //   accountantList.headerColumns[linkObjIndex] = {
  //     field: accountantList.headerColumns[linkObjIndex].field,
  //     headerName: accountantList.headerColumns[linkObjIndex].headerName,
  //     flex: 1,
  //     renderCell: (params: any) => {
  //       var urlLink = accountantList.keyField.templateUrl;
  //       var newLink = urlLink.replace("{id}", params.id);

  //       return (
  //         <Link to={newLink} className={classes.textLink}>
  //           {params.row.accTitle}
  //         </Link>
  //       );
  //     },
  //   };
  //   const statusObjIndex = accountantList.headerColumns.findIndex(
  //     (obj: { field: string; }) => obj.field === "status"
  //   );
  //   accountantList.headerColumns[statusObjIndex] = {
  //     field: "status",
  //     headerName: "Status",
  //     flex: 1,
  //     width: 50,
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params: any) => (
  //       <Chip label={params.value} />
  //     ),
  //   };
  // }

  // if (isLoading) {
  //   return (
  //     <CircularProgress
  //       size={24}
  //       data-testid="circular-progress"
  //       className={classes.loader}
  //     />
  //   );
  // }

  useEffect(()=>{
    if(listRecords){
      const updatedItems = listRecords.items.map((item:any) => ({
        ...item,
        role:item.role.join(', '),
        name: (
          <Link
            to={`/filing-resources/setup/${item.id}`}
            className="no-underline"
            style={{ color: "blue" }}
            // onClick={() => onClickHandler(row)}
          >
            {item.name}
          </Link>
        ),
      }))
      console.log("updatedItems",updatedItems)
      setFilingResourceUpdatedListItems(updatedItems)
    }
  },[listRecords])

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:FILING_RESOURCE_LIST_SCREEN_SLUG,
      companyName:clientName,
      activityType:"activity_type=delete",
      activityLogVisibility:true
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[])

  const image =
  "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";
  
  return listRecords && listRecords.items.length === 0 && zeroFilingResource === true ? (
    <EmptyList 
    showImage={true}
    imagePath= {image}
    heading={`There's nothing! Add Filing Resource to proceed`} 
    content={`You can add filing resource by directly clicking on add filing resource button.`} 
    testId={"filing-resource-empty-list"} 
    showAddButton= {true}
    addButtonLabel={`Add Filing Resource`}
    navigateToAdd={"/filing-resources/setup"}
    showImportButton={false}
    showImportXmlButton={false}
    />
  ) : (
    <>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
      {/* <EnhancedDialog
        dialogTitle="Please Confirm"
        key="dialog-box"
        testId="dialog"
        onCloseHandler={onCloseHandler}
        open={openDialog}
        dialogContent={
          <Typography variant="subtitle1">Are you sure?</Typography>
        }
        dialogAction={
          <Grid>
            <Button
              autoFocus
              variant="contained"
              onClick={onCloseHandler}
              color="default"
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={callAction}
              color="primary"
              className={classes.button}
            >
              ok
            </Button>
          </Grid>
        }
      /> */}
       <BackdropCircularProgress
          open={isLoadingActivityLogData}
          testId="filling resource list"
        />
       <DeleteConfirmationDialog
          open={openDialog}
          deleteActionHandler={deleteFillResourceField}
          cancelActionHandler={onCloseHandler}
          testId="resource-confirmation-dialog"
        />
      {/* <EnhancedDataGrid
        columns={listRecords ? listRecords.headerColumns : []}
        rows={listRecords?.items}
        selectionEnabled={false}
        autoHeight={true}
        allowSearching={true}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        selectionToolbar={selectionToolbar}
        onRowEditClicked={onRowEditClicked}
        onRowDeleteClicked={onRowDeleteClicked}
        // initialPage={0}
        initialPageSize={INITIAL_PAGE_SIZE}
        disableColumnMenu={true}
        showRowActionsColumn={true}
        autoPageSize={false} //
        showIndexColumn={true}
        testId="data-role-list-grid"
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onRequestParamsChange={onRequestParamsChange}
        totalRowsCount={listRecords?.total}
        isLoading={isLoading || isDeleting}
        rowIdGetter={(row) => row.id}
        searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
        shouldMask={false}
      /> */}

      {/* use prime data table */}
      <PrimeDataGrid 
          columns={listRecords ? listRecords.headerColumns : []}
          rows={listRecords && filingResourceUpdatedListItems && filingResourceUpdatedListItems}
          showIndexColumn={true}
          showChecboxColumn={false}
          showSettingColumn={true}
          onRowEditClicked={onRowEditClicked}
          onRowDeleteClicked={onRowDeleteClicked}
          isLoading={isLoading || isAxiosCallLoading}
          onSnackbarMessageData={(value: any) => setSnackbarData(value)}
          totalRecords={listRecords && listRecords.total ? listRecords.total : 0}
          initialPageSize={INITIAL_PAGE_SIZE}
          rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
          onRequestParamsChange={onRequestParamsChange}
          selectionToolbar={selectionToolbar}
          // selectedRowsValues={(value:any)=>setSelectedRowsValues(value)}
          searchFieldPlaceholder={`Search by Filing Resource Name...`}
          changeLoginForGetEmptyItemsBySearchQuery={(value:any)=>setzeroFilingResource(value)}
       />
    </Grid>
    <EnhancedSnackbar
    message={snackbarData.message}
    onCloseHandler={onSnackbarClosed}
    severity={snackbarData.severity}
    testId={"resource-list-snackbar"}
    open={snackbarData.open}
  />
  </>
  );
};
