import { Grid } from '@mui/material'
import { EnhancedDropDownRHFWithLabel, FieldLayout, LabelPosition, 
  RHookFormDatePicker, 
  SubmitButton } from '@websential/cosmic'
import { Calendar } from 'primereact/calendar'
import React, { useEffect, useState } from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import "./invoiceDataTableStyle.css"

export interface IInvoiceAdminReportHeadero {
  isSubmitting: boolean;
  formMethods: any
}
export const InvoiceAdminReportHeader: React.FC<IInvoiceAdminReportHeadero> = ({
  isSubmitting,
  formMethods
}) => {
    const paymentStatus = [
        {
          label: "All",
          value: 1
        },
        {
          label: "Paid",
          value: 100
        },
        {
          label: "Not Paid",
          value: 200
        }
      ]
      const dateSelectionOptions = [
        {
          label: "Invoice Date",
          value: 10
        },
        {
          label: "Payment Date",
          value: 20
        }
      ]

      useEffect(()=>{
        const currentYear = new Date().getFullYear();
        // formMethods.setValue('startDate', new Date("Sun Jan 01 2024 00:00:00 GMT+0500"))
        // formMethods.setValue('endDate', new Date("Fri Mar 31 2024 00:00:00 GMT+0500"))
        formMethods.setValue('startDate', new Date(currentYear, 0, 1)); // January 1st of the current year
        formMethods.setValue('endDate', new Date(currentYear, 2, 31)); // March 31st of the current year
      },[])

  return (
    <>
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} 
        sx={{
          margin:"0.5rem",
          border:"2px solid #dee2e6",
          width:"auto",
          padding:"2rem 10rem 1rem 10rem",
          justifyContent:"space-between",
        }}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <EnhancedDropDownRHFWithLabel
              selectName="status"
              label="Payment Status"
              labelPosition={LabelPosition.Left}
              items={paymentStatus}
              selectedItem={1}
              testId={"payment-status"}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{maxWidth:"40%!important"}}>
            <EnhancedDropDownRHFWithLabel
              selectName="dateAppliesTo"
              label="Date Applies To"
              labelPosition={LabelPosition.Left}
              items={dateSelectionOptions}
              selectedItem={10}
              testId={"date"}
            />
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{display:"flex",paddingTop:"1rem"}}>
          {/* <FieldLayout
            label="Select Date Range"
            testId=""
            labelPosition={LabelPosition.Left}
            labelColumns={4}
            fieldColumns={8}
          > */}
            {/* <Calendar 
              id='dateRange'
              {...formMethods.register('dateRange')}
              value={formMethods.watch('dateRange') || null} 
            //   onChange={(e:any) => setDates(e.value)} 
              selectionMode="range" 
            //   readOnlyInput 
              showIcon 
              showButtonBar 
              style={{
                width:"100%",
                height:"2.35rem",
                marginTop:"9px"
                }}
                dateFormat="M dd, yy"/> */}
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3} 
                sx={{paddingTop:"0.75rem"}}
                >
                  <span style={{
                    fontSize:"0.875rem",
                    fontFamily:'"Roboto","Helvetica","Arial",sans-serif',
                    fontWeight:500,
                    letterSpacing:"0.00714em",
                    color:"rgba(0, 0, 0, 0.6)"
                  }}>Select Date Range</span>
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>

                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <div style={{width:"45%"}}>
                  <RHookFormDatePicker name="startDate"
                   testId="invoice-admin-report-start-date"
                   label=""
                   labelPosition={LabelPosition.Top}
                  
                    />
                  </div>
                  {/* <i className="pi pi-angle-double-right" style={{ fontSize: '1rem', paddingTop:"1.4rem" }}></i> */}
                  <span style={{
                    fontSize:"0.875rem",
                    fontFamily:'"Roboto","Helvetica","Arial",sans-serif',
                    fontWeight:500,
                    letterSpacing:"0.00714em",
                    color:"rgba(0, 0, 0, 0.6)",
                    // paddingTop:"1.4rem"
                  }}>to</span>
                  <div style={{width:"45%"}}>
                  <RHookFormDatePicker name="endDate"
                   testId="invoice-admin-report-end-date"
                   label=""
                   labelPosition={LabelPosition.Top}
                    />
                  </div>
                </div>
                </Grid>
              {/* </FieldLayout> */}
          </Grid>
          
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{marginTop:"1.5rem"}}>
            <SubmitButton
                label="Apply"
                testId="submit-button"
                loading={isSubmitting}
                icon={<KeyboardArrowRightIcon />}
            />
        </Grid>
    </Grid>
    </>
  )
}