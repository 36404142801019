import React, { FC } from 'react';
import { useController } from 'react-hook-form';
import { IMaskMixin } from 'react-imask';

import { TextField } from '@mui/material';
import { ITest } from '@websential/cosmic';

/**
 * Text fields allow users to enter text into a UI. They typically appear in forms and dialogs.
 * The EnhancedTextInput wrapper component is a complete form control using Material UI TextField including
 * a label, input, and help text. Websential is using the outlined variants.
 */
export interface IRHookFormMaskedTextFieldWithOnChange extends ITest {
  /**
   * The id of the input element.
   */
  id: string;
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string;
  /**
   * If true, the adornment is displayed defined against prop 'inputAdornmentText'.
   */
  inputAdornment?: boolean;
  /**
   * The content of the component, normally a string.
   */
  inputAdornmentText?: string;
  /**
   * Set true if want to apply mask
   */
  inputMask?: boolean;
  /**
   * Set mask pattern
   */
  inputMaskPattern?: string;
  // /**
  //  * If true then it will change placeholder with dashes in mask text field
  //  */
  // lazy?: boolean;
  /**
   * Error message to be displayed
   */
  helperText?: string;
  /**
   * React hook form field
   */
  field?: any;
  /**
   * React hook form field value
   */
  defaultValue?: any;
  /**
   * If true, text in field wll be in upper case
   */
  uppercase?: boolean;
  /**
   * If true, will not allow user to edit value
   */
  readOnly?: boolean;

  onChangeHandler:(item: any) => void;
}

const MaskedInput = IMaskMixin(TextField);

export const RHookFormMaskedTextFieldWithOnChange: FC<IRHookFormMaskedTextFieldWithOnChange> = ({
  id,
  // inputAdornment = false,
  // inputAdornmentText = "",
  inputMaskPattern = "",
  field,
  testId,
  placeholder = "",
  defaultValue,
  uppercase = false,
  readOnly = false,
  onChangeHandler
}: IRHookFormMaskedTextFieldWithOnChange) => {
  const { field: { onChange, value }, formState: { errors } } = useController({
    name: id,
    defaultValue: defaultValue
  });

  // The destructuring of field prop preventing some TypeScript error with the MaskedInput field.
  // DO NOT REMOVE IT
  return (
    <MaskedInput
      {...field}
      id={id}
      onChange={onChangeHandler}
      variant="outlined"
      size="small"
      fullWidth={true}
      margin="dense"
      value={value}
      placeholder={placeholder}
      error={!!errors[id]}
      helperText={errors[id]?.message}
      mask={inputMaskPattern}
      unmask={true}
      lazy={true}
      sx={{
        backgroundColor: '#fff'
      }}
      inputProps={{
        "data-testid": testId + "-masked",
        style: {
          textTransform: uppercase ? "uppercase" : "none"
        },
        readOnly: readOnly,
      }}
      onAccept={(value:any, _mask:any) => onChange(value)}
    // InputProps={{
    //   startAdornment: inputAdornment && (
    //     <InputAdornment
    //       position="start"
    //       style={{color: grey[600]}}
    //       disablePointerEvents
    //       data-testid={testId + "-adornment"}
    //     >
    //       <Typography data-testid={testId + "-adornmentText"}>
    //         {inputAdornmentText}
    //       </Typography>
    //       <Typography component="span">|</Typography>
    //     </InputAdornment>
    //   ),
    // }}
    />
  );
};
