import { FieldWrapper } from "components/FieldWrapper/FieldWrapper";
import { FC } from "react";

import { Grid } from "@mui/material";
import { ITest, RHookFormTextFieldWithLabel } from "@websential/cosmic";

interface IQPPSettingsPanel extends ITest {
  selectedYear:number | string;
}

export const QPPSettingsPanel: FC<IQPPSettingsPanel> = ({ testId, selectedYear }) => {
  return (
    <Grid
      container
      columnSpacing="6"
      rowSpacing="2"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Total QPP Pensionable Earnings (Maximum)"
          id="qppSettingsTotalQppPensionableEarningsMaximum"
          testId={`${testId}-total-qpp-pensionable-earnings-maximum`}
        />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Basic QPP Exemption (Maximum)"
          id="qppSettingsBasicQppExemptionMaximum"
          testId={`${testId}-basic-qpp-exemption-maximum`}
        />
      </Grid>
      {selectedYear && selectedYear > 2023 ?
      <>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Total Second QPP Pensionable Earnings (Maximum)"
          id="qppSettingsTotalSecondQppPensionableEarningsMaximum"
          testId={`${testId}-total-second-qpp-pensionable-earnings-maximum`}
        />
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <RHookFormTextFieldWithLabel
          label="Second QPP Contribution Rate (%)"
          id="qppSettingsRequiredSecondContributionPercentage"
          testId={`${testId}-basic-second-qpp-exemption-maximum`}
        />
      </Grid></>
      :<></>}
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <FieldWrapper>
          <RHookFormTextFieldWithLabel
            label="Required Contribution (%)"
            id="qppSettingsRequiredContributionPercentage"
            testId={`${testId}-qpp-quebec-required-contribution-percentage`}
          />
        </FieldWrapper>
      </Grid>
      <Grid item md={2} lg={2} xl={2}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        {" "}
      </Grid>
    </Grid>
  );
};
