import { FC } from "react";

import {
  EnhancedDatePicker,
  EnhancedIOSSwitchRHFWithLabel,
  ITest,
  LabelPosition,
  RHookFormMaskedTextFieldWithLabel,
  RHookFormTextFieldWithLabel,
  SinNumberRHF,
} from "@websential/cosmic";

import { FieldWrapper } from "../../../FieldWrapper/FieldWrapper";
import { Box } from "@mui/material";

interface IEmployeeInfoSetupFieldsPanel extends ITest {
  onDateOfBirthChangeHandler: (selectedDate: any) => void;
}

export const EmployeeInfoSetupFieldsPanel: FC<IEmployeeInfoSetupFieldsPanel> =
  ({ onDateOfBirthChangeHandler, testId }) => {
    return (
      <>
        <FieldWrapper>
          <EnhancedIOSSwitchRHFWithLabel
            name="reflectMasterData"
            checked={false}
            testId="reflectInEmployee"
            label="Reflect in Employee"
            labelPosition={LabelPosition.Left}
          />
        </FieldWrapper>
        {/* <RHookFormTextFieldWithLabel
          label="Social Insurance"
          id="uniqueIdentifier"
          testId={`${testId}-social-insurance`}
          labelPosition={LabelPosition.Left}
          required
        /> */}
          <SinNumberRHF
            id="uniqueIdentifier"
            label="Social Insurance"
            required={true}
            labelPosition={LabelPosition.Left}
            labelColumns={4}
            fieldColumns={8}
            testId="uniqueIdentifier"
          />
        <RHookFormTextFieldWithLabel
          label="First Name"
          id="name1"
          testId={`${testId}-first-name`}
          labelPosition={LabelPosition.Left}
          required
        />
        {/* <RHookFormTextFieldWithLabel
          label="Initials"
          id="initials"
          testId={`${testId}-initials`}
          labelPosition={LabelPosition.Left}
        /> */}
        <RHookFormMaskedTextFieldWithLabel
            label="Initials"
            id="initials"
            testId={`${testId}-initials`}
            labelPosition={LabelPosition.Left}
            inputMaskPattern={"a"}
        />
        <RHookFormTextFieldWithLabel
          label="Last Name"
          id="name2"
          testId={`${testId}-last-name`}
          labelPosition={LabelPosition.Left}
          required
        />
        {/* <FieldWrapper>
          <EnhancedDatePicker
            name="dateOfBirth"
            onChange={onDateOfBirthChangeHandler}
            labelPosition={LabelPosition.Left}
            testId="date-of-birth"
            label="Date of Birth"
            // required
          />
        </FieldWrapper> */}
      </>
    );
  };
