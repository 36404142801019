import { FC } from "react";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { Toolbar } from "@mui/material";
import {
  DeleteButton,
  DisabledButton,
  ResetButton,
  SubmitButton,
} from "@websential/cosmic";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useParams } from "react-router-dom";

export interface IReourceSetupToolbar {
  onResetClicked?: () => void;
  onDeleteClicked: () => void;
  isSubmitting: boolean;
  resourceDataById?: any
}

export const ReourceSetupToolbar: FC<IReourceSetupToolbar> = ({
  onResetClicked,
  onDeleteClicked,
  isSubmitting = false,
  resourceDataById
}) => {
  const { resourceName } = useParams();

  const onchangeTooltip = () => {
    //it have to optional from cosmic then remove it 
  }
  return (
    <Toolbar >
      <>
        <SubmitButton
          label="Submit"
          testId="submit-button"
          loading={isSubmitting}
          icon={<PlaylistAddCheckOutlinedIcon />}
        />
        {/* <ResetButton testId="reset-button" onClickHandler={onResetClicked} /> */}
          {resourceDataById && resourceDataById.id ?
        (
          <DeleteButton
          testId="delete-button"
          onClickHandler={onDeleteClicked}
          />
        ):
        ( 
          <div style={{cursor:"not-allowed",marginLeft:"1rem"}}>
            <DisabledButton 
              tooltiptitle={""} 
              isDisabled={true}
              buttonLabel={"Delete"}
              icon={<DeleteOutlineIcon />}
              testId='disableButton' 
              onClick={onchangeTooltip} />
          </div>
          )
        } 
      </>
    </Toolbar>
  );
};
