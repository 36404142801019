import React from "react";

import { Grid, InputLabel, Tooltip, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { RHookFormTextField } from "components/TaxSlipDataInput/RHookFormTextField";
import { RHookFormNumericFormatTextField } from "components/TaxSlipDataInput/RHookFormNumericFormatTextField";
import { EnhancedTextAreaFieldRHF, LabelPosition } from "@websential/cosmic";

export interface ITaxSlipDataInput {
  id: string;
  label: string;
  boxNumber: string;
  showAdjustedInput?: boolean;
  alert?:boolean;
  alertTitle?:string;
  defaultValue?:string;
  titlePlacement?:any;
  readOnly?: boolean;
  onChange?: any;
  textfield?: any;
  optionalTextField?: any;
  notesField?: boolean;
}

export const TaxSlipDataInput: React.FC<ITaxSlipDataInput> = ({
  id,
  label,
  boxNumber,
  alert,
  alertTitle,
  titlePlacement,
  defaultValue,
  readOnly,
  onChange,
  textfield,
  optionalTextField,
  notesField
}) => {
  return (
    <Grid container columnSpacing={2} xs={12} sm={12} md={12} lg={12} xl={12}>
      {optionalTextField ? <>
      <Grid
        item
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
        sx={{ textAlign: "left", paddingTop: 2 }}
      >
        <InputLabel sx={{display:"flex"}}>
          <Typography variant="subtitle2">{label}</Typography>
          {alert === true ? (
              <Tooltip title={alertTitle} placement={titlePlacement}>
                  <InfoIcon sx={{fontSize:"1.1rem",margin:"1.5px"}}/>
              </Tooltip>
            ): undefined}
        </InputLabel>
      </Grid>
      <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
        {/* <RHookFormTextField
        id={`${id}`} testId={`${id}-1`} defaultValue={defaultValue}
        textAlign={'start'}
        /> */}
        <RHookFormTextField id={`${id}`} testId={`${id}-1`} defaultValue={defaultValue}
        readOnly={readOnly}
        onChange={onChange}
        textAlign={'start'}/>
      </Grid></>
      : notesField ? <>
      {/* <Grid
        item
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
        sx={{ textAlign: "left", paddingTop: 2 }}
      >
        <InputLabel sx={{display:"flex"}}>
          <Typography variant="subtitle2">{label}</Typography>
          {alert === true ? (
              <Tooltip title={alertTitle} placement={titlePlacement}>
                  <InfoIcon sx={{fontSize:"1.1rem",margin:"1.5px"}}/>
              </Tooltip>
            ): undefined}
        </InputLabel>
      </Grid> */}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{paddingY:2,"& .MuiGrid-grid-xs-7":{paddingLeft:"6px"}}}>
        {/* <RHookFormTextField
        id={`${id}`} testId={`${id}-1`} defaultValue={defaultValue}
        textAlign={'start'}
        /> */}
        {/* <RHookFormTextField id={`${id}`} testId={`${id}-1`} defaultValue={defaultValue}
        readOnly={readOnly}
        onChange={onChange}
        textAlign={'start'}/> */}
        <EnhancedTextAreaFieldRHF
            label={label}
            id={id}
            testId={`notes`}
            placeholder={label}
            labelPosition={LabelPosition.Left}
            labelColumns={5}
            fieldColumns={7}
        />
      </Grid></>
      :
      <><Grid
        item
        xs={8}
        sm={8}
        md={8}
        lg={8}
        xl={8}
        sx={{ textAlign: "left", paddingTop: 2 }}
      >
        <InputLabel sx={{display:"flex"}}>
          <Typography variant="subtitle2">{label}</Typography>
          {alert === true ? (
              <Tooltip title={alertTitle} placement={titlePlacement}>
                  <InfoIcon sx={{fontSize:"1.1rem",margin:"1.5px"}}/>
              </Tooltip>
            ): undefined}
        </InputLabel>
      </Grid>
      <Grid
        item
        xs={1}
        sm={1}
        md={1}
        lg={1}
        xl={1}
        sx={{ textAlign: "right", paddingTop: 2 }}
      >
        <InputLabel>
          <Typography variant="subtitle2">{boxNumber}</Typography>
        </InputLabel>
      </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        {/* <RHookFormTextField id={`${id}`} testId={`${id}-1`} defaultValue={defaultValue}
        readOnly={readOnly}
        onChange={onChange}
        textAlign={'end'}/> */}
        {textfield ? 
        <RHookFormTextField
        id={`${id}`} testId={`${id}-1`} defaultValue={defaultValue}
        readOnly={readOnly}
        onChange={onChange}
        textAlign={'end'}
        /> : 
        <RHookFormNumericFormatTextField
        id={id}
        readOnly={readOnly}
          testId={`${id}`}
          onChange={onChange}
          defaultValue={defaultValue}
          textAlign={'end'}
        />
        }
      </Grid></>}
    </Grid>
  );
};
