import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearSelectedAccountant, clearSelectedAccountantName, clearSelectedClient, clearSelectedClientName, clearSelectedINVOICE, clearSelectedTaxYear } from "store/actions";

export const AdminAccountantRoutesComponent = () => {
const navigate = useNavigate();
const dispatch = useDispatch();

const clearClientId = () => {
  dispatch(clearSelectedClient());
};
const clearClientName = () => {
  dispatch(clearSelectedClientName());
};
const clearAccountantId = () => {
  dispatch(clearSelectedAccountant());
};
const clearAccountantName = () => {
  dispatch(clearSelectedAccountantName());
};
const clearTaxYear = () => {
  dispatch(clearSelectedTaxYear());
};
const clearRefNo = () => {
  dispatch(clearSelectedINVOICE());
};
const ArrayData = [
  {
    label: 'Home',
    icon: <HomeOutlinedIcon />,
    items: [
      {
        label: 'Client List',
        command: () => {
            navigate("/accountants/clients")
            clearClientId();
            clearClientName();
            clearTaxYear();
            clearRefNo();
        },
      },
      {
        label: "Super User",
        command: () => {
            navigate("/accountants")
            clearClientId();
            clearClientName();
            clearAccountantId();
            clearAccountantName();
            clearTaxYear();
            clearRefNo();
        },
      },
      {
        label: "Global Search",
        command: () => {
            navigate("/global-search")
            clearClientId();
            clearClientName();
            clearAccountantId();
            clearAccountantName();
            clearTaxYear();
            clearRefNo();
        },
      },
    ],
  },
  {
    label: "Client Setup",
    icon: <ContactMailOutlinedIcon />,
        command: () => {
            navigate("/clients/setup")
        },
  },
  {
    icon: <ListAltOutlinedIcon />,
    label: "Adjustment Report",
        command: () => {
            navigate("/accountants/client-adjustment-report")
        },
  },
  {
    label: "Move Clients",
        command: () => {
            navigate("/accountants/move-clients")
        },
  },
//   {
//     label: "Logout",
//     icon: <LogoutOutlinedIcon />,
//     command: () => {
//         navigate("/logout")
//     },
//   },
];
return {
    AdminAccountantRoutes:ArrayData
  }
  }