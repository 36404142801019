import { ComponentState } from "common/enums";
import {
  IAccountantListColumnsResponse,
  IAccountantListPayload,
} from "models/accountant";
import { IClientListPayload } from "models/client";
import { IEmployeeListPayload } from "models/employee";
import { IUserProfile } from "models/IUserProfile";
import { IVendorListPayload } from "models/vendor";
import { combineReducers } from "redux";

import { Accountant, accountantReducer } from "./accountantReducers";
import { clientReducer, IClient } from "./clientReducers";
import loginReducer from "./loginReducers";
import { INavigation, navigationReducer } from "./navigationReducers";
import { ITaxYear, taxYearReducer } from "./taxYearReducers";
import { viewStateReducer } from "./viewStateReducer";
import { IInvoiceReport, invoiceReportReducer } from "./invoiceReportReducers";
import {
  ICSVInformationUI,
  CSVInformationUIReducer,
} from "./CSVInformationUIReducer";
import { homePageReducer, IHomePage } from "./homePageReducers";
import { drawerReducer, IDrawer } from "./drawerReducer";
import { activityLogReducer, IActivityLog } from "./activityLogReducers";
import {
  IIsLoadAxiosCall,
  isLoadAxiosCallReducer,
} from "./isLoadAxiosCallReducers";
import {
  IResourceApiMessage,
  resourceApiMessageReducer,
} from "./resourceAPIMessageReducer";
import { IStickyNavBar, stickyNavBarReducer } from "./stickyNavBarReducers";

export interface IAppState {
  user: {
    profile: IUserProfile;
    isAuthenticated: boolean;
    token: string;
  };
  viewState: {
    loading: boolean;
    error: boolean;
    success: boolean;
    message: string;
    componentState: ComponentState;
  };
  accountant: Accountant;
  client: IClient;
  navigation: INavigation;
  taxYear: ITaxYear;
  invoice: IInvoiceReport;
  CSVInformationUI: ICSVInformationUI;
  HomePagePath: IHomePage;
  drawerAction: IDrawer;
  activityLog: IActivityLog;
  isLoadAxiosCall: IIsLoadAxiosCall;
  resourceApiMessage: IResourceApiMessage;
  stickyNavBar: IStickyNavBar;
  }

const rootReducer = combineReducers<IAppState>({
  user: loginReducer,
  viewState: viewStateReducer,
  accountant: accountantReducer,
  client: clientReducer,
  navigation: navigationReducer,
  taxYear: taxYearReducer,
  invoice: invoiceReportReducer,
  CSVInformationUI: CSVInformationUIReducer,
  HomePagePath: homePageReducer,
  drawerAction: drawerReducer,
  activityLog: activityLogReducer,
  isLoadAxiosCall: isLoadAxiosCallReducer,
  resourceApiMessage: resourceApiMessageReducer,
  stickyNavBar: stickyNavBarReducer,
  });

export default rootReducer;
