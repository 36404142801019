import { checkSINValidity } from "components/T4Slip/T4SlipSetup/validator";
import * as yup from "yup";


export const checkIdentificationNumberValidity = (inputNumber: string | any): number => {
  let sum = 0;
  if(inputNumber){
  const factors = [4, 3, 2, 7, 6, 5, 4, 3, 2];

  // Calculate the sum of the product of each digit and its respective factor
  for (let i = 0; i < inputNumber.length; i++) {
    const digit = parseInt(inputNumber.charAt(i), 10);
    const factor = factors[i % factors.length];
    sum += digit * factor;
  }

  // Return the remainder of sum divided by 11
}
return sum % 11;
};

export const RL1SlipSettingValidation = yup.object().shape({
  transmitter: yup.string().required("Transmitter is required"),
  submissionMethod: yup.string().required("Submission Method is required!"),
  sourceOfRl1SlipsUsed: yup
    .string()
    .required("Source of RL-1 Slips Used is required!"),
  sequentialNumberStart: yup
    .string()
    .required("Start Number is required")
    .max(8, "Start Number has at most 8 Digits")
    .min(8, "Start Number cannot exceed 8 Digits"),
  // sequentialNumberEnd: yup.string().required("End Number is required").max(8, "End Number has at most 8 Digits").min(8, "End Number has at least 8 Digits"),
  sequentialNumberEnd: yup
    .string()
    .test(
      "greaterThanStartNumber",
      "End Number must be greater than Start Number",
      function (value) {
        const startValue = this.parent.sequentialNumberStart; // Access the value of fieldOne
        if (
          startValue &&
          value &&
          parseFloat(value) <= parseFloat(startValue)
        ) {
          return false; // Validation fails if end number is not greater than start number
        }
        return true; // Validation passes
      }
    )
    .test(
      "maxEndNumberLength",
      "Field Two must be between 8 and 8 characters",
      function (value) {
        if (value && (value.length < 8 || value.length > 8)) {
          return false;
        }
        return true;
      }
    )
    .required("End Number is required"),
  // identification: yup
  //   .string()
  //   .required("Identification Number is required")
  //   .min(14, "Identification Number has at least 16 Digits"),
  // quebecEnterprise: yup.string().max(10, "Quebec Enterprise  has at most 10 Digits").min(10, "Quebec Enterprise has at least 10 Digits"),
  quebecEnterprise:yup.string().max(10, 'Quebec Enterprise  has at most 10 Digits').nullable(),
  identification:yup.string().test(
    'is-valid-or-not',
    'Invalid value',
    function (value:any) {
      let maxLength = 14;
      console.log("value456",value)
      const firstNineDigits: string = value && value.substring(0, 9);
      const tenthDigit = value && parseInt(value.charAt(9), 10);
      console.log("valueeeee-- bn",firstNineDigits)
      console.log("valueeeee-- bn",tenthDigit)
      const remainder = checkIdentificationNumberValidity(firstNineDigits);
      console.log("output bn",remainder)

      if (!value || value.length !== maxLength) {
          return this.createError({
            message: 'Invalid Identification Number',
            path: this.path,
          });
        }
      
        if (
          (remainder === 0 && tenthDigit === 1) ||
          (remainder === 1 && tenthDigit === 0) ||
          (remainder !== 0 && remainder !== 1 && 11 - remainder === tenthDigit)
        ) {
          return true;
        } else {
          return this.createError({
            message: 'Invalid Identification Number',
            path: this.path,
          });
        }
    }
  ).nullable().required('Identification Number is required'),
});
