import {
  INITIAL_SNACKBAR_DATA,
  QUERY_OPTIONS,
  T4A_SUMMARY_SCREEN_ID,
  T4A_SUMMARY_SCREEN_SLUG,
} from "common/constants";
import { instanceOfIFlexFieldSetupRequest } from "common/instance-method";
import { TaxSlipAccountInformation } from "components/TaxSlipAccountInformation";
import { TaxSlipFilingResourceSelectionPanel } from "components/TaxSlipFilingResource";
import { IResponse, IServerError } from "models/common";
import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FlexFieldService } from "services/FlexFieldService";

import FileSaver from "file-saver";
import { yupResolver } from "@hookform/resolvers/yup";
// import { Divider, Paper } from "@mui/material";
import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  FlatStandardPanel,
  ITest,
  SingleColumnLayout,
  Spacer,
} from "@websential/cosmic";

import { T4ASummaryToolbar } from "../T4ASummaryToolbar";
import {
  CompanyDetailPanel,
  DifferencePanel,
  T4ASlipDetailPanel,
  TotalBalancePanel,
} from "./Panels";
import { getValidationSchema } from "./validator";
import { T4ASlipService } from "services/T4ASlipService";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { T4SummarySetupSchema } from "components/T4Slip/T4SlipSummarySetup/summaryValidate";
import {
  EnhancedDialog,
  EnhancedLinkButton,
  IconTextButton,
  RouterLinkButton,
  SplitButton,
} from "@websential/cosmic";
import { ReportsService } from "services/ReportsService";
import { setSelectedINVOICE } from "store/actions";
import { TaxSlipSummaryDataInput } from "components/TaxSlipSummaryDataInput";
import {
  Box,
  Grid,
  InputLabel,
  Paper,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import {
  EnhancedDivider,
  EnhancedDropDownRHF,
  EnhancedDropDownRHFWithLabel,
  EnhancedTextAreaFieldRHF,
  LabelPosition,
  RequiredAsterisk,
  RHookFormTextField,
} from "@websential/cosmic";
import { T4SlipService } from "services/T4SlipService";
import { PrintingService } from "services/PrintingService";
import { InvoiceDialogPanel } from "components/T4Slip/T4SlipList/InvoiceDialogPanel";
import { checkSummaryType } from "components/T4Slip";
import { AdjustmentOptionsService } from "services/AdjustmentOptionsService";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { T4ASummarySetupSchema } from "./t4aSummaryValidation";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { ClientService } from "services";
export let selectedT4ASummaryType: any = 400;
export interface IT4ASlipSummarySetup extends ITest {}

const DEFAULT_VALUES = {
  // comparison: [
  //   {
  //     operator: undefined,
  //     field: undefined,
  //   },
  // ],
  // fieldType: undefined,
  // fieldLabel: "",
  // fieldId: "",
  // defaultValue: "",
  // placeholder: "",
  // fieldFormat: "",
  // fieldValue: "",
  // dataType: undefined,
  // fieldColumn: undefined,
  // parentRelation: undefined,
  // fieldInternalValue: "",
  // notes: "",
  // minValue: 0,
  // maxValue: 0,
  // isCategory: false,
  // isFixed: false,
  // isHide: false,
  // isRequired: false,
  // parentValue: "",
  // parent: undefined,
  // id: undefined,
};

const T4A_SUMMARY_DATA_QUERY_KEY = "get-t4a-summary-data";
// const LIST_T4A_SUMMARY_QUERY_KEY = "get-t4a-summary-list";
// const T4A_SUMMARY_DATA_QUERY_KEY = "get-t4-summary-data";

const useGetT4ASummaryData = (
  clientid: string,
  slipType: string | number,
  taxYear: number
) =>
  useQuery(
    [T4A_SUMMARY_DATA_QUERY_KEY, clientid, slipType, taxYear],
    (data) => {
      return T4ASlipService.getT4ASummary(clientid, slipType, taxYear);
    },
    { refetchOnWindowFocus: false }
  );

// const useGetFlexFieldData = (flexFieldId: number) =>
//   useQuery(
//     [T4A_SUMMARY_DATA_QUERY_KEY, flexFieldId],
//     () => FlexFieldService.getFlexFieldById(flexFieldId),
//     { ...QUERY_OPTIONS, enabled: !!flexFieldId }
//   );
// const useGetFlexFieldsList = () =>
//   useQuery(
//     LIST_T4A_SUMMARY_QUERY_KEY,
//     () => FlexFieldService.getFlexFieldDropDownList("none"),
//     QUERY_OPTIONS
//   );

const useGetTransmitterDDL = (clientId: string, taxYear: number) =>
  useQuery(
    ["transmitters"],
    () => {
      return T4SlipService.getAllActiveTransmittersByClientIdAndTaxYear(
        clientId,
        taxYear
      );
    },
    { refetchOnWindowFocus: false }
  );

const useGetIssuerDDL = (clientId: string, taxYear: number) =>
  useQuery(
    ["Issuers"],
    () => {
      return T4SlipService.getAllActiveIssuersByClientIdAndTaxYear(
        clientId,
        taxYear
      );
    },
    { refetchOnWindowFocus: false }
  );
const useGetContactPersonDDL = (clientId: string, taxYear: number) =>
  useQuery(
    ["contact-person"],
    () => {
      return T4SlipService.getAllActiveContactPersonsByClientIdAndTaxYear(
        clientId,
        taxYear
      );
    },
    { refetchOnWindowFocus: false }
  );

  const useGetSlipIds = (
    tag: string,
    clientId: string,
    taxYear: number
  ) =>
    useQuery(
      ["get-slip-ids", tag, clientId, taxYear],
      (data) => {
        return T4SlipService.getSlipIds(tag, clientId, taxYear);
      },
      { refetchOnWindowFocus: false }
    );

    const useGetCompanyData = (id: string | undefined) => 
      useQuery(
        ["client-get-by-id", id],
        (data) => {
          return ClientService.getById(id);
        },
        { refetchOnWindowFocus: false }
      );

export const T4ASlipSummarySetup: FC<IT4ASlipSummarySetup> = ({ testId }) => {
  const [snackbarData, setSnackbarData] = useState<any>(INITIAL_SNACKBAR_DATA);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const id = useSelector((state: IAppState) => state.client.id);
  const clientName = useSelector((state: IAppState) => state.client.clientName);
  const isLoadingActivityLogData = useSelector(
    (state: IAppState) => state.isLoadAxiosCall.isLoading
  );
  const [invoicePaymentFor, setInvoicePaymentFor] = useState(10)

  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);

  const [selectedType, setSelectedType] = useState(400);
  const [selectedIssuer, setSelectedIssuer] = useState("");
  const [selectedContactPerson, setSelectedContactPerson] = useState("");
  const [isLoadingIssuerSelect, setIsLoadingIssuerSelect] = useState(false);
  const [openInvoiceDialog, setInvoiceDialogOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const maskedStatus: any = sessionStorage.getItem("maskEnabled");

  const { isLoading: isSubmitting, mutate: submitFormData } = useMutation(
    T4ASlipService.createSummary,
    {
      onSuccess: (
        res: IFlexFieldSetupRequest | IServerError | IResponse | any
      ) => {
        // if (instanceOfIFlexFieldSetupRequest(res)) {
        //   // remove the cache of the flex fields since we just added a new field.
        //   queryClient.invalidateQueries(LIST_T4A_SUMMARY_QUERY_KEY);
        //   // [corner case] remove the cache of the field that has just been edited in-case the user re-selects it again
        //   queryClient.invalidateQueries([T4A_SUMMARY_DATA_QUERY_KEY, res.id]);
        //   // set the currently added/edited flex field as the selected field
        //   formMethods.setValue("id", res.id);
        console.log("res", res);
        if (res.status === 201 || res.status === 200) {
          setSnackbarData({
            open: true,
            message: "Data has been submitted successfully!",
            severity: "success",
          });
        } else {
          if (res) {
            setSnackbarData({
              open: true,
              message:
                res &&
                res.message !== undefined &&
                typeof (res.message && res.message) !== "object"
                  ? res.message && res.message
                  : res.message.message
                  ? res.message.message
                  : "Something went wrong!",
              severity:
                res.status === false
                  ? "error"
                  : res.success === false
                  ? "error"
                  : "success",
            });
          }
        }
      },
      onError: (err) => {
        console.log("err34", err);
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );
  // const { isLoading: isLoadingFlexFieldsList, data: flexFieldsList } =
  //   useGetFlexFieldsList();
  // const validationSchema = useMemo(
  //   () =>
  //     getValidationSchema(
  //       flexFieldsList && Array.isArray(flexFieldsList)
  //         ? flexFieldsList.map((flexField: any) => ({
  //             id: flexField.id,
  //             value: flexField.fieldId,
  //           }))
  //         : []
  //     ),
  //   [flexFieldsList]
  // );
  const formMethods = useForm<any>({
    resolver: yupResolver(T4ASummarySetupSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  // const [selectedFlexFieldId, selectedParent] = useWatch({
  //   control: formMethods.control,
  //   name: ["id", "parent"],
  // });
  // const { isLoading: isLoadingSelectedFlexField, data: selectedFlexFieldData } =
  //   useGetFlexFieldData(selectedFlexFieldId as number);

  const {
    isLoading: isLoadingSummaryGetData,
    isFetching: reFetchingSlipType,
    refetch: refetchSlipType,
    data: t4ASummaryData,
  } = useGetT4ASummaryData(id, selectedType, taxYear);
  console.log("selectedType", selectedType);

  console.log("t4ASummaryData ============", t4ASummaryData);

  /**
   * Handle when the form is submitted.
   *
   * @param {IFlexFieldSetupRequest} formData
   */
  // const onSubmit = (formData: IFlexFieldSetupRequest) => {
  //   submitFlexFields(formData);
  // };
  const onSubmit = (formData: any) => {
    let Payload: any = {
      id: id,
      taxYear: taxYear,
      remittance:
        formData.remittance === "0.00"
          ? undefined
          : formData.remittance === ""
          ? undefined
          : parseInt(formData.remittance.replace(/[$,]/g, "")),
      slipType: selectedType,
      registrationNumbersForRppOrDscp071:
        formData.registrationNumbersForRppOrDscp071 === "0.00" ? undefined : 
        formData.registrationNumbersForRppOrDscp071 === ""
          ? undefined
          : formData.registrationNumbersForRppOrDscp071 &&
            parseFloat(
              formData.registrationNumbersForRppOrDscp071.replace(/[$,]/g, "")
            ),
      registrationNumbersForRppOrDscp072:
        formData.registrationNumbersForRppOrDscp072 === "0.00"  ? undefined : 
        formData.registrationNumbersForRppOrDscp072 === ""
          ? undefined
          : formData.registrationNumbersForRppOrDscp072 &&
            parseFloat(
              formData.registrationNumbersForRppOrDscp072.replace(/[$,]/g, "")
            ),
      registrationNumbersForRppOrDscp073:
        formData.registrationNumbersForRppOrDscp073 === "0.00"  ? undefined : 
        formData.registrationNumbersForRppOrDscp073 === ""
          ? undefined
          : formData.registrationNumbersForRppOrDscp073 &&
            parseFloat(
              formData.registrationNumbersForRppOrDscp073.replace(/[$,]/g, "")
            ),
      confirmationCode:
        formData.confirmationCode !== ""
          ? formData.confirmationCode
          : undefined,
      notes: formData.notes !== "" ? formData.notes : undefined,
      transmitter: formData.valueOfTransmitter,
      issuer: formData.valueOfIssuer,
      contactPerson: formData.valueOfContactPerson,
    };
    // submitFlexFields(formData);
    submitFormData(Payload);
    console.log("formData ========== ", formData);
  };

  const onCloseHandler = () => {
    setInvoiceDialogOpen(false);
  };

  const saveXmlAsFile = (xmlData: any, filename: any) => {
    const blob = new Blob([xmlData], { type: "xml;charset=utf-8" });
    FileSaver.saveAs(blob, filename);
  };
  const onGenerateXMLClicked = async (): Promise<void> => {
    setIsLoadingData(true);
    if (maskedStatus === "true") {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    }else if (
      t4ASummaryData &&
      t4ASummaryData.transmitter === null &&
      t4ASummaryData.issuer === null &&
      t4ASummaryData.contactPerson === null
    ) {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message:
          "Please select and submit transmitter, issuer and contact person.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(t4aSlipIds && t4aSlipIds.generalAuditStatus !== null && t4aSlipIds.generalAuditStatus === true &&
              t4aSlipIds && t4aSlipIds.auditStatus !== null &&  t4aSlipIds.auditStatus === true){
              setIsLoadingData(true);
                try {
                  const response: any = await PrintingService.createT4AXml(
                    clientId,
                    selectedType,
                    taxYear
                  );
                  if (response.status === 400) {
                    setSnackbarData({
                      open: true,
                      message: response.data.message
                        ? response.data.message
                        : "Something went wrong.",
                      severity: "error",
                    });
                  } else {
                    const currentXmlType = formMethods.getValues("slipType");
                    const filename = `T4Axml-${clientName}-${currentXmlType}.xml`;
                    saveXmlAsFile(response, filename);
                  }
                  setIsLoadingData(false);
                } catch (err) {
                  console.log("err", err);
                  alert(err);
                }
              // }
              setIsLoadingData(false);
              } else {
                setIsLoadingData(false);
                setSnackbarData({
                open: true,
                message: (
                  <>
                    Please load Audit in the menu, press Run T4A Audit and clear all audit items to generate documents.{" "}
                    <a
                      href="https://appv3.taxslips.com/#/audit/filing"
                      style={{
                        color: "#aa1010",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to view
                    </a>
                  </>
                ),
                severity: "error",
                autoHide: false,
              });
            }

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (response.message === "Slips not found for invoice.") {
          // setIsLoadingData(true);
          // try {
          //   const filingAuditResponse: any =
          //     await T4ASlipService.getT4AAuditFiling(clientId, taxYear);
          //   console.log("filingAuditResponse", filingAuditResponse);
          //   if (
          //     filingAuditResponse &&
          //     filingAuditResponse.general_filling &&
          //     filingAuditResponse.general_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.general_filling",
          //       filingAuditResponse.general_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else if (
          //     filingAuditResponse &&
          //     filingAuditResponse.t4a_filling &&
          //     filingAuditResponse.t4a_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.t4a_filling",
          //       filingAuditResponse.t4a_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else {
          //     setIsLoadingData(true);
          if(t4aSlipIds && t4aSlipIds.generalAuditStatus !== null && t4aSlipIds.generalAuditStatus === true &&
            t4aSlipIds && t4aSlipIds.auditStatus !== null &&  t4aSlipIds.auditStatus === true){
            setIsLoadingData(true);
              try {
                const response: any = await PrintingService.createT4AXml(
                  clientId,
                  selectedType,
                  taxYear
                );
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    severity: "error",
                  });
                } else {
                  const currentXmlType = formMethods.getValues("slipType");
                  const filename = `T4Axml-${clientName}-${currentXmlType}.xml`;
                  saveXmlAsFile(response, filename);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
                alert(err);
              }
            // }
            setIsLoadingData(false);
            } else {
              setIsLoadingData(false);
              setSnackbarData({
              open: true,
              message: (
                <>
                  Please load Audit in the menu, press Run T4A Audit and clear all audit items to generate documents.{" "}
                  <a
                    href="https://appv3.taxslips.com/#/audit/filing"
                    style={{
                      color: "#aa1010",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Click here to view
                  </a>
                </>
              ),
              severity: "error",
              autoHide: false,
            });
          }
          // } catch (err: any) {
          //   setIsLoadingData(false);
          //   alert(err);
          // }
        } else if (response && response.id !== null) {
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    }
    }
  };
  const savePdfAsFile = (pdfData: any, filename: any) => {
    const blob = new Blob([pdfData], { type: "pdf;charset=utf-8" });
    FileSaver.saveAs(blob, filename);
  };
  const onPrintClicked = async (): Promise<void> => {
    setIsLoadingData(true);
    if (maskedStatus === "true") {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message: "Please unmask your data.",
        severity: "error",
      });
    } else if (
      t4ASummaryData &&
      t4ASummaryData.transmitter === null &&
      t4ASummaryData.issuer === null &&
      t4ASummaryData.contactPerson === null
    ) {
      setIsLoadingData(false);
      setSnackbarData({
        open: true,
        message:
          "Please select and submit transmitter, issuer and contact person.",
        severity: "error",
      });
    } else {
      if(invoicePaymentFor === 10){
        try {
          const previousYearInvoiceResponse: any = await ReportsService.getPreviousYearInvoice(
            clientId,
            taxYear-1
          );
        try {
          const response: any = await ReportsService.createInvoice(
            clientId,
            taxYear
          );
          setIsLoadingData(false);
          console.log("responseresponse", response);
          if (previousYearInvoiceResponse.message === "Slips not found for invoice." && response.message === "Slips not found for invoice.") {
            if(t4aSlipIds && t4aSlipIds.generalAuditStatus !== null && t4aSlipIds.generalAuditStatus === true &&
              t4aSlipIds && t4aSlipIds.auditStatus !== null &&  t4aSlipIds.auditStatus === true){
              setIsLoadingData(true);
                try {
                  const response: any =
                    await PrintingService.createT4ASummaryPdfPrint(
                      clientId,
                      selectedType,
                      taxYear
                    );
                  if (response.status === 400) {
                    setSnackbarData({
                      open: true,
                      message: response.data.message
                        ? response.data.message
                        : "Something went wrong.",
                      severity: "error",
                    });
                  } else {
                    const currentXmlType = formMethods.getValues("slipType");
                    const filename = `T4ASummary-${clientName}-${currentXmlType}.pdf`;
                    savePdfAsFile(response, filename);
                  }
                  setIsLoadingData(false);
                } catch (err) {
                  console.log("err", err);
                  alert(err);
                }
              // }
              setIsLoadingData(false);
              } else {
                setIsLoadingData(false);
                setSnackbarData({
                open: true,
                message: (
                  <>
                    Please load Audit in the menu, press Run T4A Audit and clear all audit items to generate documents.{" "}
                    <a
                      href="https://appv3.taxslips.com/#/audit/filing"
                      style={{
                        color: "#aa1010",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to view
                    </a>
                  </>
                ),
                severity: "error",
                autoHide: false,
              });
            } 

          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.id != null
          ) {
            console.log("one");
            setInvoiceData([previousYearInvoiceResponse, response]);
            setInvoiceDialogOpen(true);
          } else if (
            previousYearInvoiceResponse?.id != null &&
            response?.message === "Slips not found for invoice."
          ) {
            console.log("two");
            setInvoiceData([previousYearInvoiceResponse]);
            setInvoiceDialogOpen(true);
          } else if (
            response?.id != null &&
            previousYearInvoiceResponse?.message === "Slips not found for invoice."
          ) {
            console.log("three");
            setInvoiceData([response]);
            setInvoiceDialogOpen(true);
          }else {
            setSnackbarData({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
          }
          setIsLoadingData(false);
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
        } catch (err) {
          console.log("err", err);
          setIsLoadingData(false);
        }
      } else {
      try {
        const response: any = await ReportsService.createInvoice(
          clientId,
          taxYear
        );
        setIsLoadingData(false);
        console.log("responseresponse", response);
        if (response.message === "Slips not found for invoice.") {
          // setIsLoadingData(true);
          // try {
          //   const filingAuditResponse: any =
          //     await T4ASlipService.getT4AAuditFiling(clientId, taxYear);
          //   console.log("filingAuditResponse", filingAuditResponse);
          //   if (
          //     filingAuditResponse &&
          //     filingAuditResponse.general_filling &&
          //     filingAuditResponse.general_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.general_filling",
          //       filingAuditResponse.general_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else if (
          //     filingAuditResponse &&
          //     filingAuditResponse.t4a_filling &&
          //     filingAuditResponse.t4a_filling.length > 0
          //   ) {
          //     console.log(
          //       "filingAuditResponse.t4a_filling",
          //       filingAuditResponse.t4a_filling
          //     );
          //     setSnackbarData({
          //       open: true,
          //       message: (
          //         <>
          //           Please load Audit in the menu and clear all audit items
          //           (Audit Filing and PIER Review) to generate documents.{" "}
          //           <a
          //             href="https://appv3.taxslips.com/#/audit/filing"
          //             style={{
          //               color: "#aa1010",
          //               textDecoration: "none",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             Click here to view
          //           </a>
          //         </>
          //       ),
          //       severity: "error",
          //       autoHide: false,
          //     });
          //   } else {
          //     setIsLoadingData(true);
          if(t4aSlipIds && t4aSlipIds.generalAuditStatus !== null && t4aSlipIds.generalAuditStatus === true &&
            t4aSlipIds && t4aSlipIds.auditStatus !== null &&  t4aSlipIds.auditStatus === true){
            setIsLoadingData(true);
              try {
                const response: any =
                  await PrintingService.createT4ASummaryPdfPrint(
                    clientId,
                    selectedType,
                    taxYear
                  );
                if (response.status === 400) {
                  setSnackbarData({
                    open: true,
                    message: response.data.message
                      ? response.data.message
                      : "Something went wrong.",
                    severity: "error",
                  });
                } else {
                  const currentXmlType = formMethods.getValues("slipType");
                  const filename = `T4ASummary-${clientName}-${currentXmlType}.pdf`;
                  savePdfAsFile(response, filename);
                }
                setIsLoadingData(false);
              } catch (err) {
                console.log("err", err);
                alert(err);
              }
            // }
            setIsLoadingData(false);
            } else {
              setIsLoadingData(false);
              setSnackbarData({
              open: true,
              message: (
                <>
                  Please load Audit in the menu, press Run T4A Audit and clear all audit items to generate documents.{" "}
                  <a
                    href="https://appv3.taxslips.com/#/audit/filing"
                    style={{
                      color: "#aa1010",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Click here to view
                  </a>
                </>
              ),
              severity: "error",
              autoHide: false,
            });
          } 
          // } catch (err: any) {
          //   setIsLoadingData(false);
          //   alert(err);
          // }
        } else if (response && response.id !== null) {
          setInvoiceData([response]);
          setInvoiceDialogOpen(true);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
        }
        setIsLoadingData(false);
      } catch (err) {
        console.log("err", err);
        setIsLoadingData(false);
      }
    }
    }
  };

  const onDeleteClicked = async (): Promise<void> => {
    //delete confirmation code
    try {
      setIsLoadingData(true);
      const response: any =
        await T4ASlipService.t4aSummaryclearConfirmationCode(
          clientId,
          checkSummaryType(t4ASummaryData && t4ASummaryData.slipType),
          taxYear
        );
      console.log("response64", response);
      if (response.status === 200) {
        setSnackbarData({
          open: true,
          message: response.data.message,
          severity: "success",
        });
        refetchSlipType();
      } else {
        setSnackbarData({
          open: true,
          message:
            response &&
            response.message !== undefined &&
            typeof (response.message && response.message) !== "object"
              ? response.message && response.message
              : response.message.message
              ? response.message.message
              : "Something went wrong!",
          severity:
            response.status === false
              ? "error"
              : response.success === false
              ? "error"
              : "success",
        });
      }
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log("err", err);
    }
  };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  // set the values of the selected flex field coming from BE.
  // useEffect(() => {
  //   // if the selected flex field data has loaded.
  //   if (selectedFlexFieldData) {
  //     Object.keys(selectedFlexFieldData).forEach((fieldName) => {
  //       // @ts-ignore
  //       const fieldValue = selectedFlexFieldData[fieldName];
  //       // @ts-ignore
  //       formMethods.setValue(fieldName, fieldValue);
  //     });
  //   }
  // }, [selectedFlexFieldData, formMethods.setValue, formMethods]);

  // // if we have selected the parent and a flex field and they both are the same, we should un-set the parent because a field cannot be the parent of itself
  // useEffect(() => {
  //   if (
  //     selectedFlexFieldId &&
  //     selectedParent &&
  //     selectedParent === selectedFlexFieldId
  //   ) {
  //     formMethods.setValue("parent", undefined);
  //   }
  // }, [formMethods, selectedFlexFieldId, selectedParent]);

  useEffect(() => {
    console.log("===-use Effect called");
    // if the selected flex field data has loaded.
    if (t4ASummaryData) {
      Object.keys(t4ASummaryData).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = t4ASummaryData[fieldName];
        // @ts-ignore
        formMethods.setValue(fieldName, fieldValue ?? undefined);
        //set all values to desimal
        if (fieldName === "pensionOrSuperannuation") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "lumpSumPayments") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "selfEmployedCommissions") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "annuities") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "otherIncome") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "patronageAllocations") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "registeredPensionPlanRppContributionsPastService") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "pensionAdjustment") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "respAccumulatedIncomePayments") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "respEducationalAssistancePayments") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "fees") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "incomeTaxDeducted") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "difference") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "overpayment") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "balanceDue") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "remittance") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "amountEnclosed") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "otherInformation") {
          fieldValue !== null || undefined
            ? formMethods.setValue(fieldName, parseFloat(fieldValue).toFixed(2))
            : formMethods.setValue(fieldName, "0.00");
        }
        if (fieldName === "transmitter") {
          formMethods.setValue("valueOfTransmitter", fieldValue);
        }
        if (fieldName === "issuer") {
          formMethods.setValue("valueOfIssuer", fieldValue);
        }
        if (fieldName === "contactPerson") {
          formMethods.setValue("valueOfContactPerson", fieldValue);
        }
        if (fieldName === "ownerSin1") {
          formMethods.setValue(
            fieldName,
            fieldValue
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
              : undefined
          );
        }
        if (fieldName === "ownerSin2") {
          formMethods.setValue(
            fieldName,
            fieldValue
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
              : undefined
          );
        }
        if (fieldName === "phone1") {
          formMethods.setValue(
            fieldName,
            fieldValue
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
              : undefined
          );
        }
      });
    }
  }, [t4ASummaryData, formMethods.setValue, formMethods]);

  useEffect(() => {
    if (selectedType) {
      refetchSlipType();
    }
  }, [refetchSlipType, selectedType]);

  const onSlipTypeSelectionChangeHandler = (e: any) => {
    setSelectedType(e.value);

    const selectedType = e.value;
    selectedT4ASummaryType = selectedType;
  };

  const { isLoading: isLoadingTransmitterDDL, data: transmitterDDL } =
    useGetTransmitterDDL(id, taxYear);
  console.log("transmitterDDL", transmitterDDL);

  const { isLoading: isLoadingIssuerDDL, data: issuerDDL } = useGetIssuerDDL(
    id,
    taxYear
  );
  console.log("transmitterDDL", issuerDDL);

  const { isLoading: isLoadingContactPersonDDL, data: contactPersonDDL } =
    useGetContactPersonDDL(id, taxYear);
  console.log("transmitterDDL", contactPersonDDL);

  const { isLoading:loadingSlipIds, data:t4aSlipIds } = useGetSlipIds("T4A", clientId, taxYear);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(clientId);

  useEffect(()=>{
    console.log("companyGetByIdRecord11",companyGetByIdRecord)
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentFor = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'invoice_payment_for')
      console.log("companyGetByIdRecord",paymentFor)
      setInvoicePaymentFor(paymentFor ? parseInt(paymentFor.value) : 10)
    }
  },[companyGetByIdRecord])

  //when isssuer selection chanhe sin1 abd sin 2 would be updated by using get by id of filing ewsource
  const onIssuerSelectionChangeHandler = async (
    selectedItem: any
  ): Promise<void> => {
    console.log("selectedItem.value", selectedItem.value);

    try {
      setIsLoadingIssuerSelect(true);
      setTimeout(() => {
        setIsLoadingIssuerSelect(false);
      }, 1000);
      const response = await T4SlipService.getIssuerDataById(
        selectedItem.value,
        id
      );

      console.log("responseData", response);
      setSelectedIssuer(response);
      // setSelectedResourceId(String(response.resourceId));
    } catch (err) {
      console.log("err", err);
      // alert(err);
    }
  };
  //update value of sin1 and sin 2
  useEffect(() => {
    if (selectedIssuer) {
      Object.keys(selectedIssuer).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = selectedIssuer[fieldName];
        // @ts-ignore
        if (fieldName === "ownerSin1") {
          formMethods.setValue(
            fieldName,
            fieldValue
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
              : undefined
          );
        }
        if (fieldName === "ownerSin2") {
          formMethods.setValue(
            fieldName,
            fieldValue
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3")
              : undefined
          );
        }
      });
    }
  }, [selectedIssuer, formMethods.setValue, formMethods]);

  //when Contact Person selection chanhe sin1 abd sin 2 would be updated by using get by id of filing ewsource
  const onContactPersonSelectionChangeHandler = async (
    selectedItem: any
  ): Promise<void> => {
    console.log("selectedItem.value", selectedItem.value);

    try {
      setIsLoadingIssuerSelect(true);
      setTimeout(() => {
        setIsLoadingIssuerSelect(false);
      }, 1000);
      const response = await T4SlipService.getContactPersonDataById(
        selectedItem.value,
        id
      );

      console.log("responseData Contact Person", response);
      setSelectedContactPerson(response);
      // setSelectedResourceId(String(response.resourceId));
    } catch (err) {
      console.log("err", err);
      // alert(err);
    }
  };
  //update value of sin1 and sin 2
  useEffect(() => {
    if (selectedContactPerson) {
      Object.keys(selectedContactPerson).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = selectedContactPerson[fieldName];
        // @ts-ignore
        if (fieldName === "phone1") {
          formMethods.setValue(
            fieldName,
            fieldValue
              ? fieldValue.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
              : undefined
          );
        }
        // if(fieldName === 'ownerSin2'){
        //    formMethods.setValue(fieldName, fieldValue ?? undefined);
        // }
      });
    }
  }, [selectedContactPerson, formMethods.setValue, formMethods]);

  const onUnPaidClickHandler = (clickedRow: any, tag:string) => {
    setIsLoadingData(true);
    // setTimeout(() => {
    //   setIsLoadingData(false);
    // }, 2500);
    const fetchData = async () => {
      try {
        const response: any =
          await ReportsService.paypalIntegrationOfUnpaidInvoice(
            clientId,
            clickedRow.refNo,
            tag
          );
        setIsLoadingData(false);
        console.log("responseData111-----------------", response);
        if(tag === 'paypal'){
          window.open(response, '_blank');
          } else {
            window.open(response.message, '_blank');
          }
        } catch (error) {
          setIsLoadingData(false);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingData(false);
        }
    }
    fetchData();
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  const onClickInvoiceNoHandler = (clickedRow: any) => {
    dispatch(setSelectedINVOICE(clickedRow));
    console.log("clickedRowclickedRowclickedRow", clickedRow);
  };

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: T4A_SUMMARY_SCREEN_SLUG,
        companyName: clientName,
        activityType:
          "activity_type=updated&activity_type=delete&activity_type=print&activity_type=print_xml",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({ isSticky: true }));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={
          // isLoadingSelectedFlexField ||
          isSubmitting ||
          isLoadingSummaryGetData ||
          reFetchingSlipType ||
          // isLoadingFlexFieldsList ||
          // isLoadingselectedSlipType
          isLoadingTransmitterDDL ||
          isLoadingIssuerDDL ||
          isLoadingContactPersonDDL ||
          isLoadingIssuerSelect ||
          isLoadingData ||
          isLoadingActivityLogData
        }
        testId={testId}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            toolbar={
              <T4ASummaryToolbar
                isSubmitting={isSubmitting}
                onGenerateXMLClicked={onGenerateXMLClicked}
                onPrintClicked={onPrintClicked}
                onDeleteClicked={onDeleteClicked}
              />
            }
            testId="t4-slip-summary-setup-form"
          >
            <Paper>
              <FlatStandardPanel
                title="Account Information"
                testId={`${testId}-account-information-panel`}
                showTitle={false}
                topPadding={3}
                leftSpacing={2}
                rightSpacing={2}
              >
                <TaxSlipAccountInformation
                  logoURL={`${process.env.PUBLIC_URL}/images/cra_logo.png`}
                  accountIdentifierTitle="Payer's account number"
                  accountIdentifier="121212120RP0001"
                  accountInformationMessage="You have to file your T4A information return on or before the last day of February."
                  accountName="HELLOFRESH"
                  accountAddressLine1="House # 34, Street twenty three Link Road Cavalary Ground"
                  accountCity=" Toronto"
                  accountProvince="ON"
                  accountPostalCode="W3R4T5"
                  companyInformation={t4ASummaryData && t4ASummaryData}
                />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="T4A Slips Detail"
                testId={`${testId}-t4-slip-detial-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <T4ASlipDetailPanel formMethods={formMethods} />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="Difference"
                testId={`${testId}-difference-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <DifferencePanel />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="Total Balance"
                testId={`${testId}-total-balance-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <TotalBalancePanel />
              </FlatStandardPanel>
              <Divider
                component="hr"
                sx={{ width: "68%", marginLeft: "16%", marginRight: "16%" }}
              />
              <FlatStandardPanel
                title="Statutory Contributions"
                testId={`${testId}-statutory-contributions-panel`}
                leftSpacing={2}
                rightSpacing={2}
              >
                <CompanyDetailPanel />
              </FlatStandardPanel>
              {/* <TaxSlipFilingResourceSelectionPanel /> */}

              <Grid container>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                  <Paper variant="outlined">
                    <FlatStandardPanel
                      title="Select Resources"
                      testId="company-persons-panel"
                      // leftSpacing={2}
                      // rightSpacing={2}
                    >
                      <EnhancedDropDownRHFWithLabel
                        selectName="valueOfTransmitter"
                        label="Transmitter"
                        labelPosition={LabelPosition.Left}
                        items={
                          transmitterDDL && transmitterDDL.message
                            ? []
                            : transmitterDDL
                            ? transmitterDDL
                            : []
                        }
                        testId="transmitter"
                        // required
                      />
                      <EnhancedDropDownRHFWithLabel
                        selectName="slipType"
                        label="XML Type"
                        labelPosition={LabelPosition.Left}
                        items={[
                          { label: "Original", value: 400 },
                          { label: "Amended", value: 200 },
                          { label: "Cancelled", value: 300 },
                          { label: "Additional", value: 100 },
                        ]}
                        testId="xmlType"
                        selectedItem={selectedType}
                        onSelectionChangeHandler={
                          onSlipTypeSelectionChangeHandler
                        }
                      />
                      <EnhancedTextAreaFieldRHF
                        id="notes"
                        testId="notes"
                        label="Notes"
                        maxRows={15}
                        minRows={5}
                        labelPosition={LabelPosition.Left}
                      />
                      <Box sx={{ pt: 2 }}></Box>
                      <EnhancedDivider testId="transmitter-divider" />
                      <Box sx={{ pb: 2 }}></Box>
                      <EnhancedDropDownRHFWithLabel
                        selectName="valueOfIssuer"
                        label="Issuer"
                        labelPosition={LabelPosition.Left}
                        items={
                          issuerDDL && issuerDDL.message
                            ? []
                            : issuerDDL
                            ? issuerDDL
                            : []
                        }
                        testId="transmitter"
                        // required
                        onSelectionChangeHandler={
                          onIssuerSelectionChangeHandler
                        }
                      />
                      <TaxSlipSummaryDataInput
                        id="ownerSin1"
                        label="SIN 1 Of The Proprietor(s) Or Principal Owner(s)"
                        boxNumber="74"
                        textfield={true}
                      />
                      <TaxSlipSummaryDataInput
                        id="ownerSin2"
                        label="SIN 2 Of The Proprietor(s) Or Principal Owner(s)"
                        boxNumber="75"
                        textfield={true}
                      />
                      <Box sx={{ pt: 2 }}></Box>
                      <EnhancedDivider testId="transmitter-divider" />
                      <Box sx={{ pb: 2 }}></Box>
                      <Grid
                        container
                        columnSpacing={0}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Grid
                          item
                          xs={7}
                          sm={7}
                          md={7}
                          lg={7}
                          xl={7}
                          sx={{ textAlign: "left", paddingTop: 1 }}
                        >
                          <InputLabel>
                            <Typography variant="subtitle2">
                              Person To Contact About This Return
                              {/* <RequiredAsterisk /> */}
                            </Typography>
                          </InputLabel>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          sx={{ textAlign: "center", paddingTop: 1 }}
                        >
                          <InputLabel>
                            <Typography variant="subtitle2">76</Typography>
                          </InputLabel>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <EnhancedDropDownRHF
                            selectName="valueOfContactPerson"
                            // label="Person to contact about this return"
                            // labelPosition={LabelPosition.Left}
                            items={
                              contactPersonDDL && contactPersonDDL.message
                                ? []
                                : contactPersonDDL
                                ? contactPersonDDL
                                : []
                            }
                            testId="contactPerson"
                            // required
                            onSelectionChangeHandler={
                              onContactPersonSelectionChangeHandler
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          sm={7}
                          md={7}
                          lg={7}
                          xl={7}
                          sx={{ textAlign: "left", paddingTop: 2 }}
                        >
                          <InputLabel>
                            <Typography variant="subtitle2">
                              Phone number (Ext.)
                            </Typography>
                          </InputLabel>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          sx={{ textAlign: "center", paddingTop: 2 }}
                        >
                          <InputLabel>
                            <Typography variant="subtitle2">78</Typography>
                          </InputLabel>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <RHookFormTextField
                            // id="phone-number"
                            id="phone1"
                            testId="phone-number"
                            readOnly
                          />
                        </Grid>
                      </Grid>
                    </FlatStandardPanel>
                  </Paper>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
              </Grid>

              <Spacer y={4} x={4} />
            </Paper>
          </SingleColumnLayout>
        </form>
      </FormProvider>
      {/* invoice dialogu open if any transaction is unPaid */}
      <EnhancedDialog
        dialogTitle="Invoice(s) Due"
        key="dialog-box"
        testId="dialog"
        onCloseHandler={onCloseHandler}
        open={openInvoiceDialog}
        dialogContent={
          <InvoiceDialogPanel
            invoiceData={invoiceData}
            onUnPaidClickHandler={onUnPaidClickHandler}
            onClickInvoiceNoHandler={onClickInvoiceNoHandler}
          />
        }
        dialogAction={
          <Grid>
            <Button autoFocus variant="contained" onClick={onCloseHandler}>
              Cancel
            </Button>
          </Grid>
        }
      />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
        autoHide={snackbarData.autoHide}
      />
    </Grid>
  );
};
