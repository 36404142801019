import { INVOICE } from "store/types/invoiceReportTypes";

export const setSelectedINVOICE = (selectINVOICE: any) => {
  return {
    type: INVOICE.INVOICE_SET_SELECTED,
    payload: {...selectINVOICE}
  }
}

export const clearSelectedINVOICE = () => {
  return {
    type: INVOICE.INVOICE_CLEAR_SELECTED
  }
}
