import * as yup from 'yup';

export const TaxSlipDefinitionsSchema = yup.object({
  code: yup
    .string()
    .required("Code is required")
    .min(1, "Should be minimum 1 characters")
    .max(10, "Should be maximum 10 characters"),
  title: yup
    .string()
    .required("Title is required")
    .min(1, "Should be minimum 1 characters")
    .max(10, "Should be maximum 10 characters"),
  categoryId: yup
    .string()
    .required("Category is required"),
  year: yup
    .string()
    .required("Tax Year is required"),
});
