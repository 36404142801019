import { IFlexFieldSetupRequest } from "models/flexField/IFlexFieldSetupRequest";
import yup from "./yup-extended";

export const getValidationSchema = (flexFieldsList: { id: number, value: string }[]): yup.SchemaOf<IFlexFieldSetupRequest> => {
  return yup.object({
    appFieldLabel: yup.string().required('App Label is required').min(3).max(50),
    comparison: yup.array().of(
      yup.object({
        operator: yup.string() as yup.StringSchema<string>,
        field: yup.number()
      })
    ).optional().transform((comparisonList: { operator: string, field: number }[]) => {
      return comparisonList.filter(item => item.hasOwnProperty('operator') && item.operator && item.operator.length > 0)
    }),
    dataType: yup.string().required(),
    defaultValue: yup.string().nullable().max(200).transform((x) => (x === '' ? undefined : x)) as yup.StringSchema<string>,
    displayMasked: yup.boolean().oneOf([true, false]).required(),
    fieldColumn: yup.number().required(),
    fieldFormat: yup.string().nullable().regex('Invalid regex value').max(500).transform((x) => (x === '' ? undefined : x)) as yup.StringSchema<string>,
    fieldInternalValue: yup.string().nullable().max(500).transform((x) => (x === '' ? undefined : x)) as yup.StringSchema<string>,
    fieldLabel: yup.string().required('Field Label is required').min(3).max(50),
    fieldType: yup.string().required(),
    fieldValue: yup.string().nullable().max(500).transform((x) => (x === '' ? undefined : x)) as yup.StringSchema<string>,
    isCategory: yup.boolean().oneOf([true, false]).required(),
    isFixed: yup.boolean().oneOf([true, false]).required(),
    isHide: yup.boolean().oneOf([true, false]).required(),
    isRequired: yup.boolean().oneOf([true, false]).required(),
    labelColumn: yup.number().required(),
    labelPosition: yup.string().required(),
    maxValue: yup.number().zeroIfEmpty().min(0),
    minValue: yup.number().zeroIfEmpty().min(0),
    notes: yup.string().nullable().max(200).transform((x) => (x === '' ? undefined : x)) as yup.StringSchema<string>,
    parent: yup.number()
      .test(function (value?: number) {
        const { path, createError } = this;
        const { parentValue, parentRelation } = this.parent;
        // if either parenValue or parentRelation or both have a value bu parent doesn't
        if (!value && (!!parentValue || !!parentRelation)) {
          return createError({ path, message: 'Parent is a required field' });
        }
        return true;
      }),
    parentValue: yup.string().max(200).transform((x) => (x === '' ? undefined : x))
      .test(function (value?: string | null) {
        if (value === null) return true

        const { path, createError } = this;
        const { parent, parentRelation } = this.parent;
        // if either parenValue or parentRelation or both have a value bu parent doesn't
        if (!value && (!!parent || !!parentRelation)) {
          return createError({ path, message: 'Parent value is a required field' });
        }
        return true;
      }),
    parentRelation: yup.string()
      .test(function (value?: string) {
        const { path, createError } = this;
        const { parentValue, parent } = this.parent;
        // if either parenValue or parentRelation or both have a value bu parent doesn't
        if (!value && (!!parentValue || !!parent)) {
          return createError({ path, message: 'Parent relation is a required field' });
        }
        return true;
      }),
    placeholder: yup.string().nullable().max(100).transform((x) => (x === '' ? undefined : x)) as yup.StringSchema<string>,
    readOnly: yup.boolean().oneOf([true, false]).required(),
    uppercase: yup.boolean().oneOf([true, false]).required(),
    id: yup.number(),
  })
}
