import { FC, useState } from "react";

import { RL1SlipSummaryDataInput } from "../../RL1SlipSummaryDataInput";
import { EnhancedIOSSwitchRHFWithLabel, LabelPosition } from "@websential/cosmic";
import { Grid } from "@mui/material";

export interface IHealthServiceFundPanel {
  globalSetingsRes:any;
  formMethods:any;
}

export const HealthServiceFundPanel: FC<IHealthServiceFundPanel> = ({
  formMethods,
  globalSetingsRes
}) => {
  const [sectorSwitch, setSectorSwitch] = useState(false)
  
  const handleInputChange = (event:any) => {
    const newValue = event.target.value;
    if(newValue !== ''){
      if(sectorSwitch !== false){
        if(globalSetingsRes){
          console.log("globalSetingsRes : ",globalSetingsRes)
          if(newValue <= globalSetingsRes.qhsfPrimaryMin){
            formMethods.setValue('contributionRateIn',globalSetingsRes.qhsfPrimaryRate1)
          }
          else if(newValue > globalSetingsRes.qhsfPrimaryMin && newValue < globalSetingsRes.qhsfPrimaryMax){
            let resultValue = globalSetingsRes.qhsfPrimaryValue1 + (globalSetingsRes.qhsfPrimaryValue2 * newValue / globalSetingsRes.qhsfPrimaryNormalize)
            let rountValue = (Math.round(resultValue * 100) / 100).toFixed(2)
            formMethods.setValue('contributionRateIn',rountValue)
          }
          else if(newValue >= globalSetingsRes.qhsfPrimaryMax ){
            formMethods.setValue('contributionRateIn',globalSetingsRes.qhsfPrimaryRate2)
          }
        }
        const exemptSalaryAndWages = formMethods.getValues('exemptSalaryAndWages')
        if(exemptSalaryAndWages === undefined){
          formMethods.setValue('exemptSalaryAndWages',0)
        }
      } 
      else {
        if(globalSetingsRes){
          console.log("globalSetingsRes : ",globalSetingsRes)
          if(newValue <= globalSetingsRes.qhsfMin){
            formMethods.setValue('contributionRateIn',globalSetingsRes.qhsfRate1)
          }
          else if(newValue > globalSetingsRes.qhsfMin && newValue < globalSetingsRes.qhsfMax){
            let resultValue = globalSetingsRes.qhsfValue1 + (globalSetingsRes.qhsfValue2 * newValue / globalSetingsRes.qhsfNormalize)
            let rountValue = (Math.round(resultValue * 100) / 100).toFixed(2)
            formMethods.setValue('contributionRateIn',rountValue)
          }
          else if(newValue >= globalSetingsRes.qhsfMax ){
            formMethods.setValue('contributionRateIn',globalSetingsRes.qhsfRate2)
          }
        }
        const exemptSalaryAndWages = formMethods.getValues('exemptSalaryAndWages')
        if(exemptSalaryAndWages === undefined){
          formMethods.setValue('exemptSalaryAndWages',0)
        }
      }
    }else{
      formMethods.setValue('contributionRateIn',0)
    }
  };
  const onSelectionChange = (e:any) => {
    setSectorSwitch(e)
    formMethods.setValue('totalPayrollForThePurposeOfDeterminingContributionRate',0)
    formMethods.setValue('salaryAndWagesSubjectToTheContribution',0)
    formMethods.setValue('exemptSalaryAndWages',0)
    formMethods.setValue('contributionRateIn',0)
    formMethods.setValue('contributionToTheHealthServicesFundBeforeTheReduction',0)
    formMethods.setValue('contributionToTheHealthServicesFundRemittedInTheYear',0)
    formMethods.setValue('balanceHsf',0)
  }
  const handleSalaryAndWagesChange = (e:any) => {
    const actualValue = e.target.value
    if(actualValue !== ''){
      const contributionRateIn = formMethods.getValues('contributionRateIn')
      const exemptSalaryAndWages = parseFloat(formMethods.getValues('exemptSalaryAndWages').replace(/[$,]/g, ''))
      if(contributionRateIn !== undefined){
        // const res = actualValue * contributionRateIn - exemptSalaryAndWages
        const minusExempt = actualValue-exemptSalaryAndWages
        const res = minusExempt * contributionRateIn 
        const finalResult = res / 100
        let rountValue = (Math.round(finalResult * 100) / 100).toFixed(2)
        formMethods.setValue('contributionToTheHealthServicesFundBeforeTheReduction',rountValue)
      }
      else{
        formMethods.setValue('contributionRateIn',0)
        formMethods.setValue('exemptSalaryAndWages',0)
        console.log("else")
        const minusExempt = actualValue-exemptSalaryAndWages
        const res = minusExempt * contributionRateIn 
        const finalResult = res / 100
        let rountValue = (Math.round(finalResult * 100) / 100).toFixed(2)
        formMethods.setValue('contributionToTheHealthServicesFundBeforeTheReduction',rountValue)
        }
    }
    else {
      formMethods.setValue('contributionToTheHealthServicesFundBeforeTheReduction',0)
    }
  }
  const handleExemptSalaryAndWagesChange = (e:any) => {
    const actualValue = e.target.value
    if(actualValue !== ''){
      const contributionRateIn = formMethods.getValues('contributionRateIn')
      const salaryAndWagesSubjectToTheContribution = parseFloat(formMethods.getValues('salaryAndWagesSubjectToTheContribution').replace(/[$,]/g, ''))
      if(contributionRateIn !== undefined){
        // const res = actualValue * contributionRateIn - exemptSalaryAndWages
        const minusExempt = salaryAndWagesSubjectToTheContribution - actualValue
        const res = minusExempt * contributionRateIn 
        const finalResult = res / 100
        let rountValue = (Math.round(finalResult * 100) / 100).toFixed(2)
        formMethods.setValue('contributionToTheHealthServicesFundBeforeTheReduction',rountValue)
      }
      else{
        formMethods.setValue('contributionRateIn',0)
        formMethods.setValue('exemptSalaryAndWages',0)
        console.log("else")
        const minusExempt = salaryAndWagesSubjectToTheContribution - actualValue
        const res = minusExempt * contributionRateIn 
        const finalResult = res / 100
        let rountValue = (Math.round(finalResult * 100) / 100).toFixed(2)
        formMethods.setValue('contributionToTheHealthServicesFundBeforeTheReduction',rountValue)
        }
      }
      else {
        const salaryAndWagesSubjectToTheContribution = parseFloat(formMethods.getValues('salaryAndWagesSubjectToTheContribution').replace(/[$,]/g, ''))
        const contributionRateIn = formMethods.getValues('contributionRateIn')
        if(contributionRateIn !== undefined){
          const res = salaryAndWagesSubjectToTheContribution * contributionRateIn 
          const finalResult = res / 100
          let rountValue = (Math.round(finalResult * 100) / 100).toFixed(2)
          formMethods.setValue('contributionToTheHealthServicesFundBeforeTheReduction',rountValue)
        }      
      }
  }
  const handleRemittedInTheYearChange = (e:any) => {
    const value = e.target.value
    console.log("valuea",value)
    // if(value !== ''){
      const beforeReduction = parseFloat(formMethods.getValues('contributionToTheHealthServicesFundBeforeTheReduction').replace(/[$,]/g, ''))
      const finalBalance = beforeReduction - value
      // const convertToString = finalBalance.toString()
      let rountValue = (Math.round(finalBalance * 100) / 100).toFixed(2)
      formMethods.setValue('balanceHsf',rountValue)
      // formMethods.setValue('balanceHsf',parseFloat(convertToString).toFixed(2))
      
    // }
    // else{
    //   formMethods.setValue('balanceHsf',0)
    // }
  }
  return (
    <>
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{mb:"10px"}}>
        <EnhancedIOSSwitchRHFWithLabel
            name="primaryandmenufecturesector"
            checked={false}
            testId="applyAdjustments"
            label="Primary And Manufacture Sector"
            labelPosition={LabelPosition.Left}
            labelColumns={8}
            fieldColumns={4}
            onSelectionChangeHandler={onSelectionChange}
          />
      </Grid>
      <RL1SlipSummaryDataInput
        id="totalPayrollForThePurposeOfDeterminingContributionRate"
        label="Total payroll for the purpose of determining contribution rate"
        readOnly={false}
        defaultValue="0.00"
        onChange={handleInputChange}
      />
      <RL1SlipSummaryDataInput
        id="salaryAndWagesSubjectToTheContribution"
        label="Salary and wages subject to the contribution"
        readOnly={false}
        defaultValue="0.00"
        onChange={handleSalaryAndWagesChange}
      />
      <RL1SlipSummaryDataInput
        id="exemptSalaryAndWages"
        label="Exempt salary and wages"
        readOnly={false}
        defaultValue="0.00"
        onChange={handleExemptSalaryAndWagesChange}
      />
      <RL1SlipSummaryDataInput
        id="contributionRateIn"
        label="Contribution Rate in"
        defaultValue="0.00"
      />
      <RL1SlipSummaryDataInput
        id="contributionToTheHealthServicesFundBeforeTheReduction"
        label="Contribution to the health services fund before the reduction"
        defaultValue="0.00"
      />
      <RL1SlipSummaryDataInput
        id="contributionToTheHealthServicesFundRemittedInTheYear"
        label="Contribution to the health services fund remitted in the year"
        readOnly={false}
        defaultValue="0.00"
        onChange={handleRemittedInTheYearChange}
      />
      <RL1SlipSummaryDataInput id="balanceHsf" label="Balance"
      defaultValue="0.00" />
    </>
  );
};
