import { RL1SlipList } from "components/RL1Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL1SlipListPage {}

export const RL1SlipListPage: FC<IRL1SlipListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="RL-1 Slip List"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl1/list" },
        { text: "RL-1", navigateTo: "/taxslip/rl1/list" },
        { text: "RL-1 Slips", navigateTo: "/taxslip/rl1/list" },
      ]}
      mainContents={<RL1SlipList />}
    />
  );
};
