import { createTheme } from "@mui/material/styles";

export const DefaultTaxSlipTheme = createTheme({
  palette: {
    primary: {
      main: "#0057db",
      dark: "#0057db",
    },
    secondary: {
      main: "#36b37e",
      dark: "#279b6a",
    },
  },
});
