import { IResponse, IServerError } from "@websential/cosmic";
import { AxiosError, AxiosResponse } from "axios";

import http from "common/http-common";

export const DashboardServices = {
    getAllCounts: async (
    clientId:string,
    taxYear:number
  ): Promise<any | IServerError | IResponse> => {
    try {
    const response: AxiosResponse =
      await http.get<any>(`/v1/dashboard-api/?client_id=${clientId}&tax_year=${taxYear}`);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return {
        status: false,
        message: "Bad Request.",
        error: true,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }else {
      console.log("Response status:", response.status);
      return response.data;
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    return {
      status: false,
      message: axiosError.response
        ? axiosError.response.data
        : "Unknown error",
    };
  }
  },
}