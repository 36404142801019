import { RL3SlipSetup } from "components/RL3Slip";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL3SlipSetupPage {}

export const RL3SlipSetupPage: FC<IRL3SlipSetupPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Create RL-3 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl3/list" },
        { text: "RL-3", navigateTo: "/taxslip/rl3/list" },
        { text: "Create RL-3 Slip", navigateTo: "/taxslip/rl3/setup" },
      ]}
      mainContents={<RL3SlipSetup testId="t4-slip-setup" />}
    />
  );
};
