export const ACCOUNTANT = {
  ACCOUNTANT_SET_SELECTED: "ACCOUNTANT_SET_SELECTED",
  ACCOUNTANT_CLEAR_SELECTED: "ACCOUNTANT_CLEAR_SELECTED",
  ACCOUNTANT_LIST_REQUEST_LOAD: "ACCOUNTANT_LIST_REQUEST_LOAD",
  ACCOUNTANT_LIST_REQUEST_SUCCESS: "ACCOUNTANT_LIST_REQUEST_SUCCESS",
  ACCOUNTANT_LIST_REQUEST_FAILED: "ACCOUNTANT_LIST_REQUEST_FAILED",
  ACCOUNTANT_NAME_SET_SELECTED: "ACCOUNTANT_NAME_SET_SELECTED",
  ACCOUNTANT_NAME_CLEAR_SELECTED: "ACCOUNTANT_NAME_CLEAR_SELECTED",

  CLIENT_LIST_REQUEST_LOAD: "ACCOUNTANT_CLIENT_LIST_REQUEST_LOAD",
  CLIENT_LIST_REQUEST_SUCCESS: "ACCOUNTANT_CLIENT_LIST_REQUEST_SUCCESS",
  CLIENT_LIST_REQUEST_FAILED: "ACCOUNTANT_CLIENT_LIST_REQUEST_FAILED",
};
