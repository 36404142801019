import { QUERY_OPTIONS } from "common/constants";
import { AccountLayout } from "layouts";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { AuthService } from "services/AuthService";
const useMaskedData = () =>
  useQuery(
    "mask-data",
    () => AuthService.maskData(),
    QUERY_OPTIONS
  );
const useSignOut = () =>
  useQuery(
    "log-out",
    () => AuthService.signOut(),
    QUERY_OPTIONS
  );
export const LogoutPage = () => {
  const { data: maskData } = useMaskedData();
  console.log("maskData",maskData)
  const { data: logOut } = useSignOut();
  console.log("logOut",logOut)

  useEffect(() => {
    if(logOut){
      localStorage.clear();
      // window.location.href = "/taxslips_v5.com/"; //for local server build 
      window.location.href = "/"; //for app.live build
      window.sessionStorage.setItem("maskEnabled", "true");
      sessionStorage.removeItem("unmaskTime");
    }
  // AuthService.signOut();
  }, [logOut]);
  return (
    <AccountLayout
      contents={"Logout page session and storage clear login here"}
    />
  );
};

// changes from pc
// changes from mac
