import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "./TransactionsReportTableStyle.css";
import SearchIcon from '@mui/icons-material/Search';
import { EnhancedDropDown, IEnhancedDropDownItem, RequestParams } from "@websential/cosmic";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";

export interface IDataTable {
  rows: any;
  columns: any;
  showIndexColumn?: boolean;
  isLoading?: boolean;
  onRequestParamsChange?: (params: RequestParams) => void;
  initialPageSize?: number | undefined;
  initialPage?: number;
  totalRecords?: number;
  accountantDDLList:any
  yearsDDL:any
}

export const TransactionsReportDataTable: React.FC<IDataTable> = ({
  rows,
  columns,
  showIndexColumn,
  isLoading,
  onRequestParamsChange,
  initialPageSize = 10,
  initialPage = 0,
  totalRecords,
  accountantDDLList,
  yearsDDL
}) => {
  const isInitialRequest = useRef(true);
  const taxYear = useSelector((state : IAppState) => state.taxYear.year);
  console.log("yearsDDL",yearsDDL)
  const [year, setYear] = useState(taxYear);

  useEffect(()=>{
    if(yearsDDL && yearsDDL[0]){
      setYear(yearsDDL[0].value)
    }
  },[yearsDDL])
    const [itemSearch, setItemSearch] = useState('');
    const [accountant, setAccountant] = useState('All');
    const [invoice, setInvoice] = useState(400);
    const [requestParams, setRequestParams] = useState<any>({
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        sortField: null,
        sortOrder: null,
        searchQuery:"",
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        },
        accountantselected:accountant,
        invoiceselected:invoice,
        yearselected:year,
    });

  const [selectedItems, setSelectedItems] = useState<any>([]);

  const onSearchButtonClicked = () => {
    setRequestParams((prevParams:any) => ({
      ...prevParams,
      first: initialPage,
      rows: initialPageSize,
      page: initialPage,
      searchQuery: itemSearch,
      yearselected:year,
    }));
  }
  const handleClearSearch = () => {
    setItemSearch('')
    setRequestParams((prevParams:any) => ({
          ...prevParams,
          searchQuery: "",
          first: initialPage,
          rows: initialPageSize,
          page: initialPage,
          yearselected:year,
        }));
  }
    const renderHeader = () => {
      const onAccountantSelectionChangeHandler = (item: IEnhancedDropDownItem) : void => {
        let selectedValue = String(item.value);
        setAccountant(selectedValue)
        setRequestParams((prevParams:any) => ({
          ...prevParams,
          accountantselected: selectedValue,
          searchQuery: itemSearch,
          first: initialPage,
          rows: initialPageSize,
          page: initialPage,
          yearselected:year,
        }));
      };
      const onInvoiceStatusSelectionChangeHandler = (item: any) : void => {
        let selectedValue = item.value;
        setInvoice(selectedValue)
        setRequestParams((prevParams:any) => ({
          ...prevParams,
          invoiceselected: selectedValue,
          searchQuery: itemSearch,
          first: initialPage,
          rows: initialPageSize,
          page: initialPage,
          yearselected:year,
        }));
      };
      const onYearSelectionChangeHandler = (item: any) : void => {
        let selectedValue = item.value;
        setYear(selectedValue)
        setRequestParams((prevParams:any) => ({
          ...prevParams,
          yearselected: selectedValue,
          searchQuery: itemSearch,
          first: initialPage,
          rows: initialPageSize,
          page: initialPage,
        }));
      };
        return (
            <div  style={{display:"flex",justifyContent:"space-between"}}>
            <span className="p-input-icon-left w-5" style={{display:"flex",flex:"0 0 40%"}}>
                    <i className="pi pi-search" />
                    <InputText value={itemSearch} onChange={(e) => setItemSearch(e.target.value)} placeholder="Search by Client Name" className='w-full' style={{borderRadius:"5px 0px 0px 5px"}}/>
                    {itemSearch && (
                        <Button
                        icon="pi pi-times"
                        onClick={handleClearSearch}
                        className="p-button-warning"
                        style={{borderRadius:0}}
                        />
                    )}
                    <Button
                        onClick={onSearchButtonClicked}
                        // variant="contained"
                        style={{
                          padding:"8px 10px 8px 10px",
                          marginRight:"-14px!important",
                          borderRadius:"0px 5px 5px 0px"}}
                      >
                        <SearchIcon />
                      </Button>
                </span>
                      <div style={{display:"flex",justifyContent:"end",flex:"0 0 50%",marginTop:"4px"}}>
                        <div style={{width:"33%",margin:"0 5px"}}>
                          <EnhancedDropDown
                            selectName={"accountantDDL"}
                            items={accountantDDLList}
                            onChangeHandler={onAccountantSelectionChangeHandler}
                            testId={"acc-ddl"}
                            selectedItem={accountant}
                            />
                        </div>
                        <div style={{width:"33%",margin:"0 5px"}}>
                          <EnhancedDropDown
                            selectName={"invoiceStatus"}
                            items={[
                              {label:"--All Invoice Status--", value:400},
                              {label:"Paid", value:100},
                              {label:"Not-Paid", value:200},
                              {label:"Transactions not created", value:300},
                              ]}
                            onChangeHandler={onInvoiceStatusSelectionChangeHandler}
                            testId={"invoice-ddl"}
                            selectedItem={invoice}
                            />
                        </div>
                        <div style={{width:"33%",margin:"0 5px",display:"flex",alignItems:"center"}}>
                        <div style={{width:"60%",fontSize:"small",paddingBottom:"6.5px"}}>
                        Year of Operation:
                        </div>
                        <div style={{width:"40%"}}>
                          <EnhancedDropDown
                            selectName={"invoiceStatus"}
                            items={yearsDDL}
                            onChangeHandler={onYearSelectionChangeHandler}
                            testId={"year-ddl"}
                            selectedItem={year}
                            />
                        </div>
                            </div>
                      </div>
            </div>
        )
    }
  const headerSearch = renderHeader();
  // Custom column for row indices
  const indexColumn = {
    body: (rowData:any, row:any) => {
      return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
    },
    header: '#',
  };

  const onPage = (event:any) => {
    console.log("event123",event)
    console.log("requestParams1",requestParams)
    setRequestParams(() => ({
      ...event,
      accountantselected:accountant,
      invoiceselected:invoice,
      yearselected:year,
    }));
    console.log("requestParams2",requestParams)
};
// This useEffect is triggered when the debouncedSearchQuery, pageSize or currentPage values change
useEffect(() => {
  // if the results fetcher was passed, we can use it to fetch the results.
  if (onRequestParamsChange) {
    // skip the first initial call
    if (isInitialRequest.current === true) {
      console.log(
        "##### => useEffect - requestParams - no first call",
        requestParams
      );
      isInitialRequest.current = false;
    } else {
      console.log(
        "##### => useEffect - requestParams - second call",
        requestParams
      );
      onRequestParamsChange(requestParams);
    }
  }
}, [onRequestParamsChange, requestParams]);
  console.log("props headerColumns => ",columns)
  console.log("props items  => ",rows)
  console.log("selectedItems => ",selectedItems)
  return (
      <DataTable
        value={rows && rows}
        responsiveLayout="scroll"
        lazy 
        // filterDisplay="row"
        // dataKey="id" 
        paginator
        first={requestParams.first} 
        rows={requestParams.rows}
        totalRecords={totalRecords}
        onPage={onPage}
        // paginator
        // rows={25}
        rowsPerPageOptions={[25, 50, 100]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={`Showing {first} to {last} of ${totalRecords} Items`}
        selection={selectedItems}
        onSelectionChange={(e: any) => setSelectedItems(e.value)}
        editMode="row"
        showGridlines
        resizableColumns
        columnResizeMode="expand"
        scrollable
        // scrollHeight="450px"
        reorderableColumns
        // filterDisplay="menu"
        loading={isLoading}
        // globalFilterFields={["headerName"]}
        header={headerSearch}
        emptyMessage="No Record found."
        className="TransactionsReportTableStyle"
        style={{ margin: `${rows && rows.length < 5 ? "1.5rem 0.5rem 6rem 0.5rem" : "1.5rem 0.5rem 0.5rem 0.5rem"}`, border:"2px solid #dee2e6"}}
      >
       {rows && <Column 
          {...indexColumn} 
          frozen
          hidden={!showIndexColumn}
          // footer="Total"
          style={{ width: "34px" }}
          />}

        {columns &&
          columns.map((x: any, index: any) => (
            <Column
              key={index}
              field={x.field}
              header={x.headerName}
              // headerTooltip={x.description}
              // headerTooltipOptions={{ position: "top" }}
              // flex (This property is not use Yet)
              hidden={x.hide}
              // sortable={x.sortable}
              // resizeable={x.resizable}
              dataType={x.type}
              align={x.align}
              alignHeader={x.headerAlign}
              // hideSortIcons (This property is not use Yet)
              // filterElement={HideButton(x.hideable, x, x.field)}
              // showFilterMenuOptions
              // filter
              // showFilterMenu={x.disableColumnMenu}
              // filterPlaceholder="Search by name"
              // reorderable={x.disableReorder}
              // exportable={x.disableExport}
              // frozen={x.frozenColumn}
              // alignFrozen={x.alignFrozen}
              columnKey={x.field}
              style={{
                minWidth: x.minWidth,
                fontSize: "0.875rem"
              }}
              footer={x.footer}
              // body={rows.employmentIncome}
            />
          ))}
      </DataTable>
  );
};

//resizeablbe must be false if frozen is true and viseversa
