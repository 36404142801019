import { ISignInResponse } from "models";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";

export const LocalStorageService = {
  // token: () => useSelector((state: IAppState) => state.user.token),
  setUser: (userDetail: ISignInResponse) => {
    window.localStorage.setItem("user", JSON.stringify(userDetail));
  },
  getUser: () => {
    return window.localStorage.getItem("userDetail");
  },
  getUserToken: () => {
    const userDetail = window.localStorage.getItem("user");
    if (userDetail !== null) {
      window.localStorage.setItem("token", JSON.parse(userDetail).token);
      return JSON.parse(userDetail).token;
    }
    // const token: string =
    //   "eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIn0.cMm6lkVOBI7OvE54Z2jjq455htxXHM0-TKAYBqmo0PFANdPoBZnpCS6NVtiyGMPh_3dz7bFcJO7BerKF7kT5NHpKeQc4yl0G.VJAGwQwrUBfQK0MmklV6Zw.mWMpYYi-QEu5-6VyWkpyb3RUHpkLnPzPOEzd3L9tg8vGVXrTEQ08TCqwlFraNGvQn-9Yzk_OO9is_CYJ79NQLlEGJ4GNyBuaGZ47T_ZsYd2Pg80WH6tXuyd5Exh_cHSVes3zPZFAJNMYfJ6-TJo9DlId3XWtQQI_OpQ7hPx7rTms3lbugYc8HVX_AM1u_xynN0TOVlbEFvuko9xJVaPBKDBHJg-r1xIsuZ0RaEafKJ_dTNZZww_fPK7ZR0R3g8VhW-4m5QOfrZ0PTfxMeBPuPmqrhFZd8tUdhHVyd6YDFFE.HTvkfdM62yl0dp2r_9HdemyD7x7-lyQKjI67Z8-nCNM";

    // return token;
    // return window.localStorage.getItem("user");
  },
  getClientID: () => {
    const client_id: string = "eb1fced2-5afc-11eb-a9d8-02e58ecc098c";
    return client_id;
  },
  getClientName: () => {
    const client_name: string = "New test company";
    return client_name;
  },
};
