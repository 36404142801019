import React from "react";

import { Grid, InputLabel, Tooltip, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { RHookFormNumericFormatTextField } from "components/TaxSlipDataInput/RHookFormNumericFormatTextField";
import { RHookFormTextField } from "components/TaxSlipDataInput/RHookFormTextField";
// import { RHookFormTextField } from "@websential/cosmic";

export interface ITaxSlipDataInput {
  id: string;
  label: string;
  boxNumber: string;
  showAdjustedInput?: boolean;
  alert?:boolean;
  alertTitle?:string;
  titlePlacement?:any;
  textfield?: any;
}

export const TaxSlipDataInput: React.FC<ITaxSlipDataInput> = ({
  id,
  label,
  boxNumber,
  alert,
  alertTitle,
  titlePlacement,
  textfield
}) => {
  return (
    <Grid container columnSpacing={2} xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid
        item
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
        sx={{ textAlign: "left", paddingTop: 2 }}
      >
        <InputLabel sx={{display:"flex"}}>
          <Typography variant="subtitle2">{label}</Typography>
          {alert === true ? (
              <Tooltip title={alertTitle} placement={titlePlacement}>
                  <InfoIcon sx={{fontSize:"1.1rem",margin:"1.5px"}}/>
              </Tooltip>
            ): undefined}
        </InputLabel>
      </Grid>
      <Grid
        item
        xs={2}
        sm={2}
        md={2}
        lg={2}
        xl={2}
        sx={{ textAlign: "right", paddingTop: 2 }}
      >
        <InputLabel>
          <Typography variant="subtitle2">{boxNumber}</Typography>
        </InputLabel>
      </Grid>
      <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        {/* <RHookFormTextField id={`${id}`} testId={`${id}-1`} defaultValue="0.00"/> */}
        {textfield ? 
        <RHookFormTextField
          id={`${id}`}
          testId={`${id}-1`}
          defaultValue={'0.00'}
          textAlign={'end'}
        /> : 
        <RHookFormNumericFormatTextField
          id={id}
          testId={`${id}`}
          defaultValue={'0.00'}
          textAlign={'end'}
        />
        }
      </Grid>
    </Grid>
  );
};
