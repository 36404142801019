import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from "./reducers";
import loginReducer from "./reducers/loginReducers";
import {viewStateReducer} from "./reducers/viewStateReducer";
import rootSaga from "./sagas";
import { enhancer as withReduxEnhancer } from 'addon-redux'

const config: any = {
  key: 'root',
  storage,
  blacklist: [
    'loginReducer',
    'viewStateReducer'
  //   'categoryListingReducer',
  //   'categoryDetailsReducer',
  //   'signUpReducer',
  //   'ledgerReducer',
  //   'notificationsReducer',
  ],
  // whitelist: [
  //   'loginReducer'
  // ],
  debug: true, //to get useful logging
};

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const enhancers: any = []
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware: any = [];
const sagaMiddleware = createSagaMiddleware();

const createMiddlewareEnhancer = () => {

  middleware.push(sagaMiddleware);
  if (process.env.NODE_ENV !== 'production') {
    // include other middlewares as needed, like the invariant and logger middlewares
    // middleware.push(invariant())
    // middleware.push(logger())
  }
  return applyMiddleware(...middleware)
}

const createEnhancer = (): any => {

  enhancers.push(createMiddlewareEnhancer())
  if (process.env.NODE_ENV !== 'production') {
    enhancers.push(withReduxEnhancer)
  }
  return composeEnhancers(...enhancers)
}

const reducers: any = persistReducer(config, rootReducer);
// const enhancers = [applyMiddleware(...middleware), withReduxEnhancer];
const persistConfig: any = { enhancers };

const configureStore = () => {
  // const store = createStore(reducers, undefined, composeEnhancers(...enhancers));
  const store = createStore(reducers, undefined, createEnhancer());
  const persister = persistStore(store, persistConfig, () => {
    console.log('Test', store.getState());
  });

  sagaMiddleware.run(rootSaga);
  return {persister, store};
};

export default configureStore;
