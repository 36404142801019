import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import "./mergeReaourceTableStyle.css";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Card } from "primereact/card";
import { SubmitButton } from "@websential/cosmic";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { ResourceService } from "services/ResourceService";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { INITIAL_PAGE_SIZE, ROWS_PER_PAGE_PER_OPTIONS } from "common/constants";

export interface IDataTable {
  rows: any;
  columns: any;
  showIndexColumn: boolean;
  showChecboxColumn: boolean;
  showSettingColumn: boolean;
  onRowEditClicked: (params: GridRenderCellParams) => void;
  onRowDeleteClicked: (params: GridRenderCellParams) => void;
  isLoading?: boolean;
  onKeepRecordRowSelected: (value: any) => void;
  onIsLoadingMerging: (value: boolean) => void;
  loadmergeSlipsList: (value: boolean) => void;
  onSnackbarMessageData: (value: any) => void;
}

const PrimeDataGridMergeResource: React.FC<IDataTable> = ({
  rows,
  columns,
  showIndexColumn,
  showChecboxColumn,
  showSettingColumn,
  onRowEditClicked,
  onRowDeleteClicked,
  isLoading,
  onKeepRecordRowSelected,
  onIsLoadingMerging,
  loadmergeSlipsList,
  onSnackbarMessageData,
}) => {
  const clientId = useSelector((state: IAppState) => state.client.id);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const [val, setValue] = useState(false);
  const [field, setField] = useState("");

  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]); //multiple
  // const handleSingleSelection = (rowData:any) => {
  // setSelectedRow(rowData);
  // onKeepRecordRowSelected(rowData)
  // };
  // console.log("selectedRow",selectedRow)

  const setting = () => {
    return (
      <div style={{ marginLeft: "0.35rem" }}>
        <i className="pi pi-cog text-xl"></i>
      </div>
    );
  };
  const delEdit = (params: any) => {
    return (
      <Button
        icon="pi pi-user-edit"
        // onClick={() => onRowEditClicked(selectedItems.length > 0 ? selectedIds : params.id)}
        onClick={() =>
          onRowEditClicked(selectedItems.length > 0 ? selectedItems : params)
        }
        className="p-button-rounded p-button-text w-2rem h-2rem"
      />
    );
  };

  const maskingCol = (val: any, hName: any) => {
    if (val == true) {
      return "*****";
    } else {
      return hName;
    }
  };

  const HideButton = (option: any, value: any, field: any) => {
    if (option == true) {
      return (
        <span className="field-checkbox mt-3 mb-0">
          <Button
            label="Hide Column"
            className="p-button-secondary uppercase"
            onClick={() => clickHideBtn(value, field, true)}
          />
        </span>
      );
    } else {
      return (option = null);
    }
  };

  const clickHideBtn = (value: any, field: any, is_load: any) => {
    if (is_load) {
      if (value.field === field) {
        setValue(value);
        setField(field);
      }
    } else {
      return false;
    }
  };

  console.log("props headerColumns => ", columns);
  console.log("props items RL1AdjustmentReport => ", rows);
  console.log("selectedItemsselectedItems", selectedItems);

  const rowExpansionTemplate = (data: any) => {
    let isSelected = false;

    selectedRows.some((row: any) => {
      isSelected = row.id === data.id;
      return isSelected;
    });
    return (
      <input
        type="radio"
        checked={isSelected}
        onChange={() => handleSingleSelectionc(data)}
        style={{
          height: "16px",
          width: "16px",
          display: "flex",
          margin: "0 2.3rem",
        }}
      />
    );
  };

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Employee Info"
          colSpan={3}
          style={{
            position: "sticky",
            left: "0px",
            zIndex: 1,
            backgroundColor: "#e3fcef",
          }}
        />
        <Column
          header=""
          colSpan={6}
          style={{ cursor: "auto", backgroundColor: "#e3fcef" }}
        />
        <Column
          header="T4 Slips"
          colSpan={4}
          style={{ cursor: "auto", backgroundColor: "#fffae6" }}
        />
        <Column
          header="RL-1 Slips"
          colSpan={4}
          style={{ cursor: "auto", backgroundColor: "#deebff" }}
        />
        <Column
          header=""
          colSpan={1}
          style={{
            cursor: "auto",
            position: "sticky",
            right: "0px",
            zIndex: 1,
          }}
        />
      </Row>
      <Row>
        <Column
          selectionMode="multiple"
          hidden={showChecboxColumn}
          frozen
          resizeable={false}
          style={{ maxWidth: "65px" }}
          field="selection"
          columnKey="selection"
          headerStyle={{ backgroundColor: "#e3fcef" }}
        ></Column>

        {/* <Column
                    headerStyle={{ backgroundColor:"#e3fcef" }}
                    header="Keep Record"
                    frozen
                    body={(rowData) => (
                    <input
                        type="radio"
                        checked={selectedRow === rowData}
                        onChange={() => handleSingleSelection(rowData)}
                    />
                    )}
                ></Column> */}
        <Column
          headerStyle={{ backgroundColor: "#e3fcef" }}
          header="Keep Record"
          frozen
          body={rowExpansionTemplate}
        />
        {columns !== undefined &&
          columns.map((x: any, index: any) => (
            <Column
              field={x.field}
              header={x.headerName && maskingCol(x.maskable, x.headerName)}
              headerTooltip={x.description}
              headerTooltipOptions={{ position: "top" }}
              // flex (This property is not use Yet)
              hidden={
                val === x.hide
                  ? clickHideBtn(x, x.field, false)
                  : x.field === field
                  ? val
                  : x.hide
              }
              sortable={x.sortable}
              resizeable={x.resizable}
              dataType={x.type}
              align={x.align}
              alignHeader={x.headerAlign}
              // hideSortIcons (This property is not use Yet)
              // filterElement={HideButton(x.hideable, x, x.field)}
              // showFilterMenuOptions
              // filter
              // showFilterMenu={x.disableColumnMenu}
              // filterPlaceholder="Search by name"
              reorderable={x.disableReorder}
              exportable={x.disableExport}
              frozen={x.frozenColumn}
              columnKey={x.field}
              style={{
                minWidth: x.minWidth,
                fontSize: "0.875rem",
              }}
            />
          ))}

        <Column
          field="id"
          header="No."
          hidden={showIndexColumn}
          headerStyle={{ fontWeight: "bold" }}
          style={{ minWidth: 60 }}
          resizeable={false}
          reorderable={false}
          columnKey="id"
          frozen
        ></Column>
        <Column
          body={delEdit}
          header={setting}
          style={{ maxWidth: "65px" }}
          hidden={showSettingColumn}
          resizeable={false}
          reorderable={false}
          frozen
          alignFrozen="right"
        ></Column>
      </Row>
    </ColumnGroup>
  );

  const onMergeEmployeesClicked = async (): Promise<void> => {
    // console.log("selectedItems",selectedItems)
    // console.log("selectedRows",selectedRows)
    //seperate Data according to same SIN numbers
    if(selectedItems.length > 0){
      const maskedStatus:any = sessionStorage.getItem("maskEnabled")
      if(maskedStatus === "true"){
        onSnackbarMessageData({
            open: true,
            message: "Please unmask your data.",
            severity: "error",
          });
        } else {
    const multiSelectedMergingIds = selectedItems.reduce(
      (groups: any, item: any) => {
        const groupName = item.resource.socialInsuranceNumber;
        groups[groupName] = [...(groups[groupName] || []), item.id];
        return groups;
      },
      {}
    );

    const MultiSelectedMergingIdsData = Object.values(multiSelectedMergingIds);
    console.log("MultiSelectedMergingIdsData", MultiSelectedMergingIdsData);

    const selectedFinalSlipRowsIds = selectedRows.map((item: any) => item.id);
    console.log("selectedFinalSlipRowsIds", selectedFinalSlipRowsIds);
    for (let i = 0; i < MultiSelectedMergingIdsData.length; i++) {
      const currentItem: any = MultiSelectedMergingIdsData[i];
      let matchingValue: any = null;
      let finalSlipResourceData: any = null;
      for (let j = 0; j < currentItem.length; j++) {
        if (selectedFinalSlipRowsIds.includes(currentItem[j])) {
          matchingValue = currentItem[j];
          break;
        }
      }
      for (let k = 0; k < selectedRows.length; k++) {
        console.log("inside for");
        if (selectedRows[k].id === matchingValue) {
          console.log("finalSlipResourceData", selectedRows[k].resource);
          finalSlipResourceData = selectedRows[k].resource;
          break; // If you want to stop after the first match
        } else {
          console.log("matchingValue", matchingValue);
          console.log("selectedRows[k].id", selectedRows[k].id);
        }
      }

      if (matchingValue !== null) {
        console.log("currentItem", currentItem);
        console.log(`Matching value in item ${i + 1}: ${matchingValue}`);

        const updatedIds = currentItem.filter(
          (item: any) => item !== matchingValue
        );
        const ids_list = updatedIds
          .map((ids: any) => `merging_ids=${ids}`)
          .join("&");
        const mergingIds: any = ids_list.replace(/^&+|&+$/g, "");
        console.log("mergingIds", mergingIds);

        try {
          onIsLoadingMerging(true);
          const response = await ResourceService.mergeSelectedResource(
            clientId,
            taxYear,
            mergingIds,
            matchingValue,
            finalSlipResourceData
          );

          console.log("responseData", response);
          if(response && response.data === "Record has been Merged Successfully!"){
            onSnackbarMessageData({
                open: true,
                message: response.data,
                severity: "success",
              })
          }else{
            onSnackbarMessageData({
                open: true,
                message: response.message ? response.message : response.data.message ? response.data.message : "Something went wrong!",
                severity: "error",
              })
          }
        } catch (err) {
          console.log("errerrerr", err);
          onSnackbarMessageData({
            open: true,
            message: "Somthing went wrong!",
            severity: "error",
          });
        } finally {
          onIsLoadingMerging(false);
          loadmergeSlipsList(true)
        }
      }
      else{
        onSnackbarMessageData({
              open: true,
              message: "Please select Keep record Button!",
              severity: "error",
          });
      }
      console.log("currentItem",currentItem)
      console.log("matchingValue",matchingValue)
    }
  }
  } else {
    onSnackbarMessageData({
      open: true,
      message: "Please select atleast one record!",
      severity: "error",
  });
  }
  };
  const onIgnoreMergeEmployeesClicked = async (): Promise<void> => {
    console.log("selectedItems11",selectedItems)
    if(selectedItems.length > 0){
    const selectedFinalSlipRowsIds = selectedItems.map((item: any) => item.id);
    console.log("selectedFinalSlipRowsIds", selectedFinalSlipRowsIds);
       
        const ids_list = selectedFinalSlipRowsIds
          .map((ids: any) => `merging_ids=${ids}`)
          .join("&");
        const mergingIds: any = ids_list.replace(/^&+|&+$/g, "");
        console.log("mergingIds", mergingIds);

        try {
          onIsLoadingMerging(true);
          const response = await ResourceService.ignoreMergedSelectedResource(
            clientId,
            taxYear,
            mergingIds,
            "65d485107377318cd00890ae",
            {externalId:"160a0e56-d87a-43c2-b4b8-e41a828edd55",firstName:"ignore merge",lastName:"hard code data",socialInsuranceNumber:"000000000",address:"hard code data",city:"L",country:{id:1,name:"Canada",shortCode:"CAN"},postCode:"1",
            province:{id:1,name:"Alberta",shortCode:"AB"},provinceOfEmployment:{id:1,name:"Alberta",shortCode:"AB"},cppQppExempt:false,eiExempt:false,ppipExempt:false,payPeriod:26,reducedEi:false}
          );

          console.log("responseData", response);
          if(response && response.data === "Record has been Ignored Successfully!"){
            onSnackbarMessageData({
                open: true,
                message: response.data,
                severity: "success",
              })
          }else{
            onSnackbarMessageData({
                open: true,
                message: response.message ? response.message : response.data.message ? response.data.message : "Something went wrong!",
                severity: "error",
              })
          }
        } catch (err) {
          console.log("errerrerr", err);
          onSnackbarMessageData({
            open: true,
            message: "Somthing went wrong!",
            severity: "error",
          });
        } finally {
          onIsLoadingMerging(false);
          loadmergeSlipsList(true)
        }
      } else {
        onSnackbarMessageData({
          open: true,
          message: "Please select atleast one record!",
          severity: "error",
      });
      }
  };

  const footer = (
    <div className="flex flex-wrap justify-content-center gap-2">
      <Button
        label="Merge"
        icon="pi pi-check"
        onClick={onMergeEmployeesClicked}
        size="small"
        severity="info"
        raised
      />
      <Button
        label="Ignore"
        icon="pi pi-times"
        severity="secondary"
        onClick={onIgnoreMergeEmployeesClicked}
        size="small"
        raised
      />
    </div>
  );
  let sinArray:any = undefined

  const groupedData =
    rows &&
    rows.reduce((groups: any, item: any) => {
      const groupName = item.resource.socialInsuranceNumber;
      const groupFirstName = item.employeName;
      
      
      if(groupName === "0*****0"){
        groups[groupName] = [...(groups[groupName] || []), item];
        // arrayWithDefaultZeroSIN = groups[groupName]
        
        // if(arrayWithDefaultZeroSIN)
        const newItem = groups[groupName].map((item:any) => item.employeName)
        const matchingItems = rows.filter((record:any) => {
          console.log("record",record)
          // const firstName = record.employeName;
          // return record.includes(newItem[0]);
          return newItem.some((name:any) => name === record.employeName);
        });
        console.log("inv",newItem)
        console.log("inv",matchingItems)
        // const notInData1 = matchingItems.filter((item:any) =>
        //   !groups[groupName].some((otherItem:any) => otherItem.employeName === item.employeName)
        // );
        // console.log("notInData1",notInData1)
        groups[groupName] = matchingItems;
        const sin = groups[groupName]
        .map((item: any) => {
          if (item.resource.socialInsuranceNumber !== "0*****0") {
            return item.resource.socialInsuranceNumber;
          }
        })
        .filter((value:any) => value !== undefined);
        sinArray = sin
        console.log("siiiiiiiiiin",sin)
      } else if(groupName === "000000000"){
        groups[groupName] = [...(groups[groupName] || []), item];
        // arrayWithDefaultZeroSIN = groups[groupName]
        
        // if(arrayWithDefaultZeroSIN)
        const newItem = groups[groupName].map((item:any) => item.employeName)
        const matchingItems = rows.filter((record:any) => {
          console.log("record",record)
          // const firstName = record.employeName;
          // return record.includes(newItem[0]);
          return newItem.some((name:any) => name === record.employeName);
        });
        console.log("inv",newItem)
        console.log("inv",matchingItems)
        // const notInData1 = matchingItems.filter((item:any) =>
        //   !groups[groupName].some((otherItem:any) => otherItem.employeName === item.employeName)
        // );
        // console.log("notInData1",notInData1)
        groups[groupName] = matchingItems;
        const sin = groups[groupName]
        .map((item: any) => {
          if (item.resource.socialInsuranceNumber !== "000000000") {
            return item.resource.socialInsuranceNumber;
          }
        })
        .filter((value:any) => value !== undefined);
        sinArray = sin
        console.log("siiiiiiiiiin",sin)
      } else {
        groups[groupName] = [...(groups[groupName] || []), item];
      }

      // console.log("arrayWithDefaultZeroSIN",arrayWithDefaultZeroSIN)

      console.log("groupFirstName",groupFirstName)
      console.log("groups",groups)
      console.log("groupName",groupName)
      console.log("groups[groupName]",groups[groupName])
      return groups;
    }, {});

// Check if sin values exist in groupData and remove them
sinArray && sinArray.forEach((sinValue:any) => {
  if (groupedData.hasOwnProperty(sinValue)) {
    delete groupedData[sinValue];
  }
}); 
  // Flatten groupedData into an array of arrays
  const groupedRows = Object.values(groupedData);

  // Flatten the rows
  const flattenedRows = groupedRows.flat();

  console.log("groupedData",groupedData)
  console.log("groupedRows",groupedRows)
  console.log("flattenedRows",flattenedRows)

  const forDefaultSin = flattenedRows
        .map((item: any) => {
          if (item.resource.socialInsuranceNumber === "0*****0") {
            return item.employeName;
          } else if (item.resource.socialInsuranceNumber === "000000000") {
            return item.employeName;
          }
        })
        .filter((value:any) => value !== undefined);
  console.log("forDefaultSin",forDefaultSin)
  const uniqueNames = Array.from(new Set(flattenedRows.map((row:any) => row.resource.socialInsuranceNumber))); // Get unique names
  // Remove elements from latestSinArray that are present in sinArray
  let modifiedUniqueNames = uniqueNames.map((name:any) => (name === '0*****0' ? forDefaultSin[0] : name === '000000000' ? forDefaultSin[0] : name));
  let updatedUniqueNames = modifiedUniqueNames.filter((item:any) => sinArray && !sinArray.includes(item));  
  console.log("uniqueNames",uniqueNames)
  console.log("modifiedUniqueNames",modifiedUniqueNames)
  console.log("updatedUniqueNames",updatedUniqueNames)

  const flattenedRowsWithClassesFunction = () => {
    // console.log("123ss",modifiedUniqueNames.indexOf(item.resource.socialInsuranceNumber))
    // Check if there are other flattenedRows with the same social insurance number
    // const isDuplicate = flattenedRows.filter((r:any) => r.resource.socialInsuranceNumber === item.resource.socialInsuranceNumber).length > 1;
      const itemsHaveDefaultSin = flattenedRows.map((r: any) => {
        if (r.resource.socialInsuranceNumber === "0*****0") {
          // const newItem = [r.employeName];
          // const matchingItems = flattenedRows.filter((record: any) => {
          //   return newItem.some((name: any) => name === record.employeName);
          // });
      
          // console.log("inv12 0*****0", newItem);
          // console.log("inv12 0*****0", matchingItems);
      
          // const withClass = matchingItems.map((i: any) => {
          //   return {
          //     ...i,
          //     className: "row-green", // Add the desired class here
          //   };
          // });
          
          let withClassObject = {
            ...r,
            className: "row-green"
          }

          // console.log("withClass", withClass);
          // return withClass;
          return withClassObject;
          } else if (r.resource.socialInsuranceNumber === "000000000") {
          // const newItem = [r.employeName];
          // const matchingItems = flattenedRows.filter((record: any) => {
          //   return newItem.some((name: any) => name === record.employeName);
          // });
      
          // console.log("inv12 000000000", newItem);
          // console.log("inv12 000000000", matchingItems);
      
          // const withClass = matchingItems.map((i: any) => {
          //   return {
          //     ...i,
          //     className: "row-green", // Add the desired class here
          //   };
          // });
          let withClassObject = {   //this will color green to all items which have smae sin,employeeName and POE
            ...r,
            className: "row-green"
          }
      
          // console.log("withClass", withClass);
          return withClassObject;
        } else {
          return []
        }
      });
      console.log("itemsHaveDefaultSin--", itemsHaveDefaultSin);
      
      // Flatten the array of arrays to a single array
      const flattenedItemsHaveDefaultSin = itemsHaveDefaultSin.flat().filter((value:any) => value !== undefined);
      
      console.log("flattenedItemsHaveDefaultSin", flattenedItemsHaveDefaultSin);

      // Remove items with class from flattenedRows
      // const itemsDontHaveDefaultSin = flattenedRows.filter((r: any) => {
      //   const items = !flattenedItemsWithClass.some((item: any) => item.id === r.id);
      //   console.log("items111",items)
      // });
      // Remove items with class from flattenedRows
      const itemsDontHaveDefaultSin = flattenedRows.filter((r: any) => {
        return !flattenedItemsHaveDefaultSin.some((item: any) => item.id === r.id);
      });

      console.log("itemsDontHaveDefaultSin", itemsDontHaveDefaultSin);
      
      const itemsDontHaveDefaultSinWithClass = itemsDontHaveDefaultSin.map((item:any) => {
        return {
          ...item,
          className:"row-red"
        }
      })
      
      console.log("itemsDontHaveDefaultSinWithClass", itemsDontHaveDefaultSinWithClass);

      const allItems = [...itemsDontHaveDefaultSinWithClass,...flattenedItemsHaveDefaultSin]

      console.log("allItems",allItems)
      // const isDuplicate = flattenedRows.filter((r:any) => {
      //   if(item.resource.socialInsuranceNumber === "0*****0"){
      //     const newItem = [item.employeName]
      //     const matchingItems = flattenedRows.filter((record:any) => {
      //       return newItem.some((name:any) => name === record.employeName);
      //     });
          
      //   console.log("inv12",newItem)
      //   console.log("inv12",matchingItems)
      //   } else {

      //   }

      //   // r.resource.socialInsuranceNumber === item.resource.socialInsuranceNumber
      
      // });

      // console.log("isDuplicate",isDuplicate)
      // // Add a CSS class based on the condition
      // const className = isDuplicate ? 'row-red' : 'row-yellow';
      // // return className
      // return {
      //   ...item,
      //   className: className
      // };
      return allItems
    }

    const flattenedRowsWithClasses = flattenedRowsWithClassesFunction()

  console.log("flattenedRowsWithClasses",flattenedRowsWithClasses)

  const rowsWithTwoDecimalNumbers = flattenedRowsWithClasses && flattenedRowsWithClasses.map((item:any) => {
    return {
    ...item,
    t4SlipData: {
        ...item.slipData,
        employmentIncome: parseFloat(item.t4SlipData.employmentIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        cppContributions: parseFloat(item.t4SlipData.cppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // secondCppContributions: item.t4SlipData.secondCppContributions !== null && parseFloat(item.t4SlipData.secondCppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        qppContributions: parseFloat(item.t4SlipData.qppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // secondQppContributions: item.t4SlipData.secondQppContributions !== null && parseFloat(item.t4SlipData.secondQppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        eiPremiums: parseFloat(item.t4SlipData.eiPremiums).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // rppContributions: parseFloat(item.t4SlipData.rppContributions).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        incomeTaxDeducted: parseFloat(item.t4SlipData.incomeTaxDeducted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // eiInsurableEarnings: parseFloat(item.t4SlipData.eiInsurableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // cppQppPensionableEarnings: parseFloat(item.t4SlipData.cppQppPensionableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // unionDues: parseFloat(item.t4SlipData.unionDues).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // charitableDonations: parseFloat(item.t4SlipData.charitableDonations).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // registrationNumber: item.t4SlipData.registrationNumber,
        // pensionAdjustment: parseFloat(item.t4SlipData.pensionAdjustment).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // ppipPremiums: parseFloat(item.t4SlipData.ppipPremiums).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // ppipInsurableEarnings: parseFloat(item.t4SlipData.ppipInsurableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // employerOfferedDentalBenefits: parseFloat(item.t4SlipData.employerOfferedDentalBenefits).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      },
}});

  const headerTemplate = (groupedRows: {
    resource: {
      socialInsuranceNumber:
        | string
        | number
    };
  }) => {
    return (
      <>
        <span
          style={{
            position: "sticky",
            left: "0px",
            zIndex: 1,
          }}
        >
          SIN : {rows && groupedRows.resource.socialInsuranceNumber}
        </span>
      </>
    );
  };

  const handleSingleSelectionc = (rowData: any) => {
    console.log("rowData", rowData);
    if (rows) {
      setSelectedRows((prevSelected: any) => {
        
        const foundDefaultSinItemMask = rows.find((item:any) => item.resource.socialInsuranceNumber === "0*****0");
        const foundDefaultSinItemUnMask = rows.find((item:any) => item.resource.socialInsuranceNumber === "000000000");
        if(foundDefaultSinItemMask){
          const nameOfFoundItem = foundDefaultSinItemMask.employeName;
          const selectedGroupSIN = rowData.resource.socialInsuranceNumber;
          console.log("prevSelected",prevSelected)
          console.log("selectedGroupSIN",selectedGroupSIN)
          if(selectedGroupSIN in groupedData && selectedGroupSIN !== "0*****0" && selectedGroupSIN !== "000000000"){
            console.log("jsdbgvskvbl",groupedData[selectedGroupSIN])
            // Remove previously selected rows from the same group
            const newSelected = prevSelected.filter(
              (row: any) => row.resource.socialInsuranceNumber !== selectedGroupSIN
            );
            // Add the current row to the selected list
            return [...newSelected, rowData];
          } else {
              const newSelected = prevSelected.filter(
                (row: any) => row.employeName !== nameOfFoundItem
              );
              // Add the current row to the selected list
              return [...newSelected, rowData];
          }
          } else if(foundDefaultSinItemUnMask){
            const nameOfFoundItem = foundDefaultSinItemUnMask.employeName;
            const selectedGroupSIN = rowData.resource.socialInsuranceNumber;
            console.log("prevSelected",prevSelected)
            console.log("selectedGroupSIN",selectedGroupSIN)
            if(selectedGroupSIN in groupedData && selectedGroupSIN !== "0*****0" && selectedGroupSIN !== "000000000"){
              console.log("jsdbgvskvbl",groupedData[selectedGroupSIN])
              // Remove previously selected rows from the same group
              const newSelected = prevSelected.filter(
                (row: any) => row.resource.socialInsuranceNumber !== selectedGroupSIN
              );
              // Add the current row to the selected list
              return [...newSelected, rowData];
            } else {
                const newSelected = prevSelected.filter(
                  (row: any) => row.employeName !== nameOfFoundItem
                );
                // Add the current row to the selected list
                return [...newSelected, rowData];
            }
            } else {
            const selectedGroupName = rowData.resource.socialInsuranceNumber;
            console.log("prevSelected",prevSelected)
            console.log("selectedGroupName",selectedGroupName)
            // Remove previously selected rows from the same group
            const newSelected = prevSelected.filter(
              (row: any) => row.resource.socialInsuranceNumber !== selectedGroupName
            );
            // Add the current row to the selected list
            return [...newSelected, rowData];
          }
      });
    }
  };

  console.log("groupedRows", groupedRows);
  console.log("rows", rows);
  console.log("selectedRows", selectedRows);
  console.log("selectedRows", selectedRows);
  onKeepRecordRowSelected(selectedRows);

  const getRowClassName = (rowData: any) => {
    // const value = modifiedUniqueNames.filter((item => (item.charAt(3) === "*" ? rowData.resource.socialInsuranceNumber : item.length === 9 && /^\d+$/.test(item) ? rowData.resource.socialInsuranceNumber : rowData.employeName)))
    // (rowData.resource.socialInsuranceNumber.charAt(2) === '*' || (rowData.resource.socialInsuranceNumber.length === 9 && /^\d+$/.test(rowData.resource.socialInsuranceNumber)))
    // ? rowData.resource.socialInsuranceNumber
    // : rowData.employeName;
    // for(let i = 0; i < rows && flattenedRows.length; i++){
    //   const values = modifiedUniqueNames.map(item => {
    //     if (item.charAt(3) === "*" || (item.length === 9 && /^\d+$/.test(item))) {
    //       return 'rowData.resource.socialInsuranceNumber';
    //     } else {
    //       return 'rowData.employeName';
    //     }
    //   });
    //   console.log("valueqw",values)
    // }
    
    // console.log("updatedUniqueNamesqw",updatedUniqueNames)
    // const index = modifiedUniqueNames.indexOf((value:any) => rowData.employeName && rowData.resource.socialInsuranceNumber) % 2 === 0 ? 'red' : 'yellow';
    const index = updatedUniqueNames.indexOf(
      updatedUniqueNames.some(value => value.charAt(3) === '*') ?
        rowData.resource.socialInsuranceNumber :
        rowData.employeName
    ) % 2 === 0 ? 'red' : 'yellow';
    console.log("valueqw",index)

    return `row-${index}`;
  };

  return (
    <Card
      title="Manage Conflicts"
      subTitle="System has duplicate employee(s), you can either select them and merge into one press Ignore."
      footer={footer}
      className="mt-5"
    >
      <DataTable
        value={rows && flattenedRowsWithClasses && rowsWithTwoDecimalNumbers}
        responsiveLayout="scroll"
        paginator
        rows={INITIAL_PAGE_SIZE}
        rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
        selection={selectedItems}
        onSelectionChange={(e: any) => setSelectedItems(e.value)}
        editMode="row"
        showGridlines
        resizableColumns
        columnResizeMode="expand"
        scrollable
        // scrollHeight="450px"
        reorderableColumns
        filterDisplay="menu"
        loading={isLoading}
        globalFilterFields={["headerName"]}
        emptyMessage="No Record found."
        // className='m-2'
        className="mergeResourcesDataTable"
        headerColumnGroup={headerGroup}
        style={{ margin: "0.5rem" }}
        // rowClassName={(rowData:any) => `row-${modifiedUniqueNames.indexOf(rowData.resource.socialInsuranceNumber) % 2 === 0 ? 'red' : 'yellow'}`}
        // rowClassName={getRowClassName}
        rowClassName={(rowData:any) => rowData.className}

        // rowGroupMode="subheader" groupRowsBy='resource.socialInsuranceNumber' sortMode='single' sortField='resource.socialInsuranceNumber'
        //             sortOrder={1}
        //             rowGroupHeaderTemplate={headerTemplate}
      >
        <Column
          selectionMode="multiple"
          hidden={showChecboxColumn}
          frozen
          resizeable={false}
          style={{ maxWidth: "65px" }}
          field="selection"
          columnKey="selection"
          headerStyle={{ backgroundColor: "#e3fcef" }}
        ></Column>

        {/* <Column
                    headerStyle={{ backgroundColor:"#e3fcef" }}
                    
                    header="Keep Record"
                    frozen
                    body={(rowData) => (
                    <input
                        type="radio"
                        checked={isSelected}
                        onChange={() => handleSingleSelectionc(rowData)}
                        style={{
                            height:"16px",
                            width:"16px",
                            display:"flex",
                            margin:"0 2.3rem"
                        }}
                    />
                    )}
                ></Column> */}
        <Column
          headerStyle={{ backgroundColor: "#e3fcef" }}
          header="Keep Record"
          frozen
          body={rowExpansionTemplate}
        />

        {columns !== undefined &&
          columns.map((x: any, index: any) => (
            <Column
              key={index}
              field={x.field}
              header={x.headerName}
              headerTooltip={x.description}
              headerTooltipOptions={{ position: "top" }}
              // flex (This property is not use Yet)
              hidden={
                val === x.hide
                  ? clickHideBtn(x, x.field, false)
                  : x.field === field
                  ? val
                  : x.hide
              }
              sortable={x.sortable}
              resizeable={x.resizable}
              dataType={x.type}
              align={x.align}
              alignHeader={x.headerAlign}
              // hideSortIcons (This property is not use Yet)
              // filterElement={HideButton(x.hideable, x, x.field)}
              // showFilterMenuOptions
              // filter
              // showFilterMenu={x.disableColumnMenu}
              // filterPlaceholder="Search by name"
              reorderable={x.disableReorder}
              exportable={x.disableExport}
              frozen={x.frozenColumn}
              columnKey={x.field}
              style={{
                minWidth: x.minWidth,
                fontSize: "0.875rem",
              }}
              footer={x.footer}
              body={rows.employmentIncome}
            />
          ))}

        <Column
          field="id"
          header="No."
          hidden={showIndexColumn}
          headerStyle={{ fontWeight: "bold" }}
          style={{ maxWidth: 60, padding: "16px 25px" }}
          resizeable={false}
          reorderable={false}
          frozen={true}
          columnKey="id"
        ></Column>
        <Column
          body={delEdit}
          header={setting}
          style={{ maxWidth: "65px" }}
          hidden={showSettingColumn}
          resizeable={false}
          reorderable={false}
          frozen
          alignFrozen="right"
        ></Column>
      </DataTable>
    </Card>
  );
};

export default PrimeDataGridMergeResource;

//resizeablbe must be false if frozen is true and viseversa
