import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { createStyles, makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';
import React, { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
interface ISlipsPanel {
    formMethods:any;

    t4PdfFileName:string;
    t4PdfSavedFile:any;
    onT4OpenFileClicked:()=>void;

    t4aPdfFileName:string;
    t4aPdfSavedFile:any;
    onT4aOpenFileClicked:()=>void;

    rl1PdfFileName:string;
    rl1PdfSavedFile:any;
    onRL1OpenFileClicked:()=>void;

    t5PdfFileName:string;
    t5PdfSavedFile:any;
    onT5OpenFileClicked:()=>void;
    
    rl3PdfFileName:string;
    rl3PdfSavedFile:any;
    onRL3OpenFileClicked:()=>void;

    t3PdfFileName:string;
    t3PdfSavedFile:any;
    onT3OpenFileClicked:()=>void;
    
    rl16PdfFileName:string;
    rl16PdfSavedFile:any;
    onRL16OpenFileClicked:()=>void;
}

const useStyles = makeStyles(() =>
    createStyles({
      titleStyle: {
        fontSize: ".9rem",
        color: "#000",
        padding: "16px 16px 16px 26px",
        fontWeight: "bold",
      },
      line: {
        borderColor: "rgba(0,0,0,.12)",
        border: "0",
        borderTop: "1px solid rgba(0,0,0,.1)",
        margin: 0,
      },
      subTitle: {
        color: "rgba(0,0,0,.6)",
        fontSize: ".875rem",
        // p: "12px",
      },
    })
  );

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
const SlipsPanel:React.FC<ISlipsPanel> = ({
    formMethods,
    t4PdfFileName,
    t4PdfSavedFile,
    onT4OpenFileClicked,
    t4aPdfFileName,
    t4aPdfSavedFile,
    onT4aOpenFileClicked,
    rl1PdfFileName,
    rl1PdfSavedFile,
    onRL1OpenFileClicked,
    t5PdfFileName,
    t5PdfSavedFile,
    onT5OpenFileClicked,
    rl3PdfFileName,
    rl3PdfSavedFile,
    onRL3OpenFileClicked,
    t3PdfFileName,
    t3PdfSavedFile,
    onT3OpenFileClicked,
    rl16PdfFileName,
    rl16PdfSavedFile,
    onRL16OpenFileClicked,
}) => {
    const classes = useStyles();

    const [t4FileName, setT4FileName] = useState<string>('');
    const [t4PdfSavedPdfFile, setT4PdfSavedFile] = useState(t4PdfSavedFile); 
    useEffect(()=>{
        setT4PdfSavedFile(t4PdfSavedFile)
        if(t4PdfSavedFile === false){
            setT4FileName('Choose a file')
        }
    },[t4PdfSavedFile])  
    const handleT4FileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setT4FileName(file.name);
        setT4PdfSavedFile(false)
      }
    };

    const [t4aFileName, setT4AFileName] = useState<string>('');
    const [t4aPdfSavedPdfFile, setT4APdfSavedFile] = useState(t4aPdfSavedFile); 
    useEffect(()=>{
        setT4APdfSavedFile(t4aPdfSavedFile)
        if(t4aPdfSavedFile === false){
            setT4AFileName('Choose a file')
        }
    },[t4aPdfSavedFile])  
    const handleT4AFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setT4AFileName(file.name);
        setT4APdfSavedFile(false)
      }
    };

    const [rl1FileName, setRL1FileName] = useState<string>('');
    const [rl1PdfSavedPdfFile, setRL1PdfSavedFile] = useState(rl1PdfSavedFile); 
    useEffect(()=>{
        setRL1PdfSavedFile(rl1PdfSavedFile)
        if(rl1PdfSavedFile === false){
            setRL1FileName('Choose a file')
        }
    },[rl1PdfSavedFile])  
    const handleRL1FileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setRL1FileName(file.name);
        setRL1PdfSavedFile(false)
      }
    };

    const [t5FileName, setT5FileName] = useState<string>('');
    const [t5PdfSavedPdfFile, setT5PdfSavedFile] = useState(t5PdfSavedFile); 
    useEffect(()=>{
        setT5PdfSavedFile(t5PdfSavedFile)
        if(t5PdfSavedFile === false){
            setT5FileName('Choose a file')
        }
    },[t5PdfSavedFile])  
    const handleT5FileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setT5FileName(file.name);
        setT5PdfSavedFile(false)
      }
    };

    const [rl3FileName, setRL3FileName] = useState<string>('');
    const [rl3PdfSavedPdfFile, setRL3PdfSavedFile] = useState(rl3PdfSavedFile); 
    useEffect(()=>{
        setRL3PdfSavedFile(rl3PdfSavedFile)
        if(rl3PdfSavedFile === false){
            setRL3FileName('Choose a file')
        }
    },[rl3PdfSavedFile])  
    const handleRL3FileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setRL3FileName(file.name);
        setRL3PdfSavedFile(false)
      }
    };

    const [t3FileName, setT3FileName] = useState<string>('');
    const [t3PdfSavedPdfFile, setT3PdfSavedFile] = useState(t3PdfSavedFile); 
    useEffect(()=>{
        setT3PdfSavedFile(t3PdfSavedFile)
        if(t3PdfSavedFile === false){
            setT3FileName('Choose a file')
        }
    },[t3PdfSavedFile])  
    const handleT3FileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setT3FileName(file.name);
        setT3PdfSavedFile(false)
      }
    };

    const [rl16FileName, setRL16FileName] = useState<string>('');
    const [rl16PdfSavedPdfFile, setRL16PdfSavedFile] = useState(rl16PdfSavedFile); 
    useEffect(()=>{
        setRL16PdfSavedFile(rl16PdfSavedFile)
        if(rl16PdfSavedFile === false){
            setRL16FileName('Choose a file')
        }
    },[rl16PdfSavedFile])  
    const handleRL16FileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setRL16FileName(file.name);
        setRL16PdfSavedFile(false)
      }
    };
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{alignItems:"center",pb:"3.1rem"}}>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                T4 PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {t4PdfSavedPdfFile &&
            // <Button
            //     variant="contained"
            //     sx={{padding:"0px 7px 0px 10px",fontSize:"small",marginRight:"5px"}}
            //     onClick={onT4OpenFileClicked}
            // >Open Saved File</Button>
            <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onT4OpenFileClicked}/>
            </Tooltip>
            }
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px"}}>
                {/* <input
                    id='t4Pdf'
                    {...formMethods.register("t4Pdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)", }}
                /> */}
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='t4Pdf' type="file" accept=".pdf" {...formMethods.register("t4Pdf")}
                    onChange={handleT4FileChange}/>
                </Button>
                <label
                    htmlFor="t4Pdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {t4PdfSavedPdfFile && t4PdfFileName !== '' ? t4PdfFileName : t4FileName !== '' ? t4FileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                T4A PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {t4aPdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onT4aOpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='t4aPdf'
                    {...formMethods.register("t4aPdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='t4aPdf' type="file" accept=".pdf" {...formMethods.register("t4aPdf")}
                    onChange={handleT4AFileChange}/>
                </Button>
                <label
                    htmlFor="t4aPdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {t4aPdfSavedPdfFile && t4aPdfFileName !== '' ? t4aPdfFileName : t4aFileName !== '' ? t4aFileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5 }sx={{display:"flex",alignItems:"center"}}>
            <Typography className={classes.subTitle}>
                RL-1 PDF File
            </Typography>
                <Tooltip title={'Please merge both Copy 1 and Copy 2 and then upload.'} placement={'top'}>
                  <InfoIcon sx={{fontSize:"1.1rem",margin:"4px"}}/>
              </Tooltip>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {rl1PdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onRL1OpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='rl1Pdf'
                    {...formMethods.register("rl1Pdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='rl1Pdf' type="file" accept=".pdf" {...formMethods.register("rl1Pdf")}
                    onChange={handleRL1FileChange}/>
                </Button>
                <label
                    htmlFor="rl1Pdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {rl1PdfSavedPdfFile && rl1PdfFileName !== '' ? rl1PdfFileName : rl1FileName !== '' ? rl1FileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                T5 PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {t5PdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onT5OpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='t5Pdf'
                    {...formMethods.register("t5Pdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='t5Pdf' type="file" accept=".pdf" {...formMethods.register("t5Pdf")}
                    onChange={handleT5FileChange}/>
                </Button>
                <label
                    htmlFor="t5Pdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {t5PdfSavedPdfFile && t5PdfFileName !== '' ? t5PdfFileName : t5FileName !== '' ? t5FileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                RL-3 PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {rl3PdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onRL3OpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='rl3Pdf'
                    {...formMethods.register("rl3Pdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='rl3Pdf' type="file" accept=".pdf" {...formMethods.register("rl3Pdf")}
                    onChange={handleRL3FileChange}/>
                </Button>
                <label
                    htmlFor="rl3Pdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {rl3PdfSavedPdfFile && rl3PdfFileName !== '' ? rl3PdfFileName : rl3FileName !== '' ? rl3FileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                T3 PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {t3PdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onT3OpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='t3Pdf'
                    {...formMethods.register("t3Pdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='t3Pdf' type="file" accept=".pdf" {...formMethods.register("t3Pdf")}
                    onChange={handleT3FileChange}/>
                </Button>
                <label
                    htmlFor="t3Pdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {t3PdfSavedPdfFile && t3PdfFileName !== '' ? t3PdfFileName : t3FileName !== '' ? t3FileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography className={classes.subTitle}>
                RL-16 PDF File
            </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{textAlign:"end"}}>
            {rl16PdfSavedPdfFile &&
                <Tooltip title={'Open saved file.'} placement={'top'}>
                <VisibilityIcon sx={{fontSize:"1.1rem",margin:"7px 4px 4px 4px",cursor:'pointer'}} 
                onClick={onRL16OpenFileClicked}/>
              </Tooltip>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ p: "12px" }}>
                {/* <input
                    id='rl3Pdf'
                    {...formMethods.register("rl3Pdf")}
                    accept=".pdf"
                    type="file"
                    // onChange={handleFileChange}
                    style={{ color: "rgba(0,0,0,.6)" }}
                /> */}
                
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{padding:"0px 7px 0px 10px",fontSize:"small"}}
                    >
                    Upload file
                    <VisuallyHiddenInput id='rl16Pdf' type="file" accept=".pdf" {...formMethods.register("rl16Pdf")}
                    onChange={handleRL16FileChange}/>
                </Button>
                <label
                    htmlFor="rl16Pdf"
                    style={{ color: "rgba(0,0,0,.6)", cursor: 'pointer', paddingLeft:"5px" }}
                >
                     {rl16PdfSavedPdfFile && rl16PdfFileName !== '' ? rl16PdfFileName : rl16FileName !== '' ? rl16FileName : 'Choose a file'} 
                </label>
            </Box>
        </Grid>

    </Grid>
  )
}

export default SlipsPanel