import { LeftMenu } from "components/LeftMenu";

const data = [
  {
    id: 10410,
    text: "T4",
    open: false,
    menu: [
      {
        menuId: 10411,
        text: "List",
        navigateToURL: "/taxslip/t4/list",
        parentMenuId: 10410,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10412,
        text: "Setup",
        navigateToURL: "/taxslip/t4/setup",
        parentMenuId: 10410,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10413,
        text: "Summary",
        navigateToURL: "/taxslip/t4/summary",
        parentMenuId: 10410,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10414,
        text: "Summary Reduced EI",
        navigateToURL: "/taxslip/t4/summary-reduced-ei",
        parentMenuId: 10410,
        grandParentMenuId: 10400,
      },
      // {
      //   menuId: 10415,
      //   text: "PD27 E Setup",
      //   navigateToURL: "/taxslip/t4/pd27-e-setup",
      //   parentMenuId: 10410,
      //   grandParentMenuId: 10400,
      // },
    ],
    parentMenuId: 10400
  },
  {
    id: 10420,
    text: "T4A",
    open: false,
    menu: [
      {
        menuId: 10421,
        text: "List",
        navigateToURL: "/taxslip/t4a/list",
        parentMenuId: 10420,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10422,
        text: "Setup",
        navigateToURL: "/taxslip/t4a/setup",
        parentMenuId: 10420,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10423,
        text: "Summary",
        navigateToURL: "/taxslip/t4a/summary",
        parentMenuId: 10420,
        grandParentMenuId: 10400,
      },
    ],
    parentMenuId: 10400
  },
  {
    id: 10430,
    text: "T5",
    open: false,
    menu: [
      {
        menuId: 10421,
        text: "List",
        navigateToURL: "/taxslip/t5/list",
        parentMenuId: 10430,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10422,
        text: "Setup",
        navigateToURL: "/taxslip/t5/setup",
        parentMenuId: 10430,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10423,
        text: "Summary",
        navigateToURL: "/taxslip/t5/summary",
        parentMenuId: 10430,
        grandParentMenuId: 10400,
      },
    ],
    parentMenuId: 10400
  },
  {
    id: 10440,
    text: "T3",
    open: false,
    menu: [
      {
        menuId: 10431,
        text: "List",
        navigateToURL: "/taxslip/t3/list",
        parentMenuId: 10440,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10432,
        text: "Setup",
        navigateToURL: "/taxslip/t3/setup",
        parentMenuId: 10440,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10433,
        text: "Summary",
        navigateToURL: "/taxslip/t3/summary",
        parentMenuId: 10440,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10434,
        text: "Settings",
        navigateToURL: "/taxslip/t3/settings",
        parentMenuId: 10440,
        grandParentMenuId: 10400,
      },
    ],
    parentMenuId: 10400
  },
  {
    id: 10450,
    text: "RL-1",
    open: false,
    menu: [
      {
        menuId: 10441,
        text: "List",
        navigateToURL: "/taxslip/rl1/list",
        parentMenuId: 10450,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10442,
        text: "Setup",
        navigateToURL: "/taxslip/rl1/setup",
        parentMenuId: 10450,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10443,
        text: "Summary",
        navigateToURL: "/taxslip/rl1/summary",
        parentMenuId: 10450,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10444,
        text: "Settings",
        navigateToURL: "/taxslip/rl1/settings",
        parentMenuId: 10450,
        grandParentMenuId: 10400,
      },
    ],
    parentMenuId: 10400
  },
  {
    id: 10460,
    text: "RL-3",
    open: false,
    menu: [
      {
        menuId: 10451,
        text: "List",
        navigateToURL: "/taxslip/rl3/list",
        parentMenuId: 10460,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10452,
        text: "Setup",
        navigateToURL: "/taxslip/rl3/setup",
        parentMenuId: 10460,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10453,
        text: "Summary",
        navigateToURL: "/taxslip/rl3/summary",
        parentMenuId: 10460,
        grandParentMenuId: 10400,
      },
    ],
    parentMenuId: 10400
  },
  {
    id: 10470,
    text: "RL-16",
    open: false,
    menu: [
      {
        menuId: 10461,
        text: "List",
        navigateToURL: "/taxslip/rl16/list",
        parentMenuId: 10470,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10462,
        text: "Setup",
        navigateToURL: "/taxslip/rl16/setup",
        parentMenuId: 10470,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10463,
        text: "Summary",
        navigateToURL: "/taxslip/rl16/summary",
        parentMenuId: 10470,
        grandParentMenuId: 10400,
      },
      {
        menuId: 10464,
        text: "Settings",
        navigateToURL: "/taxslip/rl16/settings",
        parentMenuId: 10470,
        grandParentMenuId: 10400,
      },
    ],
    parentMenuId: 10400
  },
];

export const SlipsMenu = () => {
  return <LeftMenu menuItems={data} />;
};
