import * as yup from 'yup';

export const FieldsetSchema = yup.object({
  screenId: yup
    .string()
    .required("Screen selection is required"),
  tabId: yup
    .string()
    .required("Tab selection is required"),
  categoryFieldId: yup
    .string()
    .required("Category field selection is required"),
  categoryFieldValue: yup
    .string()
    .required("Category field value selection is required"),
});
