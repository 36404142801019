import { IResponse, IServerError } from "models/common";
import {
  ITaxSlipCategoryRequestPayload,
} from "models";
import { AxiosError, AxiosResponse } from "axios";
import http from "common/http-common";
import { IPDFFilesPayload } from "components/PDF Settings/PDF Files";

// TODO: replace this with API response once it's ready
export const PDFSettingsService = {
  getPDFFilesByTaxYear: async (
    taxYear: number | undefined): Promise<any> => {
      if(taxYear){
        try {
          const response: AxiosResponse<any> = await http.get<any>(
            `/v1/taxslip-definitions/${taxYear}/get-by-year/`);
          console.log("response", response.data);
      
          if (response.status === 200) {
            return response.data;
          } else {
            console.log("Response status:", response.status);
            return { success: false, data: response.data };
          }
        } catch (error) {
          const axiosError = error as AxiosError;
          console.error("An error occurred:", axiosError);
          return { success: false, data: axiosError.response ? axiosError.response.data : "Unknown error" };
        }
    }
  },
  createPDFFiles: async (
    requestPayload: IPDFFilesPayload
  ): Promise<any | IServerError | IResponse> => {
    console.log("requestPayload ==>> add request", requestPayload);
    const response: AxiosResponse<any> =
      await http.post<any>(
        `/v1/taxslip-definitions/save-pdf/?tax_year=${requestPayload.year}`,
        requestPayload
      );

    if (response.status === 201) {
      return {
        status: true,
        message: "Data has been Submitted successfully.",
        error: false,
      };
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  },
  createPDFAxis: async (
    requestPayload: any
  ): Promise<any | IServerError | IResponse> => {
    console.log("requestPayload ==>> add request", requestPayload);
    try{
    const response: AxiosResponse<any> =
      await http.post<any>(
        `/v1/pdf-axis/${
          requestPayload.tag === 't4Summary' ? 't4-summary' : 
          requestPayload.tag === 't4aSummary' ? 't4a-summary' : 
          requestPayload.tag === 'rl1SummaryEnglish' ? 'rl1-summary-english' : 
          requestPayload.tag === 'rl1SummaryFrench' ? 'rl1-summary-french' : 
          requestPayload.tag === 't5Summary' ? 't5-summary' : 
          requestPayload.tag}/?tax_year=${requestPayload.taxYear}`,
        requestPayload.payload
      );

    if (response.status === 201) {
      console.log("response 300 create",response.data)
      return response.data;
    } else if (response.status === 500) {
      return {
        status: false,
        message: "Something went wrong.",
        error: true,
      };
    }

    return {
      status: false,
      message: "Something went wrong please try again later.",
    };
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error("An error occurred:", axiosError);
    const errorMessage =
      axiosError.response && typeof axiosError.response.data === "string"
        ? axiosError.response.data
        : "Unknown error";
    // if (axiosError && axiosError.status === 401) {
    //   navigateToLogout("/logout");
    //   return {
    //     status: false,
    //     message: "UnAuthrized",
    //   };
    // } else {
    //   console.log("errorMessage", errorMessage);
      return {
        status: false,
        message: errorMessage,
      };
    // }
  }
  },
  getPDFAxisData: async (
    slipName:string,
    taxYear: number): Promise<any> => {
      if(taxYear){
        try {
          const response: AxiosResponse<any> = await http.get<any>(
            `/v1/pdf-axis/pdf-axis/?slip_name=${slipName}&tax_year=${taxYear}`);
          console.log("response", response.data);
      
          if (response.status === 200) {
            // return { success: true, data: response.data };
            return response.data;
          } else {
            console.log("Response status:", response.status);
            return { success: false, data: response.data };
          }
        } catch (error) {
          const axiosError = error as AxiosError;
          console.error("An error occurred:", axiosError);
          return { success: false, data: axiosError.response ? axiosError.response.data : "Unknown error" };
        }
      }
    }
};
