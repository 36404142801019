import React, { FC } from "react";
import NumberFormat from 'react-number-format';
import { useFormContext } from "react-hook-form";
import { useController } from "react-hook-form";
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import TextField from "@mui/material/TextField";
import { ITest } from "@websential/cosmic";


/**
 * Text fields allow users to enter text into a UI. They typically appear in forms and dialogs.
 * The EnhancedTextInputWR wrapper component is a complete form control using Material UI TextField including
 * a label, input, and help text. Websential is using the outlined variants.
 */
export interface IRHookFormNumericFormatTextField extends ITest {
  /**
   * The id of the input element.
   */
  id: string;
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string;
  /**
   * If true, text input in the field will be shown in upper case
   * but the value will be in same case as typed by the user
   * you need to convert the text to upper case in case server required it in upper case
   */
  uppercase?: boolean;
  /**
   * Set the value of field; it is normally used when edit operation of the entity is required
   */
  value?: string | number;
  fieldValue?: string | number;
  defaultValue?: string | number;
  onChange?: any;
  /**
   * If true, will not allow user to edit value
   */
  readOnly?: boolean;

  textAlign: any;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string | number;
  defaultValue: string | number;
}


const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, value, defaultValue, ...other } = props;

    console.log("props--",props)
    console.log("value--",value)
    console.log("other--",other)
    console.log("defaultValue--",defaultValue)

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        value={value}
        defaultValue={defaultValue}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        // prefix="$"
      />
    );
  },
);

export const RHookFormNumericFormatTextField: FC<IRHookFormNumericFormatTextField> = ({
  id,
  testId,
  placeholder = "",
  uppercase = false,
  readOnly = false,
  textAlign,
//   value,
fieldValue,
  defaultValue,
  onChange,
}: IRHookFormNumericFormatTextField) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const {
    field: { value },
    // formState: { errors },
  } = useController({
    name: id,
    defaultValue: defaultValue,
  });

  const [values, setValues] = React.useState<any>({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("event", event)
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  console.log("use states values", values)
  console.log("controller value", value)
  console.log("fieldValue--", fieldValue)

  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth={true}
      margin="dense"
      id={id}
      value={fieldValue ? fieldValue : value ? value :  values.id}
      // value={fieldValue ? fieldValue : value ? value :  values.id ? values.id : '0.00'}
      // defaultValue={defaultValue ? defaultValue : '0.00'}
      defaultValue={defaultValue}
      {...register(id)}
      onChange={onChange ? onChange : handleChange}
      placeholder={placeholder}
      error={!!errors[id]}
      helperText={errors[id]?.message}
      inputProps={{
        "data-testid": testId + "-field",
        style: {
          textTransform: uppercase ? "uppercase" : "none",
          backgroundColor: readOnly ? "#f2f2f2" : "white",
          textAlign: textAlign,
        },
        readOnly: readOnly,
        // inputComponent: NumericFormatCustom as any,
      }}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
      }}
    />
    // <NumberFormat
    //   value={value}
    //   thousandSeparator
    //   isNumericString
    //   customInput={TextField}
    //   variant="outlined"
    //   size="small"
    //   fullWidth={true}
    //   margin="dense"
    //   id={id}
    //   defaultValue={defaultValue}
    //   {...register(id)}
    //   onValueChange={(values:any) => {
    //     onChange({
    //       target: {
    //         value: values.value,
    //       },
    //     } as React.ChangeEvent<HTMLInputElement>);
    //   }}
    //   placeholder={placeholder}
    //   error={!!errors[id]}
    //   helperText={errors[id]?.message}
    //   inputProps={{
    //     'data-testid': `${testId}-field`,
    //     style: {
    //       textTransform: uppercase ? 'uppercase' : 'none',
    //       backgroundColor: readOnly ? '#f2f2f2' : 'white',
    //       textAlign: textAlign,
    //     },
    //     readOnly: readOnly,
    //   }}
    // />
  );
};
