import { FC, useEffect, useState } from "react";
import React, { useRef } from 'react';

import { RL1SlipSummaryDataInput } from "../../RL1SlipSummaryDataInput";
import { EnhancedDropDownRHFWithLabel, LabelPosition } from "@websential/cosmic";

export interface ICNTFinancingContributionPanel {
  cntRate:number | null | undefined;
  secondCntRate:number | null | undefined;
  formMethods:any;
  taxYear:number;
  cntAmountWhileUpdate:string;
  employerCode?:number;
}

export const CNTFinancingContributionPanel: FC<ICNTFinancingContributionPanel> =
  ({cntRate,secondCntRate,formMethods,cntAmountWhileUpdate,taxYear,employerCode}) => {
    const [inputValue, setInputValue] = useState("0.00");
    useEffect(()=>{
      if(cntAmountWhileUpdate !== "0.00"){
        setInputValue(cntAmountWhileUpdate)
      }
    },[cntAmountWhileUpdate])
    const [cnt, setCnt] = useState<any>(cntRate && cntRate !== null ? cntRate : 0);

    const handleInputChange = (event:any) => {
      const newValue = event.target.value;
      console.log("newValue",newValue)
      console.log("cnt--00",cnt)
      if(cnt){
        let result = cnt * newValue / 100
        console.log("result",result)
        let convertResultToStr = result.toString()
        setInputValue(parseFloat(convertResultToStr).toFixed(2));
      }
    };

    const onEmployerCodeSelectionChangeHandler = (value:any) => {
      console.log("value11",value)
      if(value.value !== ""){
        setCnt(secondCntRate && secondCntRate !== null ? secondCntRate : 0)
        // const remunerationSubjectToContribution = formMethods.getValues('remunerationSubjectToContribution')
        const remunerationSubjectToContribution = parseFloat(formMethods.getValues("remunerationSubjectToContribution") && typeof(formMethods.getValues("remunerationSubjectToContribution")) === "string" ? formMethods.getValues("remunerationSubjectToContribution").replace(/[$,]/g, '') : formMethods.getValues("remunerationSubjectToContribution"));
        console.log("remunerationSubjectToContribution",remunerationSubjectToContribution)
        console.log("cnt",cnt)
        console.log("secondCntRate",secondCntRate)
        let result = (secondCntRate && secondCntRate !== null ? secondCntRate : 0) * remunerationSubjectToContribution / 100
        console.log("result",result)
        let convertResultToStr = result.toString()
        setInputValue(parseFloat(convertResultToStr).toFixed(2));
      } else  {
        setCnt(cntRate && cntRate !== null ? cntRate : 0)
        const remunerationSubjectToContribution = parseFloat(formMethods.getValues("remunerationSubjectToContribution") && typeof(formMethods.getValues("remunerationSubjectToContribution")) === "string" ? formMethods.getValues("remunerationSubjectToContribution").replace(/[$,]/g, '') : formMethods.getValues("remunerationSubjectToContribution"));
        console.log("remunerationSubjectToContribution",remunerationSubjectToContribution)
        console.log("cnt",cnt)
        console.log("cntRate",cntRate)
        let result = (cntRate && cntRate !== null ? cntRate : 0) * remunerationSubjectToContribution / 100
        console.log("result",result)
        let convertResultToStr = result.toString()
        setInputValue(parseFloat(convertResultToStr).toFixed(2));
      }
    }

    useEffect(()=>{
      const employerCode = formMethods.getValues('employerCode')
      console.log("employerCode--",employerCode)
      if(employerCode && employerCode !== ""){
        setCnt(secondCntRate && secondCntRate !== null ? secondCntRate : 0)
      } else if(employerCode !== null){
        setCnt(secondCntRate && secondCntRate !== null ? secondCntRate : 0)
      } else {
        setCnt(cntRate && cntRate !== null ? cntRate : 0)
      }
    },[formMethods,cntRate,secondCntRate,employerCode])
    console.log("cnt--",cnt)
    console.log("cntRate--",cntRate)
    console.log("inputValue--",inputValue)
    return (
      <>
        {taxYear > 2023 ? <EnhancedDropDownRHFWithLabel
          label="Employer Code"
          selectName="employerCode"
          items={[
            { label: "--", value: "" },
            { label: "Municipality, metropolitan community or public transit authority", value: 10 },
            {
              label:
                "School service centre, school board or educational institution",
              value: 20,
            },
            { label: "Daycare centres", value: 30 },
            { label: "Public institution that provides health services and social services", value: 40 }
          ]}
          testId="employer-code"
          labelPosition={LabelPosition.Left}
          labelColumns={8}
          fieldColumns={4}
          onSelectionChangeHandler={onEmployerCodeSelectionChangeHandler}
        /> : <></>}
        <RL1SlipSummaryDataInput
          id="remunerationSubjectToContribution"
          label="Remuneration subject to contribution (Calculated on LE-39.0.2-V form)"
          readOnly={false}
          onChange={handleInputChange}
        />
        <RL1SlipSummaryDataInput id="cntRatePercentage" 
        value={cnt && cnt !== null ? cnt : 0} 
        textfield={true}
        label="CNT Rate %" />
        <RL1SlipSummaryDataInput id="cntAmount" label="CNT Amount" value={inputValue}/>
      </>
    );
  };
