import { toSnake } from "common/methods";
import { IGetListRequest } from "models";
import React, { useCallback, useEffect, useState } from "react";
// import { EnhancedDDLBulkAction } from "@websential/ws-react-ui/lib/components/EnhancedDDLBulkAction";
// import { EnhancedDialog } from "@websential/ws-react-ui/lib/components/EnhancedDialog";
// import { ErrorState } from "@websential/ws-react-ui/lib/components/ErrorState/ErrorState";
// import { StatusChip } from "@websential/ws-react-ui/lib/components/StatusChip";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ResourceService } from "services/ResourceService";
import { IAppState } from "store/reducers";
import DeleteIcon from '@mui/icons-material/Delete';

import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import {
  BackdropCircularProgress,
  DeleteConfirmationDialog,
  EmptyList,
  EnhancedDialog,
  EnhancedLinkButton,
  EnhancedSnackbar,
  IResponse,
  IServerError,
  RouterLinkButton,
  SplitButton,
} from "@websential/cosmic";

import { EnhancedDataGrid } from "../../EnhancedDataGrid";
import {
  EMPLOYEE_LIST_SCREEN_ID,
  EMPLOYEE_LIST_SCREEN_SLUG,
  INITIAL_PAGE_SIZE,
  INITIAL_SNACKBAR_DATA,
  ROWS_PER_PAGE_PER_OPTIONS,
  SEARCH_DEBOUNCE_DELAY_IN_SECONDS,
  T3_BENEFICIARY_LIST_SCREEN_SLUG,
  T5_RECIPIENT_LIST_SCREEN_ID,
  T5_RECIPIENT_LIST_SCREEN_SLUG,
  VENDOR_LIST_SCREEN_ID,
  VENDOR_LIST_SCREEN_SLUG,
} from "common/constants";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { PrimeDataGrid } from "components/PrimeDataGrid";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { clearSelectedResourceApiMessageActions } from "store/actions/resourceApiMessageActions";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export let resourseIdSave = 0;
export let RESOURCE_LIST_QUERY_KEY = "";
export interface IResourceList {}
const useResources = (
  clientId: string,
  taxYear: number,
  resourceName: string,
  params: IGetListRequest
) =>
  useQuery(
    [`${resourceName.toLowerCase()}-resource-list`, params],
    (data) => {
      return ResourceService.getList(clientId,taxYear, resourceName, params);
    },
    { refetchOnWindowFocus: false }
  );

// console.log("resourceNameNew ///// ",resourceNameNew)
export let totalEmp = ''
export let totalVen = ''
export const ResourceList: React.FC<IResourceList> = () => {
  const { resourceName } = useParams();
  const [dataRoleName, setDataRoleName] = useState<string>("");
  const { id } = useSelector((state: IAppState) => state.client);
  const taxYear = useSelector((state: IAppState) => state.taxYear.year);
  const isLoadingActivityLogData = useSelector((state: IAppState) => state.isLoadAxiosCall.isLoading);

  const [resourceListUpdatedItems, setResourceListUpdatedItems] = useState<any>(undefined);
  const dispatch = useDispatch();
  const clientName = useSelector((state : IAppState) => state.client.clientName);

  // for delete row Purpose
  const queryClient = useQueryClient();
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  RESOURCE_LIST_QUERY_KEY = `${dataRoleName.toLowerCase()}-resource-list`;

  useEffect(() => {
    if (resourceName && resourceName.length > 0) {
      console.log("resourceName>>>>>", resourceName);
      setDataRoleName(resourceName);
    }
  }, [dataRoleName, resourceName]);

  const theme = useTheme();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<IGetListRequest>({
    search_query: "",
    order_by: "label",
    order_direction: "asc",
    page: 1,
    size: INITIAL_PAGE_SIZE,
  });

  const { isLoading, data } = useResources(
    id,
    taxYear,
    String(resourceName),
    queryParams
  ); //useQuery('accountants-list', AccountantService.getAccountantList, { refetchOnWindowFocus: false });
  const resourceList: any = data;
  console.log("@@@@@ => resourceList", resourceList);

  //Whenever Axios call is loading data it will effct on loading either slipList is undefined or not
  const [isAxiosCallLoading, setIsAxiosCallLoading] = useState(false);
  useEffect(()=>{
    if(isLoading){
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    } else {
      setIsAxiosCallLoading(true)
      setTimeout(()=>{
        setIsAxiosCallLoading(false)
      },1000)
    }
  },[isLoading])

  // totalRes = resourceList.total
  console.log("@@@@@ => totaltotalEmp", totalEmp);
  console.log("@@@@@ => totaltotalVen", totalVen);
  console.log("@@@@@ => resourceName", resourceName);

  if(resourceName !== undefined && resourceList !== undefined && resourceName === 'Employee'){
  totalEmp = resourceList.total
  }
  else if(resourceName !== undefined && resourceList !== undefined && resourceName === 'Vendor'){
  totalVen = resourceList.total
  }

  const { message, componentState } = useSelector(
    (state: IAppState) => state.viewState
  );
  // console.log("masked = >", masked);
  // const { accountantList } = useSelector(
  //   (state: IAppState) => state.accountant
  // );

  const [openDialog, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [emptyList, setEmptyList] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [searchText, setSearchText] = useState({});
  const [zeroResource, setzeroResource] = useState(true);

  // for delete row
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [resourceId, setResourceId] = useState<number>(0);
  const [apiKey, setApiKey] = useState("");
  const [service, setService] = useState("");
  const[openSingleDeleteDialogOpen, setSingleDeleteDialogOpen] = useState(false)


  const onRequestParamsChange = useCallback((requestParams) => {
    console.log("##### - request params", requestParams);
    // let dispatchParams = {
    //   search_query: requestParams.debouncedSearchQuery,
    //   // order_by: "role_name",
    //   order_by: "name1",
    //   order_direction: "asc",
    //   page: requestParams.currentPage + 1,
    //   size: requestParams.pageSize,
    // };
    // according to prime data table from prime react
    let dispatchParams = {
      search_query: requestParams.searchQuery ? requestParams.searchQuery : "",
      order_by: "label",
      order_direction: requestParams.sortOrder ? requestParams.sortOrder : "asc",
      page: requestParams.page + 1,
      size: requestParams.rows,
    };
    // if (requestParams.sort) {
    //   const [sortBy, sortDirection] = requestParams.sort.split(",");
    //   dispatchParams.order_by = toSnake(sortBy);
    //   dispatchParams.order_direction = sortDirection;
    // }

    setQueryParams(dispatchParams);
    // if(dispatchParams.search_query === ''){
    //   setQueryParams(dispatchParams);
    // }
    // setSearchText(dispatchParams)
  }, []);

  const onSearchButtonClicked=()=>{
    setQueryParams(searchText)
    setzeroResource(false)
  }

  const onRowEditClicked = useCallback((params) => {
    // alert(`Edit row with ID: ${params.row.id} has been clicked!`);
    let path = `/resources/${resourceName}/setup/${params.id}`;
    navigate(path);
    dispatch(clearSelectedResourceApiMessageActions())
    window.location.reload()
  }, []);

  const onCloseHandler = () => {
    setDialogOpen(false);
  };
  const onCloseHandlerSingleDeleteDialog = () => {
    setSingleDeleteDialogOpen(false);
  };

  const callAction = () => {
    if (apiAction === "delete") {
      alert(`Delete row with ID: ${selectedIds} has been clicked!`);
      // TODO api call for delete
    } else if (apiAction === "updateStatus") {
      alert(`action need to trigger : ${apiAction}`);
      // TODO api call for update status
    }
    setDialogOpen(false);
  };

  // For Delete Row
  const deleteResourceField = (e:string) => {
    if (singleDelete) {
      setSingleDelete(false);
      let payload = {
        resourceId:resourceId,
        roleName:resourceName,
        clientId:id,
        taxYear:taxYear,
        option:e
      }
      deleteResourceById(payload);
      // setDialogOpen(false);
      setSingleDeleteDialogOpen(false);
    }
  };
  const [bulkResourceDelete, setBulkResourceDelete] = useState<boolean>(false);

  const onRowDeleteClicked = useCallback((params) => {
    setSingleDeleteDialogOpen(true);
    // setDialogOpen(true);
    setSingleDelete(true);
    setApiAction("delete");
    setResourceId(params.id);
    setApiKey(RESOURCE_LIST_QUERY_KEY);
    setBulkResourceDelete(false);
    // setService(ResourceService.delResource)
    // services = ResourceService.delResource
    resourseIdSave = params.id;
    console.log(
      "ResourceId in Resource List OnDeleteRowClicked function",
      params.id
    );
    // alert(`Delete row with ID: ${params.row.id} has been clicked!`);
  }, []);

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const { isLoading: isDeleting, mutate: deleteResourceById } = useMutation(
    ResourceService.delResource,
    {
      onSuccess: (res: string | IServerError | IResponse | any) => {
        if (res === "Resource has been deleted successfully") {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: "Record has been deleted successfully.",
            severity: "success",
          });

          return queryClient.invalidateQueries(apiKey);
        } else {
          setSnackbarData({
            open: true,
            message: res && res.message ? res.message : "Something went wrong!",
            severity: res && res.status === false ? "error" : "success",
          });
        }
      },
      onError: (err:any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusValue(event.target.value as string);
    setApiAction("updateStatus");
    setDialogOpen(true);
  };

  // Bulk Delete
  const [bulkDelete, setBulkDelete] = useState<boolean>(false);
  const [bulkResourceId, setBulkResourceId] = useState<any>({});
  const [bulkResourceDeleteOption, setBulkResourceDeleteOption] = useState<any>('');

  // const deleteBulkResourceField = () => {
  //   if (bulkDelete) {
  //     setBulkDelete(false);
  //     deleteBulkResourceById(bulkResourceId);
  //     setDialogOpen(false);
  //   }
  // };

  const { isLoading: isBulkDeleting, mutate: deleteBulkResourceById } =
    useMutation(ResourceService.delBulkResources, {
      onSuccess: (res: any | string | IServerError | IResponse) => {
        if (res) {
          // remove the cache of the Year fields since we just added a new field.
          setSnackbarData({
            open: true,
            message: res && res.message ? res.message : "Somthing went wrong!",
            severity: res && res.status === false ? "error" : "success",
          });

          setBulkResourceDelete(false)
          return queryClient.invalidateQueries(apiKey);
        } else {
          setSnackbarData({
            open: true,
            message: res && res.message ? res.message : "Somthing went wrong!",
            severity: res && res.status === false ? "error" : "success",
          });
        }
        setBulkResourceDelete(false)
      },
      onError: (err:any) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: err.message,
          severity: "error",
        });
        setBulkResourceDelete(false)
      },
    });

  // For Bulk Delete
  const deleteBulkResourceField = () => {
    if (bulkDelete) {
      setBulkDelete(false);
      let payload = {
        clientId:id,
        taxYear:taxYear,
        roleName:resourceName,
        ids:bulkResourceId,
        options:bulkResourceDeleteOption
      }
      deleteBulkResourceById(payload);
      setDialogOpen(false);
    }
  };

  const onRowMultipleDeleteClicked = (params: any,option:string) => {
    console.log("Params in onRowMultipleDeleteClicked ====== ", params);
    console.log("option in onRowMultipleDeleteClicked ====== ", option);
    // alert(`Delete row with ID: ${params} has been clicked!`);
    setDialogOpen(true);
    setBulkDelete(true);
    setApiAction("delete");
    setBulkResourceId(params);
    setApiKey(RESOURCE_LIST_QUERY_KEY);
    setBulkResourceDelete(true);
    setBulkResourceDeleteOption(option)
    console.log("setBulkResourceId : ", bulkResourceId);
  };
  const selectionToolbar = useCallback(
    (selectedItems) => {
      setSelectedIds(selectedItems);
      console.log("selectedItems //////////// ", selectedItems);
      if (selectedItems.length) {
        return (
          <Box sx={{ marginTop: theme.spacing(1) }}>
            {/* {"Bulk Action Drop Down List"} */}
            {/* <EnhancedDDLBulkAction
            label="Status"
            value={statusValue}
            onChangeHandler={handleChange}
            options={[
              { name: "Active", value: "1" },
              { name: "Inactive", value: "2" },
            ]}
            testId="status-dd"
          /> */}
            {/* <Button
              onClick={() => onRowMultipleDeleteClicked(selectedItems)}
              variant="contained"
              color="error"
            >
              Delete
            </Button> */}
            <Box sx={{"& .MuiPopper-root":{zIndex:2}}}>
            <SplitButton
              options={[
                "Delete By Year",
                "Permanent Delete"
              ]}
              onClickHandler={(e:any) => onRowMultipleDeleteClicked(selectedItems,e)}
              testId="delete-resource-button"
              icon={<DeleteIcon />}
              listItemsLabel={`Print T4 Slips `}
            />
            </Box>
          </Box>
        );
      }
      return (
        <Box sx={{ marginTop: theme.spacing(1) }}>
          <Box sx={{ marginRight:"2rem", textTransform:"capitalize", display:"inline-block"}}>
            {dataRoleName === 'Employee' ? 
            <EnhancedLinkButton text={"Import CSV"} icon={<ExitToAppIcon/>} navigateTo={`/resources/${dataRoleName}/import`} testId={'import-t4-slip-button'}/>
            :<></>}
            </Box>
          <RouterLinkButton
            to={`/resources/${dataRoleName}/setup`}
            text={`Add ${dataRoleName === "T5_Recipient" ? "T5 Recipient" : dataRoleName === "T3_Beneficiary" ? "T3 Beneficiary" : dataRoleName}`}
          />
        </Box>
      );
    },
    [dataRoleName, theme]
  );

  //find the index of object from headerColumns to add link on given column
  // if (accountantList?.headerColumns) {
  //   const linkObjIndex = accountantList.headerColumns.findIndex(
  //     (obj: { field: any; }) => obj.field === accountantList.keyField.field
  //   );
  //   accountantList.headerColumns[linkObjIndex] = {
  //     field: accountantList.headerColumns[linkObjIndex].field,
  //     headerName: accountantList.headerColumns[linkObjIndex].headerName,
  //     flex: 1,
  //     renderCell: (params: any) => {
  //       var urlLink = accountantList.keyField.templateUrl;
  //       var newLink = urlLink.replace("{id}", params.row.id);

  //       return (
  //         <Link to={newLink} className={classes.textLink}>
  //           {params.row.accTitle}
  //         </Link>
  //       );
  //     },
  //   };
  //   const statusObjIndex = accountantList.headerColumns.findIndex(
  //     (obj: { field: string; }) => obj.field === "status"
  //   );
  //   accountantList.headerColumns[statusObjIndex] = {
  //     field: "status",
  //     headerName: "Status",
  //     flex: 1,
  //     width: 50,
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params: any) => (
  //       <Chip label={params.value} />
  //     ),
  //   };
  // }

  // if (isLoading) {
  //   return (
  //     <CircularProgress
  //       size={24}
  //       data-testid="circular-progress"
  //       className={classes.loader}
  //     />
  //   );
  // }

  console.log("queryParams",queryParams)

  const image =
  "https://taxslips.blob.core.windows.net/taxslips-public-images-container/empty_employee_list.png";

  // useEffect(()=>{
  //   setIsLoadingImage(true)
  //   setTimeout(()=>{
  //     setIsLoadingImage(false)
  //   },2000)
  // },[])

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:resourceName === 'Employee' ? EMPLOYEE_LIST_SCREEN_SLUG : resourceName === 'Vendor' ? VENDOR_LIST_SCREEN_SLUG : resourceName === 'T5_Recipient' ? T5_RECIPIENT_LIST_SCREEN_SLUG : resourceName === 'T3_Beneficiary' ? T3_BENEFICIARY_LIST_SCREEN_SLUG : '',
      companyName:clientName,
      activityType:"activity_type=delete&activity_type=import_csv",
      activityLogVisibility:true
    }));
    dispatch(setSelectedStickyNavBar({isSticky:false}));
  },[resourceName,clientName])

  useEffect(()=>{
    dispatch(clearSelectedResourceApiMessageActions())
  },[])

  const onResourceNameClicked = (params:any) => {
    let path = `/resources/${resourceName}/setup/${params.id}`;
    navigate(path);
    dispatch(clearSelectedResourceApiMessageActions())
    window.location.reload()
  }
  useEffect(()=>{
    if(resourceList && resourceList.items){
      const updatedItemsResponse = resourceList.items.map((item:any) => ({
        ...item,
        name1: (
          <Link
            to={`/resources/${resourceName}/setup/${item.id}`}
            className="no-underline"
            style={{ color: "blue" }}
            onClick={() => onResourceNameClicked(item)}
          >
            {item.name1}
          </Link>
        ),
      }));
      setResourceListUpdatedItems(updatedItemsResponse)
      console.log("t4aresourceListUpdatedItemst4aresourceListUpdatedItems",updatedItemsResponse)
  }
},[resourceList])

  return resourceList && resourceList.items && resourceList.items.length === 0 && zeroResource === true ? (
    <EmptyList 
    showImage={true}
    imagePath= {image}
    heading={`There's nothing! Add ${resourceName} to proceed`} 
    content={`You can add ${resourceName?.toLocaleLowerCase()} by directly clicking on add button or by importing csv file.`} 
    testId={"resource-empty-list"} 
    showAddButton= {true}
    addButtonLabel={`Add ${dataRoleName}`}
    navigateToAdd={`/resources/${dataRoleName}/setup`}
    showImportButton={resourceName !== 'Vendor' ? true : false}
    importButtonLabel="Import CSV"
    navigateToImport={`/resources/${dataRoleName}/import`}
    // showImportXmlButton={true}
    // importXmlButtonLabel="Import XML"
    // navigateToImportXml="/"
    />
  ) : (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: "100%" }}>
        {/* <EnhancedDialog
        dialogTitle="Please Confirm"
        key="dialog-box"
        testId="dialog"
        onCloseHandler={onCloseHandler}
        open={openDialog}
        dialogContent={
          <Typography variant="subtitle1">Are you sure?</Typography>
        }
        dialogAction={
          <Grid>
            <Button
              autoFocus
              variant="contained"
              onClick={onCloseHandler}
              color="default"
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={callAction}
              color="primary"
              className={classes.button}
            >
              ok
            </Button>
          </Grid>
        }
      /> */}
       <BackdropCircularProgress
          open={
            isBulkDeleting || isDeleting || isLoadingActivityLogData
            // || isLoading || isLoadingImage
          }
          testId="resource list"
        />
        <DeleteConfirmationDialog
          open={openDialog}
          // deleteActionHandler={deleteResourceField}
          // deleteActionHandler={
          //   bulkResourceDelete === true ? deleteBulkResourceField : deleteResourceField 
          // }
          deleteActionHandler={deleteBulkResourceField }
          // deleteActionHandler={deleteBulkResourceField}
          // deleteActionHandler={<BulkDelete />}
          // deleteActionHandler={BulkDelete}
          // deleteActionHandler={DeleteRow}
          cancelActionHandler={onCloseHandler}
          testId="resource-confirmation-dialog"
        />
        {/* <EnhancedDataGrid
          columns={resourceList ? resourceList.headerColumns : []}
          rows={resourceList?.items}
          selectionEnabled={true}
          autoHeight={true}
          allowSearching={true}
          onSearchButtonClicked={onSearchButtonClicked}
          searchFieldPlaceholder={`Search by ${resourceName} Name...`}
          rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
          selectionToolbar={selectionToolbar}
          onRowEditClicked={onRowEditClicked}
          onRowDeleteClicked={onRowDeleteClicked}
          // initialPage={0}
          initialPageSize={INITIAL_PAGE_SIZE}
          disableColumnMenu={true}
          showRowActionsColumn={true}
          autoPageSize={false} //
          showIndexColumn={true}
          testId="resource-list-grid"
          sortingMode="server"
          filterMode="server"
          paginationMode="server"
          onRequestParamsChange={onRequestParamsChange}
          onSearchCrossClicked={(value) => setzeroResource(value)}
          totalRowsCount={resourceList?.total}
          isLoading={isLoading || isAxiosCallLoading}
          rowIdGetter={(row) => row.id}
          searchDebouncingDelay={SEARCH_DEBOUNCE_DELAY_IN_SECONDS}
          shouldMask={false}
        /> */}

        {/* use prime data table */}
        <PrimeDataGrid 
          columns={resourceList ? resourceList.headerColumns : []}
          rows={resourceList && resourceList?.items && resourceListUpdatedItems && resourceListUpdatedItems}
          showIndexColumn={true}
          showChecboxColumn={true}
          showSettingColumn={true}
          onRowEditClicked={onRowEditClicked}
          onRowDeleteClicked={onRowDeleteClicked}
          isLoading={isLoading || isAxiosCallLoading}
          onSnackbarMessageData={(value: any) => setSnackbarData(value)}
          totalRecords={resourceList && resourceList.total ? resourceList.total : 0}
          initialPageSize={INITIAL_PAGE_SIZE}
          rowsPerPageOptions={ROWS_PER_PAGE_PER_OPTIONS}
          onRequestParamsChange={onRequestParamsChange}
          selectionToolbar={selectionToolbar}
          // selectedRowsValues={(value:any)=>setSelectedRowsValues(value)}
          searchFieldPlaceholder={`Search by ${resourceName} Name...`}
          changeLoginForGetEmptyItemsBySearchQuery={(value:any)=>setzeroResource(value)}
       />
      </Grid>
      {/* Single Delete Options to delte by year or permanenet */}
      <EnhancedDialog
            dialogTitle="Please Confirm"
            key="dialog-box"
            testId="dialog"
            onCloseHandler={onCloseHandlerSingleDeleteDialog}
            open={openSingleDeleteDialogOpen}
            dialogContent={
              <Typography>Are you sure, you want to delete?</Typography>
            }
            
            dialogAction={
              <Grid>
                <Button
                  autoFocus
                  variant="contained"
                  onClick={onCloseHandlerSingleDeleteDialog}
                  sx={{background:"#fff",color:"#000",marginLeft:"16px","&.MuiButton-root:hover":{background:"#fff"},fontSize:"small"}}
                >
                  Cancel
                </Button>
                <Button
                  autoFocus
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteResourceField("Delete By Year")}
                  color="error"
                  sx={{marginLeft:"16px",fontSize:"small"}}
                >
                  Delete By Year
                </Button>
                <Button
                  autoFocus
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteResourceField("Permanent Delete")}
                  color="error"
                  sx={{marginLeft:"16px",fontSize:"small"}}
                >
                  Permanenet Delete
                </Button>
              </Grid>
            }
          />
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"resource-list-snackbar"}
        open={snackbarData.open}
      />
    </>
  );
  // switch (componentState) {
  //   case ComponentState.LOADING:
  //     return (
  //       <CircularProgress
  //         size={24}
  //         data-testid="circular-progress"
  //         className={classes.loader}
  //       />
  //     );
  //   case ComponentState.ERROR:
  //     return (
  //       <div>Error loading component data</div>
  //       // <ErrorState
  //       //   testId="error-container"
  //       //   message={message}
  //       //   onClick={() => {
  //       //     clearError();
  //       //     let dispatchParams = {
  //       //       search_query: "",
  //       //       order_by: "acc_title",
  //       //       order_direction: "asc",
  //       //       page: 1,
  //       //       size: 50,
  //       //     };
  //       //     dispatch(loadAccountantListRequest(dispatchParams));
  //       //   }}
  //       // />
  //     );
  //   case ComponentState.DATA:
  //     return emptyList ? (
  //       <EmptyAccountantList />
  //     ) : (
  //       <Grid
  //         item
  //         xs={12}
  //         sm={12}
  //         md={12}
  //         lg={12}
  //         xl={12}
  //         className={classes.list}
  //       >
  //         {/* <EnhancedDialog
  //           dialogTitle="Please Confirm"
  //           key="dialog-box"
  //           testId="dialog"
  //           onCloseHandler={onCloseHandler}
  //           open={openDialog}
  //           dialogContent={
  //             <Typography variant="subtitle1">Are you sure?</Typography>
  //           }
  //           dialogAction={
  //             <Grid>
  //               <Button
  //                 autoFocus
  //                 variant="contained"
  //                 onClick={onCloseHandler}
  //                 color="default"
  //                 className={classes.button}
  //               >
  //                 Cancel
  //               </Button>
  //               <Button
  //                 autoFocus
  //                 variant="contained"
  //                 onClick={callAction}
  //                 color="primary"
  //                 className={classes.button}
  //               >
  //                 ok
  //               </Button>
  //             </Grid>
  //           }
  //         /> */}
  //         <EnhancedDataGrid
  //           columns={accountantList ? accountantList.headerColumns : []}
  //           rows={accountantList?.items}
  //           selectionEnabled={true}
  //           autoHeight={true}
  //           allowSearching={true}
  //           rowsPerPageOptions={[10, 25, 50, 100]}
  //           selectionToolbar={selectionToolbar}
  //           onRowEditClicked={onRowEditClicked}
  //           onRowDeleteClicked={onRowDeleteClicked}
  //           initialPageSize={accountantList?.size}
  //           disableColumnMenu={true}
  //           showRowActionsColumn={true}
  //           autoPageSize={true}
  //           showIndexColumn={true}
  //           testId="accountant-list-grid"
  //           sortingMode="server"
  //           filterMode="server"
  //           paginationMode="server"
  //           onRequestParamsChange={onRequestParamsChange}
  //           totalRowsCount={accountantList?.total}
  //           isLoading={false}
  //           rowIdGetter={(row) => row.id}
  //           searchDebouncingDelay={2000}
  //           shouldMask={false}
  //         />
  //       </Grid>
  //     );
  //   default:
  //     return null;
  // }
};

// // export const AccountantListContent: React.FC<IAccountantList> = () => {
// //   return (<div>This Accountant List Content Page</div>);
// // }
