import { EnhancedDropDownRHFWithLabel, LabelPosition } from '@websential/cosmic'
import { IDropDownListResponse } from 'models'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react'
import { Link, MemoryRouter } from "react-router-dom";
import { ClientService } from "services";
import stripeImage from 'components/Reports/InvoiceReport/StripeBlurple.svg';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { IAppState } from 'store/reducers';

const SINGLE_RECORD_DATA_QUERY_KEY = "client-single-data-record";

const useGetCompanyData = (id: string | undefined) => 
  useQuery(
    [SINGLE_RECORD_DATA_QUERY_KEY, id],
    (data) => {
      return ClientService.getById(id);
    },
    { refetchOnWindowFocus: false }
  );
  
export interface IInvoiceDialogPanel {
    invoiceData:any;
    onUnPaidClickHandler: (params: any, tag:string) => void;
    onClickInvoiceNoHandler: (params: any) => void;
}
const headerColumns = [
  {
    "field": "invoice",
    "headerName": "Invoice No.",
    "description": "Invoice No.",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "string",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 1
  },
  {
    "field": "netAmount",
    "headerName": "Amount",
    "description": "Amount",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "string",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 2
  },
  {
    "field": "paid",
    "headerName": "Pay Now!!!",
    "description": "Pay Now!!!",
    "flex": 1,
    "hide": false,
    "hideable": false,
    "sortable": true,
    "sortingOrder": "asc",
    "resizable": false,
    "type": "string",
    "align": "left",
    "headerAlign": "left",
    "hideSortIcons": false,
    "disableColumnMenu": true,
    "disableReorder": true,
    "disableExport": true,
    "maskable": false,
    "isFixed": true,
    "sequence": 3
    },
]
export const InvoiceDialogPanel: React.FC<IInvoiceDialogPanel> = ({
  invoiceData,
  onUnPaidClickHandler,
  onClickInvoiceNoHandler
}) => {
  const { id } = useSelector((state: IAppState) => state.client);
  const [invoicePaymentMethod, setinvoicePaymentMethod] = useState<any>(undefined);

  const { isLoading: isLoadingClientDataById, data: companyGetByIdRecord } =
  useGetCompanyData(id);

  console.log("companyGetByIdRecord",companyGetByIdRecord)

  useEffect(()=>{
    if(companyGetByIdRecord && companyGetByIdRecord.metaFields){
      const paymentMethod = companyGetByIdRecord.metaFields.find((item:any) => item.name === 'payment_method')
      setinvoicePaymentMethod(parseInt(paymentMethod.value))
    }
  },[companyGetByIdRecord])

  const indexColumn = {
    body: (rowData: any, row: any) => {
      return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
    },
    header: "#",
  };

  // const rows = [invoiceData]
  const rows = invoiceData

  console.log("rowsrowsrowsrows",rows)
  const updatedColumnsResponse = headerColumns.map(
    (item: any) => ({
      ...item,
      renderCell:
        item.field === "paid" ? (params: any) => params.value : undefined,
    })
  );

  const updatedRows = rows && rows.map((row: any) => {

      return {
        ...row,
        paid: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor:`${invoicePaymentMethod === 10 ? "auto" : "pointer"}`
            }}
            // onClick={() => onUnPaidClickHandler(row)}
          >
            {/* <img src="https://www.paypalobjects.com/en_US/i/btn/btn_xpressCheckout.gif" /> */}
            {invoicePaymentMethod && invoicePaymentMethod === 30 ? 
              <img src={stripeImage} alt="Stripe" style={{height:"38px"}} onClick={() => onUnPaidClickHandler(row,'stripe')}/>
            : invoicePaymentMethod && invoicePaymentMethod === 10 ? 
              <span style={{ color: "#ED6C02", fontWeight:"bold" }}>
                Funds Transfer
              </span>
            : invoicePaymentMethod && invoicePaymentMethod === 20 ?
              <img src="https://www.paypalobjects.com/en_US/i/btn/btn_xpressCheckout.gif" onClick={() => onUnPaidClickHandler(row,'paypal')}/>
            :<></>}
          </div>
        ),
        invoice: (
          <Link
            to={"/reports/invoice-detail"}
            className="no-underline"
            style={{ color: "blue" }}
            onClick={() => onClickInvoiceNoHandler(row)}
          >
            {row.invoiceNo}
          </Link>
        ), 
      }   
  });
  return (
    <>
      <DataTable
      value={updatedRows}
      // paginator
      // rows={25}
      // rowsPerPageOptions={[25, 50, 100]}
      // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
      showGridlines
      resizableColumns
      columnResizeMode="expand"
      scrollable
      reorderableColumns
      filterDisplay="menu"
      // loading={isLoading}
      globalFilterFields={["code"]}
      emptyMessage="No Record found."
      className="generalClientsDataTableStyle"
      // footer={footer}
      style={{ margin: "0.5rem" }}
    >
      <Column
        {...indexColumn}
        style={{ width: "3rem", textAlign: "center" }}
        frozen
        // hidden={!showIndexColumn}
        className="indexColumn"
      />

      {headerColumns &&
        updatedColumnsResponse.map((x: any, index: any) => {

          return (
            <Column
              key={index}
              field={x.field}
              header={x.headerName}
              // headerTooltip={x.description}
              // headerTooltipOptions={{ position: "top" }}
              // sortable={x.sortable}
              // resizeable={x.resizable}
              // dataType={x.type}
              // align={x.align}
              alignHeader={x.headerAlign}
              // reorderable={x.disableReorder}
              // exportable={x.disableExport}
              // frozen={x.frozenColumn}
              // columnKey={x.field}
              style={{
                minWidth: x.minWidth,
                fontSize: "0.875rem",
              }}
              // footer={x.footer}
            />
          );
        })}

      
    </DataTable>
    </>
  )
}