import { FC, useCallback, useEffect, useState } from "react";

import { RL1SlipSummaryDataInput } from "../../RL1SlipSummaryDataInput";

export interface IWSDRFContributionPanel {
  wsdrfRate:number | null | undefined;
  onValueChange: (value: number) => void;
  formMethods:any;
}
export const WSDRFContributionPanel: FC<IWSDRFContributionPanel> = ({formMethods,wsdrfRate,onValueChange}) => {


    const handleInputChange = useCallback((event:any) => {
      const newValue = event.target.value;
      if(wsdrfRate && newValue > 2000000){
        let result = wsdrfRate * newValue / 100
        const convertToStr = result.toString()
        formMethods.setValue('wsdfrAmount',parseFloat(convertToStr).toFixed(2))
        onValueChange(result)
      } else if(wsdrfRate){
        formMethods.setValue('wsdfrAmount',"0.00")
        onValueChange(0)
      }
    },[wsdrfRate]);
    
  return (
    <>
      <RL1SlipSummaryDataInput
        id="totalPayroll"
        label="Total Payroll (Payroll, if over $2,000,000)"
        readOnly={false}
        onChange={handleInputChange}
      />
      <RL1SlipSummaryDataInput id="wsdfrRate" label="WSDRF Rate %" 
      value={wsdrfRate !== null ? wsdrfRate : 0}
      textfield={true}
      />
      <RL1SlipSummaryDataInput id="wsdfrAmount" label="WSDRF Amount" />
    </>
  );
};
