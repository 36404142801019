import { FC, useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";

import { TaxSlipDataInput } from "../../TaxSlipDataInput";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  EnhancedDropDownRHFWithLabel,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
} from "@websential/cosmic";
interface IForeignCurrencyDDL {
  label:String;
  name:String;
  value:String;
}
export interface IT5SlipDataPanel extends ITest {
  formMethods:any;
  foreignCurrencyDDL:any[];
  globalVariablesData: any;
}

export const T5SlipDataPanel: FC<IT5SlipDataPanel> = ({
  formMethods,
  testId,
  foreignCurrencyDDL,
  globalVariablesData,
}) => {
  const [countryLabel, setCountryLabel] = useState("")
  const [showGlobalVariablesError, setShowGlobalVariablesError] = useState(false);

  const onForiegnCurrencyChangeHandler = (e: any) => {
      let CountryFullName = foreignCurrencyDDL.find((value:any) => e.value === value.value)
      console.log("CountryFullName",CountryFullName)
      setCountryLabel(CountryFullName.name)
    }
    let foreignCurrencyValue = formMethods.getValues('foreignCurrency')
    useEffect(()=>{
      console.log("foreignCurrencyValue",foreignCurrencyValue)
      if(foreignCurrencyValue){
      let CountryFullName = foreignCurrencyDDL && foreignCurrencyDDL.find((value:any) => foreignCurrencyValue === value.value)
      console.log("CountryFullName",CountryFullName)
      setCountryLabel(CountryFullName && CountryFullName.name)
      }
    },[formMethods,foreignCurrencyValue])
  const handleBox24InputChangeHandler = (e: any) => {
    console.log(e.target.value)
    let value = parseInt(e.target.value)
    const selectedRecipientType = formMethods.getValues('recipientType')
    if(selectedRecipientType != 30){
      if (globalVariablesData && globalVariablesData?.data?.t5) {
    if(value > 0){
      // let box25CalculatedValue = ((value * 0.38) + value);
      // let box26CalculatedValue = (box25CalculatedValue * 0.150198);
        let box25CalculatedValue = (value * (globalVariablesData?.data?.t5?.eligibleGrossUpFactor/100)) + value;
        let box26CalculatedValue = box25CalculatedValue * (globalVariablesData?.data?.t5?.eligibleDividendTaxCreditPercentageOfTaxableAmount/100);
        formMethods.setValue('taxableAmountOfEligibleDividends',box25CalculatedValue.toFixed(2))
        formMethods.setValue('dividendTaxCreditForEligibleDividends',box26CalculatedValue.toFixed(2))
      } else {
        formMethods.setValue('taxableAmountOfEligibleDividends',"0.00")
        formMethods.setValue('dividendTaxCreditForEligibleDividends',"0.00")
      }
      setShowGlobalVariablesError(false)
    } else {
      formMethods.setValue('taxableAmountOfEligibleDividends',"0.00")
      formMethods.setValue('dividendTaxCreditForEligibleDividends',"0.00")
      if(globalVariablesData){
        setShowGlobalVariablesError(globalVariablesData.data.message ? globalVariablesData.data.message : "T5 Slip definition does not exist for selected year, please add definition to configure global variables")
      }
    }
  } else {
    formMethods.setValue('taxableAmountOfEligibleDividends',"0.00")
    formMethods.setValue('dividendTaxCreditForEligibleDividends',"0.00")
  }
  }
  const handleBox10InputChangeHandler = (e: any) => {
    console.log(e.target.value)
    let value = parseInt(e.target.value)
    const selectedRecipientType = formMethods.getValues('recipientType')
    if(selectedRecipientType != 30){
      if (globalVariablesData && globalVariablesData?.data?.t5) {
      if(value > 0){
        // let box11CalculatedValue = ((value * 0.15) + value);
        // let box12CalculatedValue = ((box11CalculatedValue * 9.0301)/100);
          let box11CalculatedValue = (value * (globalVariablesData?.data?.t5?.nonEligibleGrossUpFactor/100)) + value;
          let box12CalculatedValue = (box11CalculatedValue * (globalVariablesData?.data?.t5?.nonEligibleDividendTaxCreditPercentageOfTaxableAmount/100)) / 100;
          formMethods.setValue('taxableAmountOfDivinendsOtherThanEligible',box11CalculatedValue.toFixed(2))
          formMethods.setValue('dividendTaxCreditForDivdendsOtherThanEligible',box12CalculatedValue.toFixed(2))
        } else {
          formMethods.setValue('taxableAmountOfDivinendsOtherThanEligible',"0.00")
          formMethods.setValue('dividendTaxCreditForDivdendsOtherThanEligible',"0.00")
        }
        setShowGlobalVariablesError(false)
      } else {
        formMethods.setValue('taxableAmountOfDivinendsOtherThanEligible',"0.00")
        formMethods.setValue('dividendTaxCreditForDivdendsOtherThanEligible',"0.00")
        if(globalVariablesData){
          setShowGlobalVariablesError(globalVariablesData.data.message ? globalVariablesData.data.message : "T5 Slip definition does not exist for selected year, please add definition to configure global variables")
        }
      }
    } else {
      formMethods.setValue('taxableAmountOfEligibleDividends',"0.00")
      formMethods.setValue('dividendTaxCreditForEligibleDividends',"0.00")
    }
  }
  return (
    <>
      <Grid container columnSpacing={2} xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}></Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Actual
          </Typography>
        </Grid>
      </Grid>
      {showGlobalVariablesError === false ? 
      (<>
      <TaxSlipDataInput
        id="actualAmountOfEligibleDivinends"
        label="Actual amount of eligible dividends"
        boxNumber="24"
        defaultValue="0.00"
        onChange={handleBox24InputChangeHandler}
      />
      </>)
      :
      (<>
      <div>
      <TaxSlipDataInput
        id="actualAmountOfEligibleDivinends"
        label="Actual amount of eligible dividends"
        boxNumber="24"
        defaultValue="0.00"
        onChange={handleBox24InputChangeHandler}
      />
        <span style={{display:"flex",justifyContent:"center",marginLeft:"19.6rem",color:"#cc8925",fontSize:"12px",background:"#fff2e2",alignItems:"center",padding:"2px",borderRadius:"5px"}}><WarningAmberIcon sx={{marginRight:"7px",padding:"0 2px 2px 2px"}}/>{showGlobalVariablesError}</span>
      </div></>)
}
      <TaxSlipDataInput
        id="taxableAmountOfEligibleDividends"
        label="Taxable amount of eligible dividends"
        boxNumber="25"
        defaultValue="0.00"
        readOnly={true}
      />
      <TaxSlipDataInput
        id="dividendTaxCreditForEligibleDividends"
        label="Dividend tax credit for eligible dividends"
        boxNumber="26"
        defaultValue="0.00"
        readOnly={true}
      />
      <TaxSlipDataInput
        id="interestFromCanadianSources"
        label="Interest from canadian sources"
        boxNumber="13"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="capitalGainDividends"
        label="Capital gain dividends"
        boxNumber="18"
        defaultValue="0.00"
      />
      {showGlobalVariablesError === false ? 
      (<>
      <TaxSlipDataInput
        id="actualAmountOfDivinendsOtherThanEligible"
        label="Actual amount of dividends other than eligible"
        boxNumber="10"
        defaultValue="0.00"
        onChange={handleBox10InputChangeHandler}
      />
      </>)
      :
      (<>
      <div>
      <TaxSlipDataInput
        id="actualAmountOfDivinendsOtherThanEligible"
        label="Actual amount of dividends other than eligible"
        boxNumber="10"
        defaultValue="0.00"
        onChange={handleBox10InputChangeHandler}
      />
        <span style={{display:"flex",justifyContent:"center",marginLeft:"19.6rem",color:"#cc8925",fontSize:"12px",background:"#fff2e2",alignItems:"center",padding:"2px",borderRadius:"5px"}}><WarningAmberIcon sx={{marginRight:"7px",padding:"0 2px 2px 2px"}}/>{showGlobalVariablesError}</span>
      </div></>)
}
      <TaxSlipDataInput
        id="taxableAmountOfDivinendsOtherThanEligible"
        label="Taxable amount of dividends other than eligible"
        boxNumber="11"
        defaultValue="0.00"
        readOnly={true}
      />
      <TaxSlipDataInput
        id="dividendTaxCreditForDivdendsOtherThanEligible"
        label="Dividend tax credit for dividends other than eligible"
        boxNumber="12"
        defaultValue="0.00"
        readOnly={true}
      />

      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ mt: 2, p: 4, backgroundColor: "#f4f5f7" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold", pb: 1 }}>
            Other Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TaxSlipDataInput
            id="otherIncomeFromCanadianSources"
            label="Other income from canadian sources"
            boxNumber="14"
            defaultValue="0.00"
          />
          <TaxSlipDataInput
            id="foreignIncome"
            label="Foreign income"
            boxNumber="15"
            defaultValue="0.00"
          />
          <TaxSlipDataInput
            id="foreignTaxPaid"
            label="Foreign tax paid"
            boxNumber="16"
            defaultValue="0.00"
          />
          <TaxSlipDataInput
            id="royaltiesFromCanadianSources"
            label="Royalties from canadian sources"
            boxNumber="17"
            defaultValue="0.00"
          />
          <TaxSlipDataInput
            id="accuredIncomeOrAnnuities"
            label="Accured income or annuities"
            boxNumber="19"
            defaultValue="0.00"
          />
          <TaxSlipDataInput
            id="amountEligibleForResourceAllowanceDeduction"
            label="Amount eligible for resource allowance deduction"
            boxNumber="20"
            defaultValue="0.00"
          />

          <EnhancedDropDownRHFWithLabel
            selectName="foreignCurrency"
            label="Foreign currency"
            labelPosition={LabelPosition.Left}
            testId={`${testId}-province-list`}
            items={foreignCurrencyDDL ?? []}
            // items={[{label:"CAN",value:"CAN"}]}
            // selectedItem={"CAN"}
            onSelectionChangeHandler={onForiegnCurrencyChangeHandler}
            labelColumns={9}
            fieldColumns={2.6}
          />
          <Typography sx={{fontSize:"small",paddingRight:"17px",textAlign:"end",fontWeight:"600"}}>{countryLabel}</Typography>
          <TaxSlipDataInput id="transit" label="Transit" boxNumber="28" textfield={true}/>
          <TaxSlipDataInput
            id="recipientAccount"
            label="Recipient account"
            boxNumber="29"
            textfield={true}
          />
          <TaxSlipDataInput
            id="optionalTextToPrintOnTheSlip"
            label="Optional text to print on the slip"
            boxNumber=""
            optionalTextField={true}
            alert={true}
            titlePlacement={'top'}
            alertTitle="To print text at the top of this slip (e.g., 'Additional' or 'Suplicate'), enter it here. If this slip is amended or cancelled do not indicate this here. The requirements 
            of amended or cancelled slips are taken care of by setting the status of the slip."
          />
        </Grid>
      </Grid>
    </>
  );
};
