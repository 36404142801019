import React, { useState, useRef, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "./primeDataGridStyle.css";
import { GridRenderCellParams, GridSelectionModel } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import SearchIcon from '@mui/icons-material/Search';
import { RequestParams } from "@websential/cosmic";
import { Card } from "primereact/card";

export interface IDataTable {
  rows: any;
  columns: any;
  showIndexColumn: boolean;
  showChecboxColumn: boolean;
  showSettingColumn: boolean;
  onRowDeleteClicked?: (params: GridRenderCellParams) => void;
  onRowEditClicked?: (params: GridRenderCellParams) => void;
  isLoading?: any;
  onSnackbarMessageData?: (value: any) => void;
  onRequestParamsChange?: (params: RequestParams) => void;
  initialPageSize?: number | undefined;
  initialPage?: number;
  totalRecords?: number;
  selectionToolbar?: (selectedItems: GridSelectionModel) => React.ReactNode;
  selectedRowsValues?:  any;
  changeLoginForGetEmptyItemsBySearchQuery?:  any;
  searchFieldPlaceholder?:string;
  rowsPerPageOptions?: number[];
  footer?: any;
  cardHeaderTitle?:string;
  cardHeaderSubTitle?:string;
  disabledHeaderTooltip?:boolean;
  tableScrollHeight?:boolean;
}

export const PrimeDataGrid: React.FC<IDataTable> = ({
  rows,
  columns,
  showIndexColumn,
  showChecboxColumn,
  showSettingColumn,
  onRowDeleteClicked,
  onRowEditClicked,
  isLoading,
  onSnackbarMessageData,
  onRequestParamsChange,
  initialPageSize = 10,
  initialPage = 0,
  totalRecords,
  selectionToolbar,
  selectedRowsValues,
  searchFieldPlaceholder,
  rowsPerPageOptions,
  changeLoginForGetEmptyItemsBySearchQuery,
  footer,
  cardHeaderTitle,
  cardHeaderSubTitle,
  disabledHeaderTooltip = false,
  tableScrollHeight
}) => {
  const isInitialRequest = useRef(true);
  const [loading, setLoading] = useState(false);
    const [itemSearch, setItemSearch] = useState('');
    const [requestParams, setRequestParams] = useState<any>({
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        sortField: null,
        sortOrder: "asc",
        searchQuery:"",
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectedAuditEarningsItems, setSelectedAuditEarningsItems] = useState<any>([]);
  const [selectedItemsIds, setSelectedItemsIds] = useState<any>([]);
  
  const setting = () => {
    return (
      <div>
        <i className="pi pi-cog text-xl"></i>
      </div>
    );
  };
  const delEdit = (params: any) => {
    // console.log("params",params)
    return (
      <>
      <Button
        icon="pi pi-user-edit"
        onClick={() =>
          onRowEditClicked && onRowEditClicked(params)
        }
        className="p-button-rounded p-button-text w-2rem h-2rem"
      />
      <Button
        icon="pi pi-trash"
        onClick={() =>
          onRowDeleteClicked && onRowDeleteClicked(params)
        }
        className="p-button-rounded p-button-text w-2rem h-2rem ml-2"
      />
      </>
    );
  };
    const [itemSearsch, setItemSearsch] = useState('');
    const onSearchButtonClicked = () => {
      setLoading(true)
      setRequestParams((prevParams:any) => ({
        ...prevParams,
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        searchQuery: itemSearch,
      }));
      if(changeLoginForGetEmptyItemsBySearchQuery){
        changeLoginForGetEmptyItemsBySearchQuery(false)
      }
      setTimeout(() => { 
        setLoading(false);
      }, 1000);
    }
    const handleClearSearch = () => {
      setLoading(true)
      setItemSearch('')
      setRequestParams((prevParams:any) => ({
        ...prevParams,
        first: initialPage,
        rows: initialPageSize,
        page: initialPage,
        searchQuery: "",
      }));
      if(changeLoginForGetEmptyItemsBySearchQuery){
        changeLoginForGetEmptyItemsBySearchQuery(false)
      }
      setTimeout(() => { 
        setLoading(false);
      }, 1000);
    }
    const renderHeader = () => {
        return (
            <div  style={{display:"flex",justifyContent:"space-between"}}>
            <span className="p-input-icon-left w-5" style={{display:"flex"}}>
                    <i className="pi pi-search" />
                    <InputText value={itemSearch} onChange={(e) => setItemSearch(e.target.value)} placeholder={searchFieldPlaceholder ? searchFieldPlaceholder : "Search..."} className='w-full' style={{borderRadius:"5px 0px 0px 5px"}}/>
                    {itemSearch && (
                        <Button
                        icon="pi pi-times"
                        onClick={handleClearSearch}
                        className="p-button-warning"
                        style={{borderRadius:0}}
                        />
                    )}
                    <Button
                        onClick={onSearchButtonClicked}
                        // variant="contained"
                        style={{
                          padding:"8px 10px 8px 10px",
                          marginRight:"-14px!important",
                          borderRadius:"0px 5px 5px 0px"}}
                      >
                        <SearchIcon />
                      </Button>

                </span>
                      {/* {selectedItems.length > 0 ?
                        (
                          <>
                        <Button
                          icon="pi pi-trash"
                          onClick={() =>
                            onRowDeleteClicked(selectedItems)
                          }
                          severity="danger"
                        />
                          </>
                        ):(<></>)} */}
                        {selectionToolbar && selectionToolbar(selectedItemsIds)}
            </div>
        )
    }
    const headerSearch = renderHeader();
    function search(_row:any){
        return rows && rows.filter((rows:any)=> rows.clientName.props.children.toLowerCase().indexOf(itemSearsch.toLowerCase())>-1 )
    }
  // Custom column for row indices
  const indexColumn = {
    body: (rowData:any, row:any) => {
      return <span>{row.rowIndex + 1}</span>; // +1 to start indexing from 1 instead of 0
    },
    header: '#',
  };

  const onPage = (event:any) => {
    // setRequestParams(event);
    setLoading(true)
    //imitate delay of a backend call
    setTimeout(() => {
    setRequestParams({
      ...event,
      searchQuery: itemSearch,
    });
    setLoading(false);
  }, 1000);
  };
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const onSort = useCallback((event: any) => {
    setLoading(true);

    let newSortOrder : any;
    if (event.sortField === sortField) {
        // Toggle sortOrder between 1 and -1
        newSortOrder = sortOrder === 1 ? -1 : 1;
        setSortOrder(newSortOrder);
    } else {
        // Set the new sortField and reset sortOrder to 1
        newSortOrder = 1;
        setSortField(event.sortField);
        setSortOrder(newSortOrder);
    }

    console.log("sortOrder 33 out", newSortOrder); // Use the newSortOrder value directly
    setRequestParams((prevParams: any) => ({
        ...prevParams,
        sortOrder: newSortOrder !== 1 ? "asc" : "desc",
        sortField: event.sortField,
    }));

    // Imitate delay of a backend call
    setTimeout(() => {
        setLoading(false);
    }, 1000);
}, [sortOrder, sortField]);
// This useEffect is triggered when the debouncedSearchQuery, pageSize or currentPage values change
useEffect(() => {
  // if the results fetcher was passed, we can use it to fetch the results.
  if (onRequestParamsChange) {
    // skip the first initial call
    if (isInitialRequest.current === true) {
      console.log(
        "##### => useEffect - requestParams - no first call",
        requestParams
      );
      isInitialRequest.current = false;
    } else {
      console.log(
        "##### => useEffect - requestParams - second call",
        requestParams
      );
      onRequestParamsChange(requestParams);
    }
  }
}, [onRequestParamsChange, requestParams]);

const onSelectionModelChange = useCallback((items: any) => {
  let selectedItemsIds = items.value.map((item:any) => item.id);
  // setSelectedItems(selectedItemsIds);
  // Check for deleted items and remove them from selectedItems
  const updatedSelectedItems = items.value.filter((selectedItem: any) => {
    return rows && rows.find((row: any) => row.id === selectedItem.id);
  });
  setSelectedItems(updatedSelectedItems);
  setSelectedAuditEarningsItems(updatedSelectedItems);
  setSelectedItemsIds(selectedItemsIds);
  if(selectedRowsValues){
    if (items.value.length > 0) {
      const selectedRowData = rows.filter((row:any) => {
        return items.value.includes(row);
      });
      console.log('Selected Row Data: in', selectedRowData);
      selectedRowsValues(selectedRowData)
    } else {
      selectedRowsValues(items.value)
      // console.log('No row selected');
    }
  }
}, [rows,selectedRowsValues,selectedItems]);
useEffect(() => {
  // Check for deleted items and remove them from selectedItems
  const updatedSelectedItems = selectedItems && selectedItems.length > 0 && selectedItems.filter((selectedItem: any) => {
    return rows && rows.find((row: any) => row.id === selectedItem.id);
  });
  // Only update if there are changes to selectedItems
  if (updatedSelectedItems && updatedSelectedItems.length !== selectedItems.length) {
    setSelectedItems(updatedSelectedItems);

    // Update selectedItemsIds
    const updatedSelectedItemsIds = updatedSelectedItems.map((item: any) => item.id);
    setSelectedItemsIds(updatedSelectedItemsIds);
  }

  // after update ignore tag in table then check whether new data is same as presvios selected items
  const updatedSelectedAuditEarningsItems = selectedAuditEarningsItems && selectedAuditEarningsItems.length > 0 && selectedAuditEarningsItems.filter((selectedItem: any) => {
    return rows && rows.find((row: any) => row === selectedItem);
  });
  // Only update if there are changes to selectedItems
  if (updatedSelectedAuditEarningsItems && updatedSelectedAuditEarningsItems.length !== selectedItems.length) {
    setSelectedItems(updatedSelectedAuditEarningsItems);

    // Update selectedItemsIds
    const updatedSelectedAuditEarningsItemsIds = updatedSelectedAuditEarningsItems.map((item: any) => item.id);
    setSelectedItemsIds(updatedSelectedAuditEarningsItemsIds);
  }
  console.log("updatedSelectedAuditEarningsItems",updatedSelectedAuditEarningsItems)
}, [rows, selectedItems,selectedAuditEarningsItems]);

const rowsWithTwoDecimalNumbersOnlyForEarningsAuditSreen = rows && rows.map((item:any) => {
  return {
  ...item,
  employmentIncome: parseFloat(item.employmentIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  cppQppPensionableEarnings: parseFloat(item.cppQppPensionableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  eiInsurableEarnings: parseFloat(item.eiInsurableEarnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  }});

  console.log("props headerColumns => ",columns)
  console.log("props items  => ",rows)
  console.log("selectedItems => ",selectedItems)
  console.log("selectedAuditEarningsItems => ",selectedAuditEarningsItems)
  console.log("requestParams => ",requestParams)
  return (
    <>
    {cardHeaderTitle ? 
    <Card
    title={cardHeaderTitle}
    subTitle={cardHeaderSubTitle}
    // footer={footer}
    className="mt-5"
  >
    <DataTable
        value={rows && rows}
        responsiveLayout="scroll"
        // lazy 
        // filterDisplay="row"
        // dataKey="id" 
        paginator
        // first={requestParams.first} 
        rows={25}
        // totalRecords={totalRecords}
        // onPage={onPage}
        rowsPerPageOptions={rowsPerPageOptions}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={`Showing {first} to {last} of {totalRecords} Items`}
        selection={selectedAuditEarningsItems}
        onSelectionChange={onSelectionModelChange}
        // editMode="row"
        selectionMode={'checkbox'}
        showGridlines
        resizableColumns
        columnResizeMode="expand"
        scrollable
        // scrollHeight="450px"
        reorderableColumns
        // filterDisplay="menu"
        loading={isLoading}
        // globalFilterFields={["headerName"]}
        header={searchFieldPlaceholder ? headerSearch : undefined}
        footer={footer ? footer : undefined}
        emptyMessage="No Record found."
        className="primeDataGrid"
        style={{ margin: "1rem 0.5rem 0.5rem 0.5rem"}}
        sortMode="single"
        onSort={onSort}
      >
        {columns &&<Column
          selectionMode="multiple"
          hidden={!showChecboxColumn}
          frozen
          resizeable={false}
          style={{ width: "36px",textAlign: "center" }}
          field="selection"
          columnKey="selection"
          className="selectionHeader"
        ></Column>}
      {rows &&
        <Column 
          header="#" 
          className="indexColumn" 
          headerStyle={{ width: '3rem' }} 
          frozen
          hidden={!showIndexColumn}
          body={(data, options) => options.rowIndex + 1}>
      </Column>}

        {columns &&
          columns.map((x: any, index: any) => (
            <Column
              key={index}
              field={x.field}
              header={x.headerName}
              headerTooltip={disabledHeaderTooltip ? "" : x.description}
              headerTooltipOptions={{ position: "top" }}
              hidden={x.hide}
              sortable={x.sortable}
              resizeable={x.resizable}
              dataType={x.type}
              align={x.align}
              alignHeader={x.headerAlign}
              reorderable={x.disableReorder}
              exportable={x.disableExport}
              frozen={x.frozenColumn}
              alignFrozen={x.alignFrozen}
              columnKey={x.field}
              style={{
                minWidth: x.minWidth,
                fontSize: "0.875rem"
              }}
              footer={x.footer}
            />
          ))}

{rows &&<Column
          body={delEdit}
          header={setting}
          style={{ width: "85px" }}
          hidden={!showSettingColumn}
          resizeable={false}
          reorderable={false}
          frozen
          alignFrozen="right"
          className="settingHeader"
        ></Column>}
      </DataTable>
      </Card>
      : <DataTable
      value={rows && rows}
      responsiveLayout="scroll"
      lazy 
      // filterDisplay="row"
      // dataKey="id" 
      paginator
      first={requestParams.first} 
      rows={requestParams.rows}
      totalRecords={totalRecords}
      onPage={onPage}
      rowsPerPageOptions={rowsPerPageOptions}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate={`Showing {first} to {last} of ${totalRecords} Items`}
      selection={selectedItems}
      onSelectionChange={onSelectionModelChange}
      // editMode="row"
      selectionMode={'checkbox'}
      showGridlines
      resizableColumns
      columnResizeMode="expand"
      scrollable
      scrollHeight={tableScrollHeight ? "70vh" : ""} 
      // scrollHeight="450px"
      reorderableColumns
      // filterDisplay="menu"
      loading={isLoading ? isLoading : loading}
      // globalFilterFields={["headerName"]}
      header={searchFieldPlaceholder ? headerSearch : undefined}
      footer={footer ? footer : undefined}
      emptyMessage="No Record found."
      className="primeDataGrid"
      style={{ margin: "2.5rem 0.5rem 0.5rem 0.5rem"}}
      sortMode="single"
      onSort={onSort}
    >
      {columns &&<Column
        selectionMode="multiple"
        hidden={!showChecboxColumn}
        frozen
        resizeable={false}
        style={{ width: "36px",textAlign: "center" }}
        field="selection"
        columnKey="selection"
        className="selectionHeader"
      ></Column>}
    {rows &&
      <Column 
        header="#" 
        className="indexColumn" 
        headerStyle={{ width: '3rem' }} 
        frozen
        hidden={!showIndexColumn}
        body={(data, options) => options.rowIndex + 1}>
    </Column>}

      {columns &&
        columns.map((x: any, index: any) => (
          <Column
            key={index}
            field={x.field}
            header={x.headerName}
            headerTooltip={disabledHeaderTooltip ? "" : x.description}
            headerTooltipOptions={{ position: "top" }}
            hidden={x.hide}
            sortable={x.sortable}
            // resizeable={x.resizable}
            dataType={x.type}
            align={x.align}
            alignHeader={x.headerAlign}
            reorderable={x.disableReorder}
            exportable={x.disableExport}
            frozen={x.frozenColumn}
            alignFrozen={x.alignFrozen}
            columnKey={x.field}
            style={{
              minWidth: x.minWidth,
              fontSize: "0.875rem"
            }}
            footer={x.footer}
          />
        ))}

{rows &&<Column
        body={delEdit}
        header={setting}
        style={{ width: "85px" }}
        hidden={!showSettingColumn}
        resizeable={false}
        reorderable={false}
        frozen
        alignFrozen="right"
        className="settingHeader"
      ></Column>}
    </DataTable>
    }
      
      </>
  );
};

//resizeablbe must be false if frozen is true and viseversa
