import { DEFAULT_SETTINGS_SCREEN_SLUG, INITIAL_SNACKBAR_DATA } from "common/constants";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  ITest,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
  SubmitButton,
  SubmitButtonVariant,
  TwoColumnLayout,
} from "@websential/cosmic";

import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { DefaultSettingsService } from "services/DefaultSettingsService";
import { T4DefaultSettingsPanel } from "./Panels/T4DefaultSettingsPanel";
import { EmployeeOrSlipsDefaultSettingsPanel } from "./Panels/EmployeeOrSlipsDefaultSettingsPanel";
import { T4ADefaultSettingsPanel } from "./Panels/T4ADefaultSettingsPanel";
import { Grid } from "@mui/material";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";
import { useParams } from "react-router-dom";

export interface IDefaultSettings extends ITest {}

const DEFAULT_VALUES = {};
const DEFAULT_SETTINGS_DATA_QUERY_KEY = "default-settings-data-record";

const useGetDefaultSettingsData = (
  clientId: string,
) => 
  useQuery(
    [DEFAULT_SETTINGS_DATA_QUERY_KEY, clientId],
    (data) => {
      return DefaultSettingsService.getDefaultSettings(clientId);
    },
    { refetchOnWindowFocus: false }
  );


export const DefaultSettings: FC<IDefaultSettings> = ({ testId }) => {
  const clientName = useSelector((state : IAppState) => state.client.clientName);
  const { id } = useParams();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const isLoadingActivityLogData = useSelector((state: IAppState) => state.isLoadAxiosCall.isLoading);

  const clientId = useSelector((state : IAppState) => state.client.id);
  console.log("clientName",clientName)
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);

  const { isLoading: isLoadingDefaultSettingsData, data: defaultSettingsData } = useGetDefaultSettingsData(clientId,);

  const { isLoading: isSubmitting, mutate: submitDefaultSettings } = useMutation(
    DefaultSettingsService.createDefaultSettings,
    {
      onSuccess: (res: any) => {
        if(res.message === 'Settings have been saved!'){
          setSnackbarData({
            open: true,
            message: res.message,
            severity: "success",
          });
          queryClient.invalidateQueries([DEFAULT_SETTINGS_DATA_QUERY_KEY, clientId]);
        } else {
          setSnackbarData({
            open: true,
            message: "Something went wrong, try again later!",
            severity: "error",
          });  
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );
  const formMethods = useForm<any>({
    // resolver: yupResolver(DefaultSettingsSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset(DEFAULT_VALUES);
  };

  /**
   * Handle when the form is submitted.
   *
   * @param {any} formData
   */
  const onSubmit = (formData: any) => {
    console.log("formData",formData)
    // const jsonString = formData.province as string;
    const convertStringToObject = (str: string) => {
      // const modifiedString = str.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
      // return JSON.parse(modifiedString);
      
      
      const escapedString = str.replace(/'/g, "\\'");
    // Add double quotes around keys if not already present
    const modifiedString = escapedString.replace(/(\w+):/g, '"$1":');
    // Replace the single backslash with double backslash to properly escape in JSON string
    const finalString = modifiedString.replace(/\\'/g, "'");
    return JSON.parse(finalString);
    };
    // const otherInformationObject = parseJSONLikeString(jsonString);
    let payload = {
      t4DefaultSetting:{
        rppDpspRegistration:formData.rppDpspRegistration && formData.rppDpspRegistration !== null && formData.rppDpspRegistration !== '' 
        && typeof(formData.rppDpspRegistration) === 'string' ?
        parseFloat(formData.rppDpspRegistration && formData.rppDpspRegistration.replace(/[$,]/g, '')) : 
        formData.rppDpspRegistration && formData.rppDpspRegistration !== null && formData.rppDpspRegistration !== '' ? formData.rppDpspRegistration 
        : undefined,
        otherInformation:[
          formData.t4OtherInformation1 && convertStringToObject(formData.t4OtherInformation1),
          formData.t4OtherInformation2 && convertStringToObject(formData.t4OtherInformation2),
          formData.t4OtherInformation3 && convertStringToObject(formData.t4OtherInformation3),
          formData.t4OtherInformation4 && convertStringToObject(formData.t4OtherInformation4),
          formData.t4OtherInformation5 && convertStringToObject(formData.t4OtherInformation5),
          formData.t4OtherInformation6 && convertStringToObject(formData.t4OtherInformation6),
          ].filter(Boolean),
        printT4T4aBackPage:formData.printT4T4aBackPage
        },
      t4aDefaultSetting:{
        otherInformation:[
          formData.t4aOtherInformation1 && convertStringToObject(formData.t4aOtherInformation1),
          formData.t4aOtherInformation2 && convertStringToObject(formData.t4aOtherInformation2),
          formData.t4aOtherInformation3 && convertStringToObject(formData.t4aOtherInformation3),
          formData.t4aOtherInformation4 && convertStringToObject(formData.t4aOtherInformation4),
          formData.t4aOtherInformation5 && convertStringToObject(formData.t4aOtherInformation5),
          formData.t4aOtherInformation6 && convertStringToObject(formData.t4aOtherInformation6),
        ].filter(Boolean),
        use:formData.use
      },
      fillingDataAttribute:{
        employeeDobIsRequired: formData.employeeDobIsRequired,
        provinceOfEmployment: formData.provinceOfEmployment && formData.provinceOfEmployment !== "" ? convertStringToObject(formData.provinceOfEmployment) : undefined,
        city: formData.city,
        province: formData.province && formData.province !== "" ? convertStringToObject(formData.province) : undefined,
        cppQppExempt: formData.cppQppExempt,
        eiExempt: formData.eiExempt,
        ppipExempt: formData.ppipExempt,
        employmentCode: formData.employmentCode !== '' ? formData.employmentCode : undefined,
        payPeriod: formData.payPeriod,
        reducedEi: formData.reducedEi
        },
        client: {
          externalId: clientId
          },
          // id:id ? id : defaultSettingsData && defaultSettingsData.id ? defaultSettingsData.id : undefined
          }
          console.log("payloadpayload",payload)
          submitDefaultSettings(payload);
  };

  /**
   * On Snackbar closed.
   *
   * @param {React.SyntheticEvent | Event} event
   * @param {string} reason
   */
  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  useEffect(()=>{
    if(defaultSettingsData && !defaultSettingsData.message){
      console.log("defaultSettingsData",defaultSettingsData)
      Object.keys(defaultSettingsData).forEach((fieldName) => {
        console.log("fieldName useEffect T4 Slip : defaultSettingsData", fieldName);
        // @ts-ignore
        const fieldValue = defaultSettingsData[fieldName];
        console.log("fieldValue useEffect T4 Slip : ", fieldValue);
        // @ts-ignore

        if (typeof fieldValue !== "object") {
          // @ts-ignore
          formMethods.setValue(fieldName, fieldValue ?? undefined);
        } else if (fieldValue !== null || undefined) {
          console.log("object");
          Object.keys(fieldValue).forEach((objFieldName: any) => {
            console.log(
              "objFieldName useEffect T4 Slip : defaultSettingsData",
              objFieldName
            );
            // @ts-ignore
            const objFieldValue = fieldValue[objFieldName];
            if (typeof objFieldValue !== "object") {
              // @ts-ignore
              formMethods.setValue(objFieldName, objFieldValue ?? undefined);
            }
            if(fieldName === 't4DefaultSetting' && objFieldName === 'otherInformation'){
              console.log("objFieldName--",objFieldValue[0])
              if(objFieldValue && objFieldValue[0]){
                formMethods.setValue(
                  't4OtherInformation1',
                  `{code: "${objFieldValue[0].code}", label: "${objFieldValue[0].label}", amount: 0}`
                );
              }
              if(objFieldValue && objFieldValue[1]){
                formMethods.setValue(
                  't4OtherInformation2',
                  `{code: "${objFieldValue[1].code}", label: "${objFieldValue[1].label}", amount: 0}`
                );
              }
              if(objFieldValue && objFieldValue[2]){
                formMethods.setValue(
                  't4OtherInformation3',
                  `{code: "${objFieldValue[2].code}", label: "${objFieldValue[2].label}", amount: 0}`
                );
              }
              if(objFieldValue && objFieldValue[3]){
                formMethods.setValue(
                  't4OtherInformation4',
                  `{code: "${objFieldValue[3].code}", label: "${objFieldValue[3].label}", amount: 0}`
                );
              }
              if(objFieldValue && objFieldValue[4]){
                formMethods.setValue(
                  't4OtherInformation5',
                  `{code: "${objFieldValue[4].code}", label: "${objFieldValue[4].label}", amount: 0}`
                );
              }
              if(objFieldValue && objFieldValue[5]){
                formMethods.setValue(
                  't4OtherInformation6',
                  `{code: "${objFieldValue[5].code}", label: "${objFieldValue[5].label}", amount: 0}`
                );
              }
            }
            if(fieldName === 't4aDefaultSetting' && objFieldName === 'otherInformation'){
              console.log("objFieldName--",objFieldValue[0])
              if(objFieldValue && objFieldValue[0]){
                formMethods.setValue(
                  't4aOtherInformation1',
                  `{code: "${objFieldValue[0].code}", label: "${objFieldValue[0].label}", amount: 0}`
                );
              }
              if(objFieldValue && objFieldValue[1]){
                formMethods.setValue(
                  't4aOtherInformation2',
                  `{code: "${objFieldValue[1].code}", label: "${objFieldValue[1].label}", amount: 0}`
                );
              }
              if(objFieldValue && objFieldValue[2]){
                formMethods.setValue(
                  't4aOtherInformation3',
                  `{code: "${objFieldValue[2].code}", label: "${objFieldValue[2].label}", amount: 0}`
                );
              }
              if(objFieldValue && objFieldValue[3]){
                formMethods.setValue(
                  't4aOtherInformation4',
                  `{code: "${objFieldValue[3].code}", label: "${objFieldValue[3].label}", amount: 0}`
                );
              }
              if(objFieldValue && objFieldValue[4]){
                formMethods.setValue(
                  't4aOtherInformation5',
                  `{code: "${objFieldValue[4].code}", label: "${objFieldValue[4].label}", amount: 0}`
                );
              }
              if(objFieldValue && objFieldValue[5]){
                formMethods.setValue(
                  't4aOtherInformation6',
                  `{code: "${objFieldValue[5].code}", label: "${objFieldValue[5].label}", amount: 0}`
                );
              }
            }
            if (objFieldName === "province" && objFieldValue !== null) {
              let id = objFieldValue.id;
              let name = objFieldValue.name;
              let shortCode = objFieldValue.shortCode;
              formMethods.setValue(
                objFieldName,
                // '{id:9, name: "Ontario",shortCode:"ON"}'
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
              );
            }
            if (objFieldName === "provinceOfEmployment" && objFieldValue !== null) {
              let id = objFieldValue.id;
              let name = objFieldValue.name;
              let shortCode = objFieldValue.shortCode;
              formMethods.setValue(
                objFieldName,
                `{id:${id}, name: "${name}",shortCode:"${shortCode}"}`
              );
            }
          })
        }
      })
    }
  },[defaultSettingsData])

  useEffect(()=>{
    dispatch(setSelectedActivityLogActions({
      entityId1:'',
      entityId2:'',
      entityId3:'',
      screenName:DEFAULT_SETTINGS_SCREEN_SLUG,
      companyName:clientName,
      activityType:"activity_type=updated",
      activityLogVisibility:true
    }));
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  },[formMethods])

  return (
    <Grid
    item
    xs={12}
    sm={12}
    md={12}
    lg={12}
    xl={12}
    sx={{
      pb: "16rem",
      "& .MuiAppBar-root": { zIndex: 2, top: "65px" },
      "& .p-datepicker": { zIndex: 2 },
      paddingTop: "2rem",
    }}>
      <BackdropCircularProgress open={isSubmitting || isLoadingActivityLogData || isLoadingDefaultSettingsData} testId={testId} />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <>
          <TwoColumnLayout
                  toolbar={
                    <StandardSubmitFormButtonsToolbar
                      toolbarContents={
                        <SubmitButton
                          label={"Save Settings"}
                          testId={"btnSubmit"}
                          variant={SubmitButtonVariant.Primary}
                        />
                      }
                      onResetClicked={onResetClicked}
                      isSubmitting={false}
                      testId="api-keys-toolbar"
                    />
                  }
                  leftColumnContent={
                      <StandardPanel
                          title="T4 - Default Settings"
                          testId={`${testId}-T4-Default-Settings-panel`}
                        >
                         <T4DefaultSettingsPanel testId={'T4-Default-Settings-panel'}/>
                        </StandardPanel>
                  }
                  rightColumnContent={
                    <StandardPanel
                    title="T4A - Default Settings"
                    testId={`${testId}-T4a-Default-Settings-panel`}
                    >
                    <T4ADefaultSettingsPanel testId={'T4-Default-Settings-panel'}/>
                      </StandardPanel>
                  }
                  testId="t4-slip-setup-form"
                />
                <StandardPanel
                          title="Filing Data Attributes (Employee Page / Slips)"
                          testId={`${testId}-employee-panel`}
                          topPadding={4}
                        >
                <EmployeeOrSlipsDefaultSettingsPanel testId={'T4-Default-Settings-panel'}/>
                        </StandardPanel>
                </>
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
