import { Grid, Typography } from '@mui/material'
import { LabelPosition, RHookFormTextFieldWithLabel } from '@websential/cosmic'
import React from 'react'

export interface IT4ASLipAxisPage1 {}
const T4ASLipAxisPage1:React.FC<IT4ASLipAxisPage1> = () => {
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                X-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'slipTypeP1X'} 
                testId={'slipTypeP1X'} 
                label={'Slip Type'} 
                labelPosition={LabelPosition.Left}
                defaultValue={420}
            />
            <RHookFormTextFieldWithLabel 
                id={'taxYearP1X'} 
                testId={'taxYearP1X'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={308}
            />
            <RHookFormTextFieldWithLabel 
                id={'incomeTaxDeductedP1X'} 
                testId={'incomeTaxDeductedP1X'} 
                label={'Income Tax Deducted'} 
                labelPosition={LabelPosition.Left}
                defaultValue={545.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'selfEmployedCommissionsP1X'} 
                testId={'selfEmployedCommissionsP1X'} 
                label={'Self Employed Commissions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={546.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'feesP1X'} 
                testId={'feesP1X'} 
                label={'Fees'} 
                labelPosition={LabelPosition.Left}
                defaultValue={546.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'pensionOrSuperannuationP1X'} 
                testId={'pensionOrSuperannuationP1X'} 
                label={'Pension Or Superannuation'} 
                labelPosition={LabelPosition.Left}
                defaultValue={523}
            />
            <RHookFormTextFieldWithLabel 
                id={'lumpSumPaymentsP1X'} 
                testId={'lumpSumPaymentsP1X'} 
                label={'LumpSum Payments'} 
                labelPosition={LabelPosition.Left}
                defaultValue={523}
            />
            <RHookFormTextFieldWithLabel 
                id={'annuitiesP1X'} 
                testId={'annuitiesP1X'} 
                label={'Annuities'} 
                labelPosition={LabelPosition.Left}
                defaultValue={523}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P1X'} 
                testId={'otherInfoAmount1P1X'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={149}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P1X'} 
                testId={'otherInfoCode1P1X'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={66}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P1X'} 
                testId={'otherInfoAmount2P1X'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={149}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P1X'} 
                testId={'otherInfoCode2P1X'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={66}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P1X'} 
                testId={'otherInfoAmount3P1X'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={281.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P1X'} 
                testId={'otherInfoCode3P1X'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={197.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P1X'} 
                testId={'otherInfoAmount4P1X'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={281.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P1X'} 
                testId={'otherInfoCode4P1X'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={197.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount5P1X'} 
                testId={'otherInfoAmount5P1X'} 
                label={'Other Info Amount 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={427}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode5P1X'} 
                testId={'otherInfoCode5P1X'} 
                label={'Other Info Code 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={340.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount6P1X'} 
                testId={'otherInfoAmount6P1X'} 
                label={'Other Info Amount 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={427}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode6P1X'} 
                testId={'otherInfoCode6P1X'} 
                label={'Other Info Code 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={340.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount7P1X'} 
                testId={'otherInfoAmount7P1X'} 
                label={'Other Info Amount 7'} 
                labelPosition={LabelPosition.Left}
                defaultValue={427}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode7P1X'} 
                testId={'otherInfoCode7P1X'} 
                label={'Other Info Code 7'} 
                labelPosition={LabelPosition.Left}
                defaultValue={340.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount8P1X'} 
                testId={'otherInfoAmount8P1X'} 
                label={'Other Info Amount 8'} 
                labelPosition={LabelPosition.Left}
                defaultValue={427}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode8P1X'} 
                testId={'otherInfoCode8P1X'} 
                label={'Other Info Code 8'} 
                labelPosition={LabelPosition.Left}
                defaultValue={340.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount9P1X'} 
                testId={'otherInfoAmount9P1X'} 
                label={'Other Info Amount 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={555.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode9P1X'} 
                testId={'otherInfoCode9P1X'} 
                label={'Other Info Code 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={472}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount10P1X'} 
                testId={'otherInfoAmount10P1X'} 
                label={'Other Info Amount 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={555.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode10P1X'} 
                testId={'otherInfoCode10P1X'} 
                label={'Other Info Code 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={472}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount11P1X'} 
                testId={'otherInfoAmount11P1X'} 
                label={'Other Info Amount 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={555.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode11P1X'} 
                testId={'otherInfoCode11P1X'} 
                label={'Other Info Code 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={472}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount12P1X'} 
                testId={'otherInfoAmount12P1X'} 
                label={'Other Info Amount 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={555.5}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode12P1X'} 
                testId={'otherInfoCode12P1X'} 
                label={'Other Info Code 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={472}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceCityAddressP1X'} 
                testId={'resourceCityAddressP1X'} 
                label={'Resource City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={60}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceAddressP1X'} 
                testId={'resourceAddressP1X'} 
                label={'Resource Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={60}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersCityAddressP1X'} 
                testId={'employersCityAddressP1X'} 
                label={'Employers City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={60}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersAddressP1X'} 
                testId={'employersAddressP1X'} 
                label={'Employers Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={60}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersNameP1X'} 
                testId={'employersNameP1X'} 
                label={'Employers Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={60}
            />
            <RHookFormTextFieldWithLabel 
                id={'initialsP1X'} 
                testId={'initialsP1X'} 
                label={'Initials'} 
                labelPosition={LabelPosition.Left}
                defaultValue={305}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceLastNameP1X'} 
                testId={'resourceLastNameP1X'} 
                label={'Resource Last Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={216}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceFirstNameP1X'} 
                testId={'resourceFirstNameP1X'} 
                label={'Resource First Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={72}
            />
            <RHookFormTextFieldWithLabel 
                id={'accountNoP1X'} 
                testId={'accountNoP1X'} 
                label={'Account No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={210}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsuranceNumberP1X'} 
                testId={'socialInsuranceNumberP1X'} 
                label={'Social Insurance Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={65}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAccountNoP1X'} 
                testId={'employerAccountNoP1X'} 
                label={'Employer Account No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={65}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography sx={{color:"rgba(0, 0, 0, 0.6)",fontWeight:"bold",padding:"0 0 1rem 100px",textAlign:"center",fontSize:"1.2rem"}}>
                Y-Axis
            </Typography>
            <RHookFormTextFieldWithLabel 
                id={'slipTypeP1Y'} 
                testId={'slipTypeP1Y'} 
                label={'Slip Type'} 
                labelPosition={LabelPosition.Left}
                defaultValue={971}
            />
            <RHookFormTextFieldWithLabel 
                id={'taxYearP1Y'} 
                testId={'taxYearP1Y'} 
                label={'Tax Year'} 
                labelPosition={LabelPosition.Left}
                defaultValue={954}
            />
            <RHookFormTextFieldWithLabel 
                id={'incomeTaxDeductedP1Y'} 
                testId={'incomeTaxDeductedP1Y'} 
                label={'Income Tax Deducted'} 
                labelPosition={LabelPosition.Left}
                defaultValue={887}
            />
            <RHookFormTextFieldWithLabel 
                id={'selfEmployedCommissionsP1Y'} 
                testId={'selfEmployedCommissionsP1Y'} 
                label={'Self Employed Commissions'} 
                labelPosition={LabelPosition.Left}
                defaultValue={846}
            />
            <RHookFormTextFieldWithLabel 
                id={'feesP1Y'} 
                testId={'feesP1Y'} 
                label={'Fees'} 
                labelPosition={LabelPosition.Left}
                defaultValue={798}
            />
            <RHookFormTextFieldWithLabel 
                id={'pensionOrSuperannuationP1Y'} 
                testId={'pensionOrSuperannuationP1Y'} 
                label={'Pension Or Superannuation'} 
                labelPosition={LabelPosition.Left}
                defaultValue={887}
            />
            <RHookFormTextFieldWithLabel 
                id={'lumpSumPaymentsP1Y'} 
                testId={'lumpSumPaymentsP1Y'} 
                label={'LumpSum Payments'} 
                labelPosition={LabelPosition.Left}
                defaultValue={846}
            />
            <RHookFormTextFieldWithLabel 
                id={'annuitiesP1Y'} 
                testId={'annuitiesP1Y'} 
                label={'Annuities'} 
                labelPosition={LabelPosition.Left}
                defaultValue={798}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount1P1Y'} 
                testId={'otherInfoAmount1P1Y'} 
                label={'Other Info Amount 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={662}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode1P1Y'} 
                testId={'otherInfoCode1P1Y'} 
                label={'Other Info Code 1'} 
                labelPosition={LabelPosition.Left}
                defaultValue={662}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount2P1Y'} 
                testId={'otherInfoAmount2P1Y'} 
                label={'Other Info Amount 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode2P1Y'} 
                testId={'otherInfoCode2P1Y'} 
                label={'Other Info Code 2'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount3P1Y'} 
                testId={'otherInfoAmount3P1Y'} 
                label={'Other Info Amount 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={662}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode3P1Y'} 
                testId={'otherInfoCode3P1Y'} 
                label={'Other Info Code 3'} 
                labelPosition={LabelPosition.Left}
                defaultValue={662}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount4P1Y'} 
                testId={'otherInfoAmount4P1Y'} 
                label={'Other Info Amount 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode4P1Y'} 
                testId={'otherInfoCode4P1Y'} 
                label={'Other Info Code 4'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount5P1Y'} 
                testId={'otherInfoAmount5P1Y'} 
                label={'Other Info Amount 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={632}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode5P1Y'} 
                testId={'otherInfoCode5P1Y'} 
                label={'Other Info Code 5'} 
                labelPosition={LabelPosition.Left}
                defaultValue={632}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount6P1Y'} 
                testId={'otherInfoAmount6P1Y'} 
                label={'Other Info Amount 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={697}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode6P1Y'} 
                testId={'otherInfoCode6P1Y'} 
                label={'Other Info Code 6'} 
                labelPosition={LabelPosition.Left}
                defaultValue={697}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount7P1Y'} 
                testId={'otherInfoAmount7P1Y'} 
                label={'Other Info Amount 7'} 
                labelPosition={LabelPosition.Left}
                defaultValue={662}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode7P1Y'} 
                testId={'otherInfoCode7P1Y'} 
                label={'Other Info Code 7'} 
                labelPosition={LabelPosition.Left}
                defaultValue={662}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount8P1Y'} 
                testId={'otherInfoAmount8P1Y'} 
                label={'Other Info Amount 8'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode8P1Y'} 
                testId={'otherInfoCode8P1Y'} 
                label={'Other Info Code 8'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount9P1Y'} 
                testId={'otherInfoAmount9P1Y'} 
                label={'Other Info Amount 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={632}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode9P1Y'} 
                testId={'otherInfoCode9P1Y'} 
                label={'Other Info Code 9'} 
                labelPosition={LabelPosition.Left}
                defaultValue={632}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount10P1Y'} 
                testId={'otherInfoAmount10P1Y'} 
                label={'Other Info Amount 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={697}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode10P1Y'} 
                testId={'otherInfoCode10P1Y'} 
                label={'Other Info Code 10'} 
                labelPosition={LabelPosition.Left}
                defaultValue={697}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount11P1Y'} 
                testId={'otherInfoAmount11P1Y'} 
                label={'Other Info Amount 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={662}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode11P1Y'} 
                testId={'otherInfoCode11P1Y'} 
                label={'Other Info Code 11'} 
                labelPosition={LabelPosition.Left}
                defaultValue={662}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoAmount12P1Y'} 
                testId={'otherInfoAmount12P1Y'} 
                label={'Other Info Amount 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'otherInfoCode12P1Y'} 
                testId={'otherInfoCode12P1Y'} 
                label={'Other Info Code 12'} 
                labelPosition={LabelPosition.Left}
                defaultValue={631}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceCityAddressP1Y'} 
                testId={'resourceCityAddressP1Y'} 
                label={'Resource City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={734}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceAddressP1Y'} 
                testId={'resourceAddressP1Y'} 
                label={'Resource Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={754}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersCityAddressP1Y'} 
                testId={'employersCityAddressP1Y'} 
                label={'Employers City Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={928}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersAddressP1Y'} 
                testId={'employersAddressP1Y'} 
                label={'Employers Address'} 
                labelPosition={LabelPosition.Left}
                defaultValue={942}
            />
            <RHookFormTextFieldWithLabel 
                id={'employersNameP1Y'} 
                testId={'employersNameP1Y'} 
                label={'Employers Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={958}
            />
            <RHookFormTextFieldWithLabel 
                id={'initialsP1Y'} 
                testId={'initialsP1Y'} 
                label={'Initials'} 
                labelPosition={LabelPosition.Left}
                defaultValue={794}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceLastNameP1Y'} 
                testId={'resourceLastNameP1Y'} 
                label={'Resource Last Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={794}
            />
            <RHookFormTextFieldWithLabel 
                id={'resourceFirstNameP1Y'} 
                testId={'resourceFirstNameP1Y'} 
                label={'Resource First Name'} 
                labelPosition={LabelPosition.Left}
                defaultValue={794}
            />
            <RHookFormTextFieldWithLabel 
                id={'accountNoP1Y'} 
                testId={'accountNoP1Y'} 
                label={'Account No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={854}
            />
            <RHookFormTextFieldWithLabel 
                id={'socialInsuranceNumberP1Y'} 
                testId={'socialInsuranceNumberP1Y'} 
                label={'Social Insurance Number'} 
                labelPosition={LabelPosition.Left}
                defaultValue={854}
            />
            <RHookFormTextFieldWithLabel 
                id={'employerAccountNoP1Y'} 
                testId={'employerAccountNoP1Y'} 
                label={'Employer Account No'} 
                labelPosition={LabelPosition.Left}
                defaultValue={892}
            />
        </Grid>
    </Grid>
  )
}

export default T4ASLipAxisPage1