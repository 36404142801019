import { TaxSlipCategoryList } from "components/TaxSlipCategory";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export interface ITaxSlipCategoryListPage {}

export const TaxSlipCategoryListPage: FC<ITaxSlipCategoryListPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="TaxSlip Category"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[{text: 'TaxSlip Category', navigateTo: '/taxslip-categories/list'}]}
      mainContents={<TaxSlipCategoryList />}
    />
  );
};
