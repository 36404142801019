import { TAX_YEAR } from "../types";

export interface ITaxYear {
  id?: string,
  year: number
}

const initialState: ITaxYear = {
  // year: 2022
  year: Number(new Date().getFullYear())
};

export const taxYearReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TAX_YEAR.TAX_YEAR_SET_SELECTED:
      return {
        ...state,
        year: action.payload.year,
      }
    case TAX_YEAR.TAX_YEAR_CLEAR_SELECTED:
      return {
        ...state,
        year: initialState.year,
      }
    default:
      return state;
  }
};
