import { FLEX_FIELD_TAB_SETUP_SCREEN_SLUG, INITIAL_SNACKBAR_DATA, QUERY_OPTIONS } from "common/constants";
import { instanceOfIFlexFieldTabSetupRequest } from "common/instance-method";
import { IResponse, IServerError } from "models/common";
import { IFlexFieldTabSetupRequest } from "models/flexFieldTab/IFlexFieldTabSetupRequest";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FlexFieldTabService } from "services/FlexFieldTabService";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  EnhancedTextAreaFieldRHF,
  ITest,
  LabelPosition,
  RHookFormTextFieldWithLabel,
  SingleColumnLayout,
  StandardPanel,
  StandardSubmitFormButtonsToolbar,
} from "@websential/cosmic";

import { FlexFieldTabValidationSchema } from "./validator";
import { Grid } from "@mui/material";
import { setSelectedActivityLogActions } from "store/actions/activityLogActions";
import { useDispatch } from "react-redux";
import { setSelectedStickyNavBar } from "store/actions/stickyNavBarActions";

export interface IFlexFieldTabSetup extends ITest {}

const DEFAULT_VALUES = {
  name: "",
  appName: "",
  description: "",
  sequence: "",
};

const FLEX_FIELD_TAB_DATA_QUERY_KEY = "get-flex-field-tab-data";

const useGetData = (id: string | undefined) => {
  return useQuery(
    FLEX_FIELD_TAB_DATA_QUERY_KEY,
    () => FlexFieldTabService.getById(id),
    QUERY_OPTIONS
  );
};

export const FlexFieldTabSetup: FC<IFlexFieldTabSetup> = ({ testId }) => {
  const navigate = useNavigate();
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const [isInitialize, setisInitialize] = useState(false);

  const { isLoading: isSubmitting, mutate: submitFlexFields } = useMutation(
    FlexFieldTabService.create,
    {
      onSuccess: (
        res: IFlexFieldTabSetupRequest | IServerError | IResponse
      ) => {
        if (instanceOfIFlexFieldTabSetupRequest(res)) {
          queryClient.invalidateQueries([
            FLEX_FIELD_TAB_DATA_QUERY_KEY,
            res.id,
          ]);
          setSnackbarData({
            open: true,
            message: "Flex Field Tab Data has been submitted successfully!",
            severity: "success",
          });
        }
        let path = "/flex-field-tabs/list";
        navigate(path);
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const onSnackbarClosed = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const formMethods = useForm<IFlexFieldTabSetupRequest>({
    resolver: yupResolver(FlexFieldTabValidationSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });

  const { isLoading: isLoadingData, data: selectedFlexFieldTabData } =
    useGetData(id);

  const onSubmit = (formData: IFlexFieldTabSetupRequest) => {
    submitFlexFields(formData);
  };

  const onResetClicked = () => {
    setSnackbarData({
      open: true,
      message: "Form was reset successfully",
      severity: "success",
    });
    formMethods.reset(DEFAULT_VALUES);
  };

  useEffect(() => {
    // if the selected flex field data has loaded.
    if (selectedFlexFieldTabData) {
      Object.keys(selectedFlexFieldTabData).forEach((fieldName) => {
        // @ts-ignore
        const fieldValue = selectedFlexFieldTabData[fieldName];
        // @ts-ignore
        formMethods.setValue(fieldName, fieldValue ?? undefined);
      });
    }
  }, [selectedFlexFieldTabData, formMethods.setValue, formMethods]);

  useEffect(() => {
    dispatch(
      setSelectedActivityLogActions({
        entityId1: "",
        entityId2: "",
        entityId3: "",
        screenName: FLEX_FIELD_TAB_SETUP_SCREEN_SLUG,
        companyName: '',
        activityType:
          "activity_type=create&activity_type=updated",
        activityLogVisibility: true,
      })
    );
    dispatch(setSelectedStickyNavBar({isSticky:true}));
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ "& .MuiAppBar-root": { zIndex: 2, top: "65px" } }}
    >
      <BackdropCircularProgress
        open={isSubmitting || isLoadingData}
        testId={testId}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SingleColumnLayout
            toolbar={
              <StandardSubmitFormButtonsToolbar
                onResetClicked={onResetClicked}
                isSubmitting={false}
                testId={""}
              />
            }
            testId={"flex-field-tab-setup-toolbar"}
          >
            <StandardPanel
              title="Flex Field Tab"
              leftSpacing={2}
              rightSpacing={2}
              testId={`${testId}-flex-field-tab`}
            >
              <RHookFormTextFieldWithLabel
                label="Name"
                id="name"
                testId={`${testId}-flex-field-tab-label`}
                labelPosition={LabelPosition.Left}
                placeholder="Enter Tab Name"
                required
              />
              <RHookFormTextFieldWithLabel
                label="App Name"
                id="appName"
                testId={`${testId}-flex-field-tab-app-name`}
                labelPosition={LabelPosition.Left}
                placeholder="Enter Tab App Name"
                required
              />
              <EnhancedTextAreaFieldRHF
                label="Description"
                id="description"
                testId={`${testId}-flex-field-tab-description`}
                placeholder="Enter Tab Description"
                labelPosition={LabelPosition.Left}
              />
              <RHookFormTextFieldWithLabel
                label="Sequence"
                id="sequence"
                testId={`${testId}-flex-field-tab-sequence`}
                labelPosition={LabelPosition.Left}
                placeholder="Enter Tab Sequence"
                required
              />
            </StandardPanel>
          </SingleColumnLayout>
        </form>
      </FormProvider>
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={`${testId}-snackbar`}
        open={snackbarData.open}
      />
    </Grid>
  );
};
