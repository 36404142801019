import { IAPIKeysRequest, IAPIKeysResponse } from "models";
import { IEmployeeListPayload, IEmployeeRequest } from "models/employee";
import { IVendorListPayload, IVendorRequest } from "models/vendor";

import { CLIENT } from "../types";

export const loadAPIKeysRequest = (requestPayload: IAPIKeysRequest) => {
  return {
    type: CLIENT.API_KEYS_REQUEST_LOAD,
    payload: { ...requestPayload },
  };
};

export const apiKeysRequestSucceeded = (responsePayload: IAPIKeysResponse) => {
  return {
    type: CLIENT.API_KEYS_REQUEST_SUCCESS,
    payload: { ...responsePayload },
  };
};

export const generateAPIKeysRequest = (requestPayload: IAPIKeysRequest) => {
  return {
    type: CLIENT.API_KEYS_REQUEST_GENERATE,
    payload: { ...requestPayload },
  };
};

export const generateAPIKeysRequestSucceeded = (
  responsePayload: IAPIKeysResponse
) => {
  return {
    type: CLIENT.API_KEYS_REQUEST_GENERATE_SUCCESS,
    payload: { ...responsePayload },
  };
};

export const loadEmployeeListRequest = (requestPayload: IEmployeeRequest) => {
  return {
    type: CLIENT.EMPLOYEE_LIST_REQUEST_LOAD,
    payload: { ...requestPayload },
  };
};

export const loadEmployeeListRequestSucceeded = (
  responsePayload: IEmployeeListPayload
) => {
  return {
    type: CLIENT.EMPLOYEE_LIST_REQUEST_SUCCESS,
    payload: { ...responsePayload },
  };
};

export const loadEmployeeListRequestFailed = () => {
  return {
    type: CLIENT.EMPLOYEE_LIST_REQUEST_FAILED,
  };
};

export const loadVendorListRequest = (requestPayload: IVendorRequest) => {
  return {
    type: CLIENT.VENDOR_LIST_REQUEST_LOAD,
    payload: { ...requestPayload },
  };
};

export const loadVendorListRequestSucceeded = (
  responsePayload: IVendorListPayload
) => {
  return {
    type: CLIENT.VENDOR_LIST_REQUEST_SUCCESS,
    payload: { ...responsePayload },
  };
};

export const loadVendorListRequestFailed = () => {
  return {
    type: CLIENT.VENDOR_LIST_REQUEST_FAILED,
  };
};

export const setSelectedClient = (selectClient: any) => {
  return {
    type: CLIENT.CLIENT_SET_SELECTED,
    payload: {...selectClient}
  }
}

export const clearSelectedClient = () => {
  return {
    type: CLIENT.CLIENT_CLEAR_SELECTED
  }
}
export const setSelectedClientName = (selectClient: any) => {
  return {
    type: CLIENT.CLIENT_NAME_SET_SELECTED,
    payload: {...selectClient}
  }
}

export const clearSelectedClientName = () => {
  return {
    type: CLIENT.CLIENT_NAME_CLEAR_SELECTED
  }
}
