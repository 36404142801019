import T3SlipImportLayout from "components/T3Slip/T3SlipImportLayout/T3SlipImportLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IT3SlipImportPage {}

export const T3SlipImportPage: FC<IT3SlipImportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import T3 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/t3/list" },
        { text: "T3", navigateTo: "/taxslip/t3/list" },
        { text: "Import T3 Slips", navigateTo: "/taxslip/t3/import" },
      ]}
      mainContents={<T3SlipImportLayout />}
    />
  );
};
