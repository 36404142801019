import { FC, ReactNode } from "react";

import { Box } from "@mui/material";

interface IFieldWrapper {
  children: ReactNode;
}

export const FieldWrapper: FC<IFieldWrapper> = ({ children }) => {
  return (
    <Box pt={1} pb={1}>
      {children}
    </Box>
  );
};
