import * as yup from "yup";
export const checkSINValidity = (inputNumber: string | any): number => {
  let result = 0;
  if(inputNumber){
    for (let i = 0; i < inputNumber.length; i++) {
      const digit = parseInt(inputNumber[i], 10);

      // Multiply even digits by 2 and odd digits by 1
      let j = i+1
      const multiplier = j % 2 === 0 ? 2 : 1;
      const product = digit * multiplier;

      if(product.toString().length > 1){
        const sumOfDigits = product.toString().split('').reduce((sum, num) => sum + parseInt(num, 10), 0);
        result += sumOfDigits;
      } else {
        result += product;
      }
    }
  }
  return result;
};
export const T4SlipSetupValidationSchema = yup.object({
  applyAdjustments: yup.string().required("Apply Adjustments is required"),
  addressLine1: yup
    .string()
    .required("Address is required")
    .max(50, "Address has at most 50 characters"),
  // uniqueIdentifier: yup
  //   .string()
  //   .required("social Insurance Number is required")
  //   .min(11, "Social Insuarance Number has at least 9 characters"),
  uniqueIdentifier: yup.string().test(
    'is-masked-or-not',
    'Invalid value',
    function (value:any) {
      if(value){
        let sin = value && value.replace(/-/g, '')
      const output = checkSINValidity(sin);
      console.log("output",output)
      let maxLength = 11;

      if (value && value.charAt(2) === '*') {
        maxLength = 7;
        if (value && value.length == maxLength) {
          return true;
        } else {
          return this.createError({
            message: 'Invalid Social Insuarance Number',
            path: this.path,
          });
        }
      } else {
        if (value && value.length == maxLength && output % 10 === 0) {
          return true;
        } else {
          return this.createError({
            message: 'Invalid Social Insuarance Number',
            // message: 'Social Insuarance Number has at least 9 characters',
            path: this.path,
          });
        }
      }
    }
    return this.createError({
      message: 'Social Insuarance Number is required',
      path: this.path,
    });
    }
  ).required('Social Insuarance is required'),
  name1: yup
    .string()
    .required("First Name is required")
    .max(12, "First Name has at most 12 characters"),
  name2: yup
    .string()
    .required("Last Name is required")
    .max(20, "Last Name has at most 20 characters"),
  initials: yup.string().max(1, "maximum one character"),
  city: yup
    .string()
    .required("City is required")
    .max(28, "City has at most 28 characters"),
  province: yup.string().nullable().required("Province is required"),
  postalCode: yup
    .string()
    .required("Employee PostCode is required")
    .max(10, "Post Code has at most 10 characters"),
    country: yup.mixed().test(
      'is-string-or-object',
      'Input must be a string or an object.',
      (value) => typeof value === 'string' || typeof value === 'object'
    ).required("Country is required"),
  payPeriod: yup.number().required("payPeriods is required"),
  pensionablePeriod: yup.number()
  .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
  .required("pensionable Periods is required").nullable()
  .test(
    "is-less-than-or-equal-to-payPeriod",
    "pensionable Periods must be less than or equal to Pay Periods",
    function (value:any) {
      const { payPeriod } = this.parent;
      return value <= payPeriod;
    }
  ),
  slipType: yup.number().required("slipType is required"),
  provinceOfEmployment: yup
    .string().nullable()
    .required("provinceOfEmployment is required"),
  email: yup
    .string().nullable()
    .email("Please enter valid Email"),
});
