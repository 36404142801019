import { FC, useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";

import { TaxSlipDataInput } from "../../TaxSlipDataInput";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  EnhancedDropDownRHFWithLabel,
  IEnhancedDropDownItem,
  ITest,
  LabelPosition,
} from "@websential/cosmic";
import { OtherInformationSetupFieldsPanel } from "./OtherInformationSetupFieldsPanel";
interface IForeignCurrencyDDL {
  label:String;
  name:String;
  value:String;
}
export interface IRL16SlipDataPanel extends ITest {
  formMethods: any;
  otherInformationList: any;
  isLoading: boolean;
  selectedOtherInformation:boolean;
  oiInEdit:boolean;
  // selectedProvinceOfEmployment:any;
  globalVariablesData:any;
}

export const RL16SlipDataPanel: FC<IRL16SlipDataPanel> = ({
  formMethods,
  otherInformationList,
  isLoading,
  testId,
  selectedOtherInformation,
  oiInEdit,
  // selectedProvinceOfEmployment,
  globalVariablesData
}) => {
  const [countryLabel, setCountryLabel] = useState("")
  const [showGlobalVariablesError, setShowGlobalVariablesError] = useState(false);
  
  const handleBoxC1InputChangeHandler = (e: any) => {
    console.log(e.target.value);
    let value = parseInt(e.target.value);
    // const boxC2 = parseFloat(formMethods.getValues("actualAmountOfDivinendsOtherThanEligible").replace(/[$,]/g, ''));
    const boxC2 = parseFloat(formMethods.getValues("actualAmountOfDivinendsOtherThanEligible") && typeof(formMethods.getValues("actualAmountOfDivinendsOtherThanEligible")) === "string" ? formMethods.getValues("actualAmountOfDivinendsOtherThanEligible").replace(/[$,]/g, '') : formMethods.getValues("actualAmountOfDivinendsOtherThanEligible")); 

    // const selectedRecipientType = formMethods.getValues('recipientType')
    // if(selectedRecipientType != 30){
    if (globalVariablesData && globalVariablesData?.data?.t3) {
      if (value >= 0) {
        // let box25CalculatedValue = ((value * 0.38) + value);
        // let box26CalculatedValue = (box25CalculatedValue * 0.150198);
        let calculatedValue1 =
          value * (globalVariablesData?.data?.t3?.eligibleGrossUpFactorT3 / 100) +
          value;
        let calculatedValue2 =
          // parseInt(boxC2) *
            boxC2 *
            (globalVariablesData?.data?.t3?.nonEligibleGrossUpFactorT3 / 100) +
          // parseInt(boxC2);
            boxC2;
        let boxIValue = calculatedValue1 + calculatedValue2;
        console.log("boxIValuee",boxIValue)
        formMethods.setValue("taxableAmountOfEligibleAndOrdinaryDividends", boxIValue.toFixed(2));
        let boxJValue =
          // (value * 16.146) / 100 + (parseInt(boxC2) * 3.933) / 100;
          // (value * globalVariablesData?.data?.rl16?.eligibleDividendsRL16) / 100 + (parseInt(boxC2) * globalVariablesData?.data?.rl16?.ordinaryDividendsRL16) / 100;
          (value * globalVariablesData?.data?.rl16?.eligibleDividendsRL16) / 100 + (boxC2 * globalVariablesData?.data?.rl16?.ordinaryDividendsRL16) / 100;
        formMethods.setValue("dividendTaxCredit", boxJValue.toFixed(2));
      } else {
        formMethods.setValue("taxableAmountOfEligibleAndOrdinaryDividends", "0.00");
        formMethods.setValue("dividendTaxCredit", "0.00");
      }
      setShowGlobalVariablesError(false);
    } else {
      formMethods.setValue("taxableAmountOfEligibleAndOrdinaryDividends", "0.00");
      formMethods.setValue("dividendTaxCredit", "0.00");
      if(globalVariablesData){
      setShowGlobalVariablesError(
        globalVariablesData.data.message
          ? globalVariablesData.data.message
          : "RL-16 Slip definition does not exist for selected year, please add definition to configure global variables"
      );
    }
    }
    // } else {
    //   formMethods.setValue('taxableAmountOfEligibleDividendsRL16',"0.00")
    //   formMethods.setValue('dividendTaxCreditForEligibleDividendsRL16',"0.00")
    // }
  };
  const handleBoxC2InputChangeHandler = (e: any) => {
    console.log(e.target.value);
    let value = parseInt(e.target.value);
    // const boxC1 = parseFloat(formMethods.getValues("actualAmountOfEligibleDivinends").replace(/[$,]/g, ''));
    const boxC1 = parseFloat(formMethods.getValues("actualAmountOfEligibleDivinends") && typeof(formMethods.getValues("actualAmountOfEligibleDivinends")) === "string" ? formMethods.getValues("actualAmountOfEligibleDivinends").replace(/[$,]/g, '') : formMethods.getValues("actualAmountOfEligibleDivinends")); 

    // const selectedRecipientType = formMethods.getValues('recipientType')

    // if(selectedRecipientType != 30){
    if (globalVariablesData && globalVariablesData?.data?.t3) {
      if (value >= 0) {
        let calculatedValue1 =
          // parseInt(boxC1) *
          boxC1 *
            (globalVariablesData?.data?.t3?.eligibleGrossUpFactorT3 / 100) +
          // parseInt(boxC1);
          boxC1;
        let calculatedValue2 =
          value *
            (globalVariablesData?.data?.t3?.nonEligibleGrossUpFactorT3 / 100) +
          value;
        let boxIValue = calculatedValue1 + calculatedValue2;
        formMethods.setValue("taxableAmountOfEligibleAndOrdinaryDividends", boxIValue.toFixed(2));
        let boxJValue =
          // (parseInt(boxC1) * globalVariablesData?.data?.rl16?.eligibleDividendsRL16) / 100 + (value * globalVariablesData?.data?.rl16?.ordinaryDividendsRL16) / 100;
          (boxC1 * globalVariablesData?.data?.rl16?.eligibleDividendsRL16) / 100 + (value * globalVariablesData?.data?.rl16?.ordinaryDividendsRL16) / 100;
        formMethods.setValue("dividendTaxCredit", boxJValue.toFixed(2));
      } else {
        formMethods.setValue("taxableAmountOfEligibleAndOrdinaryDividends", "0.00");
        formMethods.setValue("dividendTaxCredit", "0.00");
      }
      setShowGlobalVariablesError(false);
    } else {
      formMethods.setValue("taxableAmountOfEligibleAndOrdinaryDividends", "0.00");
      formMethods.setValue("dividendTaxCredit", "0.00");
      if(globalVariablesData){
      setShowGlobalVariablesError(
        globalVariablesData.data.message
          ? globalVariablesData.data.message
          : "RL-16 Slip definition does not exist for selected year, please add definition to configure global variables"
      );
    }
    }
    // } else {
    //   formMethods.setValue('taxableAmountOfEligibleDividendsRL16',"0.00")
    //   formMethods.setValue('dividendTaxCreditForEligibleDividendsRL16',"0.00")
    // }
  };

  return (
    <>
      <Grid container columnSpacing={2} xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}></Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Actual
          </Typography>
        </Grid>
      </Grid>
      <TaxSlipDataInput
        id="capitalGains"
        label="Capital gains"
        boxNumber="A"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="singlePensionPayment"
        label="Single pension payments"
        boxNumber="B"
        defaultValue="0.00"
      />
      {showGlobalVariablesError === false ? 
      (<>
      <TaxSlipDataInput
        id="actualAmountOfEligibleDivinends"
        label="Actual amount of eligible dividends"
        boxNumber="C1"
        defaultValue="0.00"
        onChange={handleBoxC1InputChangeHandler}
      />
      </>)
      :
      (<>
      <div>
      <TaxSlipDataInput
        id="actualAmountOfEligibleDivinends"
        label="Actual amount of eligible dividends"
        boxNumber="C1"
        defaultValue="0.00"
        onChange={handleBoxC1InputChangeHandler}
      />
        <span style={{display:"flex",justifyContent:"center",marginLeft:"19.6rem",color:"#cc8925",fontSize:"12px",background:"#fff2e2",alignItems:"center",padding:"2px",borderRadius:"5px"}}><WarningAmberIcon sx={{marginRight:"7px",padding:"0 2px 2px 2px"}}/>{showGlobalVariablesError}</span>
      </div></>)
}
{showGlobalVariablesError === false ? 
      (<>
      <TaxSlipDataInput
        id="actualAmountOfDivinendsOtherThanEligible"
        label="Actual amount - other than eligible"
        boxNumber="C2"
        defaultValue="0.00"
        onChange={handleBoxC2InputChangeHandler}
      />
      </>)
      :
      (<>
      <div>
      <TaxSlipDataInput
        id="actualAmountOfDivinendsOtherThanEligible"
        label="Actual amount - other than eligible"
        boxNumber="C2"
        defaultValue="0.00"
        onChange={handleBoxC2InputChangeHandler}
      />
        <span style={{display:"flex",justifyContent:"center",marginLeft:"19.6rem",color:"#cc8925",fontSize:"12px",background:"#fff2e2",alignItems:"center",padding:"2px",borderRadius:"5px"}}><WarningAmberIcon sx={{marginRight:"7px",padding:"0 2px 2px 2px"}}/>{showGlobalVariablesError}</span>
      </div></>)
}
      <TaxSlipDataInput
        id="pensionPaymentGivingEntitlement"
        label="Pension payment giving entitlement"
        boxNumber="D"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="foreignBusinessIncome"
        label="Foreign business income"
        boxNumber="E"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="foreignNonBusinessIncome"
        label="Foreign non business income"
        boxNumber="F"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="otherIncome"
        label=" Other income"
        boxNumber="G"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="capitalGainGivingEntitlement"
        label="Capital gains giving entitlement"
        boxNumber="H"
        defaultValue="0.00"
      />


      <TaxSlipDataInput
        id="taxableAmountOfEligibleAndOrdinaryDividends"
        label="Taxable amount of eligible and ordinary dividends"
        boxNumber="I"
        defaultValue="0.00"
        readOnly={true}
      />
      <TaxSlipDataInput
        id="dividendTaxCredit"
        label="Dividend tax credit"
        boxNumber="J"
        defaultValue="0.00"
        readOnly={true}
      />
      <TaxSlipDataInput
        id="foreignIncomeTaxOnBusinessIncome"
        label="Foreign income tax on business income"
        boxNumber="K"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="foreignIncomeTaxOnNonBusinessIncome"
        label="Foreign income tax on non-business income"
        boxNumber="L"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="costBasedAdjustmentOfCapitalInterest"
        label="Cost base adjustment of capital interest"
        boxNumber="M"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="donationFromAReligiousOrganization"
        label="Donation from a religious organization"
        boxNumber="N"
        defaultValue="0.00"
      />
      <TaxSlipDataInput
        id="beneficiaryNumber"
        label="Beneficiary number"
        boxNumber=""
        defaultValue=""
        beneficiaryNumberTextField={true}
      />
      <TaxSlipDataInput
        id="consolidatedIndicator"
        label="Consolidated indicator"
        boxNumber=""
        ddlField={true}
      />
      <TaxSlipDataInput
        id="footNotes"
        label="Foot notes"
        boxNumber=""
        notesField={true}
      />
      <TaxSlipDataInput
        id="optionalTextToPrintOnTheSlip"
        label="Optional text to print on the slip"
        boxNumber=""
        optionalTextField={true}
        alert={true}
        titlePlacement={'top'}
        alertTitle="To print text at the top of this slip (e.g., 'Additional' or 'Suplicate'), enter it here. If this slip is amended or cancelled do not indicate this here. The requirements 
        of amended or cancelled slips are taken care of by setting the status of the slip."
      />

      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ mt: 2, p: 4, backgroundColor: "#f4f5f7" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold", pb: 1 }}>
            Other Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <OtherInformationSetupFieldsPanel
            formMethods={formMethods}
            isLoading={isLoading}
            otherInformationList={otherInformationList}
            testId={testId}
            selectedOtherInformation={selectedOtherInformation}
            oiInEdit={oiInEdit}
            // selectedProvinceOfEmployment={undefined}
            globalVariablesData={undefined}
          />
        </Grid>
      </Grid>
    </>
  );
};
