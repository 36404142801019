export const ProvinceService = {
  getOld: () => {
    return [
      { id: 1, value: "AB", label: "Alberta" },
      { id: 2, value: "BC", label: "British Columbia" },
      { id: 3, value: "MB", label: "Manitoba" },
      { id: 4, value: "NB", label: "New Brunswick" },
      { id: 5, value: "NL", label: "Newfoundland and Labrador" },
      { id: 7, value: "NT", label: "Northwest Territories" },
      { id: 6, value: "NS", label: "Nova Scotia" },
      { id: 8, value: "NU", label: "Nunavut" },
      { id: 9, value: "ON", label: "Ontario" },
      { id: 10, value: "PE", label: "Prince Edward Island" },
      { id: 11, value: "QC", label: "Quebec" },
      { id: 12, value: "SK", label: "Saskatchewan" },
      { id: 13, value: "YT", label: "Yukon" },
    ];
  },
  getOperatingProvincesList: () => {
    return [
      { id: 1, value: 1, label: "Alberta" },
      { id: 2, value: 2, label: "British Columbia" },
      { id: 3, value: 3, label: "Manitoba" },
      { id: 4, value: 4, label: "New Brunswick" },
      { id: 5, value: 5, label: "Newfoundland and Labrador" },
      { id: 7, value: 6, label: "Northwest Territories" },
      { id: 6, value: 7, label: "Nova Scotia" },
      { id: 8, value: 8, label: "Nunavut" },
      { id: 9, value: 9, label: "Ontario" },
      { id: 10, value: 10, label: "Prince Edward Island" },
      { id: 11, value: 11, label: "Quebec" },
      { id: 12, value: 12, label: "Saskatchewan" },
      { id: 13, value: 13, label: "Yukon" },
    ];
  },
  get: () => {
    return [
      // { id: 1, value: "AB", label: "Alberta" },
      // { id: 2, value: "BC", label: "British Columbia" },
      // { id: 3, value: "MB", label: "Manitoba" },
      // { id: 4, value: "NB", label: "New Brunswick" },
      // { id: 5, value: "NL", label: "Newfoundland and Labrador" },
      // { id: 7, value: "NT", label: "Northwest Territories" },
      // { id: 6, value: "NS", label: "Nova Scotia" },
      // { id: 8, value: "NU", label: "Nunavut" },
      // { id: 9, value: "ON", label: "Ontario" },
      // { id: 10, value: "PE", label: "Prince Edward Island" },
      // { id: 11, value: "QC", label: "Quebec" },
      // { id: 12, value: "SK", label: "Saskatchewan" },
      // { id: 13, value: "YT", label: "Yukon" },

      // according to situation we have to send in responce this value for UPDATE
      // {label: "Alberta", value: '{id:1, name: "Alberta",shortCode:"AB"}', },
      // {label: "British Columbia", value: '{id:2, name: "British Columbia",shortCode:"BC"}',},
      // {label:"Manitoba", value: {id:3, name: "Manitoba",shortCode:"MB"},},
      // {label:"New Brunswick", value: {id:4, name: "New Brunswick",shortCode:"NB"}, },
      // {label:"Newfoundland and Labrador", value: {id:5, name: "Newfoundland and Labrador",shortCode:"NL"}, },
      // {label:"Northwest Territories", value: {id:7, name: "Northwest Territories",shortCode:"NT"},},
      // {label:"Nova Scotia", value: '{id:6, name: "Nova Scotia",shortCode:"NS"}', },
      // {label:"Nunavut", value: {id:8, name: "Nunavut",shortCode:"NU"}, },
      // {label:"Ontario", value: '{id:9, name: "Ontario",shortCode:"ON"}', },
      // {label:"Prince Edward Island", value: {id:10,name: "Prince Edward Island",shortCode:"PE"}, },
      // {label:"Quebec", value: {id:11,name: "Quebec",shortCode:"QC"}, },
      // {label:"Saskatchewan", value: {id:12,name: "Saskatchewan",shortCode:"SK"}, },
      // {label:"Yukon", value: {id:13,name: "Yukon",shortCode:"YT"},},

      // according to situation we have to send in responce this value for UPDATE but for get by id to show province only string show the value
      {id:"1", label: "Alberta", value: '{id:1, name: "Alberta",shortCode:"AB"}' },
      {id:"2", label: "British Columbia", value: '{id:2, name: "British Columbia",shortCode:"BC"}',},
      {id:"3", label: "Manitoba", value: '{id:3, name: "Manitoba",shortCode:"MB"}' },
      {id:"4", label: "New Brunswick", value: '{id:4, name: "New Brunswick",shortCode:"NB"}',},
      {id:"5", label: "Newfoundland and Labrador", value: '{id:5, name: "Newfoundland and Labrador",shortCode:"NL"}',},
      {id:"7", label: "Northwest Territories", value: '{id:7, name: "Northwest Territories",shortCode:"NT"}',},
      {id:"6", label: "Nova Scotia", value: '{id:6, name: "Nova Scotia",shortCode:"NS"}',},
      {id:"8", label: "Nunavut", value: '{id:8, name: "Nunavut",shortCode:"NU"}' },
      {id:"9", label: "Ontario", value: '{id:9, name: "Ontario",shortCode:"ON"}' },
      {id:"10", label: "Prince Edward Island", value: '{id:10, name: "Prince Edward Island",shortCode:"PE"}'},
      {id:"11", label: "Quebec", value: '{id:11, name: "Quebec",shortCode:"QC"}' },
      {id:"12", label: "Saskatchewan", value: '{id:12, name: "Saskatchewan",shortCode:"SK"}'},
      {id:"13", label: "Yukon", value: '{id:13, name: "Yukon",shortCode:"YT"}' },
    ];
  },
  getPOE: () => {
    return [
      // { id: 1, value: "AB", label: "Alberta" },
      // { id: 2, value: "BC", label: "British Columbia" },
      // { id: 3, value: "MB", label: "Manitoba" },
      // { id: 4, value: "NB", label: "New Brunswick" },
      // { id: 5, value: "NL", label: "Newfoundland and Labrador" },
      // { id: 7, value: "NT", label: "Northwest Territories" },
      // { id: 6, value: "NS", label: "Nova Scotia" },
      // { id: 8, value: "NU", label: "Nunavut" },
      // { id: 9, value: "ON", label: "Ontario" },
      // { id: 10, value: "PE", label: "Prince Edward Island" },
      // { id: 11, value: "QC", label: "Quebec" },
      // { id: 12, value: "SK", label: "Saskatchewan" },
      // { id: 13, value: "YT", label: "Yukon" },

      // according to situation we have to send in responce this value for UPDATE
      { label: "Alberta", value: '{id:1, name: "Alberta",shortCode:"AB"}' },
      {
        label: "British Columbia",
        value: { id: 2, name: "British Columbia", shortCode: "BC" },
      },
      {
        label: "Manitoba",
        value: { id: 3, name: "Manitoba", shortCode: "MB" },
      },
      {
        label: "New Brunswick",
        value: { id: 4, name: "New Brunswick", shortCode: "NB" },
      },
      {
        label: "Newfoundland and Labrador",
        value: { id: 5, name: "Newfoundland and Labrador", shortCode: "NL" },
      },
      {
        label: "Northwest Territories",
        value: { id: 7, name: "Northwest Territories", shortCode: "NT" },
      },
      {
        label: "Nova Scotia",
        value: '{id:6, name: "Nova Scotia",shortCode:"NS"}',
      },
      { label: "Nunavut", value: { id: 8, name: "Nunavut", shortCode: "NU" } },
      { label: "Ontario", value: { id: 9, name: "Ontario", shortCode: "ON" } },
      {
        label: "Prince Edward Island",
        value: { id: 10, name: "Prince Edward Island", shortCode: "PE" },
      },
      { label: "Quebec", value: { id: 11, name: "Quebec", shortCode: "QC" } },
      {
        label: "Saskatchewan",
        value: { id: 12, name: "Saskatchewan", shortCode: "SK" },
      },
      { label: "Yukon", value: { id: 13, name: "Yukon", shortCode: "YT" } },
    ];
  },
  getTransferOverRemittance: () => {
    return [
      { label: "Ali 004", value: "64be7a43ea267d2e1682fe0f" },
      { label: "Ali 00", value: "64be7723ea267d2e1682fddd" },
    ];
  },

 
};
export let PROVINCE_OF_EMPLOYMENT = ''
export const getPROVINCE_OF_EMPLOYMENT = (val:any) => {
  // alert(val)
    if(val === 1){
      PROVINCE_OF_EMPLOYMENT = '{id:1, name: "Alberta",shortCode:"AB"}'
    }
    else if (val === 2){
      PROVINCE_OF_EMPLOYMENT = '{id:2, name: "British Columbia",shortCode:"BC"}'
    }
    else if (val === 3){
      PROVINCE_OF_EMPLOYMENT = '{id:3, name: "Manitoba",shortCode:"MB"}'
    }
    else if (val === 4){
      PROVINCE_OF_EMPLOYMENT = '{id:4, name: "New Brunswick",shortCode:"NB"}'
    }
    else if (val === 5){
      PROVINCE_OF_EMPLOYMENT = '{id:5, name: "Newfoundland and Labrador",shortCode:"NL"}'
    }
    else if (val === 7){
      PROVINCE_OF_EMPLOYMENT = '{id:7, name: "Northwest Territories",shortCode:"NT"}'
    }
    else if (val === 6){
      PROVINCE_OF_EMPLOYMENT = '{id:6, name: "Nova Scotia",shortCode:"NS"}'
    }
    else if (val === 8){
      PROVINCE_OF_EMPLOYMENT = '{id:8, name: "Nunavut",shortCode:"NU"}'
    }
    else if (val === 9){
      PROVINCE_OF_EMPLOYMENT = '{id:9, name: "Ontario",shortCode:"ON"}'
    }
    else if (val === 10){
      PROVINCE_OF_EMPLOYMENT = '{id:10, name: "Prince Edward Island",shortCode:"PE"}'
    }
    else if (val === 11){
      PROVINCE_OF_EMPLOYMENT = '{id:11, name: "Quebec",shortCode:"QC"}'
    }
    else if (val === 12){
      PROVINCE_OF_EMPLOYMENT = '{id:12, name: "Saskatchewan",shortCode:"SK"}'
    }
    else if (val === 13){
      PROVINCE_OF_EMPLOYMENT = '{id:13, name: "Yukon",shortCode:"YT"}'
    }
  }

export const getProvinceByShortCode = (val:any) => {
    if(val === "AB"){
      return { id: 1, value: "AB", label: "Alberta" }
    }
    else if (val === "BC"){
      return { id: 2, value: "BC", label: "British Columbia" }
    }
    else if (val === "MB"){
      return { id: 3, value: "MB", label: "Manitoba" }
    }
    else if (val === "NB"){
      return { id: 4, value: "NB", label: "New Brunswick" }
    }
    else if (val === "NL"){
      return { id: 5, value: "NL", label: "Newfoundland and Labrador" }
    }
    else if (val === "NT"){
      return { id: 7, value: "NT", label: "Northwest Territories" }
    }
    else if (val === "NS"){
      return { id: 6, value: "NS", label: "Nova Scotia" }
    }
    else if (val === "NU"){
      return { id: 8, value: "NU", label: "Nunavut" }
    }
    else if (val === "ON"){
      return { id: 9, value: "ON", label: "Ontario" }
    }
    else if (val === "PE"){
      return { id: 10, value: "PE", label: "Prince Edward Island" }
    }
    else if (val === "QC"){
      return { id: 11, value: "QC", label: "Quebec" }
    }
    else if (val === "SK"){
      return { id: 12, value: "SK", label: "Saskatchewan" }
    }
    else if (val === "YT"){
      return { id: 13, value: "YT", label: "Yukon" }
    }
  }