import RL16SlipImportLayout from "components/RL16Slip/RL16SlipImportLayout/RL16SlipImportLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL16SlipImportPage {}

export const RL16SlipImportPage: FC<IRL16SlipImportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import RL-16 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl16/list" },
        { text: "RL-16", navigateTo: "/taxslip/rl16/list" },
        { text: "Import RL-16 Slips", navigateTo: "/taxslip/rl16/import" },
      ]}
      mainContents={<RL16SlipImportLayout />}
    />
  );
};
