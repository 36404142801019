import RL1SlipImportXmlLayout from "components/RL1Slip/RL1SlipImportXmlLayout/RL1SlipImportXmlLayout";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

export interface IRL1SlipImportXmlPage {}

export const RL1SlipImportXmlPage: FC<IRL1SlipImportXmlPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Import XML RL-1 Slip"
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[
        { text: "Compliance", navigateTo: "/taxslip/rl1/list" },
        { text: "RL-1", navigateTo: "/taxslip/rl1/list" },
        { text: "Import XML RL-1 Slips", navigateTo: "/taxslip/rl1/import-xml" },
      ]}
      mainContents={<RL1SlipImportXmlLayout />}
    />
  );
};
