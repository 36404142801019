import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { RL3Report } from "components/Reports/RL3Report";


export interface IRL3ReportPage {}

export const RL3ReportPage: FC<IRL3ReportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="RL-3 Report"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<RL3Report />}
    />
  );
};
