import * as yup from "yup";

import { Alert, AlertTitle, Box, Button, Divider } from "@mui/material";
import {
  BackdropCircularProgress,
  EnhancedSnackbar,
  IResponse,
  IServerError,
  LabelPosition,
  RHookFormPasswordFieldWithLabel,
  RHookFormTextFieldWithLabel,
  Spacer,
  StandardPanel,
  StyledRouterLink,
  SubmitButton,
  SubmitButtonVariant,
} from "@websential/cosmic";
import { FormProvider, useForm } from "react-hook-form";
import {
  LabelCaption,
  PlaceholderText,
  ValidationMessages,
} from "../../common/AppMessages";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { AccountFormLayout } from "components/AccountFormLayout";
import { AuthService } from "services";
import Grid from "@mui/material/Grid";
import { INITIAL_SNACKBAR_DATA } from "common/constants";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

export interface IForgotPasswordForm {
  email: string;
}
export interface ICreateNewPasswordForm {
  newPassword?: string;
  confirmNewPassword?: string;
}

const useUserDetail = (key: string | undefined) =>
  useQuery(
    "authentication-data",
    (data) => {
      return AuthService.authenticationData(key)
;
    },
    { refetchOnWindowFocus: false }
  );

const useGetVerfyEmailKey = (key: string | undefined) =>
  useQuery(
    "verify-email",
    (data) => {
      return AuthService.verifyEmail(key)
;
    },
    { refetchOnWindowFocus: false }
  );

const loginFormSchema: yup.SchemaOf<IForgotPasswordForm> = yup.object({
  email: yup
    .string()
    .email(ValidationMessages.invalidEmailFormat)
    .max(50)
    .required(ValidationMessages.email),
});
const createPasswordFormSchema: yup.SchemaOf<ICreateNewPasswordForm> =
  yup.object({
    newPassword: yup
      .string()
      .required("Please Enter New Password")
      .min(8, "Your password must be at least 8 characters long")
      .max(15, "Your password Should be maximum 15 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d).+$/,
        "Password must contain at least 1 letter and 1 number"
      ),
    confirmNewPassword: yup
      .string()
      .required("Please Confirm new password")
      .min(8, "Your password must be at least 8 characters long")
      .max(15, "Your password Should be maximum 15 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d).+$/,
        "Password must contain at least 1 letter and 1 number"
      )
      .oneOf([yup.ref("newPassword"), null], "Passwords don't match."),
  });

const AlreadyHaveAnAccountLinkComponent = () => {
  return (
    <Grid
      item
      md={12}
      lg={12}
      xl={12}
      style={{ position: "relative", top: "1.3rem" }}
    >
      <Typography variant="subtitle2">
        Already have an account?&nbsp;
        <StyledRouterLink to="/login">Sign In</StyledRouterLink>
      </Typography>
    </Grid>
  );
};

const PasswordUpdatedSuccessfully = () => {
  return (
    <StandardPanel title={`Welcome to TaxSlips`} testId={`welcome-panel`}>
      <Alert severity="success" sx={{ marginBottom: "1rem" }}>
        <AlertTitle>Reset Password Successfully</AlertTitle>
        <br />
        Thank you for spending some quality time with us today.
      </Alert>
      <Divider />
      <Box sx={{ textAlign: "center", marginTop: "1rem" }}>
        <StyledRouterLink to="/login">
          <Button variant="contained">Login</Button>
        </StyledRouterLink>
      </Box>
    </StandardPanel>
  );
};

const DoNotHaveAnAccountLinkComponent = () => {
  return (
    <Grid
      item
      md={12}
      lg={12}
      xl={12}
      style={{ position: "relative", top: "1.3rem" }}
    >
      <Typography variant="subtitle2">
        Do not have an account yet?&nbsp;
        <StyledRouterLink to="/create-an-account">
          Create an Account
        </StyledRouterLink>
      </Typography>
    </Grid>
  );
};

const ForgetPasswordEmailSendSuccessfully = () => {
  return (
    <StandardPanel title={`Welcome to TaxSlips`} testId={`welcome-panel`}>
      <Alert severity="success" sx={{ marginBottom: "5px" }}>
        Email Sent
      </Alert>
      <Alert severity="warning" sx={{ marginBottom: "10px" }}>
        Your Reset password link has been sent to your email address.
      </Alert>
      <Divider />
      <Box sx={{ textAlign: "right" }}>
        <AlreadyHaveAnAccountLinkComponent />
        <DoNotHaveAnAccountLinkComponent />
        <Spacer y={1.5} x={1.5} />
      </Box>
    </StandardPanel>
  );
};
const InvalidEmail = () => {
  return (
    <StandardPanel title={`Welcome to TaxSlips`} testId={`welcome-panel`}>
      <Alert severity="error" sx={{ marginBottom: "10px" }}>
        Invalid Email
      </Alert>
      <Box sx={{ textAlign: "right" }}>
        <AlreadyHaveAnAccountLinkComponent />
        <DoNotHaveAnAccountLinkComponent />
        <Spacer y={1.5} x={1.5} />
      </Box>
    </StandardPanel>
  );
};

interface IForgotPasswordProps {
  successPanel: (value: string) => void;
}
const ForgotPasswordFormComponent: React.FC<IForgotPasswordProps> = ({
  successPanel,
}) => {
  const { key } = useParams();
  console.log("keydsfg", key);
  const [snackbarData, setSnackbarData] = useState(INITIAL_SNACKBAR_DATA);
  const [verifyEmailResponse, setverifyEmailResponse] = useState<any>(undefined);

  //get data
  const { isLoading: isLoadingUserDetail, data: authenticationData } =
    useUserDetail(key)
;
    
  let emailKey = authenticationData && authenticationData.email
  // const { isLoading: isLoadingVerifyEmail, data: verifyEmail } =
  //   useGetVerfyEmailKey(authenticationData && authenticationData.email);
  // console.log("verifyEmail", verifyEmail);

  useEffect(()=>{
    (async () => {
    try {
          const response: any = await AuthService.verifyEmail(emailKey);
          console.log("responseData-----------------", response);
          if(response.data){
            setverifyEmailResponse(response)
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      })();
},[authenticationData,emailKey])

  const methods = useForm<IForgotPasswordForm>({
    resolver: yupResolver(loginFormSchema),
  });
  const methodsForCreatePassword = useForm<ICreateNewPasswordForm>({
    resolver: yupResolver(createPasswordFormSchema),
  });

  const { isLoading: isSubmitting, mutate: userForgetPassword } = useMutation(
    AuthService.forgetPassword,
    {
      onSuccess: (
        res: IServerError | any | IResponse | IForgotPasswordForm
      ) => {
        if (res.message === "Email send successfully") {
          successPanel(
            "Your Reset password link has been sent to your email address."
          );
        }else if (res.message === "Password has been updated.") {
          successPanel(
            "password Reset successful"
          );
        } else {
          console.log("res", res);
          setSnackbarData({
            open: true,
            message: res ? res.message : "Somethingwent wrong!",
            severity: res.status === false ? "error" : "success",
          });
        }
      },
      onError: (err) => {
        // TODO: customize the toast message based on the response
        setSnackbarData({
          open: true,
          message: "Something went wrong, try again later!",
          severity: "error",
        });
      },
    }
  );

  const onSnackbarClosed = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  };

  const onSubmit = (
    data: IForgotPasswordForm | ICreateNewPasswordForm | any
  ) => {
    let payload = {
      key: key ? key : undefined,
      email: data.email ? data.email : key && authenticationData ? authenticationData && authenticationData.email : undefined,
      newPassword: data.newPassword ? data.newPassword : undefined,
      confirmNewPassword: data.confirmNewPassword
        ? data.confirmNewPassword
        : undefined,
    };
    console.log("payload", payload);
    userForgetPassword(payload);
  };

  console.log(methods.formState.isDirty); // make sure formState is read before render to enable the Proxy
  return (
    <>
      {key ? (
        <>
          <BackdropCircularProgress
            open={isLoadingUserDetail  || isSubmitting}
            testId={"forgot-password"}
          />
          {verifyEmailResponse && verifyEmailResponse.status === 200 ? (
            <FormProvider {...methodsForCreatePassword}>
              <form onSubmit={methodsForCreatePassword.handleSubmit(onSubmit)}>
                <Grid container md={12} lg={12} xl={12}>
                  <Grid item md={12} lg={12} xl={12}>
                    <RHookFormPasswordFieldWithLabel
                      id="newPassword"
                      testId={`forget-new-password`}
                      label="New Password"
                      placeholder="Choose password"
                      // labelPosition={LabelPosition.Left}
                      required
                    />
                  </Grid>
                  <Spacer y={2} x={2} />
                  <Grid item md={12} lg={12} xl={12}>
                    <RHookFormPasswordFieldWithLabel
                      id="confirmNewPassword"
                      testId={`forget-confirm-new-password`}
                      label="Confirm New Password"
                      placeholder="Confirm password"
                      // labelPosition={LabelPosition.Left}
                      required
                    />
                  </Grid>
                </Grid>
                <Spacer y={2} x={2} />
                <SubmitButton
                  label="Reset Password"
                  variant={SubmitButtonVariant.Primary}
                  testId="reset-password-button"
                  loading={isSubmitting}
                />
                <Spacer y={2} x={2} />
                <AlreadyHaveAnAccountLinkComponent />
                <Spacer y={2} x={2} />
                <DoNotHaveAnAccountLinkComponent />
              </form>
            </FormProvider>
          ) : verifyEmailResponse ? (
            
            <StandardPanel
              title={`Welcome to TaxSlips`}
              testId={`welcome-panel`}
            >
              <Alert severity="error" sx={{ marginBottom: "1rem" }}>
                <AlertTitle>Error</AlertTitle>
                <br />
                {verifyEmailResponse && verifyEmailResponse.message
                  ? verifyEmailResponse.message
                  : "E-mail Does Not Exist!"}
              </Alert>
              <Divider />
              <Box sx={{ textAlign: "right" }}>
                <AlreadyHaveAnAccountLinkComponent />
                <DoNotHaveAnAccountLinkComponent />
                <Spacer y={1.5} x={1.5} />
              </Box>
            </StandardPanel>
          ) : authenticationData && authenticationData.message &&
          authenticationData.message.message === "Invalid email key." ? 
          (<InvalidEmail/>) : (<><h1>Loading...</h1></>)}
        </>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container md={12} lg={12} xl={12}>
                <Grid item md={12} lg={12} xl={12}>
                  <RHookFormTextFieldWithLabel
                    label={LabelCaption.email}
                    id="email"
                    testId="email"
                    placeholder={PlaceholderText.email}
                  />
                </Grid>
              </Grid>
              <Spacer y={2} x={2} />
              <SubmitButton
                label="Reset Password"
                variant={SubmitButtonVariant.Primary}
                testId="reset-password-button"
                loading={isSubmitting}
              />
              <Spacer y={2} x={2} />
              <AlreadyHaveAnAccountLinkComponent />
              <Spacer y={2} x={2} />
              <DoNotHaveAnAccountLinkComponent />
            </form>
          </FormProvider>
        </>
      )}
      <EnhancedSnackbar
        message={snackbarData.message}
        onCloseHandler={onSnackbarClosed}
        severity={snackbarData.severity}
        testId={"register-snackbar"}
        open={snackbarData.open}
      />
    </>
  );
};

export const ForgotPasswordForm = () => {
  const { key } = useParams();
  const [successResponse, setSuccessResponse] = useState<any>(undefined);
  return (
    <>
      {successResponse === "Your Reset password link has been sent to your email address." ? (
        <Grid container md={12} lg={12} xl={12}>
          <Grid item md={1} lg={1} xl={1}></Grid>
          <Grid item md={10} lg={10} xl={10}>
            <ForgetPasswordEmailSendSuccessfully />
          </Grid>
          <Grid item md={1} lg={1} xl={1}></Grid>
        </Grid>
      ) : successResponse === "password Reset successful" ? (
        <Grid container md={12} lg={12} xl={12}>
          <Grid item md={1} lg={1} xl={1}></Grid>
          <Grid item md={10} lg={10} xl={10}>
            <PasswordUpdatedSuccessfully />
          </Grid>
          <Grid item md={1} lg={1} xl={1}></Grid>
        </Grid>
      ) : (
        <AccountFormLayout
          title="Forgot Password?"
          subtitle={
            key
              ? "Please enter your password twice so we can verify you typed it in correctly."
              : "We'll email you instructions on how to reset your password"
          }
          form={
            <ForgotPasswordFormComponent
              successPanel={(value: any) => setSuccessResponse(value)}
            />
          }
        />
      )}
    </>
  );
};