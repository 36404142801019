import React, { FC } from "react";

import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Footer } from "@websential/cosmic";

export interface IAccountLayout {
  contents?: React.ReactNode;
}

export const AccountLayout: FC<IAccountLayout> = ({
  contents,
}: IAccountLayout) => {
  const theme = useTheme();
  const testId = "account-layout";
  const onTaxslipsClickedHandler = () => {
    window.open('https://www.taxslips.com/','_self');
  }
  return (
    <Grid container>
      <Grid
        container
        direction="column"
        alignItems="center"
        md={4}
        lg={4}
        xl={4}
        sx={{ backgroundColor: theme.palette.primary.main }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            height: "40vh",
          }}
          data-testid={testId + "-logo"}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logo-light.png`}
            alt="loading"
            width="100%"
            onClick={onTaxslipsClickedHandler}
            style={{cursor:"pointer"}}
          />
        </Grid>
        <Grid
          sx={{
            height: "60vh",
            display: "flex",
            alignItems: "flex-end",
          }}
          data-testid={testId + "-bottomImage"}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/left-bottom-img.jpg`}
            alt="loading"
            width="100%"
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        md={8}
        lg={8}
        xl={8}
      >
        <Grid
          container
          sx={{
            height: "90vh",
            display: "flex",
            alignItems: "center",
          }}
          data-testid={testId + "-content"}
        >
          {contents}
        </Grid>
        {/* <Grid
          item
          sx={{
            height: "10vh",
            display: "flex",
            alignItems: "flex-end",
          }}
          data-testid={testId + "-footer"}
        >
          <Footer />
        </Grid> */}
      </Grid>
    </Grid>
  );
};
