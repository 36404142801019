import { FC, useEffect } from "react";

import { Typography, useTheme } from "@mui/material";

export interface IAuditAlert {
  title: string;
  alerts: string[];
}

export const AuditAlert: FC<IAuditAlert> = ({ title, alerts }) => {
  const theme = useTheme();

  useEffect(() => {}, []);

  return (
    <>
      <Typography variant="subtitle1" component="span">
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        component="span"
        sx={{
          color:
            alerts.length === 0
              ? theme.palette.success.main
              : theme.palette.warning.main,
        }}
      >
        &nbsp;({alerts.length > 0 ? `${alerts.length} WARNING(S)` : "All Good"})
      </Typography>
      {alerts && alerts.length > 0 ? (
        alerts.map((alert) => {
          // return <Typography variant="body2">{alert}</Typography>;
          return <ul style={{margin:"0",paddingLeft:"20px"}}><li>{alert}</li></ul>
        })
      ) : (
        <Typography variant="body2">No audit alert(s) found.</Typography>
      )}
    </>
  );
};
