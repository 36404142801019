import { InvoiceReport } from "components/Reports";
import { AuthenticatedLayout } from "layouts/AuthenticatedLayout/AuthenticatedLayout";
import { FC } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export interface IInvoiceReportPage {}

export const InvoiceReportPage: FC<IInvoiceReportPage> = () => {
  return (
    <AuthenticatedLayout
      pageTitle="Invoice Report"
      pageTitleIcon={<DescriptionOutlinedIcon />}
      breadcrumbCurrentPageName=""
      breadcrumbLinks={[]}
      mainContents={<InvoiceReport />}
    />
  );
};
